import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-clausula-cobranza',
  templateUrl: './clausula-cobranza.component.html',
  styleUrls: ['./../options.component.scss']
})
export class ClausulaCobranzaComponent extends BusinessParticularityOption implements OnChanges {
  isCollectionClause: boolean = false;

  constructor() {
    super()
  }

  ngOnChanges(): void {
    try{
      this.isCollectionClause=this.businessParticularitiesStruct.collectionClause==1;
    } catch(err){
      this.isCollectionClause=false;
    }
  }

  setCollectionClause() {
    this.isCollectionClause = !this.isCollectionClause;
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.collectionClause = this.boolToNumber(this.isCollectionClause);
    this.emit2(newBP);
  }

}
