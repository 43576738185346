import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntregaSiloBolsasComponent } from './entrega-silo-bolsas.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { SelectInputModule } from 'src/app/components/inputs/select/select.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';



@NgModule({
  declarations: [
    EntregaSiloBolsasComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    SelectInputModule,
    NumberInputModule
  ], exports: [
    EntregaSiloBolsasComponent
  ]
})
export class EntregaSiloBolsasModule { }
