import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker, isEarlierDate } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields.component';
import { isInvalidPesification } from 'src/app/shared/price';

@Component({
  selector: 'app-end-pesification',
  templateUrl: './end-date.component.html',
  styleUrls: []
})
export class EndPesificationComponent extends Field implements OnInit {

  constructor(store: Store<appState>,private date: DateChecker) {
    super(store);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator == fieldType.PRICE) {
      this.tag = priceTags.pesificacion;
      this.subTag = priceTags.endDate;
      this.thirdTag = '';
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.pesificacion;
      this.thirdTag = priceTags.endDate;
    }
  }
  
  override setIncomplete(boolean:boolean){
    this.isIncomplete= boolean && isInvalidPesification(this.getPrice());
    //Falta verificar si la fecha es anterior
  }

  override setElement(event: any, index: number) {
    try {
      this.setTag(priceTags.endDate);

      let dateToSave = event;
      if (isEarlierDate(this.getPrice().pesificacion.startDate, dateToSave, '-')) {
        this.setElementToGenerate(dateToSave);
      } else {
        this.setElementToGenerate('--');
      }
    } catch (err) { }
  }

  override rememberValueInForm() {
    this.rememberPesification();
  }

  //Remember value from date type (For deliveryPeriod).
  rememberPesification() {

    let valueToSet: any = this.rememberValue();
    //If There is a value to remember
    if (valueToSet && this.elementToGenerate) {

      if (valueToSet) {
        if (valueToSet != '--') {
          this.elementSelected=valueToSet;
        } else {
          this.elementSelected = '';
        }
      }
    }
  }
}
