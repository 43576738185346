import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderTableComponent } from './order-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { CommoditiesComponent } from './commodities/commodities.component';
import { ComercialComponent } from './comercial/comercial.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { FilterOrderModule } from 'src/app/components/filters/filter-order/filters.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EyeSvgModule } from 'src/app/shared/styles/svg/eye/eye.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { ObservationsOrderSvgModule } from 'src/app/shared/styles/svg/observations-order/observations-order.module';
import { MenuOptionsSvgModule } from 'src/app/shared/styles/svg/menu-options/menu-options.module';
import { IndicatorsTableModule } from 'src/app/components/board/indicators/indicators.module';
import { RowHeadModule } from 'src/app/components/board/order/row-head/row-head.module';
import { RowOrderBoardModule } from 'src/app/components/board/order/row/row.module';
import { TableMobileOrderModule } from 'src/app/components/board/mobile-order/mobile-order.module';
import { ButtonsToFixModule } from 'src/app/components/buttons-to-fix/buttons-to-fix.module';

@NgModule({
  declarations: [
    OrderTableComponent,
    CommoditiesComponent,
    ComercialComponent,
  ],
  imports: [
    CommonModule, 
    MatDatepickerModule,
    FilterOrderModule,
    RowHeadModule,
    RowOrderBoardModule,
    TableMobileOrderModule,
    IndicatorsTableModule,
    ButtonsToFixModule,


    //SVG
    MenuOptionsSvgModule,
    ThreePointsSvgModule, ArrowSvgModule,
    FilterBoardSvgModule,
    EyeSvgModule,
    ObservationsOrderSvgModule,
    
    PipesModule,
  ],
  exports: [
    OrderTableComponent
  ]
})
export class OrderTableModule { }
