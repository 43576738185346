<div class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive" [ngClass]="{'is-to-fix': conditionTypeSelected==CONDITION_TYPES.TO_FIX}">
        <thead>
            <tr row-head [isControlPanel]="true" [viewSelected]="viewSelected" [filter]="orderFilter" 
            [filterIdentificator]="filterIdentificator" [isCheckedAll]="isCheckedAll"
            [conditionTypeSelected]="conditionTypeSelected"
            [showBuyer]="DIVISION_TYPES.BUY_COMMODITIES != divisionTypeSelected"
            [showAutorizateAll]="isSelectedInEraser()" [showCheckbox]="isSelectedInEraser()"

            (filterSelect)="selectFilter($event)"
            (actions)="selectAction($event)"></tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let order of ordersWithPipe; index as i">

            <tr app-row (dblclick)="selectOrder(order)"
            [ngClass]="{'corredora-1888': order.broke==2, 'is-new': getIsNew(order)}"
            [isControlPanel]="true"
            [conditionTypeSelected]="conditionTypeSelected"
            [order]="order" [viewSelected]="viewSelected" [optionsMenu]="getOptionsMenu()"
            [showCheckbox]="isSelectedInEraser()" [isChecked]="isOrderInOrderList(order)"
            [showActionsCP]="isStatusAutorizated(order)"
            [showBuyer]="DIVISION_TYPES.BUY_COMMODITIES != divisionTypeSelected"
            (optionMenu)="selectOptionMenu($event.event, $event.order)"
            
            [isHaveIndicator]="haveIndicators(order)" 
            [directionArrow]="getArrowDirection(order._id)"
            (arrow)="clickInArrow($event.order, $event.event)" 
            
            ></tr>

            <tr class="indicators" *ngIf="haveIndicators(order) && isIdInArrayParent(order._id)">
                <td class="first-col-indicators"></td>
            
                <td colspan="14">
                    <div class="indicators-container">
                        <app-indicators-table [price]="order.price" [showFirstColumn]="false"></app-indicators-table>
                    </div>
                </td>
            </tr>
            
            </ng-container>
        </tbody>
    </table>
</div>