import {Injectable} from '@angular/core';
import { exchangeInitialState, EXCHANGE_STATUS_CONST, orderExchangeInitialState } from './const/exchange-orders';
import { Exchange, OrderExchange } from "./interfaces/order-exchange";
import { User } from './interfaces/user.interface';
import { OrderValidator } from "./order";
import { ExchangeService } from './services/exchange.service';
import { getBuyerExchange } from './functions-ibm/contract.checker';
import { Order } from './interfaces/order.interface';
import { PROFORMAS_EXCHANGE } from './dict/ibm/proformas.exchange';
import { getWayPayStruct, getWayPayText, getWayPayWithProforms } from './way-pay';
import { SellerObject, sellerWithData } from './dict/seller-with-data';
import { typeCoin } from './dict/dict';
import { formatNumber, parseFloatPoint } from './validator.checker';
import { getBuyerCodeBySellerCode } from './business-validation';
import { ToFixValidator } from './to-fix';
import { typeCoins } from './const/prices.const';
import { EXCHANGER_STATES } from './dict/exchange';
import { SYNGENTA_NIDERA_AGREED_DISCOUNT } from './dict/syngenta';
import { ResponseRequest } from './interfaces/options.interface';

@Injectable({
    providedIn: 'root',
  })
export class OrderExchangeValidator {

  constructor(private toFixV:ToFixValidator, private orderV:OrderValidator, 
    private exchangeSvc:ExchangeService){}

  /**
   * Returns true if the orderExchange has an incomplete field.
   * @param order The orderExchange to check for incompleteness.
   * @returns boolean
   */
  isOrderIncomplete(order: OrderExchange): boolean {
    return isExchangeIncomplete(order?.exchange) || this.orderV.isOrderIncomplete(order);
  }

  isExchangeIncomplete(exchange: Exchange){
    return isExchangeIncomplete(exchange);
  }

  /**
   * Given an exchange, it returns true if the price is invalid.
   * The price is invalid if it is greater than 1000 and the type of coin is USD, or if it is less than 1000 and the type of coin is ARS.
   * @param exchange 
   * @returns boolean
   */
  isPriceExchangeInvalid(exchange:Exchange){
    const price=exchange?.price ?? 0;
    const typeC=exchange?.typeCoin ?? '';
    return (price > 1000 && typeC == typeCoins.USD) || (price < 1000 && typeC == typeCoins.ARS)
  }

  /**
   * Save an order in the exchange database, completing some data
   * The third parameter is optional.
   * It is the mother order, which is to update the id of this created one in its idExchange.
   * @param order The order to save in the exchange database.
   * @param user The user who is saving the order.
   * @param orderMother The mother order to update the id of the created order.
   * */
  saveOrder(order:OrderExchange, user:User, orderMother?:Order) {
    let orderToSave: OrderExchange = JSON.parse(JSON.stringify(order));
    orderToSave.dataOfcreation= this.orderV.getDataOfCreation(user);
    orderToSave.exchange.status=EXCHANGE_STATUS_CONST.INCOMPLETO;
    this.exchangeSvc.createOrderExchange(orderToSave).subscribe( (res:ResponseRequest)=>{
      const idExchange=res.data;
      if(orderMother){
        let copyOrderMother=JSON.parse(JSON.stringify(orderMother));
        copyOrderMother.idExchange=this.getIdExchange(copyOrderMother,idExchange);
        this.orderV.onlyUpdateOrder(copyOrderMother, user);
      }
    });
  }

  /**
   * Given an exchange, a user and an exchange order, 
   * if the exchange order does not exist, we set one with the initial values ​​and 
   * if it exists we set it with the previous values.
   * @param exchange 
   * @param user 
   * @param orderExchange 
   */
  saveExchange(exchange:Exchange, user:User, orderExchange?:OrderExchange) {
    let orderToSave= JSON.parse(JSON.stringify(!orderExchange? orderExchangeInitialState: orderExchange ));
    orderToSave.exchange=exchange;
    this.saveOrder(orderToSave, user);

    //Revisar bien si acepta orderExchange
  }

  /**
   * Given a redemption order structure, it edits it in the database, taking its id
   * @param order The order to edit in the database.
   * @param user The user who is editing the order.
   * */
  updateOrderExchange(order:OrderExchange){
    this.exchangeSvc.updateOrderExchange(order, order._id).subscribe(res=>{});
  }

  /**
   * Given an Exchange type structure, it returns true in case the status is Verify
   * @param exchange The exchange to check if the status is Verify.
   * @returns boolean - True if the status is Verify, false otherwise.
   * */
  isStatusVerify(exchange:Exchange){
    return exchange.status==EXCHANGE_STATUS_CONST.VERIFICAR;
  }

  /**
   * Given an order and an Exchange, insert the exchange in the exchange field of the order
   * @param order The order to insert the exchange.
   * @param exchange The exchange to insert in the order.
   * @returns OrderExchange - The order with the exchange inserted.
   * */
  setExchangeInOrder(order:OrderExchange, exchange:Exchange){
    let orderToReturn:OrderExchange=JSON.parse(JSON.stringify(order));
    orderToReturn.exchange=exchange;
    return orderToReturn;
  }

  /**
   * Given a swap order, obtain its _id and delete it from the DB
   * @param order The order to delete from the DB.
   */
  deleteExchange(order:OrderExchange){
    this.exchangeSvc.deleteOrderExchange(order._id).subscribe(res=>{});
  }

  // ------------- GETS ------------- \\
  getSellerOfExchange(order:OrderExchange){
    const SELLERS:SellerObject=sellerWithData
    return SELLERS[order?.exchange?.buyer?.codeS]?.nombre ?? '--';
  }

  getExchangeStatus(order:OrderExchange){
    const STATUS:any=EXCHANGER_STATES;
    return STATUS[order?.exchange?.status] ?? '--';
  }

  getContractNumber(order:OrderExchange){
    return order?.exchange?.contractNumber>0? order.exchange.contractNumber: '--';
  }

  getTypeCoin(order:OrderExchange){
    const TYPE_COIN:any=typeCoin
    return TYPE_COIN[order?.exchange?.typeCoin] ?? '--';
  }

  getPrice(order:OrderExchange){
    return order?.exchange?.price>0? formatNumber(order.exchange.price):'--';
  }

  getTons(order:OrderExchange){
    return order?.exchange?.tons >0 ? parseFloatPoint(order.exchange.tons): '--';
  }

  /*getWayPay(order:OrderExchange){
    return getWayPayText(order.exchange.wayPay)
  }*/

  getBonification(exchange:Exchange){
    return exchange?.amountB > 0 ? `${exchange.amountB} $` 
    : exchange?.percentageB > 0  ? `${exchange.percentageB} %` 
    : '--';
  }

  getIdExchange(order:OrderExchange, idExchange:string){
    try{
      if(order.idExchange == '--' || !order.idExchange || order.idExchange == ' '){
        return idExchange;
      } else if (order.idExchange.length>0){
        return order.idExchange + '/'+idExchange;
      }
    } catch(err){}
    return idExchange;
  }

  getContraparts(idMother:string){
    return this.exchangeSvc.getContraparts(idMother);
  }

  /**
   * Given a buyer code and a business type, returns the values that that business has.
   * N: Negocio a precio
   * S: Negocio a fijar
   * @param code - The buyer code to get the proforms.
   * @param isToPrice - Flag indicating if the price is to be fixed.
   * @returns The proforms of the buyer code and business type.
   * */
  getProforms(code:number, isToPrice:boolean){
    let businessTypeString:string= isToPrice? 'N':'S';
    let preforms=PROFORMAS_EXCHANGE;
    let preform=preforms.find((preform:any)=>{
      return preform.comprador==code && preform.tipoNeg==businessTypeString;
    });
    return preform;
  }

  /**
   * Get parent order from a swap order
   * @param {OrderExchange} order - The swap order from which to get the parent order.
   * @returns {OrderExchange} The parent order of the given swap order.
   **/
  getParentOrder(order:OrderExchange){
    let copyOrder:OrderExchange=JSON.parse(JSON.stringify(order));
    copyOrder.sellerData=order.exchange.buyer;
    copyOrder.placeOfOrigin=order.exchange.placeOfOrigin;
    copyOrder.tons=order.exchange.tons;
    copyOrder.price.price=order.exchange.price;
    copyOrder.price.typeCoin=order.exchange.typeCoin;
    copyOrder.howSell=order.exchange.howSell;
    copyOrder.contractNumber=order.exchange.contractNumber;
    copyOrder.price.wayPay=getWayPayStruct(order.exchange.codeWayPay, order.exchange.daysWayPay, order.price.wayPay);
    copyOrder.idExchange='--'
    copyOrder.exchange=this.getExchangeInitial(copyOrder);
    copyOrder.exchange.isMotherCounterpart=1;
    return copyOrder;
  }

  /**
   * Given an order, take the initial data to form an exchange consideration.
   * @param {Order} order - The order from which to form the initial exchange data.
   * @returns {Exchange} The initial exchange data formed from the order.
  */
  getExchangeInitial(order:Order):Exchange{
    const isToPrice=!this.toFixV.orderHaveIndicators(order);
    const codeB= getBuyerExchange(order);
    const getProforms= this.getProforms(codeB,isToPrice); 
    const codeS= order.sellerData.codeS;
    const subSyng= order.price.businessParticularities.subdivisionSyngenta;
    const isNidera = this.orderV.isSyngentaNidera(codeS, subSyng);

    let newExchange:Exchange=JSON.parse(JSON.stringify(exchangeInitialState));
    newExchange.idMother=order._id; //Id de la orden.
    newExchange.tons=order.tons; //Toneladas de la orden.
    newExchange.placeOfOrigin=order.placeOfOrigin; //Lugar de entrega de la orden.
    newExchange.typeCoin= isToPrice? order.price.typeCoin: '--'; //Tipo de moneda de la orden. 
    newExchange.agreedDiscount= isNidera? SYNGENTA_NIDERA_AGREED_DISCOUNT: getProforms? Number(getProforms.descConv.replace(',','.')): 0;;//Proformas del comprador.
    newExchange.priceDiscount=getProforms? Number(getProforms.descPrecio.replace(',','.')): 0;;  //Proformas del comprador.

    newExchange.codeWayPay=getWayPayWithProforms(getProforms? Number(getProforms.pago.replace(',','.')): 0) ; //Proformas del comprador
    newExchange.daysWayPay=getProforms? Number(getProforms.diasPago.replace(',','.')): 0; //Proformas del comprador
    newExchange.wayPay=getWayPayStruct(newExchange.codeWayPay, newExchange.daysWayPay, order.price.wayPay); //Armamos nuestra estructura de way pay
    newExchange.price= isToPrice? getNewPriceWithDiscounts(order.price.price, newExchange.priceDiscount): 0; //Aplicamos priceDiscount al precio de la orden
    return newExchange;
  }

  /**
  * Given an exchange, code of the seller, and a flag indicating if the price is to be fixed, 
  * it returns a copy of the exchange with updated proformas data. 
  * @param {Exchange} exchange - The exchange to update with proformas data.
  * @param {number} codeSeller - The seller code to get the buyer code.
  * @param {boolean} isToPrice - Flag indicating if the price is to be fixed.
  * @returns {Exchange} A copy of the exchange with updated proformas data.
  */
  getProformsOfExchange(exchange:Exchange, codeSeller:number, isToPrice:boolean){
    let codeB= getBuyerCodeBySellerCode(codeSeller);
    let copyExchange=JSON.parse(JSON.stringify(exchange));
    let getProforms= this.getProforms(codeB, isToPrice); 
    //let agreedDiscount= getProforms? Number(getProforms.descConv.replace(',','.')): -1;
    let priceDiscount= getProforms? Number(getProforms.descPrecio.replace(',','.')): -1;
    const priceOfExchange=exchange.price > 0 && isToPrice? exchange.price: 0;

    //copyExchange.agreedDiscount=agreedDiscount>0? agreedDiscount: -1;//Proformas del comprador.

    //El descuento convenido no se asigna puesto que en la sección de generar orden, se asigna el descuento convenido default.

    copyExchange.priceDiscount=priceDiscount>0? priceDiscount: -1;  //Proformas del comprador.
    copyExchange.codeWayPay=getWayPayWithProforms(getProforms? Number(getProforms.pago.replace(',','.')): -1); //Proformas del comprador

    copyExchange.daysWayPay=getProforms? Number(getProforms.diasPago.replace(',','.')): -1; //Proformas del comprador
    copyExchange.price= isToPrice? getNewPriceWithDiscounts(priceOfExchange, copyExchange.priceDiscount): -1 //Aplicamos priceDiscount al precio de la orden
    return copyExchange;
  }

  /**
   * Given an exchange order, the selling commission of the exchange is returned.
   * @param {Order} order - The exchange order for which the commission is calculated.
   * @returns {Commission} The calculated selling commission for the given order.
   */
  getCommisionSellerInExchange(order:OrderExchange){
    return this.orderV.getCommisionByOrder(JSON.parse(JSON.stringify(order)), 'V').seller;
  }
}

/**
 * Given a price and a discount, it is applied if the discount is greater than 0
 * The priceDiscount is only applied to the price if and only if it is greater than 0
 * @param price business price
 * @param priceDiscount discount to apply
 * @returns 
 */
export function getNewPriceWithDiscounts(price:number, priceDiscount:number){
  return priceDiscount>0? price - (price * priceDiscount/100) : price;
}

/**
 * Given an exchange struct, it returns true if it has an incomplete field.
 * Only 3 are analyzed because the others may be incomplete.
 * @param exchange 
 * @returns boolean
 */
export function isExchangeIncomplete(exchange: Exchange|null){
  return !exchange || 
    exchange.placeOfOrigin.afip <= 0 || 
    exchange.howSell <= 0 || 
    exchange.buyer.codeS <= 0;
}

/**
 * Given an array of orders of exchanges. Performs a summation of all the values stored in each 'tons' field
 * @param orders Array of orders of exchanges
 * @returns The sum of all the values stored in each 'tons' field
 */
export function getSumOfTonsExchange(orders: OrderExchange[]) {
  let sum = 0;
  for (const order of orders) {
    sum += order.exchange.tons ?? 0;
  }  
  return parseFloat(sum.toFixed(3)); // Retorna la sumatoria con un máximo de 3 decimales
}
