import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogEraserCreate } from './dialog';

@NgModule({
  declarations: [
    DialogEraserCreate
  ], imports: [
    CommonModule
  ], exports: [
    DialogEraserCreate
  ]
})
export class DialogEraserCreateModule { }
