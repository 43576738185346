import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { EraserTableComponent } from '../eraser-table.component';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';

@Component({
  selector: 'app-comercial',
  templateUrl: './comercial.component.html',
  styleUrls: ['../eraser-table.component.scss',
    './../../../../../shared/styles/board.scss']
})
export class ComercialComponent extends EraserTableComponent {

  constructor(store: Store<appState>, router: Router, dialog: MatDialog, date: DateChecker, orderV: OrderValidator, private toFixV:ToFixValidator) {
    super(store, router, dialog, date, orderV);
    this.rememberEraserOrdersOfLocalStorage();
  }

  editOrder(order: Order) {
    this.store.dispatch(setOrderToGenerate({ order: order }));
    this.router.navigate(["/" + NAVEGATION.GENERATE_MANUAL_ORDER]);
  }

  arrayOfShowParents:any[]=[];
  /*Listens to the click event on a date and is passed a Price as a parameter.
  If the price has indicators, a dropDown is performed for the conditions to be set.
  If the price does not have indicators, the dropDown is for the variants of the condition.*/
  clickInArrow(element:Order, event:any){
    const id=element._id;
    this.isIdInArrayParent(id)? this.arrayOfShowParents.splice(this.arrayOfShowParents.indexOf(id),1): this.arrayOfShowParents.push(id);
  }

  isIdInArrayParent(id:string){
    return this.arrayOfShowParents.indexOf(id) != -1;
  }

  getArrowDirection(id:string):string{
    return getArrowDirectionByBool(this.isIdInArrayParent(id))
  }

  haveIndicators(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  selectOptionMenu(event:any, order:Order){
    switch(event.action){
      case OPTION.EDIT: this.editOrder(order); break;
      case OPTION.SEND: this.sendOrder(order); break;
      case OPTION.DELETE: this.deleteOrder(order); break;
      case OPTION.DETAILS: this.showOrderDetail(order); break;
    }
  }

  getOptionsMenu():OptionMenu[]{
    return [
      {action: OPTION.EDIT, name:'Editar orden'},
      {action: OPTION.SEND, name:'Enviar al operador'},
      {action: OPTION.DELETE, name:'Eliminar orden'},
    ];
  }


}
