import { Component, OnInit,OnDestroy,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setLocation } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { SessionChecker } from 'src/app/shared/session.checker';
import { TablesIBMClass } from 'src/app/shared/tables-ibm';

@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['../../shared/styles/body.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class HistoricalPageComponent extends TablesIBMClass implements OnInit {

  constructor(private store: Store<appState>, private checker: SessionChecker, maestrosSvc:MaestrosService, private cdr:ChangeDetectorRef) {
    super(maestrosSvc)
      
    this.checker.rememberAndActualizateUser(this.store);

    this.store.dispatch(setLocation({location: NAVEGATION.HISTORICAL}));
  }

  ngOnInit(): void {
    this.checkTables();
  }
}