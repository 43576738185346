import { Component, OnInit,Input, OnChanges, SimpleChanges, Output,EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { conditionType, fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { GROUPING_CODE_TO_FIX, GROUPING_CODE_TO_PRICE } from 'src/app/shared/dict/grouping-code';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../fields.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-grouper-code',
  templateUrl: './grouper-code.component.html',
  styleUrls: ['../fields.component.scss']
})
export class GrouperCodeComponent extends Field implements OnInit, OnChanges {
  @Input() valueToSet:number=0;
  //@Input() isToFix: string = ''; //Para saber si las ordenes seleccionada son a precio o a fijar.
  private conditionType:string=''; //Para saber si las ordenes seleccionada son a precio o a fijar.
  
  @Output() grouperEvent = new EventEmitter<number>();

  private isToFix$!:Subscription;
  constructor(store: Store<appState>) {
    super(store);

    this.isToFix$=this.store.select('options','board','conditionTypeSelected').subscribe((condition)=>{
      this.conditionType=condition;
      this.actualizateStruct();
    });
  }

  actualizateStruct(){
    const STRUCT= this.conditionType==conditionType.TO_FIX? GROUPING_CODE_TO_FIX: 
    this.conditionType==conditionType.TO_PRICE? GROUPING_CODE_TO_PRICE: {};

    this.array = Object.keys(STRUCT);
    //this.array.shift();
    this.OBJECT=STRUCT;
    this.deleteLastValueInArray();
  }

  ngOnChanges(changes: SimpleChanges) {
    /*if (changes["isToFix"]) {
      const STRUCT= this.isToFix==conditionType.TO_FIX? GROUPING_CODE_TO_FIX: this.isToFix==conditionType.TO_PRICE? GROUPING_CODE_TO_PRICE: {};
      this.array = Object.keys(STRUCT);
      //this.array.shift();
      this.OBJECT=STRUCT;
      this.deleteLastValueInArray();
    }*/
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.grouperCode;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.grouperCode;
    }

    setTimeout(()=>{
      this.rememberValueInForm();
    },400)
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.isToFix$?.unsubscribe();
  }

  override rememberValueInForm(){
    if(this.fieldIdentificator != fieldType.ADD_BUYER){
      this.rememberValueTypeTwo();
    } else{
      if(this.valueToSet>0){
        this.elementSelected= this.valueToSet;
        this.placeHolder='';
        //this.search=this.OBJECT[this.valueToSet];
        this.setIsOptionSelected(true);
      }
    }
  }

  override setElement(event: any, index: number) {
    let valueToSave = Number(event);

    if(this.fieldIdentificator != fieldType.ADD_BUYER){
      this.setElementToGenerate(valueToSave);
      this.changeOptionSelected();
      this.changeShowOptions();
    } else{
      this.emit(valueToSave); //Emitimos un evento
      this.valueToSet=valueToSave;
      this.elementSelected = valueToSave;
      this.setIsOptionSelected(true);
      this.setShowOptions(false);
    }
  }

  emit(value:number){
    this.grouperEvent.emit(value);
  }

}
