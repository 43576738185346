import { Component, Input } from '@angular/core';

@Component({
  selector: 'autorizate-svg',
  templateUrl: './autorizate.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class AutorizateSvgComponent {
  @Input() color: string='';
  @Input() hover: boolean=false;
  constructor() { }

}
