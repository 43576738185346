import { Component, Input } from '@angular/core';

@Component({
  selector: 'edit-svg',
  templateUrl: './edit.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class EditOptionSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  constructor() { }

}
