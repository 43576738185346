import { AfterContentInit, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { DateChecker, dateByBusinessDays, getDateEndOfMonthBusinessDay } from 'src/app/shared/date.checker';
import { businessDict } from 'src/app/shared/dict/typeBusiness';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { Field, isPriceOrExchange } from '../../fields.component';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { orderTags } from 'src/app/shared/const/orders';

@Component({
  selector: 'app-way-to-pay',
  templateUrl: './way-to-pay.component.html',
  styleUrls: ['./../../fields.component.scss', './way-to-pay.component.scss'],
})
export class WayToPayComponent extends Field implements AfterContentInit {
  @Input() wayPay: any;
  @Input() price!: Price;
  isInputDate: boolean = false;
  isBusinessDays: boolean = true;
  options:number=0;
  @Input() override identifyClassName: string = '';
  //Specifies the direction of the selection's arrow
  //and is used to determine whether it is open or closed.
  public borderGreen: string = '';

  public WAY_PAY_CONST=wayPayNames;
  constructor(
    store: Store<appState>,
    private date: DateChecker) {
    super(store);
  }

  ngAfterContentInit(): void {
    if (isPriceOrExchange(this.fieldIdentificator)) {
      this.tag = priceTags.wayPay;
      this.subTag = '';
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.wayPay;
      this.thirdTag = '';
    }

    //this.cdRef.detectChanges();
  }

  private invertArrow() {
    this.arrowDirection== getArrowDirectionByBool(this.arrowDirection=='down')
  }

  private setBorder() {
    this.borderGreen= this.borderGreen=='' ? 'border-green' : '';
  }

  invertVariables() {
    this.setBorder();
    this.invertArrow();
  }

  setBusinessDays(value: boolean): void {
    this.isBusinessDays = value;

    this.setTag(priceTags.wayPayIsBusinessDays);
    this.setElementToGenerate(this.isBusinessDays ? 1 : -1);

    if (this.wayPay.wayPayName != wayPayNames.contraEntrega &&
      this.wayPay.wayPayName != wayPayNames.conCartaGarantia && 
      this.wayPay.wayPayName != wayPayNames.diferidoPesificacion &&
      this.wayPay.wayPayName != wayPayNames.diferidoFacturacion &&
      this.wayPay.wayPayName != wayPayNames.contraFixMercaderiaEntregada &&
      this.wayPay.wayPayName != wayPayNames.contraFixCartaGarantia) {
      //Si es distinto a contra entrega, con carta de garantía y las formas de pago a fijar, calculamos los días
      this.setTag(priceTags.wayPayExpiration);
      let newDateToSet = dateByBusinessDays(
        this.price.deliveryPeriod.endDate,
        this.wayPay.days,
        this.isBusinessDays
      );

      this.setElementToGenerate(newDateToSet);
    }
  }

  setPercentage(event: any): void {
    this.setTag(priceTags.wayPayPercentage);
    const percentage=Number(event.target.value);
    this.setElementToGenerate(percentage);

    //Si es con carta de garantía en dolares, y el porcentaje en 97,5. Se borra el vencimiento, sino se agrega
    if(this.wayPay.wayPayName==wayPayNames.conCartaGarantia && this.getPrice().typeCoin==typeCoins.USD){
      this.setTag(priceTags.wayPayExpiration);
      let newExpiration='--'
      if(percentage!=97.5){
        newExpiration = dateByBusinessDays(this.date.getDate(), this.wayPay.days, this.wayPay.isBusinessDays==1);
      }
      this.setElementToGenerate(newExpiration);
    }
  }

  setExpiration(event: any): void {
    this.setTag(priceTags.wayPayExpiration);
    this.setElementToGenerate(this.getDate(event));
  }

  setDays(event: any): void {
    this.setTag(priceTags.wayPayDays);
    const days=Number(event.target.value);
    this.setElementToGenerate(Number(event.target.value));
    let wayPay=this.wayPay;
    let typeCoin=this.getPrice().typeCoin;
    let typeBusiness=this.getPrice().typeBusiness;
    let isBusinessDays=wayPay.isBusinessDays==1;
    let deliveryPeriod= this.getPrice().deliveryPeriod;
    let newExpiration='';
    if (wayPay.wayPayName == wayPayNames.conCartaGarantia) {
      if (typeCoin == typeCoins.ARS || (typeCoin == typeCoins.USD && wayPay.percentage!=97.5)) {
        //Por default 5 días hábiles o corridos del día posterior o sino XX días de selección
        newExpiration = dateByBusinessDays(this.date.getDate(), days, isBusinessDays);
      }
    } else if (wayPay.wayPayName == wayPayNames.fechaFija) {
      if (typeBusiness == businessDict.conDescarga || typeBusiness == businessDict.depositos) {
        newExpiration = dateByBusinessDays(this.date.getDate(), days, isBusinessDays);
        //Por default 5 días hábiles o corridos del día posterior o sino se coloca
        //el vencimiento manual - la mercadería tiene que estar entregada para cobrar"
      } else if (typeBusiness == businessDict.forward) {
        //Último día hábil del mes de entrega
        newExpiration = getDateEndOfMonthBusinessDay(deliveryPeriod.endDate);
      }
    } else if (wayPay.wayPayName == wayPayNames.diferidoPesificacion) {
      //newExpiration = dateByBusinessDays(this.date.getDate(), days, true);

      //Por default 5 días hábiles
    } else if (wayPay.wayPayName == wayPayNames.diferidoFacturacion) {
      //Por default 10 días hábiles del día posterior o sino XX días de selección
      //newExpiration = dateByBusinessDays(this.date.getDate(), days, isBusinessDays);
    }

    if(newExpiration){
      this.setTag(priceTags.wayPayExpiration);
      this.setElementToGenerate(newExpiration);
    }
  }

  getDate(date: string) {
    const dateSplit = date.split('-');
    return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
  }
}
