<section class="accumulated-tonnage">
    <div class="title-and-select">
        <h3 class="title">Tonelaje acumulado</h3>
        <select>
            <option>A precio</option>
            <!--<option>A fijar</option>-->
        </select>
    </div>

    <span class="info-loaded" *ngIf="!accumulatedTonnages">Cargando...</span>
    <span class="info-loaded" *ngIf="dateOfLatestConsultation">
        Ultima actualización el {{dateOfLatestConsultation.toLocaleDateString()}} a las {{getHour(dateOfLatestConsultation)}}</span>

    <div class="acordions">
        <mat-accordion class="acordion" *ngFor="let accumulatedTonnage of accumulatedTonnages;">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="items-header row-between">
                            <div class="row-between gap-8">
                                <img class="logo-product"
                                src="../../../../../assets/images/products/{{PRODUCTS[accumulatedTonnage.productName]}}.svg" />
                                <span class="product-name">{{PRODUCTS[accumulatedTonnage.productName]}}</span>
                            </div>
                            
                            <span class="product-tons">{{accumulatedTonnage.totalTons | numberPipe}} Tn</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tabla">
                    <div class="col col1">
                        <span class="cell title-col">Cosecha</span>
                        <span class="cell total-tons">Total Tn</span>
                        <span class="cell pesos">Pesos</span>
                        <span class="cell dolares">Dólares</span>
                    </div>
                    <div class="col col2" *ngFor="let harvest of accumulatedTonnage.harvestsList">
                        <span class="cell title-col">{{harvest.fecha}}</span>
                        <span class="cell total-tons"> {{harvest.totalTons | numberPipe}}</span>
                        <span class="cell pesos">{{harvest.totalArs | numberPipe}}</span>
                        <span class="cell dolares">{{harvest.totalUsd | numberPipe}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>