import { Component, Input, OnInit } from '@angular/core';
import { DeliveryPeriod, Price, RangeDate } from 'src/app/shared/interfaces/price.interface';
import { Field } from '../fields.component';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { DateChecker, getDateTomorrow, getEndOfMonthByMonth, getNextMonth, isEarlierDate } from 'src/app/shared/date.checker';
import { fieldType } from 'src/app/shared/const/options';
import { priceTags } from 'src/app/shared/const/prices.const';
import { orderTags } from 'src/app/shared/const/orders';
import { getSinEspacioDefault, getTradicionalDefault, priceHaveIndicators } from 'src/app/shared/to-fix';
import { CONDITIONS_TYPE_TO_FIX_CONST, priceTagsToFix } from 'src/app/shared/const/to-fix';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';

@Component({
  selector: 'app-delivery-period',
  templateUrl: './delivery-period.component.html',
  styleUrls: ['./../fields.component.scss', './delivery-period.component.scss'],
})
export class DeliveryPeriodComponent extends Field implements OnInit {
  @Input() isGenerateToFix:boolean=false;
  @Input() showCross:boolean=true;
  public messageError: string = '';
  public showValidUntil:boolean=false
  public validUntil:string='';

  constructor(store: Store<appState>,private date: DateChecker) {
    super(store);
  }
  
  ngOnInit(): void {
    if (this.fieldIdentificator == fieldType.PRICE) {
      this.tag = priceTags.deliveryPeriod;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.deliveryPeriod;
    }
  }

  changeValidUntil(){
    this.showValidUntil=!this.showValidUntil;
  }

  getStartDate(){
    return this.getPrice()?.deliveryPeriod?.startDate;
  }
  
  getEndDate(){
    return this.getPrice()?.deliveryPeriod?.endDate;
  }

  getDateMin(){
    return getDateTomorrow(this.getEndDate())
  }

  setValidUntil(date:string){
    const dateSplt=date.split('-');
    const day=getEndOfMonthByMonth(Number(dateSplt[0]), Number(dateSplt[1]));
    const newDate=`${day}-${dateSplt[0]}-${dateSplt[1]}`;


    this.validUntil=date; //Asignamos la fecha seleccionada.

    //Obtenemos el array de subperiodos de entrega.
    const validFor:RangeDate[]= this.getSubPeriods(this.getStartDate(), newDate);
    
    //Ahora debemos eliminar el elemento que sea igual al periodo de entrega
    const deliveryPeriodAux:RangeDate=this.getPrice()?.deliveryPeriod;
    const index=validFor.findIndex((validFor)=>validFor.startDate==deliveryPeriodAux.startDate && validFor.endDate==deliveryPeriodAux.endDate);
    if(index!=-1){ validFor.splice(index,1);}

    //Asignamos el array de subperiodos de entrega al elemento a generar.
    const deliveryPeriod:DeliveryPeriod=this.getPrice()?.deliveryPeriod;
    this.setElementToGenerate({startDate:deliveryPeriod.startDate, endDate:deliveryPeriod.endDate, validFor:validFor});
  }

  /* Dada una fecha de inicio y una de fin.
  Obtenemos todos los subperiodos de entrega que hay en el medio de un mes respetando la siguiente condición:
  - Debe entrar el mes completo. Ejemplo: 1-1-2024 - 31-01-2024.
  - Debe entrar un mes parcial (15, 15). Ejemplo: 15-1-2024 - 15-2-2024.*/
  getSubPeriods(startDate: string, endDate: string): RangeDate[] {
    const subPeriods: RangeDate[] = [];
  
    // Validar las fechas de inicio y fin
    const start = this.date.stringToDate(startDate);
    const end = this.date.stringToDate(endDate);
  
    if (this.isValidRangeDate(start, end)) { throw new Error("Fechas no válidas"); }
  
    // Obtener el mes y año de la fecha de inicio
    let currentMonth = start.getMonth();
    let currentYear = start.getFullYear();
  
    // Iterar hasta alcanzar o superar la fecha de fin
    while (currentYear < end.getFullYear() || (currentYear === end.getFullYear() && currentMonth <= end.getMonth())) {
  
      const subPeriod: RangeDate = this.createRangeDate(currentMonth, currentYear); // Crear el subperíodo
      subPeriods.push(subPeriod); // Agregar el subperíodo al array
      
      const currentYearAux=currentMonth==11?currentYear+1:currentYear;
      const subPeriod2EndDate=`15-${getNextMonth(currentMonth + 1)}-${currentYearAux}`;
      //Primero comparamos que la fecha de fin no supere a la ingresada por el usuario.
      if(isEarlierDate(subPeriod2EndDate, endDate,'-')){
        const subPeriod2: RangeDate = this.createRangeDate(currentMonth, currentYear, true, currentYear!=currentYearAux); // Crear el subperíodo
        subPeriods.push(subPeriod2);
      }

      if (currentMonth === 11) {
        currentMonth = 0;
        currentYear++;
      } else {
        currentMonth++;
      }    
    }
  
    return subPeriods;
  }

  isValidRangeDate(start:Date, end:Date){
    return isNaN(start.getTime()) || isNaN(end.getTime()) || start >= end;
  }

  createRangeDate(currentMonth: number, currentYear: number, isMiddle: boolean = false, withNextYear: boolean = false) {
    const startDate = isMiddle ? `15-${currentMonth + 1}-${currentYear}` : `1-${currentMonth + 1}-${currentYear}`;
    const endDate = isMiddle
      ? `15-${getNextMonth(currentMonth + 1)}-${withNextYear ? currentYear + 1 : currentYear}`
      : `${new Date(currentYear, currentMonth + 1, 0).getDate()}-${currentMonth + 1}-${currentYear}`;
  
    return { startDate, endDate };
  }

  /**
   * Elimina el campo "válido hasta" de la condición de negocio
   * @param 
   * @returns
   */
  deleteValidUntil(){
    this.validUntil='';
    this.setElementToGenerate({startDate:this.getStartDate(), endDate:this.getEndDate(), validFor:[]});
  }

  /* Cada vez que se presione el botón de agregar fecha, se debe agregar la fecha que estaba seleccionada
  al array y vaciar los inputs correspondientes.
  Si no tiene fecha seleccionada, debe mostrar un mensaje de error. */
  addValidFor(){
    const startDate=this.getPrice()?.deliveryPeriod?.startDate;
    const endDate=this.getPrice()?.deliveryPeriod?.endDate;
    if(startDate=='--' || endDate=='--'){
      this.messageError="Para agregar un válido hasta, debes seleccionar un periodo de entrega.";
    } else {
      this.messageError='';
      this.showValidUntil=true;
    }
  }

  /** Para seleccionar una fecha de entrega:
   * - Dará click y se enviará el rango de fechas seleccionado y el indice en el array de la fecha.
   * - Se debe actualizar el array de validFor con la fecha anterior (price.deliveryPeriod) en el indice correspondiente.
   * - La nueva fecha, debe estar en los campos startDate y endDate. 
   * @param dates: Rango de fechas seleccionado.
   * @param index: Indice en el array de validFor.
   * @returns void.
   * */
  selectValidFor(dates:RangeDate, index:number){
    const validFor= JSON.parse(JSON.stringify(this.getValidFor()));
    const price:Price=this.getPrice();
    const deliveryPeriod:RangeDate=price?.deliveryPeriod;
    validFor[index]={startDate:deliveryPeriod.startDate, endDate:deliveryPeriod.endDate};
    
    this.setElementToGenerate({startDate:dates.startDate, endDate:dates.endDate, validFor:validFor});

    //Si tiene indicadores, y:
    // - El tipo de condición es 'sin espacio', volvemos a setear el indicador.
    // - El tipo de condición es tradicional o PORCENTAJE_PRODUCTO y cumplen con ciertos requisitos, volvemos a setear el indicador.
    if(priceHaveIndicators(price) && 
      (price?.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.SIN_ESPACIO || 
       price?.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.TRADICIONAL || 
       price?.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.PORCENTAJE_PRODUCTO )){
      let indicatorsToSave:Indicator[]=[];

      if(price?.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.SIN_ESPACIO){
        indicatorsToSave=[getSinEspacioDefault({startDate: dates.startDate, endDate: dates.endDate}, price.indicators[0])];
      } else if(price?.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.TRADICIONAL || price?.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.PORCENTAJE_PRODUCTO){
        indicatorsToSave=getTradicionalDefault({startDate: dates.startDate, endDate: dates.endDate}, price.indicators);
      }  

      this.setFirstTag(priceTagsToFix.indicators);
      this.setElementToGenerate(indicatorsToSave);
      this.setFirstTag(priceTagsToFix.deliveryPeriod);
    }

  }

  /* Cuando se presiona el botón de eliminar fecha, se debe eliminar la fecha del array de validFor en el indice correspondiente. */
  deleteValidFor(dates:RangeDate, index:number){
    const validFor= JSON.parse(JSON.stringify(this.getValidFor()));
    validFor.splice(index,1);
    const deliveryPeriod=this.getPrice()?.deliveryPeriod;
    this.setElementToGenerate({startDate:deliveryPeriod.startDate ?? '--', endDate:deliveryPeriod.endDate??'--', validFor:validFor});
  }

  /* Obtiene el array de fechas de entrega. */
  getValidFor(){
    return this.getPrice()?.deliveryPeriod?.validFor ?? [];
  }

}
