import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ARetirarComponent } from './a-retirar.component';


@NgModule({
  declarations: [
    ARetirarComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    ARetirarComponent
  ]
})
export class ARetirarModule { }
