import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsSmallComponent } from './details.component';
import { CommissionModule } from 'src/app/components/cards/commission/commission.module';
import { DataModule } from './../data/data.module';
import { DuplicateOrdersModule } from '../../cards/duplicate-orders/duplicate-orders.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { HeaderDetailsModule } from '../header-details/header-details.module';

@NgModule({
  declarations: [
    DetailsSmallComponent
  ],
  imports: [
    CommonModule,
    DataModule,
    CommissionModule,
    DuplicateOrdersModule,
    HeaderDetailsModule,
    PipesModule,
    ArrowSvgModule
  ],
  exports:[
    DetailsSmallComponent
  ]
})
export class DetailsSmallModule { }
