export const chacoLocalities=[
    {"afip":"9","location":"2 De Mayo"},
    {"afip":"122","location":"Agua Buena"},
    {"afip":"275","location":"Aldea Forestal"},
    {"afip":"310","location":"Aleloy"},
    {"afip":"486","location":"Amambay"},
    {"afip":"655","location":"Arbol Solo"},
    {"afip":"846","location":"Arroyo Quintana"},
    {"afip":"935","location":"Avanzada"},
    {"afip":"943","location":"Avia Terai"},
    {"afip":"1052","location":"Bajo Verde"},
    {"afip":"1197","location":"Barranqueras"},
    {"afip":"1257","location":"Barrio Gral Jose De San Martin"},
    {"afip":"1319","location":"Barrio Sarmiento"},
    {"afip":"1343","location":"Basail"},
    {"afip":"1457","location":"Berlin"},
    {"afip":"1515","location":"Bocas"},
    {"afip":"1528","location":"Bolsa Grande"},
    {"afip":"1553","location":"Botija"},
    {"afip":"1639","location":"Cabañaro Pasaje"}
    ,
    {"afip":"1645","location":"Cabeza De Tigre"},
    {"afip":"1663","location":"Cabral"},
    {"afip":"1664","location":"Cabral Cue"},
    {"afip":"1692","location":"Cacui"},
    {"afip":"1757","location":"California"},
    {"afip":"1878","location":"Campo De Galnasi"},
    {"afip":"1879","location":"Campo De La Choza"},
    {"afip":"1901","location":"Campo Echegaray"},
    {"afip":"1902","location":"Campo El Aibal"},
    {"afip":"1905","location":"Campo El Bermejo"},
    {"afip":"1906","location":"Campo El Jacaranda"},
    {"afip":"1910","location":"Campo El Onza"},
    {"afip":"1917","location":"Campo Feldman"},
    {"afip":"1919","location":"Campo Ferrando"},
    {"afip":"1933","location":"Campo Grande"},
    {"afip":"1956","location":"Campo Largo"},
    {"afip":"1958","location":"Campo Las Puertas"},
    {"afip":"1973","location":"Campo Moreno"},
    {"afip":"1976","location":"Campo Nuevo"},
    {"afip":"1979","location":"Campo Overos"},
    {"afip":"2025","location":"Campo Winter"},
    {"afip":"2027","location":"Campo Zapa"},
    {"afip":"2051","location":"Cancha Larga"},
    {"afip":"2141","location":"Capitan Solari"},
    {"afip":"2314","location":"Castellin"},
    {"afip":"2851","location":"Charadai"},
    {"afip":"2853","location":"Charata"},
    {"afip":"2994","location":"Chorotis"},
    {"afip":"3046","location":"Ciervo Petiso"},
    {"afip":"3087","location":"Cnia Agricola Pampa Napenay"},
    {"afip":"3148","location":"Colombia"},
    {"afip":"3170","location":"Colonia Abate"},
    {"afip":"3171","location":"Colonia Aborigen"},
    {"afip":"3172","location":"Colonia Aborigen Chaco"},
    {"afip":"3185","location":"Colonia Alelay"},
    {"afip":"3226","location":"Colonia Bajo Hondo"},
    {"afip":"3229","location":"Colonia Baranda"},
    {"afip":"3235","location":"Colonia Barrera"},
    {"afip":"3248","location":"Colonia Benitez"},
    {"afip":"3250","location":"Colonia Bermejo"},
    {"afip":"3251","location":"Colonia Bernardino Rivadavia"},
    {"afip":"3258","location":"Colonia Blas Parera"},
    {"afip":"3267","location":"Colonia Bravo"},
    {"afip":"3273","location":"Colonia Cabeza De Buey"},
    {"afip":"3274","location":"Colonia Cabral"},
    {"afip":"3311","location":"Colonia Codutti"},
    {"afip":"3315","location":"Colonia Coronel Brandsen"},
    {"afip":"3316","location":"Colonia Coronel Dorrego"},
    {"afip":"3324","location":"Colonia Cuero Quemado"},
    {"afip":"3359","location":"Colonia Drydale"},
    {"afip":"3363","location":"Colonia Echegaray"},
    {"afip":"3365","location":"Colonia Economia"},
    {"afip":"3367","location":"Colonia El Aguara"},
    {"afip":"3368","location":"Colonia El Alazan"},
    {"afip":"3377","location":"Colonia El Ciervo"},
    {"afip":"3378","location":"Colonia El Curupi"},
    {"afip":"3381","location":"Colonia El Fiscal"},
    {"afip":"3390","location":"Colonia El Paraisal"},
    {"afip":"3393","location":"Colonia El Pilar"},
    {"afip":"3405","location":"Colonia El Tigre"},
    {"afip":"3408","location":"Colonia El Triangulo"},
    {"afip":"3413","location":"Colonia Elisa"},
    {"afip":"3424","location":"Colonia Esperanza"},
    {"afip":"3442","location":"Colonia Florencia"},
    {"afip":"3447","location":"Colonia Fortuni"},
    {"afip":"3459","location":"Colonia General Necochea"},
    {"afip":"3474","location":"Colonia Gualtieri"},
    {"afip":"3482","location":"Colonia Hamburguesa"},
    {"afip":"3487","location":"Colonia Herrera"},
    {"afip":"3490","location":"Colonia Hipolito Vieytes"},
    {"afip":"3501","location":"Colonia Indigena"},
    {"afip":"3513","location":"Colonia Jose Marmol"},
    {"afip":"3518","location":"Colonia Juan Jose Castelli"},
    {"afip":"3520","location":"Colonia Juan Jose Paso"},
    {"afip":"3521","location":"Colonia Juan Larrea"},
    {"afip":"3522","location":"Colonia Juan Lavalle"},
    {"afip":"3523","location":"Colonia Juan Penco"},
    {"afip":"3535","location":"Colonia La Avanzada"},
    {"afip":"3556","location":"Colonia La Filomena"},
    {"afip":"3558","location":"Colonia La Florida Chica"},
    {"afip":"3559","location":"Colonia La Florida Grande"},
    {"afip":"3569","location":"Colonia La Lola"},
    {"afip":"3574","location":"Colonia La Maria Luisa"},
    {"afip":"3608","location":"Colonia La Tota"},
    {"afip":"3621","location":"Colonia Las Avispas"},
    {"afip":"3648","location":"Colonia Los Ganzos"},
    {"afip":"3667","location":"Colonia Lucinda"},
    {"afip":"3677","location":"Colonia Malgratti"},
    {"afip":"3685","location":"Colonia Mariano Sarratea"},
    {"afip":"3692","location":"Colonia Matheu"},
    {"afip":"3710","location":"Colonia Mixta"},
    {"afip":"3714","location":"Colonia Monte Quemado"},
    {"afip":"3731","location":"Colonia Necochea Sud"},
    {"afip":"3768","location":"Colonia Palmira"},
    {"afip":"3791","location":"Colonia Popular"},
    {"afip":"3800","location":"Colonia Puente Philipon"},
    {"afip":"3802","location":"Colonia Puente Uriburu"},
    {"afip":"3812","location":"Colonia Rio De Oro"},
    {"afip":"3819","location":"Colonia Rodriguez Peña"}
    ,
    {"afip":"3825","location":"Colonia Sabina"},
    {"afip":"3831","location":"Colonia San Antonio"},
    {"afip":"3870","location":"Colonia Santa Elena"},
    {"afip":"3892","location":"Colonia Schmidt"},
    {"afip":"3897","location":"Colonia Siete Arboles"},
    {"afip":"3912","location":"Colonia Tacuari"},
    {"afip":"3931","location":"Colonia Tres Lagunas"},
    {"afip":"3948","location":"Colonia Velez Sarsfield"},
    {"afip":"3958","location":"Colonia Welhers"},
    {"afip":"3974","location":"Colonias Unidas"},
    {"afip":"3984","location":"Comandancia Frias"},
    {"afip":"4008","location":"Concepcion Del Bermejo"},
    {"afip":"4075","location":"Coronel Avalos"},
    {"afip":"4088","location":"Coronel Du Graty"},
    {"afip":"4165","location":"Corzuela"},
    {"afip":"4203","location":"Costa Ine"},
    {"afip":"4223","location":"Cote Lai"},
    {"afip":"4291","location":"Cuatro Arboles"},
    {"afip":"4292","location":"Cuatro Bocas"},
    {"afip":"4348","location":"Curandu"},
    {"afip":"4365","location":"Curva De Novoa"},
    {"afip":"4441","location":"Diez De Mayo"},
    {"afip":"4543","location":"Dos Boliches"},
    {"afip":"4569","location":"Doña Paula"}
    ,
    {"afip":"4607","location":"El 14"},
    {"afip":"4608","location":"El 15"},
    {"afip":"4624","location":"El Aibal"},
    {"afip":"4666","location":"El Asustado"},
    {"afip":"4746","location":"El Campamento"},
    {"afip":"4782","location":"El Catorce"},
    {"afip":"4789","location":"El Cañon"}
    ,
    {"afip":"4898","location":"El Cuadrado"},
    {"afip":"4901","location":"El Cuarenta Y Seis"},
    {"afip":"4910","location":"El Curundu"},
    {"afip":"4916","location":"El Desierto"},
    {"afip":"4919","location":"El Destierro"},
    {"afip":"4951","location":"El Esquinero"},
    {"afip":"4954","location":"El Estero"},
    {"afip":"4999","location":"El Guanaco"},
    {"afip":"5017","location":"El Indio"},
    {"afip":"5042","location":"El Lapacho"},
    {"afip":"5088","location":"El Mirasol"},
    {"afip":"5135","location":"El Obraje"},
    {"afip":"5149","location":"El Oro Blanco"},
    {"afip":"5166","location":"El Palmar"},
    {"afip":"5167","location":"El Palmar  Tres Isletas"},
    {"afip":"5210","location":"El Perdido"},
    {"afip":"5220","location":"El Picaso"},
    {"afip":"5236","location":"El Pintado"},
    {"afip":"5257","location":"El Porongal"},
    {"afip":"5279","location":"El Puca"},
    {"afip":"5288","location":"El Puma"},
    {"afip":"5304","location":"El Raigonal"},
    {"afip":"5313","location":"El Recoveco"},
    {"afip":"5314","location":"El Recovo"},
    {"afip":"5361","location":"El Sauzal"},
    {"afip":"5362","location":"El Sauzalito"},
    {"afip":"5375","location":"El Simbolar"},
    {"afip":"5395","location":"El Tacuruzal"},
    {"afip":"5440","location":"El Tragadero"},
    {"afip":"5448","location":"El Treinta Y Seis"},
    {"afip":"5469","location":"El Valla"},
    {"afip":"5495","location":"El Viscacheral"},
    {"afip":"5511","location":"El Zanjon"},
    {"afip":"5522","location":"El ñandubay"}
    ,
    {"afip":"5585","location":"Enrique Urien"},
    {"afip":"5810","location":"Estancia El Sabalo"},
    {"afip":"5834","location":"Estancia La Aurora"},
    {"afip":"5903","location":"Estancia Loma Alta"},
    {"afip":"6006","location":"Estero Redondo"},
    {"afip":"6028","location":"Ex Fortin Arenales"},
    {"afip":"6029","location":"Ex Fortin Comandante Frias"},
    {"afip":"6030","location":"Ex Fortin Lavalle"},
    {"afip":"6032","location":"Ex Fortin Perez Millan"},
    {"afip":"6034","location":"Ex Fortin Wilde"},
    {"afip":"6035","location":"Ex Fortin Zelava"},
    {"afip":"6036","location":"Ex Fortin Zelaya"},
    {"afip":"6165","location":"Floradora"},
    {"afip":"6179","location":"Fontana"},
    {"afip":"6186","location":"Fortin Aguilar"},
    {"afip":"6196","location":"Fortin Cardoso"},
    {"afip":"6198","location":"Fortin Chaja"},
    {"afip":"6211","location":"Fortin Las Chuñas"}
    ,
    {"afip":"6220","location":"Fortin Potrero"},
    {"afip":"6227","location":"Fortin Totoralita Lugar Histor"},
    {"afip":"6283","location":"Fuerte Esperanza"},
    {"afip":"6310","location":"Gancedo"},
    {"afip":"6312","location":"Gandolfi"},
    {"afip":"6363","location":"General Capdevilla"},
    {"afip":"6367","location":"General Donovan"},
    {"afip":"6379","location":"General Jose De San Martin"},
    {"afip":"6392","location":"General Necochea"},
    {"afip":"6394","location":"General Obligado"},
    {"afip":"6402","location":"General Pinedo"},
    {"afip":"6417","location":"General Vedia"},
    {"afip":"6438","location":"Girasol"},
    {"afip":"6613","location":"Guayaibi"},
    {"afip":"6620","location":"Guaycuru"},
    {"afip":"6681","location":"Haumonia"},
    {"afip":"6693","location":"Hermoso Campo"},
    {"afip":"6730","location":"Hivonnait"},
    {"afip":"6747","location":"Horquilla"},
    {"afip":"6919","location":"Ingeniero Barbet"},
    {"afip":"6980","location":"Invernada"},
    {"afip":"6990","location":"Ipora Guazu"},
    {"afip":"7013","location":"Isla Antequera"},
    {"afip":"7030","location":"Isla Del Cerrito"},
    {"afip":"7090","location":"Itin"},
    {"afip":"7205","location":"Juan Jose Castelli"},
    {"afip":"7464","location":"La Chaco"},
    {"afip":"7482","location":"La China"},
    {"afip":"7484","location":"La Chiquita"},
    {"afip":"7503","location":"La Clotilde"},
    {"afip":"7533","location":"La Costosa"},
    {"afip":"7544","location":"La Cuchilla"},
    {"afip":"7560","location":"La Dificultad"},
    {"afip":"7573","location":"La Economia"},
    {"afip":"7574","location":"La Eduviges"},
    {"afip":"7575","location":"La Elaboradora"},
    {"afip":"7597","location":"La Entrada"},
    {"afip":"7602","location":"La Escondida"},
    {"afip":"7618","location":"La Estacion"},
    {"afip":"7632","location":"La Evangelica"},
    {"afip":"7645","location":"La Fidelidad"},
    {"afip":"7648","location":"La Flecha"},
    {"afip":"7649","location":"La Flojera"},
    {"afip":"7668","location":"La Ganadera"},
    {"afip":"7691","location":"La Gringa"},
    {"afip":"7722","location":"La Ilusion"},
    {"afip":"7729","location":"La Invernada"},
    {"afip":"7768","location":"La Leonesa"},
    {"afip":"7772","location":"La Libertad"},
    {"afip":"7774","location":"La Liguria"},
    {"afip":"7796","location":"La Lucinda"},
    {"afip":"7804","location":"La Magdalena"},
    {"afip":"7837","location":"La Matanza"},
    {"afip":"7842","location":"La Media Luna"},
    {"afip":"7862","location":"La Mora"},
    {"afip":"7881","location":"La Nueva"},
    {"afip":"7886","location":"La Ofelia"},
    {"afip":"7915","location":"La Paloma"},
    {"afip":"7916","location":"La Palometa"},
    {"afip":"7929","location":"La Pastoril"},
    {"afip":"7962","location":"La Pilar"},
    {"afip":"7975","location":"La Pobladora"},
    {"afip":"8029","location":"La Raquel"},
    {"afip":"8072","location":"La Sabana"},
    {"afip":"8078","location":"La Saltarina"},
    {"afip":"8119","location":"La Tambora"},
    {"afip":"8121","location":"La Tapera"},
    {"afip":"8144","location":"La Tranquilidad"},
    {"afip":"8183","location":"La Vicuña"}
    ,
    {"afip":"8187","location":"La Viruela"},
    {"afip":"8207","location":"La ñata"}
    ,
    {"afip":"8253","location":"Laguna Beligay"},
    {"afip":"8279","location":"Laguna Escondida"},
    {"afip":"8288","location":"Laguna Limpia"},
    {"afip":"8298","location":"Laguna Patos"},
    {"afip":"8340","location":"Lapachito"},
    {"afip":"8342","location":"Lapacho"},
    {"afip":"8383","location":"Las Banderas"},
    {"afip":"8394","location":"Las Blancas"},
    {"afip":"8400","location":"Las Breñas"}
    ,
    {"afip":"8415","location":"Las Carpas"},
    {"afip":"8460","location":"Las Cuatro Bocas"},
    {"afip":"8465","location":"Las Cuchillas Cnia J Marmol"},
    {"afip":"8471","location":"Las Delicias"},
    {"afip":"8491","location":"Las Garcitas"},
    {"afip":"8500","location":"Las Golondrinas"},
    {"afip":"8501","location":"Las Golondrinas Sur"},
    {"afip":"8507","location":"Las Hacheras"},
    {"afip":"8538","location":"Las Leonas"},
    {"afip":"8551","location":"Las Maravillas"},
    {"afip":"8569","location":"Las Moreras"},
    {"afip":"8702","location":"Las Vertientes"},
    {"afip":"8825","location":"Liva"},
    {"afip":"8850","location":"Loma Alta"},
    {"afip":"8870","location":"Loma Florida"},
    {"afip":"8936","location":"Lorena"},
    {"afip":"8938","location":"Loro Blanco"},
    {"afip":"8957","location":"Los Algarrobos"},
    {"afip":"8993","location":"Los Barriles"},
    {"afip":"9037","location":"Los Cerritos"},
    {"afip":"9055","location":"Los Chinacos"},
    {"afip":"9122","location":"Los Fortines"},
    {"afip":"9124","location":"Los Frentones"},
    {"afip":"9127","location":"Los Gansos"},
    {"afip":"9140","location":"Los Gualcos"},
    {"afip":"9182","location":"Los Magos"},
    {"afip":"9241","location":"Los Palmares"},
    {"afip":"9277","location":"Los Porongos"},
    {"afip":"9288","location":"Los Quebrachitos"},
    {"afip":"9296","location":"Los Quirquinchos"},
    {"afip":"9360","location":"Los Tigres"},
    {"afip":"9363","location":"Los Tobas"},
    {"afip":"9378","location":"Los Tunales"},
    {"afip":"9469","location":"Machagai"},
    {"afip":"9483","location":"Macomitas"},
    {"afip":"9487","location":"Madre De Dios"},
    {"afip":"9515","location":"Makalle"},
    {"afip":"9526","location":"Malbalaes"},
    {"afip":"9648","location":"Margarita Belen"},
    {"afip":"9675","location":"Maria Sara"},
    {"afip":"9706","location":"Martinez De Hoz"},
    {"afip":"9840","location":"Meson De Fierro"},
    {"afip":"9851","location":"Mieres"},
    {"afip":"9928","location":"Ministro Ramon Gomez"},
    {"afip":"9948","location":"Mision Angelicana"},
    {"afip":"9954","location":"Mision Nueva Pompeya"},
    {"afip":"10008","location":"Molle Marcado"},
    {"afip":"10039","location":"Monte Caseros"},
    {"afip":"10176","location":"Napalpi"},
    {"afip":"10177","location":"Napenay"},
    {"afip":"10266","location":"Ntra Señora De La Concepcion"}
    ,
    {"afip":"10295","location":"Nueva Poblacion"},
    {"afip":"10302","location":"Nueva Union"},
    {"afip":"10305","location":"Nueva York"},
    {"afip":"10329","location":"Obraje La Vicuña"}
    ,
    {"afip":"10504","location":"Palmar Central"},
    {"afip":"10508","location":"Palmar Norte"},
    {"afip":"10525","location":"Palo Marcado"},
    {"afip":"10541","location":"Pampa Aguado"},
    {"afip":"10542","location":"Pampa Aguara"},
    {"afip":"10543","location":"Pampa Alegria"},
    {"afip":"10544","location":"Pampa Alelai"},
    {"afip":"10545","location":"Pampa Almiron"},
    {"afip":"10546","location":"Pampa Alsina"},
    {"afip":"10549","location":"Pampa Avila"},
    {"afip":"10550","location":"Pampa Bandera"},
    {"afip":"10551","location":"Pampa Barrera"},
    {"afip":"10553","location":"Pampa Bolsa"},
    {"afip":"10554","location":"Pampa Borracho"},
    {"afip":"10555","location":"Pampa Brugnoli"},
    {"afip":"10556","location":"Pampa Cabrera"},
    {"afip":"10557","location":"Pampa Cabure"},
    {"afip":"10558","location":"Pampa Castro"},
    {"afip":"10559","location":"Pampa Cejas"},
    {"afip":"10561","location":"Pampa Chica"},
    {"afip":"10564","location":"Pampa Cuvalo"},
    {"afip":"10567","location":"Pampa De Las Flores"},
    {"afip":"10574","location":"Pampa De Los Locos"},
    {"afip":"10580","location":"Pampa Del Cielo"},
    {"afip":"10581","location":"Pampa Del Huevo"},
    {"afip":"10582","location":"Pampa Del Indio"},
    {"afip":"10583","location":"Pampa Del Infierno"},
    {"afip":"10585","location":"Pampa Del Regimiento"},
    {"afip":"10589","location":"Pampa Del Tordillo"},
    {"afip":"10590","location":"Pampa Del Zorro"},
    {"afip":"10591","location":"Pampa Dorotier"},
    {"afip":"10592","location":"Pampa El 11"},
    {"afip":"10593","location":"Pampa El 12"},
    {"afip":"10594","location":"Pampa El Fosforito"},
    {"afip":"10595","location":"Pampa El Mangrullo"},
    {"afip":"10596","location":"Pampa El Mollar"},
    {"afip":"10597","location":"Pampa El Silencio"},
    {"afip":"10599","location":"Pampa Flores"},
    {"afip":"10600","location":"Pampa Florida"},
    {"afip":"10601","location":"Pampa Galpon"},
    {"afip":"10602","location":"Pampa Gamba"},
    {"afip":"10603","location":"Pampa Grande"},
    {"afip":"10604","location":"Pampa Hermosa"},
    {"afip":"10606","location":"Pampa Ipora Guazu"},
    {"afip":"10607","location":"Pampa Juanita"},
    {"afip":"10608","location":"Pampa La Peligrosa"},
    {"afip":"10609","location":"Pampa Landriel"},
    {"afip":"10610","location":"Pampa Larga"},
    {"afip":"10611","location":"Pampa Legua Cuatro"},
    {"afip":"10612","location":"Pampa Loca"},
    {"afip":"10613","location":"Pampa Los Bedogni"},
    {"afip":"10614","location":"Pampa Machete"},
    {"afip":"10617","location":"Pampa Mitre"},
    {"afip":"10620","location":"Pampa Oculta"},
    {"afip":"10622","location":"Pampa Pelado"},
    {"afip":"10623","location":"Pampa Pereyra"},
    {"afip":"10625","location":"Pampa Quimili"},
    {"afip":"10626","location":"Pampa Ralera"},
    {"afip":"10629","location":"Pampa San Martin"},
    {"afip":"10630","location":"Pampa Sommer"},
    {"afip":"10632","location":"Pampa Tolosa Chica"},
    {"afip":"10633","location":"Pampa Tolosa Grande"},
    {"afip":"10634","location":"Pampa Vargas"},
    {"afip":"10635","location":"Pampa Verde"},
    {"afip":"10638","location":"Pampa Villordo"},
    {"afip":"10639","location":"Pampa Virgen"},
    {"afip":"10641","location":"Pampa Zanata"},
    {"afip":"10647","location":"Pampini"},
    {"afip":"10691","location":"Paraje El Colchon"},
    {"afip":"10692","location":"Paraje El Colorado"},
    {"afip":"10700","location":"Paraje Independencia"},
    {"afip":"10707","location":"Paraje Las Tablas"},
    {"afip":"10717","location":"Paraje Ojo De Agua"},
    {"afip":"10726","location":"Paraje Santa Cruz"},
    {"afip":"10734","location":"Paralelo 28"},
    {"afip":"10819","location":"Paso De Los Libres"},
    {"afip":"10846","location":"Paso Del Oso"},
    {"afip":"11054","location":"Picaditas"},
    {"afip":"11150","location":"Pindo"},
    {"afip":"11154","location":"Pinedo Central"},
    {"afip":"11412","location":"Pozo De La Linea"},
    {"afip":"11414","location":"Pozo De La Mula"},
    {"afip":"11417","location":"Pozo De La Pava"},
    {"afip":"11420","location":"Pozo De La Tuna"},
    {"afip":"11424","location":"Pozo De Las Garzas"},
    {"afip":"11433","location":"Pozo De Los Suris"},
    {"afip":"11449","location":"Pozo Del Cincuenta"},
    {"afip":"11454","location":"Pozo Del Gato"},
    {"afip":"11456","location":"Pozo Del Gris"},
    {"afip":"11457","location":"Pozo Del Indio Estacion Fcgb"},
    {"afip":"11463","location":"Pozo Del Molle"},
    {"afip":"11467","location":"Pozo Del Negro"},
    {"afip":"11471","location":"Pozo Del Tala"},
    {"afip":"11472","location":"Pozo Del Tigre"},
    {"afip":"11474","location":"Pozo Del Toro"},
    {"afip":"11478","location":"Pozo El Chañar"}
    ,
    {"afip":"11485","location":"Pozo Hondo"},
    {"afip":"11488","location":"Pozo La Brea"},
    {"afip":"11491","location":"Pozo La Osca"},
    {"afip":"11502","location":"Pozo Navagan"},
    {"afip":"11524","location":"Presidencia De La Plaza"},
    {"afip":"11525","location":"Presidencia Roca"},
    {"afip":"11526","location":"Presidencia Roque Saenz Peña"}
    ,
    {"afip":"11620","location":"Pueblo Clodomiro Diaz"},
    {"afip":"11650","location":"Pueblo Puca"},
    {"afip":"11690","location":"Puente Ine"},
    {"afip":"11697","location":"Puente Palometa"},
    {"afip":"11700","location":"Puente Philippon"},
    {"afip":"11707","location":"Puente Svritz"},
    {"afip":"11723","location":"Puerta De Leon"},
    {"afip":"11750","location":"Puerto Antequera"},
    {"afip":"11758","location":"Puerto Bastiani"},
    {"afip":"11762","location":"Puerto Bermejo"},
    {"afip":"11814","location":"Puerto Las Palmas"},
    {"afip":"11816","location":"Puerto Lavalle"},
    {"afip":"11873","location":"Puerto Tirol"},
    {"afip":"11878","location":"Puerto Urquiza"},
    {"afip":"11881","location":"Puerto Velaz"},
    {"afip":"11883","location":"Puerto Vicentini"},
    {"afip":"11886","location":"Puerto Vilelas"},
    {"afip":"11895","location":"Puerto Zapallar"},
    {"afip":"11918","location":"Puesto Carrizo"},
    {"afip":"11923","location":"Puesto Cocheri"},
    {"afip":"12053","location":"Puesto Mendizabal"},
    {"afip":"12130","location":"Punta De Rieles"},
    {"afip":"12156","location":"Punta Nueva"},
    {"afip":"12160","location":"Punta Rieles"},
    {"afip":"12225","location":"Quia"},
    {"afip":"12276","location":"Quitilipi"},
    {"afip":"12340","location":"Ranchos Viejos"},
    {"afip":"12396","location":"Reduccion De La Cangaye Lugar"},
    {"afip":"12397","location":"Reduccion De San Bernardo El V"},
    {"afip":"12398","location":"Reduccion Napalpi"},
    {"afip":"12432","location":"Resistencia"},
    {"afip":"12527","location":"Rincon Del Zorro"},
    {"afip":"12554","location":"Rio Araza"},
    {"afip":"12558","location":"Rio Bermejo"},
    {"afip":"12584","location":"Rio De Oro"},
    {"afip":"12605","location":"Rio Muerto"},
    {"afip":"12626","location":"Rio Tapenaga"},
    {"afip":"12698","location":"Rosales"},
    {"afip":"12860","location":"Salto De La Vieja"},
    {"afip":"12872","location":"Samuhu"},
    {"afip":"12942","location":"San Eduardo"},
    {"afip":"13057","location":"San Juancito"},
    {"afip":"13154","location":"San Telmo"},
    {"afip":"13177","location":"Santa Agueda"},
    {"afip":"13205","location":"Santa Elvira"},
    {"afip":"13241","location":"Santa Marta"},
    {"afip":"13275","location":"Santa Sylvina"},
    {"afip":"13278","location":"Santa Teresa De Carballo"},
    {"afip":"13403","location":"Selvas Del Rio De Oro"},
    {"afip":"13511","location":"Solalinde"},
    {"afip":"13647","location":"Taco Pozo"},
    {"afip":"13771","location":"Tartagal"},
    {"afip":"13833","location":"Termas Del Cerrito"},
    {"afip":"13864","location":"Timbo"},
    {"afip":"13925","location":"Tolderias"},
    {"afip":"14042","location":"Tres Boliches"},
    {"afip":"14054","location":"Tres Estacas"},
    {"afip":"14060","location":"Tres Horquetas"},
    {"afip":"14061","location":"Tres Isletas"},
    {"afip":"14070","location":"Tres Mojones"},
    {"afip":"14071","location":"Tres Monjes"},
    {"afip":"14073","location":"Tres Naciones"},
    {"afip":"14075","location":"Tres Palmas"},
    {"afip":"14080","location":"Tres Pozos"},
    {"afip":"14138","location":"Tucuru"},
    {"afip":"14212","location":"Urundel"},
    {"afip":"14306","location":"Velaz"},
    {"afip":"14311","location":"Venados Grandes"},
    {"afip":"14315","location":"Venezuela"},
    {"afip":"14346","location":"Vicentini"},
    {"afip":"14390","location":"Villa Alta"},
    {"afip":"14396","location":"Villa Angela"},
    {"afip":"14415","location":"Villa Barberan"},
    {"afip":"14423","location":"Villa Berthet"},
    {"afip":"14523","location":"Villa Dos"},
    {"afip":"14531","location":"Villa El Dorado"},
    {"afip":"14532","location":"Villa El Palmar"},
    {"afip":"14560","location":"Villa Forestacion"},
    {"afip":"14614","location":"Villa Jalon"},
    {"afip":"14616","location":"Villa Juan De Garay"},
    {"afip":"14648","location":"Villa Libertad"},
    {"afip":"14730","location":"Villa Paranacito"},
    {"afip":"14764","location":"Villa Rio Bermejito"},
    {"afip":"14821","location":"Villa Sarmiento"},
    {"afip":"14962","location":"Welhers"},
    {"afip":"14965","location":"Wichi"},
    {"afip":"15032","location":"Yatay"},
    {"afip":"15094","location":"Zaparinqui"},
    {"afip":"15123","location":"Zuberbuhler"},
    {"afip":"15157","location":"9 De Julio"},
    {"afip":"15234","location":"Almirante Brown"},
    {"afip":"15325","location":"Bajo Hondo"},
    {"afip":"15520","location":"Castelli"},
    {"afip":"15559","location":"Cerrito"},
    {"afip":"15774","location":"Coronel Brandsen"},
    {"afip":"15788","location":"Corralito"},
    {"afip":"15910","location":"El Bañado"}
    ,
    {"afip":"15915","location":"El Boqueron"},
    {"afip":"15930","location":"El Cajon"},
    {"afip":"16053","location":"El Espinillo"},
    {"afip":"16174","location":"El Paraiso"},
    {"afip":"16210","location":"El Porvenir"},
    {"afip":"16263","location":"El Quebrachal"},
    {"afip":"16281","location":"El Recreo"},
    {"afip":"16298","location":"El Retiro"},
    {"afip":"16328","location":"El Saladillo"},
    {"afip":"16404","location":"El Totoral"},
    {"afip":"16409","location":"El Triangulo"},
    {"afip":"16440","location":"El Zapallar"},
    {"afip":"16525","location":"Fortin Lavalle"},
    {"afip":"16580","location":"India Muerta"},
    {"afip":"16624","location":"La Aguada"},
    {"afip":"16654","location":"La Argentina"},
    {"afip":"16664","location":"La Armonia"},
    {"afip":"16737","location":"La Cañada"}
    ,
    {"afip":"16764","location":"La Choza"},
    {"afip":"16786","location":"La Colonia"},
    {"afip":"16872","location":"La Esperanza"},
    {"afip":"17007","location":"La Isla"},
    {"afip":"17051","location":"La Loma"},
    {"afip":"17075","location":"La Manuela"},
    {"afip":"17091","location":"La Mascota"},
    {"afip":"17111","location":"La Negra"},
    {"afip":"17166","location":"La Pinta"},
    {"afip":"17189","location":"La Posta"},
    {"afip":"17208","location":"La Providencia"},
    {"afip":"17257","location":"La Rinconada"},
    {"afip":"17280","location":"La Sara"},
    {"afip":"17294","location":"La Soledad"},
    {"afip":"17302","location":"La Suiza"},
    {"afip":"17311","location":"La Tigra"},
    {"afip":"17345","location":"La Verde"},
    {"afip":"17369","location":"La Zanja"},
    {"afip":"17373","location":"Laguna Blanca"},
    {"afip":"17487","location":"Las Cuchillas"},
    {"afip":"17508","location":"Las Flores"},
    {"afip":"17561","location":"Las Lomitas"},
    {"afip":"17576","location":"Las Mercedes"},
    {"afip":"17599","location":"Las Palmas"},
    {"afip":"17629","location":"Las Piedritas"},
    {"afip":"17646","location":"Las Rosas"},
    {"afip":"17673","location":"Las Toscas"},
    {"afip":"17933","location":"Los Pozos"},
    {"afip":"17996","location":"Manantiales"},
    {"afip":"18049","location":"Miraflores"},
    {"afip":"18052","location":"Miramar"},
    {"afip":"18172","location":"Palo Blanco"},
    {"afip":"18265","location":"Pilar"},
    {"afip":"18560","location":"San Agustin"},
    {"afip":"18585","location":"San Antonio"},
    {"afip":"18605","location":"San Bernardo"},
    {"afip":"18616","location":"San Carlos"},
    {"afip":"18659","location":"San Fernando"},
    {"afip":"18743","location":"San Jose"},
    {"afip":"18784","location":"San Lorenzo"},
    {"afip":"18799","location":"San Luis"},
    {"afip":"18813","location":"San Martin"},
    {"afip":"18825","location":"San Miguel"},
    {"afip":"18972","location":"Santa Elena"},
    {"afip":"19019","location":"Santa Maria"},
    {"afip":"19036","location":"Santa Rita"},
    {"afip":"19046","location":"Santa Rosa"},
    {"afip":"19074","location":"Santo Domingo"},
    {"afip":"19095","location":"Santos Lugares"},
    {"afip":"19138","location":"Sol De Mayo"},
    {"afip":"19167","location":"Tacuari"},
    {"afip":"19188","location":"Tigre"},
    {"afip":"19259","location":"Tropezon"},
    {"afip":"19279","location":"Viboras"},
    {"afip":"21425","location":"La Esperanza"},
    {"afip":"21426","location":"La Rinconada"},
    {"afip":"21427","location":"Las Flores"},
    {"afip":"21428","location":"San Agustin"},
    {"afip":"21429","location":"San Antonio"},
    {"afip":"21430","location":"La Cuchilla"},
    {"afip":"21431","location":"Loro Blanco"},
    {"afip":"21432","location":"Pampa Grande"},
    {"afip":"21433","location":"Colonia Esperanza"},
    {"afip":"21434","location":"Colonia San Antonio"},
    {"afip":"21435","location":"El 15"},
    {"afip":"21436","location":"Loma Alta"},
    {"afip":"21437","location":"Los Fortines"},
    {"afip":"21438","location":"Malbalaes"},
    {"afip":"21439","location":"El Palmar"},
    {"afip":"21440","location":"El Palmar"},
    {"afip":"21441","location":"La Matanza"},
    {"afip":"21442","location":"El Palmar"},
    {"afip":"21478","location":"Punta De Rieles"},
    {"afip":"21479","location":"Tres Horquetas"},
    {"afip":"21480","location":"El Palmar"},
    {"afip":"21481","location":"La Esperanza"},
    {"afip":"21482","location":"La Soledad"},
    {"afip":"21483","location":"El Estero"},
    {"afip":"21484","location":"El Triangulo"},
    {"afip":"21485","location":"Pampa Del Cielo"},
    {"afip":"21486","location":"Pampa Hermosa"},
    {"afip":"21487","location":"Pueblo Puca"},
    {"afip":"21691","location":"Hermoso Campo"},
    {"afip":"21692","location":"Juan Jose Castelli"},
    {"afip":"21846","location":"San Martin"},
    {"afip":"21996","location":"El Palmar"},
    {"afip":"21997","location":"Barrio Gral.Jose De San Martin"},
    {"afip":"21998","location":"Comandante Fernandez"},
    {"afip":"21999","location":"Colonia Agraria Pampa De Napenay"},
    {"afip":"22000","location":"Pozo Del Indio"},
    {"afip":"22001","location":"Zuberbuller"},
    {"afip":"22002","location":"Las Viboras"},
    {"afip":"22003","location":"Colonia Unidas"}
    ]