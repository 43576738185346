export const areas = {
    commodities:1,
    comercial:2
}

export const REPRESENTATION_CONST = {
    CORDOBA: 3,
    CHACABUCO:4,
    BOLIVAR:5,
}

export const REPRESENTATION_CONST_ARRAY = [REPRESENTATION_CONST.CORDOBA,REPRESENTATION_CONST.CHACABUCO,REPRESENTATION_CONST.BOLIVAR]