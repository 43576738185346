import { Component, Input } from '@angular/core';

@Component({
  selector: 'check-svg',
  templateUrl: './check.component.html',
  styleUrls: ['./../../svg.scss']
})
export class CheckSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  constructor() { }

}
