import { HostListener, Component, OnInit,OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    loadPricesWithPipesSuccess,
  setArrayOfIdsSelecteds,
  setFilterIndex,
  setHistorical,
} from 'src/app/redux/actions/price.action';
import * as dict from 'src/app/shared/dict/dict';
import { observations } from 'src/app/shared/const/prices.const';
import { Price, PriceString } from 'src/app/shared/interfaces/price.interface';
import { BOARD_TYPES, buyerType, conditionType } from 'src/app/shared/const/options';
import { DateChecker } from 'src/app/shared/date.checker';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { PriceValidator, showSlidePriceDetails } from 'src/app/shared/price';
import { buyers } from 'src/app/shared/dict/buyers';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { getMinHeight } from 'src/app/pages/order-list/body-order-list/order-table/order-table.component';
import { Subscription } from 'rxjs';
import { ToFixValidator, priceHaveIndicators } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { getDaysWayPayText, getIsWayPayDeferred, getIsWayPayVariant, getPercentageWayPay } from 'src/app/shared/way-pay';
import { User } from 'src/app/shared/interfaces/user.interface';
import { areas } from 'src/app/shared/const/user.const';
import { getPriceListWithPipesAndSort } from 'src/app/pages/price-board/price-board.component';
import { ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { createOrderWithPrice } from 'src/app/shared/order';

const OBSERVATIONS_ARRAY = [
  observations.featuredPrice,
  observations.gravanz,
  observations.comment,
];
//This arrangement is very important since a change in any letter means
//that it will not be filtered properly. Therefore, when it is modified,
//it will also have to be modified in
//filters-component -> transformSelectToObservation()
//and filters-component -> setNewPrice()

@Component({
    template:''
})
export class PriceBoard implements OnInit, OnDestroy {
  user!:User;
  isComercial:boolean=false;
  isOperator:boolean=false;

  public parentsObject: any = {};
  public childsObject: any= {};
  public arrayOfShowParents:any =[];

  public wasInside: boolean = false; //Inside in menu option;
  //Array of conditions
  prices: Price[] = [];
  pricesWithPipe:Price[]=[];
  pricesSelected: Price[] = [];
  isViewMobile = true;
  boardSelected: string = '';
  BOARDS_TYPES = BOARD_TYPES;

  //Other filter of tabla
  priceFilter!: PriceString;
  buyerTypeSelected!: string;
  buyerTypes: any;
  CONDITION_TYPES=conditionType;
  conditionTypeSelected!:string;
  conditionFilters:number[]= [];
  search: string = '';

  //Array
  STATUS: any = [];
  BUSINESS_TYPE: any = [];
  PLACE_OF_DELIVERY: any = [];
  QUALITY: any = [];
  TYPE_COIN: any = [];
  WAY_PAY: any = [];
  BONIFICATION: any = [];
  PRODUCTS: any = [];
  OBSERVATIONS: any = [];
  HARVEST: any = [];
  BUYERS: any = [];

  //The column selected to apply a filter
  filterIdentificator: number = -1; //Store of redux

  optionIdentificator: number = -1;
  //The columns that will be seen in the table
  viewSelected!: ViewPrice;

  private pricesAll$!:Subscription;
  private pricesWithPipes$!:Subscription;
  private pricesSelect$!:Subscription;
  private buyerType$!:Subscription;
  private conditionType$!:Subscription;
  private viewSelected$!:Subscription;
  private search$!:Subscription;
  private filter$!:Subscription;
  private priceSubscription!:Subscription;
  private user$!:Subscription;
  private indexFilter$!:Subscription;
  private arrayShowParents$!:Subscription;
  private boardSelect$!:Subscription;
  private conditionFilters$!:Subscription;

  constructor(public store: Store<appState>,public router: Router,
    public date: DateChecker,public priceV: PriceValidator, public toFixV:ToFixValidator, public cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setArrays();
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.setUnsubscriptions();
  }

  setSubscriptions(){
    this.user$=this.store.select('usuario').subscribe(user=>{
      this.user = user;
      this.isComercial = user.area==areas.comercial;
      this.isOperator = user.area == areas.commodities
    })

    this.pricesSelect$=this.store.select('price', 'priceBoard', 'pricesSelected').subscribe((prices) => {
      this.pricesSelected = prices;
    });

    this.buyerType$=this.store.select('price', 'priceBoard', 'buyerType').subscribe((buyerType) => {
      this.buyerTypeSelected = buyerType;
      this.actualizatePriceListWithPipes();
    });

    this.conditionType$=this.store.select('options','board','conditionTypeSelected').subscribe((conditionType) => {
      this.conditionTypeSelected = conditionType;
      this.actualizatePriceListWithPipes();
    });

    this.arrayShowParents$=this.store.select('price', 'priceBoard', 'arrayOfIdsSelecteds').subscribe((arrayOfIdsSelecteds) => {
      this.arrayOfShowParents = arrayOfIdsSelecteds;
    });

    this.indexFilter$=this.store.select('price', 'priceBoard', 'indexFilter').subscribe(indexFilter=>{
      this.filterIdentificator = indexFilter;
    })

    this.viewSelected$=this.store.select('price', 'viewsOfPriceBoard', 'viewSelected').subscribe((viewSelected) => {
      this.viewSelected = viewSelected;
    });
  
      this.search$=this.store.select('options', 'board', 'search').subscribe((search) => {
        this.search = search;
        this.actualizatePriceListWithPipes();
      });
      
  
      this.filter$=this.store.select('price', 'priceBoard', 'filter').subscribe((filter) => {
        this.priceFilter = filter;
        this.actualizatePriceListWithPipes();
      });
      
      this.pricesAll$=this.store.select('price', 'priceBoard', 'all').subscribe((priceAll) => {
        this.prices = priceAll;
        this.actualizatePriceListWithPipes();
      });
  
      this.pricesWithPipes$= this.store.select('price', 'priceBoard', 'allWithPipes').subscribe((pricesWithPipes) => {
        this.pricesWithPipe = pricesWithPipes;
        this.setParentAndChildObject();
        this.refresh();
      });

      this.boardSelect$=this.store.select('options', 'board', 'boardSelected').subscribe((boardSelected) => {
        this.boardSelected = boardSelected;
        this.cdr.detectChanges();
      });

      this.conditionFilters$=this.store.select('options', 'board', 'conditionFilter').subscribe((conditionFilter) => {
        this.conditionFilters = conditionFilter;
        this.actualizatePriceListWithPipes();
      });
  }

  actualizatePriceListWithPipes(){
    this.store.dispatch(loadPricesWithPipesSuccess({prices: 
        getPriceListWithPipesAndSort(this.prices, this.isComercial, this.buyerTypeSelected, this.priceFilter, this.conditionTypeSelected, this.search, this.conditionFilters)}))
  }

  refresh(){
    this.cdr.markForCheck();
  }

  setUnsubscriptions(){
    this.pricesAll$?.unsubscribe();
    this.pricesWithPipes$?.unsubscribe();
    this.viewSelected$?.unsubscribe();
    this.search$?.unsubscribe();
    this.filter$?.unsubscribe();
    this.priceSubscription?.unsubscribe();
    this.user$?.unsubscribe();
    this.indexFilter$?.unsubscribe();

    this.pricesSelect$?.unsubscribe();
    this.buyerType$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.arrayShowParents$?.unsubscribe();
    this.boardSelect$?.unsubscribe();
    this.conditionFilters$?.unsubscribe();
  }

  openModalPrice(price:Price){
    showSlidePriceDetails(this.store,price)
  }

  getIsNew(price: Price) {
    return price.dataOfcreation.hour==this.date.getHour();
  }

  setArrays() {
    this.STATUS = dict.priceState;
    this.BUSINESS_TYPE = typeBusiness;
    this.QUALITY = dict.quality;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.TYPE_COIN = dict.typeCoin;
    this.WAY_PAY = wayPay;
    this.PRODUCTS = PRODUCT_NAMES;
    this.OBSERVATIONS = OBSERVATIONS_ARRAY;
    this.BUYERS = buyers;
    this.HARVEST = this.date.getHarvest();
    this.buyerTypes = buyerType;
  }

  
  //This function will be used to apply the pipes to the array of prices, 
  //then we will go through the array so that the value of each element 
  //is obtained and not have to perform calculations in the re-rendering.
  setParentAndChildObject(){
    const parentsObject:any={};
    const childsObject:any={};

    const quantOfPrices=this.pricesWithPipe.length;

    if(quantOfPrices > 0 && !(this.pricesWithPipe[0].indicators?.length > 0)){
      for(let i=0; i<quantOfPrices-1; i++){
        const price1=this.pricesWithPipe[i]; //Get price
        const price2=this.pricesWithPipe[i+1]; //Get price
        if(isTheSameHeader(price1,price2)){ //If the header is the same
            const isChildId=isChildWithParentObject(price1, parentsObject); //If is child, add in the parent id, if is not child, add in the price1
            
            //Add in the child object
            const indexToSave = isChildId || price1._id;
  
            //If a value exists, a new element is added to the array, if nothing exists, it adds it
            childsObject[price2._id] = indexToSave;
            if (!parentsObject[indexToSave]) {
              parentsObject[indexToSave] = [];
            }
  
            parentsObject[indexToSave].push(price2._id);
        }
      }
    }
    
    this.parentsObject=parentsObject;
    this.childsObject=childsObject;
  }

  trackByPriceId(index: number, price: Price) {
    return price._id;
  }

  createOrderWithPrice(price: Price) {
    createOrderWithPrice(price, this.store, this.router)
  }

  //It is a different function for business users and operators.
  selectFilter(index: number) {
    this.store.dispatch(setFilterIndex({ index: index == this.filterIdentificator? -1:index }));
  }
  
  //MENU - OPTIONS
  openOptions(index: number) {
    if (index == this.optionIdentificator) {
      this.closeOptions();
    } else {
      this.optionIdentificator = index;
      this.wasInside = true;
    }
  }

  showHistorical(price:Price){
    this.store.dispatch(setHistorical({historical: price._id}));
    this.closeOptions();
  }

  closeDeferral(){}

  closeOptions() {
    this.openOptions(-1);
    this.closeDeferral();
    this.wasInside = false;
  }

  haveIndicators(price:Price){
    return priceHaveIndicators(price);
  }

  /*Listens to the click event on a date and is passed a Price as a parameter.
  If the price has indicators, a dropDown is performed for the conditions to be set.
  If the price does not have indicators, the dropDown is for the variants of the condition.*/
  clickInArrow(price:Price, event:any){
    this.dropDown(price._id);
  }

  getProductName(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.priceV.getProductName(price);
  }

  getBusinessType(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.priceV.getBusinessType(price);
  }

  getPlaceOfDelivery(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.priceV.getPlaceOfDelivery(price.placeOfDelivery);
  }

  getDeliveryPeriod(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return price.deliveryPeriod.startDate+' / '+price.deliveryPeriod.endDate;
    return this.priceV.getDeliveryPeriod(price);
  }

  getValidUntilText(price:Price){
    return this.priceV.getValidUntilText(price);
  }

  isValidUntil(price:Price){
    return this.priceV.isValidUntil(price)
  }

  getQuality(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.priceV.getQuality(price);
  }

  getQualityIBM(price:Price){
    return this.priceV.getQualityIBM(price);
  }

  getTypeCoinAndPrice(price:Price){
    return this.priceV.getTypeCoinAndPrice(price);
  }

  getTypeCoinAndPriceWithPoints(price:Price){
    return this.priceV.getTypeCoinAndPriceWithPoints(price);
  }

  getPesification(price:Price){
    return this.priceV.getPesification(price);
  }

  getDaysWayPay(price:Price){
    return getDaysWayPayText(price.wayPay);
  }

  getPercentageWayPay(price:Price){
    return getPercentageWayPay(price.wayPay);
  }

  getOnlyWayPayName(price:Price){
    return this.priceV.getOnlyWayPayName(price);
  }

  getExpiration(price:Price){
    return this.priceV.getExpirationDate(price);
  }

  isFixExpiration(price:Price){
    return this.priceV.isFixExpiration(price);;
  }
  
  getBusinessParticularities(price:Price){
    return this.priceV.getBusinessParticularities(price.businessParticularities);
  }

  @HostListener('document:click', ['$event']) clickInside(event: any) {
    try {
      const classNames: string = event.target.classList.value;
      if (classNames.length > 0) {
        if (!this.wasInside && !classNames.includes('in-menu-option')) {
          this.optionIdentificator = -1;
        }
      }
    } catch (err) {
    }
    this.wasInside = false;
  }

  //Parent function
  isParent(price: Price) {
    return this.parentsObject[price?._id] ?? false;
  }

  isParentDeferral(price: Price) {
    return this.isParent(price) && getIsWayPayDeferred(price?.wayPay?.wayPayName);
  }

  isParentVariant(price: Price) {
    return this.isParent(price) && getIsWayPayVariant(price?.wayPay?.wayPayName);
  }

  isChild(price:Price){
    return this.childsObject[price?._id] ?? false;
  }

  parentShowInDropdown(price:Price, index:number){
    //La función solo es llamada por prices que son Child
    //Get _id of parent
    const parentId=this.childsObject[price._id];    
    return this.isIdInArrayParent(parentId);
  }

  dropDown(id:string){
    if( this.isIdInArrayParent(id) ){    
      const copyArray=JSON.parse(JSON.stringify(this.arrayOfShowParents))
      copyArray.splice(this.arrayOfShowParents.indexOf(id),1);
      this.store.dispatch(setArrayOfIdsSelecteds({ids: copyArray}))
    } else {
      this.store.dispatch(setArrayOfIdsSelecteds({ids: [...this.arrayOfShowParents, id]}))
    }
  }

  isIdInArrayParent(id:string){
    return this.arrayOfShowParents.indexOf(id) != -1;
  }

  getArrowDirection(id:string):string{
    return getArrowDirectionByBool(this.isIdInArrayParent(id));
  }

  getMinHeight(table:any){
    return getMinHeight(table);
  }
}



//Devuelve si dos precios coinciden en sus headers
function isTheSameHeader(price1:Price,price2:Price){
    try {
      const sameHeader =
        price1.productName == price2.productName &&
        price1.typeBusiness == price2.typeBusiness &&
        price1.deliveryPeriod.startDate == price2.deliveryPeriod.startDate &&
        price1.deliveryPeriod.endDate == price2.deliveryPeriod.endDate &&
        price1.quality == price2.quality;
  
      if (price1.placeOfDelivery.zone < 8) {
        return sameHeader && price1.placeOfDelivery.zone == price2.placeOfDelivery.zone;
      } else {
        return sameHeader && price1.placeOfDelivery.zone == price2.placeOfDelivery.zone && price1.placeOfDelivery.port == price2.placeOfDelivery.port;
      }
    } catch (err) {}
    
    return false;
  }

function isChildWithParentObject(price:Price, parentsObject:any){
    let idToReturn:string='';
    try{
      const values:any[]=Object.values(parentsObject);
      for(let i=0; i<values.length; i++){
        const array=values[i];
        if(array.includes(price._id)){
          idToReturn=Object.keys(parentsObject)[i];
          throw new Error();
        }
      }
    } catch(err){}
    return idToReturn;
  }