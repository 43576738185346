import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BROKERS_CONST, brokerValids } from 'src/app/shared/const/broker';
import { orderTags } from 'src/app/shared/const/orders';
import { broker, } from 'src/app/shared/dict/dict';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderValidator } from 'src/app/shared/order';
import { Field } from '../fields.component';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class BrokerComponent extends Field implements OnInit,OnDestroy {
  BROKERS=broker
  isGrassi:boolean=true;
  validsBroker:any[]=[];
  flagSetAutomaticBroker:boolean=false;
  brokerSelected=1;

  private orderSubscriptionAux!:Subscription;
  constructor(store: Store<appState>, private orderV:OrderValidator) {
    super(store);
    this.orderSubscriptionAux=this.store.select('order').subscribe(order => {
      this.getBrokersValid();
    });
  }

  ngOnInit(): void {
    this.tag = orderTags.broke;
    this.setIdentifyClassName();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orderSubscriptionAux.unsubscribe();
  }

  override setElement(element: any, index: number) {
    this.brokerSelected=element;
    this.setElementToGenerate(Number(element));
  }
  
  override rememberValueInForm(){
    this.rememberValueWithObject()
  }

  setIsGrassi(boolean:boolean){
    if(this.validsBroker.includes(boolean? BROKERS_CONST.GRASSI:BROKERS_CONST.GGSA_1888)){
      this.isGrassi = boolean;
      const valueToSet= this.isGrassi? BROKERS_CONST.GRASSI:BROKERS_CONST.GGSA_1888;
      this.setElement(valueToSet,0);
    }
  }

  setIsGrassiEvent(boolean: boolean, event:any){
    const isEnter=event.key.toLowerCase()=='enter';
    if(isEnter){
      this.setIsGrassi(boolean);
    }
  }

  getBrokersValid(){
    if(this.elementToGenerate && this.elementToGenerate.placeOfOrigin.province > 0){
      this.validsBroker=this.orderV.getBrokersByProvince(this.elementToGenerate.placeOfOrigin.province)

      /*If validBroker, it has only one valid broker.
      We will change it to the order automatically.
      To avoid creating a loop, we will use flagSetAutomaticBroker*/
      if(!this.flagSetAutomaticBroker){
        setTimeout( ()=>{
          let broker=this.elementToGenerate.broke;
          
          if(broker<=0 || !this.validsBroker.includes(broker)){
            if(this.validsBroker.length>0){
              this.setIsGrassi(this.validsBroker[0]==BROKERS_CONST.GRASSI)
            } else { 
              this.setElement(-1,0);
            }
          }
          
        }, 520);
        this.flagSetAutomaticBroker=true;
      } else if(this.flagSetAutomaticBroker){
        this.flagSetAutomaticBroker=false;
      }
    }
  }

  getIsInvalid(){
    return this.validsBroker?.length==0 && this.elementToGenerate?.broke<=0;
  }
}
