import { createAction, props } from "@ngrx/store";

//Error
export const showError= createAction('[LOGIN] Show Error',
props<{error: string}>());
export const loginFailed = createAction('[LOGIN] LoginFailed',
    props<{ message: string }>());

//Splash
export const hiddenSplash= createAction('[LOGIN] Hidden Splash');
export const showSplash= createAction('[LOGIN] Show Splash');
