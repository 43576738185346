<div [ngSwitch]="title" [ngClass]="{'is-small':isSmall}">
    <div *ngSwitchCase="'Cupos'" class="card" >
            <div class="title-header">
                <span class="title">{{title}}</span>
                <arrow-svg class="arrow" [active]="false" [direction]="getArrowDirection()" (click)="changeShowOptions()" *ngIf="objectLengthMoreThan0()"></arrow-svg>
            </div>
            
            <span class="value"  [ngClass]="{'visible': !showOptions, 'invisible': showOptions}">{{getFirstTextOfQuotas(object)}}</span>
            <div class="options" [ngClass]="{'visible': showOptions, 'invisible': !showOptions}" >
                <div *ngFor="let quota of object">
                    <span class="value">{{getTextOfQuotas(quota)}}</span>
                </div>
            </div>
    </div>
    <div *ngSwitchCase="'Particularidad de negocio'"  class="card">
        <div class="title-header">
            <span class="title">{{title}}</span>
            <arrow-svg class="arrow" [active]="false" [direction]="getArrowDirection()" (click)="changeShowOptions()"></arrow-svg>
        </div>
        
        <span class="value"  [ngClass]="{'visible': !showOptions, 'invisible': showOptions, 'previus-value': previusValue}">{{getFirstTextOfParticularities(object)}}</span>
        <div class="options buss-particularities width-100" [ngClass]="{'visible': showOptions, 'invisible': !showOptions}" >
            <app-data-buss-part class="width-100" [businessParticularity]="businessParticularity"
            (closeEvent)="changeShowOptions()"></app-data-buss-part>
        </div>

    </div>
    <div *ngSwitchCase="'Restricción'" class="card">

        <div class="title-header">
            <span class="title">{{title}}</span>
            <arrow-svg class="arrow" [active]="false" [direction]="getArrowDirection()" (click)="changeShowOptions()" *ngIf="showArrowRestriction()"></arrow-svg>
        </div>
        
        <span class="value"  [ngClass]="{'visible': !showOptions, 'invisible': showOptions}">{{getFirstTextOfRestriction(object)}}</span>
        <div class="options" [ngClass]="{'visible': showOptions, 'invisible': !showOptions}" >
            <div *ngFor="let restriction of getArrayRestriction(object)">
                <span class="value">{{getBuyer(restriction)}}</span>
            </div>
        </div>
        
    </div>
    <!--<div *ngSwitchCase="'Particularidad de calidad'"  class="card">
        <div class="title-header">
            <span class="title">{{title}}</span>
            <arrow-svg class="arrow" [active]="false" [direction]="getArrowDirection()" (click)="changeShowOptions()" *ngIf="objectLengthMoreThan0()"></arrow-svg>
        </div>
        
        <span class="value"  [ngClass]="{'visible': !showOptions, 'invisible': showOptions}">{{getFirstTextOfParticularities(object)}}</span>
        <div class="options" [ngClass]="{'visible': showOptions, 'invisible': !showOptions}" >
            <div *ngFor="let particularity of object">
                <span class="value">{{getTextOfParticularities(particularity)}}</span>
            </div>
        </div>
    </div>-->
    <div *ngSwitchDefault class="card" 
    [ngClass]="{'way-pay': (title=='Forma de pago' && value.length>2),
        'not-white-space': (title=='Observaciones') }">    
        <span class="title">{{title}}</span>
        <span class="value" [ngClass]="{'previus-value': previusValue}" [title]="value">{{value}}<!--<div class="tooltip">{{previusValue}}</div>--></span>
        <span class="info-green">{{infoGreen}}</span> 
    </div>
</div>