import { LDC_BUYER,
    COFCO_BUYER,
    CARGILL_BUYER,
    VITERRA_BUYER,
    BUNGE_BUYER,
    ADM_BUYER,
    MOLINOS_AGROS_BUYER ,
    AMAGGI_BUYER,
    CHS_BUYER,
    GEAR_BUYER,
    placeOfDeliveryLDC_TRIGO,
    placeOfDeliveryLDC_MAIZ,
    placeOfDeliveryLDC_CEBADA_FORRAJERA,
    placeOfDeliveryCOFCO_SOJA,
    placeOfDeliveryCOFCO_SORGO,
    placeOfDeliveryCOFCO_CEBADA_FORRAJERA,
    placeOfDeliveryCOFCO_GIRASOL,
    placeOfDeliveryCARGILL_MAIZ,
    placeOfDeliveryCARGILL_SORGO,
    placeOfDeliveryCARGILL_GIRASOL,
    placeOfDeliveryCARGILL_CEBADA_CERVECERA,
    placeOfDeliveryVITERRA_MAIZ,
    placeOfDeliveryVITERRA_SORGO,
    placeOfDeliveryVITERRA_CEBADA_FORRAJERA,
    placeOfDeliveryVITERRA_GIRASOL,
    placeOfDeliveryBUNGE_SOJA,
    placeOfDeliveryBUNGE_TRIGO,
    placeOfDeliveryBUNGE_MAIZ,
    placeOfDeliveryBUNGE_SORGO,
    placeOfDeliveryBUNGE_CEBADA_FORRAJERA,
    placeOfDeliveryBUNGE_GIRASOL,
    placeOfDeliveryADM_SORGO,
    placeOfDeliveryADM_CEBADA_CERVECERA,
    placeOfDeliveryMOLINOS_AGROS_MAIZ,
    placeOfDeliveryAMAGGI_SORGO,
    placeOfDeliveryAMAGGI_CEBADA,
    placeOfDeliveryCHS_SOJA,
    placeOfDeliveryCHS_TRIGO,
    placeOfDeliveryCHS_MAIZ,
    placeOfDeliveryGEAR_TRIGO,
    placeOfDeliveryGEAR_MAIZ,
    placeOfDeliveryGEAR_SORGO,
    placeOfDeliveryLDC_SOJA_CAMARA,
    placeOfDeliveryLDC_SOJA_FABRICA,
    placeOfDeliveryCOFCO_TRIGO_97_5,
    placeOfDeliveryCOFCO_TRIGO_95,
    placeOfDeliveryCOFCO_MAIZ_97_5,
    placeOfDeliveryCOFCO_MAIZ_95,
    placeOfDeliveryCARGILL_SOJA_FABRICA,
    placeOfDeliveryCARGILL_SOJA_CAMARA,
    placeOfDeliveryCARGILL_TRIGO_GRADO_2,
    placeOfDeliveryCARGILL_TRIGO_CAMARA,
    placeOfDeliveryCARGILL_CEBADA_FORRAJERA_97_5,
    placeOfDeliveryCARGILL_CEBADA_FORRAJERA_90,
    placeOfDeliveryVITERRA_SOJA_FABRICA,
    placeOfDeliveryVITERRA_SOJA_CAMARA,
    placeOfDeliveryVITERRA_TRIGO_97_5,
    placeOfDeliveryVITERRA_TRIGO_95,
    placeOfDeliveryADM_SOJA_TRIGO_MAIZ,
    placeOfDeliveryMOLINOS_AGROS_SOJA_TRIGO_GIRASOL,
    placeOfDeliveryAMAGGI_SOJA_TRIGO
    } from "./const/buyers";
import { HOW_SELL_CONST } from "./const/how-sell";
import { PLACE_OF_DELIVERY_CONST, PLACE_OF_DELIVERY_IBM_CONST } from "./const/place-of-delivery";
import { PRODUCT_NAMES } from "./const/productNames";
import { PROVINCES_CONST } from "./const/provinces";
import { QUALITIES } from "./const/quality.const";
import { getPlaceOfDeliveryIBM } from "./functions-ibm/contract.checker";
import { CODE_BUYER_SELLER } from "./dict/ibm/cercanj-ibm";
import { RucaAndSisa } from "./interfaces/DataIBM";
import { Order } from "./interfaces/order.interface";
import { PlaceOfDelivery } from "./interfaces/price.interface";
// Se basa en la imagen guardada en la siguiente dirección:
// https://drive.google.com/drive/u/0/folders/1do0E5iXNjz26eHSBMczRQZFvM6CZGwUf

//Dada una orden, toma su provincia y valida si Commodities puede comprar en esa provincia.
export function canYouBuyCommodities(order:Order){
    const numberOfProvince=order.placeOfOrigin.province;
    return canBuyCommoditiesInProvince(numberOfProvince);
}

//Dada una orden, toma su provincia y valida si Hughes puede comprar en esa provincia.
export function canYouBuyHughes(order:Order){
    const numberOfProvince=order.placeOfOrigin.province;
    return canBuyHughesInProvince(numberOfProvince);
}

//Dada una provincia, verifica si Commodities puede comprar en esa provincia.
export function canBuyCommoditiesInProvince(province:number){
    return [PROVINCES_CONST.BUENOS_AIRES,
        PROVINCES_CONST.CABA,
        PROVINCES_CONST.CORDOBA,
        PROVINCES_CONST.CHACO,
        PROVINCES_CONST.ENTRE_RIOS,
        PROVINCES_CONST.LA_PAMPA,
        PROVINCES_CONST.SAN_LUIS,
        PROVINCES_CONST.SANTA_FE,
        PROVINCES_CONST.SANTIAGO_DEL_ESTERO,
        PROVINCES_CONST.TUCUMAN
    ].includes(province);
}

//Dada una provincia, verifica si Hughes puede comprar en esa provincia.
export function canBuyHughesInProvince(province:number){
    return [PROVINCES_CONST.SANTA_FE,
        PROVINCES_CONST.SALTA].includes(province);
}



export function buyerIsExchanger(buyer:number){
    return getSellerCodeByBuyerCode(buyer);
}

export function getBuyerCodeBySellerCode(codeS:number): number {
    return CODE_BUYER_SELLER[codeS]?.cgoBuyer ?? 0;
}
  
export function getSellerCodeByBuyerCode(codeB:number): number {
    let array:any[]=Object.entries(CODE_BUYER_SELLER);
    let valueToReturn:number=0;
    try{
        for(let i=0;i<array.length;i++){
            if(Number(array[i][1].cgoBuyer)==codeB){
                valueToReturn= array[i][0];
                throw new Error();
            }
        }
    } catch(er){}

    return Number(valueToReturn);
}

// ----------------- PROFORMS BUYERS ------------------\\
// Se basa en la tabla guardada en la siguiente dirección:
// https://docs.google.com/spreadsheets/d/1bs4KU_b9J9xEyJLv3XtRM9zJtD-Yx_9Vt1s0iVPfsq4/edit#gid=1422997573
// ACTUALIZACION: https://docs.google.com/spreadsheets/d/14fMCpXOzIWbWpMO4DEU3iKNq1cd88YhQZvYTa7xwkKs/edit#gid=0
export function isValidOrderByBuyer(order:Order, buyer:number):StructBuyerProform|null{
    if(order){
        switch(buyer){
            case LDC_BUYER: return isValidOrderByLDC(order);
            case COFCO_BUYER: return isValidOrderByCofco(order);
            case CARGILL_BUYER: return isValidOrderByCargill(order);
            case VITERRA_BUYER: return isValidOrderByViterra(order);
            case BUNGE_BUYER: return isValidOrderByBunge(order);
            case ADM_BUYER: return isValidOrderByADM(order);
            case MOLINOS_AGROS_BUYER: return isValidOrderByMolinosAgros(order);
            case AMAGGI_BUYER: return isValidOrderByAmaggi(order);
            case CHS_BUYER: return isValidOrderByCHS(order);
            case GEAR_BUYER: return isValidOrderByGear(order);
            //case INGREDION_ARGENTINA_BUYER: return isValidOrderByIngredionArgentina(order);
        }
    }
    return null;
}

interface FieldBuyerProform{
    isValid:boolean,
    default:number,
    arrayValids: number[],
}

export interface StructBuyerProform{
    product:FieldBuyerProform,
    quality:FieldBuyerProform,
    place:FieldBuyerProform,
    wayPay:FieldBuyerProform
}

interface DataCondition{
    productName:number,
    placeOfDeliveryIBM:number,
    place:PlaceOfDelivery,
    quality:number,
    percentageWayPay:number
}

interface Conditions{
    productName:number,
    placeOfDelivery:number[],
    quality:number,
    percentageWayPay:number
}

const fieldVoid:FieldBuyerProform={
    isValid: false,
    default: 0,
    arrayValids: []
}

const structVoid:StructBuyerProform={
    product:fieldVoid,
    place:fieldVoid,
    quality:fieldVoid,
    wayPay:fieldVoid
}

function getCondition(order:Order):DataCondition{
    return {
        percentageWayPay: order.price.wayPay.percentage,
        placeOfDeliveryIBM: getPlaceOfDeliveryIBM(order),
        place: order.price.placeOfDelivery,
        productName: order.price.productName,
        quality: order.price.quality
    }
}

/**
 * Given two arrays with numbers, it compares whether any element of the second exists in the first array and returns it.
 * @param array1
 * @param array2
 * @returns number
 */
function getCommonElement(array1:number[], array2:number[]):number{
    let valueToReturn:number=0;
    for(let i=0;i<array1.length;i++){
        if(array2.includes(array1[i])){
            valueToReturn=array1[i];
            break;
        }
    }
    return valueToReturn;
}

/**
 * Given a DataCondition, and an array of placeOfDelivery.
 * Compare the data information and find the closest element for the placeOfDelivery.
 * @param data 
 * @param placeOfDeliveries 
 * @returns placeOfDelivery:number
 */
function getPlaceOfDeliveryDefault(data:DataCondition, placeOfDeliveries:number[] ):number{
    const arrayToSearch:number[]=getArrayOfPortsIBMByZona(data.place.zone);
    const place=getCommonElement(placeOfDeliveries, arrayToSearch);

    return place?place:placeOfDeliveries[0] ;
}

/**
 * The ports that a zone has are obtained. Each port has the IBM code.
 * https://docs.google.com/spreadsheets/d/14fMCpXOzIWbWpMO4DEU3iKNq1cd88YhQZvYTa7xwkKs/edit#gid=1127956965
 * @param zone:number
 * @returns port: number[]
 */
function getArrayOfPortsIBMByZona(zone:number):number[]{
    if(zone == PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE){
        //Agregar PLACE_OF_DELIVERY_IBM_CONST.RICARDONE el día que algún comprador lo utilice.
        return [ PLACE_OF_DELIVERY_IBM_CONST.SAN_LORENZO, PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN, PLACE_OF_DELIVERY_IBM_CONST.TIMBUES] 
    } else if(zone == PLACE_OF_DELIVERY_CONST.ROSARIO_SUR){
        return [PLACE_OF_DELIVERY_IBM_CONST.GENERAL_LAGOS, PLACE_OF_DELIVERY_IBM_CONST.ARROYO_SECO,
            PLACE_OF_DELIVERY_IBM_CONST.PUNTA_ALVEAR, PLACE_OF_DELIVERY_IBM_CONST.VILLA_CONSTITUCION]
    } else if(zone == PLACE_OF_DELIVERY_CONST.ROSARIO){
        return [PLACE_OF_DELIVERY_IBM_CONST.ROSARIO]
    } else if(zone == PLACE_OF_DELIVERY_CONST.UP_RIVER){
        return [
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE),
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO_SUR),
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO)
        ]
    } else if(zone == PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE_Y_SUR){
        return [
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE),
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO_SUR)
        ]
    } else if(zone == PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE_Y_ROSARIO){
        return [
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE),
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO)
        ]
    } else if(zone == PLACE_OF_DELIVERY_CONST.ROSARIO_SUR_Y_ROSARIO){
        return [
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO_SUR),
            ...getArrayOfPortsIBMByZona(PLACE_OF_DELIVERY_CONST.ROSARIO)
        ]
    } else if(zone == PLACE_OF_DELIVERY_CONST.PUERTOS_BUENOS_AIRES){
        return [PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA, PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
            PLACE_OF_DELIVERY_IBM_CONST.LIMA, PLACE_OF_DELIVERY_IBM_CONST.TERM_LAS_PALMAS, 
            PLACE_OF_DELIVERY_IBM_CONST.RAMALLO, PLACE_OF_DELIVERY_IBM_CONST.JUNIN]
    } else if(zone == PLACE_OF_DELIVERY_CONST.PUERTOS_ENTRE_RIOS){
        return [PLACE_OF_DELIVERY_IBM_CONST.TERMINAL_Pto_DEL_GUAZU, PLACE_OF_DELIVERY_IBM_CONST.DIAMANTE]
    }
    //Plantas interior abarca todo
    return []
}

function validateCondition(data:DataCondition, productName:number, quality:number, placeOfDeliveries:number[], percentageWayPay:number): StructBuyerProform{
    let struct:StructBuyerProform=JSON.parse(JSON.stringify(structVoid));
    if(data.productName==productName){
        struct.product.isValid=true;
        struct.product.arrayValids=[productName];
        struct.product.default=productName;
        
        struct.place.isValid=placeOfDeliveries.includes(data.placeOfDeliveryIBM)
        struct.place.arrayValids=placeOfDeliveries;
        struct.place.default= struct.place.isValid? data.placeOfDeliveryIBM:getPlaceOfDeliveryDefault(data,placeOfDeliveries)
        
        struct.quality.isValid=data.quality==quality || quality==0;
        struct.quality.arrayValids=[quality];
        struct.quality.default=quality;
        
        struct.wayPay.isValid=data.percentageWayPay==percentageWayPay || percentageWayPay==0;
        struct.wayPay.arrayValids=[percentageWayPay];
        struct.wayPay.default=percentageWayPay;
    }
    
    return struct;
}

/**
 * Given an array of Conditions and a productName, 
 * it returns an array with all the indexes where that productName has that productName.
 * @params conditions: Conditions[]
 * @params productName: number
 * @returns number[]
 */
function getIndexesByProductName(conditions:Conditions[], productName:number):number[]{
    let indexes:number[]=[];
    for(let i=0;i<conditions.length;i++){
        if(conditions[i].productName==productName){
            indexes.push(i);
        }
    }
    return indexes;
}


/**
 * Given a StructBuyerProform, it returns the number of isValid fields it has.
 * @params struct: StructBuyerProform
 * @returns number
 */
function getNumberOfValids(struct:StructBuyerProform):number{
    let number:number=0;
    if(struct.product.isValid) number++;
    if(struct.quality.isValid) number++;
    if(struct.place.isValid) number++;
    if(struct.wayPay.isValid) number++;
    return number;
}

/**
 * Compares 2 StructBuyerProforms, returns the one with the most valid fields.
 * @params struct1: StructBuyerProform
 * @params struct2: StructBuyerProform
 * @returns StructBuyerProform
 */
function compareStructs(struct1:StructBuyerProform, struct2:StructBuyerProform):StructBuyerProform{
    const quantValids= getNumberOfValids(struct1)-getNumberOfValids(struct2);
    return quantValids>0? struct1: struct2;
}

/**
 * Given the information of an order and an arrangement of preloaded conditions. 
 * Returns a structure specifying what was the best preloaded condition for that case.
 * We always assume that the product from the user's order is correctly loaded. Therefore, 
 * we analyze how many conditions are preloaded with that product.
 * If it is one, there will be no problem. It will end up analyzing that one alone.
 * If there is more than one, we must take the best one. We must take the one that has the most valid fields.
 * @params data: DataCondition
 * @params conditions: Conditions[]
 * @returns StructBuyerProform
 */
function getStructByConditions(data:DataCondition, conditions:Conditions[]):StructBuyerProform{
    let struct:StructBuyerProform=JSON.parse(JSON.stringify(structVoid));
    const indexProducts=getIndexesByProductName(conditions, data.productName);
    
    if(indexProducts.length==1){
        const index=indexProducts[0];
        struct=validateCondition(data, conditions[index].productName, conditions[index].quality,  
            conditions[index].placeOfDelivery, conditions[index].percentageWayPay);
    } else if(indexProducts.length>1){
        let structAux=JSON.parse(JSON.stringify(structVoid));
        for(const index of indexProducts){
            structAux=validateCondition(data, conditions[index].productName, conditions[index].quality,  
                conditions[index].placeOfDelivery, conditions[index].percentageWayPay);
            
            struct=compareStructs(struct, structAux);
        }
    }
    return struct;
}

function isValidOrderByLDC(order: Order) {
    const conditions:Conditions[] = [
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryLDC_SOJA_CAMARA, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryLDC_SOJA_FABRICA, quality: QUALITIES.FABRICA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryLDC_TRIGO, quality: QUALITIES.GRADO_2, percentageWayPay: 95 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryLDC_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.CEBADA_FORRAJERA, placeOfDelivery: placeOfDeliveryLDC_CEBADA_FORRAJERA, quality: QUALITIES.CAMARA, percentageWayPay: 95 },
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByCofco(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryCOFCO_SOJA, quality: QUALITIES.FABRICA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryCOFCO_TRIGO_97_5, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryCOFCO_TRIGO_95, quality: QUALITIES.GRADO_2, percentageWayPay: 95 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryCOFCO_MAIZ_97_5, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryCOFCO_MAIZ_95, quality: QUALITIES.GRADO_2, percentageWayPay: 95 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryCOFCO_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 0 },
        { productName: PRODUCT_NAMES.GIRASOL, placeOfDelivery: placeOfDeliveryCOFCO_GIRASOL, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.CEBADA_FORRAJERA, placeOfDelivery: placeOfDeliveryCOFCO_CEBADA_FORRAJERA, quality: QUALITIES.GRADO_2, percentageWayPay: 95 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByCargill(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryCARGILL_SOJA_FABRICA, quality: QUALITIES.FABRICA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryCARGILL_SOJA_CAMARA, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryCARGILL_TRIGO_CAMARA, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryCARGILL_TRIGO_GRADO_2, quality: QUALITIES.GRADO_2, percentageWayPay: 95 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryCARGILL_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.GIRASOL, placeOfDelivery: placeOfDeliveryCARGILL_GIRASOL, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryCARGILL_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 0 },
        { productName: PRODUCT_NAMES.CEBADA_FORRAJERA, placeOfDelivery: placeOfDeliveryCARGILL_CEBADA_FORRAJERA_97_5, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.CEBADA_FORRAJERA, placeOfDelivery: placeOfDeliveryCARGILL_CEBADA_FORRAJERA_90, quality: QUALITIES.GRADO_2, percentageWayPay: 90 },
        { productName: PRODUCT_NAMES.CEBADA_CERVECERA, placeOfDelivery: placeOfDeliveryCARGILL_CEBADA_CERVECERA, quality: QUALITIES.CAMARA, percentageWayPay: 90 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByViterra(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryVITERRA_SOJA_FABRICA, quality: QUALITIES.FABRICA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryVITERRA_SOJA_CAMARA, quality: QUALITIES.CAMARA, percentageWayPay: 97.5},
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryVITERRA_TRIGO_97_5, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryVITERRA_TRIGO_95, quality: QUALITIES.GRADO_2, percentageWayPay: 95 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryVITERRA_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.GIRASOL, placeOfDelivery: placeOfDeliveryVITERRA_GIRASOL, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryVITERRA_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 0 },
        { productName: PRODUCT_NAMES.CEBADA_FORRAJERA, placeOfDelivery: placeOfDeliveryVITERRA_CEBADA_FORRAJERA, quality: QUALITIES.GRADO_2, percentageWayPay: 95 }
    ];
    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByBunge(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryBUNGE_SOJA, quality: QUALITIES.FABRICA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryBUNGE_TRIGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryBUNGE_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryBUNGE_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.GIRASOL, placeOfDelivery: placeOfDeliveryBUNGE_GIRASOL, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.CEBADA_FORRAJERA, placeOfDelivery: placeOfDeliveryBUNGE_CEBADA_FORRAJERA, quality: QUALITIES.GRADO_2, percentageWayPay: 95 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByADM(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryADM_SOJA_TRIGO_MAIZ, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryADM_SOJA_TRIGO_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 95 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryADM_SOJA_TRIGO_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryADM_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.CEBADA_CERVECERA, placeOfDelivery: placeOfDeliveryADM_CEBADA_CERVECERA, quality: 0, percentageWayPay: 90 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByMolinosAgros(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryMOLINOS_AGROS_SOJA_TRIGO_GIRASOL, quality: QUALITIES.FABRICA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryMOLINOS_AGROS_SOJA_TRIGO_GIRASOL, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryMOLINOS_AGROS_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.GIRASOL, placeOfDelivery: placeOfDeliveryMOLINOS_AGROS_SOJA_TRIGO_GIRASOL, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByAmaggi(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryAMAGGI_SOJA_TRIGO, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryAMAGGI_SOJA_TRIGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryAMAGGI_SOJA_TRIGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryAMAGGI_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.CEBADA_CERVECERA, placeOfDelivery: placeOfDeliveryAMAGGI_CEBADA, quality: QUALITIES.GRADO_2, percentageWayPay: 95 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByCHS(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.SOJA, placeOfDelivery: placeOfDeliveryCHS_SOJA, quality: QUALITIES.CAMARA, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryCHS_TRIGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryCHS_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

function isValidOrderByGear(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.TRIGO, placeOfDelivery: placeOfDeliveryGEAR_TRIGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryGEAR_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 },
        { productName: PRODUCT_NAMES.SORGO, placeOfDelivery: placeOfDeliveryGEAR_SORGO, quality: QUALITIES.GRADO_2, percentageWayPay: 97.5 }
    ];

    return getStructByConditions(getCondition(order), conditions);
}

/*function isValidOrderByIngredionArgentina(order:Order){
    const conditions:Conditions[]=[
        { productName: PRODUCT_NAMES.MAIZ, placeOfDelivery: placeOfDeliveryINGREDION_ARGENTINA_MAIZ, quality: QUALITIES.GRADO_2, percentageWayPay: 100 }
    ];
    
    return getStructByConditions(getCondition(order), conditions);
}*/

// -------------- HOW SELL ----------------\\
export function itCanBeHowSell(howSell:number, validationRucaSisa:RucaAndSisa){
    switch(howSell){
        case HOW_SELL_CONST.ACOPIO: return itCanBeAcopioSeller(howSell, validationRucaSisa);
        case HOW_SELL_CONST.PRODUCCION_PROPIA: return itCanBeProduccionPropiaSeller(howSell, validationRucaSisa);
        case HOW_SELL_CONST.CONSIGNACION: return itCanBeConsignacionSeller(howSell, validationRucaSisa);
        case HOW_SELL_CONST.COOPERATIVA: return itCanBeCooperativaSeller(howSell, validationRucaSisa);
        default: return false;
    }
}

export function itCanBeAcopioSeller(howSell:number, validationRucaSisa:RucaAndSisa){
    if(howSell==HOW_SELL_CONST.ACOPIO){
        if(validationRucaSisa.ExisteRuca=='N'){
            return false;
        } else if (validationRucaSisa.ExisteSisa=='N'){
            return false;
        } else if (validationRucaSisa["Estado SISA"]==0){
            return false;
        }
        return true;
    }
    return false;
}

export function itCanBeProduccionPropiaSeller(howSell:number, validationRucaSisa:RucaAndSisa){
    if(howSell==HOW_SELL_CONST.PRODUCCION_PROPIA){
        if (validationRucaSisa.ExisteSisa=='N'){
            return false;
        } else if (validationRucaSisa["Estado SISA"]==0){
            return false;
        }
        return true;
    } 
    return false;
}

export function itCanBeConsignacionSeller(howSell:number, validationRucaSisa:RucaAndSisa){
    if(howSell==HOW_SELL_CONST.CONSIGNACION){
        if(validationRucaSisa.ExisteRuca=='N'){
            return false;
        } else if (validationRucaSisa.ExisteSisa=='N'){
            return false;
        } else if (validationRucaSisa["Estado SISA"]==0){
            return false;
        }
        return true;
    }
    return false;
}

export function itCanBeCooperativaSeller(howSell:number, validationRucaSisa:RucaAndSisa){
    if(howSell==HOW_SELL_CONST.COOPERATIVA){
        if(validationRucaSisa.ExisteRuca=='N'){
            return false;
        } else if (validationRucaSisa.ExisteSisa=='N'){
            return false;
        } else if (validationRucaSisa["Estado SISA"]==0){
            return false;
        }
        return true;
    }
    return false;
}