import { Component, OnInit,OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DialogOrderCreate } from 'src/app/components/dialogs/order-create/dialog';
import { setLocation } from 'src/app/redux/actions/options.action';
import { setCommisionInOrder, setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Commission, Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { PriceValidator } from 'src/app/shared/price';

const SIZE_OF_VIEW_MOBILE = 950;

@Component({
  selector: 'app-body-generate-order',
  templateUrl: './body-generate-order.component.html',
  styleUrls: [ './../../../shared/styles/body-generate.scss',
    './../../generate-price/body-generate-price/body-generate-price.component.scss', './body-generate-order.component.scss',]
})
export class BodyGenerateOrderComponent implements OnInit, OnDestroy {
  //It serves to identify if the user pressed 'Orden de venta' or 'Orden de compra'
  //public isOrderSell: boolean = true;

  //It is a boolean that represents if the button is disabled or not
  disabledButton: boolean = true;

  //It is a boolean that represents if the details are being displayed or not
  detailsVisible: boolean = false;

  //It is a boolean that represents if the form is being displayed or not
  formVisible: boolean = true;

  count=0;

  //Cards
  isOpenCommission:boolean = false;
  isOpenDuplicate:boolean = false;
  comissionEnabled: boolean = false;
  duplicateOrderEnabled: boolean = false;

  public orderToGenerate!: Order;
  public quantOrdersToGenerate:number=1;

  private order$!:Subscription;
  private sizeView$!:Subscription;

  constructor(private store: Store<appState>, private orderV:OrderValidator,  private priceV:PriceValidator, private router: Router,
    private dialog: MatDialog) {
    this.setSubscriptions();
    this.store.dispatch(setLocation({location: NAVEGATION.GENERATE_PRICE_BOARD_ORDER}));
  }

  ngOnInit(): void {
    if(this.orderToGenerate && this.priceV.isPriceIncomplete(this.orderToGenerate.price)){
      const url="/"+NAVEGATION.PRICE_BOARD;
      this.router.navigate([url]);
    }
  }

  ngOnDestroy(): void {
    this.order$?.unsubscribe();
    this.sizeView$?.unsubscribe();
  }

  setSubscriptions(){
    this.order$=this.store.select('order').subscribe(orderR => {
      this.orderToGenerate = orderR.generateOrder;
      this.quantOrdersToGenerate=orderR.generateOrders.length;

      this.disabledButton=this.orderV.isOrderIncomplete(this.orderToGenerate);
      this.comissionEnabled = this.duplicateOrderEnabled = !this.disabledButton;
    })

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.showDetailsAndForm(sizeView);
    })
  }

  showForm(showForm:boolean){
    this.formVisible=showForm;
    this.detailsVisible=!showForm;
    this.count++;
  }

  showDetails(showDetails:boolean){
    this.detailsVisible=showDetails;
    this.formVisible=!showDetails;
    this.count++;
  }

  showDetailsAndForm(sizeView:number){
    if(sizeView > SIZE_OF_VIEW_MOBILE) {
      this.detailsVisible=true;
      this.formVisible=true;
      this.count=0
    } else if(this.count==0) {
      this.detailsVisible=true;
      this.formVisible=!this.detailsVisible
    }
  }
  
  setCommission(event:Commission){
    this.store.dispatch(setCommisionInOrder({commission:event}));
  }

  //-------- CARDS --------\\
  openCommission(){
    /*if(this.comissionEnabled){
      this.isOpenCommission=!this.isOpenCommission;
      this.isOpenDuplicate=false;
    }*/
  }

  openDuplicateOrder(){
    if(this.duplicateOrderEnabled){
      this.isOpenDuplicate=!this.isOpenDuplicate;
      this.isOpenCommission=false;
    }
  }

  closeCommission(event:any){
    if(event=="close"){
      this.isOpenCommission=false;
    } else {
      this.isOpenCommission=true;
    }
  }

  closeDuplicateOrder(event:any){
    if(event=="close"){
      this.isOpenDuplicate=false;
    } else if(event=='open'){
      this.isOpenDuplicate=true;
    }
  }
  //-----------------------\\

  confirmOrder(){
    this.dialog.open(DialogOrderCreate);
  }
}