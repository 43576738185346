import { Pipe, PipeTransform } from '@angular/core';
import * as dict from '../../dict/dict';
import { priceTags, typeCoins } from '../../const/prices.const';
import { Order, OrderString } from '../../interfaces/order.interface';
import { PRODUCT_NAMES } from '../../dict/productName';
import { teams } from '../../dict/user';
import { orderTags } from '../../const/orders';
import { typeBusiness } from '../../dict/typeBusiness';
import { orderStates } from '../../dict/orders';
import { wayPay } from '../../dict/wayToPay';
import { elementContainCharacteristic, elementContainCharacteristicSimple, elementContainSubCharasteristic } from './filter.by.price.pipe';
import { buyers } from '../../dict/buyers';

const ORDER_STATUS:any=orderStates;
const PRODUCT: any = PRODUCT_NAMES;
const COIN_TYPE: any = dict.typeCoin;
const BUSINESS_TYPE: any = typeBusiness;
const QUALITY: any = dict.quality;
const TEAMS: any = teams;
const PLACE_OF_DELIVERY: any = dict.puertos;
const WAY_TO_PAY: any = wayPay;

@Pipe({
  name: 'filterByOrder'
})
export class FilterByOrderPipe implements PipeTransform {

  transform(list: any[], order: OrderString) {
    let arrayFinal: any[] = [];
    list.forEach(element => {
      if (filterOrderWithOrderString(element, order)) {
        arrayFinal.push(element);
      }
    })

    return arrayFinal;
  }
}  

/* Given two elements and a label.
The value that the element has in that label is searched for and if it matches (at least one), it returns true. */
function elementContainCharacteristicWithOutList(elementToCompare: any, value: any, tag: string) {
  try {
    if (value[tag].length == 0) throw new Error();
    value[tag].forEach((element: any) => {
      if (element == elementToCompare[tag]) throw new Error()
    });
  } catch (err: any) {
    return true;
  }

  return false;
}

//Given two elements, it compares if the first one is the same 
//type of currency and if the price is between two values.
function elementContainValuesInPrice(elementToCompare: any, value: any, tag: string) {
  if (value.price.typeCoin == typeCoins.ALL) {
    return valueIsInTheRange(elementToCompare.price, value.price.start, value.price.end);
  }

  if (elementToCompare.typeCoin == value.price.typeCoin) {
    return valueIsInTheRange(elementToCompare.price, value.price.start, value.price.end);
  }
  return false
}

function elementContainTons(elementToCompare: any, value: any, tag: string){
  try{
    return valueIsInTheRange(elementToCompare.tons, Number(value[tag].start), Number(value[tag].end));
  } catch(err) {
    return true;
  }
}

//Returns true if a value is between 2 others.
//In case the end is 0, it returns true.
//In case the start is bigger than the end, it returns false.
function valueIsInTheRange(value: number, start: number, end: number): boolean {
  if (!end && !start){return true};
  if (!end) { return start<=value; };
  if (!start ) { return end>=value; };
  if (start > end) { return false };
  return start <= value && value <= end;
}

//Given two elements, 
//compare if they have the same hour (not minutes)
function elementContainHour(elementToCompare: Order, value: any, tag: string) {
  if( !value.hour) {return true;}
  const hour=elementToCompare.dataOfcreation.hour.substring(0,2);
  return hour == value.hour
}

/*Given two elements,
  Returns if the element contains at least one field with the same value.*/
  function elementContainCharacteristicInPrice(elementToCompare: any, value: any, tag: string, array: any): boolean {
    try {

      if (value['price'][tag].length == 0) throw new Error();
      value['price'][tag].forEach((element: any) => {
        if (element == array[elementToCompare['price'][tag]]) throw new Error()
      });
    } catch (err: any) {
      return true;
    }
    return false;
  }


export function elementContainsNameOfCreator(nameOfCreator: string, value: any){
  try{
    if( !(nameOfCreator=='') ||  !(value.nameOfcreator.length<=0)) {
        value.nameOfcreator.forEach((element: any) => {
        if ( (element && stringEquals(element, nameOfCreator)) || element==' ') throw new Error()
        });
    };
  } catch(err:any){
    return true;
  }

  return false;
}

/* Returns true if two strings are equal, regardless of whether they are case sensitive */
export function stringEquals(string1: string, string2: string):boolean {
  return string1.toLowerCase() == string2.toLowerCase()
}


export function filterOrderWithOrderString(element:Order, order:OrderString){
  try{
    return elementContainHour(element, order, orderTags.hour) &&
    elementContainCharacteristic(element, order, orderTags.statusOrder, ORDER_STATUS) &&
    elementContainCharacteristicWithOutList(element.sellerData, order, orderTags.codeS) &&
    elementContainCharacteristicInPrice(element, order, priceTags.productName, PRODUCT) &&
    elementContainCharacteristicInPrice(element, order, priceTags.typeBusiness, BUSINESS_TYPE) &&

    elementContainCharacteristicInPrice(element, order, priceTags.typeCoin, COIN_TYPE) &&

    elementContainCharacteristicWithOutList(element.price.observations, order?.price, priceTags.buyer) &&

    elementContainCharacteristicSimple(element?.price?.deliveryPeriod, order?.price?.deliveryPeriod, priceTags.startDate) &&
    elementContainCharacteristicSimple(element?.price?.deliveryPeriod, order?.price?.deliveryPeriod, priceTags.endDate) &&

    elementContainCharacteristicInPrice(element, order, priceTags.quality, QUALITY) &&
    elementContainSubCharasteristic(element?.price, order?.price, priceTags.placeOfDelivery, priceTags.zone, PLACE_OF_DELIVERY) &&
    elementContainValuesInPrice(element.price, order?.price, priceTags.price) &&
    elementContainTons(element, order, orderTags.tons) &&
    elementContainCharacteristicWithOutList(element.price, order?.price, priceTags.harvest) &&
    elementContainCharacteristic(element.price.wayPay, order?.price, priceTags.wayPayName, WAY_TO_PAY) &&
    validateMyTableAndMyOrderes(element, order)
    //this.elementContainCharacteristicWithOutList(element, order, 'businessParticularities')
  } catch(err){
    return false;
  }
}

function validateMyTableAndMyOrderes(order: Order, orderString:OrderString): boolean{
  const team=orderString.nroTeam;
  const name=orderString.nameOfcreator;
  
  //Estamos en visualización completa
  if(name.length!=0 && team.length!=0) return ( 
    elementContainCharacteristic(order.dataOfcreation.teamOfcreator, orderString, 'nroTeam', TEAMS) ) &&
  elementContainsNameOfCreator(order.dataOfcreation.nameOfcreator, orderString);

  //Estamos en 'Mi mesa'
  if(name.length==0) return ( elementContainCharacteristic(order.dataOfcreation.teamOfcreator, orderString, 'nroTeam', TEAMS) ); 

  //Estamos en 'Mis ordenes'
  if(team.length==0) return ( elementContainsNameOfCreator(order.dataOfcreation.nameOfcreator, orderString) );

  return false;
}