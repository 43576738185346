import { Action, createReducer, on } from "@ngrx/store";
import { Dashboard } from "src/app/shared/interfaces/dashboard.interface";
import * as actions from '../actions/dashboard.action';

export const initialState: Dashboard = {
    ordersList: {
        returned: [],
        pending: [],
        confirmed: []
    },
    featuredPricesList: [],
    marketPricesList: {
        soja: [],
        trigo: [],
        maiz: []
    },
    futureMarketsList: [{ 
        date: "Jul 2022",
        price: 203.4,
        valorPorc: -1.5, valorUsd: -1.4,
    },
    {
        date: "Ago 2022",
        price: 205.4,
        valorPorc: 1.5, valorUsd: 1.4,
    },
    {
        date: "Sep 2022",
        price: 207.4,
        valorPorc: -1.5,
        valorUsd: -1.4,
    },
    {
        date: "Oct 2022",
        price: 207.4,
        valorPorc: -1.5,
        valorUsd: -1.4,
    }

],
    optionsFutureMarket: {
        date: '',
        market: '',
        product: ''
    },
    dolar: {
        tasaFCI: 34,
        tasaBancaria: 32,
        dolarList: []
    },
    objetives: {
        tons: {
            dailySales: 0,
            missing: 0,
            missingAverage: 0,
            quarterly: 0
        },
        grafic: []
    },
    accumulatedTonnage: [],
    dateAccumTons: null,

};

const _dashboardReducer = createReducer(
    initialState,
    on(actions.rememberDashboardData, (state, action) => {
        return action.state;
    }),

    //Orders
    on(actions.loadAllOrdersSuccess, (state, action) => {
        return {
            ...state,
            ordersList: action.orderList
        };
    }),

    //MarketPrices
    on(actions.loadAllMarketPricesSuccess, (state, action) => {
        return {
            ...state,
            marketPricesList: action.marketPrices
        };
    }),

    on(actions.loadFeaturedPricesSuccess, (state, action) => {
        return {
            ...state,
            featuredPricesList: action.featuredPrices,
        };
    }),
    on(actions.loadMarketPricesSuccess, (state, action) => {
        return {
            ...state,
            marketPricesList: action.marketPrices,
        };
    }),
    on(actions.loadSojaMarketPrice, (state, action) => {
        return {
            ...state,
            marketPricesList: {
                ...state.marketPricesList,
                soja: action.prices
            }
        };
    }),
    on(actions.loadTrigoMarketPrice, (state, action) => {
        return {
            ...state,
            marketPricesList: {
                ...state.marketPricesList,
                trigo: action.prices
            }
        };
    }),
    on(actions.loadMaizMarketPrice, (state, action) => {
        return {
            ...state,
            marketPricesList: {
                ...state.marketPricesList,
                maiz: action.prices
            }
        };
    }),

    on(actions.loadFutureMarketsSuccess, (state, action) => {
        return {
            ...state,
            futureMarketsList: action.futureMarkets,
        };
    }),
    on(actions.setProduct, (state, action) => {
        return {
            ...state,
            optionsFutureMarket:{
                ...state.optionsFutureMarket,
                product: action.product
            }
        };
    }),
    on(actions.setMarket, (state, action) => {
        return {
            ...state,
            optionsFutureMarket:{
                ...state.optionsFutureMarket,
                market: action.market
            }
        };
    }),
    on(actions.setDate, (state, action) => {
        return {
            ...state,
            optionsFutureMarket:{
                ...state.optionsFutureMarket,
                date: action.date
            }
        };
    }),

    //Objetives
    on(actions.setObjetive, (state, action) => {
        return {
            ...state,
            objetives: action.objetives
        };
    }),

    //AccumulatedTonnage
    on(actions.setAccumulatedTonnageSuccess, (state, action) => {
        return {
            ...state,
            accumulatedTonnage: action.accumulatedTonnage,
            dateAccumTons: action.date
        };
    }),


    //Dolar
    on(actions.loadDolarPriceSuccess, (state, action) => {
        return {
            ...state,
            dolar: {
                ...state.dolar,
                dolarList: [...state.dolar.dolarList, action.dolarPrice]
            }
        };
    }),
)


export function dashboardReducer(state: any, action: Action) {
    return _dashboardReducer(state, action);
}

