import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrashDuplicateOrderSvgDOComponent } from './trash/trash.component';
import { EditDuplicateOrderSvgDOComponent } from './edit/edit-svg.component';
import { DuplicateOrderSvgDOComponent } from './duplicate-order/duplicate-order.component';
import { CommissionSvgDOComponent } from './commission/commission.component';

@NgModule({
  declarations: [
    TrashDuplicateOrderSvgDOComponent,
    EditDuplicateOrderSvgDOComponent,
    DuplicateOrderSvgDOComponent,
    CommissionSvgDOComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    TrashDuplicateOrderSvgDOComponent,
    EditDuplicateOrderSvgDOComponent,
    DuplicateOrderSvgDOComponent,
    CommissionSvgDOComponent
  ]
})
export class DuplicateOrderCollectionSvgModule { }
