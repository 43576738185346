import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusConst } from '../../const/orders';
import { Order } from '../../interfaces/order.interface';
import { isEarlierHour } from '../../date.checker';

const ORDENATE_OPERATOR = [
  orderStatusConst.CONFIRM,
  orderStatusConst.PENDING,
  orderStatusConst.CHECK,
  orderStatusConst.OFERTED,
  orderStatusConst.SEND_TO_BROKE,
  orderStatusConst.OUT_OF_MARKET,
  orderStatusConst.RETURNED,
];

const ORDENATE_COMERCIAL = [
    orderStatusConst.RETURNED,
    orderStatusConst.OUT_OF_MARKET,
    orderStatusConst.CHECK,
    orderStatusConst.OFERTED,
    orderStatusConst.SEND_TO_BROKE,
    orderStatusConst.PENDING,
    
    orderStatusConst.CONFIRM,
    orderStatusConst.AUTORIZATION,
    orderStatusConst.CONTRACT,
  ];
  

@Pipe({
  name: 'sortOrder',
})
export class OrderSortPipe implements PipeTransform {
  /* Given an array and an area, sort the array by 'orderStatus'.
    If the area is commercial, first come the returned, confirmed, 
    pending, viewed, offered and sent to the stock market.*/
  transform(valueList: any[], isComercial: boolean) {
    return this.mergeSort(valueList, isComercial);
  }

  private mergeSort(array: Order[], isComercial: boolean): Order[] {
    if (array.length <= 1) {
      return array;
    }

    const middle = Math.floor(array.length / 2);
    const left = array.slice(0, middle);
    const right = array.slice(middle);

    return this.merge(
      this.mergeSort(left, isComercial),
      this.mergeSort(right, isComercial),
      isComercial
    );
  }

  private merge(left: Order[], right: Order[], isComercial: boolean): Order[] {
    const result: Order[] = [];
    let leftIndex = 0;
    let rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      if (
        this.compareOrders(left[leftIndex], right[rightIndex], isComercial) <= 0
      ) {
        result.push(left[leftIndex]);
        leftIndex++;
      } else {
        result.push(right[rightIndex]);
        rightIndex++;
      }
    }

    return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
  }

  /*Estos son los criterios de ordenamiento:
  - Primer punto es ordenar por statusOrder acorde a las constantes de arreglo ORDENATE_OPERATOR. ORDENATE_COMERCIAL
  - Segundo punto es ordenar por hora de creación de mayor a menor.*/

  private compareOrders(a: Order, b: Order, isComercial: boolean): number {

    if(a.statusOrder !== b.statusOrder){
      if(isComercial){
        return ORDENATE_COMERCIAL.indexOf(a.statusOrder) - ORDENATE_COMERCIAL.indexOf(b.statusOrder);
      } else {
        return ORDENATE_OPERATOR.indexOf(a.statusOrder) - ORDENATE_OPERATOR.indexOf(b.statusOrder);
      }
    }

    return isEarlierHour(a.dataOfcreation.hour, b.dataOfcreation.hour) ? -1 : 1;
  }
}
