<div class="field">
    <span class="field-title">Observaciones</span>

    <div>
        <div class="form-field" (click)="changeShowOptions()" [ngClass]="{'border-green': isOptionSelected}">
            <span class="title-field  {{identifyClassName}}"> 
                <input #inputName (keydown)="checkKeyPress()" (focus)="focusField()" 
                (blur)="unfocusField()"
                value="{{elementSelected}}" placeholder="{{initalElementSelected}}" 
                class="{{identifyClassName}} no-background-border" />

                <img (clich)="setIsFocus(!isFocus)" alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>
        <span class="text-is-incomplete invalid-text" *ngIf="showMessage && isFocus">Debes escribir en la sección de "otro".</span>

        <div #options class="options" *ngIf="showOptions">
            <div class="option-radio pointer" (click)="setSubOption(1)">
                <span class="title">Anula y reemplaza</span>
                <input type="radio" name="option" [checked]="nroSubOption==1"/>
            </div>
            <div *ngIf="nroSubOption==1">
                <span class="title-sub-field">N° de contrato a reemplazar</span>
                <number-input class="number-input" [initialPlaceHolder]="'Ingresar numero de contrato'" (valueOut)="setNroOfContractNew($event)"></number-input>
            </div>
            
            <div class="option-radio pointer" (click)="setSubOption(2)">
                <span class="title">Negocio vinculado con otro</span>
                <input type="radio" name="option" (click)="setElement('Negocio vinculado con otro',0)" 
                [checked]="nroSubOption==2"/>
            </div>
            
            <div class="option-radio pointer" (click)="setSubOption(3)">
                <span class="title">Numero de depósito</span>
                <input type="radio" name="option" [checked]="nroSubOption==3"/>
            </div>
            <div class="sub-option-section" *ngIf="nroSubOption==3">
                <div class="row-left gap-8 options-selecteds">
                    <div class="option-selected" *ngFor="let numberContract of arrayOfContracts">
                        <p class="number-contract">{{numberContract}}</p>
                        <cross-svg class="pointer" (click)="deleteContract(numberContract)" [size]="12" [style]="{height: '22px'}"></cross-svg>
                    </div>
                </div>

                <number-input class="number-input" [value]="nroOfContract"
                [initialPlaceHolder]="'Ingresar número de depósito'" (valueOut)="setNroOfDep($event)"></number-input>        
                <p class="number-contract text-is-incomplete invalid-text" *ngIf="showMessageDeposit">El depósito debe ser de 6 cifras.</p>

            </div>
            
            <div class="option-radio pointer" (click)="setSubOption(4)">
                <span class="title">Operativos</span>
                <input type="radio" name="option" [checked]="nroSubOption==4"/>
            </div>
            <div *ngIf="nroSubOption==4" class="width-100">
                <div class="sub-option-radio" (click)="setOperative(1)">
                    <span>Operativo excluyente</span>
                    <input type="radio" name="option"/>
                </div>
                <div class="sub-option-radio" (click)="setOperative(2)">
                    <span>Opción operativo</span>
                    <input type="radio" name="option"/>
                </div>
                <div class="sub-option-radio" (click)="setOperative(3)">
                    <span>Trocha</span>
                    <input type="radio" name="option"/>
                </div>
            </div>
            
            <div class="option-radio pointer" (click)="setSubOption(5, options)">
                <span>Otro</span>
                <input type="radio" name="option" [checked]="nroSubOption==5"/>
            </div>
            <div *ngIf="nroSubOption==5" style="margin-bottom:16px">
                <text-input class="text-input" [initialPlaceHolder]="'Ingresar una observación'" 
                [value]="elementSelected" (valueOut)="setElement($event, 0)"></text-input>
            </div>
        </div>
    </div>
</div>