<div class="card column-center pointer" (click)="goToGenerateOrder()">
    
    <div class="column-center width-100">
        <div class="container-boat">
            <div *ngIf="price.observations.isPort == 1" class="img-barco"></div>
        </div>  
        <p class="zone">{{getZoneName(price)}}</p>
    </div>
    <p class="price-text">{{getTypeCoinAndPrice(price)}}</p>
    <p class="fecha">{{getMonth(price.deliveryPeriod.startDate)}}</p>
    <p class="tipo-negocio">{{getTypeBusiness(price)}}</p>
</div>