import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyControlPanelComponent } from './body-control-panel.component';
import { OrderEraserTableModule } from './order-eraser-table/order-eraser-table.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { DetailsEyeModule } from 'src/app/components/slide-cards/details-eye/details-eye.module';
import { SearchInputModule } from 'src/app/components/inputs/search/search.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';
import { LoadingLottieModule } from 'src/app/components/loading/loading.module';

import { DialogEraserCreateModule } from 'src/app/components/dialogs/eraser-create/eraser-create.module';
import { DialogContractCreateModule } from 'src/app/components/dialogs/contract-create/contract-create.module';
import { DialogContractFailedModule } from 'src/app/components/dialogs/contract-failed/contract-failed.module';
import { ViewsSectionModule } from 'src/app/components/views-section/views-section.module';
import { HistoricalModule } from 'src/app/components/slide-cards/historical/historical.module';
import { TableMobileOrderModule } from 'src/app/components/board/mobile-order/mobile-order.module';
import { ButtonsToFixModule } from 'src/app/components/buttons-to-fix/buttons-to-fix.module';

@NgModule({
  declarations: [
    BodyControlPanelComponent
  ],
  imports: [
    CommonModule,
    OrderEraserTableModule,
    PipesModule,
    ArrowSvgModule,
    DetailsEyeModule,
    TableMobileOrderModule,
    ButtonsToFixModule,
    ViewsSectionModule,

    SearchInputModule,
    ButtonsConditionTypeModule,
    LoadingLottieModule,
    HistoricalModule,

    DialogContractCreateModule,
    DialogEraserCreateModule,
    DialogContractFailedModule,
  ], exports: [
    BodyControlPanelComponent
  ]
})
export class BodyControlPanelModule { }
