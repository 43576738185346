import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormOrderFromBoardComponent } from './form.component';
import { FieldsModule } from '../../fields/fields.module';

@NgModule({
  declarations: [
    FormOrderFromBoardComponent
  ],
  imports: [
    CommonModule,
    FieldsModule
  ], exports:[
    FormOrderFromBoardComponent
  ]
})
export class FormOrderFromBoardModule { }
