import { ChangeDetectorRef, Component, OnDestroy,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BodyBoard } from 'src/app/components/board/body-board';
import { setBuyerTypeFilter, showOrderDetails } from 'src/app/redux/actions/order.action';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, getSumOfTons } from 'src/app/shared/order';

@Component({
  selector: 'app-eraser-body',
  templateUrl: './eraser-body.component.html',
  styleUrls: ['./../../../shared/styles/body-board.scss']
})
export class EraserBodyComponent extends BodyBoard implements OnInit, OnDestroy{
  orders:Order[]=[]
  ordersSelected!:Order[];
  isShowDetails:boolean=false;
  orderDetails!:Order;

  ORDER_STATUS_CONTS = orderStatusConst;

  //DIALOGS
  dataModal:any={
    action:'',
    order:{},
    title:'',
    showModal:false
  };

  // ---- Options ---- \\
  isReload:boolean=false;

  refresh(){
    this.isReload=!this.isReload
  }

  constructor(store: Store<appState>, private orderV:OrderValidator, cdr:ChangeDetectorRef) {
    super(store, cdr);
  }

  ngOnInit(): void {
    this.showDetails$=this.store.select('order','orderBoard','showOrderDetails').subscribe(show=>{
      this.isShowDetails=show;
    });

    this.details$=this.store.select('order','orderBoard','orderDetails').subscribe(order=>{
      this.orderDetails=order;
    });

    this.selected$=this.store.select('order','orderBoard','ordersSelected').subscribe(orders=>{
      this.ordersSelected=orders;
    });

    this.buyerTypeSelected$=this.store.select('order','orderBoard','buyerType').subscribe(buyerType=>{
      this.buyerTypeSelected=buyerType;
    });
    
    this.setBodySubscription();
  }

  ngOnDestroy(): void {
    this.setBodyUnsubscription();
  }

  setBuyerType(identificator:string){
    this.store.dispatch(setBuyerTypeFilter({buyerType:identificator}));
  }

  setModal(event:any){
    this.dataModal=event;
  }

  applyAction(event:boolean){
    if(event){ //Press yes in modal
      if(this.dataModal.action=='delete'){
        this.orderV.deleteOrderInEraserOrder(this.dataModal.order);
      } else if (this.dataModal.action=='send'){
        this.orderV.saveOrder(this.dataModal.order, this.user);
        this.orderV.deleteOrderInEraserOrder(this.dataModal.order);
      }
      this.rememberEraserOrdersOfLocalStorage();
     
    }else{ //Press no in modal
      this.dataModal.showModal=false;
    }
    this.refresh();
    this.dataModal.action='';
  }

  closeSlide(){
    this.store.dispatch(showOrderDetails({show:false}));
  }

  rememberEraserOrdersOfLocalStorage() {
    this.orders = this.orderV.getEraserOrders();
  }

  getQuantOfTons(orders:Order[]){
    return getSumOfTons(orders);
  }
}