import { Component, OnInit } from '@angular/core';
import { PriceValidator } from 'src/app/shared/price';

@Component({
  selector: 'app-body-comercial',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  constructor(private priceV:PriceValidator) {
  }

  ngOnInit(): void {
    this.priceV.searchTodayPrices();
  }

}
