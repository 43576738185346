import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceComponent } from './price.component';
import { NumberInputModule } from '../../inputs/number/number.module';

@NgModule({
  declarations: [
    PriceComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule
  ], exports: [
    PriceComponent
  ]
})
export class PriceModule { }
