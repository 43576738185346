export const jujuyLocalities=[
    {"afip":"15","location":"23 De Agosto"},
    {"afip":"35","location":"Abdon Castro Tolay"},
    {"afip":"46","location":"Abra De Peñas"}
    ,
    {"afip":"47","location":"Abra De Pives"},
    {"afip":"53","location":"Abra Del Trigo"},
    {"afip":"57","location":"Abra Mayo"},
    {"afip":"58","location":"Abra Pampa"},
    {"afip":"61","location":"Abralaite"},
    {"afip":"78","location":"Achacamayoc"},
    {"afip":"119","location":"Agua Bendita"},
    {"afip":"123","location":"Agua Caliente"},
    {"afip":"124","location":"Agua Caliente De La Puna"},
    {"afip":"126","location":"Agua Chica"},
    {"afip":"129","location":"Agua De Castilla"},
    {"afip":"158","location":"Agua Negra"},
    {"afip":"160","location":"Agua Palomar"},
    {"afip":"203","location":"Aguas Blancas"},
    {"afip":"300","location":"Alegria"},
    {"afip":"317","location":"Alfarcito"},
    {"afip":"347","location":"Alisos  De Abajo"},
    {"afip":"348","location":"Alisos De Arriba"},
    {"afip":"395","location":"Alto Calilegua"},
    {"afip":"399","location":"Alto Comedero"},
    {"afip":"402","location":"Alto De Casa"},
    {"afip":"420","location":"Alto De Lozano"},
    {"afip":"422","location":"Alto De Mojon"},
    {"afip":"425","location":"Alto Del Angosto"},
    {"afip":"436","location":"Alto Del Saladillo"},
    {"afip":"444","location":"Alto Huancar"},
    {"afip":"451","location":"Alto Minero"},
    {"afip":"456","location":"Alto Potrerillo"},
    {"afip":"458","location":"Alto Quemado"},
    {"afip":"459","location":"Alto Quirquincho"},
    {"afip":"490","location":"Amancayoc"},
    {"afip":"493","location":"Amarguras"},
    {"afip":"576","location":"Angosto Del Perchel"},
    {"afip":"588","location":"Animas"},
    {"afip":"600","location":"Antiguo"},
    {"afip":"602","location":"Antiguyos"},
    {"afip":"620","location":"Antumpa"},
    {"afip":"623","location":"Aparejo"},
    {"afip":"626","location":"Aparzo"},
    {"afip":"661","location":"Arbolito Nuevo"},
    {"afip":"670","location":"Arenal Barroso"},
    {"afip":"702","location":"Arrayanal"},
    {"afip":"753","location":"Arroyo Colorado"},
    {"afip":"962","location":"Azul Pampa"},
    {"afip":"995","location":"Bajada Alta"},
    {"afip":"1118","location":"Baliazo"},
    {"afip":"1122","location":"Balliazo"},
    {"afip":"1174","location":"Barcena"},
    {"afip":"1217","location":"Barrio 9 De Julio"},
    {"afip":"1219","location":"Barrio Alberdi"},
    {"afip":"1222","location":"Barrio Alto La Loma"},
    {"afip":"1223","location":"Barrio Alto La Viña"}
    ,
    {"afip":"1227","location":"Barrio Bajo La Viña"}
    ,
    {"afip":"1237","location":"Barrio Chijra"},
    {"afip":"1239","location":"Barrio Cuyaya"},
    {"afip":"1274","location":"Barrio La Providencia"},
    {"afip":"1275","location":"Barrio La Union"},
    {"afip":"1282","location":"Barrio Lujan"},
    {"afip":"1289","location":"Barrio Parque 19 De Abril"},
    {"afip":"1318","location":"Barrio Santa Rita"},
    {"afip":"1336","location":"Barrios"},
    {"afip":"1366","location":"Bateas"},
    {"afip":"1521","location":"Boleteria"},
    {"afip":"1529","location":"Bomba"},
    {"afip":"1545","location":"Bordo La Isla"},
    {"afip":"1667","location":"Cabreria"},
    {"afip":"1676","location":"Cachi Punco"},
    {"afip":"1680","location":"Cachihuaico"},
    {"afip":"1687","location":"Cacho"},
    {"afip":"1695","location":"Cadilla"},
    {"afip":"1705","location":"Caimancito"},
    {"afip":"1710","location":"Cajas"},
    {"afip":"1727","location":"Calahoyo"},
    {"afip":"1752","location":"Calete"},
    {"afip":"1759","location":"Calilegua"},
    {"afip":"1844","location":"Campo Bajo"},
    {"afip":"1868","location":"Campo Colorado"},
    {"afip":"1954","location":"Campo La Tuna"},
    {"afip":"1977","location":"Campo Oculto"},
    {"afip":"2052","location":"Canchahuasi"},
    {"afip":"2053","location":"Canchuela"},
    {"afip":"2064","location":"Cangrejillos"},
    {"afip":"2065","location":"Cangrejos"},
    {"afip":"2095","location":"Capachacra"},
    {"afip":"2145","location":"Capla"},
    {"afip":"2152","location":"Caracara"},
    {"afip":"2157","location":"Carahunco"},
    {"afip":"2167","location":"Carayoc"},
    {"afip":"2171","location":"Carcel"},
    {"afip":"2248","location":"Casa Colorada"},
    {"afip":"2262","location":"Casa Negra"},
    {"afip":"2272","location":"Casabindo"},
    {"afip":"2283","location":"Casayock"},
    {"afip":"2294","location":"Casilla"},
    {"afip":"2298","location":"Casira"},
    {"afip":"2299","location":"Caspala"},
    {"afip":"2315","location":"Casti"},
    {"afip":"2325","location":"Catamontaña"}
    ,
    {"afip":"2329","location":"Catari"},
    {"afip":"2337","location":"Catua"},
    {"afip":"2341","location":"Cauchari"},
    {"afip":"2473","location":"Cañas"}
    ,
    {"afip":"2504","location":"Centro Forestal"},
    {"afip":"2565","location":"Cerro Chico"},
    {"afip":"2721","location":"Cerro Redondo"},
    {"afip":"2753","location":"Cerros Zapla"},
    {"afip":"2763","location":"Cevilar"},
    {"afip":"2823","location":"Chalican"},
    {"afip":"2826","location":"Chamical"},
    {"afip":"2870","location":"Chaupi Rodero"},
    {"afip":"2897","location":"Chañar Solo"}
    ,
    {"afip":"2903","location":"Chañaral"}
    ,
    {"afip":"2912","location":"Chañi"}
    ,
    {"afip":"2913","location":"Chañi Chico"}
    ,
    {"afip":"2939","location":"Chilcar"},
    {"afip":"2943","location":"Chilcayoc"},
    {"afip":"2985","location":"Chocoite"},
    {"afip":"2991","location":"Chorcan"},
    {"afip":"2992","location":"Chorojra"},
    {"afip":"2996","location":"Chorrillos"},
    {"afip":"2998","location":"Chorro"},
    {"afip":"3006","location":"Chucalezna"},
    {"afip":"3016","location":"Chulin O Inca Nueva"},
    {"afip":"3019","location":"Chuquina"},
    {"afip":"3034","location":"Cianzo"},
    {"afip":"3040","location":"Cienaga Grande"},
    {"afip":"3043","location":"Cienego Grande"},
    {"afip":"3044","location":"Cieneguillas"},
    {"afip":"3047","location":"Cincel"},
    {"afip":"3096","location":"Cochagate"},
    {"afip":"3102","location":"Cochinoca"},
    {"afip":"3106","location":"Coctaca"},
    {"afip":"3116","location":"Coiruro"},
    {"afip":"3167","location":"Colonia 8 De Septiembre"},
    {"afip":"3653","location":"Colonia Los Lapachos"},
    {"afip":"3976","location":"Colorados"},
    {"afip":"4017","location":"Condor"},
    {"afip":"4054","location":"Coranzulli"},
    {"afip":"4056","location":"Coraya"},
    {"afip":"4073","location":"Coronel Arias"},
    {"afip":"4124","location":"Corral Blanco"},
    {"afip":"4137","location":"Corral De Piedras"},
    {"afip":"4220","location":"Costillar"},
    {"afip":"4230","location":"Coyaguaima"},
    {"afip":"4276","location":"Cruz Nido"},
    {"afip":"4367","location":"Cusi Cusi"},
    {"afip":"4499","location":"Doglonzo"},
    {"afip":"4525","location":"Don Jorge"},
    {"afip":"4535","location":"Doncellas"},
    {"afip":"4582","location":"Duraznal"},
    {"afip":"4623","location":"El Aguilar"},
    {"afip":"4645","location":"El Amancay"},
    {"afip":"4649","location":"El Angosto"},
    {"afip":"4684","location":"El Bananal"},
    {"afip":"4729","location":"El Cabral"},
    {"afip":"4735","location":"El Cadillal"},
    {"afip":"4765","location":"El Cardonal"},
    {"afip":"4770","location":"El Carmen Perico De"},
    {"afip":"4783","location":"El Caulario"},
    {"afip":"4848","location":"El Chucupal"},
    {"afip":"4874","location":"El Condor"},
    {"afip":"4905","location":"El Cucho"},
    {"afip":"4971","location":"El Fuerte"},
    {"afip":"5089","location":"El Mistol"},
    {"afip":"5103","location":"El Monumento"},
    {"afip":"5108","location":"El Morro"},
    {"afip":"5116","location":"El Naranjo"},
    {"afip":"5144","location":"El Ollero"},
    {"afip":"5168","location":"El Palmar De San Francisco"},
    {"afip":"5238","location":"El Piquete"},
    {"afip":"5255","location":"El Pongo"},
    {"afip":"5271","location":"El Potrero De La Puna"},
    {"afip":"5321","location":"El Remate"},
    {"afip":"5334","location":"El Rio Negro"},
    {"afip":"5422","location":"El Tipal"},
    {"afip":"5424","location":"El Toba"},
    {"afip":"5432","location":"El Toro"},
    {"afip":"5609","location":"Escaya"},
    {"afip":"5637","location":"Esquina De Huancar"},
    {"afip":"5638","location":"Esquina De Quisto"},
    {"afip":"5644","location":"Esquina Grande"},
    {"afip":"5646","location":"Esquinas Blancas"},
    {"afip":"5720","location":"Estacion Perico"},
    {"afip":"5737","location":"Estacion Zootecnica"},
    {"afip":"5821","location":"Estancia Grande"},
    {"afip":"6056","location":"Falda Del Quebrachal"},
    {"afip":"6057","location":"Falda Mojon"},
    {"afip":"6058","location":"Falda Montosa"},
    {"afip":"6067","location":"Farillon"},
    {"afip":"6110","location":"Finca Agua Salada"},
    {"afip":"6111","location":"Finca Agua Tapada"},
    {"afip":"6131","location":"Finca La Lucrecia"},
    {"afip":"6132","location":"Finca La Realidad"},
    {"afip":"6135","location":"Finca Leach"},
    {"afip":"6147","location":"Finca Santa Cornelia"},
    {"afip":"6166","location":"Florencia"},
    {"afip":"6238","location":"Fraile Pintado"},
    {"afip":"6286","location":"Fundiciones"},
    {"afip":"6299","location":"Galeta"},
    {"afip":"6390","location":"General Manuel Savio"},
    {"afip":"6472","location":"Gobernador Ovejero"},
    {"afip":"6478","location":"Gobernador Tello"},
    {"afip":"6520","location":"Gral Manuel Savio"},
    {"afip":"6526","location":"Granadas"},
    {"afip":"6551","location":"Guachan"},
    {"afip":"6611","location":"Guayacan"},
    {"afip":"6618","location":"Guayatayoc"},
    {"afip":"6629","location":"Guemes"},
    {"afip":"6633","location":"Guerreros"},
    {"afip":"6710","location":"Higueritas"},
    {"afip":"6724","location":"Hipolito Yrigoyen  Est Iturbe"},
    {"afip":"6741","location":"Hornaditas"},
    {"afip":"6767","location":"Huacalera"},
    {"afip":"6770","location":"Huachichocana"},
    {"afip":"6773","location":"Huaico Chico"},
    {"afip":"6784","location":"Huallatayoc"},
    {"afip":"6788","location":"Huancar"},
    {"afip":"6831","location":"Huichaira"},
    {"afip":"6839","location":"Humahuaca"},
    {"afip":"6894","location":"Inca Huasi"},
    {"afip":"6954","location":"Ingenio La Esperanza"},
    {"afip":"6960","location":"Ingenio Ledesma"},
    {"afip":"6977","location":"Inticancho"},
    {"afip":"7020","location":"Isla Chica"},
    {"afip":"7091","location":"Ituaicochico"},
    {"afip":"7092","location":"Iturbe"},
    {"afip":"7125","location":"Jaramillo"},
    {"afip":"7199","location":"Juan Galan"},
    {"afip":"7208","location":"Juan Manuel De Rosas"},
    {"afip":"7229","location":"Juella"},
    {"afip":"7308","location":"La Almona"},
    {"afip":"7396","location":"La Cabaña"}
    ,
    {"afip":"7483","location":"La Chinaca"},
    {"afip":"7548","location":"La Cueva"},
    {"afip":"7727","location":"La Intermedia"},
    {"afip":"7846","location":"La Mendieta"},
    {"afip":"8021","location":"La Quiaca"},
    {"afip":"8060","location":"La Ronda"},
    {"afip":"8083","location":"La Sanga"},
    {"afip":"8178","location":"La Vertiente"},
    {"afip":"8179","location":"La Veta"},
    {"afip":"8303","location":"Laguna San Miguel"},
    {"afip":"8309","location":"Laguna Totorillas"},
    {"afip":"8312","location":"Lagunas"},
    {"afip":"8313","location":"Lagunas De Yala"},
    {"afip":"8314","location":"Lagunas Deyala"},
    {"afip":"8316","location":"Lagunilla El Carmen"},
    {"afip":"8317","location":"Lagunilla Ledesma"},
    {"afip":"8338","location":"Lapachal Ledesma"},
    {"afip":"8339","location":"Lapachal Santa Barbara"},
    {"afip":"8410","location":"Las Capillas"},
    {"afip":"8436","location":"Las Chicapenas"},
    {"afip":"8479","location":"Las Escaleras"},
    {"afip":"8620","location":"Las Pircas"},
    {"afip":"8733","location":"Lavayen"},
    {"afip":"8739","location":"Leachs"},
    {"afip":"8743","location":"Lecheria"},
    {"afip":"8747","location":"Ledesma"},
    {"afip":"8760","location":"Leon"},
    {"afip":"8775","location":"Lib Gral San Martin"},
    {"afip":"8818","location":"Lipan"},
    {"afip":"8830","location":"Llameria"},
    {"afip":"8836","location":"Llulluchayoc"},
    {"afip":"8868","location":"Loma Del Medio"},
    {"afip":"8874","location":"Loma Larga"},
    {"afip":"8879","location":"Loma Pelada"},
    {"afip":"8959","location":"Los Alisos"},
    {"afip":"8996","location":"Los Bayos"},
    {"afip":"9000","location":"Los Baños Termales"}
    ,
    {"afip":"9002","location":"Los Blancos"},
    {"afip":"9028","location":"Los Catres"},
    {"afip":"9170","location":"Los Lapachos"},
    {"afip":"9187","location":"Los Matos"},
    {"afip":"9441","location":"Lumara"},
    {"afip":"9502","location":"Maimara"},
    {"afip":"9517","location":"Mal Paso"},
    {"afip":"9616","location":"Maquinista Veron"},
    {"afip":"9695","location":"Marta"},
    {"afip":"9755","location":"Mayilte"},
    {"afip":"9756","location":"Mayinte"},
    {"afip":"9824","location":"Merco"},
    {"afip":"9863","location":"Milan"},
    {"afip":"9868","location":"Mina 9 De Octubre"},
    {"afip":"9869","location":"Mina Ajedrez"},
    {"afip":"9872","location":"Mina Belgica"},
    {"afip":"9898","location":"Mina Pirquitas"},
    {"afip":"9899","location":"Mina Pulpera"},
    {"afip":"9902","location":"Mina San Francisco"},
    {"afip":"9908","location":"Mina Sol De Mayo"},
    {"afip":"9913","location":"Minas Azules"},
    {"afip":"9914","location":"Minas De Borato"},
    {"afip":"9926","location":"Miniaio"},
    {"afip":"9938","location":"Miraflores De La Candelaria"},
    {"afip":"9945","location":"Mires"},
    {"afip":"9972","location":"Miyuyoc"},
    {"afip":"9975","location":"Mocoraite"},
    {"afip":"9986","location":"Mojon Azucena"},
    {"afip":"10016","location":"Molli Punco"},
    {"afip":"10019","location":"Molular"},
    {"afip":"10020","location":"Molulo"},
    {"afip":"10074","location":"Monte Rico"},
    {"afip":"10097","location":"Moralito"},
    {"afip":"10106","location":"Moreno Chico"},
    {"afip":"10113","location":"Morrito"},
    {"afip":"10114","location":"Morro"},
    {"afip":"10138","location":"Mulli Punco"},
    {"afip":"10149","location":"Muñayoc"}
    ,
    {"afip":"10199","location":"Nazareno"},
    {"afip":"10249","location":"Nogal"},
    {"afip":"10250","location":"Nogales"},
    {"afip":"10260","location":"Normenta"},
    {"afip":"10333","location":"Obraje San Jose"},
    {"afip":"10340","location":"Ocloyas"},
    {"afip":"10342","location":"Oculto"},
    {"afip":"10343","location":"Ocumazo"},
    {"afip":"10356","location":"Olacapato"},
    {"afip":"10358","location":"Olaroz Chico"},
    {"afip":"10359","location":"Olaroz Grande"},
    {"afip":"10400","location":"Ornillo"},
    {"afip":"10404","location":"Oros San Juan"},
    {"afip":"10405","location":"Orosmayo"},
    {"afip":"10422","location":"Ovara"},
    {"afip":"10463","location":"Paicone"},
    {"afip":"10468","location":"Pairique Chico"},
    {"afip":"10469","location":"Pairique Grande"},
    {"afip":"10486","location":"Palca De Aparzo"},
    {"afip":"10539","location":"Palpala"},
    {"afip":"10637","location":"Pampa Vieja"},
    {"afip":"10645","location":"Pampichuela"},
    {"afip":"10651","location":"Pan De Azucar Mina"},
    {"afip":"10764","location":"Pasajes"},
    {"afip":"10917","location":"Pasto Pampa"},
    {"afip":"10925","location":"Pastos Chicos"},
    {"afip":"10938","location":"Paulete"},
    {"afip":"10939","location":"Paulina"},
    {"afip":"10949","location":"Payo"},
    {"afip":"10955","location":"Paño"}
    ,
    {"afip":"11005","location":"Perico"},
    {"afip":"11006","location":"Perico Del Carmen"},
    {"afip":"11007","location":"Perico San Juan"},
    {"afip":"11024","location":"Peña Colorada"}
    ,
    {"afip":"11037","location":"Picacho"},
    {"afip":"11079","location":"Pie De La Cuesta"},
    {"afip":"11088","location":"Piedra Chota"},
    {"afip":"11133","location":"Pilcomayo"},
    {"afip":"11185","location":"Piscuno"},
    {"afip":"11187","location":"Pisungo"},
    {"afip":"11349","location":"Posta De Hornillos"},
    {"afip":"11351","location":"Posta Guardaparque Nacional Ca"},
    {"afip":"11360","location":"Potrerillo"},
    {"afip":"11364","location":"Potrero Alegre"},
    {"afip":"11370","location":"Potrero De La Puna"},
    {"afip":"11398","location":"Pozo Bravo"},
    {"afip":"11399","location":"Pozo Cabado"},
    {"afip":"11405","location":"Pozo Colorado"},
    {"afip":"11422","location":"Pozo De Las Avispas"},
    {"afip":"11517","location":"Pozuelo"},
    {"afip":"11522","location":"Prediliana"},
    {"afip":"11600","location":"Pucara"},
    {"afip":"11607","location":"Pueblo"},
    {"afip":"11635","location":"Pueblo Ledesma"},
    {"afip":"11649","location":"Pueblo Pla"},
    {"afip":"11715","location":"Puerta De Colorados"},
    {"afip":"11724","location":"Puerta De Lipan"},
    {"afip":"11728","location":"Puerta De Salas"},
    {"afip":"11735","location":"Puerta Patacal"},
    {"afip":"11736","location":"Puerta Potrero"},
    {"afip":"11739","location":"Puerta Vieja"},
    {"afip":"11874","location":"Puerto Tolava"},
    {"afip":"11900","location":"Puesto"},
    {"afip":"11921","location":"Puesto Chico"},
    {"afip":"11971","location":"Puesto Del Marquez"},
    {"afip":"11995","location":"Puesto Grande"},
    {"afip":"12106","location":"Pumahuasi"},
    {"afip":"12109","location":"Puna De Jujuy"},
    {"afip":"12121","location":"Punta Canal"},
    {"afip":"12122","location":"Punta Corral"},
    {"afip":"12123","location":"Punta De Agua"},
    {"afip":"12135","location":"Punta Del Campo"},
    {"afip":"12161","location":"Puntas De Colorados"},
    {"afip":"12172","location":"Purmamarca"},
    {"afip":"12204","location":"Quebrada Huasamayo"},
    {"afip":"12206","location":"Quebraleña"}
    ,
    {"afip":"12214","location":"Quenti Taco"},
    {"afip":"12218","location":"Quera"},
    {"afip":"12220","location":"Queta"},
    {"afip":"12223","location":"Queñoal"}
    ,
    {"afip":"12226","location":"Quichagua"},
    {"afip":"12241","location":"Quimazo"},
    {"afip":"12271","location":"Quisquine"},
    {"afip":"12287","location":"Rachaite"},
    {"afip":"12305","location":"Ramada"},
    {"afip":"12358","location":"Rastrojos"},
    {"afip":"12368","location":"Real De Los Toros"},
    {"afip":"12385","location":"Receptoria"},
    {"afip":"12392","location":"Redonda"},
    {"afip":"12445","location":"Reyes"},
    {"afip":"12446","location":"Reyes Villa Jardin"},
    {"afip":"12545","location":"Rinconada"},
    {"afip":"12546","location":"Rinconadillas"},
    {"afip":"12559","location":"Rio Blanco"},
    {"afip":"12607","location":"Rio Negro"},
    {"afip":"12642","location":"Riverito"},
    {"afip":"12651","location":"Rodeitos"},
    {"afip":"12655","location":"Rodeo Chico"},
    {"afip":"12672","location":"Rodero"},
    {"afip":"12691","location":"Ronque"},
    {"afip":"12692","location":"Rontuyoc"},
    {"afip":"12736","location":"Rumi Cruz"},
    {"afip":"12804","location":"Sala"},
    {"afip":"12817","location":"Saladillo Ledesma"},
    {"afip":"12819","location":"Saladillo San Pedro"},
    {"afip":"12852","location":"Salitre"},
    {"afip":"12908","location":"San Antonio Perico De"},
    {"afip":"12963","location":"San Francisco De Alfarcito"},
    {"afip":"13020","location":"San Jose De La Rinconada"},
    {"afip":"13028","location":"San Jose De Miraflores"},
    {"afip":"13035","location":"San Jose Del Bordo"},
    {"afip":"13036","location":"San Jose Del Chañi"}
    ,
    {"afip":"13046","location":"San Juan De Dios"},
    {"afip":"13050","location":"San Juan De Oros"},
    {"afip":"13051","location":"San Juan De Quillagues"},
    {"afip":"13063","location":"San Leon"},
    {"afip":"13065","location":"San Lucas"},
    {"afip":"13108","location":"San Pablo De Reyes"},
    {"afip":"13114","location":"San Pedrito"},
    {"afip":"13121","location":"San Pedro De Iruya"},
    {"afip":"13149","location":"San Salvador De Jujuy"},
    {"afip":"13173","location":"Sansana"},
    {"afip":"13180","location":"Santa Ana De La Puna"},
    {"afip":"13267","location":"Santa Rosa Tumbaya"},
    {"afip":"13268","location":"Santa Rosa Valle Grande"},
    {"afip":"13295","location":"Santillo"},
    {"afip":"13309","location":"Santuario"},
    {"afip":"13311","location":"Santuyoc"},
    {"afip":"13369","location":"Sauzal"},
    {"afip":"13372","location":"Sauzalito"},
    {"afip":"13375","location":"Sayate"},
    {"afip":"13406","location":"Senador Perez"},
    {"afip":"13412","location":"Sepultura"},
    {"afip":"13427","location":"Sey"},
    {"afip":"13433","location":"Siberia"},
    {"afip":"13470","location":"Siete Aguas"},
    {"afip":"13474","location":"Sijes"},
    {"afip":"13499","location":"Socabon"},
    {"afip":"13539","location":"Sorcuyo"},
    {"afip":"13550","location":"Soysolayte"},
    {"afip":"13600","location":"Suripujio"},
    {"afip":"13606","location":"Susques"},
    {"afip":"13607","location":"Susuyaco"},
    {"afip":"13622","location":"Tabladitas"},
    {"afip":"13624","location":"Tablon"},
    {"afip":"13660","location":"Tacta"},
    {"afip":"13678","location":"Tafna"},
    {"afip":"13692","location":"Tala Grusa"},
    {"afip":"13735","location":"Tambillos"},
    {"afip":"13744","location":"Tanques"},
    {"afip":"13768","location":"Tarijita"},
    {"afip":"13798","location":"Tejadas"},
    {"afip":"13830","location":"Termas De Reyes"},
    {"afip":"13837","location":"Tesorero"},
    {"afip":"13838","location":"Teuco"},
    {"afip":"13855","location":"Tilcara"},
    {"afip":"13862","location":"Tilquiza"},
    {"afip":"13872","location":"Timon Cruz"},
    {"afip":"13891","location":"Tio Mayo"},
    {"afip":"13904","location":"Tiraxi"},
    {"afip":"13905","location":"Tiraxi Chico"},
    {"afip":"13911","location":"Toba"},
    {"afip":"13916","location":"Tocol"},
    {"afip":"13950","location":"Toquero"},
    {"afip":"13951","location":"Toquillera"},
    {"afip":"13998","location":"Totorito"},
    {"afip":"14010","location":"Trancas"},
    {"afip":"14026","location":"Trementinal"},
    {"afip":"14072","location":"Tres Morros"},
    {"afip":"14136","location":"Tucumancito"},
    {"afip":"14140","location":"Tuite"},
    {"afip":"14149","location":"Tumbaya"},
    {"afip":"14150","location":"Tumbaya Grande"},
    {"afip":"14154","location":"Tunalito"},
    {"afip":"14163","location":"Turilari"},
    {"afip":"14164","location":"Turu Tari"},
    {"afip":"14165","location":"Tusaquillas"},
    {"afip":"14200","location":"Uquia"},
    {"afip":"14252","location":"Valle Colorado"},
    {"afip":"14268","location":"Valle Grande"},
    {"afip":"14283","location":"Varas"},
    {"afip":"14314","location":"Venecias Argentinas"},
    {"afip":"14334","location":"Veta Mina Aguilar"},
    {"afip":"14355","location":"Vicuñayoc"}
    ,
    {"afip":"14405","location":"Villa Argentina"},
    {"afip":"14461","location":"Villa Ciudad De Nieva"},
    {"afip":"14503","location":"Villa Del Perchel"},
    {"afip":"14588","location":"Villa Gorriti"},
    {"afip":"14896","location":"Vinalito"},
    {"afip":"14910","location":"Viscachani"},
    {"afip":"14928","location":"Vizacachal"},
    {"afip":"14938","location":"Volcan"},
    {"afip":"14941","location":"Volcan Higuera"},
    {"afip":"14988","location":"Yacoraite"},
    {"afip":"15002","location":"Yala"},
    {"afip":"15003","location":"Yala De Monte Carmelo"},
    {"afip":"15034","location":"Yavi"},
    {"afip":"15035","location":"Yavi Chico"},
    {"afip":"15040","location":"Yerba Buena Ledesma"},
    {"afip":"15041","location":"Yerba Buena Tilcara"},
    {"afip":"15056","location":"Yoscaba"},
    {"afip":"15073","location":"Yuto"},
    {"afip":"15098","location":"Zapla"},
    {"afip":"15210","location":"Aguas Calientes"},
    {"afip":"15223","location":"Algarrobal"},
    {"afip":"15250","location":"Alto Verde"},
    {"afip":"15298","location":"Arroyo Del Medio"},
    {"afip":"15309","location":"Atalaya"},
    {"afip":"15345","location":"Barrancas"},
    {"afip":"15368","location":"Barro Negro"},
    {"afip":"15382","location":"Bella Vista"},
    {"afip":"15474","location":"Candelaria"},
    {"afip":"15485","location":"Carahuasi"},
    {"afip":"15506","location":"Casa Grande"},
    {"afip":"15508","location":"Casa Vieja"},
    {"afip":"15517","location":"Casillas"},
    {"afip":"15549","location":"Ceibal"},
    {"afip":"15555","location":"Cerrillos"},
    {"afip":"15562","location":"Cerrito"},
    {"afip":"15594","location":"Cerro Negro"},
    {"afip":"15627","location":"Chañarcito"}
    ,
    {"afip":"15647","location":"Cienaga"},
    {"afip":"15790","location":"Corralito"},
    {"afip":"15798","location":"Cortaderas"},
    {"afip":"15849","location":"El Acheral"},
    {"afip":"15856","location":"El Aibal"},
    {"afip":"15863","location":"El Algarrobal"},
    {"afip":"15882","location":"El Arenal"},
    {"afip":"15923","location":"El Brete"},
    {"afip":"15937","location":"El Callejon"},
    {"afip":"16003","location":"El Colorado"},
    {"afip":"16041","location":"El Durazno"},
    {"afip":"16092","location":"El Manantial"},
    {"afip":"16127","location":"El Molino"},
    {"afip":"16133","location":"El Mollar"},
    {"afip":"16145","location":"El Moreno"},
    {"afip":"16159","location":"El Olvido"},
    {"afip":"16166","location":"El Palmar"},
    {"afip":"16191","location":"El Perchel"},
    {"afip":"16214","location":"El Porvenir"},
    {"afip":"16275","location":"El Quemado"},
    {"afip":"16329","location":"El Saladillo"},
    {"afip":"16351","location":"El Sauce"},
    {"afip":"16374","location":"El Sunchal"},
    {"afip":"16387","location":"El Talar"},
    {"afip":"16449","location":"Encrucijada"},
    {"afip":"16453","location":"Ensenada"},
    {"afip":"16457","location":"Entre Rios"},
    {"afip":"16474","location":"Esquina"},
    {"afip":"16560","location":"Guerrero"},
    {"afip":"16568","location":"Hornillos"},
    {"afip":"16588","location":"Iriarte"},
    {"afip":"16592","location":"Isla Grande"},
    {"afip":"16632","location":"La Aguadita"},
    {"afip":"16678","location":"La Bajada"},
    {"afip":"16682","location":"La Banda"},
    {"afip":"16709","location":"La Calera"},
    {"afip":"16767","location":"La Cienaga"},
    {"afip":"16823","location":"La Cruz"},
    {"afip":"16829","location":"La Cuesta"},
    {"afip":"16875","location":"La Esperanza"},
    {"afip":"16914","location":"La Falda"},
    {"afip":"17124","location":"La Ollada"},
    {"afip":"17127","location":"La Ovejeria"},
    {"afip":"17212","location":"La Puerta"},
    {"afip":"17230","location":"La Quinta"},
    {"afip":"17242","location":"La Reduccion"},
    {"afip":"17315","location":"La Toma"},
    {"afip":"17335","location":"La Union"},
    {"afip":"17400","location":"Lagunillas"},
    {"afip":"17443","location":"Las Cañadas"}
    ,
    {"afip":"17527","location":"Las Higuerillas"},
    {"afip":"17531","location":"Las Higueritas"},
    {"afip":"17618","location":"Las Pampitas"},
    {"afip":"17628","location":"Las Pichanas"},
    {"afip":"17641","location":"Las Quintas"},
    {"afip":"17716","location":"Lindero"},
    {"afip":"17720","location":"Loma Blanca"},
    {"afip":"17807","location":"Los Cedros"},
    {"afip":"17870","location":"Los Manantiales"},
    {"afip":"17976","location":"Lozano"},
    {"afip":"18048","location":"Mira Flores"},
    {"afip":"18062","location":"Molinos"},
    {"afip":"18105","location":"Naranjito"},
    {"afip":"18115","location":"Nogalito"},
    {"afip":"18132","location":"Ojo De Agua"},
    {"afip":"18147","location":"Oratorio"},
    {"afip":"18160","location":"Palma Sola"},
    {"afip":"18168","location":"Palo A Pique"},
    {"afip":"18173","location":"Palo Blanco"},
    {"afip":"18180","location":"Palo Santo"},
    {"afip":"18188","location":"Pampa Blanca"},
    {"afip":"18194","location":"Pampa Larga"},
    {"afip":"18237","location":"Peñas Blancas"}
    ,
    {"afip":"18245","location":"Piedra Blanca"},
    {"afip":"18256","location":"Piedras Amontonadas"},
    {"afip":"18259","location":"Piedras Blancas"},
    {"afip":"18284","location":"Porvenir"},
    {"afip":"18294","location":"Potrero"},
    {"afip":"18338","location":"Pozo Verde"},
    {"afip":"18358","location":"Pueblo Nuevo"},
    {"afip":"18365","location":"Pueblo Viejo"},
    {"afip":"18396","location":"Puesto Nuevo"},
    {"afip":"18400","location":"Puesto Viejo"},
    {"afip":"18412","location":"Punta Del Agua"},
    {"afip":"18452","location":"Ramallo"},
    {"afip":"18502","location":"Rio Colorado"},
    {"afip":"18507","location":"Rio Grande"},
    {"afip":"18515","location":"Rio Seco"},
    {"afip":"18529","location":"Rodeo"},
    {"afip":"18578","location":"San Andres"},
    {"afip":"18590","location":"San Antonio"},
    {"afip":"18607","location":"San Bernardo"},
    {"afip":"18669","location":"San Francisco"},
    {"afip":"18675","location":"San Gabriel"},
    {"afip":"18720","location":"San Javier"},
    {"afip":"18748","location":"San Jose"},
    {"afip":"18762","location":"San Juan"},
    {"afip":"18772","location":"San Juancito"},
    {"afip":"18789","location":"San Lorenzo"},
    {"afip":"18857","location":"San Pedro"},
    {"afip":"18873","location":"San Rafael"},
    {"afip":"18891","location":"San Roque"},
    {"afip":"18921","location":"San Vicente"},
    {"afip":"18932","location":"Santa Ana"},
    {"afip":"18942","location":"Santa Barbara"},
    {"afip":"18951","location":"Santa Catalina"},
    {"afip":"18959","location":"Santa Clara"},
    {"afip":"19039","location":"Santa Rita"},
    {"afip":"19077","location":"Santo Domingo"},
    {"afip":"19147","location":"Soledad"},
    {"afip":"19176","location":"Talar"},
    {"afip":"19195","location":"Toro Muerto"},
    {"afip":"19226","location":"Tres Cruces"},
    {"afip":"19258","location":"Triunvirato"},
    {"afip":"19373","location":"Ciudad De Perico"},
    {"afip":"20900","location":"Potrero"},
    {"afip":"20901","location":"Toba"},
    {"afip":"20902","location":"Carahunco"},
    {"afip":"20903","location":"Centro Forestal"},
    {"afip":"20904","location":"Cerros Zapla"},
    {"afip":"20905","location":"El Algarrobal"},
    {"afip":"20906","location":"El Brete"},
    {"afip":"20907","location":"El Cucho"},
    {"afip":"20908","location":"El Remate"},
    {"afip":"20909","location":"La Cuesta"},
    {"afip":"20910","location":"Las Capillas"},
    {"afip":"20911","location":"Las Escaleras"},
    {"afip":"20912","location":"Los Blancos"},
    {"afip":"20913","location":"Mina 9 De Octubre"},
    {"afip":"20914","location":"Palpala"},
    {"afip":"20915","location":"La Veta"},
    {"afip":"20916","location":"Peñas Blancas"}
    ,
    {"afip":"20917","location":"Quera"},
    {"afip":"20918","location":"El Ollero"},
    {"afip":"20919","location":"Los Alisos"},
    {"afip":"20920","location":"Rio Blanco"},
    {"afip":"20921","location":"Rio Grande"},
    {"afip":"20922","location":"Saladillo Ledesma"},
    {"afip":"20923","location":"Agua Caliente"},
    {"afip":"20924","location":"Bella Vista"},
    {"afip":"20925","location":"El Arenal"},
    {"afip":"20926","location":"El Sunchal"},
    {"afip":"20927","location":"Ingenio La Esperanza"},
    {"afip":"20928","location":"La Ollada"},
    {"afip":"20929","location":"San Rafael"},
    {"afip":"20930","location":"Santa Rita"},
    {"afip":"20931","location":"Guayatayoc"},
    {"afip":"20932","location":"Hornillos"},
    {"afip":"20933","location":"La Cienaga"},
    {"afip":"20934","location":"Potrero"},
    {"afip":"20935","location":"San Juan"},
    {"afip":"20936","location":"Tio Mayo"},
    {"afip":"20937","location":"Turu Tari"},
    {"afip":"20938","location":"Bella Vista"},
    {"afip":"20939","location":"Chorrillos"},
    {"afip":"20940","location":"La Banda"},
    {"afip":"20941","location":"La Sanga"},
    {"afip":"20942","location":"Agua Chica"},
    {"afip":"20943","location":"Alto Del Saladillo"},
    {"afip":"20944","location":"Condor"},
    {"afip":"20945","location":"Cortaderas"},
    {"afip":"20946","location":"Higueritas"},
    {"afip":"20947","location":"Huancar"},
    {"afip":"20948","location":"La Cienaga"},
    {"afip":"20949","location":"La Puerta"},
    {"afip":"20950","location":"Lagunas De Yala"},
    {"afip":"20951","location":"Leon"},
    {"afip":"20952","location":"Molinos"},
    {"afip":"20953","location":"Posta De Hornillos"},
    {"afip":"20954","location":"Potrero"},
    {"afip":"20955","location":"Tesorero"},
    {"afip":"20956","location":"Chilcar"},
    {"afip":"20957","location":"Cortaderas"},
    {"afip":"20958","location":"Loma Larga"},
    {"afip":"20959","location":"Molulo"},
    {"afip":"20960","location":"Piscuno"},
    {"afip":"20961","location":"Ramada"},
    {"afip":"20962","location":"San Antonio"},
    {"afip":"20963","location":"San Francisco"},
    {"afip":"20964","location":"San Lucas"},
    {"afip":"20965","location":"Santa Clara"},
    {"afip":"20966","location":"Soledad"},
    {"afip":"20967","location":"Agua De Castilla"},
    {"afip":"20968","location":"Condor"},
    {"afip":"20969","location":"Corral Blanco"},
    {"afip":"20970","location":"La Cienaga"},
    {"afip":"20971","location":"La Cueva"},
    {"afip":"20993","location":"Chaupi Rodero"},
    {"afip":"21682","location":"Chorrillo"},
    {"afip":"22077","location":"San Pedro De Jujuy"},
    {"afip":"22078","location":"Libertador General San Martin"},
    {"afip":"22079","location":"Chucupal"},
    {"afip":"22080","location":"El Carmen"},
    {"afip":"22081","location":"Monterrico"},
    {"afip":"22082","location":"Ovejeria"},
    {"afip":"22083","location":"Santa Rosa"}
    ]