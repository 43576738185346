<div *ngIf="conditionTypeSelected == CONDITION_TYPES.TO_PRICE || 
(conditionTypeSelected == CONDITION_TYPES.TO_FIX && boardSelected != BOARDS_TYPES.CARDS)
 else cardsIndicators" class="table table-desktop" #table [style]="getMinHeight(table)">

<div class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive" [ngClass]="{'is-to-fix': conditionTypeSelected==CONDITION_TYPES.TO_FIX}">
        <thead>
            <tr row-price-head class="sticky-header" [viewSelected]="viewSelected" [filter]="priceFilter" [buyerType]="buyerTypeSelected" 
            [conditionType]="conditionTypeSelected" [filterIdentificator]="filterIdentificator" (filterSelect)="selectFilter($event)">
        </thead>
        <tbody>
                <ng-container *ngFor="let product of pricesWithPipe; index as i;">
            <tr *ngIf="!(isChild(product) && !parentShowInDropdown(product, i))"
            class="{{isIdInArrayParent(product._id)?'is-open':'is-close'}}"
            [ngClass]="{'isRetired': product.status == 4,'is-new': getIsNew(product),
            'is-parent': isParent(product), 'is-child': isChild(product),
            'is-open': getArrowDirection(product._id)=='up' || isParent(product)}">
                <td class="options-column"> 

                    <plus-sign-svg (click)="createOrderWithPrice(product)"></plus-sign-svg>
                    <eye-svg (click)="openModalPrice(product)" [active]="false"></eye-svg>

                    <three-point-svg (click)="openOptions(i)"></three-point-svg>
                    <div class="menu-options in-menu-option" *ngIf="optionIdentificator==i">
                        <ul class="in-menu-option">
                            <li (click)="showHistorical(product)" class="in-menu-option">
                                <clock-svg></clock-svg> Historial de cambios</li>
                            <!--<li (click)="createOrderWithPrice(product)" class="in-menu-option">Generar orden</li>-->
                        </ul>
                    </div>
                    
                    <arrow-board-svg class="arrow" [direction]="getArrowDirection(product._id)" *ngIf="isParent(product) || haveIndicators(product)" 
                    (click)="clickInArrow(product, $event)"></arrow-board-svg>
                </td>    
            
                <td class="observations-column" *ngIf="viewSelected.observations">
                    <div class="observations-column-contain">
                        <featured-price-svg class="icon"
                        [active]="true" *ngIf="product.observations.isFeatured==1"></featured-price-svg>

                        
                        <div class="message-container icon tooltip-container"  *ngIf="product.observations.isMessage==1">
                            <message-svg class="icon" 
                            [active]="true"></message-svg>

                            <div class="tooltip message">
                                <span>
                                    Comentario: <br>
                                    {{product.observations.message}}
                                </span>
                                <div class="triangle"></div>
                            </div>
                        </div>

                        <gravanz-svg class="icon" 
                        [active]="true" *ngIf="product.observations.isBussinesGravanz==1"></gravanz-svg>
                    </div>
                </td>

                <td *ngIf="viewSelected.state" class="{{STATUS[product.status].toLowerCase()}}">
                    {{STATUS[product.status]}}
                </td>

                <td *ngIf="viewSelected.product">
                    <span>{{getProductName(product)}}</span>
                </td>

                <td *ngIf="buyerTypeSelected==buyerTypes.BUYER || buyerTypeSelected==buyerTypes.ALL">
                    {{ BUYERS[product.observations.buyer]}}
                </td>

                <td *ngIf="viewSelected.typeBusiness">{{getBusinessType(product)}}</td>

                <td *ngIf="viewSelected.placeOfDelivery">
                    {{getPlaceOfDelivery(product)}}
                </td>

                <td *ngIf="viewSelected.deliveryPeriod">
                    <div class="delivery-period-container tooltip-container" [ngClass]="{'is-valid-until': isValidUntil(product)}">

                        <span>{{getDeliveryPeriod(product)}}</span>
                        
                        <div class="tooltip delivery-period-message" *ngIf="isValidUntil(product)">
                            <span>Valido hasta: {{getValidUntilText(product)}}</span>
                            <div class="triangle"></div>
                        </div>

                    </div>
                </td>

                <td *ngIf="viewSelected.quality" class="quality-column">
                    <div class="quality-container tooltip-container {{QUALITY[product.quality].toLowerCase()}}" 
                    [ngClass]="{'not-quality-particularity': product.qualityIBM<0}">
                        <span class="quality">{{getQuality(product)}}</span>
                        <div class="tooltip quality-message" *ngIf="product.qualityIBM > 0">
                            <span>
                                Particularidad de calidad: <br>
                                "{{getQualityIBM(product)}}"
                            </span>
                            <div class="triangle"></div>
                        </div>
                    </div>
                </td>

                <td class="price-column" *ngIf="viewSelected?.price && conditionTypeSelected!=CONDITION_TYPES.TO_FIX">
                    <div class="price-container tooltip-container">
                        <span>{{getTypeCoinAndPriceWithPoints(product)}}</span>
                    </div>
                </td>
                
                <td *ngIf="viewSelected.wayPay" class="">
                    <span>{{getOnlyWayPayName(product)}}</span><br>
                    <p class="days-way-pay">{{getDaysWayPay(product)}} - {{getPercentageWayPay(product)}}</p>
                </td>
                <td *ngIf="viewSelected.expiration && conditionTypeSelected==CONDITION_TYPES.TO_PRICE">{{product.wayPay.expiration}}</td>
                <td *ngIf="viewSelected.pesificacion">{{getPesification(product)}}</td>
                <td *ngIf="viewSelected.harvest">{{product.harvest}}</td>
                <td *ngIf="viewSelected.businessParticularities">{{getBusinessParticularities(product)}}</td>
                <td *ngIf="viewSelected.hour">{{product.dataOfcreation.hour}}</td>
            </tr>

            <tr class="indicators" *ngIf="haveIndicators(product) && isIdInArrayParent(product._id)">
                <td class="first-col-indicators"></td>
            
                <td colspan="14">
                    <div class="indicators-container">
                        <app-indicators-table [price]="product" [showFirstColumn]="false"></app-indicators-table>
                    </div>
                </td>
            </tr>

            </ng-container>
        </tbody>
    </table>
</div>

</div>


<ng-template #cardsIndicators>
    <div class="container-card-indicators">
        <card-indicators class="card-indicator" *ngFor="let price of pricesWithPipe" [price]="price"></card-indicators>
    </div>
</ng-template>