export const formosaLocalities=[
    {"afip":"7","location":"19 De Marzo"},
    {"afip":"110","location":"Agente Argentino Alegre"},
    {"afip":"111","location":"Agente Felipe Santiago Ibañez"}
    ,
    {"afip":"213","location":"Aguas Negras"},
    {"afip":"319","location":"Alfonsina Storni"},
    {"afip":"366","location":"Alolague"},
    {"afip":"557","location":"Andrade Olegario Victor"},
    {"afip":"559","location":"Andres Flores"},
    {"afip":"627","location":"Apayerey"},
    {"afip":"949","location":"Ayudante Paredes"},
    {"afip":"987","location":"Bahia Negra"},
    {"afip":"1123","location":"Ballon"},
    {"afip":"1154","location":"Banco Payagua"},
    {"afip":"1311","location":"Barrio San Jose Obrero"},
    {"afip":"1320","location":"Barrio Sud America"},
    {"afip":"1340","location":"Bartolome De Las Casas"},
    {"afip":"1384","location":"Bañaderos"}
    ,
    {"afip":"1507","location":"Boca Del Riacho De Pilaga"},
    {"afip":"1514","location":"Bocarin"},
    {"afip":"1527","location":"Bolsa De Palomo"},
    {"afip":"1577","location":"Brigadier General Pueyrredon"},
    {"afip":"1582","location":"Bruchard"},
    {"afip":"1586","location":"Buen Lugar"},
    {"afip":"1633","location":"Caballo Muerto"},
    {"afip":"1651","location":"Cabo 1Ro Casimiro Benitez"},
    {"afip":"1652","location":"Cabo Adriano Ayala"},
    {"afip":"1655","location":"Cabo Noroña"}
    ,
    {"afip":"1656","location":"Cabo Primero Chavez"},
    {"afip":"1842","location":"Campo Azcurra"},
    {"afip":"1893","location":"Campo Del Cielo"},
    {"afip":"1912","location":"Campo El Suri"},
    {"afip":"1932","location":"Campo Goreta"},
    {"afip":"1934","location":"Campo Hardy"},
    {"afip":"1978","location":"Campo Oswald"},
    {"afip":"1994","location":"Campo Redondo"},
    {"afip":"1997","location":"Campo Rigonato"},
    {"afip":"2137","location":"Capitan Juan Sola"},
    {"afip":"2191","location":"Carlos Pelegrini"},
    {"afip":"2193","location":"Carlos Saavedra Lamas"},
    {"afip":"2288","location":"Casco Cue"},
    {"afip":"2327","location":"Cataneo Cue"},
    {"afip":"2413","location":"Cañada Doce"}
    ,
    {"afip":"2432","location":"Cañada San Pedro"}
    ,
    {"afip":"2485","location":"Ceibo Trece"},
    {"afip":"2505","location":"Centro Fronterizo Clorinda"},
    {"afip":"2812","location":"Chagaday"},
    {"afip":"2977","location":"Chirochilas"},
    {"afip":"3023","location":"Churqui Cue"},
    {"afip":"3080","location":"Clorinda"},
    {"afip":"3082","location":"Cmte Principal Ismael St"},
    {"afip":"3084","location":"Cnel Miguel Martinez De Hoz"},
    {"afip":"3086","location":"Cnia Aborigen B Bartolome D"},
    {"afip":"3154","location":"Colonia  Francisco J Muñiz"}
    ,
    {"afip":"3160","location":"Colonia 25 De Mayo"},
    {"afip":"3164","location":"Colonia 5 De Octubre"},
    {"afip":"3168","location":"Colonia 8 De Setiembre"},
    {"afip":"3187","location":"Colonia Alfonso"},
    {"afip":"3197","location":"Colonia Alto Tigre"},
    {"afip":"3211","location":"Colonia Aquino"},
    {"afip":"3265","location":"Colonia Bouvier"},
    {"afip":"3280","location":"Colonia Campo Villafañe"}
    ,
    {"afip":"3283","location":"Colonia Cano"},
    {"afip":"3332","location":"Colonia Dalmacia"},
    {"afip":"3369","location":"Colonia El Alba"},
    {"afip":"3375","location":"Colonia El Catorce"},
    {"afip":"3389","location":"Colonia El Olvido"},
    {"afip":"3400","location":"Colonia El Silencio"},
    {"afip":"3410","location":"Colonia El Zapallito"},
    {"afip":"3479","location":"Colonia Guillermina"},
    {"afip":"3503","location":"Colonia Isla Alvarez"},
    {"afip":"3504","location":"Colonia Isla De Oro"},
    {"afip":"3505","location":"Colonia Isla Sola"},
    {"afip":"3517","location":"Colonia Juan B Alberdi"},
    {"afip":"3526","location":"Colonia Juanita"},
    {"afip":"3540","location":"Colonia La Brava"},
    {"afip":"3552","location":"Colonia La Disciplina"},
    {"afip":"3605","location":"Colonia La Sociedad"},
    {"afip":"3660","location":"Colonia Los Tres Reyes"},
    {"afip":"3766","location":"Colonia Palmar Grande"},
    {"afip":"3774","location":"Colonia Pastoril"},
    {"afip":"3782","location":"Colonia Perin"},
    {"afip":"3801","location":"Colonia Puente Pucu"},
    {"afip":"3806","location":"Colonia Reconquista"},
    {"afip":"3834","location":"Colonia San Bernardo"},
    {"afip":"3857","location":"Colonia San Pablo"},
    {"afip":"3889","location":"Colonia Santoro"},
    {"afip":"3899","location":"Colonia Siete Quebrados"},
    {"afip":"3917","location":"Colonia Tatane"},
    {"afip":"3955","location":"Colonia Villa Rica"},
    {"afip":"3986","location":"Comandante Fontana"},
    {"afip":"3996","location":"Comisaria Pte Yrigoyen"},
    {"afip":"4072","location":"Coronel Argentino Larrabure"},
    {"afip":"4089","location":"Coronel Enrique Rostagno"},
    {"afip":"4092","location":"Coronel Felix Bogado"},
    {"afip":"4100","location":"Coronel Jose I Warnes"},
    {"afip":"4188","location":"Costa Del Lindo"},
    {"afip":"4191","location":"Costa Del Pilcomayo"},
    {"afip":"4208","location":"Costa Rio Negro"},
    {"afip":"4211","location":"Costa Salado"},
    {"afip":"4354","location":"Curtiembre Cue"},
    {"afip":"4357","location":"Curupay"},
    {"afip":"4426","location":"Desvio Los Matacos"},
    {"afip":"4487","location":"Doctor Carlos Montag"},
    {"afip":"4494","location":"Doctor Gumersindo Sayago"},
    {"afip":"4495","location":"Doctor Luis Agote"},
    {"afip":"4507","location":"Domingo F Sarmiento"},
    {"afip":"4570","location":"Dr E Ramos Mejia  Chiriguanos"},
    {"afip":"4616","location":"El Acheral"},
    {"afip":"4625","location":"El Aibalito"},
    {"afip":"4627","location":"El Alambrado"},
    {"afip":"4648","location":"El Angelito"},
    {"afip":"4673","location":"El Azotado"},
    {"afip":"4715","location":"El Bordo Santo"},
    {"afip":"4719","location":"El Bragado"},
    {"afip":"4785","location":"El Cavado"},
    {"afip":"4794","location":"El Ceibal"},
    {"afip":"4863","location":"El Coati"},
    {"afip":"4865","location":"El Cogoik"},
    {"afip":"4883","location":"El Corralito"},
    {"afip":"4884","location":"El Corredero"},
    {"afip":"4983","location":"El Gato"},
    {"afip":"4996","location":"El Guajho"},
    {"afip":"5070","location":"El Marcado"},
    {"afip":"5090","location":"El Mistolar"},
    {"afip":"5137","location":"El Oculto"},
    {"afip":"5146","location":"El Olvido"},
    {"afip":"5147","location":"El Ombu"},
    {"afip":"5169","location":"El Palo Santo"},
    {"afip":"5228","location":"El Pilon"},
    {"afip":"5230","location":"El Pimpin"},
    {"afip":"5232","location":"El Pindo"},
    {"afip":"5249","location":"El Poi"},
    {"afip":"5254","location":"El Pombero"},
    {"afip":"5259","location":"El Porteñito"}
    ,
    {"afip":"5268","location":"El Potrerito"},
    {"afip":"5294","location":"El Quebranto"},
    {"afip":"5323","location":"El Resguardo"},
    {"afip":"5339","location":"El Rosado"},
    {"afip":"5345","location":"El Salado"},
    {"afip":"5386","location":"El Sombrero Negro"},
    {"afip":"5391","location":"El Surr"},
    {"afip":"5413","location":"El Tastas"},
    {"afip":"5501","location":"El Yacare"},
    {"afip":"5506","location":"El Yuchan"},
    {"afip":"5507","location":"El Yulo"},
    {"afip":"5521","location":"El ñandu"}
    ,
    {"afip":"5647","location":"Esquinitas"},
    {"afip":"5795","location":"Estancia El Ciervo"},
    {"afip":"5889","location":"Estancia Las Horquetas"},
    {"afip":"5989","location":"Estanislao Del Campo"},
    {"afip":"6002","location":"Esterito"},
    {"afip":"6004","location":"Estero Patiño"}
    ,
    {"afip":"6033","location":"Ex Fortin Sola"},
    {"afip":"6037","location":"Ex Posta General Lavalle"},
    {"afip":"6170","location":"Florencio Sanchez"},
    {"afip":"6181","location":"Formosa"},
    {"afip":"6192","location":"Fortin Cabo 1 Lugones"},
    {"afip":"6193","location":"Fortin Cabo 1Ro Chaves"},
    {"afip":"6194","location":"Fortin Cabo 1Ro Chavez"},
    {"afip":"6202","location":"Fortin Fontana"},
    {"afip":"6204","location":"Fortin Galpon"},
    {"afip":"6206","location":"Fortin Guemes"},
    {"afip":"6209","location":"Fortin La Soledad"},
    {"afip":"6213","location":"Fortin Media Luna"},
    {"afip":"6219","location":"Fortin Pilcomayo"},
    {"afip":"6222","location":"Fortin Sargento 1Ro Leyes"},
    {"afip":"6251","location":"Francisco Narciso De Laprida"},
    {"afip":"6260","location":"Fray Mamerto Esquiu"},
    {"afip":"6293","location":"Gabriela Mistral"},
    {"afip":"6320","location":"Garcete Cue"},
    {"afip":"6351","location":"Gendarme Viviano Garcete"},
    {"afip":"6372","location":"General Francisco B Bosch"},
    {"afip":"6380","location":"General Julio De Vedia"},
    {"afip":"6386","location":"General Lucio Victorio Mansill"},
    {"afip":"6389","location":"General Manuel Belgrano"},
    {"afip":"6391","location":"General Mosconi"},
    {"afip":"6397","location":"General Pablo Ricchieri"},
    {"afip":"6464","location":"Gobernador Luna Olmos"},
    {"afip":"6482","location":"Gobernador Yalur"},
    {"afip":"6518","location":"Gral Ignacio H Fotheringham"},
    {"afip":"6525","location":"Gran Guardia"},
    {"afip":"6556","location":"Guadalcazar"},
    {"afip":"6619","location":"Guaycolec"},
    {"afip":"6692","location":"Hermindo Bonas"},
    {"afip":"6698","location":"Herradura"},
    {"afip":"6722","location":"Hipolito Vieytes"},
    {"afip":"6752","location":"Hospital Rural"},
    {"afip":"6862","location":"Ibarreta"},
    {"afip":"6909","location":"Ing Guillermo N Juarez"},
    {"afip":"6926","location":"Ingeniero Enrique H Faure"},
    {"afip":"7011","location":"Isla 9 De Julio"},
    {"afip":"7014","location":"Isla Apango"},
    {"afip":"7018","location":"Isla Caraya"},
    {"afip":"7028","location":"Isla De Puen"},
    {"afip":"7049","location":"Isla Oca"},
    {"afip":"7051","location":"Isla Payagua"},
    {"afip":"7063","location":"Isla Toldo"},
    {"afip":"7071","location":"Isleta"},
    {"afip":"7151","location":"Joaquin V Gonzalez"},
    {"afip":"7160","location":"Jose Cancio"},
    {"afip":"7167","location":"Jose Hernandez"},
    {"afip":"7172","location":"Jose Manuel Estrada"},
    {"afip":"7197","location":"Juan G Bazan"},
    {"afip":"7236","location":"Julio Cue"},
    {"afip":"7726","location":"La Inmaculada"},
    {"afip":"7753","location":"La Junta"},
    {"afip":"7797","location":"La Lucrecia"},
    {"afip":"7811","location":"La Manija"},
    {"afip":"7910","location":"La Palma Sola"},
    {"afip":"7914","location":"La Palmita"},
    {"afip":"7926","location":"La Pasion"},
    {"afip":"7952","location":"La Picadita"},
    {"afip":"8158","location":"La Urbana"},
    {"afip":"8282","location":"Laguna Gallo"},
    {"afip":"8284","location":"Laguna Ines"},
    {"afip":"8292","location":"Laguna Murua"},
    {"afip":"8293","location":"Laguna Naick Neck"},
    {"afip":"8311","location":"Laguna Yema"},
    {"afip":"8324","location":"Lamadrid"},
    {"afip":"8380","location":"Las Avispas"},
    {"afip":"8395","location":"Las Bolivianas"},
    {"afip":"8439","location":"Las Choyas"},
    {"afip":"8542","location":"Las Lolas"},
    {"afip":"8683","location":"Las Tres Marias"},
    {"afip":"8737","location":"Lazo Quemado"},
    {"afip":"8751","location":"Legua A"},
    {"afip":"8855","location":"Loma Clavel"},
    {"afip":"8882","location":"Loma Sene"},
    {"afip":"8886","location":"Loma Zapatu"},
    {"afip":"8939","location":"Loro Cue"},
    {"afip":"9043","location":"Los Chaguancos"},
    {"afip":"9064","location":"Los Claveles"},
    {"afip":"9116","location":"Los Esteros"},
    {"afip":"9163","location":"Los Inmigrantes"},
    {"afip":"9223","location":"Los Nidos"},
    {"afip":"9264","location":"Los Pilagas"},
    {"afip":"9342","location":"Los Suspiros"},
    {"afip":"9376","location":"Los Tres Reyes"},
    {"afip":"9416","location":"Lucero Cue"},
    {"afip":"9491","location":"Maestra Blanca Gomez"},
    {"afip":"9492","location":"Maestro Fermin Baez"},
    {"afip":"9634","location":"Marca M"},
    {"afip":"9655","location":"Maria Cristina"},
    {"afip":"9681","location":"Mariano Boedo"},
    {"afip":"9735","location":"Matias Gulacsi"},
    {"afip":"9763","location":"Mayor Marcelo T Rojas"},
    {"afip":"9764","location":"Mayor Vicente E Villafañe"}
    ,
    {"afip":"9822","location":"Mercedes Cue"},
    {"afip":"9852","location":"Miguel Cane"},
    {"afip":"9950","location":"Mision El Carmen"},
    {"afip":"9951","location":"Mision Evangelica Laguna De Ya"},
    {"afip":"9957","location":"Mision Tacaagle"},
    {"afip":"9963","location":"Mistol Marcado"},
    {"afip":"9987","location":"Mojon De Fierro"},
    {"afip":"10029","location":"Monseñor De Andrea"}
    ,
    {"afip":"10032","location":"Monte Agudo"},
    {"afip":"10045","location":"Monte Claro"},
    {"afip":"10064","location":"Monte Lindo"},
    {"afip":"10065","location":"Monte Lindo  Cnia Pastoril"},
    {"afip":"10079","location":"Monteagudo"},
    {"afip":"10226","location":"Nicora"},
    {"afip":"10291","location":"Nueva Italia"},
    {"afip":"10312","location":"Nuevo Pilcomayo"},
    {"afip":"10362","location":"Olegario Victor Andrade"},
    {"afip":"10505","location":"Palmar Chico"},
    {"afip":"10507","location":"Palmar Largo"},
    {"afip":"10510","location":"Palmarcito"},
    {"afip":"10530","location":"Palo Santo"},
    {"afip":"10669","location":"Para Todo"},
    {"afip":"10750","location":"Parque Botanico Forestal Igr L"},
    {"afip":"10754","location":"Parque Nacional"},
    {"afip":"10823","location":"Paso De Los Tobas"},
    {"afip":"10826","location":"Paso De Naite"},
    {"afip":"10867","location":"Paso La Cruz"},
    {"afip":"10885","location":"Paso Nalte"},
    {"afip":"10933","location":"Pato Marcado"},
    {"afip":"10942","location":"Pavao"},
    {"afip":"11122","location":"Pigo"},
    {"afip":"11127","location":"Pilanga Iii"},
    {"afip":"11169","location":"Pirane"},
    {"afip":"11287","location":"Poncho Quemado"},
    {"afip":"11312","location":"Porteño Viejo"}
    ,
    {"afip":"11337","location":"Porton Negro"},
    {"afip":"11346","location":"Posta Cambio A Zalazar"},
    {"afip":"11352","location":"Posta Lencina"},
    {"afip":"11353","location":"Posta San Martin 1"},
    {"afip":"11354","location":"Posta San Martin 2"},
    {"afip":"11356","location":"Posta Sargento Cabral"},
    {"afip":"11375","location":"Potrero De Los Caballos"},
    {"afip":"11384","location":"Potrero Norte"},
    {"afip":"11421","location":"Pozo De La Yegua"},
    {"afip":"11423","location":"Pozo De Las Botijas"},
    {"afip":"11430","location":"Pozo De Los Chanchos"},
    {"afip":"11435","location":"Pozo De Mara"},
    {"afip":"11437","location":"Pozo De Navagan"},
    {"afip":"11450","location":"Pozo Del Cuchillo"},
    {"afip":"11458","location":"Pozo Del Leon"},
    {"afip":"11460","location":"Pozo Del Maza"},
    {"afip":"11466","location":"Pozo Del Mortero"},
    {"afip":"11479","location":"Pozo El Lecheron"},
    {"afip":"11489","location":"Pozo La China"},
    {"afip":"11490","location":"Pozo La Negra"},
    {"afip":"11512","location":"Pozo Verde"},
    {"afip":"11513","location":"Pozo Verde  Ingeniero G N Ju"},
    {"afip":"11531","location":"Presidente Yrigoyen"},
    {"afip":"11778","location":"Puerto Dalmacia"},
    {"afip":"11805","location":"Puerto Irigoyen"},
    {"afip":"11843","location":"Puerto Pilcomayo"},
    {"afip":"11850","location":"Puerto Ramona"},
    {"afip":"11861","location":"Puerto San Carlos"},
    {"afip":"11893","location":"Puerto Yrigoyen"},
    {"afip":"11909","location":"Puesto Aguara"},
    {"afip":"12146","location":"Punta Guia"},
    {"afip":"12157","location":"Punta Pora"},
    {"afip":"12182","location":"Quebracho Marcado"},
    {"afip":"12286","location":"Racedo Escobar"},
    {"afip":"12344","location":"Ranero Cue"},
    {"afip":"12395","location":"Reduccion Cacique Coquena"},
    {"afip":"12431","location":"Reserva Natural Formosa"},
    {"afip":"12449","location":"Riacho He He"},
    {"afip":"12450","location":"Riacho Lindo"},
    {"afip":"12451","location":"Riacho Negro"},
    {"afip":"12452","location":"Riacho Ramirez"},
    {"afip":"12460","location":"Ricardo Guiraldes"},
    {"afip":"12530","location":"Rincon Florido"},
    {"afip":"12543","location":"Rincon ñandu"}
    ,
    {"afip":"12544","location":"Rincon ñaro"}
    ,
    {"afip":"12569","location":"Rio Cue"},
    {"afip":"12668","location":"Rodeo Tapiti"},
    {"afip":"12716","location":"Rozadito"},
    {"afip":"12865","location":"Salvacion"},
    {"afip":"12922","location":"San Camilo"},
    {"afip":"12967","location":"San Francisco De Laishi"},
    {"afip":"12985","location":"San Hilario"},
    {"afip":"13081","location":"San Martin 1"},
    {"afip":"13085","location":"San Martin Ii"},
    {"afip":"13319","location":"Sargento Agramonte"},
    {"afip":"13320","location":"Sargento Cabral"},
    {"afip":"13330","location":"Saturnino Segurola"},
    {"afip":"13392","location":"Segunda Punta"},
    {"afip":"13402","location":"Selva Maria"},
    {"afip":"13472","location":"Siete Palmas"},
    {"afip":"13519","location":"Soldado Alberto Villalba"},
    {"afip":"13520","location":"Soldado Dante Salvatierra"},
    {"afip":"13521","location":"Soldado Edmundo Sosa"},
    {"afip":"13522","location":"Soldado Ermindo Luna"},
    {"afip":"13523","location":"Soldado Heriberto Avalos"},
    {"afip":"13524","location":"Soldado Ismael Sanchez"},
    {"afip":"13526","location":"Soldado Marcelino Torales"},
    {"afip":"13527","location":"Soldado Ramon A Arrieta"},
    {"afip":"13528","location":"Soldado Tomas Sanchez"},
    {"afip":"13536","location":"Sombrero Negro"},
    {"afip":"13559","location":"Sto Ayudante V Sanabria"},
    {"afip":"13560","location":"Sto Mayor Bernardo Aguila"},
    {"afip":"13565","location":"Subteniente Perin"},
    {"afip":"13566","location":"Subtte Ricardo E Masaferro"},
    {"afip":"13580","location":"Sumayen"},
    {"afip":"13784","location":"Tatane"},
    {"afip":"13788","location":"Tatu Pire"},
    {"afip":"13809","location":"Teniente Brown"},
    {"afip":"13868","location":"Timbo Pora"},
    {"afip":"13935","location":"Tomas Godoy Cruz"},
    {"afip":"13966","location":"Toro Paso"},
    {"afip":"14013","location":"Transito Cue"},
    {"afip":"14065","location":"Tres Lagunas  Herradura"},
    {"afip":"14066","location":"Tres Lagunas  Pilcomayo"},
    {"afip":"14069","location":"Tres Marias"},
    {"afip":"14078","location":"Tres Pocitos"},
    {"afip":"14125","location":"Tte Cnel Gaspar Campos"},
    {"afip":"14128","location":"Tte Gral Juan C Sanche"},
    {"afip":"14129","location":"Tte Gral Rosendo N Fra"},
    {"afip":"14202","location":"Urbana Vieja"},
    {"afip":"14235","location":"Vaca Perdida"},
    {"afip":"14375","location":"Villa Adelaida"},
    {"afip":"14524","location":"Villa Dos Trece"},
    {"afip":"14542","location":"Villa Escolar"},
    {"afip":"14573","location":"Villa General Guemes"},
    {"afip":"14579","location":"Villa General Urquiza"},
    {"afip":"14590","location":"Villa Gral Manuel Belgrano"},
    {"afip":"14600","location":"Villa Hermosa"},
    {"afip":"14660","location":"Villa Lucero"},
    {"afip":"14692","location":"Villa Mercedes"},
    {"afip":"14760","location":"Villa Real"},
    {"afip":"14903","location":"Virasol"},
    {"afip":"15066","location":"Yunca"},
    {"afip":"15121","location":"Zorrilla Cue"},
    {"afip":"15160","location":"9 De Julio"},
    {"afip":"15193","location":"Agua Verde"},
    {"afip":"15243","location":"Alto Alegre"},
    {"afip":"15267","location":"Angostura"},
    {"afip":"15277","location":"Arbol Solo"},
    {"afip":"15327","location":"Bajo Hondo"},
    {"afip":"15330","location":"Bajo Verde"},
    {"afip":"15367","location":"Barrio San Martin"},
    {"afip":"15381","location":"Bella Vista"},
    {"afip":"15421","location":"Buena Vista"},
    {"afip":"15446","location":"Campo Alegre"},
    {"afip":"15458","location":"Campo Grande"},
    {"afip":"15481","location":"Capilla San Antonio"},
    {"afip":"15666","location":"Colonia Buena Vista"},
    {"afip":"15670","location":"Colonia Coronel Dorrego"},
    {"afip":"15700","location":"Colonia Puente Uriburu"},
    {"afip":"15705","location":"Colonia Sabina"},
    {"afip":"15715","location":"Colonia San Isidro"},
    {"afip":"15718","location":"Colonia San Jose"},
    {"afip":"15735","location":"Colonia Santa Catalina"},
    {"afip":"15750","location":"Colonia Santa Rosa"},
    {"afip":"15866","location":"El Algarrobo"},
    {"afip":"15876","location":"El Arbol Solo"},
    {"afip":"15877","location":"El Arbolito"},
    {"afip":"15961","location":"El Cañon"}
    ,
    {"afip":"16002","location":"El Colorado"},
    {"afip":"16017","location":"El Descanso"},
    {"afip":"16021","location":"El Desmonte"},
    {"afip":"16055","location":"El Espinillo"},
    {"afip":"16114","location":"El Mirador"},
    {"afip":"16123","location":"El Mojon"},
    {"afip":"16165","location":"El Palmar"},
    {"afip":"16177","location":"El Paraiso"},
    {"afip":"16192","location":"El Perdido"},
    {"afip":"16208","location":"El Porteño"}
    ,
    {"afip":"16271","location":"El Quebracho"},
    {"afip":"16274","location":"El Quemado"},
    {"afip":"16282","location":"El Recreo"},
    {"afip":"16290","location":"El Remanso"},
    {"afip":"16350","location":"El Sauce"},
    {"afip":"16361","location":"El Silencio"},
    {"afip":"16367","location":"El Simbolar"},
    {"afip":"16378","location":"El Tacuruzal"},
    {"afip":"16405","location":"El Totoral"},
    {"afip":"16446","location":"El Zorro"},
    {"afip":"16469","location":"Espinillo"},
    {"afip":"16503","location":"Estero Grande"},
    {"afip":"16516","location":"Florentino Ameghino"},
    {"afip":"16529","location":"Frontera"},
    {"afip":"16602","location":"Ituzaingo"},
    {"afip":"16614","location":"Juan Jose Paso"},
    {"afip":"16691","location":"La Blanca"},
    {"afip":"16761","location":"La China"},
    {"afip":"16788","location":"La Colonia"},
    {"afip":"16874","location":"La Esperanza"},
    {"afip":"16930","location":"La Florencia"},
    {"afip":"16936","location":"La Florida"},
    {"afip":"16953","location":"La Frontera"},
    {"afip":"17043","location":"La Libertad"},
    {"afip":"17054","location":"La Loma"},
    {"afip":"17096","location":"La Media Luna"},
    {"afip":"17117","location":"La Nobleza"},
    {"afip":"17135","location":"La Paloma"},
    {"afip":"17197","location":"La Primavera"},
    {"afip":"17250","location":"La Represa"},
    {"afip":"17291","location":"La Sirena"},
    {"afip":"17297","location":"La Soledad"},
    {"afip":"17375","location":"Laguna Blanca"},
    {"afip":"17452","location":"Las Cañitas"}
    ,
    {"afip":"17496","location":"Las Delicias"},
    {"afip":"17563","location":"Las Lomitas"},
    {"afip":"17588","location":"Las Mochas"},
    {"afip":"17656","location":"Las Saladas"},
    {"afip":"17731","location":"Loma Hermosa"},
    {"afip":"17783","location":"Los Baldes"},
    {"afip":"17849","location":"Los Galpones"},
    {"afip":"17927","location":"Los Pocitos"},
    {"afip":"18032","location":"Media Luna"},
    {"afip":"18159","location":"Palma Sola"},
    {"afip":"18310","location":"Pozo De Las Garzas"},
    {"afip":"18311","location":"Pozo De Piedra"},
    {"afip":"18324","location":"Pozo Del Tigre"},
    {"afip":"18330","location":"Pozo Hondo"},
    {"afip":"18343","location":"Presidente Avellaneda"},
    {"afip":"18344","location":"Primavera"},
    {"afip":"18348","location":"Primera Junta"},
    {"afip":"18512","location":"Rio Muerto"},
    {"afip":"18546","location":"Saladillo"},
    {"afip":"18551","location":"Salado"},
    {"afip":"18589","location":"San Antonio"},
    {"afip":"18629","location":"San Cayetano"},
    {"afip":"18709","location":"San Isidro"},
    {"afip":"18718","location":"San Jacinto"},
    {"afip":"18761","location":"San Juan"},
    {"afip":"18788","location":"San Lorenzo"},
    {"afip":"18829","location":"San Miguel"},
    {"afip":"18880","location":"San Ramon"},
    {"afip":"18912","location":"San Simon"},
    {"afip":"18950","location":"Santa Catalina"},
    {"afip":"18997","location":"Santa Isabel"},
    {"afip":"19023","location":"Santa Maria"},
    {"afip":"19050","location":"Santa Rosa"},
    {"afip":"19062","location":"Santa Teresa"},
    {"afip":"19140","location":"Sol De Mayo"},
    {"afip":"19153","location":"Suipacha"},
    {"afip":"19236","location":"Tres Lagunas"},
    {"afip":"19242","location":"Tres Mojones"},
    {"afip":"19249","location":"Tres Pozos"},
    {"afip":"19300","location":"Villa Emilia"},
    {"afip":"20972","location":"San Isidro"},
    {"afip":"20973","location":"Tres Pozos"},
    {"afip":"20974","location":"Colonia Santa Rosa"},
    {"afip":"20975","location":"San Antonio"},
    {"afip":"20976","location":"Tres Lagunas"},
    {"afip":"20977","location":"Tte Cnel Gaspar Campos"},
    {"afip":"20978","location":"El Paraiso"},
    {"afip":"20979","location":"Palma Sola"},
    {"afip":"20980","location":"Colonia Santa Rosa"},
    {"afip":"20981","location":"El Corralito"},
    {"afip":"20982","location":"Espinillo"},
    {"afip":"20983","location":"La Esperanza"},
    {"afip":"20984","location":"Las Cañitas"}
    ,
    {"afip":"20985","location":"Olegario Victor Andrade"},
    {"afip":"20986","location":"El Desmonte"},
    {"afip":"20987","location":"El Totoral"},
    {"afip":"20988","location":"Media Luna"},
    {"afip":"20989","location":"Mistol Marcado"},
    {"afip":"20990","location":"Pozo De Los Chanchos"},
    {"afip":"20991","location":"San Isidro"},
    {"afip":"20992","location":"Santa Teresa"},
    {"afip":"21003","location":"Presidente Yrigoyen"},
    {"afip":"21004","location":"El Pindo"},
    {"afip":"21005","location":"Nuevo Pilcomayo"},
    {"afip":"21006","location":"Riacho Lindo"},
    {"afip":"21007","location":"San Antonio"},
    {"afip":"21008","location":"Alto Alegre"},
    {"afip":"21009","location":"Colonia San Isidro"},
    {"afip":"21010","location":"El Totoral"},
    {"afip":"21011","location":"Los Claveles"},
    {"afip":"22074","location":"Lucio V. Mansilla"},
    {"afip":"22075","location":"Mision San Fco. De Laishi"},
    {"afip":"22076","location":"General Guemes"}
    ]