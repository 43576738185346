<div class="background-svg">
    
    <svg class="svg-20" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        
        <path d="M10.9997 0.833008C9.18668 0.833008 7.4144 1.37062 5.90695 2.37787C4.3995 3.38512 3.22459 4.81675 2.53078 6.49174C1.83698 8.16673 1.65545 10.0098 2.00915 11.788C2.36284 13.5662 3.23589 15.1995 4.51787 16.4815C5.79985 17.7635 7.43319 18.6365 9.21135 18.9902C10.9895 19.3439 12.8326 19.1624 14.5076 18.4686C16.1826 17.7748 17.6142 16.5999 18.6215 15.0924C19.6287 13.585 20.1663 11.8127 20.1663 9.99968C20.1663 8.79589 19.9292 7.6039 19.4686 6.49174C19.0079 5.37959 18.3327 4.36907 17.4815 3.51786C16.6303 2.66666 15.6198 1.99145 14.5076 1.53078C13.3955 1.07011 12.2035 0.833008 10.9997 0.833008V0.833008ZM15.583 10.9163H11.9163V14.583C11.9163 14.8261 11.8198 15.0593 11.6479 15.2312C11.476 15.4031 11.2428 15.4997 10.9997 15.4997C10.7566 15.4997 10.5234 15.4031 10.3515 15.2312C10.1796 15.0593 10.083 14.8261 10.083 14.583V10.9163H6.41635C6.17323 10.9163 5.94007 10.8198 5.76816 10.6479C5.59626 10.4759 5.49968 10.2428 5.49968 9.99968C5.49968 9.75656 5.59626 9.5234 5.76816 9.3515C5.94007 9.17959 6.17323 9.08301 6.41635 9.08301H10.083V5.41634C10.083 5.17323 10.1796 4.94007 10.3515 4.76816C10.5234 4.59625 10.7566 4.49968 10.9997 4.49968C11.2428 4.49968 11.476 4.59625 11.6479 4.76816C11.8198 4.94007 11.9163 5.17323 11.9163 5.41634V9.08301H15.583C15.8261 9.08301 16.0593 9.17959 16.2312 9.3515C16.4031 9.5234 16.4997 9.75656 16.4997 9.99968C16.4997 10.2428 16.4031 10.4759 16.2312 10.6479C16.0593 10.8198 15.8261 10.9163 15.583 10.9163Z" fill="#597393"/>
        <defs>
        <filter id="filter0_d_2449_144723" x="-2.16699" y="0.833008" width="26.333" height="26.333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2449_144723"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2449_144723" result="shape"/>
        </filter>
        </defs>
        </svg>
        
    
<div class="tooltip row-center plus-sign w-60">
    <span>Crear orden</span>
</div>
</div>