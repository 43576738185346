<div class="objetives">
    <div class="title-and-select">
        <p class="title">Objetivos</p>
        <div class="selects">

            <!--  (change)="setSelectPeriod($event)"  -->
            <div class="select" (click)="openOption('period')">
                <span>{{periodString}} <arrow-svg [direction]="'down'"></arrow-svg></span>

                <div class="option option-period" *ngIf="option=='period'">
                    <span value="weekly" (click)="setSelectPeriod('weekly')">Semanal</span>
                    <span value="monthly" (click)="setSelectPeriod('monthly')">Mensual</span>
                    <span value="quarterly" (click)="setSelectPeriod('quarterly')">Trimestral</span>
                    <!--<span value="yearly">Anual</span>-->
                </div>
            </div>

            <!--  (change)="setSelectPeriod($event)"  -->
            <div class="select" (click)="openOption('table')">
                <span>Mesa <arrow-svg [direction]="'down'"></arrow-svg></span>

                <div class="option table" *ngIf="option=='table'">
                    <span *ngFor="let table of TABLES" (click)="setSelectTable(table)">{{table}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="info-period">
        <span> {{periodInfo}} - {{workUnitInfo}}</span>
    </div>

    <div class="grafic-and-info">

        <div class="grafic">
            <app-grafic [average]="objetives.tons.missingAverage" [periodSelect]="periodSelect"
                [values]="objetives.grafic"></app-grafic>
        </div>

        <div class="section">
            <p class="tons">Toneladas</p>

            <div class="info">
                    <!-- Is area commodities-->

                    <div class="contenedor" id="missing">
                        <p>Faltantes</p>
                        <p class="valor">{{objetives.tons.missing | numberPipe}}</p>
                    </div>


                    <div class="contenedor" id="sells">
                        <p>Vendidas</p>
                        <p class="valor">{{objetives.tons.dailySales | numberPipe}}</p>
                    </div>

                    <!--<div class="contenedor" id="missing-average">
                        <p>Promedio faltantes</p>
                        <p class="valor">{{objetives.tons.missingAverage}}</p>
                    </div>-->

                    <div class="contenedor" id="trimestral" *ngIf="periodSelect=='weekly'">
                        <p>Semanal</p>
                        <p class="valor">{{objetives.tons.quarterly | numberPipe}}</p>
                    </div>
                    <div class="contenedor" id="trimestral" *ngIf="periodSelect=='monthly'">
                        <p>Mensual</p>
                        <p class="valor">{{objetives.tons.quarterly | numberPipe}}</p>
                    </div>
                    <div class="contenedor" id="trimestral" *ngIf="periodSelect=='quarterly'">
                        <p>Trimestral</p>
                        <p class="valor">{{objetives.tons.quarterly | numberPipe}}</p>
                    </div>
                    <div class="contenedor" id="trimestral" *ngIf="periodSelect=='yearly'">
                        <p>Anual</p>
                        <p class="valor">{{objetives.tons.quarterly | numberPipe}}</p>
                    </div>
                    <!-------------------------->

            </div>
        </div>
    </div>
</div>