import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NAVEGATION } from 'src/app/shared/const/navegation';

@Component({
  selector: 'app-generate-orden',
  templateUrl: './generate-orden.component.html',
  styleUrls: ['./generate-orden.component.scss']
})
export class GenerateOrdenComponent {
  constructor(private router:Router) {}

  goToPriceBoard(){
    this.router.navigate(['/'+NAVEGATION.PRICE_BOARD]);
  }

  goToGenerateOrderManual(){
    this.router.navigate(['/'+NAVEGATION.GENERATE_MANUAL_ORDER]);
  }
}