<div class="center-container" *ngIf="showAnimation">
  <div class="animation-container">
    <ng-lottie [options]="splashLogin" (complete)="closeSplash($event)" ></ng-lottie>
  </div>
</div>

<div class="body" *ngIf="!showAnimation">
  <app-headers class="width-100 header"></app-headers>
  <app-boton-google class="button-google"></app-boton-google>
</div>


