import { Component, OnInit,Output,EventEmitter,HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from './../../fields/fields.component';
import { PriceValidator } from 'src/app/shared/price';
import { buyers } from 'src/app/shared/dict/buyers';
import { setArrayOfBuyers } from 'src/app/redux/actions/price.action';

@Component({
  selector: 'buyers-generate-price',
  templateUrl: './buyers-generate-price.component.html',
  styleUrls: ['./../slide-card.scss', './../../fields/fields.component.scss', './buyers-generate-price.component.scss']
})
export class BuyerGeneratePriceSlideCardComponent  extends Field implements OnInit{
  @Output() closeEvent = new EventEmitter<string>();
  @Output() deleteBuyer = new EventEmitter<boolean>();
  @Input() options:any[]=[];
  selectedIndex:number=0;

  constructor(store:Store<appState>, private priceV:PriceValidator) { 
    super(store);
    this.array = Object.keys(buyers);
    this.array.shift();
    this.OBJECT=buyers;
    this.deleteLastValueInArray();
    this.initalElementSelected='Ingresar un comprador';

  }

  ngOnInit(): void {
    if(fieldType.PRICE == this.fieldIdentificator){
      this.tag=priceTags.observations;
      this.subTag=priceTags.buyer;
    } else {
      this.tag=orderTags.price;
      this.subTag=priceTags.observations;
      this.thirdTag=priceTags.buyer;
    }
    //this.setIdentifyClassName();
  }

  getElementSelected(){
    if(this.options.length>0){
      this.isOptionSelected=true;
      this.elementSelected= this.options.length + (this.options.length==1?" comprador seleccionado": " compradores seleccionados");
    } else {
      this.elementSelected='';
      this.isOptionSelected=false;
      this.deleteBuyer.emit(true);
    }
  }

  override setShowOptions(boolean: boolean): void {
    this.showOptions=boolean;
    this.selected(0);
  }

  selected(index:number){
    this.selectedIndex=index;
  }

  closeSlide(){
    this.showOptions=false;
  }

  close(){
    this.closeEvent.emit('close');
  }

  @HostListener('document:keydown', ['$event'])
  pressEsc(event: KeyboardEvent){
    if(event.key=='Escape'){
      this.close();
    }
  }

  removeOption(option:string){
    if(this.isOptionInOptionsSelected(option)){
      this.options=this.options.filter((item)=>item!=option);
      this.getElementSelected();
    }
  }

  changeSelectedOption(option:any, input:any=null){
    if(this.isOptionInOptionsSelected(option)){
      this.options=this.options.filter((item)=>item!=option);
      this.getElementSelected();
    } else {
      this.options.push(option);
      this.getElementSelected();
    }

    if(input){
      this.search=''
      input.focus();
    }
  }

  isOptionInOptionsSelected(option:any){
    return this.options.includes(option);
  }

  applyChanges(){
    this.store.dispatch(setArrayOfBuyers({buyers:this.options}));

    this.close();
  }

  getValue(){
    return this.isFocus? this.search: this.elementSelected;
  }
}
