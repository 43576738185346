import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileExchangeComponent } from './mobile-exchange.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';

@NgModule({
  declarations: [
    MobileExchangeComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule
  ], exports:[
    MobileExchangeComponent
  ]
})
export class TableMobileExchangeModule { }