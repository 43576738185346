<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Historial"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Historial</h2>

    <!-- CONTENT OF THE PRICE BOARD -->
    <app-body-historical></app-body-historical>

    <app-menu class="menu"></app-menu>
    <!--<app-menu-footer></app-menu-footer> -->
</div>