import { Component } from "@angular/core";
import { ViewsSectionBasic } from "./view-section";
import { Store } from "@ngrx/store";
import { appState } from "src/app/shared/interfaces/appState.interface";
import { ViewOrder, ViewOrderExchange } from "src/app/shared/interfaces/views.interface";
import { TEAMS_REPRESENTATION, teams } from "src/app/shared/dict/user";
import { costumer_by_seller } from "src/app/shared/dict/ibm/costumer-by-seller";
import { OrderString } from "src/app/shared/interfaces/order.interface";

@Component({
    template: '',
})
export class ViewSectionOrderBasic extends ViewsSectionBasic {
    override views:ViewOrder[] | ViewOrderExchange[]=[];
    override VIEW_ALL!: ViewOrder | ViewOrderExchange;

    isSelectedMyOrders: boolean = false; //Select 'Mis ordenes'
    isSelectedMyTable: boolean = false; //Select 'Mi mesa'
    constructor(store: Store<appState>) {
        super(store);
    }

    override getFilter(view:ViewOrder|ViewOrderExchange):OrderString{
        return view.orderString;
    }

    /**
    * Given an identifier of 'orders', 'table', or none.
    * Set the values to know in what type of view the user is standing
    * @param identificator
    * @returns void
    */
    override setSelectMy(identificator: string) {
      if (identificator == 'orders') {
        this.isSelectedMyOrders = true;
        this.isSelectedMyTable = false;
      } else if (identificator == 'table') {
        this.isSelectedMyOrders = false;
        this.isSelectedMyTable = true;
      } else {
        this.isSelectedMyOrders = false;
        this.isSelectedMyTable = false;
      }
    }


    viewMyOrders(){
        let newView:ViewOrder|ViewOrderExchange= JSON.parse(JSON.stringify(this.VIEW_ALL));
        newView.orderString.nameOfcreator=[ this.user.name+" "+this.user.lastName ];
        newView.orderString.codeS=getClientsByUser(this.user.subType);
        
        this.setViewSelected(newView);
        this.setFilter(newView.orderString);
        this.setSelectMy('orders')
        
        this.setViewSelectedInLocalStorage(this.VIEW_ALL, -1, this.isHistorical, this.getViewSelectedConst());
    }
      
    viewMyTable(){
        let newView:ViewOrder= JSON.parse(JSON.stringify(this.VIEW_ALL));
        const TEAMS:any=this.isRepresentation? TEAMS_REPRESENTATION: teams;
        newView.orderString.nroTeam=[ TEAMS[this.user.team.nroTeam] ];

        this.setViewSelected(newView);
        this.setFilter(newView.orderString);
        this.setSelectMy('table')

        this.setViewSelectedInLocalStorage(this.VIEW_ALL, -1, this.isHistorical, this.getViewSelectedConst());
    }
}

//Dado un numero de usuario (SUBTIPO), devuelve los clientes que tiene asignados.
function getClientsByUser(user:number){
    const clientByUser:any=costumer_by_seller;
    return clientByUser[user]? clientByUser[user]: [];
}