<div class="filter" (click)="selectFilter()">
    <span class="title-col">{{name}}</span>

    <arrow-svg class="arrow" [direction]="'down'" *ngIf="!isFilter else elseBlock"></arrow-svg>
    <ng-template #elseBlock class="arrow">
        <filter-board-svg class="arrow"></filter-board-svg>
    </ng-template>
</div>
<ng-container *ngIf="showFilter">
    <ng-container *ngIf="!isPrice else filterPrice">
        <filter-order [title]="name"
        [filterIdentificator]="filterIdentificator"
        [identificatorString]="identificatorString"
        [identificatorStringAux]="identificatorStringAux"
        [isPropertyOfPrice]="isPropertyOfPrice"
        [isSearchOrNumber]="isSearchOrNumber"
        [arrayAux]="arrayAux"
        [onlyFilter]="onlyFilter"
        [isToFix]="isToFix"></filter-order>
    </ng-container>

    <ng-template #filterPrice>
        <filter-price [title]="name"
        [filterIdentificator]="filterIdentificator"
        [identificatorString]="identificatorString"
        [identificatorStringAux]="identificatorStringAux" 
        [arrayAux]="arrayAux"
        [isSearchOrNumber]="isSearchOrNumber">
    </filter-price>
    </ng-template>
</ng-container>