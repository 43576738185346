import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from 'src/app/components/header/header.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { BodyGenerateOrderModule } from './body-generate-order/body-generate-order.module';
import { GenerateManualOrderComponent } from './generate-manual-order.component';
import { ModalModule } from 'src/app/components/dialogs/modal.module';
import { LoadingLottieModule } from 'src/app/components/loading/loading.module';

@NgModule({
  declarations: [
    GenerateManualOrderComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    HeaderInSectionModule,
    MenuModule,
    BodyGenerateOrderModule,
    ModalModule,
    LoadingLottieModule
  ], exports: [
    GenerateManualOrderComponent
  ]
})
export class GenerateManualOrderModule { }
