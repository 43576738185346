import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { PRICE_STATUS_CONST } from 'src/app/shared/const/prices.const';
import { PRODUCT_NAMES } from 'src/app/shared/const/productNames';
import { DataChecker } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { MarketPrice } from 'src/app/shared/interfaces/dashboard.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'app-market-prices',
  templateUrl: './market-prices.component.html',
  styleUrls: ['./market-prices.component.scss', './../../../../shared/styles/empty-state.scss']
})
export class MarketPricesComponent implements OnInit, OnDestroy {
  marketPrice: MarketPrice={
    maiz:[],
    soja:[],
    trigo:[]
  };
  
  private price$!:Subscription;
  
  constructor(private store: Store<appState>, 
    private dataChecker: DataChecker,
    private router:Router) {
    this.store.dispatch(dataChecker.rememberMarketPrices());
  }

  ngOnInit(): void {
    this.price$=this.store.select('price').subscribe(priceR => {
      this.loadMarketPrices(priceR.priceBoard.all);
    })
  }

  ngOnDestroy(): void {
    this.price$?.unsubscribe();
  }
  
  //Given an array of price, sets and filters new arrays depending on a number (representing the name of a product)
  loadMarketPrices(prices:Price[]){
    let newPrices=prices;
    if(newPrices.length<=0){
      newPrices=this.dataChecker.rememberPrices();
    }

    this.loadPrice(PRODUCT_NAMES.SOJA,newPrices);
    this.loadPrice(PRODUCT_NAMES.TRIGO,newPrices);
    this.loadPrice(PRODUCT_NAMES.MAIZ,newPrices);
  }


  loadPrice(priceName:number, prices:Price[]){
    let array:Price[]=[];
    if(prices){
      prices.forEach((price:Price) => {
        if(price.productName==priceName && 
          price.status!=PRICE_STATUS_CONST.INACTIVO && 
          ((price?.indicators) ?? []).length<=0 ){
          array.push(JSON.parse(JSON.stringify(price)));
        }
      });
    }

    if(priceName==PRODUCT_NAMES.SOJA){
      this.marketPrice.soja= array;
    } else if(priceName==PRODUCT_NAMES.TRIGO){
      this.marketPrice.trigo=array;
    } else if(priceName==PRODUCT_NAMES.MAIZ){
      this.marketPrice.maiz=array;
    }
  }

  goToPriceBoard(){
    const url=`/${NAVEGATION.PRICE_BOARD}`;
    this.router.navigate([url]);
  }
}