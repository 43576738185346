
<div class="form-field" [ngClass]="{'border-green': getIsInputCompleted(),'is-incomplete': isIncomplete, 'disabled': isDisabled}">
    
    <span class="title-field"> 
        <span class="autocomplete" *ngIf="showAuxiliarText && auxiliarField"> 
            <span class="first-part" style="visibility: hidden;">{{auxiliarField}}</span> 
            <span *ngIf="getValue() || (getValue()==0 && showCero)">{{auxiliarText}}</span>
        </span>
        
        <input #inputNumber ondrop="return false;" autocomplete="off"
        (keypress)="validateInputNumber($event,inputNumber)"
        (keyup)="copyData($event,inputNumber)"
        (change)="select($event)"
        [disabled]="isDisabled"
        type="text" (click)="emitFocus()"
        [min]="min" [max]="max" [step]="step"
        value="{{getValue()}}" placeholder="{{initialPlaceHolder}}"
        class="form-control no-background-border margin" aria-label="title">
    </span>


</div>
