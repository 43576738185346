import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusConst } from '../../const/orders';
import { OrderExchange } from '../../interfaces/order-exchange';
import { Order } from '../../interfaces/order.interface';
@Pipe({
  name: 'withOnlyContractStatus'
})
export class FilterWithOnlyContractStatus implements PipeTransform {

  transform(orderList: OrderExchange[] | Order[], status:number=orderStatusConst.AUTORIZATION) {
    let newValueToReturn = [];
    const quantOfItems = orderList.length;
    for(let i=0; i<quantOfItems; i++){
      if(orderList[i].statusOrder == status){ //&& orderList[i].statusOrder == orderStatusConst.CONTRACT){
        newValueToReturn.push(orderList[i]);
      }
    }

    return newValueToReturn;
  }

}
