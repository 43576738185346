import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThreePointsSvgComponent } from './three-points-svg.component';

@NgModule({
  declarations: [
    ThreePointsSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    ThreePointsSvgComponent
  ]
})
export class ThreePointsSvgModule { }
