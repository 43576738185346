import { Component, Output, OnInit,EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { typeCoinsStr } from 'src/app/shared/dict/dict';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-type-coin',
  templateUrl: './type-coin.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class TypeCoinComponent extends Field implements OnInit{
  constructor(store:Store<appState>) { 
    super(store);
    this.array=Object.keys(typeCoinsStr);
    this.array.shift();
    this.OBJECT=typeCoinsStr;
    this.deleteLastValueInArray();
    this.initalElementSelected='Ingresar una moneda';
    
    this.pipe=new FilterByObjectPipe();
  }
  
  
  /* Escuchamos el evento para cuando esté en el formulario de precio y pulse tab. Así salta al paso siguiente */
  @Output() tab = new EventEmitter<string>();
  emit(){
    this.tab.emit('tab');
  }

  listenerTab(event: any) {
    if(event.key.toLowerCase() == 'tab' && this.fieldIdentificator == fieldType.PRICE){
      this.emit();
  }}

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.typeCoin;
    } else if(this.fieldIdentificator==fieldType.EXCHANGE){
      this.tag=priceTags.typeCoin;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.typeCoin;
    }
    this.setIdentifyClassName();
  }
  
  override rememberValueInForm(){
    this.rememberTypeCoin()
  }

  override setElement(element:any, index:number){
    let typeCoinToSave=element;
    this.setElementToGenerate(typeCoinToSave);
  }

    //Remember value from string type coin type.
    rememberTypeCoin() {
      let valueToSet: any = this.rememberValue();

      //If There is a value to remember
      if (valueToSet!='--' && this.elementToGenerate && valueToSet) {
        this.indexOption=valueToSet=='ARS'? 0: 1;
        this.elementSelected = valueToSet;
        this.changeOptionSelected();
      } else if(valueToSet=='--'){
        this.elementSelected = '';
        this.setIsOptionSelected(false);
      }
    }

    isPriceIn(identificator: "USD" | "ARS"){
      return false;
    }

    setIsDolar(boolean:boolean){
      let newTypeCoin=boolean? typeCoins.USD:typeCoins.ARS;
      this.setElement(newTypeCoin,0);
    }

    setIsDolarEvent(boolean:boolean, event:any){
      const isEnter=event.key.toLowerCase()=='enter';
      if(isEnter){
        this.setIsDolar(boolean);
      }
    }
}
