import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { B_OR_R_VOID, CONDITIONS_TYPE_TO_FIX_CONST, INDICATORS_TO_FIX_CONST, PLACES_TO_FIX_CONST, indicatorsTags } from 'src/app/shared/const/to-fix';
import { PeriodType, getDatesBetweenDates, getPeriodsBetweenDates } from 'src/app/shared/date.checker';
import { quality } from 'src/app/shared/dict/dict';
import { PLANTAS_INTERIOR_MAP } from 'src/app/shared/dict/plantas-interior';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { ForwardPeriod, Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { ToFixValidator, isOptionalPartial, isRequiredPeriod, isRequiredPlace, isRequiredQuality, isSameIndicator } from 'src/app/shared/to-fix';

@Component({
  selector: 'app-indicator-information',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {
  @Input() showButtonSave:boolean=false;
  @Output() save= new EventEmitter<{save:boolean, indicator:Indicator}>();

  //ConditionType to achieve rendering certain elements in the parent component.
  @Input() conditionType!:number;

  @Input() allIndicators:Indicator[]=[];

  //Indicator structure that is received from the parent component
  @Input() indicator!:Indicator;

  //Previus date min
  @Input() previusDateMin!:string|null;

  //Variable that we use to emit a new flag to the parent component
  @Output() valueOut= new EventEmitter<Indicator>();

  //List of tags that we use to display the inputs
  public indicatorsTags=indicatorsTags;

  //Const of INDICATORS
  public INDICATORS_TO_FIX_CONST=INDICATORS_TO_FIX_CONST;
  
  //Const ofsCONDITION TYPES
  public CONDITION_TYPES=CONDITIONS_TYPE_TO_FIX_CONST;


  //List of places that we use to display the select
  public places=PLANTAS_INTERIOR_MAP;
  public arrayPlaces:any;

  //List of products that we use to display the select
  public products=PRODUCT_NAMES;
  public arrayProducts:any;

  //List of qualities that we use to display the select
  public qualities=quality;
  public arrayQualities:any;
  
  //List of period type
  periodType=PeriodType;

  fixationInvalids:string[]=[]

  constructor(private toFixV:ToFixValidator) { }

  ngOnInit(): void {
    this.arrayPlaces= this.getPlaces();
    this.arrayProducts=this.getArrayOf(PRODUCT_NAMES);
    this.arrayQualities=this.getArrayOf(quality); //Debemos eliminar la ultima que es "Tal cual";
    this.arrayQualities.pop();

    this.fixationInvalids=this.getArrayFixationInvalids();
  }

  getArrayOf(object:any){
    try{
      let copy=JSON.parse(JSON.stringify(object));
      delete copy["-1"];
      delete copy[0];
      return Object.keys(copy);
    } catch (error) {}
    return [];
  }

  getPlaces(){
    const indicAll=[INDICATORS_TO_FIX_CONST.MERC_COMP_DISP, INDICATORS_TO_FIX_CONST.FORW_COMP,
      INDICATORS_TO_FIX_CONST.MERC_COMP_GEN, INDICATORS_TO_FIX_CONST.FORW_GEN]
    //
  //Por pedido de Gastaldi, estos indicadores deben tener todos los valores.
    //const indicSinDarsena=[INDICATORS_TO_FIX_CONST.MERC_COMP_GEN, INDICATORS_TO_FIX_CONST.FORW_GEN]
    const indicDars=[INDICATORS_TO_FIX_CONST.PIZ_DISP, INDICATORS_TO_FIX_CONST.MATBA, INDICATORS_TO_FIX_CONST.DISP_MATBA];
    if(indicAll.includes(this.indicator.indicatorName)){
      const arrayToReturn= Object.keys(this.places);
      arrayToReturn.shift();
      return arrayToReturn;
    } /*else if(indicSinDarsena.includes(this.indicator.indicatorName)){
      return [PLACES_TO_FIX_CONST.ROSARIO, PLACES_TO_FIX_CONST.BAHIA_BLANCA, PLACES_TO_FIX_CONST.NECOCHEA]
    } */else if(indicDars.includes(this.indicator.indicatorName)){
      return [PLACES_TO_FIX_CONST.ROSARIO, PLACES_TO_FIX_CONST.BAHIA_BLANCA, PLACES_TO_FIX_CONST.NECOCHEA, PLACES_TO_FIX_CONST.DARSENA]
    }
  }

  /*Given a value and an indicator tag, it copies the new indicator into a variable, 
  inserts that value into the corresponding tag and emits it*/
  setValueWithTag(value:any, tag:string){
    let copyIndicator:any=JSON.parse(JSON.stringify(this.indicator));
    let copyValue=value;
    
    if(tag==indicatorsTags.fixationPeriod){
      //Si seteamos alguna fecha, actualizamos el array de fechas invalidas
      this.fixationInvalids=this.getArrayFixationInvalids();
    }

    if(tag==indicatorsTags.proporcionality && value==false){
      copyIndicator.propMinOrMax=0;
      copyIndicator.propPeriod=0;
      copyIndicator.propQuantDivs=0;
    }

    copyIndicator[tag]=copyValue;
    this.emit(copyIndicator);
  }

  /*Given a value (caught in an event) and the name of a tag.
  It copies the indicator, accesses the value of the tag, 
  and assigns it the value (in numeric) of the event and its element field.
  Then emit the indicator.*/
  setValueWithTagEvent(event:any, tag:string){
    let copyIndicator:any=JSON.parse(JSON.stringify(this.indicator));
    copyIndicator[tag]=Number(event.element);
    this.emit(copyIndicator);
  }

  /**
   * Obtains all the dates that exist in the setting period of all the indicators.
   * We remove dates that match the indicator we are editing to allow free editing.
   * @returns Array of dates
   */
  getArrayFixationInvalids():string[]{
    const indicators=this.allIndicators ?? [];
    const arrayToReturn:string[]=[];
    indicators.forEach( (indicator:Indicator) => {
      if(this.indicator.fixationPeriod.startDate!=indicator.fixationPeriod.startDate &&
        this.indicator.fixationPeriod.endDate!=indicator.fixationPeriod.endDate){
          const datesBetween=getDatesBetweenDates(indicator.fixationPeriod.startDate, indicator.fixationPeriod.endDate);
          arrayToReturn.push(...datesBetween);
        }
    });
    
    return arrayToReturn;
  }

  /*Given a tag and subTag, copy the flag, access its properties, and return the value */
  getValue(tag:string, subTag:string=''){
    try{
      let copyIndicator=JSON.parse(JSON.stringify(this.indicator));
      return subTag===''? copyIndicator[tag]: copyIndicator[tag][subTag];
    } catch (error) {}
    return '';
  }

  /*Get place selected and return the value */
  getPlaceSelected(){
    const place=this.toFixV.getPlace(this.indicator);
    return place=='--'? '':place;
  }

  /*Get product selected and return the value */
  getProductSelected(){
    const product= this.toFixV.getProduct(this.indicator);
    return product=='--'? '':product;
  }

  /*Get quality selected and return the value */
  getQualitySelected(){
    const quality=this.toFixV.getQuality(this.indicator);
    return quality=='--'? '':quality;
  }

  /*Get prop period selected and return the value */
  getPropPeriodSelected(){
    return this.toFixV.getPropPeriodText(this.indicator);
  }

  /*Given a value, a parent tag, a subtag, and a variable indicating whether the type.
- Copy the indicator into a new variable
- Assign the value in the tag and subtag
- Then take the variable type and assign its value to [tag]['type'] */
  setValueBoR(value:any, tag:string, subtag:string, type:string){
    let copyIndicator:any=JSON.parse(JSON.stringify(this.indicator));
    copyIndicator[tag][subtag]=value;
    copyIndicator[tag]['type']=type;

    if(tag=='reduction'){
      copyIndicator.bonification=B_OR_R_VOID;
    } else if(tag=='bonification'){
      copyIndicator.reduction=B_OR_R_VOID;
    }

    if(value==0){
      copyIndicator.reduction=B_OR_R_VOID;
      copyIndicator.bonification=B_OR_R_VOID;
    }

    if(type=='percentage'){

    }

    this.emit(copyIndicator);
  }

  /*Given a tag, indicating whether it is bonus or reduction and another that is just 'amount' | 'percentage'.
- If the variable is 'percentage' and the type=='%', then it returns value, otherwise it returns 0.
- If the variable is 'amount' and the type!='%', then it returns value, otherwise it returns 0. */
  getValueBoR(tag:string, identificator: 'amount' | 'percentage'){
    try{
      let copyIndicator=JSON.parse(JSON.stringify(this.indicator));
      if(identificator==='percentage' && copyIndicator[tag]['type']==='%'){
        return copyIndicator[tag]['value'];
      }else if(identificator==='amount' && copyIndicator[tag]['type']!=='%'){
        return copyIndicator[tag]['value'];
      }
    } catch (error) {}
    return 0;
  }

  /* With the ForwardPeriod structure passed by parameter, it creates a string with the form of 'month-year' and returns it */
  getMonthAndYear(value:ForwardPeriod){
    return (value.month<=0 || value.year<=0) ? '':`${value.month}-${value.year}`;
  }

  getMonthAndYearString(indicator:Indicator){
    return this.toFixV.getMonthAndYear(indicator);
  }

  /*Given a string in 'month-year' format, create a value variable of type ForwardPeriod with the fields of the string.
Then call the 'setValueWithTag' function with the tag=indicatorsTags.monthAndYear; */
  setMonthAndYear(value:string){
    let monthAndYearArray=value.split('-');
    const monthAndYear:ForwardPeriod={
      month:parseInt(monthAndYearArray[0]),
      year:parseInt(monthAndYearArray[1]),
      isPartial:this.indicator.monthAndYear.isPartial
    }
    this.setValueWithTag(monthAndYear, indicatorsTags.monthAndYear);
  }

  /* Change the value of the isPartial flag */
  changeIsPartial(){
    let copyIndicator:any=JSON.parse(JSON.stringify(this.indicator));
    copyIndicator.monthAndYear.isPartial=!copyIndicator.monthAndYear.isPartial;
    this.emit(copyIndicator);
  }

  /* Gets the options for the currency of the bonus or rebate */
  getOptionsBoR(){
    return [
      {value:'USD', name:'Dólares'},
      {value:'ARS', name:'Pesos'},
    ]
  }

  getOptionsProportionality(){
    return [
      {value:1, name:'Mínimo'},
      {value:2, name:'Máximo'},
    ]
  }
      
  setMinOrMaxProp(tag:string, value:any){
    let copyIndicator=JSON.parse(JSON.stringify(this.indicator));
    copyIndicator[tag]=value; //Seteamos si es MIN o MAX
    if(value>0){ //Si tiene valor, entonces hacemos los calculos necesarios
      copyIndicator=this.setValuesDefaultIndicator(copyIndicator, copyIndicator.propPeriod<=0? 1: copyIndicator.propPeriod);
    }
    this.emit(copyIndicator);
  }

  setPeriodProp(value:number){
    let copyIndicator=this.setValuesDefaultIndicator(this.indicator, value);
    this.setShowSubOptions(value<0) //Si eligió opción ocultamos las subopciones del periodo;
    this.emit(copyIndicator);
  }

  private setValuesDefaultIndicator(indicator:Indicator, propPeriod:number){
    let copyIndicator=JSON.parse(JSON.stringify(indicator));
    copyIndicator.propPeriod=propPeriod;
    copyIndicator.propQuantDivs= getPeriodsBetweenDates(copyIndicator.fixationPeriod.startDate, copyIndicator.fixationPeriod.endDate, copyIndicator.propPeriod);
    copyIndicator= this.toFixV.setMinOrMaxWithProp(copyIndicator);
    return copyIndicator
  }

  getTextProporcionalityComplete(){
    return this.toFixV.getTextProporcionalityComplete(this.indicator);
  }

  showSubOptions:boolean=false;
  setShowSubOptions(boolean:boolean){
    this.showSubOptions=boolean;
  }

  /* Given a value and an identifier, sets the bonus or rebate typeCoin */
  setTypeCoin(value:any, tag:string){
    let copyIndicator=JSON.parse(JSON.stringify(this.indicator));
    copyIndicator[tag].type=value;

    if(tag=='reduction'){
      copyIndicator.bonification=B_OR_R_VOID;
    } else if(tag=='bonification'){
      copyIndicator.reduction=B_OR_R_VOID;
    }

    this.emit(copyIndicator);
  }

  saveIndicator(){
    this.save.emit({
      indicator: this.indicator,
      save: true
    })
  }

  /* Casts the flag to the parent component */
  emit(indicator:Indicator){
    this.valueOut.emit(indicator);
  }
  
  /*It determines if an indicator has proportionality, comparing if it has a maximum or a minimum*/
  hasProportionality(indicator:Indicator){
    return indicator?.proporcionality ?? false;
  }

  isIndicatorSelected(indicatorName:number){
    return isSameIndicator(this.indicator.indicatorName, indicatorName);
  }

  isConditionTypeSelected(conditionType:number){
    return isConditionTypeSelected(this.conditionType, conditionType)
  }

  //Se muestra siempre salvo que sea
  showQuality(indicator:number){
    return isRequiredQuality(indicator);
  }

  //Se muestra siempre salvo que sea CME EN MATBA o DISPONIBLE MATBA
  showPlaza(indicator:number){
    return isRequiredPlace(indicator)
  }

  //Se muestra siempre solo con: los forward, posicion MATBA, CME, CME EN MATBA
  showMonthAndYear(indicator:number){
    return isRequiredPeriod(indicator);
  }

  //Se muestra siempre solo con: los forward
  showIsPartial(indicator:number){
    return isOptionalPartial(indicator);
  }
}

export function isConditionTypeSelected(conditionType1:number, conditionType2:number){
  return conditionType1==Number(conditionType2);
}