<!-- Add a new View-->
<div class="card" *ngIf="identificator==1">
    <div class="header">
        <span>Agregar vista</span>
    </div>

    <div class="search-and-text">
        <input type="text" class="search" [ngClass]="{'input-not-name':isButtonClicked && view.viewName==''}" 
        placeholder="Nombre de la vista" value="{{view.viewName}}" (keyup)="setName($event)">
        <span class="not-name" *ngIf="isButtonClicked && view.viewName==''">No olvides colocar un nombre a la vista.</span>
    </div>
    
    <div class="line-grey" *ngIf="isComercial && !isRepresentation"></div>
    
    <div class="tables" *ngIf="isComercial && !isRepresentation">
        <div class="select-table row-table" (click)="changeShowTables()">
            <span class="title">Seleccionar mesa</span>
            <arrow-svg [direction]="'down'"></arrow-svg>
        </div>

        <div class="table-names" *ngIf="showTables" [ngClass]="{'show-tables':showTables, 'not-show-tables': !showTables}">
            <div class="row-table" *ngFor="let table of TABLES" (click)="setCheck(table)">
                <span class="table-name" [ngClass]="{'selected': tableChecks.includes(table)}">{{table}}</span>
                <check-svg *ngIf="tableChecks.includes(table)"></check-svg>
            </div>
        </div>
    </div>

    <div class="line-grey"></div>
    <div class="select-all">
        <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span>    
    </div>
    <div class="line-grey"></div>
    
    <div class="options">
        <app-option option="Hora" [isChecked]="view.hour" (checked)="setProperty($event, VIEW_ORDER_FIELDS.hour)"></app-option>
        <app-option option="Observaciones" [isChecked]="view.observations" (checked)="setProperty($event, VIEW_ORDER_FIELDS.observations)"></app-option>
        <app-option [option]="isControlPanel? 'N° de contrato': 'Estado de la orden'" [isChecked]="view.orderStatus" (checked)="setProperty($event, VIEW_ORDER_FIELDS.orderStatus)"></app-option>
        <app-option option="Vendedor" [isChecked]="view.seller" (checked)="setProperty($event, VIEW_ORDER_FIELDS.seller)"></app-option>
        <app-option option="Comprador" [isChecked]="view.buyer" (checked)="setProperty($event, VIEW_ORDER_FIELDS.buyer)"></app-option>
        <app-option option="Producto" [isChecked]="view.product" (checked)="setProperty($event, VIEW_ORDER_FIELDS.product)"></app-option>
        <app-option option="Tipo de negocio" [isChecked]="view.typeBusiness" (checked)="setProperty($event, VIEW_ORDER_FIELDS.typeBusiness)"></app-option>
        <app-option option="Lugar de entrega" [isChecked]="view.placeOfDelivery"  (checked)="setProperty($event, VIEW_ORDER_FIELDS.placeOfDelivery)"></app-option>
        <app-option option="Periodo de entrega" [isChecked]="view.deliveryPeriod" (checked)="setProperty($event, VIEW_ORDER_FIELDS.deliveryPeriod)"></app-option>
        <app-option option="Calidad" [isChecked]="view.quality" (checked)="setProperty($event, VIEW_ORDER_FIELDS.quality)"></app-option>
        <app-option option="Precio" [isChecked]="view.price" (checked)="setProperty($event, VIEW_ORDER_FIELDS.price)"></app-option>
        <app-option option="Toneladas" [isChecked]="view.tons" (checked)="setProperty($event, VIEW_ORDER_FIELDS.tons)"></app-option>
        <app-option option="Forma de pago" [isChecked]="view.wayPay" (checked)="setProperty($event, VIEW_ORDER_FIELDS.wayPay)"></app-option>
        <app-option option="Cosecha" [isChecked]="view.harvest" (checked)="setProperty($event, VIEW_ORDER_FIELDS.harvest)"></app-option>
        <app-option option="Particularidad del negocio" [isChecked]="view.businessParticularities" (checked)="setProperty($event, VIEW_ORDER_FIELDS.businessParticularities)"></app-option>        
        <app-option option="Fecha" [isChecked]="view.date" (checked)="setProperty($event, VIEW_ORDER_FIELDS.date)"></app-option>
        <app-option option="Mesas" [isChecked]="view.tables" (checked)="setProperty($event, VIEW_ORDER_FIELDS.tables)"></app-option>
    </div>        
    <span class="not-name" *ngIf="isButtonClicked && notClickedNothing()">No olvides colocar un nombre a la vista.</span>


    <button class="apply" [ngClass]="{'disabled': view.viewName=='' || notClickedNothing()}" (click)="createView()">
        Guardar vista
    </button>
</div>

<!-- Edit an existing view-->
<div class="card" *ngIf="identificator==2" [ngClass]="{'max-z-index': isDeleteView}">
    <div class="header" *ngIf="!isEditingName">
        <span>{{view.viewName}}</span>
        <edit-svg [size]="20" (click)="setIsEditingName(true)"></edit-svg>
    </div>
    
    <div class="column-left search-and-text" style="margin-top: 10px;" *ngIf="isEditingName">
        <input type="text" class="search" [ngClass]="{'input-not-name':isButtonClicked && view.viewName==''}" 
        placeholder="Nombre de la vista" value="{{view.viewName}}" (keyup)="setName($event)">
        <span class="not-name" *ngIf="isButtonClicked && view.viewName==''">No olvides colocar un nombre a la vista.</span>
        <span class="font-small">Se guardará cuando presione "Aplicar".</span>
    </div>
    
    <div class="line-grey" *ngIf="isComercial"></div>
    
    <div class="tables" *ngIf="isComercial">
        <div class="select-table row-table" (click)="changeShowTables()">
            <span class="title">Seleccionar mesa</span>
            <arrow-svg [direction]="showTables? 'down':'left'"></arrow-svg>
        </div>

        <div class="table-names"  *ngIf="showTables" [ngClass]="{'show-tables':showTables, 'not-show-tables': !showTables}">
            <div class="row-table" *ngFor="let table of TABLES" (click)="setCheck(table)">
                <span class="table-name" [ngClass]="{'selected': tableChecks.includes(table)}">{{table}}</span>
                <check-svg *ngIf="tableChecks.includes(table)"></check-svg>
            </div>
        </div>
    </div>
    
    <div class="line-grey"></div>
    <div class="select-all">
        <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span>
    </div>
    <div class="line-grey"></div>

    <div class="options">
        <app-option option="Hora" [isChecked]="view.hour" (checked)="setProperty($event, VIEW_ORDER_FIELDS.hour)"></app-option>
        <app-option option="Observaciones" [isChecked]="view.observations" (checked)="setProperty($event, VIEW_ORDER_FIELDS.observations)"></app-option>
        <app-option [option]="isControlPanel? 'N° de contrato': 'Estado de la orden'" [isChecked]="view.orderStatus" (checked)="setProperty($event, VIEW_ORDER_FIELDS.orderStatus)"></app-option>
        <app-option option="Vendedor" [isChecked]="view.seller" (checked)="setProperty($event, VIEW_ORDER_FIELDS.seller)"></app-option>
        <app-option option="Comprador" [isChecked]="view.buyer" (checked)="setProperty($event, VIEW_ORDER_FIELDS.buyer)"></app-option>
        <app-option option="Producto" [isChecked]="view.product" (checked)="setProperty($event, VIEW_ORDER_FIELDS.product)"></app-option>
        <app-option option="Tipo de negocio" [isChecked]="view.typeBusiness" (checked)="setProperty($event, VIEW_ORDER_FIELDS.typeBusiness)"></app-option>
        <app-option option="Lugar de entrega" [isChecked]="view.placeOfDelivery"  (checked)="setProperty($event, VIEW_ORDER_FIELDS.placeOfDelivery)"></app-option>
        <app-option option="Periodo de entrega" [isChecked]="view.deliveryPeriod" (checked)="setProperty($event, VIEW_ORDER_FIELDS.deliveryPeriod)"></app-option>
        <app-option option="Calidad" [isChecked]="view.quality" (checked)="setProperty($event, VIEW_ORDER_FIELDS.quality)"></app-option>
        <app-option option="Precio" [isChecked]="view.price" (checked)="setProperty($event, VIEW_ORDER_FIELDS.price)"></app-option>
        <app-option option="Toneladas" [isChecked]="view.tons" (checked)="setProperty($event, VIEW_ORDER_FIELDS.tons)"></app-option>
        <app-option option="Forma de pago" [isChecked]="view.wayPay" (checked)="setProperty($event, VIEW_ORDER_FIELDS.wayPay)"></app-option>
        <app-option option="Cosecha" [isChecked]="view.harvest" (checked)="setProperty($event, VIEW_ORDER_FIELDS.harvest)"></app-option>
        <app-option option="Particularidad del negocio" [isChecked]="view.businessParticularities" (checked)="setProperty($event, VIEW_ORDER_FIELDS.businessParticularities)"></app-option>
        <app-option option="Fecha" [isChecked]="view.date" (checked)="setProperty($event, VIEW_ORDER_FIELDS.date)"></app-option>
        <app-option option="Mesas" [isChecked]="view.tables" (checked)="setProperty($event, VIEW_ORDER_FIELDS.tables)"></app-option>
    </div>

    <button class="apply" [disabled]="view.viewName==''" (click)="editView()">
        Aplicar
    </button>
    <button class="delete" (click)="openModal(true)">
        Eliminar vista
    </button>

    <div id="modal" [ngClass]="{'show': isDeleteView}">
        <div class="modal-content">

            <p class="message">¿Deseas eliminar la vista?</p>
        
            <button class="button not-stuffed" (click)="deleteView()">Si</button>
            <button class="button filling" (click)="openModal(false)">No</button>
        
        </div>
    </div>
</div>