
<div class="field" style="margin-top:0" #fieldName>
    <span class="field-title">Solo le vende a</span>
    <div>
        <div class="form-field" (click)="changeShowOptions()" [ngClass]="{'border-green': isOptionSelected, 'is-incomplete': isInvalid}">
            <span class="title-field  {{identifyClassName}}"> 

                <span class="autocomplete"> 
                    <span class="first-part">{{search}}</span> 
                    <span class="second-part">{{placeHolder}}</span>
                </span>
                
                <input #inputName (focus)="focusField(fieldName)" 
                (blur)="unfocusField()"
                value="{{getValue()}}" [(ngModel)]="search"  placeholder="{{initalElementSelected}}"
                class="{{identifyClassName}} no-background-border" />
                <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>
        {{elementSelected}}
        <span class="text-is-incomplete" *ngIf="isInvalid">Destino o procedencia no admitida para el comprador.</span>

        <div class="options" *ngIf="showOptions">
            <div class="width-100 row-between" style="padding-right:16px;" 
                *ngFor="let element of arrayOfSelected; index as i"
                (click)="addOrRemoveBuyer(element,inputName)" >
                
                <span>{{OBJECT[element]}}</span>
                <input type="checkbox" [checked]="true"/>
            </div>

            <div class="line grey division" *ngIf="arrayOfSelected.length>0"></div>

            <div (click)="addOrRemoveBuyer(element, inputName)" 
            class="width-100 row-between" style="padding-right:16px;" 
            [ngClass]="{'display-none': includesElement(element)}"
            *ngFor="let element of array | filterByObject: search:OBJECT: 'buyer' ; index as i">
                
                <span>{{OBJECT[element]}}</span>
                
                <input type="checkbox" [checked]="false"/>
            </div>
        </div>
        </div>
    </div>