import { typeCoins } from '../const/prices.const';
import { DataIBM, FailedResponse } from '../interfaces/DataIBM';
import { Order, PlaceOfOrigin } from '../interfaces/order.interface';
import { ContractService } from '../services/constract.service';
import { Injectable } from '@angular/core';
import { BusinessParticularities } from '../interfaces/business-particularities';
import { getDateISOFormat } from '../date.checker';
import { orderStatusConst } from '../const/orders';
import { CODE_FMC_QUIMICA, COMMODITIES_CODE, COMMODITIES_CODE_SELLER, HUGHES_CODE_BUYER, HUGHES_CODE_SELLER } from '../const/buyers';
import { PlaceOfDelivery, Price } from '../interfaces/price.interface';
import { Store } from '@ngrx/store';
import { appState } from '../interfaces/appState.interface';
import { addModalContractFailed, addModalContractSuccess, setModalContract, setModalContractType } from '../../redux/actions/options.action';
import { CODIGO_POSTAL } from '../dict/provinces/codigo-postal';
import { User } from '../interfaces/user.interface';
import { SellerObject, sellerWithData } from '../dict/seller-with-data';
import { OrderService } from '../services/order.service';
import { getWayPayCodeIBM } from '../way-pay';
import { PLANTAS_INTERIOR_MAP } from '../dict/plantas-interior';
import { puertos } from '../dict/dict';
import { getIsFinancialAdvance, getIsFreightPaidByTheBuyer, isConditional, isDeliveryAtGrassiPlants, isEntregaSiloBolsas } from '../business-particularities';
import { MARCA_SILO_BOLSAS, RATE_TO_DEFINE, TAMAIN_SILO_BOLSAS } from '../const/business-particularities';
import { OrderExchange } from '../interfaces/order-exchange';
import { getLocality, isCodeCommoditiesOrHughesBuyer, isSancor, OrderValidator } from '../order';
import { EXCHANGE_STATUS_CONST } from '../const/exchange-orders';
import { ExchangeService } from '../services/exchange.service';
import { COMMISION_SYNGENTA_AVC_BUYER, SYNGENTA_CODE_BUYER, SYNGENTA_CODE_SELLER } from '../dict/syngenta';
import { businessDict } from '../dict/typeBusiness';
import { getNewPriceWithDiscounts, isExchangeIncomplete } from '../order-exchange';
import { getBuyerCodeBySellerCode } from '../business-validation';
import { provincesWithLetterIBM } from '../dict/provinces/provinces';
import { NOMBRES_HABILITADOS } from '../../components/header/header.component';
import { QuotasIBMFunctions } from './quotas-ibm';
import { PLACE_OF_DELIVERY_CONST } from '../const/place-of-delivery';
import { sendIndicatorsToIBM } from './indicators-ibm';
import { addLoaderSendToIBM, discountLoaderSendToIBM } from 'src/app/redux/actions/order.action';
import { typeOfModal } from '../const/options';
import { PRODUCT_NAMES } from '../const/productNames';
import { catchError } from 'rxjs';
import { ResponseRequest } from '../interfaces/options.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractChecker {

  constructor(private store:Store<appState>, 
    private contractsSvc: ContractService, private orderSvc:OrderService, 
    private orderExcSvc:ExchangeService,
    private quotasChecker:QuotasIBMFunctions, private orderV:OrderValidator) {

  }

  createContracts(orders: Order[], nroOfCall:number, user:User) {
    for (let i = 0; i < orders.length; i++) {
      if(user.email){
        this.store.dispatch(addLoaderSendToIBM()); //Agregamos un loader para que empiece a cargar;
        let dataIBM=this.createContract(orders[i], user); //Obtenemos el contrato con la estructura para IBM.
        this.sendDataToIBM(orders, i, nroOfCall, user, dataIBM); //Enviamos los datos a IBM
      }
    }
  }
  
  async sendDataToIBM(orders:Order[], i:number, nroOfCall:number, user:User, dataIBM:DataIBM){
    let newOrder=JSON.parse(JSON.stringify(orders[i])); //Copiamos la orden

    //Si el código del comprador es Commodities o Hughes y el número de llamado es 2, 
    //enviamos los indicadores antes de terminar de autorizar el contrato. 
    if(isCodeCommoditiesOrHughesBuyer(newOrder.price.observations.buyer) && nroOfCall==2){
      
      //La función estará dentro de un try-catch para que si hay un error con los indicadores,
      //se aborte el proceso y no se confirme el negocio.
      try{
        const length=newOrder?.price?.indicators?.length;
        if(length>0) {
          await sendIndicatorsToIBM(newOrder.price.indicators, newOrder.contractNumber, this.orderSvc, this.store); //Enviamos los indicadores
        }
      } catch(err){
        // Manejo de errores si es necesario
        console.error("Error al enviar indicadores a IBM:", err);      
        this.store.dispatch(discountLoaderSendToIBM()); // Quitamos el loader
        return; // Opcional: decide si debes abortar el proceso en caso de error
      }
    }

    this.contractsSvc.createContract(dataIBM).subscribe( async (res:FailedResponse)=>{
      let newResponse:FailedResponse=JSON.parse(JSON.stringify(res)); //Creamos una copia de la respuesta
      newResponse.nroOfCall=nroOfCall; //Añadimos el nro de llamado
      newResponse.data.NombreVendedor= getSellerName(orders[i].sellerData.codeS); //Añadimos el nombre del vendedor

      //If the answer is ok, we update the contract number of the order
      if(res.status.status=="ok"){
        if(newOrder.price.observations.buyer != SYNGENTA_CODE_BUYER){ //Si el código del vendedor es distinto al de Syngenta comprando, ingresamos

          //Si la orden no es de commodities o hughes, se autoriza el contrato, enviando los indicadores y solicitando cupos.
          if(!isCodeCommoditiesOrHughesBuyer(newOrder.price.observations.buyer)){
            const contractNumber=res.data.Contrato; //Copiamos el contrato

            const length=newOrder?.price?.indicators?.length;
            if(length>0){
              await sendIndicatorsToIBM(newOrder.price.indicators, contractNumber, this.orderSvc, this.store); //Enviamos los indicadores porque no existe nroOfCall=1
            }

            dataIBM.CON1BO="S"; 
            dataIBM.CON2BO="";
            dataIBM.CON3BO="";
            dataIBM.CON4BO="";

            this.contractsSvc.createContract(dataIBM).subscribe((res2:FailedResponse)=>{ 
              //Llamamos por segunda vez para confirmar el contrato
              this.quotasChecker.solicitateQuotas(newOrder, false, 'C'); //Solicitamos los cupos
              //IMPORTANTE: LOS CUPOS QUE NO SON DE COMMODITIES O HUGHES, SE SOLICITAN UNA VEZ QUE SE CONFIRMA EL CONTRARO
            });
          } 

          const newStatus= nroOfCall==1? orderStatusConst.AUTORIZATION: orderStatusConst.CONTRACT; //Si es el primer llamado, se va a poner la orden en estado AUTORIZADO, sino en CONTRATO.
          newOrder=this.orderV.saveOrderStatus(newOrder, newStatus, '', user); //Guardamos el estado
          newOrder.contractNumber=res.data.Contrato; //Añadimos el numero de contrato a la orden
          if(nroOfCall==2){this.actualizateOrderExchange(newOrder, user);} //Actualizamos todos los canjes que estén asociados a la orden.
          
          if(nroOfCall==1){this.quotasChecker.solicitateQuotas(newOrder);} //Solicitamos cupos

          this.orderSvc.updateOrder(newOrder, newOrder._id).pipe( 
            catchError(err => {
              console.error('Error al actualizar la orden', err);
              return [];
            })
          ).subscribe(res=>{
            console.log("Orden actualizada correctamente: ", res)
          }); //Actualizamos con toda la información nueva.
        } else {

          // NOTA PARA MIQUEAS (12-4-2023):
          // Si no recuerdo mal, este caso se creó para las contrapartidas sin contrato madre.
          // Es el caso especial de Syngenta Agro AVC.
          // Lo que noto es que ese caso, nunca ingresa por esta sección del código.
          // Y se realiza en la parta de crear canjes.
          console.log("Comprador igual a syngenta")
          newOrder.exchange.contractNumber=res.data.Contrato;
          delete newOrder.price._id;
          delete newOrder.price.createdAt
          this.orderExcSvc.updateOrderExchange(newOrder, newOrder._id).subscribe(res=>{});

        }
      }

      this.openModal(newResponse); //Abrimos el modal con la respuesta
      this.store.dispatch(discountLoaderSendToIBM()); //Quitamos el loader
    });
  }

  openModal(response:FailedResponse, isExchange:boolean=false){
    if(response.status.status=='error'){
      //Al array de faileds que teníamos anteriormente, le agregamos la nueva respuesta
      this.store.dispatch(addModalContractFailed({arrayOfFaileds: [response], isIndicators:false}));
      this.store.dispatch(setModalContractType({typeModal: typeOfModal.ERROR}));

    } else if(response.nroOfCall==1 && !isExchange){ //Si el llamado es 1, se crea el borrador.
      //Al array de success que teníamos anteriormente, le agregamos la nueva respuesta
      this.store.dispatch(addModalContractSuccess({arrayOfSuccess: [response]}));
      this.store.dispatch(setModalContractType({typeModal: typeOfModal.ERASER}));

    } else if(response.nroOfCall==2){ //Si es el llamado 2, se crea contrato.
      //Al array de success que teníamos anteriormente, le agregamos la nueva respuesta
      this.store.dispatch(addModalContractSuccess({arrayOfSuccess: [response]}));
      this.store.dispatch(setModalContractType({typeModal: typeOfModal.CONTRACT}));
    }
  }
  
  createContractExchangeSvc(order:OrderExchange, user:User, nroOfCall:number){
    let dataIBM=this.createContractExchange(order,user,nroOfCall); //Datos para dar de alta el borrador
    
    this.contractsSvc.createContract(dataIBM).subscribe(async (res:FailedResponse)=>{ //Llamamos por primera vez para dar de alta el borrador
      //If the answer is ok. Volvemos a realizar la petición pero con la confirmación
      if(res.status.status=="ok"){
        dataIBM.CON1BO="S";
        dataIBM.CON2BO="";
        dataIBM.CON3BO="S";
        dataIBM.CON4BO="";
        dataIBM.SUCTBO=res.data.Contrato.toString();

        //Enviamos indicadores
        const length=order?.price?.indicators?.length;
        if(length>0) {
          await sendIndicatorsToIBM(order.price.indicators, res.data.Contrato, this.orderSvc, this.store); //Enviamos los indicadores
        }

        this.contractsSvc.createContract(dataIBM).subscribe((res2:FailedResponse)=>{ //Llamamos por segunda vez para confirmar el contrato
          let newResponse:FailedResponse=JSON.parse(JSON.stringify(res2));
          newResponse.nroOfCall=2;
          newResponse.data.NombreVendedor= getSellerName(dataIBM.VENDBO);

          if(res2.status.status=='ok'){
            let newOrder:OrderExchange=JSON.parse(JSON.stringify(order));
            newOrder.exchange.contractNumber=res.data.Contrato;
            newOrder.exchange.status=EXCHANGE_STATUS_CONST.COMPLETA;

            //Solicitamos las quotas
            this.quotasChecker.solicitateQuotas(newOrder, true);

            this.orderExcSvc.updateOrderExchange(newOrder, newOrder._id).subscribe(res=>{});
          }
        
          this.openModal(newResponse, true);
        });
      } else {
        this.openModal(res, true);
      }
    });
  }

  createContract(orderP: Order, user:User): DataIBM {
    const order: OrderExchange = JSON.parse(JSON.stringify(orderP));
    const observations:string=getObservations(order);
    let dataIBM: DataIBM = {
      ACPRBO: getHowSellIBM(order.howSell),
      ACUMUBO: isAccumulator(order.price.businessParticularities),
      ANTFIBO: isAnticipeFinancier(order.price.businessParticularities),
      ANTIVBO: getIvaStatusAnticipeFinancier(order.price.businessParticularities),
      ANTPABO: getPercentageAnticipeFinancier(order.price.businessParticularities),
      ANTPTBO: getRateAnticipeFinancier(order.price.businessParticularities),
      ANTTABO: getRateTypeAnticipeFinancier(order.price.businessParticularities),
      ANTTPBO: getPercentageTypeAnticipeFinancier(order.price.businessParticularities),
      BONIBO: 0,
      BONPBO: 0,
      CADPGBO: 0,
      CALIBO: getQuality(order.price),
      CAMICBO: 0,
      CANDBO: order.price.wayPay.days,
      CBRABO: isCollectionClause(order.price.businessParticularities),
      CDAGCOBO: '',
      CDAGVEBO: getAgroupSeller(order),
      CDIEVBO: getDaysOfFreightPaidByTheBuyer(order.price.businessParticularities),
      CEREBO: getProductName(order.price.productName),
      CFIDEBO: 'N',
      CGRCBO: getGrouperCode(order),
      CGT4BO: getBusinessType(order.price.typeBusiness),
      CODEBO: 0,//getBuyer(order.price.observations.buyer),
      CODIBO: 0,
      COMPBO: getBuyer(order.price.observations.buyer),
      CON1BO: getConfirmation(order, 1),
      CON2BO: getConfirmation(order, 2), //Confirmacion vendeor
      CON3BO: getConfirmation(order, 3), //Confirmacion comprador
      CON4BO: getConfirmation(order, 4), //Alta definitiva
      CONTBO: 0,
      CORRBO: getBroker(order.broke),
      COSEBO: getHarvest(order.price.harvest),
      CPPRBO: getCodPost(order.placeOfOrigin),
      CRESCBO: 'N',
      CSYNGBO: isLaboratoryAgreement(order.price.businessParticularities),
      DESTBO: '',
      DESVIBO: 0,
      DISACBO: 0,
      EVFLEBO: getEventTypeFreightPaidByTheBuyer(order.price.businessParticularities),
      FEPABO: getDate(order.price.wayPay.expiration, 6),
      FFEBBO: getDate(order.price.deliveryPeriod.endDate,8),
      FIEBBO: getDate(order.price.deliveryPeriod.startDate,8),
      FIENBO: getDate(order.price.deliveryPeriod.endDate,6),
      FLECLBO: isFreightPaidByTheClient(order.price.businessParticularities),
      FLETEBO: isFreighPaidByTheBuyer(order.price.businessParticularities),
      FPDEBO: getPesificacion(order.price.pesificacion, 'startDate'),
      FPHABO: getPesificacion(order.price.pesificacion, 'endDate'),
      GRASEBO: getSensorization(order.price.businessParticularities.gravanz.isOwnSensorization),
      GRAVZBO: isGravanz(order.price.businessParticularities),
      HASTBO: 0,
      INENBO: getDate(order.price.deliveryPeriod.startDate,6),
      LUGABO: getPlaceOfDeliveryIBM(order),
      MAFIBO: 0,
      MIFIBO: 0,
      MONDEBO: 0,
      MONEBO: getTypeCoin(order.price.typeCoin),
      MONEDBO: getTypeCoinFreight(order.price.businessParticularities),
      MOPACBO: 0,
      NOPAGBO: '',
      OB01BO: getObservationSubstring(observations, 1),
      OB02BO: getObservationSubstring(observations, 2),
      OB03BO: getObservationSubstring(observations, 3),
      OB04BO: getObservationSubstring(observations, 4),
      PA01BO: '',
      PA02BO: '',
      PAFMTBO: getIsPAFGuaranteed(order.price.businessParticularities),
      PAGOBO: getWayPayCodeIBM(order.price.wayPay, order.price.typeCoin),
      PPO2BO: 0,
      PPORBO: 0,
      PRAFBO: order.placeOfOrigin.afip,
      PREACBO: 0,
      PRECBO: order.price.price > 0 ? order.price.price : 0,
      PRECIBO: getPriceFreight(order.price.businessParticularities), //order.price.businessParticularities.freightPaidByTheBuyer.amount.quantity,
      PREDEBO: 0,
      REXIBO: isRespectVatException(order.price.businessParticularities), // booleanToString(order.price.businessParticularities.respectVatException),
      SOJINBO: isSojaIntacta(order.price.productName), //Es soja intacta
      SOJSBO: isSojaSustentable(order.price.productName), //Es soja sustenable
      SUBDIBO: getIsAvc(order.price.businessParticularities), //order.price.businessParticularities.isAVCs,
      SUCTBO: '',
      SUGEBO: getSucG(order.sellerData.codeS),
      SYNGBO: order.sellerData.codeS==CODE_FMC_QUIMICA? 'F': getSyngentaSubdivision(order.price.businessParticularities),
      TASACBO: 0,
      TASINBO: 0,
      TASYNBO: getLaboratoryAgreement(order.price.businessParticularities), //order.price.businessParticularities.laboratoryAgreement,
      TDOLBO: getTypeDolar(order),
      TOLECBO: 'N',
      TONEBO: getTons(order.tons),
      VENDBO: order.sellerData.codeS,
      WASHABO: isWashOut(order.price.businessParticularities),//booleanToString(order.price.businessParticularities.washOut),

      CONVEP01: isConvenio(order.price.businessParticularities),
      BONIFP01: getBonificationNumber(order.price.businessParticularities),
      VALOBP01: getBonificationCaracteristica(order.price.businessParticularities),    
      MONEBP01: getTypeCoinBonification(order.price.businessParticularities),                        
      MONTBP01: getImporteBonification(order.price.businessParticularities),
      REBAJP01: getTypeReduction(order.price.businessParticularities),
      MONERP01: getTypeCoinReduction(order.price.businessParticularities),
      VALORP01: getValueReduction(order.price.businessParticularities),
      SELLAP01: getSealedToRetrieve(order.price.businessParticularities),
      ARETIP01: isToWithdraw(order.price.businessParticularities),
      LUGAES01:getPortEspecify(order),
      PCOV38:getCommisionSeller(order),//Comisión Vendedora
      PCOC38:getCommisionBuyer(order),//Comisión Compradora
      CTOMADRE:0,
      
      usuarioIBM: !NOMBRES_HABILITADOS.includes(user.email)? user.email:'praderio@ggsa.com.ar', // 
      UsuarioOV: this.getUserOV(order), //
      id_order: order._id? order._id : '',
      
    };
    return dataIBM;
  }

  getUserOV(order:Order){
    try{
      if(order.dataOfcreation.email && order.dataOfcreation.email!='--'){
        return !NOMBRES_HABILITADOS.includes(order.dataOfcreation.email)? order.dataOfcreation.email:'cabrera@ggsa.com.ar'
      }
    } catch (error) {}
    return '';
  }

  createContractExchange(orderE: OrderExchange, user:User,nroOfCall:number): DataIBM {
    let dataIBM: DataIBM = this.createContract(orderE, user);
    dataIBM.PRECBO=orderE.exchange.price > 0 ? orderE.exchange.price : 0;
    dataIBM.MONEBO=getTypeCoin(orderE.exchange.typeCoin),
    dataIBM.ACPRBO=getHowSellIBM(orderE.exchange.howSell);
    dataIBM.BONIBO=getNumber(orderE.exchange.amountB)//Importe bonificacion
    dataIBM.BONPBO=getNumber(orderE.exchange.percentageB)// Porcentaje bonificacion
    
    dataIBM.COMPBO=getBuyerExchange(orderE);
    dataIBM.CON1BO=getConfirmationExchange(orderE, 1,nroOfCall),
    dataIBM.CON2BO=getConfirmationExchange(orderE, 2,nroOfCall),
    dataIBM.CON3BO=getConfirmationExchange(orderE, 3,nroOfCall),
    dataIBM.CON4BO=getConfirmationExchange(orderE, 4,nroOfCall),
    dataIBM.CPPRBO=getCodPost(orderE.exchange.placeOfOrigin);

    //dataIBM.PAGOBO= getWayPayCodeIBM(orderE.exchange.wayPay, orderE.exchange.typeCoin),
    //dataIBM.CANDBO= getNumber(orderE.exchange.wayPay.days);
    //dataIBM.FEPABO= getDate(orderE.exchange.wayPay.expiration, 6),
    dataIBM.PAGOBO=orderE?.exchange?.codeWayPay ?? 0,

    dataIBM.PRAFBO=orderE.exchange.placeOfOrigin.afip;
    dataIBM.SUGEBO=getSucG(orderE.exchange.buyer.codeS);
    dataIBM.TONEBO=getTons(orderE.exchange.tons);
    dataIBM.VENDBO=orderE.exchange.buyer.codeS;
    dataIBM.CTOMADRE=orderE.contractNumber >0? orderE.contractNumber : 0;
    dataIBM.PPO2BO=getNumber(orderE.exchange.priceDiscount); //Descuento de precio
    dataIBM.PPORBO=getNumber(orderE.exchange.agreedDiscount); //Descuento convenido
    dataIBM.PCOV38=0; //Comisión vendedora
    dataIBM.PCOC38= 0;//isSyngentaAgroAvc(orderE)? COMMISION_SYNGENTA_AVC_BUYER: 0; //Comisión compradora
    //Si es Syngenta Agro AVC, se setea la commision en 0.25
    //Actualización 16-09-2024: Syngenta Agro AVC, tiene comisión del 0%.
    //No se elimina el campo por si en el futuro termina cambiandola de nuevo.

    dataIBM.CBRABO=''; //El campo de Clausula de Cobranza debe llegar vacío para las contrapartidas.
    dataIBM.REXIBO=''; //El campo de Respeta exención de IVA debe llegar vacío para las contrapartidas.
    return dataIBM;
  }

  /*Given an order and a user.
It takes the order, performs a Split('/') on its idExchange field.
With that fix, it performs a search of the database and updates each one with update. */
  actualizateOrderExchange(orderE: OrderExchange, user:User){
    const idExchange:string=orderE.idExchange;
    if(idExchange){
      let idExchangeArray:string[]=idExchange.split('/');
      idExchangeArray.forEach(id => {
        this.searchAndUpdateElement(orderE,user,id);
      });
    }
  }

  searchAndUpdateElement(order: OrderExchange, user:User, id:string){
    if(id && id!='--'){
      this.orderExcSvc.getOrderById(id).subscribe( (res:ResponseRequest) => {
        if(res){
          //Actualizamos la parte del madre y le agregamos los datos del canje.
          let orderMother=JSON.parse(JSON.stringify(order));
          orderMother._id=res.data._id; //Reemplazamos ID
          orderMother.exchange=res.data.exchange; //Reemplazamos exchange

          //Copiamos los datos del contrato madre en la contrapartida;
          orderMother.exchange.typeCoin=orderMother.price.typeCoin;
          orderMother.exchange.tons=orderMother.tons;
          orderMother.exchange.price=getNewPriceWithDiscounts(orderMother.price.price, orderMother.exchange.priceDiscount);

          let isExchangeCompleted:boolean= !isExchangeIncomplete(orderMother.exchange) && (orderMother.tons<=orderMother.exchange.tons);
          orderMother.exchange.status=isExchangeCompleted?EXCHANGE_STATUS_CONST.VERIFICAR: EXCHANGE_STATUS_CONST.INCOMPLETO; //Reemplazamos status
      
          this.orderExcSvc.updateOrderExchange(orderMother, orderMother._id).subscribe(res => {});
        } else {
          console.log('No se encontro el contrato de canje: '+id);
        }
      });
    }
  }
}

export function getCodPost(placeOfOrigin:PlaceOfOrigin){
  try{
    //const locality:any=this.orderV.getLocality(placeOfOrigin.province)[placeOfOrigin.town - 1];
    const loc:any=getLocality(placeOfOrigin.province)[placeOfOrigin.town - 1]?.location;
    let codePostal=0;
    if(loc){
      codePostal=searchCodePostalByAfipCode(placeOfOrigin.afip);
      if(codePostal==0){
        codePostal=searchCodePostal(loc, placeOfOrigin.province);
      }
    }
    return codePostal;
  } catch(err){
    console.error(err);
    return 1000;
  }
}

function getNumber(number: number):number {
  return number>0? number: 0;
}

function booleanToString(boolean: boolean) {
  return boolean ? 'S' : '';
}

function isSojaIntacta(productName: number):string {
  return booleanToString(false);
  return booleanToString(productName === 1);
}

function isSojaSustentable(productName: number):string {
  return booleanToString(false);
  return booleanToString(productName === 1);
} 

export function getHowSellIBM(howSell: number):string {
  const howSellStruct:any={
    1:'A',
    2:'P',
    3:'C',
    4:'O'
  };
  return howSellStruct[howSell] ?? '';
}

function getQuality(price: Price) {
  const { quality, qualityIBM, productName } = price;
  const isCebada = productName === PRODUCT_NAMES.CEBADA_CERVECERA || productName === PRODUCT_NAMES.CEBADA_FORRAJERA;

  // Devuelve qualityIBM si es mayor que 0, de lo contrario, mapea quality según las condiciones
  return qualityIBM > 0 ? qualityIBM
    : quality == 1 ? 1 // CAMARA
    : quality == 2 ? 7 // FABRICA
    : quality == 3 ? (isCebada ? 20 : 17) // GRADO 2. Cuando es cebada y grado 2, lleva otro código.
    : quality == 4 ? 2 // TAL CUAL 16, Después fue 4 (no tiene nada que ver)
    : 0;
}

function getProductName(productName: number) {
  return productName;
  if(isSojaIntacta(productName)==='S' || isSojaSustentable(productName)==='S' ) {
    return 5;//Code of soja
  } else{
    return productName
  }
}

function getBusinessType(typeBusiness: number): string {
  let charToReturn: string = '';
  if (typeBusiness == businessDict.conDescarga) {
    charToReturn = 'D';
  } else if (typeBusiness == businessDict.sinDescarga) {
    charToReturn = 'S';
  } else if (typeBusiness == businessDict.depositos) {
    charToReturn = 'E';
  } else if (typeBusiness == businessDict.forward) {
    charToReturn = 'F';
  }
  return charToReturn;
}

function getBuyer(buyer: any): number {
  return buyer<=0? COMMODITIES_CODE: buyer;
}

/*transforms the SellerData code into a Buyer code with CERCANJ Table*/
export function getBuyerExchange(order:Order): number {
  return getBuyerCodeBySellerCode(order.sellerData.codeS);
}

export function isSyngentaAgroAvc(order:OrderExchange):boolean{
  return getBuyerExchange(order)==SYNGENTA_CODE_BUYER && getIsAvc(order.price.businessParticularities)=='A';
}

function getAgroupSeller(order:Order){
  try{
    const isSancorSeller=isSancor(order.sellerData.codeS);
    if(isSancorSeller){
      return order?.price?.businessParticularities?.campus ?? '';
    }
  } catch(err){}
  return ''
}

function getSucG(codeS:number){
  try{
    const sellerWithDataC:any=sellerWithData;
    const sucG=sellerWithDataC[codeS]["SucProd"];
    if(sucG && sucG>0){
      return sucG;
    }
  } catch(err){}
  return 0;
}

function getTons(number:number){
  try{
    if(number>9000){
      return 9000;
    } else if(number>0) {
      return number;
    }
  } catch(err){}
  return 0;
}

function getGrouperCode(order:OrderExchange):number{
  try{
    const grouperCode=order.price.grouperCode;
    if(grouperCode && grouperCode>0){
      return grouperCode;
    }
  } catch(err){}
  return 1;
}

function getConfirmation(order:OrderExchange, confirmation: number):string {
  try{
    let isCommoditiesBuyer=isCodeCommoditiesOrHughesBuyer(order.price.observations.buyer);

    if(confirmation===4){
      //Nunca se envía
      return '';
    } else if(confirmation===3){
      //Se envía S en el segundo llamado de Commodities
      if(isCommoditiesBuyer){
        return booleanToString(order.statusOrder === orderStatusConst.AUTORIZATION); 
      }
    } else if(confirmation===2){
      return booleanToString(order.statusOrder === orderStatusConst.CONFIRM || !isCommoditiesBuyer);
    } else if(confirmation==1){
      if(isCommoditiesBuyer){
        return booleanToString(order.statusOrder == orderStatusConst.AUTORIZATION);
      } else {
        return '';
        //return booleanToString(order.statusOrder == orderStatusConst.CONFIRM);
      }
    }
  } catch(err){}
  return '';
}

function getConfirmationExchange(order:OrderExchange, confirmation: number,nroOfCall:number):string {
  try{
    if(confirmation===4){
      return '';
    } else if(confirmation===3){
      return booleanToString(nroOfCall==2 && order.exchange.status === EXCHANGE_STATUS_CONST.VERIFICAR); 
    }else if(confirmation===2){
      return booleanToString(nroOfCall==1); //Primer llamado solo S, segundo llamado, Vacio
    } else {
      return booleanToString(nroOfCall==2 && order.exchange.status === EXCHANGE_STATUS_CONST.VERIFICAR); 
    }
  } catch(err){}
  return '';
}

function getBroker(broke: number): string {
  return broke==1? '1': broke==2? '2': '0';
}

function getHarvest(harvest: string): number {
  try {
    const newHarvest = harvest.split('-');
    const firstNumbers = Number(newHarvest[0]);
    const secondNumbers = Number(newHarvest[1]);
    return firstNumbers * 10000 + secondNumbers
  } catch (err) {
    return 0;
  }
}

function getDate(date: string, quantChars:number) {
  try{
    const dateSplit = date.split('-');
    let day = dateSplit[0];
    const monthParsed=Number(dateSplit[1]);
    let month = monthParsed < 10 ? '0' + monthParsed : monthParsed;
    let year= dateSplit[2]; //Full year for default.
    if(quantChars==6){
      year = dateSplit[2].substring(2,4); //Only 2 chars for year.
    } 
    
    let valueToReturn = day + month + year //Ultimos dos digitos del año
    return Number(valueToReturn)
  } catch(err){
    return 0;
  }
}

function getPesificacion(pesificacion:any, tag:string){
  try{
    if(tag=='startDate' && pesificacion.startDate){
      return getDateISOFormat(pesificacion.startDate);
    } else if(tag=='endDate' && pesificacion.endDate){
      return getDateISOFormat(pesificacion.endDate);
    } else {
      return getDateISOFormat(pesificacion);
    }
  } catch(err){
    return getDateISOFormat(pesificacion);
  }
}

function getSensorization(isOwnSensorization: boolean) {
  try {
    return isOwnSensorization ? 'P' : 'T';
  } catch (err) {
    return '';
  }
}

//--------------- COMMISION -----------------\\
function getCommisionBuyer(order:Order){
  const BUYER_CODE= order.price.observations.buyer;
  const isBuyerWithCommission0:number[]= [SYNGENTA_CODE_BUYER, COMMODITIES_CODE, HUGHES_CODE_BUYER];

  //Si el comprador es Syngenta.
  if(BUYER_CODE==SYNGENTA_CODE_BUYER){
    //Si es AVC, la comisión compradora es 0.25.
    //Si no es AVC, la comisión compradora es la que viene en el objeto.
    return order.price.businessParticularities.isAVC==1 ? COMMISION_SYNGENTA_AVC_BUYER : (order.commision.buyer <= 0 ? 0 : order.commision.buyer);
  }

    //Si la comisión compradora es 0 o el comprador es Commodities o Hughes, es 0. Sino el valor que tiene el objeto.
    return order.commision.buyer<=0 || isBuyerWithCommission0.includes(BUYER_CODE) ? 0 : order.commision.buyer;
  }

function getCommisionSeller(order:Order){
  const SELLER_CODE= order.sellerData.codeS;  
  const isSellerWithCommission0:number[]= [COMMODITIES_CODE_SELLER, HUGHES_CODE_SELLER];

  //Si el vendedor es Syngenta.
  if(SELLER_CODE==SYNGENTA_CODE_SELLER){
    //Si es AVC, la comisión vendedora es 0.
    //Si no es AVC, la comisión vendedora es la que viene en el objeto.
    return order.price.businessParticularities.isAVC==1 ? 0 : (order.commision.seller <= 0 ? 0 : order.commision.seller);
  }

  //Si la comisión vendedora es 0 o el vendedor es Commodities o Hughes, es 0. Sino el valor que tiene el objeto.
  return order.commision.seller <= 0 || isSellerWithCommission0.includes(SELLER_CODE) ? 0 : order.commision.seller;
}

//---------- PLACE OF DELIVERY ----------------\\

export function getPlaceOfDeliveryIBM(order:Order) {
  let placeOfDelivery:PlaceOfDelivery=order.price.placeOfDelivery;
  let isCommodities:boolean=isCodeCommoditiesOrHughesBuyer(order.price.observations.buyer);
  let valueToReturn: number = 0;

  switch(placeOfDelivery.zone){
    case PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE: valueToReturn=getZoneOfRosarioNorte(placeOfDelivery.port, isCommodities); break;
    case PLACE_OF_DELIVERY_CONST.ROSARIO_SUR: valueToReturn=getZoneOfRosarioSur(placeOfDelivery.port,isCommodities); break;
    case PLACE_OF_DELIVERY_CONST.ROSARIO: valueToReturn=5; break;
    case PLACE_OF_DELIVERY_CONST.UP_RIVER: valueToReturn=90001; break;
    case PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE_Y_ROSARIO: valueToReturn=(isCommodities? 1503:0); break; //ROS NOR Y ROS
    case PLACE_OF_DELIVERY_CONST.ROSARIO_SUR_Y_ROSARIO: valueToReturn= (isCommodities? 1504: 0); break; //ROS SUR Y ROS
    case PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE_Y_SUR: valueToReturn= (isCommodities? 1502: 0); break; //ROS NOR Y ROS SUR
    case PLACE_OF_DELIVERY_CONST.PUERTOS_BUENOS_AIRES: valueToReturn=getZoneOfPuertosBuenosAires(placeOfDelivery.port); break;
    case PLACE_OF_DELIVERY_CONST.PUERTOS_ENTRE_RIOS: valueToReturn=getZoneOfPuertosEntreRios(placeOfDelivery.port); break;
    case PLACE_OF_DELIVERY_CONST.PLANTAS_GRASSI: valueToReturn=getZoneOfPlantasGrassi(placeOfDelivery.port); break;
    case PLACE_OF_DELIVERY_CONST.PLANTAS_INTERIOR: valueToReturn=getZoneOfPlantasInterior(placeOfDelivery.port); break;
  }

  return valueToReturn;
}

function getZoneOfRosarioNorte(port: number, isCommodities:boolean){
  let valueToReturn=0;
  if(isCommodities) return 1500;
  
  switch(port){
    case 1: valueToReturn=6; break;
    case 2: valueToReturn=12; break;
    case 3: valueToReturn=322; break;
    case 4: valueToReturn=180; break;
    default: valueToReturn=0; break;
  }
  return valueToReturn;
}

function getZoneOfRosarioSur(port: number,isCommodities:boolean){
  let valueToReturn=0;
  if(isCommodities) return 1501;

  switch(port){
    case 1: valueToReturn=359; break;
    case 2: valueToReturn=350; break;
    case 3: valueToReturn=215; break;
    case 4: valueToReturn=8; break;
    default: valueToReturn=0; break;
  }
  return valueToReturn;
}

export function getZoneOfPuertosBuenosAires(port: number){
  let valueToReturn=0;
  switch(port){
    case 1: valueToReturn=1; break;
    case 2: valueToReturn=132; break;
    case 3: valueToReturn=426; break;
    case 4: valueToReturn=551; break;
    case 5: valueToReturn=11; break;
    case 6: valueToReturn=41; break;
    default: valueToReturn=2; break;
  }
  return valueToReturn;
}

export function getZoneOfPuertosEntreRios(zone: number){
  let valueToReturn=0;
  switch(zone){
    case 1: valueToReturn=945; break;
    case 2: valueToReturn=4; break;
  }
  return valueToReturn;
}

export function getZoneOfPlantasGrassi(zone: number){
  let valueToReturn=0;
  switch(zone){
    case 1: valueToReturn=366; break;
    case 2: valueToReturn=858; break;
    case 3: valueToReturn=310; break;
  }
  return valueToReturn;
}

export function getZoneOfPlantasInterior(zone:number){
  return getStructPlantasInterior(zone).cgo;
}

export function getStructPlantasInterior(port:number){
  const plantas_interior= Object.entries(PLANTAS_INTERIOR_MAP);
  const quantItems=plantas_interior.length;  
  const portString= puertos[11].dropdown[port - 1].toLowerCase();
  let valueToReturn:any;

  try{
    for(let i=0; i<quantItems; i++){  
      if(plantas_interior[i][1].nombre.toLowerCase()==portString){
        valueToReturn=plantas_interior[i][1];
        throw new Error();
      }
    }
    return null;
  } catch(err){}

  return valueToReturn;
}

function getPortEspecify(order:OrderExchange){
  let placeOfDelivery:PlaceOfDelivery=order.price.placeOfDelivery;
  let valueToReturn: number = 0;
  switch(placeOfDelivery.zone){
    case PLACE_OF_DELIVERY_CONST.ROSARIO_NORTE: valueToReturn=getZoneOfRosarioNorte(placeOfDelivery.port, false); break;
    case PLACE_OF_DELIVERY_CONST.ROSARIO_SUR: valueToReturn=getZoneOfRosarioSur(placeOfDelivery.port,false); break;
    case PLACE_OF_DELIVERY_CONST.PUERTOS_BUENOS_AIRES: valueToReturn=getZoneOfPuertosBuenosAires(placeOfDelivery.port); break;
    case PLACE_OF_DELIVERY_CONST.PUERTOS_ENTRE_RIOS: valueToReturn=getZoneOfPuertosEntreRios(placeOfDelivery.port); break;
    case PLACE_OF_DELIVERY_CONST.PLANTAS_GRASSI: valueToReturn=getZoneOfPlantasGrassi(placeOfDelivery.port);break;
    case PLACE_OF_DELIVERY_CONST.PLANTAS_INTERIOR: valueToReturn=getZoneOfPlantasInterior(placeOfDelivery.port); break;
  }
  return valueToReturn;
}

//-----------------------------------\\

function searchCodePostal(locality:string, province:number){
  const provincesWithLetterIBMConst:any=provincesWithLetterIBM;
  const codePostals:any=CODIGO_POSTAL;

  const provinceMatchWithIBM=provincesWithLetterIBMConst[province];
  const quantItems=codePostals.length;

  const localityLowerCase=locality.toLocaleLowerCase();

  let valueToReturn=0;
  try{
    for(let i=0; i<quantItems; i++){
      const descriptionLowerCase=codePostals[i].DESCRIPCION.toLocaleLowerCase();
      if( (descriptionLowerCase==localityLowerCase ||
          descriptionLowerCase==localityLowerCase.replace("ñ", "n")) &&
          codePostals[i].PROVINCIA==provinceMatchWithIBM){
        valueToReturn=codePostals[i]["CODIGO POSTAL"];
        throw new Error();
      }
    }
  } catch(err){}
  
  return valueToReturn;
}

function searchCodePostalByAfipCode(afipCode:number){
  const codePostals:any=CODIGO_POSTAL;
  const quantItems=codePostals.length;
  let valueToReturn=0;
  try{
    for(let i=0; i<quantItems; i++){
      if(codePostals[i]["AFIP"]==afipCode){
        valueToReturn=codePostals[i]["CODIGO POSTAL"];
      }
    }
  } catch(err){}
  return valueToReturn;
}


//---------------------------------------------\\

export function getTypeCoin(typeCoin: string) {
  return getTypeCoinIBM(typeCoin);
}

function getTypeDolar(order: Order){
  try{
    if(order.price.typeCoin=='--'){
      return order.price.typeDolar>0? order.price.typeDolar : 1;
    }
    return order.price.typeCoin==typeCoins.USD && order.price.typeDolar > 0? order.price.typeDolar : 0;
  } catch(err){}
  return 0;
}

function getTypeCoinFreight(businessParticularities: BusinessParticularities) {
  if(getIsFreightPaidByTheBuyer(businessParticularities.freightPaidByTheBuyer)){
    
    const typeCoin = businessParticularities?.freightPaidByTheBuyer?.amount?.typeCoin;
    return typeCoin == typeCoins.ARS ? 0 : typeCoin==typeCoins.USD ? 1 : 9;
  }

  return 9;
}


//---------- BUSSINESS PARTICULARITIES ---------------\\
function isWashOut(businessParticularities: BusinessParticularities) {
  return booleanToString( (businessParticularities?.washOut  ?? 0 ) == 1);
}

export function getImporteBonification(businessParticularities: BusinessParticularities){
  const quantity=businessParticularities?.bonification?.amount?.quantity ?? 0
  return  quantity > 0 ? quantity : 0;
}

function getBonificationNumber(businessParticularities: BusinessParticularities){
  try{
    if(businessParticularities.bonification.typeBonification>0){
      return '0' + businessParticularities.bonification.typeBonification;
    }
    return ''
  } catch(err){
    return '';
  }
}

function getTypeCoinBonification(businessParticularities:BusinessParticularities){
  return getTypeCoinBonificationIBM(businessParticularities?.bonification?.amount?.typeCoin ?? '');
}

function getBonificationCaracteristica(businessParticularities: BusinessParticularities){
  try{
    if(businessParticularities.bonification.typeBonification==1){
      return getDateISOFormat(businessParticularities.bonification.startDate);
    } else if(businessParticularities.bonification.typeBonification==2){
      return businessParticularities.bonification.placeOfDelivery;
    }
    return 0;
  } catch(err){
    return 0;
  }
} 

function getTypeReduction(businessParticularities: BusinessParticularities){
  try{
    if(businessParticularities.reduction.amount>0){
      return "02"
    } else if(businessParticularities.reduction.percentage>0){
      return "01"
    }
    return '';
  } catch(e){
    return '';
  }
}

function getValueReduction(businessParticularities:BusinessParticularities){
  const amount = businessParticularities?.reduction?.amount ?? 0;
  const percentage = businessParticularities?.reduction?.percentage ?? 0;
  return amount > 0 ? amount : percentage > 0 ? percentage : 0;
}

function getTypeCoinReduction(businessParticularities:BusinessParticularities){
  return getTypeCoinBonificationIBM(businessParticularities?.reduction?.typeCoin ?? '');
}

function isAccumulator(businessParticularities: BusinessParticularities) {
  try {
    const isAccumulator = businessParticularities.accumulator.isAccumulator;
    const numberAccumulator= businessParticularities.accumulator.typeAccumulator;
    if(isAccumulator==1 && numberAccumulator>0){
      return numberAccumulator.toString();
    }
  } catch (err) {}
  return '';
}

function isCollectionClause(businessParticularities: BusinessParticularities) {
  try {
    return booleanToString(businessParticularities.collectionClause == 1);
  } catch (err) {}
  return '';
}

//FREIGHT PAID BY THE BUYER
function getDaysOfFreightPaidByTheBuyer(businessParticularities: BusinessParticularities) {
  if(getIsFreightPaidByTheBuyer(businessParticularities?.freightPaidByTheBuyer)){
    return businessParticularities.freightPaidByTheBuyer.numberOfDays;
  }

  return 0;
}

function getPriceFreight(businessParticularities: BusinessParticularities) {
  if(getIsFreightPaidByTheBuyer(businessParticularities?.freightPaidByTheBuyer)){

    const typeCoin=businessParticularities?.freightPaidByTheBuyer?.amount?.typeCoin;
    if(typeCoin == RATE_TO_DEFINE){
      return 0; //Es a definir
    }
  
    //Es definido
    return businessParticularities?.freightPaidByTheBuyer?.amount?.quantity ?? 0;
  }

  //No hay flete a cargo del comprador
  return 0;
}

function isFreighPaidByTheBuyer(businessParticularities: BusinessParticularities) {
  return booleanToString(getIsFreightPaidByTheBuyer(businessParticularities?.freightPaidByTheBuyer));
  
  /*if(getIsFreightPaidByTheBuyer(businessParticularities?.freightPaidByTheBuyer)){
    return 'S'
  }
  return ""*/
  //const isFreightPaid=businessParticularities?.freightPaidByTheBuyer?.isFreightPaid ?? 0;
  //return booleanToString(isFreightPaid==1);
} 

function getEventTypeFreightPaidByTheBuyer(businessParticularities: BusinessParticularities){
  if(getIsFreightPaidByTheBuyer(businessParticularities?.freightPaidByTheBuyer)){
    const eventType = businessParticularities?.freightPaidByTheBuyer?.eventType ?? '';

    switch(eventType){
      case 'APLICACION': return '1';
      case 'DESCARGA': return '2';
      case 'PESIFICACION': return '3';
      case 'FACTURACION': return '4';
      default: return "";
    }

  }
  return "";
}

function isFreightPaidByTheClient(businessParticularities: BusinessParticularities) {
  if(getIsFreightPaidByTheBuyer(businessParticularities?.freightPaidByTheBuyer)){
    const isCustomerFreight=businessParticularities?.freightPaidByTheBuyer?.isCustomerFreight ?? 0;
    return booleanToString(isCustomerFreight==1);  
  }

  return "";
}
//-------------------------------------\\

function isRespectVatException(businessParticularities: BusinessParticularities) {
  const isRespectVat=businessParticularities?.respectVatException ?? 0;
  return booleanToString(isRespectVat==1);
}

//Laboratory Agreement
function isLaboratoryAgreement(businessParticularities: BusinessParticularities) {
  return booleanToString(businessParticularities?.laboratoryAgreement > 0);
}

function getLaboratoryAgreement(businessParticularities: BusinessParticularities) {
  return businessParticularities?.laboratoryAgreement>0? businessParticularities.laboratoryAgreement: 0;
}
//-------------------------------------\\

function isGravanz(businessParticularities: BusinessParticularities) {
  const isGravanz=businessParticularities?.gravanz?.isGravanz ?? 0;
  return booleanToString(isGravanz==1);
}

//-------------------------------------\\

function isConvenio(businessParticularities:BusinessParticularities){
  const agreement = businessParticularities?.agreement ?? 0;
  return booleanToString(agreement==1);
}

function getSealedToRetrieve(businessParticularities:BusinessParticularities){
  const sealedToRetrieve = businessParticularities?.sealedToRetrieve ?? 0;
  return sealedToRetrieve > 0 ? '0' + sealedToRetrieve : '';
}

function isToWithdraw(businessParticularities:BusinessParticularities){
  return booleanToString(businessParticularities?.toWithdraw==1);
}

function getSellerName(codeS:number){
  let sellers:SellerObject=sellerWithData;
  return sellers[codeS]?.nombre ?? ''
}

function getSyngentaSubdivision(businessParticularities:BusinessParticularities){
  try{
    const subdiv= businessParticularities.subdivisionSyngenta;
    if(subdiv>0){
      switch(subdiv){
        case 1: return 'A'
        case 2: return 'S'
        case 3: return 'N'
        case 4: return 'P'
      }
    }
  } catch(err){}
  return '';
}

function getIsAvc(businessParticularities:BusinessParticularities){
  const isAvc = businessParticularities?.isAVC ?? 0;
  return isAvc==1 ? 'A' : '';
}

/* -------------- OBSERVATIONS ----------------- */
function getGravanzInfo(businessParticularities:BusinessParticularities){
  try{
    if(businessParticularities.gravanz.isGravanz==1){
      let string="Grvz: -Selec de bolsa:"+ businessParticularities.gravanz.selectionStock;
      string+=" -Empresa: "+ businessParticularities.gravanz.business;
      return string.toUpperCase();
    }
  } catch(err){}
  return ''
}

function getConvenioNutrienInfo(businessParticularities:BusinessParticularities){
  const isNutr=businessParticularities?.nutrienAgreement ?? false;
  return isNutr? 'Convenio Nutrien': '';
}

function getNroBillingInfo(businessParticularities:BusinessParticularities){
  try{
    if(businessParticularities?.nroBilling?.length>0){
      return 'Factura insumos num.: '+businessParticularities.nroBilling;
    }
  } catch(err){}
  return '';
}

function getEntregaEnSiloBolsasInfo(businessParticularities:BusinessParticularities){
  try{
    if(isEntregaSiloBolsas(businessParticularities.deliveryInSiloBolsas)){
      let string:string='Entrg Silo Bolsas:';

      //Si tiene valor en brand, agregamos string
      if(businessParticularities.deliveryInSiloBolsas.brand>0){
        string+="-Marca:"+ MARCA_SILO_BOLSAS[businessParticularities.deliveryInSiloBolsas.brand];
      }

      if(businessParticularities.deliveryInSiloBolsas.quantity>0){
        string+="-Cantidad:"+ businessParticularities.deliveryInSiloBolsas.quantity+'';
      }

      if(businessParticularities.deliveryInSiloBolsas.size>0){
        string+="-Tamano:"+ TAMAIN_SILO_BOLSAS[businessParticularities.deliveryInSiloBolsas.size];
      }

      return string.toUpperCase();
    }
  } catch(err){}
  return '';
}

function getEntregaPlantasGrassiInfo(businessParticularities:BusinessParticularities){
  try{
    if(isDeliveryAtGrassiPlants(businessParticularities.deliveriesAtGrassiPlant)){
      let string="Entreg plant grassi:"
      //Si tiene porcentaje en comission, agregamos string
      if(businessParticularities.deliveriesAtGrassiPlant.commission.percentage>0){
        string+="-Comis:"+ businessParticularities.deliveriesAtGrassiPlant.commission.percentage+"%";
      }
      //Si tiene quantity en flete, agregamos string
      if(businessParticularities.deliveriesAtGrassiPlant.freight.amount.quantity>0){
        string+="-Flete:"+ businessParticularities.deliveriesAtGrassiPlant.freight.amount.quantity+"$ "+businessParticularities.deliveriesAtGrassiPlant.freight.description;
      }

      //Si tiene quantity en humedad, agregamos string
      if(businessParticularities.deliveriesAtGrassiPlant.humidity.amount.quantity>0){
        string+="-Hum:"+ businessParticularities.deliveriesAtGrassiPlant.humidity.amount.quantity+"$ Tarifa portuaria:"+booleanToString(businessParticularities.deliveriesAtGrassiPlant.humidity.isPortFee==1);
        +"porc:"+businessParticularities.deliveriesAtGrassiPlant.humidity.percentage+"%"+
        "reb x mont:"+businessParticularities.deliveriesAtGrassiPlant.humidity.reductionAmount;+
        "Reb x punt:"+businessParticularities.deliveriesAtGrassiPlant.humidity.reductionPoint;
      }

      //Si tiene quantity en paritaria, agregamos string
      if(businessParticularities.deliveriesAtGrassiPlant.parity.quantity>0){
        string+="Parit:"+ businessParticularities.deliveriesAtGrassiPlant.parity.quantity+"$";
      }

      //Si tiene lugar de entrega, lo agregamos.
      if(businessParticularities.deliveriesAtGrassiPlant.placeOfDelivery>0){
        string+="Lugar de entrega:"+ businessParticularities.deliveriesAtGrassiPlant.placeOfDelivery;
      }

      if(businessParticularities.deliveriesAtGrassiPlant.volatileWaste.percentage>0){
        string+="Merma volatil: porc:"+ businessParticularities.deliveriesAtGrassiPlant.volatileWaste.percentage+"%"+
        "Descr:"+businessParticularities.deliveriesAtGrassiPlant.volatileWaste.productDescription;
      }

      return string.toUpperCase();
    }
  } catch(err){}
  return ''
}

function getConditionalInfo(businessParticularities:BusinessParticularities){
  try{
    if(isConditional(businessParticularities.conditional)){
      let string='Condicional: ';
      switch(businessParticularities.conditional.selection){
        case 1: string+='Call venta';break;
        case 2: string+='Put venta';break;
        case 3: string+='Call compra';break;
        case 4: string+='Put compra';break;
        default:'';
      }
      string+=" Strike:"+businessParticularities.conditional.strike;
      string+=" Posic: "+businessParticularities.conditional.position;
      return string;
    }
  } catch(err){}
  return '';
}

//---------------- PAF GARANTIZADO ----------------\\

export function getIsPAFGuaranteed(bussPart:BusinessParticularities){
  return booleanToString(bussPart.guaranteedPAF);
}

//--------------- ANTICIPO FINANCIERO --------------\\
function isAnticipeFinancier(bussPart:BusinessParticularities){
  return booleanToString(getIsFinancialAdvance(bussPart));
}

//Si la factura va a ser con o sin iva ( C con iva, N Sin iva, A a determinar)
function getIvaStatusAnticipeFinancier(bussPart:BusinessParticularities){
  try{
    switch(bussPart.financialAdvance.VAT){
      case 1: return 'N';
      case 2: return 'C';
      case 3: return 'A';
    }
  }catch(err){}
  return 'N'
}

//Obtenemos el tipo de tasa: 'A' a determinar, 'D' determinada
function getRateTypeAnticipeFinancier(bussPart:BusinessParticularities){
  try{
    switch(bussPart.financialAdvance.rate){
      case 1: return 'A';
      case 2: return 'D';
    }
  }catch(err){}
  return ''
}

//Obenemos la tasa a pagar si es determinada
function getRateAnticipeFinancier(bussPart:BusinessParticularities){
  return (bussPart?.financialAdvance?.rate==2 && bussPart?.financialAdvance?.amountRate>0) ? 
    bussPart.financialAdvance.amountRate ?? 0:0;
}

//Obtenemos el tipo de porcentaje a pagar: 'A' a determinar, 'D' determinado, 'P' por defecto
function getPercentageTypeAnticipeFinancier(bussPart:BusinessParticularities){
  try{
    switch(bussPart.financialAdvance.percentageTypeToPay){
      case 1: return 'A';
      case 2: return 'D';
      case 3: return 'P';
    }
  }catch(err){}
  return ''
}

//Obtenemos el porcentaje a pagar si es determinado
function getPercentageAnticipeFinancier(bussPart:BusinessParticularities){
  return (bussPart?.financialAdvance?.percentageTypeToPay==2 && bussPart?.financialAdvance?.percentagePayable>0) ?
    bussPart.financialAdvance.percentagePayable ?? 0:0;
}

function getObservationParam(order:OrderExchange){
  try{
    const obsParam=order.obsParam;
    if(obsParam && obsParam!=' ' && obsParam!='--'){
      return ("Obs: "+obsParam).toUpperCase();
    }
  } catch(err){}
  return '';
}


function getObservations(order:OrderExchange){
  return quitarTildes(
    getConvenioNutrienInfo(order.price.businessParticularities) +
    getGravanzInfo(order.price.businessParticularities) +
    getEntregaPlantasGrassiInfo(order.price.businessParticularities) +
    getEntregaEnSiloBolsasInfo(order.price.businessParticularities) +
    getConditionalInfo(order.price.businessParticularities) +
    getObservationParam(order) + 
    getNroBillingInfo(order.price.businessParticularities)
  );
}

/**
 * Given a string, replace all the vowels with accents with the vowels without accents.
 * @param texto 
 * @returns string
 */
function quitarTildes(texto: string): string {
  const vocalesConTilde = /[ÁáÉéÍíÓóÚú]/g;
  const reemplazos: { [key: string]: string } = {
      'Á': 'A',
      'á': 'a',
      'É': 'E',
      'é': 'e',
      'Í': 'I',
      'í': 'i',
      'Ó': 'O',
      'ó': 'o',
      'Ú': 'U',
      'ú': 'u'
  };

  return texto.replace(vocalesConTilde, match => reemplazos[match]);
}

/*Given a thread.
If the identifier is 1, it returns the first 65 characters.
If it is 2, it returns the second 65 characters.
And so on*/
function getObservationSubstring(obs:string, identificator:number){
  return obs.substring((identificator-1)*65, (identificator-1)*65+65);
}

export function getTypeCoinBonificationIBM(typeCoin:string){
  return typeCoin==typeCoins.ARS ? 0 : (typeCoin==typeCoins.USD) ? 1 : 0;
}

export function getTypeCoinIBM(typeCoin:string){
  return typeCoin == typeCoins.ARS ? 1 : typeCoin==typeCoins.USD ? 4 : 1;
}