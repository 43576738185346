//ORDER STATE (estado de las ordenes):
export const orderState = {
	'-1':'--',
	0: "--",
	1: "De vuelta",
	2: "Pendientes",
	3: "Confirmadas",
}

//----------------Orders----------------\\
export const orderOrigin = {
	'-1':'--',
	0:'--',
	1:"Desde el tablero",
	2:"Manual"
}

export const orderType = {
	'-1':'--',
	0: '--',
	1: "De venta",
	2: 'De compra',
}

export const orderCategory = {
	'-1':'--',
	0: "--",
	1: "A Precio",
	2: "A Fijar"
}


//ORDER STATE (estado de las ordenes):
export const orderStates = {
	'-1':'--',
	0: "--",
	1: "Devuelta",
	2: "Pendiente",
	3: "Confirmada",
	4: "Visto",
	5: "Ofertado",
	6: "Enviado a bolsa",
	7: "Borrador",
	8: "Contrato",
	9: "Fuera de mercado"
}
