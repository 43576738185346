import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'eraser-svg',
  templateUrl: './eraser-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class EraserSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
