import { Pipe, PipeTransform } from '@angular/core';
import { Price, PriceString } from '../interfaces/price.interface';
import { PRICE_STATUS_CONST } from '../const/prices.const';

const arrayStatus = [PRICE_STATUS_CONST.IDEA,
  PRICE_STATUS_CONST.PRECIO,
  PRICE_STATUS_CONST.PAUSADO,
  PRICE_STATUS_CONST.RETIRADO];

@Pipe({
  name: 'isPriceVisibleForComercial'
})
export class PriceVisibleForComercial implements PipeTransform {
    transform(list: any[], isComercial:boolean) {
        if (isComercial){
          let arrayFinal: any[] = [];
    
          list.forEach( (element:Price) => { 
            if ( arrayStatus.includes(element.status)){
              arrayFinal.push(element);
            }
          });    
          return arrayFinal;
        }

        return list;
      }
}

export function isPriceVisibleForComercial(price:Price):boolean{
  return arrayStatus.includes(price.status);
}