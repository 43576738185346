import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { DateChecker, getDaysBetweenDatesByBusinessDay } from 'src/app/shared/date.checker';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { PriceValidator } from 'src/app/shared/price';
import { WayPayValidator } from 'src/app/shared/way-pay';
import { Field } from '../fields.component';

@Component({
  selector: 'app-expiration',
  templateUrl: './expiration.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class ExpirationComponent extends Field implements OnInit, OnDestroy {
  @Input() isOperator:boolean=false;
  isFechaFija:  boolean = false;
  isFixExpiration: boolean = false;
  isContraEntrega: boolean = false;
  
  wayPaySubscription!:Subscription;
  constructor(store: Store<appState>, private date: DateChecker,private _adapter: DateAdapter<any>, 
    private priceV:PriceValidator, private wayPayV:WayPayValidator) {
    super(store);
    this._adapter.setLocale('es');
    this.elementSelected = '';
    this.wayPaySubscription=this.store.subscribe(state=>{
      setTimeout(()=> {
        const price=this.getPrice();
        if(price){
          this.isContraEntrega = this.wayPayV.getIsContraEntrega(price);
          this.isFechaFija = this.wayPayV.getIsFechaFija(price);
          this.isFixExpiration = this.priceV.isFixExpiration(price);
        }
      }, 100)
      
    })
  }

  ngOnInit(): void {
    this.setTag(priceTags.wayPayExpiration);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.wayPaySubscription?.unsubscribe();
  }


  override getIsFieldVoid(): boolean {
    return !this.isContraEntrega && this.elementSelected == ''
  }
  
  override rememberValueInForm(){
    if(this.subTag==priceTags.wayPayExpiration || this.thirdTag==priceTags.wayPayExpiration){
      this.rememberValueTypeThree();
    }
  }

  override setElement(element: any, index: number): void {
    this.setTag(priceTags.wayPayExpiration);
    this.setElementToGenerate(element);
    this.verifyDaysToActualizate();
  }

  isNecesaryExpiration(){
    return !this.isContraEntrega && !this.wayPayV.getIsConCartaDeGarantia(this.getPrice()) && 
    !(this.getPrice()? this.getPrice().typeCoin: '' == typeCoins.USD);  
  }

  setOption(){
    this.setTag(priceTags.wayPayOptions);
    this.setElementToGenerate(this.isFixExpiration? 0: 1);

    this.setTag(priceTags.wayPayExpiration);
  }

  override setTag(nameOfTag: string): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.wayPay;
      this.subTag = nameOfTag;
      this.thirdTag='';
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.wayPay;
      this.thirdTag = nameOfTag;
    }
  }

  verifyDaysToActualizate(){
    const price=this.getPrice();
    let wayPay=price.wayPay;
    let typeCoin=price.typeCoin;
    let expiration=wayPay.expiration;
    let typeBusiness=price.typeBusiness;
    let isBusinessDay=wayPay.isBusinessDays==1;

    let newDays=0;
    if (wayPay.wayPayName == wayPayNames.conCartaGarantia) {
      if (typeCoin == typeCoins.ARS || (typeCoin==typeCoins.USD && wayPay.percentage!=97.5)) {
        newDays = getDaysBetweenDatesByBusinessDay(this.date.getDate(), expiration,isBusinessDay);
      }
    } else if (wayPay.wayPayName == wayPayNames.fechaFija) {
      if (typeBusiness == 1 || typeBusiness == 3) {
        newDays = getDaysBetweenDatesByBusinessDay(this.date.getDate(), expiration,isBusinessDay);
        //Por default 5 días hábiles o corridos del día posterior o sino se coloca
        //el vencimiento manual - la mercadería tiene que estar entregada para cobrar"
      } else if (typeBusiness == 2) {
        newDays = getDaysBetweenDatesByBusinessDay(this.date.getDate(), expiration,isBusinessDay);
      } else if (typeBusiness == 4) {
        //Último día hábil del mes de entrega
        //¿Calculamos los días? Si.
        newDays = getDaysBetweenDatesByBusinessDay(this.date.getDate(), expiration,isBusinessDay);
      }
    } else if (wayPay.wayPayName == wayPayNames.diferidoPesificacion) {
      //newDays = getDaysBetweenDatesByBusinessDay(this.date.getDate(), expiration,isBusinessDay);
    } else if (wayPay.wayPayName == wayPayNames.diferidoFacturacion) {
      //newDays = getDaysBetweenDatesByBusinessDay(this.date.getDate(), expiration,isBusinessDay);
    }

    if(newDays>0){
      this.setTag(priceTags.wayPayDays);
      this.setElementToGenerate(newDays);
      this.setTag(priceTags.wayPayExpiration);
    }

  }
  
}
