import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleteCargoCompradorComponent } from './flete-cargo-comprador.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { AmountModule } from '../../amount/amount.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';



@NgModule({
  declarations: [
    FleteCargoCompradorComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    AmountModule,
    NumberInputModule
  ],exports:[
    FleteCargoCompradorComponent
  ]
})
export class FleteCargoCompradorModule { }
