import { getTableIBM } from "../data.checker";
import { IBM_TABLES } from "./ibm/tables";

//export let QUALITY_IBM: {[key:string]:string} = getTableIBM(IBM_TABLES.CERCALI);

/*export function updateQuality(data:{[key:string]:string}){
    QUALITY_IBM=data;
}*/

export const QUALITY_IBM:any = {
"-1": "--",
0: "--",
  1: 'Condiciones Camara',
  2: 'Segun Muestra Tal Cual',
  3: 'Condiciones Camara Sin Bonificaciones Ni Rebajas',
  4: 'Condiciones Camara Tolerando Humedad Hasta',
  5: 'Condiciones Camara Libre De Rebajas Por Granos Quebrados Y/O Partidos Hasta 50 %',
  6: 'Condiciones Camara,Secada Por Punto.',
  7: 'Condiciones Camara Libre De Rebajas Por Granos Quebrados Y/O Partidos H/50%,Secada Por Punto.',
  8: 'Condiciones Camara Libre De Rebajas Por Granos Quebrados Y/O Partidos H/ 30%, Secada Por Punto.',
  9: 'Segun Muestras Recibidas Y Analizadas Y Demas Condiciones Camara.',
  10: 'Condiciones Camara Libre De Rebajas Por Granos Quebrados Y/O Partidos H/ 30%',
  11: 'Grado Dos O Mejor, Liquidandose Tal Cual.',
  12: 'Cond.Cra.Peso Y Calidad En Destino C/Analisis Del Cpdor. Si Vdor.No Esta De Acuerdo Result.Cra.Cba.',
  13: 'Cond.Cra.Art.12. Libre Olor,Carbon,Ardido,Brotado,Trebol,Granos Punta Negra. Minimo Peso Hectol.76',
  14: 'Grado Dos O Mejor, Sin Bonificaciones Ni Rebajas',
  15: 'Grado Dos O Mejor',
  16: 'Mercaderia Tal Cual Sin Bonificaciones Ni Rebajas',
  17: 'Grado Dos, No Bonifica Grado Uno Y Rebaja Grado Tres Y Demas Condiciones Camara',
  18: 'Grado Dos O Mejor, No Bonifica Grado Uno, Rechaza Grado Tres Y Demas Condiciones Camara',
  19: 'Mercaderia Tal Cual',
  20: 'Grado Dos, Sin Bonificaciones Ni Rebajasno Reconoce Grado,Ni Prot.Y Demas Cond. Camara',
  21: 'Minimo Ph 78 Y Minimo Prot. 10',
  22: 'Minimo Ph 78 Y Minimo Prot. 10.5 Y Demas Condicion Es Camara',
  23: 'Minimo Ph 78 Y Minimo Prot. 10.8',
  24: 'Minimo Ph 78 Y Minimo Prot. 11',
  25: 'Minimo Ph 76 Y Minimo Gl 28',
  26: 'Minimo Ph 74 Y Minimo Gl 26',
  27: 'Fca Con 20% Libre De Rebajas Por Granos Verdes. Recepción 30%',
  28: 'Minimo Ph 76% Y Minimo Proteinas 10,5%  Y Demas Co Ondiciones Camara',
  29: 'C/Cam,Libre De Rebajas Por Granos Queb.Y/O Partido O Hasta 50% Y Un Punto Libre De Gastos De Secada',
  30: 'C.Cam, Libre De Rebajas Por Granos Queb. Y/O Part. Hasta 50% Y Recepción De Granos Dañados Hasta 40%',
  31: 'C.Cam,Lib.De Reb. Por Granos Queb. Y/O Part. H/50% Y Recep.De Granos Dañados Hasta 20% Al 0.75% X Pt',
  32: 'C.Cam,Lib.De Reb. Por Granos Queb. Y/O Part. H/50% Y Recep.De Granos Dañados Hasta 40% Al 0.50% X Pt',
  33: 'C.C,Lib. Reb.Queb.Y/O Part.H/50%,Recep.Dañado H40% % Desde 8% A 20% R.0,5%.Desde20,1% A 40% R1% X Pt',
  34: 'C.Cam,Lib. Reb.Queb.Y/O Part.H/50%, Recepcion De G Granos Dañados Hasta 20% Al 0.50% Por Pto',
  35: 'Articulo 12. Cond.Cam.Sin Bonif. Ni Rebajas',
  36: 'C/Cam,Libre De Rebajas Por Granos Queb.Y/O Partido O Hasta 50% Y Dos Puntos Libres De Gastos De Seca',
  37: '.Cam,Lib.De Reb. Por Granos Queb. Y/O Part. H/50% Y Recep.De Granos Dañados Hasta 15% Al 0.75% X Pt',
  38: 'C.C,Lib.Reb.Queb.Y/O Part.H/50%,Recep Dañado H 30%Desde 8% A 20% R 0.50%.Desde 20,1 A 30% R 1% X Pt',
  39: 'Condiciones Camara No Bonifica G.1',
  40: 'C.Cam,Lib.De Reb. Por Granos Queb. Y/O Part. H/50%Y Recep.De Granos Dañados Hasta 30% Al 0.50% X Pt',
  41: 'C.Cam,Lib.De Reb. Por Granos Queb. Y/O Part. H/50%% % Y Recep.De Granos Dañados Hasta 30%',
  42: 'Cond.Cra.Art.12. Libre Olor,Carbon,Ardido,Brotado, ,Trebol,Granos Punta Negra.',
  43: 'Cam,Lib.De Reb. Por Granos Queb. Y/O Part. H/50% Yrecep.De Granos Dañados Hasta 15% Al 0,50% X Pto',
  44: 'G.2, Minimo Ph 78 Y Minimo Prot. 10.5. No Bonifica A A Grado 1 Y Rebaja Grado 3.',
  45: 'Articulo 12. Condiciones Camara',
  46: 'Ph Minimo 76 Y Gl Minimo 31 Sin Bonificaciones Ni Rebajas.',
  47: 'Cond.Cra.Art.12. Gluten 24. Libre Olor,Carbon, Ardido,Brotado, Trebol,Granos Punta Negra.',
  48: 'Cond.Cra, Cl 30 Sin Bonificaciones Ni Rebajas , Ar Rt. 12',
  49: 'Cond.Cra,Gl 23, Ph 74 Sin Bonif. Rebajas , Art 12',
  50: 'Cond.Cra,Gl 24, Ph 76 Sin Bonif. Rebajas , Art 12',
  51: 'C.Cra. Proteinas 10,5%.',
  52: 'Cond.Cra,Gl 28, Ph 74 Sin Bonif. Rebajas , Art 12',
  53: 'Cond.Cra,Gl 26, Ph 74 Sin Bonif. Rebajas , Art 12',
  54: 'Cond.Cra,Gl 31, Ph 76 Sin Bonif. Rebajas , Art 12',
  55: 'G2. No Bonif G1,Rebaja G3, Ph78 Prot.10,5%',
  56: 'G2. No Bonif G1,Rebaja G3, Ph76 Prot.10,5%',
  57: 'G2, Prot 9,5% No Bonif G1 Reb G3',
  58: 'Cond.Cra,Gl 24, Ph 74 Sin Bonif. Rebajas , Art 12',
  59: 'Condiciones Camara Libre De Rebajas Por Granos Quebrados Y/O Partidos, Secada Por Punto.',
  60: 'C.C,Lib. Reb.Queb.Y/O Part.H/50%,Secada Por Puntorecep. De Granos Verdes H El 25% (Cobra Desde 8%)',
  61: 'Aceite De Soja, Crudo Desgomado.',
  62: 'C.C,Lib.Reb.Queb.Y/O Part.H/50%,Recep Dañado H 50%Desde 8.01% A 15% R 0.50%.Desde 15,1 A 50% R1% Xp.',
  63: 'C.C,Lib.Reb.Queb.Y/O Part.H/50%,Recep Dañado H 15%Desde 8.01% A 15% R 0.50% X Punto',
  64: 'Condiciones Camara Con 2 Puntos Libres De Gastosde Secada.-',
  65: 'Fabrica,Libre De Gastos Dañado Hasta 8%,Desde 8,01% A 12% Rebaja 0,5% X Punto.',
  66: 'Fabrica, Segun Dañado: 5% A 10% Rebaja 1%,De 10,1%A 15% Rebaja 0,5% Y Del 15,1% A 40% Rebaja 1%.',
  67: 'Fabrica,Libre De Gastos Dañado Hasta 8%,Desde8,01%A 20% Rebaja 0,5% Por Punto.',
  68: 'Fabrica,Libre De Gastos Dañado Hasta 8%, Desde8,01% A 40% Rebaja 0,5% Por Punto.',
  69: 'Fabrica, Libre De Rebaja Por Granos Dañados Hasta10%, 10,01% Al 25% Descuenta 0,5 Por Punto.',
  70: 'Fabrica, Libre De Granos Verdes Hasta 10%Y Recepcion Hasta 30%',
  71: 'Grado Dos, No Bonifica Grado Uno, No Bonifica Proteinas, Rebaja Grado Tres Y Demas Cond. Camara.',
  72: 'Fabrica, Libre De Granos Verdes Hasta 08%Y Recepcion Hasta 30%',
  73: 'Condicion fabrica, libre de rebajas por granos verdes hasta 10% y recepcion hasta 60%',
  74: "Condicion fabrica, libre de rebajas por granos verdes hasta 15%",
  75:	"Fabrica libre de rebaja por granos verdes hasta 20%",
  76: "Girasol Alto Oleico"
};