import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsModule } from '../../fields/business-particularities/options/options.module';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { BuyerGeneratePriceSlideCardComponent } from './buyers-generate-price.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BuyerGeneratePriceSlideCardComponent
  ],
  imports: [
    CommonModule,
    OptionsModule,
    CrossSvgModule,
    PipesModule,
    FormsModule
  ], exports:[
    BuyerGeneratePriceSlideCardComponent
  ]
})
export class BuyerGeneratePriceCardModule { }
