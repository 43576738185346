<div class="row-left gap-8" style="margin-top:8px">
    <button class="button-to-fix row-center tradicional" [ngClass]="{'selected': isSelected(CONDITION_TYPES.TRADICIONAL)}"
    (click)="select(CONDITION_TYPES.TRADICIONAL)">Tradicional</button>
    
    <button class="button-to-fix row-center pase-de-cosecha" [ngClass]="{'selected': isSelected(CONDITION_TYPES.PASE_COSECHA)}" 
    (click)="select(CONDITION_TYPES.PASE_COSECHA)">Pase de cosecha</button>
    
    <button class="button-to-fix row-center porcentaje-de-producto" [ngClass]="{'selected': isSelected(CONDITION_TYPES.PORCENTAJE_PRODUCTO)}" 
    (click)="select(CONDITION_TYPES.PORCENTAJE_PRODUCTO)">Porcentaje de otro producto</button>
    
    <button class="button-to-fix row-center sin-espacio" [ngClass]="{'selected': isSelected(CONDITION_TYPES.SIN_ESPACIO)}" 
    (click)="select(CONDITION_TYPES.SIN_ESPACIO)">Sin espacio</button>

    <button class="button-to-fix row-center show-all" [ngClass]="{'selected': conditionsSelected.length==4}" 
    (click)="selectAll()">Ver todo</button>
</div>