<div class="future-markets column-left gap-8">
    <p class="title">Mercados Futuros</p>

    <div class="filters">
        <select class="filter">
            <option>MtR</option>
            <option>CBOT</option>
        </select>
        <select class="filter">
            <option>Soja</option>
            <option>Trigo</option>
            <option>Maiz</option>
        </select>
        <div class="date">
            <input matInput [matDatepicker]="picker" value="{{date}}"
                class="filter input-date">
            <mat-datepicker-toggle matSuffix [for]="picker" class="logo"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </div>
    </div>

    <table>
        <thead>
            <tr>
                <th id="col1"><span>Posición</span></th>
                <th id="col2"><span>Precio</span></th>
                <th id="col3"><span>Valor US$</span></th>
                <th id="col4"><span>Valor %</span></th>
            </tr>
        </thead>
        <tbody *ngFor="let futureMarket of futureMarkets">
            <tr>
                <td><span>{{futureMarket.date}}</span></td>
                <td><span>{{futureMarket.price}}</span></td>
                <td>
                    <div *ngIf="futureMarket.valorUsd >0 else dolarNegative">
                        <span style="color:#00C689">+{{futureMarket.valorUsd}}</span>
                        <img
                            src="../../../../../assets/images/market-price/dolar-positive.svg" />
                    </div>

                    <ng-template #dolarNegative>
                        <span style="color:#E05B33">{{futureMarket.valorUsd}}</span>
                        <img
                            src="../../../../../assets/images/market-price/dolar-negative.svg" />

                    </ng-template>
                </td>
                <td>
                    <div *ngIf="futureMarket.valorPorc > 0 else porcNegative">
                        <span style="color:#00C689">+{{futureMarket.valorPorc}}</span>
                        <img
                            src="../../../../../assets/images/market-price/percentage-positive.svg" />
                    </div>

                    <ng-template #porcNegative>
                        <span style="color:#E05B33">{{futureMarket.valorPorc}}</span>
                        <img
                            src="../../../../../assets/images/market-price/percentage-negative.svg" />

                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>