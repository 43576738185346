import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterPrice } from 'src/app/redux/actions/price.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { initialPriceString } from 'src/app/shared/const/prices.const';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'app-market-price',
  templateUrl: './market-price.component.html',
  styleUrls: ['./market-price.component.scss']
})
export class MarketPriceComponent implements OnInit {
  @Input() prices!:Price[];
  public PRODUCTS:any=PRODUCT_NAMES;
  public productName:string='';
  public firstElement:any;
  public secondElement:any;
  public thirdElement:any;
  public fourthElement:any;

  constructor(private store:Store<appState>, private router:Router) {}

  ngOnInit(): void {
    this.assignProducts();
    this.productName= this.PRODUCTS[this.firstElement?.productName] ?? '';
  }

  assignProducts(){
    try{
      this.firstElement=this.prices[0];
      this.secondElement=this.prices[1];
      this.thirdElement=this.prices[2];
      this.fourthElement=this.prices[3];
    } catch(err){}
  }

  /*Given a product Name, it applies the filter and goes to the price list, in case of an error, it goes to the price list without filter*/
  goToPriceBoard(productName:string){
    const url=`/${NAVEGATION.PRICE_BOARD}`;
    let priceFilter:any= JSON.parse(JSON.stringify(initialPriceString));
    priceFilter.productName=[productName];
    this.store.dispatch(setFilterPrice({priceString: priceFilter}));
    this.router.navigate([url]);
  }
}