import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvenioLaboratorioComponent } from './convenio-laboratorio.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';



@NgModule({
  declarations: [
    ConvenioLaboratorioComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    NumberInputModule
  ], exports:[
    ConvenioLaboratorioComponent
  ]
})
export class ConvenioLaboratorioModule { }
