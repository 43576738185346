import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SedeComponent } from './sede.component';
import { SelectInputModule } from '../../inputs/select/select.module';



@NgModule({
  declarations: [
    SedeComponent
  ],
  imports: [
    CommonModule,
    SelectInputModule,
    
  ],
  exports: [
    SedeComponent
  ]
})
export class SedeModule { }
