import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InicioComponent } from './inicio.component';
import { BodyComponent } from './comercial/body.component';
import { GoalsModule } from './goals/goals.module';
import { MarketPricesModule } from './comercial/market-prices/market-prices.module';
import { OrderModule } from './order/order.module';
import { GenerateOrdenModule } from './generate-orden/generate-orden.module';
import { FutureMarketsModule } from './comercial/future-markets/future-markets.module';
import { FeaturedPricesModule } from './comercial/featured-prices/featured-prices.module';
import { CommoditiesComponent } from './commodities/commodities.component';
import { AccumulatedTonnageModule } from './commodities/accumulated-tonnage/accumulated-tonnage.module';
import { DolarModule } from './commodities/dolar/dolar.module';
import { HeaderModule } from '../../components/header/header.module';
import { MenuFooterModule } from '../../components/menu-footer/menu-footer.module';
import { MenuModule } from '../../components/menu/menu.module';
import { EditSvgModule } from 'src/app/shared/styles/svg/edit/edit-svg.module';
import { LoadingLottieModule } from 'src/app/components/loading/loading.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    InicioComponent,
    BodyComponent,
    CommoditiesComponent,
  ],
  imports: [
    CommonModule,
    GoalsModule,
    MarketPricesModule,
    OrderModule,
    GenerateOrdenModule,
    FutureMarketsModule,
    FeaturedPricesModule,
    AccumulatedTonnageModule,
    DolarModule,
    HeaderModule,
    MenuFooterModule,
    MenuModule,
    EditSvgModule,
    LoadingLottieModule,
    ModalModule
  ]
})
export class InicioModule { }