import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyGeneratePriceComponent } from './body-generate-price.component';
import { FormPriceModule } from 'src/app/components/form/form-price/form.module';
import { DetailsPriceModule } from 'src/app/components/details/details-price/details.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';

@NgModule({
  declarations: [
    BodyGeneratePriceComponent
  ],
  imports: [
    CommonModule,
    FormPriceModule,
    DetailsPriceModule,
    ButtonsConditionTypeModule
  ], exports:[
    BodyGeneratePriceComponent
  ]
})
export class BodyGeneratePriceModule { }
