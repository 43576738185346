import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { orderTags, QUOTA_VOID } from 'src/app/shared/const/orders';
import { isInvalidStartDate } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Quotas } from 'src/app/shared/interfaces/order.interface';
import { Field } from '../fields.component';

const ONE_TRUCK_FOR_TONS=28;

@Component({
  selector: 'app-quotas',
  templateUrl: './quotas.component.html',
  styleUrls: ['./../fields.component.scss'],
})
export class QuotasComponent extends Field implements OnInit {
  quotas: Quotas[] = [QUOTA_VOID];
  errorMessage:string='';

  constructor(store: Store<appState>) {
    super(store);
  }

  ngOnInit(): void {
    this.tag = orderTags.quotas;
  }

  override rememberValueInForm(){
    this.rememberQuotas();
  }

  addQuota() {
    if(this.quotas[this.quotas.length-1].date=='--' || this.quotas[this.quotas.length-1].trucks<=0){
      this.errorMessage='Debes completar los datos para agregar.'
    } else if(this.elementToGenerate){
      const quantMaxOfTrucks=this.getMaxOfTrucksForQuota(this.elementToGenerate.tons);
      const trucksUsed=this.getTrucksUsed();
      if(quantMaxOfTrucks-trucksUsed>0){
        this.quotas.push({
          date: '',
          trucks: 0,
        });
        this.errorMessage='';
      } else {
        this.errorMessage='No hay más camiones disponibles.'
      }

    }
  }

  deleteQuota(index: number) {
    this.quotas.splice(index, 1);
    if(this.quotas.length==0){
      this.quotas.push(QUOTA_VOID);
    }
    let quotas:string = JSON.stringify(this.quotas);
    this.setElementToGenerate(quotas);
  }

  getDate(fecha: string) {
    if (fecha && fecha!='--') {
      return fecha;
    } 
    return ''
  }

  setTrucksQuotas(event: any, index: number) {
    let valueToSet=Math.round(Number(event));
    if(valueToSet<=0) { valueToSet=0}
    this.quotas[index] = {
      ...this.quotas[index],
      trucks: valueToSet,
    };
    
    if(this.quotas[index].date==''){
      let today=new Date();
      this.quotas[index].date=(today.getDate())+'-'+(today.getMonth()+1)+'-'+ today.getFullYear();
    }

    let quotas:string = JSON.stringify(this.quotas);
    this.setElementToGenerate(quotas);
  }

  setDateQuotas(event: any, index: number) {
    this.quotas[index] = {
      ...this.quotas[index],
      date: event,
    };

    let quotas:string = JSON.stringify(this.quotas);
    this.setElementToGenerate(quotas);
  }
  
  rememberQuotas(){
    try{
      let valueToSet: any = this.rememberValue();
      if(valueToSet){
        const quota=JSON.parse(valueToSet);
        if(quota.length>0){
          this.quotas=quota;
        } else {
          this.quotas=[QUOTA_VOID];
        }
      }
    } catch (error){
      this.quotas=[QUOTA_VOID];
    }
  }

  getMaxOfTrucks(indexOfQuota:number):string{
    if(this.elementToGenerate){
      let quotasPlus:number=0;
      if(this.quotas[indexOfQuota].trucks>0){
        quotasPlus=this.quotas[indexOfQuota].trucks
      }
      const quantMaxOfTrucks=this.getMaxOfTrucksForQuota(this.elementToGenerate.tons);
      const trucksUsed=this.getTrucksUsed();
      return (quantMaxOfTrucks-trucksUsed + quotasPlus).toString();
    }
    return 'none'
  }

  getMaxOfTrucksForQuota(tons:number):number{
    if(tons<=0) return 0;
    if(tons<=1) return 1;
    return Math.round(tons/ONE_TRUCK_FOR_TONS);
  }

  getTrucksUsed():number{
    let trucksUsed=0;
    this.quotas.forEach(quota=>{
      if(quota.trucks){
        trucksUsed+=quota.trucks;
      }
    })
    return trucksUsed <=0? 0: trucksUsed;
  }

  isInvalidDate(date:string){
    return date!='--' && isInvalidStartDate(date)
  }
}