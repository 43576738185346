import { MaestrosService } from "../services/mestros.service";
import { TablesIBMClass } from "../tables-ibm";

export class WebSocketFunctions extends TablesIBMClass {
    public count: number = 0;
    private socket!: WebSocket;
    private isInside = true;

    constructor(private route: string, maestroSvc:MaestrosService) {
        super(maestroSvc);
        this.conncetion();
    }

    private conncetion(){
        this.connect(this.route);
        this.socket.onopen = (e: any) => this.functionToOpen();
        this.socket.onmessage = (event: any) => this.functionToMessage(event);
        this.socket.onclose = (event: any) => this.functionToClose(event);
        this.socket.onerror = (error: any) => this.functionToError(error);
    }

    private functionToOpen(): void {
        // Lógica para manejar la apertura de la conexión
    }

    protected functionToMessage(event: any): void {
        //Cada componente tiene su implementación distinta
    }

    private functionToClose(event: any): void {
        if (event.wasClean) {
            console.log("Conexión cerrada limpiamente");
        } else {
            console.log("La conexión con el websocket se cayó");
            if (this.isInside) {
                setTimeout(() => {
                    console.log("Intentando reconectar...");
                    this.conncetion();
                }, 5000);
            }
        }
    }

    protected functionToError(error: any): void {
        console.log("[error]:", error);
    }

    private connect(ruta: string): void {
        if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
            this.socket.close();
        }
        
        this.socket = new WebSocket(ruta);
        console.log("Connected to websocket");
    }

    public closeConnection(): void {
        this.isInside = false;
        this.socket?.close();
    }
}