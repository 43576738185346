import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileOrderComponent } from './mobile-order.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';

@NgModule({
  declarations: [
    MobileOrderComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    ArrowSvgModule
  ], exports: [
    MobileOrderComponent
  ]
})
export class TableMobileOrderModule { }
