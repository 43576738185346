import { updateBuyers } from "./dict/buyers";
import { updateCercanj } from "./dict/ibm/cercanj-ibm";
import { updateCommisionIBM } from "./dict/ibm/commision-ibm";
import { IBM_TABLES } from "./dict/ibm/tables";
import { updateCodigoPostal } from "./dict/provinces/codigo-postal";
//import { updateQuality } from "./dict/quality_ibm";
import { updateSellerWithData } from "./dict/seller-with-data";
import { TableIBM } from "./interfaces/DataIBM";
import { MaestrosService } from "./services/mestros.service";

export class TablesIBMClass {
    constructor(private maestroSvc:MaestrosService){}

    checkTables(){
        const tables = Object.values(IBM_TABLES);
        tables.forEach((table)=>{
            this.checkTable(table);
        })
    }

    checkTable(table:string){
        const item=localStorage.getItem(table);
        const tableParse:TableIBM|null = item?JSON.parse(item):null;
        const date=new Date(tableParse?.lastUpdate ?? '').getDate();
        if(date != new Date().getDate()){
            this.saveTable(table);
        } else if(tableParse){
            this.maestroSvc.getCount(table).subscribe((count)=>{
                if(count!=tableParse.length){
                    this.saveTable(table);
                }
            });
        } else {
            this.saveTable(table);
        }
    }

    saveTable(table:string){
        this.maestroSvc.getCollection(table).subscribe((collection)=>{
            console.log('Tabla', table)
            const tableParse:TableIBM = {
                collection: table,
                data: this.getData(table, collection),
                length: this.getLength(table, collection),
                lastUpdate: new Date()
            }
            localStorage.setItem(table, JSON.stringify(tableParse)); 
            this.saveValueInVariable(table, tableParse);
        });
    }

    saveValueInVariable(table:string, tableParse:TableIBM){
        switch(table){
            case IBM_TABLES.CER002: updateSellerWithData(tableParse.data); break;
            case IBM_TABLES.CERoo7: updateBuyers(tableParse.data); break;
            //case IBM_TABLES.CER225: updateCommisionIBM(tableParse.data); break;
            //case IBM_TABLES.CER050TC: updateCommisionIBM2(tableParse.data); break;
            //case IBM_TABLES.CERCANJ: updateCercanj(tableParse.data); break;
            //case IBM_TABLES.CCLOCA: updateCodigoPostal(tableParse.data); break;
            //case IBM_TABLES.CERCALI: updateQuality(tableParse.data); break;
        }
    }

    getData(table:string, object:any){
        //table==IBM_TABLES.CERCALI || 
        if(table==IBM_TABLES.CERoo7){
            return this.getOnlyName(object);
        }/* else if(table==IBM_TABLES.CER225){
            return object?.length ?? [];
        }*/
        return object;
    }

    getLength(table:string, object:any){
        /*if(table==IBM_TABLES.CER225){
            //El object ya es un array
            return object?.length ?? 0;
        }*/
        return Object.keys(object).length;
    }

    getOnlyName(object:any){
        const entries=Object.entries(object);
        let objectToReturn:any={};
        entries.forEach((entrie:any) => {
            objectToReturn[entrie[0]]=entrie[1]?.nombre;
        });
        return objectToReturn;
    }
}