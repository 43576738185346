import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBoardSvgComponent } from './filter-board-svg.component';

@NgModule({
  declarations: [
    FilterBoardSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    FilterBoardSvgComponent
  ]
})
export class FilterBoardSvgModule { }
