import { getClaimFailed, getClaimSuccess } from "../redux/actions/options.action";
import { loadPricesAllSuccess, loadPricesFailed } from "../redux/actions/price.action";
import { localStorageNames } from "./const/localStorageNames";
import { loadAllMarketPricesSuccess, loadAllOrdersSuccess, loadFeaturedPricesFailed, loadFeaturedPricesSuccess, loadFutureMarketsFailed, loadFutureMarketsSuccess, loadMarketPricesFailed, loadOrdersFailed } from "../redux/actions/dashboard.action";
import { loadOrdersAllSuccess } from "../redux/actions/order.action";
import { loadExchangesAllSuccess } from "../redux/actions/exchange.action";
import { loadOrdersAllSuccessInControlPanel, loadOrdersFailedControlPanel } from "../redux/actions/control-panel.action";

export class DataChecker {

    public rememberMarketPrices() {
        const soja = localStorage.getItem(localStorageNames.marketPricesSoja);
        const trigo = localStorage.getItem(localStorageNames.marketPricesTrigo);
        const maiz = localStorage.getItem(localStorageNames.marketPricesMaiz);

        if (soja && trigo && maiz) {  //&& featuredPrices && marketPrices && futureMarkets) {
            const state = {
                marketPrices: {
                    soja: JSON.parse(soja),
                    trigo: JSON.parse(trigo),
                    maiz: JSON.parse(maiz)
                }
            }
            return loadAllMarketPricesSuccess({ marketPrices: state.marketPrices });
        }
        return loadMarketPricesFailed();
    }

    public rememberFeaturedPrices() {
        const featuredPrices = localStorage.getItem(localStorageNames.featuredPrices);
        return featuredPrices ? loadFeaturedPricesSuccess({ featuredPrices: JSON.parse(featuredPrices) }) : loadFeaturedPricesFailed();
    }

    public rememberFutureMarkets() {
        const futureMarkets = localStorage.getItem(localStorageNames.futureMarkets);
        return futureMarkets ? loadFutureMarketsSuccess({ futureMarkets: JSON.parse(futureMarkets) }) : loadFutureMarketsFailed();
    }

    public rememberClaim() {
        const temp = localStorage.getItem(localStorageNames.temp);
        return temp ? getClaimSuccess({ temp: Number(temp) }) : getClaimFailed();
    }

    rememberPrices(): any[]{
        return this.rememberAnyData(localStorageNames.pricesAll);
    }

    rememberPricesAux(): any[]{
        return this.rememberAnyData(localStorageNames.pricesAllAux);
    }


    public rememberPriceBoard() {
        const prices=this.rememberPrices();
        return prices.length>0? loadPricesAllSuccess({ prices: prices }): loadPricesFailed();
    }

    public rememberOrderList() {
        const orders=localStorage.getItem(localStorageNames.ordersAll);
        return orders? loadOrdersAllSuccess({orders:JSON.parse(orders)}) : loadOrdersFailed();
    }

    public rememberOrderExchangeList(){
        const ordersExchange=this.rememberAnyData(localStorageNames.ordersExchange);
        return loadExchangesAllSuccess({orders: ordersExchange});
    }

    public rememberOrdersInControlPanel(){
        const orders=localStorage.getItem(localStorageNames.ordersAll);
        return orders? loadOrdersAllSuccessInControlPanel({orders:JSON.parse(orders)}) : loadOrdersFailedControlPanel();
    }

    private rememberAnyData(storage:string) {
        const data = localStorage.getItem(storage);
        return data? JSON.parse(data): [];
    }
}

export function getViewsByTag(tag:string){
    const data = localStorage.getItem(tag);
    return data? JSON.parse(data) ?? []: [];
}

export function getOfLocalStorage(tag:string){
    const data = localStorage.getItem(tag);
    return data? JSON.parse(data) : null ;
}

export function getTableIBM(table:string, isArray:boolean=false){
    const data = localStorage.getItem(table);
    return data?JSON.parse(data).data: (isArray? []:{});
}