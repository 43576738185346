import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EyeComponent } from './eye.component';

@NgModule({
  declarations: [
   EyeComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    EyeComponent
  ]
})
export class EyeSvgModule { }
