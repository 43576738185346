import { Component, OnDestroy, OnInit,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BodyBoard } from 'src/app/components/board/body-board';
import { setTypeOfHistorical } from 'src/app/redux/actions/historical.action';
import { resetPriceToGenerate, setBuyerTypeFilter, setEditIndicator, setHistorical, showEditIndicators, showPriceDetails} from 'src/app/redux/actions/price.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { conditionType, typeOfHistorical } from 'src/app/shared/const/options';
import { PRICE_STATUS_CONST, priceTags } from 'src/app/shared/const/prices.const';
import { DataChecker } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';
import { sortIndicatorsByFixationPeriod } from 'src/app/shared/to-fix';

@Component({
  selector: 'body-price-board',
  templateUrl: './body-price-board.component.html',
  styleUrls: ['./../../../shared/styles/body-board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class PriceBodyComponent extends BodyBoard implements OnInit, OnDestroy{
  pricesWithPipes!: Price[];
  pricesSelected!: Price[];
  isShowDetails: boolean = false;
  priceDetails!: Price;

  PRICE_STATUS_CONST= PRICE_STATUS_CONST;
  CONDITION_TYPES_CONST = conditionType;

  //-------- TO FIX --------\\
  isShowEditIndicators: boolean = false;
  priceToEdit!:Price;
  indicatorToEdit!:Indicator;
  conditionTypeToEdit!:number;
  indexIndicatorToEdit!:number;

  private pricesWithPipes$!:Subscription;
  private indicatorToEdit$!:Subscription;
  private showEditIndicator$!:Subscription;
  private conditionTypeToEdit$!:Subscription;
  private indexIndicatorToEdit$!:Subscription;
  private priceToEdit$!:Subscription;
  constructor(store: Store<appState>,private router: Router,private dataChecker: DataChecker, private priceV:PriceValidator, cdr:ChangeDetectorRef) {
    super(store, cdr);
    this.rememberPricesOfLocalStorage();
  }

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.setUnsubscriptions();
  }

  setSubscriptions(){
    this.pricesWithPipes$=this.store.select('price', 'priceBoard', 'allWithPipes').subscribe((pricesWithPipes) => {
      this.pricesWithPipes = pricesWithPipes;
      this.cdr.detectChanges();
    });

    this.selected$=this.store.select('price', 'priceBoard', 'pricesSelected').subscribe((prices) => {
      this.pricesSelected = prices;
    });

    this.buyerTypeSelected$=this.store.select('price', 'priceBoard', 'buyerType').subscribe((buyerType) => {
      this.buyerTypeSelected = buyerType;
    });

    this.indicatorToEdit$=this.store.select('price', 'priceBoard', 'indicatorToEdit').subscribe((indicator) => {
      this.indicatorToEdit = indicator;
    });

    this.showEditIndicator$=this.store.select('price', 'priceBoard', 'showEditIndicators').subscribe((show) => {
      this.isShowEditIndicators = show;
    });

    this.conditionTypeToEdit$=this.store.select('price', 'priceBoard', 'conditionTypeToEdit').subscribe((conditionType) => {
      this.conditionTypeToEdit = conditionType;
    });

    this.indexIndicatorToEdit$=this.store.select('price', 'priceBoard', 'indexIndicatorToEdit').subscribe((index) => {
      this.indexIndicatorToEdit = index;
    });

    this.priceToEdit$=this.store.select('price', 'generatePrice').subscribe((price) => {
      this.priceToEdit = price;
    });

    this.historical$=this.store.select('price', 'historical').subscribe((historical) => {
      this.historical = historical;
    });

    this.details$=this.store.select('price', 'priceBoard', 'priceDetails').subscribe((price) => {
      this.priceDetails = price;
    });
    
    this.showDetails$=this.store.select('price', 'priceBoard', 'showPriceDetails').subscribe((show) => {
      this.isShowDetails = show;
    });

    this.setBodySubscription();
  }

  setUnsubscriptions(){
    this.indicatorToEdit$?.unsubscribe();
    this.showEditIndicator$?.unsubscribe();
    this.conditionTypeToEdit$?.unsubscribe();
    this.pricesWithPipes$?.unsubscribe();
    this.indexIndicatorToEdit$?.unsubscribe();
    this.priceToEdit$?.unsubscribe();
    this.setBodyUnsubscription();
  }

  rememberPricesOfLocalStorage() {
    const prices = this.dataChecker.rememberPrices();
    this.store.dispatch(this.dataChecker.rememberPriceBoard());
    localStorage.setItem( localStorageNames.pricesAllAux, JSON.stringify(prices));
  }

  goToCreate(isComercial:boolean){
    //If it is commercial, we will create an order.
    //If it's not commercial, let's create a condition.
    this.router.navigate(["/"+(isComercial?NAVEGATION.GENERATE_MANUAL_ORDER:NAVEGATION.GENERATE_PRICE) ]);
  }

  setBuyerType(identificator: string) {
    this.store.dispatch(setBuyerTypeFilter({ buyerType: identificator }));
  }

  closeCards(){
    this.store.dispatch(showPriceDetails({show: false}));
    this.store.dispatch(setHistorical({historical: ''}));
    this.store.dispatch(showEditIndicators({show: false}));
  }

  goToHistorical(){
    this.store.dispatch(setTypeOfHistorical({typeOfHistorical: typeOfHistorical.PRICE}))
    const url= "/"+NAVEGATION.HISTORICAL;
    this.router.navigate([url]);
  }

  setIndicatorSelected(indicator:Indicator){
    this.store.dispatch(setEditIndicator({indicator: indicator, conditionType: this.conditionTypeToEdit, index: this.indexIndicatorToEdit}));
  }

  saveIndicatorToEdit(event:{save:boolean, indicator:Indicator}){
    if(event.save){
      let copyPrice=JSON.parse(JSON.stringify(this.priceToEdit));
      copyPrice.indicators[this.indexIndicatorToEdit]=event.indicator;
      copyPrice.indicators=sortIndicatorsByFixationPeriod(copyPrice.indicators);

      this.priceV.updatePrice(copyPrice, this.user);


      this.store.dispatch(resetPriceToGenerate());
      this.store.dispatch(showEditIndicators({show: false}));
    }
  }

  setEditPrices(newStatus:number){
    //Recorremos el array de condiciones seleccionadas.
    for (let i = 0; i < this.pricesSelected.length; i++) {
      this.priceV.editPrice(this.pricesSelected[i], priceTags.status, newStatus, this.user, true);
    }
    this.priceV.resetSelectedPriceList();
  } 
}