import { Component, OnChanges, OnInit } from '@angular/core';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-convenio-laboratorio',
  templateUrl: './convenio-laboratorio.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class ConvenioLaboratorioComponent extends BusinessParticularityOption implements OnChanges {
  laboratoryAgreement:number=0;

  constructor(private validator:ValidatorChecker) {
    super()
  }

  ngOnChanges(): void {
    try{
      this.laboratoryAgreement=this.businessParticularitiesStruct.laboratoryAgreement;
    } catch(err){
      this.laboratoryAgreement=0;
    }
  }

  setLaboratoryAgreement(laboratoryAgreement:number){
    this.laboratoryAgreement=laboratoryAgreement;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.laboratoryAgreement=this.laboratoryAgreement;
    this.emit2(newBP);
  }

  setPercentage(event:any){
    this.setLaboratoryAgreement(this.validator.validatorPercentage(event))
  }

}
