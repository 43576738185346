<section class="body">
    <div class="buttons row-between not-visible-in-950"> 
        <p class="description">Para generar la orden de venta desde el tablero completá los campos requeridos.</p>
        <div class="buttons-options row-between">
            <div class="button-option commission" [ngClass]="{'no-enabled': comissionEnabled}" (click)="openCommission()">
                <!--[ngClass]="{'enabled': comissionEnabled}"-->
                <!--<commission-svg [active]="comissionEnabled"></commission-svg>--> 
                <commission-svg [active]="false"></commission-svg>
                <span class="option-text">Cambiar comisión</span>
                    <div class="tooltip commission-message">
                        <span>Presiona para modificar la comisión</span>
                    </div>
            </div>
            
            <!--<app-commission (close)="closeCommission($event)" (commission)="setCommission($event)" 
            [valueSeller]="orderToGenerate.commision.seller" [valueBuyer]="orderToGenerate.commision.buyer"
            *ngIf="isOpenCommission" class="commission pop-up pop-up-visible"></app-commission>-->

            <div class="button-option duplicate-order" [ngClass]="{'enabled': duplicateOrderEnabled}" (click)="openDuplicateOrder()">
                <duplicate-order-svg [active]="duplicateOrderEnabled"></duplicate-order-svg> <span class="option-text">Duplicar orden</span>
                <div class="tooltip duplicate-message">
                    <span>Presiona para duplicar ordenes</span>
                </div>
            </div>

            <app-duplicate-orders (close)="closeDuplicateOrder($event)" *ngIf="isOpenDuplicate"
            class="duplicate pop-up pop-up-visible"></app-duplicate-orders>
        </div>
    </div>

    <div class="show-details-container width-100 row-between" *ngIf="formVisible">
        <span></span>
        <button class="view-details not-visible-in-desktop" (click)="showDetails(true)">Ver detalle</button>
    </div>

    <div class="inform" style="margin-top:8px">
        <div class="form-void" *ngIf="quantOrdersToGenerate>1 && formVisible"></div>
        <app-form-order-from-board  *ngIf="formVisible" class="form"
        (applyButtonEvent)="showDetails($event)"></app-form-order-from-board>

        <app-details class="details" *ngIf="detailsVisible"></app-details>

        <div class="complete-data-container not-visible-in-desktop">
            <div class="background-complete-data"></div>

            <button *ngIf="!formVisible && count==0" class="complete-data not-filled-button"
            (click)="showForm(true)">
                Completar datos
            </button>
        </div>
        
        <button *ngIf="detailsVisible && count>0" [disabled]="disabledButton" 
            style="z-index:50"
            [ngClass]="{'disabled': disabledButton}" class="filled-button not-visible-in-desktop"
        (click)="confirmOrder()">
            Confirmar
        </button>
        <img *ngIf="detailsVisible" (click)="showDetails(false)" class="button-edit" alt="button-edit" src="../../../../../assets/images/extra/button-edit.svg"/>
    </div>
</section> 