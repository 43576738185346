<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(9)">Entrega en plantas Grassi</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="isEnabled()" (change)="setOptionOpenWithCheck($event,9)" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">

        <div class="option-content-particularity">
            <span class="option-name">Paritaria</span>
            <div class="actions">
                <input type="radio" (click)="setIsParity()" [checked]="isParity" class="radio" />
            </div>
        </div>

        <app-amount *ngIf="isParity" (amountToSave)="setParity($event)"></app-amount>


        <!------ Merma Volatil ------>
        <div class="option-content-particularity">
            <span class="option-name">Merma volatil</span>
            <div class="actions">
                <input type="radio" (click)="setIsVolatileWaste()"  [checked]="isVolatileWaste" class="radio" />
            </div>
        </div>

        <div class="field" *ngIf="isVolatileWaste">
            <span class="field-title">Porcentaje<span class="asterisk">*</span></span>

            <div>
                <number-input [initialPlaceHolder]="'Ingresar monto'"
                [value]="deliveriesAtGrassiPlant.volatileWaste.percentage" (valueOut)="setPercentage($event, 'volatileWaste')"></number-input>
            </div>
        </div>

        <div class="field" *ngIf="isVolatileWaste">
            <span class="field-title">Descripción del producto<span class="asterisk">*</span></span>

            <div>
                <text-input [initialPlaceHolder]="'Ingresar descripción'"
                [value]="deliveriesAtGrassiPlant.volatileWaste.productDescription" 
                (valueOut)="setDescription($event, 'volatileWaste', 'productDescription')"></text-input>
            </div>
        </div>
        <!-------------------------------------->

        <!------------- Comisión --------------->
        <div class="option-content-particularity">
            <span class="option-name">Comisión<span class="asterisk">*</span></span>
            <div class="actions">
                <input type="radio" (click)="setIsCommission()" [checked]="isCommission" class="radio" />
            </div>
        </div>

        <div class="option-content-particularity" *ngIf="isCommission">
            <span class="option-name">Acopio</span>
            <div class="actions">
                <input type="radio" [value]="deliveriesAtGrassiPlant.commission.isHoard==1" (click)="setIsHoard(true)" class="radio" />
            </div>
        </div>

        <div class="option-content-particularity" *ngIf="isCommission">
            <span class="option-name">Corredor</span>
            <div class="actions">
                <input type="radio" [value]="deliveriesAtGrassiPlant.commission.isHoard==-1" (click)="setIsHoard(false)" class="radio" />
            </div>
        </div>
        <div class="field" *ngIf="isCommission">
            <span class="field-title">Porcentaje de la comisión</span>
            <div>
                <number-input [initialPlaceHolder]="'Ingresar porcentaje'"
                (valueOut)="setPercentage($event, 'commission')"
                [value]="deliveriesAtGrassiPlant.commission.percentage"></number-input>
            </div>
        </div>
        <!----------------------------------->

        <!-------------- Flete -------------->
        <div class="option-content-particularity">
            <span class="option-name">Flete<span class="asterisk">*</span></span>
            <div class="actions">
                <input type="radio" (click)="setIsFreight()"  [checked]="isFreight" class="radio" />
            </div>
        </div>

        <app-amount *ngIf="isFreight" (amountToSave)="setAmount($event, 'freight')"></app-amount>
        
        <div class="field" *ngIf="isFreight">
            <span class="field-title">Descripción<span class="asterisk">*</span></span>

            <div>
                <text-input [initialPlaceHolder]="'Ingresar descripción'"
                (valueOut)="setDescription($event, 'freight', 'description')"
                [value]="deliveriesAtGrassiPlant.freight.description"></text-input>
            </div>
        </div>
        <!----------------------------------->

        <!------------ Humedad -------------->
        <div class="option-content-particularity">
            <span class="option-name">Humedad<span class="asterisk">*</span></span>
            <div class="actions">
                <input type="radio" (click)="setIsHumidity()"   class="radio" />
            </div>
        </div>

        <div class="option-content-particularity">
            <span class="option-name">Tarifa de acopio</span>
            <div class="actions">
                <input type="radio" [checked]="deliveriesAtGrassiPlant.humidity.isPortFee==-1" (click)="setIsPortFee(false)" class="radio" />
            </div>
        </div>

        <app-amount *ngIf="deliveriesAtGrassiPlant.humidity.isPortFee==-1" (amountToSave)="setAmount($event, 'humidity')"></app-amount>
        
        <div class="field" *ngIf="deliveriesAtGrassiPlant.humidity.isPortFee==-1">
            <span class="field-title">Porcentaje por tonelada</span>
            <div>
                <number-input [initialPlaceHolder]="'Porcentaje por toneladas'"
                [value]="deliveriesAtGrassiPlant.humidity.percentage" (valueOut)="setPercentage($event, 'humidity')"></number-input>
            </div>
        </div>

        <div class="option-content-particularity">
            <span class="option-name">Tarifa de puerto</span>
            <div class="actions">
                <input type="radio" [checked]="deliveriesAtGrassiPlant.humidity.isPortFee==1" (click)="setIsPortFee(true)" class="radio" />
            </div>
        </div>

        <app-amount *ngIf="deliveriesAtGrassiPlant.humidity.isPortFee==1" (amountToSave)="setAmount($event, 'humidity')"></app-amount>
        
        <div class="field" *ngIf="deliveriesAtGrassiPlant.humidity.isPortFee==1">
            <span class="field-title">Porcentaje por tonelada</span>
            <div>
                <number-input [initialPlaceHolder]="'Porcentaje por tonelada'" 
                [value]="deliveriesAtGrassiPlant.humidity.percentage" (valueOut)="setPercentage($event, 'humidity')"></number-input>
            </div>
        </div>
    
        <span *ngIf="deliveriesAtGrassiPlant.humidity.isPortFee">Rebaja</span>
        <div class="field" style="display:flex; justify-content:space-between; flex-direction: row;"
        *ngIf="deliveriesAtGrassiPlant.humidity.isPortFee">
            <div style="width:48%">
                <number-input [initialPlaceHolder]="'Punto'" [value]="deliveriesAtGrassiPlant.humidity.reductionPoint"
                (valueOut)="setReduction($event, 'reductionPoint')"></number-input>
            </div>

            <div style="width:48%">
                <number-input [initialPlaceHolder]="'Monto'" [value]="deliveriesAtGrassiPlant.humidity.reductionAmount" 
                (valueOut)="setReduction($event, 'reductionAmount')"></number-input>
            </div>
        </div>
    </div>
</div>