import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowComponent } from './row.component';
import { EyeSvgModule } from 'src/app/shared/styles/svg/eye/eye.module';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { ObservationsOrderSvgModule } from 'src/app/shared/styles/svg/observations-order/observations-order.module';
import { IndicatorsTableModule } from '../../indicators/indicators.module';
import { OptionsMenuModule } from '../../options/options.module';
import { ActionsControlPanelModule } from 'src/app/shared/styles/svg/actions-control-panel/actions-control-panel.module';
import { ArrowBoardSvgModule } from 'src/app/shared/styles/svg/arrow-board/arrow-price-board.module';
import { DataBussinesParticularitiesModule } from 'src/app/components/details/data-buss-part/data-buss-part.module';

@NgModule({
  declarations: [
    RowComponent
  ],
  imports: [
    CommonModule,

    //--------- SVGS ---------\\
    EyeSvgModule,
    ThreePointsSvgModule,
    ArrowSvgModule,
    ArrowBoardSvgModule,
    FilterBoardSvgModule,
    ObservationsOrderSvgModule,
    ActionsControlPanelModule,

    OptionsMenuModule,
    IndicatorsTableModule,
    DataBussinesParticularitiesModule
    
  ], exports: [
    RowComponent
  ]
})
export class RowOrderBoardModule { }
