export const entreRiosLocalities=[
    {"afip":"12","location":"20 De Setiembre"},
    {"afip":"105","location":"Aero Club Canal"},
    {"afip":"217","location":"Aguila Rio"},
    {"afip":"241","location":"Alarcon"},
    {"afip":"254","location":"Alberto Gerchunoff"},
    {"afip":"256","location":"Alcaracito"},
    {"afip":"257","location":"Alcaraz  2Do"},
    {"afip":"258","location":"Alcaraz 1Ro"},
    {"afip":"259","location":"Alcaraz Norte"},
    {"afip":"260","location":"Alcaraz Pueblo Arrua"},
    {"afip":"261","location":"Alcaraz Sud"},
    {"afip":"264","location":"Alcete"},
    {"afip":"268","location":"Aldea Asuncion"},
    {"afip":"270","location":"Aldea Brasilera"},
    {"afip":"271","location":"Aldea Chaleco"},
    {"afip":"272","location":"Aldea Cuesta"},
    {"afip":"273","location":"Aldea Eigenfeld"},
    {"afip":"276","location":"Aldea Maria Luisa"},
    {"afip":"277","location":"Aldea Merou"},
    {"afip":"278","location":"Aldea Protestante"},
    {"afip":"280","location":"Aldea Salto"},
    {"afip":"282","location":"Aldea San Antonio"},
    {"afip":"283","location":"Aldea San Francisco"},
    {"afip":"284","location":"Aldea San Gregorio"},
    {"afip":"285","location":"Aldea San Jorge"},
    {"afip":"286","location":"Aldea San Jose"},
    {"afip":"287","location":"Aldea San Juan"},
    {"afip":"288","location":"Aldea San Miguel"},
    {"afip":"289","location":"Aldea San Rafael"},
    {"afip":"290","location":"Aldea Santa Celia"},
    {"afip":"291","location":"Aldea Santa Maria"},
    {"afip":"292","location":"Aldea Santa Rosa"},
    {"afip":"293","location":"Aldea Santafecina"},
    {"afip":"294","location":"Aldea Spatzenkutter"},
    {"afip":"295","location":"Aldea Valle Maria"},
    {"afip":"331","location":"Algarrobito 1Ro"},
    {"afip":"354","location":"Almacen Cristian Schubert"},
    {"afip":"362","location":"Almirante Iglesias"},
    {"afip":"383","location":"Altamirano Norte"},
    {"afip":"384","location":"Altamirano Sud"},
    {"afip":"528","location":"Anahi"},
    {"afip":"599","location":"Antelo"},
    {"afip":"616","location":"Antonio Tomas"},
    {"afip":"617","location":"Antonio Tomas Sud"},
    {"afip":"642","location":"Aranguren"},
    {"afip":"719","location":"Arroyo Baltazar"},
    {"afip":"720","location":"Arroyo Baltazar Chico"},
    {"afip":"722","location":"Arroyo Baru"},
    {"afip":"723","location":"Arroyo Bicho Feo"},
    {"afip":"725","location":"Arroyo Boca Falsa"},
    {"afip":"727","location":"Arroyo Brasilero"},
    {"afip":"728","location":"Arroyo Bravo Gutierrez"},
    {"afip":"729","location":"Arroyo Brazo Chico"},
    {"afip":"730","location":"Arroyo Brazo De La Tinta"},
    {"afip":"731","location":"Arroyo Buen Pastor"},
    {"afip":"733","location":"Arroyo Caballo"},
    {"afip":"737","location":"Arroyo Caraballo"},
    {"afip":"740","location":"Arroyo Carbon"},
    {"afip":"741","location":"Arroyo Carboncito"},
    {"afip":"742","location":"Arroyo Carpincho"},
    {"afip":"746","location":"Arroyo Ceibito"},
    {"afip":"747","location":"Arroyo Ceibo"},
    {"afip":"752","location":"Arroyo Cle Desvio"},
    {"afip":"754","location":"Arroyo Concepcion"},
    {"afip":"755","location":"Arroyo Correntoso"},
    {"afip":"757","location":"Arroyo Cucharas"},
    {"afip":"758","location":"Arroyo Cuzco"},
    {"afip":"761","location":"Arroyo De La Rosa"},
    {"afip":"764","location":"Arroyo Del Cura"},
    {"afip":"765","location":"Arroyo Del Medio"},
    {"afip":"769","location":"Arroyo Desaguadero Del Gutierr"},
    {"afip":"770","location":"Arroyo Desaguadero Del Sauce"},
    {"afip":"774","location":"Arroyo El Mocho"},
    {"afip":"777","location":"Arroyo Garcete"},
    {"afip":"782","location":"Arroyo Gutierrez Chico"},
    {"afip":"783","location":"Arroyo Hondo"},
    {"afip":"785","location":"Arroyo Ibicuycito"},
    {"afip":"787","location":"Arroyo Jacinto"},
    {"afip":"791","location":"Arroyo La Paciencia"},
    {"afip":"792","location":"Arroyo La Tinta"},
    {"afip":"794","location":"Arroyo La Virgen"},
    {"afip":"795","location":"Arroyo Lara"},
    {"afip":"796","location":"Arroyo Las Animas"},
    {"afip":"800","location":"Arroyo Las Tunas"},
    {"afip":"802","location":"Arroyo Llorones"},
    {"afip":"806","location":"Arroyo Los Platos"},
    {"afip":"811","location":"Arroyo Malambo"},
    {"afip":"813","location":"Arroyo Manzano"},
    {"afip":"814","location":"Arroyo Maria"},
    {"afip":"815","location":"Arroyo Martinez"},
    {"afip":"817","location":"Arroyo Merlo"},
    {"afip":"818","location":"Arroyo Molino"},
    {"afip":"819","location":"Arroyo Moreira"},
    {"afip":"820","location":"Arroyo Mosquito"},
    {"afip":"822","location":"Arroyo Obispo"},
    {"afip":"823","location":"Arroyo Paciencia Chico"},
    {"afip":"824","location":"Arroyo Paciencia Grande"},
    {"afip":"826","location":"Arroyo Palmar"},
    {"afip":"827","location":"Arroyo Pancho"},
    {"afip":"830","location":"Arroyo Pelado"},
    {"afip":"833","location":"Arroyo Perdido Ceibo"},
    {"afip":"834","location":"Arroyo Perdido Mosquito"},
    {"afip":"835","location":"Arroyo Pereyra"},
    {"afip":"839","location":"Arroyo Piedras"},
    {"afip":"840","location":"Arroyo Pito"},
    {"afip":"841","location":"Arroyo Platitos"},
    {"afip":"843","location":"Arroyo Principal"},
    {"afip":"848","location":"Arroyo Sagastume Chico"},
    {"afip":"849","location":"Arroyo Sagastume Grande"},
    {"afip":"850","location":"Arroyo Salado"},
    {"afip":"853","location":"Arroyo Sanchez Chico"},
    {"afip":"854","location":"Arroyo Sanchez Grande"},
    {"afip":"858","location":"Arroyo Santos Chico"},
    {"afip":"859","location":"Arroyo Santos Grande"},
    {"afip":"869","location":"Arroyo Tiroles"},
    {"afip":"874","location":"Arroyo Urquiza"},
    {"afip":"877","location":"Arroyo Venerato"},
    {"afip":"881","location":"Arroyo Zapallo"},
    {"afip":"884","location":"Arroyo Ñancay"}
    ,
    {"afip":"916","location":"Atencio"},
    {"afip":"939","location":"Avenida Ejercito Parana"},
    {"afip":"950","location":"Ayui Parada"},
    {"afip":"1012","location":"Bajada Grande"},
    {"afip":"1143","location":"Balneario Pelay"},
    {"afip":"1166","location":"Banderas"},
    {"afip":"1185","location":"Baron Hirsch"},
    {"afip":"1195","location":"Barrancas Coloradas"},
    {"afip":"1344","location":"Basavilbaso"},
    {"afip":"1382","location":"Bañadero Oficial Burgos"}
    ,
    {"afip":"1383","location":"Bañadero Oficial Las Galarzas"}
    ,
    {"afip":"1426","location":"Belez"},
    {"afip":"1434","location":"Bella Union Paraje"},
    {"afip":"1446","location":"Benito Legeren"},
    {"afip":"1454","location":"Berduc"},
    {"afip":"1456","location":"Berisso Desvio Fcgu"},
    {"afip":"1476","location":"Betbeder"},
    {"afip":"1485","location":"Bizcocho"},
    {"afip":"1509","location":"Boca Del Tigre"},
    {"afip":"1510","location":"Boca Del Tigre Apeadero Fcgu"},
    {"afip":"1511","location":"Boca Gualeguay"},
    {"afip":"1531","location":"Bonaldi"},
    {"afip":"1558","location":"Bovril Dto Nro 12"},
    {"afip":"1567","location":"Brazo Largo"},
    {"afip":"1580","location":"Britos"},
    {"afip":"1597","location":"Buena Vista Paraje"},
    {"afip":"1648","location":"Cabi Monda"},
    {"afip":"1724","location":"Calabacillas"},
    {"afip":"1728","location":"Calandria"},
    {"afip":"1784","location":"Camba Paso"},
    {"afip":"1802","location":"Camino A Diamante Km 1"},
    {"afip":"1891","location":"Campo De Villamil"},
    {"afip":"1899","location":"Campo Dominguez"},
    {"afip":"1915","location":"Campo Escales"},
    {"afip":"1998","location":"Campo Riquelme"},
    {"afip":"2034","location":"Camps"},
    {"afip":"2040","location":"Canal Nuevo"},
    {"afip":"2042","location":"Canal Principal"},
    {"afip":"2043","location":"Canal San Martin"},
    {"afip":"2075","location":"Cantera La Constancia"},
    {"afip":"2154","location":"Caraguata"},
    {"afip":"2210","location":"Carpinchoris"},
    {"afip":"2324","location":"Catalotti"},
    {"afip":"2387","location":"Cañada De Las Ovejas"}
    ,
    {"afip":"2482","location":"Ceibal"},
    {"afip":"2484","location":"Ceibas"},
    {"afip":"2492","location":"Centella"},
    {"afip":"2494","location":"Centenario La Paz"},
    {"afip":"2495","location":"Centenario Parana"},
    {"afip":"2503","location":"Centro Comunitario Cnia Nue"},
    {"afip":"2818","location":"Chajari"},
    {"afip":"2875","location":"Chaviyu Colonia Flores"},
    {"afip":"2876","location":"Chaviyu Parada Fcgu"},
    {"afip":"2878","location":"Chañar"}
    ,
    {"afip":"2883","location":"Chañar Feliciano"}
    ,
    {"afip":"2886","location":"Chañar Maria Grande Primera"}
    ,
    {"afip":"2942","location":"Chilcas Sud"},
    {"afip":"2970","location":"Chiquero"},
    {"afip":"2974","location":"Chircalito"},
    {"afip":"3066","location":"Clara"},
    {"afip":"3078","location":"Clodomiro Ledesma"},
    {"afip":"3089","location":"Cnia Justo Jose De Urquiza"},
    {"afip":"3126","location":"Colegio Adventista Del Plata"},
    {"afip":"3157","location":"Colonia 1 De Mayo"},
    {"afip":"3165","location":"Colonia 5 Ensanche De Mayo"},
    {"afip":"3174","location":"Colonia Achiras"},
    {"afip":"3176","location":"Colonia Adela"},
    {"afip":"3183","location":"Colonia Alcarcito"},
    {"afip":"3189","location":"Colonia Algarrabitos"},
    {"afip":"3202","location":"Colonia Ambis"},
    {"afip":"3206","location":"Colonia Angela"},
    {"afip":"3217","location":"Colonia Arroyo Urquiza"},
    {"afip":"3222","location":"Colonia Avigdor"},
    {"afip":"3223","location":"Colonia Ayui Grande"},
    {"afip":"3225","location":"Colonia Bailina"},
    {"afip":"3243","location":"Colonia Belez"},
    {"afip":"3244","location":"Colonia Belga Americana"},
    {"afip":"3245","location":"Colonia Belgrano"},
    {"afip":"3253","location":"Colonia Berro"},
    {"afip":"3257","location":"Colonia Bizcocho"},
    {"afip":"3270","location":"Colonia Buena Vista"},
    {"afip":"3281","location":"Colonia Campos"},
    {"afip":"3286","location":"Colonia Caraballo"},
    {"afip":"3287","location":"Colonia Carlos Calvo"},
    {"afip":"3289","location":"Colonia Carmel"},
    {"afip":"3290","location":"Colonia Carmelo"},
    {"afip":"3293","location":"Colonia Carrasco"},
    {"afip":"3301","location":"Colonia Celina"},
    {"afip":"3303","location":"Colonia Centenario"},
    {"afip":"3320","location":"Colonia Crespo"},
    {"afip":"3322","location":"Colonia Crucesitas"},
    {"afip":"3327","location":"Colonia Cupalen"},
    {"afip":"3328","location":"Colonia Curbelo"},
    {"afip":"3345","location":"Colonia Delta"},
    {"afip":"3352","location":"Colonia Don Bosco"},
    {"afip":"3360","location":"Colonia Duportal"},
    {"afip":"3366","location":"Colonia Egido"},
    {"afip":"3373","location":"Colonia El Carmen"},
    {"afip":"3396","location":"Colonia El Potrero"},
    {"afip":"3412","location":"Colonia Elia"},
    {"afip":"3415","location":"Colonia Emilio Gouchon"},
    {"afip":"3416","location":"Colonia Ensanche Mayo"},
    {"afip":"3417","location":"Colonia Ensanche Sauce"},
    {"afip":"3418","location":"Colonia Ensayo"},
    {"afip":"3426","location":"Colonia Espindola"},
    {"afip":"3429","location":"Colonia F Sillen"},
    {"afip":"3430","location":"Colonia Falco"},
    {"afip":"3433","location":"Colonia Feimberg"},
    {"afip":"3443","location":"Colonia Flores"},
    {"afip":"3445","location":"Colonia Fontanini"},
    {"afip":"3449","location":"Colonia Frazer"},
    {"afip":"3450","location":"Colonia Freitas"},
    {"afip":"3455","location":"Colonia Gdor Basavilbaso"},
    {"afip":"3460","location":"Colonia General Roca"},
    {"afip":"3469","location":"Colonia Gral Urquiza"},
    {"afip":"3471","location":"Colonia Grapschental"},
    {"afip":"3473","location":"Colonia Gualeguaycito"},
    {"afip":"3477","location":"Colonia Guiburg"},
    {"afip":"3484","location":"Colonia Hebrea"},
    {"afip":"3486","location":"Colonia Hernandarias"},
    {"afip":"3488","location":"Colonia Higuera"},
    {"afip":"3492","location":"Colonia Hocker"},
    {"afip":"3495","location":"Colonia Hughes"},
    {"afip":"3498","location":"Colonia Ida"},
    {"afip":"3512","location":"Colonia Jorge Fink"},
    {"afip":"3534","location":"Colonia La Armonia"},
    {"afip":"3538","location":"Colonia La Blanquita"},
    {"afip":"3551","location":"Colonia La Delia"},
    {"afip":"3560","location":"Colonia La Gama"},
    {"afip":"3562","location":"Colonia La Gloria"},
    {"afip":"3568","location":"Colonia La Llave"},
    {"afip":"3575","location":"Colonia La Matilde"},
    {"afip":"3576","location":"Colonia La Matilde Santa Ana"},
    {"afip":"3578","location":"Colonia La Mora"},
    {"afip":"3579","location":"Colonia La Morenita"},
    {"afip":"3592","location":"Colonia La Pampa"},
    {"afip":"3594","location":"Colonia La Paz"},
    {"afip":"3600","location":"Colonia La Quinta"},
    {"afip":"3603","location":"Colonia La Rosada"},
    {"afip":"3618","location":"Colonia Lamarca"},
    {"afip":"3623","location":"Colonia Las Gamas"},
    {"afip":"3625","location":"Colonia Las Pepas"},
    {"afip":"3642","location":"Colonia Loma Negra"},
    {"afip":"3643","location":"Colonia Lopez"},
    {"afip":"3656","location":"Colonia Los Sauces"},
    {"afip":"3664","location":"Colonia Luca"},
    {"afip":"3666","location":"Colonia Lucienville"},
    {"afip":"3668","location":"Colonia Lucrecia"},
    {"afip":"3673","location":"Colonia Mabragaña"}
    ,
    {"afip":"3684","location":"Colonia Maria Luisa"},
    {"afip":"3697","location":"Colonia Maximo Castro"},
    {"afip":"3705","location":"Colonia Miguel"},
    {"afip":"3722","location":"Colonia N 1"},
    {"afip":"3723","location":"Colonia N 2"},
    {"afip":"3724","location":"Colonia N 3"},
    {"afip":"3725","location":"Colonia N 4"},
    {"afip":"3729","location":"Colonia Navarro"},
    {"afip":"3735","location":"Colonia Nueva"},
    {"afip":"3736","location":"Colonia Nueva Al Sud"},
    {"afip":"3737","location":"Colonia Nueva Alemania"},
    {"afip":"3739","location":"Colonia Nueva Montevideo"},
    {"afip":"3741","location":"Colonia Nueva San Miguel"},
    {"afip":"3746","location":"Colonia Oficial N 1 La Flori"},
    {"afip":"3747","location":"Colonia Oficial N 11"},
    {"afip":"3748","location":"Colonia Oficial N 13"},
    {"afip":"3749","location":"Colonia Oficial N 14"},
    {"afip":"3750","location":"Colonia Oficial N 3"},
    {"afip":"3751","location":"Colonia Oficial N 4"},
    {"afip":"3752","location":"Colonia Oficial N 6"},
    {"afip":"3753","location":"Colonia Oficial Nro 5"},
    {"afip":"3760","location":"Colonia Ougrie"},
    {"afip":"3765","location":"Colonia Palmar"},
    {"afip":"3780","location":"Colonia Pereira"},
    {"afip":"3781","location":"Colonia Perfeccion"},
    {"afip":"3783","location":"Colonia Perliza"},
    {"afip":"3807","location":"Colonia Reffino"},
    {"afip":"3814","location":"Colonia Rivadavia"},
    {"afip":"3815","location":"Colonia Rivas"},
    {"afip":"3826","location":"Colonia Saenz Valiente"},
    {"afip":"3827","location":"Colonia Sagastume"},
    {"afip":"3835","location":"Colonia San Bonifacio"},
    {"afip":"3836","location":"Colonia San Cipriano"},
    {"afip":"3839","location":"Colonia San Ernesto"},
    {"afip":"3844","location":"Colonia San Ignacio"},
    {"afip":"3848","location":"Colonia San Jorge"},
    {"afip":"3850","location":"Colonia San Juan"},
    {"afip":"3853","location":"Colonia San Manuel"},
    {"afip":"3864","location":"Colonia Sandoval"},
    {"afip":"3871","location":"Colonia Santa Eloisa"},
    {"afip":"3872","location":"Colonia Santa Elvira"},
    {"afip":"3873","location":"Colonia Santa Juana"},
    {"afip":"3875","location":"Colonia Santa Luisa"},
    {"afip":"3887","location":"Colonia Santa Teresita"},
    {"afip":"3906","location":"Colonia Sonenfeld"},
    {"afip":"3907","location":"Colonia Stauwer"},
    {"afip":"3930","location":"Colonia Tres De Febrero"},
    {"afip":"3934","location":"Colonia Ubajay"},
    {"afip":"3944","location":"Colonia Vazquez"},
    {"afip":"3954","location":"Colonia Villa Libertad"},
    {"afip":"3956","location":"Colonia Villaguaycito"},
    {"afip":"3957","location":"Colonia Viraro"},
    {"afip":"3963","location":"Colonia Yerua"},
    {"afip":"4009","location":"Concepcion Del Uruguay"},
    {"afip":"4013","location":"Concordia"},
    {"afip":"4026","location":"Conscripto Bernardi"},
    {"afip":"4042","location":"Cooperativa Brazo Largo"},
    {"afip":"4043","location":"Cooperativa Gral San Martin"},
    {"afip":"4148","location":"Corrales Nuevos"},
    {"afip":"4153","location":"Correa"},
    {"afip":"4189","location":"Costa Del Nogoya"},
    {"afip":"4190","location":"Costa Del Payticu"},
    {"afip":"4198","location":"Costa Del Uruguay"},
    {"afip":"4200","location":"Costa Grande Doll"},
    {"afip":"4217","location":"Costas De San Antonio"},
    {"afip":"4234","location":"Crespo"},
    {"afip":"4235","location":"Crespo Norte"},
    {"afip":"4249","location":"Crucecitas 3A Seccion"},
    {"afip":"4250","location":"Crucecitas 7A Seccion"},
    {"afip":"4251","location":"Crucecitas 8A Seccion"},
    {"afip":"4253","location":"Crucecitas Urquiza"},
    {"afip":"4289","location":"Cuarto Distrito"},
    {"afip":"4293","location":"Cuatro Bocas Paraje"},
    {"afip":"4300","location":"Cuatro Manos"},
    {"afip":"4307","location":"Cuchilla"},
    {"afip":"4309","location":"Cuchilla Redonda"},
    {"afip":"4332","location":"Cueva Del Tigre"},
    {"afip":"4342","location":"Cupalen"},
    {"afip":"4359","location":"Curupi"},
    {"afip":"4361","location":"Curuzu Chali"},
    {"afip":"4410","location":"Desparramados"},
    {"afip":"4415","location":"Destacamento General Guemes"},
    {"afip":"4432","location":"Diamante"},
    {"afip":"4471","location":"Distrito El Sauce"},
    {"afip":"4474","location":"Distrito Tala"},
    {"afip":"4490","location":"Doctor Eugenio Muñoz"}
    ,
    {"afip":"4493","location":"Doctor Garcia"},
    {"afip":"4520","location":"Don Cristobal"},
    {"afip":"4521","location":"Don Cristobal 1Ra Seccion"},
    {"afip":"4522","location":"Don Cristobal 2Da Seccion"},
    {"afip":"4523","location":"Don Gonzalo"},
    {"afip":"4529","location":"Don Roberto"},
    {"afip":"4592","location":"Eben Horoscha"},
    {"afip":"4595","location":"Echague"},
    {"afip":"4601","location":"Ejido Colon"},
    {"afip":"4602","location":"Ejido Diamante"},
    {"afip":"4603","location":"Ejido Sud"},
    {"afip":"4671","location":"El Avestruz"},
    {"afip":"4724","location":"El Brillante"},
    {"afip":"4859","location":"El Cimarron"},
    {"afip":"4872","location":"El Colorado"},
    {"afip":"4880","location":"El Corcovado"},
    {"afip":"4927","location":"El Diecisiete"},
    {"afip":"4936","location":"El Duraznal"},
    {"afip":"4941","location":"El Embalsado"},
    {"afip":"4943","location":"El Empalme Paraje"},
    {"afip":"4984","location":"El Gaucho"},
    {"afip":"4990","location":"El Gramillal"},
    {"afip":"4997","location":"El Gualeguay"},
    {"afip":"5074","location":"El Martillo"},
    {"afip":"5132","location":"El Nuevo Rincon"},
    {"afip":"5156","location":"El Pago Apeadero Fcgu"},
    {"afip":"5165","location":"El Palenque"},
    {"afip":"5306","location":"El Ramblon"},
    {"afip":"5317","location":"El Redomon"},
    {"afip":"5338","location":"El Romance"},
    {"afip":"5356","location":"El Sarandi"},
    {"afip":"5379","location":"El Solar"},
    {"afip":"5402","location":"El Taller"},
    {"afip":"5542","location":"Embarcadero Ferrari"},
    {"afip":"5561","location":"Empalme Holt"},
    {"afip":"5564","location":"Empalme Neild"},
    {"afip":"5580","location":"Enrique Berduc"},
    {"afip":"5581","location":"Enrique Carbo"},
    {"afip":"5613","location":"Escriña"}
    ,
    {"afip":"5657","location":"Establecimiento El Carmen"},
    {"afip":"5659","location":"Establecimiento El Cimarron"},
    {"afip":"5660","location":"Establecimiento El Tala"},
    {"afip":"5662","location":"Establecimiento La Calera"},
    {"afip":"5663","location":"Establecimiento La Esperanza"},
    {"afip":"5667","location":"Establecimiento Las Margaritas"},
    {"afip":"5668","location":"Establecimiento Los Monigotes"},
    {"afip":"5670","location":"Establecimiento Punta Alta"},
    {"afip":"5671","location":"Establecimiento San Eduardo"},
    {"afip":"5672","location":"Establecimiento San Eusebio"},
    {"afip":"5673","location":"Establecimiento San Francisco"},
    {"afip":"5674","location":"Establecimiento San Martin"},
    {"afip":"5676","location":"Estacas"},
    {"afip":"5681","location":"Estacion Alcaraz"},
    {"afip":"5704","location":"Estacion General Racedo"},
    {"afip":"5726","location":"Estacion Santa Ana"},
    {"afip":"5727","location":"Estacion Sola"},
    {"afip":"5731","location":"Estacion Urquiza"},
    {"afip":"5732","location":"Estacion Uruguay"},
    {"afip":"5735","location":"Estacion Yerua"},
    {"afip":"5750","location":"Estancia Bella Vista"},
    {"afip":"5764","location":"Estancia Cnia La Primavera"},
    {"afip":"5765","location":"Estancia Cnia Santa Elena"},
    {"afip":"5766","location":"Estancia Cnia Sta Teresa"},
    {"afip":"5767","location":"Estancia Colonia El Ombu"},
    {"afip":"5768","location":"Estancia Colonia El Toropi"},
    {"afip":"5769","location":"Estancia Colonia La Tapera"},
    {"afip":"5770","location":"Estancia Colonia Peribebuy"},
    {"afip":"5771","location":"Estancia Colonia San Pedro"},
    {"afip":"5772","location":"Estancia Colonia Santa Eloisa"},
    {"afip":"5773","location":"Estancia Colonia Santa Juana"},
    {"afip":"5815","location":"Estancia El Toropi"},
    {"afip":"5847","location":"Estancia La Floresta"},
    {"afip":"5850","location":"Estancia La Gama"},
    {"afip":"5916","location":"Estancia Los Vascos"},
    {"afip":"5947","location":"Estancia Salinas"},
    {"afip":"5953","location":"Estancia San Jose"},
    {"afip":"6043","location":"Fabrica Colon"},
    {"afip":"6078","location":"Faustino M Parera"},
    {"afip":"6083","location":"Febre"},
    {"afip":"6084","location":"Federacion"},
    {"afip":"6085","location":"Federal"},
    {"afip":"6174","location":"Floresta"},
    {"afip":"6231","location":"Fortuna"},
    {"afip":"6267","location":"Frigorifico Yuqueri"},
    {"afip":"6274","location":"Fronteras"},
    {"afip":"6350","location":"Genacito"},
    {"afip":"6354","location":"General Almada"},
    {"afip":"6362","location":"General Campos"},
    {"afip":"6373","location":"General Galarza"},
    {"afip":"6407","location":"General Racedo"},
    {"afip":"6408","location":"General Ramirez"},
    {"afip":"6424","location":"Geribebuy"},
    {"afip":"6435","location":"Gilbert"},
    {"afip":"6457","location":"Gobernador Echague"},
    {"afip":"6458","location":"Gobernador Febre"},
    {"afip":"6465","location":"Gobernador Mansilla"},
    {"afip":"6477","location":"Gobernador Sola"},
    {"afip":"6481","location":"Gobernador Urquiza"},
    {"afip":"6496","location":"Gonzalez"},
    {"afip":"6497","location":"Gonzalez Calderon"},
    {"afip":"6545","location":"Grupo Achiras"},
    {"afip":"6546","location":"Grupo Parrero"},
    {"afip":"6564","location":"Gualeguay"},
    {"afip":"6565","location":"Gualeguaychu"},
    {"afip":"6566","location":"Gualeguaycito"},
    {"afip":"6567","location":"Gualeyan"},
    {"afip":"6592","location":"Guardamonte"},
    {"afip":"6617","location":"Guayaquil"},
    {"afip":"6657","location":"Hambis"},
    {"afip":"6680","location":"Hasenkamp"},
    {"afip":"6695","location":"Hernandarias"},
    {"afip":"6696","location":"Hernandez"},
    {"afip":"6699","location":"Herrera"},
    {"afip":"6702","location":"Hervidero"},
    {"afip":"6714","location":"Hinojal"},
    {"afip":"6720","location":"Hipodromo"},
    {"afip":"6732","location":"Hojas Anchas"},
    {"afip":"6735","location":"Holt"},
    {"afip":"6867","location":"Ibicuy"},
    {"afip":"6911","location":"Ing Miguel Sajaroff"},
    {"afip":"6993","location":"Irazusta Estacion"},
    {"afip":"7021","location":"Isla Curuzu Chali"},
    {"afip":"7033","location":"Isla Del Ibicuy"},
    {"afip":"7036","location":"Isla El Dorado"},
    {"afip":"7037","location":"Isla El Pillo"},
    {"afip":"7043","location":"Isla La Paz"},
    {"afip":"7046","location":"Isla Lynch"},
    {"afip":"7053","location":"Isla San Jose"},
    {"afip":"7067","location":"Islas Alcaraz"},
    {"afip":"7068","location":"Islas De Las Lechiguanas"},
    {"afip":"7077","location":"Isthilart"},
    {"afip":"7155","location":"Jorge Fink"},
    {"afip":"7189","location":"Juan B Monti"},
    {"afip":"7203","location":"Juan Jorge"},
    {"afip":"7228","location":"Jubileo"},
    {"afip":"7274","location":"Kilmetro 165"},
    {"afip":"7307","location":"La Alicia"},
    {"afip":"7315","location":"La Amiguita"},
    {"afip":"7347","location":"La Balsa"},
    {"afip":"7348","location":"La Balsa Parana"},
    {"afip":"7353","location":"La Barraca"},
    {"afip":"7419","location":"La Capilla"},
    {"afip":"7471","location":"La Chica"},
    {"afip":"7476","location":"La Chilena Canal"},
    {"afip":"7477","location":"La Chilena Zanja"},
    {"afip":"7499","location":"La Clarita"},
    {"afip":"7528","location":"La Corvina"},
    {"afip":"7542","location":"La Cuadra"},
    {"afip":"7561","location":"La Diligencia"},
    {"afip":"7593","location":"La Encierra"},
    {"afip":"7603","location":"La Esmeralda"},
    {"afip":"7639","location":"La Favorita"},
    {"afip":"7682","location":"La Goya"},
    {"afip":"7705","location":"La Hierra"},
    {"afip":"7748","location":"La Joya"},
    {"afip":"7752","location":"La Juliana"},
    {"afip":"7780","location":"La Llave"},
    {"afip":"7825","location":"La Maria Luisa"},
    {"afip":"7834","location":"La Maruja A"},
    {"afip":"7879","location":"La Nobleza"},
    {"afip":"7885","location":"La Odilia"},
    {"afip":"7890","location":"La Ollita"},
    {"afip":"7950","location":"La Picada"},
    {"afip":"7951","location":"La Picada Norte"},
    {"afip":"8071","location":"La S Diez Casas"},
    {"afip":"8095","location":"La Sesteada"},
    {"afip":"8128","location":"La Tigresa"},
    {"afip":"8173","location":"La Verbena"},
    {"afip":"8204","location":"La Zelmira"},
    {"afip":"8275","location":"Laguna Del Pescado"},
    {"afip":"8325","location":"Lamarca"},
    {"afip":"8352","location":"Larroque"},
    {"afip":"8392","location":"Las Bateas"},
    {"afip":"8423","location":"Las Catorce"},
    {"afip":"8445","location":"Las Colas"},
    {"afip":"8496","location":"Las Gauchas"},
    {"afip":"8565","location":"Las Mochas"},
    {"afip":"8571","location":"Las Moscas"},
    {"afip":"8574","location":"Las Mulitas"},
    {"afip":"8584","location":"Las Pajitas"},
    {"afip":"8728","location":"Laurencena"},
    {"afip":"8736","location":"Lazo"},
    {"afip":"8766","location":"Lesca"},
    {"afip":"8778","location":"Libaros"},
    {"afip":"8781","location":"Libertador Gral San Martin"},
    {"afip":"8782","location":"Libertador San Martin"},
    {"afip":"8787","location":"Liebig"},
    {"afip":"8808","location":"Linea 19"},
    {"afip":"8809","location":"Linea 20"},
    {"afip":"8810","location":"Linea 24"},
    {"afip":"8811","location":"Linea 25"},
    {"afip":"8817","location":"Lionel"},
    {"afip":"8843","location":"Lobos Arroyo"},
    {"afip":"8890","location":"Lomas Blancas"},
    {"afip":"8966","location":"Los Amigos"},
    {"afip":"9007","location":"Los Brillantes"},
    {"afip":"9012","location":"Los Burgos Apeadero Fcgu"},
    {"afip":"9048","location":"Los Charruas"},
    {"afip":"9077","location":"Los Conquistadores"},
    {"afip":"9220","location":"Los Naranjos"},
    {"afip":"9232","location":"Los Ombues"},
    {"afip":"9331","location":"Los Sauer"},
    {"afip":"9411","location":"Lucas Gonzalez"},
    {"afip":"9413","location":"Lucas Noreste"},
    {"afip":"9414","location":"Lucas Norte"},
    {"afip":"9415","location":"Lucas Sud"},
    {"afip":"9418","location":"Lucienville 1"},
    {"afip":"9419","location":"Lucienville 2"},
    {"afip":"9420","location":"Lucienville 3"},
    {"afip":"9421","location":"Lucienville 4"},
    {"afip":"9460","location":"Mac Dougall"},
    {"afip":"9461","location":"Mac Keller"},
    {"afip":"9475","location":"Macia"},
    {"afip":"9572","location":"Manatiales"},
    {"afip":"9582","location":"Mandisovi"},
    {"afip":"9588","location":"Mangrullo"},
    {"afip":"9662","location":"Maria Grande"},
    {"afip":"9663","location":"Maria Grande Primera"},
    {"afip":"9664","location":"Maria Grande Segunda Sur"},
    {"afip":"9703","location":"Martinetti"},
    {"afip":"9709","location":"Martiniano Leguizamon"},
    {"afip":"9750","location":"Mauricio Ribole"},
    {"afip":"9768","location":"Mazaruca"},
    {"afip":"9827","location":"Mesa"},
    {"afip":"9855","location":"Miguel J Perliza"},
    {"afip":"9934","location":"Mira Monte"},
    {"afip":"9991","location":"Mojones Norte"},
    {"afip":"9992","location":"Mojones Sud Primero"},
    {"afip":"9993","location":"Mojones Sur Segundo"},
    {"afip":"9997","location":"Molino Bob"},
    {"afip":"9999","location":"Molino Doll"},
    {"afip":"10042","location":"Monte Chico"},
    {"afip":"10077","location":"Monte Verde"},
    {"afip":"10087","location":"Montiel"},
    {"afip":"10088","location":"Montoya"},
    {"afip":"10089","location":"Montoya Victoria"},
    {"afip":"10222","location":"Nicolas Herrera"},
    {"afip":"10255","location":"Nogoya"},
    {"afip":"10264","location":"Novibuco Primero"},
    {"afip":"10282","location":"Nueva Escocia"},
    {"afip":"10304","location":"Nueva Vizcaya"},
    {"afip":"10380","location":"Ombues"},
    {"afip":"10403","location":"Oro Verde"},
    {"afip":"10412","location":"Osvaldo Magnasco"},
    {"afip":"10471","location":"Paja Brava"},
    {"afip":"10481","location":"Palacio San Jose"},
    {"afip":"10485","location":"Palavecino"},
    {"afip":"10509","location":"Palmar Yatay"},
    {"afip":"10518","location":"Palo A Pique"},
    {"afip":"10670","location":"Paracao"},
    {"afip":"10677","location":"Parada Yuqueri"},
    {"afip":"10699","location":"Paraje Guayabo"},
    {"afip":"10719","location":"Paraje Palaveroi"},
    {"afip":"10738","location":"Parana"},
    {"afip":"10739","location":"Parana Bravo"},
    {"afip":"10740","location":"Paranacito"},
    {"afip":"10741","location":"Paranacito Rio"},
    {"afip":"10755","location":"Parque Nacional El Palmar"},
    {"afip":"10761","location":"Pasaje Al Aguila Rio"},
    {"afip":"10763","location":"Pasaje Talavera"},
    {"afip":"10797","location":"Paso De La Arena"},
    {"afip":"10798","location":"Paso De La Balza"},
    {"afip":"10800","location":"Paso De La Laguna"},
    {"afip":"10801","location":"Paso De La Legua"},
    {"afip":"10833","location":"Paso Del Abra"},
    {"afip":"10838","location":"Paso Del Cisnero"},
    {"afip":"10840","location":"Paso Del Gallo"},
    {"afip":"10845","location":"Paso Del Molino"},
    {"afip":"10859","location":"Paso Garibaldi"},
    {"afip":"10878","location":"Paso Margariños"}
    ,
    {"afip":"10880","location":"Paso Medina"},
    {"afip":"10891","location":"Paso Potrillo"},
    {"afip":"10893","location":"Paso Puerto Augusto"},
    {"afip":"10905","location":"Paso Sociedad"},
    {"afip":"10906","location":"Paso Telegrafo"},
    {"afip":"10918","location":"Pastor Britos"},
    {"afip":"10957","location":"Pedermar"},
    {"afip":"10958","location":"Pedernal"},
    {"afip":"10979","location":"Pehuajo Norte"},
    {"afip":"10980","location":"Pehuajo Sud"},
    {"afip":"10994","location":"Perdices"},
    {"afip":"11015","location":"Perucho Verna"},
    {"afip":"11018","location":"Pesqueria Diamantino"},
    {"afip":"11139","location":"Piloto Avila"},
    {"afip":"11340","location":"Pos Pos"},
    {"afip":"11527","location":"Presidente Avellaneda"},
    {"afip":"11535","location":"Primer Congreso"},
    {"afip":"11536","location":"Primer Cuartel"},
    {"afip":"11537","location":"Primer Distrito"},
    {"afip":"11545","location":"Pronunciamiento"},
    {"afip":"11604","location":"Pueblito Norte"},
    {"afip":"11615","location":"Pueblo Bellocq"},
    {"afip":"11616","location":"Pueblo Brugo"},
    {"afip":"11619","location":"Pueblo Cazes"},
    {"afip":"11621","location":"Pueblo Colorado"},
    {"afip":"11624","location":"Pueblo Dominguez"},
    {"afip":"11626","location":"Pueblo Ellison"},
    {"afip":"11628","location":"Pueblo Ferre"},
    {"afip":"11630","location":"Pueblo General Belgrano"},
    {"afip":"11631","location":"Pueblo General Paz"},
    {"afip":"11636","location":"Pueblo Liebig S"},
    {"afip":"11642","location":"Pueblo Moreno"},
    {"afip":"11672","location":"Puente Carmona"},
    {"afip":"11677","location":"Puente De Gualeguaychu"},
    {"afip":"11678","location":"Puente De Las Pencas"},
    {"afip":"11679","location":"Puente De Lucas"},
    {"afip":"11681","location":"Puente Del Chañar"}
    ,
    {"afip":"11682","location":"Puente Del Doll"},
    {"afip":"11696","location":"Puente Obispo"},
    {"afip":"11698","location":"Puente Paranacito"},
    {"afip":"11699","location":"Puente Pellegrini"},
    {"afip":"11708","location":"Puente Victoria"},
    {"afip":"11710","location":"Puente ñancay"}
    ,
    {"afip":"11716","location":"Puerta De Crespo"},
    {"afip":"11746","location":"Puerto Algarrobo"},
    {"afip":"11748","location":"Puerto Almiron"},
    {"afip":"11757","location":"Puerto Barriles"},
    {"afip":"11766","location":"Puerto Cadenas"},
    {"afip":"11767","location":"Puerto Campinchuelo"},
    {"afip":"11775","location":"Puerto Colorado"},
    {"afip":"11776","location":"Puerto Constanza"},
    {"afip":"11777","location":"Puerto Curtiembre"},
    {"afip":"11786","location":"Puerto Diamante"},
    {"afip":"11791","location":"Puerto Esquina"},
    {"afip":"11801","location":"Puerto Ibicuy"},
    {"afip":"11807","location":"Puerto La Esmeralda"},
    {"afip":"11812","location":"Puerto Las Cuevas"},
    {"afip":"11821","location":"Puerto Lopez"},
    {"afip":"11826","location":"Puerto Marquez"},
    {"afip":"11841","location":"Puerto Perazzo"},
    {"afip":"11857","location":"Puerto Ruiz"},
    {"afip":"11863","location":"Puerto San Juan"},
    {"afip":"11877","location":"Puerto Unzue"},
    {"afip":"11882","location":"Puerto Viboras"},
    {"afip":"11885","location":"Puerto Viejo"},
    {"afip":"11887","location":"Puerto Villarruel"},
    {"afip":"11892","location":"Puerto Yerua"},
    {"afip":"11894","location":"Puerto Yunque"},
    {"afip":"12163","location":"Puntas De Moreira"},
    {"afip":"12164","location":"Puntas Del Gualeguaychu"},
    {"afip":"12165","location":"Puntas Del Palmar"},
    {"afip":"12176","location":"Quebrachito"},
    {"afip":"12254","location":"Quintas Al Sud"},
    {"afip":"12258","location":"Quinto Cuartel Victoria"},
    {"afip":"12259","location":"Quinto Distrito"},
    {"afip":"12288","location":"Rachel"},
    {"afip":"12299","location":"Raices Al Este"},
    {"afip":"12300","location":"Raices Al Norte"},
    {"afip":"12301","location":"Raices Oeste"},
    {"afip":"12320","location":"Ramon A Parera"},
    {"afip":"12481","location":"Rincon De Cinto"},
    {"afip":"12491","location":"Rincon De Las Guachas"},
    {"afip":"12501","location":"Rincon De Mojones"},
    {"afip":"12503","location":"Rincon De Nogoya"},
    {"afip":"12517","location":"Rincon Del Doll"},
    {"afip":"12519","location":"Rincon Del Gato"},
    {"afip":"12521","location":"Rincon Del Nogoya Sur"},
    {"afip":"12535","location":"Rincon Lucas Norte"},
    {"afip":"12536","location":"Rincon Lucas Sud"},
    {"afip":"12552","location":"Rio Alferez Nelson Page"},
    {"afip":"12564","location":"Rio Ceibo"},
    {"afip":"12609","location":"Rio Parana Guazu"},
    {"afip":"12621","location":"Rio Sauce"},
    {"afip":"12625","location":"Rio Talavera"},
    {"afip":"12640","location":"Rivas"},
    {"afip":"12648","location":"Rocamora"},
    {"afip":"12705","location":"Rosario Del Tala"},
    {"afip":"12713","location":"Rospina"},
    {"afip":"12751","location":"Ruta 14 Km 443"},
    {"afip":"12797","location":"Sagastume"},
    {"afip":"12809","location":"Saladero Alzua"},
    {"afip":"12810","location":"Saladero Concordia"},
    {"afip":"12813","location":"Saladero San Jose"},
    {"afip":"12883","location":"San Anselmo"},
    {"afip":"12931","location":"San Cipriano"},
    {"afip":"12994","location":"San Jaime"},
    {"afip":"12995","location":"San Jaime De La Frontera"},
    {"afip":"13013","location":"San Jose De Feliciano"},
    {"afip":"13054","location":"San Juan La Querencia"},
    {"afip":"13072","location":"San Luis San Jose Feliciano"},
    {"afip":"13100","location":"San Miguel Nro 2"},
    {"afip":"13133","location":"San Ramirez"},
    {"afip":"13158","location":"San Victor"},
    {"afip":"13160","location":"Sanatorio Adventista Del Plata"},
    {"afip":"13161","location":"Sanatorio Apeadero Fcgu"},
    {"afip":"13181","location":"Santa Anita"},
    {"afip":"13271","location":"Santa Sara"},
    {"afip":"13317","location":"Sarandi-Cora"},
    {"afip":"13342","location":"Sauce De Luna"},
    {"afip":"13348","location":"Sauce Montrull"},
    {"afip":"13349","location":"Sauce Norte"},
    {"afip":"13351","location":"Sauce Pinto"},
    {"afip":"13354","location":"Sauce Rio"},
    {"afip":"13357","location":"Sauce Sud"},
    {"afip":"13373","location":"Saxto Distrito Colon"},
    {"afip":"13386","location":"Seccion Urquiza"},
    {"afip":"13390","location":"Segui"},
    {"afip":"13393","location":"Segunda Seccion Lucas Al Sud"},
    {"afip":"13396","location":"Segundo Cuartel"},
    {"afip":"13411","location":"Septimo Distrito"},
    {"afip":"13426","location":"Sexto Distrito"},
    {"afip":"13494","location":"Sir Leonard"},
    {"afip":"13509","location":"Sola"},
    {"afip":"13554","location":"Spindola"},
    {"afip":"13561","location":"Strobel"},
    {"afip":"13564","location":"Subcentral Santa Maria"},
    {"afip":"13603","location":"Surst"},
    {"afip":"13620","location":"Tablada Norte Concordia"},
    {"afip":"13621","location":"Tablada Oeste Concordia"},
    {"afip":"13626","location":"Tabossi"},
    {"afip":"13665","location":"Tacuaras Yacare"},
    {"afip":"13723","location":"Talitas"},
    {"afip":"13724","location":"Talitas-Gualeguaychu"},
    {"afip":"13778","location":"Tases"},
    {"afip":"13840","location":"Tezanos Pinto"},
    {"afip":"13907","location":"Tiro Federal"},
    {"afip":"13939","location":"Tomas Rocamora"},
    {"afip":"14033","location":"Tres Aldeas"},
    {"afip":"14064","location":"Tres Lagunas"},
    {"afip":"14131","location":"Tte Primero Brigio Cainzo"},
    {"afip":"14178","location":"Ubajay"},
    {"afip":"14204","location":"Urdinarrain"},
    {"afip":"14273","location":"Valle Maria"},
    {"afip":"14337","location":"Viale"},
    {"afip":"14381","location":"Villa Aida"},
    {"afip":"14402","location":"Villa Antony"},
    {"afip":"14427","location":"Villa Boreilo"},
    {"afip":"14462","location":"Villa Clara"},
    {"afip":"14498","location":"Villa Del Cerro"},
    {"afip":"14519","location":"Villa Dominguez"},
    {"afip":"14536","location":"Villa Eleonora"},
    {"afip":"14550","location":"Villa Faustino M Parera"},
    {"afip":"14583","location":"Villa Gob Luis Etchevehere"},
    {"afip":"14601","location":"Villa Hernandarias"},
    {"afip":"14649","location":"Villa Libertador San Martin"},
    {"afip":"14651","location":"Villa Lila"},
    {"afip":"14672","location":"Villa Mantero"},
    {"afip":"14736","location":"Villa Perper"},
    {"afip":"14738","location":"Villa Porteña"}
    ,
    {"afip":"14775","location":"Villa Romero"},
    {"afip":"14797","location":"Villa San Justo"},
    {"afip":"14799","location":"Villa San Marcial"},
    {"afip":"14849","location":"Villa Tres De Febrero"},
    {"afip":"14853","location":"Villa Udine"},
    {"afip":"14855","location":"Villa Uranga"},
    {"afip":"14856","location":"Villa Urquiza"},
    {"afip":"14873","location":"Villa Zorraquin"},
    {"afip":"14877","location":"Villaguay"},
    {"afip":"14878","location":"Villaguay Este"},
    {"afip":"14879","location":"Villaguaycito"},
    {"afip":"14882","location":"Villamil"},
    {"afip":"14901","location":"Virano"},
    {"afip":"14929","location":"Vizcachera"},
    {"afip":"14954","location":"Walter Moss"},
    {"afip":"15023","location":"Yaros"},
    {"afip":"15045","location":"Yerua"},
    {"afip":"15046","location":"Yeso"},
    {"afip":"15048","location":"Yeso Oeste"},
    {"afip":"15108","location":"Zenon Roca"},
    {"afip":"15132","location":"Ñandubaysal"}
    ,
    {"afip":"15169","location":"Achiras"},
    {"afip":"15215","location":"Albardon"},
    {"afip":"15217","location":"Albariño"}
    ,
    {"afip":"15295","location":"Arroyo Burgos"},
    {"afip":"15301","location":"Arroyo Grande"},
    {"afip":"15302","location":"Arroyo Negro"},
    {"afip":"15395","location":"Benitez"},
    {"afip":"15396","location":"Berisso"},
    {"afip":"15440","location":"Calera"},
    {"afip":"15465","location":"Campo Moreno"},
    {"afip":"15496","location":"Carrizal"},
    {"afip":"15515","location":"Caseros"},
    {"afip":"15529","location":"Cañada Grande"}
    ,
    {"afip":"15561","location":"Cerrito"},
    {"afip":"15609","location":"Chacras"},
    {"afip":"15654","location":"Colon"},
    {"afip":"15658","location":"Colonia Alemana"},
    {"afip":"15661","location":"Colonia Argentina"},
    {"afip":"15663","location":"Colonia Baron Hirsch"},
    {"afip":"15676","location":"Colonia Elisa"},
    {"afip":"15680","location":"Colonia Italiana"},
    {"afip":"15683","location":"Colonia La Argentina"},
    {"afip":"15684","location":"Colonia La Esperanza"},
    {"afip":"15690","location":"Colonia La Providencia"},
    {"afip":"15691","location":"Colonia Leven"},
    {"afip":"15707","location":"Colonia San Antonio"},
    {"afip":"15710","location":"Colonia San Francisco"},
    {"afip":"15717","location":"Colonia San Jose"},
    {"afip":"15726","location":"Colonia San Martin"},
    {"afip":"15727","location":"Colonia San Miguel"},
    {"afip":"15731","location":"Colonia San Ramon"},
    {"afip":"15733","location":"Colonia Santa Ana"},
    {"afip":"15739","location":"Colonia Santa Elena"},
    {"afip":"15749","location":"Colonia Santa Rosa"},
    {"afip":"15754","location":"Colonia Sauce"},
    {"afip":"15758","location":"Colonia Velez"},
    {"afip":"15805","location":"Costa Grande"},
    {"afip":"15812","location":"Cuatro Bocas"},
    {"afip":"15818","location":"Curtiembre"},
    {"afip":"15825","location":"Distrito Espinillo"},
    {"afip":"15835","location":"Dos Hermanas"},
    {"afip":"15843","location":"Durazno"},
    {"afip":"15945","location":"El Carmen"},
    {"afip":"15983","location":"El Chaja"},
    {"afip":"16196","location":"El Pingo"},
    {"afip":"16228","location":"El Potrero"},
    {"afip":"16244","location":"El Pueblito"},
    {"afip":"16270","location":"El Quebracho"},
    {"afip":"16286","location":"El Refugio"},
    {"afip":"16322","location":"El Rosario"},
    {"afip":"16349","location":"El Sauce"},
    {"afip":"16416","location":"El Tropezon"},
    {"afip":"16468","location":"Espinillo"},
    {"afip":"16493","location":"Estancia San Juan"},
    {"afip":"16508","location":"Fernandez"},
    {"afip":"16521","location":"Florida"},
    {"afip":"16537","location":"General Alvear"},
    {"afip":"16656","location":"La Argentina"},
    {"afip":"16696","location":"La Blanqueada"},
    {"afip":"16708","location":"La Calera"},
    {"afip":"16725","location":"La Carlota"},
    {"afip":"16782","location":"La Colina"},
    {"afip":"16785","location":"La Colmena"},
    {"afip":"16796","location":"La Colorada"},
    {"afip":"16813","location":"La Costa"},
    {"afip":"16820","location":"La Criolla"},
    {"afip":"16859","location":"La Emilia"},
    {"afip":"16863","location":"La Escondida"},
    {"afip":"16906","location":"La Estrella"},
    {"afip":"16929","location":"La Florencia"},
    {"afip":"16935","location":"La Florida"},
    {"afip":"16963","location":"La Granja"},
    {"afip":"16994","location":"La Ilusion"},
    {"afip":"16996","location":"La Invernada"},
    {"afip":"17036","location":"La Lata"},
    {"afip":"17053","location":"La Loma"},
    {"afip":"17139","location":"La Pampa"},
    {"afip":"17151","location":"La Paz"},
    {"afip":"17158","location":"La Perla"},
    {"afip":"17227","location":"La Querencia"},
    {"afip":"17229","location":"La Quinta"},
    {"afip":"17267","location":"La Rosada"},
    {"afip":"17286","location":"La Selva"},
    {"afip":"17296","location":"La Soledad"},
    {"afip":"17303","location":"La Suiza"},
    {"afip":"17362","location":"La Virginia"},
    {"afip":"17389","location":"Laguna Larga"},
    {"afip":"17413","location":"Las Achiras"},
    {"afip":"17489","location":"Las Cuevas"},
    {"afip":"17516","location":"Las Garzas"},
    {"afip":"17546","location":"Las Lagunas"},
    {"afip":"17573","location":"Las Masitas"},
    {"afip":"17579","location":"Las Mercedes"},
    {"afip":"17623","location":"Las Peñas"}
    ,
    {"afip":"17649","location":"Las Rosas"},
    {"afip":"17669","location":"Las Tejas"},
    {"afip":"17675","location":"Las Toscas"},
    {"afip":"17687","location":"Las Tunas"},
    {"afip":"17734","location":"Loma Negra"},
    {"afip":"17854","location":"Los Gansos"},
    {"afip":"17912","location":"Los Paraisos"},
    {"afip":"18028","location":"Medanos"},
    {"afip":"18099","location":"Moreno"},
    {"afip":"18154","location":"Pajas Blancas"},
    {"afip":"18156","location":"Pajonal"},
    {"afip":"18161","location":"Palmar"},
    {"afip":"18215","location":"Paso De Las Piedras"},
    {"afip":"18258","location":"Piedras Blancas"},
    {"afip":"18355","location":"Pueblito"},
    {"afip":"18357","location":"Pueblo Nuevo"},
    {"afip":"18418","location":"Punta Del Monte"},
    {"afip":"18435","location":"Quebracho"},
    {"afip":"18446","location":"Racedo"},
    {"afip":"18448","location":"Raices"},
    {"afip":"18453","location":"Ramblon"},
    {"afip":"18457","location":"Ramirez"},
    {"afip":"18588","location":"San Antonio"},
    {"afip":"18602","location":"San Benito"},
    {"afip":"18614","location":"San Buenaventura"},
    {"afip":"18668","location":"San Francisco"},
    {"afip":"18681","location":"San Geronimo"},
    {"afip":"18686","location":"San Gregorio"},
    {"afip":"18693","location":"San Gustavo"},
    {"afip":"18736","location":"San Jorge"},
    {"afip":"18747","location":"San Jose"},
    {"afip":"18760","location":"San Juan"},
    {"afip":"18776","location":"San Julian"},
    {"afip":"18778","location":"San Justo"},
    {"afip":"18787","location":"San Lorenzo"},
    {"afip":"18816","location":"San Martin"},
    {"afip":"18828","location":"San Miguel"},
    {"afip":"18856","location":"San Pedro"},
    {"afip":"18901","location":"San Salvador"},
    {"afip":"18920","location":"San Vicente"},
    {"afip":"18931","location":"Santa Ana"},
    {"afip":"18974","location":"Santa Elena"},
    {"afip":"18988","location":"Santa Ines"},
    {"afip":"18996","location":"Santa Isabel"},
    {"afip":"19004","location":"Santa Juana"},
    {"afip":"19015","location":"Santa Luisa"},
    {"afip":"19022","location":"Santa Maria"},
    {"afip":"19032","location":"Santa Marta"},
    {"afip":"19049","location":"Santa Rosa"},
    {"afip":"19101","location":"Sarandi"},
    {"afip":"19109","location":"Sauce"},
    {"afip":"19152","location":"Sosa"},
    {"afip":"19177","location":"Talita"},
    {"afip":"19218","location":"Tres Bocas"},
    {"afip":"19230","location":"Tres Esquinas"},
    {"afip":"19278","location":"Vergara"},
    {"afip":"19280","location":"Viboras"},
    {"afip":"19281","location":"Victoria"},
    {"afip":"19286","location":"Villa Angelica"},
    {"afip":"19292","location":"Villa Del Rosario"},
    {"afip":"19299","location":"Villa Elisa"},
    {"afip":"19303","location":"Villa Fontana"},
    {"afip":"19307","location":"Villa Libertad"},
    {"afip":"19323","location":"Villa Paranacito"},
    {"afip":"19328","location":"Villa San Jose"},
    {"afip":"19331","location":"Villa San Miguel"},
    {"afip":"19336","location":"Villa Sarmiento"},
    {"afip":"19354","location":"Yacare"},
    {"afip":"19358","location":"Yatay"},
    {"afip":"19363","location":"Yuqueri"},
    {"afip":"20994","location":"Las Mercedes"},
    {"afip":"20995","location":"Pueblo Nuevo"},
    {"afip":"20996","location":"Puerto Viejo"},
    {"afip":"20997","location":"Sagastume"},
    {"afip":"20998","location":"San Martin"},
    {"afip":"20999","location":"Santa Rosa"},
    {"afip":"21000","location":"Tres Esquinas"},
    {"afip":"21001","location":"Colonia La Mora"},
    {"afip":"21002","location":"Colonia San Jorge"},
    {"afip":"21012","location":"La Calera"},
    {"afip":"21013","location":"Sarandi"},
    {"afip":"21014","location":"Arroyo Hondo"},
    {"afip":"21015","location":"Las Lagunas"},
    {"afip":"21016","location":"Puerto Algarrobo"},
    {"afip":"21017","location":"San Antonio"},
    {"afip":"21018","location":"Santa Ines"},
    {"afip":"21019","location":"Los Paraisos"},
    {"afip":"21020","location":"Septimo Distrito"},
    {"afip":"21021","location":"Aldea Santa Maria"},
    {"afip":"21022","location":"Quebracho"},
    {"afip":"21023","location":"Cuatro Bocas"},
    {"afip":"21024","location":"Sauce Norte"},
    {"afip":"21025","location":"Sola"},
    {"afip":"21026","location":"Colonia Elisa"},
    {"afip":"21027","location":"Colonia San Antonio"},
    {"afip":"21045","location":"San Francisco"},
    {"afip":"21046","location":"Cuatro Bocas"},
    {"afip":"21683","location":"1 De Mayo"},
    {"afip":"21684","location":"El Remance"},
    {"afip":"21693","location":"San Salvador"},
    {"afip":"21930","location":"Villa Urquiza"},
    {"afip":"22004","location":"Colonia Oficial Numero 6"},
    {"afip":"22005","location":"Primero De Mayo"},
    {"afip":"22006","location":"Colonia 3 De Febrero"},
    {"afip":"22007","location":"Colonia Hambis"},
    {"afip":"22008","location":"Colonia La Suiza"},
    {"afip":"22009","location":"Colonia Santa Ines"},
    {"afip":"22010","location":"Colonia Primero De Mayo"},
    {"afip":"22011","location":"Colonia Quinta Ensanche De Mayo"},
    {"afip":"22012","location":"Colonia Federal"},
    {"afip":"22013","location":"Colonia San Lorenzo"},
    {"afip":"22014","location":"Diego Lopez"},
    {"afip":"22015","location":"Paso Quebracho"},
    {"afip":"22016","location":"Colonia Racedo"},
    {"afip":"22017","location":"Walter Moos"},
    {"afip":"22018","location":"Los Sauces"},
    {"afip":"22019","location":"Colonia Oficial Numero 1"},
    {"afip":"22020","location":"Tatuti"},
    {"afip":"22021","location":"Mojones Sud"},
    {"afip":"22022","location":"Paso La Laguna"},
    {"afip":"22023","location":"Ingeniero Miguel Sajaroff"},
    {"afip":"22024","location":"Arroyo Cle"},
    {"afip":"22025","location":"Calderon"},
    {"afip":"22026","location":"Colonia Las Guachas"},
    {"afip":"22027","location":"El Obispo"},
    {"afip":"22028","location":"Las Guachas"},
    {"afip":"22029","location":"Sauce Sur"},
    {"afip":"22031","location":"Costa Uruguay"},
    {"afip":"22032","location":"Faustino M. Parera"},
    {"afip":"22033","location":"Islas Del Ibicuy"},
    {"afip":"22034","location":"Almada"},
    {"afip":"22035","location":"Costa San Antonio"},
    {"afip":"22036","location":"Rincon Del Cinto"},
    {"afip":"22037","location":"Escrinia"},
    {"afip":"22038","location":"Irazusta"},
    {"afip":"22039","location":"Las Hermanas"},
    {"afip":"22040","location":"Las Mercedes (Larroque)"},
    {"afip":"22041","location":"Aldea Grapschental"},
    {"afip":"22042","location":"Puiggari"},
    {"afip":"22043","location":"Libertador San Martin (Diamante)"},
    {"afip":"22044","location":"Crucesitas Septima Seccion"},
    {"afip":"22045","location":"Colonia Avellaneda"},
    {"afip":"22046","location":"Gobernador Etchevehere"},
    {"afip":"22047","location":"Las Delicias"},
    {"afip":"22048","location":"Tezanos Pintos"},
    {"afip":"22049","location":"Colonia Cerrito"},
    {"afip":"22050","location":"Colonia General Guemes"},
    {"afip":"22051","location":"Maria Grande Segunda"},
    {"afip":"22052","location":"Alcaraz"},
    {"afip":"22053","location":"Alcaraz Segundo"},
    {"afip":"22054","location":"Pueblo Arrua"},
    {"afip":"22055","location":"Bovril"},
    {"afip":"22056","location":"Alcaraz Primero"},
    {"afip":"22057","location":"Don Cristobal Primera Seccion"},
    {"afip":"22058","location":"Crucesitas Tercera Seccion"},
    {"afip":"22059","location":"Distrito Corrales"},
    {"afip":"22060","location":"Chiqueros"},
    {"afip":"22061","location":"Veinte De Septiembre"},
    {"afip":"22062","location":"Colonia Algarrobito"},
    {"afip":"22063","location":"Don Cristobal Segunda Seccion"},
    {"afip":"22064","location":"Isletas"},
    {"afip":"22065","location":"Crucesitas Octava Seccion"},
    {"afip":"22066","location":"Colonia Oficial Numero 3"},
    {"afip":"22067","location":"Ombu"},
    {"afip":"22068","location":"Colonia M. Castro"},
    {"afip":"22069","location":"Colonia Oficial Numero 14"},
    {"afip":"22070","location":"Colonia Oficial Numero 15"},
    {"afip":"22071","location":"Colonia San Gustavo"},
    {"afip":"22072","location":"Manantiales"},
    {"afip":"22073","location":"Tacuara"},
    {"afip":"22237","location":"Colonia Nuemro 1"},
    {"afip":"22238","location":"Colonia Nuemro 3"},
    {"afip":"22239","location":"Colonia Nuemro 4"},
    {"afip":"22240","location":"Kilometro 208"},
    {"afip":"22241","location":"Colonia San Antonio (Dpto. Colon)"},
    {"afip":"22242","location":"Colonia Elisa (Dpto. Uruguay)"},
    {"afip":"22243","location":"Kilometro 112"},
    {"afip":"22244","location":"Colonia Elisa (Dpto. Colon)"},
    {"afip":"22245","location":"Colonia San Antonio (Dpto. Uruguay)"},
    {"afip":"22246","location":"Puntas De Gualeguaychu"},
    {"afip":"22247","location":"San Luis San Jose De Feliciano"},
    {"afip":"22248","location":"Colonia Oficial Numero 5"},
    {"afip":"22285","location":"Los Ceibos"},
    {"afip":"22288","location":"Distrito Sexto Costa De Nogoya"},
    {"afip":"22289","location":"Tala"},
    {"afip":"22329","location":"Santa Anita"}
    ]