import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateExchangeComponent } from './generate-exchange.component';
import { HeaderModule } from 'src/app/components/header/header.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { BodyGenerateExchangeModule } from './body-generate-exchange/body-generate-exchange.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    GenerateExchangeComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    HeaderInSectionModule,
    MenuModule,
    BodyGenerateExchangeModule,
    ModalModule
  ], exports:[
    GenerateExchangeComponent
  ]
})
export class GenerateExchangeModule { }
