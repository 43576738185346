import { Component, OnInit,Output,EventEmitter,Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { CONDITIONS_TYPE_TO_FIX_CONST, INDICATORS_TO_FIX_CONST, INDICATOR_VOID, priceTagsToFix } from 'src/app/shared/const/to-fix';
import { INDICATORS_TO_FIX } from 'src/app/shared/dict/condition-and-indicators';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Indicator, } from 'src/app/shared/interfaces/price-to-fix.interface';
import { ToFixValidator, getMaxDateInIndicators, getPlaceDefault, getProductDefault, getQualityDefault, getSinEspacioDefault, isIndicatorIncomplete, isRequiredPlace, isRequiredQuality, isSameIndicator, sortIndicatorsByFixationPeriod } from 'src/app/shared/to-fix';
import { Field } from '../../fields/fields.component';
import { getDate, getDateTomorrow, getDateYesterDay, isEarlierDate } from 'src/app/shared/date.checker';
import { RangeDate } from 'src/app/shared/interfaces/price.interface';
import { isConditionTypeSelected } from '../../fields/to-fix-indicators/indicator/indicator.component';
import { businessDict } from 'src/app/shared/dict/typeBusiness';

@Component({
  selector: 'to-fix-indicators-slide-card',
  templateUrl: './to-fix-indicators.component.html',
  styleUrls: ['./../particularities.scss', './../slide-card.scss', 
  './../../fields/fields.component.scss', './to-fix-indicators.component.scss' ]
})
export class IndicatorsSlideCardComponent extends Field implements OnInit, OnChanges, OnDestroy {
  @Output() closeEvent = new EventEmitter<string>();

  @Input() edit:{indicator:Indicator,index:number}|null=null;
  @Input() delete:Indicator|null=null;


  INDICATORS_TO_FIX_CONST=INDICATORS_TO_FIX_CONST;
  INDICATORS=INDICATORS_TO_FIX;
  arrayIndicators:any[]=[];

  indicatorSelected!:Indicator;
  indicatorSelectedToComplete:number=0;
  indexToEditIndicator:number=-1;
  showMessageError:boolean=false;


  constructor(store:Store<appState>, private toFixV:ToFixValidator) { 
    super(store);

    this.arrayIndicators=Object.entries(INDICATORS_TO_FIX);
    this.arrayIndicators.shift(); //Eliminamos 0: '--' del array
    this.arrayIndicators.pop(); //Eliminamos "-1": '--' del array
  }

  ngOnInit(): void {
    if(this.fieldIdentificator==fieldType.PRICE ){
      this.tag=priceTagsToFix.indicators;
    } else {
      this.tag=priceTagsToFix.price;
      this.subTag=priceTagsToFix.indicators;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["edit"]) {
      this.handleEditChange();
    }

    if (changes["delete"]) {
      this.handleDeleteChange();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  private handleEditChange() {
    setTimeout(()=>{
      if (this.edit) {
        this.editIndicator(this.edit.indicator, this.edit.index);
      }
    }, 80)
  }

  private handleDeleteChange() {
    setTimeout( () =>{
      if (this.delete) {
        this.deleteIndicator(this.delete);
      }
    }, 80)
  }

  override rememberValueInForm(): void {

  }

  /* Event to emit the closing of the slide card */
  close(){
    this.closeEvent.emit('close');
  }

  /* Given an indicator, it takes the conditionType of the condition and checks if that indicator can be with that condition. */
  getIsConditionType(indicator:number){
    return this.elementToGenerate && this.toFixV.getIndicatorsByConditionType(this.getConditionType()).includes(Number(indicator));
  }

  getOptions(){
    return this.getPrice()?.indicators ?? [];
  }

  //Obtenemos la fecha final del indicador anterior
  getEndDateOfPreviusIndicator(){
    try{
      const indicators=this.getOptions();
      const lastIndicator=indicators[indicators.length-1];
      return lastIndicator.fixationPeriod.endDate;
    } catch(e){
      return null;
    }
  }

  //Gets the conditionType of the condition
  getConditionType(){
    return this.getPrice()?.conditionType ?? 0;
  }

  isConditionTypeSinEspacio(){
    return isConditionTypeSelected(this.getConditionType(), CONDITIONS_TYPE_TO_FIX_CONST.SIN_ESPACIO);
  }

  //Get all indicators of the condition
  getAllIndicators(){
    return this.getPrice()?.indicators ?? [];
  }

  setIndicatorSelectedToComplete(indicator:number, event:any=null){
    if(indicator==this.indicatorSelectedToComplete){
      this.indicatorSelectedToComplete=0;
      this.indicatorSelected=this.getIndicatorVoid();

      this.indexToEditIndicator>-1? this.deleteIndicatorByIndex(this.indexToEditIndicator):null;
      
    }else {
      const indicName=Number(indicator);
      if (this.indicatorSelected) {
        this.indicatorSelectedToComplete = this.indicatorSelected.indicatorName = indicName;
        this.indicatorSelected=this.getIndicatorVoid();
        this.indicatorSelected.indicatorName=indicName;
      } else {
        this.indicatorSelectedToComplete = indicName;
        this.indicatorSelected=this.getIndicatorVoid();
        this.indicatorSelected.indicatorName=indicName;
      }
    }
  }

  getIndicatorName(indicator:Indicator){
    return this.toFixV.getIndicatorName(indicator);
  }

  getFixedPeriod(indicator:Indicator){
    return this.toFixV.getFixedPeriod(indicator);
  }

  isIndicatorSelected(indicatorName:number){
    return isSameIndicator(this.indicatorSelectedToComplete, Number(indicatorName));
  }

  setIndicatorSelected(indicator:Indicator){
    this.indicatorSelected=indicator;
  }

  //The applychanges button, takes the indicator, adds it to the price indicators, and then calls the close function.
  applyChanges(){
    const copyIndicator=this.getNewIndicator();
    if(!isIndicatorIncomplete(copyIndicator, this.getConditionType())){
      this.setElementToGenerate(this.getNewIndicators(copyIndicator));
      this.showMessageError=false;
      this.close();
    } else {
      this.showMessageError=true;
    }

    /*    
    const copyIndicator:Indicator=this.getNewIndicator();
    if( !isIndicatorIncomplete(copyIndicator, this.getConditionType()) ){
      this.setElementToGenerate(this.getNewIndicators(copyIndicator));

      this.indicatorSelected=this.getIndicatorVoid();
      this.indicatorSelectedToComplete=0;
      this.indexToEditIndicator=-1;
      this.showMessageError=false;
    } else {
      this.showMessageError=true;
    }*/
  }

  isIndicatorIncomplete(indicator:Indicator, conditionType:number){
    return isIndicatorIncomplete(indicator, conditionType);
  }

  /*Given an indicator, which will be selected by clicking, 
  it is passed to the variable indicatorSelected and the name of the indicator is passed to the variable indicatorSelectedToComplete*/
  editIndicator(indicator:Indicator, index:number){   
    this.indexToEditIndicator=index;
    this.setIndicatorSelectedToComplete(indicator.indicatorName);
    this.setIndicatorSelected(indicator);
  }

  /*Given an indicator, get the indicators with the 'getOptions()' function.
Gets the index of the flag passed by parameter in the array.
If the flag exists, it deletes it and copies the contents of the array into a new variable with JSON.parse(JSON.stringify).
If it doesn't exist, it does nothing. */
  deleteIndicator(indicator:Indicator){
    const index=this.getOptions().indexOf(indicator);
    index>-1? this.deleteIndicatorByIndex(index):null
  }

  deleteIndicatorByIndex(index:number){
    let copyIndicators=JSON.parse(JSON.stringify(this.getOptions()));
    copyIndicators.splice(index,1);
    this.setElementToGenerate(copyIndicators);
  }

  addNewIndicator(){
    const copyIndicator:Indicator=this.getNewIndicator();

    if( !isIndicatorIncomplete(copyIndicator, this.getConditionType()) ){
      this.setElementToGenerate(this.getNewIndicators(copyIndicator));

      this.indicatorSelected=this.getIndicatorVoid();
      this.indicatorSelectedToComplete=0;
      this.indexToEditIndicator=-1;
      this.showMessageError=false;
    } else {
      this.showMessageError=true;
    }
  }

  /*Given a flag, it takes the flags of the condition, 
  checks if it has an edit index, if it does, it replaces it, if it doesn't, it adds it.
  It then returns the array.*/
  getNewIndicators(copyIndicator:Indicator){
    let indicatorsToSave:Indicator[]=JSON.parse(JSON.stringify(this.getPrice().indicators));
    this.indexToEditIndicator>-1 ? indicatorsToSave[this.indexToEditIndicator]=copyIndicator : indicatorsToSave.push(copyIndicator);

    indicatorsToSave=sortIndicatorsByFixationPeriod(indicatorsToSave);
    return indicatorsToSave;
  }

  /*Takes the indicator that was being created, adds the name of the indicator that was selected, and returns it*/
  getNewIndicator(){
    try{
      let copyIndicator:Indicator=JSON.parse(JSON.stringify(this.indicatorSelected));
      copyIndicator.indicatorName=this.indicatorSelectedToComplete;
  
      //Si no tiene producto, le asigno el producto de la condición.
      if(copyIndicator.product<=0){
        copyIndicator.product=JSON.parse(JSON.stringify(this.getPrice().productName));
      }
  
      //Si no tiene porcentaje del producto, le asigno el 100%
      if(copyIndicator.percentageProduct<=0){
        copyIndicator.percentageProduct=100;
      }
  
      return copyIndicator;
    } catch(e){}
    return INDICATOR_VOID
  }

  getIndicatorVoid():Indicator{
    try{
      let indicatorVoid:Indicator=JSON.parse(JSON.stringify(INDICATOR_VOID));
      if(this.getConditionType() != CONDITIONS_TYPE_TO_FIX_CONST.PORCENTAJE_PRODUCTO){
        indicatorVoid.product=getProductDefault(this.getPrice());
      }

      if(isRequiredQuality(this.indicatorSelectedToComplete)){
        indicatorVoid.quality=getQualityDefault(this.getPrice());
      }

      if(isRequiredPlace(this.indicatorSelectedToComplete)){
        indicatorVoid.location=getPlaceDefault(this.getPrice(), this.indicatorSelectedToComplete );
      }
      
      if(this.getConditionType() == CONDITIONS_TYPE_TO_FIX_CONST.SIN_ESPACIO && this.getPrice().typeBusiness==businessDict.forward){
        indicatorVoid=getSinEspacioDefault(this.getPrice().deliveryPeriod, indicatorVoid);
      } else {
        const date=getMaxDateInIndicators(this.getPrice().indicators)
        indicatorVoid.fixationPeriod.startDate= date? getDateTomorrow(date): '--';
      }

      indicatorVoid.min=0;
      indicatorVoid.max=100;

      return indicatorVoid;
    } catch(e){}
    return JSON.parse(JSON.stringify(INDICATOR_VOID));
  }

}