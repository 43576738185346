<div class="dialog-content" mat-dialog-content>
  <div class="logo-container">
    <!--    <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_eni7kar8.json" background="transparent"  speed="1"  style="width: 38px; height: 38px;" loop controls autoplay></lottie-player> -->
    <!--<img class="logo" src="../../../../../assets/images/extra/reload.gif">-->
  </div>

  <span class="hour">{{hour}}</span>
  <p class="title">{{title}}</p>
  <p class="title">¿Deseas continuar de todas formas?</p>

  <div mat-dialog-actions class="buttons">
    <button class="button filling" (click)="confirmOrders()" mat-dialog-close>Si</button>
    <button class="button not-stuffed " mat-dialog-close (click)="close()">No</button>
  </div>

</div>