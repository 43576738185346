import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//Componentes
import { AppComponent } from './app.component';
import { PriceBoardModule } from './pages/price-board/price-board.module';
import { EraserModule } from './pages/eraser/eraser.module';
import { ProfileModule } from './pages/profile/profile.module';
import { HistoricalPageModule } from './pages/historical/historical.module';
import { ControlPanelModule } from './pages/control-panel/control-panel.module';
import { GeneratePriceModule } from './pages/generate-price/generate-price.module';
import { GenerateManualOrderModule } from './pages/generate-order-manual/generate-manual-order.module';
import { GeneratePriceBoardOrderModule } from './pages/generate-order-price-board/generate-order-price-board.module';
import { OrderListModule } from './pages/order-list/order-list.module';
import { OrdersValidationModule } from './pages/orders-validation/orders-validation.module';
import { ExchangeListModule } from './pages/exchange-list/exchange-list.module';
import { GenerateExchangeModule } from './pages/generate-exchange/generate-exchange.module';

//Components Modules
import { LoginModule } from './pages/login/login.module';
import { InicioModule } from './pages/dashboard/inicio.module';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

//Google Login
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {  GoogleLoginProvider } from '@abacritt/angularx-social-login';

//Redux
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

//Effects
import { SessionEffects } from './redux/effects/session.effect';
import { OrderEffects } from './redux/effects/order.effect';
import { ClaimEffects } from './redux/effects/claim.effect';
import { HistoricalEffects } from './redux/effects/historical.effect';
import { ViewsEffects } from './redux/effects/views.effect';

//Reducers
import { loginReducer } from './redux/reducers/login.reducer';
import { optionsReducer } from './redux/reducers/options.reducer';
import { userReducer } from './redux/reducers/session.reducer';
import { dashboardReducer } from './redux/reducers/dashboard.reducer';
import { priceReducer } from './redux/reducers/price.reducer';
import { orderReducer } from './redux/reducers/order.reducer';
import { exchangeReducer } from './redux/reducers/order-exchange.reducer';
import { controlPanelReducer } from './redux/reducers/control-panel.reducer';
import { historicalArrayReducer, historicalReducer } from './redux/reducers/historical.reducer';

//Checker
import { SessionChecker } from './shared/session.checker';
import { DataChecker } from './shared/data.checker';
import { ValidatorChecker } from './shared/validator.checker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TokenInterceptor } from './shared/services/token.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
 
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,

    //Login
    SocialLoginModule,

    //Redux
    EffectsModule.forRoot([SessionEffects, OrderEffects, ClaimEffects, HistoricalEffects, ViewsEffects]),
    StoreModule.forRoot({
      usuario: userReducer,
      login: loginReducer,
      options: optionsReducer,
      dashboard: dashboardReducer,
      price: priceReducer,
      order: orderReducer,
      exchange: exchangeReducer,
      controlPanel: controlPanelReducer,
      historical: historicalReducer,
      historicalArray: historicalArrayReducer
    }),

    //Modulos
    LoginModule,
    InicioModule,
    ProfileModule,
    PriceBoardModule,
    OrderListModule,
    GeneratePriceModule,
    GenerateManualOrderModule,
    GeneratePriceBoardOrderModule,
    GenerateExchangeModule,
    OrdersValidationModule,
    EraserModule,
    ExchangeListModule,
    HistoricalPageModule,
    ControlPanelModule,

    MatDialogModule,
  ],
  providers: [{
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '313888454615-f6tnmff1964pee1ch3hdqfbf4v115r5i.apps.googleusercontent.com'
          )
        }
      ],
      onError: (err) => {
        console.error(err);
      }
    } as SocialAuthServiceConfig,
  },
  { provide: MAT_DATE_LOCALE, useValue: 'es' },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  SessionChecker, DataChecker, ValidatorChecker],
  bootstrap: [AppComponent],
})
export class AppModule { }
