<div class="form" #formulario>
    <div class="buttons validation">
        <button [ngClass]="{'selected':numOfStep==1}" class="step" (click)="changeStep(1, formulario)">Paso 1</button>
        <button [ngClass]="{'selected':numOfStep==2}" class="step" (click)="changeStep(2, formulario)">Paso 2</button>
        <button [ngClass]="{'selected':numOfStep==3}" class="step" (click)="changeStep(3, formulario)">Paso 3</button>
        <button [ngClass]="{'selected':numOfStep==4}" class="step" (click)="changeStep(4, formulario)"
            *ngIf="isGenerateToFixValidator">Paso 4</button>
        <div class="rectangle step-{{numOfStep}}"></div>
    </div>

    <div class="delete-all gap-8 validation">
        <span (click)="deleteStep(numOfStep)">Borrar paso {{numOfStep}}</span>
    </div>

    <span class="nro-order-vta">Orden de venta N°{{indexToEdit +1}}</span>
    <span class="seller-name caption">Vendedor: {{getSellerNameAndTons(orderToEdit)}}</span>

    <div class="paso paso1" [ngClass]="{'visible': numOfStep==1, 'invisible': numOfStep!=1 }">
        <app-buyer [fieldIdentificator]="'validation'"></app-buyer>
        <app-type-dolar [fieldIdentificator]="'validation'" *ngIf="isInDolars(orderToEdit)"></app-type-dolar>
        <app-grouper-code [fieldIdentificator]="'validation'"></app-grouper-code>
        <app-business-particularities [fieldIdentificator]="'validation'"></app-business-particularities>
        <app-tons [fieldIdentificator]="'validation'"></app-tons> <!-- (tab)="changeStep(2)" -->
        <app-quotas [fieldIdentificator]="'validation'"></app-quotas>
        <app-parameterized-observations [fieldIdentificator]="'validation'"
    (focusEvent)="focusField($event, formulario)"></app-parameterized-observations>
    </div>

    <div class="paso paso2" [ngClass]="{'visible': numOfStep==2, 'invisible': numOfStep!=2 }">
        <app-business-type [fieldIdentificator]="'validation'"></app-business-type>
        <app-product-name [fieldIdentificator]="'validation'"></app-product-name>
        <app-price [fieldIdentificator]="'validation'" *ngIf="!isGenerateToFixValidator"></app-price>
        <app-type-coin [fieldIdentificator]="'validation'" *ngIf="!isGenerateToFixValidator"></app-type-coin>
        <app-place-of-delivery [fieldIdentificator]="'validation'"
        [requirePort]="orderToEdit.price.placeOfDelivery.zone>7"
        [incompleteRequirePort]="requirePort()"></app-place-of-delivery>
        <app-quality [fieldIdentificator]="'validation'"(tab)="changeStep(3, formulario)" (addQuality)="showQualities($event)"></app-quality>
        <app-quality-particularities [fieldIdentificator]="'validation'" *ngIf="showPartQuality || price.qualityIBM>0"></app-quality-particularities>
    </div>

    <div class="paso paso3" [ngClass]="{'visible': numOfStep==3, 'invisible': numOfStep!=3 }">
        <app-delivery-period [fieldIdentificator]="'validation'" [isGenerateToFix]="isGenerateToFixValidator"></app-delivery-period>
        <app-harvest [fieldIdentificator]="'validation'"></app-harvest>
        <app-way-pay [fieldIdentificator]="'validation'" [isGenerateToFix]="isGenerateToFixValidator"></app-way-pay>
        <app-expiration [fieldIdentificator]="'validation'" *ngIf="!isWayPayInvalidToExpiration(orderToEdit?.price?.wayPay) && !isGenerateToFixValidator"></app-expiration>
        <app-pesification [fieldIdentificator]="'validation'" *ngIf="isInDolars(orderToEdit) || isGenerateToFixValidator"></app-pesification>
    </div>

    <div class="paso paso4" [ngClass]="{'visible': numOfStep==4, 'invisible': numOfStep!=4 }" *ngIf="isGenerateToFixValidator"> <!-- && Agregar validación si queremos ocultar el campo -->
        <app-condition-type [fieldIdentificator]="'validation'"></app-condition-type>
        <app-to-fix-indicators [fieldIdentificator]="'validation'" [indicators]="orderToEdit.price.indicators" 
        [conditionType]="orderToEdit.price.conditionType"></app-to-fix-indicators>
    </div>

    <div class="button-container">
        <button class="not-filled-button" (click)="changeStep(numOfStep+1, formulario)" 
        *ngIf="getNumMaxOfStep()!=numOfStep">Siguiente</button>
    </div>
</div>
