export const NAVEGATION:Navegation ={
    ACCESS:'access',
    HOME:'home',
    PROFILE:'profile',
    PRICE_BOARD:'price-board',
    ORDER_BOARD:'order-board',
    GENERATE_MANUAL_ORDER:'generate-manual-order',
    GENERATE_PRICE_BOARD_ORDER:'generate-price-board-order',
    GENERATE_PRICE:'generate-price',
    GENERATE_EXCHANGE:'generate-exchange',
    WORKSHEET:'worksheet',
    ORDER_EXCHANGE:'order-exchange',
    ORDER_VALIDATION:'order-validation',
    ERASER:'eraser',
    CONTROL_PANEL: 'control-panel',
    HISTORICAL: 'historical',
    NOT_FOUND:'**'    
}

export interface Navegation {
    ACCESS:string;
    HOME:string;
    PROFILE:string;
    PRICE_BOARD:string;
    ORDER_BOARD:string;
    GENERATE_MANUAL_ORDER:string;
    GENERATE_PRICE_BOARD_ORDER:string;
    GENERATE_PRICE:string;
    GENERATE_EXCHANGE:string;
    WORKSHEET:string;
    ORDER_EXCHANGE:string;
    ORDER_VALIDATION:string;
    CONTROL_PANEL:string;
    NOT_FOUND:string;
    ERASER:string;
    HISTORICAL:string;
}

