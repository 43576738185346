import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { areas } from 'src/app/shared/const/user.const';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { resetOrderToGenerate, setIndexToEdit, setOrdersToGenerate, setValidation } from 'src/app/redux/actions/order.action';
import { resetOrderExchangeToGenerate, setEditGenerateOrderExchange, setIsGenerateOrderExchange } from 'src/app/redux/actions/exchange.action';
import { setIsGenerateToFix } from 'src/app/redux/actions/options.action';
import { validationInitialState } from 'src/app/shared/const/orders';
import { OBJECT_ID_VOID } from 'src/app/shared/const/options';
import { Subscription } from 'rxjs';
import { ToFixValidator } from 'src/app/shared/to-fix';


@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.scss']
})

export class DialogOrderExchangeCreate implements OnInit, OnDestroy {
  hour: string = '';
  mensaje: string = 'Los datos del canje están listos para generarse';
  moreInfo: string = '';
  isConfirm: boolean = true;

  //Orders
  identificatorOrderEdit: number = -1;
  disabledButton: boolean = true;

  private user!: User; //Store of Redux.
  public orders: Order[] = [];
  isComercial: boolean = true;
  order!: OrderExchange;
  isGenerateOrderExchange: boolean = false;

  private exchangeSubscription!: Subscription;
  private userSubscription!: Subscription;

  constructor(private router: Router, private store: Store<appState>, public date: DateChecker,
    public dialogRef: MatDialogRef<DialogOrderExchangeCreate>, private toFixV:ToFixValidator, private orderV: OrderValidator, private exchangeV: OrderExchangeValidator) {
    this.hour = this.date.setHourFormat();
    this.moreInfo = '';
  }

  ngOnInit(): void {
    this.exchangeSubscription = this.store.select('exchange').subscribe(exchange => {
      this.order = exchange.generateOrder;
    })

    this.userSubscription = this.store.select('usuario').subscribe(user => {
      this.user = user;
      this.isComercial = user.area == areas.comercial;
    })
  }

  ngOnDestroy(): void {
    this.exchangeSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  sendOrderExchange() {
    let newOrder = JSON.parse(JSON.stringify(this.order));
    const isToPrice = !this.toFixV.orderHaveIndicators(newOrder);
    newOrder.exchange=this.exchangeV.getProformsOfExchange(newOrder.exchange, this.order?.sellerData?.codeS ?? 0,isToPrice);
    newOrder.exchange.idMother = OBJECT_ID_VOID;

    this.exchangeV.saveExchange(newOrder.exchange, this.user, newOrder);
    this.router.navigate(['/' + NAVEGATION.ORDER_EXCHANGE])
    this.resetAllOrders();
    this.dialogRef.close();
  }

  resetAllOrders() {
    //Reset generate order
    this.store.dispatch(resetOrderToGenerate());

    //Reset generate orders
    this.store.dispatch(setOrdersToGenerate({ orders: [this.order] }));
    this.store.dispatch(setIndexToEdit({ indexToEdit: 0 }));

    //Reset validation
    this.store.dispatch(setValidation({ validation: validationInitialState }));
    //Reset generate order exchange
    this.store.dispatch(resetOrderExchangeToGenerate());

    this.store.dispatch(setEditGenerateOrderExchange({ isEditGenerate: false }));
    this.store.dispatch(setIsGenerateOrderExchange({ isGenerateOrderExchange: false }));
    this.store.dispatch(setIsGenerateToFix({ isGenerateToFix: false }));

    //Reset generate order to fix
  }

}


