export const WAY_PAY_OV_IBM:any = {
    0:0,

    //--- A precio ---\\
    2: 212,
    18: 15,
    44: 117,


    //--- A fijar ---\\
    19: 219,
    17: 149,
    60: 99,
}