import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { ExchangeService } from 'src/app/shared/services/exchange.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { PriceService } from 'src/app/shared/services/price.service';
import { getExchangesByDate, getExchangesByRangeDate, getOrdersByDate, getOrdersByRangeDate, getPricesByDate, setArrayOfHistoricalFailed, setArrayOfHistoricalSuccess } from '../actions/historical.action';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { setErrorStruct } from '../actions/options.action';

@Injectable()
export class HistoricalEffects {
  constructor(private actions$: Actions, private orderSvc: OrderService, private priceSvc:PriceService, private exchangeSvc: ExchangeService) { }

  getOrderListEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOrdersByDate),
      
      mergeMap((action) => {
        return this.orderSvc.getOrdersByDate(action.date).pipe(

          map((res:ResponseRequest) => {
            try {
              return setArrayOfHistoricalSuccess({ array: res.data? res.data: [] });
            } catch (error: any) {
              const errorMessage = error.message;
              return setArrayOfHistoricalFailed({error: ""});
            }
          }),
          catchError((error) => {
            console.error(error.error)
            return of(setArrayOfHistoricalSuccess({ array: [] }));
            return of(setErrorStruct({error: {color: '#fff', isVisible:true, message: 'No hay datos para mostrar'}}));
          })
        );
      })
    );
  });

  getOrderRangeListEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOrdersByRangeDate),
      mergeMap((action) => {
        return this.orderSvc.getOrdersByRange(action.date1, action.date2).pipe(
          map(( res: ResponseRequest ) => {
            try {
              return setArrayOfHistoricalSuccess({ array: res.data? res.data: [] });
            } catch (error: any) {
              const errorMessage = error.message;
              return setArrayOfHistoricalFailed({error: ""});
            }
          }),
          catchError((error) => {
            console.error(error.error)
            return of(setArrayOfHistoricalSuccess({ array: [] }));
            return of(setErrorStruct({error: {color: '#fff', isVisible:true, message: 'No hay datos para mostrar'}}));
          })
        );
      })
    );
  });

  //---------------- EXCHANGES ----------------\\

  getExchangeListEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getExchangesByDate),
      mergeMap((action) => {
        return this.exchangeSvc.getExchangesByDate(action.date).pipe(
          map((res:ResponseRequest) => {
            try {
              return setArrayOfHistoricalSuccess({ array: res.data? res.data: [] });
            } catch (error: any) {
              const errorMessage = error.message;
              return setArrayOfHistoricalFailed({error: ""});
            }
          })
        );
      })
    );
  });

  getExchangeRangeListEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getExchangesByRangeDate),
      mergeMap((action) => {
        return this.exchangeSvc.getExchangesByRange(action.date1, action.date2).pipe(
          map((res:ResponseRequest) => {
            try {
              return setArrayOfHistoricalSuccess({ array: res.data? res.data: [] });
            } catch (error: any) {
              const errorMessage = error.message;
              return setArrayOfHistoricalFailed({error: ""});
            }
          })
        );
      })
    );
  });

  //---------------- PRICES ----------------\\

  getPriceListEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPricesByDate),
      mergeMap((action) => {
        return this.priceSvc.getPricesByDate(action.date).pipe(
          map((response:ResponseRequest) => {
            try {
              return setArrayOfHistoricalSuccess({ array: response.data? response.data: [] });
            } catch (error: any) {
              const errorMessage = error.message;
              return setArrayOfHistoricalFailed({error: ""});
            }
          })
        );
      })
    );
  });
}
