import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsOrdersComponent } from './views-orders.component';
import { OptionModule } from '../option/option.module';
import { FormsModule } from '@angular/forms';
import { CheckSvgModule } from 'src/app/shared/styles/svg/check/check.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { EditSvgModule } from 'src/app/shared/styles/svg/edit/edit-svg.module';

@NgModule({
  declarations: [
    ViewsOrdersComponent
  ], imports: [
    CommonModule,
    OptionModule,
    FormsModule,
    CheckSvgModule,
    ArrowSvgModule,
    EditSvgModule
  ], exports:[
    ViewsOrdersComponent
  ]
})

export class ViewsOrdersExchangeModule { }