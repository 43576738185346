import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'generate-order-svg',
  templateUrl: './generate-order.component.html',
  styleUrls: ['./../../svg.scss']
})
export class GenerateOrderComponent {

  @Input() active:boolean=false;
  constructor() { }
}
