<div class="field">
    <span class="field-title">Corredora<span class="asterisk">*</span></span>

    <div>
        <div class="radios-buttons">
            <mat-radio-group>
                <mat-radio-button class="radio-button" [value]="'grassi'" [disabled]="!validsBroker.includes(1)"
                (click)="setIsGrassi(true)" [checked]="brokerSelected==1" (keyup)="setIsGrassiEvent(true, $event)"> 
                    <span class="radio grassi" [ngClass]="{'selected-radio': brokerSelected==1}" >Grassi S.A.</span>
                </mat-radio-button>
                <mat-radio-button class="radio-button" [value]="'ggsa1888'" [disabled]="!validsBroker.includes(2)"
                (click)="setIsGrassi(false)" (keyup)="setIsGrassiEvent(false,$event)" [checked]="brokerSelected==2" >
                    <span class="radio" [ngClass]="{'selected-radio': brokerSelected==2}">GG 1888</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <span class="text-is-incomplete" *ngIf="getIsInvalid()">No podemos actuar como corredores en dicha jurisdicción.</span>

</div>