<div class="body">
    <div class="header">
        <span class="add-buyer title">Generar borrador</span>
        <cross-svg class="cross" (click)="closeAddBuyer()"></cross-svg>
    </div>

    <!--<p class="description">Tienes <b>{{getStringOrders(ordersSelected.length)}}</b>, 
        completá el comprador y el tipo de dolar para generar el borrador.</p>-->
    <p class="description">Tienes <b>{{getStringOrders(ordersSelected.length)}}</b>, 
    completá los datos para generar el borrador.</p>
    
    <div class="width-100 column-left gap-8">

        <div class="fields-container width-100 column-left">
            <app-buyer class="field" [fieldIdentificator]="'add-buyer'"  [isRequired]="true"
            [valueToSet]="getBuyerSelected()" (buyerEvent)="saveBuyerSelected($event)"></app-buyer>
            <span class="message-error" *ngIf="isDisabledButton()">Procedencia no admitida para dicho comprador.</span>
            <span class="message-error" *ngIf="isExensionDeIVAWithCommodities()">Commodities no puede comprar con Exención de IVA.</span>
    
            <app-form-proform-buyer *ngIf="orderToAnalizateProform && ordersCheck.length>0 && !isProductionFunc()" class="buyer-section width-100" 
                [proform]="structBuyer" [order]="orderToAnalizateProform" [orderOriginal]="orderOriginal"
                [buyer]="buyerSelectedField" [actionProform]="actionProform"
                (actualizate)="actualizateStructBuyer($event.newOrder)"
                (action)="saveNewOrderWithProform($event)"></app-form-proform-buyer>
    
            <app-type-dolar class="field" [fieldIdentificator]="'add-buyer'" [valueToSet]="getDolarTypeSelected()" 
            (dolarEvent)="saveDolarTypeSelected($event)" *ngIf="showTypeDolar()"></app-type-dolar>
    
            <app-grouper-code class="field" [fieldIdentificator]="'add-buyer'" [valueToSet]="getGrouperCodeSelected()"
            (grouperEvent)="saveGrouperCodeSelected($event)"></app-grouper-code>
            
            <span class="message-error" *ngIf="grouperCodeSelectedField<0">Se debe colocar un código agrupador.</span>
        </div>
    
        <div class="line grey"></div>
    
        <div class="select-all-container"*ngIf="getOrdersSelectedWithPipe().length>0">
            <input type="checkbox" [checked]="isAllChecked()" (click)="selectAllOrders()"/>
            <span class="select-all">Seleccionar todas las ordenes</span>
        </div>
        <div class="line grey"></div>
        <div class="orders-description margin-bottom-120">
        
            <div *ngFor="let order of ordersSelected | withOutContractStatus; index as i">
                <div class="acordion">
                    <div class="names">
                        <input type="checkbox" class="input-check" [value]="order" [checked]="isOrderCheck(order)" (click)="addOrRemoveOrder(order)" />
                        <!--<span class="order-type">Orden {{ORDER_TYPES[order.orderDestination.orderType]}}</span>-->
                        <span class="order-type">Orden de venta</span>
                        <span class="order">Vendedor: {{getSellerData(order)}}</span>
                        <span class="creator">Creada por {{order.dataOfcreation.nameOfcreator}} a las {{order.dataOfcreation.hour}}</span>
                    </div>
    
                    <arrow-svg class="arrow" [direction]="getArrowDirection(i)" (click)="setIndexToOpen(i)"></arrow-svg>
                </div>
    
                <div class="acordion" *ngIf="indexToOpen==i">
                    <app-details-small class="width-100" [order]="order" [price]="order.price" [isIndicatorsInThreeColumns]="true"></app-details-small>
                </div>
    
                <div class="line grey"></div>
            </div>
        </div>
    </div>

    <div class="buttons-add-buyer bottom-fixed">   
        <span class="message-error" *ngIf="showMessageEdit">No se han seleccionado ordenes para editar.</span>
        <button class="not-filled-button not-visible-in-mobile" (click)="goToValidations()">Editar</button>

        <button class="filled-button" (click)="applyChanges()" 
        [ngClass]="{'disabled': isButtonDisabled()}" [disabled]="isButtonDisabled()">Generar borrador</button>
        <p class="description message-error" *ngIf="isClicked && ordersCheck.length==0">Debes seleccionar alguna orden.</p>
    </div>
    
</div>