import { Component, OnChanges, Input } from '@angular/core';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { FINANCIAL_ADNAVANCE_VOID } from 'src/app/shared/const/business-particularities';
import { FinancialAdvance } from 'src/app/shared/interfaces/price-to-fix.interface';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-anticipo-financiero',
  templateUrl: './anticipo-financiero.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class FinancialAdvanceComponent extends BusinessParticularityOption implements OnChanges {
  
  public financialAdvance:FinancialAdvance=FINANCIAL_ADNAVANCE_VOID;
  public withIVA:number=0;
  public isRate:number=0;

  constructor(private bussPartV:BusinessParticularitiesValidator) {
    super()
  }

  ngOnChanges(): void {
    try{
      this.financialAdvance=this.businessParticularitiesStruct.financialAdvance;
      this.withIVA=this.financialAdvance.VAT;
      this.isRate=this.financialAdvance.rate;
    } catch(err){
      this.financialAdvance=FINANCIAL_ADNAVANCE_VOID;
      this.withIVA=0;
      this.isRate=0;
    }
  }

  setFinancialAdvance(financialAdvance:FinancialAdvance){
    this.financialAdvance=financialAdvance;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.financialAdvance=this.financialAdvance;
    this.emit2(newBP);
  }

  //------------ RATE ----------------\\
  setRate(){
    const numToSet= this.isRate==0?1:0;
    let newBP= JSON.parse(JSON.stringify(this.financialAdvance))
    newBP.rate=numToSet;
    this.setFinancialAdvance(newBP);
  }

  //------------ ALL VALUES ----------------\\
  setValueWithTag(value:any, tag:string){
    let newBP= JSON.parse(JSON.stringify(this.financialAdvance))
    newBP[tag]=value;
    this.setFinancialAdvance(newBP);
  }

  verifyFinancialAdvance(financialAdvance:FinancialAdvance){
    if(JSON.stringify(financialAdvance) != JSON.stringify(FINANCIAL_ADNAVANCE_VOID)){
      this.setFinancialAdvance(FINANCIAL_ADNAVANCE_VOID); //Reset bonification
    }
  }

  getIsFinancialAdvance(){
    return this.bussPartV.getIsFinancialAdvance(this.businessParticularitiesStruct);
  }
}