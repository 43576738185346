import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicatorsListComponent } from './indicators-list.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';



@NgModule({
  declarations: [
    IndicatorsListComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule
  ], exports: [
    IndicatorsListComponent
  ]
})
export class IndicatorsListModule { }
