import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-product-name',
  templateUrl: './product-name.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class ProductNameComponent extends Field implements OnInit {

  constructor(store: Store<appState>) {
    super(store);
    this.OBJECT=PRODUCT_NAMES;
    this.array = Object.keys(PRODUCT_NAMES);
    this.array.shift();
    this.deleteLastValueInArray();
    this.initalElementSelected = 'Ingresar un producto';
    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.productName;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.productName;
    }
    this.setIdentifyClassName();
  }
  override setElement(element:any, index:number){
    this.setChangeShowOptions(false); //Ocultamos las options
    this.setElementToGenerate(Number(element));
  }

  override rememberValueInForm(){
    this.rememberValueWithObject();
  }


  
}
