import { Component, OnInit,OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DialogOrderCreate } from 'src/app/components/dialogs/order-create/dialog';
import { setIsGenerateToFix } from 'src/app/redux/actions/options.action';
import { setCommisionInOrder, setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { conditionType } from 'src/app/shared/const/options';
import { orderCategoryConst } from 'src/app/shared/const/orders';
import { WAY_PAY_TO_FIX_DEFAULT, WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Commission, Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';

const SIZE_OF_VIEW_MOBILE = 950;

@Component({
  selector: 'app-body-generate-order',
  templateUrl: './body-generate-order.component.html',
  styleUrls: [ './../../../shared/styles/body-generate.scss',
    './../../generate-price/body-generate-price/body-generate-price.component.scss', './body-generate-order.component.scss',]
})
export class BodyGenerateOrderComponent implements OnInit, OnDestroy {
  //It serves to identify if the user pressed 'Orden de venta' or 'Orden de compra'
  public isOrderSell: boolean = true;

  //It is a boolean that represents if the button is disabled or not
  disabledButton: boolean = true;

  //It is a boolean that represents if the details are being displayed or not
  detailsVisible: boolean = false;

  //It is a boolean that represents if the form is being displayed or not
  formVisible: boolean = true;

  //Cards
  isOpenCommission:boolean = false;
  isOpenDuplicate:boolean = false;
  
  count:number=0;
  comissionEnabled: boolean = false;
  duplicateOrderEnabled: boolean = false;


  public orderToGenerate!: Order;
  public quantOrdersToGenerate: number = 1;

  isGenerateOrderToFix: boolean = false;
  conditionTypes=conditionType;

  private order$!:Subscription;
  private sizeView$!:Subscription;
  
  constructor(private store: Store<appState>, private orderV:OrderValidator, private dialog:MatDialog) {}

  ngOnInit(): void {    
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.order$?.unsubscribe();
    this.sizeView$?.unsubscribe();
  }

  setSubscriptions(){
    this.order$=this.store.select('order').subscribe(orderR => {
      this.orderToGenerate = orderR.generateOrder;
      this.quantOrdersToGenerate=orderR.generateOrders.length;
      this.isOrderSell = orderR.generateOrder.orderDestination.orderCategory == orderCategoryConst.orderSale;

      this.disabledButton=this.orderV.isOrderIncomplete(this.orderToGenerate);
      this.comissionEnabled = this.duplicateOrderEnabled = !this.disabledButton;
    })

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.showDetailsAndForm(sizeView);
    });
  }

  //It serves to identify if the user pressed 'Commodities' or 'Exporter'
  setIsOrderSell(isOrderSell: boolean) {
    this.store.dispatch(setOrderToGenerate({
      order: {
        ...this.orderToGenerate,
        orderDestination: {
          ...this.orderToGenerate.orderDestination,
          orderCategory: isOrderSell? orderCategoryConst.orderSale: orderCategoryConst.orderPurchase
        }
      }
    }))
  }

  //It serves to identify if the user pressed 'A precio' or 'A fijar'
  setIsOrderToPrice(conditionType:any) {
    const isOrderToPrice= conditionType==this.conditionTypes.TO_PRICE;

    let newOrder:Order=JSON.parse(JSON.stringify(this.orderToGenerate));
    if(isOrderToPrice){
      newOrder.price.indicators=[];
      newOrder.price.conditionType=0;
      newOrder.price.wayPay=WAY_PAY_VOID;
      newOrder.price.grouperCode=1;
    } else {
      newOrder.price.typeCoin='--';
      newOrder.price.price=-1;
      newOrder.price.wayPay=WAY_PAY_TO_FIX_DEFAULT;
      newOrder.price.grouperCode=0;
    }
    this.store.dispatch(setOrderToGenerate({order: newOrder}))
  }

  setCommission(event:Commission){
    this.store.dispatch(setCommisionInOrder({commission:event}));
  }

  showDetails(showDetails: boolean) {
    this.detailsVisible = showDetails;
    this.formVisible = !showDetails;
    this.count++;
  }

  showDetailsAndForm(sizeView: number) {
    if (sizeView > SIZE_OF_VIEW_MOBILE) {
      this.detailsVisible = true;
      this.formVisible = true;
      this.count=0
    } else if(this.count==0) {
      this.detailsVisible=false;
      this.formVisible=!this.detailsVisible
    }
  }

  //-------- CARDS --------\\
  openCommission(){
    /*if(this.comissionEnabled){
      this.isOpenCommission=!this.isOpenCommission;
      this.isOpenDuplicate=false;
    }*/
  }
  openDuplicateOrder(){
    if(this.duplicateOrderEnabled){
      this.isOpenDuplicate=!this.isOpenDuplicate;
      this.isOpenCommission=false;
    }
  }

  closeCommission(event:any){
    this.isOpenCommission= event!='close';
  }

  closeDuplicateOrder(event:any){
    this.isOpenDuplicate= event=='open';
  }

  getCommisionBuyer(order:Order){
    return order?.commision?.buyer>0? order.commision.buyer: 0;
  }

  getCommisionSeller(order:Order){
    return order?.commision?.seller>0? order.commision.seller: 0;
  }

  //-----------------------\\
  confirmOrder(){
    this.dialog.open(DialogOrderCreate);
  }
}