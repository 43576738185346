import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'placeholder'
})

export class PlaceHolderPipe implements PipeTransform {
  /*Given a string and an object, transform the values of the object into an array and return the element that starts as the string*/
  transform(value: string, object: any) {
    try{
      const valueLowerCase=value.toLowerCase();
      const result:any=this.getFirstElementOfObject(valueLowerCase, object);
      return this.quitSameParts(valueLowerCase, result.toLowerCase());
    } catch(er){
      return '';
    }
  }


  /*Given string1 and string2, return string2, removing the FIRST part where they match.*/
  quitSameParts(string1: string, string2: string){
    try{
      let result:string = string2;
      let isBucleFinished:boolean = false;
      const quantLetters=string1.length;
      let i=0;
      while(i<quantLetters && !isBucleFinished){
        if(string1[i]==string2[i]){
          result=string2.substring(i+1);
        } else {
          isBucleFinished=true;
        }
        i++;
      }
      return result;
    } catch(er){
      return string2;
    }
  }

  public getFirstElementOfObject(value:string, object: any){
    const array = Object.values(object);
    const result:any = array.find((element:any) => element.toLowerCase().startsWith(value));
    return result;
  }

  public transformPlaceOfDelivery(object:any){
    const array = Object.entries(object);
    let objectToReturn:any=[];

    for(let i=0; i<array.length; i++){
      const element:any = array[i];
      const key = element[0];
      const value = element[1].name? element[1].name: element[1];
      objectToReturn.push(value);
    }

    return objectToReturn;
  }
}
