import { Component, OnInit,OnDestroy,ViewChild,ElementRef, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { setExchangeInOrderToGenerate, setIsGenerateOrderExchange, setOrderToGenerateInExchange } from 'src/app/redux/actions/exchange.action';
import { goToInitForm, setIdentificatorFieldSelected, setIsClickedInConfirm } from 'src/app/redux/actions/options.action';
import { setHowSellValidation, setOrderToGenerate, setRucaAndSisa, setValidPxQ } from 'src/app/redux/actions/order.action';
import { areas } from 'src/app/shared/const/user.const';
import { businessDict } from 'src/app/shared/dict/typeBusiness';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { Price, WayPay } from 'src/app/shared/interfaces/price.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { OrderValidator, resetAllOrders } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { PriceValidator } from 'src/app/shared/price';
import { DialogOrderCreate } from '../dialogs/order-create/dialog';
import { Exchange, OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { exchangeInitialState } from 'src/app/shared/const/exchange-orders';
import { getIsInvalidTown } from '../fields/place-of-origin/town/town.component';
import { Subscription } from 'rxjs';
import { HowSellValidation, PxQIBM, ResponseHowSell, ResponseIBM, ResponseRucaAndSisa, RucaAndSisa } from 'src/app/shared/interfaces/DataIBM';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';
import { isAnyInvalidBuyer } from '../fields/buyer-order/only-sell/only-sell.component';
import { SessionChecker } from 'src/app/shared/session.checker';
import { getIsWayPayDeferred, getWayPayCodeIBM } from 'src/app/shared/way-pay';
import { isIndicatorsIncomplete } from 'src/app/shared/to-fix';
import { isInvalidStartDate } from 'src/app/shared/date.checker';
import { getHowSellIBM, getImporteBonification, getTypeCoinBonificationIBM, getTypeCoinIBM } from 'src/app/shared/functions-ibm/contract.checker';
import { itCanBeHowSell } from 'src/app/shared/business-validation';
import { howSellValidationInitialState } from 'src/app/shared/const/orders';
import { WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { typeCoins } from 'src/app/shared/const/prices.const';

interface HowSellState{
  codeS:number;
  howSell:number;
  broker:number;
}

@Component({
  template:''
})
export class FormComponent implements OnInit, OnDestroy {
  @ViewChild("formulario") formulario!: ElementRef;
  @Output() applyButtonEvent= new EventEmitter<boolean>();
  public numOfStep:number=1;
  order!:any;
  onlyExchange!:OrderExchange;
  price!:Price;
  identificatorFieldSelected:number=-1;
  isClickedInConfirm:boolean=false;
  public user!:User;  
  public isOperator:boolean=false;
  public isComercial:boolean=false;
  public showPartQuality:boolean=false;
  disabledButton:boolean=false;
  
  public showExchangeOptions:boolean=false;

  //---------- Way pay default ----------\\
  public wayPayDefault:WayPay=WAY_PAY_VOID;
  public agreedDiscountDefault:number=0;

  isGenerateToFix:boolean=false;

  //---------- Validation Exchanger ----------\\
  public isExchangeOrder:boolean=false;

  //---------- Validation HowSell ----------\\
  public howSellValidation!:HowSellValidation;
  public rucaAndSisa!:RucaAndSisa;
  private lastStateHowSell:HowSellState|null=null;
  //public processHowSell:boolean=false;
  public inProcessRucaAndSisa:boolean=false;
  public isInvalidRucaAndSisa:boolean=false;
  public completed:boolean=false;
  public isInvalid:boolean=false;
  public failedConnection:boolean=false;
  public inProcessInvalid:boolean=false;


  //---------- Validation PxQ ----------\\
  public messagePxQ:string='';
  private lastStatePxQ:PxQIBM | null=null;
  private validPxQ!:string;
  
  //Subscriptions
  private vExchanger$!:Subscription;
  private vHowSell$!:Subscription;
  private vPxQ$!:Subscription;
  private vRucaAndSisa$!:Subscription;
  private price$!:Subscription;
  private order$!:Subscription;
  private exchange$!:Subscription;
  private options$!:Subscription;
  private user$!:Subscription;
  private isGenerateToFix$!:Subscription;

  constructor(public store:Store<appState>, public checker:SessionChecker, 
    public orderExcV:OrderExchangeValidator, public orderV:OrderValidator, public priceV:PriceValidator,  public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.store.dispatch(setIdentificatorFieldSelected({identificator:-1}));

    this.price$=this.store.select('price').subscribe(price=>{
      this.price=price.generatePrice;
    })

    this.vExchanger$=this.store.select('order','validation','isExchanger').subscribe(isExchanger=>{
      this.isExchangeOrder=isExchanger;
    });

    this.vHowSell$=this.store.select('order','howSellValidation').subscribe(howSellValidation=>{
      this.howSellValidation=howSellValidation;
      this.refreshDisabledOrder();
    });

    this.vPxQ$=this.store.select('order','validPxQ').subscribe(validPxQ=>{
      this.validPxQ=validPxQ;
      this.refreshDisabledOrder();
    });

    this.vRucaAndSisa$=this.store.select('order','rucaAndSisa').subscribe(rucaAndSisa=>{
      this.rucaAndSisa=rucaAndSisa;
      this.refreshDisabledOrder();
    });

    this.order$=this.store.select('order','generateOrder').subscribe(order=>{
      this.order=order;
      this.refreshDisabledOrder();
      this.functionsWithFormOrders(order);
      this.validateTons(this.order);
      this.validateHowSell(this.order);
    })

    this.exchange$=this.store.select('exchange').subscribe(exchange=>{
      this.showExchangeOptions=exchange.isGenerateOrderExchange;
      this.onlyExchange=exchange.generateOrder;
    })

    this.user$=this.store.select('usuario').subscribe(user=>{
      this.user=user;
      this.isComercial= user.area== areas.comercial;
      this.isOperator=user.area== areas.commodities;
    })

    this.isGenerateToFix$=this.store.select('options', 'isGenerateToFix').subscribe(toFix=>{
      this.isGenerateToFix=toFix;
    })

    this.options$=this.store.select('options').subscribe(options=>{
      this.identificatorFieldSelected=options.identificatorFieldSelectedInForm;
      if(options.goToInitForm){
        this.goToInitForm();
      }
    });

  }
  
  ngOnDestroy(): void {
    this.store.dispatch(setIsClickedInConfirm({isClickedInConfirm: false}));
    //Si el tamaño de la pantalla es de más de 950. Se resetean los valores de los campos de la orden
    if(window.innerWidth>950){
      resetAllOrders(this.store);
    }
    this.unsubscribe();
  }

  unsubscribe(){
    this.vExchanger$?.unsubscribe();
    this.vHowSell$?.unsubscribe();
    this.vPxQ$?.unsubscribe();
    this.vRucaAndSisa$?.unsubscribe();

    this.price$?.unsubscribe();
    this.order$?.unsubscribe();
    this.exchange$?.unsubscribe();
    this.options$?.unsubscribe();
    this.user$?.unsubscribe();
    this.isGenerateToFix$?.unsubscribe();
  }

  refreshDisabledOrder(){}

  loadUser(){
    this.checker.rememberAndActualizateUser(this.store)
  }

  changeStep(numOfStep:number, formulario:any){
    this.numOfStep=numOfStep;
    this.goToTop(formulario);
  }
  
  /* go to the top of the form */
  goToTop(formulario:any){
    if(formulario){
      formulario.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  goToInitForm(){
    this.changeStep(1,this.formulario.nativeElement);
    this.store.dispatch(goToInitForm({goToInitForm: false}));
  }

  getNumMaxOfStep(){
    if(this.isGenerateToFix && this.showExchangeOptions){
      return 5;
    } else if(this.isGenerateToFix || this.showExchangeOptions){
      return 4;
    } 
    return 3;
  }


  confirmOrder(formulario:any){
    if(this.isDisabledOrder(this.showExchangeOptions)){
      this.store.dispatch(setIsClickedInConfirm({isClickedInConfirm: true}));
      this.verifyElement(formulario);
    } else {
      if(this.isComercial || this.isOperator){
        this.dialog.open(DialogOrderCreate);
      } else {
        this.loadUser();
      }
    }
  }

  isDisabledOrder(isExchange: boolean = false){
    try{
      const commonChecks = !this.isCodePostalValid(this.order) || !this.isWayPayValid(this.order.price) ||
      isInvalidStartDate(this.order.price.deliveryPeriod.startDate) || this.invalidBuyer(this.order);

      return isExchange ? this.orderV.isOrderIncomplete(this.order) || this.orderExcV.isPriceExchangeInvalid(this.onlyExchange.exchange) || 
      //getWayPayCodeIBM(this.onlyExchange.exchange.wayPay, this.onlyExchange.exchange.typeCoin)==0 || 
      commonChecks || this.validPxQ?.toLowerCase()!='ok' : 
      this.orderV.isOrderIncompleteWithHowSellValidation(this.order, this.howSellValidation, this.rucaAndSisa, this.validPxQ) || commonChecks;
    } catch(err){}
    return true;
  }

  deleteStep(numOfStep:number){}

  deleteAll(numOfStep:number){}

  verifyElement(formulario:any){
    if(
      this.order.sellerData.codeS== -1 ||
      this.order.placeOfOrigin.province==-1 || this.order.placeOfOrigin.town==-1 || this.order.placeOfOrigin.afip==-1 ||
      this.order.broke== -1 ||
      this.invalidBuyer(this.order) ||
      this.order.howSell== -1 ||
      this.order.tons== -1){
        this.changeStep(1,formulario);
    } else if(
      this.order.price.typeBusiness== -1 ||
      this.order.price.productName== -1 ||
      (this.isGenerateToFix? false:this.order.price.price== -1 || this.order.price.typeCoin== '--') ||
      this.order.price.placeOfDelivery.zone== -1 ||
      this.requirePort() ||
      this.order.price.deliveryPeriod.endDate == '--' ||
      this.order.price.deliveryPeriod.startDate == '--' ||
      isInvalidStartDate(this.order.price.deliveryPeriod.startDate)
    ){
      this.changeStep(2,formulario);
    } else if(
      this.order.price.quality== -1||
      this.order.price.harvest== '--'
    ){
      this.changeStep(3,formulario);
    } else if(
      (this.order.price.indicators?.length>0? isIndicatorsIncomplete(this.order.price?.indicators, this.order.price?.conditionType):false)
    ){
      this.changeStep(4,formulario);
    }
  }
 
  showQualities(event:string){
    if(event=='add'){
      this.showPartQuality=true;
    }
  }

  setExchangeOptions(boolean:boolean){
    if(boolean){
      this.store.dispatch(setOrderToGenerateInExchange({order: this.order})); //Seteamos la orden
      let newExchange:Exchange=JSON.parse(JSON.stringify(exchangeInitialState)); //Creamos una contrapartida vacía
      newExchange=this.orderExcV.getExchangeInitial(this.order); //Obtenemos los datos por default de la contrapartida vacía.
      this.setPreviusWayPayAndAgreedDiscount(this.order, newExchange); //Seteamos el tipo de pago y descuento convenido que se asignó por default
      this.store.dispatch(setExchangeInOrderToGenerate({exchange: newExchange})); //Seteamos la contrapartida en el store

    } else {
      let exchangeWitoutExchange=JSON.parse(JSON.stringify(this.order));
      delete exchangeWitoutExchange.exchange;
      this.store.dispatch(setOrderToGenerate({order: exchangeWitoutExchange}));
    }
    this.store.dispatch(setIsGenerateOrderExchange({isGenerateOrderExchange: boolean}));
    setTimeout(()=>{
      this.changeStep(this.getNumMaxOfStep(),this.formulario.nativeElement);
    },10)
  }

  setPreviusWayPayAndAgreedDiscount(contractMother:Order, exchange:Exchange){
    this.wayPayDefault=contractMother.price.wayPay;
    this.agreedDiscountDefault=exchange.agreedDiscount;
  }

  isNideraOSemillas(){
    const codeS=this.order.sellerData.codeS;
    const subSyng=this.order.price.businessParticularities.subdivisionSyngenta;
    return this.orderV.isSyngentaNidera(codeS, subSyng) || this.orderV.isSyngentaSemillas(codeS, subSyng);
  }

  requirePort(){
    return this.priceV.isInvalidOptionalPort(this.order.price.placeOfDelivery);
  }

  isConDescarga(order:Order){
    return order.price.typeBusiness==businessDict.conDescarga;
  }

  isContraEntrega(wayPay:WayPay|undefined){
    return wayPay?.wayPayName==wayPayNames.contraEntrega;
  }

  isWayPayInvalidToExpiration(wayPay:WayPay|undefined){
    return this.isContraEntrega(wayPay) || getIsWayPayDeferred(wayPay?.wayPayName??0);
  }

  isInDolars(order:Order){
    return this.orderV.isInDolars(order);
  }

  getFieldType(){}

  isCodePostalValid(order:Order){
    return !getIsInvalidTown(order.placeOfOrigin)
  }

  isWayPayValid(price:Price){
    return this.priceV.getWayPayIBM(price) != 0;
  }

  invalidBuyer(order:Order){
    return isAnyInvalidBuyer(order.buyer.onlySell, order);
  }

  focusField(position:any, formulario:any){
    //Scrolleamos en el formulario hasta la posicion que se le pasa
    formulario.scrollTo({top: position, behavior: 'smooth'});
  }

  applyButtonEmit(bool:boolean){
    this.applyButtonEvent.emit(bool);
  }

  /*his function is used so that in the order form component, 
  a variable is set to true or false if the business is from Syngenta Agro AVC
  and if the business have 'valid until'*/
  functionsWithFormOrders(order:Order){}

  /**
   * Since at IBM there is a problem with the number of digits, a function was 
   * momentarily created that, passing certain data, tells you if that business 
   * would generate problems in the future.
   * @param order    
   * @returns 
   * */
  private validateTons(order:Order){
    let PxQ:PxQIBM | null=null;
    if(order.price){
      PxQ={
        CEREAL: order.price.productName,
        MONEDA: order.price.typeCoin == typeCoins.ARS ? 1 : order.price.typeCoin==typeCoins.USD ? 4 : 0,
        PRECIO: this.isGenerateToFix || order.price.price<=0? 0: order.price.price,
        TONELA: order.tons,
        IMPBON: getImporteBonification(order?.price?.businessParticularities),
        MONBON: getTypeCoinBonificationIBM(order?.price?.businessParticularities),
        PORBON: 0,
      }
    }

    if(PxQ && this.isValidPxQ(PxQ, this.isGenerateToFix) && !this.isEqualsPxQ(PxQ, this.lastStatePxQ)){
      this.messagePxQ='Validando toneladas...';
      this.lastStatePxQ=PxQ;

      console.log("Validando toneladas");

      this.orderV.getValidatePxQ(PxQ).then((res:ResponseIBM)=>{
        this.store.dispatch(setValidPxQ({validPxQ: res.status.status}));
        if(res.status.status!='ok'){
          this.messagePxQ=res.status.messageError.msg;
        } else {
          this.messagePxQ=''
        }
      })

    }
  }

  isValidPxQ(PxQ:PxQIBM | null, isToFix:boolean):boolean{
    if(PxQ){
      return ( (isToFix && PxQ.PRECIO<=0) || (!isToFix && PxQ.PRECIO>0) ) && 
      PxQ.TONELA>0 && PxQ.CEREAL>0 && 
      ((PxQ.MONEDA==1 && PxQ.PRECIO>1000) || (PxQ.MONEDA==4 && PxQ.PRECIO<1000) || (PxQ.MONEDA==0 && PxQ.PRECIO==0)) 
    }
    return false;
  }

  isEqualsPxQ(PxQ:PxQIBM | null, lastState:PxQIBM | null):boolean{
    if(PxQ && lastState){
      return PxQ.CEREAL==lastState.CEREAL && 
      PxQ.MONEDA==lastState.MONEDA && 
      PxQ.PRECIO==lastState.PRECIO && 
      PxQ.TONELA==lastState.TONELA && 
      PxQ.IMPBON==lastState.IMPBON &&
      PxQ.MONBON==lastState.MONBON &&
      PxQ.PORBON==lastState.PORBON;
    }
    return false;
  }

  private validateHowSell(order:Order){
    //const howSell=getHowSellIBM(order.howSell); //Obtenemos el valor de cómo vende de IBM
    const newState:HowSellState={ 
      codeS: order.sellerData.codeS,
      howSell: order.howSell,
      broker: order.broke
    }

    this.isInvalid=false; //Reiniciamos los valores de cómo vende
    this.isInvalidRucaAndSisa=false; //Reiniciamos los valores de cómo vende

    //Realizamos una actualización de cómo vende si es que no es igual al último estado y el vendedor NO es canjeador
    if(this.isValidState(newState) && !this.isEqualsHowSell(newState, this.lastStateHowSell) /*&& !this.isExchangeOrder*/){
        if(!this.inProcessRucaAndSisa){
          this.verifyHowSell(newState);
        }
    }

    this.lastStateHowSell=newState; 
    //Lo asignamos inmediatamente pase para que no se esté ejecutando varias veces mientras se hace la consulta
    //Otra solución es que esta función se llame solamente cuando se detecte un cambio en el vendedor o en el cómo vende
    //Y no escuchando los cambios en toda la orden.
  }
  
  verifyRucaAndSisa(state:HowSellState){
    this.inProcessRucaAndSisa=true;

    this.orderV.getValidateRucaAndSisa(state.codeS, state.howSell).then((res:ResponseRucaAndSisa)=>{
      console.log(res);
      this.store.dispatch(setRucaAndSisa({rucaAndSisa:res.data.msg}));
      this.isInvalidRucaAndSisa=!itCanBeHowSell(state.howSell, res.data.msg);
      this.inProcessRucaAndSisa=false; //No se procesa Ruca y Sisa
    });
  }

  verifyHowSell(state:HowSellState){
    this.inProcessInvalid=true;

    this.orderV.getValidateHowSell(state.codeS, state.howSell, state.broker).then((res:ResponseHowSell)=>{
      console.log(res);
      if(res.status.status=='ok'){
        this.store.dispatch(setHowSellValidation({howSellValidation: res.data.msg}));
        this.isInvalid=false; //No es una forma de venta inválida
        this.verifyRucaAndSisa(state);
      } else {
        this.isInvalid=true;
        this.store.dispatch(setHowSellValidation({howSellValidation: howSellValidationInitialState}));
        this.inProcessRucaAndSisa=false;
      }

      this.completed=true;
      this.inProcessInvalid=false;
      this.failedConnection=false;
      
    }).catch(err=>{
      this.store.dispatch(setHowSellValidation({howSellValidation: howSellValidationInitialState}));
      this.completed=true;
      this.isInvalid=true;
      this.failedConnection=true;

      this.inProcessInvalid=false;
    });
  }

  private isValidState(state:HowSellState):boolean{
    return state?.codeS>0 && state?.howSell>0 && state?.broker>0;
  }

  /*private isValidHowSell(codeIBM:string):boolean{
    //Si no está completo el como vende
    //O si en el ruca y sisa no tiene producción propia
    //O si no tiene el vendedor
    //O si no tiene el vendedor del ruca y sisa
    //Y estamos en el campo de orden y el proceso no corrió, entonces lo ejecutamos
    return true
    return (codeIBM != this.howSellValidation.ComoVende || codeIBM!=this.rucaAndSisa['Producción Propia'] 
    || !this.howSellValidation.Vendedor || !this.rucaAndSisa.Vendedor) 
    /*&& (howSell?.codeS ?? 0)>0 && (howSell?.howSell ?? 0)>0;
    /*&& (this.fieldIdentificator==fieldType.ORDER)
  }*/

  private isEqualsHowSell(state:HowSellState|null, lastState:HowSellState|null):boolean{
    if(state && lastState){
      return state.codeS==lastState.codeS && state.howSell==lastState.howSell;
    }
    return false;
  }
}