import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBuyerComponent } from './add-buyer.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BuyerModule } from '../../fields/buyer/buyer.module';
import { DetailsSmallModule } from '../../details/small-detail/details.module';
import { TypeDolarModule } from '../../fields/type-dolar/type-dolar.module';
import { GrouperCodeModule } from '../../fields/grouper-code/grouper-code.module';
import { FormProformBuyerModule } from '../../form/form-proform-buyer/form-proform-buyer.module';

@NgModule({
  declarations: [
    AddBuyerComponent
  ],
  imports: [
    CommonModule,
    BuyerModule,
    TypeDolarModule,
    GrouperCodeModule,
    FormProformBuyerModule,

    DetailsSmallModule,

    //Pipe,
    PipesModule,

    //SVG
    ArrowSvgModule,
    CrossSvgModule,
  ],
  exports: [
    AddBuyerComponent
  ],
})
export class AddBuyerModule { }
