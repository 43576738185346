import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialAdvanceComponent } from './anticipo-financiero.component';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';


@NgModule({
  declarations: [
    FinancialAdvanceComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule

  ], exports: [
    FinancialAdvanceComponent
  ]
})
export class FinancialAdvanceModule { }
