//CERCANJ TABLE
interface CERCANJ { [key: string]: { cgoBuyer: number } }

export function updateCercanj(data: CERCANJ) {
  CODE_BUYER_SELLER = data;
}

export let CODE_BUYER_SELLER:CERCANJ= {
    "102": {
      "cgoBuyer": 16
    },
    "149": {
      "cgoBuyer": 630
    },
    "159": {
      "cgoBuyer": 906
    },
    "162": {
      "cgoBuyer": 925
    },
    "166": {
      "cgoBuyer": 866
    },
    "167": {
      "cgoBuyer": 858
    },
    "169": {
      "cgoBuyer": 871
    },
    "171": {
      "cgoBuyer": 884
    },
    "174": {
      "cgoBuyer": 894
    },
    "182": {
      "cgoBuyer": 865
    },
    "206": {
      "cgoBuyer": 908
    },
    "231": {
      "cgoBuyer": 903
    },
    "294": {
      "cgoBuyer": 914
    },
    "298": {
      "cgoBuyer": 913
    },
    "350": {
      "cgoBuyer": 825
    },
    "411": {
      "cgoBuyer": 98
    },
    "828": {
      "cgoBuyer": 195
    },
    "831": {
      "cgoBuyer": 132
    },
    "895": {
      "cgoBuyer": 114
    },
    "915": {
      "cgoBuyer": 928
    },
    "989": {
      "cgoBuyer": 254
    },
    "1063": {
      "cgoBuyer": 920
    },
    "1204": {
      "cgoBuyer": 499
    },
    "1407": {
      "cgoBuyer": 674
    },
    "1475": {
      "cgoBuyer": 921
    },
    "1492": {
      "cgoBuyer": 513
    },
    "1585": {
      "cgoBuyer": 619
    },
    "2249": {
      "cgoBuyer": 722
    },
    "2266": {
      "cgoBuyer": 873
    },
    "2272": {
      "cgoBuyer": 875
    },
    "2293": {
      "cgoBuyer": 868
    },
    "2305": {
      "cgoBuyer": 859
    },
    "2335": {
      "cgoBuyer": 869
    },
    "2463": {
      "cgoBuyer": 893
    },
    "2661": {
      "cgoBuyer": 649
    },
    "2775": {
      "cgoBuyer": 966
    },
    "2790": {
      "cgoBuyer": 882
    },
    "2891": {
      "cgoBuyer": 965
    },
    "2913": {
      "cgoBuyer": 381
    },
    "2928": {
      "cgoBuyer": 89
    },
    "2943": {
      "cgoBuyer": 90
    },
    "3002": {
      "cgoBuyer": 222
    },
    "3046": {
      "cgoBuyer": 910
    },
    "3047": {
      "cgoBuyer": 917
    },
    "3055": {
      "cgoBuyer": 849
    },
    "3070": {
      "cgoBuyer": 863
    },
    "3220": {
      "cgoBuyer": 263
    },
    "3286": {
      "cgoBuyer": 163
    },
    "3351": {
      "cgoBuyer": 872
    },
    "3370": {
      "cgoBuyer": 855
    },
    "3377": {
      "cgoBuyer": 879
    },
    "3460": {
      "cgoBuyer": 861
    },
    "3476": {
      "cgoBuyer": 494
    },
    "3525": {
      "cgoBuyer": 964
    },
    "3579": {
      "cgoBuyer": 972
    },
    "3641": {
      "cgoBuyer": 627
    },
    "3673": {
      "cgoBuyer": 962
    },
    "3696": {
      "cgoBuyer": 237
    },
    "3771": {
      "cgoBuyer": 902
    },
    "3786": {
      "cgoBuyer": 887
    },
    "3814": {
      "cgoBuyer": 909
    },
    "3877": {
      "cgoBuyer": 890
    },
    "3885": {
      "cgoBuyer": 897
    },
    "3902": {
      "cgoBuyer": 955
    },
    "3937": {
      "cgoBuyer": 918
    },
    "3972": {
      "cgoBuyer": 214
    },
    "3973": {
      "cgoBuyer": 217
    },
    "3974": {
      "cgoBuyer": 219
    },
    "3975": {
      "cgoBuyer": 220
    },
    "3976": {
      "cgoBuyer": 223
    },
    "3977": {
      "cgoBuyer": 224
    },
    "3978": {
      "cgoBuyer": 225
    },
    "3980": {
      "cgoBuyer": 227
    },
    "3981": {
      "cgoBuyer": 229
    },
    "3982": {
      "cgoBuyer": 231
    },
    "3983": {
      "cgoBuyer": 235
    },
    "3984": {
      "cgoBuyer": 249
    },
    "3987": {
      "cgoBuyer": 256
    },
    "3988": {
      "cgoBuyer": 258
    },
    "3989": {
      "cgoBuyer": 271
    },
    "3991": {
      "cgoBuyer": 306
    },
    "3992": {
      "cgoBuyer": 348
    },
    "4070": {
      "cgoBuyer": 856
    },
    "4080": {
      "cgoBuyer": 922
    },
    "4125": {
      "cgoBuyer": 694
    },
    "4247": {
      "cgoBuyer": 934
    },
    "4369": {
      "cgoBuyer": 80
    },
    "4486": {
      "cgoBuyer": 959
    },
    "4490": {
      "cgoBuyer": 904
    },
    "4592": {
      "cgoBuyer": 878
    },
    "4594": {
      "cgoBuyer": 886
    },
    "4608": {
      "cgoBuyer": 907
    },
    "4635": {
      "cgoBuyer": 915
    },
    "4691": {
      "cgoBuyer": 975
    },
    "4741": {
      "cgoBuyer": 48
    },
    "4750": {
      "cgoBuyer": 854
    },
    "4780": {
      "cgoBuyer": 876
    },
    "4810": {
      "cgoBuyer": 877
    },
    "4820": {
      "cgoBuyer": 857
    },
    "4914":{
      "cgoBuyer": 1746
    },
    "4916":{
      "cgoBuyer": 1152
    },
    "4981": {
      "cgoBuyer": 982
    },
    "4988": {
      "cgoBuyer": 528
    },
    "4989": {
      "cgoBuyer": 530
    },
    "5012": {
      "cgoBuyer": 524
    },
    "5013": {
      "cgoBuyer": 525
    },
    "5014": {
      "cgoBuyer": 536
    },
    "5015": {
      "cgoBuyer": 540
    },
    "5016": {
      "cgoBuyer": 542
    },
    "5017": {
      "cgoBuyer": 541
    },
    "5018": {
      "cgoBuyer": 543
    },
    "5019": {
      "cgoBuyer": 544
    },
    "5021": {
      "cgoBuyer": 546
    },
    "5022": {
      "cgoBuyer": 550
    },
    "5024": {
      "cgoBuyer": 552
    },
    "5025": {
      "cgoBuyer": 554
    },
    "5026": {
      "cgoBuyer": 556
    },
    "5027": {
      "cgoBuyer": 559
    },
    "5028": {
      "cgoBuyer": 561
    },
    "5029": {
      "cgoBuyer": 563
    },
    "5030": {
      "cgoBuyer": 565
    },
    "5031": {
      "cgoBuyer": 255
    },
    "5032": {
      "cgoBuyer": 349
    },
    "5033": {
      "cgoBuyer": 308
    },
    "5034": {
      "cgoBuyer": 226
    },
    "5035": {
      "cgoBuyer": 279
    },
    "5036": {
      "cgoBuyer": 37
    },
    "5098": {
      "cgoBuyer": 290
    },
    "5124": {
      "cgoBuyer": 609
    },
    "5168": {
      "cgoBuyer": 171
    },
    "5467": {
      "cgoBuyer": 773
    },
    "5568": {
      "cgoBuyer": 798
    },
    "5611": {
      "cgoBuyer": 864
    },
    "5674": {
      "cgoBuyer": 889
    },
    "5736": {
      "cgoBuyer": 179
    },
    "5755": {
      "cgoBuyer": 317
    },
    "5850": {
      "cgoBuyer": 557
    },
    "6008": {
      "cgoBuyer": 338
    },
    "6138": {
      "cgoBuyer": 527
    },
    "6177": {
      "cgoBuyer": 129
    },
    "6237": {
      "cgoBuyer": 624
    },
    "6390": {
      "cgoBuyer": 1724
    },
    "6403": {
      "cgoBuyer": 651
    },
    "6439": {
      "cgoBuyer": 895
    },
    "6567": {
      "cgoBuyer": 106
    },
    "6594": {
      "cgoBuyer": 167
    },
    "6677": {
      "cgoBuyer": 243
    },
    "6734": {
      "cgoBuyer": 268
    },
    "7064": {
      "cgoBuyer": 383
    },
    "7228": {
      "cgoBuyer": 611
    },
    "7526": {
      "cgoBuyer": 1287
    },
    "11062": {
      "cgoBuyer": 46
    },
    "11495": {
      "cgoBuyer": 880
    },
    "11641": {
      "cgoBuyer": 210
    },
    "12485": {
      "cgoBuyer": 658
    },
    "12495": {
      "cgoBuyer": 311
    },
    "13610": {
      "cgoBuyer": 923
    },
    "14354": {
      "cgoBuyer": 680
    },
    "15053": {
      "cgoBuyer": 979
    },
    "15415": {
      "cgoBuyer": 521
    },
    "15982": {
      "cgoBuyer": 213
    },
    "40400": {
      "cgoBuyer": 641
    },
    "41550": {
      "cgoBuyer": 366
    },
    "68270": {
      "cgoBuyer": 932
    }
  }