import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../../interfaces/order.interface';

@Pipe({
    name: 'sortOrderByContractNumber',
  })
  export class OrderSortByContractNumerPipe implements PipeTransform {
    /* Given an array, return the same array by sorting it:
If the contractNumber is -1, it goes first.
Otherwise, it is sorted in descending order.*/
    transform(valueList: any[]) {
      return this.mergeSort(valueList) ?? [];
  }

  private mergeSort(array: Order[]): Order[] {
    if (array.length <= 1) {
      return array;
    }

    const middle = Math.floor(array.length / 2);
    const left = array.slice(0, middle);
    const right = array.slice(middle);

    return this.merge(this.mergeSort(left),this.mergeSort(right));
  }

  private merge(left: Order[], right: Order[]): Order[] {
    const result: Order[] = [];
    let leftIndex = 0;
    let rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      if (
        this.compareOrder(left[leftIndex], right[rightIndex]) <= 0
      ) {
        result.push(left[leftIndex]);
        leftIndex++;
      } else {
        result.push(right[rightIndex]);
        rightIndex++;
      }
    }

    return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
  }

  /*Estos son los criterios de ordenamiento:
  - Ordenar por contractNumber de mayor a menor.*/
  private compareOrder(a: Order, b: Order): number {
    return b.contractNumber - a.contractNumber;
  }
}