export const neuquenLocalities=[
    {"afip":"83","location":"Achico"},
    {"afip":"114","location":"Agrio Balsa"},
    {"afip":"175","location":"Aguada Chacay Co"},
    {"afip":"187","location":"Aguada Florencio"},
    {"afip":"208","location":"Aguas De Las Mulas"},
    {"afip":"342","location":"Alianza"},
    {"afip":"344","location":"Alicura"},
    {"afip":"470","location":"Alumine"},
    {"afip":"548","location":"Andacollo"},
    {"afip":"593","location":"Anquinco"},
    {"afip":"709","location":"Arroyito Challaco"},
    {"afip":"724","location":"Arroyo Blanco"},
    {"afip":"735","location":"Arroyo Cahunco"},
    {"afip":"845","location":"Arroyo Quillen"},
    {"afip":"847","location":"Arroyo Ranquilco"},
    {"afip":"927","location":"Auca Mahuida"},
    {"afip":"928","location":"Auca Pan"},
    {"afip":"964","location":"Añelo"}
    ,
    {"afip":"998","location":"Bajada Colorada"},
    {"afip":"1002","location":"Bajada De Los Molles"},
    {"afip":"1005","location":"Bajada Del Agrio"},
    {"afip":"1009","location":"Bajada Del Marucho"},
    {"afip":"1129","location":"Balneario Del Rio Agrio"},
    {"afip":"1151","location":"Balsa Del Rio Agrio"},
    {"afip":"1152","location":"Balsa Senillosa"},
    {"afip":"1175","location":"Barda Anguil"},
    {"afip":"1179","location":"Bardas Negras"},
    {"afip":"1296","location":"Barrio Peligroso"},
    {"afip":"1372","location":"Batre Lauquen"},
    {"afip":"1614","location":"Buta Co"},
    {"afip":"1615","location":"Buta Mallin"},
    {"afip":"1616","location":"Buta Ranquil"},
    {"afip":"1618","location":"Butalon"},
    {"afip":"1697","location":"Caepe Malal"},
    {"afip":"1701","location":"Caichihue"},
    {"afip":"1713","location":"Cajon De Almaza"},
    {"afip":"1714","location":"Cajon De Curi Leuvu"},
    {"afip":"1717","location":"Cajon De Los Patos"},
    {"afip":"1719","location":"Cajon De Manzano"},
    {"afip":"1722","location":"Cajon Del Toro"},
    {"afip":"1739","location":"Caleofu"},
    {"afip":"1758","location":"Calihue"},
    {"afip":"1777","location":"Camaleones"},
    {"afip":"1792","location":"Caminera"},
    {"afip":"1800","location":"Caminera Traful"},
    {"afip":"1818","location":"Campamento Sol"},
    {"afip":"2050","location":"Cancha Huiganco"},
    {"afip":"2212","location":"Carran Cara"},
    {"afip":"2216","location":"Carreri"},
    {"afip":"2220","location":"Carri Lauquen"},
    {"afip":"2221","location":"Carri Lil"},
    {"afip":"2326","location":"Catan Lil"},
    {"afip":"2347","location":"Caviahue"},
    {"afip":"2349","location":"Cayanta"},
    {"afip":"2455","location":"Cañadon De Los Indios"}
    ,
    {"afip":"2458","location":"Cañadon Del Indio"}
    ,
    {"afip":"2585","location":"Cerro De La Grasa"},
    {"afip":"2586","location":"Cerro De La Parva"},
    {"afip":"2596","location":"Cerro De Los Pinos"},
    {"afip":"2610","location":"Cerro Del Leon"},
    {"afip":"2630","location":"Cerro Gato"},
    {"afip":"2689","location":"Cerro Negro Chapua"},
    {"afip":"2692","location":"Cerro Negro Tricao"},
    {"afip":"2771","location":"Chacay"},
    {"afip":"2772","location":"Chacay Co"},
    {"afip":"2775","location":"Chacay Melehue"},
    {"afip":"2777","location":"Chacayal"},
    {"afip":"2778","location":"Chacayco"},
    {"afip":"2824","location":"Challaco"},
    {"afip":"2842","location":"Chapelco"},
    {"afip":"2844","location":"Chapua"},
    {"afip":"2845","location":"Chapua Abajo"},
    {"afip":"2852","location":"Charahuilla"},
    {"afip":"2917","location":"Chenquecura"},
    {"afip":"2926","location":"Chichiguay"},
    {"afip":"2960","location":"Chimehuin"},
    {"afip":"2964","location":"China Muerta"},
    {"afip":"2965","location":"Chinchina"},
    {"afip":"2971","location":"Chiquillihuin"},
    {"afip":"2984","location":"Chochoy Mallin"},
    {"afip":"3001","location":"Chos Malal"},
    {"afip":"3026","location":"Churriaca"},
    {"afip":"3107","location":"Codihue"},
    {"afip":"3131","location":"Coli Malal"},
    {"afip":"3133","location":"Colipili"},
    {"afip":"3141","location":"Collun Co"},
    {"afip":"3939","location":"Colonia Valentina"},
    {"afip":"3940","location":"Colonia Valentina Sur"},
    {"afip":"4023","location":"Confluencia Del Aguijon"},
    {"afip":"4045","location":"Copahue"},
    {"afip":"4154","location":"Correntoso"},
    {"afip":"4183","location":"Costa Del Arroyo Salado"},
    {"afip":"4204","location":"Costa Limay"},
    {"afip":"4226","location":"Covunco"},
    {"afip":"4227","location":"Covunco Abajo"},
    {"afip":"4228","location":"Covunco Arriba"},
    {"afip":"4229","location":"Covunco Centro"},
    {"afip":"4232","location":"Coyuco"},
    {"afip":"4313","location":"Cuchillo Cura"},
    {"afip":"4338","location":"Cullin Manzano"},
    {"afip":"4355","location":"Curu  Leuvu"},
    {"afip":"4368","location":"Cutral Co"},
    {"afip":"4605","location":"El  Tropezon"},
    {"afip":"4670","location":"El Atravesado"},
    {"afip":"4839","location":"El Chingue"},
    {"afip":"4845","location":"El Cholar"},
    {"afip":"4909","location":"El Curileo"},
    {"afip":"4935","location":"El Dormido"},
    {"afip":"5014","location":"El Huecu"},
    {"afip":"5058","location":"El Machete"},
    {"afip":"5163","location":"El Palao"},
    {"afip":"5208","location":"El Peralito"},
    {"afip":"5235","location":"El Pino Andino"},
    {"afip":"5262","location":"El Porton"},
    {"afip":"5455","location":"El Tromen"},
    {"afip":"5628","location":"Espinazo Del Zorro"},
    {"afip":"5867","location":"Estancia La Primavera"},
    {"afip":"5927","location":"Estancia Newbery"},
    {"afip":"5976","location":"Estancia Tequel Malal"},
    {"afip":"6106","location":"Filmatue"},
    {"afip":"6108","location":"Filo Hua Hum"},
    {"afip":"6173","location":"Flores"},
    {"afip":"6184","location":"Fortin 1 De Mayo"},
    {"afip":"6205","location":"Fortin Guañacos"}
    ,
    {"afip":"6256","location":"Franhucura"},
    {"afip":"6421","location":"Gente Grande"},
    {"afip":"6514","location":"Goñi Co"}
    ,
    {"afip":"6626","location":"Guañacos"}
    ,
    {"afip":"6653","location":"Haichol"},
    {"afip":"6673","location":"Haras Patria"},
    {"afip":"6682","location":"Haycu"},
    {"afip":"6765","location":"Hua Hum"},
    {"afip":"6781","location":"Hualcupen"},
    {"afip":"6793","location":"Huaraco"},
    {"afip":"6794","location":"Huarinchenque"},
    {"afip":"6816","location":"Huechahue"},
    {"afip":"6817","location":"Huechulafquen"},
    {"afip":"6834","location":"Huillilon"},
    {"afip":"6835","location":"Huinca Lu"},
    {"afip":"6837","location":"Huinganco"},
    {"afip":"6838","location":"Huitrin"},
    {"afip":"6848","location":"Humigamio"},
    {"afip":"6849","location":"Huncal"},
    {"afip":"6985","location":"Invernada Vieja"},
    {"afip":"7065","location":"Isla Victoria"},
    {"afip":"7141","location":"Jecanasco"},
    {"afip":"7226","location":"Juaranco"},
    {"afip":"7252","location":"Junin De Los Andes"},
    {"afip":"7271","location":"Kilca Casa"},
    {"afip":"7322","location":"La Angostura De Icalma"},
    {"afip":"7326","location":"La Araucaria"},
    {"afip":"7395","location":"La Buitrera"},
    {"afip":"7496","location":"La Cieneguita"},
    {"afip":"7660","location":"La Fria"},
    {"afip":"7779","location":"La Lipela"},
    {"afip":"7930","location":"La Patagonia"},
    {"afip":"8115","location":"La Susana"},
    {"afip":"8124","location":"La Teresa"},
    {"afip":"8125","location":"La Teresa Piedra Del Aguila"},
    {"afip":"8174","location":"La Verdad"},
    {"afip":"8231","location":"Lago Log Log"},
    {"afip":"8247","location":"Lagotera"},
    {"afip":"8291","location":"Laguna Miranda"},
    {"afip":"8336","location":"Lapa"},
    {"afip":"8337","location":"Lapachal"},
    {"afip":"8356","location":"Las Abejas"},
    {"afip":"8387","location":"Las Barditas"},
    {"afip":"8448","location":"Las Coloradas"},
    {"afip":"8532","location":"Las Lajitas"},
    {"afip":"8580","location":"Las Ovejas"},
    {"afip":"8605","location":"Las Perlas"},
    {"afip":"8682","location":"Las Tres Lagunas"},
    {"afip":"8716","location":"Lascar"},
    {"afip":"8770","location":"Leuto Caballo"},
    {"afip":"8791","location":"Lileo"},
    {"afip":"8796","location":"Limay Centro"},
    {"afip":"8823","location":"Litran"},
    {"afip":"8824","location":"Liu Cullin"},
    {"afip":"8831","location":"Llamuco"},
    {"afip":"8845","location":"Log Log"},
    {"afip":"8860","location":"Loma De La Lata"},
    {"afip":"8920","location":"Lonco Luan"},
    {"afip":"8921","location":"Lonco Mula"},
    {"afip":"8924","location":"Loncopue"},
    {"afip":"9003","location":"Los Bolillos"},
    {"afip":"9025","location":"Los Carrizos"},
    {"afip":"9029","location":"Los Catutos"},
    {"afip":"9041","location":"Los Chacales"},
    {"afip":"9054","location":"Los Chihuidos"},
    {"afip":"9056","location":"Los Chinitos"},
    {"afip":"9110","location":"Los Entierros"},
    {"afip":"9196","location":"Los Menucos"},
    {"afip":"9199","location":"Los Miches"},
    {"afip":"9218","location":"Los Muchachos"},
    {"afip":"9313","location":"Los Rodillos"},
    {"afip":"9373","location":"Los Tres Chorros"},
    {"afip":"9408","location":"Lubeca"},
    {"afip":"9425","location":"Luicoco"},
    {"afip":"9426","location":"Luin Coco"},
    {"afip":"9471","location":"Machico"},
    {"afip":"9521","location":"Malaico"},
    {"afip":"9541","location":"Mallin Chileno"},
    {"afip":"9542","location":"Mallin De La Cueva"},
    {"afip":"9543","location":"Mallin De Las Yeguas"},
    {"afip":"9544","location":"Mallin De Mena"},
    {"afip":"9545","location":"Mallin Del Rubio"},
    {"afip":"9546","location":"Mallin Del Toro"},
    {"afip":"9561","location":"Mamul Malal"},
    {"afip":"9606","location":"Manzano Amargo"},
    {"afip":"9651","location":"Mari Menuco"},
    {"afip":"9685","location":"Mariano Moreno"},
    {"afip":"9754","location":"Mayan Mahuida"},
    {"afip":"9808","location":"Meliquina"},
    {"afip":"9865","location":"Milla"},
    {"afip":"9874","location":"Mina Carrascosa"},
    {"afip":"9894","location":"Mina Lileo"},
    {"afip":"10093","location":"Moquehue"},
    {"afip":"10137","location":"Mulichinco"},
    {"afip":"10162","location":"Nahuel Huapi"},
    {"afip":"10164","location":"Nahuel Mape"},
    {"afip":"10169","location":"Nahueve"},
    {"afip":"10172","location":"Nalay Cullin"},
    {"afip":"10193","location":"Nau Nauco"},
    {"afip":"10212","location":"Nereco Norte"},
    {"afip":"10215","location":"Neuquen"},
    {"afip":"10242","location":"Nireco"},
    {"afip":"10256","location":"Nogueira"},
    {"afip":"10484","location":"Palau"},
    {"afip":"10495","location":"Palitue"},
    {"afip":"10563","location":"Pampa Collon Cura"},
    {"afip":"10569","location":"Pampa De Lonco Luan"},
    {"afip":"10576","location":"Pampa De Tril"},
    {"afip":"10584","location":"Pampa Del Malleo"},
    {"afip":"10598","location":"Pampa Ferreira"},
    {"afip":"10662","location":"Pantanitos"},
    {"afip":"10773","location":"Paso Aguerre"},
    {"afip":"10779","location":"Paso Barda"},
    {"afip":"10783","location":"Paso Cata Tun"},
    {"afip":"10788","location":"Paso Coihue"},
    {"afip":"10818","location":"Paso De Los Indios"},
    {"afip":"10830","location":"Paso De San Ignacio"},
    {"afip":"10988","location":"Peninsula Huemul"},
    {"afip":"11056","location":"Pichaihue"},
    {"afip":"11061","location":"Piche Ponon"},
    {"afip":"11070","location":"Pichi Neuquen"},
    {"afip":"11078","location":"Picun Leufu"},
    {"afip":"11093","location":"Piedra Del Aguila"},
    {"afip":"11099","location":"Piedra Mala"},
    {"afip":"11110","location":"Piedras Bayas"},
    {"afip":"11137","location":"Pilmatue"},
    {"afip":"11138","location":"Pilo Lil"},
    {"afip":"11157","location":"Pino Hachado"},
    {"afip":"11158","location":"Pino Solo"},
    {"afip":"11203","location":"Planicie Banderita"},
    {"afip":"11222","location":"Plaza Huincul"},
    {"afip":"11230","location":"Plottier"},
    {"afip":"11304","location":"Portada Covunco"},
    {"afip":"11310","location":"Portezuelo Grande"},
    {"afip":"11486","location":"Pozo Hualiches"},
    {"afip":"11540","location":"Primeros Pinos"},
    {"afip":"11701","location":"Puente Picun Leufu"},
    {"afip":"11749","location":"Puerto Anchorena"},
    {"afip":"11825","location":"Puerto Manzano"},
    {"afip":"11998","location":"Puesto Hernandez  Baterias"},
    {"afip":"12131","location":"Punta De Sierra"},
    {"afip":"12211","location":"Quempu Leufu"},
    {"afip":"12215","location":"Quentrenquen"},
    {"afip":"12228","location":"Quila Chanquil"},
    {"afip":"12229","location":"Quila Quehue"},
    {"afip":"12230","location":"Quila Quina"},
    {"afip":"12231","location":"Quilca"},
    {"afip":"12233","location":"Quili Malal"},
    {"afip":"12236","location":"Quillen"},
    {"afip":"12239","location":"Quilquihue"},
    {"afip":"12248","location":"Quinineliu"},
    {"afip":"12249","location":"Quinquimitreo"},
    {"afip":"12260","location":"Quintuco"},
    {"afip":"12275","location":"Quita Quina"},
    {"afip":"12297","location":"Rahue"},
    {"afip":"12317","location":"Ramichal"},
    {"afip":"12350","location":"Ranquil Vega"},
    {"afip":"12351","location":"Ranquilco"},
    {"afip":"12353","location":"Ranquilon"},
    {"afip":"12448","location":"Reñileo"}
    ,
    {"afip":"12466","location":"Richoique"},
    {"afip":"12483","location":"Rincon De Emilio"},
    {"afip":"12497","location":"Rincon De Los Sauces"},
    {"afip":"12550","location":"Rio Agrio"},
    {"afip":"12718","location":"Ruca Chorroy Arriba"},
    {"afip":"12801","location":"Sainuco"},
    {"afip":"12854","location":"Salquico"},
    {"afip":"12939","location":"San Demetrio"},
    {"afip":"13053","location":"San Juan Junin De Los Andes"},
    {"afip":"13055","location":"San Juan Rahue"},
    {"afip":"13083","location":"San Martin De Los Andes"},
    {"afip":"13113","location":"San Patricio Del Chañar"}
    ,
    {"afip":"13370","location":"Sauzal Bonito"},
    {"afip":"13376","location":"Sañico"}
    ,
    {"afip":"13409","location":"Senillosa"},
    {"afip":"13763","location":"Taqui Nileu"},
    {"afip":"13764","location":"Taquimilan"},
    {"afip":"13765","location":"Taquimillan Abajo"},
    {"afip":"13853","location":"Tihue"},
    {"afip":"13900","location":"Tipiliuke"},
    {"afip":"14000","location":"Trahuncura"},
    {"afip":"14004","location":"Tralatue"},
    {"afip":"14018","location":"Tratayen"},
    {"afip":"14048","location":"Tres Chorros"},
    {"afip":"14077","location":"Tres Piedras"},
    {"afip":"14093","location":"Tricao Malal"},
    {"afip":"14106","location":"Tromen"},
    {"afip":"14108","location":"Trompul"},
    {"afip":"14234","location":"Vaca Muerta"},
    {"afip":"14286","location":"Varvarco"},
    {"afip":"14528","location":"Villa El Chocon"},
    {"afip":"14620","location":"Villa La Angostura"},
    {"afip":"14671","location":"Villa Mallin"},
    {"afip":"14734","location":"Villa Pehuenia"},
    {"afip":"14759","location":"Villa Raur"},
    {"afip":"14847","location":"Villa Traful"},
    {"afip":"14915","location":"Vista Alegre Norte"},
    {"afip":"14916","location":"Vista Alegre Sur"},
    {"afip":"15078","location":"Zaina Yegua"},
    {"afip":"15090","location":"Zapala"},
    {"afip":"15111","location":"Zingone Y Cia M"},
    {"afip":"15125","location":"Zulemita"},
    {"afip":"15135","location":"Ñireco Sud"}
    ,
    {"afip":"15140","location":"Ñorquin"}
    ,
    {"afip":"15292","location":"Arroyito"},
    {"afip":"15347","location":"Barrancas"},
    {"afip":"15386","location":"Bella Vista"},
    {"afip":"15414","location":"Buena Esperanza"},
    {"afip":"15438","location":"Cajon Grande"},
    {"afip":"15502","location":"Casa De Piedra"},
    {"afip":"15542","location":"Cañada Seca"}
    ,
    {"afip":"15552","location":"Centenario"},
    {"afip":"15583","location":"Cerro Colorado"},
    {"afip":"15607","location":"Chacabuco"},
    {"afip":"15651","location":"Cochico"},
    {"afip":"15652","location":"Coihueco"},
    {"afip":"15780","location":"Corral De Piedra"},
    {"afip":"15861","location":"El Alamito"},
    {"afip":"15879","location":"El Arbolito"},
    {"afip":"15919","location":"El Bosque"},
    {"afip":"15976","location":"El Cerrito"},
    {"afip":"16044","location":"El Durazno"},
    {"afip":"16050","location":"El Escorial"},
    {"afip":"16068","location":"El Gato"},
    {"afip":"16100","location":"El Manzano"},
    {"afip":"16154","location":"El Oasis"},
    {"afip":"16162","location":"El Overo"},
    {"afip":"16218","location":"El Porvenir"},
    {"afip":"16332","location":"El Salado"},
    {"afip":"16338","location":"El Salitral"},
    {"afip":"16354","location":"El Sauce"},
    {"afip":"16652","location":"La Arboleda"},
    {"afip":"16658","location":"La Argentina"},
    {"afip":"16672","location":"La Atalaya"},
    {"afip":"16770","location":"La Cienaga"},
    {"afip":"16893","location":"La Estacada"},
    {"afip":"16948","location":"La Fortuna"},
    {"afip":"17005","location":"La Isabel"},
    {"afip":"17112","location":"La Negra"},
    {"afip":"17122","location":"La Ofelia"},
    {"afip":"17148","location":"La Patria"},
    {"afip":"17168","location":"La Pintada"},
    {"afip":"17176","location":"La Pochola"},
    {"afip":"17185","location":"La Porteña"}
    ,
    {"afip":"17201","location":"La Primavera"},
    {"afip":"17259","location":"La Rinconada"},
    {"afip":"17277","location":"La Salada"},
    {"afip":"17337","location":"La Union"},
    {"afip":"17376","location":"Laguna Blanca"},
    {"afip":"17404","location":"Laja"},
    {"afip":"17425","location":"Las Bandurrias"},
    {"afip":"17461","location":"Las Chacras"},
    {"afip":"17482","location":"Las Cortaderas"},
    {"afip":"17547","location":"Las Lagunas"},
    {"afip":"17551","location":"Las Lajas"},
    {"afip":"17581","location":"Las Mercedes"},
    {"afip":"17657","location":"Las Saladas"},
    {"afip":"17677","location":"Las Toscas"},
    {"afip":"17824","location":"Los Cisnes"},
    {"afip":"17851","location":"Los Galpones"},
    {"afip":"17888","location":"Los Molles"},
    {"afip":"17958","location":"Los Sauces"},
    {"afip":"17989","location":"Mallin Blanco"},
    {"afip":"17990","location":"Mallin Quemado"},
    {"afip":"18136","location":"Ojo De Agua"},
    {"afip":"18189","location":"Pampa Del Salado"},
    {"afip":"18209","location":"Paso Ancho"},
    {"afip":"18234","location":"Peña Colorada"}
    ,
    {"afip":"18255","location":"Piedra Pintada"},
    {"afip":"18359","location":"Pueblo Nuevo"},
    {"afip":"18439","location":"Quebrada Honda"},
    {"afip":"18459","location":"Ranqueles"},
    {"afip":"18483","location":"Rincon Chico"},
    {"afip":"18486","location":"Rincon Grande"},
    {"afip":"18492","location":"Rio Barrancas"},
    {"afip":"18610","location":"San Bernardo"},
    {"afip":"18641","location":"San Eduardo"},
    {"afip":"18701","location":"San Ignacio"},
    {"afip":"18999","location":"Santa Isabel"},
    {"afip":"19026","location":"Santa Maria"},
    {"afip":"19081","location":"Santo Domingo"},
    {"afip":"19092","location":"Santo Tomas"},
    {"afip":"19186","location":"Tierras Blancas"},
    {"afip":"19247","location":"Tres Picos"},
    {"afip":"19255","location":"Trili"},
    {"afip":"20742","location":"Las Cortaderas"},
    {"afip":"20743","location":"Las Cortaderas"},
    {"afip":"20744","location":"Ojo De Agua"},
    {"afip":"20752","location":"Santa Isabel"},
    {"afip":"20753","location":"El Tromen"},
    {"afip":"20754","location":"Santa Isabel"}
    ]