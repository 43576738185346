import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberPipe } from './number.pipe';
import { FilterOptionsPipe } from './filter/filter.options.pipe';
import { FilterOrderWithStringPipe, FilterPriceWithStringPipe } from './filter/filterWithString.pipe';
import { FilterByPricePipe } from './filter/filter.by.price.pipe';
import { FilterOrderByCommoditiesPipe, FilterPriceByCommoditiesPipe } from './filter/filterByBuyerType.pipe';
import { FilterByOrderPipe } from './filter/filter.by.order.pipe';
import { FilterByStatusPipe } from './filter/filter.by.status.pipe';
import { MatchExchangesByName, MatchOrdersByName, MatchPricesByName } from './match-prices-by-name';
import { PriceVisibleForComercial } from './price.visible.comercial';
import { FilterByObjectPipe } from './filter/filter-by-object';
import { OrderSortPipe } from './sorts/orderSort.pipe';
import { PriceSortPipe } from './sorts/pricesSort.pipe';
import { FilterWithOutContractStatus } from './filter/filterWithOutContractStatus.pipe';
import { OrderExchangeSortPipe } from './sorts/ordersExchangeSort.pipe';
import { FilterWithOnlyContractStatus } from './filter/filterWithContractStatus.pipe';
import { ZonePipe } from './zone.pipe';
import { PeriodPipe } from './period.pipe';
import { OrderSortByContractNumerPipe } from './sorts/orderSortByContractNumber';
import { FilterArrayIncludesStringPipe } from './filter/filterIncludesString.pipe';
import { FilterAllOrderPipe } from './filter/filter-all-order.pipe';
import { FilterOrderByRepresentationPipe } from './filter/filter-by-restriction.pipe';
import { FilterOrderIsOwnerCommoditiesPipe } from './filter/filter-is-owner-commodities.pipe';
import { FilterOrderDashboardPipe } from './filter/filter-order-dashboard.pipe';
import { FilterByConditionTypePipe, FilterOrderByConditionTypePipe } from './filter/filter-by-condition-type.pipe';
import { FilterCommoditiesSellerPipe } from './filter/filterOnlyCommoditiesSell.pipe';
import { FilterAllPricePipe } from './filter/filter-all-price.pipe';



@NgModule({
  declarations: [
    NumberPipe,
    FilterOptionsPipe,
    
    FilterPriceWithStringPipe,
    PriceVisibleForComercial,
    FilterByPricePipe,
    FilterPriceByCommoditiesPipe,
    FilterByConditionTypePipe,
    FilterOrderByConditionTypePipe,
    FilterAllPricePipe,
    
    FilterByOrderPipe,
    FilterOrderWithStringPipe,
    FilterOrderByCommoditiesPipe,
    FilterByStatusPipe,
    FilterByObjectPipe,
    FilterWithOutContractStatus,
    FilterWithOnlyContractStatus,
    FilterAllOrderPipe,
    FilterOrderByRepresentationPipe,
    FilterOrderIsOwnerCommoditiesPipe,
    FilterCommoditiesSellerPipe,
    FilterOrderDashboardPipe,

    FilterArrayIncludesStringPipe,
    MatchPricesByName,
    MatchOrdersByName,
    MatchExchangesByName,

    OrderSortPipe,
    PriceSortPipe,
    OrderExchangeSortPipe,
    OrderSortByContractNumerPipe,
    
    ZonePipe,
    PeriodPipe
    
  ],
  imports: [
    CommonModule
  ], exports: [
    NumberPipe,
    FilterOptionsPipe,
    
    FilterPriceWithStringPipe,
    PriceVisibleForComercial,
    FilterByPricePipe,
    FilterPriceByCommoditiesPipe,
    FilterByConditionTypePipe,
    FilterOrderByConditionTypePipe,
    FilterAllPricePipe,
    
    FilterByOrderPipe,
    FilterOrderWithStringPipe,
    FilterOrderByCommoditiesPipe,
    FilterByStatusPipe,
    FilterByObjectPipe,
    FilterWithOutContractStatus,
    FilterWithOnlyContractStatus,
    FilterAllOrderPipe,
    FilterOrderByRepresentationPipe,
    FilterOrderIsOwnerCommoditiesPipe,
    FilterCommoditiesSellerPipe,
    FilterOrderDashboardPipe,

    FilterArrayIncludesStringPipe,
    
    MatchPricesByName,
    MatchOrdersByName,
    MatchExchangesByName,
    
    OrderSortPipe,
    PriceSortPipe,
    OrderExchangeSortPipe,
    OrderSortByContractNumerPipe,

    ZonePipe,
    PeriodPipe
  ]

})
export class PipesModule { }
