<div class="paso paso1">
    <app-data class="data not-in-mobile" title="Producto" value="{{PRODUCTS[price.productName]}}"></app-data>
    <app-data *ngIf="isExport(price)" class="data" title="Comprador" value="{{getBuyers()}}"></app-data>
    <app-data class="data" title="Tipo de negocio" value="{{BUSINESS_TYPE[price.typeBusiness]}}"></app-data>
    <app-data class="data" title="Lugar de entrega"
        value="{{getPlaceOfDelivery(price)}}">
    </app-data>
    <app-data class="data" title="Particularidad de negocio" [object]="getBusinessParticularities(price.businessParticularities)"
    [businessParticularity]="price.businessParticularities"></app-data>
</div>
<div class="paso paso2">
    <app-data class="data" title="Periodo de entrega"
        value="{{price.deliveryPeriod.startDate != '--'? price.deliveryPeriod.startDate +'/'+price.deliveryPeriod.endDate : '--'}}">
    </app-data>
    <app-data class="data" title="Calidad" value="{{getQuality(price)}}"></app-data>
    <app-data class="data" title="Particularidad de calidad" value="{{getQualityIBM(price)}}"></app-data>
    <app-data class="data not-in-mobile" title="Precio" value="{{getPrice(price)}}" *ngIf="!isGenerateToFix"></app-data>
    <app-data class="data not-in-mobile" title="Moneda" value="{{getTypeCoin(price)}}" *ngIf="!isGenerateToFix"></app-data>
</div>
<div class="paso paso3">
    <app-data class="data" title="Cosecha" value="{{price.harvest}}"></app-data>
    <app-data class="data" title="Forma de pago" value="{{getWayPay(price)}}"></app-data>
    <app-data class="data" title="Vencimiento" value="{{price.wayPay.expiration}}" [infoGreen]="isOperator && isFixExpiration(price)? 'Vencimiento anclado':''"></app-data>
    <app-data class="data" title="Pesificación" value="{{getPesification(price)}}" *ngIf="!isGenerateToFix"></app-data>
    <app-data class="data" title="Código agrupador" value="{{getGrouperCode(price)}}" *ngIf="isOperator"></app-data>
</div>