<div class="card">
    <div class="header">
        <span class="title">Información</span>

        <cross-svg (click)="closeCard()" class="cross" [size]="12"></cross-svg>
    </div>

    <div class="body">
        <p class="text description">
            Al ingresar días e intereses con decimales los días se redondearán para arriba y los intereses hacia abajo.
        </p>
        <span class="text example">Ejemplo: 3,1 días se reflejará como 4.</span>
    </div>
</div>