<!--<button class="filled-button" (click)="handleDownloadCsv()">PRUEBA</button>-->
<div class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive">
        <thead>
            <tr row-head [viewSelected]="viewSelected" [conditionTypeSelected]="conditionTypeSelected"
            [filter]="orderFilter" [filterIdentificator]="filterIdentificator" (filterSelect)="selectFilter($event)"></tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let order of arrayHistoricalWithPipe
            | filterByRepresentation: isRepresentation:user | sortOrderByContractNumber; trackBy: trackById; index as i">
            
                <tr app-row class="disabled" [ngClass]="{'corredora-1888': order.broke==2,
                    'is-open': getArrowDirection(order._id)=='up' || isIdInArrayParent(order._id)}" (dblclick)="editOrder(order);"
                [order]="order" [viewSelected]="viewSelected" [isDisabled]="true"
                [optionsMenu]="getOptionsMenu()"
                [conditionTypeSelected]="conditionTypeSelected"
                [isHaveIndicator]="haveIndicators(order)" [directionArrow]="getArrowDirection(order._id)"
                (arrow)="clickInArrow($event.order, $event.event)" (optionMenu)="selectOptionMenu($event.event, $event.order)"></tr>

                <tr class="indicators" *ngIf="haveIndicators(order) && isIdInArrayParent(order._id)">
                    <td class="first-col-indicators"></td>
                
                    <td colspan="14">
                        <div class="indicators-container">
                            <app-indicators-table [price]="order.price" [showFirstColumn]="false"></app-indicators-table>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>