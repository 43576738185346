
<div class="dialog-content" mat-dialog-content>
  <div class="logo-container">
    <img class="logo confirm" *ngIf="isConfirm" alt="check-confirm" src="../../../../../../assets/images/extra/confirmed.svg" />
    <img class="logo denied" *ngIf="!isConfirm" alt="check-denied" src="../../../../../../assets/images/extra/denied.svg" />
  </div>
  <span class="hour">{{hour}}</span>
  <p class="message">{{mensaje}}</p>
  <p class="more-info">{{moreInfo}}</p>
</div>
<div class="actions">
  <div class="width-100 column-center" *ngIf="isComercial else commodities">
    <button class="button not-filled-button visible-in-desktop" (click)="borrador()" *ngIf="isOrderExchangeIncomplete()">Enviar a borrador</button>
    <button class="button filled-button" (click)="operator()">Enviar al operador</button>
  </div>
  <ng-template #commodities>
    <button class="button not-filled-button" (click)="borrador()">Enviar a boletos</button>
    <button class="button filled-button" (click)="operator()">Enviar al listado de ordenes</button>
  </ng-template>
</div>
 