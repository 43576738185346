import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  constructor(private http: HttpClient) { }

  getClaim(){
    return this.http.get<any>(environment.rutas.claim); 
    //We deliver claim
  }

}
