export function updateCodigoPostal(data:CCLOCA[]){
  CODIGO_POSTAL=data;
}

interface CCLOCA {
  "CODIGO POSTAL": number,
  "DESCRIPCION": string,
  "PROVINCIA": string,
  "AFIP": number|string
}

export let CODIGO_POSTAL: CCLOCA[] =[
  {
    "DESCRIPCION": "ALTO RIO SENGUER",
    "CODIGO POSTAL": 9033,
    "PROVINCIA": "U",
    "AFIP": 463
  },
  {
    "DESCRIPCION": "COMODORO RIVADAVIA",
    "CODIGO POSTAL": 9000,
    "PROVINCIA": "U",
    "AFIP": 4000
  },
  {
    "DESCRIPCION": "GOBERNADOR COSTA",
    "CODIGO POSTAL": 9223,
    "PROVINCIA": "U",
    "AFIP": 16548
  },
  {
    "DESCRIPCION": "GOBERNADOR FONTANA",
    "CODIGO POSTAL": 9101,
    "PROVINCIA": "U",
    "AFIP": 8228
  },
  {
    "DESCRIPCION": "GENERAL MOSCONI",
    "CODIGO POSTAL": 9005,
    "PROVINCIA": "U",
    "AFIP": 19374
  },
  {
    "DESCRIPCION": "GENERAL MOSCONI",
    "CODIGO POSTAL": 9501,
    "PROVINCIA": "U",
    "AFIP": 19374
  },
  {
    "DESCRIPCION": "PUERTO MADRYN",
    "CODIGO POSTAL": 9120,
    "PROVINCIA": "U",
    "AFIP": 11824
  },
  {
    "DESCRIPCION": "ALBARDON",
    "CODIGO POSTAL": 5419,
    "PROVINCIA": "J",
    "AFIP": 5650
  },
  {
    "DESCRIPCION": "BARRIO PUEYRREDON",
    "CODIGO POSTAL": 5013,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "HIPOLITO BOUCHARD",
    "CODIGO POSTAL": 6225,
    "PROVINCIA": "X",
    "AFIP": 6721
  },
  {
    "DESCRIPCION": "HIPOLITO BOUCHARD",
    "CODIGO POSTAL": 6225,
    "PROVINCIA": "X",
    "AFIP": 6721
  },
  {
    "DESCRIPCION": "CAPILLA DEL SEÑOR",
    "CODIGO POSTAL": 2812,
    "PROVINCIA": "B",
    "AFIP": 2117
  },
  {
    "DESCRIPCION": "CIUDAD AUTONOMA BUENOS AIRES",
    "CODIGO POSTAL": 1405,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CIUDAD AUTONOMA BUENOS AIRES",
    "CODIGO POSTAL": 1295,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CIUDAD AUTONOMA DE BUENOS AIRES",
    "CODIGO POSTAL": 1020,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "BALNEARIO CLAROMECO",
    "CODIGO POSTAL": 7505,
    "PROVINCIA": "B",
    "AFIP": 1128
  },
  {
    "DESCRIPCION": "COLONIA ITALIANA",
    "CODIGO POSTAL": 2646,
    "PROVINCIA": "X",
    "AFIP": 3506
  },
  {
    "DESCRIPCION": "ESPELETA OESTE",
    "CODIGO POSTAL": 1873,
    "PROVINCIA": "B",
    "AFIP": 6042
  },
  {
    "DESCRIPCION": "EST DE HERRERA VEGAS",
    "CODIGO POSTAL": 3272,
    "PROVINCIA": "E",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "GOBERNADOR JUAN E MARTINEZ",
    "CODIGO POSTAL": 3445,
    "PROVINCIA": "W",
    "AFIP": 6461
  },
  {
    "DESCRIPCION": "GENERAL LAS HERAS",
    "CODIGO POSTAL": 1737,
    "PROVINCIA": "B",
    "AFIP": 6383
  },
  {
    "DESCRIPCION": "GENERAL RODRIGUEZ",
    "CODIGO POSTAL": 1748,
    "PROVINCIA": "B",
    "AFIP": 6411
  },
  {
    "DESCRIPCION": "GENERAL CERRI",
    "CODIGO POSTAL": 8105,
    "PROVINCIA": "B",
    "AFIP": 6365
  },
  {
    "DESCRIPCION": "ISIDRO CASANOVA",
    "CODIGO POSTAL": 1765,
    "PROVINCIA": "B",
    "AFIP": 7009
  },
  {
    "DESCRIPCION": "INGENIERO IACOBACCI",
    "CODIGO POSTAL": 8418,
    "PROVINCIA": "R",
    "AFIP": 6935
  },
  {
    "DESCRIPCION": "INGENIERO JUAREZ",
    "CODIGO POSTAL": 3636,
    "PROVINCIA": "P",
    "AFIP": 6909
  },
  {
    "DESCRIPCION": "INGENIERO LUIGI",
    "CODIGO POSTAL": 6205,
    "PROVINCIA": "L",
    "AFIP": 6936
  },
  {
    "DESCRIPCION": "INGENIO LA FLORIDA",
    "CODIGO POSTAL": 4115,
    "PROVINCIA": "T",
    "AFIP": 6955
  },
  {
    "DESCRIPCION": "JUNIN",
    "CODIGO POSTAL": 5573,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "LAS HERAS",
    "CODIGO POSTAL": 1741,
    "PROVINCIA": "B",
    "AFIP": 6383
  },
  {
    "DESCRIPCION": "LOS QUIRQUINCHOS",
    "CODIGO POSTAL": 2637,
    "PROVINCIA": "S",
    "AFIP": 17947
  },
  {
    "DESCRIPCION": "LOS QUIRQUINCHOS",
    "CODIGO POSTAL": 2667,
    "PROVINCIA": "S",
    "AFIP": 17947
  },
  {
    "DESCRIPCION": "GOBERNADOR MANSILLA",
    "CODIGO POSTAL": 3115,
    "PROVINCIA": "E",
    "AFIP": 6465
  },
  {
    "DESCRIPCION": "PAMPA DEL INFIERNO",
    "CODIGO POSTAL": 3708,
    "PROVINCIA": "H",
    "AFIP": 10583
  },
  {
    "DESCRIPCION": "POCITO",
    "CODIGO POSTAL": 5427,
    "PROVINCIA": "J",
    "AFIP": 18270
  },
  {
    "DESCRIPCION": "RAMALLO",
    "CODIGO POSTAL": 1847,
    "PROVINCIA": "B",
    "AFIP": 14758
  },
  {
    "DESCRIPCION": "SAN A DE PADUA",
    "CODIGO POSTAL": 1718,
    "PROVINCIA": "B",
    "AFIP": 12898
  },
  {
    "DESCRIPCION": "SAN CARLOS MINAS",
    "CODIGO POSTAL": 5291,
    "PROVINCIA": "X",
    "AFIP": 12926
  },
  {
    "DESCRIPCION": "CARLOS SPEGAZZINI",
    "CODIGO POSTAL": 1813,
    "PROVINCIA": "B",
    "AFIP": 2195
  },
  {
    "DESCRIPCION": "VALENTIN ALSINA",
    "CODIGO POSTAL": 1822,
    "PROVINCIA": "B",
    "AFIP": 14245
  },
  {
    "DESCRIPCION": "V CELINA",
    "CODIGO POSTAL": 1772,
    "PROVINCIA": "B",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "VILLA CONCEPCION DEL TIO",
    "CODIGO POSTAL": 2433,
    "PROVINCIA": "X",
    "AFIP": 14472
  },
  {
    "DESCRIPCION": "ESTACION POMAN",
    "CODIGO POSTAL": 4742,
    "PROVINCIA": "K",
    "AFIP": 5721
  },
  {
    "DESCRIPCION": "CAÑADA DEL UCLE",
    "CODIGO POSTAL": 2635,
    "PROVINCIA": "S",
    "AFIP": 2412
  },
  {
    "DESCRIPCION": "EL TRIUNFO",
    "CODIGO POSTAL": 6073,
    "PROVINCIA": "B",
    "AFIP": 5454
  },
  {
    "DESCRIPCION": "BASAVILBASO",
    "CODIGO POSTAL": 3170,
    "PROVINCIA": "E",
    "AFIP": 1344
  },
  {
    "DESCRIPCION": "BERNAL ESTE",
    "CODIGO POSTAL": 1876,
    "PROVINCIA": "B",
    "AFIP": 1462
  },
  {
    "DESCRIPCION": "BRINKMANN",
    "CODIGO POSTAL": 2419,
    "PROVINCIA": "X",
    "AFIP": 1579
  },
  {
    "DESCRIPCION": "CAÑADA DE GOMEZ",
    "CODIGO POSTAL": 2500,
    "PROVINCIA": "S",
    "AFIP": 2376
  },
  {
    "DESCRIPCION": "CAÑADA DE LUQUE",
    "CODIGO POSTAL": 5229,
    "PROVINCIA": "X",
    "AFIP": 2391
  },
  {
    "DESCRIPCION": "EL CALAFATE",
    "CODIGO POSTAL": 9405,
    "PROVINCIA": "Z",
    "AFIP": 15935
  },
  {
    "DESCRIPCION": "CAMPOS SALLES",
    "CODIGO POSTAL": 2903,
    "PROVINCIA": "B",
    "AFIP": 21822
  },
  {
    "DESCRIPCION": "CAÑADA SECA",
    "CODIGO POSTAL": 6105,
    "PROVINCIA": "B",
    "AFIP": 2434
  },
  {
    "DESCRIPCION": "CAÑADA ROSQUIN",
    "CODIGO POSTAL": 2454,
    "PROVINCIA": "S",
    "AFIP": 2430
  },
  {
    "DESCRIPCION": "CAÑUELAS",
    "CODIGO POSTAL": 1814,
    "PROVINCIA": "B",
    "AFIP": 2477
  },
  {
    "DESCRIPCION": "CATUNA",
    "CODIGO POSTAL": 5275,
    "PROVINCIA": "F",
    "AFIP": 2338
  },
  {
    "DESCRIPCION": "CHELFORO",
    "CODIGO POSTAL": 8366,
    "PROVINCIA": "R",
    "AFIP": 2915
  },
  {
    "DESCRIPCION": "CIPOLLETTI",
    "CODIGO POSTAL": 8324,
    "PROVINCIA": "R",
    "AFIP": 3051
  },
  {
    "DESCRIPCION": "PUERTO WANDA",
    "CODIGO POSTAL": 3376,
    "PROVINCIA": "N",
    "AFIP": 11888
  },
  {
    "DESCRIPCION": "LOS CORRALITOS",
    "CODIGO POSTAL": 5527,
    "PROVINCIA": "M",
    "AFIP": 17839
  },
  {
    "DESCRIPCION": "CORONEL DORREGO",
    "CODIGO POSTAL": 5519,
    "PROVINCIA": "M",
    "AFIP": 15775
  },
  {
    "DESCRIPCION": "EL CHAÑAR",
    "CODIGO POSTAL": 8305,
    "PROVINCIA": "Q",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "HOLT",
    "CODIGO POSTAL": 2846,
    "PROVINCIA": "E",
    "AFIP": 6735
  },
  {
    "DESCRIPCION": "EZPELETA ESTE",
    "CODIGO POSTAL": 1882,
    "PROVINCIA": "B",
    "AFIP": 6041
  },
  {
    "DESCRIPCION": "FORTIN ACHA",
    "CODIGO POSTAL": 6031,
    "PROVINCIA": "B",
    "AFIP": 6185
  },
  {
    "DESCRIPCION": "HURLINGHAM",
    "CODIGO POSTAL": 1686,
    "PROVINCIA": "B",
    "AFIP": 6853
  },
  {
    "DESCRIPCION": "LA CAÑADA",
    "CODIGO POSTAL": 5285,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "ACHIRAS",
    "CODIGO POSTAL": 5833,
    "PROVINCIA": "X",
    "AFIP": 84
  },
  {
    "DESCRIPCION": "LONCOPUE",
    "CODIGO POSTAL": 8349,
    "PROVINCIA": "Q",
    "AFIP": 8924
  },
  {
    "DESCRIPCION": "LULES",
    "CODIGO POSTAL": 4128,
    "PROVINCIA": "T",
    "AFIP": 9440
  },
  {
    "DESCRIPCION": "MAQUINCHAO",
    "CODIGO POSTAL": 8422,
    "PROVINCIA": "R",
    "AFIP": 9612
  },
  {
    "DESCRIPCION": "MONTE CRISTO",
    "CODIGO POSTAL": 5129,
    "PROVINCIA": "X",
    "AFIP": 10050
  },
  {
    "DESCRIPCION": "VILLA MOQUEHUA",
    "CODIGO POSTAL": 6625,
    "PROVINCIA": "B",
    "AFIP": 14701
  },
  {
    "DESCRIPCION": "VILLA MOQUEHUA",
    "CODIGO POSTAL": 6625,
    "PROVINCIA": "B",
    "AFIP": 14701
  },
  {
    "DESCRIPCION": "POCITO",
    "CODIGO POSTAL": 5427,
    "PROVINCIA": "J",
    "AFIP": 18270
  },
  {
    "DESCRIPCION": "POLVAREDAS",
    "CODIGO POSTAL": 7267,
    "PROVINCIA": "B",
    "AFIP": 11281
  },
  {
    "DESCRIPCION": "RAFAEL CASTILLO",
    "CODIGO POSTAL": 1755,
    "PROVINCIA": "B",
    "AFIP": 12292
  },
  {
    "DESCRIPCION": "RAUCH",
    "CODIGO POSTAL": 7203,
    "PROVINCIA": "B",
    "AFIP": 12359
  },
  {
    "DESCRIPCION": "VILLA MERCEDES",
    "CODIGO POSTAL": 5730,
    "PROVINCIA": "D",
    "AFIP": 22118
  },
  {
    "DESCRIPCION": "VILLA GESELL",
    "CODIGO POSTAL": 7165,
    "PROVINCIA": "B",
    "AFIP": 14580
  },
  {
    "DESCRIPCION": "ALEJANDRO KORN",
    "CODIGO POSTAL": 1864,
    "PROVINCIA": "B",
    "AFIP": 304
  },
  {
    "DESCRIPCION": "ALTOS DE CHIPION",
    "CODIGO POSTAL": 2417,
    "PROVINCIA": "X",
    "AFIP": 467
  },
  {
    "DESCRIPCION": "ARROYO DULCE",
    "CODIGO POSTAL": 2743,
    "PROVINCIA": "B",
    "AFIP": 771
  },
  {
    "DESCRIPCION": "ALBA POSSE",
    "CODIGO POSTAL": 3363,
    "PROVINCIA": "N",
    "AFIP": 244
  },
  {
    "DESCRIPCION": "ALDEA MARIA LUISA",
    "CODIGO POSTAL": 3112,
    "PROVINCIA": "E",
    "AFIP": 276
  },
  {
    "DESCRIPCION": "ALDEA PROTESTANTE",
    "CODIGO POSTAL": 3106,
    "PROVINCIA": "E",
    "AFIP": 278
  },
  {
    "DESCRIPCION": "ALDEA MARIA LUISA",
    "CODIGO POSTAL": 3114,
    "PROVINCIA": "E",
    "AFIP": 276
  },
  {
    "DESCRIPCION": "ALDEA SANTA ROSA",
    "CODIGO POSTAL": 3267,
    "PROVINCIA": "E",
    "AFIP": 292
  },
  {
    "DESCRIPCION": "ALDEA SANTA MARIA",
    "CODIGO POSTAL": 3123,
    "PROVINCIA": "E",
    "AFIP": 291
  },
  {
    "DESCRIPCION": "ALDO BONZI",
    "CODIGO POSTAL": 1770,
    "PROVINCIA": "B",
    "AFIP": 298
  },
  {
    "DESCRIPCION": "ALMIRANTE BROWN",
    "CODIGO POSTAL": 3370,
    "PROVINCIA": "N",
    "AFIP": 15235
  },
  {
    "DESCRIPCION": "AMERICA",
    "CODIGO POSTAL": 6287,
    "PROVINCIA": "B",
    "AFIP": 505
  },
  {
    "DESCRIPCION": "ARISTOBULO DEL VALLE",
    "CODIGO POSTAL": 3364,
    "PROVINCIA": "N",
    "AFIP": 687
  },
  {
    "DESCRIPCION": "BARRIO SAN JOSE",
    "CODIGO POSTAL": 1834,
    "PROVINCIA": "B",
    "AFIP": 1310
  },
  {
    "DESCRIPCION": "BERNARDO DE IRIGOYEN",
    "CODIGO POSTAL": 2248,
    "PROVINCIA": "S",
    "AFIP": 15398
  },
  {
    "DESCRIPCION": "BERON DE ASTRADA",
    "CODIGO POSTAL": 3481,
    "PROVINCIA": "W",
    "AFIP": 1470
  },
  {
    "DESCRIPCION": "BERNARDO DE IRIGOYEN",
    "CODIGO POSTAL": 3366,
    "PROVINCIA": "N",
    "AFIP": 1466
  },
  {
    "DESCRIPCION": "BANDA DEL RIO SALI",
    "CODIGO POSTAL": 4109,
    "PROVINCIA": "T",
    "AFIP": 1158
  },
  {
    "DESCRIPCION": "BUENA ESPERANZA",
    "CODIGO POSTAL": 6277,
    "PROVINCIA": "D",
    "AFIP": 15416
  },
  {
    "DESCRIPCION": "BERNARDO LARROUDE",
    "CODIGO POSTAL": 6220,
    "PROVINCIA": "L",
    "AFIP": 1467
  },
  {
    "DESCRIPCION": "Barrio Parque Velez Sarsfield",
    "CODIGO POSTAL": 5016,
    "PROVINCIA": "X",
    "AFIP": 21857
  },
  {
    "DESCRIPCION": "BARRIO BATAN",
    "CODIGO POSTAL": 7601,
    "PROVINCIA": "B",
    "AFIP": 1228
  },
  {
    "DESCRIPCION": "BELLA VISTA",
    "CODIGO POSTAL": 1661,
    "PROVINCIA": "B",
    "AFIP": 1435
  },
  {
    "DESCRIPCION": "BELTRAN",
    "CODIGO POSTAL": 4205,
    "PROVINCIA": "G",
    "AFIP": 15393
  },
  {
    "DESCRIPCION": "GODEKEN",
    "CODIGO POSTAL": 2639,
    "PROVINCIA": "S",
    "AFIP": 6484
  },
  {
    "DESCRIPCION": "CRUCECITAS 7A SECCION",
    "CODIGO POSTAL": 3110,
    "PROVINCIA": "E",
    "AFIP": 4250
  },
  {
    "DESCRIPCION": "COLONIA BELGRANO",
    "CODIGO POSTAL": 2257,
    "PROVINCIA": "S",
    "AFIP": 15664
  },
  {
    "DESCRIPCION": "CONSCRIPTO BERNARDI",
    "CODIGO POSTAL": 3188,
    "PROVINCIA": "E",
    "AFIP": 4026
  },
  {
    "DESCRIPCION": "CORONEL BOGADO",
    "CODIGO POSTAL": 2103,
    "PROVINCIA": "S",
    "AFIP": 4081
  },
  {
    "DESCRIPCION": "CORONEL BRANDSEN",
    "CODIGO POSTAL": 1980,
    "PROVINCIA": "B",
    "AFIP": 4082
  },
  {
    "DESCRIPCION": "CARLOS CASARES",
    "CODIGO POSTAL": 6530,
    "PROVINCIA": "B",
    "AFIP": 2186
  },
  {
    "DESCRIPCION": "CORONEL CHARLONE",
    "CODIGO POSTAL": 6223,
    "PROVINCIA": "B",
    "AFIP": 4083
  },
  {
    "DESCRIPCION": "CARMEN DE ARECO",
    "CODIGO POSTAL": 6725,
    "PROVINCIA": "B",
    "AFIP": 2201
  },
  {
    "DESCRIPCION": "CORRAL DE BUSTOS",
    "CODIGO POSTAL": 2645,
    "PROVINCIA": "X",
    "AFIP": 4126
  },
  {
    "DESCRIPCION": "CHACRAS DE CORIA",
    "CODIGO POSTAL": 5505,
    "PROVINCIA": "M",
    "AFIP": 2799
  },
  {
    "DESCRIPCION": "CONCEPCION DE LA SIERRA",
    "CODIGO POSTAL": 3355,
    "PROVINCIA": "N",
    "AFIP": 4007
  },
  {
    "DESCRIPCION": "CARMEN DE PATAGONES",
    "CODIGO POSTAL": 8504,
    "PROVINCIA": "B",
    "AFIP": 2202
  },
  {
    "DESCRIPCION": "CONCEPCION DEL URUGUAY",
    "CODIGO POSTAL": 3260,
    "PROVINCIA": "E",
    "AFIP": 4009
  },
  {
    "DESCRIPCION": "COMANDANTE FONTANA",
    "CODIGO POSTAL": 3620,
    "PROVINCIA": "P",
    "AFIP": 3986
  },
  {
    "DESCRIPCION": "CORONEL GRANADA",
    "CODIGO POSTAL": 6062,
    "PROVINCIA": "B",
    "AFIP": 4096
  },
  {
    "DESCRIPCION": "COLONIA MARINA",
    "CODIGO POSTAL": 2424,
    "PROVINCIA": "X",
    "AFIP": 3686
  },
  {
    "DESCRIPCION": "DEVOTO",
    "CODIGO POSTAL": 2424,
    "PROVINCIA": "X",
    "AFIP": 4430
  },
  {
    "DESCRIPCION": "COMANDANTE NICANOR OTAMENDI",
    "CODIGO POSTAL": 7603,
    "PROVINCIA": "B",
    "AFIP": 3991
  },
  {
    "DESCRIPCION": "CARLOS PELLEGRINI",
    "CODIGO POSTAL": 2453,
    "PROVINCIA": "S",
    "AFIP": 2192
  },
  {
    "DESCRIPCION": "COMANDANTE LUIS PIEDRABUENA",
    "CODIGO POSTAL": 9303,
    "PROVINCIA": "Z",
    "AFIP": 3990
  },
  {
    "DESCRIPCION": "CORONEL PRINGLES",
    "CODIGO POSTAL": 8505,
    "PROVINCIA": "B",
    "AFIP": 4113
  },
  {
    "DESCRIPCION": "COLONIA SANTA ROSA",
    "CODIGO POSTAL": 4531,
    "PROVINCIA": "A",
    "AFIP": 15751
  },
  {
    "DESCRIPCION": "COLONIA SANTA ROSA",
    "CODIGO POSTAL": 3421,
    "PROVINCIA": "W",
    "AFIP": 15748
  },
  {
    "DESCRIPCION": "CORONEL SUAREZ",
    "CODIGO POSTAL": 7540,
    "PROVINCIA": "B",
    "AFIP": 4119
  },
  {
    "DESCRIPCION": "CARLOS TEJEDOR",
    "CODIGO POSTAL": 6455,
    "PROVINCIA": "B",
    "AFIP": 2197
  },
  {
    "DESCRIPCION": "25 de Mayo",
    "CODIGO POSTAL": 8201,
    "PROVINCIA": "L",
    "AFIP": 15150
  },
  {
    "DESCRIPCION": "CORONEL VIDAL",
    "CODIGO POSTAL": 7174,
    "PROVINCIA": "B",
    "AFIP": 4120
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1340,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1171,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1272,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1247,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAN BERMUDEZ",
    "CODIGO POSTAL": 2154,
    "PROVINCIA": "S",
    "AFIP": 2132
  },
  {
    "DESCRIPCION": "CAPILLA DEL CARMEN",
    "CODIGO POSTAL": 5963,
    "PROVINCIA": "X",
    "AFIP": 2113
  },
  {
    "DESCRIPCION": "CAPILLA DEL MONTE",
    "CODIGO POSTAL": 5184,
    "PROVINCIA": "X",
    "AFIP": 2115
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1069,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1107,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1134,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1307,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1052,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1021,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1050,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1019,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1025,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1015,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1053,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1367,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1380,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1176,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1007,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1396,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1033,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1010,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1113,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1364,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1172,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1117,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1070,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1106,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1368,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1127,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1008,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1141,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1089,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1215,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1095,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1373,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1358,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1359,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1984,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1116,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1012,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1094,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1111,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1148,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1090,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1112,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1087,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1183,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1163,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "Capitan General Bernardo O'Higgins",
    "CODIGO POSTAL": 2644,
    "PROVINCIA": "X",
    "AFIP": 21932
  },
  {
    "DESCRIPCION": "CAPITAN SARMIENTO",
    "CODIGO POSTAL": 2752,
    "PROVINCIA": "B",
    "AFIP": 2140
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1000,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1004,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1005,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1006,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1009,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1011,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1013,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1014,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1016,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1017,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1018,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1022,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1024,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1034,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1035,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1042,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1043,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1045,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1046,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1047,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1048,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1049,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1057,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1059,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1063,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1067,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1072,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1073,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1075,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1080,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1084,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1086,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1088,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1091,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1093,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1104,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1118,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1123,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1129,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1133,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1138,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1154,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1159,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1181,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1184,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1191,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1193,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1195,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1200,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1203,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1204,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1205,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1225,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1232,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1233,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1235,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1250,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1264,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1266,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1268,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1270,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1271,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1282,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1285,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1308,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1407,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1408,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1414,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1424,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1426,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1427,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1428,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1429,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1430,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1431,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1439,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1440,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1879,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAÑADA RICA",
    "CODIGO POSTAL": 2102,
    "PROVINCIA": "S",
    "AFIP": 15538
  },
  {
    "DESCRIPCION": "CIUDAD JARDIN DEL PALOMAR",
    "CODIGO POSTAL": 1621,
    "PROVINCIA": "B",
    "AFIP": 3063
  },
  {
    "DESCRIPCION": "CORONEL DU GRATY",
    "CODIGO POSTAL": 3541,
    "PROVINCIA": "H",
    "AFIP": 4088
  },
  {
    "DESCRIPCION": "CORONEL MANUEL LEONCIO RICO",
    "CODIGO POSTAL": 3712,
    "PROVINCIA": "G",
    "AFIP": 4104
  },
  {
    "DESCRIPCION": "CORONEL ARNOLD",
    "CODIGO POSTAL": 2123,
    "PROVINCIA": "S",
    "AFIP": 4074
  },
  {
    "DESCRIPCION": "CORONEL BAIGORRIA",
    "CODIGO POSTAL": 5811,
    "PROVINCIA": "X",
    "AFIP": 4076
  },
  {
    "DESCRIPCION": "CORONEL DORREGO",
    "CODIGO POSTAL": 8150,
    "PROVINCIA": "B",
    "AFIP": 4087
  },
  {
    "DESCRIPCION": "CORONEL HILARIOS LAGOS",
    "CODIGO POSTAL": 6228,
    "PROVINCIA": "L",
    "AFIP": 4098
  },
  {
    "DESCRIPCION": "CORONEL MOLDES",
    "CODIGO POSTAL": 5847,
    "PROVINCIA": "X",
    "AFIP": 4108
  },
  {
    "DESCRIPCION": "CORONEL MOLLINEDO",
    "CODIGO POSTAL": 4449,
    "PROVINCIA": "A",
    "AFIP": 4109
  },
  {
    "DESCRIPCION": "CORONEL PRINGLES",
    "CODIGO POSTAL": 7530,
    "PROVINCIA": "B",
    "AFIP": 4113
  },
  {
    "DESCRIPCION": "CORONEL SEGUI",
    "CODIGO POSTAL": 6628,
    "PROVINCIA": "B",
    "AFIP": 4118
  },
  {
    "DESCRIPCION": "COLONIA CASTELAR",
    "CODIGO POSTAL": 2401,
    "PROVINCIA": "S",
    "AFIP": 3294
  },
  {
    "DESCRIPCION": "COLONIA CELINA",
    "CODIGO POSTAL": 3113,
    "PROVINCIA": "E",
    "AFIP": 3301
  },
  {
    "DESCRIPCION": "COLONIA BENITEZ",
    "CODIGO POSTAL": 3505,
    "PROVINCIA": "H",
    "AFIP": 3248
  },
  {
    "DESCRIPCION": "COLONIA BISMARCK",
    "CODIGO POSTAL": 2652,
    "PROVINCIA": "X",
    "AFIP": 3256
  },
  {
    "DESCRIPCION": "COLONIA CAROYA",
    "CODIGO POSTAL": 5223,
    "PROVINCIA": "X",
    "AFIP": 3292
  },
  {
    "DESCRIPCION": "COLONIA EL FISCAL",
    "CODIGO POSTAL": 3509,
    "PROVINCIA": "H",
    "AFIP": 3381
  },
  {
    "DESCRIPCION": "COLONIA LA PROVIDENCIA",
    "CODIGO POSTAL": 3137,
    "PROVINCIA": "E",
    "AFIP": 15690
  },
  {
    "DESCRIPCION": "LOS CEIBOS",
    "CODIGO POSTAL": 3261,
    "PROVINCIA": "E",
    "AFIP": 22285
  },
  {
    "DESCRIPCION": "COLONIA SAN JOSE",
    "CODIGO POSTAL": 3016,
    "PROVINCIA": "S",
    "AFIP": 15720
  },
  {
    "DESCRIPCION": "COLONIA VIDELA",
    "CODIGO POSTAL": 5865,
    "PROVINCIA": "X",
    "AFIP": 3951
  },
  {
    "DESCRIPCION": "COLALAO DEL VALLE",
    "CODIGO POSTAL": 4141,
    "PROVINCIA": "T",
    "AFIP": 3118
  },
  {
    "DESCRIPCION": "SAN MARTIN",
    "CODIGO POSTAL": 5008,
    "PROVINCIA": "X",
    "AFIP": 18814
  },
  {
    "DESCRIPCION": "SAN VICENTE",
    "CODIGO POSTAL": 5006,
    "PROVINCIA": "X",
    "AFIP": 18918
  },
  {
    "DESCRIPCION": "COMANDANTE GRANVILLE",
    "CODIGO POSTAL": 5720,
    "PROVINCIA": "D",
    "AFIP": 3988
  },
  {
    "DESCRIPCION": "CURUPAITY",
    "CODIGO POSTAL": 2342,
    "PROVINCIA": "S",
    "AFIP": 4356
  },
  {
    "DESCRIPCION": "DIEGO DE ALVEAR",
    "CODIGO POSTAL": 6036,
    "PROVINCIA": "S",
    "AFIP": 4437
  },
  {
    "DESCRIPCION": "DELFIN GALLO",
    "CODIGO POSTAL": 4117,
    "PROVINCIA": "T",
    "AFIP": 4397
  },
  {
    "DESCRIPCION": "45211",
    "CODIGO POSTAL": 6501,
    "PROVINCIA": "B",
    "AFIP": 4
  },
  {
    "DESCRIPCION": "DOLAVON",
    "CODIGO POSTAL": 9107,
    "PROVINCIA": "U",
    "AFIP": 4500
  },
  {
    "DESCRIPCION": "DESVIO DR BARROS PAZOS",
    "CODIGO POSTAL": 3569,
    "PROVINCIA": "S",
    "AFIP": 4423
  },
  {
    "DESCRIPCION": "EMPALME VILLA CONSTITUCION",
    "CODIGO POSTAL": 2916,
    "PROVINCIA": "S",
    "AFIP": 5570
  },
  {
    "DESCRIPCION": "EL CARMEN PERICO DE",
    "CODIGO POSTAL": 4603,
    "PROVINCIA": "Y",
    "AFIP": 4770
  },
  {
    "DESCRIPCION": "EL MAITEN",
    "CODIGO POSTAL": 9210,
    "PROVINCIA": "U",
    "AFIP": 5060
  },
  {
    "DESCRIPCION": "EMBAJADOR MARTINI",
    "CODIGO POSTAL": 6203,
    "PROVINCIA": "L",
    "AFIP": 5535
  },
  {
    "DESCRIPCION": "EMPALME SAN CARLOS",
    "CODIGO POSTAL": 3007,
    "PROVINCIA": "S",
    "AFIP": 16448
  },
  {
    "DESCRIPCION": "ESTANISLAO DEL CAMPO",
    "CODIGO POSTAL": 3626,
    "PROVINCIA": "P",
    "AFIP": 5989
  },
  {
    "DESCRIPCION": "ESTACION SAGUIER",
    "CODIGO POSTAL": 2315,
    "PROVINCIA": "S",
    "AFIP": 5724
  },
  {
    "DESCRIPCION": "FLORENCIO VARELA",
    "CODIGO POSTAL": 1888,
    "PROVINCIA": "B",
    "AFIP": 6171
  },
  {
    "DESCRIPCION": "FRAY LUIS BELTRAN",
    "CODIGO POSTAL": 2156,
    "PROVINCIA": "S",
    "AFIP": 16528
  },
  {
    "DESCRIPCION": "FRAY LUIS BELTRAN",
    "CODIGO POSTAL": 5531,
    "PROVINCIA": "M",
    "AFIP": 6259
  },
  {
    "DESCRIPCION": "FELIPE SOLA",
    "CODIGO POSTAL": 8129,
    "PROVINCIA": "B",
    "AFIP": 6089
  },
  {
    "DESCRIPCION": "FRANCISCO MADERO",
    "CODIGO POSTAL": 6472,
    "PROVINCIA": "B",
    "AFIP": 6247
  },
  {
    "DESCRIPCION": "FORTIN OLAVARRIA",
    "CODIGO POSTAL": 6403,
    "PROVINCIA": "B",
    "AFIP": 6216
  },
  {
    "DESCRIPCION": "FRANCISCO ALVAREZ",
    "CODIGO POSTAL": 1746,
    "PROVINCIA": "B",
    "AFIP": 6240
  },
  {
    "DESCRIPCION": "FRANCK",
    "CODIGO POSTAL": 3009,
    "PROVINCIA": "S",
    "AFIP": 6255
  },
  {
    "DESCRIPCION": "GOBERNADOR PIEDRABUENA",
    "CODIGO POSTAL": 4181,
    "PROVINCIA": "T",
    "AFIP": 6473
  },
  {
    "DESCRIPCION": "GRANADERO BAIGORRIA",
    "CODIGO POSTAL": 2152,
    "PROVINCIA": "S",
    "AFIP": 6528
  },
  {
    "DESCRIPCION": "GOBERNADOR BENEGAS",
    "CODIGO POSTAL": 5544,
    "PROVINCIA": "M",
    "AFIP": 6451
  },
  {
    "DESCRIPCION": "GOBERNADOR CRESPO",
    "CODIGO POSTAL": 3044,
    "PROVINCIA": "S",
    "AFIP": 6455
  },
  {
    "DESCRIPCION": "GOBERNADOR GREGORES",
    "CODIGO POSTAL": 9311,
    "PROVINCIA": "Z",
    "AFIP": 6460
  },
  {
    "DESCRIPCION": "GENERAL MANUEL BELGRANO",
    "CODIGO POSTAL": 3615,
    "PROVINCIA": "P",
    "AFIP": 6389
  },
  {
    "DESCRIPCION": "GOBERNADOR UGARTE",
    "CODIGO POSTAL": 6621,
    "PROVINCIA": "B",
    "AFIP": 6480
  },
  {
    "DESCRIPCION": "GOBERNADOR CASTRO",
    "CODIGO POSTAL": 2946,
    "PROVINCIA": "B",
    "AFIP": 6452
  },
  {
    "DESCRIPCION": "GOBERNADOR MANSILLA",
    "CODIGO POSTAL": 2845,
    "PROVINCIA": "E",
    "AFIP": 6465
  },
  {
    "DESCRIPCION": "GENERAL PACHECO",
    "CODIGO POSTAL": 1617,
    "PROVINCIA": "B",
    "AFIP": 6398
  },
  {
    "DESCRIPCION": "GERMANIA",
    "CODIGO POSTAL": 6053,
    "PROVINCIA": "B",
    "AFIP": 6427
  },
  {
    "DESCRIPCION": "GIGENA ALCIRA",
    "CODIGO POSTAL": 5813,
    "PROVINCIA": "X",
    "AFIP": 6433
  },
  {
    "DESCRIPCION": "GOBERNADOR GARMENDIA",
    "CODIGO POSTAL": 4187,
    "PROVINCIA": "T",
    "AFIP": 22235
  },
  {
    "DESCRIPCION": "GENERAL GELLY",
    "CODIGO POSTAL": 2701,
    "PROVINCIA": "S",
    "AFIP": 16541
  },
  {
    "DESCRIPCION": "GENERAL ACHA",
    "CODIGO POSTAL": 8200,
    "PROVINCIA": "L",
    "AFIP": 6353
  },
  {
    "DESCRIPCION": "GENERAL ALVEAR",
    "CODIGO POSTAL": 5620,
    "PROVINCIA": "M",
    "AFIP": 16538
  },
  {
    "DESCRIPCION": "GENERAL ALVEAR",
    "CODIGO POSTAL": 7263,
    "PROVINCIA": "B",
    "AFIP": 6356
  },
  {
    "DESCRIPCION": "GENERAL ARENALES",
    "CODIGO POSTAL": 6005,
    "PROVINCIA": "B",
    "AFIP": 6357
  },
  {
    "DESCRIPCION": "GENERAL BALDISSERA",
    "CODIGO POSTAL": 2583,
    "PROVINCIA": "X",
    "AFIP": 6358
  },
  {
    "DESCRIPCION": "GENERAL BELGRANO",
    "CODIGO POSTAL": 7223,
    "PROVINCIA": "B",
    "AFIP": 6360
  },
  {
    "DESCRIPCION": "GENERAL CABRERA",
    "CODIGO POSTAL": 5809,
    "PROVINCIA": "X",
    "AFIP": 6361
  },
  {
    "DESCRIPCION": "GENERAL CAPDEVILLA",
    "CODIGO POSTAL": 3732,
    "PROVINCIA": "H",
    "AFIP": 6363
  },
  {
    "DESCRIPCION": "GENERAL CONESA",
    "CODIGO POSTAL": 7101,
    "PROVINCIA": "B",
    "AFIP": 6364
  },
  {
    "DESCRIPCION": "GENERAL CONESA",
    "CODIGO POSTAL": 8503,
    "PROVINCIA": "R",
    "AFIP": 16540
  },
  {
    "DESCRIPCION": "GENERAL DEHEZA",
    "CODIGO POSTAL": 5923,
    "PROVINCIA": "X",
    "AFIP": 6366
  },
  {
    "DESCRIPCION": "GENERAL GALARZA",
    "CODIGO POSTAL": 2843,
    "PROVINCIA": "E",
    "AFIP": 6373
  },
  {
    "DESCRIPCION": "GENERAL GUEMES",
    "CODIGO POSTAL": 4430,
    "PROVINCIA": "A",
    "AFIP": 6375
  },
  {
    "DESCRIPCION": "GENERAL GUIDO",
    "CODIGO POSTAL": 7118,
    "PROVINCIA": "B",
    "AFIP": 6376
  },
  {
    "DESCRIPCION": "GENERAL GUTIERREZ",
    "CODIGO POSTAL": 5511,
    "PROVINCIA": "M",
    "AFIP": 6377
  },
  {
    "DESCRIPCION": "GENERAL GUTIERREZ",
    "CODIGO POSTAL": 5593,
    "PROVINCIA": "M",
    "AFIP": 6377
  },
  {
    "DESCRIPCION": "GENERAL LAGOS",
    "CODIGO POSTAL": 2126,
    "PROVINCIA": "S",
    "AFIP": 6381
  },
  {
    "DESCRIPCION": "GENERAL LAMADRID",
    "CODIGO POSTAL": 7406,
    "PROVINCIA": "B",
    "AFIP": 6382
  },
  {
    "DESCRIPCION": "GENERAL LAVALLE",
    "CODIGO POSTAL": 7103,
    "PROVINCIA": "B",
    "AFIP": 6384
  },
  {
    "DESCRIPCION": "GENERAL LEVALLE",
    "CODIGO POSTAL": 6132,
    "PROVINCIA": "X",
    "AFIP": 19371
  },
  {
    "DESCRIPCION": "GENERAL MADARIAGA",
    "CODIGO POSTAL": 7163,
    "PROVINCIA": "B",
    "AFIP": 6387
  },
  {
    "DESCRIPCION": "GENERAL MANSILLA",
    "CODIGO POSTAL": 1911,
    "PROVINCIA": "B",
    "AFIP": 6388
  },
  {
    "DESCRIPCION": "GENERAL O BRIEN",
    "CODIGO POSTAL": 6647,
    "PROVINCIA": "B",
    "AFIP": 6393
  },
  {
    "DESCRIPCION": "GENERAL PAZ",
    "CODIGO POSTAL": 3407,
    "PROVINCIA": "W",
    "AFIP": 6399
  },
  {
    "DESCRIPCION": "GENERAL PICO",
    "CODIGO POSTAL": 6360,
    "PROVINCIA": "L",
    "AFIP": 6401
  },
  {
    "DESCRIPCION": "GENERAL PINTO",
    "CODIGO POSTAL": 6050,
    "PROVINCIA": "B",
    "AFIP": 6403
  },
  {
    "DESCRIPCION": "GENERAL PIRAN",
    "CODIGO POSTAL": 7172,
    "PROVINCIA": "B",
    "AFIP": 6404
  },
  {
    "DESCRIPCION": "GENERAL ROCA",
    "CODIGO POSTAL": 2592,
    "PROVINCIA": "X",
    "AFIP": 6410
  },
  {
    "DESCRIPCION": "GENERAL ROCA",
    "CODIGO POSTAL": 8332,
    "PROVINCIA": "R",
    "AFIP": 16544
  },
  {
    "DESCRIPCION": "GENERAL ROJO",
    "CODIGO POSTAL": 2905,
    "PROVINCIA": "B",
    "AFIP": 21823
  },
  {
    "DESCRIPCION": "GENERAL SAN MARTIN",
    "CODIGO POSTAL": 8206,
    "PROVINCIA": "L",
    "AFIP": 16545
  },
  {
    "DESCRIPCION": "GENERAL VIAMONTE",
    "CODIGO POSTAL": 6016,
    "PROVINCIA": "B",
    "AFIP": 6418
  },
  {
    "DESCRIPCION": "GENERAL VILLEGAS",
    "CODIGO POSTAL": 6230,
    "PROVINCIA": "B",
    "AFIP": 6419
  },
  {
    "DESCRIPCION": "HILARIO ASCASUBI",
    "CODIGO POSTAL": 8142,
    "PROVINCIA": "B",
    "AFIP": 6712
  },
  {
    "DESCRIPCION": "HOLMBERG",
    "CODIGO POSTAL": 5825,
    "PROVINCIA": "X",
    "AFIP": 6734
  },
  {
    "DESCRIPCION": "INES INDART",
    "CODIGO POSTAL": 2747,
    "PROVINCIA": "B",
    "AFIP": 6904
  },
  {
    "DESCRIPCION": "INDIO RICO",
    "CODIGO POSTAL": 7501,
    "PROVINCIA": "B",
    "AFIP": 6902
  },
  {
    "DESCRIPCION": "INGENIERO WHITE",
    "CODIGO POSTAL": 8103,
    "PROVINCIA": "B",
    "AFIP": 6945
  },
  {
    "DESCRIPCION": "INGENIERO BALBIN",
    "CODIGO POSTAL": 6051,
    "PROVINCIA": "B",
    "AFIP": 6917
  },
  {
    "DESCRIPCION": "INGENIERO GIAGNONI",
    "CODIGO POSTAL": 5582,
    "PROVINCIA": "M",
    "AFIP": 6932
  },
  {
    "DESCRIPCION": "INGENIERO HUERGO",
    "CODIGO POSTAL": 8334,
    "PROVINCIA": "R",
    "AFIP": 6934
  },
  {
    "DESCRIPCION": "INGENIERO THOMPSON",
    "CODIGO POSTAL": 6337,
    "PROVINCIA": "B",
    "AFIP": 6944
  },
  {
    "DESCRIPCION": "INTENDENTE ALVEAR",
    "CODIGO POSTAL": 6221,
    "PROVINCIA": "L",
    "AFIP": 6975
  },
  {
    "DESCRIPCION": "INTENDENTE ALVEAR",
    "CODIGO POSTAL": 6221,
    "PROVINCIA": "L",
    "AFIP": 6975
  },
  {
    "DESCRIPCION": "JUNIN DE LOS ANDES",
    "CODIGO POSTAL": 8371,
    "PROVINCIA": "Q",
    "AFIP": 7252
  },
  {
    "DESCRIPCION": "JOSE A GUISASOLA",
    "CODIGO POSTAL": 8151,
    "PROVINCIA": "B",
    "AFIP": 7157
  },
  {
    "DESCRIPCION": "JUAN BAUTISTA ALBERDI",
    "CODIGO POSTAL": 4158,
    "PROVINCIA": "T",
    "AFIP": 16613
  },
  {
    "DESCRIPCION": "JUAN BAUTISTA ALBERDI",
    "CODIGO POSTAL": 6034,
    "PROVINCIA": "B",
    "AFIP": 7190
  },
  {
    "DESCRIPCION": "JUAN B MOLINA",
    "CODIGO POSTAL": 2909,
    "PROVINCIA": "S",
    "AFIP": 7188
  },
  {
    "DESCRIPCION": "JUAN COUSTE",
    "CODIGO POSTAL": 8136,
    "PROVINCIA": "B",
    "AFIP": 7193
  },
  {
    "DESCRIPCION": "JUAN COUSTE",
    "CODIGO POSTAL": 8136,
    "PROVINCIA": "B",
    "AFIP": 7193
  },
  {
    "DESCRIPCION": "JAMES CRAIK",
    "CODIGO POSTAL": 6984,
    "PROVINCIA": "X",
    "AFIP": 7122
  },
  {
    "DESCRIPCION": "JOSE A. GUISASOLA",
    "CODIGO POSTAL": 8156,
    "PROVINCIA": "B",
    "AFIP": 21727
  },
  {
    "DESCRIPCION": "JUAN JOSE CASTELLI",
    "CODIGO POSTAL": 3705,
    "PROVINCIA": "H",
    "AFIP": 7205
  },
  {
    "DESCRIPCION": "JUAN JOSE PASO",
    "CODIGO POSTAL": 6474,
    "PROVINCIA": "B",
    "AFIP": 7206
  },
  {
    "DESCRIPCION": "JOAQUIN V GONZALEZ",
    "CODIGO POSTAL": 4448,
    "PROVINCIA": "A",
    "AFIP": 16610
  },
  {
    "DESCRIPCION": "JOSE CLEMENTE PAZ",
    "CODIGO POSTAL": 1665,
    "PROVINCIA": "B",
    "AFIP": 7161
  },
  {
    "DESCRIPCION": "JOSE LEON SUAREZ",
    "CODIGO POSTAL": 1655,
    "PROVINCIA": "B",
    "AFIP": 7169
  },
  {
    "DESCRIPCION": "JUSTINIANO POSSE",
    "CODIGO POSTAL": 2553,
    "PROVINCIA": "X",
    "AFIP": 7264
  },
  {
    "DESCRIPCION": "LOMAS DEL MIRADOR",
    "CODIGO POSTAL": 1752,
    "PROVINCIA": "B",
    "AFIP": 8904
  },
  {
    "DESCRIPCION": "LOS BOULEVARES",
    "CODIGO POSTAL": 5147,
    "PROVINCIA": "X",
    "AFIP": 21921
  },
  {
    "DESCRIPCION": "LOS CONQUISTADORES",
    "CODIGO POSTAL": 3183,
    "PROVINCIA": "E",
    "AFIP": 9077
  },
  {
    "DESCRIPCION": "LOMAS DE ZAMORA",
    "CODIGO POSTAL": 1832,
    "PROVINCIA": "B",
    "AFIP": 8902
  },
  {
    "DESCRIPCION": "LUCAS GONZALEZ",
    "CODIGO POSTAL": 3158,
    "PROVINCIA": "E",
    "AFIP": 9411
  },
  {
    "DESCRIPCION": "LEANDRO N ALEM",
    "CODIGO POSTAL": 6032,
    "PROVINCIA": "B",
    "AFIP": 8741
  },
  {
    "DESCRIPCION": "LISANDRO OLMOS (SUDESTE)",
    "CODIGO POSTAL": 1901,
    "PROVINCIA": "B",
    "AFIP": 22259
  },
  {
    "DESCRIPCION": "PASTEUR",
    "CODIGO POSTAL": 6077,
    "PROVINCIA": "B",
    "AFIP": 10915
  },
  {
    "DESCRIPCION": "LOS QUIRQUINCHOS",
    "CODIGO POSTAL": 2637,
    "PROVINCIA": "S",
    "AFIP": 17947
  },
  {
    "DESCRIPCION": "LA PAZ",
    "CODIGO POSTAL": 5879,
    "PROVINCIA": "X",
    "AFIP": 17150
  },
  {
    "DESCRIPCION": "LAS CUEVAS",
    "CODIGO POSTAL": 5557,
    "PROVINCIA": "M",
    "AFIP": 17490
  },
  {
    "DESCRIPCION": "LOMA HERMOSA",
    "CODIGO POSTAL": 1657,
    "PROVINCIA": "B",
    "AFIP": 8872
  },
  {
    "DESCRIPCION": "LOS JURIES",
    "CODIGO POSTAL": 3763,
    "PROVINCIA": "G",
    "AFIP": 9167
  },
  {
    "DESCRIPCION": "MANUEL B GONNET",
    "CODIGO POSTAL": 1897,
    "PROVINCIA": "B",
    "AFIP": 9597
  },
  {
    "DESCRIPCION": "MAYOR BURATOVICH",
    "CODIGO POSTAL": 8146,
    "PROVINCIA": "B",
    "AFIP": 9760
  },
  {
    "DESCRIPCION": "MICAELA CASCALLARES",
    "CODIGO POSTAL": 7507,
    "PROVINCIA": "B",
    "AFIP": 9847
  },
  {
    "DESCRIPCION": "MONTE DE LOS GAUCHOS",
    "CODIGO POSTAL": 5839,
    "PROVINCIA": "X",
    "AFIP": 10051
  },
  {
    "DESCRIPCION": "MARCELINO ESCALADA",
    "CODIGO POSTAL": 3042,
    "PROVINCIA": "S",
    "AFIP": 9635
  },
  {
    "DESCRIPCION": "MANUEL OCAMPO",
    "CODIGO POSTAL": 2713,
    "PROVINCIA": "B",
    "AFIP": 9603
  },
  {
    "DESCRIPCION": "MARTIN CORONADO",
    "CODIGO POSTAL": 1682,
    "PROVINCIA": "B",
    "AFIP": 9698
  },
  {
    "DESCRIPCION": "NORBERTO DE LA RIESTRA",
    "CODIGO POSTAL": 6663,
    "PROVINCIA": "B",
    "AFIP": 10259
  },
  {
    "DESCRIPCION": "NICANOR OLIVERA",
    "CODIGO POSTAL": 6692,
    "PROVINCIA": "B",
    "AFIP": 10218
  },
  {
    "DESCRIPCION": "COMANDANTE NICANOR OTAMENDI",
    "CODIGO POSTAL": 2802,
    "PROVINCIA": "B",
    "AFIP": 3991
  },
  {
    "DESCRIPCION": "ODORQUI",
    "CODIGO POSTAL": 6537,
    "PROVINCIA": "B",
    "AFIP": 10344
  },
  {
    "DESCRIPCION": "PLANICIE BANDERITA",
    "CODIGO POSTAL": 8301,
    "PROVINCIA": "Q",
    "AFIP": 11203
  },
  {
    "DESCRIPCION": "PRESIDENCIA DE LA PLAZA",
    "CODIGO POSTAL": 3536,
    "PROVINCIA": "H",
    "AFIP": 11524
  },
  {
    "DESCRIPCION": "PASO DE LOS LIBRES",
    "CODIGO POSTAL": 3230,
    "PROVINCIA": "W",
    "AFIP": 18219
  },
  {
    "DESCRIPCION": "PIEDRA DEL AGUILA",
    "CODIGO POSTAL": 8315,
    "PROVINCIA": "Q",
    "AFIP": 11093
  },
  {
    "DESCRIPCION": "ESPERANZA",
    "CODIGO POSTAL": 3378,
    "PROVINCIA": "N",
    "AFIP": 16464
  },
  {
    "DESCRIPCION": "PUERTO GRAL SAN MARTIN",
    "CODIGO POSTAL": 2202,
    "PROVINCIA": "S",
    "AFIP": 11797
  },
  {
    "DESCRIPCION": "PRESIDENCIA ROQUE SAENZ PEÑA",
    "CODIGO POSTAL": 3700,
    "PROVINCIA": "H",
    "AFIP": 11526
  },
  {
    "DESCRIPCION": "PUERTO SANTA CRUZ",
    "CODIGO POSTAL": 9300,
    "PROVINCIA": "Z",
    "AFIP": 11868
  },
  {
    "DESCRIPCION": "PRESIDENCIA ROCA",
    "CODIGO POSTAL": 3511,
    "PROVINCIA": "H",
    "AFIP": 11525
  },
  {
    "DESCRIPCION": "PRESIDENTE DERQUI",
    "CODIGO POSTAL": 1635,
    "PROVINCIA": "B",
    "AFIP": 11528
  },
  {
    "DESCRIPCION": "PRESIDENTE AVELLANEDA",
    "CODIGO POSTAL": 3386,
    "PROVINCIA": "E",
    "AFIP": 11527
  },
  {
    "DESCRIPCION": "PUERTO BELGRANO",
    "CODIGO POSTAL": 8111,
    "PROVINCIA": "B",
    "AFIP": 11760
  },
  {
    "DESCRIPCION": "PUEBLO MUÑOZ",
    "CODIGO POSTAL": 2120,
    "PROVINCIA": "S",
    "AFIP": 11643
  },
  {
    "DESCRIPCION": "RINCON DE LOS SAUCES",
    "CODIGO POSTAL": 8319,
    "PROVINCIA": "Q",
    "AFIP": 12497
  },
  {
    "DESCRIPCION": "RINCON DE NOGOYA",
    "CODIGO POSTAL": 3155,
    "PROVINCIA": "E",
    "AFIP": 12503
  },
  {
    "DESCRIPCION": "ROSARIO DE LA FRONTERA",
    "CODIGO POSTAL": 4190,
    "PROVINCIA": "A",
    "AFIP": 12701
  },
  {
    "DESCRIPCION": "REMEDIOS DE ESCALADA",
    "CODIGO POSTAL": 1826,
    "PROVINCIA": "B",
    "AFIP": 12415
  },
  {
    "DESCRIPCION": "RODEO DE LA CRUZ",
    "CODIGO POSTAL": 5525,
    "PROVINCIA": "M",
    "AFIP": 12657
  },
  {
    "DESCRIPCION": "RIO DE LOS SAUCES",
    "CODIGO POSTAL": 5821,
    "PROVINCIA": "X",
    "AFIP": 12582
  },
  {
    "DESCRIPCION": "PUEBLO RAMONA",
    "CODIGO POSTAL": 2301,
    "PROVINCIA": "S",
    "AFIP": 11652
  },
  {
    "DESCRIPCION": "RINCON DE MILBERG",
    "CODIGO POSTAL": 1789,
    "PROVINCIA": "B",
    "AFIP": 12500
  },
  {
    "DESCRIPCION": "ROSARIO DEL TALA",
    "CODIGO POSTAL": 3174,
    "PROVINCIA": "E",
    "AFIP": 12705
  },
  {
    "DESCRIPCION": "SAN FRANCISCO SOLANO",
    "CODIGO POSTAL": 1881,
    "PROVINCIA": "B",
    "AFIP": 12973
  },
  {
    "DESCRIPCION": "SALTO DE LAS ROSAS",
    "CODIGO POSTAL": 5603,
    "PROVINCIA": "M",
    "AFIP": 12861
  },
  {
    "DESCRIPCION": "SANTA CLARA DE BUENA VISTA",
    "CODIGO POSTAL": 2258,
    "PROVINCIA": "S",
    "AFIP": 13193
  },
  {
    "DESCRIPCION": "BARILOCHE",
    "CODIGO POSTAL": 8400,
    "PROVINCIA": "R",
    "AFIP": 1181
  },
  {
    "DESCRIPCION": "SANTA CLARA DE SAGUIER",
    "CODIGO POSTAL": 2405,
    "PROVINCIA": "S",
    "AFIP": 13194
  },
  {
    "DESCRIPCION": "SAN CARLOS CENTRO",
    "CODIGO POSTAL": 3013,
    "PROVINCIA": "S",
    "AFIP": 12925
  },
  {
    "DESCRIPCION": "SAN CARLOS NORTE",
    "CODIGO POSTAL": 3010,
    "PROVINCIA": "S",
    "AFIP": 12927
  },
  {
    "DESCRIPCION": "SAN CLEMENTE DEL TUYU",
    "CODIGO POSTAL": 7105,
    "PROVINCIA": "B",
    "AFIP": 12933
  },
  {
    "DESCRIPCION": "SIERRA DE LA VENTANA",
    "CODIGO POSTAL": 8168,
    "PROVINCIA": "B",
    "AFIP": 13449
  },
  {
    "DESCRIPCION": "SAN FRANCISCO DEL CHAÑAR",
    "CODIGO POSTAL": 5209,
    "PROVINCIA": "X",
    "AFIP": 12969
  },
  {
    "DESCRIPCION": "SAN FERNANDO DEL VALLE DE CATAMARCA",
    "CODIGO POSTAL": 4700,
    "PROVINCIA": "K",
    "AFIP": 21843
  },
  {
    "DESCRIPCION": "SAN FRANCISCO DE BELLOCQ",
    "CODIGO POSTAL": 4505,
    "PROVINCIA": "B",
    "AFIP": 12965
  },
  {
    "DESCRIPCION": "SAN GENARO NORTE",
    "CODIGO POSTAL": 2147,
    "PROVINCIA": "S",
    "AFIP": 12977
  },
  {
    "DESCRIPCION": "SAN JOSE DE FELICIANO",
    "CODIGO POSTAL": 3187,
    "PROVINCIA": "E",
    "AFIP": 13013
  },
  {
    "DESCRIPCION": "SAN JOSE DE LA DORMIDA",
    "CODIGO POSTAL": 5244,
    "PROVINCIA": "X",
    "AFIP": 13018
  },
  {
    "DESCRIPCION": "SAN JOSE DE LA ESQUINA",
    "CODIGO POSTAL": 2185,
    "PROVINCIA": "S",
    "AFIP": 13019
  },
  {
    "DESCRIPCION": "SAN JERONIMO NORTE",
    "CODIGO POSTAL": 3011,
    "PROVINCIA": "S",
    "AFIP": 13000
  },
  {
    "DESCRIPCION": "SAN JERONIMO NORTE",
    "CODIGO POSTAL": 3011,
    "PROVINCIA": "S",
    "AFIP": 13000
  },
  {
    "DESCRIPCION": "SAN JERONIMO SUD",
    "CODIGO POSTAL": 2136,
    "PROVINCIA": "S",
    "AFIP": 13001
  },
  {
    "DESCRIPCION": "SAN JOSE",
    "CODIGO POSTAL": 3806,
    "PROVINCIA": "N",
    "AFIP": 18752
  },
  {
    "DESCRIPCION": "SAN LUIS DEL PALMAR",
    "CODIGO POSTAL": 3403,
    "PROVINCIA": "W",
    "AFIP": 13070
  },
  {
    "DESCRIPCION": "SAN MARTIN DE LOS ANDES",
    "CODIGO POSTAL": 8370,
    "PROVINCIA": "Q",
    "AFIP": 13083
  },
  {
    "DESCRIPCION": "SANTA MARIA DE PUNILLA",
    "CODIGO POSTAL": 5164,
    "PROVINCIA": "X",
    "AFIP": 13234
  },
  {
    "DESCRIPCION": "SAN MIGUEL DEL MONTE",
    "CODIGO POSTAL": 7220,
    "PROVINCIA": "B",
    "AFIP": 13097
  },
  {
    "DESCRIPCION": "SAN MARTIN DE LAS ESCOBAS",
    "CODIGO POSTAL": 2449,
    "PROVINCIA": "S",
    "AFIP": 13082
  },
  {
    "DESCRIPCION": "SAN MIGUEL DE TUCUMAN",
    "CODIGO POSTAL": 4000,
    "PROVINCIA": "T",
    "AFIP": 13095
  },
  {
    "DESCRIPCION": "VIRGINIA",
    "CODIGO POSTAL": 4186,
    "PROVINCIA": "T",
    "AFIP": 19346
  },
  {
    "DESCRIPCION": "SAN MIGUEL",
    "CODIGO POSTAL": 3485,
    "PROVINCIA": "W",
    "AFIP": 18827
  },
  {
    "DESCRIPCION": "SAN PEDRO DE JUJUY",
    "CODIGO POSTAL": 4500,
    "PROVINCIA": "Y",
    "AFIP": 22077
  },
  {
    "DESCRIPCION": "SANTA ROSA DE RIO PRIMERO",
    "CODIGO POSTAL": 5196,
    "PROVINCIA": "X",
    "AFIP": 13262
  },
  {
    "DESCRIPCION": "SAN SALVADOR DE JUJUY",
    "CODIGO POSTAL": 4600,
    "PROVINCIA": "Y",
    "AFIP": 13149
  },
  {
    "DESCRIPCION": "Sancti Spiritu",
    "CODIGO POSTAL": 2617,
    "PROVINCIA": "S",
    "AFIP": 13166
  },
  {
    "DESCRIPCION": "SA PEREYRA",
    "CODIGO POSTAL": 2318,
    "PROVINCIA": "S",
    "AFIP": 12786
  },
  {
    "DESCRIPCION": "SAENZ PEÑA",
    "CODIGO POSTAL": 1674,
    "PROVINCIA": "B",
    "AFIP": 21712
  },
  {
    "DESCRIPCION": "SAN ANTONIO DE ARECO",
    "CODIGO POSTAL": 2760,
    "PROVINCIA": "B",
    "AFIP": 12886
  },
  {
    "DESCRIPCION": "SAN ANDRES DE GILES",
    "CODIGO POSTAL": 6720,
    "PROVINCIA": "B",
    "AFIP": 12881
  },
  {
    "DESCRIPCION": "SAN ANTONIO OESTE",
    "CODIGO POSTAL": 8520,
    "PROVINCIA": "R",
    "AFIP": 12907
  },
  {
    "DESCRIPCION": "LA CLOTILDE",
    "CODIGO POSTAL": 3701,
    "PROVINCIA": "H",
    "AFIP": 7503
  },
  {
    "DESCRIPCION": "SAN JOSE",
    "CODIGO POSTAL": 3306,
    "PROVINCIA": "N",
    "AFIP": 18752
  },
  {
    "DESCRIPCION": "SAN PEDRO",
    "CODIGO POSTAL": 3021,
    "PROVINCIA": "S",
    "AFIP": 18865
  },
  {
    "DESCRIPCION": "SEBASTIAN EL CANO",
    "CODIGO POSTAL": 5231,
    "PROVINCIA": "X",
    "AFIP": 13381
  },
  {
    "DESCRIPCION": "SANTIAGO DEL ESTERO",
    "CODIGO POSTAL": 4200,
    "PROVINCIA": "G",
    "AFIP": 13288
  },
  {
    "DESCRIPCION": "SARGENTO CABRAL",
    "CODIGO POSTAL": 2106,
    "PROVINCIA": "S",
    "AFIP": 19102
  },
  {
    "DESCRIPCION": "TERMAS DE RIO HONDO",
    "CODIGO POSTAL": 4220,
    "PROVINCIA": "G",
    "AFIP": 13831
  },
  {
    "DESCRIPCION": "TENIENTE ORIGONE",
    "CODIGO POSTAL": 8144,
    "PROVINCIA": "B",
    "AFIP": 13812
  },
  {
    "DESCRIPCION": "TENIENTE ORIGONE",
    "CODIGO POSTAL": 8444,
    "PROVINCIA": "B",
    "AFIP": 13812
  },
  {
    "DESCRIPCION": "VILLA BERTHET",
    "CODIGO POSTAL": 3545,
    "PROVINCIA": "H",
    "AFIP": 14423
  },
  {
    "DESCRIPCION": "VILLA CARLOS PAZ",
    "CODIGO POSTAL": 5152,
    "PROVINCIA": "X",
    "AFIP": 14442
  },
  {
    "DESCRIPCION": "VILLA CONSTITUCION",
    "CODIGO POSTAL": 2919,
    "PROVINCIA": "S",
    "AFIP": 14474
  },
  {
    "DESCRIPCION": "44977",
    "CODIGO POSTAL": 4975,
    "PROVINCIA": "A",
    "AFIP": 10
  },
  {
    "DESCRIPCION": "VILLA MARIA DEL RIO SECO",
    "CODIGO POSTAL": 5248,
    "PROVINCIA": "X",
    "AFIP": 21885
  },
  {
    "DESCRIPCION": "VILLA DEL CARMEN",
    "CODIGO POSTAL": 5835,
    "PROVINCIA": "D",
    "AFIP": 19291
  },
  {
    "DESCRIPCION": "VILLA DEL ROSARIO",
    "CODIGO POSTAL": 3229,
    "PROVINCIA": "E",
    "AFIP": 19292
  },
  {
    "DESCRIPCION": "VILLA DEL ROSARIO",
    "CODIGO POSTAL": 5964,
    "PROVINCIA": "X",
    "AFIP": 14505
  },
  {
    "DESCRIPCION": "VILLA DEL TOTORAL",
    "CODIGO POSTAL": 5236,
    "PROVINCIA": "X",
    "AFIP": 14508
  },
  {
    "DESCRIPCION": "VILLA DOMINGUEZ",
    "CODIGO POSTAL": 3246,
    "PROVINCIA": "E",
    "AFIP": 14519
  },
  {
    "DESCRIPCION": "VILLA ELISA",
    "CODIGO POSTAL": 3265,
    "PROVINCIA": "E",
    "AFIP": 19299
  },
  {
    "DESCRIPCION": "VILLA GOBERNADOR GALVEZ",
    "CODIGO POSTAL": 2124,
    "PROVINCIA": "S",
    "AFIP": 14585
  },
  {
    "DESCRIPCION": "VILLA GENERAL BELGRANO",
    "CODIGO POSTAL": 5194,
    "PROVINCIA": "X",
    "AFIP": 14572
  },
  {
    "DESCRIPCION": "VILLA GENERAL GUEMES",
    "CODIGO POSTAL": 3621,
    "PROVINCIA": "P",
    "AFIP": 14573
  },
  {
    "DESCRIPCION": "VILLA HUIDOBRO",
    "CODIGO POSTAL": 6275,
    "PROVINCIA": "X",
    "AFIP": 14605
  },
  {
    "DESCRIPCION": "VILLA LIBERTADOR SAN MARTIN",
    "CODIGO POSTAL": 3103,
    "PROVINCIA": "E",
    "AFIP": 14649
  },
  {
    "DESCRIPCION": "VILLA LA ANGOSTURA",
    "CODIGO POSTAL": 8407,
    "PROVINCIA": "Q",
    "AFIP": 14620
  },
  {
    "DESCRIPCION": "VILLA MARIA DEL RIO SECO",
    "CODIGO POSTAL": 5710,
    "PROVINCIA": "X",
    "AFIP": 21885
  },
  {
    "DESCRIPCION": "VILLA REGINA",
    "CODIGO POSTAL": 6336,
    "PROVINCIA": "R",
    "AFIP": 14761
  },
  {
    "DESCRIPCION": "VILLA SAN LORENZO",
    "CODIGO POSTAL": 4401,
    "PROVINCIA": "A",
    "AFIP": 14798
  },
  {
    "DESCRIPCION": "VICUÑA MACKENNA",
    "CODIGO POSTAL": 6140,
    "PROVINCIA": "X",
    "AFIP": 14353
  },
  {
    "DESCRIPCION": "Wenceslao ESCALANTE",
    "CODIGO POSTAL": 2655,
    "PROVINCIA": "X",
    "AFIP": 14963
  },
  {
    "DESCRIPCION": "EL TURBIO",
    "CODIGO POSTAL": 9031,
    "PROVINCIA": "Z",
    "AFIP": 5460
  },
  {
    "DESCRIPCION": "25 DE MAYO",
    "CODIGO POSTAL": 6660,
    "PROVINCIA": "B",
    "AFIP": 16
  },
  {
    "DESCRIPCION": "30 DE AGOSTO",
    "CODIGO POSTAL": 6405,
    "PROVINCIA": "B",
    "AFIP": 24
  },
  {
    "DESCRIPCION": "9 DE JULIO",
    "CODIGO POSTAL": 7130,
    "PROVINCIA": "B",
    "AFIP": 30
  },
  {
    "DESCRIPCION": "ABRA PAMPA",
    "CODIGO POSTAL": 4640,
    "PROVINCIA": "Y",
    "AFIP": 58
  },
  {
    "DESCRIPCION": "ACASSUSO",
    "CODIGO POSTAL": 1640,
    "PROVINCIA": "B",
    "AFIP": 71
  },
  {
    "DESCRIPCION": "CAMARONES",
    "CODIGO POSTAL": 9111,
    "PROVINCIA": "U",
    "AFIP": 1781
  },
  {
    "DESCRIPCION": "DOLAVON",
    "CODIGO POSTAL": 9107,
    "PROVINCIA": "U",
    "AFIP": 4500
  },
  {
    "DESCRIPCION": "ESQUEL",
    "CODIGO POSTAL": 9200,
    "PROVINCIA": "U",
    "AFIP": 5634
  },
  {
    "DESCRIPCION": "RIO MAYO",
    "CODIGO POSTAL": 9030,
    "PROVINCIA": "U",
    "AFIP": 12603
  },
  {
    "DESCRIPCION": "SARMIENTO",
    "CODIGO POSTAL": 9020,
    "PROVINCIA": "U",
    "AFIP": 13326
  },
  {
    "DESCRIPCION": "TRELEW",
    "CODIGO POSTAL": 9100,
    "PROVINCIA": "U",
    "AFIP": 14025
  },
  {
    "DESCRIPCION": "TREVELIN",
    "CODIGO POSTAL": 9203,
    "PROVINCIA": "U",
    "AFIP": 14090
  },
  {
    "DESCRIPCION": "4 BOCAS",
    "CODIGO POSTAL": 3185,
    "PROVINCIA": "E",
    "AFIP": 15812
  },
  {
    "DESCRIPCION": "ACEBAL",
    "CODIGO POSTAL": 2109,
    "PROVINCIA": "S",
    "AFIP": 73
  },
  {
    "DESCRIPCION": "ADELIA MARIA",
    "CODIGO POSTAL": 5843,
    "PROVINCIA": "X",
    "AFIP": 21951
  },
  {
    "DESCRIPCION": "ADROGUE",
    "CODIGO POSTAL": 1846,
    "PROVINCIA": "B",
    "AFIP": 19372
  },
  {
    "DESCRIPCION": "AGUA HEDIONDA",
    "CODIGO POSTAL": 2516,
    "PROVINCIA": "X",
    "AFIP": 155
  },
  {
    "DESCRIPCION": "AGUARA GRANDE",
    "CODIGO POSTAL": 3071,
    "PROVINCIA": "S",
    "AFIP": 201
  },
  {
    "DESCRIPCION": "AGUILARES",
    "CODIGO POSTAL": 4152,
    "PROVINCIA": "T",
    "AFIP": 218
  },
  {
    "DESCRIPCION": "AGUSTIN ROCA",
    "CODIGO POSTAL": 6001,
    "PROVINCIA": "B",
    "AFIP": 224
  },
  {
    "DESCRIPCION": "AGUSTINA",
    "CODIGO POSTAL": 6023,
    "PROVINCIA": "B",
    "AFIP": 225
  },
  {
    "DESCRIPCION": "AGUSTONI",
    "CODIGO POSTAL": 6361,
    "PROVINCIA": "L",
    "AFIP": 227
  },
  {
    "DESCRIPCION": "AIMOGASTA",
    "CODIGO POSTAL": 5310,
    "PROVINCIA": "F",
    "AFIP": 232
  },
  {
    "DESCRIPCION": "ALAMEDA",
    "CODIGO POSTAL": 5502,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "ALBERDI",
    "CODIGO POSTAL": 3747,
    "PROVINCIA": "G",
    "AFIP": 15218
  },
  {
    "DESCRIPCION": "ALBERTI",
    "CODIGO POSTAL": 6634,
    "PROVINCIA": "B",
    "AFIP": 252
  },
  {
    "DESCRIPCION": "ALCARAZ",
    "CODIGO POSTAL": 3138,
    "PROVINCIA": "E",
    "AFIP": 22052
  },
  {
    "DESCRIPCION": "ALCARAZ NORTE",
    "CODIGO POSTAL": 3136,
    "PROVINCIA": "E",
    "AFIP": 259
  },
  {
    "DESCRIPCION": "ALCORTA",
    "CODIGO POSTAL": 2117,
    "PROVINCIA": "S",
    "AFIP": 22143
  },
  {
    "DESCRIPCION": "ALDAO",
    "CODIGO POSTAL": 2214,
    "PROVINCIA": "S",
    "AFIP": 266
  },
  {
    "DESCRIPCION": "ALDEA BRASILERA",
    "CODIGO POSTAL": 3104,
    "PROVINCIA": "E",
    "AFIP": 270
  },
  {
    "DESCRIPCION": "ALEJANDRO",
    "CODIGO POSTAL": 2686,
    "PROVINCIA": "X",
    "AFIP": 303
  },
  {
    "DESCRIPCION": "ALEJO LEDESMA",
    "CODIGO POSTAL": 2662,
    "PROVINCIA": "X",
    "AFIP": 309
  },
  {
    "DESCRIPCION": "ALHUAMPA",
    "CODIGO POSTAL": 3742,
    "PROVINCIA": "G",
    "AFIP": 341
  },
  {
    "DESCRIPCION": "ALICIA",
    "CODIGO POSTAL": 5949,
    "PROVINCIA": "X",
    "AFIP": 343
  },
  {
    "DESCRIPCION": "ALLEN",
    "CODIGO POSTAL": 8328,
    "PROVINCIA": "R",
    "AFIP": 350
  },
  {
    "DESCRIPCION": "ALMAFUERTE",
    "CODIGO POSTAL": 5854,
    "PROVINCIA": "X",
    "AFIP": 359
  },
  {
    "DESCRIPCION": "ALMAFUERTE",
    "CODIGO POSTAL": 6017,
    "PROVINCIA": "B",
    "AFIP": 1221
  },
  {
    "DESCRIPCION": "ALPACHIRI",
    "CODIGO POSTAL": 6309,
    "PROVINCIA": "L",
    "AFIP": 370
  },
  {
    "DESCRIPCION": "ALTA GRACIA",
    "CODIGO POSTAL": 5186,
    "PROVINCIA": "X",
    "AFIP": 15239
  },
  {
    "DESCRIPCION": "ALTA ITALIA",
    "CODIGO POSTAL": 6207,
    "PROVINCIA": "L",
    "AFIP": 377
  },
  {
    "DESCRIPCION": "ALTO ALEGRE",
    "CODIGO POSTAL": 5907,
    "PROVINCIA": "X",
    "AFIP": 390
  },
  {
    "DESCRIPCION": "ALTO GRANDE",
    "CODIGO POSTAL": 5893,
    "PROVINCIA": "X",
    "AFIP": 442
  },
  {
    "DESCRIPCION": "ALTO PELADO",
    "CODIGO POSTAL": 5721,
    "PROVINCIA": "D",
    "AFIP": 454
  },
  {
    "DESCRIPCION": "ALUMINE",
    "CODIGO POSTAL": 8345,
    "PROVINCIA": "Q",
    "AFIP": 470
  },
  {
    "DESCRIPCION": "ALVAREZ",
    "CODIGO POSTAL": 2107,
    "PROVINCIA": "S",
    "AFIP": 15256
  },
  {
    "DESCRIPCION": "ALVAREZ JONTE",
    "CODIGO POSTAL": 1921,
    "PROVINCIA": "B",
    "AFIP": 475
  },
  {
    "DESCRIPCION": "ALVEAR",
    "CODIGO POSTAL": 3344,
    "PROVINCIA": "W",
    "AFIP": 477
  },
  {
    "DESCRIPCION": "ALVEAR",
    "CODIGO POSTAL": 2125,
    "PROVINCIA": "S",
    "AFIP": 15257
  },
  {
    "DESCRIPCION": "AMEGHINO",
    "CODIGO POSTAL": 6064,
    "PROVINCIA": "B",
    "AFIP": 21764
  },
  {
    "DESCRIPCION": "FLORENTINO AMEGHINO",
    "CODIGO POSTAL": 6064,
    "PROVINCIA": "B",
    "AFIP": 6172
  },
  {
    "DESCRIPCION": "AMENABAR",
    "CODIGO POSTAL": 6103,
    "PROVINCIA": "S",
    "AFIP": 504
  },
  {
    "DESCRIPCION": "AMERICA",
    "CODIGO POSTAL": 6237,
    "PROVINCIA": "B",
    "AFIP": 505
  },
  {
    "DESCRIPCION": "ANDALGALA",
    "CODIGO POSTAL": 4540,
    "PROVINCIA": "K",
    "AFIP": 549
  },
  {
    "DESCRIPCION": "ANDALGALA",
    "CODIGO POSTAL": 4740,
    "PROVINCIA": "K",
    "AFIP": 549
  },
  {
    "DESCRIPCION": "ANGELICA",
    "CODIGO POSTAL": 2303,
    "PROVINCIA": "S",
    "AFIP": 15263
  },
  {
    "DESCRIPCION": "ANGELICA",
    "CODIGO POSTAL": 4533,
    "PROVINCIA": "A",
    "AFIP": 573
  },
  {
    "DESCRIPCION": "ANGUIL",
    "CODIGO POSTAL": 6326,
    "PROVINCIA": "L",
    "AFIP": 582
  },
  {
    "DESCRIPCION": "ANILLACO",
    "CODIGO POSTAL": 5301,
    "PROVINCIA": "F",
    "AFIP": 15270
  },
  {
    "DESCRIPCION": "ANTILLA",
    "CODIGO POSTAL": 4193,
    "PROVINCIA": "A",
    "AFIP": 604
  },
  {
    "DESCRIPCION": "ANTILLAS",
    "CODIGO POSTAL": 4119,
    "PROVINCIA": "T",
    "AFIP": 605
  },
  {
    "DESCRIPCION": "AÑATUYA",
    "CODIGO POSTAL": 3760,
    "PROVINCIA": "G",
    "AFIP": 963
  },
  {
    "DESCRIPCION": "APOSTOLES",
    "CODIGO POSTAL": 3350,
    "PROVINCIA": "N",
    "AFIP": 634
  },
  {
    "DESCRIPCION": "ARANGUREN",
    "CODIGO POSTAL": 3162,
    "PROVINCIA": "E",
    "AFIP": 642
  },
  {
    "DESCRIPCION": "ARATA",
    "CODIGO POSTAL": 6385,
    "PROVINCIA": "L",
    "AFIP": 647
  },
  {
    "DESCRIPCION": "ARBOLEDAS",
    "CODIGO POSTAL": 6557,
    "PROVINCIA": "B",
    "AFIP": 21810
  },
  {
    "DESCRIPCION": "ARENALES",
    "CODIGO POSTAL": 4185,
    "PROVINCIA": "G",
    "AFIP": 15288
  },
  {
    "DESCRIPCION": "ARENAZA",
    "CODIGO POSTAL": 6075,
    "PROVINCIA": "B",
    "AFIP": 672
  },
  {
    "DESCRIPCION": "AREQUITO",
    "CODIGO POSTAL": 2183,
    "PROVINCIA": "S",
    "AFIP": 675
  },
  {
    "DESCRIPCION": "ARIAS",
    "CODIGO POSTAL": 2624,
    "PROVINCIA": "X",
    "AFIP": 681
  },
  {
    "DESCRIPCION": "ARIZONA",
    "CODIGO POSTAL": 6389,
    "PROVINCIA": "D",
    "AFIP": 689
  },
  {
    "DESCRIPCION": "ARMINDA",
    "CODIGO POSTAL": 2119,
    "PROVINCIA": "S",
    "AFIP": 691
  },
  {
    "DESCRIPCION": "ARMSTRONG",
    "CODIGO POSTAL": 2508,
    "PROVINCIA": "S",
    "AFIP": 693
  },
  {
    "DESCRIPCION": "AROCENA",
    "CODIGO POSTAL": 2242,
    "PROVINCIA": "S",
    "AFIP": 695
  },
  {
    "DESCRIPCION": "ARRECIFES",
    "CODIGO POSTAL": 2740,
    "PROVINCIA": "B",
    "AFIP": 704
  },
  {
    "DESCRIPCION": "ARRIBEÑOS",
    "CODIGO POSTAL": 6007,
    "PROVINCIA": "B",
    "AFIP": 706
  },
  {
    "DESCRIPCION": "ARROYITO",
    "CODIGO POSTAL": 2434,
    "PROVINCIA": "X",
    "AFIP": 708
  },
  {
    "DESCRIPCION": "ARROYO AGUIAR",
    "CODIGO POSTAL": 3014,
    "PROVINCIA": "S",
    "AFIP": 712
  },
  {
    "DESCRIPCION": "ARROYO ALGODON",
    "CODIGO POSTAL": 5909,
    "PROVINCIA": "X",
    "AFIP": 715
  },
  {
    "DESCRIPCION": "ARROYO BARU",
    "CODIGO POSTAL": 3269,
    "PROVINCIA": "E",
    "AFIP": 722
  },
  {
    "DESCRIPCION": "ARROYO CABRAL",
    "CODIGO POSTAL": 5917,
    "PROVINCIA": "X",
    "AFIP": 734
  },
  {
    "DESCRIPCION": "ARROYO CABRAL",
    "CODIGO POSTAL": 8827,
    "PROVINCIA": "X",
    "AFIP": 734
  },
  {
    "DESCRIPCION": "ARROYO SECO",
    "CODIGO POSTAL": 2128,
    "PROVINCIA": "S",
    "AFIP": 15305
  },
  {
    "DESCRIPCION": "ARRUFO",
    "CODIGO POSTAL": 2344,
    "PROVINCIA": "S",
    "AFIP": 885
  },
  {
    "DESCRIPCION": "ARTEAGA",
    "CODIGO POSTAL": 2187,
    "PROVINCIA": "S",
    "AFIP": 888
  },
  {
    "DESCRIPCION": "ATALIVA",
    "CODIGO POSTAL": 2307,
    "PROVINCIA": "S",
    "AFIP": 914
  },
  {
    "DESCRIPCION": "AVELLANEDA",
    "CODIGO POSTAL": 1870,
    "PROVINCIA": "B",
    "AFIP": 937
  },
  {
    "DESCRIPCION": "AVELLANEDA",
    "CODIGO POSTAL": 3561,
    "PROVINCIA": "S",
    "AFIP": 15312
  },
  {
    "DESCRIPCION": "AVERIAS",
    "CODIGO POSTAL": 3766,
    "PROVINCIA": "G",
    "AFIP": 940
  },
  {
    "DESCRIPCION": "AVIA TERAI",
    "CODIGO POSTAL": 3706,
    "PROVINCIA": "H",
    "AFIP": 943
  },
  {
    "DESCRIPCION": "AYACUCHO",
    "CODIGO POSTAL": 7150,
    "PROVINCIA": "B",
    "AFIP": 946
  },
  {
    "DESCRIPCION": "AZUL",
    "CODIGO POSTAL": 7300,
    "PROVINCIA": "B",
    "AFIP": 960
  },
  {
    "DESCRIPCION": "B.ITUZAINGO",
    "CODIGO POSTAL": 5020,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "B.JARD.ESPINOSA",
    "CODIGO POSTAL": 5014,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "BAHIA BLANCA",
    "CODIGO POSTAL": 8000,
    "PROVINCIA": "B",
    "AFIP": 981
  },
  {
    "DESCRIPCION": "BAJO CHICO",
    "CODIGO POSTAL": 5189,
    "PROVINCIA": "X",
    "AFIP": 1019
  },
  {
    "DESCRIPCION": "BALCARCE",
    "CODIGO POSTAL": 7620,
    "PROVINCIA": "B",
    "AFIP": 1056
  },
  {
    "DESCRIPCION": "BALLESTEROS",
    "CODIGO POSTAL": 2572,
    "PROVINCIA": "X",
    "AFIP": 1120
  },
  {
    "DESCRIPCION": "BALNEARIA",
    "CODIGO POSTAL": 5141,
    "PROVINCIA": "X",
    "AFIP": 1124
  },
  {
    "DESCRIPCION": "BANDA DEL MEDIO",
    "CODIGO POSTAL": 8380,
    "PROVINCIA": "Q",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "BANDERA",
    "CODIGO POSTAL": 3064,
    "PROVINCIA": "G",
    "AFIP": 1163
  },
  {
    "DESCRIPCION": "BANDERALO",
    "CODIGO POSTAL": 6244,
    "PROVINCIA": "B",
    "AFIP": 1165
  },
  {
    "DESCRIPCION": "BANFIELD",
    "CODIGO POSTAL": 1828,
    "PROVINCIA": "B",
    "AFIP": 1169
  },
  {
    "DESCRIPCION": "BARADERO",
    "CODIGO POSTAL": 2942,
    "PROVINCIA": "B",
    "AFIP": 1170
  },
  {
    "DESCRIPCION": "BARRANCAS",
    "CODIGO POSTAL": 2246,
    "PROVINCIA": "S",
    "AFIP": 15348
  },
  {
    "DESCRIPCION": "BARRANQUERO",
    "CODIGO POSTAL": 5509,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "BARREAL",
    "CODIGO POSTAL": 5405,
    "PROVINCIA": "J",
    "AFIP": 1199
  },
  {
    "DESCRIPCION": "BATAVIA",
    "CODIGO POSTAL": 6279,
    "PROVINCIA": "D",
    "AFIP": 1364
  },
  {
    "DESCRIPCION": "BAYAUCA",
    "CODIGO POSTAL": 6078,
    "PROVINCIA": "B",
    "AFIP": 1377
  },
  {
    "DESCRIPCION": "BECCAR",
    "CODIGO POSTAL": 1643,
    "PROVINCIA": "B",
    "AFIP": 1420
  },
  {
    "DESCRIPCION": "BELEN",
    "CODIGO POSTAL": 4750,
    "PROVINCIA": "K",
    "AFIP": 1424
  },
  {
    "DESCRIPCION": "BELL VILLE",
    "CODIGO POSTAL": 2550,
    "PROVINCIA": "X",
    "AFIP": 1431
  },
  {
    "DESCRIPCION": "BELLA VISTA",
    "CODIGO POSTAL": 3432,
    "PROVINCIA": "W",
    "AFIP": 15380
  },
  {
    "DESCRIPCION": "BELLA VISTA",
    "CODIGO POSTAL": 4168,
    "PROVINCIA": "T",
    "AFIP": 15392
  },
  {
    "DESCRIPCION": "BELLOCQ",
    "CODIGO POSTAL": 6535,
    "PROVINCIA": "B",
    "AFIP": 1438
  },
  {
    "DESCRIPCION": "BELTRAN",
    "CODIGO POSTAL": 4308,
    "PROVINCIA": "G",
    "AFIP": 15393
  },
  {
    "DESCRIPCION": "BENITO JUAREZ",
    "CODIGO POSTAL": 7020,
    "PROVINCIA": "B",
    "AFIP": 1445
  },
  {
    "DESCRIPCION": "BENJAMIN GOULD",
    "CODIGO POSTAL": 2664,
    "PROVINCIA": "X",
    "AFIP": 1448
  },
  {
    "DESCRIPCION": "BERABEVU",
    "CODIGO POSTAL": 2639,
    "PROVINCIA": "S",
    "AFIP": 22126
  },
  {
    "DESCRIPCION": "BERAZATEGUI",
    "CODIGO POSTAL": 1884,
    "PROVINCIA": "B",
    "AFIP": 1452
  },
  {
    "DESCRIPCION": "BERISSO",
    "CODIGO POSTAL": 1923,
    "PROVINCIA": "B",
    "AFIP": 1455
  },
  {
    "DESCRIPCION": "BERMEJO",
    "CODIGO POSTAL": 5533,
    "PROVINCIA": "M",
    "AFIP": 1458
  },
  {
    "DESCRIPCION": "BERNA",
    "CODIGO POSTAL": 3570,
    "PROVINCIA": "S",
    "AFIP": 1460
  },
  {
    "DESCRIPCION": "BERNASCONI",
    "CODIGO POSTAL": 8204,
    "PROVINCIA": "L",
    "AFIP": 1469
  },
  {
    "DESCRIPCION": "BERROTARAN",
    "CODIGO POSTAL": 5817,
    "PROVINCIA": "X",
    "AFIP": 1473
  },
  {
    "DESCRIPCION": "BERUTTI",
    "CODIGO POSTAL": 6424,
    "PROVINCIA": "B",
    "AFIP": 1474
  },
  {
    "DESCRIPCION": "BIGAND",
    "CODIGO POSTAL": 2177,
    "PROVINCIA": "S",
    "AFIP": 1480
  },
  {
    "DESCRIPCION": "BLAQUIER",
    "CODIGO POSTAL": 6065,
    "PROVINCIA": "B",
    "AFIP": 1493
  },
  {
    "DESCRIPCION": "BOBADAL",
    "CODIGO POSTAL": 4188,
    "PROVINCIA": "G",
    "AFIP": 1503
  },
  {
    "DESCRIPCION": "BOLIVAR",
    "CODIGO POSTAL": 6550,
    "PROVINCIA": "B",
    "AFIP": 1526
  },
  {
    "DESCRIPCION": "BOMBAL",
    "CODIGO POSTAL": 2179,
    "PROVINCIA": "S",
    "AFIP": 1530
  },
  {
    "DESCRIPCION": "BONIFACIO",
    "CODIGO POSTAL": 6439,
    "PROVINCIA": "B",
    "AFIP": 1533
  },
  {
    "DESCRIPCION": "BORDENAVE",
    "CODIGO POSTAL": 8187,
    "PROVINCIA": "B",
    "AFIP": 1540
  },
  {
    "DESCRIPCION": "BOSQUES",
    "CODIGO POSTAL": 1889,
    "PROVINCIA": "B",
    "AFIP": 1552
  },
  {
    "DESCRIPCION": "BOULOGNE",
    "CODIGO POSTAL": 1609,
    "PROVINCIA": "B",
    "AFIP": 1555
  },
  {
    "DESCRIPCION": "BOUQUET",
    "CODIGO POSTAL": 2523,
    "PROVINCIA": "S",
    "AFIP": 1556
  },
  {
    "DESCRIPCION": "BOVRIL",
    "CODIGO POSTAL": 3142,
    "PROVINCIA": "E",
    "AFIP": 22055
  },
  {
    "DESCRIPCION": "BOWEN",
    "CODIGO POSTAL": 5634,
    "PROVINCIA": "M",
    "AFIP": 1559
  },
  {
    "DESCRIPCION": "BRAGADO",
    "CODIGO POSTAL": 6640,
    "PROVINCIA": "B",
    "AFIP": 1563
  },
  {
    "DESCRIPCION": "BULNES",
    "CODIGO POSTAL": 5845,
    "PROVINCIA": "X",
    "AFIP": 1604
  },
  {
    "DESCRIPCION": "BURZACO",
    "CODIGO POSTAL": 1852,
    "PROVINCIA": "B",
    "AFIP": 1610
  },
  {
    "DESCRIPCION": "BUSTINZA",
    "CODIGO POSTAL": 2501,
    "PROVINCIA": "S",
    "AFIP": 1612
  },
  {
    "DESCRIPCION": "CABILDO",
    "CODIGO POSTAL": 8118,
    "PROVINCIA": "B",
    "AFIP": 1649
  },
  {
    "DESCRIPCION": "CABRED",
    "CODIGO POSTAL": 6708,
    "PROVINCIA": "B",
    "AFIP": 4488
  },
  {
    "DESCRIPCION": "CACHARI",
    "CODIGO POSTAL": 7214,
    "PROVINCIA": "B",
    "AFIP": 1669
  },
  {
    "DESCRIPCION": "CACHI",
    "CODIGO POSTAL": 4417,
    "PROVINCIA": "A",
    "AFIP": 15435
  },
  {
    "DESCRIPCION": "CAFAYATE",
    "CODIGO POSTAL": 4427,
    "PROVINCIA": "A",
    "AFIP": 1699
  },
  {
    "DESCRIPCION": "CAFFERATA",
    "CODIGO POSTAL": 2643,
    "PROVINCIA": "S",
    "AFIP": 1700
  },
  {
    "DESCRIPCION": "CALCHAQUI",
    "CODIGO POSTAL": 3050,
    "PROVINCIA": "S",
    "AFIP": 1731
  },
  {
    "DESCRIPCION": "CALCHIN",
    "CODIGO POSTAL": 5201,
    "PROVINCIA": "X",
    "AFIP": 1732
  },
  {
    "DESCRIPCION": "CALCHIN",
    "CODIGO POSTAL": 5969,
    "PROVINCIA": "X",
    "AFIP": 1732
  },
  {
    "DESCRIPCION": "CALETA OLIVIA",
    "CODIGO POSTAL": 9011,
    "PROVINCIA": "Z",
    "AFIP": 1750
  },
  {
    "DESCRIPCION": "CALEUFU",
    "CODIGO POSTAL": 6387,
    "PROVINCIA": "L",
    "AFIP": 1754
  },
  {
    "DESCRIPCION": "CAMILO ALDAO",
    "CODIGO POSTAL": 2585,
    "PROVINCIA": "X",
    "AFIP": 1791
  },
  {
    "DESCRIPCION": "CAMPANA",
    "CODIGO POSTAL": 2804,
    "PROVINCIA": "B",
    "AFIP": 1823
  },
  {
    "DESCRIPCION": "CAMPICHUELO",
    "CODIGO POSTAL": 4552,
    "PROVINCIA": "A",
    "AFIP": 1828
  },
  {
    "DESCRIPCION": "CAMPO DEL CIELO",
    "CODIGO POSTAL": 3735,
    "PROVINCIA": "G",
    "AFIP": 15455
  },
  {
    "DESCRIPCION": "CAMPO GRANDE",
    "CODIGO POSTAL": 3362,
    "PROVINCIA": "N",
    "AFIP": 15459
  },
  {
    "DESCRIPCION": "CAMPO GRANDE",
    "CODIGO POSTAL": 8497,
    "PROVINCIA": "R",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "CAMPO LARGO",
    "CODIGO POSTAL": 3716,
    "PROVINCIA": "H",
    "AFIP": 1956
  },
  {
    "DESCRIPCION": "CAMPO QUIJANO",
    "CODIGO POSTAL": 4407,
    "PROVINCIA": "A",
    "AFIP": 1986
  },
  {
    "DESCRIPCION": "CANALS",
    "CODIGO POSTAL": 2650,
    "PROVINCIA": "X",
    "AFIP": 2045
  },
  {
    "DESCRIPCION": "CANDELARIA",
    "CODIGO POSTAL": 5713,
    "PROVINCIA": "D",
    "AFIP": 15476
  },
  {
    "DESCRIPCION": "CANDELARIA SUD",
    "CODIGO POSTAL": 5233,
    "PROVINCIA": "X",
    "AFIP": 2058
  },
  {
    "DESCRIPCION": "CAPDEVILLE",
    "CODIGO POSTAL": 5543,
    "PROVINCIA": "M",
    "AFIP": 2098
  },
  {
    "DESCRIPCION": "CAPIOVI",
    "CODIGO POSTAL": 3332,
    "PROVINCIA": "N",
    "AFIP": 2127
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1001,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1002,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1003,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1023,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1031,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1036,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1038,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1041,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1054,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1055,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1058,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1060,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1065,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1085,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1092,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1119,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1185,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1188,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1194,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1218,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1226,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1263,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1265,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1288,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1291,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1313,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1324,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1406,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1416,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1417,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1419,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1425,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1437,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1143,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1372,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1293,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1281,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1061,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1062,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1081,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1126,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1037,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1275,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CAPITAL FEDERAL",
    "CODIGO POSTAL": 1079,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "CARABELAS",
    "CODIGO POSTAL": 2704,
    "PROVINCIA": "B",
    "AFIP": 2150
  },
  {
    "DESCRIPCION": "CARAPACHAY",
    "CODIGO POSTAL": 1605,
    "PROVINCIA": "B",
    "AFIP": 2163
  },
  {
    "DESCRIPCION": "CARCARAÑA",
    "CODIGO POSTAL": 2138,
    "PROVINCIA": "S",
    "AFIP": 2170
  },
  {
    "DESCRIPCION": "CARHUE",
    "CODIGO POSTAL": 6430,
    "PROVINCIA": "B",
    "AFIP": 2178
  },
  {
    "DESCRIPCION": "CARLOS BEGUERIE",
    "CODIGO POSTAL": 7247,
    "PROVINCIA": "B",
    "AFIP": 2184
  },
  {
    "DESCRIPCION": "CARLOS KEEN",
    "CODIGO POSTAL": 6701,
    "PROVINCIA": "B",
    "AFIP": 2188
  },
  {
    "DESCRIPCION": "CARMEN",
    "CODIGO POSTAL": 2618,
    "PROVINCIA": "S",
    "AFIP": 2200
  },
  {
    "DESCRIPCION": "CARNERILLO",
    "CODIGO POSTAL": 5166,
    "PROVINCIA": "X",
    "AFIP": 2205
  },
  {
    "DESCRIPCION": "CARRERAS",
    "CODIGO POSTAL": 2729,
    "PROVINCIA": "S",
    "AFIP": 15493
  },
  {
    "DESCRIPCION": "CARRILOBO",
    "CODIGO POSTAL": 5915,
    "PROVINCIA": "X",
    "AFIP": 2227
  },
  {
    "DESCRIPCION": "CARRODILLA",
    "CODIGO POSTAL": 5503,
    "PROVINCIA": "M",
    "AFIP": 22090
  },
  {
    "DESCRIPCION": "CASA DE PIEDRA",
    "CODIGO POSTAL": 8202,
    "PROVINCIA": "L",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "CASBAS",
    "CODIGO POSTAL": 6417,
    "PROVINCIA": "B",
    "AFIP": 2284
  },
  {
    "DESCRIPCION": "CASEROS",
    "CODIGO POSTAL": 1678,
    "PROVINCIA": "B",
    "AFIP": 2289
  },
  {
    "DESCRIPCION": "CASEROS",
    "CODIGO POSTAL": 3262,
    "PROVINCIA": "E",
    "AFIP": 15515
  },
  {
    "DESCRIPCION": "CASILDA",
    "CODIGO POSTAL": 2170,
    "PROVINCIA": "S",
    "AFIP": 2293
  },
  {
    "DESCRIPCION": "CASTELAR",
    "CODIGO POSTAL": 1712,
    "PROVINCIA": "B",
    "AFIP": 2311
  },
  {
    "DESCRIPCION": "CASTELLI",
    "CODIGO POSTAL": 7114,
    "PROVINCIA": "B",
    "AFIP": 2313
  },
  {
    "DESCRIPCION": "CASTILLA",
    "CODIGO POSTAL": 6616,
    "PROVINCIA": "B",
    "AFIP": 2316
  },
  {
    "DESCRIPCION": "CATRIEL",
    "CODIGO POSTAL": 8307,
    "PROVINCIA": "R",
    "AFIP": 2335
  },
  {
    "DESCRIPCION": "CATRILO",
    "CODIGO POSTAL": 6330,
    "PROVINCIA": "L",
    "AFIP": 2336
  },
  {
    "DESCRIPCION": "CAUCETE",
    "CODIGO POSTAL": 5442,
    "PROVINCIA": "J",
    "AFIP": 2340
  },
  {
    "DESCRIPCION": "CAVANAGH",
    "CODIGO POSTAL": 2625,
    "PROVINCIA": "X",
    "AFIP": 2344
  },
  {
    "DESCRIPCION": "CAYASTACITO",
    "CODIGO POSTAL": 3038,
    "PROVINCIA": "S",
    "AFIP": 2351
  },
  {
    "DESCRIPCION": "CENTENARIO",
    "CODIGO POSTAL": 8309,
    "PROVINCIA": "Q",
    "AFIP": 15552
  },
  {
    "DESCRIPCION": "CENTENO",
    "CODIGO POSTAL": 2148,
    "PROVINCIA": "S",
    "AFIP": 2496
  },
  {
    "DESCRIPCION": "CEPEDA",
    "CODIGO POSTAL": 2922,
    "PROVINCIA": "S",
    "AFIP": 2507
  },
  {
    "DESCRIPCION": "CERES",
    "CODIGO POSTAL": 2340,
    "PROVINCIA": "S",
    "AFIP": 2513
  },
  {
    "DESCRIPCION": "CERRILLOS",
    "CODIGO POSTAL": 4403,
    "PROVINCIA": "A",
    "AFIP": 15557
  },
  {
    "DESCRIPCION": "CERRITO",
    "CODIGO POSTAL": 3122,
    "PROVINCIA": "E",
    "AFIP": 15561
  },
  {
    "DESCRIPCION": "CERRITO",
    "CODIGO POSTAL": 3405,
    "PROVINCIA": "W",
    "AFIP": 15560
  },
  {
    "DESCRIPCION": "CERRO AZUL",
    "CODIGO POSTAL": 3313,
    "PROVINCIA": "N",
    "AFIP": 15569
  },
  {
    "DESCRIPCION": "CERVANTES",
    "CODIGO POSTAL": 8326,
    "PROVINCIA": "R",
    "AFIP": 2756
  },
  {
    "DESCRIPCION": "CHABAS",
    "CODIGO POSTAL": 2174,
    "PROVINCIA": "S",
    "AFIP": 2765
  },
  {
    "DESCRIPCION": "CHACABUCO",
    "CODIGO POSTAL": 6740,
    "PROVINCIA": "B",
    "AFIP": 2767
  },
  {
    "DESCRIPCION": "CHAJAN",
    "CODIGO POSTAL": 5837,
    "PROVINCIA": "X",
    "AFIP": 2817
  },
  {
    "DESCRIPCION": "CHAJARI",
    "CODIGO POSTAL": 3228,
    "PROVINCIA": "E",
    "AFIP": 2818
  },
  {
    "DESCRIPCION": "CHAMICAL",
    "CODIGO POSTAL": 5380,
    "PROVINCIA": "F",
    "AFIP": 15612
  },
  {
    "DESCRIPCION": "CHAÑAR",
    "CODIGO POSTAL": 5276,
    "PROVINCIA": "F",
    "AFIP": 15620
  },
  {
    "DESCRIPCION": "CHAÑAR LADEADO",
    "CODIGO POSTAL": 2642,
    "PROVINCIA": "S",
    "AFIP": 2884
  },
  {
    "DESCRIPCION": "CHAPUY",
    "CODIGO POSTAL": 2603,
    "PROVINCIA": "S",
    "AFIP": 2846
  },
  {
    "DESCRIPCION": "CHARATA",
    "CODIGO POSTAL": 3730,
    "PROVINCIA": "H",
    "AFIP": 2853
  },
  {
    "DESCRIPCION": "CHASCOMUS",
    "CODIGO POSTAL": 2130,
    "PROVINCIA": "B",
    "AFIP": 2864
  },
  {
    "DESCRIPCION": "CHAVARRIA",
    "CODIGO POSTAL": 3474,
    "PROVINCIA": "W",
    "AFIP": 15618
  },
  {
    "DESCRIPCION": "CHAZON",
    "CODIGO POSTAL": 2675,
    "PROVINCIA": "X",
    "AFIP": 2877
  },
  {
    "DESCRIPCION": "CHEPES",
    "CODIGO POSTAL": 5270,
    "PROVINCIA": "F",
    "AFIP": 2919
  },
  {
    "DESCRIPCION": "CHEPES",
    "CODIGO POSTAL": 5470,
    "PROVINCIA": "F",
    "AFIP": 2919
  },
  {
    "DESCRIPCION": "CHILECITO",
    "CODIGO POSTAL": 5360,
    "PROVINCIA": "F",
    "AFIP": 15637
  },
  {
    "DESCRIPCION": "CHILLAR",
    "CODIGO POSTAL": 7311,
    "PROVINCIA": "B",
    "AFIP": 2949
  },
  {
    "DESCRIPCION": "CHIMBAS",
    "CODIGO POSTAL": 5413,
    "PROVINCIA": "J",
    "AFIP": 2958
  },
  {
    "DESCRIPCION": "CHIMPAY",
    "CODIGO POSTAL": 8364,
    "PROVINCIA": "R",
    "AFIP": 2963
  },
  {
    "DESCRIPCION": "CHIVILCOY",
    "CODIGO POSTAL": 6620,
    "PROVINCIA": "B",
    "AFIP": 2981
  },
  {
    "DESCRIPCION": "CHOELE CHOEL",
    "CODIGO POSTAL": 8360,
    "PROVINCIA": "R",
    "AFIP": 2987
  },
  {
    "DESCRIPCION": "CHOS MALAL",
    "CODIGO POSTAL": 8353,
    "PROVINCIA": "Q",
    "AFIP": 3001
  },
  {
    "DESCRIPCION": "CHOVET",
    "CODIGO POSTAL": 2633,
    "PROVINCIA": "S",
    "AFIP": 3003
  },
  {
    "DESCRIPCION": "CHRISTOPHERSEN",
    "CODIGO POSTAL": 6039,
    "PROVINCIA": "S",
    "AFIP": 5693
  },
  {
    "DESCRIPCION": "CHUCUL",
    "CODIGO POSTAL": 5805,
    "PROVINCIA": "X",
    "AFIP": 3011
  },
  {
    "DESCRIPCION": "CHUMBICHA",
    "CODIGO POSTAL": 4728,
    "PROVINCIA": "K",
    "AFIP": 3018
  },
  {
    "DESCRIPCION": "CINCO SALTOS",
    "CODIGO POSTAL": 8303,
    "PROVINCIA": "R",
    "AFIP": 3049
  },
  {
    "DESCRIPCION": "CINTRA",
    "CODIGO POSTAL": 2559,
    "PROVINCIA": "X",
    "AFIP": 3050
  },
  {
    "DESCRIPCION": "CINTRA",
    "CODIGO POSTAL": 2559,
    "PROVINCIA": "X",
    "AFIP": 3050
  },
  {
    "DESCRIPCION": "CIPOLLETTI",
    "CODIGO POSTAL": 8324,
    "PROVINCIA": "R",
    "AFIP": 3051
  },
  {
    "DESCRIPCION": "CITY BELL",
    "CODIGO POSTAL": 1896,
    "PROVINCIA": "B",
    "AFIP": 3056
  },
  {
    "DESCRIPCION": "CIUDAD EVITA",
    "CODIGO POSTAL": 1778,
    "PROVINCIA": "B",
    "AFIP": 3061
  },
  {
    "DESCRIPCION": "CIUDAD PERICO",
    "CODIGO POSTAL": 4608,
    "PROVINCIA": "Y",
    "AFIP": 19373
  },
  {
    "DESCRIPCION": "CIUDADELA",
    "CODIGO POSTAL": 1702,
    "PROVINCIA": "B",
    "AFIP": 3065
  },
  {
    "DESCRIPCION": "CLARAZ",
    "CODIGO POSTAL": 7005,
    "PROVINCIA": "B",
    "AFIP": 3067
  },
  {
    "DESCRIPCION": "CLARKE",
    "CODIGO POSTAL": 2218,
    "PROVINCIA": "S",
    "AFIP": 3069
  },
  {
    "DESCRIPCION": "CLODOMIRA",
    "CODIGO POSTAL": 4338,
    "PROVINCIA": "G",
    "AFIP": 3077
  },
  {
    "DESCRIPCION": "CLORINDA",
    "CODIGO POSTAL": 3610,
    "PROVINCIA": "P",
    "AFIP": 3080
  },
  {
    "DESCRIPCION": "CLUCELLAS",
    "CODIGO POSTAL": 2407,
    "PROVINCIA": "S",
    "AFIP": 3081
  },
  {
    "DESCRIPCION": "COLAZO",
    "CODIGO POSTAL": 5981,
    "PROVINCIA": "X",
    "AFIP": 3125
  },
  {
    "DESCRIPCION": "COLOMBRES",
    "CODIGO POSTAL": 4111,
    "PROVINCIA": "T",
    "AFIP": 3149
  },
  {
    "DESCRIPCION": "COLON",
    "CODIGO POSTAL": 2720,
    "PROVINCIA": "B",
    "AFIP": 3151
  },
  {
    "DESCRIPCION": "COLON",
    "CODIGO POSTAL": 3280,
    "PROVINCIA": "E",
    "AFIP": 15654
  },
  {
    "DESCRIPCION": "COLONIA ADELA",
    "CODIGO POSTAL": 3201,
    "PROVINCIA": "E",
    "AFIP": 3176
  },
  {
    "DESCRIPCION": "COLONIA ALMADA",
    "CODIGO POSTAL": 5987,
    "PROVINCIA": "X",
    "AFIP": 3191
  },
  {
    "DESCRIPCION": "COLONIA ALPINA",
    "CODIGO POSTAL": 2341,
    "PROVINCIA": "G",
    "AFIP": 3193
  },
  {
    "DESCRIPCION": "COLONIA BARON",
    "CODIGO POSTAL": 6315,
    "PROVINCIA": "L",
    "AFIP": 3232
  },
  {
    "DESCRIPCION": "COLONIA DORA",
    "CODIGO POSTAL": 4332,
    "PROVINCIA": "G",
    "AFIP": 3353
  },
  {
    "DESCRIPCION": "COLONIA ELISA",
    "CODIGO POSTAL": 3515,
    "PROVINCIA": "H",
    "AFIP": 3413
  },
  {
    "DESCRIPCION": "COLONIA ENSAYO",
    "CODIGO POSTAL": 3102,
    "PROVINCIA": "E",
    "AFIP": 3418
  },
  {
    "DESCRIPCION": "COLONIA LIEBIG",
    "CODIGO POSTAL": 3358,
    "PROVINCIA": "W",
    "AFIP": 3638
  },
  {
    "DESCRIPCION": "COLONIA SERE",
    "CODIGO POSTAL": 6459,
    "PROVINCIA": "B",
    "AFIP": 3895
  },
  {
    "DESCRIPCION": "COMODORO PY",
    "CODIGO POSTAL": 6641,
    "PROVINCIA": "B",
    "AFIP": 3999
  },
  {
    "DESCRIPCION": "CONCARAN",
    "CODIGO POSTAL": 5770,
    "PROVINCIA": "D",
    "AFIP": 4005
  },
  {
    "DESCRIPCION": "CONCEPCION",
    "CODIGO POSTAL": 3423,
    "PROVINCIA": "W",
    "AFIP": 15761
  },
  {
    "DESCRIPCION": "CONCEPCION",
    "CODIGO POSTAL": 4146,
    "PROVINCIA": "T",
    "AFIP": 15763
  },
  {
    "DESCRIPCION": "CONCORDIA",
    "CODIGO POSTAL": 3200,
    "PROVINCIA": "E",
    "AFIP": 4013
  },
  {
    "DESCRIPCION": "CONESA",
    "CODIGO POSTAL": 2907,
    "PROVINCIA": "B",
    "AFIP": 21824
  },
  {
    "DESCRIPCION": "COPETONAS",
    "CODIGO POSTAL": 7511,
    "PROVINCIA": "B",
    "AFIP": 4046
  },
  {
    "DESCRIPCION": "CORDOBA",
    "CODIGO POSTAL": 5000,
    "PROVINCIA": "X",
    "AFIP": 4060
  },
  {
    "DESCRIPCION": "CORDOBA",
    "CODIGO POSTAL": 5000,
    "PROVINCIA": "X",
    "AFIP": 4060
  },
  {
    "DESCRIPCION": "CORONDA",
    "CODIGO POSTAL": 2240,
    "PROVINCIA": "S",
    "AFIP": 4068
  },
  {
    "DESCRIPCION": "CORRALITO",
    "CODIGO POSTAL": 5853,
    "PROVINCIA": "X",
    "AFIP": 15789
  },
  {
    "DESCRIPCION": "CORREA",
    "CODIGO POSTAL": 2506,
    "PROVINCIA": "S",
    "AFIP": 15795
  },
  {
    "DESCRIPCION": "CORRIENTES",
    "CODIGO POSTAL": 3400,
    "PROVINCIA": "W",
    "AFIP": 4156
  },
  {
    "DESCRIPCION": "CORZUELA",
    "CODIGO POSTAL": 3718,
    "PROVINCIA": "H",
    "AFIP": 4165
  },
  {
    "DESCRIPCION": "COSTA DE ARAUJO",
    "CODIGO POSTAL": 5535,
    "PROVINCIA": "M",
    "AFIP": 4179
  },
  {
    "DESCRIPCION": "COSTA SACATE",
    "CODIGO POSTAL": 5961,
    "PROVINCIA": "X",
    "AFIP": 4210
  },
  {
    "DESCRIPCION": "CRESPO",
    "CODIGO POSTAL": 3116,
    "PROVINCIA": "E",
    "AFIP": 4234
  },
  {
    "DESCRIPCION": "CRUZ ALTA",
    "CODIGO POSTAL": 2189,
    "PROVINCIA": "X",
    "AFIP": 4255
  },
  {
    "DESCRIPCION": "CRUZ DEL EJE",
    "CODIGO POSTAL": 5280,
    "PROVINCIA": "X",
    "AFIP": 4267
  },
  {
    "DESCRIPCION": "CUADRO NACIONAL",
    "CODIGO POSTAL": 5607,
    "PROVINCIA": "M",
    "AFIP": 4282
  },
  {
    "DESCRIPCION": "CUENCA",
    "CODIGO POSTAL": 6231,
    "PROVINCIA": "B",
    "AFIP": 4317
  },
  {
    "DESCRIPCION": "CURUZU CUATIA",
    "CODIGO POSTAL": 3460,
    "PROVINCIA": "W",
    "AFIP": 4362
  },
  {
    "DESCRIPCION": "CUTRAL CO",
    "CODIGO POSTAL": 8322,
    "PROVINCIA": "Q",
    "AFIP": 4368
  },
  {
    "DESCRIPCION": "DAIREAUX",
    "CODIGO POSTAL": 6555,
    "PROVINCIA": "B",
    "AFIP": 4376
  },
  {
    "DESCRIPCION": "DALMACIO VELEZ",
    "CODIGO POSTAL": 5919,
    "PROVINCIA": "X",
    "AFIP": 21939
  },
  {
    "DESCRIPCION": "DARREGUEIRA",
    "CODIGO POSTAL": 8183,
    "PROVINCIA": "B",
    "AFIP": 4384
  },
  {
    "DESCRIPCION": "DE LA GARMA",
    "CODIGO POSTAL": 7515,
    "PROVINCIA": "B",
    "AFIP": 4390
  },
  {
    "DESCRIPCION": "DEAN FUNES",
    "CODIGO POSTAL": 5200,
    "PROVINCIA": "X",
    "AFIP": 4392
  },
  {
    "DESCRIPCION": "DEL CAMPILLO",
    "CODIGO POSTAL": 6271,
    "PROVINCIA": "X",
    "AFIP": 4393
  },
  {
    "DESCRIPCION": "DEL VALLE",
    "CODIGO POSTAL": 6509,
    "PROVINCIA": "B",
    "AFIP": 4395
  },
  {
    "DESCRIPCION": "DEL VISO",
    "CODIGO POSTAL": 1669,
    "PROVINCIA": "B",
    "AFIP": 4396
  },
  {
    "DESCRIPCION": "DESPEÑADEROS",
    "CODIGO POSTAL": 5121,
    "PROVINCIA": "X",
    "AFIP": 15823
  },
  {
    "DESCRIPCION": "DIAMANTE",
    "CODIGO POSTAL": 3105,
    "PROVINCIA": "E",
    "AFIP": 4432
  },
  {
    "DESCRIPCION": "DIAZ",
    "CODIGO POSTAL": 2222,
    "PROVINCIA": "S",
    "AFIP": 4434
  },
  {
    "DESCRIPCION": "DOBLAS",
    "CODIGO POSTAL": 6305,
    "PROVINCIA": "L",
    "AFIP": 4480
  },
  {
    "DESCRIPCION": "DOCK SUD",
    "CODIGO POSTAL": 1871,
    "PROVINCIA": "B",
    "AFIP": 4485
  },
  {
    "DESCRIPCION": "DOLORES",
    "CODIGO POSTAL": 7100,
    "PROVINCIA": "B",
    "AFIP": 4501
  },
  {
    "DESCRIPCION": "DON TORCUATO",
    "CODIGO POSTAL": 1611,
    "PROVINCIA": "B",
    "AFIP": 4530
  },
  {
    "DESCRIPCION": "DOYLE",
    "CODIGO POSTAL": 2936,
    "PROVINCIA": "B",
    "AFIP": 4565
  },
  {
    "DESCRIPCION": "DUDIGNAC",
    "CODIGO POSTAL": 6505,
    "PROVINCIA": "B",
    "AFIP": 4576
  },
  {
    "DESCRIPCION": "DUFAUR",
    "CODIGO POSTAL": 8164,
    "PROVINCIA": "B",
    "AFIP": 4577
  },
  {
    "DESCRIPCION": "EDUARDO CASTEX",
    "CODIGO POSTAL": 6380,
    "PROVINCIA": "L",
    "AFIP": 4597
  },
  {
    "DESCRIPCION": "EL AGUILAR",
    "CODIGO POSTAL": 4634,
    "PROVINCIA": "Y",
    "AFIP": 4623
  },
  {
    "DESCRIPCION": "EL ALAMITO",
    "CODIGO POSTAL": 4743,
    "PROVINCIA": "K",
    "AFIP": 4628
  },
  {
    "DESCRIPCION": "EL ARAÑADO",
    "CODIGO POSTAL": 5947,
    "PROVINCIA": "X",
    "AFIP": 4653
  },
  {
    "DESCRIPCION": "EL ARBOLITO",
    "CODIGO POSTAL": 2718,
    "PROVINCIA": "B",
    "AFIP": 21818
  },
  {
    "DESCRIPCION": "EL BAJO",
    "CODIGO POSTAL": 4164,
    "PROVINCIA": "T",
    "AFIP": 15895
  },
  {
    "DESCRIPCION": "EL BOLSON",
    "CODIGO POSTAL": 8430,
    "PROVINCIA": "R",
    "AFIP": 15914
  },
  {
    "DESCRIPCION": "EL CADILLO",
    "CODIGO POSTAL": 4453,
    "PROVINCIA": "D",
    "AFIP": 4736
  },
  {
    "DESCRIPCION": "EL CARMEN",
    "CODIGO POSTAL": 4627,
    "PROVINCIA": "Y",
    "AFIP": 22080
  },
  {
    "DESCRIPCION": "EL CARRIL",
    "CODIGO POSTAL": 4421,
    "PROVINCIA": "A",
    "AFIP": 4774
  },
  {
    "DESCRIPCION": "EL COLORADO",
    "CODIGO POSTAL": 3603,
    "PROVINCIA": "P",
    "AFIP": 16002
  },
  {
    "DESCRIPCION": "EL COLORADO",
    "CODIGO POSTAL": 3603,
    "PROVINCIA": "P",
    "AFIP": 16002
  },
  {
    "DESCRIPCION": "EL FORTIN",
    "CODIGO POSTAL": 5951,
    "PROVINCIA": "X",
    "AFIP": 4966
  },
  {
    "DESCRIPCION": "EL GALPON",
    "CODIGO POSTAL": 4444,
    "PROVINCIA": "A",
    "AFIP": 22104
  },
  {
    "DESCRIPCION": "EL MILAGRO",
    "CODIGO POSTAL": 5262,
    "PROVINCIA": "F",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "EL MILAGRO",
    "CODIGO POSTAL": 5274,
    "PROVINCIA": "A",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "EL MOLLAR",
    "CODIGO POSTAL": 4135,
    "PROVINCIA": "T",
    "AFIP": 16138
  },
  {
    "DESCRIPCION": "SANTA FELISA",
    "CODIGO POSTAL": 4197,
    "PROVINCIA": "G",
    "AFIP": 18985
  },
  {
    "DESCRIPCION": "EL PALOMAR",
    "CODIGO POSTAL": 1684,
    "PROVINCIA": "B",
    "AFIP": 5170
  },
  {
    "DESCRIPCION": "EL PARAISO",
    "CODIGO POSTAL": 2917,
    "PROVINCIA": "B",
    "AFIP": 5180
  },
  {
    "DESCRIPCION": "EL PINGO",
    "CODIGO POSTAL": 3132,
    "PROVINCIA": "E",
    "AFIP": 16196
  },
  {
    "DESCRIPCION": "EL PLUMERILLO",
    "CODIGO POSTAL": 5541,
    "PROVINCIA": "M",
    "AFIP": 16198
  },
  {
    "DESCRIPCION": "EL PORVENIR",
    "CODIGO POSTAL": 7265,
    "PROVINCIA": "B",
    "AFIP": 5263
  },
  {
    "DESCRIPCION": "EL PUEBLITO",
    "CODIGO POSTAL": 3151,
    "PROVINCIA": "E",
    "AFIP": 16244
  },
  {
    "DESCRIPCION": "EL SOCORRO",
    "CODIGO POSTAL": 2715,
    "PROVINCIA": "B",
    "AFIP": 5378
  },
  {
    "DESCRIPCION": "EL TALAR",
    "CODIGO POSTAL": 1618,
    "PROVINCIA": "B",
    "AFIP": 5399
  },
  {
    "DESCRIPCION": "EL TIO",
    "CODIGO POSTAL": 2432,
    "PROVINCIA": "X",
    "AFIP": 16398
  },
  {
    "DESCRIPCION": "EL TREBOL",
    "CODIGO POSTAL": 2535,
    "PROVINCIA": "S",
    "AFIP": 5445
  },
  {
    "DESCRIPCION": "EL TUNAL",
    "CODIGO POSTAL": 4446,
    "PROVINCIA": "A",
    "AFIP": 5458
  },
  {
    "DESCRIPCION": "ELDORADO",
    "CODIGO POSTAL": 3380,
    "PROVINCIA": "N",
    "AFIP": 5525
  },
  {
    "DESCRIPCION": "ELENA",
    "CODIGO POSTAL": 5815,
    "PROVINCIA": "X",
    "AFIP": 5526
  },
  {
    "DESCRIPCION": "ELEODORO LOBOS",
    "CODIGO POSTAL": 5722,
    "PROVINCIA": "D",
    "AFIP": 5527
  },
  {
    "DESCRIPCION": "ELISA",
    "CODIGO POSTAL": 3029,
    "PROVINCIA": "S",
    "AFIP": 5529
  },
  {
    "DESCRIPCION": "ELORTONDO",
    "CODIGO POSTAL": 2732,
    "PROVINCIA": "S",
    "AFIP": 5531
  },
  {
    "DESCRIPCION": "ELVIRA",
    "CODIGO POSTAL": 7243,
    "PROVINCIA": "B",
    "AFIP": 5534
  },
  {
    "DESCRIPCION": "EMBALSE",
    "CODIGO POSTAL": 5856,
    "PROVINCIA": "X",
    "AFIP": 5536
  },
  {
    "DESCRIPCION": "EMBARCACION",
    "CODIGO POSTAL": 4550,
    "PROVINCIA": "A",
    "AFIP": 5541
  },
  {
    "DESCRIPCION": "EMPEDRADO",
    "CODIGO POSTAL": 3418,
    "PROVINCIA": "W",
    "AFIP": 5571
  },
  {
    "DESCRIPCION": "ENERGIA",
    "CODIGO POSTAL": 7641,
    "PROVINCIA": "B",
    "AFIP": 5578
  },
  {
    "DESCRIPCION": "ENRIQUE CARBO",
    "CODIGO POSTAL": 2852,
    "PROVINCIA": "E",
    "AFIP": 5581
  },
  {
    "DESCRIPCION": "ENSENADA",
    "CODIGO POSTAL": 1925,
    "PROVINCIA": "B",
    "AFIP": 5588
  },
  {
    "DESCRIPCION": "ESCOBAR",
    "CODIGO POSTAL": 1625,
    "PROVINCIA": "B",
    "AFIP": 21839
  },
  {
    "DESCRIPCION": "ESCOBAR",
    "CODIGO POSTAL": 1695,
    "PROVINCIA": "B",
    "AFIP": 21839
  },
  {
    "DESCRIPCION": "ESMERALDA",
    "CODIGO POSTAL": 2456,
    "PROVINCIA": "S",
    "AFIP": 5616
  },
  {
    "DESCRIPCION": "ESMERALDA 561",
    "CODIGO POSTAL": 1370,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "ESPARTILLAR",
    "CODIGO POSTAL": 8171,
    "PROVINCIA": "B",
    "AFIP": 5619
  },
  {
    "DESCRIPCION": "ESPERANZA",
    "CODIGO POSTAL": 3080,
    "PROVINCIA": "S",
    "AFIP": 16465
  },
  {
    "DESCRIPCION": "ESQUINA",
    "CODIGO POSTAL": 3196,
    "PROVINCIA": "W",
    "AFIP": 16473
  },
  {
    "DESCRIPCION": "ETRURIA",
    "CODIGO POSTAL": 2681,
    "PROVINCIA": "X",
    "AFIP": 6018
  },
  {
    "DESCRIPCION": "EUGENIO BUSTOS",
    "CODIGO POSTAL": 5569,
    "PROVINCIA": "M",
    "AFIP": 6020
  },
  {
    "DESCRIPCION": "EUSEBIA",
    "CODIGO POSTAL": 2317,
    "PROVINCIA": "S",
    "AFIP": 6021
  },
  {
    "DESCRIPCION": "EXTERIOR",
    "CODIGO POSTAL": 9999,
    "PROVINCIA": "C",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "EZEIZA",
    "CODIGO POSTAL": 1804,
    "PROVINCIA": "B",
    "AFIP": 6040
  },
  {
    "DESCRIPCION": "FAMAILLA",
    "CODIGO POSTAL": 4132,
    "PROVINCIA": "T",
    "AFIP": 6062
  },
  {
    "DESCRIPCION": "FAMATINA",
    "CODIGO POSTAL": 5365,
    "PROVINCIA": "F",
    "AFIP": 6064
  },
  {
    "DESCRIPCION": "FAMATINA",
    "CODIGO POSTAL": 5365,
    "PROVINCIA": "F",
    "AFIP": 6064
  },
  {
    "DESCRIPCION": "FEDERACION",
    "CODIGO POSTAL": 3206,
    "PROVINCIA": "E",
    "AFIP": 6084
  },
  {
    "DESCRIPCION": "FEDERAL",
    "CODIGO POSTAL": 3180,
    "PROVINCIA": "E",
    "AFIP": 6085
  },
  {
    "DESCRIPCION": "FELICIA",
    "CODIGO POSTAL": 3087,
    "PROVINCIA": "S",
    "AFIP": 6086
  },
  {
    "DESCRIPCION": "FERNANDEZ ORO",
    "CODIGO POSTAL": 8329,
    "PROVINCIA": "R",
    "AFIP": 6370
  },
  {
    "DESCRIPCION": "FERRE",
    "CODIGO POSTAL": 6003,
    "PROVINCIA": "B",
    "AFIP": 6095
  },
  {
    "DESCRIPCION": "FIAMBALA",
    "CODIGO POSTAL": 5345,
    "PROVINCIA": "K",
    "AFIP": 6100
  },
  {
    "DESCRIPCION": "FIGHIERA",
    "CODIGO POSTAL": 2101,
    "PROVINCIA": "S",
    "AFIP": 6104
  },
  {
    "DESCRIPCION": "FIRMAT",
    "CODIGO POSTAL": 2630,
    "PROVINCIA": "S",
    "AFIP": 6153
  },
  {
    "DESCRIPCION": "FLORENCIA",
    "CODIGO POSTAL": 3516,
    "PROVINCIA": "S",
    "AFIP": 16514
  },
  {
    "DESCRIPCION": "FLORIDA",
    "CODIGO POSTAL": 1602,
    "PROVINCIA": "B",
    "AFIP": 6175
  },
  {
    "DESCRIPCION": "FONTEZUELA",
    "CODIGO POSTAL": 2702,
    "PROVINCIA": "B",
    "AFIP": 6180
  },
  {
    "DESCRIPCION": "FORMOSA",
    "CODIGO POSTAL": 3600,
    "PROVINCIA": "P",
    "AFIP": 6181
  },
  {
    "DESCRIPCION": "FRAGA",
    "CODIGO POSTAL": 5736,
    "PROVINCIA": "D",
    "AFIP": 6236
  },
  {
    "DESCRIPCION": "FRENCH",
    "CODIGO POSTAL": 6516,
    "PROVINCIA": "B",
    "AFIP": 6261
  },
  {
    "DESCRIPCION": "FREYRE",
    "CODIGO POSTAL": 2413,
    "PROVINCIA": "X",
    "AFIP": 6262
  },
  {
    "DESCRIPCION": "FRIAS",
    "CODIGO POSTAL": 4230,
    "PROVINCIA": "G",
    "AFIP": 6263
  },
  {
    "DESCRIPCION": "FRONTERA",
    "CODIGO POSTAL": 2438,
    "PROVINCIA": "S",
    "AFIP": 16530
  },
  {
    "DESCRIPCION": "FUENTES",
    "CODIGO POSTAL": 2171,
    "PROVINCIA": "S",
    "AFIP": 6277
  },
  {
    "DESCRIPCION": "FUNES",
    "CODIGO POSTAL": 2132,
    "PROVINCIA": "S",
    "AFIP": 6287
  },
  {
    "DESCRIPCION": "GAHAN",
    "CODIGO POSTAL": 2745,
    "PROVINCIA": "B",
    "AFIP": 6294
  },
  {
    "DESCRIPCION": "GAIMAN",
    "CODIGO POSTAL": 9105,
    "PROVINCIA": "V",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "GALVEZ",
    "CODIGO POSTAL": 2252,
    "PROVINCIA": "S",
    "AFIP": 6308
  },
  {
    "DESCRIPCION": "GANCEDO",
    "CODIGO POSTAL": 3734,
    "PROVINCIA": "H",
    "AFIP": 6310
  },
  {
    "DESCRIPCION": "GARDEY",
    "CODIGO POSTAL": 7003,
    "PROVINCIA": "B",
    "AFIP": 6323
  },
  {
    "DESCRIPCION": "GARIBALDI",
    "CODIGO POSTAL": 2443,
    "PROVINCIA": "S",
    "AFIP": 6325
  },
  {
    "DESCRIPCION": "GARIN",
    "CODIGO POSTAL": 1619,
    "PROVINCIA": "B",
    "AFIP": 6326
  },
  {
    "DESCRIPCION": "GARRE",
    "CODIGO POSTAL": 6411,
    "PROVINCIA": "B",
    "AFIP": 6329
  },
  {
    "DESCRIPCION": "GENERAL CAMPOS",
    "CODIGO POSTAL": 3216,
    "PROVINCIA": "E",
    "AFIP": 6362
  },
  {
    "DESCRIPCION": "GESSLER",
    "CODIGO POSTAL": 2253,
    "PROVINCIA": "S",
    "AFIP": 6430
  },
  {
    "DESCRIPCION": "GILBERT",
    "CODIGO POSTAL": 2828,
    "PROVINCIA": "E",
    "AFIP": 6435
  },
  {
    "DESCRIPCION": "GIRONDO",
    "CODIGO POSTAL": 6451,
    "PROVINCIA": "B",
    "AFIP": 21686
  },
  {
    "DESCRIPCION": "GLEW",
    "CODIGO POSTAL": 1856,
    "PROVINCIA": "B",
    "AFIP": 6443
  },
  {
    "DESCRIPCION": "GOBERNADOR ROCA",
    "CODIGO POSTAL": 3324,
    "PROVINCIA": "N",
    "AFIP": 6474
  },
  {
    "DESCRIPCION": "GODOY CRUZ",
    "CODIGO POSTAL": 5501,
    "PROVINCIA": "M",
    "AFIP": 6486
  },
  {
    "DESCRIPCION": "GONZALEZ CATAN",
    "CODIGO POSTAL": 1759,
    "PROVINCIA": "B",
    "AFIP": 6498
  },
  {
    "DESCRIPCION": "GONZALEZ CHAVEZ",
    "CODIGO POSTAL": 7513,
    "PROVINCIA": "B",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "GONZALEZ MORENO",
    "CODIGO POSTAL": 6239,
    "PROVINCIA": "B",
    "AFIP": 6499
  },
  {
    "DESCRIPCION": "GOWLAND",
    "CODIGO POSTAL": 6608,
    "PROVINCIA": "B",
    "AFIP": 6509
  },
  {
    "DESCRIPCION": "GOYA",
    "CODIGO POSTAL": 3450,
    "PROVINCIA": "W",
    "AFIP": 6510
  },
  {
    "DESCRIPCION": "GENERAL PAZ",
    "CODIGO POSTAL": 5145,
    "PROVINCIA": "X",
    "AFIP": 5703
  },
  {
    "DESCRIPCION": "GRAND BOURG",
    "CODIGO POSTAL": 1615,
    "PROVINCIA": "B",
    "AFIP": 6532
  },
  {
    "DESCRIPCION": "GRANEROS",
    "CODIGO POSTAL": 4159,
    "PROVINCIA": "T",
    "AFIP": 6535
  },
  {
    "DESCRIPCION": "GUALEGUAY",
    "CODIGO POSTAL": 2840,
    "PROVINCIA": "E",
    "AFIP": 6564
  },
  {
    "DESCRIPCION": "GUALEGUAYCHU",
    "CODIGO POSTAL": 2820,
    "PROVINCIA": "E",
    "AFIP": 6565
  },
  {
    "DESCRIPCION": "GUAMINI",
    "CODIGO POSTAL": 6435,
    "PROVINCIA": "B",
    "AFIP": 6575
  },
  {
    "DESCRIPCION": "GUARDIA ESCOLTA",
    "CODIGO POSTAL": 3062,
    "PROVINCIA": "G",
    "AFIP": 6598
  },
  {
    "DESCRIPCION": "GUATIMOZIN",
    "CODIGO POSTAL": 2627,
    "PROVINCIA": "X",
    "AFIP": 6606
  },
  {
    "DESCRIPCION": "GUATRACHE",
    "CODIGO POSTAL": 6311,
    "PROVINCIA": "L",
    "AFIP": 6607
  },
  {
    "DESCRIPCION": "GUAYMALLEN",
    "CODIGO POSTAL": 5572,
    "PROVINCIA": "M",
    "AFIP": 13016
  },
  {
    "DESCRIPCION": "GUERNICA",
    "CODIGO POSTAL": 1862,
    "PROVINCIA": "B",
    "AFIP": 6630
  },
  {
    "DESCRIPCION": "GUERRICO",
    "CODIGO POSTAL": 2717,
    "PROVINCIA": "B",
    "AFIP": 6634
  },
  {
    "DESCRIPCION": "GUIÑAZU SUR",
    "CODIGO POSTAL": 5019,
    "PROVINCIA": "X",
    "AFIP": 21916
  },
  {
    "DESCRIPCION": "HAEDO",
    "CODIGO POSTAL": 1706,
    "PROVINCIA": "B",
    "AFIP": 6651
  },
  {
    "DESCRIPCION": "HASENKAMP",
    "CODIGO POSTAL": 3134,
    "PROVINCIA": "E",
    "AFIP": 6680
  },
  {
    "DESCRIPCION": "HELVECIA",
    "CODIGO POSTAL": 3003,
    "PROVINCIA": "S",
    "AFIP": 6686
  },
  {
    "DESCRIPCION": "HENDERSON",
    "CODIGO POSTAL": 6465,
    "PROVINCIA": "B",
    "AFIP": 6687
  },
  {
    "DESCRIPCION": "HERMOSO CAMPO",
    "CODIGO POSTAL": 3733,
    "PROVINCIA": "H",
    "AFIP": 6693
  },
  {
    "DESCRIPCION": "HERNANDARIAS",
    "CODIGO POSTAL": 3127,
    "PROVINCIA": "E",
    "AFIP": 6695
  },
  {
    "DESCRIPCION": "HERNANDO",
    "CODIGO POSTAL": 5929,
    "PROVINCIA": "X",
    "AFIP": 6697
  },
  {
    "DESCRIPCION": "HERRERA VEGAS",
    "CODIGO POSTAL": 6467,
    "PROVINCIA": "B",
    "AFIP": 6700
  },
  {
    "DESCRIPCION": "HERSILIA",
    "CODIGO POSTAL": 2352,
    "PROVINCIA": "S",
    "AFIP": 6701
  },
  {
    "DESCRIPCION": "HILARIO LAGOS",
    "CODIGO POSTAL": 5178,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "HINOJO",
    "CODIGO POSTAL": 7318,
    "PROVINCIA": "B",
    "AFIP": 6717
  },
  {
    "DESCRIPCION": "HUANCHILLA",
    "CODIGO POSTAL": 6121,
    "PROVINCIA": "X",
    "AFIP": 6789
  },
  {
    "DESCRIPCION": "HUANGUELEN",
    "CODIGO POSTAL": 7545,
    "PROVINCIA": "B",
    "AFIP": 6791
  },
  {
    "DESCRIPCION": "HUANQUEROS",
    "CODIGO POSTAL": 3076,
    "PROVINCIA": "S",
    "AFIP": 6792
  },
  {
    "DESCRIPCION": "HUERTA GRANDE",
    "CODIGO POSTAL": 5174,
    "PROVINCIA": "X",
    "AFIP": 6823
  },
  {
    "DESCRIPCION": "HUGHES",
    "CODIGO POSTAL": 2725,
    "PROVINCIA": "S",
    "AFIP": 6829
  },
  {
    "DESCRIPCION": "HUINCA RENANCO",
    "CODIGO POSTAL": 6270,
    "PROVINCIA": "X",
    "AFIP": 6836
  },
  {
    "DESCRIPCION": "HUMAUACA",
    "CODIGO POSTAL": 4630,
    "PROVINCIA": "Y",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "HUMBERTO PRIMO",
    "CODIGO POSTAL": 2309,
    "PROVINCIA": "S",
    "AFIP": 22134
  },
  {
    "DESCRIPCION": "HUMBOLDT",
    "CODIGO POSTAL": 3081,
    "PROVINCIA": "S",
    "AFIP": 6846
  },
  {
    "DESCRIPCION": "HUYAMAMPA",
    "CODIGO POSTAL": 4336,
    "PROVINCIA": "G",
    "AFIP": 6857
  },
  {
    "DESCRIPCION": "ISIDRO CASANOVA",
    "CODIGO POSTAL": 1765,
    "PROVINCIA": "B",
    "AFIP": 7009
  },
  {
    "DESCRIPCION": "IBARLUCEA",
    "CODIGO POSTAL": 2143,
    "PROVINCIA": "S",
    "AFIP": 6861
  },
  {
    "DESCRIPCION": "IBARRETA",
    "CODIGO POSTAL": 3624,
    "PROVINCIA": "P",
    "AFIP": 6862
  },
  {
    "DESCRIPCION": "ICAÑO",
    "CODIGO POSTAL": 5265,
    "PROVINCIA": "K",
    "AFIP": 6870
  },
  {
    "DESCRIPCION": "IDIAZABAL",
    "CODIGO POSTAL": 2557,
    "PROVINCIA": "X",
    "AFIP": 6874
  },
  {
    "DESCRIPCION": "INRIVILLE",
    "CODIGO POSTAL": 2587,
    "PROVINCIA": "X",
    "AFIP": 6973
  },
  {
    "DESCRIPCION": "IRALA",
    "CODIGO POSTAL": 6743,
    "PROVINCIA": "B",
    "AFIP": 6991
  },
  {
    "DESCRIPCION": "IRENEO PORTELA",
    "CODIGO POSTAL": 2943,
    "PROVINCIA": "B",
    "AFIP": 6995
  },
  {
    "DESCRIPCION": "IRIARTE",
    "CODIGO POSTAL": 6042,
    "PROVINCIA": "B",
    "AFIP": 6996
  },
  {
    "DESCRIPCION": "IRIGOYEN",
    "CODIGO POSTAL": 2249,
    "PROVINCIA": "S",
    "AFIP": 16589
  },
  {
    "DESCRIPCION": "ISCA YACU",
    "CODIGO POSTAL": 4184,
    "PROVINCIA": "G",
    "AFIP": 7004
  },
  {
    "DESCRIPCION": "ISLA DE IBICUY",
    "CODIGO POSTAL": 3184,
    "PROVINCIA": "E",
    "AFIP": 22033
  },
  {
    "DESCRIPCION": "ISLA VERDE",
    "CODIGO POSTAL": 2661,
    "PROVINCIA": "X",
    "AFIP": 16597
  },
  {
    "DESCRIPCION": "ITA IBATE",
    "CODIGO POSTAL": 3480,
    "PROVINCIA": "W",
    "AFIP": 7082
  },
  {
    "DESCRIPCION": "ITALO",
    "CODIGO POSTAL": 6272,
    "PROVINCIA": "X",
    "AFIP": 7086
  },
  {
    "DESCRIPCION": "ITATI",
    "CODIGO POSTAL": 3414,
    "PROVINCIA": "W",
    "AFIP": 7087
  },
  {
    "DESCRIPCION": "ITUZAINGO",
    "CODIGO POSTAL": 1714,
    "PROVINCIA": "B",
    "AFIP": 7096
  },
  {
    "DESCRIPCION": "ITUZAINGO",
    "CODIGO POSTAL": 3302,
    "PROVINCIA": "W",
    "AFIP": 16601
  },
  {
    "DESCRIPCION": "JACHAL",
    "CODIGO POSTAL": 5460,
    "PROVINCIA": "J",
    "AFIP": 7103
  },
  {
    "DESCRIPCION": "JACHAL",
    "CODIGO POSTAL": 5460,
    "PROVINCIA": "J",
    "AFIP": 7103
  },
  {
    "DESCRIPCION": "JACINTO ARAUZ",
    "CODIGO POSTAL": 8208,
    "PROVINCIA": "L",
    "AFIP": 7106
  },
  {
    "DESCRIPCION": "JAMES CRAIK",
    "CODIGO POSTAL": 6984,
    "PROVINCIA": "X",
    "AFIP": 7122
  },
  {
    "DESCRIPCION": "JARDIN AMERICA",
    "CODIGO POSTAL": 3328,
    "PROVINCIA": "N",
    "AFIP": 7126
  },
  {
    "DESCRIPCION": "JAUREGUI",
    "CODIGO POSTAL": 6706,
    "PROVINCIA": "B",
    "AFIP": 21791
  },
  {
    "DESCRIPCION": "JESUS MARIA",
    "CODIGO POSTAL": 5220,
    "PROVINCIA": "X",
    "AFIP": 16606
  },
  {
    "DESCRIPCION": "JIMENEZ",
    "CODIGO POSTAL": 4322,
    "PROVINCIA": "G",
    "AFIP": 7148
  },
  {
    "DESCRIPCION": "JOSEFINA",
    "CODIGO POSTAL": 2403,
    "PROVINCIA": "S",
    "AFIP": 22139
  },
  {
    "DESCRIPCION": "JOVITA",
    "CODIGO POSTAL": 6127,
    "PROVINCIA": "X",
    "AFIP": 7181
  },
  {
    "DESCRIPCION": "JUAN FERNANDEZ",
    "CODIGO POSTAL": 7011,
    "PROVINCIA": "B",
    "AFIP": 7210
  },
  {
    "DESCRIPCION": "JUAN JORBA",
    "CODIGO POSTAL": 5731,
    "PROVINCIA": "D",
    "AFIP": 7202
  },
  {
    "DESCRIPCION": "JUAN LLERENA",
    "CODIGO POSTAL": 5735,
    "PROVINCIA": "D",
    "AFIP": 7207
  },
  {
    "DESCRIPCION": "JUBILEO",
    "CODIGO POSTAL": 3254,
    "PROVINCIA": "E",
    "AFIP": 7228
  },
  {
    "DESCRIPCION": "JUNCAL",
    "CODIGO POSTAL": 2723,
    "PROVINCIA": "S",
    "AFIP": 16617
  },
  {
    "DESCRIPCION": "JUNIN",
    "CODIGO POSTAL": 5678,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "JUNIN",
    "CODIGO POSTAL": 6000,
    "PROVINCIA": "B",
    "AFIP": 7251
  },
  {
    "DESCRIPCION": "JUSTO DARACT",
    "CODIGO POSTAL": 5738,
    "PROVINCIA": "D",
    "AFIP": 7266
  },
  {
    "DESCRIPCION": "KILOMETRO ONCE",
    "CODIGO POSTAL": 5551,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "L.G.S.MARTIN",
    "CODIGO POSTAL": 4512,
    "PROVINCIA": "Y",
    "AFIP": 220778
  },
  {
    "DESCRIPCION": "L.N.ALEM",
    "CODIGO POSTAL": 3315,
    "PROVINCIA": "N",
    "AFIP": 17707
  },
  {
    "DESCRIPCION": "LA ADELA",
    "CODIGO POSTAL": 8134,
    "PROVINCIA": "L",
    "AFIP": 16620
  },
  {
    "DESCRIPCION": "LA BANDA",
    "CODIGO POSTAL": 4300,
    "PROVINCIA": "G",
    "AFIP": 16685
  },
  {
    "DESCRIPCION": "LA CALERA",
    "CODIGO POSTAL": 5151,
    "PROVINCIA": "X",
    "AFIP": 16707
  },
  {
    "DESCRIPCION": "LA CARLOTA",
    "CODIGO POSTAL": 2670,
    "PROVINCIA": "X",
    "AFIP": 16723
  },
  {
    "DESCRIPCION": "LA CAROLINA",
    "CODIGO POSTAL": 5133,
    "PROVINCIA": "X",
    "AFIP": 21957
  },
  {
    "DESCRIPCION": "LA CAUTIVA",
    "CODIGO POSTAL": 6142,
    "PROVINCIA": "X",
    "AFIP": 16731
  },
  {
    "DESCRIPCION": "LA CENTRAL",
    "CODIGO POSTAL": 5579,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "LA CESIRA",
    "CODIGO POSTAL": 6101,
    "PROVINCIA": "X",
    "AFIP": 7461
  },
  {
    "DESCRIPCION": "LA CHISPA",
    "CODIGO POSTAL": 2602,
    "PROVINCIA": "S",
    "AFIP": 7486
  },
  {
    "DESCRIPCION": "LA COCHA",
    "CODIGO POSTAL": 4162,
    "PROVINCIA": "T",
    "AFIP": 16781
  },
  {
    "DESCRIPCION": "LA COLONIA",
    "CODIGO POSTAL": 5631,
    "PROVINCIA": "M",
    "AFIP": 16789
  },
  {
    "DESCRIPCION": "LA CONSULTA",
    "CODIGO POSTAL": 5567,
    "PROVINCIA": "M",
    "AFIP": 16804
  },
  {
    "DESCRIPCION": "LA CRIOLLA",
    "CODIGO POSTAL": 3052,
    "PROVINCIA": "S",
    "AFIP": 16821
  },
  {
    "DESCRIPCION": "LA CRUZ",
    "CODIGO POSTAL": 3346,
    "PROVINCIA": "W",
    "AFIP": 16822
  },
  {
    "DESCRIPCION": "LA CRUZ",
    "CODIGO POSTAL": 5859,
    "PROVINCIA": "X",
    "AFIP": 7540
  },
  {
    "DESCRIPCION": "LA DORMIDA",
    "CODIGO POSTAL": 5592,
    "PROVINCIA": "M",
    "AFIP": 16848
  },
  {
    "DESCRIPCION": "LA DULCE",
    "CODIGO POSTAL": 7638,
    "PROVINCIA": "B",
    "AFIP": 7572
  },
  {
    "DESCRIPCION": "LA EMILIA",
    "CODIGO POSTAL": 2901,
    "PROVINCIA": "B",
    "AFIP": 21821
  },
  {
    "DESCRIPCION": "LA FALDA",
    "CODIGO POSTAL": 5172,
    "PROVINCIA": "X",
    "AFIP": 16913
  },
  {
    "DESCRIPCION": "LA FRANCIA",
    "CODIGO POSTAL": 2426,
    "PROVINCIA": "X",
    "AFIP": 7657
  },
  {
    "DESCRIPCION": "LA GALLARETA",
    "CODIGO POSTAL": 3057,
    "PROVINCIA": "S",
    "AFIP": 7666
  },
  {
    "DESCRIPCION": "LA GRANJA",
    "CODIGO POSTAL": 5115,
    "PROVINCIA": "X",
    "AFIP": 16962
  },
  {
    "DESCRIPCION": "LA LAGUNA",
    "CODIGO POSTAL": 5905,
    "PROVINCIA": "X",
    "AFIP": 17028
  },
  {
    "DESCRIPCION": "LA LEONESA",
    "CODIGO POSTAL": 3522,
    "PROVINCIA": "H",
    "AFIP": 7768
  },
  {
    "DESCRIPCION": "LA LIGURIA",
    "CODIGO POSTAL": 3501,
    "PROVINCIA": "H",
    "AFIP": 7774
  },
  {
    "DESCRIPCION": "LA LUCILA",
    "CODIGO POSTAL": 1636,
    "PROVINCIA": "B",
    "AFIP": 7794
  },
  {
    "DESCRIPCION": "LA NIÑA",
    "CODIGO POSTAL": 6513,
    "PROVINCIA": "B",
    "AFIP": 7878
  },
  {
    "DESCRIPCION": "LA ORIENTAL",
    "CODIGO POSTAL": 3054,
    "PROVINCIA": "S",
    "AFIP": 17125
  },
  {
    "DESCRIPCION": "LA PALMIRA",
    "CODIGO POSTAL": 5741,
    "PROVINCIA": "D",
    "AFIP": 17134
  },
  {
    "DESCRIPCION": "LA PALOMA",
    "CODIGO POSTAL": 4319,
    "PROVINCIA": "G",
    "AFIP": 17137
  },
  {
    "DESCRIPCION": "LA PARA",
    "CODIGO POSTAL": 5138,
    "PROVINCIA": "X",
    "AFIP": 17144
  },
  {
    "DESCRIPCION": "LA PAZ",
    "CODIGO POSTAL": 3190,
    "PROVINCIA": "E",
    "AFIP": 17151
  },
  {
    "DESCRIPCION": "LA PAZ",
    "CODIGO POSTAL": 5590,
    "PROVINCIA": "M",
    "AFIP": 17153
  },
  {
    "DESCRIPCION": "LA PELADA",
    "CODIGO POSTAL": 3027,
    "PROVINCIA": "S",
    "AFIP": 7938
  },
  {
    "DESCRIPCION": "LA PETRA",
    "CODIGO POSTAL": 5751,
    "PROVINCIA": "D",
    "AFIP": 7947
  },
  {
    "DESCRIPCION": "LA PLATA",
    "CODIGO POSTAL": 1900,
    "PROVINCIA": "B",
    "AFIP": 7970
  },
  {
    "DESCRIPCION": "LA PLAYOSA",
    "CODIGO POSTAL": 5911,
    "PROVINCIA": "X",
    "AFIP": 7972
  },
  {
    "DESCRIPCION": "LA PORTEÑA",
    "CODIGO POSTAL": 6407,
    "PROVINCIA": "B",
    "AFIP": 7984
  },
  {
    "DESCRIPCION": "LA POSTA",
    "CODIGO POSTAL": 5227,
    "PROVINCIA": "X",
    "AFIP": 17190
  },
  {
    "DESCRIPCION": "LA PRIMAVERA",
    "CODIGO POSTAL": 5518,
    "PROVINCIA": "M",
    "AFIP": 17200
  },
  {
    "DESCRIPCION": "LA PUERTA",
    "CODIGO POSTAL": 5137,
    "PROVINCIA": "X",
    "AFIP": 17211
  },
  {
    "DESCRIPCION": "LA PUERTA",
    "CODIGO POSTAL": 5137,
    "PROVINCIA": "X",
    "AFIP": 17211
  },
  {
    "DESCRIPCION": "LA QUIACA",
    "CODIGO POSTAL": 4650,
    "PROVINCIA": "Y",
    "AFIP": 8021
  },
  {
    "DESCRIPCION": "LA REDUCCION",
    "CODIGO POSTAL": 4129,
    "PROVINCIA": "T",
    "AFIP": 17244
  },
  {
    "DESCRIPCION": "LA RIOJA",
    "CODIGO POSTAL": 5300,
    "PROVINCIA": "F",
    "AFIP": 8055
  },
  {
    "DESCRIPCION": "LA SARITA",
    "CODIGO POSTAL": 3563,
    "PROVINCIA": "S",
    "AFIP": 17283
  },
  {
    "DESCRIPCION": "LA TABLADA",
    "CODIGO POSTAL": 1766,
    "PROVINCIA": "B",
    "AFIP": 8116
  },
  {
    "DESCRIPCION": "LA TOMA",
    "CODIGO POSTAL": 5750,
    "PROVINCIA": "D",
    "AFIP": 17319
  },
  {
    "DESCRIPCION": "LA TORDILLA",
    "CODIGO POSTAL": 2435,
    "PROVINCIA": "X",
    "AFIP": 22249
  },
  {
    "DESCRIPCION": "LA VERDE",
    "CODIGO POSTAL": 3514,
    "PROVINCIA": "H",
    "AFIP": 17345
  },
  {
    "DESCRIPCION": "LA VIOLETA",
    "CODIGO POSTAL": 2751,
    "PROVINCIA": "B",
    "AFIP": 8184
  },
  {
    "DESCRIPCION": "LABARDEN",
    "CODIGO POSTAL": 7151,
    "PROVINCIA": "B",
    "AFIP": 8209
  },
  {
    "DESCRIPCION": "LABORDE",
    "CODIGO POSTAL": 2657,
    "PROVINCIA": "X",
    "AFIP": 8210
  },
  {
    "DESCRIPCION": "LABORDEBOY",
    "CODIGO POSTAL": 2726,
    "PROVINCIA": "S",
    "AFIP": 8211
  },
  {
    "DESCRIPCION": "LABOULAYE",
    "CODIGO POSTAL": 6120,
    "PROVINCIA": "X",
    "AFIP": 8213
  },
  {
    "DESCRIPCION": "LAFERRERE",
    "CODIGO POSTAL": 1757,
    "PROVINCIA": "B",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "LAGO EPECUEN",
    "CODIGO POSTAL": 6431,
    "PROVINCIA": "B",
    "AFIP": 8227
  },
  {
    "DESCRIPCION": "LAGO PUELO",
    "CODIGO POSTAL": 8431,
    "PROVINCIA": "V",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "LAGUNA BLANCA",
    "CODIGO POSTAL": 3613,
    "PROVINCIA": "P",
    "AFIP": 17375
  },
  {
    "DESCRIPCION": "LAGUNA LARGA",
    "CODIGO POSTAL": 5974,
    "PROVINCIA": "X",
    "AFIP": 8285
  },
  {
    "DESCRIPCION": "LAGUNA PAIVA",
    "CODIGO POSTAL": 3020,
    "PROVINCIA": "S",
    "AFIP": 17393
  },
  {
    "DESCRIPCION": "LAMADRID",
    "CODIGO POSTAL": 4176,
    "PROVINCIA": "T",
    "AFIP": 17405
  },
  {
    "DESCRIPCION": "LAMARQUE",
    "CODIGO POSTAL": 8363,
    "PROVINCIA": "R",
    "AFIP": 8326
  },
  {
    "DESCRIPCION": "LANDETA",
    "CODIGO POSTAL": 2531,
    "PROVINCIA": "S",
    "AFIP": 8330
  },
  {
    "DESCRIPCION": "LANTERI",
    "CODIGO POSTAL": 3575,
    "PROVINCIA": "S",
    "AFIP": 8334
  },
  {
    "DESCRIPCION": "LANUS",
    "CODIGO POSTAL": 1824,
    "PROVINCIA": "B",
    "AFIP": 8335
  },
  {
    "DESCRIPCION": "LAPRIDA",
    "CODIGO POSTAL": 7414,
    "PROVINCIA": "B",
    "AFIP": 8344
  },
  {
    "DESCRIPCION": "LARRECHEA",
    "CODIGO POSTAL": 2241,
    "PROVINCIA": "S",
    "AFIP": 8351
  },
  {
    "DESCRIPCION": "LARROQUE",
    "CODIGO POSTAL": 2854,
    "PROVINCIA": "E",
    "AFIP": 8352
  },
  {
    "DESCRIPCION": "LAS ABRAS",
    "CODIGO POSTAL": 4306,
    "PROVINCIA": "G",
    "AFIP": 17410
  },
  {
    "DESCRIPCION": "LAS ACEQUIAS",
    "CODIGO POSTAL": 5848,
    "PROVINCIA": "X",
    "AFIP": 8360
  },
  {
    "DESCRIPCION": "LAS BAJADAS",
    "CODIGO POSTAL": 5851,
    "PROVINCIA": "X",
    "AFIP": 8382
  },
  {
    "DESCRIPCION": "LAS CAÑITAS",
    "CODIGO POSTAL": 2428,
    "PROVINCIA": "X",
    "AFIP": 8427
  },
  {
    "DESCRIPCION": "LAS CATITAS",
    "CODIGO POSTAL": 5594,
    "PROVINCIA": "M",
    "AFIP": 8422
  },
  {
    "DESCRIPCION": "LAS CEJAS",
    "CODIGO POSTAL": 4186,
    "PROVINCIA": "T",
    "AFIP": 17456
  },
  {
    "DESCRIPCION": "LAS CHACRAS",
    "CODIGO POSTAL": 7241,
    "PROVINCIA": "B",
    "AFIP": 8432
  },
  {
    "DESCRIPCION": "LAS FLORES",
    "CODIGO POSTAL": 7200,
    "PROVINCIA": "B",
    "AFIP": 8488
  },
  {
    "DESCRIPCION": "LAS HERAS",
    "CODIGO POSTAL": 5539,
    "PROVINCIA": "M",
    "AFIP": 17520
  },
  {
    "DESCRIPCION": "LAS HERAS",
    "CODIGO POSTAL": 9017,
    "PROVINCIA": "Z",
    "AFIP": 17521
  },
  {
    "DESCRIPCION": "LAS HIGUERAS",
    "CODIGO POSTAL": 5117,
    "PROVINCIA": "X",
    "AFIP": 8511
  },
  {
    "DESCRIPCION": "LAS JUNTURAS",
    "CODIGO POSTAL": 5965,
    "PROVINCIA": "X",
    "AFIP": 8527
  },
  {
    "DESCRIPCION": "LAS LAJAS",
    "CODIGO POSTAL": 8347,
    "PROVINCIA": "Q",
    "AFIP": 17551
  },
  {
    "DESCRIPCION": "LAS LAJITAS",
    "CODIGO POSTAL": 4450,
    "PROVINCIA": "A",
    "AFIP": 17555
  },
  {
    "DESCRIPCION": "LAS LOMITAS",
    "CODIGO POSTAL": 3630,
    "PROVINCIA": "P",
    "AFIP": 17563
  },
  {
    "DESCRIPCION": "LAS MARIANAS",
    "CODIGO POSTAL": 6607,
    "PROVINCIA": "B",
    "AFIP": 8552
  },
  {
    "DESCRIPCION": "LAS PALMAS",
    "CODIGO POSTAL": 3518,
    "PROVINCIA": "H",
    "AFIP": 17599
  },
  {
    "DESCRIPCION": "LAS PALMERAS",
    "CODIGO POSTAL": 2326,
    "PROVINCIA": "S",
    "AFIP": 17606
  },
  {
    "DESCRIPCION": "LAS PALMITAS",
    "CODIGO POSTAL": 4231,
    "PROVINCIA": "K",
    "AFIP": 8588
  },
  {
    "DESCRIPCION": "LAS PAREJAS",
    "CODIGO POSTAL": 2505,
    "PROVINCIA": "S",
    "AFIP": 8596
  },
  {
    "DESCRIPCION": "LAS PENAS SUD",
    "CODIGO POSTAL": 5819,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "LAS PEÑAS",
    "CODIGO POSTAL": 5238,
    "PROVINCIA": "X",
    "AFIP": 17622
  },
  {
    "DESCRIPCION": "LAS PERDICES",
    "CODIGO POSTAL": 5921,
    "PROVINCIA": "X",
    "AFIP": 8603
  },
  {
    "DESCRIPCION": "LAS PETACAS",
    "CODIGO POSTAL": 2441,
    "PROVINCIA": "S",
    "AFIP": 8606
  },
  {
    "DESCRIPCION": "LAS ROSAS",
    "CODIGO POSTAL": 2520,
    "PROVINCIA": "S",
    "AFIP": 17653
  },
  {
    "DESCRIPCION": "LAS TERMAS",
    "CODIGO POSTAL": 5150,
    "PROVINCIA": "G",
    "AFIP": 13831
  },
  {
    "DESCRIPCION": "LAS TOSCAS",
    "CODIGO POSTAL": 3586,
    "PROVINCIA": "S",
    "AFIP": 17679
  },
  {
    "DESCRIPCION": "LAS TOSCAS",
    "CODIGO POSTAL": 6453,
    "PROVINCIA": "B",
    "AFIP": 8674
  },
  {
    "DESCRIPCION": "LAS VARAS",
    "CODIGO POSTAL": 5941,
    "PROVINCIA": "X",
    "AFIP": 8695
  },
  {
    "DESCRIPCION": "LAS VARILLAS",
    "CODIGO POSTAL": 5940,
    "PROVINCIA": "X",
    "AFIP": 8696
  },
  {
    "DESCRIPCION": "LAS VERTIENTES",
    "CODIGO POSTAL": 5827,
    "PROVINCIA": "X",
    "AFIP": 17693
  },
  {
    "DESCRIPCION": "LASPIUR",
    "CODIGO POSTAL": 5943,
    "PROVINCIA": "X",
    "AFIP": 13328
  },
  {
    "DESCRIPCION": "LEALES",
    "CODIGO POSTAL": 4113,
    "PROVINCIA": "T",
    "AFIP": 8740
  },
  {
    "DESCRIPCION": "LEHMANN",
    "CODIGO POSTAL": 2305,
    "PROVINCIA": "S",
    "AFIP": 8753
  },
  {
    "DESCRIPCION": "LEONES",
    "CODIGO POSTAL": 2594,
    "PROVINCIA": "X",
    "AFIP": 8764
  },
  {
    "DESCRIPCION": "LIBANO",
    "CODIGO POSTAL": 7407,
    "PROVINCIA": "B",
    "AFIP": 8777
  },
  {
    "DESCRIPCION": "LIBAROS",
    "CODIGO POSTAL": 3244,
    "PROVINCIA": "E",
    "AFIP": 8778
  },
  {
    "DESCRIPCION": "LIBERTAD",
    "CODIGO POSTAL": 1716,
    "PROVINCIA": "B",
    "AFIP": 8779
  },
  {
    "DESCRIPCION": "LIMA",
    "CODIGO POSTAL": 2806,
    "PROVINCIA": "B",
    "AFIP": 8793
  },
  {
    "DESCRIPCION": "LINCOLN",
    "CODIGO POSTAL": 6070,
    "PROVINCIA": "B",
    "AFIP": 8803
  },
  {
    "DESCRIPCION": "LLAMBI CAMPBELL",
    "CODIGO POSTAL": 3036,
    "PROVINCIA": "S",
    "AFIP": 8829
  },
  {
    "DESCRIPCION": "LLAVALLOL",
    "CODIGO POSTAL": 1836,
    "PROVINCIA": "B",
    "AFIP": 8833
  },
  {
    "DESCRIPCION": "LOBERIA",
    "CODIGO POSTAL": 7635,
    "PROVINCIA": "B",
    "AFIP": 8840
  },
  {
    "DESCRIPCION": "LOBOS",
    "CODIGO POSTAL": 7240,
    "PROVINCIA": "B",
    "AFIP": 8842
  },
  {
    "DESCRIPCION": "LOGROÑO",
    "CODIGO POSTAL": 3066,
    "PROVINCIA": "S",
    "AFIP": 8846
  },
  {
    "DESCRIPCION": "LOMA ALTA",
    "CODIGO POSTAL": 2254,
    "PROVINCIA": "S",
    "AFIP": 17719
  },
  {
    "DESCRIPCION": "LOMA NEGRA",
    "CODIGO POSTAL": 7403,
    "PROVINCIA": "B",
    "AFIP": 8876
  },
  {
    "DESCRIPCION": "LONGCHAMPS",
    "CODIGO POSTAL": 1854,
    "PROVINCIA": "B",
    "AFIP": 8929
  },
  {
    "DESCRIPCION": "LONQUIMAY",
    "CODIGO POSTAL": 6352,
    "PROVINCIA": "L",
    "AFIP": 8930
  },
  {
    "DESCRIPCION": "LOPEZ",
    "CODIGO POSTAL": 2255,
    "PROVINCIA": "S",
    "AFIP": 17752
  },
  {
    "DESCRIPCION": "LORETO",
    "CODIGO POSTAL": 4208,
    "PROVINCIA": "G",
    "AFIP": 17755
  },
  {
    "DESCRIPCION": "LOS ALTOS",
    "CODIGO POSTAL": 4723,
    "PROVINCIA": "K",
    "AFIP": 17773
  },
  {
    "DESCRIPCION": "LOS CARDALES",
    "CODIGO POSTAL": 2814,
    "PROVINCIA": "B",
    "AFIP": 9020
  },
  {
    "DESCRIPCION": "LOS CARDOS",
    "CODIGO POSTAL": 2533,
    "PROVINCIA": "S",
    "AFIP": 17803
  },
  {
    "DESCRIPCION": "LOS CHAÑARITOS",
    "CODIGO POSTAL": 5125,
    "PROVINCIA": "X",
    "AFIP": 9051
  },
  {
    "DESCRIPCION": "LOS CHARRUAS",
    "CODIGO POSTAL": 3212,
    "PROVINCIA": "E",
    "AFIP": 9048
  },
  {
    "DESCRIPCION": "LOS CISNES",
    "CODIGO POSTAL": 2684,
    "PROVINCIA": "X",
    "AFIP": 9061
  },
  {
    "DESCRIPCION": "LOS COMETIERRA",
    "CODIGO POSTAL": 5221,
    "PROVINCIA": "X",
    "AFIP": 9073
  },
  {
    "DESCRIPCION": "LOS CONDORES",
    "CODIGO POSTAL": 5823,
    "PROVINCIA": "X",
    "AFIP": 9076
  },
  {
    "DESCRIPCION": "LOS JURIES",
    "CODIGO POSTAL": 3773,
    "PROVINCIA": "G",
    "AFIP": 9167
  },
  {
    "DESCRIPCION": "LOS LAURELES",
    "CODIGO POSTAL": 3567,
    "PROVINCIA": "S",
    "AFIP": 17866
  },
  {
    "DESCRIPCION": "LOS MENUCOS",
    "CODIGO POSTAL": 8424,
    "PROVINCIA": "R",
    "AFIP": 17877
  },
  {
    "DESCRIPCION": "LOS MISTOLES",
    "CODIGO POSTAL": 5281,
    "PROVINCIA": "X",
    "AFIP": 9203
  },
  {
    "DESCRIPCION": "LOS MOLINOS",
    "CODIGO POSTAL": 2181,
    "PROVINCIA": "S",
    "AFIP": 17884
  },
  {
    "DESCRIPCION": "LOS MOLLES",
    "CODIGO POSTAL": 2561,
    "PROVINCIA": "X",
    "AFIP": 17886
  },
  {
    "DESCRIPCION": "LOS PATOS",
    "CODIGO POSTAL": 2551,
    "PROVINCIA": "X",
    "AFIP": 21922
  },
  {
    "DESCRIPCION": "LOS PEREYRA",
    "CODIGO POSTAL": 4178,
    "PROVINCIA": "T",
    "AFIP": 17921
  },
  {
    "DESCRIPCION": "LOS POLVORINES",
    "CODIGO POSTAL": 1613,
    "PROVINCIA": "B",
    "AFIP": 9275
  },
  {
    "DESCRIPCION": "LOS PUESTOS",
    "CODIGO POSTAL": 5319,
    "PROVINCIA": "K",
    "AFIP": 9285
  },
  {
    "DESCRIPCION": "LOS RALOS",
    "CODIGO POSTAL": 4182,
    "PROVINCIA": "T",
    "AFIP": 17949
  },
  {
    "DESCRIPCION": "LOS SURGENTES",
    "CODIGO POSTAL": 2581,
    "PROVINCIA": "X",
    "AFIP": 9341
  },
  {
    "DESCRIPCION": "LOS TOLDOS",
    "CODIGO POSTAL": 6015,
    "PROVINCIA": "B",
    "AFIP": 9365
  },
  {
    "DESCRIPCION": "LOS ZORROS",
    "CODIGO POSTAL": 5901,
    "PROVINCIA": "X",
    "AFIP": 9400
  },
  {
    "DESCRIPCION": "LOZADA",
    "CODIGO POSTAL": 5101,
    "PROVINCIA": "X",
    "AFIP": 9404
  },
  {
    "DESCRIPCION": "LUIS A. SAUCE",
    "CODIGO POSTAL": 2423,
    "PROVINCIA": "X",
    "AFIP": 21971
  },
  {
    "DESCRIPCION": "LUIS BELTRAN",
    "CODIGO POSTAL": 8361,
    "PROVINCIA": "R",
    "AFIP": 9427
  },
  {
    "DESCRIPCION": "LUIS GUILLON",
    "CODIGO POSTAL": 1838,
    "PROVINCIA": "B",
    "AFIP": 9431
  },
  {
    "DESCRIPCION": "LUJAN",
    "CODIGO POSTAL": 6700,
    "PROVINCIA": "B",
    "AFIP": 9438
  },
  {
    "DESCRIPCION": "LUJAN",
    "CODIGO POSTAL": 5709,
    "PROVINCIA": "D",
    "AFIP": 17979
  },
  {
    "DESCRIPCION": "LUJAN DE CUYO",
    "CODIGO POSTAL": 5507,
    "PROVINCIA": "M",
    "AFIP": 9439
  },
  {
    "DESCRIPCION": "LUNLUNTA",
    "CODIGO POSTAL": 5517,
    "PROVINCIA": "M",
    "AFIP": 9448
  },
  {
    "DESCRIPCION": "LUQUE",
    "CODIGO POSTAL": 5967,
    "PROVINCIA": "X",
    "AFIP": 9449
  },
  {
    "DESCRIPCION": "LUXARDO",
    "CODIGO POSTAL": 2411,
    "PROVINCIA": "X",
    "AFIP": 9453
  },
  {
    "DESCRIPCION": "LUZURIAGA",
    "CODIGO POSTAL": 5513,
    "PROVINCIA": "M",
    "AFIP": 9457
  },
  {
    "DESCRIPCION": "MACACHIN",
    "CODIGO POSTAL": 6307,
    "PROVINCIA": "L",
    "AFIP": 9463
  },
  {
    "DESCRIPCION": "MACHAGAI",
    "CODIGO POSTAL": 3534,
    "PROVINCIA": "H",
    "AFIP": 9469
  },
  {
    "DESCRIPCION": "MACIA",
    "CODIGO POSTAL": 3177,
    "PROVINCIA": "E",
    "AFIP": 9475
  },
  {
    "DESCRIPCION": "MACIEL",
    "CODIGO POSTAL": 2208,
    "PROVINCIA": "S",
    "AFIP": 9476
  },
  {
    "DESCRIPCION": "MACIEL",
    "CODIGO POSTAL": 2208,
    "PROVINCIA": "S",
    "AFIP": 9476
  },
  {
    "DESCRIPCION": "MAGDALENA",
    "CODIGO POSTAL": 1913,
    "PROVINCIA": "B",
    "AFIP": 9495
  },
  {
    "DESCRIPCION": "MAGGIOLO",
    "CODIGO POSTAL": 2622,
    "PROVINCIA": "S",
    "AFIP": 9496
  },
  {
    "DESCRIPCION": "MAIPU",
    "CODIGO POSTAL": 5515,
    "PROVINCIA": "M",
    "AFIP": 17985
  },
  {
    "DESCRIPCION": "MAIPU",
    "CODIGO POSTAL": 7160,
    "PROVINCIA": "B",
    "AFIP": 9505
  },
  {
    "DESCRIPCION": "MALABRIGO",
    "CODIGO POSTAL": 3572,
    "PROVINCIA": "S",
    "AFIP": 9519
  },
  {
    "DESCRIPCION": "MALARGUE",
    "CODIGO POSTAL": 5613,
    "PROVINCIA": "M",
    "AFIP": 9524
  },
  {
    "DESCRIPCION": "MANFREDI",
    "CODIGO POSTAL": 5988,
    "PROVINCIA": "X",
    "AFIP": 9584
  },
  {
    "DESCRIPCION": "MAR DE AJO",
    "CODIGO POSTAL": 7109,
    "PROVINCIA": "B",
    "AFIP": 9622
  },
  {
    "DESCRIPCION": "MAR DEL PLATA",
    "CODIGO POSTAL": 7600,
    "PROVINCIA": "B",
    "AFIP": 9625
  },
  {
    "DESCRIPCION": "MAR DEL PLATA",
    "CODIGO POSTAL": 7600,
    "PROVINCIA": "B",
    "AFIP": 9625
  },
  {
    "DESCRIPCION": "MARCOS JUAREZ",
    "CODIGO POSTAL": 2580,
    "PROVINCIA": "X",
    "AFIP": 9637
  },
  {
    "DESCRIPCION": "MARCOS PAZ",
    "CODIGO POSTAL": 1727,
    "PROVINCIA": "B",
    "AFIP": 9638
  },
  {
    "DESCRIPCION": "MARGARITA",
    "CODIGO POSTAL": 3056,
    "PROVINCIA": "S",
    "AFIP": 9647
  },
  {
    "DESCRIPCION": "MARIA GRANDE",
    "CODIGO POSTAL": 3133,
    "PROVINCIA": "E",
    "AFIP": 9662
  },
  {
    "DESCRIPCION": "MARIA JUANA",
    "CODIGO POSTAL": 2445,
    "PROVINCIA": "S",
    "AFIP": 9668
  },
  {
    "DESCRIPCION": "MARIA SUSANA",
    "CODIGO POSTAL": 2527,
    "PROVINCIA": "S",
    "AFIP": 9676
  },
  {
    "DESCRIPCION": "MARIA TERESA",
    "CODIGO POSTAL": 2609,
    "PROVINCIA": "S",
    "AFIP": 9677
  },
  {
    "DESCRIPCION": "MARIANO LOZA",
    "CODIGO POSTAL": 3476,
    "PROVINCIA": "W",
    "AFIP": 21991
  },
  {
    "DESCRIPCION": "MARTINEZ DE HOZ",
    "CODIGO POSTAL": 6533,
    "PROVINCIA": "B",
    "AFIP": 4107
  },
  {
    "DESCRIPCION": "MARULL",
    "CODIGO POSTAL": 5139,
    "PROVINCIA": "X",
    "AFIP": 9713
  },
  {
    "DESCRIPCION": "MATORRALES",
    "CODIGO POSTAL": 5983,
    "PROVINCIA": "X",
    "AFIP": 9739
  },
  {
    "DESCRIPCION": "MATTALDI",
    "CODIGO POSTAL": 6124,
    "PROVINCIA": "X",
    "AFIP": 9741
  },
  {
    "DESCRIPCION": "MAXIMO PAZ",
    "CODIGO POSTAL": 1812,
    "PROVINCIA": "B",
    "AFIP": 9752
  },
  {
    "DESCRIPCION": "MAXIMO PAZ",
    "CODIGO POSTAL": 2115,
    "PROVINCIA": "S",
    "AFIP": 18021
  },
  {
    "DESCRIPCION": "MBURUCUYA",
    "CODIGO POSTAL": 3427,
    "PROVINCIA": "W",
    "AFIP": 9773
  },
  {
    "DESCRIPCION": "MECHONGUE",
    "CODIGO POSTAL": 7605,
    "PROVINCIA": "B",
    "AFIP": 9778
  },
  {
    "DESCRIPCION": "MEDANOS",
    "CODIGO POSTAL": 8132,
    "PROVINCIA": "B",
    "AFIP": 9791
  },
  {
    "DESCRIPCION": "MEDIA AGUA",
    "CODIGO POSTAL": 5435,
    "PROVINCIA": "J",
    "AFIP": 9796
  },
  {
    "DESCRIPCION": "MEDRANO",
    "CODIGO POSTAL": 5585,
    "PROVINCIA": "M",
    "AFIP": 9803
  },
  {
    "DESCRIPCION": "MELCHOR ROMERO",
    "CODIGO POSTAL": 1903,
    "PROVINCIA": "B",
    "AFIP": 9804
  },
  {
    "DESCRIPCION": "MELINCUE",
    "CODIGO POSTAL": 2728,
    "PROVINCIA": "S",
    "AFIP": 9807
  },
  {
    "DESCRIPCION": "MELO",
    "CODIGO POSTAL": 6123,
    "PROVINCIA": "X",
    "AFIP": 9810
  },
  {
    "DESCRIPCION": "MENDIOLAZA",
    "CODIGO POSTAL": 5107,
    "PROVINCIA": "X",
    "AFIP": 9815
  },
  {
    "DESCRIPCION": "MENDOZA",
    "CODIGO POSTAL": 5500,
    "PROVINCIA": "M",
    "AFIP": 9816
  },
  {
    "DESCRIPCION": "MERCEDES",
    "CODIGO POSTAL": 3470,
    "PROVINCIA": "W",
    "AFIP": 18038
  },
  {
    "DESCRIPCION": "MERCEDES",
    "CODIGO POSTAL": 6600,
    "PROVINCIA": "B",
    "AFIP": 9821
  },
  {
    "DESCRIPCION": "MERLO",
    "CODIGO POSTAL": 1722,
    "PROVINCIA": "B",
    "AFIP": 9826
  },
  {
    "DESCRIPCION": "MERLO",
    "CODIGO POSTAL": 5881,
    "PROVINCIA": "D",
    "AFIP": 18042
  },
  {
    "DESCRIPCION": "METAN",
    "CODIGO POSTAL": 4440,
    "PROVINCIA": "A",
    "AFIP": 9841
  },
  {
    "DESCRIPCION": "MIGUEL RIGLOS",
    "CODIGO POSTAL": 6301,
    "PROVINCIA": "L",
    "AFIP": 9858
  },
  {
    "DESCRIPCION": "MIGUEL TORRES",
    "CODIGO POSTAL": 2631,
    "PROVINCIA": "S",
    "AFIP": 11641
  },
  {
    "DESCRIPCION": "MINA CLAVERO",
    "CODIGO POSTAL": 5889,
    "PROVINCIA": "X",
    "AFIP": 9875
  },
  {
    "DESCRIPCION": "MIRAMAR",
    "CODIGO POSTAL": 5143,
    "PROVINCIA": "X",
    "AFIP": 18053
  },
  {
    "DESCRIPCION": "MIRAMAR",
    "CODIGO POSTAL": 7607,
    "PROVINCIA": "B",
    "AFIP": 9940
  },
  {
    "DESCRIPCION": "MISION LAISHI",
    "CODIGO POSTAL": 3601,
    "PROVINCIA": "P",
    "AFIP": 12967
  },
  {
    "DESCRIPCION": "MISION LAISHI",
    "CODIGO POSTAL": 3601,
    "PROVINCIA": "P",
    "AFIP": 22075
  },
  {
    "DESCRIPCION": "MOCORETA",
    "CODIGO POSTAL": 3226,
    "PROVINCIA": "W",
    "AFIP": 9976
  },
  {
    "DESCRIPCION": "MOISES VILLE",
    "CODIGO POSTAL": 2313,
    "PROVINCIA": "S",
    "AFIP": 9984
  },
  {
    "DESCRIPCION": "MONES CAZON",
    "CODIGO POSTAL": 6469,
    "PROVINCIA": "B",
    "AFIP": 10024
  },
  {
    "DESCRIPCION": "MONJE",
    "CODIGO POSTAL": 2212,
    "PROVINCIA": "S",
    "AFIP": 10026
  },
  {
    "DESCRIPCION": "MONTE BUEY",
    "CODIGO POSTAL": 2589,
    "PROVINCIA": "X",
    "AFIP": 10037
  },
  {
    "DESCRIPCION": "MONTE CASEROS",
    "CODIGO POSTAL": 3220,
    "PROVINCIA": "W",
    "AFIP": 18072
  },
  {
    "DESCRIPCION": "MONTE CHINGOLO",
    "CODIGO POSTAL": 1825,
    "PROVINCIA": "B",
    "AFIP": 10043
  },
  {
    "DESCRIPCION": "MONTE COMAN",
    "CODIGO POSTAL": 5609,
    "PROVINCIA": "M",
    "AFIP": 10047
  },
  {
    "DESCRIPCION": "MONTE GRANDE",
    "CODIGO POSTAL": 1842,
    "PROVINCIA": "B",
    "AFIP": 10058
  },
  {
    "DESCRIPCION": "MONTE HERMOSO",
    "CODIGO POSTAL": 8153,
    "PROVINCIA": "B",
    "AFIP": 10060
  },
  {
    "DESCRIPCION": "MONTE HERMOSO",
    "CODIGO POSTAL": 8153,
    "PROVINCIA": "B",
    "AFIP": 10060
  },
  {
    "DESCRIPCION": "MONTE LEÑA",
    "CODIGO POSTAL": 2564,
    "PROVINCIA": "X",
    "AFIP": 10063
  },
  {
    "DESCRIPCION": "MONTE MAIZ",
    "CODIGO POSTAL": 2659,
    "PROVINCIA": "X",
    "AFIP": 10066
  },
  {
    "DESCRIPCION": "MONTE QUEMADO",
    "CODIGO POSTAL": 4195,
    "PROVINCIA": "G",
    "AFIP": 10070
  },
  {
    "DESCRIPCION": "MONTE RALO",
    "CODIGO POSTAL": 5159,
    "PROVINCIA": "X",
    "AFIP": 10071
  },
  {
    "DESCRIPCION": "MONTE RICO",
    "CODIGO POSTAL": 4605,
    "PROVINCIA": "Y",
    "AFIP": 10074
  },
  {
    "DESCRIPCION": "MONTECARLO",
    "CODIGO POSTAL": 3384,
    "PROVINCIA": "N",
    "AFIP": 18095
  },
  {
    "DESCRIPCION": "MONTEROS",
    "CODIGO POSTAL": 4142,
    "PROVINCIA": "T",
    "AFIP": 10082
  },
  {
    "DESCRIPCION": "MONTES DE OCA",
    "CODIGO POSTAL": 2521,
    "PROVINCIA": "S",
    "AFIP": 18096
  },
  {
    "DESCRIPCION": "MORENO",
    "CODIGO POSTAL": 1744,
    "PROVINCIA": "B",
    "AFIP": 10105
  },
  {
    "DESCRIPCION": "MORON",
    "CODIGO POSTAL": 1708,
    "PROVINCIA": "B",
    "AFIP": 10109
  },
  {
    "DESCRIPCION": "MORRISON",
    "CODIGO POSTAL": 2568,
    "PROVINCIA": "X",
    "AFIP": 10112
  },
  {
    "DESCRIPCION": "MORSE",
    "CODIGO POSTAL": 6013,
    "PROVINCIA": "B",
    "AFIP": 10117
  },
  {
    "DESCRIPCION": "MORTEROS",
    "CODIGO POSTAL": 2421,
    "PROVINCIA": "X",
    "AFIP": 10119
  },
  {
    "DESCRIPCION": "MOSCONI",
    "CODIGO POSTAL": 4562,
    "PROVINCIA": "A",
    "AFIP": 6369
  },
  {
    "DESCRIPCION": "MURPHY",
    "CODIGO POSTAL": 2601,
    "PROVINCIA": "S",
    "AFIP": 10142
  },
  {
    "DESCRIPCION": "NARE",
    "CODIGO POSTAL": 3046,
    "PROVINCIA": "S",
    "AFIP": 10188
  },
  {
    "DESCRIPCION": "NASCHEL",
    "CODIGO POSTAL": 5759,
    "PROVINCIA": "D",
    "AFIP": 10191
  },
  {
    "DESCRIPCION": "NAVARRO",
    "CODIGO POSTAL": 6605,
    "PROVINCIA": "B",
    "AFIP": 10196
  },
  {
    "DESCRIPCION": "NECOCHEA",
    "CODIGO POSTAL": 7630,
    "PROVINCIA": "B",
    "AFIP": 10201
  },
  {
    "DESCRIPCION": "NELSON",
    "CODIGO POSTAL": 3032,
    "PROVINCIA": "S",
    "AFIP": 10209
  },
  {
    "DESCRIPCION": "NEUQUEN",
    "CODIGO POSTAL": 8300,
    "PROVINCIA": "Q",
    "AFIP": 10215
  },
  {
    "DESCRIPCION": "NICANOR OLIVERA",
    "CODIGO POSTAL": 7637,
    "PROVINCIA": "B",
    "AFIP": 10218
  },
  {
    "DESCRIPCION": "NOETINGER",
    "CODIGO POSTAL": 2563,
    "PROVINCIA": "X",
    "AFIP": 10248
  },
  {
    "DESCRIPCION": "NOGOYA",
    "CODIGO POSTAL": 3150,
    "PROVINCIA": "E",
    "AFIP": 10255
  },
  {
    "DESCRIPCION": "NONOGASTA",
    "CODIGO POSTAL": 5372,
    "PROVINCIA": "F",
    "AFIP": 10258
  },
  {
    "DESCRIPCION": "NUEVA GALIA",
    "CODIGO POSTAL": 6216,
    "PROVINCIA": "D",
    "AFIP": 10287
  },
  {
    "DESCRIPCION": "NUEVE DE JULIO",
    "CODIGO POSTAL": 6500,
    "PROVINCIA": "B",
    "AFIP": 30
  },
  {
    "DESCRIPCION": "O HIGGINS",
    "CODIGO POSTAL": 6748,
    "PROVINCIA": "B",
    "AFIP": 10319
  },
  {
    "DESCRIPCION": "OBERA",
    "CODIGO POSTAL": 3360,
    "PROVINCIA": "N",
    "AFIP": 10321
  },
  {
    "DESCRIPCION": "OBISPO TREJO",
    "CODIGO POSTAL": 5225,
    "PROVINCIA": "X",
    "AFIP": 10322
  },
  {
    "DESCRIPCION": "OJO DE AGUA",
    "CODIGO POSTAL": 5250,
    "PROVINCIA": "G",
    "AFIP": 18139
  },
  {
    "DESCRIPCION": "OLAETA",
    "CODIGO POSTAL": 5807,
    "PROVINCIA": "X",
    "AFIP": 10357
  },
  {
    "DESCRIPCION": "OLAVARRIA",
    "CODIGO POSTAL": 7400,
    "PROVINCIA": "B",
    "AFIP": 10361
  },
  {
    "DESCRIPCION": "OLIVA",
    "CODIGO POSTAL": 5980,
    "PROVINCIA": "X",
    "AFIP": 10364
  },
  {
    "DESCRIPCION": "OLIVEROS",
    "CODIGO POSTAL": 2206,
    "PROVINCIA": "S",
    "AFIP": 10367
  },
  {
    "DESCRIPCION": "OLMOS",
    "CODIGO POSTAL": 1681,
    "PROVINCIA": "B",
    "AFIP": 22259
  },
  {
    "DESCRIPCION": "OLTA",
    "CODIGO POSTAL": 5383,
    "PROVINCIA": "F",
    "AFIP": 18144
  },
  {
    "DESCRIPCION": "ONAGOITY",
    "CODIGO POSTAL": 6227,
    "PROVINCIA": "X",
    "AFIP": 21879
  },
  {
    "DESCRIPCION": "ONCATIVO",
    "CODIGO POSTAL": 5986,
    "PROVINCIA": "X",
    "AFIP": 10383
  },
  {
    "DESCRIPCION": "ONCE",
    "CODIGO POSTAL": 1032,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "ORAN",
    "CODIGO POSTAL": 4530,
    "PROVINCIA": "A",
    "AFIP": 10390
  },
  {
    "DESCRIPCION": "ORDOÑEZ",
    "CODIGO POSTAL": 2555,
    "PROVINCIA": "X",
    "AFIP": 10395
  },
  {
    "DESCRIPCION": "ORENSE",
    "CODIGO POSTAL": 7503,
    "PROVINCIA": "B",
    "AFIP": 10397
  },
  {
    "DESCRIPCION": "ORIENTE",
    "CODIGO POSTAL": 7509,
    "PROVINCIA": "B",
    "AFIP": 10398
  },
  {
    "DESCRIPCION": "OTUMPA",
    "CODIGO POSTAL": 3741,
    "PROVINCIA": "G",
    "AFIP": 10419
  },
  {
    "DESCRIPCION": "PALMIRA",
    "CODIGO POSTAL": 5584,
    "PROVINCIA": "M",
    "AFIP": 18163
  },
  {
    "DESCRIPCION": "PALPALA",
    "CODIGO POSTAL": 4612,
    "PROVINCIA": "Y",
    "AFIP": 10539
  },
  {
    "DESCRIPCION": "PAMPA DEL INDIO",
    "CODIGO POSTAL": 3531,
    "PROVINCIA": "H",
    "AFIP": 10582
  },
  {
    "DESCRIPCION": "PAMPA POZO",
    "CODIGO POSTAL": 4242,
    "PROVINCIA": "T",
    "AFIP": 18198
  },
  {
    "DESCRIPCION": "PAMPAYASTA",
    "CODIGO POSTAL": 5931,
    "PROVINCIA": "X",
    "AFIP": 10643
  },
  {
    "DESCRIPCION": "PAMPAYASTA SUR",
    "CODIGO POSTAL": 5975,
    "PROVINCIA": "X",
    "AFIP": 10644
  },
  {
    "DESCRIPCION": "PARANA",
    "CODIGO POSTAL": 3100,
    "PROVINCIA": "E",
    "AFIP": 10738
  },
  {
    "DESCRIPCION": "PARERA",
    "CODIGO POSTAL": 6213,
    "PROVINCIA": "L",
    "AFIP": 10748
  },
  {
    "DESCRIPCION": "PARQUE LELOIR",
    "CODIGO POSTAL": 1713,
    "PROVINCIA": "B",
    "AFIP": 1292
  },
  {
    "DESCRIPCION": "PASCANAS",
    "CODIGO POSTAL": 2679,
    "PROVINCIA": "X",
    "AFIP": 10766
  },
  {
    "DESCRIPCION": "PASCO",
    "CODIGO POSTAL": 5925,
    "PROVINCIA": "X",
    "AFIP": 10768
  },
  {
    "DESCRIPCION": "PASMAN",
    "CODIGO POSTAL": 7547,
    "PROVINCIA": "B",
    "AFIP": 10772
  },
  {
    "DESCRIPCION": "PASO DEL REY",
    "CODIGO POSTAL": 1742,
    "PROVINCIA": "B",
    "AFIP": 10847
  },
  {
    "DESCRIPCION": "PAVON ARRIBA",
    "CODIGO POSTAL": 2110,
    "PROVINCIA": "S",
    "AFIP": 10946
  },
  {
    "DESCRIPCION": "PEDERNALES",
    "CODIGO POSTAL": 6665,
    "PROVINCIA": "B",
    "AFIP": 10959
  },
  {
    "DESCRIPCION": "PEDREGAL",
    "CODIGO POSTAL": 5529,
    "PROVINCIA": "M",
    "AFIP": 10961
  },
  {
    "DESCRIPCION": "PEDRO LURO",
    "CODIGO POSTAL": 8148,
    "PROVINCIA": "B",
    "AFIP": 10971
  },
  {
    "DESCRIPCION": "PEHUAJO",
    "CODIGO POSTAL": 6450,
    "PROVINCIA": "B",
    "AFIP": 10978
  },
  {
    "DESCRIPCION": "PELLEGRINI",
    "CODIGO POSTAL": 6346,
    "PROVINCIA": "B",
    "AFIP": 10984
  },
  {
    "DESCRIPCION": "PERDICES",
    "CODIGO POSTAL": 2823,
    "PROVINCIA": "E",
    "AFIP": 10994
  },
  {
    "DESCRIPCION": "PEREZ",
    "CODIGO POSTAL": 2121,
    "PROVINCIA": "S",
    "AFIP": 11001
  },
  {
    "DESCRIPCION": "PEREZ MILLAN",
    "CODIGO POSTAL": 2933,
    "PROVINCIA": "B",
    "AFIP": 11003
  },
  {
    "DESCRIPCION": "PERGAMINO",
    "CODIGO POSTAL": 2700,
    "PROVINCIA": "B",
    "AFIP": 11004
  },
  {
    "DESCRIPCION": "PERITO MORENO",
    "CODIGO POSTAL": 9040,
    "PROVINCIA": "Z",
    "AFIP": 18232
  },
  {
    "DESCRIPCION": "PERUGORRIA",
    "CODIGO POSTAL": 3461,
    "PROVINCIA": "W",
    "AFIP": 11016
  },
  {
    "DESCRIPCION": "PEYRANO",
    "CODIGO POSTAL": 2113,
    "PROVINCIA": "S",
    "AFIP": 11022
  },
  {
    "DESCRIPCION": "PIAMONTE",
    "CODIGO POSTAL": 2529,
    "PROVINCIA": "S",
    "AFIP": 11036
  },
  {
    "DESCRIPCION": "PICO TRUNCADO",
    "CODIGO POSTAL": 9015,
    "PROVINCIA": "Z",
    "AFIP": 11073
  },
  {
    "DESCRIPCION": "PICUN LEUFU",
    "CODIGO POSTAL": 8313,
    "PROVINCIA": "Q",
    "AFIP": 11078
  },
  {
    "DESCRIPCION": "PIEDRITAS",
    "CODIGO POSTAL": 6241,
    "PROVINCIA": "B",
    "AFIP": 11118
  },
  {
    "DESCRIPCION": "PIERES",
    "CODIGO POSTAL": 7633,
    "PROVINCIA": "B",
    "AFIP": 11120
  },
  {
    "DESCRIPCION": "PIGUE",
    "CODIGO POSTAL": 8170,
    "PROVINCIA": "B",
    "AFIP": 11123
  },
  {
    "DESCRIPCION": "PILAR",
    "CODIGO POSTAL": 1629,
    "PROVINCIA": "B",
    "AFIP": 11128
  },
  {
    "DESCRIPCION": "PILAR",
    "CODIGO POSTAL": 3085,
    "PROVINCIA": "S",
    "AFIP": 18267
  },
  {
    "DESCRIPCION": "PILAR",
    "CODIGO POSTAL": 5972,
    "PROVINCIA": "X",
    "AFIP": 18266
  },
  {
    "DESCRIPCION": "PINAMAR",
    "CODIGO POSTAL": 7167,
    "PROVINCIA": "B",
    "AFIP": 11143
  },
  {
    "DESCRIPCION": "PINTO",
    "CODIGO POSTAL": 2356,
    "PROVINCIA": "G",
    "AFIP": 22187
  },
  {
    "DESCRIPCION": "PINZON",
    "CODIGO POSTAL": 2703,
    "PROVINCIA": "B",
    "AFIP": 11160
  },
  {
    "DESCRIPCION": "PIÑEYRO",
    "CODIGO POSTAL": 7548,
    "PROVINCIA": "C",
    "AFIP": 21700
  },
  {
    "DESCRIPCION": "PIQUILLIN",
    "CODIGO POSTAL": 5235,
    "PROVINCIA": "X",
    "AFIP": 11165
  },
  {
    "DESCRIPCION": "PIRANE",
    "CODIGO POSTAL": 3606,
    "PROVINCIA": "P",
    "AFIP": 11169
  },
  {
    "DESCRIPCION": "PIROVANO",
    "CODIGO POSTAL": 6551,
    "PROVINCIA": "B",
    "AFIP": 11177
  },
  {
    "DESCRIPCION": "PLA",
    "CODIGO POSTAL": 6691,
    "PROVINCIA": "B",
    "AFIP": 11197
  },
  {
    "DESCRIPCION": "PLATANOS",
    "CODIGO POSTAL": 1885,
    "PROVINCIA": "B",
    "AFIP": 11208
  },
  {
    "DESCRIPCION": "PLAZA HUINCUL",
    "CODIGO POSTAL": 8318,
    "PROVINCIA": "Q",
    "AFIP": 11222
  },
  {
    "DESCRIPCION": "PLOTTIER",
    "CODIGO POSTAL": 8316,
    "PROVINCIA": "Q",
    "AFIP": 11230
  },
  {
    "DESCRIPCION": "POBLET",
    "CODIGO POSTAL": 1905,
    "PROVINCIA": "B",
    "AFIP": 11265
  },
  {
    "DESCRIPCION": "POCHO",
    "CODIGO POSTAL": 5299,
    "PROVINCIA": "X",
    "AFIP": 11266
  },
  {
    "DESCRIPCION": "POCITOS",
    "CODIGO POSTAL": 4568,
    "PROVINCIA": "A",
    "AFIP": 18271
  },
  {
    "DESCRIPCION": "POMAN",
    "CODIGO POSTAL": 5315,
    "PROVINCIA": "K",
    "AFIP": 11282
  },
  {
    "DESCRIPCION": "POMAN",
    "CODIGO POSTAL": 5315,
    "PROVINCIA": "K",
    "AFIP": 11282
  },
  {
    "DESCRIPCION": "PORTEÑA",
    "CODIGO POSTAL": 2415,
    "PROVINCIA": "X",
    "AFIP": 11311
  },
  {
    "DESCRIPCION": "PORVENIR",
    "CODIGO POSTAL": 6063,
    "PROVINCIA": "B",
    "AFIP": 11339
  },
  {
    "DESCRIPCION": "POSADAS",
    "CODIGO POSTAL": 3300,
    "PROVINCIA": "N",
    "AFIP": 11341
  },
  {
    "DESCRIPCION": "POSADAS",
    "CODIGO POSTAL": 3305,
    "PROVINCIA": "N",
    "AFIP": 11341
  },
  {
    "DESCRIPCION": "POTRERO NORTE",
    "CODIGO POSTAL": 3608,
    "PROVINCIA": "P",
    "AFIP": 11384
  },
  {
    "DESCRIPCION": "POZO BORRADO",
    "CODIGO POSTAL": 3059,
    "PROVINCIA": "S",
    "AFIP": 11397
  },
  {
    "DESCRIPCION": "POZO DEL MOLLE",
    "CODIGO POSTAL": 5913,
    "PROVINCIA": "X",
    "AFIP": 18319
  },
  {
    "DESCRIPCION": "POZO DEL MOLLE",
    "CODIGO POSTAL": 5913,
    "PROVINCIA": "X",
    "AFIP": 18319
  },
  {
    "DESCRIPCION": "POZO DEL TOBA",
    "CODIGO POSTAL": 5253,
    "PROVINCIA": "G",
    "AFIP": 11473
  },
  {
    "DESCRIPCION": "PROGRESO",
    "CODIGO POSTAL": 3023,
    "PROVINCIA": "S",
    "AFIP": 18350
  },
  {
    "DESCRIPCION": "PRONUNCIAMIENTO",
    "CODIGO POSTAL": 3263,
    "PROVINCIA": "E",
    "AFIP": 11545
  },
  {
    "DESCRIPCION": "PUAN",
    "CODIGO POSTAL": 8180,
    "PROVINCIA": "B",
    "AFIP": 11599
  },
  {
    "DESCRIPCION": "PUEBLO BRUGO",
    "CODIGO POSTAL": 3125,
    "PROVINCIA": "E",
    "AFIP": 11616
  },
  {
    "DESCRIPCION": "PUEBLO ITALIANO",
    "CODIGO POSTAL": 2651,
    "PROVINCIA": "X",
    "AFIP": 11633
  },
  {
    "DESCRIPCION": "PUEBLO ITALIANO",
    "CODIGO POSTAL": 2651,
    "PROVINCIA": "X",
    "AFIP": 11633
  },
  {
    "DESCRIPCION": "PUEBLO SAN JOSE",
    "CODIGO POSTAL": 7541,
    "PROVINCIA": "B",
    "AFIP": 11658
  },
  {
    "DESCRIPCION": "PUERTO DESEADO",
    "CODIGO POSTAL": 9050,
    "PROVINCIA": "Z",
    "AFIP": 11785
  },
  {
    "DESCRIPCION": "PUERTO RICO",
    "CODIGO POSTAL": 3334,
    "PROVINCIA": "N",
    "AFIP": 11852
  },
  {
    "DESCRIPCION": "PUJATO",
    "CODIGO POSTAL": 2127,
    "PROVINCIA": "S",
    "AFIP": 12097
  },
  {
    "DESCRIPCION": "PUNILLA",
    "CODIGO POSTAL": 5831,
    "PROVINCIA": "D",
    "AFIP": 18403
  },
  {
    "DESCRIPCION": "PUNTA ALTA",
    "CODIGO POSTAL": 8109,
    "PROVINCIA": "B",
    "AFIP": 12117
  },
  {
    "DESCRIPCION": "QUEMU QUEMU",
    "CODIGO POSTAL": 6333,
    "PROVINCIA": "L",
    "AFIP": 12213
  },
  {
    "DESCRIPCION": "QUENUMA",
    "CODIGO POSTAL": 6335,
    "PROVINCIA": "B",
    "AFIP": 12216
  },
  {
    "DESCRIPCION": "QUEQUEN",
    "CODIGO POSTAL": 7631,
    "PROVINCIA": "B",
    "AFIP": 12217
  },
  {
    "DESCRIPCION": "QUETREQUEN",
    "CODIGO POSTAL": 6212,
    "PROVINCIA": "L",
    "AFIP": 12221
  },
  {
    "DESCRIPCION": "QUILMES",
    "CODIGO POSTAL": 1878,
    "PROVINCIA": "B",
    "AFIP": 12237
  },
  {
    "DESCRIPCION": "QUIMILI",
    "CODIGO POSTAL": 3740,
    "PROVINCIA": "G",
    "AFIP": 12242
  },
  {
    "DESCRIPCION": "QUINES",
    "CODIGO POSTAL": 5711,
    "PROVINCIA": "D",
    "AFIP": 12247
  },
  {
    "DESCRIPCION": "QUIQUIZACATE",
    "CODIGO POSTAL": 5021,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "QUITILIPI",
    "CODIGO POSTAL": 3530,
    "PROVINCIA": "H",
    "AFIP": 12276
  },
  {
    "DESCRIPCION": "RADA TILLY",
    "CODIGO POSTAL": 9001,
    "PROVINCIA": "U",
    "AFIP": 12290
  },
  {
    "DESCRIPCION": "RAFAEL GARCIA",
    "CODIGO POSTAL": 5119,
    "PROVINCIA": "X",
    "AFIP": 12293
  },
  {
    "DESCRIPCION": "RAFAELA",
    "CODIGO POSTAL": 2300,
    "PROVINCIA": "S",
    "AFIP": 12295
  },
  {
    "DESCRIPCION": "RAICES OESTE",
    "CODIGO POSTAL": 3241,
    "PROVINCIA": "E",
    "AFIP": 12301
  },
  {
    "DESCRIPCION": "RAMALLO",
    "CODIGO POSTAL": 2915,
    "PROVINCIA": "B",
    "AFIP": 12309
  },
  {
    "DESCRIPCION": "RAMIREZ",
    "CODIGO POSTAL": 3164,
    "PROVINCIA": "E",
    "AFIP": 18457
  },
  {
    "DESCRIPCION": "RAMOS MEJIA",
    "CODIGO POSTAL": 1704,
    "PROVINCIA": "B",
    "AFIP": 12331
  },
  {
    "DESCRIPCION": "RANCHOS",
    "CODIGO POSTAL": 1987,
    "PROVINCIA": "B",
    "AFIP": 12338
  },
  {
    "DESCRIPCION": "RANCUL",
    "CODIGO POSTAL": 6214,
    "PROVINCIA": "L",
    "AFIP": 12341
  },
  {
    "DESCRIPCION": "RAPELLI",
    "CODIGO POSTAL": 4189,
    "PROVINCIA": "G",
    "AFIP": 12354
  },
  {
    "DESCRIPCION": "RAWSON",
    "CODIGO POSTAL": 5425,
    "PROVINCIA": "J",
    "AFIP": 19375
  },
  {
    "DESCRIPCION": "RAWSON",
    "CODIGO POSTAL": 6734,
    "PROVINCIA": "B",
    "AFIP": 12361
  },
  {
    "DESCRIPCION": "RAWSON",
    "CODIGO POSTAL": 9103,
    "PROVINCIA": "U",
    "AFIP": 18460
  },
  {
    "DESCRIPCION": "RAYO CORTADO",
    "CODIGO POSTAL": 5246,
    "PROVINCIA": "X",
    "AFIP": 12363
  },
  {
    "DESCRIPCION": "REAL DEL PADRE",
    "CODIGO POSTAL": 5624,
    "PROVINCIA": "M",
    "AFIP": 12373
  },
  {
    "DESCRIPCION": "REALICO",
    "CODIGO POSTAL": 6200,
    "PROVINCIA": "L",
    "AFIP": 12381
  },
  {
    "DESCRIPCION": "RECONQUISTA",
    "CODIGO POSTAL": 3560,
    "PROVINCIA": "S",
    "AFIP": 18461
  },
  {
    "DESCRIPCION": "RECREO",
    "CODIGO POSTAL": 3018,
    "PROVINCIA": "S",
    "AFIP": 18465
  },
  {
    "DESCRIPCION": "RECREO",
    "CODIGO POSTAL": 5260,
    "PROVINCIA": "K",
    "AFIP": 12389
  },
  {
    "DESCRIPCION": "RECREO SUR",
    "CODIGO POSTAL": 3001,
    "PROVINCIA": "S",
    "AFIP": 12390
  },
  {
    "DESCRIPCION": "REDUCCION",
    "CODIGO POSTAL": 5803,
    "PROVINCIA": "X",
    "AFIP": 12394
  },
  {
    "DESCRIPCION": "RESISTENCIA",
    "CODIGO POSTAL": 3500,
    "PROVINCIA": "H",
    "AFIP": 12432
  },
  {
    "DESCRIPCION": "RI OBAMBA",
    "CODIGO POSTAL": 6134,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "RICARDONE",
    "CODIGO POSTAL": 2201,
    "PROVINCIA": "S",
    "AFIP": 12464
  },
  {
    "DESCRIPCION": "RIO CEBALLOS",
    "CODIGO POSTAL": 5111,
    "PROVINCIA": "X",
    "AFIP": 12563
  },
  {
    "DESCRIPCION": "RIO COLORADO",
    "CODIGO POSTAL": 8138,
    "PROVINCIA": "R",
    "AFIP": 18503
  },
  {
    "DESCRIPCION": "RIO CUARTO",
    "CODIGO POSTAL": 5800,
    "PROVINCIA": "X",
    "AFIP": 12568
  },
  {
    "DESCRIPCION": "RIO GALLEGOS",
    "CODIGO POSTAL": 9400,
    "PROVINCIA": "Z",
    "AFIP": 12592
  },
  {
    "DESCRIPCION": "RIO GRANDE",
    "CODIGO POSTAL": 5701,
    "PROVINCIA": "D",
    "AFIP": 18509
  },
  {
    "DESCRIPCION": "RIO GRANDE",
    "CODIGO POSTAL": 9420,
    "PROVINCIA": "V",
    "AFIP": 18510
  },
  {
    "DESCRIPCION": "RIO PRIMERO",
    "CODIGO POSTAL": 5127,
    "PROVINCIA": "X",
    "AFIP": 12615
  },
  {
    "DESCRIPCION": "RIO SECO",
    "CODIGO POSTAL": 5284,
    "PROVINCIA": "X",
    "AFIP": 12622
  },
  {
    "DESCRIPCION": "RIO SEGUNDO",
    "CODIGO POSTAL": 5960,
    "PROVINCIA": "X",
    "AFIP": 12623
  },
  {
    "DESCRIPCION": "RIO TALA",
    "CODIGO POSTAL": 2944,
    "PROVINCIA": "B",
    "AFIP": 12624
  },
  {
    "DESCRIPCION": "RIO TERCERO",
    "CODIGO POSTAL": 5850,
    "PROVINCIA": "X",
    "AFIP": 12627
  },
  {
    "DESCRIPCION": "RIO TURBIO",
    "CODIGO POSTAL": 9407,
    "PROVINCIA": "Z",
    "AFIP": 12629
  },
  {
    "DESCRIPCION": "RIVADAVIA",
    "CODIGO POSTAL": 5577,
    "PROVINCIA": "M",
    "AFIP": 18520
  },
  {
    "DESCRIPCION": "RIVERA",
    "CODIGO POSTAL": 6441,
    "PROVINCIA": "B",
    "AFIP": 12641
  },
  {
    "DESCRIPCION": "ROCAMORA",
    "CODIGO POSTAL": 3172,
    "PROVINCIA": "E",
    "AFIP": 12648
  },
  {
    "DESCRIPCION": "ROJAS",
    "CODIGO POSTAL": 2705,
    "PROVINCIA": "B",
    "AFIP": 12677
  },
  {
    "DESCRIPCION": "ROLDAN",
    "CODIGO POSTAL": 2134,
    "PROVINCIA": "S",
    "AFIP": 12678
  },
  {
    "DESCRIPCION": "ROMANG",
    "CODIGO POSTAL": 3555,
    "PROVINCIA": "S",
    "AFIP": 12685
  },
  {
    "DESCRIPCION": "ROQUE PEREZ",
    "CODIGO POSTAL": 7245,
    "PROVINCIA": "B",
    "AFIP": 12694
  },
  {
    "DESCRIPCION": "ROSARIO",
    "CODIGO POSTAL": 2000,
    "PROVINCIA": "S",
    "AFIP": 12699
  },
  {
    "DESCRIPCION": "ROSARIO LERMA",
    "CODIGO POSTAL": 4405,
    "PROVINCIA": "A",
    "AFIP": 12702
  },
  {
    "DESCRIPCION": "ROVERSI",
    "CODIGO POSTAL": 3736,
    "PROVINCIA": "G",
    "AFIP": 12714
  },
  {
    "DESCRIPCION": "RUEDA",
    "CODIGO POSTAL": 2921,
    "PROVINCIA": "S",
    "AFIP": 12722
  },
  {
    "DESCRIPCION": "RUFINO",
    "CODIGO POSTAL": 6100,
    "PROVINCIA": "S",
    "AFIP": 12723
  },
  {
    "DESCRIPCION": "RUNCIMAN",
    "CODIGO POSTAL": 2611,
    "PROVINCIA": "S",
    "AFIP": 12749
  },
  {
    "DESCRIPCION": "SAAVEDRA",
    "CODIGO POSTAL": 8174,
    "PROVINCIA": "B",
    "AFIP": 12787
  },
  {
    "DESCRIPCION": "SACANTA",
    "CODIGO POSTAL": 5945,
    "PROVINCIA": "X",
    "AFIP": 12790
  },
  {
    "DESCRIPCION": "SACHAYOJ",
    "CODIGO POSTAL": 3731,
    "PROVINCIA": "G",
    "AFIP": 12792
  },
  {
    "DESCRIPCION": "SAFORCADA",
    "CODIGO POSTAL": 6022,
    "PROVINCIA": "B",
    "AFIP": 12796
  },
  {
    "DESCRIPCION": "SAIRA",
    "CODIGO POSTAL": 2525,
    "PROVINCIA": "X",
    "AFIP": 12802
  },
  {
    "DESCRIPCION": "SALADAS",
    "CODIGO POSTAL": 3420,
    "PROVINCIA": "W",
    "AFIP": 12808
  },
  {
    "DESCRIPCION": "SALADILLO",
    "CODIGO POSTAL": 7260,
    "PROVINCIA": "B",
    "AFIP": 12814
  },
  {
    "DESCRIPCION": "SALAZAR",
    "CODIGO POSTAL": 6471,
    "PROVINCIA": "B",
    "AFIP": 12829
  },
  {
    "DESCRIPCION": "SALDUNGARAY",
    "CODIGO POSTAL": 8166,
    "PROVINCIA": "B",
    "AFIP": 12835
  },
  {
    "DESCRIPCION": "SALICAS",
    "CODIGO POSTAL": 5327,
    "PROVINCIA": "F",
    "AFIP": 12837
  },
  {
    "DESCRIPCION": "SALLIQUELO",
    "CODIGO POSTAL": 6339,
    "PROVINCIA": "B",
    "AFIP": 12853
  },
  {
    "DESCRIPCION": "SALSACATE",
    "CODIGO POSTAL": 5295,
    "PROVINCIA": "X",
    "AFIP": 12855
  },
  {
    "DESCRIPCION": "SALSIPUEDES",
    "CODIGO POSTAL": 5113,
    "PROVINCIA": "X",
    "AFIP": 12856
  },
  {
    "DESCRIPCION": "SALTA",
    "CODIGO POSTAL": 4400,
    "PROVINCIA": "A",
    "AFIP": 12857
  },
  {
    "DESCRIPCION": "SALTA",
    "CODIGO POSTAL": 4400,
    "PROVINCIA": "A",
    "AFIP": 12857
  },
  {
    "DESCRIPCION": "SALTO",
    "CODIGO POSTAL": 2741,
    "PROVINCIA": "B",
    "AFIP": 12858
  },
  {
    "DESCRIPCION": "SALTO GRANDE",
    "CODIGO POSTAL": 2142,
    "PROVINCIA": "S",
    "AFIP": 12863
  },
  {
    "DESCRIPCION": "SAMPACHO",
    "CODIGO POSTAL": 5829,
    "PROVINCIA": "X",
    "AFIP": 12871
  },
  {
    "DESCRIPCION": "SAN AGUSTIN",
    "CODIGO POSTAL": 5191,
    "PROVINCIA": "X",
    "AFIP": 18561
  },
  {
    "DESCRIPCION": "SAN AGUSTIN",
    "CODIGO POSTAL": 7623,
    "PROVINCIA": "B",
    "AFIP": 12874
  },
  {
    "DESCRIPCION": "SAN AGUSTIN",
    "CODIGO POSTAL": 3019,
    "PROVINCIA": "S",
    "AFIP": 18566
  },
  {
    "DESCRIPCION": "SAN ANDRES",
    "CODIGO POSTAL": 1651,
    "PROVINCIA": "B",
    "AFIP": 12880
  },
  {
    "DESCRIPCION": "SAN BARTOLO",
    "CODIGO POSTAL": 5249,
    "PROVINCIA": "X",
    "AFIP": 12912
  },
  {
    "DESCRIPCION": "SAN BARTOLOME",
    "CODIGO POSTAL": 5801,
    "PROVINCIA": "X",
    "AFIP": 12913
  },
  {
    "DESCRIPCION": "SAN BASILIO",
    "CODIGO POSTAL": 5841,
    "PROVINCIA": "X",
    "AFIP": 12914
  },
  {
    "DESCRIPCION": "SAN BERNARDO",
    "CODIGO POSTAL": 3701,
    "PROVINCIA": "H",
    "AFIP": 18605
  },
  {
    "DESCRIPCION": "SAN BERNARDO",
    "CODIGO POSTAL": 6561,
    "PROVINCIA": "B",
    "AFIP": 12916
  },
  {
    "DESCRIPCION": "SAN BERNARDO",
    "CODIGO POSTAL": 6561,
    "PROVINCIA": "B",
    "AFIP": 12916
  },
  {
    "DESCRIPCION": "SAN CAYETANO",
    "CODIGO POSTAL": 7521,
    "PROVINCIA": "B",
    "AFIP": 12929
  },
  {
    "DESCRIPCION": "SAN CRISTOBAL",
    "CODIGO POSTAL": 3070,
    "PROVINCIA": "S",
    "AFIP": 18635
  },
  {
    "DESCRIPCION": "SAN EDUARDO",
    "CODIGO POSTAL": 2615,
    "PROVINCIA": "S",
    "AFIP": 18642
  },
  {
    "DESCRIPCION": "SAN ENRIQUE",
    "CODIGO POSTAL": 6661,
    "PROVINCIA": "B",
    "AFIP": 12946
  },
  {
    "DESCRIPCION": "SAN ENRIQUE",
    "CODIGO POSTAL": 6661,
    "PROVINCIA": "B",
    "AFIP": 12946
  },
  {
    "DESCRIPCION": "SAN EUGENIO",
    "CODIGO POSTAL": 2256,
    "PROVINCIA": "S",
    "AFIP": 18648
  },
  {
    "DESCRIPCION": "SAN FERNANDO",
    "CODIGO POSTAL": 1645,
    "PROVINCIA": "B",
    "AFIP": 12959
  },
  {
    "DESCRIPCION": "SAN FRANCISCO",
    "CODIGO POSTAL": 2400,
    "PROVINCIA": "X",
    "AFIP": 18666
  },
  {
    "DESCRIPCION": "SAN FRANCISCO",
    "CODIGO POSTAL": 5705,
    "PROVINCIA": "D",
    "AFIP": 5951
  },
  {
    "DESCRIPCION": "SAN FRANCISCO",
    "CODIGO POSTAL": 2604,
    "PROVINCIA": "S",
    "AFIP": 22174
  },
  {
    "DESCRIPCION": "SAN GENARO",
    "CODIGO POSTAL": 2146,
    "PROVINCIA": "S",
    "AFIP": 12976
  },
  {
    "DESCRIPCION": "SAN GREGORIO",
    "CODIGO POSTAL": 2613,
    "PROVINCIA": "S",
    "AFIP": 18688
  },
  {
    "DESCRIPCION": "SAN GUILLERMO",
    "CODIGO POSTAL": 2347,
    "PROVINCIA": "S",
    "AFIP": 18691
  },
  {
    "DESCRIPCION": "SAN GUSTAVO",
    "CODIGO POSTAL": 3191,
    "PROVINCIA": "E",
    "AFIP": 18693
  },
  {
    "DESCRIPCION": "SAN IGNACIO",
    "CODIGO POSTAL": 3322,
    "PROVINCIA": "N",
    "AFIP": 18700
  },
  {
    "DESCRIPCION": "SAN ISIDRO",
    "CODIGO POSTAL": 1642,
    "PROVINCIA": "B",
    "AFIP": 12991
  },
  {
    "DESCRIPCION": "SAN JAVIER",
    "CODIGO POSTAL": 3005,
    "PROVINCIA": "S",
    "AFIP": 18725
  },
  {
    "DESCRIPCION": "SAN JAVIER",
    "CODIGO POSTAL": 3357,
    "PROVINCIA": "N",
    "AFIP": 18722
  },
  {
    "DESCRIPCION": "SAN JORGE",
    "CODIGO POSTAL": 2451,
    "PROVINCIA": "S",
    "AFIP": 18740
  },
  {
    "DESCRIPCION": "SAN JORGE",
    "CODIGO POSTAL": 5012,
    "PROVINCIA": "X",
    "AFIP": 18735
  },
  {
    "DESCRIPCION": "SAN JORGE",
    "CODIGO POSTAL": 2452,
    "PROVINCIA": "S",
    "AFIP": 18740
  },
  {
    "DESCRIPCION": "SAN JOSE",
    "CODIGO POSTAL": 3283,
    "PROVINCIA": "E",
    "AFIP": 18747
  },
  {
    "DESCRIPCION": "SAN JUAN",
    "CODIGO POSTAL": 5400,
    "PROVINCIA": "J",
    "AFIP": 18768
  },
  {
    "DESCRIPCION": "SAN JULIAN",
    "CODIGO POSTAL": 9310,
    "PROVINCIA": "Z",
    "AFIP": 11864
  },
  {
    "DESCRIPCION": "SAN JULIAN",
    "CODIGO POSTAL": 9310,
    "PROVINCIA": "Z",
    "AFIP": 11864
  },
  {
    "DESCRIPCION": "SAN JUSTO",
    "CODIGO POSTAL": 1754,
    "PROVINCIA": "B",
    "AFIP": 13060
  },
  {
    "DESCRIPCION": "SAN JUSTO",
    "CODIGO POSTAL": 3040,
    "PROVINCIA": "S",
    "AFIP": 18780
  },
  {
    "DESCRIPCION": "SAN JUSTO",
    "CODIGO POSTAL": 3253,
    "PROVINCIA": "E",
    "AFIP": 18778
  },
  {
    "DESCRIPCION": "SAN LORENZO",
    "CODIGO POSTAL": 2200,
    "PROVINCIA": "S",
    "AFIP": 18794
  },
  {
    "DESCRIPCION": "SAN LORENZO",
    "CODIGO POSTAL": 2200,
    "PROVINCIA": "S",
    "AFIP": 18794
  },
  {
    "DESCRIPCION": "SAN LUIS",
    "CODIGO POSTAL": 5700,
    "PROVINCIA": "D",
    "AFIP": 18803
  },
  {
    "DESCRIPCION": "SAN MANUEL",
    "CODIGO POSTAL": 7007,
    "PROVINCIA": "B",
    "AFIP": 13073
  },
  {
    "DESCRIPCION": "SAN MARCOS SUD",
    "CODIGO POSTAL": 2566,
    "PROVINCIA": "X",
    "AFIP": 13078
  },
  {
    "DESCRIPCION": "SAN MARTIN",
    "CODIGO POSTAL": 1650,
    "PROVINCIA": "B",
    "AFIP": 6413
  },
  {
    "DESCRIPCION": "SAN MARTIN",
    "CODIGO POSTAL": 5570,
    "PROVINCIA": "M",
    "AFIP": 5957
  },
  {
    "DESCRIPCION": "SAN MARTIN",
    "CODIGO POSTAL": 5755,
    "PROVINCIA": "D",
    "AFIP": 18821
  },
  {
    "DESCRIPCION": "SAN MIGUEL",
    "CODIGO POSTAL": 1663,
    "PROVINCIA": "B",
    "AFIP": 13092
  },
  {
    "DESCRIPCION": "SAN MIGUEL",
    "CODIGO POSTAL": 3224,
    "PROVINCIA": "W",
    "AFIP": 18827
  },
  {
    "DESCRIPCION": "SAN NICOLAS",
    "CODIGO POSTAL": 2900,
    "PROVINCIA": "B",
    "AFIP": 21820
  },
  {
    "DESCRIPCION": "SAN PEDRO",
    "CODIGO POSTAL": 2930,
    "PROVINCIA": "B",
    "AFIP": 13115
  },
  {
    "DESCRIPCION": "SAN PEDRO",
    "CODIGO POSTAL": 4238,
    "PROVINCIA": "G",
    "AFIP": 18866
  },
  {
    "DESCRIPCION": "SAN PEDRO",
    "CODIGO POSTAL": 5621,
    "PROVINCIA": "M",
    "AFIP": 18860
  },
  {
    "DESCRIPCION": "SAN RAFAEL",
    "CODIGO POSTAL": 5600,
    "PROVINCIA": "M",
    "AFIP": 18875
  },
  {
    "DESCRIPCION": "SAN RAMON",
    "CODIGO POSTAL": 4302,
    "PROVINCIA": "G",
    "AFIP": 18886
  },
  {
    "DESCRIPCION": "SAN ROMAN",
    "CODIGO POSTAL": 8154,
    "PROVINCIA": "B",
    "AFIP": 13142
  },
  {
    "DESCRIPCION": "SAN ROQUE",
    "CODIGO POSTAL": 3448,
    "PROVINCIA": "W",
    "AFIP": 18890
  },
  {
    "DESCRIPCION": "SAN SALVADOR",
    "CODIGO POSTAL": 3218,
    "PROVINCIA": "E",
    "AFIP": 18901
  },
  {
    "DESCRIPCION": "SAN SEBASTIAN",
    "CODIGO POSTAL": 6623,
    "PROVINCIA": "B",
    "AFIP": 13150
  },
  {
    "DESCRIPCION": "SAN VICENTE",
    "CODIGO POSTAL": 1865,
    "PROVINCIA": "B",
    "AFIP": 13157
  },
  {
    "DESCRIPCION": "SAN VICENTE",
    "CODIGO POSTAL": 2447,
    "PROVINCIA": "S",
    "AFIP": 18926
  },
  {
    "DESCRIPCION": "SANFORD",
    "CODIGO POSTAL": 2173,
    "PROVINCIA": "S",
    "AFIP": 13170
  },
  {
    "DESCRIPCION": "SANSINENA",
    "CODIGO POSTAL": 6233,
    "PROVINCIA": "B",
    "AFIP": 13174
  },
  {
    "DESCRIPCION": "SANTA ANITA",
    "CODIGO POSTAL": 3248,
    "PROVINCIA": "E",
    "AFIP": 13181
  },
  {
    "DESCRIPCION": "SANTA ELENA",
    "CODIGO POSTAL": 3192,
    "PROVINCIA": "E",
    "AFIP": 18974
  },
  {
    "DESCRIPCION": "SANTA EUFEMIA",
    "CODIGO POSTAL": 2672,
    "PROVINCIA": "X",
    "AFIP": 13207
  },
  {
    "DESCRIPCION": "SANTA FE",
    "CODIGO POSTAL": 3000,
    "PROVINCIA": "S",
    "AFIP": 13208
  },
  {
    "DESCRIPCION": "SANTA FE",
    "CODIGO POSTAL": 3000,
    "PROVINCIA": "S",
    "AFIP": 13208
  },
  {
    "DESCRIPCION": "SANTA ISABEL",
    "CODIGO POSTAL": 2605,
    "PROVINCIA": "S",
    "AFIP": 19001
  },
  {
    "DESCRIPCION": "SANTA ISABEL",
    "CODIGO POSTAL": 5282,
    "PROVINCIA": "X",
    "AFIP": 18994
  },
  {
    "DESCRIPCION": "SANTA ISABEL",
    "CODIGO POSTAL": 6323,
    "PROVINCIA": "L",
    "AFIP": 18998
  },
  {
    "DESCRIPCION": "SANTA LUCIA",
    "CODIGO POSTAL": 2935,
    "PROVINCIA": "B",
    "AFIP": 13224
  },
  {
    "DESCRIPCION": "SANTA LUCIA",
    "CODIGO POSTAL": 3440,
    "PROVINCIA": "W",
    "AFIP": 19007
  },
  {
    "DESCRIPCION": "SANTA LUCIA",
    "CODIGO POSTAL": 5411,
    "PROVINCIA": "J",
    "AFIP": 19010
  },
  {
    "DESCRIPCION": "SANTA MARGARITA",
    "CODIGO POSTAL": 3063,
    "PROVINCIA": "S",
    "AFIP": 13229
  },
  {
    "DESCRIPCION": "SANTA MARIA",
    "CODIGO POSTAL": 4139,
    "PROVINCIA": "K",
    "AFIP": 19018
  },
  {
    "DESCRIPCION": "SANTA ROSA",
    "CODIGO POSTAL": 3466,
    "PROVINCIA": "W",
    "AFIP": 19048
  },
  {
    "DESCRIPCION": "SANTA ROSA",
    "CODIGO POSTAL": 3466,
    "PROVINCIA": "W",
    "AFIP": 19048
  },
  {
    "DESCRIPCION": "SANTA ROSA",
    "CODIGO POSTAL": 5596,
    "PROVINCIA": "M",
    "AFIP": 19053
  },
  {
    "DESCRIPCION": "SANTA ROSA",
    "CODIGO POSTAL": 5777,
    "PROVINCIA": "D",
    "AFIP": 19056
  },
  {
    "DESCRIPCION": "SANTA ROSA",
    "CODIGO POSTAL": 6300,
    "PROVINCIA": "L",
    "AFIP": 19051
  },
  {
    "DESCRIPCION": "SANTA ROSA",
    "CODIGO POSTAL": 4709,
    "PROVINCIA": "K",
    "AFIP": 19045
  },
  {
    "DESCRIPCION": "SANTA TERESA",
    "CODIGO POSTAL": 2111,
    "PROVINCIA": "S",
    "AFIP": 19067
  },
  {
    "DESCRIPCION": "SANTA TERESITA",
    "CODIGO POSTAL": 7107,
    "PROVINCIA": "B",
    "AFIP": 13279
  },
  {
    "DESCRIPCION": "SANTIAGO TEMPLE",
    "CODIGO POSTAL": 5124,
    "PROVINCIA": "X",
    "AFIP": 13293
  },
  {
    "DESCRIPCION": "SANTO DOMINGO",
    "CODIGO POSTAL": 3025,
    "PROVINCIA": "S",
    "AFIP": 19085
  },
  {
    "DESCRIPCION": "SANTO DOMINGO",
    "CODIGO POSTAL": 7119,
    "PROVINCIA": "B",
    "AFIP": 13296
  },
  {
    "DESCRIPCION": "SANTO TOME",
    "CODIGO POSTAL": 3340,
    "PROVINCIA": "W",
    "AFIP": 13306
  },
  {
    "DESCRIPCION": "SANTOS LUGARES",
    "CODIGO POSTAL": 1676,
    "PROVINCIA": "B",
    "AFIP": 13307
  },
  {
    "DESCRIPCION": "SANTOS TESEI",
    "CODIGO POSTAL": 1688,
    "PROVINCIA": "B",
    "AFIP": 14816
  },
  {
    "DESCRIPCION": "SARANDI",
    "CODIGO POSTAL": 1872,
    "PROVINCIA": "B",
    "AFIP": 13316
  },
  {
    "DESCRIPCION": "SARASA",
    "CODIGO POSTAL": 2721,
    "PROVINCIA": "B",
    "AFIP": 13318
  },
  {
    "DESCRIPCION": "SARMIENTO",
    "CODIGO POSTAL": 5212,
    "PROVINCIA": "X",
    "AFIP": 19103
  },
  {
    "DESCRIPCION": "SASTRE",
    "CODIGO POSTAL": 2440,
    "PROVINCIA": "S",
    "AFIP": 13327
  },
  {
    "DESCRIPCION": "SASTRE",
    "CODIGO POSTAL": 2440,
    "PROVINCIA": "S",
    "AFIP": 13327
  },
  {
    "DESCRIPCION": "SAUCE DE LUNA",
    "CODIGO POSTAL": 3144,
    "PROVINCIA": "E",
    "AFIP": 13342
  },
  {
    "DESCRIPCION": "SAUCE PINTO",
    "CODIGO POSTAL": 3107,
    "PROVINCIA": "E",
    "AFIP": 13351
  },
  {
    "DESCRIPCION": "SAUCE VIEJO",
    "CODIGO POSTAL": 3017,
    "PROVINCIA": "S",
    "AFIP": 13358
  },
  {
    "DESCRIPCION": "SAYACO",
    "CODIGO POSTAL": 4203,
    "PROVINCIA": "G",
    "AFIP": 13374
  },
  {
    "DESCRIPCION": "SEGUI",
    "CODIGO POSTAL": 3117,
    "PROVINCIA": "E",
    "AFIP": 13390
  },
  {
    "DESCRIPCION": "SELVA",
    "CODIGO POSTAL": 2354,
    "PROVINCIA": "G",
    "AFIP": 13400
  },
  {
    "DESCRIPCION": "SERODINO",
    "CODIGO POSTAL": 2216,
    "PROVINCIA": "S",
    "AFIP": 13416
  },
  {
    "DESCRIPCION": "SERRANO",
    "CODIGO POSTAL": 6125,
    "PROVINCIA": "X",
    "AFIP": 13417
  },
  {
    "DESCRIPCION": "SIERRA GRANDE",
    "CODIGO POSTAL": 8532,
    "PROVINCIA": "R",
    "AFIP": 13458
  },
  {
    "DESCRIPCION": "SIMOCA",
    "CODIGO POSTAL": 4172,
    "PROVINCIA": "T",
    "AFIP": 13485
  },
  {
    "DESCRIPCION": "SINGUIL",
    "CODIGO POSTAL": 4711,
    "PROVINCIA": "K",
    "AFIP": 13489
  },
  {
    "DESCRIPCION": "SINSACATE",
    "CODIGO POSTAL": 5222,
    "PROVINCIA": "X",
    "AFIP": 13493
  },
  {
    "DESCRIPCION": "SMITH",
    "CODIGO POSTAL": 6531,
    "PROVINCIA": "B",
    "AFIP": 13498
  },
  {
    "DESCRIPCION": "SOL DE MAYO",
    "CODIGO POSTAL": 2709,
    "PROVINCIA": "B",
    "AFIP": 13508
  },
  {
    "DESCRIPCION": "SOLA",
    "CODIGO POSTAL": 3176,
    "PROVINCIA": "E",
    "AFIP": 13509
  },
  {
    "DESCRIPCION": "SOLIS",
    "CODIGO POSTAL": 2764,
    "PROVINCIA": "B",
    "AFIP": 13532
  },
  {
    "DESCRIPCION": "SOTELOS",
    "CODIGO POSTAL": 4304,
    "PROVINCIA": "G",
    "AFIP": 13546
  },
  {
    "DESCRIPCION": "SOVEN",
    "CODIGO POSTAL": 4725,
    "PROVINCIA": "D",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "STROBEL",
    "CODIGO POSTAL": 3101,
    "PROVINCIA": "E",
    "AFIP": 13561
  },
  {
    "DESCRIPCION": "STROEDER",
    "CODIGO POSTAL": 8508,
    "PROVINCIA": "B",
    "AFIP": 13562
  },
  {
    "DESCRIPCION": "SUARDI",
    "CODIGO POSTAL": 2349,
    "PROVINCIA": "S",
    "AFIP": 13563
  },
  {
    "DESCRIPCION": "SUC.CBA.",
    "CODIGO POSTAL": 5001,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "SUCO",
    "CODIGO POSTAL": 5840,
    "PROVINCIA": "X",
    "AFIP": 13567
  },
  {
    "DESCRIPCION": "SUCO",
    "CODIGO POSTAL": 3583,
    "PROVINCIA": "X",
    "AFIP": 13567
  },
  {
    "DESCRIPCION": "SUIPACHA",
    "CODIGO POSTAL": 6612,
    "PROVINCIA": "B",
    "AFIP": 13575
  },
  {
    "DESCRIPCION": "SUMAMPA",
    "CODIGO POSTAL": 4722,
    "PROVINCIA": "G",
    "AFIP": 19155
  },
  {
    "DESCRIPCION": "SUNCHALES",
    "CODIGO POSTAL": 2322,
    "PROVINCIA": "S",
    "AFIP": 13582
  },
  {
    "DESCRIPCION": "SUNCHO CORRAL",
    "CODIGO POSTAL": 4350,
    "PROVINCIA": "G",
    "AFIP": 13586
  },
  {
    "DESCRIPCION": "SUNDBLAD",
    "CODIGO POSTAL": 6401,
    "PROVINCIA": "B",
    "AFIP": 13591
  },
  {
    "DESCRIPCION": "T.C.J.C.SANCHEZ",
    "CODIGO POSTAL": 3611,
    "PROVINCIA": "P",
    "AFIP": 13524
  },
  {
    "DESCRIPCION": "TABOSSI",
    "CODIGO POSTAL": 3111,
    "PROVINCIA": "E",
    "AFIP": 13626
  },
  {
    "DESCRIPCION": "TACO POZO",
    "CODIGO POSTAL": 3714,
    "PROVINCIA": "H",
    "AFIP": 13647
  },
  {
    "DESCRIPCION": "TACO POZO",
    "CODIGO POSTAL": 5255,
    "PROVINCIA": "G",
    "AFIP": 19164
  },
  {
    "DESCRIPCION": "TACURAL",
    "CODIGO POSTAL": 2324,
    "PROVINCIA": "S",
    "AFIP": 19168
  },
  {
    "DESCRIPCION": "TAFI DEL VALLE",
    "CODIGO POSTAL": 4137,
    "PROVINCIA": "T",
    "AFIP": 13675
  },
  {
    "DESCRIPCION": "TAFI VIEJO",
    "CODIGO POSTAL": 4103,
    "PROVINCIA": "T",
    "AFIP": 13676
  },
  {
    "DESCRIPCION": "TALA",
    "CODIGO POSTAL": 4126,
    "PROVINCIA": "A",
    "AFIP": 19170
  },
  {
    "DESCRIPCION": "TANCACHA",
    "CODIGO POSTAL": 5933,
    "PROVINCIA": "X",
    "AFIP": 13742
  },
  {
    "DESCRIPCION": "TANDIL",
    "CODIGO POSTAL": 7000,
    "PROVINCIA": "B",
    "AFIP": 13743
  },
  {
    "DESCRIPCION": "TANTI",
    "CODIGO POSTAL": 5155,
    "PROVINCIA": "X",
    "AFIP": 13745
  },
  {
    "DESCRIPCION": "TAPALQUE",
    "CODIGO POSTAL": 7303,
    "PROVINCIA": "B",
    "AFIP": 13749
  },
  {
    "DESCRIPCION": "TAPSO",
    "CODIGO POSTAL": 4234,
    "PROVINCIA": "K",
    "AFIP": 13759
  },
  {
    "DESCRIPCION": "TARTAGAL",
    "CODIGO POSTAL": 4560,
    "PROVINCIA": "A",
    "AFIP": 19183
  },
  {
    "DESCRIPCION": "TELEN",
    "CODIGO POSTAL": 6321,
    "PROVINCIA": "L",
    "AFIP": 13802
  },
  {
    "DESCRIPCION": "TEODELINA",
    "CODIGO POSTAL": 6009,
    "PROVINCIA": "S",
    "AFIP": 13814
  },
  {
    "DESCRIPCION": "THEOBALD",
    "CODIGO POSTAL": 2918,
    "PROVINCIA": "S",
    "AFIP": 13842
  },
  {
    "DESCRIPCION": "THEOBALD",
    "CODIGO POSTAL": 2918,
    "PROVINCIA": "S",
    "AFIP": 13842
  },
  {
    "DESCRIPCION": "TICINO",
    "CODIGO POSTAL": 5927,
    "PROVINCIA": "X",
    "AFIP": 13844
  },
  {
    "DESCRIPCION": "TIGRE",
    "CODIGO POSTAL": 1648,
    "PROVINCIA": "B",
    "AFIP": 13851
  },
  {
    "DESCRIPCION": "TILISARAO",
    "CODIGO POSTAL": 5773,
    "PROVINCIA": "D",
    "AFIP": 13859
  },
  {
    "DESCRIPCION": "TIMBO VIEJO",
    "CODIGO POSTAL": 4101,
    "PROVINCIA": "T",
    "AFIP": 13869
  },
  {
    "DESCRIPCION": "TIMBUES",
    "CODIGO POSTAL": 2204,
    "PROVINCIA": "S",
    "AFIP": 22158
  },
  {
    "DESCRIPCION": "TINOCO",
    "CODIGO POSTAL": 5131,
    "PROVINCIA": "X",
    "AFIP": 13881
  },
  {
    "DESCRIPCION": "TINOGASTA",
    "CODIGO POSTAL": 5340,
    "PROVINCIA": "K",
    "AFIP": 13882
  },
  {
    "DESCRIPCION": "TINTINA",
    "CODIGO POSTAL": 3743,
    "PROVINCIA": "G",
    "AFIP": 13884
  },
  {
    "DESCRIPCION": "TIO PUJIO",
    "CODIGO POSTAL": 5936,
    "PROVINCIA": "X",
    "AFIP": 13893
  },
  {
    "DESCRIPCION": "TOAY",
    "CODIGO POSTAL": 6303,
    "PROVINCIA": "L",
    "AFIP": 13910
  },
  {
    "DESCRIPCION": "TODD",
    "CODIGO POSTAL": 2754,
    "PROVINCIA": "B",
    "AFIP": 13918
  },
  {
    "DESCRIPCION": "TOLEDO",
    "CODIGO POSTAL": 5123,
    "PROVINCIA": "X",
    "AFIP": 13927
  },
  {
    "DESCRIPCION": "TOMAS YOUNG",
    "CODIGO POSTAL": 3765,
    "PROVINCIA": "G",
    "AFIP": 13940
  },
  {
    "DESCRIPCION": "TORDILLA NORTE",
    "CODIGO POSTAL": 5135,
    "PROVINCIA": "X",
    "AFIP": 13952
  },
  {
    "DESCRIPCION": "TORNQUIST",
    "CODIGO POSTAL": 8160,
    "PROVINCIA": "B",
    "AFIP": 13954
  },
  {
    "DESCRIPCION": "TORRES",
    "CODIGO POSTAL": 6703,
    "PROVINCIA": "B",
    "AFIP": 13978
  },
  {
    "DESCRIPCION": "TORTUGAS",
    "CODIGO POSTAL": 2512,
    "PROVINCIA": "S",
    "AFIP": 13980
  },
  {
    "DESCRIPCION": "TORTUGUITAS",
    "CODIGO POSTAL": 1667,
    "PROVINCIA": "B",
    "AFIP": 13981
  },
  {
    "DESCRIPCION": "TOSQUITA",
    "CODIGO POSTAL": 6141,
    "PROVINCIA": "X",
    "AFIP": 13986
  },
  {
    "DESCRIPCION": "TOSTADO",
    "CODIGO POSTAL": 3060,
    "PROVINCIA": "S",
    "AFIP": 13987
  },
  {
    "DESCRIPCION": "TOTORAS",
    "CODIGO POSTAL": 2144,
    "PROVINCIA": "S",
    "AFIP": 13994
  },
  {
    "DESCRIPCION": "TRANCAS",
    "CODIGO POSTAL": 4124,
    "PROVINCIA": "T",
    "AFIP": 19213
  },
  {
    "DESCRIPCION": "TRANSITO",
    "CODIGO POSTAL": 2436,
    "PROVINCIA": "X",
    "AFIP": 14012
  },
  {
    "DESCRIPCION": "TRENEL",
    "CODIGO POSTAL": 6369,
    "PROVINCIA": "L",
    "AFIP": 14027
  },
  {
    "DESCRIPCION": "TRENQUE LAUQUEN",
    "CODIGO POSTAL": 6400,
    "PROVINCIA": "B",
    "AFIP": 14029
  },
  {
    "DESCRIPCION": "TRENQUE LAUQUEN",
    "CODIGO POSTAL": 8340,
    "PROVINCIA": "Q",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "TRES ALGARROBOS",
    "CODIGO POSTAL": 6723,
    "PROVINCIA": "B",
    "AFIP": 14034
  },
  {
    "DESCRIPCION": "TRES ARROYOS",
    "CODIGO POSTAL": 7500,
    "PROVINCIA": "B",
    "AFIP": 14037
  },
  {
    "DESCRIPCION": "TRES ESQUINAS",
    "CODIGO POSTAL": 3156,
    "PROVINCIA": "E",
    "AFIP": 19230
  },
  {
    "DESCRIPCION": "TRES ESTACAS",
    "CODIGO POSTAL": 3722,
    "PROVINCIA": "H",
    "AFIP": 14054
  },
  {
    "DESCRIPCION": "LAS PIEDRITAS",
    "CODIGO POSTAL": 3722,
    "PROVINCIA": "H",
    "AFIP": 17629
  },
  {
    "DESCRIPCION": "LAS BREÑAS",
    "CODIGO POSTAL": 3722,
    "PROVINCIA": "H",
    "AFIP": 8400
  },
  {
    "DESCRIPCION": "TRES ISLETAS",
    "CODIGO POSTAL": 3703,
    "PROVINCIA": "H",
    "AFIP": 14061
  },
  {
    "DESCRIPCION": "TRES LOMAS",
    "CODIGO POSTAL": 6409,
    "PROVINCIA": "B",
    "AFIP": 14068
  },
  {
    "DESCRIPCION": "TRES PUNTAS",
    "CODIGO POSTAL": 4707,
    "PROVINCIA": "K",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "TRES SARGENTOS",
    "CODIGO POSTAL": 6727,
    "PROVINCIA": "B",
    "AFIP": 14086
  },
  {
    "DESCRIPCION": "TRISTAN SUAREZ",
    "CODIGO POSTAL": 1806,
    "PROVINCIA": "B",
    "AFIP": 14104
  },
  {
    "DESCRIPCION": "TROPEZON",
    "CODIGO POSTAL": 6904,
    "PROVINCIA": "B",
    "AFIP": 14118
  },
  {
    "DESCRIPCION": "TULUMBA",
    "CODIGO POSTAL": 5203,
    "PROVINCIA": "X",
    "AFIP": 14146
  },
  {
    "DESCRIPCION": "TUNUYAN",
    "CODIGO POSTAL": 5560,
    "PROVINCIA": "M",
    "AFIP": 14158
  },
  {
    "DESCRIPCION": "TUPUNGATO",
    "CODIGO POSTAL": 5561,
    "PROVINCIA": "M",
    "AFIP": 14160
  },
  {
    "DESCRIPCION": "TURDERA",
    "CODIGO POSTAL": 1637,
    "PROVINCIA": "B",
    "AFIP": 14161
  },
  {
    "DESCRIPCION": "UBAJAY",
    "CODIGO POSTAL": 3287,
    "PROVINCIA": "E",
    "AFIP": 14178
  },
  {
    "DESCRIPCION": "UCACHA",
    "CODIGO POSTAL": 2677,
    "PROVINCIA": "X",
    "AFIP": 14180
  },
  {
    "DESCRIPCION": "UNQUILLO",
    "CODIGO POSTAL": 5109,
    "PROVINCIA": "X",
    "AFIP": 14198
  },
  {
    "DESCRIPCION": "URANGA",
    "CODIGO POSTAL": 2105,
    "PROVINCIA": "S",
    "AFIP": 14201
  },
  {
    "DESCRIPCION": "URDAMPILLETA",
    "CODIGO POSTAL": 6553,
    "PROVINCIA": "B",
    "AFIP": 14203
  },
  {
    "DESCRIPCION": "URDINARRAIN",
    "CODIGO POSTAL": 2826,
    "PROVINCIA": "E",
    "AFIP": 14204
  },
  {
    "DESCRIPCION": "URIBURU",
    "CODIGO POSTAL": 6354,
    "PROVINCIA": "L",
    "AFIP": 19266
  },
  {
    "DESCRIPCION": "URQUIZA",
    "CODIGO POSTAL": 2719,
    "PROVINCIA": "B",
    "AFIP": 14210
  },
  {
    "DESCRIPCION": "USAR EL 6339",
    "CODIGO POSTAL": 8045,
    "PROVINCIA": "B",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "USHUAIA",
    "CODIGO POSTAL": 9410,
    "PROVINCIA": "V",
    "AFIP": 14214
  },
  {
    "DESCRIPCION": "USPALLATA",
    "CODIGO POSTAL": 5545,
    "PROVINCIA": "M",
    "AFIP": 14219
  },
  {
    "DESCRIPCION": "V.SARMIENTO",
    "CODIGO POSTAL": 5489,
    "PROVINCIA": "J",
    "AFIP": 14578
  },
  {
    "DESCRIPCION": "VALCHETA",
    "CODIGO POSTAL": 8536,
    "PROVINCIA": "R",
    "AFIP": 14241
  },
  {
    "DESCRIPCION": "VALLE ESCONDIDO",
    "CODIGO POSTAL": 5003,
    "PROVINCIA": "X",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "VEDIA",
    "CODIGO POSTAL": 6030,
    "PROVINCIA": "B",
    "AFIP": 14291
  },
  {
    "DESCRIPCION": "VEINT.DE MAYO",
    "CODIGO POSTAL": 3377,
    "PROVINCIA": "N",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "VENADO TUERTO",
    "CODIGO POSTAL": 2600,
    "PROVINCIA": "S",
    "AFIP": 14310
  },
  {
    "DESCRIPCION": "VERA",
    "CODIGO POSTAL": 3550,
    "PROVINCIA": "S",
    "AFIP": 14320
  },
  {
    "DESCRIPCION": "VERONICA",
    "CODIGO POSTAL": 1917,
    "PROVINCIA": "B",
    "AFIP": 14330
  },
  {
    "DESCRIPCION": "VERTIZ",
    "CODIGO POSTAL": 6365,
    "PROVINCIA": "L",
    "AFIP": 14331
  },
  {
    "DESCRIPCION": "VIALE",
    "CODIGO POSTAL": 3109,
    "PROVINCIA": "E",
    "AFIP": 14337
  },
  {
    "DESCRIPCION": "VIAMONTE",
    "CODIGO POSTAL": 2671,
    "PROVINCIA": "X",
    "AFIP": 14338
  },
  {
    "DESCRIPCION": "VICENTE LOPEZ",
    "CODIGO POSTAL": 1638,
    "PROVINCIA": "B",
    "AFIP": 14342
  },
  {
    "DESCRIPCION": "VICTORIA",
    "CODIGO POSTAL": 1644,
    "PROVINCIA": "B",
    "AFIP": 14350
  },
  {
    "DESCRIPCION": "VICTORIA",
    "CODIGO POSTAL": 1644,
    "PROVINCIA": "B",
    "AFIP": 14350
  },
  {
    "DESCRIPCION": "VICTORIA",
    "CODIGO POSTAL": 3153,
    "PROVINCIA": "E",
    "AFIP": 19281
  },
  {
    "DESCRIPCION": "VICTORIA",
    "CODIGO POSTAL": 3153,
    "PROVINCIA": "E",
    "AFIP": 19281
  },
  {
    "DESCRIPCION": "VICTORICA",
    "CODIGO POSTAL": 6319,
    "PROVINCIA": "L",
    "AFIP": 14351
  },
  {
    "DESCRIPCION": "VIDELA",
    "CODIGO POSTAL": 3048,
    "PROVINCIA": "S",
    "AFIP": 14356
  },
  {
    "DESCRIPCION": "VIDELA",
    "CODIGO POSTAL": 3048,
    "PROVINCIA": "S",
    "AFIP": 14356
  },
  {
    "DESCRIPCION": "VIEDMA",
    "CODIGO POSTAL": 8500,
    "PROVINCIA": "R",
    "AFIP": 14357
  },
  {
    "DESCRIPCION": "VILA",
    "CODIGO POSTAL": 2306,
    "PROVINCIA": "S",
    "AFIP": 14363
  },
  {
    "DESCRIPCION": "VILELAS",
    "CODIGO POSTAL": 3503,
    "PROVINCIA": "H",
    "AFIP": 11886
  },
  {
    "DESCRIPCION": "VILELAS",
    "CODIGO POSTAL": 3752,
    "PROVINCIA": "G",
    "AFIP": 14367
  },
  {
    "DESCRIPCION": "VILLA ADELA",
    "CODIGO POSTAL": 3596,
    "PROVINCIA": "S",
    "AFIP": 14374
  },
  {
    "DESCRIPCION": "VILLA ADELINA",
    "CODIGO POSTAL": 1607,
    "PROVINCIA": "B",
    "AFIP": 14376
  },
  {
    "DESCRIPCION": "VILLA ALICURA",
    "CODIGO POSTAL": 8403,
    "PROVINCIA": "Q",
    "AFIP": 344
  },
  {
    "DESCRIPCION": "VILLA ALLENDE",
    "CODIGO POSTAL": 5105,
    "PROVINCIA": "X",
    "AFIP": 14388
  },
  {
    "DESCRIPCION": "VILLA ANGELA",
    "CODIGO POSTAL": 3540,
    "PROVINCIA": "H",
    "AFIP": 14396
  },
  {
    "DESCRIPCION": "VILLA ASCASUBI",
    "CODIGO POSTAL": 5935,
    "PROVINCIA": "X",
    "AFIP": 14407
  },
  {
    "DESCRIPCION": "VILLA ATUEL",
    "CODIGO POSTAL": 5622,
    "PROVINCIA": "M",
    "AFIP": 14410
  },
  {
    "DESCRIPCION": "VILLA BALLESTER",
    "CODIGO POSTAL": 1653,
    "PROVINCIA": "B",
    "AFIP": 14413
  },
  {
    "DESCRIPCION": "VILLA CAÑAS",
    "CODIGO POSTAL": 2607,
    "PROVINCIA": "S",
    "AFIP": 14454
  },
  {
    "DESCRIPCION": "VILLA CELINA",
    "CODIGO POSTAL": 1771,
    "PROVINCIA": "B",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "VILLA CLARA",
    "CODIGO POSTAL": 3252,
    "PROVINCIA": "E",
    "AFIP": 14462
  },
  {
    "DESCRIPCION": "VILLA DOLORES",
    "CODIGO POSTAL": 5870,
    "PROVINCIA": "X",
    "AFIP": 19293
  },
  {
    "DESCRIPCION": "VILLA DOMINICO",
    "CODIGO POSTAL": 1874,
    "PROVINCIA": "B",
    "AFIP": 14521
  },
  {
    "DESCRIPCION": "VILLA EL CHOCON",
    "CODIGO POSTAL": 8311,
    "PROVINCIA": "Q",
    "AFIP": 14528
  },
  {
    "DESCRIPCION": "VILLA ELISA",
    "CODIGO POSTAL": 1894,
    "PROVINCIA": "B",
    "AFIP": 14537
  },
  {
    "DESCRIPCION": "VILLA ELOISA",
    "CODIGO POSTAL": 2503,
    "PROVINCIA": "S",
    "AFIP": 14538
  },
  {
    "DESCRIPCION": "VILLA FONTANA",
    "CODIGO POSTAL": 3118,
    "PROVINCIA": "E",
    "AFIP": 19303
  },
  {
    "DESCRIPCION": "VILLA GIARDINO",
    "CODIGO POSTAL": 5176,
    "PROVINCIA": "X",
    "AFIP": 14581
  },
  {
    "DESCRIPCION": "VILLA HIPODROMO",
    "CODIGO POSTAL": 5547,
    "PROVINCIA": "M",
    "AFIP": 14602
  },
  {
    "DESCRIPCION": "VILLA IRIS",
    "CODIGO POSTAL": 8126,
    "PROVINCIA": "B",
    "AFIP": 14610
  },
  {
    "DESCRIPCION": "VILLA LIA",
    "CODIGO POSTAL": 2761,
    "PROVINCIA": "B",
    "AFIP": 14647
  },
  {
    "DESCRIPCION": "VILLA LYNCH",
    "CODIGO POSTAL": 1672,
    "PROVINCIA": "B",
    "AFIP": 14667
  },
  {
    "DESCRIPCION": "VILLA MADERO",
    "CODIGO POSTAL": 1768,
    "PROVINCIA": "B",
    "AFIP": 19378
  },
  {
    "DESCRIPCION": "VILLA MARIA",
    "CODIGO POSTAL": 5900,
    "PROVINCIA": "X",
    "AFIP": 19311
  },
  {
    "DESCRIPCION": "VILLA MARINI",
    "CODIGO POSTAL": 5504,
    "PROVINCIA": "M",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "VILLA MARTELLI",
    "CODIGO POSTAL": 1603,
    "PROVINCIA": "B",
    "AFIP": 14680
  },
  {
    "DESCRIPCION": "VILLA MAZA",
    "CODIGO POSTAL": 6343,
    "PROVINCIA": "B",
    "AFIP": 14688
  },
  {
    "DESCRIPCION": "VILLA MERCEDES",
    "CODIGO POSTAL": 5461,
    "PROVINCIA": "J",
    "AFIP": 19313
  },
  {
    "DESCRIPCION": "VILLA MINETTI",
    "CODIGO POSTAL": 3061,
    "PROVINCIA": "S",
    "AFIP": 14693
  },
  {
    "DESCRIPCION": "VILLA MOLL",
    "CODIGO POSTAL": 6627,
    "PROVINCIA": "B",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "VILLA MUGUETA",
    "CODIGO POSTAL": 2175,
    "PROVINCIA": "S",
    "AFIP": 14704
  },
  {
    "DESCRIPCION": "VILLA NOUGUES",
    "CODIGO POSTAL": 4105,
    "PROVINCIA": "T",
    "AFIP": 14712
  },
  {
    "DESCRIPCION": "VILLA NUEVA",
    "CODIGO POSTAL": 5521,
    "PROVINCIA": "M",
    "AFIP": 14715
  },
  {
    "DESCRIPCION": "VILLA NUEVA",
    "CODIGO POSTAL": 5903,
    "PROVINCIA": "X",
    "AFIP": 14713
  },
  {
    "DESCRIPCION": "VILLA NUEVA",
    "CODIGO POSTAL": 7225,
    "PROVINCIA": "B",
    "AFIP": 21719
  },
  {
    "DESCRIPCION": "VILLA OCAMPO",
    "CODIGO POSTAL": 3580,
    "PROVINCIA": "S",
    "AFIP": 14718
  },
  {
    "DESCRIPCION": "VILLA RAMALLO",
    "CODIGO POSTAL": 2914,
    "PROVINCIA": "B",
    "AFIP": 14757
  },
  {
    "DESCRIPCION": "VILLA REGINA",
    "CODIGO POSTAL": 8336,
    "PROVINCIA": "R",
    "AFIP": 14761
  },
  {
    "DESCRIPCION": "VILLA REYNOLDS",
    "CODIGO POSTAL": 5733,
    "PROVINCIA": "D",
    "AFIP": 14763
  },
  {
    "DESCRIPCION": "VILLA ROSSI",
    "CODIGO POSTAL": 6128,
    "PROVINCIA": "X",
    "AFIP": 14780
  },
  {
    "DESCRIPCION": "VILLA RUIZ",
    "CODIGO POSTAL": 6705,
    "PROVINCIA": "B",
    "AFIP": 14783
  },
  {
    "DESCRIPCION": "VILLA SARMIENTO",
    "CODIGO POSTAL": 6273,
    "PROVINCIA": "X",
    "AFIP": 19335
  },
  {
    "DESCRIPCION": "VILLA SAUZE",
    "CODIGO POSTAL": 6235,
    "PROVINCIA": "B",
    "AFIP": 21765
  },
  {
    "DESCRIPCION": "VILLA TESEI",
    "CODIGO POSTAL": 1891,
    "PROVINCIA": "B",
    "AFIP": 14816
  },
  {
    "DESCRIPCION": "VILLA TRINIDAD",
    "CODIGO POSTAL": 2345,
    "PROVINCIA": "S",
    "AFIP": 14851
  },
  {
    "DESCRIPCION": "VILLA UNION",
    "CODIGO POSTAL": 5350,
    "PROVINCIA": "F",
    "AFIP": 14854
  },
  {
    "DESCRIPCION": "VILLAGUAY",
    "CODIGO POSTAL": 3240,
    "PROVINCIA": "E",
    "AFIP": 14877
  },
  {
    "DESCRIPCION": "VILLALONGA",
    "CODIGO POSTAL": 8512,
    "PROVINCIA": "B",
    "AFIP": 14881
  },
  {
    "DESCRIPCION": "VINAL POZO",
    "CODIGO POSTAL": 3749,
    "PROVINCIA": "G",
    "AFIP": 19343
  },
  {
    "DESCRIPCION": "VINCHINA",
    "CODIGO POSTAL": 5357,
    "PROVINCIA": "F",
    "AFIP": 14898
  },
  {
    "DESCRIPCION": "VIRASORO",
    "CODIGO POSTAL": 3342,
    "PROVINCIA": "W",
    "AFIP": 22092
  },
  {
    "DESCRIPCION": "VIRGINIA",
    "CODIGO POSTAL": 2311,
    "PROVINCIA": "S",
    "AFIP": 14906
  },
  {
    "DESCRIPCION": "VIRREY DEL PINO",
    "CODIGO POSTAL": 1763,
    "PROVINCIA": "B",
    "AFIP": 14907
  },
  {
    "DESCRIPCION": "VIRREYES",
    "CODIGO POSTAL": 1646,
    "PROVINCIA": "B",
    "AFIP": 14909
  },
  {
    "DESCRIPCION": "VISTA FLORES",
    "CODIGO POSTAL": 5565,
    "PROVINCIA": "M",
    "AFIP": 14917
  },
  {
    "DESCRIPCION": "WARNES",
    "CODIGO POSTAL": 6646,
    "PROVINCIA": "B",
    "AFIP": 14957
  },
  {
    "DESCRIPCION": "WASHINGTON",
    "CODIGO POSTAL": 6144,
    "PROVINCIA": "X",
    "AFIP": 14958
  },
  {
    "DESCRIPCION": "WEISBURD",
    "CODIGO POSTAL": 4351,
    "PROVINCIA": "G",
    "AFIP": 14960
  },
  {
    "DESCRIPCION": "WHEELWRIGHT",
    "CODIGO POSTAL": 2722,
    "PROVINCIA": "S",
    "AFIP": 14964
  },
  {
    "DESCRIPCION": "WILDE",
    "CODIGO POSTAL": 1875,
    "PROVINCIA": "B",
    "AFIP": 14966
  },
  {
    "DESCRIPCION": "WINIFREDA",
    "CODIGO POSTAL": 6313,
    "PROVINCIA": "L",
    "AFIP": 14968
  },
  {
    "DESCRIPCION": "XXXXX",
    "CODIGO POSTAL": 3709,
    "PROVINCIA": "H",
    "AFIP": ""
  },
  {
    "DESCRIPCION": "YERBA BUENA",
    "CODIGO POSTAL": 4107,
    "PROVINCIA": "T",
    "AFIP": 19361
  },
  {
    "DESCRIPCION": "YUTU YACO",
    "CODIGO POSTAL": 4221,
    "PROVINCIA": "G",
    "AFIP": 15075
  },
  {
    "DESCRIPCION": "ZARATE",
    "CODIGO POSTAL": 2800,
    "PROVINCIA": "B",
    "AFIP": 15100
  },
  {
    "DESCRIPCION": "ZAVALIA",
    "CODIGO POSTAL": 6018,
    "PROVINCIA": "B",
    "AFIP": 15103
  },
  {
    "DESCRIPCION": "ZAVALLA",
    "CODIGO POSTAL": 2172,
    "PROVINCIA": "S",
    "AFIP": 19370
  },
  {
    "DESCRIPCION": "ZENON PEREYRA",
    "CODIGO POSTAL": 2409,
    "PROVINCIA": "S",
    "AFIP": 15107
  }
]