import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageSvgComponent } from './message.component';



@NgModule({
  declarations: [
    MessageSvgComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    MessageSvgComponent
  ]
})
export class MessageSvgModule { }
