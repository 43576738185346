import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowBoardSvgComponent } from './arrow-board.component';

@NgModule({
  declarations: [
    ArrowBoardSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    ArrowBoardSvgComponent
  ]
})
export class ArrowBoardSvgModule { }
