import { Component, OnChanges, OnInit } from '@angular/core';
import { FREIGHT_PAID_VOID } from 'src/app/shared/const/business-particularities';
import { FreightPaid } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-flete-cargo-comprador',
  templateUrl: './flete-cargo-comprador.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class FleteCargoCompradorComponent extends BusinessParticularityOption implements OnChanges {
  freightPaidByTheBuyer:FreightPaid=FREIGHT_PAID_VOID;
  isCustomerFreight: boolean = false;

  constructor(private validator:ValidatorChecker) {
    super();
  }

  ngOnChanges(): void {
    try{
      this.freightPaidByTheBuyer=this.businessParticularitiesStruct.freightPaidByTheBuyer;
      this.isCustomerFreight=this.businessParticularitiesStruct.freightPaidByTheBuyer.isCustomerFreight == 1;
    } catch(err){
      this.freightPaidByTheBuyer=FREIGHT_PAID_VOID;
      this.isCustomerFreight=false;
    }
  }

  setFreightPaidByTheBuyer(freightPaidByTheBuyer:FreightPaid){
    this.freightPaidByTheBuyer=freightPaidByTheBuyer;
    this.freightPaidByTheBuyer.isFreightPaid= this.boolToNumber(freightPaidByTheBuyer.amount.quantity>0 && freightPaidByTheBuyer.numberOfDays>0);
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.freightPaidByTheBuyer=freightPaidByTheBuyer;
    this.emit2(newBP);
  }

  getFreightPaidByTheBuyer() {
    let freightPaidByTheBuyer = JSON.parse(JSON.stringify(this.businessParticularitiesStruct.freightPaidByTheBuyer));
    return freightPaidByTheBuyer;
  }
  
  setCustomerFreight(){
    let newBool:boolean=JSON.parse(JSON.stringify(!this.isCustomerFreight));
    this.isCustomerFreight = newBool;
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.isCustomerFreight=this.boolToNumber(newBool);
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }
  
  /* AMOUNT */
  setAmount(event:any){
    let freightPaidByTheBuyer = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.amount=JSON.parse(event);
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }

  /* NUM OF DAYS */
  setNumOfDays(event:any){
    let valueToSet= this.validator.validatorNumber(event);
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.numberOfDays=valueToSet;
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }

  setIsFleteACargoDelComprador(){
    let freightPaidByTheBuyer = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.isFreightPaid= this.boolToNumber(freightPaidByTheBuyer.isFreightPaid!=1);
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }
}
