import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { updateViewDB } from 'src/app/redux/actions/views.action';
import { MAP_LOCAL_STORAGE_VIEW_NAME, VIEW_ORDER_VOID, VIEW_PRICE_VOID } from 'src/app/shared/const/views.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderString } from 'src/app/shared/interfaces/order.interface';
import { PriceString } from 'src/app/shared/interfaces/price.interface';
import { ViewOrder, ViewOrderExchange, ViewPrice } from 'src/app/shared/interfaces/views.interface';

@Component({
  template: '',
})
export class View implements OnInit,OnDestroy {
  //If identificator is 1, we add a new view
  //If identificator is 2, we edit an existing view
  @Input() identificator: number = 0;

  //If is a view for edit.
  //This value is a struct of view.
  @Input() view!: ViewPrice | ViewOrder | ViewOrderExchange;

  //If used to edit a view,
  //this variable represents the view for edit for your index.
  @Input() indexToEdit: number = -1;
  indexToEditInStore: number = -1;

  //It is used to determine if the view is historical or not.
  @Input() isHistorical:boolean=false;

  //Is an array of views for delete or edit an element
  @Input() arrayOfViews: ViewPrice[] | ViewOrder[] | ViewOrderExchange[] = [];
  
  //Button click
  public isButtonClicked: boolean = false;

  //Detect clicks
  protected wasInside = false;
  protected count: number = 0; //CLICKS
  protected isApplyButton:boolean=false;

  public isDeleteView: boolean = false;

  public isEditingName:boolean=false;

  private email:string='';
  private email$!:Subscription;

  constructor(public store:Store<appState>) {}

  ngOnInit(): void {
    this.email$=this.store.select('usuario').subscribe(user => {
      this.email=user.email;
    })
  }

  ngOnDestroy(): void {
    this.email$?.unsubscribe();
  }

  //----------- HOST LISTENER CLICKS -------------\\
  @HostListener('click') clickInside() {
    if(!this.isApplyButton){
      this.setIndexViewSelected(this.indexToEdit);
    }
    this.wasInside = true;
  }
  
  @HostListener('document:click') clickout() {
    if(this.indexToEditInStore==this.indexToEdit) this.count++;

    if (!this.wasInside) {
      if(this.indexToEditInStore == this.indexToEdit && this.count>1){
        this.setIndexViewSelected(-2);
      }
      this.count=0;
    }
    this.wasInside = false;
  }

  protected selectAll() {
    this.view = {
      ...this.view,
      ...this.getViewAll()
    }
  }
  protected deleteAll() {
    this.view = {
      ...this.view,
      ...this.getViewVoid()
    }
  }

  protected createView(){}
  protected deleteView(){}
  protected editView(){}

  protected getViewAll(): ViewPrice | ViewOrder | ViewOrderExchange | null {
    return null
  }

  protected getViewVoid(): ViewPrice | ViewOrder | ViewOrderExchange | null {
    return null
  }

  openModal(isOpenModal: boolean) {
    this.isDeleteView = isOpenModal;
  }

  resetView(tag:string) {
    if(tag=='order'){
      this.view = VIEW_ORDER_VOID;
    } else if(tag=='price'){
      this.view = VIEW_PRICE_VOID;
    }
  }
  
  setName(event: any) {
    this.view = {
      ...this.view,
      viewName: event.target.value,
    };
  }

  setIsEditingName(bool: boolean) {
    this.isEditingName = bool;
  }
  
  setProperty(event: any, tag: string) {
    let viewToSet = JSON.parse(JSON.stringify(this.view));
    viewToSet[tag] = event;
    this.view = viewToSet;
  }

  protected setInStore(views: (ViewPrice|ViewOrder|ViewOrderExchange)[], 
    index:number, 
    view:ViewOrderExchange|ViewOrder|ViewPrice|null=null, 
    filter:OrderString|PriceString|null=null){}

  protected setIndexViewSelected(index:number){}

  saveInDB(views:ViewPrice[] | ViewOrder[] | ViewOrderExchange[]){
    updViewsDB(this.store, this.email, views, this.getLocalStorageName());
  }

  getLocalStorageName():string{
    return '';
  }

  notClickedNothing(): boolean {
    return false;
  }

  isInvalidCreateView():boolean{
    return this.view.viewName == '' || this.notClickedNothing()
  }
}

/**
 * Given a store, email, some views and a name in localStorage.
 * Issue an action in the store to save the views in the database.
 * @params store: Store<appState>
 * @params email: string
 * @params views: ViewPrice[] | ViewOrder[] | ViewOrderExchange[]
 * @params name: string
 * @returns void
 */
export function updViewsDB(store:Store<appState>, email:string, views:ViewPrice[] | ViewOrder[] | ViewOrderExchange[], name:string){
  store.dispatch(updateViewDB({
      email:email, 
      name:MAP_LOCAL_STORAGE_VIEW_NAME[name], 
      views:views }))
}
