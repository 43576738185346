<div class="body">

    <div class="header">
        <span class="add-buyer title">Particularidad de negocio</span>
        <cross-svg class="cross" (click)="close()"></cross-svg>
    </div>

    <p class="description">Seleccioná las particularidades necesarias para tu orden de venta.</p>

    <div class="options-selected">
        <div class="option-selected" *ngFor="let option of options">
            <span class="text">{{option}}</span>
            <cross-svg (click)="removeOption(option)" class="pointer" [size]="12" [style]="{height: '22px'}"></cross-svg>
        </div>
    </div>

    <div class="line grey"></div>
    
    <div [ngClass]="{'selected':selectedIndex}"></div>

    <div class="options-particularities" [ngClass]="{'selected-one': selectedIndex}">
        <app-wash-out class="option-p wash-out" [ngClass]="{'no-selected': 1!=selectedIndex}" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-wash-out>
        
        <app-gravanz class="option-p" [ngClass]="{'no-selected': 2!=selectedIndex}" (save)="saveElement($event)"
        [businessParticularitiesStruct]="getBusinessParticularities()" [fieldIdentificator]="fieldIdentificator" (selected)="selected($event)"></app-gravanz>
        
        <app-acumulador *ngIf="isGenerateToFix" class="option-p" [ngClass]="{'no-selected': 3!=selectedIndex}" (save)="saveElement($event)"
        [businessParticularitiesStruct]="getBusinessParticularities()" (selected)="selected($event)"></app-acumulador>
       
        <app-paf-garantizado *ngIf="isGenerateToFix" class="option-p" [ngClass]="{'no-selected': 17!=selectedIndex}" (save)="saveElement($event)"
        [businessParticularitiesStruct]="getBusinessParticularities()" (selected)="selected($event)"></app-paf-garantizado>
        
        <app-anticipo-financiero *ngIf="isGenerateToFix" class="option-p" [ngClass]="{'no-selected': 18!=selectedIndex}" (save)="saveElement($event)"
        [businessParticularitiesStruct]="getBusinessParticularities()" (selected)="selected($event)"></app-anticipo-financiero>

        <app-condicional class="option-p" [ngClass]="{'no-selected': 4!=selectedIndex}" [strikeDefault]="getPriceOfPrice()"
        (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-condicional>
        
        <app-convenio-nutrien class="option-p" [ngClass]="{'no-selected': 19!=selectedIndex}" 
        [businessParticularitiesStruct]="getBusinessParticularities()" (saveNutrien)="saveNutrien($event)"></app-convenio-nutrien>

        <app-clausula-cobranza class="option-p" [ngClass]="{'no-selected': 5!=selectedIndex}" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-clausula-cobranza>
        
        <app-respeta-iva class="option-p" [ngClass]="{'no-selected': 6!=selectedIndex}" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-respeta-iva>
        
        <app-flete-cargo-comprador class="option-p" [ngClass]="{'no-selected': 7!=selectedIndex}" (selected)="selected($event)" 
        [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-flete-cargo-comprador>
        
        <app-convenio class="option-p" [ngClass]="{'no-selected': 8!=selectedIndex}" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-convenio>
        
        <app-entrega-plantas-grassi class="option-p" [ngClass]="{'no-selected': 9!=selectedIndex}" (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-entrega-plantas-grassi>
        
        <app-entrega-silo-bolsas class="option-p" [ngClass]="{'no-selected': 10!=selectedIndex}" (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-entrega-silo-bolsas>
        
        <app-bonificacion class="option-p" [ngClass]="{'no-selected': 11!=selectedIndex}" (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-bonificacion>
        
        <app-rebaja class="option-p" [ngClass]="{'no-selected': 12!=selectedIndex}" (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-rebaja>
        
        <app-convenio-laboratorio class="option-p" [ngClass]="{'no-selected': 13!=selectedIndex}" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-convenio-laboratorio>
        
        <app-sellado-a-recuperar class="option-p" [ngClass]="{'no-selected': 14!=selectedIndex}" (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-sellado-a-recuperar>
        
        <app-a-retirar class="option-p" [ngClass]="{'no-selected': 15!=selectedIndex}" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-a-retirar>
        
        <app-pactado-con class="option-p" [ngClass]="{'no-selected': 16!=selectedIndex}" (selected)="selected($event)" [businessParticularitiesStruct]="getBusinessParticularities()" (save)="saveElement($event)"></app-pactado-con>
    </div>
    
    <div class="bottom-fixed">
        <button class="filled-button" (click)="close()"><span>Aplicar</span></button>
    </div>
</div>