import { Component, OnDestroy,OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setTypeOfHistorical } from 'src/app/redux/actions/historical.action';
import { setChangesVisibles, setIsGenerateToFix, setShowAddBuyer } from 'src/app/redux/actions/options.action';
import { setBuyerTypeFilter, setEditAll, setHistorical, setIndexToEditValidation, setValidationOrdersToGenerate, showOrderDetails } from 'src/app/redux/actions/order.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { conditionType, typeOfHistorical } from 'src/app/shared/const/options';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { DataChecker } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, getSumOfTons } from 'src/app/shared/order';
import { BodyBoard } from 'src/app/components/board/body-board';

@Component({
  selector: 'body-order-list',
  templateUrl: './body-order-list.component.html',
  styleUrls: ['./body-order-list.component.scss','./../../../shared/styles/body-board.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class OrderBodyComponent extends BodyBoard implements OnInit, OnDestroy{
  orderWithPipe!:Order[];
  ordersSelected!:Order[];
  quantEraserOrders:number=0;
  ORDER_STATUS_CONTS = orderStatusConst;

  // ---- Options ---- \\
  isShowDetails:boolean=false;
  orderDetails!:Order;
  isShowAddBuyer:boolean=false;
  typeModal:number=0;

  //Loading
  showLoading:boolean=false;

  private allPipe$!:Subscription;
  private showAddBuyer$!:Subscription;
  private typeModal$!:Subscription;
  private showLoad$!:Subscription;

  constructor(store: Store<appState>, private router:Router, private dataChecker: DataChecker, 
    private orderV:OrderValidator, cdr:ChangeDetectorRef) {
    super(store, cdr);
    this.rememberEraserOrdersOfLocalStorage();
    this.rememberOrdersOfLocalStorage();
  }

  ngOnInit(): void {
    this.setSubscriptions(); 
  }

  ngOnDestroy(){
    this.allPipe$?.unsubscribe();
    this.showAddBuyer$?.unsubscribe();
    this.typeModal$?.unsubscribe();
    this.showLoad$?.unsubscribe();
    this.setBodyUnsubscription();
    this.store.dispatch(setShowAddBuyer({isShowAddBuyer:false}));
  }

  setSubscriptions(){
    this.allPipe$=this.store.select('order', 'orderBoard', 'allPipe').subscribe((orderWithPipe) => {
      this.orderWithPipe = orderWithPipe;
      this.cdr.detectChanges();
    });

    this.historical$= this.store.select('order', 'historical').subscribe(historical=>{
      this.historical=historical;
    });

    this.selected$=this.store.select('order', 'orderBoard', 'ordersSelected').subscribe((orders) => {
      this.ordersSelected = orders;
    });

    this.buyerTypeSelected$=this.store.select('order', 'orderBoard', 'buyerType').subscribe((buyerType) => {
      this.buyerTypeSelected = buyerType;
    });

    this.showDetails$=this.store.select('order', 'orderBoard', 'showOrderDetails').subscribe((show) => {
      this.isShowDetails = show;
    });

    this.details$=this.store.select('order', 'orderBoard', 'orderDetails').subscribe((order) => {
      this.orderDetails = order;
    });

    this.showLoad$ = this.store.select('order', 'loaderSendToIBM').subscribe((loaderSendToIBM) => {
      this.showLoading = loaderSendToIBM>0;
    });

    this.showAddBuyer$=this.store.select('options','isShowAddBuyer').subscribe(isShowAddBuyer=>{
      this.isShowAddBuyer=isShowAddBuyer;
    });

    this.typeModal$=this.store.select('options','modalContract','typeModal').subscribe(typeModal=>{
      this.typeModal=typeModal;
    });

    this.setBodySubscription();
  }

  getQuantOfTons(orders:Order[]){
    return getSumOfTons(orders);
  }

  rememberEraserOrdersOfLocalStorage() {
    if(this.isComercial){
      this.quantEraserOrders=this.orderV.getEraserOrders().length
    }
  }

  rememberOrdersOfLocalStorage(){
    this.store.dispatch(this.dataChecker.rememberOrderList());
  }

  isOrdersSelectedStatus(ordersSelected:Order[], status:number){
    return (ordersSelected?.length ?? 0)<=0 || !this.orderV.isOrdersSelectedStatus(ordersSelected, status);
  }

  goToEraser(){
    this.router.navigate(["/"+NAVEGATION.ERASER]);
  }

  setBuyerType(identificator:string){
    this.store.dispatch(setBuyerTypeFilter({buyerType:identificator}));
  }

  closeCards(){
    this.store.dispatch(showOrderDetails({show: false}));
    this.store.dispatch(setHistorical({historical: ''}));
    this.store.dispatch(setShowAddBuyer({isShowAddBuyer:false}));
  }

  sendToTickets(){ 
    this.store.dispatch(setShowAddBuyer({isShowAddBuyer:true}));

    let validationOrders=JSON.parse(JSON.stringify(this.ordersSelected));

    this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
    this.store.dispatch(setEditAll({editAll: true}));
    this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
  }

  validateOrders(){
    let validationOrders=JSON.parse(JSON.stringify(this.ordersSelected));
    
    this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
    this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
    this.router.navigate(['/'+NAVEGATION.ORDER_VALIDATION]);
  }

  setEditOrders(newStatus: any) {
    if (newStatus == orderStatusConst.RETURNED) {
      this.orderV.openModal(orderStatusConst.RETURNED);
    } else if (newStatus == orderStatusConst.CONFIRM) {
      this.orderV.openModal(orderStatusConst.CONFIRM);
    } else {
      //Recorremos el array de ordenes seleccionadas.
      for (let i = 0; i < this.ordersSelected.length; i++) {
        //let indexToEdit= this.orderV.indexOfOrder(this.orderBoard.all, this.ordersSelected[i]);
        let orderToSave = this.orderV.saveOrderStatus(this.ordersSelected[i], newStatus, ' ', this.user);
        this.store.dispatch(setChangesVisibles({isChangeVisible:true}));
      }
      this.orderV.resetSelectedOrderList();
    }
  }

  goToCreateOrder(){
    //Para generar la orden, primero verificamos si está situado en la pestaña de A Precio o A Fijar.
    this.store.dispatch(setIsGenerateToFix({isGenerateToFix: this.conditionTypeSelected==conditionType.TO_FIX}));
    this.router.navigate(['/'+NAVEGATION.GENERATE_MANUAL_ORDER]);
  }

  goToHistorical(){
    this.store.dispatch(setTypeOfHistorical({typeOfHistorical: typeOfHistorical.ORDER}))
    const url= "/"+NAVEGATION.HISTORICAL;
    this.router.navigate([url]);
  }
}

//Toma un json de la tabla de zonas (POT013)
//Crea un nuevo json donde asocia todos los numeros de usuario a los numeros internos de clientes
/*function getClientesByUserCode(){
    let valueToReturn: any={};
    const zonas= zona;
    const quantZonas= zonas.length;

    for(let i=0; i<quantZonas; i++){
      if(! valueToReturn[zonas[i].SUBTIPO.toString()]){
        const nroCliente=transformNroInternoToNroCliente(zonas[i].NRO_INTERNO);
        if(nroCliente){
          valueToReturn[zonas[i].SUBTIPO.toString()]=[nroCliente];
        }
      } else {
        const nroCliente=transformNroInternoToNroCliente(zonas[i].NRO_INTERNO);
        if(nroCliente){
          valueToReturn[zonas[i].SUBTIPO.toString()].push(nroCliente);
        }
      }

    }
  }

function saveClients(orderSvc:OrderService){
    const sellers:any=sellerWithData;
    const arraySellers=Object.keys(sellers);
    //Eliminamos los vacíos
    arraySellers.shift();
    arraySellers.pop();
    
    const quantSellers=arraySellers.length;
    const initial=9000
    let i=initial;
    const corte= Math.min(initial + 1000, quantSellers);
    let interval=setInterval( () =>{
      
      const newSeller= {
        ...sellers[arraySellers[i]],
        _id: Number(arraySellers[i])
      }

      orderSvc.setSeller(newSeller).subscribe(res=>{});

      i++;

      if(i>=corte){
        clearInterval(interval);
      }
    }, 20)
  }

//Transforma el nro interno por el numero de cliente real
function transformNroInternoToNroCliente(nroInterno:number){
    try{
      const POT010_TABLE:any=POT010;
      return POT010_TABLE[nroInterno.toString()]["NRO CLIENTE"];
    } catch (error){
      return 0;
    }
  }

function saveAfipCode(){
    const code_postals= CODIGO_POSTAL;
    const quantCodePostals= code_postals.length;
    const new_code_postals:any[]=[];

    for(let i=0; i<quantCodePostals; i++){
      const new_code_postal={
        ...code_postals[i],
        AFIP: getAfipCode(code_postals[i])
      }
      new_code_postals.push(new_code_postal);
    }
  }

function getAfipCode(code_postal:any){
  const localityStr=code_postal.DESCRIPCION.toLowerCase(); //Locality of table code postal
  const arrayProvinces=getLocality(getProvinceKeyByValue(code_postal.PROVINCIA)); //Array of localities of province
  const quantProvinces=arrayProvinces.length; //Quantity of localities of province

  let afipCodeToReturn=0; //Afip code to return
  try{
    for(let i=0; i<quantProvinces; i++){
      const localityArray= arrayProvinces[i].location.toLowerCase();
      if(localityArray==localityStr || localityArray.includes('ñ')==localityStr){
        afipCodeToReturn=Number(arrayProvinces[i].afip);
        throw new Error();
      }
    }
  } catch(err){}

  return afipCodeToReturn;
}

function getProvinceKeyByValue(value: string): number {
  const provinces = Object.entries(provincesWithLetterIBM);
  let keyReturn=0;
  try{
    for (const [key, province] of provinces) {
      if (province === value) {
        keyReturn=Number(key);
        throw new Error();
      }
    }
  } catch(err){}
  return keyReturn;
}*/