import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellHeadComponent } from './cell-head.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { FilterOrderModule } from '../../filters/filter-order/filters.module';
import { FilterPriceModule } from '../../filters/filter-price/filters.module';



@NgModule({
  declarations: [
    CellHeadComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    FilterBoardSvgModule,
    FilterOrderModule,
    FilterPriceModule
  ], exports:[
    CellHeadComponent
  ]
})
export class CellHeadModule { }
