export const rioNegroLocalities=[
    {"afip":"25","location":"6 De Octubre"},
    {"afip":"174","location":"Aguada Cecilio"},
    {"afip":"176","location":"Aguada De Guerra"},
    {"afip":"180","location":"Aguada De Los Pajaritos"},
    {"afip":"181","location":"Aguada De Piedra"},
    {"afip":"183","location":"Aguada Del Loro"},
    {"afip":"189","location":"Aguada Guzman"},
    {"afip":"240","location":"Alanitos"},
    {"afip":"350","location":"Allen"},
    {"afip":"562","location":"Anecon Chico"},
    {"afip":"563","location":"Anecon Grande"},
    {"afip":"748","location":"Arroyo Chacay"},
    {"afip":"793","location":"Arroyo La Ventana"},
    {"afip":"798","location":"Arroyo Las Minas"},
    {"afip":"803","location":"Arroyo Los Berros"},
    {"afip":"867","location":"Arroyo Tembrado"},
    {"afip":"921","location":"Atraico"},
    {"afip":"1051","location":"Bajo Rico"},
    {"afip":"1130","location":"Balneario El Condor"},
    {"afip":"1135","location":"Balneario Las Grutas"},
    {"afip":"1176","location":"Barda Colorada"},
    {"afip":"1177","location":"Barda Del Medio"},
    {"afip":"1181","location":"Bariloche"},
    {"afip":"1215","location":"Barril Niyeo"},
    {"afip":"1276","location":"Barrio Laguna"},
    {"afip":"1285","location":"Barrio Norte"},
    {"afip":"1450","location":"Benjamin Zorrilla"},
    {"afip":"1505","location":"Boca De La Travesia"},
    {"afip":"1593","location":"Buena Parada"},
    {"afip":"1774","location":"Caltrauna"},
    {"afip":"1824","location":"Campana Mahuida"},
    {"afip":"2066","location":"Canllequin"},
    {"afip":"2222","location":"Carri Yegua"},
    {"afip":"2225","location":"Carrilauquen"},
    {"afip":"2265","location":"Casa Quemada"},
    {"afip":"2286","location":"Cascada Los Cantaros"},
    {"afip":"2335","location":"Catriel"},
    {"afip":"2448","location":"Cañadon Camallo"}
    ,
    {"afip":"2457","location":"Cañadon Del Corral"}
    ,
    {"afip":"2528","location":"Cerro Abanico"},
    {"afip":"2538","location":"Cerro Alto"},
    {"afip":"2601","location":"Cerro De Policia"},
    {"afip":"2683","location":"Cerro Mesa"},
    {"afip":"2756","location":"Cervantes"},
    {"afip":"2768","location":"Chacalhua Ruca"},
    {"afip":"2774","location":"Chacay Huarruca"},
    {"afip":"2798","location":"Chacras De Allen"},
    {"afip":"2815","location":"Chaiful"},
    {"afip":"2915","location":"Chelforo"},
    {"afip":"2918","location":"Chenqueniyeu"},
    {"afip":"2927","location":"Chichihuao"},
    {"afip":"2928","location":"Chichinales"},
    {"afip":"2963","location":"Chimpay"},
    {"afip":"2966","location":"Chipauquil"},
    {"afip":"2986","location":"Chocori"},
    {"afip":"2987","location":"Choele Choel"},
    {"afip":"3025","location":"Churquiñeo"}
    ,
    {"afip":"3048","location":"Cinco Chañares"}
    ,
    {"afip":"3049","location":"Cinco Saltos"},
    {"afip":"3051","location":"Cipolletti"},
    {"afip":"3075","location":"Clemente Onelli"},
    {"afip":"3132","location":"Coli Toro"},
    {"afip":"3195","location":"Colonia Alte Guerrico"},
    {"afip":"3385","location":"Colonia El Manzano"},
    {"afip":"3458","location":"Colonia General Frias"},
    {"afip":"3515","location":"Colonia Josefa"},
    {"afip":"3527","location":"Colonia Julia Y Echarren"},
    {"afip":"3570","location":"Colonia La Luisa"},
    {"afip":"3808","location":"Colonia Regina"},
    {"afip":"3982","location":"Comallo"},
    {"afip":"3983","location":"Comallo Abajo"},
    {"afip":"3995","location":"Comi C"},
    {"afip":"4004","location":"Cona Niyeu"},
    {"afip":"4035","location":"Contralmirante Cordero"},
    {"afip":"4036","location":"Contralmirante M Guerrico"},
    {"afip":"4050","location":"Coquelen"},
    {"afip":"4078","location":"Coronel Belisle"},
    {"afip":"4090","location":"Coronel Eugenio Del Busto"},
    {"afip":"4095","location":"Coronel Francisco Sosa"},
    {"afip":"4101","location":"Coronel Juan Jose Gomez"},
    {"afip":"4134","location":"Corral De Los Pinos"},
    {"afip":"4166","location":"Cos Zaures"},
    {"afip":"4192","location":"Costa Del Rio Azul"},
    {"afip":"4218","location":"Costas Del Pichi Leufu"},
    {"afip":"4318","location":"Cuenca Vidal"},
    {"afip":"4326","location":"Cuesta Del Ternero"},
    {"afip":"4346","location":"Cura Lauquen"},
    {"afip":"4385","location":"Darwin"},
    {"afip":"4444","location":"Dina Huapi"},
    {"afip":"4734","location":"El Cacique"},
    {"afip":"4737","location":"El Cain"},
    {"afip":"4744","location":"El Camaruro"},
    {"afip":"4830","location":"El Cheiful"},
    {"afip":"4875","location":"El Condor Estancia"},
    {"afip":"4911","location":"El Cuy"},
    {"afip":"4930","location":"El Dique"},
    {"afip":"4967","location":"El Foyel"},
    {"afip":"4985","location":"El Gaucho Pobre"},
    {"afip":"5027","location":"El Jardinero"},
    {"afip":"5066","location":"El Manso"},
    {"afip":"5096","location":"El Moligue"},
    {"afip":"5176","location":"El Pantanoso"},
    {"afip":"5891","location":"Estancia Las Julias"},
    {"afip":"6052","location":"Falckner"},
    {"afip":"6098","location":"Ferri"},
    {"afip":"6154","location":"Fitalancao"},
    {"afip":"6155","location":"Fitamiche"},
    {"afip":"6156","location":"Fitatimen"},
    {"afip":"6228","location":"Fortin Uno"},
    {"afip":"6289","location":"Futa Ruin"},
    {"afip":"6313","location":"Ganzu Lauquen"},
    {"afip":"6368","location":"General Enrique Godoy"},
    {"afip":"6370","location":"General Fernandez Oro"},
    {"afip":"6385","location":"General Liborio Bernal"},
    {"afip":"6599","location":"Guardia Mitre"},
    {"afip":"6766","location":"Hua Miche"},
    {"afip":"6787","location":"Huan Luan"},
    {"afip":"6934","location":"Ingeniero Huergo"},
    {"afip":"6935","location":"Ingeniero Jacobacci"},
    {"afip":"6947","location":"Ingeniero Zimmermann Resta"},
    {"afip":"6967","location":"Ingenio San Lorenzo"},
    {"afip":"7000","location":"Iris"},
    {"afip":"7119","location":"Jaguel Campo Monte"},
    {"afip":"7123","location":"Janinue"},
    {"afip":"7149","location":"Jita Rusia"},
    {"afip":"7194","location":"Juan De Garay"},
    {"afip":"7273","location":"Kili Malal"},
    {"afip":"7306","location":"La Alianza"},
    {"afip":"7427","location":"La Carolina"},
    {"afip":"7475","location":"La Chilena"},
    {"afip":"7537","location":"La Criollita"},
    {"afip":"7620","location":"La Estancia Vieja"},
    {"afip":"7634","location":"La Excurra"},
    {"afip":"7751","location":"La Julia"},
    {"afip":"7783","location":"La Loberia"},
    {"afip":"7824","location":"La Maria Ines"},
    {"afip":"7850","location":"La Meseta"},
    {"afip":"7854","location":"La Mimosa"},
    {"afip":"8170","location":"La Vencedora"},
    {"afip":"8235","location":"Lago Pellegrini"},
    {"afip":"8260","location":"Laguna Cortes"},
    {"afip":"8264","location":"Laguna De La Prueba"},
    {"afip":"8271","location":"Laguna Del Barro"},
    {"afip":"8281","location":"Laguna Frias"},
    {"afip":"8289","location":"Laguna Los Juncos"},
    {"afip":"8326","location":"Lamarque"},
    {"afip":"8333","location":"Lanquiñeo"}
    ,
    {"afip":"8550","location":"Las Maquinas"},
    {"afip":"8557","location":"Las Mellizas"},
    {"afip":"8612","location":"Las Piedras"},
    {"afip":"8757","location":"Lenzaniyen"},
    {"afip":"8922","location":"Lonco Vaca"},
    {"afip":"9089","location":"Los Costeros"},
    {"afip":"9166","location":"Los Juncos"},
    {"afip":"9304","location":"Los Repollos"},
    {"afip":"9427","location":"Luis Beltran"},
    {"afip":"9432","location":"Luis M Zagaglia"},
    {"afip":"9503","location":"Mainque"},
    {"afip":"9539","location":"Mallin Ahogado"},
    {"afip":"9560","location":"Mamuel Choique"},
    {"afip":"9573","location":"Mancha Blanca"},
    {"afip":"9579","location":"Mancullique"},
    {"afip":"9612","location":"Maquinchao"},
    {"afip":"9722","location":"Mata Negra"},
    {"afip":"9814","location":"Mencue"},
    {"afip":"9817","location":"Menuco Vaca Muerta"},
    {"afip":"9848","location":"Michi Honoca"},
    {"afip":"9849","location":"Michihuao"},
    {"afip":"9929","location":"Ministro Ramos Mexia"},
    {"afip":"10135","location":"Mulanillo"},
    {"afip":"10144","location":"Musters"},
    {"afip":"10166","location":"Nahuel Niyeu"},
    {"afip":"10194","location":"Naupa Huen"},
    {"afip":"10210","location":"Neneo Ruca"},
    {"afip":"10232","location":"Niluan"},
    {"afip":"10276","location":"Nueva Carolina"},
    {"afip":"10308","location":"Nuevo Leon"},
    {"afip":"10350","location":"Ojo De Agua Embarcadero Fcgb"},
    {"afip":"10450","location":"Padre Alejandro Stefanelli"},
    {"afip":"10473","location":"Pajalta"},
    {"afip":"10488","location":"Palenque Niyeu"},
    {"afip":"10658","location":"Panquehuao"},
    {"afip":"10785","location":"Paso Chacabuco"},
    {"afip":"10790","location":"Paso Cordova"},
    {"afip":"10820","location":"Paso De Los Molles"},
    {"afip":"10842","location":"Paso Del Limay"},
    {"afip":"10857","location":"Paso Flores"},
    {"afip":"10871","location":"Paso Lezcano"},
    {"afip":"10882","location":"Paso Miranda"},
    {"afip":"10888","location":"Paso Piedra"},
    {"afip":"10993","location":"Percy H Scott"},
    {"afip":"11009","location":"Perito Moreno"},
    {"afip":"11010","location":"Perito Moreno Estacion Fcgr"},
    {"afip":"11065","location":"Pichi Leufu"},
    {"afip":"11066","location":"Pichi Leufu Abajo"},
    {"afip":"11067","location":"Pichi Leufu Arriba"},
    {"afip":"11068","location":"Pichi Mahuida"},
    {"afip":"11126","location":"Pilahue"},
    {"afip":"11129","location":"Pilcaniyeu"},
    {"afip":"11130","location":"Pilcaniyeu Viejo"},
    {"afip":"11140","location":"Pilqui Niyeu"},
    {"afip":"11204","location":"Planicie De Jaguelito"},
    {"afip":"11210","location":"Playa Bonita"},
    {"afip":"11284","location":"Pomona"},
    {"afip":"11499","location":"Pozo Moro"},
    {"afip":"11506","location":"Pozo Salado"},
    {"afip":"11521","location":"Prahuaniyeu"},
    {"afip":"11538","location":"Primera Angostura"},
    {"afip":"11764","location":"Puerto Blest"},
    {"afip":"11834","location":"Puerto Ojo De Agua"},
    {"afip":"11860","location":"Puerto San Antonio Este"},
    {"afip":"11872","location":"Puerto Tigre Isla Victoria"},
    {"afip":"11990","location":"Puesto Faria"},
    {"afip":"11992","location":"Puesto Gaviña"}
    ,
    {"afip":"12212","location":"Quempu Niyeu"},
    {"afip":"12222","location":"Quetrequile"},
    {"afip":"12250","location":"Quinta Panal"},
    {"afip":"12362","location":"Rayhuao"},
    {"afip":"12421","location":"Repollos"},
    {"afip":"12484","location":"Rincon De Gastre"},
    {"afip":"12618","location":"Rio Salado"},
    {"afip":"12632","location":"Rio Villegas"},
    {"afip":"12721","location":"Rucu Luan"},
    {"afip":"12793","location":"Saco Viejo"},
    {"afip":"12851","location":"Salitral Negro"},
    {"afip":"12907","location":"San Antonio Oeste"},
    {"afip":"13213","location":"Santa Genoveva"},
    {"afip":"13216","location":"Santa Gregoria"},
    {"afip":"13245","location":"Santa Nicolasa"},
    {"afip":"13323","location":"Sargento Vidal"},
    {"afip":"13336","location":"Sauce Blanco"},
    {"afip":"13391","location":"Segunda Angostura"},
    {"afip":"13458","location":"Sierra Grande"},
    {"afip":"13462","location":"Sierra Paileman"},
    {"afip":"13811","location":"Teniente Maza Estacion Fcgr"},
    {"afip":"13822","location":"Tercera Zona"},
    {"afip":"14020","location":"Travesia Castro"},
    {"afip":"14028","location":"Treneta"},
    {"afip":"14074","location":"Tres Ojos De Aguas"},
    {"afip":"14092","location":"Tricaco"},
    {"afip":"14107","location":"Tromeniyeu"},
    {"afip":"14127","location":"Tte Gral Eustoquio Frias"},
    {"afip":"14157","location":"Tunquelen"},
    {"afip":"14233","location":"Vaca Lauquen"},
    {"afip":"14241","location":"Valcheta"},
    {"afip":"14251","location":"Valle Azul"},
    {"afip":"14256","location":"Valle De Los Alamos"},
    {"afip":"14357","location":"Viedma"},
    {"afip":"14383","location":"Villa Alberdi"},
    {"afip":"14652","location":"Villa Llanquin"},
    {"afip":"14673","location":"Villa Manzano"},
    {"afip":"14681","location":"Villa Mascardi"},
    {"afip":"14761","location":"Villa Regina"},
    {"afip":"14852","location":"Villa Turismo"},
    {"afip":"15071","location":"Yuquinche"},
    {"afip":"15136","location":"Ñirihuao Estacion Fcgr"}
    ,
    {"afip":"15141","location":"Ñorquinco"}
    ,
    {"afip":"15206","location":"Aguara"},
    {"afip":"15294","location":"Arroyo Blanco"},
    {"afip":"15303","location":"Arroyo Salado"},
    {"afip":"15306","location":"Arroyo Verde"},
    {"afip":"15318","location":"Bajo Del Gualicho"},
    {"afip":"15490","location":"Carhue"},
    {"afip":"15546","location":"Cañadon Chileno"}
    ,
    {"afip":"15617","location":"Chasico"},
    {"afip":"15640","location":"China Muerta"},
    {"afip":"15704","location":"Colonia Rusa"},
    {"afip":"15722","location":"Colonia San Juan"},
    {"afip":"15777","location":"Coronel Vidal"},
    {"afip":"15791","location":"Corralito"},
    {"afip":"15814","location":"Cuatro Esquinas"},
    {"afip":"15914","location":"El Bolson"},
    {"afip":"16101","location":"El Manzano"},
    {"afip":"16219","location":"El Porvenir"},
    {"afip":"16333","location":"El Salado"},
    {"afip":"16540","location":"General Conesa"},
    {"afip":"16544","location":"General Roca"},
    {"afip":"16591","location":"Isla Chica"},
    {"afip":"16594","location":"Isla Grande"},
    {"afip":"16621","location":"La Adela"},
    {"afip":"16646","location":"La Angostura"},
    {"afip":"16699","location":"La Bombilla"},
    {"afip":"16857","location":"La Elvira"},
    {"afip":"16860","location":"La Emilia"},
    {"afip":"16868","location":"La Esmeralda"},
    {"afip":"16879","location":"La Esperanza"},
    {"afip":"16908","location":"La Estrella"},
    {"afip":"16925","location":"La Flecha"},
    {"afip":"16964","location":"La Granja"},
    {"afip":"17061","location":"La Lucinda"},
    {"afip":"17186","location":"La Porteña"}
    ,
    {"afip":"17202","location":"La Primavera"},
    {"afip":"17223","location":"La Quebrada"},
    {"afip":"17260","location":"La Rinconada"},
    {"afip":"17273","location":"La Rubia"},
    {"afip":"17281","location":"La Sara"},
    {"afip":"17385","location":"Laguna Del Monte"},
    {"afip":"17402","location":"Lagunita"},
    {"afip":"17431","location":"Las Bayas"},
    {"afip":"17722","location":"Loma Blanca"},
    {"afip":"17872","location":"Los Manantiales"},
    {"afip":"17877","location":"Los Menucos"},
    {"afip":"17883","location":"Los Molinos"},
    {"afip":"17925","location":"Los Pirineos"},
    {"afip":"17944","location":"Los Quebrachos"},
    {"afip":"17959","location":"Los Sauces"},
    {"afip":"18013","location":"Maria Cristina"},
    {"afip":"18031","location":"Medanos Negros"},
    {"afip":"18112","location":"Negro Muerto"},
    {"afip":"18239","location":"Peñas Blancas"}
    ,
    {"afip":"18279","location":"Portezuelo"},
    {"afip":"18406","location":"Punta De Agua"},
    {"afip":"18490","location":"Rinconada"},
    {"afip":"18499","location":"Rio Chico"},
    {"afip":"18503","location":"Rio Colorado"},
    {"afip":"18723","location":"San Javier"},
    {"afip":"18737","location":"San Jorge"},
    {"afip":"18766","location":"San Juan"},
    {"afip":"18782","location":"San Leon"},
    {"afip":"18791","location":"San Lorenzo"},
    {"afip":"18862","location":"San Pedro"},
    {"afip":"18883","location":"San Ramon"},
    {"afip":"18914","location":"San Simon"},
    {"afip":"18978","location":"Santa Elena"},
    {"afip":"19127","location":"Sierra Colorada"},
    {"afip":"19128","location":"Sierra De La Ventana"},
    {"afip":"20745","location":"Sauce Blanco"},
    {"afip":"21864","location":"Capilla De Remedios"},
    {"afip":"22095","location":"Cubanea"},
    {"afip":"22098","location":"Sanjon De Oyuela"},
    {"afip":"22262","location":"Dina Huapi (Pilcaniyeu)"}
    ]