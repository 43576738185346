<div class="card">
    <div class="header">
        <h3 class="text title not-visible-in-950">Detalle de la nueva condición</h3>
        <!--<arrow-svg class="arrow" [direction]="getArrowDirection(isShowInfo)" *ngIf="isGenerateToFix" (click)="changeShowInfo()"></arrow-svg>-->
    </div>

    <div class="body">
        <div class="header-info visible-in-mobile">
            <div class="column-between gap-8">
                <!--<span>Orden {{ORDER_TYPE_CATEGORY[0]}}</span>-->
                <span class="order-type">Nueva condición de negocio</span> 
                <span><img alt="product-icon" src="../../../../../assets/images/products/{{PRODUCTS[price.productName]}}.svg"/> {{PRODUCTS[price.productName]}}</span>
            </div>
        
            <div class="paso visible-in-mobile price-container" *ngIf="!isGenerateToFix">
                <span class="price {{getTypeCoin(price).toLowerCase()}}">{{getPriceOfPrice(getPrice())}} {{getTypeCoin(price)}}</span>
            </div>
        </div>

        <app-info-price [price]="getPrice()" *ngIf="isShowInfo" [buyers]="buyersToGenerate"></app-info-price>
    </div>
    
    <div class="header" *ngIf="isGenerateToFix">
        <h3 class="text title">Condición a fijar</h3>
        <!--<arrow-svg class="arrow" [direction]="getArrowDirection(isShowToFix)" *ngIf="isGenerateToFix" (click)="changeShowToFix()"></arrow-svg>-->
    </div>
    <div class="body" *ngIf="isGenerateToFix">
        <app-info-to-fix [conditionType]="getConditionType()" [indicators]="getIndicators()"></app-info-to-fix>
    </div>
</div>