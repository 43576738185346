<svg class="{{size}} {{color}}" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_3720_13739)">
    <path d="M8.92943 5.07227C8.74834 5.07227 8.60156 5.21904 8.60156 5.40013V11.5969C8.60156 11.7778 8.74834 11.9247 8.92943 11.9247C9.11053 11.9247 9.2573 11.7778 9.2573 11.5969V5.40013C9.2573 5.21904 9.11053 5.07227 8.92943 5.07227Z" fill="black"/>
    <path d="M5.06224 5.07227C4.88115 5.07227 4.73438 5.21904 4.73438 5.40013V11.5969C4.73438 11.7778 4.88115 11.9247 5.06224 11.9247C5.24334 11.9247 5.39011 11.7778 5.39011 11.5969V5.40013C5.39011 5.21904 5.24334 5.07227 5.06224 5.07227Z" fill="black"/>
    <path d="M2.2437 4.16793V12.2459C2.2437 12.7234 2.41877 13.1717 2.72461 13.4935C3.02904 13.8161 3.45271 13.9992 3.8961 14H10.0995C10.543 13.9992 10.9666 13.8161 11.271 13.4935C11.5768 13.1717 11.7519 12.7234 11.7519 12.2459V4.16793C12.3598 4.00656 12.7538 3.41922 12.6725 2.79537C12.591 2.17166 12.0596 1.70508 11.4305 1.70496H9.75187V1.29512C9.75379 0.950476 9.61752 0.619534 9.37354 0.376067C9.12956 0.132727 8.79811 -0.00277453 8.45346 4.30791e-05H5.5421C5.19745 -0.00277453 4.866 0.132727 4.62202 0.376067C4.37804 0.619534 4.24177 0.950476 4.24369 1.29512V1.70496H2.56503C1.93594 1.70508 1.40456 2.17166 1.32311 2.79537C1.24178 3.41922 1.63573 4.00656 2.2437 4.16793ZM10.0995 13.3443H3.8961C3.33552 13.3443 2.89943 12.8627 2.89943 12.2459V4.19675H11.0961V12.2459C11.0961 12.8627 10.66 13.3443 10.0995 13.3443ZM4.89943 1.29512C4.89725 1.1244 4.96436 0.960081 5.08552 0.839564C5.20655 0.719047 5.37125 0.652833 5.5421 0.655779H8.45346C8.62431 0.652833 8.78902 0.719047 8.91005 0.839564C9.0312 0.959953 9.09831 1.1244 9.09614 1.29512V1.70496H4.89943V1.29512ZM2.56503 2.36069H11.4305C11.7565 2.36069 12.0207 2.62491 12.0207 2.95085C12.0207 3.2768 11.7565 3.54102 11.4305 3.54102H2.56503C2.23909 3.54102 1.97487 3.2768 1.97487 2.95085C1.97487 2.62491 2.23909 2.36069 2.56503 2.36069Z" fill="black"/>
    <path d="M6.99974 5.07227C6.81865 5.07227 6.67188 5.21904 6.67188 5.40013V11.5969C6.67188 11.7778 6.81865 11.9247 6.99974 11.9247C7.18084 11.9247 7.32761 11.7778 7.32761 11.5969V5.40013C7.32761 5.21904 7.18084 5.07227 6.99974 5.07227Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_3720_13739">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
</svg>