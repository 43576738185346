<div class="background opaque" [ngClass]="{'visible': showModal, 'invisible': !showModal}"></div>

<div class="background" [ngClass]="{'visible': showModal, 'invisible': !showModal}">
<div class="dialog" >
    <div class="header">
        <span></span>
        <span class="warning">Aviso</span>
        <span class="close pointer" (click)="close()">&times;</span>
    </div>

    <div class="content">
        <p class="text">La modificación del precio no puede superar el {{getPercentage()}}. Por favor ingrese otro valor.</p>
    </div>
</div>

</div>