import { Component, OnInit,Output, Input, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { buyers } from 'src/app/shared/dict/buyers';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class BuyerComponent extends Field implements OnInit, OnDestroy {
  @Input() valueToSet!:number;
  @Input() isRequired:boolean=false;
  @Output() buyerEvent = new EventEmitter<number>();
  arrayOfBuyers: any[] = [];


  private arrayOfBuyers$!:Subscription;


  constructor(store: Store<appState>) {
    super(store);
    this.array = Object.keys(buyers);
    //this.array.shift();
    this.OBJECT=buyers;
    this.deleteLastValueInArray();

    this.initalElementSelected = 'Ingresar un comprador';

    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.isGeneratePrice()) {
      this.tag =priceTags.observations;
      this.subTag = priceTags.buyer;
    } else {
      this.tag = orderTags.price;
      this.subTag =priceTags.observations;
      this.thirdTag = priceTags.buyer;
    }

    if(this.isGeneratePrice()){
      this.arrayOfBuyers$=this.store.select('price','arrayOfBuyers').subscribe(array=>{
        this.arrayOfBuyers= JSON.parse(JSON.stringify(array));
      })
    }

    setTimeout(()=>{
      this.rememberValueInForm();
    },400)
  }

  override ngOnDestroy(){
    super.ngOnDestroy();
    this.arrayOfBuyers$?.unsubscribe();
  }

  deleteBuyers(event:any){
    if(event==true){
      this.setElement(-1,-1);
    }
  }

  setElementSelectedToGeneratePrice(){
    if(this.arrayOfBuyers.length>0){
      this.elementSelected=this.arrayOfBuyers.length + (this.arrayOfBuyers.length==1 ? ' comprador seleccionado' : ' compradores seleccionados');
      this.setIsOptionSelected(true);
    } else{
      this.elementSelected='';
      this.setIsOptionSelected(false);
    }
  }

  emit(value:number){
    this.buyerEvent.emit(value);
  }
  
  override rememberValueInForm(){
    if(this.fieldIdentificator != fieldType.ADD_BUYER && !this.isGeneratePrice()){
      this.rememberValueWithObject()
    } else if (this.fieldIdentificator == fieldType.ADD_BUYER){

      this.rememberValueWithAddBuyerField()
      
    } else if (this.isGeneratePrice()){
      this.setElementSelectedToGeneratePrice();
    }
  }

  rememberValueWithAddBuyerField(){
    if(this.valueToSet>0){
      this.elementSelected= this.OBJECT[this.valueToSet];
      this.placeHolder='';
      this.search=this.OBJECT[this.valueToSet];
      this.changeOptionSelected();
    } else {
      this.elementSelected='';
      this.search='';
      this.placeHolder='';
      this.setIsOptionSelected(false);
    }
  }

  override setElement(element: any, index: number) {
    const valueToSet=Number(element);
    if(this.fieldIdentificator != fieldType.ADD_BUYER){
      this.setElementToGenerate(valueToSet);
    } else{
      this.valueToSet=valueToSet;
      this.elementSelected = valueToSet >0? this.OBJECT[valueToSet]: '';
      this.emit(valueToSet); //Emitimos un evento
      this.search=this.elementSelected;
      this.placeHolder='';
      this.setIsOptionSelected(true);
      this.setShowOptions(false);

    }
  }

  getElementSelected(){
    return this.fieldIdentificator!= fieldType.ADD_BUYER ? this.elementSelected : this.OBJECT[this.valueToSet];
  }

  isGeneratePrice(){
    return this.fieldIdentificator==fieldType.PRICE;
  }

  closeSlide(){
    this.showOptions=false;
  }

    //DETECT CLICK
    @HostListener('document:click', ['$event']) 
    override clickInside(event: any) {
      if(!this.isGeneratePrice()){
        try {
          const classNames: string = event.target.classList.value;
          if (this.showOptions) this.count = 1;
          
          if (classNames.length > 0) {
            if (!classNames.includes(this.identifyClassName) && this.count > 0) {
              this.setChangeShowOptions(false);
            }
          }
          this.count = 0;
        } catch (err) {
          console.error('Esta es el error', err);
        }
      }
    }
}
