import { Pipe, PipeTransform } from '@angular/core';
import { isFromCommodities } from './filterByBuyerType.pipe';
import { isPriceToFix } from './filter-by-condition-type.pipe';
import { isPriceVisibleForComercial } from '../price.visible.comercial';
import { Price, PriceString } from '../../interfaces/price.interface';
import { filterPriceByPrice } from './filter.by.price.pipe';
import { filterPriceByStrings } from './filterWithString.pipe';

@Pipe({
  name: 'filterAllPrice'
})
export class FilterAllPricePipe implements PipeTransform {

  transform(list: Price[], isComercial:boolean, buyerType:string,priceFilter:PriceString,conditionType:string, search:string,conditionFilters:number[]) {
    let arrayFinal: Price[] = [];
    const searchs = (search?.length>1)? search.trim().toLowerCase().split(" "): [];

    if(isComercial){
        list.forEach(element => {
            if( isPriceVisibleForComercial(element) && 
            this.allFilters(element, buyerType, conditionType, priceFilter,searchs,conditionFilters) ){ arrayFinal.push(element); }
        })
    } else {
        list.forEach(element => {
            if(this.allFilters(element, buyerType, conditionType, priceFilter, searchs,conditionFilters)){ arrayFinal.push(element); }
        })
    }

    return arrayFinal
}

    allFilters(price:Price, buyerType:string, conditionType:string, priceFilter:PriceString, searchs:string[], conditionFilters:number[]){
        return isFromCommodities(price, buyerType) &&
        isPriceToFix(price,conditionType, conditionFilters) &&
        filterPriceByPrice(price,priceFilter) && 
        filterPriceByStrings(price, searchs)
    }

}  
