import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getClaim, setDate, setHour, showMenu } from 'src/app/redux/actions/options.action';
import { loginSuccess } from 'src/app/redux/actions/session.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { DataChecker } from 'src/app/shared/data.checker';
import { DateChecker } from 'src/app/shared/date.checker';
import { area } from 'src/app/shared/dict/user';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { User } from 'src/app/shared/interfaces/user.interface';

export const NOMBRES_HABILITADOS=[
  'miqueasdavidgentile@gmail.com',
  'm.gentile@bimtrazer.com',
  'paulablason68@gmail.com',
  'p.blason@bimtrazer.com',
  'edangelosante@gmail.com',
  'guillermo.pianta@gmail.com',
  'cpmarceloorione@gmail.com',
  'n.ruiz@bimtrazer.com',
  'l.idbetan@bimtrazer.com',
  'm.lombardo@bimtrazer.com',
  's.perichon@bimtrazer.com',
  'justinacid20@gmail.com',
  'j.cid@bimtrazer.com',
]

const MAILS_VALIDS=[
  ...NOMBRES_HABILITADOS,
  'cabrera@ggsa.com.ar',
  'pomponio@ggsa.com.ar',
  'gastaldi@ggsa.com.ar',
  'brian.gonzalez@ggsa.com.ar',
  'arriola@ggsa.com.ar',
  'murugarren@ggsa.com.ar'
]

export interface AreaLocalStorage{
  dateSelected: Date;
  area: number;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public user!: User;
  public areas: any;
  public hour!: string;
  public claim!: number;

  private user$!: Subscription;
  private claim$!: Subscription;
  private hour$!: Subscription;

  constructor(private store: Store<appState>, private dataChecker: DataChecker, private dateC:DateChecker) {
    this.store.dispatch(this.dataChecker.rememberClaim());
    this.areas = area;
    
    this.setHour();
    this.setDate();
    this.refreshHour();
    this.setClaim();
  }

  ngOnInit(): void {
    this.user$ = this.store.select('usuario').subscribe(usuario => {
      this.user = usuario;
    });

    this.claim$ = this.store.select('options', 'temp').subscribe(temp => {
      this.claim = temp;
    });

    this.hour$ = this.store.select('options', 'hour').subscribe(hour => {
      this.hour = hour;
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.claim$?.unsubscribe();
    this.hour$?.unsubscribe();
  }

  //Abrimos el menu lateral
  openMenu() {
    this.store.dispatch(showMenu());
  }

  //Seteamos la fecha en el store de redux
  setDate(){
    this.store.dispatch(setDate({date: this.dateC.getDateInYYYYMMDDformat(new Date())}))
  }

  //Función para setear la hora en el store de redux
  setHour() {
    const date = new Date();
    this.store.dispatch(setHour(
      { hour: date.toLocaleTimeString().substring(0, 5) + " " + (12 > date.getHours() ? "AM" : "PM") }))
  }

  //Funcion para refrescar la hora cada minuto
  refreshHour() {
    setTimeout(() => {
      this.setHour();
      this.refreshHour();
    }, 60000)
  }

  //Funcion que invoca un effect para traer el clima desde la API
  setClaim() {
    this.store.dispatch(getClaim())
  }
  
  //Sirve para cambiar el perfil de usuario dependiendo el parametro pasado.
  //Seteamos el valor del area en el store de redux y tambien en una variable que se guardará en el localStorage.
  //Posteriormente cada vez que busque en al backend el token, si tiene esa variable y su area es distinta, no se la cambiara.
  //Para que no tenga que darle al botón de cambiar de perfil cada vez que ejecute una acción.
  changeTo(area:number){
    this.setAreaInLocalStorage(area); //Set area in local sorage
    const newUser={...this.user,area:area}; //Create user
    this.store.dispatch(loginSuccess({ user: newUser})); //Dispatch user
    localStorage.setItem(localStorageNames.user,JSON.stringify(newUser)); //Set user in local storage
  }

  //Dado un area, calcula la fecha y lo setea en el localStorage.
  setAreaInLocalStorage(area:number){
    const areaLocalStorage:AreaLocalStorage={
      dateSelected: new Date(),
      area: area
    }
    localStorage.setItem(localStorageNames.isSelectedArea,JSON.stringify(areaLocalStorage));
  }


  //Se fija si el email del usuario está en los mails validos para visualizar los botones de cambiar de estado.
  mailsVisibles(){
    return MAILS_VALIDS.includes(this.user.email);
  }
}
