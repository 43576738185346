import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { REDUCTION_VOID } from 'src/app/shared/const/business-particularities';
import { typeCoin } from 'src/app/shared/dict/dict';
import { Reduction } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-rebaja',
  templateUrl: './rebaja.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class RebajaComponent extends BusinessParticularityOption implements OnChanges {
  reduction:Reduction=REDUCTION_VOID;

  constructor(private validator: ValidatorChecker, private bussPartV:BusinessParticularitiesValidator) {
    super()
  }

  ngOnChanges(): void {
    try{
      this.reduction=this.businessParticularitiesStruct.reduction;
    } catch(err){
      this.reduction=REDUCTION_VOID;
    }
  }

  setReduction(reduction:Reduction){
    this.reduction=reduction;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.reduction=this.reduction;
    this.emit2(newBP);
  }

  getReduction(){
    return JSON.parse(JSON.stringify(this.businessParticularitiesStruct.reduction));
  }

  setValue(event:any, tag:string){
    //Si el tag es typeCoin, seteamos el valor tal cual, sino, lo validamos
    let valueToSet= tag=='typeCoin'? event: this.validator.validatorNumber(event);
    let newReduction=JSON.parse(JSON.stringify(this.reduction));
    newReduction[tag]=valueToSet; //Asignamos el valor
    if(tag=='percentage'){ 
      //Si el tag es porcentaje, seteamos el monto en 0
      newReduction['amount']=-1;
      newReduction['typeCoin']='--';
    }else{
      //Si el tag no es porcentage, lo seteamos en 0
      newReduction['percentage']=-1;

      //Si el tag es amount, seteamos el typeCoin en USD
      if(tag=='amount'){
        newReduction['typeCoin']='USD';
      }
    }
    this.setReduction(newReduction);
  }

  isReductionActive(){
    return this.bussPartV.getIsReduction(this.businessParticularitiesStruct);
    //return this.reduction.amount>0 || this.reduction.percentage>0;
  }

  getOptions(){
    return [
      {
        value: 'USD',
        name: 'Dólares'
      },
      {
        value: 'ARS',
        name: 'Pesos'
      }
    ]
  }
}
