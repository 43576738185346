import { Component, Output,EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { quality } from 'src/app/shared/dict/dict';
import {QUALITY_IBM} from 'src/app/shared/dict/quality_ibm';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-quality-ibm',
  templateUrl: './quality.component.html',
  styleUrls: ['./../fields.component.scss'],
})
export class QualityIBMComponent extends Field implements OnInit {

  constructor(store: Store<appState>) {
    super(store);

    this.array = Object.keys(QUALITY_IBM);
    //this.array.shift();
    this.deleteLastValueInArray();
    this.OBJECT = QUALITY_IBM;
    this.initalElementSelected = 'Ingresar una calidad';

    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.qualityIBM;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.qualityIBM;
    }
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    this.rememberQuality();
  }
  
  rememberQuality(){
    let valueToSet: any = this.rememberValue();
    //If There is a value to remember
    if (valueToSet != -1 && this.elementToGenerate && valueToSet) {
      this.elementSelected = this.OBJECT[valueToSet];
      this.changeOptionSelected();
    } else {
      this.elementSelected = '';
      this.setIsOptionSelected(false)
    }
  }

  override setElement(element: any, index: number) {
    this.setElementToGenerate(Number(element));
  }

  /* Pasamos al siguiente paso cuando completamos el formulario de orden o validación */
  @Output() tab = new EventEmitter<string>();
  emit(){
    this.tab.emit('tab');
  }
  listenerTab(event: any) {
    if(event.key.toLowerCase() == 'tab' &&  (this.fieldIdentificator == fieldType.ORDER  || this.fieldIdentificator==fieldType.VALIDATION)){
      this.emit();
  }}

}
