
<div class="field" #fieldName>
    <span class="field-title">Localidad<span class="asterisk">*</span></span>
    <div>
        <div class="form-field" [ngClass]="{'border-green': isOptionSelected,'is-incomplete': isIncomplete || isInvalid}"
            (click)="changeShowOptions()">
            <span class="title-field">
                <span class="autocomplete"> 
                    <span class="first-part">{{search}}</span> 
                    <span class="second-part">{{placeHolder}}</span>
                </span>
                
                <input  #inputName (focus)="focusField(fieldName);" 
                    (blur)="unfocusField()" value="{{elementSelected}}" [(ngModel)]="search" placeholder="{{initalElementSelected}}" 
                    class="{{identifyClassName}} no-background-border"/>
                <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                <img alt="arrow-green" class="{{arrowDirection}}" (mouseup)="focusInInput(inputName)"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>

        <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>
        <span class="text-is-incomplete" *ngIf="isInvalid">No se encontró código postal de la localidad, por favor ingresá otra.</span>
        <div class="options" *ngIf="showOptions">
            <span *ngFor="let element of array | filterByObject: search:OBJECT:'town'; index as i" [ngClass]="{'selected': element.location==elementSelected}"
                (click)="setElement(element, i)">
                {{element.location}}
            </span>
        </div>
    </div>
</div>