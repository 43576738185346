import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'commission-svg-do',
  templateUrl: './commission.component.html',
  styleUrls: ['./../../../svg.scss','./../duplicate-orders.scss']
})
export class CommissionSvgDOComponent implements OnInit{
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  @Input() size: number= 24;
  public style={
    width: '24px',
    height: '24px',
  }
  constructor() { }

  ngOnInit(): void {
    this.style.width=this.size+'px';
    this.style.height=this.size+'px';
  }

}
