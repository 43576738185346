import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ComercialComponent } from './comercial/comercial.component';
import { FilterOrderModule } from 'src/app/components/filters/filter-order/filters.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EraserTableComponent } from './eraser-table.component';
import { DialogYesOrNoModule } from 'src/app/components/dialogs/dialog-yes-or-no/dialog-yes-or-no.module';
import { ObservationsOrderSvgModule } from 'src/app/shared/styles/svg/observations-order/observations-order.module';
import { IndicatorsTableModule } from 'src/app/components/board/indicators/indicators.module';
import { RowHeadModule } from 'src/app/components/board/order/row-head/row-head.module';
import { RowOrderBoardModule } from 'src/app/components/board/order/row/row.module';
import { TableMobileOrderModule } from 'src/app/components/board/mobile-order/mobile-order.module';
import { ButtonsToFixModule } from 'src/app/components/buttons-to-fix/buttons-to-fix.module';

@NgModule({
  declarations: [
    EraserTableComponent,
    ComercialComponent,
  ],
  imports: [
    CommonModule, 
    MatDatepickerModule,
    FilterOrderModule,
    DialogYesOrNoModule,
    TableMobileOrderModule,
    PipesModule,
    ButtonsToFixModule,

    ObservationsOrderSvgModule,

    IndicatorsTableModule,
    RowHeadModule,
    RowOrderBoardModule
  ],
  exports: [
    EraserTableComponent
  ]
})
export class EraserTableModule { }
