import { Input, Component, OnInit } from '@angular/core';
import { howSell, typeCoinsStr } from 'src/app/shared/dict/dict';
import { SellerObject, sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';

@Component({
  selector: 'app-info-exchange',
  templateUrl: './info-exchange.component.html',
  styleUrls: ['./../info.scss']
})
export class InfoExchangeComponent implements OnInit {
  @Input() order!:any;
  SELLERS:SellerObject={};
  HOW_SELL:any={};

  constructor(private orderV:OrderValidator, private orderExcV:OrderExchangeValidator) {
    this.SELLERS=sellerWithData;
    this.HOW_SELL=howSell;
  }

  ngOnInit(): void {
  }

  /* Is the buyer of order, seller of exchange*/
  getBuyerName(order:OrderExchange){
    try{
      return this.SELLERS[order.exchange.buyer.codeS].nombre + ' ' + (order.exchange.buyer.codeS <=0 ? '' : ' ('+order.exchange.buyer.codeS+')');
    } catch (e){
      return '--';
    }
  }

  /* Is the seller of order, buyer of exchange */
  getSellerName(order:OrderExchange){
    try{
      return this.SELLERS[order.sellerData.codeS].nombre + (order.sellerData.codeS <=0 ? '' : ' (' + order.sellerData.codeS+ ')');
    } catch (e){
      return '--';
    }
  }

  getHowSell(order:OrderExchange){
    try{
      return this.HOW_SELL[order.exchange.howSell];
    } catch (e){
      return '--';
    }
  }

  getBonus(order:OrderExchange){
    return this.orderExcV.getBonification(order.exchange);
  }

  getPlaceOfOrigin(order:OrderExchange){
    try{
      return this.orderV.getPlaceOfOrigin(order.exchange.placeOfOrigin.province, order.exchange.placeOfOrigin.town);
    } catch (e){
      return '--';
    }
  }

  getTons(order:OrderExchange){
    return this.orderExcV.getTons(order);
  }

  /*getWayPay(order:OrderExchange){
    return this.orderExcV.getWayPay(order);
  }*/

  isSyngenta(order:OrderExchange){
    return this.orderV.isSyngenta(order.exchange.buyer.codeS);
  }

  getSubdivisionSyngenta(order:OrderExchange){
    return this.orderV.getSubdivisionSyngenta(order);
  }

  getAgreedDiscount(order:OrderExchange){
    try{
      return order.exchange.agreedDiscount<=0?'--':order.exchange.agreedDiscount + ' %';
    } catch (e){
      return '--';
    }
  }

  getPriceDiscount(order:OrderExchange){
    try{
      return order.exchange.priceDiscount<=0?'--':order.exchange.priceDiscount+' %';
    } catch (e){
      return '--';
    }
  }
  getPrice(order:OrderExchange){
    return order.exchange.price<=0?'--':order.exchange.price;
  }

  getTypeCoin(order:OrderExchange){
    let typeCoin:any=typeCoinsStr;
    return typeCoin[order?.exchange?.typeCoin] ?? '--';
  }

}
