import { Component, OnInit,Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateChecker } from 'src/app/shared/date.checker';
import { InputE } from '../input';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports


@Component({
  selector: 'date-month-input',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss', '../input.scss']
})
export class DateMonthComponent extends InputE implements OnInit {
  public dateAux:string = ''; //Date in numeric format ddmmyy
  @Input() minDate: string='';

  constructor(private _adapter: DateAdapter<any>, private dateC: DateChecker) { 
    super();
    this._adapter.setLocale('es-AR');
  }

  setMonthAndYearAux(normalizedMonthAndYear: any, datepicker: MatDatepicker<any>) {
    const date=new Date(normalizedMonthAndYear);
    const month=date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
    const year=date.getFullYear();
    const dateStr=month + '-' + year;
    this.select(dateStr);
    datepicker.close();
  }

  override select(event: any): void {
    try{
      this.emit(event);
      this.setValue(event);
    } catch(err){}
  }

  getDateMin(){
    if(this.minDate!='' && this.minDate!='--'){
      return this.dateC.stringToDate(this.minDate); 
    }
    return new Date();
  }

  getDateAux(){
    return this.dateAux;
  }

  override setValue(dateStr:string){
    this.value = dateStr;
  }

  override getIsInputCompleted(): boolean {
    return this.value && this.value != '' && this.value!=' ' && this.value!='--' ; 
  }

  override getValue(): string {
    try{
      if(this.value.length < 5){
        return '';
      }
      return this.value;
    } catch(err){
      return '';
    }
  }

}
