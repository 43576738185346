import { Component, OnInit, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateChecker, getDateTomorrow, invertDate, isBusinessDay, isEarlierDate, isValidDate, parseDate } from 'src/app/shared/date.checker';
import { InputE } from '../input';
import { FormControl } from '@angular/forms';

// dd/mm/yyyy
const quantMaxOfLetterDate=10;

@Component({
  selector: 'date-input',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss', '../input.scss']
})
export class DateComponent extends InputE implements OnInit {
  @Input() isFilter: boolean = false;
  @Input() arrayDatesFilter: string[] = [];
  @Input() minDate: string='';
  @Input() maxDate: string='';

  public dateAux:string = ''; //Date in numeric format ddmmyy
  public dateControl = new FormControl(new Date());
  
  public myFilter:any

  constructor(private _adapter: DateAdapter<any>, private date: DateChecker) { 
    super();
    this._adapter.setLocale('es-AR');
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.filterDates();
  }

  filterDates(){ 
    if(this.isFilter && this.arrayDatesFilter.length<=0) {
      this.myFilter = (d: Date | null): boolean => {
        const date= d || new Date();
  
        return isBusinessDay(parseDate(date))
        // Prevent Saturday, Sunday or feriado from being selected
      };
    } else if(this.isFilter && this.arrayDatesFilter.length>0){
      this.myFilter = (d: Date | null): boolean => {
        const date= d || new Date();
        const dateStr = parseDate(date);

        return !this.arrayDatesFilter.includes(dateStr);
      };
    }
  }
  override select(event: any): void {
    try{
      const dateParsed = this.date.toLocaleDateDashString(event.target.value); //Date in string
      this.setAllValues(dateParsed);
    } catch(err){}
  }

  getDateMin(): Date{
    if(this.minDate!='' && this.minDate!='--'){
      return this.date.stringToDate(this.minDate);
    }
    return new Date();
  }

  getDateMax(){
    if(this.maxDate!='' && this.maxDate!='--'){
      return this.date.stringToDate(this.maxDate);
    }
    return 'none';
  }

  //Calcula la fecha auxiliar para mostrar en el input
  checkKeyPress(event: any, inputDate: any){
    const key:string= event.key.toLowerCase();
    if(inputDate.value.length>=quantMaxOfLetterDate){
      return false;
    }

    return key=='enter' || key=='tab' || key=='arrowleft' || key=='backspace' || '0123456789'.includes(key);
  }

  setDateAux(event: any, inputDate: any){
    const key:string= event.key.toLowerCase();
    if('0123456789'.includes(key)){

      if(goesStripper(inputDate.value)){
        inputDate.value+='-';
      }
      this.dateAux=inputDate.value;
      this.value=this.dateAux;
    } else if (key=='enter' || key=='arrowleft' || ( key=='tab' && inputDate.value.length>1)  ){
      //this.parseDateAux(inputDate.value);
      inputDate.blur();
    } else if(event.key.toLowerCase()=='backspace'){

      if(event.target.value.length<=1){
        this.emit('--');
      }
    }
  }
  
  /* Once the (blur) event is emitted, this function is executed to emit the value that exists in the input */
  emitBlur(event: any, inputDate: any){
    this.parseDateAux(inputDate.value);
  }

  parseDateAux(dateStr:string){
    let dateToReturn='--';
    const dateSplit=dateStr.split('-');
    
    if(dateSplit.length==3){
      //Quitamos 0 a la izquierda, le agregamos el año completo.
      const year=Number(dateSplit[2]) < 100? '20'+dateSplit[2]:dateSplit[2];
      dateToReturn= Number(dateSplit[0]) + '-' + Number(dateSplit[1]) + '-' + year;

      const dateToday=this.date.getDate();
      //Ahora verificamos que la fecha sea valida y que no sea anterior a la fecha actual
      if(isValidDate(dateToReturn) == '--' || 
      (isEarlierDate( dateToReturn,dateToday, '-') && dateToday!=dateToReturn) ){
        dateToReturn='--';
      }
    }
    this.emit(dateToReturn);
    this.value=dateToReturn=='--'? '':dateToReturn;
  }

  setAllValues(dateStr:string){
    this.emit(dateStr);
    this.dateAux=dateStr;
    this.setValue(dateStr);
  }

  checkBackspace(event:any){
    if(event.key.toLowerCase()=='backspace'){
      if(event.target.value.length<=1){
        this.emit('--');
      }
    }
  }

  getDateAux(){
    return this.dateAux;
  }

  override setValue(dateStr:string){
    const nextDate = getDateTomorrow(dateStr);
    this.value = invertDate(nextDate);
  }

  override getValue(): string {
    return this.value?.length < 5? '':this.value;
  }

  getValueDate() {
    this.dateControl.setValue(this.date.stringToDate(this.value));
    return this.dateControl;
  }

  override getIsInputCompleted(): boolean {
    return this.value && this.value != '' && this.value!=' ' && this.value!='--' && isValidDate(this.value); 
  }
}

/**
 * Given a date in '-' format, returns true if the separator is present.
 * Example: 
 * 21 -> true
 * 21-2 -> False
 * 2 -> False
 * 23-02 -> True
 * @param {string} string - Date in '-' format
 * @returns {boolean} - True if the separator is present, false otherwise
 * */
export function goesStripper(string:string):boolean{
  return string?.length==2 || string?.length==5;
}
