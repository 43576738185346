import { Component, Input,AfterViewInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';

const QUANT_MAX_OF_PARTICULARITIES:number=12;

@Component({
  selector: 'app-quality-particularities',
  templateUrl: './quality-particularities.component.html',
  styleUrls: ['../fields.component.scss']
})
export class QualityParticularitiesComponent implements AfterViewInit,OnDestroy{
  particularities: number[]=[];
  @Input() fieldIdentificator:string='';
    showOptions:boolean=false;
    isOptionSelected:boolean=false;
    elementSelected:string='';
    placeHolder:string='Ingresar una particularidad';
    elementToGenerate:any;
  
    private price$!:Subscription;
    private order$!:Subscription;

    constructor(private store:Store<appState>, private priceV:PriceValidator) { 
    }
  
    ngAfterViewInit(): void {
      this.price$=this.store.select('price').subscribe(price=>{
        if(this.fieldIdentificator==fieldType.PRICE){
          this.elementToGenerate = price.generatePrice;
        }
        this.getParticularities();
      })
  
      this.order$=this.store.select('order').subscribe(order=>{
        if(this.fieldIdentificator==fieldType.ORDER){
          const orders=order.generateOrders;
          if(orders.length>1){
            this.elementToGenerate=orders[order.indexToEdit];
          } else {
            this.elementToGenerate = order.generateOrder;
          }
          this.getParticularities();
        } else if(this.fieldIdentificator==fieldType.VALIDATION){
          this.elementToGenerate =
            order.edit.ordersEdit[
              order.edit.indexToEdit
            ];
            this.getParticularities();
        } 
      });
    }

    ngOnDestroy(): void {
      this.price$?.unsubscribe();
      this.order$?.unsubscribe();
    }
  
    closeSlide(){
      this.showOptions=false;
    }
  
    getIsOptionSelected(): boolean {
      return this.isOptionSelected;
    }
  
  changeShowOptions() {
    this.showOptions = !this.showOptions;
  }
  

  setShowOptions(value: boolean) {
    this.showOptions = value;
  }

  focusInInput(input:any){
    input.focus();
  }

  getParticularities(){
    try{
      //const parts=this.priceV.getQualityParticularities(this.getPrice().qualityParticularities);
      const part=this.priceV.getQualityIBM(this.getPrice());
      //if(parts.length>0){
      if(part && part!='--'){
        this.isOptionSelected=true;      
        //this.elementSelected="Seleccionada " + parts.length + " de " + QUANT_MAX_OF_PARTICULARITIES;
        this.elementSelected=part
      } else {
        this.elementSelected='';
        this.isOptionSelected=false;
      }
    } catch(err){
      this.elementSelected='';
      this.isOptionSelected=false;
    }
  }

  getPrice():Price {
    try{
      if (this.fieldIdentificator == fieldType.PRICE) {
        return this.elementToGenerate;
      } else {
        return this.elementToGenerate.price;
      }
    } catch (error) {
      const und:any=undefined;
      return und;
    }
  }

  
}
