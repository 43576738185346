import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { orderTags } from 'src/app/shared/const/orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../fields.component';

const subOption3='Nro de deposito';

@Component({
  selector: 'app-parameterized-observations',
  templateUrl: './parameterized-observations.component.html',
  styleUrls: ['./parameterized-observations.component.scss', './../fields.component.scss']
})
export class ParameterizedObservationsComponent extends Field implements OnInit {
  nroSubOption:number=0;
  nroOperative:number=0;

  //Sirve para mostrar el mensaje de que no se escribe en ese input cuando se presiona una tecla
  showMessage:boolean=false;

  //Cuando la suboption es 3. Se obtiene el numero de contrato
  nroOfContract:number=0;
  arrayOfContracts:number[]=[];
  showMessageDeposit:boolean=false;

  constructor(store:Store<appState>) { 
    super(store);
    this.initalElementSelected='Selecciona una observación'
  }

  ngOnInit(): void {
    this.tag=orderTags.observationsParameterized;    
  }
  
  override rememberValueInForm(){
    this.rememberObsParam();
  }

  override setElement(event:any, index:number){
    this.elementSelected = event;
    this.setElementToGenerate(event? event: '--');

    if(this.nroSubOption!=3){
      this.changeShowOptions();
    } else {
      //this.nroOfContract=0;
    }
  }

  checkKeyPress(){
    this.showMessage=true;
    return false;
  }

  rememberObsParam(){
    try {
      let valueToSet: string = this.rememberValue();
      //If There is a value to remember
      if (this.elementToGenerate && valueToSet && valueToSet!='--') {
        this.elementSelected = valueToSet;
        this.setNroSubOption(valueToSet);
        this.setIsOptionSelected(this.elementSelected.length>0);
      } else {
        this.elementSelected = '';
        this.nroSubOption=0;
        this.setIsOptionSelected(false);
      }
    } catch (err) {
      this.elementSelected = '';
    }
  }

  //Dado un numero de suboption, se asigna en una variable
  //Si es la suboption 5 (opción "Otro");
  //El div pasado por parametro (options), debe bajar hasta el final
  setSubOption(value:number, options:any=null){
    this.nroSubOption = value;
    if(value==5){
      setTimeout( () => {
        options.behavior = 'smooth';
        options.scrollTop = options.scrollHeight;
      }, 50);
    }
  }

  setOperative(value:number){
    this.nroOperative = value;
    let textToReturn=''
    switch(value){
      case 1: textToReturn='Operativo excluyente'; break;
      case 2: textToReturn='Opción operativo'; break;
      case 3: textToReturn='Trocha'; break;
      default: textToReturn=''; break;
    }
    this.setElement(textToReturn,0)
  }

  setNroOfContractNew(event:any){
    this.setElement('El contrato Nro '+ event +' se anula y se reemplaza con el nuevo',0)
  }

  setNroOfDep(event:any){
    const number=Number(event);
    //Corroboramos que el numero sea mayor a 200.000 y menor a 999.999
    if(number>=100000 && number<9999999){
      this.showMessageDeposit=false;
      this.nroOfContract=number;
      //Si el numero está incluido, no hacemos nada
      if(this.arrayOfContracts.includes(number)){

      } else if(this.arrayOfContracts.length==0){
        //Si el numero no está incluido, entonces lo agregaremos.
        //Para agregarlo nos fijamos si el array ya tenía un elemento.
        //Si no tenía elementos, entonces enviamos el mensaje subOption3+' '+ number
          this.setElement(subOption3+' '+ number,0)
      } else {
        //Si tenía elementos, entonces enviamos el mensaje que estaba antes +'- '+ ultimoElemento
        this.setElement(this.elementSelected+' - '+number,0)
      }
    } else {
      this.showMessageDeposit=true;
    }
  }

    //A partir de un texto, se busca una suboption.
  setNroSubOption(text:string){
    if(text.includes(subOption3)){
      this.nroSubOption=3;
      this.arrayOfContracts=this.getArrayOfDepoosits(text);
    } else {
      this.arrayOfContracts=[];
      this.nroOfContract=0;
      this.nroSubOption=5;
    }
  }

  //A partir de un texto, obtiene un arreglo de depósitos (Numeros de contrato)
  getArrayOfDepoosits(text:string){
    return text.split('deposito ')[1].split('-').map( (value:string) => parseInt(value.trim()) );
  }

  deleteContract(numberContract:number){
    const index=this.arrayOfContracts.indexOf(numberContract);
    this.arrayOfContracts.splice(index,1);
    if(this.arrayOfContracts.length==0){
      this.setElement('--',0)
    } else {
      this.setElement(subOption3+' '+this.arrayOfContracts.join(' - '),0)
    }
  }

  
}
