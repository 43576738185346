import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setShowErrorChangePrice } from 'src/app/redux/actions/options.action';
import { VARIATION_PERCENT } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'app-error-change-price',
  templateUrl: './error-change-price.component.html',
  styleUrls: ['./error-change-price.component.scss', './../modal.scss'],
})
export class ErrorChangePriceComponent implements OnInit, OnDestroy {
  showModal:boolean=false;

  private optionsSubscription!:Subscription;
  constructor(private store:Store<appState>) {}

  ngOnInit(): void {
    this.optionsSubscription=this.store.select('options').subscribe(options=>{
      this.showModal=options.showErrorChangePrice;
    });
  }

  ngOnDestroy(): void {
    this.optionsSubscription.unsubscribe();
  }

  getPercentage(){
    return (VARIATION_PERCENT * 100)+'%';
  }

  close(){
    this.store.dispatch(setShowErrorChangePrice({showErrorChangePrice:false}));
  }
}
