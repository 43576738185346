<svg class="svg-menu flex" [ngClass]="{'enabled': active, 'disabled': !active}" 
width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3298_180247)">
    <path d="M17.875 0.6875H4.125C3.21358 0.688337 2.33972 1.05077 1.69525 1.69525C1.05077 2.33972 0.688337 3.21358 0.6875 4.125V17.875C0.688337 18.7864 1.05077 19.6603 1.69525 20.3048C2.33972 20.9492 3.21358 21.3117 4.125 21.3125H17.875C18.7864 21.3117 19.6603 20.9492 20.3048 20.3048C20.9492 19.6603 21.3117 18.7864 21.3125 17.875V4.125C21.3117 3.21358 20.9492 2.33972 20.3048 1.69525C19.6603 1.05077 18.7864 0.688337 17.875 0.6875ZM6.1875 6.1875H19.9375V11.6875H6.1875V6.1875ZM4.125 2.0625H17.875C18.4218 2.06312 18.9461 2.28062 19.3327 2.66728C19.7194 3.05394 19.9369 3.57818 19.9375 4.125V4.8125H2.0625V4.125C2.06312 3.57818 2.28062 3.05394 2.66728 2.66728C3.05394 2.28062 3.57818 2.06312 4.125 2.0625ZM2.0625 17.875V6.1875H4.8125V19.9375H4.125C3.57818 19.9369 3.05394 19.7194 2.66728 19.3327C2.28062 18.9461 2.06312 18.4218 2.0625 17.875ZM17.875 19.9375H6.1875V13.0625H19.9375V17.875C19.9369 18.4218 19.7194 18.9461 19.3327 19.3327C18.9461 19.7194 18.4218 19.9369 17.875 19.9375Z" fill="#2E3B4C"/>
    </g>
    <defs>
    <clipPath id="clip0_3298_180247">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
</svg>