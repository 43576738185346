<div class="width-100 container-validation">

    <div class="row-between width-100 not-visible-in-mobile">
        <span class="title">Ordenes seleccionadas</span>
    
        <div class="row-between gap-4px checkbox-section pointer" (click)="changeEditAllOrders(!editAllOrders)">
            <input class="pointer" type="checkbox" [checked]="editAllOrders" [value]="editAllOrders"> 
            <span>Aplicar proxima edición a todo.</span>
        </div>
    </div>

    <app-details-many-orders class="width-100"
    [orders]="orders" [indexSelected]="identificatorOrderEdit" [editAllOrders]="editAllOrders"
    (action)="recieveAction($event)"></app-details-many-orders>

</div>


<div class="footer button-continue">
    <button (click)="goToOrderBoard()" class="not-filled-button go-to-order-board">
        Volver al listado
    </button>
    <button (click)="sendOrders()" [disabled]="disabledButton || haventBuyerInOrders()" [ngClass]="{'disabled': disabledButton || haventBuyerInOrders()}" class="not-filled-button generate-eraser">
        Generar borrador
    </button>
</div>