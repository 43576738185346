import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeferralComponent } from './deferral.component';
import { InfoSvgModule } from 'src/app/shared/styles/svg/info/info-svg.module';
import { InfoDeferralModule } from '../info-deferral/info-deferral.module';
import { NumberInputModule } from '../../inputs/number/number.module';



@NgModule({
  declarations: [
    DeferralComponent
  ],
  imports: [
    CommonModule,
  InfoDeferralModule,
    InfoSvgModule,
    NumberInputModule
  ], exports:[
    DeferralComponent
  ]
})
export class DeferralModule { }
