
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './pages/dashboard/inicio.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { PriceBoardComponent } from './pages/price-board/price-board.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UserGuard } from './options/guards/user.guard';
import { GeneratePriceComponent } from './pages/generate-price/generate-price.component';
import { GenerateManualOrderComponent } from './pages/generate-order-manual/generate-manual-order.component';
import { GeneratePriceBoardOrderComponent } from './pages/generate-order-price-board/generate-order-price-board.component';
import { OrdersValidationComponent } from './pages/orders-validation/orders-validation.component';
import { NAVEGATION } from './shared/const/navegation';
import { EraserComponent } from './pages/eraser/eraser.component';
import { ExchangeListComponent } from './pages/exchange-list/exchange-list.component';
import { GenerateExchangeComponent } from './pages/generate-exchange/generate-exchange.component';
import { HistoricalPageComponent } from './pages/historical/historical.component';
import { ControlPanelComponent } from './pages/control-panel/control-panel.component';

const routes: Routes = [
  { path: '', redirectTo: NAVEGATION.ACCESS, pathMatch: 'full'},
  { path: NAVEGATION.ACCESS, component: LoginComponent},
  { path: NAVEGATION.HOME, component: InicioComponent},
  { path: NAVEGATION.PROFILE, component: ProfileComponent}, // canActivate: [UserGuard]
  { path: NAVEGATION.PRICE_BOARD, component: PriceBoardComponent},
  { path: NAVEGATION.ORDER_BOARD, component: OrderListComponent},
  { path: NAVEGATION.GENERATE_MANUAL_ORDER, component: GenerateManualOrderComponent},
  { path: NAVEGATION.GENERATE_PRICE_BOARD_ORDER, component: GeneratePriceBoardOrderComponent},
  { path: NAVEGATION.GENERATE_PRICE, component: GeneratePriceComponent},
  { path: NAVEGATION.ORDER_VALIDATION, component: OrdersValidationComponent},
  { path: NAVEGATION.ORDER_EXCHANGE, component: ExchangeListComponent},
  { path: NAVEGATION.ERASER, component: EraserComponent},
  { path: NAVEGATION.GENERATE_EXCHANGE, component: GenerateExchangeComponent},
  { path: NAVEGATION.HISTORICAL, component: HistoricalPageComponent},
  { path: NAVEGATION.CONTROL_PANEL, component: ControlPanelComponent},
  { path: NAVEGATION.NOT_FOUND, redirectTo: NAVEGATION.ACCESS, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
