<div class="card selected" *ngIf="orders.length==1">
    <div class="width-100" [ngClass]="{'is-exchange-order':isGenerateExchangeOrder || isOrderToFix(order)}">
        <h3 class="text title not-visible-in-950">{{titleOfDetails}}</h3>
        <div class="card-basic-info row-between pointer" (click)="changeShowToFix()" *ngIf="isGenerateExchangeOrder || isOrderToFix(order)">
            <div class="basic-info column-left" (click)="changeShowDetailsOrdExc()">
                <span class="order-nro">Orden {{ORDER_TYPE[order.orderDestination.orderType]}}</span>
                <span class="seller">Vendedor: {{sellerAndTons(order)}}</span>
            </div>

            <arrow-svg class="arrow"
            [direction]="getArrowDirectionGeneric()" 
            (click)="changeShowDetailsOrdExc()"></arrow-svg>
        </div>
    </div>
    <div class="width-100 column-left">
        <div class="features visible-in-mobile card-basic-info">
            <div class="basic-info column-left">
                <span class="order-nro">Orden {{ORDER_TYPE[order.orderDestination.orderType]}}</span>
                <span class="row-between gap-8" *ngIf="!isGenerateExchangeOrder || isOrderToFix(order)">
                    <img alt="product-icon" src="../../../../../assets/images/products/{{PRODUCTS[order.price.productName]}}.svg" />
                    {{PRODUCTS[order.price.productName]}}</span>
                <span *ngIf="isGenerateExchangeOrder || isOrderToFix(order)">
                    <span class="seller">Vendedor: {{sellerAndTons(order)}}</span>
                </span>
            </div>
            
            <div class="paso visible-in-mobile price-container">
                <span class="price {{getTypeCoin(order).toLowerCase()}}">{{getPriceOfCondition(order)}} {{getTypeCoin(order)}}</span>
            </div>
        </div>
        <app-info-order [order]="order"  *ngIf="(!isGenerateExchangeOrder || showDetailsOrdExc) && (!isOrderToFix(order) || isShowToFix)"></app-info-order>

        <h3 class="text title" *ngIf="isGenerateExchangeOrder">Detalle de la contrapartida</h3>
        <app-info-exchange class="details-exchange" [order]="onlyExchange" *ngIf="isGenerateExchangeOrder"></app-info-exchange>

        <div class="header" *ngIf="isOrderToFix(order)">
            <h3 class="text title">Condición a fijar</h3>
        </div>
        <div class="width-100" *ngIf="isOrderToFix(order)">
            <app-info-to-fix [conditionType]="getConditionType(order)" [indicators]="getIndicators(order)"></app-info-to-fix>
        </div>
    </div>
</div>

<!--Duplicate orders-->
<app-details-many-orders *ngIf="orders.length>1" class="width-100"
[orders]="orders" [indexSelected]="identificatorOrderEdit"
(action)="recieveAction($event)"></app-details-many-orders>


    <div class="footer" *ngIf="orders.length>1">
        <span *ngIf="showMessageError" class="message-error">Campos incompletos en una de las ordenes.</span>
        <button (click)="sendOrders()" class="filled-button continue-button"
        
        [ngClass]="{'disabled': isOrdersIncomplete()}"
            data-toggle="modal" data-target="#exampleModal">
            Continuar
        </button>
    </div>