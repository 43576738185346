import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { businessDict } from 'src/app/shared/dict/typeBusiness';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields.component';

@Component({
  selector: 'app-start-pesification',
  templateUrl: './start-date.component.html',
  styleUrls: []
})
export class StartPesificationComponent extends Field implements OnInit {

  constructor(store: Store<appState>,private date: DateChecker) {
    super(store);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator == fieldType.PRICE) {
      this.tag = priceTags.pesificacion;
      this.subTag = priceTags.startDate;
      this.thirdTag = '';
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.pesificacion;
      this.thirdTag = priceTags.startDate;
    }
  }

  override setIncomplete(boolean:boolean){
    const pesification = this.getPrice().pesificacion;
    this.isIncomplete= boolean && (pesification.startDate == '--' && pesification.endDate != '--');
  }

  override setElement(event:any, index:number){
    try { 
      this.setTag(priceTags.startDate);
      
      let dateToSave = event;
      this.setElementToGenerate(dateToSave);

      this.setTag(priceTags.endDate);
      let date= '--';

      if( businessDict.forward == this.getPrice().typeBusiness && this.date.stringToDate(dateToSave).getDate() == 1 && event!='--'){
        //Si es foward, y la fecha es primero, calcular desde inicio de mes hasta fin. Sino 30 días
        date = this.date.getDateEndOfMonth(dateToSave);
      } else if(event!='--') {
        date=this.date.addDaysToDate(dateToSave, 30);
      }
      
      if(event!='--'){
        this.setElementToGenerate(date);
      }

    } catch (err) {}
  }

  override rememberValueInForm() {
    if(this.subTag==priceTags.startDate || this.thirdTag==priceTags.startDate){
      this.rememberPesification();
    }
  }

  //Remember value from date type (For deliveryPeriod).
  rememberPesification() {
    this.setTag(priceTags.startDate);

    let valueToSet: any = this.rememberValue();
    //If There is a value to remember
    if (valueToSet && this.elementToGenerate) {
      if (valueToSet) {
        if(valueToSet != '--'){
          this.elementSelected=valueToSet;
        } else {
          this.elementSelected='';
        }
      }
    }
  }
}
