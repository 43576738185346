<div class="section">
    
    <div class="datos-google" id="buttonDiv">
        <!--(click)="login()"-->
        <button  id="customBtn" (click)="login()" class="customGPlusSignIn"
        type="button" class="btn btn-primary button-google googleBtn">
                <div class="logo-text"><img src="../../../../assets/images/google-icon.svg"
                    alt="Google Icon" /><span>Acceder con Google</span></div>
        </button>
    </div>
    
    <p class="error" *ngIf="showError">{{messageError}}</p>
</div>