export const PLANTAS_INTERIOR_MAP={
  "-1": {
    "cgo": -1,
    "nombre": "--"
  },
  "0": {
      "cgo": 0,
      "nombre": "--"
  },
  "1": {
      "cgo": 1,
      "nombre": "Bahia Blanca",
      "provincia": 1
  },
  "2": {
      "cgo": 2,
      "nombre": "Buenos Aires",
      "provincia": 1
  },
  "3": {
      "cgo": 3,
      "nombre": "Con.Del Uruguay",
      "provincia": 3
  },
  "4": {
      "cgo": 4,
      "nombre": "Diamante",
      "provincia": 3
  },
  "5": {
      "cgo": 5,
      "nombre": "Rosario",
      "provincia": 6
  },
  "6": {
      "cgo": 6,
      "nombre": "San Lorenzo",
      "provincia": 6
  },
  "7": {
      "cgo": 7,
      "nombre": "Santa Fe",
      "provincia": 6
  },
  "8": {
      "cgo": 8,
      "nombre": "V.Constitucion",
      "provincia": 6
  },
  "9": {
      "cgo": 9,
      "nombre": "San Nicolas",
      "provincia": 1
  },
  "10": {
      "cgo": 10,
      "nombre": "San Pedro",
      "provincia": 1
  },
  "11": {
      "cgo": 11,
      "nombre": "Ramallo",
      "provincia": 1
  },
  "12": {
      "cgo": 12,
      "nombre": "San Martin",
      "provincia": 6
  },
  "13": {
      "cgo": 13,
      "nombre": "Duggan",
      "provincia": 1
  },
  "14": {
      "cgo": 14,
      "nombre": "San Ambrosio",
      "provincia": 2
  },
  "15": {
      "cgo": 15,
      "nombre": "S.Antonio Areco",
      "provincia": 1
  },
  "16": {
      "cgo": 16,
      "nombre": "Nelson",
      "provincia": 6
  },
  "17": {
      "cgo": 17,
      "nombre": "Maria Susana",
      "provincia": 6
  },
  "18": {
      "cgo": 18,
      "nombre": "St.Rosa Rio 1Ro",
      "provincia": 2
  },
  "19": {
      "cgo": 19,
      "nombre": "Altos D.Chipion",
      "provincia": 2
  },
  "20": {
      "cgo": 20,
      "nombre": "San Justo (Sf)",
      "provincia": 6
  },
  "21": {
      "cgo": 21,
      "nombre": "Chabas",
      "provincia": 6
  },
  "22": {
      "cgo": 22,
      "nombre": "Darsena",
      "provincia": 7
  },
  "23": {
      "cgo": 23,
      "nombre": "Pilar (Cba)",
      "provincia": 2
  },
  "24": {
      "cgo": 24,
      "nombre": "Esperanza",
      "provincia": 6
  },
  "25": {
      "cgo": 25,
      "nombre": "Avellaneda(Ba)",
      "provincia": 1
  },
  "26": {
      "cgo": 26,
      "nombre": "Cplla.Del Señor",
      "provincia": 1
  },
  "27": {
      "cgo": 27,
      "nombre": "Quilmes",
      "provincia": 1
  },
  "28": {
      "cgo": 28,
      "nombre": "Gral. Cabrera",
      "provincia": 2
  },
  "29": {
      "cgo": 29,
      "nombre": "San Justo (Ba)",
      "provincia": 1
  },
  "30": {
      "cgo": 30,
      "nombre": "Gigena (Cba)",
      "provincia": 2
  },
  "31": {
      "cgo": 31,
      "nombre": "Rio Cuarto",
      "provincia": 2
  },
  "32": {
      "cgo": 32,
      "nombre": "Las Acequias",
      "provincia": 2
  },
  "33": {
      "cgo": 33,
      "nombre": "Adelia Maria",
      "provincia": 2
  },
  "34": {
      "cgo": 34,
      "nombre": "Avellaneda (Sf)",
      "provincia": 6
  },
  "35": {
      "cgo": 35,
      "nombre": "Marcos Juarez",
      "provincia": 2
  },
  "36": {
      "cgo": 36,
      "nombre": "Pilar (Ba)",
      "provincia": 1
  },
  "37": {
      "cgo": 37,
      "nombre": "V. Alsina",
      "provincia": 1
  },
  "38": {
      "cgo": 38,
      "nombre": "Tilisarao",
      "provincia": 5
  },
  "39": {
      "cgo": 39,
      "nombre": "Berrotaran",
      "provincia": 2
  },
  "40": {
      "cgo": 40,
      "nombre": "Sta.C. Saguier",
      "provincia": 6
  },
  "41": {
      "cgo": 41,
      "nombre": "Junin (Bs.As.)",
      "provincia": 1
  },
  "42": {
      "cgo": 42,
      "nombre": "Villa Maria",
      "provincia": 2
  },
  "43": {
      "cgo": 43,
      "nombre": "Recreo",
      "provincia": 6
  },
  "44": {
      "cgo": 44,
      "nombre": "Cordoba",
      "provincia": 2
  },
  "45": {
      "cgo": 45,
      "nombre": "V.Del Rosario",
      "provincia": 2
  },
  "46": {
      "cgo": 46,
      "nombre": "Nogoya",
      "provincia": 3
  },
  "47": {
      "cgo": 47,
      "nombre": "Sampacho",
      "provincia": 2
  },
  "48": {
      "cgo": 48,
      "nombre": "Reconquista",
      "provincia": 6
  },
  "49": {
      "cgo": 49,
      "nombre": "Canals",
      "provincia": 2
  },
  "50": {
      "cgo": 50,
      "nombre": "V. Mackenna",
      "provincia": 2
  },
  "51": {
      "cgo": 51,
      "nombre": "San Jorge",
      "provincia": 6
  },
  "52": {
      "cgo": 52,
      "nombre": "Elena",
      "provincia": 2
  },
  "53": {
      "cgo": 53,
      "nombre": "Gdor.Crespo",
      "provincia": 6
  },
  "54": {
      "cgo": 54,
      "nombre": "Maciel",
      "provincia": 6
  },
  "55": {
      "cgo": 55,
      "nombre": "Pergamino",
      "provincia": 1
  },
  "56": {
      "cgo": 56,
      "nombre": "Gualeguaychu",
      "provincia": 3
  },
  "57": {
      "cgo": 57,
      "nombre": "Idiazabal",
      "provincia": 2
  },
  "58": {
      "cgo": 58,
      "nombre": "Maria Juana",
      "provincia": 6
  },
  "59": {
      "cgo": 59,
      "nombre": "Venado Tuerto",
      "provincia": 6
  },
  "60": {
      "cgo": 60,
      "nombre": "Capital Federal",
      "provincia": 1
  },
  "61": {
      "cgo": 61,
      "nombre": "Las Peñas",
      "provincia": 2
  },
  "62": {
      "cgo": 62,
      "nombre": "Malabrigo",
      "provincia": 6
  },
  "63": {
      "cgo": 63,
      "nombre": "Alfonso, M.H.",
      "provincia": 1
  },
  "64": {
      "cgo": 64,
      "nombre": "Laborde",
      "provincia": 2
  },
  "65": {
      "cgo": 65,
      "nombre": "Rosario D. Tala",
      "provincia": 3
  },
  "66": {
      "cgo": 66,
      "nombre": "Rio Segundo",
      "provincia": 2
  },
  "67": {
      "cgo": 67,
      "nombre": "Gral. Deheza",
      "provincia": 2
  },
  "68": {
      "cgo": 68,
      "nombre": "Barranqueras",
      "provincia": 10
  },
  "69": {
      "cgo": 69,
      "nombre": "V. Mercedes",
      "provincia": 5
  },
  "70": {
      "cgo": 70,
      "nombre": "Montes De Oca",
      "provincia": 6
  },
  "71": {
      "cgo": 71,
      "nombre": "Villaguay",
      "provincia": 3
  },
  "72": {
      "cgo": 72,
      "nombre": "Concordia",
      "provincia": 3
  },
  "73": {
      "cgo": 73,
      "nombre": "Rufino",
      "provincia": 6
  },
  "74": {
      "cgo": 74,
      "nombre": "Marull",
      "provincia": 2
  },
  "75": {
      "cgo": 75,
      "nombre": "Arrecifes",
      "provincia": 1
  },
  "76": {
      "cgo": 76,
      "nombre": "Las Higueras",
      "provincia": 2
  },
  "77": {
      "cgo": 77,
      "nombre": "Mar Del Plata",
      "provincia": 1
  },
  "78": {
      "cgo": 78,
      "nombre": "Rojas",
      "provincia": 1
  },
  "79": {
      "cgo": 79,
      "nombre": "Ewald",
      "provincia": 6
  },
  "80": {
      "cgo": 80,
      "nombre": "Tancacha",
      "provincia": 2
  },
  "81": {
      "cgo": 81,
      "nombre": "Strobel",
      "provincia": 3
  },
  "82": {
      "cgo": 82,
      "nombre": "Bovril",
      "provincia": 3
  },
  "83": {
      "cgo": 83,
      "nombre": "Piamonte",
      "provincia": 6
  },
  "84": {
      "cgo": 84,
      "nombre": "Acebal",
      "provincia": 6
  },
  "85": {
      "cgo": 85,
      "nombre": "Los Quirquincho",
      "provincia": 6
  },
  "86": {
      "cgo": 86,
      "nombre": "Chovet",
      "provincia": 6
  },
  "87": {
      "cgo": 87,
      "nombre": "Cnel.Pringles",
      "provincia": 1
  },
  "88": {
      "cgo": 88,
      "nombre": "Peyrano",
      "provincia": 6
  },
  "89": {
      "cgo": 89,
      "nombre": "Villa San Jose",
      "provincia": 6
  },
  "90": {
      "cgo": 90,
      "nombre": "La Paz",
      "provincia": 3
  },
  "91": {
      "cgo": 91,
      "nombre": "Ramona",
      "provincia": 6
  },
  "92": {
      "cgo": 92,
      "nombre": "Viale",
      "provincia": 3
  },
  "93": {
      "cgo": 93,
      "nombre": "Cerrito",
      "provincia": 3
  },
  "94": {
      "cgo": 94,
      "nombre": "Galarza",
      "provincia": 3
  },
  "95": {
      "cgo": 95,
      "nombre": "Seeber",
      "provincia": 2
  },
  "96": {
      "cgo": 96,
      "nombre": "Lezama",
      "provincia": 1
  },
  "97": {
      "cgo": 97,
      "nombre": "Saavedra",
      "provincia": 1
  },
  "98": {
      "cgo": 98,
      "nombre": "Ortiz Basualdo",
      "provincia": 1
  },
  "99": {
      "cgo": 99,
      "nombre": "Lucas Gonzalez",
      "provincia": 3
  },
  "100": {
      "cgo": 100,
      "nombre": "Sancti Spiritu",
      "provincia": 6
  },
  "101": {
      "cgo": 101,
      "nombre": "La Laguna",
      "provincia": 2
  },
  "102": {
      "cgo": 102,
      "nombre": "Diego De Alvear",
      "provincia": 6
  },
  "103": {
      "cgo": 103,
      "nombre": "Santa Isabel",
      "provincia": 6
  },
  "104": {
      "cgo": 104,
      "nombre": "Weisburd",
      "provincia": 7
  },
  "105": {
      "cgo": 105,
      "nombre": "Matilde",
      "provincia": 6
  },
  "106": {
      "cgo": 106,
      "nombre": "Jacinto Arauz",
      "provincia": 4
  },
  "107": {
      "cgo": 107,
      "nombre": "Villa Iris",
      "provincia": 1
  },
  "108": {
      "cgo": 108,
      "nombre": "Medanos",
      "provincia": 1
  },
  "109": {
      "cgo": 109,
      "nombre": "Tornquist",
      "provincia": 1
  },
  "110": {
      "cgo": 110,
      "nombre": "Ranqueles",
      "provincia": 2
  },
  "111": {
      "cgo": 111,
      "nombre": "Huinca Renanco",
      "provincia": 2
  },
  "112": {
      "cgo": 112,
      "nombre": "Gerli",
      "provincia": 1
  },
  "113": {
      "cgo": 113,
      "nombre": "San Jose",
      "provincia": 2
  },
  "114": {
      "cgo": 114,
      "nombre": "Guamini",
      "provincia": 1
  },
  "115": {
      "cgo": 115,
      "nombre": "Llambi Campbell",
      "provincia": 6
  },
  "116": {
      "cgo": 116,
      "nombre": "Piedras Blancas",
      "provincia": 3
  },
  "117": {
      "cgo": 117,
      "nombre": "M.Yr.Buratovich",
      "provincia": 1
  },
  "118": {
      "cgo": 118,
      "nombre": "Charata",
      "provincia": 9
  },
  "119": {
      "cgo": 119,
      "nombre": "Hasenkamp",
      "provincia": 3
  },
  "120": {
      "cgo": 120,
      "nombre": "Gdor. Mansilla",
      "provincia": 1
  },
  "121": {
      "cgo": 121,
      "nombre": "Escobar",
      "provincia": 1
  },
  "122": {
      "cgo": 122,
      "nombre": "Cnel.Baigorria",
      "provincia": 2
  },
  "123": {
      "cgo": 123,
      "nombre": "Gral. Pinedo",
      "provincia": 1
  },
  "124": {
      "cgo": 124,
      "nombre": "Humberto 1Ro.",
      "provincia": 6
  },
  "125": {
      "cgo": 125,
      "nombre": "Victoria",
      "provincia": 3
  },
  "126": {
      "cgo": 126,
      "nombre": "Santa Rosa",
      "provincia": 4
  },
  "127": {
      "cgo": 127,
      "nombre": "Fernandez",
      "provincia": 7
  },
  "128": {
      "cgo": 128,
      "nombre": "Huanchilla",
      "provincia": 2
  },
  "129": {
      "cgo": 129,
      "nombre": "Doll",
      "provincia": 1
  },
  "130": {
      "cgo": 130,
      "nombre": "Santo Tome (Sf)",
      "provincia": 6
  },
  "131": {
      "cgo": 131,
      "nombre": "Quitihual",
      "provincia": 1
  },
  "132": {
      "cgo": 132,
      "nombre": "Necochea",
      "provincia": 1
  },
  "133": {
      "cgo": 133,
      "nombre": "Macachin",
      "provincia": 4
  },
  "134": {
      "cgo": 134,
      "nombre": "Larroque",
      "provincia": 3
  },
  "135": {
      "cgo": 135,
      "nombre": "Gualeguay",
      "provincia": 3
  },
  "136": {
      "cgo": 136,
      "nombre": "Campana",
      "provincia": 1
  },
  "137": {
      "cgo": 137,
      "nombre": "S.A.De Giles",
      "provincia": 1
  },
  "138": {
      "cgo": 138,
      "nombre": "Gral. Las Heras",
      "provincia": 1
  },
  "139": {
      "cgo": 139,
      "nombre": "Pto.Seco (Cba)",
      "provincia": 2
  },
  "140": {
      "cgo": 140,
      "nombre": "Munro",
      "provincia": 1
  },
  "141": {
      "cgo": 141,
      "nombre": "Pedro Luro",
      "provincia": 1
  },
  "142": {
      "cgo": 142,
      "nombre": "T.Emb.Vicentin",
      "provincia": 6
  },
  "143": {
      "cgo": 143,
      "nombre": "Laboulaye",
      "provincia": 2
  },
  "144": {
      "cgo": 144,
      "nombre": "Viamonte",
      "provincia": 1
  },
  "145": {
      "cgo": 145,
      "nombre": "Tandil",
      "provincia": 1
  },
  "146": {
      "cgo": 146,
      "nombre": "Jose L.Suarez",
      "provincia": 1
  },
  "147": {
      "cgo": 147,
      "nombre": "Carcaraña",
      "provincia": 6
  },
  "148": {
      "cgo": 148,
      "nombre": "Dalmacio Velez",
      "provincia": 2
  },
  "149": {
      "cgo": 149,
      "nombre": "Faro",
      "provincia": 1
  },
  "150": {
      "cgo": 150,
      "nombre": "F. Varela",
      "provincia": 1
  },
  "151": {
      "cgo": 151,
      "nombre": "Alejandro Roca",
      "provincia": 2
  },
  "152": {
      "cgo": 152,
      "nombre": "Baradero",
      "provincia": 1
  },
  "153": {
      "cgo": 153,
      "nombre": "Embarc.Medileo",
      "provincia": 1
  },
  "154": {
      "cgo": 154,
      "nombre": "Porteña",
      "provincia": 2
  },
  "155": {
      "cgo": 155,
      "nombre": "Cnel.Dorrego",
      "provincia": 1
  },
  "156": {
      "cgo": 156,
      "nombre": "Saladillo",
      "provincia": 1
  },
  "157": {
      "cgo": 157,
      "nombre": "Galvez",
      "provincia": 6
  },
  "158": {
      "cgo": 158,
      "nombre": "Castellanos",
      "provincia": 6
  },
  "159": {
      "cgo": 159,
      "nombre": "Alvarez",
      "provincia": 6
  },
  "160": {
      "cgo": 160,
      "nombre": "Maria Grande",
      "provincia": 3
  },
  "161": {
      "cgo": 161,
      "nombre": "Jesus Maria",
      "provincia": 2
  },
  "162": {
      "cgo": 162,
      "nombre": "Instalac. Jng.",
      "provincia": 0
  },
  "163": {
      "cgo": 163,
      "nombre": "San Roman",
      "provincia": 1
  },
  "164": {
      "cgo": 164,
      "nombre": "Chacabuco",
      "provincia": 1
  },
  "165": {
      "cgo": 165,
      "nombre": "Rafaela",
      "provincia": 6
  },
  "166": {
      "cgo": 166,
      "nombre": "San Vicente",
      "provincia": 6
  },
  "167": {
      "cgo": 167,
      "nombre": "Tres Picos",
      "provincia": 1
  },
  "168": {
      "cgo": 168,
      "nombre": "Villalonga",
      "provincia": 1
  },
  "169": {
      "cgo": 169,
      "nombre": "G. Catan",
      "provincia": 1
  },
  "170": {
      "cgo": 170,
      "nombre": "Consc.Bernardi",
      "provincia": 1
  },
  "171": {
      "cgo": 171,
      "nombre": "Basavilbaso",
      "provincia": 3
  },
  "172": {
      "cgo": 172,
      "nombre": "Villa Minetti",
      "provincia": 6
  },
  "173": {
      "cgo": 173,
      "nombre": "Guardia Escolta",
      "provincia": 6
  },
  "174": {
      "cgo": 174,
      "nombre": "Sunchales",
      "provincia": 6
  },
  "175": {
      "cgo": 175,
      "nombre": "La Criolla",
      "provincia": 6
  },
  "176": {
      "cgo": 176,
      "nombre": "Tabossi",
      "provincia": 3
  },
  "177": {
      "cgo": 177,
      "nombre": "Los Molinos",
      "provincia": 2
  },
  "178": {
      "cgo": 178,
      "nombre": "La Carlota",
      "provincia": 2
  },
  "179": {
      "cgo": 179,
      "nombre": "Monte Grande",
      "provincia": 1
  },
  "180": {
      "cgo": 180,
      "nombre": "Ricardone",
      "provincia": 6
  },
  "181": {
      "cgo": 181,
      "nombre": "Morteros",
      "provincia": 2
  },
  "182": {
      "cgo": 182,
      "nombre": "Sgto. Cabral",
      "provincia": 6
  },
  "183": {
      "cgo": 183,
      "nombre": "Betreder",
      "provincia": 1
  },
  "184": {
      "cgo": 184,
      "nombre": "Arroyito",
      "provincia": 2
  },
  "185": {
      "cgo": 185,
      "nombre": "Villa Totoral",
      "provincia": 2
  },
  "186": {
      "cgo": 186,
      "nombre": "Juan Jorba",
      "provincia": 5
  },
  "187": {
      "cgo": 187,
      "nombre": "Oliveros (S.Fe)",
      "provincia": 6
  },
  "188": {
      "cgo": 188,
      "nombre": "Cañuelas",
      "provincia": 1
  },
  "189": {
      "cgo": 189,
      "nombre": "Gancedo",
      "provincia": 6
  },
  "190": {
      "cgo": 190,
      "nombre": "Sarah",
      "provincia": 4
  },
  "191": {
      "cgo": 191,
      "nombre": "San Francisco",
      "provincia": 2
  },
  "192": {
      "cgo": 192,
      "nombre": "Vilelas",
      "provincia": 6
  },
  "193": {
      "cgo": 193,
      "nombre": "Tio Pujio",
      "provincia": 6
  },
  "194": {
      "cgo": 194,
      "nombre": "El Fortin",
      "provincia": 2
  },
  "195": {
      "cgo": 195,
      "nombre": "Grunbein",
      "provincia": 1
  },
  "196": {
      "cgo": 196,
      "nombre": "Cañada De Gomez",
      "provincia": 6
  },
  "197": {
      "cgo": 197,
      "nombre": "Diaz (Sta. Fe)",
      "provincia": 6
  },
  "198": {
      "cgo": 198,
      "nombre": "Winifreda",
      "provincia": 4
  },
  "199": {
      "cgo": 199,
      "nombre": "Inriville",
      "provincia": 2
  },
  "200": {
      "cgo": 200,
      "nombre": "Cabred",
      "provincia": 2
  },
  "201": {
      "cgo": 201,
      "nombre": "Quequen-Nec-",
      "provincia": 1
  },
  "202": {
      "cgo": 202,
      "nombre": "Lujan",
      "provincia": 1
  },
  "203": {
      "cgo": 203,
      "nombre": "Roque Perez",
      "provincia": 2
  },
  "204": {
      "cgo": 204,
      "nombre": "Los Ralos",
      "provincia": 8
  },
  "205": {
      "cgo": 205,
      "nombre": "Chivilcoy",
      "provincia": 1
  },
  "206": {
      "cgo": 206,
      "nombre": "Santa Teresa",
      "provincia": 6
  },
  "207": {
      "cgo": 207,
      "nombre": "Tres Arroyos",
      "provincia": 1
  },
  "208": {
      "cgo": 208,
      "nombre": "San Jeronimo S",
      "provincia": 6
  },
  "209": {
      "cgo": 209,
      "nombre": "Ordoñez",
      "provincia": 2
  },
  "210": {
      "cgo": 210,
      "nombre": "Colon (E.Rios)",
      "provincia": 3
  },
  "211": {
      "cgo": 211,
      "nombre": "Casas",
      "provincia": 6
  },
  "212": {
      "cgo": 212,
      "nombre": "Realico",
      "provincia": 4
  },
  "213": {
      "cgo": 213,
      "nombre": "Moquehua",
      "provincia": 1
  },
  "214": {
      "cgo": 214,
      "nombre": "Pto.Galvan",
      "provincia": 1
  },
  "215": {
      "cgo": 215,
      "nombre": "Punta Alvear",
      "provincia": 6
  },
  "216": {
      "cgo": 216,
      "nombre": "Villa Eloisa",
      "provincia": 6
  },
  "217": {
      "cgo": 217,
      "nombre": "Bombal",
      "provincia": 6
  },
  "218": {
      "cgo": 218,
      "nombre": "Gral.Gelly",
      "provincia": 6
  },
  "219": {
      "cgo": 219,
      "nombre": "Colon (B.A.)",
      "provincia": 1
  },
  "220": {
      "cgo": 220,
      "nombre": "Monte Buey",
      "provincia": 2
  },
  "221": {
      "cgo": 221,
      "nombre": "Cañada Rosquin",
      "provincia": 6
  },
  "222": {
      "cgo": 222,
      "nombre": "Hughes",
      "provincia": 6
  },
  "223": {
      "cgo": 223,
      "nombre": "Casilda",
      "provincia": 6
  },
  "224": {
      "cgo": 224,
      "nombre": "Centeno",
      "provincia": 6
  },
  "225": {
      "cgo": 225,
      "nombre": "Fighiera",
      "provincia": 6
  },
  "226": {
      "cgo": 226,
      "nombre": "Miguel Torres",
      "provincia": 6
  },
  "227": {
      "cgo": 227,
      "nombre": "Maria Teresa",
      "provincia": 6
  },
  "228": {
      "cgo": 228,
      "nombre": "Las Rosas",
      "provincia": 6
  },
  "229": {
      "cgo": 229,
      "nombre": "La Cocha",
      "provincia": 8
  },
  "230": {
      "cgo": 230,
      "nombre": "Cañada Del Ucle",
      "provincia": 6
  },
  "231": {
      "cgo": 231,
      "nombre": "Guatimozin",
      "provincia": 2
  },
  "232": {
      "cgo": 232,
      "nombre": "Leones",
      "provincia": 2
  },
  "233": {
      "cgo": 233,
      "nombre": "Larrechea",
      "provincia": 6
  },
  "234": {
      "cgo": 234,
      "nombre": "Pasco",
      "provincia": 2
  },
  "235": {
      "cgo": 235,
      "nombre": "Villa Cañas",
      "provincia": 6
  },
  "236": {
      "cgo": 236,
      "nombre": "Berraondo",
      "provincia": 2
  },
  "237": {
      "cgo": 237,
      "nombre": "Rueda",
      "provincia": 6
  },
  "238": {
      "cgo": 238,
      "nombre": "Vedia",
      "provincia": 1
  },
  "239": {
      "cgo": 239,
      "nombre": "Calchin",
      "provincia": 2
  },
  "240": {
      "cgo": 240,
      "nombre": "San Marcos Sud",
      "provincia": 2
  },
  "241": {
      "cgo": 241,
      "nombre": "Perez Millan",
      "provincia": 1
  },
  "242": {
      "cgo": 242,
      "nombre": "Balnearia",
      "provincia": 2
  },
  "243": {
      "cgo": 243,
      "nombre": "Salto Grande",
      "provincia": 6
  },
  "244": {
      "cgo": 244,
      "nombre": "Lobos",
      "provincia": 1
  },
  "245": {
      "cgo": 245,
      "nombre": "Bell Ville",
      "provincia": 2
  },
  "246": {
      "cgo": 246,
      "nombre": "Arminda",
      "provincia": 6
  },
  "247": {
      "cgo": 247,
      "nombre": "Balnearia",
      "provincia": 2
  },
  "248": {
      "cgo": 248,
      "nombre": "Segui",
      "provincia": 3
  },
  "249": {
      "cgo": 249,
      "nombre": "Crespo(E.Rios)",
      "provincia": 3
  },
  "250": {
      "cgo": 250,
      "nombre": "Gral.Viamonte",
      "provincia": 1
  },
  "251": {
      "cgo": 251,
      "nombre": "Col.Josefina",
      "provincia": 2
  },
  "252": {
      "cgo": 252,
      "nombre": "Ceres(S.Fe)",
      "provincia": 6
  },
  "253": {
      "cgo": 253,
      "nombre": "Las Parejas-Sfe",
      "provincia": 6
  },
  "254": {
      "cgo": 254,
      "nombre": "La Plata-",
      "provincia": 1
  },
  "255": {
      "cgo": 255,
      "nombre": "Bella Vista",
      "provincia": 1
  },
  "256": {
      "cgo": 256,
      "nombre": "Posadas",
      "provincia": 12
  },
  "257": {
      "cgo": 257,
      "nombre": "Corrientes",
      "provincia": 10
  },
  "258": {
      "cgo": 258,
      "nombre": "Goya",
      "provincia": 10
  },
  "259": {
      "cgo": 259,
      "nombre": "S.Martin (Nid.)",
      "provincia": 6
  },
  "260": {
      "cgo": 260,
      "nombre": "S.Martin-B.Born",
      "provincia": 6
  },
  "261": {
      "cgo": 261,
      "nombre": "S.Martin(L.Pla)",
      "provincia": 6
  },
  "262": {
      "cgo": 262,
      "nombre": "S.Martin-Cargil",
      "provincia": 6
  },
  "263": {
      "cgo": 263,
      "nombre": "Dock Sud",
      "provincia": 1
  },
  "264": {
      "cgo": 264,
      "nombre": "Rosario-Ggarcia",
      "provincia": 6
  },
  "265": {
      "cgo": 265,
      "nombre": "Rosario-F.A.C.A",
      "provincia": 6
  },
  "266": {
      "cgo": 266,
      "nombre": "Metan",
      "provincia": 11
  },
  "267": {
      "cgo": 267,
      "nombre": "Clorinda",
      "provincia": 13
  },
  "268": {
      "cgo": 268,
      "nombre": "La Banda",
      "provincia": 7
  },
  "269": {
      "cgo": 269,
      "nombre": "La Quiaca",
      "provincia": 14
  },
  "270": {
      "cgo": 270,
      "nombre": "J.B.Alberdi",
      "provincia": 8
  },
  "271": {
      "cgo": 271,
      "nombre": "Chaco",
      "provincia": 9
  },
  "272": {
      "cgo": 272,
      "nombre": "Formosa",
      "provincia": 13
  },
  "273": {
      "cgo": 273,
      "nombre": "Jujuy",
      "provincia": 14
  },
  "274": {
      "cgo": 274,
      "nombre": "Salta",
      "provincia": 11
  },
  "275": {
      "cgo": 275,
      "nombre": "D.Gallo (Tuc)",
      "provincia": 8
  },
  "276": {
      "cgo": 276,
      "nombre": "Sgo.Del Estero",
      "provincia": 7
  },
  "277": {
      "cgo": 277,
      "nombre": "Ros.De Frontera",
      "provincia": 11
  },
  "278": {
      "cgo": 278,
      "nombre": "Salto",
      "provincia": 1
  },
  "279": {
      "cgo": 279,
      "nombre": "Berutti",
      "provincia": 2
  },
  "280": {
      "cgo": 280,
      "nombre": "P.De Guanacos",
      "provincia": 7
  },
  "281": {
      "cgo": 281,
      "nombre": "Guasayan-Catam.",
      "provincia": 1
  },
  "282": {
      "cgo": 282,
      "nombre": "Volta",
      "provincia": 1
  },
  "283": {
      "cgo": 283,
      "nombre": "Las Cejas(Tuc.)",
      "provincia": 8
  },
  "284": {
      "cgo": 284,
      "nombre": "Tostado",
      "provincia": 6
  },
  "285": {
      "cgo": 285,
      "nombre": "Parana",
      "provincia": 3
  },
  "286": {
      "cgo": 286,
      "nombre": "Isidro-Casanova",
      "provincia": 7
  },
  "287": {
      "cgo": 287,
      "nombre": "Rio Tercero-Cba",
      "provincia": 2
  },
  "288": {
      "cgo": 288,
      "nombre": "Holmberg (Cba.)",
      "provincia": 2
  },
  "289": {
      "cgo": 289,
      "nombre": "Las Varillas",
      "provincia": 2
  },
  "290": {
      "cgo": 290,
      "nombre": "Aranguren",
      "provincia": 3
  },
  "291": {
      "cgo": 291,
      "nombre": "Lag. Larga-Cba.",
      "provincia": 2
  },
  "292": {
      "cgo": 292,
      "nombre": "Tosquita-Cba.",
      "provincia": 2
  },
  "293": {
      "cgo": 293,
      "nombre": "Colazo-Cba",
      "provincia": 2
  },
  "294": {
      "cgo": 294,
      "nombre": "Las Junturas",
      "provincia": 2
  },
  "295": {
      "cgo": 295,
      "nombre": "Ballesteros",
      "provincia": 2
  },
  "296": {
      "cgo": 296,
      "nombre": "Ucacha",
      "provincia": 2
  },
  "297": {
      "cgo": 297,
      "nombre": "Sauce Pintos",
      "provincia": 3
  },
  "298": {
      "cgo": 298,
      "nombre": "Villa Ramallo",
      "provincia": 1
  },
  "299": {
      "cgo": 299,
      "nombre": "Roversi",
      "provincia": 7
  },
  "300": {
      "cgo": 300,
      "nombre": "Matheu",
      "provincia": 1
  },
  "301": {
      "cgo": 301,
      "nombre": "Maximo Paz",
      "provincia": 6
  },
  "302": {
      "cgo": 302,
      "nombre": "Las Garzas",
      "provincia": 3
  },
  "303": {
      "cgo": 303,
      "nombre": "Gral.Roca(Cba)",
      "provincia": 2
  },
  "304": {
      "cgo": 304,
      "nombre": "Maguirre",
      "provincia": 1
  },
  "305": {
      "cgo": 305,
      "nombre": "Villa Fontana",
      "provincia": 3
  },
  "306": {
      "cgo": 306,
      "nombre": "Los Toldos",
      "provincia": 1
  },
  "307": {
      "cgo": 307,
      "nombre": "V.Gdor.Galvez",
      "provincia": 6
  },
  "308": {
      "cgo": 308,
      "nombre": "Lomas De Zamora",
      "provincia": 1
  },
  "309": {
      "cgo": 309,
      "nombre": "Pavon Arriba",
      "provincia": 6
  },
  "310": {
      "cgo": 310,
      "nombre": "San Genaro",
      "provincia": 6
  },
  "311": {
      "cgo": 311,
      "nombre": "Santo Domingo",
      "provincia": 6
  },
  "312": {
      "cgo": 312,
      "nombre": "Colegiales",
      "provincia": 1
  },
  "313": {
      "cgo": 313,
      "nombre": "San Martin Bas",
      "provincia": 1
  },
  "314": {
      "cgo": 314,
      "nombre": "Daireaux",
      "provincia": 1
  },
  "315": {
      "cgo": 315,
      "nombre": "Eduardo Castex",
      "provincia": 1
  },
  "316": {
      "cgo": 316,
      "nombre": "Cptan.Sarmiento",
      "provincia": 1
  },
  "317": {
      "cgo": 317,
      "nombre": "Villa Diego",
      "provincia": 6
  },
  "318": {
      "cgo": 318,
      "nombre": "Catrilo",
      "provincia": 1
  },
  "319": {
      "cgo": 319,
      "nombre": "Choele Choel",
      "provincia": 1
  },
  "320": {
      "cgo": 320,
      "nombre": "Los Surgentes",
      "provincia": 2
  },
  "321": {
      "cgo": 321,
      "nombre": "G.Lagos/Timbues",
      "provincia": 6
  },
  "322": {
      "cgo": 322,
      "nombre": "Timbues",
      "provincia": 6
  },
  "323": {
      "cgo": 323,
      "nombre": "Aldao(Fc.Mitre)",
      "provincia": 1
  },
  "324": {
      "cgo": 324,
      "nombre": "L.Gonzalez",
      "provincia": 2
  },
  "325": {
      "cgo": 325,
      "nombre": "25 De Mayo",
      "provincia": 1
  },
  "326": {
      "cgo": 326,
      "nombre": "Benito Juarez",
      "provincia": 1
  },
  "327": {
      "cgo": 327,
      "nombre": "Otumpa",
      "provincia": 7
  },
  "328": {
      "cgo": 328,
      "nombre": "Cerana",
      "provincia": 6
  },
  "329": {
      "cgo": 329,
      "nombre": "Rincon Nogoya",
      "provincia": 3
  },
  "330": {
      "cgo": 330,
      "nombre": "Buena Esperanza",
      "provincia": 2
  },
  "331": {
      "cgo": 331,
      "nombre": "Ind. Aceitera",
      "provincia": 6
  },
  "332": {
      "cgo": 332,
      "nombre": "Villa Huidobro",
      "provincia": 2
  },
  "333": {
      "cgo": 333,
      "nombre": "Christophersen",
      "provincia": 2
  },
  "334": {
      "cgo": 334,
      "nombre": "Irazusta (E.R)",
      "provincia": 7
  },
  "335": {
      "cgo": 335,
      "nombre": "Noetinger (Cba)",
      "provincia": 2
  },
  "336": {
      "cgo": 336,
      "nombre": "Sanford (Sfe)",
      "provincia": 6
  },
  "337": {
      "cgo": 337,
      "nombre": "W.Escalante",
      "provincia": 2
  },
  "338": {
      "cgo": 338,
      "nombre": "Ramirez",
      "provincia": 3
  },
  "339": {
      "cgo": 339,
      "nombre": "Stroeder",
      "provincia": 1
  },
  "340": {
      "cgo": 340,
      "nombre": "El Divisorio",
      "provincia": 1
  },
  "341": {
      "cgo": 341,
      "nombre": "Villada",
      "provincia": 6
  },
  "342": {
      "cgo": 342,
      "nombre": "Clason",
      "provincia": 6
  },
  "343": {
      "cgo": 343,
      "nombre": "Carmen (Sf)",
      "provincia": 6
  },
  "344": {
      "cgo": 344,
      "nombre": "Bigand",
      "provincia": 6
  },
  "345": {
      "cgo": 345,
      "nombre": "C.De Bustos",
      "provincia": 2
  },
  "346": {
      "cgo": 346,
      "nombre": "Mendoza",
      "provincia": 20
  },
  "347": {
      "cgo": 347,
      "nombre": "Firmat",
      "provincia": 6
  },
  "348": {
      "cgo": 348,
      "nombre": "La Vanguardia",
      "provincia": 6
  },
  "349": {
      "cgo": 349,
      "nombre": "Gral. Pico",
      "provincia": 4
  },
  "350": {
      "cgo": 350,
      "nombre": "Arroyo Seco",
      "provincia": 6
  },
  "351": {
      "cgo": 351,
      "nombre": "Estacion Pino",
      "provincia": 6
  },
  "352": {
      "cgo": 352,
      "nombre": "Justin. Posse",
      "provincia": 6
  },
  "353": {
      "cgo": 353,
      "nombre": "Pearson",
      "provincia": 1
  },
  "354": {
      "cgo": 354,
      "nombre": "Monje",
      "provincia": 6
  },
  "355": {
      "cgo": 355,
      "nombre": "R.Saenz Peña",
      "provincia": 9
  },
  "356": {
      "cgo": 356,
      "nombre": "Azul",
      "provincia": 1
  },
  "357": {
      "cgo": 357,
      "nombre": "3 Sargentos",
      "provincia": 1
  },
  "358": {
      "cgo": 358,
      "nombre": "S.M/Ros/S.Loren",
      "provincia": 6
  },
  "359": {
      "cgo": 359,
      "nombre": "Gral.Lagos",
      "provincia": 6
  },
  "360": {
      "cgo": 360,
      "nombre": "Amenabar",
      "provincia": 6
  },
  "361": {
      "cgo": 361,
      "nombre": "Punilla",
      "provincia": 5
  },
  "362": {
      "cgo": 362,
      "nombre": "Navarro",
      "provincia": 1
  },
  "363": {
      "cgo": 363,
      "nombre": "El Trebol",
      "provincia": 6
  },
  "364": {
      "cgo": 364,
      "nombre": "Lincoln",
      "provincia": 1
  },
  "365": {
      "cgo": 365,
      "nombre": "Ros.De La Front",
      "provincia": 11
  },
  "366": {
      "cgo": 366,
      "nombre": "Melincue",
      "provincia": 6
  },
  "367": {
      "cgo": 367,
      "nombre": "Resistencia",
      "provincia": 9
  },
  "368": {
      "cgo": 368,
      "nombre": "Hersilia",
      "provincia": 6
  },
  "369": {
      "cgo": 369,
      "nombre": "Ameghino",
      "provincia": 1
  },
  "370": {
      "cgo": 370,
      "nombre": "Cruz Alta",
      "provincia": 2
  },
  "371": {
      "cgo": 371,
      "nombre": "Jovita",
      "provincia": 2
  },
  "372": {
      "cgo": 372,
      "nombre": "S.Fr.De Bellock",
      "provincia": 1
  },
  "373": {
      "cgo": 373,
      "nombre": "San Mayol",
      "provincia": 1
  },
  "374": {
      "cgo": 374,
      "nombre": "Oncativo",
      "provincia": 2
  },
  "375": {
      "cgo": 375,
      "nombre": "Manfredi",
      "provincia": 2
  },
  "376": {
      "cgo": 376,
      "nombre": "Monte Maiz",
      "provincia": 2
  },
  "377": {
      "cgo": 377,
      "nombre": "Arias",
      "provincia": 2
  },
  "378": {
      "cgo": 378,
      "nombre": "Carlos Casares",
      "provincia": 1
  },
  "379": {
      "cgo": 379,
      "nombre": "Dique Iii",
      "provincia": 1
  },
  "380": {
      "cgo": 380,
      "nombre": "Hernandarias",
      "provincia": 3
  },
  "381": {
      "cgo": 381,
      "nombre": "Irala",
      "provincia": 2
  },
  "382": {
      "cgo": 382,
      "nombre": "Roldan",
      "provincia": 6
  },
  "383": {
      "cgo": 383,
      "nombre": "Alcorta",
      "provincia": 6
  },
  "384": {
      "cgo": 384,
      "nombre": "Justo Daract",
      "provincia": 5
  },
  "385": {
      "cgo": 385,
      "nombre": "Perez(Sfe)",
      "provincia": 6
  },
  "386": {
      "cgo": 386,
      "nombre": "Camilo Aldao",
      "provincia": 2
  },
  "387": {
      "cgo": 387,
      "nombre": "Corral D.Bustos",
      "provincia": 2
  },
  "388": {
      "cgo": 388,
      "nombre": "Cap.Bermudez",
      "provincia": 6
  },
  "389": {
      "cgo": 389,
      "nombre": "Pje.El Crisol",
      "provincia": 1
  },
  "390": {
      "cgo": 390,
      "nombre": "Ros. P/Chabas",
      "provincia": 6
  },
  "391": {
      "cgo": 391,
      "nombre": "Pozo Del Molle",
      "provincia": 2
  },
  "392": {
      "cgo": 392,
      "nombre": "Vagues",
      "provincia": 1
  },
  "393": {
      "cgo": 393,
      "nombre": "E.Echeverria",
      "provincia": 1
  },
  "394": {
      "cgo": 394,
      "nombre": "Gr.Baigorria",
      "provincia": 6
  },
  "395": {
      "cgo": 395,
      "nombre": "Arequito",
      "provincia": 6
  },
  "396": {
      "cgo": 396,
      "nombre": "Isla Verde",
      "provincia": 2
  },
  "397": {
      "cgo": 397,
      "nombre": "Cafferata",
      "provincia": 6
  },
  "398": {
      "cgo": 398,
      "nombre": "Caballito",
      "provincia": 100
  },
  "399": {
      "cgo": 399,
      "nombre": "Martinez De Hoz",
      "provincia": 1
  },
  "400": {
      "cgo": 400,
      "nombre": "San Isidro",
      "provincia": 1
  },
  "401": {
      "cgo": 401,
      "nombre": "Lanus",
      "provincia": 1
  },
  "402": {
      "cgo": 402,
      "nombre": "El Socorro",
      "provincia": 1
  },
  "403": {
      "cgo": 403,
      "nombre": "Chanar Ladeado",
      "provincia": 6
  },
  "404": {
      "cgo": 404,
      "nombre": "Lules",
      "provincia": 8
  },
  "405": {
      "cgo": 405,
      "nombre": "Funes",
      "provincia": 6
  },
  "406": {
      "cgo": 406,
      "nombre": "Albarellos",
      "provincia": 6
  },
  "407": {
      "cgo": 407,
      "nombre": "Villegas",
      "provincia": 1
  },
  "408": {
      "cgo": 408,
      "nombre": "Alejo Ledesma",
      "provincia": 200
  },
  "409": {
      "cgo": 409,
      "nombre": "Gral Rodriguez",
      "provincia": 2
  },
  "410": {
      "cgo": 410,
      "nombre": "Berazategui",
      "provincia": 2
  },
  "411": {
      "cgo": 411,
      "nombre": "Glew",
      "provincia": 1
  },
  "412": {
      "cgo": 412,
      "nombre": "Mercedes (Ba)",
      "provincia": 1
  },
  "413": {
      "cgo": 413,
      "nombre": "Cnel.Brandsen",
      "provincia": 1
  },
  "414": {
      "cgo": 414,
      "nombre": "Morrison",
      "provincia": 2
  },
  "415": {
      "cgo": 415,
      "nombre": "Pigue",
      "provincia": 1
  },
  "416": {
      "cgo": 416,
      "nombre": "Pehuajo",
      "provincia": 1
  },
  "417": {
      "cgo": 417,
      "nombre": "Cnel.Suarez",
      "provincia": 1
  },
  "418": {
      "cgo": 418,
      "nombre": "Huanguelen",
      "provincia": 1
  },
  "419": {
      "cgo": 419,
      "nombre": "Arteaga",
      "provincia": 6
  },
  "420": {
      "cgo": 420,
      "nombre": "Trenque Lauquen",
      "provincia": 1
  },
  "421": {
      "cgo": 421,
      "nombre": "Marcos Paz(Ba)",
      "provincia": 1
  },
  "422": {
      "cgo": 422,
      "nombre": "Monte (Ba)",
      "provincia": 1
  },
  "423": {
      "cgo": 423,
      "nombre": "Villa Vaudagna",
      "provincia": 2
  },
  "424": {
      "cgo": 424,
      "nombre": "Tres Esquinas",
      "provincia": 10
  },
  "425": {
      "cgo": 425,
      "nombre": "Cura-Malal",
      "provincia": 1
  },
  "426": {
      "cgo": 426,
      "nombre": "Lima(Bs.As)",
      "provincia": 1
  },
  "427": {
      "cgo": 427,
      "nombre": "Alta Italia(Lp)",
      "provincia": 4
  },
  "428": {
      "cgo": 428,
      "nombre": "Simson",
      "provincia": 4
  },
  "429": {
      "cgo": 429,
      "nombre": "Goyena",
      "provincia": 1
  },
  "430": {
      "cgo": 430,
      "nombre": "Rancul",
      "provincia": 400
  },
  "431": {
      "cgo": 431,
      "nombre": "Pueblo Munoz",
      "provincia": 6
  },
  "432": {
      "cgo": 432,
      "nombre": "San M.Del Monte",
      "provincia": 1
  },
  "433": {
      "cgo": 433,
      "nombre": "D Orbigny",
      "provincia": 1
  },
  "434": {
      "cgo": 434,
      "nombre": "Ing.Thompson",
      "provincia": 1
  },
  "435": {
      "cgo": 435,
      "nombre": "La Colina(Ba)",
      "provincia": 1
  },
  "436": {
      "cgo": 436,
      "nombre": "La Ramada Abajo",
      "provincia": 8
  },
  "437": {
      "cgo": 437,
      "nombre": "Pto.Uruguay(Er)",
      "provincia": 3
  },
  "438": {
      "cgo": 438,
      "nombre": "Los Cardales",
      "provincia": 1
  },
  "439": {
      "cgo": 439,
      "nombre": "Tres Lomas",
      "provincia": 1
  },
  "440": {
      "cgo": 440,
      "nombre": "San Jeronimo N.",
      "provincia": 6
  },
  "441": {
      "cgo": 441,
      "nombre": "Gral.Paz(Cba)",
      "provincia": 2
  },
  "442": {
      "cgo": 442,
      "nombre": "Villa Elisa",
      "provincia": 3
  },
  "443": {
      "cgo": 443,
      "nombre": "Sacanta",
      "provincia": 2
  },
  "444": {
      "cgo": 444,
      "nombre": "Gral O Higgins",
      "provincia": 2
  },
  "445": {
      "cgo": 445,
      "nombre": "Tres Algarrobos",
      "provincia": 1
  },
  "446": {
      "cgo": 446,
      "nombre": "Piquete Cabado",
      "provincia": 11
  },
  "447": {
      "cgo": 447,
      "nombre": "Ros.P/Tancacha",
      "provincia": 2
  },
  "448": {
      "cgo": 448,
      "nombre": "Cuenca *Ba*",
      "provincia": 2
  },
  "449": {
      "cgo": 449,
      "nombre": "Las Perdices",
      "provincia": 2
  },
  "450": {
      "cgo": 450,
      "nombre": "Armstrong",
      "provincia": 6
  },
  "451": {
      "cgo": 451,
      "nombre": "T.Emb.Vicentin",
      "provincia": 6
  },
  "452": {
      "cgo": 452,
      "nombre": "Ros.P/G.Deheza",
      "provincia": 2
  },
  "453": {
      "cgo": 453,
      "nombre": "S.Fernando(Ba)",
      "provincia": 100
  },
  "454": {
      "cgo": 454,
      "nombre": "Bragado",
      "provincia": 1
  },
  "455": {
      "cgo": 455,
      "nombre": "Paso Libres",
      "provincia": 10
  },
  "456": {
      "cgo": 456,
      "nombre": "Las Vertientes",
      "provincia": 2
  },
  "457": {
      "cgo": 457,
      "nombre": "Urdinarrain",
      "provincia": 3
  },
  "458": {
      "cgo": 458,
      "nombre": "Pincen (Cba)",
      "provincia": 2
  },
  "459": {
      "cgo": 459,
      "nombre": "San Carlos",
      "provincia": 6
  },
  "460": {
      "cgo": 460,
      "nombre": "Burzaco",
      "provincia": 1
  },
  "461": {
      "cgo": 461,
      "nombre": "Bulnes",
      "provincia": 2
  },
  "462": {
      "cgo": 462,
      "nombre": "Ochandio",
      "provincia": 1
  },
  "463": {
      "cgo": 463,
      "nombre": "San Manuel (Ba)",
      "provincia": 2
  },
  "464": {
      "cgo": 464,
      "nombre": "Sta Clara Ros.",
      "provincia": 1
  },
  "465": {
      "cgo": 465,
      "nombre": "S.Martin",
      "provincia": 6
  },
  "466": {
      "cgo": 466,
      "nombre": "S.Martin/S Lzo.",
      "provincia": 6
  },
  "467": {
      "cgo": 467,
      "nombre": "Bonifacio (Ba)",
      "provincia": 1
  },
  "468": {
      "cgo": 468,
      "nombre": "Del Campillo",
      "provincia": 2
  },
  "469": {
      "cgo": 469,
      "nombre": "Casbas (Ba)",
      "provincia": 1
  },
  "470": {
      "cgo": 470,
      "nombre": "Oriente",
      "provincia": 1
  },
  "471": {
      "cgo": 471,
      "nombre": "Brugo",
      "provincia": 3
  },
  "472": {
      "cgo": 472,
      "nombre": "Villa Amelia",
      "provincia": 6
  },
  "473": {
      "cgo": 473,
      "nombre": "Puan",
      "provincia": 1
  },
  "474": {
      "cgo": 474,
      "nombre": "Macia",
      "provincia": 3
  },
  "475": {
      "cgo": 475,
      "nombre": "Hernando",
      "provincia": 2
  },
  "476": {
      "cgo": 476,
      "nombre": "Rivera",
      "provincia": 1
  },
  "477": {
      "cgo": 477,
      "nombre": "Tucuman",
      "provincia": 8
  },
  "478": {
      "cgo": 478,
      "nombre": "Bosques",
      "provincia": 1
  },
  "479": {
      "cgo": 479,
      "nombre": "Landeta",
      "provincia": 6
  },
  "480": {
      "cgo": 480,
      "nombre": "Rafael Garcia",
      "provincia": 2
  },
  "481": {
      "cgo": 481,
      "nombre": "O-Higgins",
      "provincia": 1
  },
  "482": {
      "cgo": 482,
      "nombre": "America",
      "provincia": 1
  },
  "483": {
      "cgo": 483,
      "nombre": "Arribeños",
      "provincia": 1
  },
  "484": {
      "cgo": 484,
      "nombre": "Lunlunta",
      "provincia": 19
  },
  "485": {
      "cgo": 485,
      "nombre": "San Salvador",
      "provincia": 3
  },
  "486": {
      "cgo": 486,
      "nombre": "Guaymallen",
      "provincia": 19
  },
  "487": {
      "cgo": 487,
      "nombre": "Zavalla",
      "provincia": 6
  },
  "488": {
      "cgo": 488,
      "nombre": "S.J.De Felician",
      "provincia": 3
  },
  "489": {
      "cgo": 489,
      "nombre": "Los Altos",
      "provincia": 20
  },
  "490": {
      "cgo": 490,
      "nombre": "Puerto Buey",
      "provincia": 3
  },
  "491": {
      "cgo": 491,
      "nombre": "Gral Villegas",
      "provincia": 1
  },
  "492": {
      "cgo": 492,
      "nombre": "Godeken",
      "provincia": 6
  },
  "493": {
      "cgo": 493,
      "nombre": "Humboldt",
      "provincia": 6
  },
  "494": {
      "cgo": 494,
      "nombre": "Villa Dolores",
      "provincia": 1
  },
  "495": {
      "cgo": 495,
      "nombre": "Uranga",
      "provincia": 6
  },
  "496": {
      "cgo": 496,
      "nombre": "Col.El Fiscal",
      "provincia": 9
  },
  "497": {
      "cgo": 497,
      "nombre": "Cargill Quequen",
      "provincia": 1
  },
  "498": {
      "cgo": 498,
      "nombre": "Neco Sitio 0",
      "provincia": 1
  },
  "499": {
      "cgo": 499,
      "nombre": "Aca Necochea",
      "provincia": 1
  },
  "500": {
      "cgo": 500,
      "nombre": "Sinsacate",
      "provincia": 2
  },
  "501": {
      "cgo": 501,
      "nombre": "Complejo Apg",
      "provincia": 2
  },
  "502": {
      "cgo": 502,
      "nombre": "Playa S.Miguel",
      "provincia": 6
  },
  "503": {
      "cgo": 503,
      "nombre": "Anguil",
      "provincia": 4
  },
  "504": {
      "cgo": 504,
      "nombre": "Fraga",
      "provincia": 5
  },
  "505": {
      "cgo": 505,
      "nombre": "Mercedes",
      "provincia": 10
  },
  "506": {
      "cgo": 506,
      "nombre": "S.C.De Saguier",
      "provincia": 6
  },
  "507": {
      "cgo": 507,
      "nombre": "Las Lomitas",
      "provincia": 13
  },
  "508": {
      "cgo": 508,
      "nombre": "Selva",
      "provincia": 8
  },
  "509": {
      "cgo": 509,
      "nombre": "C.Pellegrini",
      "provincia": 6
  },
  "510": {
      "cgo": 510,
      "nombre": "B. Irigoyen",
      "provincia": 6
  },
  "511": {
      "cgo": 511,
      "nombre": "Los Charruas",
      "provincia": 3
  },
  "512": {
      "cgo": 512,
      "nombre": "Bandera",
      "provincia": 7
  },
  "513": {
      "cgo": 513,
      "nombre": "Theobald",
      "provincia": 6
  },
  "515": {
      "cgo": 515,
      "nombre": "San Jose (E.Rio",
      "provincia": 3
  },
  "516": {
      "cgo": 516,
      "nombre": "Carrilobo",
      "provincia": 2
  },
  "517": {
      "cgo": 517,
      "nombre": "Sastre",
      "provincia": 6
  },
  "520": {
      "cgo": 520,
      "nombre": "Gral.Cabrera",
      "provincia": 2
  },
  "521": {
      "cgo": 521,
      "nombre": "Naschel",
      "provincia": 5
  },
  "522": {
      "cgo": 522,
      "nombre": "Freyre",
      "provincia": 5
  },
  "523": {
      "cgo": 523,
      "nombre": "Pozo Hondo",
      "provincia": 500
  },
  "524": {
      "cgo": 524,
      "nombre": "Sarmiento",
      "provincia": 6
  },
  "525": {
      "cgo": 525,
      "nombre": "Antillas",
      "provincia": 8
  },
  "526": {
      "cgo": 526,
      "nombre": "Pto.Zarate",
      "provincia": 1
  },
  "530": {
      "cgo": 530,
      "nombre": "Zarate",
      "provincia": 1
  },
  "531": {
      "cgo": 531,
      "nombre": "La Palmira",
      "provincia": 500
  },
  "532": {
      "cgo": 532,
      "nombre": "Cap.Del Señor",
      "provincia": 1
  },
  "536": {
      "cgo": 536,
      "nombre": "Sachayoj",
      "provincia": 7
  },
  "540": {
      "cgo": 540,
      "nombre": "Villa Lia",
      "provincia": 1
  },
  "550": {
      "cgo": 550,
      "nombre": "Clarke",
      "provincia": 6
  },
  "551": {
      "cgo": 551,
      "nombre": "Term.Las Palmas",
      "provincia": 1
  },
  "552": {
      "cgo": 552,
      "nombre": "Eusebia",
      "provincia": 6
  },
  "555": {
      "cgo": 555,
      "nombre": "V.Paranacito",
      "provincia": 3
  },
  "556": {
      "cgo": 556,
      "nombre": "Nonogasta",
      "provincia": 21
  },
  "557": {
      "cgo": 557,
      "nombre": "Col. Tirolesa",
      "provincia": 2
  },
  "558": {
      "cgo": 558,
      "nombre": "Cañada De Luque",
      "provincia": 2
  },
  "559": {
      "cgo": 559,
      "nombre": "Lozada",
      "provincia": 2
  },
  "560": {
      "cgo": 560,
      "nombre": "Chajan",
      "provincia": 2
  },
  "561": {
      "cgo": 561,
      "nombre": "Burruyacu",
      "provincia": 8
  },
  "562": {
      "cgo": 562,
      "nombre": "Reduccion",
      "provincia": 2
  },
  "563": {
      "cgo": 563,
      "nombre": "Manuel Ocampo",
      "provincia": 1
  },
  "565": {
      "cgo": 565,
      "nombre": "9 De Julio",
      "provincia": 1
  },
  "567": {
      "cgo": 567,
      "nombre": "S.Mart./Alv.",
      "provincia": 6
  },
  "569": {
      "cgo": 569,
      "nombre": "Chilecito",
      "provincia": 21
  },
  "570": {
      "cgo": 570,
      "nombre": "P.De Los Libres",
      "provincia": 10
  },
  "571": {
      "cgo": 571,
      "nombre": "Arrufo",
      "provincia": 6
  },
  "572": {
      "cgo": 572,
      "nombre": "S.Martin/A.Seco",
      "provincia": 6
  },
  "573": {
      "cgo": 573,
      "nombre": "S.M/Ros/A.Seco",
      "provincia": 6
  },
  "574": {
      "cgo": 574,
      "nombre": "Las Arrias",
      "provincia": 2
  },
  "575": {
      "cgo": 575,
      "nombre": "Pilar",
      "provincia": 6
  },
  "576": {
      "cgo": 576,
      "nombre": "S.Martin-Cargil",
      "provincia": 6
  },
  "577": {
      "cgo": 577,
      "nombre": "Pajonal",
      "provincia": 3
  },
  "578": {
      "cgo": 578,
      "nombre": "Costa Grande",
      "provincia": 3
  },
  "579": {
      "cgo": 579,
      "nombre": "Espinillo",
      "provincia": 3
  },
  "580": {
      "cgo": 580,
      "nombre": "El Pingo",
      "provincia": 3
  },
  "581": {
      "cgo": 581,
      "nombre": "Villa Urquiza",
      "provincia": 3
  },
  "582": {
      "cgo": 582,
      "nombre": "San Guillermo",
      "provincia": 6
  },
  "583": {
      "cgo": 583,
      "nombre": "Ing.Balbin",
      "provincia": 1
  },
  "584": {
      "cgo": 584,
      "nombre": "Las Palmas",
      "provincia": 1
  },
  "585": {
      "cgo": 585,
      "nombre": "Pinedo",
      "provincia": 9
  },
  "586": {
      "cgo": 586,
      "nombre": "S.J.D.L Dormida",
      "provincia": 2
  },
  "587": {
      "cgo": 587,
      "nombre": "Colonia Caroya",
      "provincia": 2
  },
  "588": {
      "cgo": 588,
      "nombre": "S.R.Rio Primero",
      "provincia": 2
  },
  "589": {
      "cgo": 589,
      "nombre": "Rio Primero",
      "provincia": 2
  },
  "590": {
      "cgo": 590,
      "nombre": "Matorrales",
      "provincia": 2
  },
  "591": {
      "cgo": 591,
      "nombre": "S.Martin/S.Gena",
      "provincia": 6
  },
  "592": {
      "cgo": 592,
      "nombre": "Los Pereyra",
      "provincia": 8
  },
  "593": {
      "cgo": 593,
      "nombre": "Frias",
      "provincia": 7
  },
  "594": {
      "cgo": 594,
      "nombre": "Las Palmitas",
      "provincia": 20
  },
  "595": {
      "cgo": 595,
      "nombre": "Juarez Celman",
      "provincia": 2
  },
  "596": {
      "cgo": 596,
      "nombre": "Soldini",
      "provincia": 6
  },
  "597": {
      "cgo": 597,
      "nombre": "Las Albahacas",
      "provincia": 2
  },
  "598": {
      "cgo": 598,
      "nombre": "Fontezuela",
      "provincia": 1
  },
  "599": {
      "cgo": 599,
      "nombre": "Antilla",
      "provincia": 11
  },
  "600": {
      "cgo": 600,
      "nombre": "S.Lorenzo-Curci",
      "provincia": 6
  },
  "601": {
      "cgo": 601,
      "nombre": "Villa Elisa",
      "provincia": 3
  },
  "602": {
      "cgo": 602,
      "nombre": "Cnel.Mollinedo",
      "provincia": 11
  },
  "603": {
      "cgo": 603,
      "nombre": "Los Mistoles",
      "provincia": 2
  },
  "604": {
      "cgo": 604,
      "nombre": "Hermoso Campo",
      "provincia": 9
  },
  "605": {
      "cgo": 605,
      "nombre": "Villa De Maria",
      "provincia": 2
  },
  "606": {
      "cgo": 606,
      "nombre": "Granville",
      "provincia": 5
  },
  "607": {
      "cgo": 607,
      "nombre": "La Porteña",
      "provincia": 1
  },
  "608": {
      "cgo": 608,
      "nombre": "La Palmira (Mz)",
      "provincia": 20
  },
  "609": {
      "cgo": 609,
      "nombre": "Tortuguitas",
      "provincia": 1
  },
  "610": {
      "cgo": 610,
      "nombre": "San Agustin",
      "provincia": 2
  },
  "611": {
      "cgo": 611,
      "nombre": "Gral.Baldissera",
      "provincia": 2
  },
  "612": {
      "cgo": 612,
      "nombre": "Avia Terai",
      "provincia": 9
  },
  "613": {
      "cgo": 613,
      "nombre": "Palmira",
      "provincia": 5
  },
  "614": {
      "cgo": 614,
      "nombre": "Timbues/G.Lagos",
      "provincia": 6
  },
  "615": {
      "cgo": 615,
      "nombre": "Federal",
      "provincia": 3
  },
  "616": {
      "cgo": 616,
      "nombre": "Bustinza",
      "provincia": 6
  },
  "617": {
      "cgo": 617,
      "nombre": "Aldao",
      "provincia": 6
  },
  "618": {
      "cgo": 618,
      "nombre": "Soven",
      "provincia": 5
  },
  "619": {
      "cgo": 619,
      "nombre": "Solis",
      "provincia": 1
  },
  "620": {
      "cgo": 620,
      "nombre": "La Toma",
      "provincia": 5
  },
  "621": {
      "cgo": 621,
      "nombre": "Vinal Pozo",
      "provincia": 7
  },
  "625": {
      "cgo": 625,
      "nombre": "S.Martin/Labord",
      "provincia": 6
  },
  "628": {
      "cgo": 628,
      "nombre": "Totoras",
      "provincia": 6
  },
  "630": {
      "cgo": 630,
      "nombre": "Arroyo Seco Gea",
      "provincia": 6
  },
  "632": {
      "cgo": 632,
      "nombre": "Las Lajitas",
      "provincia": 11
  },
  "640": {
      "cgo": 640,
      "nombre": "Correa",
      "provincia": 6
  },
  "650": {
      "cgo": 650,
      "nombre": "Cnel.Arnold",
      "provincia": 6
  },
  "656": {
      "cgo": 656,
      "nombre": "Hilario Lagos",
      "provincia": 4
  },
  "660": {
      "cgo": 660,
      "nombre": "San Martin(Mza)",
      "provincia": 20
  },
  "661": {
      "cgo": 661,
      "nombre": "S.M/Ros./Lagos",
      "provincia": 6
  },
  "662": {
      "cgo": 662,
      "nombre": "Los Frentones",
      "provincia": 9
  },
  "663": {
      "cgo": 663,
      "nombre": "Piedrabuena",
      "provincia": 8
  },
  "666": {
      "cgo": 666,
      "nombre": "Jimenez (Stgo)",
      "provincia": 7
  },
  "670": {
      "cgo": 670,
      "nombre": "Palmira (Mza)",
      "provincia": 20
  },
  "680": {
      "cgo": 680,
      "nombre": "Mariano Benitez",
      "provincia": 1
  },
  "681": {
      "cgo": 681,
      "nombre": "Rapelli",
      "provincia": 7
  },
  "682": {
      "cgo": 682,
      "nombre": "S. Lorenzo Gear",
      "provincia": 6
  },
  "683": {
      "cgo": 683,
      "nombre": "S.Lorenzo Curci",
      "provincia": 6
  },
  "684": {
      "cgo": 684,
      "nombre": "S.Lorenzo Mrp",
      "provincia": 6
  },
  "685": {
      "cgo": 685,
      "nombre": "S.Lorenzo Afa",
      "provincia": 6
  },
  "686": {
      "cgo": 686,
      "nombre": "Bouquet",
      "provincia": 6
  },
  "687": {
      "cgo": 687,
      "nombre": "Rosar/Arroyo S.",
      "provincia": 6
  },
  "689": {
      "cgo": 689,
      "nombre": "Cnel.Granada",
      "provincia": 1
  },
  "690": {
      "cgo": 690,
      "nombre": "Las Petacas",
      "provincia": 6
  },
  "695": {
      "cgo": 695,
      "nombre": "Arroy Hsta Timb",
      "provincia": 6
  },
  "700": {
      "cgo": 700,
      "nombre": "Chazon",
      "provincia": 2
  },
  "701": {
      "cgo": 701,
      "nombre": "Brandsen",
      "provincia": 1
  },
  "702": {
      "cgo": 702,
      "nombre": "Renova (Tr/Mz)",
      "provincia": 1
  },
  "705": {
      "cgo": 705,
      "nombre": "Sarmiento (Cba)",
      "provincia": 2
  },
  "709": {
      "cgo": 709,
      "nombre": "Timbues (Ldc)",
      "provincia": 6
  },
  "710": {
      "cgo": 710,
      "nombre": "Timbues (Cofco)",
      "provincia": 6
  },
  "711": {
      "cgo": 711,
      "nombre": "V. Del Totoral",
      "provincia": 2
  },
  "712": {
      "cgo": 712,
      "nombre": "Maggiolo",
      "provincia": 6
  },
  "715": {
      "cgo": 715,
      "nombre": "Rosario S.Clara",
      "provincia": 6
  },
  "720": {
      "cgo": 720,
      "nombre": "S.Martin/Lagos",
      "provincia": 6
  },
  "721": {
      "cgo": 721,
      "nombre": "Beltran Sgo Est",
      "provincia": 7
  },
  "722": {
      "cgo": 722,
      "nombre": "Aldea M Luisa",
      "provincia": 3
  },
  "730": {
      "cgo": 730,
      "nombre": "Rawson",
      "provincia": 2
  },
  "731": {
      "cgo": 731,
      "nombre": "Gowland",
      "provincia": 2
  },
  "750": {
      "cgo": 750,
      "nombre": "Cevil Pozo",
      "provincia": 8
  },
  "777": {
      "cgo": 777,
      "nombre": "Cascallares",
      "provincia": 1
  },
  "780": {
      "cgo": 780,
      "nombre": "Sarmiento",
      "provincia": 2
  },
  "800": {
      "cgo": 800,
      "nombre": "Sarandi",
      "provincia": 1
  },
  "801": {
      "cgo": 801,
      "nombre": "Balcarce",
      "provincia": 1
  },
  "805": {
      "cgo": 805,
      "nombre": "Colombres",
      "provincia": 8
  },
  "851": {
      "cgo": 851,
      "nombre": "G.Lagos/Alvear",
      "provincia": 1
  },
  "852": {
      "cgo": 852,
      "nombre": "G.Lag/Alv/Arroy",
      "provincia": 1
  },
  "855": {
      "cgo": 855,
      "nombre": "San Martin*",
      "provincia": 1
  },
  "856": {
      "cgo": 856,
      "nombre": "Timbues *",
      "provincia": 6
  },
  "857": {
      "cgo": 857,
      "nombre": "Gral.Lagos *",
      "provincia": 1
  },
  "858": {
      "cgo": 858,
      "nombre": "Labordeboy",
      "provincia": 6
  },
  "859": {
      "cgo": 859,
      "nombre": "Ing.White",
      "provincia": 1
  },
  "860": {
      "cgo": 860,
      "nombre": "Bahia L.P.Buena",
      "provincia": 1
  },
  "879": {
      "cgo": 879,
      "nombre": "Col.Adela",
      "provincia": 3
  },
  "881": {
      "cgo": 881,
      "nombre": "Piquillin",
      "provincia": 2
  },
  "888": {
      "cgo": 888,
      "nombre": "S.M. Agd",
      "provincia": 6
  },
  "889": {
      "cgo": 889,
      "nombre": "B. De Irigoyen",
      "provincia": 6
  },
  "896": {
      "cgo": 896,
      "nombre": "S. Martin / Ros",
      "provincia": 1
  },
  "897": {
      "cgo": 897,
      "nombre": "Alberti",
      "provincia": 1
  },
  "899": {
      "cgo": 899,
      "nombre": "Bolivar",
      "provincia": 1
  },
  "900": {
      "cgo": 900,
      "nombre": "Esmeralda",
      "provincia": 6
  },
  "902": {
      "cgo": 902,
      "nombre": "Gral.Lagos",
      "provincia": 6
  },
  "903": {
      "cgo": 903,
      "nombre": "Fca Ros Norte",
      "provincia": 6
  },
  "904": {
      "cgo": 904,
      "nombre": "Fca Ros Sur",
      "provincia": 6
  },
  "905": {
      "cgo": 905,
      "nombre": "Fabrica Rosario",
      "provincia": 6
  },
  "906": {
      "cgo": 906,
      "nombre": "Gral.Levalle",
      "provincia": 2
  },
  "910": {
      "cgo": 910,
      "nombre": "Terminal 6",
      "provincia": 6
  },
  "911": {
      "cgo": 911,
      "nombre": "Rosario/S.Loren",
      "provincia": 6
  },
  "915": {
      "cgo": 915,
      "nombre": "San Martin",
      "provincia": 6
  },
  "916": {
      "cgo": 916,
      "nombre": "Olavarria",
      "provincia": 1
  },
  "917": {
      "cgo": 917,
      "nombre": "Esquina Cordoba",
      "provincia": 2
  },
  "918": {
      "cgo": 918,
      "nombre": "Timb/Ros/Villa",
      "provincia": 6
  },
  "920": {
      "cgo": 920,
      "nombre": "Timbues",
      "provincia": 6
  },
  "930": {
      "cgo": 930,
      "nombre": "Fontezuela",
      "provincia": 9
  },
  "945": {
      "cgo": 945,
      "nombre": "Ter.P.Del Guazu",
      "provincia": 3
  },
  "950": {
      "cgo": 950,
      "nombre": "Sin Entrega",
      "provincia": 6
  },
  "956": {
      "cgo": 956,
      "nombre": "Monte Cristo",
      "provincia": 2
  },
  "965": {
      "cgo": 965,
      "nombre": "Tinoco Cordoba",
      "provincia": 2
  },
  "966": {
      "cgo": 966,
      "nombre": "Irigoyen",
      "provincia": 6
  },
  "990": {
      "cgo": 990,
      "nombre": "Almafuerte",
      "provincia": 2
  },
  "996": {
      "cgo": 996,
      "nombre": "Berabevu",
      "provincia": 6
  },
  "997": {
      "cgo": 997,
      "nombre": "Oliva",
      "provincia": 2
  },
  "998": {
      "cgo": 998,
      "nombre": "Gral. Racedo",
      "provincia": 3
  },
  "999": {
      "cgo": 999,
      "nombre": "Villa Valeria",
      "provincia": 400
  },
  "1000": {
      "cgo": 1000,
      "nombre": "Aca S. Lorenzo",
      "provincia": 6
  },
  "1001": {
      "cgo": 1001,
      "nombre": "Alvear",
      "provincia": 6
  },
  "1002": {
      "cgo": 1002,
      "nombre": "Sm/R/Gl",
      "provincia": 6
  },
  "1003": {
      "cgo": 1003,
      "nombre": "S.Martin/Timbue",
      "provincia": 6
  },
  "1004": {
      "cgo": 1004,
      "nombre": "Renova/Timbues",
      "provincia": 6
  },
  "1005": {
      "cgo": 1005,
      "nombre": "Arroyo Seco",
      "provincia": 6
  },
  "1006": {
      "cgo": 1006,
      "nombre": "Pampa Guanacos",
      "provincia": 7
  },
  "1007": {
      "cgo": 1007,
      "nombre": "Espartillar",
      "provincia": 1
  },
  "1008": {
      "cgo": 1008,
      "nombre": "Pampa Del Infie",
      "provincia": 9
  },
  "1050": {
      "cgo": 1050,
      "nombre": "El Tio",
      "provincia": 2
  },
  "1100": {
      "cgo": 1100,
      "nombre": "Ricard./S.Loren",
      "provincia": 6
  },
  "1101": {
      "cgo": 1101,
      "nombre": "Arroyo/Timbues",
      "provincia": 6
  },
  "1102": {
      "cgo": 1102,
      "nombre": "Santa Elena",
      "provincia": 3
  },
  "1112": {
      "cgo": 1112,
      "nombre": "T6 Agd",
      "provincia": 6
  },
  "1200": {
      "cgo": 1200,
      "nombre": "Eduardo Costa",
      "provincia": 1
  },
  "1201": {
      "cgo": 1201,
      "nombre": "Ber. Larroude",
      "provincia": 4
  },
  "1500": {
      "cgo": 1500,
      "nombre": "Rosario Norte",
      "provincia": 6
  },
  "1501": {
      "cgo": 1501,
      "nombre": "Rosario Sur",
      "provincia": 6
  },
  "1785": {
      "cgo": 1785,
      "nombre": "A La Matanza",
      "provincia": 1
  },
  "1893": {
      "cgo": 1893,
      "nombre": "Abasto",
      "provincia": 1
  },
  "2646": {
      "cgo": 2646,
      "nombre": "",
      "provincia": 0
  },
  "2684": {
      "cgo": 2684,
      "nombre": "Los Cisnes",
      "provincia": 2
  },
  "3741": {
      "cgo": 3741,
      "nombre": "El Colorado",
      "provincia": 7
  },
  "4449": {
      "cgo": 4449,
      "nombre": "Rio Del Valle",
      "provincia": 11
  },
  "6500": {
      "cgo": 6500,
      "nombre": "9 De Julio",
      "provincia": 1
  },
  "90001": {
      "cgo": 90001,
      "nombre": "Up River",
      "provincia": 6
  },
}