import { wayPayNames } from "../dict/wayToPay"

export const WAY_PAY_VOID ={
    wayPayName: -1,
    percentage: 97.5,
    expiration: '--',
    days: -1,
    isBusinessDays:1,
    options:0
}

export const WAY_PAY_TO_FIX_DEFAULT = {
    wayPayName: wayPayNames.contraFixMercaderiaEntregada,
    percentage: 97.5,
    expiration: '--',
    days: 5,
    isBusinessDays:1,
    options:0
}

export const DEFAULT_WAY_PAY_WITH_COMMODITIES_HUGHES = {
    ...WAY_PAY_VOID,
    wayPayName:wayPayNames.contraEntrega,
    days: 3,
    isBusinessDays: 1,
    percentage: 100
}