import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-svg',
  templateUrl: './dashboard-svg.component.html',
  styleUrls: ['../../svg.scss']
})
export class DashboardSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
