import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { orderTags } from 'src/app/shared/const/orders';
import { PROVINCES_CONST } from 'src/app/shared/const/provinces';
import { COMMODITIES_CODE, HUGHES_CODE_BUYER } from 'src/app/shared/const/buyers';
import { provinces } from 'src/app/shared/dict/provinces/provinces';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../../fields.component';

@Component({
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./../../fields.component.scss']
})
export class ProvinceComponent extends Field implements OnInit {

  constructor(store: Store<appState>) {
    super(store);
    this.array = Object.keys(provinces);
    this.array.shift();
    this.OBJECT=provinces;
    this.deleteLastValueInArray();
    this.initalElementSelected = 'Ingresar una provincia';
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    this.tag = orderTags.placeOfOrigin;
    this.subTag = orderTags.province;
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    this.subTag=orderTags.province;
    this.rememberValueWithObject();
  }

  override setElement(element: any, index: number) {
    //Reseteamos el "las localidades" cuando cambiamos de provincia.;
    this.subTag=orderTags.town;
    this.setElementToGenerate(-1);
    
    //Reseteamos el "afip" code cuando cambiamos de provincia.;
    this.subTag=orderTags.afip;
    this.setElementToGenerate(-1);

    this.subTag=orderTags.province;
    this.setElementToGenerate(Number(element));

    this.setRestriction(Number(element));
  }

  setRestriction(province: number){
    if([PROVINCES_CONST.CATAMARCA, PROVINCES_CONST.LA_RIOJA, PROVINCES_CONST.SALTA].includes(province)){
      this.tag=orderTags.buyer;
      this.subTag=orderTags.notSell;
      this.setElementToGenerate([COMMODITIES_CODE]);

      this.tag=orderTags.placeOfOrigin;
      this.subTag=orderTags.province;
    }
  }

  isCaba(){
    return this.elementToGenerate?.placeOfOrigin?.province==PROVINCES_CONST.CABA;
  }

}
