import { Component, OnInit } from '@angular/core';
import { Field } from '../fields.component';
import { SEDE_AGRUPACION } from 'src/app/shared/const/business-particularities';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { fieldType } from 'src/app/shared/const/options';
import { priceTags } from 'src/app/shared/const/prices.const';
import { orderTags } from 'src/app/shared/const/orders';

@Component({
  selector: 'app-sede',
  templateUrl: './sede.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class SedeComponent extends Field implements OnInit{

  constructor(store:Store<appState>){
    super(store);
    this.OBJECT=SEDE_AGRUPACION;
    this.array= Object.keys(this.OBJECT);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.businessParticularities;
      this.subTag= priceTags.campus;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.businessParticularities;
      this.thirdTag= priceTags.campus;
    }
    this.setIdentifyClassName();
  }


  override setElement(element:any){
    this.setElementToGenerate(element.element);
  }

  override rememberValueInForm(){
    this.rememberCampus();
  }

  rememberCampus(){
    try{
      let valueToSet: any = this.rememberValue();
      
      if (this.elementToGenerate && valueToSet.length>0) {
        this.elementSelected = this.OBJECT[valueToSet];
        this.search = this.elementSelected;
        this.indexOption = this.getIndexOfArray(valueToSet, this.array);
        this.setIsOptionSelected(true)      
      } else if( valueToSet < 0){
        this.elementSelected = '';
        this.search = '';
        this.indexOption = -1;
        this.setIsOptionSelected(false)
      }
    } catch(err){}
  }
}
