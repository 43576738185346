<div class="market-price" *ngIf="prices.length">
    <div class="header {{productName}} row-between width-100" >
        <div class="name-and-icon row-left gap-8">
            <div class="product-icon"><img src="../../../../../../assets/images/market-price/{{productName}}.svg"></div>
            <span class="product-name">{{productName}}</span>
        </div>
        <arrow-svg class="pointer" [direction]="'right'" (click)="goToPriceBoard(productName)"></arrow-svg>
    </div>

    <div class="body width-100">
        <div class="card-container">
            <app-price-market-card class="card-price" [price]="prices[0]" *ngIf="prices[0]"></app-price-market-card>
            <app-price-market-card class="card-price" [price]="prices[1]" *ngIf="prices[1]"></app-price-market-card>
        </div>
        <div class="card-container" id="card-in-tablet-and-desktop">
            <app-price-market-card class="card-price" [price]="prices[2]" *ngIf="prices[2]"></app-price-market-card>
            <app-price-market-card class="card-price" [price]="prices[3]" *ngIf="prices[3]"></app-price-market-card>
        </div>
    </div>
</div>