import { HowSellValidation, RucaAndSisa } from "../interfaces/DataIBM";
import { Commission, Order, OrderString, Quotas, SellerData, ValidationStruct } from "../interfaces/order.interface"
import { initialPrice, initialPriceString } from "./prices.const"

export const orderOriginConst = { 
    desdeTablero:1,
	manual:2,
    desdeTableroModificada:3,
}

export const orderCategoryConst = {
    orderSale:1,
	orderPurchase:2,
}

export const orderTypeConst = {
	orderPrice:1,
	orderToSet:2
}

export const orderStatusConst = {
	RETURNED: 1,
	PENDING: 2,
	CONFIRM: 3,
	CHECK: 4,
	OFERTED: 5,
	SEND_TO_BROKE: 6,
    AUTORIZATION: 7,
    CONTRACT: 8,
    OUT_OF_MARKET: 9,
}

export const orderTags = {
	price: 'price',
	name:'name',
	placeOfOrigin:'placeOfOrigin',
    town:'town', 
    province:'province',
    afip:'afip',
    broke:'broke', 
    howSell:'howSell', 
    buyer:'buyer',
    onlySell:'onlySell', 
    notSell:'notSell',
    tons:'tons', 
    statusOrder:'statusOrder', 
    quotas:'quotas',
    orderDestination:'orderDestination',
    orderOrigin:'orderOrigin', 
    orderType:'orderType', 
    orderCategory:'orderCategory',
    commision:'commision',
	comissionSeller:'seller',
	comissionBuyer:'buyer',    
	message:'message', 
    nameOfcreator:'nameOfcreator', 
    dateOfcreation:'dateOfcreation',
    dataOfCreation:'dataOfcreation',
    sellerName: 'sellerName',
    sellerData: 'sellerData',
    observationsParameterized: 'obsParam',
    codeS:'codeS',
    sucG:'sucG',
    cuit:'cuit',
    hour:'hour', 
}

export const QUOTA_VOID:Quotas={ date: '--', trucks: -1 };

export const SELLER_DATA_VOID:SellerData={
    codeS: -1,
    sucG: -1,
    cuit:''
}

export const PLACE_OF_ORIGIN_VOID={
    town: -1,
    province:-1,
    afip: -1
}

export const BUYER_COMMISION=1;
export const SELLER_COMMISION=0.5;

export const COMMISION_VOID:Commission={
    buyer: BUYER_COMMISION,
    seller: SELLER_COMMISION
}
export const orderInitialState:Order={
    _id:0,
    price:initialPrice,
    contractNumber: -1,
    placeOfOrigin: PLACE_OF_ORIGIN_VOID,
    broke: 1, //Broker: Grassi
    howSell: -1,
    buyer: {
        onlySell: [],
        notSell: [],
    },
    arrayMods:[],
	tons: -1,
	statusOrder: orderStatusConst.PENDING,
    quotas: JSON.stringify([QUOTA_VOID]),
    commision: COMMISION_VOID,
    dataOfcreation: {
        nameOfcreator: '--',
        email: '--',
        teamOfcreator: {
            nroTeam: -1,
            bossName: '--',
            bossLastName: '--'
        },
        dateOfcreation: '--',
        hour: '--'
    },
    orderDestination: {
        orderOrigin: -1, //Desde el tablero o manual // orderOriginConst
        orderCategory:orderCategoryConst.orderSale, //De venta o de compra //orderCategoryConst 
        orderType:orderTypeConst.orderPrice //A precio o A fijar // orderTypeConst 
    },
    sellerData: SELLER_DATA_VOID,
    obsParam: '--',
    idExchange: '--',
    message: ' ',
    created_at: ''
}

export const initialOrderString:OrderString={
    price:initialPriceString,
    hour:'',
    statusOrder:[],
    tons: {
        end:0,
        start:0
    },
    buyer: [],
    codeS: [],
    nroTeam: [],
    nameOfcreator: []
}

export const validationInitialState:ValidationStruct={
    isExchanger:false,
    isCompleted:false,
    sellerCode:0,
}

export const rucaAndSisaInitialState:RucaAndSisa={
    "COD.ACTIVIDAD RUCA": "",
    "COD.ACTIVIDAD SISA": "",
    "Estado SISA": 0,
    "Producción Propia": "",
    ExisteRuca: "",
    ExisteSisa: "",
    Vendedor: '',
}

export const howSellValidationInitialState: HowSellValidation ={
    ComoVende: "",
    Validación: "",
    Vendedor: "",
}