import { Component, Output, OnInit,EventEmitter, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { Field } from '../fields.component';
@Component({
  selector: 'app-tons',
  templateUrl: './tons.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class TonsComponent extends Field implements OnInit, OnDestroy{
  @Input() maxOfTons: number=9000;
  constructor(store:Store<appState>, private validator:ValidatorChecker) { 
    super(store);
  }

  ngOnInit(): void {
    if(this.fieldIdentificator==fieldType.EXCHANGE){
      this.tag=orderTags.tons;
    } else {
      //this.tag=orderTags.exchange;
      this.tag=orderTags.tons;
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  
  override rememberValueInForm(){
    this.rememberValueTypeTwo();
  }

  getMaxOfTons():string{
    return this.maxOfTons.toString();
  }

  override setElement(event:any, index:number){
    let valueToSave= this.validator.validatorNumber(event);
    this.elementSelected = valueToSave;
    this.changeShowOptions();
    this.setElementToGenerate(valueToSave);
  }


  @Output() tab = new EventEmitter<string>();
  emit(){
    this.tab.emit('tab');
  }
  listenerTab(event: any) {
    if(event.key.toLowerCase() == 'tab' && (this.fieldIdentificator == fieldType.ORDER  || this.fieldIdentificator==fieldType.VALIDATION)){
      this.emit();
  }}
  
}
