import { Injectable } from '@angular/core';
import { IndicatorIBM } from '../interfaces/DataIBM';
import { Indicator } from '../interfaces/price-to-fix.interface';
import { getDateISOFormat } from '../date.checker';
import { INDICATORS_TO_FIX_CONST } from '../const/to-fix';
import { ForwardPeriod } from '../interfaces/price-to-fix.interface';
import { getIndicatorsWithProporcionality, haveBonusOrReduction } from '../to-fix';
import { getTypeCoinBonificationIBM } from './contract.checker';
import { OrderService } from '../services/order.service';
import { appState } from '../interfaces/appState.interface';
import { Store } from '@ngrx/store';
import { addModalContractFailed, setInfoIndicators, setModalContractType } from 'src/app/redux/actions/options.action';
import { typeOfModal } from '../const/options';

@Injectable({
  providedIn: 'root',
})
export class IndicatorsIBMFunctions {
    constructor(){}
}

/*Given some indicators and numberContract, go through each one of them.
To pass them to a function called 'sendIndicatorToIBM'. */
export async function sendIndicatorsToIBM(indicators:Indicator[], numberContract:number, orderSvc:OrderService, store:Store<appState>){
    const copyIndicators=getIndicatorsWithProporcionality(indicators);
    if(indicators && indicators.length>0){
        //store.dispatch(setInfoIndicators({infoIndicators: "Enviando los indicadores..."}));
        for (const indicator of copyIndicators) {
            await sendIndicatorToIBM(indicator, numberContract, orderSvc, store);
            //store.dispatch(setInfoIndicators({infoIndicators: "Indicadores enviados."}));
        }
    }
}

/*A function that, given an indicator and numberContract, 
uses the 'getIndicatorIBM' function to create an indicator 
with the IBM structure and then pass it through the service to send it to IBM */
export function sendIndicatorToIBM(indicator:Indicator,numberContract:number, orderSvc:OrderService, store:Store<appState>){
    return new Promise<void>((resolve, reject) => {
        const indicatorIBM = getIndicatorIBM(indicator,numberContract);
        //Send indicatorIBM to IBM
    
        orderSvc.insertNewIndicator(indicatorIBM).then((res:any) => {
    
            if(res.status.status=='error'){
                //Al array de faileds que teníamos anteriormente, le agregamos la nueva respuesta
                store.dispatch(addModalContractFailed({arrayOfFaileds: [res], isIndicators:true}));
                store.dispatch(setModalContractType({typeModal: typeOfModal.ERROR}));
                reject(new Error("Error al enviar indicador a IBM: "+res)); // Rechazamos la promesa si la respuesta es un error
            }
            resolve(); // Resolvemos la promesa cuando se recibe la respuesta del backend
        }).catch((err:any) => {
            reject(new Error("Error al enviar indicador a IBM: "+err)); // Rechazamos la promesa si ocurre un error
            store.dispatch(addModalContractFailed({arrayOfFaileds: ["Compruebe su conexión o la VPN."], isIndicators:true}));
            store.dispatch(setModalContractType({typeModal: typeOfModal.ERROR}));
        });
    });
}

/*A function that, given an Indicator and numberContract, parses each value to return an InicatorIBM*/
function getIndicatorIBM(indicator:Indicator,numberContract:number):IndicatorIBM{
    return {
        CONT:numberContract, //CHECK
        FECHD:getDateISOFormat(indicator.fixationPeriod.startDate), //CHECK
        FECHH:getDateISOFormat(indicator.fixationPeriod.endDate), //CHECK
        INDI:getIndicatorCodeInIBM(indicator.indicatorName), //CHECK
        PERI:getPositionInIBM(indicator.monthAndYear), //CHECK
        PROD:indicator?.product, //CHECK
        PORC:(indicator?.percentageProduct ?? 0) * 100, //CHECK
        PLAZA: indicator?.location <0? 0: indicator.location ??0, //CHECK
        BORE:getHaveBonusOrReduction(indicator), //CHECK
        IMBR:getAmountBonusOrReduction(indicator), //CHECK
        MONE:getTypeCoinBonusOrReduction(indicator), //CHECK
        MINI:getPercentageMinOrMax(indicator, 'min'), //CHECK
        MAXI:getPercentageMinOrMax(indicator, 'max'), //CHECK
        CALI: getQualityIndicator(indicator) //CHECK
    }
}

/*Given a number that represents the name of our indicator.
Returns the code with which you identify yourself in IBM.
We help ourselves with the table CER050t8.*/
export function getIndicatorCodeInIBM(indicatorName:number){
    switch(indicatorName){
        case INDICATORS_TO_FIX_CONST.MERC_COMP_DISP: return 36; //Mercado Comprador Disponible Fabrica
        case INDICATORS_TO_FIX_CONST.MERC_COMP_GEN: return 34; //Mercado Comprador Generalizado
        case INDICATORS_TO_FIX_CONST.PIZ_DISP: return 35; //Esperando que me digan el código
        case INDICATORS_TO_FIX_CONST.MATBA: return 39; //Posición de MATBA
        case INDICATORS_TO_FIX_CONST.CME: return 40; //Posición de CME
        case INDICATORS_TO_FIX_CONST.FORW_COMP: return 37; //Mercado Forward Comprador
        case INDICATORS_TO_FIX_CONST.FORW_GEN: return 38; //Mercado Forward Generalizado
        case INDICATORS_TO_FIX_CONST.CME_EN_MATBA: return 41; //Posición de CME en MATBA
        case INDICATORS_TO_FIX_CONST.DISP_MATBA: return 42; //Posición de MATBA Disponible
    }
    return 0;
}

/*Given a position field of type ForwardPeriod, returns the position for the IBM field in the following format:
Examples: 202301 -> Enero 2023
          ...
          202312 -> Diciembre 2023
            Si es parcial:
          202313 -> 15 Enero - 15 Febrero
          ...
          202319 -> 15 Julio - 15 Agosto
          ...
          202324 -> 15 Diciembre - 15 Enero 2024
Where the first number is what the function should return and after the arrow, what represents the value */
export function getPositionInIBM(position:ForwardPeriod){ 
    try{
        if(position.month && position.year){
            //If it is partial, to the month, we add 12 (number of months)
            const positionMonth= position.isPartial ? position.month + 12 : position.month;

            //If the previous value is less than 10, we add a '0' before
            const monthString= positionMonth < 10 ? '0' + positionMonth : positionMonth.toString();

            return Number(position.year + monthString);
        }
    } catch(err){}
    return 0;
}

/*Given an indicator, returns 'B' if it has a bonus or 'R' if it has a discount*/
export function getHaveBonusOrReduction(indicator:Indicator){
    return  haveBonusOrReduction(indicator.bonification) ? 'B' : 
            haveBonusOrReduction(indicator.reduction) ? 'R' : '';
}


/*Given an indicator, it returns the amount of the bonus or rebate depending on which one it has.
If it doesn't have any, it returns 0. */
export function getAmountBonusOrReduction(indicator:Indicator){
    return haveBonusOrReduction(indicator.bonification) ? indicator.bonification.value * 100 :
            haveBonusOrReduction(indicator.reduction) ? indicator.reduction.value * 100 : 0;
}

/*Given an indicator, it returns the currency of the bonus or rebate depending on which one it has.
If it doesn't have any, it returns 0. */
export function getTypeCoinBonusOrReduction(indicator:Indicator){
    return haveBonusOrReduction(indicator.bonification) ? getTypeCoinBonificationIBM(indicator.bonification.type) :
            haveBonusOrReduction(indicator.reduction) ? getTypeCoinBonificationIBM(indicator.reduction.type) : 0;
}

/*Given an indicator and a string 'min' or 'max, we return the minimum or maximum percentage. */
export function getPercentageMinOrMax(indicator:Indicator, minOrMax:string){
    switch (minOrMax) {
        case 'min': return (indicator?.min ?? 0) * 100;
        case 'max': return (indicator?.max ?? 0) * 100;
        default: return 0;
    }
}

/*Given an indicator, it accesses its quality field and arranges it to send to IBM*/
export function getQualityIndicator(indicator:Indicator){
    switch (indicator.quality) {
        case 1: return 2; //Calidad CAMARA
        case 2: return 1; //Calidad FABRICA
        case 3: return 3; //Calidad GRADO 2
        default: return 0;
    }
}



