import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { orderStatusConst, validationInitialState } from "src/app/shared/const/orders";
import { Order } from 'src/app/shared/interfaces/order.interface';
import { resetOrderToGenerate, setIndexToEdit, setOrdersToGenerate, setValidation } from 'src/app/redux/actions/order.action';
import { User } from 'src/app/shared/interfaces/user.interface';
import { DateChecker, } from 'src/app/shared/date.checker';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { areas } from 'src/app/shared/const/user.const';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { resetOrderExchangeToGenerate, setEditGenerateOrderExchange, setIsGenerateOrderExchange } from 'src/app/redux/actions/exchange.action';
import { setIsGenerateToFix } from 'src/app/redux/actions/options.action';
import { ExchangeService } from 'src/app/shared/services/exchange.service';
import { OBJECT_ID_VOID } from 'src/app/shared/const/options';
import { EXCHANGE_STATUS_CONST } from 'src/app/shared/const/exchange-orders';
import { SYNGENTA_SELLER_DATA } from 'src/app/shared/dict/syngenta';
import { Subscription } from 'rxjs';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';


@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.scss', '../modal.scss']
})

export class DialogOrderToBolets implements OnInit, OnDestroy {
  hour: string = '';
  mensaje: string = 'Estás por enviar la orden de';
  moreInfo: string = '';
  isConfirm: boolean = true;

  //Orders
  identificatorOrderEdit: number = -1;
  disabledButton: boolean = true;

  sellerIsExchange: boolean = false;

  private user!: User; //Store of Redux.
  public orders: Order[] = [];
  isComercial: boolean = true;
  order!: Order;
  orderExchange!: OrderExchange;
  isGenerateOrderExchange: boolean = false;

  private orderSubscription!: Subscription;
  private orderExchangeSubscription!: Subscription;
  private userSubscription!: Subscription;

  constructor(private router: Router, private store: Store<appState>, public date: DateChecker,
    public dialogRef: MatDialogRef<DialogOrderToBolets>, private orderExcV: OrderExchangeValidator, 
    private orderV: OrderValidator, private orderExchangeSvc: ExchangeService,
    private IBM:ContractChecker) {
    this.hour = this.date.setHourFormat();
    this.moreInfo = '';
  }

  ngOnInit(): void {
    this.orderSubscription = this.store.select('order').subscribe(orderReducer => {
      this.orders = orderReducer.generateOrders;
      this.order = orderReducer.generateOrder;
      this.identificatorOrderEdit = orderReducer.indexToEdit;
      this.sellerIsExchange = orderReducer.validation.isExchanger;
    })

    this.orderExchangeSubscription = this.store.select('exchange').subscribe(exchange => {
      this.isGenerateOrderExchange = exchange.isGenerateOrderExchange;
      this.orderExchange = exchange.generateOrder;
    })

    this.userSubscription = this.store.select('usuario').subscribe(user => {
      this.user = user;
      this.isComercial = user.area == areas.comercial;
    })
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe();
    this.orderExchangeSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  generateOrderBackgroundCommodities() {
    let orderToSave: OrderExchange = JSON.parse(JSON.stringify(this.order));
    const sellerData = JSON.parse(JSON.stringify(orderToSave.sellerData)); //Guardamos seller data,
    //Para reemplazarlo por syngenta, que busque las proforms y luego lo volvemos a ingresar.
    orderToSave.message = ' ';
    orderToSave.statusOrder = orderStatusConst.CONTRACT;
    orderToSave.dataOfcreation = this.orderV.getDataOfCreation(this.user);
    orderToSave.quotas = JSON.stringify(this.orderV.getValidQuotas(orderToSave));
    orderToSave.sellerData = SYNGENTA_SELLER_DATA;
    orderToSave.exchange = this.orderExcV.getExchangeInitial(orderToSave);
    orderToSave.exchange.buyer = sellerData;
    orderToSave.exchange.idMother = OBJECT_ID_VOID;
    //orderToSave.exchange.buyer=orderToSave.sellerData;
    orderToSave.exchange.howSell = orderToSave.howSell;
    orderToSave.exchange.status = EXCHANGE_STATUS_CONST.VERIFICAR;

    this.orderExchangeSvc.createOrderExchange(orderToSave).subscribe( (res:ResponseRequest) => {
      orderToSave._id = res.data;
      orderToSave.created_at= this.date.getDateInYYYYMMDDformat(new Date());//Borramos el created at porque todavía no lo tenemos
      this.IBM.createContractExchangeSvc(orderToSave, this.user,1)
    });

    this.resetAllOrders();
    const url = "/" + NAVEGATION.ORDER_EXCHANGE;
    this.router.navigate([url]);
    this.dialogRef.close();
  }


  resetAllOrders() {
    //Reset generate order
    this.store.dispatch(resetOrderToGenerate());

    //Reset generate orders
    this.store.dispatch(setOrdersToGenerate({ orders: [this.order] }));
    this.store.dispatch(setIndexToEdit({ indexToEdit: 0 }));

    //Reset validation
    this.store.dispatch(setValidation({ validation: validationInitialState }));
    //Reset generate order exchange
    this.store.dispatch(resetOrderExchangeToGenerate());

    this.store.dispatch(setEditGenerateOrderExchange({ isEditGenerate: false }));
    this.store.dispatch(setIsGenerateOrderExchange({ isGenerateOrderExchange: false }));
    this.store.dispatch(setIsGenerateToFix({ isGenerateToFix: false }));

    //Reset generate order to fix
  }

  /*If the seller is not an exchanger, we create a common order.
  If not, we create exchange order */
  isOrderExchange(order: Order) {
    //El vendedor debe ser canjeador, la orden no debe tener estado devuelta, y el vendedor no debe ser: Syngenta Agro AVC
    const isExchanger = this.sellerIsExchange;
    const isReturned = order.statusOrder == orderStatusConst.RETURNED;
    const isSyngentaAgroAVC = this.orderV.isSyngenta(order.sellerData.codeS) &&
      order.price.businessParticularities.subdivisionSyngenta == 1 &&
      order.price.businessParticularities.isAVC == 1;
    return isExchanger && !isReturned && !isSyngentaAgroAVC;
  }

  getQuantOfTons(order: Order) {
    return order.tons + ' toneladas.'
  }

}


