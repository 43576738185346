import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags, QUOTA_VOID } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { businessDict, typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class BusinessTypeComponent extends Field implements OnInit{

  constructor(store:Store<appState>, private dateC:DateChecker) { 
    super(store);
    this.OBJECT=typeBusiness;
    this.array=Object.keys(typeBusiness);
    this.array.shift();
    this.deleteLastValueInArray();
    this.initalElementSelected='Ingresar un tipo de negocio';
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.typeBusiness;
    } else {
      this.tag=orderTags.price;
      this.subTag=priceTags.typeBusiness;
    }
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    if(this.tag==priceTags.typeBusiness || this.subTag==priceTags.typeBusiness){
      this.rememberValueWithObject();
    }
  }

  override setElement(element: any, index: number) {
    const number=Number(element);
    this.setElementToGenerate(number);
    
    this.checkResetQuotas(number);
    this.checkDeliveryPeriod(number);
  }

  //If we are creating an order and the business type of the order is a download business type, it should not have quotas.
  checkResetQuotas(typeBusiness:number){
    if( (this.fieldIdentificator!= fieldType.PRICE && this.fieldIdentificator != fieldType.PRICE) && typeBusiness!=businessDict.conDescarga){
      this.tag=orderTags.quotas;
      this.subTag='';
      this.setElementToGenerate(JSON.stringify([QUOTA_VOID])); //Cuando es tipo de negocio Con Descarga, no debe tener cuotas.

      this.tag=orderTags.price;
      this.subTag=priceTags.typeBusiness;
    }
  }

  /*If the type of business is different from Forward, the default delivery period type is: 30 days, from today. */
  checkDeliveryPeriod(typeBusiness:number){
    this.setFirstTag(priceTags.deliveryPeriod);
    this.setTag(priceTags.startDate);
    const getDateToDay= typeBusiness==businessDict.forward? '--':this.dateC.getDate();
    this.setElementToGenerate(getDateToDay);
  

    this.setTag(priceTags.endDate);
    const getDate30Days=  typeBusiness==businessDict.forward?'--':this.dateC.addDaysToDate(getDateToDay, 30);
    this.setElementToGenerate(getDate30Days);

    this.setTag("");
    this.setFirstTag(priceTags.typeBusiness);
  }

}
