import { Component, Input } from '@angular/core';

@Component({
  selector: 'arrow-back-svg',
  templateUrl: './arrow-back.component.html',
  styleUrls: ['./../../svg.scss']
})
export class ArrowBackSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  constructor() { }

}
