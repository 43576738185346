import { Component, OnInit, HostListener, ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setFilterIndex, setFilterPrice, setPricesSelected } from 'src/app/redux/actions/price.action';
import {
  priceState,
  puertos,
  quality,
} from 'src/app/shared/dict/dict';
import { observations, priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price, WayPay } from 'src/app/shared/interfaces/price.interface';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { DateChecker, parseDate } from 'src/app/shared/date.checker';
import { Filter } from '../filter';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { PriceValidator } from 'src/app/shared/price';
import { setShowErrorChangePrice } from 'src/app/redux/actions/options.action';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { Subscription } from 'rxjs';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { WayPayValidator, getExpirationByWayPay } from 'src/app/shared/way-pay';

const PLACE_OF_DELIVERY = 'placeOfDelivery';

@Component({
  selector: 'filter-price',
  templateUrl: './filters.component.html',
  styleUrls: ['./../filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush

})
export class FilterPriceComponent extends Filter implements OnInit {
  //Is a price It is a price. Where your fields are Strings. It is used to filter list prices.
  priceFilter!: any; //Store of redux.
  isFixExpiration:boolean=false;

  pricesSelected!: Price[]; //Store of redux

  private priceSubscription!:Subscription;

  constructor(store: Store<appState>, date: DateChecker, private cdref: ChangeDetectorRef, 
    private priceV: PriceValidator, private wayPayV:WayPayValidator) {
    super(store,date);
    this.setSubscriptions();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.array = this.toArray(this.arrayAux);
    if (this.identificatorString == 'price') {
      this.setValueToSetInPrice();
    } else if(this.identificatorString==priceTags.deliveryPeriod || this.identificatorString==priceTags.pesificacion){
      this.deleteDateRangeSelected()
    }
    this.isFilter = true;

    this.wasInside = true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.priceSubscription.unsubscribe();
  }

  setSubscriptions(){
    this.priceSubscription=this.store.select('price').subscribe((price) => {
      this.priceFilter = price.priceBoard.filter;
      this.pricesSelected = price.priceBoard.pricesSelected;
      this.filterIndexSelected = price.priceBoard.indexFilter;
      this.views = price.viewsOfPriceBoard.views;
      this.viewSelected = price.viewsOfPriceBoard.viewSelected;

      if (this.identificatorString == 'price') {
        if (!this.isFilter) {
          this.valueToSet.typeCoin = this.getTypeCoin(this.pricesSelected)
        }
      }

      if (this.filterIndexSelected == this.filterIdentificator)
        this.wasInside = true;
    });
  }

  override setValueToSetInPrice() {
    if (this.isFilter) {
      this.valueToSet = {
        typeCoin: typeCoins.ALL,
        start: 0,
        end: 0,
        value: 0,
      };
      this.cdref.detectChanges(); //Detectamos cambios para que no salga error  
    } else {
      this.valueToSet = {
        typeCoin: this.getTypeCoin(this.pricesSelected),
        start: 0,
        end: 0,
        value: 0,
      };
    }
  }

  getTypeCoin(prices: Price[]) {
    const quantPrices = prices.length;
    const typeCoinOfAll = quantPrices > 0 ? prices[0].typeCoin : '';
    try {
      for (let i = 0; i < quantPrices; i++) {
        if (typeCoinOfAll != prices[i].typeCoin) {
          throw new Error();
        }
      }
    } catch (err) {
      return this.valueToSet.typeCoin;
    }
    return typeCoinOfAll;
  }

  setFixExpiration(){
    this.isFixExpiration= !this.isFixExpiration;
  }

  addOrDeleteElement(element: any) {
    if (this.isFilter || this.isComercial) {
      this.addOrDeleteItemComercial(element);
    } else if (this.isOperator && !this.isFilter) {
      this.addOrDeleteElementCommodities(element);
    }
  }

  addOrDeleteElementCommodities(element: any) {
    this.selects = [element];
  }

  addOrDeleteItemComercial(element: any) {
    let positionOfItem = -1;
    let elementAux = '';
    if (this.iSANestedField()) {
      positionOfItem = this.selects.indexOf(element.name);
      elementAux = element.name;
    } else {
      positionOfItem = this.selects.indexOf(element);
      elementAux = element;
    }
    //If the element is NOT in the array, we add it.Otherwise, we remove it.
    if (positionOfItem == -1) {
      this.selects.push(elementAux);
    } else {
      if (positionOfItem == 0) {
        this.selects.shift();
      } else {
        this.selects.splice(positionOfItem, 1);
      }
    }
  }

  applyChanges() {
    if (this.isComercial || this.isFilter) {
      this.setPriceFilter();
    } else if (this.isOperator && !this.isFilter) {
      this.setNewPrices();
    }

    this.store.dispatch(setFilterIndex({ index: -1 }));
  }

  //Function in case the user is commodities. (I would change the prices)
  setNewPrices() {
    //We go through the selected prices to apply the change.
    for (let i = 0; i < this.pricesSelected.length; i++) {
      let priceToSave = JSON.parse(JSON.stringify(this.pricesSelected[i]));
      let value = this.getValueToSetInThePrice(priceToSave);
      
      if (this.identificatorStringAux != priceTags.wayPayName && this.identificatorStringAux != priceTags.wayPayExpiration) {
        //Vamos a actualizar la condición en la BD
        //Si no es Way Pay, ni vencimiento, actualizamos el campo y luego la hora.
        this.priceV.editPrice(priceToSave, this.getField(this.identificatorString, this.identificatorStringAux), value, this.user);
        this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
      } else if (this.identificatorStringAux == priceTags.wayPayName && value){
        //Si es Way Pay, actualizamos todos los campos y el vencimiento.
        let parseValue:WayPay=JSON.parse(JSON.stringify(value));
        parseValue.expiration=getExpirationByWayPay(priceToSave, parseValue)
        priceToSave = this.priceV.setWayPay(priceToSave, parseValue, this.user);
      } else if (this.identificatorStringAux == priceTags.wayPayExpiration){
        //Si es de vencimiento, debemos corroborar que si es fecha fija.
        //Si es fecha fija, y no colocó vencimiento, quedará el mismo y resta fijarse si es fixExpiration
        //Si es fecha fija, y colocó el vencimiento, se actualizará el vencimiento y se actualizará la hora.
        
        if(this.wayPayV.getIsFechaFija(priceToSave)){
          if(value != '--' && value){
            this.priceV.editPrice(priceToSave, this.getField(this.identificatorString, this.identificatorStringAux), value, this.user);
            this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
          } else {
            this.priceV.editPrice(priceToSave, this.getField(this.identificatorString, priceTags.wayPayOptions), this.isFixExpiration? 1:0, this.user);
          }
        } else {
          //Si no es fecha fija, se actualizará el vencimiento y se actualizará la hora.
          this.priceV.editPrice(priceToSave, this.getField(this.identificatorString, this.identificatorStringAux), value, this.user);
          this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
        }

      }
    }

    this.resetSelectedPriceList();
  }

  //Given a price, it is determined if the new value to be set is valid
  //Checking if it complies with the stipulated percentage, if it is correct, it returns the value.
  //If it is incorrect, return the previous value in addition to displaying the modal.
  getPriceToSetInCondition(priceToSave: Price | any) {
    const typeCoin = this.valueToSet.typeCoin;
    const previusPrice = priceToSave[this.identificatorString];
    const amount = Number(this.valueToSet.value) + Number(previusPrice);

    if (typeCoin != priceToSave.typeCoin) {
      return previusPrice;
    }
  
    const isPriceVariance = this.priceV.isPriceVariance(amount, previusPrice);
    const showError = isPriceVariance ? this.store.dispatch(setShowErrorChangePrice({ showErrorChangePrice: true })) : null;
  
    return isPriceVariance ? previusPrice : amount;
  }

  resetSelectedPriceList() {
    this.store.dispatch(setPricesSelected({ prices: [] }));
  }

  //---------------EDIT PRICE------------------------\\

  getValueToSetInThePrice(priceToSave:Price) {
    //As we do not have the name of the subfield when it is the
    //'observations' filter, we assign a name depending on the selected field.
    let value: any = 0;
    if (this.identificatorString == priceTags.observations) {
      switch (this.selects[0]) {
        case observations.featuredPrice: this.identificatorStringAux = priceTags.isFeatured; break;
        case observations.gravanz: this.identificatorStringAux = priceTags.isBusinessGravanz; break;
        case observations.comment: this.identificatorStringAux = priceTags.isMessage; break;
        default: break;
      }

      //The value to set in the field will be true
      value = true;
    } else if (this.identificatorString == priceTags.price) {
      value = 
      value= this.getPriceToSetInCondition(priceToSave)
    } else if (this.identificatorString == priceTags.harvest) {
      value = this.selects[0];
    } else if ( (this.identificatorString == priceTags.wayPay && 
      this.identificatorStringAux == priceTags.wayPayName) ||
      this.identificatorString==priceTags.deliveryPeriod ||
      this.identificatorString==priceTags.pesificacion ) {
      //Field with type string for to set WAY PAY NAME
      value = this.valueToSet; //Struct from WayPay
    } else if(this.identificatorStringAux==priceTags.wayPayExpiration){

      value= this.valueToSet?this.valueToSet:'--';
    }  else {
      value = Number(this.getIndexOfDict(this.identificatorString, this.selects[0]));
    }

    return value;
  }

  getIndexOfDict(dictString: string, value: string) {
    let dict = {};
    switch (dictString) {
      case priceTags.quality: dict = quality; break;
      case priceTags.productName: dict = PRODUCT_NAMES; break;
      case priceTags.typeBusiness: dict = typeBusiness; break;
      case priceTags.status: dict = priceState; break;
      case priceTags.placeOfDelivery: dict = puertos; break;
      case priceTags.wayPay: dict = wayPay; break;
      default: break;
    }
    const indexOfItem = Object.values(dict).indexOf(value);
    if (indexOfItem >= 0) {
      return Object.keys(dict)[indexOfItem];
    }
    return null;
  }

  //Function in case the user is commercial. (filter would be applied)
  setPriceFilter() {
    let newPrice: any = { ...this.priceFilter };

    //When is a struct nested, his form of interaction is different.
    if (this.iSANestedField()) {
      newPrice[this.identificatorString] = { ...this.priceFilter[this.identificatorString] };
      newPrice[this.identificatorString][this.identificatorStringAux] = [...this.selects];
    } else if (this.identificatorStringAux == priceTags.wayPayExpiration) {
      //Field with type date.      
      newPrice[this.identificatorStringAux]= this.valueToSet? [this.valueToSet]: [];
    } else if (this.identificatorStringAux == priceTags.buyer){
      newPrice[this.identificatorStringAux]= this.selects? [...this.selects]: [];
    } else if (this.identificatorString==priceTags.wayPay) {
      newPrice[this.identificatorStringAux] = [...this.selects]
    } else {
      //If the identifier is observations or price, we used other form to interactue with struct
      switch (this.identificatorString) {
        case priceTags.observations:
          newPrice[this.identificatorString] =
            this.transformSelectToObservations();
          break;
        case priceTags.price:
        case priceTags.priceId:
        case priceTags.hour:
        case priceTags.deliveryPeriod:
        case priceTags.pesificacion:
          newPrice[this.identificatorString] = this.valueToSet;
          break;
        default:
          newPrice[this.identificatorString] = [...this.selects];
          break;
      }
    }

    //Guardamos la nueva vista (en la que estamos situados)
    this.store.dispatch(setFilterPrice({ priceString: newPrice }));
    this.editView(localStorageNames.viewPrices, newPrice);
  }

  iSANestedField() {
    return this.identificatorString == PLACE_OF_DELIVERY;
  }

  transformSelectToObservations() {
    //The values to filter are given according to the constant that is located in:
    //.. -> Price-table.compoent.ts -> OBSERVATIONS_ARRAY
    return {
      isBussinesGravanz: this.selects.indexOf(observations.gravanz) != -1,
      isFeatured: this.selects.indexOf(observations.featuredPrice) != -1,
      isMessage: this.selects.indexOf(observations.comment) != -1,
    };
  }

  getCheckedPriceCard(typeCoin: string) {
    return false;
    if (typeCoin == 'ARS') {
      return false;
    } else {
      return false;
    }
  }

  setPrice(event: any, identificator: string) {
    if (identificator == 'typeCoin') {
      this.valueToSet = {
        ...this.valueToSet,
        typeCoin: event.value,
      };
    } else {
      this.valueToSet[identificator] = event; //Is with Input Number Module
    }
  }

  setValue(event: any) {
    this.valueToSet = event.target.value;
  }

  setValueToSet(event: any) {
    this.valueToSet = event;

    if(this.identificatorStringAux==priceTags.wayPayExpiration){
      this.valueToSet = parseDate(event)
    }
  }


  //Detect Clicks
  @HostListener('click') clickInside() {
    this.store.dispatch(setFilterIndex({ index: this.filterIndexSelected }));
    this.wasInside = true;
  }

  @HostListener('document:click') clickout() {
    if (this.filterIdentificator == this.filterIndexSelected) this.count++;

    if (!this.wasInside) {
      if (
        this.filterIndexSelected == this.filterIdentificator &&
        this.count > 1
      ) {
        this.store.dispatch(setFilterIndex({ index: -1 }));
      }
      this.count = 0;
    }
    this.wasInside = false;
  }

  getField(tag: string, subTag: string) {
    return tag && subTag ? tag + '.' + subTag : tag? tag : '';
  }

  setWayPay(event: any, tag: string) {
    //We verify that the variable exists
    //If it does, the valueToSet structure already has the structure of wayPay
    //If it doesn't, we set the valueToSet structure for the first time.
    if (this.valueToSet) {
      this.valueToSet[tag] = event;
    } else {
      this.valueToSet = {
        wayPayName: 0,
        days: 0,
        expiration: '',
        isBusinessDays: 0,
        percentage: 0,
      };
      this.valueToSet[tag] = event;
    }
    
    if(tag==priceTags.wayPayName){
      this.selects = [event];
      this.valueToSet.wayPayName=Number(this.getIndexOfDict(this.identificatorString, this.selects[0]))
    }
  }
}
