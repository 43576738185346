<div class="option"> 
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(2)">Gravanz</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" (change)="setOptionOpenWithCheck($event,2)" 
            [checked]="gravanz.isGravanz==1" (click)="setIsGravanz()" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <span>Sensorización</span>
        <div class="option-content-particularity">
            <span class="option-name">Propia</span>
            <div class="actions">
                <input type="radio" [checked]="gravanz.isOwnSensorization==1" (click)="setOwnSensorization(true)" class="radio" />
            </div>
        </div>
        <div class="option-content-particularity">
            <span class="option-name">Terceros</span>
            <div class="actions">
                <input type="radio" [checked]="gravanz.isOwnSensorization!=1" (click)="setOwnSensorization(false)" class="radio" />
            </div>
        </div>

        <!------ Empresa depositaria ------>
        <span>Empresa depositaria</span>
        <div class="field">
            <span class="field-title">Empresa</span>
            <text-input [initialPlaceHolder]="'Ingresar una empresa'" [value]="getBusiness()"
            (valueOut)="setBusiness($event)"></text-input>
            <!--<select-input [placeHolder]="'Ingrese una empresa'" [array]="empresas" [OBJECT]="objectEmpresa" 
            [value]="objectEmpresa[gravanz.business]" (valueOut)="setBusiness($event)"></select-input>-->
        </div>


        <div class="field" style="margin-bottom:16px;">
            <span class="field-title">Selección de bolsa</span>
            <text-input [initialPlaceHolder]="'Ingrese una selección de bolsa'" 
            [value]="getSelectionStock()"  (valueOut)="setBrokerSelector($event)"></text-input>
            <!--<select-input [placeHolder]="'Ingrese una selección de bolsa'" [array]="selectionStock" [OBJECT]="objectSelectionStock"
            [value]="objectSelectionStock[gravanz.selectionStock]" (valueOut)="setBrokerSelector($event)"></select-input>-->
        </div>

    </div>
</div>