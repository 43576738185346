import { Component,OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { MatLegacyDialog as MatDialog, } from '@angular/material/legacy-dialog';
import { DateChecker } from 'src/app/shared/date.checker';
import { OrderValidator } from 'src/app/shared/order';
import { Details } from '../details.component';
import { DialogOrderCreate } from '../../dialogs/order-create/dialog';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { Subscription } from 'rxjs';
import { ToFixValidator } from 'src/app/shared/to-fix';


@Component({
  selector: 'app-details-exchange',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss','./../details.scss']
})

export class DetailsExchangeComponent extends Details implements OnInit, OnDestroy{
  isGenerateExchangeOrder:boolean = false;
  isSyngentaBuyer:boolean=false;
  orderSyngenta!:any;

  private exchangeSubscription!:Subscription;
  private orderSubscription!:Subscription;

  constructor(store: Store<appState>, dialog: MatDialog, date: DateChecker, orderV:OrderValidator, toFixV:ToFixValidator) {
    super(store,dialog,date,orderV,toFixV);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.exchangeSubscription=this.store.select('exchange').subscribe(exchangeReducer => {
      this.order = exchangeReducer.generateOrder;
      this.isSyngentaBuyer=this.orderV.isSyngentaAgroAvc(this.order.sellerData.codeS, this.order.price.businessParticularities.subdivisionSyngenta, this.order.price.businessParticularities.isAVC );
    }) 

    this.orderSubscription=this.store.select('order').subscribe(orderR=>{
      this.orderSyngenta=orderR.generateOrder;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.exchangeSubscription.unsubscribe();
    this.orderSubscription.unsubscribe();
  }

  override sendOrder() {
    this.openModal()
  }

  override sendOrders() {
    this.openModal()
  }

  openModal(){
    this.dialog.open(DialogOrderCreate);
  }

  isOrdersIncomplete():boolean{
    try{
      for(let i=0; i<this.orders.length; i++){
        if(this.orderV.isOrderIncomplete(this.orders[i])){
          throw new Error();
        }
      }
      return false;
    } catch(err){
      return true;
    }
  }
}