import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker, isEarlierDate } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields.component';

@Component({
  selector: 'app-end-date',
  templateUrl: './end-date.component.html',
  styleUrls: []
})
export class EndDateComponent extends Field implements OnInit {

  constructor(store: Store<appState>,private date: DateChecker) {
    super(store);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator == fieldType.PRICE) {
      this.tag = priceTags.deliveryPeriod;
      this.subTag = priceTags.endDate;
      this.thirdTag = '';
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.deliveryPeriod;
      this.thirdTag = priceTags.endDate;
    }
  }

  override setElement(event: any, index: number) {
    try {
      this.setTag(priceTags.endDate);

      let dateToSave = event;
      if (isEarlierDate(this.getPrice().deliveryPeriod.startDate, dateToSave, '-')) {
        this.setElementToGenerate(dateToSave);
      } else {
        this.setElementToGenerate('--');
      }
    } catch (err) { }
  }

  override rememberValueInForm() {
    this.rememberDeliveryPeriod();
  }

  //Remember value from date type (For deliveryPeriod).
  rememberDeliveryPeriod() {
try{
  let valueToSet: any = this.rememberValue();
  //If There is a value to remember
  if (valueToSet && this.elementToGenerate) {

    if (valueToSet) {
      if (valueToSet != '--') {
        this.elementSelected=valueToSet;
      } else {
        this.elementSelected = '';
      }
    }
  }
} catch(err){
  this.elementSelected = '';
}

  }
}
