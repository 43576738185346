import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'duplicate-order-svg',
  templateUrl: './duplicate-order.component.html',
  styleUrls: ['./../../svg.scss']
})
export class DuplicateOrderComponent {
  @Input() active:boolean=false;
  
  constructor() { }
}
