<app-buttons-to-fix *ngIf="conditionTypeSelected==CONDITION_TYPES.TO_FIX"></app-buttons-to-fix>
<div class="table table-desktop"*ngIf="!isViewMobile else elseBlock">
    <app-comercial *ngIf="isComercial" class="table"></app-comercial>
    <app-commodities *ngIf="isOperator" class="table"></app-commodities>
</div>

<ng-template #elseBlock>
        <div class="options">
            <button class="pending" [ngClass]="{ selected: orderStatus.PENDING == orderStatusSelected }"
              (click)="setOrderStatusSelected(orderStatus.PENDING)">
              Pendientes
            </button>
            <button class="returned" [ngClass]="{ selected: orderStatus.RETURNED == orderStatusSelected }"
                (click)="setOrderStatusSelected(orderStatus.RETURNED)">
                Devueltas
            </button>
            <button class="confirmed" [ngClass]="{ selected: orderStatus.CONFIRM == orderStatusSelected }"
              (click)="setOrderStatusSelected(orderStatus.CONFIRM)">
              Confirmadas
            </button>

            <div class="rectangle {{getStatusString(orderStatusSelected)}}"></div>
        </div>

    <div class="order-mobile visible-in-mobile" *ngFor="let order of ordersWithPipes | matchOrdersByName: orderStatusSelected:orderFilter">        
        <app-table-mobile-order [orders]="order" class="info-order" (actions)="selectOptionMenu($event.event, $event.order)"></app-table-mobile-order>
    </div>

    <div class="empty-state" *ngIf="(ordersWithPipes | matchOrdersByName: orderStatusSelected:orderFilter).length==0">
        <div class="info">
            <span class="header">No tienes ordenes con ese estado operando.</span>
        </div>
    </div>
</ng-template>