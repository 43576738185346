import { Action, createReducer, on } from "@ngrx/store";
import { User } from "src/app/shared/interfaces/user.interface";
import * as actions from '../actions/session.action';

export const USER_VOID: User = {
    id: '',
    name: '',
    lastName:'',
    email: '',
    photo: '',
    role: '',
    area: 0,
    workUnit: 0,
    phone: '',
    birthDay: '',
    subType: 0,
    team: {
        nroTeam: 0,
        bossName: '',
        bossLastName: ''
    }
};

const _userReducer = createReducer(
    USER_VOID,
    on(actions.loginSuccess, (state, action) => {
        return action.user;
    }),
    on(actions.logoutSuccess, (state, action) => { return USER_VOID }),
)


export function userReducer(state: any, action: Action) {
    return _userReducer(state, action);
}