<div class="header">
    <div class="menu" (click)="openMenu()"></div>

    <div class="container-logo">
        <img src="../../../assets/images/menu/logo-mobile.svg" alt="logo-grassi">
    </div>

    <span></span>
    <div *ngIf="mailsVisibles()">
        <button class="btn btn-sm btn-secondary" [ngClass]="{'selected': user.area==2}" (click)="changeTo(2)">Ser comercial</button>
        <button class="btn btn-sm btn-secondary" [ngClass]="{'selected': user.area==1}" (click)="changeTo(1)">Ser Operator</button>
    </div>

    <div class="row-between gap-32 claim-and-user">

        <div class="claim-and-hour">
            <p class="hour">{{hour}}</p>
            <div class="claim">
                <img src="../../../assets/images/header/sun.svg" />
                <p>{{claim}}°</p>
            </div>
        </div>
    
        <div class="user">
            <!--<div class="icons">
                <span class="campana"></span>
                <span class="mensajes"></span>
            </div>-->
    
            <img src="{{user.photo}}" alt="user-photo" class="user-photo" />
    
            <div class="name-and-area">
                <p class="name">{{user.name}} {{user.lastName}}</p>
                <p class="area">Sector {{areas[user.area]}}</p>
            </div>
        </div>
    </div>


</div>