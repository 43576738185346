<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Tablero de condiciones"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Tablero de condiciones de negocios</h2>
    <!-- CONTENT OF THE PRICE BOARD -->
    <body-price-board></body-price-board> 

    <app-menu class="menu"></app-menu>
    <app-menu-footer></app-menu-footer>
</div>