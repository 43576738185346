import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FutureMarketsComponent } from './future-markets.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    FutureMarketsComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports:[
    FutureMarketsComponent
  ]
})
export class FutureMarketsModule { }
