import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsConditionTypeComponent } from './buttons-condition-type.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

@NgModule({
  declarations: [
    ButtonsConditionTypeComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule
  ], exports: [
    ButtonsConditionTypeComponent
  ]
})
export class ButtonsConditionTypeModule { }