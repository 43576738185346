import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edit-svg-do',
  templateUrl: './edit-svg.component.html',
  styleUrls: ['./../../../svg.scss', './../duplicate-orders.scss']
})
export class EditDuplicateOrderSvgDOComponent {
  @Input() active:boolean=false;
  constructor() { }
}
