import { Exchange, OrderExchange } from "../interfaces/order-exchange"
import { OBJECT_ID_VOID } from "./options"
import { orderInitialState, PLACE_OF_ORIGIN_VOID, SELLER_DATA_VOID } from "./orders"
import { WAY_PAY_VOID } from "./wayPay"

export const exchangeTags = {
    exchange: 'exchange',
    status: 'status',
    buyer: 'buyer',
    agreedDiscount: 'agreedDiscount',
    priceDiscount: 'priceDiscount',
    percentageB: 'percentageB',
    amountB: 'amountB',
    motherContract: 'motherContract',
}

export const exchangeInitialState:Exchange={
    status:-1,
    buyer:SELLER_DATA_VOID,
    tons: -1,
    howSell:-1,
    placeOfOrigin:PLACE_OF_ORIGIN_VOID,
    price:-1,
    typeCoin:'--',
    codeWayPay: -1,
    daysWayPay:-1,
    contractNumber:-1,
    agreedDiscount:-1,
    priceDiscount:-1,
    wayPay: WAY_PAY_VOID,
    amountB:-1,
    percentageB:-1,
    isMotherCounterpart:-1,
    idMother:OBJECT_ID_VOID,
}

export const orderExchangeInitialState:OrderExchange={
    ...orderInitialState,
    exchange: exchangeInitialState
}

export const EXCHANGE_STATUS_CONST = {
	INCOMPLETO: 1,
	VERIFICAR: 2,
	COMPLETA: 3,
}