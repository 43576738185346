import { Injectable } from '@angular/core';
import { localStorageNames } from './const/localStorageNames';
import { getDate, isEarlierDate, isValidDate } from './date.checker';
import { Commission, Order, OrderString, Quotas, Restriction } from './interfaces/order.interface';
import { PriceValidator, comparePricesKeys } from './price';
import { getPlaceOfDeliveryIBM } from './functions-ibm/contract.checker';
import { BUYER_COMMISION, COMMISION_VOID, howSellValidationInitialState, orderInitialState, orderOriginConst, orderStatusConst, orderTags, rucaAndSisaInitialState, SELLER_COMMISION, validationInitialState } from './const/orders';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogReturnedOrder } from '../components/dialogs/order-returned/order-returned';
import { DialogConfirmedOrder } from '../components/dialogs/order-confirmed/order-confirmed';
import { Store } from '@ngrx/store';
import { appState } from './interfaces/appState.interface';
import { resetOrderToGenerate, setHowSellValidation, setIndexToEdit, setIsOrderReturned, setOrderToGenerate, setOrdersSelected, setOrdersToGenerate, setPriceToGenerateInOrder, setRucaAndSisa, setValidPxQ, setValidation } from '../redux/actions/order.action';
import { OrderService } from './services/order.service';


/* LOCALITIES */
import { buenosAiresLocalities } from 'src/app/shared/dict/provinces/locations-of-buenos-aires';
import { cabaLocalities } from 'src/app/shared/dict/provinces/locations-of-caba';
import { catamarcaLocalities } from 'src/app/shared/dict/provinces/locations-of-catamarca';
import { chacoLocalities } from 'src/app/shared/dict/provinces/locations-of-chaco';
import { chubutLocalities } from 'src/app/shared/dict/provinces/locations-of-chubut';
import { cordobaLocations } from 'src/app/shared/dict/provinces/locations-of-cordoba';
import { corrientesLocalities } from 'src/app/shared/dict/provinces/locations-of-corrientes';
import { entreRiosLocalities } from 'src/app/shared/dict/provinces/locations-of-entre-rios';
import { formosaLocalities } from 'src/app/shared/dict/provinces/locations-of-formosa';
import { jujuyLocalities } from 'src/app/shared/dict/provinces/locations-of-jujuy';
import { laPampaLocalities } from 'src/app/shared/dict/provinces/locations-of-la-pampa';
import { laRiojaLocalities } from 'src/app/shared/dict/provinces/locations-of-la-rioja';
import { mendozaLocalities } from 'src/app/shared/dict/provinces/locations-of-mendoza';
import { misionesLocalities } from 'src/app/shared/dict/provinces/locations-of-misiones';
import { neuquenLocalities } from 'src/app/shared/dict/provinces/locations-of-neuquen';
import { rioNegroLocalities } from 'src/app/shared/dict/provinces/locations-of-rio-negro';
import { saltaLocalities } from 'src/app/shared/dict/provinces/locations-of-salta';
import { sanJuanLocalities } from 'src/app/shared/dict/provinces/locations-of-san-juan';
import { sanLuisLocalities } from 'src/app/shared/dict/provinces/locations-of-san-luis';
import { santaCruzLocalities } from 'src/app/shared/dict/provinces/locations-of-santa-cruz';
import { santaFeLocalities } from 'src/app/shared/dict/provinces/locations-of-santa-fe';
import { santiagoDelEsteroLocalities } from 'src/app/shared/dict/provinces/locations-of-santiago-del-estero';
import { tierraDelFuegoLocalities } from 'src/app/shared/dict/provinces/locations-of-tierra-del-fuego';
import { tucumanLocalities } from 'src/app/shared/dict/provinces/locations-of-tucuman';
import { buyers } from './dict/buyers';
import { provinces } from './dict/provinces/provinces';
import { PlaceOfDelivery, Price } from './interfaces/price.interface';
import { User } from './interfaces/user.interface';
import { BusinessParticularities } from './interfaces/business-particularities';
import { OrderExchange } from './interfaces/order-exchange';
import { SUBDIVISION_SYNGENTA, SUB_SYNG_CONST, SYNGENTA_CODE_SELLER } from './dict/syngenta';
import { SellerObject, sellerWithData } from './dict/seller-with-data';
import { COMMISION_IBM } from './dict/ibm/commision-ibm';
import { CLIENTES_AC_IBM } from './dict/ibm/unidad-negocio-clientes';
import { HowSellValidation, /*PxQIBM, PxQIBMString,*/ RucaAndSisa } from './interfaces/DataIBM';
import { orderStates } from './dict/orders';
import { itCanBeHowSell } from './business-validation';
import { brokerValids } from './const/broker';
import { PROVINCES_CONST } from './const/provinces';
import { COMMODITIES_CODE, COMMODITIES_CODE_SELLER, HUGHES_CODE_BUYER, HUGHES_CODE_SELLER, NUTRIEN_CUIT, SANCOR_CODE_SELLER } from './const/buyers';
import { OBJECT_ID_VOID } from './const/options';
import { FilterAllOrderPipe } from './pipes/filter/filter-all-order.pipe';
import { areas } from './const/user.const';
import { resetOrderExchangeToGenerate, setEditGenerateOrderExchange, setIsGenerateOrderExchange } from '../redux/actions/exchange.action';
import { setErrorStruct, setIsGenerateToFix, setModalLoading } from '../redux/actions/options.action';
import { SEDE_AGRUPACION } from './const/business-particularities';
import { formatNumber, formatNumberWithDecimals } from './validator.checker';
import { wayPay } from './dict/wayToPay';
import { typeCoin } from './dict/dict';
import { COMMISION_BUYER_INITIAL } from '../components/cards/commission/commission.component';
import { Router } from '@angular/router';
import { NAVEGATION } from './const/navegation';
import { resetPriceToGenerate } from '../redux/actions/price.action';
import { priceHaveIndicators } from './to-fix';
import { Indicator } from './interfaces/price-to-fix.interface';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderValidator {
  constructor(
    private store: Store<appState>,
    private priceV: PriceValidator,
    private router:Router,

    public dialog: MatDialog,
    public orderSvc: OrderService,
  ) { }


  /* Compare two Orders by their JSON stringify */
  compareOrders(order1: Order, order2: Order): boolean {
    return JSON.stringify(order1._id) == JSON.stringify(order2._id);
  }

  /* Given a Order and an ArrayOrder, return if the Order exists in the ArrayOrder with the help of ComparePrice() */
  isOrderInOrderList(order: Order, orderList: Order[]) {
    try {
      const quantOrders = orderList.length;
      for (let i = 0; i < quantOrders; i++) {
        if (this.compareOrders(orderList[i], order)) {
          throw new Error();
        }
      }
      return false;
    } catch (err) {
      return true;
    }
  }

  /*Given an Order and an Array of orders.
Look in the array for the _id and compare the order with the one in the array.
If they are different, return true, else false. */
  isOrderNewInArray(order: Order, orders: Order[]) {
    if (orders.length == 0) { return false }
    const foundOrder = orders.find(existingOrder => existingOrder._id == order._id);
    if (foundOrder) {
      return JSON.stringify(foundOrder) != JSON.stringify(order);
    }
    return true;
  }

  areArraysOfOrdersEqual(arr1Param: Order[], arr2Param: Order[]): boolean {
    // Comprueba si las longitudes de los arreglos son diferentes,
    // si lo son, los arreglos no pueden ser iguales.
    if (arr1Param.length !== arr2Param.length) {
      return false;
    }

    let arr1: Order[] = JSON.parse(JSON.stringify(arr1Param));
    let arr2: Order[] = JSON.parse(JSON.stringify(arr2Param));

    // Ordena los arreglos por el campo _id antes de la comparación
    arr1.sort((a, b) => a._id.localeCompare(b._id));
    arr2.sort((a, b) => a._id.localeCompare(b._id));


    // Convierte los arreglos ordenados a cadenas JSON y los compara
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  /* Given a Order and an ArrayOrder, return the index if the Order exists in the ArrayOrder with the help of ComparePrice() */
  indexOfOrder(orders: Order[], order: Order) {
    return orders.findIndex(existingOrder => existingOrder._id == order._id);
  }

  isOrderIncomplete(order: Order, withOutIndic: boolean = false): boolean {
    try {
      return (
        //order.sellerName <= 0 ||
        !this.getBrokersByProvince(order.placeOfOrigin.province).includes(order.broke) ||
        !this.getBrokersByProvince(this.getProvinceOfPlaceOfDelivery(order)).includes(order.broke) ||
        order.placeOfOrigin.province == PROVINCES_CONST.CABA ||
        order.placeOfOrigin.afip <= 0 ||
        (order.howSell <= 0) ||
        order.tons <= 0 ||
        this.priceV.isPriceIncomplete(order.price, withOutIndic)
      );
    } catch (err) {
      return true;
    }
  }

  hasAnOldDate(order:Order){
    return this.priceV.hasAnOldDate(order.price);
  }

  isOrderIncompleteWithHowSellValidation(order: Order, howSellValidation: HowSellValidation, rucaAndSisa: RucaAndSisa/*, validPxQ: string*/): boolean {
    try {
      return ( (order.howSell <= 0 || howSellValidation.Validación != "OK" ||
          !itCanBeHowSell(order.howSell, rucaAndSisa)) /*|| validPxQ?.toLowerCase() != 'ok'*/ || this.isOrderIncomplete(order))
    } catch (err) {
      return true;
    }
  }

  isOrdersSelectedStatus(orders: Order[], status: number): boolean {
    try {
      orders.forEach((order) => {
        if (order.statusOrder != status) {
          throw new Error()
        }
      });
      return true;
    } catch (err) { }
    return false;
  }

  /* Given an order of type Order and an orderStatus of type number.
  Copy the command with a JSON parse and stringify.
  Access the 'statusOrder' property and copy the orderStatus.
  Accesses the 'hour' property and uses 'getHour()'
  And returns it */
  saveOrderStatus(order: Order, orderStatus: number, message: string, user: User) {
    let orderCopy: Order = JSON.parse(JSON.stringify(order));

    if (orderCopy.statusOrder != orderStatus &&//Si el nuevo estado es distinto al anterior y,
      orderCopy.statusOrder != orderStatusConst.RETURNED && //El estado de la orden es distinto a devuelta
      orderCopy.statusOrder != orderStatusConst.CONTRACT //El estado de la orden es distinto a contrato
    ) {

      orderCopy.message = message;
      if (order.message != message) {
        this.editOrder(order, orderTags.message, message, user);
      }
      //------------------\\
      orderCopy.statusOrder = orderStatus;
      this.editOrder(order, orderTags.statusOrder, orderStatus, user);

      //------------------\\
    }
    return orderCopy;
  }

  /* Given an orderSelected of type Order[].
  Copy the orders to a variable called ordersToReturn=[].
  Iterate over all selected orders by applying 'saveOrderStatus()'
  If the new state is different from the previous one, the new order is added to the arrayToReturn.
  We return the arrayToReturn */
  saveOrdersEdited(ordersSelected: Order[], orderStatus: number, message: string, user: User) {
    const quantOrders = ordersSelected.length;
    let ordersToReturn: Order[] = [];
    for (let i = 0; i < quantOrders; i++) {
      let orderToSave = this.saveOrderStatus(ordersSelected[i], orderStatus, message, user);

      //If the status of the order has changed, it is added to the array.
      if (orderToSave.statusOrder != ordersSelected[i].statusOrder) {
        ordersToReturn.push(orderToSave);
      }

    }
    return ordersToReturn;
  }

  searchOrderInArray(index: string, array: Order[]): Order | null {
    const foundOrder: Order | undefined = array.find((orderInStorage: Order) => orderInStorage._id == index);

    return foundOrder || null;
  }

  /* Given an order status, if Returned, open the order-returned modal.
  If CONFIRMED, open the order-confirmed modal. */
  openModal(orderStatus: number) {
    if (orderStatus == orderStatusConst.RETURNED) {
      this.dialog.open(DialogReturnedOrder);
      this.store.dispatch(setIsOrderReturned({ isOrderReturned: true }));
    } else if (orderStatus == orderStatusConst.CONFIRM) {
      this.dialog.open(DialogConfirmedOrder);
    }
  }

  editOrders(orders: Order[], user: User) {
    const quantOrders = orders.length;
    for (let i = 0; i < quantOrders; i++) {
      this.saveOrderStatus(orders[i], orderStatusConst.CONFIRM, ' ', user);
    }
  }

  getTypeCoinString(order: OrderExchange | Order) {
    return this.priceV.getTypeCoinString(order.price);
  }

  getTypeCoinAndPriceString(order: OrderExchange | Order) {
    return this.priceV.getTypeCoinAndPriceString(order.price);
  }

  getPriceOfCondition(order: OrderExchange | Order) {
    return this.priceV.getPriceOfCondition(order.price);
  }

  getPriceWithPoints(order: Order) {
    return this.priceV.getPriceWithPoints(order.price);
  }

  getRangeDateText(order: Order) {
    return this.priceV.getRangeDateText(order.price?.deliveryPeriod);
  }

  /*Given an index that represents the province number. Returns an array with all the towns in the province*/
  getLocality(index: number) {
    return getLocality(index);
  }

  getProvinceOfPlaceOfDelivery(order: Order) {
    return this.priceV.getProvinceOfPlaceOfDeliveryByPrice(order.price);
  }

  /* Reset selected order list */
  resetSelectedOrderList() {
    this.store.dispatch(setOrdersSelected({ orders: [] }));
  }

  isSyngenta(sellerCode: number) {
    return sellerCode == SYNGENTA_CODE_SELLER;
  }

  isSancor(sellerCode: number) {
    return isSancor(sellerCode);
  }

  getCampusSancor(order: Order) {
    const CAMPUS: any = SEDE_AGRUPACION;
    return CAMPUS[order.price.businessParticularities.campus] ?? '--';
  }

  isInDolars(order: Order) {
    return this.priceV.isInDolars(order?.price);
  }

  isSyngentaAgroAvc(sellerCode: number, subdivisionSyngenta: number, isAVC: number) {
    return sellerCode == SYNGENTA_CODE_SELLER && subdivisionSyngenta == SUB_SYNG_CONST.AGRO && isAVC == 1;
  }

  isSyngentaNidera(sellerCode:number, subdivisionSyngenta:number){
    return sellerCode == SYNGENTA_CODE_SELLER && subdivisionSyngenta == SUB_SYNG_CONST.NIDERA;
  }

  isSyngentaSemillas(sellerCode:number, subdivisionSyngenta:number){
    return sellerCode == SYNGENTA_CODE_SELLER && subdivisionSyngenta == SUB_SYNG_CONST.SEMILLAS;
  }

  orderIsSyngentaAgroAvc(order: Order) {
    try {
      const code = order.sellerData.codeS;
      const subdivision = order.price.businessParticularities.subdivisionSyngenta;
      const isAVC = order.price.businessParticularities.isAVC;
      return this.isSyngentaAgroAvc(code, subdivision, isAVC);
    } catch (er) {
      return false;
    }
  }

  getSubdivisionSyngenta(order: Order) {
    try {
      const subdivisions: any = SUBDIVISION_SYNGENTA;
      const subdivision = order.price.businessParticularities.subdivisionSyngenta;
      const isAVC = order.price.businessParticularities.isAVC == 1;
      let valueToReturn = subdivisions[subdivision];
      if (isAVC) {
        valueToReturn += ' - AVC'
      }
      return valueToReturn;
    } catch (er) {
      return '--'
    }
  }

  getSellerNameAndTons(order: Order) {
    try {
      if (order.sellerData.codeS > 0 && order.tons > 0) {
        return this.getSellerName(order) + ' - ' + order.tons + ' toneladas.';
      } else if (order.sellerData.codeS > 0) {
        return this.getSellerName(order);
      } else if (order.tons > 0) {
        return order.tons + ' toneladas.';
      }
    } catch (er) { }
    return '--'
  }

  getSellerName(order: Order) {
    const SELLERS: SellerObject = sellerWithData;
    return SELLERS[order?.sellerData?.codeS]?.nombre ?? '--';
  }

  //Numero de facturación para casos Nutrien
  getNroBilling(order: Order) {
    const nro=order?.price?.businessParticularities?.nroBilling;
    return nro?.length>0? nro: '--';
  }

  getTons(order: Order) {
    return order.tons > 0 ? formatNumber(order.tons) : '--';
  }

  getContractNumber(order: Order) {
    return order?.contractNumber > 0 ? order.contractNumber : '--';
  }

  getStatusOrder(order: Order) {
    const status: any = orderStates;
    return status[order?.statusOrder ?? 0] ?? '--';
  }

  /*Returns whether or not an order has a constraint.
If it is array, check that it has at least one element.
If it is not an array, check that the number is greater than 0 */
  getHaveRestriction(order: Order) {
    if (Array.isArray(order.buyer.onlySell)) {
      return order.buyer.onlySell.length > 0 || order.buyer.notSell.length > 0;
    }
    return order.buyer.notSell > 0 || order.buyer.onlySell > 0
  }


  /**
   * Returns a restriction message for a buyer based on their notSell and onlySell properties.
   * @param {Restriction} buyer - The buyer object containing notSell and onlySell properties.
   * @returns {string} The restriction message.
   */
  getRestriction(buyer: Restriction): string {
    const BUYERS: any = buyers;
    try {
      if (Array.isArray(buyer.notSell) && Array.isArray(buyer.onlySell)) {
        if (buyer.notSell.length > 0) {
          return `No le vende a ${buyer.notSell.map(id => BUYERS[id]).join(', ')}`;
        } else if (buyer.onlySell.length > 0) {
          return `Solo le vende a ${buyer.onlySell.map(id => BUYERS[id]).join(', ')}`;
        }
      } else {
        if (buyer.notSell > 0) {
          return `No le vende a ${BUYERS[buyer.notSell]}`;
        } else if (buyer.onlySell > 0) {
          return `Solo le vende a ${BUYERS[buyer.onlySell]}`;
        }
      }
    } catch (err) {
      // Optionally log the error if needed
    }

    return '--';
  }


  getPlaceOfOrigin(provinceIndex: number, localityIndex: number) {
    try {
      if (provinceIndex == -1) {
        return '--'
      } else if (localityIndex == -1) {
        let PROVINCES: any = provinces;
        let province = PROVINCES[provinceIndex];
        return province + ' - ';
      } else {
        let PROVINCES: any = provinces;
        let province = PROVINCES[provinceIndex];
        let locality: any = this.getLocality(provinceIndex)[localityIndex - 1];
        return province + " - " + locality.location;
      }
    } catch (err) {
      return '--';
    }
  }

  getWayPay(order: Order) {
    return this.priceV.getWayPay(order.price);
  }

  getBuyerName(order: Order) {
    return this.priceV.getBuyer(order.price);
  }

  getProductName(order: Order) {
    return this.priceV.getProductName(order.price);
  }

  getBusinessType(order: Order) {
    return this.priceV.getBusinessType(order.price);
  }

  getTypeCoinAndPrice(order: Order) {
    const TYPE_COIN: any = typeCoin;
    return TYPE_COIN[order?.price?.typeCoin ?? 0] + ' ' + formatNumber(order?.price?.price);
  }

  getWayPayNameText(order: Order) {
    const WAY_PAY: any = wayPay;
    return WAY_PAY[order?.price?.wayPay?.wayPayName];
  }

  getPlaceOfDelivery(placeOfDelivery: PlaceOfDelivery) {
    return this.priceV.getPlaceOfDelivery(placeOfDelivery);
  }

  getHarvest(order: Order) {
    return this.priceV.getHarvest(order.price);
  }

  getBusinessParticularities(businessParticularities: BusinessParticularities) {
    return this.priceV.getBusinessParticularities(businessParticularities);
  }

  getQualityParticularities(qualityParticularities: any /*QualityParticularities*/) {
    return this.priceV.getQualityParticularities(qualityParticularities);
  }

  getQualityIBM(order: Order) {
    return this.priceV.getQualityIBM(order.price);
  }

  getQuality(order: Order) {
    return this.priceV.getQuality(order.price);
  }

  getDeliveryPeriod(order: Order) {
    return this.priceV.getDeliveryPeriod(order?.price);
  }

  getPesification(order: Order) {
    return this.priceV.getPesification(order.price);
  }

  getBossNameCreator(order: Order) {
    return (order?.dataOfcreation?.teamOfcreator?.bossName ?? '--') + " " + (order?.dataOfcreation?.teamOfcreator?.bossLastName ?? '--');
  }

  getNameOfCreator(order: Order) {
    return order?.dataOfcreation?.nameOfcreator ?? '--';
  }

  getDataOfCreation(user: User) {
    return this.priceV.getDataOfCreation(user);
  }

  getGrouperCode(order: Order) {
    return this.priceV.getGrouperCode(order.price);
  }

  getObservations(order: Order): string {
    return order?.obsParam?.length > 2 ? order.obsParam : '--';
  }

  getQuotas(order: Order): Quotas[] {
    try {
      return order?.quotas ? (JSON.parse(order?.quotas) ?? []) : [];
    } catch (error) {
      return [];
    }
  }

  haveQuotas(order: Order): boolean {
    try {
      const parseQuotas = JSON.parse(order.quotas);
      return parseQuotas && this.isValidQuota(parseQuotas[0])
    } catch (error) { }
    return false;
  }

  /* Add order in eraserOrder list of LocalStorage */
  addOrderInEraserOrder(order: Order, user: User) {
    let eraserOrders: Order[] = this.getEraserOrders();
    let newOrder = JSON.parse(JSON.stringify(order));
    newOrder._id = eraserOrders.length + 1;
    newOrder.dataOfcreation = this.getDataOfCreation(user);
    newOrder.quotas = JSON.stringify(this.getValidQuotas(order));
    eraserOrders.push(newOrder);
    localStorage.setItem(
      localStorageNames.eraserOrders,
      JSON.stringify(eraserOrders)
    );
  }

  /* Get eraserOrder list of LocalStorage */
  getEraserOrders(): Order[] {
    const eraserStr = localStorage.getItem(localStorageNames.eraserOrders)
    return eraserStr ? JSON.parse(eraserStr) : [];
  }

  /* Delete order in eraserOrder list of LocalStorage */
  deleteOrderInEraserOrder(order: Order) {
    let eraserOrders: Order[] = this.getEraserOrders();
    let index = eraserOrders.findIndex((o) => o._id == order._id);
    eraserOrders.splice(index, 1);
    localStorage.setItem(
      localStorageNames.eraserOrders,
      JSON.stringify(eraserOrders)
    );
  }

  /* Delete orders in eraserOrder list of LocalStorage */
  deleteOrdersInEraserOrder(orders: Order[]) {
    let eraserOrders: Order[] = this.getEraserOrders();
    for (let i = 0; i < orders.length; i++) {
      let index = eraserOrders.findIndex((o) => o._id == orders[i]._id);
      eraserOrders.splice(index, 1);
    }
    localStorage.setItem(localStorageNames.eraserOrders, JSON.stringify(eraserOrders));
  }

  /* Edit order in eraserOrder list of LocalStorage */
  editOrderInEraserOrder(order: Order) {
    let eraserOrders: Order[] = this.getEraserOrders();
    let index = eraserOrders.findIndex((o) => o._id == order._id);
    eraserOrders[index] = order;
    localStorage.setItem(localStorageNames.eraserOrders, JSON.stringify(eraserOrders));
  }

  saveOrder(order: Order, user: User, showLoading: boolean = false) {
    let orderToSave: Order = this.setMainFeaturesOfOrders(order, user);
    this.createOrder(orderToSave, showLoading);
  }

  saveOrders(orders: Order[], user: User) {
    this.store.dispatch(setModalLoading({ isLoading: true }));

    for (let i = 0; i < orders.length; i++) {
      this.saveOrder(orders[i], user, i == orders.length - 1); //Mostramos el modal en la ultima orden.
    }
  }

  /** 
   * Given an order. 
   * It uses the orderService and the 'createOrder()' function, to make an http request.
   * If the request is successful, it will dispatch the 'setModalLoading()' action with the value 'false'.
   * @param { Order } order - The order to save.
   * @param { boolean } isShowLoading - A boolean value to show the loading spinner.
   * @returns { void }  
   * */
  createOrder(order: Order, isShowLoading: boolean) {
    this.setLoading(isShowLoading, true);    
    return this.orderSvc.createOrder(order).pipe(
      catchError((error) => {
        //Si el backend nos devuelve un error, lo capturamos y hacemos las acciones correspondientes
        console.error(error.error);
        this.setLoading(isShowLoading, false);
        return [];
      })
    ).subscribe((res) => {
      this.setLoading(isShowLoading, false, true);
    });
  }

  updateOrder(order: Order, user: User, isShowLoading:boolean) {
    this.setLoading(isShowLoading, true);
    let orderToSave: Order = this.setMainFeaturesOfOrders(order, user);

    this.orderSvc.updateOrder(orderToSave, orderToSave._id).pipe(
      catchError((error) => {
        console.error(error.error);
        this.setLoading(isShowLoading, false);
        return [];
      })
    ).subscribe(res => {
      this.setLoading(isShowLoading, false, true);
    });
  }

  onlyUpdateOrder(order: Order, user:User) {
    this.orderSvc.updateOrder(order, order._id).pipe(
      catchError( (error) => {
        console.error(error.error);
        return [];
      })
    ).subscribe(res => {  
    });
  }

  setLoading(isShowLoading:boolean, isLoading: boolean, isNavigate:boolean=false) {
    if(isShowLoading){
      this.store.dispatch(setModalLoading({isLoading: isLoading}))
      if(isNavigate){
        this.router.navigate(["/" + NAVEGATION.ORDER_BOARD]);
      }
    }
  }

  /* Given a command, set default fields */
  setMainFeaturesOfOrders(order: Order, user: User) {
    let orderToSave: Order = JSON.parse(JSON.stringify(order));
    orderToSave.message = ''; //Seteamos mensaje en 0
    orderToSave.statusOrder = orderStatusConst.PENDING; //Seteamos la orden en pendiente
    orderToSave.dataOfcreation = this.getDataOfCreation(user); //Le ponemos los datos de creacion
    orderToSave.quotas = JSON.stringify(this.getValidQuotas(order)); //Llevamos a string los cupos
    orderToSave.orderDestination.orderOrigin = this.getNumberOrderFromBoard(order); //Seteamos el origen de la orden

    const validFor = orderToSave.price.deliveryPeriod?.validFor; //Si tiene validFor, lo seteamos, sino, lo dejamos vacio
    orderToSave.price.deliveryPeriod.validFor = validFor ? validFor : [];

    //Si tiene modificaciones, buscamos cuales son
    if (orderToSave.orderDestination.orderOrigin == orderOriginConst.desdeTableroModificada) {
      const priceOriginal: Price | undefined = this.priceV.searchPriceInPriceBoard(order.price);
      if (priceOriginal) {
        orderToSave.arrayMods = comparePricesKeys(priceOriginal, order.price);
      } else {
        orderToSave.arrayMods = []
      }
    } else {
      orderToSave.arrayMods = []
    }
    
    if (orderToSave.buyer.notSell.includes(COMMODITIES_CODE)) {
      //Si marcó que no le vende a Commodities, 
      //le quitamos el comprador y le ponemos que la compra un exportador
      orderToSave.price.observations.buyer = -1;
      orderToSave.price.observations.isPort = 1;
    } else if (orderToSave.buyer.onlySell.length > 0) {
      //Si es un solo comprador: 
      //- Se lo ponemos en el campo de buyer
      //Si tiene más de dos compradores, le ponemos buyer -1;
      //Nos fijamos si es Commodities o Hughes. Si no es ninguno, le ponemos que es exportador
      orderToSave.price.observations.buyer = orderToSave.buyer.onlySell.length == 1 ? orderToSave.buyer.onlySell[0] : -1;
      orderToSave.price.observations.isPort =
        (orderToSave.buyer.onlySell.includes(COMMODITIES_CODE) || orderToSave.buyer.onlySell.includes(HUGHES_CODE_BUYER)) ? -1 : 1;
    }
    return orderToSave;
  }

  /* Given an order. It takes its 'price' field, parses it, and returns a number:
If the business condition does NOT have _id, it returns that it is a manual order.
If the business condition HAS _id, it can be from the dashboard or from the modified dashboard.
To know this, it takes the prices that are in the localStorage (that match the ones in the conditions table), looks for the price by the _id that we pass and compares with a function to compare Prices if it is the same.
If it is the same, then it is from the dashboard, if it is not the same, then it is from the modified dashboard. */
  getNumberOrderFromBoard(order: Order) {
    //Si no tiene _id, es manual
    if (this.getIsOrderManualByPrice(order)) {
      return orderOriginConst.manual;
    } else {
      //Si tiene _id, puede ser del tablero o del tablero modificado
      const price: Price = JSON.parse(JSON.stringify(order.price));
      const priceFromBoard: Price | undefined = this.priceV.searchPriceInPriceBoard(price);

      //Si la encuentra, la compara con la que tiene el pedido
      if (priceFromBoard) {
        if (this.priceV.compareAllFields(price, priceFromBoard)) {
          return orderOriginConst.desdeTablero;
        }
        return orderOriginConst.desdeTableroModificada;
      }

      //Si no la encuentra, es manual
      return orderOriginConst.manual;
    }
  }

  /* Given a order, return true if the order is manual looking at the price id */
  getIsOrderManualByPrice(order: Order) {
    return !(order.price._id.length > 4 && order.price._id != OBJECT_ID_VOID);
  }

  /** 
   * Given a order to save, a field and a value.
   * If the value is number, consume 'editOrderNumber' from the service.
   * If string, consume 'edirOrderString' from service 
   * */
  editOrder(orderToSave: Order, field: string, value: any, user: User) {
    if (field) {
      this.orderSvc.editOrderNumber(orderToSave._id, field, value, user).pipe(
        catchError((error) => {
          console.error(error.error);
          this.store.dispatch(setErrorStruct({error: {color: '#fff', message: 'No se pudo editar la orden, reintente.', isVisible: true}}));
          return [];
        })
      ).subscribe(res => {
        console.log("Actualización del estado: ", res)
      });
    }
  }

  /* Get historical orders with orderSvc */
  getHistorical(id: string) {
    return this.orderSvc.getHistorical(id)
  }

  /* Get historical orders with orderSvc */
  getFirstVersion(id: string) {
    return this.orderSvc.getFirstVersion(id)
  }

  //VALIDATIONS
  /* Given a order, it analyzes its quotas[], returns the ones that are valid (generally the last one can be invalid). */
  getValidQuotas(order: Order): Quotas[] {
    try {
      let quotas: Quotas[] = [];
      let orderQuotas = order.quotas ? JSON.parse(order.quotas) : [];
      for (let i = 0; i < orderQuotas.length; i++) {
        if (this.isValidQuota(orderQuotas[i])) {
          quotas.push(orderQuotas[i]);
        }
      }
      return quotas;
    } catch (err) {
      return []
    }
  }

  isValidQuota(quota: Quotas) {
    return isValidQuota(quota)
  }

  //--------------------------------------------\\

  getQuotasMessage(order: Order) {
    const parseQuota = JSON.parse(order.quotas);
    const quantQuotas = parseQuota ? parseQuota.length : 0;
    let stringToReturn = ''
    for (let i = 0; i < quantQuotas; i++) {
      let quotas = parseQuota[i].date ? parseQuota[i].date : 'Fecha inválida.';
      let trucks = parseQuota[i].trucks ? parseQuota[i].trucks + ' camiones' : 'Camiones inválidos.';
      stringToReturn += quotas + ' - ' + trucks + '\n';
    }
    return stringToReturn
  }

  getOrderById(id: string) {
    return this.orderSvc.getOrderByID(id);
  }
  getOrderByContract(contract: number) {
    return this.orderSvc.getOrderByContract(contract)
  }

  getBrokersByProvince(province: number) {
    const brokerValid: any = brokerValids;
    return brokerValid[province] ? brokerValid[province] : [];
  }

  //Filtramos el arreglo de COMMISIONES_IBM para obtener la comisión que corresponde a la orden
  getCommisionByOrder(order: Order, typeCommision: "C" | "V" | ""): Commission {
    let copyOrder = JSON.parse(JSON.stringify(order));
    const product = order.price.productName;
    const buyer = order.price.observations.buyer;
    const seller = order.sellerData.codeS;
    const isAC = CLIENTES_AC_IBM.includes(seller) ? 'AC' : '';
    const placeOfDelivery = getPlaceOfDeliveryIBM(copyOrder);
    const isSyngentaAgroAvc = this.isSyngentaAgroAvc(order.sellerData.codeS, order.price.businessParticularities.subdivisionSyngenta, order.price.businessParticularities.isAVC);
    //Si es syngenta y no es avc, la comision es la default.
    if (seller == SYNGENTA_CODE_SELLER && !isSyngentaAgroAvc) {
      return COMMISION_VOID;
    }

    let arrayOfPosibleCommisions: any[] = [];
    //Filtramos las posibles comisiones

    arrayOfPosibleCommisions = this.filterPosibleCommisions(product, buyer, seller, placeOfDelivery, isAC, typeCommision);
    //En caso de que no haya comisiones, volvemos a filtrar pero sin tipo de comision;
    if (arrayOfPosibleCommisions.length == 0) {
      arrayOfPosibleCommisions = this.filterPosibleCommisions(product, buyer, seller, placeOfDelivery, isAC);
    }

    //Si sigue siendo 0, retornamos la comisión inicial..
    if (arrayOfPosibleCommisions.length == 0) {
      return COMMISION_VOID;
    } else if (arrayOfPosibleCommisions.length == 1) {
      //Si es 1, retornamos la comision comprador o vendedor dependiendo el tipo de comisión seteada.
      const typeCom = arrayOfPosibleCommisions[0].tipoCom;
      if (typeCom == 'C') {
        return { buyer: arrayOfPosibleCommisions[0].percentage, seller: SELLER_COMMISION };
      } else if (typeCom == 'V') {
        return { buyer: BUYER_COMMISION, seller: arrayOfPosibleCommisions[0].percentage };
      }
    } else if (arrayOfPosibleCommisions.length > 1) {
      //Si es mayor a 1, Obtenemos la primera que es la mas prioritaria.

      const typeCom = arrayOfPosibleCommisions[0].tipoCom;
      if (typeCom == 'C') {
        return { buyer: arrayOfPosibleCommisions[0].percentage, seller: SELLER_COMMISION };
      } else if (typeCom == 'V') {
        return { buyer: BUYER_COMMISION, seller: arrayOfPosibleCommisions[0].percentage };
      }
    }

    return COMMISION_VOID;
  }

  filterPosibleCommisions(product: number, buyer: number, seller: number, placeOfDelivery: number, isAC: '' | 'AC', typeCommision: "C" | "V" | "" = "") {
    if (typeCommision == 'C') {
      return COMMISION_IBM.filter((commision) => {
        return (commision.product == 0 || commision.product == product) &&
          (commision.comprador == buyer) &&
          (commision.vendedor == 0 || commision.vendedor == seller) &&
          (commision.placeOfDelivery == 0 || commision.placeOfDelivery == placeOfDelivery) &&
          (commision.tipoCom == typeCommision)
      });
    } else if (typeCommision == 'V') {
      return COMMISION_IBM.filter((commision) => {
        return (commision.product == 0 || commision.product == product) &&
          (commision.comprador == 0 || commision.comprador == buyer) &&
          (commision.vendedor == seller) &&
          (commision.placeOfDelivery == 0 || commision.placeOfDelivery == placeOfDelivery) &&
          (commision.unidadDeNegocio == isAC) &&
          (commision.tipoCom == typeCommision)
      });
    } else {
      return COMMISION_IBM.filter((commision) => {
        return (commision.product == 0 || commision.product == product) &&
          (commision.comprador == 0 || commision.comprador == buyer) &&
          (commision.vendedor == 0 || commision.vendedor == seller) &&
          (commision.unidadDeNegocio == isAC) &&
          (commision.placeOfDelivery == 0 || commision.placeOfDelivery == placeOfDelivery)
      });
    }
  }

  /*getValidatePxQ(PxQ: PxQIBM) {
    const PxQString: PxQIBMString = {
      CEREAL: PxQ.CEREAL,
      TONELA: formatNumberWithDecimals(PxQ.TONELA, 3),
      PRECIO: formatNumberWithDecimals(PxQ.PRECIO, 2),
      MONEDA: PxQ.MONEDA,
      IMPBON: formatNumberWithDecimals(PxQ.IMPBON, 2),
      PORBON: formatNumberWithDecimals(PxQ.PORBON, 2),
      MONBON: PxQ.MONBON,
    }

    return this.orderSvc.getValidatePxQ(PxQString);
  }*/

  getValidateHowSell(codeS:number, howSell:number, broker:number ) {
    return this.orderSvc.getValidateHowSell(codeS, howSell, broker)
  }

  getValidateRucaAndSisa(codeS:number, howSell:number) {
    return this.orderSvc.getValidateRucaAndSisa(codeS, howSell)
  }
}

/*Given an index that represents the province number. Returns an array with all the towns in the province*/
export function getLocality(index: number): any {
  let location: any = [];
  switch (index) {
    case PROVINCES_CONST.BUENOS_AIRES: location = buenosAiresLocalities; break;
    case PROVINCES_CONST.CABA: location = cabaLocalities; break;
    case PROVINCES_CONST.CATAMARCA: location = catamarcaLocalities; break;
    case PROVINCES_CONST.CHACO: location = chacoLocalities; break;
    case PROVINCES_CONST.CHUBUT: location = chubutLocalities; break;
    case PROVINCES_CONST.CORDOBA: location = cordobaLocations; break;
    case PROVINCES_CONST.CORRIENTES: location = corrientesLocalities; break;
    case PROVINCES_CONST.ENTRE_RIOS: location = entreRiosLocalities; break;
    case PROVINCES_CONST.FORMOSA: location = formosaLocalities; break;
    case PROVINCES_CONST.JUJUY: location = jujuyLocalities; break;
    case PROVINCES_CONST.LA_PAMPA: location = laPampaLocalities; break;
    case PROVINCES_CONST.LA_RIOJA: location = laRiojaLocalities; break;
    case PROVINCES_CONST.MENDOZA: location = mendozaLocalities; break;
    case PROVINCES_CONST.MISIONES: location = misionesLocalities; break;
    case PROVINCES_CONST.NEUQUEN: location = neuquenLocalities; break;
    case PROVINCES_CONST.RIO_NEGRO: location = rioNegroLocalities; break;
    case PROVINCES_CONST.SALTA: location = saltaLocalities; break;
    case PROVINCES_CONST.SAN_JUAN: location = sanJuanLocalities; break;
    case PROVINCES_CONST.SAN_LUIS: location = sanLuisLocalities; break;
    case PROVINCES_CONST.SANTA_CRUZ: location = santaCruzLocalities; break;
    case PROVINCES_CONST.SANTA_FE: location = santaFeLocalities; break;
    case PROVINCES_CONST.SANTIAGO_DEL_ESTERO: location = santiagoDelEsteroLocalities; break;
    case PROVINCES_CONST.TIERRA_DEL_FUEGO: location = tierraDelFuegoLocalities; break;
    case PROVINCES_CONST.TUCUMAN: location = tucumanLocalities; break;
  }
  return Object.values(location);
}

export function isValidQuota(quota: Quotas) {
  return isValidDate(quota.date) != '--' && quota.trucks > 0 && quota.trucks % 1 == 0;
}

/*Given an array of orders. Performs a summation of all the values stored in each 'tons' field*/
export function getSumOfTons(orders: Order[]): number {
  let sum = 0; // Inicializa la variable sumatoria
  // Recorre el arreglo de órdenes y suma los valores en el campo 'tons'
  for (const order of orders) {
    sum += order.tons;
  }
  return parseFloat(sum.toFixed(3)); // Retorna la sumatoria con un máximo de 3 decimales
}

/* This function create an orderList with Pipes */
export function getOrderListWithPipes(orders: Order[], user: User, filter: OrderString, search: string, buyerType: string, conditionType: string, conditionsFilters: number[], showContracts: boolean = false): Order[] {
  try {
    let newOrderList = JSON.parse(JSON.stringify(orders));
    //| filterByRepresentation: isRepresentation:user
    const pipe6 = new FilterAllOrderPipe();
    return pipe6.transform(newOrderList, user.area == areas.comercial, filter, search, buyerType, conditionType, showContracts, conditionsFilters)
  } catch (error) {}
  return orders;
}

export function resetAllOrders(store: Store<appState>) {
  //Reset generate order
  store.dispatch(resetOrderToGenerate());

  //Reset generate orders
  store.dispatch(setOrdersToGenerate({ orders: [orderInitialState] }));
  store.dispatch(setIndexToEdit({ indexToEdit: 0 }));

  //Reset validation
  store.dispatch(setValidation({ validation: validationInitialState }));
  store.dispatch(setHowSellValidation({ howSellValidation: howSellValidationInitialState }));
  store.dispatch(setRucaAndSisa({ rucaAndSisa: rucaAndSisaInitialState }));
  store.dispatch(setValidPxQ({ validPxQ: '' }));

  //Reset generate order exchange
  store.dispatch(resetOrderExchangeToGenerate());

  store.dispatch(setEditGenerateOrderExchange({ isEditGenerate: false }));
  store.dispatch(setIsGenerateOrderExchange({ isGenerateOrderExchange: false }));

  // Reset conditions
  store.dispatch(resetPriceToGenerate());
}

//Retorna true si el código pasado por parametro pertenece al de sancor.
export function isSancor(sellerCode: number) {
  return sellerCode == SANCOR_CODE_SELLER;
}

//Retorna true si es el cuit de Nutrien
export function isNutrienCuit(cuit: string) {
  return cuit == NUTRIEN_CUIT;
}

//Retorna true si el comprador de la orden es Commodities o Hughes
export function isCommoditiesOrHughes(order: Order) {
  return isCodeCommoditiesOrHughesBuyer(order.price.observations.buyer);
}

export function isCodeCommoditiesOrHughesBuyer(buyerCode: number) {
  return [COMMODITIES_CODE, HUGHES_CODE_BUYER].includes(buyerCode);
}

export function isSellerCommoditiesOrHughes(sellerCode: number) {
  return sellerCode == COMMODITIES_CODE_SELLER || sellerCode == HUGHES_CODE_SELLER;
}

/**
 * Given an HTML table of some orders. Exports them to csv
 * @param {HTMLElement} table - The table to export
 * @param {string} fileName - The name of the file to download
 * @returns {void}
 * * */
/*export function exportToCSV(table: HTMLElement | null, fileName: string) {
  if (table) {
    const rows = Array.from(table.querySelectorAll('tr'));
    const headerArray = Array.from(rows[0].querySelectorAll('th'));

    //Buscamos el indice de esas columnas y les restamos 1 porque se elimina la primer fila de las celdas
    const indexPrecio = headerArray.findIndex(column => column.textContent == 'Precio') - 1;
    const indexToneladas = headerArray.findIndex(column => column.textContent == 'Toneladas') - 1;
    const indexMesas = headerArray.findIndex(column => column.textContent == 'Mesas') - 1;

    const csv = rows.map((row) => {
      const cells = Array.from(row.querySelectorAll('th, td'));
      cells.shift();

      return cells.map((cell, index) => {
        if (index == indexPrecio || index == indexToneladas) {
          //Eliminamos el punto y reemplazamos la "," por un "."
          return cell.textContent?.replace(/\./g, '').replace(/,/g, '.');
        } else if (index == indexMesas) {
          return cell.textContent?.replace(/Creada/g, ' - Creada');
        }
        return cell.textContent?.replace(/\n/g, '').trim();
      }).join(';');
    }).join('\n');

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}*/

export function copyOrder(order: Order, store: Store<appState>, router: Router) {
  let copyOrder:Order = JSON.parse(JSON.stringify(order));
  copyOrder._id = '';
  copyOrder.created_at = '--';
  copyOrder.message = ' ';
  copyOrder.statusOrder = orderInitialState.statusOrder;
  copyOrder.contractNumber = -1;
  copyOrder.idExchange = orderInitialState.idExchange;
  copyOrder.commision.buyer = COMMISION_BUYER_INITIAL;
  copyOrder.price.observations.buyer = -1;
  copyOrder.price.observations.isPort = -1;

  //copyOrder.commision.seller=COMMISION_SELLER_INITIAL; No se reinicia porque el vendedor puede tener una comisión pactada
  store.dispatch(setOrderToGenerate({ order: copyOrder }));
  store.dispatch(setIsGenerateToFix({ isGenerateToFix: order?.price?.indicators?.length > 0 }))
  router.navigate(['/' + NAVEGATION.GENERATE_MANUAL_ORDER]);
}

/*Given a business condition, the redux store and a router.
We verify that the business condition is to be set and we set it in the store.
We also assign the price to create an order in the store.
Finally, we use the router to create an order from the dashboard.*/
export function createOrderWithPrice(price: Price, store: Store<appState>, router: Router, route: string = NAVEGATION.GENERATE_PRICE_BOARD_ORDER) {
  const haveIndic: boolean = priceHaveIndicators(price);
  let priceAux: Price = JSON.parse(JSON.stringify(price));

  if (haveIndic) {
    //Eliminamos los indicadores que el campo 'enabled' sea false.
    priceAux.indicators = priceAux.indicators.filter((indic: Indicator) => { return indic.enabled })

    //Ahora analizamos la fecha de inicio del primer indicador. Si es anterior hay que colocarle la fecha de hoy.
    const toDay= getDate()
    if(priceAux.indicators.length > 0 && 
      isEarlierDate(priceAux.indicators[0].fixationPeriod.startDate, toDay, "-")){
      priceAux.indicators[0].fixationPeriod.startDate = toDay
    }
  }

  store.dispatch(setIsGenerateToFix({ isGenerateToFix: haveIndic }))
  store.dispatch(setPriceToGenerateInOrder({ price: priceAux }));
  router.navigate(['/' + route]);
}