import { createAction, props } from "@ngrx/store";
import { ErrorStruct, InfoQuotas, ModalContract } from "src/app/shared/interfaces/options.interface";
import { Price } from "src/app/shared/interfaces/price.interface";

//Menu
export const hiddenMenu= createAction('[MENU] Hidden Menu');
export const showMenu= createAction('[MENU] Show Menu');
export const setLocation= createAction('[MENU] Set Location',
props<{ location: string }>());

//Claim
export const getClaim= createAction('[CLAIM] Get Claim');
export const getClaimSuccess= createAction('[CLAIM] Get Claim Success',
props<{ temp: number }>());
export const getClaimFailed= createAction('[CLAIM] Get Claim Failed');

//Visual
export const setSizeView= createAction('[SIZE VIEW] Set Size View',
props<{size: number}>())
export const setOrderCss= createAction('[CSS DESKTOP] Set Orders Css Desktop',
props<{ css: string }>());
export const setGoalsCss= createAction('[CSS DESKTOP] Set Goals Css Desktop',
props<{ css: string }>());

export const setHeaderInMobile = createAction('[HEADER] Set Header In Mobile',
props<{ header: string }>());
//Date
export const setHour= createAction('[DATE] Set Hour',
props<{ hour: string }>());
export const setDate= createAction('[DATE] Set Date',
props<{ date: string }>());


export const goToInitForm= createAction('[FORM] Go To Init Form',
props<{ goToInitForm: boolean }>());

//---- CHANGES AND ERRORS VISIBLES IN BOARD ----\\
/*
export const setErrorVisibles= createAction('[CHANGES] Set Error Visibles',
props<{ isErrorVisible: boolean }>());
export const setMessage= createAction('[CHANGES] Set Message Visible',
props<{ message: string }>());*/

export const setErrorStruct= createAction('[CHANGES] Set Error Struct',
props<{ error: ErrorStruct }>());

export const setChangesVisibles= createAction('[CHANGES] Set Changes Visibles',
props<{ isChangeVisible: boolean }>());

export const setModalLoading= createAction('[MODAL] Set Modal Loading',
props<{ isLoading: boolean }>());
export const setModalSuccess= createAction('[MODAL] Set Modal Success', 
props<{ isSuccess: boolean }>());


export const setIdentificatorFieldSelected= createAction('[FORM] Set IDENTIFICATOR FIELD',
props<{ identificator: number }>());

export const setShowAddBuyer= createAction('[ADD BUYER] Set Show Add Buyer',
props<{ isShowAddBuyer: boolean }>());


//Show error change price
export const setShowErrorChangePrice= createAction('[ERROR CHANGE PRICE] Set Show Error Change Price',
props<{ showErrorChangePrice: boolean }>());
//Show warning change type coin
export const setShowWarningChangeTypeCoin= createAction('[WARNING CHANGE TYPE COIN] Set Show Warning Change Type Coin',
props<{ price: any, isShow:boolean }>());

//Show delete price
export const setShowDeletePrice= createAction('[DELETE PRICE] Set Show Delete Price',
props<{ idPrice: any, isShow:boolean }>());

export const setIsClickedInConfirm= createAction('[FORM] Set Is Clicked In Confirm', props<{ isClickedInConfirm: boolean }>());

export const setModalContract= createAction('[MODAL CONTRACT] Set Show Modal Contract', props<{ modalContract: ModalContract }>());
export const addModalContractSuccess= createAction('[MODAL CONTRACT] Add Modal Contract Failed', props<{ arrayOfSuccess: any[] }>());
export const addModalContractFailed= createAction('[MODAL CONTRACT] Add Modal Contract Success', props<{ arrayOfFaileds: any[], isIndicators:boolean }>());
export const setModalContractType= createAction('[MODAL CONTRACT] Set Modal Contract Type', props<{ typeModal: number }>());

export const setInfoQuotas=createAction('[INFO QUOTAS] Set Info Quotas', props<{ dataOfQuotas: InfoQuotas }>());
export const setInfoIndicators=createAction('[INFO INDICATORS] Set Info Indicators', props<{ infoIndicators: string }>());



export const setIsGenerateToFix= createAction('[GENERATE TO FIX] Set Generate To Fix', props<{ isGenerateToFix: boolean }>());

export const priceToWriteObservation= createAction('[OBSERVATION MESSAGE] Set Price To Write Observation Message', props<{ price:Price}>());

export const isCaseSyngentaAgroAvc= createAction('[CASE SYNGENTA AGRO AVC] Set Case Syngenta Agro Avc', props<{ isCaseSyngentaAgroAvc: boolean }>());

export const setIsEditedOrderFromBoard = createAction('[ORDER] Set Is Editer Order From Board', props<{ isEditedOrderFromBoard: boolean }>());


export const setSearchBoard = createAction('[BOARD] Set Search Board', props<{ search: string }>());
export const setBoardSelected = createAction('[BOARD] Set Board Selected', props<{ boardSelected: string }>());
export const setConditionTypeSelectedBoard = createAction('[BOARD] Set Condition Type Selected Board', 
props<{ conditionTypeSelected: string }>());
export const setConditionFilterBoard = createAction('[BOARD] Set Condition Filter Board',
props<{ conditionFilter: number[] }>());