<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(11)">Bonificación</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" (change)="setOptionOpenWithCheck($event,11)" 
            (click)="verifyBonification(bonification)" [checked]="getIsBonification()" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="option-content-particularity pointer" (click)="setTypeBonification(1)">
            <span class="option-name">Entrega anticipada</span>
            <div class="actions">
                <input type="radio" [checked]="identificator==1" class="radio" />
            </div>
        </div>

        <div *ngIf="identificator==1">
            <span class="option-name">Seleccionar nueva fecha y monto de bonificación</span>

            <div class="field">
                <span class="field-title">Fecha<span class="asterisk">*</span></span>

                <div>
                    <div class="form-control input-date border-green">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Periodo de entrega" (dateChange)="setDate($event, 'startDate')">
                            <!-- (dateChange)="setElement($event,1)" -->
                            <input matEndDate placeholder="" (dateChange)="setDate($event, 'endDate')"> <!-- (dateChange)="setElement($event,2)" -->
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker" class="logo"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>
                </div>
            </div>
            
            <div class="field">
                <span class="field-title">Monto<span class="asterisk">*</span></span>

                <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="bonification.amount.quantity"
                (valueOut)="setMount($event)"></number-input>
            </div>
            <span class="message-error" *ngIf="isShowMessage()">Debes ingresar una moneda</span>
            
            <div class="field">
                <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoin($event)"
                [value]="bonification.amount.typeCoin"></radio-button-input>
            </div>
        </div>

        <div class="option-content-particularity pointer" (click)="setTypeBonification(2)">
            <span class="option-name">Cambio de destino</span>
            <div class="actions">
                <input type="radio" [checked]="identificator==2" class="radio" />
            </div>
        </div>

        <div *ngIf="identificator==2">
            <span class="option-name">Seleccionar nuevo lugar de entrega y monto</span>

            <select-input [array]="array" [OBJECT]="PLANTAS_INTERIOR_MAP"
            [placeHolder]="'Ingresar lugar de entrega'" [value]="getPlaceOfDelivery()" (valueOut)="setPlaceOfDelivery($event)"></select-input>

            <div class="field">
                <span class="field-title">Monto<span class="asterisk">*</span></span>

                <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="bonification.amount.quantity"
                (valueOut)="setMount($event)"></number-input>
            </div>
            <span class="message-error" *ngIf="isShowMessage()">Debes ingresar una moneda</span>

            <div class="field">
                <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoin($event)"
                [value]="bonification.amount.typeCoin"></radio-button-input>
            </div>
        </div>


        <div class="option-content-particularity pointer" (click)="setTypeBonification(3)">
            <span class="option-name">Sustentabilidad</span>
            <div class="actions">
                <input type="radio" [checked]="identificator==3" class="radio" />
            </div>
        </div>
        <div *ngIf="identificator==3">
            <span class="option-name">Seleccionar monto por sustentabilidad</span>

            <div class="field">
                <span class="field-title">Monto<span class="asterisk">*</span></span>

                <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="bonification.amount.quantity"
                (valueOut)="setMount($event)"></number-input>
            </div>
            <span class="message-error" *ngIf="isShowMessage()">Debes ingresar una moneda</span>

            <div class="field">
                <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoin($event)"
                [value]="bonification.amount.typeCoin"></radio-button-input>
            </div>
        </div>


        <div class="option-content-particularity pointer" (click)="setTypeBonification(4)">
            <span class="option-name">Almacenaje</span>
            <div class="actions">
                <input type="radio" [checked]="identificator==4" class="radio" />
            </div>
        </div>
        <div *ngIf="identificator==4">
            <span class="option-name">Seleccionar monto por almacenaje</span>

            <div class="field">
                <span class="field-title">Monto<span class="asterisk">*</span></span>

                <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="bonification.amount.quantity"
                (valueOut)="setMount($event)"></number-input>
            </div>
            <span class="message-error" *ngIf="isShowMessage()">Debes ingresar una moneda</span>

            <div class="field">
                <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoin($event)"
                [value]="bonification.amount.typeCoin"></radio-button-input>
            </div>
        </div>

        <div class="option-content-particularity pointer" (click)="setTypeBonification(5)">
            <span class="option-name">Rolado</span>
            <div class="actions">
                <input type="radio" [checked]="identificator==5" class="radio" />
            </div>
        </div>

        <div *ngIf="identificator==5">
            <span class="option-name">Seleccionar monto por rolado</span>

            <div class="field">
                <span class="field-title">Monto<span class="asterisk">*</span></span>

                <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="bonification.amount.quantity"
                (valueOut)="setMount($event)"></number-input>
            </div>
            <span class="message-error" *ngIf="isShowMessage()">Debes ingresar una moneda</span>

            <div class="field">
                <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoin($event)"
                [value]="bonification.amount.typeCoin"></radio-button-input>
            </div>
        </div>

        <div class="option-content-particularity pointer" (click)="setTypeBonification(6)">
            <span class="option-name">Convenido</span>
            <div class="actions">
                <input type="radio" [checked]="identificator==6" class="radio" />
            </div>
        </div>

        <div *ngIf="identificator==6">
            <span class="option-name">Seleccionar monto por convenido</span>

            <div class="field">
                <span class="field-title">Monto<span class="asterisk">*</span></span>

                <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="bonification.amount.quantity"
                (valueOut)="setMount($event)"></number-input>
            </div>

            <span class="message-error" *ngIf="isShowMessage()">Debes ingresar una moneda</span>

            <div class="field">
                <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoin($event)"
                [value]="bonification.amount.typeCoin"></radio-button-input>
            </div>
        </div>

    </div>
</div>