//UNIDADES DE TRABAJO:
export const workUnit = {
	1: 'Casa Central',
	2: 'Sucursal',
	3: 'Representación Córdoba',
	4: 'Representación Chacabuco',
	5: 'Representación Bolivar',
	11: 'Casa Central'
}


//Mesas
export const teams ={
	1:'Ferrara',
	2:'Gatti',
	3:'Martinez',
	4:'Ettolitre',
	8:'Echeverry', //Representacion Bolivar
	9:'Martinetti', //Representación Chacabuco
	10:'Ferraris' //Representación Cordoba
}

export const TEAMS_REPRESENTATION={
	8:'Echeverry', //Representacion Bolivar
	9:'Martinetti', //Representación Chacabuco
	10:'Ferraris' //Representación Cordoba
}


//ÁREA
export const area = {
	1: 'Operador',
	2: 'Comercial',
}


