import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-sellado-a-recuperar',
  templateUrl: './sellado-a-recuperar.component.html',
  styleUrls: ['./../options.component.scss']
})
export class SelladoARecuperarComponent extends BusinessParticularityOption implements OnChanges {
  sealedToRetrieve:number=0;

  constructor() {
    super()
  }

  ngOnChanges(): void {
    try{
      this.sealedToRetrieve=this.businessParticularitiesStruct.sealedToRetrieve;
    } catch(err){
      this.sealedToRetrieve=0;
    }
  }

  setSealedToRetrieve(sealedToRetrieve:number){
    this.sealedToRetrieve=sealedToRetrieve;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.sealedToRetrieve=this.sealedToRetrieve;
    this.emit2(newBP);
  }

  getSealedToRetrieve(){
    return this.sealedToRetrieve;
  }

  override  setOptionOpenWithCheck(event:any, selectedIndex:number=0){
    const direction= event.target.value;
    if(direction=='up'){
      this.setOptionOpen('down');
      this.setSealedToRetrieve(1);
      this.emitSelected(selectedIndex);
    } else{
      this.setOptionOpen('up');
      this.setSealedToRetrieve(0);
      this.emitSelected(0);
    }
  }

}
