<section class="dolar">
    <h3 class="title">Dólar</h3>
    <span class="info">Última actualización el {{getFecha()}}</span>
    <div class="tabla">
        <table>
            <thead>
                <tr>
                    <th id="col1"><span>Posición</span></th>
                    <th id="col2"><span>Último operado</span></th>
                    <th id="col3"><span>Comprador</span></th>
                    <th id="col4"><span>Vendedor</span></th>
                    <th id="col5"><span>Tasa implícita</span></th>
                    <th id="col6"><span>Variación</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dolarPrice of dolar.dolarList">
                    <td><span>{{dolarPrice.fecha}}</span></td>
                    <td><span>{{dolarPrice.lastOperated}}</span></td>
                    <td>
                        <span>{{dolarPrice.buyer}}</span>
                    </td>
                    <td><span>{{dolarPrice.seller}}</span></td>
                    <td><span>{{dolarPrice.implicitRate}}</span></td>
                    <td><span>% {{dolarPrice.variation}}</span></td>
                </tr>
            </tbody>
        </table>

        <div class="empty-state" style="height: 280px" *ngIf="dolar.dolarList.length==0;">
        <div class="info">
            <span class="header">Aún no están los resultados del dolar.</span>
            <span class="description extra">
                Deberás de esperar a que el mercado abra para poder visualizar el dólar.</span>
            <span class="show-order-board extra">Visualizar dolar del día anterior</span>
        </div>
    </div>
    </div>

    <div class="tasas">
        <span class="tasa">Tasa FCI: {{dolar.tasaFCI}}%</span>
        <span class="tasa">Tasa bancaria: {{dolar.tasaBancaria}}% anual</span>
    </div>

</section>