<div class="list-orders column-left">
    <div class="title-and-info column-left width-100">
        <div class="row-between width-100">
            <h3 class="title">Listado de ordenes</h3>

            <div class="row-between pointer gap-8" (click)="goToOrderList()">
                <span class="show-order-list">Ver listado de orderes </span>
                <arrow-svg [direction]="'right'"></arrow-svg>
            </div>
        </div>

        <span class="info-message">Mostrando mis ultimas 6</span>
    </div>

    <div class="row-between width-100">
        <div class="options row-left">
            <button class="pending row-center"
                [ngClass]="{ selected: orderStatus.PENDING == orderStatusSelected }"
                (click)="setOrderStatusSelected(orderStatus.PENDING)">
                Pendientes
            </button>
            <button class="returned row-center"
                [ngClass]="{ selected: orderStatus.RETURNED == orderStatusSelected }"
                (click)="setOrderStatusSelected(orderStatus.RETURNED)">
                Devueltas
            </button>
            <button class="confirmed row-center"
                [ngClass]="{ selected: orderStatus.CONFIRM == orderStatusSelected }"
                (click)="setOrderStatusSelected(orderStatus.CONFIRM)">
                Confirmadas
            </button>
            <div class="rectangle green"
                [ngClass]="{'returned': orderStatus.RETURNED==orderStatusSelected, 'pending': orderStatus.PENDING==orderStatusSelected, 'confirmed': orderStatus.CONFIRM==orderStatusSelected}"></div>
        </div>

        <select class="select">
            <option>A precio</option>
            <!--<option>A fijar</option>-->
        </select>
    </div>

    <app-table-order class="width-100" [orderStatus]="orderStatusSelected"></app-table-order>

    <div class="empty-state"
        *ngIf="orders && (orders | filterByStatus:orderStatusSelected).length==0">
        <div class="info">
            <span class="header">Aún no tienes ordenes.</span>
            <span class="description extra">Ya que el tablero de precios <span
                    class="stroke">no está activo</span> para operar.</span>
            <span class="show-order-board extra">Visualizar listado de ordenes
                del día anterior</span>
        </div>
    </div>
</div>