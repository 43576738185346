<div class="background-svg" [ngStyle]="style">
    <svg class="svg-22" [ngStyle]="style" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <rect y="0.5" width="24" height="24" rx="12" fill="#C5CBD0"/>
        <g clip-path="url(#clip0_3451_199538)">
        <path d="M14.625 18.4981H7.37503C6.61652 18.4981 6 17.8816 6 17.123V9.87308C6 9.11456 6.61652 8.49805 7.37503 8.49805H11.625C11.832 8.49805 12 8.66605 12 8.87305C12 9.08005 11.832 9.24805 11.625 9.24805H7.37503C7.03052 9.24805 6.75 9.52856 6.75 9.87308V17.123C6.75 17.4676 7.03052 17.7481 7.37503 17.7481H14.625C14.9695 17.7481 15.25 17.4676 15.25 17.123V12.8731C15.25 12.6661 15.418 12.4981 15.625 12.4981C15.832 12.4981 16 12.6656 16 12.8731V17.123C16 17.8816 15.3835 18.4981 14.625 18.4981Z" fill="white"/>
        <path d="M10.3826 14.488C10.2841 14.488 10.1881 14.449 10.1176 14.378C10.0286 14.2895 9.99059 14.162 10.0151 14.0395L10.3686 12.2715C10.3831 12.1985 10.4191 12.132 10.4711 12.08L15.6496 6.90203C16.1856 6.36599 17.0576 6.36599 17.5941 6.90203C17.8536 7.16149 17.9966 7.50655 17.9966 7.87405C17.9966 8.24154 17.8536 8.58651 17.5936 8.84606L12.4156 14.0246C12.3636 14.077 12.2966 14.1125 12.2241 14.127L10.4566 14.4805C10.4321 14.4855 10.4071 14.488 10.3826 14.488ZM12.1506 13.7595H12.1556H12.1506ZM11.0816 12.5301L10.8611 13.635L11.9656 13.414L17.0636 8.31606C17.1816 8.1975 17.2466 8.04104 17.2466 7.87405C17.2466 7.70705 17.1816 7.5505 17.0636 7.43203C16.8206 7.1885 16.4241 7.1885 16.1796 7.43203L11.0816 12.5301Z" fill="white"/>
        <path d="M16.6248 9.6629C16.5288 9.6629 16.4328 9.62637 16.3598 9.55286L14.9458 8.13837C14.7993 7.99188 14.7993 7.75439 14.9458 7.60791C15.0923 7.46143 15.3298 7.46143 15.4763 7.60791L16.8903 9.0224C17.0368 9.16888 17.0368 9.40637 16.8903 9.55286C16.8168 9.62592 16.7208 9.6629 16.6248 9.6629Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_3451_199538">
        <rect width="12" height="12" fill="white" transform="translate(6 6.5)"/>
        </clipPath>
        </defs>
    </svg>

    <div class="tooltip row-center w-80" *ngIf="tooltip">
        <span>Editar contrato</span>
    </div>

</div>