import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { orderTags } from 'src/app/shared/const/orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields.component';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { OrderValidator } from 'src/app/shared/order';
import { getCodPost } from 'src/app/shared/functions-ibm/contract.checker';
import { PlaceOfOrigin } from 'src/app/shared/interfaces/order.interface';

@Component({
  selector: 'app-town',
  templateUrl: './town.component.html',
  styleUrls: ['./../../fields.component.scss']
})
export class TownComponent extends Field implements OnInit {
  
  constructor(store: Store<appState>, private orderV: OrderValidator) {
    super(store);
    this.initalElementSelected = 'Ingresar una localidad';
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    this.tag = orderTags.placeOfOrigin;
    this.subTag = orderTags.town;
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    if(this.subTag==orderTags.town && this.elementToGenerate){
      this.rememberValueTown();
    }
  }
  
  override setElement(element: any, index: number) {
    //this.elementSelectedSecondary = element.location;
    //this.changeShowOptionsSecondary();
    this.subTag = orderTags.afip;
    this.setElementToGenerate(Number(element?.afip ?? -1));

    this.subTag = orderTags.town;
    this.setElementToGenerate(this.getIndexToTown(element?.location ?? -1, this.array) + 1);

    this.subTag = orderTags.town;
    this.changeShowOptions();
  }


  getIndexToTown(element:any, object:any){
    let index=-1;
    try{
    for(let i=0; i<object.length; i++){
      if(object[i].location==element){
        index=JSON.parse(i.toString());
        throw new Error();
      }
    }
    } catch(err){
    }
    return index;
  }

  rememberValueTown() {
    try{
      this.subTag = '';
      let valueToSet: any = this.rememberValue();
      this.setArrayLocalities(valueToSet.province, valueToSet.province);
      if (valueToSet.town >0 ) {
        this.elementSelected = this.array[valueToSet.town -   1].location;
        this.isInvalid=getIsInvalidTown(valueToSet);
        this.indexOption=valueToSet.town - 1;
        this.search=this.elementSelected;
        this.placeHolder='';
        this.setIsOptionSelected(true);
      } else {
        this.elementSelected=''
        this.setIsOptionSelected(false);
        this.search='';
        this.placeHolder='';
        this.isInvalid=false;
        //this.indexOption=-1
      }
  
      this.setIsOptionSelected(this.elementSelected != '');
      this.subTag = orderTags.town;
  
    } catch(err){
      this.search='';
      this.placeHolder='';
      this.subTag = orderTags.town;
    }
    
  }

  setArrayLocalities(element: any, index: number) {
    let location=this.orderV.getLocality(Number(element));

    this.OBJECT=location;
    this.array = Object.values(location);
  }


  override save() {
    let newList = this.pipe.transform(this.array, this.search, this.OBJECT,orderTags.town);
    if (newList.length > 0) {
      this.setElement(this.array[this.indexOption], 0);
    }

    this.search=this.elementSelected;
    this.setChangeShowOptions(false);
  }


  override setPlaceHolder(){
    try{
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT, orderTags.town);

      if(newList.length > 0 && this.search){
        this.placeHolder= this.removeFirstLetters(newList[0].location, this.search);

        if(this.setSearchIndex){
          this.indexOption= this.getIndexOfArray(newList[0], this.array);
        } else {
          this.setSearchIndex=true;
        }
      } else {
        this.placeHolder= '';
        this.indexOption= -1;
      }
    } catch (err) {
      this.placeHolder='';
    }
  }

  setSearchIndex:boolean=false;
}

export function getIsInvalidTown(placeOfOrigin:PlaceOfOrigin){
  const codePost=getCodPost(placeOfOrigin);
  return codePost == 0;
}
