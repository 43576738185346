import { Component, Input } from '@angular/core';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { getSellerName } from '../small-detail/details.component';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { priceHaveIndicators } from 'src/app/shared/to-fix';

const URL_DEFAULT='./../../../../../assets/images/products/default.svg';

@Component({
  selector: 'app-header-details',
  templateUrl: './header-details.component.html',
  styleUrls: ['./header-details.component.scss']
})
export class HeaderDetailsComponent {
  @Input() order!:Order|null;
  @Input() productPreviousValue:boolean=false;
  @Input() pricePreviousValue:boolean=false;
  @Input() deliveryPeriodPreviousValue:boolean=false;

  constructor(private orderV:OrderValidator){

  }

  getNroOfContract(order:Order){
    return order?.contractNumber>0? order.contractNumber : '';
  }

  getSellerName(order:Order){
    return getSellerName(order, this.orderV);
  }
  
  getBuyerName(order:Order){
    return this.orderV.getBuyerName(order);
  }

  getProductName(order:Order){
    return this.orderV.getProductName(order);
  }

  conditionHaveIndicators(price:Price){
    return priceHaveIndicators(price);
  }

  getTypeCoinStr(order:Order){
    return this.orderV.getTypeCoinString(order);
  }

  getTypeCoinAndPriceString(order:Order){
    return this.orderV.getTypeCoinAndPriceString(order);
  }

  getRangeDateText(order:Order){
    return this.orderV.getRangeDateText(order);
  }

  onErrorImg(event: any) { 
    event.target.src = URL_DEFAULT
  }
}
