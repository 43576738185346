import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Commission, Order } from 'src/app/shared/interfaces/order.interface';
import { setEditAll, setIndexToEdit, setIndexToEditValidation, setOrdersSelected, setValidationOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { DateChecker } from 'src/app/shared/date.checker';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { OrderValidator, isCommoditiesOrHughes } from 'src/app/shared/order';
import { Details } from '../details.component';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { orderCategory } from 'src/app/shared/dict/orders';
import { Subscription } from 'rxjs';
import { COMMODITIES_CODE, COMMODITIES_CODE_SELLER, HUGHES_CODE_BUYER, HUGHES_CODE_SELLER } from 'src/app/shared/const/buyers';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { buyerIsExchanger } from 'src/app/shared/business-validation';
import { ACTIONS_MANY_ORDERS, ActionOrderMany } from '../details-many-orders/details-many-orders.component';

@Component({
  selector: 'details-validation',
  templateUrl: './details.component.html',
  styleUrls: ['./../details.scss']
})

export class DetailsValidationComponent extends Details implements OnInit,OnDestroy{
  nroOfCall:number=1;
  isGenerateEraser:boolean=true;

  ORDER_TYPE_CATEGORY:any=orderCategory;

  private orders$!:Subscription;
  private identificatorOrderEdit$!:Subscription;
  private isGenerateEraser$!:Subscription;
  private editAll$!:Subscription;

  constructor(store: Store<appState>, 
    private router:Router, dialog: 
    MatDialog, date: DateChecker, orderV:OrderValidator, toFixV:ToFixValidator,
    private IBM:ContractChecker) {
    super(store,dialog,date,orderV, toFixV);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.orders$= this.store.select('order', 'edit', 'ordersEdit').subscribe(ordersEdit=>{
      this.orders=ordersEdit;
      this.order=this.orders[this.identificatorOrderEdit];
      this.disabledButton = this.getIsOrdersIncompletes(this.orders);
    });

    this.identificatorOrderEdit$=this.store.select('order', 'edit', 'indexToEdit').subscribe(indexToEdit=>{
      this.identificatorOrderEdit=indexToEdit;
      this.order=this.orders[this.identificatorOrderEdit];
    });

    this.isGenerateEraser$= this.store.select('order', 'edit', 'isGenerateEraser').subscribe(isGenerateEraser=>{
      this.isGenerateEraser=isGenerateEraser;
      //Si estamos editando la orden por primera vez antes de generar borrador, es true
      //Si estamos editando la orden por segunda vez, para generar contrato, es false.
    });

    this.editAll$=this.store.select('order', 'edit', 'editAll').subscribe(editAll=>{
      this.editAllOrders=editAll;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orders$?.unsubscribe();
    this.identificatorOrderEdit$?.unsubscribe();
    this.isGenerateEraser$?.unsubscribe();
    this.editAll$?.unsubscribe();
  }

  getIsOrdersIncompletes(orders:Order[]){
    return orders.some(order => this.orderV.isOrderIncomplete(order));
  }

  editAllOrders:boolean=false;
  changeEditAllOrders(booleanToSet:boolean){
    this.store.dispatch(setEditAll({editAll: booleanToSet}));
  }

  override editOrder(index: number) {
    this.store.dispatch(setIndexToEditValidation({indexToEdit: index}));
  }

  override setCommission(event:Commission, index:number){
    let newOrders:Order[]= JSON.parse(JSON.stringify(this.orders)); //Parsed orders
    newOrders[index].commision=event;
    this.store.dispatch(setValidationOrdersToGenerate({orders: newOrders})); //Set orders
  }

  override sendOrder() {
  }

  override sendOrders(){
    let newOrders=this.updateOrders(this.orders);

    if(this.isGenerateEraser){
      //Depende del nro de call seleccionado antes (si el comprador es commodities o no)
      this.IBM.createContracts(newOrders,this.nroOfCall,this.user);
    } else {
      //Siempre se envía 2 porque significa que ingresamos desde otra sección
      this.IBM.createContracts(newOrders, 2, this.user);
    }

    this.goToOrderBoard();
  }
  
  goToOrderBoard() {
    const url='/'+NAVEGATION.ORDER_BOARD
    this.router.navigate([url]);
    this.store.dispatch(setOrdersSelected({orders: []}));
    this.store.dispatch(setValidationOrdersToGenerate({orders:[]}))
    this.store.dispatch(setIndexToEdit({indexToEdit:0}))
    this.store.dispatch(setEditAll({editAll:false}))
  }

  updateOrders(orders:Order[]){
    let newOrders:Order[]=JSON.parse(JSON.stringify(orders));
    for(let i=0; i<newOrders.length; i++){
      this.setNroOfCall(newOrders[i]);
      const sellerCode=newOrders[i].sellerData.codeS;
      const buyerCode=newOrders[i].price.observations.buyer;
      newOrders[i].quotas=JSON.stringify(this.orderV.getValidQuotas(newOrders[i]));

      //Actualizamos las comisiones;

       //Si el vendedor es commodities, la comisión vendedora es 0
       if(sellerCode==COMMODITIES_CODE_SELLER || sellerCode==HUGHES_CODE_SELLER){
        newOrders[i].commision.seller=-1; //Editamos la condición vendedora si es Commodities o Hughes
      }

      if(buyerCode==COMMODITIES_CODE || buyerCode==HUGHES_CODE_BUYER){
        //Si es commodities, la comisión compradora es 0
        newOrders[i].commision.buyer=-1;
        newOrders[i].price.observations.isPort=-1;
        
        const commisionSeller= this.orderV.getCommisionByOrder(newOrders[i], 'V');
        newOrders[i].commision.seller=commisionSeller.seller<=0? -1: commisionSeller.seller;
      } else {
        if(buyerIsExchanger(buyerCode)){
          newOrders[i].commision.buyer=-1;
        } else {
          const commisionBuyer= this.orderV.getCommisionByOrder(newOrders[i], 'C');
          const commisionSeller= this.orderV.getCommisionByOrder(newOrders[i], 'V');
          newOrders[i].commision.buyer=commisionBuyer.buyer;
          newOrders[i].commision.seller=commisionSeller.seller;
        }

        newOrders[i].price.observations.isPort=1;
      }

    }

    return newOrders;
  }

  haventBuyerInOrders(): boolean {
    return this.orders.some(order => order.price.observations.buyer <= 0);
  }

  setNroOfCall(order:Order){
    //this.nroOfCall= (order.price.observations.buyer==COMMODITIES_CODE)?1:2;
    this.nroOfCall= (isCommoditiesOrHughes(order))?1:2;
  }

  recieveAction(actOrdMany:ActionOrderMany){
    if(actOrdMany.action==ACTIONS_MANY_ORDERS.EDIT){
      this.editOrder(actOrdMany.index);
    } else if(actOrdMany.action==ACTIONS_MANY_ORDERS.COMMISSION && actOrdMany.commission){
      this.setCommission(actOrdMany.commission, actOrdMany.index);
    }
  }
}
