import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields.component';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { fieldType } from 'src/app/shared/const/options';
import { priceTags } from 'src/app/shared/const/prices.const';
import { ports } from 'src/app/shared/dict/place-of-delivery';
import { orderTags } from 'src/app/shared/const/orders';
import { OrderValidator } from 'src/app/shared/order';
import { getProvinceOfPlaceOfDelivery, PriceValidator } from 'src/app/shared/price';
import { PlaceOfDelivery } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'app-port',
  templateUrl: './port.component.html',
  styleUrls: ['./../../fields.component.scss']
})
export class PortComponent extends Field implements OnInit {
  @Input() requirePort:boolean=false;
  @Input() incompleteRequirePort:boolean=false;
  @Output() tab = new EventEmitter<string>();

  constructor(store: Store<appState>, private orderV:OrderValidator, private priceV:PriceValidator) {
    super(store);
    this.initalElementSelected = 'Ingresar un puerto';
    this.pipe = new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator == fieldType.PRICE) {
      this.tag = priceTags.placeOfDelivery
      this.subTag = priceTags.port;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.placeOfDelivery
      this.thirdTag = priceTags.port;
    }
    this.setIdentifyClassName();
  }

  override rememberValueInForm() {
    if (this.subTag == priceTags.port || this.thirdTag == priceTags.port) {
      this.rememberValuePort();
    }
  }

  override setElement(element: any, index: number) {
    this.setTag(priceTags.port);
    const indexOfELement=this.array.indexOf(element);
    this.setElementToGenerate(indexOfELement + 1);
  }

  rememberValuePort() {
    this.setTag('');
    let valueToSet: any = this.rememberValue(); //Recordamos el valor de {zone:.. , port:..}
    if (valueToSet) {
      if (valueToSet.zone > 0) {
        this.setArrayPort(valueToSet.zone, valueToSet.zone);
        if (valueToSet.port != -1 && valueToSet.port) {
          this.elementSelected = this.array[valueToSet.port - 1];
          this.search = this.elementSelected;
          this.placeHolder='';
          this.verifyIsInvalid(valueToSet);
        }
      } else {
        this.search = '';
        this.placeHolder='';
        this.setArrayPort(0, 0);
      }
    } else {
      this.search = '';
      this.placeHolder='';
    }
    this.setIsOptionSelected(this.elementSelected != '');
    this.setTag(priceTags.port);
  }

  setArrayPort(element: any, index: number) {
    const portsAny: any = ports;
    this.array = portsAny[element];
    this.OBJECT = ports;
    this.elementSelected = '';
  }

  verifyIsInvalid(placeOfDelivery:PlaceOfDelivery){
    if(this.fieldIdentificator==fieldType.ORDER){
      this.isInvalid=!this.orderV.getBrokersByProvince(getProvinceOfPlaceOfDelivery(placeOfDelivery)).includes(this.elementToGenerate.broke)
    }
  }

  override save() {
    try{
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT, priceTags.port);
      this.indexOption = this.getIndexOfArray(newList[0], this.array);
      if (this.indexOption != -1) {
        this.setElement(this.array[this.indexOption], this.indexOption);
      }
      this.search = this.elementSelected;
      this.setChangeShowOptions(false);
      this.setPlaceHolder();
    } catch(err){}
  }

  override setPlaceHolder() {
    try {
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT, priceTags.port);

      if (newList.length > 0 && this.search) {
        this.placeHolder = this.removeFirstLetters(newList[0], this.search);
      } else {
        this.placeHolder = '';
      }
    } catch (err) {
      this.placeHolder = '';
    }
  }

  listenerTab(event: any) {
    if (event.key.toLowerCase() == 'tab' && this.fieldIdentificator == fieldType.PRICE) {
      this.emit();
    }
  }

  emit() {
    this.tab.emit('tab');
  }
}