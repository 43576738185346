import { Component, Input } from '@angular/core';

@Component({
  selector: 'arrow-board-svg',
  templateUrl: './arrow-board.component.html',
  styleUrls: ['./../../svg.scss']
})
export class ArrowBoardSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  constructor() { }

}
