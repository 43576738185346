<svg class="svg-24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" rx="12" fill="#00C689"/>
    <g clip-path="url(#clip0_4884_15762)">
    <path d="M12 20C16.4107 20 20 16.4107 20 12C20 7.58934 16.4107 4 12 4C7.5893 4 4 7.58934 4 12C4 16.4107 7.58934 20 12 20ZM12 5.06665C15.824 5.06665 18.9334 8.17597 18.9334 12C18.9334 15.824 15.824 18.9334 12 18.9334C8.17597 18.9334 5.06665 15.824 5.06665 12C5.06665 8.17597 8.17602 5.06665 12 5.06665Z" fill="white"/>
    <path d="M14.3335 14.5495C14.4321 14.6295 14.5494 14.6669 14.6668 14.6669C14.8241 14.6669 14.9788 14.5975 15.0828 14.4669C15.2668 14.2375 15.2294 13.9015 15.0001 13.7175L12.5334 11.7442V7.73352C12.5334 7.44018 12.2935 7.2002 12.0001 7.2002C11.7068 7.2002 11.4668 7.44018 11.4668 7.73352V12.0002C11.4668 12.1629 11.5415 12.3149 11.6668 12.4162L14.3335 14.5495Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_4884_15762">
    <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
    </clipPath>
    </defs>
</svg>