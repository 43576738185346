import { Action, createReducer, on } from "@ngrx/store";
import { INITIAL_STATE_OPTIONS } from "src/app/shared/const/options";
import { Options } from "src/app/shared/interfaces/options.interface";
import * as actions from '../actions/options.action';

export const initialState: Options = INITIAL_STATE_OPTIONS

const _optionsReducer = createReducer(
    initialState,
    on(actions.hiddenMenu, (state, action) => {
        return {
            ...state,
            showMenu:false
        };
    }),
    on(actions.showMenu, (state, action) => {
        return {
            ...state,
            showMenu:true
        };
    }),
    on(actions.setLocation, (state, action) => {
        return {
            ...state,
            location:action.location
        };
    }),
    on(actions.setDate, (state, action) => {
        return {
            ...state,
            date:action.date
        };
    }),
    on(actions.setHour, (state, action) => {
        return {
            ...state,
            hour:action.hour
        };
    }),
    on(actions.getClaimSuccess, (state, action) => {
        return {
            ...state,
            temp: action.temp
        };
    }),
    on(actions.setHeaderInMobile, (state, action) => {
        return {
            ...state,
            headerInMobile: action.header
        };
    }),
    on(actions.setSizeView, (state, action) => {
        return {
            ...state,
            sizeView: action.size
        };
    }),
    on(actions.goToInitForm, (state, action) => {
        return {
            ...state,
            goToInitForm: action.goToInitForm
        };
    }),

    //---- CHANGES AND ERRORS VISIBLES IN BOARD ----\\ 
    on(actions.setErrorStruct, (state, action) => {
        return {
            ...state,
            error: {
                ...state.error,
                ...action.error
            }
        };
    }),

    on(actions.setChangesVisibles, (state, action) => {
        return {
            ...state,
            isChangeVisible: action.isChangeVisible
        };
    }),

    on(actions.setModalLoading, (state, action) => {
        return {
            ...state,
            modalCreate: {
                ...state.modalCreate,
                isLoading: action.isLoading
            }
        };
    }),

    on(actions.setModalSuccess, (state, action) => {
        return {
            ...state,
            modalCreate: {
                ...state.modalCreate,
                isSuccess: action.isSuccess
            }
        };
    }),

    //---- SHOW ADD BUYER IN ORDER BOARD ----\\ 
    on(actions.setShowAddBuyer, (state, action) => {
        return {
            ...state,
            isShowAddBuyer: action.isShowAddBuyer
        };
    }),

    on(actions.setIdentificatorFieldSelected, (state, action) => {
        return {
            ...state,
            identificatorFieldSelectedInForm: action.identificator
        };
    }),
    
    //---- SHOW ERROR CHANGE PRICE IN PRICE BOARD ----\\
    on(actions.setShowErrorChangePrice, (state, action) => {
        return {
            ...state,
            showErrorChangePrice: action.showErrorChangePrice
        };
    }),
    on(actions.setShowWarningChangeTypeCoin, (state, action) => {
        return {
            ...state,
            warning: {
                ...state.warning,
                isShow: action.isShow,
                price: action.price
            }
        };
    }),
    on(actions.setShowDeletePrice, (state, action) => {
        return {
            ...state,
            deletePrice: {
                ...state.deletePrice,
                isShow: action.isShow,
                idPrice: action.idPrice
            }
        };
    }),

    //Form clicked
    on(actions.setIsClickedInConfirm, (state, action) => {
        return {
            ...state,
            isClickedInConfirm: action.isClickedInConfirm
        }
    }),

    //---- SHOW MODAL CONTRACT ----\\
    on(actions.setModalContract, (state, action) => {
        return {
            ...state,
            modalContract: action.modalContract
        }
    }),
    
    on(actions.addModalContractFailed, (state, action) => {
        return {
            ...state,
            modalContract: {
                ...state.modalContract,
                arrayOfFaileds: [...state.modalContract.arrayOfFaileds, ...action.arrayOfFaileds],
                isIndicators: action.isIndicators
            }
        }
    }),

    on(actions.addModalContractSuccess, (state, action) => {
        return {
            ...state,
            modalContract: {
                ...state.modalContract,
                arrayOfSuccess: [...state.modalContract.arrayOfSuccess, ...action.arrayOfSuccess]
            }
        }
    }),

    on(actions.setModalContractType, (state, action) => {
        return {
            ...state,
            modalContract: {
                ...state.modalContract,
                typeModal: action.typeModal
            }
        }
    }),

    //---- GENERATE ORDER TO FIX ----\\
    on(actions.setIsGenerateToFix, (state, action) => {
        return {
            ...state,
            isGenerateToFix: action.isGenerateToFix
        }
    }),

    //---- SET OBSERVATION MESSAGE IN PRICE ----\\
    on(actions.priceToWriteObservation, (state, action) => {
        return {
            ...state,
            priceToObservationMessage: action.price
        }
    }),

    //---- SET CASE SYNGENTA AGRO AVC ----\\
    on(actions.isCaseSyngentaAgroAvc, (state, action) => {
        return {
            ...state,
            isCaseSyngentaAgroAvc: action.isCaseSyngentaAgroAvc
        }
    }),

    on(actions.setInfoQuotas, (state, action) => {
        return {
            ...state,
            infoQuotas: action.dataOfQuotas
        }
    }),

    on(actions.setInfoIndicators, (state, action) => {
        return {
            ...state,
            infoIndicators: action.infoIndicators
        }
    }),

    on(actions.setIsEditedOrderFromBoard, (state, action) => {
        return {
            ...state,
            isEditedOrderFromBoard: action.isEditedOrderFromBoard
        }
    }),

    on(actions.setSearchBoard, (state, action) => {
        return {
            ...state,
            board: {
                ...state.board,
                search: action.search
            }
        }
    }),

    on(actions.setBoardSelected, (state, action) => {
        return {
            ...state,
            board: {
                ...state.board,
                boardSelected: action.boardSelected
            }
        }
    }),

    on(actions.setConditionTypeSelectedBoard, (state, action) => {
        return {
            ...state,
            board: {
                ...state.board,
                conditionTypeSelected: action.conditionTypeSelected
            }
        }
    }),

    on(actions.setConditionFilterBoard, (state, action) => {
        return {
            ...state,
            board: {
                ...state.board,
                conditionFilter: action.conditionFilter
            }
        }
    }),

)


export function optionsReducer(state: any, action: Action) {
    return _optionsReducer(state, action);
}