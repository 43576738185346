<div class="background opaque visible"></div>
<div class="background visible" (click)="close()">
  <div class="dialog dialog-contract">
    <div class="logo-container">
      <img class="logo confirm" *ngIf="isConfirm" alt="check-confirm" src="../../../../../../assets/images/extra/confirmed.svg" />
      <img class="logo denied" *ngIf="!isConfirm" alt="check-denied" src="../../../../../../assets/images/extra/denied.svg" />
    </div>
    <span class="hour">{{hour}}</span>
    <p class="message" *ngIf="isExchange()">La nueva contrapartida se creó bajo el N° de contrato:</p>
    <p class="message" *ngIf="!isExchange()">El nuevo borrador fue <b>enviado a boletos</b> para su aprobación bajo el N° de contrato:</p>
    <span *ngFor="let success of arrayOfSuccess" class="message-success">{{success.data.Contrato}} del cliente {{getClientName(success)}}
    </span>

    
    <div class="buttons" style="margin-top:24px;">
      <button class="button filled-button" (click)="close()">Volver al listado</button>
    </div>
  </div>
</div>