import { Component, Input, OnInit } from '@angular/core';
import { buyers } from 'src/app/shared/dict/buyers';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { Quotas, Restriction } from 'src/app/shared/interfaces/order.interface';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent {
  @Input() title:string='';
  @Input() previusValue:any; //Usada para cuando sea una orden que se haya modificado el field.
  @Input() value:string='--';
  @Input() infoGreen:string='';
  @Input() object:any;
  @Input() isSmall:boolean=false;
  @Input() businessParticularity!:BusinessParticularities;
  showOptions: boolean = false;
  
  constructor() { }
  objectLengthMoreThan0(){
    return this.object?.length>0
  }

  changeShowOptions(){
    if(this.object.length>1 || this.title=='Particularidad de negocio' || this.title=='Restricción'){
      this.showOptions=!this.showOptions;
    }
  }

  getArrowDirection(){
    return getArrowDirectionByBool(this.showOptions)
  }

  getTextOfQuotas(quota:Quotas){
    return getTextOfQuotas(quota)
  }

  getFirstTextOfQuotas(quotas:Quotas[]){
    return getFirstTextOfQuotas(quotas)
  }

  getFirstTextOfParticularities(particularities:string[]){
    return getFirstTextOfParticularities(particularities)
  }

  getFirstTextOfRestriction(object:Restriction){
    return getFirstTextOfRestriction(object);
  }

  //Muestra la flecha en la restricción si es un arreglo
  showArrowRestriction(){
    return Array.isArray(this.object?.onlySell) || Array.isArray(this.object?.notSell)
  }

  getBuyer(buyer:number){
    return getBuyer(buyer)
  }

  //Obtiene el arreglo de la restricción dependiendo si tiene valores en onlySell o notSell
  getArrayRestriction(restriction:Restriction){
    try{
      if(restriction.onlySell.length>0){
        return restriction.onlySell
      } else if(restriction.notSell.length>0){
        return restriction.notSell
      }
    } catch(err){
      return []
    }
  }
}

//Obtiene el texto inicial para la card de Data de Quotas
export function getFirstTextOfQuotas(quotas:Quotas[]){
  try{
    if(quotas.length>0 && quotas[0].date!='--') {
      return getTextOfQuotas(quotas[0]) + (quotas.length>1? ' ...': '');
    }
  } catch(err){}
  return '--'
}

//Dada una quota obtiene su texto para la card
function getTextOfQuotas(quota:Quotas){
  try{
    if(quota.date!=' ' && quota.trucks!=-1) {
      let finallyText=quota.date + ' / '+quota.trucks;
      finallyText+=(quota.trucks==1)? ' Camión': ' Camiones'
      return finallyText
    }
  } catch(err){}
  return '--'
}

//Obtiene el texto inicial para la card de Data de Particularities
export function getFirstTextOfParticularities(particularities:string[]){
  try{
    if(particularities.length>0 && particularities[0]!='--') {
      return getTextOfParticularities(particularities[0]) + " "+ (particularities.length>1? '...': '');
    }
  } catch(err){}
    return '--'
}

//Dada una particularity obtiene su texto para la card
function getTextOfParticularities(particularity:string){
  try{
    if(particularity!=' ' && particularity!='--') {
      return particularity;
    }
  } catch(err){}
  return '--'
}

//Obtiene el texto inicial para la card de Data de Restriction
export function getFirstTextOfRestriction(object:Restriction){
  try{
    if(Array.isArray(object.onlySell)){
      if(object.onlySell.length>0){
        const buyers=object.onlySell.length==1? getBuyer(object.onlySell[0]): (object.onlySell.length + ' compradores')
        return 'Le vende a ' + buyers
      } else if(object.notSell.length>0){
        const buyers=object.notSell.length==1? getBuyer(object.notSell[0]): (object.notSell.length + ' compradores')
        return 'No le vende a ' + buyers;
      }
    } else {
      if(object.onlySell>0){
        return 'Le vende a ' + getBuyer(object.onlySell)
      } else if(object.notSell>0){
        return 'No le vende a ' + getBuyer(object.notSell)
      }
    }
  } catch(err){}
  return '--';
}

//Dado un numero de comprador, obtiene su nombre en formato string
function getBuyer(buyer:number){
  const BUYERS:any=buyers;
  return BUYERS[buyer];
}
