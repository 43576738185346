<div class="body">

    <app-loading *ngIf="isLoading"></app-loading>

    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="{{headerInSection? headerInSection: 'Generar nueva condición' }}" [isCross]="headerInSection=='Editar condición'"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Generar nueva condición</h2>
    <!-- CONTENT OF THE PRICE BOARD -->
    
    <app-body-generate-price></app-body-generate-price>
    
    <app-menu class="menu"></app-menu>
    <!--<app-menu-footer></app-menu-footer> -->
</div>