import { Pipe, PipeTransform } from '@angular/core';
import { orderTags } from '../../const/orders';
import { priceTags } from '../../const/prices.const';
import * as dict from '../../dict/dict';
import { orderStates } from '../../dict/orders';
import { PRODUCT_NAMES } from '../../dict/productName';
import { sellerWithData } from '../../dict/seller-with-data';
import { typeBusiness } from '../../dict/typeBusiness';
import { wayPay } from '../../dict/wayToPay';
import { OrderExchange } from '../../interfaces/order-exchange';
import { Order } from '../../interfaces/order.interface';
import { Price } from '../../interfaces/price.interface';
import { buyers } from '../../dict/buyers';
import { zones } from '../../dict/place-of-delivery';
import { getbussinesParticularities } from '../../business-particularities';


@Pipe({
  name: 'filterPrice'
})
export class FilterPriceWithStringPipe implements PipeTransform {

  transform(priceList: Price[], search: string) {
    if(!search) return priceList;
    //if (!search || 3 > search.length) return lista;

    const searchs = search.trim().toLowerCase().split(" ");
    const numberOfSearchs=searchs.length;
    const cantElements = priceList.length;

    let list:Price[]=priceList //Return one or another
    let listAny:Price[]=[];
    
    for (let i = 0; i < numberOfSearchs; i++) {
      list=this.isValueInList(list, searchs[i])
    }
    return list;
  }

  isValueInList(list: any[], value: any) {
    let listReturned: any[] = []
    list.forEach(element => {
      if (filterPriceByString(element,value)) {
        listReturned.push(element)
      }
    });
    return listReturned;
  }
}

export function filterPriceByStrings(element:any, searchs:string[] ){
  try{
    for (let i = 0; i < searchs.length; i++) {
      if (!filterPriceByString(element, searchs[i])) {
        throw new Error()
      }
    }
    return true;
  } catch(err){
    return false;
  }
}

function filterPriceByString(element:any, value:string ){
  try{
    return !value || isValueInObject(element, value, priceTags.productName, PRODUCT_NAMES) ||
    isValueInObject(element, value, priceTags.status, dict.priceState) || 
    isValueInObject(element, value, priceTags.quality, dict.quality) ||
    isValueInObject(element, value, priceTags.typeBusiness, typeBusiness) ||
    isValueInObject(element.wayPay, value, priceTags.wayPayName, wayPay)||
    isValueInObject(element.placeOfDelivery, value, priceTags.zone, zones)||
    isValueInElement(element.deliveryPeriod.startDate, value) ||
    isValueInElement(element.deliveryPeriod.endDate, value) || 
    
    isValueInObject(element?.observations, value, priceTags.buyer, buyers) ||
    isValueInElement(element.harvest, value) ||
    isValueInElement(element.price.toString(), value) || 
    searchStringInArray( getbussinesParticularities(element.businessParticularities), value)

  } catch(err){
    return false;
  }
}


@Pipe({
  name: 'filterOrder'
})
export class FilterOrderWithStringPipe implements PipeTransform {

  transform(orderList: Order[] | OrderExchange[], search: string) {
    if(!search) return orderList;
    //if (!search || 3 > search.length) return lista;
    const searchs = search.trim().toLowerCase().split(" ");
    const numberOfSearchs=searchs.length;

    let list:any=orderList //Return one or another
    
    for (let i = 0; i < numberOfSearchs; i++) {
      list=this.isValueInList(list, searchs[i])
    }
    return list;
  }

  isValueInList(list: any[], value: any) {
    let listReturned: any[] = []
    list.forEach(element => {
      if (filterOrderByString(element,value)) {
        listReturned.push(element)
      }
    });
    return listReturned;
  }
}

export function filterOrderByStrings(element:any, searchs:string[] ){
  try{
    for (let i = 0; i < searchs.length; i++) {
      if (!filterOrderByString(element, searchs[i])) {
        throw new Error()
      }
    }
    return true;
  } catch(err){
    return false;
  }
}

export function filterOrderByString(element:any, value:string ){
  try{
    return !value || isValueInObject(element, value, orderTags.statusOrder, orderStates) ||
    element.contractNumber.toString().includes(value) ||
    isValueInObjectSeller(element.sellerData, value, orderTags.codeS, sellerWithData) ||
    isValueInObject(element?.price?.observations, value, priceTags.buyer, buyers) ||
    isValueInObject(element.price, value, priceTags.productName, PRODUCT_NAMES) ||
    isValueInObject(element.price, value, priceTags.quality, dict.quality) ||
    isValueInObject(element.price, value, priceTags.typeBusiness, typeBusiness) || 
    isValueInObject(element.price.placeOfDelivery, value, priceTags.zone, zones)||
    isValueInElement(element.price.deliveryPeriod.startDate, value) ||
    isValueInElement(element.price.deliveryPeriod.endDate, value) || 
    isValueInElement(element.price.harvest, value) ||
    isValueInObject(element.price.wayPay, value, priceTags.wayPayName,wayPay) ||
    searchStringInArray( getbussinesParticularities(element.price.businessParticularities), value)
  } catch(err){
    return false;
  }
}

function searchStringInArray(array:string[], value:string){
  return array.some(element=> isValueInElement(element.toLowerCase(),value));
}

function isValueInObject(object: any, value: any, tag:string, dict:any) {
  return dict[object[tag]]? dict[object[tag]].toLowerCase().includes(value): false;
}

function isValueInObjectSeller(object: any, value: any, tag:string, dict:any) {
  return dict[object[tag]].nombre.toLowerCase().includes(value);
}

function isValueInElement(object:any, value:any){
  return object.includes(value);
}