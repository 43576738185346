import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionComponent } from './option.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    OptionComponent
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule
  ], exports: [
    OptionComponent
  ]
})
export class OptionModule { }
