import { Component, Output,EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { quality } from 'src/app/shared/dict/dict';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { PriceValidator } from 'src/app/shared/price';
import { Field } from '../fields.component';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./../fields.component.scss'],
})
export class QualityComponent extends Field implements OnInit, OnDestroy {

  private store$!:Subscription;

  constructor(store: Store<appState>, private priceV:PriceValidator) {
    super(store);

    this.store$=this.store.subscribe((state) => {
      this.array = this.getQualities();
    });
    this.OBJECT = quality;
    this.initalElementSelected = 'Ingresar una calidad';

    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.quality;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.quality;
    }

    this.setIdentifyClassName();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.unsubscribe();
  }

  override rememberValueInForm(){
    this.rememberQuality();
  }

  override setIncomplete(boolean:boolean){
    let isInvalidQuality:boolean=false;
    try{
      isInvalidQuality= !this.priceV.isQualityValid(this.getPrice().productName, this.getPrice().quality);
    } catch(err){
      isInvalidQuality=false;
    }
    this.isIncomplete= (boolean || isInvalidQuality) && this.isClickedInConfirm;

  }
  
  rememberQuality(){
    let valueToSet: any = this.rememberValue();
    //If There is a value to remember
    if (valueToSet != -1 && this.elementToGenerate && valueToSet) {
      this.elementSelected = this.OBJECT[valueToSet];
      this.setIsOptionSelected(true)
      this.placeHolder='';
      this.search=this.OBJECT[valueToSet]
    } else {
      this.elementSelected = '';
      this.setIsOptionSelected(false);
      this.placeHolder='';
      this.search='';
    }
  }

  getQualities() {
    try {
      let productName: number = this.getPrice().productName;
      let PRODUCTS: any = PRODUCT_NAMES;
      let values: any[] = [];
      try {
        if (productName <= 0) {
          values = [];
        } else {
          switch (PRODUCTS[productName]) {
            case 'Soja':
              values = [1, 2, 4]; //Bien
              break;
            case 'Girasol':
              values = [1, 4]; //Bien, falta alto oleico (problema para el futuro jaja);
              break;
            case 'Maiz':
              values = [1, 3, 4]; //Bien, Camara, grado2 y tal cual
              break;
            case 'Sorgo':   
              values = [1, 3, 4];
              break;
            case 'Trigo':
              values = [1, 3, 4];
              break;
            case 'Cebada Cervecera':
              values = [1, 3, 4];
              break;
            case 'Cebada Forrajera':
              values = [1, 3, 4];
              break;
            default:
              values = [1, 2, 3, 4];
              break;
          }
        }
        return values;
      } catch (err) {
        return [];
      }
    } catch (err) {
      return [];
    }
  }

  override setElement(element: any, index: number) {
    this.setElementToGenerate(element);
  }

  @Output() addQuality= new EventEmitter<string>();
  addQualityParticularity(){
    try{
      const price=this.getPrice();
      if(price.productName > 0 && price.quality > 0){
        this.addQuality.emit('add');
      }
    } catch(err){

    }
  }

  /* Pasamos al siguiente paso cuando completamos el formulario de orden o validación */
  @Output() tab = new EventEmitter<string>();
  emit(){
    this.tab.emit('tab');
  }
  listenerTab(event: any) {
    if(event.key.toLowerCase() == 'tab' &&  (this.fieldIdentificator == fieldType.ORDER  || this.fieldIdentificator==fieldType.VALIDATION)){
      this.emit();
  }}

}
