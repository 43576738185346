export const costumer_by_seller = {
  '0': [
    130, 150, 151, 152, 153, 154, 155, 157, 188, 189, 196, 198, 202, 203, 206,
    211, 212, 214, 218, 228, 242, 245, 260, 1010, 1055, 1080, 1083, 1144, 1148,
    1149, 1152, 1153, 1154, 1159, 1160, 1174, 1176, 1490, 1550, 2274, 2276,
    2287, 2307, 2315, 2338, 2339, 2395, 2399, 2454, 2730, 2732, 2733, 2734,
    2736, 2738, 2740, 2788, 2845, 2910, 2960, 2980, 3092, 3095, 3101, 3200,
    3305, 3340, 3352, 3352, 3354, 3372, 3373, 3379, 3380, 3381, 3382, 3413,
    3487, 3603, 3650, 3652, 3670, 3740, 3797, 3825, 3882, 4040, 4100, 4215,
    4250, 4350, 4357, 4480, 4550, 4551, 4558, 4559, 4595, 4597, 4601, 4650,
    4803, 4830, 4990, 10040, 10050, 10085, 10100, 10147, 10156, 10157, 10160,
    10161, 10210, 10213, 10215, 10220, 10310, 10334, 10340, 10345, 10375, 10380,
    10390, 10391, 10393, 10397, 10411, 10451, 10527, 10528, 10556, 10557, 10558,
    10564, 10566, 10578, 10580, 10581, 10700, 10750, 10760, 10763, 10890, 10895,
    10897, 10900, 10910, 10914, 10916, 10918, 10919, 10920, 10921, 10924, 10950,
    10960, 11025, 11250, 11350, 11410, 11455, 11500, 11530, 11550, 11570, 11600,
    11700, 11705, 11810, 11830, 11845, 11865, 11870, 11930, 11932, 11935, 12150,
    12470, 12730, 13305, 13307, 13400, 13605, 13620, 13625, 13640, 13671, 13690,
    13700, 13790, 14615, 14640, 14646, 14845, 15057, 15058, 15080, 15464, 15480,
    15620, 15641, 15665, 15670, 15682, 15815, 15832, 16070, 16286, 16360, 16760,
    17820, 40250, 40350, 4987, 2861, 227, 270, 271, 4612, 2779, 3391, 3392,
    2273, 2271, 1350, 2797, 3867, 158, 1095, 3475, 3421, 4561, 246, 226, 2786,
    4617, 4617, 2385, 2453, 1058, 2665, 4035, 3835, 4358, 2277, 3789, 2741,
    2281, 2920, 255, 3364, 1161, 1036, 2345, 4563, 4218, 1135, 2794, 236, 4020,
    2855, 2798, 2342, 3468, 1168, 1087, 4660, 3367, 2789, 2796, 1090, 955, 256,
    258, 3778, 257, 3868, 2799, 2767, 967, 1038, 262, 4532, 264, 263, 265, 4509,
    3053, 266, 1062, 3672, 148, 147, 1136, 1163, 1189, 4603, 4867, 1169, 1171,
    2890, 1034, 4619, 320, 3422, 4217, 2801, 144, 3106, 2764, 3346, 310, 3051,
    4515,
  ],
  '1': [
    175, 220, 400, 2160, 2472, 2715, 2795, 2850, 2905, 2980, 3048, 3094, 3389,
    3500, 3610, 3769, 3805, 3863, 3873, 3875, 4000, 4045, 4300, 4537, 4590,
    4607, 4995, 4996, 11729, 11950, 11958, 11968, 12490, 12500, 13365, 13565,
    13572, 13595, 13627, 13646, 13647, 13660, 13665, 13669, 13683, 13695, 13815,
    13817, 13820, 14610, 14650, 14860, 14878, 15027, 15490, 15605, 15632, 15800,
    15855, 15900, 16260, 17818, 17830, 17980, 40350, 4614, 4584, 3847, 3848,
    3940, 2742, 3807, 3887, 2745, 3985, 3818, 4508, 4569, 2761, 3075, 3408,
    3347, 4032, 4362, 2162,
  ],
  '2': [
    215, 20020, 20050, 20200, 20250, 20330, 20350, 20400, 21050, 21100, 21500,
    23000, 23500, 24000, 24500, 25000, 26500, 27000, 27500, 27510, 28000, 28500,
    77245, 22100, 10911,
  ],
  '3': [
    164, 177, 207, 235, 1082, 1092, 2297, 2310, 2355, 2356, 2360, 2465, 2739,
    3020, 3025, 3371, 3844, 3879, 3990, 4010, 4065, 4530, 4611, 4813, 4864,
    5520, 10360, 10395, 10455, 10540, 10561, 10570, 10650, 10912, 11279, 11708,
    11720, 11933, 13200, 14000, 15675, 15910, 16200, 16258, 17000, 30005, 30020,
    30100, 30800, 30920, 30950, 30960, 30970, 30990, 31170, 31180, 31225, 31228,
    31240, 31290, 31295, 31340, 31430, 31450, 31501, 31600, 31801, 31900, 32010,
    32500, 224, 1158, 239, 2772, 4557, 3804, 3362, 1166, 4528, 2748, 2784, 3105,
    4564, 261, 3406, 3960, 1057, 4568, 1184, 4572, 268, 272, 1182, 4581, 330,
    3065, 3063, 3061, 31320, 4216, 4008, 274, 885, 1505, 3312, 2749, 340, 3111,
    276, 4970, 31090, 31226, 1069, 30915, 31171, 30007, 31227, 30120, 31191,
    31001, 30820, 31810, 30111, 30740, 32015, 30955, 30135, 31955, 30331, 31610,
    30563, 1587, 32502, 31291, 31292, 30346, 35425, 34521, 30054, 30021, 3705,
    4459, 5576, 32545, 4292,
  ],
  '4': [
    45100, 45150, 45200, 45230, 45250, 45300, 45400, 45450, 45500, 46000, 46100,
    46250, 46410, 46450, 46500, 47000, 47250, 47300, 47500, 47900, 47950, 48000,
    49950, 40120, 41020, 45350, 45140, 40200, 40160, 40140, 2585, 5596, 40130,
  ],
  '5': [1140, 3750, 3770, 2460, 2986],
  '7': [
    850, 860, 1155, 2336, 2727, 3799, 3876, 3890, 4585, 4605, 4609, 4805, 10545,
    11960, 13304, 13685, 13810, 14870, 15465, 15637, 15663, 15673, 15857, 15905,
    17515, 50070, 50450, 50460, 50630, 50650, 50660, 50680, 50700, 50800, 50850,
    50950, 51140, 51220, 51250, 51300, 51455, 51460, 51470, 51510, 51840, 51900,
    51930, 52050, 52100, 52150, 52750, 52900, 77430, 51511, 2777, 2722, 4356,
    2337, 50799, 4828, 251, 3400, 51160, 3342, 3604, 3366, 138, 4063, 51095,
    3474, 65650, 52011, 51925, 51245, 50440, 90003, 90004, 90007, 15079, 50161,
    50071, 90024, 90025, 80030, 90028, 15997, 51865, 50068, 4896, 90042, 12542,
    50545, 51211, 54288, 90055, 90045, 6702, 90020, 52285, 90075, 90078, 90018,
    90077, 50682, 50162, 55854, 90082, 90038, 51830, 90041, 1325, 3009, 90013,
    90006, 90079, 6976, 90037, 11538, 50730, 50725, 90044, 90029, 51932, 90005,
    90080, 15801, 91030, 90002, 7020, 7021, 90072, 90071, 90074, 1655, 4469,
    50683, 90033, 3004, 50030, 51581, 14136, 50090, 50795, 52451, 50798, 90000,
    90026, 90048, 51928, 51842, 3218, 90034, 50017, 50825, 50791, 90027, 50797,
    51855, 50653, 90021, 50760, 90050, 50121, 50902, 51450, 90015, 90046, 50341,
    51458, 5044, 51210, 90030, 50037, 90083, 90011, 90076, 90081, 51870, 50340,
    90053, 90047, 50652, 5642, 90008, 52000, 90017, 90057, 90056, 90014, 50480,
    5712, 90084, 90035, 90010, 55795,
  ],
  '8': [
    10555, 80020, 80050, 80075, 80180, 80210, 80225, 80250, 80400, 82600, 83900,
    85000, 85100, 85600, 86000, 86001, 80235, 80215, 86501, 80230, 2673, 80227,
    86710, 80350, 85050, 80030, 85700, 80040, 80085, 86720, 80245, 80255, 12226,
    80110, 11284, 80010, 80022, 80021, 80061, 80000, 85200, 80150,
  ],
  '9': [
    3880, 10200, 12550, 70700, 72410, 72430, 72450, 74500, 78550, 78740, 78750,
    71100, 77435, 70060, 77460, 78410, 16335, 70150, 78372, 321, 78401,
  ],
  '10': [
    20010, 29000, 78300, 1118, 15187, 10270, 10651, 25003, 25012, 25038, 25009,
    11979, 5287, 78360, 1253, 15189, 78500, 11615, 11547, 2685, 25010, 11021,
    25030, 25011, 25001, 12241, 25032, 5195, 25008, 25036, 25039, 25023, 25022,
    3833, 25016, 15024, 25015, 25017, 25013, 4527, 25034, 25031, 2916, 25035,
  ],
  '11': [
    1145, 2723, 2603, 10222, 11567, 5964, 19589, 4759, 11585, 51705, 50001,
    60067, 5998, 2639, 14052, 1508, 16584, 16445, 6214, 6899, 5744, 10722, 1620,
    50745, 15355, 50008, 2620, 3785, 14726, 3648, 3350, 1858, 10546, 5568,
  ],
  '12': [
    1621, 2250, 2710, 2256, 2894, 5624, 14300, 5915, 5918, 5942, 4379, 15346,
    10178, 15667, 50651, 50736, 16101, 5794, 14257, 1416, 7049, 3299, 1268,
    6199, 15669, 11726, 168, 1880, 3700, 10615, 10577, 4649, 3904, 4684, 3566,
    5042, 12102, 1406, 17365, 11953, 12022, 3178, 4252, 3992, 41700, 5787, 5788,
    3353, 1150, 14586, 2401, 11509, 7141, 200, 900,
  ],
  '14': [137, 910, 2725, 2726, 3090, 3100, 4804, 1810],
  '15': [
    10065, 90043, 4099, 4127, 4128, 4291, 4432, 4716, 5047, 5474, 5493, 4912,
    5973, 6312, 6177, 3790, 11451, 5043, 5433, 15185, 5019, 15034, 4191, 6985,
    17321, 6606, 52200, 6799, 6970, 4989, 5240, 12192, 7034, 15038, 6813, 10592,
    5736, 15192, 2630, 16931, 13892, 16604, 68350, 9856, 54284, 3055, 3583,
    2943, 10824, 2995, 11106,
  ],
  '16': [
    10525, 13330, 51650, 3752, 331, 13841, 10173, 15982, 17382, 17385, 14875,
    2553, 5572, 2983, 4265, 4439, 4441, 5205, 5220, 5365, 5376, 5424, 5977,
    4665, 3635, 2776, 182, 5467, 10613, 2140, 3987, 5034, 2957, 12271, 13610,
    10126, 6249, 6680, 5687, 3983, 6423, 13684, 2520, 6043, 12724, 7100, 11416,
    11967, 50735, 3696, 7120, 50007, 1810, 1700, 3286, 6315, 50006, 15967, 6167,
    90061, 7188, 7223, 7224, 7222, 13609, 10146, 5703, 7191, 40100, 11099, 6008,
    831, 5352, 2811, 13026, 3579, 16071, 7251, 7249, 7250, 7117, 7239, 7005,
    7267, 4782, 6496, 7130, 7284, 7283, 6182, 11280, 7288, 7291, 7310, 7306,
    7308,
  ],
  '19': [
    4323, 3663, 11022, 10908, 66410, 410, 4606, 80200, 50790, 1175, 1792, 11345,
    29570, 5364, 3991, 5196, 6511, 7169, 7006, 6816, 2187,
  ],
  '20': [
    2725, 2726, 77230, 2760, 4511, 10944, 5065, 51841, 5033, 4078, 3973, 3974,
    3975, 3976, 3977, 3978, 3980, 5631, 5734, 7095, 5584, 5227, 136, 3232,
    13502, 3982, 5027, 1671, 4594, 2130, 13505, 4860,
  ],
  '22': [
    1810, 3790, 911, 4665, 2868, 13796, 11451, 3635, 13732, 13586, 5043, 5433,
    2776, 124, 6059, 3279, 10026, 1790, 6415, 4452, 2957, 15034, 12271, 10930,
    5272, 6228, 4222, 12192, 51920, 50150, 10586, 2102, 6315, 15967, 6082, 6167,
    90061, 1796, 4640, 40100, 10071, 12207, 6062, 15360, 5633, 5352, 2811,
    16931, 17366, 13892, 6240, 67540, 16604, 6359, 16071, 68350, 9856, 3954,
    3055, 6929, 2943, 5655, 2050, 3013, 6798,
  ],
  '24': [
    169, 30330, 4918, 331, 3616, 15960, 15329, 14913, 12015, 16583, 90043, 2678,
    11985, 19187, 2553, 50016, 2571, 2983, 3138, 4069, 4097, 5275, 5412, 5424,
    5665, 4912, 6013, 6046, 6334, 6343, 6365, 6372, 6374, 6412, 6428, 6430,
    6436, 6438, 6441, 6442, 6445, 6446, 6448, 6450, 6453, 6454, 6455, 6459,
    6458, 6460, 6461, 6470, 6476, 6477, 6479, 6481, 6485, 6487, 6491, 6507,
    6508, 6510, 6519, 6520, 6525, 6530, 6532, 6533, 6540, 6544, 6546, 6549,
    6555, 6558, 6575, 6579, 6580, 6583, 6584, 6585, 3510, 6593, 6592, 6597,
    6602, 6604, 6177, 6610, 6611, 4121, 6613, 6614, 6617, 6623, 6630, 6635,
    4058, 3734, 6637, 6640, 6639, 6642, 6643, 6644, 6646, 3790, 911, 4665, 2868,
    13796, 11451, 3635, 13732, 13586, 5433, 2776, 124, 6059, 6647, 6648, 6649,
    6651, 12934, 12286, 182, 5467, 4452, 14528, 15185, 10613, 2140, 3220, 3987,
    5019, 5034, 2957, 15034, 12271, 13610, 10930, 6654, 5331, 3720, 5272, 6663,
    5908, 6668, 6680, 6689, 6691, 6692, 6699, 5519, 3983, 6704, 6706, 6710,
    2570, 6714, 6718, 6719, 6954, 4343, 4101, 6236, 1857, 4106, 3691, 6732,
    6740, 6619, 6744, 6745, 336, 6747, 6749, 6750, 6753, 6759, 6751, 6761, 6762,
    4774, 5810, 6866, 6764, 6766, 6767, 6768, 6773, 6774, 6785, 6781, 12124,
    6788, 2907, 3938, 6534, 6793, 6797, 6809, 6814, 6822, 5181, 11316, 16772,
    6853, 6852, 3275, 6861, 6862, 6868, 3751, 6875, 6883, 6882, 13684, 6888,
    17648, 5562, 4446, 15082, 10447, 6921, 6922, 6923, 12059, 6925, 6930, 6944,
    6937, 6931, 6932, 6935, 6942, 6043, 5652, 6955, 6963, 6964, 3631, 6966,
    4222, 16771, 6105, 6983, 6975, 6981, 5418, 6493, 6984, 4781, 15706, 6994,
    3649, 5256, 7003, 7002, 7014, 4036, 7029, 7032, 11009, 7042, 7055, 7059,
    15731, 7073, 4989, 1780, 7093, 7096, 11937, 1426, 6344, 11891, 11868, 6711,
    14258, 3695, 3696, 5782, 7122, 50018, 7123, 7124, 4253, 7128, 7127, 7132,
    7136, 7138, 5163, 10515, 15549, 7149, 4120, 7157, 7158, 7114, 4776, 7159,
    7162, 7173, 7163, 7171, 50150, 2993, 7175, 7184, 7207, 1810, 6167, 90061,
    7220, 1796, 5185, 7028, 3954, 2943, 7266, 6618, 10563, 15963, 5655, 11280,
    11106, 166, 2887, 6876,
  ],
  '25': [
    132, 4486, 9584, 5600, 6002, 6057, 11946, 51475, 7588, 5322, 15227, 5455,
    15425, 5536, 5458, 5623, 5622, 6232, 5387, 5529, 5266, 5454, 5972, 12246,
    5538, 14893, 2820, 5497, 5420, 5456, 13802, 5476, 5537, 5457, 15456, 5535,
    12093, 5165, 5486, 5484, 5260, 5477, 5036, 16340, 2709, 15667, 15533, 294,
    3885, 71950, 13548, 12064, 3072, 6849, 225, 1880, 3310, 10577, 13680, 52800,
    2628, 77370, 4647, 4684, 78710, 45260, 10594, 11277, 15081, 5042, 16533,
    12102, 11283, 10112, 6898, 11986, 6439, 3646, 5013, 5022, 5030, 5032, 5674,
    77210, 78155, 5112, 7177, 52450, 3353, 3266, 2401, 1601, 16338, 7141, 6285,
    12505, 3143,
  ],
  '27': [
    4244, 52950, 15902, 2370, 411, 6677, 4707, 209, 10033, 359, 5718, 3947,
    14150, 14100, 5606, 90039, 12010, 10214, 5254, 90016, 16734, 4054, 6686,
    7064, 7053, 6734, 7079, 5943, 2270, 12114, 7098, 16763, 15658, 54256, 3258,
    7216, 5010, 6518, 3370, 5742,
  ],
  '28': [
    10520, 2051, 10511, 10177, 4072, 16343, 13583, 7585, 65248, 54854, 90051,
    10258, 3686, 4138, 4372, 4789, 5705, 5771, 5804, 5817, 5818, 5826, 5831,
    6012, 6064, 6114, 6147, 6148, 6151, 6222, 6242, 5811, 6282, 6283, 60037,
    60038, 60039, 6314, 60040, 60041, 60044, 60045, 60046, 60047, 60048, 60050,
    60051, 6443, 60052, 60055, 60057, 3316, 60058, 6679, 6696, 60066, 66000,
    6758, 6765, 51705, 67490, 68500, 66350, 67480, 50001, 5886, 62247, 66504,
    18550, 5239, 6872, 11842, 3225, 233, 1123, 6111, 5998, 2639, 6063, 5749,
    60061, 219, 5752, 14052, 1508, 16584, 16445, 6214, 6899, 6996, 54124, 7031,
    7035, 6573, 11421, 10722, 50745, 12149, 52458, 51520, 4521, 7116, 7125,
    6588, 67560, 7164, 16044, 7209, 63400, 6915, 1138, 3096, 7203, 3196, 16007,
    7214, 15359, 68120, 60000, 6492, 6465, 2269, 3648, 298, 5746, 4319, 7293,
    5568, 7299, 7298, 9008,
  ],
  '29': [2913],
  '30': [
    3077, 4486, 2666, 11873, 15074, 15854, 16408, 12086, 12087, 5990, 6524,
    4726, 5437, 6183, 6184, 5036, 15667, 15533, 78420, 6928, 2606, 6556, 14572,
    19854, 6007, 7077, 3072, 2455, 3310, 52800, 2628, 131, 2316, 10682, 45260,
    15783, 11277, 15081, 3659, 4145, 11986, 2921, 5013, 5030, 5032, 5674, 6296,
    2655, 13414, 2958, 2295, 4388, 7280, 12505, 7303,
  ],
  '32': [12288, 5924, 4379, 4538, 15346, 6877, 6138, 4737, 3168, 84000, 12229],
  '33': [
    3793, 9584, 2970, 4538, 6601, 3242, 3690, 13375, 50002, 989, 2005, 3267,
    5098, 6612, 294, 10406, 10681, 6168, 10928, 10112, 6898, 5501, 6439, 6048,
    6206, 3646, 6083, 6902, 50015, 5222, 11062, 1601, 7233, 3169, 3143,
  ],
  '35': [
    14726, 5035, 1072, 3927, 14130, 16386, 3989, 17367, 66500, 66493, 3959,
    19760, 7231, 1859, 5947, 52452, 66503, 5746, 5709, 67101, 68505, 60029,
    60028, 127,
  ],
  '36': [
    4490, 5525, 6913, 6275, 6276, 7065, 3071, 6280, 7081, 5944, 50075, 15231,
    17254, 50065, 16359, 2933, 2755, 2577, 12491, 13306, 6006, 6393, 1407, 3930,
    3949, 6172, 4080, 3771, 1006, 7101, 7108, 10039, 2282, 6543, 2918, 16367,
    116, 7255, 3040, 5725, 5436, 12347, 6164, 11146, 12485,
  ],
  '37': [
    6419, 7086, 3273, 3289, 3578, 12112, 12111, 5217, 1291, 2690, 2275, 3129,
    4321, 11641, 3981, 5024, 4721, 12710, 51400, 11855, 51960, 6234,
  ],
  '38': [
    4213, 16395, 51475, 5036, 15533, 4899, 6138, 11136, 1627, 14297, 5428,
    65281, 70001, 5028, 5031, 6403, 3885, 78420, 10149, 10020, 4201, 5123, 1540,
    10758, 225, 13680, 74450, 77370, 4647, 5711, 15903, 11064, 78710, 10594,
    16533, 78370, 11283, 15110, 5229, 5569, 5022, 2705, 965, 12229, 16383,
    52450, 10216, 11860, 5755, 3786, 15498, 3266, 13608, 16338, 14295, 6673,
    5264,
  ],
  '39': [
    5282, 360, 4897, 361, 363, 456, 364, 454, 365, 5777, 368, 7089, 369, 370,
    1410, 371, 5341, 2791, 15085, 372, 373, 374, 5004, 3308, 377, 378, 3447,
    379, 380, 3452, 6220, 6298, 381, 382, 383, 5115, 366, 367, 375, 384, 385,
    386, 387, 388, 389, 390, 391, 393, 7084, 7068, 446, 437, 464, 461, 6339,
    429, 436, 376, 458, 362, 13582, 426, 19321, 433, 5190, 467, 473, 7085, 449,
    453, 421, 432, 471, 430, 600, 18223, 418, 468, 5114, 7078, 6302, 4415, 392,
  ],
  '40': [
    169, 30330, 14913, 5124, 15553, 90043, 2913, 5258, 5415, 5543, 6078, 6142,
    911, 2868, 13796, 5043, 4452, 3220, 10930, 15706, 1780, 51920, 4260, 4098,
    4133, 2102, 4640, 10071, 5054, 6008, 50793, 2100, 193, 3172, 13026, 4988,
    6359, 16071, 3954, 5655, 3013,
  ],
  '41': [
    6138, 13375, 50651, 11136, 1627, 14297, 50002, 50736, 989, 2005, 5428,
    65281, 70001, 3267, 5098, 5028, 5031, 6403, 6612, 16101, 10406, 78420,
    10149, 3299, 1268, 3072, 4201, 5123, 6199, 15669, 11726, 1540, 168, 225,
    1880, 2455, 3310, 3700, 10615, 10577, 13680, 52800, 74450, 2628, 77370,
    4647, 4649, 5711, 131, 2316, 15903, 11064, 3904, 4684, 78710, 3566, 10681,
    10682, 45260, 10594, 15783, 11277, 6168, 15081, 5042, 16533, 12102, 1406,
    10928, 78370, 17365, 11283, 10112, 6898, 5501, 11953, 12022, 15110, 3659,
    4145, 11986, 2921, 3178, 5229, 5569, 6439, 6048, 6206, 3646, 4252, 5013,
    5022, 5030, 5032, 5674, 6083, 965, 3992, 41700, 6902, 16383, 5787, 5788,
    52450, 10216, 3353, 11860, 5222, 14586, 3786, 5786, 13608, 2295, 2401,
    11509, 1601, 16338, 7141, 200, 12505, 900, 3143, 5264,
  ],
  '42': [
    135, 3560, 71900, 78280, 1040, 4846, 349, 4895, 16053, 7000, 4706, 5589,
  ],
  '43': [
    65770, 66301, 252, 4930, 67520, 66498, 4930, 65905, 66492, 67060, 67525,
    65860, 67554, 50066, 66494, 65710, 67090, 12247, 12249, 69853, 3222, 5618,
    60026, 60027, 65500, 65310, 62247, 66451, 66504, 5239, 65612, 60012, 66280,
    68511, 64410, 65960, 66450, 68510, 67999, 67356, 60069, 65903, 64901, 65400,
    64586, 66510, 60013, 66155, 60068, 66511, 66390, 66441,
  ],
  '44': [
    66490, 4554, 4044, 6503, 66515, 61550, 66512, 67545, 67201, 62542, 62544,
    65610, 18586, 18585, 65611, 65214, 65221, 65223, 62514, 65245, 60003, 3269,
    4295, 4372, 60017, 60021, 6218, 60033, 66150, 65328, 60004, 60009, 60010,
    60053, 5391, 60006, 12189, 60023, 66400, 6191, 6409, 6564, 60060, 63302,
    6563, 66587, 6264, 60001, 63300, 68271, 66156, 66355, 68270, 66497, 66496,
    63301, 66540, 66502, 65488, 60007, 60008, 65024, 5400, 66596, 66506, 67200,
    60059, 60011, 60024, 60014, 5370, 7259, 7261, 7260, 66100, 13621, 60016,
  ],
  '45': [
    3883, 65980, 66250, 65950, 64550, 66462, 15676, 67341, 66452, 68480, 10614,
    2154, 63256, 50000, 60002, 6567, 65760, 68000, 12185, 60065, 67556, 65300,
    67358, 67400, 64400, 67490, 68500, 66350, 67480, 18550, 12126, 3802, 67380,
    66906, 11421, 5440, 67560, 65904, 63400, 6957, 6926, 90049, 66905,
  ],
  '46': [
    68280, 66487, 67335, 65860, 50066, 66785, 50000, 3269, 60017, 60026, 60027,
    60036, 60042, 60043, 60049, 60064, 60054, 65310, 65328, 60009, 60010, 60053,
    66451, 50067, 5391, 65612, 60012, 12189, 66280, 67391, 60023, 64410, 66450,
    68510, 67999, 6030, 65902, 65216, 65222, 5744, 15083, 6564, 60060, 63302,
    5819, 4279, 6149, 60019, 60018, 64901, 65400, 64586, 16106, 15125, 5261,
    65940, 3201, 6605, 60059, 7090, 6198, 67051, 60013, 66155, 60068, 66100,
    13621, 5928, 10608, 165, 66516, 5747, 5648, 60016,
  ],
  '47': [
    3883, 64700, 66250, 3803, 65950, 65901, 66515, 66462, 65775, 67525, 67341,
    68480, 10616, 10614, 12174, 18772, 2154, 65248, 63256, 62543, 65610, 65611,
    13250, 65218, 60002, 2601, 60003, 6107, 60031, 60035, 66150, 12185, 16605,
    65500, 67556, 65300, 67358, 67400, 64400, 60004, 60006, 66400, 6004, 6003,
    7033, 67380, 60063, 66906, 5440, 60001, 63300, 68271, 66156, 66355, 68270,
    66497, 66496, 63301, 66540, 66502, 65488, 60007, 60008, 65024, 5400, 66506,
    7153, 65904, 6005, 60070, 67200, 60011, 60024, 11302, 6962, 3181, 60025,
    6821, 60022, 7205, 13355, 60020, 60034, 7099, 2602, 7198, 65220, 5960,
    65217, 65215, 60014, 7247, 7248, 7257, 66480, 6846, 7294, 5871,
  ],
  '48': [
    30563, 31292, 10911, 30015, 30025, 30017, 30018, 30345, 30028, 30515, 30520,
    30798, 30797, 30600, 30790, 31293, 31150, 30910, 31192, 30035, 31300, 5289,
    3901, 31802, 4560, 31805, 416, 14666, 2974, 30795, 15123, 5587, 6185, 2594,
    30042, 30034, 5839, 30032, 30056, 2618, 30041, 30057, 6186, 16921, 30036,
    30038, 2968, 4427, 15127, 5368, 65428, 7015, 30033, 30026, 5443, 30062,
    2540, 31750, 15677, 12017, 7323,
  ],
  '49': [
    30150, 1069, 31001, 30111, 32015, 32502, 34521, 16341, 32110, 15093, 30008,
    30052, 30515, 30520, 30798, 14960, 13736, 30013, 16548, 4324, 31477, 31190,
    31192, 31070, 30009, 30006, 13832, 31352, 31802, 30019, 31805, 31950, 32001,
    32016, 30058, 237, 9001, 31000, 30051, 30016, 30059, 30012,
  ],
  '50': [
    3371, 30020, 31290, 31291, 30346, 35425, 30054, 2962, 18762, 15211, 30015,
    30025, 31246, 4342, 30605, 30797, 30600, 5279, 30790, 6483, 30961, 16537,
    31962, 30340, 31150, 30014, 31245, 35914, 31231, 31350, 31306, 30003, 15001,
    30011, 30000, 3741, 306, 10117, 32550, 30780, 30060, 30061, 30001, 15009,
    31400, 9000, 30029, 30002, 30027, 4316, 3393, 3547, 4292, 31750,
  ],
  '52': [30021, 5576, 32545],
  '54': [77180, 77700, 254, 2744, 14414, 15485],
  '55': [10651, 25003, 25040],
  '56': [
    20010, 7285, 25048, 78650, 78680, 25019, 25027, 5311, 2929, 25025, 14846,
    5423, 6115, 25028, 5692, 5896, 25040, 7165, 25049, 10053, 25021, 25005,
    25042, 5249, 11094, 25037, 25004, 2999, 7023, 15195, 11557, 14862, 1486,
    25006, 25046, 25043, 25029, 25014, 25026, 25044, 25020, 25033, 25047, 25024,
    5767, 3608, 14162, 13991, 15198, 25045, 6791, 25007, 4367, 5845, 9004, 4639,
    25018,
  ],
  '58': [70700, 71100, 77435, 78410, 70150, 321, 78401],
  '59': [
    12237, 54854, 90051, 4372, 4203, 12224, 4731, 6376, 6375, 6377, 54124, 7017,
    11814, 65219, 6565, 1620, 16044, 2269, 3648, 3350, 60032, 10546,
  ],
  '62': [
    10190, 3616, 16854, 15183, 2956, 5825, 7300, 6380, 414, 2955, 5827, 6017,
    6379, 13732, 13586, 124, 6059, 14528, 16050, 6733, 11543, 5060, 11068, 5822,
    13833, 50150, 13840, 6315, 6082, 1796, 4640, 6022, 12207, 5054, 6062, 15360,
    5633, 2700, 6023, 5909, 51472, 17366, 14847, 6240, 67540, 16398, 102, 6155,
    2050, 166,
  ],
  '63': [
    169, 1850, 4800, 30330, 40315, 331, 3616, 12223, 2553, 2983, 5424, 4912,
    6177, 182, 5467, 14528, 15185, 10613, 2140, 3220, 3987, 5019, 5034, 13610,
    3983, 3751, 15706, 4989, 1780, 5240, 3696, 50007, 1810, 1700, 6813, 3286,
    10592, 50021, 50006, 5736, 13609, 6022, 11099, 5054, 6008, 50793, 2100, 831,
    15192, 193, 2630, 2700, 6023, 5909, 51472, 3172, 13026, 3579, 4988, 14847,
    16398, 3583, 102, 6155, 11280, 10824, 2050, 11106,
  ],
  '65': [50826, 12046],
  '66': [
    50450, 50680, 51930, 2722, 51095, 3557, 50440, 90003, 90004, 90007, 90025,
    17001, 90042, 90077, 50682, 50162, 51830, 1325, 3009, 90013, 11538, 50725,
    3004, 50030, 50090, 50798, 90026, 90048, 51928, 51842, 90034, 50017, 50825,
    90027, 50797, 51855, 50653, 90021, 50760, 90050, 50121, 50902, 51450, 90015,
    90046, 51210, 50037, 51870, 50340, 90053, 90047, 50652, 90008, 52000, 90017,
    90014, 50480, 90010,
  ],
  '67': [
    11354, 3004, 14136, 50090, 52451, 50798, 90000, 51928, 51842, 3218, 50017,
    50825, 50791, 90027, 50797, 50653, 90021, 90050, 90015, 90046, 51210, 90030,
    90011, 51870, 50652, 90008, 90017, 90057, 90014, 90010,
  ],
  '68': [
    15712, 90020, 52285, 90075, 90078, 90018, 55854, 90082, 90038, 90041, 90006,
    90079, 6976, 90037, 50730, 90044, 90029, 51932, 90005, 90080, 15801, 91030,
    7020, 7021, 90072, 90071, 90074, 90033, 51581, 14136, 50795, 52451, 90000,
    3218, 50791, 50341, 51458, 5044, 90030, 90083, 90011, 90076, 90081, 5642,
    90057, 90056, 5712, 90084, 90035, 55795,
  ],
  '69': [15997, 50068],
  '70': [
    11420, 3664, 52459, 3405, 15895, 3641, 3772, 167, 3176, 1156, 67295, 51270,
    11011, 6815, 50170, 4981, 5850, 90019, 5151, 16018, 12044, 2927,
  ],
  '71': [
    50850, 51865, 12542, 50795, 90026, 90048, 90034, 50760, 50121, 50902, 51450,
    90076, 90081, 50340, 90047, 52000, 90056, 50480,
  ],
  '72': [
    10346, 4701, 13935, 11432, 11001, 15311, 11911, 16395, 17354, 11428, 12140,
    13224, 7586, 5919, 9584, 19758, 4762, 4778, 4879, 5191, 5224, 5237, 5251,
    5263, 5286, 5312, 5335, 5366, 5367, 5379, 5392, 5403, 5406, 5419, 5427,
    5435, 5439, 5446, 5448, 5459, 5464, 5488, 5489, 5491, 5511, 5517, 5522,
    5558, 7039, 5710, 4903, 4904, 5832, 5836, 6002, 6120, 6162, 3023, 51475,
    2923, 6601, 17550, 5547, 5546, 5548, 4892, 6661, 6665, 13375, 50651, 11136,
    1627, 14297, 50002, 50736, 989, 2005, 5428, 65281, 70001, 3267, 5098, 5028,
    5031, 6403, 6612, 16101, 294, 12160, 3885, 10406, 3213, 280, 6190, 6179,
    1108, 11536, 5799, 6102, 11487, 6207, 14664, 10149, 10107, 6203, 3449, 3596,
    4201, 1540, 6168, 10928, 78370, 17365, 5501, 6206, 6083, 3992, 16383, 5787,
    5788, 50015, 5048, 11860, 1150, 5786, 15498, 13608, 11509, 7228, 7018, 6986,
    900, 5264, 7318,
  ],
  '73': [
    16395, 4379, 4538, 15346, 6671, 6672, 4220, 16275, 4773, 4642, 6050, 6287,
    16290, 11517, 6789, 3299, 1268, 5123, 6199, 15669, 11726, 168, 2455, 3700,
    10615, 74450, 4649, 5711, 131, 2316, 15903, 11064, 3904, 3566, 10681, 10682,
    15783, 1406, 11953, 12022, 15110, 3659, 4145, 2921, 3178, 5229, 5569, 6048,
    4252, 965, 12229, 10216, 50015, 7046, 5222, 14586, 5755, 11062, 2295, 200,
    4692, 7305,
  ],
  '74': [
    6941, 6268, 2952, 504, 5518, 3647, 6429, 5021, 15030, 4241, 14141, 5821,
    126, 4741, 6310, 6233, 495, 77300, 3633, 6675, 2928, 51700, 51702, 968,
    11738, 3313, 1702, 4669, 5002, 12025, 6920, 10055, 3002, 5274,
  ],
  '75': [
    259, 3781, 2357, 11063, 4915, 5268, 11317, 14756, 11381, 16345, 17382,
    10425, 3256, 5878, 5917, 2641, 2913, 2973, 3244, 4293, 5113, 5348, 5369,
    5404, 5471, 5593, 5614, 5660, 5673, 5748, 6058, 11, 6160, 6161, 6163, 6173,
    6174, 6202, 6255, 6297, 6385, 7001, 6413, 6416, 6417, 6424, 6426, 6427,
    6434, 6449, 6478, 6480, 6482, 6486, 6494, 6497, 6502, 6504, 6505, 6512,
    6513, 6551, 2942, 4115, 11163, 6600, 4435, 3254, 6522, 6594, 12551, 6051,
    12487, 6669, 5687, 6352, 2886, 6708, 6721, 6717, 6722, 6723, 6636, 6726,
    6731, 5567, 6855, 6763, 11719, 6313, 6784, 6779, 6787, 6790, 6629, 5707,
    6246, 6247, 6271, 6304, 6338, 6357, 6358, 6484, 6660, 6626, 6523, 6796,
    6808, 6838, 6293, 6830, 6829, 6839, 6827, 6811, 6806, 6804, 6847, 4622,
    5176, 6848, 6354, 5308, 6885, 6832, 6890, 1233, 6269, 6897, 14134, 6856,
    6506, 6904, 6911, 6912, 6905, 12754, 6916, 6917, 6495, 6951, 6950, 5514,
    5513, 12284, 6627, 7004, 7009, 6116, 5714, 7008, 6850, 3829, 12075, 6071,
    6366, 6992, 7083, 6420, 2533, 2664, 9006, 4757, 4177, 6526, 16930, 7121,
    7120, 7131, 7139, 7143, 7144, 9007, 7145, 7148, 7150, 11032, 12544, 14466,
    4539, 7190, 2735, 6658, 16351, 5105, 4844, 6598, 11162, 4256, 15823, 5950,
    7201, 7199, 7200,
  ],
  '76': [15402, 5667, 5701, 6194, 6192, 6209, 6221],
  '78': [
    3333, 5014, 5018, 5184, 3984, 6061, 4369, 6237, 5240, 12192, 51920, 3988,
    7040, 11728, 50007, 2102, 1700, 12494, 3286, 10592, 50021, 50006, 15967,
    6193, 5736, 19755, 3693, 7188, 7217, 7213, 7215, 7212, 7197, 7192, 7189,
    15983, 7174, 7170, 7154, 7223, 7224, 7225, 7222, 13609, 5703, 7240, 7191,
    11099, 12207, 6062, 50793, 5352, 15192, 2700, 5909, 2811, 51472, 3172, 3579,
    4988, 14847, 6240, 67540, 16604, 16398, 68350, 9856, 15804, 51521, 7251,
    7249, 7250, 7246, 7117, 7239, 7005, 3055, 11422, 3583, 7265, 7267, 6993,
    6496, 6572, 7269, 7271, 7264, 7274, 7278, 102, 7130, 7281, 7284, 7283, 7288,
    7291, 10824, 2050, 2887, 5310, 3013, 7310, 7306, 7308, 4714, 7317, 7319,
    7325, 7324,
  ],
  '79': [
    6212, 5276, 14626, 70050, 2651, 12532, 14585, 7111, 12085, 5132, 10145,
    10061, 15966, 4202, 6243, 10142, 15190, 3281, 3598, 5252, 1167, 19216,
    67150, 4204, 6200, 5762, 12515, 11978, 15154, 7112, 4566, 12063, 5740, 7060,
    10202, 51001, 90058, 54287, 50010, 54285, 51002, 90059, 54286, 50011, 50005,
    50012, 50004, 90060, 50003, 50009, 10131, 12399, 1000, 11983, 12400, 2586,
    2874, 6531, 10357,
  ],
  '99': [2260, 2262, 2263, 2249, 1493],
};
