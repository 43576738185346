export const corrientesLocalities=[
    {"afip":"8","location":"2 De Julio"},
    {"afip":"28","location":"8 De Diciembre"},
    {"afip":"31","location":"Abalo"},
    {"afip":"37","location":"Abeli"},
    {"afip":"40","location":"Abo Nezu"},
    {"afip":"41","location":"Abra"},
    {"afip":"56","location":"Abra Guazu"},
    {"afip":"92","location":"Acuña"}
    ,
    {"afip":"93","location":"Acuña Cue"}
    ,
    {"afip":"198","location":"Aguapey"},
    {"afip":"200","location":"Aguara Cua"},
    {"afip":"215","location":"Aguay"},
    {"afip":"219","location":"Aguirre Cue"},
    {"afip":"220","location":"Aguirre Lomas"},
    {"afip":"237","location":"Alamo"},
    {"afip":"245","location":"Albardon"},
    {"afip":"247","location":"Albardones"},
    {"afip":"302","location":"Alejandria"},
    {"afip":"313","location":"Alen Cue"},
    {"afip":"320","location":"Alfonso Lomas"},
    {"afip":"327","location":"Algarrobal Puisoye"},
    {"afip":"329","location":"Algarrobales"},
    {"afip":"338","location":"Algarrobo Paraje"},
    {"afip":"378","location":"Alta Mora"},
    {"afip":"385","location":"Altamora Parada"},
    {"afip":"477","location":"Alvear"},
    {"afip":"580","location":"Angua"},
    {"afip":"631","location":"Apipe Grande"},
    {"afip":"650","location":"Arañita"}
    ,
    {"afip":"676","location":"Arerungua"},
    {"afip":"685","location":"Aristia"},
    {"afip":"716","location":"Arroyo Ambrosio"},
    {"afip":"718","location":"Arroyo Balmaceda"},
    {"afip":"739","location":"Arroyo Carancho"},
    {"afip":"743","location":"Arroyo Casco"},
    {"afip":"744","location":"Arroyo Castillo"},
    {"afip":"745","location":"Arroyo Ceibal"},
    {"afip":"776","location":"Arroyo Garay"},
    {"afip":"779","location":"Arroyo Gonzalez"},
    {"afip":"784","location":"Arroyo Horqueta"},
    {"afip":"812","location":"Arroyo Manganga"},
    {"afip":"816","location":"Arroyo Mendez"},
    {"afip":"825","location":"Arroyo Paiso"},
    {"afip":"831","location":"Arroyo Pelon"},
    {"afip":"842","location":"Arroyo Ponton"},
    {"afip":"852","location":"Arroyo San Juan"},
    {"afip":"860","location":"Arroyo Sarandi"},
    {"afip":"861","location":"Arroyo Saturno"},
    {"afip":"863","location":"Arroyo Solis"},
    {"afip":"864","location":"Arroyo Soro"},
    {"afip":"868","location":"Arroyo Timboy"},
    {"afip":"872","location":"Arroyo Totoras"},
    {"afip":"875","location":"Arroyo Vega"},
    {"afip":"947","location":"Ayala Cue"},
    {"afip":"992","location":"Baibiene"},
    {"afip":"1042","location":"Bajo Guazu"},
    {"afip":"1117","location":"Balengo"},
    {"afip":"1180","location":"Bargone"},
    {"afip":"1193","location":"Barrancas"},
    {"afip":"1220","location":"Barrio Algarrobo"},
    {"afip":"1329","location":"Barrio Villa Cordoba"},
    {"afip":"1341","location":"Bartolome Mitre"},
    {"afip":"1359","location":"Bastidores"},
    {"afip":"1360","location":"Basualdo"},
    {"afip":"1361","location":"Batal"},
    {"afip":"1363","location":"Batara"},
    {"afip":"1365","location":"Batay"},
    {"afip":"1367","location":"Batel"},
    {"afip":"1368","location":"Batelito"},
    {"afip":"1378","location":"Baygorria"},
    {"afip":"1397","location":"Bañado Norte"}
    ,
    {"afip":"1398","location":"Bañado San Antonio"}
    ,
    {"afip":"1399","location":"Bañado Sur"}
    ,
    {"afip":"1422","location":"Bedoya"},
    {"afip":"1461","location":"Bernachea"},
    {"afip":"1470","location":"Beron De Astrada"},
    {"afip":"1489","location":"Blanco Cue"},
    {"afip":"1524","location":"Boliche Lata"},
    {"afip":"1532","location":"Bonete"},
    {"afip":"1535","location":"Bonpland"},
    {"afip":"1537","location":"Boqueron"},
    {"afip":"1538","location":"Boranza"},
    {"afip":"1575","location":"Bregain Cue"},
    {"afip":"1578","location":"Briganis"},
    {"afip":"1581","location":"Broja Cue"},
    {"afip":"1590","location":"Buena Esperanza"},
    {"afip":"1594","location":"Buena Ventura"},
    {"afip":"1621","location":"Caa Carai"},
    {"afip":"1622","location":"Caa Cati"},
    {"afip":"1623","location":"Caa Garay"},
    {"afip":"1624","location":"Caa Garay Gdor Valentin"},
    {"afip":"1625","location":"Caa Guazu"},
    {"afip":"1626","location":"Caabi Poi"},
    {"afip":"1627","location":"Caaby Poy"},
    {"afip":"1629","location":"Caayobay"},
    {"afip":"1635","location":"Cabaña"}
    ,
    {"afip":"1665","location":"Cabred"},
    {"afip":"1698","location":"Cafarreño"}
    ,
    {"afip":"1704","location":"Caiman"},
    {"afip":"1769","location":"Callejon"},
    {"afip":"1783","location":"Camba Cua"},
    {"afip":"1786","location":"Cambal"},
    {"afip":"1787","location":"Cambara"},
    {"afip":"1788","location":"Cambireta"},
    {"afip":"1839","location":"Campo Araujo"},
    {"afip":"1852","location":"Campo Bordon"},
    {"afip":"1857","location":"Campo Cafferata"},
    {"afip":"1859","location":"Campo Cardozo"},
    {"afip":"1877","location":"Campo De Carlos"},
    {"afip":"1914","location":"Campo Escalada"},
    {"afip":"1918","location":"Campo Fernandez"},
    {"afip":"1968","location":"Campo Maidana"},
    {"afip":"1972","location":"Campo Morato"},
    {"afip":"1985","location":"Campo Poy"},
    {"afip":"2001","location":"Campo Romero"},
    {"afip":"2007","location":"Campo San Jacinto"},
    {"afip":"2100","location":"Capi Vari"},
    {"afip":"2102","location":"Capiguari"},
    {"afip":"2105","location":"Capilla Cue"},
    {"afip":"2126","location":"Capillita"},
    {"afip":"2129","location":"Capirari"},
    {"afip":"2130","location":"Capita Mini"},
    {"afip":"2135","location":"Capitan Joaquin Madariaga"},
    {"afip":"2148","location":"Carabajal"},
    {"afip":"2149","location":"Carabajal Este"},
    {"afip":"2151","location":"Carabi Poy"},
    {"afip":"2159","location":"Carambola"},
    {"afip":"2162","location":"Carandaita"},
    {"afip":"2166","location":"Caraya"},
    {"afip":"2176","location":"Cardozo Phi"},
    {"afip":"2198","location":"Carman"},
    {"afip":"2217","location":"Carreta Paso"},
    {"afip":"2233","location":"Carrizal Norte"},
    {"afip":"2243","location":"Caruso Apeaero Fcgu"},
    {"afip":"2296","location":"Casillas"},
    {"afip":"2319","location":"Casualidad"},
    {"afip":"2320","location":"Casuarina"},
    {"afip":"2321","location":"Casuarinas"},
    {"afip":"2323","location":"Catalan Cue"},
    {"afip":"2339","location":"Cau Garay"},
    {"afip":"2345","location":"Cavi Poy"},
    {"afip":"2346","location":"Cavia Cue"},
    {"afip":"2354","location":"Caza Pava"},
    {"afip":"2356","location":"Cazadores Correntinos"},
    {"afip":"2368","location":"Cañada Burgos"}
    ,
    {"afip":"2423","location":"Cañada Mala"}
    ,
    {"afip":"2428","location":"Cañada Quiroz"}
    ,
    {"afip":"2442","location":"Cañaditas"}
    ,
    {"afip":"2481","location":"Cebollas"},
    {"afip":"2497","location":"Centinela"},
    {"afip":"2754","location":"Cerrudo Cue"},
    {"afip":"2794","location":"Chacras 1A Seccion"},
    {"afip":"2795","location":"Chacras 2A Seccion"},
    {"afip":"2796","location":"Chacras 3A Seccion"},
    {"afip":"2797","location":"Chacras 4A Seccion"},
    {"afip":"2805","location":"Chacras Norte"},
    {"afip":"2806","location":"Chacras Seccion Ejido"},
    {"afip":"2807","location":"Chacras Sud"},
    {"afip":"2849","location":"Chaquito"},
    {"afip":"2946","location":"Chilecito"},
    {"afip":"2973","location":"Chircal"},
    {"afip":"3090","location":"Cnia Oficial Juan Bautista"},
    {"afip":"3153","location":"Colonia"},
    {"afip":"3162","location":"Colonia 3 De Abril"},
    {"afip":"3175","location":"Colonia Acuña"}
    ,
    {"afip":"3198","location":"Colonia Alvarez"},
    {"afip":"3201","location":"Colonia Amadei"},
    {"afip":"3214","location":"Colonia Arocena Ina"},
    {"afip":"3215","location":"Colonia Arrocera"},
    {"afip":"3236","location":"Colonia Barrientes"},
    {"afip":"3238","location":"Colonia Basualdo"},
    {"afip":"3252","location":"Colonia Beron De Astrada"},
    {"afip":"3266","location":"Colonia Branchi"},
    {"afip":"3269","location":"Colonia Brougnes"},
    {"afip":"3275","location":"Colonia Caiman"},
    {"afip":"3288","location":"Colonia Carlos Pellegrini"},
    {"afip":"3291","location":"Colonia Carolina"},
    {"afip":"3299","location":"Colonia Cecilio Echeverria"},
    {"afip":"3308","location":"Colonia Chircal"},
    {"afip":"3333","location":"Colonia Danuzzo"},
    {"afip":"3354","location":"Colonia Dora Elena"},
    {"afip":"3362","location":"Colonia Durazno"},
    {"afip":"3452","location":"Colonia Gaiman"},
    {"afip":"3453","location":"Colonia Garabi"},
    {"afip":"3457","location":"Colonia General Ferre"},
    {"afip":"3461","location":"Colonia General Uriburu"},
    {"afip":"3466","location":"Colonia Gobernador Ruiz"},
    {"afip":"3508","location":"Colonia Jacobo Finh"},
    {"afip":"3514","location":"Colonia Jose R Gomez"},
    {"afip":"3524","location":"Colonia Juan Pujol"},
    {"afip":"3543","location":"Colonia La Carmen"},
    {"afip":"3565","location":"Colonia La Habana"},
    {"afip":"3610","location":"Colonia La Union"},
    {"afip":"3637","location":"Colonia Libertad"},
    {"afip":"3638","location":"Colonia Liebig S"},
    {"afip":"3639","location":"Colonia Llano"},
    {"afip":"3665","location":"Colonia Lucero"},
    {"afip":"3669","location":"Colonia Lujan"},
    {"afip":"3672","location":"Colonia M Aberastury"},
    {"afip":"3675","location":"Colonia Madariaga"},
    {"afip":"3683","location":"Colonia Maria Esther"},
    {"afip":"3693","location":"Colonia Matilde"},
    {"afip":"3701","location":"Colonia Mendez Bar"},
    {"afip":"3703","location":"Colonia Mercedes Cossio"},
    {"afip":"3743","location":"Colonia Nueva Valencia"},
    {"afip":"3763","location":"Colonia Pairiri"},
    {"afip":"3770","location":"Colonia Pando"},
    {"afip":"3792","location":"Colonia Porvenir"},
    {"afip":"3798","location":"Colonia Pucheta"},
    {"afip":"3821","location":"Colonia Rolon Cossio"},
    {"afip":"3822","location":"Colonia Romero"},
    {"afip":"3840","location":"Colonia San Eugenio"},
    {"afip":"3849","location":"Colonia San Jose"},
    {"afip":"3855","location":"Colonia San Mateo"},
    {"afip":"3891","location":"Colonia Sauce"},
    {"afip":"3911","location":"Colonia Tacuaralito"},
    {"afip":"3916","location":"Colonia Tatacua"},
    {"afip":"3936","location":"Colonia Urdaniz"},
    {"afip":"3945","location":"Colonia Vedoya"},
    {"afip":"4067","location":"Corona"},
    {"afip":"4069","location":"Coronel Abraham Schweizer"},
    {"afip":"4085","location":"Coronel Desiderio Sosa"},
    {"afip":"4156","location":"Corrientes"},
    {"afip":"4157","location":"Corsa Cue"},
    {"afip":"4170","location":"Costa"},
    {"afip":"4173","location":"Costa Arroyo Garay"},
    {"afip":"4175","location":"Costa Batel"},
    {"afip":"4180","location":"Costa De Arroyo San Lorenzo"},
    {"afip":"4181","location":"Costa De Empedrado"},
    {"afip":"4184","location":"Costa Del Batel"},
    {"afip":"4199","location":"Costa Grande"},
    {"afip":"4201","location":"Costa Guaviravi"},
    {"afip":"4202","location":"Costa Guazu"},
    {"afip":"4209","location":"Costa Rio Parana"},
    {"afip":"4212","location":"Costa San Lorenzo"},
    {"afip":"4213","location":"Costa Santa Lucia"},
    {"afip":"4214","location":"Costa Toledo"},
    {"afip":"4216","location":"Costas"},
    {"afip":"4248","location":"Crucecitas"},
    {"afip":"4252","location":"Crucecitas Santa Lucia"},
    {"afip":"4263","location":"Cruz De Los Milagros"},
    {"afip":"4283","location":"Cuarta Seccion Ensenada Grande"},
    {"afip":"4303","location":"Cuay Chico"},
    {"afip":"4304","location":"Cuay Grande"},
    {"afip":"4353","location":"Curtiembre"},
    {"afip":"4358","location":"Curupayti"},
    {"afip":"4360","location":"Curuzu"},
    {"afip":"4362","location":"Curuzu Cuatia"},
    {"afip":"4363","location":"Curuzu Laurel"},
    {"afip":"4373","location":"Cuña Suegra"}
    ,
    {"afip":"4408","location":"Desmochado"},
    {"afip":"4492","location":"Doctor Felix Maria Gomez"},
    {"afip":"4527","location":"Don Maximo"},
    {"afip":"4548","location":"Dos Hermanas"},
    {"afip":"4553","location":"Dos Ombues"},
    {"afip":"4726","location":"El Buen Retiro"},
    {"afip":"4796","location":"El Ceibo"},
    {"afip":"4842","location":"El Chircal"},
    {"afip":"4878","location":"El Coquito"},
    {"afip":"5053","location":"El Loto"},
    {"afip":"5155","location":"El Pago"},
    {"afip":"5204","location":"El Pelon"},
    {"afip":"5226","location":"El Pilar"},
    {"afip":"5242","location":"El Plata"},
    {"afip":"5252","location":"El Pollo"},
    {"afip":"5256","location":"El Ponton"},
    {"afip":"5264","location":"El Porvenir Colonia Libertad"},
    {"afip":"5320","location":"El Remanso"},
    {"afip":"5353","location":"El Salvador"},
    {"afip":"5414","location":"El Tatare"},
    {"afip":"5419","location":"El Tigre"},
    {"afip":"5434","location":"El Toro Pi"},
    {"afip":"5441","location":"El Transito"},
    {"afip":"5475","location":"El Vasco"},
    {"afip":"5504","location":"El Yapu"},
    {"afip":"5508","location":"El Yuqueri"},
    {"afip":"5552","location":"Emilio R Coni"},
    {"afip":"5571","location":"Empedrado"},
    {"afip":"5572","location":"Empedrado Limpio"},
    {"afip":"5589","location":"Ensenada Grande"},
    {"afip":"5590","location":"Ensenadita"},
    {"afip":"5615","location":"Esfadal"},
    {"afip":"5629","location":"Espinillar"},
    {"afip":"5649","location":"Esquivel Cue"},
    {"afip":"5664","location":"Establecimiento La Merced"},
    {"afip":"5680","location":"Estacion Agronomica"},
    {"afip":"5711","location":"Estacion Libertad"},
    {"afip":"5725","location":"Estacion Saladas"},
    {"afip":"5744","location":"Estancia Aguaceros"},
    {"afip":"5752","location":"Estancia Buena Vista"},
    {"afip":"5754","location":"Estancia Cafferatta"},
    {"afip":"5759","location":"Estancia Casurina"},
    {"afip":"5761","location":"Estancia Cerro Verde"},
    {"afip":"5780","location":"Estancia Del Medio"},
    {"afip":"5785","location":"Estancia Durruti"},
    {"afip":"5804","location":"Estancia El Ombu"},
    {"afip":"5806","location":"Estancia El Porvenir"},
    {"afip":"5819","location":"Estancia Gonzalez Cruz"},
    {"afip":"5827","location":"Estancia Ita Caabo"},
    {"afip":"5832","location":"Estancia La Arboleda"},
    {"afip":"5836","location":"Estancia La Calera"},
    {"afip":"5837","location":"Estancia La Carmencha"},
    {"afip":"5838","location":"Estancia La Carolina"},
    {"afip":"5856","location":"Estancia La Loma"},
    {"afip":"5857","location":"Estancia La Loma Alta"},
    {"afip":"5859","location":"Estancia La Maria"},
    {"afip":"5882","location":"Estancia Laguna Limpia"},
    {"afip":"5892","location":"Estancia Las Magñolas"}
    ,
    {"afip":"5896","location":"Estancia Las Salinas"},
    {"afip":"5897","location":"Estancia Las Tunas"},
    {"afip":"5904","location":"Estancia Lomatora"},
    {"afip":"5912","location":"Estancia Los Milagros"},
    {"afip":"5915","location":"Estancia Los Paraisos"},
    {"afip":"5918","location":"Estancia Mandure"},
    {"afip":"5924","location":"Estancia Marquez Lui"},
    {"afip":"5925","location":"Estancia Mbota"},
    {"afip":"5934","location":"Estancia Pozo Cuadrado"},
    {"afip":"5937","location":"Estancia Rincon Grande"},
    {"afip":"5944","location":"Estancia Rosario"},
    {"afip":"5952","location":"Estancia San Javier"},
    {"afip":"5954","location":"Estancia San Juan"},
    {"afip":"5955","location":"Estancia San Julio"},
    {"afip":"5958","location":"Estancia San Mateo"},
    {"afip":"5959","location":"Estancia San Miguel"},
    {"afip":"5962","location":"Estancia San Roberto"},
    {"afip":"5964","location":"Estancia San Solano"},
    {"afip":"5967","location":"Estancia Santa Cruz"},
    {"afip":"5968","location":"Estancia Santa Maria"},
    {"afip":"5973","location":"Estancia Soledad"},
    {"afip":"5979","location":"Estancia Tunas"},
    {"afip":"5993","location":"Este Argentino"},
    {"afip":"6003","location":"Estero Grande"},
    {"afip":"6005","location":"Estero Piru"},
    {"afip":"6007","location":"Estero Sauce"},
    {"afip":"6008","location":"Estero Yatay"},
    {"afip":"6011","location":"Estingana"},
    {"afip":"6014","location":"Estrella"},
    {"afip":"6065","location":"Fanegas"},
    {"afip":"6090","location":"Felipe Yofre"},
    {"afip":"6093","location":"Fernandez"},
    {"afip":"6096","location":"Ferret"},
    {"afip":"6099","location":"Ferro"},
    {"afip":"6245","location":"Francisco Gauna"},
    {"afip":"6269","location":"Frontera"},
    {"afip":"6296","location":"Galarza Cue"},
    {"afip":"6315","location":"Garabata"},
    {"afip":"6322","location":"Garcitas"},
    {"afip":"6330","location":"Garrido"},
    {"afip":"6331","location":"Garrido Cue"},
    {"afip":"6333","location":"Garruchos"},
    {"afip":"6348","location":"Gdor Ing Valentin Viraso"},
    {"afip":"6349","location":"Gdor Juan Eusebio Torren"},
    {"afip":"6399","location":"General Paz"},
    {"afip":"6461","location":"Gobernador Juan E Martinez"},
    {"afip":"6475","location":"Gobernador Ruiz"},
    {"afip":"6494","location":"Gomez Cue"},
    {"afip":"6510","location":"Goya"},
    {"afip":"6536","location":"Granja Amelia"},
    {"afip":"6608","location":"Guaviravi"},
    {"afip":"6609","location":"Guay Grande"},
    {"afip":"6623","location":"Guayquiraro"},
    {"afip":"6624","location":"Guayu"},
    {"afip":"6625","location":"Guazu Cora"},
    {"afip":"6690","location":"Herlitzka"},
    {"afip":"6860","location":"Ibahay"},
    {"afip":"6864","location":"Ibaviyu"},
    {"afip":"6866","location":"Ibera"},
    {"afip":"6868","location":"Ibira Pita"},
    {"afip":"6869","location":"Ibiritangay"},
    {"afip":"6875","location":"Ifran"},
    {"afip":"6883","location":"Iguate Pora"},
    {"afip":"6906","location":"Infante"},
    {"afip":"6912","location":"Inga"},
    {"afip":"6964","location":"Ingenio Primer Correntino"},
    {"afip":"6987","location":"Ipacarapa"},
    {"afip":"7003","location":"Isabel Victoria"},
    {"afip":"7012","location":"Isla Alta"},
    {"afip":"7015","location":"Isla Apipe Chico"},
    {"afip":"7039","location":"Isla Grande"},
    {"afip":"7040","location":"Isla Ibatay"},
    {"afip":"7041","location":"Isla Ibate"},
    {"afip":"7056","location":"Isla San Mateo"},
    {"afip":"7059","location":"Isla Sola"},
    {"afip":"7061","location":"Isla Tacuara"},
    {"afip":"7078","location":"Ita Cora"},
    {"afip":"7079","location":"Ita Cua"},
    {"afip":"7080","location":"Ita Curubi"},
    {"afip":"7082","location":"Ita Ibate"},
    {"afip":"7083","location":"Ita Pucu"},
    {"afip":"7087","location":"Itati"},
    {"afip":"7088","location":"Itati Rincon"},
    {"afip":"7128","location":"Jardin Florido"},
    {"afip":"7213","location":"Juan Pujol"},
    {"afip":"7214","location":"Juan Ramon Vidal"},
    {"afip":"7265","location":"Justino Solari"},
    {"afip":"7278","location":"Km 425"},
    {"afip":"7292","location":"La Agripina"},
    {"afip":"7313","location":"La Amelia"},
    {"afip":"7318","location":"La Angela"},
    {"afip":"7369","location":"La Belermina"},
    {"afip":"7422","location":"La Carlina"},
    {"afip":"7439","location":"La Casualidad"},
    {"afip":"7452","location":"La Celeste"},
    {"afip":"7536","location":"La Criolla"},
    {"afip":"7556","location":"La Delicia"},
    {"afip":"7559","location":"La Diana"},
    {"afip":"7576","location":"La Elena"},
    {"afip":"7583","location":"La Eloisa"},
    {"afip":"7584","location":"La Elsa"},
    {"afip":"7585","location":"La Elva"},
    {"afip":"7586","location":"La Elvira"},
    {"afip":"7587","location":"La Emilia"},
    {"afip":"7640","location":"La Fe"},
    {"afip":"7650","location":"La Flor"},
    {"afip":"7651","location":"La Florencia"},
    {"afip":"7652","location":"La Florentina"},
    {"afip":"7653","location":"La Floresta"},
    {"afip":"7655","location":"La Fortuna"},
    {"afip":"7669","location":"La Garcia"},
    {"afip":"7699","location":"La Haydee"},
    {"afip":"7708","location":"La Hileorica"},
    {"afip":"7743","location":"La Jaula"},
    {"afip":"7769","location":"La Leonor"},
    {"afip":"7770","location":"La Leontina"},
    {"afip":"7785","location":"La Lolita"},
    {"afip":"7787","location":"La Loma Torrent"},
    {"afip":"7805","location":"La Magnolia"},
    {"afip":"7864","location":"La Morocha"},
    {"afip":"7874","location":"La Nena"},
    {"afip":"7900","location":"La Pachina"},
    {"afip":"7911","location":"La Palmera"},
    {"afip":"8013","location":"La Pupi"},
    {"afip":"8014","location":"La Pupii"},
    {"afip":"8212","location":"Labory"},
    {"afip":"8222","location":"Lago Arias"},
    {"afip":"8249","location":"Laguna Alfonso"},
    {"afip":"8251","location":"Laguna Avalos"},
    {"afip":"8296","location":"Laguna Paiva"},
    {"afip":"8299","location":"Laguna Pucu"},
    {"afip":"8306","location":"Laguna Sirena"},
    {"afip":"8307","location":"Laguna Soto"},
    {"afip":"8370","location":"Las Animas"},
    {"afip":"8475","location":"Las Elinas"},
    {"afip":"8493","location":"Las Garzas"},
    {"afip":"8529","location":"Las Lagunas"},
    {"afip":"8555","location":"Las Matreras"},
    {"afip":"8587","location":"Las Palmiras"},
    {"afip":"8639","location":"Las Ratas"},
    {"afip":"8659","location":"Las Taperas"},
    {"afip":"8681","location":"Las Tres Hermanas"},
    {"afip":"8707","location":"Las Violetas"},
    {"afip":"8723","location":"Laurel"},
    {"afip":"8729","location":"Lauretti"},
    {"afip":"8761","location":"Leon Cua"},
    {"afip":"8780","location":"Libertador"},
    {"afip":"8795","location":"Limas Cue"},
    {"afip":"8804","location":"Linda Vista"},
    {"afip":"8841","location":"Lobory"},
    {"afip":"8880","location":"Loma Poy"},
    {"afip":"8885","location":"Loma Villanueva"},
    {"afip":"8887","location":"Lomas"},
    {"afip":"8893","location":"Lomas De Aguirre"},
    {"afip":"8894","location":"Lomas De Empedrado"},
    {"afip":"8895","location":"Lomas De Galarza"},
    {"afip":"8897","location":"Lomas De Gonzalez"},
    {"afip":"8900","location":"Lomas De Vallejos"},
    {"afip":"8901","location":"Lomas De Vergara"},
    {"afip":"8906","location":"Lomas Esquivel"},
    {"afip":"8907","location":"Lomas Este"},
    {"afip":"8908","location":"Lomas Floridas"},
    {"afip":"8910","location":"Lomas Ramirez"},
    {"afip":"8911","location":"Lomas Redondas"},
    {"afip":"8912","location":"Lomas Saladas"},
    {"afip":"8913","location":"Lomas San Cayetano"},
    {"afip":"8914","location":"Lomas San Juan"},
    {"afip":"8915","location":"Lomas Vazquez"},
    {"afip":"8970","location":"Los Angeles Del Batel"},
    {"afip":"8973","location":"Los Arboles"},
    {"afip":"9006","location":"Los Bretes"},
    {"afip":"9033","location":"Los Ceibos"},
    {"afip":"9121","location":"Los Flotadores"},
    {"afip":"9131","location":"Los Gemelos"},
    {"afip":"9177","location":"Los Lirios"},
    {"afip":"9191","location":"Los Medios"},
    {"afip":"9370","location":"Los Tres Amigos"},
    {"afip":"9372","location":"Los Tres Cerros"},
    {"afip":"9374","location":"Los Tres Hermanos"},
    {"afip":"9389","location":"Los Vences"},
    {"afip":"9430","location":"Luis Gomez"},
    {"afip":"9437","location":"Lujambio"},
    {"afip":"9485","location":"Madariaga"},
    {"afip":"9531","location":"Malezal"},
    {"afip":"9552","location":"Maloya"},
    {"afip":"9553","location":"Maloyita"},
    {"afip":"9554","location":"Malvinas"},
    {"afip":"9556","location":"Malvinas Centro"},
    {"afip":"9557","location":"Malvinas Norte"},
    {"afip":"9558","location":"Malvinas Sur"},
    {"afip":"9576","location":"Manchita"},
    {"afip":"9581","location":"Mandinga"},
    {"afip":"9592","location":"Mansion De Invierno"},
    {"afip":"9598","location":"Manuel Derqui"},
    {"afip":"9600","location":"Manuel Florencio Mantilla"},
    {"afip":"9656","location":"Maria Del Carmen"},
    {"afip":"9665","location":"Maria Idalina"},
    {"afip":"9683","location":"Mariano I Losas Est Solar"},
    {"afip":"9696","location":"Martin"},
    {"afip":"9701","location":"Martin Garcia"},
    {"afip":"9705","location":"Martinez Cue"},
    {"afip":"9711","location":"Maruchas"},
    {"afip":"9712","location":"Maruchitas"},
    {"afip":"9725","location":"Matadero Santa Catalina"},
    {"afip":"9736","location":"Matilde"},
    {"afip":"9740","location":"Matrera"},
    {"afip":"9769","location":"Mbalguiapu"},
    {"afip":"9770","location":"Mbarigui"},
    {"afip":"9771","location":"Mboi Cua"},
    {"afip":"9773","location":"Mburucuya"},
    {"afip":"9802","location":"Mediodia"},
    {"afip":"9931","location":"Minuanes"},
    {"afip":"9933","location":"Mira Flores"},
    {"afip":"9936","location":"Mirador"},
    {"afip":"9946","location":"Mirunga"},
    {"afip":"9976","location":"Mocoreta"},
    {"afip":"9985","location":"Mojon"},
    {"afip":"10030","location":"Montaña"}
    ,
    {"afip":"10057","location":"Monte Florido"},
    {"afip":"10086","location":"Montevideo"},
    {"afip":"10094","location":"Mora"},
    {"afip":"10107","location":"Morica"},
    {"afip":"10121","location":"Mota"},
    {"afip":"10123","location":"Mota Piedritas"},
    {"afip":"10132","location":"Muchas Islas"},
    {"afip":"10181","location":"Naranjaty"},
    {"afip":"10182","location":"Naranjito"},
    {"afip":"10183","location":"Naranjito San Roque"},
    {"afip":"10233","location":"Nina"},
    {"afip":"10267","location":"Ntra Sra Del Rosario De Caa"},
    {"afip":"10288","location":"Nueva Granada"},
    {"afip":"10293","location":"Nueva Palmira"},
    {"afip":"10311","location":"Nuevo Paraiso"},
    {"afip":"10313","location":"Nuevo Porvenir"},
    {"afip":"10325","location":"Obraje Cue"},
    {"afip":"10326","location":"Obraje Del Vasco"},
    {"afip":"10336","location":"Ocanto Cue"},
    {"afip":"10375","location":"Ombu Lomas"},
    {"afip":"10377","location":"Ombu Solo"},
    {"afip":"10378","location":"Ombucito"},
    {"afip":"10391","location":"Oratorio"},
    {"afip":"10409","location":"Oscuro"},
    {"afip":"10457","location":"Pago Alegre"},
    {"afip":"10458","location":"Pago Arias"},
    {"afip":"10459","location":"Pago De Los Deseos"},
    {"afip":"10460","location":"Pago Largo"},
    {"afip":"10461","location":"Pago Poi"},
    {"afip":"10462","location":"Pago Redondo"},
    {"afip":"10465","location":"Paimbre"},
    {"afip":"10501","location":"Palma Sola"},
    {"afip":"10502","location":"Palmar"},
    {"afip":"10503","location":"Palmar Arerungua"},
    {"afip":"10506","location":"Palmar Grande"},
    {"afip":"10514","location":"Palmera"},
    {"afip":"10515","location":"Palmira"},
    {"afip":"10516","location":"Palmita"},
    {"afip":"10646","location":"Pampin"},
    {"afip":"10656","location":"Pancho Cue"},
    {"afip":"10672","location":"Parada Labougle"},
    {"afip":"10675","location":"Parada Pucheta"},
    {"afip":"10680","location":"Paraiso"},
    {"afip":"10681","location":"Paraje Augua"},
    {"afip":"10683","location":"Paraje Barranquitas"},
    {"afip":"10690","location":"Paraje El Carmen"},
    {"afip":"10696","location":"Paraje Florida"},
    {"afip":"10702","location":"Paraje Iribu Cua"},
    {"afip":"10723","location":"Paraje Portillo"},
    {"afip":"10724","location":"Paraje Poton"},
    {"afip":"10725","location":"Paraje San Isidro"},
    {"afip":"10762","location":"Pasaje Santa Juana"},
    {"afip":"10770","location":"Pasito"},
    {"afip":"10774","location":"Paso Aguirre"},
    {"afip":"10775","location":"Paso Algarrobo"},
    {"afip":"10777","location":"Paso Ancho"},
    {"afip":"10778","location":"Paso Bandera"},
    {"afip":"10780","location":"Paso Bermudez"},
    {"afip":"10784","location":"Paso Cejas"},
    {"afip":"10787","location":"Paso Chañaral"}
    ,
    {"afip":"10789","location":"Paso Concepcion"},
    {"afip":"10791","location":"Paso Coronel"},
    {"afip":"10802","location":"Paso De La Patria"},
    {"afip":"10808","location":"Paso De Las Piedras"},
    {"afip":"10825","location":"Paso De Mula"},
    {"afip":"10855","location":"Paso Esterito"},
    {"afip":"10856","location":"Paso Florentin"},
    {"afip":"10858","location":"Paso Gallego"},
    {"afip":"10864","location":"Paso Iribu Cua"},
    {"afip":"10870","location":"Paso Ledesma"},
    {"afip":"10872","location":"Paso Lopez"},
    {"afip":"10874","location":"Paso Los Angeles"},
    {"afip":"10876","location":"Paso Lovera"},
    {"afip":"10877","location":"Paso Lucero"},
    {"afip":"10881","location":"Paso Mesa"},
    {"afip":"10886","location":"Paso Naranjito"},
    {"afip":"10887","location":"Paso Pesoa"},
    {"afip":"10890","location":"Paso Potrero"},
    {"afip":"10892","location":"Paso Pucheta"},
    {"afip":"10897","location":"Paso Rosario"},
    {"afip":"10898","location":"Paso Rubio"},
    {"afip":"10899","location":"Paso Saldaña"}
    ,
    {"afip":"10902","location":"Paso San Juan"},
    {"afip":"10904","location":"Paso Santa Rosa"},
    {"afip":"10907","location":"Paso Tirante"},
    {"afip":"10908","location":"Paso Vallejos"},
    {"afip":"10921","location":"Pastores"},
    {"afip":"10947","location":"Pay Ubre Chico"},
    {"afip":"10963","location":"Pedro Diaz Colodrero"},
    {"afip":"10975","location":"Pedro R Fernandez"},
    {"afip":"10977","location":"Pehuaho"},
    {"afip":"11016","location":"Perugorria"},
    {"afip":"11097","location":"Piedra Ita Pucu"},
    {"afip":"11116","location":"Piedrita"},
    {"afip":"11134","location":"Pilincho"},
    {"afip":"11151","location":"Pindoncito"},
    {"afip":"11168","location":"Piracu"},
    {"afip":"11172","location":"Pirayu"},
    {"afip":"11179","location":"Pirra Puy"},
    {"afip":"11217","location":"Playadito"},
    {"afip":"11382","location":"Potrero Grande"},
    {"afip":"11543","location":"Progreso"},
    {"afip":"11603","location":"Pueblito Espinosa"},
    {"afip":"11605","location":"Pueblito San Juan"},
    {"afip":"11622","location":"Pueblo De Julio"},
    {"afip":"11667","location":"Puente Avalos"},
    {"afip":"11670","location":"Puente Batel"},
    {"afip":"11693","location":"Puente Machuca"},
    {"afip":"11734","location":"Puerta Ifran"},
    {"afip":"11752","location":"Puerto Araza"},
    {"afip":"11795","location":"Puerto Gonzalez"},
    {"afip":"11796","location":"Puerto Goya"},
    {"afip":"11799","location":"Puerto Hormiguero"},
    {"afip":"11806","location":"Puerto Juan De Dios"},
    {"afip":"11813","location":"Puerto Las Lajas"},
    {"afip":"11815","location":"Puerto Las Tacuaritas"},
    {"afip":"11830","location":"Puerto Naranjito"},
    {"afip":"11842","location":"Puerto Piedra"},
    {"afip":"11876","location":"Puerto Ubajay"},
    {"afip":"11880","location":"Puerto Valle"},
    {"afip":"11940","location":"Puesto De Isla"},
    {"afip":"12029","location":"Puesto Lata"},
    {"afip":"12096","location":"Puisoye"},
    {"afip":"12100","location":"Pujol Bedoya"},
    {"afip":"12145","location":"Punta Grande"},
    {"afip":"12147","location":"Punta Ifran"},
    {"afip":"12153","location":"Punta Mercedes"},
    {"afip":"12162","location":"Puntas De Francisco Gomez"},
    {"afip":"12166","location":"Puntas Del Tigre"},
    {"afip":"12251","location":"Quinta Seccion Ombucito"},
    {"afip":"12252","location":"Quinta Teresa"},
    {"afip":"12277","location":"Quiyati"},
    {"afip":"12298","location":"Raices"},
    {"afip":"12302","location":"Ralera Sud"},
    {"afip":"12306","location":"Ramada Paso"},
    {"afip":"12329","location":"Ramones"},
    {"afip":"12342","location":"Ranegas"},
    {"afip":"12367","location":"Real Cue"},
    {"afip":"12412","location":"Remanso"},
    {"afip":"12453","location":"Riachuelito"},
    {"afip":"12454","location":"Riachuelo"},
    {"afip":"12455","location":"Riachuelo Bardeci"},
    {"afip":"12456","location":"Riachuelo Sud"},
    {"afip":"12472","location":"Rincon Chico"},
    {"afip":"12474","location":"Rincon De Ambrosio"},
    {"afip":"12475","location":"Rincon De Animas"},
    {"afip":"12485","location":"Rincon De Gomez"},
    {"afip":"12492","location":"Rincon De Las Mercedes"},
    {"afip":"12499","location":"Rincon De Mercedes"},
    {"afip":"12504","location":"Rincon De Pago"},
    {"afip":"12507","location":"Rincon De San Lorenzo"},
    {"afip":"12508","location":"Rincon De Sarandy"},
    {"afip":"12509","location":"Rincon De Soto"},
    {"afip":"12511","location":"Rincon De Tunas"},
    {"afip":"12512","location":"Rincon De Vences"},
    {"afip":"12514","location":"Rincon De Yaguary"},
    {"afip":"12524","location":"Rincon Del Rosario"},
    {"afip":"12525","location":"Rincon Del Sombrero"},
    {"afip":"12526","location":"Rincon Del Tigre"},
    {"afip":"12534","location":"Rincon Itaembe"},
    {"afip":"12537","location":"Rincon Mercedes Estancia"},
    {"afip":"12540","location":"Rincon San Pedro"},
    {"afip":"12541","location":"Rincon Tranquera General"},
    {"afip":"12542","location":"Rincon Zalazar"},
    {"afip":"12681","location":"Rolon Jacinto"},
    {"afip":"12689","location":"Romero"},
    {"afip":"12690","location":"Romero Cuazu"},
    {"afip":"12696","location":"Rosadito"},
    {"afip":"12697","location":"Rosado Grande"},
    {"afip":"12728","location":"Ruiz Cue"},
    {"afip":"12795","location":"Saenz Valiente"},
    {"afip":"12808","location":"Saladas"},
    {"afip":"12812","location":"Saladero San Antonio"},
    {"afip":"12832","location":"Saldana"},
    {"afip":"12833","location":"Saldana 9 De Julio"},
    {"afip":"12839","location":"Salinas"},
    {"afip":"12845","location":"Salinas Gandes"},
    {"afip":"12864","location":"Salto Ita Jhase"},
    {"afip":"12877","location":"San Alejo"},
    {"afip":"12878","location":"San Alonso"},
    {"afip":"12902","location":"San Antonio Del Caiman"},
    {"afip":"12919","location":"San Borjita"},
    {"afip":"12930","location":"San Celestino"},
    {"afip":"12935","location":"San Cosme"},
    {"afip":"12940","location":"San Diego"},
    {"afip":"12941","location":"San Dionisio"},
    {"afip":"12950","location":"San Eugenio"},
    {"afip":"12962","location":"San Francisco Cue"},
    {"afip":"12971","location":"San Francisco Guavirari"},
    {"afip":"12982","location":"San Gregorio"},
    {"afip":"12983","location":"San Guillermo"},
    {"afip":"12984","location":"San Gustavo"},
    {"afip":"13008","location":"San Jose Caacati"},
    {"afip":"13041","location":"San Jose Est Libertad Dp"},
    {"afip":"13068","location":"San Luis Cue"},
    {"afip":"13070","location":"San Luis Del Palmar"},
    {"afip":"13071","location":"San Luis Est Libertad Dp"},
    {"afip":"13075","location":"San Marcos"},
    {"afip":"13099","location":"San Miguel Estacion Libertad"},
    {"afip":"13103","location":"San Nicanor"},
    {"afip":"13109","location":"San Paladio"},
    {"afip":"13146","location":"San Roquito"},
    {"afip":"13171","location":"Sangara"},
    {"afip":"13204","location":"Santa Elisa"},
    {"afip":"13206","location":"Santa Emilia"},
    {"afip":"13220","location":"Santa Juana"},
    {"afip":"13222","location":"Santa Lea"},
    {"afip":"13223","location":"Santa Librada"},
    {"afip":"13225","location":"Santa Lucia 9 De Julio"},
    {"afip":"13249","location":"Santa Rita Parada Pucheta"},
    {"afip":"13273","location":"Santa Sinforosa"},
    {"afip":"13276","location":"Santa Tecla"},
    {"afip":"13286","location":"Santiago Alcorta"},
    {"afip":"13294","location":"Santillan"},
    {"afip":"13306","location":"Santo Tome"},
    {"afip":"13364","location":"Saucesito"},
    {"afip":"13385","location":"Seccion Primera San Juan"},
    {"afip":"13415","location":"Seriano Cue"},
    {"afip":"13471","location":"Siete Arboles"},
    {"afip":"13476","location":"Silvero Cue"},
    {"afip":"13516","location":"Solari Mariano I Loza"},
    {"afip":"13535","location":"Sombrero"},
    {"afip":"13543","location":"Sosa"},
    {"afip":"13544","location":"Sosa Cue"},
    {"afip":"13547","location":"Soto"},
    {"afip":"13615","location":"Tabay"},
    {"afip":"13661","location":"Tacuabe"},
    {"afip":"13663","location":"Tacuaracarendy"},
    {"afip":"13664","location":"Tacuaral"},
    {"afip":"13666","location":"Tacuarembo"},
    {"afip":"13669","location":"Tacuaritas"},
    {"afip":"13672","location":"Tacural"},
    {"afip":"13673","location":"Tacural Mercedes"},
    {"afip":"13683","location":"Tajibo"},
    {"afip":"13689","location":"Tala Cora"},
    {"afip":"13697","location":"Tala Paso"},
    {"afip":"13716","location":"Talaty"},
    {"afip":"13721","location":"Talita Cue"},
    {"afip":"13725","location":"Talleres"},
    {"afip":"13750","location":"Tape Rati"},
    {"afip":"13751","location":"Tapebicua"},
    {"afip":"13766","location":"Tarangullo"},
    {"afip":"13767","location":"Tareiri"},
    {"afip":"13772","location":"Tartaguito"},
    {"afip":"13773","location":"Tartaria"},
    {"afip":"13783","location":"Tatacua"},
    {"afip":"13785","location":"Tatare"},
    {"afip":"13792","location":"Teblenari"},
    {"afip":"13847","location":"Tierra Colorada"},
    {"afip":"13860","location":"Tilita"},
    {"afip":"13865","location":"Timbo Cora"},
    {"afip":"13867","location":"Timbo Paso"},
    {"afip":"13870","location":"Timboy"},
    {"afip":"13879","location":"Tingui"},
    {"afip":"13902","location":"Tiquino"},
    {"afip":"13924","location":"Tolatu"},
    {"afip":"13948","location":"Topador"},
    {"afip":"13958","location":"Toro Chipay"},
    {"afip":"13961","location":"Toro I"},
    {"afip":"13967","location":"Toro Pichay"},
    {"afip":"13973","location":"Toros Cora"},
    {"afip":"13977","location":"Torrent"},
    {"afip":"14041","location":"Tres Bocas"},
    {"afip":"14044","location":"Tres Capones"},
    {"afip":"14046","location":"Tres Cerros"},
    {"afip":"14050","location":"Tres Cruces"},
    {"afip":"14056","location":"Tres Hermanas"},
    {"afip":"14059","location":"Tres Hojas"},
    {"afip":"14087","location":"Tres Taperas"},
    {"afip":"14101","location":"Tripoli"},
    {"afip":"14102","location":"Tristan Chico"},
    {"afip":"14156","location":"Tunitas"},
    {"afip":"14189","location":"Uguay"},
    {"afip":"14190","location":"Ulajay"},
    {"afip":"14206","location":"Uriburu"},
    {"afip":"14211","location":"Uruguay"},
    {"afip":"14230","location":"Vaca Cua"},
    {"afip":"14244","location":"Valencia"},
    {"afip":"14289","location":"Vecindad"},
    {"afip":"14292","location":"Vedoya"},
    {"afip":"14309","location":"Veloso"},
    {"afip":"14326","location":"Vergara Lomas"},
    {"afip":"14329","location":"Veron Cue"},
    {"afip":"14403","location":"Villa Aquino"},
    {"afip":"14477","location":"Villa Cordoba"},
    {"afip":"14482","location":"Villa Cristia"},
    {"afip":"14484","location":"Villa Cue"},
    {"afip":"14617","location":"Villa Juan De Vera"},
    {"afip":"14663","location":"Villa Lujan"},
    {"afip":"14722","location":"Villa Olivari"},
    {"afip":"14726","location":"Villa P Argentina"},
    {"afip":"14773","location":"Villa Rollet"},
    {"afip":"14774","location":"Villa Rolon"},
    {"afip":"14792","location":"Villa San Isidro"},
    {"afip":"14796","location":"Villa San Juan"},
    {"afip":"14802","location":"Villa San Ramon"},
    {"afip":"14833","location":"Villa Solari"},
    {"afip":"14835","location":"Villa Soto"},
    {"afip":"14844","location":"Villa Tesaro"},
    {"afip":"14876","location":"Villaga Cue"},
    {"afip":"14904","location":"Virgen Maria"},
    {"afip":"14932","location":"Vizcaino"},
    {"afip":"14951","location":"Vuelta Del Ombu"},
    {"afip":"14975","location":"Yacare"},
    {"afip":"14976","location":"Yacarey"},
    {"afip":"14996","location":"Yagua Rincon"},
    {"afip":"14997","location":"Yagua Rocau"},
    {"afip":"14999","location":"Yaguaru"},
    {"afip":"15000","location":"Yaguary"},
    {"afip":"15001","location":"Yahape"},
    {"afip":"15016","location":"Yapeyu"},
    {"afip":"15018","location":"Yapuca"},
    {"afip":"15027","location":"Yataiti Sata"},
    {"afip":"15028","location":"Yataity Calle"},
    {"afip":"15029","location":"Yataity Poi"},
    {"afip":"15033","location":"Yatay Cora"},
    {"afip":"15036","location":"Yazuca"},
    {"afip":"15058","location":"Yta Paso"},
    {"afip":"15070","location":"Yuqueri"},
    {"afip":"15072","location":"Yurucua"},
    {"afip":"15091","location":"Zapallar"},
    {"afip":"15093","location":"Zapallos"},
    {"afip":"15128","location":"Ñaembe"}
    ,
    {"afip":"15134","location":"Ñatiu"}
    ,
    {"afip":"15145","location":"Ñuruguay"}
    ,
    {"afip":"15159","location":"9 De Julio"},
    {"afip":"15222","location":"Algarrobal"},
    {"afip":"15229","location":"Algarrobo"},
    {"afip":"15242","location":"Altamira"},
    {"afip":"15266","location":"Angostura"},
    {"afip":"15276","location":"Arbol Solo"},
    {"afip":"15290","location":"Arroyito"},
    {"afip":"15300","location":"Arroyo Grande"},
    {"afip":"15304","location":"Arroyo Seco"},
    {"afip":"15313","location":"Bacacay"},
    {"afip":"15319","location":"Bajo Grande"},
    {"afip":"15353","location":"Barranqueras"},
    {"afip":"15380","location":"Bella Vista"},
    {"afip":"15412","location":"Buen Retiro"},
    {"afip":"15420","location":"Buena Vista"},
    {"afip":"15434","location":"Cabral"},
    {"afip":"15457","location":"Campo Grande"},
    {"afip":"15495","location":"Carrizal"},
    {"afip":"15528","location":"Cañada Grande"}
    ,
    {"afip":"15560","location":"Cerrito"},
    {"afip":"15608","location":"Chacras"},
    {"afip":"15615","location":"Chamorro"},
    {"afip":"15618","location":"Chavarria"},
    {"afip":"15678","location":"Colonia Florencia"},
    {"afip":"15699","location":"Colonia Progreso"},
    {"afip":"15706","location":"Colonia San Antonio"},
    {"afip":"15725","location":"Colonia San Martin"},
    {"afip":"15748","location":"Colonia Santa Rosa"},
    {"afip":"15761","location":"Concepcion"},
    {"afip":"15811","location":"Cuatro Bocas"},
    {"afip":"15820","location":"Desaguadero"},
    {"afip":"15838","location":"Dos Hermanos"},
    {"afip":"15944","location":"El Carmen"},
    {"afip":"15952","location":"El Carrizal"},
    {"afip":"15968","location":"El Centinela"},
    {"afip":"15974","location":"El Cerrito"},
    {"afip":"15980","location":"El Cerro"},
    {"afip":"16164","location":"El Palmar"},
    {"afip":"16176","location":"El Paraiso"},
    {"afip":"16184","location":"El Parque"},
    {"afip":"16213","location":"El Porvenir"},
    {"afip":"16240","location":"El Progreso"},
    {"afip":"16321","location":"El Rosario"},
    {"afip":"16370","location":"El Socorro"},
    {"afip":"16373","location":"El Sombrero"},
    {"afip":"16393","location":"El Tesoro"},
    {"afip":"16447","location":"El ñandubay"}
    ,
    {"afip":"16463","location":"Esperanza"},
    {"afip":"16467","location":"Espinillo"},
    {"afip":"16473","location":"Esquina"},
    {"afip":"16481","location":"Estancia El Carmen"},
    {"afip":"16483","location":"Estancia El Chañar"}
    ,
    {"afip":"16488","location":"Estancia San Antonio"},
    {"afip":"16520","location":"Florida"},
    {"afip":"16556","location":"Guaycuru"},
    {"afip":"16577","location":"Independencia"},
    {"afip":"16585","location":"Invernada"},
    {"afip":"16601","location":"Ituzaingo"},
    {"afip":"16607","location":"Jesus Maria"},
    {"afip":"16642","location":"La Amistad"},
    {"afip":"16644","location":"La Angelita"},
    {"afip":"16665","location":"La Armonia"},
    {"afip":"16674","location":"La Aurora"},
    {"afip":"16690","location":"La Blanca"},
    {"afip":"16695","location":"La Blanqueada"},
    {"afip":"16697","location":"La Bolsa"},
    {"afip":"16724","location":"La Carlota"},
    {"afip":"16732","location":"La Cautiva"},
    {"afip":"16739","location":"La Cañada"}
    ,
    {"afip":"16746","location":"La Celia"},
    {"afip":"16752","location":"La Celina"},
    {"afip":"16763","location":"La Chiquita"},
    {"afip":"16795","location":"La Colorada"},
    {"afip":"16798","location":"La Concepcion"},
    {"afip":"16801","location":"La Constancia"},
    {"afip":"16822","location":"La Cruz"},
    {"afip":"16905","location":"La Estrella"},
    {"afip":"16924","location":"La Flecha"},
    {"afip":"16934","location":"La Florida"},
    {"afip":"16973","location":"La Herminia"},
    {"afip":"17003","location":"La Isabel"},
    {"afip":"17052","location":"La Loma"},
    {"afip":"17062","location":"La Luisa"},
    {"afip":"17093","location":"La Matilde"},
    {"afip":"17130","location":"La Palma"},
    {"afip":"17133","location":"La Palmira"},
    {"afip":"17145","location":"La Parada"},
    {"afip":"17146","location":"La Pastoril"},
    {"afip":"17156","location":"La Pepita"},
    {"afip":"17182","location":"La Porteña"}
    ,
    {"afip":"17226","location":"La Querencia"},
    {"afip":"17334","location":"La Union"},
    {"afip":"17346","location":"La Verde"},
    {"afip":"17381","location":"Laguna Brava"},
    {"afip":"17407","location":"Lapacho"},
    {"afip":"17488","location":"Las Cuchillas"},
    {"afip":"17495","location":"Las Delicias"},
    {"afip":"17558","location":"Las Lomas"},
    {"afip":"17578","location":"Las Mercedes"},
    {"afip":"17601","location":"Las Palmas"},
    {"afip":"17609","location":"Las Palmitas"},
    {"afip":"17648","location":"Las Rosas"},
    {"afip":"17704","location":"Lavalle"},
    {"afip":"17711","location":"Libertad"},
    {"afip":"17717","location":"Loma Alta"},
    {"afip":"17733","location":"Loma Negra"},
    {"afip":"17753","location":"Loreto"},
    {"afip":"17766","location":"Los Algarrobos"},
    {"afip":"17775","location":"Los Angeles"},
    {"afip":"17846","location":"Los Eucaliptos"},
    {"afip":"17864","location":"Los Laureles"},
    {"afip":"17869","location":"Los Manantiales"},
    {"afip":"17911","location":"Los Paraisos"},
    {"afip":"17923","location":"Los Pinos"},
    {"afip":"17956","location":"Los Sauces"},
    {"afip":"17977","location":"Lujan"},
    {"afip":"17982","location":"Macedo"},
    {"afip":"17998","location":"Manantiales"},
    {"afip":"18010","location":"Maria"},
    {"afip":"18038","location":"Mercedes"},
    {"afip":"18072","location":"Monte Caseros"},
    {"afip":"18077","location":"Monte Grande"},
    {"afip":"18121","location":"Nueva Esperanza"},
    {"afip":"18131","location":"Ojo De Agua"},
    {"afip":"18145","location":"Ombu"},
    {"afip":"18165","location":"Palmitas"},
    {"afip":"18208","location":"Parque San Martin"},
    {"afip":"18219","location":"Paso De Los Libres"},
    {"afip":"18268","location":"Pindo"},
    {"afip":"18283","location":"Porvenir"},
    {"afip":"18354","location":"Pueblito"},
    {"afip":"18462","location":"Recreo"},
    {"afip":"18466","location":"Reduccion"},
    {"afip":"18478","location":"Rincon"},
    {"afip":"18527","location":"Rodeito"},
    {"afip":"18562","location":"San Agustin"},
    {"afip":"18577","location":"San Andres"},
    {"afip":"18587","location":"San Antonio"},
    {"afip":"18618","location":"San Carlos"},
    {"afip":"18628","location":"San Cayetano"},
    {"afip":"18639","location":"San Eduardo"},
    {"afip":"18643","location":"San Emilio"},
    {"afip":"18651","location":"San Felipe"},
    {"afip":"18657","location":"San Fermin"},
    {"afip":"18660","location":"San Fernando"},
    {"afip":"18667","location":"San Francisco"},
    {"afip":"18674","location":"San Gabriel"},
    {"afip":"18696","location":"San Ignacio"},
    {"afip":"18708","location":"San Isidro"},
    {"afip":"18717","location":"San Jacinto"},
    {"afip":"18719","location":"San Javier"},
    {"afip":"18729","location":"San Jeronimo"},
    {"afip":"18731","location":"San Joaquin"},
    {"afip":"18746","location":"San Jose"},
    {"afip":"18759","location":"San Juan"},
    {"afip":"18775","location":"San Julian"},
    {"afip":"18777","location":"San Justo"},
    {"afip":"18786","location":"San Lorenzo"},
    {"afip":"18801","location":"San Luis"},
    {"afip":"18807","location":"San Manuel"},
    {"afip":"18815","location":"San Martin"},
    {"afip":"18827","location":"San Miguel"},
    {"afip":"18840","location":"San Nicolas"},
    {"afip":"18855","location":"San Pedro"},
    {"afip":"18872","location":"San Rafael"},
    {"afip":"18890","location":"San Roque"},
    {"afip":"18900","location":"San Salvador"},
    {"afip":"18906","location":"San Sebastian"},
    {"afip":"18919","location":"San Vicente"},
    {"afip":"18930","location":"Santa Ana"},
    {"afip":"18949","location":"Santa Catalina"},
    {"afip":"18957","location":"Santa Cecilia"},
    {"afip":"18993","location":"Santa Irene"},
    {"afip":"18995","location":"Santa Isabel"},
    {"afip":"19007","location":"Santa Lucia"},
    {"afip":"19017","location":"Santa Magdalena"},
    {"afip":"19021","location":"Santa Maria"},
    {"afip":"19031","location":"Santa Marta"},
    {"afip":"19038","location":"Santa Rita"},
    {"afip":"19048","location":"Santa Rosa"},
    {"afip":"19061","location":"Santa Teresa"},
    {"afip":"19076","location":"Santo Domingo"},
    {"afip":"19090","location":"Santo Tomas"},
    {"afip":"19096","location":"Santos Lugares"},
    {"afip":"19100","location":"Sarandi"},
    {"afip":"19108","location":"Sauce"},
    {"afip":"19137","location":"Socorro"},
    {"afip":"19146","location":"Soledad"},
    {"afip":"19159","location":"Tablada"},
    {"afip":"19181","location":"Tambo Nuevo"},
    {"afip":"19191","location":"Timbo"},
    {"afip":"19217","location":"Tres Arboles"},
    {"afip":"19264","location":"Tuyuti"},
    {"afip":"19277","location":"Vergara"},
    {"afip":"19296","location":"Villa El Dorado"},
    {"afip":"19306","location":"Villa La Florida"},
    {"afip":"19322","location":"Villa Ortiz"},
    {"afip":"19357","location":"Yatay"},
    {"afip":"21028","location":"San Antonio"},
    {"afip":"21029","location":"San Francisco"},
    {"afip":"21030","location":"San Gabriel"},
    {"afip":"21031","location":"San Vicente"},
    {"afip":"21032","location":"Arroyo Seco"},
    {"afip":"21033","location":"Buena Vista"},
    {"afip":"21034","location":"La Concepcion"},
    {"afip":"21035","location":"La Estrella"},
    {"afip":"21036","location":"Loma Alta"},
    {"afip":"21037","location":"Los Eucaliptos"},
    {"afip":"21038","location":"San Jose"},
    {"afip":"21039","location":"San Luis"},
    {"afip":"21040","location":"San Martin"},
    {"afip":"21041","location":"Santa Rosa"},
    {"afip":"21042","location":"Santa Teresa"},
    {"afip":"21043","location":"Sauce"},
    {"afip":"21044","location":"Seriano Cue"},
    {"afip":"21047","location":"La Aurora"},
    {"afip":"21048","location":"Naranjito"},
    {"afip":"21049","location":"San Carlos"},
    {"afip":"21050","location":"San Nicolas"},
    {"afip":"21051","location":"Yuqueri"},
    {"afip":"21052","location":"Chircal"},
    {"afip":"21053","location":"El Ceibo"},
    {"afip":"21054","location":"La Flor"},
    {"afip":"21055","location":"Mira Flores"},
    {"afip":"21056","location":"Pilincho"},
    {"afip":"21057","location":"San Antonio"},
    {"afip":"21058","location":"San Fernando"},
    {"afip":"21059","location":"San Francisco"},
    {"afip":"21060","location":"San Gregorio"},
    {"afip":"21061","location":"San Salvador"},
    {"afip":"21062","location":"San Vicente"},
    {"afip":"21063","location":"Santa Rita"},
    {"afip":"21064","location":"Santo Domingo"},
    {"afip":"21065","location":"Tres Bocas"},
    {"afip":"21066","location":"Palmar"},
    {"afip":"21067","location":"Palmita"},
    {"afip":"21068","location":"Rincon De Yaguary"},
    {"afip":"21069","location":"San Antonio"},
    {"afip":"21070","location":"San Carlos"},
    {"afip":"21071","location":"San Francisco"},
    {"afip":"21072","location":"San Isidro"},
    {"afip":"21073","location":"San Joaquin"},
    {"afip":"21074","location":"San Juan"},
    {"afip":"21075","location":"San Pedro"},
    {"afip":"21076","location":"San Salvador"},
    {"afip":"21077","location":"Santa Isabel"},
    {"afip":"21078","location":"El Carmen"},
    {"afip":"21079","location":"Lomas"},
    {"afip":"21080","location":"Mira Flores"},
    {"afip":"21081","location":"San Francisco"},
    {"afip":"21082","location":"San Lorenzo"},
    {"afip":"21083","location":"San Nicolas"},
    {"afip":"21084","location":"Santo Domingo"},
    {"afip":"21085","location":"Soledad"},
    {"afip":"21086","location":"Buena Vista"},
    {"afip":"21087","location":"Costa"},
    {"afip":"21088","location":"San Jose"},
    {"afip":"21089","location":"Santa Ana"},
    {"afip":"21090","location":"Santa Rita"},
    {"afip":"21091","location":"Santo Domingo"},
    {"afip":"21092","location":"Soledad"},
    {"afip":"21093","location":"Cañada Grande"}
    ,
    {"afip":"21094","location":"Costa Grande"},
    {"afip":"21095","location":"Empedrado Limpio"},
    {"afip":"21096","location":"Las Palmitas"},
    {"afip":"21097","location":"Oratorio"},
    {"afip":"21098","location":"Santa Teresa"},
    {"afip":"21099","location":"Buena Vista"},
    {"afip":"21100","location":"La Cruz"},
    {"afip":"21101","location":"Yapeyu"},
    {"afip":"21102","location":"Casualidad"},
    {"afip":"21103","location":"Colonia"},
    {"afip":"21104","location":"Colonia San Antonio"},
    {"afip":"21105","location":"Ombu"},
    {"afip":"21106","location":"San Miguel"},
    {"afip":"21107","location":"San Nicolas"},
    {"afip":"21108","location":"Santa Isabel"},
    {"afip":"21109","location":"Tacuaral"},
    {"afip":"21110","location":"Yuqueri"},
    {"afip":"21111","location":"Alamo"},
    {"afip":"21112","location":"Algarrobal"},
    {"afip":"21113","location":"Balengo"},
    {"afip":"21114","location":"Capita Mini"},
    {"afip":"21115","location":"Cerrito"},
    {"afip":"21116","location":"Costa Santa Lucia"},
    {"afip":"21117","location":"El Socorro"},
    {"afip":"21118","location":"Isla Alta"},
    {"afip":"21119","location":"Manantiales"},
    {"afip":"21120","location":"Naranjito"},
    {"afip":"21121","location":"Nueva Esperanza"},
    {"afip":"21122","location":"San Antonio"},
    {"afip":"21123","location":"San Juan"},
    {"afip":"21124","location":"San Pedro"},
    {"afip":"21125","location":"San Rafael"},
    {"afip":"21126","location":"San Roque"},
    {"afip":"21127","location":"San Sebastian"},
    {"afip":"21128","location":"Santo Domingo"},
    {"afip":"21129","location":"Santo Tomas"},
    {"afip":"21130","location":"Tatacua"},
    {"afip":"21131","location":"Aguapey"},
    {"afip":"21132","location":"Boqueron"},
    {"afip":"21133","location":"Casualidad"},
    {"afip":"21134","location":"Cuay Chico"},
    {"afip":"21135","location":"El Carmen"},
    {"afip":"21136","location":"El Carmen"},
    {"afip":"21137","location":"El Porvenir"},
    {"afip":"21138","location":"Estancia El Carmen"},
    {"afip":"21139","location":"San Agustin"},
    {"afip":"21140","location":"San Fernando"},
    {"afip":"21141","location":"San Francisco"},
    {"afip":"21142","location":"San Juan"},
    {"afip":"21143","location":"Santa Rita"},
    {"afip":"21144","location":"Sarandi"},
    {"afip":"21145","location":"Concepcion"},
    {"afip":"21146","location":"Malezal"},
    {"afip":"21147","location":"Pirayu"},
    {"afip":"21148","location":"San Jose"},
    {"afip":"21149","location":"San Juan"},
    {"afip":"21150","location":"San Pedro"},
    {"afip":"21151","location":"Santa Ana"},
    {"afip":"21152","location":"Santa Isabel"},
    {"afip":"21153","location":"Santa Rita"},
    {"afip":"21154","location":"Aguay"},
    {"afip":"21155","location":"La Loma"},
    {"afip":"21156","location":"San Jose"},
    {"afip":"21157","location":"Santa Isabel"},
    {"afip":"21158","location":"Buena Vista"},
    {"afip":"21159","location":"Casualidad"},
    {"afip":"21160","location":"San Martin"},
    {"afip":"21161","location":"San Pedro"},
    {"afip":"21162","location":"Tres Bocas"},
    {"afip":"21163","location":"San Isidro"},
    {"afip":"21164","location":"San Jose"},
    {"afip":"21165","location":"Buena Vista"},
    {"afip":"21166","location":"Empedrado Limpio"},
    {"afip":"21167","location":"Florida"},
    {"afip":"21168","location":"Libertad"},
    {"afip":"21169","location":"Loma Alta"},
    {"afip":"21170","location":"Los Laureles"},
    {"afip":"21171","location":"Porvenir"},
    {"afip":"21172","location":"San Antonio"},
    {"afip":"21173","location":"San Carlos"},
    {"afip":"21174","location":"San Isidro"},
    {"afip":"21175","location":"San Jose"},
    {"afip":"21176","location":"San Juan"},
    {"afip":"21177","location":"San Pedro"},
    {"afip":"21178","location":"Santa Ana"},
    {"afip":"21179","location":"Santa Maria"},
    {"afip":"21180","location":"Santa Rosa"},
    {"afip":"21181","location":"Cerrito"},
    {"afip":"21182","location":"La Celia"},
    {"afip":"21183","location":"San Antonio"},
    {"afip":"21184","location":"San Luis"},
    {"afip":"21185","location":"Abra"},
    {"afip":"21186","location":"Arroyito"},
    {"afip":"21187","location":"Buena Vista"},
    {"afip":"21188","location":"Chacras"},
    {"afip":"21189","location":"Loma Alta"},
    {"afip":"21190","location":"Punta Grande"},
    {"afip":"21191","location":"San Antonio"},
    {"afip":"21192","location":"San Juan"},
    {"afip":"21193","location":"San Lorenzo"},
    {"afip":"21194","location":"Santa Ana"},
    {"afip":"21195","location":"Boqueron"},
    {"afip":"21247","location":"Palmar"},
    {"afip":"21248","location":"Rincon"},
    {"afip":"21249","location":"San Juan"},
    {"afip":"21250","location":"Santa Maria"},
    {"afip":"21251","location":"Santa Rosa"},
    {"afip":"21697","location":"Onagoyti"},
    {"afip":"21988","location":"Rio De Caa Cati"},
    {"afip":"21989","location":"Arroyo Ambrosio (Apead.Fcg)"},
    {"afip":"21990","location":"Sauce (Dpto. Sauce)"},
    {"afip":"21991","location":"Mariano I. Loza"},
    {"afip":"21992","location":"San Roque"},
    {"afip":"21993","location":"Gobernador Martinez"},
    {"afip":"21994","location":"Rroyo Gonzalez"},
    {"afip":"21995","location":"Pedro R. Fernandez"},
    {"afip":"22091","location":"Colonia Liebig'S"},
    {"afip":"22092","location":"Gob. Ing. V. Virasoro"}
    ]