import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { PriceValidator } from 'src/app/shared/price';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { Field } from '../fields.component';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class PriceComponent extends Field implements OnInit{
  constructor(store:Store<appState>,private validator: ValidatorChecker, private priceV:PriceValidator) { 
    super(store);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.price;
    } else if(this.fieldIdentificator==fieldType.EXCHANGE){
      this.tag=priceTags.price;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.price;
    }
  }

  override rememberValueInForm(){
    if((
      (this.fieldIdentificator==fieldType.PRICE || this.fieldIdentificator==fieldType.EXCHANGE) && 
      this.tag==priceTags.price) || this.subTag==priceTags.price ){
        
      try {
        let valueToSet: number = this.rememberValue();
        //If There is a value to remember
        if (this.elementToGenerate && valueToSet) {
          this.elementSelected = valueToSet;
          this.setIsOptionSelected(this.elementSelected > 0);

          setTimeout( ()=> {
            this.setIsInvalidPrice(valueToSet);
          }, 100);
        }
      } catch (err) {
        this.elementSelected = 0;
        this.isInvalid=false;
        this.setIsOptionSelected(false);
      }
    }
  }

  override setElement(event:any, index:number){

    let valueToSet= this.validator.validatorNumber(event);
    this.elementSelected = valueToSet
    this.changeShowOptions();
    let priceToSave=Number(valueToSet);
    this.setElementToGenerate(priceToSave);

    this.setTypeCoinWithPrice(priceToSave);
  }

  //If the generated price is between 1 and 999, the currency type will be USD. 
  //If it is greater than 999, the type of currency will be ARS
  setTypeCoinWithPrice(priceToSet:number){
    this.setFirstTag(priceTags.typeCoin);
    if (priceToSet>0 && priceToSet<1000) {
      this.setElementToGenerate(typeCoins.USD);
    } else if (priceToSet<=0){
      this.setElementToGenerate(typeCoins.NONE);
    } else  {
      this.setElementToGenerate(typeCoins.ARS);
    }
    this.setFirstTag(priceTags.price);
  }


  setIsInvalidPrice(valueToSet:number){
    const typeCoin:string=this.fieldIdentificator==fieldType.EXCHANGE? this.elementToGenerate.typeCoin : this.getPrice()?.typeCoin;    
    this.isInvalid= typeCoin && typeCoin!='--' ? this.priceV.isInvalidPriceAndTypeCoin(valueToSet,typeCoin):false;
  }

  override setFirstTag(nameOfTag: string): void {
    if (this.isGenerateCondition() || this.fieldIdentificator == fieldType.EXCHANGE) {
      this.tag = nameOfTag;
      this.subTag = '';
      this.thirdTag = '';
    } else {
      this.subTag = nameOfTag;
      this.thirdTag = '';
    }
  }
}
