<div class="header">
  <app-logo-grassi></app-logo-grassi>
  
  <div class="container-info">
    <span></span>
    <div class="column-center gap-40">
      <div class="text">
        <p class="title">Te damos la bienvenida</p>
        <p class="info">A la nueva plataforma de ordenes de venta</p>
      </div>
  
      <app-boton-google class="button-google"></app-boton-google>
    </div>
  </div>
</div>