import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, tap } from "rxjs";
import { localStorageNames } from "src/app/shared/const/localStorageNames";
import { ClaimService } from "src/app/shared/services/claim.service";
import { loadOrders, loadOrdersFailed } from "../actions/dashboard.action";
import { getClaim, getClaimFailed, getClaimSuccess } from "../actions/options.action";

@Injectable()
export class ClaimEffects {

    constructor(private actions$: Actions, private claimSvc: ClaimService) { }

    getClaimEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getClaim),
            mergeMap((action) => {
                return this.claimSvc.getClaim().pipe(
                    map(claim  => {
                        try {
                            const temp=Math.floor(claim.main.temp);
                            this.setLocalStorage(temp, localStorageNames.temp);
                            return getClaimSuccess({ temp: temp });
                        } catch (error:any) {
                            const errorMessage = error.message;
                            return getClaimFailed();
                        }
                    }),
                )
            }))
    })

    setLocalStorage(object:any, name:string){
        localStorage.setItem(name, JSON.stringify(object));
    }

}