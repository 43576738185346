<div class="field" #fieldName>
    <div class="row-between">
        <span class="field-title">Calidad<span class="asterisk">*</span></span>
    </div>
        <div>
            <div class="form-field" [ngClass]="{'border-green': isOptionSelected}">
            <span class="title-field  {{identifyClassName}}"> 

                <span class="autocomplete"> 
                    <span class="first-part">{{search}}</span> 
                    <span class="second-part">{{placeHolder}}</span>
                </span>

                <input #inputName (focus)="focusField(fieldName)"  (keydown)="listenerTab($event)"
                (blur)="unfocusField()"
                value="{{elementSelected}}" [(ngModel)]="search" placeholder="{{initalElementSelected}}" 
                class="{{identifyClassName}} no-background-border" />
                <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="up {{identifyClassName}}"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>

        <div class="options max-height-50vh">
            <div *ngFor="let element of array | includesString: OBJECT: search; index as i" class="option-quality" [ngClass]="{'selected': OBJECT[element]==elementSelected}"
                (click)="setElement(element, i)">
                {{OBJECT[element]}}
            </div>
        </div>
    </div>
</div>