import { Component, OnInit, OnDestroy,ChangeDetectionStrategy,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { getExchangesByDate, getExchangesByRangeDate, getOrdersByDate, getOrdersByRangeDate, getPricesByDate, setArrayOfHistoricalSuccess, setDateOfHistorical, setDateOfHistoricalRangeDate, setTypeOfHistorical } from 'src/app/redux/actions/historical.action';
import { showOrderDetails } from 'src/app/redux/actions/order.action';
import { showPriceDetails } from 'src/app/redux/actions/price.action';
import { buyerType, conditionType, typeOfHistorical } from 'src/app/shared/const/options';
import { DateChecker, getNextDate, invertDateWithout0 } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { getOrderListWithPipes, getSumOfTons } from 'src/app/shared/order';
import { DateRange, MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import { BodyBoard } from 'src/app/components/board/body-board';

@Component({
  selector: 'app-body-historical',
  templateUrl: './body-historical.component.html',
  styleUrls: ['./body-historical.component.scss','./../../../shared/styles/body-board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class BodyHistoricalComponent extends BodyBoard implements OnInit, OnDestroy {
  @ViewChild('pickerOne') pickerOne!: MatDatepicker<Date>;
  @ViewChild('pickerRange') pickerRange!: MatDateRangePicker<Date>;

  isShowOrderDetails:boolean=false;
  isShowPriceDetails:boolean=false;
  isShowOrderExchangeDetails:boolean=false;
  priceHistorical:string='';

  orderDetails!: Order; //Store de redux
  orderString!:OrderString;

  priceDetails!: Price; //Store de redux
  
  exchangeDetails!:OrderExchange; //Store de redux
  showAddExchange:boolean=false;

  TYPE_OF_HISTORICAL= typeOfHistorical
  dateToSearch: string = ''; //Store de redux
  startDateToSearch: string = ''; //Store de redux
  endDateToSearch: string = ''; //Store de redux
  
  boardSelected:string=this.TYPE_OF_HISTORICAL.ORDER;
  array:any[]=[]
  dateSelected=new FormControl(new Date());
  dateRange!:any;

  //---- Subscriptions ----\\
  private historicalArray$!:Subscription;
  private historicalIdPrice$!:Subscription;
  private showPriceDetails$!:Subscription;
  private priceDetails$!:Subscription;
  private showExchangeDetails$!:Subscription;
  private exchangeDetails$!:Subscription;
  private showAddExchange$!:Subscription;
  private typeOfBoard$!:Subscription;
  private oneDate$!:Subscription;
  private rangeDate$!:Subscription;


  constructor(store:Store<appState>, private dateC:DateChecker, cdr:ChangeDetectorRef) {
    super(store, cdr);
  }

  ngOnInit(): void {
    this.setSubscriptions();
    this.searchToday();
  }

  ngOnDestroy(): void {
    this.setUnsubscriptions();
    this.store.dispatch(setArrayOfHistoricalSuccess({array:[]}));
  }

  setSubscriptions(){
    this.historical$=this.store.select('order', 'historical').subscribe(historical => {
      this.historical=historical;
    })
    this.showDetails$=this.store.select('order', 'orderBoard', 'showOrderDetails').subscribe(isShowOrderDetails => {
      this.isShowOrderDetails=isShowOrderDetails;
    })
    this.details$=this.store.select('order', 'orderBoard', 'orderDetails').subscribe(orderDetails => {
      this.orderDetails=orderDetails;
    })
    this.filter$=this.store.select('order', 'orderBoard', 'filter').subscribe(orderString => {
      this.orderString=orderString;
    })

    this.historicalIdPrice$=this.store.select('price', 'historical').subscribe(historical => {
      this.priceHistorical=historical;
    })

    this.showPriceDetails$=this.store.select('price', 'priceBoard', 'showPriceDetails').subscribe(isShowPriceDetails => {
      this.isShowPriceDetails=isShowPriceDetails;
    })

    this.priceDetails$=this.store.select('price', 'priceBoard', 'priceDetails').subscribe(priceDetails => {
      this.priceDetails=priceDetails;
    })

    this.showExchangeDetails$=this.store.select('exchange', 'exchangeBoard', 'showOrderDetails').subscribe(isShowOrderDetails => {
      this.isShowOrderExchangeDetails=isShowOrderDetails;
    })
    this.exchangeDetails$=this.store.select('exchange', 'exchangeBoard', 'orderDetails').subscribe(orderDetails => {
      this.exchangeDetails=orderDetails;
    })

    this.showAddExchange$=this.store.select('exchange', 'exchangeBoard','showAddExchange').subscribe(showAddExchange=>{
      this.showAddExchange=showAddExchange;
    })

    this.oneDate$= this.store.select('historical','date').subscribe(oneDate => {
      this.dateToSearch=oneDate;
      this.dateSelected.setValue( getNextDate(new Date(this.dateToSearch)) );
    })

    this.rangeDate$= this.store.select('historical').subscribe(historical => {
      this.startDateToSearch=historical.startDate;
      this.endDateToSearch=historical.endDate;
      this.dateRange= new DateRange(this.startDateToSearch, this.endDateToSearch);
    })

    this.typeOfBoard$ = this.store.select('historical','typeOfHistorical').subscribe(typeOfHistorical => {
      this.boardSelected=typeOfHistorical;
    })

    this.historicalArray$=this.store.select('historicalArray').subscribe(historicalArray => {
      this.array=historicalArray?.array ?? [];
      this.cdr.markForCheck();
    })

    this.setBodySubscription();
  }

  setUnsubscriptions(){
    this.oneDate$?.unsubscribe();
    this.rangeDate$?.unsubscribe();
    this.historicalArray$?.unsubscribe();
    this.historicalIdPrice$?.unsubscribe();
    this.showPriceDetails$?.unsubscribe();
    this.priceDetails$?.unsubscribe();
    this.showExchangeDetails$?.unsubscribe();
    this.exchangeDetails$?.unsubscribe();
    this.showAddExchange$?.unsubscribe();
    this.typeOfBoard$?.unsubscribe();

    this.setBodyUnsubscription();
  }

  setSelectBoard(newBoard:string){
    this.store.dispatch(setArrayOfHistoricalSuccess({array:[]}));
    this.store.dispatch(setTypeOfHistorical({typeOfHistorical:newBoard}));
    const date= this.dateC.getDateInYYYYMMDDformat(this.dateC.stringToDate(this.dateC.getDate()));
    this.store.dispatch(setDateOfHistorical({date:date}));

    this.store.dispatch(setDateOfHistoricalRangeDate({date1: date, date2: date}));

    this.searchToday()
  }

  setDate(event:any){
    let date=this.dateC.getDateInYYYYMMDDformat(event.target.value);
    this.store.dispatch(setDateOfHistorical({date:date}));
    this.searchHistorical(date, this.boardSelected);
  }

  setStartDate(event:any){
    let date=this.dateC.getDateInYYYYMMDDformat(event.target.value);
    this.store.dispatch(setDateOfHistoricalRangeDate({date1:date, date2:''}));
  }

  setEndDate(event:any){
    let date=this.dateC.getDateInYYYYMMDDformat(event.target.value);
    this.store.dispatch(setDateOfHistoricalRangeDate({date1:this.startDateToSearch, date2:date}));
    
    this.searchHistoricalRangeDate(this.startDateToSearch, date, this.boardSelected);
  }

  /*Given a date and a selected history type,
  It checks that the history is the one selected and sends the redux store to search the corresponding function. */
  searchHistorical(date:string, typeOfHistorical:string){
    switch(typeOfHistorical){
      case this.TYPE_OF_HISTORICAL.ORDER: this.store.dispatch(getOrdersByDate({date: date})); break;
      case this.TYPE_OF_HISTORICAL.PRICE: this.store.dispatch(getPricesByDate({date: date})); break;
      case this.TYPE_OF_HISTORICAL.EXCHANGE: this.store.dispatch(getExchangesByDate({date: date})); break;
      default: ''; break;
    }
  }

  /*Given a date1,date2 and a selected history type,
  It checks that the history is the one selected and sends the redux store to search the corresponding function. */
  searchHistoricalRangeDate(date1:string, date2:string, typeOfHistorical:string){
    switch(typeOfHistorical){
      case this.TYPE_OF_HISTORICAL.ORDER: this.store.dispatch(getOrdersByRangeDate({date1: date1, date2: date2})); break;
      case this.TYPE_OF_HISTORICAL.PRICE: this.store.dispatch(getPricesByDate({date: date1})); break;
      case this.TYPE_OF_HISTORICAL.EXCHANGE: this.store.dispatch(getExchangesByRangeDate({date1: date1, date2: date2})); break;
      default: ''; break;
    }
  }

  getQuantOfTons(orders:Order[]){
    return getSumOfTons(orders);
  }

  getOrdersPipe(orders:Order[]){
    return getOrderListWithPipes(orders, this.user, this.orderString, this.search, buyerType.ALL, this.conditionTypeSelected, this.conditionFilters, true);
  }

  //A function that looks for today's prices, orders or trades that will be executed when the component is started
  searchToday(){
    const date= this.dateC.getDateInYYYYMMDDformat(this.dateC.stringToDate(this.dateC.getDate()));
    this.store.dispatch(setDateOfHistorical({date:date}));
    this.searchHistorical(date, this.boardSelected);
  }

  closeDetails(){
    this.store.dispatch(showOrderDetails({show:false}));
    this.store.dispatch(showPriceDetails({show:false}));
    this.store.dispatch(showOrderExchangeDetails({show:false}));
  }

  getStartDate(){
    const date=this.startDateToSearch? this.startDateToSearch: this.date;
    return invertDateWithout0(date);
  }

  getEndDate(){
    const date=this.endDateToSearch? this.endDateToSearch: this.date;
    return invertDateWithout0(date);
  }

  isOneDate(){
    return this.TYPE_OF_HISTORICAL.PRICE==this.boardSelected;
  }

  override getDate(){
    return this.dateToSearch? invertDateWithout0(this.dateToSearch): super.getDate();
  }
}