import { Component, OnChanges, OnInit } from '@angular/core';
import { EVENTS_TYPES_ARRAY, EVENTS_TYPES_OBJECT, FREIGHT_PAID_VOID, RATE_TO_DEFINE, RATES_TYPES_OPTIONS } from 'src/app/shared/const/business-particularities';
import { FreightPaid } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { BusinessParticularityOption } from '../options.component';
import { getIsFreightPaidByTheBuyer } from 'src/app/shared/business-particularities';

@Component({
  selector: 'app-flete-cargo-comprador',
  templateUrl: './flete-cargo-comprador.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class FleteCargoCompradorComponent extends BusinessParticularityOption implements OnChanges {
  freightPaidByTheBuyer:FreightPaid = FREIGHT_PAID_VOID;
  isCustomerFreight: boolean = false;

  constructor(private validator:ValidatorChecker) {
    super();
  }

  ngOnChanges(): void {
    try{
      this.freightPaidByTheBuyer=this.businessParticularitiesStruct.freightPaidByTheBuyer;
      this.isCustomerFreight=this.businessParticularitiesStruct.freightPaidByTheBuyer.isCustomerFreight == 1;
    } catch(err){
      this.freightPaidByTheBuyer=FREIGHT_PAID_VOID;
      this.isCustomerFreight=false;
    }
  }

  setFreightPaidByTheBuyer(freightPaidByTheBuyer:FreightPaid){
    this.freightPaidByTheBuyer=freightPaidByTheBuyer;
    this.freightPaidByTheBuyer.isFreightPaid= this.boolToNumber(getIsFreightPaidByTheBuyer(this.freightPaidByTheBuyer));
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.freightPaidByTheBuyer=freightPaidByTheBuyer;
    this.emit2(newBP);
  }

  getFreightPaidByTheBuyer() {
    let freightPaidByTheBuyer = JSON.parse(JSON.stringify(this.businessParticularitiesStruct.freightPaidByTheBuyer));
    return freightPaidByTheBuyer;
  }

  //----------------- RATES TYPE ------------------\\
  getRatesOptions(): any[]{
    return RATES_TYPES_OPTIONS;
  }

  setRatesType(value:string){
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer));
    freightPaidByTheBuyer.amount.typeCoin= value;
    freightPaidByTheBuyer.amount.quantity= value==RATE_TO_DEFINE? -1: 0;
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }
  
  getRatesType(){
    return this.businessParticularitiesStruct.freightPaidByTheBuyer.amount.typeCoin == RATE_TO_DEFINE? RATE_TO_DEFINE: '--';
  }

  isRateToDefine(){
    return this.getRatesType() == RATE_TO_DEFINE;
  }

  //------------------ EVENT TYPE ------------------\\
  setDefaultEventType(){
    const previousEventType = this.businessParticularitiesStruct.freightPaidByTheBuyer.eventType;
    if(!previousEventType || previousEventType == ''){
      this.setEventType({element: 'DESCARGA', index: 0});
    }
  }


  getEventType(){
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.businessParticularitiesStruct.freightPaidByTheBuyer));
    return EVENTS_TYPES_OBJECT[freightPaidByTheBuyer?.eventType] || '';
  }

  getEventsTypesArray(){
    return EVENTS_TYPES_ARRAY
  }

  getEventsTypesObject(){
    return EVENTS_TYPES_OBJECT
  }

  setEventType(value:{element: string, index: number}){
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer));
    freightPaidByTheBuyer.eventType=value.element;
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }

  //------------------------------------------\\

  getIsFreightPaidByTheBuyer(){
    return getIsFreightPaidByTheBuyer(this.businessParticularitiesStruct?.freightPaidByTheBuyer);
  }
  
  setCustomerFreight(){
    let newBool:boolean=JSON.parse(JSON.stringify(!this.isCustomerFreight));
    this.isCustomerFreight = newBool;
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.isCustomerFreight=this.boolToNumber(newBool);
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }
  
  /* AMOUNT */
  setAmount(event:any){
    let freightPaidByTheBuyer = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.amount=JSON.parse(event);
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }

  /* NUM OF DAYS */
  setNumOfDays(event:any){
    let valueToSet= this.validator.validatorNumber(event);
    let freightPaidByTheBuyer:FreightPaid = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.numberOfDays=valueToSet;
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }

  setIsFleteACargoDelComprador(){
    let freightPaidByTheBuyer = JSON.parse(JSON.stringify(this.freightPaidByTheBuyer))
    freightPaidByTheBuyer.isFreightPaid= this.boolToNumber(freightPaidByTheBuyer.isFreightPaid!=1);
    this.setFreightPaidByTheBuyer(freightPaidByTheBuyer);
  }
}
