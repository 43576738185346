import { Component, Input, OnInit, HostListener,ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  setFilterOrder,
  setOrdersSelected,
} from 'src/app/redux/actions/order.action';
import { priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import { orderStates } from 'src/app/shared/dict/orders';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { orderStatusConst, orderTags } from 'src/app/shared/const/orders';
import { setFilterIndex } from 'src/app/redux/actions/order.action';
import { Filter } from '../filter';
import { OrderValidator } from 'src/app/shared/order';
import { setChangesVisibles } from 'src/app/redux/actions/options.action';
import { Subscription } from 'rxjs';
import { DateChecker } from 'src/app/shared/date.checker';
import { setFilterIndexControlPanel, setFilterOrderControlPanel, setOrdersSelectedInControlPanel } from 'src/app/redux/actions/control-panel.action';

@Component({
  selector: 'filter-order',
  templateUrl: './filters.component.html',
  styleUrls: ['./../filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterOrderComponent extends Filter implements OnInit {
  @Input() isPropertyOfPrice: boolean = false;

  //Is an Order. Where your fields are Strings. It is used to filter an order list.
  orderFilter!: any; //Store of redux.

  ordersSelected!: Order[]; //Store of redux.
  orders!:Order[];

  private orderSub$!:Subscription;
  private controlPanelSub$!:Subscription;
  constructor(store: Store<appState>, private orderV:OrderValidator, date:DateChecker) {
    super(store,date);
    this.isFilter=true;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.setSubscriptions();
    this.isFilter= this.onlyFilter || (this.isComercial || this.identificatorString!=orderTags.statusOrder);

    //Si el identificador es CodeS o identificatorAux=='buyer', creamos un arreglo de arreglo,
    //donde el primer elemento sea el código y el segundo los datos del vendedor
    //esto sirve para mostrar el código y el nombre del vendedor o comprador en el filtro.
    if(this.identificatorString == 'codeS' || this.identificatorStringAux=='buyer'){
      this.array = Object.entries(this.arrayAux);
    } else if(this.identificatorString==priceTags.deliveryPeriod){
      this.deleteDateRangeSelected();
    } else {
      //this.codes= Object.keys(this.arrayAux);
      //this.array = Object.values(this.arrayAux);
      this.array = this.toArray(this.arrayAux);
    }
    
    if (this.identificatorString == 'price' && this.isFilter)
      this.valueToSet = { typeCoin: typeCoins.ALL, start: 0, end: 0 };

    this.wasInside=true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orderSub$?.unsubscribe();
    this.controlPanelSub$?.unsubscribe();
  }

  setSubscriptions(){
    if(this.isControlPanel){
      this.controlPanelSub$=this.store.select('controlPanel').subscribe((controlPanel) => {
        this.orderFilter =controlPanel.controlPanelBoard.filter;
        this.ordersSelected = controlPanel.controlPanelBoard.ordersSelected;
        this.filterIndexSelected=controlPanel.controlPanelBoard.indexFilter;
        this.views=controlPanel.viewsOfControlPanelBoard.views;
        this.viewSelected=controlPanel.viewsOfControlPanelBoard.viewSelected;

        if(this.filterIndexSelected == this.filterIdentificator) this.wasInside=true;
      });
    } else {
      this.orderSub$=this.store.select('order').subscribe((order) => {
        this.orderFilter =order.orderBoard.filter;
        this.ordersSelected = order.orderBoard.ordersSelected;
        this.filterIndexSelected=order.orderBoard.indexFilter;
        this.views=order.viewsOfOrderBoard.views;
        this.viewSelected=order.viewsOfOrderBoard.viewSelected;
        
        if(this.filterIndexSelected == this.filterIdentificator) this.wasInside=true;
      });
    }
  }

  addOrDeleteElement(element: any) {
    if (this.isComercial || this.isFilter) {
      this.addOrDeleteItemComercial(element);
    } else if (this.isOperator && !this.isFilter) {
      this.addOrDeleteElementCommodities(element);
    }
  }

  addOrDeleteElementCommodities(element: any) {
    this.selects = [element];
  }

  addOrDeleteItemComercial(element: any) {
    const isNestedField=this.iSANestedField();
    let positionOfItem = this.selects.indexOf( isNestedField? element.name: element);
    let elementAux = isNestedField? element.name: element;

    //If the element is NOT in the array, we add it.Otherwise, we remove it.
    if (positionOfItem == -1) {
      this.selects.push(elementAux);
    } else {
      positionOfItem == 0? this.selects.shift(): this.selects.splice(positionOfItem, 1);
    }
  }

  applyChanges() {
    if (this.isComercial || this.isFilter) {
      this.setOrderFilter();
    } else if (this.isOperator && !this.isFilter) {
      this.setNewOrder();
    }
    
    this.setFilterIndex(-1);
  }

  //Function in case the user is commodities. (I would change the orders)
  setNewOrder() {
    //It only enters when the status of the order is modified.
    let newStatus = this.getValueToSetInTheOrder();
    if (newStatus == orderStatusConst.RETURNED) {
      this.orderV.openModal(orderStatusConst.RETURNED);
    } else if (newStatus == orderStatusConst.CONFIRM) {
      this.orderV.openModal(orderStatusConst.CONFIRM);
    } else {
      for (let i = 0; i < this.ordersSelected.length; i++) {
        const value = this.getValueToSetInTheOrder();
        
        let orderToSave = JSON.parse(JSON.stringify(this.ordersSelected[i]));

        if (this.identificatorString && this.identificatorStringAux) {
          orderToSave[this.identificatorString][this.identificatorStringAux] = value;
        } else {
          if(this.identificatorString==orderTags.statusOrder){
            this.orderV.saveOrderStatus(orderToSave, value, '', this.user)
          }
          orderToSave[this.identificatorString] = value;
        }
        
        this.store.dispatch(setChangesVisibles({isChangeVisible:true}));
      }
      this.resetSelectedOrderList();
    }

    this.setFilterIndex(-1)
  }

  resetSelectedOrderList() {
    this.store.dispatch( this.isControlPanel? setOrdersSelectedInControlPanel({orders:[]}):setOrdersSelected({ orders: [] }));
  }

  //---------------EDIT PRICE------------------------\\

  getValueToSetInTheOrder() {
    //At the moment, the only field that can be modified is the STATUS.
    //Therefore, the method is adapted only for that case.
    return Number(
      this.getIndexOfDict(this.identificatorString, this.selects[0])
    );
  }

  getIndexOfDict(dictString: string, value: string) {
    let dict = {};
    switch (dictString) {
      case orderTags.statusOrder:
        dict = orderStates;
        break;
    }
    const indexOfItem = Object.values(dict).indexOf(value);

    if (indexOfItem >= 0) {
      return Object.keys(dict)[indexOfItem];
    }
    return null;
  }

  //Function in case the user is commercial. (filter would be applied)
  setOrderFilter() {
    let newOrder: any = JSON.parse(JSON.stringify(this.orderFilter));
    //When is a struct nested, his form of interaction is different.
    if (this.isPropertyOfPrice) {
      if (this.iSANestedField()) {
        newOrder[orderTags.price][this.identificatorString] = {
          ...this.orderFilter[orderTags.price][this.identificatorString],
        };
        newOrder[orderTags.price][this.identificatorString][this.identificatorStringAux] = [...this.selects];

      } else if (this.identificatorStringAux == priceTags.wayPayExpiration) {
        //Field with type date.      
        newOrder[orderTags.price][this.identificatorStringAux]= this.valueToSet? [this.valueToSet]: [];
      } else if (this.identificatorStringAux == priceTags.buyer){
        newOrder[orderTags.price][this.identificatorStringAux]= this.selects? [...this.selects]: [];
      } else if (this.identificatorString==priceTags.wayPay) {
        newOrder[orderTags.price][this.identificatorStringAux] = [...this.selects]
      } else {
        //If the identifier is observations or price, we used other form to interactue with struct
        switch (this.identificatorString) {
          case priceTags.price:
          case priceTags.hour:
          case priceTags.deliveryPeriod:
            newOrder[orderTags.price][this.identificatorString] = this.valueToSet;
            break;

          default:
            newOrder[orderTags.price][this.identificatorString] = [...this.selects];
            break;
        }
      }
    } else {
      //Es una propiedad de orden
      switch (this.identificatorString) {
        case orderTags.tons:
        case orderTags.hour:
          newOrder[this.identificatorString] = this.valueToSet;
          break;

        default:
          newOrder[this.identificatorString] = [...this.selects];
          break;
      }
    }

    this.setFilterOrder(newOrder);
    this.editView(this.getLocalStorageName(), newOrder);
  }

  iSANestedField() {
    return this.identificatorString == priceTags.placeOfDelivery;
  }

  setPrice(event: any, identificator: string) {
    if (this.isComercial || this.isFilter) {
      this.setPriceValueComercial(event, identificator);
    } else if (this.isOperator) {
      this.setPriceValueCommodities(event, identificator);
    }
  }
  setTons(event: any, identificator: string) {
    this.valueToSet = {
      ...this.valueToSet,
      [identificator]: event.target.value,
    };
  }

  setPriceValueComercial(event: any, identificator: string) {
    const propertyMap: Record<string, string> = {
      typeCoin: 'typeCoin',
      start: 'start',
      end: 'end',
    };
  
    this.valueToSet = {
      ...this.valueToSet,
      [propertyMap[identificator]]: identificator === 'typeCoin' ? event.value : event.target.value,
    };
  }

  setPriceValueCommodities(event: any, identificator: string) {
    if (identificator == 'typeCoin') {
      this.valueToSet = {
        ...this.valueToSet,
        typeCoin: event.value,
      };
    } else if (identificator == 'priceCommodities') {
      this.valueToSet = event.target.value;
    }
  }

  setValue(event: any) {
    this.valueToSet = event.target.value;
  }

  //Detect Clicks
  @HostListener('click') clickInside() {
    //this.setFilterIndex(this.filterIndexSelected);
    this.wasInside = true;
  }
  
  @HostListener('document:click') clickout() {
    if(this.filterIdentificator==this.filterIndexSelected) this.count++;

    if (!this.wasInside) {
      if(this.filterIndexSelected == this.filterIdentificator && this.count>1){
        this.setFilterIndex(-1);
      }
      this.count=0;
    }
    this.wasInside = false;
  }

  setFilterIndex(index:number){
    this.store.dispatch(this.isControlPanel? setFilterIndexControlPanel({index:index}):setFilterIndex({index:index}));
  }

  setFilterOrder(filterOrder:OrderString){
    this.store.dispatch(
      this.isControlPanel? setFilterOrderControlPanel({orderString: filterOrder }):setFilterOrder({ orderString: filterOrder }));
  }

  getLocalStorageName(){
    return this.isControlPanel? localStorageNames.viewsControlPanel: localStorageNames.viewOrders
  }
}