export const tierraDelFuegoLocalities=[
    {"afip":"899","location":"Aserradero Arroyo"},
    {"afip":"985","location":"Bahia Lapataia"},
    {"afip":"1345","location":"Base Aerea Teniente Matienzo"},
    {"afip":"1346","location":"Base Aerea Vicecomod Marambio"},
    {"afip":"1350","location":"Base Ejercito Esperanza"},
    {"afip":"1351","location":"Base Ejercito Gral Belgrano"},
    {"afip":"1352","location":"Base Ejercito Gral Belgrano 3"},
    {"afip":"1353","location":"Base Ejercito Gral San Martin"},
    {"afip":"1354","location":"Base Ejercito Primavera"},
    {"afip":"1355","location":"Base Ejercito Sobral"},
    {"afip":"1638","location":"Cabaña Ruby"}
    ,
    {"afip":"1659","location":"Cabo San Pablo"},
    {"afip":"1813","location":"Campamento Central Y P F"},
    {"afip":"1815","location":"Campamento Los Chorrillos Y P"},
    {"afip":"3997","location":"Comisaria Radman"},
    {"afip":"4416","location":"Destacamento Melchior"},
    {"afip":"5181","location":"El Paramo"},
    {"afip":"5679","location":"Estacion Aeronaval"},
    {"afip":"5694","location":"Estacion Cientifica Alte Brown"},
    {"afip":"5718","location":"Estacion Osn"},
    {"afip":"5747","location":"Estancia Aurelia"},
    {"afip":"5753","location":"Estancia Buenos Aires"},
    {"afip":"5755","location":"Estancia Carmen"},
    {"afip":"5760","location":"Estancia Cauchico"},
    {"afip":"5775","location":"Estancia Costancia"},
    {"afip":"5777","location":"Estancia Cullen"},
    {"afip":"5781","location":"Estancia Despedida"},
    {"afip":"5783","location":"Estancia Dos Hemanas"},
    {"afip":"5808","location":"Estancia El Roble"},
    {"afip":"5809","location":"Estancia El Rodeo"},
    {"afip":"5812","location":"Estancia El Salvador"},
    {"afip":"5822","location":"Estancia Guazu Cue"},
    {"afip":"5824","location":"Estancia Harberton"},
    {"afip":"5825","location":"Estancia Herminita"},
    {"afip":"5826","location":"Estancia Ines"},
    {"afip":"5830","location":"Estancia Jose Menendez"},
    {"afip":"5844","location":"Estancia La Criolla"},
    {"afip":"5849","location":"Estancia La Fueguina"},
    {"afip":"5853","location":"Estancia La Indiana"},
    {"afip":"5865","location":"Estancia La Porteña"}
    ,
    {"afip":"5888","location":"Estancia Las Hijas"},
    {"afip":"5898","location":"Estancia Las Violetas"},
    {"afip":"5900","location":"Estancia Laura"},
    {"afip":"5902","location":"Estancia Libertad"},
    {"afip":"5905","location":"Estancia Los Cerros"},
    {"afip":"5907","location":"Estancia Los Flamencos"},
    {"afip":"5920","location":"Estancia Maria Behety"},
    {"afip":"5921","location":"Estancia Maria Cristina"},
    {"afip":"5922","location":"Estancia Maria Luisa"},
    {"afip":"5923","location":"Estancia Marina"},
    {"afip":"5926","location":"Estancia Miramonte"},
    {"afip":"5932","location":"Estancia Pirinaica"},
    {"afip":"5933","location":"Estancia Policarpo"},
    {"afip":"5938","location":"Estancia Rio Claro"},
    {"afip":"5939","location":"Estancia Rio Ewan"},
    {"afip":"5940","location":"Estancia Rio Irigoyen"},
    {"afip":"5943","location":"Estancia Rolito"},
    {"afip":"5945","location":"Estancia Rosita"},
    {"afip":"5946","location":"Estancia Ruby"},
    {"afip":"5956","location":"Estancia San Justo"},
    {"afip":"5960","location":"Estancia San Pablo"},
    {"afip":"5965","location":"Estancia Santa Ana"},
    {"afip":"5970","location":"Estancia Sara"},
    {"afip":"5975","location":"Estancia Tepi"},
    {"afip":"5981","location":"Estancia Viamonte"},
    {"afip":"6265","location":"Frigorifico Cap"},
    {"afip":"6756","location":"Hosteria Kaiken"},
    {"afip":"7026","location":"Isla De Los Estados"},
    {"afip":"7038","location":"Isla Gran Malvina"},
    {"afip":"7042","location":"Isla Joinville"},
    {"afip":"7058","location":"Isla Shetland Del Sur"},
    {"afip":"7060","location":"Isla Soledad"},
    {"afip":"7069","location":"Islas Georgias"},
    {"afip":"7070","location":"Islas Orcadas"},
    {"afip":"8229","location":"Lago Khami"},
    {"afip":"9955","location":"Mision Salesiana Mñor Fagnano"}
    ,
    {"afip":"12151","location":"Punta Maria"},
    {"afip":"13383","location":"Seccion Aviles  Estancia San J"},
    {"afip":"13928","location":"Tolhuin"},
    {"afip":"14214","location":"Ushuaia"},
    {"afip":"16487","location":"Estancia Rivadavia"},
    {"afip":"16492","location":"Estancia San Jose"},
    {"afip":"16494","location":"Estancia San Julio"},
    {"afip":"16495","location":"Estancia San Martin"},
    {"afip":"18510","location":"Rio Grande"},
    {"afip":"18911","location":"San Sebastian"},
    {"afip":"18992","location":"Santa Ines"},
    {"afip":"21696","location":"Islas Del Atlantico Sur"}
    ]