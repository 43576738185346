import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateOrderComponent } from './generate-order.component';

@NgModule({
  declarations: [
    GenerateOrderComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    GenerateOrderComponent
  ]
})
export class GenerateOrderSvgModule { }
