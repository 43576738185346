import { Component, OnDestroy, OnInit,ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setLocation } from 'src/app/redux/actions/options.action';
import { loadPricesAllSuccess } from 'src/app/redux/actions/price.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price, PriceString } from 'src/app/shared/interfaces/price.interface';
import { FilterAllPricePipe } from 'src/app/shared/pipes/filter/filter-all-price.pipe';
import { PriceSortPipe } from 'src/app/shared/pipes/sorts/pricesSort.pipe';
import { PriceValidator } from 'src/app/shared/price';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { SessionChecker } from 'src/app/shared/session.checker';
import { WebSocketFunctions } from 'src/app/shared/websocket/websocket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-price-board',
  templateUrl: './price-board.component.html',
  styleUrls: ['./../../shared/styles/body.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})

export class PriceBoardComponent extends WebSocketFunctions implements OnInit, OnDestroy{
  private prices:Price[]=[];
  private prices$!:Subscription;
  conditionFilters:number[]=[];

  private conditionFilters$!:Subscription;

  constructor(private store: Store<appState>, private checker: SessionChecker, private priceV:PriceValidator,
    maestroSvc:MaestrosService) {
    super(environment.rutas.websocket.prices, maestroSvc)
    this.loadPrices();

    this.checker.rememberAndActualizateUser(this.store);
    this.store.dispatch(setLocation({location: NAVEGATION.PRICE_BOARD}));
  }

  setSubscriptions(){
    this.prices$=this.store.select('price', 'priceBoard', 'all').subscribe( (priceAll) => {
      this.prices = priceAll;
    });

    this.conditionFilters$=this.store.select('options', 'board', 'conditionFilter').subscribe( (conditionFilter) => {
      this.conditionFilters = conditionFilter;
    });
  }

  setUnsubscriptions(){
    this.prices$?.unsubscribe();
    this.conditionFilters$?.unsubscribe();
  }

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.setUnsubscriptions();
    this.closeConnection()
  }

  override functionToMessage(event: any): void {
    console.log("Response ws")
      if(event.data !== JSON.stringify(this.prices)){
        const prices=JSON.parse(event.data)
        let almacenamiento= !prices? []: prices;
        localStorage.setItem(localStorageNames.pricesAll, JSON.stringify(almacenamiento));
        this.store.dispatch(loadPricesAllSuccess({prices: almacenamiento}));
      }
  }
  
  loadPrices(){
    this.priceV.searchTodayPrices();
  }
}

export function getPriceListWithPipes(prices:Price[], isComercial:boolean, buyerType:string,
  priceFilter:PriceString, conditionType:string, search:string, conditionFilters:number[]){
  const pipe= new FilterAllPricePipe();
  return pipe.transform(prices, isComercial, buyerType, priceFilter, conditionType, search, conditionFilters);
}

export function getPriceListWithPipesAndSort(prices:Price[], isComercial:boolean, buyerType:string,
  priceFilter:PriceString, conditionType:string, search:string, conditionFilters:number[]){
  const pipe4= new PriceSortPipe();
  return pipe4.transform(getPriceListWithPipes(prices,isComercial,buyerType,priceFilter,conditionType, search, conditionFilters), isComercial);
}