<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="{{title}}"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">{{title}}</h2>
    <!-- CONTENT OF THE PRICE BOARD -->
    <body-order-list></body-order-list>

    <app-menu class="menu"></app-menu>
    <app-menu-footer></app-menu-footer>
</div>