import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { priceTags } from 'src/app/shared/const/prices.const';
import { zones } from 'src/app/shared/dict/place-of-delivery';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../../fields.component';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./../../fields.component.scss']
})
export class ZoneComponent extends Field implements OnInit {

  constructor(store: Store<appState>) {
    super(store);
    this.array = Object.keys(zones);
    this.array.shift();
    this.OBJECT=zones;
    this.deleteLastValueInArray();
    this.elementSelected = '';
    this.initalElementSelected = 'Ingresar una zona';
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.placeOfDelivery
      this.subTag = priceTags.zone;
    } else {
      this.tag = 'price';
      this.subTag = priceTags.placeOfDelivery
      this.thirdTag = priceTags.zone;
    }
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    this.resetTags();
    this.rememberValueWithObject();
  }

  override setElement(element: any, index: number) {
    this.setTag(priceTags.port);
    this.setElementToGenerate(-1); 
    //Cada vez que se selecciona una nueva zona.
    //Entonces reiniciamos el port a -1

    this.setTag(priceTags.zone);
    this.setElementToGenerate(Number(element));
  }

  resetTags(){
    this.fieldIdentificator==fieldType.PRICE? this.subTag = priceTags.zone: this.thirdTag=priceTags.zone;
  }
}
