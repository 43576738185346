<div class="way-to-pay {{identifyClassName}}">
    
    <div class="row-between {{identifyClassName}}">

        <div class="field-percentage {{identifyClassName}}">
            <label for="percentage {{identifyClassName}}">Porcentaje</label>
            <div class="form-field {{identifyClassName}}" [ngClass]="{'border-green': wayPay?.percentage>0, 'is-incomplete': isInvalid}" (click)="invertVariables()" (change)="setPercentage($event)">
                <input style="margin-left:-16px" type="number" step="0.5" min="0" max="100" placeholder="Porcentaje"
                    value="{{wayPay?.percentage ?? 0}}" class="form-control no-background-border {{identifyClassName}}" aria-label="title">
            </div>
        </div>
        
        <div class="field-days {{identifyClassName}}">
            <label for="days {{identifyClassName}}" *ngIf="!(wayPay?.wayPayName == 3)">Días</label>
            <div class="form-field {{identifyClassName}}" 
            [ngClass]="{'border-green':(wayPay?.days ?? 0) > 0, 'disabled':wayPay?.wayPayName==WAY_PAY_CONST.contraEntrega }" 
            (click)="invertVariables()" *ngIf="!(wayPay?.wayPayName==WAY_PAY_CONST.fechaFija)">
                <input style="margin-left:-16px" type="number" min="0" placeholder="Cantidad de días"
                (change)="setDays($event)" [disabled]="wayPay?.wayPayName==1"
                value="{{(wayPay?.days ?? 0) <= 0? '': wayPay.days}}" class="form-control no-background-border {{identifyClassName}}">
            </div>
        </div>
        
    </div>

    <div class="radios-buttons {{identifyClassName}}" *ngIf="! (wayPay?.wayPayName == WAY_PAY_CONST.fechaFija)">
        <div class="radio-button {{identifyClassName}}" style="margin-right:8px;">
            <input type="radio" class="form-check-input pointer {{identifyClassName}}" [checked]="wayPay?.isBusinessDays==1" (click)="setBusinessDays(true)">
            <span class="{{identifyClassName}}">Días hábiles</span>
        </div>

        <div class="radio-button button-order-purchase {{identifyClassName}}">
            <input type="radio" class="form-check-input pointer {{identifyClassName}}" [checked]="wayPay?.isBusinessDays==-1" (click)="setBusinessDays(false)">
            <span class="{{identifyClassName}}">Días corridos</span>
        </div>
    </div>
</div>