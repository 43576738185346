import { Component, EventEmitter,Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { conditionType } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { setConditionTypeSelectedBoard, setIsGenerateToFix } from 'src/app/redux/actions/options.action';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-buttons-condition-type',
  templateUrl: './buttons-condition-type.component.html',
  styleUrls: ['./buttons-condition-type.component.scss']
})
export class ButtonsConditionTypeComponent implements OnInit, OnDestroy {
  conditionTypeSelected: string = '';
  public conditionTypes=conditionType;

  @Output() valueOut = new EventEmitter<string>();

  condTypSelec$!:Subscription;
  constructor(private store:Store<appState>) {}

  ngOnInit(): void {
    this.condTypSelec$=this.store.select('options','board','conditionTypeSelected').subscribe((conditType)=>{
      this.conditionTypeSelected=conditType;
    });
  }

  ngOnDestroy(){
    this.condTypSelec$?.unsubscribe();
  }

  setConditionType(conditionType:string){
    this.store.dispatch(setConditionTypeSelectedBoard({conditionTypeSelected:conditionType}));
    this.store.dispatch(setIsGenerateToFix({isGenerateToFix:conditionType==this.conditionTypes.TO_FIX }));
    this.valueOut.emit(conditionType);
  }
  
}