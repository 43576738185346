import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ViewOrder, ViewOrderExchange, ViewPrice } from '../interfaces/views.interface';
import { InfoViewUser } from 'src/app/redux/effects/views.effect';

@Injectable({
  providedIn: 'root'
})
export class ViewsService {
    constructor(private http:HttpClient) { }
  
    searchViews(email:string, nameOfView:string){
      const body = {
        'email': email,
        'campo': nameOfView
      };
    
      return this.http.post<any>(environment.rutas.views.search, body);
    }

    searchAllViews(email:string){
      return this.http.get<any>(environment.rutas.views.searchAll + `/${email}`);
    }

    //Se guarda el documento completo de las vistas
    saveNewView(info:InfoViewUser){
      return this.http.post<any>(environment.rutas.views.send, info);
    }

    updateViews(email:string, name:string, views:ViewOrder[] | ViewPrice[] | ViewOrderExchange[]){
      return this.http.put<any>(environment.rutas.views.update, { email:email, campo: name, data: views});
    }
}