<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(14)">Sellado a recuperar</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="sealedToRetrieve>0" 
            (change)="setOptionOpenWithCheck($event,14)" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="option-content-particularity">
            <span class="option-name">100% a cargo del comprador</span>
            <div class="actions">
                <input type="radio" [checked]="sealedToRetrieve == 1" (click)="setSealedToRetrieve(1)" class="radio" />
            </div>
        </div>
        <div class="option-content-particularity">
            <span class="option-name">100% a cargo del vendedor</span>
            <div class="actions">
                <input type="radio" [checked]="sealedToRetrieve == 2" (click)="setSealedToRetrieve(2)" class="radio" />
            </div>
        </div>
    </div>
</div>