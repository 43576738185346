import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setModalContract } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FailedResponse } from 'src/app/shared/interfaces/DataIBM';

@Component({
    selector: 'dialog-contract-create',
    templateUrl: './dialog.html',
    styleUrls: ['./dialog.scss', '../modal.scss']
  })
  export class DialogContractCreate implements OnInit, OnDestroy {
    hour: string = '';
    mensaje: string = 'El nuevo borrador fue enviado a boletos para su aprobación bajo el N° de contrato:';
    moreInfo: string = 'Su número es: ';
    arrayOfSuccess!: FailedResponse[];
    location:string='';
    isConfirm: boolean = true;

    optionsSubscription!: Subscription;
    constructor(private store: Store<appState>, private date: DateChecker) {}

    ngOnInit(): void {
      this.hour = this.date.setHourFormat();

      this.optionsSubscription=this.store.select('options').subscribe(optionsR => {
        this.arrayOfSuccess = optionsR.modalContract.arrayOfSuccess;
        this.location = optionsR.location;
      });
    }

    ngOnDestroy(): void {
      this.optionsSubscription.unsubscribe();
    }

    close(){
      this.store.dispatch(setModalContract({modalContract:{isShow:false, arrayOfFaileds:[], arrayOfSuccess:[], isIndicators:false, typeModal: 0}}));
    }

    getClientName(success:FailedResponse){
      return success.data.NombreVendedor;
    }

    isExchange(){
      return this.location == NAVEGATION.ORDER_EXCHANGE;
    }
  }