import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'duplicate-order-svg-do',
  templateUrl: './duplicate-order.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class DuplicateOrderSvgDOComponent {
  @Input() active:boolean=false;
  
  constructor() { }
}
