import { Component, HostListener,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderTableComponent } from '../order-table.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateChecker } from 'src/app/shared/date.checker';
import { setEditAll, setIndexToEditValidation, setIsOrderReturned, setValidationOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { DialogConfirmedOrder } from 'src/app/components/dialogs/order-confirmed/order-confirmed';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, copyOrder } from 'src/app/shared/order';
import { setErrorStruct, setShowAddBuyer } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';

export const STATES_ORDERS_VALIDS=
["Devuelta","Pendiente","Confirmada","Visto","Ofertado","Enviado a bolsa","Fuera de mercado"];

@Component({
  selector: 'app-commodities',
  templateUrl: './commodities.component.html',
  styleUrls: ['../order-table.component.scss', './../../../../../shared/styles/board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CommoditiesComponent extends OrderTableComponent {
  public STATES_ORDERS_VALIDS = STATES_ORDERS_VALIDS;

  constructor(store: Store<appState>, router: Router, dialog: MatDialog, 
    date:DateChecker, orderV:OrderValidator, toFixV:ToFixValidator, cdr: ChangeDetectorRef) {
    super(store, router, dialog, date, orderV,toFixV, cdr)
  }

  @HostListener('document:keypress', ['$event'])
  listenerKeyPress(event: KeyboardEvent){
    if(!this.isOrderReturned && this.ordersSelected.length>0 && !this.isShowAddBuyer){
      let statusOrder = -1;
      switch(event.key.toLowerCase() ){
        case 'd': this.store.dispatch(setIsOrderReturned({isOrderReturned:true})); 
                  statusOrder=orderStatusConst.RETURNED; break;
        //case 'p': statusOrder=orderStatusConst.PENDING; break;
        case 'x': statusOrder=orderStatusConst.CONFIRM; break;  //CONFIRM WITH ANY BUYER DEFAULT
        case 'c': statusOrder=orderStatusConst.CONFIRM; break; //CONFIRM WITH COMMODITIES DEFAULT
        case 'v': statusOrder=orderStatusConst.CHECK; break;
        case 'o': statusOrder=orderStatusConst.OFERTED; break;
        case 'e': statusOrder=orderStatusConst.SEND_TO_BROKE; break;
        case 'f': statusOrder=orderStatusConst.OUT_OF_MARKET; break;
        default: statusOrder=-1;
      }

      if(statusOrder!=-1){
        this.setEditOrders(statusOrder);
      } else {      
        this.store.dispatch(setErrorStruct({ error: {isVisible: true, message: 'No se ha pulsado una tecla válida', color: '#fff' }}));
      }
    }
  }

  sendToTickets(order:Order){
    //Si la orden no tiene estado de autorizacion ni contrato.
    if( ![orderStatusConst.AUTORIZATION, orderStatusConst.CONTRACT].includes(order.statusOrder)){
      //Reiniciamos order list y seleccionamos la orden
      this.orderV.resetSelectedOrderList();
      this.selectOrder(order);

      //Si la orden tiene estado confirmado, abrimos el slide card de añadir comprador.
      if(order.statusOrder==orderStatusConst.CONFIRM){
        this.store.dispatch(setShowAddBuyer({ isShowAddBuyer: true }))    
        this.store.dispatch(setValidationOrdersToGenerate({ orders: [order] }));
        this.store.dispatch(setEditAll({ editAll: true }));
        this.store.dispatch(setIndexToEditValidation({ indexToEdit: 0 }));

      } else {
        //Si la orden no está confirmada, abrimos el dialogo de confirmación de orden.  
        this.dialog.open(DialogConfirmedOrder);
      }
    }
  }

  validateOrders(order:Order){
    if(order.statusOrder==orderStatusConst.CONFIRM){
      this.orderV.resetSelectedOrderList();
      this.selectOrder(order);
      let validationOrders=JSON.parse(JSON.stringify(this.ordersSelected));
      this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
      this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
      this.router.navigate(['/'+NAVEGATION.ORDER_VALIDATION]);
    } else {
      this.store.dispatch(setErrorStruct({ error: {isVisible: true, message: 'No se puede editar una orden que no está confirmada', color: '#fff' }}));
    }
  }

  getOptionsMenu(order:Order):OptionMenu[]{
    return order.statusOrder === orderStatusConst.RETURNED ? 
  [ {name:'Historial de cambios', icon:'clock', action:OPTION.HISTORICAL},
    {name:'Reingresar orden', icon:'edit', action:OPTION.REINGRESATE},
    {name:'Copiar orden', icon:'copy', action:OPTION.COPY} ]
    : 
  [ {name:'Historial de cambios', icon:'clock', action:OPTION.HISTORICAL},
    {name:'Generar borrador', icon:'generate-order-option', action:OPTION.SEND},
    {name:'Editar y enviar orden', icon:'edit', action:OPTION.EDIT},
    {name:'Copiar orden', icon:'copy', action:OPTION.COPY} ]

    return [
      {name:'Historial de cambios', icon:'clock', action:OPTION.HISTORICAL},
      {name:'Generar borrador', icon:'generate-order-option', action:OPTION.SEND},
      {name:'Editar y enviar orden', icon:'edit', action:OPTION.EDIT},
      {name:'Copiar orden', icon:'copy', action:OPTION.COPY},
    ]
  }
  
  override selectOptionMenu(event:OptionMenu, order:Order){
    switch(event.action){
      case OPTION.DETAILS: this.showOrderDetail(order); break;
      case OPTION.SELECT: this.selectOrder(order); break;
      case OPTION.HISTORICAL: this.showHistorical(order); break;
      case OPTION.SEND:this.sendToTickets(order);break;
      case OPTION.EDIT: this.validateOrders(order); break;
      
      case OPTION.REINGRESATE: this.reingresateOrder(order); break;
      case OPTION.COPY: copyOrder(order,this.store, this.router); break;
    }
   }
}