<div class="info-product gap-16">
    <div class="icon-and-name row-left gap-8">
        <img class="product-icon" src="../../../../assets/images/products/{{productName}}.svg">
        <span>{{productName}}</span>
    </div>

    <table>
        <thead>
            <tr>
                <th>Vendedor</th>
                <th>Periodo</th>
                <th>Precio</th>
                <th>Tn</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr class="row-table" *ngFor="let dataOfOrder of orders" (click)="goToOrderBoard(dataOfOrder)">
                <td>{{getSellerName(dataOfOrder)}}</td>
                <td>{{dataOfOrder.price.deliveryPeriod | periodPipe:''}}</td>
                <td class="precio">{{getTypeCoinAndPrice(dataOfOrder)}}</td>
                <td>{{getTons(dataOfOrder)}}</td>
                <td> <arrow-svg [direction]="'right'"></arrow-svg> </td>
            </tr>
        </tbody>
    </table>
</div>