import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderInSectionComponent } from './header-in-section.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';



@NgModule({
  declarations: [
    HeaderInSectionComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule
  ],
  exports: [
    HeaderInSectionComponent
  ]
})
export class HeaderInSectionModule { }
