import { Pipe, PipeTransform } from '@angular/core';
import { PRICE_STATUS_CONST } from '../../const/prices.const';
import { PRODUCT_NAMES } from '../../const/productNames';
import { DateChecker, isEarlierDate } from '../../date.checker';
import { Price } from '../../interfaces/price.interface';

const ORDENATE_PRICE = [
  PRICE_STATUS_CONST.PRECIO,
  PRICE_STATUS_CONST.IDEA,
  PRICE_STATUS_CONST.PAUSADO,
  PRICE_STATUS_CONST.RETIRADO,
  PRICE_STATUS_CONST.INACTIVO,
  PRICE_STATUS_CONST.NONE,
];

//De abajo a arriba
const PRODUCTS_ORDENATE = [
  PRODUCT_NAMES.MAIZ,
  PRODUCT_NAMES.TRIGO,
  PRODUCT_NAMES.SOJA,
  /*PRODUCT_NAMES.GIRASOL,
  PRODUCT_NAMES.CEBADA_CERVECERA,
  PRODUCT_NAMES.CEBADA_FORRAJERA,
  PRODUCT_NAMES.SORGO*/
];

@Pipe({
  name: 'sortPrice',
})
export class PriceSortPipe implements PipeTransform {
  constructor() {}

  transform(valueList: Price[], isComercial: boolean) {
    return this.mergeSort(valueList, isComercial);
  }

  private mergeSort(array: Price[], isComercial: boolean): Price[] {
    if (array.length <= 1) {
      return array;
    }

    const middle = Math.floor(array.length / 2);
    const left = array.slice(0, middle);
    const right = array.slice(middle);

    return this.merge(
      this.mergeSort(left, isComercial),
      this.mergeSort(right, isComercial),
      isComercial
    );
  }

  private merge(left: Price[], right: Price[], isComercial: boolean): Price[] {
    const result: Price[] = [];
    let leftIndex = 0;
    let rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      if (
        this.comparePrices(left[leftIndex], right[rightIndex], isComercial) <= 0
      ) {
        result.push(left[leftIndex]);
        leftIndex++;
      } else {
        result.push(right[rightIndex]);
        rightIndex++;
      }
    }

    return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
  }

  /*Estos son los criterios de ordenamiento:
  - SOLO cuando isComercial==true se seguirá este criterio: Primero deben estar los Price que sean DESTACADOS. Si no es comercial pasamos al siguiente punto.
  - El segundo punto para ordenar es el nombre del producto. La constante PRODUCTS_ORDENATE tiene el orden con que deben ordenarse: primero las condiciones que tengan el producto SOJA, despues TRIGO, despues MAIZ y si no es ninguna de esas por el valor que tenga productName.
  - El tercer punto para ordenar es el typeBusiness. Hay que ordenarlos de menor a mayor.
  - El cuarto punto para ordenar es el lugar de entrega. La interface PlaceOfDelivery tiene 2 campos: zone y port. Se debe ordenar de menor a mayor la zone y cuando sean iguales se debe ordenar de menor a mayor el port.
  - El quinto punto para ordenar es el periodo de entrega.  Ordenamos la fecha de inicio de menor a mayor. Posteriormente ordenamos la fecha de fin de menor a mayor. Para saber si una fecha es anterior o no utilizamos isEarlierDate que es una función del objeto dateC.
  - El sexto punto para ordenar es la calidad. Se ordena de menor a mayor.
  - El séptimo punto para ordenar es el nombre de la forma de pago. Se ordena de menor a mayor.
  - El octavo punto para ordenar es la fecha de vencimiento. Es una fecha. Para ordenarla de menor a mayor volvemos a utilizar la funcion isEarlierDate del objeto dateC.
  - El noveno punto para ordenar es el estado de la condición. Nos ayudamos de la constante ORDENATE_PRICE que es un arreglo que indica que se ordenan los estados en el siguiente orden:*/

  private comparePrices(a: Price, b: Price, isComercial: boolean): number {
    // Implementar la comparación basada en los criterios de ordenamiento aquí
    // Devuelve un número negativo si a debe ir antes que b, positivo si b debe ir antes que a, y 0 si son iguales.
    if (isComercial) {
      // Criterio: Primero deben estar los Price que sean DESTACADOS
      if (a.observations.isFeatured !== b.observations.isFeatured) {
        return b.observations.isFeatured - a.observations.isFeatured;
      }
    }

    // Criterio: Nombre del producto
    const aProductNameIndex = PRODUCTS_ORDENATE.indexOf(a.productName);
    const bProductNameIndex = PRODUCTS_ORDENATE.indexOf(b.productName);

    if (aProductNameIndex !== bProductNameIndex) {
      return aProductNameIndex * -1 - bProductNameIndex * -1;
    }

    // Criterio: typeBusiness
    if (a.typeBusiness !== b.typeBusiness) {
      return a.typeBusiness - b.typeBusiness;
    }

    // Criterio: placeOfDelivery
    if (a.placeOfDelivery.zone !== b.placeOfDelivery.zone) {
      return a.placeOfDelivery.zone - b.placeOfDelivery.zone;
    }

    if (
      a.placeOfDelivery.zone >= 8 &&
      a.placeOfDelivery.zone === b.placeOfDelivery.zone
    ) {
      return a.placeOfDelivery.port - b.placeOfDelivery.port;
    }

    // Criterio: deliveryPeriod
    if (a.deliveryPeriod.startDate !== b.deliveryPeriod.startDate) {
      return isEarlierDate(a.deliveryPeriod.startDate, b.deliveryPeriod.startDate, '-')? -1: 1;
    }

    if (a.deliveryPeriod.endDate !== b.deliveryPeriod.endDate) {
      return isEarlierDate(a.deliveryPeriod.endDate, b.deliveryPeriod.endDate, '-')? -1: 1;
    }

    // Criterio: quality
    if (a.quality !== b.quality) {
      return a.quality - b.quality;
    }

    // Criterio: wayPayName
    if (a.wayPay.wayPayName !== b.wayPay.wayPayName) {
      return a.wayPay.wayPayName - b.wayPay.wayPayName;
    }

    // Criterio: expiration (fecha de vencimiento)
    if (
      a.wayPay.expiration !== b.wayPay.expiration &&
      a.wayPay.expiration !== '--' &&
      b.wayPay.expiration !== '--'
    ) {
      return isEarlierDate(a.wayPay.expiration, b.wayPay.expiration, '-')? -1: 1;
    }

    // Criterio: status
    return ORDENATE_PRICE.indexOf(a.status) - ORDENATE_PRICE.indexOf(b.status);
  }
}