import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionSvgComponent } from './commission.component';

@NgModule({
  declarations: [
    CommissionSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    CommissionSvgComponent
  ]
})
export class CommissionSvgModule { }
