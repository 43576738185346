import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DialogOrderExchangeCreate } from 'src/app/components/dialogs/order-exchange-create/dialog';
import { DialogOrderToBolets } from 'src/app/components/dialogs/order-to-bolets/dialog';
import { orderCategoryConst } from 'src/app/shared/const/orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';

const SIZE_OF_VIEW_MOBILE = 950;

@Component({
  selector: 'app-body-generate-exchange',
  templateUrl: './body-generate-exchange.component.html',
  styleUrls: ['./../../../shared/styles/body-generate.scss', 
  './../../generate-price/body-generate-price/body-generate-price.component.scss']
})
export class BodyGenerateExchangeComponent implements OnInit, OnDestroy {
  //It serves to identify if the user pressed 'Orden de venta' or 'Orden de compra'
  public isOrderSell: boolean = true;

  //It is a boolean that represents if the button is disabled or not
  disabledButton: boolean = true;

  //It is a boolean that represents if the details are being displayed or not
  detailsVisible: boolean = false;

  //It is a boolean that represents if the form is being displayed or not
  formVisible: boolean = true;
  count=0;

  //Cards
  isOpenCommission:boolean = false;
  isOpenDuplicate:boolean = false;

  public orderToGenerate!: OrderExchange;
  public quantOrdersToGenerate: number = 1;

  isSyngentaBuyer: boolean = false;

  private exchange$!:Subscription;
  private options$!:Subscription;
  
  constructor(private store: Store<appState>, private orderV:OrderValidator, 
    private orderExcV:OrderExchangeValidator, private dialog:MatDialog) {}

  ngOnInit(): void {
    this.exchange$=this.store.select('exchange').subscribe(orderR => {
      this.orderToGenerate = orderR.generateOrder;
      this.isOrderSell = orderR.generateOrder.orderDestination.orderCategory == orderCategoryConst.orderSale;

      this.disabledButton=this.isButtonDisabled();
      this.isSyngentaBuyer=this.orderV.isSyngentaAgroAvc(this.orderToGenerate.sellerData.codeS, 
        this.orderToGenerate.price.businessParticularities.subdivisionSyngenta, 
        this.orderToGenerate.price.businessParticularities.isAVC)

    })

    this.options$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.showDetailsAndForm(sizeView);
    })

  }

  ngOnDestroy(): void {
    this.exchange$?.unsubscribe();
    this.options$?.unsubscribe();
  }

  showDetails(showDetails: boolean) {
    this.detailsVisible = showDetails;
    this.formVisible = !showDetails;
    this.count++;
  }

  showDetailsAndForm(sizeView:number){
    if(sizeView > SIZE_OF_VIEW_MOBILE) {
      this.detailsVisible=true;
      this.formVisible=true;
      this.count=0
    } else if(this.count==0) {
      this.detailsVisible=false;
      this.formVisible=!this.detailsVisible
    }
  }

  //-------- CARDS --------\\
  openCommission(){
    if(!this.disabledButton){
      this.isOpenCommission=!this.isOpenCommission;
      this.isOpenDuplicate=false;
    }
  }
  openDuplicateOrder(){
    if(!this.disabledButton){
      this.isOpenDuplicate=!this.isOpenDuplicate;
      this.isOpenCommission=false;
    }
  }
  //-----------------------\\

  isButtonDisabled():boolean{
    return (!this.isSyngentaBuyer && this.orderExcV.isExchangeIncomplete(this.orderToGenerate.exchange) ) || 
     (this.isSyngentaBuyer && this.orderV.isOrderIncomplete(this.orderToGenerate))
  }

  openModal(){
    this.isSyngentaBuyer? this.dialog.open(DialogOrderToBolets): this.dialog.open(DialogOrderExchangeCreate);
  }
}