import { Pipe, PipeTransform } from '@angular/core';
import { COMMODITIES_CODE, COMMODITIES_CODE_SELLER, HUGHES_CODE_SELLER } from '../../const/buyers';
import { areas } from '../../const/user.const';
import { Order } from '../../interfaces/order.interface';
import { User } from '../../interfaces/user.interface';
import { getArrayByStatus } from './filter.by.status.pipe';
import { isTheSameCreator } from './filter-by-restriction.pipe';


@Pipe({
  name: 'filterOrderDashboard'
})
export class FilterOrderDashboardPipe implements PipeTransform {
  /*Filter the orders to display in the dashboard component taking into account:
  Passing it by parameter, the user and a status
  If it is commercial, filter yours.
  Sort orders by hour, then filter their status. Gathering a maximum of 6 orders and then filtering them. */
  transform(list: Order[], user:User, status:number) {
    const arrayFinal: any[] = [];

    //Si es comercial, filtramos las ordenes que creó el usuario
    let arrayAux:any[]=user.area==areas.comercial?filterByCreator(list,user):list;
    //Ordenamos por hora las ordenes.
    /*arrayAux.sort(function(a,b){
        return compareByHour(a.hour,b.hour);
    })*/

    //Filtramos por estado, hasta un maximo de 6 ordenes
    const statusArray = getArrayByStatus(status);
    for(let i=0; i<arrayAux.length; i++){
      if ( statusArray.includes(arrayAux[i].statusOrder)) {
          arrayFinal.push(arrayAux[i]);
      }

      if(arrayFinal.length == 6){
          i=list.length; //Para que corte
      }
    }

    return arrayFinal;
  }
}  

export function filterByCreator(list:Order[], user:User){
  const arrayFinal: any[] = [];
  list.forEach((element:Order) => {
    if (isTheSameCreator(element.dataOfcreation.nameOfcreator, user)){
      arrayFinal.push(element);
    }
  });
  return arrayFinal;
}
