<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(4)">Condicional</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" (change)="setOptionOpenWithCheck($event,4)" 
            (click)="verifyConditional(conditional)" [checked]="getIsConditional()"/>
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="option-content-particularity">
            <span class="option-name">Call venta</span>
            <div class="actions">
                <input type="radio" (click)="setConditionalType(1)" [checked]="conditional.selection==1" class="radio" />
            </div>
        </div>
        <div class="option-content-particularity">
            <span class="option-name">Put venta</span>
            <div class="actions">
                <input type="radio" (click)="setConditionalType(2)" [checked]="conditional.selection==2"  class="radio" />
            </div>
        </div>        
        <div class="option-content-particularity">
            <span class="option-name">Call compra</span>
            <div class="actions">
                <input type="radio" (click)="setConditionalType(3)" [checked]="conditional.selection==3"  class="radio" />
            </div>
        </div>
        <div class="option-content-particularity">
            <span class="option-name">Put compra</span>
            <div class="actions">
                <input type="radio" (click)="setConditionalType(4)" [checked]="conditional.selection==4" class="radio" />
            </div>
        </div>

        <div class="field" *ngIf="conditional.selection > 0">
            <span class="field-title">Strike<span class="asterisk">*</span></span>
            <number-input [initialPlaceHolder]="'Ingresar un valor'" [value]="conditional.strike" (valueOut)="setStrike($event)"></number-input>
        </div>


        <div class="field" style="margin-bottom: 8px;" *ngIf="conditional.selection > 0">
            <span class="field-title">Posición<span class="asterisk">*</span></span>
            <date-month-input [initialPlaceHolder]="'Ingrese un mes y año'"
            [value]="conditional.position" (valueOut)="setDate($event)"></date-month-input>
        </div>
        

    </div>
</div>