import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setSearchBoard } from 'src/app/redux/actions/options.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'app-search-input',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy {
  @Input() search: string='';
  @Output() searchChange = new EventEmitter<string>();
  constructor(private store:Store<appState>) {}

  setSearch(event: any) {
    this.store.dispatch(setSearchBoard({search:event.target.value}))
    //this.searchChange.emit(event.target.value);
  }

  ngOnDestroy(): void {
    this.store.dispatch(setSearchBoard({search:''}))
    //this.searchChange.emit('');
  }
}
