import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsValidationComponent } from './details.component';
import { CommissionModule } from 'src/app/components/cards/commission/commission.module';
import { DataModule } from 'src/app/components/details/data/data.module';
import { DuplicateOrdersModule } from '../../cards/duplicate-orders/duplicate-orders.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { DuplicateOrderCollectionSvgModule } from 'src/app/shared/styles/svg/duplicate-orders/duplicate-order.module';
import { InfoOrderModule } from '../info/info-order/info-order.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { InfoToFixModule } from '../info/info-to-fix/info-to-fix.module';
import { DetailsManyOrdersModule } from '../details-many-orders/details-many-orders.module';

@NgModule({
  declarations: [
    DetailsValidationComponent
  ],
  imports: [
    CommonModule,
    DataModule,
    CommissionModule,
    DuplicateOrdersModule,
    PipesModule,
    
    ArrowSvgModule,
    DuplicateOrderCollectionSvgModule,
    InfoOrderModule,
    InfoToFixModule,
    DetailsManyOrdersModule
  ],
  exports:[
    DetailsValidationComponent
  ]
})
export class DetailsValidationModule { }
