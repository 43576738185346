import { Pipe, PipeTransform } from '@angular/core';
import { conditionType } from '../../const/options';
import { Price } from '../../interfaces/price.interface';

@Pipe({
  name: 'filterByConditionType'
})
export class FilterByConditionTypePipe implements PipeTransform {

  transform(list: any[], identificator: string, conditionsFilters:number[]) {
    if(identificator == conditionType.ALL) return list;
    
    return list.filter(price=> isPriceToFix(price,identificator,conditionsFilters))
  }
}
  
@Pipe({
  name: 'filterOrderByConditionType'
})
export class FilterOrderByConditionTypePipe implements PipeTransform {

  transform(list: any[], identificator: string, conditionsFilters:number[]) {
    if(identificator == conditionType.ALL) return list;
    
    return list.filter(order=> isPriceToFix(order.price,identificator,conditionsFilters) )
  }
}

export function isPriceToFix(price: Price, identificator: string, conditionsFilters:number[]){
  return (identificator == conditionType.TO_FIX && price.indicators && price.indicators.length>0 && conditionsFilters.includes(price?.conditionType)) ||
  identificator == conditionType.TO_PRICE && (!price.indicators || price.indicators.length==0)
}
  