import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyHistoricalComponent } from './body-historical.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ViewsOrdersModule } from 'src/app/components/views/views-orders/views-orders.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { DetailsEyeModule } from 'src/app/components/slide-cards/details-eye/details-eye.module';
import { DialogYesOrNoModule } from 'src/app/components/dialogs/dialog-yes-or-no/dialog-yes-or-no.module';
import { TableModule } from './table/table.module';
import { ViewsSectionModule } from 'src/app/components/views-section/views-section.module';
import { ExchangeDetailsModule } from 'src/app/components/slide-cards/exchange-details/exchange-details.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';
import { SearchInputModule } from 'src/app/components/inputs/search/search.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HistoricalModule } from 'src/app/components/slide-cards/historical/historical.module';
import { AddExchangeModule } from 'src/app/components/slide-cards/add-exchange/add-exchange.module';
import { BoardSlideModule } from 'src/app/components/board/slide/slide.module';

@NgModule({
  declarations: [
    BodyHistoricalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BoardSlideModule,

    FormsModule,
    ReactiveFormsModule,

    ViewsOrdersModule,
    PipesModule,
    ViewsSectionModule,

    //Slides
    HistoricalModule,
    DetailsEyeModule,
    ExchangeDetailsModule,
    AddExchangeModule,

    //DIALOG
    DialogYesOrNoModule,

    //SVG
    ThreePointsSvgModule,
    ArrowSvgModule,

    TableModule,
    SearchInputModule,
    ButtonsConditionTypeModule,
  ], exports: [
    BodyHistoricalComponent,
  ]
})
export class BodyHistoricalModule { }
