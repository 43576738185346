<div class="field">
    <span class="field-title">Moneda<span class="asterisk">*</span></span>

    <div>
        <div class="radios-buttons">
            <mat-radio-group>
                <mat-radio-button class="radio-button" [value]="'grassi'" [disabled]="isPriceIn('USD')"
                (click)="setIsDolar(true)" [checked]="elementSelected=='USD'" (keyup)="setIsDolarEvent(true, $event)"> 
                    <span class="radio grassi" [ngClass]="{'selected-radio': elementSelected=='USD'}" >Dólares</span>
                </mat-radio-button>
                <mat-radio-button class="radio-button" [value]="'ggsa1888'" [disabled]="isPriceIn('ARS')"
                (click)="setIsDolar(false)" (keyup)="setIsDolarEvent(false,$event)" [checked]="elementSelected=='ARS'" >
                    <span class="radio" [ngClass]="{'selected-radio': elementSelected=='ARS'}">Pesos</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>