import { Component, OnChanges, OnInit } from '@angular/core';
import { isDeliveryAtGrassiPlants } from 'src/app/shared/business-particularities';
import { AMOUNT_VOID, GRASSI_PLANTS_VOID } from 'src/app/shared/const/business-particularities';
import { BusinessParticularities, GrassiPlants } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { Field } from '../../../fields.component';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-entrega-plantas-grassi',
  templateUrl: './entrega-plantas-grassi.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class EntregaPlantasGrassiComponent extends BusinessParticularityOption implements OnChanges {
  deliveriesAtGrassiPlant: GrassiPlants = GRASSI_PLANTS_VOID
  isParity:boolean=false;
  isVolatileWaste: boolean=false;
  isCommission:boolean=false;
  isFreight:boolean=false;
  isHumidity:boolean=false;
  constructor(private validator:ValidatorChecker) {
    super()
  }

  ngOnChanges(): void {
    try{
      this.deliveriesAtGrassiPlant=this.businessParticularitiesStruct.deliveriesAtGrassiPlant;
    } catch(err){
      this.deliveriesAtGrassiPlant=GRASSI_PLANTS_VOID;
    }
  }

  setDeliveriesAtGrassiPlant(deliveriesAtGrassiPlant: GrassiPlants) {
    this.deliveriesAtGrassiPlant = deliveriesAtGrassiPlant;
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct));
    newBP.deliveriesAtGrassiPlant=deliveriesAtGrassiPlant;
    this.emit2(newBP);
  }

  getDeliveriesAtGrassiPlant() {
    let deliveryAux= JSON.parse(JSON.stringify(this.businessParticularitiesStruct));
    return deliveryAux.deliveriesAtGrassiPlant;
  }

  setParity(event:any){
    let deliveryAux= JSON.parse(JSON.stringify(this.deliveriesAtGrassiPlant));
    deliveryAux.parity=JSON.parse(event);
    this.setDeliveriesAtGrassiPlant(deliveryAux);
  }

  setIsParity(){
    this.isParity=!this.isParity;
    if(!this.isParity){
      let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct));
      newBP.deliveriesAtGrassiPlant.parity=AMOUNT_VOID;
      this.setDeliveriesAtGrassiPlant(newBP.deliveriesAtGrassiPlant);
    }
  }

  setIsVolatileWaste(){
    this.isVolatileWaste=!this.isVolatileWaste;
  }

  setIsCommission(){
    this.isCommission=!this.isCommission;
  }

  setIsFreight(){
    this.isFreight=!this.isFreight;
  }

  setIsHumidity(){
    this.isHumidity=!this.isHumidity;
  }

  /* SET PERCENTAGE */
  setPercentage(event:any, tag:string){
    let deliveryAux= JSON.parse(JSON.stringify(this.deliveriesAtGrassiPlant));
    deliveryAux[tag].percentage=this.validator.validatorPercentage(event);
    this.setDeliveriesAtGrassiPlant(deliveryAux);
  }

  /* Set description */
  setDescription(event:any, tag:string, subTag:string){
    let deliveryAux= JSON.parse(JSON.stringify(this.deliveriesAtGrassiPlant));
    deliveryAux[tag][subTag]=event;
    this.setDeliveriesAtGrassiPlant(deliveryAux);
  }

  /* SET AMOUNT */
  setAmount(event:any, tag:string){
    let deliveryAux= JSON.parse(JSON.stringify(this.deliveriesAtGrassiPlant));
    deliveryAux[tag].amount=JSON.parse(event);
    this.setDeliveriesAtGrassiPlant(deliveryAux)
  }

  /* COMMISSION */
  setIsHoard(boolean:boolean){
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.deliveriesAtGrassiPlant.commission.isHoard=this.boolToNumber(boolean);
    this.setDeliveriesAtGrassiPlant(newBP.deliveriesAtGrassiPlant);
  }


  /* HUMIDITY */
  setIsPortFee(boolean:boolean){
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct));
    newBP.deliveriesAtGrassiPlant.humidity.isPortFee=this.boolToNumber(boolean);
    this.setDeliveriesAtGrassiPlant(newBP.deliveriesAtGrassiPlant);
  }

  setReduction(event:any, tag:string){
    let deliveryAux= JSON.parse(JSON.stringify(this.deliveriesAtGrassiPlant));
    (deliveryAux.humidity)[tag]=this.validator.validatorNumber(event);
    this.setDeliveriesAtGrassiPlant(deliveryAux);
  }

  isEnabled(){
    return isDeliveryAtGrassiPlants(this.deliveriesAtGrassiPlant)
  }

}
