import { Component, Input,OnInit,OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Mod, PlaceOfDelivery, Price } from 'src/app/shared/interfaces/price.interface';
import * as dict from 'src/app/shared/dict/dict';
import { Order, Restriction } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { provinces } from 'src/app/shared/dict/provinces/provinces';
import { OrderValidator, isNutrienCuit } from 'src/app/shared/order';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { buyers } from 'src/app/shared/dict/buyers';
import { orderCategory, orderStates } from 'src/app/shared/dict/orders';
import { PriceValidator, getValueOfMod } from 'src/app/shared/price';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { Exchange, OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { SellerObject, sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { catchError, Subscription } from 'rxjs';
import { areas } from 'src/app/shared/const/user.const';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { ToFixValidator, priceHaveIndicators } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { getFirstTextOfParticularities, getFirstTextOfQuotas, getFirstTextOfRestriction } from '../data/data.component';
import { ExchangeService } from 'src/app/shared/services/exchange.service';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';

const URL_DEFAULT='./../../../../../assets/images/products/default.svg';

@Component({
  selector: 'app-details-small',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DetailsSmallComponent implements OnInit, OnDestroy{
  //If the price is exporter, more fields are displayed.
  //*ngIf are performed based on the value of isPort.
  @Input() isPort!: boolean;

  @Output() goTo= new EventEmitter<boolean>();

  private user!: User; //Store of Redux.
  isOperator!: boolean; //Store of Redux.

  @Input() order!: any;
  messageLoading:string='';
  orderExchangeData!:OrderExchange; //Data of exchange in order.
  infoExchangeSecondary:boolean=false;

  @Input() price!: Price;
  @Input() isToFix:boolean=false;
  @Input() arrayMods:Mod[]=[]
  @Input() isExchange:boolean=false;
  @Input() isIndicatorsInThreeColumns:boolean=false; //If the indicators are displayed in three columns, we will use a different style.

  showSteps: boolean = true;

  businessParticularities: string = '';

  //---- OBJECTS ----\\
  AUX: any;
  SELLERS: SellerObject={};
  PLACE_OF_ORIGIN: any;
  BROKERS: any;
  HOW_SELL: any;
  ONLY_SELL: any;
  DONT_SELL: any;
  BUYERS: any;
  PROVINCE: any;
  LOCALITIES: any;
  public ORDER_TYPE_CATEGORY: any;
  public PRODUCTS: any;
  public BUSINESS_TYPE: any;
  public PLACE_OF_DELIVERY: any;
  public ZONES: any;
  public PECULIARITIES: any;
  public QUALITY: any;
  public COIN_TYPE: any;
  public WAY_TO_PAY: any;
  public HARVEST: any;

  //Only in small details:
  STATUS_PRICE: any;
  STATUS_ORDER: any;

  user$!:Subscription;

  constructor(private store: Store<appState>, private orderV:OrderValidator, 
    private priceV:PriceValidator, private orderExcV:OrderExchangeValidator, private toFixV:ToFixValidator,
    private exchangeSvc:ExchangeService, private cdr:ChangeDetectorRef) {
    this.setArrays();
    this.STATUS_PRICE= dict.priceState;
    this.STATUS_ORDER= orderStates;
  }

  ngOnInit(): void {
    this.user$=this.store.select('usuario').subscribe(user => {
      this.user = user;
      this.isOperator = user.area==areas.commodities;
    })

    this.searchExchange();
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  onErrorImg(event: any) { 
    event.target.src = URL_DEFAULT
  }

  getNroBilling(order:Order){
    return this.orderV.getNroBilling(order);
  }

  isNutrien(order:Order){
    return isNutrienCuit(order?.sellerData?.cuit);
  }

  private setArrays() {
    this.SELLERS = sellerWithData;
    this.PLACE_OF_ORIGIN = provinces;
    this.BROKERS = dict.broker;
    this.HOW_SELL = dict.howSell;
    this.BUYERS = buyers;

    this.ORDER_TYPE_CATEGORY = orderCategory;
    this.BUSINESS_TYPE = typeBusiness;
    this.PRODUCTS = PRODUCT_NAMES;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.QUALITY = dict.quality;
    this.COIN_TYPE = dict.typeCoinsStr;;
    this.WAY_TO_PAY = wayPay;
  }

  //Recibe por parametro 
  changeInfoExchangeSecondary(){
    this.infoExchangeSecondary=!this.infoExchangeSecondary;

    //Si la variable es true, desplegamos hacia abajo la card, si es false, desplegamos hacia arriba.
    setTimeout( ()=> {
      this.goTo.emit(this.infoExchangeSecondary);
    }, 5)
  }

  getArrowDirectionInfoExchangeSecondary(){
    return getArrowDirectionByBool(this.infoExchangeSecondary);
  }

  changeShowSteps() {
    this.showSteps = !this.showSteps;
  }

  getArrowDirection(){
    return getArrowDirectionByBool(this.showSteps);
  }
  
  getNameOfSwitch(){
    if(this.isExchange && this.order){
      return 'order-exchange';
    } else if(this.order){
      return 'order';
    } else if(this.price){
      return 'price';
    }
    return 'loading'
  }

  //We analyze whether you are seeing the details of the order, 
  //if so: we analyze whether the order has a counterparty. 
  //If we have it, we will search for it in the database to later show it.
  searchExchange(){
    if(this.getNameOfSwitch() == 'order' && this.order?.idExchange && this.order.idExchange!='--'){
      this.messageLoading='Buscando contrapartida...';
      const id=this.order.idExchange.split('/')[0];

      this.exchangeSvc.getOrderById(id).pipe(
        catchError(
          (error) => {
            console.error(error);
            this.messageLoading='No se encontró la contrapartida.';
            this.cdr.detectChanges();
            return [];
          }
        )
      ).subscribe( (res:ResponseRequest) => {
        this.orderExchangeData=res.data; //orderE
        this.messageLoading='';
        this.cdr.detectChanges();
      });
    }
  }

  isOrderIncomplete(order:Order){
    return this.orderV.isOrderIncomplete(order);
  }

  conditionHaveIndicators(price:Price){
    return priceHaveIndicators(price);
  }

  getProductName(order:Order){
    return this.orderV.getProductName(order);
  }

  //Get from order
  getSellerName(order:Order){
    return getSellerName(order, this.orderV);
  }

  isSancor(order:Order){
    return this.orderV.isSancor(order?.sellerData?.codeS)
  }

  getCampus(order:Order){
    return this.orderV.getCampusSancor(order);
  }

  getBuyerName(order:Order){
    return this.orderV.getBuyerName(order);
  }

  getBuyerNameExchange(order:OrderExchange){
    return this.SELLERS[order?.exchange?.buyer?.codeS]?.nombre ?? '--'
  }

  getHowSellExchange(order:OrderExchange){
    return this.HOW_SELL[order?.exchange?.howSell] ?? '--'
  }

  getTonsExchange(order:OrderExchange):number{
    return order?.exchange?.tons > 0 ? order.exchange.tons: 0;
  }

  getPlaceOfOrigin(indexProvince:number, indexTown:number) {
    return this.orderV.getPlaceOfOrigin(indexProvince, indexTown);
  }

  getBuyer(buyer: Restriction) {
    return buyer
  }

  getRangeDateText(price:Price){
    return this.priceV.getRangeDateText(price?.deliveryPeriod);
  }

  isValidUntil(price:Price){
    return this.priceV.isValidUntil(price);
  }

  getValidUntilText(price:Price){
    return this.priceV.getValidUntilText(price);
  }

  getBuyerType(buyer:Restriction){
    return buyer?.notSell?.length>0 ? 'notSell' : 
    buyer?.onlySell?.length>0 ? 'onlySell' : ''
  }

  getWayPay(price:Price){
    return this.priceV.getWayPay(price);
  }

  isFixExpiration(price:Price){
    return this.priceV.isFixExpiration(price);
  }

  getPesification(price:Price){
    return this.priceV.getPesification(price);
  }

  getGrouperCode(price:Price){
    return this.priceV.getGrouperCode(price);
  }

  getObservations(order:Order){
    return this.orderV.getObservations(order);
  }

  getIndicators(price:Price){
    return price?.indicators ?? []
  }

  //If the identifier is 'order' we use orderV, if it is 'price' we use priceV
  getBusinessParticularities(identificator:string){
    if(identificator=='order'){
      return this.orderV.getBusinessParticularities(this.order.price.businessParticularities);
    } else if(identificator=='price'){
      return this.priceV.getBusinessParticularities(this.price.businessParticularities);
    }
  }

  //If the identifier is 'order' we use orderV, if it is 'price' we use priceV
  getQualityParticularities(identificator:string){
    if(identificator=='order'){
      //return this.orderV.getQualityParticularities(this.order.price.qualityParticularities);
      return this.orderV.getQualityIBM(this.order);
    } else if(identificator=='price'){
      //return this.priceV.getQualityParticularities(this.price.qualityParticularities);
      return this.priceV.getQualityIBM(this.price);
    }
  }

  getQuality(tag:string){
    if(tag=='order'){
      return this.orderV.getQuality(this.order); 
    } else if(tag=='price') {
      return this.priceV.getQuality(this.price);
    }
  }

  getNroOfContract(order:Order){
    return order?.contractNumber>0? order.contractNumber : '';
  }

  getNumber(number: number) {
    return number<=0 ? '--' : number;
  }

  //Si el objeto es de tipo Orden, lo pasamos por el validador de ordenes, si es de tipo precio, lo pasamos por el validador de precios.
  getPlaceOfDelivery(placeOfDelivery:PlaceOfDelivery){
    return this.orderV.getPlaceOfDelivery(placeOfDelivery);
  }

  getQuotas(order:Order){
    return this.orderV.getQuotas(order);
  }

  getIBMCode(price:Price){
    return this.priceV.getWayPayIBM(price);
  }

  getAgreedDiscount(order:OrderExchange){
    return order?.exchange?.agreedDiscount>0 ? order.exchange.agreedDiscount : '--';
  }

  getPriceDiscount(order:OrderExchange){
    return order?.exchange?.priceDiscount>0 ? order.exchange.priceDiscount : '--';
  }

  getPrice(order:OrderExchange){
    return order.exchange.price<=0?'--':order.exchange.price;
  }

  getTypeCoin(order:OrderExchange){
    const typeCoin:any=dict.typeCoinsStr;
    return typeCoin[order?.exchange?.typeCoin] ?? '--';
  }

  getBonification(exchange:Exchange){
    return this.orderExcV.getBonification(exchange);
  }

  //-------------- INDICATORS -----------------//

  getConditionType(price:Price){
    return this.toFixV.getConditionType(price?.conditionType);
  }

  getIndicatorFixedPeriod(indicator:Indicator){
    return this.toFixV.getFixedPeriod(indicator);
  }

  getIndicatorName(indicator:Indicator){
    return this.toFixV.getIndicatorNameSummary(indicator);
  }

  getIndicatorQuality(indicator:Indicator){
    return this.toFixV.getQuality(indicator);
  }
  
  getIndicatorPercentageAndProduct(indicator:Indicator){
    return this.toFixV.getPercentageAndProductName(indicator);
  }

  getIndicatorLocation(indicator:Indicator){
    return this.toFixV.getPlace(indicator);
  }

  getIndicatorMinAndMax(indicator:Indicator){
    return this.toFixV.getMinAndMax(indicator);
  }

  getIndicatorProporcionalityDetails(indicator:Indicator){
    return this.toFixV.getProporcionalityShort(indicator);
  }

  getIndicatorBonification(indicator:Indicator){
    return this.toFixV.getBonification(indicator);
  }

  getIndicatorReduction(indicator:Indicator){
    return this.toFixV.getReduction(indicator);
  }

  getIndicatorPosition(indicator:Indicator){
    return this.toFixV.getMonthAndYear(indicator);
  }

  //Si el valor para colocar en la card es '--' entonces aplicamos el display:none para que no ocupe espacio en vano.
  getStyle(dataContainer:any): { [key: string]: string } {
    const value=this.getValue(dataContainer?.title, dataContainer?.value, dataContainer?.object);
    const style: { [key: string]: string } = {};
    if (value=='--') {
      style['display'] = 'none';
    }
    return style;
  }
  
  //Obtenemos el valor que tienen en las cards de detalles
  getValue(title:string, value:any, object:any){
    switch(title){
      case 'Restricción': return getFirstTextOfRestriction(object);
      case 'Cupos': return getFirstTextOfQuotas(object);
      case 'Particularidad de negocio': return getFirstTextOfParticularities(object);
      default: return value;
    }
  }

  getPreviusValue(tag:string){
    try{
      const arrayModifieds=this.arrayMods;
      const value=getValueOfMod(arrayModifieds, tag);
      return value;
    } catch(err){
      return '';
    }
  }

  getPreviusValueIndicators(tag:string, index:number){
    return this.getPreviusValue('indicators.'+index+'.'+tag);
  }

}

export function getSellerName(order:Order, orderV:OrderValidator){
  try{
    const isSyngenta=orderV.isSyngenta(order.sellerData.codeS);
    if(isSyngenta){
      const subdivisionSyngenta=orderV.getSubdivisionSyngenta(order);
      return 'Syngenta - '+subdivisionSyngenta;
    }
    return orderV.getSellerName(order); 
  } catch(err){
    return '--'
  }
}