import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { canBuyCommoditiesInProvince, canBuyHughesInProvince, canYouBuyCommodities, canYouBuyHughes } from 'src/app/shared/business-validation';
import { COMMODITIES_CODE, HUGHES_CODE_BUYER } from 'src/app/shared/const/buyers';
import { orderTags } from 'src/app/shared/const/orders';
import { buyers } from 'src/app/shared/dict/buyers';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { getProvinceOfPlaceOfDelivery, PriceValidator } from 'src/app/shared/price';
import { Field } from '../../fields.component';
import { isCodeCommoditiesOrHughesBuyer, isSellerCommoditiesOrHughes } from 'src/app/shared/order';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DEFAULT_WAY_PAY_WITH_COMMODITIES_HUGHES } from 'src/app/shared/const/wayPay';

@Component({
  selector: 'app-only-sell',
  templateUrl: './only-sell.component.html',
  styleUrls: ['./../../fields.component.scss']
})
export class OnlySellComponent extends Field implements OnInit {
  arrayOfSelected: any[] = [];

  constructor(store: Store<appState>, private bussPartValidator:BusinessParticularitiesValidator, private priceV:PriceValidator) {
    super(store);
    this.array = Object.keys(buyers);
    //this.array.shift();
    this.OBJECT = buyers;
    this.deleteLastValueInArray();

    this.elementSelected = '';
    this.initalElementSelected = 'Ingresar una restricción';
    
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    this.tag = orderTags.buyer;
    this.subTag = orderTags.onlySell;
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    if(this.subTag==orderTags.onlySell){
      this.rememberValueBuyer()
    }
  }
  
  override setElement(element: any, index: number) {
    //Reseteamos el "no le vende a" si no es Gravanz;
    if(! this.bussPartValidator.getIsGravanz(this.elementToGenerate.price.businessParticularities)){
      this.subTag=orderTags.notSell;
      this.setElementToGenerate(-1);

      this.subTag=orderTags.onlySell;
      this.setElementToGenerate(Number(element));
    }
  }

  setWayPay(buyer: number){
    const sellerCode= this.elementToGenerate.sellerData.codeS;

    if(isCodeCommoditiesOrHughesBuyer(buyer) && isSellerCommoditiesOrHughes(sellerCode)){
      this.tag=orderTags.price;
      this.subTag=priceTags.wayPay;
      this.thirdTag='';

      this.setElementToGenerate(DEFAULT_WAY_PAY_WITH_COMMODITIES_HUGHES);

      this.tag=orderTags.buyer;
      this.subTag=orderTags.onlySell;
    }
  }

  addOrRemoveBuyer(element: any, inputName:any=null) {
    /*if(! this.bussPartValidator.getIsGravanz(this.elementToGenerate.price.businessParticularities)){
      this.subTag=orderTags.notSell;
      this.setElementToGenerate([]);

      this.subTag=orderTags.onlySell;
      this.setElementToGenerate([Number(element)]);
    }*/      
    this.subTag=orderTags.notSell;
    this.setElementToGenerate([]);
    const elementNumber=Number(element)
    if (this.arrayOfSelected.includes(elementNumber)) {
      this.arrayOfSelected.splice(this.arrayOfSelected.indexOf(elementNumber), 1);
    } else {
      this.arrayOfSelected.push(elementNumber);
    }
    
    this.subTag=orderTags.onlySell;
    this.setElementToGenerate(this.arrayOfSelected);

    this.setWayPay(Number(element));

    this.search='';
    this.placeHolder='';
    if(inputName){
      inputName.focus();
    }
  }

  rememberValueBuyer() {
    try{
      this.subTag='';
      let valueToSet: any = this.rememberValue(); //Recordamos el valor de {onlySell:.. , notSell:..}

      if (valueToSet.onlySell.length>0 && valueToSet.notSell.length==0) { //If only sell to a buyer.
        if(valueToSet.onlySell.length==1){
          this.elementSelected = this.OBJECT[valueToSet.onlySell[0]];
        } else {
          this.elementSelected = valueToSet.onlySell.length + ' compradores seleccionados';
        }
        this.arrayOfSelected=JSON.parse(JSON.stringify(valueToSet.onlySell))
        this.setIsOptionSelected(true);
        this.isInvalid=isAnyInvalidBuyer(valueToSet.onlySell, this.elementToGenerate);
      } else if(valueToSet.onlySell.length <= 0){
        this.elementSelected = '';
        this.search='';
        this.placeHolder='';
        this.arrayOfSelected=[];
        this.isInvalid=false;
        this.setIsOptionSelected(false);
      }

      this.subTag=orderTags.onlySell;
    } catch(err){}
  }

  getValue(){
    return this.isFocus? this.search : this.elementSelected;
  }

  includesElement(element:any){
    return this.arrayOfSelected.includes(Number(element))
  }

  override save() {
    if(this.search.length > 0){
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT,'');
      if (newList.length > 0) {
        this.addOrRemoveBuyer(newList[0]);
      }

      this.search=''
      this.setPlaceHolder();
    }
  }

}

export function isAnyInvalidBuyer(buyers:number[], order:Order){
  try{
    if(buyers?.length>0){
      for(let buyer of buyers){
        if(isInvalidBuyer(buyer,order)){
          throw new Error()
        }
      }
    }
    return false;
  } catch(err){
    return true;
  }
}

function  isInvalidBuyer(buyer:number, order:Order){
    const provincePlaceOfDelivery= getProvinceOfPlaceOfDelivery(order.price.placeOfDelivery);
    if(buyer==COMMODITIES_CODE){
      return !canYouBuyCommodities(order) || ( (provincePlaceOfDelivery<=0)? false: !canBuyCommoditiesInProvince(provincePlaceOfDelivery));
    } else if(buyer==HUGHES_CODE_BUYER){ 
      return !canYouBuyHughes(order) || ( (provincePlaceOfDelivery<=0)? false: !canBuyHughesInProvince(provincePlaceOfDelivery));
    }
    return false;
}