import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntregaPlantasGrassiComponent } from './entrega-plantas-grassi.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { AmountModule } from '../../amount/amount.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { TextInputModule } from 'src/app/components/inputs/text/text.module';



@NgModule({
  declarations: [
    EntregaPlantasGrassiComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    AmountModule,
    NumberInputModule,
    TextInputModule
  ], exports:[
    EntregaPlantasGrassiComponent
  ]
})
export class EntregaPlantasGrassiModule { }
