import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-order',
  templateUrl: './button-order.component.html',
  styleUrls: ['./button-order.component.scss']
})
export class ButtonOrderComponent {
  //Text represents the text that is displayed 
  //on the screen when the component is created
  @Input() text: string="";

  //Image represents the name of the image to be searched 
  //for in the path: './assets/images/orden-venta'
  @Input() image: string="";

  //IsCommodities is a boolean that assigns the name of a class.
  //With that class we can give styles (corresponding when they are commodities)
  @Input() isComodities:boolean=false;

  @Input() isDisabled:boolean=false;

  constructor() { }
}