import { Component, Input, OnChanges } from '@angular/core';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { CONDITIONAL_VOID } from 'src/app/shared/const/business-particularities';
import { DateChecker } from 'src/app/shared/date.checker';
import { BusinessParticularities, Conditional } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-condicional',
  templateUrl: './condicional.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class CondicionalComponent extends BusinessParticularityOption implements OnChanges {
  conditional:Conditional=CONDITIONAL_VOID;
  @Input() strikeDefault:number=-1;

  constructor(private date:DateChecker, private validator:ValidatorChecker, private bussPartV:BusinessParticularitiesValidator) {
    super()
  }

  ngOnChanges(): void {
    try{
      this.conditional=this.businessParticularitiesStruct.conditional;
    } catch(err){
      this.conditional=CONDITIONAL_VOID;
    }
  }

  setConditional(conditional:Conditional){
    this.conditional=conditional;
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct));
    newBP.conditional=this.conditional;
    this.emit2(newBP);
  }

  verifyConditional(conditional:any){
    if(JSON.stringify(conditional) != JSON.stringify(CONDITIONAL_VOID)){
      this.setConditional(CONDITIONAL_VOID); //Reset conditional
    }
  }

  setConditionalType(conditionalType:number){
    let conditional:Conditional=JSON.parse(JSON.stringify(this.conditional));
    conditional.selection=conditionalType;
    this.setConditional(conditional);
  }

  setDate(event:any){
    let conditional:Conditional=JSON.parse(JSON.stringify(this.conditional));
    conditional.position=event;
    this.setConditional(conditional);
  }

  setStrike(event:any){
    let conditional:Conditional=JSON.parse(JSON.stringify(this.conditional));
    conditional.strike=this.validator.validatorNumber(event);
    this.setConditional(conditional);
  }

  getIsConditional(){
    return this.bussPartV.getIsConditional(this.businessParticularitiesStruct);
  }

  override setOptionOpenWithCheck(event:any, selectedIndex:number=0){
    super.setOptionOpenWithCheck(event, selectedIndex);

    if(this.optionOpen=='down'){
      this.setStrike(this.strikeDefault);
    } else {
      this.setStrike(-1);
    }
  }

  
}
