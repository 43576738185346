import { Component, OnDestroy, OnInit,ViewChild, ElementRef, Renderer2,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {setArrayOfBuyers,setPricesSelected, setPriceToGenerate} from 'src/app/redux/actions/price.action';
import * as dict from 'src/app/shared/dict/dict';
import { priceTags,typeCoins,} from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { DateChecker, isEarlierDate, isValidDate } from 'src/app/shared/date.checker';
import { ValidatorChecker, haveLetter } from 'src/app/shared/validator.checker';
import { PriceValidator, selectPrice } from 'src/app/shared/price';
import { priceToWriteObservation, setIsGenerateToFix, setShowDeletePrice, setShowErrorChangePrice, setShowWarningChangeTypeCoin } from 'src/app/redux/actions/options.action';
import { PlaceHolderPipe } from 'src/app/shared/pipes/placeholder.pipe';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { businessDict } from 'src/app/shared/dict/typeBusiness';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogObservationPrice } from 'src/app/components/dialogs/observation-price/observation-price';
import {ToFixValidator, priceHaveIndicators} from 'src/app/shared/to-fix';
import { getWayPayDefault, getWayPaysValids } from 'src/app/shared/way-pay';
import { goesStripper } from 'src/app/components/inputs/date/date.component';
import { OPTION } from 'src/app/components/board/options/options.component';
import { PriceBoard } from 'src/app/components/board/price/price-board';

const quantMaxOfLetterDate=10;

@Component({
  selector: 'app-price-table-commodities',
  templateUrl: './price-table-commodities.component.html',
  styleUrls: [
    '../price-table.component.scss',
    './../../../../../shared/styles/board.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class PriceTableCommoditiesComponent extends PriceBoard implements OnInit, OnDestroy{
  public deferral:number=-1;

  public firstPart:string='';
  public placeHolder:string='';
  public pipePlaceHolder:any;

  @ViewChild('tableDesktop') tableDesktop!: ElementRef;
  @ViewChild('scrollFalso') scrollFalso!: ElementRef;

  constructor(
    store: Store<appState>,
    router: Router,
    date: DateChecker,
    private validator: ValidatorChecker,
    priceV: PriceValidator,
    toFixV:ToFixValidator,
    private dialog: MatDialog,
    private renderer: Renderer2,
    cdr: ChangeDetectorRef
  ) {
    super(store, router, date,priceV, toFixV,cdr);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.pipePlaceHolder=new PlaceHolderPipe();
  }


  onScroll(){
    const scrollTop = this.tableDesktop.nativeElement.scrollTop;
    this.renderer.setStyle(this.scrollFalso.nativeElement, 'transform', `translateY(-${scrollTop}px)`);
  }

  override ngOnDestroy(){
    super.ngOnDestroy();
  }
  
  //-----------------------------------------------\\
  emitAction(action:string){
    switch(action){
      case OPTION.SELECT_ALL: this.checkedAllItems(); break;
    }
  }

  //---------- CHECKED, SELECT AND FILTER ----------\\
  checkedAllItems() {
    this.store.dispatch(setPricesSelected({ prices: this.getCheckedAll()? []:this.pricesWithPipe }));
  }

  getCheckedAll(){
    return JSON.stringify(this.pricesWithPipe) == JSON.stringify(this.pricesSelected);
  }

  selectPrice(price: Price) {
    selectPrice(price, this.pricesSelected, this.store);
  }

  resetSelectedPriceList() {
    this.store.dispatch(setPricesSelected({ prices: [] }));
  }

  isPriceInPriceList(price: Price) {
    return this.priceV.isPriceInPriceList(price, this.pricesSelected);
  }
  
  //-----------------------------------------------\\

  editPriceWithSubTag(price: any, tag: string, subtag: string, value: any) {
    let priceToSave = JSON.parse(JSON.stringify(price));
    priceToSave[tag][subtag] = value;
    priceToSave.hour = this.date.getHour();

    //this.store.dispatch(editPrice({price:priceToSave,field:'', value:''}))
    this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    this.priceV.editPrice(priceToSave, tag+'.'+subtag, value, this.user)
    this.priceV.editPrice(priceToSave, priceTags.dataOfcreation + '.' + priceTags.hour, this.date.getHour(),this.user);
    this.resetSelectedPriceList();
  }

  openDialogToWriting(price:Price){
    //Si está prendido, lo cerramos.
    if(price.observations.isMessage==1){
      const fieldIsMessage=priceTags.observations+'.'+priceTags.isMessage;
      this.priceV.editPrice(price, fieldIsMessage, -1, this.user);
    } else {
      this.store.dispatch(priceToWriteObservation({price:price}));
      this.dialog.open(DialogObservationPrice);
    }
  }

  editPriceWithSubTagEvent(price: any,tag: string,subtag: string,event: any) {
    this.editPriceWithSubTag(price, tag, subtag, event?.target.value);
  }

  //-------- OPTIONS --------\\

  //Create new price
  duplicatePrice(price: Price) {
    this.priceV.duplicatePrice(price, this.user);
    this.closeOptions();
  }

  editPrice(price:Price){
    if(price.observations.isPort==1){
      let array= price.observations.buyer > 0 ? [price.observations.buyer] : [];
      this.store.dispatch(setArrayOfBuyers({buyers: array}))
    }
    this.store.dispatch(setIsGenerateToFix({isGenerateToFix: priceHaveIndicators(price)}))
    this.store.dispatch(setPriceToGenerate({price:price}));
    this.closeOptions();
    
    this.router.navigate(['/' + NAVEGATION.GENERATE_PRICE]);
  }

  createDeferral(index:number){
    this.deferral=index;
  }

  emitActionDeferral(action: string, index:number){
    if(action=='open'){
      this.optionIdentificator = index;
      this.wasInside = true;
      this.createDeferral(index);
    } else if(action=='close'){
      this.closeDeferral();
    }
  }

  override closeDeferral(){
    this.deferral=-1;
  }

  deletePrice(price: Price) {
    this.store.dispatch(setShowDeletePrice({ isShow: true, idPrice: price._id }));
  }

  //setPlaceHolder
  setPlaceHolder(event: any, object:any, input:any,identificator: string){
    if(identificator=='placeOfDelivery'){
      object=this.pipePlaceHolder.transformPlaceOfDelivery(object);
    }
    
    if(event.key.toLowerCase() == 'enter'){
      input.textContent = this.pipePlaceHolder.getFirstElementOfObject(input.textContent.toLowerCase(), object);
      input.blur();
      this.resetPlaceHolder();
    } else {
      const value=input.textContent;
      if(value){
        this.firstPart=value;
        this.placeHolder=this.pipePlaceHolder.transform(value, object);
      } else {
        this.resetPlaceHolder()
      }
    }
  }

  resetPlaceHolder(){
    this.firstPart='';
    this.placeHolder='';
  }

  setProductName(span:any,priceToSave:Price){
    const object:any=PRODUCT_NAMES;
    let keys:any[]=Object.keys(object);
    let indexToChange = -1;
    try {
      for (let i = 0; i < keys.length; i++) {
        if (object[keys[i]].toLowerCase() == span.textContent.toLowerCase().trim()) {
          indexToChange = Number(keys[i]);
          throw new Error();
        }
      }
    } catch (err) {}
    
    let newPrice = JSON.parse(JSON.stringify(priceToSave));
    let tag=priceTags.productName;
    if (indexToChange != -1 && newPrice[tag]!=indexToChange && this.priceV.isQualityValid(indexToChange, newPrice.quality) ) {
      newPrice[tag] = indexToChange;
      this.priceV.editPrice(newPrice, tag, indexToChange,this.user);
      this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    } else if(indexToChange>0 && !this.priceV.isQualityValid(indexToChange, newPrice.quality)){
      this.refreshPricesWithInvalidData(span, this.getProductName(priceToSave), 'No posee calidad válida.');
    } else if(newPrice[tag]==indexToChange){
      this.refreshPricesWithInvalidData(span, this.getProductName(priceToSave), 'No se ha modificado el valor');
    } else {
      this.refreshPricesWithInvalidData(span, this.getProductName(priceToSave));
    }

    this.resetPlaceHolder();
  }

  setTagSpan(span: any, priceToSave: Price, tag: string, object: any) {
    let keys:any[]=Object.keys(object);
    let indexToChange = -1;
    try {
      for (let i = 0; i < keys.length; i++) {
        if (object[keys[i]].toLowerCase() == span.textContent.toLowerCase().trim()) {
          indexToChange = Number(keys[i]);
          throw new Error();
        }
      }
    } catch (err) {}

    let newPrice = JSON.parse(JSON.stringify(priceToSave));
    if (indexToChange != -1 && newPrice[tag]!=indexToChange) {
      newPrice[tag] = indexToChange;
      this.priceV.editPrice(newPrice, tag, indexToChange,this.user);
      this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    } else if(newPrice[tag]==indexToChange){
      this.refreshPricesWithInvalidData(span, object[newPrice[tag]], 'No se ha modificado el valor');
    } else {
      this.refreshPricesWithInvalidData(span, object[newPrice[tag]]);
    }

    this.resetPlaceHolder();
  }

  //EDIT PRICE
  setPrice(priceToSave: Price, span: any) {
    let isError: boolean = false;
    let typeCoin: string = '';
    let priceValue: any = '';
    
    try {
      let newPrice = span.textContent.trim().split(' ');
      if(newPrice[0]=='') {newPrice.shift()}
      
      //Si hay mas de dos elementos
      if (newPrice.length > 2 || newPrice.length == 0) {
        throw new Error();
      } else if (newPrice.length == 2) {
        //If you have two elements, the first is the type of currency and the second is the price
        typeCoin = newPrice[0];
        if (['US$', 'USD', 'D'].includes(typeCoin.toUpperCase())) {
          typeCoin = typeCoins.USD;
        } else if (['$', 'ARS', 'P'].includes(typeCoin.toUpperCase())){
          typeCoin= typeCoins.ARS;
        } else {
          throw new Error();
        }
        priceValue = newPrice[1];
        if (haveLetter(priceValue)) throw new Error();
        priceValue= this.validator.validatorNumber(priceValue);
      } else if (newPrice.length==1){
        //If you have one element, the first is the price and the type of currency is the default
        priceValue = newPrice[0];
        if (haveLetter(priceValue)) throw new Error();
        priceValue= this.validator.validatorNumber(priceValue);
        typeCoin = this.priceV.getTypeCoinDefault(priceValue);

      }
    } catch (err) {
      isError = true;
    }

    if (!isError) {
      let newPrice = JSON.parse(JSON.stringify(priceToSave));
      if(this.priceV.isPriceVariance(newPrice.price, priceValue) && priceToSave.typeCoin==typeCoin){
        //If the variation is greater than 3% and it is the same type of currency, we show the error.
        this.refreshPricesWithInvalidData(span, this.getTypeCoinAndPrice(priceToSave));
        this.store.dispatch(setShowErrorChangePrice({showErrorChangePrice: true}));
      } else if(priceToSave.price==priceValue && priceToSave.typeCoin==typeCoin){
        this.refreshPricesWithInvalidData(span, this.getTypeCoinAndPrice(priceToSave), 'No se ha modificado el valor.');
      } else {
        newPrice.typeCoin = typeCoin;
        newPrice.price= priceValue;

        if(priceToSave.typeCoin==typeCoin){
          //Is not change the typeCoin
          this.priceV.editPrice(newPrice, priceTags.typeCoin, typeCoin,this.user);
          this.priceV.editPrice(newPrice, priceTags.price, priceValue,this.user);
          this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
        } else {
          //Is change the typeCoin
          this.store.dispatch(setShowWarningChangeTypeCoin({price:newPrice, isShow:true}));
        }
      } 
    } else {
      this.refreshPricesWithInvalidData(span,this.getTypeCoinAndPrice(priceToSave));
    }
  }

  setPlaceOfDelivery(span: any, priceToSave: Price) {
    let array: any[] = Object.values(dict.puertos);
    let indexToChange = -1;
    try {
      for (let i = 0; i < array.length; i++) {
        if (array[i] != '--') {
          if (array[i].name.toLowerCase() == span.textContent.toLowerCase()) {
            indexToChange = i;
            throw new Error();
          }
        }
      }
    } catch (err) {}

    let newPrice = JSON.parse(JSON.stringify(priceToSave));
    if (indexToChange != -1 && newPrice.placeOfDelivery.zone!=indexToChange) {
      newPrice[priceTags.placeOfDelivery][priceTags.zone] = indexToChange;
      this.priceV.editPrice(newPrice, priceTags.placeOfDelivery + "." +priceTags.zone , indexToChange,this.user);
      newPrice[priceTags.placeOfDelivery][priceTags.port] = -1;
      this.priceV.editPrice(newPrice, priceTags.placeOfDelivery + "." +priceTags.port , -1,this.user);
      this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    } else if(newPrice.placeOfDelivery.zone==indexToChange){
      this.refreshPricesWithInvalidData(span, this.getPlaceOfDelivery(priceToSave), 'No se han realizado cambios');
    }else {      
      this.refreshPricesWithInvalidData(span, this.getPlaceOfDelivery(priceToSave));
    }

    this.resetPlaceHolder();
  }

  setDeliveryPeriod(span: any, priceToSave: Price) {
    let isError: boolean = false;
    let firstDate:string='';
    let secondDate:string='';
    try {
      const dateSplit = span.textContent.split('/');
      firstDate= isValidDate(dateSplit[0]);
      secondDate = '';
      if (dateSplit.length < 2 && firstDate!='--') {
        if( businessDict.forward == priceToSave.typeBusiness && this.date.stringToDate(firstDate).getDate() == 1){
          //Si es foward, y la fecha es primero, calcular desde inicio de mes hasta fin. Sino 30 días
          secondDate= this.date.getDateEndOfMonth(firstDate);
        } else {
          secondDate=this.date.addDaysToDate(firstDate, 30);
        }
      } else {
        secondDate=dateSplit[1]
      }

      secondDate= isValidDate(secondDate);
      if (firstDate == '--' || secondDate == '--') {throw new Error()};

      if (isEarlierDate(secondDate, firstDate, '-')) {throw new Error()};

    } catch (err) {
      isError = true;
    }

    if (!isError) {
      let newPrice = JSON.parse(JSON.stringify(priceToSave));
      newPrice[priceTags.deliveryPeriod].startDate = firstDate;
      newPrice[priceTags.deliveryPeriod].endDate = secondDate;
      this.priceV.editPrice(newPrice, priceTags.deliveryPeriod + "." +priceTags.startDate , firstDate,this.user);
      this.priceV.editPrice(newPrice, priceTags.deliveryPeriod + "." +priceTags.endDate , secondDate,this.user);
      this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    } else {
      this.refreshPricesWithInvalidData(span, this.getDeliveryPeriod(priceToSave));
    }
  }

  setWayPay(span: any, priceToSave: Price) {
    let array: number[] = getWayPaysValids(priceToSave);
    let indexToChange: number = -1;
    try {
      for (let i = 0; i < array.length; i++) {
        if (this.WAY_PAY[array[i]].toLowerCase() ==span.textContent.toLowerCase()) {
          indexToChange = array[i];
          throw new Error();
        }
      }
    } catch (err) {}

    const wayPayDefault=getWayPayDefault(priceToSave, indexToChange);

    let newPrice = JSON.parse(JSON.stringify(priceToSave));
    if (indexToChange != -1 && newPrice.wayPay.wayPayName!=indexToChange ) {
      newPrice.wayPay = wayPayDefault;
      this.priceV.editPrice(newPrice, priceTags.wayPay, wayPayDefault,this.user);
      this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    } else if(newPrice.wayPay.wayPayName==indexToChange){
      this.refreshPricesWithInvalidData(span, this.getOnlyWayPayName(priceToSave), 'No se ha modificado el valor.');
    } else {
      this.refreshPricesWithInvalidData(span, this.getOnlyWayPayName(priceToSave));
    }

    this.resetPlaceHolder();
  }

  setExpiration(priceToSave:Price, span:any){
      let newPrice=JSON.parse(JSON.stringify(priceToSave));
      const dateParsed=isValidDate(span.textContent);
      if(dateParsed){
        if(newPrice.wayPay.expiration == dateParsed){
          this.refreshPricesWithInvalidData(span, this.getExpiration(priceToSave),'No se ha modificado el valor.');
        } else {
          newPrice.wayPay.expiration = dateParsed;
          this.priceV.editPrice(newPrice, priceTags.wayPay + '.' + priceTags.expiration, dateParsed,this.user);
          this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
        }
      } else {
        this.refreshPricesWithInvalidData(span, this.getExpiration(priceToSave));
      }
  }

  setPesification(priceToSave:Price, span:any){
    let isError:boolean=false;
    let firstDate:string='';
    let secondDate:string='';
    try{
      const dateSplit=span.textContent.split('/');
      
      //Seteamos la primera fecha
      firstDate= isValidDate(dateSplit[0]);

      //Seteamos la segunda fecha dependiendo si se puso o no.
      secondDate = dateSplit.length<2 && firstDate!='--' ? 
      this.date.addDaysToDate(firstDate, 30): 
      dateSplit[1];
      
      secondDate= isValidDate(secondDate);
      if (firstDate == '--' || secondDate == '--') {throw new Error()};
      if (isEarlierDate(secondDate, firstDate, '-')) {throw new Error()};
    } catch(err){
      isError=true;
    }

    if (!isError) {
      let newPrice = JSON.parse(JSON.stringify(priceToSave));
      newPrice[priceTags.pesificacion].startDate = firstDate;
      newPrice[priceTags.pesificacion].endDate = secondDate;
      this.priceV.editPrice(newPrice, priceTags.pesificacion + "." +priceTags.startDate , firstDate,this.user);
      this.priceV.editPrice(newPrice, priceTags.pesificacion + "." +priceTags.endDate , secondDate,this.user);
      this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
    } else {
      this.refreshPricesWithInvalidData(span, this.getPesification(priceToSave));
    }
  }

  setHarvest(span: any, priceToSave: Price, tag: string) {
    let newHarvest: string = '';
    let newHarvestAux: string = span.textContent;
    let isError: boolean = false;
    let firstNumbers = '';
    let secondNumbers = '';
    try {
      //Borramos espacios
      newHarvest = this.validator.deleteSpaces(newHarvestAux);

      //Verificamos que la tag tenga 9 de largo
      if (newHarvest.length != 9) {
        throw new Error();
      }

      //Tomamos los primeros 4 digitos (representando en año)
      firstNumbers = newHarvest.substring(0, 4);

      //Verificamos que los cuatro sean numeros
      if (haveLetter(firstNumbers)) throw new Error();

      //Verificamos que el tercer item sea un '-' o '/'
      if (newHarvest[4] != '-' && newHarvest[4] != '/') {
        throw new Error();
      }

      //Tomamos los ultimos 4 digitos.
      secondNumbers = newHarvest.substring(5, newHarvest.length);

      //Verificamos que los cuatro sean numeros
      if (haveLetter(secondNumbers)) throw new Error();

      //Verificamos que los primeros cuatro numeros sea ANTERIOR a los segundos 4;
      if (Number(firstNumbers) + 1 != Number(secondNumbers)) throw new Error();
    } catch (err) {
      isError = true;
    }

    if (!isError) {
      const harvest = firstNumbers + ' - ' + secondNumbers;
      if(priceToSave.harvest!=harvest){
        let newPrice = JSON.parse(JSON.stringify(priceToSave));
        newPrice[tag] = harvest;
        this.priceV.editPrice(newPrice, priceTags.harvest, harvest,this.user)
  
        this.priceV.actualizateHour(priceToSave, this.date.getHour(), this.user);
      }
    } else {
      this.refreshPricesWithInvalidData(span, priceToSave.harvest)
    }
  }

  //Dado un span, un texto y un texto para un modal
  //Pone en el span el texto y en el modal el texto para el modal.
  //Suele usarse para cuando se cargó mal un dato y colocar el que estaba antes
  private refreshPricesWithInvalidData(span:any, textContent:any, textModal:string='Dato no valido.'){
    span.textContent = textContent;
    // Luego, forzar la renderización manual
    this.priceV.showModalWithMessage(textModal);
  }

  blurInput(input:any, eventKey:any){
    if(eventKey.key.toLowerCase() == 'enter' && this.placeHolder==''){
      const string= input.textContent;
      input.blur();
      this.resetPlaceHolder();
    } 
  }

  notLinePlus(eventKey:any){
    if(eventKey.key.toLowerCase() == 'enter' && this.placeHolder==''){
      return false;
    } 
  }

  checkKeyPressForDate(event:any, input: any, isTwoDates:boolean=false) {
      const key:string= event.key.toLowerCase();
      const quantMaxOfLetter = isTwoDates? quantMaxOfLetterDate*2+3:quantMaxOfLetterDate;
      if(input.textContent.length>=quantMaxOfLetter){
        return false;
      }
      return key=='enter' || key=='tab' || key=='backspace' || '0123456789'.includes(key);
  }

  setInputDate(event: any, input: any, isTwoDates:boolean=false){  
    const key:string= event.key.toLowerCase();
    if('0123456789'.includes(key)){

      if(isTwoDates){
        const twoDates= input?.textContent?.split('/');
        const firstDateSplit= twoDates[0]?.split('-');

        //Si se puede poner un guion en la primer fecha o la segunda, se pone
        if( goesStripper(twoDates[0]?.trim()) || goesStripper(twoDates[1]?.trim()) ){
          this.addCharInInput('-', input)
        } else if( !(input?.textContent?.includes('/')) && firstDateSplit[2]?.length==4 ){ 
          //Si el texto original no tiene barra y la primer fecha, en el año tiene 4 digitos, se agrega una barra
          this.addCharInInput('/', input)
        }

      } else {
        if(goesStripper(input.textContent)){
          this.addCharInInput('-', input)
        }
      }

    } else if (key=='enter' || key=='tab'){
      //this.parseDateAux(inputDate.value);
      input.blur();
    }
  }

  //HTMLSpanElement
  addCharInInput(char:string, input:any){
    const cursorPosition = window.getSelection()?.getRangeAt(0).startOffset;
    if(cursorPosition){
      input.textContent = input.textContent + char;
      const range = document.createRange();
      range.setStart(input.firstChild, cursorPosition+1);
      range.setEnd(input.firstChild, cursorPosition+1);
      const sel = window.getSelection();
      if(sel){
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }

  paintCell(cell: HTMLElement) {
    cell.classList.add('is-editing');
  }

  unpaintCell(cell: HTMLElement) {
    cell.classList.remove('is-editing');
  }
}