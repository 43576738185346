import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDetailsComponent } from './header-details.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    HeaderDetailsComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ], exports:[
    HeaderDetailsComponent
  ]
})
export class HeaderDetailsModule { }