import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageSvgComponent } from './message/message.component';
import { GravanzSvgComponent } from './gravanz/gravanz.component';
import { FeaturedPriceSvgComponent } from './featured-price/featured-price.component';

@NgModule({
  declarations: [
    MessageSvgComponent,
    GravanzSvgComponent,
    FeaturedPriceSvgComponent
  ],
  imports: [CommonModule],
  exports: [
    MessageSvgComponent,
    GravanzSvgComponent,
    FeaturedPriceSvgComponent
  ],
})
export class ObservationsPriceSvgModule {}
