import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { setPriceToGenerateInOrder } from "src/app/redux/actions/order.action";
import { NAVEGATION } from "src/app/shared/const/navegation";
import { getMonthAndYearAbbreviated } from "src/app/shared/date.checker";
import { zones } from "src/app/shared/dict/place-of-delivery";
import { appState } from "src/app/shared/interfaces/appState.interface";
import { Price } from "src/app/shared/interfaces/price.interface";
import { PriceValidator } from "src/app/shared/price";

@Component({
    template:''
})
export class CardPrice {
    @Input() price!: Price;
    zones:any=zones;
    
    constructor(public store:Store<appState>, public router:Router, public priceV:PriceValidator){}
    
    getMonth(date:string){
        const dateSplit=date.split('-');
        return getMonthAndYearAbbreviated(Number(dateSplit[1]), Number(dateSplit[2]))
    }

    goToGenerateOrder(){
      let newPrice=JSON.parse(JSON.stringify(this.price));
      this.store.dispatch(setPriceToGenerateInOrder({price: newPrice}));
      this.router.navigate(['/'+NAVEGATION.GENERATE_PRICE_BOARD_ORDER])
    }

    getProductName(price:Price){
        return this.priceV.getProductName(price);
    }

    getZoneName(price:Price){
        return this.zones[price?.placeOfDelivery?.zone] ?? 'No definido';
    }

    getTypeBusiness(price:Price){
        return this.priceV.getBusinessType(price)
    }

    getTypeCoinAndPrice(price:Price){
        return this.priceV.getTypeCoinAndPriceWithPoints(price);
    }
}