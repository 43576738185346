import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPriceComponent } from './info-price.component';
import { DataModule } from '../../data/data.module';

@NgModule({
  declarations: [
    InfoPriceComponent
  ],
  imports: [
    CommonModule,
    DataModule
  ], exports:[
    InfoPriceComponent
  ]
})
export class InfoPriceModule { }
