import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EyeGreenSvgComponent } from './eye-green-svg.component';

@NgModule({
  declarations: [
    EyeGreenSvgComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    EyeGreenSvgComponent
  ]
})
export class EyeGreenSvgModule { }
