import { Component, Input } from '@angular/core';

@Component({
  selector: 'order-list-svg',
  templateUrl: './order-list.component.html',
  styleUrls: ['./../../svg.scss']
})
export class OrderListComponent {
  @Input() active:boolean=false;
  constructor() { }
}
