import { Pipe, PipeTransform } from '@angular/core';
import { OrderExchange } from '../../interfaces/order-exchange';

@Pipe({
  name: 'sorOrderExchange',
})
export class OrderExchangeSortPipe implements PipeTransform {
  constructor() {}

  /* Sort orders by exchange.idMother */
  transform(valueList: OrderExchange[]) {
    let valueToReturn: any = [];
    console.log('Sort order exchange');
    valueToReturn = this.quickSort(valueList,0, valueList.length - 1);
    return valueToReturn;
  }

  quickSort(array: OrderExchange[], left: number, right: number) {
    let index;
    if (array.length > 1) {
      index = this.partition(array, left, right);

      if (left < index - 1) {
        this.quickSort(array, left, index - 1);
      }
      if (index < right) {
        this.quickSort(array, index, right);
      }
    }
    return array;
  }

  partition(array: OrderExchange[], left: number, right: number) {
    let pivot = array[Math.floor((right + left) / 2)],
      i = left,
      j = right;
    while (i <= j) {
      while (this.isFirst(array[i], pivot)) {
        i++;
      }
      while (this.isSecond(array[j], pivot)) {
        j--;
      }
      if (i <= j) {
        [array[i], array[j]] = [array[j], array[i]];
        i++;
        j--;
      }
    }
    return i;
  }

  isFirst(orderExchange: OrderExchange, pivot: OrderExchange) {
    return orderExchange.exchange.idMother < pivot.exchange.idMother;
  }

  isSecond(orderExchange: OrderExchange, pivot: OrderExchange) {
    return orderExchange.exchange.idMother > pivot.exchange.idMother;
  }
}
