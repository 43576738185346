import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BodyBoard } from 'src/app/components/board/body-board';
import { setDivisionTypeFilterControlPanel, setSectionTypeFilterControlPanel } from 'src/app/redux/actions/control-panel.action';
import { buyerType, divisionsControlPanel, sectionType, setViewMobile } from 'src/app/shared/const/options';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { DataChecker } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, getSumOfTons } from 'src/app/shared/order';

@Component({
  selector: 'app-body-control-panel',
  templateUrl: './body-control-panel.component.html',
  styleUrls: ['./body-control-panel.component.scss','./../../../shared/styles/body-board.scss'],
  
})
export class BodyControlPanelComponent extends BodyBoard implements OnInit {
  ordersAll:Order[]=[];
  ordersSelected!:Order[];
  isViewMobile:boolean=false;

  ORDER_STATUS_CONTS = orderStatusConst;

  sectionType:any=sectionType;
  buyerType=buyerType;
  divisionsType=divisionsControlPanel;
  sectionTypeSelected:string='';
  divisionSelected:string='';
  
  typeModal:number=0;
  orderFilter!:OrderString;

  isShowDetails:boolean=false;
  orderDetails!:Order;
  showLoading:boolean=false;

  private ordersAll$!:Subscription;
  private showLoading$!:Subscription;
  private sizeView$!:Subscription;
  private typeModal$!:Subscription;
  private sectionType$!:Subscription;
  private divisionType$!:Subscription;

  constructor(store: Store<appState>, private dataChecker: DataChecker, private orderV:OrderValidator, cdr: ChangeDetectorRef) {
    super(store, cdr);
    this.rememberOrdersOfLocalStorage();
  }

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(){
    this.ordersAll$?.unsubscribe();
    this.showLoading$?.unsubscribe();
    this.sizeView$?.unsubscribe();
    this.typeModal$?.unsubscribe();
    this.sectionType$?.unsubscribe();
    this.divisionType$?.unsubscribe();

    this.setBodyUnsubscription();
  }

  setSubscriptions(){
    this.ordersAll$=this.store.select('controlPanel', 'controlPanelBoard', 'all').subscribe((orders:Order[]) => {
      this.ordersAll = orders;
      this.cdr.detectChanges();
    });

    this.showDetails$=this.store.select('controlPanel','controlPanelBoard','showOrderDetails').subscribe(isShowDetails=>{
      this.isShowDetails=isShowDetails;
    });

    this.details$=this.store.select('controlPanel','controlPanelBoard','orderDetails').subscribe(orderDetails=>{
      this.orderDetails=orderDetails;
    });

    this.selected$=this.store.select('controlPanel','controlPanelBoard','ordersSelected').subscribe(ordersSelected=>{
      this.ordersSelected=ordersSelected;
    });

    this.filter$=this.store.select('controlPanel','controlPanelBoard','filter').subscribe(filter=>{
      this.orderFilter=filter;
    });

    this.sectionType$=this.store.select('controlPanel','controlPanelBoard','sectionType').subscribe(sectionType=>{
      this.sectionTypeSelected=sectionType;
    });

    this.divisionType$=this.store.select('controlPanel','controlPanelBoard','divisionType').subscribe(divisionType=>{
      this.divisionSelected=divisionType;
    });

    this.historical$=this.store.select('order','historical').subscribe(historical=>{
      this.historical=historical;
    });

    this.typeModal$=this.store.select('options','modalContract','typeModal').subscribe(typeModal=>{
      this.typeModal=typeModal;
    });

    this.sizeView$=this.store.select('options','sizeView').subscribe(sizeView=>{
      this.isViewMobile = setViewMobile(sizeView);
    });

    this.showLoading$=this.store.select('order','loaderSendToIBM').subscribe(loaderSendToIBM=>{
      this.showLoading=loaderSendToIBM>0;
    });

    this.setBodySubscription();
  }

  rememberOrdersOfLocalStorage(){
    this.store.dispatch(this.dataChecker.rememberOrdersInControlPanel());
  }

  isOrdersSelectedStatus(ordersSelected:Order[], status:number){
    return ordersSelected.length<=0 || !this.orderV.isOrdersSelectedStatus(ordersSelected, status);
  }

  setSectionType(identificator:string){
    this.store.dispatch(setSectionTypeFilterControlPanel({sectionType:identificator}));
  }

  setDivisionType(identificator:string){
    this.store.dispatch(setDivisionTypeFilterControlPanel({sectionType:identificator}));
  }

  getStatusOfFilterOrder(){
    return getStatusOfFilterOrder(this.sectionTypeSelected, this.divisionSelected);
  }

  getBuyerType(){
    return getBuyerType(this.divisionSelected);
  }

  getStatusSelected() {
    return getStatusOfFilterOrder(this.sectionTypeSelected, this.divisionSelected);
  }

  getIsSellCommodities() {
    return getIsSellCommodities(this.divisionSelected);
  }

  getQuantOfTons(orders:Order[]){
    return getSumOfTons(orders);
  }
}

//Si la section Selected es igual a Contratos en borrador devolvemos 7 (estado de contratos en borrador)
//Si la section Selected es igual a Contratos autorizados devolvemos 8 (estado de contratos autorizados)
export function getStatusOfFilterOrder(sectionTypeSelected:string, divisionTypeSelected:string){
  return sectionTypeSelected==sectionType.CONTRACTS_IN_ERASERS && divisionTypeSelected==divisionsControlPanel.BUY_COMMODITIES?
  orderStatusConst.AUTORIZATION: orderStatusConst.CONTRACT;
}

//Si la divisionSelected es igual a BUY_COMMODITIES devolvemos 'commodities' (compras commodities)
//Si la divisionSelected NO igual a BUY_COMMODITIES devolvemos 'buyer';
export function getBuyerType(divisionTypeSelected:string){
  return divisionTypeSelected==divisionsControlPanel.BUY_COMMODITIES?buyerType.COMMODITIES:buyerType.BUYER;
}

//Si la divisionSelected es igual a SELL_COMMODITIES devolvemos true (ventas commodities)
export function getIsSellCommodities(divisionTypeSelected:string){
  return divisionTypeSelected==divisionsControlPanel.SELL_COMMODITIES;
}