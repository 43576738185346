import { Component, Input, OnInit,HostListener,Output,EventEmitter} from '@angular/core';
import { Store } from '@ngrx/store';
import { setCommisionInOrder } from 'src/app/redux/actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Commission } from 'src/app/shared/interfaces/order.interface';

export const COMMISION_BUYER_INITIAL=1;
export const COMMISION_SELLER_INITIAL=0.5;

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss', './../card.scss']
})
export class CommissionComponent implements OnInit {
  isSellerChecked:boolean=true;
  isBuyerChecked:boolean=true;
  @Input() valueSeller:number=COMMISION_SELLER_INITIAL;
  @Input() valueBuyer:number=COMMISION_BUYER_INITIAL;

  //Detect click
  wasInside:boolean=false;
  count:number=0;
  @Output() close = new EventEmitter<string>();
  @Output() commission = new EventEmitter<Commission>();

  constructor(private store: Store<appState>) {}

  ngOnInit(): void {
    this.wasInside=true;
  }

  verifyCheckeds(){
    this.isBuyerChecked=this.valueBuyer>0;
    this.isSellerChecked=this.valueSeller>0;
  }


  setSellerValue(event:any){
    this.valueSeller=Number(event.target.value);
    this.verifyCheckeds();
  }

  setBuyerValue(event:any){
    this.valueBuyer=Number(event.target.value);
    this.verifyCheckeds();
  }

  setCommission(){
    const commission= {
      seller: Number(this.valueSeller),
      buyer:Number(this.valueBuyer)
    }

    this.store.dispatch(setCommisionInOrder({commission:commission}))
  }

  @HostListener('click') clickInside() {
    this.emit('open');
    this.wasInside = true;
  }

  @HostListener('document:click') clickout() {
    if(this.wasInside) this.count++;

    if (!this.wasInside) {
      if(this.count>0){
        this.closeFunction()
      }
      this.count=0;
    }
    this.wasInside = false;
  }

  emit(value:string){
    this.close.emit(value);
  }

  emitCommission(){
    this.commission.emit(this.getCommission());
    this.closeFunction()
  }

  getCommission():Commission{
    return {
      seller: Number(this.valueSeller),
      buyer:Number(this.valueBuyer)
    }
  }

  closeFunction(){
    this.emit('close');
  }
}
