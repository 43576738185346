<svg class="svg-22" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clip-path="url(#clip0_883_167588)">
    <path d="M17.875 22H4.125C1.84665 22 0 20.1534 0 17.875V4.125C0 1.84665 1.84665 0 4.125 0H17.875C20.1534 0 22 1.84665 22 4.125V17.875C22 20.1534 20.1534 22 17.875 22Z" fill="#00C689" fill-opacity="0.1"/>
    <path d="M9.96918 14.4375C8.89529 14.4375 8.02148 13.5637 8.02148 12.4898V7.79211H7.21918C6.52412 7.79211 5.95898 8.35725 5.95898 9.05231V15.2398C5.95898 15.9349 6.52412 16.5 7.21918 16.5H12.9481C13.6432 16.5 14.2083 15.9349 14.2083 15.2398V14.4375H9.96918Z" fill="#00C689" fill-opacity="0.58"/>
    <path d="M9.96918 5.5C9.27278 5.5 8.70898 6.06447 8.70898 6.76019V12.4891C8.70898 13.1855 9.27345 13.7493 9.96918 13.7493H14.7817C15.4781 13.7493 16.0419 13.1849 16.0419 12.4891V6.875C16.0419 6.11533 15.4265 5.5 14.6669 5.5H9.96918Z" fill="#00C689"/>
    </g>
    <defs>
    <clipPath id="clip0_883_167588">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
</svg>