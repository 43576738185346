import { Component,OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setInfoIndicators, setInfoQuotas, setModalContract } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { INFO_QUOTAS_VOID } from 'src/app/shared/const/options';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FailedResponse } from 'src/app/shared/interfaces/DataIBM';
import { InfoQuotas } from 'src/app/shared/interfaces/options.interface';
import { OrderValidator } from 'src/app/shared/order';

@Component({
    selector: 'dialog-eraser-create',
    templateUrl: './dialog.html',
    styleUrls: ['./dialog.scss', './../modal.scss']
  })
  export class DialogEraserCreate implements OnInit, OnDestroy{
    hour: string = '';
    arrayOfSuccess!: FailedResponse[];
    dataOfQuotas!:InfoQuotas;
    dataOfIndicators:string='';
    isConfirm: boolean = true;

    optionsSubscription!: Subscription;
    constructor(private router: Router, private store: Store<appState>, 
      private date: DateChecker, private orderV:OrderValidator) {}
    
    close(){    
      this.store.dispatch(setModalContract({modalContract:{isShow:false, arrayOfFaileds:[], arrayOfSuccess:[], isIndicators:false, typeModal: 0}}));
    }

    ngOnInit(): void {
      this.hour = this.date.setHourFormat();
      this.optionsSubscription=this.store.select('options').subscribe(optionsR => {
        this.arrayOfSuccess = optionsR.modalContract.arrayOfSuccess;
        this.dataOfQuotas=optionsR.infoQuotas;
        this.dataOfIndicators=optionsR.infoIndicators;
      });
    }

    ngOnDestroy(){
      this.optionsSubscription.unsubscribe();
      this.store.dispatch(setInfoQuotas({dataOfQuotas: INFO_QUOTAS_VOID}));
      this.store.dispatch(setInfoIndicators({infoIndicators:''}));
      this.close();
    }

    getClientName(success:FailedResponse){
      return success.data.NombreVendedor;
    }
    
    goToControlPanel(){
      this.router.navigate(['/'+NAVEGATION.CONTROL_PANEL]);
      this.close();
    }

    getMessageOfQuotas(){
      if(!this.dataOfQuotas.isErrorSolicitate && this.dataOfQuotas.messageSolicitate){
        return this.dataOfQuotas.messageSolicitate;
      } else if(this.dataOfQuotas.isErrorSolicitate && this.dataOfQuotas.messageSolicitate){
        return this.dataOfQuotas.messageSolicitate;
      } else if(!this.dataOfQuotas.messageResponse && this.orderV.isValidQuota(this.dataOfQuotas.quota)){
        const camOrCams=this.dataOfQuotas.quota.trucks>1?' camiones ':' camión ';
        return 'Los cupos para el ' + this.dataOfQuotas.quota.date +' con ' + this.dataOfQuotas.quota.trucks+ camOrCams +'fueron asignados.';
      }
      return this.dataOfQuotas.messageResponse;
    }
  }