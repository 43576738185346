import { Component, OnInit,HostListener,Input, OnChanges} from '@angular/core';
import { Store } from '@ngrx/store';
import { setRestriction } from 'src/app/redux/actions/order.action';
import { GRAVANZ_VOID } from 'src/app/shared/const/business-particularities';
import { fieldType } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Gravanz } from 'src/app/shared/interfaces/business-particularities';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { BusinessParticularityOption } from '../options.component';
import { COMMODITIES_CODE } from 'src/app/shared/const/buyers';

@Component({
  selector: 'app-gravanz',
  templateUrl: './gravanz.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class GravanzComponent extends BusinessParticularityOption implements OnChanges {
  public gravanz:Gravanz=GRAVANZ_VOID;
  public empresas:any[] = []
  public objectEmpresa:any = {}

  public selectionStock:any[] = []
  public objectSelectionStock:any = {}
  @Input() fieldIdentificator: string = '';

  //-----------------------------\\

  constructor(private store:Store<appState>) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    try{
      this.gravanz=this.businessParticularitiesStruct.gravanz;
    } catch(err){
      this.gravanz=GRAVANZ_VOID;
    }
  }


  setGravanz(gravanz:Gravanz){
    this.gravanz=gravanz;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.gravanz = this.gravanz;
    this.emit2(newBP);
  }

  getGravanz() {
    let gravanz = JSON.parse(JSON.stringify(this.gravanz));
    return gravanz;
  }

  setIsGravanz(){
    let newGravanz:Gravanz=JSON.parse(JSON.stringify(this.gravanz))
    newGravanz.isGravanz= this.boolToNumber( ! (this.gravanz.isGravanz==1) ) ;
    
    //Si es GRAVANZ, la sensorización es PROPIA por default
    if(newGravanz.isGravanz==1){
      newGravanz.isOwnSensorization=1;
    }
    this.setCommoditiesInBuyer(newGravanz);

    this.setGravanz(newGravanz);
  }

  setOwnSensorization(boolean:boolean){
    let newGravanz=JSON.parse(JSON.stringify(this.gravanz))
    newGravanz.isOwnSensorization=this.boolToNumber(boolean);
    this.setGravanz(newGravanz);
  }

  setBusiness(element:any){
    let newGravanz=JSON.parse(JSON.stringify(this.gravanz))
    newGravanz.business=element.element;
    this.setGravanz(newGravanz);
  }

  setBrokerSelector(element:any){
    let newGravanz=JSON.parse(JSON.stringify(this.gravanz))
    newGravanz.selectionStock=element.element;
    this.setGravanz(newGravanz);
  }

  setCommoditiesInBuyer(gravanz:Gravanz){
    //If it is Gravanz, set the field order -> buyer -> only buyer with 'Commodities' (code: 722)
    if(gravanz.isGravanz){
      this.store.dispatch(setRestriction({restriction: {notSell: [], onlySell: [COMMODITIES_CODE]}}))
    }
  }

  getSelectionStock(){
    return this.gravanz.selectionStock && this.gravanz.selectionStock != ' '? this.gravanz.selectionStock : '';
  }

  getBusiness(){
    return this.gravanz.business && this.gravanz.business != ' '? this.gravanz.business : '';
  }

}
