import { NgModule } from '@angular/core';
import { ProductNameModule } from './product-name/product-name.module';
import { HarvestModule } from './harvest/harvest.module';
import { PriceModule } from './price/price.module';
import { BusinessTypeModule } from './business-type/business-type.module';
import { WayPayModule } from './way-pay/way-pay.module';
import { TypeCoinModule } from './type-coin/type-coin.module';
import { PlaceOfDeliveryModule } from './place-of-delivery/place-of-delivery.module';
import { PesificationModule } from './pesification/pesification.module';
import { ExpirationModule } from './expiration/expiration.module';
import { DeliveryPeriodModule } from './delivery-period/delivery-period.module';
import { TonsModule } from './tons/tons.module';
import { SellerNameModule } from './seller-name/seller-name.module';
import { QuotasModule } from './quotas/quotas.module';
import { PlaceOfOriginModule } from './place-of-origin/place-of-origin.module';
import { HowSellModule } from './how-sell/how-sell.module';
import { BuyerModule } from './buyer/buyer.module';
import { BrokerModule } from './broker/broker.module';
import { BusinessParticularitiesModule } from './business-particularities/business-particularities.module';
import { BuyerOrderModule } from './buyer-order/buyer.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
//import { QualityParticularitiesModule } from '../slide-cards/quality-particularities/quality-particularities.module';
import { QualityParticularitiesModule } from './quality-particularities/quality-particularities.module';
import { GrouperCodeModule } from './grouper-code/grouper-code.module';
import { ParameterizedObservationsModule } from './parameterized-observations/parameterized-observations.module';
import { AgreedDiscountModule } from './agreed-discount/agreed-discount.module';
import { ToFixConditionTypeModule } from './to-fix-condition-type/condition-type.module';
import { ToFixIndicatorsModule } from './to-fix-indicators/to-fix-indicators.module';
import { QualityModule } from './quality/quality.module';
import { QualityIBMModule } from './quality-ibm/quality.module';
import { SyngentaSubdivisionModule } from './syngenta/syngenta.module';
import { BonificationExchangeModule } from './bonification-exchange/exchange-bonus.module';
import { TypeDolarModule } from './type-dolar/type-dolar.module';

@NgModule({
  exports:[
    AgreedDiscountModule,

    //Price
    ProductNameModule,
    HarvestModule,
    PriceModule,
    BusinessTypeModule,
    WayPayModule,
    TypeCoinModule,
    QualityModule,
    PlaceOfDeliveryModule,
    PesificationModule,
    ExpirationModule,
    DeliveryPeriodModule,

    //GROUPER CODE
    GrouperCodeModule,

    //Particularities
    BusinessParticularitiesModule,
    QualityParticularitiesModule,

    //Orders
    TonsModule,
    SellerNameModule,
    QuotasModule,
    PlaceOfOriginModule,
    HowSellModule,
    BuyerModule,
    BrokerModule,
    BuyerOrderModule,
    ParameterizedObservationsModule,
    TypeDolarModule,

    //ToFix
    ToFixConditionTypeModule,
    ToFixIndicatorsModule,

    //EXCHANGE
    AgreedDiscountModule,
    BonificationExchangeModule,

    //PIPES MODULE
    PipesModule,

    //IBM
    QualityIBMModule,

    //Syngenta
    SyngentaSubdivisionModule
  ],
})
export class FieldsModule { }
