<div class="info-product">
    <div class="header">
        <div class="icon-and-name row-left gap-8">
            <img class="product-icon" src="../../../../assets/images/products/{{productName}}.svg">
            <span>{{productName}}</span>
        </div>
    </div>
    <table>
        <thead>
            <tr>
                <th></th>
                <th>Periodo</th>
                <th>Entrega</th>
                <th>Calidad</th>
                <th>Precio</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr class="row-table" *ngFor="let dataOfPrice of prices" (click)="goToOrderBoard(dataOfPrice)">
                <td class="{{getStatusOfPrice(dataOfPrice)}}"> <div class="circle"></div> </td>
                <td>{{dataOfPrice.deliveryPeriod | periodPipe:''}}</td>
                <td>{{getPlaceOfDelivery(dataOfPrice)}}</td>
                <td class="quality-column">
                    <div class="quality-container tooltip-container {{getQuality(dataOfPrice).toLowerCase()}}" 
                    [ngClass]="{'not-quality-particularity': dataOfPrice.qualityIBM<0}">
                        <span class="quality">{{getQuality(dataOfPrice)}}</span>
                    </div>
                <td class="precio">{{getTypeCoinAndPrice(dataOfPrice)}}</td>
                <td> <arrow-svg class="arrow" [direction]="'right'"></arrow-svg> </td>
            </tr>
        </tbody>
    </table>
</div>