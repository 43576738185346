import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GravanzComponent } from './gravanz.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SelectInputModule } from 'src/app/components/inputs/select/select.module';
import { TextInputModule } from 'src/app/components/inputs/text/text.module';



@NgModule({
  declarations: [
    GravanzComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    FormsModule,
    PipesModule,
    SelectInputModule,
    TextInputModule
  ], exports: [
    GravanzComponent
  ]
})
export class GravanzModule { }
