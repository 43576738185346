import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularityOption } from './../options.component';

@Component({
  selector: 'app-wash-out',
  templateUrl: './wash-out.component.html',
  styleUrls: ['./../options.component.scss']
})
export class WashOutComponent extends BusinessParticularityOption implements OnChanges {
  isWashOut:boolean=false;

  constructor() { 
    super()
  }

  ngOnChanges(): void {
    try{
      this.isWashOut=this.businessParticularitiesStruct.washOut==1;
    } catch(err){
      this.isWashOut=false;
    }
  }

  setWashOut(){
    this.isWashOut=!this.isWashOut;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.washOut=this.boolToNumber(this.isWashOut);
    this.emit2(newBP);
  }
}
