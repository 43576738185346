import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { ACCUMULATOR_VOID } from 'src/app/shared/const/business-particularities';
import { Accumulator } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-acumulador',
  templateUrl: './acumulador.component.html',
  styleUrls: ['./../options.component.scss']
})
export class AcumuladorComponent extends BusinessParticularityOption implements OnChanges {
  public accumulator: Accumulator = ACCUMULATOR_VOID;

  constructor() {
    super()
  }

  ngOnChanges(): void {
    try{
      this.accumulator=this.businessParticularitiesStruct.accumulator;
    } catch(err){
      this.accumulator=ACCUMULATOR_VOID;
    }
  }

  setAccumulator(accumulator:number) {
    let newAccumulator = JSON.parse(JSON.stringify(this.accumulator));
    newAccumulator.isAccumulator=this.boolToNumber(accumulator>0);
    newAccumulator.typeAccumulator = accumulator;
    
    let newBP = JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.accumulator = newAccumulator;
    this.emit2(newBP);
    this.accumulator=newAccumulator
  }

  verifyAccumulator(accumulator:any){
    if(accumulator.typeAccumulator > 0){
      this.setAccumulator(0); //Reset accumulator
    }
  }
}
