import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { OPTION, OptionMenu } from '../options/options.component';

@Component({
  selector: 'app-table-mobile-order',
  templateUrl: './mobile-order.component.html',
  styleUrls: ['./../mobile-board.scss']
})
export class MobileOrderComponent implements OnInit {
  @Input() orders!: Order[];
  @Output() actions= new EventEmitter<{event:OptionMenu, order:Order}>();
  productName:string='';

  constructor(private orderV:OrderValidator) { }

  ngOnInit(): void {
    this.productName=this.getProductName(this.orders[0]) ?? '';
  }

  goToOrderBoard(order:Order){
    this.actions.emit({event:{ name: 'Ver detalle', action: OPTION.DETAILS }, order:order});
  }

  getSellerName(order:Order){
    return this.orderV.getSellerName(order);
  }

  getProductName(order:Order){
    return this.orderV.getProductName(order);
  }

  getTypeCoinAndPrice(order:Order){
    return this.orderV.getTypeCoinAndPrice(order);
  }

  getTons(order:Order){
    return this.orderV.getTons(order)
  }
}