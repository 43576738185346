import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { RangeDate } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'date-range-input',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeInputComponent implements OnInit {
  @Input() valueInit:string='';
  @Input() valueEnd:string='';
  @Input() isIncompleteInit:boolean=false;
  @Input() isIncompleteEnd:boolean=false;
  @Input() isFilter: boolean = false;
  @Input() arrayDatesFilter: string[] = [];
  @Output() valueOut= new EventEmitter<RangeDate>();

  constructor() { }

  ngOnInit(): void {

  }

  emitValues(value:string, tag:string){
    if(tag==='init'){
      this.valueOut.emit({startDate: value, endDate: this.valueEnd});
    }else{
      this.valueOut.emit({startDate: this.valueInit, endDate:value});
    }
  }
}