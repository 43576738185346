<div class="field">
    <div class="width-100 row-between">
        <div><span class="field-title">Periodo de entrega<span class="asterisk">*</span></span></div>
    </div>

    <div class="inputs">
        <app-start-date class="input" [fieldIdentificator]="fieldIdentificator"></app-start-date>
        <app-end-date class="input"   [fieldIdentificator]="fieldIdentificator"></app-end-date>
    </div>

    <span class="message-error" *ngIf="messageError">{{messageError}}</span>

    <!-- isIncomplete -->
    <span class="text-is-incomplete" *ngIf="false">Completar la siguiente información para continuar.</span>


    <!-- VALID UNTIL -->
    <div class="column-left gap-8 width-100" style="margin-top:8px;">
        <button *ngIf="isGenerateToFix && isGenerateCondition() && isForward()" class="not-filled-button-2" (click)="addValidFor()">
            <plus-generate-order-svg class="icon" [size]="30"></plus-generate-order-svg>
            <span class="field-name">Agregar válido hasta</span>
        </button>

        <date-month-input class="width-100" *ngIf="showValidUntil" [initialPlaceHolder]="'Hasta'"
        [value]="validUntil" [minDate]="getDateMin()" (valueOut)="setValidUntil($event)"></date-month-input>
    
        <!--<date-input  class="width-100" *ngIf="showValidUntil" [initialPlaceHolder]="'Hasta'" 
        [value]="validUntil" [minDate]="getDateMin()" 
        (valueOut)="setValidUntil($event)"></date-input>-->
        
        <div class="row-right width-100" *ngIf="isGenerateCondition() && getValidFor().length>0">
            <div class="row-between gap-8 pointer" (click)="deleteValidUntil()">
                <span>Eliminar todo</span>
                <input type="checkbox"/>
            </div>
        </div>

        <div class="valid-for-container width-100">
            <div class="valid-for row-between pointer" *ngFor="let dates of getValidFor(); index as i">
                <span (click)="selectValidFor(dates, i)">{{dates.startDate}} al {{dates.endDate}}</span>
                <cross-svg class="pointer" [size]="16" (click)="deleteValidFor(dates, i)" *ngIf="showCross"></cross-svg>
            </div>
        </div>

    </div>
</div>