<div class="table table-desktop" *ngIf="!isViewMobile else elseBlock">
    <app-comercial class="table"(actionEraser)="emitAction($event)"></app-comercial> <!-- *ngIf="isComercial"  -->
</div>

<ng-template #elseBlock>
    <div class="options">
        <button class="verificar" [ngClass]="{ selected: EXCHANGE_STATUS_CONST.VERIFICAR == exchangeStatusSelected }"
  (click)="setExchangeStatusSelected(EXCHANGE_STATUS_CONST.VERIFICAR)">
            Verificar
        </button>
        <button class="incompleto" [ngClass]="{ selected: EXCHANGE_STATUS_CONST.INCOMPLETO == exchangeStatusSelected }"
          (click)="setExchangeStatusSelected(EXCHANGE_STATUS_CONST.INCOMPLETO)">
          Incompleto
        </button>
        <button class="completo" [ngClass]="{ selected: EXCHANGE_STATUS_CONST.COMPLETA == exchangeStatusSelected }"
          (click)="setExchangeStatusSelected(EXCHANGE_STATUS_CONST.COMPLETA)">
          Completo
        </button>

        <div class="rectangle {{getStatusString(exchangeStatusSelected)}}"></div>
    </div>

    <div class="exchange-mobile" *ngFor="let order of orders | filterByRepresentation: isRepresentation:user | matchExchangesByName: exchangeStatusSelected: orderFilter ">
        <app-table-mobile-exchange class="info-exchange" [orders]="order"></app-table-mobile-exchange>
    </div>
</ng-template>
