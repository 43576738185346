import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Modulos
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { OrderBodyComponent } from './body-order-list.component';
import { OrderTableModule } from './order-table/order-table.module';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ViewsOrdersModule } from 'src/app/components/views/views-orders/views-orders.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { AddBuyerModule } from 'src/app/components/slide-cards/add-buyer/add-buyer.module';
import { DetailsEyeModule } from 'src/app/components/slide-cards/details-eye/details-eye.module';
import { HistoricalModule } from 'src/app/components/slide-cards/historical/historical.module';
import { ViewsSectionModule } from 'src/app/components/views-section/views-section.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';
import { SearchInputModule } from 'src/app/components/inputs/search/search.module';
import { LoadingLottieModule } from 'src/app/components/loading/loading.module';
import { DialogEraserCreateModule } from 'src/app/components/dialogs/eraser-create/eraser-create.module';
import { DialogContractCreateModule } from 'src/app/components/dialogs/contract-create/contract-create.module';
import { DialogContractFailedModule } from 'src/app/components/dialogs/contract-failed/contract-failed.module';
import { ButtonsBuyerTypeModule } from 'src/app/components/buttons-buyer-type/buttons-buyer-type.module';

@NgModule({
  declarations: [
    OrderBodyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OrderTableModule,
    ViewsSectionModule,

    //SLIDE CARDS
    HistoricalModule,
    AddBuyerModule,
    DetailsEyeModule,

    ViewsOrdersModule,
    PipesModule,

    //SVG
    ThreePointsSvgModule,
    ArrowSvgModule,

    SearchInputModule,
    ButtonsConditionTypeModule,
    ButtonsBuyerTypeModule,

    LoadingLottieModule,

    DialogContractCreateModule,
    DialogEraserCreateModule,
    DialogContractFailedModule,
  ], 
  exports: [
    OrderBodyComponent,
  ]
})
export class OrderBodyModule { }
