export const santiagoDelEsteroLocalities=[
    {"afip":"17","location":"25 De Mayo De Barnegas"},
    {"afip":"18","location":"25 De Mayo Sud"},
    {"afip":"27","location":"7 De Abril"},
    {"afip":"44","location":"Abra De La Cruz"},
    {"afip":"48","location":"Abra De Quimil"},
    {"afip":"63","location":"Abras Del Martirizado"},
    {"afip":"64","location":"Abras Del Medio"},
    {"afip":"65","location":"Abrita"},
    {"afip":"66","location":"Abrita Chica"},
    {"afip":"67","location":"Abrita Grande"},
    {"afip":"88","location":"Acos"},
    {"afip":"107","location":"Aerolito"},
    {"afip":"118","location":"Agua Azul"},
    {"afip":"150","location":"Agua Dulce"},
    {"afip":"168","location":"Agua Turbia"},
    {"afip":"206","location":"Aguas Coloradas"},
    {"afip":"222","location":"Agujereado"},
    {"afip":"226","location":"Agustina Libarona"},
    {"afip":"229","location":"Aibal"},
    {"afip":"230","location":"Aibalito"},
    {"afip":"246","location":"Albardon Chuña"}
    ,
    {"afip":"307","location":"Alejito"},
    {"afip":"341","location":"Alhuampa"},
    {"afip":"369","location":"Alpa Puca"},
    {"afip":"457","location":"Alto Pozo"},
    {"afip":"478","location":"Alza Nueva"},
    {"afip":"485","location":"Amama"},
    {"afip":"491","location":"Amapola"},
    {"afip":"494","location":"Ambargasta"},
    {"afip":"507","location":"Amicha"},
    {"afip":"509","location":"Amiman"},
    {"afip":"512","location":"Amoladeras"},
    {"afip":"513","location":"Ampa"},
    {"afip":"530","location":"Anca"},
    {"afip":"531","location":"Ancajan"},
    {"afip":"536","location":"Anchanga"},
    {"afip":"539","location":"Anchilo"},
    {"afip":"542","location":"Anchoriga"},
    {"afip":"545","location":"Ancocha"},
    {"afip":"546","location":"Ancoche"},
    {"afip":"567","location":"Anga"},
    {"afip":"596","location":"Antaje"},
    {"afip":"606","location":"Antilo"},
    {"afip":"619","location":"Antuco"},
    {"afip":"638","location":"Aragones"},
    {"afip":"654","location":"Arbol Negro"},
    {"afip":"662","location":"Arbolitos"},
    {"afip":"666","location":"Ardiles"},
    {"afip":"667","location":"Ardiles De La Costa"},
    {"afip":"668","location":"Areas"},
    {"afip":"678","location":"Argentina"},
    {"afip":"692","location":"Armonia"},
    {"afip":"700","location":"Arraga"},
    {"afip":"866","location":"Arroyo Tala"},
    {"afip":"904","location":"Aspa Sinchi"},
    {"afip":"919","location":"Atoj Pozo"},
    {"afip":"936","location":"Ave Maria"},
    {"afip":"940","location":"Averias"},
    {"afip":"951","location":"Ayuncha"},
    {"afip":"955","location":"Azogasta"},
    {"afip":"959","location":"Azucena"},
    {"afip":"963","location":"Añatuya"}
    ,
    {"afip":"965","location":"Añil"}
    ,
    {"afip":"973","location":"Babilonia"},
    {"afip":"977","location":"Baez"},
    {"afip":"979","location":"Bagual Muerto"},
    {"afip":"991","location":"Bahoma"},
    {"afip":"1015","location":"Bajadita"},
    {"afip":"1048","location":"Bajo Las Piedras"},
    {"afip":"1101","location":"Balde Pozo"},
    {"afip":"1163","location":"Bandera"},
    {"afip":"1164","location":"Bandera Bajada"},
    {"afip":"1168","location":"Banegas"},
    {"afip":"1209","location":"Barrial Alto"},
    {"afip":"1212","location":"Barrialito"},
    {"afip":"1250","location":"Barrio Este"},
    {"afip":"1259","location":"Barrio Jardin"},
    {"afip":"1270","location":"Barrio La Leñera"}
    ,
    {"afip":"1328","location":"Barrio Villa Cohesa"},
    {"afip":"1330","location":"Barrio Villa Fernandez"},
    {"afip":"1375","location":"Bauman"},
    {"afip":"1419","location":"Bebidas"},
    {"afip":"1423","location":"Bejan"},
    {"afip":"1427","location":"Belgica"},
    {"afip":"1440","location":"Beltran Loreto"},
    {"afip":"1441","location":"Beltranloreto"},
    {"afip":"1483","location":"Binal Esquina"},
    {"afip":"1486","location":"Blanca"},
    {"afip":"1488","location":"Blanca Pozo"},
    {"afip":"1491","location":"Blanco Pozo"},
    {"afip":"1503","location":"Bobadal"},
    {"afip":"1547","location":"Bordo Pampa"},
    {"afip":"1561","location":"Bracho"},
    {"afip":"1564","location":"Brandan"},
    {"afip":"1570","location":"Brea Pozo"},
    {"afip":"1571","location":"Brea Pozo Viejo"},
    {"afip":"1572","location":"Brea Puñuna"}
    ,
    {"afip":"1574","location":"Brealoj"},
    {"afip":"1599","location":"Buenos Aires"},
    {"afip":"1602","location":"Buey Rodeo"},
    {"afip":"1606","location":"Burra Huañuna"}
    ,
    {"afip":"1607","location":"Burro Pozo"},
    {"afip":"1661","location":"Cabra"},
    {"afip":"1679","location":"Cachico"},
    {"afip":"1712","location":"Cajon"},
    {"afip":"1747","location":"Caleras"},
    {"afip":"1770","location":"Callejon Bajada"},
    {"afip":"1773","location":"Caloj"},
    {"afip":"1836","location":"Campo Amarillo"},
    {"afip":"1847","location":"Campo Belgrano"},
    {"afip":"1875","location":"Campo De Amor"},
    {"afip":"1892","location":"Campo Del Aguila"},
    {"afip":"1894","location":"Campo Del Cisne"},
    {"afip":"1895","location":"Campo Del Infierno"},
    {"afip":"1911","location":"Campo El Rosario"},
    {"afip":"1922","location":"Campo Gallo"},
    {"afip":"1940","location":"Campo La Angelita"},
    {"afip":"1962","location":"Campo Limpio"},
    {"afip":"1992","location":"Campo Ramon Laplace"},
    {"afip":"1996","location":"Campo Rico"},
    {"afip":"2021","location":"Campo Verde"},
    {"afip":"2046","location":"Canario"},
    {"afip":"2055","location":"Cancinos"},
    {"afip":"2062","location":"Caneinos"},
    {"afip":"2069","location":"Cantamampa"},
    {"afip":"2092","location":"Canteros"},
    {"afip":"2104","location":"Capilla"},
    {"afip":"2160","location":"Caranchi Yaco"},
    {"afip":"2169","location":"Carbon Pozo"},
    {"afip":"2173","location":"Cardajal"},
    {"afip":"2175","location":"Cardon Esquina"},
    {"afip":"2177","location":"Cardozos"},
    {"afip":"2214","location":"Carrera Vieja"},
    {"afip":"2219","location":"Carretero"},
    {"afip":"2241","location":"Cartavio"},
    {"afip":"2244","location":"Casa Alta"},
    {"afip":"2253","location":"Casa De Dios"},
    {"afip":"2269","location":"Casa Verde"},
    {"afip":"2279","location":"Casares"},
    {"afip":"2292","location":"Cashico"},
    {"afip":"2295","location":"Casilla Del Medio"},
    {"afip":"2300","location":"Caspi Corral"},
    {"afip":"2334","location":"Catorce Quebrachos"},
    {"afip":"2342","location":"Cavadito"},
    {"afip":"2343","location":"Cavado"},
    {"afip":"2355","location":"Cazadores"},
    {"afip":"2379","location":"Cañada De La Costa"}
    ,
    {"afip":"2380","location":"Cañada De La Cruz"}
    ,
    {"afip":"2404","location":"Cañada Del Medio"}
    ,
    {"afip":"2415","location":"Cañada Escobar"}
    ,
    {"afip":"2422","location":"Cañada Limpia"}
    ,
    {"afip":"2433","location":"Cañada San Ramon"}
    ,
    {"afip":"2435","location":"Cañada Tala Pozo"}
    ,
    {"afip":"2474","location":"Cañas Paso"}
    ,
    {"afip":"2486","location":"Ceja Pozo"},
    {"afip":"2487","location":"Cejas"},
    {"afip":"2490","location":"Cejolao"},
    {"afip":"2491","location":"Celestina"},
    {"afip":"2498","location":"Central Dolores"},
    {"afip":"2516","location":"Cerrillo"},
    {"afip":"2520","location":"Cerrillos De San Isidro"},
    {"afip":"2723","location":"Cerro Rico"},
    {"afip":"2813","location":"Chaguar Puncu"},
    {"afip":"2816","location":"Chainima"},
    {"afip":"2833","location":"Chanchillos"},
    {"afip":"2857","location":"Charco Viejo"},
    {"afip":"2861","location":"Charquina"},
    {"afip":"2868","location":"Chauchillas"},
    {"afip":"2869","location":"Chaupi Pozo"},
    {"afip":"2874","location":"Chaves"},
    {"afip":"2880","location":"Chañar Bajada"}
    ,
    {"afip":"2882","location":"Chañar Esquina"}
    ,
    {"afip":"2891","location":"Chañar Pocito"}
    ,
    {"afip":"2892","location":"Chañar Pozo"}
    ,
    {"afip":"2893","location":"Chañar Pozo De Abajo"}
    ,
    {"afip":"2894","location":"Chañar Pujio"}
    ,
    {"afip":"2898","location":"Chañar Sunichaj"}
    ,
    {"afip":"2902","location":"Chañar Yaco"}
    ,
    {"afip":"2906","location":"Chañares Altos"}
    ,
    {"afip":"2936","location":"Chilca Albardon"},
    {"afip":"2937","location":"Chilca Juliana"},
    {"afip":"2941","location":"Chilcas La Loma"},
    {"afip":"2944","location":"Chile"},
    {"afip":"2947","location":"Chileno"},
    {"afip":"2951","location":"Chillimo"},
    {"afip":"2952","location":"Chilpa Macho"},
    {"afip":"2953","location":"Chilpa Mayo"},
    {"afip":"2954","location":"Chilquita"},
    {"afip":"2955","location":"Chilquita I"},
    {"afip":"2956","location":"Chilquitas"},
    {"afip":"2972","location":"Chira"},
    {"afip":"3007","location":"Chuchi"},
    {"afip":"3014","location":"Chuiqui"},
    {"afip":"3021","location":"Churqui"},
    {"afip":"3024","location":"Churqui Esquina"},
    {"afip":"3031","location":"Chuña Albardon"}
    ,
    {"afip":"3033","location":"Chuña Palma"}
    ,
    {"afip":"3072","location":"Clavel Blanco"},
    {"afip":"3076","location":"Cleveland"},
    {"afip":"3077","location":"Clodomira"},
    {"afip":"3108","location":"Codillo"},
    {"afip":"3109","location":"Codo"},
    {"afip":"3110","location":"Codo Bajada"},
    {"afip":"3111","location":"Codo Pozo"},
    {"afip":"3112","location":"Codo Viejo"},
    {"afip":"3137","location":"Collera Huircuna"},
    {"afip":"3138","location":"Collera Huiri"},
    {"afip":"3139","location":"Collera Hurcuna"},
    {"afip":"3140","location":"Collujlioj"},
    {"afip":"3142","location":"Collun-Lioj"},
    {"afip":"3193","location":"Colonia Alpina"},
    {"afip":"3194","location":"Colonia Alsina"},
    {"afip":"3353","location":"Colonia Dora"},
    {"afip":"3391","location":"Colonia El Peligro"},
    {"afip":"3402","location":"Colonia El Simbolar"},
    {"afip":"3420","location":"Colonia Ermelinda"},
    {"afip":"3462","location":"Colonia Geraldina"},
    {"afip":"3502","location":"Colonia Isla"},
    {"afip":"3613","location":"Colonia La Victoria"},
    {"afip":"3699","location":"Colonia Media"},
    {"afip":"3702","location":"Colonia Mercedes"},
    {"afip":"3775","location":"Colonia Paula"},
    {"afip":"3776","location":"Colonia Paz"},
    {"afip":"3788","location":"Colonia Pinto"},
    {"afip":"3884","location":"Colonia Santa Rosa Aguirre"},
    {"afip":"3896","location":"Colonia Siegel"},
    {"afip":"3919","location":"Colonia Tinco"},
    {"afip":"4012","location":"Conchayos"},
    {"afip":"4033","location":"Consulñoj"}
    ,
    {"afip":"4037","location":"Contreras Establecimiento"},
    {"afip":"4039","location":"Conzo"},
    {"afip":"4049","location":"Copo Viejo"},
    {"afip":"4055","location":"Coraspino"},
    {"afip":"4065","location":"Coro Abra"},
    {"afip":"4077","location":"Coronel Barros"},
    {"afip":"4093","location":"Coronel Fernandez"},
    {"afip":"4104","location":"Coronel Manuel Leoncio Rico"},
    {"afip":"4127","location":"Corral De Carcos"},
    {"afip":"4144","location":"Corral Grande"},
    {"afip":"4164","location":"Corvalanes"},
    {"afip":"4206","location":"Costa Rica"},
    {"afip":"4215","location":"Costa Vieja"},
    {"afip":"4256","location":"Cruz Bajada"},
    {"afip":"4260","location":"Cruz Chula"},
    {"afip":"4272","location":"Cruz Grande"},
    {"afip":"4274","location":"Cruz Loma"},
    {"afip":"4277","location":"Cruz Pozo"},
    {"afip":"4306","location":"Cuchi Corral"},
    {"afip":"4333","location":"Cuichicaña"}
    ,
    {"afip":"4344","location":"Cuquenos"},
    {"afip":"4345","location":"Cuquero"},
    {"afip":"4370","location":"Cuyoj"},
    {"afip":"4427","location":"Desvio Pozo Dulce"},
    {"afip":"4433","location":"Diaspa"},
    {"afip":"4440","location":"Diente Del Arado"},
    {"afip":"4449","location":"Dique Chico"},
    {"afip":"4454","location":"Dique Figueroa"},
    {"afip":"4462","location":"Dique Los Quiroga"},
    {"afip":"4481","location":"Doble Tero"},
    {"afip":"4502","location":"Dolores Central"},
    {"afip":"4517","location":"Don Bartolo"},
    {"afip":"4528","location":"Don Pietro"},
    {"afip":"4532","location":"Donadeu"},
    {"afip":"4541","location":"Dos Arboles"},
    {"afip":"4546","location":"Dos Eulacias"},
    {"afip":"4547","location":"Dos Eulalias"},
    {"afip":"4557","location":"Dos Represas"},
    {"afip":"4561","location":"Dos Varones"},
    {"afip":"4566","location":"Doña Lorenza"}
    ,
    {"afip":"4567","location":"Doña Luisa"}
    ,
    {"afip":"4591","location":"Ea La Verde"},
    {"afip":"4609","location":"El 21"},
    {"afip":"4617","location":"El Aerolito"},
    {"afip":"4618","location":"El Agricultor"},
    {"afip":"4626","location":"El Aibe"},
    {"afip":"4655","location":"El Arbol De Piedra"},
    {"afip":"4665","location":"El Aspirante"},
    {"afip":"4676","location":"El Añil"}
    ,
    {"afip":"4697","location":"El Bañadero"}
    ,
    {"afip":"4703","location":"El Bobadal"},
    {"afip":"4712","location":"El Bordito"},
    {"afip":"4720","location":"El Bravo"},
    {"afip":"4730","location":"El Cabure"},
    {"afip":"4731","location":"El Cachi"},
    {"afip":"4745","location":"El Cambiado"},
    {"afip":"4749","location":"El Canal"},
    {"afip":"4751","location":"El Candelero"},
    {"afip":"4791","location":"El Cebolliin"},
    {"afip":"4792","location":"El Cebollin"},
    {"afip":"4805","location":"El Cerrito Monte Quemado"},
    {"afip":"4837","location":"El Chinchillar"},
    {"afip":"4852","location":"El Churqui"},
    {"afip":"4870","location":"El Colmenar"},
    {"afip":"4885","location":"El Corrido"},
    {"afip":"4890","location":"El Crece"},
    {"afip":"4897","location":"El Crucero"},
    {"afip":"4900","location":"El Cuarenta Y Nueve"},
    {"afip":"4906","location":"El Cuello"},
    {"afip":"4912","location":"El Dean"},
    {"afip":"4924","location":"El Diablo"},
    {"afip":"4925","location":"El Diamante"},
    {"afip":"4942","location":"El Empachado"},
    {"afip":"4958","location":"El Favorito"},
    {"afip":"4961","location":"El Fisco"},
    {"afip":"4962","location":"El Fisco De Fatima"},
    {"afip":"4965","location":"El Florido"},
    {"afip":"4991","location":"El Gramillar"},
    {"afip":"5002","location":"El Guayacan"},
    {"afip":"5034","location":"El Juncal"},
    {"afip":"5061","location":"El Malacara"},
    {"afip":"5072","location":"El Marne"},
    {"afip":"5095","location":"El Molar"},
    {"afip":"5111","location":"El Mulato"},
    {"afip":"5118","location":"El Negrito"},
    {"afip":"5120","location":"El Nerio"},
    {"afip":"5129","location":"El Noventa"},
    {"afip":"5142","location":"El Olivar"},
    {"afip":"5148","location":"El Once"},
    {"afip":"5150","location":"El Oscuro"},
    {"afip":"5182","location":"El Parana"},
    {"afip":"5214","location":"El Perseguido"},
    {"afip":"5217","location":"El Peru"},
    {"afip":"5237","location":"El Pinto"},
    {"afip":"5240","location":"El Pirucho"},
    {"afip":"5298","location":"El Quillin"},
    {"afip":"5305","location":"El Rajo"},
    {"afip":"5310","location":"El Real"},
    {"afip":"5364","location":"El Segundo"},
    {"afip":"5367","location":"El Setenta"},
    {"afip":"5368","location":"El Señuelo"}
    ,
    {"afip":"5382","location":"El Solitario"},
    {"afip":"5394","location":"El Tacial"},
    {"afip":"5408","location":"El Tanque"},
    {"afip":"5426","location":"El Tobiano"},
    {"afip":"5443","location":"El Traslado"},
    {"afip":"5462","location":"El Ucle"},
    {"afip":"5463","location":"El Unco"},
    {"afip":"5465","location":"El Urunday"},
    {"afip":"5471","location":"El Valle De Oriente"},
    {"afip":"5478","location":"El Veinte"},
    {"afip":"5480","location":"El Veinticinco  Sumampa"},
    {"afip":"5481","location":"El Veinticinco De Mayo"},
    {"afip":"5482","location":"El Veintisiete"},
    {"afip":"5486","location":"El Venticinco"},
    {"afip":"5494","location":"El Vinalar"},
    {"afip":"5497","location":"El Vizcacheral"},
    {"afip":"5498","location":"El Viñalito"}
    ,
    {"afip":"5592","location":"Envidia"},
    {"afip":"5608","location":"Escalera"},
    {"afip":"5627","location":"Espinal"},
    {"afip":"5656","location":"Establecimiento 14 De Setiembr"},
    {"afip":"5685","location":"Estacion Atamisqui"},
    {"afip":"5719","location":"Estacion Pampa Muyoj"},
    {"afip":"5739","location":"Estados Unidos"},
    {"afip":"5831","location":"Estancia La Agustina"},
    {"afip":"5845","location":"Estancia La Elsita"},
    {"afip":"5854","location":"Estancia La Invernada"},
    {"afip":"5928","location":"Estancia Nueva Esperanza"},
    {"afip":"5999","location":"Esteco"},
    {"afip":"6009","location":"Esteros"},
    {"afip":"6039","location":"Ezcurra"},
    {"afip":"6074","location":"Farol"},
    {"afip":"6081","location":"Favorina"},
    {"afip":"6082","location":"Favorita"},
    {"afip":"6161","location":"Fivialtos"},
    {"afip":"6207","location":"Fortin Inca"},
    {"afip":"6210","location":"Fortin La Viuda"},
    {"afip":"6263","location":"Frias"},
    {"afip":"6297","location":"Galeano"},
    {"afip":"6301","location":"Gallegos"},
    {"afip":"6319","location":"Garceano"},
    {"afip":"6337","location":"Garza"},
    {"afip":"6340","location":"Gaspar Suarez"},
    {"afip":"6420","location":"Genoveva"},
    {"afip":"6431","location":"Gibialto"},
    {"afip":"6437","location":"Girardet"},
    {"afip":"6522","location":"Gramilla"},
    {"afip":"6523","location":"Gramillal"},
    {"afip":"6530","location":"Granadero Gatica"},
    {"afip":"6561","location":"Guaipi"},
    {"afip":"6563","location":"Gualamba"},
    {"afip":"6576","location":"Guampacha"},
    {"afip":"6582","location":"Guanaco Sombriana"},
    {"afip":"6583","location":"Guanacuyoj"},
    {"afip":"6591","location":"Guarcan"},
    {"afip":"6594","location":"Guardia"},
    {"afip":"6595","location":"Guardia De La Esquina"},
    {"afip":"6597","location":"Guardia Del Norte"},
    {"afip":"6598","location":"Guardia Escolta"},
    {"afip":"6605","location":"Guatana"},
    {"afip":"6621","location":"Guaype"},
    {"afip":"6627","location":"Guañagasta"}
    ,
    {"afip":"6631","location":"Guerra"},
    {"afip":"6642","location":"Guiñao"}
    ,
    {"afip":"6649","location":"Haase"},
    {"afip":"6652","location":"Hahuancuyos"},
    {"afip":"6683","location":"Hazan Villa"},
    {"afip":"6694","location":"Hernan Mejia Miraval"},
    {"afip":"6705","location":"Higuera Chaqui"},
    {"afip":"6713","location":"Hilumampa"},
    {"afip":"6740","location":"Horcos Tucucuna"},
    {"afip":"6762","location":"Hoyo Cerco"},
    {"afip":"6763","location":"Hoyo Con Agua"},
    {"afip":"6764","location":"Hoyon"},
    {"afip":"6768","location":"Huacanitas"},
    {"afip":"6769","location":"Huachana"},
    {"afip":"6780","location":"Huajia"},
    {"afip":"6785","location":"Hualo Cancana"},
    {"afip":"6803","location":"Huascan"},
    {"afip":"6806","location":"Huascho  Patilla"},
    {"afip":"6814","location":"Huchupayana"},
    {"afip":"6832","location":"Huilla Catina"},
    {"afip":"6852","location":"Huritu Huasi"},
    {"afip":"6855","location":"Hutcu Chacra"},
    {"afip":"6856","location":"Huturungo"},
    {"afip":"6857","location":"Huyamampa"},
    {"afip":"6858","location":"Huñajcito"}
    ,
    {"afip":"6871","location":"Ichagon"},
    {"afip":"6885","location":"Iliages"},
    {"afip":"6903","location":"Industria Nueva"},
    {"afip":"6923","location":"Ingeniero Carlos Christiernson"},
    {"afip":"6927","location":"Ingeniero Ezcurra"},
    {"afip":"6928","location":"Ingeniero Forres"},
    {"afip":"6976","location":"Inti Huasi"},
    {"afip":"7004","location":"Isca Yacu"},
    {"afip":"7005","location":"Isca Yacu Semaul"},
    {"afip":"7017","location":"Isla Baja"},
    {"afip":"7022","location":"Isla De Aragones"},
    {"afip":"7025","location":"Isla De Los Castillos"},
    {"afip":"7027","location":"Isla De Los Sotelos"},
    {"afip":"7048","location":"Isla Mota"},
    {"afip":"7097","location":"Iuchan"},
    {"afip":"7104","location":"Jacimampa"},
    {"afip":"7124","location":"Janta"},
    {"afip":"7127","location":"Jardin De Las Delicias"},
    {"afip":"7148","location":"Jimenez"},
    {"afip":"7224","location":"Juanillo"},
    {"afip":"7239","location":"Jume Esquina"},
    {"afip":"7241","location":"Jumeal O Jumial"},
    {"afip":"7242","location":"Jumi Pozo"},
    {"afip":"7243","location":"Jumi Viejo"},
    {"afip":"7244","location":"Jumial"},
    {"afip":"7245","location":"Jumial Grande"},
    {"afip":"7246","location":"Jumialito"},
    {"afip":"7247","location":"Junalito"},
    {"afip":"7249","location":"Juncal Grande"},
    {"afip":"7269","location":"Kenti Tacko"},
    {"afip":"7270","location":"Kenti Taco"},
    {"afip":"7275","location":"Kiska Hurmana"},
    {"afip":"7276","location":"Kiska Loro"},
    {"afip":"7287","location":"La Abrita"},
    {"afip":"7300","location":"La Aida"},
    {"afip":"7305","location":"La Alemana"},
    {"afip":"7309","location":"La Aloja"},
    {"afip":"7344","location":"La Balanza"},
    {"afip":"7370","location":"La Bella Criolla"},
    {"afip":"7437","location":"La Casimira"},
    {"afip":"7456","location":"La Centella"},
    {"afip":"7469","location":"La Chejchilla"},
    {"afip":"7501","location":"La Clemira"},
    {"afip":"7505","location":"La Codicia"},
    {"afip":"7543","location":"La Cuarteada"},
    {"afip":"7552","location":"La Darsena"},
    {"afip":"7564","location":"La Donosa"},
    {"afip":"7591","location":"La Encalada"},
    {"afip":"7596","location":"La Ensenada"},
    {"afip":"7629","location":"La Eulalia"},
    {"afip":"7658","location":"La Francisca"},
    {"afip":"7675","location":"La Geraldina"},
    {"afip":"7681","location":"La Golondrina"},
    {"afip":"7684","location":"La Grama"},
    {"afip":"7686","location":"La Grana"},
    {"afip":"7687","location":"La Granada"},
    {"afip":"7692","location":"La Griteria"},
    {"afip":"7704","location":"La Hiedra"},
    {"afip":"7773","location":"La Libia"},
    {"afip":"7788","location":"La Lomada"},
    {"afip":"7831","location":"La Marta"},
    {"afip":"7844","location":"La Melada"},
    {"afip":"7901","location":"La Paciencia"},
    {"afip":"7908","location":"La Paliza"},
    {"afip":"7921","location":"La Panchita"},
    {"afip":"7948","location":"La Petronila"},
    {"afip":"7989","location":"La Potocha"},
    {"afip":"8005","location":"La Puerta Del Monte"},
    {"afip":"8010","location":"La Punta"},
    {"afip":"8034","location":"La Recompensa"},
    {"afip":"8035","location":"La Reconquista"},
    {"afip":"8045","location":"La Resbalosa"},
    {"afip":"8050","location":"La Revancha"},
    {"afip":"8058","location":"La Rivera"},
    {"afip":"8059","location":"La Romelia"},
    {"afip":"8084","location":"La Santafecina"},
    {"afip":"8103","location":"La Simona"},
    {"afip":"8117","location":"La Tala"},
    {"afip":"8120","location":"La Tapa"},
    {"afip":"8186","location":"La Virtud"},
    {"afip":"8194","location":"La Vuelta"},
    {"afip":"8196","location":"La Yerba"},
    {"afip":"8233","location":"Lago Muyoj"},
    {"afip":"8248","location":"Laguna"},
    {"afip":"8252","location":"Laguna Baya"},
    {"afip":"8278","location":"Laguna Del Suncho"},
    {"afip":"8322","location":"Lagunitas"},
    {"afip":"8358","location":"Las Abras De San Antonio"},
    {"afip":"8366","location":"Las Aguilas"},
    {"afip":"8369","location":"Las Almas"},
    {"afip":"8409","location":"Las Cantinas"},
    {"afip":"8430","location":"Las Cejas"},
    {"afip":"8446","location":"Las Colinas"},
    {"afip":"8473","location":"Las Dos Flores"},
    {"afip":"8519","location":"Las Islas"},
    {"afip":"8578","location":"Las Orellanas"},
    {"afip":"8604","location":"Las Perforaciones"},
    {"afip":"8626","location":"Las Porteñas"}
    ,
    {"afip":"8631","location":"Las Quebradas"},
    {"afip":"8638","location":"Las Randas"},
    {"afip":"8658","location":"Las Talitas"},
    {"afip":"8663","location":"Las Teresas"},
    {"afip":"8666","location":"Las Tigreras"},
    {"afip":"8667","location":"Las Tinajas"},
    {"afip":"8686","location":"Las Trincheras"},
    {"afip":"8706","location":"Las Viboritas"},
    {"afip":"8717","location":"Laspa"},
    {"afip":"8725","location":"Laureles"},
    {"afip":"8745","location":"Lechuzas"},
    {"afip":"8754","location":"Leiva"},
    {"afip":"8767","location":"Lescano"},
    {"afip":"8773","location":"Lezcanos"},
    {"afip":"8776","location":"Libanesa"},
    {"afip":"8792","location":"Lilo Viejo"},
    {"afip":"8802","location":"Lincho"},
    {"afip":"8816","location":"Linton"},
    {"afip":"8827","location":"Llajta Mauca"},
    {"afip":"8828","location":"Llama Pampa"},
    {"afip":"8847","location":"Lojlo"},
    {"afip":"8864","location":"Loma De Yeso"},
    {"afip":"8919","location":"Lomitas Blancas"},
    {"afip":"8953","location":"Los Alderete"},
    {"afip":"8954","location":"Los Alderetes"},
    {"afip":"8975","location":"Los Arbolitos"},
    {"afip":"8979","location":"Los Arias"},
    {"afip":"9031","location":"Los Caños"}
    ,
    {"afip":"9065","location":"Los Cobres"},
    {"afip":"9087","location":"Los Correas"},
    {"afip":"9091","location":"Los Cruces"},
    {"afip":"9097","location":"Los Decimas"},
    {"afip":"9100","location":"Los Diaz"},
    {"afip":"9101","location":"Los Doce Quebrachos"},
    {"afip":"9109","location":"Los Encantos"},
    {"afip":"9119","location":"Los Fierros"},
    {"afip":"9125","location":"Los Gallardos"},
    {"afip":"9128","location":"Los Gatos"},
    {"afip":"9144","location":"Los Guerreros"},
    {"afip":"9150","location":"Los Herreras"},
    {"afip":"9151","location":"Los Herreros"},
    {"afip":"9167","location":"Los Juries"},
    {"afip":"9171","location":"Los Laterales"},
    {"afip":"9176","location":"Los Linares"},
    {"afip":"9186","location":"Los Marcos"},
    {"afip":"9201","location":"Los Milagros"},
    {"afip":"9207","location":"Los Mollares"},
    {"afip":"9216","location":"Los Moyas"},
    {"afip":"9226","location":"Los Nuñez"}
    ,
    {"afip":"9252","location":"Los Pecariel"},
    {"afip":"9259","location":"Los Pensamientos"},
    {"afip":"9260","location":"Los Pereyra"},
    {"afip":"9270","location":"Los Pirpintos"},
    {"afip":"9278","location":"Los Porteños"}
    ,
    {"afip":"9286","location":"Los Puntos"},
    {"afip":"9295","location":"Los Quiroga"},
    {"afip":"9303","location":"Los Remansos"},
    {"afip":"9340","location":"Los Sunchos"},
    {"afip":"9344","location":"Los Tableros"},
    {"afip":"9355","location":"Los Telares"},
    {"afip":"9424","location":"Lugones"},
    {"afip":"9480","location":"Maco"},
    {"afip":"9481","location":"Maco Yanda"},
    {"afip":"9486","location":"Maderas"},
    {"afip":"9498","location":"Maguito"},
    {"afip":"9500","location":"Maidana"},
    {"afip":"9501","location":"Mailin"},
    {"afip":"9510","location":"Majadas"},
    {"afip":"9511","location":"Majadas Sud"},
    {"afip":"9514","location":"Majancito"},
    {"afip":"9527","location":"Malbran"},
    {"afip":"9550","location":"Mallin Viejo"},
    {"afip":"9551","location":"Malota"},
    {"afip":"9575","location":"Manchin"},
    {"afip":"9583","location":"Manfloa"},
    {"afip":"9585","location":"Manga Bajada"},
    {"afip":"9591","location":"Manogasta"},
    {"afip":"9593","location":"Mansupa"},
    {"afip":"9611","location":"Maquijata"},
    {"afip":"9617","location":"Maquita"},
    {"afip":"9618","location":"Maquitis"},
    {"afip":"9619","location":"Maquito"},
    {"afip":"9657","location":"Maria Delicia"},
    {"afip":"9702","location":"Martin Paso"},
    {"afip":"9718","location":"Masumpa"},
    {"afip":"9730","location":"Matara"},
    {"afip":"9731","location":"Mate Pampa"},
    {"afip":"9795","location":"Medellin"},
    {"afip":"9797","location":"Media Flor"},
    {"afip":"9800","location":"Medio Mundo"},
    {"afip":"9805","location":"Melero"},
    {"afip":"9850","location":"Miel De Palo"},
    {"afip":"9864","location":"Mili"},
    {"afip":"9925","location":"Minerva"},
    {"afip":"9943","location":"Mirandas"},
    {"afip":"9944","location":"Mirca"},
    {"afip":"9962","location":"Mistol Loma"},
    {"afip":"9965","location":"Mistol Muyoj"},
    {"afip":"9966","location":"Mistol Pampa"},
    {"afip":"9967","location":"Mistol Pozo"},
    {"afip":"9969","location":"Mistoles"},
    {"afip":"9970","location":"Mistolito"},
    {"afip":"9974","location":"Moconza"},
    {"afip":"9990","location":"Mojoncito"},
    {"afip":"10005","location":"Molle"},
    {"afip":"10014","location":"Molleoj"},
    {"afip":"10015","location":"Molles"},
    {"afip":"10033","location":"Monte Alto"},
    {"afip":"10048","location":"Monte Crecido"},
    {"afip":"10070","location":"Monte Quemado"},
    {"afip":"10085","location":"Montesino"},
    {"afip":"10095","location":"Moradito"},
    {"afip":"10098","location":"Morampa"},
    {"afip":"10100","location":"Morayos"},
    {"afip":"10101","location":"Morcillo"},
    {"afip":"10103","location":"Morello"},
    {"afip":"10173","location":"Nanda"},
    {"afip":"10180","location":"Naquito"},
    {"afip":"10184","location":"Naranjitos"},
    {"afip":"10189","location":"Nasalo"},
    {"afip":"10204","location":"Negra Muerta"},
    {"afip":"10253","location":"Noge"},
    {"afip":"10261","location":"Norqueoj"},
    {"afip":"10265","location":"Novillo"},
    {"afip":"10269","location":"Nueva Alza"},
    {"afip":"10271","location":"Nueva Antaje"},
    {"afip":"10273","location":"Nueva Aurora"},
    {"afip":"10278","location":"Nueva Ceres"},
    {"afip":"10280","location":"Nueva Colonia"},
    {"afip":"10286","location":"Nueva Francia"},
    {"afip":"10290","location":"Nueva Industria"},
    {"afip":"10300","location":"Nueva Trinidad"},
    {"afip":"10307","location":"Nueve Mistoles"},
    {"afip":"10309","location":"Nuevo Libano"},
    {"afip":"10310","location":"Nuevo Lujan"},
    {"afip":"10315","location":"Nuevo Simbolar"},
    {"afip":"10328","location":"Obraje Iriondo"},
    {"afip":"10330","location":"Obraje Los Tigres"},
    {"afip":"10331","location":"Obraje Mailin"},
    {"afip":"10332","location":"Obraje Maria Angelica"},
    {"afip":"10341","location":"Octavia"},
    {"afip":"10381","location":"Oncajan"},
    {"afip":"10382","location":"Oncan"},
    {"afip":"10402","location":"Oro Pampa"},
    {"afip":"10419","location":"Otumpa"},
    {"afip":"10425","location":"Ovejeros"},
    {"afip":"10434","location":"Paaj Muyo"},
    {"afip":"10435","location":"Paaj Pozo"},
    {"afip":"10436","location":"Paaj Rodeo"},
    {"afip":"10447","location":"Paciencia"},
    {"afip":"10452","location":"Padua"},
    {"afip":"10475","location":"Pajaro Blanco"},
    {"afip":"10496","location":"Palizas"},
    {"afip":"10497","location":"Palma Flor"},
    {"afip":"10498","location":"Palma Larga"},
    {"afip":"10499","location":"Palma Pozo"},
    {"afip":"10500","location":"Palma Redonda"},
    {"afip":"10511","location":"Palmares"},
    {"afip":"10512","location":"Palmas"},
    {"afip":"10524","location":"Palo Lindo"},
    {"afip":"10538","location":"Palos Quemados"},
    {"afip":"10548","location":"Pampa Atun"},
    {"afip":"10573","location":"Pampa De Los Guanacos"},
    {"afip":"10615","location":"Pampa Mayo"},
    {"afip":"10619","location":"Pampa Muyoj"},
    {"afip":"10642","location":"Pampallajta"},
    {"afip":"10693","location":"Paraje El Prado"},
    {"afip":"10697","location":"Paraje Gauna"},
    {"afip":"10704","location":"Paraje La Pampa"},
    {"afip":"10708","location":"Paraje Lilo Viejo"},
    {"afip":"10711","location":"Paraje Milagro"},
    {"afip":"10716","location":"Paraje Obraje Maria Angelica"},
    {"afip":"10732","location":"Paraje Villa Yolanda"},
    {"afip":"10827","location":"Paso De Oscares"},
    {"afip":"10850","location":"Paso Del Saladillo"},
    {"afip":"10884","location":"Paso Mosoj"},
    {"afip":"10894","location":"Paso Reducido"},
    {"afip":"10928","location":"Patay"},
    {"afip":"10930","location":"Patillo"},
    {"afip":"10985","location":"Peludo Warcuna"},
    {"afip":"10986","location":"Penal Provincial"},
    {"afip":"10990","location":"Peralta"},
    {"afip":"10991","location":"Percas"},
    {"afip":"10992","location":"Perchil Bajo"},
    {"afip":"11002","location":"Perez De Zurita"},
    {"afip":"11075","location":"Picos De Amor"},
    {"afip":"11076","location":"Picos De Arroz"},
    {"afip":"11087","location":"Piedra Buena"},
    {"afip":"11152","location":"Pineda"},
    {"afip":"11153","location":"Pinedo"},
    {"afip":"11159","location":"Pinto - Villa General Mitre"},
    {"afip":"11176","location":"Pirhuas"},
    {"afip":"11182","location":"Piruitas"},
    {"afip":"11209","location":"Platero"},
    {"afip":"11268","location":"Pocito De La Loma"},
    {"afip":"11276","location":"Poleo Pozo"},
    {"afip":"11292","location":"Porongos"},
    {"afip":"11355","location":"Posta Sanitaria Pocitos"},
    {"afip":"11365","location":"Potrero Bajada"},
    {"afip":"11389","location":"Pozancon"},
    {"afip":"11391","location":"Pozo"},
    {"afip":"11395","location":"Pozo Betbeder"},
    {"afip":"11401","location":"Pozo Castaño"}
    ,
    {"afip":"11404","location":"Pozo Ciego"},
    {"afip":"11419","location":"Pozo De La Puerta"},
    {"afip":"11441","location":"Pozo Del Arbolito"},
    {"afip":"11446","location":"Pozo Del Castaño"}
    ,
    {"afip":"11453","location":"Pozo Del Garabato"},
    {"afip":"11459","location":"Pozo Del Macho"},
    {"afip":"11464","location":"Pozo Del Monte"},
    {"afip":"11473","location":"Pozo Del Toba"},
    {"afip":"11476","location":"Pozo Dulce"},
    {"afip":"11484","location":"Pozo Herrera"},
    {"afip":"11487","location":"Pozo Huascho"},
    {"afip":"11495","location":"Pozo Lerdo"},
    {"afip":"11496","location":"Pozo Limpio"},
    {"afip":"11497","location":"Pozo Lindo"},
    {"afip":"11498","location":"Pozo Marcado"},
    {"afip":"11500","location":"Pozo Mosoj"},
    {"afip":"11501","location":"Pozo Muerto"},
    {"afip":"11503","location":"Pozo Nuevo"},
    {"afip":"11505","location":"Pozo Redondo"},
    {"afip":"11514","location":"Pozo Vil"},
    {"afip":"11544","location":"Progreso De Jume"},
    {"afip":"11547","location":"Proviru"},
    {"afip":"11664","location":"Pueda Ser"},
    {"afip":"11668","location":"Puente Bajada"},
    {"afip":"11684","location":"Puente Del Saladillo"},
    {"afip":"11685","location":"Puente Del Salado"},
    {"afip":"11695","location":"Puente Negro"},
    {"afip":"11703","location":"Puente Rio Salado"},
    {"afip":"11713","location":"Puerta Chiquita"},
    {"afip":"11722","location":"Puerta De Las Piedras"},
    {"afip":"11725","location":"Puerta De Los Rios"},
    {"afip":"11730","location":"Puerta Del Cielo"},
    {"afip":"11732","location":"Puerta Del Monte"},
    {"afip":"11899","location":"Puestito De San Antonio"},
    {"afip":"11925","location":"Puesto Cordoba"},
    {"afip":"11934","location":"Puesto De Diaz"},
    {"afip":"11941","location":"Puesto De Juanes"},
    {"afip":"11956","location":"Puesto De Mena"},
    {"afip":"11966","location":"Puesto De Vieyra"},
    {"afip":"11974","location":"Puesto Del Retiro"},
    {"afip":"11976","location":"Puesto Del Simbol"},
    {"afip":"12040","location":"Puesto Los Marcos"},
    {"afip":"12104","location":"Puma"},
    {"afip":"12107","location":"Pumitayoj"},
    {"afip":"12108","location":"Puna"},
    {"afip":"12111","location":"Punco"},
    {"afip":"12113","location":"Puni Tajo"},
    {"afip":"12115","location":"Punita Norte"},
    {"afip":"12116","location":"Punita Sud"},
    {"afip":"12138","location":"Punta Del Garabato"},
    {"afip":"12158","location":"Punta Pozo"},
    {"afip":"12179","location":"Quebracho Coto"},
    {"afip":"12183","location":"Quebracho Pintado"},
    {"afip":"12185","location":"Quebracho Yacu"},
    {"afip":"12202","location":"Quebrada Esquina"},
    {"afip":"12209","location":"Quemadito"},
    {"afip":"12240","location":"Quilumpa"},
    {"afip":"12242","location":"Quimili"},
    {"afip":"12243","location":"Quimili Paso"},
    {"afip":"12244","location":"Quimilioj"},
    {"afip":"12245","location":"Quimilloj"},
    {"afip":"12270","location":"Quishca"},
    {"afip":"12273","location":"Quisña Loro"}
    ,
    {"afip":"12274","location":"Quita Punco"},
    {"afip":"12304","location":"Rama Paso"},
    {"afip":"12308","location":"Ramaditas"},
    {"afip":"12316","location":"Rami Yacu"},
    {"afip":"12319","location":"Ramirez De Velazco"},
    {"afip":"12354","location":"Rapelli"},
    {"afip":"12380","location":"Real Sayana"},
    {"afip":"12411","location":"Remansito"},
    {"afip":"12413","location":"Remansos"},
    {"afip":"12416","location":"Remes"},
    {"afip":"12418","location":"Reparo"},
    {"afip":"12419","location":"Repecho Diaz"},
    {"afip":"12420","location":"Repecho Montenegro"},
    {"afip":"12422","location":"Represa"},
    {"afip":"12444","location":"Rey Viejo"},
    {"afip":"12488","location":"Rincon De La Esperanza"},
    {"afip":"12529","location":"Rincon Esperanza"},
    {"afip":"12573","location":"Rio De Gallo"},
    {"afip":"12606","location":"Rio Nambi"},
    {"afip":"12617","location":"Rio Saladillo"},
    {"afip":"12645","location":"Robles"},
    {"afip":"12653","location":"Rodeo Bajada"},
    {"afip":"12660","location":"Rodeo De Soria"},
    {"afip":"12661","location":"Rodeo De Valdez"},
    {"afip":"12682","location":"Roma"},
    {"afip":"12686","location":"Romanos"},
    {"afip":"12695","location":"Rosada"},
    {"afip":"12710","location":"Rosiyulloj"},
    {"afip":"12714","location":"Roversi"},
    {"afip":"12717","location":"Rubia Moreno"},
    {"afip":"12734","location":"Rumi"},
    {"afip":"12737","location":"Rumi Esquina"},
    {"afip":"12738","location":"Rumi Huasi"},
    {"afip":"12739","location":"Rumi Jaco"},
    {"afip":"12743","location":"Rumiarco"},
    {"afip":"12747","location":"Rumios"},
    {"afip":"12754","location":"Ruta Nacional 34"},
    {"afip":"12759","location":"Ruta Provincial 11"},
    {"afip":"12760","location":"Ruta Provincial 130"},
    {"afip":"12761","location":"Ruta Provincial 17"},
    {"afip":"12780","location":"Ruta Provincial 40"},
    {"afip":"12781","location":"Ruta Provincial 5"},
    {"afip":"12782","location":"Ruta Provincial 8"},
    {"afip":"12788","location":"Sabagasta"},
    {"afip":"12792","location":"Sachayoj"},
    {"afip":"12800","location":"Sainquen Punco"},
    {"afip":"12828","location":"Salavina"},
    {"afip":"12834","location":"Saldivar"},
    {"afip":"12867","location":"Salviaioj Gaitan"},
    {"afip":"12889","location":"San Antonio De Copo"},
    {"afip":"12892","location":"San Antonio De Las Flores"},
    {"afip":"12894","location":"San Antonio De Los Caceres"},
    {"afip":"12938","location":"San Delfin"},
    {"afip":"12957","location":"San Felix"},
    {"afip":"12972","location":"San Francisco Lavalle"},
    {"afip":"12986","location":"San Horacio"},
    {"afip":"13014","location":"San Jose De Flores"},
    {"afip":"13034","location":"San Jose Del Boqueron"},
    {"afip":"13040","location":"San Jose Dto Figueroa"},
    {"afip":"13088","location":"San Mateo"},
    {"afip":"13096","location":"San Miguel Del Matara"},
    {"afip":"13111","location":"San Pastor"},
    {"afip":"13138","location":"San Ramon Quebrachos"},
    {"afip":"13143","location":"San Romano"},
    {"afip":"13162","location":"Sanavirones"},
    {"afip":"13167","location":"Sandia Huajchu"},
    {"afip":"13175","location":"Sansioj"},
    {"afip":"13184","location":"Santa Barbara Ferreira"},
    {"afip":"13186","location":"Santa Brigida"},
    {"afip":"13221","location":"Santa Justina"},
    {"afip":"13232","location":"Santa Maria De Las Chacras"},
    {"afip":"13237","location":"Santa Maria Dto Figueroa"},
    {"afip":"13252","location":"Santa Rosa  Arraga"},
    {"afip":"13253","location":"Santa Rosa Copo"},
    {"afip":"13258","location":"Santa Rosa De Coronel"},
    {"afip":"13264","location":"Santa Rosa De Vitervo"},
    {"afip":"13288","location":"Santiago Del Estero"},
    {"afip":"13298","location":"Santo Domingo Chico"},
    {"afip":"13300","location":"Santo Domingo Pellegrini"},
    {"afip":"13301","location":"Santo Domingo Robles"},
    {"afip":"13343","location":"Sauce Esquina"},
    {"afip":"13362","location":"Saucen"},
    {"afip":"13363","location":"Sauces"},
    {"afip":"13365","location":"Saucioj"},
    {"afip":"13374","location":"Sayaco"},
    {"afip":"13397","location":"Segundo Pozo"},
    {"afip":"13400","location":"Selva"},
    {"afip":"13401","location":"Selva Blanca"},
    {"afip":"13413","location":"Sepulturas"},
    {"afip":"13418","location":"Serrano Muerto"},
    {"afip":"13421","location":"Sesteadero"},
    {"afip":"13429","location":"Señora Pujio"}
    ,
    {"afip":"13432","location":"Shishi Pozo"},
    {"afip":"13475","location":"Silipica"},
    {"afip":"13477","location":"Simbol"},
    {"afip":"13478","location":"Simbol Bajo"},
    {"afip":"13479","location":"Simbol Cañada"}
    ,
    {"afip":"13480","location":"Simbol Huasi"},
    {"afip":"13481","location":"Simbol Pozo"},
    {"afip":"13486","location":"Sin Descanso"},
    {"afip":"13488","location":"Sinchi Caña   Establecimiento"}
    ,
    {"afip":"13491","location":"Sinquen Punco"},
    {"afip":"13507","location":"Sol De Julio"},
    {"afip":"13541","location":"Soria Bajada"},
    {"afip":"13545","location":"Sotelillos"},
    {"afip":"13546","location":"Sotelos"},
    {"afip":"13556","location":"Stayle"},
    {"afip":"13577","location":"Sumamao"},
    {"afip":"13579","location":"Sumampa Viejo"},
    {"afip":"13584","location":"Sunchituyoj"},
    {"afip":"13586","location":"Suncho Corral"},
    {"afip":"13588","location":"Suncho Pozo"},
    {"afip":"13589","location":"Suncho Pujio"},
    {"afip":"13594","location":"Superintendente Ledesma"},
    {"afip":"13595","location":"Suri"},
    {"afip":"13598","location":"Suri Pozo"},
    {"afip":"13599","location":"Surihuaya"},
    {"afip":"13616","location":"Tabeanita"},
    {"afip":"13617","location":"Tabiana"},
    {"afip":"13619","location":"Tablada Del Boqueron"},
    {"afip":"13623","location":"Tableado"},
    {"afip":"13625","location":"Taboada Estacion"},
    {"afip":"13629","location":"Tacamampa"},
    {"afip":"13631","location":"Tacanas"},
    {"afip":"13632","location":"Tacanitas"},
    {"afip":"13633","location":"Tacañitas"}
    ,
    {"afip":"13634","location":"Tacioj"},
    {"afip":"13636","location":"Taco Atun"},
    {"afip":"13637","location":"Taco Bajada"},
    {"afip":"13639","location":"Taco Esquina"},
    {"afip":"13640","location":"Taco Fura"},
    {"afip":"13641","location":"Taco Huaco"},
    {"afip":"13642","location":"Taco Isla"},
    {"afip":"13644","location":"Taco Misqui"},
    {"afip":"13645","location":"Taco Palta"},
    {"afip":"13648","location":"Taco Pujio"},
    {"afip":"13649","location":"Taco Punco"},
    {"afip":"13650","location":"Taco Quinka"},
    {"afip":"13653","location":"Taco Suyo"},
    {"afip":"13654","location":"Taco Totarayol"},
    {"afip":"13657","location":"Tacon Esquina"},
    {"afip":"13659","location":"Tacoyoj"},
    {"afip":"13679","location":"Tagan"},
    {"afip":"13681","location":"Tajamar"},
    {"afip":"13685","location":"Tala Atun"},
    {"afip":"13698","location":"Tala Pozo"},
    {"afip":"13703","location":"Tala Yacu"},
    {"afip":"13754","location":"Taperas"},
    {"afip":"13760","location":"Taquello"},
    {"afip":"13762","location":"Taquetuyoj"},
    {"afip":"13769","location":"Tarpuna"},
    {"afip":"13776","location":"Taruy"},
    {"afip":"13807","location":"Tenene"},
    {"afip":"13813","location":"Tenti Taco"},
    {"afip":"13831","location":"Termas De Rio Hondo"},
    {"afip":"13843","location":"Tibilar"},
    {"afip":"13850","location":"Tiestituyos"},
    {"afip":"13875","location":"Tinajeraloj"},
    {"afip":"13877","location":"Tinap Jerayoj"},
    {"afip":"13878","location":"Tinco Colonia"},
    {"afip":"13884","location":"Tintina"},
    {"afip":"13887","location":"Tio Alto"},
    {"afip":"13888","location":"Tio Chacra"},
    {"afip":"13892","location":"Tio Pozo"},
    {"afip":"13901","location":"Tipiro"},
    {"afip":"13909","location":"Tiun Punco"},
    {"afip":"13913","location":"Tobas"},
    {"afip":"13931","location":"Tolozas"},
    {"afip":"13940","location":"Tomas Young"},
    {"afip":"13941","location":"Tome Y Traiga"},
    {"afip":"13946","location":"Tontola"},
    {"afip":"13947","location":"Tonzu"},
    {"afip":"13957","location":"Toro Charquina"},
    {"afip":"13960","location":"Toro Human"},
    {"afip":"13968","location":"Toro Pozo"},
    {"afip":"13971","location":"Toropan"},
    {"afip":"13990","location":"Totora Pampa"},
    {"afip":"13996","location":"Totorilla Nuevo"},
    {"afip":"14005","location":"Tramo 16"},
    {"afip":"14006","location":"Tramo 20"},
    {"afip":"14007","location":"Tramo Veintiseis"},
    {"afip":"14016","location":"Traslado"},
    {"afip":"14038","location":"Tres Bajadas"},
    {"afip":"14039","location":"Tres Bajos"},
    {"afip":"14052","location":"Tres De Marzo"},
    {"afip":"14055","location":"Tres Flores"},
    {"afip":"14062","location":"Tres Jazmines"},
    {"afip":"14084","location":"Tres Quebrachos"},
    {"afip":"14088","location":"Tres Varones"},
    {"afip":"14109","location":"Troncal"},
    {"afip":"14110","location":"Tronco Blanco"},
    {"afip":"14111","location":"Tronco Juras"},
    {"afip":"14113","location":"Tronco Quemado"},
    {"afip":"14115","location":"Troncos Quemados"},
    {"afip":"14120","location":"Trozo Pozo"},
    {"afip":"14144","location":"Tulum"},
    {"afip":"14147","location":"Tulun"},
    {"afip":"14153","location":"Tunales"},
    {"afip":"14155","location":"Tunas Punco"},
    {"afip":"14162","location":"Turena"},
    {"afip":"14166","location":"Tusca Bajada"},
    {"afip":"14168","location":"Tusca Pozo"},
    {"afip":"14171","location":"Tuscayoj"},
    {"afip":"14184","location":"Uclar"},
    {"afip":"14199","location":"Upianita"},
    {"afip":"14213","location":"Urutau"},
    {"afip":"14222","location":"Utrunjos"},
    {"afip":"14223","location":"Uturunco"},
    {"afip":"14231","location":"Vaca Huañuna"}
    ,
    {"afip":"14232","location":"Vaca Human"},
    {"afip":"14243","location":"Valdivia"},
    {"afip":"14284","location":"Varas Cuchuna"},
    {"afip":"14303","location":"Veintiocho De Marzo"},
    {"afip":"14319","location":"Ventura Pampa"},
    {"afip":"14328","location":"Veron"},
    {"afip":"14364","location":"Vila Isla"},
    {"afip":"14367","location":"Vilelas"},
    {"afip":"14373","location":"Villa Abregu"},
    {"afip":"14409","location":"Villa Atamisqui"},
    {"afip":"14414","location":"Villa Balnearia"},
    {"afip":"14430","location":"Villa Brana"},
    {"afip":"14466","location":"Villa Coinor"},
    {"afip":"14473","location":"Villa Constantina"},
    {"afip":"14489","location":"Villa De La Barraca"},
    {"afip":"14539","location":"Villa Elvira"},
    {"afip":"14545","location":"Villa Esquina"},
    {"afip":"14552","location":"Villa Figueroa"},
    {"afip":"14592","location":"Villa Grimanesa"},
    {"afip":"14593","location":"Villa Guasayan"},
    {"afip":"14595","location":"Villa Guañuna"}
    ,
    {"afip":"14603","location":"Villa Hipolita"},
    {"afip":"14611","location":"Villa Isla"},
    {"afip":"14615","location":"Villa Jimenez"},
    {"afip":"14627","location":"Villa La Punta"},
    {"afip":"14683","location":"Villa Matara"},
    {"afip":"14684","location":"Villa Matilde"},
    {"afip":"14685","location":"Villa Matoque"},
    {"afip":"14729","location":"Villa Palmar"},
    {"afip":"14749","location":"Villa Quebrachos"},
    {"afip":"14766","location":"Villa Rio Hondo"},
    {"afip":"14771","location":"Villa Robles"},
    {"afip":"14845","location":"Villa Tolojna"},
    {"afip":"14869","location":"Villa Yolanda"},
    {"afip":"14871","location":"Villa Zanjon"},
    {"afip":"14885","location":"Villares"},
    {"afip":"14891","location":"Vilmer"},
    {"afip":"14893","location":"Vinal Macho"},
    {"afip":"14895","location":"Vinal Viejo"},
    {"afip":"14897","location":"Vinara"},
    {"afip":"14920","location":"Viteaca"},
    {"afip":"14925","location":"Viva Mercedes"},
    {"afip":"14947","location":"Vuelta De La Barranca"},
    {"afip":"14960","location":"Weisburd"},
    {"afip":"14969","location":"Wiñano"}
    ,
    {"afip":"14971","location":"Yacano"},
    {"afip":"14977","location":"Yacasnioj"},
    {"afip":"14984","location":"Yaco Misqui"},
    {"afip":"14990","location":"Yacu Hichacuna"},
    {"afip":"14991","location":"Yacu Hurmana"},
    {"afip":"14993","location":"Yacuchiri"},
    {"afip":"15004","location":"Yalan"},
    {"afip":"15010","location":"Yanda"},
    {"afip":"15013","location":"Yanta"},
    {"afip":"15024","location":"Yaso"},
    {"afip":"15047","location":"Yeso Alto"},
    {"afip":"15049","location":"Ylumampa"},
    {"afip":"15054","location":"Yolohuasi"},
    {"afip":"15061","location":"Yuchancito"},
    {"afip":"15063","location":"Yulu Huasi"},
    {"afip":"15064","location":"Yumampa"},
    {"afip":"15069","location":"Yunta Pozo"},
    {"afip":"15074","location":"Yuto Yaca"},
    {"afip":"15075","location":"Yutu Yaco"},
    {"afip":"15096","location":"Zapi Pozo"},
    {"afip":"15122","location":"Zorro Huarcuna"},
    {"afip":"15153","location":"25 De Mayo"},
    {"afip":"15165","location":"9 De Julio"},
    {"afip":"15167","location":"Abra Grande"},
    {"afip":"15171","location":"Acosta"},
    {"afip":"15177","location":"Agua Amarga"},
    {"afip":"15179","location":"Agua Blanca"},
    {"afip":"15181","location":"Agua Caliente"},
    {"afip":"15191","location":"Agua Salada"},
    {"afip":"15198","location":"Aguada"},
    {"afip":"15203","location":"Aguadita"},
    {"afip":"15212","location":"Ahi Veremos"},
    {"afip":"15214","location":"Alarcon"},
    {"afip":"15216","location":"Albardon"},
    {"afip":"15218","location":"Alberdi"},
    {"afip":"15226","location":"Algarrobal Viejo"},
    {"afip":"15227","location":"Algarrobales"},
    {"afip":"15231","location":"Algarrobo"},
    {"afip":"15238","location":"Alpapuca"},
    {"afip":"15241","location":"Alta Gracia"},
    {"afip":"15245","location":"Alto Alegre"},
    {"afip":"15246","location":"Alto Bello"},
    {"afip":"15271","location":"Animas"},
    {"afip":"15273","location":"Anjuli"},
    {"afip":"15275","location":"Arbol Blanco"},
    {"afip":"15281","location":"Arbol Solo"},
    {"afip":"15288","location":"Arenales"},
    {"afip":"15308","location":"Atahualpa"},
    {"afip":"15314","location":"Bahia Blanca"},
    {"afip":"15323","location":"Bajo Grande"},
    {"afip":"15329","location":"Bajo Hondo"},
    {"afip":"15331","location":"Bajo Verde"},
    {"afip":"15333","location":"Balbuena"},
    {"afip":"15344","location":"Barranca Colorada"},
    {"afip":"15349","location":"Barrancas"},
    {"afip":"15364","location":"Barrio Obrero"},
    {"afip":"15370","location":"Bayo Muerto"},
    {"afip":"15377","location":"Belgrano"},
    {"afip":"15391","location":"Bella Vista"},
    {"afip":"15393","location":"Beltran"},
    {"afip":"15404","location":"Boca Del Tigre"},
    {"afip":"15407","location":"Boqueron"},
    {"afip":"15408","location":"Botija"},
    {"afip":"15410","location":"Buen Lugar"},
    {"afip":"15417","location":"Buena Esperanza"},
    {"afip":"15427","location":"Buena Vista"},
    {"afip":"15430","location":"Buey Muerto"},
    {"afip":"15436","location":"Cachi"},
    {"afip":"15439","location":"Calderon"},
    {"afip":"15449","location":"Campo Alegre"},
    {"afip":"15455","location":"Campo Del Cielo"},
    {"afip":"15461","location":"Campo Grande"},
    {"afip":"15467","location":"Campo Nuevo"},
    {"afip":"15477","location":"Candelaria"},
    {"afip":"15524","location":"Cañada"}
    ,
    {"afip":"15534","location":"Cañada Honda"}
    ,
    {"afip":"15539","location":"Cañada Rica"}
    ,
    {"afip":"15547","location":"Cañitas"}
    ,
    {"afip":"15558","location":"Cerrillos"},
    {"afip":"15565","location":"Cerrito"},
    {"afip":"15610","location":"Chacras"},
    {"afip":"15623","location":"Chañar Aguada"}
    ,
    {"afip":"15624","location":"Chañar Laguna"}
    ,
    {"afip":"15630","location":"Chañaritos"}
    ,
    {"afip":"15632","location":"Chilca"},
    {"afip":"15644","location":"Choya"},
    {"afip":"15653","location":"Colombia"},
    {"afip":"15677","location":"Colonia España"}
    ,
    {"afip":"15694","location":"Colonia Mackinlay"},
    {"afip":"15696","location":"Colonia Maria Luisa"},
    {"afip":"15723","location":"Colonia San Juan"},
    {"afip":"15759","location":"Colonias"},
    {"afip":"15762","location":"Concepcion"},
    {"afip":"15767","location":"Condor Huasi"},
    {"afip":"15778","location":"Coropampa"},
    {"afip":"15782","location":"Corral Del Rey"},
    {"afip":"15785","location":"Corral Quemado"},
    {"afip":"15793","location":"Corralito"},
    {"afip":"15796","location":"Cortadera"},
    {"afip":"15802","location":"Cortaderas"},
    {"afip":"15813","location":"Cuatro Bocas"},
    {"afip":"15830","location":"Divisadero"},
    {"afip":"15832","location":"Dolores"},
    {"afip":"15833","location":"Dormida"},
    {"afip":"15837","location":"Dos Hermanas"},
    {"afip":"15839","location":"Dos Hermanos"},
    {"afip":"15848","location":"El Abra"},
    {"afip":"15853","location":"El Aguila"},
    {"afip":"15857","location":"El Aibal"},
    {"afip":"15858","location":"El Aibalito"},
    {"afip":"15860","location":"El Alambrado"},
    {"afip":"15869","location":"El Algarrobo"},
    {"afip":"15880","location":"El Arbolito"},
    {"afip":"15891","location":"El Bagual"},
    {"afip":"15894","location":"El Bajo"},
    {"afip":"15899","location":"El Balde"},
    {"afip":"15901","location":"El Baldecito"},
    {"afip":"15907","location":"El Barrial"},
    {"afip":"15921","location":"El Bosque"},
    {"afip":"15922","location":"El Bragado"},
    {"afip":"15929","location":"El Cadillo"},
    {"afip":"15933","location":"El Cajon"},
    {"afip":"15949","location":"El Carmen"},
    {"afip":"15962","location":"El Cañon"}
    ,
    {"afip":"15971","location":"El Cercado"},
    {"afip":"15979","location":"El Cerrito"},
    {"afip":"15982","location":"El Cerro"},
    {"afip":"15985","location":"El Charabon"},
    {"afip":"15986","location":"El Charco"},
    {"afip":"15989","location":"El Chañar"}
    ,
    {"afip":"15999","location":"El Cincuenta"},
    {"afip":"16005","location":"El Colorado"},
    {"afip":"16015","location":"El Cuadrado"},
    {"afip":"16019","location":"El Descanso"},
    {"afip":"16026","location":"El Destino"},
    {"afip":"16031","location":"El Divisadero"},
    {"afip":"16035","location":"El Dorado"},
    {"afip":"16046","location":"El Durazno"},
    {"afip":"16049","location":"El Escondido"},
    {"afip":"16062","location":"El Fraile"},
    {"afip":"16065","location":"El Fuerte"},
    {"afip":"16066","location":"El Galpon"},
    {"afip":"16075","location":"El Hoyo"},
    {"afip":"16076","location":"El Huaico"},
    {"afip":"16084","location":"El Jardin"},
    {"afip":"16087","location":"El Jume"},
    {"afip":"16095","location":"El Manantial"},
    {"afip":"16105","location":"El Mataco"},
    {"afip":"16110","location":"El Milagro"},
    {"afip":"16120","location":"El Mistol"},
    {"afip":"16124","location":"El Mojon"},
    {"afip":"16143","location":"El Molle"},
    {"afip":"16150","location":"El Naranjo"},
    {"afip":"16158","location":"El Ojo De Agua"},
    {"afip":"16161","location":"El Oso"},
    {"afip":"16163","location":"El Pacara"},
    {"afip":"16167","location":"El Palmar"},
    {"afip":"16169","location":"El Palomar"},
    {"afip":"16181","location":"El Paraiso"},
    {"afip":"16190","location":"El Peral"},
    {"afip":"16194","location":"El Pertigo"},
    {"afip":"16195","location":"El Pilar"},
    {"afip":"16222","location":"El Porvenir"},
    {"afip":"16231","location":"El Potrero"},
    {"afip":"16238","location":"El Prado"},
    {"afip":"16247","location":"El Pueblito"},
    {"afip":"16248","location":"El Puente"},
    {"afip":"16254","location":"El Puestito"},
    {"afip":"16259","location":"El Puesto"},
    {"afip":"16279","location":"El Quemado"},
    {"afip":"16285","location":"El Recreo"},
    {"afip":"16291","location":"El Remanso"},
    {"afip":"16292","location":"El Remate"},
    {"afip":"16304","location":"El Retiro"},
    {"afip":"16309","location":"El Rincon"},
    {"afip":"16317","location":"El Rodeo"},
    {"afip":"16326","location":"El Rosario"},
    {"afip":"16330","location":"El Saladillo"},
    {"afip":"16346","location":"El Salvador"},
    {"afip":"16357","location":"El Sauce"},
    {"afip":"16363","location":"El Silencio"},
    {"afip":"16365","location":"El Simbol"},
    {"afip":"16369","location":"El Simbolar"},
    {"afip":"16385","location":"El Tala"},
    {"afip":"16408","location":"El Trece"},
    {"afip":"16425","location":"El Valle"},
    {"afip":"16431","location":"El Veinticinco"},
    {"afip":"16455","location":"Ensenada"},
    {"afip":"16466","location":"Esperanza"},
    {"afip":"16472","location":"Espinillo"},
    {"afip":"16482","location":"Estancia El Carmen"},
    {"afip":"16499","location":"Estancia Vieja"},
    {"afip":"16505","location":"Famatina"},
    {"afip":"16509","location":"Fernandez"},
    {"afip":"16510","location":"Fierro"},
    {"afip":"16519","location":"Floresta"},
    {"afip":"16524","location":"Florida"},
    {"afip":"16527","location":"Fortuna"},
    {"afip":"16554","location":"Guardia Vieja"},
    {"afip":"16555","location":"Guayacan"},
    {"afip":"16558","location":"Guaycuru"},
    {"afip":"16562","location":"Herrera"},
    {"afip":"16563","location":"Higuerillas"},
    {"afip":"16570","location":"Hornillos"},
    {"afip":"16574","location":"Humaita"},
    {"afip":"16575","location":"Icaño"}
    ,
    {"afip":"16598","location":"Isla Verde"},
    {"afip":"16616","location":"Jume"},
    {"afip":"16619","location":"La Abra"},
    {"afip":"16629","location":"La Aguada"},
    {"afip":"16635","location":"La Aguadita"},
    {"afip":"16641","location":"La America"},
    {"afip":"16645","location":"La Angelita"},
    {"afip":"16662","location":"La Argentina"},
    {"afip":"16669","location":"La Armonia"},
    {"afip":"16677","location":"La Aurora"},
    {"afip":"16685","location":"La Banda"},
    {"afip":"16689","location":"La Barrosa"},
    {"afip":"16694","location":"La Blanca"},
    {"afip":"16701","location":"La Bota"},
    {"afip":"16704","location":"La Brea"},
    {"afip":"16712","location":"La Calera"},
    {"afip":"16721","location":"La Capilla"},
    {"afip":"16728","location":"La Carolina"},
    {"afip":"16744","location":"La Cañada"}
    ,
    {"afip":"16759","location":"La Chilca"},
    {"afip":"16784","location":"La Colina"},
    {"afip":"16792","location":"La Colonia"},
    {"afip":"16799","location":"La Concepcion"},
    {"afip":"16810","location":"La Cortadera"},
    {"afip":"16817","location":"La Costa"},
    {"afip":"16819","location":"La Costosa"},
    {"afip":"16826","location":"La Cruz"},
    {"afip":"16830","location":"La Cuesta"},
    {"afip":"16836","location":"La Curva"},
    {"afip":"16837","location":"La Defensa"},
    {"afip":"16840","location":"La Delia"},
    {"afip":"16842","location":"La Dolores"},
    {"afip":"16846","location":"La Dora"},
    {"afip":"16849","location":"La Dormida"},
    {"afip":"16871","location":"La Esmeralda"},
    {"afip":"16883","location":"La Esperanza"},
    {"afip":"16890","location":"La Esquina"},
    {"afip":"16897","location":"La Estancia"},
    {"afip":"16917","location":"La Falda"},
    {"afip":"16922","location":"La Feliciana"},
    {"afip":"16923","location":"La Firmeza"},
    {"afip":"16944","location":"La Florida"},
    {"afip":"16950","location":"La Fortuna"},
    {"afip":"16952","location":"La Fragua"},
    {"afip":"16958","location":"La Germania"},
    {"afip":"16965","location":"La Granja"},
    {"afip":"16966","location":"La Gringa"},
    {"afip":"16972","location":"La Guardia"},
    {"afip":"16978","location":"La Higuera"},
    {"afip":"16992","location":"La Huerta"},
    {"afip":"17001","location":"La Invernada"},
    {"afip":"17014","location":"La Isla"},
    {"afip":"17016","location":"La Isleta"},
    {"afip":"17025","location":"La Juliana"},
    {"afip":"17030","location":"La Laguna"},
    {"afip":"17040","location":"La Laura"},
    {"afip":"17057","location":"La Loma"},
    {"afip":"17065","location":"La Luna"},
    {"afip":"17070","location":"La Magdalena"},
    {"afip":"17073","location":"La Manga"},
    {"afip":"17079","location":"La Maravilla"},
    {"afip":"17103","location":"La Mesada"},
    {"afip":"17116","location":"La Nena"},
    {"afip":"17119","location":"La Noria"},
    {"afip":"17128","location":"La Overa"},
    {"afip":"17131","location":"La Palma"},
    {"afip":"17137","location":"La Paloma"},
    {"afip":"17143","location":"La Pampa"},
    {"afip":"17154","location":"La Paz"},
    {"afip":"17162","location":"La Perlita"},
    {"afip":"17169","location":"La Pintada"},
    {"afip":"17177","location":"La Polvareda"},
    {"afip":"17180","location":"La Porfia"},
    {"afip":"17188","location":"La Porteña"}
    ,
    {"afip":"17204","location":"La Primavera"},
    {"afip":"17205","location":"La Primitiva"},
    {"afip":"17207","location":"La Protegida"},
    {"afip":"17210","location":"La Providencia"},
    {"afip":"17215","location":"La Puerta"},
    {"afip":"17236","location":"La Ramada"},
    {"afip":"17239","location":"La Ramadita"},
    {"afip":"17243","location":"La Reduccion"},
    {"afip":"17254","location":"La Represa"},
    {"afip":"17263","location":"La Rinconada"},
    {"afip":"17272","location":"La Rosilla"},
    {"afip":"17284","location":"La Sarita"},
    {"afip":"17289","location":"La Selva"},
    {"afip":"17300","location":"La Soledad"},
    {"afip":"17305","location":"La Susana"},
    {"afip":"17309","location":"La Teresa"},
    {"afip":"17324","location":"La Totorilla"},
    {"afip":"17327","location":"La Trampa"},
    {"afip":"17333","location":"La Tusca"},
    {"afip":"17340","location":"La Union"},
    {"afip":"17351","location":"La Verde"},
    {"afip":"17358","location":"La Victoria"},
    {"afip":"17363","location":"La Viuda"},
    {"afip":"17378","location":"Laguna Blanca"},
    {"afip":"17391","location":"Laguna Larga"},
    {"afip":"17399","location":"Lagunilla"},
    {"afip":"17406","location":"Lapa"},
    {"afip":"17409","location":"Laprida"},
    {"afip":"17410","location":"Las Abras"},
    {"afip":"17414","location":"Las Aguadas"},
    {"afip":"17439","location":"Las Carpas"},
    {"afip":"17450","location":"Las Cañas"}
    ,
    {"afip":"17464","location":"Las Chacras"},
    {"afip":"17470","location":"Las Chilcas"},
    {"afip":"17472","location":"Las Cienagas"},
    {"afip":"17476","location":"Las Colonias"},
    {"afip":"17485","location":"Las Cruces"},
    {"afip":"17503","location":"Las Delicias"},
    {"afip":"17513","location":"Las Flores"},
    {"afip":"17515","location":"Las Gamas"},
    {"afip":"17523","location":"Las Hermanas"},
    {"afip":"17538","location":"Las Horquetas"},
    {"afip":"17539","location":"Las Isletas"},
    {"afip":"17544","location":"Las Juntas"},
    {"afip":"17554","location":"Las Lajas"},
    {"afip":"17560","location":"Las Lomas"},
    {"afip":"17566","location":"Las Lomitas"},
    {"afip":"17571","location":"Las Maravillas"},
    {"afip":"17589","location":"Las Mochas"},
    {"afip":"17594","location":"Las Mostazas"},
    {"afip":"17605","location":"Las Palmas"},
    {"afip":"17607","location":"Las Palmeras"},
    {"afip":"17610","location":"Las Palmitas"},
    {"afip":"17615","location":"Las Palomitas"},
    {"afip":"17621","location":"Las Parvas"},
    {"afip":"17627","location":"Las Peñas"}
    ,
    {"afip":"17640","location":"Las Puertas"},
    {"afip":"17644","location":"Las Ramaditas"},
    {"afip":"17654","location":"Las Rosas"},
    {"afip":"17662","location":"Las Salinas"},
    {"afip":"17664","location":"Las Talas"},
    {"afip":"17670","location":"Las Tejas"},
    {"afip":"17701","location":"Las Zanjas"},
    {"afip":"17705","location":"Lavalle"},
    {"afip":"17709","location":"Ledesma"},
    {"afip":"17713","location":"Libertad"},
    {"afip":"17715","location":"Limache"},
    {"afip":"17723","location":"Loma Colorada"},
    {"afip":"17727","location":"Loma Del Medio"},
    {"afip":"17729","location":"Loma Grande"},
    {"afip":"17737","location":"Loma Negra"},
    {"afip":"17749","location":"Lomas Blancas"},
    {"afip":"17751","location":"Lomitas"},
    {"afip":"17755","location":"Loreto"},
    {"afip":"17756","location":"Loro Huasi"},
    {"afip":"17771","location":"Los Algarrobos"},
    {"afip":"17777","location":"Los Angeles"},
    {"afip":"17804","location":"Los Carrizos"},
    {"afip":"17806","location":"Los Castillos"},
    {"afip":"17812","location":"Los Cerrillos"},
    {"afip":"17815","location":"Los Cerros"},
    {"afip":"17820","location":"Los Chañares"}
    ,
    {"afip":"17831","location":"Los Colorados"},
    {"afip":"17892","location":"Los Molles"},
    {"afip":"17895","location":"Los Morteros"},
    {"afip":"17898","location":"Los Naranjos"},
    {"afip":"17913","location":"Los Paraisos"},
    {"afip":"17916","location":"Los Paredones"},
    {"afip":"17929","location":"Los Pocitos"},
    {"afip":"17932","location":"Los Porongos"},
    {"afip":"17937","location":"Los Pozos"},
    {"afip":"17938","location":"Los Puentes"},
    {"afip":"17941","location":"Los Puestos"},
    {"afip":"17946","location":"Los Quebrachos"},
    {"afip":"17948","location":"Los Ralos"},
    {"afip":"17953","location":"Los Robles"},
    {"afip":"17962","location":"Los Sauces"},
    {"afip":"17968","location":"Los Tigres"},
    {"afip":"17980","location":"Lujan"},
    {"afip":"17984","location":"Magdalena"},
    {"afip":"17987","location":"Mal Paso"},
    {"afip":"18003","location":"Manantiales"},
    {"afip":"18005","location":"Mangrullo"},
    {"afip":"18009","location":"Maravilla"},
    {"afip":"18012","location":"Maria"},
    {"afip":"18020","location":"Martinez"},
    {"afip":"18030","location":"Medanos"},
    {"afip":"18036","location":"Media Luna"},
    {"afip":"18037","location":"Mendoza"},
    {"afip":"18040","location":"Mercedes"},
    {"afip":"18046","location":"Milagro"},
    {"afip":"18055","location":"Miramonte"},
    {"afip":"18059","location":"Mojon"},
    {"afip":"18065","location":"Molle Pozo"},
    {"afip":"18074","location":"Monte Cristo"},
    {"afip":"18082","location":"Monte Potrero"},
    {"afip":"18085","location":"Monte Redondo"},
    {"afip":"18087","location":"Monte Rico"},
    {"afip":"18091","location":"Monte Verde"},
    {"afip":"18093","location":"Monteagudo"},
    {"afip":"18097","location":"Montevideo"},
    {"afip":"18098","location":"Morales"},
    {"afip":"18100","location":"Moron"},
    {"afip":"18106","location":"Naranjito"},
    {"afip":"18110","location":"Navarro"},
    {"afip":"18114","location":"Nogales"},
    {"afip":"18124","location":"Nueva Esperanza"},
    {"afip":"18126","location":"Nueva Granada"},
    {"afip":"18139","location":"Ojo De Agua"},
    {"afip":"18148","location":"Oratorio"},
    {"afip":"18158","location":"Palermo"},
    {"afip":"18166","location":"Palmitas"},
    {"afip":"18170","location":"Palo A Pique"},
    {"afip":"18177","location":"Palo Negro"},
    {"afip":"18179","location":"Palo Parado"},
    {"afip":"18184","location":"Palomar"},
    {"afip":"18193","location":"Pampa Grande"},
    {"afip":"18197","location":"Pampa Pozo"},
    {"afip":"18207","location":"Parana"},
    {"afip":"18224","location":"Paso Grande"},
    {"afip":"18248","location":"Piedra Blanca"},
    {"afip":"18264","location":"Piedritas"},
    {"afip":"18273","location":"Pocitos"},
    {"afip":"18274","location":"Polvareda"},
    {"afip":"18275","location":"Polvaredas"},
    {"afip":"18277","location":"Porongal"},
    {"afip":"18278","location":"Portalis"},
    {"afip":"18281","location":"Portezuelo"},
    {"afip":"18299","location":"Pozancones"},
    {"afip":"18302","location":"Pozo Cabado"},
    {"afip":"18305","location":"Pozo Cercado"},
    {"afip":"18306","location":"Pozo Colorado"},
    {"afip":"18313","location":"Pozo Del Algarrobo"},
    {"afip":"18315","location":"Pozo Del Campo"},
    {"afip":"18317","location":"Pozo Del Chañar"}
    ,
    {"afip":"18321","location":"Pozo Del Simbol"},
    {"afip":"18326","location":"Pozo Escondido"},
    {"afip":"18328","location":"Pozo Grande"},
    {"afip":"18332","location":"Pozo Hondo"},
    {"afip":"18334","location":"Pozo Moro"},
    {"afip":"18336","location":"Pozo Salado"},
    {"afip":"18340","location":"Pozo Verde"},
    {"afip":"18342","location":"Pozuelos"},
    {"afip":"18346","location":"Primavera"},
    {"afip":"18352","location":"Providencia"},
    {"afip":"18356","location":"Pueblito"},
    {"afip":"18363","location":"Pueblo Nuevo"},
    {"afip":"18372","location":"Puerta Grande"},
    {"afip":"18380","location":"Puestito"},
    {"afip":"18382","location":"Puesto"},
    {"afip":"18385","location":"Puesto De Arriba"},
    {"afip":"18391","location":"Puesto Del Medio"},
    {"afip":"18393","location":"Puesto Del Rosario"},
    {"afip":"18397","location":"Puesto Nuevo"},
    {"afip":"18404","location":"Punta Corral"},
    {"afip":"18410","location":"Punta De Rieles"},
    {"afip":"18415","location":"Punta Del Agua"},
    {"afip":"18421","location":"Punta Del Monte"},
    {"afip":"18425","location":"Punta Rieles"},
    {"afip":"18430","location":"Quebrachal"},
    {"afip":"18432","location":"Quebrachito"},
    {"afip":"18434","location":"Quebrachitos"},
    {"afip":"18437","location":"Quebracho Herrado"},
    {"afip":"18438","location":"Quebrachos"},
    {"afip":"18441","location":"Quenti Taco"},
    {"afip":"18442","location":"Quera"},
    {"afip":"18449","location":"Ramadita"},
    {"afip":"18458","location":"Ranchitos"},
    {"afip":"18468","location":"Remanso"},
    {"afip":"18474","location":"Retiro"},
    {"afip":"18480","location":"Rincon"},
    {"afip":"18513","location":"Rio Muerto"},
    {"afip":"18518","location":"Rio Viejo"},
    {"afip":"18524","location":"Rivadavia"},
    {"afip":"18528","location":"Rodeito"},
    {"afip":"18531","location":"Rodeo"},
    {"afip":"18537","location":"Roldan"},
    {"afip":"18542","location":"Rosario"},
    {"afip":"18549","location":"Saladillo"},
    {"afip":"18556","location":"Salinas"},
    {"afip":"18567","location":"San Agustin"},
    {"afip":"18574","location":"San Alberto"},
    {"afip":"18582","location":"San Andres"},
    {"afip":"18598","location":"San Antonio"},
    {"afip":"18604","location":"San Benito"},
    {"afip":"18626","location":"San Carlos"},
    {"afip":"18630","location":"San Cayetano"},
    {"afip":"18633","location":"San Cosme"},
    {"afip":"18636","location":"San Cristobal"},
    {"afip":"18638","location":"San Dionisio"},
    {"afip":"18645","location":"San Enrique"},
    {"afip":"18655","location":"San Felipe"},
    {"afip":"18664","location":"San Fernando"},
    {"afip":"18672","location":"San Francisco"},
    {"afip":"18678","location":"San German"},
    {"afip":"18689","location":"San Gregorio"},
    {"afip":"18692","location":"San Guillermo"},
    {"afip":"18704","location":"San Ignacio"},
    {"afip":"18715","location":"San Isidro"},
    {"afip":"18726","location":"San Javier"},
    {"afip":"18730","location":"San Jeronimo"},
    {"afip":"18741","location":"San Jorge"},
    {"afip":"18756","location":"San Jose"},
    {"afip":"18770","location":"San Juan"},
    {"afip":"18773","location":"San Juancito"},
    {"afip":"18781","location":"San Justo"},
    {"afip":"18795","location":"San Lorenzo"},
    {"afip":"18804","location":"San Luis"},
    {"afip":"18809","location":"San Manuel"},
    {"afip":"18812","location":"San Marcos"},
    {"afip":"18822","location":"San Martin"},
    {"afip":"18837","location":"San Miguel"},
    {"afip":"18843","location":"San Nicolas"},
    {"afip":"18848","location":"San Pablo"},
    {"afip":"18850","location":"San Pascual"},
    {"afip":"18852","location":"San Patricio"},
    {"afip":"18866","location":"San Pedro"},
    {"afip":"18886","location":"San Ramon"},
    {"afip":"18898","location":"San Roque"},
    {"afip":"18899","location":"San Rufino"},
    {"afip":"18904","location":"San Salvador"},
    {"afip":"18910","location":"San Sebastian"},
    {"afip":"18915","location":"San Simon"},
    {"afip":"18927","location":"San Vicente"},
    {"afip":"18940","location":"Santa Ana"},
    {"afip":"18946","location":"Santa Barbara"},
    {"afip":"18955","location":"Santa Catalina"},
    {"afip":"18970","location":"Santa Cruz"},
    {"afip":"18980","location":"Santa Elena"},
    {"afip":"18985","location":"Santa Felisa"},
    {"afip":"18991","location":"Santa Ines"},
    {"afip":"19002","location":"Santa Isabel"},
    {"afip":"19013","location":"Santa Lucia"},
    {"afip":"19030","location":"Santa Maria"},
    {"afip":"19035","location":"Santa Paula"},
    {"afip":"19043","location":"Santa Rita"},
    {"afip":"19057","location":"Santa Rosa"},
    {"afip":"19086","location":"Santo Domingo"},
    {"afip":"19098","location":"Santos Lugares"},
    {"afip":"19107","location":"Sarmiento"},
    {"afip":"19112","location":"Sauce Bajada"},
    {"afip":"19114","location":"Sauce Solo"},
    {"afip":"19117","location":"Sauzal"},
    {"afip":"19120","location":"Sepultura"},
    {"afip":"19124","location":"Siempre Verde"},
    {"afip":"19130","location":"Siete Arboles"},
    {"afip":"19134","location":"Simbolar"},
    {"afip":"19136","location":"Soconcho"},
    {"afip":"19143","location":"Sol De Mayo"},
    {"afip":"19150","location":"Soledad"},
    {"afip":"19155","location":"Sumampa"},
    {"afip":"19164","location":"Taco Pozo"},
    {"afip":"19171","location":"Tala"},
    {"afip":"19190","location":"Tigre Muerto"},
    {"afip":"19197","location":"Toro Pampa"},
    {"afip":"19200","location":"Totora"},
    {"afip":"19207","location":"Totoras"},
    {"afip":"19212","location":"Trancas"},
    {"afip":"19224","location":"Tres Cerros"},
    {"afip":"19225","location":"Tres Chañares"}
    ,
    {"afip":"19229","location":"Tres Cruces"},
    {"afip":"19235","location":"Tres Hermanas"},
    {"afip":"19238","location":"Tres Lagunas"},
    {"afip":"19243","location":"Tres Mojones"},
    {"afip":"19252","location":"Tres Pozos"},
    {"afip":"19256","location":"Trinidad"},
    {"afip":"19269","location":"Urundel"},
    {"afip":"19283","location":"Villa Adela"},
    {"afip":"19298","location":"Villa Elena"},
    {"afip":"19302","location":"Villa Fanny"},
    {"afip":"19314","location":"Villa Mercedes"},
    {"afip":"19318","location":"Villa Nueva"},
    {"afip":"19321","location":"Villa Ojo De Agua"},
    {"afip":"19338","location":"Villa Union"},
    {"afip":"19343","location":"Vinal Pozo"},
    {"afip":"19345","location":"Vinalito"},
    {"afip":"19352","location":"Vizcacheral"},
    {"afip":"19362","location":"Yuchan"},
    {"afip":"19364","location":"Zanja"},
    {"afip":"19365","location":"Zanjon"},
    {"afip":"19642","location":"Yacano"},
    {"afip":"19643","location":"Yalan"},
    {"afip":"19644","location":"Campo Alegre"},
    {"afip":"19645","location":"Campo Grande"},
    {"afip":"19646","location":"Chañar Pujio"}
    ,
    {"afip":"19647","location":"Cruz Pozo"},
    {"afip":"19648","location":"El Mojon"},
    {"afip":"19649","location":"La Esperanza"},
    {"afip":"19650","location":"La Higuera"},
    {"afip":"19651","location":"La Porteña"}
    ,
    {"afip":"19652","location":"Las Flores"},
    {"afip":"19653","location":"Maco"},
    {"afip":"19654","location":"Naranjito"},
    {"afip":"19655","location":"Pozo Cercado"},
    {"afip":"19656","location":"Pozo Grande"},
    {"afip":"19657","location":"Puente Del Salado"},
    {"afip":"19658","location":"Puesto De Diaz"},
    {"afip":"19659","location":"Puesto Del Medio"},
    {"afip":"19660","location":"Ramaditas"},
    {"afip":"19661","location":"Rodeo De Soria"},
    {"afip":"19662","location":"Rodeo De Valdez"},
    {"afip":"19663","location":"San Agustin"},
    {"afip":"19664","location":"San Andres"},
    {"afip":"19665","location":"San Antonio"},
    {"afip":"19666","location":"San Antonio De Los Caceres"},
    {"afip":"19667","location":"San Benito"},
    {"afip":"19668","location":"San Carlos"},
    {"afip":"19669","location":"San Dionisio"},
    {"afip":"19670","location":"San Ignacio"},
    {"afip":"19671","location":"San Isidro"},
    {"afip":"19672","location":"San Martin"},
    {"afip":"19673","location":"San Pedro"},
    {"afip":"19674","location":"San Sebastian"},
    {"afip":"19675","location":"San Vicente"},
    {"afip":"19676","location":"Santa Maria"},
    {"afip":"19677","location":"Santa Rosa"},
    {"afip":"19678","location":"Santo Domingo"},
    {"afip":"19679","location":"Sauce Solo"},
    {"afip":"19680","location":"Sauzal"},
    {"afip":"19681","location":"Simbol Pozo"},
    {"afip":"19682","location":"Sol De Mayo"},
    {"afip":"19683","location":"Tipiro"},
    {"afip":"19684","location":"Upianita"},
    {"afip":"19685","location":"Yanda"},
    {"afip":"19689","location":"El Palomar"},
    {"afip":"19690","location":"El Paraiso"},
    {"afip":"19691","location":"El Pilar"},
    {"afip":"19692","location":"El Porvenir"},
    {"afip":"19693","location":"El Unco"},
    {"afip":"19694","location":"Horcos Tucucuna"},
    {"afip":"19695","location":"Jacimampa"},
    {"afip":"19696","location":"Jume"},
    {"afip":"19697","location":"La Clemira"},
    {"afip":"19698","location":"La Pampa"},
    {"afip":"19699","location":"La Soledad"},
    {"afip":"19700","location":"La Trampa"},
    {"afip":"19701","location":"La Tusca"},
    {"afip":"19702","location":"Laguna Del Suncho"},
    {"afip":"19703","location":"Las Cañas"}
    ,
    {"afip":"19704","location":"Las Colonias"},
    {"afip":"19705","location":"Las Flores"},
    {"afip":"19706","location":"Las Talas"},
    {"afip":"19707","location":"Los Arbolitos"},
    {"afip":"19708","location":"Los Molles"},
    {"afip":"19709","location":"Los Paredones"},
    {"afip":"19710","location":"Los Quebrachos"},
    {"afip":"19711","location":"Los Remansos"},
    {"afip":"19712","location":"Manchin"},
    {"afip":"19713","location":"Miramonte"},
    {"afip":"19714","location":"Mistol Loma"},
    {"afip":"19715","location":"Palomar"},
    {"afip":"19716","location":"Pozo Del Chañar"}
    ,
    {"afip":"19717","location":"Primavera"},
    {"afip":"19718","location":"Progreso De Jume"},
    {"afip":"19719","location":"Puesto Del Medio"},
    {"afip":"19720","location":"Ramadita"},
    {"afip":"19721","location":"Retiro"},
    {"afip":"19722","location":"Rey Viejo"},
    {"afip":"19723","location":"San Andres"},
    {"afip":"19724","location":"San Carlos"},
    {"afip":"19725","location":"San Francisco"},
    {"afip":"19726","location":"San Francisco"},
    {"afip":"19727","location":"San Javier"},
    {"afip":"19728","location":"San Jorge"},
    {"afip":"19729","location":"San Lorenzo"},
    {"afip":"19730","location":"San Martin"},
    {"afip":"19731","location":"San Nicolas"},
    {"afip":"19732","location":"Santa Ana"},
    {"afip":"19733","location":"Santa Elena"},
    {"afip":"19734","location":"Santa Maria"},
    {"afip":"19735","location":"Santa Rosa"},
    {"afip":"19736","location":"Taco Pozo"},
    {"afip":"19737","location":"Wiñano"}
    ,
    {"afip":"19738","location":"Ylumampa"},
    {"afip":"19739","location":"Alta Gracia"},
    {"afip":"19740","location":"Barrialito"},
    {"afip":"19741","location":"Boca Del Tigre"},
    {"afip":"19742","location":"Buena Vista"},
    {"afip":"19743","location":"Chañar Pozo"}
    ,
    {"afip":"19744","location":"Charco Viejo"},
    {"afip":"19745","location":"El Alambrado"},
    {"afip":"19746","location":"El Arbolito"},
    {"afip":"19747","location":"El Barrial"},
    {"afip":"19748","location":"El Puesto"},
    {"afip":"19749","location":"El Retiro"},
    {"afip":"19750","location":"El Rincon"},
    {"afip":"19751","location":"Hoyon"},
    {"afip":"19752","location":"Huachana"},
    {"afip":"19753","location":"Isla De Aragones"},
    {"afip":"19754","location":"Kenti Taco"},
    {"afip":"19755","location":"La Aguada"},
    {"afip":"19756","location":"La Darsena"},
    {"afip":"19757","location":"La Esquina"},
    {"afip":"19758","location":"La Florida"},
    {"afip":"19759","location":"La Fortuna"},
    {"afip":"19760","location":"La Perlita"},
    {"afip":"19761","location":"La Reconquista"},
    {"afip":"19762","location":"La Soledad"},
    {"afip":"19763","location":"Las Orellanas"},
    {"afip":"19764","location":"Las Tigreras"},
    {"afip":"19765","location":"Leiva"},
    {"afip":"19766","location":"Lezcanos"},
    {"afip":"19767","location":"Lomitas"},
    {"afip":"19768","location":"Los Quebrachos"},
    {"afip":"19769","location":"Los Quiroga"},
    {"afip":"19770","location":"Los Robles"},
    {"afip":"19771","location":"Manogasta"},
    {"afip":"19772","location":"Mirandas"},
    {"afip":"19773","location":"Poleo Pozo"},
    {"afip":"19774","location":"Punta Pozo"},
    {"afip":"19775","location":"Quebrachos"},
    {"afip":"19776","location":"Saladillo"},
    {"afip":"19777","location":"San Carlos"},
    {"afip":"19778","location":"San Pablo"},
    {"afip":"19779","location":"Tala"},
    {"afip":"19780","location":"Tala Pozo"},
    {"afip":"19781","location":"Tunales"},
    {"afip":"19782","location":"Chañar Sunichaj"}
    ,
    {"afip":"19783","location":"El Aspirante"},
    {"afip":"19784","location":"El Oso"},
    {"afip":"19785","location":"El Ucle"},
    {"afip":"19786","location":"Fortin La Viuda"},
    {"afip":"19787","location":"La Blanca"},
    {"afip":"19788","location":"La Carolina"},
    {"afip":"19789","location":"La Centella"},
    {"afip":"19790","location":"La Esmeralda"},
    {"afip":"19791","location":"La Recompensa"},
    {"afip":"19792","location":"La Union"},
    {"afip":"19793","location":"Las Palmas"},
    {"afip":"19794","location":"Maravilla"},
    {"afip":"19795","location":"Nueva Trinidad"},
    {"afip":"19796","location":"Porongos"},
    {"afip":"19797","location":"San Jose"},
    {"afip":"19798","location":"San Sebastian"},
    {"afip":"19799","location":"Santa Ana"},
    {"afip":"19800","location":"Tres Pozos"},
    {"afip":"19801","location":"Alto Pozo"},
    {"afip":"19802","location":"Arbolitos"},
    {"afip":"19803","location":"Ardiles De La Costa"},
    {"afip":"19804","location":"Aspa Sinchi"},
    {"afip":"19805","location":"Bandera Bajada"},
    {"afip":"19806","location":"Buena Vista"},
    {"afip":"19807","location":"Campo Verde"},
    {"afip":"19808","location":"Candelaria"},
    {"afip":"19809","location":"Casilla Del Medio"},
    {"afip":"19810","location":"Cavadito"},
    {"afip":"19811","location":"Cavado"},
    {"afip":"19812","location":"Colonias"},
    {"afip":"19813","location":"Corvalanes"},
    {"afip":"19814","location":"El Aibal"},
    {"afip":"19815","location":"El Cebollin"},
    {"afip":"19816","location":"El Ojo De Agua"},
    {"afip":"19817","location":"El Puente"},
    {"afip":"19818","location":"El Rosario"},
    {"afip":"19819","location":"El Saladillo"},
    {"afip":"19820","location":"El Vizcacheral"},
    {"afip":"19821","location":"Jume Esquina"},
    {"afip":"19822","location":"Kiska Hurmana"},
    {"afip":"19823","location":"La Bota"},
    {"afip":"19824","location":"La Cañada"}
    ,
    {"afip":"19825","location":"La Colonia"},
    {"afip":"19826","location":"La Cruz"},
    {"afip":"19827","location":"La Cuarteada"},
    {"afip":"19828","location":"La Esperanza"},
    {"afip":"19829","location":"La Falda"},
    {"afip":"19830","location":"La Florida"},
    {"afip":"19831","location":"La Germania"},
    {"afip":"19832","location":"La Invernada"},
    {"afip":"19833","location":"La Loma"},
    {"afip":"19834","location":"La Primitiva"},
    {"afip":"19835","location":"La Ramada"},
    {"afip":"19836","location":"Loma Negra"},
    {"afip":"19837","location":"Lomitas"},
    {"afip":"19838","location":"Los Diaz"},
    {"afip":"19839","location":"Los Doce Quebrachos"},
    {"afip":"19840","location":"Los Gallardos"},
    {"afip":"19841","location":"Los Guerreros"},
    {"afip":"19842","location":"Los Herreros"},
    {"afip":"19843","location":"Los Puestos"},
    {"afip":"19844","location":"Los Puntos"},
    {"afip":"19845","location":"Maco"},
    {"afip":"19846","location":"Maderas"},
    {"afip":"19847","location":"Palermo"},
    {"afip":"19848","location":"Pampa Muyoj"},
    {"afip":"19849","location":"Pozo Verde"},
    {"afip":"19850","location":"Quishca"},
    {"afip":"19851","location":"Quita Punco"},
    {"afip":"19852","location":"Remansito"},
    {"afip":"19853","location":"Rivadavia"},
    {"afip":"19854","location":"San Andres"},
    {"afip":"19855","location":"San Ignacio"},
    {"afip":"19856","location":"San Javier"},
    {"afip":"19857","location":"San Lorenzo"},
    {"afip":"19858","location":"San Martin"},
    {"afip":"19859","location":"San Ramon"},
    {"afip":"19860","location":"San Roque"},
    {"afip":"19861","location":"San Salvador"},
    {"afip":"19862","location":"San Vicente"},
    {"afip":"19863","location":"Santa Cruz"},
    {"afip":"19864","location":"Santa Rita"},
    {"afip":"19865","location":"Santa Rosa"},
    {"afip":"19866","location":"Sepulturas"},
    {"afip":"19867","location":"Suri Pozo"},
    {"afip":"19868","location":"Taperas"},
    {"afip":"19869","location":"Trancas"},
    {"afip":"19870","location":"Tres Chañares"}
    ,
    {"afip":"19871","location":"Tusca Pozo"},
    {"afip":"19872","location":"Barrancas"},
    {"afip":"19873","location":"Cardajal"},
    {"afip":"19874","location":"Cerrillos"},
    {"afip":"19875","location":"Chañar Pozo"}
    ,
    {"afip":"19876","location":"El Pueblito"},
    {"afip":"19877","location":"La Golondrina"},
    {"afip":"19878","location":"La Gringa"},
    {"afip":"19879","location":"La Paloma"},
    {"afip":"19880","location":"La Pampa"},
    {"afip":"19881","location":"La Protegida"},
    {"afip":"19882","location":"La Puerta Del Monte"},
    {"afip":"19883","location":"Las Lomas"},
    {"afip":"19884","location":"Los Caños"}
    ,
    {"afip":"19885","location":"Los Cerrillos"},
    {"afip":"19886","location":"Mal Paso"},
    {"afip":"19887","location":"Manchin"},
    {"afip":"19888","location":"Portalis"},
    {"afip":"19889","location":"Pozo Del Monte"},
    {"afip":"19890","location":"Puesto Del Medio"},
    {"afip":"19891","location":"Ramadita"},
    {"afip":"19892","location":"Rami Yacu"},
    {"afip":"19893","location":"Remansos"},
    {"afip":"19894","location":"Rumi Jaco"},
    {"afip":"19895","location":"Saladillo"},
    {"afip":"19896","location":"San Nicolas"},
    {"afip":"19897","location":"Santa Brigida"},
    {"afip":"19898","location":"Santa Lucia"},
    {"afip":"19899","location":"Santa Maria"},
    {"afip":"19900","location":"Yacu Hurmana"},
    {"afip":"19901","location":"Blanca"},
    {"afip":"19902","location":"Cachi"},
    {"afip":"19903","location":"Candelaria"},
    {"afip":"19904","location":"Carbon Pozo"},
    {"afip":"19905","location":"Cazadores"},
    {"afip":"19906","location":"Coraspino"},
    {"afip":"19907","location":"El Bajo"},
    {"afip":"19908","location":"El Cuello"},
    {"afip":"19909","location":"Hornillos"},
    {"afip":"19910","location":"La Blanca"},
    {"afip":"19911","location":"La Cruz"},
    {"afip":"19912","location":"La Falda"},
    {"afip":"19913","location":"La Loma"},
    {"afip":"19914","location":"La Paz"},
    {"afip":"19915","location":"La Petronila"},
    {"afip":"19916","location":"Pozo Moro"},
    {"afip":"19917","location":"Pozo Mosoj"},
    {"afip":"19918","location":"Rio De Gallo"},
    {"afip":"19919","location":"San Cayetano"},
    {"afip":"19920","location":"San Jose"},
    {"afip":"19921","location":"San Pedro"},
    {"afip":"19922","location":"San Vicente"},
    {"afip":"19923","location":"Taco Huaco"},
    {"afip":"19924","location":"Toro Pozo"},
    {"afip":"19925","location":"Juncal Grande"},
    {"afip":"19926","location":"La Loma"},
    {"afip":"19927","location":"La Paloma"},
    {"afip":"19928","location":"La Pampa"},
    {"afip":"19929","location":"Libertad"},
    {"afip":"19930","location":"Los Milagros"},
    {"afip":"19931","location":"Los Puentes"},
    {"afip":"19932","location":"Lujan"},
    {"afip":"19933","location":"Maravilla"},
    {"afip":"19934","location":"Maria"},
    {"afip":"19935","location":"Mercedes"},
    {"afip":"19936","location":"Minerva"},
    {"afip":"19937","location":"Monte Redondo"},
    {"afip":"19938","location":"Nogales"},
    {"afip":"19939","location":"Palermo"},
    {"afip":"19940","location":"Pampa Pozo"},
    {"afip":"19941","location":"Pirhuas"},
    {"afip":"19942","location":"Puesto De Mena"},
    {"afip":"19943","location":"Remansito"},
    {"afip":"19944","location":"Rumi"},
    {"afip":"19945","location":"San Antonio"},
    {"afip":"19946","location":"San Carlos"},
    {"afip":"19947","location":"San Felipe"},
    {"afip":"19948","location":"San Francisco"},
    {"afip":"19949","location":"San Isidro"},
    {"afip":"19950","location":"San Jose"},
    {"afip":"19951","location":"San Luis"},
    {"afip":"19952","location":"San Martin"},
    {"afip":"19953","location":"San Miguel"},
    {"afip":"19954","location":"San Nicolas"},
    {"afip":"19955","location":"San Pablo"},
    {"afip":"19956","location":"San Pedro"},
    {"afip":"19957","location":"San Ramon"},
    {"afip":"19958","location":"San Roque"},
    {"afip":"19959","location":"Santa Lucia"},
    {"afip":"19960","location":"Santa Maria"},
    {"afip":"19961","location":"Santa Rita"},
    {"afip":"19962","location":"Santa Rosa"},
    {"afip":"19963","location":"Santo Domingo"},
    {"afip":"19964","location":"Simbolar"},
    {"afip":"19965","location":"Tres Pozos"},
    {"afip":"19966","location":"Villa Guañuna"}
    ,
    {"afip":"19967","location":"9 De Julio"},
    {"afip":"19968","location":"Agua Blanca"},
    {"afip":"19969","location":"Ahi Veremos"},
    {"afip":"19970","location":"Arbolitos"},
    {"afip":"19971","location":"Buena Vista"},
    {"afip":"19972","location":"Campo Alegre"},
    {"afip":"19973","location":"El Abra"},
    {"afip":"19974","location":"El Arbolito"},
    {"afip":"19975","location":"El Bajo"},
    {"afip":"19976","location":"El Barrial"},
    {"afip":"19977","location":"El Carmen"},
    {"afip":"19978","location":"El Diamante"},
    {"afip":"19979","location":"El Porvenir"},
    {"afip":"19980","location":"El Rodeo"},
    {"afip":"19981","location":"El Sauce"},
    {"afip":"19982","location":"Esperanza"},
    {"afip":"19983","location":"Inti Huasi"},
    {"afip":"19984","location":"La Abra"},
    {"afip":"19985","location":"La Argentina"},
    {"afip":"19986","location":"La Calera"},
    {"afip":"19987","location":"La Cañada"}
    ,
    {"afip":"19988","location":"La Capilla"},
    {"afip":"19989","location":"La Chilca"},
    {"afip":"19990","location":"La Cruz"},
    {"afip":"19991","location":"La Esperanza"},
    {"afip":"19992","location":"La Florida"},
    {"afip":"19993","location":"La Isla"},
    {"afip":"19994","location":"La Pampa"},
    {"afip":"19995","location":"La Resbalosa"},
    {"afip":"19996","location":"Las Aguilas"},
    {"afip":"19997","location":"Las Chacras"},
    {"afip":"19998","location":"Las Colonias"},
    {"afip":"19999","location":"Las Flores"},
    {"afip":"20000","location":"Lomitas"},
    {"afip":"20001","location":"Milagro"},
    {"afip":"20002","location":"Monte Verde"},
    {"afip":"20003","location":"Negra Muerta"},
    {"afip":"20004","location":"Palermo"},
    {"afip":"20005","location":"Pozo Cabado"},
    {"afip":"20006","location":"Pozo Grande"},
    {"afip":"20007","location":"Puesto"},
    {"afip":"20008","location":"Puesto Del Medio"},
    {"afip":"20009","location":"Quebrachal"},
    {"afip":"20010","location":"Retiro"},
    {"afip":"20011","location":"Rincon"},
    {"afip":"20012","location":"Salinas"},
    {"afip":"20013","location":"San Francisco"},
    {"afip":"20014","location":"San Ignacio"},
    {"afip":"20015","location":"San Isidro"},
    {"afip":"20016","location":"San Javier"},
    {"afip":"20017","location":"San Jorge"},
    {"afip":"20018","location":"San Juan"},
    {"afip":"20019","location":"San Lorenzo"},
    {"afip":"20020","location":"San Luis"},
    {"afip":"20021","location":"San Pedro"},
    {"afip":"20022","location":"San Ramon"},
    {"afip":"20023","location":"Santa Ana"},
    {"afip":"20024","location":"Santa Elena"},
    {"afip":"20025","location":"Santa Maria"},
    {"afip":"20026","location":"Santa Rosa"},
    {"afip":"20027","location":"Santo Domingo"},
    {"afip":"20028","location":"Simbolar"},
    {"afip":"20029","location":"Soledad"},
    {"afip":"20030","location":"Suri Pozo"},
    {"afip":"20031","location":"Ahi Veremos"},
    {"afip":"20032","location":"Bajo Grande"},
    {"afip":"20033","location":"Belgrano"},
    {"afip":"20034","location":"Buen Lugar"},
    {"afip":"20035","location":"Campo Grande"},
    {"afip":"20036","location":"Chañar Pozo"}
    ,
    {"afip":"20037","location":"El Cajon"},
    {"afip":"20038","location":"El Cambiado"},
    {"afip":"20039","location":"El Gramillar"},
    {"afip":"20040","location":"El Porvenir"},
    {"afip":"20041","location":"El Quemado"},
    {"afip":"20042","location":"El Rosario"},
    {"afip":"20043","location":"El Saladillo"},
    {"afip":"20044","location":"El Sauce"},
    {"afip":"20045","location":"Ensenada"},
    {"afip":"20046","location":"Isla Mota"},
    {"afip":"20047","location":"La Blanca"},
    {"afip":"20048","location":"La Florida"},
    {"afip":"20049","location":"La Maravilla"},
    {"afip":"20050","location":"La Melada"},
    {"afip":"20051","location":"Las Chacras"},
    {"afip":"20052","location":"Las Delicias"},
    {"afip":"20053","location":"Las Lomas"},
    {"afip":"20054","location":"Las Puertas"},
    {"afip":"20055","location":"Libertad"},
    {"afip":"20056","location":"Loma Negra"},
    {"afip":"20057","location":"Los Molles"},
    {"afip":"20058","location":"Maravilla"},
    {"afip":"20059","location":"Monte Potrero"},
    {"afip":"20060","location":"Monte Quemado"},
    {"afip":"20061","location":"Nueva Esperanza"},
    {"afip":"20062","location":"Pampa Pozo"},
    {"afip":"20063","location":"Piedra Buena"},
    {"afip":"20064","location":"Puesto Del Medio"},
    {"afip":"20065","location":"Puesto Nuevo"},
    {"afip":"20066","location":"Ranchitos"},
    {"afip":"20067","location":"San Gregorio"},
    {"afip":"20068","location":"San Isidro"},
    {"afip":"20069","location":"San Luis"},
    {"afip":"20070","location":"San Nicolas"},
    {"afip":"20071","location":"San Ramon"},
    {"afip":"20072","location":"Santa Cruz"},
    {"afip":"20073","location":"Santa Rosa"},
    {"afip":"20074","location":"Santo Domingo"},
    {"afip":"20075","location":"Señora Pujio"}
    ,
    {"afip":"20076","location":"Simbol Pozo"},
    {"afip":"20077","location":"Simbolar"},
    {"afip":"20078","location":"Tacanitas"},
    {"afip":"20079","location":"Villa Nueva"},
    {"afip":"20080","location":"Agua Turbia"},
    {"afip":"20081","location":"Arbol Solo"},
    {"afip":"20082","location":"Bajo Las Piedras"},
    {"afip":"20083","location":"Belgrano"},
    {"afip":"20084","location":"Buena Vista"},
    {"afip":"20085","location":"Cajon"},
    {"afip":"20086","location":"Campo Rico"},
    {"afip":"20087","location":"Chacras"},
    {"afip":"20088","location":"Chañares Altos"}
    ,
    {"afip":"20089","location":"Corral De Carcos"},
    {"afip":"20090","location":"Corral Del Rey"},
    {"afip":"20091","location":"Cuchi Corral"},
    {"afip":"20092","location":"El Abra"},
    {"afip":"20093","location":"El Aguila"},
    {"afip":"20094","location":"El Arbol De Piedra"},
    {"afip":"20095","location":"El Arbolito"},
    {"afip":"20096","location":"El Bajo"},
    {"afip":"20097","location":"El Fuerte"},
    {"afip":"20098","location":"El Mistol"},
    {"afip":"20099","location":"Bella Vista"},
    {"afip":"20100","location":"Buena Vista"},
    {"afip":"20101","location":"Campo Verde"},
    {"afip":"20102","location":"El Aibal"},
    {"afip":"20103","location":"El Chañar"}
    ,
    {"afip":"20104","location":"El Crucero"},
    {"afip":"20105","location":"Jumialito"},
    {"afip":"20106","location":"La Cañada"}
    ,
    {"afip":"20107","location":"La Esperanza"},
    {"afip":"20108","location":"La Guardia"},
    {"afip":"20109","location":"Lomitas"},
    {"afip":"20110","location":"Maravilla"},
    {"afip":"20111","location":"Puesto Del Medio"},
    {"afip":"20112","location":"Remansito"},
    {"afip":"20113","location":"Rincon"},
    {"afip":"20114","location":"Saladillo"},
    {"afip":"20115","location":"San Felipe"},
    {"afip":"20116","location":"San Jose"},
    {"afip":"20117","location":"San Nicolas"},
    {"afip":"20118","location":"San Pablo"},
    {"afip":"20119","location":"San Roque"},
    {"afip":"20120","location":"San Vicente"},
    {"afip":"20121","location":"Santa Maria"},
    {"afip":"20122","location":"Santa Rita"},
    {"afip":"20123","location":"Santa Rosa"},
    {"afip":"20124","location":"Santo Domingo"},
    {"afip":"20125","location":"Boqueron"},
    {"afip":"20126","location":"El Bajo"},
    {"afip":"20127","location":"El Dorado"},
    {"afip":"20128","location":"El Puente"},
    {"afip":"20129","location":"Isla Verde"},
    {"afip":"20130","location":"La Blanca"},
    {"afip":"20131","location":"La Cañada"}
    ,
    {"afip":"20132","location":"Puesto Del Rosario"},
    {"afip":"20133","location":"Suncho Pozo"},
    {"afip":"20134","location":"Tala Pozo"},
    {"afip":"20135","location":"La Esmeralda"},
    {"afip":"20136","location":"La Simona"},
    {"afip":"20137","location":"Las Flores"},
    {"afip":"20138","location":"Santa Ana"},
    {"afip":"20139","location":"Tres Pozos"},
    {"afip":"20140","location":"Ahi Veremos"},
    {"afip":"20141","location":"Aibalito"},
    {"afip":"20142","location":"Belgrano"},
    {"afip":"20143","location":"Campo Verde"},
    {"afip":"20144","location":"El Carmen"},
    {"afip":"20145","location":"El Porvenir"},
    {"afip":"20146","location":"El Puestito"},
    {"afip":"20147","location":"El Simbolar"},
    {"afip":"20148","location":"Las Chacras"},
    {"afip":"20149","location":"Las Flores"},
    {"afip":"20150","location":"Lavalle"},
    {"afip":"20151","location":"Lujan"},
    {"afip":"20152","location":"Palmitas"},
    {"afip":"20153","location":"Pampa Pozo"},
    {"afip":"20154","location":"Parana"},
    {"afip":"20155","location":"Pozo Cabado"},
    {"afip":"20156","location":"Puesto De Diaz"},
    {"afip":"20157","location":"Puesto Del Medio"},
    {"afip":"20158","location":"Quebrachos"},
    {"afip":"20159","location":"San Antonio"},
    {"afip":"20160","location":"San Jose"},
    {"afip":"20161","location":"San Juan"},
    {"afip":"20162","location":"San Lorenzo"},
    {"afip":"20163","location":"San Pedro"},
    {"afip":"20164","location":"San Ramon"},
    {"afip":"20165","location":"Santa Catalina"},
    {"afip":"20166","location":"Santa Rosa"},
    {"afip":"20167","location":"Santos Lugares"},
    {"afip":"20168","location":"Tala Pozo"},
    {"afip":"20169","location":"Arbolitos"},
    {"afip":"20170","location":"Bajo Hondo"},
    {"afip":"20171","location":"Bella Vista"},
    {"afip":"20172","location":"El Cambiado"},
    {"afip":"20173","location":"El Churqui"},
    {"afip":"20174","location":"El Palomar"},
    {"afip":"20175","location":"El Rincon"},
    {"afip":"20176","location":"La Esperanza"},
    {"afip":"20177","location":"La Fortuna"},
    {"afip":"20178","location":"La Melada"},
    {"afip":"20179","location":"Las Cejas"},
    {"afip":"20180","location":"Los Ralos"},
    {"afip":"20181","location":"Retiro"},
    {"afip":"20182","location":"San Felix"},
    {"afip":"20183","location":"San Gregorio"},
    {"afip":"20184","location":"San Javier"},
    {"afip":"20185","location":"San Pedro"},
    {"afip":"20186","location":"Santo Domingo"},
    {"afip":"20187","location":"Suncho Pujio"},
    {"afip":"20188","location":"Tusca Pozo"},
    {"afip":"20189","location":"Albardon"},
    {"afip":"20190","location":"Bajadita"},
    {"afip":"20191","location":"Bella Vista"},
    {"afip":"20192","location":"Burra Huañuna"}
    ,
    {"afip":"20193","location":"Campo De Amor"},
    {"afip":"20194","location":"Chuña Albardon"}
    ,
    {"afip":"20195","location":"Codo"},
    {"afip":"20196","location":"Envidia"},
    {"afip":"20197","location":"Isla Verde"},
    {"afip":"20198","location":"Jumi Pozo"},
    {"afip":"20199","location":"La Laura"},
    {"afip":"20200","location":"La Noria"},
    {"afip":"20201","location":"Laguna Blanca"},
    {"afip":"20202","location":"Las Dos Flores"},
    {"afip":"20203","location":"Linton"},
    {"afip":"20204","location":"Lomitas"},
    {"afip":"20205","location":"Los Angeles"},
    {"afip":"20206","location":"Los Ralos"},
    {"afip":"20207","location":"Monte Redondo"},
    {"afip":"20208","location":"Paaj Muyo"},
    {"afip":"20209","location":"Pampa Pozo"},
    {"afip":"20210","location":"Porongal"},
    {"afip":"20211","location":"San Gregorio"},
    {"afip":"20212","location":"San Ignacio"},
    {"afip":"20213","location":"San Isidro"},
    {"afip":"20214","location":"San Jose"},
    {"afip":"20215","location":"San Juan"},
    {"afip":"20216","location":"San Luis"},
    {"afip":"20217","location":"San Miguel"},
    {"afip":"20218","location":"San Pablo"},
    {"afip":"20219","location":"San Vicente"},
    {"afip":"20220","location":"Santa Barbara"},
    {"afip":"20221","location":"Santa Isabel"},
    {"afip":"20222","location":"Santa Maria"},
    {"afip":"20223","location":"Santa Rosa De Coronel"},
    {"afip":"20224","location":"Sol De Mayo"},
    {"afip":"20225","location":"Tacanitas"},
    {"afip":"20226","location":"Taco Pozo"},
    {"afip":"20227","location":"Tres Bajadas"},
    {"afip":"20228","location":"Tusca Pozo"},
    {"afip":"20229","location":"Yacuchiri"},
    {"afip":"20230","location":"Las Abras"},
    {"afip":"20231","location":"Retiro"},
    {"afip":"20232","location":"Villa Union"},
    {"afip":"20233","location":"25 De Mayo"},
    {"afip":"20234","location":"Aibal"},
    {"afip":"20235","location":"Bella Vista"},
    {"afip":"20236","location":"Campo Limpio"},
    {"afip":"20237","location":"Campo Verde"},
    {"afip":"20238","location":"Cañada Limpia"}
    ,
    {"afip":"20239","location":"Cartavio"},
    {"afip":"20240","location":"Colonia Media"},
    {"afip":"20241","location":"Dolores"},
    {"afip":"20242","location":"Dos Hermanas"},
    {"afip":"20243","location":"El Aibalito"},
    {"afip":"20244","location":"El Carmen"},
    {"afip":"20245","location":"El Crucero"},
    {"afip":"20246","location":"El Descanso"},
    {"afip":"20247","location":"El Fisco"},
    {"afip":"20248","location":"El Noventa"},
    {"afip":"20249","location":"El Ojo De Agua"},
    {"afip":"20250","location":"El Palomar"},
    {"afip":"20251","location":"El Rosario"},
    {"afip":"20252","location":"Negra Muerta"},
    {"afip":"20253","location":"Nueva Trinidad"},
    {"afip":"20254","location":"San Carlos"},
    {"afip":"20255","location":"San Isidro"},
    {"afip":"20256","location":"San Martin"},
    {"afip":"20257","location":"San Pedro"},
    {"afip":"20258","location":"San Ramon"},
    {"afip":"20259","location":"San Roque"},
    {"afip":"20260","location":"Santa Cruz"},
    {"afip":"20261","location":"Santa Rosa"},
    {"afip":"20262","location":"Santo Domingo"},
    {"afip":"20263","location":"Santos Lugares"},
    {"afip":"20264","location":"El Simbol"},
    {"afip":"20265","location":"Tres Lagunas"},
    {"afip":"20266","location":"Bajo Hondo"},
    {"afip":"20267","location":"Dolores"},
    {"afip":"20268","location":"El Aibal"},
    {"afip":"20269","location":"El Quemado"},
    {"afip":"20270","location":"Jumi Pozo"},
    {"afip":"20271","location":"La Balanza"},
    {"afip":"20272","location":"Nueva Granada"},
    {"afip":"20273","location":"Pampa Pozo"},
    {"afip":"20274","location":"Pozo Grande"},
    {"afip":"20275","location":"San Antonio"},
    {"afip":"20276","location":"San Carlos"},
    {"afip":"20277","location":"San Luis"},
    {"afip":"20278","location":"San Pedro"},
    {"afip":"20279","location":"San Ramon"},
    {"afip":"20280","location":"Santa Maria"},
    {"afip":"20281","location":"Campo Grande"},
    {"afip":"20282","location":"Dique Los Quiroga"},
    {"afip":"20283","location":"El Barrial"},
    {"afip":"20284","location":"El Carmen"},
    {"afip":"20285","location":"El Dean"},
    {"afip":"20286","location":"La Esperanza"},
    {"afip":"20287","location":"La Vuelta"},
    {"afip":"20288","location":"Los Quiroga"},
    {"afip":"20289","location":"San Isidro"},
    {"afip":"20290","location":"San Lorenzo"},
    {"afip":"20291","location":"San Pedro"},
    {"afip":"20292","location":"Santa Maria"},
    {"afip":"20293","location":"Santa Rosa"},
    {"afip":"20294","location":"Santo Domingo"},
    {"afip":"20295","location":"Arbol Solo"},
    {"afip":"20296","location":"Chañar Pozo"}
    ,
    {"afip":"20297","location":"El Milagro"},
    {"afip":"20298","location":"El Rosario"},
    {"afip":"20299","location":"Esperanza"},
    {"afip":"20300","location":"Florida"},
    {"afip":"20301","location":"La Blanca"},
    {"afip":"20302","location":"La Esperanza"},
    {"afip":"20303","location":"La Esquina"},
    {"afip":"20304","location":"Las Flores"},
    {"afip":"20305","location":"Lomitas"},
    {"afip":"20306","location":"Lujan"},
    {"afip":"20307","location":"Maravilla"},
    {"afip":"20308","location":"Parana"},
    {"afip":"20309","location":"Pozo Cercado"},
    {"afip":"20310","location":"Remansito"},
    {"afip":"20311","location":"San Agustin"},
    {"afip":"20312","location":"San Carlos"},
    {"afip":"20313","location":"San Jose"},
    {"afip":"20314","location":"San Juan"},
    {"afip":"20315","location":"San Luis"},
    {"afip":"20316","location":"San Pedro"},
    {"afip":"20317","location":"San Ramon"},
    {"afip":"20318","location":"San Roque"},
    {"afip":"20319","location":"Santa Ana"},
    {"afip":"20320","location":"Santa Rosa"},
    {"afip":"20321","location":"Sol De Mayo"},
    {"afip":"20322","location":"Argentina"},
    {"afip":"20323","location":"Bajo Grande"},
    {"afip":"20324","location":"Campo Alegre"},
    {"afip":"20325","location":"Chañar Pozo"}
    ,
    {"afip":"20326","location":"El Rosario"},
    {"afip":"20327","location":"La Armonia"},
    {"afip":"20328","location":"La Cañada"}
    ,
    {"afip":"20329","location":"La Esperanza"},
    {"afip":"20330","location":"La Granja"},
    {"afip":"20331","location":"Las Perforaciones"},
    {"afip":"20332","location":"Maravilla"},
    {"afip":"20333","location":"Mercedes"},
    {"afip":"20334","location":"Palermo"},
    {"afip":"20335","location":"Puesto Del Medio"},
    {"afip":"20336","location":"San Carlos"},
    {"afip":"20337","location":"San Isidro"},
    {"afip":"20338","location":"San Juan"},
    {"afip":"20339","location":"San Pedro"},
    {"afip":"20340","location":"Santa Cruz"},
    {"afip":"20341","location":"Santa Maria"},
    {"afip":"20342","location":"Toro Pampa"},
    {"afip":"20343","location":"Huachana"},
    {"afip":"20344","location":"Pozo Grande"},
    {"afip":"20345","location":"San Antonio"},
    {"afip":"20346","location":"Taco Pozo"},
    {"afip":"20347","location":"La Esmeralda"},
    {"afip":"20348","location":"Punta Pozo"},
    {"afip":"20349","location":"San Antonio"},
    {"afip":"20350","location":"San Isidro"},
    {"afip":"20351","location":"San Jose"},
    {"afip":"20352","location":"San Jose"},
    {"afip":"20353","location":"San Luis"},
    {"afip":"20354","location":"San Ramon"},
    {"afip":"20355","location":"Chuiqui"},
    {"afip":"20356","location":"Coropampa"},
    {"afip":"20357","location":"El Carmen"},
    {"afip":"21695","location":"Brea Pozo"},
    {"afip":"21698","location":"Las Arenas"},
    {"afip":"22111","location":"Balde De Astica"},
    {"afip":"22186","location":"Colonia Mackinley"},
    {"afip":"22187","location":"Pinto (Villa General Mitre)"},
    {"afip":"22188","location":"La Esperanza"},
    {"afip":"22189","location":"Coronel Manuel Leonicio Rico"},
    {"afip":"22191","location":"Tireajeraloj"},
    {"afip":"22192","location":"Taruca"},
    {"afip":"22193","location":"Barrio La Leqera"},
    {"afip":"22194","location":"El Palomar (Jimenez)"},
    {"afip":"22195","location":"Barrio Oeste"},
    {"afip":"22197","location":"Pampa Mayoj"},
    {"afip":"22198","location":"Los Cerillos"},
    {"afip":"22199","location":"Remancito"},
    {"afip":"22200","location":"Cadillo"},
    {"afip":"22201","location":"Guasayan"},
    {"afip":"22203","location":"Tala Pozo"},
    {"afip":"22204","location":"La Isla"},
    {"afip":"22205","location":"San Jose Boqueron"},
    {"afip":"22206","location":"La Cañada"}
    ,
    {"afip":"22207","location":"Codo  Viejo"},
    {"afip":"22208","location":"Remancito"},
    {"afip":"22209","location":"San Isidro"},
    {"afip":"22211","location":"Los Cerillos"},
    {"afip":"22212","location":"Sabagosta"},
    {"afip":"22213","location":"Santa Lucia"},
    {"afip":"22214","location":"Señora Pujio"}
    ,
    {"afip":"22215","location":"Pampa Mayoj"},
    {"afip":"22216","location":"Santa Rosa"},
    {"afip":"22217","location":"La Cañada"}
    ,
    {"afip":"22218","location":"La Cañada"}
    ,
    {"afip":"22219","location":"(Dpto. Ojo De Agua)"},
    {"afip":"22221","location":"Guardia De Esquina"},
    {"afip":"22222","location":"Belgrano"},
    {"afip":"22334","location":"Santa Maria"},
    {"afip":"22335","location":"Jumi Pozo"},
    {"afip":"22336","location":"San Pablo"},
    {"afip":"22337","location":"San Pablo"},
    {"afip":"22338","location":"El Porvenir"}
    ]