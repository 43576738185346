import { Component, Input } from '@angular/core';

@Component({
  selector: 'exchange-order-svg',
  templateUrl: './exchange-order.component.html',
  styleUrls: ['./../../svg.scss']
})
export class ExchangeOrderListSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
  
}
