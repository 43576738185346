import { Component, Input } from '@angular/core';

@Component({
  selector: 'delete-svg',
  templateUrl: './delete.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class DeleteSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() size:string='svg-12';
  @Input() color: string='';
  constructor() { }

}
