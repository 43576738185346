//TIPO DE MONEDA: (TypeCoin)
export const typeCoin = {
	'--': "--",
	'ARS': '$',
	'USD': 'US$',
}

export const typeCoinsStr={
	'--': "--",
	'ARS': 'Pesos',
	'USD': 'Dólares',
}

//ESTADO DEL PRECIO (NEGOCIO):
export const priceState = {
	'-1':'--',
	0: '--',
	1: 'Idea',
	2: 'Precio',
	3: 'Pausado',
	4: 'Retirado',
	5: 'Inactivo'
}

export const puertos = {
	'-1':'--',
	0: "--",
	1: {
		name: "Rosario Norte",
		dropdown: ["San Lorenzo", "San Martín", "Timbues", "Ricardone"]
	},
	2: {
		name: "Rosario Sur",
		dropdown: ["General Lagos", "Arroyo Seco", "Alvear", "Villa Constitucion"]
	},
	3: {
		name: "Rosario",
		dropdown: []
	},
	4: {
		name: "Up River",
		dropdown: []
	},
	5: {
		name: "Ros Norte y Rosario",
		dropdown: []
	},
	6: {
		name: "Ros Sur y Rosario",
		dropdown: []
	},
	7: {
		name: "Rosario Norte y Sur",
		dropdown: []
	},
	8: {
		name: "Puertos Buenos Aires",
		dropdown: ["Bahia Blanca", "Necochea", "Lima", "Terminal Las Palmas", "Ramallo", "Junin"]
	},
	9: {
		name: "Puertos Entre Rios",
		dropdown: ['Del Guazu', 'Diamante']
	},
	10: {
		name: "Plantas Grassi",
		dropdown: ['Melincue', 'Labordeboy', 'San Genaro']
	},
	11: {
		name: "Plantas Interior",
		dropdown: ['Bahia Blanca',
		'Buenos Aires',
		'Con.Del Uruguay',
		'Diamante',
		'Rosario',
		'San Lorenzo',
		'Santa Fe',
		'V.Constitucion',
		'San Nicolas',
		'San Pedro',
		'Ramallo',
		'San Martin',
		'Duggan',
		'San Ambrosio',
		'S.Antonio Areco',
		'Nelson',
		'Maria Susana',
		'St.Rosa Rio 1Ro',
		'Altos D.Chipion',
		'San Justo (Sf)',
		'Chabas',
		'Darsena',
		'Pilar (Cba)',
		'Esperanza',
		'Avellaneda(Ba)',
		'Cplla.Del Señor',
		'Quilmes',
		'Gral. Cabrera',
		'San Justo (Ba)',
		'Gigena (Cba)',
		'Rio Cuarto',
		'Las Acequias',
		'Adelia Maria',
		'Avellaneda (Sf)',
		'Marcos Juarez',
		'Pilar (Ba)',
		'V. Alsina',
		'Tilisarao',
		'Berrotaran',
		'Sta.C. Saguier',
		'Junin (Bs.As.)',
		'Villa Maria',
		'Recreo',
		'Cordoba',
		'V.Del Rosario',
		'Nogoya',
		'Sampacho',
		'Reconquista',
		'Canals',
		'V. Mackenna',
		'San Jorge',
		'Elena',
		'Gdor.Crespo',
		'Maciel',
		'Pergamino',
		'Gualeguaychu',
		'Idiazabal',
		'Maria Juana',
		'Venado Tuerto',
		'Capital Federal',
		'Las Peñas',
		'Malabrigo',
		'Alfonso, M.H.',
		'Laborde',
		'Rosario D. Tala',
		'Rio Segundo',
		'Gral. Deheza',
		'Barranqueras',
		'V. Mercedes',
		'Montes De Oca',
		'Villaguay',
		'Concordia',
		'Rufino',
		'Marull',
		'Arrecifes',
		'Las Higueras',
		'Mar Del Plata',
		'Rojas',
		'Ewald',
		'Tancacha',
		'Strobel',
		'Bovril',
		'Piamonte',
		'Acebal',
		'Los Quirquincho',
		'Chovet',
		'Cnel.Pringles',
		'Peyrano',
		'Villa San Jose',
		'La Paz',
		'Ramona',
		'Viale',
		'Cerrito',
		'Galarza',
		'Seeber',
		'Lezama',
		'Saavedra',
		'Ortiz Basualdo',
		'Lucas Gonzalez',
		'Sancti Spiritu',
		'La Laguna',
		'Diego De Alvear',
		'Santa Isabel',
		'Matilde',
		'Jacinto Arauz',
		'Villa Iris',
		'Medanos',
		'Tornquist',
		'Ranqueles',
		'Huinca Renanco',
		'Gerli',
		'San Jose',
		'Guamini',
		'Llambi Campbell',
		'Piedras Blancas',
		'M.Yr.Buratovich',
		'Charata',
		'Hasenkamp',
		'Gdor. Mansilla',
		'Escobar',
		'Cnel.Baigorria',
		'Gral. Pinedo',
		'Humberto 1Ro.',
		'Victoria',
		'Santa Rosa',
		'Fernandez',
		'Huanchilla',
		'Doll',
		'Santo Tome (Sf)',
		'Quitihual',
		'Necochea',
		'Macachin',
		'Larroque',
		'Gualeguay',
		'Campana',
		'S.A.De Giles',
		'Gral. Las Heras',
		'Pto.Seco (Cba)',
		'Munro',
		'Pedro Luro',
		'T.Emb.Vicentin',
		'Laboulaye',
		'Viamonte',
		'Tandil',
		'Jose L.Suarez',
		'Carcaraña',
		'Dalmacio Velez',
		'Faro',
		'F. Varela',
		'Alejandro Roca',
		'Baradero',
		'Embarc.Medileo',
		'Porteña',
		'Cnel.Dorrego',
		'Saladillo',
		'Galvez',
		'Castellanos',
		'Alvarez',
		'Maria Grande',
		'Jesus Maria',
		'Instalac. Jng.',
		'San Roman',
		'Chacabuco',
		'Rafaela',
		'San Vicente',
		'Tres Picos',
		'Villalonga',
		'G. Catan',
		'Consc.Bernardi',
		'Basavilbaso',
		'Villa Minetti',
		'Guardia Escolta',
		'Sunchales',
		'La Criolla',
		'Tabossi',
		'Los Molinos',
		'La Carlota',
		'Monte Grande',
		'Ricardone',
		'Morteros',
		'Sgto. Cabral',
		'Betreder',
		'Arroyito',
		'Villa Totoral',
		'Juan Jorba',
		'Oliveros (S.Fe)',
		'Cañuelas',
		'Gancedo',
		'Sarah',
		'San Francisco',
		'Vilelas',
		'Tio Pujio',
		'El Fortin',
		'Grunbein',
		'Cañada De Gomez',
		'Diaz (Sta. Fe)',
		'Winifreda',
		'Inriville',
		'Cabred',
		'Quequen-Nec-',
		'Lujan',
		'Roque Perez',
		'Los Ralos',
		'Chivilcoy',
		'Santa Teresa',
		'Tres Arroyos',
		'San Jeronimo S',
		'Ordoñez',
		'Colon (E.Rios)',
		'Casas',
		'Realico',
		'Moquehua',
		'Pto.Galvan',
		'Punta Alvear',
		'Villa Eloisa',
		'Bombal',
		'Gral.Gelly',
		'Colon (B.A.)',
		'Monte Buey',
		'Cañada Rosquin',
		'Hughes',
		'Casilda',
		'Centeno',
		'Fighiera',
		'Miguel Torres',
		'Maria Teresa',
		'Las Rosas',
		'La Cocha',
		'Cañada Del Ucle',
		'Guatimozin',
		'Leones',
		'Larrechea',
		'Pasco',
		'Villa Cañas',
		'Berraondo',
		'Rueda',
		'Vedia',
		'Calchin',
		'San Marcos Sud',
		'Perez Millan',
		'Balnearia',
		'Salto Grande',
		'Lobos',
		'Bell Ville',
		'Arminda',
		'Balnearia',
		'Segui',
		'Crespo(E.Rios)',
		'Gral.Viamonte',
		'Col.Josefina',
		'Ceres(S.Fe)',
		'Las Parejas-Sfe',
		'La Plata-',
		'Bella Vista',
		'Posadas',
		'Corrientes',
		'Goya',
		'S.Martin (Nid.)',
		'S.Martin-B.Born',
		'S.Martin(L.Pla)',
		'S.Martin-Cargil',
		'Dock Sud',
		'Rosario-Ggarcia',
		'Rosario-F.A.C.A',
		'Metan',
		'Clorinda',
		'La Banda',
		'La Quiaca',
		'J.B.Alberdi',
		'Chaco',
		'Formosa',
		'Jujuy',
		'Salta',
		'D.Gallo (Tuc)',
		'Sgo.Del Estero',
		'Ros.De Frontera',
		'Salto',
		'Berutti',
		'P.De Guanacos',
		'Guasayan-Catam.',
		'Volta',
		'Las Cejas(Tuc.)',
		'Tostado',
		'Parana',
		'Isidro-Casanova',
		'Rio Tercero-Cba',
		'Holmberg (Cba.)',
		'Las Varillas',
		'Aranguren',
		'Lag. Larga-Cba.',
		'Tosquita-Cba.',
		'Colazo-Cba',
		'Las Junturas',
		'Ballesteros',
		'Ucacha',
		'Sauce Pintos',
		'Villa Ramallo',
		'Roversi',
		'Matheu',
		'Maximo Paz',
		'Las Garzas',
		'Gral.Roca(Cba)',
		'Maguirre',
		'Villa Fontana',
		'Los Toldos',
		'V.Gdor.Galvez',
		'Lomas De Zamora',
		'Pavon Arriba',
		'San Genaro',
		'Santo Domingo',
		'Colegiales',
		'San Martin Bas',
		'Daireaux',
		'Eduardo Castex',
		'Cptan.Sarmiento',
		'Villa Diego',
		'Catrilo',
		'Choele Choel',
		'Los Surgentes',
		'G.Lagos/Timbues',
		'Timbues',
		'Aldao(Fc.Mitre)',
		'L.Gonzalez',
		'25 De Mayo',
		'Benito Juarez',
		'Otumpa',
		'Cerana',
		'Rincon Nogoya',
		'Buena Esperanza',
		'Ind. Aceitera',
		'Villa Huidobro',
		'Christophersen',
		'Irazusta (E.R)',
		'Noetinger (Cba)',
		'Sanford (Sfe)',
		'W.Escalante',
		'Ramirez',
		'Stroeder',
		'El Divisorio',
		'Villada',
		'Clason',
		'Carmen (Sf)',
		'Bigand',
		'C.De Bustos',
		'Mendoza',
		'Firmat',
		'La Vanguardia',
		'Gral. Pico',
		'Arroyo Seco',
		'Estacion Pino',
		'Justin. Posse',
		'Pearson',
		'Monje',
		'R.Saenz Peña',
		'Azul',
		'3 Sargentos',
		'S.M/Ros/S.Loren',
		'Gral.Lagos',
		'Amenabar',
		'Punilla',
		'Navarro',
		'El Trebol',
		'Lincoln',
		'Ros.De La Front',
		'Melincue',
		'Resistencia',
		'Hersilia',
		'Ameghino',
		'Cruz Alta',
		'Jovita',
		'S.Fr.De Bellock',
		'San Mayol',
		'Oncativo',
		'Manfredi',
		'Monte Maiz',
		'Arias',
		'Carlos Casares',
		'Dique Iii',
		'Hernandarias',
		'Irala',
		'Roldan',
		'Alcorta',
		'Justo Daract',
		'Perez(Sfe)',
		'Camilo Aldao',
		'Corral D.Bustos',
		'Cap.Bermudez',
		'Pje.El Crisol',
		'Ros. P/Chabas',
		'Pozo Del Molle',
		'Vagues',
		'E.Echeverria',
		'Gr.Baigorria',
		'Arequito',
		'Isla Verde',
		'Cafferata',
		'Caballito',
		'Martinez De Hoz',
		'San Isidro',
		'Lanus',
		'El Socorro',
		'Chanar Ladeado',
		'Lules',
		'Funes',
		'Albarellos',
		'Villegas',
		'Alejo Ledesma',
		'Gral Rodriguez',
		'Berazategui',
		'Glew',
		'Mercedes (Ba)',
		'Cnel.Brandsen',
		'Morrison',
		'Pigue',
		'Pehuajo',
		'Cnel.Suarez',
		'Huanguelen',
		'Arteaga',
		'Trenque Lauquen',
		'Marcos Paz(Ba)',
		'Monte (Ba)',
		'Villa Vaudagna',
		'Tres Esquinas',
		'Cura-Malal',
		'Lima(Bs.As)',
		'Alta Italia(Lp)',
		'Simson',
		'Goyena',
		'Rancul',
		'Pueblo Munoz',
		'San M.Del Monte',
		'D Orbigny',
		'Ing.Thompson',
		'La Colina(Ba)',
		'La Ramada Abajo',
		'Pto.Uruguay(Er)',
		'Los Cardales',
		'Tres Lomas',
		'San Jeronimo N.',
		'Gral.Paz(Cba)',
		'Villa Elisa',
		'Sacanta',
		'Gral O Higgins',
		'Tres Algarrobos',
		'Piquete Cabado',
		'Ros.P/Tancacha',
		'Cuenca *Ba*',
		'Las Perdices',
		'Armstrong',
		'T.Emb.Vicentin',
		'Ros.P/G.Deheza',
		'S.Fernando(Ba)',
		'Bragado',
		'Paso Libres',
		'Las Vertientes',
		'Urdinarrain',
		'Pincen (Cba)',
		'San Carlos',
		'Burzaco',
		'Bulnes',
		'Ochandio',
		'San Manuel (Ba)',
		'Sta Clara Ros.',
		'S.Martin',
		'S.Martin/S Lzo.',
		'Bonifacio (Ba)',
		'Del Campillo',
		'Casbas (Ba)',
		'Oriente',
		'Brugo',
		'Villa Amelia',
		'Puan',
		'Macia',
		'Hernando',
		'Rivera',
		'Tucuman',
		'Bosques',
		'Landeta',
		'Rafael Garcia',
		'O-Higgins',
		'America',
		'Arribeños',
		'Lunlunta',
		'San Salvador',
		'Guaymallen',
		'Zavalla',
		'S.J.De Felician',
		'Los Altos',
		'Puerto Buey',
		'Gral Villegas',
		'Godeken',
		'Humboldt',
		'Villa Dolores',
		'Uranga',
		'Sinsacate',
		'Complejo Apg',
		'Playa S.Miguel',
		'Anguil',
		'Fraga',
		'Mercedes',
		'S.C.De Saguier',
		'Las Lomitas',
		'Selva',
		'C.Pellegrini',
		'B. Irigoyen',
		'Los Charruas',
		'Bandera',
		'Theobald',
		'San Jose (E.Rio',
		'Carrilobo',
		'Gral.Cabrera',
		'Naschel',
		'Freyre',
		'Pozo Hondo',
		'Sarmiento',
		'Antillas',
		'Pto.Zarate',
		'Zarate',
		'La Palmira',
		'Cap.Del Señor',
		'Villa Lia',
		'Clarke',
		'Term.Las Palmas',
		'Eusebia',
		'V.Paranacito',
		'Nonogasta',
		'Col. Tirolesa',
		'Cañada De Luque',
		'Lozada',
		'Chajan',
		'Burruyacu',
		'Reduccion',
		'Manuel Ocampo',
		'Chilecito',
		'P.De Los Libres',
		'Arrufo',
		'S.Martin/A.Seco',
		'S.M/Ros/A.Seco',
		'Las Arrias',
		'Pilar',
		'S.Martin-Cargil',
		'Pajonal',
		'Costa Grande',
		'Espinillo',
		'El Pingo',
		'Villa Urquiza',
		'San Guillermo',
		'Ing.Balbin',
		'Las Palmas',
		'Pinedo',
		'S.J.D.L Dormida',
		'Colonia Caroya',
		'S.R.Rio Primero',
		'Rio Primero',
		'Matorrales',
		'S.Martin/S.Gena',
		'Los Pereyra',
		'Frias',
		'Las Palmitas',
		'Juarez Celman',
		'Soldini',
		'Las Albahacas',
		'Fontezuela',
		'Antilla',
		'S.Lorenzo-Curci',
		'Villa Elisa',
		'Cnel.Mollinedo',
		'Los Mistoles',
		'Hermoso Campo',
		'Villa De Maria',
		'Granville',
		'La Porteña',
		'La Palmira (Mz)',
		'Tortuguitas',
		'San Agustin',
		'Gral.Baldissera',
		'Avia Terai',
		'Palmira',
		'Federal',
		'Bustinza',
		'Aldao',
		'Soven',
		'Solis',
		'La Toma',
		'Vinal Pozo',
		'Arroyo Seco Gea',
		'Las Lajitas',
		'Correa',
		'Cnel.Arnold',
		'San Martin(Mza)',
		'S.M/Ros./Lagos',
		'Los Frentones',
		'Piedrabuena',
		'Jimenez (Stgo)',
		'Palmira (Mza)',
		'Mariano Benitez',
		'Rapelli',
		'S. Lorenzo Gear',
		'S.Lorenzo Curci',
		'S.Lorenzo Mrp',
		'S.Lorenzo Afa',
		'Bouquet',
		'Cnel.Granada',
		'Las Petacas',
		'Arroy Hsta Timb',
		'Chazon',
		'Brandsen',
		'Timbues (Cofco)',
		'V. Del Totoral',
		'S.Martin/Lagos',
		'Rawson',
		'Gowland',
		'Sarandi',
		'Balcarce',
		'San Martin*',
		'Timbues *',
		'Gral.Lagos *',
		'Labordeboy',
		'Ing.White',
		'Bahia L.P.Buena',
		'Alberti',
		'Esmeralda',
		'Fabrica Rosario',
		'Fontezuela',
		'Oliva',
		'Gral. Racedo',
		'Villa Valeria',
		'Bolivar',
		'S. Martin / Ros',
		'G.Lag/Alv/Arroy',
		'Gral.Levalle',
		'Rosar/Arroyo S.',
		'Rosario/S.Loren',
		'9 De Julio',
		'Col.El Fiscal',
		'Fca Ros Norte',
		'Fca Ros Sur',
		'Abasto',
		'Hilario Lagos',
		'Colombres',
		'Cascallares',
		'Sin Entrega',
		'S.Mart./Alv.',
		'B. De Irigoyen',
		'Col.Adela',
		'Ter.P.Del Guazu',
		'"San Martin"',
		'Olavarria',
		'Esquina Cordoba',
		'Piquillin',
		'Monte Cristo',
		'Beltran Sgo Est',
		'Tinoco Cordoba',
		'Almafuerte',
		'Sachayoj',
		'Terminal 6',
		'Aldea M Luisa',
		'G.Lagos/Alvear',
		'Los Cisnes',
		'Rio Del Valle',
		'El Colorado',
		'"Timbues"',
		'Aca S. Lorenzo',
		'Cevil Pozo',
		'Berabevu',
		'"Alvear"',
		'"Sm/R/Gl"',
		'Timbues/G.Lagos',
		'Sarmiento',
		'Sarmiento (Cba)',
		'Rosario S.Clara',
		'"Gral.Lagos"',
		'S.Martin/Timbue',
		'"Arroyo Seco"',
		'Timbues (Ldc)',
		'Renova/Timbues',
		'Eduardo Costa',
		'Ber. Larroude',
		'Neco Sitio 0',
		'Aca Necochea',
		'Cargill Quequen',
		'T6 Agd',
		'Maggiolo',
		'Irigoyen',
		'Pampa Guanacos',
		'Up River',
		'Sastre',
		'S.Martin/Labord',
		'Totoras',
		'Espartillar',
		'Timb/Ros/Villa',
		'',
		'Pampa Del Infie',
		'9 De Julio',
		'Weisburd',
		'S.M. Agd',
		'Renova (Tr/Mz)',
		'Ricard./S.Loren',
		'Arroyo/Timbues',
		'Santa Elena',]
	},
}

//QUALITY (calidad)
export const quality = {
	'-1':'--',
	0: "--",
	1: "Camara",
	2: "Fabrica",
	3: "Grado 2",
	4: "Tal cual"
}

//Broker (Corredora)
export const broker = {
	'-1':'--',
	0: '--',
	1: 'Grassi',
	2: 'GG 1888'
}

//How he sells (¿Cómo vende?)
export const howSell = {
	'-1':'--',
	0: '--',
	1: 'Acopio',
	2: 'Produccion Propia',
	3: 'Consignacion',
	4: 'Cooperativa',
}