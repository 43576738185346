import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTagsToFix } from 'src/app/shared/const/to-fix';
import { CONDITIONS_TYPE_TO_FIX } from 'src/app/shared/dict/condition-and-indicators';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-condition-type',
  templateUrl: './condition-type.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class ToFixConditionTypeComponent extends Field implements OnInit, OnDestroy {

  constructor(store: Store<appState>) {
    super(store);
    this.OBJECT=CONDITIONS_TYPE_TO_FIX;
    this.array = Object.keys(CONDITIONS_TYPE_TO_FIX);
    this.array.shift();
    this.deleteLastValueInArray();
    this.initalElementSelected = 'Ingresar una condición';
    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTagsToFix.conditionType;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTagsToFix.conditionType;
    }
    this.setIdentifyClassName();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  
  override rememberValueInForm(){
    if(this.tag==priceTagsToFix.conditionType || this.subTag==priceTagsToFix.conditionType){
      this.rememberValueWithObject();
    }
  }

  override setElement(value:any, index:number){
    this.setFirstTag(priceTagsToFix.indicators);
    this.setElementToGenerate([]);

    this.setFirstTag(priceTagsToFix.conditionType);
    this.setElementToGenerate(Number(value));
  }
}
