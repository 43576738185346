<div class="background opaque visible"></div>

<div class="background visible" (click)="closeModal()">
  <div class="dialog">
    
    <div class="width-100 row-between">
      <span></span>
      <img class="logo denied" alt="check-denied" src="../../../../../../assets/images/extra/denied.svg" />
      <cross-svg class="cross pointer" (click)="closeModal()"></cross-svg>

    </div>
  
    <div class="header">
      <span class="title">Falló el envío {{isIndicators? 'de indicadores':''}} al IBM.</span>
    </div>

    <div class="content" *ngIf="!isIndicators">

      <div class="column" *ngFor="let failed of arrayOfFailed">
        <span class="text-content title-text">ID: {{ getID(failed) }}</span>
        <p class="text-content title-text title-message" >Mensaje:</p>
        <p class="text-content message" *ngFor="let message of getArrayOfMessage(failed)">
          {{ getMessageError(failed) }}
        </p>
        <div class="line grey"></div>
      </div>
    </div>

    <div class="content" *ngIf="isIndicators">
      <div class="column" *ngFor="let failed of arrayOfFailed">
        <p class="text-content title-text title-message" >Mensaje:</p>

        <p class="text-content message">{{ failed?.status?.messageError ?? failed }}</p>

        <div class="line grey"></div>
      </div>
    </div>

    <button class="filled-button" (click)="closeModal()">
      Cerrar
    </button>
  </div>
</div>
