import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class MaestrosService {
  constructor(private http: HttpClient) { }
  
  //We get any collection from the DB
  getCollection(table:string){
    return this.http.get<any>(environment.rutas.maestros.getDB + '/' + table); 
  }
  
  getCount(table:string){
    return this.http.get<any>(environment.rutas.maestros.getCount + '/' + table); 
  }
}