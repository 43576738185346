import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import * as fromUser from 'src/app/redux/actions/session.action'
import { User, UserGoogle } from 'src/app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { TokenService } from 'src/app/shared/services/token.service';
import { getCookie } from 'src/app/shared/session.checker';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';

declare var google:any;
export declare var gapi:any;

export const CLIENT_ID:string="313888454615-f6tnmff1964pee1ch3hdqfbf4v115r5i.apps.googleusercontent.com"

@Component({
  selector: 'app-boton-google',
  templateUrl: './boton-google.component.html',
  styleUrls: ['./boton-google.component.scss']
})
export class BotonGoogleComponent implements OnInit {
  info!: string;
  showError: boolean = false;
  messageError: string = '';

  private login$!:Subscription;

  GoogleLoginProvider = GoogleLoginProvider;

  constructor(private store: Store<appState>, private tokenSvc:TokenService) {
    this.setSubscriptions();
  }

  ngOnInit(): void {
    gapi.load();

    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: (response:any) => this.handleCredentialResponse(response)
    });

    google.accounts.id.prompt(); // also display the One Tap dialog*/
  }

  ngOnDestroy(): void {
    this.login$?.unsubscribe();
  }

  setSubscriptions(){
    this.login$=this.store.select('login').subscribe(state => {
      this.showError = state.showError;
      this.messageError = state.error;
    })
  }

  login() {
    google.accounts.id.prompt();
    this.store.dispatch(fromUser.login());
  }
  
  handleCredentialResponse(response:any) {
    //We decode the jwt
    const jwt = response.credential.split('.')[1];
    const decodedJwtJsonData = window.atob(jwt);  
    const decodedJwtData = JSON.parse(decodedJwtJsonData);

    //Create an UserGoogle
    const user:UserGoogle={
      email: decodedJwtData.email,
      photo: decodedJwtData.picture,
      id: decodedJwtData.id,
    }

    //Create an User
    this.store.dispatch(fromUser.createUser({
      photo: user.photo,
      email: user.email,
      id: user.id
    }))
  }
}