<div class="paso paso1">
    <app-data class="data" title="Vendedor" value="{{getSellerName(order)}}"></app-data>
    <app-data class="data" title="Subdivisión Syngenta" value="{{getSubdivisionSyngenta(order)}}" *ngIf="isSyngenta(order)"></app-data>
    <app-data class="data" title="Numero de factura" value="{{getNroBilling(order)}}" *ngIf="isNutrien(order)"></app-data>
    <app-data class="data" title="Lugar de procedencia" value="{{getPlaceOfOrigin(order)}}"></app-data>
    <app-data class="data" title="Corredor" value="{{BROKERS[order.broke]}}"></app-data>    
    <app-data class="data" title="¿Cómo vende?" value="{{HOW_SELL[order.howSell]}}"></app-data>
    <app-data class="data" title="Particularidad de negocio" 
    [object]="getBusinessParticularities(order.price.businessParticularities)"
    [businessParticularity]="order.price.businessParticularities"></app-data>
    <app-data class="data" title="Restricción" value="{{getBuyerType(order.buyer)}}" [object]="getBuyer(order.buyer)"></app-data>
    <app-data class="data" title="Toneladas" value="{{getTons(order)}}"></app-data>
    <app-data class="data" title="Cupos" [object]="getQuotas(order)" *ngIf="!isManualPage"></app-data>
</div>
<div class="paso paso2">
    <app-data class="data" title="Tipo de negocio" value="{{BUSINESS_TYPE[order.price.typeBusiness]}}"></app-data>
    <app-data class="data" title="Cupos" [object]="getQuotas(order)" *ngIf="isManualPage"></app-data>
    <app-data class="data" title="Periodo de entrega"
    value="{{order.price.deliveryPeriod.startDate != '--'? order.price.deliveryPeriod.startDate +'/'+order.price.deliveryPeriod.endDate : '--'}}">
    </app-data>
    <app-data class="data" title="Producto" value="{{PRODUCTS[order.price.productName]}}"></app-data>
    <app-data class="data not-in-mobile" title="Precio" value="{{getPrice(order)}}" *ngIf="!isGenerateToFix">
    </app-data>
    <app-data class="data not-in-mobile" title="Moneda" value="{{order.price.typeCoin == 'ARS'? 'Pesos': order.price.typeCoin=='USD'? 'Dólares': '--'}}"  *ngIf="!isGenerateToFix">
    </app-data>
    <app-data class="data" title="Lugar de entrega" value="{{getPlaceOfDelivery(order)}}"></app-data>

</div>
<div class="paso paso3">
    <app-data class="data" title="Cosecha" value="{{order.price.harvest}}"></app-data>
    <app-data class="data" title="Calidad" value="{{getQuality(order)}}"></app-data>
    <app-data class="data" title="Particularidad de calidad" value="{{getQualityIBM(order)}}"></app-data>
    <app-data class="data" title="Forma de pago" value="{{getWayPay(order)}}"></app-data>
    <app-data class="data" title="Vencimiento" value="{{order.price.wayPay.expiration}}"></app-data>
    <app-data class="data" title="Pesificación" value="{{getPesification(order)}}"></app-data>
    <app-data class="data" title="Observaciones" value="{{getObservations(order)}}"></app-data>
    <app-data class="data" title="Código agrupador" value="{{getGrouperCode(order)}}" *ngIf="isCommodities"></app-data>
</div>