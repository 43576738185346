import { Component, Input } from '@angular/core';

@Component({
  selector: 'featured-price-svg',
  templateUrl: './featured-price.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class FeaturedPriceSvgComponent {
  @Input() public active:boolean=false;
  @Input() public tooltip:boolean=true;
  constructor() { }
}
