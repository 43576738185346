import { Action, createReducer, on } from "@ngrx/store";
import * as actions from '../actions/exchange.action';
import { initialOrderString } from "src/app/shared/const/orders";
import { ExchangeReducer } from "src/app/shared/interfaces/order-exchange";
import { orderExchangeInitialState } from "src/app/shared/const/exchange-orders";
import { VIEW_ORDER_EXCHANGE_ALL, VIEW_ORDER_EXCHANGE_VOID } from "src/app/shared/const/views.const";

export const initialState: ExchangeReducer = {
    exchangeBoard: {
        all: [],
        //buyerType: buyerType.COMMODITIES,
        filter: initialOrderString,
        indexFilter:-1,
        ordersSelected:[],
        isOrderReturned:false,
        orderDetails: orderExchangeInitialState,
        showOrderDetails: false,
        showAddExchange: false,
        viewsOfExchangeBoard: {
            views: [],
            newView: VIEW_ORDER_EXCHANGE_VOID,
            indexViewSelected: -2,
            viewSelected: {
                ...VIEW_ORDER_EXCHANGE_ALL,
                viewName: 'default'
            }
        },
    },
    generateOrder: orderExchangeInitialState,
    generateOrders: [orderExchangeInitialState],
    indexToEditOrder: -1,
    isEditGenerate: false,
    isGenerateOrderExchange: false,
    exchangeConfirmed:{
        confirmed: false,
        message: '',
        showConfirmed: false
    }
};

const _exchangeReducer = createReducer(
    initialState,

    //Order to generate  
    on(actions.setOrderToGenerateInExchange, (state, action) => {
        return {
            ...state,
            generateOrder: {
                ...state.generateOrder,
                ...action.order
            }
        }
    }),

    on(actions.setExchangeInOrderToGenerate, (state, action) => {
        return {
            ...state,
            generateOrder: {
                ...state.generateOrder,
                exchange: action.exchange
            }
        }
    }),

    on(actions.setEditGenerateOrderExchange, (state, action) => {
        return {
            ...state,
            isEditGenerate: action.isEditGenerate
        }
    }),

    on(actions.setIsGenerateOrderExchange, (state, action) => {
        return {
            ...state,
            isGenerateOrderExchange: action.isGenerateOrderExchange
        }
    }),


    on(actions.resetOrderExchangeToGenerate, (state, action) => {
        return {
            ...state,
            generateOrder: initialState.generateOrder
        }
    }),

    on(actions.setGenerateOrdersExchange, (state, action) => {
        return {
            ...state,
            generateOrders: action.orders
        }
    }),

    on(actions.setIndexToEditOrderExchange, (state, action) => {
        return {
            ...state,
            indexToEditOrder: action.index
        }
    }),

    on(actions.resetOrdersToGenerateExchange, (state, action) => {
        return {
            ...state,
            generateOrders: initialState.generateOrders
        }
    }),



    on(actions.loadExchangesAllSuccess, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                all: action.orders
            }

        };
    }),

    ////----------------------FILTERS------------------------\\\\
    
    on(actions.setFilterOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                filter: action.orderString
            }

        };
    }),

    on(actions.setFilterIndexExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                indexFilter: action.index
            }
        };
    }),
    ////----------------------VIEWS------------------------\\\\

    on(actions.setViewToGenerateOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                viewsOfExchangeBoard:{
                    ...state.exchangeBoard.viewsOfExchangeBoard,
                    newView: action.view
                }
            }
        }
    }),
    on(actions.resetNewViewOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                viewsOfExchangeBoard:{
                    ...state.exchangeBoard.viewsOfExchangeBoard,
                    newView: initialState.exchangeBoard.viewsOfExchangeBoard.newView
                }
            }
        }
    }),
    on(actions.setViewSelectedOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                viewsOfExchangeBoard:{
                    ...state.exchangeBoard.viewsOfExchangeBoard,
                    viewSelected: action.view
                }
            }
        }
    }),
    on(actions.resetViewSelectedOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                viewsOfExchangeBoard:{
                    ...state.exchangeBoard.viewsOfExchangeBoard,
                    viewSelected: initialState.exchangeBoard.viewsOfExchangeBoard.viewSelected
                }
            }
        }
    }),
    on(actions.setAllViewsOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                viewsOfExchangeBoard:{
                    ...state.exchangeBoard.viewsOfExchangeBoard,
                    views: action.views
                }
            }
        }
    }),
    on(actions.setIndexViewSelectedOrderExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                viewsOfExchangeBoard:{
                    ...state.exchangeBoard.viewsOfExchangeBoard,
                    indexViewSelected: action.index
                }
            }
        }
    }),

    //----------------------ORDERS SELECTED----------------------\\
    //Select prices
    /*on(actions.setOrdersExchangeSelected, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                ordersSelected:action.orders
            }
        }
    }),*/

    //Price details
    on(actions.setOrderExchangeDetails, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                orderDetails: action.order
            }
        }
    }),
    on(actions.showOrderExchangeDetails, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                showOrderDetails: action.show
            }
        }
    }),

    //Add exchange
    on(actions.setAddExchange, (state, action) => {
        return {
            ...state,
            exchangeBoard: {
                ...state.exchangeBoard,
                showAddExchange: action.addExchange
            }
        }
    }),



    /* ----------------- EXCHANGE CONFIRMED ----------------- */
    on(actions.setExchangeConfirmed, (state, action) => {
        return {
            ...state,
            exchangeConfirmed: action.exchangedConfirmed
        }
    }),
);


export function exchangeReducer(state: any, action: Action) {
    return _exchangeReducer(state, action);
}

