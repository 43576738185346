export const tucumanLocalities=[
    {"afip":"42","location":"Abra Baya"},
    {"afip":"45","location":"Abra De La Picaza"},
    {"afip":"49","location":"Abra De Yareta"},
    {"afip":"51","location":"Abra Del Infiernillo"},
    {"afip":"52","location":"Abra Del Tafi"},
    {"afip":"54","location":"Abra El Candado"},
    {"afip":"59","location":"Abra Rica"},
    {"afip":"75","location":"Acequiones"},
    {"afip":"151","location":"Agua El Simbo"},
    {"afip":"163","location":"Agua Rosada"},
    {"afip":"177","location":"Aguada De Jorge"},
    {"afip":"218","location":"Aguilares"},
    {"afip":"233","location":"Alabama"},
    {"afip":"234","location":"Alabama Nueva"},
    {"afip":"297","location":"Alderetes"},
    {"afip":"346","location":"Alisos"},
    {"afip":"349","location":"Alizal"},
    {"afip":"367","location":"Alongo"},
    {"afip":"376","location":"Alta Gracia De Villa Burruyacu"},
    {"afip":"398","location":"Alto Cazadera"},
    {"afip":"401","location":"Alto De Anfama"},
    {"afip":"406","location":"Alto De La Angostura"},
    {"afip":"413","location":"Alto De Leiva"},
    {"afip":"414","location":"Alto De Los Gimenez"},
    {"afip":"418","location":"Alto De Los Reales"},
    {"afip":"421","location":"Alto De Medina"},
    {"afip":"427","location":"Alto Del Huascho"},
    {"afip":"428","location":"Alto Del Lampazo"},
    {"afip":"434","location":"Alto Del Pusto"},
    {"afip":"441","location":"Alto El Puesto"},
    {"afip":"446","location":"Alto La Totora"},
    {"afip":"447","location":"Alto Las Flores"},
    {"afip":"448","location":"Alto Las Lechuzas"},
    {"afip":"450","location":"Alto Los Cardones"},
    {"afip":"453","location":"Alto Nuestra Señora Del Valle"}
    ,
    {"afip":"471","location":"Alurralde"},
    {"afip":"482","location":"Amaicha Del Llano"},
    {"afip":"483","location":"Amaicha Del Valle"},
    {"afip":"510","location":"Amimpa"},
    {"afip":"518","location":"Ampatilla"},
    {"afip":"519","location":"Ampimpa"},
    {"afip":"522","location":"Amunpa"},
    {"afip":"532","location":"Ancajulli"},
    {"afip":"538","location":"Anchillos"},
    {"afip":"558","location":"Andres Ferreyra"},
    {"afip":"564","location":"Anegados"},
    {"afip":"566","location":"Anfana"},
    {"afip":"589","location":"Anjuana"},
    {"afip":"594","location":"Anta"},
    {"afip":"595","location":"Anta Chica"},
    {"afip":"597","location":"Antama"},
    {"afip":"601","location":"Antiguo Quilmes"},
    {"afip":"605","location":"Antillas"},
    {"afip":"618","location":"Antu Mapu"},
    {"afip":"622","location":"Aparadero Militar Gral Muñoz"}
    ,
    {"afip":"637","location":"Aragon"},
    {"afip":"639","location":"Aran"},
    {"afip":"643","location":"Aranilla"},
    {"afip":"646","location":"Araoz"},
    {"afip":"659","location":"Arboles Grandes"},
    {"afip":"660","location":"Arboles Verdes"},
    {"afip":"664","location":"Arcadia"},
    {"afip":"694","location":"Arocas"},
    {"afip":"717","location":"Arroyo Atahona"},
    {"afip":"721","location":"Arroyo Barriento"},
    {"afip":"810","location":"Arroyo Mal Paso"},
    {"afip":"898","location":"Aserradero"},
    {"afip":"903","location":"Asna Yaco"},
    {"afip":"941","location":"Avestilla"},
    {"afip":"972","location":"B Zorrilla"},
    {"afip":"1016","location":"Bajastine"},
    {"afip":"1029","location":"Bajo De Los Sueldos"},
    {"afip":"1031","location":"Bajo De Raco"},
    {"afip":"1112","location":"Balderrama Sur"},
    {"afip":"1158","location":"Banda Del Rio Sali"},
    {"afip":"1172","location":"Barborin"},
    {"afip":"1235","location":"Barrio Casino"},
    {"afip":"1243","location":"Barrio Diagonal"},
    {"afip":"1321","location":"Barrio Textil"},
    {"afip":"1338","location":"Barrosa"},
    {"afip":"1371","location":"Batiruana"},
    {"afip":"1394","location":"Bañado Del Valle"}
    ,
    {"afip":"1430","location":"Belicha Huaico"},
    {"afip":"1437","location":"Bello Horizonte"},
    {"afip":"1447","location":"Benjamin Araoz"},
    {"afip":"1449","location":"Benjamin Paz"},
    {"afip":"1481","location":"Bilca Pozo"},
    {"afip":"1502","location":"Boba Yacu"},
    {"afip":"1562","location":"Bracho Viejo"},
    {"afip":"1596","location":"Buena Vista Oeste"},
    {"afip":"1598","location":"Buena Yerba"},
    {"afip":"1609","location":"Burruyacu"},
    {"afip":"1611","location":"Bustamante"},
    {"afip":"1675","location":"Cachi Huasi"},
    {"afip":"1678","location":"Cachi Yaco Apeadero Fcgb"},
    {"afip":"1683","location":"Cachiyaco"},
    {"afip":"1711","location":"Cajas Viejas"},
    {"afip":"1741","location":"Calera Aconquija"},
    {"afip":"1745","location":"Calera De Chirimayo"},
    {"afip":"1760","location":"Calimayo"},
    {"afip":"1761","location":"Calimonte"},
    {"afip":"1782","location":"Camas Amontonadas"},
    {"afip":"1805","location":"Camino Del Peru"},
    {"afip":"1881","location":"Campo De Las Gallinas"},
    {"afip":"1884","location":"Campo De Los Cardones"},
    {"afip":"1885","location":"Campo De Los Chañares"}
    ,
    {"afip":"1890","location":"Campo De Talamayo"},
    {"afip":"1907","location":"Campo El Luisito"},
    {"afip":"1909","location":"Campo El Mollar"},
    {"afip":"1935","location":"Campo Herrera"},
    {"afip":"1941","location":"Campo La Cruz"},
    {"afip":"1943","location":"Campo La Flor"},
    {"afip":"1944","location":"Campo La Flor Los Ralos"},
    {"afip":"2015","location":"Campo Solco Los Cochamolles"},
    {"afip":"2024","location":"Campo Volante"},
    {"afip":"2028","location":"Campo Zauzal"},
    {"afip":"2059","location":"Candelillal"},
    {"afip":"2133","location":"Capitan Caceres"},
    {"afip":"2161","location":"Carancho Pozo"},
    {"afip":"2208","location":"Carolinas Bajas"},
    {"afip":"2218","location":"Carreta Quemada"},
    {"afip":"2223","location":"Carrichango"},
    {"afip":"2251","location":"Casa De Campo"},
    {"afip":"2258","location":"Casa De Piedras"},
    {"afip":"2259","location":"Casa De Zinc"},
    {"afip":"2260","location":"Casa Del Alto"},
    {"afip":"2266","location":"Casa Rosada"},
    {"afip":"2267","location":"Casa Santa"},
    {"afip":"2276","location":"Casales"},
    {"afip":"2301","location":"Caspichango Viejo"},
    {"afip":"2317","location":"Castillas"},
    {"afip":"2361","location":"Cañada Alegre"}
    ,
    {"afip":"2371","location":"Cañada De Alzogaray"}
    ,
    {"afip":"2389","location":"Cañada De Los Negros"}
    ,
    {"afip":"2400","location":"Cañada De Viclos"}
    ,
    {"afip":"2402","location":"Cañada Del Arenal"}
    ,
    {"afip":"2414","location":"Cañada El Arenal"}
    ,
    {"afip":"2437","location":"Cañada Yerba Buena"}
    ,
    {"afip":"2438","location":"Cañadas"}
    ,
    {"afip":"2475","location":"Cañete"}
    ,
    {"afip":"2488","location":"Cejas De Aroca"},
    {"afip":"2489","location":"Cejas De Beñachillos"}
    ,
    {"afip":"2510","location":"Cerco Cuatro"},
    {"afip":"2755","location":"Cervalito"},
    {"afip":"2759","location":"Cevil Grande"},
    {"afip":"2760","location":"Cevil Pozo"},
    {"afip":"2761","location":"Cevil Redondo"},
    {"afip":"2762","location":"Cevil Solo"},
    {"afip":"2764","location":"Cevilarcito"},
    {"afip":"2766","location":"Chabela"},
    {"afip":"2822","location":"Chalchacito"},
    {"afip":"2866","location":"Chasquivil"},
    {"afip":"2887","location":"Chañar Muya"}
    ,
    {"afip":"2889","location":"Chañar Pago"}
    ,
    {"afip":"2899","location":"Chañar Taqueño"}
    ,
    {"afip":"2900","location":"Chañar Via"}
    ,
    {"afip":"2908","location":"Chañarito"}
    ,
    {"afip":"2930","location":"Chicligasta"},
    {"afip":"2938","location":"Chilcal"},
    {"afip":"2993","location":"Choromoro"},
    {"afip":"2997","location":"Chorrilos"},
    {"afip":"3015","location":"Chulca"},
    {"afip":"3028","location":"Chusca"},
    {"afip":"3057","location":"Ciudacita"},
    {"afip":"3098","location":"Cochamolle"},
    {"afip":"3104","location":"Cochuna"},
    {"afip":"3105","location":"Coco"},
    {"afip":"3113","location":"Cohigac"},
    {"afip":"3118","location":"Colalao Del Valle"},
    {"afip":"3145","location":"Colmena Lolita"},
    {"afip":"3149","location":"Colombres"},
    {"afip":"3166","location":"Colonia 6"},
    {"afip":"3173","location":"Colonia Acevedo"},
    {"afip":"3178","location":"Colonia Agricola"},
    {"afip":"3237","location":"Colonia Bascary"},
    {"afip":"3398","location":"Colonia El Puesto"},
    {"afip":"3403","location":"Colonia El Sunchal"},
    {"afip":"3404","location":"Colonia El Tarco"},
    {"afip":"3431","location":"Colonia Fara"},
    {"afip":"3434","location":"Colonia Felipe"},
    {"afip":"3496","location":"Colonia Humaita Primera"},
    {"afip":"3519","location":"Colonia Juan Jose Iramain"},
    {"afip":"3539","location":"Colonia La Bonaria"},
    {"afip":"3589","location":"Colonia La Ortiz"},
    {"afip":"3602","location":"Colonia La Roca"},
    {"afip":"3641","location":"Colonia Lolita Norte"},
    {"afip":"3647","location":"Colonia Los Chasales"},
    {"afip":"3649","location":"Colonia Los Hills"},
    {"afip":"3682","location":"Colonia Maria Elena"},
    {"afip":"3689","location":"Colonia Marull"},
    {"afip":"3709","location":"Colonia Mistol"},
    {"afip":"3716","location":"Colonia Monteros"},
    {"afip":"3728","location":"Colonia Naschi"},
    {"afip":"3734","location":"Colonia Nro 2"},
    {"afip":"3742","location":"Colonia Nueva Trinidad"},
    {"afip":"3761","location":"Colonia Pacara"},
    {"afip":"3778","location":"Colonia Pedro Leon Cornet"},
    {"afip":"3852","location":"Colonia San Luis"},
    {"afip":"3879","location":"Colonia Santa Marina"},
    {"afip":"3890","location":"Colonia Sarmiento"},
    {"afip":"3903","location":"Colonia Sobrecasa"},
    {"afip":"3910","location":"Colonia Tacapunco"},
    {"afip":"4002","location":"Comuna La Esperanza"},
    {"afip":"4027","location":"Consimo"},
    {"afip":"4041","location":"Cooperativa Agronomica"},
    {"afip":"4066","location":"Coromama"},
    {"afip":"4159","location":"Cortaderal"},
    {"afip":"4169","location":"Cossio"},
    {"afip":"4172","location":"Costa Arroyo Esquina"},
    {"afip":"4194","location":"Costa Del Rio Seco"},
    {"afip":"4219","location":"Costilla"},
    {"afip":"4237","location":"Criollas"},
    {"afip":"4261","location":"Cruz De Abajo"},
    {"afip":"4268","location":"Cruz Del Norte Estacion Fcgm"},
    {"afip":"4299","location":"Cuatro Gatos"},
    {"afip":"4301","location":"Cuatro Sauces"},
    {"afip":"4310","location":"Cuchillas"},
    {"afip":"4322","location":"Cuesta De Chilca"},
    {"afip":"4323","location":"Cuesta De La Chilca"},
    {"afip":"4364","location":"Curva De Los Vegas"},
    {"afip":"4397","location":"Delfin Gallo"},
    {"afip":"4405","location":"Descanso"},
    {"afip":"4409","location":"Desmonte"},
    {"afip":"4453","location":"Dique Escaba"},
    {"afip":"4512","location":"Domingo Millan"},
    {"afip":"4533","location":"Donato Alvarez"},
    {"afip":"4586","location":"Duraznos Blancos"},
    {"afip":"4641","location":"El Alpizar"},
    {"afip":"4650","location":"El Antigal"},
    {"afip":"4657","location":"El Arbolar"},
    {"afip":"4662","location":"El Arqueal"},
    {"afip":"4664","location":"El Aserradero"},
    {"afip":"4667","location":"El Atacal"},
    {"afip":"4668","location":"El Atacat"},
    {"afip":"4675","location":"El Azul"},
    {"afip":"4677","location":"El Bachi"},
    {"afip":"4687","location":"El Barco"},
    {"afip":"4689","location":"El Barranquero"},
    {"afip":"4694","location":"El Batiruano"},
    {"afip":"4717","location":"El Boyero"},
    {"afip":"4718","location":"El Bracho"},
    {"afip":"4748","location":"El Campo"},
    {"afip":"4764","location":"El Cardenal"},
    {"afip":"4771","location":"El Carmen Puente Alto"},
    {"afip":"4779","location":"El Casial"},
    {"afip":"4780","location":"El Casialito"},
    {"afip":"4781","location":"El Castoral"},
    {"afip":"4790","location":"El Cebil"},
    {"afip":"4793","location":"El Cedro"},
    {"afip":"4808","location":"El Cevilar"},
    {"afip":"4809","location":"El Cevilar Colombres"},
    {"afip":"4835","location":"El Chilcar"},
    {"afip":"4853","location":"El Churquis"},
    {"afip":"4864","location":"El Cochuchal"},
    {"afip":"4867","location":"El Colcolar"},
    {"afip":"4886","location":"El Cortaderal"},
    {"afip":"4896","location":"El Cruce"},
    {"afip":"4899","location":"El Cuadro"},
    {"afip":"4902","location":"El Cuarteadero"},
    {"afip":"4952","location":"El Establo"},
    {"afip":"4970","location":"El Frasquillo"},
    {"afip":"5001","location":"El Guardamonte"},
    {"afip":"5021","location":"El Interes"},
    {"afip":"5037","location":"El Junquillar"},
    {"afip":"5040","location":"El Lamedero"},
    {"afip":"5041","location":"El Lamparazo"},
    {"afip":"5077","location":"El Matadero"},
    {"afip":"5078","location":"El Matal"},
    {"afip":"5084","location":"El Melon"},
    {"afip":"5102","location":"El Molle Viejo"},
    {"afip":"5104","location":"El Morado"},
    {"afip":"5112","location":"El Mutul"},
    {"afip":"5114","location":"El Naranjal"},
    {"afip":"5115","location":"El Naranjito"},
    {"afip":"5122","location":"El Nial"},
    {"afip":"5128","location":"El Nogalito"},
    {"afip":"5153","location":"El Pabellon"},
    {"afip":"5157","location":"El Pajal"},
    {"afip":"5162","location":"El Palancho"},
    {"afip":"5164","location":"El Palcara"},
    {"afip":"5193","location":"El Pavon"},
    {"afip":"5195","location":"El Payanal"},
    {"afip":"5203","location":"El Pelado De Paranillo"},
    {"afip":"5225","location":"El Pila"},
    {"afip":"5286","location":"El Puesto Del Medio"},
    {"afip":"5299","location":"El Quimil"},
    {"afip":"5366","location":"El Sesteadero"},
    {"afip":"5370","location":"El Siambon"},
    {"afip":"5376","location":"El Sinquial"},
    {"afip":"5388","location":"El Suncal"},
    {"afip":"5423","location":"El Tiro Argentino"},
    {"afip":"5425","location":"El Tobar"},
    {"afip":"5517","location":"El Zauzal"},
    {"afip":"5537","location":"Embalse El Cadillal"},
    {"afip":"5539","location":"Embalse Rio Hondo"},
    {"afip":"5557","location":"Empalme Agua Dulce"},
    {"afip":"5603","location":"Escaba"},
    {"afip":"5604","location":"Escaba De Abajo"},
    {"afip":"5605","location":"Escaba De Arriba"},
    {"afip":"5624","location":"Espiadero"},
    {"afip":"5640","location":"Esquina Del Llano"},
    {"afip":"5643","location":"Esquina Del Valle"},
    {"afip":"5645","location":"Esquina Norte"},
    {"afip":"5661","location":"Establecimiento Esquina"},
    {"afip":"5665","location":"Establecimiento Las Colonias"},
    {"afip":"5683","location":"Estacion Araoz"},
    {"afip":"5698","location":"Estacion De Zootecnia B"},
    {"afip":"5700","location":"Estacion Experimental Agricola"},
    {"afip":"5729","location":"Estacion Superior Agricola"},
    {"afip":"5796","location":"Estancia El Diamante"},
    {"afip":"5868","location":"Estancia La Princesa"},
    {"afip":"5974","location":"Estancia Suri Yaco"},
    {"afip":"6050","location":"Fagsa"},
    {"afip":"6053","location":"Falda De Arcadia"},
    {"afip":"6062","location":"Famailla"},
    {"afip":"6109","location":"Fin Del Mundo"},
    {"afip":"6112","location":"Finca Anchorena"},
    {"afip":"6113","location":"Finca Araoz"},
    {"afip":"6119","location":"Finca Cristina"},
    {"afip":"6124","location":"Finca El Ceibo"},
    {"afip":"6128","location":"Finca Elisa"},
    {"afip":"6129","location":"Finca Entre Rios"},
    {"afip":"6136","location":"Finca Leila"},
    {"afip":"6139","location":"Finca Los Llanos"},
    {"afip":"6140","location":"Finca Mayo"},
    {"afip":"6142","location":"Finca Pacara"},
    {"afip":"6143","location":"Finca Pereyra"},
    {"afip":"6144","location":"Finca Piedra Blanca"},
    {"afip":"6146","location":"Finca San Luis"},
    {"afip":"6149","location":"Finca Tina"},
    {"afip":"6150","location":"Finca Tinta"},
    {"afip":"6151","location":"Finca Tulio"},
    {"afip":"6275","location":"Fronteritas"},
    {"afip":"6291","location":"G Anselmo Rojo"},
    {"afip":"6327","location":"Garmendia"},
    {"afip":"6341","location":"Gastona"},
    {"afip":"6342","location":"Gastonilla"},
    {"afip":"6469","location":"Gobernador Nouges"},
    {"afip":"6473","location":"Gobernador Piedrabuena"},
    {"afip":"6493","location":"Gomez Chico"},
    {"afip":"6501","location":"Gonzalo"},
    {"afip":"6521","location":"Gramajos"},
    {"afip":"6535","location":"Graneros"},
    {"afip":"6537","location":"Granja Modelo"},
    {"afip":"6647","location":"Guzman Estacion Fcgb"},
    {"afip":"6725","location":"Hitachi"},
    {"afip":"6738","location":"Horco Molle"},
    {"afip":"6761","location":"Hoyada"},
    {"afip":"6783","location":"Hualinchay"},
    {"afip":"6795","location":"Huasa Pampa"},
    {"afip":"6796","location":"Huasa Pampa Norte"},
    {"afip":"6797","location":"Huasa Pampa Sur"},
    {"afip":"6798","location":"Huasa Rincon"},
    {"afip":"6799","location":"Huasamayo"},
    {"afip":"6800","location":"Huasamayo Sud"},
    {"afip":"6841","location":"Humaita 1"},
    {"afip":"6842","location":"Humaita 2"},
    {"afip":"6863","location":"Ibatin"},
    {"afip":"6879","location":"Iglesias"},
    {"afip":"6882","location":"Iguana"},
    {"afip":"6890","location":"Iltico"},
    {"afip":"6910","location":"Ing Mercedes"},
    {"afip":"6913","location":"Ingas"},
    {"afip":"6942","location":"Ingeniero Santa Lucia"},
    {"afip":"6948","location":"Ingeniero ñuñorco"}
    ,
    {"afip":"6949","location":"Ingenio Bella Vista"},
    {"afip":"6950","location":"Ingenio Concepcion"},
    {"afip":"6951","location":"Ingenio Cruz Alta"},
    {"afip":"6952","location":"Ingenio Esperanza"},
    {"afip":"6953","location":"Ingenio La Corona"},
    {"afip":"6955","location":"Ingenio La Florida"},
    {"afip":"6956","location":"Ingenio La Fronterita"},
    {"afip":"6957","location":"Ingenio La Providencia"},
    {"afip":"6958","location":"Ingenio La Trinidad"},
    {"afip":"6959","location":"Ingenio Leales"},
    {"afip":"6961","location":"Ingenio Los Ralos"},
    {"afip":"6962","location":"Ingenio Lules"},
    {"afip":"6963","location":"Ingenio Marapa"},
    {"afip":"6966","location":"Ingenio San Juan"},
    {"afip":"6969","location":"Ingenio Santa Ana"},
    {"afip":"6970","location":"Ingenio Santa Barbara"},
    {"afip":"6971","location":"Ingenio Santa Rosa"},
    {"afip":"7008","location":"Ischillon"},
    {"afip":"7054","location":"Isla San Jose Sud"},
    {"afip":"7138","location":"Java"},
    {"afip":"7139","location":"Jaya"},
    {"afip":"7212","location":"Juan Posse"},
    {"afip":"7232","location":"Juliana"},
    {"afip":"7263","location":"Jusco Pozo"},
    {"afip":"7277","location":"Km 12"},
    {"afip":"7381","location":"La Bomba"},
    {"afip":"7387","location":"La Brama"},
    {"afip":"7417","location":"La Cantina"},
    {"afip":"7445","location":"La Cavera"},
    {"afip":"7450","location":"La Cañada Parada"}
    ,
    {"afip":"7514","location":"La Combada"},
    {"afip":"7525","location":"La Cornelia"},
    {"afip":"7529","location":"La Corzuela"},
    {"afip":"7532","location":"La Costa Palampa"},
    {"afip":"7541","location":"La Cruz De Arriba"},
    {"afip":"7590","location":"La Empatada"},
    {"afip":"7592","location":"La Encantada"},
    {"afip":"7599","location":"La Ercilia"},
    {"afip":"7663","location":"La Fronterita"},
    {"afip":"7698","location":"La Guillermina"},
    {"afip":"7700","location":"La Heladera"},
    {"afip":"7721","location":"La Iguana"},
    {"afip":"7840","location":"La Media Agua"},
    {"afip":"7964","location":"La Pinta Y La Cuarenta"},
    {"afip":"7968","location":"La Planta"},
    {"afip":"7977","location":"La Pola"},
    {"afip":"7997","location":"La Princesa"},
    {"afip":"8002","location":"La Puerta De Luca"},
    {"afip":"8003","location":"La Puerta De Marapa"},
    {"afip":"8020","location":"La Queseria"},
    {"afip":"8026","location":"La Ramada De Abajo"},
    {"afip":"8054","location":"La Rinconada Parada"},
    {"afip":"8101","location":"La Silla"},
    {"afip":"8122","location":"La Tapia"},
    {"afip":"8130","location":"La Tipa"},
    {"afip":"8192","location":"La Viñita"}
    ,
    {"afip":"8214","location":"Lacavera"},
    {"afip":"8215","location":"Lachico"},
    {"afip":"8220","location":"Lagarte"},
    {"afip":"8269","location":"Laguna De Robles"},
    {"afip":"8327","location":"Lamparcito"},
    {"afip":"8341","location":"Lapachitos"},
    {"afip":"8345","location":"Lara"},
    {"afip":"8363","location":"Las Acostillas"},
    {"afip":"8384","location":"Las Banderitas"},
    {"afip":"8398","location":"Las Botijas"},
    {"afip":"8401","location":"Las Brisas"},
    {"afip":"8416","location":"Las Carreras"},
    {"afip":"8431","location":"Las Celayas"},
    {"afip":"8457","location":"Las Criollas"},
    {"afip":"8484","location":"Las Faldas"},
    {"afip":"8505","location":"Las Guchas Los Guchea"},
    {"afip":"8536","location":"Las Leguas"},
    {"afip":"8537","location":"Las Lenguas Las Leguas"},
    {"afip":"8570","location":"Las Moritas"},
    {"afip":"8597","location":"Las Parritas"},
    {"afip":"8599","location":"Las Pavas"},
    {"afip":"8600","location":"Las Pechosas"},
    {"afip":"8621","location":"Las Pirvas"},
    {"afip":"8652","location":"Las Tablas"},
    {"afip":"8653","location":"Las Tablitas"},
    {"afip":"8654","location":"Las Tacanas"},
    {"afip":"8669","location":"Las Tipas"},
    {"afip":"8670","location":"Las Tipas De Colalao"},
    {"afip":"8677","location":"Las Trancas Tranquitas"},
    {"afip":"8693","location":"Las Tuscas Tuscal"},
    {"afip":"8714","location":"Las Zanjitas"},
    {"afip":"8715","location":"Las Zorras"},
    {"afip":"8719","location":"Lastenia"},
    {"afip":"8724","location":"Laurel Yaco"},
    {"afip":"8726","location":"Laureles Norte"},
    {"afip":"8727","location":"Laureles Sur"},
    {"afip":"8735","location":"Lazarte"},
    {"afip":"8740","location":"Leales"},
    {"afip":"8758","location":"Leo Huasi"},
    {"afip":"8759","location":"Leocadio Paz"},
    {"afip":"8762","location":"Leon Rouges"},
    {"afip":"8838","location":"Loa Diaz"},
    {"afip":"8848","location":"Lolita"},
    {"afip":"8849","location":"Lolita Nueva"},
    {"afip":"8898","location":"Lomas De Imbaud"},
    {"afip":"8933","location":"Lopez Dominguez"},
    {"afip":"8944","location":"Los Agudos"},
    {"afip":"8945","location":"Los Agueros"},
    {"afip":"8946","location":"Los Aguirre"},
    {"afip":"8949","location":"Los Alamitos"},
    {"afip":"8952","location":"Los Alcaraces"},
    {"afip":"8981","location":"Los Arrietas"},
    {"afip":"8982","location":"Los Arroyo"},
    {"afip":"8985","location":"Los Bajos"},
    {"afip":"8995","location":"Los Bateones"},
    {"afip":"9018","location":"Los Camperos"},
    {"afip":"9035","location":"Los Cercos"},
    {"afip":"9044","location":"Los Chamicos"},
    {"afip":"9057","location":"Los Chorrillos"},
    {"afip":"9079","location":"Los Cordoba"},
    {"afip":"9081","location":"Los Cordones"},
    {"afip":"9084","location":"Los Corpitos"},
    {"afip":"9090","location":"Los Crespo"},
    {"afip":"9093","location":"Los Cuartos"},
    {"afip":"9096","location":"Los Decima"},
    {"afip":"9114","location":"Los Estanques"},
    {"afip":"9133","location":"Los Godos"},
    {"afip":"9134","location":"Los Godoy"},
    {"afip":"9135","location":"Los Gomez"},
    {"afip":"9137","location":"Los Gonzalez"},
    {"afip":"9138","location":"Los Gramajo"},
    {"afip":"9139","location":"Los Gramajos"},
    {"afip":"9141","location":"Los Guayacanes"},
    {"afip":"9143","location":"Los Guchea"},
    {"afip":"9147","location":"Los Gutierrez"},
    {"afip":"9148","location":"Los Hardoy"},
    {"afip":"9152","location":"Los Hilos"},
    {"afip":"9165","location":"Los Juarez"},
    {"afip":"9175","location":"Los Lescanos"},
    {"afip":"9181","location":"Los Lunas"},
    {"afip":"9195","location":"Los Mendozas"},
    {"afip":"9217","location":"Los Moyes"},
    {"afip":"9227","location":"Los Ocho Cuartos"},
    {"afip":"9242","location":"Los Panchillos"},
    {"afip":"9254","location":"Los Pedraza"},
    {"afip":"9261","location":"Los Perez"},
    {"afip":"9266","location":"Los Pinos B"},
    {"afip":"9271","location":"Los Pizarro"},
    {"afip":"9272","location":"Los Planchones"},
    {"afip":"9276","location":"Los Porceles"},
    {"afip":"9290","location":"Los Quemados"},
    {"afip":"9306","location":"Los Reyes"},
    {"afip":"9309","location":"Los Rios"},
    {"afip":"9310","location":"Los Rizos"},
    {"afip":"9314","location":"Los Rodriguez"},
    {"afip":"9315","location":"Los Rojos"},
    {"afip":"9317","location":"Los Romanos"},
    {"afip":"9321","location":"Los Ruiz"},
    {"afip":"9327","location":"Los Saracho"},
    {"afip":"9333","location":"Los Sifones"},
    {"afip":"9336","location":"Los Soraire"},
    {"afip":"9337","location":"Los Sosa"},
    {"afip":"9338","location":"Los Sotelo"},
    {"afip":"9339","location":"Los Sueldos"},
    {"afip":"9361","location":"Los Timbos"},
    {"afip":"9362","location":"Los Timbres"},
    {"afip":"9369","location":"Los Trejos"},
    {"afip":"9371","location":"Los Tres Bajos"},
    {"afip":"9384","location":"Los Vallistos"},
    {"afip":"9388","location":"Los Vega"},
    {"afip":"9391","location":"Los Villagra"},
    {"afip":"9397","location":"Los Zaragoza"},
    {"afip":"9398","location":"Los Zazos"},
    {"afip":"9399","location":"Los Zelayas"},
    {"afip":"9402","location":"Lovar"},
    {"afip":"9436","location":"Luisiana Estacion Fcgm"},
    {"afip":"9440","location":"Lules"},
    {"afip":"9447","location":"Lunarejos"},
    {"afip":"9456","location":"Luz Y Fuerza"},
    {"afip":"9473","location":"Macho Huañusca"}
    ,
    {"afip":"9477","location":"Macio"},
    {"afip":"9478","location":"Macio Sur"},
    {"afip":"9482","location":"Macomita"},
    {"afip":"9562","location":"Managua"},
    {"afip":"9566","location":"Manantial De Ovanta"},
    {"afip":"9574","location":"Manchala"},
    {"afip":"9577","location":"Mancopa"},
    {"afip":"9578","location":"Mancopa Chico"},
    {"afip":"9601","location":"Manuel Garcia Fernandez"},
    {"afip":"9630","location":"Marapa"},
    {"afip":"9654","location":"Maria Blanca"},
    {"afip":"9691","location":"Mariño"}
    ,
    {"afip":"9717","location":"Mascio Pilco"},
    {"afip":"9737","location":"Mato Yaco"},
    {"afip":"9743","location":"Matul"},
    {"afip":"9757","location":"Mayo"},
    {"afip":"9799","location":"Medina"},
    {"afip":"9813","location":"Membrillo"},
    {"afip":"9831","location":"Mesada De Encima"},
    {"afip":"9857","location":"Miguel Lillo"},
    {"afip":"9866","location":"Mimillo"},
    {"afip":"9959","location":"Misky"},
    {"afip":"9960","location":"Mistol"},
    {"afip":"9971","location":"Mixta"},
    {"afip":"10006","location":"Molle Chato"},
    {"afip":"10007","location":"Molle De Abajo"},
    {"afip":"10011","location":"Molle Yaco"},
    {"afip":"10036","location":"Monte Bello"},
    {"afip":"10062","location":"Monte Largo"},
    {"afip":"10072","location":"Monte Redomon"},
    {"afip":"10082","location":"Monteros"},
    {"afip":"10083","location":"Monteros Viejo"},
    {"afip":"10090","location":"Montuoso"},
    {"afip":"10099","location":"Moras Minucas"},
    {"afip":"10124","location":"Mothe"},
    {"afip":"10128","location":"Moya"},
    {"afip":"10133","location":"Mujer Muerta"},
    {"afip":"10139","location":"Multiflores"},
    {"afip":"10148","location":"Muyo"},
    {"afip":"10186","location":"Naranjo Esquina"},
    {"afip":"10190","location":"Nasche"},
    {"afip":"10207","location":"Negro Potrero"},
    {"afip":"10236","location":"Nio El Puestito"},
    {"afip":"10237","location":"Nio Villa Padre Monti"},
    {"afip":"10238","location":"Niogasta"},
    {"afip":"10247","location":"Noario"},
    {"afip":"10251","location":"Nogalita"},
    {"afip":"10274","location":"Nueva Baviera"},
    {"afip":"10285","location":"Nueva Esquina"},
    {"afip":"10298","location":"Nueva Rosa"},
    {"afip":"10314","location":"Nuevo Pueblo La Florida"},
    {"afip":"10317","location":"Nuevos Mataderos"},
    {"afip":"10324","location":"Obraje"},
    {"afip":"10348","location":"Ojo"},
    {"afip":"10427","location":"Overo Pozo"},
    {"afip":"10429","location":"P G Mendez"},
    {"afip":"10440","location":"Pacara"},
    {"afip":"10441","location":"Pacara Marcado"},
    {"afip":"10442","location":"Pacara Pintado"},
    {"afip":"10449","location":"Padilla"},
    {"afip":"10453","location":"Paez"},
    {"afip":"10470","location":"Paja Blanca"},
    {"afip":"10472","location":"Paja Colorada"},
    {"afip":"10480","location":"Pala Pala"},
    {"afip":"10513","location":"Palmas Redondas"},
    {"afip":"10522","location":"Palo Gacho"},
    {"afip":"10535","location":"Palomas"},
    {"afip":"10536","location":"Palominos Banda De"},
    {"afip":"10616","location":"Pampa Mayo Noroeste"},
    {"afip":"10618","location":"Pampa Muyo"},
    {"afip":"10627","location":"Pampa Rosa"},
    {"afip":"10671","location":"Parada De Ohuanta"},
    {"afip":"10807","location":"Paso De Las Lanzas"},
    {"afip":"10821","location":"Paso De Los Nievas"},
    {"afip":"10916","location":"Pasteur Luis"},
    {"afip":"10999","location":"Pereyra Norte"},
    {"afip":"11000","location":"Pereyra Sur"},
    {"afip":"11014","location":"Perucho"},
    {"afip":"11025","location":"Peña Overa"}
    ,
    {"afip":"11026","location":"Peña Picaza"}
    ,
    {"afip":"11059","location":"Pichao"},
    {"afip":"11081","location":"Pie Del Aconquija"},
    {"afip":"11107","location":"Piedra Tendida"},
    {"afip":"11113","location":"Piedras Coloradas"},
    {"afip":"11132","location":"Pilco"},
    {"afip":"11155","location":"Pingollar"},
    {"afip":"11205","location":"Planta Compresora Ypf"},
    {"afip":"11206","location":"Planta De Bombeo De Ypf"},
    {"afip":"11213","location":"Playa Larga"},
    {"afip":"11277","location":"Poliar"},
    {"afip":"11278","location":"Polito"},
    {"afip":"11290","location":"Ponzacon"},
    {"afip":"11294","location":"Port De Las Animas"},
    {"afip":"11297","location":"Port De Tomas"},
    {"afip":"11344","location":"Posse Desvio Particular Fcgm"},
    {"afip":"11357","location":"Posta Vieja"},
    {"afip":"11371","location":"Potrero De Las Cabras"},
    {"afip":"11372","location":"Potrero De Las Tablas"},
    {"afip":"11374","location":"Potrero De Los Alamos"},
    {"afip":"11387","location":"Potro Yaco"},
    {"afip":"11392","location":"Pozo Alto"},
    {"afip":"11440","location":"Pozo Del Alto"},
    {"afip":"11480","location":"Pozo El Quebracho"},
    {"afip":"11493","location":"Pozo Lapacho"},
    {"afip":"11494","location":"Pozo Largo"},
    {"afip":"11511","location":"Pozo Suncho"},
    {"afip":"11519","location":"Pradera Alegre"},
    {"afip":"11646","location":"Pueblo Obrero"},
    {"afip":"11686","location":"Puente El Manantial"},
    {"afip":"11704","location":"Puente Rio Sali"},
    {"afip":"11711","location":"Puerta Alegre"},
    {"afip":"11717","location":"Puerta De Julipao"},
    {"afip":"11726","location":"Puerta De Palavecino"},
    {"afip":"11737","location":"Puerta Quemada"},
    {"afip":"11738","location":"Puerta San Javier"},
    {"afip":"11740","location":"Puertas"},
    {"afip":"11741","location":"Puertas Grandes"},
    {"afip":"11898","location":"Puestito De Arriba"},
    {"afip":"11901","location":"Puesto 9 De Julio"},
    {"afip":"11914","location":"Puesto Belen"},
    {"afip":"11919","location":"Puesto Cevil Con Agua"},
    {"afip":"11922","location":"Puesto Cienaga Amarilla"},
    {"afip":"11924","location":"Puesto Cochucho"},
    {"afip":"11927","location":"Puesto De Alumbre"},
    {"afip":"11929","location":"Puesto De Avila"},
    {"afip":"11935","location":"Puesto De Encalillo"},
    {"afip":"11938","location":"Puesto De Galvanes"},
    {"afip":"11942","location":"Puesto De Julipao"},
    {"afip":"11954","location":"Puesto De Los Valdes"},
    {"afip":"11964","location":"Puesto De Uncos"},
    {"afip":"11967","location":"Puesto De Zarzo"},
    {"afip":"12017","location":"Puesto La Ramadita"},
    {"afip":"12033","location":"Puesto Los Avilas"},
    {"afip":"12034","location":"Puesto Los Barraza"},
    {"afip":"12037","location":"Puesto Los Galvez"},
    {"afip":"12042","location":"Puesto Los Perez"},
    {"afip":"12044","location":"Puesto Los Robles"},
    {"afip":"12088","location":"Puesto Varela"},
    {"afip":"12091","location":"Puesto Villagra"},
    {"afip":"12105","location":"Puma Pozo"},
    {"afip":"12193","location":"Quebrada De Lules"},
    {"afip":"12255","location":"Quinteros 1"},
    {"afip":"12256","location":"Quinteros 2"},
    {"afip":"12266","location":"Quisca Chica"},
    {"afip":"12289","location":"Raco"},
    {"afip":"12296","location":"Rafaela Pozo"},
    {"afip":"12330","location":"Ramos"},
    {"afip":"12334","location":"Ranchillos"},
    {"afip":"12335","location":"Ranchillos Viejos"},
    {"afip":"12337","location":"Rancho De Cascada"},
    {"afip":"12382","location":"Rearte"},
    {"afip":"12428","location":"Requelme"},
    {"afip":"12430","location":"Rescate"},
    {"afip":"12457","location":"Riarte"},
    {"afip":"12468","location":"Riegasta"},
    {"afip":"12476","location":"Rincon De Balderrama"},
    {"afip":"12493","location":"Rincon De Las Tacanas"},
    {"afip":"12505","location":"Rincon De Quilmes"},
    {"afip":"12533","location":"Rincon Huasa"},
    {"afip":"12586","location":"Rio Del Nio"},
    {"afip":"12595","location":"Rio Hondito"},
    {"afip":"12599","location":"Rio Loro"},
    {"afip":"12602","location":"Rio Lules"},
    {"afip":"12633","location":"Rio Vipos"},
    {"afip":"12662","location":"Rodeo Del Algarrobo"},
    {"afip":"12669","location":"Rodeo Toro"},
    {"afip":"12687","location":"Romera Pozo"},
    {"afip":"12688","location":"Romerello"},
    {"afip":"12708","location":"Rosario Oeste"},
    {"afip":"12735","location":"Rumi Cocha"},
    {"afip":"12740","location":"Rumi Punco"},
    {"afip":"12741","location":"Rumi Yura"},
    {"afip":"12753","location":"Ruta Nacional 157"},
    {"afip":"12755","location":"Ruta Nacional 38"},
    {"afip":"12756","location":"Ruta Nacional 64"},
    {"afip":"12757","location":"Ruta Nacional 9"},
    {"afip":"12758","location":"Ruta Nacional38"},
    {"afip":"12762","location":"Ruta Provincial 301"},
    {"afip":"12763","location":"Ruta Provincial 302"},
    {"afip":"12764","location":"Ruta Provincial 303"},
    {"afip":"12765","location":"Ruta Provincial 304"},
    {"afip":"12766","location":"Ruta Provincial 306"},
    {"afip":"12767","location":"Ruta Provincial 308"},
    {"afip":"12768","location":"Ruta Provincial 319"},
    {"afip":"12769","location":"Ruta Provincial 320"},
    {"afip":"12770","location":"Ruta Provincial 322"},
    {"afip":"12771","location":"Ruta Provincial 323"},
    {"afip":"12772","location":"Ruta Provincial 333"},
    {"afip":"12773","location":"Ruta Provincial 334"},
    {"afip":"12774","location":"Ruta Provincial 335"},
    {"afip":"12775","location":"Ruta Provincial 338"},
    {"afip":"12776","location":"Ruta Provincial 366"},
    {"afip":"12777","location":"Ruta Provincial 374"},
    {"afip":"12778","location":"Ruta Provincial 375"},
    {"afip":"12779","location":"Ruta Provincial 380"},
    {"afip":"12794","location":"Sacrificio"},
    {"afip":"12805","location":"Sala Vieja"},
    {"afip":"12822","location":"Salamanca"},
    {"afip":"12826","location":"Salas"},
    {"afip":"12900","location":"San Antonio De Quisca"},
    {"afip":"12911","location":"San Arturo"},
    {"afip":"12917","location":"San Bernardo B"},
    {"afip":"12923","location":"San Carlitos"},
    {"afip":"12975","location":"San Gabriel Del Monte"},
    {"afip":"12997","location":"San Jenaro"},
    {"afip":"13009","location":"San Jose De Buena Vista"},
    {"afip":"13010","location":"San Jose De Chasquivil"},
    {"afip":"13017","location":"San Jose De La Cocha"},
    {"afip":"13023","location":"San Jose De Leales"},
    {"afip":"13026","location":"San Jose De Lules"},
    {"afip":"13027","location":"San Jose De Macomita"},
    {"afip":"13031","location":"San Jose De San Martin"},
    {"afip":"13059","location":"San Julian Yaco"},
    {"afip":"13069","location":"San Luis De Las Casas Viejas"},
    {"afip":"13095","location":"San Miguel De Tucuman"},
    {"afip":"13102","location":"San Miguelito"},
    {"afip":"13120","location":"San Pedro De Colalao"},
    {"afip":"13125","location":"San Pedro Martir"},
    {"afip":"13129","location":"San Pereyra"},
    {"afip":"13135","location":"San Ramon Chicligasta"},
    {"afip":"13168","location":"Sandis"},
    {"afip":"13169","location":"Sandovales"},
    {"afip":"13196","location":"Santa Clara Sud"},
    {"afip":"13259","location":"Santa Rosa De Leales"},
    {"afip":"13322","location":"Sargento Moya"},
    {"afip":"13344","location":"Sauce Gaucho"},
    {"afip":"13350","location":"Sauce Partido"},
    {"afip":"13355","location":"Sauce Seco"},
    {"afip":"13359","location":"Sauce Yaco"},
    {"afip":"13360","location":"Sauce Yacu"},
    {"afip":"13485","location":"Simoca"},
    {"afip":"13490","location":"Sinqueal"},
    {"afip":"13492","location":"Sinquial"},
    {"afip":"13525","location":"Soldado Maldonado"},
    {"afip":"13538","location":"Soraire"},
    {"afip":"13570","location":"Sud De Lazarte"},
    {"afip":"13571","location":"Sud De Sandovales"},
    {"afip":"13572","location":"Sud De Trejos"},
    {"afip":"13573","location":"Sueldo"},
    {"afip":"13574","location":"Sueldos"},
    {"afip":"13590","location":"Suncho Punta"},
    {"afip":"13602","location":"Suriyacu"},
    {"afip":"13643","location":"Taco Llano"},
    {"afip":"13651","location":"Taco Ralo"},
    {"afip":"13652","location":"Taco Rodeo"},
    {"afip":"13655","location":"Taco Yaco"},
    {"afip":"13656","location":"Taco Yana"},
    {"afip":"13675","location":"Tafi Del Valle"},
    {"afip":"13676","location":"Tafi Viejo"},
    {"afip":"13677","location":"Taficillo"},
    {"afip":"13686","location":"Tala Bajada"},
    {"afip":"13687","location":"Tala Caida"},
    {"afip":"13696","location":"Tala Pampa"},
    {"afip":"13699","location":"Tala Sacha"},
    {"afip":"13710","location":"Talamuyo"},
    {"afip":"13719","location":"Talilar"},
    {"afip":"13722","location":"Talita Pozo"},
    {"afip":"13726","location":"Talleres Nacionales"},
    {"afip":"13738","location":"Tambor De Tacuari"},
    {"afip":"13756","location":"Tapia"},
    {"afip":"13782","location":"Tata Yacu"},
    {"afip":"13794","location":"Tecotex"},
    {"afip":"13808","location":"Teniente Berdina"},
    {"afip":"13845","location":"Ticucho"},
    {"afip":"13866","location":"Timbo Nuevo"},
    {"afip":"13869","location":"Timbo Viejo"},
    {"afip":"13876","location":"Tinajeros"},
    {"afip":"13890","location":"Tio Franco"},
    {"afip":"13894","location":"Tio Punco"},
    {"afip":"13896","location":"Tipa Mayo"},
    {"afip":"13898","location":"Tipas"},
    {"afip":"13915","location":"Toco Llana"},
    {"afip":"13949","location":"Toquello"},
    {"afip":"13962","location":"Toro Loco"},
    {"afip":"14035","location":"Tres Almacenes"},
    {"afip":"14152","location":"Tuna Sola"},
    {"afip":"14167","location":"Tusca Pampa"},
    {"afip":"14170","location":"Tuscal Redondo"},
    {"afip":"14173","location":"Tusquitas"},
    {"afip":"14185","location":"Ucuchacra"},
    {"afip":"14209","location":"Urizar"},
    {"afip":"14224","location":"Uturungu"},
    {"afip":"14225","location":"Uturuno"},
    {"afip":"14229","location":"Va Recaste"},
    {"afip":"14236","location":"Vacahuasi"},
    {"afip":"14247","location":"Valenzuela"},
    {"afip":"14333","location":"Vesubio"},
    {"afip":"14336","location":"Viaductos Del Toro"},
    {"afip":"14348","location":"Viclos"},
    {"afip":"14360","location":"Vielos"},
    {"afip":"14365","location":"Vilca Pozo"},
    {"afip":"14384","location":"Villa Alberdi Estacion"},
    {"afip":"14391","location":"Villa Alvear"},
    {"afip":"14398","location":"Villa Angelina"},
    {"afip":"14419","location":"Villa Belgrano"},
    {"afip":"14421","location":"Villa Benjamin Araoz"},
    {"afip":"14432","location":"Villa Brava"},
    {"afip":"14437","location":"Villa Burruyacu"},
    {"afip":"14445","location":"Villa Carmela"},
    {"afip":"14465","location":"Villa Clodomiro Hileret"},
    {"afip":"14469","location":"Villa Colmena"},
    {"afip":"14492","location":"Villa De Los Britos"},
    {"afip":"14511","location":"Villa Desierto De Luz"},
    {"afip":"14512","location":"Villa Devoto"},
    {"afip":"14526","location":"Villa El Bache"},
    {"afip":"14533","location":"Villa El Retiro"},
    {"afip":"14551","location":"Villa Fiad"},
    {"afip":"14582","location":"Villa Gloria"},
    {"afip":"14624","location":"Villa La Colmena"},
    {"afip":"14630","location":"Villa La Soledad"},
    {"afip":"14631","location":"Villa La Trinidad"},
    {"afip":"14632","location":"Villa La Tuna"},
    {"afip":"14642","location":"Villa Leales"},
    {"afip":"14675","location":"Villa Marcos Paz"},
    {"afip":"14700","location":"Villa Monte Cristo"},
    {"afip":"14705","location":"Villa Muñecas"}
    ,
    {"afip":"14712","location":"Villa Nougues"},
    {"afip":"14714","location":"Villa Nueva Aguilares"},
    {"afip":"14727","location":"Villa Padre Monti"},
    {"afip":"14748","location":"Villa Pujio"},
    {"afip":"14752","location":"Villa Quinteros"},
    {"afip":"14768","location":"Villa Rita"},
    {"afip":"14789","location":"Villa San Antonio"},
    {"afip":"14814","location":"Villa Santa Rosa De Nueva Trin"},
    {"afip":"14842","location":"Villa Tercera"},
    {"afip":"14848","location":"Villa Trancas"},
    {"afip":"14864","location":"Villa Vieja Santa Ana"},
    {"afip":"14872","location":"Villa Zenon Santillan"},
    {"afip":"14892","location":"Viltran"},
    {"afip":"14900","location":"Vipos"},
    {"afip":"14983","location":"Yaco"},
    {"afip":"14992","location":"Yacuchina"},
    {"afip":"15005","location":"Yalapa"},
    {"afip":"15007","location":"Yaminas"},
    {"afip":"15011","location":"Yangallo"},
    {"afip":"15012","location":"Yanimas"},
    {"afip":"15014","location":"Yapachin"},
    {"afip":"15020","location":"Yaquilo"},
    {"afip":"15021","location":"Yarami"},
    {"afip":"15026","location":"Yasyamayo"},
    {"afip":"15030","location":"Yatapayana"},
    {"afip":"15042","location":"Yerba Huasi"},
    {"afip":"15050","location":"Ympas"},
    {"afip":"15055","location":"Yonopongo"},
    {"afip":"15059","location":"Yuchaco"},
    {"afip":"15062","location":"Yucumanita"},
    {"afip":"15065","location":"Yumillura"},
    {"afip":"15067","location":"Yunca Suma"},
    {"afip":"15068","location":"Yunopongo Sud"},
    {"afip":"15088","location":"Zanjon Mascio"},
    {"afip":"15126","location":"Zurita"},
    {"afip":"15138","location":"Ñorco"}
    ,
    {"afip":"15139","location":"Ñoreo"}
    ,
    {"afip":"15154","location":"25 De Mayo"},
    {"afip":"15156","location":"7 De Abril"},
    {"afip":"15166","location":"9 De Julio"},
    {"afip":"15168","location":"Acheral"},
    {"afip":"15170","location":"Aconquija"},
    {"afip":"15173","location":"Acostilla"},
    {"afip":"15178","location":"Agua Azul"},
    {"afip":"15180","location":"Agua Blanca"},
    {"afip":"15183","location":"Agua Colorada"},
    {"afip":"15184","location":"Agua Dulce"},
    {"afip":"15189","location":"Agua Negra"},
    {"afip":"15192","location":"Agua Salada"},
    {"afip":"15199","location":"Aguada"},
    {"afip":"15204","location":"Aguadita"},
    {"afip":"15209","location":"Aguas Blancas"},
    {"afip":"15213","location":"Ahi Veremos"},
    {"afip":"15237","location":"Alpachiri"},
    {"afip":"15255","location":"Alto Verde"},
    {"afip":"15259","location":"Amberes"},
    {"afip":"15261","location":"Ampata"},
    {"afip":"15269","location":"Angostura"},
    {"afip":"15272","location":"Animas"},
    {"afip":"15274","location":"Aragones"},
    {"afip":"15282","location":"Arbol Solo"},
    {"afip":"15289","location":"Arenilla"},
    {"afip":"15297","location":"Arroyo De La Cruz"},
    {"afip":"15307","location":"Atahona"},
    {"afip":"15324","location":"Bajo Grande"},
    {"afip":"15339","location":"Balderrama"},
    {"afip":"15341","location":"Banda"},
    {"afip":"15350","location":"Barrancas"},
    {"afip":"15352","location":"Barrancas Coloradas"},
    {"afip":"15354","location":"Barranqueras"},
    {"afip":"15359","location":"Barrealito"},
    {"afip":"15363","location":"Barrio Belgrano"},
    {"afip":"15365","location":"Barrio Rivadavia"},
    {"afip":"15392","location":"Bella Vista"},
    {"afip":"15394","location":"Beltran"},
    {"afip":"15399","location":"Blanco Pozo"},
    {"afip":"15402","location":"Boca De La Quebrada"},
    {"afip":"15405","location":"Boca Del Tigre"},
    {"afip":"15413","location":"Buen Retiro"},
    {"afip":"15428","location":"Buena Vista"},
    {"afip":"15437","location":"Cachi Yaco"},
    {"afip":"15441","location":"California"},
    {"afip":"15445","location":"Campana"},
    {"afip":"15450","location":"Campo Alegre"},
    {"afip":"15451","location":"Campo Azul"},
    {"afip":"15452","location":"Campo Bello"},
    {"afip":"15453","location":"Campo Blanco"},
    {"afip":"15462","location":"Campo Grande"},
    {"afip":"15469","location":"Campo Redondo"},
    {"afip":"15480","location":"Capilla"},
    {"afip":"15487","location":"Carapunco"},
    {"afip":"15489","location":"Carbon Pozo"},
    {"afip":"15504","location":"Casa De Piedra"},
    {"afip":"15510","location":"Casa Vieja"},
    {"afip":"15514","location":"Casas Viejas"},
    {"afip":"15518","location":"Caspinchango"},
    {"afip":"15525","location":"Cañada"}
    ,
    {"afip":"15526","location":"Cañada Angosta"}
    ,
    {"afip":"15527","location":"Cañada De La Cruz"}
    ,
    {"afip":"15535","location":"Cañada Honda"}
    ,
    {"afip":"15537","location":"Cañada Larga"}
    ,
    {"afip":"15551","location":"Ceja Pozo"},
    {"afip":"15614","location":"Chamico"},
    {"afip":"15619","location":"Chavarria"},
    {"afip":"15622","location":"Chañar"}
    ,
    {"afip":"15625","location":"Chañar Pozo"}
    ,
    {"afip":"15626","location":"Chañar Viejo"}
    ,
    {"afip":"15631","location":"Chañaritos"}
    ,
    {"afip":"15633","location":"Chilca"},
    {"afip":"15634","location":"Chilcar"},
    {"afip":"15636","location":"Chilcas"},
    {"afip":"15643","location":"Chorrillos"},
    {"afip":"15646","location":"Churqui"},
    {"afip":"15650","location":"Cienaguita"},
    {"afip":"15662","location":"Colonia Argentina"},
    {"afip":"15698","location":"Colonia Mercedes"},
    {"afip":"15729","location":"Colonia San Miguel"},
    {"afip":"15732","location":"Colonia San Ramon"},
    {"afip":"15737","location":"Colonia Santa Catalina"},
    {"afip":"15738","location":"Colonia Santa Clara"},
    {"afip":"15742","location":"Colonia Santa Lucia"},
    {"afip":"15747","location":"Colonia Santa Rita"},
    {"afip":"15763","location":"Concepcion"},
    {"afip":"15768","location":"Condor Huasi"},
    {"afip":"15773","location":"Corona"},
    {"afip":"15779","location":"Corral Blanco"},
    {"afip":"15783","location":"Corral Grande"},
    {"afip":"15787","location":"Corral Viejo"},
    {"afip":"15803","location":"Cortaderas"},
    {"afip":"15807","location":"Cruz Alta"},
    {"afip":"15831","location":"Dolavon"},
    {"afip":"15840","location":"Dos Pozos"},
    {"afip":"15845","location":"Durazno"},
    {"afip":"15855","location":"El Aguilar"},
    {"afip":"15881","location":"El Arbolito"},
    {"afip":"15885","location":"El Arenal"},
    {"afip":"15892","location":"El Bagual"},
    {"afip":"15895","location":"El Bajo"},
    {"afip":"15909","location":"El Barrialito"},
    {"afip":"15913","location":"El Bañado"}
    ,
    {"afip":"15925","location":"El Brete"},
    {"afip":"15928","location":"El Cadillal"},
    {"afip":"15934","location":"El Cajon"},
    {"afip":"15941","location":"El Carbon"},
    {"afip":"15950","location":"El Carmen"},
    {"afip":"15957","location":"El Carrizal"},
    {"afip":"15959","location":"El Catorce"},
    {"afip":"15964","location":"El Ceibal"},
    {"afip":"15972","location":"El Cercado"},
    {"afip":"15990","location":"El Chañar"}
    ,
    {"afip":"15993","location":"El Chorro"},
    {"afip":"15994","location":"El Churqui"},
    {"afip":"16001","location":"El Colmenar"},
    {"afip":"16012","location":"El Corralito"},
    {"afip":"16013","location":"El Corte"},
    {"afip":"16032","location":"El Divisadero"},
    {"afip":"16037","location":"El Duraznillo"},
    {"afip":"16038","location":"El Duraznito"},
    {"afip":"16047","location":"El Durazno"},
    {"afip":"16051","location":"El Espinal"},
    {"afip":"16058","location":"El Espinillo"},
    {"afip":"16074","location":"El Guayacan"},
    {"afip":"16077","location":"El Huaico"},
    {"afip":"16079","location":"El Indio"},
    {"afip":"16080","location":"El Infiernillo"},
    {"afip":"16085","location":"El Jardin"},
    {"afip":"16096","location":"El Manantial"},
    {"afip":"16111","location":"El Milagro"},
    {"afip":"16121","location":"El Mistol"},
    {"afip":"16122","location":"El Mistolar"},
    {"afip":"16125","location":"El Mojon"},
    {"afip":"16132","location":"El Molino"},
    {"afip":"16138","location":"El Mollar"},
    {"afip":"16144","location":"El Molle"},
    {"afip":"16148","location":"El Moyar"},
    {"afip":"16151","location":"El Naranjo"},
    {"afip":"16152","location":"El Nogal"},
    {"afip":"16153","location":"El Nogalar"},
    {"afip":"16156","location":"El Obraje"},
    {"afip":"16157","location":"El Ojo"},
    {"afip":"16160","location":"El Once"},
    {"afip":"16170","location":"El Palomar"},
    {"afip":"16182","location":"El Paraiso"},
    {"afip":"16188","location":"El Paso"},
    {"afip":"16189","location":"El Pelado"},
    {"afip":"16200","location":"El Polear"},
    {"afip":"16207","location":"El Portezuelo"},
    {"afip":"16223","location":"El Porvenir"},
    {"afip":"16226","location":"El Potrerillo"},
    {"afip":"16232","location":"El Potrero"},
    {"afip":"16236","location":"El Pozo"},
    {"afip":"16239","location":"El Prado"},
    {"afip":"16250","location":"El Puerto"},
    {"afip":"16255","location":"El Puestito"},
    {"afip":"16260","location":"El Puesto"},
    {"afip":"16267","location":"El Quebrachal"},
    {"afip":"16269","location":"El Quebrachito"},
    {"afip":"16293","location":"El Remate"},
    {"afip":"16310","location":"El Rincon"},
    {"afip":"16318","location":"El Rodeo"},
    {"afip":"16327","location":"El Rosario"},
    {"afip":"16360","location":"El Sauzal"},
    {"afip":"16376","location":"El Sunchal"},
    {"afip":"16377","location":"El Suncho"},
    {"afip":"16381","location":"El Tajamar"},
    {"afip":"16389","location":"El Talar"},
    {"afip":"16397","location":"El Timbo"},
    {"afip":"16400","location":"El Tipal"},
    {"afip":"16402","location":"El Toro"},
    {"afip":"16403","location":"El Tostado"},
    {"afip":"16415","location":"El Triunfo"},
    {"afip":"16420","location":"El Tropezon"},
    {"afip":"16421","location":"El Tuscal"},
    {"afip":"16430","location":"El Vallecito"},
    {"afip":"16439","location":"El Zanjon"},
    {"afip":"16445","location":"El Zapallar"},
    {"afip":"16451","location":"Encrucijada"},
    {"afip":"16456","location":"Ensenada"},
    {"afip":"16461","location":"Entre Rios"},
    {"afip":"16462","location":"Escobas"},
    {"afip":"16476","location":"Esquina"},
    {"afip":"16500","location":"Estanque"},
    {"afip":"16506","location":"Favorina"},
    {"afip":"16511","location":"Finca Lopez"},
    {"afip":"16532","location":"Fuerte Alto"},
    {"afip":"16534","location":"Fuerte Quemado"},
    {"afip":"16550","location":"Gramilla"},
    {"afip":"16553","location":"Guanaco Muerto"},
    {"afip":"16559","location":"Guemes"},
    {"afip":"16564","location":"Higueritas"},
    {"afip":"16573","location":"Huacra"},
    {"afip":"16576","location":"Ichipuca"},
    {"afip":"16579","location":"Independencia"},
    {"afip":"16583","location":"India Muerta"},
    {"afip":"16587","location":"Invernada"},
    {"afip":"16596","location":"Isla San Jose"},
    {"afip":"16604","location":"Jaguel"},
    {"afip":"16613","location":"Juan Bautista Alberdi"},
    {"afip":"16615","location":"Julipao"},
    {"afip":"16618","location":"Junta"},
    {"afip":"16630","location":"La Aguada"},
    {"afip":"16636","location":"La Aguadita"},
    {"afip":"16637","location":"La Aguita"},
    {"afip":"16649","location":"La Angostura"},
    {"afip":"16663","location":"La Argentina"},
    {"afip":"16686","location":"La Banda"},
    {"afip":"16687","location":"La Banderita"},
    {"afip":"16698","location":"La Bolsa"},
    {"afip":"16713","location":"La Calera"},
    {"afip":"16722","location":"La Capilla"},
    {"afip":"16736","location":"La Cautiva"},
    {"afip":"16745","location":"La Cañada"}
    ,
    {"afip":"16760","location":"La Chilca"},
    {"afip":"16774","location":"La Cienaga"},
    {"afip":"16781","location":"La Cocha"},
    {"afip":"16793","location":"La Colonia"},
    {"afip":"16800","location":"La Concepcion"},
    {"afip":"16827","location":"La Cruz"},
    {"afip":"16831","location":"La Cuesta"},
    {"afip":"16832","location":"La Cueva"},
    {"afip":"16843","location":"La Donosa"},
    {"afip":"16847","location":"La Dorita"},
    {"afip":"16884","location":"La Esperanza"},
    {"afip":"16891","location":"La Esquina"},
    {"afip":"16911","location":"La Estrella"},
    {"afip":"16918","location":"La Falda"},
    {"afip":"16920","location":"La Favorita"},
    {"afip":"16928","location":"La Flor"},
    {"afip":"16945","location":"La Florida"},
    {"afip":"16951","location":"La Fortuna"},
    {"afip":"16960","location":"La Grama"},
    {"afip":"16979","location":"La Higuera"},
    {"afip":"16987","location":"La Hoyada"},
    {"afip":"16993","location":"La Huerta"},
    {"afip":"17002","location":"La Invernada"},
    {"afip":"17015","location":"La Isla"},
    {"afip":"17027","location":"La Junta"},
    {"afip":"17031","location":"La Laguna"},
    {"afip":"17033","location":"La Lagunilla"},
    {"afip":"17034","location":"La Lagunita"},
    {"afip":"17046","location":"La Libertad"},
    {"afip":"17058","location":"La Loma"},
    {"afip":"17074","location":"La Manga"},
    {"afip":"17080","location":"La Maravilla"},
    {"afip":"17090","location":"La Marta"},
    {"afip":"17104","location":"La Mesada"},
    {"afip":"17163","location":"La Picada"},
    {"afip":"17193","location":"La Posta"},
    {"afip":"17216","location":"La Puerta"},
    {"afip":"17220","location":"La Puntilla"},
    {"afip":"17225","location":"La Quebrada"},
    {"afip":"17231","location":"La Quinta"},
    {"afip":"17237","location":"La Ramada"},
    {"afip":"17240","location":"La Ramadita"},
    {"afip":"17244","location":"La Reduccion"},
    {"afip":"17248","location":"La Reina"},
    {"afip":"17264","location":"La Rinconada"},
    {"afip":"17275","location":"La Ruda"},
    {"afip":"17276","location":"La Sala"},
    {"afip":"17279","location":"La Salamanca"},
    {"afip":"17301","location":"La Soledad"},
    {"afip":"17308","location":"La Tala"},
    {"afip":"17320","location":"La Toma"},
    {"afip":"17328","location":"La Tranca"},
    {"afip":"17331","location":"La Trinidad"},
    {"afip":"17332","location":"La Tuna"},
    {"afip":"17341","location":"La Union"},
    {"afip":"17352","location":"La Verde"},
    {"afip":"17372","location":"La Zanja"},
    {"afip":"17379","location":"Laguna Blanca"},
    {"afip":"17388","location":"Laguna Grande"},
    {"afip":"17392","location":"Laguna Larga"},
    {"afip":"17405","location":"Lamadrid"},
    {"afip":"17411","location":"Las Abras"},
    {"afip":"17418","location":"Las Animas"},
    {"afip":"17419","location":"Las Arcas"},
    {"afip":"17429","location":"Las Barrancas"},
    {"afip":"17433","location":"Las Bolsas"},
    {"afip":"17434","location":"Las Burras"},
    {"afip":"17444","location":"Las Cañadas"}
    ,
    {"afip":"17451","location":"Las Cañas"}
    ,
    {"afip":"17455","location":"Las Cañitas"}
    ,
    {"afip":"17456","location":"Las Cejas"},
    {"afip":"17465","location":"Las Chacras"},
    {"afip":"17473","location":"Las Cienagas"},
    {"afip":"17474","location":"Las Colas"},
    {"afip":"17477","location":"Las Colonias"},
    {"afip":"17486","location":"Las Cuatro Esquinas"},
    {"afip":"17493","location":"Las Cuevas"},
    {"afip":"17506","location":"Las Encrucijadas"},
    {"afip":"17529","location":"Las Higuerillas"},
    {"afip":"17534","location":"Las Higueritas"},
    {"afip":"17545","location":"Las Juntas"},
    {"afip":"17567","location":"Las Lomitas"},
    {"afip":"17575","location":"Las Mellizas"},
    {"afip":"17585","location":"Las Mercedes"},
    {"afip":"17586","location":"Las Mesadas"},
    {"afip":"17593","location":"Las Moreras"},
    {"afip":"17611","location":"Las Palmitas"},
    {"afip":"17616","location":"Las Palomitas"},
    {"afip":"17620","location":"Las Pampitas"},
    {"afip":"17635","location":"Las Piedritas"},
    {"afip":"17637","location":"Las Pircas"},
    {"afip":"17645","location":"Las Ratas"},
    {"afip":"17663","location":"Las Salinas"},
    {"afip":"17665","location":"Las Talas"},
    {"afip":"17666","location":"Las Talitas"},
    {"afip":"17684","location":"Las Tres Flores"},
    {"afip":"17702","location":"Las Zanjas"},
    {"afip":"17703","location":"Laureles"},
    {"afip":"17710","location":"Lescano"},
    {"afip":"17724","location":"Loma Colorada"},
    {"afip":"17728","location":"Loma Del Medio"},
    {"afip":"17730","location":"Loma Grande"},
    {"afip":"17738","location":"Loma Negra"},
    {"afip":"17742","location":"Loma Redonda"},
    {"afip":"17745","location":"Loma Verde"},
    {"afip":"17763","location":"Los Alamos"},
    {"afip":"17772","location":"Los Alisos"},
    {"afip":"17778","location":"Los Angeles"},
    {"afip":"17794","location":"Los Bordos"},
    {"afip":"17795","location":"Los Britos"},
    {"afip":"17796","location":"Los Bulacios"},
    {"afip":"17801","location":"Los Callejones"},
    {"afip":"17821","location":"Los Chañares"}
    ,
    {"afip":"17823","location":"Los Chañaritos"}
    ,
    {"afip":"17832","location":"Los Colorados"},
    {"afip":"17843","location":"Los Diaz"},
    {"afip":"17848","location":"Los Eucaliptos"},
    {"afip":"17853","location":"Los Galpones"},
    {"afip":"17855","location":"Los Gonzales"},
    {"afip":"17858","location":"Los Herreras"},
    {"afip":"17879","location":"Los Mistoles"},
    {"afip":"17893","location":"Los Molles"},
    {"afip":"17903","location":"Los Nogales"},
    {"afip":"17914","location":"Los Paraisos"},
    {"afip":"17921","location":"Los Pereyra"},
    {"afip":"17930","location":"Los Pocitos"},
    {"afip":"17942","location":"Los Puestos"},
    {"afip":"17949","location":"Los Ralos"},
    {"afip":"17954","location":"Los Robles"},
    {"afip":"17955","location":"Los Sarmientos"},
    {"afip":"17963","location":"Los Sauces"},
    {"afip":"17972","location":"Los Valdes"},
    {"afip":"17973","location":"Los Vazquez"},
    {"afip":"17974","location":"Los Villegas"},
    {"afip":"17981","location":"Lujan"},
    {"afip":"17988","location":"Mal Paso"},
    {"afip":"17991","location":"Malvinas"},
    {"afip":"18004","location":"Manantiales"},
    {"afip":"18006","location":"Manuela Pedraza"},
    {"afip":"18014","location":"Maria Elena"},
    {"afip":"18017","location":"Maria Luisa"},
    {"afip":"18018","location":"Marta"},
    {"afip":"18041","location":"Mercedes"},
    {"afip":"18057","location":"Miranda"},
    {"afip":"18060","location":"Mojon"},
    {"afip":"18064","location":"Molinos"},
    {"afip":"18066","location":"Molle Pozo"},
    {"afip":"18068","location":"Molles"},
    {"afip":"18069","location":"Monasterio"},
    {"afip":"18075","location":"Monte Cristo"},
    {"afip":"18080","location":"Monte Grande"},
    {"afip":"18086","location":"Monte Redondo"},
    {"afip":"18088","location":"Monte Rico"},
    {"afip":"18094","location":"Monteagudo"},
    {"afip":"18101","location":"Moron"},
    {"afip":"18103","location":"Moyar"},
    {"afip":"18104","location":"Mundo Nuevo"},
    {"afip":"18107","location":"Naranjito"},
    {"afip":"18117","location":"Nogalito"},
    {"afip":"18120","location":"Nueva España"}
    ,
    {"afip":"18125","location":"Nueva Esperanza"},
    {"afip":"18128","location":"Nueva Trinidad"},
    {"afip":"18140","location":"Ojo De Agua"},
    {"afip":"18146","location":"Oran"},
    {"afip":"18152","location":"Ovejeria"},
    {"afip":"18167","location":"Palmitas"},
    {"afip":"18174","location":"Palo Blanco"},
    {"afip":"18182","location":"Palo Seco"},
    {"afip":"18185","location":"Palomitas"},
    {"afip":"18186","location":"Palos Quemados"},
    {"afip":"18195","location":"Pampa Larga"},
    {"afip":"18196","location":"Pampa Mayo"},
    {"afip":"18198","location":"Pampa Pozo"},
    {"afip":"18200","location":"Pantanillo"},
    {"afip":"18206","location":"Paraiso"},
    {"afip":"18212","location":"Paso De La Patria"},
    {"afip":"18225","location":"Paso Grande"},
    {"afip":"18243","location":"Pie De La Cuesta"},
    {"afip":"18249","location":"Piedra Blanca"},
    {"afip":"18250","location":"Piedra Grande"},
    {"afip":"18263","location":"Piedras Blancas"},
    {"afip":"18269","location":"Pirhuas"},
    {"afip":"18282","location":"Portezuelo"},
    {"afip":"18286","location":"Porvenir"},
    {"afip":"18287","location":"Posta"},
    {"afip":"18290","location":"Potrerillo"},
    {"afip":"18293","location":"Potrerillos"},
    {"afip":"18296","location":"Potrero"},
    {"afip":"18298","location":"Potrero Grande"},
    {"afip":"18303","location":"Pozo Cavado"},
    {"afip":"18314","location":"Pozo Del Algarrobo"},
    {"afip":"18329","location":"Pozo Grande"},
    {"afip":"18333","location":"Pozo Hondo"},
    {"afip":"18341","location":"Pozo Verde"},
    {"afip":"18364","location":"Pueblo Nuevo"},
    {"afip":"18367","location":"Pueblo Viejo"},
    {"afip":"18373","location":"Puerta Grande"},
    {"afip":"18374","location":"Puerta Vieja"},
    {"afip":"18383","location":"Puesto Chico"},
    {"afip":"18392","location":"Puesto Del Medio"},
    {"afip":"18395","location":"Puesto Grande"},
    {"afip":"18398","location":"Puesto Nuevo"},
    {"afip":"18402","location":"Puesto Viejo"},
    {"afip":"18411","location":"Punta De Rieles"},
    {"afip":"18416","location":"Punta Del Agua"},
    {"afip":"18422","location":"Punta Del Monte"},
    {"afip":"18426","location":"Punta Rieles"},
    {"afip":"18433","location":"Quebrachito"},
    {"afip":"18444","location":"Quilmes"},
    {"afip":"18450","location":"Ramaditas"},
    {"afip":"18475","location":"Retiro"},
    {"afip":"18481","location":"Rincon"},
    {"afip":"18488","location":"Rincon Grande"},
    {"afip":"18495","location":"Rio Blanco"},
    {"afip":"18501","location":"Rio Chico"},
    {"afip":"18505","location":"Rio Colorado"},
    {"afip":"18517","location":"Rio Seco"},
    {"afip":"18532","location":"Rodeo"},
    {"afip":"18535","location":"Rodeo Grande"},
    {"afip":"18538","location":"Roma"},
    {"afip":"18543","location":"Rosario"},
    {"afip":"18550","location":"Saladillo"},
    {"afip":"18557","location":"Salinas"},
    {"afip":"18568","location":"San Agustin"},
    {"afip":"18575","location":"San Alberto"},
    {"afip":"18583","location":"San Andres"},
    {"afip":"18599","location":"San Antonio"},
    {"afip":"18600","location":"San Antonio De Padua"},
    {"afip":"18627","location":"San Carlos"},
    {"afip":"18649","location":"San Eusebio"},
    {"afip":"18650","location":"San Federico"},
    {"afip":"18656","location":"San Felipe"},
    {"afip":"18665","location":"San Fernando"},
    {"afip":"18673","location":"San Francisco"},
    {"afip":"18677","location":"San Gabriel"},
    {"afip":"18679","location":"San German"},
    {"afip":"18685","location":"San Geronimo"},
    {"afip":"18705","location":"San Ignacio"},
    {"afip":"18716","location":"San Isidro"},
    {"afip":"18727","location":"San Javier"},
    {"afip":"18757","location":"San Jose"},
    {"afip":"18758","location":"San Jose De Flores"},
    {"afip":"18774","location":"San Juancito"},
    {"afip":"18796","location":"San Lorenzo"},
    {"afip":"18805","location":"San Luis"},
    {"afip":"18838","location":"San Miguel"},
    {"afip":"18844","location":"San Nicolas"},
    {"afip":"18849","location":"San Pablo"},
    {"afip":"18853","location":"San Patricio"},
    {"afip":"18867","location":"San Pedro"},
    {"afip":"18878","location":"San Rafael"},
    {"afip":"18887","location":"San Ramon"},
    {"afip":"18928","location":"San Vicente"},
    {"afip":"18941","location":"Santa Ana"},
    {"afip":"18947","location":"Santa Barbara"},
    {"afip":"18956","location":"Santa Catalina"},
    {"afip":"18971","location":"Santa Cruz"},
    {"afip":"18981","location":"Santa Elena"},
    {"afip":"18986","location":"Santa Felisa"},
    {"afip":"19003","location":"Santa Isabel"},
    {"afip":"19014","location":"Santa Lucia"},
    {"afip":"19016","location":"Santa Luisa"},
    {"afip":"19034","location":"Santa Monica"},
    {"afip":"19044","location":"Santa Rita"},
    {"afip":"19058","location":"Santa Rosa"},
    {"afip":"19068","location":"Santa Teresa"},
    {"afip":"19072","location":"Santillan"},
    {"afip":"19087","location":"Santo Domingo"},
    {"afip":"19099","location":"Santos Lugares"},
    {"afip":"19113","location":"Sauce Huacho"},
    {"afip":"19118","location":"Sauzal"},
    {"afip":"19121","location":"Sepultura"},
    {"afip":"19122","location":"Sesteadero"},
    {"afip":"19131","location":"Simbol"},
    {"afip":"19135","location":"Simbolar"},
    {"afip":"19144","location":"Sol De Mayo"},
    {"afip":"19151","location":"Soledad"},
    {"afip":"19156","location":"Sunchal"},
    {"afip":"19157","location":"Superintendente Ledesma"},
    {"afip":"19158","location":"Suriyaco"},
    {"afip":"19160","location":"Tacanas"},
    {"afip":"19161","location":"Taco"},
    {"afip":"19162","location":"Taco Palta"},
    {"afip":"19165","location":"Taco Punco"},
    {"afip":"19172","location":"Tala Paso"},
    {"afip":"19173","location":"Tala Pozo"},
    {"afip":"19175","location":"Tala Yaco"},
    {"afip":"19179","location":"Talitas"},
    {"afip":"19182","location":"Taquello"},
    {"afip":"19185","location":"Taruca Pampa"},
    {"afip":"19187","location":"Tierras Blancas"},
    {"afip":"19196","location":"Toro Muerto"},
    {"afip":"19198","location":"Toro Yaco"},
    {"afip":"19199","location":"Tostado"},
    {"afip":"19206","location":"Totoral"},
    {"afip":"19209","location":"Totorilla"},
    {"afip":"19210","location":"Totoritas"},
    {"afip":"19213","location":"Trancas"},
    {"afip":"19214","location":"Tranquitas"},
    {"afip":"19253","location":"Tres Pozos"},
    {"afip":"19254","location":"Tres Sargentos"},
    {"afip":"19257","location":"Trinidad"},
    {"afip":"19261","location":"Tunalito"},
    {"afip":"19262","location":"Tusca Pozo"},
    {"afip":"19263","location":"Tuscal"},
    {"afip":"19284","location":"Villa Alberdi"},
    {"afip":"19288","location":"Villa Carolina"},
    {"afip":"19312","location":"Villa Maria"},
    {"afip":"19315","location":"Villa Mercedes"},
    {"afip":"19316","location":"Villa Mitre"},
    {"afip":"19319","location":"Villa Nueva"},
    {"afip":"19324","location":"Villa Rosa"},
    {"afip":"19327","location":"Villa San Javier"},
    {"afip":"19340","location":"Villa Vieja"},
    {"afip":"19346","location":"Virginia"},
    {"afip":"19351","location":"Vizcachera"},
    {"afip":"19355","location":"Yacuchiri"},
    {"afip":"19361","location":"Yerba Buena"},
    {"afip":"19366","location":"Zapallar"},
    {"afip":"19368","location":"Zarate"},
    {"afip":"19369","location":"Zavalia"},
    {"afip":"19388","location":"Esquina"},
    {"afip":"19389","location":"La Aguadita"},
    {"afip":"19390","location":"La Bolsa"},
    {"afip":"19391","location":"La Cienaga"},
    {"afip":"19392","location":"La Cruz"},
    {"afip":"19393","location":"La Maravilla"},
    {"afip":"19394","location":"La Sala"},
    {"afip":"19395","location":"Las Zanjas"},
    {"afip":"19396","location":"Los Pocitos"},
    {"afip":"19397","location":"Quilmes"},
    {"afip":"19398","location":"Rodeo Grande"},
    {"afip":"19399","location":"Saladillo"},
    {"afip":"19400","location":"San Francisco"},
    {"afip":"19401","location":"Sinqueal"},
    {"afip":"19402","location":"Aguadita"},
    {"afip":"19403","location":"Angostura"},
    {"afip":"19404","location":"El Duraznito"},
    {"afip":"19405","location":"La Banda"},
    {"afip":"19406","location":"La Esperanza"},
    {"afip":"19407","location":"La Ramada"},
    {"afip":"19408","location":"La Sala"},
    {"afip":"19409","location":"La Toma"},
    {"afip":"19410","location":"Las Cañitas"}
    ,
    {"afip":"19411","location":"Las Talitas"},
    {"afip":"19412","location":"Las Talitas"},
    {"afip":"19413","location":"Las Tipas"},
    {"afip":"19414","location":"Los Pocitos"},
    {"afip":"19415","location":"Quilmes"},
    {"afip":"19416","location":"Rio Loro"},
    {"afip":"19417","location":"Santa Lucia"},
    {"afip":"19418","location":"Yerba Buena"},
    {"afip":"19419","location":"Agua Salada"},
    {"afip":"19420","location":"Benjamin Paz"},
    {"afip":"19421","location":"Cachi Yaco"},
    {"afip":"19422","location":"Campo Redondo"},
    {"afip":"19423","location":"Casa Del Alto"},
    {"afip":"19424","location":"Casas Viejas"},
    {"afip":"19425","location":"Desmonte"},
    {"afip":"19426","location":"El Arenal"},
    {"afip":"19427","location":"El Aserradero"},
    {"afip":"19428","location":"El Cadillal"},
    {"afip":"19429","location":"El Milagro"},
    {"afip":"19430","location":"El Mistol"},
    {"afip":"19431","location":"El Molino"},
    {"afip":"19432","location":"El Mollar"},
    {"afip":"19433","location":"El Pelado"},
    {"afip":"19434","location":"El Porvenir"},
    {"afip":"19435","location":"El Potrero"},
    {"afip":"19436","location":"El Pozo"},
    {"afip":"19437","location":"El Puestito"},
    {"afip":"19438","location":"El Talar"},
    {"afip":"19439","location":"Huasamayo"},
    {"afip":"19440","location":"La Aguada"},
    {"afip":"19441","location":"La Aguadita"},
    {"afip":"19442","location":"La Banda"},
    {"afip":"19443","location":"La Cañada"}
    ,
    {"afip":"19444","location":"La Cienaga"},
    {"afip":"19445","location":"La Esperanza"},
    {"afip":"19446","location":"La Higuera"},
    {"afip":"19447","location":"La Toma"},
    {"afip":"19448","location":"La Zanja"},
    {"afip":"19449","location":"Las Arcas"},
    {"afip":"19450","location":"Las Cañitas"}
    ,
    {"afip":"19451","location":"Las Juntas"},
    {"afip":"19452","location":"Las Mesadas"},
    {"afip":"19453","location":"Los Puestos"},
    {"afip":"19454","location":"Los Sauces"},
    {"afip":"19455","location":"Molle Yaco"},
    {"afip":"19456","location":"Monte Bello"},
    {"afip":"19457","location":"Pie De La Cuesta"},
    {"afip":"19458","location":"Portezuelo"},
    {"afip":"19459","location":"Rearte"},
    {"afip":"19460","location":"Rodeo Grande"},
    {"afip":"19461","location":"San Carlos"},
    {"afip":"19462","location":"San Jose"},
    {"afip":"19463","location":"San Miguel"},
    {"afip":"19464","location":"San Vicente"},
    {"afip":"19465","location":"Santa Rita"},
    {"afip":"19466","location":"Sauce Yacu"},
    {"afip":"19467","location":"Tacanas"},
    {"afip":"19468","location":"La Banda"},
    {"afip":"19469","location":"La Sala"},
    {"afip":"19470","location":"Las Talas"},
    {"afip":"19471","location":"Los Bulacios"},
    {"afip":"19472","location":"Potrerillo"},
    {"afip":"19473","location":"Ruta Provincial 338"},
    {"afip":"19474","location":"San Javier"},
    {"afip":"19475","location":"Yerba Buena"},
    {"afip":"19476","location":"Alto Verde"},
    {"afip":"19477","location":"Campo Redondo"},
    {"afip":"19478","location":"El Carmen"},
    {"afip":"19479","location":"El Ceibal"},
    {"afip":"19480","location":"La Bolsa"},
    {"afip":"19481","location":"Las Juntas"},
    {"afip":"19482","location":"Los Aguirre"},
    {"afip":"19483","location":"Los Chañaritos"}
    ,
    {"afip":"19484","location":"Potrerillo"},
    {"afip":"19485","location":"Ruta Nacional 157"},
    {"afip":"19486","location":"Ruta Provincial 380"},
    {"afip":"19487","location":"San Felipe"},
    {"afip":"19488","location":"San Jose"},
    {"afip":"19489","location":"San Miguel"},
    {"afip":"19490","location":"San Pablo"},
    {"afip":"19491","location":"Santa Barbara"},
    {"afip":"19492","location":"Cachi Huasi"},
    {"afip":"19493","location":"Cachi Yaco"},
    {"afip":"19494","location":"Camas Amontonadas"},
    {"afip":"19495","location":"Campana"},
    {"afip":"19496","location":"Campo Azul"},
    {"afip":"19497","location":"Cañada De Viclos"}
    ,
    {"afip":"19498","location":"Candelillal"},
    {"afip":"19499","location":"Capitan Caceres"},
    {"afip":"19500","location":"Cevilarcito"},
    {"afip":"19501","location":"Chañarito"}
    ,
    {"afip":"19502","location":"Chilcar"},
    {"afip":"19503","location":"La Florida"},
    {"afip":"19504","location":"La Maravilla"},
    {"afip":"19505","location":"Oran"},
    {"afip":"19506","location":"Pueblo Viejo"},
    {"afip":"19507","location":"Ruta Nacional 38"},
    {"afip":"19508","location":"San Jose"},
    {"afip":"19509","location":"Santa Lucia"},
    {"afip":"19510","location":"Santa Rosa"},
    {"afip":"19511","location":"El Carmen"},
    {"afip":"19512","location":"El Rodeo"},
    {"afip":"19513","location":"Las Animas"},
    {"afip":"19514","location":"Mal Paso"},
    {"afip":"19515","location":"Mercedes"},
    {"afip":"19516","location":"Monte Bello"},
    {"afip":"19517","location":"Monte Redondo"},
    {"afip":"19518","location":"San Miguel"},
    {"afip":"19519","location":"Santa Rosa"},
    {"afip":"19520","location":"Yaminas"},
    {"afip":"19521","location":"Buena Vista"},
    {"afip":"19522","location":"El Durazno"},
    {"afip":"19523","location":"El Jardin"},
    {"afip":"19524","location":"El Mollar"},
    {"afip":"19525","location":"El Polear"},
    {"afip":"19526","location":"El Rodeo"},
    {"afip":"19527","location":"Entre Rios"},
    {"afip":"19528","location":"La Florida"},
    {"afip":"19529","location":"La Loma"},
    {"afip":"19530","location":"La Tuna"},
    {"afip":"19531","location":"Las Cejas"},
    {"afip":"19532","location":"Loma Grande"},
    {"afip":"19533","location":"Los Perez"},
    {"afip":"19534","location":"Macio"},
    {"afip":"19535","location":"Pampa Mayo"},
    {"afip":"19536","location":"San Carlos"},
    {"afip":"19537","location":"Santa Cruz"},
    {"afip":"19538","location":"Agua Salada"},
    {"afip":"19539","location":"Aguada"},
    {"afip":"19540","location":"Chilcas"},
    {"afip":"19541","location":"El Bañado"}
    ,
    {"afip":"19542","location":"El Carmen"},
    {"afip":"19543","location":"El Chorro"},
    {"afip":"19544","location":"El Lamedero"},
    {"afip":"19545","location":"El Molino"},
    {"afip":"19546","location":"El Mollar"},
    {"afip":"19547","location":"El Potrerillo"},
    {"afip":"19548","location":"Esquina"},
    {"afip":"19549","location":"La Cañada"}
    ,
    {"afip":"19550","location":"La Esperanza"},
    {"afip":"19551","location":"La Florida"},
    {"afip":"19552","location":"La Loma"},
    {"afip":"19553","location":"La Soledad"},
    {"afip":"19554","location":"La Trinidad"},
    {"afip":"19555","location":"Los Aguirre"},
    {"afip":"19556","location":"Pozo Hondo"},
    {"afip":"19557","location":"Retiro"},
    {"afip":"19558","location":"Ruta Nacional 157"},
    {"afip":"19559","location":"Ruta Provincial 334"},
    {"afip":"19560","location":"San Andres"},
    {"afip":"19561","location":"San Miguel"},
    {"afip":"19562","location":"Santa Rosa"},
    {"afip":"19563","location":"Zapallar"},
    {"afip":"19564","location":"El Churqui"},
    {"afip":"19565","location":"La Calera"},
    {"afip":"19566","location":"Ruta Nacional 38"},
    {"afip":"19567","location":"El Jardin"},
    {"afip":"19568","location":"El Porvenir"},
    {"afip":"19569","location":"El Rincon"},
    {"afip":"19570","location":"La Cañada"}
    ,
    {"afip":"19571","location":"Las Cejas"},
    {"afip":"19572","location":"Monte Grande"},
    {"afip":"19573","location":"Pozo Cavado"},
    {"afip":"19574","location":"Puesto Los Robles"},
    {"afip":"19575","location":"Ruta Nacional 38"},
    {"afip":"19576","location":"Ruta Provincial 334"},
    {"afip":"19577","location":"San Francisco"},
    {"afip":"19578","location":"San Ignacio"},
    {"afip":"19579","location":"Taco Rodeo"},
    {"afip":"19580","location":"Agua Azul"},
    {"afip":"19581","location":"Agua Blanca"},
    {"afip":"19582","location":"Agua Dulce"},
    {"afip":"19583","location":"Bajo Grande"},
    {"afip":"19584","location":"Cortaderas"},
    {"afip":"19585","location":"El Carmen"},
    {"afip":"19586","location":"El Castoral"},
    {"afip":"19587","location":"El Ceibal"},
    {"afip":"19588","location":"El Mojon"},
    {"afip":"19589","location":"El Naranjo"},
    {"afip":"19590","location":"El Suncho"},
    {"afip":"19591","location":"Entre Rios"},
    {"afip":"19592","location":"La Florida"},
    {"afip":"19593","location":"La Fronterita"},
    {"afip":"19594","location":"La Tuna"},
    {"afip":"19595","location":"Las Mercedes"},
    {"afip":"19596","location":"Los Agudos"},
    {"afip":"19597","location":"Los Diaz"},
    {"afip":"19598","location":"Los Villagra"},
    {"afip":"19599","location":"Pozo Del Alto"},
    {"afip":"19600","location":"Rosario Oeste"},
    {"afip":"19601","location":"Ruta Nacional 157"},
    {"afip":"19602","location":"Ruta Nacional 9"},
    {"afip":"19603","location":"Ruta Provincial 302"},
    {"afip":"19604","location":"Ruta Provincial 306"},
    {"afip":"19605","location":"San Antonio"},
    {"afip":"19606","location":"San Carlos"},
    {"afip":"19607","location":"San Ramon"},
    {"afip":"19608","location":"Sol De Mayo"},
    {"afip":"19609","location":"Tres Pozos"},
    {"afip":"19610","location":"Concepcion"},
    {"afip":"19611","location":"Cortaderas"},
    {"afip":"19612","location":"El Porvenir"},
    {"afip":"19613","location":"El Puesto"},
    {"afip":"19614","location":"La Calera"},
    {"afip":"19615","location":"La Esperanza"},
    {"afip":"19616","location":"San Antonio"},
    {"afip":"19617","location":"San Carlos"},
    {"afip":"19618","location":"San Jose"},
    {"afip":"19619","location":"San Ramon"},
    {"afip":"19620","location":"Santa Rosa"},
    {"afip":"19621","location":"El Cruce"},
    {"afip":"19622","location":"El Naranjito"},
    {"afip":"19623","location":"El Palomar"},
    {"afip":"19624","location":"La Florida"},
    {"afip":"19625","location":"La Tala"},
    {"afip":"19626","location":"Lujan"},
    {"afip":"19627","location":"Pacara"},
    {"afip":"19628","location":"Pacara Pintado"},
    {"afip":"19629","location":"San Jose"},
    {"afip":"19630","location":"San Miguel"},
    {"afip":"19631","location":"San Pedro"},
    {"afip":"19632","location":"Colonia Santa Rita"},
    {"afip":"19633","location":"El Ceibal"},
    {"afip":"19634","location":"El Cruce"},
    {"afip":"19635","location":"El Obraje"},
    {"afip":"19636","location":"La Banda"},
    {"afip":"19637","location":"Alto Verde"},
    {"afip":"19638","location":"El Paraiso"},
    {"afip":"19639","location":"El Puestito"},
    {"afip":"19640","location":"El Rodeo"},
    {"afip":"19641","location":"El Sauzal"},
    {"afip":"19686","location":"Alto Las Lechuzas"},
    {"afip":"19687","location":"Alto Verde"},
    {"afip":"19688","location":"Barrio Belgrano"},
    {"afip":"21677","location":"Medinas"},
    {"afip":"22223","location":"Los Gucheas"},
    {"afip":"22224","location":"Los Arroyos"},
    {"afip":"22225","location":"El Pueblo Viejo"},
    {"afip":"22226","location":"Alta Gracia"},
    {"afip":"22227","location":"Granja Modelo B. Rivadavia"},
    {"afip":"22228","location":"Los Aguirres"},
    {"afip":"22229","location":"El  Cortaderal"},
    {"afip":"22230","location":"Los Herrera"},
    {"afip":"22231","location":"Oran (Leales)"},
    {"afip":"22232","location":"Cañada De Alsogaray"}
    ,
    {"afip":"22233","location":"Luisiana"},
    {"afip":"22234","location":"El Jardin (Simoca)"},
    {"afip":"22235","location":"Gobernador Garmendia"},
    {"afip":"22236","location":"Siete De Abril"},
    {"afip":"22253","location":"Las Tusquitas"},
    {"afip":"22266","location":"El Suncho (Leales)"},
    {"afip":"22339","location":"Yerba Buena"},
    {"afip":"22340","location":"San Ramon"}
    ]