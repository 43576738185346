import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setIsOrderReturned } from 'src/app/redux/actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OrderValidator } from 'src/app/shared/order';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';
import { priceTags } from 'src/app/shared/const/prices.const';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';

@Component({
  selector: 'dialog-observation-price',
  templateUrl: './observation-price.html',
  styleUrls: ['./observation-price.scss']
})

export class DialogObservationPrice implements OnInit, OnDestroy {
  hour: string = '';
  title: string = 'Ingresá la observación para enviar al comercial.';
  price!: Price; //Store of redux;
  isMessage: boolean = false;
  message: string = '';
  count=0;
  user!:User;

  private optionsSubscription!:Subscription;
  private userSubscription!:Subscription;

  constructor(private store: Store<appState>, private date: DateChecker, 
    public dialogRef: MatDialogRef<DialogObservationPrice>, private priceV:PriceValidator) {}

  ngOnInit() {    
    this.hour = this.date.setHourFormat();
    this.setSubscriptions();
  }

  ngOnDestroy() {
    this.optionsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.store.dispatch(setIsOrderReturned({ isOrderReturned: false }));
  }

  setSubscriptions(){
    this.optionsSubscription=this.store.select('options').subscribe(optionsR=>{
      this.price=optionsR.priceToObservationMessage;

      if(this.price.observations.message!=' ' && this.count==0){
        this.message=this.price.observations.message;
        this.count++;
      }
    })

    this.userSubscription=this.store.select('usuario').subscribe(userR=>{
      this.user=userR;
    })
  }
  
  setMessage(event: any) {
    this.message = event.target.value;
  }

  sendObservation(){
    const fieldIsMessage=priceTags.observations+'.'+priceTags.isMessage;
    const fieldMessage=priceTags.observations+'.'+priceTags.message;
    const newMessage=this.message.trim();
    if(newMessage.length>2){
      this.priceV.editPrice(this.price, fieldMessage,newMessage,this.user);
      this.priceV.editPrice(this.price, fieldIsMessage, 1,this.user);
    }

    this.count=0;
    this.dialogRef.close();

  }
}