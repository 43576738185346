<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Generar contrapartida"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Generar contrapartida</h2>
    <!-- CONTENT OF THE EXCGANGE BOARD -->
    <app-body-generate-exchange></app-body-generate-exchange>

    <app-menu class="menu"></app-menu>
    <!--<app-menu-footer></app-menu-footer> -->
</div>