<div class="field" #fieldName>
    <span class="field-title" *ngIf="fieldIdentificator !='exchange-buyer'">Vendedor<span class="asterisk">*</span></span>
    <span class="field-title" *ngIf="fieldIdentificator =='exchange-buyer'">Comprador<span class="asterisk">*</span></span>
    <div>
        <div class="form-field" [ngClass]="{'border-green': isOptionSelected,'is-incomplete': isIncomplete || messageErrorSeller}"
        (click)="changeShowOptions()">
            <span class="title-field {{identifyClassName}}"> 

                <span class="autocomplete"> 
                    <span class="first-part">{{search}}</span> 
                    <span class="second-part">{{placeHolder}}</span>
                </span>
                
                <input  #inputName (focus)="focusField(fieldName);" 
                    (blur)="unfocusField()" value="{{elementSelected}}" [(ngModel)]="search" placeholder="{{initalElementSelected}}" class="{{identifyClassName}} no-background-border"/>
                <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                <img alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}" (mouseup)="focusInInput(inputName)"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>
        <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>
        <div class="options" *ngIf="showOptions" >
            <span *ngFor="let element of array | filterByObject: search:OBJECT: 'codeS'; index as i"  
            [ngClass]="{'selected': element==array[indexOption]}"
                (click)="setElement(element, i)">
                {{OBJECT[element].nombre}} - {{element}}
            </span>
        </div>
    </div>

    <span class="exchange" *ngIf="isExchange && fieldIdentificator!='exchange'">El vendedor es un canjeador</span>
    <span class="text-is-incomplete" *ngIf="messageErrorSeller">{{messageErrorSeller}}</span>
</div>

<app-syngenta-subdivision [fieldIdentificator]="fieldIdentificator" *ngIf="isSyngenta"></app-syngenta-subdivision>
<app-sede [fieldIdentificator]="fieldIdentificator" *ngIf="isSancor"></app-sede>
<app-nro-billing [fieldIdentificator]="fieldIdentificator" *ngIf="isCuitNutrien"></app-nro-billing>