import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NAVEGATION } from 'src/app/shared/const/navegation';
const VIEW_MORE_TO_DESKTOP=1367;

@Component({
  selector: 'app-commodities',
  templateUrl: './commodities.component.html',
  styleUrls: ['./commodities.component.scss']
})
export class CommoditiesComponent {
  sellers:any;
  constructor(private router:Router) {}
  
  goToPriceBoard(){
    this.router.navigate(['/'+NAVEGATION.PRICE_BOARD]);
  }

}
