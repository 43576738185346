<div class="body">

    <div class="header">
        <span class="add-buyer title">Particularidad de calidad</span>
        <cross-svg class="cross" (click)="close()"></cross-svg>
    </div>

    <p class="description">Seleccioná las particularidad necesaria</p>

    <app-quality-ibm [fieldIdentificator]="fieldIdentificator" class="width-100"></app-quality-ibm>

    <!--<p class="description">Seleccioná las particularidades necesarias</p>-->

    <!--<div class="field">
        <span class="field-title">Particularidad de calidad</span>
    
        <div>
            <div class="form-field" [ngClass]="{'border-green': isOptionSelected}"
                (click)="changeShowOptions()">
                <span class="title-field  {{identifyClassName}}"> 
                    <input #inputName (focus)="focusField()" 
                    (blur)="unfocusField()"
                    value="{{elementSelected}}" placeholder="{{initalElementSelected}}" 
                    class="{{identifyClassName}} no-background-border" />
                    <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}"
                        src="../../../../../../assets/images/extra/arrow-down-green.svg" />
                </span>
            </div>
        </div>
    </div>

     <div class="options-selected">
        <div class="option-selected" *ngFor="let option of options">{{option}}</div>
    </div>
    
    <div [ngClass]="{'selected':selectedIndex}"></div>

    <div class="options-particularities" [ngClass]="{'selected-one': selectedIndex}" *ngIf="showOptions">
        <app-green class="option-p first" [ngClass]="{'no-selected': 1!=selectedIndex}"  (selected)="selected($event)"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(1)"></app-green>
        
        <app-damage class="option-p" [ngClass]="{'no-selected': 2!=selectedIndex}"  (selected)="selected($event)"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(2)"></app-damage>
        
        <app-humidity class="option-p" [ngClass]="{'no-selected': 3!=selectedIndex}"  (selected)="selected($event)"
         [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)"*ngIf="isQualityInArray(3)"></app-humidity>
        
        <app-volatile-waste class="option-p" [ngClass]="{'no-selected': 4!=selectedIndex}"  (selected)="selected($event)"
         [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(4)"></app-volatile-waste>
        
        <app-product-variety class="option-p" [ngClass]="{'no-selected': 5!=selectedIndex}" (selected)="selected($event)" 
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(5)"></app-product-variety>
        
        <app-reject-grade3 class="option-p" [ngClass]="{'no-selected': 6!=selectedIndex}"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(6)"></app-reject-grade3>
        
        <app-odor-free class="option-p" [ngClass]="{'no-selected': 7!=selectedIndex}"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(7)"></app-odor-free>
        
        <app-toxins-free class="option-p" [ngClass]="{'no-selected': 8!=selectedIndex}" (selected)="selected($event)" 
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(8)"></app-toxins-free>
        
        <app-hectoliter-weight class="option-p" [ngClass]="{'no-selected': 9!=selectedIndex}" (selected)="selected($event)"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(9)"></app-hectoliter-weight>
        
        <app-protein-content class="option-p" [ngClass]="{'no-selected': 10!=selectedIndex}" (selected)="selected($event)"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(10)"></app-protein-content>
        
        <app-article-12 class="option-p" [ngClass]="{'no-selected': 11!=selectedIndex}" (selected)="selected($event)"
        [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(11)"></app-article-12>
        
        <app-ph class="option-p" [ngClass]="{'no-selected': 12!=selectedIndex}" (selected)="selected($event)" [qualityParticularitiesStruct]="getQualityParticularities()" (save)="saveElement($event)" *ngIf="isQualityInArray(12)"></app-ph>
    </div>-->

    <div class="bottom-fixed">
        <button class="filled-button" (click)="close()"><span>Aplicar</span></button>
    </div>
</div>
