import { Pipe, PipeTransform } from '@angular/core';
import { getMonthStringAbbreviated } from '../date.checker';
import { RangeDate } from '../interfaces/price.interface';

@Pipe({
  name: 'periodPipe'
})

export class PeriodPipe implements PipeTransform {

  /*Given an delivery period.
It is formatted and returned differently for a mobile view.*/
  transform(period: RangeDate, format: string) {
    let dateToReturn='';
    try{
      const startDateSplit=period.startDate.split('-');
      const endDateSplit=period.endDate.split('-');
      if(endDateSplit[1]==startDateSplit[1]){
        dateToReturn+= getMonthStringAbbreviated(Number(endDateSplit[1])) + ' '+endDateSplit[2];
      } else {
        //Tomamos el día[0] y el mes[1] de la fecha de inicio
        //Separamos con '/' 
        //Agregamos el día y el mes de la fecha de fin
        
        dateToReturn+=startDateSplit[0]+'-'+startDateSplit[1]+'/'+endDateSplit[0]+'-'+endDateSplit[1]; 
      }
    } catch(err){
      console.error("El periodo recibido no es válido");
      dateToReturn="Error";
    }
    return dateToReturn;
  }
}
