import { createAction, props } from "@ngrx/store";
import { MarketPrice, FutureMarket, Dashboard, Objetive, AccumulatedTonage } from "src/app/shared/interfaces/dashboard.interface";
import { Price } from "src/app/shared/interfaces/price.interface";

//RememberDashboard
export const rememberDashboardData = createAction('[DASHBOARD] Remember Data',
    props<{ state: any }>());

//Orders
export const loadOrders = createAction('[DASHBOARD] Load Orders',
    props<{ email: String, status: number }>());
export const loadAllOrdersSuccess = createAction('[DASHBOARD] Load Orders All Success',
    props<{ orderList: any }>());
export const loadOrdersFailed = createAction('[DASHBOARD] Load Orders Failed');

//Featured Prices
export const loadFeaturedPrices = createAction('[DASHBOARD] Load Featured Prices');
export const loadFeaturedPricesSuccess = createAction('[DASHBOARD] Load Featured Prices Success',
    props<{ featuredPrices: Price[] }>());
export const loadFeaturedPricesFailed = createAction('[DASHBOARD] Load Featured Prices Failed');

//Market Prices
export const loadMarketPrices = createAction('[DASHBOARD] Load Market Prices',
    props<{ productName: String, arrayPosition: number }>());
export const loadAllMarketPricesSuccess = createAction('[DASHBOARD] Load All Market Prices Success',
    props<{ marketPrices: any }>());
export const loadSojaMarketPrice = createAction('[DASHBOARD] Load Soja Market Prices Success',
    props<{ prices: Price[] }>());
export const loadTrigoMarketPrice = createAction('[DASHBOARD] Load Trigo Market Prices Success',
    props<{ prices: Price[] }>());
export const loadMaizMarketPrice = createAction('[DASHBOARD] Load Maiz Market Prices Success',
    props<{ prices: Price[] }>());

export const loadMarketPricesSuccess = createAction('[DASHBOARD] Load Market Prices Success',
    props<{ marketPrices: MarketPrice }>());
export const loadMarketPricesFailed = createAction('[DASHBOARD] Load Market Prices Failed');

//Future Market Prices
export const loadFutureMarkets = createAction('[DASHBOARD] Load Future Markets',
props<{ simbol:string }>());
export const loadFutureMarketsSuccess = createAction('[DASHBOARD] Load Future Markets Success',
    props<{ futureMarkets: FutureMarket[] }>());
export const loadFutureMarketsFailed = createAction('[DASHBOARD] Load Future Markets Failed');
export const setMarket = createAction('[DASHBOARD] Set Market',
props<{ market:string }>());
export const setProduct = createAction('[DASHBOARD] Set Product',
props<{ product:string }>());
export const setDate = createAction('[DASHBOARD] Set Date',
props<{ date:string }>());


//Objetives
export const setObjetive= createAction('[OBJETIVE] Set Objetives',
props<{ objetives: Objetive }>());
export const getObjetive= createAction('[OBJETIVE] Get Objetives');


//Accumulated Tonnage
export const setAccumulatedTonnage = createAction('[DASHBOARD] Set Accumulated Tonnage',
props<{ productName: number, harvest: string, wave:number }>());

export const setAccumulatedTonnageSuccess = createAction('[DASHBOARD] Set Accumulated Tonnage Success',
props<{ accumulatedTonnage: AccumulatedTonage[], date:Date }>());

export const setAccumulatedTonnageFailed = createAction('[DASHBOARD] Set Accumulated Tonnage Failed');
props<{ accumulatedTonnage: any }>();


//Dolar
export const loadDolarPrice = createAction('[DASHBOARD] Load Dolar Price',
props<{ date: string }>());
export const loadDolarPriceSuccess = createAction('[DASHBOARD] Load Dolar Price Success',
    props<{ dolarPrice: any }>());
export const loadDolarPriceFailed = createAction('[DASHBOARD] Load Dolar Price Failed');
