import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyProfileComponent } from './body-profile.component';
import { DataProfileModule } from 'src/app/components/data-profile/data-profile.module';
import { SharedSvgModule } from 'src/app/shared/styles/svg/shared/shared-svg.module';
import { ActionsControlPanelModule } from 'src/app/shared/styles/svg/actions-control-panel/actions-control-panel.module';

@NgModule({
  declarations: [
    BodyProfileComponent
  ],
  imports: [
    CommonModule,
    DataProfileModule,
    SharedSvgModule,
    ActionsControlPanelModule, //De aqui sacamos el icon edit
  ], exports: [
    BodyProfileComponent
  ]
})
export class BodyProfileModule { }
