export const sanLuisLocalities=[
    {"afip":"23","location":"3 Esquinas"},
    {"afip":"26","location":"6 De Septiembre"},
    {"afip":"70","location":"Acasape"},
    {"afip":"102","location":"Adolfo Rodriguez Saa"},
    {"afip":"147","location":"Agua Del Portezuelo"},
    {"afip":"153","location":"Agua Fria"},
    {"afip":"156","location":"Agua Linda"},
    {"afip":"166","location":"Agua Seballe"},
    {"afip":"209","location":"Aguas De Piedras"},
    {"afip":"228","location":"Ahi Veremos"},
    {"afip":"239","location":"Alanices"},
    {"afip":"243","location":"Alazanas"},
    {"afip":"316","location":"Alfaland"},
    {"afip":"332","location":"Algarrobitos"},
    {"afip":"340","location":"Algarrobos Grandes"},
    {"afip":"387","location":"Altillo"},
    {"afip":"389","location":"Alto"},
    {"afip":"394","location":"Alto Blanco"},
    {"afip":"408","location":"Alto De La Leña"}
    ,
    {"afip":"429","location":"Alto Del Leon"},
    {"afip":"431","location":"Alto Del Molle"},
    {"afip":"439","location":"Alto Del Valle"},
    {"afip":"449","location":"Alto Lindo"},
    {"afip":"452","location":"Alto Negro"},
    {"afip":"454","location":"Alto Pelado"},
    {"afip":"455","location":"Alto Pencoso"},
    {"afip":"533","location":"Ancamilla"},
    {"afip":"541","location":"Anchorena"},
    {"afip":"575","location":"Angelita"},
    {"afip":"603","location":"Antihuasi"},
    {"afip":"656","location":"Arbol Verde"},
    {"afip":"673","location":"Arenilla"},
    {"afip":"689","location":"Arizona"},
    {"afip":"763","location":"Arroyo De Vilches"},
    {"afip":"788","location":"Arroyo La Cal"},
    {"afip":"944","location":"Aviador Origone"},
    {"afip":"978","location":"Bagual"},
    {"afip":"1022","location":"Bajo De Conlara"},
    {"afip":"1026","location":"Bajo De La Cruz"},
    {"afip":"1046","location":"Bajo La Laguna"},
    {"afip":"1053","location":"Bajos Hondos"},
    {"afip":"1059","location":"Balda"},
    {"afip":"1061","location":"Balde Ahumada"},
    {"afip":"1062","location":"Balde De Amira"},
    {"afip":"1063","location":"Balde De Arriba"},
    {"afip":"1064","location":"Balde De Azcurra"},
    {"afip":"1065","location":"Balde De Escudero"},
    {"afip":"1066","location":"Balde De Garcia"},
    {"afip":"1067","location":"Balde De Guardia"},
    {"afip":"1068","location":"Balde De Guiñazu"}
    ,
    {"afip":"1069","location":"Balde De La Isla"},
    {"afip":"1071","location":"Balde De La Linea"},
    {"afip":"1074","location":"Balde De Ledesma"},
    {"afip":"1077","location":"Balde De Monte"},
    {"afip":"1078","location":"Balde De Nuevo"},
    {"afip":"1080","location":"Balde De Puertas"},
    {"afip":"1081","location":"Balde De Quines"},
    {"afip":"1082","location":"Balde De Torres"},
    {"afip":"1083","location":"Balde Del Escudero"},
    {"afip":"1089","location":"Balde El Carril"},
    {"afip":"1091","location":"Balde Hondo"},
    {"afip":"1102","location":"Balde Retamo"},
    {"afip":"1107","location":"Balde Viejo"},
    {"afip":"1110","location":"Baldecito La Pampa"},
    {"afip":"1161","location":"Banda Sud"},
    {"afip":"1210","location":"Barriales"},
    {"afip":"1231","location":"Barrio Blanco"},
    {"afip":"1342","location":"Barzola"},
    {"afip":"1364","location":"Batavia"},
    {"afip":"1385","location":"Bañadito"}
    ,
    {"afip":"1386","location":"Bañadito Viejo"}
    ,
    {"afip":"1387","location":"Bañado"}
    ,
    {"afip":"1388","location":"Bañado De Cautana"}
    ,
    {"afip":"1396","location":"Bañado Lindo"}
    ,
    {"afip":"1414","location":"Baños Zapallar"}
    ,
    {"afip":"1416","location":"Beazley"},
    {"afip":"1417","location":"Bebedero"},
    {"afip":"1432","location":"Bella Estancia"},
    {"afip":"1482","location":"Billiken"},
    {"afip":"1554","location":"Botijas"},
    {"afip":"1644","location":"Cabeza De Novillo"},
    {"afip":"1674","location":"Cachi Corral"},
    {"afip":"1707","location":"Cain De Los Tigres"},
    {"afip":"1735","location":"Caldenadas"},
    {"afip":"1742","location":"Calera Argentina"},
    {"afip":"1748","location":"Caleras Cañada Grande"}
    ,
    {"afip":"1825","location":"Campanario"},
    {"afip":"1889","location":"Campo De San Pedro"},
    {"afip":"2070","location":"Cantantal"},
    {"afip":"2091","location":"Canteras Santa Isabel"},
    {"afip":"2099","location":"Capelen"},
    {"afip":"2199","location":"Carmelo"},
    {"afip":"2252","location":"Casa De Condor"},
    {"afip":"2297","location":"Casimiro Gomez"},
    {"afip":"2359","location":"Caña Larga"}
    ,
    {"afip":"2365","location":"Cañada Angosta"}
    ,
    {"afip":"2367","location":"Cañada Blanca"}
    ,
    {"afip":"2373","location":"Cañada De Atras"}
    ,
    {"afip":"2382","location":"Cañada De La Negra"}
    ,
    {"afip":"2386","location":"Cañada De Las Lagunas"}
    ,
    {"afip":"2390","location":"Cañada De Los Tigres"}
    ,
    {"afip":"2401","location":"Cañada De Vilan"}
    ,
    {"afip":"2405","location":"Cañada Del Pasto"}
    ,
    {"afip":"2408","location":"Cañada Del Puestito"}
    ,
    {"afip":"2418","location":"Cañada Honda De Guzman"}
    ,
    {"afip":"2419","location":"Cañada La Negra"}
    ,
    {"afip":"2420","location":"Cañada La Tienda"}
    ,
    {"afip":"2427","location":"Cañada Quemada"}
    ,
    {"afip":"2476","location":"Cañitas"}
    ,
    {"afip":"2523","location":"Cerrito Blanco"},
    {"afip":"2525","location":"Cerrito Negro"},
    {"afip":"2587","location":"Cerro De La Pila"},
    {"afip":"2599","location":"Cerro De Oro"},
    {"afip":"2600","location":"Cerro De Piedra"},
    {"afip":"2743","location":"Cerro Varela"},
    {"afip":"2744","location":"Cerro Verde"},
    {"afip":"2745","location":"Cerro Viejo"},
    {"afip":"2752","location":"Cerros Largos"},
    {"afip":"2770","location":"Chacaritas"},
    {"afip":"2792","location":"Chacra La Primavera"},
    {"afip":"2802","location":"Chacras Del Cantaro"},
    {"afip":"2821","location":"Chalanta"},
    {"afip":"2831","location":"Chancarita"},
    {"afip":"2858","location":"Charlone"},
    {"afip":"2859","location":"Charlones"},
    {"afip":"2881","location":"Chañar De La Legua"}
    ,
    {"afip":"2959","location":"Chimborazo"},
    {"afip":"2967","location":"Chipical"},
    {"afip":"2978","location":"Chischaca"},
    {"afip":"2979","location":"Chischaquita"},
    {"afip":"3002","location":"Chosmes"},
    {"afip":"3029","location":"Chutusa"},
    {"afip":"3062","location":"Ciudad Jardin De San Luis"},
    {"afip":"3099","location":"Cochenelos"},
    {"afip":"3100","location":"Cochequingan"},
    {"afip":"3278","location":"Colonia Calzada"},
    {"afip":"3371","location":"Colonia El Campamento"},
    {"afip":"3557","location":"Colonia La Florida"},
    {"afip":"3888","location":"Colonia Santa Virginia"},
    {"afip":"3988","location":"Comandante Granville"},
    {"afip":"4005","location":"Concaran"},
    {"afip":"4031","location":"Consuelo"},
    {"afip":"4032","location":"Consulta"},
    {"afip":"4071","location":"Coronel Alzogaray"},
    {"afip":"4117","location":"Coronel Segovia"},
    {"afip":"4139","location":"Corral De Torres"},
    {"afip":"4143","location":"Corral Del Tala"},
    {"afip":"4147","location":"Corrales"},
    {"afip":"4233","location":"Cramer"},
    {"afip":"4258","location":"Cruz Brillante"},
    {"afip":"4330","location":"Cueva De Tigre"},
    {"afip":"4381","location":"Daniel Donovan"},
    {"afip":"4457","location":"Dique La Florida"},
    {"afip":"4515","location":"Dominguez"},
    {"afip":"4537","location":"Dormida"},
    {"afip":"4606","location":"El  Valle"},
    {"afip":"4647","location":"El Amparo"},
    {"afip":"4702","location":"El Blanco"},
    {"afip":"4727","location":"El Burrito"},
    {"afip":"4736","location":"El Cadillo"},
    {"afip":"4763","location":"El Cardal"},
    {"afip":"4787","location":"El Cazador"},
    {"afip":"4825","location":"El Charabon"},
    {"afip":"4826","location":"El Charco"},
    {"afip":"4846","location":"El Chorrillo"},
    {"afip":"4926","location":"El Dichoso"},
    {"afip":"5007","location":"El Hormiguero"},
    {"afip":"5008","location":"El Hornito"},
    {"afip":"5020","location":"El Injerto"},
    {"afip":"5028","location":"El Jarillal"},
    {"afip":"5064","location":"El Manantial Escondido"},
    {"afip":"5076","location":"El Mataco"},
    {"afip":"5100","location":"El Mollarcito"},
    {"afip":"5117","location":"El Nasao"},
    {"afip":"5119","location":"El Negro"},
    {"afip":"5145","location":"El Olmo"},
    {"afip":"5158","location":"El Pajarete"},
    {"afip":"5179","location":"El Paraguay"},
    {"afip":"5186","location":"El Pasajero"},
    {"afip":"5197","location":"El Payero"},
    {"afip":"5199","location":"El Pedernal"},
    {"afip":"5201","location":"El Peje"},
    {"afip":"5221","location":"El Piche"},
    {"afip":"5224","location":"El Pigue"},
    {"afip":"5231","location":"El Pimpollo"},
    {"afip":"5248","location":"El Pocito"},
    {"afip":"5251","location":"El Poleo"},
    {"afip":"5272","location":"El Potrero De Leyes"},
    {"afip":"5302","location":"El Quingual"},
    {"afip":"5316","location":"El Recuerdo"},
    {"afip":"5331","location":"El Riecito"},
    {"afip":"5346","location":"El Salado De Amaya"},
    {"afip":"5357","location":"El Sarco"},
    {"afip":"5365","location":"El Sembrado"},
    {"afip":"5417","location":"El Tembleque"},
    {"afip":"5430","location":"El Torcido"},
    {"afip":"5433","location":"El Toro Muerto"},
    {"afip":"5502","location":"El Yacatan"},
    {"afip":"5527","location":"Eleodoro Lobos"},
    {"afip":"5538","location":"Embalse La Florida"},
    {"afip":"5666","location":"Establecimiento Las Flores"},
    {"afip":"5736","location":"Estacion Zanjitas"},
    {"afip":"5741","location":"Estancia 30 De Octubre"},
    {"afip":"5782","location":"Estancia Don Arturo"},
    {"afip":"5792","location":"Estancia El Chamico"},
    {"afip":"5797","location":"Estancia El Divisadero"},
    {"afip":"5800","location":"Estancia El Medano"},
    {"afip":"5807","location":"Estancia El Quebrachal"},
    {"afip":"5811","location":"Estancia El Salado"},
    {"afip":"5813","location":"Estancia El Saucecito"},
    {"afip":"5835","location":"Estancia La Blanca"},
    {"afip":"5851","location":"Estancia La Guardia"},
    {"afip":"5852","location":"Estancia La Guillermina"},
    {"afip":"5861","location":"Estancia La Morena"},
    {"afip":"5872","location":"Estancia La Reserva"},
    {"afip":"5877","location":"Estancia La Union"},
    {"afip":"5881","location":"Estancia La Zulemita"},
    {"afip":"5883","location":"Estancia Las Bebidas"},
    {"afip":"5908","location":"Estancia Los Hermanos"},
    {"afip":"5913","location":"Estancia Los Nogales"},
    {"afip":"5942","location":"Estancia Rivadavia"},
    {"afip":"5948","location":"Estancia San Alberto"},
    {"afip":"5951","location":"Estancia San Francisco"},
    {"afip":"5978","location":"Estancia Tres Arboles"},
    {"afip":"6080","location":"Favelli"},
    {"afip":"6091","location":"Fenoglio"},
    {"afip":"6201","location":"Fortin El Patria"},
    {"afip":"6221","location":"Fortin Salto"},
    {"afip":"6232","location":"Fortuna De San Juan"},
    {"afip":"6236","location":"Fraga"},
    {"afip":"6268","location":"Frisia"},
    {"afip":"6400","location":"General Pedernera"},
    {"afip":"6415","location":"General Urquiza"},
    {"afip":"6432","location":"Gigante"},
    {"afip":"6444","location":"Gloria A Dios"},
    {"afip":"6503","location":"Gorgonta"},
    {"afip":"6547","location":"Gruta De Intihuasi"},
    {"afip":"6573","location":"Gualtaran"},
    {"afip":"6581","location":"Guanaco Pampa"},
    {"afip":"6602","location":"Guasquita"},
    {"afip":"6646","location":"Guzman"},
    {"afip":"6716","location":"Hinojito"},
    {"afip":"6718","location":"Hinojos"},
    {"afip":"6743","location":"Hornito"},
    {"afip":"6786","location":"Hualtaran"},
    {"afip":"6813","location":"Huchisson"},
    {"afip":"6819","location":"Huejeda"},
    {"afip":"6824","location":"Huertas"},
    {"afip":"6978","location":"Intihuasi"},
    {"afip":"7010","location":"Isla"},
    {"afip":"7073","location":"Islitas"},
    {"afip":"7130","location":"Jarilla"},
    {"afip":"7202","location":"Juan Jorba"},
    {"afip":"7207","location":"Juan Llerena"},
    {"afip":"7219","location":"Juan W Gez"},
    {"afip":"7222","location":"Juana Koslay"},
    {"afip":"7225","location":"Juante"},
    {"afip":"7266","location":"Justo Daract"},
    {"afip":"7293","location":"La Agua Nueva"},
    {"afip":"7295","location":"La Aguada De Las Animas"},
    {"afip":"7298","location":"La Agueda"},
    {"afip":"7303","location":"La Alcorteña"}
    ,
    {"afip":"7304","location":"La Alegria"},
    {"afip":"7312","location":"La Amarga"},
    {"afip":"7332","location":"La Aroma"},
    {"afip":"7363","location":"La Bava"},
    {"afip":"7364","location":"La Bavaria"},
    {"afip":"7371","location":"La Bertita"},
    {"afip":"7379","location":"La Bolivia"},
    {"afip":"7383","location":"La Bonita"},
    {"afip":"7397","location":"La Cabra"},
    {"afip":"7400","location":"La Calaguala"},
    {"afip":"7403","location":"La Caldera"},
    {"afip":"7425","location":"La Carmencita"},
    {"afip":"7468","location":"La Chañarienta"}
    ,
    {"afip":"7470","location":"La Cherindu"},
    {"afip":"7478","location":"La Chilla"},
    {"afip":"7538","location":"La Cristina"},
    {"afip":"7539","location":"La Crucecita"},
    {"afip":"7565","location":"La Donostia"},
    {"afip":"7571","location":"La Duda"},
    {"afip":"7579","location":"La Elida"},
    {"afip":"7581","location":"La Elisa"},
    {"afip":"7588","location":"La Emma"},
    {"afip":"7589","location":"La Empajada"},
    {"afip":"7600","location":"La Ernestina"},
    {"afip":"7612","location":"La Espesura"},
    {"afip":"7614","location":"La Esquina Del Rio"},
    {"afip":"7628","location":"La Ethel"},
    {"afip":"7644","location":"La Felisa"},
    {"afip":"7646","location":"La Finca"},
    {"afip":"7656","location":"La Fragua"},
    {"afip":"7667","location":"La Gama"},
    {"afip":"7671","location":"La Garrapata"},
    {"afip":"7672","location":"La Garza"},
    {"afip":"7678","location":"La Gitana"},
    {"afip":"7702","location":"La Hermosura"},
    {"afip":"7711","location":"La Hortensia"},
    {"afip":"7716","location":"La Huertita"},
    {"afip":"7719","location":"La Iberia"},
    {"afip":"7730","location":"La Irene"},
    {"afip":"7744","location":"La Javiera"},
    {"afip":"7745","location":"La Jerga"},
    {"afip":"7746","location":"La Josefa"},
    {"afip":"7749","location":"La Juana"},
    {"afip":"7754","location":"La Justa"},
    {"afip":"7762","location":"La Laura"},
    {"afip":"7765","location":"La Lechuga"},
    {"afip":"7777","location":"La Linda"},
    {"afip":"7819","location":"La Margarita Carlota"},
    {"afip":"7823","location":"La Maria Esther"},
    {"afip":"7841","location":"La Media Legua"},
    {"afip":"7843","location":"La Medula"},
    {"afip":"7845","location":"La Melina"},
    {"afip":"7855","location":"La Mina"},
    {"afip":"7947","location":"La Petra"},
    {"afip":"7983","location":"La Portada"},
    {"afip":"8032","location":"La Realidad"},
    {"afip":"8044","location":"La Represita"},
    {"afip":"8049","location":"La Resistencia"},
    {"afip":"8056","location":"La Riojita"},
    {"afip":"8066","location":"La Rosina"},
    {"afip":"8074","location":"La Sala"},
    {"afip":"8079","location":"La Salud"},
    {"afip":"8080","location":"La Salvadora"},
    {"afip":"8082","location":"La Sandia"},
    {"afip":"8093","location":"La Serrana"},
    {"afip":"8100","location":"La Silesia"},
    {"afip":"8143","location":"La Tranca"},
    {"afip":"8150","location":"La Tula"},
    {"afip":"8153","location":"La Tusca"},
    {"afip":"8155","location":"La Ulbara"},
    {"afip":"8159","location":"La Uruguaya"},
    {"afip":"8161","location":"La Vaca"},
    {"afip":"8171","location":"La Venecia"},
    {"afip":"8197","location":"La Yerba Buena"},
    {"afip":"8219","location":"Lafinur"},
    {"afip":"8256","location":"Laguna Capelen"},
    {"afip":"8263","location":"Laguna De La Cañada"}
    ,
    {"afip":"8267","location":"Laguna De Los Patos"},
    {"afip":"8268","location":"Laguna De Patos"},
    {"afip":"8304","location":"Laguna Sayape"},
    {"afip":"8364","location":"Las Aguadas"},
    {"afip":"8390","location":"Las Barranquitas"},
    {"afip":"8413","location":"Las Caritas"},
    {"afip":"8414","location":"Las Carolinas"},
    {"afip":"8417","location":"Las Carretas"},
    {"afip":"8433","location":"Las Chacras De San Martin"},
    {"afip":"8444","location":"Las Claritas"},
    {"afip":"8489","location":"Las Galeras"},
    {"afip":"8499","location":"Las Gitanas"},
    {"afip":"8522","location":"Las Islitas"},
    {"afip":"8547","location":"Las Mangas"},
    {"afip":"8556","location":"Las Meladas"},
    {"afip":"8561","location":"Las Mesias"},
    {"afip":"8563","location":"Las Mestizas"},
    {"afip":"8623","location":"Las Playas Argentinas"},
    {"afip":"8627","location":"Las Praderas"},
    {"afip":"8635","location":"Las Raices"},
    {"afip":"8641","location":"Las Rosadas"},
    {"afip":"8665","location":"Las Tigras"},
    {"afip":"8679","location":"Las Tres Cañadas"}
    ,
    {"afip":"8722","location":"Laura Elisa"},
    {"afip":"8730","location":"Lavaisse"},
    {"afip":"8783","location":"Liborio Luna"},
    {"afip":"8801","location":"Lince"},
    {"afip":"8806","location":"Lindo"},
    {"afip":"8928","location":"Longari"},
    {"afip":"8943","location":"Los Aguados"},
    {"afip":"8960","location":"Los Almacigos"},
    {"afip":"8972","location":"Los Araditos"},
    {"afip":"8976","location":"Los Arces"},
    {"afip":"8978","location":"Los Arguellos"},
    {"afip":"9023","location":"Los Carricitos"},
    {"afip":"9040","location":"Los Cesares"},
    {"afip":"9046","location":"Los Chancaros"},
    {"afip":"9052","location":"Los Chenas"},
    {"afip":"9071","location":"Los Comederos"},
    {"afip":"9072","location":"Los Comedores"},
    {"afip":"9083","location":"Los Coros"},
    {"afip":"9092","location":"Los Cuadros"},
    {"afip":"9105","location":"Los Dueros"},
    {"afip":"9106","location":"Los Duraznitos"},
    {"afip":"9112","location":"Los Espinillos"},
    {"afip":"9113","location":"Los Esquineros"},
    {"afip":"9153","location":"Los Hinojos"},
    {"afip":"9173","location":"Los Lechuzones"},
    {"afip":"9178","location":"Los Lobos"},
    {"afip":"9188","location":"Los Medanitos"},
    {"afip":"9193","location":"Los Menbrillos"},
    {"afip":"9194","location":"Los Mendocinos"},
    {"afip":"9208","location":"Los Mollecitos"},
    {"afip":"9211","location":"Los Montes"},
    {"afip":"9236","location":"Los Oscuros"},
    {"afip":"9250","location":"Los Pasitos"},
    {"afip":"9262","location":"Los Peros"},
    {"afip":"9274","location":"Los Poleos"},
    {"afip":"9287","location":"Los Puquios"},
    {"afip":"9316","location":"Los Roldanes"},
    {"afip":"9349","location":"Los Tamariños"}
    ,
    {"afip":"9351","location":"Los Tapiales"},
    {"afip":"9356","location":"Los Telarios"},
    {"afip":"9383","location":"Los Valles"},
    {"afip":"9470","location":"Machao"},
    {"afip":"9563","location":"Manantial"},
    {"afip":"9564","location":"Manantial Blanco"},
    {"afip":"9565","location":"Manantial De Flores"},
    {"afip":"9567","location":"Manantial De Renca"},
    {"afip":"9569","location":"Manantial Lindo"},
    {"afip":"9594","location":"Mantilla"},
    {"afip":"9632","location":"Maray"},
    {"afip":"9692","location":"Marlito"},
    {"afip":"9693","location":"Marmol Verde"},
    {"afip":"9699","location":"Martin De Loyola"},
    {"afip":"9723","location":"Mataco"},
    {"afip":"9785","location":"Medano Ballo"},
    {"afip":"9787","location":"Medano Chico"},
    {"afip":"9790","location":"Medano Grande"},
    {"afip":"9896","location":"Mina Los Condores"},
    {"afip":"9907","location":"Mina Santo Domingo"},
    {"afip":"10044","location":"Monte Chiquito"},
    {"afip":"10046","location":"Monte Cochequingan"},
    {"afip":"10130","location":"Moyar"},
    {"afip":"10131","location":"Moyarcito"},
    {"afip":"10163","location":"Nahuel Mapa"},
    {"afip":"10185","location":"Naranjo"},
    {"afip":"10191","location":"Naschel"},
    {"afip":"10197","location":"Navia"},
    {"afip":"10231","location":"Nilinast"},
    {"afip":"10246","location":"No Es Mia"},
    {"afip":"10254","location":"Nogoli"},
    {"afip":"10263","location":"Nossar"},
    {"afip":"10281","location":"Nueva Constitucion"},
    {"afip":"10287","location":"Nueva Galia"},
    {"afip":"10352","location":"Ojo Del Rio"},
    {"afip":"10384","location":"Once De Mayo"},
    {"afip":"10467","location":"Paines"},
    {"afip":"10477","location":"Paje"},
    {"afip":"10494","location":"Paliguanta"},
    {"afip":"10572","location":"Pampa De Los Gobernadores"},
    {"afip":"10577","location":"Pampa Del Bajo"},
    {"afip":"10587","location":"Pampa Del Tamborero"},
    {"afip":"10605","location":"Pampa Invernada"},
    {"afip":"10648","location":"Pampita"},
    {"afip":"10660","location":"Pantanillo"},
    {"afip":"10661","location":"Pantanillos"},
    {"afip":"10794","location":"Paso De Cuero"},
    {"afip":"10803","location":"Paso De La Tierra"},
    {"afip":"10809","location":"Paso De Las Salinas"},
    {"afip":"10810","location":"Paso De Las Sierras"},
    {"afip":"10811","location":"Paso De Las Toscas"},
    {"afip":"10812","location":"Paso De Las Vacas"},
    {"afip":"10815","location":"Paso De Los Bayos"},
    {"afip":"10829","location":"Paso De Piedra"},
    {"afip":"10844","location":"Paso Del Medio"},
    {"afip":"10865","location":"Paso Juan Gomez"},
    {"afip":"10873","location":"Paso Los Algarrobos"},
    {"afip":"10913","location":"Pastal"},
    {"afip":"10932","location":"Patio Limpio"},
    {"afip":"10960","location":"Pedernera"},
    {"afip":"10987","location":"Penice"},
    {"afip":"11017","location":"Pescadores"},
    {"afip":"11034","location":"Peñon Colorado"}
    ,
    {"afip":"11077","location":"Picos Yacu"},
    {"afip":"11086","location":"Piedra Bola"},
    {"afip":"11104","location":"Piedra Rosada"},
    {"afip":"11106","location":"Piedra Sola"},
    {"afip":"11112","location":"Piedras Chatas"},
    {"afip":"11166","location":"Piquillines"},
    {"afip":"11184","location":"Piscoyaco"},
    {"afip":"11191","location":"Pizarras Bajo Velez"},
    {"afip":"11199","location":"Placilla"},
    {"afip":"11207","location":"Planta De Sandia"},
    {"afip":"11235","location":"Plumerito"},
    {"afip":"11279","location":"Polledo"},
    {"afip":"11305","location":"Portada Del Sauce"},
    {"afip":"11347","location":"Posta De Fierro"},
    {"afip":"11350","location":"Posta Del Portezuelo"},
    {"afip":"11376","location":"Potrero De Los Funes"},
    {"afip":"11402","location":"Pozo Cavado"},
    {"afip":"11426","location":"Pozo De Las Raices"},
    {"afip":"11432","location":"Pozo De Los Rayos"},
    {"afip":"11445","location":"Pozo Del Carril"},
    {"afip":"11452","location":"Pozo Del Espinillo"},
    {"afip":"11482","location":"Pozo Frio"},
    {"afip":"11507","location":"Pozo Santiago"},
    {"afip":"11509","location":"Pozo Simon"},
    {"afip":"11534","location":"Primer Agua"},
    {"afip":"11689","location":"Puente Hierro"},
    {"afip":"11691","location":"Puente La Orqueta"},
    {"afip":"11727","location":"Puerta De Palo"},
    {"afip":"11897","location":"Puestito"},
    {"afip":"11915","location":"Puesto Bella Vista"},
    {"afip":"11950","location":"Puesto De Los Jumes"},
    {"afip":"11963","location":"Puesto De Tabares"},
    {"afip":"11985","location":"Puesto El Tala"},
    {"afip":"12063","location":"Puesto Pampa Invernada"},
    {"afip":"12065","location":"Puesto Quebrada Cal"},
    {"afip":"12074","location":"Puesto Roberto"},
    {"afip":"12085","location":"Puesto Talar"},
    {"afip":"12126","location":"Punta De La Loma"},
    {"afip":"12127","location":"Punta De La Sierra"},
    {"afip":"12134","location":"Punta Del Alto"},
    {"afip":"12171","location":"Puntos De La Linea"},
    {"afip":"12189","location":"Quebrada De La Burra"},
    {"afip":"12190","location":"Quebrada De La Mora"},
    {"afip":"12191","location":"Quebrada De Los Barrosos"},
    {"afip":"12196","location":"Quebrada De San Vicente"},
    {"afip":"12199","location":"Quebrada Del Tigre"},
    {"afip":"12247","location":"Quines"},
    {"afip":"12307","location":"Ramadita"},
    {"afip":"12346","location":"Ranquelco"},
    {"afip":"12364","location":"Real"},
    {"afip":"12387","location":"Reconquista"},
    {"afip":"12399","location":"Reforma Chica"},
    {"afip":"12417","location":"Renca"},
    {"afip":"12425","location":"Represa Del Carmen"},
    {"afip":"12426","location":"Represa Del Chañar"}
    ,
    {"afip":"12440","location":"Retazo Del Monte"},
    {"afip":"12467","location":"Riecito"},
    {"afip":"12516","location":"Rincon Del Carmen"},
    {"afip":"12518","location":"Rincon Del Este"},
    {"afip":"12597","location":"Rio Juan Gomez"},
    {"afip":"12616","location":"Rio Quinto"},
    {"afip":"12635","location":"Riojita"},
    {"afip":"12654","location":"Rodeo Cadenas"},
    {"afip":"12684","location":"Romance"},
    {"afip":"12821","location":"Salado De Amaya"},
    {"afip":"12842","location":"Salinas Del Bebedero"},
    {"afip":"12850","location":"Salitral"},
    {"afip":"12859","location":"Salto Chico"},
    {"afip":"12876","location":"San Alejandro"},
    {"afip":"12953","location":"San Fco Del Monte De Oro"},
    {"afip":"13024","location":"San Jose De Los Chañares"}
    ,
    {"afip":"13037","location":"San Jose Del Durazno"},
    {"afip":"13052","location":"San Juan De Tastu"},
    {"afip":"13106","location":"San Nicolas Punilla"},
    {"afip":"13132","location":"San Raimundo"},
    {"afip":"13139","location":"San Ramon Sud"},
    {"afip":"13147","location":"San Rufino"},
    {"afip":"13176","location":"Sant Ana"},
    {"afip":"13201","location":"Santa Dionisia"},
    {"afip":"13211","location":"Santa Felisa"},
    {"afip":"13226","location":"Santa Lucinda"},
    {"afip":"13242","location":"Santa Martina"},
    {"afip":"13257","location":"Santa Rosa De Conlara"},
    {"afip":"13265","location":"Santa Rosa Del Gigante"},
    {"afip":"13272","location":"Santa Simona"},
    {"afip":"13399","location":"Selci"},
    {"afip":"13414","location":"Serafina"},
    {"afip":"13503","location":"Socoscora"},
    {"afip":"13505","location":"Sol De Abril"},
    {"afip":"13506","location":"Sol De Abril (Dpto-San Martin)"},
    {"afip":"13533","location":"Solobasta"},
    {"afip":"13534","location":"Sololosta"},
    {"afip":"13714","location":"Talarcito"},
    {"afip":"13732","location":"Tamascanes"},
    {"afip":"13739","location":"Tamboreo"},
    {"afip":"13781","location":"Tasto"},
    {"afip":"13791","location":"Taza Blanca"},
    {"afip":"13805","location":"Temeraria"},
    {"afip":"13859","location":"Tilisarao"},
    {"afip":"13885","location":"Tintitaco"},
    {"afip":"13920","location":"Toigus"},
    {"afip":"13921","location":"Toingua"},
    {"afip":"13956","location":"Toro Bayo"},
    {"afip":"13964","location":"Toro Negro"},
    {"afip":"13983","location":"Toscal"},
    {"afip":"14014","location":"Transval"},
    {"afip":"14015","location":"Trapiche"},
    {"afip":"14023","location":"Trece De Enero"},
    {"afip":"14024","location":"Treinta De Octubre"},
    {"afip":"14045","location":"Tres Cañadas"}
    ,
    {"afip":"14082","location":"Tres Puertas"},
    {"afip":"14142","location":"Tukiros"},
    {"afip":"14181","location":"Uchaima"},
    {"afip":"14196","location":"Union"},
    {"afip":"14217","location":"Usiyal"},
    {"afip":"14220","location":"Uspara"},
    {"afip":"14238","location":"Vacas Muertas"},
    {"afip":"14254","location":"Valle De La Pancanta"},
    {"afip":"14274","location":"Valle San Agustin"},
    {"afip":"14275","location":"Valle San Jose"},
    {"afip":"14285","location":"Varela"},
    {"afip":"14317","location":"Venta De Los Rios"},
    {"afip":"14358","location":"Vieja Estancia"},
    {"afip":"14490","location":"Villa De La Quebrada"},
    {"afip":"14495","location":"Villa De Praga"},
    {"afip":"14576","location":"Villa General Roca"},
    {"afip":"14636","location":"Villa Larca"},
    {"afip":"14662","location":"Villa Luisa"},
    {"afip":"14763","location":"Villa Reynolds"},
    {"afip":"14815","location":"Villa Santiago"},
    {"afip":"14902","location":"Virarco"},
    {"afip":"14911","location":"Viscacheras"},
    {"afip":"14918","location":"Vista Hermosa"},
    {"afip":"14924","location":"Viva La Patria"},
    {"afip":"14931","location":"Vizcacheras"},
    {"afip":"14940","location":"Volcan Estanzuela"},
    {"afip":"14989","location":"Yacoro"},
    {"afip":"15079","location":"Zampal"},
    {"afip":"15144","location":"Ñurilay"}
    ,
    {"afip":"15149","location":"20 De Febrero"},
    {"afip":"15164","location":"9 De Julio"},
    {"afip":"15176","location":"Agua Amarga"},
    {"afip":"15187","location":"Agua Hedionda"},
    {"afip":"15190","location":"Agua Salada"},
    {"afip":"15197","location":"Aguada"},
    {"afip":"15202","location":"Aguadita"},
    {"afip":"15205","location":"Aguaditas"},
    {"afip":"15219","location":"Alegria"},
    {"afip":"15225","location":"Algarrobal"},
    {"afip":"15240","location":"Alta Gracia"},
    {"afip":"15248","location":"Alto Grande"},
    {"afip":"15253","location":"Alto Verde"},
    {"afip":"15280","location":"Arbol Solo"},
    {"afip":"15284","location":"Arboleda"},
    {"afip":"15285","location":"Arboles Blancos"},
    {"afip":"15310","location":"Avanzada"},
    {"afip":"15315","location":"Bajada"},
    {"afip":"15317","location":"Bajada Nueva"},
    {"afip":"15322","location":"Bajo Grande"},
    {"afip":"15334","location":"Balcarce"},
    {"afip":"15335","location":"Balde"},
    {"afip":"15336","location":"Balde Del Rosario"},
    {"afip":"15338","location":"Baldecito"},
    {"afip":"15343","location":"Barranca Colorada"},
    {"afip":"15356","location":"Barranquitas"},
    {"afip":"15361","location":"Barrial"},
    {"afip":"15372","location":"Bebida"},
    {"afip":"15373","location":"Becerra"},
    {"afip":"15389","location":"Bella Vista"},
    {"afip":"15401","location":"Boca De La Quebrada"},
    {"afip":"15403","location":"Boca Del Rio"},
    {"afip":"15416","location":"Buena Esperanza"},
    {"afip":"15418","location":"Buena Ventura"},
    {"afip":"15426","location":"Buena Vista"},
    {"afip":"15476","location":"Candelaria"},
    {"afip":"15491","location":"Carolina"},
    {"afip":"15492","location":"Carpinteria"},
    {"afip":"15503","location":"Casa De Piedra"},
    {"afip":"15513","location":"Casas Viejas"},
    {"afip":"15523","location":"Cañada"}
    ,
    {"afip":"15530","location":"Cañada Grande"}
    ,
    {"afip":"15533","location":"Cañada Honda"}
    ,
    {"afip":"15540","location":"Cañada San Antonio"}
    ,
    {"afip":"15544","location":"Cañada Verde"}
    ,
    {"afip":"15545","location":"Cañaditas"}
    ,
    {"afip":"15548","location":"Cebollar"},
    {"afip":"15553","location":"Centenario"},
    {"afip":"15563","location":"Cerrito"},
    {"afip":"15572","location":"Cerro Bayo"},
    {"afip":"15575","location":"Cerro Blanco"},
    {"afip":"15586","location":"Cerro Colorado"},
    {"afip":"15598","location":"Cerro Negro"},
    {"afip":"15611","location":"Chacras Viejas"},
    {"afip":"15629","location":"Chañaritos"}
    ,
    {"afip":"15635","location":"Chilcas"},
    {"afip":"15639","location":"Chimbas"},
    {"afip":"15665","location":"Colonia Bella Vista"},
    {"afip":"15693","location":"Colonia Luna"},
    {"afip":"15757","location":"Colonia Urdaniz"},
    {"afip":"15769","location":"Conlara"},
    {"afip":"15781","location":"Corral De Piedra"},
    {"afip":"15801","location":"Cortaderas"},
    {"afip":"15806","location":"Crucecitas"},
    {"afip":"15808","location":"Cruz De Caña"}
    ,
    {"afip":"15810","location":"Cruz De Piedra"},
    {"afip":"15816","location":"Cuatro Esquinas"},
    {"afip":"15829","location":"Divisadero"},
    {"afip":"15841","location":"Duraznito"},
    {"afip":"15852","location":"El Aguila"},
    {"afip":"15874","location":"El Alto"},
    {"afip":"15884","location":"El Arenal"},
    {"afip":"15886","location":"El Arroyo"},
    {"afip":"15889","location":"El Bagual"},
    {"afip":"15893","location":"El Bajo"},
    {"afip":"15898","location":"El Balde"},
    {"afip":"15900","location":"El Baldecito"},
    {"afip":"15906","location":"El Barrial"},
    {"afip":"15912","location":"El Bañado"}
    ,
    {"afip":"15936","location":"El Calden"},
    {"afip":"15939","location":"El Campamento"},
    {"afip":"15948","location":"El Carmen"},
    {"afip":"15960","location":"El Cavado"},
    {"afip":"15977","location":"El Cerrito"},
    {"afip":"15981","location":"El Cerro"},
    {"afip":"15988","location":"El Chañar"}
    ,
    {"afip":"15997","location":"El Cinco"},
    {"afip":"16007","location":"El Condor"},
    {"afip":"16009","location":"El Coro"},
    {"afip":"16028","location":"El Dique"},
    {"afip":"16045","location":"El Durazno"},
    {"afip":"16057","location":"El Espinillo"},
    {"afip":"16060","location":"El Fortin"},
    {"afip":"16089","location":"El Lechuzo"},
    {"afip":"16094","location":"El Manantial"},
    {"afip":"16098","location":"El Mangrullo"},
    {"afip":"16104","location":"El Martillo"},
    {"afip":"16109","location":"El Milagro"},
    {"afip":"16131","location":"El Molino"},
    {"afip":"16137","location":"El Mollar"},
    {"afip":"16142","location":"El Molle"},
    {"afip":"16146","location":"El Morro"},
    {"afip":"16155","location":"El Oasis"},
    {"afip":"16172","location":"El Pantanillo"},
    {"afip":"16173","location":"El Pantano"},
    {"afip":"16180","location":"El Paraiso"},
    {"afip":"16197","location":"El Plateado"},
    {"afip":"16199","location":"El Plumerito"},
    {"afip":"16205","location":"El Portezuelo"},
    {"afip":"16221","location":"El Porvenir"},
    {"afip":"16225","location":"El Potrerillo"},
    {"afip":"16234","location":"El Pozo"},
    {"afip":"16241","location":"El Progreso"},
    {"afip":"16246","location":"El Pueblito"},
    {"afip":"16249","location":"El Puerto"},
    {"afip":"16253","location":"El Puestito"},
    {"afip":"16258","location":"El Puesto"},
    {"afip":"16273","location":"El Quebracho"},
    {"afip":"16280","location":"El Ramblon"},
    {"afip":"16296","location":"El Retamo"},
    {"afip":"16308","location":"El Rincon"},
    {"afip":"16311","location":"El Rio"},
    {"afip":"16316","location":"El Rodeo"},
    {"afip":"16325","location":"El Rosario"},
    {"afip":"16335","location":"El Salado"},
    {"afip":"16344","location":"El Salto"},
    {"afip":"16345","location":"El Salvador"},
    {"afip":"16356","location":"El Sauce"},
    {"afip":"16372","location":"El Socorro"},
    {"afip":"16384","location":"El Tala"},
    {"afip":"16390","location":"El Talita"},
    {"afip":"16406","location":"El Totoral"},
    {"afip":"16424","location":"El Valle"},
    {"afip":"16429","location":"El Vallecito"},
    {"afip":"16433","location":"El Verano"},
    {"afip":"16436","location":"El Volcan"},
    {"afip":"16437","location":"El Zampal"},
    {"afip":"16444","location":"El Zapallar"},
    {"afip":"16454","location":"Ensenada"},
    {"afip":"16460","location":"Entre Rios"},
    {"afip":"16471","location":"Espinillo"},
    {"afip":"16479","location":"Estancia"},
    {"afip":"16484","location":"Estancia Grande"},
    {"afip":"16489","location":"Estancia San Antonio"},
    {"afip":"16496","location":"Estancia San Roque"},
    {"afip":"16501","location":"Estanzuela"},
    {"afip":"16522","location":"Florida"},
    {"afip":"16526","location":"Fortuna"},
    {"afip":"16552","location":"Guanaco"},
    {"afip":"16567","location":"Hipolito Yrigoyen"},
    {"afip":"16586","location":"Invernada"},
    {"afip":"16600","location":"Isondu"},
    {"afip":"16622","location":"La Adela"},
    {"afip":"16627","location":"La Aguada"},
    {"afip":"16638","location":"La Alameda"},
    {"afip":"16639","location":"La Alianza"},
    {"afip":"16640","location":"La Amalia"},
    {"afip":"16643","location":"La Angelina"},
    {"afip":"16648","location":"La Angostura"},
    {"afip":"16653","location":"La Arboleda"},
    {"afip":"16660","location":"La Argentina"},
    {"afip":"16668","location":"La Armonia"},
    {"afip":"16673","location":"La Atalaya"},
    {"afip":"16676","location":"La Aurora"},
    {"afip":"16680","location":"La Bajada"},
    {"afip":"16703","location":"La Brea"},
    {"afip":"16711","location":"La Calera"},
    {"afip":"16726","location":"La Carmen"},
    {"afip":"16735","location":"La Cautiva"},
    {"afip":"16743","location":"La Cañada"}
    ,
    {"afip":"16749","location":"La Celia"},
    {"afip":"16758","location":"La Chilca"},
    {"afip":"16773","location":"La Cienaga"},
    {"afip":"16780","location":"La Cocha"},
    {"afip":"16783","location":"La Colina"},
    {"afip":"16791","location":"La Colonia"},
    {"afip":"16805","location":"La Cora"},
    {"afip":"16806","location":"La Corina"},
    {"afip":"16809","location":"La Cortadera"},
    {"afip":"16816","location":"La Costa"},
    {"afip":"16834","location":"La Cumbre"},
    {"afip":"16839","location":"La Delia"},
    {"afip":"16845","location":"La Dora"},
    {"afip":"16850","location":"La Dulce"},
    {"afip":"16851","location":"La Elena"},
    {"afip":"16852","location":"La Elenita"},
    {"afip":"16858","location":"La Elvira"},
    {"afip":"16861","location":"La Emilia"},
    {"afip":"16866","location":"La Escondida"},
    {"afip":"16869","location":"La Esmeralda"},
    {"afip":"16881","location":"La Esperanza"},
    {"afip":"16889","location":"La Esquina"},
    {"afip":"16896","location":"La Estancia"},
    {"afip":"16899","location":"La Estanzuela"},
    {"afip":"16910","location":"La Estrella"},
    {"afip":"16912","location":"La Eulogia"},
    {"afip":"16926","location":"La Flecha"},
    {"afip":"16942","location":"La Florida"},
    {"afip":"16956","location":"La Gaviota"},
    {"afip":"16957","location":"La Germania"},
    {"afip":"16961","location":"La Gramilla"},
    {"afip":"16969","location":"La Guardia"},
    {"afip":"16981","location":"La Higuerita"},
    {"afip":"16982","location":"La Holanda"},
    {"afip":"16991","location":"La Huerta"},
    {"afip":"17000","location":"La Invernada"},
    {"afip":"17006","location":"La Isabel"},
    {"afip":"17013","location":"La Isla"},
    {"afip":"17019","location":"La Josefina"},
    {"afip":"17021","location":"La Juanita"},
    {"afip":"17022","location":"La Julia"},
    {"afip":"17041","location":"La Legua"},
    {"afip":"17048","location":"La Linea"},
    {"afip":"17055","location":"La Loma"},
    {"afip":"17064","location":"La Luisa"},
    {"afip":"17069","location":"La Magdalena"},
    {"afip":"17072","location":"La Majada"},
    {"afip":"17078","location":"La Maravilla"},
    {"afip":"17083","location":"La Margarita"},
    {"afip":"17086","location":"La Maria"},
    {"afip":"17088","location":"La Maria Luisa"},
    {"afip":"17089","location":"La Maroma"},
    {"afip":"17092","location":"La Mascota"},
    {"afip":"17099","location":"La Merced"},
    {"afip":"17105","location":"La Mesilla"},
    {"afip":"17106","location":"La Moderna"},
    {"afip":"17113","location":"La Negra"},
    {"afip":"17114","location":"La Negrita"},
    {"afip":"17115","location":"La Nelida"},
    {"afip":"17121","location":"La Nutria"},
    {"afip":"17134","location":"La Palmira"},
    {"afip":"17142","location":"La Pampa"},
    {"afip":"17149","location":"La Patria"},
    {"afip":"17157","location":"La Peregrina"},
    {"afip":"17171","location":"La Plata"},
    {"afip":"17179","location":"La Porfia"},
    {"afip":"17203","location":"La Primavera"},
    {"afip":"17209","location":"La Providencia"},
    {"afip":"17214","location":"La Puerta"},
    {"afip":"17224","location":"La Quebrada"},
    {"afip":"17235","location":"La Ramada"},
    {"afip":"17246","location":"La Reforma"},
    {"afip":"17247","location":"La Reina"},
    {"afip":"17253","location":"La Represa"},
    {"afip":"17255","location":"La Reserva"},
    {"afip":"17262","location":"La Rinconada"},
    {"afip":"17268","location":"La Rosada"},
    {"afip":"17270","location":"La Rosalia"},
    {"afip":"17285","location":"La Segunda"},
    {"afip":"17287","location":"La Selva"},
    {"afip":"17290","location":"La Seña"}
    ,
    {"afip":"17293","location":"La Sirena"},
    {"afip":"17304","location":"La Suiza"},
    {"afip":"17313","location":"La Tigra"},
    {"afip":"17319","location":"La Toma"},
    {"afip":"17322","location":"La Tosca"},
    {"afip":"17323","location":"La Totora"},
    {"afip":"17329","location":"La Travesia"},
    {"afip":"17339","location":"La Union"},
    {"afip":"17349","location":"La Verde"},
    {"afip":"17353","location":"La Vertiente"},
    {"afip":"17367","location":"La Yesera"},
    {"afip":"17382","location":"Laguna Brava"},
    {"afip":"17390","location":"Laguna Larga"},
    {"afip":"17396","location":"Laguna Seca"},
    {"afip":"17421","location":"Las Aromas"},
    {"afip":"17423","location":"Las Bajadas"},
    {"afip":"17428","location":"Las Barrancas"},
    {"afip":"17435","location":"Las Cabras"},
    {"afip":"17437","location":"Las Canteras"},
    {"afip":"17448","location":"Las Cañas"}
    ,
    {"afip":"17454","location":"Las Cañitas"}
    ,
    {"afip":"17463","location":"Las Chacras"},
    {"afip":"17468","location":"Las Chacritas"},
    {"afip":"17469","location":"Las Chilcas"},
    {"afip":"17471","location":"Las Chimbas"},
    {"afip":"17475","location":"Las Colonias"},
    {"afip":"17484","location":"Las Cortaderas"},
    {"afip":"17501","location":"Las Delicias"},
    {"afip":"17504","location":"Las Encadenadas"},
    {"afip":"17512","location":"Las Flores"},
    {"afip":"17514","location":"Las Gamas"},
    {"afip":"17526","location":"Las Higueras"},
    {"afip":"17549","location":"Las Lagunas"},
    {"afip":"17550","location":"Las Lagunitas"},
    {"afip":"17553","location":"Las Lajas"},
    {"afip":"17559","location":"Las Lomas"},
    {"afip":"17572","location":"Las Martinetas"},
    {"afip":"17596","location":"Las Nieves"},
    {"afip":"17603","location":"Las Palmas"},
    {"afip":"17614","location":"Las Palomas"},
    {"afip":"17619","location":"Las Pampitas"},
    {"afip":"17626","location":"Las Peñas"}
    ,
    {"afip":"17634","location":"Las Piedritas"},
    {"afip":"17638","location":"Las Playas"},
    {"afip":"17639","location":"Las Puertas"},
    {"afip":"17652","location":"Las Rosas"},
    {"afip":"17661","location":"Las Salinas"},
    {"afip":"17678","location":"Las Toscas"},
    {"afip":"17681","location":"Las Totoritas"},
    {"afip":"17698","location":"Las Viscacheras"},
    {"afip":"17708","location":"Leandro N Alem"},
    {"afip":"17726","location":"Loma Del Medio"},
    {"afip":"17748","location":"Lomas Blancas"},
    {"afip":"17750","location":"Lomitas"},
    {"afip":"17762","location":"Los Alamos"},
    {"afip":"17764","location":"Los Algarrobitos"},
    {"afip":"17770","location":"Los Algarrobos"},
    {"afip":"17781","location":"Los Arroyos"},
    {"afip":"17790","location":"Los Barriales"},
    {"afip":"17799","location":"Los Cajones"},
    {"afip":"17810","location":"Los Cerrillos"},
    {"afip":"17813","location":"Los Cerritos"},
    {"afip":"17819","location":"Los Chañares"}
    ,
    {"afip":"17822","location":"Los Chañaritos"}
    ,
    {"afip":"17825","location":"Los Cisnes"},
    {"afip":"17827","location":"Los Claveles"},
    {"afip":"17834","location":"Los Condores"},
    {"afip":"17838","location":"Los Corrales"},
    {"afip":"17840","location":"Los Corralitos"},
    {"afip":"17844","location":"Los Dos Rios"},
    {"afip":"17845","location":"Los Duraznos"},
    {"afip":"17860","location":"Los Huaycos"},
    {"afip":"17862","location":"Los Jagueles"},
    {"afip":"17873","location":"Los Manantiales"},
    {"afip":"17876","location":"Los Medanos"},
    {"afip":"17890","location":"Los Molles"},
    {"afip":"17904","location":"Los Noques"},
    {"afip":"17919","location":"Los Pejes"},
    {"afip":"17936","location":"Los Pozos"},
    {"afip":"17940","location":"Los Puestos"},
    {"afip":"17945","location":"Los Quebrachos"},
    {"afip":"17950","location":"Los Ramblones"},
    {"afip":"17961","location":"Los Sauces"},
    {"afip":"17966","location":"Los Talas"},
    {"afip":"17967","location":"Los Tigres"},
    {"afip":"17979","location":"Lujan"},
    {"afip":"17994","location":"Manantial Grande"},
    {"afip":"18002","location":"Manantiales"},
    {"afip":"18008","location":"Maravilla"},
    {"afip":"18029","location":"Medanos"},
    {"afip":"18035","location":"Media Luna"},
    {"afip":"18039","location":"Mercedes"},
    {"afip":"18042","location":"Merlo"},
    {"afip":"18045","location":"Milagro"},
    {"afip":"18047","location":"Mina Carolina"},
    {"afip":"18067","location":"Mollecito"},
    {"afip":"18071","location":"Monte Carmelo"},
    {"afip":"18089","location":"Monte Verde"},
    {"afip":"18102","location":"Mosmota"},
    {"afip":"18113","location":"Negro Muerto"},
    {"afip":"18118","location":"Nueva Escocia"},
    {"afip":"18123","location":"Nueva Esperanza"},
    {"afip":"18138","location":"Ojo De Agua"},
    {"afip":"18149","location":"Otra Banda"},
    {"afip":"18183","location":"Palomar"},
    {"afip":"18187","location":"Pampa"},
    {"afip":"18192","location":"Pampa Grande"},
    {"afip":"18202","location":"Papagayos"},
    {"afip":"18205","location":"Paraiso"},
    {"afip":"18210","location":"Paso Ancho"},
    {"afip":"18211","location":"Paso De La Cruz"},
    {"afip":"18214","location":"Paso De Las Carretas"},
    {"afip":"18216","location":"Paso De Los Algarrobos"},
    {"afip":"18217","location":"Paso De Los Gauchos"},
    {"afip":"18222","location":"Paso Del Rey"},
    {"afip":"18223","location":"Paso Grande"},
    {"afip":"18242","location":"Pie De La Cuesta"},
    {"afip":"18247","location":"Piedra Blanca"},
    {"afip":"18252","location":"Piedra Larga"},
    {"afip":"18257","location":"Piedras Anchas"},
    {"afip":"18262","location":"Piedras Blancas"},
    {"afip":"18272","location":"Pocitos"},
    {"afip":"18280","location":"Portezuelo"},
    {"afip":"18285","location":"Porvenir"},
    {"afip":"18289","location":"Potrerillo"},
    {"afip":"18304","location":"Pozo Cercado"},
    {"afip":"18318","location":"Pozo Del Medio"},
    {"afip":"18320","location":"Pozo Del Molle"},
    {"afip":"18322","location":"Pozo Del Tala"},
    {"afip":"18325","location":"Pozo Escondido"},
    {"afip":"18337","location":"Pozo Seco"},
    {"afip":"18369","location":"Puerta Colorada"},
    {"afip":"18370","location":"Puerta De La Isla"},
    {"afip":"18376","location":"Puerto Alegre"},
    {"afip":"18378","location":"Puerto Rico"},
    {"afip":"18403","location":"Punilla"},
    {"afip":"18409","location":"Punta De Agua"},
    {"afip":"18417","location":"Punta Del Cerro"},
    {"afip":"18427","location":"Puntos De Agua"},
    {"afip":"18431","location":"Quebrachito"},
    {"afip":"18440","location":"Quebrada Honda"},
    {"afip":"18456","location":"Ramblones"},
    {"afip":"18464","location":"Recreo"},
    {"afip":"18469","location":"Represa Del Monte"},
    {"afip":"18471","location":"Retamo"},
    {"afip":"18473","location":"Retiro"},
    {"afip":"18509","location":"Rio Grande"},
    {"afip":"18541","location":"Rosales"},
    {"afip":"18544","location":"Rumiguasi"},
    {"afip":"18548","location":"Saladillo"},
    {"afip":"18552","location":"Salado"},
    {"afip":"18555","location":"Salinas"},
    {"afip":"18565","location":"San Agustin"},
    {"afip":"18572","location":"San Alberto"},
    {"afip":"18597","location":"San Antonio"},
    {"afip":"18615","location":"San Camilo"},
    {"afip":"18624","location":"San Carlos"},
    {"afip":"18631","location":"San Celestino"},
    {"afip":"18654","location":"San Felipe"},
    {"afip":"18663","location":"San Fernando"},
    {"afip":"18683","location":"San Geronimo"},
    {"afip":"18687","location":"San Gregorio"},
    {"afip":"18703","location":"San Ignacio"},
    {"afip":"18714","location":"San Isidro"},
    {"afip":"18739","location":"San Jorge"},
    {"afip":"18754","location":"San Jose"},
    {"afip":"18769","location":"San Juan"},
    {"afip":"18793","location":"San Lorenzo"},
    {"afip":"18803","location":"San Luis"},
    {"afip":"18821","location":"San Martin"},
    {"afip":"18835","location":"San Miguel"},
    {"afip":"18847","location":"San Pablo"},
    {"afip":"18864","location":"San Pedro"},
    {"afip":"18877","location":"San Rafael"},
    {"afip":"18885","location":"San Ramon"},
    {"afip":"18896","location":"San Roque"},
    {"afip":"18903","location":"San Salvador"},
    {"afip":"18925","location":"San Vicente"},
    {"afip":"18938","location":"Santa Ana"},
    {"afip":"18954","location":"Santa Catalina"},
    {"afip":"18958","location":"Santa Cecilia"},
    {"afip":"18965","location":"Santa Clara"},
    {"afip":"19000","location":"Santa Isabel"},
    {"afip":"19011","location":"Santa Lucia"},
    {"afip":"19028","location":"Santa Maria"},
    {"afip":"19042","location":"Santa Rita"},
    {"afip":"19056","location":"Santa Rosa"},
    {"afip":"19059","location":"Santa Rufina"},
    {"afip":"19066","location":"Santa Teresa"},
    {"afip":"19069","location":"Santa Teresita"},
    {"afip":"19071","location":"Santa Victoria"},
    {"afip":"19084","location":"Santo Domingo"},
    {"afip":"19111","location":"Sauce"},
    {"afip":"19115","location":"Saucesito"},
    {"afip":"19174","location":"Tala Verde"},
    {"afip":"19178","location":"Talita"},
    {"afip":"19205","location":"Totoral"},
    {"afip":"19208","location":"Totorilla"},
    {"afip":"19215","location":"Travesia"},
    {"afip":"19240","location":"Tres Lomas"},
    {"afip":"19241","location":"Tres Marias"},
    {"afip":"19265","location":"Unquillo"},
    {"afip":"19273","location":"Valle Hermoso"},
    {"afip":"19276","location":"Vallecito"},
    {"afip":"19291","location":"Villa Del Carmen"},
    {"afip":"19294","location":"Villa Dolores"},
    {"afip":"19297","location":"Villa Elena"},
    {"afip":"19350","location":"Vista Alegre"},
    {"afip":"20370","location":"El Rincon"},
    {"afip":"20371","location":"Isla"},
    {"afip":"20372","location":"La Chilca"},
    {"afip":"20373","location":"La Ramada"},
    {"afip":"20374","location":"La Union"},
    {"afip":"20375","location":"Los Chañares"}
    ,
    {"afip":"20376","location":"Los Molles"},
    {"afip":"20377","location":"San Miguel"},
    {"afip":"20378","location":"Santa Ana"},
    {"afip":"20379","location":"Santa Lucia"},
    {"afip":"20380","location":"Alto Grande"},
    {"afip":"20381","location":"Balde"},
    {"afip":"20382","location":"Bella Vista"},
    {"afip":"20383","location":"Cortaderas"},
    {"afip":"20384","location":"El Chañar"}
    ,
    {"afip":"20385","location":"El Molle"},
    {"afip":"20386","location":"El Quebracho"},
    {"afip":"20387","location":"El Socorro"},
    {"afip":"20388","location":"El Talita"},
    {"afip":"20389","location":"El Totoral"},
    {"afip":"20390","location":"La Brea"},
    {"afip":"20391","location":"La Cañada"}
    ,
    {"afip":"20392","location":"La Dulce"},
    {"afip":"20393","location":"La Totora"},
    {"afip":"20394","location":"La Union"},
    {"afip":"20395","location":"La Verde"},
    {"afip":"20396","location":"Las Barrancas"},
    {"afip":"20397","location":"Las Lagunitas"},
    {"afip":"20398","location":"Los Cerrillos"},
    {"afip":"20399","location":"Represa Del Monte"},
    {"afip":"20400","location":"San Antonio"},
    {"afip":"20401","location":"San Jorge"},
    {"afip":"20402","location":"San Martin"},
    {"afip":"20403","location":"San Roque"},
    {"afip":"20404","location":"San Vicente"},
    {"afip":"20405","location":"Santa Isabel"},
    {"afip":"20406","location":"Santa Rosa"},
    {"afip":"20407","location":"Santa Teresa"},
    {"afip":"20408","location":"Santo Domingo"},
    {"afip":"20409","location":"Tres Cañadas"}
    ,
    {"afip":"20410","location":"9 De Julio"},
    {"afip":"20411","location":"Alto Del Valle"},
    {"afip":"20412","location":"Cañada"}
    ,
    {"afip":"20413","location":"Cañada Quemada"}
    ,
    {"afip":"20414","location":"Casa De Piedra"},
    {"afip":"20415","location":"Cortaderas"},
    {"afip":"20416","location":"Divisadero"},
    {"afip":"20417","location":"Duraznito"},
    {"afip":"20418","location":"El Paraiso"},
    {"afip":"20419","location":"El Poleo"},
    {"afip":"20420","location":"El Porvenir"},
    {"afip":"20421","location":"El Puesto"},
    {"afip":"20422","location":"El Rincon"},
    {"afip":"20423","location":"El Rodeo"},
    {"afip":"20424","location":"El Salado"},
    {"afip":"20425","location":"El Salto"},
    {"afip":"20426","location":"El Talita"},
    {"afip":"20427","location":"El Valle"},
    {"afip":"20428","location":"El Vallecito"},
    {"afip":"20429","location":"La Aurora"},
    {"afip":"20430","location":"La Elvira"},
    {"afip":"20431","location":"La Esperanza"},
    {"afip":"20432","location":"La Esquina"},
    {"afip":"20433","location":"La Florida"},
    {"afip":"20434","location":"La Ramada"},
    {"afip":"20435","location":"La Totora"},
    {"afip":"20436","location":"La Vertiente"},
    {"afip":"20437","location":"Las Barranquitas"},
    {"afip":"20438","location":"Las Chacras De San Martin"},
    {"afip":"20439","location":"Las Flores"},
    {"afip":"20440","location":"Las Higueras"},
    {"afip":"20441","location":"Las Lagunas"},
    {"afip":"20442","location":"Las Lomas"},
    {"afip":"20443","location":"Los Alamos"},
    {"afip":"20444","location":"Los Algarrobos"},
    {"afip":"20445","location":"Los Lobos"},
    {"afip":"20446","location":"Los Molles"},
    {"afip":"20447","location":"Los Sauces"},
    {"afip":"20448","location":"Los Talas"},
    {"afip":"20449","location":"Manantial"},
    {"afip":"20450","location":"Ojo De Agua"},
    {"afip":"20451","location":"Pampa Grande"},
    {"afip":"20452","location":"San Isidro"},
    {"afip":"20453","location":"San Jose"},
    {"afip":"20454","location":"San Lorenzo"},
    {"afip":"20455","location":"San Martin"},
    {"afip":"20456","location":"San Miguel"},
    {"afip":"20457","location":"San Pedro"},
    {"afip":"20458","location":"San Ramon"},
    {"afip":"20459","location":"Santa Clara"},
    {"afip":"20460","location":"Santa Maria"},
    {"afip":"20461","location":"Sol De Abril"},
    {"afip":"20462","location":"Totoral"},
    {"afip":"20464","location":"San Antonio"},
    {"afip":"20465","location":"El Chañar"}
    ,
    {"afip":"20466","location":"La Aguada"},
    {"afip":"20467","location":"La Alameda"},
    {"afip":"20468","location":"La Bajada"},
    {"afip":"20469","location":"La Florida"},
    {"afip":"20470","location":"San Antonio"},
    {"afip":"20471","location":"San Miguel"},
    {"afip":"20472","location":"Santa Clara"},
    {"afip":"20473","location":"Cerro Blanco"},
    {"afip":"20474","location":"Cerro Negro"},
    {"afip":"20475","location":"El Calden"},
    {"afip":"20476","location":"El Carmen"},
    {"afip":"20477","location":"El Chañar"}
    ,
    {"afip":"20478","location":"El Espinillo"},
    {"afip":"20479","location":"El Talita"},
    {"afip":"20480","location":"La Aguada"},
    {"afip":"20481","location":"La Cañada"}
    ,
    {"afip":"20482","location":"La Primavera"},
    {"afip":"20483","location":"San Pedro"},
    {"afip":"20484","location":"Santa Clara"},
    {"afip":"20485","location":"Santa Isabel"},
    {"afip":"20486","location":"El Espinillo"},
    {"afip":"20487","location":"El Recuerdo"},
    {"afip":"20488","location":"El Verano"},
    {"afip":"20489","location":"La Aurora"},
    {"afip":"20490","location":"La Cañada"}
    ,
    {"afip":"20491","location":"La Colonia"},
    {"afip":"20492","location":"La Escondida"},
    {"afip":"20493","location":"La Esperanza"},
    {"afip":"20494","location":"La Estrella"},
    {"afip":"20495","location":"La Florida"},
    {"afip":"20496","location":"La Josefa"},
    {"afip":"20497","location":"La Juanita"},
    {"afip":"20498","location":"La Mascota"},
    {"afip":"20499","location":"Las Lagunas"},
    {"afip":"20500","location":"Los Chañares"}
    ,
    {"afip":"20501","location":"San Antonio"},
    {"afip":"20502","location":"San Isidro"},
    {"afip":"20503","location":"San Jorge"},
    {"afip":"20504","location":"San Jose"},
    {"afip":"20505","location":"Santa Maria"},
    {"afip":"20506","location":"Santo Domingo"},
    {"afip":"20518","location":"El Calden"},
    {"afip":"20519","location":"La Aguada"},
    {"afip":"20520","location":"La Florida"},
    {"afip":"20521","location":"San Pedro"},
    {"afip":"20522","location":"Bella Vista"},
    {"afip":"20523","location":"Cuatro Esquinas"},
    {"afip":"20524","location":"El Bañado"}
    ,
    {"afip":"20525","location":"El Calden"},
    {"afip":"20526","location":"El Puesto"},
    {"afip":"20527","location":"El Tala"},
    {"afip":"20528","location":"La Escondida"},
    {"afip":"20529","location":"Los Chañares"}
    ,
    {"afip":"21678","location":"El Algarrobal"},
    {"afip":"22112","location":"Leandro N. Alem"},
    {"afip":"22113","location":"San Francisco Del Monte"},
    {"afip":"22116","location":"Altogrande"},
    {"afip":"22117","location":"Juan W. Gez"},
    {"afip":"22118","location":"Villa Mercedes"},
    {"afip":"22119","location":"Alzogaray"},
    {"afip":"22120","location":"Santa Rosa Del Conlara"},
    {"afip":"22122","location":"La Punilla"},
    {"afip":"22123","location":"El Tala"},
    {"afip":"22124","location":"Fortin El  Patria"},
    {"afip":"22272","location":"Carolina (Cnel. Pringles)"},
    {"afip":"22273","location":"Las Carolinas (Gral. Pedernera)"}
    ]