import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { BUSINESS_PARTICULARITIES_VOID } from 'src/app/shared/const/business-particularities';
import { WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { PriceValidator } from 'src/app/shared/price';
import { FormComponent } from '../form.component';
import { SessionChecker } from 'src/app/shared/session.checker';
import { setIsEditedOrderFromBoard } from 'src/app/redux/actions/options.action';
import { SELLER_DATA_VOID } from 'src/app/shared/const/orders';

@Component({
  selector: 'app-form-order-from-board',
  templateUrl: './form.component.html',
  styleUrls: [ './../form.component.scss']
})
export class FormOrderFromBoardComponent extends FormComponent implements OnDestroy {
  public isEditedOrderFromBoard:boolean=false;
  public isDuplicateOrder:boolean=false;
  public isSyngentaAgroAvc:boolean=false;
  public isDisabled:boolean=false;
  public haveValidUntil:boolean=false;

  private orderToForm$!:Subscription;
  private optionsAux$!:Subscription;
  

  constructor(store:Store<appState>,orderExcV:OrderExchangeValidator, orderV:OrderValidator, priceV:PriceValidator,  dialog: MatDialog, checker:SessionChecker) { 
    super(store, checker,orderExcV, orderV, priceV, dialog);
    
    this.orderToForm$=this.store.select('order','generateOrders').subscribe(orders=>{
      this.isDuplicateOrder=orders.length>1;
    })

    this.optionsAux$=this.store.select('options').subscribe(optionsAuxR=>{
      this.isEditedOrderFromBoard=optionsAuxR.isEditedOrderFromBoard;
    });
  }

  override ngOnDestroy(){
    super.ngOnDestroy();
    this.orderToForm$?.unsubscribe();
    this.optionsAux$?.unsubscribe();
    
    this.store.dispatch(setIsEditedOrderFromBoard({isEditedOrderFromBoard: false}));
  }

  override functionsWithFormOrders(order: Order): void {
    this.isSyngentaAgroAvc=this.orderV.orderIsSyngentaAgroAvc(order);
    this.haveValidUntil=this.priceV.isValidUntil(order.price);
  }

  override refreshDisabledOrder(): void {
    this.isDisabled=this.isDisabledOrder(this.showExchangeOptions)
  }

  editOrder(){
    this.store.dispatch(setIsEditedOrderFromBoard({isEditedOrderFromBoard: true}));
    this.changeStep(2, this.formulario.nativeElement);
  }

  override deleteStep(numOfStep: number): void {
    let order:Order=JSON.parse(JSON.stringify(this.order));
    if(numOfStep==1){
      order.sellerData= SELLER_DATA_VOID;
      order.placeOfOrigin = {province: -1, town:-1, afip: -1};
      order.broke= 1;
      order.price.businessParticularities= BUSINESS_PARTICULARITIES_VOID;
      order.howSell= -1;
      order.buyer= {onlySell: -1, notSell: -1};
      order.tons= -1;
      order.quotas= "[]";
      order.obsParam='--'
    } else if(numOfStep==2){
      order.price.typeBusiness= -1;
      order.price.productName= -1;
      order.price.price=-1;
      order.price.typeCoin='--';
      order.price.placeOfDelivery={port: -1, zone: -1};
      order.price.deliveryPeriod= {endDate: '--', startDate: '--'}
    } else if(numOfStep==3){;
      order.price.quality= -1;
      order.price.harvest='--';
      order.price.wayPay= WAY_PAY_VOID;
      order.price.pesificacion={endDate: '--', startDate: '--'}
    } else if(numOfStep==4){
      order.price.conditionType=0;
      order.price.indicators=[];
    }
    this.store.dispatch(setOrderToGenerate({order:order}));
  }

}
