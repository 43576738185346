import { Component,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, copyOrder } from 'src/app/shared/order';
import { OrderTableComponent } from '../order-table.component';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';

@Component({
  selector: 'app-comercial',
  templateUrl: './comercial.component.html',
  styleUrls: ['../order-table.component.scss', './../../../../../shared/styles/board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
  
})
export class ComercialComponent extends OrderTableComponent {

 constructor(store: Store<appState>, router: Router, dialog: MatDialog, 
  date:DateChecker, orderV:OrderValidator, toFixV:ToFixValidator, cdr: ChangeDetectorRef) {
  super(store,router, dialog,date, orderV, toFixV,cdr)
 }

 getStatusFromOrder(order:Order){
  return orderStatusConst.AUTORIZATION==order.statusOrder? 'Confirmada': this.orderV.getStatusOrder(order);
 }

 getContractNumber(order:Order){
  return order.contractNumber;
 }

 showContractNumber(order:Order){
  return order.contractNumber > 0 && order.statusOrder == orderStatusConst.CONTRACT;
 }

 getOptionsMenu(order:Order):OptionMenu[]{
  return order.statusOrder === orderStatusConst.RETURNED
  ? [ { name: 'Historial de cambios', icon: 'clock-svg', action: OPTION.HISTORICAL },
      { name: 'Reingresar orden', icon: 'edit-svg', action: OPTION.REINGRESATE },
      { name: 'Copiar orden', icon: 'copy', action: OPTION.COPY }, ]
  : [ { name: 'Historial de cambios', icon: 'clock-svg', action: OPTION.HISTORICAL },
      { name: 'Copiar orden', icon: 'copy', action: OPTION.COPY }, ];
 }

 override selectOptionMenu(option:OptionMenu, order:Order){
  switch(option.action){
    case OPTION.DETAILS: this.showOrderDetail(order); break;
    case OPTION.HISTORICAL: this.showHistorical(order); break;
    case OPTION.REINGRESATE: this.reingresateOrder(order); break;
    case OPTION.COPY: copyOrder(order,this.store, this.router); break;
  }
 }
} 