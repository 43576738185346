<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(3)">Acumulador</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="accumulator.isAccumulator==1" (change)="setOptionOpenWithCheck($event,3)" (change)="verifyAccumulator(accumulator)"/>
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="option-content-particularity">
            <span class="option-name">Doble con knock out</span>
            <div class="actions">
                <input type="radio" (click)="setAccumulator(1)" [checked]="accumulator.typeAccumulator==1"  class="radio" />
            </div>
        </div>  
        <div class="option-content-particularity">
            <span class="option-name">Simple con knock out</span>
            <div class="actions">
                <input type="radio" (click)="setAccumulator(2)" [checked]="accumulator.typeAccumulator==2" class="radio" />
            </div>
        </div>      
        <div class="option-content-particularity">
            <span class="option-name">Doble con piso</span>
            <div class="actions">
                <input type="radio" (click)="setAccumulator(3)" [checked]="accumulator.typeAccumulator==3"  class="radio" />
            </div>
        </div>
    </div>
</div>