import { Component, OnInit,OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { areas } from 'src/app/shared/const/user.const';
import { conditionType } from 'src/app/shared/const/options';
import { DateChecker } from 'src/app/shared/date.checker';
import { PriceValidator } from 'src/app/shared/price';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Subscription } from 'rxjs';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { setViewMobile } from 'src/app/shared/const/options';

const condicionesMAP={
  1:'tradicional',
  2:'pase-de-cosecha',
  3:'porcentaje-de-producto',
  4: 'sin-espacio'
}

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class PriceTableComponent implements OnInit, OnDestroy {
  isComercial: boolean = false;
  isOperator:boolean=false;
  pricesWithPipe: Price[] = [];

  isViewMobile = true;

  CONDITION_TYPES=conditionType;
  conditionType!:string;
  condicionesClass:any;

  private pricesWithPipes$!: Subscription;
  private conditionType$!:Subscription;
  private user$!:Subscription;
  private sizeView$!:Subscription;

  constructor(public store: Store<appState>,public router: Router,
    public date: DateChecker,public priceV: PriceValidator, public toFixV:ToFixValidator, public cdr: ChangeDetectorRef) {

      this.condicionesClass=condicionesMAP;
    }

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.setUnsubscriptions();
  }

  setSubscriptions(){
    this.user$=this.store.select('usuario').subscribe(user=>{
      this.isComercial = user.area == areas.comercial;
      this.isOperator= user.area ==areas.commodities;
    })

    this.pricesWithPipes$=this.store.select('price', 'priceBoard', 'allWithPipes').subscribe((pricesWithPipes) => {
      this.pricesWithPipe = pricesWithPipes;
    });

    this.conditionType$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe((conditionType) => {
      this.conditionType = conditionType;
    });

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView=>{
      this.isViewMobile = setViewMobile(sizeView);
    })
  }

  setUnsubscriptions(){
    this.user$?.unsubscribe();
    this.sizeView$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.pricesWithPipes$?.unsubscribe();
  }

  getConditionType(price:Price){
    return this.condicionesClass[price.conditionType] ?? 'display-none';
  }
}