import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowBackSvgComponent } from './arrow-back.component';

@NgModule({
  declarations: [
    ArrowBackSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    ArrowBackSvgComponent
  ]
})
export class ArrowBackSvgModule { }
