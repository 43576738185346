<div class="paso step-exchange">
    <app-data class="data" title="Comprador" value="{{getSellerName(order)}}"></app-data>
    <app-data class="data" title="Subdivisión Syngenta" value="{{getSubdivisionSyngenta(order)}}" *ngIf="isSyngenta(order)"></app-data>
    <app-data class="data" title="Vendedor" value="{{getBuyerName(order)}}"></app-data>
    <app-data class="data" title="¿Cómo vende?" value="{{getHowSell(order)}}"></app-data>
    <app-data class="data" title="Lugar de procedencia" value="{{getPlaceOfOrigin(order)}}"></app-data>
    <app-data class="data" title="Toneladas" value="{{getTons(order)}}"></app-data>
    <!--<app-data class="data" title="Forma de pago" value="{{getWayPay(order)}}"></app-data>-->
    <app-data class="data" title="Descuento convenido" value="{{getAgreedDiscount(order)}}" *ngIf="getAgreedDiscount(order) != '--'"></app-data>
    <app-data class="data" title="Bonificación" value="{{getBonus(order)}}"></app-data>
    
    <div class="relative data">
        <app-data class="data relative" title="Precio" value="{{getPrice(order)}}"></app-data>
        <span class="absolute caption" *ngIf="getPriceDiscount(order) != '--'">Descuento del {{getPriceDiscount(order)}}</span>
    </div>
    <app-data class="data" title="Moneda" value="{{getTypeCoin(order)}}"></app-data>
</div>