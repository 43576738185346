export const BROKERS_CONST={
    GRASSI:1,
    GGSA_1888:2,
}


export const brokerValids={
    "-1": [],
    0: [], 
    1: [1,2], //Buenos Aires - ESTA
    2: [1], //CABA - ESTA
    3: [2], //Catamarca - ESTA
    4: [1], //Chaco - ESTA
    5: [], //Chubut 
    6: [1,2], //Cordoba - ESTA
    7: [], //Corrientes
    8: [1], //Entre Rios - ESTA
    9: [], //Formosa
    10: [], //Jujuy
    11: [1], //La Pampa - ESTA
    12: [2], //La Rioja - ESTA
    13: [], //Mendoza
    14: [], //Misiones
    15: [], //Neuquen
    16: [], //Rio Negro
    17: [2], //Salta - ESTA
    18: [], //San Juan
    19: [1], //San Luis - ESTA
    20: [], //Santa Cruz
    21: [1,2], //Santa Fe - ESTA 
    22: [1,2], //Santiago del Estero - ESTA
    23: [], //Tierra del Fuego
    24: [2], //Tucuman - ESTA
}