<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Editar orden"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Editar orden de venta </h2>
    <!-- CONTENT OF VALIDATIONS ORDER -->
    <app-body-orders-validation></app-body-orders-validation>    

    <app-menu class="menu"></app-menu>
    <!--<app-menu-footer></app-menu-footer> -->
</div>