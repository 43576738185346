<div class="features order width-100" *ngIf="order">
    <div class="container-contract-number" *ngIf="getNroOfContract(order)">
        <span class="nro-of-contract">Contrato N°: {{getNroOfContract(order)}}</span>
    </div>

    <div class="row-features">
        <div class="column-left">
            <span class="seller">{{getSellerName(order)}}</span>
            <span class="sub-info-seller-buyer">Vendedor - N° de cuenta {{order.sellerData.codeS}}</span>
        </div>

        <div class="column-right">
            <span class="buyer">{{getBuyerName(order)}}</span>
            <span class="sub-info-seller-buyer">Comprador {{order.price.observations.buyer>0? '- N° de cuenta '+order.price.observations.buyer: ''}}</span>
        </div>
    </div>

    <div class="row-features">
        <span class="product-name" [ngClass]="{'previus-value': productPreviousValue}">
            <img alt="product-icon" (error)="onErrorImg($event)" src="../../../../../assets/images/products/{{getProductName(order)}}.svg"/>
            {{getProductName(order)}}</span>
        <span class="tons">{{order.tons <= 0? '--': order.tons | numberPipe}} toneladas</span>
        
        <span *ngIf="!conditionHaveIndicators(order.price)" class="price to-price {{getTypeCoinStr(order).toLowerCase()}}"
        [ngClass]="{'previus-value': pricePreviousValue}">{{getTypeCoinAndPriceString(order)}}</span>
        <span class="delivery-period to-fix" *ngIf="conditionHaveIndicators(order.price)" 
        [ngClass]="{'previus-value': deliveryPeriodPreviousValue}">{{getRangeDateText(order)}}
        </span>
    </div>
</div>