import { Component, Input,AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';

const QUANT_MAX_OF_PARTICULARITIES:number=16;

@Component({
  selector: 'app-business-particularities',
  templateUrl: './business-particularities.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class BusinessParticularitiesComponent implements AfterViewInit, OnDestroy{
  @Input() fieldIdentificator:string='';
  showOptions:boolean=false;
  isOptionSelected:boolean=false;
  elementSelected:string='';
  placeHolder:string='Ingresar una particularidad';
  elementToGenerate:any;

  private priceSubscription!:Subscription;
  private orderSubscription!:Subscription;

  constructor(private store:Store<appState>, private priceV:PriceValidator) { 
  }

  ngAfterViewInit(): void {
    this.priceSubscription=this.store.select('price', 'generatePrice').subscribe(generatePrice=>{
      if(this.fieldIdentificator==fieldType.PRICE){
        this.elementToGenerate = generatePrice;
        this.getParticularities();
      }
    })

    this.orderSubscription=this.store.select('order').subscribe(order=>{
      if(this.fieldIdentificator==fieldType.ORDER){
        const orders=order.generateOrders;
        if(orders.length>1){
          this.elementToGenerate=orders[order.indexToEdit];
        } else {
          this.elementToGenerate = order.generateOrder;
        }
        this.getParticularities();
      } else if(this.fieldIdentificator==fieldType.VALIDATION){
        this.elementToGenerate =
          order.edit.ordersEdit[
            order.edit.indexToEdit
          ];
          this.getParticularities();
      } 
    });

  }
  
  ngOnDestroy(): void {
    this.priceSubscription?.unsubscribe();
    this.orderSubscription?.unsubscribe();
  }

  closeSlide(){
    this.showOptions=false;
  }

  getIsOptionSelected(): boolean {
    return this.isOptionSelected;
  }

  changeShowOptions() {
    this.showOptions = !this.showOptions;
  }

  setShowOptions(value: boolean) {
    this.showOptions = value;
  }

  verifyEnter(event:any){
    if(event.key.toLowerCase()=='enter' && !this.showOptions){
      this.setShowOptions(true);
    }
  }

  focusInInput(input:any){
    input.focus();
  }

  getParticularities(){
    try{
      const parts=this.priceV.getBusinessParticularities(this.getPrice().businessParticularities);
      if(parts.length>0){
        this.isOptionSelected=true;      
        this.elementSelected="Seleccionada " + parts.length + " particularidad";
      } else {
        this.elementSelected='';
        this.isOptionSelected=false;
      }
    } catch(err){
      this.elementSelected='';
      this.isOptionSelected=false;
    }
  }

  getPrice():Price {
    try{
      if (this.fieldIdentificator == fieldType.PRICE) {
        return this.elementToGenerate;
      } else {
        return this.elementToGenerate.price;
      }
    } catch (error) {
      const und:any=undefined;
      return und;
    }
  }


}
