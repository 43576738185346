import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import * as dict from 'src/app/shared/dict/dict';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Commission, Order } from 'src/app/shared/interfaces/order.interface';
import { setIndexToEdit, setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { DateChecker } from 'src/app/shared/date.checker';
import { User } from 'src/app/shared/interfaces/user.interface';

import { provinces } from 'src/app/shared/dict/provinces/provinces';
import { OrderValidator } from 'src/app/shared/order';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { buyers } from 'src/app/shared/dict/buyers';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { orderCategory, orderType } from 'src/app/shared/dict/orders';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { Subscription } from 'rxjs';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

@Component({
  template: '',
})

export class Details implements OnInit, OnDestroy {
  //If the price is exporter, more fields are displayed.
  //*ngIf are performed based on the value of isPort.
  @Input() isPort!: boolean;
  @Input() isSmallDetail: boolean = false;
  isOpenCommission: boolean = false;
  isOpenDuplicateOrder: boolean = false;
  identificatorOrderEdit: number = -1;
  disabledButton: boolean = true;

  public user!: User; //Store of Redux.
  public orders: Order[] = [];
  order!: any;
  price!: Price; //Is the price in the order.
  businessParticularities: string = '';

  AUX: any;
  SELLERS: any;
  PLACE_OF_ORIGIN: any;
  BROKERS: any;
  HOW_SELL: any;
  ONLY_SELL: any;
  DONT_SELL: any;
  BUYERS: any;
  PROVINCE: any;
  LOCALITIES: any;

  public ORDER_CATEGORY: any;
  public ORDER_TYPE: any;
  public PRODUCTS: any;
  public BUSINESS_TYPE: any;
  public PLACE_OF_DELIVERY: any;
  public ZONES: any;
  public PECULIARITIES: any;
  public QUALITY: any;
  public COIN_TYPE: any;
  public WAY_TO_PAY: any;
  public HARVEST: any;

  private user$!: Subscription;

  constructor(public store: Store<appState>, public dialog: MatDialog, 
    public date: DateChecker, public orderV: OrderValidator, public toFixV:ToFixValidator) {}

  ngOnInit(): void {
    this.setArrays();

    this.user$=this.store.select('usuario').subscribe(user => {
      this.user = user
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  toArray(obj: any) {
    const array = [];
    for (var i in obj) {
      array.push(obj[i]);
    }
    return array;
  }

  duplicateOrders(event: any) {
    this.openDuplicate();//To close the tooltip
  }

  openDuplicate() {
    //If not disabled, clicking can duplicate order    
    if (!this.disabledButton) {
      this.isOpenDuplicateOrder = !this.isOpenDuplicateOrder;
      if (this.isOpenDuplicateOrder) {
        this.isOpenCommission = false;
      }
    }
  }

  openCommission() {
    this.isOpenCommission = !this.isOpenCommission;
    if (this.isOpenCommission) {
      this.isOpenDuplicateOrder = false;
    }
  }

  closeCommission(event: any) {
    if (event == 'close') {
      this.isOpenCommission = false;
      if (this.isOpenDuplicateOrder) {
        this.isOpenDuplicateOrder = false;
      }
    }
  }

  closeDuplicate(event: any) {
    if (event == 'close') {
      this.isOpenDuplicateOrder = false;
      if (this.isOpenCommission) {
        this.isOpenCommission = false;
      }
    }
  }

  sendOrder() {
  }

  sendOrders() {
  }

  editOrder(index: number) {
    this.store.dispatch(setIndexToEdit({ indexToEdit: index }));

    this.store.dispatch(setOrderToGenerate({ order: this.orders[index] }));
  }


  private setArrays() {
    this.SELLERS = sellerWithData;
    this.PLACE_OF_ORIGIN = provinces;
    this.BROKERS = dict.broker;
    this.HOW_SELL = dict.howSell;
    this.BUYERS = buyers;

    this.ORDER_TYPE=orderType;
    this.ORDER_CATEGORY =orderCategory;
    this.BUSINESS_TYPE = typeBusiness;
    this.PRODUCTS = PRODUCT_NAMES;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.QUALITY = dict.quality;
    this.COIN_TYPE = dict.typeCoin;
    this.WAY_TO_PAY = wayPay;
  }

  getPlaceOfOrigin(order: Order) {
    return this.orderV.getPlaceOfOrigin(order.placeOfOrigin.province, order.placeOfOrigin.town);
  }

  getBuyer(buyer: any) {
    return this.orderV.getRestriction(buyer);
  }

  getWayPay(order:Order) {
    return this.orderV.getWayPay(order);
  }

  getBusinessParticularities(businessParticularities: BusinessParticularities){
    return this.orderV.getBusinessParticularities(businessParticularities);
  }

  getPesification(order:Order) {
    return this.orderV.getPesification(order);
  }

  //-------- ACTIONS WITH ORDERS ------------\\
  indexOfShowOrder:number=-1;
  showCommission:number=-1;
  deleteOrder(index:number){}

  //Edit order in parent component.
  duplicateOrder(index:number){}

  openOneCommission(index:number){
    this.showCommission=this.showCommission==index ? -1 : index;
  }

  setCommission(event:Commission, index:number){}

  showOrder(index:number){
    this.indexOfShowOrder= this.indexOfShowOrder==index ? -1 : index;
  }

  getArrowDirection(index:number){
    return getArrowDirectionByBool(this.indexOfShowOrder==index);
  }

  /*Given an order (of type Order or type OrderExchange)
  Returns true if it is the OrderExchange type, else returns false*/
  isOrderExchange(order:Order|OrderExchange){
    return order.hasOwnProperty('exchange');    
  }

  isOrderToFix(order:Order|OrderExchange){
    return this.toFixV.orderHaveIndicators(order);
  }

  sellerAndTons(order:Order|OrderExchange){
    return this.orderV.getSellerNameAndTons(order);
  }

  getConditionType(order:Order|OrderExchange){
    return this.toFixV.getConditionTypeByOrder(order);
  
  }
  getIndicators(order:Order|OrderExchange){
    return this.toFixV.getIndicatorsByOrder(order);
  }
}
