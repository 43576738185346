import { Component, OnChanges, OnInit } from '@angular/core';
import { isEntregaSiloBolsas } from 'src/app/shared/business-particularities';
import { SILO_BOLSAS_VOID, MARCA_SILO_BOLSAS, TAMAIN_SILO_BOLSAS } from 'src/app/shared/const/business-particularities';
import { SiloBolsas } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';


@Component({
  selector: 'app-entrega-silo-bolsas',
  templateUrl: './entrega-silo-bolsas.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class EntregaSiloBolsasComponent extends BusinessParticularityOption implements OnChanges {
  deliveryInSiloBolsas:SiloBolsas= SILO_BOLSAS_VOID;
  MARCA:any;
  TAMAIN:any;
  constructor() {
    super();
    this.MARCA=MARCA_SILO_BOLSAS;
    this.TAMAIN=TAMAIN_SILO_BOLSAS;
  }

  ngOnChanges(): void {
    try{
      this.deliveryInSiloBolsas=this.businessParticularitiesStruct.deliveryInSiloBolsas;
    } catch(err){
      this.deliveryInSiloBolsas=SILO_BOLSAS_VOID;
    }
  }

  setDeliveryInSiloBolsas(deliveryInSiloBolsas:SiloBolsas){
    this.deliveryInSiloBolsas=deliveryInSiloBolsas;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.deliveryInSiloBolsas=this.deliveryInSiloBolsas;
    this.emit2(newBP);
  }


  getDeliveriesAtGrassiPlant() {
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    return newBP.deliveryInSiloBolsas;
  }

  setQuantity(event:any){
    let valueToSet= event;
    this.setValue(valueToSet, 'quantity');
  }

  setValueWithEvent(event:any, tag:string){
    let valueToSet= event.element;
    this.setValue(valueToSet, tag);
  }

  setValue(number:number, tag:string){
    let newDelivery=JSON.parse(JSON.stringify(this.deliveryInSiloBolsas));
    newDelivery[tag]=number;
    this.setDeliveryInSiloBolsas(newDelivery);
  }

  isEnabled(){
    return isEntregaSiloBolsas(this.deliveryInSiloBolsas);
  }

}
