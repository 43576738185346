import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuplicateOrderComponent } from './duplicate-order.component';

@NgModule({
  declarations: [
    DuplicateOrderComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    DuplicateOrderComponent
  ]
})
export class DuplicateOrderSvgModule { }
