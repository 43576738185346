
import { environment } from 'src/environments/environment';
import {ErrorStruct, InfoQuotas, Options} from './../interfaces/options.interface';
import { orderInitialState, QUOTA_VOID } from './orders';
import { initialPrice } from './prices.const';

export const buyerType = {
    COMMODITIES:'commodities',
    BUYER: 'buyer',
    ALL: 'all'
}

export const conditionType = {
    TO_PRICE: 'to-price',
    TO_FIX: 'to-fix',
    ALL: 'all',
}

export const typeOfHistorical = {
    PRICE: 'price',
    ORDER: 'order',
    EXCHANGE: 'exchange',
}

export const sectionType = {
    CONTRACTS_IN_ERASERS: 'contracts-in-erasers',
    CONTRACTS_AUTORIZATES: 'contracts-autorizates',
}

export const divisionsControlPanel = {
    BUY_COMMODITIES: 'buy-commodities',
    SELL_COMMODITIES: 'sell-commodities',
    EXPORTS: 'exports',
}

export const fieldType = {
    PRICE: 'price',
    ORDER: 'order',
    EXCHANGE:'exchange',
    EXCHANGE_BUYER: 'exchange-buyer',
    VALIDATION: 'validation',
    ADD_BUYER: 'add-buyer',
}

export const BOARD_TYPES = {
    BOARD: 'Tablero',
    CARDS: 'Tarjetas',
}

export const typeOfModal = {
    ERASER: 1,
    ERROR: 2,
    CONTRACT: 3,
}

export const INFO_QUOTAS_VOID:InfoQuotas = 
{messageResponse: '', order: orderInitialState, quota: QUOTA_VOID, isErrorSolicitate: true, messageSolicitate: ''};

export const ERROR_VOID: ErrorStruct={
    isVisible: false,
    message: '',
    color: '#fff'
}

export const INITIAL_STATE_OPTIONS:Options={
    showMenu: true,
    location: 'dashboard',
    sizeView: 1366,
    date: '',
    hour: '',
    temp: 20,
    orderListIsVisible: true,
    objetivesListIsVisible: true,
    isChangeVisible: false, //On board of priceboard and orderList,
    isErrorVisible:false, //On board of priceboard and orderList,
    //Modal de creación de price/order/exchange
    modalCreate: {
        isLoading: false,
        isSuccess: false,
    },

    priceToObservationMessage: initialPrice,
    error: ERROR_VOID, //MODAL MESSAGE
    showErrorChangePrice: false, //On board of priceboard,
    warning: {
        price: {},
        isShow: false
    }, //On board of priceboard,
    deletePrice: {
        idPrice: '',
        isShow: false
    }, //On board of priceboard,
    goToInitForm:false, //When it is true, it takes us to the beginning of the form.
    message: '',
    identificatorFieldSelectedInForm: -1,
    isShowAddBuyer:false,
    isClickedInConfirm: false,
    infoQuotas: INFO_QUOTAS_VOID,
    infoIndicators: '',
    modalContract: {
        isShow: false, //Sirve para mostrar el modal
        arrayOfFaileds: [], //Sirve para mostrar los errores
        isIndicators: false, //Sirve para saber si los errores son de los indicadores
        arrayOfSuccess: [], //Sirve para mostrar los aciertos
        typeModal: 0, //Sirve para saber si son errores o aciertos.
    },
    isCaseSyngentaAgroAvc: false,
    isGenerateToFix: false,
    headerInMobile: '',
    isEditedOrderFromBoard: false,
    board: {
        search: '',
        boardSelected: BOARD_TYPES.BOARD,
        conditionTypeSelected: conditionType.TO_PRICE,
        conditionFilter: [1,2,3,4]
    }
};


export const OBJECT_ID_VOID:any ='000000000000000000000000';

export const VIEW_OF_MOBILE=600;


export function setViewMobile(sizeView: number) {
    return sizeView < VIEW_OF_MOBILE;
}

export function isProduction(){
    return environment.production;
}