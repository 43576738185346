import { Price, PriceString, RangeDate } from '../interfaces/price.interface';
import { BUSINESS_PARTICULARITIES_VOID } from './business-particularities';
import { WAY_PAY_VOID } from './wayPay';

export const observations = {
  gravanz: 'Gravanz',
  featuredPrice: 'Precios destacados',
  fixed: 'Fijaciones',
  comment: 'Comentarios',
};

export const PRICE_STATUS_CONST = {
  '-1': '--',
  NONE: 0,
  IDEA: 1,
  PRECIO: 2,
  PAUSADO: 3,
  RETIRADO: 4,
  INACTIVO: 5,
};

export const VARIATION_PERCENT = 0.05;

export const typeCoins = {
  USD: 'USD',
  ARS: 'ARS',
  ALL: 'ALL',
  NONE: '--',
};

export const priceTags = {
  observations: 'observations',
  isFeatured: 'isFeatured',
  isFixed: 'isFixed',
  isMessage: 'isMessage',
  message: 'message',
  isBusinessGravanz: 'isBusinessGravanz',
  isPort: 'isPort',
  buyer: 'buyer',
  status: 'status',
  productName: 'productName',
  typeBusiness: 'typeBusiness',
  placeOfDelivery: 'placeOfDelivery',
  zone: 'zone',
  port: 'port',
  deliveryPeriod: 'deliveryPeriod',
  startDate: 'startDate',
  endDate: 'endDate',
  quality: 'quality',
  price: 'price',
  typeCoin: 'typeCoin',
  typeDolar: 'typeDolar',
  wayPay: 'wayPay',
  wayPayName: 'wayPayName',
  wayPayDays: 'days',
  wayPayExpiration: 'expiration',
  wayPayPercentage: 'percentage',
  wayPayIsBusinessDays: 'isBusinessDays',
  wayPayOptions: 'options',
  expiration: 'expiration',
  bonus: 'bonus',
  reduction: 'reduction',
  pesificacion: 'pesificacion',
  harvest: 'harvest',
  orderDestination: 'orderDestination',

  idExchange: 'idExchange',

  orderType: 'orderType',
  orderCategory: 'orderCategory',
  businessParticularities: 'businessParticularities',
  qualityParticularities: 'qualityParticularities',
  dataOfcreation: 'dataOfcreation',
  hour: 'hour',
  grouperCode: 'grouperCode',
  priceId: 'priceId',
  
  createdAt: 'createdAt',
  wayPayIBM: 'wayPayIBM',
  qualityIBM: 'qualityIBM',

  //Syngenta
	isAVC: 'isAVC',
	subdivisionSyngenta: 'subdivisionSyngenta',
  campus:'campus',

  //Nutrien
  nroBilling: 'nroBilling',
};

export const RANGE_DATE_VOID:RangeDate = {
    startDate: '--',
    endDate: '--',
}

export const initialPrice: Price = {
  _id: 0,
  observations: {
    isFeatured: -1,
    isFixed: -1,
    isMessage: -1,
    message: ' ',
    isBussinesGravanz: -1,
    isPort: -1,
    buyer: -1,
  },
  status: -1,
  productName: -1,
  typeBusiness: -1,
  placeOfDelivery: {
    zone: -1,
    port: -1,
  },
  deliveryPeriod: RANGE_DATE_VOID,
  quality: -1,
  price: -1,
  typeCoin: '--',
  typeDolar: 1,
  wayPay: WAY_PAY_VOID,
  pesificacion: {
    startDate: '--',
    endDate: '--',
  },
  harvest: '--',
  priceId: 1,
  dataOfcreation: {
    nameOfcreator: '--',
    email: '--',
    teamOfcreator: {
      bossLastName: '--',
      bossName: '--',
      nroTeam: -1,
    },
    dateOfcreation: '--',
    hour: '--',
  },
  businessParticularities: BUSINESS_PARTICULARITIES_VOID,
  //qualityParticularities: 0,//QUALITY_PARTICULARITIES_VOID,
  grouperCode: 1,
  qualityIBM: -1,
  created_at: '',

  conditionType: 0,
  indicators: []
};

export const initialPriceString: PriceString = {
  observations: {
    isBussinesGravanz: false,
    isFeatured: false,
    isMessage: false,
    isPort: false,
    message: '',
  },
  buyer: [],
  typeBusiness: [],
  deliveryPeriod: { endDate: '', startDate: '', isExactly: false},
  expiration: [],
  harvest: [],
  hour: '',
  particularity: [],
  pesificacion: [],
  placeOfDelivery: {
    zone: [],
  },
  price: {
    typeCoin: 'ALL',
    start: 0,
    end: 0,
  },
  priceId: '',
  productName: [],
  quality: [],
  status: [],
  typeCoin: [],
  wayPayName: [],
};

export const translatePricesField={
  observations: 'Observaciones',
  isFeatured: 'Destacado',
  isFixed: 'Fijación',
  isMessage: 'Mensaje',
  message: 'Mensaje',
  isBusinessGravanz: 'Gravanz',
  isPort: 'Exportador',
  buyer: 'Comprador',
  status: 'Estado',
  productName: 'Producto',
  typeBusiness: 'Tipo de negocio',
  placeOfDelivery: 'Lugar de entrega',
  zone: 'Zona',
  port: 'Puerto',
  deliveryPeriod: 'Periodo de entrega',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de fin',
  quality: 'Calidad',
  price: 'Precio',
  typeCoin: 'Tipo de moneda',
  typeDolar: 'Tipo de dólar',
  wayPay: 'Forma de pago',
  wayPayName: 'Forma de pago',
  wayPayDays: 'Días',
  wayPayExpiration: 'Vencimiento',
  wayPayPercentage: 'Porcentaje',
  wayPayIsBusinessDays: 'Días hábiles',
  wayPayOptions: 'Opciones',
  harvest: 'Cosecha',
  pesificacion: 'Pesificación',
  businessParticularities: 'Particularidad de negocio',
  qualityIBM: 'Particularidad de calidad',
  qualityParticularities: 'Particularidad de calidad',
  grouperCode: 'Código de agrupador',
  priceId: 'Id de precio',
  dataOfcreation: 'Datos de creación',
  nameOfcreator: 'Nombre del creador',
  email: 'Email',
  teamOfcreator: 'Equipo del creador',
  bossLastName: 'Apellido del jefe',
  bossName: 'Nombre del jefe',
  nroTeam: 'Número de equipo',
  dateOfcreation: 'Fecha de creación',
  hour: 'Hora',
  createdAt: 'Fecha de creación',
  conditionType: 'Tipo de condición',
  indicators: 'Indicadores',
}