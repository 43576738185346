import { Component, Input,OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setPriceToGenerateInOrder } from 'src/app/redux/actions/order.action';
import { setPriceDetails, showPriceDetails } from 'src/app/redux/actions/price.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { areas } from 'src/app/shared/const/user.const';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';
export const statusOfPrice:any= {
  1:'idea-price',
  2:'price-price',
  3:'paused-price',
  4:'retired-price',
  5:'inactive-price',
}
@Component({
  selector: 'app-table-mobile-price',
  templateUrl: './mobile-price.component.html',
  styleUrls: ['./mobile-price.component.scss', './../mobile-board.scss']
})
export class MobilePriceComponent implements OnInit, OnDestroy {
  private isComercial:boolean=false;
  public productName='';
  public PRODUCTS:any;
  @Input() prices!: any[];
  user$!:Subscription;

  constructor(private store:Store<appState>, private router:Router, private priceV:PriceValidator) {}

  ngOnInit(): void {
    this.productName=this.getProductName(this.prices[0]) ?? '';
    this.PRODUCTS=PRODUCT_NAMES;

    this.user$=this.store.select('usuario').subscribe(user=>{
      this.isComercial=user.area==areas.comercial;
    })
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  goToOrderBoard(price:Price){
    if(this.isComercial){
      this.store.dispatch(setPriceToGenerateInOrder({ price: price }));
      this.router.navigate(['/'+NAVEGATION.GENERATE_PRICE_BOARD_ORDER]);
    } else {
      this.store.dispatch(showPriceDetails({ show: true }));
      this.store.dispatch(setPriceDetails({price: price}));
    }
  }

  getProductName(price:Price){
    return this.priceV.getProductName(price);
  }

  getQuality(price:Price){
    return this.priceV.getQuality(price);
  }

  getPlaceOfDelivery(price:Price){
    return this.priceV.getPlaceOfDelivery(price.placeOfDelivery);
  }

  getTypeCoinAndPrice(price:Price){
    return this.priceV.getTypeCoinAndPriceWithPoints(price);
  }

  getStatusOfPrice(price:Price){
    return getStatusOfPrice(price)
  }
}
export function getStatusOfPrice(price:Price, statusDict=statusOfPrice){
  return statusDict[price.status] ?? '';
}