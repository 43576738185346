import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityParticularitiesComponent } from './quality-particularities.component';
import { FormsModule } from '@angular/forms';
import { QualityParticularitiesSlideCardModule } from '../../slide-cards/quality-particularities/quality-particularities.module';

@NgModule({
  declarations: [
    QualityParticularitiesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    QualityParticularitiesSlideCardModule
  ],
  exports: [
    QualityParticularitiesComponent
  ]
})
export class QualityParticularitiesModule { }
