import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormOrderComponent } from './form.component';
import { FieldsModule } from '../../fields/fields.module';
import { ModalModule } from '../../dialogs/modal.module';

@NgModule({
  declarations: [
    FormOrderComponent
  ],
  imports: [
    CommonModule,
    FieldsModule,
    ModalModule
  ], exports:[
    FormOrderComponent
  ]
})
export class FormOrderModule { }
