<div class="selects">
    <button class="commodities column-center" [ngClass]="{ selected: buyerTypes.COMMODITIES == buyerTypeSelected }"
      (click)="setBuyerType(buyerTypes.COMMODITIES)">
      Commodities
    </button>
    <button class="buyer column-center" [ngClass]="{ selected: buyerTypes.BUYER == buyerTypeSelected }"
      (click)="setBuyerType(buyerTypes.BUYER)">
      Compradores
    </button>
    <button class="all column-center" [ngClass]="{ selected: buyerTypes.ALL == buyerTypeSelected }"
      (click)="setBuyerType(buyerTypes.ALL)">
      Ver todos
    </button>
    <div class="rectangle green {{ buyerTypeSelected }}"></div>
</div>