import { Action, createReducer, on } from "@ngrx/store";
import { Login } from "src/app/shared/interfaces/login.interface";
import * as actions from '../actions/login.action';

export const initialState: Login = {
    showError: false,
    error: '',
    splashInTransition: true,
};

const _loginReducer = createReducer(
    initialState,
    on(actions.hiddenSplash, (state, action) => {
        return {
            ...initialState,
            splashInTransition: false,
        };
    }),
    on(actions.showSplash, (state, action) => {
        return initialState;
    }),

    on(actions.loginFailed, (state, action) => {
        return {
            showError: true,
            error: action.message,
            splashInTransition: false,
        };
    }),

)


export function loginReducer(state: any, action: Action) {
    return _loginReducer(state, action);
}