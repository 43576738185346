<section class="body">
    
    <app-buttons-condition-type></app-buttons-condition-type>

    <div class="inform">
        <div class="form-void" *ngIf="quantOrdersToGenerate>1"></div>
        <app-form-exchange class="form"  *ngIf="formVisible"></app-form-exchange>

        <button *ngIf="formVisible" [disabled]="disabledButton" [ngClass]="{'disabled': disabledButton}" class="filled-button not-visible-in-desktop"
        (click)="showDetails(true)">
            Aplicar
        </button>

        <app-details-exchange *ngIf="detailsVisible" class="details"></app-details-exchange>
        <img *ngIf="detailsVisible" (click)="showDetails(false)" class="button-edit" alt="button-edit" src="../../../../../assets/images/extra/button-edit.svg"/>
        
        <button *ngIf="detailsVisible" [disabled]="disabledButton" [ngClass]="{'disabled': disabledButton}" class="filled-button not-visible-in-desktop"
        (click)="openModal()">
            Confirmar
        </button>
    </div>
</section> 