import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDeferralComponent } from './info-deferral.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';



@NgModule({
  declarations: [
    InfoDeferralComponent
  ],
  imports: [
    CommonModule,

    CrossSvgModule
  ],
  exports: [
    InfoDeferralComponent
  ]
})
export class InfoDeferralModule { }
