import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'profile-svg',
  templateUrl: './profile-svg.component.html',
  styleUrls: ['../../svg.scss']
})
export class ProfileSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
