import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edit-svg',
  templateUrl: './edit-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class EditSvgComponent implements OnInit {
  @Input() active:boolean=false;
  @Input() size:number=20;
  public style={
    width: '14px',
    height: '14px',
  }
  constructor() { }

  ngOnInit(): void {
    this.style.width=this.size+'px';
    this.style.height=this.size+'px';
  }
}
