<div class="price column-left gap-4px" (click)="goToGenerateOrder()">
    <div class="title row-between width-100">
        <span class="product-name letter-bold">{{getProductName(price)}}</span>
        
        <featured-price-svg class="icon img" [active]="true" [tooltip]="false"></featured-price-svg>
    </div>

    <span class="zone">{{getZoneName(price)}}</span>
    <span class="price-text letter-bold">{{getTypeCoinAndPrice(price)}}</span>
    <span class="fecha">{{getMonth(price.deliveryPeriod.startDate)}}</span>
    <span class="tipo-negocio">{{getTypeBusiness(price)}}</span>
</div>