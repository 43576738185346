import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonInputComponent } from './radio-buttons.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';



@NgModule({
  declarations: [
    RadioButtonInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule
  ], exports:[
    RadioButtonInputComponent
  ]
})
export class RadioButtonInputModule { }
