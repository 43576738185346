
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setOrderExchangeDetails, showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';

@Component({
  selector: 'app-table-mobile-exchange',
  templateUrl: './mobile-exchange.component.html',
  styleUrls: ['./../mobile-board.scss']
})
export class MobileExchangeComponent implements OnInit {
  @Input() orders!: OrderExchange[];
  productName:string='';
  
  constructor(private store:Store<appState>, private router:Router, 
    private orderV:OrderValidator, private orderExcV:OrderExchangeValidator) {}

  ngOnInit(): void {
    this.productName=this.orderV.getProductName(this.orders[0]);
  }

  showDetails(order:OrderExchange){
    this.store.dispatch(setOrderExchangeDetails({ order: order }));
    this.store.dispatch(showOrderExchangeDetails({ show: true }));
  }

  getSellerName(order:OrderExchange){
    return this.orderExcV.getSellerOfExchange(order)
  }

  getExchangerName(order:OrderExchange){
    return this.orderV.getSellerName(order);
  }

  getTypeCoin(order:OrderExchange){
    return this.orderExcV.getTypeCoin(order);
  }

  getPrice(order:OrderExchange){
    return this.orderExcV.getPrice(order);
  }

  getTons(order:OrderExchange){
    return order.exchange.tons > 0 ? order.exchange.tons : '--';
  }
}