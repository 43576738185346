import { BonusOrReduction, Indicator } from '../interfaces/price-to-fix.interface';
import { priceTags, RANGE_DATE_VOID } from './prices.const';

export const B_OR_R_VOID:BonusOrReduction = {
    value: 0,
    type: '',
}

export const INDICATOR_VOID:Indicator = {
    enabled: true,
    fixationPeriod: RANGE_DATE_VOID,
    indicatorName: -1,
    bonification: B_OR_R_VOID,
    quality: 0,
    monthAndYear: {month: 0, year: 0, isPartial: false},
    location: -1,
    proporcionality: false,
    propMinOrMax: 0,
    propPeriod: 0,
    propQuantDivs: 0,

    max: 100,
    min: 0,
    reduction: B_OR_R_VOID,
    product: 0,
    percentageProduct: 0
}

export const priceTagsToFix={
    ...priceTags,
    conditionType: 'conditionType',
    indicators: 'indicators',
}

export const indicatorsTags={
    enabled: 'enabled',
    fixationPeriod: 'fixationPeriod',
    indicatorName: 'indicatorName',
    forwardPeriod: 'forwardPeriod',
    monthAndYear: 'monthAndYear',
    location: 'location',
    quality: 'quality',
    max: 'max',
    min: 'min',
    bonification: 'bonification',
    reduction: 'reduction',
    value: 'value',
    type: 'type',
    product: 'product',
    percentageProduct: 'percentageProduct',
    proporcionality: 'proporcionality',
    propMinOrMax: 'propMinOrMax',
    propPeriod: 'propPeriod',
    propQuantDivs: 'propQuantDivs',
    
}

export const INDICATORS_TO_FIX_CONST={
    MERC_COMP_DISP: 1,
    MERC_COMP_GEN: 2,
    PIZ_DISP: 3,
    FORW_COMP: 4,
    FORW_GEN: 5,
    MATBA: 6,
    CME: 7,
    CME_EN_MATBA: 8,
    DISP_MATBA: 9
}

export const CONDITIONS_TYPE_TO_FIX_CONST={
    TRADICIONAL: 1,
    PASE_COSECHA: 2,
    PORCENTAJE_PRODUCTO: 3,
    SIN_ESPACIO: 4
}

export const PLACES_TO_FIX_CONST = {
    ROSARIO: 5,
    UP_RIVER: 90001,
    ROSARIO_NORTE: 1500,
    ROSARIO_SUR: 1501,
    BAHIA_BLANCA: 1,
    NECOCHEA: 132,
    DARSENA: 22
}