import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NAVEGATION, Navegation } from 'src/app/shared/const/navegation';
import { areas } from 'src/app/shared/const/user.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss']
})
export class MenuFooterComponent implements OnInit, OnDestroy {
  isComercial:boolean=false;
  public NAVEGATION:Navegation;
  public location:string="";


  private userSubscription!:Subscription;
  private optionsSubscription!:Subscription;
  constructor(private router:Router, private store:Store<appState>) {
    this.NAVEGATION=NAVEGATION;
  }

  ngOnInit(): void { 
    this.userSubscription=this.store.select('usuario').subscribe(usuarioR => {
      this.isComercial=usuarioR.area==areas.comercial;
    });

    this.optionsSubscription=this.store.select('options').subscribe(optionsR=>{
      this.location=optionsR.location;
    })
  }

  ngOnDestroy(): void {
    try{
      this.userSubscription.unsubscribe();
      this.optionsSubscription.unsubscribe();
    } catch(err){}
  }

  goTo(location:string){
    const url = "/" + location;
    this.router.navigate([url]);
  }
}
