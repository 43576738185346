import { Component, Input } from '@angular/core';

@Component({
  selector: 'plus-sign-svg',
  templateUrl: './plus-sign.component.html',
  styleUrls: ['./../../svg.scss']
})
export class PlusSignComponent {

  @Input() active:boolean=false;
  constructor() { }
}
