import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { ToFixValidator } from 'src/app/shared/to-fix';

@Component({
  selector: 'app-indicators-list',
  templateUrl: './indicators-list.component.html',
  styleUrls: ['./indicators-list.component.scss']
})
export class IndicatorsListComponent {
  @Input() indicators: Indicator[]=[];
  @Output() edit= new EventEmitter<{indicator:Indicator, index:number}>();
  @Output() delete= new EventEmitter<Indicator>();
  constructor(private toFixV:ToFixValidator){}
  
  getFixedPeriod(indicator:Indicator){
    return this.toFixV.getFixedPeriod(indicator);
  }
  
  getIndicatorName(indicator:Indicator){
    return this.toFixV.getIndicatorName(indicator);
  }
  
  editIndicator(indicator:Indicator, index:number){
    this.edit.emit({indicator, index});
  }

  deleteIndicator(indicator:Indicator){
    this.delete.emit(indicator);
  }

}
