import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isGenerateEraser } from 'src/app/redux/actions/order.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { areas } from 'src/app/shared/const/user.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';

@Component({
  selector: 'app-orders-validation',
  templateUrl: './orders-validation.component.html',
  styleUrls: ['../../shared/styles/body.scss']
})
export class OrdersValidationComponent implements OnInit, OnDestroy {
  ordersSelectedValidation:Order[]=[];
  isComercial:boolean=false;

  private order$!:Subscription;
  private user$!:Subscription;
  constructor(private store:Store<appState>, private router:Router) {
    this.order$=this.store.select('order','edit', 'ordersEdit').subscribe(ordersEdit=>{
      this.ordersSelectedValidation=ordersEdit;
    })

    this.user$=this.store.select('usuario').subscribe(userR=>{
      this.isComercial=userR.area==areas.comercial;
    })
   }

  ngOnInit(): void {
    if(this.ordersSelectedValidation.length<=0 || this.isComercial){
      const url = "/"+NAVEGATION.ORDER_BOARD;
      this.router.navigate([url]);
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(isGenerateEraser({isGenerateEraser:true})) //Siempre la dejamos seteada en true;
    this.order$?.unsubscribe();
    this.user$?.unsubscribe();
  }

}