import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ValidatorChecker, parseFloatPoint } from 'src/app/shared/validator.checker';
import { InputE } from '../input';

@Component({
  selector: 'number-input',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss','../input.scss']
})
export class NumberComponent extends InputE implements OnInit {
  @Input() isWithPoint=true;
  @Input() step='none';
  @Input() min=0;
  @Input() max='none';
  @Input() isDisabled=false;
  isFocus:boolean=false;
  @Input() showCero=false;
  @Input() showAuxiliarText=false;
  @Input() auxiliarText='%';
  auxiliarField='';
  @Output() focus = new EventEmitter<boolean>();

  constructor(private validator: ValidatorChecker) {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if(this.showAuxiliarText){
      this.auxiliarField=this.getValue().toString();
    }
  }

  copyData(event:any, inputNumber:any){
    this.auxiliarField=inputNumber.value;
  }

  nextIsFirstDecimal:boolean=false;
  validateInputNumber(event:any, inputNumber:any){
    if(event.key.toLowerCase()=='enter'){
      return true;
    } else {  
      const selectedText = window.getSelection()?.toString();
      const valueWithoutText = inputNumber.value.replace(selectedText, '');
      const number= valueWithoutText=='-'? 0: Number(this.parseComma(valueWithoutText));
      //Si es un guion, debe ser el primer caracter y que el min sea menor a 0
      if(event.charCode==45){
        return !(valueWithoutText!='' || this.min>=0);
      }

      /*set true if it belongs to a point or a comma*/
      const numberBetweenMinAndMax=this.isNumberBetweenMinAndMax(valueWithoutText, event.key, this.nextIsFirstDecimal);
    
      //La coma solo se puede agregar si el input no esta vacio
      this.nextIsFirstDecimal=event.charCode == 46 || event.charCode==44;

      //Only accept numbers or point
      return this.validator.onlyNumbersAndPoint(event.charCode) && 
      this.validator.getDecimalPlaces(number) < (this.isWithPoint?3:1) && 
      numberBetweenMinAndMax;
    }
  }
  
  isNumberBetweenMinAndMax(number:any, addNum:any, isDecimal:boolean){
    const numberAux= this.parseComma(number);
    let value=Number(numberAux + addNum); //'1'+'1'='11'
    return addNum=='.' || addNum==',' || (value>= Number(this.min) && (this.max=='none' || value<=Number(this.max)));
  }

  override select(event:any){
    const number=Number( this.parseComma(event.target.value) );
    this.emit(number);
    this.setValue(number);
  }

  emitFocus(){
    this.isFocus=!this.isFocus;      
    this.focus.emit(this.isFocus);
  }

  override getValue() {
    return this.value<=0? (this.showCero? parseFloatPoint(0): ''): parseFloatPoint(this.value);
  }

  override getIsInputCompleted() {
    return this.value > 0 || (this.showCero && this.value==0); 
  }

  parseComma(value:any){
    return value.toString().replace(',','.');
  }
}