<div class="body" #card class="{{isToFix? 'card-to-fix': 'card-to-price'}}" [ngClass]="{'displaced':historical }">
    <div class="details-container width-100" *ngIf="!showCompleteState">
        <div class="header">
            <span class="add-buyer title">{{getTitle(order)}}</span>
            <cross-svg class="cross" (click)="close()"></cross-svg>
        </div>
        
        <div class="state-container width-100 row-between">    
            <p class="description state-text">Estado de la orden: <span class="state {{getStatusOrder(order).toLowerCase()}}">{{getStatusOrder(order)}}</span></p>
            <button class="filled-button change-state" (click)="setShowChangeState(true)" 
            *ngIf="showButtonChangeState()">Cambiar estado</button>
        </div>
        <p class="description">Orden creada por 
            <span class="stroke">{{nameOfCreator}}</span> el día 
            <span class="stroke">{{dateOfCreation}}</span> a las 
            <span class="stroke">{{hourOfCreation}}</span> y
            <span class="stroke">{{getHourOfConfirmation(order)}}</span>.</p>


        <div class="container-slides row-between width-100" [ngClass]="{'one-item': order.statusOrder != STATUS_ORDER_CONST.CONTRACT}">
            <div class="buttons-slides" *ngIf="order.statusOrder == STATUS_ORDER_CONST.CONTRACT">
                <span class="last version" [ngClass]="{'selected': versionSelected=='last'}" (click)="setVersionSelected('last')">Última versión</span>
                <span class="first version" [ngClass]="{'selected': versionSelected=='first'}" (click)="setVersionSelected('first')">Primera versión</span>
                <div class="rectangle green {{versionSelected}}"></div>
            </div>

            <clock-svg class="pointer" (click)="showHistorical(order)"></clock-svg>
        </div>

        <span *ngIf="showErrorFirstVersion">No se puede cargar la primera versión. Por favor, reintente.</span>
        
        
        <div class="orders-description" *ngIf="!isDisable(order) || order.statusOrder==-1">
                <div class="row-between visible-in-mobile first-line">
                    <span class="first-description">Orden a precio</span>
                    <edit-svg class="edit-icon" [size]="20" (click)="editOrder()" *ngIf="!isComercial && (isOrderConfirm() || isOrderAutorizate() ) && versionSelected=='last'"></edit-svg>
                </div>
                <app-details-small *ngIf="versionSelected=='last'" [order]="order" [price]="order.price" [isToFix]="isToFix" [arrayMods]="firstVersion? firstVersion.arrayMods:[]" (goTo)="goTo($event)"></app-details-small>

                <app-details-small *ngIf="versionSelected=='first'" [order]="firstVersion" [price]="firstVersion? firstVersion.price: firstVersion" [isToFix]="isToFix" (goTo)="goTo($event)"></app-details-small>
        </div>

        <button class="not-filled-button generate-eraser visible-in-mobile" (click)="sendToTickets()" *ngIf="!isComercial && isOrderConfirm()">
            Generar borrador
        </button>

        <button class="filled-button generate-eraser visible-in-mobile" (click)="autorizate()" *ngIf="!isComercial && isOrderAutorizate()">
            Autorizar
        </button>

        <div class="complete-data-container not-visible-in-desktop">
            <div class="background-complete-data"></div>

            <button *ngIf="isOrderReturned()" class="complete-data not-filled-button"
            (click)="reingresateOrder(order)">
                Reingresar orden
            </button>
        </div>

    </div>


    <!--Only visible in mobile-->
    <div class="complete-state-container column-between width-100" style="height: 100%;" *ngIf="showCompleteState">
        <div class="column gap-8 width-100">

            <div class="header">
                <span class="add-buyer title">Cambiar estado</span>
                <cross-svg class="cross" (click)="setShowChangeState(false)"></cross-svg>
            </div>
    
            <p class="description" >Seleccioná un estado para aplicar en la orden de venta y enviarla al comericial.</p>
            
            <div class="status-container column gap-16">
                <div class="row-between check-order status-row" (click)="setStatusSelected(STATUS_ORDER_CONST.CHECK)">
                    <div class="row-between gap-8">
                        <div class="circle"></div>
                        <span>Vista</span>
                    </div>
                    <input type="radio" name="status" [checked]="statusSelected==STATUS_ORDER_CONST.CHECK">
                </div>
    
                <div class="row-between oferted-order status-row" (click)="setStatusSelected(STATUS_ORDER_CONST.OFERTED)">
                    <div class="row-between gap-8">
                        <div class="circle"></div>
                        <span>Ofertada</span>
                    </div>
                    <input type="radio" name="status" [checked]="statusSelected==STATUS_ORDER_CONST.OFERTED">
                </div>
                
                <div class="row-between status-row" (click)="setStatusSelected(STATUS_ORDER_CONST.SEND_TO_BROKE)">
                    <div class="row-between gap-8 send-to-broker-order">
                        <div class="circle"></div>
                        <span>Enviado a bolsa</span>
                    </div>
                    <input type="radio" name="status" [checked]="statusSelected==STATUS_ORDER_CONST.SEND_TO_BROKE">
                </div>
    
                <div class="row-between status-row" (click)="setStatusSelected(STATUS_ORDER_CONST.CONFIRM)">
                    <div class="row-between gap-8 confirmed-order">
                        <div class="circle"></div>
                        <span>Confirmada</span>
                    </div>
                    <input type="radio" name="status" [checked]="statusSelected==STATUS_ORDER_CONST.CONFIRM">
                </div>
    
                <div class="row-between status-row" (click)="setStatusSelected(STATUS_ORDER_CONST.RETURNED)">
                    <div class="row-between gap-8 return-order">
                        <div class="circle"></div>
                        <span>Devuelta</span>
                    </div>
                    <input type="radio" name="status" [checked]="statusSelected==STATUS_ORDER_CONST.RETURNED">
                </div>
    
                <div class="row-between status-row" (click)="setStatusSelected(STATUS_ORDER_CONST.OUT_OF_MARKET)">
                    <div class="row-between gap-8 out-of-market-order">
                        <div class="circle"></div>
                        <span>Fuera de mercado</span>
                    </div>
                    <input type="radio" name="status" [checked]="statusSelected==STATUS_ORDER_CONST.OUT_OF_MARKET">
                </div>
    
                <text-input [initialPlaceHolder]="'Ingresar el mensaje'" [value]="messageReturned" 
                (valueOut)="setMessageReturned($event)" *ngIf="statusSelected==STATUS_ORDER_CONST.RETURNED"></text-input>
            </div>
        </div>

        <!--<button class="not-filled-button" style="margin-top:64px;" [disabled]="isDisabledButtonStatus()"  
        [ngClass]="{'disabled':isDisabledButtonStatus()}"
        (click)="applyNewStatus()">
            Aplicar
        </button>-->
    </div>
    
</div>