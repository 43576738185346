import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { howSellValidationInitialState, orderTags } from 'src/app/shared/const/orders';
import { howSell } from 'src/app/shared/dict/dict';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';
import {ValidationStruct} from 'src/app/shared/interfaces/order.interface';
import { HOW_SELL_CONST } from 'src/app/shared/const/how-sell';
import { fieldType } from 'src/app/shared/const/options';
import { OrderService } from 'src/app/shared/services/order.service';
import { Subscription } from 'rxjs';
import { HowSellValidation, ResponseHowSell, ResponseRucaAndSisa, RucaAndSisa } from 'src/app/shared/interfaces/DataIBM';
import { setHowSellValidation, setRucaAndSisa } from 'src/app/redux/actions/order.action';
import { itCanBeHowSell } from 'src/app/shared/business-validation';
import { getHowSellIBM } from 'src/app/shared/functions-ibm/contract.checker';


@Component({
  selector: 'app-how-sell',
  templateUrl: './how-sell.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class HowSellComponent extends Field implements OnInit, OnDestroy {
  @Input() isExchanger!:boolean;
  @Input() rucaAndSisa!: RucaAndSisa;
  @Input() howSellValidation!:HowSellValidation;
  /*The value of the 3 validation structures is only used for orders.
  For exchange it is not necessary because we know that the only possible value is 'Acopio' or 'Producción propia'*/

  @Input() inProcessInvalid:boolean=false;
  @Input() inProcessRucaAndSisa:boolean=false;
  @Input() isInvalidRucaAndSisa:boolean=false;
  @Input() failedConnection:boolean=false;

  isCaseSyngentaAgroAvc: boolean = false;

  private orderHowSell$!: Subscription; 
  private isSyngenta$!: Subscription;

  constructor(store: Store<appState>, private orderSvc:OrderService) {
    super(store);
    
    this.initalElementSelected = 'Ingresar cómo vende';
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    this.tag = orderTags.howSell;
    this.setIdentifyClassName();
    this.OBJECT=howSell;
    
    this.orderHowSell$=this.store.select('order').subscribe(order => {
      this.array=this.getArray(this.isExchanger);
    })

    this.isSyngenta$= this.store.select('options').subscribe(options => {
      this.isCaseSyngentaAgroAvc = options.isCaseSyngentaAgroAvc;
    })

    this.array = this.getArray();
  }

  override ngOnDestroy(): void {
    this.unsubscribe();
    this.isSyngenta$?.unsubscribe();
    this.orderHowSell$?.unsubscribe();
  }

  override setIncomplete(boolean: boolean): void {
    if(this.isExchanger){
      this.isIncomplete=false;
    } else {
      this.isIncomplete = boolean && !this.inProcessInvalid;
    }
  }
  
  override rememberValueInForm(){
    try {
      //this.rememberValueTypeOne();
      let valueToSet: any = this.rememberValue();
      //If There is a value to remember
      if (valueToSet != -1 && this.elementToGenerate && valueToSet) {
        this.elementSelected = this.OBJECT[valueToSet];
        this.search = this.elementSelected;
        this.indexOption = this.getIndexOfArray(valueToSet, this.array);
        this.setIsOptionSelected(true);

        //Si es exchanger, lo unico posible es que sea ACOPIO;
        if(valueToSet!=HOW_SELL_CONST.ACOPIO && this.isExchanger && this.fieldIdentificator==fieldType.ORDER){
          this.setElement(HOW_SELL_CONST.ACOPIO,1);
          this.resetIncompletes();
        }
        /* else {
          //this.checkValidations(valueToSet);
        }*/

      } else if( valueToSet == -1){
        this.elementSelected = '';
        this.search = '';
        this.indexOption = -1;
        this.setIsOptionSelected(false);
      }
    } catch (err) {}
  }

  //If you are an exchange seller, the only possible value is 'Acopio'.
  //If you are generating a counterpart, the possible values ​​are 'Storage' and 'Own production'
  //If it is not an exchange, it can be any of the options
  getArray(isExchanger:boolean|null=null){
    if(isExchanger){
        return [HOW_SELL_CONST.ACOPIO]; //Si es exchanger, lo unico posible es que sea ACOPIO;
    } else if(this.fieldIdentificator==fieldType.EXCHANGE || this.isCaseSyngentaAgroAvc){
      return [HOW_SELL_CONST.ACOPIO, HOW_SELL_CONST.PRODUCCION_PROPIA]; //Si es de canje o caso syngenta agro avc, solo puede ser acopio o produccion propia
    }

    let array= Object.keys(howSell); //Si no es de canje, puede ser cualquiera de las opciones
    array.shift();
    if(this.OBJECT[array[array.length - 1]] == '--'){
      array.pop();
    }
    return array;
  }

  override setElement(element:any, index:number){
    if(element != this.elementToGenerate.howSell){
      this.resetIncompletes();
      this.setElementToGenerate(Number(element));
    }
  }


  getMessageInvalidRucaAndSisa(){
    if(this.rucaAndSisa.ExisteRuca=='N'){
      return 'No existe en el Ruca';
    } else if(this.rucaAndSisa.ExisteSisa=='N'){
      return 'No existe en el Sisa';
    } else if(this.rucaAndSisa['Estado SISA']==0){
      return 'Código 0 en el Sisa'
    }
    return '';
  }

  resetIncompletes(){
    this.isIncomplete=false;
    this.isInvalid=false;
    this.isInvalidRucaAndSisa=false;
  }
}

