import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Store } from '@ngrx/store';
import {
  ViewOrder,
  ViewOrderExchange,
  ViewPrice,
  ViewsSelected,
} from 'src/app/shared/interfaces/views.interface';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { getOfLocalStorage } from 'src/app/shared/data.checker';
import { MAP_LOCAL_STORAGE_VIEW_NAME, VIEWS_SELECTED_CONST } from 'src/app/shared/const/views.const';
import { User } from 'src/app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';
import {
  REPRESENTATION_CONST_ARRAY,
  areas,
} from 'src/app/shared/const/user.const';
import { searchViewInDB } from 'src/app/redux/actions/views.action';
import { PriceString } from 'src/app/shared/interfaces/price.interface';
import { OrderString } from 'src/app/shared/interfaces/order.interface';

@Component({
  template: '',
})
export class ViewsSectionBasic implements OnInit, OnDestroy {
  @Input() isHistorical: boolean = false; //Determines the identificator of the view to be saved in the LocalStorage
  @Input() isShowAddView: boolean = true;

  views: ViewPrice[] | ViewOrder[] | ViewOrderExchange[] = []; //It is extracted from the LocalStorage
  isNewView: boolean = false; //Determines whether or not clicked to create a new view
  editViewIdentificator: number = -1; //Determines the index of the view to edit.
  viewSelected!: ViewPrice | ViewOrder | ViewOrderExchange;
  VIEW_ALL: ViewPrice | ViewOrder | ViewOrderExchange = {} as ViewPrice | ViewOrder | ViewOrderExchange;

  user!: User; //Store de redux
  isComercial: boolean = false;
  isRepresentation: boolean = false;


  protected user$!: Subscription;
  protected views$!: Subscription;
  protected viewSelected$!: Subscription;
  protected index$!: Subscription;

  constructor(public store: Store<appState>) {
    this.user$ = this.store.select('usuario').subscribe((userR) => {
      this.user = userR;
      this.isComercial = this.user.area == areas.comercial;
      this.isRepresentation = REPRESENTATION_CONST_ARRAY.includes(
        this.user.workUnit
      );
    });
  }

  ngOnInit(): void {
    this.rememberViewsOfLocalStorage();
    this.setSubscriptions();
    this.rememberViewsSelectedOfLocalStorage(this.getViewSelectedConst());

    this.store.dispatch(searchViewInDB({ email: this.user.email, 
        name: MAP_LOCAL_STORAGE_VIEW_NAME[this.getLocalStorageName()]}));
    
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.views$?.unsubscribe();
    this.viewSelected$?.unsubscribe();
    this.index$?.unsubscribe();
  }

  /**
   * Store subscriptions are set
   */
  setSubscriptions() {}

  /**
   * It takes the views, remembers through an object stored in the
   * local storage which was the last one selected and sets it in the store.
   */
  rememberViewsOfLocalStorage() {}

  /**
   * It is used to remember the views selected from the local storage
   * @returns "priceBoard" | "orderBoard" | "orderExchangeBoard" | "historicalBoard"
   */
  getViewSelectedConst(): 'priceBoard' | 'orderBoard' | 'orderExchangeBoard' | 'historicalBoard' {
    return VIEWS_SELECTED_CONST.PRICE_BOARD;
  }

  /**
   * It is used to remember the name from view save in local storage
   * @returns string
   */
  getLocalStorageName():string{
    return ''
  }

  /**
   * Used to select a default view
   * @returns
   */
  selectViewDefault() {}

  /**
   * Change a view previus create
   * @param index
   * @returns
   */
  changeView(index: number) {
    this.closeAllViews();   //We close any open order.
    this.setViewSelected(this.views[index]);
    this.setFilter(this.getFilter(this.views[index]));
    this.setViewSelectedInLocalStorage(this.views[index], index, this.isHistorical, this.getViewSelectedConst());
  }
  
  setViewAll(views:ViewPrice[] | ViewOrder[] | ViewOrderExchange[][]){}
  
  setIndexViewSelected(index:number){}
  
  setViewSelected(view:ViewPrice | ViewOrder | ViewOrderExchange){}

  setFilter(filter:OrderString | PriceString | null){}

  getFilter(view:ViewPrice | ViewOrder | ViewOrderExchange):OrderString | PriceString | null{
    return null;
  }
  
  openNewView() {
    this.editView(-1);
  }

  editView(index: number) {
    this.editViewIdentificator==index? this.closeAllViews(): this.setIndexViewSelected(index);
  }

  closeAllViews(){
    this.setIndexViewSelected(-2);
    this.setSelectMy('');
  }

  setSelectMy(identificator:string){}

  /**
   * Given an array of Views, it filters depending on whether it is historical or not.
   * @param views ViewPrice[] | ViewOrder[] | ViewOrderExchange[]
   * @returns
   */
  filterViews(views: any): any[] {
    return views? views.filter(
          (view: ViewPrice | ViewOrder | ViewOrderExchange) => view.isHistorical === this.isHistorical) : [];
  }

  /**
   * Compares whether VIEW_ALL and viewSelected are equal
   * @returns boolean
   */
  isViewAllSelected(): boolean {
    return JSON.stringify(this.VIEW_ALL) === JSON.stringify(this.viewSelected);
  }

  viewAll(){
    this.setViewSelected(this.VIEW_ALL);
    this.setFilter( this.getFilter(this.VIEW_ALL));
    this.setSelectMy('');
  
    this.setViewSelectedInLocalStorage(this.VIEW_ALL, -1, this.isHistorical, this.getViewSelectedConst());
  }

  /**
   * Given a tag, it remembers through an object stored in the local storage which was the last one selected and sets it in the store.
   * @param tag
   * @returns
   */
  rememberViewsSelectedOfLocalStorage( tag: 'priceBoard' | 'orderBoard' | 'orderExchangeBoard' | 'historicalBoard') {

    const viewsSelected: ViewsSelected = getOfLocalStorage(localStorageNames.viewsSelected);
    const copyViews:any=JSON.parse(JSON.stringify(viewsSelected))
    const tagAux= this.getTagName(tag);
    if(copyViews && copyViews[tagAux]?.index >= 0){
      this.changeView(copyViews[tagAux].index)
    } else {
      this.selectViewDefault();
    }
  }

  setViewSelectedInLocalStorage(
    view: ViewPrice | ViewOrder | ViewOrderExchange,
    index: number,
    isHistorical: boolean,
    tag: string) {
      const viewsSelectedConst = getOfLocalStorage(localStorageNames.viewsSelected);
      const tagAux = this.getTagName(tag);

      let viewsSelected = viewsSelectedConst ? viewsSelectedConst : {};
      viewsSelected[tagAux] = viewsSelected[tagAux]? viewsSelected[tagAux]: {};
      viewsSelected[tagAux]['viewName'] = view.viewName;
      viewsSelected[tagAux]['index'] = index;
      viewsSelected[tagAux]['isHistorical'] = isHistorical;
      localStorage.setItem(localStorageNames.viewsSelected,JSON.stringify(viewsSelected));
  }

  private getTagName(tag:string): string /*'priceBoard' | 'orderBoard' | 'orderExchangeBoard' | 'historicalBoard'*/  {
    return tag == VIEWS_SELECTED_CONST.ORDER_BOARD && this.isHistorical ? VIEWS_SELECTED_CONST.HISTORICAL_BOARD : tag;
  }

  isViewSelected(view:ViewPrice | ViewOrder | ViewOrderExchange, viewSelected: ViewPrice| ViewOrder|ViewOrderExchange):boolean{
    return JSON.stringify(view) === JSON.stringify(viewSelected);
  }
}
