export const businessParticularities = {
    "-1": "--",
    0: "--",
    1: "Wash Out",
    2: "Gravanz",
    3: "Acumulador",
    4: "Condicional",
    5: "Cláusula cobranza",
    6: "Respeta excención de IVA",
    7: "Flete a cargo del comprador",
    8: "Convenio",
    9: "Entregas en planta Grassi",
    10: "Entrega en Silo Bolsas",
    11: "Bonificación",
    12: "Rebaja",
    13: "Convenio Laboratorio",
    14: "Sellado a recuperar",
    15: "A retirar",
    16: "Pactado con",
    17: "PAF Garantizado",
    18: "Anticipo financiero",
}

export const particularities_soja_fabrica = [1,2,3,4,5,6]

export const particularities_maiz_camara =[5,6,7];
export const particularities_maiz_grado2 =[5, 2, 8, 6, 7, 9, 10];

export const particularities_sorgo_camara =[5,6,7];
export const particularities_sorgo_grado2 =[5, 2, 8, 6, 7, 9, 10];

export const particularities_trigo_camara =[5, 11, 12, 6];
export const particularities_trigo_grado2 =[5,11,2,6,7,8,13];

export const particularities_cebada_cervecera_camara =[5,14,7,15];

export const particularities_cebada_forrajera_camara =[5,14];
export const particularities_cebada_forrajera_grado2 =[14];

export const BUSS_PART_CONST = {
    washOut: "Wash Out",
    gravanz: "Gravanz",
    accumulator: "Acumulador",
    conditional: "Condicional",
    collectionClause: "Cláusula cobranza",
    respectIVA: "Respeta excención de IVA",
    freightPaidByTheBuyer: "Flete a cargo del comprador",
    agreement: "Convenio",
    deliveriesAtGrassiPlant: "Entregas en planta Grassi",
    deliveryInSiloBolsas: "Entrega en Silo Bolsas",
    bonification: "Bonificación",
    reduction: "Rebaja",
    laboratoryAgreement: "Convenio Laboratorio",
    sealedToRetrieve: "Sellado a recuperar",
    toWithdraw:"A retirar",
    agreedWith: "Pactado con",
    financialAdvance: "Anticipo financiero",
    guaranteedPAF: "PAF Garantizado",
    nutrienAgreement: "Convenio Nutrien"
}

export const BUSS_PART_YES_OR_NOT = [
    BUSS_PART_CONST.washOut,
    BUSS_PART_CONST.nutrienAgreement,
    BUSS_PART_CONST.collectionClause,
    BUSS_PART_CONST.respectIVA,
    BUSS_PART_CONST.agreement,
    BUSS_PART_CONST.toWithdraw,
    BUSS_PART_CONST.guaranteedPAF
]

export const BUSS_PART_TAGS={
    washOut: 'washOut',
	gravanz: 'gravanz',
	accumulator: 'accumulator' ,
	conditional: 'conditional' ,
	collectionClause: 'collectionClause' ,
	respectVatException: 'respectVatException' ,
	freightPaidByTheBuyer: 'freightPaidByTheBuyer' ,
	agreement: 'agreement' ,
	deliveriesAtGrassiPlant: 'deliveriesAtGrassiPlant' ,
	deliveryInSiloBolsas: 'deliveryInSiloBolsas' ,
	bonification: 'bonification' ,
	reduction: 'reduction' ,
	laboratoryAgreement: 'laboratoryAgreement' ,
	sealedToRetrieve: 'sealedToRetrieve' ,
	toWithdraw: 'toWithdraw',
	agreedWith: 'agreedWith',
    financialAdvance: 'financialAdvance',
    guaranteedPAF: 'guaranteedPAF',
    nutrienAgreement: 'nutrienAgreement'
}

export const BUSS_PART_NAME_TO_TAG:any={
    "Wash Out": BUSS_PART_TAGS.washOut,
    "Gravanz": BUSS_PART_TAGS.gravanz,
    "Acumulador": BUSS_PART_TAGS.accumulator,
    "Condicional": BUSS_PART_TAGS.conditional,
    "Cláusula cobranza": BUSS_PART_TAGS.collectionClause,
    "Respeta excención de IVA": BUSS_PART_TAGS.respectVatException,
    "Flete a cargo del comprador": BUSS_PART_TAGS.freightPaidByTheBuyer,
    "Convenio": BUSS_PART_TAGS.agreement,
    "Entregas en planta Grassi": BUSS_PART_TAGS.deliveriesAtGrassiPlant,
    "Entrega en Silo Bolsas": BUSS_PART_TAGS.deliveryInSiloBolsas,
    "Bonificación": BUSS_PART_TAGS.bonification,
    "Rebaja": BUSS_PART_TAGS.reduction,
    "Convenio Laboratorio": BUSS_PART_TAGS.laboratoryAgreement,
    "Sellado a recuperar": BUSS_PART_TAGS.sealedToRetrieve,
    "A retirar": BUSS_PART_TAGS.toWithdraw,
    "Pactado con": BUSS_PART_TAGS.agreedWith,
    "Anticipo financiero": BUSS_PART_TAGS.financialAdvance,
    "PAF Garantizado": BUSS_PART_TAGS.guaranteedPAF,
    "Convenio Nutrien": BUSS_PART_TAGS.nutrienAgreement
}