export const localStorageNames={
    //Session
    token: 'token', //Almacena el token del usuario
    refreshToken: 'refreshToken', //Almacena el refreshToken del usuario
    user: 'user', //Almacena el usuario logueado
    isSelectedArea: 'is-selected-area-by-user', //Almacena si el usuario cambio de area, la fecha q lo selecconó y cual fue la area seleccionada

    //Dashboard
    marketPricesSoja: 'market-prices-soja-dashboard', //Almacena los precios de la soja
    marketPricesTrigo: 'market-prices-trigo-dashboard', //Almacena los precios del trigo
    marketPricesMaiz: 'market-prices-maiz-dashboard', //Almacena los precios del maiz
    featuredPrices: "featured-prices-dashboard", //Almacena los precios destacados
    futureMarkets: "future-markets-dashboard", //Almacena los mercados futuros
    accumulatedTonnage: "accumulated-tonnage-dashboard", //Almacena el tonelaje acumulado

    temp: "temp", //Almacena el clima
    lastIdPrice: 'last-id-price', //Almacena el ultimo id de precio

    pricesCommodities: "prices-commodities", //Almacena los precios de las commodities
    pricesBuyers: "prices-buyers", //Almacena los precios de los compradores
    pricesAll: "prices-all", //Almacena los precios de todos los precios 
    pricesAllAux:"prices-all-aux",  //Almacena los precios de todos los precios auxiliar
 
    ordersAll: "orders-all", //Almacena las ordenes de todos los usuarios
    ordersAllAux: "orders-all-aux", //Almacena las ordenes de todos los usuarios auxiliar
 
    ordersExchange: "orders-exchange", //Almacena las ordenes de canje
    eraserOrders: "eraser-orders", //Almacena las ordenes de borrador

    //views
    viewPrices: "views-prices", // <-- Price Board
    viewOrders: "views-orders",  // <-- Order Board
    viewsControlPanel: "views-control-panel", // <-- Control Panel Board
    viewExchange: "views-exchange",  // <-- Order ExchangeBoard
    viewsSelected: "views-selected", // <-- Selected Board
}

export const COOKIES_CONST={
    accessToken: 'access_token',
    refreshToken: 'refresh_token'
}