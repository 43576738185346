import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceFeaturedComponent } from './price-featured.component';
import { ObservationsPriceSvgModule } from 'src/app/shared/styles/svg/observations-price/observations-price.module';

@NgModule({
  declarations: [
    PriceFeaturedComponent
  ], imports: [
    CommonModule,
    ObservationsPriceSvgModule
  ], exports:[
    PriceFeaturedComponent
  ]
})
export class CardPriceFeaturedModule { }