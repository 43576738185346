import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setConditionFilterBoard } from 'src/app/redux/actions/options.action';
import { CONDITIONS_TYPE_TO_FIX_CONST } from 'src/app/shared/const/to-fix';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'app-buttons-to-fix',
  templateUrl: './buttons-to-fix.component.html',
  styleUrls: ['./buttons-to-fix.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class ButtonsToFixComponent implements OnInit {
  conditionsSelected:number[]=[];
  CONDITION_TYPES=CONDITIONS_TYPE_TO_FIX_CONST;

  conditionFilter$!: Subscription;

  constructor(private store:Store<appState>, private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.store.select('options', 'board', 'conditionFilter').subscribe((conditionFilter:number[])=>{
      this.conditionsSelected=conditionFilter;
      this.cdr.detectChanges();
    });
  }

  isSelected(conditionType:number){
    return this.conditionsSelected.length==1 && this.conditionsSelected.includes(conditionType);
  }

  select(conditionType:number){
    this.store.dispatch(setConditionFilterBoard({conditionFilter:[conditionType]}))
  }

  selectAll(){
    this.store.dispatch(setConditionFilterBoard({conditionFilter:[1,2,3,4]}))
  }
}
