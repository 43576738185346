import { Injectable } from "@angular/core";
import { BUSS_PART_CONST, BUSS_PART_NAME_TO_TAG } from "./dict/business.particularities";
import { BusinessParticularities, Conditional, FreightPaid, GrassiPlants, SiloBolsas } from "./interfaces/business-particularities";
import { RATE_TO_DEFINE } from "./const/business-particularities";

@Injectable({
  providedIn: 'root',
})
export class BusinessParticularitiesValidator {
  constructor() { }

  getIsWashOut(bussPart: BusinessParticularities): boolean {
    return getIsWashOut(bussPart);
  }

  getIsGravanz(bussPart: BusinessParticularities): boolean {
    return getIsGravanz(bussPart);
  }

  getIsAccumulator(bussPart: BusinessParticularities): boolean {
    return getIsAccumulator(bussPart);
  }

  getIsConditional(bussPart: BusinessParticularities): boolean {
    return bussPart? isConditional(bussPart.conditional): false;
  }

  getIsAgreedWith(bussPart: BusinessParticularities): boolean {
    return getIsAgreedWith(bussPart);
  }

  getIsAgreement(bussPart: BusinessParticularities): boolean {
    return getIsAgreement(bussPart);
  }

  getIsBonification(bussPart: BusinessParticularities): boolean {
    return getIsBonification(bussPart);
  }

  getIsCollectionClause(bussPart:BusinessParticularities){
    return getIsCollectionClause(bussPart);
  }

  getIsNutrienAgreement(bussPart:BusinessParticularities){
    return getIsNutrienAgreement(bussPart);
  }

  getIsRespectVatException(bussPart:BusinessParticularities){
    return getIsRespectVatException(bussPart);
  }

  getIsFreightPaidByTheBuyer(bussPart:BusinessParticularities){
    return getIsFreightPaidByTheBuyer(bussPart.freightPaidByTheBuyer);
  }

  getIsDeliveriesAtGrassiPlant(bussPart:BusinessParticularities){
    return bussPart?  isDeliveryAtGrassiPlants(bussPart.deliveriesAtGrassiPlant):false;
  }

  getIsDeliveryInSiloBolsas(bussPart:BusinessParticularities){
    return bussPart? isEntregaSiloBolsas(bussPart.deliveryInSiloBolsas):false;
  }

  getIsReduction(bussPart:BusinessParticularities){
    return getIsReduction(bussPart);
  }

  getIsLaboratoryAgreement(bussPart:BusinessParticularities){
    return getIsLaboratoryAgreement(bussPart);
  }

  getIsSealedToRetrieve(bussPart:BusinessParticularities){
    return getIsSealedToRetrieve(bussPart);
  }

  getIsToWithdraw(bussPart:BusinessParticularities){
    return getIsToWithdraw(bussPart);
  }

  getIsFinancialAdvance(bussPart:BusinessParticularities){
    return getIsFinancialAdvance(bussPart);
  }
  
  getIsGuaranteedPAF(bussPart:BusinessParticularities){
    return getIsGuaranteedPAF(bussPart);
  }

  getbussinesParticularities(bussPart: BusinessParticularities): string[] {
    return getbussinesParticularities(bussPart);
  }
}

//---------- GET BUSINESS PARTICULARITIES ----------\\

export function isDeliveryAtGrassiPlants(grassiPlants: GrassiPlants) {
  return grassiPlants?.commission?.percentage > 0 ||
    grassiPlants?.parity?.quantity > 0 ||
    grassiPlants?.volatileWaste?.percentage > 0 ||
    grassiPlants?.freight?.amount?.quantity > 0 ||
    grassiPlants?.humidity?.amount?.quantity > 0 ||
    grassiPlants?.placeOfDelivery > 0
}

export function isEntregaSiloBolsas(siloBolsas: SiloBolsas) {
  return siloBolsas?.brand > 0 ||
    siloBolsas?.quantity > 0 ||
    siloBolsas?.size > 0;
}

export function isConditional(conditional: Conditional) {
  return conditional?.selection > 0 && conditional?.strike > 0;
}

export function getIsFinancialAdvance(bussPart:BusinessParticularities){
  return bussPart?.financialAdvance?.VAT > 0 ||
    bussPart?.financialAdvance?.rate > 0 ||
    bussPart?.financialAdvance?.percentageTypeToPay > 0
}

function getIsWashOut(bussPart: BusinessParticularities): boolean {
  return bussPart?.washOut == 1;
}

function getIsGravanz(bussPart: BusinessParticularities): boolean {
  return bussPart?.gravanz?.isGravanz == 1;
}

function getIsAccumulator(bussPart: BusinessParticularities): boolean {
  return bussPart?.accumulator?.isAccumulator == 1;
}

function getIsConditional(bussPart: BusinessParticularities): boolean {
  return bussPart? isConditional(bussPart.conditional): false;
}

function getIsAgreedWith(bussPart: BusinessParticularities): boolean {
  return (bussPart?.agreedWith?.buyer?.length) > 3 ||
         (bussPart?.agreedWith?.comercialName?.length) > 3 ||
         (bussPart?.agreedWith?.responsable?.length) > 3;
}

function getIsAgreement(bussPart: BusinessParticularities): boolean {
  return bussPart?.agreement > 0;
}

export function getIsBonification(bussPart: BusinessParticularities): boolean {
  let aux:boolean = true;

  //Bonificacion entrega anticipada
  if(bussPart?.bonification?.typeBonification == 1){
    aux = bussPart?.bonification.startDate != '' && bussPart?.bonification.startDate != '--';
  } else if (bussPart?.bonification?.typeBonification==2){
    //Bonificacion cambio de destino
    aux = bussPart?.bonification.placeOfDelivery > 0;
  }

  return bussPart?.bonification?.typeBonification > 0 &&
         bussPart?.bonification?.amount?.quantity > 0 &&
         bussPart?.bonification?.amount?.typeCoin !== '--' &&
         aux;
}

function getIsCollectionClause(businessParticularity:BusinessParticularities){
  return businessParticularity?.collectionClause > 0;
}

function getIsNutrienAgreement(businessParticularity:BusinessParticularities){
  return businessParticularity?.nutrienAgreement ?? false;
}

function getIsRespectVatException(businessParticularity:BusinessParticularities){
  return businessParticularity?.respectVatException > 0;
}

/*
Si es tarifa a definir, entonces el monto debe ser 0, el numero de dias debe ser mayor a 0 y el tipo de evento debe ser seleccionado.
Si es tarifa definida, entonces el monto debe ser mayor a 0, el numero de dias debe ser mayor a 0 y el tipo de evento debe ser seleccionado.*/
export function getIsFreightPaidByTheBuyer(freightPaidByTheBuyer:FreightPaid | undefined){
  if(!freightPaidByTheBuyer) return false

  const amount = freightPaidByTheBuyer?.amount;
  const numberOfDays= freightPaidByTheBuyer?.numberOfDays;
  const eventType = freightPaidByTheBuyer?.eventType;
  
  if(amount?.typeCoin == RATE_TO_DEFINE){
    return amount?.quantity <= 0 && numberOfDays > 0 && eventType != '';
  } else {
    return (amount?.typeCoin == 'ARS' || amount?.typeCoin == 'USD') && 
    amount?.quantity > 0 && 
    numberOfDays > 0 && 
    eventType != '';
  }
}

function getIsReduction(businessParticularity:BusinessParticularities){
  return (businessParticularity?.reduction?.amount > 0 && businessParticularity?.reduction?.typeCoin !== '--') || 
         (businessParticularity?.reduction?.percentage > 0);
}

function getIsLaboratoryAgreement(businessParticularity:BusinessParticularities){
  return businessParticularity?.laboratoryAgreement > 0;
}

function getIsSealedToRetrieve(businessParticularity:BusinessParticularities){
  return businessParticularity?.sealedToRetrieve > 0;
}

function getIsToWithdraw(businessParticularity:BusinessParticularities){
  return businessParticularity?.toWithdraw > 0;
}

function getIsGuaranteedPAF(businessParticularity:BusinessParticularities){
  return businessParticularity?.guaranteedPAF;
}

export function getbussinesParticularities(bussPart: BusinessParticularities): string[] {
  try {
    let arrayToReturn: string[] = [];
    if (getIsWashOut(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.washOut);
    }
    if (getIsAccumulator(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.accumulator);
    }
    if (getIsAgreedWith(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.agreedWith);
    }
    if (getIsAgreement(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.agreement);
    }
    if (getIsBonification(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.bonification);
    }
    if (getIsCollectionClause(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.collectionClause);
    }
    if (getIsConditional(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.conditional);
    }
    if (isDeliveryAtGrassiPlants(bussPart.deliveriesAtGrassiPlant)) {
      arrayToReturn.push(BUSS_PART_CONST.deliveriesAtGrassiPlant);
    }
    if (isEntregaSiloBolsas(bussPart.deliveryInSiloBolsas)) {
      arrayToReturn.push(BUSS_PART_CONST.deliveryInSiloBolsas);
    }
    if (getIsFreightPaidByTheBuyer(bussPart.freightPaidByTheBuyer)) {
      arrayToReturn.push(BUSS_PART_CONST.freightPaidByTheBuyer);
    }
    if (getIsGravanz(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.gravanz);
    }
    if (getIsLaboratoryAgreement(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.laboratoryAgreement);
    }
    if (getIsReduction(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.reduction);
    }
    if (getIsRespectVatException(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.respectIVA);
    }
    if (getIsSealedToRetrieve(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.sealedToRetrieve);
    }
    if (getIsToWithdraw(bussPart)) {
      arrayToReturn.push(BUSS_PART_CONST.toWithdraw);
    }
    if(getIsGuaranteedPAF(bussPart)){
      arrayToReturn.push(BUSS_PART_CONST.guaranteedPAF);
    }
    if(getIsFinancialAdvance(bussPart)){
      arrayToReturn.push(BUSS_PART_CONST.financialAdvance);
    }
    if(getIsNutrienAgreement(bussPart)){
      arrayToReturn.push(BUSS_PART_CONST.nutrienAgreement);
    }

    return arrayToReturn;
  } catch (err) {
    return [];
  }
}

export function getParticularityTag(name: string) {
  return BUSS_PART_NAME_TO_TAG[name] ?? '';
}

/**
 * Passing as a parameter the same string array called bussParts, and another string array 
 * (representing the strings that are "yes or no") evaluate each element of bussParts.
 * The idea is to return an object of elements that are yesOrNot and elements that are withData (all in one object).
 * @param {string[]} bussParts - Array of strings to evaluate.
 * @param {string[]} yesOrNo - Array of strings that are "yes or no".
 * @returns {object} - Object with two arrays: yesOrNo and withData.
 * */
export function separateBussPart(bussParts: string[], yesOrNo: string[]): { yesOrNo: string[], withData: string[] } {
  const resultado: { yesOrNo: string[], withData: string[] } = { yesOrNo: [], withData: [] };
  bussParts.forEach((elemento, index) => {
      if (yesOrNo.includes(elemento)) {
          resultado.yesOrNo.push(elemento);
      } else {
          resultado.withData.push(elemento);
      }
  });
  return resultado;
}