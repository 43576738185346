<div class="field">
    <span class="field-title">Moneda<span class="asterisk">*</span></span>

    <select-input [placeHolder]="'Ingresar moneda'" [value]="OBJECT[amount.typeCoin]" [array]="array" 
    [OBJECT]="OBJECT" (valueOut)="setTypeCoinAmount($event)"></select-input>
</div>

<div class="field">
    <span class="field-title">Monto<span class="asterisk">*</span></span>

    <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="amount.quantity" 
    (valueOut)="setMontAmount($event)"></number-input>
</div>