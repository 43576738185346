import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusConst } from '../../const/orders';
import { OrderExchange } from '../../interfaces/order-exchange';
import { Order } from '../../interfaces/order.interface';
@Pipe({
  name: 'withOutContractStatus'
})
export class FilterWithOutContractStatus implements PipeTransform {

  transform(orderList: OrderExchange[] | Order[], identificator: number=0) {
    let newValueToReturn = [];
    const quantOfItems = orderList.length;
    for(let i=0; i<quantOfItems; i++){
      if(isOrderVisibleForCommodities(orderList[i], false)){
        newValueToReturn.push(orderList[i]);
      }
    }

    return newValueToReturn;
  }
}

export function isOrderVisibleForCommodities(order:Order, isComercial:boolean, showContracts:boolean=false){
  if(isComercial) return true;
  return showContracts || order.statusOrder != orderStatusConst.AUTORIZATION && order.statusOrder != orderStatusConst.CONTRACT
}
