import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setLocation } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { SessionChecker } from 'src/app/shared/session.checker';

@Component({
  selector: 'app-eraser',
  templateUrl: './eraser.component.html',
  styleUrls: ['../../shared/styles/body.scss']
})
export class EraserComponent implements OnInit{
  constructor(private store: Store<appState>,
    private checker: SessionChecker) {

    this.checker.rememberAndActualizateUser(this.store);
    this.store.dispatch(setLocation({location: NAVEGATION.ERASER}));
  }
  ngOnInit(): void {
  }
}
