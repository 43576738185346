<svg class="svg-12" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_3720_13693)">
    <path d="M6.00177 11.3375C7.05747 11.3375 8.08946 11.0245 8.96724 10.438C9.84502 9.85144 10.5292 9.01781 10.9332 8.04247C11.3372 7.06713 11.4429 5.9939 11.2369 4.95848C11.031 3.92307 10.5226 2.97198 9.7761 2.22549C9.02961 1.479 8.07852 0.970631 7.0431 0.764674C6.00769 0.558718 4.93446 0.664422 3.95912 1.06842C2.98378 1.47242 2.15014 2.15657 1.56363 3.03435C0.977114 3.91213 0.664063 4.94412 0.664062 5.99982C0.665657 7.41498 1.22853 8.77172 2.2292 9.77239C3.22987 10.7731 4.58661 11.3359 6.00177 11.3375ZM6.00177 1.59961C6.87205 1.59961 7.72278 1.85768 8.44639 2.34118C9.17 2.82468 9.73399 3.5119 10.067 4.31593C10.4001 5.11997 10.4872 6.0047 10.3174 6.85826C10.1476 7.71181 9.72857 8.49585 9.11319 9.11123C8.49781 9.72661 7.71376 10.1457 6.86021 10.3155C6.00665 10.4853 5.12192 10.3981 4.31788 10.0651C3.51385 9.73204 2.82663 9.16805 2.34313 8.44444C1.85963 7.72083 1.60156 6.8701 1.60156 5.99982C1.6029 4.83322 2.06692 3.71479 2.89183 2.88988C3.71674 2.06497 4.83517 1.60095 6.00177 1.59961Z" fill="black"/>
    <path d="M3.34375 6.46918H5.52875V8.65417C5.52875 8.77849 5.57813 8.89772 5.66604 8.98563C5.75395 9.07354 5.87318 9.12292 5.9975 9.12292C6.12182 9.12292 6.24105 9.07354 6.32895 8.98563C6.41686 8.89772 6.46625 8.77849 6.46625 8.65417V6.46918H8.65125C8.77557 6.46918 8.89479 6.41979 8.9827 6.33188C9.07061 6.24398 9.12 6.12475 9.12 6.00043C9.12 5.87611 9.07061 5.75688 8.9827 5.66897C8.89479 5.58106 8.77557 5.53168 8.65125 5.53168H6.46625V3.34668C6.46625 3.22236 6.41686 3.10313 6.32895 3.01522C6.24105 2.92732 6.12182 2.87793 5.9975 2.87793C5.87318 2.87793 5.75395 2.92732 5.66604 3.01522C5.57813 3.10313 5.52875 3.22236 5.52875 3.34668V5.53168H3.34375C3.21943 5.53168 3.1002 5.58106 3.01229 5.66897C2.92439 5.75688 2.875 5.87611 2.875 6.00043C2.875 6.12475 2.92439 6.24398 3.01229 6.33188C3.1002 6.41979 3.21943 6.46918 3.34375 6.46918Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_3720_13693">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
</svg>