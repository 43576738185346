import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-svg',
  templateUrl: './info-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class InfoSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
