import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoOrderComponent } from './info-order.component';
import { DataModule } from '../../data/data.module';



@NgModule({
  declarations: [
    InfoOrderComponent
  ],
  imports: [
    CommonModule,
    DataModule
  ], exports:[
    InfoOrderComponent
  ]
})
export class InfoOrderModule { }
