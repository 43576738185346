import { createAction, props } from "@ngrx/store";
import { HowSellValidation, RucaAndSisa } from "src/app/shared/interfaces/DataIBM";
import { Order, OrderString, Restriction, ValidationStruct } from "src/app/shared/interfaces/order.interface";
import { Price } from "src/app/shared/interfaces/price.interface";
import { ViewOrder } from "src/app/shared/interfaces/views.interface";

//Remember Orders
export const rememberOrdersData = createAction('[ORDER] Remember Data',
    props<{ state: any }>());
export const setIsOrderReturned=createAction('[ORDER] Set is order returned',
props<{ isOrderReturned: any }>());

//Orders
export const loadOrdersAllSuccess = createAction('[ORDER] Load Orders All Success',
    props<{ orders: Order[] }>());
export const loadOrdersFailed = createAction('[ORDER] Load Orders Failed');

export const loadOrdersAllWithPipesSuccess = createAction('[ORDER] Load Orders All With Pipes Success',
    props<{ orders: Order[] }>());

export const loadOrdersAllPrevius = createAction('[ORDER] Load Orders All Previus',
    props<{ orders: Order[] }>());


//Order filter
export const setFilterOrder = createAction('[FILTER ORDER] Set Filter Order',
    props<{ orderString: OrderString }>());
export const setBuyerTypeFilter = createAction('[FILTER ORDER] Set Buyer Type Filter In Order List',
    props<{ buyerType: string }>());
export const setFilterIndex= createAction('[FILTER ORDER] Set INDEX Filter In Order List',
    props<{ index:number }>())

//Generate Orders
export const setOrderToGenerate = createAction('[GENERATE ORDER] Set Generate Order',
    props<{ order: Order }>());
export const setPriceToGenerateInOrder = createAction('[GENERATE ORDER] Set Generate Price In Order',
    props<{ price: Price }>());
export const setCommisionInOrder = createAction('[GENERATE ORDER] Set Commission At Price In Order',
    props<{ commission: any }>());
export const resetOrderToGenerate = createAction('[GENERATE ORDER] Reset Generate Order');

export const setRestriction = createAction('[GENERATE ORDER] Set Restriction',
    props<{ restriction: Restriction }>());

export const setValidation = createAction('[VALIDATION] Set Validation',
    props<{ validation: ValidationStruct }>());
export const setRucaAndSisa = createAction('[VALIDATION] Set Ruca And Sisa',
    props<{ rucaAndSisa:RucaAndSisa}>());
export const setHowSellValidation = createAction('[VALIDATION] Set How Sell Validation',
    props<{ howSellValidation: HowSellValidation }>());
export const setValidPxQ = createAction('[VALIDATION] Set Valid PxQ',
    props<{ validPxQ: string }>());

//Views of Order board
export const setViewToGenerateO = createAction('[VIEW] Set View To Generate In ORDER LIST',
    props<{ view: ViewOrder }>());
export const setNewViewO = createAction('[VIEW] Set New View In ORDER LIST',
    props<{ view: ViewOrder }>());
export const resetNewViewO = createAction('[VIEW] Reset New View In ORDER LIST');
export const resetViewSelectedO = createAction('[VIEW] Reset View Selected In ORDER LIST');
export const setViewSelectedO = createAction('[VIEW] Set View Selected In ORDER LIST',
    props<{ view: ViewOrder }>());
export const setAllViewsO = createAction('[VIEW] Set All Views In ORDER LIST',
    props<{ views: ViewOrder[] }>());
export const setIndexViewSelected = createAction('[VIEW] Set Index Of View Selected In ORDER LIST',
props<{ index: number }>());


//Orders selected to edit (Only Commodities)
export const setOrdersSelected = createAction('[ORDERS SELECTED] Set Orders Selected',
    props<{ orders: Order[] }>());

//EDIT ORDERS
export const editOrders = createAction('[EDIT ORDERS] Edit Orders',
    props<{ orders: Order[], field: string, value: any }>());
export const editOrdersSuccess = createAction('[EDIT ORDERS] Edit Orders Success',
    props<{ message: string }>());
export const editOrdersFailed = createAction('[EDIT ORDERS] Edit Orders Failed',
    props<{ message: string }>());

export const editOrder = createAction('[EDIT ORDER] Edit Order',
    props<{ order: Order, field: string, subField: string, value: any }>());
export const editOrderSuccess = createAction('[EDIT ORDER] Edit Order Success',
    props<{ message: string }>());
export const editOrderFailed = createAction('[EDIT ORDER] Edit Order Failed',
    props<{ message: string }>());

//DUPLICATE ORDERS
export const setNumOfDuplicateOrders = createAction('[DUPLICATE ORDERS] Set Number Of Duplicate Orders',
    props<{ numOfDuplicateOrders: number }>());
export const setOrdersToGenerate = createAction('[GENERATE ORDERS] Set Generate Orders',
    props<{ orders: Order[] }>());


export const setIndexToEdit = createAction('[DUPLICATE ORDERS] Set Index To Edit Order',
    props<{ indexToEdit: number }>());


//Loader send order to IBM
export const setLoaderSendToIBM = createAction('[LOADER SEND TO IBM] Set Loader Send To IBM',
    props<{ loader: number }>());
export const addLoaderSendToIBM = createAction('[LOADER SEND TO IBM] Add Loader Send To IBM');
export const discountLoaderSendToIBM = createAction('[LOADER SEND TO IBM] Remove Loader Send To IBM');


//ORDERS EDIT
export const setValidationOrdersToGenerate = createAction('[EDIT ORDERS] Set Generate Orders',
    props<{ orders: Order[] }>());
export const setIndexToEditValidation = createAction('[EDIT ORDERS] Set Index To Edit Order',
    props<{ indexToEdit: number }>());
    export const setEditAll = createAction('[EDIT ORDERS] Set Edit All Orders',
    props<{ editAll: boolean }>());
export const isGenerateEraser=createAction('[EDIT ORDERS] Set Is Generate Eraser',
props<{ isGenerateEraser: boolean }>());

//Details of order
export const setOrderDetails = createAction('[ORDER DETAILS] Set Order Details',
    props<{ order: Order }>());
export const showOrderDetails = createAction('[ORDER DETAILS] Show Order Details', props<{ show: boolean }>());


//Historical
export const setHistorical = createAction('[ORDER HISTORICAL] Set Historical',
    props<{ historical: any }>());
