import { Component, Input } from '@angular/core';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';
import { getStatusOfPrice } from '../../board/mobile-price/mobile-price.component';

@Component({
  selector: 'app-status-tag',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusTagComponent {
  @Input() price!:Price;

  constructor(private priceV:PriceValidator){}

  getStatusPrice(price:Price){
    return this.priceV.getPriceStatus(price);
  }

  getStatusOfPrice(price: Price) {
    return getStatusOfPrice(price);
  }
}
