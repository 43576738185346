import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrderExchange } from '../interfaces/order-exchange';
import { ResponseRequest } from '../interfaces/options.interface';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  constructor(private http: HttpClient) { }

  getExchangesByDate(date: any){
    return this.http.get<ResponseRequest>(environment.rutas.exchanges.getExchangesByDate+`?startDate=${date}`);
  }
  
  //We create the orderExchange
  createOrderExchange(orderExchange: OrderExchange){
    let newOrderExchange=JSON.parse(JSON.stringify(orderExchange));
    delete newOrderExchange._id;
    delete newOrderExchange.created_at;
    delete newOrderExchange.price._id;
    delete newOrderExchange.price.created_at;
    let request= JSON.parse(JSON.stringify(newOrderExchange));

    return this.http.post<ResponseRequest>(environment.rutas.exchanges.createExchange, JSON.stringify(request)); 
  }
  
  //We update the orderExchange
  updateOrderExchange(orderExchange: OrderExchange, id:string){
    let request= JSON.parse(JSON.stringify(orderExchange));
    delete request._id;
    delete request.price._id;
    return this.http.put<ResponseRequest>(environment.rutas.exchanges.updateExchange.replace('{id}', id), request); 
  }
  
  //We delete the orderExchange
  deleteOrderExchange(id:string){
    return this.http.delete<ResponseRequest>(environment.rutas.exchanges.deleteExchange.replace('{id}', id)); 
  }
  
  //We get the orderExchange
  getOrderById(id:string){
    return this.http.get<ResponseRequest>(environment.rutas.exchanges.getOrderByID.replace('{id}', id)); 
  }
  
  //We get the contraparts
  getContraparts(id:string){
    return this.http.get<ResponseRequest>(environment.rutas.exchanges.getContraparts.replace('{id}', id));
  }

  getExchangesByRange(date1: string, date2: string, statusOrder:number=0) {
    return this.http.get<ResponseRequest>(environment.rutas.exchanges.getExchangesByRange + `?startDate=${date1}&endDate=${date2}`);
  }
}