
<th class="col-s col-1 row-left gap-4px fixed">
    <input *ngIf="showCheckbox" class="form-check-input" style="margin:0" [checked]="isCheckedAll" (click)="checkedAllItems()" type="checkbox"
    value="true">

    <div class="background-svg row-center" *ngIf="pricesSelected.length>0" (click)="deletePrices()">
        <delete-svg style="height: 100%;" [color]="'caribean-green'" [size]="'svg-14'"></delete-svg>
    </div>
</th>

<th cell-head class="col-mb col-2" *ngIf="viewSelected.observations"
[name]="'Observaciones'" [filterIdentificator]="1" [identificatorString]="'observations'"
[isFilter]="getIsFilter('observations','')" [showFilter]="filterIdentificator==1"
[arrayAux]="OBSERVATIONS" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-sm col-3" *ngIf="viewSelected.state"
[name]="'Estado'" [filterIdentificator]="2" [identificatorString]="'status'"
[isFilter]="getIsFilter('status','')" [showFilter]="filterIdentificator==2"
[arrayAux]="STATUS" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-m col-4" *ngIf="viewSelected.product"
[name]="'Producto'" [filterIdentificator]="3" [identificatorString]="'productName'"
[isFilter]="getIsFilter('productName','')" [showFilter]="filterIdentificator==3"
[arrayAux]="PRODUCTS" [isSearchOrNumber]="true" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-b col-17" *ngIf="buyerType==buyerTypes.BUYER || buyerType==buyerTypes.ALL"
[name]="'Comprador'" [filterIdentificator]="17" [identificatorString]="'observations'" [identificatorStringAux]="'buyer'"
[isFilter]="getIsFilter('buyer','')" [showFilter]="filterIdentificator==17" [isSearchOrNumber]="true"
[arrayAux]="BUYERS" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-b col-5" *ngIf="viewSelected.typeBusiness"
[name]="'Tipo de negocio'" [filterIdentificator]="4" [identificatorString]="'typeBusiness'"
[isFilter]="getIsFilter('typeBusiness','')" [showFilter]="filterIdentificator==4"
[arrayAux]="BUSINESS_TYPE" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-b col-6" *ngIf="viewSelected.placeOfDelivery"
[name]="'Lugar de entrega'" [filterIdentificator]="5" [identificatorString]="'placeOfDelivery'" [identificatorStringAux]="'zone'"
[isFilter]="getIsFilter('placeOfDelivery','zone')" [showFilter]="filterIdentificator==5"
[arrayAux]="PLACE_OF_DELIVERY" [isSearchOrNumber]="true" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-bb col-7" *ngIf="viewSelected.deliveryPeriod"
[name]="'Periodo de entrega'" [filterIdentificator]="6" [identificatorString]="'deliveryPeriod'"
[isFilter]="getIsFilter('deliveryPeriod','')" [showFilter]="filterIdentificator==6" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-sm col-8" *ngIf="viewSelected.quality"
[name]="'Calidad'" [filterIdentificator]="7" [identificatorString]="'quality'"
[isFilter]="getIsFilter('quality','')" [showFilter]="filterIdentificator==7"
[arrayAux]="QUALITY" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-m col-9" *ngIf="viewSelected?.price && conditionType!=CONDITION_TYPES.TO_FIX" 
[name]="'Precio'" [filterIdentificator]="8" [identificatorString]="'price'"
[isFilter]="getIsFilter('price','')" [showFilter]="filterIdentificator==8"
[isSearchOrNumber]="true" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-10 {{conditionType==CONDITION_TYPES.TO_FIX? 'col-bbb':'col-bb'}}" *ngIf="viewSelected.wayPay"
[name]="'Forma de pago'" [filterIdentificator]="9" [identificatorString]="'wayPay'" [identificatorStringAux]="'wayPayName'"
[isFilter]="getIsFilter('wayPayName','')" [showFilter]="filterIdentificator==9" [isToFix]="conditionType==CONDITION_TYPES.TO_FIX"
[arrayAux]="WAY_PAY" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-mb col-11" *ngIf="viewSelected.expiration && conditionType==CONDITION_TYPES.TO_PRICE"
[name]="'Vencimiento'" [filterIdentificator]="10" [identificatorString]="'wayPay'" [identificatorStringAux]="'expiration'"
[isFilter]="getIsFilter('expiration','')" [showFilter]="filterIdentificator==10"
(select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-b col-14" *ngIf="viewSelected.pesificacion"
[name]="'Pesificación'" [filterIdentificator]="13" [identificatorString]="'pesificacion'"
[isFilter]="getIsFilter('pesificacion','')" [showFilter]="filterIdentificator==13" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-m col-15" *ngIf="viewSelected.harvest"
[name]="'Cosecha'" [filterIdentificator]="14" [identificatorString]="'harvest'"
[isFilter]="getIsFilter('harvest','')" [showFilter]="filterIdentificator==14"
[arrayAux]="HARVEST" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-bb col-12" *ngIf="viewSelected.businessParticularities"
[name]="'Particularidad de negocio'" [filterIdentificator]="12" [identificatorString]="'businessParticularities'"
[showFilter]="filterIdentificator==12" 
[arrayAux]="[]" (select)="selectFilter($event)" [isPrice]="true"></th>

<th cell-head class="col-s col-16" *ngIf="viewSelected.hour"
[name]="'Hora'" [filterIdentificator]="15" [identificatorString]="'hour'"
[isFilter]="getIsFilter('hour','')" [showFilter]="filterIdentificator==15"
(select)="selectFilter($event)" [isPrice]="true"></th>