import { Component, Input, OnInit,OnDestroy,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { showOrderDetailsInControlPanel } from 'src/app/redux/actions/control-panel.action';
import { setShowAddBuyer } from 'src/app/redux/actions/options.action';
import { isGenerateEraser, setEditAll, setHistorical, setIndexToEditValidation, setOrderToGenerate, setOrdersSelected, setValidationOrdersToGenerate, showOrderDetails } from 'src/app/redux/actions/order.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { orderStates } from 'src/app/shared/dict/orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { DetailsEyeComponent } from '../details-eye.component';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { OrderService } from 'src/app/shared/services/order.service';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { Historical } from 'src/app/shared/interfaces/historical.interface';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-order-eye',
  templateUrl: './order.component.html',
  styleUrls: ['./../details-eye.component.scss', './../../slide-card.scss']
})
export class OrderComponent extends DetailsEyeComponent implements OnInit, OnDestroy {
  @Input() override isShow:boolean=false;
  @Input() order!:Order;
  lastVersion!:Order; //Ultima version, para comparar si se ha modificado
  firstVersion!:Order; //Version inicial, para comparar si se ha modificado
  showErrorFirstVersion:boolean=false;
  versionSelected='last';
  isToFix:boolean=false;
  STATUS_ORDER_CONST=orderStatusConst;
  messageReturned:string='';
  historical:string='';

  hourOfCreation:string='';
  nameOfCreator:string='';
  dateOfCreation:string='';
  hourOfConfirmation:string='';
  nameOfConfirmator:string='';

  constructor(store:Store<appState>, toFixV:ToFixValidator, private orderV:OrderValidator, private router:Router,
    private orderSvc:OrderService,
    private IBM:ContractChecker, private cdr:ChangeDetectorRef) { 
    super(store, toFixV);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.checkFirstVersion();
    this.setDataOfCreationAndConfirmation();
    this.isToFix=this.toFixV.orderHaveIndicators(this.order);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getTitle(order:Order){
    return orderStatusConst.CONTRACT== (order.statusOrder ?? -1) ? 'Detalle del contrato' : 'Detalle de la orden';
  }

  override close(): void {
    this.store.dispatch(showOrderDetails({show:false}));
    this.store.dispatch(showOrderDetailsInControlPanel({show:false}));
    this.clickInClose=true;
    this.count=0;
  };

  override open(): void {
    this.store.dispatch(showOrderDetails({show:true}));
    this.store.dispatch(showOrderDetailsInControlPanel({show:true}));
  }

  showHistorical(order:Order){
    this.historical=order._id;
    this.store.dispatch(setHistorical({historical:order._id}));
  }

  override isDisable(order:Order):boolean{
    return this.orderV.isOrderIncomplete(order, true);
  }

  getStatusOrder(order:Order){
    return (order?.statusOrder==this.STATUS_ORDER_CONST.AUTORIZATION && this.isComercial)?'Confirmada':this.orderV.getStatusOrder(order);
  }

  getDataOfCreation(order:Order){
    return `Orden creada por ${order?.dataOfcreation?.nameOfcreator} a las ${order?.dataOfcreation?.hour}`
  }

  getHourOfConfirmation(order:Order){
    if(order?.statusOrder==orderStatusConst.CONTRACT){
      return `confirmada a las ${this.hourOfConfirmation} por ${this.nameOfConfirmator}`
    }
    return 'pendiente de confirmación'
  }

  /* Returns if the order is in 'Devuelta' status*/
  isOrderReturned(){
    return this.order.statusOrder==orderStatusConst.RETURNED;
  }
  
  reingresateOrder(order:Order){
    if(order.statusOrder== orderStatusConst.RETURNED){
      this.store.dispatch(setOrderToGenerate({order:order}));

      const url='/'+NAVEGATION.GENERATE_MANUAL_ORDER
      this.router.navigate([url]);  
    }
  }
  

  //--------CHANGE STATUS--------\\

  override setStatusSelected(status:number){
    this.statusSelected=status;

    if(status!=this.STATUS_ORDER_CONST.RETURNED){
      this.setMessageReturned('');
      this.orderV.saveOrderStatus(this.order, this.statusSelected, this.messageReturned, this.user);
      this.setShowChangeState(false);
    } else {
      this.orderV.openModal(this.STATUS_ORDER_CONST.RETURNED);
      this.store.dispatch(setOrdersSelected({orders: [this.order]}));
    }
  }

  override showButtonChangeState(){
    try{
      return !this.isComercial && 
      this.order.statusOrder!=this.STATUS_ORDER_CONST.RETURNED && 
      this.order.statusOrder!=this.STATUS_ORDER_CONST.AUTORIZATION &&
      this.order.statusOrder!=this.STATUS_ORDER_CONST.CONTRACT;
    } catch(err){
      return false;
    }
  }

  setMessageReturned(message:any){
    this.messageReturned=message;
  }

  setVersionSelected(version:string){
    this.versionSelected=version;
  }

  checkFirstVersion(){
    this.orderV.getFirstVersion(this.order._id).pipe(
      catchError(error=>{
        this.showErrorFirstVersion=true;
        return [];
      })
    ).subscribe( (res:ResponseRequest)=>{
      if(res){
        this.showErrorFirstVersion=false;
        this.firstVersion=res.data;
        this.cdr.detectChanges();
      }
    })
  }

  setDataOfCreationAndConfirmation(){
    this.hourOfCreation=this.order?.dataOfcreation?.hour;
    this.nameOfCreator=this.order?.dataOfcreation?.nameOfcreator;
    this.dateOfCreation=this.order?.dataOfcreation?.dateOfcreation;

    if(this.order.statusOrder==orderStatusConst.CONTRACT){
      this.orderV.getHistorical(this.order._id).subscribe((res:ResponseRequest)=>{
        if(res.data){
         const hist=res.data.find( (h:Historical)=> h.campo=='statusOrder' && h.valorI==orderStatusConst.CONTRACT);
          if(hist){
            const date=new Date(hist.createdAt);
            const minutes=date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
            this.hourOfConfirmation= date.getHours()+':'+minutes;
            this.nameOfConfirmator=hist.name+' '+hist.lastname;
            this.cdr.detectChanges();
          }
        }
      });
    }

  }

  override isDisabledButtonStatus(){
    return this.statusSelected==0 || (this.statusSelected==this.STATUS_ORDER_CONST.RETURNED && this.messageReturned.length==0)
  }

  override applyNewStatus(){
    if(!this.isDisabledButtonStatus()){
      this.orderV.saveOrderStatus(this.order, this.statusSelected, this.messageReturned, this.user);
      this.setShowChangeState(false);
    }
  }

  isOrderConfirm(){
    return this.order?.statusOrder==this.STATUS_ORDER_CONST.CONFIRM;
  }

  isOrderAutorizate(){
    return this.order?.statusOrder==this.STATUS_ORDER_CONST.AUTORIZATION;
  }

  
  sendToTickets(){ 
    this.store.dispatch(setShowAddBuyer({isShowAddBuyer:true}));

    let validationOrders=[JSON.parse(JSON.stringify(this.order))];
    for(let i=0; i<validationOrders.length; i++){
      validationOrders[i].price.observations.buyer=-1;
    }

    this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
    this.store.dispatch(setEditAll({editAll: true}));
    this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
  }

  autorizate(){
    let newOrder:Order=JSON.parse(JSON.stringify(this.order));
    //newOrder.statusOrder=orderStatusConst.CONTRACT;
    this.IBM.createContracts([newOrder], 2, this.user); //Llamada 2 porque se enviará a boletos y no se podrá editar más
  }

  editOrder(){
    if(this.isOrderConfirm()){
      this.editConfirmOrder();
    } else if(this.isOrderAutorizate()){
      this.editAutorizateOrder();
    }
  }

  editAutorizateOrder(){
    this.orderV.resetSelectedOrderList();
    let validationOrders=[JSON.parse(JSON.stringify(this.order))];
    this.store.dispatch(isGenerateEraser({isGenerateEraser:false}));
    this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
    this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
    this.router.navigate(['/'+NAVEGATION.ORDER_VALIDATION]);
  }

  editConfirmOrder(){
    let validationOrders=[JSON.parse(JSON.stringify(this.order))];
    this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
    this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
    this.router.navigate(['/'+NAVEGATION.ORDER_VALIDATION]);
  }
}