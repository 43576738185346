import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PactadoConComponent } from './pactado-con.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { TextInputModule } from 'src/app/components/inputs/text/text.module';



@NgModule({
  declarations: [
    PactadoConComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    TextInputModule
  ], exports: [
    PactadoConComponent
  ]
})
export class PactadoConModule { }
