
<div class="background-sin-color">
<svg class="svg-24 hover-enabled" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#DCDCDC"/>
    <g clip-path="url(#clip0_138_112738)">
    <path d="M13.6551 10.8477C13.4998 10.8477 13.374 10.9735 13.374 11.1287V16.4401C13.374 16.5953 13.4998 16.7212 13.6551 16.7212C13.8103 16.7212 13.9361 16.5953 13.9361 16.4401V11.1287C13.9361 10.9735 13.8103 10.8477 13.6551 10.8477Z" fill="white"/>
    <path d="M10.3386 10.8477C10.1834 10.8477 10.0576 10.9735 10.0576 11.1287V16.4401C10.0576 16.5953 10.1834 16.7212 10.3386 16.7212C10.4939 16.7212 10.6197 16.5953 10.6197 16.4401V11.1287C10.6197 10.9735 10.4939 10.8477 10.3386 10.8477Z" fill="white"/>
    <path d="M7.92219 10.0725V16.9965C7.92219 17.4057 8.07226 17.7901 8.33441 18.0658C8.59535 18.3424 8.95849 18.4993 9.33854 18.5H14.6557C15.0359 18.4993 15.399 18.3424 15.6598 18.0658C15.922 17.7901 16.0721 17.4057 16.0721 16.9965V10.0725C16.5932 9.9342 16.9308 9.43076 16.8611 8.89603C16.7913 8.36142 16.3358 7.9615 15.7966 7.96139H14.3578V7.6101C14.3594 7.31469 14.2426 7.03103 14.0335 6.82234C13.8244 6.61377 13.5403 6.49762 13.2448 6.50004H10.7494C10.454 6.49762 10.1699 6.61377 9.96076 6.82234C9.75163 7.03103 9.63483 7.31469 9.63647 7.6101V7.96139H8.19762C7.6584 7.9615 7.20293 8.36142 7.13311 8.89603C7.06341 9.43076 7.40108 9.9342 7.92219 10.0725ZM14.6557 17.9379H9.33854C8.85804 17.9379 8.48425 17.5252 8.48425 16.9965V10.0972H15.51V16.9965C15.51 17.5252 15.1362 17.9379 14.6557 17.9379ZM10.1985 7.6101C10.1967 7.46377 10.2542 7.32293 10.358 7.21963C10.4618 7.11633 10.603 7.05957 10.7494 7.0621H13.2448C13.3913 7.05957 13.5325 7.11633 13.6362 7.21963C13.7401 7.32282 13.7976 7.46377 13.7957 7.6101V7.96139H10.1985V7.6101ZM8.19762 8.52345H15.7966C16.076 8.52345 16.3025 8.74992 16.3025 9.0293C16.3025 9.30869 16.076 9.53516 15.7966 9.53516H8.19762C7.91824 9.53516 7.69177 9.30869 7.69177 9.0293C7.69177 8.74992 7.91824 8.52345 8.19762 8.52345Z" fill="white"/>
    <path d="M11.9968 10.8477C11.8416 10.8477 11.7158 10.9735 11.7158 11.1287V16.4401C11.7158 16.5953 11.8416 16.7212 11.9968 16.7212C12.1521 16.7212 12.2779 16.5953 12.2779 16.4401V11.1287C12.2779 10.9735 12.1521 10.8477 11.9968 10.8477Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_138_112738">
    <rect width="12" height="12" fill="white" transform="translate(6 6.5)"/>
    </clipPath>
    </defs>
    </svg>
    
<div class="tooltip down row-center w-50">
    <span>Eliminar</span>
</div>
</div>