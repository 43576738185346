import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterOptionsPipe } from 'src/app/shared/pipes/filter/filter.options.pipe';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { EditSvgModule } from 'src/app/shared/styles/svg/edit/edit-svg.module';
import { FilterSvgModule } from 'src/app/shared/styles/svg/filter/filter-svg.module';
import { FilterPriceComponent } from './filters.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DateInputModule } from '../../inputs/date/date.module';
import { NumberInputModule } from '../../inputs/number/number.module';
import { DateCalendarInlineModule } from '../../inputs/date-calendar-inline/date-calendar-inline.module';


@NgModule({
  declarations: [
    FilterPriceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    EditSvgModule,
    FilterSvgModule,
    PipesModule,
    DateInputModule,
    NumberInputModule,
    DateCalendarInlineModule

  ], exports: [
    FilterPriceComponent
  ]
})
export class FilterPriceModule { }
