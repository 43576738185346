import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-buyer-order',
  templateUrl: './buyer.component.html',
  styleUrls: ['./../fields.component.scss'],
})
export class BuyerComponent  {
  @Input() fieldIdentificator ='';
  @Output() focusEvent= new EventEmitter<number>();
  constructor() {
  }

  emitField(fieldGroups: any) {
    this.focusEvent.emit(fieldGroups.offsetTop);
  }
  
}
