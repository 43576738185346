import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerateOrdenComponent } from './generate-orden.component';
import { ButtonOrderComponent } from './button-order/button-order.component';

@NgModule({
  declarations: [
    GenerateOrdenComponent,
    ButtonOrderComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GenerateOrdenComponent
  ]
})
export class GenerateOrdenModule { }
