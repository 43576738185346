export const CLIENTES_AC_IBM = [
  600, 362, 5282, 4897, 5777, 5114, 7078, 1410, 2791, 5341, 15085, 5004, 3447,
  3452, 6298, 6220, 361, 360, 363, 364, 365, 368, 369, 370, 371, 372, 373, 374,
  376, 377, 378, 379, 380, 381, 382, 383, 5115, 4415, 366, 367, 375, 384, 385,
  386, 387, 388, 389, 390, 2976, 5190, 6302, 6339, 4148, 391, 392, 393, 394,
  399, 421, 422, 423, 419, 425, 426, 427, 4884, 10955, 418, 428, 429, 430, 431,
  432, 433, 434, 435, 436, 437, 439, 440, 442, 443, 19321, 6451, 6566, 6095,
  18223, 438, 445, 446, 447, 448, 449, 450, 451, 452, 453, 455, 457, 458, 459,
  460, 461, 462, 463, 464, 444, 7012, 454, 456, 465, 466, 471, 468, 3308, 13582,
  472, 7237, 467, 473,
];
