<div class="field" #fieldName>
    <span class="field-title row-between">Cupos <span class="add" (click)="addQuota()">Agregar +</span></span>
    <span class="error message" *ngIf="errorMessage">{{errorMessage}}</span>
    <div *ngFor="let quota of quotas; index as i" class="quotas-container">

        <date-input class="half-input" (valueOut)="setDateQuotas($event,i)" [initialPlaceHolder]="'Fecha'" [value]="getDate(quota.date)"
        [isIncomplete]="isInvalidDate(quota.date)"></date-input>

        <div class="half-input trucks-col">
            <span class="delete-quota" (click)="deleteQuota(i)" *ngIf="i!=0">Eliminar</span>
            <number-input  [initialPlaceHolder]="'Camiones'" 
                (valueOut)="setTrucksQuotas($event,i)" [value]="quota.trucks" 
                [step]="'1'" [isWithPoint]="false" [max]="getMaxOfTrucks(i)"></number-input>
        </div>

    </div>
    <span style="font-size:11px">Cantidad máxima de camiones: {{getMaxOfTrucksForQuota(elementToGenerate?.tons ?? 0)}}</span>
</div>