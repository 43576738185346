import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuFooterComponent } from './menu-footer.component';
import { DashboardSvgModule } from 'src/app/shared/styles/svg/dashboard/dashboard-svg.module';
import { PriceBoardSvgModule } from 'src/app/shared/styles/svg/price-board/price-board-svg.module';
import { OrderListSvgModule } from 'src/app/shared/styles/svg/order-list/order-list.module';
import { GenerateOrderSvgModule } from 'src/app/shared/styles/svg/generate-order/generate-order.module';
import { ExchangeOrderListSvgModule } from 'src/app/shared/styles/svg/exchange-order/exchange-order.module';
import { ControlPanelSvgModule } from 'src/app/shared/styles/svg/control-panel/control-panel.module';



@NgModule({
  declarations: [MenuFooterComponent],
  imports: [
    CommonModule,
    DashboardSvgModule,
    PriceBoardSvgModule,
    OrderListSvgModule,
    GenerateOrderSvgModule,
    ExchangeOrderListSvgModule,
    ControlPanelSvgModule
  ], exports:[ MenuFooterComponent]
})
export class MenuFooterModule { }
