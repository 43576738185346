import { Component, Output, OnInit,EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags, typeCoins } from 'src/app/shared/const/prices.const';
import { TYPE_DOLARS } from 'src/app/shared/dict/type-dolars';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-type-dolar',
  templateUrl: './type-dolar.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class TypeDolarComponent extends Field implements OnInit{
  @Input() valueToSet!:number;
  @Output() dolarEvent = new EventEmitter<number>();

  constructor(store:Store<appState>) { 
    super(store);
    this.array=Object.keys(TYPE_DOLARS);
    this.array.shift();
    this.OBJECT=TYPE_DOLARS;
    this.deleteLastValueInArray();
    this.initalElementSelected='Ingresar un tipo de dolar';
    
    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.typeDolar;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.typeDolar;
    }
    this.setIdentifyClassName();

    setTimeout(()=>{
      this.rememberValueInForm();
    },300)
  }

  emit(value:number){
    this.dolarEvent.emit(value);
  }
  
  override rememberValueInForm(){
    this.rememberTypeDolar()
  }

  override setElement(element:any, index:number){
    let typeDolar=Number(element);
    if(this.fieldIdentificator != fieldType.ADD_BUYER){
      this.setElementToGenerate(typeDolar);
    } else{
      this.emit(typeDolar); //Emitimos un evento
      this.valueToSet=typeDolar;
      this.elementSelected = this.OBJECT[typeDolar];
      this.changeOptionSelected();
    }
  }

  //Remember value from string type coin type.
  rememberTypeDolar() {
    if(this.fieldIdentificator != fieldType.ADD_BUYER){
      let valueToSet: any = this.rememberValue();
      //If There is a value to remember
      if (valueToSet!='--' && this.elementToGenerate && valueToSet) {
        this.elementSelected = this.OBJECT[valueToSet];
        this.changeOptionSelected();
      } else if(valueToSet=='--'){
        this.elementSelected = '';
        this.setIsOptionSelected(false);
      }
    } else {
      this.elementSelected = this.OBJECT[this.valueToSet];
      this.changeOptionSelected();
    }
  }

}
