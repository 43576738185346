import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { orderTags } from 'src/app/shared/const/orders';
import { buyers } from 'src/app/shared/dict/buyers';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../../fields.component';

@Component({
  selector: 'app-not-sell',
  templateUrl: './not-sell.component.html',
  styleUrls: ['./../../fields.component.scss']
})
export class NotSellComponent extends Field implements OnInit {
  arrayOfSelected: any[] = [];
  constructor(store: Store<appState>, private bussPartValidator: BusinessParticularitiesValidator) {
    super(store);
    this.array = Object.keys(buyers);
    //this.array.shift();
    this.OBJECT = buyers;
    this.deleteLastValueInArray();

    this.elementSelected = '';
    this.initalElementSelected = 'Ingresar una restricción';
    this.pipe= new FilterByObjectPipe();
  }

  ngOnInit(): void {
    this.tag = orderTags.buyer;
    this.subTag = orderTags.notSell;
    this.setIdentifyClassName();
  }

  override rememberValueInForm(){
    //Para que no intente recordar cuando es OnlySell.
    if(this.subTag==orderTags.notSell){
      this.rememberValueBuyer()
    }
  }

  override setElement(element: any, index: number) {
    //Reseteamos el "no le vende a" si no es Gravanz;
    
    if(! this.bussPartValidator.getIsGravanz(this.elementToGenerate.price.businessParticularities)){
      this.subTag=orderTags.onlySell;
      this.setElementToGenerate(-1);

      this.subTag=orderTags.notSell;
      this.setElementToGenerate(Number(element));
    }
  }

  addOrRemoveBuyer(element: any, inputName:any=null) {
    /*if(! this.bussPartValidator.getIsGravanz(this.elementToGenerate.price.businessParticularities)){
      this.subTag=orderTags.notSell;
      this.setElementToGenerate([]);

      this.subTag=orderTags.onlySell;
      this.setElementToGenerate([Number(element)]);
    }*/      
    this.subTag=orderTags.onlySell;
    this.setElementToGenerate([]);

    const elementNumber=Number(element);
    if (this.arrayOfSelected.includes(elementNumber)) {
      this.arrayOfSelected.splice(this.arrayOfSelected.indexOf(elementNumber), 1);
    } else {
      this.arrayOfSelected.push(elementNumber);
    }

    
    this.subTag=orderTags.notSell;
    this.setElementToGenerate(this.arrayOfSelected);
    this.search='';
    this.placeHolder='';
    if(inputName){
      inputName.focus();
    }
  }

  rememberValueBuyer() {
    try{
      this.subTag='';
      let valueToSet: any = this.rememberValue(); //Recordamos el valor de {onlySell:.. , notSell:..}
      
      if (valueToSet.notSell.length==0) { //If only sell to a buyer.
        this.elementSelected = '';
        this.search='';
        this.placeHolder='';
        this.arrayOfSelected=[]
        this.setIsOptionSelected(false);
      } else if(valueToSet.onlySell.length == 0 && valueToSet.notSell.length >0){
        if(valueToSet.notSell.length == 1){
          this.elementSelected = this.OBJECT[valueToSet.notSell[0]];
        } else {
          this.elementSelected = valueToSet.notSell.length + ' compradores seleccionados';
        }
        this.arrayOfSelected=JSON.parse(JSON.stringify(valueToSet.notSell))
        this.setIsOptionSelected(true);
      }
      this.subTag=orderTags.notSell;
    } catch(err){}
  }

  getValue(){
    return this.isFocus? this.search : this.elementSelected;
  }

  includesElement(element: any){
    return this.arrayOfSelected.includes(Number(element));
  }

  override save() {
    if(this.search.length > 0){
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT,'');
      if (newList.length > 0) {
        this.addOrRemoveBuyer(newList[0]);
      }
      this.search='';
    }
  }
}