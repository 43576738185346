<td class="fixed" [ngClass]="{'z-index-200':showMenuOptions}">
    <div class="actions">
        <input *ngIf="showCheckbox" class="form-check-input" [checked]="isChecked" 
        (click)="selectOrder(order)" type="checkbox">

        <eye-svg [active]="false" (click)="showOrderDetail(order)"></eye-svg>

        <three-point-svg (click)="openOptions()" *ngIf="optionsMenu.length > 0"></three-point-svg>
        <div option-menu *ngIf="showMenuOptions" class="menu-options" [options]="optionsMenu" (selected)="selectOptionMenu($event,order)"></div>

        <arrow-board-svg *ngIf="isHaveIndicator" class="arrow" 
        [direction]="directionArrow" (click)="clickInArrow(order, $event)"></arrow-board-svg>

        <ng-container *ngIf="showActionsCP">
            <edit-contract-svg (click)="edit(order)"></edit-contract-svg>

            <div class="row-left autorization-button pointer" (click)="autorizate(order)">
                <span>Autorizar</span>
            </div>
        </ng-container>
    </div>
</td>

<td *ngIf="viewSelected?.observations">
    <div class="observations">
        <div class="quotas-container tooltip-container"
            *ngIf="haveQuotas(order)">
            <span class="trucks"><truck-svg></truck-svg></span>
            <div class="tooltip quotas-message">
                <span class="text-of-tooltip">{{getQuotasMessage(order)}}</span>
                <div class="triangle"></div>
            </div>
        </div>

        <div class="buyer-container tooltip-container"
            *ngIf="getHaveRestriction(order)">
            <span class="buyer"><warning-svg></warning-svg></span>
            <div class="tooltip buyer-message">
                <span>
                    {{'Con restricción:'}} <br>
                    {{getMessage(order.buyer)}}
                </span>
                <div class="triangle"></div>
            </div>
        </div>

        <manual-order-svg
            *ngIf="order.orderDestination.orderOrigin==ORDER_ORIGIN_CONST.manual"></manual-order-svg>

        <div class="edit-order-container tooltip-container"
            *ngIf="order.orderDestination.orderOrigin==ORDER_ORIGIN_CONST.desdeTableroModificada">
            <edit-from-board-svg></edit-from-board-svg>

            <!--<div class="tooltip edit-order-message"
                *ngIf="(order.arrayMods?.length ?? 0) > 0">
                <div class="column-left"> <span
                        *ngFor="let mods of order.arrayMods">{{getName(mods)}}</span></div>
                <div class="triangle"></div>
            </div>-->
        </div>

        <div class="parametrizer-observation-container"
            *ngIf="order.obsParam && order.obsParam != '--'">
            <parametrizer-observation-svg></parametrizer-observation-svg>
            <div class="tooltip parametrizer-observation-text">
                <span>{{order.obsParam}}</span>
                <div class="triangle"></div>
            </div>
        </div>

        <div class="message-container"
            *ngIf="order.message!= ' ' && order.message && order.statusOrder==1">
            <message-svg class="mesesage-icon"></message-svg>
            <div class="tooltip message-text">
                <span>{{order.message}}</span>
                <div class="triangle"></div>
            </div>
        </div>
    </div>
</td>

<td *ngIf="viewSelected?.hour" [class.disabled]="isDisabled" [class.is-returned]="isReturned">{{order.dataOfcreation.hour}}</td>

<td *ngIf="viewSelected?.orderStatus || viewSelected?.contractNumber" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <ng-container *ngIf="isControlPanel else notControlPanel">
        {{getContractNumber(order)}}
    </ng-container>

    <ng-template #notControlPanel>
        <span class="{{isDisabled? '':getStatusFromOrder(order).toLowerCase()}}">{{getStatusFromOrder(order)}}
            <span class="contract-number" *ngIf="showContractNumber(order)">N° {{getContractNumber(order)}}</span>
        </span>
    </ng-template>
</td>

<td *ngIf="viewSelected?.seller" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <span class="seller-name">{{getSellerName(order)}}</span>
</td>

<td *ngIf="viewSelected?.buyer && showBuyer" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    {{getBuyer(order)}}
</td>

<td *ngIf="viewSelected?.product" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    {{getProductName(order)}}
</td>

<td *ngIf="viewSelected?.typeBusiness" [class.disabled]="isDisabled" [class.is-returned]="isReturned">{{getBusinessType(order)}}</td>

<td *ngIf="viewSelected?.placeOfDelivery" [class.disabled]="isDisabled" [class.is-returned]="isReturned">{{getPlaceOfDelivery(order)}}</td>

<td *ngIf="viewSelected?.deliveryPeriod" [class.disabled]="isDisabled" [class.is-returned]="isReturned">{{getDeliveryPeriod(order)}}</td>

<td *ngIf="viewSelected?.quality" class="quality-column" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <div
        class="quality-container tooltip-container {{getQuality(order).toLowerCase()}}"
        [ngClass]="{'not-quality-particularity': order.price.qualityIBM<0}">
        <span class="quality">{{getQuality(order)}}</span>
        <div class="tooltip quality-message" *ngIf="order.price.qualityIBM > 0">
            <span>
                Particularidad de calidad: <br>
                "{{getQualityIBM(order)}}"
            </span>
            <div class="triangle"></div>
        </div>
    </div>
</td>

<td *ngIf="viewSelected?.price && conditionTypeSelected!=CONDITION_TYPE.TO_FIX" class="price-column" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <span>{{getTypeCoinAndPrice(order)}}</span>
</td>

<td *ngIf="viewSelected?.tons" class="tons-column" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <span>{{getTons(order)}}</span>
</td>

<td *ngIf="viewSelected?.wayPay" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <span>{{getWayPay(order)}}</span>
    <p class="days-way-pay">{{getDaysWayPay(order)}} - {{getPercentageWayPay(order)}}</p>
</td>

<td *ngIf="viewSelected?.harvest" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <span>{{getHarvest(order)}}</span>
</td>

<td *ngIf="viewSelected?.businessParticularities" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <div class="row-left" *ngIf="allBussPart.length>0">
        <span>{{bussPartYesOrNot}}</span>

        <span class="business-particularities-container tooltip-container" *ngIf="bussPartWithData.length>0">
            <span class="business-particularities">{{bussPartWithData}}</span>
            
            <div class="tooltip business-particularities-message">
            
                <app-data-buss-part [showTitle]="false" [isSmall]="true"
                [businessParticularity]="order.price.businessParticularities"></app-data-buss-part>
            
                <div class="triangle"></div>
            </div>
        </span>
    </div>
</td>

<td *ngIf="viewSelected?.date" [class.disabled]="isDisabled" [class.is-returned]="isReturned">    
    <span>{{getDateOfCreation(order)}}</span>
</td>

<td *ngIf="viewSelected?.tables" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    <div class="column-beetwen">
        <span class="team">{{getBossNameCreator(order)}}</span>
        <span class="name-creator">Creada por {{getNameOfCreator(order)}}</span>
    </div>
</td>

<td *ngIf="viewSelected?.id" [class.disabled]="isDisabled" [class.is-returned]="isReturned">
    {{order._id}}
</td>