import { Action, createReducer, on } from "@ngrx/store";
import { VIEW_ORDER_ALL, VIEW_ORDER_VOID } from "src/app/shared/const/views.const";
import { OrderReducer } from "src/app/shared/interfaces/order.interface";
import * as actions from '../actions/order.action';
import { buyerType } from "src/app/shared/const/options";
import { howSellValidationInitialState, initialOrderString, orderInitialState, rucaAndSisaInitialState, validationInitialState } from "src/app/shared/const/orders";

export const initialState: OrderReducer = {
    orderBoard: {
        all: [],
        allPipe: [],
        allPreviusOrders: [],
        buyerType: buyerType.COMMODITIES,
        filter: initialOrderString,
        indexFilter:-1,
        ordersSelected:[],
        isOrderReturned:false,
        orderDetails: orderInitialState,
        showOrderDetails: false
    },
    generateOrder: orderInitialState,
    //----------------\\
    generateOrders: [orderInitialState],
    indexToEdit: 0,
    numOfOrdersToGenerate:1,
    //----------------\\
    validation: validationInitialState,
    rucaAndSisa: rucaAndSisaInitialState,
    howSellValidation: howSellValidationInitialState,
    validPxQ: '',
    edit: {
        ordersEdit: [],
        indexToEdit: 0,
        editAll: false,
        isGenerateEraser:true,
    },
    loaderSendToIBM: 0,

    viewsOfOrderBoard: {
        views: [],
        newView: VIEW_ORDER_VOID,
        indexViewSelected: -2,
        viewSelected: {
            ...VIEW_ORDER_ALL,
            viewName: 'default'
        }
    },
    historical: ''
};

const _orderReducer = createReducer(
    initialState,

     //Order to generate
     on(actions.setOrderToGenerate, (state, action) => {
        return {
            ...state,
            generateOrder: action.order
        }
    }),
    on(actions.setPriceToGenerateInOrder, (state, action) => {
        return {
            ...state,
            generateOrder: {
                ...state.generateOrder,
                price: action.price
            }
        }
    }),

    on(actions.setCommisionInOrder, (state, action) => {
        return {
            ...state,
            generateOrder: {
                ...state.generateOrder,
                commision: action.commission
            }
        }
    }),

    on(actions.setRestriction, (state, action) => {
        return {
            ...state,
            generateOrder: {
                ...state.generateOrder,
                buyer: action.restriction
            }
        }
    }),

    on(actions.setValidation, (state, action) => {
        return {
            ...state,
            validation: action.validation
        }
    }),
    on(actions.setRucaAndSisa, (state, action) => {
        return {
            ...state,
            rucaAndSisa: action.rucaAndSisa
        }
    }),
    on(actions.setHowSellValidation, (state, action) => {
        return {
            ...state,
            howSellValidation: action.howSellValidation
        }
    }),
    on(actions.setValidPxQ, (state, action) => {
        return {
            ...state,
            validPxQ: action.validPxQ
        }
    }),


    on(actions.resetOrderToGenerate, (state, action) => {
        return {
            ...state,
            generateOrder: initialState.generateOrder
        }
    }),

    on(actions.setNumOfDuplicateOrders, (state, action) => {
        return {
            ...state,
            numOfOrdersToGenerate: action.numOfDuplicateOrders
        }
    }),

    on(actions.setOrdersToGenerate, (state, action) => {
        return {
            ...state,
            generateOrders: action.orders
        }
    }),

    on(actions.setIndexToEdit, (state, action) => {
        return {
            ...state,
            indexToEdit: action.indexToEdit
        }
    }),


    on(actions.loadOrdersAllSuccess, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                all: action.orders
            }

        };
    }),

    on(actions.loadOrdersAllPrevius, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                allPreviusOrders: action.orders
            }
        }
    }),

    on(actions.loadOrdersAllWithPipesSuccess, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                allPipe: action.orders
            }
        }
    }),

    //Is order returned
    on(actions.setIsOrderReturned, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                isOrderReturned: action.isOrderReturned
            }

        };
    }),



    ////----------------------FILTERS------------------------\\\\
    
    on(actions.setFilterOrder, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                filter: action.orderString
            }

        };
    }),
    on(actions.setBuyerTypeFilter, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                buyerType: action.buyerType
            }

        };
    }),

    on(actions.setFilterIndex, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                indexFilter: action.index
            }
        };
    }),
    ////----------------------VIEWS------------------------\\\\

    on(actions.setViewToGenerateO, (state, action) => {
        return {
            ...state,
            viewsOfOrderBoard: {
                ...state.viewsOfOrderBoard,
                newView: action.view
            }
        }
    }),
    on(actions.resetNewViewO, (state, action) => {
        return {
            ...state,
            viewsOfOrderBoard: {
                ...state.viewsOfOrderBoard,
                newView: initialState.viewsOfOrderBoard.newView
            }
        }
    }),
    on(actions.setViewSelectedO, (state, action) => {
        return {
            ...state,
            viewsOfOrderBoard: {
                ...state.viewsOfOrderBoard,
                viewSelected: action.view
            }
        }
    }),
    on(actions.resetViewSelectedO, (state, action) => {
        return {
            ...state,
            viewsOfOrderBoard: {
                ...state.viewsOfOrderBoard,
                viewSelected: initialState.viewsOfOrderBoard.viewSelected
            }
        }
    }),
    on(actions.setAllViewsO, (state, action) => {
        return {
            ...state,
            viewsOfOrderBoard: {
                ...state.viewsOfOrderBoard,
                views: action.views
            }
        }
    }),
    on(actions.setIndexViewSelected, (state, action) => {
        return {
            ...state,
            viewsOfOrderBoard: {
                ...state.viewsOfOrderBoard,
                indexViewSelected: action.index
            }
        }
    }),

    //----------------------ORDERS SELECTED----------------------\\
    //Select prices
    on(actions.setOrdersSelected, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                ordersSelected:action.orders
            }
        }
    }),


    //---------------------Orders Edit------------------------\\
    on(actions.setValidationOrdersToGenerate, (state, action) => {
        return {
            ...state,
            edit: {
                ...state.edit,
                ordersEdit: action.orders
            }
        }
    }),

    on(actions.setIndexToEditValidation, (state, action) => {
        return {
            ...state,
            edit: {
                ...state.edit,
                indexToEdit: action.indexToEdit
            }
        }
    }),
    on(actions.setEditAll, (state, action) => {
        return {
            ...state,
            edit: {
                ...state.edit,
                editAll: action.editAll
            }
        }
    }),
    on(actions.isGenerateEraser, (state, action)=> {
        return {
            ...state,
            edit: {
                ...state.edit,
                isGenerateEraser: action.isGenerateEraser
            }
        }
    }),

    //Price details
    on(actions.setOrderDetails, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                orderDetails: action.order
            }
        }
    }),
    on(actions.showOrderDetails, (state, action) => {
        return {
            ...state,
            orderBoard: {
                ...state.orderBoard,
                showOrderDetails: action.show
            }
        }
    }),

    //Loader send to IBM
    on(actions.setLoaderSendToIBM, (state, action) => {
        return {
            ...state,
            loaderSendToIBM: action.loader
        }
    }),
    on(actions.addLoaderSendToIBM, (state, action) => {
        return {
            ...state,
            loaderSendToIBM: state.loaderSendToIBM + 1
        }
    }),
    on(actions.discountLoaderSendToIBM, (state, action) => {
        return {
            ...state,
            loaderSendToIBM: state.loaderSendToIBM - 1
        }
    }),

    //Historical
    on(actions.setHistorical, (state, action) => {
        return {
            ...state,
            historical: action.historical
        }
    }),
)


export function orderReducer(state: any, action: Action) {
    return _orderReducer(state, action);
}

