<div class="table-container">
    <table>
        <thead>
            <tr>
                <th class="head-col" id="col1"><span>Producto</span></th>
                <th class="head-col" id="col2"><span>Período de entrega</span></th>
                <th class="head-col" id="col3"><span>Precio</span></th>
                <th class="head-col" id="col4"><span>Toneladas</span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order of orders |filterOrderDashboard:user:orderStatus">
                <td>
                    <div class="image-name row-left gap-8">
                        <img class="product-image" (error)="onErrorImg($event)"
                            src="../../../../../../assets/images/products/{{PRODUCTS[order.price.productName]}}.svg" alt="product">
                        <span>{{PRODUCTS[order.price.productName]}}</span>
                    </div>
                </td>
                <td>
                    <span class="contenido">{{order.price.deliveryPeriod.startDate}} / {{order.price.deliveryPeriod.endDate}}</span>
                </td>
                <td>
                    <span class="contenido">{{getTypeCoinAndPrice(order)}}</span>
                </td>
                <td>
                    <span class="contenido">{{order.tons}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>