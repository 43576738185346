export const sanJuanLocalities=[
    {"afip":"76","location":"Acerillos"},
    {"afip":"94","location":"Adan Quiroga"},
    {"afip":"109","location":"Aeropuerto San Juan"},
    {"afip":"125","location":"Agua Cercada"},
    {"afip":"135","location":"Agua De La Zanja"},
    {"afip":"136","location":"Agua De La Zorra"},
    {"afip":"139","location":"Agua De Los Caballos"},
    {"afip":"170","location":"Agua Y Energia"},
    {"afip":"178","location":"Aguada De La Peña"}
    ,
    {"afip":"195","location":"Aguaditas"},
    {"afip":"196","location":"Aguaditas Del Rio Jachal"},
    {"afip":"197","location":"Aguango"},
    {"afip":"211","location":"Aguas Del Pajaro"},
    {"afip":"236","location":"Alamito"},
    {"afip":"250","location":"Albarracin"},
    {"afip":"262","location":"Alcaucha"},
    {"afip":"336","location":"Algarrobo Del Cura"},
    {"afip":"339","location":"Algarrobo Verde"},
    {"afip":"424","location":"Alto De Sierra"},
    {"afip":"443","location":"Alto Huaco"},
    {"afip":"492","location":"Amarfil"},
    {"afip":"495","location":"Ambas Puntillas"},
    {"afip":"514","location":"Ampacama"},
    {"afip":"568","location":"Angaco Norte"},
    {"afip":"569","location":"Angaco Sud"},
    {"afip":"581","location":"Angualasto"},
    {"afip":"628","location":"Apeadero Guanacache"},
    {"afip":"629","location":"Apeadero Las Chimbas"},
    {"afip":"630","location":"Apeadero Quiroga"},
    {"afip":"705","location":"Arrequintin"},
    {"afip":"895","location":"Aschichusca"},
    {"afip":"906","location":"Astica"},
    {"afip":"933","location":"Aurora"},
    {"afip":"958","location":"Azucarera De Cuyo"},
    {"afip":"1075","location":"Balde De Leyes"},
    {"afip":"1084","location":"Balde Del Lucero"},
    {"afip":"1085","location":"Balde Del Norte"},
    {"afip":"1087","location":"Balde Del Rosario"},
    {"afip":"1100","location":"Balde Plumerito"},
    {"afip":"1105","location":"Balde San Carlos"},
    {"afip":"1108","location":"Baldecito"},
    {"afip":"1109","location":"Baldecito Del Morado"},
    {"afip":"1113","location":"Baldes De La Chilca"},
    {"afip":"1115","location":"Baldes Del Sud"},
    {"afip":"1116","location":"Baldes Del Tarabay"},
    {"afip":"1189","location":"Barranca Colorada"},
    {"afip":"1190","location":"Barranca De Los Loros"},
    {"afip":"1194","location":"Barrancas Blancas"},
    {"afip":"1199","location":"Barreal"},
    {"afip":"1205","location":"Barreales"},
    {"afip":"1218","location":"Barrio Agua Y Energia"},
    {"afip":"1238","location":"Barrio Colon"},
    {"afip":"1256","location":"Barrio Graffigna"},
    {"afip":"1300","location":"Barrio Rawson"},
    {"afip":"1316","location":"Barrio Santa Barbara"},
    {"afip":"1402","location":"Baños Centenario"}
    ,
    {"afip":"1405","location":"Baños De La Laja"}
    ,
    {"afip":"1406","location":"Baños De Los Despoblados"}
    ,
    {"afip":"1407","location":"Baños De San Crispin"}
    ,
    {"afip":"1408","location":"Baños Del Cerro"}
    ,
    {"afip":"1409","location":"Baños Del Salado"}
    ,
    {"afip":"1412","location":"Baños Pismanta"}
    ,
    {"afip":"1517","location":"Bodega Graffigna"},
    {"afip":"1518","location":"Bodega San Antonio"},
    {"afip":"1640","location":"Cabecera Del Barrial"},
    {"afip":"1647","location":"Cabeza Del Toro"},
    {"afip":"1688","location":"Cacho Ancho"},
    {"afip":"1718","location":"Cajon De Los Tambillos"},
    {"afip":"1756","location":"Calibar"},
    {"afip":"1762","location":"Calingasta"},
    {"afip":"1763","location":"Calle Aguileras"},
    {"afip":"1764","location":"Calle Larga"},
    {"afip":"1766","location":"Calle Nacional"},
    {"afip":"1768","location":"Callecita"},
    {"afip":"1808","location":"Camp D P V La Cienaga"},
    {"afip":"1826","location":"Campanario Nuevo"},
    {"afip":"1833","location":"Campo Afuera"},
    {"afip":"1876","location":"Campo De Batalla"},
    {"afip":"1896","location":"Campo Del Leoncito"},
    {"afip":"1957","location":"Campo Las Liebres"},
    {"afip":"1965","location":"Campo Los Pozos"},
    {"afip":"2138","location":"Capitan Lazo"},
    {"afip":"2211","location":"Carpinteria"},
    {"afip":"2237","location":"Carrizalito"},
    {"afip":"2254","location":"Casa De Javier"},
    {"afip":"2308","location":"Castaño"}
    ,
    {"afip":"2309","location":"Castaño Nuevo"}
    ,
    {"afip":"2310","location":"Castaño Viejo"}
    ,
    {"afip":"2340","location":"Caucete"},
    {"afip":"2383","location":"Cañada De Laguna"}
    ,
    {"afip":"2406","location":"Cañada Del Pozo"}
    ,
    {"afip":"2502","location":"Centro Aviacion Civil San Juan"},
    {"afip":"2527","location":"Cerro  D  L  Bañitos"}
    ,
    {"afip":"2533","location":"Cerro Aguaditas"},
    {"afip":"2540","location":"Cerro Alto Del Descubrimiento"},
    {"afip":"2542","location":"Cerro Amarillo"},
    {"afip":"2545","location":"Cerro Asilan"},
    {"afip":"2555","location":"Cerro Boleadora"},
    {"afip":"2557","location":"Cerro Bramadero"},
    {"afip":"2559","location":"Cerro Caballo Anca"},
    {"afip":"2560","location":"Cerro Caballo Bayo"},
    {"afip":"2561","location":"Cerro Casa De Piedra"},
    {"afip":"2575","location":"Cerro Cortadera"},
    {"afip":"2579","location":"Cerro De Conconta"},
    {"afip":"2580","location":"Cerro De Dolores"},
    {"afip":"2583","location":"Cerro De La Cuesta Del Viento"},
    {"afip":"2588","location":"Cerro De La Sepultura"},
    {"afip":"2591","location":"Cerro De Las Vacas"},
    {"afip":"2593","location":"Cerro De Los Caballos"},
    {"afip":"2598","location":"Cerro De Los Pozos"},
    {"afip":"2603","location":"Cerro Del Agua De Las Vacas"},
    {"afip":"2605","location":"Cerro Del Alumbre"},
    {"afip":"2606","location":"Cerro Del Cachiyuyal"},
    {"afip":"2608","location":"Cerro Del Coquimbito"},
    {"afip":"2609","location":"Cerro Del Guanaco"},
    {"afip":"2614","location":"Cerro Del Salado"},
    {"afip":"2615","location":"Cerro Del Tome"},
    {"afip":"2618","location":"Cerro Divosadero"},
    {"afip":"2620","location":"Cerro El Bronce"},
    {"afip":"2621","location":"Cerro El Cepo"},
    {"afip":"2622","location":"Cerro El Durazno"},
    {"afip":"2623","location":"Cerro El Frances"},
    {"afip":"2625","location":"Cerro Espantajo"},
    {"afip":"2633","location":"Cerro Guanaquero"},
    {"afip":"2634","location":"Cerro Hediondo"},
    {"afip":"2635","location":"Cerro Hornito"},
    {"afip":"2637","location":"Cerro Iman"},
    {"afip":"2638","location":"Cerro Infiernillo"},
    {"afip":"2640","location":"Cerro Jaguel"},
    {"afip":"2641","location":"Cerro Joaquin"},
    {"afip":"2644","location":"Cerro La Bolsa"},
    {"afip":"2646","location":"Cerro La Cañada Amarilla"}
    ,
    {"afip":"2647","location":"Cerro La Cienaga"},
    {"afip":"2648","location":"Cerro La Colorada"},
    {"afip":"2649","location":"Cerro La Flecha"},
    {"afip":"2650","location":"Cerro La Fortuna"},
    {"afip":"2652","location":"Cerro La Jarilla"},
    {"afip":"2654","location":"Cerro La Ortiga"},
    {"afip":"2655","location":"Cerro La Rinconada"},
    {"afip":"2657","location":"Cerro La Ventanita"},
    {"afip":"2659","location":"Cerro Lagunita"},
    {"afip":"2660","location":"Cerro Lajitas"},
    {"afip":"2661","location":"Cerro Las Barrancas"},
    {"afip":"2662","location":"Cerro Las Mulas"},
    {"afip":"2663","location":"Cerro Las Mulitas"},
    {"afip":"2665","location":"Cerro Las Placetas"},
    {"afip":"2666","location":"Cerro Las Raices"},
    {"afip":"2667","location":"Cerro Las Yeguas"},
    {"afip":"2668","location":"Cerro Lavaderos"},
    {"afip":"2673","location":"Cerro Los Mogotes"},
    {"afip":"2674","location":"Cerro Los Patos"},
    {"afip":"2675","location":"Cerro Los Pozos"},
    {"afip":"2682","location":"Cerro Mercedario"},
    {"afip":"2687","location":"Cerro Mudadero"},
    {"afip":"2690","location":"Cerro Negro De Chita"},
    {"afip":"2691","location":"Cerro Negro Del Corral"},
    {"afip":"2695","location":"Cerro Nico"},
    {"afip":"2696","location":"Cerro Ocucaros"},
    {"afip":"2697","location":"Cerro Pachaco"},
    {"afip":"2702","location":"Cerro Panteon"},
    {"afip":"2703","location":"Cerro Pata De Indio"},
    {"afip":"2706","location":"Cerro Pichereguas"},
    {"afip":"2708","location":"Cerro Pintado"},
    {"afip":"2709","location":"Cerro Pircas"},
    {"afip":"2713","location":"Cerro Potrerito De Agua Blanco"},
    {"afip":"2714","location":"Cerro Potrero"},
    {"afip":"2724","location":"Cerro Riquiliponche"},
    {"afip":"2729","location":"Cerro Santa Rosa"},
    {"afip":"2731","location":"Cerro Sasito"},
    {"afip":"2732","location":"Cerro Senda Azul"},
    {"afip":"2734","location":"Cerro Silvio"},
    {"afip":"2735","location":"Cerro Silvo"},
    {"afip":"2737","location":"Cerro Tambolar"},
    {"afip":"2740","location":"Cerro Tres Mogotes"},
    {"afip":"2741","location":"Cerro Tres Puntas"},
    {"afip":"2746","location":"Cerro Villa Loncito"},
    {"afip":"2747","location":"Cerro Villicun"},
    {"afip":"2829","location":"Champones"},
    {"afip":"2834","location":"Chanchos"},
    {"afip":"2841","location":"Chaparro"},
    {"afip":"2890","location":"Chañar Pintado"}
    ,
    {"afip":"2896","location":"Chañar Seco"}
    ,
    {"afip":"2921","location":"Chepical"},
    {"afip":"2923","location":"Chica Negra"},
    {"afip":"2933","location":"Chigua De Abajo"},
    {"afip":"2958","location":"Chimbas"},
    {"afip":"2980","location":"Chisnasco"},
    {"afip":"3013","location":"Chucuma"},
    {"afip":"3041","location":"Cienaguillos"},
    {"afip":"3097","location":"Cochagual"},
    {"afip":"3120","location":"Colanguil"},
    {"afip":"3121","location":"Colanqui"},
    {"afip":"3135","location":"Coll"},
    {"afip":"3147","location":"Colola"},
    {"afip":"3284","location":"Colonia Cantoni"},
    {"afip":"3296","location":"Colonia Castro Padin"},
    {"afip":"3387","location":"Colonia El Molino"},
    {"afip":"3440","location":"Colonia Fiorito"},
    {"afip":"3441","location":"Colonia Fiscal Sarmiento"},
    {"afip":"3480","location":"Colonia Gutierrez"},
    {"afip":"3525","location":"Colonia Juan Solari"},
    {"afip":"3719","location":"Colonia Moya"},
    {"afip":"3811","location":"Colonia Richet"},
    {"afip":"3818","location":"Colonia Rodas"},
    {"afip":"3820","location":"Colonia Rodriguez Zavalla"},
    {"afip":"3964","location":"Colonia Yorner"},
    {"afip":"3966","location":"Colonia Zabala"},
    {"afip":"3969","location":"Colonia Zapata"},
    {"afip":"4014","location":"Concota"},
    {"afip":"4021","location":"Condor Muerto"},
    {"afip":"4034","location":"Contegrand"},
    {"afip":"4138","location":"Corral De Pirca"},
    {"afip":"4231","location":"Coyon"},
    {"afip":"4266","location":"Cruz De San Pedro"},
    {"afip":"4311","location":"Cuchillazo"},
    {"afip":"4327","location":"Cuesta Viejo"},
    {"afip":"4336","location":"Culebra"},
    {"afip":"4341","location":"Cumiyango"},
    {"afip":"4369","location":"Cuyo"},
    {"afip":"4404","location":"Desamparados"},
    {"afip":"4435","location":"Diaz Velez"},
    {"afip":"4443","location":"Difunta Correa"},
    {"afip":"4448","location":"Dique Cauquenes"},
    {"afip":"4459","location":"Dique Las Crucecitas"},
    {"afip":"4467","location":"Dique Soldano"},
    {"afip":"4468","location":"Dique Toma"},
    {"afip":"4477","location":"Divisadero De La Mujer Helada"},
    {"afip":"4479","location":"Divisoria"},
    {"afip":"4506","location":"Domingo De Oro"},
    {"afip":"4539","location":"Dos Acequias"},
    {"afip":"4551","location":"Dos Mojones"},
    {"afip":"4556","location":"Dos Puentes"},
    {"afip":"4614","location":"El Abanico"},
    {"afip":"4815","location":"El Chacrero"},
    {"afip":"4822","location":"El Chamizudo"},
    {"afip":"4840","location":"El Chinguillo"},
    {"afip":"4850","location":"El Chupino"},
    {"afip":"4960","location":"El Fical"},
    {"afip":"5023","location":"El Jaboncito"},
    {"afip":"5045","location":"El Lechuzo"},
    {"afip":"5092","location":"El Mogote Chimbas"},
    {"afip":"5246","location":"El Plumerito"},
    {"afip":"5276","location":"El Pozo Del 20"},
    {"afip":"5283","location":"El Puerto"},
    {"afip":"5348","location":"El Salitre"},
    {"afip":"5447","location":"El Treinta"},
    {"afip":"5500","location":"El Volcan"},
    {"afip":"5575","location":"Encon"},
    {"afip":"5650","location":"Est Albardon"},
    {"afip":"5651","location":"Est De Herrera Vegas"},
    {"afip":"5652","location":"Est La Cienaga De Gualila"},
    {"afip":"5653","location":"Est Marayes"},
    {"afip":"5654","location":"Est Niquivil"},
    {"afip":"5708","location":"Estacion La Rinconada"},
    {"afip":"5742","location":"Estancia Acequion"},
    {"afip":"5749","location":"Estancia Bajo De Las Tumanas"},
    {"afip":"5757","location":"Estancia Casa Rio Blanco"},
    {"afip":"5794","location":"Estancia El Chañar Nuevo"}
    ,
    {"afip":"5798","location":"Estancia El Durazno"},
    {"afip":"5799","location":"Estancia El Jumeal"},
    {"afip":"5801","location":"Estancia El Molino"},
    {"afip":"5805","location":"Estancia El Polear"},
    {"afip":"5816","location":"Estancia El Totoral"},
    {"afip":"5817","location":"Estancia Elizondo"},
    {"afip":"5846","location":"Estancia La Escalera"},
    {"afip":"5848","location":"Estancia La Florida"},
    {"afip":"5855","location":"Estancia La Lata"},
    {"afip":"5866","location":"Estancia La Posta"},
    {"afip":"5871","location":"Estancia La Puntilla"},
    {"afip":"5901","location":"Estancia Leoncito"},
    {"afip":"5919","location":"Estancia Maradona"},
    {"afip":"5936","location":"Estancia Quiroga"},
    {"afip":"5941","location":"Estancia Rio Verde"},
    {"afip":"5963","location":"Estancia San Roque"},
    {"afip":"6101","location":"Fierro"},
    {"afip":"6102","location":"Fierro Nuevo"},
    {"afip":"6103","location":"Fierro Viejo"},
    {"afip":"6107","location":"Filo Del Mocho"},
    {"afip":"6120","location":"Finca De Izasa"},
    {"afip":"6121","location":"Finca Del Japones"},
    {"afip":"6126","location":"Finca El Molino"},
    {"afip":"6127","location":"Finca El Toro"},
    {"afip":"6152","location":"Finca Zapata"},
    {"afip":"6352","location":"Gendarmeria Nacional"},
    {"afip":"6524","location":"Gran China"},
    {"afip":"6553","location":"Guachipampa"},
    {"afip":"6562","location":"Guaja"},
    {"afip":"6577","location":"Guanacache"},
    {"afip":"6612","location":"Guayaguas"},
    {"afip":"6614","location":"Guayamas"},
    {"afip":"6628","location":"Guañizul"}
    ,
    {"afip":"6650","location":"Hachango"},
    {"afip":"6711","location":"Hilario"},
    {"afip":"6754","location":"Hosteria El Balde"},
    {"afip":"6775","location":"Huaicos"},
    {"afip":"6811","location":"Huañizuil"}
    ,
    {"afip":"6822","location":"Huerta De Guachi"},
    {"afip":"6826","location":"Hueso Quebrado"},
    {"afip":"6872","location":"Ichigualasto"},
    {"afip":"6877","location":"Iglesia"},
    {"afip":"6892","location":"Imsa"},
    {"afip":"6901","location":"Indio Muerto"},
    {"afip":"6938","location":"Ingeniero Matias G Sanchez"},
    {"afip":"7006","location":"Ischigualasto"},
    {"afip":"7035","location":"Isla Del Sauce"},
    {"afip":"7103","location":"Jachal"},
    {"afip":"7118","location":"Jaguelito"},
    {"afip":"7131","location":"Jarilla Chica"},
    {"afip":"7133","location":"Jarillito"},
    {"afip":"7176","location":"Jose Marti"},
    {"afip":"7192","location":"Juan Celani"},
    {"afip":"7256","location":"Junta De Santa Rosa"},
    {"afip":"7257","location":"Juntas De La Jarilla"},
    {"afip":"7258","location":"Juntas De La Sal"},
    {"afip":"7260","location":"Juntas Del Frio"},
    {"afip":"7261","location":"Juntas Del Guandacol"},
    {"afip":"7310","location":"La Alumbrera"},
    {"afip":"7410","location":"La Callecita"},
    {"afip":"7428","location":"La Carpa"},
    {"afip":"7460","location":"La Cercada"},
    {"afip":"7473","location":"La Chigua"},
    {"afip":"7480","location":"La Chimbera"},
    {"afip":"7493","location":"La Cienaga De Cumillango"},
    {"afip":"7530","location":"La Cosechera"},
    {"afip":"7616","location":"La Estaca"},
    {"afip":"7676","location":"La Germania"},
    {"afip":"7766","location":"La Legua"},
    {"afip":"7807","location":"La Majadita"},
    {"afip":"7815","location":"La Maral"},
    {"afip":"7863","location":"La Moral"},
    {"afip":"7893","location":"La Orilla"},
    {"afip":"7894","location":"La Orqueta"},
    {"afip":"7899","location":"La Overa"},
    {"afip":"8057","location":"La Ripiera"},
    {"afip":"8067","location":"La Rosita"},
    {"afip":"8073","location":"La Sal"},
    {"afip":"8419","location":"Las Casuarinas"},
    {"afip":"8438","location":"Las Chimbas"},
    {"afip":"8442","location":"Las Cienagas Verdes"},
    {"afip":"8481","location":"Las Espinas"},
    {"afip":"8515","location":"Las Hornillas"},
    {"afip":"8539","location":"Las Liebres"},
    {"afip":"8610","location":"Las Peñitas"}
    ,
    {"afip":"8629","location":"Las Puestas"},
    {"afip":"8637","location":"Las Ramaditas"},
    {"afip":"8689","location":"Las Tumanas"},
    {"afip":"8834","location":"Lloveras"},
    {"afip":"8851","location":"Loma Ancha"},
    {"afip":"8859","location":"Loma De Cocho"},
    {"afip":"8875","location":"Loma Leones"},
    {"afip":"8903","location":"Lomas Del Aguaditas"},
    {"afip":"8968","location":"Los Angacos"},
    {"afip":"8988","location":"Los Baldes De Astica"},
    {"afip":"9001","location":"Los Berros"},
    {"afip":"9049","location":"Los Chaves"},
    {"afip":"9067","location":"Los Cogotes"},
    {"afip":"9099","location":"Los Diaguitas"},
    {"afip":"9157","location":"Los Hornos"},
    {"afip":"9169","location":"Los Lagares"},
    {"afip":"9192","location":"Los Mellizos"},
    {"afip":"9291","location":"Los Quillay"},
    {"afip":"9292","location":"Los Quimbaletes"},
    {"afip":"9300","location":"Los Ranchos"},
    {"afip":"9324","location":"Los Sanchez"},
    {"afip":"9326","location":"Los Sapitos"},
    {"afip":"9335","location":"Los Sombreros"},
    {"afip":"9358","location":"Los Terremotos"},
    {"afip":"9393","location":"Los Viñedos"}
    ,
    {"afip":"9455","location":"Luz Del Mundo"},
    {"afip":"9479","location":"Maclacasto"},
    {"afip":"9504","location":"Maipirinqui"},
    {"afip":"9513","location":"Majadita"},
    {"afip":"9532","location":"Maliman"},
    {"afip":"9533","location":"Maliman Arriba"},
    {"afip":"9534","location":"Maliman De Abajo"},
    {"afip":"9633","location":"Marayes"},
    {"afip":"9694","location":"Marquesado"},
    {"afip":"9726","location":"Matagusanos"},
    {"afip":"9789","location":"Medano De Oro"},
    {"afip":"9796","location":"Media Agua"},
    {"afip":"9830","location":"Mesada Aguada"},
    {"afip":"9846","location":"Mica"},
    {"afip":"9877","location":"Mina De Guachi"},
    {"afip":"9878","location":"Mina De Las Carachas"},
    {"afip":"9880","location":"Mina El Algarrobo"},
    {"afip":"9881","location":"Mina El Pescado"},
    {"afip":"9882","location":"Mina Escondida"},
    {"afip":"9884","location":"Mina General Belgrano"},
    {"afip":"9885","location":"Mina Gualilan"},
    {"afip":"9889","location":"Mina La Abundancia"},
    {"afip":"9891","location":"Mina La Delfina"},
    {"afip":"9892","location":"Mina La Esperanza"},
    {"afip":"9893","location":"Mina La Salamanta"},
    {"afip":"9895","location":"Mina Los Caballos"},
    {"afip":"9897","location":"Mina Montosa"},
    {"afip":"9900","location":"Mina San Antonio"},
    {"afip":"9904","location":"Mina San Jorge"},
    {"afip":"9982","location":"Mogna"},
    {"afip":"10023","location":"Mondaca"},
    {"afip":"10118","location":"Morterito"},
    {"afip":"10179","location":"Naquera"},
    {"afip":"10230","location":"Nikisanga"},
    {"afip":"10239","location":"Niquivil"},
    {"afip":"10240","location":"Niquivil Viejo"},
    {"afip":"10277","location":"Nueva Castilla"},
    {"afip":"10353","location":"Ojos De Agua"},
    {"afip":"10417","location":"Otra Banda"},
    {"afip":"10444","location":"Pachaco"},
    {"afip":"10571","location":"Pampa De Los Caballos"},
    {"afip":"10579","location":"Pampa Del Chañar"}
    ,
    {"afip":"10652","location":"Panacan"},
    {"afip":"10666","location":"Papagayos"},
    {"afip":"10668","location":"Paquita"},
    {"afip":"10684","location":"Paraje Bebida"},
    {"afip":"10771","location":"Pasleam"},
    {"afip":"10795","location":"Paso De Ferreira"},
    {"afip":"10804","location":"Paso De Lamas"},
    {"afip":"10828","location":"Paso De Otarola"},
    {"afip":"10834","location":"Paso Del Agua Negra"},
    {"afip":"10841","location":"Paso Del Lamar"},
    {"afip":"10966","location":"Pedro Echague"},
    {"afip":"11031","location":"Peñasco Colorado"}
    ,
    {"afip":"11032","location":"Peñasquito"}
    ,
    {"afip":"11055","location":"Pichagual"},
    {"afip":"11080","location":"Pie De Palo"},
    {"afip":"11090","location":"Piedra Colorada"},
    {"afip":"11103","location":"Piedra Rajada"},
    {"afip":"11125","location":"Pila De Macho"},
    {"afip":"11141","location":"Pimpa"},
    {"afip":"11173","location":"Pircas Blancas"},
    {"afip":"11175","location":"Pircas Negras"},
    {"afip":"11186","location":"Pismania"},
    {"afip":"11234","location":"Plumerillos"},
    {"afip":"11240","location":"Po Cajon De La Brea"},
    {"afip":"11241","location":"Po De Barahona"},
    {"afip":"11244","location":"Po De La Guardia"},
    {"afip":"11246","location":"Po De Las Llaretas"},
    {"afip":"11247","location":"Po De Las Ojotas"},
    {"afip":"11249","location":"Po De Los Piuquenes"},
    {"afip":"11250","location":"Po De Los Teatinos"},
    {"afip":"11251","location":"Po De Usno"},
    {"afip":"11252","location":"Po Del Chollay"},
    {"afip":"11254","location":"Po Del Inca"},
    {"afip":"11255","location":"Po Del Portillo"},
    {"afip":"11257","location":"Po Del Tontal"},
    {"afip":"11258","location":"Po Las Tortolas"},
    {"afip":"11293","location":"Port De La Punilla"},
    {"afip":"11295","location":"Port De Longomiche"},
    {"afip":"11296","location":"Port De Los Sombreros"},
    {"afip":"11298","location":"Port Del Colorado"},
    {"afip":"11299","location":"Port Las Caracachas"},
    {"afip":"11300","location":"Port Las Chilcas"},
    {"afip":"11301","location":"Port Las Francas"},
    {"afip":"11302","location":"Port San Guillermo"},
    {"afip":"11303","location":"Port Santa Rosa"},
    {"afip":"11336","location":"Porton Grande"},
    {"afip":"11359","location":"Potranca"},
    {"afip":"11386","location":"Potreros Los Amadores"},
    {"afip":"11408","location":"Pozo De Aguadita"},
    {"afip":"11428","location":"Pozo De Los Algarrobos"},
    {"afip":"11523","location":"Presbitero Francisco Perez Her"},
    {"afip":"11549","location":"Pto Ag Del Burro"},
    {"afip":"11550","location":"Pto Aguadita"},
    {"afip":"11551","location":"Pto Aguadita De Abajo"},
    {"afip":"11552","location":"Pto Anjulio"},
    {"afip":"11553","location":"Pto Chanquia"},
    {"afip":"11554","location":"Pto Chavez"},
    {"afip":"11555","location":"Pto Cordova"},
    {"afip":"11556","location":"Pto Cumillango"},
    {"afip":"11557","location":"Pto Del Agua De Pinto"},
    {"afip":"11558","location":"Pto Durazno"},
    {"afip":"11559","location":"Pto El Arbol Ligudo"},
    {"afip":"11560","location":"Pto El Molle"},
    {"afip":"11561","location":"Pto El Sarco"},
    {"afip":"11562","location":"Pto El Toro"},
    {"afip":"11564","location":"Pto Figueroa"},
    {"afip":"11565","location":"Pto Gen"},
    {"afip":"11566","location":"Pto Gordillo"},
    {"afip":"11567","location":"Pto Huasi"},
    {"afip":"11568","location":"Pto La Chilca"},
    {"afip":"11569","location":"Pto La Cortadera"},
    {"afip":"11570","location":"Pto La Espina"},
    {"afip":"11571","location":"Pto La Represa"},
    {"afip":"11572","location":"Pto La Tuna"},
    {"afip":"11573","location":"Pto La Virgencita"},
    {"afip":"11574","location":"Pto Las Cuevas"},
    {"afip":"11575","location":"Pto Lima"},
    {"afip":"11576","location":"Pto Los Alamos"},
    {"afip":"11578","location":"Pto Los Papagallos"},
    {"afip":"11579","location":"Pto Los Pozos"},
    {"afip":"11580","location":"Pto Majadita"},
    {"afip":"11581","location":"Pto Pajarito"},
    {"afip":"11582","location":"Pto Pantanito"},
    {"afip":"11583","location":"Pto Perico"},
    {"afip":"11584","location":"Pto Pescado"},
    {"afip":"11585","location":"Pto Pimpa"},
    {"afip":"11586","location":"Pto Portezuelo Hondo"},
    {"afip":"11587","location":"Pto Potrerillo"},
    {"afip":"11588","location":"Pto Punilla"},
    {"afip":"11589","location":"Pto Recreo"},
    {"afip":"11590","location":"Pto Romero"},
    {"afip":"11591","location":"Pto Sabato"},
    {"afip":"11592","location":"Pto San Isidro"},
    {"afip":"11593","location":"Pto Santa Rosa De Abajo"},
    {"afip":"11594","location":"Pto Segovia"},
    {"afip":"11595","location":"Pto Trapiche"},
    {"afip":"11596","location":"Pto Vallecito"},
    {"afip":"11597","location":"Pto Varejon"},
    {"afip":"11598","location":"Pto Vega"},
    {"afip":"11601","location":"Puchuzun"},
    {"afip":"11705","location":"Puente Rio San Juan"},
    {"afip":"11706","location":"Puente Rufino"},
    {"afip":"11718","location":"Puerta De La Chilca"},
    {"afip":"11731","location":"Puerta Del Infiernillo"},
    {"afip":"11870","location":"Puerto Tapones De Maya"},
    {"afip":"11912","location":"Puesto Angostura"},
    {"afip":"12005","location":"Puesto La Chilca De Abajo"},
    {"afip":"12060","location":"Puesto Olguin"},
    {"afip":"12069","location":"Puesto Retiro"},
    {"afip":"12082","location":"Puesto Santa Rosa"},
    {"afip":"12120","location":"Punta Blanca"},
    {"afip":"12128","location":"Punta De Laguna"},
    {"afip":"12140","location":"Punta Del Medano"},
    {"afip":"12167","location":"Puntilla"},
    {"afip":"12168","location":"Puntilla Blanca"},
    {"afip":"12169","location":"Puntilla Negra"},
    {"afip":"12235","location":"Quilinquil"},
    {"afip":"12257","location":"Quinto Cuartel"},
    {"afip":"12339","location":"Ranchos De Famacoa"},
    {"afip":"12400","location":"Refugio"},
    {"afip":"12401","location":"Refugio D P V"},
    {"afip":"12403","location":"Refugio Los Gauchos"},
    {"afip":"12437","location":"Retamito"},
    {"afip":"12465","location":"Richard"},
    {"afip":"12473","location":"Rincon Colorado"},
    {"afip":"12487","location":"Rincon De La Brea"},
    {"afip":"12489","location":"Rincon De La Ollita"},
    {"afip":"12495","location":"Rincon De Los Chinchilleros"},
    {"afip":"12547","location":"Rincones"},
    {"afip":"12608","location":"Rio Palo"},
    {"afip":"12620","location":"Rio Saso"},
    {"afip":"12726","location":"Ruinas Indigenas"},
    {"afip":"13045","location":"San Juan Bautista Usno"},
    {"afip":"13164","location":"Sanchez De Loria"},
    {"afip":"13172","location":"Sanjuanino"},
    {"afip":"13236","location":"Santa Maria Del Rosario"},
    {"afip":"13438","location":"Sierra Billicum"},
    {"afip":"13447","location":"Sierra De Chaves"},
    {"afip":"13448","location":"Sierra De Elizondo"},
    {"afip":"13454","location":"Sierra De Rivero"},
    {"afip":"13542","location":"Sorocayense"},
    {"afip":"13705","location":"Talacasto"},
    {"afip":"13734","location":"Tamberias"},
    {"afip":"13748","location":"Tap Gallardo"},
    {"afip":"13824","location":"Terma La Laja"},
    {"afip":"13825","location":"Terma Pismanta"},
    {"afip":"13827","location":"Termas Agua Hedionda"},
    {"afip":"13828","location":"Termas Centenario"},
    {"afip":"13829","location":"Termas De Agua Negra"},
    {"afip":"13846","location":"Tierra Adentro"},
    {"afip":"13903","location":"Tira Larga"},
    {"afip":"13917","location":"Tocota"},
    {"afip":"13945","location":"Tontal"},
    {"afip":"13993","location":"Totoralito"},
    {"afip":"14021","location":"Travesia De Mogna"},
    {"afip":"14085","location":"Tres Quebraditas"},
    {"afip":"14099","location":"Trinidad"},
    {"afip":"14137","location":"Tucunuco"},
    {"afip":"14139","location":"Tudcum"},
    {"afip":"14148","location":"Tumanas"},
    {"afip":"14151","location":"Tuminico"},
    {"afip":"14159","location":"Tupeli"},
    {"afip":"14174","location":"Tutianca"},
    {"afip":"14192","location":"Ullum"},
    {"afip":"14215","location":"Usina"},
    {"afip":"14218","location":"Usno"},
    {"afip":"14258","location":"Valle Del Cura"},
    {"afip":"14265","location":"Valle Fertil"},
    {"afip":"14316","location":"Venillo"},
    {"afip":"14369","location":"Villa"},
    {"afip":"14370","location":"Villa  P A D Sarmiento"},
    {"afip":"14371","location":"Villa 20 De Junio"},
    {"afip":"14372","location":"Villa Aberastain"},
    {"afip":"14416","location":"Villa Barboza"},
    {"afip":"14417","location":"Villa Basilio Nievas"},
    {"afip":"14422","location":"Villa Bermejito"},
    {"afip":"14428","location":"Villa Borjas"},
    {"afip":"14444","location":"Villa Carlota"},
    {"afip":"14447","location":"Villa Carolina"},
    {"afip":"14470","location":"Villa Colon"},
    {"afip":"14479","location":"Villa Corral"},
    {"afip":"14506","location":"Villa Del Salvador"},
    {"afip":"14520","location":"Villa Dominguito"},
    {"afip":"14548","location":"Villa Estevez"},
    {"afip":"14554","location":"Villa Fleury"},
    {"afip":"14563","location":"Villa Franca"},
    {"afip":"14570","location":"Villa General Acha"},
    {"afip":"14574","location":"Villa General Las Heras"},
    {"afip":"14577","location":"Villa General San Martin"},
    {"afip":"14578","location":"Villa General Sarmiento"},
    {"afip":"14584","location":"Villa Gobernador Chavez"},
    {"afip":"14604","location":"Villa Huasihul"},
    {"afip":"14613","location":"Villa J C Sarmiento"},
    {"afip":"14618","location":"Villa Juan Xxiii"},
    {"afip":"14619","location":"Villa Krause"},
    {"afip":"14635","location":"Villa Laprida"},
    {"afip":"14645","location":"Villa Lerga"},
    {"afip":"14661","location":"Villa Lugano"},
    {"afip":"14664","location":"Villa Luz Del Mundo"},
    {"afip":"14679","location":"Villa Marini"},
    {"afip":"14690","location":"Villa Media Agua"},
    {"afip":"14702","location":"Villa Morrones"},
    {"afip":"14706","location":"Villa Muñoz"}
    ,
    {"afip":"14707","location":"Villa N Larrain"},
    {"afip":"14708","location":"Villa Nacusi"},
    {"afip":"14728","location":"Villa Palermo"},
    {"afip":"14733","location":"Villa Patricias Sanjuaninas"},
    {"afip":"14747","location":"Villa Pueyrredon"},
    {"afip":"14754","location":"Villa Rachel"},
    {"afip":"14770","location":"Villa Rizzo"},
    {"afip":"14782","location":"Villa Rufino Gomez"},
    {"afip":"14807","location":"Villa Santa Anita"},
    {"afip":"14811","location":"Villa Santa Paula"},
    {"afip":"14819","location":"Villa Sargento Cabral"},
    {"afip":"14981","location":"Yacimiento De Cobre El Pachon"},
    {"afip":"15051","location":"Yoca"},
    {"afip":"15104","location":"Zavalla"},
    {"afip":"15120","location":"Zonda"},
    {"afip":"15163","location":"9 De Julio"},
    {"afip":"15186","location":"Agua Escondida"},
    {"afip":"15233","location":"Algarrobo Grande"},
    {"afip":"15358","location":"Barrealito"},
    {"afip":"15362","location":"Barrialitos"},
    {"afip":"15376","location":"Belgrano"},
    {"afip":"15388","location":"Bella Vista"},
    {"afip":"15397","location":"Bermejo"},
    {"afip":"15400","location":"Boca De La Quebrada"},
    {"afip":"15415","location":"Buena Esperanza"},
    {"afip":"15488","location":"Carbometal"},
    {"afip":"15509","location":"Casa Vieja"},
    {"afip":"15522","location":"Cañada"}
    ,
    {"afip":"15532","location":"Cañada Honda"}
    ,
    {"afip":"15566","location":"Cerro Aguila"},
    {"afip":"15568","location":"Cerro Aspero"},
    {"afip":"15571","location":"Cerro Bayo"},
    {"afip":"15574","location":"Cerro Blanco"},
    {"afip":"15576","location":"Cerro Bola"},
    {"afip":"15577","location":"Cerro Bonete"},
    {"afip":"15579","location":"Cerro Bravo"},
    {"afip":"15580","location":"Cerro Chiquero"},
    {"afip":"15585","location":"Cerro Colorado"},
    {"afip":"15587","location":"Cerro De Los Burros"},
    {"afip":"15589","location":"Cerro Del Medio"},
    {"afip":"15590","location":"Cerro Grande"},
    {"afip":"15597","location":"Cerro Negro"},
    {"afip":"15600","location":"Cerro Puntudo"},
    {"afip":"15604","location":"Cerro Tigre"},
    {"afip":"15648","location":"Cienaga"},
    {"afip":"15649","location":"Cienaguita"},
    {"afip":"15656","location":"Colon"},
    {"afip":"15669","location":"Colonia Centenario"},
    {"afip":"15679","location":"Colonia Florida"},
    {"afip":"15702","location":"Colonia Roca"},
    {"afip":"15708","location":"Colonia San Antonio"},
    {"afip":"15809","location":"Cruz De Piedra"},
    {"afip":"15815","location":"Cuatro Esquinas"},
    {"afip":"15828","location":"Divisadero"},
    {"afip":"15897","location":"El Balde"},
    {"afip":"15908","location":"El Barrialito"},
    {"afip":"15920","location":"El Bosque"},
    {"afip":"15927","location":"El Buen Retiro"},
    {"afip":"15956","location":"El Carrizal"},
    {"afip":"15987","location":"El Chañar"}
    ,
    {"afip":"16011","location":"El Corralito"},
    {"afip":"16064","location":"El Fuerte"},
    {"afip":"16069","location":"El Gigantillo"},
    {"afip":"16081","location":"El Infierno"},
    {"afip":"16090","location":"El Leoncito"},
    {"afip":"16107","location":"El Medanito"},
    {"afip":"16108","location":"El Medano"},
    {"afip":"16130","location":"El Molino"},
    {"afip":"16278","location":"El Quemado"},
    {"afip":"16303","location":"El Retiro"},
    {"afip":"16307","location":"El Rincon"},
    {"afip":"16334","location":"El Salado"},
    {"afip":"16343","location":"El Salto"},
    {"afip":"16391","location":"El Tapon"},
    {"afip":"16459","location":"Entre Rios"},
    {"afip":"16547","location":"Germania"},
    {"afip":"16572","location":"Huaco"},
    {"afip":"16647","location":"La Angostura"},
    {"afip":"16719","location":"La Capilla"},
    {"afip":"16742","location":"La Cañada"}
    ,
    {"afip":"16757","location":"La Chilca"},
    {"afip":"16772","location":"La Cienaga"},
    {"afip":"16775","location":"La Cienaguita"},
    {"afip":"16777","location":"La Cieneguita"},
    {"afip":"16790","location":"La Colonia"},
    {"afip":"16825","location":"La Cruz"},
    {"afip":"16888","location":"La Esquina"},
    {"afip":"16902","location":"La Estrechura"},
    {"afip":"16916","location":"La Falda"},
    {"afip":"16941","location":"La Florida"},
    {"afip":"16990","location":"La Huerta"},
    {"afip":"17012","location":"La Isla"},
    {"afip":"17102","location":"La Mesada"},
    {"afip":"17155","location":"La Penca"},
    {"afip":"17219","location":"La Puntilla"},
    {"afip":"17234","location":"La Ramada"},
    {"afip":"17261","location":"La Rinconada"},
    {"afip":"17318","location":"La Toma"},
    {"afip":"17342","location":"La Valentina"},
    {"afip":"17386","location":"Laguna Del Rosario"},
    {"afip":"17395","location":"Laguna Seca"},
    {"afip":"17408","location":"Laprida"},
    {"afip":"17416","location":"Las Aguaditas"},
    {"afip":"17441","location":"Las Casitas"},
    {"afip":"17462","location":"Las Chacras"},
    {"afip":"17467","location":"Las Chacritas"},
    {"afip":"17492","location":"Las Cuevas"},
    {"afip":"17500","location":"Las Delicias"},
    {"afip":"17511","location":"Las Flores"},
    {"afip":"17522","location":"Las Hermanas"},
    {"afip":"17525","location":"Las Higueras"},
    {"afip":"17532","location":"Las Higueritas"},
    {"afip":"17543","location":"Las Juntas"},
    {"afip":"17548","location":"Las Lagunas"},
    {"afip":"17552","location":"Las Lajas"},
    {"afip":"17565","location":"Las Lomitas"},
    {"afip":"17633","location":"Las Piedritas"},
    {"afip":"17660","location":"Las Salinas"},
    {"afip":"17668","location":"Las Tapias"},
    {"afip":"17699","location":"Las Yeguas"},
    {"afip":"17736","location":"Loma Negra"},
    {"afip":"17747","location":"Lomas Blancas"},
    {"afip":"17782","location":"Los Baldecitos"},
    {"afip":"17787","location":"Los Baldes"},
    {"afip":"17789","location":"Los Barriales"},
    {"afip":"17818","location":"Los Chañares"}
    ,
    {"afip":"17833","location":"Los Compartos"},
    {"afip":"17842","location":"Los Corredores"},
    {"afip":"17868","location":"Los Loros"},
    {"afip":"17875","location":"Los Medanos"},
    {"afip":"17889","location":"Los Molles"},
    {"afip":"17901","location":"Los Nogales"},
    {"afip":"17910","location":"Los Papagayos"},
    {"afip":"17915","location":"Los Paramillos"},
    {"afip":"17920","location":"Los Penitentes"},
    {"afip":"17931","location":"Los Porongos"},
    {"afip":"17939","location":"Los Puestos"},
    {"afip":"17952","location":"Los Rincones"},
    {"afip":"18001","location":"Manantiales"},
    {"afip":"18026","location":"Medano Colorado"},
    {"afip":"18044","location":"Milagro"},
    {"afip":"18119","location":"Nueva España"}
    ,
    {"afip":"18155","location":"Pajas Blancas"},
    {"afip":"18191","location":"Pampa Grande"},
    {"afip":"18199","location":"Pampa Vieja"},
    {"afip":"18229","location":"Pedernal"},
    {"afip":"18246","location":"Piedra Blanca"},
    {"afip":"18253","location":"Piedra Parada"},
    {"afip":"18261","location":"Piedras Blancas"},
    {"afip":"18270","location":"Pocito"},
    {"afip":"18292","location":"Potrerillos"},
    {"afip":"18335","location":"Pozo Salado"},
    {"afip":"18347","location":"Primer Cuartel"},
    {"afip":"18368","location":"Puente Nacional"},
    {"afip":"18375","location":"Puerto Alegre"},
    {"afip":"18384","location":"Puesto De Arriba"},
    {"afip":"18408","location":"Punta De Agua"},
    {"afip":"18419","location":"Punta Del Monte"},
    {"afip":"18424","location":"Punta Norte"},
    {"afip":"18445","location":"Quiroga"},
    {"afip":"18455","location":"Ramblon"},
    {"afip":"18479","location":"Rincon"},
    {"afip":"18484","location":"Rincon Chico"},
    {"afip":"18485","location":"Rincon Del Gato"},
    {"afip":"18487","location":"Rincon Grande"},
    {"afip":"18491","location":"Rinconada"},
    {"afip":"18522","location":"Rivadavia"},
    {"afip":"18530","location":"Rodeo"},
    {"afip":"18596","location":"San Antonio"},
    {"afip":"18623","location":"San Carlos"},
    {"afip":"18713","location":"San Isidro"},
    {"afip":"18768","location":"San Juan"},
    {"afip":"18820","location":"San Martin"},
    {"afip":"18895","location":"San Roque"},
    {"afip":"18945","location":"Santa Barbara"},
    {"afip":"18964","location":"Santa Clara"},
    {"afip":"19010","location":"Santa Lucia"},
    {"afip":"19083","location":"Santo Domingo"},
    {"afip":"19105","location":"Sarmiento"},
    {"afip":"19119","location":"Segundo Cuartel"},
    {"afip":"19211","location":"Trancas"},
    {"afip":"19233","location":"Tres Esquinas"},
    {"afip":"19267","location":"Uriburu"},
    {"afip":"19275","location":"Vallecito"},
    {"afip":"19285","location":"Villa Alem"},
    {"afip":"19290","location":"Villa Del Carmen"},
    {"afip":"19305","location":"Villa Independencia"},
    {"afip":"19313","location":"Villa Mercedes"},
    {"afip":"19317","location":"Villa Nueva"},
    {"afip":"19320","location":"Villa Obrera"},
    {"afip":"19326","location":"Villa San Isidro"},
    {"afip":"19334","location":"Villa Santa Rosa"},
    {"afip":"19353","location":"Volcan"},
    {"afip":"19360","location":"Yerba Buena"},
    {"afip":"19375","location":"Rawson"},
    {"afip":"20507","location":"La Cieneguita"},
    {"afip":"20508","location":"La Esquina"},
    {"afip":"20509","location":"La Majadita"},
    {"afip":"20510","location":"Loma Negra"},
    {"afip":"20511","location":"Lomas Blancas"},
    {"afip":"20512","location":"Refugio"},
    {"afip":"20513","location":"Tamberias"},
    {"afip":"20514","location":"Cerro Blanco"},
    {"afip":"20515","location":"Cerro Negro"},
    {"afip":"20516","location":"Cerro Tambolar"},
    {"afip":"20517","location":"Zonda"},
    {"afip":"20530","location":"El Salado"},
    {"afip":"20531","location":"La Cañada"}
    ,
    {"afip":"20532","location":"Manantiales"},
    {"afip":"20533","location":"Peñasquito"}
    ,
    {"afip":"20534","location":"Tamberias"},
    {"afip":"20535","location":"Bella Vista"},
    {"afip":"20536","location":"Cerro Colorado"},
    {"afip":"20537","location":"Cerro Iman"},
    {"afip":"20538","location":"Cerro Negro"},
    {"afip":"20539","location":"Los Puestos"},
    {"afip":"20540","location":"Ojos De Agua"},
    {"afip":"20541","location":"Rincon"},
    {"afip":"20542","location":"Tamberias"},
    {"afip":"20543","location":"Colonia Rodriguez Zavalla"},
    {"afip":"20544","location":"Villa General Acha"},
    {"afip":"20545","location":"La Puntilla"},
    {"afip":"20546","location":"Puntilla Blanca"},
    {"afip":"20547","location":"San Isidro"},
    {"afip":"20548","location":"Callecita"},
    {"afip":"20549","location":"Colonia Zapata"},
    {"afip":"20550","location":"La Legua"},
    {"afip":"20551","location":"Los Viñedos"}
    ,
    {"afip":"20552","location":"Cerro De Los Burros"},
    {"afip":"20553","location":"Cerro Hediondo"},
    {"afip":"20554","location":"Cerro Los Pozos"},
    {"afip":"20555","location":"Colonia Fiorito"},
    {"afip":"20556","location":"La Chilca"},
    {"afip":"20557","location":"San Carlos"},
    {"afip":"20558","location":"Santa Clara"},
    {"afip":"20559","location":"Cienaguita"},
    {"afip":"20625","location":"Diaz Velez"},
    {"afip":"20626","location":"El Rincon"},
    {"afip":"20627","location":"Las Higueritas"},
    {"afip":"20628","location":"Punta Del Medano"},
    {"afip":"20629","location":"San Antonio"},
    {"afip":"20630","location":"Vallecito"},
    {"afip":"20631","location":"Tierra Adentro"},
    {"afip":"20632","location":"La Cañada"}
    ,
    {"afip":"20633","location":"Bella Vista"},
    {"afip":"20634","location":"Cerro Amarillo"},
    {"afip":"20635","location":"Cerro Cortadera"},
    {"afip":"21679","location":"El Chilote"},
    {"afip":"22121","location":"La Punilla"}
    ]