<div class="background-svg">
    <svg class="svg-22" [ngClass]="{'fill-grey-opaque':color, 'fill-grey-opaque-hover':hover}" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <rect y="0.5" width="24" height="24" rx="12" fill="#00C689"/>
        <g clip-path="url(#clip0_3451_199543)">
        <path d="M12.7193 19.2131L16.6225 16.8704C17.1365 16.5617 17.5272 15.9304 17.5318 15.4038L17.6075 6.73684C17.6128 6.12204 17.0173 5.61586 16.281 5.60944L7.4423 5.53232C6.70603 5.5259 6.10173 6.02161 6.09637 6.63641L6.02075 15.3034C6.01613 15.8328 6.39577 16.4709 6.90437 16.7856L10.7661 19.1961C11.3086 19.5367 12.1676 19.5442 12.7193 19.2131ZM11.3101 18.5803L7.44835 16.1698C7.1771 16.0023 6.93525 15.5903 6.93771 15.3085L7.0133 6.64441C7.01497 6.45371 7.20381 6.2988 7.43219 6.30079L16.2743 6.37793C16.5027 6.37993 16.6888 6.53811 16.6871 6.72881L16.6115 15.3958C16.609 15.6776 16.3567 16.0824 16.0859 16.248L12.1827 18.5908C11.9531 18.7254 11.5373 18.7218 11.3101 18.5803Z" fill="white"/>
        <g clip-path="url(#clip1_3451_199543)">
        <path d="M10.875 14C10.7755 14 10.6802 13.9605 10.6098 13.8902L9.10983 12.3902C8.96339 12.2437 8.96339 12.0063 9.10983 11.8598C9.25629 11.7134 9.49373 11.7134 9.64016 11.8598L10.875 13.0947L14.3598 9.60983C14.5063 9.46339 14.7437 9.46339 14.8902 9.60983C15.0366 9.75628 15.0366 9.99371 14.8902 10.1402L11.1402 13.8902C11.0698 13.9605 10.9745 14 10.875 14Z" fill="white"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_3451_199543">
        <rect width="13.8862" height="13.8862" fill="white" transform="translate(19.0078 5.62109) rotate(90.4999)"/>
        </clipPath>
        <clipPath id="clip1_3451_199543">
        <rect width="6" height="6" fill="white" transform="translate(9 8.5)"/>
        </clipPath>
        </defs>
    </svg>

    <div class="tooltip row-center w-100px">
        <span>Autorizar contrato</span>
    </div>

</div>