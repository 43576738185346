import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { typeCoin } from 'src/app/shared/dict/dict';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';
import { OrderValidator } from 'src/app/shared/order';

const URL_DEFAULT='./../../../../assets/images/products/default.svg'

@Component({
  selector: 'app-table-order',
  templateUrl: './table-order.component.html',
  styleUrls: ['./table-order.component.scss', '../../../../shared/styles/table.scss', './../../../../shared/styles/empty-state.scss']
})
export class TableOrderComponent implements OnInit, OnDestroy {
  @Input() orders!: Order[];
  @Input() orderStatus:number=0;
  public user!:User;
  public typeCoins: any;
  public PRODUCTS: any;

  private orderSubscription!:Subscription;
  private userSubscription!:Subscription;

  constructor(private orderV: OrderValidator, private store: Store<appState>) {}

  ngOnInit(): void {
    this.typeCoins = typeCoin
    this.PRODUCTS = PRODUCT_NAMES;

    this.orderSubscription=this.store.select('order').subscribe(orderReducer => {
      this.orders=orderReducer.orderBoard.all;
    })

    this.userSubscription=this.store.select('usuario').subscribe(userReducer => {
      this.user=userReducer;
    });
  }

  ngOnDestroy(): void {
    this.orderSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  onErrorImg(event: any) { 
    event.target.src = URL_DEFAULT
  }

  getTypeCoinAndPrice(order:Order){
    return this.orderV.getTypeCoinAndPrice(order);
  }
}
