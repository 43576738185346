import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setLocation } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { SessionChecker } from 'src/app/shared/session.checker';
const TITLE_MOBILE_EDIT='Editar condición'
@Component({
  selector: 'app-generate-price',
  templateUrl: './generate-price.component.html',
  styleUrls: ['./../../shared/styles/body.scss']
})
export class GeneratePriceComponent implements OnInit,OnDestroy {
  public headerInSection:string='Generar nueva condición';
  public isLoading:boolean=false;

  private header$!:Subscription;
  private load$!:Subscription;
  
  constructor(private store: Store<appState>, private checker: SessionChecker) {
    this.checker.rememberAndActualizateUser(this.store);
    this.store.dispatch(setLocation({location: NAVEGATION.GENERATE_PRICE}));
  }

  ngOnInit(): void {
    this.header$=this.store.select('options', 'headerInMobile').subscribe( (headerInMobile:string) => {
      if(TITLE_MOBILE_EDIT == headerInMobile){
        this.headerInSection = headerInMobile;
      }
    })

    this.load$=this.store.select('options', 'modalCreate', 'isLoading').subscribe( (isLoading:boolean) => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.header$?.unsubscribe();
    this.load$?.unsubscribe();
  }
}
