import { Component, OnInit, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OrderValidator } from 'src/app/shared/order';
import { setShowAddBuyer } from 'src/app/redux/actions/options.action';
import { setEditAll, setIndexToEditValidation, setOrdersSelected, setValidationOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { User } from 'src/app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dialog-confirmed-order',
  templateUrl: './order-confirmed.html',
  styleUrls: ['./order-confirmed.scss'],
})
export class DialogConfirmedOrder implements OnInit,OnDestroy {
  user!:User;
  hour: string = '';
  title: string = 'Se enviará la confirmación';
  ordersSelected!: Order[]; //Store of redux;
  orders!: Order[]; //Store of redux;

  orderSubscription!:Subscription;
  userSubscription!:Subscription;
  constructor(private store: Store<appState>, private date: DateChecker, public dialogRef: MatDialogRef<DialogConfirmedOrder>, 
    private orderV: OrderValidator) {}

  ngOnInit(): void {
    this.hour = this.date.setHourFormat();
    this.orderSubscription=this.store.select('order').subscribe((orderR) => {
      this.ordersSelected = orderR.orderBoard.ordersSelected;
      this.orders = orderR.orderBoard.all;
    });

    this.userSubscription=this.store.select('usuario').subscribe((userR) => {
      this.user = userR;
    });
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  openAddBuyer() {
    this.sendToTickets();
    this.close();
  }

  sendToTickets() {
    this.store.dispatch(setShowAddBuyer({ isShowAddBuyer: true }))

    let validationOrders = JSON.parse(JSON.stringify(this.ordersSelected));

    this.store.dispatch(setValidationOrdersToGenerate({ orders: validationOrders }));
    this.store.dispatch(setEditAll({ editAll: true }));
    this.store.dispatch(setIndexToEditValidation({ indexToEdit: 0 }));
  }

  /*We get the orders that change state in a variable called allOrders
    We update orders. 
Open Add Buyer */
  confirmOrders() {
    let newAllOrders= this.quitOrdersWithStatusConfirm(this.ordersSelected);

    let allOrders = this.orderV.saveOrdersEdited(newAllOrders, orderStatusConst.CONFIRM, '',this.user);
    
    //Obtenemos las ordenes que antes habíamos sacado.
    const ordersToConfirm = this.getOrdersStatusConfirm(this.ordersSelected);
    //Seteamos las ordenes seleccionadas actualizadas.
    this.store.dispatch(setOrdersSelected({ orders: [...allOrders, ...ordersToConfirm] }))

    this.openAddBuyer();
  }

  quitOrdersWithStatusConfirm(orders:Order[]){
    let ordersToReturn:Order[]=[];
    for (let i = 0; i < orders.length; i++) {
      if(orders[i].statusOrder!=orderStatusConst.CONFIRM && orders[i].statusOrder!=orderStatusConst.AUTORIZATION && orders[i].statusOrder!=orderStatusConst.CONTRACT){
        ordersToReturn.push(orders[i]);
      }
    }
    return ordersToReturn;
  }

  getOrdersStatusConfirm(orders:Order[]){
    let ordersToReturn:Order[]=[];
    for (let i = 0; i < orders.length; i++) {
      if(orders[i].statusOrder==orderStatusConst.CONFIRM || orders[i].statusOrder==orderStatusConst.AUTORIZATION || orders[i].statusOrder==orderStatusConst.CONTRACT){
        ordersToReturn.push(orders[i]);
      }
    }
    return ordersToReturn;
  }

  

  close() {
    this.dialogRef.close();
  }
}