import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COMMODITIES_CODE } from 'src/app/shared/const/buyers';
import { Commission, Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

export const ACTIONS_MANY_ORDERS={
  EDIT: 'edit',
  COMMISSION: 'commission'
}

export interface ActionOrderMany{
  action:string,
  index:number,
  commission?:Commission
}

@Component({
  selector: 'app-details-many-orders',
  templateUrl: './details-many-orders.component.html',
  styleUrls: ['./details-many-orders.component.scss','./../details.scss']
})
export class DetailsManyOrdersComponent {
  @Input() orders:Order[]= [];
  @Input() indexSelected:number = 0;
  @Input() editAllOrders:boolean=false;

  @Output() action=new EventEmitter<ActionOrderMany>();

  indexOfShowOrder:number=-1;
  showCommission:number=-1;

  constructor(private orderV:OrderValidator, private toFixV:ToFixValidator) { }

  isOrderSelected(index:number):boolean{
    return index == this.indexSelected || this.editAllOrders;
  }

  editOrder(index:number){
    this.action.emit({action:ACTIONS_MANY_ORDERS.EDIT, index:index});
  }

  getArrowDirection(index:number):string{
    return getArrowDirectionByBool(this.indexOfShowOrder==index);
  }

  showOrder(index:number){
    this.indexOfShowOrder= this.indexOfShowOrder==index ? -1 : index;
  }

  //-------- GETS ------------\\
  getProduct(order:Order){
    return this.orderV.getProductName(order);
  }

  getSellerNameAndTons(order:Order){
    return this.orderV.getSellerNameAndTons(order);
  }

  getSellerName(order:Order){
    return this.orderV.getSellerName(order);
  }
  
  getTypeCoin(order:Order){
    return this.orderV.getTypeCoinString(order);
  }

  getPriceOfCondition(order:Order){
    return this.orderV.getPriceOfCondition(order);
  }

  getConditionType(order:Order){
    return this.toFixV.getConditionTypeByOrder(order);
  
  }

  getIndicators(order:Order){
    return this.toFixV.getIndicatorsByOrder(order);
  }
  
  isOrderToFix(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  //-------- Commission ------------\\
  
  openOneCommission(index:number){
    //this.showCommission=this.showCommission==index ? -1 : index;
  }

  closeCommisionEvent(event:any, index:number){
    event=='close'?this.openOneCommission(-1):''
  }

  setCommission(event:Commission, index:number){
    this.action.emit({action:ACTIONS_MANY_ORDERS.COMMISSION, index:index, commission: event});
  }

  getBuyerCommision(order:Order){
    return order.price.observations.buyer==COMMODITIES_CODE? 0:order.commision.buyer;
  }

}
