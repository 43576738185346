import { Component, OnInit,Output,EventEmitter,HostListener, OnDestroy, AfterContentChecked } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { BUSS_PART_CONST, businessParticularities } from 'src/app/shared/dict/business.particularities';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from './../../fields/fields.component';
import { PriceValidator } from 'src/app/shared/price';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { BUSINESS_PARTICULARITIES_VOID } from 'src/app/shared/const/business-particularities';
import { getIsBonification, getParticularityTag } from 'src/app/shared/business-particularities';
import { Subscription } from 'rxjs';
import { WayPay } from 'src/app/shared/interfaces/price.interface';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';

@Component({
  selector: 'b-p-slide-card',
  templateUrl: './business-particularities.component.html',
  styleUrls: ['./../particularities.scss', './../slide-card.scss']
})
export class BPSlideCardComponent  extends Field implements OnInit, AfterContentChecked, OnDestroy {
  @Output() closeEvent = new EventEmitter<string>();
  options:any[]=[];
  QUANT_MAX_OF_PARTICULARITIES:number=16;
  selectedIndex:number=0;
  isGenerateToFix:boolean=false;

  private optionsBussPartSubscription!:Subscription;

  constructor(store:Store<appState>, private priceV:PriceValidator) { 
    super(store);
    this.array=Object.values(businessParticularities);

    this.optionsBussPartSubscription=this.store.select('options', 'isGenerateToFix').subscribe(isGenerateToFix=>{
      this.isGenerateToFix=isGenerateToFix;
    });
  }

  ngOnInit(): void {
    this.resetTags();
  }

  resetTags(){
    if(fieldType.PRICE == this.fieldIdentificator){
      this.tag=priceTags.businessParticularities;
    } else {
      this.tag=orderTags.price;
      this.subTag=priceTags.businessParticularities;
    }
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.optionsBussPartSubscription?.unsubscribe();

    this.checkInvalidBusinessParticularities();
  }

  ngAfterContentChecked(): void {
    const price=this.getPrice();
    if(price){
      this.options=this.getArrayBusinessParticularities(price.businessParticularities);
    }
  }

  saveElement(event:any){
    let particularity:BusinessParticularities=JSON.parse(event);
    this.options=this.getArrayBusinessParticularities(particularity);
    this.setElementToGenerate(particularity);
  }

  saveNutrien(event:any){
    let particularity:BusinessParticularities=JSON.parse(event);
    this.options=this.getArrayBusinessParticularities(particularity);
    this.setElementToGenerate(particularity);

    const wayPay:WayPay= {
      //Si es a precio: Diferido de la pesificación si es a precio.
      //Si es a fijar: Contra fijación con mercadería entregada. 
      wayPayName: this.isGenerateToFix? wayPayNames.contraFixMercaderiaEntregada: wayPayNames.diferidoPesificacion, 
      percentage: 100,
      expiration: '--',
      days: 10,
      isBusinessDays:1,
      options:0
    }
    this.setFirstTag(priceTags.wayPay);
    this.setElementToGenerate(wayPay);

    this.setFirstTag(priceTags.businessParticularities);
  }

  /*we obtain an array of business particularities*/
  getArrayBusinessParticularities(businessParticularities:BusinessParticularities){
    return this.priceV.getBusinessParticularities(businessParticularities)
  }

  getBusinessParticularities():any{
    return this.getPrice()?.businessParticularities ?? null;
  }

  getPriceOfPrice(){
    return this.getPrice()?.price ?? -1;
  }

  override setShowOptions(boolean: boolean): void {
    this.showOptions=boolean;
    this.selected(0);
  }

  selected(index:number){
    this.selectedIndex=index;
  }

  closeSlide(){
    this.showOptions=false;
  }

  close(){
    this.closeEvent.emit('close');
  }

  @HostListener('document:keydown', ['$event'])
  pressEsc(event: KeyboardEvent){
    if(event.key=='Escape'){
      this.close();
    }
  }

  removeOption(option:string){
    const particularityTag:any=getParticularityTag(option);
    let particularityNow:any=JSON.parse(JSON.stringify(this.getBusinessParticularities()));
    const particularityVoid:any=JSON.parse(JSON.stringify(BUSINESS_PARTICULARITIES_VOID))[particularityTag];
    particularityNow[particularityTag]=particularityVoid;
    this.saveElement(JSON.stringify(particularityNow));
  }

  checkInvalidBusinessParticularities(){
    const bussPart: BusinessParticularities|null=this.getPrice()?.businessParticularities ?? null;

    if(bussPart){
      //Si bonificación no está completa, por las dudas que haya seleccionado algo, la seteamos en incompleta
      if(!getIsBonification(bussPart)){
        this.removeOption(BUSS_PART_CONST.bonification);
      }
      
      //Esto se podría hacer con los demás campos que están en particularidades del negocio pero nunca ocurrió con ningún otro.
    }
  }
}
