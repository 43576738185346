import { createAction, props } from "@ngrx/store";
import { Indicator } from "src/app/shared/interfaces/price-to-fix.interface";
import { Price, PriceString } from "src/app/shared/interfaces/price.interface";
import { ViewPrice } from "src/app/shared/interfaces/views.interface";

//Prices
export const loadPricesAllSuccess = createAction('[PRICE] Load Prices All Success',
    props<{ prices: Price[] }>());
export const loadPricesWithPipesSuccess = createAction('[PRICE] Load Prices With Pipes Success',
    props<{ prices: Price[] }>());
export const loadPricesFailed = createAction('[PRICE] Load Prices Failed');


//Price filter
export const setFilterPrice = createAction('[FILTER PRICE] Set Filter Price',
    props<{ priceString: PriceString }>());
export const setBuyerTypeFilter = createAction('[FILTER PRICE] Set Buyer Type Filter In Price Board',
props<{ buyerType: string }>())
export const setFilterIndex= createAction('[FILTER PRICE] Set INDEX Filter In Price Board',
props<{ index:number }>())

//Generate prices
export const setPriceToGenerate = createAction('[GENERATE PRICE] Set Generate Price',
    props<{ price: Price }>());
export const setArrayOfBuyers = createAction('[GENERATE PRICE] Set Array Of Buyers In Price',
    props<{ buyers: any[] }>());
export const resetPriceToGenerate = createAction('[GENERATE PRICE] Reset Generate Price');

//Views of price board
export const setViewToGenerate = createAction('[VIEW] Set View To Generate In Price Board',
    props<{ view: ViewPrice }>());
export const setNewView = createAction('[VIEW] Set New View In Price Board',
    props<{ view: ViewPrice }>());
export const resetNewView = createAction('[VIEW] Reset New View In Price Board');
export const resetViewSelected = createAction('[VIEW] Reset View Selected In Price Board');
export const setViewSelected = createAction('[VIEW] Set View Selected In Price Board',
    props<{ view: ViewPrice }>());
export const setAllViews = createAction('[VIEW] Set All Views In Price Board',
    props<{ views: ViewPrice[] }>());
export const setIndexViewSelected = createAction('[VIEW] Set Index Of View Selected In Price Board',
    props<{ index: number }>());

//Prices selected to edit (Only Commodities)
export const setPricesSelected = createAction('[PRICES SELECTED] Set Prices Selected',
    props<{ prices: Price[] }>());

export const setArrayOfIdsSelecteds = createAction('[PRICES SELECTED] Set Array Of Ids Selecteds',
    props<{ ids: string[] }>());


export const showEditIndicators = createAction('[EDIT INDICATOR] Show Edit Indicator', props<{ show: boolean }>());
export const setEditIndicator = createAction('[EDIT INDICATOR] Set Edit Indicator', 
props<{ indicator: Indicator, conditionType:number, index:number }>());

//Details of price
export const setPriceDetails = createAction('[PRICE DETAILS] set Price Details',
    props<{ price: Price }>());
export const showPriceDetails = createAction('[PRICE DETAILS] Show Price Details', props<{ show: boolean }>());

//Historical
export const setHistorical = createAction('[PRICE HISTORICAL] Set Historical',
    props<{ historical: any }>());