import { Component, Input } from '@angular/core';

@Component({
  selector: 'price-board-svg',
  templateUrl: './price-board-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class PriceBoardSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
