import { Component, Output, EventEmitter, Input, OnInit,OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterIndex, setOrderDetails, showOrderDetails } from 'src/app/redux/actions/order.action';
import { areas } from 'src/app/shared/const/user.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order, OrderBoard, OrderString } from 'src/app/shared/interfaces/order.interface';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { orderOriginConst } from 'src/app/shared/const/orders';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateChecker } from 'src/app/shared/date.checker';
import { FilterOrderByCommoditiesPipe } from 'src/app/shared/pipes/filter/filterByBuyerType.pipe';
import { FilterByOrderPipe } from 'src/app/shared/pipes/filter/filter.by.order.pipe';
import { FilterOrderWithStringPipe } from 'src/app/shared/pipes/filter/filterWithString.pipe';
import { OrderValidator } from 'src/app/shared/order';
import { User } from 'src/app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { conditionType, setViewMobile } from 'src/app/shared/const/options';

@Component({
  selector: 'eraser-table',
  templateUrl: './eraser-table.component.html',
  styleUrls: ['./eraser-table.component.scss']
})
export class EraserTableComponent implements OnInit, OnDestroy {
  user!: User;
  @Input() reload:boolean=false;
  //Array of products
  orders: Order[] = [];
  orderBoard!: OrderBoard;
  isComercial: boolean = true;
  isViewMobile = true;

  //Other filter of tabla
  orderFilter!: OrderString;
  buyerType!: string;
  conditionTypes=conditionType;
  conditionTypeSelected!:string;
  conditionFilters:number[]=[];
  search: string = '';

  //Arrays
  STATUS: any = [];
  BUSINESS_TYPE: any = [];
  PLACE_OF_DELIVERY: any = [];
  QUALITY: any = [];
  TYPE_COIN: any = [];
  WAY_PAY: any = [];
  BONIFICATION: any = [];
  PRODUCTS: any = [];
  OBSERVATIONS: any = [];
  HARVEST: any = [];
  SELLERS: any = [];
  TABLES: any = [];

  ORDER_ORIGIN_CONST: any = orderOriginConst;


  //The column selected to apply a filter
  filterIdentificator: number = -1;
  optionIdentificator: number = -1;

  //The columns that will be seen in the table
  viewSelected!: ViewOrder;

  isCheckedAll: boolean = false;
  isOrderReturned: boolean = false;

  public wasInside: boolean = false; //Inside in menu option;

  private filter$!:Subscription;
  private buyer$!:Subscription;
  private identificator$!:Subscription;
  private conditionType$!:Subscription;
  private search$!:Subscription;
  private viewSelected$!:Subscription;
  private conditionFilters$!:Subscription;
  private sizeView$!:Subscription;
  private options$!:Subscription;
  private user$!:Subscription;
  constructor(public store: Store<appState>, public router: Router, public dialog: MatDialog, public date: DateChecker, public orderV: OrderValidator) {}

  ngOnInit():void {
    this.viewSelected$=this.store.select('order', 'viewsOfOrderBoard', 'viewSelected').subscribe(viewSelected => {
      this.viewSelected= this.editViewSelected(viewSelected)
    });

    this.filter$=this.store.select('order', 'orderBoard', 'filter').subscribe(filter => {
      this.orderFilter = filter;
    })

    this.buyer$=this.store.select('order', 'orderBoard', 'buyerType').subscribe(buyerType => {
      this.buyerType = buyerType;
    })

    this.identificator$=this.store.select('order', 'orderBoard', 'indexFilter').subscribe(indexFilter => {
      this.filterIdentificator = indexFilter;
    })

    this.conditionType$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe(conditionType => {
      this.conditionTypeSelected = conditionType;
    })

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.isViewMobile= setViewMobile(sizeView);
    })

    this.user$=this.store.select('usuario').subscribe(state => {
      this.user = state;
      this.isComercial = state.area == areas.comercial;
    })

    this.search$=this.store.select('options', 'board', 'search').subscribe(search => {
      this.search = search;
    });

    this.conditionFilters$=this.store.select('options','board','conditionFilter').subscribe((conditionFilters)=>{
      this.conditionFilters=conditionFilters;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["reload"]) {
      this.handleReloadChange();
    }
  }

  private handleReloadChange() {
    if (this.reload) {
      this.rememberEraserOrdersOfLocalStorage();
    }
  }

  rememberEraserOrdersOfLocalStorage() {
    this.orders = this.orderV.getEraserOrders();
  }

  ngOnDestroy():void {
    this.filter$?.unsubscribe();
    this.buyer$?.unsubscribe();
    this.identificator$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.sizeView$?.unsubscribe();
    this.options$?.unsubscribe();
    this.user$?.unsubscribe();
    this.search$?.unsubscribe();
    this.viewSelected$?.unsubscribe();
    this.conditionFilters$?.unsubscribe();
  }

  //It is a different function for business users and operators.
  selectFilter(index: number) {
    this.store.dispatch(setFilterIndex({ index: index == this.filterIdentificator? -1 : index }));
  }

  closeAllFilters() {
    this.store.dispatch(setFilterIndex({ index: -1 }));
  }

  getIsNew(order: Order): boolean {
    return order.dataOfcreation.hour == this.date.getHour();
  }

  showOrderDetail(order: Order) {
    this.store.dispatch(setOrderDetails({ order: order }));
    this.store.dispatch(showOrderDetails({ show: true }));
  }

  //-----------------------------------------------\\
  editViewSelected(firstViewSelected:ViewOrder):ViewOrder{
    let copyView:ViewOrder= JSON.parse(JSON.stringify(firstViewSelected));
    copyView.orderStatus=false;
    copyView.contractNumber=false;
    copyView.buyer=false;
    return copyView;
  }

  getOrderListWithPipes() {
    let newPricesList = JSON.parse(JSON.stringify(this.orders));
    const pipe1 = new FilterOrderByCommoditiesPipe();
    const pipe2 = new FilterByOrderPipe();
    const pipe3 = new FilterOrderWithStringPipe();
    return pipe3.transform(pipe2.transform(pipe1.transform(newPricesList, this.buyerType), this.orderFilter), this.search)
  }

  @Output() actionEraser = new EventEmitter<any>(); //Emit to parent component
  deleteOrder(order: Order) {
    const respon: any = {
      showModal: true,
      title: "¿Está seguro que desea eliminar la orden?",
      action: 'delete',
      order: order
    }
    this.actionEraser.emit(respon); //Emite la respuesta
  }

  sendOrder(order:Order){
    const respon: any = {
      showModal: true,
      title: "¿Está seguro que desea enviar la orden al operador?",
      action: 'send',
      order: order
    }
    this.actionEraser.emit(respon); //Emite la respuesta
   }

   emitAction(event:any){
    this.actionEraser.emit(event);
   }
}