
<div class="background-sin-color">

    <svg class="svg-22 hover-bg-white" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <rect width="22" height="22" rx="11" fill="white"/>
            <path d="M5 5.36927V11.4992C5 15.1402 7.99117 18.0796 11.6544 17.9984C15.1109 17.9171 17.9322 15.0885 17.9987 11.6321C18.0356 9.78576 17.297 8.10927 16.0932 6.90544C14.9189 5.73116 13.294 5 11.4993 5H5.36928C5.16987 5 5 5.16986 5 5.36927Z" fill="#FB6D1D"/>
            <circle cx="11.5" cy="11.5" r="3" stroke="white"/>
        </svg>

    <div class="tooltip down row-center w-80">
        <span>Observación</span>
    </div>
</div>