import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FutureMarket, FutureMarketOptions } from 'src/app/shared/interfaces/dashboard.interface';
@Component({
  selector: 'app-future-markets',
  templateUrl: './future-markets.component.html',
  styleUrls: ['./future-markets.component.scss']
})
export class FutureMarketsComponent implements OnInit {
  public futureMarkets!: FutureMarket[];
  public marketOptions!: FutureMarketOptions;
  public date!: string;

  private futureMarkets$!:Subscription;
  private marketsOptions$!:Subscription;
  private options$!:Subscription;

  constructor(private store: Store<appState>) {}

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.futureMarkets$?.unsubscribe();
    this.marketsOptions$?.unsubscribe();
    this.options$?.unsubscribe();
  }

  setSubscriptions(){
    this.futureMarkets$ = this.store.select('dashboard', 'futureMarketsList').subscribe(futureMarketsList => {
      this.futureMarkets = futureMarketsList;
    })

    this.marketsOptions$ = this.store.select('dashboard', 'optionsFutureMarket').subscribe(options => {
      this.marketOptions = options;
    })

    this.options$ = this.store.select('options', 'date').subscribe(date => {
      this.date = date;
    })
  }
}