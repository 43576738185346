<div class="board" [ngClass]="{'disabled': isDisabled}">
    <table>
        <thead>
            <tr>
                <th *ngIf="showFirstColumn"></th>
                <th>Periodo de fijación</th>
                <th>Indicador</th>
                <th>Calidad</th>
                <th>Plaza</th>
                <th>Mínimo y máximo</th>
                <th>Mes y año</th>
                <th>Rebaja</th>
                <th>Bonificación</th>
                <th>Porcentaje de producto</th>
                <th>Proporcionalidad</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let indicator of getIndicatorsOfPrice(); index as i">
                <tr *ngIf="isComercial ? indicator.enabled : true">
                    <td class="options-column" *ngIf="showFirstColumn">
                        <div class="row-left gap-8">                    
                            <input type="checkbox" (click)="select(indicator)" [checked]="isIndicatorSelected(indicator)"/>
                            <three-point-svg (click)="showOptions(i)"></three-point-svg>

                            <div class="menu-options" *ngIf="showOptionsIdentificator==i">
                                <ul>
                                    <li (click)="editIndicator(indicator, i)">Editar indicador</li>
                                    <li (click)="deleteIndicator(indicator)">Eliminar indicador</li>
                                </ul>
                            </div>

                            <mat-slide-toggle [checked]="indicator.enabled" (change)="changeIsEnabled(indicator)"></mat-slide-toggle>

                        </div>

                    </td>
                    <td>{{ getFixationPeriod(indicator) }}</td>
                    <td>{{ getIndicatorName(indicator) }}</td>
                    <td>{{ getQuality(indicator) }}</td>
                    <td>{{ getLocationIndicator(indicator) }}</td>
                    <td>{{ getMinAndMax(indicator) }}</td>
                    <td>{{ getMonthAndYear(indicator) }}</td>
                    <td>{{ getReduction(indicator) }}</td>
                    <td>{{ getBonification(indicator) }}</td>
                    <td>{{ getProductAndPercentage(indicator) }}</td>
                    <td>{{ getProporcionality(indicator) }}</td>
                </tr>
            
            </ng-container>
        </tbody>
    </table>

    <div class="info-to-fix gap-8">
        <span>{{getConditionType()}} |</span>
        <span>Periodo de fijación: {{getMacroFixationPeriodToFix()}}</span>
    </div>
</div>