<div class="row-between" *ngIf="showTitle">
    <div class="title">Particularidad de negocio</div>
    <cross-svg class="pointer" (click)="close()"></cross-svg>
</div>
<div class="width-100" [ngClass]="{'small-letter': isSmall}">
    <div class="info wash-out" *ngIf="getIsWashOut(businessParticularity) && !isSmall">
        <span class="title">{{BUSS_PART_CONST.washOut}}</span>
    </div>
    <div class="info" *ngIf="getIsGravanz(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.gravanz}}</span>
        <span class="value">{{getSensorization(businessParticularity)}}</span>
        <span class="value">{{getGravanzBusiness(businessParticularity)}}</span>
        <span class="value">{{getGravanzSelectionStock(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsAccumulator(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.accumulator}}</span>
        <span class="value">{{getTypeAccumulator(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsConditional(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.conditional}}</span>
        <span class="value">{{getConditionalSelection(businessParticularity)}}</span>
        <span class="value">{{getConditionalStrike(businessParticularity)}}</span>
        <span class="value">{{getConditionalPosition(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsNutrienAgreement(businessParticularity) && !isSmall" >
        <span class="title">{{BUSS_PART_CONST.nutrienAgreement}}</span>
    </div>
    <div class="info" *ngIf="getIsCollectionClause(businessParticularity) && !isSmall" >
        <span class="title">{{BUSS_PART_CONST.collectionClause}}</span>
    </div>
    <div class="info" *ngIf="getIsRespectVatException(businessParticularity) && !isSmall">
        <span class="title">{{BUSS_PART_CONST.respectIVA}}</span>
    </div>
    <div class="info" *ngIf="getIsFreightPaidByTheBuyer(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.freightPaidByTheBuyer}}</span>
        <span class="value">Tarifa: {{getRateFreightPaidByTheBuyer(businessParticularity)}}</span>
        <span class="value" *ngIf="!getIsToDefineFreightPaidByTheBuyer(businessParticularity)">{{getFreightPaidAmount(businessParticularity)}}</span>
        <span class="value">{{getEventTypeFreightPaidByTheBuyer(businessParticularity)}}</span>
        <span class="value">{{getFreightPaidNumberOfDays(businessParticularity)}}</span>
        <span class="value">{{getFreightPaidIsCustomer(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsAgreement(businessParticularity) && !isSmall">
        <span class="title">{{BUSS_PART_CONST.agreement}}</span>
    </div>
    <div class="info" *ngIf="getIsDeliveriesAtGrassiPlant(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.deliveriesAtGrassiPlant}}</span>
        <span class="value">{{getGrassiPlantsPlaceOfDelivery(businessParticularity)}}</span>
    
        <span class="sub-title">Paritaria</span>
        <span class="value">{{getGrassiPlantsParity(businessParticularity)}}</span>
    
        <span class="sub-title">Merma volátil</span>
        <span class="value">{{getGrassiPlantsVolatileWaste(businessParticularity)}}</span>
    
        <span class="sub-title">Comisión</span>
        <span class="value">{{getGrassiPlantsCommission(businessParticularity)}}</span>
    
        <span class="sub-title">Flete</span>
        <span class="value">{{getGrassiPlantsFreight(businessParticularity)}}</span>
    
        <span class="sub-title">Humedad</span>
        <span class="value">{{getGrassiPlantsHumidityAmountAndPercentage(businessParticularity)}}</span>
        <span class="value">{{getGrassiPlantsHumidityReductionPoint(businessParticularity)}}</span>
        <span class="value">{{getGrassiPlantsHumidityReductionAmount(businessParticularity)}}</span>
    </div>
    
    <div class="info" *ngIf="getIsDeliveryInSiloBolsas(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.deliveryInSiloBolsas}}</span>
        <span class="value">{{getSiloBolsasQuantity(businessParticularity)}}</span>
        <span class="value">{{getSiloBolsasBrand(businessParticularity)}}</span>
        <span class="value">{{getSiloBolsasSize(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsBonification(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.bonification}}</span>
        <span class="value">{{getBonificationType(businessParticularity)}}</span>
        <span class="value">{{getBonificationAmount(businessParticularity)}}</span>
        <span class="value">{{getBonificationDate(businessParticularity)}}</span>
        <span class="value">{{getBonificationPlaceOfDelivery(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsReduction(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.reduction}}</span>
        <span class="value">{{getReductionType(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsLaboratoryAgreement(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.laboratoryAgreement}}</span>
        <span class="value">{{getLaboratoryAgreementType(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsSealedToRetrieve(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.sealedToRetrieve}}</span>
        <span class="value">{{getSealedToRetrieveType(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsToWithdraw(businessParticularity) && !isSmall">
        <span class="title">{{BUSS_PART_CONST.toWithdraw}}</span>
    </div>
    <div class="info" *ngIf="getIsAgreedWith(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.agreedWith}}</span>
        <span class="value">{{getIsAgreedWithNombreComercial(businessParticularity)}}</span>
        <span class="value">{{getIsAgreedWithComprador(businessParticularity)}}</span>
        <span class="value">{{getIsAgreedWithResponsable(businessParticularity)}}</span>
    </div>
    <div class="info" *ngIf="getIsGuaranteedPAF(businessParticularity) && !isSmall">
        <span class="title">{{BUSS_PART_CONST.guaranteedPAF}}</span>
    </div>
    
    <div class="info" *ngIf="getIsFinancialAdvance(businessParticularity)">
        <span class="title">{{BUSS_PART_CONST.financialAdvance}}</span>
        <span class="value">{{getVATFinancialAdvance(businessParticularity)}}</span>
        <span class="value">{{getRateFinancialAdvance(businessParticularity)}}</span>
        <span class="value">{{getTypePercentageFinancialAdvance(businessParticularity)}}</span>
        <span class="value">{{getPercentagePayableFinancialAdvance(businessParticularity)}}</span>
    </div>
</div>