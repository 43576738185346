import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { localStorageNames } from './const/localStorageNames';
import { priceTags, PRICE_STATUS_CONST, typeCoins, VARIATION_PERCENT } from './const/prices.const';
import { PRODUCT_NAMES } from './const/productNames';
import * as PRODUCT_NAMES_DICT from './dict/productName';
import { QUALITIES } from './const/quality.const';
import { DataChecker } from './data.checker';
import { DateChecker, getMonthStringAbbreviated, isEarlierDate, isSameDate, isValidDate, stringToDate } from './date.checker';
import { businessDict, typeBusiness } from './dict/typeBusiness';
import { appState } from './interfaces/appState.interface';
import { BusinessParticularities } from './interfaces/business-particularities';
import { DataOfCreation } from './interfaces/order.interface';
import { Mod, PlaceOfDelivery, Price, RangeDate, WayPay } from './interfaces/price.interface';
//import { QualityParticularities } from './interfaces/quality-particularities';
import { User } from './interfaces/user.interface';
import { PriceService } from './services/price.service';
import { BusinessParticularitiesValidator } from './business-particularities';
import { priceState, puertos, quality, typeCoin, typeCoinsStr } from './dict/dict';
import { QUALITY_IBM } from './dict/quality_ibm';
import { WayPayValidator, getWayPayText, getWayPaysValids } from './way-pay';
import { buyers } from './dict/buyers';
import { COMMODITIES_CODE } from './const/buyers';
import { PLACE_OF_DELIVERY_CONST } from './const/place-of-delivery';
import { PROVINCES_CONST } from './const/provinces';
import { getStructPlantasInterior } from './functions-ibm/contract.checker';
import { matchProvincesWithSellers } from './dict/provinces/provinces';
import { isIndicatorsIncomplete, priceHaveIndicators } from './to-fix';
import { setErrorStruct, setModalLoading, setModalSuccess } from '../redux/actions/options.action';
import { GROUPING_CODE_TO_FIX, GROUPING_CODE_TO_PRICE } from './dict/grouping-code';
import { wayPay } from './dict/wayToPay';
import { loadPricesAllSuccess, resetPriceToGenerate, setPriceDetails, setPricesSelected, showPriceDetails } from '../redux/actions/price.action';
import { formatNumber } from './validator.checker';
import { PLANTAS_INTERIOR_MAP } from './dict/plantas-interior';
import { catchError } from 'rxjs';
import { ResponseRequest } from './interfaces/options.interface';
import { DialogPriceCreate } from '../components/dialogs/price-create/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class PriceValidator {
  constructor(
    private store:Store<appState>, //Para emitir acciones
    private dataC: DataChecker, //Para validar datos
    private date: DateChecker, //Para corroborar fechas
    private priceSvc: PriceService,
    private wayPayValid: WayPayValidator,
    private bussPartValid: BusinessParticularitiesValidator,

    public dialog: MatDialog
  ) { }

  /* Compare two Prices by their JSON stringify */
  comparePrices(price1: Price, price2: Price): boolean {
    return JSON.stringify(price1._id) == JSON.stringify(price2._id);
  }

  //A function that compares all the fields of Price and returns if they match or not
  compareAllFields(price1: Price, price2: Price): boolean {
    return JSON.stringify(price1) == JSON.stringify(price2);
  }

  getPricesOfLocalStorage() {
    return this.dataC.rememberPrices();
  }

  resetSelectedPriceList(){
    this.store.dispatch(setPricesSelected({ prices: [] }));
  }

  //Busca en el array de precios almacenado en el localStorage si existe un precio con el mismo _id que se pasa por parametro
  searchPriceInPriceBoard(price:Price){
    const prices:Price[]=this.getPricesOfLocalStorage();
    return prices.find(p=>p._id==price._id);
  }

  /* Given a Price and an ArrayPrice, return if the Price exists in the ArrayPrice with the help of ComparePrice() */
  isPriceInPriceList(price: Price, priceList: Price[]) {
    try {
      const quantPrices = priceList.length;
      for (let i = 0; i < quantPrices; i++) {
        if (this.comparePrices(priceList[i], price)) {
          throw new Error();
        }
      }
      return false;
    } catch (err) {
      return true;
    }
  }

  /**
   * Given an array of buyers, a price and a user, first check if the array has elements. 
   * If it does, you must create a different price for each buyer.
   * If it does not, check if the price has an ID, 
   * if it does, you must update the price, if it does not, you must create a new one.
   * @param { number[] } arrayOfBuyers - Array of buyers
   * @param { Price } price - Price to confirm
   * @param { User } user - User who confirms the price
   */
  confirmPrice(arrayOfBuyers:number[], price:Price, user:User){
    const quantOfBuyers=arrayOfBuyers.length;
    if(quantOfBuyers>0){
      //Si tiene compradores, analizamos que tenga price._id, en ese caso debe hacerse un update del primero y el resto no.
      //En caso de no tener price._id, debe hacerse un duplicate para todas las condiciones.
      for(let i=0; i<quantOfBuyers; i++){

        let newPrice:Price=JSON.parse(JSON.stringify(price));
        newPrice.observations.buyer=Number(arrayOfBuyers[i]);
        price._id && i==0? this.updatePriceInFormSection(newPrice, user): this.createPriceInFormSection(newPrice, user);

      }

    } else {
      //Si tiene price._id, entonces debemos hacer un update, sino, significa que no existe y es un duplicate.
      price._id? this.updatePriceInFormSection(price, user): this.createPriceInFormSection(price, user);
    }  
  }

  showModalCreatePrice(isSuccess:boolean){
    this.store.dispatch(setModalLoading({isLoading:false}));
    this.store.dispatch(setModalSuccess({isSuccess: isSuccess}));
    this.dialog.open(DialogPriceCreate);
  }

  /**
   * Function to create a new price from the 'generate new price' form
   * @param { Price } price - Price to create 
   * @param { User } user - User who creates the price
   */
  createPriceInFormSection(price:Price, user:User){
    this.store.dispatch(setModalLoading({isLoading:true}));

    let newPrice=this.setMainFeaturesOfPrice(price, user);
    newPrice.status = PRICE_STATUS_CONST.INACTIVO;
    this.setIdToPrice(newPrice);

    this.priceSvc.createPrice(newPrice).pipe(
      catchError((error) => {
        console.error(error.error)
        this.showModalCreatePrice(false);
        return [];
      })
    ).subscribe( (res) => {
      this.showModalCreatePrice(true);
      this.store.dispatch(resetPriceToGenerate());
    });
  }

  /**
   *  Function to update a price from the 'generate new price' form
   * @param { Price } price - Price to create 
   * @param { User } user - User who creates the price
   */
  updatePriceInFormSection(price:Price, user:User){
    this.store.dispatch(setModalLoading({isLoading:true}));

    let newPrice=this.setMainFeaturesOfPrice(price, user)

    this.priceSvc.updatePrice(newPrice, newPrice._id).pipe(
      catchError((error) => {
        console.error(error.error)
        this.showModalCreatePrice(false);
        return [];
      })
    ).subscribe( (res)=>{
      this.showModalCreatePrice(true);
      this.store.dispatch(resetPriceToGenerate());
    });
  }

  /* Duplicate Price */
  duplicatePrice(price: Price, user: User) {        
    let newPrice = this.setMainFeaturesOfPrice(price,user);
    newPrice.status = PRICE_STATUS_CONST.INACTIVO;
    this.setIdToPrice(newPrice)
    this.showModalWithMessage('Creando condición...');
    
    this.priceSvc.createPrice(newPrice).pipe(
      catchError((error) => {
        
        console.error(error.error)
        this.showModalWithMessage('Error al crear la condición');
        return [];
      })
    ).subscribe((res) => {
      this.showLoadSuccess();
    });
  }

  /** 
   * Given a price to save, a field and a value.
   * If the value is number, consume 'editPriceNumber' from the service.
   * If string, consume 'edirPriceString' from service
   * @param { Price } priceToSave - Price to save
   * @param { string } field - Field to edit
   * @param { any } value - Value to save
   * @param { User } user - User who saves the price
   * @param { boolean } showModal - Show modal or not
   * */
  editPrice(priceToSave: Price, field: string, value: any, user:User,showModal=true) {
    if (field) {
      if(showModal){this.showModalWithMessage('Actualizando condición...')}

      this.priceSvc.editPriceNumber(priceToSave._id, field, value, user).pipe(
        catchError((error) => {
          if(showModal){this.showModalWithMessage('Error al actualizar la condición')}
          return [];
        })
      ).subscribe( (res) =>{
        if(showModal){
          this.showModalWithMessage('Condición actualizada');
        }
      });
    }
  }

  /*Actualizamos el precio */
  updatePrice(price:Price, user:User){
    let newPrice=this.setMainFeaturesOfPrice(price, user)
    this.showModalWithMessage('Actualizando condición...')
    this.priceSvc.updatePrice(newPrice, newPrice._id).pipe(
      catchError((error) => {
        this.showModalWithMessage('Error al actualizar la condición');
        return [];
      })
    ).subscribe(res=>{
      this.showLoadSuccess();
    });
  }

  /* Delete price */
  deletePrice(id: string) {
    this.showModalWithMessage('Eliminando condición...')

    return this.priceSvc.deletePrice(id).pipe(
      catchError((error) => {
        this.showModalWithMessage('Error al eliminar la condición');
        return []
      })
    ).subscribe(res => {
      this.showLoadSuccess();
    });
  }

  
  searchTodayPrices(){
    const date=this.date.getDateInYYYYMMDDformat(this.date.stringToDate(this.date.getDate()));
    
    this.priceSvc.getPricesByDate(date).pipe(
      catchError((error) => {
        const msg=error?.error?.error?.REQUEST
        msg? this.showModalWithMessage("No hay precios en la fecha selecionada"):''
        this.store.dispatch(loadPricesAllSuccess({prices: []}));

        return [];
      })
    ).subscribe((response:ResponseRequest) =>{
      const pricesToSet=response.data? response.data: [];
      localStorage.setItem(localStorageNames.pricesAll, JSON.stringify(pricesToSet));
      this.store.dispatch(loadPricesAllSuccess({prices: pricesToSet}));
    });
  }

  /* Show load Success */
  showLoadSuccess(){
    this.showModalWithMessage('Completado exitosamente.')
  }

  /* Show modal with message */
  showModalWithMessage(message:string){
    this.store.dispatch(setErrorStruct({error: {color: '#fff', isVisible:true, message: message}}));
  }

  /* Given a command, set default fields */
  private setMainFeaturesOfPrice(price:Price, user:User){
    let newPrice=JSON.parse(JSON.stringify(price));
    newPrice.dataOfcreation = this.getDataOfCreation(user);
    if(newPrice.observations.buyer <= 0 && newPrice.observations.isPort!=1){
      newPrice.observations.buyer = COMMODITIES_CODE;
    }
    return newPrice;
  }

  /*Given a price.
  Look in localStorage for 'last-id-price' and see if the number exists.
  If it exists, it assigns the value added to one in the priceId property of the price.
  If it does not exist, it assigns a 1 to the priceId property.
  Then update in the localStorage the 'last-id-price' with the value of the priceId property and return the new price*/
  private setIdToPrice(price: Price) {
    const storage = localStorageNames.lastIdPrice;
    const lastIdStr = localStorage.getItem(storage);
    price.priceId = lastIdStr? (Number(lastIdStr) + 1): 1;
    localStorage.setItem(storage, price.priceId.toString());
    return price;
  }

  //If withoutIndic is true, not analyze the indicators 
  isPriceIncomplete(price: Price, withOutIndic:boolean=false): boolean {
    //If any value is true, it means that it is an incomplete field.
    //Therefore, the continue button cannot be clicked.
    const isPriceToFix=priceHaveIndicators(price);
    return (
      price.productName <= 0 ||
      price.typeBusiness <= 0 ||
      price.placeOfDelivery.zone <= 0 ||
      this.isInvalidOptionalPort(price.placeOfDelivery) ||
      //this.isInvalidStartDate(price.deliveryPeriod.startDate) ||
      price.deliveryPeriod.startDate == '--' ||
      price.deliveryPeriod.endDate == '--' ||
      !this.isQualityValid(price.productName, price.quality)||
      this.isInvalidPriceAndTypeCoin(price.price, price.typeCoin, isPriceToFix) ||
      (price.indicators?.length>0? (withOutIndic? false: isIndicatorsIncomplete(price?.indicators, price?.conditionType)) :false) ||
      this.isExpirationIncomplete(price) ||
      this.isInvalidPesification(price) ||
      price.harvest == '--' ||
      price.wayPay.wayPayName <= 0
    );
  }

  /**
   * Given a business condition, returns whether its delivery period or start of fixation period is before today's date 
   * @param { Price } price - Business condition
   * @returns { boolean }
   * */
  hasAnOldDate(price:Price):boolean{
    console.log("Validamos fechas");

    //------ DELIVERY PERIOD ------\\
    if(price.deliveryPeriod.startDate == '--') return false

    //Si son fechas distintas, entonces hacemos la validación para saber si es fecha anterior, sino seguimos.
    if(!isSameDate( stringToDate(price.deliveryPeriod.startDate), new Date()) &&
    isEarlierDate(price.deliveryPeriod.startDate, this.date.getDate(), '-')) return true;
    
    //------ INDICATORS ------\\
    if( (price.indicators ?? []).length <= 0) return false

    //Si son fechas distintas, entonces hacemos la validación para saber si es fecha anterior, sino seguimos. 
    if(!isSameDate( stringToDate(price.indicators[0].fixationPeriod.startDate), new Date()) &&
    isEarlierDate(price.indicators[0].fixationPeriod.startDate, this.date.getDate(), '-')) return true

    return false;
  }
  
  /*Given a Price.
  Take 'prices-all-aux' from localStorage. If it exists, it looks for the price according to the priceId of the parameter.
  Returns if its 'price' attribute is greater or not*/
  isPriceHigher(price: Price): boolean {
    const prices = this.dataC.rememberPricesAux();
    if (prices) {
      const index = getIndexForPriceId(prices, price._id ? price._id : 0);
      return index != -1 && price.price > prices[index].price;
    }
    return false;
  }

  /* Si la zona del lugar de entrega no es UpRiver ni Rosario, el puerto debe ser mayor a 0*/
  isInvalidRequiredPort(placeOfDelivery:PlaceOfDelivery){
    return placeOfDelivery?.zone != PLACE_OF_DELIVERY_CONST.UP_RIVER && 
      placeOfDelivery?.zone != PLACE_OF_DELIVERY_CONST.ROSARIO && placeOfDelivery?.port <= 0;
  }

  /* Si la zona del lugar de entrega es mayor a 7, el puerto debe ser mayor a 0*/
  isInvalidOptionalPort(placeOfDelivery:PlaceOfDelivery){
    return placeOfDelivery?.zone > 7 && placeOfDelivery?.port <= 0;
  }

  //Devuelve si una condición está en dolares o no
  isInDolars(price: Price): boolean {
    return price?.typeCoin==typeCoins.USD;
  }

  /* Given two numbers, check to see if there is a 2 percent variance between those numbers. */
  isPriceVariance(price: number, priceAux: number): boolean {
    return Math.abs(price - priceAux) / priceAux > VARIATION_PERCENT;
  }

  /*Given a number that represents the productName and another number that represents the quality.
Returns true if that product can have that quality and false if it doesn't.*/
  isQualityValid(productName: number, quality: number): boolean {
    return !(
      (productName == PRODUCT_NAMES.SOJA && quality == QUALITIES.GRADO_2) || //Si es soja y calidad es grado 2
      (productName != PRODUCT_NAMES.SOJA && quality == QUALITIES.FABRICA) || //Si no es soja y calidad es fábrica
      quality <= 0 //Si la calidad es menor o igual a 0
    );
  }

  /* Given a price number of type Price and a number that represents a form of payment.
Returns true if that business condition (price) can have that form of payment and false if not.*/
  isWayPayValid(price: Price, wayPay: number): boolean {
    return getWayPaysValids(price).includes(wayPay);
  }

  /**
   * Given a business condition (Price), the code of the form of payment that goes to IBM is obtained. 
   * @param {Price} price - Business condition
   * @returns {number} - IBM code of the form of payment
   * */
  getWayPayIBM(price: Price): number {
    return this.wayPayValid.getIBMCode(price.wayPay, price.typeCoin);
  }

  /* Given a number, return 'USD' if less than 1000 or 'ARS' if greater than 1000 */
  getTypeCoinDefault(value: number): string {
    return value < 1000 ? typeCoins.USD : typeCoins.ARS;
  }

  /* GETS */
  getPriceStatus(price:Price){
    const status:any=priceState;
    return price?.status>0 ? status[price.status] ?? '--': '--';
  }

  getPriceWithPoints(price:Price):string{
    return formatNumber(price.price);
  }

  getTypeCoinString(price:Price):string {
    const typeCoin:any=typeCoinsStr;
    return price?.typeCoin ? typeCoin[price.typeCoin] ?? '--': '--';
  }

  getPriceOfCondition(price:Price){
    return price?.price>0? price.price: '--';
  }

  getTypeCoinAndPriceString(price:Price):string {
    return this.getPriceWithPoints(price) + ' ' + this.getTypeCoinString(price)
  }

  getTypeCoinAndPrice(price:Price):string {
    const TYPE_COINS:any=typeCoin;
    return TYPE_COINS[price.typeCoin]+' '+this.getPriceOfCondition(price);
  }

  getTypeCoinAndPriceWithPoints(price:Price):string {
    const TYPE_COINS:any=typeCoin;
    return TYPE_COINS[price.typeCoin]+' '+this.getPriceWithPoints(price);
  }

  getProductName(price:Price){
    const PRODUCT_NAME:any=PRODUCT_NAMES_DICT.PRODUCT_NAMES;
    return PRODUCT_NAME[price?.productName ?? 0];
  }

  getBusinessType(price:Price){
    const BUSINESS_TYPES:any=typeBusiness;
    return BUSINESS_TYPES[price?.typeBusiness ?? 0];
  }

  getWayPay(price:Price): string {
    return getWayPayText(price?.wayPay);
  }

  getOnlyWayPayName(price:Price): string {
    const WAY_PAYS:any=wayPay;
    return WAY_PAYS[price?.wayPay?.wayPayName ?? 0];
  }

  getExpirationDate(price:Price): string {
    return price?.wayPay?.expiration ? price.wayPay.expiration : '--';
  }

  //Given a price, it returns true if it is a fixed date and the expiration has been set (options>0) 
  isFixExpiration(price:Price):boolean {
    return this.wayPayValid.getIsFechaFija(price) && price?.wayPay?.options>0;
  }

  getBuyer(price:Price):string {
    const buyerParam=price?.observations?.buyer;
    const BUYER:any=buyers;
    return buyerParam>0? BUYER[buyerParam] ?? '--': '--';
  }

  getPlaceOfDelivery(placeOfDelivery: PlaceOfDelivery): string {
    let PLACE_OF_DELIVERY:any= puertos;
    let valueToReturn:string='';
    try{
      //Agregamos la zona
      if(PLACE_OF_DELIVERY[placeOfDelivery.zone].name){
        valueToReturn=PLACE_OF_DELIVERY[placeOfDelivery.zone].name;
      } else {
        valueToReturn='--'
      }

      //Add the port
      if(PLACE_OF_DELIVERY[placeOfDelivery.zone].dropdown[placeOfDelivery.port - 1]){
        valueToReturn+=' - '+PLACE_OF_DELIVERY[placeOfDelivery.zone].dropdown[placeOfDelivery.port - 1];
      }
    } catch(err){}

    return valueToReturn;  
  }

  getProvinceOfPlaceOfDeliveryByPrice(price:Price){
    return getProvinceOfPlaceOfDelivery(price?.placeOfDelivery);
  }

  getHarvest(price:Price){
    return price?.harvest ?? '--';
  }

  getBusinessParticularities(businessParticularities: BusinessParticularities): string[] {
    return this.bussPartValid.getbussinesParticularities(businessParticularities);
  }

  getQualityParticularities(qualityParticularities: any /*QualityParticularities*/): string[] {
    return []
    //return this.qualityPartValid.getQualityParticularities(qualityParticularities);
  }

  getQualityIBM(price:Price){
    const qualitiesIBM:any=QUALITY_IBM;
    return price?.qualityIBM>0? qualitiesIBM[price.qualityIBM] ?? '--': '--';
  }

  getDeliveryPeriod(price: Price): string {
    try {
      if (isConditionForward(price)) {
        const startDate = price.deliveryPeriod.startDate;
        //If it is the first day of the month
        if (this.date.getDateOfDateStr(startDate) == 1) {
          return getMonthStringAbbreviated(this.date.getMonthOfDateStr(startDate)) + " " + this.date.getYearOfDateStr(startDate);
        }
      }
      if(price.deliveryPeriod.startDate == '--' && price.deliveryPeriod.endDate == '--') {
        return '--'
      }
      return price.deliveryPeriod.startDate + ' / ' + price.deliveryPeriod.endDate
    } catch (error) {
      return '--';
    }
  }

  getRangeDateText(rangeDate:RangeDate){
    const startDate = rangeDate.startDate;
    //If it is the first day of the month
    if (this.date.getDateOfDateStr(startDate) == 1) {
      return getMonthStringAbbreviated(this.date.getMonthOfDateStr(startDate)) + " " + this.date.getYearOfDateStr(startDate);
    }
    return rangeDate.startDate + '/' + rangeDate.endDate
  }

  getValidUntilText(price:Price){
    if(price.deliveryPeriod.validFor){
      const rangeDate:RangeDate= price.deliveryPeriod.validFor[price.deliveryPeriod.validFor.length-1];
      return this.getRangeDateText(rangeDate);   
    }
    return ''
  }

  isValidUntil(price:Price){
    return (price.deliveryPeriod?.validFor??[]).length > 0
  }

  getPesification(price: Price): string {
    try {
      if (price.pesificacion.startDate == '--' && price.pesificacion.endDate != '--') {
        return 'Hasta ' + price.pesificacion.endDate;
      } else if (price.pesificacion.startDate != '--' && price.pesificacion.endDate == '--') {
        return 'Desde ' + price.pesificacion.startDate;
      } else if (price.pesificacion.startDate != '--' && price.pesificacion.endDate != '--') {
        return price.pesificacion.startDate + ' / ' + price.pesificacion.endDate;
      }
    } catch (error) {}
    return '--';
  }

  getQuality(price:Price){
    return getQualityStr(price?.quality);
  }

  getGrouperCode(price: Price): string {
    const GCPrice:any=GROUPING_CODE_TO_PRICE;
    const GCFix:any=GROUPING_CODE_TO_FIX;
    const grouperCode=price?.grouperCode ?? 0;

    return GCPrice[grouperCode] ?? GCFix[grouperCode] ?? '--';
  }

  /*getRowPrice(price: Price):RowPrice {
    return {
      _id: price._id,
      isFeatured: price.observations.isFeatured==1,
      isGravanz: price.observations.isBussinesGravanz==1,
      isMessage: price.observations.isMessage==1,
      meesage:price.observations.message,
      status: this.getPriceStatus(price),
      productName: this.getProductName(price),
      typeBusiness: this.getBusinessType(price),
      placeOfDelivery: this.getPlaceOfDelivery(price.placeOfDelivery),
      quality: this.getQuality(price),
      haveQualityParticularity: price.qualityIBM>0,
      qualityParticularity:this.getQualityIBM(price),
      typeCoinAndPrice: this.getTypeCoinAndPriceWithPoints(price),
      wayPay:this.getWayPay(price),
      expiration:this.getExpirationDate(price),
      pesificacion:this.getPesification(price),
      harvest:price.harvest,
      businessParticularities:this.getBusinessParticularities(price.businessParticularities),
      hour:price.dataOfcreation.hour,
      haveIndicators:priceHaveIndicators(price),
    }
  }*/


  isExpirationIncomplete(price: Price) {
    return this.wayPayValid.getIsConCartaDeGarantia(price) && price?.wayPay?.options>0 && 
    (price?.wayPay?.expiration == '--' || !price?.wayPay?.expiration);
  }

  /*It is set if the price of the condition is valid.
  If it is a condition to set, the response is false.
  Then check that the price is greater than 0
  and that it correctly has its type of currency associated */
  isInvalidPriceAndTypeCoin(price:number, typeCoin: string, isPriceToFix:boolean=false){
    try{  
      const isInvalidPrice = price <= 0;
      const isInvalidCoin = typeCoin == '--' || (price > 1000 && typeCoin == typeCoins.USD) || (price < 1000 && typeCoin == typeCoins.ARS);
      return !isPriceToFix && (isInvalidPrice || isInvalidCoin);
    }catch(err){
      return false;
    }
  }

  //Si falta alguna pesificación, devuelve true, también valida que la primer pesificación no sea mayor a la segunda
  isInvalidPesification(price:Price){
    return isInvalidPesification(price)
  }

  getDataOfCreation(user: User) {
    let data: DataOfCreation = {
      dateOfcreation: this.date.getDate(),
      hour: this.date.getHour(),
      email: user.email,
      nameOfcreator: user.name + " " + user.lastName,
      teamOfcreator: user.team
    }
    return data;
  }

  //Returns true if the observations.isPort field of the price is 1
  isExport(price: Price) {
    return (price?.observations?.isPort ?? -1) == 1;
  }

  actualizateHour(priceToSave:Price, hour:string, user:User){
    this.editPrice(priceToSave, priceTags.dataOfcreation+'.'+priceTags.hour, hour, user);
  }

  getHistorical(id: string) {
    return this.priceSvc.getHistorical(id)
  }

  setWayPay(price: Price, wayPay: WayPay, user:User) {
    try {
      if (!this.isWayPayValid(price, wayPay.wayPayName)) { throw new Error() }

      let newPrice = JSON.parse(JSON.stringify(price));
      if (wayPay.wayPayName > 0 && newPrice.wayPay.wayPayName != wayPay.wayPayName) {
        newPrice.wayPay.wayPayName = wayPay.wayPayName;
        this.editPrice(newPrice, priceTags.wayPay + '.' + priceTags.wayPayName, wayPay.wayPayName, user);
      }

      if (wayPay.isBusinessDays != 0 && newPrice.wayPay.isBusinessDays != wayPay.isBusinessDays) {
        newPrice.wayPay.isBusinessDays = wayPay.isBusinessDays;
        this.editPrice(newPrice, priceTags.wayPay + '.' + priceTags.wayPayIsBusinessDays, wayPay.isBusinessDays, user);
      }

      if (wayPay.percentage > 0 && newPrice.wayPay.percentage != wayPay.percentage) {
        newPrice.wayPay.percentage = wayPay.percentage;
        this.editPrice(newPrice, priceTags.wayPay + '.' + priceTags.wayPayPercentage, wayPay.percentage, user);
      }

      if (wayPay.days > 0 && newPrice.wayPay.days != wayPay.days) {
        newPrice.wayPay.days = wayPay.days;
        this.editPrice(newPrice, priceTags.wayPay + '.' + priceTags.wayPayDays, wayPay.days, user);
      }

      if(wayPay.expiration != '--' && newPrice.wayPay.expiration != wayPay.expiration){
        newPrice.wayPay.expiration = wayPay.expiration;
        this.editPrice(newPrice, priceTags.wayPay + '.' + priceTags.wayPayExpiration, wayPay.expiration, user);
      }

      return newPrice;
    } catch (err) {
      this.showModalWithMessage('La condición no es válida para la forma de pago seleccionada')
      return price;
    }
  }
}

export function isInvalidPesification(price:Price){
    try {
      const startDate= isValidDate(price.pesificacion.startDate);
      const endDate=isValidDate(price.pesificacion.endDate);
      if (startDate != '--' && endDate != '--') {
        return isEarlierDate(endDate, startDate, '-');
      } else if(startDate != '--' && endDate == '--'){
        return true;
      } else if(startDate == '--' && endDate != '--'){
        return true;
      }
    } catch (err) {}
    return false;
}

/*Given a delivery location, returns the province to which that delivery location belongs. */
export function getProvinceOfPlaceOfDelivery(placeOfDelivery:PlaceOfDelivery){
  const zone=placeOfDelivery.zone;
  if( zone>0 && zone<=7 || zone==PLACE_OF_DELIVERY_CONST.PLANTAS_GRASSI){
    return PROVINCES_CONST.SANTA_FE; //SANTA FE
  } else if(zone==PLACE_OF_DELIVERY_CONST.PUERTOS_BUENOS_AIRES){
    return PROVINCES_CONST.BUENOS_AIRES; //BUENOS AIRES
  } else if (zone==PLACE_OF_DELIVERY_CONST.PUERTOS_ENTRE_RIOS){
    return PROVINCES_CONST.ENTRE_RIOS; //ENTRE RIOS
  } else if (zone==PLACE_OF_DELIVERY_CONST.PLANTAS_INTERIOR){
    const structPlantInterior:any=getStructPlantasInterior(placeOfDelivery.port);
    const dictMatchProvinces:any=matchProvincesWithSellers;
    return dictMatchProvinces[structPlantInterior.provincia]; //OBTENER PROVINCIA SEGUN LA PLANTA DE INTERIOR
  }
  return 0;
}

/**
 * Given two Prices, it compares them field by field and returns an array 
 * with all the fields that are different from each other.
 * Each element of the array is a Mod object that has a field and a value field. 
 * The value of the first price object passed by parameter will be stored in value.
 * */
/**
 * Given two Prices, it compares them field by field and returns an array 
 * with all the fields that are different from each other.
 * Each element of the array is a Mod object that has a field and a value field. 
 * The value of the first price object passed by parameter will be stored in value.
 * */
export function comparePricesKeys(price1: Price|undefined, price2: Price): Mod[] {
  const mods: Mod[] = [];

  for (const key in price1) {
    if (price1.hasOwnProperty(key) && price2.hasOwnProperty(key)) {
      const value1 = price1[key as keyof Price];
      const value2 = price2[key as keyof Price];

      if (key === 'indicators' && Array.isArray(value1) && Array.isArray(value2)) {
        value1.forEach((indicator1, index) => {
          const indicator2 = value2[index];
          for (const indicatorKey in indicator1) {
            if (indicator1.hasOwnProperty(indicatorKey) && indicator2.hasOwnProperty(indicatorKey)) {
              const indicatorValue1 = indicator1[indicatorKey];
              const indicatorValue2 = indicator2[indicatorKey];

              if (JSON.stringify(indicatorValue1) !== JSON.stringify(indicatorValue2)) {
                mods.push({ 
                  fiels: `indicators.${index}.${indicatorKey}`, 
                  value: indicatorValue1 
                });
              }
            }
          }
        });
      } else if (JSON.stringify(value1) !== JSON.stringify(value2)) {
        mods.push({ fiels: key, value: value1 });
      }
    }
  }

  return mods;
}
/*export function comparePricesKeys(price1: Price|undefined, price2: Price): Mod[] {
  const mods: Mod[] = [];

  for (const key in price1) {
    if (price1.hasOwnProperty(key) && price2.hasOwnProperty(key)) {
      const value1 = price1[key as keyof Price];
      const value2 = price2[key as keyof Price];

      if (JSON.stringify(value1) !== JSON.stringify(value2)) {
        mods.push({ fiels: key, value: value1 });
      }

    }
  }

  return mods;
}*/


/**
 * Given an array of Mods, and a tag passed as a parameter, 
 * it checks whether the tag exists in any Mod element in the fiels field.
 * If it exists, it returns the value field, if it does not exist, it returns null
 * @param mods:Mod[]
 * @param tag:string
 * @returns any - value of the mod
 * */
export function getValueOfMod(mods: Mod[], tag: string): any {
  const mod = mods.find((m) => m.fiels == tag);
  return mod ? mod.value : null;
}



export function showSlidePriceDetails(store:Store<appState>, price:Price){
  store.dispatch(setPriceDetails({price:price}));
  store.dispatch(showPriceDetails({show:true}));
}

/*Given an array of Prices and an id, it searches each element of the array if its 'priceId' property is equal to the id, 
and if so, it returns the position of the element in the array.*/
export function getIndexForPriceId(prices: Price[], id: any) {
  return prices.findIndex(price => price._id === id);
}

/*Function that is passed an array of selected prices, a price to select and the redux store.
Adds or removes the selected price in the array and sets it in the redux store.*/
export function selectPrice(price: Price, pricesSelected:Price[], store:Store<appState>) {
  const positionInPriceList = getIndexForPriceId(pricesSelected, price._id);
  const pricesSelectedAux = [...pricesSelected];
  //Is element in array
  positionInPriceList != -1? pricesSelectedAux.splice(positionInPriceList, 1):pricesSelectedAux.push(price);

  store.dispatch(setPricesSelected({ prices: pricesSelectedAux }));
}

export function isConditionForward(price:Price){
  return price?.typeBusiness==businessDict.forward;
}

// -------- GETS -------- //
/**
 * Given a number, returns the quality in string format
 * @param quality:number
 * @returns string
 */
export function getQualityStr(qual:number){
  const QUALITY:any=quality;
  return QUALITY[qual] ?? '--';
}

/**
 * Given a number representing an IBM port, it returns its string format.
 * @param port:number
 * @returns string
 */
export function getPortIBMStr(port:number){
  const map:any=PLANTAS_INTERIOR_MAP;
  const name=map[port].nombre;
  return name=='--'? '': name;
}