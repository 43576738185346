import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WashOutModule } from './wash-out/wash-out.module';
import { SelladoARecuperarModule } from './sellado-a-recuperar/sellado-a-recuperar.module';
import { RespetaIvaModule } from './respeta-iva/respeta-iva.module';
import { RebajaModule } from './rebaja/rebaja.module';
import { PactadoConModule } from './pactado-con/pactado-con.module';
import { GravanzModule } from './gravanz/gravanz.module';
import { FleteCargoCompradorModule } from './flete-cargo-comprador/flete-cargo-comprador.module';
import { EntregaSiloBolsasModule } from './entrega-silo-bolsas/entrega-silo-bolsas.module';
import { EntregaPlantasGrassiModule } from './entrega-plantas-grassi/entrega-plantas-grassi.module';
import { ConvenioLaboratorioModule } from './convenio-laboratorio/convenio-laboratorio.module';
import { ConvenioModule } from './convenio/convenio.module';
import { CondicionalModule } from './condicional/condicional.module';
import { ClausulaCobranzaModule } from './clausula-cobranza/clausula-cobranza.module';
import { BonificacionModule } from './bonificacion/bonificacion.module';
import { AcumuladorModule } from './acumulador/acumulador.module';
import { ARetirarModule } from './a-retirar/a-retirar.module';
import { PafGarantizadoModule } from './PAF-garantizado/PAF-garantizado.module';
import { FinancialAdvanceModule } from './anticipo-financiero/anticipo-financiero.module';
import { ConvenioNutrienModule } from './convenio-nutrien/convenio-nutrien.module';


@NgModule({
  exports: [
    WashOutModule,
    SelladoARecuperarModule,
    RespetaIvaModule,
    RebajaModule,
    PactadoConModule,
    GravanzModule,
    FleteCargoCompradorModule,
    EntregaSiloBolsasModule,
    EntregaPlantasGrassiModule,
    ConvenioLaboratorioModule,
    ConvenioModule,
    CondicionalModule,
    ConvenioNutrienModule,
    ClausulaCobranzaModule,
    BonificacionModule,
    AcumuladorModule,
    ARetirarModule,
    PafGarantizadoModule,
    FinancialAdvanceModule,
  ]
})
export class OptionsModule { }
