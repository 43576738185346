<div class="body">
    <div class="header">
        <div class="sub-menu">
            <arrow-back-svg class="cross" *ngIf="arrowViewCreateExchange=='up'" (click)="changeArrowViewCreateExchange()"></arrow-back-svg>
            <span class="add-buyer title">Agregar canje</span>
        </div>
        <cross-svg class="cross" (click)="close()" *ngIf="arrowViewCreateExchange!='up'"></cross-svg>
    </div>
    <div class="width-100 body-container" *ngIf="arrowViewCreateExchange!='up'">

        <p class="description">Orden creada por {{order.dataOfcreation.nameOfcreator}} a las {{order.dataOfcreation.hour}} hr.</p>

        <!------------- DATOS DEL CANJE -------------------->
        <div class="exchange-section width-100">
            <div class="header-section header-exchange">
                <span class="header-section-text text">Canje</span>
                <span class="header-section-data text buyer">Comprador: {{getBuyerData(order)}}</span>
            </div>
    
            <arrow-svg class="arrow" [direction]="arrowViewExchange" (click)="changeArrowViewExchange()"></arrow-svg>
        </div>
    
        <div class="exchange-information" *ngIf="arrowViewExchange == 'up'">
            <app-details-small class="details" [order]="order" [price]="order.price" [isExchange]="true"></app-details-small>
        </div>
    
    
        <!------------- DATOS DE LA ORDEN -------------------->
    
        <div class="orders-description width-100" *ngIf="!isOrderIncomplete(order)">
    
            <div class="header-section header-order">
                <span class="header-section-text text">OV - Contrato madre asociado: <span class="state {{getStatusContractMother(order).toLowerCase()}}">{{getStatusContractMother(order)}}</span></span>
                <span class="header-section-data text seller">Vendedor: {{getSellerData(order)}}</span>
            </div>
    
            <arrow-svg class="arrow" [direction]="arrowViewOrderData" (click)="changeArrowViewOrderData()"></arrow-svg>
        </div>
    
        <div class="orders-information" *ngIf="arrowViewOrderData == 'up'">
            <app-details-small class="details" [order]="order" [price]="order.price"></app-details-small>
        </div>

    </div>

    <!------------- COMPLETAR DATOS DEL CANJE -------------------->

    <div class="complete exchange-data button-container width-100" >
        <div class="line grey" style="margin-bottom:8px"></div>
        <div class="button complete exchange" (click)="changeArrowViewCreateExchange()" *ngIf="arrowViewCreateExchange!='up'">
            <div class="button-init">
                <plus-generate-order-svg></plus-generate-order-svg>
                <span class="text enabled">Agregar datos</span>
            </div>
            <arrow-svg class="arrow" [direction]="arrowViewCreateExchange" [color]="'caribean-green'"></arrow-svg>
        </div>

        <span class="message error">{{messageError}}</span>

        <div class="section create" *ngIf="arrowViewCreateExchange=='up'">
            <div class="exchanfe-info">
                <div class="exchange-data" *ngFor="let exchange of getExchanges(); index as i">
                    <span class="exchange-data-text" [ngClass]="{'last': i==getExchanges().length-1}" (click)="selectExchangeInArray(i)">Vendedor: {{getBuyerDataOfExchange(exchange)}}</span>
                </div>
            </div>
            <div class="row-between">

                <span class="missing-tons" *ngIf="showMissingTons()">
                    Quedan <span class="stroke">{{tonsAvaible}} toneladas</span> pendientes.
                </span>
                <span class="add other" (click)="addOtherExchange()" 
                *ngIf="showMissingTons()">+ Agregar otro</span>
            </div>
            
            <app-seller-name  [fieldIdentificator]="'exchange'"></app-seller-name>
            <app-how-sell [fieldIdentificator]="'exchange'"></app-how-sell>
            <app-place-of-origin [fieldIdentificator]="'exchange'"></app-place-of-origin>
            <app-price *ngIf="!orderHaveIndicators(order)" [fieldIdentificator]="'exchange'"></app-price>
            <app-type-coin *ngIf="!orderHaveIndicators(order)" [fieldIdentificator]="'exchange'"></app-type-coin>
            <app-tons [fieldIdentificator]="'exchange'" [maxOfTons]="order.tons"></app-tons>
            <app-agreed-discount [fieldIdentificator]="'exchange'"></app-agreed-discount>
            <app-way-pay [fieldIdentificator]="'exchange'"></app-way-pay>
            <app-bonification-exchange [fieldIdentificator]="'exchange'"></app-bonification-exchange>
        </div>
    </div>

    <div class="button-void"></div>
    
    <div class="bottom-fixed" *ngIf="arrowViewCreateExchange=='up'">
        <button class="not-filled-button" (click)="createNewExchange()">
            <span class="text-button">Crear nuevo canje</span>
        </button>
    </div>
</div>