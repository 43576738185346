import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DolarComponent } from './dolar.component';



@NgModule({
  declarations: [
    DolarComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    DolarComponent
  ]
})
export class DolarModule { }
