
<div class="dialog-content" mat-dialog-content>
  <div class="logo-container">
    <img class="logo confirm" *ngIf="isSuccess" alt="check-confirm" src="../../../../../../assets/images/extra/confirmed.svg" />
    <img class="logo denied" *ngIf="!isSuccess" alt="check-denied" src="../../../../../../assets/images/extra/denied.svg" />
  </div>
  <span class="hour">{{hour}}</span>
  <p class="message">{{getMessage()}}</p>
  <p class="more-info">{{moreInfo}}</p>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close class="button not-stuffed" (click)="generateNewPrice()">Generar otra condición</button>
  <button mat-button mat-dialog-close class="button filling" (click)="goToPriceBoard()">Ir al tablero de condiciones</button>
</div>