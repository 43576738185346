import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgreedDiscountComponent } from './agreed-discount.component';
import { NumberInputModule } from '../../inputs/number/number.module';



@NgModule({
  declarations: [
    AgreedDiscountComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule
  ],
  exports: [
    AgreedDiscountComponent
  ]
})
export class AgreedDiscountModule { }
