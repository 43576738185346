<div class="views">
    <ul>
      <li class="view option">
        <span (click)="viewAll()" [ngClass]="{'view-selected': isViewAllSelected()}">
          Visualización completa</span>
        <three-point-svg (click)="viewAll()" class="not-visible-in-mobile"></three-point-svg>
      </li>
      <li class="view option" *ngFor="let view of views; index as i">
        <span (click)="changeView(i)" [ngClass]="{'view-selected': isViewSelected(view, viewSelected) }">{{ view.viewName }}</span>
        <three-point-svg (click)="editView(i)" class="not-visible-in-mobile"></three-point-svg>
        <views-prices *ngIf="i==editViewIdentificator" [identificator]="2" [view]="view" [indexToEdit]="i" [isHistorical]="isHistorical"></views-prices>
      </li>
    </ul>

    <span class="option add-view not-visible-in-mobile" (click)="openNewView()" *ngIf="isShowAddView">+ Agregar vista</span>
    <views-prices class="not-visible-in-mobile" *ngIf="-1 ==editViewIdentificator" [identificator]="1" [isHistorical]="isHistorical"></views-prices>

      
</div>