import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, tap } from "rxjs";
import { AuthService } from "src/app/shared/services/auth.service";
import { createUser, login, loginSuccess, logout, logoutFailed, logoutSuccess, refreshToken } from "../actions/session.action";
import { loginFailed } from "../actions/login.action";
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from "src/app/shared/services/token.service";
import { User } from "src/app/shared/interfaces/user.interface";
import { localStorageNames } from "src/app/shared/const/localStorageNames";
import { AreaLocalStorage } from "src/app/components/header/header.component";
import { isSameDate } from "src/app/shared/date.checker";

export interface ResponseToken {
    accessToken: string;
    refreshToken: string;
}

@Injectable()
export class SessionEffects {

    constructor(private actions$: Actions, private authSvc: AuthService,
        private tokenSvc: TokenService) { }

    getCreateUserEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUser),
            mergeMap((action) => {
                return this.tokenSvc.getToken(action.email, action.id).pipe(
                    map( (tokens:ResponseToken) => {
                        try {
                            const userLog: User = this.createUser(tokens, action.photo, action.id);
                            //That is recorded in Local Storage
                            //this.setLocalStorage(tokens, userLog);
                            setInCookies(tokens);
                            localStorage.setItem(localStorageNames.user, JSON.stringify(userLog));
                            
                            return loginSuccess({ user: userLog });
                        } catch (error:any) {
                            const errorMessage = error.message? error.message:'Se ha detectado un error con el servidor, por favor contactarse con el equipo de IT.';
                            return loginFailed({ message: errorMessage });
                        }
                    }),
                    catchError((error) => {
                        console.error(error)
                        const messageError='Usuario no registrado.';
                        return [loginFailed({ message: messageError })];
                    })
                )
            }))
    })

    getUserLoginEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(login),
            mergeMap((action) => {
                
                return this.authSvc.loginWithGoogle().then( (auth2:any) => {
                    const user=auth2.currentUser.get().getBasicProfile();
                    //If the user is valid...
                    const email=user.getEmail();
                    const photo=user.getImageUrl();
                    const id=user.getId();
                    console.log(email, photo, id)


                    if (email) {
                        return createUser({ email: email, photo: photo, id: id });
                    } else {throw new Error();}
                  }).catch((error:any) => {
                    console.error(error);
                    const errorMessage = "Se ha detectado un error con el servidor, por favor contactarse con el equipo de IT.";
                    return loginFailed({ message: errorMessage });
                });   
            })
        )
    })    

    getUserLogoutEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logout),
            map((action) => {
                try {
                    localStorage.removeItem(localStorageNames.user);
                    localStorage.removeItem(localStorageNames.isSelectedArea);
                    document.cookie = "access_token=; max-age=0; path=/";
                    document.cookie = "refresh_token=; max-age=0; path=/";

                    return logoutSuccess();
                } catch (e) {
                    return logoutFailed();
                }
            })
        )
    })

    private decodeToken(token: string) {
        const helper = new JwtHelperService();
        return helper.decodeToken(token);
    }

    private createUser(token: ResponseToken, photo:string, id:string): User {
        //We decoded token
        const decodeToken = this.decodeToken(token.accessToken);
        if (decodeToken.area == "") {
            throw new Error("Sistema caído o usuario no registrado.");
        } else {
            return {
                id: id,
                name: decodeToken.name, //Se saca del token
                lastName: decodeToken.lastname, //Se saca del token
                email: decodeToken.email,
                photo: photo,
                workUnit: decodeToken.workunit,
                area: this.getAreaUser(decodeToken.area) ,//decodeToken.area,
                phone: decodeToken.phone,
                birthDay: decodeToken.birthDay,
                subType: decodeToken.subType,

                team: {
                    nroTeam: decodeToken.team.nroTeam,
                    bossName: decodeToken.team.bossName,
                    bossLastName: decodeToken.team.bossLastName
                },

                role: 'admin',
            }
        }

    }

    //Dado el area del usuario que viene en el token.
    //Toma la variable del local storage y verifica:
    //Si la variable existe, la fecha selected dentro de la variable es la de hoy, y el area es distinto, devuelve el area de la variable.
    //Caso contrario, elimina la variable y devuelve el area del token.
    private getAreaUser(area: number){
        const areaSelected = localStorage.getItem(localStorageNames.isSelectedArea);
        if(areaSelected){
            const areaSelectedParse:AreaLocalStorage = JSON.parse(areaSelected);
            const dateSelected = new Date(areaSelectedParse.dateSelected);
            const dateToday = new Date();
            if(isSameDate(dateToday, dateSelected) && areaSelectedParse.area != area){
                return areaSelectedParse.area;
            }else{
                localStorage.removeItem(localStorageNames.isSelectedArea);
                return area;
            }
        }else{
            return area;
        }
    }
}

export function setInCookies(tokens:ResponseToken){
    document.cookie = `access_token=${tokens.accessToken}; max-age=50400; path=/`;
    document.cookie = `refresh_token=${tokens.refreshToken}; max-age=50400; path=/`;
}