import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccumulatedTonnageComponent } from './accumulated-tonnage.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { NumberPipe } from 'src/app/shared/pipes/number.pipe';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    AccumulatedTonnageComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    PipesModule
  ],
  exports:[
    AccumulatedTonnageComponent,
  ]
})
export class AccumulatedTonnageModule { }
