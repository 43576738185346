import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker, isInvalidStartDate } from 'src/app/shared/date.checker';
import { businessDict } from 'src/app/shared/dict/typeBusiness';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields.component';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { getWayPayDefault } from 'src/app/shared/way-pay';
@Component({
  selector: 'app-start-date',
  templateUrl: './start-date.component.html',
  styleUrls: []
})
export class StartDateComponent extends Field implements OnInit {
  constructor(store: Store<appState>,private date: DateChecker) {
    super(store);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator == fieldType.PRICE) {
      this.tag = priceTags.deliveryPeriod;
      this.subTag = priceTags.startDate;
      this.thirdTag = '';
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.deliveryPeriod;
      this.thirdTag = priceTags.startDate;
    }
  }

  override setElement(event:any, index:number){
    try {
      this.setTag(priceTags.startDate); //Set tag "startDate"
      let dateToSave = event;
      this.setElementToGenerate(dateToSave); //Set element to generate with the date


      this.setTag(priceTags.endDate);
      let date= '--';
      
      if( businessDict.forward == this.getPrice().typeBusiness && this.date.stringToDate(dateToSave).getDate() == 1 && event!='--'){
        //Si es foward, y la fecha es primero, calcular desde inicio de mes hasta fin. Sino 30 días
        date = this.date.getDateEndOfMonth(dateToSave);
      } else if (event!='--') {
        date=this.date.addDaysToDate(dateToSave, 30);
      }
      if(event !='--'){
        this.setElementToGenerate(date);
      }

      this.setDaysOfWayPay(this.getPrice());
    } catch (err) {}
  }

  override rememberValueInForm() {
    this.rememberDeliveryPeriod();
  }

  //Remember value from date type (For deliveryPeriod).
  rememberDeliveryPeriod() {
    try{
      this.setTag(priceTags.startDate);
  
      let valueToSet: any = this.rememberValue();
      //If There is a value to remember
      if (valueToSet && this.elementToGenerate) {
  
        if (valueToSet) {
          if(valueToSet != '--'){
            this.elementSelected=valueToSet;
            this.isInvalid = this.isInvalidDate();
          } else {
            this.elementSelected='';
            this.isInvalid=false;
          }
        }
      }
    } catch(err){
      this.elementSelected='';
      this.isInvalid=false;
    }
  }

  isInvalidDate(){
    return isInvalidStartDate(this.getPrice()?.deliveryPeriod?.startDate ?? '--');
  }


  setDaysOfWayPay(price:Price){
    this.setFirstTag(priceTags.wayPay)
    this.setTag('');

    const wayPayName= price.wayPay.wayPayName;
    if(wayPayName>0){
      const wayPay= getWayPayDefault(price, wayPayName);
      this.setElementToGenerate(wayPay);
    }

    this.setFirstTag(priceTags.deliveryPeriod);
  }
}
