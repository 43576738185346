<div class="background opaque" [ngClass]="{'visible': showModal, 'invisible': !showModal}"></div>

<div class="background" [ngClass]="{'visible': showModal, 'invisible': !showModal}">
<div class="dialog" >
    <div class="header">
    </div>

    <div class="content">
        <p class="text">{{title}}</p>
        <!--<p class="text">¿Querés continuar de todas formas?</p>-->
    </div>

    <div class="buttons">
        <button class="not-filled-button" (click)="confirm()">Si</button>
        <button class="filled-button" (click)="noConfirm()">No</button>
    </div>
</div>

</div>