import { Component, Input } from '@angular/core';

@Component({
  selector: 'eye-svg',
  templateUrl: './eye.component.html',
  styleUrls: ['./../../svg.scss']
})
export class EyeComponent {

  @Input() active:boolean=false;
  constructor() { }
}
