import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutorizateSvgComponent } from './autorizate/autorizate.component';
import { EditContractSvgComponent } from './edit-contract/edit-contract-svg.component';



@NgModule({
  declarations: [
    AutorizateSvgComponent,
    EditContractSvgComponent,
  ],
  imports: [
    CommonModule
  ], exports: [
    AutorizateSvgComponent,
    EditContractSvgComponent,
  ]
})
export class ActionsControlPanelModule { }
