import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { BUSINESS_PARTICULARITIES_VOID } from 'src/app/shared/const/business-particularities';
import { WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { PriceValidator } from 'src/app/shared/price';
import { FormComponent } from '../form.component';
import { SessionChecker } from 'src/app/shared/session.checker';
import { SELLER_DATA_VOID } from 'src/app/shared/const/orders';

@Component({
  selector: 'app-form-order',
  templateUrl: './form.component.html',
  styleUrls: [ './../form.component.scss']
})
export class FormOrderComponent extends FormComponent implements OnInit, OnDestroy {
  isDuplicateOrder:boolean=false;
  isSyngentaAgroAVC:boolean=false;
  isDisabled:boolean=false;

  private orderToForm$!:Subscription;
  
  constructor(store:Store<appState>,orderExcV:OrderExchangeValidator, orderV:OrderValidator, priceV:PriceValidator,  dialog: MatDialog, checker:SessionChecker) { 
    super(store, checker, orderExcV, orderV, priceV, dialog);

    this.orderToForm$=this.store.select('order','generateOrders').subscribe(orders=>{
      this.isDuplicateOrder=orders.length>1;
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orderToForm$?.unsubscribe();
  }

  override functionsWithFormOrders(order: Order): void {
    this.isSyngentaAgroAVC=this.orderV.orderIsSyngentaAgroAvc(order);
    //Las ordenes manuales no tienen valid until
  }

  override refreshDisabledOrder(): void {
    this.isDisabled=this.isDisabledOrder(this.showExchangeOptions)
  }

  override deleteStep(numOfStep: number): void {
    let order:Order=JSON.parse(JSON.stringify(this.order));
    if(numOfStep==1){
      order.sellerData= SELLER_DATA_VOID;
      order.placeOfOrigin = {province: -1, town:-1, afip: -1};
      order.broke= 1;
      order.price.businessParticularities= BUSINESS_PARTICULARITIES_VOID;
      order.howSell= -1;
      order.buyer= {onlySell: -1, notSell: -1};
      order.tons= -1;
      order.quotas= "[]";
    } else if(numOfStep==2){
      order.price.typeBusiness= -1;
      order.price.productName= -1;
      order.price.price=-1;
      order.price.typeCoin='--';
      order.price.placeOfDelivery={port: -1, zone: -1};
      order.price.deliveryPeriod= {endDate: '--', startDate: '--'};
    } else if(numOfStep==3){
      order.price.quality= -1;
      order.price.harvest='--';
      order.price.wayPay= WAY_PAY_VOID;
      order.price.pesificacion={endDate: '--', startDate: '--'}
      order.obsParam='--';
    } else if(numOfStep==4){
      order.price.conditionType= 0;
      order.price.indicators= [];
    }
    this.store.dispatch(setOrderToGenerate({order:order}));
  }

  override deleteAll(numOfStep:number=0){
    let order:Order=JSON.parse(JSON.stringify(this.order));
    order.sellerData= SELLER_DATA_VOID;
    order.placeOfOrigin = {province: -1, town:-1, afip: -1};
    order.broke= 1;
    order.price.businessParticularities= BUSINESS_PARTICULARITIES_VOID;
    order.howSell= -1;
    order.buyer= {onlySell: -1, notSell: -1};
    order.tons= -1;
    order.quotas= "[]";
    order.price.typeBusiness= -1;
    order.price.productName= -1;
    order.price.price=-1;
    order.price.typeCoin='--';
    order.price.placeOfDelivery={port: -1, zone: -1};
    order.price.quality= -1;
    order.price.deliveryPeriod= {endDate: '--', startDate: '--'};
    order.price.harvest='--';
    order.price.wayPay= WAY_PAY_VOID;
    order.price.pesificacion.startDate= '--';
    order.price.pesificacion.endDate= '--';
    order.obsParam='--';
    order.price.conditionType= 0;
    order.price.indicators= [];
    this.store.dispatch(setOrderToGenerate({order:order}));
  }

}
