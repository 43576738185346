<div class="background-svg">
    
    <svg *ngIf="!active" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="22px" height="23px" viewBox="0 0 22 23" enable-background="new 0 0 22 23" xml:space="preserve">  <image id="image0" width="22" height="23" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAMAAAA4Nk+sAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA6lBMVEUAAADp9Prs8vzr8/vr 8/vf7//p8fnq8frp8fnq8vrv9//q8frq8vrr8fnn7/fp8frq8vrv8/vq8/vq8frq8/rq8vrr8/vn 8/vr8vrq8vnp8/vs9Pzq8vrr8vrq9Prr8/vq8vrq9Prq8vrn7vbJzNDEx8rDxsnDx8nFyM3p8fnD x8uioqKwsLCrq6ugoKDJzdK6vsKQkJCvr6+urq6jo6Obm5u4vL6jpaeMjIyRkZGdnZ2cnJylpqbg 6O/Axcna4Ofk6/OWlpbCxsqRkpKampqmp6mbm52hoaLg5+2foKHKz9S2ub3i6fD///+OzCBPAAAA InRSTlMAL19/PxB/79+fH29ffyDfvz+Pz8/vj0C/339f3+9vQGAwfjKxIgAAAAFiS0dETYBoIGUA AAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnAx8PBCGrFi7kAAAA0klEQVQoz22R5RbCMAyF A8WG23AZheKug+Fu7/88nHWVM+D+6c3XNOlJAJgcToScLrDJ7fFiKp/ilzTAIL0IchrCNnn+UsbD +Edm58gvjgLEzLNaI3XSaBBC6s0WTVeshHana6rXH5hRHBLs4XDUGU+mszkNkqDyggt9uTLWG+pV EH22+tIwdiyQ2Xv9YByZV0VtjE/nC7dJSAl8vd25Vax/Uz2ewqYBMty/BM0CyPS3wHTm6HskOWuy 6C8FyBckLJbk1sIKuygit33LZQ0hrcKjD5gjNfhYL9n6AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIz LTAzLTMxVDE1OjA0OjMzKzAwOjAwXexobwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMy0zMVQx NTowNDozMyswMDowMCyx0NMAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDMtMzFUMTU6MDQ6 MzMrMDA6MDB7pPEMAAAAAElFTkSuQmCC"/>
    </svg>

    <svg *ngIf="active" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22" enable-background="new 0 0 22 22" xml:space="preserve">  <image id="image0" width="22" height="22" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAnFBMVEUAAAD///////////// ///////////////////////////////+9t/0zl/3twD7ugD6uQHusgX035/s0H/mqwD0tgDvswTl rQnuzGnjuT/aogDgpwDtsALnrQnkrArntij8+Or26cPhqQf79N7fsCjbowHx1YToxWHdpQPpvDrg qAbmsx/79eDs0YDjqwn04KLu0oHty2Xx2ZL9+O0CU8MWAAAAC3RSTlMAEG+v379fH3/vIP8KsRsA AAABYktHRAH/Ai3eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5wMfDxAXSgJsKAAAALNJ REFUGNN9kecagiAUhg9TlLZNK2zv3f3fW0gCltn3B773OXAWgBHChEpJGQ/AC4lQWglHIyoLosEv 6vgX1RxpKmRJ+v8oO2v1RtOo1e5oGyLg74C429OwPxgah4HlD0fJeDJV6cwY4hLOk2Sh1DJPCi7P aq3UxhqPtzu1d8ZXHR+OJ1c5EHs9X64umAG219vd98MB2eE9nh4HVc0DKo/q32Ar1vAx3FCgwjYj zrIVE5zDF8loFAU3Un3KAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAzLTMxVDE1OjE2OjIzKzAw OjAwVD1nDAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMy0zMVQxNToxNjoyMyswMDowMCVg37AA AAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDMtMzFUMTU6MTY6MjMrMDA6MDBydf5vAAAAAElF TkSuQmCC"/>
    </svg>
      

    <div class="tooltip row-center w-50 down" style="z-index:1">
        <span>Gravanz</span>
    </div>
</div>