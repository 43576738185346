import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BPSlideCardComponent } from './business-particularities.component';
import { OptionsModule } from '../../fields/business-particularities/options/options.module';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';

@NgModule({
  declarations: [
    BPSlideCardComponent
  ],
  imports: [
    CommonModule,
    OptionsModule,
    CrossSvgModule
  ], exports:[
    BPSlideCardComponent
  ]
})
export class BPSlideCardModule { }
