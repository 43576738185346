import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClockSvgComponent } from './clock/clock.component';
import { CopySvgComponent } from './copy/copy.component';
import { CreateSvgComponent } from './create/create.component';
import { DeleteSvgComponent } from './delete/delete.component';
import { EditOptionSvgComponent } from './edit/edit.component';
import { GenerateOrderOptionSvgComponent } from './generate-order/generate-order.component';


@NgModule({
  declarations: [
    ClockSvgComponent,
    CopySvgComponent,
    CreateSvgComponent,
    DeleteSvgComponent,
    EditOptionSvgComponent,
    GenerateOrderOptionSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    ClockSvgComponent,
    CopySvgComponent,
    CreateSvgComponent,
    DeleteSvgComponent,
    EditOptionSvgComponent,
    GenerateOrderOptionSvgComponent
  ]
})
export class MenuOptionsSvgModule { }
