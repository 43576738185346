import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { localStorageNames } from "src/app/shared/const/localStorageNames";
import { orderStatusConst } from "src/app/shared/const/orders";
import { Order } from 'src/app/shared/interfaces/order.interface';
import { resetOrderToGenerate, setIndexToEdit, setOrdersToGenerate} from 'src/app/redux/actions/order.action';
import { User } from 'src/app/shared/interfaces/user.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OrderService } from 'src/app/shared/services/order.service';
import { areas } from 'src/app/shared/const/user.const';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { OrderValidator, isCommoditiesOrHughes, resetAllOrders } from 'src/app/shared/order';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { setOrderToGenerateInExchange } from 'src/app/redux/actions/exchange.action';
import { catchError, Subscription } from 'rxjs';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { setModalLoading } from 'src/app/redux/actions/options.action';


@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.scss']
})

export class DialogOrderCreate implements OnInit, OnDestroy {
  hour: string = '';
  mensaje: string = 'La orden ya está lista para generarse.';
  moreInfo: string = '';
  isConfirm: boolean = true;

  //Orders
  identificatorOrderEdit: number = -1;
  disabledButton: boolean = true;

  sellerIsExchange: boolean = false;

  private user!: User; //Store of Redux.
  public orders: Order[] = [];
  isComercial: boolean = false;
  isOperator: boolean = false;
  order!: Order;
  orderExchange!: OrderExchange;
  isGenerateOrderExchange: boolean = false;

  private orderSubscription!: Subscription;
  private orderExchangeSubscription!: Subscription;
  private userSubscription!: Subscription;

  constructor(private router: Router, private store: Store<appState>, public date: DateChecker,
    public dialogRef: MatDialogRef<DialogOrderCreate>, 
    private orderExcV: OrderExchangeValidator, 
    private orderV: OrderValidator,
    private toFixV: ToFixValidator,
    private orderSvc: OrderService,
    private IBM:ContractChecker) {
    this.hour = this.date.setHourFormat();
    this.moreInfo = '';
  }

  ngOnInit(): void {
    this.orderSubscription = this.store.select('order').subscribe(orderReducer => {
      this.orders = orderReducer.generateOrders;
      this.order = orderReducer.generateOrder;
      this.identificatorOrderEdit = orderReducer.indexToEdit;
      this.sellerIsExchange = orderReducer.validation.isExchanger;
    })

    this.orderExchangeSubscription = this.store.select('exchange').subscribe(exchange => {
      this.isGenerateOrderExchange = exchange.isGenerateOrderExchange;
      this.orderExchange = exchange.generateOrder;
    })

    this.userSubscription = this.store.select('usuario').subscribe(user => {
      this.user = user;
      this.isComercial = user.area == areas.comercial;
      this.isOperator = user.area == areas.commodities;
    })
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe();
    this.orderExchangeSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    //this.IBM.optionsSubscription?.unsubscribe();
  }

  borrador() {
    if (this.isComercial) {
      this.sendToBorrador();
    } else if(this.isOperator){
      this.sendToTickets();
    }
  }

  operator() {
    if (this.isComercial) {
      this.goToOrderBoard();
    } else if(this.isOperator){
      this.goToOrderBoard();
    }
  }

  deleteOrdersFromEraserOrderList() {
    if (this.isComercial) {
      if (this.orders.length == 1) {
        this.orderV.deleteOrderInEraserOrder(this.order);
      } else {
        this.orderV.deleteOrdersInEraserOrder(this.orders);
      }
    }
  }

  //Commercial Personal Draft
  sendToBorrador() {
    if (this.orders.length == 1) {
      if (this.order._id <= 0) {
        this.orderV.addOrderInEraserOrder(this.order, this.user);
      } else {
        this.orderV.editOrderInEraserOrder(this.order);
      }
    } else {
      let isFirstEdit = false;
      for (let i = 0; i < this.orders.length; i++) {
        if (!isFirstEdit && this.orders[i]._id > 0) {
          this.orderV.editOrderInEraserOrder(this.orders[i]);
          isFirstEdit = true;
        } else {
          this.orderV.addOrderInEraserOrder(this.orders[i], this.user);
        }
      }

      this.store.dispatch(setOrdersToGenerate({ orders: [this.order] }));
      this.store.dispatch(setIndexToEdit({ indexToEdit: 0 }));
    }

    this.resetAllOrders();
    const url = "/" + NAVEGATION.ERASER;
    this.router.navigate([url]);
    this.dialogRef.close();
  }

  //Send to tickets directly (IBM)
  sendToTickets() {
    if(this.orders.length == 1){
      this.sendOrderToIBM(this.order);
    } else {
      for (let i = 0; i < this.orders.length; i++) {
        this.sendOrderToIBM(this.orders[i]);
      }
    }

    this.router.navigate(["/" + NAVEGATION.ORDER_BOARD]);
    this.dialogRef.close();

    this.store.dispatch(resetOrderToGenerate());
  }

  sendOrderToIBM(order:Order){
    let orderCopy = this.orderV.setMainFeaturesOfOrders(order,this.user);
    this.orderSvc.createOrder(orderCopy).subscribe((res: ResponseRequest) => {
      orderCopy._id = res.data._id;
      orderCopy.created_at= res.data.created_at;
      this.IBM.createContracts([orderCopy], this.getNroCallByBuyer(orderCopy), this.user);
    });
  }

  getNroCallByBuyer(order:Order){
    return isCommoditiesOrHughes(order)? 1:2;
  }

  goToOrderBoard() {
    this.deleteOrdersFromEraserOrderList();
    if (this.orders.length == 1) {
      /*If the seller is not an exchanger, we create a common order.
      If not, we create exchange order */
      if (!this.isOrderExchange(this.order)) {
        //Si es returned, la actualizamos
        if (this.order.statusOrder == orderStatusConst.RETURNED) {
          this.orderV.updateOrder(this.order, this.user, true)
        } else {
          //Si no es returned, la creamos
          this.orderV.saveOrder(this.order, this.user, true);
        }
      } else {
        this.createOrderAndExchange(this.order, this.orderExchange, this.user)
      }
    } else {
      this.orderV.saveOrders(this.orders, this.user);
    }

    this.dialogRef.close();

  }

  createOrderAndExchange(order:Order, orderExchange:OrderExchange, user:User){
    this.store.dispatch(setModalLoading({ isLoading: true }));

    //Capturamos el canje antes de que se asigne nueva order;
    let newOrderExchange: OrderExchange = JSON.parse(JSON.stringify(orderExchange));
    this.store.dispatch(setOrderToGenerateInExchange({ order: order }));
    //Join the entire order to a trade order, another part to a price order.

    let orderToSave: Order = this.orderV.setMainFeaturesOfOrders(order, user);

    this.orderSvc.createOrder(orderToSave).pipe(
      catchError((error) => {
        console.error(error.error);
        this.store.dispatch(setModalLoading({ isLoading: false }));
        return [];
      })
    ).subscribe( (res:ResponseRequest) => {
      //Cuando es Syngenta Agro AVC, no tiene contrapartida de canje.
      if (!this.orderV.orderIsSyngentaAgroAvc(orderToSave)) {

        //Si la orden es 'a precio' y no tiene precio, significa que debemos autocompletar los datos.
        //Si la orden es 'a fijar' y no tiene vendedor/comprador, significa que debemos autocompletar los datos.
        //De lo contrario, significa que la contrapartida ya tiene datos y sus proformas cargadas
        const isToPrice= !this.toFixV.orderHaveIndicators(orderToSave); //Es a precio si no tiene indicadores

        if ( (isToPrice && newOrderExchange.exchange.price <= 0) || 
        (!isToPrice && newOrderExchange?.exchange?.buyer?.codeS<=0)) {
          //Significa que nunca completó los datos, por lo tanto, lo autocompletamos con datos vacios.
          newOrderExchange.exchange = this.orderExcV.getExchangeInitial(orderToSave);
        } 

        newOrderExchange.exchange.idMother = res.data._id; //En el idMother guardamos el id de la orden madre

        orderToSave._id = res.data._id; //Copiamos el id porque todavía no existe
        orderToSave.created_at = this.date.getDateInYYYYMMDDformat(new Date());//Obtenemos el created at porque todavía no lo tenemos
        this.orderExcV.saveOrder(newOrderExchange, this.user, orderToSave);
      }

      this.store.dispatch(setModalLoading({ isLoading: false }));
      this.router.navigate(["/" + NAVEGATION.ORDER_BOARD]);
    });
  }

  isOrderExchangeIncomplete() {
    return this.orderExcV.isOrderIncomplete(this.orderExchange);
  }

  resetAllOrders() {
    resetAllOrders(this.store);
  }

  /*If the seller is not an exchanger, we create a common order.
  If not, we create exchange order */
  isOrderExchange(order: Order) {
    //El vendedor debe ser canjeador, la orden no debe tener estado devuelta, y el vendedor no debe ser: Syngenta Agro AVC
    const isExchanger = this.sellerIsExchange;
    const isReturned = order.statusOrder == orderStatusConst.RETURNED;
    const isSyngentaAgroAVC = this.orderV.isSyngenta(order.sellerData.codeS) &&
      order.price.businessParticularities.subdivisionSyngenta == 1 &&
      order.price.businessParticularities.isAVC == 1;
    return isExchanger && !isReturned && !isSyngentaAgroAVC;
  }
}


