import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessParticularitiesComponent } from './business-particularities.component';
import { OptionsModule } from './options/options.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BPSlideCardModule } from '../../slide-cards/business-particularities/business-particularities.module';



@NgModule({
  declarations: [
    BusinessParticularitiesComponent
  ],
  imports: [
    CommonModule,
    OptionsModule,
    FormsModule,
    PipesModule,
    BPSlideCardModule
  ], exports: [
    BusinessParticularitiesComponent
  ]
})
export class BusinessParticularitiesModule { }
