import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelladoARecuperarComponent } from './sellado-a-recuperar.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';



@NgModule({
  declarations: [
    SelladoARecuperarComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule
  ], exports: [
    SelladoARecuperarComponent
  ]
})
export class SelladoARecuperarModule { }
