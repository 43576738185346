import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setEditIndicator, setPriceToGenerate, showEditIndicators } from 'src/app/redux/actions/price.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';
import { setErrorStruct } from 'src/app/redux/actions/options.action';
import { areas } from 'src/app/shared/const/user.const';
import { indicatorsTags, priceTagsToFix } from 'src/app/shared/const/to-fix';

@Component({
  selector: 'app-indicators-table',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
})
export class IndicatorsTableComponent implements OnInit, OnDestroy {
  @Input() price!:Price;
  @Input() showFirstColumn:boolean=false;
  @Input() user!:User; //Pasado por parametro en caso de que se cambie algo de los indicadores.
  @Input() isDisabled:boolean=false;

  showOptionsIdentificator: number=-1;

  isComercial:boolean=true;
  user$!:Subscription;
  constructor(private store:Store<appState>, private toFixV: ToFixValidator, private priceV:PriceValidator) {
    this.user$=this.store.select('usuario').subscribe((user)=>{
      this.isComercial=user.area==areas.comercial;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  select(indicator: Indicator) {}
  isIndicatorSelected(indicator: Indicator) {}

  //-------- MENU OPTIONS --------//
  editIndicator(indicator:Indicator, index:number){
    this.store.dispatch(setEditIndicator({indicator: indicator, conditionType: this.price.conditionType, index: index}));
    this.store.dispatch(setPriceToGenerate({price: this.price}));
    this.store.dispatch(showEditIndicators({show: true}));
    this.closeOptions();
  }

  deleteIndicator(indicator:Indicator){
    //Si la condición, tiene un solo indicador, no se puede eliminar.
    if(this.price.indicators.length<=1){
      this.store.dispatch(setErrorStruct({error: {color: '#fff', isVisible:true,  message: 'No se puede eliminar el último indicador de la condición.'}}));
    } else {
      //Si tiene mas de un indicador, se elimina el indicador.
      const indexToIndicator=this.price.indicators.indexOf(indicator);
      if(indexToIndicator>=0){
        const newPrice=this.toFixV.deleteIndicatorInPrice(this.price, indexToIndicator);
        this.priceV.updatePrice(newPrice, this.user);
      }
    }
    this.closeOptions();
  }

  changeIsEnabled(indicator:Indicator){
    const indexToIndicator=this.price.indicators.indexOf(indicator);
    if(indexToIndicator>=0){
      const field=priceTagsToFix.indicators+'.'+indexToIndicator+'.'+indicatorsTags.enabled;
      this.priceV.editPrice(this.price, field, !indicator.enabled, this.user);
    } 
  }

  showOptions(identificator:number) {
    this.showOptionsIdentificator= this.showOptionsIdentificator==identificator? -1:identificator;
  }

  closeOptions(){
    this.showOptionsIdentificator=-1;
  }

  //-----------------------------//
  getConditionType(){
    return this.toFixV.getConditionType(this.price.conditionType);
  }

  getMacroFixationPeriodToFix(){
    return this.toFixV.getMacroFixationPeriod(this.price.indicators);
  }

  getFixationPeriod(indicator: Indicator) {
    return this.toFixV.getFixedPeriod(indicator);
  }

  getIndicatorName(indicator: Indicator) {
    return this.toFixV.getIndicatorName(indicator);
  }
  
  getQuality(indicator:Indicator){
    return this.toFixV.getQuality(indicator);
  }

  getProductAndPercentage(indicator:Indicator){
    return this.toFixV.getPercentageAndProductName(indicator)
  }

  getProporcionality(indicator:Indicator){
    return this.toFixV.getProporcionalityShort(indicator);
  }

  getLocationIndicator(indicator: Indicator) {
    return this.toFixV.getPlace(indicator);
  }

  getMinAndMax(indicator: Indicator) {
    return this.toFixV.getMinAndMax(indicator);
  }

  getMonthAndYear(indicator: Indicator) {
    return this.toFixV.getMonthAndYear(indicator);
  }

  getReduction(indicator: Indicator) {
    return this.toFixV.getReduction(indicator);
  }

  getBonification(indicator: Indicator) {
    return this.toFixV.getBonification(indicator);
  }

  getIndicatorsOfPrice(){
    return this.price?.indicators ?? [];
  }
}
