<div class="field" #fieldName>
  <span class="field-title">Forma de pago<span class="asterisk">*</span></span>
  <div>
    <div class="form-field" (click)="changeShowOptions()" [ngClass]="{'border-green': isOptionSelected,
    'is-incomplete': isIncomplete || isInvalid}">
      <span class="title-field  {{identifyClassName}}"> 
      
         <span class="autocomplete"> 
            <span class="first-part">{{search}}</span> 
            <span class="second-part">{{placeHolder}}</span>
          </span>

          <input #inputName (focus)="focusField(fieldName)" (blur)="unfocusField()" 
          [readOnly]="isReadOnly()"
          value="{{elementSelected}}" [(ngModel)]="search" placeholder="{{initalElementSelected}}" 
          class="{{identifyClassName}} no-background-border" />
          <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
          <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}"
              src="../../../../../../assets/images/extra/arrow-down-green.svg" />
      </span>
    </div>
    <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>
    <span class="text-is-incomplete" *ngIf="isInvalid">La combinación de forma de pago de porcentaje y días no existe, por favor ingresá otra.</span>
    <!--<span class="msg-warning" *ngIf="getIsInvalidWayPayDefault()">La forma de pago no concide con la del contrato madre, verificá que sea la correcta.</span>-->

    <div class="options {{identifyClassName}} special-option" *ngIf="showOptions" > 
      <div  *ngFor="let element of array; index as i" class="option {{identifyClassName}}" >
          <span class="{{identifyClassName}}" [ngClass]="{ selected:  OBJECT[element] == elementSelected }" 
          (click)="setElement(element, i)">
            {{ OBJECT[element] }}
          </span>
      </div>
    </div>

    <app-way-to-pay [ngClass]="{'invisible': (getWayPay()?.wayPayName ?? 0)<=0}" class="{{identifyClassName}}" 
    [fieldIdentificator]="fieldIdentificator"  [wayPay]="getWayPay()" [identifyClassName]="identifyClassName" [price]="getPrice()"
    [isInvalid]="isInvalidAux"></app-way-to-pay>

    <div class="row-left gap-14 width-100" style="margin-top:12px;" *ngIf="getWayPay()?.wayPayName==WAY_PAY_CONST.conCartaGarantia">
      <div class="radio-button row-left {{identifyClassName}}">
          <input type="radio" class="radio-button-field pointer {{identifyClassName}}" [checked]="getIsOption(1)" (click)="setOptionField(1)">
          <span class="{{identifyClassName}}">Certificado de depósito</span>
      </div>

      <div class="radio-button row-left {{identifyClassName}}" >
          <input type="radio" class="radio-button-field pointer {{identifyClassName}}" [checked]="getIsOption(2)" (click)="setOptionField(2)">
          <span class="{{identifyClassName}}">Warrant</span>
      </div>
    </div>
  </div>
</div>