import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricalComponent } from './historical.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { PriceHistoricalComponent } from './price/price.component';
import { OrderHistoricalComponent } from './order/order.component';
import { HeaderDetailsModule } from '../../details/header-details/header-details.module';



@NgModule({
  declarations: [
    HistoricalComponent,
    PriceHistoricalComponent,
    OrderHistoricalComponent,
  ],
  imports: [
    CommonModule,
    HeaderDetailsModule,


    //SVG,
    CrossSvgModule
  ],
  exports: [
    HistoricalComponent,
    PriceHistoricalComponent,
    OrderHistoricalComponent,
  ]
})
export class HistoricalModule { }
