import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface OptionMenu {
  name: string;
  icon?: string;
  action: string;
  arrow?:boolean;
}

export const OPTION={
  HISTORICAL: 'historical',
  COPY: 'copy',
  EDIT: 'edit',
  REINGRESATE: 'reingresate',
  SEND: 'send',
  DELETE: 'delete',
  AUTORIZATE: 'autorizate',
  AUTORIZATE_ALL: 'autorizate_all',
  DETAILS: 'details',
  SELECT: 'select',
  SELECT_ALL: 'select_all',
}

@Component({
  selector: 'div[option-menu]',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent {
  @Input() options: OptionMenu[] = [];
  @Output() selected= new EventEmitter<OptionMenu>();

  constructor() { }

  emit(option: OptionMenu) {
    this.selected.emit(option);
  }
}
