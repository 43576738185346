import { Component, EventEmitter, Input, Output } from "@angular/core";
import { orderTags } from "src/app/shared/const/orders";
import { priceTags } from "src/app/shared/const/prices.const";
import { OrderString } from "src/app/shared/interfaces/order.interface";
import { PriceString } from "src/app/shared/interfaces/price.interface";
import { OPTION } from "./options/options.component";

@Component({
    template:''
})
export class RowHead {
  @Input() filter!:OrderString | PriceString;
  @Input() filterIdentificator!: number;
  @Input() showCheckbox:boolean=false;
  @Input() isCheckedAll:boolean=false;
  @Output() filterSelect= new EventEmitter<number>();
  @Output() actions = new EventEmitter<any>();

  constructor(){}
  getIsFilter(tag:string,subTag:string){
    try{
      const isFilterPrice=tag==orderTags.price && subTag.length>0;
      const tagToAnalice= isFilterPrice ? subTag : tag;
      const field=this.getFieldFilter(tag,subTag);

      if(Array.isArray(field) || typeof field == 'string'){
        return field.length>0; //If it is array or string, we see if its length is greater than 0
      } else if(typeof field=='number'){
        return field>0; //If it is a number, we see if it is greater than 0
      } else if(tagToAnalice==priceTags.price){
        return field.start>0 || field.end>0 || field.typeCoin!='ALL';
      } else if( (tagToAnalice==priceTags.deliveryPeriod || tagToAnalice==priceTags.pesificacion)){
        return field.startDate.length>2 || field.endDate.length>2;
      } else if(tagToAnalice==priceTags.placeOfDelivery){
        return field.zone.length>0
      } else if(tagToAnalice==orderTags.tons){
        return field.start>0 || field.end>0;
      } else if(tagToAnalice==priceTags.observations){
        return field.isFeatured || field.isFixed || field.isMessage || field.isBusinessGravanz
      } else {
        return false;
      }
    }catch(err){
      return false;
    }
  }
  
  getFieldFilter(tag:string,subTag:string=''){
      const newFilter=JSON.parse(JSON.stringify(this.filter));
      if(tag && subTag){
          return newFilter[tag][subTag];
      } 
      return newFilter[tag];
  }
  selectFilter(index:number){
      this.filterSelect.emit(index);
  }
    
  checkedAllItems(){
    this.actions.emit(OPTION.SELECT_ALL);
  }

}