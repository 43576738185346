import { createAction, props } from "@ngrx/store";

//Orders
export const setTypeOfHistorical = createAction('[HISTORICAL] Set Type Of Historical',
    props<{ typeOfHistorical: string }>());

export const setDateOfHistorical = createAction('[HISTORICAL] Set Date Of Historical',
    props<{ date: string }>());
export const setDateOfHistoricalRangeDate = createAction('[HISTORICAL] Set Date Of Historical Range Date',
    props<{ date1: string, date2:string }>());

export const setArrayOfHistoricalSuccess = createAction('[HISTORICAL] Set Array Of Historical',
    props<{ array: any[] }>());

//------- ONE DATE -------\\
export const getOrdersByDate= createAction('[HISTORICAL] Get Orders By Date',
    props<{ date: string }>());
export const getPricesByDate= createAction('[HISTORICAL] Get Prices By Date',
    props<{ date: string }>());
export const getExchangesByDate= createAction('[HISTORICAL] Get Exchanges By Date',
    props<{ date: string}>());


//-------- RANGE DATE --------\\
export const getOrdersByRangeDate= createAction('[HISTORICAL] Get Orders By Range Date',
    props<{ date1: string, date2:string }>());
export const getExchangesByRangeDate= createAction('[HISTORICAL] Get Exchanges By Range Date',
    props<{ date1: string, date2:string }>());

export const setArrayOfHistoricalFailed = createAction('[HISTORICAL] Set Array Of Historical Failed',
    props<{ error: any }>());