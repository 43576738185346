import { Pipe, PipeTransform } from '@angular/core';
import { OrderExchange } from '../../interfaces/order-exchange';
import { Order } from '../../interfaces/order.interface';
import { isSellerCommoditiesOrHughes } from '../../order';

@Pipe({
  name: 'filterCommoditiesSeller'
})
export class FilterCommoditiesSellerPipe implements PipeTransform {

  transform(orderList: OrderExchange[] | Order[], filter:boolean) {
    if(!filter) return orderList;

    let newValueToReturn = [];
    const quantOfItems = orderList.length;
    for(let i=0; i<quantOfItems; i++){
      if(isSellerCommoditiesOrHughes(orderList[i]?.sellerData?.codeS)){ 
        newValueToReturn.push(orderList[i]);
      }
    }

    return newValueToReturn;
  }

}
