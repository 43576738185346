import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import * as dict from 'src/app/shared/dict/dict';
import { PriceValidator } from 'src/app/shared/price';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { buyers } from 'src/app/shared/dict/buyers';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { orderCategory } from 'src/app/shared/dict/orders';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { Subscription } from 'rxjs';
import { areas } from 'src/app/shared/const/user.const';
import { wayPay } from 'src/app/shared/dict/wayToPay';
@Component({
  selector: 'app-info-price',
  templateUrl: './info-price.component.html',
  styleUrls: ['./../info.scss']
})
export class InfoPriceComponent implements OnInit, OnDestroy{
  public isOperator!: boolean; //Store of Redux.
  @Input() price!: Price;
  @Input() buyers:any[]=[];
  isPriceExport: boolean = false;
  isGenerateToFix:boolean=false;

  //CONSTS
  public ORDER_TYPE_CATEGORY: any;
  public PRODUCTS: any;
  public BUSINESS_TYPE: any;
  public PLACE_OF_DELIVERY: any;
  public PECULIARITIES: any;
  public QUALITY: any;
  public COIN_TYPE: any;
  public WAY_TO_PAY: any;
  public BUYERS: any;

  private optionsSubscription!:Subscription;
  private userSubscription!:Subscription;

  constructor(private store: Store<appState>, private priceV:PriceValidator) {
    this.ORDER_TYPE_CATEGORY = orderCategory;
    this.BUSINESS_TYPE = typeBusiness;
    this.PRODUCTS = PRODUCT_NAMES;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.QUALITY = dict.quality;
    this.COIN_TYPE = dict.typeCoinsStr;
    this.WAY_TO_PAY = wayPay;
    this.BUYERS = buyers;
  }

  ngOnInit(): void {
    this.userSubscription=this.store.select('usuario').subscribe(userR=>{
      this.isOperator=userR.area==areas.commodities;
    })

    this.optionsSubscription=this.store.select('options').subscribe(optionsR=>{
      this.isGenerateToFix=optionsR.isGenerateToFix
    })
  }

  ngOnDestroy(): void {
    this.optionsSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  getPlaceOfDelivery(price:Price){
    return this.priceV.getPlaceOfDelivery(price.placeOfDelivery);
  }

  getBuyers(){
    if(this.buyers.length>0){
      return this.buyers.map(buyer=>this.BUYERS[buyer]).join(', ');
    } else {
      return this.BUYERS[this.price.observations.buyer]
    }
  }

  getPrice(price:Price){
    return price.price>0? this.priceV.getPriceWithPoints(price):'--';
  }
  
  getTypeCoin(price:Price){
    return this.priceV.getTypeCoinString(price);
  }

  getQuality(price:Price){
    let newPrice:Price=JSON.parse(JSON.stringify(price))
    return this.priceV.getQuality(newPrice);
  }

  getWayPay(price:Price){
    return this.priceV.getWayPay(price);
  }

  getPesification(pesification:any){
    return this.priceV.getPesification(pesification);
  }

  getBusinessParticularities(businessParticularities:BusinessParticularities):string[]{
    return this.priceV.getBusinessParticularities(businessParticularities);
  }

  /*getQualityParticularities(qualityParticularities:any):string[]{
    return this.priceV.getQualityParticularities(qualityParticularities);
  }*/

  getQualityIBM(price:Price){
    return this.priceV.getQualityIBM(price);
  }

  isFixExpiration(price:Price){
    return this.priceV.isFixExpiration(price);
  }

  getGrouperCode(price:Price){
    let newPrice:Price=JSON.parse(JSON.stringify(price))
    return this.priceV.getGrouperCode(newPrice);
  }
  isExport(price:Price){
    return this.priceV.isExport(price);
  }
}