<div class="body">
    <div class="header">
        <div class="sub-menu">
            <arrow-back-svg class="cross" *ngIf="arrowViewCreateExchange=='up'" (click)="changeArrowViewCreateExchange()"></arrow-back-svg>
            <span class="add-buyer title">Detalle de la contrapartida</span>
        </div>
        <cross-svg class="cross" (click)="close()" *ngIf="arrowViewCreateExchange!='up'"></cross-svg>
    </div>
    
    <div class="width-100 body-container" style="margin-top:-8px;" *ngIf="arrowViewCreateExchange!='up'">
        <p class="description state-text width-100">Estado de datos: 
            <span class="exchange-status {{getExchangeStatus(order).toLowerCase()}}">
                <span class="status-text">{{getExchangeStatus(order)}}</span>
            </span>
        </p>
        <p class="description" style="margin-top:-4px; margin-bottom:24px;">Orden creada por {{order.dataOfcreation.nameOfcreator}} a las {{order.dataOfcreation.hour}}.</p>
    
        <!------------- DATOS de la contrapartida -------------------->
        <div class="exchange-section width-100" style="margin-bottom:16px;">
            <div class="header-section header-exchange">
                <span class="header-section-text text title-section">Contrapartida <span class="stroke" *ngIf="getContractNumberExchange(order)>0">N° {{getContractNumberExchange(order)}}</span></span>
                <span class="header-section-data text buyer">Vendedor: {{getBuyerData(order)}}</span>
                <span class="header-section-data text buyer">Canjeador: {{getSellerData(order)}}</span>
            </div>
    
            <arrow-svg class="arrow" [direction]="arrowViewExchange" (click)="changeArrowViewExchange()"></arrow-svg>
        </div>
    
        <div class="exchange-information" *ngIf="arrowViewExchange == 'up'">
            <app-details-small class="details" [order]="order" [price]="order.price" [isExchange]="true"></app-details-small>
        </div>
    
    
        <!------------- DATOS DE LA ORDEN -------------------->
    
        <div class="orders-description width-100" *ngIf="!isOrderIncomplete(order)">
    
            <div class="header-section header-order">
                <span class="header-section-text text">Contrato madre asociado: <span class="stroke">N° {{getContractNumber(order)}}</span></span>
                <span class="header-section-data text seller">Vendedor: {{getSellerData(order)}}</span>
            </div>
    
            <arrow-svg class="arrow" [direction]="arrowViewOrderData" (click)="changeArrowViewOrderData()"></arrow-svg>
        </div>
    
        <div class="orders-information" *ngIf="arrowViewOrderData == 'up'">
            <app-details-small class="details" [order]="order" [price]="order.price"></app-details-small>
        </div>
    
        <!------------- ASOCIAR CONTRATO MADRE ----------------->
        <div class="asociate contract-mother button-container width-100" *ngIf="missingMotherContract(order)">
            <button class="not-filled-button-2" (click)="changeArrowViewContractMother()">
                <plus-generate-order-svg></plus-generate-order-svg>
                <span class="text" [ngClass]="{'enabled': arrowViewContractMother=='up'}">Asociar contrato madre</span>
            </button>
    
            <div class="section asociate" *ngIf="arrowViewContractMother=='up'">
                <number-input [initialPlaceHolder]="'Ingresar N° de contrato'" (valueOut)="searchContractMother($event)" [value]="numberContractMother"></number-input>
            </div>
            <span *ngIf="messageErrorContractMother">{{messageErrorContractMother}}</span>
            <div class="section asociate">
                <app-details-small class="details" [order]="orderContractMother" [price]="orderContractMother.price" *ngIf="orderContractMother"></app-details-small>
            </div>
        </div>
    
        <div class="bottom-fixed">
            <button  class="filled-button" 
            [ngClass]="{'disabled': !orderContractMother}" [disabled]="!orderContractMother" 
            (click)="asociateContractMother()" *ngIf="arrowViewContractMother=='up'">Asociar contrato madre
            </button>
        </div>
    </div>
    <!------------- COMPLETAR DATOS de la contrapartida -------------------->
    <div class="complete exchange-data button-container width-100" *ngIf="getShowCompleteDataOfExchange(order)">
        <div class="line grey" style="margin-bottom:8px"></div>
        <button class="not-filled-button-2 row-between width-100 exchange" (click)="changeArrowViewCreateExchange()">
            <div class="row-left gap-8">
                <plus-generate-order-svg></plus-generate-order-svg>
                <span class="text" [ngClass]="{'enabled': arrowViewCreateExchange=='up'}" *ngIf="isExchangeIncomplete(order.exchange)">Completar datos</span>
                <span class="text" [ngClass]="{'enabled': arrowViewCreateExchange=='up'}" *ngIf="!isExchangeIncomplete(order.exchange)">Editar datos</span>
            </div>
            <arrow-svg class="arrow" [direction]="arrowViewCreateExchange" [color]="'caribean-green'"></arrow-svg>
        </button>

        <div class="section create" *ngIf="arrowViewCreateExchange=='up'">
            <div class="exchanfe-info">
                <div class="exchange-data" *ngFor="let exchange of getExchanges(); index as i">
                    <span class="exchange-data-text" [ngClass]="{'last': i==getExchanges().length-1}" (click)="selectExchangeInArray(i)">Vendedor: {{getBuyerDataOfExchange(exchange)}}</span>
                </div>
            </div>
            
            <app-seller-name  [fieldIdentificator]="'exchange'"></app-seller-name>
            <app-how-sell [fieldIdentificator]="'exchange'"></app-how-sell>
            <app-place-of-origin [fieldIdentificator]="'exchange'"></app-place-of-origin>
            <app-tons [fieldIdentificator]="'exchange'" [maxOfTons]="order.tons"></app-tons>
            <app-agreed-discount [fieldIdentificator]="'exchange'"  [defaultValue]="agreedDiscountDefault" [showMessageNT]="isNideraOSemillas()"></app-agreed-discount>
            <app-way-pay [fieldIdentificator]="'exchange'"></app-way-pay>
            <app-bonification-exchange [fieldIdentificator]="'exchange'"></app-bonification-exchange>
            <app-price *ngIf="!orderHaveIndicators(order)" [fieldIdentificator]="'exchange'"></app-price>
            <app-type-coin *ngIf="!orderHaveIndicators(order)" [fieldIdentificator]="'exchange'"></app-type-coin>

            <div class="row-between width-100">
                <span class="missing-tons" *ngIf="showMissingTons()">
                    Quedan <span class="stroke">{{tonsAvaible}} toneladas</span> pendientes.
                </span>
                <span class="add other" (click)="addOtherExchange()" 
                *ngIf="showMissingTons()">+ Agregar otro</span>
            </div>
        </div>
    </div>

    <div class="button-void"></div>
    
    <div class="bottom-fixed">
        <button  class="filled-button" 
        (click)="editExchange()" *ngIf="arrowViewCreateExchange=='up'">Editar canje</button>
    </div>

    <!--  [disabled]="missingMotherContract(order)"  -->
    <div class="bottom-fixed" *ngIf="isVisibleConfirmButton(order) && arrowViewCreateExchange=='down'">
        <span class="msg-warning messagge" *ngIf="hasAnOldDate(order)">Recordá que, al confirmarse esta contrapartida, la fecha de concreción del negocio será la de hoy.</span>

        <button  class="filled-button"
        [ngClass]="{'disabled': missingMotherContract(order)}" 
        (click)="confirmOrderExchange()">Confirmar</button>
    </div>
    
</div>