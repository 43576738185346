import { INDICATORS_TO_FIX_CONST } from "../const/to-fix"

export const CONDITIONS_TYPE_TO_FIX={
    "-1":"--",
    0: "--",
    1:"Tradicional",
    2:"Pase de cosecha",
    3:"Porcentaje de producto",
    4:"Sin espacio",
}

export const INDICATORS_TO_FIX={
    "-1":"--",
    0: "--", //Nombres que estaban antes:
    1: "Mercado Comprador", //Mercado comprador disponible
    2: "Mercado Generalizado", //Mercado comprador generalizado
    3: "Pizarra", //Pizzarra disponible
    4: "Mercado Forward Comprador", //Forward comprador 
    5: "Mercado Forward Generalizado", //Forward generalizado
    6: "Posición MATBA/ROFEX", //Matba
    7: "Posición CME", //CBOT
    8: "Posición CME en Matba/Rofex", //Antes no existía
    9: "Posición Disponible Matba/Rofex", //Antes no existía
}

export const INDICATORS_TO_FIX_SUMMARY={
    '-1': '--',
    0: '--',
    1: 'Mkt Comprador',
    2: 'Mkt Generalizado',
    3: 'Pizarra',
    4: 'Mkt FWD Comp.',
    5: 'Mkt FWD Gral.',
    6: 'Posición MtR',
    7: 'Posición CME',
    8: 'Pos. CME en MtR',
    9: 'MtR Diponible'
}

export const INDICATORS_TRADICIONAL=[1,2,3,4,5,6,7,8,9]
export const INDICATORS_PASE_COSECHA=[1,2,3,4,5,6,9]

export const INDICATORS_PORCENTAJE_PRODUCTO=[1,2,3,4,5,6,7,8]
export const INDICATORS_SIN_ESPACIO=[4,5,6,7,8]

export const INDICATORS_WITH_QUALITY=[
    INDICATORS_TO_FIX_CONST.MERC_COMP_DISP,
    INDICATORS_TO_FIX_CONST.MERC_COMP_GEN,
    INDICATORS_TO_FIX_CONST.FORW_COMP,
    INDICATORS_TO_FIX_CONST.FORW_GEN,
]

export const INDICATORS_WITH_PLACE=[
    INDICATORS_TO_FIX_CONST.MERC_COMP_DISP,
    INDICATORS_TO_FIX_CONST.MERC_COMP_GEN,
    INDICATORS_TO_FIX_CONST.PIZ_DISP,
    INDICATORS_TO_FIX_CONST.FORW_COMP,
    INDICATORS_TO_FIX_CONST.FORW_GEN,
    INDICATORS_TO_FIX_CONST.MATBA,
    INDICATORS_TO_FIX_CONST.DISP_MATBA,
]

export const INDICATORS_WITH_POSITION=[
    INDICATORS_TO_FIX_CONST.FORW_COMP,
    INDICATORS_TO_FIX_CONST.FORW_GEN,
    INDICATORS_TO_FIX_CONST.MATBA,
    INDICATORS_TO_FIX_CONST.CME,
    INDICATORS_TO_FIX_CONST.CME_EN_MATBA
]

export const INDICATORS_WITH_PARTIAL=[
    INDICATORS_TO_FIX_CONST.FORW_COMP,
    INDICATORS_TO_FIX_CONST.FORW_GEN,
]