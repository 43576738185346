import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeInputComponent } from './date-range.component';
import { DateInputModule } from '../date/date.module';



@NgModule({
  declarations: [
    DateRangeInputComponent
  ],
  imports: [
    CommonModule,
    DateInputModule
  ], exports: [
    DateRangeInputComponent
  ]
})
export class DateRangeInputModule { }
