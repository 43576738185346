
<div class="background-sin-color">

    <svg class="svg-22 hover-bg-white" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" rx="11" fill="white"/>
        <g clip-path="url(#clip0_3498_182512)">
        <path d="M16.8214 10.9999C16.8214 8.68077 15.3334 6.58203 13.0308 5.65321C12.7316 5.53256 12.3833 5.65856 12.2527 5.93463C12.1221 6.21066 12.2586 6.53222 12.5575 6.65283C14.4298 7.40802 15.6395 9.11432 15.6395 10.9999C15.6395 12.4937 14.8785 13.8759 13.6307 14.7611L14.0321 12.8985C14.0958 12.603 13.8879 12.3158 13.5679 12.2571C13.2476 12.1983 12.9367 12.3902 12.873 12.6856L12.2147 15.7403C12.1842 15.8822 12.2159 16.0295 12.303 16.1498C12.39 16.2701 12.5253 16.3535 12.679 16.3817L15.9882 16.9893C16.0272 16.9964 16.066 16.9999 16.1042 16.9999C16.3803 16.9999 16.6271 16.8203 16.6831 16.5607C16.7467 16.2653 16.5389 15.9781 16.2188 15.9193L14.4064 15.5866C15.9082 14.4965 16.8214 12.815 16.8214 10.9999Z" fill="#D7C20D"/>
        <path d="M7.4335 9.74276C7.47242 9.74989 7.51122 9.75334 7.54943 9.75334C7.82558 9.75334 8.07238 9.57374 8.12832 9.31419L8.78654 6.2595C8.8502 5.96405 8.64236 5.6769 8.32229 5.61813L5.01313 5.01054C4.69306 4.95192 4.38197 5.14367 4.31827 5.43908C4.25461 5.73457 4.46245 6.02169 4.78252 6.08049L6.59631 6.41347C5.09468 7.50465 4.17969 9.18888 4.17969 11.0001C4.17969 13.3193 5.66761 15.418 7.97031 16.3467C8.04736 16.3778 8.12761 16.3925 8.20659 16.3925C8.43429 16.3925 8.65134 16.2703 8.74837 16.0653C8.879 15.7893 8.74254 15.4677 8.4435 15.3471C6.5713 14.592 5.36153 12.8857 5.36153 11.0001C5.36153 9.50993 6.12343 8.12544 7.37051 7.23942L6.96925 9.10139C6.90559 9.39684 7.11343 9.68396 7.4335 9.74276Z" fill="#D7C20D"/>
        </g>
        <defs>
        <clipPath id="clip0_3498_182512">
        <rect width="13" height="12" fill="white" transform="translate(4 5)"/>
        </clipPath>
        </defs>
    </svg>
    
    <div class="tooltip down row-center w-100px">
        <span>Orden modificada</span>
    </div>
</div>