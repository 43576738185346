import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyerComponent } from './buyer.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { OnlySellComponent } from './only-sell/only-sell.component';
import { NotSellComponent } from './not-sell/not-sell.component';



@NgModule({
  declarations: [
    BuyerComponent,
    OnlySellComponent,
    NotSellComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule
  ], exports:[
    BuyerComponent
  ]
})
export class BuyerOrderModule { }
