<div class="field">
    <div>
        <div class="input-date" [ngClass]="{'border-green': getIsInputCompleted(), 'is-incomplete':isIncomplete}">

            <!-- Input date-->
            <input style="height:0px; width:0px"  class="no-background-border"
            matInput [matDatepicker]="picker"           
            matSuffix [matDatepickerFilter]="myFilter" 
            [min]="getDateMin()"
            [formControl]="getValueDate()"
            (dateChange)='select($event)'
            (focus)="inputDate.focus()">

            <!-- Input text -->
            <input class="no-background-border" style="margin-top:-4px; margin-left:-4px;"
                ondrop="return false;" autocomplete="off"
                #inputDate  autocomplete="off" [value]="getValue()"
                (keypress)="checkKeyPress($event, inputDate)"
                (keyup)="setDateAux($event,inputDate)"
                (blur)="emitBlur($event,inputDate)"
                placeholder="{{initialPlaceHolder}}">

            <mat-datepicker-toggle matSuffix [for]="picker" class="logo"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </div>

    </div>
</div>