import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneratePriceComponent } from './generate-price.component';



@NgModule({
  declarations: [
    GeneratePriceComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    GeneratePriceComponent
  ]
})
export class GeneratePriceSvgModule { }
