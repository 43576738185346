import { Component, OnInit,Input,Output, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { InputE } from '../input';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

@Component({
  selector: 'select-input',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectInputComponent extends InputE implements OnInit {
  //---------- Options ----------\\
  public showOptions: boolean = false;
  public isOptionSelected: boolean = false;
  public arrowDirection: string = 'down';
  @Input() public placeHolder: string = '';
  public isClickedInConfirm:boolean=false;
  //-----------------------------\\
  @Input() public array: any[] = [];
  @Input() public OBJECT: any;
  @Input() identificatorSearch: string = '';
  //Detect clicks
  public count: number = 0;
  public identifyClassName: string = '';

  //Form with keyboard
  public isFocus: boolean = false;
  public isClickInArrow: boolean = true;
  public search: string='';
  public indexOption: number = -1;
  @Input() isSelected: boolean = false;
  @Output() valueToReturn:any={
    element:'',
    index: -1
  }

  protected pipe:any;//declarada en los fields que utilicen el pipe

  constructor(private cdr:ChangeDetectorRef) {
    super();
    this.pipe= new FilterByObjectPipe()
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.search= JSON.parse(JSON.stringify(this.value))
  }

  //---- REMEMBER VALUES ----\\
  setShowOptions(boolean: boolean) {
    this.showOptions = boolean;
  }

  setIsFocus(boolean: boolean) {
    this.isFocus = boolean;
    if (!boolean) {
      this.isClickInArrow = false;
    }
  }

  setIsOptionSelected(boolean: boolean) {
    this.isOptionSelected = boolean;
    this.setIncomplete(this.isClickedInConfirm && !this.getIsInputCompleted());
  }

  getShowOptions(): boolean {
    return this.showOptions;
  }

  getIsOptionSelected(): boolean {
    return this.isOptionSelected;
  }

  override getValue() {
    return this.value && this.value != '--'? this.value: '';
  }

  override getIsInputCompleted() {
    return this.value && this.value != '--'; 
  }

  changeShowOptions() {
    this.setChangeShowOptions(this.isFocus);
  }

  setChangeShowOptions(boolean: boolean) {
    this.setShowOptions(boolean);
    this.setIsOptionSelected( boolean || (this.value != ''));
    this.changeArrowDirection();
  }

  changeOptionSelected() {
    this.setIsOptionSelected(
      this.value != this.placeHolder
    );
  }

  @HostListener('document:keydown', ['$event'])
  listenerKeyPress(event: any, inputName:any) {
    if(this.isFocus){
      switch (event.key.toLowerCase()) {
        case 'tab':
          this.setChangeShowOptions(false);
          this.unfocusField();
          this.save();
          inputName.blur();
          break;
        case 'arrowright':
        case 'enter':
          this.save();
          this.unfocusField(); 
          inputName.blur(); 
          break;
      }
    }
  }

  changeArrowDirection() {
    this.arrowDirection= getArrowDirectionByBool(this.showOptions);
  }

  setIncomplete(boolean:boolean){
    this.isIncomplete= boolean;
  }

  //---- ELEMENT TO GENERATE ----\\
  onInputChange(event:any) {
    this.search = event.target.value;
  }

  @ViewChild('inputName', { static: false }) inputName!: ElementRef;


  setElement(element:any, index:number){
    this.setChangeShowOptions(false); //Ocultamos las options
    this.valueToReturn={
      element:element,
      index: index
    }

    this.search='';

    if (this.inputName && this.inputName.nativeElement) {
      this.inputName.nativeElement.value = this.getValue();
    }
  
    this.emit(this.valueToReturn);
  }

  focusInInput(inputName: any) {
    if (this.arrowDirection == 'down') {
      inputName.focus();
    } else {
      this.setChangeShowOptions(false);
    }
    this.isClickInArrow = true;
  }

  focusField() {
    this.setIsFocus(true);
    this.setShowOptions(true);
    //this.changeShowOptions();
  }

  unfocusField() {
    this.setIsFocus(false);
    //this.setShowOptions(false); //If we uncomment it, we cannot select with the mouse
  }

  save() {
    if(this.search.length > 0){
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT,this.identificatorSearch);

      if (newList.length > 0) {
        this.setElement(newList[0], 0);
      }

      //this.search=this.value;
      //this.setChangeShowOptions(false);
    }
  }

  /*Given two strings, it removes the first letters from the first, depending on which ones match the second*/
  removeFirstLetters(first: string, second: string): string {
    try{
      let newFirst = first.toLowerCase();
      let newSecond = second.toLowerCase();
      let i = 0;
      while (newFirst[i] == newSecond[i] && i < newFirst.length) {
        i++;
      }
      newFirst = newFirst.substring(i);
      return newFirst;
    } catch (err) {
      return '';
    }
  }

  //Dado un elemento y un array ordenado de menor a mayor, devuelve el indice del elemento en el array
  getIndexOfArray(element:any, array:any[]){
    return array.indexOf(element);
  }
}
