import { SellerData } from "../interfaces/order.interface";

export const SUBDIVISION_SYNGENTA= {
    "-1": '--',
    0: '--',
    1: 'Agro',
    2: 'Semillas',
    3: 'Nidera',
    4: 'SPS',
}

export const SYNGENTA_CODE_SELLER=4486;
export const SYNGENTA_CODE_BUYER=959;

export const SYNGENTA_NIDERA_AGREED_DISCOUNT=1.5; //DESCUENTO ACORDADO NIDERA

export const COMMISION_SYNGENTA_AVC_BUYER=0.25; //COMISION SYNGENTA

export const SYNGENTA_SELLER_DATA:SellerData= {codeS: SYNGENTA_CODE_SELLER, sucG:-1, cuit:''};

export const SUB_SYNG_CONST = {
    AGRO: 1,
    SEMILLAS: 2,
    NIDERA: 3,
    SPS: 4
}