export const saltaLocalities=[
    {"afip":"10","location":"20 De Febrero"},
    {"afip":"39","location":"Ablome"},
    {"afip":"50","location":"Abra Del Gallo"},
    {"afip":"55","location":"Abra Grande"},
    {"afip":"68","location":"Acambuco"},
    {"afip":"72","location":"Acazoque"},
    {"afip":"80","location":"Acharas"},
    {"afip":"89","location":"Acosta"},
    {"afip":"91","location":"Acoyte"},
    {"afip":"167","location":"Agua Sucia"},
    {"afip":"202","location":"Aguaray"},
    {"afip":"212","location":"Aguas Muertas"},
    {"afip":"308","location":"Alejo De Alberro"},
    {"afip":"311","location":"Alem"},
    {"afip":"312","location":"Alemania Campos De"},
    {"afip":"328","location":"Algarrobal Viejo"},
    {"afip":"365","location":"Almona"},
    {"afip":"409","location":"Alto De La Sierra"},
    {"afip":"430","location":"Alto Del Mistol"},
    {"afip":"468","location":"Altos Hornos Guemes"},
    {"afip":"481","location":"Amaicha"},
    {"afip":"497","location":"Amberes"},
    {"afip":"499","location":"Amblayo"},
    {"afip":"516","location":"Ampascachi"},
    {"afip":"570","location":"Angastaco"},
    {"afip":"572","location":"Angel Peredo"},
    {"afip":"573","location":"Angelica"},
    {"afip":"577","location":"Angosto Pescado"},
    {"afip":"587","location":"Animana"},
    {"afip":"604","location":"Antilla"},
    {"afip":"609","location":"Antonio Alice"},
    {"afip":"614","location":"Antonio Quijarro"},
    {"afip":"633","location":"Apolinario Saravia"},
    {"afip":"669","location":"Arenal"},
    {"afip":"688","location":"Arita"},
    {"afip":"690","location":"Arjuntas"},
    {"afip":"707","location":"Arrocero Italiano"},
    {"afip":"907","location":"Astillero"},
    {"afip":"918","location":"Atocha"},
    {"afip":"924","location":"Atudillo"},
    {"afip":"961","location":"Azul Cuesta"},
    {"afip":"975","location":"Bacoya"},
    {"afip":"994","location":"Bajada"},
    {"afip":"997","location":"Bajada Blanca"},
    {"afip":"999","location":"Bajada De Gavi"},
    {"afip":"1041","location":"Bajo Grande Desvio Fcgb"},
    {"afip":"1054","location":"Balboa"},
    {"afip":"1111","location":"Balderrama"},
    {"afip":"1119","location":"Ballenal"},
    {"afip":"1160","location":"Banda Grande"},
    {"afip":"1171","location":"Barbayasco"},
    {"afip":"1182","location":"Baritu"},
    {"afip":"1206","location":"Barrealito"},
    {"afip":"1271","location":"Barrio La Loma"},
    {"afip":"1288","location":"Barrio Parabolica"},
    {"afip":"1304","location":"Barrio San Antonio"},
    {"afip":"1429","location":"Belgrano Fortin 2"},
    {"afip":"1475","location":"Betania"},
    {"afip":"1519","location":"Bodeguita"},
    {"afip":"1573","location":"Brealito"},
    {"afip":"1591","location":"Buena Fe"},
    {"afip":"1608","location":"Burro Yaco"},
    {"afip":"1641","location":"Cabeza De Anta"},
    {"afip":"1662","location":"Cabra Corral Dique"},
    {"afip":"1673","location":"Cachi Adentro"},
    {"afip":"1686","location":"Cachiñal"}
    ,
    {"afip":"1689","location":"Cacho Molino"},
    {"afip":"1699","location":"Cafayate"},
    {"afip":"1709","location":"Caipe"},
    {"afip":"1736","location":"Caldera"},
    {"afip":"1737","location":"Calderilla"},
    {"afip":"1775","location":"Calvimonte"},
    {"afip":"1779","location":"Camara"},
    {"afip":"1799","location":"Caminera San Pedrito"},
    {"afip":"1819","location":"Campamento Tablilla"},
    {"afip":"1820","location":"Campamento Vespucio"},
    {"afip":"1828","location":"Campichuelo"},
    {"afip":"1840","location":"Campo Argentino"},
    {"afip":"1843","location":"Campo Azul"},
    {"afip":"1862","location":"Campo Caseros"},
    {"afip":"1900","location":"Campo Duran"},
    {"afip":"1961","location":"Campo Libre"},
    {"afip":"1986","location":"Campo Quijano"},
    {"afip":"2011","location":"Campo Santo"},
    {"afip":"2054","location":"Cancillar"},
    {"afip":"2073","location":"Cantera Del Sauce"},
    {"afip":"2101","location":"Capiazutti"},
    {"afip":"2118","location":"Capilla Fuerte"},
    {"afip":"2136","location":"Capitan Juan Page"},
    {"afip":"2164","location":"Carapari"},
    {"afip":"2215","location":"Carreras"},
    {"afip":"2307","location":"Castañares"}
    ,
    {"afip":"2381","location":"Cañada De La Junta"}
    ,
    {"afip":"2453","location":"Cañadon De Las Juntas"}
    ,
    {"afip":"2478","location":"Cebados"},
    {"afip":"2483","location":"Ceibalito"},
    {"afip":"2535","location":"Cerro Alemania"},
    {"afip":"2589","location":"Cerro De La Zorra Vieja"},
    {"afip":"2602","location":"Cerro Del Agua De La Falda"},
    {"afip":"2676","location":"Cerro Mal Canto"},
    {"afip":"2769","location":"Chacar"},
    {"afip":"2781","location":"Chachapoyas"},
    {"afip":"2784","location":"Chachas"},
    {"afip":"2788","location":"Chacra Experimental"},
    {"afip":"2814","location":"Chaguaral"},
    {"afip":"2879","location":"Chañar Aguada"}
    ,
    {"afip":"2888","location":"Chañar Muyo"}
    ,
    {"afip":"2931","location":"Chicoana"},
    {"afip":"2940","location":"Chilcas"},
    {"afip":"2975","location":"Chirete"},
    {"afip":"2982","location":"Chivilme"},
    {"afip":"2983","location":"Chiyayoc"},
    {"afip":"2995","location":"Chorrillitos"},
    {"afip":"2999","location":"Chorro Blanco"},
    {"afip":"3000","location":"Chorroarin"},
    {"afip":"3012","location":"Chuculaqui"},
    {"afip":"3045","location":"Ciervo Cansado"},
    {"afip":"3092","location":"Coba"},
    {"afip":"3093","location":"Cobas"},
    {"afip":"3095","location":"Cobos"},
    {"afip":"3122","location":"Colanzuli"},
    {"afip":"3129","location":"Colgadas"},
    {"afip":"3146","location":"Colmenar"},
    {"afip":"3150","location":"Colome"},
    {"afip":"3169","location":"Colonia A"},
    {"afip":"3179","location":"Colonia Agricola San Agustin"},
    {"afip":"3271","location":"Colonia Buenaventura"},
    {"afip":"3331","location":"Colonia D"},
    {"afip":"3383","location":"Colonia El Fuerte"},
    {"afip":"3497","location":"Colonia Hurlingham"},
    {"afip":"3759","location":"Colonia Otomana"},
    {"afip":"3885","location":"Colonia Santa Rosa De Lima"},
    {"afip":"3968","location":"Colonia Zanja Del Tigre"},
    {"afip":"3975","location":"Colorado"},
    {"afip":"3978","location":"Colte"},
    {"afip":"4011","location":"Conchas"},
    {"afip":"4015","location":"Condado"},
    {"afip":"4048","location":"Copo Quile"},
    {"afip":"4084","location":"Coronel Cornejo"},
    {"afip":"4102","location":"Coronel Juan Sola"},
    {"afip":"4109","location":"Coronel Mollinedo"},
    {"afip":"4111","location":"Coronel Olleros"},
    {"afip":"4121","location":"Coronel Vidt"},
    {"afip":"4122","location":"Coropampa"},
    {"afip":"4155","location":"Corrida De Cori"},
    {"afip":"4167","location":"Cosme"},
    {"afip":"4278","location":"Cruz Quemada"},
    {"afip":"4315","location":"Cuchiyaco"},
    {"afip":"4319","location":"Cuesta Azul"},
    {"afip":"4321","location":"Cuesta Chica"},
    {"afip":"4325","location":"Cuesta Del Obispo"},
    {"afip":"4379","location":"Damian N Torino"},
    {"afip":"4436","location":"Diego De Almagro"},
    {"afip":"4452","location":"Dique Embalse Campo Alegre"},
    {"afip":"4456","location":"Dique Itiruyo"},
    {"afip":"4491","location":"Doctor Facundo Zuviria"},
    {"afip":"4563","location":"Dos Yuchanes"},
    {"afip":"4572","location":"Dragones"},
    {"afip":"4584","location":"Duraznito"},
    {"afip":"4594","location":"Ebro"},
    {"afip":"4620","location":"El Aguay"},
    {"afip":"4634","location":"El Alfredito"},
    {"afip":"4639","location":"El Alisal"},
    {"afip":"4711","location":"El Borde De San Miguel"},
    {"afip":"4714","location":"El Bordo Campo Santo"},
    {"afip":"4721","location":"El Breal"},
    {"afip":"4722","location":"El Brealito"},
    {"afip":"4750","location":"El Candado"},
    {"afip":"4774","location":"El Carril"},
    {"afip":"4851","location":"El Churcal"},
    {"afip":"4869","location":"El Colgado"},
    {"afip":"4882","location":"El Corral Viejo"},
    {"afip":"4891","location":"El Creston"},
    {"afip":"4904","location":"El Cuchillo"},
    {"afip":"4907","location":"El Cuibal"},
    {"afip":"4908","location":"El Cuinco Pozo Del"},
    {"afip":"4944","location":"El Encon"},
    {"afip":"4977","location":"El Gallinato"},
    {"afip":"4980","location":"El Galpon Est Foguista J F"},
    {"afip":"4987","location":"El Golgota"},
    {"afip":"5012","location":"El Huaico"},
    {"afip":"5025","location":"El Jaravi"},
    {"afip":"5026","location":"El Jardin"},
    {"afip":"5048","location":"El Leoncito"},
    {"afip":"5049","location":"El Libano"},
    {"afip":"5069","location":"El Maray"},
    {"afip":"5105","location":"El Moranillo"},
    {"afip":"5110","location":"El Moyar"},
    {"afip":"5127","location":"El Nogalar"},
    {"afip":"5134","location":"El Obelisco"},
    {"afip":"5139","location":"El Ojito"},
    {"afip":"5140","location":"El Ojo"},
    {"afip":"5151","location":"El Oso"},
    {"afip":"5154","location":"El Pacara"},
    {"afip":"5212","location":"El Pericote"},
    {"afip":"5270","location":"El Potrero De Diaz"},
    {"afip":"5290","location":"El Puyil"},
    {"afip":"5300","location":"El Quimilar Carboncito"},
    {"afip":"5328","location":"El Rey"},
    {"afip":"5340","location":"El Rosal"},
    {"afip":"5355","location":"El Sapo"},
    {"afip":"5380","location":"El Soldadito"},
    {"afip":"5392","location":"El Tabacal"},
    {"afip":"5398","location":"El Tala Est R De Los Llanos"},
    {"afip":"5407","location":"El Tandil"},
    {"afip":"5409","location":"El Tapial"},
    {"afip":"5420","location":"El Timbo"},
    {"afip":"5451","location":"El Trigal"},
    {"afip":"5458","location":"El Tunal"},
    {"afip":"5459","location":"El Tunalito"},
    {"afip":"5484","location":"El Vencido"},
    {"afip":"5503","location":"El Yacon"},
    {"afip":"5505","location":"El Yeso"},
    {"afip":"5513","location":"El Zapallo"},
    {"afip":"5524","location":"El ñato"}
    ,
    {"afip":"5533","location":"Eltunal"},
    {"afip":"5541","location":"Embarcacion"},
    {"afip":"5543","location":"Emboscada"},
    {"afip":"5577","location":"Encrucijada De Tastil"},
    {"afip":"5607","location":"Escalchi"},
    {"afip":"5611","location":"Escoipe"},
    {"afip":"5689","location":"Estacion Cachiñal"}
    ,
    {"afip":"5738","location":"Estacion Zuviria"},
    {"afip":"5996","location":"Esteban De Urizar"},
    {"afip":"6000","location":"Esteco Embarcadero Fcgb"},
    {"afip":"6114","location":"Finca Armonia"},
    {"afip":"6115","location":"Finca Belgrano"},
    {"afip":"6116","location":"Finca Camino A Colon"},
    {"afip":"6117","location":"Finca Camino Vallisios"},
    {"afip":"6118","location":"Finca Colon"},
    {"afip":"6123","location":"Finca El Carmen"},
    {"afip":"6125","location":"Finca El Colegio"},
    {"afip":"6130","location":"Finca La China"},
    {"afip":"6133","location":"Finca La Rosa"},
    {"afip":"6134","location":"Finca La Toma"},
    {"afip":"6141","location":"Finca Mision Zenta"},
    {"afip":"6145","location":"Finca Rocca"},
    {"afip":"6148","location":"Finca Santiago"},
    {"afip":"6178","location":"Foguista J F Juarez"},
    {"afip":"6191","location":"Fortin Belgrano"},
    {"afip":"6203","location":"Fortin Frias"},
    {"afip":"6270","location":"Frontera 4"},
    {"afip":"6271","location":"Frontera 5"},
    {"afip":"6273","location":"Frontera Tres"},
    {"afip":"6279","location":"Fuerte Alto"},
    {"afip":"6282","location":"Fuerte El Pito"},
    {"afip":"6303","location":"Gallinato"},
    {"afip":"6314","location":"Gaona"},
    {"afip":"6359","location":"General Ballivian"},
    {"afip":"6369","location":"General Enrique Mosconi"},
    {"afip":"6375","location":"General Guemes"},
    {"afip":"6405","location":"General Pizarro"},
    {"afip":"6466","location":"Gobernador Manuel Sola"},
    {"afip":"6476","location":"Gobernador Saravia"},
    {"afip":"6534","location":"Grandes Pastos"},
    {"afip":"6554","location":"Guachipas"},
    {"afip":"6568","location":"Gualfin"},
    {"afip":"6569","location":"Gualiana"},
    {"afip":"6574","location":"Guamachi"},
    {"afip":"6703","location":"Hickman"},
    {"afip":"6707","location":"Higueras"},
    {"afip":"6726","location":"Hito 1"},
    {"afip":"6736","location":"Hombre Muerto Salar De"},
    {"afip":"6739","location":"Horcones"},
    {"afip":"6755","location":"Hosteria Juramento"},
    {"afip":"6774","location":"Huaicondo"},
    {"afip":"6810","location":"Huayra Huasy"},
    {"afip":"6821","location":"Huerta"},
    {"afip":"6830","location":"Huguerillas"},
    {"afip":"6840","location":"Humaita"},
    {"afip":"6843","location":"Humanas"},
    {"afip":"6895","location":"Incachuli"},
    {"afip":"6896","location":"Incahuasi"},
    {"afip":"6897","location":"Incamayo"},
    {"afip":"6939","location":"Ingeniero Maury"},
    {"afip":"6965","location":"Ingenio San Isidro"},
    {"afip":"6968","location":"Ingenio San Martin"},
    {"afip":"7001","location":"Iruya"},
    {"afip":"7023","location":"Isla De Cañas"}
    ,
    {"afip":"7024","location":"Isla De La Candelaria"},
    {"afip":"7076","location":"Isonza"},
    {"afip":"7095","location":"Ituyuro"},
    {"afip":"7105","location":"Jacimana"},
    {"afip":"7145","location":"Jeronimo Matorras"},
    {"afip":"7248","location":"Juncal"},
    {"afip":"7250","location":"Juncalito"},
    {"afip":"7259","location":"Juntas De San Antonio"},
    {"afip":"7262","location":"Juramento"},
    {"afip":"7329","location":"La Arcadia"},
    {"afip":"7334","location":"La Asuncion"},
    {"afip":"7377","location":"La Bodega"},
    {"afip":"7378","location":"La Bodeguita"},
    {"afip":"7401","location":"La Calavera"},
    {"afip":"7431","location":"La Carretera"},
    {"afip":"7440","location":"La Casualidad Mina"},
    {"afip":"7546","location":"La Cuestita Anta"},
    {"afip":"7547","location":"La Cuestita Metan"},
    {"afip":"7553","location":"La Defensa"},
    {"afip":"7615","location":"La Esquinita"},
    {"afip":"7623","location":"La Estela"},
    {"afip":"7638","location":"La Fama"},
    {"afip":"7647","location":"La Firmeza"},
    {"afip":"7810","location":"La Manga"},
    {"afip":"7830","location":"La Maroma"},
    {"afip":"7859","location":"La Montaña"}
    ,
    {"afip":"7887","location":"La Oliva"},
    {"afip":"7903","location":"La Pajita"},
    {"afip":"7905","location":"La Palata"},
    {"afip":"7933","location":"La Paya"},
    {"afip":"7936","location":"La Pedrera"},
    {"afip":"7979","location":"La Poma"},
    {"afip":"7981","location":"La Porcelana"},
    {"afip":"8009","location":"La Punilla"},
    {"afip":"8017","location":"La Quena"},
    {"afip":"8019","location":"La Quesera"},
    {"afip":"8102","location":"La Silleta"},
    {"afip":"8149","location":"La Troja"},
    {"afip":"8320","location":"Lagunita Nueva Poblacion"},
    {"afip":"8361","location":"Las Acheras"},
    {"afip":"8373","location":"Las Arcas"},
    {"afip":"8374","location":"Las Arenas"},
    {"afip":"8396","location":"Las Bolsas"},
    {"afip":"8428","location":"Las Cebadas"},
    {"afip":"8452","location":"Las Conchas"},
    {"afip":"8455","location":"Las Cortderas"},
    {"afip":"8456","location":"Las Costas"},
    {"afip":"8467","location":"Las Cuestitas"},
    {"afip":"8469","location":"Las Curtiembres"},
    {"afip":"8486","location":"Las Flacas"},
    {"afip":"8487","location":"Las Flechas"},
    {"afip":"8508","location":"Las Hecheras"},
    {"afip":"8526","location":"Las Juntas De Alemania"},
    {"afip":"8535","location":"Las Lechuzas"},
    {"afip":"8540","location":"Las Llaves"},
    {"afip":"8560","location":"Las Mesadas"},
    {"afip":"8562","location":"Las Mesitas"},
    {"afip":"8567","location":"Las Mojarritas"},
    {"afip":"8583","location":"Las Pailas"},
    {"afip":"8664","location":"Las Tienditas"},
    {"afip":"8691","location":"Las Tunillas"},
    {"afip":"8698","location":"Las Vateas"},
    {"afip":"8701","location":"Las Ventanas"},
    {"afip":"8704","location":"Las Vertientes Santa Rita De"},
    {"afip":"8711","location":"Las Viñas"}
    ,
    {"afip":"8744","location":"Lechiguana"},
    {"afip":"8768","location":"Lesser"},
    {"afip":"8794","location":"Limache"},
    {"afip":"8799","location":"Limoncito"},
    {"afip":"8819","location":"Lipeo"},
    {"afip":"8826","location":"Lizoite"},
    {"afip":"8835","location":"Llucha"},
    {"afip":"8858","location":"Loma De Burro"},
    {"afip":"8899","location":"Lomas De Olmedo"},
    {"afip":"8998","location":"Los Bañados"}
    ,
    {"afip":"8999","location":"Los Baños"}
    ,
    {"afip":"9019","location":"Los Canteros"},
    {"afip":"9053","location":"Los Chifles"},
    {"afip":"9058","location":"Los Churquis"},
    {"afip":"9180","location":"Los Los"},
    {"afip":"9197","location":"Los Mercados"},
    {"afip":"9210","location":"Los Mollinedos"},
    {"afip":"9225","location":"Los Noques"},
    {"afip":"9263","location":"Los Peñones"}
    ,
    {"afip":"9299","location":"Los Ranchillos"},
    {"afip":"9318","location":"Los Rosales"},
    {"afip":"9428","location":"Luis Burela"},
    {"afip":"9443","location":"Lumbreras"},
    {"afip":"9446","location":"Luna Muerta"},
    {"afip":"9450","location":"Luracatao"},
    {"afip":"9465","location":"Macapillo"},
    {"afip":"9474","location":"Macho Rastrojo"},
    {"afip":"9484","location":"Macueta"},
    {"afip":"9488","location":"Madre Vieja"},
    {"afip":"9489","location":"Madrejon"},
    {"afip":"9516","location":"Mal Cante"},
    {"afip":"9586","location":"Manga Vieja"},
    {"afip":"9599","location":"Manuel Elordi"},
    {"afip":"9604","location":"Manuela Pedraza"},
    {"afip":"9667","location":"Maria Jose"},
    {"afip":"9707","location":"Martinez Del Tinco"},
    {"afip":"9708","location":"Martinez Del Tineo"},
    {"afip":"9729","location":"Matansillas"},
    {"afip":"9758","location":"Mayo Torito"},
    {"afip":"9780","location":"Mecoyita"},
    {"afip":"9820","location":"Merced De Arriba"},
    {"afip":"9834","location":"Meseta"},
    {"afip":"9839","location":"Meson"},
    {"afip":"9841","location":"Metan"},
    {"afip":"9842","location":"Metan Viejo"},
    {"afip":"9873","location":"Mina Carolina"},
    {"afip":"9879","location":"Mina Don Otto"},
    {"afip":"9888","location":"Mina Julio"},
    {"afip":"9901","location":"Mina San Esteban"},
    {"afip":"9903","location":"Mina San Guillermo"},
    {"afip":"9905","location":"Mina San Walterio"},
    {"afip":"9909","location":"Mina Tincalaya"},
    {"afip":"9920","location":"Minas Victoria"},
    {"afip":"9921","location":"Minas Ypf"},
    {"afip":"9939","location":"Miraflores M"},
    {"afip":"9949","location":"Mision Chaqueña"}
    ,
    {"afip":"9952","location":"Mision Franciscana"},
    {"afip":"9953","location":"Mision La Paz"},
    {"afip":"9956","location":"Mision Santa Lucia"},
    {"afip":"9958","location":"Misiones"},
    {"afip":"9964","location":"Mistol Mareado"},
    {"afip":"9968","location":"Mistolar"},
    {"afip":"9994","location":"Mojotoro"},
    {"afip":"9998","location":"Molino De Gongora"},
    {"afip":"10004","location":"Mollar"},
    {"afip":"10009","location":"Molle Pozo"},
    {"afip":"10017","location":"Mollinedo"},
    {"afip":"10022","location":"Monasterios"},
    {"afip":"10031","location":"Monte"},
    {"afip":"10038","location":"Monte Carmelo"},
    {"afip":"10078","location":"Monte Viejo"},
    {"afip":"10096","location":"Morales"},
    {"afip":"10104","location":"Morenillo"},
    {"afip":"10108","location":"Morillo"},
    {"afip":"10268","location":"Nuestra Señora De Talavera"}
    ,
    {"afip":"10354","location":"Olacapanto Chico"},
    {"afip":"10355","location":"Olacapanto Grande"},
    {"afip":"10390","location":"Oran"},
    {"afip":"10410","location":"Osma"},
    {"afip":"10415","location":"Otomana"},
    {"afip":"10420","location":"Ovando"},
    {"afip":"10424","location":"Ovejero"},
    {"afip":"10432","location":"P Salvador Mazza Est Pocito"},
    {"afip":"10479","location":"Pal Tolco"},
    {"afip":"10489","location":"Palermo"},
    {"afip":"10492","location":"Palermo Oeste"},
    {"afip":"10528","location":"Palo Pintado"},
    {"afip":"10534","location":"Palomar"},
    {"afip":"10537","location":"Palomitas"},
    {"afip":"10685","location":"Paraje Campo Largo"},
    {"afip":"10686","location":"Paraje Cerro Negro"},
    {"afip":"10687","location":"Paraje Cobres"},
    {"afip":"10688","location":"Paraje Cortaderas"},
    {"afip":"10694","location":"Paraje Esquina De Guardia"},
    {"afip":"10706","location":"Paraje Las Cuevas"},
    {"afip":"10712","location":"Paraje Mina Concordia"},
    {"afip":"10714","location":"Paraje Morro Colorado"},
    {"afip":"10715","location":"Paraje Nacimientos"},
    {"afip":"10718","location":"Paraje Olacapato"},
    {"afip":"10720","location":"Paraje Pastos Grandes"},
    {"afip":"10721","location":"Paraje Pircas"},
    {"afip":"10722","location":"Paraje Pizcuno"},
    {"afip":"10730","location":"Paraje Uncuru"},
    {"afip":"10733","location":"Paraje Zanjon"},
    {"afip":"10735","location":"Paramamaya"},
    {"afip":"10743","location":"Parani"},
    {"afip":"10746","location":"Paredes"},
    {"afip":"10765","location":"Pascalla"},
    {"afip":"10767","location":"Pascha"},
    {"afip":"10793","location":"Paso De Baderrana"},
    {"afip":"10799","location":"Paso De La Cruz"},
    {"afip":"10848","location":"Paso Del Rio"},
    {"afip":"10854","location":"Paso El Milagro San Aniceto"},
    {"afip":"10909","location":"Paso Verde"},
    {"afip":"10914","location":"Pasteadero"},
    {"afip":"10919","location":"Pastor Sevillosa"},
    {"afip":"10950","location":"Payogasta"},
    {"afip":"10951","location":"Payogastilla"},
    {"afip":"10970","location":"Pedro Lozano"},
    {"afip":"11028","location":"Peñalva"}
    ,
    {"afip":"11029","location":"Peñas Azules"}
    ,
    {"afip":"11057","location":"Pichanal"},
    {"afip":"11083","location":"Piedemonte"},
    {"afip":"11094","location":"Piedra Del Molino"},
    {"afip":"11095","location":"Piedra Del Potrillo"},
    {"afip":"11115","location":"Piedras Moradas"},
    {"afip":"11142","location":"Pinal"},
    {"afip":"11163","location":"Piquete Cabado"},
    {"afip":"11164","location":"Piquete De Anta"},
    {"afip":"11167","location":"Piquirenda"},
    {"afip":"11190","location":"Piul"},
    {"afip":"11192","location":"Pizarro"},
    {"afip":"11232","location":"Pluma Del Pato"},
    {"afip":"11263","location":"Poblacion"},
    {"afip":"11264","location":"Poblacion De Ortega"},
    {"afip":"11272","location":"Pocoy"},
    {"afip":"11285","location":"Pompeya"},
    {"afip":"11291","location":"Porongal"},
    {"afip":"11342","location":"Poscaya"},
    {"afip":"11343","location":"Poso De Algarrobo"},
    {"afip":"11366","location":"Potrero De Castilla"},
    {"afip":"11367","location":"Potrero De Diaz"},
    {"afip":"11373","location":"Potrero De Linares"},
    {"afip":"11379","location":"Potrero De Poyogasta"},
    {"afip":"11381","location":"Potrero De Uriburu"},
    {"afip":"11393","location":"Pozo Azul"},
    {"afip":"11394","location":"Pozo Bermejo"},
    {"afip":"11400","location":"Pozo Cantado"},
    {"afip":"11403","location":"Pozo Cercado"},
    {"afip":"11451","location":"Pozo Del Cuico"},
    {"afip":"11455","location":"Pozo Del Greal"},
    {"afip":"11468","location":"Pozo Del Pato"},
    {"afip":"11469","location":"Pozo Del Sauce"},
    {"afip":"11475","location":"Pozo Del Zorro"},
    {"afip":"11477","location":"Pozo El Algarrobo"},
    {"afip":"11504","location":"Pozo Pringles"},
    {"afip":"11516","location":"Pozos Largos"},
    {"afip":"11541","location":"Pringles"},
    {"afip":"11676","location":"Puente De Diaz"},
    {"afip":"11680","location":"Puente De Plata"},
    {"afip":"11712","location":"Puerta Blanca"},
    {"afip":"11720","location":"Puerta De La Paya"},
    {"afip":"11729","location":"Puerta De Tastil"},
    {"afip":"11759","location":"Puerto Baules"},
    {"afip":"11779","location":"Puerto De Diaz"},
    {"afip":"11809","location":"Puerto La Paz"},
    {"afip":"11957","location":"Puesto De Motijo"},
    {"afip":"11973","location":"Puesto Del Pañuelo"}
    ,
    {"afip":"12095","location":"Puil"},
    {"afip":"12101","location":"Pulares"},
    {"afip":"12102","location":"Puli"},
    {"afip":"12110","location":"Punca Viscana"},
    {"afip":"12112","location":"Punco Viscana"},
    {"afip":"12188","location":"Quebrada De Escoipe"},
    {"afip":"12197","location":"Quebrada Del Agua"},
    {"afip":"12200","location":"Quebrada Del Toro"},
    {"afip":"12205","location":"Quebrada Muñano"}
    ,
    {"afip":"12219","location":"Quesera"},
    {"afip":"12224","location":"Queñua"}
    ,
    {"afip":"12267","location":"Quisca Grande"},
    {"afip":"12268","location":"Quisca Loro"},
    {"afip":"12272","location":"Quisto"},
    {"afip":"12384","location":"Recaredo"},
    {"afip":"12393","location":"Redondo"},
    {"afip":"12551","location":"Rio Alemania"},
    {"afip":"12553","location":"Rio Ancho"},
    {"afip":"12562","location":"Rio Carapan"},
    {"afip":"12580","location":"Rio De Las Piedras"},
    {"afip":"12588","location":"Rio Del Valle"},
    {"afip":"12598","location":"Rio Lavallen"},
    {"afip":"12611","location":"Rio Pescado"},
    {"afip":"12628","location":"Rio Toro"},
    {"afip":"12630","location":"Rio Urueña"}
    ,
    {"afip":"12646","location":"Roca"},
    {"afip":"12656","location":"Rodeo Colorado"},
    {"afip":"12667","location":"Rodeo Pampa"},
    {"afip":"12671","location":"Rodeos"},
    {"afip":"12701","location":"Rosario De La Frontera"},
    {"afip":"12702","location":"Rosario De Lerma"},
    {"afip":"12703","location":"Rosario Del Dorado"},
    {"afip":"12706","location":"Rosario Funca"},
    {"afip":"12729","location":"Ruiz De Los Llanos"},
    {"afip":"12745","location":"Rumihuasi"},
    {"afip":"12748","location":"Rumiuarco"},
    {"afip":"12785","location":"S Jollin"},
    {"afip":"12791","location":"Sacha Pera"},
    {"afip":"12815","location":"Saladillo De Juarez"},
    {"afip":"12816","location":"Saladillo De Osma"},
    {"afip":"12824","location":"Salar De Hombre Muerto"},
    {"afip":"12825","location":"Salar De Pocitos"},
    {"afip":"12857","location":"Salta"},
    {"afip":"12885","location":"San Antonio Chicoana"},
    {"afip":"12890","location":"San Antonio De Iruya"},
    {"afip":"12895","location":"San Antonio De Los Cobres"},
    {"afip":"12905","location":"San Antonio La Viña"}
    ,
    {"afip":"12960","location":"San Fernando De Escoipe"},
    {"afip":"12989","location":"San Ignacio De Loyola"},
    {"afip":"12992","location":"San Isidro De Iruya"},
    {"afip":"13011","location":"San Jose De Colte"},
    {"afip":"13012","location":"San Jose De Escalchi"},
    {"afip":"13021","location":"San Jose De La Viña"}
    ,
    {"afip":"13029","location":"San Jose De Orqueras"},
    {"afip":"13049","location":"San Juan De Oro"},
    {"afip":"13062","location":"San Laurencio"},
    {"afip":"13086","location":"San Martin La Cuesta"},
    {"afip":"13118","location":"San Pedro De Aranda"},
    {"afip":"13122","location":"San Pedro De Los Corrales"},
    {"afip":"13123","location":"San Pedro De San Alberto"},
    {"afip":"13137","location":"San Ramon De La Nueva Oran"},
    {"afip":"13240","location":"Santa Marina"},
    {"afip":"13254","location":"Santa Rosa De Anta"},
    {"afip":"13260","location":"Santa Rosa De Los Patos Grande"},
    {"afip":"13263","location":"Santa Rosa De Tastil"},
    {"afip":"13269","location":"Santa Rufina"},
    {"afip":"13284","location":"Santa Victoria Este"},
    {"afip":"13297","location":"Santo Domingo Anta"},
    {"afip":"13302","location":"Santo Domingo Santa Victoria"},
    {"afip":"13313","location":"Sapo Quemado"},
    {"afip":"13321","location":"Sargento Cristobal"},
    {"afip":"13333","location":"Sauce Alegre"},
    {"afip":"13335","location":"Sauce Bajada"},
    {"afip":"13353","location":"Sauce Redondo"},
    {"afip":"13356","location":"Sauce Solo"},
    {"afip":"13361","location":"Saucelito"},
    {"afip":"13368","location":"Sausalito"},
    {"afip":"13380","location":"Schneidewind"},
    {"afip":"13387","location":"Seclanta Adentro"},
    {"afip":"13388","location":"Seclantas"},
    {"afip":"13407","location":"Senda Hachada Estacion Fcgb"},
    {"afip":"13425","location":"Sevillar"},
    {"afip":"13482","location":"Simbol Yaco"},
    {"afip":"13484","location":"Simbolito"},
    {"afip":"13497","location":"Siñunto"}
    ,
    {"afip":"13500","location":"Socompa"},
    {"afip":"13517","location":"Solazuti"},
    {"afip":"13531","location":"Soledani"},
    {"afip":"13583","location":"Sunchalito"},
    {"afip":"13596","location":"Suri Micuna"},
    {"afip":"13597","location":"Suri Pintado"},
    {"afip":"13610","location":"Tabacal Ingenio"},
    {"afip":"13611","location":"Tabaco Cimarron"},
    {"afip":"13627","location":"Taca Taca Estacion Fcgb"},
    {"afip":"13646","location":"Taco Pampa"},
    {"afip":"13671","location":"Tacuil"},
    {"afip":"13694","location":"Tala Muyo"},
    {"afip":"13702","location":"Tala Yaco"},
    {"afip":"13712","location":"Talapampa"},
    {"afip":"13715","location":"Talas"},
    {"afip":"13717","location":"Talavera"},
    {"afip":"13731","location":"Tamas Cortadas"},
    {"afip":"13856","location":"Tilian"},
    {"afip":"13897","location":"Tipa Sola"},
    {"afip":"13899","location":"Tipayoc"},
    {"afip":"13908","location":"Titicoite"},
    {"afip":"13912","location":"Tobantirenda"},
    {"afip":"13922","location":"Tolar Chico"},
    {"afip":"13923","location":"Tolar Grande"},
    {"afip":"13929","location":"Tolloche"},
    {"afip":"13930","location":"Tolombon"},
    {"afip":"13942","location":"Tomuco"},
    {"afip":"13943","location":"Tonco"},
    {"afip":"13944","location":"Tonono"},
    {"afip":"13965","location":"Toro Pampa"},
    {"afip":"13970","location":"Toro Yaco"},
    {"afip":"13975","location":"Toroyoc"},
    {"afip":"13982","location":"Torzalito"},
    {"afip":"14011","location":"Tranquitas"},
    {"afip":"14089","location":"Tres Yuchanes"},
    {"afip":"14094","location":"Trigal"},
    {"afip":"14096","location":"Trigo Huayco"},
    {"afip":"14123","location":"Trusuca"},
    {"afip":"14134","location":"Tuctuca"},
    {"afip":"14176","location":"Tuyunti"},
    {"afip":"14182","location":"Uchogol"},
    {"afip":"14183","location":"Uchuyoc"},
    {"afip":"14197","location":"Unquillal Embarcadero Fcgb"},
    {"afip":"14239","location":"Vado Hondo"},
    {"afip":"14262","location":"Valle Delgado"},
    {"afip":"14264","location":"Valle Encantado"},
    {"afip":"14280","location":"Vaqueria"},
    {"afip":"14281","location":"Vaqueria Los Sauces"},
    {"afip":"14282","location":"Vaqueros"},
    {"afip":"14295","location":"Vega De Arizaro"},
    {"afip":"14305","location":"Velardes"},
    {"afip":"14313","location":"Vencida"},
    {"afip":"14321","location":"Vera Cruz"},
    {"afip":"14332","location":"Vespucio"},
    {"afip":"14335","location":"Viaducto El Muñal"}
    ,
    {"afip":"14359","location":"Vieja Pozo"},
    {"afip":"14387","location":"Villa Alem"},
    {"afip":"14411","location":"Villa Aurelia"},
    {"afip":"14480","location":"Villa Corta"},
    {"afip":"14549","location":"Villa Fanny"},
    {"afip":"14659","location":"Villa Los Tarcos"},
    {"afip":"14687","location":"Villa Mayor Zabaleta"},
    {"afip":"14737","location":"Villa Petrona"},
    {"afip":"14785","location":"Villa Saavedra"},
    {"afip":"14798","location":"Villa San Lorenzo"},
    {"afip":"14832","location":"Villa Sola"},
    {"afip":"14890","location":"Villitas"},
    {"afip":"14894","location":"Vinal Pozo"},
    {"afip":"14905","location":"Virgilio Tedin"},
    {"afip":"14908","location":"Virrey Toledo"},
    {"afip":"14930","location":"Vizcacheral"},
    {"afip":"14933","location":"Vizcarra"},
    {"afip":"14936","location":"Viñaco"}
    ,
    {"afip":"14937","location":"Viñal Pozo"}
    ,
    {"afip":"14939","location":"Volcan Azufre"},
    {"afip":"14942","location":"Volcan Higueras"},
    {"afip":"14948","location":"Vuelta De Las Tobas"},
    {"afip":"14961","location":"Weisburg"},
    {"afip":"14974","location":"Yacara"},
    {"afip":"14978","location":"Yacay"},
    {"afip":"14979","location":"Yacera"},
    {"afip":"14982","location":"Yacimiento Tonono"},
    {"afip":"14985","location":"Yacochuya"},
    {"afip":"14987","location":"Yacones"},
    {"afip":"14995","location":"Yacuy"},
    {"afip":"15009","location":"Yanchuya"},
    {"afip":"15022","location":"Yariguarenda"},
    {"afip":"15025","location":"Yasquiasme"},
    {"afip":"15031","location":"Yatasto"},
    {"afip":"15060","location":"Yuchan"},
    {"afip":"15084","location":"Zanja Honda"},
    {"afip":"15085","location":"Zanjon"},
    {"afip":"15092","location":"Zapallito"},
    {"afip":"15188","location":"Agua Negra"},
    {"afip":"15194","location":"Agua Verde"},
    {"afip":"15208","location":"Aguas Blancas"},
    {"afip":"15211","location":"Aguas Calientes"},
    {"afip":"15220","location":"Alfarcito"},
    {"afip":"15224","location":"Algarrobal"},
    {"afip":"15236","location":"Almirante Brown"},
    {"afip":"15244","location":"Alto Alegre"},
    {"afip":"15252","location":"Alto Verde"},
    {"afip":"15260","location":"Ampata"},
    {"afip":"15268","location":"Angostura"},
    {"afip":"15279","location":"Arbol Solo"},
    {"afip":"15287","location":"Arenales"},
    {"afip":"15316","location":"Bajada Grande"},
    {"afip":"15321","location":"Bajo Grande"},
    {"afip":"15332","location":"Balbuena"},
    {"afip":"15342","location":"Baradero"},
    {"afip":"15360","location":"Barrial"},
    {"afip":"15366","location":"Barrio San Cayetano"},
    {"afip":"15369","location":"Barro Negro"},
    {"afip":"15371","location":"Baños Termales"}
    ,
    {"afip":"15375","location":"Belgrano"},
    {"afip":"15387","location":"Bella Vista"},
    {"afip":"15409","location":"Buen Lugar"},
    {"afip":"15425","location":"Buena Vista"},
    {"afip":"15433","location":"Cabeza De Buey"},
    {"afip":"15435","location":"Cachi"},
    {"afip":"15448","location":"Campo Alegre"},
    {"afip":"15464","location":"Campo Largo"},
    {"afip":"15479","location":"Cangrejillos"},
    {"afip":"15483","location":"Carabajal"},
    {"afip":"15486","location":"Carahuasi"},
    {"afip":"15507","location":"Casa Grande"},
    {"afip":"15519","location":"Castellanos"},
    {"afip":"15521","location":"Cauchari"},
    {"afip":"15550","location":"Ceibal"},
    {"afip":"15557","location":"Cerrillos"},
    {"afip":"15570","location":"Cerro Bayo"},
    {"afip":"15578","location":"Cerro Bravo"},
    {"afip":"15584","location":"Cerro Colorado"},
    {"afip":"15596","location":"Cerro Negro"},
    {"afip":"15605","location":"Cevilar"},
    {"afip":"15613","location":"Chamical"},
    {"afip":"15621","location":"Chañar"}
    ,
    {"afip":"15642","location":"Chorrillos"},
    {"afip":"15655","location":"Colon"},
    {"afip":"15746","location":"Colonia Santa Maria"},
    {"afip":"15751","location":"Colonia Santa Rosa"},
    {"afip":"15764","location":"Condor"},
    {"afip":"15776","location":"Coronel Moldes"},
    {"afip":"15784","location":"Corral Quemado"},
    {"afip":"15792","location":"Corralito"},
    {"afip":"15800","location":"Cortaderas"},
    {"afip":"15804","location":"Corzuela"},
    {"afip":"15819","location":"Curuzu"},
    {"afip":"15824","location":"Diamante"},
    {"afip":"15827","location":"Divisadero"},
    {"afip":"15844","location":"Durazno"},
    {"afip":"15850","location":"El Acheral"},
    {"afip":"15854","location":"El Aguilar"},
    {"afip":"15865","location":"El Algarrobal"},
    {"afip":"15868","location":"El Algarrobo"},
    {"afip":"15883","location":"El Arenal"},
    {"afip":"15887","location":"El Aybal"},
    {"afip":"15918","location":"El Bordo"},
    {"afip":"15924","location":"El Brete"},
    {"afip":"15940","location":"El Carancho"},
    {"afip":"15947","location":"El Carmen"},
    {"afip":"15955","location":"El Carrizal"},
    {"afip":"15963","location":"El Ceibal"},
    {"afip":"15992","location":"El Chorro"},
    {"afip":"15996","location":"El Cienego"},
    {"afip":"16000","location":"El Colegio"},
    {"afip":"16006","location":"El Condor"},
    {"afip":"16010","location":"El Corralito"},
    {"afip":"16022","location":"El Desmonte"},
    {"afip":"16024","location":"El Destierro"},
    {"afip":"16034","location":"El Dorado"},
    {"afip":"16056","location":"El Espinillo"},
    {"afip":"16059","location":"El Estanque"},
    {"afip":"16061","location":"El Fraile"},
    {"afip":"16073","location":"El Guanaco"},
    {"afip":"16093","location":"El Manantial"},
    {"afip":"16102","location":"El Manzano"},
    {"afip":"16117","location":"El Mirador"},
    {"afip":"16119","location":"El Mistol"},
    {"afip":"16129","location":"El Molino"},
    {"afip":"16136","location":"El Mollar"},
    {"afip":"16141","location":"El Molle"},
    {"afip":"16149","location":"El Naranjo"},
    {"afip":"16186","location":"El Parque"},
    {"afip":"16193","location":"El Pertigo"},
    {"afip":"16204","location":"El Portezuelo"},
    {"afip":"16220","location":"El Porvenir"},
    {"afip":"16230","location":"El Potrero"},
    {"afip":"16237","location":"El Prado"},
    {"afip":"16242","location":"El Pucara"},
    {"afip":"16252","location":"El Puestito"},
    {"afip":"16266","location":"El Quebrachal"},
    {"afip":"16277","location":"El Quemado"},
    {"afip":"16284","location":"El Recreo"},
    {"afip":"16302","location":"El Retiro"},
    {"afip":"16315","location":"El Rodeo"},
    {"afip":"16342","location":"El Salto"},
    {"afip":"16355","location":"El Sauce"},
    {"afip":"16359","location":"El Sauzal"},
    {"afip":"16368","location":"El Simbolar"},
    {"afip":"16375","location":"El Sunchal"},
    {"afip":"16388","location":"El Talar"},
    {"afip":"16392","location":"El Tartagal"},
    {"afip":"16399","location":"El Tipal"},
    {"afip":"16401","location":"El Toro"},
    {"afip":"16428","location":"El Vallecito"},
    {"afip":"16438","location":"El Zanjon"},
    {"afip":"16443","location":"El Zapallar"},
    {"afip":"16450","location":"Encrucijada"},
    {"afip":"16458","location":"Entre Rios"},
    {"afip":"16470","location":"Espinillo"},
    {"afip":"16475","location":"Esquina"},
    {"afip":"16498","location":"Estancia Vieja"},
    {"afip":"16502","location":"Estela"},
    {"afip":"16507","location":"Federacion"},
    {"afip":"16518","location":"Floresta"},
    {"afip":"16533","location":"Fuerte Quemado"},
    {"afip":"16536","location":"General Alvarado"},
    {"afip":"16549","location":"Gonzalez"},
    {"afip":"16566","location":"Hipolito Yrigoyen"},
    {"afip":"16569","location":"Hornillos"},
    {"afip":"16610","location":"Joaquin V Gonzalez"},
    {"afip":"16634","location":"La Aguadita"},
    {"afip":"16659","location":"La Argentina"},
    {"afip":"16667","location":"La Armonia"},
    {"afip":"16684","location":"La Banda"},
    {"afip":"16716","location":"La Cancha"},
    {"afip":"16717","location":"La Candelaria"},
    {"afip":"16741","location":"La Cañada"}
    ,
    {"afip":"16762","location":"La China"},
    {"afip":"16771","location":"La Cienaga"},
    {"afip":"16776","location":"La Cieneguita"},
    {"afip":"16815","location":"La Costa"},
    {"afip":"16818","location":"La Costosa"},
    {"afip":"16824","location":"La Cruz"},
    {"afip":"16835","location":"La Curva"},
    {"afip":"16862","location":"La Entrada"},
    {"afip":"16880","location":"La Esperanza"},
    {"afip":"16909","location":"La Estrella"},
    {"afip":"16915","location":"La Falda"},
    {"afip":"16940","location":"La Florida"},
    {"afip":"16949","location":"La Fortuna"},
    {"afip":"16968","location":"La Guardia"},
    {"afip":"16974","location":"La Herradura"},
    {"afip":"16986","location":"La Hoyada"},
    {"afip":"16989","location":"La Huerta"},
    {"afip":"17011","location":"La Isla"},
    {"afip":"17032","location":"La Lagunilla"},
    {"afip":"17059","location":"La Lomita"},
    {"afip":"17077","location":"La Maravilla"},
    {"afip":"17082","location":"La Margarita"},
    {"afip":"17095","location":"La Matilde"},
    {"afip":"17098","location":"La Merced"},
    {"afip":"17108","location":"La Mora"},
    {"afip":"17123","location":"La Ofelia"},
    {"afip":"17141","location":"La Pampa"},
    {"afip":"17170","location":"La Plata"},
    {"afip":"17174","location":"La Poblacion"},
    {"afip":"17192","location":"La Posta"},
    {"afip":"17213","location":"La Puerta"},
    {"afip":"17218","location":"La Puntilla"},
    {"afip":"17233","location":"La Ramada"},
    {"afip":"17252","location":"La Represa"},
    {"afip":"17299","location":"La Soledad"},
    {"afip":"17307","location":"La Tablada"},
    {"afip":"17317","location":"La Toma"},
    {"afip":"17326","location":"La Trampa"},
    {"afip":"17338","location":"La Union"},
    {"afip":"17364","location":"La Viña"}
    ,
    {"afip":"17366","location":"La Yesera"},
    {"afip":"17377","location":"Laguna Blanca"},
    {"afip":"17394","location":"Laguna Seca"},
    {"afip":"17397","location":"Laguna Verde"},
    {"afip":"17403","location":"Lagunita"},
    {"afip":"17417","location":"Las Animas"},
    {"afip":"17430","location":"Las Bateas"},
    {"afip":"17432","location":"Las Blancas"},
    {"afip":"17438","location":"Las Capillas"},
    {"afip":"17442","location":"Las Catitas"},
    {"afip":"17447","location":"Las Cañas"}
    ,
    {"afip":"17453","location":"Las Cañitas"}
    ,
    {"afip":"17483","location":"Las Cortaderas"},
    {"afip":"17491","location":"Las Cuevas"},
    {"afip":"17499","location":"Las Delicias"},
    {"afip":"17510","location":"Las Flores"},
    {"afip":"17517","location":"Las Garzas"},
    {"afip":"17536","location":"Las Horquetas"},
    {"afip":"17542","location":"Las Juntas"},
    {"afip":"17555","location":"Las Lajitas"},
    {"afip":"17564","location":"Las Lomitas"},
    {"afip":"17570","location":"Las Maravillas"},
    {"afip":"17582","location":"Las Mercedes"},
    {"afip":"17591","location":"Las Mojarras"},
    {"afip":"17592","location":"Las Moras"},
    {"afip":"17602","location":"Las Palmas"},
    {"afip":"17632","location":"Las Piedritas"},
    {"afip":"17636","location":"Las Pircas"},
    {"afip":"17651","location":"Las Rosas"},
    {"afip":"17658","location":"Las Saladas"},
    {"afip":"17671","location":"Las Tortugas"},
    {"afip":"17683","location":"Las Trancas"},
    {"afip":"17692","location":"Las Varas"},
    {"afip":"17696","location":"Las Viboras"},
    {"afip":"17700","location":"Las Zanjas"},
    {"afip":"17757","location":"Lorohuasi"},
    {"afip":"17761","location":"Los Alamos"},
    {"afip":"17786","location":"Los Baldes"},
    {"afip":"17792","location":"Los Blancos"},
    {"afip":"17805","location":"Los Castillos"},
    {"afip":"17830","location":"Los Colorados"},
    {"afip":"17837","location":"Los Corrales"},
    {"afip":"17865","location":"Los Laureles"},
    {"afip":"17881","location":"Los Mogotes"},
    {"afip":"17900","location":"Los Nogales"},
    {"afip":"17918","location":"Los Patos"},
    {"afip":"17928","location":"Los Pocitos"},
    {"afip":"17960","location":"Los Sauces"},
    {"afip":"17969","location":"Los Toldos"},
    {"afip":"17975","location":"Los Zanjones"},
    {"afip":"17983","location":"Madariaga"},
    {"afip":"17986","location":"Mal Paso"},
    {"afip":"18015","location":"Maria Luisa"},
    {"afip":"18019","location":"Martin Garcia"},
    {"afip":"18034","location":"Media Luna"},
    {"afip":"18051","location":"Miraflores"},
    {"afip":"18061","location":"Molino"},
    {"afip":"18063","location":"Molinos"},
    {"afip":"18079","location":"Monte Grande"},
    {"afip":"18111","location":"Nazareno"},
    {"afip":"18116","location":"Nogalito"},
    {"afip":"18129","location":"Nuevo Porvenir"},
    {"afip":"18137","location":"Ojo De Agua"},
    {"afip":"18143","location":"Olmos"},
    {"afip":"18151","location":"Ovejeria"},
    {"afip":"18162","location":"Palmarcito"},
    {"afip":"18164","location":"Palmira"},
    {"afip":"18169","location":"Palo A Pique"},
    {"afip":"18181","location":"Palo Santo"},
    {"afip":"18190","location":"Pampa Grande"},
    {"afip":"18201","location":"Papa Chacra"},
    {"afip":"18204","location":"Paraiso"},
    {"afip":"18213","location":"Paso De Las Carretas"},
    {"afip":"18220","location":"Paso Del Durazno"},
    {"afip":"18226","location":"Paso La Cruz"},
    {"afip":"18230","location":"Pedregal"},
    {"afip":"18233","location":"Peru"},
    {"afip":"18236","location":"Peñaflor"}
    ,
    {"afip":"18240","location":"Peñas Blancas"}
    ,
    {"afip":"18241","location":"Pie De La Cuesta"},
    {"afip":"18271","location":"Pocitos"},
    {"afip":"18288","location":"Potrerillo"},
    {"afip":"18291","location":"Potrerillos"},
    {"afip":"18295","location":"Potrero"},
    {"afip":"18300","location":"Pozo Blanco"},
    {"afip":"18301","location":"Pozo Bravo"},
    {"afip":"18307","location":"Pozo De La Esquina"},
    {"afip":"18308","location":"Pozo De La Piedra"},
    {"afip":"18316","location":"Pozo Del Chañar"}
    ,
    {"afip":"18327","location":"Pozo Grande"},
    {"afip":"18331","location":"Pozo Hondo"},
    {"afip":"18339","location":"Pozo Verde"},
    {"afip":"18353","location":"Pucara"},
    {"afip":"18360","location":"Pueblo Nuevo"},
    {"afip":"18366","location":"Pueblo Viejo"},
    {"afip":"18381","location":"Puesto"},
    {"afip":"18386","location":"Puesto De La Viuda"},
    {"afip":"18390","location":"Puesto Del Medio"},
    {"afip":"18394","location":"Puesto Grande"},
    {"afip":"18401","location":"Puesto Viejo"},
    {"afip":"18407","location":"Punta De Agua"},
    {"afip":"18414","location":"Punta Del Agua"},
    {"afip":"18429","location":"Quebrachal"},
    {"afip":"18463","location":"Recreo"},
    {"afip":"18470","location":"Resistencia"},
    {"afip":"18472","location":"Retiro"},
    {"afip":"18494","location":"Rio Blanco"},
    {"afip":"18504","location":"Rio Colorado"},
    {"afip":"18516","location":"Rio Seco"},
    {"afip":"18521","location":"Rivadavia"},
    {"afip":"18534","location":"Rodeo Grande"},
    {"afip":"18540","location":"Rosales"},
    {"afip":"18547","location":"Saladillo"},
    {"afip":"18553","location":"Salazar"},
    {"afip":"18564","location":"San Agustin"},
    {"afip":"18576","location":"San Alejo"},
    {"afip":"18581","location":"San Andres"},
    {"afip":"18595","location":"San Antonio"},
    {"afip":"18611","location":"San Bernardo"},
    {"afip":"18622","location":"San Carlos"},
    {"afip":"18632","location":"San Clemente"},
    {"afip":"18647","location":"San Esteban"},
    {"afip":"18653","location":"San Felipe"},
    {"afip":"18662","location":"San Fernando"},
    {"afip":"18671","location":"San Francisco"},
    {"afip":"18676","location":"San Gabriel"},
    {"afip":"18682","location":"San Geronimo"},
    {"afip":"18702","location":"San Ignacio"},
    {"afip":"18712","location":"San Isidro"},
    {"afip":"18724","location":"San Javier"},
    {"afip":"18733","location":"San Joaquin"},
    {"afip":"18738","location":"San Jorge"},
    {"afip":"18753","location":"San Jose"},
    {"afip":"18767","location":"San Juan"},
    {"afip":"18783","location":"San Leon"},
    {"afip":"18792","location":"San Lorenzo"},
    {"afip":"18798","location":"San Lucas"},
    {"afip":"18802","location":"San Luis"},
    {"afip":"18811","location":"San Marcos"},
    {"afip":"18819","location":"San Martin"},
    {"afip":"18834","location":"San Miguel"},
    {"afip":"18842","location":"San Nicolas"},
    {"afip":"18851","location":"San Patricio"},
    {"afip":"18863","location":"San Pedro"},
    {"afip":"18868","location":"San Pedro De Iruya"},
    {"afip":"18876","location":"San Rafael"},
    {"afip":"18884","location":"San Ramon"},
    {"afip":"18894","location":"San Roque"},
    {"afip":"18908","location":"San Sebastian"},
    {"afip":"18924","location":"San Vicente"},
    {"afip":"18937","location":"Santa Ana"},
    {"afip":"18944","location":"Santa Barbara"},
    {"afip":"18953","location":"Santa Catalina"},
    {"afip":"18963","location":"Santa Clara"},
    {"afip":"18969","location":"Santa Cruz"},
    {"afip":"18979","location":"Santa Elena"},
    {"afip":"18987","location":"Santa Gertrudis"},
    {"afip":"19009","location":"Santa Lucia"},
    {"afip":"19027","location":"Santa Maria"},
    {"afip":"19041","location":"Santa Rita"},
    {"afip":"19055","location":"Santa Rosa"},
    {"afip":"19065","location":"Santa Teresa"},
    {"afip":"19070","location":"Santa Victoria"},
    {"afip":"19082","location":"Santo Domingo"},
    {"afip":"19097","location":"Santos Lugares"},
    {"afip":"19110","location":"Sauce"},
    {"afip":"19116","location":"Sauzal"},
    {"afip":"19133","location":"Simbolar"},
    {"afip":"19148","location":"Soledad"},
    {"afip":"19154","location":"Sumalao"},
    {"afip":"19166","location":"Tacuara"},
    {"afip":"19170","location":"Tala"},
    {"afip":"19183","location":"Tartagal"},
    {"afip":"19193","location":"Toro"},
    {"afip":"19204","location":"Totoral"},
    {"afip":"19216","location":"Tres Acequias"},
    {"afip":"19228","location":"Tres Cruces"},
    {"afip":"19244","location":"Tres Morros"},
    {"afip":"19250","location":"Tres Pozos"},
    {"afip":"19260","location":"Tunalito"},
    {"afip":"19268","location":"Urundel"},
    {"afip":"19274","location":"Vallecito"},
    {"afip":"19282","location":"Victorica"},
    {"afip":"19304","location":"Villa General Guemes"},
    {"afip":"19344","location":"Vinalito"},
    {"afip":"19347","location":"Viscachani"},
    {"afip":"20560","location":"Santo Domingo"},
    {"afip":"20561","location":"Tala"},
    {"afip":"20562","location":"Tres Pozos"},
    {"afip":"20563","location":"Aguas Calientes"},
    {"afip":"20564","location":"Arenal"},
    {"afip":"20565","location":"Bella Vista"},
    {"afip":"20566","location":"Cabeza De Anta"},
    {"afip":"20567","location":"Diamante"},
    {"afip":"20568","location":"El Bordo"},
    {"afip":"20569","location":"El Ceibal"},
    {"afip":"20570","location":"El Naranjo"},
    {"afip":"20571","location":"El Potrero"},
    {"afip":"20572","location":"El Quemado"},
    {"afip":"20573","location":"La Banda"},
    {"afip":"20574","location":"La Cruz"},
    {"afip":"20575","location":"Las Mercedes"},
    {"afip":"20576","location":"Los Churquis"},
    {"afip":"20577","location":"Los Rosales"},
    {"afip":"20578","location":"Ojo De Agua"},
    {"afip":"20579","location":"Ovejeria"},
    {"afip":"20580","location":"Potrerillo"},
    {"afip":"20581","location":"Potrero"},
    {"afip":"20582","location":"Pozo Verde"},
    {"afip":"20583","location":"San Juan"},
    {"afip":"20584","location":"San Lorenzo"},
    {"afip":"20585","location":"San Luis"},
    {"afip":"20586","location":"San Pedro"},
    {"afip":"20587","location":"San Roque"},
    {"afip":"20588","location":"San Vicente"},
    {"afip":"20589","location":"Santa Ana"},
    {"afip":"20590","location":"Santa Cruz"},
    {"afip":"20591","location":"Santa Maria"},
    {"afip":"20592","location":"Santa Rosa"},
    {"afip":"20593","location":"Vaqueria"},
    {"afip":"20594","location":"Camara"},
    {"afip":"20595","location":"Cerro Negro"},
    {"afip":"20596","location":"Doctor Facundo Zuviria"},
    {"afip":"20597","location":"El Carmen"},
    {"afip":"20598","location":"El Mollar"},
    {"afip":"20599","location":"El Porvenir"},
    {"afip":"20600","location":"El Potrero"},
    {"afip":"20601","location":"Las Arcas"},
    {"afip":"20602","location":"Media Luna"},
    {"afip":"20603","location":"Pie De La Cuesta"},
    {"afip":"20604","location":"Tres Cruces"},
    {"afip":"20605","location":"Angostura"},
    {"afip":"20606","location":"Buena Vista"},
    {"afip":"20607","location":"Corralito"},
    {"afip":"20608","location":"Jacimana"},
    {"afip":"20609","location":"La Arcadia"},
    {"afip":"20610","location":"La Merced"},
    {"afip":"20611","location":"La Viña"}
    ,
    {"afip":"20612","location":"Los Sauces"},
    {"afip":"20613","location":"Monte Viejo"},
    {"afip":"20614","location":"San Antonio"},
    {"afip":"20615","location":"San Felipe"},
    {"afip":"20616","location":"Santa Rosa"},
    {"afip":"20617","location":"Simbolar"},
    {"afip":"20618","location":"Tonco"},
    {"afip":"20619","location":"La Falda"},
    {"afip":"20620","location":"Pucara"},
    {"afip":"20621","location":"San Felipe"},
    {"afip":"20622","location":"Santa Cruz"},
    {"afip":"20623","location":"Santa Victoria"},
    {"afip":"20624","location":"Soledad"},
    {"afip":"20636","location":"Santa Elena"},
    {"afip":"20637","location":"Las Cañas"}
    ,
    {"afip":"20638","location":"Limoncito"},
    {"afip":"20639","location":"Molino"},
    {"afip":"20640","location":"Pueblo Viejo"},
    {"afip":"20641","location":"San Antonio"},
    {"afip":"20642","location":"San Juan"},
    {"afip":"20643","location":"Los Sauces"},
    {"afip":"20644","location":"El Naranjo"},
    {"afip":"20645","location":"El Sunchal"},
    {"afip":"20646","location":"La Candelaria"},
    {"afip":"20647","location":"La Poblacion"},
    {"afip":"20648","location":"Miraflores"},
    {"afip":"20649","location":"Potrerillos"},
    {"afip":"20650","location":"El Potrero"},
    {"afip":"20651","location":"El Rodeo"},
    {"afip":"20652","location":"La Esperanza"},
    {"afip":"20653","location":"Pueblo Viejo"},
    {"afip":"20654","location":"Bella Vista"},
    {"afip":"20655","location":"El Acheral"},
    {"afip":"20656","location":"El Carmen"},
    {"afip":"20657","location":"Finca El Carmen"},
    {"afip":"20658","location":"La Armonia"},
    {"afip":"20659","location":"La Costa"},
    {"afip":"20660","location":"La Viña"}
    ,
    {"afip":"20661","location":"Las Cañas"}
    ,
    {"afip":"20662","location":"Potrerillos"},
    {"afip":"20663","location":"Saladillo"},
    {"afip":"20664","location":"Campo Alegre"},
    {"afip":"20665","location":"Durazno"},
    {"afip":"20666","location":"El Bordo"},
    {"afip":"20667","location":"La Armonia"},
    {"afip":"20668","location":"La Poblacion"},
    {"afip":"20669","location":"Miraflores"},
    {"afip":"20670","location":"Piquete De Anta"},
    {"afip":"20671","location":"Poblacion"},
    {"afip":"20672","location":"Potrero"},
    {"afip":"20673","location":"Santa Elena"},
    {"afip":"20674","location":"Tala Muyo"},
    {"afip":"20675","location":"Tunalito"},
    {"afip":"20676","location":"Angostura"},
    {"afip":"20677","location":"Chicoana"},
    {"afip":"20678","location":"El Carmen"},
    {"afip":"20679","location":"Esquina"},
    {"afip":"20680","location":"Rumihuasi"},
    {"afip":"20681","location":"San Jose De Colte"},
    {"afip":"20682","location":"San Martin"},
    {"afip":"20683","location":"Aguas Blancas"},
    {"afip":"20684","location":"Algarrobal"},
    {"afip":"20685","location":"El Carmen"},
    {"afip":"20686","location":"El Quemado"},
    {"afip":"20687","location":"Finca Mision Zenta"},
    {"afip":"20688","location":"La Toma"},
    {"afip":"20689","location":"Las Cortaderas"},
    {"afip":"20690","location":"Minas Ypf"},
    {"afip":"20691","location":"Mision Franciscana"},
    {"afip":"20692","location":"Rio De Las Piedras"},
    {"afip":"20693","location":"San Antonio"},
    {"afip":"20694","location":"San Ignacio"},
    {"afip":"20695","location":"Santa Rosa"},
    {"afip":"20696","location":"Algarrobal"},
    {"afip":"20697","location":"Bella Vista"},
    {"afip":"20698","location":"El Ceibal"},
    {"afip":"20699","location":"El Espinillo"},
    {"afip":"20700","location":"El Sunchal"},
    {"afip":"20701","location":"El Zapallar"},
    {"afip":"20702","location":"La Asuncion"},
    {"afip":"20703","location":"La Esperanza"},
    {"afip":"20704","location":"La Maravilla"},
    {"afip":"20705","location":"La Montaña"}
    ,
    {"afip":"20706","location":"La Union"},
    {"afip":"20707","location":"Las Conchas"},
    {"afip":"20708","location":"Media Luna"},
    {"afip":"20709","location":"Pluma Del Pato"},
    {"afip":"20710","location":"Pozo Cercado"},
    {"afip":"20711","location":"Pozo Verde"},
    {"afip":"20712","location":"San Agustin"},
    {"afip":"20713","location":"San Bernardo"},
    {"afip":"20714","location":"San Isidro"},
    {"afip":"20715","location":"San Joaquin"},
    {"afip":"20716","location":"San Miguel"},
    {"afip":"20717","location":"San Pedro De Aranda"},
    {"afip":"20718","location":"Santa Maria"},
    {"afip":"20719","location":"Santa Rosa"},
    {"afip":"20720","location":"Buena Vista"},
    {"afip":"20721","location":"Los Alamos"},
    {"afip":"20722","location":"La Isla"},
    {"afip":"20723","location":"Las Blancas"},
    {"afip":"20724","location":"Las Palmas"},
    {"afip":"20725","location":"Los Alamos"},
    {"afip":"20726","location":"Rio Ancho"},
    {"afip":"20727","location":"San Martin"},
    {"afip":"20728","location":"Campo Alegre"},
    {"afip":"20729","location":"El Simbolar"},
    {"afip":"20730","location":"La Esperanza"},
    {"afip":"20731","location":"Santa Ana"},
    {"afip":"20732","location":"Santa Rosa"},
    {"afip":"20733","location":"Santa Victoria"},
    {"afip":"20734","location":"Algarrobal"},
    {"afip":"20735","location":"El Bordo"},
    {"afip":"20736","location":"El Prado"},
    {"afip":"20737","location":"Ojo De Agua"},
    {"afip":"20738","location":"Saladillo"},
    {"afip":"20739","location":"San Isidro"},
    {"afip":"20740","location":"San Martin"},
    {"afip":"20741","location":"Santa Rosa"},
    {"afip":"21680","location":"Estola"},
    {"afip":"22099","location":"Candelaria"},
    {"afip":"22100","location":"El Potrero"},
    {"afip":"22101","location":"Rio Urueña"}
    ,
    {"afip":"22102","location":"General Güemes"}
    ,
    {"afip":"22103","location":"Anta"},
    {"afip":"22104","location":"El Galpon"},
    {"afip":"22105","location":"Coronel Ollero"},
    {"afip":"22106","location":"Joaquin V. Gonzalez"},
    {"afip":"22107","location":"Santa Victoria (J. De San Martin)"},
    {"afip":"22108","location":"Hickan"},
    {"afip":"22109","location":"Padre Lozano"},
    {"afip":"22110","location":"Profesor Salvador Mazza"},
    {"afip":"22256","location":"Rodeo(Santa Victoria)"},
    {"afip":"22290","location":"Guemes"},
    {"afip":"22291","location":"La Merced Chica"}
    ]