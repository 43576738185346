<svg  class="svg-12" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_3720_213956)">
    <path d="M8.625 11.9991H1.37503C0.616516 11.9991 0 11.3825 0 10.624V3.37405C0 2.61554 0.616516 1.99902 1.37503 1.99902H5.625C5.832 1.99902 6 2.16702 6 2.37402C6 2.58102 5.832 2.74902 5.625 2.74902H1.37503C1.03052 2.74902 0.75 3.02954 0.75 3.37405V10.624C0.75 10.9685 1.03052 11.2491 1.37503 11.2491H8.625C8.96951 11.2491 9.25003 10.9685 9.25003 10.624V6.37405C9.25003 6.16705 9.41803 5.99905 9.62503 5.99905C9.83203 5.99905 10 6.1666 10 6.37405V10.624C10 11.3825 9.38351 11.9991 8.625 11.9991V11.9991Z" fill="black"/>
    <path d="M4.38262 7.988C4.2841 7.988 4.18807 7.949 4.11757 7.87804C4.02858 7.78951 3.99059 7.66198 4.01512 7.53948L4.36861 5.77151C4.38307 5.69854 4.41905 5.63198 4.47106 5.57998L9.64964 0.402031C10.1856 -0.13401 11.0576 -0.13401 11.5941 0.402031C11.8536 0.661491 11.9966 1.00655 11.9966 1.37405C11.9966 1.74154 11.8536 2.08651 11.5936 2.34606L6.41564 7.52456C6.36363 7.57702 6.29662 7.61254 6.22411 7.62701L4.45659 7.98049C4.43205 7.98553 4.40706 7.988 4.38262 7.988V7.988ZM6.15059 7.25951H6.15563H6.15059ZM5.08162 6.03005L4.86107 7.135L5.96556 6.91399L11.0636 1.81606C11.1816 1.6975 11.2466 1.54104 11.2466 1.37405C11.2466 1.20705 11.1816 1.0505 11.0636 0.93203C10.8206 0.688499 10.4241 0.688499 10.1796 0.93203L5.08162 6.03005Z" fill="black"/>
    <path d="M10.6248 3.16388C10.5288 3.16388 10.4328 3.12735 10.3598 3.05383L8.9458 1.63934C8.79932 1.49286 8.79932 1.25537 8.9458 1.10889C9.09229 0.962402 9.32977 0.962402 9.47635 1.10889L10.8903 2.52338C11.0368 2.66986 11.0368 2.90735 10.8903 3.05383C10.8168 3.12689 10.7208 3.16388 10.6248 3.16388V3.16388Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_3720_213956">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
</svg>