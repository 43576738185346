
<div *ngIf="conditionTypeSelected == conditionTypes.TO_PRICE || 
(conditionTypeSelected == conditionTypes.TO_FIX && boardSelected != BOARDS_TYPES.CARDS) else cardsIndicators" class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive">
        <thead>
            <tr row-price-head class="sticky-header" [buyerType]="buyerType" [conditionType]="conditionTypeSelected"
            [filter]="priceFilter" [filterIdentificator]="filterIdentificator" [viewSelected]="viewSelected"
            (filterSelect)="selectFilter($event)"></tr>
        </thead>
        <tbody>
            <ng-container  *ngFor="let product of arrayOfHistorical | filterByConditionType:conditionTypeSelected:conditionFilters | isPriceVisibleForComercial:true | filterByPrice:priceFilter | filterPrice:search | sortPrice: isComercial; index as i;" >
            <tr class="disabled" [ngClass]="{'isRetired': product.status == 4, 
            'is-open': getArrowDirection(product._id)=='up' && haveIndicatorsP(product)}" >
                <td class="options-column"> 

                    <eye-svg (click)="openModalPrice(product)" [active]="false"></eye-svg>

                    <three-point-svg (click)="openOptions(i)"></three-point-svg>
                    <div class="menu-options in-menu-option" *ngIf="optionIdentificator==i">
                        <ul class="in-menu-option">
                            <li (click)="showHistoricalP(product)" class="in-menu-option">Historial de cambios</li>
                            <li (click)="reingresateCondition(product)" class="in-menu-option">
                            {{isComercial?'Utilizar condición':'Reingresar condición'}}</li>
                        </ul>
                    </div>

                    <arrow-board-svg class="arrow" [direction]="getArrowDirection(product._id)" *ngIf="haveIndicatorsP(product)" 
                    (click)="clickInArrow(product, $event)"></arrow-board-svg>
                </td>    
            
                <td class="observations-column" *ngIf="viewSelected.observations">
                    <div class="observations-column-contain">
                        <div *ngIf="product?.observations?.isFeatured == 1" class="icon featured-price-icon-active">
                            <div class="tooltip-info row-center w-90">
                                <span>Precio destacado</span>
                            </div>
                        </div>
                        <div *ngIf="product?.observations?.isMessage == 1"
                            class="icon message-icon-active message-container tooltip-container">
                            <div class="tooltip message ">
                                <span>
                                    Observación: <br>
                                    {{product?.observations?.message}}
                                </span>
                                <div class="triangle"></div>
                            </div>
                        </div>
                        <div *ngIf="product?.observations?.isBussinesGravanz == 1" class="icon gravanz-icon-active">
                            <div class="tooltip-info row-center w-60">
                                <span>Gravanz</span>
                            </div>
                        </div>
                    </div>
                </td>

                <td *ngIf="viewSelected.state">
                    {{getPriceStatus(product)}}
                </td>

                <td *ngIf="viewSelected.product">
                    <span>{{getProductName(product)}}</span>
                </td>

                <td *ngIf="buyerType==buyerTypes.BUYER || buyerType==buyerTypes.ALL">
                    {{ BUYERS[product?.observations?.buyer ?? 0]}}
                </td>

                <td *ngIf="viewSelected.typeBusiness">{{getBusinessType(product)}}</td>

                <td *ngIf="viewSelected.placeOfDelivery">
                    {{getPlaceOfDeliveryP(product)}}
                </td>

                <td *ngIf="viewSelected.deliveryPeriod">                    
                    <div class="delivery-period-container tooltip-container" [ngClass]="{'is-valid-until': isValidUntil(product)}">

                    <span>{{getDeliveryPeriodP(product)}}</span>
                    
                    <div class="tooltip delivery-period-message" *ngIf="isValidUntil(product)">
                        <span>Valido hasta: {{getValidUntilText(product)}}</span>
                        <div class="triangle"></div>
                    </div>

                </div>
                </td>

                <!-- Quality -->
                <td *ngIf="viewSelected.quality" class="quality-column">
                    <div class="quality-container tooltip-container {{QUALITY[product.quality].toLowerCase()}}" 
                    [ngClass]="{'not-quality-particularity': product.qualityIBM<0}">
                        <span class="quality">{{getQualityP(product)}}</span>
                        <div class="tooltip quality-message" *ngIf="product.qualityIBM > 0">
                            <span>
                                Particularidad de calidad: <br>
                                "{{getQualityIBM(product)}}"
                            </span>
                            <div class="triangle"></div>
                        </div>
                    </div>
                </td>

                <!-- Price -->
                <td class="price-column" *ngIf="viewSelected?.price && conditionTypeSelected!=conditionTypes.TO_FIX">
                    <div class="price-container tooltip-container">
                        <span>{{getTypeCoinAndPriceWithPoints(product)}}</span>
                    </div>
                </td>
                
                <td *ngIf="viewSelected.wayPay">
                    <span>{{getOnlyWayPayName(product)}}</span><br>
                    <p class="days-way-pay">{{getDaysWayPay(product)}} - {{getPercentageWayPay(product)}}</p>
                </td>
                <td *ngIf="viewSelected.expiration && conditionTypeSelected==conditionTypes.TO_PRICE">{{product?.wayPay?.expiration}}</td>
                <td *ngIf="viewSelected.pesificacion">{{getPesificationP(product)}}</td>
                <td *ngIf="viewSelected.harvest">{{product?.harvest}}</td>
                <td *ngIf="viewSelected.businessParticularities">{{getBusinessParticularities(product.businessParticularities)}}</td>
                <td *ngIf="viewSelected.hour">{{product?.dataOfcreation?.hour}}</td>
            </tr>

            <tr class="indicators" *ngIf="haveIndicatorsP(product) && isIdInArrayParent(product._id)">
                <td class="first-col-indicators"></td>
            
                <td colspan="14">
                    <div class="indicators-container">
                        <app-indicators-table [price]="product" [showFirstColumn]="false" [user]="user"></app-indicators-table>
                    </div>
                </td>
            </tr>
                
            </ng-container>
        </tbody>
    </table>
</div>

<ng-template #cardsIndicators>
    <div class="container-card-indicators">
        <card-indicators class="card-indicator" *ngFor="let price of arrayOfHistorical | filterByConditionType:conditionTypeSelected:conditionFilters | isPriceVisibleForComercial:true | filterByPrice:priceFilter | filterPrice:search | sortPrice: isComercial; index as i;" [price]="price"></card-indicators>
    </div>
</ng-template>