<div class="field">
    <span class="field-title">Bonificación</span>

    <div class="width-100 row-between">
        <number-input class="half-input" [initialPlaceHolder]="'Porcentaje'" [max]="'100'" [isWithPoint]="true" 
        (valueOut)="setElementBonus($event, 'percentageB')" [value]="percentageB"></number-input>
    
        <number-input class="half-input" [initialPlaceHolder]="'Monto'" [isWithPoint]="true" 
        (valueOut)="setElementBonus($event, 'amountB')" [value]="amountB"></number-input>
    </div>
</div>