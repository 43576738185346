import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogYesOrNoComponent } from './dialog-yes-or-no.component';

@NgModule({
  declarations: [
    DialogYesOrNoComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    DialogYesOrNoComponent
  ]
})
export class DialogYesOrNoModule { }
