import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setShowAddBuyer } from 'src/app/redux/actions/options.action';
import { setEditAll, setIndexToEditValidation, setIsOrderReturned, setOrdersSelected, setValidationOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { StructBuyerProform, buyerIsExchanger, canYouBuyCommodities, canYouBuyHughes, isValidOrderByBuyer } from 'src/app/shared/business-validation';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { orderOriginConst, orderStatusConst } from 'src/app/shared/const/orders';
import { COMMODITIES_CODE, COMMODITIES_CODE_SELLER, HUGHES_CODE_BUYER, HUGHES_CODE_SELLER } from 'src/app/shared/const/buyers';
import { typeCoins } from 'src/app/shared/const/prices.const';
import { orderType } from 'src/app/shared/dict/orders';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { OrderValidator } from 'src/app/shared/order';
import { FilterWithOutContractStatus } from 'src/app/shared/pipes/filter/filterWithOutContractStatus.pipe';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { GROUPING_CODE_TO_PRICE } from 'src/app/shared/dict/grouping-code';
import { ACT_PROFORM } from '../../form/form-proform-buyer/form-proform-buyer.component';
import { conditionType, isProduction } from 'src/app/shared/const/options';

@Component({
  selector: 'app-add-buyer',
  templateUrl: './add-buyer.component.html',
  styleUrls: ['./add-buyer.component.scss', './../slide-card.scss']
})
export class AddBuyerComponent implements OnInit, OnDestroy{
  user!:User;

  orders:Order[]=[];
  ordersSelected:Order[]=[];

  ordersCheck:Order[]=[];
  isClicked:boolean=false;
  buyerSelectedField:number=-1;

  typeDolarSelectedField:number=-1;
  grouperCodeSelectedField:number=-1;

  //Acordion
  indexToOpen:number=-1;

  //Detect click
  wasInside = false;
  count=0;
  clickInClose=false;
  
  //CONST
  SELLERS:any=sellerWithData;
  ORDER_TYPES:any=orderType;

  //orderToChangeWithProform!:Order; //Orden antes de cambiar las cosas
  
  orderToAnalizateProform!:Order|null;
  orderOriginal!:Order|null;
  //Se actualiza con las siguientes funciones: selectAllOrders, getOrdersCheck, addOrRemoveOrder
  structBuyer!:StructBuyerProform|null;
  actionProform:string='';


  private orders$!: Subscription;
  private ordersSelected$!: Subscription;
  private user$!: Subscription;
  constructor(private store:Store<appState>, private router:Router, private orderV:OrderValidator,
    private IBM:ContractChecker) {}

  ngOnInit(): void {

    this.orders$=this.store.select('order','orderBoard','all').subscribe(orders=>{
      this.orders=orders;
    })

    this.ordersSelected$=this.store.select('order','edit','ordersEdit').subscribe(orders=>{
      this.ordersSelected=orders;
    })

    this.user$=this.store.select('usuario').subscribe(userR=>{
      this.user=userR;
    })


    //Seteamos en true para no cambiar el estado mientras está agregando un comprador
    this.store.dispatch(setIsOrderReturned({isOrderReturned:true}));
    
    this.getOrdersCheck();
  }

  ngOnDestroy():void{
    this.orders$?.unsubscribe();
    this.ordersSelected$?.unsubscribe();
    this.user$?.unsubscribe();
    
    //Seteamos en false para volver a cambiar el estado mientras está agregando un comprador
    this.store.dispatch(setIsOrderReturned({isOrderReturned:false}));
  }

  checkClose(ordersSelected:Order[], isLoader:boolean){
    if(ordersSelected.length == 0 && !isLoader){
      this.closeAddBuyer();
    }
  }

  isOrderCheck(order:Order){
    return this.orderV.isOrderInOrderList(order, this.ordersCheck);
  }

  showTypeDolar(){
    const quantOrdersCheck=this.ordersCheck.length;
    if(quantOrdersCheck==0){ return true;}
    try{
      for(let i=0; i<quantOrdersCheck; i++){
        //Si es en dolares o tiene indicadores, pide que se seleccione el tipo de dolar
        if(this.ordersCheck[i]?.price?.typeCoin==typeCoins.USD || this.ordersCheck[i]?.price?.indicators?.length > 0 ){
          throw new Error();
        }
      }
      return false;
    } catch(err){
      return true;
    }
  }

  getSellerData(order:Order){ 
    return this.orderV.getSellerNameAndTons(order);
  }

  addOrRemoveOrder(order:Order){
    let indexOfOrder=this.orderV.indexOfOrder(this.ordersCheck, order);
    if(indexOfOrder != -1){
      this.ordersCheck.splice(indexOfOrder, 1);
    } else {
      this.ordersCheck.push(order);
    }
    this.setOrderToProform();
  }

  getStringOrders(number:number){
    return number==1? '1 orden seleccionada': (number + ' ordenes seleccionadas');
  }

  setIndexToOpen(index:number){
    this.indexToOpen= (this.indexToOpen==index? -1: index);
  }

  getArrowDirection(index:number){
    return getArrowDirectionByBool(this.indexToOpen==index);
  }

  showMessageEdit:boolean=false;
  goToValidations(){
    if(this.ordersCheck.length>0){
      this.showMessageEdit=false;
      let validationOrders=this.getValidationsOrderWithFields(this.ordersCheck, this.buyerSelectedField, this.typeDolarSelectedField, this.grouperCodeSelectedField);
      this.store.dispatch(setValidationOrdersToGenerate({orders: validationOrders}));
      this.store.dispatch(setIndexToEditValidation({indexToEdit: 0}));
  
      const url= "/" + NAVEGATION.ORDER_VALIDATION
      this.router.navigate([url]);
      this.closeAddBuyer();
    } else{
      this.showMessageEdit=true;
    }
  }

  //Dadas las ordenes seleccionadas y los campos que ya editó el usuario, 
  //setea los valores para que aparezcan completados en la sección de editar
  getValidationsOrderWithFields(orders:Order[], buyer:number, typeDolar:number, grouperCode:number){
    let validationsOrders:Order[]=[];
    orders.forEach(order=>{
      let validationOrder:Order=JSON.parse(JSON.stringify(order));
      validationOrder.price.observations.buyer=buyer;
      validationOrder.price.typeDolar=typeDolar;
      validationOrder.price.grouperCode=grouperCode;
      validationsOrders.push(validationOrder);
    })
    return validationsOrders;
  }

  applyChanges(){
    this.isClicked=true;
    //Edit orders
    if(this.buyerSelectedField!=-1 && this.ordersCheck.length>0){
      this.editOrdersChecks();
    }
    
    if(this.ordersSelected.length == 0){
      this.closeAddBuyer();
    }
  }

  editOrdersChecks(){
    let ordersEdit:Order[]=[];
    let newValidationsOrdersSelected:Order[]=JSON.parse(JSON.stringify(this.ordersSelected));
    let nroOfCall=1;
    this.ordersCheck.forEach(order=>{

      let orderEdit:Order=JSON.parse(JSON.stringify(order)); //Parseamos order
      const sellerCode=orderEdit.sellerData.codeS;
      orderEdit.price.observations.buyer=this.buyerSelectedField; //Editamos buyer
      orderEdit.price.typeDolar=this.typeDolarSelectedField; //Editamos typeDolar
      orderEdit.price.grouperCode=this.grouperCodeSelectedField; //Editamos codigo agrupador

      orderEdit=this.editOrderByActionAndBuyer(orderEdit, this.orderToAnalizateProform, this.actionProform);


      //Guardamos el estado de la orden
      orderEdit.statusOrder= orderStatusConst.CONFIRM;

      //Lo siguiente es el seteo de las comisiones que también debe ejecutarse cuando editan una orden
      //está en el componente de details-validation/details.component.ts

      //Si el vendedor es commodities, la comisión vendedora es 0
      if(sellerCode==COMMODITIES_CODE_SELLER || sellerCode==HUGHES_CODE_SELLER){
        orderEdit.commision.seller=-1; //Editamos la condición vendedora si es Commodities o Hughes
      }

      if(this.buyerSelectedField==COMMODITIES_CODE || this.buyerSelectedField==HUGHES_CODE_BUYER){
        //Si es commodities, la comisión compradora es 0
        orderEdit.commision.buyer=-1;
        orderEdit.price.observations.isPort=-1;

        const commisionSeller= this.orderV.getCommisionByOrder(orderEdit, 'V');
        orderEdit.commision.seller=commisionSeller.seller<=0? -1: commisionSeller.seller;

        nroOfCall=1; //Se llama a IBM y el estado es AUTORIZACION
      } else {
        if(buyerIsExchanger(orderEdit.price.observations.buyer)){
          orderEdit.commision.buyer=-1;
        } else {
          const commisionBuyer= this.orderV.getCommisionByOrder(orderEdit, 'C');
          const commisionSeller= this.orderV.getCommisionByOrder(orderEdit, 'V');
          orderEdit.commision.buyer=commisionBuyer.buyer;
          orderEdit.commision.seller=commisionSeller.seller;
        }

        nroOfCall=2; //Se llama a IBM y el estado es CONTRATO
        orderEdit.price.observations.isPort=1;
      }
      
      ordersEdit.push(orderEdit); //Añadimos a la lista de orders editados

      //Quitamos la orden de las ordenes validadas;
      let indexOrder=this.orderV.indexOfOrder(newValidationsOrdersSelected, order);
      if(indexOrder!=-1){
        newValidationsOrdersSelected.splice(indexOrder, 1);
      }
    })

    this.IBM.createContracts(ordersEdit,nroOfCall, this.user); //ACTUALIZATE
    this.store.dispatch(setValidationOrdersToGenerate({orders: newValidationsOrdersSelected}));
    this.store.dispatch(setOrdersSelected({orders:[]}))
    this.ordersCheck=[];
    this.isClicked=false;
  }

  closeAddBuyer(){
    this.clickInClose=true;
    this.count=0;
    this.store.dispatch(setShowAddBuyer({isShowAddBuyer:false}));
    this.store.dispatch(setEditAll({editAll: false}));
  }

  saveBuyerSelected(event:any){
    const index= Number(event);
    this.buyerSelectedField=index;
    this.actualizateStructBuyer(this.orderToAnalizateProform);
  }

  saveNewOrderWithProform(event:{action:string, newOrder:Order|null}){
    this.actionProform=event.action;

    if(this.actionProform==ACT_PROFORM.IGNORE){
      this.actualizateStructBuyer(this.orderOriginal, true);
    }
    //this.orderProform=event.newOrder;
  }

  saveDolarTypeSelected(event:any){
    const index= Number(event);
    this.typeDolarSelectedField=index;
  }

  saveGrouperCodeSelected(event:any){
    const index= Number(event);
    this.grouperCodeSelectedField=index;
  }

  getBuyerSelected(){
    if(this.buyerSelectedField<=0){
      try{
        const isPort=this.ordersCheck[0].price.observations.isPort == 1;
        const onlySell=this.ordersCheck[0].buyer.onlySell[0];
        const buyer=this.ordersCheck[0].price.observations.buyer;
        const isOrderManual=this.ordersCheck[0].orderDestination.orderOrigin==orderOriginConst.manual;
        const sellerIsCommodities=this.ordersCheck[0].sellerData.codeS==COMMODITIES_CODE_SELLER;
        
        if(onlySell>0){
          //Si tiene only sell
          this.buyerSelectedField=onlySell;
        } else if(buyer>0) {
          //Si tiene buyer
          this.buyerSelectedField=buyer;
        } else if(isPort && buyer<=0 || sellerIsCommodities || isOrderManual){
          //Si tiene puerto y no tiene buyer o el vendedor es Commodities o es MANUAL
          this.buyerSelectedField=-1;
          } else {
          //Si no es nada, entonces se asigna commodities como default
          this.buyerSelectedField=COMMODITIES_CODE;
        }
      } catch(err){
      }
    }

    return this.buyerSelectedField;
  }

  getDolarTypeSelected(){
    if(this.typeDolarSelectedField<= 0){
      this.typeDolarSelectedField=1;
    }
    return this.typeDolarSelectedField;
  }

  getGrouperCodeSelected(){
    if(this.grouperCodeSelectedField<=0){
      try{
        const grouperCode=this.ordersCheck[0].price.grouperCode;
        const isToFix=this.ordersCheck[0]?.price?.indicators?.length>0;
        
        //Ponemos el que tiene por defecto
        //Si no tiene, no ponemos ninguno
        if(isToFix){
          this.grouperCodeSelectedField=grouperCode>0 && !Object.keys(GROUPING_CODE_TO_PRICE).includes(grouperCode.toString())? grouperCode:-1; //Si es a fijar, completa el operador
        } else if(grouperCode>0){
          this.grouperCodeSelectedField=grouperCode;
        } else {
          this.grouperCodeSelectedField=1;
        }
      } catch(er){}
    }
    return this.grouperCodeSelectedField;
  }

  getOrdersCheck(){
    setTimeout(() => {
      if(this.ordersSelected.length==1){
        this.ordersCheck=[]
        this.addOrRemoveOrder(this.ordersSelected[0]);
    }
  }, 100);
  }

  isAllChecked(){
    return this.ordersCheck?.length==this.getOrdersSelectedWithPipe()?.length;
  }

  getOrdersSelectedWithPipe(){
    const pipe = new FilterWithOutContractStatus();
    return pipe.transform(this.ordersSelected);
  }

  selectAllOrders(){
    this.ordersCheck= this.isAllChecked()? []: this.getOrdersSelectedWithPipe();
    this.setOrderToProform();
  }

  setOrderToProform(){
    const order=this.ordersCheck.length>0? this.ordersCheck[0]: null;
    this.actualizateStructBuyer(order);
    this.orderOriginal=order;
  }

  actualizateStructBuyer(newOrder:Order|null, notReiniciateAction?:boolean){
    if(!this.isProductionFunc()){
      this.orderToAnalizateProform=newOrder;
      if(this.orderToAnalizateProform){
        this.structBuyer=isValidOrderByBuyer(this.orderToAnalizateProform, this.buyerSelectedField);
        this.actionProform= notReiniciateAction? this.actionProform:'';
      }
    }
  }

  /**
   * Given an order, an order with the proformas applied and an action.
   * We analyze the action. If it is autocorrect, we assign the values of the proformas. 
   * If it is ignore, we do nothing.
   * @param order 
   * @param orderProform 
   * @param action 
   * @returns order
   */
  editOrderByActionAndBuyer(order:Order, orderProform:Order|null, action:string):Order{ 
    let orderEdit:Order=JSON.parse(JSON.stringify(order));
    if(!this.isProductionFunc() && action==ACT_PROFORM.AUTOCORRECT && orderProform){
      orderEdit.price.quality=orderProform.price.quality;
      orderEdit.price.placeOfDelivery=orderProform.price.placeOfDelivery;
      orderEdit.price.wayPay=orderProform.price.wayPay;
    }

    return orderEdit;
  }


  isDisabledButton(){
    if(this.buyerSelectedField==COMMODITIES_CODE){
      let flag=0;
      this.ordersCheck.forEach(order=>{
        if(!canYouBuyCommodities(order)){
          flag++;
        }
      });

      return flag>0;

    } else if(this.buyerSelectedField==HUGHES_CODE_BUYER){
      let flag=0;
      this.ordersCheck.forEach(order=>{
        if(!canYouBuyHughes(order)){
          flag++;
        }
      });

      return flag>0;
      
    }else {
      return false;
    }
  }

  isInvalidOrderWithBuyer(){
    let flag=0;
    this.ordersCheck.forEach(order=>{
      if(!isValidOrderByBuyer(order, this.buyerSelectedField)){
        flag++;
      }
    });

    return flag>0;
  }

  isExensionDeIVAWithCommodities(){
    let flag=0;
    this.ordersCheck.forEach(order=>{
      if((order.price.businessParticularities.respectVatException==1 && this.isCommoditiesOrHughes()) ){
        flag++;
      }
    });

    return flag>0;
  }

  isCommoditiesOrHughes(){
    return this.buyerSelectedField==COMMODITIES_CODE || this.buyerSelectedField==HUGHES_CODE_BUYER;
  }

  isButtonDisabled(){
    return this.ordersCheck.length==0 || //Si no seleccionó ninguna orden
    this.buyerSelectedField<=0 || //Si no seleccionó comprador
    this.typeDolarSelectedField<=0 || //Si no seleccionó tipo de dolar
    this.isDisabledButton() || //Si alguna orden de las seleccionadas es invalida
    this.isExensionDeIVAWithCommodities() || //Si tiene exensión de IVA y compra Commodities
    this.grouperCodeSelectedField<0 || //Si no seleccionó código agrupador.
    (!this.isProductionFunc() ? this.structBuyer && !this.actionProform: false) 
    //Si no todavía no realizó una acción en el ambiente desarrollo
  }

  isProductionFunc(){
    return isProduction();
  }
}