import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  resetViewSelected,
  setAllViews,
  setFilterPrice,
  setIndexViewSelected,
  setViewSelected,
} from 'src/app/redux/actions/price.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { VIEW_PRICE_ALL, VIEW_PRICE_FIELDS, VIEW_PRICE_VOID } from 'src/app/shared/const/views.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { View } from '../view';
import { PriceString } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'views-prices',
  templateUrl: './views-prices.component.html',
  styleUrls: ['./../views.component.scss'],
})
export class ViewsPricesComponent extends View implements OnInit,OnDestroy {
  @Input() override view: ViewPrice = VIEW_PRICE_VOID;
  @Input() override arrayOfViews: ViewPrice[] = [];
  VIEW_PRICE_FIELDS=VIEW_PRICE_FIELDS;

  private price$!:Subscription;
  constructor(store: Store<appState>) {
    super(store);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.price$=this.store.select('price').subscribe((priceReducer) => {
      this.arrayOfViews = priceReducer.viewsOfPriceBoard.views;
      this.indexToEditInStore = priceReducer.viewsOfPriceBoard.indexViewSelected;
      if(this.indexToEditInStore==this.indexToEdit) this.wasInside=true;
    });
  }
  
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.price$?.unsubscribe();
  }

  override getViewAll(): ViewPrice {
    let newView=JSON.parse(JSON.stringify(VIEW_PRICE_ALL))
    delete newView.viewName;
    delete newView.priceString;
    delete newView.isHistorical;
    return newView;
  }

  override getViewVoid(): ViewPrice {
    let newView=JSON.parse(JSON.stringify(VIEW_PRICE_VOID))
    delete newView.viewName;
    delete newView.priceString;
    delete newView.isHistorical;
    return newView;
  }

  override createView() {
    if(this.isInvalidCreateView()){
      this.isButtonClicked=true
    } else {
      const viewsStr:string=localStorage.getItem(this.getLocalStorageName()) || '';

      this.view.isHistorical=this.isHistorical;
      if (this.identificator == 1) {
        
        let views:ViewPrice[]=viewsStr? JSON.parse(viewsStr): [];
        views.push(this.view);

  
        this.isApplyButton=true;
        localStorage.setItem(this.getLocalStorageName(), JSON.stringify(views));
        this.setInStore(views, -2, this.view, this.view.priceString);

        this.resetView('price');
      }
    }   
  }

  override notClickedNothing(): boolean {
    let viewParse=JSON.parse(JSON.stringify(this.view));
    delete viewParse.viewName;
    delete viewParse.priceString;
    delete viewParse.isHistorical;
    return Object.values(viewParse).every((value:any)=>value==false);
  }

  override deleteView() {
    const arrayModified = this.arrayOfViews.filter( (view:any) => this.isHistorical==view.isHistorical);
    const restantes = this.arrayOfViews.filter( (view:any) => this.isHistorical!=view.isHistorical);

    arrayModified.splice(this.indexToEdit, 1);

    const arrayComplete = [...arrayModified, ...restantes];

    localStorage.setItem(this.getLocalStorageName(), JSON.stringify(arrayComplete));

    this.setInStore(arrayComplete, -2);
  }

  override editView() {
    const arrayModified = this.arrayOfViews.filter( (view:any) => this.isHistorical==view.isHistorical);
    const restantes = this.arrayOfViews.filter( (view:any) => this.isHistorical!=view.isHistorical);

    arrayModified[this.indexToEdit] = this.view;

    const arrayComplete = [...arrayModified, ...restantes];

    localStorage.setItem(this.getLocalStorageName(), JSON.stringify(arrayComplete));
    this.isApplyButton=true;

    this.setInStore(arrayComplete, -2, this.view, this.view.priceString);
  }

  override setIndexViewSelected(index: number): void {
    this.store.dispatch(setIndexViewSelected({ index: index }));
  }

  override setInStore(views:ViewPrice[], index:number, view:ViewPrice|null=null, filter:PriceString|null=null){
    this.store.dispatch(setAllViews({ views: views }));
    this.store.dispatch(view? setViewSelected({ view: view }): resetViewSelected());
    this.store.dispatch(setIndexViewSelected({ index: index }));
    filter? this.store.dispatch(setFilterPrice({priceString: filter})): '';

    this.saveInDB(views);
  }

  override getLocalStorageName(): string {
    return localStorageNames.viewPrices;
  }
}