import { Component, ElementRef, HostListener, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { goToInitForm } from 'src/app/redux/actions/options.action';
import { setIndexToEdit, setNumOfDuplicateOrders, setOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';

@Component({
  selector: 'app-duplicate-orders',
  templateUrl: './duplicate-orders.component.html',
  styleUrls: [ './../card.scss', ],
})
export class DuplicateOrdersComponent implements OnInit, OnDestroy {
  order!: Order;
  value!: number;

  //Detect click
  wasInside: boolean = false;
  count: number = 0;
  @Output() close = new EventEmitter<string>();

  private orderSubscription!: Subscription;
  constructor(private store: Store<appState>, private eRef: ElementRef) {}

  ngOnInit(): void {
    this.wasInside = true;
    
    this.orderSubscription = this.store.select('order').subscribe((orderReducer) => {
      this.order = orderReducer.generateOrder;
    });
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe();
  }

  setValueToDuplicateOrder(event: any) {
    this.value = Number(event);
  }

  setNumberToDuplicate() {
    this.store.dispatch(
      setNumOfDuplicateOrders({ numOfDuplicateOrders: this.value + 1 })
    );

    let orders = [this.order];
    for (let i = 0; i < this.value; i++) {
      orders.push(this.order);
    }

    this.store.dispatch(setOrdersToGenerate({ orders: orders }));
    this.store.dispatch(setIndexToEdit({ indexToEdit: 1 })); //We stop at the first to edit

    this.store.dispatch(goToInitForm({ goToInitForm: true })); //When the orders are duplicated, we go to the beginning of the form


    this.emit('close');
  }


  text: string = '';
  @HostListener('document:click', ['$event']) clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target) || this.wasInside) {
      this.wasInside = false;
      this.emit('open');
    } else {
      this.emit('close')
      this.wasInside = false;
    }
  }

  emit(value: string) {
    this.close.emit(value);
  }
}
