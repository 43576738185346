import { Component, Input, OnInit } from '@angular/core';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { InputE } from '../input';

@Component({
  selector: 'text-input',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss','../input.scss']
})
export class TextComponent extends InputE implements OnInit {

  constructor(private validator: ValidatorChecker) {
    super()
  }

  validateInputText(event:any){
    if(event.key.toLowerCase()=='enter'){
      this.select(event);
      return true;
    } else {
      //Only accept numbers or point
      return this.validator.isAlphanumeric(event.charCode) || (event.charCode>=32 && event.charCode<=63);
    }
  }

  override select(event:any){
    const text=event.target.value;
    this.emit(text);
    this.setValue(text);
  }

  override getValue() {
    try{
      return this.value.length>0 && this.value!=' '?this.value:'';
    } catch {
      return '';
    }
  }

  override getIsInputCompleted() {
    try{
      return this.value.length > 0
    } catch {
      return false;
    }
  }
}
