<div class="field">
    <div class="input-date" [ngClass]="{'border-green': getIsInputCompleted(), 'is-incomplete':isIncomplete}">  
        <input type="text" readonly class="no-background-border" [placeholder]="initialPlaceHolder" [value]="getValue()"/>

        <input readonly matInput [matDatepicker]="dp" class="no-background-border"
        autocomplete="off" style="visibility: hidden; height: 0px;" [min]="getDateMin()">
        <mat-datepicker-toggle matIconSuffix [for]="dp" class="logo"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (monthSelected)="setMonthAndYearAux($event, dp)"
                        panelClass="example-month-picker">
        </mat-datepicker>
    </div>
</div>