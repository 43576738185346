import { Component, OnInit,Input,Output } from '@angular/core';
import { InputE } from '../input';

interface OptionRadioButton {
  value: any;
  name: string;
}

@Component({
  selector: 'radio-button-input',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss']
})
export class RadioButtonInputComponent extends InputE {
  @Input() public options:OptionRadioButton[]=[];
  constructor() {
    super()
  }

  isSelected(value:string){
    return this.value==value;
  }

  
}
