import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyOrdersValidationComponent } from './body-orders-validation.component';
import { DetailsValidationModule } from 'src/app/components/details/details-validation/details.module';
import { FormValidationModule } from 'src/app/components/form/form-validation/form.module';

import { DialogEraserCreateModule } from 'src/app/components/dialogs/eraser-create/eraser-create.module';
import { DialogContractCreateModule } from 'src/app/components/dialogs/contract-create/contract-create.module';
import { DialogContractFailedModule } from 'src/app/components/dialogs/contract-failed/contract-failed.module';

@NgModule({
  declarations: [
    BodyOrdersValidationComponent
  ],
  imports: [
    CommonModule,
    DetailsValidationModule,
    FormValidationModule,

    DialogContractCreateModule,
    DialogEraserCreateModule,
    DialogContractFailedModule
  ], exports:[
    BodyOrdersValidationComponent
  ]
})
export class BodyOrdersValidationModule { }
