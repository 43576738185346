import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Subscription } from 'rxjs';
import { setHistorical } from 'src/app/redux/actions/price.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { PriceValidator } from 'src/app/shared/price';
import { HistoricalComponent } from '../historical.component';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';

@Component({
  selector: 'app-price-historical',
  templateUrl: './../historical.component.html',
  styleUrls: ['./../historical.component.scss', './../../slide-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceHistoricalComponent extends HistoricalComponent implements OnInit, OnDestroy {
  private price$!: Subscription;

  constructor(private store: Store<appState>, private priceV: PriceValidator,private cdr: ChangeDetectorRef) {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.price$ = this.store.select('price', 'historical').subscribe(historical => {
      this.historicalId = historical;
      if (this.historicalId) {
        this.isShow = true;
        if (this.historicalId != this.historicalIdAux) {
          this.getHistorical();
        }
      } else {
        this.isShow = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.price$?.unsubscribe();
  }

  getHistorical() {          
    this.messageInfo='Cargando...';

    this.priceV.getHistorical(this.historicalId).pipe(
      catchError( error => {
        this.historicalArray=[];
        this.messageInfo='Ocurrion un error al cargar el historial';
        return []
      })
    ).subscribe( (res:ResponseRequest) => {
      this.messageInfo='';
      this.historicalArray = res.data ? res.data.reverse() : [];
      
      this.cdr.detectChanges();
    });
  }

  override close(): void {
    this.store.dispatch(setHistorical({ historical: '' }));
    this.historicalIdAux = '';
    this.historicalArray = [];
    this.clickInClose = true;
    this.count = 0;
  };

  override open(): void {
    this.historicalIdAux = JSON.parse(JSON.stringify(this.historicalId))
    this.store.dispatch(setHistorical({ historical: this.historicalIdAux }));
  }
}