import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceOfOriginComponent } from './place-of-origin.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ProvinceComponent } from './province/province.component';
import { TownComponent } from './town/town.component';

@NgModule({
  declarations: [
    PlaceOfOriginComponent,
    ProvinceComponent,
    TownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule
  ], exports:[
    PlaceOfOriginComponent,
    ProvinceComponent,
    TownComponent
  ]
})
export class PlaceOfOriginModule { }
