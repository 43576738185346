import { Action, createReducer, on } from "@ngrx/store";
import { VIEW_PRICE_ALL, VIEW_PRICE_VOID } from "src/app/shared/const/views.const";
import { initialPrice, initialPriceString } from "src/app/shared/const/prices.const";
import { PriceReducer } from "src/app/shared/interfaces/price.interface";
import * as actions from '../actions/price.action';
import { buyerType } from "src/app/shared/const/options";
import { INDICATOR_VOID } from "src/app/shared/const/to-fix";

export const initialState: PriceReducer = {
    priceBoard: {
        all: [],
        allWithPipes: [],
        buyerType: buyerType.COMMODITIES,
        filter: initialPriceString,
        pricesSelected: [],
        indexFilter: -1,
        priceDetails: initialPrice,
        showPriceDetails: false,
        showEditIndicators: false,
        indicatorToEdit: INDICATOR_VOID,
        conditionTypeToEdit: -1,
        indexIndicatorToEdit: -1,
        arrayOfIdsSelecteds: []
    },
    generatePrice: initialPrice,
    arrayOfBuyers: [],
    viewsOfPriceBoard: {
        views: [],
        newView: VIEW_PRICE_VOID,
        indexViewSelected: -2,
        viewSelected: {
            ...VIEW_PRICE_ALL,
            viewName: 'default',
        }
    },
    historical: ''
};

const _priceReducer = createReducer(
    initialState,
    on(actions.loadPricesAllSuccess, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                all: action.prices
            }

        };
    }),
    on(actions.loadPricesWithPipesSuccess, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                allWithPipes: action.prices
            }

        };
    }),

    //Price filter
    on(actions.setFilterPrice, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                filter: action.priceString
            }
        }
    }),

    //Price to generate
    on(actions.setArrayOfIdsSelecteds, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                arrayOfIdsSelecteds: action.ids
            }
        }
    }),

     ////----------------------FILTERS------------------------\\\\
    
     on(actions.setFilterPrice, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                filter: action.priceString
            }

        };
    }),
    on(actions.setBuyerTypeFilter, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                buyerType: action.buyerType
            }

        };
    }),
    on(actions.setFilterIndex, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                indexFilter: action.index
            }
        };
    }),
    //Price to generate
    on(actions.setPriceToGenerate, (state, action) => {
        return {
            ...state,
            generatePrice: action.price
        }
    }),
    on(actions.setArrayOfBuyers, (state, action) => {
        return {
            ...state,
            arrayOfBuyers: action.buyers
        }
    }),

    on(actions.resetPriceToGenerate, (state, action) => {
        return {
            ...state,
            generatePrice: initialState.generatePrice,
            arrayOfBuyers: initialState.arrayOfBuyers
        }
    }),
    //--------------------------VIEWS----------------------\\
    on(actions.setViewToGenerate, (state, action) => {
        return {
            ...state,
            viewsOfPriceBoard: {
                ...state.viewsOfPriceBoard,
                newView: action.view
            }
        }
    }),
    on(actions.resetNewView, (state, action) => {
        return {
            ...state,
            viewsOfPriceBoard: {
                ...state.viewsOfPriceBoard,
                newView: initialState.viewsOfPriceBoard.newView
            }
        }
    }),
    on(actions.setViewSelected, (state, action) => {
        return {
            ...state,
            viewsOfPriceBoard: {
                ...state.viewsOfPriceBoard,
                viewSelected: action.view
            }
        }
    }),
    on(actions.resetViewSelected, (state, action) => {
        return {
            ...state,
            viewsOfPriceBoard: {
                ...state.viewsOfPriceBoard,
                viewSelected: initialState.viewsOfPriceBoard.viewSelected
            }
        }
    }),
    on(actions.setAllViews, (state, action) => {
        return {
            ...state,
            viewsOfPriceBoard: {
                ...state.viewsOfPriceBoard,
                views: action.views
            }
        }
    }),
    on(actions.setIndexViewSelected, (state, action) => {
        return {
            ...state,
            viewsOfPriceBoard: {
                ...state.viewsOfPriceBoard,
                indexViewSelected: action.index
            }
        }
    }),

    //Select prices
    on(actions.setPricesSelected, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                pricesSelected:action.prices
            }
        }
    }),

    //Price details
    on(actions.setPriceDetails, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                priceDetails: action.price
            }
        }
    }),
    on(actions.showPriceDetails, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                showPriceDetails: action.show
            }
        }
    }),
    on(actions.showEditIndicators, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                showEditIndicators: action.show
            }
        }
    }),

    on(actions.setEditIndicator, (state, action) => {
        return {
            ...state,
            priceBoard: {
                ...state.priceBoard,
                indicatorToEdit: action.indicator,
                conditionTypeToEdit:action.conditionType,
                indexIndicatorToEdit:action.index
            }
        }
    }),


    //Historical
    on(actions.setHistorical, (state, action) => {
        return {
            ...state,
            historical: action.historical
        }
    }),
)


export function priceReducer(state: any, action: Action) {
    return _priceReducer(state, action);
}

