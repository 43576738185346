<div class="option">
    <div class="option-content-particularity pointer"
    (click)="showOptions(7)"
    (click)="setDefaultEventType()" >
        <span class="option-title">Flete a cargo del comprador</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" 
            (change)="setOptionOpenWithCheck($event,7)" 
            (click)="setIsFleteACargoDelComprador()"
            [checked]="getIsFreightPaidByTheBuyer()"/>
        </div>
    </div>


    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="option-content-particularity">
            <span class="option-name">Flete del cliente</span>
            <div class="actions">
                <input type="checkbox" [checked]="isCustomerFreight" (click)="setCustomerFreight()" class="radio" />
            </div>
        </div>

        <div class="field">

            <span class="field-title">Tarifa<span class="asterisk">*</span></span>

            <radio-button-input 
            [options]="getRatesOptions()" 
            (valueOut)="setRatesType($event)"
            [value]="getRatesType()"></radio-button-input>
        </div>

        <app-amount (amountToSave)="setAmount($event)" 
        [amount]="freightPaidByTheBuyer.amount"
        *ngIf="!isRateToDefine()"></app-amount>

        <div class="field" style="margin-bottom: 10px;">
            <span class="field-title">Tipo de evento<span class="asterisk">*</span></span>
        
            <select-input 
            [placeHolder]="'Seleccionar evento'"
            [value]="getEventType()"
            [array]="getEventsTypesArray()"
            [OBJECT]="getEventsTypesObject()"
            [isIncomplete]="false"
            (valueOut)="setEventType($event)"
            ></select-input>
        </div>

        <div class="field" style="margin-bottom: 10px;">
            <span class="field-title">Cantidad de días<span class="asterisk">*</span></span>
        
            <div>
                <!-- [ngClass]="{'not-selected': !isOptionSelected, 'border-green': isOptionSelected}" -->
                <number-input [initialPlaceHolder]="'Ingresar días'" [value]="freightPaidByTheBuyer.numberOfDays"
                (valueOut)="setNumOfDays($event)"></number-input>
            </div>
        </div>


    </div>
</div>