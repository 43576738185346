import { Component, OnInit,OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { setIsClickedInConfirm } from 'src/app/redux/actions/options.action';
import { setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { Order } from 'src/app/shared/interfaces/order.interface';
import {isCaseSyngentaAgroAvc} from 'src/app/redux/actions/options.action';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { PriceValidator } from 'src/app/shared/price';
import { DialogOrderExchangeCreate } from '../../dialogs/order-exchange-create/dialog';
import { DialogOrderToBolets } from '../../dialogs/order-to-bolets/dialog';
import { FormComponent } from '../form.component';
import { Subscription } from 'rxjs';
import { BUSINESS_PARTICULARITIES_VOID } from 'src/app/shared/const/business-particularities';
import { WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { SessionChecker } from 'src/app/shared/session.checker';
import { isIndicatorsIncomplete } from 'src/app/shared/to-fix';
import { isInvalidStartDate } from 'src/app/shared/date.checker';
import { SELLER_DATA_VOID } from 'src/app/shared/const/orders';
import { setExchangeInOrderToGenerate } from 'src/app/redux/actions/exchange.action';

@Component({
  selector: 'app-form-exchange',
  templateUrl: './form.component.html',
  styleUrls: [ './../form.component.scss']
})
export class FormOrderExchangeComponent extends FormComponent implements OnInit,OnDestroy {
  isSyngentaBuyerAVC:boolean=false;
  
  incompleteRequirePort:boolean=false;


  private exchangeAux$!:Subscription;
  private codeS$!:Subscription;
  private subdivisionSyngenta$!:Subscription;

  constructor(store:Store<appState>,orderExcV:OrderExchangeValidator, orderV:OrderValidator, priceV:PriceValidator,  dialog: MatDialog, checker:SessionChecker) { 
    super(store, checker, orderExcV, orderV, priceV, dialog);

    this.exchangeAux$=this.store.select('exchange').subscribe(exchangeReducer => {
      this.order = exchangeReducer.generateOrder;
      this.isSyngentaBuyerAVC=this.orderV.isSyngentaAgroAvc(this.order.sellerData.codeS, this.order.price.businessParticularities.subdivisionSyngenta, this.order.price.businessParticularities.isAVC);
      this.store.dispatch(isCaseSyngentaAgroAvc({isCaseSyngentaAgroAvc: this.isSyngentaBuyerAVC}));

      this.disabledButton = this.isDisabledOrder();
    })

    //Analizamos cada vez que cambia el valor el codeS. En ese punto es momento de volver a asignar un valor default a agreedDiscount
    this.codeS$=this.store.select('exchange', 'generateOrder', 'sellerData', 'codeS').subscribe(codeS => {
      this.agreedDiscountDefault= this.orderExcV.getExchangeInitial(this.order).agreedDiscount;
      this.store.dispatch(setExchangeInOrderToGenerate({exchange: {...this.order.exchange, agreedDiscount: this.agreedDiscountDefault}}));
    })

    //Analizamos cada vez que cambia el valor el subdivisionSyngenta. En ese punto es momento de volver a asignar un valor default a agreedDiscount
    this.codeS$=this.store.select('exchange', 'generateOrder', 'price', 'businessParticularities', 'subdivisionSyngenta').subscribe(subdivisionSyngenta => {
      this.agreedDiscountDefault= this.orderExcV.getExchangeInitial(this.order).agreedDiscount;      
      this.store.dispatch(setExchangeInOrderToGenerate({exchange: {...this.order.exchange, agreedDiscount: this.agreedDiscountDefault}}));
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.exchangeAux$?.unsubscribe();
    this.codeS$?.unsubscribe();
    this.subdivisionSyngenta$?.unsubscribe();
  }

  override deleteAll(numOfStep:number=0){
    let order:Order=JSON.parse(JSON.stringify(this.order));
    order.sellerData= SELLER_DATA_VOID;
    order.placeOfOrigin = {province: -1, town:-1, afip: -1};
    order.broke= 1;
    order.price.businessParticularities= BUSINESS_PARTICULARITIES_VOID;
    order.howSell= -1;
    order.buyer= {onlySell: -1, notSell: -1};
    order.tons= -1;
    order.quotas= "[]";
    order.price.typeBusiness= -1;
    order.price.productName= -1;
    order.price.price=-1;
    order.price.typeCoin='--';
    order.price.placeOfDelivery={port: -1, zone: -1};
    order.price.quality= -1;
    order.price.deliveryPeriod= {endDate: '--', startDate: '--'};
    order.price.harvest='--';
    order.price.wayPay= WAY_PAY_VOID;
    order.price.pesificacion={endDate: '--', startDate: '--'}
    order.price.conditionType= 0;
    order.price.indicators= [];
    this.store.dispatch(setOrderToGenerate({order:order}));
  }

  override deleteStep(numOfStep:number):void{
    let order:OrderExchange=JSON.parse(JSON.stringify(this.order));
    order.exchange.buyer= SELLER_DATA_VOID;
    order.exchange.placeOfOrigin = {province: -1, town:-1, afip: -1};;
    order.exchange.howSell= -1;
    order.exchange.amountB= -1;
    order.exchange.percentageB= -1;
    this.store.dispatch(setOrderToGenerate({order:order}));
  } 

  override isDisabledOrder():boolean{
    if(this.isSyngentaBuyerAVC){
      return this.disableOrderToPrice(this.order);
    }
    return this.orderExcV.isExchangeIncomplete(this.order.exchange) || this.orderExcV.isPriceExchangeInvalid(this.order.exchange);
  }

  disableOrderToPrice(order:Order){
    return this.orderV.isOrderIncomplete(order) || this.priceV.isInvalidRequiredPort(order.price.placeOfDelivery);
  }

  confirmExchange(form:any){
    if(this.isDisabledOrder()){
      this.store.dispatch(setIsClickedInConfirm({isClickedInConfirm: true}));
      this.verifyElement(this.formulario);
    } else {
      if(this.isComercial){
        this.dialog.open(DialogOrderExchangeCreate); 
      } else {
        this.loadUser();
      } 
    }
  }

  override verifyElement(formulario:any){
    if( this.order.exchange.sellerData.codeS== -1 ||
        this.order.exchange.placeOfOrigin.province==-1 || this.order.placeOfOrigin.town==-1 || this.order.placeOfOrigin.afip==-1 ||
        this.order.exchange.howSell== -1){
        this.changeStep(1,formulario);
      }
  }

  override confirmOrder(formulario:any){
    if(this.disableOrderToPrice(this.order)){
      this.store.dispatch(setIsClickedInConfirm({isClickedInConfirm: true}));
      this.verifyElementSyngentaAgro(formulario);
    } else {
      if(this.isComercial){
        this.dialog.open(DialogOrderToBolets);
      } else {
        this.loadUser();
      }
    }
  }

  verifyElementSyngentaAgro(formulario:any){
      if(
        this.order.sellerData.codeS<= 0 ||
        this.order.placeOfOrigin.province<=0 || this.order.placeOfOrigin.town<=0 || this.order.placeOfOrigin.afip<=0 ||
        this.order.broke<= 0 ||
        this.order.howSell<= 0 ||
        this.order.tons<= 0){
          this.changeStep(1,formulario);
      } else if(
        this.order.price.typeBusiness<= 0 ||
        this.order.price.productName<= 0 ||
        (this.isGenerateToFix? false: this.order.price.price<=0 || this.order.price.typeCoin== '--') ||
        this.order.price.placeOfDelivery.zone<= 0 ||
        this.priceV.isInvalidRequiredPort(this.order.price.placeOfDelivery) ||
        this.order.price.quality<= 0
      ){
        this.incompleteRequirePort=this.priceV.isInvalidRequiredPort(this.order.price.placeOfDelivery);
        this.changeStep(2,formulario);
      } else if(
        this.order.price.deliveryPeriod.endDate== '--' ||
        this.order.price.deliveryPeriod.startDate== '--' ||
        isInvalidStartDate(this.order.price.deliveryPeriod.startDate) ||
        this.order.price.harvest== '--'
      ){
        this.changeStep(3,formulario);
      } else if(
        (this.order.price.indicators?.length>0? isIndicatorsIncomplete(this.order.price?.indicators, this.order.price?.conditionType):false)
      ){
        this.changeStep(4,formulario);
      }
  }
}