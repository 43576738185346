import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotasComponent } from './quotas.component';
import { DateInputModule } from '../../inputs/date/date.module';
import { NumberInputModule } from '../../inputs/number/number.module';

@NgModule({
  declarations: [
    QuotasComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule,
    DateInputModule
  ], exports:[
    QuotasComponent
  ]
})
export class QuotasModule { }
