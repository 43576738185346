import { Component, OnInit,OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Subscription } from 'rxjs';
import { setHistorical, showOrderDetails } from 'src/app/redux/actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderValidator } from 'src/app/shared/order';
import { HistoricalComponent } from '../historical.component';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { Historical } from 'src/app/shared/interfaces/historical.interface';
import { dateAndHourToDate } from 'src/app/shared/date.checker';
import { orderStatusConst, orderTags } from 'src/app/shared/const/orders';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';

@Component({
  selector: 'app-order-historical',
  templateUrl: './historical-order.component.html',
  styleUrls: ['./../historical.component.scss', './../../slide-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHistoricalComponent extends HistoricalComponent implements OnInit,OnDestroy{
  order!:Order|null;
  orders:Order[]=[];
  arrayHist:Order[]=[];

  private historical$!:Subscription;
  private orders$!:Subscription;
  private arrayHist$!:Subscription;
  constructor(private store:Store<appState>, private orderV:OrderValidator, private cdr: ChangeDetectorRef) { 
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.orders$=this.store.select('order', 'orderBoard','all').subscribe(orders=>{
      this.orders=orders;
    })

    this.arrayHist$=this.store.select('historicalArray','array').subscribe(array=>{
      this.arrayHist=array;
    });

    this.historical$=this.store.select('order', 'historical').subscribe(historical=>{
      this.historicalId=historical;

      this.order = this.orderV.searchOrderInArray(this.historicalId,this.orders);
      if(!this.order){ this.order=this.orderV.searchOrderInArray(this.historicalId,this.arrayHist);}

      if(this.historicalId){
        this.isShow=true;

        if(this.historicalId!=this.historicalIdAux){
          this.getHistorical();
        }
      } else {
        this.isShow=false;
      }
    })
  }

  ngOnDestroy(): void {
    this.historical$?.unsubscribe();
    this.orders$?.unsubscribe();
    this.arrayHist$?.unsubscribe();
  }

  getHistorical(){
    this.messageInfo='Cargando...';

    this.orderV.getHistorical(this.historicalId).pipe(
      catchError(error=>{
        this.historicalArray=[];
        this.messageInfo='Ocurrió un error al cargar el historial';
        this.cdr.detectChanges();
        return [];
      })
    ).subscribe((res:ResponseRequest)=>{
      this.historicalArray=this.buildArray(res.data ?? [], this.order);
      this.messageInfo='';
      this.cdr.detectChanges();
    });
  }

  /* Construimos un arreglo del historial de cambios */
  buildArray(historical:Historical[], order:Order|null){
    let copyHistorical=JSON.parse(JSON.stringify(historical));
    const array:Historical[]=[];

    //El primer elemento es la creación de la orden
    if(order){
      const nameOfCreatorParts = order.dataOfcreation.nameOfcreator.split(' ');

      const item:Historical={
        idPrice: '',
        idOrder: order._id,
        campo: orderTags.message,
        valorS: 'Ingresada',
        valorI:0,
        name: nameOfCreatorParts[0],
        lastname: nameOfCreatorParts.slice(1).join(' '),
        createdAt: dateAndHourToDate(order.dataOfcreation.dateOfcreation, order.dataOfcreation.hour).toISOString(),
      }
      array.push(item);
    }

    //Agregamos los demás elementos
    let quantItems=copyHistorical.length;
    for(let i=0; i<quantItems; i++){
      let item:Historical=copyHistorical[i];

      //Si se editó el campo statusOrder y el valorI es 1 (orden devuelta)
      if(item.campo==orderTags.statusOrder && item.valorI==orderStatusConst.RETURNED){
        //Entonces agregamos en el campo obs el campo valorI del elemento siguiente o anterior.
        //Para saber si es el siguiente o anterior debemos comparar la hora (deben ser iguales)
        const next=copyHistorical[i+1];
        const previous=copyHistorical[i-1];
        let isNextOrPrevius:string=''

        const date1= new Date(item.createdAt);
        if(next){
          const date2= new Date(next.createdAt);
          //Si el siguiente tiene la misma hora, asignamos el valorI del siguiente
          if(date1.getHours()==date2.getHours() && date1.getMinutes()==date2.getMinutes()){
            item.obs=next.valorI.toString();
          }
          isNextOrPrevius='next';
        } else if(previous){
          //Si el anterior tiene la misma hora, asignamos el valorI del anterior
          const date2= new Date(previous.createdAt);
          if(date1.getHours()==date2.getHours() && date1.getMinutes()==date2.getMinutes()){
            item.obs=previous.valorI.toString();
          }
          isNextOrPrevius='previous';
        }

        //Y eliminamos el elemento siguiente o anterior segun la variable isNextOrPrevius
        if(isNextOrPrevius=='next'){ 
          copyHistorical.splice(i+1,1); 
        } else if(isNextOrPrevius=='previous'){ 
          //Si el elemento es previus, ademas de eliminarlo, debemos quitarlo de array.
          copyHistorical.splice(i-1,1); 
          array.splice(array.length-1,1);
        }
        quantItems--;
        array.push(item);
      } else if(item.campo!=orderTags.message){
        //Si el campo NO es mensaje, lo agregamos al array
        array.push(item);
      }

    }
    
    return array;
  }

  override close(): void {
    this.store.dispatch(setHistorical({historical:''}));
    this.historicalIdAux='';
    this.historicalArray=[];
    this.clickInClose=true;
    this.count=0;
  };

  override open(): void {

  }
}
