import { Component, OnInit,OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setErrorStruct, setShowWarningChangeTypeCoin } from 'src/app/redux/actions/options.action';
import { priceTags } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { PriceValidator } from 'src/app/shared/price';

@Component({
  selector: 'warning-currency-exchange',
  templateUrl: './currency-exchange-warning.component.html',
  styleUrls: ['./../modal.scss']
})
export class WarningChangeTypeCoinComponent implements OnInit, OnDestroy {
  public showModal:boolean=false;
  public price:any;
  user!:User;

  private optionsSubscription!:Subscription;
  private userSubscription!:Subscription;

  constructor(private store:Store<appState>, private priceV:PriceValidator, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.optionsSubscription=this.store.select('options').subscribe(options=>{
      this.showModal=options.warning.isShow;
      this.price=options.warning.price;
    });

    this.userSubscription=this.store.select('usuario').subscribe(user=>{
      this.user=user;
    });
  }

  ngOnDestroy(): void {
    this.optionsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  confirm(){
    const newPrice:Price=JSON.parse(JSON.stringify(this.price));
    this.priceV.editPrice(newPrice, priceTags.typeCoin, newPrice.typeCoin, this.user);
    this.priceV.editPrice(newPrice, priceTags.price, newPrice.price, this.user);
    this.cdr.detectChanges();
    this.close();
  }

  noConfirm(){
    this.close();
  }

  close(){
    this.store.dispatch(setShowWarningChangeTypeCoin({price:{}, isShow:false}));
  }

  //Shows the banner of changes saved successfully
  showChangesSaved(){
    this.store.dispatch(setErrorStruct({ error: {isVisible: true, message: 'Cambios guardados', color: '#fff' }}));
  }
}
