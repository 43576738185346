import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setAllViews, setFilterPrice, setIndexViewSelected, setViewSelected } from 'src/app/redux/actions/price.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { VIEWS_SELECTED_CONST, VIEW_PRICE_ALL } from 'src/app/shared/const/views.const';
import { getViewsByTag } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { ViewsSectionBasic } from '../view-section';
import { PriceString } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'app-views-section-price',
  templateUrl: './views-section-price.component.html',
  styleUrls: ['./../views-section.scss']
})
export class ViewsSectionPriceComponent extends ViewsSectionBasic implements OnInit, OnDestroy {
  override views: ViewPrice[] = []; //It is extracted from the LocalStorage
  override VIEW_ALL: ViewPrice = VIEW_PRICE_ALL;

  constructor(store:Store<appState>) {
    super(store);
  }

  override setSubscriptions(){
    this.views$=this.store.select('price','viewsOfPriceBoard','views').subscribe((views) => {
      this.views = this.filterViews(views);
    });

    this.viewSelected$=this.store.select('price','viewsOfPriceBoard','viewSelected').subscribe((viewSelected) => {
      this.viewSelected = viewSelected;
    });

    this.index$=this.store.select('price','viewsOfPriceBoard','indexViewSelected').subscribe((indexViewSelected) => {
      this.editViewIdentificator = indexViewSelected;
    });
  }

  override rememberViewsOfLocalStorage() {
    const views = getViewsByTag(localStorageNames.viewPrices);
    this.setViewAll(views)
  }

  override getViewSelectedConst(): "priceBoard" {
    return VIEWS_SELECTED_CONST.PRICE_BOARD
  }

  override getLocalStorageName(): string {
    return localStorageNames.viewPrices;
  }

  override selectViewDefault(){
    this.viewAll();
  }

  override setViewAll(views:ViewPrice[]){
    this.store.dispatch(setAllViews({ views: views }));
  }
  
  override setIndexViewSelected(index:number){
    this.store.dispatch(setIndexViewSelected({ index: index }));
  }
  
  override setViewSelected(view:ViewPrice){
    this.store.dispatch(setViewSelected({view:view}));
  }

  override setFilter(filter:PriceString){
    this.store.dispatch(setFilterPrice({priceString: filter}));
  }
  
  override getFilter(view:ViewPrice):PriceString{
    return view.priceString;
  }
}