import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerNameComponent } from './seller-name.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { SyngentaSubdivisionModule } from '../syngenta/syngenta.module';
import { SedeModule } from '../sede/sede.module';
import { NroBillingModule } from '../nro-billing/nro-billing.module';



@NgModule({
  declarations: [
    SellerNameComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    PipesModule,
    SyngentaSubdivisionModule,
    SedeModule,
    NroBillingModule
  ], exports:[
    SellerNameComponent
  ]
})
export class SellerNameModule { }
