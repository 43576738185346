<div class="form" #formulario>
    <div class="buttons">
        <button [ngClass]="{'selected':numOfStep==1}" class="step" (click)="changeStep(1, formulario)">Paso 1</button>
        <button [ngClass]="{'selected':numOfStep==2}" class="step" (click)="changeStep(2, formulario)" >Paso 2</button>
        <button [ngClass]="{'selected':numOfStep==3}" class="step" (click)="changeStep(3, formulario)">Paso 3</button>
        <button [ngClass]="{'selected':numOfStep==4}" class="step" (click)="changeStep(4, formulario)" *ngIf="isGenerateToFix">Paso 4</button>
        <div class="rectangle step-{{numOfStep}}"></div>
    </div>
    
    <div class="delete-all gap-8">
        <span (click)="deleteStep(numOfStep)">Borrar paso {{numOfStep}}</span>
        
        <span (click)="deleteAll()">Borrar todo</span>
    </div>
    
    <div class="paso paso1" [ngClass]="{'visible': numOfStep==1, 'invisible': numOfStep!=1 }">     
        <app-product-name [fieldIdentificator]="getFieldType()"></app-product-name>
        <app-buyer [ngClass]="{'visible': isPort, 'invisible': !isPort }" [fieldIdentificator]="getFieldType()"></app-buyer>
        <app-business-type [fieldIdentificator]="getFieldType()"></app-business-type>
        <app-business-particularities [fieldIdentificator]="getFieldType()"></app-business-particularities>
        <app-place-of-delivery [fieldIdentificator]="getFieldType()"
        [requirePort]="price.placeOfDelivery.zone>7"
        [incompleteRequirePort]="requirePort()"
        (tab)="changeStep(2, formulario)"></app-place-of-delivery>
    </div>
    <div class="paso paso2" style="margin-top:0" [ngClass]="{'visible': numOfStep==2, 'invisible': numOfStep!=2 }">
        <app-delivery-period [fieldIdentificator]="getFieldType()" [isGenerateToFix]="isGenerateToFix"></app-delivery-period>
        <app-quality [fieldIdentificator]="getFieldType()" (addQuality)="showQualities($event)"></app-quality>
        <app-quality-particularities [fieldIdentificator]="getFieldType()" *ngIf="showPartQuality || price.qualityIBM>0"></app-quality-particularities>
        <app-price *ngIf="!isGenerateToFix" [fieldIdentificator]="getFieldType()"></app-price>
        <app-type-coin *ngIf="!isGenerateToFix" [fieldIdentificator]="getFieldType()" (tab)="changeStep(3, formulario)"></app-type-coin>
    </div>
    <div class="paso paso3" [ngClass]="{'visible': numOfStep==3, 'invisible': numOfStep!=3 }">
        <app-harvest [fieldIdentificator]="getFieldType()"></app-harvest>
        <app-way-pay [fieldIdentificator]="getFieldType()" [isGenerateToFix]="isGenerateToFix"></app-way-pay>
        <app-expiration [fieldIdentificator]="getFieldType()"  *ngIf="!isWayPayInvalidToExpiration(price?.wayPay) && !isGenerateToFix" [isOperator]="isOperator"></app-expiration>
        <app-pesification [fieldIdentificator]="getFieldType()" *ngIf="isInDolarsPrice(price) || isGenerateToFix"></app-pesification>
        <app-grouper-code *ngIf="isOperator && !isGenerateToFix" [fieldIdentificator]="getFieldType()"></app-grouper-code>
    </div>
    <div class="paso paso4" [ngClass]="{'visible': numOfStep==4, 'invisible': numOfStep!=4 }" *ngIf="isGenerateToFix">
        <app-condition-type [fieldIdentificator]="getFieldType()"></app-condition-type>
        <app-to-fix-indicators [fieldIdentificator]="getFieldType()" [indicators]="price.indicators" [conditionType]="price.conditionType"></app-to-fix-indicators>
        <app-grouper-code *ngIf="isOperator && isGenerateToFix" [fieldIdentificator]="getFieldType()"></app-grouper-code>
    </div>


    <div class="button-container">
        <button class="not-filled-button" (click)="changeStep(numOfStep+1, formulario)" *ngIf="numOfStep<getNumMaxOfStep()">Siguiente</button>

        <button class="filled-button not-visible-in-950" (click)="confirmPrice(formulario)" 
        [ngClass]="{'disabled': isDisablePrice()}"
        *ngIf="getNumMaxOfStep()==numOfStep" >Generar condición</button>

        <button class="filled-button visible-in-950" type="button" [disabled]="isDisablePrice()" 
        [ngClass]="{'disabled': isDisablePrice()}" (click)="applyButtonEmit(true)" *ngIf="getNumMaxOfStep()==numOfStep">
            Aplicar
        </button>
    </div>
</div>