
<div class="column-left gap-8 width-100">

    <!--<div class="width-100">
        Plaza - Calidad - % de pago: <br>
        {{placeToSave}} - {{qualityToSave}} - {{percentageWayPayToSave}}<br>
        {{proform?.place?.isValid}} - {{proform?.quality?.isValid}} - {{proform?.wayPay?.isValid}} <br>
        {{proform?.place?.default}} - {{proform?.quality?.default}} - {{proform?.wayPay?.default}} <br>
        Lugares: {{proform?.place?.arrayValids}} <br>
        Calidades: {{proform?.quality?.arrayValids}} <br>

        {{order.price.placeOfDelivery.zone}} - {{order.price.placeOfDelivery.port}} <br>
    </div>-->

    <div class="row-between width-100"> 
        <div class="info-buyer column-left">
            <span class="text-data message-success" *ngIf="proform && isAllEnabled">Datos del comprador y la orden correctos.</span>
            <span class="text-data message-warning" *ngIf="!proform && !isBuyerCommodities() && buyer>0">No se encontró parametrización para el comprador.</span>
            <span class="text-data message-error" *ngIf="proform && !isAllEnabled">Los datos de la orden no coinciden con los del comprador.</span>
            
            <span class="text-buyer" *ngIf="proform">Datos correctos:
                <span [ngClass]="{'message-bold': ! proform.place.isValid}">{{getPlaceOfDeliveryBuyer()}}</span>
                -
                <span [ngClass]="{'message-bold': ! proform.quality.isValid}">{{getQualityBuyer()}}</span>
                - 
                <span [ngClass]="{'message-bold': ! proform.wayPay.isValid}">{{getPercentageWayPayBuyer()}}%</span>
            </span>
        </div>

        <div class="arrow-info pointer" *ngIf="proform" (click)="changeShowInfo()">
            <arrow-svg [color]="'color-white'" [direction]="getArrowDirection()"></arrow-svg>
        </div>
    </div>

    <span class="message-success" *ngIf="actionProform">Se ha {{actionProform==IGNORE_CONST?'ignorado la sugerencia':'confirmado la acción'}}</span>

    <div class="width-100 column-left form-container" *ngIf="showInfoToComplete">
        <div class="line grey width-100 first-line"></div>

        <span>Datos para aplicar en la orden.</span>
        
        <div class="column-left width-100" style="margin-top:16px">
            <span>Puerto<span class="asterisk">*</span></span>
    
            <select-input class="width-100" [array]="arrayPlaces" [OBJECT]="PLACES_MAP"
            [placeHolder]="'Ingresar lugar de entrega'" [value]="getPlaceOfDeliveryStr(placeToSave)" 
            (valueOut)="setPlaceOfDelivery($event)"
            [isInvalid]="!(proform?.place?.isValid ?? false)"></select-input>
        </div>

    
        <app-quality class="width-100" [fieldIdentificator]="'order'"
        [isInvalid]="!(proform?.quality?.isValid ?? false)"></app-quality>
    
    
        <app-way-pay class="width-100" [fieldIdentificator]="'order'"
        [isInvalidAux]="!(proform?.wayPay?.isValid ?? false)"></app-way-pay>
    
        <div class="width-100 row-between">
            <button class="not-filled-button autocorrect" (click)="autocorrect()">Autocorregir</button>
        
            <div class="container-buttons row-right gap-8 width-100">
                <button (click)="confirm()">Confirmar</button>
                <button (click)="ignore()">Ignorar</button>
            </div>    
        </div>
        
        <div class="line grey width-100 last-line"></div>
    </div>
</div>