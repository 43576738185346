<div class="contenido" (click)="menuClicked($event)" [ngClass]="{'big-menu': location == NAVEGATION.HOME, 'clicked':isClicked }">
    <nav class="menu" [@show]="showMenu"
    [ngClass]="{'mobile': isMobile(), 'tablet-desktop': !isMobile(), 'desarrollo': isDesarrollo()}">

        <div class="user-info">
            <img class="cruz" src="../../../assets/images/menu/cruz.svg" (click)="closeMenu()" />
            <img class="avatar" src="{{user.photo}}" />
            <div class="info">
                <img class="logo-grassi" src="../../../assets/images/menu/logo-menu-tablet.svg">

                <p class="unidad">{{unidades[user.workUnit]}}</p>
                <p class="nombre">{{user.name}} {{user.lastName}}</p>
                <p class="area">Área {{areas[user.area]}}</p>
            </div>
        </div>

        <a class="section" title="Inicio" id="inicio" [routerLink]="['/'+NAVEGATION.HOME]">
            <dashboard-svg [active]="location == NAVEGATION.HOME" class="icon"></dashboard-svg>
            <p [ngClass]="{'green':location == NAVEGATION.HOME}">Inicio</p>
        </a>
        <a class="section" title="Perfil" id="perfil" [routerLink]="['/'+NAVEGATION.PROFILE]">
            <profile-svg [active]="location == NAVEGATION.PROFILE" class="icon"></profile-svg> 
            <p>Perfil</p>
        </a>
        <a class="section" title="Tablero de condiciones" id="tablero-precios" [routerLink]="['/'+NAVEGATION.PRICE_BOARD]">
            <price-board-svg [active]="location == NAVEGATION.PRICE_BOARD" class="icon"></price-board-svg>
            <p [ngClass]="{'green': location==NAVEGATION.PRICE_BOARD}">Tablero de condiciones</p>
        </a>
        <a class="section" title="Listado de ordenes" id="listado-ordenes" [routerLink]="['/'+NAVEGATION.ORDER_BOARD]">
            <order-list-svg [active]="location == NAVEGATION.ORDER_BOARD" class="icon"></order-list-svg>
            <p [ngClass]="{'green': location==NAVEGATION.ORDER_BOARD}">Listado de ordenes</p>
        </a>
        
        <a *ngIf="!isComercial" class="section" title="Generar condición" id="generate-price" [routerLink]="['/'+NAVEGATION.GENERATE_PRICE]">
            <generate-price-svg [active]="location == NAVEGATION.GENERATE_PRICE" class="icon"></generate-price-svg>
            <p [ngClass]="{'green': location==NAVEGATION.GENERATE_PRICE}">Nueva condición</p>
        </a>

        <a *ngIf="isComercial" class="section" title="Listado de contrapartidas" id="listado-canje" [routerLink]="['/'+NAVEGATION.ORDER_EXCHANGE]">
            <exchange-order-svg [active]="location == NAVEGATION.ORDER_EXCHANGE" class="icon"></exchange-order-svg>
            <p [ngClass]="{'green': location==NAVEGATION.ORDER_EXCHANGE}">Listado de contrapartidas</p>
        </a>
        <a *ngIf="isComercial" class="section" title="Borrador de ordenes" id="eraser" [routerLink]="['/'+NAVEGATION.ERASER]">
            <eraser-svg [active]="location == NAVEGATION.ERASER" class="icon"></eraser-svg>
            <p [ngClass]="{'green': location==NAVEGATION.ERASER}">Borrador de ordenes</p>
        </a>
        <a class="section" title="Generar orden" id="generar-orden" [routerLink]="['/'+NAVEGATION.GENERATE_MANUAL_ORDER]">
            <generate-order-svg [active]="location == NAVEGATION.GENERATE_MANUAL_ORDER" class="icon"></generate-order-svg>
            <p [ngClass]="{'green': location==NAVEGATION.GENERATE_MANUAL_ORDER}">Generar orden</p>
        </a>
        
        <!--<div *ngIf="!isComercial" class="section" id="planilla-de-trabajo" [routerLink]="['/'+'/worksheet])"> 
            <img src="../../../assets/images/menu/worksheet.svg" class="icon" />
            <p>Planilla de trabajo</p>
        </div>-->

        <a *ngIf="!isComercial" class="section" title="Panel de control" id="control-panel" [routerLink]="['/'+NAVEGATION.CONTROL_PANEL]">
            <control-panel-svg [active]="location==NAVEGATION.CONTROL_PANEL" class="icon"></control-panel-svg>
            <p [ngClass]="{'green': location==NAVEGATION.CONTROL_PANEL}">Panel de control</p>
        </a>

        <a class="section" title="Historial" id="historial" [routerLink]="['/'+NAVEGATION.HISTORICAL]">
            <historical-svg [active]="location==NAVEGATION.HISTORICAL" class="icon"></historical-svg>
            <p [ngClass]="{'green': location==NAVEGATION.HISTORICAL}">Historial</p>
        </a>
        <div class="section" title="Cerrar sesión" id="cerrar-sesion" (click)="logout()">
            <img src="../../../assets/images/menu/on-off.svg" class="icon" />
            <p>Cerrar sesión</p>
        </div>

    </nav>
</div>