import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataBussinesParticularitiesComponent } from './data-buss-part.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';

@NgModule({
  declarations: [
    DataBussinesParticularitiesComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule
  ], exports:[
    DataBussinesParticularitiesComponent
  ]
})
export class DataBussinesParticularitiesModule { }
