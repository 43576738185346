<div class="card {{isToFix? 'card-to-fix':'card-to-price'}}" [ngSwitch]="getNameOfSwitch()">

    <div class="body" *ngSwitchCase="'order-exchange'">
        <div class="features order" *ngIf="!isOrderIncomplete(order)">
            <div class="row-features">
                <span class="seller">{{getSellerName(order)}}</span>
                <span class="tons">{{getTonsExchange(order) | numberPipe}} toneladas</span>
            </div>

            <div class="row-features">
                <span class="product-name">
                <img alt="product-icon" (error)="onErrorImg($event)"
                src="../../../../../assets/images/products/{{PRODUCTS[price.productName]}}.svg" />
                {{PRODUCTS[price.productName]}}</span>
                <span class="price {{COIN_TYPE[order.price.typeCoin].toLowerCase()}}">{{price.price | numberPipe}} {{COIN_TYPE[order.price.typeCoin]}}</span>
            </div>
        </div>
        <div class="paso paso1">
            <app-data class="data" [isSmall]="true" title="Vendedor" value="{{getBuyerNameExchange(order)}}" #data1 [ngStyle]="getStyle(data1)"></app-data>
            <app-data class="data" [isSmall]="true" title="Sede" value="{{getCampus(order)}}" *ngIf="isSancor(order)" #data2 [ngStyle]="getStyle(data2)"></app-data>
            <app-data class="data" [isSmall]="true" title="¿Cómo vende?" value="{{getHowSellExchange(order)}}"  #data3 [ngStyle]="getStyle(data3)"></app-data>
            <app-data class="data" [isSmall]="true" title="Lugar de procedencia" value="{{getPlaceOfOrigin(order.exchange? order.exchange.placeOfOrigin.province:0, order.exchange? order.exchange.placeOfOrigin.town:0)}}" #data4 [ngStyle]="getStyle(data4)"></app-data>
            <app-data class="data" [isSmall]="true" title="Periodo de entrega" value="{{order.price.deliveryPeriod.startDate != '--'? order.price.deliveryPeriod.startDate +'-'+order.price.deliveryPeriod.endDate : '--'}}"
            #data5 [ngStyle]="getStyle(data5)"></app-data>
            <app-data class="data" [isSmall]="true" title="Bonificación" value="{{getBonification(order.exchange)}}" *ngIf="getBonification(order.exchange)!='--'" #data6 [ngStyle]="getStyle(data6)"></app-data>
            <app-data class="data" [isSmall]="true" title="Descuento convenido" value="{{getAgreedDiscount(order)}}" *ngIf="getAgreedDiscount(order) != '--'" #data7 [ngStyle]="getStyle(data7)"></app-data>
            <div class="data relative">            
                <app-data class="data" [isSmall]="true" title="Precio" value="{{getPrice(order)}}" #data8 [ngStyle]="getStyle(data8)"></app-data>
                <span class="absolute caption" *ngIf="getPriceDiscount(order) != '--'">Descuento del {{getPriceDiscount(order)}}</span>
            </div>
            <app-data class="data" [isSmall]="true" title="Moneda" value="{{getTypeCoin(order)}}" #data9 [ngStyle]="getStyle(data9)"></app-data>
        </div>
    </div>
    
    <div class="body" #card *ngSwitchCase="'order'">
        <app-header-details class="width-100" [order]="order"
        [productPreviousValue]="getPreviusValue('productName')"
        [pricePreviousValue]="getPreviusValue('typeCoin') || getPreviusValue('price')"
        [deliveryPeriodPreviousValue]="getPreviusValue('deliveryPeriod')"></app-header-details>

        <div class="width-100 all-data" *ngIf="showSteps">
            <div class="paso paso1">
                <app-data class="data" [isSmall]="true" title="Lugar de procedencia" value="{{getPlaceOfOrigin(order.placeOfOrigin.province, order.placeOfOrigin.town)}}"  #data1 [ngStyle]="getStyle(data1)"></app-data>
                <app-data class="data" [isSmall]="true" title="Corredor" value="{{BROKERS[order.broke]}}" #data2 [ngStyle]="getStyle(data2)"></app-data>
                <app-data class="data" [isSmall]="true" title="Particularidad de negocio" 
                [previusValue]="getPreviusValue('businessParticularities')" [object]="getBusinessParticularities('order')" 
                [businessParticularity]="order.price.businessParticularities" #data3 [ngStyle]="getStyle(data3)"></app-data>
                <app-data class="data" [isSmall]="true" title="¿Cómo vende?" value="{{HOW_SELL[order.howSell]}}" #data4 [ngStyle]="getStyle(data4)"></app-data>    
                <app-data class="data" [isSmall]="true" title="Restricción" value="{{getBuyerType(order.buyer)}}" [object]="getBuyer(order.buyer)" #data5 [ngStyle]="getStyle(data5)"></app-data>
                <app-data class="data" [isSmall]="true" title="Tipo de negocio" 
                [previusValue]="getPreviusValue('typeBusiness')" value="{{BUSINESS_TYPE[order.price.typeBusiness]}}" #data6 [ngStyle]="getStyle(data6)"></app-data>
                <app-data class="data" [isSmall]="true" title="Cupos" [object]="getQuotas(order)" #data7 [ngStyle]="getStyle(data7)" ></app-data>
                <app-data *ngIf="isSancor(order)" class="data" [isSmall]="true" title="Sede" value="{{getCampus(order)}}" #data8 [ngStyle]="getStyle(data8)"></app-data>
            </div>
            <div class="paso paso2">
                <app-data class="data" [isSmall]="true" title="Lugar de entrega" 
                [previusValue]="getPreviusValue('placeOfDelivery')" value="{{getPlaceOfDelivery(order.price.placeOfDelivery)}}" 
                #data9 [ngStyle]="getStyle(data9)"></app-data>
                <app-data class="data" [isSmall]="true" title="Calidad" 
                [previusValue]="getPreviusValue('quality')" value="{{getQuality('order')}}" 
                #data10 [ngStyle]="getStyle(data10)"></app-data>
                <app-data class="data" [isSmall]="true" title="Particularidad de calidad" 
                [previusValue]="getPreviusValue('qualityIBM')" value="{{getQualityParticularities('order')}}" #data11 [ngStyle]="getStyle(data11)"></app-data>
                <app-data *ngIf="!conditionHaveIndicators(order?.price)" class="data" [isSmall]="true" title="Periodo de entrega"
                [previusValue]="getPreviusValue('deliveryPeriod')"
                value="{{order.price.deliveryPeriod.startDate != '--'? order.price.deliveryPeriod.startDate +'-'+order.price.deliveryPeriod.endDate : '--'}}"
                #data12 [ngStyle]="getStyle(data12)"></app-data>
            </div>
            <div class="paso paso3">
                <app-data class="data" [isSmall]="true" title="Cosecha" 
                [previusValue]="getPreviusValue('harvest')" value="{{order.price.harvest}}" #data13 [ngStyle]="getStyle(data13)"></app-data>

                <app-data class="data" [isSmall]="true" title="Forma de pago" [infoGreen]="'Codigo IBM: ' +getIBMCode(order.price)"
                [previusValue]="getPreviusValue('wayPay')" value="{{getWayPay(order.price)}}" #data14 [ngStyle]="getStyle(data14)">
                </app-data>
                <app-data class="data" [isSmall]="true" title="Vencimiento" 
                [previusValue]="getPreviusValue('expiration')" value="{{order.price.wayPay.expiration}}" #data15 [ngStyle]="getStyle(data15)"></app-data>
                <app-data class="data" [isSmall]="true" title="Pesificación" 
                [previusValue]="getPreviusValue('pesificacion')" value="{{getPesification(order.price)}}" #data16 [ngStyle]="getStyle(data16)"></app-data>
                <app-data  *ngIf="isOperator" class="data" [isSmall]="true" title="Código agrupador" value="{{getGrouperCode(order.price)}}" #data17 [ngStyle]="getStyle(data17)" ></app-data>
                <app-data class="data" title="Numero de factura" value="{{getNroBilling(order)}}" *ngIf="isNutrien(order)"></app-data>
            </div>

            <div class="observations">
                <app-data class="data-observation data" [isSmall]="true" title="Observaciones" value="{{getObservations(order)}}" #data18 [ngStyle]="getStyle(data18)"></app-data>
            </div>
            
        </div>

        <div class="indicators column-left gap-8 width-100" *ngIf="conditionHaveIndicators(order?.price)">            
            <div class="line grey width-100"></div>

            <span class="title-indicator">Indicadores</span>

            <div class="row-left gap-4px">
                <img alt="product-icon" (error)="onErrorImg($event)" 
                src="../../../../../assets/images/products/{{PRODUCTS[price.productName]}}.svg" />

                <span class="info">
                    {{PRODUCTS[price.productName]}} - {{getConditionType(order?.price)}}
                </span>
            </div>

            <div class="indicator" [ngClass]="{'not-border': i==getIndicators(order.price).length - 1}" *ngFor="let indicator of getIndicators(order.price); index as i">
                <div class="paso" [ngClass]="{'three-columns': isIndicatorsInThreeColumns}">
                    <app-data [isSmall]="true" title="Fix" [previusValue]="getPreviusValueIndicators('fixationPeriod', i)"
                    [value]="getIndicatorFixedPeriod(indicator)" #data28 [ngStyle]="getStyle(data28)"></app-data>

                    <app-data [isSmall]="true" title="Indicador"  [previusValue]="getPreviusValueIndicators('indicatorName', i)"
                    [value]="getIndicatorName(indicator)" #data27 [ngStyle]="getStyle(data27)"></app-data>

                    <app-data [isSmall]="true" title="Calidad" [previusValue]="getPreviusValueIndicators('quality', i)" 
                    [value]="getIndicatorQuality(indicator)" #data19 [ngStyle]="getStyle(data19)"></app-data>

                    <app-data [isSmall]="true" title="Plaza" [previusValue]="getPreviusValueIndicators('location', i)"
                    [value]="getIndicatorLocation(indicator)" #data21 [ngStyle]="getStyle(data21)"></app-data>

                    <app-data [isSmall]="true" title="Fija por" [previusValue]="getPreviusValueIndicators('percentageProduct', i)"
                    [value]="getIndicatorPercentageAndProduct(indicator)" #data20 [ngStyle]="getStyle(data20)"></app-data>

                    <app-data [isSmall]="true" title="Mes y año" [previusValue]="getPreviusValueIndicators('monthAndYear', i)"
                    [value]="getIndicatorPosition(indicator)" #data25 [ngStyle]="getStyle(data25)"></app-data>

                    <app-data [isSmall]="true" title="Bonificación" [previusValue]="getPreviusValueIndicators('bonification', i)"
                    [value]="getIndicatorBonification(indicator)" #data23 [ngStyle]="getStyle(data23)"></app-data>

                    <app-data [isSmall]="true" title="Rebaja" [previusValue]="getPreviusValueIndicators('reduction', i)"
                    [value]="getIndicatorReduction(indicator)" #data24 [ngStyle]="getStyle(data24)"></app-data>       

                    <app-data [isSmall]="true" title="Mínimo y máximo" 
                    [previusValue]="getPreviusValueIndicators('min', i) || getPreviusValueIndicators('max', i)"
                    [value]="getIndicatorMinAndMax(indicator)" #data22 [ngStyle]="getStyle(data22)"></app-data>

                    <app-data [isSmall]="true" title="Proporcionalidad" 
                    [value]="getIndicatorProporcionalityDetails(indicator)" #data26 [ngStyle]="getStyle(data26)"></app-data>
                </div>
            </div>
        </div>

        <span *ngIf="messageLoading">{{messageLoading}}</span>

        <div class="contrapart column-left" *ngIf="orderExchangeData">
            <div class="row-between width-100 pointer" (click)="changeInfoExchangeSecondary()">
                <span class="title">Contrapartida</span>
                
                <arrow-svg [direction]="getArrowDirectionInfoExchangeSecondary()"></arrow-svg>
            </div>

            <div class="row-features pointer" (click)="changeInfoExchangeSecondary()">
                <div class="column-left">
                    <span class="seller">{{getBuyerNameExchange(orderExchangeData)}}</span>
                    <span class="sub-info-seller-buyer">Vendedor - N° de cuenta {{orderExchangeData.exchange.buyer.codeS}}</span>
                </div>

                <div style="width:1px; height:40px; background:#000;"></div>

                <div class="column-right">
                    <span class="buyer">{{getSellerName(order)}}</span>
                    <span class="sub-info-seller-buyer">Comprador - N° de cuenta {{orderExchangeData.sellerData.codeS}}</span>
                </div>

            </div>

            <div class="paso" *ngIf="infoExchangeSecondary">
                <app-data class="data" [isSmall]="true" title="Sede" value="{{getCampus(orderExchangeData)}}" *ngIf="isSancor(orderExchangeData)" #data2 [ngStyle]="getStyle(data2)"></app-data>
                <app-data class="data" [isSmall]="true" title="¿Cómo vende?" value="{{getHowSellExchange(orderExchangeData)}}"  #data3 [ngStyle]="getStyle(data3)"></app-data>
                <app-data class="data" [isSmall]="true" title="Lugar de procedencia" value="{{getPlaceOfOrigin(orderExchangeData.exchange? orderExchangeData.exchange.placeOfOrigin.province:0, orderExchangeData.exchange? orderExchangeData.exchange.placeOfOrigin.town:0)}}" #data4 [ngStyle]="getStyle(data4)"></app-data>
                <app-data class="data" [isSmall]="true" title="Periodo de entrega" value="{{orderExchangeData.price.deliveryPeriod.startDate != '--'? orderExchangeData.price.deliveryPeriod.startDate +'-'+orderExchangeData.price.deliveryPeriod.endDate : '--'}}"
                #data5 [ngStyle]="getStyle(data5)"></app-data>
                <app-data class="data" [isSmall]="true" title="Bonificación" value="{{getBonification(orderExchangeData.exchange)}}" *ngIf="getBonification(orderExchangeData.exchange)!='--'" #data6 [ngStyle]="getStyle(data6)"></app-data>
                <app-data class="data" [isSmall]="true" title="Descuento convenido" value="{{getAgreedDiscount(orderExchangeData)}}" *ngIf="getAgreedDiscount(orderExchangeData) != '--'" #data7 [ngStyle]="getStyle(data7)"></app-data>
                <div class="data relative">            
                    <app-data class="data" [isSmall]="true" title="Precio" value="{{getPrice(orderExchangeData)}}" #data8 [ngStyle]="getStyle(data8)"></app-data>
                    <span class="absolute caption" *ngIf="getPriceDiscount(orderExchangeData) != '--'">Descuento del {{getPriceDiscount(orderExchangeData)}}</span>
                </div>
                <app-data class="data" [isSmall]="true" title="Moneda" value="{{getTypeCoin(orderExchangeData)}}" #data9 [ngStyle]="getStyle(data9)"></app-data>
            </div>
        </div>
    </div>

    <div class="body" *ngSwitchCase="'price'">
        <div class="features" *ngIf="!conditionHaveIndicators(price) else conditionToFix">
            <span class="product-name">
                <img alt="product-icon" (error)="onErrorImg($event)" src="../../../../../assets/images/products/{{PRODUCTS[price.productName]}}.svg"/>
                {{PRODUCTS[price.productName]}}
            </span>

            <span class="price to-price {{COIN_TYPE[price.typeCoin].toLowerCase()}}">{{price.price | numberPipe}} {{COIN_TYPE[price.typeCoin]}}</span>
        </div>

        <ng-template #conditionToFix>
            <div class="width-100 row-between header-condition pointer" [ngClass]="{'close': !showSteps}" (click)="changeShowSteps()">
                <span class="title-condition">Condición</span>
                
                <arrow-svg class="arrow pointer" [direction]="getArrowDirection()" ></arrow-svg>
            </div>
            <span class="product-name" *ngIf="showSteps">
                <img alt="product-icon" (error)="onErrorImg($event)" src="../../../../../assets/images/products/{{PRODUCTS[price.productName]}}.svg"/>
                {{PRODUCTS[price.productName]}}
            </span>
        </ng-template>


        <div class="width-100 all-data" *ngIf="showSteps">
            <div class="paso paso1">
                <!--<app-data class="data not-in-mobile"[isSmall]="true" title="Producto" value="{{PRODUCTS[price.productName]}}"></app-data>-->
                <app-data *ngIf="isPort" class="data" [isSmall]="true" title="Comprador" value="{{BUYERS[price.observations.buyer]}}" #data1 [ngStyle]="getStyle(data1)"></app-data>
                <app-data class="data" title="Tipo de negocio"[isSmall]="true" value="{{BUSINESS_TYPE[price.typeBusiness]}}" #data2 [ngStyle]="getStyle(data2)"></app-data>
                <app-data class="data" title="Lugar de entrega"[isSmall]="true" value="{{getPlaceOfDelivery(price.placeOfDelivery)}}" #data3 [ngStyle]="getStyle(data3)"></app-data>
                <app-data class="data" [isSmall]="true" title="Particularidad de negocio" [object]="getBusinessParticularities('price')" 
                [businessParticularity]="price.businessParticularities" #data4 [ngStyle]="getStyle(data4)"></app-data>
            </div>
            <div class="paso paso2">
                <app-data class="data" [isSmall]="true" title="Periodo de entrega"
                    value="{{price.deliveryPeriod.startDate != '--'? price.deliveryPeriod.startDate +'/'+price.deliveryPeriod.endDate : '--'}}"
                    #data5 [ngStyle]="getStyle(data5)"></app-data>
                <app-data class="data" [isSmall]="true" title="Calidad" value="{{getQuality('price')}}" #data6 [ngStyle]="getStyle(data6)"></app-data>
                <app-data class="data" [isSmall]="true" title="Particularidad de calidad" value="{{getQualityParticularities('price')}}" #data7 [ngStyle]="getStyle(data7)"></app-data>
            </div>
            <div class="paso paso3">
                <app-data class="data" [isSmall]="true" title="Cosecha" value="{{price.harvest}}" #data8 [ngStyle]="getStyle(data8)"></app-data>
                <app-data class="data" [isSmall]="true" title="Forma de pago" value="{{getWayPay(price)}}" 
                [infoGreen]="'Codigo IBM: ' + getIBMCode(price)"
                #data9 [ngStyle]="getStyle(data9)">
                </app-data>
                
                <app-data class="data" [isSmall]="true" title="Vencimiento" value="{{price.wayPay.expiration}}" 
                [infoGreen]="isOperator && isFixExpiration(price)? 'Vencimiento anclado':''" #data10 [ngStyle]="getStyle(data10)"></app-data>
                <app-data class="data" [isSmall]="true" title="Pesificación" value="{{getPesification(price)}}" #data11 [ngStyle]="getStyle(data11)"></app-data>
                <app-data *ngIf="isOperator" class="data" [isSmall]="true" title="Código agrupador" value="{{getGrouperCode(price)}}" #data12 [ngStyle]="getStyle(data12)"></app-data>
            </div>
        </div>
        <div class="indicators width-100" *ngIf="conditionHaveIndicators(price)">        
            <div class="line grey width-100" *ngIf="showSteps"></div>

            <div class="indicators column-left gap-8 width-100" *ngIf="conditionHaveIndicators(price)">
                <span class="title-indicator">Indicadores</span>
    
                <div class="row-left gap-4px">
                    <img alt="product-icon" (error)="onErrorImg($event)" 
                    src="../../../../../assets/images/products/{{PRODUCTS[price.productName]}}.svg" />
    
                    <span class="info">
                        {{PRODUCTS[price.productName]}} - {{getConditionType(price)}}
                    </span>
                </div>

                <div class="row-left" *ngIf="isValidUntil(price)">
                    <span class="valid-until">Periodo de entrega válido hasta: {{getValidUntilText(price)}}</span>
                </div>
    
                <div class="indicator" [ngClass]="{'not-border': i==getIndicators(price).length - 1}" *ngFor="let indicator of getIndicators(price); index as i">
                    <div class="paso">
                        <app-data [isSmall]="true" title="Fix" [value]="getIndicatorFixedPeriod(indicator)" #data28 [ngStyle]="getStyle(data28)"></app-data>
                        <app-data [isSmall]="true" title="Indicador" [value]="getIndicatorName(indicator)" #data27 [ngStyle]="getStyle(data27)"></app-data>
                        <app-data [isSmall]="true" title="Calidad" value="{{getIndicatorQuality(indicator)}}" #data19 [ngStyle]="getStyle(data19)"></app-data>
                        <app-data [isSmall]="true" title="Plaza" value="{{getIndicatorLocation(indicator)}}" #data21 [ngStyle]="getStyle(data21)"></app-data>
                        <app-data [isSmall]="true" title="Fija por" value="{{getIndicatorPercentageAndProduct(indicator)}}" #data20 [ngStyle]="getStyle(data20)"></app-data>
                        <app-data [isSmall]="true" title="Mes y año" value="{{getIndicatorPosition(indicator)}}" #data25 [ngStyle]="getStyle(data25)"></app-data>
                        <app-data [isSmall]="true" title="Bonificación" value="{{getIndicatorBonification(indicator)}}" #data23 [ngStyle]="getStyle(data23)"></app-data>
                        <app-data [isSmall]="true" title="Rebaja" value="{{getIndicatorReduction(indicator)}}" #data24 [ngStyle]="getStyle(data24)"></app-data>                    
                        <app-data [isSmall]="true" title="Mínimo y máximo" value="{{getIndicatorMinAndMax(indicator)}}" #data22 [ngStyle]="getStyle(data22)"></app-data>
                        <app-data [isSmall]="true" title="Proporcionalidad" [value]="getIndicatorProporcionalityDetails(indicator)" #data26 [ngStyle]="getStyle(data26)"></app-data>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="body" *ngSwitchCase="'loading'">
        <div class="column-center gap-4px">
            <div class="rectangle-grey"></div>
            <div class="rectangle-grey" style="margin-bottom:16px"></div>
        </div>

        <div class="line grey"></div>

        <div class="width-100">
            <div class="paso paso1 gap-8">
                <div class="rectangle-grey"></div>
                <div class="rectangle-grey" style="margin-bottom:16px"></div>
            </div>
            <div class="paso paso2 gap-8">
                <div class="rectangle-grey"></div>
                <div class="rectangle-grey" style="margin-bottom:16px"></div>
            </div>
            <div class="paso paso3 gap-8">
                <div class="rectangle-grey"></div>
                <div class="rectangle-grey" style="margin-bottom:16px"></div>
            </div>

            <div class="rectangle-grey big"></div>
        </div>
    </div>
</div>