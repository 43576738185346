import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { BonusOrReduction, Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { CONDITIONS_TYPE_TO_FIX } from 'src/app/shared/dict/condition-and-indicators';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { INDICATOR_VOID } from 'src/app/shared/const/to-fix';

@Component({
  selector: 'app-info-to-fix',
  templateUrl: './info-to-fix.component.html',
  styleUrls: ['./../info.scss']
})
export class InfoToFixComponent {
  @Input() conditionType!:number;
  @Input() indicators:Indicator[]=[]
  
  CONDITION_TYPE:any;
  constructor(private store: Store<appState>, private toFixV:ToFixValidator) {
    this.CONDITION_TYPE=CONDITIONS_TYPE_TO_FIX;
  }

  getConditionType(){
    return this.toFixV.getConditionType(this.conditionType);
  }

  getProductName(){
    return this.toFixV.getProduct(this.indicators[0]);
  }

  getMacroFixationPeriodToFix(){
    return this.toFixV.getMacroFixationPeriod(this.indicators);
  }

  getFixationPeriod(indicator:Indicator){
    return this.toFixV.getFixedPeriod(indicator);
  }

  /*If the number of flags is greater than 0, we return it, otherwise we return an array of an empty flag.*/
  getIndicators(){
    if(this.indicators.length>0){
      return this.indicators;
    }
    return [INDICATOR_VOID];
  }

  getIndicatorName(indicator:Indicator){
    return this.toFixV.getIndicatorName(indicator);
  }

  getQuality(indicator:Indicator){
    return this.toFixV.getQuality(indicator);
  }

  getProductAndPercentage(indicator:Indicator){
    return this.toFixV.getPercentageAndProductName(indicator);
  }
  
  getPlace(indicator:Indicator){
    return this.toFixV.getPlace(indicator);
  }
  
  getMinAndMax(indicator:Indicator){
    return this.toFixV.getMinAndMax(indicator);
  }

  getProporcionalityDetails(indicator:Indicator){
    return this.toFixV.getProporcionalityShort(indicator);
  }
  
  getReduction(indicator:Indicator){
    return this.toFixV.getReduction(indicator);
  }
  
  getBonus(indicator:Indicator){
    return this.toFixV.getBonification(indicator);
  }
  
  getBonusOrReduction(bonusOrReduction:BonusOrReduction){
    return this.toFixV.getBonusOrReduction(bonusOrReduction);
  }
  
  getMonthAndYear(indicator:Indicator){
    return this.toFixV.getMonthAndYear(indicator);
  }
}