<button class="not-filled-button-2" style="margin-top:16px" #fieldName (click)="setShowOptions(true)">
    <plus-generate-order-svg class="icon" [size]="30"></plus-generate-order-svg>
    <span class="field-name">Ingresar indicadores</span>
</button>

<span class="message-error" *ngIf="isClickedButton">Debes cargar un tipo de condición a fijar antes de ingresar los indicadores.</span>
<span class="message-error" *ngIf="showMessageSinEspacio">Los negocios 'sin espacio' solo pueden tener un indicador.</span>
<span class="message-error" *ngIf="isInvalidsIndicators(indicators,conditionType)">Los indicadores no están correctamente cargados.</span>

<app-indicators-list class="width-100" style="display:flex; margin-top:8px;" [indicators]="getOptions()"
(edit)="edit($event.indicator, $event.index)" (delete)="delete($event)"></app-indicators-list>

<div class="background opaque" *ngIf="showOptions" (click)="setShowOptions(false, true)"></div>
<to-fix-indicators-slide-card class="slide-card slide-card-visible" *ngIf="showOptions" (closeEvent)="setShowOptions(false, true)" 
[fieldIdentificator]="fieldIdentificator" [edit]="editIndicator" [delete]="deleteIndicator"></to-fix-indicators-slide-card>