<section class="body">

  <div class="header-section">
    <div class="downs">
      <app-buttons-buyer-type [buyerTypeSelected]="buyerTypeSelected" (selected)="setBuyerType($event)"></app-buttons-buyer-type>
      <app-buttons-condition-type></app-buttons-condition-type>
      <app-views-section-price class="views not-visible-in-mobile"></app-views-section-price>
    </div>
  
    <div class="call-to-actions">
      <div class="buttons-to-actions">
        <app-search-input class="search"></app-search-input>
        <button class="add filled-button button price not-visible-in-mobile" (click)="goToCreate(isComercial)">Nueva {{isComercial? 'orden': 'condición'}}</button>
        <button class="add filled-button button price visible-in-mobile mobile" (click)="goToCreate(isComercial)">+</button>
      </div>
  
      <div class="description">
        <span class="quant">Cantidad de condiciones de negocio: <span class="stroke">{{pricesWithPipes? pricesWithPipes.length: 0  }}</span></span>
        <span class="day pointer"  (mouseenter)="hover()" (mouseleave)="noHover()" 
          (click)="goToHistorical()">Tablero del día <span class="stroke">{{getDate()}}</span>
          <arrow-svg [direction]="'right'" [isHover]="isHover" class="arrow"></arrow-svg>
        </span>
      </div>

      <app-slide *ngIf="conditionTypeSelected==CONDITION_TYPES_CONST.TO_FIX"></app-slide>
    </div>
  </div>

  <div class="buttons-actions not-visible-in-mobile container-animation" *ngIf="isOperator" [ngClass]="{'active': (pricesSelected? pricesSelected.length:0) > 0}">
    <button class="idea-price" (click)="setEditPrices(PRICE_STATUS_CONST.IDEA)"> <div class="circle sent-to-broker"></div> Idea</button>
    <button class="price-price" (click)="setEditPrices(PRICE_STATUS_CONST.PRECIO)"> <div class="circle confirm"></div> Precio</button>
    <button class="retired-price" (click)="setEditPrices(PRICE_STATUS_CONST.RETIRADO)"> <div class="circle return"></div> Retirado</button>
    <button class="paused-price" (click)="setEditPrices(PRICE_STATUS_CONST.PAUSADO)"> <div class="circle check"></div> Pausado</button>
    <button class="inactive-price" (click)="setEditPrices(PRICE_STATUS_CONST.INACTIVO)"> <div class="circle out-of-market"></div> Inactivo</button>
  </div>

  <app-price-table class="table"></app-price-table>

  <!-- EYE SECTION -->
  <div [ngClass]="{'background opaque': isShowDetails || historical || isShowEditIndicators}"
  (click)="closeCards()"></div>
  <app-price-historical class="slide-card slide-card-visible" *ngIf="historical"></app-price-historical>
  <app-price-eye *ngIf="isShowDetails" class="slide-card slide-card-visible" [price]="priceDetails"></app-price-eye>
  <app-indicator-information *ngIf="isShowEditIndicators && !isComercial"
  class="slide-card slide-card-visible card-edit-indicator"
  style="height:100vh; overflow-y:auto;"
  (valueOut)="setIndicatorSelected($event)" (save)="saveIndicatorToEdit($event)"
  [indicator]="indicatorToEdit" [conditionType]="conditionTypeToEdit" [showButtonSave]="true"></app-indicator-information>
  

  <!-- DIALOG PRICE VARIANCE ERROR -->
  <app-error-change-price *ngIf="isOperator"></app-error-change-price>
  <warning-currency-exchange *ngIf="isOperator"></warning-currency-exchange>
  <app-price-delete *ngIf="isOperator"></app-price-delete>

  <!-- CHANGES VISIBLES -->   
  <div class="error-visible changes-visibles" *ngIf="errorVisible?.isVisible">
    <span>{{errorVisible.message}}</span>
  </div>
  <div class="save-changes" *ngIf="isOperator" [ngClass]="{'changes-visibles': isChangeVisible, 'changes-invisibles':!isChangeVisible}">
    <span>Se realizaron cambio en la condición. <!--<span class="undo">Deshacer</span>--></span>
  </div>
</section>