import { Injectable } from '@angular/core';
import { Quotas } from '../interfaces/order.interface';
import { getDateISOFormat } from '../date.checker';
import { InfoQuotas } from '../interfaces/options.interface';
import { setInfoQuotas } from 'src/app/redux/actions/options.action';
import { appState } from '../interfaces/appState.interface';
import { Store } from '@ngrx/store';
import { OrderExchange } from '../interfaces/order-exchange';
import { OrderService } from '../services/order.service';
import { isSancor, isValidQuota } from '../order';
import { QUOTA_VOID, orderInitialState } from '../const/orders';

@Injectable({
  providedIn: 'root',
})
export class QuotasIBMFunctions {
    constructor(private store:Store<appState>, private orderSvc:OrderService){}

  setMessageQuota(message:string){
    this.store.dispatch(setInfoQuotas({dataOfQuotas: {
      messageResponse: message,
      order: orderInitialState,
      quota: QUOTA_VOID,
      isErrorSolicitate: true,
      messageSolicitate: message
    }
}))
  }

  solicitateQuotas(order:OrderExchange, isExchange:boolean=false, assignmentTypeDefault:string='A'){
    try{
      const quotasParsed:Quotas[]=JSON.parse(order.quotas);
      const quantOfQuotas:number=quotasParsed.length;
      const nroContract:number= isExchange? order.exchange.contractNumber: order.contractNumber;
      const isOrderMotherInExchange:boolean= order.idExchange? 
      order.idExchange.length>6 && (order.exchange.tons==undefined):
      false;
      const isSancorSeller:boolean=isSancor(order.sellerData.codeS);
  
      /* Si es SANCOR, el unico caso para entrar al if es que orderMotherInExchange sea true.
      Si no es sancor, debe ingresar al if cuando orderMotherInExchange sea false.*/
      if((!isSancorSeller && !isOrderMotherInExchange) || (isSancorSeller && isOrderMotherInExchange)){
        let i=0;
        const intervalId=setInterval(()=>{
          try{
            let quota:Quotas=quotasParsed[i];
  
            //Si es un cupo valido, lo solicita
            if(isValidQuota(quota)){
              
              this.setMessageQuota('Cargando los cupos. Por favor, espere...');
  
              const dateInISO:number=getDateISOFormat(quota.date);
              const quantOfTrucks:number=quota.trucks;
              let assignmentType:string=assignmentTypeDefault;

              this.orderSvc.solicitateQuotas(nroContract, dateInISO, quantOfTrucks, assignmentType).then(response=>{
                var dataOfQuota:InfoQuotas={
                  messageResponse: response.status.messageError,
                  order: order,
                  quota: quota,
                  isErrorSolicitate: false,
                  messageSolicitate: ''
                }
                if(response.status.status=="ok"){
                  dataOfQuota.messageResponse='';
                  this.store.dispatch(setInfoQuotas({dataOfQuotas: dataOfQuota}))
                } else if(response.status.status=="error"){
                  //Si da error al asignar, directamente lo solicitamos.
                  this.reintentateSendQuota(nroContract, dateInISO, quantOfTrucks, dataOfQuota)
                }
              })
            }
          } catch(err){}
  
  
          i++;
          if(i==quantOfQuotas){
            window.clearInterval(intervalId);
          }
        },500)
      }
    } catch(err){}
  }

  reintentateSendQuota(nroContract:number, dateInISO:number, quantOfTrucks:number, dataOfQuota:InfoQuotas){
    this.orderSvc.solicitateQuotas(nroContract, dateInISO, quantOfTrucks, 'C').then(response2=>{
      if(response2.status.status=="ok") {
        dataOfQuota.isErrorSolicitate=false;
        dataOfQuota.messageSolicitate=`No hay cupos reservados para el
        ${dataOfQuota.quota.date} de ${dataOfQuota.quota.trucks + (dataOfQuota.quota.trucks>1? ' camiones': ' camión')}, 
        se ha enviado una solicitud al área de logística.`;
      } else if (response2.status.status=="error"){
        dataOfQuota.isErrorSolicitate=true;
        dataOfQuota.messageSolicitate=response2.status.messageError + '. Por favor, intente nuevamente.';
      }
      this.store.dispatch(setInfoQuotas({dataOfQuotas: dataOfQuota}));
    }).catch(err=>{
      dataOfQuota.isErrorSolicitate=true;
      dataOfQuota.messageSolicitate='No se pudo solicitar los cupos. Por favor, intente nuevamente.ya';
      this.store.dispatch(setInfoQuotas({dataOfQuotas: dataOfQuota}));
    })
  }
}

