import { Component, OnInit,OnDestroy, ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setOrderDetails, setOrdersSelected, showOrderDetails } from 'src/app/redux/actions/order.action';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, copyOrder } from 'src/app/shared/order';
import { PriceValidator } from 'src/app/shared/price';
import { TableComponent } from '../table.component';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { STATES_ORDERS_VALIDS } from 'src/app/pages/order-list/body-order-list/order-table/commodities/commodities.component';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';

@Component({
  selector: 'app-orden-table',
  templateUrl: './orden-table.component.html',
  styleUrls: ['../table.component.scss', './../../../../../shared/styles/board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class OrderTableComponent extends TableComponent implements OnInit, OnDestroy  {
  public STATES_ORDERS_VALIDS= [...STATES_ORDERS_VALIDS, "Contrato", "Borrador"];
  constructor(store: Store<appState>, router: Router, 
    date:DateChecker, orderV:OrderValidator, 
    priceV:PriceValidator, toFixV:ToFixValidator, cdr:ChangeDetectorRef) {
    super(store,router,date, orderV, priceV, toFixV,cdr);
  }

 override ngOnInit(): void {
  super.ngOnInit();
 }

 override ngOnDestroy(): void {
  super.ngOnDestroy()
  this.store.dispatch(setOrdersSelected({ orders: [] }));
 }

 override setNewOrdersPipe(): void {
  this.arrayHistoricalWithPipe= this.getOrdersPipe(this.arrayOfHistorical);
  this.cdr.detectChanges();
 }

  selectOptionMenu(event:OptionMenu, order:Order){
  switch(event.action){
    case OPTION.HISTORICAL: this.showHistorical(order); break;
    case OPTION.COPY:this.editOrder(order);break;
    case OPTION.DETAILS: this.showOrderDetail(order); break;
  }
 }

 editOrder(order:Order){
  copyOrder(order, this.store, this.router)
 }
 
  getOptionsMenu():OptionMenu[]{
    return [
      {action: OPTION.HISTORICAL, name:'Historial de cambios'},
      {action: OPTION.COPY, name:'Copiar orden'},
    ];
  }

  trackById(index: number, item: any): number {
    return item._id; // Suponiendo que los elementos de la lista tienen una propiedad "id" única.
  }

  /*handleDownloadCsv() {
    const table:HTMLTableElement | null = document.querySelector('table'); // Obtén la referencia a la tabla
    exportToCSV(table, 'historial-ordenes');
  }*/

  override getFilter() {
    return this.orderFilter
  }
  
 showOrderDetail(order: Order) {
    this.store.dispatch(setOrderDetails({ order: order }));
    this.store.dispatch(showOrderDetails({ show: true }));
    this.pauseActualizations();
  }
}