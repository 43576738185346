<div class="views">
    <ul>
        <li class="view option" *ngIf="isComercial">
            <span (click)="viewMyOrders()" [ngClass]="{'view-selected': isSelectedMyOrders  }">Mis ordenes</span>
            <three-point-svg class="not-visible-in-mobile" (click)="viewMyOrders()"></three-point-svg></li>
            
        <li class="view option" *ngIf="isComercial">
            <span (click)="viewMyTable()" [ngClass]="{'view-selected': isSelectedMyTable  }">Mi mesa</span>
            <three-point-svg class="not-visible-in-mobile" (click)="viewMyTable()"></three-point-svg></li>

        <li class="view option" *ngIf="!isRepresentation">
            <span (click)="viewAll()" [ngClass]="{'view-selected': isViewAllSelected()  }">Visualización completa</span>
            <three-point-svg class="not-visible-in-mobile" (click)="viewAll()"></three-point-svg></li>
        <li class="view option" *ngFor="let view of views; index as i">
            <span (click)="changeView(i)" [ngClass]="{'view-selected': isViewSelected(view, viewSelected) }">{{view.viewName}}</span>
            <three-point-svg class="not-visible-in-mobile" (click)="editView(i)"></three-point-svg>
            <views-orders *ngIf="i==editViewIdentificator" [identificator]="2" [view]="view" [indexToEdit]="i" 
                [isHistorical]="isHistorical" [isControlPanel]="isControlPanel"></views-orders>
        </li>
    </ul>
    <span class="option add-view not-visible-in-mobile" (click)="openNewView()" *ngIf="isShowAddView">+ Agregar vista</span>
    <views-orders class="not-visible-in-mobile" *ngIf="-1 == editViewIdentificator" [identificator]="1" [isHistorical]="isHistorical" [isControlPanel]="isControlPanel"></views-orders>
</div>