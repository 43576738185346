<div class="card">
    <h4 class="title">Comisión</h4>
    <p class="text">Ingresar un porcentaje de comisión para cada perfil de la orden.</p>

    <div class="values">
        <div class="row-between width-100">
            <div class="icon-and-name">
                <div class="icon confirmed"></div>
                <span class="profile">Vendedor</span>
            </div>
            <input class="number" type="number" min="0" max="2.5" step="0.1" value="{{valueSeller}}" (change)="setSellerValue($event)"/>
        </div>

        <div class="row-between width-100">
            <div class="icon-and-name">
                <div class="icon confirmed"></div>
                <span class="profile">Comprador</span>
            </div>
            <input class="number" type="number" min="0" max="2.5" step="0.1" value="{{valueBuyer}}" (change)="setBuyerValue($event)"/>
        </div>
    </div>

    <button class="filled-button row-center" style="margin-top:6px;" (click)="emitCommission()">Aplicar</button>
</div>