import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoSyngentaComponent } from './info-syngenta.component';
import { DataModule } from '../../data/data.module';



@NgModule({
  declarations: [
    InfoSyngentaComponent
  ],
  imports: [
    CommonModule,
    DataModule
  ], exports:[
    InfoSyngentaComponent
  ]
})
export class InfoSyngentaModule { }
