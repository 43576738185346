import { Component, Input } from '@angular/core';

@Component({
  selector: 'message-svg',
  templateUrl: './message.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class MessageSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
