<div class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive">
        <thead>
            <tr>
                <th class="col-s col-1">
                    <!--[checked]="isCheckedAll" (click)="checkedAllItems()"-->
                    <input class="form-check-input" [checked]="isCheckedAll" (click)="checkedAllItems()" type="checkbox"
                        value="true">
                </th>

                <th class="col-b col-12" *ngIf="viewSelected.motherContract">
                    <div (click)="selectFilter(12)" class="filter">
                        <span class="title-col">N° contrato madre</span>
                        <arrow-svg class="arrow" [direction]="'down'"></arrow-svg>
                    </div>
                    <filter-order *ngIf="filterIdentificator==12" [filterIdentificator]="12" title="Contrato madre"  [arrayAux]="[]" identificatorString="motherContract"></filter-order>
                </th>
                
                <th class="col-b col-13" *ngIf="viewSelected.motherContract">
                    <div (click)="selectFilter(13)" class="filter">
                        <span class="title-col">N° de contrapartida</span>
                        <arrow-svg class="arrow" [direction]="'down'"></arrow-svg>
                    </div>
                    <filter-order *ngIf="filterIdentificator==13" [filterIdentificator]="13" title="Contrapartida"  [arrayAux]="[]" identificatorString="contractNumber"></filter-order>
                </th>

                <th class="col-m col-16" *ngIf="viewSelected.date">
                    <div (click)="selectFilter(1)" class="filter">
                        <span class="title-col">Fecha</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('date','') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==1"
                    title="Fecha" identificatorString="hour" [filterIdentificator]="1"></filter-order>
                </th>

                <th class="col-b col-3" *ngIf="viewSelected.seller">
                    <div (click)="selectFilter(13)" class="filter">
                        <span class="title-col">Vendedor</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('codeS','') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==13" title="Vendedor" [isSearchOrNumber]="true" 
                    [arrayAux]="SELLERS" identificatorString="codeS" [filterIdentificator]="13"></filter-order>
                </th>

                <th class="col-b col-3" *ngIf="viewSelected.exchanger">
                    <div (click)="selectFilter(14)" class="filter">
                        <span class="title-col">Canjeador</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('exchanger','') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==14" title="Vendedor" [isSearchOrNumber]="true" 
                    [arrayAux]="SELLERS" identificatorString="codeS" [filterIdentificator]="14"></filter-order>
                </th>
                
                
                <th class="col-m col-4" *ngIf="viewSelected.product">
                    <div (click)="selectFilter(3)" class="filter">
                        <span class="title-col">Producto</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('price','productName') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order [ngClass]="{'invisible': 3 != filterIdentificator, 'visible': 3==filterIdentificator}"
                        title="Producto" [isPropertyOfPrice]="true" identificatorString="productName" [arrayAux]="PRODUCTS"
                        [isSearchOrNumber]="true" [filterIdentificator]="3"></filter-order>
                </th>
                
                <!-- PRICE -->
                <th class="col-sm col-9" *ngIf="viewSelected.price">
                    <div (click)="selectFilter(8)" class="filter">
                        <span class="title-col">Precio </span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('price','price') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==8" title="Precio" [isPropertyOfPrice]="true" 
                    identificatorString="price" [isSearchOrNumber]="true" [filterIdentificator]="8"></filter-order>
                </th>

                <!-- TONS -->
                <th class="col-m col-11" *ngIf="viewSelected.tons">
                    <div (click)="selectFilter(10)" class="filter">
                        <span class="title-col">Toneladas</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('tons','') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==10" title="Toneladas" identificatorString="tons" 
                    [isSearchOrNumber]="true"  [filterIdentificator]="10"></filter-order>
                </th>

                <!--Way to pay-->
                <th class="col-bb col-10" *ngIf="viewSelected.exchangeStatus">
                    <div (click)="selectFilter(9)" class="filter">
                    <span class="title-col">Estado de la contrapartida</span>
                    <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('exchangeStatus','') else elseBlock"></arrow-svg>
                    <ng-template #elseBlock class="arrow">
                        <filter-board-svg class="arrow"></filter-board-svg>
                    </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==9" title="Estado de la contrapartida"
                    [arrayAux]="EXCHANGE_STATES" identificatorString="exchangeStatus" [filterIdentificator]="9"></filter-order>
                </th>

                <th class="col-b col-7" *ngIf="viewSelected.deliveryPeriod">
                    <div (click)="selectFilter(6)" class="filter">
                        <span class="title-col">Periodo de entrega</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('price','deliveryPeriod') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==6" [filterIdentificator]="6" [isPropertyOfPrice]="true"
                    title="Periodo de entrega" identificatorString="deliveryPeriod"></filter-order>
                </th>

                <th class="col-bb col-15" *ngIf="viewSelected.tables">
                    <div (click)="selectFilter(15)" class="filter">
                        <span class="title-col">Mesas</span>
                        <arrow-svg class="arrow" [direction]="'down'" *ngIf="!getIsFilter('nroTeam','') else elseBlock"></arrow-svg>
                        <ng-template #elseBlock class="arrow">
                            <filter-board-svg class="arrow"></filter-board-svg>
                        </ng-template>
                    </div>
                    <filter-order *ngIf="filterIdentificator==15" [filterIdentificator]="15" title="Mesa" 
                    [arrayAux]="TABLES" identificatorString="nroTeam"></filter-order>
                </th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order of orders | filterByRepresentation: isRepresentation:user | filterOrderByConditionType: conditionTypeSelected:conditionFilters | filterByOrder: orderFilter | filterOrder:search | sorOrderExchange; index as i"
                [ngClass]="{'is-new': getIsNew(order), 
                'confirmation-missing': isConfirmationMissing(order),
                'is-child':isChild(order), 
                'is-parent': isParent(order), 
                'invisible': (isChild(order) && !parentShowInDropdown(order, i)),
                'display-none': getInvisibleContrapart(order)}">
                <!-- ACTIONS -->
                <td>
                    <div class="actions">
                        <input class="form-check-input" [checked]="isOrderInOrderList(order)"
                            (click)="selectOrder(order)" type="checkbox" value="true">
                        
                        <eye-svg [active]="false" (click)="showOrderDetail(order)"></eye-svg>

                        <three-point-svg (click)="openOptions(i)" *ngIf="!isRepresentation"></three-point-svg>

                        <div class="menu-options in-menu-option" *ngIf="!isRepresentation && optionIdentificator==i">
                            <ul class="in-menu-option">
                                <li (click)="showAddExchange(order)" class="in-menu-option" *ngIf="order.exchange.status==3">Agregar canje</li>
                                <li (click)="deleteExchange(order)" class="in-menu-option" *ngIf="order.exchange.status!=3">Retirar canje</li>
                            </ul>
                        </div>

                        <arrow-svg class="arrow" [direction]="getArrowDirection(order._id)" *ngIf="isParent(order)" (click)="dropDown(order._id)"></arrow-svg>
                    </div>
                </td>

                <!-- MOTHER CONTRACT -->
                <td *ngIf="viewSelected.motherContract">{{getMotherContract(order)}}</td>

                <td *ngIf="viewSelected.motherContract">{{getContractNumber(order)}}</td>

                <td *ngIf="viewSelected.date">{{order.dataOfcreation.dateOfcreation}}</td>

                <!-- SELLER NAME -->
                <td *ngIf="viewSelected.seller">
                    <div style="display: flex; flex-direction:row">
                        <span class="seller-name">{{getExchanger(order)}}</span>
                    </div>
                </td>

                <!-- BUYER / EXCHANGER -->
                <td *ngIf="viewSelected.exchanger">{{getSeller(order)}}</td>

                <!-- PRODUCT -->
                <td *ngIf="viewSelected.product">
                    {{getProductName(order)}}
                </td>
                
                <!-- Price -->
                <td class="price-column" *ngIf="viewSelected.price">
                    <div class="price-container tooltip-container">
                        <span>{{getPrice(order)}}</span>
                    </div>
                </td>

                <!-- Tons -->
                <td class="tons-column" *ngIf="viewSelected.tons">
                    <span>{{getTons(order)}}</span>
                </td>

                <!-- EXCHANGER STATUS -->
                <td *ngIf="viewSelected.exchangeStatus">
                    <div class="exchange-status {{order.exchange.status>0? getExchangeStatus(order).toLowerCase():''}}" (click)="showOrderDetail(order)">
                        <span class="status-text">{{getExchangeStatus(order)}}</span>
                    </div>
                </td>

                <!-- DELIVERY PERIOD -->
                <td *ngIf="viewSelected.deliveryPeriod">{{getDeliveryPeriod(order)}}</td>

                <!-- OWNER OF ORDER -->
                <td *ngIf="viewSelected.tables">
                    <div class="column-beetwen">
                        <span class="team">{{getBossNameCreator(order)}}</span>
                        <span class="name-creator">Creada por {{getNameOfCreator(order)}}</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
