import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-a-retirar',
  templateUrl: './a-retirar.component.html',
  styleUrls: ['./../options.component.scss']
})
export class ARetirarComponent extends BusinessParticularityOption implements OnChanges {
  isRetired:boolean=false;

  constructor() {
    super()
  }

  ngOnChanges(): void {
    try{
      this.isRetired=this.businessParticularitiesStruct.toWithdraw==1;
    }catch(err){
      this.isRetired=false;
    }
  }

  setRetired(){
    this.isRetired=!this.isRetired;
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.toWithdraw= this.boolToNumber(this.isRetired);
    this.emit2(newBP);
  }

}
