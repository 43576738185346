<svg class="svg-menu flex" [ngClass]="{'enabled': active, 'disabled': !active}" width="22" height="22" viewBox="0 0 22 22"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_209_1617)">
        <path
            d="M19.5312 0.5H5.46875C5.08044 0.5 4.76562 0.814812 4.76562 1.20312V19.4844C4.76562 19.8727 5.08044 20.1875 5.46875 20.1875H7.57812V21.6875H6.875C6.48669 21.6875 6.17188 22.0023 6.17188 22.3906C6.17188 22.7789 6.48669 23.0938 6.875 23.0938H7.57812V23.7969C7.57812 24.1852 7.89294 24.5 8.28125 24.5C8.66956 24.5 8.98438 24.1852 8.98438 23.7969V23.0938H16.0156V23.7969C16.0156 24.1852 16.3304 24.5 16.7188 24.5C17.1071 24.5 17.4219 24.1852 17.4219 23.7969V23.0938H18.125C18.5133 23.0938 18.8281 22.7789 18.8281 22.3906C18.8281 22.0023 18.5133 21.6875 18.125 21.6875H17.4219V20.1875H19.5312C19.9196 20.1875 20.2344 19.8727 20.2344 19.4844V1.20312C20.2344 0.814812 19.9196 0.5 19.5312 0.5ZM16.0156 21.6875H8.98438V20.1875H16.0156V21.6875ZM18.8281 18.7812H6.17188V1.90625H18.8281V18.7812Z"
            fill="#2E3B4C" />
        <path
            d="M12.947 8.9375H11.0938C10.7054 8.9375 10.3906 9.25231 10.3906 9.64062C10.3906 10.0289 10.7054 10.3438 11.0938 10.3438H11.7969V11.0469C11.7969 11.4352 12.1117 11.75 12.5 11.75C12.8883 11.75 13.2031 11.4352 13.2031 11.0469V10.3211C13.7372 10.2285 14.1863 9.85128 14.3621 9.32384C14.5655 8.71377 14.3564 8.04462 13.8419 7.6588L12.0018 6.2787C11.9724 6.25658 11.9604 6.21828 11.972 6.18336C11.9837 6.14844 12.0162 6.125 12.053 6.125H13.9062C14.2946 6.125 14.6094 5.81019 14.6094 5.42188C14.6094 5.03356 14.2946 4.71875 13.9062 4.71875H13.2031V4.01562C13.2031 3.62731 12.8883 3.3125 12.5 3.3125C12.1117 3.3125 11.7969 3.62731 11.7969 4.01562V4.74139C11.2629 4.83402 10.8138 5.21122 10.6379 5.73866C10.4345 6.34869 10.6436 7.01783 11.158 7.4037L12.9982 8.7838C13.0276 8.80587 13.0396 8.84417 13.028 8.87914C13.0163 8.91406 12.9838 8.9375 12.947 8.9375Z"
            fill="#2E3B4C" />
        <path
            d="M8.28125 14.5625C8.66958 14.5625 8.98438 14.2477 8.98438 13.8594C8.98438 13.471 8.66958 13.1562 8.28125 13.1562C7.89292 13.1562 7.57812 13.471 7.57812 13.8594C7.57812 14.2477 7.89292 14.5625 8.28125 14.5625Z"
            fill="#2E3B4C" />
        <path
            d="M11.0938 14.5625H16.7188C17.1071 14.5625 17.4219 14.2477 17.4219 13.8594C17.4219 13.4711 17.1071 13.1562 16.7188 13.1562H11.0938C10.7054 13.1562 10.3906 13.4711 10.3906 13.8594C10.3906 14.2477 10.7054 14.5625 11.0938 14.5625Z"
            fill="#2E3B4C" />
        <path
            d="M8.28125 17.375C8.66958 17.375 8.98438 17.0602 8.98438 16.6719C8.98438 16.2835 8.66958 15.9688 8.28125 15.9688C7.89292 15.9688 7.57812 16.2835 7.57812 16.6719C7.57812 17.0602 7.89292 17.375 8.28125 17.375Z"
            fill="#2E3B4C" />
        <path
            d="M11.0938 17.375H16.7188C17.1071 17.375 17.4219 17.0602 17.4219 16.6719C17.4219 16.2836 17.1071 15.9688 16.7188 15.9688H11.0938C10.7054 15.9688 10.3906 16.2836 10.3906 16.6719C10.3906 17.0602 10.7054 17.375 11.0938 17.375Z"
            fill="#2E3B4C" />
    </g>
    <defs>
        <clipPath id="clip0_209_1617">
            <rect width="22" height="22" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
    </defs>
</svg>