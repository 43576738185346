import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Subscription } from 'rxjs';
import { resetOrderExchangeToGenerate, setExchangeConfirmed, setExchangeInOrderToGenerate, setOrderToGenerateInExchange, showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { EXCHANGE_STATUS_CONST, exchangeInitialState } from 'src/app/shared/const/exchange-orders';
import { OBJECT_ID_VOID } from 'src/app/shared/const/options';
import { orderStatusConst, SELLER_DATA_VOID } from 'src/app/shared/const/orders';
import { REPRESENTATION_CONST_ARRAY } from 'src/app/shared/const/user.const';
import { getDate, isEarlierDate, isSameDate, stringToDate } from 'src/app/shared/date.checker';
import { EXCHANGER_STATES } from 'src/app/shared/dict/exchange';
import { orderStates } from 'src/app/shared/dict/orders';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { Exchange, OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator, getSumOfTonsExchange } from 'src/app/shared/order-exchange';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

@Component({
  selector: 'app-exchange-details',
  templateUrl: './exchange-details.component.html',
  styleUrls: ['./exchange-details.component.scss', './../slide-card.scss', './../details-eye/details-eye.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExchangeDetailsComponent implements OnInit,OnDestroy {
  public order!:OrderExchange;
  EXCHANGE_STATES:any;
  SELLERS:any;
  ORDER_STATES:any;
  user!:User;
  isRepresentation:boolean=false;



  //  ARROWS  \\
  arrowViewCreateExchange:string='down';
  exchangeToGenerate!:Exchange;
  exchangesToGenerate:Exchange[]=[];
  tonsAvaible:number=0;

  arrowViewExchange:string='down';
  arrowViewOrderData:string='down';

  arrowViewContractMother:string='down';
  numberContractMother:number=-1;
  orderContractMother!:Order|null;
  messageErrorContractMother:string='';
  count=0;

  agreedDiscountDefault=0;

  private user$!:Subscription;
  private exchange$!:Subscription;
  private orderDetails$!:Subscription;

  constructor(private store:Store<appState>, private toFixV:ToFixValidator, private orderV:OrderValidator, private exchangeV: OrderExchangeValidator, private IBM:ContractChecker) { }

  ngOnInit(): void {
    this.EXCHANGE_STATES= EXCHANGER_STATES;
    this.SELLERS=sellerWithData;
    this.ORDER_STATES=orderStates;

    this.user$=this.store.select('usuario').subscribe(user=>{
      this.user=user;
      this.isRepresentation=REPRESENTATION_CONST_ARRAY.includes(user.workUnit);
    })

    this.exchange$=this.store.select('exchange').subscribe(exchanger=>{
      this.exchangeToGenerate=exchanger.generateOrder.exchange;

      if(this.exchangesToGenerate.length <= 1){
        this.exchangesToGenerate=[this.exchangeToGenerate];
      } else {
        let lastIndex=this.exchangesToGenerate.length-1;
        this.exchangesToGenerate[lastIndex]=JSON.parse(JSON.stringify(this.exchangeToGenerate));
      }

      if(exchanger.exchangeBoard.showOrderDetails && this.count==0){
        this.getAllTonsAvailable();
        this.count++;
      } else if(!exchanger.exchangeBoard.showOrderDetails){
        this.count=0;
      }

    })

    //Cada vez que se produce un cambio en order details (debería ser uno solo), se calcula el agreedDiscountDefault del cliente.
    this.orderDetails$=this.store.select('exchange', 'exchangeBoard', 'orderDetails').subscribe(order=>{
      this.order=order;
      this.agreedDiscountDefault= this.exchangeV.getExchangeInitial(this.order).agreedDiscount;
    })
    
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.exchange$?.unsubscribe();
    this.orderDetails$?.unsubscribe();
  }

  orderHaveIndicators(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  /*verifyOrder(order:OrderExchange){
    if(order?.exchange && order.exchange.idMother != OBJECT_ID_VOID){
      this.orderV.getOrderById(this.order.exchange.idMother).subscribe(order=>{
        //If the states are different.
        //You have to update it in exchange
        if(order.statusOrder != order.statusOrder){
          let newOrder=JSON.parse(JSON.stringify(order));
          newOrder.statusOrder=order.statusOrder;
          newOrder.contractNumber=order.contractNumber;
  
          //Si el estado es confirmado o contrato y no está incompleto, le cambiamos el estado al canje a completo y agregamos el moterContract
          if(newOrder.statusOrder == orderStatusConst.AUTORIZATION || newOrder.statusOrder == orderStatusConst.CONTRACT && !this.isOrderIncomplete(order)){
            newOrder.exchange.status=EXCHANGE_STATUS_CONST.VERIFICAR;
            newOrder.exchange.motherContract=order.contractNumber;
          }
  
          this.exchangeV.updateOrderExchange(newOrder);
        }
      })
    }
  }*/

  changeArrowViewCreateExchange(){
    let flag=true;
    if(this.arrowViewCreateExchange=='up'){
      flag=false;
    }
    this.closeAllArrows();

    if(flag){
      this.arrowViewCreateExchange=this.changeArrow(this.arrowViewCreateExchange);
    }

    if(this.arrowViewCreateExchange=='down'){
      //Cuando se cierra, reseteamos
      this.store.dispatch(resetOrderExchangeToGenerate()); //Reset form
    } else {
      //Cuando se hbre, seteamos
      this.store.dispatch(setOrderToGenerateInExchange({order:this.order})); //Set order in exchange
      this.store.dispatch(setExchangeInOrderToGenerate({exchange:this.order.exchange})); //Set exchange in order
    }
  }

  changeArrowViewContractMother(){
    let flag=true;
    if(this.arrowViewContractMother=='up'){
      flag=false;
    }
    this.closeAllArrows();

    if(flag){
      this.arrowViewContractMother=this.changeArrow(this.arrowViewContractMother);
    }

    if(this.arrowViewContractMother=='down'){
      this.numberContractMother=-1;
    }
  }

  changeArrowViewExchange(){
    let flag=true;
    if(this.arrowViewExchange=='up'){
      flag=false;
    }
    this.closeAllArrows();

    if(flag){
      this.arrowViewExchange=this.changeArrow(this.arrowViewExchange);
    }
  }

  changeArrowViewOrderData(){
    let flag=true;
    if(this.arrowViewOrderData=='up'){
      flag=false;
    }
    this.closeAllArrows();
    if(flag){
      this.arrowViewOrderData=this.changeArrow(this.arrowViewOrderData);
    }
  }

  //Close all arrows
  closeAllArrows(){
    this.arrowViewCreateExchange='down';
    this.arrowViewExchange='down';
    this.arrowViewOrderData='down';
    this.arrowViewContractMother='down';
  }

  changeArrow(direction:string){
    return getArrowDirectionByBool(direction=='down');
  }

  close(){
    this.store.dispatch(showOrderExchangeDetails({show:false}))
    this.orderContractMother=null;
    this.numberContractMother=-1
  }

  getContractNumber(order:OrderExchange){
    return order?.contractNumber ?? '--';
  }

  getContractNumberExchange(order:OrderExchange){
    return order?.exchange?.contractNumber ?? '--';
  }

  getExchangeStatus(order:OrderExchange){
    return this.EXCHANGE_STATES[order?.exchange?.status] ?? '--';
  }

  getStatusContractMother(order:OrderExchange){
    return this.ORDER_STATES[order?.statusOrder] ?? '--';
  }

  getBuyerData(order:OrderExchange){
    try{
      let stringToReturn=this.SELLERS[order.exchange.buyer.codeS].nombre;
      stringToReturn+= order.exchange.tons>0 ?
         ' - ' + order.exchange.tons + ' toneladas.' : ' - 0 toneladas.';
      return stringToReturn ;
    } catch (error) {}
    return '--';
  }

  getBuyerDataOfExchange(exchange:Exchange){
    try{
      let stringToReturn=this.SELLERS[exchange.buyer.codeS].nombre;
      stringToReturn+= exchange.tons>0 ?
         ' - ' + exchange.tons + ' toneladas.' : ' - 0 toneladas.';

      return stringToReturn ;
    } catch (error) {}
    return '--';
  }

  getSellerData(order:OrderExchange){
    try{
      return this.SELLERS[order.sellerData.codeS].nombre + ' - ' + order.tons + ' toneladas.';
    } catch (error) {}
    return '--';
  }

  isOrderIncomplete(order:Order){
    const withOutInd= this.orderHaveIndicators(order);
    return this.orderV.isOrderIncomplete(order, withOutInd)
  }

  hasAnOldDate(order:OrderExchange){
    return this.orderV.hasAnOldDate(order);
  }

  isExchangeIncomplete(exchange:Exchange){
    return this.exchangeV.isExchangeIncomplete(exchange);
  }

  searchContractMother(contract:number){
    this.numberContractMother=contract;
    this.orderV.getOrderByContract(contract).pipe(
      catchError(error=>{
        this.orderContractMother=null;
        this.messageErrorContractMother='No se encontró el contrato madre.';
        return [];
      })
    ).subscribe((res:ResponseRequest)=>{
      if(res.data){
        this.orderContractMother=res.data[0];
        this.messageErrorContractMother='';
      } else {
        this.messageErrorContractMother='No se encontró el contrato madre.';
        this.orderContractMother=null;
      }
    });
  }

  //Se fija si a una orden de canje le falta el contrato madre.
  //Verifica que no sea una orden de Syngenta Agro - AVC
  missingMotherContract(order:OrderExchange){
    try{
      //Se muestra la opción de agregar contrato madre,
      //Mientras que no sea un usuario de representación y no tenga contrato madre.
      return !this.isRepresentation && order.contractNumber<=0 && order.contractNumber<1000 && !this.isSyngentaAgroAVC(order);
    } catch(err){
      return true;
    }
  }

  //Devuelve true en caso de que sea una orden de syngenta agro avc
  isSyngentaAgroAVC(order:OrderExchange){
    return this.orderV.orderIsSyngentaAgroAvc(order);
  }
  
  asociateContractMother(){
    if(this.orderContractMother){
      let newOrderMother:OrderExchange=JSON.parse(JSON.stringify(this.orderContractMother));
      newOrderMother._id=this.order._id;
      newOrderMother.exchange=JSON.parse(JSON.stringify(this.order.exchange));
      //newOrderMother.exchange.motherContract=this.orderContractMother.contractNumber;
      newOrderMother.exchange.idMother=this.orderContractMother._id;
      newOrderMother.exchange.status= EXCHANGE_STATUS_CONST.VERIFICAR;

      this.exchangeV.updateOrderExchange(newOrderMother);
      this.close();
    }
  }

  isStatusVerify(order:OrderExchange){
    return this.exchangeV.isStatusVerify(order.exchange);
  }

  getOrderByContractMother(contractMother:number){
    return this.orderV.getOrderByContract(contractMother);
  }

  addOtherExchange(){
    //Solo añadimos otro, si se completó un canje con toneladas mayores a 0.
    if(!this.isExchangeWithTonsLessThanZero()){
      let newExchange:Exchange=JSON.parse(JSON.stringify(exchangeInitialState));
      newExchange.placeOfOrigin.province=this.exchangeToGenerate.placeOfOrigin.province;
      newExchange.placeOfOrigin.town=this.exchangeToGenerate.placeOfOrigin.town;
      newExchange.placeOfOrigin.afip=this.exchangeToGenerate.placeOfOrigin.afip;
      newExchange.tons=this.getOutstandingTons();
      this.exchangesToGenerate.push(newExchange)
      this.store.dispatch(resetOrderExchangeToGenerate());
      this.store.dispatch(setExchangeInOrderToGenerate({exchange:newExchange}));
    }
  }

  /*There is an exchange with tons less than or equal to 0*/
  isExchangeWithTonsLessThanZero(){
    let exchangeWithTonsLessThanZero=false;
    this.exchangesToGenerate.forEach(exchange=>{
      if(exchange.tons<=0){
        exchangeWithTonsLessThanZero=true;
      }
    })
    return exchangeWithTonsLessThanZero;
  }

  selectExchangeInArray(index:number){
    /*We save the index element in a variable, delete it from the original array and insert it last*/
    let exchangeToMove:Exchange=JSON.parse(JSON.stringify(this.exchangesToGenerate[index]));
    this.exchangesToGenerate.splice(index, 1);
    this.exchangesToGenerate.push(exchangeToMove);
    this.store.dispatch(setExchangeInOrderToGenerate({exchange:exchangeToMove}));
  }

  getExchanges(){
    /* The last element of the array is removed and the array without that element is used (without modifying the initial array) */
    return this.exchangesToGenerate;
  }

  editExchange(){
    const quantOfExchanges=this.exchangesToGenerate.length;
    for(let i=0; i<quantOfExchanges; i++){
      let newExchangeToGenerate=JSON.parse(JSON.stringify(this.exchangesToGenerate[i]));
      let statusToSet= !this.isExchangeIncomplete(this.exchangesToGenerate[i]) ? EXCHANGE_STATUS_CONST.VERIFICAR : EXCHANGE_STATUS_CONST.INCOMPLETO;
      newExchangeToGenerate.status= statusToSet;  
      let newOrder=this.exchangeV.setExchangeInOrder(this.order, newExchangeToGenerate);

      if(i==0){
        this.exchangeV.updateOrderExchange(newOrder);
      } else {
        this.exchangeV.saveOrder(newOrder, this.user);
      }
    }
    this.store.dispatch(resetOrderExchangeToGenerate());
    this.closeAllArrows();
    this.close(); 
    this.showModal();
  }

  getShowCompleteDataOfExchange(order:OrderExchange){
    return !this.isRepresentation && order.exchange.status != EXCHANGE_STATUS_CONST.COMPLETA
    //Se le muestra la opción de completar datos, mientras que no esté completa y no sea un usuario de representación.
  }

  //If the tons of the order are more than the sum of the tons of the arrangement
  showMissingTons(){
    return this.tonsAvaible;
  }

  getOutstandingTons(){
    return this.tonsAvaible;
  }

  isVisibleConfirmButton(order:OrderExchange){
    return !this.isRepresentation && !this.isOrderIncomplete(order) && 
    !this.isExchangeIncomplete(order.exchange) &&
    //(this.order.statusOrder == orderStatusConst.CONFIRM || this.order.statusOrder == orderStatusConst.CONTRACT) &&
    this.order.exchange.status == EXCHANGE_STATUS_CONST.VERIFICAR
    //this.order.exchange.motherContract >= 0;

    //Se muestra para confirmar mientras no sea un usuario de representación,
    //Mientras que la orden madre no esté incompleta,
    //Mientras que el canje no esté completo,
    //Mientras que el estado esté en verificar
  }

  confirmOrderExchange(){
    let newOrder= this.getOrderWithNewDates(this.order);

    //newOrder.exchange.status=EXCHANGE_STATUS_CONST.COMPLETA;
    //this.exchangeV.updateOrderExchange(newOrder);
    this.IBM.createContractExchangeSvc(newOrder, this.user,1);

    this.createOtherOrderToTonsMissing(newOrder);
    this.close();
  }

  /**
   * Given a counterparty, we modify the start date of the delivery period and the start of the fixing period.
   * @param { OrderExchange } order - Order to modify
   * @returns { OrderExchange }
   */
  getOrderWithNewDates(order:OrderExchange):OrderExchange{
    let newOrder:OrderExchange=JSON.parse(JSON.stringify(order));
    
    //Si son fechas distintas, entonces hacemos la validación de validar si es fecha anterior, sino seguimos
    if(!isSameDate( stringToDate(newOrder.price.deliveryPeriod.startDate), new Date()) &&
    isEarlierDate(newOrder.price.deliveryPeriod.startDate, getDate(), '-')){
      newOrder.price.deliveryPeriod.startDate=getDate();
    }

    //Si la orden tiene indicadores y son fechas distintas, validamos si es fecha anterior
    if(this.orderHaveIndicators(order) && 
    !isSameDate( stringToDate(newOrder.price.indicators[0].fixationPeriod.startDate), new Date()) &&
    isEarlierDate(newOrder.price.indicators[0].fixationPeriod.startDate, getDate(), '-')){
      newOrder.price.indicators[0].fixationPeriod.startDate=getDate();
    }
    
    return newOrder;
  }

  showModal(){
    const exchangedConfirmed={
      showConfirmed: true,
      confirmed: true,
      message: ''
    }
    this.store.dispatch(setExchangeConfirmed({exchangedConfirmed:exchangedConfirmed}));
  }

  createOtherOrderToTonsMissing(orderMother:OrderExchange){
    createOrderToTonsMissing(orderMother, this.user, this.exchangeV);
  }

  getAllTonsAvailable(){
    if(this.order?.exchange){
      let getContraparts$=this.exchangeV.getContraparts(this.order.exchange.idMother);
      getContraparts$.subscribe((res:ResponseRequest)=>{//contraparts=>{
        let allQuantOfTons=getSumOfTonsExchange(res.data);//contraparts);
        this.tonsAvaible=this.order.tons-allQuantOfTons;
      })
    }

    /*if(this.order?.exchange){
      let getContraparts$=this.exchangeV.getContraparts(this.order.exchange.idMother);
      getContraparts$.subscribe(contraparts=>{
        let allQuantOfTons=getSumOfTonsExchange(contraparts);
        this.tonsAvaible=this.order.tons-allQuantOfTons;
      })
    }*/
  }

  isNideraOSemillas(){
    return this.orderV.isSyngentaNidera(this.order.sellerData.codeS, this.order.price.businessParticularities.subdivisionSyngenta) || 
    this.orderV.isSyngentaSemillas(this.order.sellerData.codeS, this.order.price.businessParticularities.subdivisionSyngenta);
  }

}

export function createOrderToTonsMissing(orderMother:OrderExchange, user:User, exchangeV:OrderExchangeValidator){
  let getContraparts$=exchangeV.getContraparts(orderMother.exchange.idMother);
  getContraparts$.subscribe( (res:ResponseRequest)=>{// contraparts=>{
    let allQuantOfTons=getSumOfTonsExchange(res.data);//contraparts);

    //Si faltan completar toneladas para el madre, creamos otro pedido con los datos del canje incompletos
    if(orderMother.tons > allQuantOfTons){
      let newOrder:OrderExchange=JSON.parse(JSON.stringify(orderMother));
      newOrder.exchange.idMother=orderMother.exchange.idMother;
      newOrder.exchange.tons=orderMother.tons-allQuantOfTons;
      newOrder.exchange.price=orderMother.price.price;
      newOrder.exchange.contractNumber=-1;
      newOrder.exchange.howSell=-1;
      newOrder.exchange.buyer=SELLER_DATA_VOID;
      
      exchangeV.saveOrder(newOrder, user);
    }
  })
}
