import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataComponent } from './data.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { DataBussinesParticularitiesModule } from '../data-buss-part/data-buss-part.module';

@NgModule({
  declarations: [
    DataComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    DataBussinesParticularitiesModule
  ], exports:[
    DataComponent
  ]
})
export class DataModule { }
