import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'control-panel-svg',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./../../svg.scss']
})
export class ControlPanelSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
