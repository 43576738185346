import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryPeriodComponent } from './delivery-period.component';
import { StartDateComponent } from './start-date/start-date.component';
import { DateInputModule } from '../../inputs/date/date.module';
import { EndDateComponent } from './end-date/end-date.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { PlusSignGreenSvgModule } from 'src/app/shared/styles/svg/plus-sign-green/plus-sign-green.module';
import { DateMonthInputModule } from '../../inputs/date-month/date.module';

@NgModule({
  declarations: [
    DeliveryPeriodComponent,
    StartDateComponent,
    EndDateComponent
  ],
  imports: [
    CommonModule,
    DateInputModule,
    CrossSvgModule,
    PlusSignGreenSvgModule,
    DateMonthInputModule,

  ], exports:[
    DeliveryPeriodComponent
  ]
})
export class DeliveryPeriodModule { }
