import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, Subscription } from 'rxjs';
import { loadOrdersAllSuccessInControlPanel, setOrdersSelectedInControlPanel } from 'src/app/redux/actions/control-panel.action';
import { setErrorStruct, setLocation } from 'src/app/redux/actions/options.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { isProduction } from 'src/app/shared/const/options';
import { areas } from 'src/app/shared/const/user.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { SessionChecker } from 'src/app/shared/session.checker';
import { WebSocketFunctions } from 'src/app/shared/websocket/websocket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./../../shared/styles/body.scss']
})
export class ControlPanelComponent extends WebSocketFunctions implements OnInit {
  public user!:User;
  private orders:Order[]=[];
  
  private controlPanel$!:Subscription;
  private user$!:Subscription;

  constructor(private store: Store<appState>, private checker: SessionChecker, private router:Router,
    private orderSvc:OrderService, private dateC:DateChecker, maestroSvc:MaestrosService) {
      super(environment.rutas.websocket.orders, maestroSvc);
    this.loadOrders();
    
    this.checker.rememberAndActualizateUser(this.store);

    this.store.dispatch(setLocation({location: NAVEGATION.CONTROL_PANEL}));
  }

 
  ngOnInit(): void {
    this.setSubscriptions();
    this.checkIsComercial();
    this.checkTables();
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.controlPanel$?.unsubscribe();
    this.closeConnection();
    this.store.dispatch(setOrdersSelectedInControlPanel({orders:[]}));
  }

  setSubscriptions(){
    this.user$=this.store.select('usuario').subscribe( (usuario:User) => {
      this.user = usuario;
    })

    this.controlPanel$=this.store.select('controlPanel', 'controlPanelBoard', 'all').subscribe( (allControlPanel) => {
      this.orders = allControlPanel;
    });

  }

  override functionToMessage(event: any): void {
    console.log(JSON.parse(event.data))
    if(event.data !== JSON.stringify(this.orders)){
      this.setOrders(JSON.parse(event.data))
    }
  }

  checkIsComercial(){
    if(this.user.area == areas.comercial && isProduction()){
      this.router.navigate(['/' + NAVEGATION.HOME]);
    }
  }

  //Esto es lo mismo que está en order list
  loadOrders(){
    const date=this.dateC.getDateInYYYYMMDDformat(this.dateC.stringToDate(this.dateC.getDate()));
    this.orderSvc.getOrdersByDate(date).pipe(
      catchError((error) => {
        console.error(error.error);
        this.store.dispatch(loadOrdersAllSuccessInControlPanel({orders: []}));
        this.store.dispatch(setErrorStruct({error: {color: '#fff', isVisible:true, message: 'No hay datos para mostrar'}}));
        return [];
      })
    ).subscribe( (res:ResponseRequest) => {
      this.setOrders(res.data);
    })
  }

  setOrders(orders:Order[]){
    let almacenamiento= !orders? []: orders;
    localStorage.setItem(localStorageNames.ordersAll, JSON.stringify(almacenamiento));
    this.store.dispatch(loadOrdersAllSuccessInControlPanel({orders: almacenamiento}));
  }
}
