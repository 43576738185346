import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { appState } from 'src/app/shared/interfaces/appState.interface';

const SIZE_OF_VIEW_MOBILE = 950;
@Component({
  selector: 'app-body-orders-validation',
  templateUrl: './body-orders-validation.component.html',
  styleUrls: ['./body-orders-validation.component.scss',
  './../../generate-price/body-generate-price/body-generate-price.component.scss']
})
export class BodyOrdersValidationComponent implements OnInit {
  //It is a boolean that represents if the button is disabled or not
  disabledButton: boolean = true;

  //It is a boolean that represents if the details are being displayed or not
  detailsVisible: boolean = false;

  //It is a boolean that represents if the form is being displayed or not
  formVisible: boolean = true;

  count=0;

  optionsSubscription!:Subscription;
  userSubscription!:Subscription;
  constructor(private store:Store<appState>) {}

  ngOnInit(): void {
    this.optionsSubscription=this.store.select('options').subscribe(options=>{
      this.showDetailsAndForm(options.sizeView);
    });
  }

  ngOnDestroy(): void {
    this.optionsSubscription.unsubscribe();
  }
  
  showForm(showForm:boolean){
    this.formVisible=showForm;
    this.detailsVisible=!showForm;
    this.count++;
  }

  showDetails(showDetails:boolean){
    this.detailsVisible=showDetails;
    this.formVisible=!showDetails;
    this.count++;
  }

  showDetailsAndForm(sizeView:number){
    if(sizeView > SIZE_OF_VIEW_MOBILE) {
      this.detailsVisible=true;
      this.formVisible=true;
      this.count=0
    } else if(this.count==0) {
      this.detailsVisible=true;
      this.formVisible=!this.detailsVisible
    }
  }

}

