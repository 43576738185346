import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormProformBuyerComponent } from './form-proform-buyer.component';
import { WayPayModule } from '../../fields/way-pay/way-pay.module';
import { QualityModule } from '../../fields/quality/quality.module';
import { PlaceOfDeliveryModule } from '../../fields/place-of-delivery/place-of-delivery.module';
import { SelectInputModule } from '../../inputs/select/select.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';

@NgModule({
  declarations: [
    FormProformBuyerComponent
  ],
  imports: [
    CommonModule,
    WayPayModule,
    QualityModule,
    PlaceOfDeliveryModule,
    SelectInputModule,
    ArrowSvgModule
  ], exports:[
    FormProformBuyerComponent
  ]
})
export class FormProformBuyerModule { }