import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CONDITIONS_TYPE_TO_FIX_CONST } from 'src/app/shared/const/to-fix';
import { businessDict } from 'src/app/shared/dict/typeBusiness';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { Price, RangeDate } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator, showSlidePriceDetails } from 'src/app/shared/price';
import { ToFixValidator, isRequiredQuality } from 'src/app/shared/to-fix';
import { Clipboard } from '@angular/cdk/clipboard';
import { PRICE_STATUS_CONST } from 'src/app/shared/const/prices.const';
import { createOrderWithPrice } from 'src/app/shared/order';
import { Router } from '@angular/router';
import { getDate, getDateTomorrow, getDaysBetweenDates } from 'src/app/shared/date.checker';

const emojis:any={
  'Soja': '🌱',
  'Maiz': '🌽',
  'Trigo': '🌾',
  'Girasol': '🌻',
  'Arroz': '🍚',
  'Sorgo': '🌰'
}

const emojiDefault='🍃'
const emojiDolar='💸'
const emojiPesos='💰'

@Component({
  selector: 'card-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class CardIndicatorsComponent implements OnInit {
  @Input() price!:Price;
  @Input() isSelected:boolean=false;
  isInactive:boolean=false;
  @Output() selected= new EventEmitter<Price>();

  productName: string='';
  businessType: string='';
  placeOfDelivery: string='';
  quality: string='';
  conditionType: string='';
  validFor: string='';
  indicators:Indicator[]=[]
  bussParts:string[]=[];

  showValidFor:boolean=false;
  showQualityIndicators:boolean=false;
  showPlaceIndicators:boolean=false;

  constructor(private store:Store<appState>, private clipboard:Clipboard, 
    private priceV:PriceValidator, private toFixV:ToFixValidator, private router:Router){}

  ngOnInit(): void {
    this.isInactive=this.price.status==PRICE_STATUS_CONST.INACTIVO;

    this.bussParts=this.priceV.getBusinessParticularities(this.price?.businessParticularities);

    this.productName = this.priceV.getProductName(this.price);
    this.businessType = this.getBusinessType(this.price);

    const placDelv = this.priceV.getPlaceOfDelivery(this.price?.placeOfDelivery).split('-');
    this.placeOfDelivery = placDelv[1]? placDelv[1].trim(): placDelv[0];

    this.quality = this.priceV.getQuality(this.price);
    this.conditionType = this.getConditionType(this.price);    
    this.indicators = this.price?.indicators ?? [];
    this.showQualityIndicators = this.isSomeDifferentQuality(this.price);

    this.showValidFor= (this.price.deliveryPeriod.validFor ?? []).length>0;
    if(this.showValidFor && this.price.deliveryPeriod.validFor){
      this.validFor='Válido hasta: '+ this.priceV.getValidUntilText(this.price);
    }
  }

  getBusinessType(price:Price){
    if(price.typeBusiness==businessDict.forward){
      return this.priceV.getDeliveryPeriod(price);
    } else if(price.typeBusiness==businessDict.depositos){
      return this.priceV.getBusinessType(price);
    } else {
      /**
       * Negocios con descarga y sin descarga:
       * -Empieza hoy y no dura 30 dias que diga al xxx.
       * -Empieza hoy y dura 30 dias dice el tipo de negocio.
       * -No Empieza hoy y dura 30 dias que diga desde xxx.
       * -No empieza hoy y no dura 30 dias que diga desde xxx al xxx.
       */
      const isStartedTomorrow= price.deliveryPeriod.startDate== getDate()? '': ' desde '+ price.deliveryPeriod.startDate;
      const is30Days= getDaysBetweenDates(price.deliveryPeriod.startDate, price.deliveryPeriod.endDate)==30? '': ' al '+ price.deliveryPeriod.endDate;

      return this.priceV.getBusinessType(price) + isStartedTomorrow + is30Days;
    }
  }

  getConditionType(price:Price){
    const condTyp=price.conditionType;
    return condTyp == CONDITIONS_TYPE_TO_FIX_CONST.TRADICIONAL? '': this.toFixV.getConditionType(condTyp);
  }

  getIndicatorInfo(indicator:Indicator){
    const fixationPeriod= this.toFixV.getFixedPeriod(indicator);
    const indicName= this.toFixV.getIndicatorNameSummary(indicator);
    const place= this.toFixV.getPlace(indicator);
    const quality:string= this.showQualityIndicators && isRequiredQuality(indicator.indicatorName)? 
    ' | '+this.toFixV.getQuality(indicator):'';
    const product= this.price.conditionType==CONDITIONS_TYPE_TO_FIX_CONST.PORCENTAJE_PRODUCTO? ' | ' + this.toFixV.getPercentageAndProductName(indicator, ' '):'';

    return fixationPeriod + ' | ' + indicName + ' | ' + place + quality + product;
  }
  
  getHaveBonifOrReduc(indicator:Indicator){
    return indicator.bonification.value>0? 'bonus': indicator.reduction.value>0? 'reduction': '';
  }

  getBonifOrReduc(indicator:Indicator){
    const haveBonifOrReduc= this.getHaveBonifOrReduc(indicator);
    if(haveBonifOrReduc=='bonus') return '+'+ this.toFixV.getBonification(indicator);
    if(haveBonifOrReduc=='reduction') return '-'+ this.toFixV.getReduction(indicator);
    return '';
  }

  /*Given a price, it takes its quality and its indicators, 
  it goes through the indicators and if there is one that has the quality field obtained from the price, 
  then it returns true. But false.*/
  isSomeDifferentQuality(price:Price){
    const quality= price.quality;
    return this.indicators.some(indicator=> indicator.quality != quality);
  }

  //--------- ACTIONS BUTTONS ---------\\
  selectedPrice(){
    this.selected.emit(this.price);
  }

  createOrderWithPrice(price:Price){
    createOrderWithPrice(price, this.store, this.router)
  }
  
  showDetails(price:Price){
    showSlidePriceDetails(this.store, price);
  }

  //--- BUTTONS ---\\
  @ViewChild('card') card!: ElementRef; 

  copyInfo(card:any){
    const textToCopy = this.getTextToCopy(card); // Define esta función para obtener el texto que deseas copiar
    this.clipboard.copy(textToCopy);
  }

  getTextToCopy(cardElement:any): string {
    const headers = cardElement.querySelectorAll('.info-header');
    const indicators = cardElement.querySelectorAll('.description-indicator');
    let textToCopy = (emojis[this.productName] ?? emojiDefault) + ' ';

    //Primero copiamos la información del header
    headers.forEach((span: HTMLElement) => {
      textToCopy += span.textContent;
    });

    //Agregamos un salto de linea
    textToCopy += '\n\n';

    //Luego copiamos la información de los indicadores
    indicators.forEach((span: HTMLElement) => {
      textToCopy += span.textContent + '\n'; // Agregar un salto de linea
    });
    textToCopy=textToCopy.replace(/USD/g, 'USD ' + emojiDolar);
    textToCopy=textToCopy.replace(/ARS/g, 'ARS ' + emojiPesos);
    //textToCopy=textToCopy.replace(/- /g, '-');
    //textToCopy=textToCopy.replace(/+ /g, '+');

    this.priceV.showModalWithMessage('Copiado al portapapeles')

    return textToCopy.trim(); // Eliminar espacios en blanco al principio y al final
  }
}