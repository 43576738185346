import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { loadExchangesAllSuccess } from 'src/app/redux/actions/exchange.action';
import { setLocation } from 'src/app/redux/actions/options.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { ExchangeService } from 'src/app/shared/services/exchange.service';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { SessionChecker } from 'src/app/shared/session.checker';
import { WebSocketFunctions } from 'src/app/shared/websocket/websocket';

@Component({
  selector: 'app-exchange-list',
  templateUrl: './exchange-list.component.html',
  styleUrls: ['./../../shared/styles/body.scss']
})
export class ExchangeListComponent extends WebSocketFunctions implements OnInit, OnDestroy {
  private orders:OrderExchange[] = [];
  private exchange$!:Subscription;

  constructor(private store:Store<appState>, private checker:SessionChecker, private dateC:DateChecker, private exchangeSvc:ExchangeService,
    maestroSvc:MaestrosService) {
    super(environment.rutas.websocket.exchanges, maestroSvc);
    this.loadExchanges();
  
    this.checker.rememberAndActualizateUser(this.store);
    this.setSubscriptions();
    this.store.dispatch(setLocation({location: NAVEGATION.ORDER_EXCHANGE}));
  }

  ngOnInit(): void {
    this.checkTables();
  }

  ngOnDestroy(): void {
    this.exchange$?.unsubscribe();
    this.closeConnection();
  }

  setSubscriptions(){
    this.exchange$=this.store.select('exchange').subscribe( exchange => {
      this.orders = exchange.exchangeBoard.all;
    });
  }

  loadExchanges(){
    const date=this.dateC.getDateInYYYYMMDDformat(this.dateC.stringToDate(this.dateC.getDate()));
    this.exchangeSvc.getExchangesByDate(date).pipe(
      catchError(
        (error) => {
          console.error(error);
          this.setExchanges([]);
          return [];
        }
      ),
    ).subscribe( (res:ResponseRequest) => {
      this.setExchanges(res?.data ? res.data: []);
    })
  }

  override functionToMessage(event: any): void {
      console.log(JSON.parse(event.data))
      if(event.data !== JSON.stringify(this.orders)){
        const orders=JSON.parse(event.data)
        this.setExchanges(orders);
      }
  }
  
  setExchanges(orders:OrderExchange[]){
    let almacenamiento= !orders? []: orders;
    localStorage.setItem(localStorageNames.ordersExchange, JSON.stringify(almacenamiento));
    this.store.dispatch(loadExchangesAllSuccess({orders: almacenamiento}));
  }
}