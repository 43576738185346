import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsEyeComponent } from './details-eye.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { OrderComponent } from './order/order.component';
import { PriceComponent } from './price/price.component';
import { BuyerModule } from '../../fields/buyer/buyer.module';
import { DetailsSmallModule } from '../../details/small-detail/details.module';
import { TextInputModule } from '../../inputs/text/text.module';
import { EditSvgModule } from 'src/app/shared/styles/svg/edit/edit-svg.module';
import { StatusTagModule } from '../../tags/status/status.module';
import { ClockSvgModule } from 'src/app/shared/styles/svg/clock/clock-svg.module';

@NgModule({
  declarations: [
    DetailsEyeComponent,
    OrderComponent,
    PriceComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule,
    BuyerModule,
    DetailsSmallModule,
    StatusTagModule,
    TextInputModule,

    //SVG
    ArrowSvgModule,
    EditSvgModule,
    ClockSvgModule
  ],
  exports: [
    DetailsEyeComponent,
    OrderComponent,
    PriceComponent
  ],
})
export class DetailsEyeModule { }
