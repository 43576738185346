export const laPampaLocalities=[
    {"afip":"62","location":"Abramo"},
    {"afip":"103","location":"Adolfo Van Praet"},
    {"afip":"144","location":"Agua De Torre"},
    {"afip":"204","location":"Aguas Buenas"},
    {"afip":"227","location":"Agustoni"},
    {"afip":"323","location":"Alfredo Peña"}
    ,
    {"afip":"335","location":"Algarrobo Del Aguila"},
    {"afip":"370","location":"Alpachiri"},
    {"afip":"377","location":"Alta Italia"},
    {"afip":"582","location":"Anguil"},
    {"afip":"621","location":"Anzoategui"},
    {"afip":"647","location":"Arata"},
    {"afip":"653","location":"Arbol De La Esperanza"},
    {"afip":"679","location":"Argentina Belvedere"},
    {"afip":"889","location":"Arturo Almaraz"},
    {"afip":"915","location":"Ataliva Roca"},
    {"afip":"922","location":"Atreuco"},
    {"afip":"957","location":"Azteazu"},
    {"afip":"1027","location":"Bajo De Las Palomas"},
    {"afip":"1149","location":"Balo Los Morros"},
    {"afip":"1247","location":"Barrio El Molino"},
    {"afip":"1428","location":"Belgrano"},
    {"afip":"1467","location":"Bernardo Larroude"},
    {"afip":"1469","location":"Bernasconi"},
    {"afip":"1520","location":"Boeuf"},
    {"afip":"1523","location":"Boliche La Araña"}
    ,
    {"afip":"1617","location":"Butalo"},
    {"afip":"1682","location":"Cachirulo"},
    {"afip":"1702","location":"Caichue"},
    {"afip":"1706","location":"Caimi"},
    {"afip":"1730","location":"Calchahue"},
    {"afip":"1753","location":"Caleu Caleu"},
    {"afip":"1754","location":"Caleufu"},
    {"afip":"1860","location":"Campo Caretto"},
    {"afip":"1865","location":"Campo Cicare"},
    {"afip":"1886","location":"Campo De Los Toros"},
    {"afip":"1888","location":"Campo De Salas"},
    {"afip":"1945","location":"Campo La Florida"},
    {"afip":"1966","location":"Campo Ludueña"}
    ,
    {"afip":"1970","location":"Campo Moises Seccion 1A"},
    {"afip":"1975","location":"Campo Nicholson"},
    {"afip":"1984","location":"Campo Pico"},
    {"afip":"2005","location":"Campo Salusso"},
    {"afip":"2020","location":"Campo Urdaniz"},
    {"afip":"2158","location":"Caraman"},
    {"afip":"2185","location":"Carlos Berg"},
    {"afip":"2238","location":"Carro Quemado"},
    {"afip":"2336","location":"Catrilo"},
    {"afip":"2352","location":"Cayupan"},
    {"afip":"2479","location":"Ceballos"},
    {"afip":"2512","location":"Cereales"},
    {"afip":"2547","location":"Cerro Azul"},
    {"afip":"2552","location":"Cerro Bayo"},
    {"afip":"2604","location":"Cerro Del Aigre"},
    {"afip":"2645","location":"Cerro La Bota"},
    {"afip":"2783","location":"Chacharramendi"},
    {"afip":"2786","location":"Chacra 16"},
    {"afip":"2790","location":"Chacra La Casilda"},
    {"afip":"2791","location":"Chacra La Magdalena"},
    {"afip":"2801","location":"Chacras De Victorica"},
    {"afip":"2810","location":"Chacu"},
    {"afip":"2825","location":"Chamaico"},
    {"afip":"2836","location":"Chanilao"},
    {"afip":"2837","location":"Chapalco"},
    {"afip":"2924","location":"Chicalco"},
    {"afip":"3155","location":"Colonia  La India"},
    {"afip":"3159","location":"Colonia 17 De Agosto"},
    {"afip":"3180","location":"Colonia Aguirre"},
    {"afip":"3204","location":"Colonia Anasagasti"},
    {"afip":"3232","location":"Colonia Baron"},
    {"afip":"3239","location":"Colonia Beatriz"},
    {"afip":"3240","location":"Colonia Beaufort"},
    {"afip":"3297","location":"Colonia Cazaux"},
    {"afip":"3346","location":"Colonia Denevi"},
    {"afip":"3347","location":"Colonia Devoto"},
    {"afip":"3364","location":"Colonia Echeta"},
    {"afip":"3379","location":"Colonia El Destino"},
    {"afip":"3395","location":"Colonia El Porvenir"},
    {"afip":"3422","location":"Colonia España"}
    ,
    {"afip":"3425","location":"Colonia Espiga De Oro"},
    {"afip":"3437","location":"Colonia Ferraro"},
    {"afip":"3464","location":"Colonia Giusti"},
    {"afip":"3465","location":"Colonia Gobernador Ayala"},
    {"afip":"3478","location":"Colonia Guiburg N 2"},
    {"afip":"3485","location":"Colonia Helvecia"},
    {"afip":"3531","location":"Colonia La Abundancia"},
    {"afip":"3532","location":"Colonia La Amarga"},
    {"afip":"3542","location":"Colonia La Carlota"},
    {"afip":"3549","location":"Colonia La Chispa"},
    {"afip":"3561","location":"Colonia La Gaviota"},
    {"afip":"3572","location":"Colonia La Margarita"},
    {"afip":"3582","location":"Colonia La Mutua"},
    {"afip":"3588","location":"Colonia La Oracion"},
    {"afip":"3593","location":"Colonia La Pastoril"},
    {"afip":"3604","location":"Colonia La Sara"},
    {"afip":"3617","location":"Colonia Lagos"},
    {"afip":"3624","location":"Colonia Las Mercedes"},
    {"afip":"3626","location":"Colonia Las Piedritas"},
    {"afip":"3628","location":"Colonia Las Tres Piedras"},
    {"afip":"3629","location":"Colonia Las Vizcacheras"},
    {"afip":"3636","location":"Colonia Lia Y Allende"},
    {"afip":"3655","location":"Colonia Los Piojos"},
    {"afip":"3659","location":"Colonia Los Toros"},
    {"afip":"3670","location":"Colonia Luna"},
    {"afip":"3698","location":"Colonia Medano Colorado"},
    {"afip":"3704","location":"Colonia Migliori"},
    {"afip":"3707","location":"Colonia Ministro Lobos"},
    {"afip":"3805","location":"Colonia Ramon Quintas"},
    {"afip":"3816","location":"Colonia Roca"},
    {"afip":"3833","location":"Colonia San Basilio"},
    {"afip":"3841","location":"Colonia San Felipe"},
    {"afip":"3851","location":"Colonia San Lorenzo"},
    {"afip":"3862","location":"Colonia San Rosario"},
    {"afip":"3863","location":"Colonia San Victorio"},
    {"afip":"3868","location":"Colonia Santa Cecilia"},
    {"afip":"3869","location":"Colonia Santa Clara"},
    {"afip":"3886","location":"Colonia Santa Teresa"},
    {"afip":"3902","location":"Colonia Sobadell"},
    {"afip":"3921","location":"Colonia Torello"},
    {"afip":"3927","location":"Colonia Trenquenda"},
    {"afip":"3928","location":"Colonia Trequen"},
    {"afip":"3943","location":"Colonia Vascongada"},
    {"afip":"3953","location":"Colonia Villa Alba"},
    {"afip":"3972","location":"Colonias Drysdale"},
    {"afip":"3973","location":"Colonias Murray"},
    {"afip":"4003","location":"Cona Lauquen"},
    {"afip":"4024","location":"Conhelo"},
    {"afip":"4098","location":"Coronel Hilarios Lagos"},
    {"afip":"4195","location":"Costa Del Salado"},
    {"afip":"4224","location":"Cotita"},
    {"afip":"4312","location":"Cuchillo  Co"},
    {"afip":"4351","location":"Curilco"},
    {"afip":"4352","location":"Curru Mahuida"},
    {"afip":"4480","location":"Doblas"},
    {"afip":"4536","location":"Dorila"},
    {"afip":"4540","location":"Dos Amigos"},
    {"afip":"4544","location":"Dos Chañares"}
    ,
    {"afip":"4545","location":"Dos De Ipiña"}
    ,
    {"afip":"4562","location":"Dos Violetas"},
    {"afip":"4597","location":"Eduardo Castex"},
    {"afip":"4621","location":"El Aguila"},
    {"afip":"4651","location":"El Antojo"},
    {"afip":"4699","location":"El Belgica"},
    {"afip":"4760","location":"El Carancho"},
    {"afip":"4836","location":"El Chillen"},
    {"afip":"4860","location":"El Cinco"},
    {"afip":"4914","location":"El Descanso  Lonquimay"},
    {"afip":"4917","location":"El Deslinde"},
    {"afip":"4921","location":"El Destino  Rolon"},
    {"afip":"4928","location":"El Diez"},
    {"afip":"4929","location":"El Diez Y Siete"},
    {"afip":"4945","location":"El Escabel"},
    {"afip":"4955","location":"El Eucalipto"},
    {"afip":"4956","location":"El Eucalipto Carro Quemado"},
    {"afip":"4973","location":"El Furlong"},
    {"afip":"4995","location":"El Guaicuru"},
    {"afip":"5000","location":"El Guanaco  Winifreda"},
    {"afip":"5015","location":"El Huitru"},
    {"afip":"5059","location":"El Madrigal"},
    {"afip":"5062","location":"El Malacate"},
    {"afip":"5079","location":"El Mate"},
    {"afip":"5087","location":"El Mirador De Juarez"},
    {"afip":"5131","location":"El Nueve"},
    {"afip":"5133","location":"El Oasis"},
    {"afip":"5138","location":"El Odre"},
    {"afip":"5143","location":"El Olivo"},
    {"afip":"5205","location":"El Peludo"},
    {"afip":"5229","location":"El Pimia"},
    {"afip":"5343","location":"El Rubi"},
    {"afip":"5412","location":"El Tartagal"},
    {"afip":"5431","location":"El Tordillo"},
    {"afip":"5446","location":"El Trece"},
    {"afip":"5464","location":"El Uno"},
    {"afip":"5476","location":"El Vasquito"},
    {"afip":"5487","location":"El Veraneo"},
    {"afip":"5535","location":"Embajador Martini"},
    {"afip":"5550","location":"Emilio Mitre"},
    {"afip":"5594","location":"Epu Pel"},
    {"afip":"5658","location":"Establecimiento El Centinela"},
    {"afip":"5858","location":"Estancia La Lucha"},
    {"afip":"5864","location":"Estancia La Pampeana"},
    {"afip":"5880","location":"Estancia La Voluntad"},
    {"afip":"6023","location":"Euskadi"},
    {"afip":"6060","location":"Falucho"},
    {"afip":"6302","location":"Gallinao"},
    {"afip":"6346","location":"Gaviotas"},
    {"afip":"6353","location":"General Acha"},
    {"afip":"6401","location":"General Pico"},
    {"afip":"6429","location":"Gervasio Ortiz De Rosas"},
    {"afip":"6450","location":"Gobernador Ayala"},
    {"afip":"6456","location":"Gobernador Duval"},
    {"afip":"6519","location":"Gral Manuel Campos"},
    {"afip":"6558","location":"Guadaloza"},
    {"afip":"6587","location":"Guaraco"},
    {"afip":"6607","location":"Guatrache"},
    {"afip":"6704","location":"Hidalgo"},
    {"afip":"6723","location":"Hipolito Yrigoyen"},
    {"afip":"6812","location":"Hucal"},
    {"afip":"6820","location":"Huelen"},
    {"afip":"6905","location":"Ines Y Carlota"},
    {"afip":"6929","location":"Ingeniero Foster"},
    {"afip":"6936","location":"Ingeniero Luiggi"},
    {"afip":"6975","location":"Intendente Alvear"},
    {"afip":"6989","location":"Ipiña"}
    ,
    {"afip":"7098","location":"Ivanowsky"},
    {"afip":"7106","location":"Jacinto Arauz"},
    {"afip":"7114","location":"Jaguel Del Esquinero"},
    {"afip":"7117","location":"Jaguel Del Monte"},
    {"afip":"7129","location":"Jardon"},
    {"afip":"7231","location":"Julian A Mansilla"},
    {"afip":"7267","location":"Juzgado Viejo"},
    {"afip":"7291","location":"La Administracion"},
    {"afip":"7324","location":"La Antonia"},
    {"afip":"7327","location":"La Araña"}
    ,
    {"afip":"7333","location":"La Asturiana"},
    {"afip":"7335","location":"La Atalaya"},
    {"afip":"7338","location":"La Avanzada"},
    {"afip":"7351","location":"La Banderita"},
    {"afip":"7365","location":"La Baya"},
    {"afip":"7366","location":"La Baya Muerta"},
    {"afip":"7373","location":"La Bilbaina"},
    {"afip":"7384","location":"La Bota"},
    {"afip":"7424","location":"La Carmen"},
    {"afip":"7426","location":"La Carola"},
    {"afip":"7436","location":"La Casilda"},
    {"afip":"7441","location":"La Catalina"},
    {"afip":"7442","location":"La Catalinita"},
    {"afip":"7443","location":"La Catita"},
    {"afip":"7455","location":"La Celmira"},
    {"afip":"7466","location":"La Chapelle"},
    {"afip":"7485","location":"La Chirlandia"},
    {"afip":"7487","location":"La Chita"},
    {"afip":"7488","location":"La Chita Puelches"},
    {"afip":"7500","location":"La Clelia"},
    {"afip":"7513","location":"La Colorada Grande"},
    {"afip":"7518","location":"La Constancia  Anguil"},
    {"afip":"7520","location":"La Copelina"},
    {"afip":"7563","location":"La Dolores"},
    {"afip":"7577","location":"La Elenita"},
    {"afip":"7578","location":"La Elia"},
    {"afip":"7580","location":"La Elina"},
    {"afip":"7594","location":"La Energia"},
    {"afip":"7595","location":"La Enriqueta"},
    {"afip":"7604","location":"La Esmeralda  Macachin"},
    {"afip":"7606","location":"La Esperanza  Anguil"},
    {"afip":"7607","location":"La Esperanza  Hidalgo"},
    {"afip":"7608","location":"La Esperanza  Macachin"},
    {"afip":"7611","location":"La Esperanza Vertiz"},
    {"afip":"7624","location":"La Esther"},
    {"afip":"7627","location":"La Estrella Del Sud"},
    {"afip":"7630","location":"La Eulogia"},
    {"afip":"7673","location":"La Gavenita"},
    {"afip":"7693","location":"La Guadalosa"},
    {"afip":"7697","location":"La Gueñita"}
    ,
    {"afip":"7718","location":"La Humada"},
    {"afip":"7723","location":"La Imarra"},
    {"afip":"7741","location":"La Japonesa"},
    {"afip":"7747","location":"La Josefina"},
    {"afip":"7750","location":"La Juanita"},
    {"afip":"7763","location":"La Laurentina"},
    {"afip":"7771","location":"La Leña"}
    ,
    {"afip":"7790","location":"La Lonja"},
    {"afip":"7791","location":"La Lucha"},
    {"afip":"7792","location":"La Lucha La Reforma"},
    {"afip":"7808","location":"La Malvina"},
    {"afip":"7814","location":"La Manuelita"},
    {"afip":"7817","location":"La Marcela"},
    {"afip":"7821","location":"La Maria Elena"},
    {"afip":"7822","location":"La Maria Elisa"},
    {"afip":"7826","location":"La Maria Rosa"},
    {"afip":"7827","location":"La Maria Vertiz"},
    {"afip":"7828","location":"La Marianita"},
    {"afip":"7833","location":"La Maruja"},
    {"afip":"7858","location":"La Moderna"},
    {"afip":"7877","location":"La Nilda"},
    {"afip":"7882","location":"La Nueva Provincia"},
    {"afip":"7888","location":"La Olla"},
    {"afip":"7891","location":"La Oracion"},
    {"afip":"7918","location":"La Pampeana"},
    {"afip":"7919","location":"La Pampita"},
    {"afip":"7920","location":"La Pampita  Hidalgo"},
    {"afip":"7932","location":"La Paulina"},
    {"afip":"7940","location":"La Pencosa"},
    {"afip":"7957","location":"La Piedad"},
    {"afip":"7980","location":"La Pomona"},
    {"afip":"7992","location":"La Primavera  Miguel Riglos"},
    {"afip":"7993","location":"La Primavera  Santa Rosa"},
    {"afip":"7994","location":"La Primavera Chamaico"},
    {"afip":"7995","location":"La Primera"},
    {"afip":"8007","location":"La Puma"},
    {"afip":"8008","location":"La Puna"},
    {"afip":"8015","location":"La Puñalada"}
    ,
    {"afip":"8031","location":"La Razon Santa Isabel"},
    {"afip":"8033","location":"La Rebeca"},
    {"afip":"8039","location":"La Reforma Vieja"},
    {"afip":"8047","location":"La Reserva  Anguil"},
    {"afip":"8048","location":"La Reserva Ivanowsky"},
    {"afip":"8061","location":"La Rosa"},
    {"afip":"8089","location":"La Segunda"},
    {"afip":"8104","location":"La Sin Nombre"},
    {"afip":"8111","location":"La Sorpresa"},
    {"afip":"8113","location":"La Suerte"},
    {"afip":"8126","location":"La Teresita"},
    {"afip":"8129","location":"La Tinajera"},
    {"afip":"8136","location":"La Torera"},
    {"afip":"8139","location":"La Tosca"},
    {"afip":"8156","location":"La Unida"},
    {"afip":"8169","location":"La Veintitres"},
    {"afip":"8176","location":"La Verde  Anguil"},
    {"afip":"8193","location":"La Voluntad"},
    {"afip":"8205","location":"La Zota"},
    {"afip":"8208","location":"Labal"},
    {"afip":"8474","location":"Las Dos Naciones"},
    {"afip":"8485","location":"Las Felicitas"},
    {"afip":"8497","location":"Las Gaviotas"},
    {"afip":"8749","location":"Legasa"},
    {"afip":"8763","location":"Leona Redonda"},
    {"afip":"8790","location":"Lihue Calel"},
    {"afip":"8797","location":"Limay Mahuida"},
    {"afip":"8807","location":"Lindo Ver"},
    {"afip":"8896","location":"Lomas De Gatica"},
    {"afip":"8909","location":"Lomas Ombu"},
    {"afip":"8930","location":"Lonquimay"},
    {"afip":"8931","location":"Loo Co"},
    {"afip":"9102","location":"Los Dos Hermanos"},
    {"afip":"9213","location":"Los Morros"},
    {"afip":"9230","location":"Los Olivos"},
    {"afip":"9269","location":"Los Pirineos"},
    {"afip":"9293","location":"Los Quinientos"},
    {"afip":"9367","location":"Los Toros"},
    {"afip":"9379","location":"Los Turcos"},
    {"afip":"9403","location":"Loventuel"},
    {"afip":"9406","location":"Luan Toro"},
    {"afip":"9444","location":"Luna"},
    {"afip":"9463","location":"Macachin"},
    {"afip":"9506","location":"Maisonnave"},
    {"afip":"9628","location":"Maraco"},
    {"afip":"9629","location":"Maraco Chico"},
    {"afip":"9650","location":"Mari Mari"},
    {"afip":"9684","location":"Mariano Miro"},
    {"afip":"9749","location":"Mauricio Mayer"},
    {"afip":"9753","location":"Mayaco"},
    {"afip":"9788","location":"Medano Colorado"},
    {"afip":"9793","location":"Medanos Negros"},
    {"afip":"9843","location":"Metileo"},
    {"afip":"9858","location":"Miguel Riglos"},
    {"afip":"9916","location":"Minas De Sal"},
    {"afip":"9924","location":"Minerales De La Pampa"},
    {"afip":"9927","location":"Ministro Orlando"},
    {"afip":"9977","location":"Mocovi"},
    {"afip":"10067","location":"Monte Nievas"},
    {"afip":"10165","location":"Nahuel Napa"},
    {"afip":"10170","location":"Naico"},
    {"afip":"10174","location":"Nanquel Huitre"},
    {"afip":"10187","location":"Narciso Leven"},
    {"afip":"10213","location":"Nerre Co"},
    {"afip":"10225","location":"Nicolas Vera"},
    {"afip":"10345","location":"Oficial E Segura"},
    {"afip":"10347","location":"Ojeda"},
    {"afip":"10748","location":"Parera"},
    {"afip":"10752","location":"Parque Luro"},
    {"afip":"10813","location":"Paso De Los Algarrobos"},
    {"afip":"10822","location":"Paso De Los Puntanos"},
    {"afip":"10866","location":"Paso La Balsa"},
    {"afip":"10868","location":"Paso La Razon"},
    {"afip":"11013","location":"Peru"},
    {"afip":"11060","location":"Piche Cona Lauquen"},
    {"afip":"11062","location":"Pichi  Huilco"},
    {"afip":"11063","location":"Pichi  Huinca"},
    {"afip":"11069","location":"Pichi Merico"},
    {"afip":"11273","location":"Poitague"},
    {"afip":"11609","location":"Pueblo Alassa"},
    {"afip":"11651","location":"Pueblo Quintana"},
    {"afip":"11665","location":"Puelches"},
    {"afip":"11666","location":"Puelen"},
    {"afip":"12207","location":"Quehue"},
    {"afip":"12213","location":"Quemu Quemu"},
    {"afip":"12221","location":"Quetrequen"},
    {"afip":"12253","location":"Quintana"},
    {"afip":"12278","location":"Quiñi Malal"}
    ,
    {"afip":"12326","location":"Ramon Quintas"},
    {"afip":"12328","location":"Ramon Segundo"},
    {"afip":"12341","location":"Rancul"},
    {"afip":"12381","location":"Realico"},
    {"afip":"12410","location":"Relmo"},
    {"afip":"12414","location":"Remeco"},
    {"afip":"12461","location":"Ricardo Lavalle"},
    {"afip":"12680","location":"Rolon"},
    {"afip":"12719","location":"Rucahue"},
    {"afip":"12720","location":"Rucanelo"},
    {"afip":"12847","location":"Salinas Grandes  Anzoategui"},
    {"afip":"12848","location":"Salinas Grandes Hidalgo"},
    {"afip":"12849","location":"Salinas Mari Manuel"},
    {"afip":"12910","location":"San Aquilino"},
    {"afip":"12966","location":"San Francisco De La Ramada"},
    {"afip":"12987","location":"San Humberto"},
    {"afip":"12990","location":"San Ildefonso"},
    {"afip":"13003","location":"San Joaquin Metileo"},
    {"afip":"13006","location":"San Jose  Anguil"},
    {"afip":"13056","location":"San Juan Simson"},
    {"afip":"13074","location":"San Marcelo"},
    {"afip":"13116","location":"San Pedro  Rolon"},
    {"afip":"13141","location":"San Roberto"},
    {"afip":"13155","location":"San Urbano"},
    {"afip":"13182","location":"Santa Aurelia"},
    {"afip":"13210","location":"Santa Felicitas"},
    {"afip":"13215","location":"Santa Gracia"},
    {"afip":"13274","location":"Santa Stella"},
    {"afip":"13291","location":"Santiago Orellano"},
    {"afip":"13314","location":"Sarah"},
    {"afip":"13384","location":"Seccion Primera Conhello"},
    {"afip":"13467","location":"Sierras De Lihuel Calel"},
    {"afip":"13551","location":"Speluzzi"},
    {"afip":"13609","location":"Ta Huilco"},
    {"afip":"13802","location":"Telen"},
    {"afip":"13910","location":"Toay"},
    {"afip":"13937","location":"Tomas M De Anchorena"},
    {"afip":"14001","location":"Traico"},
    {"afip":"14002","location":"Traico Grande"},
    {"afip":"14022","location":"Trebolares"},
    {"afip":"14027","location":"Trenel"},
    {"afip":"14043","location":"Tres Botones"},
    {"afip":"14057","location":"Tres Hermanos  Macachin"},
    {"afip":"14058","location":"Tres Hermanos Quetrequen"},
    {"afip":"14091","location":"Tribuluci"},
    {"afip":"14097","location":"Trili"},
    {"afip":"14121","location":"Trubuluco"},
    {"afip":"14126","location":"Tte Gral Emilio Mitre"},
    {"afip":"14193","location":"Unanue"},
    {"afip":"14221","location":"Utracan"},
    {"afip":"14250","location":"Valle Argentino"},
    {"afip":"14253","location":"Valle Daza"},
    {"afip":"14331","location":"Vertiz"},
    {"afip":"14351","location":"Victorica"},
    {"afip":"14382","location":"Villa Alba"},
    {"afip":"14691","location":"Villa Mencuelle"},
    {"afip":"14694","location":"Villa Mirasol"},
    {"afip":"14968","location":"Winifreda"},
    {"afip":"15114","location":"Zona Rural"},
    {"afip":"15115","location":"Zona Rural  Metileo"},
    {"afip":"15116","location":"Zona Rural De Mirasol"},
    {"afip":"15117","location":"Zona Rural De Vertiz"},
    {"afip":"15118","location":"Zona Rural Dorila"},
    {"afip":"15119","location":"Zona Urbana Norte"},
    {"afip":"15150","location":"25 De Mayo"},
    {"afip":"15278","location":"Arbol Solo"},
    {"afip":"15351","location":"Barrancas Coloradas"},
    {"afip":"15383","location":"Bella Vista"},
    {"afip":"15675","location":"Colonia El Tigre"},
    {"afip":"15685","location":"Colonia La Esperanza"},
    {"afip":"15689","location":"Colonia La Paz"},
    {"afip":"15695","location":"Colonia Maria Luisa"},
    {"afip":"15713","location":"Colonia San Ignacio"},
    {"afip":"15719","location":"Colonia San Jose"},
    {"afip":"15721","location":"Colonia San Juan"},
    {"afip":"15728","location":"Colonia San Miguel"},
    {"afip":"15734","location":"Colonia Santa Ana"},
    {"afip":"15740","location":"Colonia Santa Elena"},
    {"afip":"15741","location":"Colonia Santa Elvira"},
    {"afip":"15744","location":"Colonia Santa Maria"},
    {"afip":"15916","location":"El Boqueron"},
    {"afip":"15926","location":"El Brillante"},
    {"afip":"15967","location":"El Centenario"},
    {"afip":"15969","location":"El Centinela"},
    {"afip":"16018","location":"El Descanso"},
    {"afip":"16025","location":"El Destino"},
    {"afip":"16042","location":"El Durazno"},
    {"afip":"16071","location":"El Guanaco"},
    {"afip":"16091","location":"El Lucero"},
    {"afip":"16115","location":"El Mirador"},
    {"afip":"16168","location":"El Palomar"},
    {"afip":"16185","location":"El Parque"},
    {"afip":"16215","location":"El Porvenir"},
    {"afip":"16261","location":"El Puma"},
    {"afip":"16283","location":"El Recreo"},
    {"afip":"16287","location":"El Refugio"},
    {"afip":"16299","location":"El Retiro"},
    {"afip":"16337","location":"El Salitral"},
    {"afip":"16352","location":"El Sauce"},
    {"afip":"16362","location":"El Silencio"},
    {"afip":"16379","location":"El Tajamar"},
    {"afip":"16394","location":"El Tigre"},
    {"afip":"16412","location":"El Trigo"},
    {"afip":"16413","location":"El Triunfo"},
    {"afip":"16435","location":"El Volante"},
    {"afip":"16545","location":"General San Martin"},
    {"afip":"16620","location":"La Adela"},
    {"afip":"16675","location":"La Aurora"},
    {"afip":"16688","location":"La Barrancosa"},
    {"afip":"16692","location":"La Blanca"},
    {"afip":"16733","location":"La Cautiva"},
    {"afip":"16747","location":"La Celia"},
    {"afip":"16753","location":"La Celina"},
    {"afip":"16768","location":"La Cienaga"},
    {"afip":"16797","location":"La Colorada Chica"},
    {"afip":"16802","location":"La Constancia"},
    {"afip":"16833","location":"La Cumbre"},
    {"afip":"16838","location":"La Delfina"},
    {"afip":"16841","location":"La Delicia"},
    {"afip":"16853","location":"La Elsa"},
    {"afip":"16855","location":"La Elva"},
    {"afip":"16856","location":"La Elvira"},
    {"afip":"16864","location":"La Escondida"},
    {"afip":"16867","location":"La Esmeralda"},
    {"afip":"16876","location":"La Esperanza"},
    {"afip":"16907","location":"La Estrella"},
    {"afip":"16921","location":"La Fe"},
    {"afip":"16931","location":"La Florencia"},
    {"afip":"16937","location":"La Florida"},
    {"afip":"16946","location":"La Fortuna"},
    {"afip":"16955","location":"La Gaviota"},
    {"afip":"16959","location":"La Gloria"},
    {"afip":"16997","location":"La Invernada"},
    {"afip":"17004","location":"La Isabel"},
    {"afip":"17047","location":"La Limpia"},
    {"afip":"17063","location":"La Luisa"},
    {"afip":"17067","location":"La Luz"},
    {"afip":"17068","location":"La Magdalena"},
    {"afip":"17081","location":"La Margarita"},
    {"afip":"17085","location":"La Maria"},
    {"afip":"17094","location":"La Matilde"},
    {"afip":"17109","location":"La Morocha"},
    {"afip":"17120","location":"La Nueva"},
    {"afip":"17136","location":"La Paloma"},
    {"afip":"17147","location":"La Pastoril"},
    {"afip":"17152","location":"La Paz"},
    {"afip":"17159","location":"La Perla"},
    {"afip":"17160","location":"La Perlita"},
    {"afip":"17183","location":"La Porteña"}
    ,
    {"afip":"17194","location":"La Pradera"},
    {"afip":"17198","location":"La Primavera"},
    {"afip":"17241","location":"La Razon"},
    {"afip":"17282","location":"La Sarita"},
    {"afip":"17298","location":"La Soledad"},
    {"afip":"17330","location":"La Trinidad"},
    {"afip":"17336","location":"La Union"},
    {"afip":"17343","location":"La Vanguardia"},
    {"afip":"17347","location":"La Verde"},
    {"afip":"17356","location":"La Victoria"},
    {"afip":"17359","location":"La Violeta"},
    {"afip":"17412","location":"Las Acacias"},
    {"afip":"17459","location":"Las Chacras"},
    {"afip":"17497","location":"Las Delicias"},
    {"afip":"17568","location":"Las Malvinas"},
    {"afip":"17631","location":"Las Piedritas"},
    {"afip":"17642","location":"Las Quintas"},
    {"afip":"17685","location":"Las Tres Hermanas"},
    {"afip":"17741","location":"Loma Redonda"},
    {"afip":"17759","location":"Los Alamos"},
    {"afip":"17767","location":"Los Algarrobos"},
    {"afip":"17871","location":"Los Manantiales"},
    {"afip":"17899","location":"Los Nogales"},
    {"afip":"17964","location":"Los Tajamares"},
    {"afip":"17970","location":"Los Tres Pozos"},
    {"afip":"17993","location":"Malvinas Argentinas"},
    {"afip":"17999","location":"Manantiales"},
    {"afip":"18011","location":"Maria"},
    {"afip":"18025","location":"Medano Blanco"},
    {"afip":"18043","location":"Miguel Cane"},
    {"afip":"18083","location":"Monte Ralo"},
    {"afip":"18133","location":"Ojo De Agua"},
    {"afip":"18227","location":"Pavon"},
    {"afip":"18554","location":"Salinas"},
    {"afip":"18569","location":"San Alberto"},
    {"afip":"18579","location":"San Andres"},
    {"afip":"18591","location":"San Antonio"},
    {"afip":"18601","location":"San Basilio"},
    {"afip":"18603","location":"San Benito"},
    {"afip":"18608","location":"San Bernardo"},
    {"afip":"18619","location":"San Carlos"},
    {"afip":"18637","location":"San Diego"},
    {"afip":"18640","location":"San Eduardo"},
    {"afip":"18644","location":"San Emilio"},
    {"afip":"18646","location":"San Ernesto"},
    {"afip":"18652","location":"San Felipe"},
    {"afip":"18661","location":"San Fernando"},
    {"afip":"18670","location":"San Francisco"},
    {"afip":"18694","location":"San Hilario"},
    {"afip":"18697","location":"San Ignacio"},
    {"afip":"18732","location":"San Joaquin"},
    {"afip":"18749","location":"San Jose"},
    {"afip":"18763","location":"San Juan"},
    {"afip":"18779","location":"San Justo"},
    {"afip":"18808","location":"San Manuel"},
    {"afip":"18830","location":"San Miguel"},
    {"afip":"18858","location":"San Pedro"},
    {"afip":"18881","location":"San Ramon"},
    {"afip":"18902","location":"San Salvador"},
    {"afip":"18913","location":"San Simon"},
    {"afip":"18933","location":"Santa Ana"},
    {"afip":"18952","location":"Santa Catalina"},
    {"afip":"18960","location":"Santa Clara"},
    {"afip":"18975","location":"Santa Elena"},
    {"afip":"18982","location":"Santa Elvira"},
    {"afip":"18989","location":"Santa Ines"},
    {"afip":"18998","location":"Santa Isabel"},
    {"afip":"19024","location":"Santa Maria"},
    {"afip":"19051","location":"Santa Rosa"},
    {"afip":"19078","location":"Santo Domingo"},
    {"afip":"19091","location":"Santo Tomas"},
    {"afip":"19141","location":"Sol De Mayo"},
    {"afip":"19237","location":"Tres Lagunas"},
    {"afip":"19245","location":"Tres Naciones"},
    {"afip":"19266","location":"Uriburu"},
    {"afip":"19329","location":"Villa San Jose"},
    {"afip":"19349","location":"Vista Alegre"},
    {"afip":"20845","location":"La Puma"},
    {"afip":"20846","location":"San Miguel"},
    {"afip":"20847","location":"El Guanaco"},
    {"afip":"20848","location":"Las Delicias"},
    {"afip":"20849","location":"La Elina"},
    {"afip":"20850","location":"La Celina"},
    {"afip":"20851","location":"Zona Rural"},
    {"afip":"20852","location":"Barrancas Coloradas"},
    {"afip":"20853","location":"El Boqueron"},
    {"afip":"20854","location":"La Magdalena"},
    {"afip":"20855","location":"Santa Elena"},
    {"afip":"20856","location":"Valle Argentino"},
    {"afip":"20877","location":"La Juanita"},
    {"afip":"20878","location":"La Victoria"},
    {"afip":"20879","location":"San Jose"},
    {"afip":"20880","location":"San Jose"},
    {"afip":"20881","location":"La Esperanza"},
    {"afip":"20882","location":"La Juanita"},
    {"afip":"20883","location":"La Victoria"},
    {"afip":"20884","location":"Remeco"},
    {"afip":"20885","location":"San Jose"},
    {"afip":"20886","location":"San Juan"},
    {"afip":"20887","location":"El Porvenir"},
    {"afip":"20888","location":"Dos Amigos"},
    {"afip":"20889","location":"El Destino"},
    {"afip":"20890","location":"La Catalina"},
    {"afip":"20891","location":"La Constancia"},
    {"afip":"20892","location":"La Esmeralda"},
    {"afip":"20893","location":"La Isabel"},
    {"afip":"20894","location":"La Union"},
    {"afip":"20895","location":"San Jose"},
    {"afip":"20896","location":"La Maria"},
    {"afip":"20897","location":"La Verde"},
    {"afip":"20898","location":"San Joaquin"},
    {"afip":"20899","location":"San Jose"},
    {"afip":"21772","location":"Potrillo Oscuro"},
    {"afip":"21773","location":"Tomas M. De Anchorena"},
    {"afip":"22084","location":"Coronel Hilario Lagos"},
    {"afip":"22085","location":"Arteazu"},
    {"afip":"22086","location":"General Manuel Campos"},
    {"afip":"22087","location":"Alfredo Peña"}
    ,
    {"afip":"22088","location":"Arguil"},
    {"afip":"22089","location":"Lote 22"},
    {"afip":"22286","location":"Colonia El Sauzal"},
    {"afip":"22287","location":"Colonia Chica"}
    ]