import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { RouterModule } from '@angular/router';
import { DashboardSvgModule } from 'src/app/shared/styles/svg/dashboard/dashboard-svg.module';
import { OrderListSvgModule } from 'src/app/shared/styles/svg/order-list/order-list.module';
import { GenerateOrderSvgModule } from 'src/app/shared/styles/svg/generate-order/generate-order.module';
import { GeneratePriceSvgModule } from 'src/app/shared/styles/svg/generate-price/generate-price.module';
import { PriceBoardSvgModule } from 'src/app/shared/styles/svg/price-board/price-board-svg.module';
import { EraserSvgModule } from 'src/app/shared/styles/svg/eraser/eraser-svg.module';
import { ExchangeOrderListSvgModule } from 'src/app/shared/styles/svg/exchange-order/exchange-order.module';
import { ControlPanelSvgModule } from 'src/app/shared/styles/svg/control-panel/control-panel.module';
import { HistoricalSvgModule } from 'src/app/shared/styles/svg/historical/historical-svg.module';
import { ProfileSvgModule } from 'src/app/shared/styles/svg/profile/profile-svg.module';

@NgModule({
  declarations: [
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DashboardSvgModule,
    ProfileSvgModule,
    OrderListSvgModule,
    GenerateOrderSvgModule,
    GeneratePriceSvgModule,
    PriceBoardSvgModule,
    EraserSvgModule,
    ExchangeOrderListSvgModule,
    ControlPanelSvgModule,
    HistoricalSvgModule
  ],
  exports:[MenuComponent]
})
export class MenuModule { }
