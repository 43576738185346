//--------------------DESARROLLO--------------------\\
export const urlIBM = 'https://dev-be-ibm.servicios.grassisa.com.ar';
export const urlLogin = 'https://dev-be-login.servicios.grassisa.com.ar';

export const URL_USERS = 'https://dev-ms-be-login.servicios.grassisa.com.ar';
export const URL_PRICES = 'https://dev-ms-be-prices.servicios.grassisa.com.ar';
export const URL_ORDER = 'https://dev-ms-be-orders.servicios.grassisa.com.ar';
export const URL_EXCHANGE = 'https://dev-ms-be-exchange.servicios.grassisa.com.ar';

export const urlWS = 'wss://dev-websocket.servicios.grassisa.com.ar';
export const urlCore = 'https://dev-be-core.servicios.grassisa.com.ar';
export const ambiente = 'desarrollo';

export const environment = {
  production: false,
  rutas: {
    getToken: URL_USERS + '/auth/login',
    getRefreshToken: URL_USERS + '/auth/renew-token',
    claim:
      'https://api.openweathermap.org/data/2.5/weather?lat=-32.94682&lon=-60.63932&appid=c2016502a8d8d7b4da7c147d8bc7d042&units=metric',
    dashboard: {
      getAccumulatedTonnage: urlCore + '/pandh',
      getFutureMarketsParams:
        'https://api.remarkets.primary.com.ar/rest/marketdata/get?marketId=ROFX&symbol=DODic21&entries=BI',
      getFutureMarkets:
        'https://api.remarkets.primary.com.ar/rest/marketdata/get',
      getTokenFutureMarkets:
        'https://api.remarkets.primary.com.ar/auth/getToken',
    },
    maestros: {
      getDB: urlLogin + '/db',
      getCount: urlLogin + '/count',
    },
    prices: {
      createPrice: URL_PRICES + '/price', // LISTO
      editPriceNumber: URL_PRICES + '/price', //Pasar ID
      deletePrice: URL_PRICES + '/price', //Pasar ID - LISTO
      updatePrices: URL_PRICES + '/price', //Pasar ID - LISTO
      historical: URL_PRICES + '/price/{id}/historical-price', //Pasar ID
      getPricesByDate: URL_PRICES + '/prices', //Pasar fecha   - LISTO
    },
    orders: {
      createOrder: URL_ORDER + '/order', //POST       //LISTO
      updateOrder: URL_ORDER + '/order', //POST        //LISTO
      getOrderByID: URL_ORDER + '/order', //GET        //LISTO
      getFirstVersion: URL_ORDER + '/original', //GET  //LISTO
      getOrderByContract: URL_ORDER + '/orders', //GET //LISTO
      getOrdersByDate: URL_ORDER + '/orders', //GET    //LISTO
      getOrdersByRange: URL_ORDER + '/orders', //GET   //LISTO
      editOrderNumber: URL_ORDER + '/order', //PUT     //LISTO
      historical: URL_ORDER + '/order/{id}/historical-orders', //LISTO
    },
    exchanges: {
      createExchange: URL_EXCHANGE + '/exchange', //POST
      updateExchange: URL_EXCHANGE + '/exchange/{id}', //PUT
      deleteExchange: URL_EXCHANGE + '/exchange/{id}', //DELETE
      getOrderByID: URL_EXCHANGE + '/exchange/{id}', //GET
      getContraparts: URL_EXCHANGE + '/exchange/{id}/counter-parts', //GET
      getExchangesByDate: URL_EXCHANGE + '/exchanges', //GET
      getExchangesByRange: URL_EXCHANGE + '/exchanges', //GET
    },
    /*orders: {
      getOrderByID: urlCore + '/getorder', //Listo
      getOrderByContract: urlCore + '/getcontract',
      createOrder: urlCore + '/order', //Listo
      editOrderNumber: urlCore + '/replaceint', //Listo
      historical: urlCore + '/getho', //Listo
      updateOrder: urlCore + '/replaceorder', //Listo
      getOrdersByDate: urlCore + '/bydate', //Listo
      getOrdersByRange: urlCore + '/getrange', //Listo
      getFirstVersion: urlCore + '/getorderO', //Listo
    },

    exchanges: {
      createExchange: urlCore + '/exchange-create', //Listo
      updateExchange: urlCore + '/exchange-replace', //Listo
      deleteExchange: urlCore + '/deletexch', //Listo
      getOrderByID: urlCore + '/exchange-by-id', //Listo
      getContraparts: urlCore + '/getcontractex',
      getExchangesByDate: urlCore + '/bydatee',
      getExchangesByRange: urlCore + '/getrangex', //Listo
    },*/
    views: {
      search: urlCore + '/viewV',
      searchAll: urlCore + '/view',
      send: urlCore + '/view',
      update: urlCore + '/view',
    },
    ibm: {
      createContract: urlIBM + '/setibm',
      validation: urlIBM + '/ibm',
    },
    websocket: {
      orders: urlWS + '/orders',
      prices: urlWS + '/prices',
      exchanges: urlWS + '/exchange',
    },
  },
};
