import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceMarketCardComponent } from './price-market.component';

@NgModule({
  declarations: [
    PriceMarketCardComponent,
  ],
  imports: [
    CommonModule
  ], exports:[
    PriceMarketCardComponent
  ]
})
export class PriceMarketCardModule { }