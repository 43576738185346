import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicatorsTableComponent } from './indicators.component';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [
    IndicatorsTableComponent
  ],
  imports: [
    CommonModule,
    ThreePointsSvgModule,
    MatSlideToggleModule,
    PipesModule,
  ], exports: [
    IndicatorsTableComponent
  ]
})
export class IndicatorsTableModule { }
