<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(18)">Anticipo financiero</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" (change)="setOptionOpenWithCheck($event,18)" 
            (click)="verifyFinancialAdvance(financialAdvance)" [checked]="getIsFinancialAdvance()" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="container-sub-section">
            <span class="title-sub-section">IVA</span>
        </div>

        <div>
            <div class="option-content-particularity">
                <span class="option-name">Sin IVA</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(1,'VAT')" [checked]="withIVA==1" class="radio" />
                </div>
            </div>

            <div class="option-content-particularity">
                <span class="option-name">Con IVA</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(2,'VAT')" [checked]="withIVA==2" class="radio" />
                </div>
            </div>

            <div class="option-content-particularity">
                <span class="option-name">A determinar</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(3,'VAT')" [checked]="withIVA==3" class="radio" />
                </div>
            </div>
        </div>

        <div class="line light-grey"></div>


        <div class="container-sub-section row-between">
            <span class="title-sub-section">Tasa</span>
        </div>

        <div>
            <div class="option-content-particularity">
                <span class="option-name">A determinar</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(1,'rate')" [checked]="financialAdvance.rate==1" class="radio" />
                </div>
            </div>

            <div class="option-content-particularity">
                <span class="option-name">Determinada</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(2,'rate')" [checked]="financialAdvance.rate==2" class="radio" />
                </div>
            </div>
        </div>

        <div class="container-sub-section" style="margin-top:4px;" *ngIf="financialAdvance.rate==2">
            <number-input [initialPlaceHolder]="'Ingresar tasa'" [value]="financialAdvance.amountRate" 
            (valueOut)="setValueWithTag($event, 'amountRate')"></number-input>
        </div>

        
        <div class="line light-grey"></div>


        <div class="container-sub-section">
            <span class="title-sub-section">Tipo de porcentaje a pagar</span>
        </div>

        <div>
            <div class="option-content-particularity">
                <span class="option-name">A determinar</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(1, 'percentageTypeToPay')" [checked]="financialAdvance.percentageTypeToPay==1" class="radio" />
                </div>
            </div>

            <div class="option-content-particularity">
                <span class="option-name">Determinado</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(2, 'percentageTypeToPay')" [checked]="financialAdvance.percentageTypeToPay==2" class="radio" />
                </div>
            </div>

            <div class="option-content-particularity">
                <span class="option-name">Por defecto</span>
                <div class="actions">
                    <input type="radio" (click)="setValueWithTag(3, 'percentageTypeToPay')" [checked]="financialAdvance.percentageTypeToPay==3" class="radio" />
                </div>
            </div>
        </div>


        <div *ngIf="financialAdvance.percentageTypeToPay==2" >
            <number-input [initialPlaceHolder]="'Ingresar porcentage a pagar'" [value]="financialAdvance.percentagePayable" 
            (valueOut)="setValueWithTag($event, 'percentagePayable')"></number-input>
        </div>

        
        <div class="line light-grey"></div>
    </div>
</div>