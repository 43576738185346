import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

declare var gapi:any;
const CLIENT_ID:string="313888454615-f6tnmff1964pee1ch3hdqfbf4v115r5i.apps.googleusercontent.com"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    constructor(private afauth: SocialAuthService) {
    }

    async signInWithGoogle() {
      return await this.afauth.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    async loginWithGoogle() {
      return new Promise((resolve, reject) => {
        gapi.load('auth2', () => {
            gapi.auth2.init({
                client_id: CLIENT_ID,
                cookiepolicy: 'single_host_origin',
                scope: 'profile email'
            }).then(() => {
                const auth2 = gapi.auth2.getAuthInstance();
                if (!auth2) {
                    reject(new Error('No se pudo obtener una instancia de auth2.'));
                } else {
                    auth2.signIn().then(() => {
                        // Popup opened successfully, user can sign in
                        const user = auth2.currentUser.get().getBasicProfile();
                        resolve(auth2);
                    }).catch((error:any) => {
                        // Error opening popup
                        reject(error);
                    });
                }
            }).catch((error:any) => {
                reject(error);
            });
        });
      });
    }
  
    signOut(): void {
      try {
        this.afauth.signOut();
      } catch (error) {
        //If the user reloads the page his 
        //session expires and this will bring an error
      }
    }
}