<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(13)">Convenio laboratorio</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="laboratoryAgreement>0" (change)="setOptionOpenWithCheck($event,13)" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="field" style="margin-bottom:16px;">
            <span class="field-title">Ingresar tasa porcentual</span>

            <number-input [value]="laboratoryAgreement" [initialPlaceHolder]="'Ingresar tasa porcentual'"
            (valueOut)="setPercentage($event)"></number-input>
        </div>
    </div>
</div>