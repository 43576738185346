
<div class="many-orders">
    <div class="card" [ngClass]="{'selected': isOrderSelected(i)}" *ngFor="let order of orders; index as i">
        <div class="card-basic-info row-between not-visible-in-mobile pointer" (click)="editOrder(i)">
            <div class="basic-info column-left">
                <span class="order-nro">Orden de venta N° {{i+1}}</span>
                <span class="seller">Vendedor: {{getSellerNameAndTons(order)}}</span>
            </div>

            <div class="actions row-between">
                <edit-svg-do class="action" (click)="editOrder(i)" [active]="isOrderSelected(i)"></edit-svg-do>
                <!-- <trash-svg-do class="action" (click)="deleteOrder(i)"></trash-svg-do> -->
                <!--<duplicate-order-svg-do class="action" (click)="duplicateOrder(i)"></duplicate-order-svg-do>-->
                <!--<commission-svg-do class="action" (click)="openOneCommission(i)" [active]="isOrderSelected(i)"></commission-svg-do>-->
                <commission-svg-do class="action" (click)="openOneCommission(i)" [active]="false"></commission-svg-do>
                <arrow-svg class="arrow" [direction]="getArrowDirection(i)" (click)="showOrder(i)"></arrow-svg>
            </div>

            <!--<app-commission class="commission" *ngIf="showCommission==i" (close)="closeCommisionEvent($event, showCommission)" 
            (commission)="setCommission($event, showCommission)" [valueSeller]="order.commision.seller" [valueBuyer]="getBuyerCommision(order)"></app-commission>-->
        </div>

        <div *ngIf="indexOfShowOrder==i" class="card-info">
            <app-info-order [order]="order"></app-info-order>
            <div class="header" *ngIf="isOrderToFix(order)">
                <h3 class="text title">Condición a fijar</h3>
            </div>
            <app-info-to-fix *ngIf="isOrderToFix(order)" [conditionType]="getConditionType(order)" [indicators]="getIndicators(order)"></app-info-to-fix>
            
        </div>

        <div class="body width-100 visible-in-mobile">
            <div class="features">
                <div class="row-between width-100">
                    <span class="seller">{{getSellerName(order)}}</span>
                    <span class="tons">{{order.tons <= 0? '--': order.tons | numberPipe}} toneladas</span>
                </div>
            
                <div class="row-between width-100">
                    <span class="product-name">
                        <img alt="product-icon" src="../../../../../assets/images/products/{{getProduct(order)}}.svg" />
                    {{getProduct(order)}}   </span>
                    <span class="price {{getTypeCoin(order).toLowerCase()}}">{{getPriceOfCondition(order) | numberPipe}} {{getTypeCoin(order)}}</span>
                </div>
            </div>
            <app-info-order [order]="order"></app-info-order>
        </div>
    </div>
</div>