import { Component, OnInit,OnDestroy,HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { SessionChecker } from 'src/app/shared/session.checker';
import { hiddenSplash, showSplash } from 'src/app/redux/actions/login.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy{

  splashLogin: any= {
    path: './../../assets/lotties/splash-login.json',
    loop: false,
  };

  splashInTransition: boolean = true;
  isUserLogged:boolean=false;
  count:number=0;
  showAnimation:boolean=true;

  private store$!:Subscription;
  constructor(private store: Store<appState>, private checker: SessionChecker) {
    this.checker.rememberAndActualizateUser(store);
  }

  ngOnInit(): void {
    this.splash();
    
    this.store$=this.store.subscribe(res => {
      this.splashInTransition = res.login.splashInTransition;
      this.isUserLogged= res.usuario.email!='';
      this.goToHome();
    });
  }

  ngOnDestroy(): void {
    this.store$?.unsubscribe();
    
    this.store.dispatch(showSplash());
  }

  splash(){
    setTimeout(() => {
      this.store.dispatch(hiddenSplash());
      this.showAnimation=false;
    }, 2500);
  }
  

  closeSplash(event:any){
    this.store.dispatch(hiddenSplash());
    this.showAnimation=false;
  }

  goToHome(){
    if(this.isUserLogged && this.count==0){
      this.count++;
      window.location.href = '#/'+NAVEGATION.HOME;
    }
  }
}
