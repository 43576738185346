<section class="body">
    <div class="tab" *ngIf="formVisible">
        <div class="rectangle {{!isPort}}"></div>
        <div class="tablinks {{!isPort}} commodities" [ngClass]="{'selected': !isPort}" (click)="setIsExport(false)">Commodities</div>
        <div class="tablinks {{isPort}} export" [ngClass]="{'selected': isPort}" (click)="setIsExport(true)">Compradores</div>
    </div>

    <div class="radios-buttons" *ngIf="formVisible">
        <app-buttons-condition-type (valueOut)="setIsOrderToPrice($event)"></app-buttons-condition-type>

        <button class="view-details not-visible-in-desktop" (click)="showDetails(true)">Ver detalle</button>
    </div>

    <div class="inform">
        <app-form-price class="form" *ngIf="formVisible" (applyButtonEvent)="showDetails($event)"></app-form-price>

        <app-details *ngIf="detailsVisible" class="details"></app-details>
        <img *ngIf="detailsVisible" (click)="showDetails(false)" class="button-edit" alt="button-edit" src="../../../../../assets/images/extra/button-edit.svg"/>

        <button *ngIf="detailsVisible" type="button" [disabled]="disabledButton" [ngClass]="{'disabled': disabledButton}" 
        class="filled-button continue not-visible-in-desktop"
        (click)="confirmPrice()">
            Confirmar
        </button>
    </div>
</section>