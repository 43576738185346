import { Pipe, PipeTransform } from '@angular/core';
import { buyerType } from '../../const/options';
import { Price } from '../../interfaces/price.interface';

@Pipe({
  name: 'isPriceOfCommodities'
})
export class FilterPriceByCommoditiesPipe implements PipeTransform {

  transform(priceList: Price[], identificator: string) {
    if(identificator == buyerType.ALL) return priceList;

    return priceList.filter(price=> isFromCommodities(price,identificator) )
  }
}


@Pipe({
  name: 'isOrderOfCommodities'
})
export class FilterOrderByCommoditiesPipe implements PipeTransform {

  transform(orderList: any[], identificator: string) {
    if(identificator == buyerType.ALL){ return orderList }
    
    let orderListToReturn: any[] = [];
    orderListToReturn=orderList.filter(order=> isFromCommodities(order.price, identificator) )

    return orderListToReturn;
  }
}

export function isFromCommodities(price: Price, identificator: string){
  return (price.observations.isPort==1) == (identificator==buyerType.BUYER) || identificator == buyerType.ALL
}
