import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { initialPrice, initialPriceString, priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-harvest',
  templateUrl: './harvest.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class HarvestComponent extends Field implements OnInit {

  constructor(store: Store<appState>, private date:DateChecker) {
    super(store);
    this.array = this.date.getHarvest();
    this.initalElementSelected = 'Ingresar una cosecha';
    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.harvest;
      setTimeout( () => {
        this.setInitialElement();
      }, 1000) //When we create a price we only set the initial harvest
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.harvest;
    }
    this.setIdentifyClassName();
  }
  
  override rememberValueInForm(){
    this.rememberValueTypeFour()
  }

  /*Take the current year and subtract 1 from it.
Pass the number to string and note which element of the array begins like this.*/
  setInitialElement(){
    try{
      const price=this.getPrice();
      const year = new Date().getFullYear()  - 1;
      const element=this.array.find((element) => element.startsWith(year.toString()));
      const index=this.array.indexOf(element);
      if(price.harvest == '--'){
        this.setElement(element,index)
      }
    } catch(err){}
  }

  override setElement(value:any, index:number){
    if(isNaN(value)){
      this.setElementToGenerate(value);
    } else {
      this.setElementToGenerate(initialPrice.harvest);
    }
  }

  override save() {
    let newList = this.pipe.transform(this.array, this.search, this.OBJECT,priceTags.harvest);

    if (newList.length > 0) {
      this.setElement(newList[0], 0);
    }

    this.search=this.elementSelected;
    this.setChangeShowOptions(false);
  }


  override setPlaceHolder(){
    try{
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT, priceTags.harvest);
      if(newList.length > 0 && this.search){
        this.placeHolder= this.removeFirstLetters(newList[0], this.search);
      } else {
        this.placeHolder= '';
      }
    } catch (err) {
      this.placeHolder='';
    }
    
  }
}
