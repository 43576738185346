<div class="body">
    <div class="header">
        <span class="title">Historial de cambios</span>
        <cross-svg class="cross" (click)="close()"></cross-svg>
    </div>

    <app-header-details class="width-100" [order]="order"></app-header-details>

    <div class="history-information">
        <div class="row-center">
            <span class="field">{{messageInfo}}</span>
        </div>

        <div *ngIf="historicalArray else emptyState">
            <div *ngFor="let historical of historicalArray" class="history">
                

                <div class="row-between width-100 container-all-info">
                    <div class="info-container user">
                        <span class="header-info">Usuario</span>
                        <span class="description-info">{{getNameOfChanger(historical)}}</span>
                    </div>

                    <div class="info-container hour">
                        <span class="header-info">Hora</span>
                        <span class="description-info">{{getHour(historical.createdAt)}}</span>
                    </div>

                    <div class="info-container status">
                        <span class="header-info">Estado</span>
                        <span class="description-info">{{getValue(historical)}}</span>
                    </div>                

                    <div class="info-container obs">
                        <span class="header-info">Observación</span>
                        <span class="description-info">{{historical.obs? historical.obs:'--'}}</span>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #emptyState>
            <div class="empty-state" *ngIf="!isError else showError">
                <span class="empty-state-text">Aún no hay ningun cambio en está
                    condición de negocio.</span>
            </div>
            <ng-template #showError>

            </ng-template>
        </ng-template>
    </div>
</div>