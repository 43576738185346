import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setAccumulatedTonnage, setAccumulatedTonnageSuccess } from 'src/app/redux/actions/dashboard.action';
import { DateChecker, getHourByDate } from 'src/app/shared/date.checker';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { AccumTonsLocalStorage, AccumulatedTonage } from 'src/app/shared/interfaces/dashboard.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import * as constantes from 'src/app/shared/const/productNames';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';

//Si deseamos agregar un nuevo producto para visualizar en Tonelaje acumulado, debemos agregarlo en
//interface.ts

@Component({
  selector: 'app-accumulated-tonnage',
  templateUrl: './accumulated-tonnage.component.html',
  styleUrls: ['./accumulated-tonnage.component.scss']
})
export class AccumulatedTonnageComponent implements OnInit, OnDestroy {
  panelOpenState = true;
  public accumulatedTonnages!:AccumulatedTonage[];
  public dateOfLatestConsultation!:Date|null;
  public orders!:Order[];
  public PRODUCTS:any;
  public harvestList:string[]=[]

  private orderSubscription!:Subscription;
  private dashboardSubscription!:Subscription;

  constructor(private store:Store<appState>, private date:DateChecker) {
    this.rememberAccumulatedTonnage();
  }

  ngOnInit(): void {
    this.dashboardSubscription=this.store.select('dashboard').subscribe(state => {
      this.accumulatedTonnages=state.accumulatedTonnage;
      this.dateOfLatestConsultation= state.dateAccumTons;
    })

    this.orderSubscription=this.store.select('order').subscribe(state => {
      this.orders=state.orderBoard.all;
    });


    this.PRODUCTS=PRODUCT_NAMES;
    this.harvestList=this.date.getHarvest();
    this.loadAccumulatedTonnage();
  }

  ngOnDestroy(): void {
    this.orderSubscription?.unsubscribe();
    this.dashboardSubscription?.unsubscribe();
  }

  rememberAccumulatedTonnage(){
    const accumTonsLocStor:AccumTonsLocalStorage=getAccumulatedTonnageLocalStorage();
    if(accumTonsLocStor){
      this.store.dispatch(setAccumulatedTonnageSuccess({accumulatedTonnage: accumTonsLocStor.dataDashboard, date: new Date(accumTonsLocStor.date)}));
    }
  }

  /*Takes the wave from local storage, 
  if it does not exist it or it exists and it is 1, it returns 0, 
  if it exists and it is 0, it assigns it 1*/
  getNewWave(){
    const wave=getAccumulatedTonnageLocalStorage()?.wave;
    return wave==undefined || wave==1? 0:1;
  }

  loadAccumulatedTonnage(){
    const firstHarvest=this.date.getHarvest()[1];
    const secondHarvest=this.date.getHarvest()[2];
    const thirdHarvest=this.date.getHarvest()[3];
    const wave=this.getNewWave();

    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.SOJA, harvest:firstHarvest , wave: wave}));
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.SOJA, harvest:secondHarvest, wave: wave}));
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.SOJA, harvest:thirdHarvest , wave: wave}));

    
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.TRIGO, harvest:firstHarvest , wave: wave}));
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.TRIGO, harvest:secondHarvest, wave: wave}));
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.TRIGO, harvest:thirdHarvest , wave: wave}));

    
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.MAIZ, harvest:firstHarvest , wave: wave}));
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.MAIZ, harvest:secondHarvest, wave: wave}));
    this.store.dispatch(setAccumulatedTonnage({productName:constantes.PRODUCT_NAMES.MAIZ, harvest:thirdHarvest , wave: wave}));
  }

  getHour(date:Date){
    return getHourByDate(date);
  }
}

export function getAccumulatedTonnageLocalStorage(){
  const accumTonsLocStor=localStorage.getItem(localStorageNames.accumulatedTonnage);
  return accumTonsLocStor? JSON.parse(accumTonsLocStor):null;
}
