import { createAction, props } from "@ngrx/store";
import { Exchange, ExchangeConfirmed, OrderExchange } from "src/app/shared/interfaces/order-exchange";
import { Order, OrderString } from "src/app/shared/interfaces/order.interface";
import { ViewOrderExchange } from "src/app/shared/interfaces/views.interface";


//Remember Orders
export const rememberOrdersData = createAction('[ORDER EXCHANGE] Remember Data',
    props<{ state: any }>());


export const loadExchangesBoard = createAction('[ORDER EXCHANGE] Load Orders Exchange',
    props<{ flag:string}>());
export const loadExchangesAllSuccess = createAction('[ORDER EXCHANGE] Load Orders Exchange All Success',
    props<{ orders: OrderExchange[] }>());
export const loadExchangesFailed = createAction('[ORDER EXCHANGE] Load Orders Exchange Failed');


//Generate Orders
export const setOrderToGenerateInExchange = createAction('[GENERATE ORDER EXCHANGE] Set Generate Order',
    props<{ order: Order }>());
export const setExchangeInOrderToGenerate = createAction('[GENERATE ORDER EXCHANGE] Set Exchange in Generate Order',
    props<{ exchange: Exchange }>());

export const setEditGenerateOrderExchange = createAction('[GENERATE ORDER EXCHANGE] Set Edit Generate Order',
    props<{ isEditGenerate: boolean }>());

export const setIsGenerateOrderExchange = createAction('[GENERATE ORDER EXCHANGE] Set Generate Order',
    props<{ isGenerateOrderExchange: boolean }>());
export const resetOrderExchangeToGenerate = createAction('[GENERATE ORDER EXCHANGE] Reset Order To Generate')

export const setIndexToEditOrderExchange = createAction('[GENERATE ORDER EXCHANGE] Set Index To Edit Order',
    props<{ index: number }>());
export const setGenerateOrdersExchange = createAction('[GENERATE ORDER EXCHANGE] Set Generate Orders',
    props<{ orders: OrderExchange[] }>());
export const resetOrdersToGenerateExchange = createAction('[GENERATE ORDER EXCHANGE] Reset Orders To Generate');



//Views of Order board
export const setViewToGenerateOrderExchange = createAction('[VIEW] Set View To Generate In ORDER EXCHANGE LIST',
    props<{ view: ViewOrderExchange }>());
export const setNewViewOrderExchange = createAction('[VIEW] Set New View In ORDER EXCHANGE LIST',
    props<{ view: ViewOrderExchange }>());
export const resetNewViewOrderExchange = createAction('[VIEW] Reset New View In ORDER EXCHANGE LIST');
export const resetViewSelectedOrderExchange = createAction('[VIEW] Reset View Selected In ORDER EXCHANGE LIST');
export const setViewSelectedOrderExchange = createAction('[VIEW] Set View Selected In ORDER EXCHANGE LIST',
    props<{ view: ViewOrderExchange }>());
export const setAllViewsOrderExchange = createAction('[VIEW] Set All Views In ORDER EXCHANGE LIST',
    props<{ views: ViewOrderExchange[] }>());
export const setIndexViewSelectedOrderExchange = createAction('[VIEW] Set Index Of View Selected In ORDER EXCHANGE LIST',
props<{ index: number }>());


export const setFilterOrderExchange = createAction('[FILTER ORDER] Set Filter Order Exchange',
    props<{ orderString: OrderString }>());
export const setBuyerTypeFilterExchange = createAction('[FILTER ORDER] Set Buyer Type Filter In Order Exchange List',
    props<{ buyerType: string }>())
export const setFilterIndexExchange= createAction('[FILTER ORDER] Set INDEX Filter In Order Exchange List',
    props<{ index:number }>());

    
//Details of order
export const setOrderExchangeDetails = createAction('[ORDER EXCHANGE DETAILS] Set Order Exchange Details',
props<{ order: OrderExchange }>());
export const showOrderExchangeDetails = createAction('[ORDER EXCHANGE DETAILS] Show Order Exchange Details', props<{ show: boolean }>());

//Add exchange
export const setAddExchange = createAction('[ADD EXCHANGE] Set Add Exchange',
    props<{ addExchange: boolean }>());

//EXCHANGE CONFIRMED
export const setExchangeConfirmed = createAction('[EXCHANGE CONFIRMED] Set Exchange Confirmed',
    props<{ exchangedConfirmed:ExchangeConfirmed }>());