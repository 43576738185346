import { Component, OnChanges } from '@angular/core';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-paf-garantizado',
  templateUrl: './paf-garantizado.component.html',
  styleUrls: ['./../options.component.scss']
})
export class PafGarantizadoComponent extends BusinessParticularityOption implements OnChanges {
  isGuaranteedPAF:boolean=false;

  constructor() { 
    super()
  }

  ngOnChanges(): void {
    try{
      this.isGuaranteedPAF=this.businessParticularitiesStruct.guaranteedPAF;
    } catch(err){
      this.isGuaranteedPAF=false;
    }
  }
  
  setGuaranteedPAF(){
    this.isGuaranteedPAF=!this.isGuaranteedPAF;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.guaranteedPAF=this.isGuaranteedPAF;
    this.emit2(newBP);
  }
}
