import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvenioNutrienComponent } from './convenio-nutrien.component';



@NgModule({
  declarations: [
    ConvenioNutrienComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    ConvenioNutrienComponent
  ]
})
export class ConvenioNutrienModule { }
