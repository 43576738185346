export const PROFORMAS_EXCHANGE =[
    {
      "tipoNeg": "N",
      "comprador": 897,
      "descPrecio": "0,70",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 897,
      "descPrecio": "0,70",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 902,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 902,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 855,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 855,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 959,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 959,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 890,
      "descPrecio": "2,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 890,
      "descPrecio": "2,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 132,
      "descPrecio": "1,25",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 132,
      "descPrecio": "1,25",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 972,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 972,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 48,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 48,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 499,
      "descPrecio": "0,00",
      "descConv": "0,50",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 499,
      "descPrecio": "0,00",
      "descConv": "0,50",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 80,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 80,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 849,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 849,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 630,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 630,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "19,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 882,
      "descPrecio": "1,40",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 882,
      "descPrecio": "1,40",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 857,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 857,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 254,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 254,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 658,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 658,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 619,
      "descPrecio": "1,50",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 619,
      "descPrecio": "1,50",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 914,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 914,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 913,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 913,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 865,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 865,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 680,
      "descPrecio": "1,25",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 680,
      "descPrecio": "1,25",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 694,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 694,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 381,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 381,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 922,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 922,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "17,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 89,
      "descPrecio": "0,00",
      "descConv": "0,50",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 89,
      "descPrecio": "0,00",
      "descConv": "0,50",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 90,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 90,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 222,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "44,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 222,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "60,00",
      "diasPago": "72,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 214,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 214,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 163,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 163,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 306,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 306,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 231,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 565,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 271,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 224,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 249,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 249,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 256,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 561,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 256,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 225,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 349,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 229,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 525,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 348,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 235,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 255,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 219,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 554,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 223,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 542,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 552,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 530,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 308,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 226,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 556,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 536,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 550,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 217,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 528,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 541,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 540,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 524,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 279,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 563,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 227,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 220,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 544,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 258,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 543,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 559,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 546,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 231,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 565,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 271,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 224,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 561,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 225,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 349,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 229,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 525,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 348,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 235,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 255,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 219,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 554,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 223,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 542,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 552,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 530,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 308,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 226,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 556,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 536,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 550,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 217,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 528,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 541,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 540,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 524,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 279,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 563,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 227,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 544,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 258,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 543,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 559,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 546,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 220,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 237,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 237,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "17,00",
      "diasPago": "3,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 37,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 37,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 290,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 290,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 600,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 600,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 263,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 263,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 773,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 773,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 871,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 871,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 798,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 798,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 886,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 886,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 338,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 338,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 527,
      "descPrecio": "2,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 527,
      "descPrecio": "2,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 887,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 887,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 317,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 317,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 46,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 46,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 16,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 16,
      "descPrecio": "0,00",
      "descConv": "1,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 213,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 213,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 651,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 651,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 895,
      "descPrecio": "1,50",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 895,
      "descPrecio": "1,50",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 932,
      "descPrecio": "0,00",
      "descConv": "1,25",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 932,
      "descPrecio": "0,00",
      "descConv": "1,25",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 866,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 866,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 98,
      "descPrecio": "1,20",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 98,
      "descPrecio": "1,20",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 106,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 106,
      "descPrecio": "0,00",
      "descConv": "1,50",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 129,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 129,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 167,
      "descPrecio": "1,50",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 171,
      "descPrecio": "1,50",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 243,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 243,
      "descPrecio": "1,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 268,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 268,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 366,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 366,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 383,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 383,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "5,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 875,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 875,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },    
    {
      "tipoNeg": "N",
      "comprador": 1724,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 1724,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "N",
      "comprador": 1287,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "18,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 1287,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "17,00",
      "diasPago": "0,00"
    },    
    {
      "tipoNeg": "N",
      "comprador": 179,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 179,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },    
    {
      "tipoNeg": "N",
      "comprador": 889,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 889,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },    {
      "tipoNeg": "N",
      "comprador": 1746,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "2,00",
      "diasPago": "0,00"
    },
    {
      "tipoNeg": "S",
      "comprador": 1746,
      "descPrecio": "0,00",
      "descConv": "0,00",
      "pago": "19,00",
      "diasPago": "5,00"
    },
  ]