
<div class="background-sin-color">
<svg class="svg-24" [ngClass]="{'active-svg':active}"  width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#DCDCDC"/>
    <g clip-path="url(#clip0_138_112826)">
    <path d="M14.625 18.4991H7.37503C6.61652 18.4991 6 17.8825 6 17.124V9.87405C6 9.11554 6.61652 8.49902 7.37503 8.49902H11.625C11.832 8.49902 12 8.66702 12 8.87402C12 9.08102 11.832 9.24902 11.625 9.24902H7.37503C7.03052 9.24902 6.75 9.52954 6.75 9.87405V17.124C6.75 17.4685 7.03052 17.7491 7.37503 17.7491H14.625C14.9695 17.7491 15.25 17.4685 15.25 17.124V12.8741C15.25 12.6671 15.418 12.4991 15.625 12.4991C15.832 12.4991 16 12.6666 16 12.8741V17.124C16 17.8825 15.3835 18.4991 14.625 18.4991Z" fill="white"/>
    <path d="M10.3855 14.488C10.287 14.488 10.191 14.449 10.1205 14.378C10.0315 14.2895 9.99352 14.162 10.0181 14.0395L10.3715 12.2715C10.386 12.1985 10.422 12.132 10.474 12.08L15.6526 6.90203C16.1885 6.36599 17.0606 6.36599 17.5971 6.90203C17.8565 7.16149 17.9995 7.50655 17.9995 7.87405C17.9995 8.24154 17.8565 8.58651 17.5965 8.84606L12.4186 14.0246C12.3666 14.077 12.2995 14.1125 12.227 14.127L10.4595 14.4805C10.435 14.4855 10.41 14.488 10.3855 14.488ZM12.1535 13.7595H12.1586H12.1535ZM11.0846 12.5301L10.864 13.635L11.9685 13.414L17.0665 8.31606C17.1845 8.1975 17.2495 8.04104 17.2495 7.87405C17.2495 7.70705 17.1845 7.5505 17.0665 7.43203C16.8235 7.1885 16.427 7.1885 16.1826 7.43203L11.0846 12.5301Z" fill="white"/>
    <path d="M16.6248 9.66339C16.5288 9.66339 16.4328 9.62686 16.3598 9.55334L14.9458 8.13885C14.7993 7.99237 14.7993 7.75488 14.9458 7.6084C15.0923 7.46191 15.3298 7.46191 15.4763 7.6084L16.8903 9.02289C17.0368 9.16937 17.0368 9.40686 16.8903 9.55334C16.8168 9.6264 16.7208 9.66339 16.6248 9.66339Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_138_112826">
    <rect width="12" height="12" fill="white" transform="translate(6 6.5)"/>
    </clipPath>
    </defs>
</svg>

<div class="tooltip down row-center w-50">
    <span>Editar</span>
</div>
</div>