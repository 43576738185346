export const misionesLocalities=[
    {"afip":"13","location":"22 De Diciembre"},
    {"afip":"69","location":"Acaragua"},
    {"afip":"244","location":"Alba Posse"},
    {"afip":"379","location":"Alta Union"},
    {"afip":"625","location":"Aparicio Cue"},
    {"afip":"634","location":"Apostoles"},
    {"afip":"687","location":"Aristobulo Del Valle"},
    {"afip":"703","location":"Arrechea"},
    {"afip":"775","location":"Arroyo Fedor"},
    {"afip":"786","location":"Arroyo Isabel"},
    {"afip":"809","location":"Arroyo Magdalena"},
    {"afip":"829","location":"Arroyo Pastora"},
    {"afip":"836","location":"Arroyo Persiguero"},
    {"afip":"856","location":"Arroyo Santa Maria"},
    {"afip":"871","location":"Arroyo Tomas"},
    {"afip":"873","location":"Arroyo Tunitas"},
    {"afip":"879","location":"Arroyo Yabebiri"},
    {"afip":"900","location":"Aserradero Echeverria"},
    {"afip":"902","location":"Aserradero Piñalito"}
    ,
    {"afip":"952","location":"Azara"},
    {"afip":"1186","location":"Barra Bonita"},
    {"afip":"1187","location":"Barra Concepcion"},
    {"afip":"1188","location":"Barracon"},
    {"afip":"1196","location":"Barrancon"},
    {"afip":"1244","location":"Barrio Don Santiago"},
    {"afip":"1339","location":"Bartolito"},
    {"afip":"1380","location":"Bayo Troncho"},
    {"afip":"1395","location":"Bañado Grande"}
    ,
    {"afip":"1465","location":"Bernardino Rivadavia"},
    {"afip":"1466","location":"Bernardo De Irigoyen"},
    {"afip":"1536","location":"Bonpland Norte"},
    {"afip":"1566","location":"Brazo Del Tacuaruzu"},
    {"afip":"1576","location":"Bretes Martires"},
    {"afip":"1628","location":"Caapora"},
    {"afip":"1668","location":"Cabure"},
    {"afip":"1708","location":"Cainguas"},
    {"afip":"1830","location":"Campiña De Bonpland"}
    ,
    {"afip":"1831","location":"Campiña Grande"}
    ,
    {"afip":"1832","location":"Campiñas De America"}
    ,
    {"afip":"1991","location":"Campo Ramon"},
    {"afip":"1995","location":"Campo Richardson"},
    {"afip":"2016","location":"Campo Tornquinst"},
    {"afip":"2023","location":"Campo Viera"},
    {"afip":"2033","location":"Campos Salles"},
    {"afip":"2127","location":"Capiovi"},
    {"afip":"2128","location":"Capiovisiño"}
    ,
    {"afip":"2131","location":"Capitan Antonio Morales"},
    {"afip":"2143","location":"Capivu"},
    {"afip":"2146","location":"Capueron"},
    {"afip":"2155","location":"Caraguatay"},
    {"afip":"2226","location":"Carrillo Viejo"},
    {"afip":"2328","location":"Cataratas Del Iguazu"},
    {"afip":"2574","location":"Cerro Cora"},
    {"afip":"2922","location":"Cheroguita"},
    {"afip":"2976","location":"Chirimay"},
    {"afip":"3055","location":"Citrus"},
    {"afip":"3190","location":"Colonia Alicia"},
    {"afip":"3192","location":"Colonia Almafuerte"},
    {"afip":"3210","location":"Colonia Apostoles"},
    {"afip":"3213","location":"Colonia Aristobulo Del Valle"},
    {"afip":"3221","location":"Colonia Aurora"},
    {"afip":"3224","location":"Colonia Azara"},
    {"afip":"3272","location":"Colonia Caa Guazu"},
    {"afip":"3285","location":"Colonia Capon Bonito"},
    {"afip":"3304","location":"Colonia Chafariz"},
    {"afip":"3306","location":"Colonia Chapa"},
    {"afip":"3325","location":"Colonia Cumanday"},
    {"afip":"3326","location":"Colonia Cunci"},
    {"afip":"3344","location":"Colonia Delicia"},
    {"afip":"3351","location":"Colonia Domingo Savio"},
    {"afip":"3361","location":"Colonia Duran"},
    {"afip":"3380","location":"Colonia El Doradillo"},
    {"afip":"3392","location":"Colonia El Pesado"},
    {"afip":"3397","location":"Colonia El Progreso"},
    {"afip":"3439","location":"Colonia Finlandesa"},
    {"afip":"3444","location":"Colonia Florida"},
    {"afip":"3446","location":"Colonia Fortaleza"},
    {"afip":"3470","location":"Colonia Gramado"},
    {"afip":"3476","location":"Colonia Guarani"},
    {"afip":"3509","location":"Colonia Japonesa"},
    {"afip":"3548","location":"Colonia La Chillita"},
    {"afip":"3564","location":"Colonia La Gruta"},
    {"afip":"3590","location":"Colonia La Otilia"},
    {"afip":"3597","location":"Colonia La Polaca"},
    {"afip":"3634","location":"Colonia Leiva"},
    {"afip":"3679","location":"Colonia Manuel Belgrano"},
    {"afip":"3687","location":"Colonia Martir Santa Maria"},
    {"afip":"3688","location":"Colonia Martires"},
    {"afip":"3711","location":"Colonia Mondori"},
    {"afip":"3755","location":"Colonia Oro Verde"},
    {"afip":"3762","location":"Colonia Paduan"},
    {"afip":"3767","location":"Colonia Palmera"},
    {"afip":"3789","location":"Colonia Polaca"},
    {"afip":"3790","location":"Colonia Polana"},
    {"afip":"3793","location":"Colonia Primavera"},
    {"afip":"3795","location":"Colonia Profundidad"},
    {"afip":"3803","location":"Colonia Puerto Rosales"},
    {"afip":"3817","location":"Colonia Roca Chica"},
    {"afip":"3846","location":"Colonia San Javier"},
    {"afip":"3894","location":"Colonia Segui"},
    {"afip":"3898","location":"Colonia Siete Estrellas"},
    {"afip":"3915","location":"Colonia Taranco"},
    {"afip":"3932","location":"Colonia Tres Marias"},
    {"afip":"3950","location":"Colonia Victoria"},
    {"afip":"3959","location":"Colonia Yabebiri"},
    {"afip":"3960","location":"Colonia Yacutinga"},
    {"afip":"3970","location":"Colonia ñacangazu"}
    ,
    {"afip":"3985","location":"Comandante Andresito"},
    {"afip":"4007","location":"Concepcion De La Sierra"},
    {"afip":"4123","location":"Corpus"},
    {"afip":"4205","location":"Costa Portera"},
    {"afip":"4246","location":"Cruce Caballero"},
    {"afip":"4247","location":"Cruce Londero"},
    {"afip":"4371","location":"Cuña Piru"}
    ,
    {"afip":"4372","location":"Cuña Pora"}
    ,
    {"afip":"4380","location":"Damus"},
    {"afip":"4406","location":"Deseado"},
    {"afip":"4412","location":"Desplayada"},
    {"afip":"4414","location":"Destacamento Bosques"},
    {"afip":"4505","location":"Domingo Barthe"},
    {"afip":"4524","location":"Don Horacio"},
    {"afip":"4568","location":"Doña Maria"}
    ,
    {"afip":"4610","location":"El 26"},
    {"afip":"4633","location":"El Alcazar"},
    {"afip":"4827","location":"El Chaton"},
    {"afip":"5057","location":"El Macaco"},
    {"afip":"5215","location":"El Persiguero"},
    {"afip":"5308","location":"El Rancho"},
    {"afip":"5322","location":"El Reposo"},
    {"afip":"5350","location":"El Saltiño"}
    ,
    {"afip":"5377","location":"El Soberbio"},
    {"afip":"5525","location":"Eldorado"},
    {"afip":"5586","location":"Ensanche Este"},
    {"afip":"5587","location":"Ensanche Norte"},
    {"afip":"5682","location":"Estacion Apostoles"},
    {"afip":"5701","location":"Estacion Experimental De Loret"},
    {"afip":"5828","location":"Estancia Itaembre"},
    {"afip":"5969","location":"Estancia Santa Rita"},
    {"afip":"6048","location":"Fachinal"},
    {"afip":"6105","location":"Filemon Pose"},
    {"afip":"6235","location":"Fracran"},
    {"afip":"6239","location":"Frances"},
    {"afip":"6334","location":"Garuhape"},
    {"afip":"6335","location":"Garupa"},
    {"afip":"6336","location":"Garupa Norte"},
    {"afip":"6462","location":"Gobernador Lanusse"},
    {"afip":"6474","location":"Gobernador Roca"},
    {"afip":"6517","location":"Gral Guemes"},
    {"afip":"6560","location":"Guaibichu"},
    {"afip":"6588","location":"Guarani"},
    {"afip":"6590","location":"Guaraypo"},
    {"afip":"6610","location":"Guayabera"},
    {"afip":"6685","location":"Hekenan"},
    {"afip":"6974","location":"Integracion"},
    {"afip":"6981","location":"Invernada Chica"},
    {"afip":"6982","location":"Invernada De Itacaruare"},
    {"afip":"6983","location":"Invernada Grande"},
    {"afip":"6984","location":"Invernada San Ignacio"},
    {"afip":"7016","location":"Isla Argentina"},
    {"afip":"7055","location":"Isla San Lucas"},
    {"afip":"7081","location":"Ita Curuzu"},
    {"afip":"7084","location":"Itacaruare"},
    {"afip":"7085","location":"Itaembre Mini"},
    {"afip":"7126","location":"Jardin America"},
    {"afip":"7852","location":"La Milagrosa"},
    {"afip":"7857","location":"La Misionera"},
    {"afip":"7897","location":"La Otilia"},
    {"afip":"7969","location":"La Plantadora"},
    {"afip":"8068","location":"La Rotonda"},
    {"afip":"8349","location":"Larraque"},
    {"afip":"8632","location":"Las Quemadas"},
    {"afip":"8812","location":"Linea Cuchilla"},
    {"afip":"8813","location":"Linea De Peray"},
    {"afip":"9354","location":"Los Teales"},
    {"afip":"9462","location":"Macaca"},
    {"afip":"9464","location":"Macaco"},
    {"afip":"9468","location":"Machadiño"}
    ,
    {"afip":"9499","location":"Mai Bao"},
    {"afip":"9590","location":"Manis"},
    {"afip":"9653","location":"Maria Antonia"},
    {"afip":"9673","location":"Maria Magdalena"},
    {"afip":"9772","location":"Mbopicua"},
    {"afip":"9779","location":"Mecking"},
    {"afip":"9829","location":"Mesa Redonda"},
    {"afip":"9854","location":"Miguel Guemes"},
    {"afip":"9856","location":"Miguel Lanus"},
    {"afip":"9973","location":"Mocona"},
    {"afip":"9988","location":"Mojon Grande"},
    {"afip":"10154","location":"Nacientes Del Isabel"},
    {"afip":"10155","location":"Nacientes Del Tunar"},
    {"afip":"10303","location":"Nueva Valencia"},
    {"afip":"10306","location":"Nueve De Julio"},
    {"afip":"10320","location":"Oasis"},
    {"afip":"10321","location":"Obera"},
    {"afip":"10323","location":"Obligado"},
    {"afip":"10386","location":"Once Vueltas"},
    {"afip":"10414","location":"Otilia"},
    {"afip":"10654","location":"Panambi"},
    {"afip":"10673","location":"Parada Leis"},
    {"afip":"10682","location":"Paraje Azopardo"},
    {"afip":"10689","location":"Paraje Dos Hermanas"},
    {"afip":"10695","location":"Paraje Estelina"},
    {"afip":"10698","location":"Paraje Granado"},
    {"afip":"10701","location":"Paraje Intercontinental"},
    {"afip":"10710","location":"Paraje Lucero"},
    {"afip":"10731","location":"Paraje Villa Union"},
    {"afip":"10742","location":"Paranay"},
    {"afip":"10836","location":"Paso Del Arroyo Persiguero"},
    {"afip":"10889","location":"Paso Porteño"}
    ,
    {"afip":"10920","location":"Pastoreo"},
    {"afip":"10929","location":"Pati Cua"},
    {"afip":"10973","location":"Pedro Nuñez"}
    ,
    {"afip":"11012","location":"Persiguero"},
    {"afip":"11038","location":"Picada Belgrano"},
    {"afip":"11039","location":"Picada Bonpland"},
    {"afip":"11040","location":"Picada Española"}
    ,
    {"afip":"11041","location":"Picada Finlandesa"},
    {"afip":"11042","location":"Picada Gobernador Lopez"},
    {"afip":"11043","location":"Picada Iglesia"},
    {"afip":"11044","location":"Picada Libertad"},
    {"afip":"11045","location":"Picada Polaca"},
    {"afip":"11046","location":"Picada Portuguesa"},
    {"afip":"11047","location":"Picada Pozo Feo"},
    {"afip":"11048","location":"Picada Rusa"},
    {"afip":"11049","location":"Picada San Javier"},
    {"afip":"11050","location":"Picada San Martin"},
    {"afip":"11051","location":"Picada Sueca"},
    {"afip":"11052","location":"Picada Sur Mecking"},
    {"afip":"11053","location":"Picada Yapeyu"},
    {"afip":"11147","location":"Pindaiti"},
    {"afip":"11148","location":"Pindapoy"},
    {"afip":"11149","location":"Pindayti"},
    {"afip":"11170","location":"Piray"},
    {"afip":"11171","location":"Piray Mini"},
    {"afip":"11193","location":"Piñalito Norte"}
    ,
    {"afip":"11194","location":"Piñalito Sur"}
    ,
    {"afip":"11201","location":"Planchada Banderita"},
    {"afip":"11341","location":"Posadas"},
    {"afip":"11542","location":"Profundidad"},
    {"afip":"11654","location":"Pueblo Salto"},
    {"afip":"11694","location":"Puente Nacional"},
    {"afip":"11742","location":"Puerto  Concepcion"},
    {"afip":"11743","location":"Puerto Aguirre"},
    {"afip":"11744","location":"Puerto Alcazar"},
    {"afip":"11747","location":"Puerto Alicia"},
    {"afip":"11753","location":"Puerto Aurora"},
    {"afip":"11754","location":"Puerto Avellaneda"},
    {"afip":"11755","location":"Puerto Azara"},
    {"afip":"11761","location":"Puerto Bemberg"},
    {"afip":"11765","location":"Puerto Bossetti"},
    {"afip":"11768","location":"Puerto Canoas"},
    {"afip":"11769","location":"Puerto Caraguatay"},
    {"afip":"11770","location":"Puerto Carolina"},
    {"afip":"11771","location":"Puerto Cazador"},
    {"afip":"11773","location":"Puerto Chuño"}
    ,
    {"afip":"11784","location":"Puerto Delicia"},
    {"afip":"11787","location":"Puerto Doce"},
    {"afip":"11788","location":"Puerto El Dorado"},
    {"afip":"11789","location":"Puerto Errecaborde"},
    {"afip":"11790","location":"Puerto España"}
    ,
    {"afip":"11794","location":"Puerto Gisela"},
    {"afip":"11798","location":"Puerto Hardelaste"},
    {"afip":"11802","location":"Puerto Iguazu"},
    {"afip":"11803","location":"Puerto Ingeniero Morandi"},
    {"afip":"11804","location":"Puerto Insua"},
    {"afip":"11808","location":"Puerto La Mina"},
    {"afip":"11811","location":"Puerto Laharrague"},
    {"afip":"11817","location":"Puerto Leoni"},
    {"afip":"11818","location":"Puerto Libertad"},
    {"afip":"11820","location":"Puerto Londero"},
    {"afip":"11822","location":"Puerto Lujan"},
    {"afip":"11823","location":"Puerto Mado"},
    {"afip":"11827","location":"Puerto Mbopicua"},
    {"afip":"11828","location":"Puerto Menochio"},
    {"afip":"11829","location":"Puerto Mineral"},
    {"afip":"11832","location":"Puerto Nuevo"},
    {"afip":"11835","location":"Puerto Oro Verde"},
    {"afip":"11836","location":"Puerto Paraiso"},
    {"afip":"11837","location":"Puerto Paranay"},
    {"afip":"11838","location":"Puerto Paticaa"},
    {"afip":"11839","location":"Puerto Paulito"},
    {"afip":"11840","location":"Puerto Peninsula"},
    {"afip":"11844","location":"Puerto Pinares"},
    {"afip":"11848","location":"Puerto Piray"},
    {"afip":"11852","location":"Puerto Rico"},
    {"afip":"11855","location":"Puerto Rosario"},
    {"afip":"11856","location":"Puerto Ruben"},
    {"afip":"11858","location":"Puerto Saltiño"}
    ,
    {"afip":"11859","location":"Puerto San Alberto"},
    {"afip":"11862","location":"Puerto San Ignacio"},
    {"afip":"11865","location":"Puerto San Lucas"},
    {"afip":"11866","location":"Puerto San Martin"},
    {"afip":"11869","location":"Puerto Tabay"},
    {"afip":"11871","location":"Puerto Tigre"},
    {"afip":"11879","location":"Puerto Uruguay"},
    {"afip":"11884","location":"Puerto Victoria"},
    {"afip":"11888","location":"Puerto Wanda"},
    {"afip":"11890","location":"Puerto Yabebiri"},
    {"afip":"11891","location":"Puerto Yacuy"},
    {"afip":"12478","location":"Rincon De Bonpland"},
    {"afip":"12479","location":"Rincon De Bugres"},
    {"afip":"12480","location":"Rincon De Chimtray"},
    {"afip":"12494","location":"Rincon De Lopez"},
    {"afip":"12520","location":"Rincon Del Guerrero"},
    {"afip":"12634","location":"Rio Yabotay"},
    {"afip":"12647","location":"Roca Chica"},
    {"afip":"12725","location":"Ruinas De Loreto"},
    {"afip":"12730","location":"Ruiz De Montoya"},
    {"afip":"12862","location":"Salto Encantado"},
    {"afip":"12868","location":"Samambaya"},
    {"afip":"12964","location":"San Francisco De Asis"},
    {"afip":"12981","location":"San Gotardo"},
    {"afip":"13047","location":"San Juan De La Sierra"},
    {"afip":"13238","location":"Santa Maria La Mayor"},
    {"afip":"13239","location":"Santa Maria Martir"},
    {"afip":"13287","location":"Santiago De Liniers"},
    {"afip":"13303","location":"Santo Pipo"},
    {"afip":"13395","location":"Segunda Zona"},
    {"afip":"13453","location":"Sierra De Oro"},
    {"afip":"13455","location":"Sierra De San Jose"},
    {"afip":"13469","location":"Sierras San Juan"},
    {"afip":"13662","location":"Tacuara"},
    {"afip":"13670","location":"Tacuaruzu"},
    {"afip":"13775","location":"Taruma"},
    {"afip":"13839","location":"Teyuguare"},
    {"afip":"13863","location":"Timbauba"},
    {"afip":"13906","location":"Tirica"},
    {"afip":"13914","location":"Tobunas"},
    {"afip":"13972","location":"Tororo"},
    {"afip":"13979","location":"Torta Quemada"},
    {"afip":"14017","location":"Tratado De Paz"},
    {"afip":"14406","location":"Villa Armonia"},
    {"afip":"14425","location":"Villa Blanquita"},
    {"afip":"14426","location":"Villa Bonita"},
    {"afip":"14522","location":"Villa Don Bosco"},
    {"afip":"14541","location":"Villa Errecaborde"},
    {"afip":"14555","location":"Villa Flor"},
    {"afip":"14634","location":"Villa Lanus"},
    {"afip":"14720","location":"Villa Ojo De Agua"},
    {"afip":"14725","location":"Villa Ortiz Pereira"},
    {"afip":"14781","location":"Villa Roulet"},
    {"afip":"14822","location":"Villa Sarubbi"},
    {"afip":"14839","location":"Villa Svea"},
    {"afip":"14861","location":"Villa Venecia"},
    {"afip":"14866","location":"Villa Vilma"},
    {"afip":"14956","location":"Wanda"},
    {"afip":"14970","location":"Yabebiri"},
    {"afip":"14994","location":"Yacutinga"},
    {"afip":"15017","location":"Yapeyu Centro"},
    {"afip":"15043","location":"Yerbal Mamboreta"},
    {"afip":"15148","location":"2 De Mayo"},
    {"afip":"15152","location":"25 De Mayo"},
    {"afip":"15155","location":"3 De Mayo"},
    {"afip":"15162","location":"9 De Julio"},
    {"afip":"15235","location":"Almirante Brown"},
    {"afip":"15293","location":"Arroyo"},
    {"afip":"15299","location":"Arroyo Del Medio"},
    {"afip":"15385","location":"Bella Vista"},
    {"afip":"15406","location":"Bonpland"},
    {"afip":"15424","location":"Buena Vista"},
    {"afip":"15444","location":"Campana"},
    {"afip":"15447","location":"Campo Alegre"},
    {"afip":"15459","location":"Campo Grande"},
    {"afip":"15475","location":"Candelaria"},
    {"afip":"15554","location":"Centinela"},
    {"afip":"15569","location":"Cerro Azul"},
    {"afip":"15657","location":"Colonia Alberdi"},
    {"afip":"15660","location":"Colonia Alemana"},
    {"afip":"15682","location":"Colonia Juanita"},
    {"afip":"15701","location":"Colonia Roca"},
    {"afip":"15714","location":"Colonia San Ignacio"},
    {"afip":"15745","location":"Colonia Santa Maria"},
    {"afip":"15753","location":"Colonia Santa Teresa"},
    {"afip":"15834","location":"Dos Arroyos"},
    {"afip":"15836","location":"Dos Hermanas"},
    {"afip":"16023","location":"El Destierro"},
    {"afip":"16179","location":"El Paraiso"},
    {"afip":"16217","location":"El Porvenir"},
    {"afip":"16339","location":"El Saltito"},
    {"afip":"16371","location":"El Socorro"},
    {"afip":"16395","location":"El Tigre"},
    {"afip":"16414","location":"El Triunfo"},
    {"afip":"16418","location":"El Tropezon"},
    {"afip":"16464","location":"Esperanza"},
    {"afip":"16517","location":"Florentino Ameghino"},
    {"afip":"16531","location":"Fronteras"},
    {"afip":"16539","location":"General Alvear"},
    {"afip":"16561","location":"Guerrero"},
    {"afip":"16565","location":"Hipolito Yrigoyen"},
    {"afip":"16718","location":"La Capilla"},
    {"afip":"16985","location":"La Horqueta"},
    {"afip":"16999","location":"La Invernada"},
    {"afip":"17191","location":"La Posta"},
    {"afip":"17580","location":"Las Mercedes"},
    {"afip":"17688","location":"Las Tunas"},
    {"afip":"17695","location":"Las Vertientes"},
    {"afip":"17707","location":"Leandro N Alem"},
    {"afip":"17712","location":"Libertad"},
    {"afip":"17754","location":"Loreto"},
    {"afip":"17850","location":"Los Galpones"},
    {"afip":"17857","location":"Los Helechos"},
    {"afip":"17978","location":"Lujan"},
    {"afip":"18000","location":"Manantiales"},
    {"afip":"18081","location":"Monte Hermoso"},
    {"afip":"18092","location":"Monteagudo"},
    {"afip":"18095","location":"Montecarlo"},
    {"afip":"18135","location":"Ojo De Agua"},
    {"afip":"18141","location":"Olegario Victor Andrade"},
    {"afip":"18203","location":"Paraiso"},
    {"afip":"18377","location":"Puerto Naranjito"},
    {"afip":"18379","location":"Puerto Viejo"},
    {"afip":"18571","location":"San Alberto"},
    {"afip":"18580","location":"San Andres"},
    {"afip":"18594","location":"San Antonio"},
    {"afip":"18621","location":"San Carlos"},
    {"afip":"18700","location":"San Ignacio"},
    {"afip":"18711","location":"San Isidro"},
    {"afip":"18722","location":"San Javier"},
    {"afip":"18752","location":"San Jose"},
    {"afip":"18765","location":"San Juan"},
    {"afip":"18797","location":"San Lucas"},
    {"afip":"18818","location":"San Martin"},
    {"afip":"18833","location":"San Miguel"},
    {"afip":"18861","location":"San Pedro"},
    {"afip":"18907","location":"San Sebastian"},
    {"afip":"18923","location":"San Vicente"},
    {"afip":"18936","location":"Santa Ana"},
    {"afip":"18990","location":"Santa Ines"},
    {"afip":"19040","location":"Santa Rita"},
    {"afip":"19054","location":"Santa Rosa"},
    {"afip":"19142","location":"Sol De Mayo"},
    {"afip":"19189","location":"Tigre"},
    {"afip":"19219","location":"Tres Bocas"},
    {"afip":"19221","location":"Tres Capones"},
    {"afip":"19232","location":"Tres Esquinas"},
    {"afip":"19301","location":"Villa Emilia"},
    {"afip":"19308","location":"Villa Libertad"},
    {"afip":"19337","location":"Villa Union"},
    {"afip":"19341","location":"Villafañe"}
    ,
    {"afip":"19342","location":"Villalonga"},
    {"afip":"20746","location":"Picada San Javier"},
    {"afip":"20747","location":"Colonia Alberdi"},
    {"afip":"20748","location":"Picada San Martin"},
    {"afip":"20749","location":"Barrancon"},
    {"afip":"20750","location":"Picada San Javier"},
    {"afip":"20751","location":"Barracon"},
    {"afip":"20776","location":"Barra Bonita"},
    {"afip":"20777","location":"San Isidro"},
    {"afip":"22093","location":"Leandro N. Alem"},
    {"afip":"22094","location":"Iguazu"}
    ]