import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SyngentaSubdivisionComponent } from './syngenta.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
  declarations: [
    SyngentaSubdivisionComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    FormsModule,
    PipesModule,
    MatSlideToggleModule
  ], exports: [
    SyngentaSubdivisionComponent
  ]
})
export class SyngentaSubdivisionModule { }
