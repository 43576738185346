import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogOrderCreate } from './order-create/dialog';
import {DialogConfirmedExchanged} from './exchanged-confirmed/exchanged-confirmed';
import { DialogPriceCreate } from './price-create/dialog';

@NgModule({
  declarations: [
    DialogOrderCreate,
    DialogConfirmedExchanged,
    DialogPriceCreate
  ], imports: [
    CommonModule
  ],exports: [
    DialogOrderCreate,
    DialogConfirmedExchanged,
    DialogPriceCreate
  ],
})
export class ModalModule {}
