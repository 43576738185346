<div class="background opaque visible"></div>
<div class="background visible" (click)="close()">
<div class="dialog dialog-contract">
  <div class="logo-container">
    <img class="logo confirm" *ngIf="isConfirm" alt="check-confirm" src="../../../../../../assets/images/extra/confirmed.svg" />
  </div>
  <span class="hour">{{hour}}</span>
  <span class="title">Se generó el borrador con éxito.</span>
  <span *ngFor="let success of arrayOfSuccess" class="message-success">N° {{success.data.Contrato}} del cliente {{getClientName(success)}}</span>

  <span class="message-indicator italic" *ngIf="dataOfIndicators">
    {{dataOfIndicators}}
  </span>


  <span class="message-quota italic">
    {{getMessageOfQuotas()}}
  </span>

  <div class="buttons">
    <button class="filled-button" (click)="goToControlPanel()">Ir al panel de control</button>
    <button class="not-filled-button" (click)="close()">Volver al listado</button>
  </div>
</div>
</div>