<div class="featured-prices column-left gap-8">
    <p class="title">Precios destacados</p>

    <div class="empty-state" *ngIf="featuredPrices.length==0; else featured">
        <div class="info">
            <span class="header">Aún no tienes precios disponibles de mercado.</span>
            <span class="description extra">
                Deberás de esperar a que los operadores activen las condiciones, para poder visualizar los <span
                    class="stroke">precios disponibles.</span></span>
            <span class="show-order-board extra">Visualizar precios disponibles del día anterior</span>
        </div>
    </div>

    <ng-template #featured>
        <div class="container-featured-prices">
            <div *ngFor="let featuredPrice of featuredPrices; index as i">
                <app-card-price-featured *ngIf="i<5" [price]="featuredPrice"></app-card-price-featured>
            </div>
        </div>
    </ng-template>
</div>