import { Component, EventEmitter, OnChanges, Output } from '@angular/core';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-convenio-nutrien',
  templateUrl: './convenio-nutrien.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class ConvenioNutrienComponent extends BusinessParticularityOption implements OnChanges {
  nutrienAgreement:boolean=false;
  @Output() saveNutrien = new EventEmitter<string>();

  constructor() { 
    super()
  }

  ngOnChanges(): void {
    try{
      this.nutrienAgreement=this.businessParticularitiesStruct.nutrienAgreement;
    } catch(err){
      this.nutrienAgreement=false;
    }
  }

  setNutrienAgreement(){
    this.nutrienAgreement=!this.nutrienAgreement;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.nutrienAgreement=this.nutrienAgreement;
    this.saveNutrien.emit(JSON.stringify(newBP));
  }
}
