import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from 'src/app/components/header/header.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { MenuFooterModule } from 'src/app/components/menu-footer/menu-footer.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import {  HistoricalPageComponent } from './historical.component';
import { BodyHistoricalModule } from './body-historical/body-historical.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    HistoricalPageComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    MenuModule,
    MenuFooterModule,
    HeaderInSectionModule,
    BodyHistoricalModule,
    ModalModule
  ], exports: [
    HistoricalPageComponent,
  ]
})
export class HistoricalPageModule { }
