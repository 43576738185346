import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setModalContract } from 'src/app/redux/actions/options.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { FailedResponse } from 'src/app/shared/interfaces/DataIBM';

@Component({
  selector: 'dialog-contract-failed',
  templateUrl: './contract-failed.component.html',
  styleUrls: ['./contract-failed.component.scss', './../modal.scss', './../../../shared/styles/table.scss']
})
export class ContractFailedComponent implements OnInit, OnDestroy {
  showModal: boolean = false; 
  arrayOfFailed: FailedResponse[] = []; //Array con la información del o los errores
  isIndicators: boolean = false; //Vamos a mostrar error de los indicadores
  private options$!: Subscription;

  constructor(private store: Store<appState>) { }

  ngOnInit(): void {
    this.options$ = this.store.select('options').subscribe(optionsR => {
      this.showModal = optionsR.modalContract.isShow && optionsR.modalContract.arrayOfFaileds.length > 0;
      this.arrayOfFailed = optionsR.modalContract.arrayOfFaileds;
      this.isIndicators = optionsR.modalContract.isIndicators;
    })
  }

  ngOnDestroy() {
    this.options$.unsubscribe();
  }

  getArrayOfMessage(failed: FailedResponse) {
    try {
      return failed.status.messageError.msg.split('-');
    } catch (err) {
      return [failed.status.messageError.msg]
    }
  }

  closeModal() {
    this.store.dispatch(setModalContract({ modalContract: { isShow: false, arrayOfFaileds: [], arrayOfSuccess: [], isIndicators:false, typeModal: 0} }));
  }

  getID(failed: FailedResponse) {
    try{
      if (failed.status.messageError.ID) {
        return failed.status.messageError.ID;
      } else {
        return failed.data.ID;
      }
    } catch(err){
      return "Sin ID";
    }
  }

  getNumOfContract(failed: FailedResponse) {
    return failed.status.messageError.Contrato;
  }

  getMessageError(failed: FailedResponse) {
    try{
      if (failed.status.messageError.msg) {
        return failed.status.messageError.msg;
      }
      if (failed.status.message) {
        return failed.status.message;
      }
    } catch(err){
      return "Sin mensaje de error";
    }
  }

}
