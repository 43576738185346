import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Modulos
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ViewsOrdersModule } from 'src/app/components/views/views-orders/views-orders.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { EraserBodyComponent } from './eraser-body.component';
import { EraserTableModule } from './eraser-table/eraser.module';
import { DetailsEyeModule } from 'src/app/components/slide-cards/details-eye/details-eye.module';
import { DialogYesOrNoModule } from 'src/app/components/dialogs/dialog-yes-or-no/dialog-yes-or-no.module';
import { ViewsSectionModule } from 'src/app/components/views-section/views-section.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';
import { SearchInputModule } from 'src/app/components/inputs/search/search.module';

@NgModule({
  declarations: [
    EraserBodyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    EraserTableModule,
    ViewsOrdersModule,
    PipesModule,
    DetailsEyeModule,
    ViewsSectionModule,

    //DIALOG
    DialogYesOrNoModule,

    //SVG
    ThreePointsSvgModule,
    ArrowSvgModule,
    
    SearchInputModule,
    ButtonsConditionTypeModule
  ], 
  exports: [
    EraserBodyComponent,
  ]
})
export class EraserBodyModule { }
