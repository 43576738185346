import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { MARCA_SILO_BOLSAS, TAMAIN_SILO_BOLSAS } from 'src/app/shared/const/business-particularities';
import { BUSS_PART_CONST } from 'src/app/shared/dict/business.particularities';
import { typeCoin } from 'src/app/shared/dict/dict';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';

@Component({
  selector: 'app-data-buss-part',
  templateUrl: './data-buss-part.component.html',
  styleUrls: ['./data-buss-part.component.scss']
})
export class DataBussinesParticularitiesComponent implements OnInit{
  @Input() businessParticularity!: BusinessParticularities;
  @Input() showTitle=true;
  @Input() isSmall=false;
  showOptions: boolean = false;
  BUSS_PART_CONST= BUSS_PART_CONST;
  @Output() closeEvent = new EventEmitter<boolean>();

  constructor(private bussPartV: BusinessParticularitiesValidator) { }

  close() {
    this.closeEvent.emit();
  }

  ngOnInit() {
  }

  getIsWashOut(businessParticularities: BusinessParticularities) {
    return this.bussPartV.getIsWashOut(businessParticularities);
  }

  // --------------- GRAVANZ ----------------\\
  getIsGravanz(businessParticularities: BusinessParticularities) {
    return this.bussPartV.getIsGravanz(businessParticularities);
  }

  getSensorization(businessParticularities: BusinessParticularities) {
    return 'Sensorización '+ (businessParticularities.gravanz.isOwnSensorization ? 'propia' : 'de terceros');
  }

  getGravanzBusiness(businessParticularities: BusinessParticularities) {
    return 'Empresa: ' + businessParticularities.gravanz.business;
  }

  getGravanzSelectionStock(businessParticularities: BusinessParticularities) {
    return 'Selección de bolsa: ' + businessParticularities.gravanz.selectionStock;
  }

  /* ---------------------------------------- */

  /* -------------- CONDITIONAL ------------- */
  getIsConditional(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsConditional(businessParticularity);
  }

  getConditionalSelection(businessParticularity: BusinessParticularities) {
    switch(businessParticularity.conditional.selection) {
      case 1: return 'Call venta';
      case 2: return 'Put venta';
      case 3: return 'Call compra';
      case 4: return 'Put compra';
      default: return 'Sin especificar';
    }
  }

  getConditionalStrike(businessParticularity: BusinessParticularities) {
    return 'Strike: ' + businessParticularity.conditional.strike;
  }

  getConditionalPosition(businessParticularity: BusinessParticularities) {
    return 'Posición: ' + businessParticularity.conditional.position;
  }
  /* ---------------------------------------- */

  getIsNutrienAgreement(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsNutrienAgreement(businessParticularity);
  }


  /* --------- COLLECTION CLAUSE ------------ */
  getIsCollectionClause(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsCollectionClause(businessParticularity);
  }
  /* ---------------------------------------- */

  /* ------------ RESPECT VAT --------------- */
  getIsRespectVatException(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsRespectVatException(businessParticularity);
  }
  /* ---------------------------------------- */

  /* ------ FREIGHT PAID BY THE BUYER ------- */
  getIsFreightPaidByTheBuyer(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsFreightPaidByTheBuyer(businessParticularity);
  }

  getFreightPaidIsCustomer(businessParticularity: BusinessParticularities) {
    return businessParticularity.freightPaidByTheBuyer.isCustomerFreight==1? 'Flete del cliente': '';
  }

  getFreightPaidAmount(businessParticularity:BusinessParticularities){
    const TYPE_COIN:any=typeCoin
    return 'Monto: ' + TYPE_COIN[businessParticularity.freightPaidByTheBuyer.amount.typeCoin] + ' ' + businessParticularity.freightPaidByTheBuyer.amount.quantity;
  }

  getFreightPaidNumberOfDays(businessParticularity:BusinessParticularities){
    return 'Cantidad de días: ' + businessParticularity.freightPaidByTheBuyer.numberOfDays;
  }
  /* ---------------------------------------- */

  /* -------------- AGREEMENT --------------- */  
  getIsAgreement(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsAgreement(businessParticularity);
  }
  /* ---------------------------------------- */

  /* ----------- GRASSI PLANTS -------------- */
  getIsDeliveriesAtGrassiPlant(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsDeliveriesAtGrassiPlant(businessParticularity);
  }

  getGrassiPlantsPlaceOfDelivery(businessParticularity: BusinessParticularities) {
    if(businessParticularity.deliveriesAtGrassiPlant.placeOfDelivery> 0){
      return 'Lugar de entrega: ' + businessParticularity.deliveriesAtGrassiPlant.placeOfDelivery;
    }
    return '';
  }

  getGrassiPlantsParity(businessParticularity: BusinessParticularities) {
    const TYPE_COIN:any=typeCoin
    return TYPE_COIN[businessParticularity.deliveriesAtGrassiPlant.parity.typeCoin] + ' ' + businessParticularity.deliveriesAtGrassiPlant.parity.quantity;
  }

  getGrassiPlantsVolatileWaste(businessParticularity: BusinessParticularities) {
    return businessParticularity.deliveriesAtGrassiPlant.volatileWaste.percentage + 
    ' % - Descripción del producto: ' +
    businessParticularity.deliveriesAtGrassiPlant.volatileWaste.productDescription;
  }

  getGrassiPlantsCommission(businessParticularity: BusinessParticularities) {
    return businessParticularity.deliveriesAtGrassiPlant.commission.percentage + ' % - ' +
    (businessParticularity.deliveriesAtGrassiPlant.commission.isHoard==1? 'Acopio': 'Corredor');
  }

  getGrassiPlantsFreight(businessParticularity: BusinessParticularities) {
    const TYPE_COIN:any=typeCoin
    return TYPE_COIN[businessParticularity.deliveriesAtGrassiPlant.freight.amount.typeCoin] + 
    ' ' + businessParticularity.deliveriesAtGrassiPlant.freight.amount.quantity + 
    ' - Descripción: ' + businessParticularity.deliveriesAtGrassiPlant.freight.description;
  }

  getGrassiPlantsHumidityAmountAndPercentage(businessParticularity: BusinessParticularities) {
    const TYPE_COIN:any=typeCoin
    return TYPE_COIN[businessParticularity.deliveriesAtGrassiPlant.humidity.amount.typeCoin] +
    ' ' + businessParticularity.deliveriesAtGrassiPlant.humidity.amount.quantity +
    ' - ' + businessParticularity.deliveriesAtGrassiPlant.humidity.percentage + ' %';
  }

  getGrassiPlantsHumidityReductionPoint(businessParticularity: BusinessParticularities) {
    return "Puntos de rebaja: " + businessParticularity.deliveriesAtGrassiPlant.humidity.reductionPoint;
  }

  getGrassiPlantsHumidityReductionAmount(businessParticularity: BusinessParticularities) {
    if(businessParticularity.deliveriesAtGrassiPlant.humidity.isPortFee==1){
      return "Reduccion tarifa portuaria: " + businessParticularity.deliveriesAtGrassiPlant.humidity.reductionAmount;
    }
    return '';
  }
  /* ---------------------------------------- */

  /* ------------- SILO BOLSAS -------------- */
  getIsDeliveryInSiloBolsas(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsDeliveryInSiloBolsas(businessParticularity);
  }

  getSiloBolsasQuantity(businessParticularity: BusinessParticularities) {
    return 'Cantidad: ' + businessParticularity.deliveryInSiloBolsas.quantity;
  }

  getSiloBolsasBrand(businessParticularity:BusinessParticularities){
    const BRAND:any=MARCA_SILO_BOLSAS;
    return 'Marca: ' + BRAND[businessParticularity.deliveryInSiloBolsas.brand];
  }

  getSiloBolsasSize(businessParticularity:BusinessParticularities){
    const SIZE:any=TAMAIN_SILO_BOLSAS;
    return 'Tamaño: ' + SIZE[businessParticularity.deliveryInSiloBolsas.size];
  }
  /* ---------------------------------------- */

  /* -------------- BONIFICATION ------------ */
  getIsBonification(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsBonification(businessParticularity);
  }

  getBonificationType(businessParticularity:BusinessParticularities){
    switch(businessParticularity.bonification.typeBonification) {
      case 1: return 'Entrega anticipada';
      case 2: return 'Cambio de destino';
      case 3: return 'Sustentabilidad';
      case 4: return 'Almacenaje';
      case 5: return 'Rolado';
      case 6: return 'Convenido';
      default: return 'Sin especificar';
    }
  }

  getBonificationDate(businessParticularity:BusinessParticularities){
    if(businessParticularity.bonification.endDate!='--'){
      return 'Fecha: ' + businessParticularity.bonification.endDate;
    }
    return '';
  }

  getBonificationPlaceOfDelivery(businessParticularity:BusinessParticularities){
    if(businessParticularity.bonification.placeOfDelivery>0){
      return 'Lugar de entrega: ' + businessParticularity.bonification.placeOfDelivery;
    }
    return '';
  }

  getBonificationAmount(businessParticularity:BusinessParticularities){
    const TYPE_COIN:any=typeCoin;
    const typeCoinString=TYPE_COIN[businessParticularity.bonification.amount.typeCoin];
    return (typeCoinString=='--'? 'U$S ': typeCoinString) + businessParticularity.bonification.amount.quantity;
  }
  /* ---------------------------------------- */

  /* -------------- REDUCTION --------------- */
  getIsReduction(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsReduction(businessParticularity);
  }

  getReductionType(businessParticularity:BusinessParticularities){
    let stringToReturn='';
    const isPercentage= businessParticularity.reduction.percentage>0;
    stringToReturn += isPercentage? 'Monto: ' : 'Porcentaje: ';
    if(isPercentage){
      stringToReturn += businessParticularity.reduction.percentage + ' %';
    }else{
      const TYPE_COINT:any=typeCoin;
      stringToReturn += TYPE_COINT[businessParticularity.reduction.typeCoin] + 
      ' ' + businessParticularity.reduction.amount;
    }
    return stringToReturn;
  }
  /* ---------------------------------------- */
  
  /* -------------- LABORATORY -------------- */
  getIsLaboratoryAgreement(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsLaboratoryAgreement(businessParticularity);
  }

  getLaboratoryAgreementType(businessParticularity:BusinessParticularities){
    return 'Tasa porcentual: ' + businessParticularity.laboratoryAgreement + ' %';
  }
  /* ---------------------------------------- */
  
  /* --------- SEALED TO RETRIEVE ----------- */
  getIsSealedToRetrieve(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsSealedToRetrieve(businessParticularity);
  }

  getSealedToRetrieveType(businessParticularity:BusinessParticularities){
    switch(businessParticularity.sealedToRetrieve) {
      case 1: return '100% a cargo del comprador';
      case 2: return '100% a cargo del vendedor';
      default: return 'Sin especificar';
    }
  }
  /* ---------------------------------------- */

  /* -------------- TO WITHDRAW ------------- */
  getIsToWithdraw(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsToWithdraw(businessParticularity);
  }
  /* ---------------------------------------- */

  /* -------------- AGREED WITH ------------- */
  getIsAgreedWith(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsAgreedWith(businessParticularity);
  }

  getIsAgreedWithNombreComercial(businessParticularity: BusinessParticularities) {
    return 'Nombre comercial: ' + businessParticularity.agreedWith.comercialName;
  }

  getIsAgreedWithComprador(businessParticularity: BusinessParticularities) {
    return 'Comprador: ' + businessParticularity.agreedWith.buyer;
  }

  getIsAgreedWithResponsable(businessParticularity: BusinessParticularities) {
    return 'Responsable ' + businessParticularity.agreedWith.responsable;
  }


  //------------------- A FIJAR ----------------------\\

  /* ------------- ACCUMULATOR -------------- */
  getIsAccumulator(businessParticularity: BusinessParticularities) {
    return this.bussPartV.getIsAccumulator(businessParticularity);
  }

  getTypeAccumulator(businessParticularity: BusinessParticularities) {
    switch(businessParticularity.accumulator.typeAccumulator) {
      case 1: return 'Simple con knock out';
      case 2: return 'Doble con knock out';
      case 3: return 'Doble con piso';
      default: return 'Sin especificar';
    }
  }
  /* ---------------------------------------- */

  /* -------------- PAF Garantizado ---------- */
  getIsGuaranteedPAF(bussPart:BusinessParticularities){
    return this.bussPartV.getIsGuaranteedPAF(bussPart);
  }
  /* ----------------------------------------- */

  /* ------------ ANTICIPO FINANCIERO -------- */
  getIsFinancialAdvance(bussPart:BusinessParticularities){
    return this.bussPartV.getIsFinancialAdvance(bussPart);
  }

  getVATFinancialAdvance(bussPart:BusinessParticularities){
    switch(bussPart.financialAdvance.VAT) {
      case 1: return 'Sin IVA';
      case 2: return 'Con IVA';
      case 3: return 'IVA a determinar'
      default: return 'IVA sin informar';
    }
  }

  getRateFinancialAdvance(bussPart:BusinessParticularities){
    if(bussPart.financialAdvance.rate==1){
      return 'Tasa a determinar';
    } else if(bussPart.financialAdvance.rate==2) {
      return 'Tasa determinada: ' + bussPart.financialAdvance.amountRate + ' %';
    }
    return 'Tasa sin info';
  }

  getTypePercentageFinancialAdvance(bussPart:BusinessParticularities){
    let stringToReturn='Tipo de porcentaje: ';
    if(bussPart.financialAdvance.percentageTypeToPay==1){
      stringToReturn+='A determinar';
    }else if(bussPart.financialAdvance.percentageTypeToPay==2){
      stringToReturn+='Determinado';
    }else if(bussPart.financialAdvance.percentageTypeToPay==3){
      stringToReturn+='Por defecto';
    } else {
      stringToReturn+='Sin información';
    }
    return stringToReturn;
  }

  getPercentagePayableFinancialAdvance(bussPart:BusinessParticularities){
    return 'Porcentaje a pagar: ' + bussPart.financialAdvance.percentagePayable + ' %';
  }
}