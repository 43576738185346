<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Borrador"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Borrador</h2>
    <!-- CONTENT OF THE PRICE BOARD -->
    <app-eraser-body></app-eraser-body>

    <app-menu class="menu"></app-menu>
    <!--<app-menu-footer></app-menu-footer> -->
</div>