<th class="fixed row-left gap-8" [ngClass]="{'min-width-150': showAutorizateAll}">
    <input *ngIf="showCheckbox" class="form-check-input" [checked]="isCheckedAll" 
    (click)="checkedAllItems()" type="checkbox">
            
    <div class="row-left autorization-button pointer" (click)="autorizateAll()" *ngIf="showAutorizateAll">
        <span>Autorizar todo</span>
    </div>
</th>

<th cell-head *ngIf="viewSelected?.observations" class="col-mb col-17" [name]="'Observación'"></th>

<th cell-head *ngIf="viewSelected?.hour" class="col-s col-16" [name]="'Hora'"
[isFilter]="getIsFilter('hour','')" [showFilter]="filterIdentificator==1"
[filterIdentificator]="1" [identificatorString]="'hour'" (select)="selectFilter($event)"></th>
 
<th cell-head *ngIf="viewSelected?.orderStatus || viewSelected?.contractNumber" class="col-b col-3"
[name]="isControlPanel? 'N° de contrato': 'Estado de orden'" [filterIdentificator]="2"
[showFilter]="!isControlPanel && filterIdentificator==2" [isFilter]="getIsFilter('statusOrder','')" [arrayAux]="STATES_ORDERS_VALIDS"
[identificatorString]="'statusOrder'" [onlyFilter]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.seller" class="col-b col-3" 
[name]="'Vendedor'" [filterIdentificator]="13" [showFilter]="filterIdentificator==13" [isFilter]="getIsFilter('codeS','')"
[arrayAux]="SELLERS" [identificatorString]="'codeS'" [isSearchOrNumber]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.buyer && showBuyer" class="col-b col-14" [name]="'Comprador'" [filterIdentificator]="14"
[showFilter]="filterIdentificator==14" [isFilter]="getIsFilter('price','buyer')" [arrayAux]="BUYERS"
[identificatorString]="'observations'" [identificatorStringAux]="'buyer'" [isSearchOrNumber]="true" [isPropertyOfPrice]="true"
(select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.product" class="col-m col-4" [name]="'Producto'" [filterIdentificator]="3"
[showFilter]="filterIdentificator==3" [isFilter]="getIsFilter('price','productName')" [arrayAux]="PRODUCTS"
[identificatorString]="'productName'" [isSearchOrNumber]="true" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.typeBusiness" class="col-b col-5" [name]="'Tipo de negocio'" [filterIdentificator]="4"
[showFilter]="filterIdentificator==4" [isFilter]="getIsFilter('price','typeBusiness')" [arrayAux]="BUSINESS_TYPE"
[identificatorString]="'typeBusiness'" [isSearchOrNumber]="true" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.placeOfDelivery" class="col-b col-6" [name]="'Lugar de entrega'" [filterIdentificator]="5"
[showFilter]="filterIdentificator==5" [isFilter]="getIsFilter('price','placeOfDelivery')" [arrayAux]="PLACE_OF_DELIVERY"
[identificatorString]="'placeOfDelivery'" [identificatorStringAux]="'zone'" [isPropertyOfPrice]="true"
(select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.deliveryPeriod" class="col-mbb col-7" [name]="'Periodo de entrega'" [filterIdentificator]="6"
[showFilter]="filterIdentificator==6" [isFilter]="getIsFilter('price','deliveryPeriod')"
[identificatorString]="'deliveryPeriod'" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.quality" class="col-sm col-8" [name]="'Calidad'" [filterIdentificator]="7"
[showFilter]="filterIdentificator==7" [isFilter]="getIsFilter('price','quality')" [arrayAux]="QUALITY"
[identificatorString]="'quality'" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.price && conditionTypeSelected!=CONDITION_TYPES.TO_FIX" class="col-sm col-9" [name]="'Precio'" [filterIdentificator]="8"
[showFilter]="filterIdentificator==8" [isFilter]="getIsFilter('price','price')" [isSearchOrNumber]="true"
[identificatorString]="'price'" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.tons" class="col-m col-11" [name]="'Toneladas'" [filterIdentificator]="10"
[showFilter]="filterIdentificator==10" [isFilter]="getIsFilter('tons','')" [isSearchOrNumber]="true"
[identificatorString]="'tons'" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.wayPay" class="{{conditionTypeSelected==CONDITION_TYPES.TO_FIX? 'col-bbb':'col-mbb'}} col-10" [name]="'Forma de pago'" [filterIdentificator]="9"
[showFilter]="filterIdentificator==9" [isFilter]="getIsFilter('price','wayPayName')" [arrayAux]="WAY_PAY"
[identificatorString]="'wayPay'" [identificatorStringAux]="'wayPayName'" [isPropertyOfPrice]="true" 
[isToFix]="false" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.harvest" class="col-sm col-13" [name]="'Cosecha'" [filterIdentificator]="16"
[showFilter]="filterIdentificator==16" [isFilter]="getIsFilter('price','harvest')" [arrayAux]="HARVEST"
[identificatorString]="'harvest'" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.businessParticularities" class="col-bb col-12" [name]="'Particularidad de negocio'"
[filterIdentificator]="12" [showFilter]="filterIdentificator==12" [isFilter]="getIsFilter('price','businessParticularities')"
[identificatorString]="'businessParticularities'" [isPropertyOfPrice]="true" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.date" class="col-m" [name]="'Fecha'"></th>

<th cell-head *ngIf="viewSelected?.tables" class="col-bb col-15" [name]="'Mesa'" [filterIdentificator]="15"
[showFilter]="filterIdentificator==15" [isFilter]="getIsFilter('nroTeam','')" [arrayAux]="TABLES"
[identificatorString]="'nroTeam'" (select)="selectFilter($event)"></th>

<th cell-head *ngIf="viewSelected?.id" class="col-mb" [name]="'ID de orden'"></th>