export const GROUPING_CODE_TO_PRICE = {
    1: 'Negocio disponible común',
    3: 'Negocio (convenio entre partes)',    
    28:'Condicional a precio',
    32:'Condicional precio call compra',
    50:'Piso-techo',
    57:'Put Venta (WO)',
    64:'Pase de Cosecha X WO A Precio',
    65:'Pase Misma Cos X WO A Precio',
    66:'Soja X Maix X WO A Precio',
    70:'Trigo X Soja X WO A Precio',
    82: 'Pesif. Mes Determinado.'
}

export const GROUPING_CODE_TO_FIX = {
    4:	"A Fijar Merc Comprador",
    5:	"A Fijar Pizarra",
    6:	"A Fijar Pase Cosecha Lleno",
    7:	"A Fijar Pase Cosecha %",
    8:	"A Fijar (Sin Espacio Propio)",
    9:	"A Fijar Maiz X Soja",
    10:	"A Fijar Sorgo X Soja",
    11:	"A Fijar Merc Exportador Gener",
    12:	"A Fijar Soja X Maiz",
    13:	"A Fijar Soja X Cbot",
    14:	"A Fijar Por Matba",
    15:	"A Fijar X Maiz Cbot",
    17:	"Almacenaje",
    18:	"A Fijar Sorgo Por Maiz",
    20:	"A Fijar Trigo X Soja",
    26:	"Condicionales",
    27:	"Acumuladores",
    35:	"Escalera",
    39:	"A Fij Canjeador C/Espacio",
    40:	"A Fij Merc Comprador (Vencido)",
    41:	"Pase Maiz Primera A Segunda",
    42:	"Maiz X Soja Con Pase",
    43:	"Sorgo X Soja Con Pase",
    44:	"Trigo X Soja Con Pase",
    45:	"Pase Cos Vs Julio Lleno",
    46:	"Convenio",
    48:	"Piz Proporcional Mensual",
    50:	"Piso-Techo",
    51:	"Af Pase Lleno C/Posib D Cobro",
    52:	"Af Pase % C/Posib D Cobro",
    53:	"Condicional Call Sintetico",
    54:	"Call Compra (Wo)",
    55:	"Call Venta (Wo)",
    56:	"Put Compra (Wo)",
    57:	"Put Venta (Wo)",
    60:	"Chicago/Matba",
    61:	"Pase Maiz % Primera A Segunda",
    62:	"Pase % Cos Vs Julio",
    83: '(Sin Espacio) Con U$S Pactado',
    84: 'Piz C/Intervalos De No Fix'
}