import { Component, OnInit,OnDestroy,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setAddExchange, setOrderExchangeDetails,showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { setOrdersSelected } from 'src/app/redux/actions/order.action';
import { OBJECT_ID_VOID } from 'src/app/shared/const/options';
import { DateChecker } from 'src/app/shared/date.checker';
import { EXCHANGER_STATES } from 'src/app/shared/dict/exchange';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { PriceValidator } from 'src/app/shared/price';
import { TableComponent } from '../table.component';
import { ToFixValidator } from 'src/app/shared/to-fix';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./../../../../../shared/styles/board.scss', 
  './../../../../exchange-list/body-exchange-list/exchange-table/exchange-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class ExchangeComponent extends TableComponent implements OnInit, OnDestroy {
  OBJECT_ID_VOID=OBJECT_ID_VOID;
  EXCHANGE_STATES:any=EXCHANGER_STATES;
  isCheckedAll:boolean=false;

  private exchange$!:Subscription;
  constructor(store: Store<appState>, router: Router, date:DateChecker, orderV:OrderValidator, 
    private ordExcV:OrderExchangeValidator, priceV:PriceValidator, toFixV:ToFixValidator, cdr:ChangeDetectorRef) {
    super(store,router,date, orderV, priceV, toFixV, cdr);
  }


  override ngOnInit(): void {
    this.setArrays();
    this.setSubscriptions();
  
    this.exchange$=this.store.select('exchange').subscribe(exchange => {
      this.viewSelected=exchange.exchangeBoard.viewsOfExchangeBoard.viewSelected;
      this.exchangeFilter=exchange.exchangeBoard.filter;

      this.cdr.detectChanges();
    });
   }
  
   override ngOnDestroy(): void {
    this.exchange$?.unsubscribe();
    this.setUnsubscriptions();
    this.store.dispatch(setOrdersSelected({ orders: [] }));
   }

   override getFilter() {
    return this.exchangeFilter
   }

   override selectFilter(index: number): void {
     
   }


  getSeller(order:OrderExchange){
    return this.orderV.getSellerName(order);
  }

  getExchanger(order:OrderExchange){
    return this.ordExcV.getSellerOfExchange(order);
  }

  getProductName(order:OrderExchange){
    return this.orderV.getProductName(order);
  }

  getExchangeStatus(order:OrderExchange){
    return this.ordExcV.getExchangeStatus(order);
  }

  getPrice(order:OrderExchange){
    return this.ordExcV.getTypeCoin(order) + ' ' + this.ordExcV.getPrice(order);
  }

  getTons(order:OrderExchange){
    return this.ordExcV.getTons(order);
  }

  getMotherContract(order:OrderExchange){
    return this.orderV.getContractNumber(order);
  }

  getContractNumber(order:OrderExchange){
    return this.ordExcV.getContractNumber(order);
  }

  isConfirmationMissing(order:OrderExchange){
    return !this.ordExcV.isOrderIncomplete(order) && order?.contractNumber <=0;
  }

  deleteExchange(order:OrderExchange){
    this.ordExcV.deleteExchange(order);
  }

  showAddExchange(order:OrderExchange){
    this.store.dispatch(setAddExchange({addExchange:true}));
    this.store.dispatch(setOrderExchangeDetails({ order: order }));
  }

  showOrderDetail(order:OrderExchange){
    this.store.dispatch(setOrderExchangeDetails({ order: order }));
    this.store.dispatch(showOrderExchangeDetails({ show: true }));
  }
}