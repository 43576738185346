import { Pipe, PipeTransform } from '@angular/core';
import { COMMODITIES_CODE, COMMODITIES_CODE_SELLER, HUGHES_CODE_SELLER } from '../../const/buyers';
import { Order, OrderString } from '../../interfaces/order.interface';
import { filterOrderWithOrderString } from './filter.by.order.pipe';
import { isFromCommodities } from './filterByBuyerType.pipe';
import { filterOrderByStrings } from './filterWithString.pipe';
import { isPriceToFix } from './filter-by-condition-type.pipe';
import { isOrderVisibleForCommodities } from './filterWithOutContractStatus.pipe';


@Pipe({
  name: 'filterAllOrder'
})
export class FilterAllOrderPipe implements PipeTransform {

  transform(list: any[], isComercial:boolean, order: OrderString, search:string, identificator: string, conditionType:string, showContracts:boolean, conditionsFilters:number[]) {
    let arrayFinal: any[] = [];

    const searchs = search.trim().toLowerCase().split(" ");

    list.forEach(element => {
      if ( isOwnerCommoditiesOrder(element, isComercial) &&
      filterOrderByStrings(element, searchs) && 
      isOrderVisibleForCommodities(element, isComercial, showContracts) &&
      filterOrderWithOrderString(element,order) &&
      isFromCommodities(element.price, identificator) &&
      isPriceToFix(element.price,conditionType,conditionsFilters)) {
        arrayFinal.push(element);
      }
    })

    return arrayFinal;
  }
}

//Si no es comercial, debe retornar true, si es comercial, debe fijarse que el vendedor de la orden no sea Commodities ni Hughes.
export function isOwnerCommoditiesOrder(order:Order, isComercial:boolean){
  if(!isComercial) return true;
  return order.sellerData.codeS != COMMODITIES_CODE_SELLER && 
  order.sellerData.codeS != HUGHES_CODE_SELLER
}