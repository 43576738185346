import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { SessionChecker } from 'src/app/shared/session.checker';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { setLocation } from 'src/app/redux/actions/options.action';
import { TablesIBMClass } from 'src/app/shared/tables-ibm';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-generate-order-price-board',
  templateUrl: './generate-order-price-board.component.html',
  styleUrls: ['././../../shared/styles/body.scss']
})
export class GeneratePriceBoardOrderComponent extends TablesIBMClass implements OnInit, OnDestroy {
  public isLoading:boolean=false;
  private load$!:Subscription;

  constructor(private store: Store<appState>, private checker: SessionChecker, maestroSvc:MaestrosService) {
    super(maestroSvc);
    this.checker.rememberAndActualizateUser(this.store);
    this.store.dispatch(setLocation({location: NAVEGATION.GENERATE_PRICE_BOARD_ORDER}));

    this.load$=this.store.select('options', 'modalCreate', 'isLoading').subscribe( (isLoading:boolean) => {
      this.isLoading = isLoading;
    });

  }

  ngOnInit(): void {
    this.checkTables();
  }

  ngOnDestroy(): void {
    this.load$?.unsubscribe();
  }
}
