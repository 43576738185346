import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setAllViewsO, setFilterOrder, setIndexViewSelected, setViewSelectedO } from 'src/app/redux/actions/order.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { VIEWS_SELECTED_CONST, VIEW_ORDER_ALL } from 'src/app/shared/const/views.const';
import { getViewsByTag } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { setAllViewsCP, setFilterOrderControlPanel, setIndexViewSelectedCP, setViewSelectedCP } from 'src/app/redux/actions/control-panel.action';
import { OrderString } from 'src/app/shared/interfaces/order.interface';
import { ViewSectionOrderBasic } from '../view-order-section';

@Component({
  selector: 'app-views-section-order',
  templateUrl: './views-section-order.component.html',
  styleUrls: ['./../views-section.scss']
})
export class ViewsSectionOrderComponent extends ViewSectionOrderBasic implements OnInit, OnDestroy {
  @Input() isControlPanel:boolean=false;

  override views: ViewOrder[] = []; //It is extracted from the LocalStorage
  override VIEW_ALL: ViewOrder = VIEW_ORDER_ALL;

  constructor(store:Store<appState>) {
    super(store);    
  }

  override setSubscriptions() { 
    if(this.isControlPanel){
      this.views$=this.store.select('controlPanel', 'viewsOfControlPanelBoard', 'views').subscribe((views) => {
        this.views = this.filterViews(views);
      });
  
      this.viewSelected$=this.store.select('controlPanel', 'viewsOfControlPanelBoard', 'viewSelected').subscribe((viewSelected) => {
        this.viewSelected = viewSelected;
      });
  
      this.index$=this.store.select('controlPanel', 'viewsOfControlPanelBoard', 'indexViewSelected').subscribe((indexViewSelected) => {
        this.editViewIdentificator = indexViewSelected;
      });
    } else {
      this.views$ = this.store.select('order', 'viewsOfOrderBoard', 'views').subscribe((views) => {
        this.views = this.filterViews(views);
      });

      this.viewSelected$ = this.store.select('order', 'viewsOfOrderBoard', 'viewSelected').subscribe((viewSelected) => {
        this.viewSelected = viewSelected;
      });

      this.index$ = this.store.select('order', 'viewsOfOrderBoard', 'indexViewSelected').subscribe((indexViewSelected) => {
        this.editViewIdentificator = indexViewSelected;
      });
    }
  }

  override rememberViewsOfLocalStorage() {
    const views= getViewsByTag(this.isControlPanel? localStorageNames.viewsControlPanel: localStorageNames.viewOrders);
    this.setViewAll(views)
  }
  
  override getViewSelectedConst():"priceBoard" | "orderBoard" | "orderExchangeBoard" | "historicalBoard"{
    return this.isControlPanel? VIEWS_SELECTED_CONST.CONTROL_PANEL_BOARD: VIEWS_SELECTED_CONST.ORDER_BOARD;
  }
  
  override selectViewDefault(){
    if(this.isComercial){
      this.isSelectedMyOrders=true;
      this.viewMyOrders();
    } else {
      this.viewAll();
    }
  }

  override setViewAll(views:ViewOrder[]){
    this.store.dispatch(this.isControlPanel? setAllViewsCP({views:views}):setAllViewsO({views:views}));
  }

  override getLocalStorageName(): string {
    return this.isControlPanel? localStorageNames.viewsControlPanel: localStorageNames.viewOrders;
  }

  override setIndexViewSelected(index:number){
    this.store.dispatch( this.isControlPanel? setIndexViewSelectedCP({index:index}):setIndexViewSelected({index: index}));
  }

  override setViewSelected(view:ViewOrder){
    this.store.dispatch(this.isControlPanel? setViewSelectedCP({view:view }):setViewSelectedO({view: view}));
  }

  override setFilter(filter:OrderString){
    this.store.dispatch( this.isControlPanel? setFilterOrderControlPanel({orderString:filter}) :
    setFilterOrder({orderString: filter}));
  }
}