import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'filter-svg',
  templateUrl: './filter-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class FilterSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
