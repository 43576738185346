import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import * as dict from 'src/app/shared/dict/dict';
import { PriceService } from 'src/app/shared/services/price.service';
import { PriceValidator } from 'src/app/shared/price';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { buyers } from 'src/app/shared/dict/buyers';
import { User } from 'src/app/shared/interfaces/user.interface';
import { orderCategory } from 'src/app/shared/dict/orders';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { Subscription } from 'rxjs';
import { wayPay } from 'src/app/shared/dict/wayToPay';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./../details.scss']
})
export class DetailsPriceComponent implements OnInit, OnDestroy {
  public user!:User;


  //If the price is exporter, more fields are displayed.
  //*ngIf are performed based on the value of isPort.
  @Input() isPort!: boolean;
  disabledButton: boolean = false;
  isOpenCommission: boolean = false;
  price!: Price;
  buyersToGenerate: any[] = [];
  isPriceFeatured:boolean=false;
  isPriceExport:boolean=false;

  isGenerateToFix:boolean=false;
  isShowInfo:boolean=true;
  isShowToFix:boolean=true;

  public ORDER_TYPE_CATEGORY: any;
  public PRODUCTS: any;
  public BUSINESS_TYPE: any;
  public PLACE_OF_DELIVERY: any;
  public PECULIARITIES: any;
  public QUALITY: any;
  public COIN_TYPE: any;
  public WAY_TO_PAY: any;
  public BUYERS: any;

  private priceSubscriptions!:Subscription;
  private userSubscriptions!:Subscription;
  private optionsSubscriptions!:Subscription;

  constructor(private store: Store<appState>, private priceV:PriceValidator) {
    this.ORDER_TYPE_CATEGORY = orderCategory;
    this.BUSINESS_TYPE = typeBusiness;
    this.PRODUCTS = PRODUCT_NAMES;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.QUALITY = dict.quality;
    this.COIN_TYPE = dict.typeCoinsStr;
    this.WAY_TO_PAY = wayPay;
    this.BUYERS = buyers;
  }

  ngOnInit() {
    this.setSubscriptions();  
  }

  ngOnDestroy(){
    this.priceSubscriptions.unsubscribe();
    this.userSubscriptions.unsubscribe();
    this.optionsSubscriptions.unsubscribe();
  }

  setSubscriptions(){
    this.priceSubscriptions=this.store.select('price').subscribe(price => {
      this.price = price.generatePrice;
      this.buyersToGenerate=price.arrayOfBuyers;
      this.isPriceFeatured= price.generatePrice.observations.isFeatured==1;
      this.isPriceExport=price.generatePrice.observations.isPort==1;
      this.disabledButton = this.priceV.isPriceIncomplete(this.price); //this.isDisabledButton(this.price);
    })

    this.userSubscriptions=this.store.select('usuario').subscribe(user=>{
      this.user=user;
    })
  
    this.optionsSubscriptions=this.store.select('options').subscribe(optionsR=>{
      this.isGenerateToFix=optionsR.isGenerateToFix;
    })

  }

  changeShowInfo(){
    this.isShowInfo=!this.isShowInfo;
  }

  changeShowToFix(){
    this.isShowToFix=!this.isShowToFix;
  }

  getArrowDirection(boolean:boolean){
    return boolean? 'down': 'up';
  }

  getConditionType(){
    return this.getPrice().conditionType;
  }

  getIndicators(){
    return this.getPrice().indicators;
  }
  
  getPrice(){
    return this.price;
  }

  getPriceOfPrice(price:Price){
    return this.priceV.getPriceOfCondition(price)
  }

  getTypeCoin(price:Price){
    return this.priceV.getTypeCoinString(price);
  }
}
