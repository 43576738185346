import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPipe'
})

export class NumberPipe implements PipeTransform {

  transform(number: any) {
    /*Given a number, it is modified to apply a point every 3 digits.*/
    /* Dejando solo 2 decimales */

    const numberStr=number.toString();
    const commaSeparated=numberStr.split('.');
    const numOfLetter=commaSeparated[0].length;
    let result='';
    let numOfNumbersPlaced=0;
    for(let i=numOfLetter-1; i>=0; i--){
      if(numOfNumbersPlaced % 3 == 0 && numOfNumbersPlaced!=0){
        result= this.joinRootAndEnding('.', result);
      }
      result= this.joinRootAndEnding(commaSeparated[0][i], result);
      numOfNumbersPlaced++;
      
    }
    if(commaSeparated.length>1){
      result += ',' + commaSeparated[1].substring(0,2);
    }

    return result;
  }


  joinRootAndEnding(root:string, ending:string){
    return root.concat(ending);
  }
}
