import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { View } from "./view";
import { Store } from "@ngrx/store";
import { appState } from "src/app/shared/interfaces/appState.interface";
import { teams } from "src/app/shared/dict/user";
import { ViewOrder, ViewOrderExchange } from "src/app/shared/interfaces/views.interface";
import { OrderString } from "src/app/shared/interfaces/order.interface";

@Component({
    template: '',
  })
  export class ViewStructOrder extends View implements OnInit,OnDestroy {
    @Input() override view!: ViewOrderExchange | ViewOrder;
    @Input() override arrayOfViews!: any[];

    public TABLES:any=Object.values(teams);
    public showTables:boolean=false;
    public tableChecks: string[] = [];

    constructor(store:Store<appState>){
        super(store);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.tableChecks = JSON.parse(JSON.stringify(this.view.orderString.nroTeam));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    //------- TABLES --------\\
    public changeShowTables(){
      this.showTables=!this.showTables;
    }

    public setCheck(table:string){
      this.tableChecks.includes(table)?
        this.tableChecks.splice(this.tableChecks.indexOf(table),1):
        this.tableChecks.push(table);
    }

    public addTeamsInView(): ViewOrderExchange | ViewOrder {
      let newView:ViewOrderExchange | ViewOrder = JSON.parse(JSON.stringify(this.view));
      newView.orderString.nroTeam = this.getNroTeam();
      return newView;
    }

    getNroTeam():string[] {
      return this.tableChecks
    }

    //---------------------------\\

    override createView() {
        if(this.isInvalidCreateView()){
          this.isButtonClicked=true
        } else{
        const viewStr: string = localStorage.getItem(this.getLocalStorageName()) ?? '';
        if (this.identificator == 1) {
          let newView=this.addTeamsInView();
          newView.isHistorical=this.isHistorical;
          
          let views=viewStr? JSON.parse(viewStr): [];
          views.push(newView);
    
          this.isApplyButton=true;
          localStorage.setItem(this.getLocalStorageName(), JSON.stringify(views));
          this.setInStore(views, -2, newView, newView.orderString);
          this.resetNewView();
          this.resetView('order');
        }
      }
    }

    override deleteView() {
        const arrayModified = this.arrayOfViews.filter((view)=> this.isHistorical == view.isHistorical)
        const restantes = this.arrayOfViews.filter((view)=> this.isHistorical != view.isHistorical)
    
        arrayModified.splice(this.indexToEdit, 1);
    
        const arrayComplete = [...arrayModified, ...restantes];
    
        localStorage.setItem(this.getLocalStorageName(), JSON.stringify(arrayComplete));
        this.setInStore(arrayComplete, -2);
    }

    override editView() {
        const arrayModified = this.arrayOfViews.filter((view) => this.isHistorical == view.isHistorical);
        const restantes = this.arrayOfViews.filter((view) => this.isHistorical != view.isHistorical);
    
        let newView= this.addTeamsInView();
    
        arrayModified[this.indexToEdit] = newView;
        const arrayComplete = [...arrayModified, ...restantes];
        this.isApplyButton=true;
        localStorage.setItem(this.getLocalStorageName(), JSON.stringify(arrayComplete));
        
        this.setInStore(arrayComplete, -2,newView, newView.orderString);
      }

    protected resetNewView(){}

    override notClickedNothing(): boolean {
        let viewParse=JSON.parse(JSON.stringify(this.view));
        delete viewParse.viewName;
        delete viewParse.orderString;
        delete viewParse.isHistorical;
        return Object.values(viewParse).every((value:any)=>value==false);
    }
}
