import { Component,OnInit,OnDestroy,ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { PriceValidator } from 'src/app/shared/price';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { PriceBoard } from 'src/app/components/board/price/price-board';

@Component({
  selector: 'app-price-table-comercial',
  templateUrl: './price-table-comercial.component.html',
  styleUrls: ["../price-table.component.scss", './../../../../../shared/styles/board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceTableComercialComponent extends PriceBoard implements OnInit, OnDestroy {
  constructor(store: Store<appState>, router: Router, date:DateChecker, priceV:PriceValidator,
  toFixV:ToFixValidator, cdr:ChangeDetectorRef) {
    super(store, router,date, priceV,toFixV,cdr);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  override getDeliveryPeriod(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.priceV.getDeliveryPeriod(price);
  }
}