import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetNewViewO, resetViewSelectedO, setAllViewsO, setFilterOrder, setIndexViewSelected, setViewSelectedO } from 'src/app/redux/actions/order.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { areas, REPRESENTATION_CONST_ARRAY } from 'src/app/shared/const/user.const';
import { VIEW_ORDER_ALL, VIEW_ORDER_FIELDS, VIEW_ORDER_VOID } from 'src/app/shared/const/views.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { OrderString } from 'src/app/shared/interfaces/order.interface';
import { resetNewViewCP, resetViewSelectedCP, setAllViewsCP, setFilterOrderControlPanel, setIndexViewSelectedCP, setViewSelectedCP } from 'src/app/redux/actions/control-panel.action';
import { ViewStructOrder } from '../view-order';

@Component({
  selector: 'views-orders',
  templateUrl: './views-orders.component.html',
  styleUrls: ['./../views.component.scss']
})
export class ViewsOrdersComponent extends ViewStructOrder implements OnInit, OnDestroy {
  @Input() isControlPanel:boolean=false; //If is a view for control panel.
  @Input() override view: ViewOrder = VIEW_ORDER_VOID;
  @Input() override arrayOfViews: ViewOrder[] = [];
  
  isComercial: boolean = false;
  isRepresentation: boolean = false;
  nroTeam: number = 0;
  VIEW_ORDER_FIELDS = VIEW_ORDER_FIELDS;

  private order$!:Subscription;
  private user$!:Subscription;

  constructor(store: Store<appState>) {
    super(store);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if(!this.isControlPanel){    
      //Si no es control panel, nos subscribimos a este store.
      this.order$=this.store.select('order').subscribe(orderReducer => {
        this.arrayOfViews = orderReducer.viewsOfOrderBoard.views;
        this.indexToEditInStore = orderReducer.viewsOfOrderBoard.indexViewSelected;
        if(this.indexToEditInStore==this.indexToEdit) this.wasInside=true;
      })
    } else {    
      //Si es control panel, nos subscribimos a este store.
      this.order$=this.store.select('controlPanel').subscribe(controlPanelReducer => {
        this.arrayOfViews = controlPanelReducer.viewsOfControlPanelBoard.views;
        this.indexToEditInStore = controlPanelReducer.viewsOfControlPanelBoard.indexViewSelected;
        if(this.indexToEditInStore==this.indexToEdit) this.wasInside=true;
      })
    }

    this.user$=this.store.select('usuario').subscribe(usuarioReducer => {
      this.nroTeam = usuarioReducer.team.nroTeam;
      this.isComercial = usuarioReducer.area==areas.comercial;
      this.isRepresentation = REPRESENTATION_CONST_ARRAY.includes(usuarioReducer.workUnit);
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.user$?.unsubscribe();
    this.order$?.unsubscribe();
  }

  override getViewAll(): ViewOrder {
    let newView=JSON.parse(JSON.stringify(VIEW_ORDER_ALL));
    delete newView.viewName;
    delete newView.orderString;
    delete newView.isHistorical;
    return newView;
  }

  override getViewVoid(): ViewOrder {
    let newView=JSON.parse(JSON.stringify(VIEW_ORDER_VOID));
    delete newView.viewName;
    delete newView.orderString;
    delete newView.isHistorical;
    return newView;
  }

  override getLocalStorageName(){
    return this.isControlPanel? localStorageNames.viewsControlPanel:localStorageNames.viewOrders;
  }

  override setIndexViewSelected(index:number){
    this.store.dispatch(this.isControlPanel? setIndexViewSelectedCP({index:index}): setIndexViewSelected({ index: index }));
  }

  override setInStore(views:ViewOrder[], indexViewSelected:number, view:ViewOrder|null=null, orderString:OrderString|null=null){
    if(this.isControlPanel){
      //ACCIONES PARA EL PANEL DE CONTROL
      this.store.dispatch(setAllViewsCP({ views: views }));
      this.store.dispatch(view? setViewSelectedCP({ view: view }):resetViewSelectedCP());
      this.store.dispatch(setIndexViewSelectedCP({ index: indexViewSelected }));
      orderString?this.store.dispatch(setFilterOrderControlPanel({orderString: orderString})):'';
    } else {
      //ACCIONES PARA EL PANEL DE ORDENES
      this.store.dispatch(setAllViewsO({ views: views }));
      this.store.dispatch(view? setViewSelectedO({ view: view }):resetViewSelectedO());
      this.store.dispatch(setIndexViewSelected({ index: indexViewSelected }));
      orderString?this.store.dispatch(setFilterOrder({orderString: orderString})):'';    
    }

    //Guardamos en la base de datos
    this.saveInDB(views);
  }

  override resetNewView(){
    this.store.dispatch(this.isControlPanel? resetNewViewCP():resetNewViewO());
  }

  override getNroTeam():string[] {
    return this.isRepresentation? [this.nroTeam.toString()]:this.tableChecks
  }
}
