import { Component, Input, OnInit, OnDestroy,ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { appState } from "src/app/shared/interfaces/appState.interface";
import * as userConst from 'src/app/shared/const/user.const';
import { ViewOrder, ViewOrderExchange, ViewPrice } from "src/app/shared/interfaces/views.interface";
import { PriceString } from "src/app/shared/interfaces/price.interface";
import { OrderString } from "src/app/shared/interfaces/order.interface";
import { setAllViews, setViewSelected } from "src/app/redux/actions/price.action";
import { localStorageNames } from "src/app/shared/const/localStorageNames";
import { setAllViewsO, setViewSelectedO } from "src/app/redux/actions/order.action";
import { priceTags, typeCoins } from "src/app/shared/const/prices.const";
import { Subscription } from "rxjs";
import { User } from "src/app/shared/interfaces/user.interface";
import { DateChecker, parseDate } from "src/app/shared/date.checker";
import { NAVEGATION } from "src/app/shared/const/navegation";
import { setAllViewsCP, setViewSelectedCP } from "src/app/redux/actions/control-panel.action";
import { updViewsDB } from "../views/view";


@Component({
  template: '',
})
export class Filter implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() filterIdentificator: number = -1;

  @Input() onlyFilter: boolean = false; //If the component is only a filter, it is not necessary to create the structure of the component.

  //It is an identification by string.
  @Input() identificatorString: string = '';

  //Only utilised when the element is an anidade struct
  @Input() identificatorStringAux: string = '';

  @Input() arrayAux: any = [];

  //If the component to create contains the search or the value is of numeric type
  @Input() isSearchOrNumber: boolean = false;

  @Input() isToFix:boolean=false;

  isControlPanel:boolean=false;

  //Detect number events.
  //And in case the time is leaking, build its structure.
  valueToSet: any;

  user!:User;
  isComercial!: boolean; //Store of redux.
  isOperator!: boolean; //Store of redux.
  search: string = ''; //Value for the search if necessary
  selects: any[] = [];//List of selected values.
  isFilter: boolean = false;
  isDisabledButton: boolean = true;

  //For detect click
  protected wasInside = false;
  protected count: number = 0; //CLICKS
  filterIndexSelected: number = -1;

  //VIEWS
  views!: any[];
  isHistorical: boolean = false; //Variable para saber si estamos en las vistas del historial o no.
  viewSelected!: ViewPrice | ViewOrder;

  array: any[] = [];
  private userSubscription!: Subscription;
  private optionSubscription!: Subscription;
  constructor(public store: Store<appState>, public date:DateChecker) {
  }

  ngOnInit(): void {
    this.userSubscription = this.store.select('usuario').subscribe(usuario => {
      this.user=usuario;
      this.isComercial = usuario.area == userConst.areas.comercial;
      this.isOperator = usuario.area == userConst.areas.commodities;
    });

    this.optionSubscription = this.store.select('options', 'location').subscribe(location => {
      this.isHistorical= location == NAVEGATION.HISTORICAL;
      this.isControlPanel= location == NAVEGATION.CONTROL_PANEL;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.optionSubscription.unsubscribe();
  }


  toArray(obj: any) {
    const array = [];
    for (var i in obj) {
      array.push(obj[i]);
    }
    if (array[0] == '--') array.shift();
    if (array[array.length - 1] == '--') array.pop();
    return array;
  }

  setSearch(event: any) {
    this.search = event.target.value;
  }

  changeTo(option: string) {
    if (option == 'filter') {
      this.isFilter = true;
      this.selects = [];
      if (this.identificatorString == priceTags.price) this.setValueToSetInPrice();
    } else if (option == 'edit') {
      this.isFilter = false;
      this.selects = [];
      if (this.identificatorString == priceTags.price) this.setValueToSetInPrice();
    }
  }

  setValueToSetInPrice() {
    
  }

  getTypeForm(): string {
    return this.isFilter ? 'checkbox' : 'radio';
  }

  selectAll() {
    this.selects = JSON.parse(JSON.stringify(this.array));
  }

  deleteAll() {
    this.selects = [];
    //resetSelectedOrderList
    //ResetSelectedPriceList
  }

  getIsDisabledButton() {
    if (!this.isFilter && this.identificatorString == priceTags.price) {
      let valueToReturn: any;
      try {
        valueToReturn = this.valueToSet.typeCoin ? false : true;
      } catch (err) {
        valueToReturn = true;
      }
      return valueToReturn;
    } else {
      return !this.isFilter && this.selects.length == 0 && this.array.length > 0 && this.valueToSet;
    }
  }

  setValueToSetDate(event:any){
    this.valueToSet = {startDate: parseDate(event.startDate),
      endDate: parseDate(event.endDate)}
  }

  deleteDateRangeSelected(): void {
    this.valueToSet = {startDate: '', endDate: ''}
  }

  deleteDateSelected() {
    this.valueToSet = null;
  }

  editView(localStorageName: string, filterString: PriceString | OrderString) {

    //Dividimos las vistas entre las que estamos y las que no.
    const newViewsAux: any[] = this.views.filter((view: any) => view.isHistorical == this.isHistorical);
    let newViews=JSON.parse(JSON.stringify(newViewsAux));
    const restantes= this.views.filter((view: any) => view.isHistorical != this.isHistorical); 
    //Search the index;
    let indexToEdit = newViewsAux.indexOf(this.viewSelected);
    let viewsToSet: any[] = [];
    if (indexToEdit > -1) {
      if (localStorageNames.viewPrices == localStorageName) {
        newViews[indexToEdit].priceString = filterString;
        viewsToSet= [...newViews, ...restantes];

        this.store.dispatch(setAllViews({ views: viewsToSet }));
        this.store.dispatch(setViewSelected({ view: newViews[indexToEdit] }));
      } else if (localStorageNames.viewOrders == localStorageName) {
        newViews[indexToEdit].orderString = filterString;
        viewsToSet= [...newViews, ...restantes];

        this.store.dispatch(setAllViewsO({ views: viewsToSet }));
        this.store.dispatch(setViewSelectedO({ view: newViews[indexToEdit] }));
      } else if (localStorageNames.viewsControlPanel == localStorageName) {
        newViews[indexToEdit].orderString = filterString;
        viewsToSet= [...newViews, ...restantes];
        this.store.dispatch(setAllViewsCP({ views: viewsToSet }));
        this.store.dispatch(setViewSelectedCP({ view: newViews[indexToEdit] }));
      }
      
      localStorage.setItem(localStorageName, JSON.stringify(viewsToSet));
      updViewsDB(this.store, this.user.email,viewsToSet,localStorageName)
    }
  }

  showWayPay(element:string){
    const isWayPayToFix= element.includes("Contra fijacion");
    return (!this.isToFix && isWayPayToFix) || (this.isToFix && !isWayPayToFix);
  }

}