import { createAction, props } from "@ngrx/store";
import { Order, OrderString } from "src/app/shared/interfaces/order.interface";
import { ViewOrder } from "src/app/shared/interfaces/views.interface";

//Orders
export const loadOrdersBoardControlPanel = createAction('[CONTROL PANEL] Load Orders',
    props<{ flag:string}>());
export const loadOrdersAllSuccessInControlPanel = createAction('[CONTROL PANEL] Load Orders All Success',
    props<{ orders: Order[] }>());
export const loadOrdersFailedControlPanel = createAction('[CONTROL PANEL] Load Orders Failed');

//Order filter
export const setFilterOrderControlPanel = createAction('[FILTER ORDER CONTROL PANEL] Set Filter Order',
    props<{ orderString: OrderString }>());
export const setSectionTypeFilterControlPanel = createAction('[FILTER ORDER CONTROL PANEL] Set Buyer Type Filter In Control Panel',
    props<{ sectionType: string }>())
export const setDivisionTypeFilterControlPanel = createAction('[FILTER ORDER CONTROL PANEL] Set Division Type Filter In Control Panel',
    props<{ sectionType: string }>())
export const setFilterIndexControlPanel= createAction('[FILTER ORDER CONTROL PANEL] Set INDEX Filter In Order List',
    props<{ index:number }>())

//Views of Order board
export const setViewToGenerateCP = createAction('[VIEW] Set View To Generate In CONTROL PANEL',
    props<{ view: ViewOrder }>());
export const setNewViewCP = createAction('[VIEW] Set New View In CONTROL PANEL',
    props<{ view: ViewOrder }>());
export const resetNewViewCP = createAction('[VIEW] Reset New View In CONTROL PANEL');
export const resetViewSelectedCP = createAction('[VIEW] Reset View Selected In CONTROL PANEL');
export const setViewSelectedCP = createAction('[VIEW] Set View Selected In CONTROL PANEL',
    props<{ view: ViewOrder }>());
export const setAllViewsCP = createAction('[VIEW] Set All Views In CONTROL PANEL',
    props<{ views: ViewOrder[] }>());
export const setIndexViewSelectedCP = createAction('[VIEW] Set Index Of View Selected In CONTROL PANEL',
props<{ index: number }>());


//Orders selected to edit (Only Commodities)
export const setOrdersSelectedInControlPanel = createAction('[ORDERS SELECTED CONTROL PANEL] Set Orders Selected',
    props<{ orders: Order[] }>());

//EDIT ORDERS
export const editOrders = createAction('[EDIT ORDERS] Edit Orders',
    props<{ orders: Order[], field: string, value: any }>());
export const editOrdersSuccess = createAction('[EDIT ORDERS] Edit Orders Success',
    props<{ message: string }>());
export const editOrdersFailed = createAction('[EDIT ORDERS] Edit Orders Failed',
    props<{ message: string }>());

export const editOrder = createAction('[EDIT ORDER] Edit Order',
    props<{ order: Order, field: string, subField: string, value: any }>());
export const editOrderSuccess = createAction('[EDIT ORDER] Edit Order Success',
    props<{ message: string }>());
export const editOrderFailed = createAction('[EDIT ORDER] Edit Order Failed',
    props<{ message: string }>());

//DUPLICATE ORDERS
export const setNumOfDuplicateOrders = createAction('[DUPLICATE ORDERS] Set Number Of Duplicate Orders',
    props<{ numOfDuplicateOrders: number }>());
export const setOrdersToGenerate = createAction('[GENERATE ORDERS] Set Generate Orders',
    props<{ orders: Order[] }>());


export const setIndexToEdit = createAction('[DUPLICATE ORDERS] Set Index To Edit Order',
    props<{ indexToEdit: number }>());

//ORDERS EDIT
export const setValidationOrdersToGenerate = createAction('[EDIT ORDERS] Set Generate Orders',
    props<{ orders: Order[] }>());
export const setIndexToEditValidation = createAction('[EDIT ORDERS] Set Index To Edit Order',
    props<{ indexToEdit: number }>());
    export const setEditAll = createAction('[EDIT ORDERS] Set Edit All Orders',
    props<{ editAll: boolean }>());

//Details of order
export const setOrderDetailsInControlPanel = createAction('[ORDER DETAILS CONTROL PANEL] Set Order Details',
    props<{ order: Order }>());
export const showOrderDetailsInControlPanel = createAction('[ORDER DETAILS CONTROL PANEL] Show Order Details', props<{ show: boolean }>());
