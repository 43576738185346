export const provinces={
    "-1": "--",
    0: "--",
    1: "Buenos Aires",
    2: "Caba",
    3: "Catamarca",
    4: "Chaco",
    5: "Chubut",
    6: "Cordoba",
    7: "Corrientes",
    8: "Entre Rios",
    9: "Formosa",
    10: "Jujuy",
    11: "La Pampa",
    12: "La Rioja",
    13: "Mendoza",
    14: "Misiones",
    15: "Neuquen",
    16: "Rio Negro",
    17: "Salta",
    18: "San Juan",
    19: "San Luis",
    20: "Santa Cruz",
    21: "Santa Fe",
    22: "Santiago del Estero",
    23: "Tierra del Fuego",
    24: "Tucuman"   
}

export const matchProvincesWithSellers={
    "-1": '-1',
    0: 0,
    1: 1, //Buenos aires.
    2: 6, //Córdoba.
    3: 8, //Entre Rios.
    4: 11, //La Pampa.
    5: 19, //San Luis.
    6: 21, //Santa Fe.
    7: 22, //Santiago del Estero.
    8: 24, //Tucuman.
    9: 4, //Chaco
    10: 7, //Corrientes
    11: 17, //Salta
    12: 14, //Misiones
    13: 9, //Formosa
    14: 10, //Jujuy
    15: 5, //Chubut
    16: 15, //Neuquen
    17: 16, //Rio Negro
    18: 20, //Santa Cruz
    19: 13, //Mendoza
    20: 3, //Catamarca.
    21: 12, //La Rioja.
    22: 18, //San Juan
    23: 2, //Caba.
    24: 23, //Tierra del Fuego.
}

export const provincesWithLetterIBM={
    1: "B", //Buenos aires
    2: "C", //Caba
    3: "K", //Catamarca
    4: "H", //Chaco
    5: "U", //Chubut
    6: "X", //Córdoba
    7: "W", //Corrientes
    8: "E", //Entre Rios
    9: "P", //Formosa
    10: "Y", //Jujuy
    11: "L", //La Pampa
    12: "F", //La Rioja
    13: "M", //Mendoza
    14: "N", //Misiones
    15: "Q", //Neuquen
    16: "R", //Rio negro 
    17: "A", //Salta
    18: "J", //San Juan
    19: "D", //San Luis
    20: "Z", //Santa Cruz
    21: "S", //Santa Fe
    22: "G", //Santiago del Estero
    23: "V", //Tierra del Fuego
    24: "T" //Tucuman
}