import { Component, Input } from '@angular/core';
import { Indicator } from 'src/app/shared/interfaces/price-to-fix.interface';
import { isIndicatorsIncomplete } from 'src/app/shared/to-fix';
import { isConditionTypeSelected } from './indicator/indicator.component';
import { CONDITIONS_TYPE_TO_FIX_CONST } from 'src/app/shared/const/to-fix';

@Component({
  selector: 'app-to-fix-indicators',
  templateUrl: './to-fix-indicators.component.html',
  styleUrls: ['./to-fix-indicators.component.scss', './../fields.component.scss']
})
export class ToFixIndicatorsComponent{
  @Input() fieldIdentificator:string='';
  @Input() indicators:Indicator[]=[];
  @Input() conditionType:number=0;
  showOptions:boolean=false;
  isClickedButton:boolean=false;
  showMessageSinEspacio:boolean=false;

  public editIndicator:{indicator:Indicator,index:number}|null=null;
  public deleteIndicator:Indicator|null=null;

  constructor(){}

  setShowOptions(bool:boolean, exception:boolean=false){
    this.isClickedButton=this.conditionType<=0;
    this.showMessageSinEspacio= isConditionTypeSelected(this.conditionType, CONDITIONS_TYPE_TO_FIX_CONST.SIN_ESPACIO) && this.indicators.length>=1 && !exception;
    if(!this.isClickedButton && !this.showMessageSinEspacio){
      this.showOptions=bool;

      //Si se cierra el menu de opciones, se limpian los indicadores a editar y eliminar
      if(!bool){
        this.editIndicator=null;
        this.deleteIndicator=null;
      }
    }
  }

  getOptions(){
    return this.indicators ?? [];
  }

  edit(indicator:Indicator, index:number){
    this.setShowOptions(true, true)
    this.editIndicator={indicator:indicator,index:index};
  }

  delete(indicator:Indicator){
    this.setShowOptions(true, true)
    this.deleteIndicator=indicator;
  }
  
  isInvalidsIndicators(indicators:Indicator[], conditionType:number){
    return isIndicatorsIncomplete(indicators, conditionType);
  }
}
