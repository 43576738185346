import { Component, OnChanges } from '@angular/core';
import { AGREE_WITH_VOID } from 'src/app/shared/const/business-particularities';
import { AgreeWith } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-pactado-con',
  templateUrl: './pactado-con.component.html',
  styleUrls: ['./../options.component.scss']
})
export class PactadoConComponent extends BusinessParticularityOption implements OnChanges {
  agreeWith: AgreeWith=AGREE_WITH_VOID;

  constructor() {
    super()
  }

  ngOnChanges(): void {
    try{
      this.agreeWith=this.businessParticularitiesStruct.agreedWith;
    } catch(err){
      this.agreeWith=AGREE_WITH_VOID;
    }
  }

  setAgreeWith(agreeWith:AgreeWith){
    this.agreeWith=agreeWith;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.agreedWith=this.agreeWith;
    this.emit2(newBP);
  }

  getAgreeWith(){
    return this.agreeWith;
  }

  setValue(value:string, tag:string){
    let newAgreeWith=JSON.parse(JSON.stringify(this.agreeWith));
    newAgreeWith[tag]=value;
    this.setAgreeWith(newAgreeWith);
  }
  
  verifyPactadoCon(event:any){
  }

  isPactadoConActive(){
    try{
      return this.agreeWith.buyer.length>3 || this.agreeWith.comercialName.length>3 || this.agreeWith.responsable.length>3;
    } catch(e){
      return false;
    }
  }

}
