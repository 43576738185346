import { Component } from '@angular/core';

@Component({
  selector: 'trash-svg-do',
  templateUrl: './trash.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class TrashDuplicateOrderSvgDOComponent {
  constructor() { }
}
