import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceBoardComponent } from './price-board.component';
import { HeaderModule } from 'src/app/components/header/header.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { MenuFooterModule } from 'src/app/components/menu-footer/menu-footer.module';
import { PriceBodyModule } from './body-price-board/body-price-board.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    PriceBoardComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    MenuModule,
    MenuFooterModule,
    PriceBodyModule,
    HeaderInSectionModule,
    ModalModule
  ]
})
export class PriceBoardModule { }
