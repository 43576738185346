<div class="field">
    <span class="field-title">Particularidad de calidad</span>

    <div>
        <div class="form-field" [ngClass]="{'border-green': isOptionSelected}"
            (click)="changeShowOptions()">
            <span class="title-field"> 
                <input #inputName (focus)="setShowOptions(true)"
                value="{{elementSelected}}" placeholder="{{placeHolder}}"
                class="no-background-border" />
            </span>
        </div>

        <div class="background opaque" *ngIf="showOptions" (click)="closeSlide()"></div>

        <q-p-slide-card class="slide-card slide-card-visible" *ngIf="showOptions" 
        (closeEvent)="closeSlide()" [fieldIdentificator]="fieldIdentificator"></q-p-slide-card>

    </div>
</div>