export const santaFeLocalities=[
    {"afip":"14","location":"22 De Mayo"},
    {"afip":"21","location":"29"},
    {"afip":"38","location":"Abipones"},
    {"afip":"73","location":"Acebal"},
    {"afip":"81","location":"Achaval Rodriguez"},
    {"afip":"106","location":"Aero Club Rosario"},
    {"afip":"201","location":"Aguara Grande"},
    {"afip":"248","location":"Albarellos"},
    {"afip":"266","location":"Aldao"},
    {"afip":"301","location":"Alejandra"},
    {"afip":"351","location":"Allende"},
    {"afip":"501","location":"Ambrosetti"},
    {"afip":"504","location":"Amenabar"},
    {"afip":"554","location":"Andino"},
    {"afip":"571","location":"Angel Gallardo"},
    {"afip":"613","location":"Antonio Pini"},
    {"afip":"675","location":"Arequito"},
    {"afip":"683","location":"Arijon"},
    {"afip":"691","location":"Arminda"},
    {"afip":"693","location":"Armstrong"},
    {"afip":"695","location":"Arocena"},
    {"afip":"697","location":"Aromos"},
    {"afip":"698","location":"Aron Castellanos"},
    {"afip":"701","location":"Arrascaeta"},
    {"afip":"712","location":"Arroyo Aguiar"},
    {"afip":"768","location":"Arroyo Del Rey"},
    {"afip":"801","location":"Arroyo Leyes"},
    {"afip":"885","location":"Arrufo"},
    {"afip":"886","location":"Arsenal De Guerra San Lorenzo"},
    {"afip":"888","location":"Arteaga"},
    {"afip":"896","location":"Ascochingas"},
    {"afip":"910","location":"Asuncion Maria"},
    {"afip":"914","location":"Ataliva"},
    {"afip":"930","location":"Aurelia"},
    {"afip":"931","location":"Aurelia Norte"},
    {"afip":"932","location":"Aurelia Sud"},
    {"afip":"938","location":"Avena"},
    {"afip":"945","location":"Avichuncho"},
    {"afip":"1049","location":"Bajo Las Tunas"},
    {"afip":"1184","location":"Barlett"},
    {"afip":"1230","location":"Barrio Belgrano Ortiz"},
    {"afip":"1232","location":"Barrio Caima"},
    {"afip":"1374","location":"Bauer Y Sigel"},
    {"afip":"1415","location":"Bealistock"},
    {"afip":"1433","location":"Bella Italia"},
    {"afip":"1451","location":"Berabevu 1"},
    {"afip":"1460","location":"Berna"},
    {"afip":"1464","location":"Bernard"},
    {"afip":"1472","location":"Berretta"},
    {"afip":"1480","location":"Bigand"},
    {"afip":"1512","location":"Boca P 25"},
    {"afip":"1530","location":"Bombal"},
    {"afip":"1549","location":"Borghi"},
    {"afip":"1556","location":"Bouquet"},
    {"afip":"1612","location":"Bustinza"},
    {"afip":"1630","location":"Cabal"},
    {"afip":"1637","location":"Cabaña El Cisne"}
    ,
    {"afip":"1643","location":"Cabeza De Chancho"},
    {"afip":"1691","location":"Cacique Ariacaiquin"},
    {"afip":"1700","location":"Cafferata"},
    {"afip":"1731","location":"Calchaqui"},
    {"afip":"1734","location":"Calchines"},
    {"afip":"1806","location":"Camino Monte Flores"},
    {"afip":"1838","location":"Campo Andino"},
    {"afip":"1849","location":"Campo Berraz"},
    {"afip":"1853","location":"Campo Botto"},
    {"afip":"1855","location":"Campo Brarda"},
    {"afip":"1861","location":"Campo Carignano"},
    {"afip":"1863","location":"Campo Castro"},
    {"afip":"1864","location":"Campo Charo"},
    {"afip":"1866","location":"Campo Clucellas"},
    {"afip":"1869","location":"Campo Coubert"},
    {"afip":"1871","location":"Campo Crenna"},
    {"afip":"1872","location":"Campo Crespo"},
    {"afip":"1874","location":"Campo Daratti"},
    {"afip":"1897","location":"Campo Del Medio"},
    {"afip":"1904","location":"Campo El Araza"},
    {"afip":"1908","location":"Campo El Mataco"},
    {"afip":"1916","location":"Campo Faggiano"},
    {"afip":"1921","location":"Campo Furrer"},
    {"afip":"1923","location":"Campo Galloso"},
    {"afip":"1924","location":"Campo Garabato"},
    {"afip":"1925","location":"Campo Garay"},
    {"afip":"1926","location":"Campo Garcia"},
    {"afip":"1928","location":"Campo Genero"},
    {"afip":"1929","location":"Campo Gimbatti"},
    {"afip":"1930","location":"Campo Gimenez"},
    {"afip":"1931","location":"Campo Gola"},
    {"afip":"1936","location":"Campo Horquesco"},
    {"afip":"1937","location":"Campo Huber"},
    {"afip":"1938","location":"Campo Iturraspe"},
    {"afip":"1939","location":"Campo La Amistad"},
    {"afip":"1949","location":"Campo La Paz"},
    {"afip":"1952","location":"Campo La Riviere"},
    {"afip":"1959","location":"Campo Lehman"},
    {"afip":"1967","location":"Campo Magnin"},
    {"afip":"1969","location":"Campo Medina"},
    {"afip":"1971","location":"Campo Monte La Viruela"},
    {"afip":"1974","location":"Campo Moure"},
    {"afip":"1980","location":"Campo Paletta"},
    {"afip":"1982","location":"Campo Pesoa"},
    {"afip":"1987","location":"Campo Quirno"},
    {"afip":"1988","location":"Campo Quiñones"}
    ,
    {"afip":"1989","location":"Campo Raffo"},
    {"afip":"1993","location":"Campo Ramseyer"},
    {"afip":"1999","location":"Campo Rodriguez"},
    {"afip":"2003","location":"Campo Rueda"},
    {"afip":"2008","location":"Campo San Jose"},
    {"afip":"2010","location":"Campo Santa Isabel"},
    {"afip":"2012","location":"Campo Santo Domingo"},
    {"afip":"2013","location":"Campo Siete Provincias"},
    {"afip":"2017","location":"Campo Torquinston"},
    {"afip":"2019","location":"Campo Ubajo"},
    {"afip":"2022","location":"Campo Verge"},
    {"afip":"2026","location":"Campo Yaguarete"},
    {"afip":"2029","location":"Campo Zavalla"},
    {"afip":"2030","location":"Campo Zurbriggen"},
    {"afip":"2060","location":"Candioti"},
    {"afip":"2119","location":"Capilla Guadalupe Norte"},
    {"afip":"2132","location":"Capitan Bermudez"},
    {"afip":"2142","location":"Capivara"},
    {"afip":"2170","location":"Carcaraña"}
    ,
    {"afip":"2172","location":"Carcel Modelo Coronda"},
    {"afip":"2187","location":"Carlos Dose"},
    {"afip":"2192","location":"Carlos Pellegrini"},
    {"afip":"2200","location":"Carmen"},
    {"afip":"2203","location":"Carmen Del Sauce"},
    {"afip":"2235","location":"Carrizales"},
    {"afip":"2273","location":"Casablanca"},
    {"afip":"2275","location":"Casalegno"},
    {"afip":"2280","location":"Casas"},
    {"afip":"2293","location":"Casilda"},
    {"afip":"2348","location":"Cavour"},
    {"afip":"2350","location":"Cayasta"},
    {"afip":"2351","location":"Cayastacito"},
    {"afip":"2376","location":"Cañada De Gomez"}
    ,
    {"afip":"2412","location":"Cañada Del Ucle"}
    ,
    {"afip":"2426","location":"Cañada Ombu"}
    ,
    {"afip":"2430","location":"Cañada Rosquin"}
    ,
    {"afip":"2440","location":"Cañadita"}
    ,
    {"afip":"2496","location":"Centeno"},
    {"afip":"2507","location":"Cepeda"},
    {"afip":"2509","location":"Cerana"},
    {"afip":"2513","location":"Ceres"},
    {"afip":"2765","location":"Chabas"},
    {"afip":"2846","location":"Chapuy"},
    {"afip":"2867","location":"Chateaubriand"},
    {"afip":"2884","location":"Chañar Ladeado"}
    ,
    {"afip":"3003","location":"Chovet"},
    {"afip":"3035","location":"Cicarelli"},
    {"afip":"3069","location":"Clarke"},
    {"afip":"3070","location":"Clason"},
    {"afip":"3081","location":"Clucellas"},
    {"afip":"3123","location":"Colastine"},
    {"afip":"3124","location":"Colastine Norte"},
    {"afip":"3144","location":"Colmena"},
    {"afip":"3177","location":"Colonia Adolfo Alsina"},
    {"afip":"3184","location":"Colonia Aldao"},
    {"afip":"3196","location":"Colonia Althuaus"},
    {"afip":"3203","location":"Colonia Ana"},
    {"afip":"3208","location":"Colonia Angeloni"},
    {"afip":"3246","location":"Colonia Bella Italia"},
    {"afip":"3249","location":"Colonia Berlin"},
    {"afip":"3254","location":"Colonia Bicha"},
    {"afip":"3255","location":"Colonia Bigand"},
    {"afip":"3262","location":"Colonia Bossi"},
    {"afip":"3277","location":"Colonia California"},
    {"afip":"3279","location":"Colonia Campo Botto"},
    {"afip":"3282","location":"Colonia Candelaria"},
    {"afip":"3294","location":"Colonia Castelar"},
    {"afip":"3295","location":"Colonia Castellanos"},
    {"afip":"3302","location":"Colonia Cello"},
    {"afip":"3309","location":"Colonia Clara"},
    {"afip":"3310","location":"Colonia Clodomira"},
    {"afip":"3314","location":"Colonia Corondina"},
    {"afip":"3357","location":"Colonia Dos Rosas"},
    {"afip":"3388","location":"Colonia El Ochenta"},
    {"afip":"3401","location":"Colonia El Simbol"},
    {"afip":"3406","location":"Colonia El Toba"},
    {"afip":"3409","location":"Colonia El Veinticinco"},
    {"afip":"3414","location":"Colonia Ella"},
    {"afip":"3421","location":"Colonia Escribano"},
    {"afip":"3435","location":"Colonia Fernandez"},
    {"afip":"3438","location":"Colonia Fidela"},
    {"afip":"3448","location":"Colonia Francesa"},
    {"afip":"3467","location":"Colonia Gomez"},
    {"afip":"3483","location":"Colonia Hansen"},
    {"afip":"3500","location":"Colonia Independencia"},
    {"afip":"3516","location":"Colonia Josefina"},
    {"afip":"3528","location":"Colonia La  Catalana"},
    {"afip":"3529","location":"Colonia La  Palencia"},
    {"afip":"3530","location":"Colonia La  Pellegrini"},
    {"afip":"3537","location":"Colonia La Blanca"},
    {"afip":"3550","location":"Colonia La Costa"},
    {"afip":"3573","location":"Colonia La Maria"},
    {"afip":"3583","location":"Colonia La Negra"},
    {"afip":"3585","location":"Colonia La Nicolasa"},
    {"afip":"3587","location":"Colonia La Nueva"},
    {"afip":"3595","location":"Colonia La Penca"},
    {"afip":"3614","location":"Colonia La Yerba"},
    {"afip":"3616","location":"Colonia Lago Di Como"},
    {"afip":"3663","location":"Colonia Los Zapallos"},
    {"afip":"3674","location":"Colonia Mackinlay"},
    {"afip":"3678","location":"Colonia Malhman Sud"},
    {"afip":"3680","location":"Colonia Manuel Menchaca"},
    {"afip":"3681","location":"Colonia Margarita"},
    {"afip":"3691","location":"Colonia Mascias"},
    {"afip":"3694","location":"Colonia Maua"},
    {"afip":"3700","location":"Colonia Medici"},
    {"afip":"3715","location":"Colonia Montefiore"},
    {"afip":"3718","location":"Colonia Morgan"},
    {"afip":"3740","location":"Colonia Nueva Narciso"},
    {"afip":"3756","location":"Colonia Ortiz"},
    {"afip":"3785","location":"Colonia Piaggio"},
    {"afip":"3786","location":"Colonia Piamontesa"},
    {"afip":"3804","location":"Colonia Pujol"},
    {"afip":"3810","location":"Colonia Reina Margarita"},
    {"afip":"3813","location":"Colonia Ripamonti"},
    {"afip":"3823","location":"Colonia Rosa"},
    {"afip":"3828","location":"Colonia Sager"},
    {"afip":"3847","location":"Colonia San Joaquin"},
    {"afip":"3861","location":"Colonia San Roque"},
    {"afip":"3866","location":"Colonia Santa Anita"},
    {"afip":"3874","location":"Colonia Santa Lucia"},
    {"afip":"3880","location":"Colonia Santa Natalia"},
    {"afip":"3900","location":"Colonia Silva"},
    {"afip":"3905","location":"Colonia Sol De Mayo"},
    {"afip":"3918","location":"Colonia Teresa"},
    {"afip":"3925","location":"Colonia Toscana Primera"},
    {"afip":"3926","location":"Colonia Toscana Segunda"},
    {"afip":"3933","location":"Colonia Tres Reyes"},
    {"afip":"3937","location":"Colonia Valdez"},
    {"afip":"3938","location":"Colonia Valencia"},
    {"afip":"3961","location":"Colonia Yaguarete"},
    {"afip":"4028","location":"Constanza"},
    {"afip":"4030","location":"Constituyentes"},
    {"afip":"4052","location":"Cora"},
    {"afip":"4068","location":"Coronda"},
    {"afip":"4070","location":"Coronel Aguirre"},
    {"afip":"4074","location":"Coronel Arnold"},
    {"afip":"4081","location":"Coronel Bogado"},
    {"afip":"4086","location":"Coronel Dominguez"},
    {"afip":"4094","location":"Coronel Fraga"},
    {"afip":"4115","location":"Coronel Rodriguez"},
    {"afip":"4116","location":"Coronel Roseti"},
    {"afip":"4197","location":"Costa Del Toba"},
    {"afip":"4236","location":"Cresta"},
    {"afip":"4238","location":"Crispi"},
    {"afip":"4244","location":"Cristolia"},
    {"afip":"4295","location":"Cuatro Casas"},
    {"afip":"4296","location":"Cuatro De Febrero"},
    {"afip":"4337","location":"Cullen"},
    {"afip":"4339","location":"Cululu"},
    {"afip":"4356","location":"Curupaity"},
    {"afip":"4419","location":"Desvio Arauz"},
    {"afip":"4420","location":"Desvio Arijon"},
    {"afip":"4421","location":"Desvio Boero"},
    {"afip":"4423","location":"Desvio Dr Barros Pazos"},
    {"afip":"4431","location":"Dho"},
    {"afip":"4434","location":"Diaz"},
    {"afip":"4437","location":"Diego De Alvear"},
    {"afip":"4469","location":"Distrito 3 Isletas"},
    {"afip":"4482","location":"Doce Casas"},
    {"afip":"4588","location":"Durham"},
    {"afip":"4600","location":"Egusquiza"},
    {"afip":"4611","location":"El 38"},
    {"afip":"4612","location":"El 44"},
    {"afip":"4619","location":"El Aguara"},
    {"afip":"4631","location":"El Alberdon"},
    {"afip":"4646","location":"El Amargo"},
    {"afip":"4696","location":"El Bayo"},
    {"afip":"4706","location":"El Bonete"},
    {"afip":"4755","location":"El Cantor"},
    {"afip":"4758","location":"El Caramelo"},
    {"afip":"4767","location":"El Carmen De Avellaneda"},
    {"afip":"4795","location":"El Ceibalito"},
    {"afip":"4979","location":"El Galpon"},
    {"afip":"5005","location":"El Gusano"},
    {"afip":"5071","location":"El Mariano"},
    {"afip":"5125","location":"El Nochero"},
    {"afip":"5160","location":"El Pajaro Blanco"},
    {"afip":"5177","location":"El Para"},
    {"afip":"5303","location":"El Rabon"},
    {"afip":"5330","location":"El Ricardito"},
    {"afip":"5383","location":"El Sombrerero"},
    {"afip":"5384","location":"El Sombrerito"},
    {"afip":"5410","location":"El Tapialito"},
    {"afip":"5445","location":"El Trebol"},
    {"afip":"5529","location":"Elisa"},
    {"afip":"5531","location":"Elortondo"},
    {"afip":"5544","location":"Emilia"},
    {"afip":"5570","location":"Empalme Villa Constitucion"},
    {"afip":"5573","location":"Encadenadas"},
    {"afip":"5616","location":"Esmeralda"},
    {"afip":"5617","location":"Esmeraldita"},
    {"afip":"5626","location":"Espin"},
    {"afip":"5693","location":"Estacion Christophersen"},
    {"afip":"5695","location":"Estacion Clucellas"},
    {"afip":"5699","location":"Estacion Erasto"},
    {"afip":"5707","location":"Estacion Josefina"},
    {"afip":"5713","location":"Estacion Maria Juana"},
    {"afip":"5714","location":"Estacion Matilde"},
    {"afip":"5724","location":"Estacion Saguier"},
    {"afip":"5730","location":"Estacion Teodelina"},
    {"afip":"5734","location":"Estacion Villa Constitucion"},
    {"afip":"5743","location":"Estancia Achala"},
    {"afip":"5841","location":"Estancia La Cigueña"}
    ,
    {"afip":"5842","location":"Estancia La Constancia"},
    {"afip":"5914","location":"Estancia Los Palmares"},
    {"afip":"5931","location":"Estancia Pavenhan"},
    {"afip":"5935","location":"Estancia Prusia"},
    {"afip":"5998","location":"Esteban Rams"},
    {"afip":"6013","location":"Estrada"},
    {"afip":"6021","location":"Eusebia"},
    {"afip":"6024","location":"Eustolia"},
    {"afip":"6027","location":"Ewald"},
    {"afip":"6046","location":"Fabrica Militar San Lorenzo"},
    {"afip":"6075","location":"Fassi"},
    {"afip":"6086","location":"Felicia"},
    {"afip":"6104","location":"Fighiera"},
    {"afip":"6153","location":"Firmat"},
    {"afip":"6160","location":"Fives Lille"},
    {"afip":"6163","location":"Flor De Oro"},
    {"afip":"6187","location":"Fortin Alerta"},
    {"afip":"6188","location":"Fortin Almagro"},
    {"afip":"6189","location":"Fortin Argentina"},
    {"afip":"6190","location":"Fortin Atahualpa"},
    {"afip":"6195","location":"Fortin Cacique"},
    {"afip":"6199","location":"Fortin Charrua"},
    {"afip":"6200","location":"Fortin Chilcas"},
    {"afip":"6217","location":"Fortin Olmos"},
    {"afip":"6223","location":"Fortin Seis De Caballeria"},
    {"afip":"6224","location":"Fortin Tacuru"},
    {"afip":"6226","location":"Fortin Tostado"},
    {"afip":"6253","location":"Francisco Paz"},
    {"afip":"6255","location":"Franck"},
    {"afip":"6277","location":"Fuentes"},
    {"afip":"6287","location":"Funes"},
    {"afip":"6292","location":"Gaboto"},
    {"afip":"6300","location":"Galisteo"},
    {"afip":"6308","location":"Galvez"},
    {"afip":"6316","location":"Garabato"},
    {"afip":"6325","location":"Garibaldi"},
    {"afip":"6344","location":"Gato Colorado"},
    {"afip":"6381","location":"General Lagos"},
    {"afip":"6430","location":"Gessler"},
    {"afip":"6455","location":"Gobernador Crespo"},
    {"afip":"6484","location":"Godeken"},
    {"afip":"6485","location":"Godoy"},
    {"afip":"6489","location":"Golondrina"},
    {"afip":"6527","location":"Granadero B Bargas"},
    {"afip":"6528","location":"Granadero Baigorria"},
    {"afip":"6529","location":"Granadero Brasilio Bustos"},
    {"afip":"6531","location":"Granaderos"},
    {"afip":"6538","location":"Granja San Manuel"},
    {"afip":"6539","location":"Gregoria Perez De Denis"},
    {"afip":"6549","location":"Grutly"},
    {"afip":"6550","location":"Grutly Norte"},
    {"afip":"6559","location":"Guadalupe Norte"},
    {"afip":"6589","location":"Guaranies"},
    {"afip":"6604","location":"Guasuncho"},
    {"afip":"6686","location":"Helvecia"},
    {"afip":"6701","location":"Hersilia"},
    {"afip":"6719","location":"Hipatia"},
    {"afip":"6792","location":"Huanqueros"},
    {"afip":"6828","location":"Hugentobler"},
    {"afip":"6829","location":"Hughes"},
    {"afip":"6845","location":"Humberto Primero"},
    {"afip":"6846","location":"Humboldt"},
    {"afip":"6847","location":"Humboldt Chico"},
    {"afip":"6861","location":"Ibarlucea"},
    {"afip":"6922","location":"Ingeniero Boasi"},
    {"afip":"6924","location":"Ingeniero Chanourdie"},
    {"afip":"6930","location":"Ingeniero Garmendia"},
    {"afip":"6931","location":"Ingeniero Germania"},
    {"afip":"6979","location":"Intiyaco"},
    {"afip":"6999","location":"Iriondo"},
    {"afip":"7034","location":"Isla Del Porteño"}
    ,
    {"afip":"7062","location":"Isla Tigre"},
    {"afip":"7093","location":"Iturraspe"},
    {"afip":"7171","location":"Jose Macias"},
    {"afip":"7188","location":"Juan B Molina"},
    {"afip":"7211","location":"Juan Ortiz"},
    {"afip":"7398","location":"La Cabral"},
    {"afip":"7411","location":"La Calma"},
    {"afip":"7412","location":"La Camila"},
    {"afip":"7459","location":"La Ceramica Y Cuyo"},
    {"afip":"7486","location":"La Chispa"},
    {"afip":"7497","location":"La Cigueña"}
    ,
    {"afip":"7498","location":"La Clara"},
    {"afip":"7502","location":"La Clorinda"},
    {"afip":"7558","location":"La Diamela"},
    {"afip":"7666","location":"La Gallareta"},
    {"afip":"7694","location":"La Guampita"},
    {"afip":"7712","location":"La Hosca"},
    {"afip":"7724","location":"La Ines"},
    {"afip":"7725","location":"La Inglesita"},
    {"afip":"7784","location":"La Lola"},
    {"afip":"7829","location":"La Marina"},
    {"afip":"7896","location":"La Othilia"},
    {"afip":"7938","location":"La Pelada"},
    {"afip":"7978","location":"La Polvareda"},
    {"afip":"7988","location":"La Potasa"},
    {"afip":"8087","location":"La Sarnosa"},
    {"afip":"8091","location":"La Sementera"},
    {"afip":"8189","location":"La Viuda"},
    {"afip":"8206","location":"La Zulema"},
    {"afip":"8211","location":"Labordeboy"},
    {"afip":"8330","location":"Landeta"},
    {"afip":"8334","location":"Lanteri"},
    {"afip":"8346","location":"Larguia"},
    {"afip":"8351","location":"Larrechea"},
    {"afip":"8371","location":"Las Anintas"},
    {"afip":"8420","location":"Las Catalinas"},
    {"afip":"8440","location":"Las Chuñas"}
    ,
    {"afip":"8472","location":"Las Dos Angelitas"},
    {"afip":"8492","location":"Las Garsitas"},
    {"afip":"8596","location":"Las Parejas"},
    {"afip":"8606","location":"Las Petacas"},
    {"afip":"8649","location":"Las Siete Provincias"},
    {"afip":"8687","location":"Las Trojas"},
    {"afip":"8718","location":"Lassaga"},
    {"afip":"8753","location":"Lehmann"},
    {"afip":"8786","location":"Liceo Aeronautico Militar"},
    {"afip":"8814","location":"Links"},
    {"afip":"8829","location":"Llambi Campbell"},
    {"afip":"8846","location":"Logroño"}
    ,
    {"afip":"8967","location":"Los Amores"},
    {"afip":"9021","location":"Los Cardenales"},
    {"afip":"9047","location":"Los Charabones"},
    {"afip":"9063","location":"Los Claros"},
    {"afip":"9095","location":"Los Cuervos"},
    {"afip":"9237","location":"Los Osos"},
    {"afip":"9322","location":"Los Saladillos"},
    {"afip":"9332","location":"Los Sembrados"},
    {"afip":"9343","location":"Los Tabanos Desvio Km 366"},
    {"afip":"9417","location":"Luciano Leiva"},
    {"afip":"9422","location":"Lucio V Lopez"},
    {"afip":"9434","location":"Luis Palacios"},
    {"afip":"9476","location":"Maciel"},
    {"afip":"9496","location":"Maggiolo"},
    {"afip":"9507","location":"Maizales"},
    {"afip":"9519","location":"Malabrigo"},
    {"afip":"9587","location":"Mangore"},
    {"afip":"9595","location":"Manucho"},
    {"afip":"9635","location":"Marcelino Escalada"},
    {"afip":"9647","location":"Margarita"},
    {"afip":"9660","location":"Maria Eugenia"},
    {"afip":"9668","location":"Maria Juana"},
    {"afip":"9672","location":"Maria Luisa Correa"},
    {"afip":"9676","location":"Maria Susana"},
    {"afip":"9677","location":"Maria Teresa"},
    {"afip":"9687","location":"Mariano Saavedra"},
    {"afip":"9716","location":"Mascias"},
    {"afip":"9807","location":"Melincue"},
    {"afip":"9823","location":"Merceditas"},
    {"afip":"9853","location":"Miguel Escalada"},
    {"afip":"9984","location":"Moises Ville"},
    {"afip":"10025","location":"Monigotes"},
    {"afip":"10026","location":"Monje"},
    {"afip":"10056","location":"Monte Flores"},
    {"afip":"10068","location":"Monte Obscuridad"},
    {"afip":"10076","location":"Monte Vera"},
    {"afip":"10126","location":"Moussy"},
    {"afip":"10142","location":"Murphy"},
    {"afip":"10145","location":"Mutchnik"},
    {"afip":"10188","location":"Nare"},
    {"afip":"10209","location":"Nelson"},
    {"afip":"10217","location":"Nicanor E Molinas"},
    {"afip":"10296","location":"Nueva Pompeya"},
    {"afip":"10301","location":"Nueva Ukrania"},
    {"afip":"10316","location":"Nuevo Torino"},
    {"afip":"10327","location":"Obraje Indio Muerto"},
    {"afip":"10334","location":"Obraje San Juan"},
    {"afip":"10346","location":"Ogilive"},
    {"afip":"10367","location":"Oliveros"},
    {"afip":"10376","location":"Ombu Norte"},
    {"afip":"10393","location":"Oratorio Morante"},
    {"afip":"10406","location":"Oroño"}
    ,
    {"afip":"10418","location":"Otto Bemberg"},
    {"afip":"10451","location":"Padre Pedro Iturralde"},
    {"afip":"10455","location":"Paganini"},
    {"afip":"10464","location":"Paikin"},
    {"afip":"10729","location":"Paraje Tragnaghi"},
    {"afip":"10911","location":"Paso Vinal"},
    {"afip":"10936","location":"Paul Groussac"},
    {"afip":"10943","location":"Pavenhan"},
    {"afip":"10946","location":"Pavon Arriba"},
    {"afip":"10968","location":"Pedro Gomez Cello"},
    {"afip":"10998","location":"Pereyra Lucena"},
    {"afip":"11001","location":"Perez"},
    {"afip":"11008","location":"Pericota"},
    {"afip":"11021","location":"Petronila"},
    {"afip":"11022","location":"Peyrano"},
    {"afip":"11036","location":"Piamonte"},
    {"afip":"11156","location":"Pino De San Lorenzo"},
    {"afip":"11162","location":"Piquete"},
    {"afip":"11306","location":"Portalis"},
    {"afip":"11338","location":"Portugalete"},
    {"afip":"11383","location":"Potrero Guasuncho"},
    {"afip":"11397","location":"Pozo Borrado"},
    {"afip":"11431","location":"Pozo De Los Indios"},
    {"afip":"11530","location":"Presidente Roca"},
    {"afip":"11608","location":"Pueblo Abc"},
    {"afip":"11611","location":"Pueblo Alcorta"},
    {"afip":"11612","location":"Pueblo Arequito"},
    {"afip":"11617","location":"Pueblo Candioti"},
    {"afip":"11627","location":"Pueblo Esther"},
    {"afip":"11632","location":"Pueblo Golondrina"},
    {"afip":"11634","location":"Pueblo Kirston"},
    {"afip":"11638","location":"Pueblo Maria Juana"},
    {"afip":"11639","location":"Pueblo Marini"},
    {"afip":"11641","location":"Pueblo Miguel Torres"},
    {"afip":"11643","location":"Pueblo Muñoz"}
    ,
    {"afip":"11652","location":"Pueblo Ramona"},
    {"afip":"11655","location":"Pueblo San Antonio"},
    {"afip":"11656","location":"Pueblo San Bernardo"},
    {"afip":"11662","location":"Pueblo Terragni"},
    {"afip":"11675","location":"Puente Colastine"},
    {"afip":"11751","location":"Puerto Aragon"},
    {"afip":"11782","location":"Puerto De San Lorenzo"},
    {"afip":"11792","location":"Puerto Gaboto"},
    {"afip":"11797","location":"Puerto Gral San Martin"},
    {"afip":"11833","location":"Puerto Ocampo"},
    {"afip":"11845","location":"Puerto Piracua"},
    {"afip":"11846","location":"Puerto Piracuacito"},
    {"afip":"11851","location":"Puerto Reconquista"},
    {"afip":"12097","location":"Pujato"},
    {"afip":"12098","location":"Pujato Norte"},
    {"afip":"12284","location":"Rabiola"},
    {"afip":"12295","location":"Rafaela"},
    {"afip":"12310","location":"Ramayon"},
    {"afip":"12355","location":"Raquel"},
    {"afip":"12357","location":"Rastreador Fournier"},
    {"afip":"12388","location":"Reconquista Dest Aer Militar"},
    {"afip":"12390","location":"Recreo Sur"},
    {"afip":"12409","location":"Reina Margarita"},
    {"afip":"12447","location":"Reynaldo Cullen"},
    {"afip":"12464","location":"Ricardone"},
    {"afip":"12469","location":"Rigby"},
    {"afip":"12486","location":"Rincon De Grondona"},
    {"afip":"12506","location":"Rincon De San Antonio"},
    {"afip":"12510","location":"Rincon De Tacurales"},
    {"afip":"12522","location":"Rincon Del Pintado"},
    {"afip":"12523","location":"Rincon Del Quebracho"},
    {"afip":"12539","location":"Rincon Potreros"},
    {"afip":"12548","location":"Rinlon De Avila"},
    {"afip":"12673","location":"Rodolfo Alcorta"},
    {"afip":"12678","location":"Roldan"},
    {"afip":"12685","location":"Romang"},
    {"afip":"12699","location":"Rosario"},
    {"afip":"12722","location":"Rueda"},
    {"afip":"12723","location":"Rufino"},
    {"afip":"12727","location":"Ruinas Santa Fe La Vieja"},
    {"afip":"12749","location":"Runciman"},
    {"afip":"12786","location":"Sa Pereyra"},
    {"afip":"12799","location":"Saguier"},
    {"afip":"12811","location":"Saladero M Cabal"},
    {"afip":"12863","location":"Salto Grande"},
    {"afip":"12896","location":"San Antonio De Obligado"},
    {"afip":"12925","location":"San Carlos Centro"},
    {"afip":"12927","location":"San Carlos Norte"},
    {"afip":"12928","location":"San Carlos Sud"},
    {"afip":"12948","location":"San Estanislao"},
    {"afip":"12952","location":"San Fabian"},
    {"afip":"12968","location":"San Francisco De Santa Fe"},
    {"afip":"12976","location":"San Genaro"},
    {"afip":"12977","location":"San Genaro Norte"},
    {"afip":"12999","location":"San Jeronimo Del Sauce"},
    {"afip":"13000","location":"San Jeronimo Norte"},
    {"afip":"13001","location":"San Jeronimo Sud"},
    {"afip":"13019","location":"San Jose De La Esquina"},
    {"afip":"13038","location":"San Jose Del Rincon"},
    {"afip":"13042","location":"San Jose Frontera"},
    {"afip":"13076","location":"San Marcos De Venado Tuerto"},
    {"afip":"13079","location":"San Mariano"},
    {"afip":"13082","location":"San Martin De Las Escobas"},
    {"afip":"13087","location":"San Martin Norte"},
    {"afip":"13127","location":"San Pedro Sur"},
    {"afip":"13140","location":"San Ricardo"},
    {"afip":"13166","location":"Sancti Spiritu"},
    {"afip":"13170","location":"Sanford"},
    {"afip":"13193","location":"Santa Clara De Buena Vista"},
    {"afip":"13194","location":"Santa Clara De Saguier"},
    {"afip":"13208","location":"Santa Fe"},
    {"afip":"13229","location":"Santa Margarita"},
    {"afip":"13244","location":"Santa Natalia"},
    {"afip":"13246","location":"Santa Paula"},
    {"afip":"13256","location":"Santa Rosa De Calchines"},
    {"afip":"13310","location":"Santurce"},
    {"afip":"13327","location":"Sastre"},
    {"afip":"13358","location":"Sauce Viejo"},
    {"afip":"13379","location":"Schiffner"},
    {"afip":"13405","location":"Semino"},
    {"afip":"13416","location":"Serodino"},
    {"afip":"13422","location":"Setubal"},
    {"afip":"13463","location":"Sierra Pereyra"},
    {"afip":"13487","location":"Sin Pereza"},
    {"afip":"13529","location":"Soldini"},
    {"afip":"13549","location":"Souto Mayor"},
    {"afip":"13558","location":"Stephenson"},
    {"afip":"13563","location":"Suardi"},
    {"afip":"13582","location":"Sunchales"},
    {"afip":"13605","location":"Susana"},
    {"afip":"13667","location":"Tacuarendi"},
    {"afip":"13680","location":"Tais"},
    {"afip":"13770","location":"Tarragona"},
    {"afip":"13814","location":"Teodelina"},
    {"afip":"13842","location":"Theobald"},
    {"afip":"13871","location":"Timbues Jose Maria"},
    {"afip":"13933","location":"Tomas Alva Edison"},
    {"afip":"13980","location":"Tortugas"},
    {"afip":"13987","location":"Tostado"},
    {"afip":"13994","location":"Totoras"},
    {"afip":"14003","location":"Traill"},
    {"afip":"14049","location":"Tres Colonias"},
    {"afip":"14130","location":"Tte Hipolito Bouchard"},
    {"afip":"14201","location":"Uranga"},
    {"afip":"14302","location":"Veinticuatro Casas"},
    {"afip":"14307","location":"Velazquez"},
    {"afip":"14310","location":"Venado Tuerto"},
    {"afip":"14320","location":"Vera"},
    {"afip":"14322","location":"Vera Mujica"},
    {"afip":"14323","location":"Vera Y Pintado"},
    {"afip":"14341","location":"Vicente Echeverria"},
    {"afip":"14349","location":"Victor Manuel Segundo"},
    {"afip":"14356","location":"Videla"},
    {"afip":"14363","location":"Vila"},
    {"afip":"14374","location":"Villa Adela"},
    {"afip":"14393","location":"Villa Amelia"},
    {"afip":"14394","location":"Villa America"},
    {"afip":"14395","location":"Villa Ana"},
    {"afip":"14424","location":"Villa Biota"},
    {"afip":"14450","location":"Villa Cassini"},
    {"afip":"14454","location":"Villa Cañas"}
    ,
    {"afip":"14474","location":"Villa Constitucion"},
    {"afip":"14516","location":"Villa Divisa De Mayo"},
    {"afip":"14538","location":"Villa Eloisa"},
    {"afip":"14547","location":"Villa Estela"},
    {"afip":"14565","location":"Villa Fredickson"},
    {"afip":"14568","location":"Villa Garibaldi"},
    {"afip":"14585","location":"Villa Gobernador Galvez"},
    {"afip":"14594","location":"Villa Guastalla"},
    {"afip":"14596","location":"Villa Guillermina"},
    {"afip":"14629","location":"Villa La Ribera"},
    {"afip":"14639","location":"Villa Lastenia"},
    {"afip":"14666","location":"Villa Lyly Talleres"},
    {"afip":"14678","location":"Villa Maria Selva"},
    {"afip":"14693","location":"Villa Minetti"},
    {"afip":"14704","location":"Villa Mugueta"},
    {"afip":"14718","location":"Villa Ocampo"},
    {"afip":"14739","location":"Villa Porucci"},
    {"afip":"14762","location":"Villa Regules"},
    {"afip":"14779","location":"Villa Rosello"},
    {"afip":"14790","location":"Villa San Diego"},
    {"afip":"14818","location":"Villa Saralegui"},
    {"afip":"14851","location":"Villa Trinidad"},
    {"afip":"14867","location":"Villa Viveros"},
    {"afip":"14868","location":"Villa Yapeyu"},
    {"afip":"14874","location":"Villada"},
    {"afip":"14883","location":"Villani"},
    {"afip":"14906","location":"Virginia"},
    {"afip":"14952","location":"Vuelta Del Pirata"},
    {"afip":"14955","location":"Walvelberg"},
    {"afip":"14964","location":"Wheelwright"},
    {"afip":"14967","location":"Wildermuth"},
    {"afip":"14998","location":"Yaguarete"},
    {"afip":"15006","location":"Yamandu"},
    {"afip":"15077","location":"Zadockhan"},
    {"afip":"15080","location":"Zamponi"},
    {"afip":"15082","location":"Zanetti"},
    {"afip":"15107","location":"Zenon Pereyra"},
    {"afip":"15130","location":"Ñandu"}
    ,
    {"afip":"15131","location":"Ñandubay"}
    ,
    {"afip":"15133","location":"Ñanducita"}
    ,
    {"afip":"15174","location":"Adolfo Alsina"},
    {"afip":"15207","location":"Aguara"},
    {"afip":"15254","location":"Alto Verde"},
    {"afip":"15256","location":"Alvarez"},
    {"afip":"15257","location":"Alvear"},
    {"afip":"15263","location":"Angelica"},
    {"afip":"15296","location":"Arroyo Ceibal"},
    {"afip":"15305","location":"Arroyo Seco"},
    {"afip":"15312","location":"Avellaneda"},
    {"afip":"15348","location":"Barrancas"},
    {"afip":"15357","location":"Barranquitas"},
    {"afip":"15398","location":"Bernardo De Irigoyen"},
    {"afip":"15443","location":"Caminera General Lopez"},
    {"afip":"15454","location":"Campo Calvo"},
    {"afip":"15460","location":"Campo Grande"},
    {"afip":"15463","location":"Campo Hardy"},
    {"afip":"15466","location":"Campo Nuevo"},
    {"afip":"15468","location":"Campo Redondo"},
    {"afip":"15470","location":"Campo Romero"},
    {"afip":"15472","location":"Campo Urdaniz"},
    {"afip":"15478","location":"Candelaria Sud"},
    {"afip":"15482","location":"Capilla San Jose"},
    {"afip":"15484","location":"Caraguatay"},
    {"afip":"15493","location":"Carreras"},
    {"afip":"15538","location":"Cañada Rica"}
    ,
    {"afip":"15564","location":"Cerrito"},
    {"afip":"15664","location":"Colonia Belgrano"},
    {"afip":"15671","location":"Colonia Dolores"},
    {"afip":"15672","location":"Colonia Duran"},
    {"afip":"15674","location":"Colonia El Carmen"},
    {"afip":"15688","location":"Colonia La Mora"},
    {"afip":"15697","location":"Colonia Matilde"},
    {"afip":"15709","location":"Colonia San Antonio"},
    {"afip":"15712","location":"Colonia San Francisco"},
    {"afip":"15720","location":"Colonia San Jose"},
    {"afip":"15724","location":"Colonia San Manuel"},
    {"afip":"15736","location":"Colonia Santa Catalina"},
    {"afip":"15755","location":"Colonia Tacurales"},
    {"afip":"15756","location":"Colonia Tres Marias"},
    {"afip":"15771","location":"Copacabana"},
    {"afip":"15795","location":"Correa"},
    {"afip":"15817","location":"Cuatro Esquinas"},
    {"afip":"15875","location":"El Araza"},
    {"afip":"15890","location":"El Bagual"},
    {"afip":"15966","location":"El Ceibo"},
    {"afip":"15998","location":"El Cincuenta"},
    {"afip":"16027","location":"El Diecisiete"},
    {"afip":"16083","location":"El Jardin"},
    {"afip":"16088","location":"El Laurel"},
    {"afip":"16235","location":"El Pozo"},
    {"afip":"16289","location":"El Refugio"},
    {"afip":"16380","location":"El Tajamar"},
    {"afip":"16396","location":"El Timbo"},
    {"afip":"16407","location":"El Transito"},
    {"afip":"16411","location":"El Triangulo"},
    {"afip":"16419","location":"El Tropezon"},
    {"afip":"16448","location":"Empalme San Carlos"},
    {"afip":"16465","location":"Esperanza"},
    {"afip":"16478","location":"Esquina Grande"},
    {"afip":"16485","location":"Estancia La Maria"},
    {"afip":"16486","location":"Estancia Las Gamas"},
    {"afip":"16490","location":"Estancia San Antonio"},
    {"afip":"16491","location":"Estancia San Francisco"},
    {"afip":"16504","location":"Esther"},
    {"afip":"16514","location":"Florencia"},
    {"afip":"16523","location":"Florida"},
    {"afip":"16528","location":"Fray Luis Beltran"},
    {"afip":"16530","location":"Frontera"},
    {"afip":"16541","location":"General Gelly"},
    {"afip":"16557","location":"Guaycuru"},
    {"afip":"16578","location":"Independencia"},
    {"afip":"16589","location":"Irigoyen"},
    {"afip":"16599","location":"Isleta"},
    {"afip":"16603","location":"Ituzaingo"},
    {"afip":"16609","location":"Jesus Maria"},
    {"afip":"16611","location":"Jose Manuel Estrada"},
    {"afip":"16617","location":"Juncal"},
    {"afip":"16623","location":"La Adelaida"},
    {"afip":"16671","location":"La Asturiana"},
    {"afip":"16693","location":"La Blanca"},
    {"afip":"16700","location":"La Bombilla"},
    {"afip":"16702","location":"La Brava"},
    {"afip":"16714","location":"La California"},
    {"afip":"16720","location":"La Capilla"},
    {"afip":"16727","location":"La Carolina"},
    {"afip":"16730","location":"La Catalina"},
    {"afip":"16750","location":"La Celia"},
    {"afip":"16779","location":"La Clarita"},
    {"afip":"16803","location":"La Constancia"},
    {"afip":"16821","location":"La Criolla"},
    {"afip":"16854","location":"La Elsa"},
    {"afip":"16870","location":"La Esmeralda"},
    {"afip":"16954","location":"La Gama"},
    {"afip":"16971","location":"La Guardia"},
    {"afip":"17020","location":"La Josefina"},
    {"afip":"17024","location":"La Julia"},
    {"afip":"17039","location":"La Lata"},
    {"afip":"17056","location":"La Loma"},
    {"afip":"17060","location":"La Lucila"},
    {"afip":"17100","location":"La Merced"},
    {"afip":"17110","location":"La Morocha"},
    {"afip":"17118","location":"La Noria"},
    {"afip":"17125","location":"La Oriental"},
    {"afip":"17126","location":"La Orilla"},
    {"afip":"17164","location":"La Picasa"},
    {"afip":"17256","location":"La Reserva"},
    {"afip":"17266","location":"La Rosa"},
    {"afip":"17274","location":"La Rubia"},
    {"afip":"17278","location":"La Salada"},
    {"afip":"17283","location":"La Sarita"},
    {"afip":"17288","location":"La Selva"},
    {"afip":"17344","location":"La Vanguardia"},
    {"afip":"17350","location":"La Verde"},
    {"afip":"17393","location":"Laguna Paiva"},
    {"afip":"17398","location":"Laguna Verde"},
    {"afip":"17420","location":"Las Arenas"},
    {"afip":"17422","location":"Las Avispas"},
    {"afip":"17426","location":"Las Bandurrias"},
    {"afip":"17449","location":"Las Cañas"}
    ,
    {"afip":"17502","location":"Las Delicias"},
    {"afip":"17505","location":"Las Encadenadas"},
    {"afip":"17518","location":"Las Garzas"},
    {"afip":"17533","location":"Las Higueritas"},
    {"afip":"17556","location":"Las Liebres"},
    {"afip":"17584","location":"Las Mercedes"},
    {"afip":"17604","location":"Las Palmas"},
    {"afip":"17606","location":"Las Palmeras"},
    {"afip":"17643","location":"Las Quintas"},
    {"afip":"17653","location":"Las Rosas"},
    {"afip":"17679","location":"Las Toscas"},
    {"afip":"17686","location":"Las Tres Marias"},
    {"afip":"17689","location":"Las Tunas"},
    {"afip":"17706","location":"Lazzarino"},
    {"afip":"17719","location":"Loma Alta"},
    {"afip":"17744","location":"Loma Verde"},
    {"afip":"17752","location":"Lopez"},
    {"afip":"17780","location":"Los Arcos"},
    {"afip":"17803","location":"Los Cardos"},
    {"afip":"17811","location":"Los Cerrillos"},
    {"afip":"17841","location":"Los Corralitos"},
    {"afip":"17852","location":"Los Galpones"},
    {"afip":"17859","location":"Los Hornos"},
    {"afip":"17863","location":"Los Lapachos"},
    {"afip":"17866","location":"Los Laureles"},
    {"afip":"17867","location":"Los Leones"},
    {"afip":"17884","location":"Los Molinos"},
    {"afip":"17891","location":"Los Molles"},
    {"afip":"17896","location":"Los Muchachos"},
    {"afip":"17902","location":"Los Nogales"},
    {"afip":"17906","location":"Los Olivos"},
    {"afip":"17909","location":"Los Palmares"},
    {"afip":"17947","location":"Los Quirquinchos"},
    {"afip":"18016","location":"Maria Luisa"},
    {"afip":"18021","location":"Maximo Paz"},
    {"afip":"18054","location":"Miramar"},
    {"afip":"18058","location":"Mocovi"},
    {"afip":"18096","location":"Montes De Oca"},
    {"afip":"18127","location":"Nueva Italia"},
    {"afip":"18157","location":"Palacios"},
    {"afip":"18228","location":"Pavon"},
    {"afip":"18267","location":"Pilar"},
    {"afip":"18276","location":"Pompeya"},
    {"afip":"18350","location":"Progreso"},
    {"afip":"18351","location":"Providencia"},
    {"afip":"18362","location":"Pueblo Nuevo"},
    {"afip":"18461","location":"Reconquista"},
    {"afip":"18465","location":"Recreo"},
    {"afip":"18489","location":"Rincon Norte"},
    {"afip":"18514","location":"Rio Salado"},
    {"afip":"18523","location":"Rivadavia"},
    {"afip":"18566","location":"San Agustin"},
    {"afip":"18573","location":"San Alberto"},
    {"afip":"18612","location":"San Bernardo"},
    {"afip":"18625","location":"San Carlos"},
    {"afip":"18635","location":"San Cristobal"},
    {"afip":"18642","location":"San Eduardo"},
    {"afip":"18648","location":"San Eugenio"},
    {"afip":"18684","location":"San Geronimo"},
    {"afip":"18688","location":"San Gregorio"},
    {"afip":"18691","location":"San Guillermo"},
    {"afip":"18725","location":"San Javier"},
    {"afip":"18734","location":"San Joaquin"},
    {"afip":"18740","location":"San Jorge"},
    {"afip":"18755","location":"San Jose"},
    {"afip":"18780","location":"San Justo"},
    {"afip":"18794","location":"San Lorenzo"},
    {"afip":"18810","location":"San Marcelo"},
    {"afip":"18823","location":"San Martin De Tours"},
    {"afip":"18836","location":"San Miguel"},
    {"afip":"18865","location":"San Pedro"},
    {"afip":"18869","location":"San Pedro Norte"},
    {"afip":"18897","location":"San Roque"},
    {"afip":"18909","location":"San Sebastian"},
    {"afip":"18916","location":"San Urbano"},
    {"afip":"18926","location":"San Vicente"},
    {"afip":"18939","location":"Santa Ana"},
    {"afip":"18966","location":"Santa Clara"},
    {"afip":"18983","location":"Santa Emilia"},
    {"afip":"18984","location":"Santa Felicia"},
    {"afip":"19001","location":"Santa Isabel"},
    {"afip":"19012","location":"Santa Lucia"},
    {"afip":"19029","location":"Santa Maria"},
    {"afip":"19067","location":"Santa Teresa"},
    {"afip":"19085","location":"Santo Domingo"},
    {"afip":"19093","location":"Santo Tome"},
    {"afip":"19102","location":"Sargento Cabral"},
    {"afip":"19106","location":"Sarmiento"},
    {"afip":"19149","location":"Soledad"},
    {"afip":"19168","location":"Tacural"},
    {"afip":"19180","location":"Talleres"},
    {"afip":"19184","location":"Tartagal"},
    {"afip":"19192","location":"Toba"},
    {"afip":"19220","location":"Tres Bocas"},
    {"afip":"19234","location":"Tres Esquinas"},
    {"afip":"19251","location":"Tres Pozos"},
    {"afip":"19295","location":"Villa Don Bosco"},
    {"afip":"19309","location":"Villa Lujan"},
    {"afip":"19310","location":"Villa Margarita"},
    {"afip":"19330","location":"Villa San Jose"},
    {"afip":"19370","location":"Zavalla"},
    {"afip":"20358","location":"La Celia"},
    {"afip":"20359","location":"La Vanguardia"},
    {"afip":"20360","location":"San Vicente"},
    {"afip":"20361","location":"Los Leones"},
    {"afip":"20362","location":"San Guillermo"},
    {"afip":"20363","location":"Hugentobler"},
    {"afip":"20364","location":"San Guillermo"},
    {"afip":"20365","location":"Larrechea"},
    {"afip":"20369","location":"Santa Teresa"},
    {"afip":"21720","location":"Barrio Monteverde"},
    {"afip":"21888","location":"Barrio Alto Alberdi"},
    {"afip":"22114","location":"San Francisco Del Monte"},
    {"afip":"22125","location":"Calafate"},
    {"afip":"22126","location":"Berabevu"},
    {"afip":"22127","location":"Arrocera Barrancas"},
    {"afip":"22128","location":"Oroqo"},
    {"afip":"22129","location":"Ramona"},
    {"afip":"22130","location":"San Antonio"},
    {"afip":"22131","location":"Villa San Jorge"},
    {"afip":"22132","location":"Angelica Vieja"},
    {"afip":"22133","location":"Kilometro 85"},
    {"afip":"22134","location":"Humberto Primo"},
    {"afip":"22135","location":"Colonia Raquel"},
    {"afip":"22136","location":"Nueva Lehmann"},
    {"afip":"22137","location":"Colonia Malmann"},
    {"afip":"22138","location":"Monte Oscuridad"},
    {"afip":"22139","location":"Josefina"},
    {"afip":"22140","location":"Kilometro 501"},
    {"afip":"22141","location":"Kilometro 483"},
    {"afip":"22142","location":"Colonia Iturraspe"},
    {"afip":"22143","location":"Alcorta"},
    {"afip":"22144","location":"Estacion Rams"},
    {"afip":"22145","location":"Los Tabanos"},
    {"afip":"22146","location":"Ogilvie"},
    {"afip":"22147","location":"Nicanor Molinas"},
    {"afip":"22148","location":"Victor Manuel Ii"},
    {"afip":"22149","location":"Destacamento Aeronautico Militar"},
    {"afip":"22150","location":"Doctor Barros Pozo"},
    {"afip":"22151","location":"Ingeniero Chanordie"},
    {"afip":"22152","location":"Barrio Fisherton"},
    {"afip":"22153","location":"Barrio General Jose De San Martin"},
    {"afip":"22154","location":"Rosario Norte"},
    {"afip":"22155","location":"Froilan Palacios"},
    {"afip":"22156","location":"Lucio V. Lopez"},
    {"afip":"22157","location":"Puerto General San Martin"},
    {"afip":"22158","location":"Timbues"},
    {"afip":"22159","location":"Morante"},
    {"afip":"22160","location":"Colonia Macias"},
    {"afip":"22161","location":"Saladero M. Cabal"},
    {"afip":"22162","location":"Santa Rosa"},
    {"afip":"22163","location":"Matilde"},
    {"afip":"22164","location":"Rincon De Avilarincon De Avila"},
    {"afip":"22165","location":"Soutomayor"},
    {"afip":"22166","location":"Jacinto Arauz"},
    {"afip":"22167","location":"Ester"},
    {"afip":"22168","location":"Colonia Esther"},
    {"afip":"22169","location":"Avichucho"},
    {"afip":"22170","location":"Fives Hille"},
    {"afip":"22171","location":"Kilometro 187"},
    {"afip":"22172","location":"Colonia Pujato"},
    {"afip":"22173","location":"Chatcbriano"},
    {"afip":"22174","location":"San Francisco"},
    {"afip":"22175","location":"Balneario Melincue"},
    {"afip":"22176","location":"Aaron Castellanos"},
    {"afip":"22177","location":"Rosario Sud"},
    {"afip":"22178","location":"Juan B. Molina"},
    {"afip":"22179","location":"Coronel Rodolfo S. Dominguez"},
    {"afip":"22180","location":"Pueblo Colazo"},
    {"afip":"22181","location":"Arroyo Del Medio"},
    {"afip":"22182","location":"Alcorta"},
    {"afip":"22183","location":"Benard"},
    {"afip":"22184","location":"Erasto"},
    {"afip":"22185","location":"Villa Diego"},
    {"afip":"22190","location":"Maria"},
    {"afip":"22196","location":"Cañada"}
    ,
    {"afip":"22210","location":"San Martin"},
    {"afip":"22220","location":"Progreso De Jume"},
    {"afip":"22250","location":"Piñero"}
    ,
    {"afip":"22257","location":"Pueblo San Bernardo (San Justo)"},
    {"afip":"22258","location":"Colonia Sol De Mayo(San Justo)"},
    {"afip":"22261","location":"San Mariano (Las Colonias)"}
    ]