export const PLACE_OF_DELIVERY_CONST = {
    ROSARIO_NORTE: 1,
    ROSARIO_SUR: 2,
    ROSARIO: 3,
    UP_RIVER: 4,
    ROSARIO_NORTE_Y_ROSARIO: 5,
    ROSARIO_SUR_Y_ROSARIO: 6,
    ROSARIO_NORTE_Y_SUR: 7,
    PUERTOS_BUENOS_AIRES: 8,
    PUERTOS_ENTRE_RIOS: 9,
    PLANTAS_GRASSI: 10,
    PLANTAS_INTERIOR: 11,
}

export const PLACE_OF_DELIVERY_IBM_CONST = {
    BARADERO:152,
    CHACABUCO:164,
    GENERAL_LAGOS: 359,
    TIMBUES: 322,
    RIO_CUARTO: 32,
    BAHIA_BLANCA: 1,
    NECOCHEA: 132,
    NAVARRO: 362,
    AVELLANEDA: 34,
    CHABAS: 21,
    SAN_MARTIN:12,		
    JUNIN: 41,
    LIMA:426,
    GUARDIA_ESCOLTA:173,	
    PUNTA_ALVEAR:215,
    COMPLEJO_APG:501,	
    DIAMANTE: 4,
    GENERAL_PICO:349,
    AMERICA:482,
    LA_CARLOTA:	178,
    QUEQUEN: 0,
    RECONQUISTA:48,	
    VILLA_CONSTITUCION:8,
    CASCALLARES: 777,
    ARRECIFES: 75,
    ORTIZ_BASUALDO:98,
    DIAZ_STA_FE:197,
    TERMINAL_DE_EMBARQUE: 0,
    RENOVA_TIMBUES: 1004,
    DAIREAUX: 314,
    NECOCHEA_QQN:0,
    GENERAL_VILLEGAS:491,
    RAMALLO:11,
    AVIA_TERAI: 612,
    TANCACHA: 80,
    BANDERA: 512,
    PINEDO: 585,
    GENERAL_PAZ: 441,
    D_GALLO:275,	
    LABORDE:64,
    SAN_JERONIMO_SUR:208,
    ROSARIO: 5,	
    ARROYO_SECO:350,
    SAN_LORENZO:6,
    UP_RIVER:90001,
    TERM_LAS_PALMAS:551,
    TERMINAL_Pto_DEL_GUAZU:945,
    ZARATE:530
}