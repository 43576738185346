import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcumuladorComponent } from './acumulador.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';



@NgModule({
  declarations: [
    AcumuladorComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule
  ], exports: [
    AcumuladorComponent
  ]
})
export class AcumuladorModule { }
