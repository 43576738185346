<div class="field">
    <div>
        <div class="form-field" (click)="changeShowOptions()" 
        [ngClass]="{'border-green': getIsInputCompleted(), 'is-incomplete': isIncomplete || isInvalid}">
            <span class="title-field  {{identifyClassName}}"> 
                <input #inputName class="{{identifyClassName}} no-background-border"
                (focus)="focusField()" (blur)="unfocusField()"
                (input)="onInputChange($event)"
                placeholder="{{placeHolder}}" 
                (keydown)="listenerKeyPress($event, inputName)"
                [value]="getValue()"/>

                <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>

        <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>
    
        <div class="options" *ngIf="showOptions">
            <span *ngFor="let element of array | filterByObject: search:OBJECT:identificatorSearch; index as i" [ngClass]="{'selected': OBJECT[element]==value}"
                (click)="setElement(element, i)">
                {{ OBJECT[element]? OBJECT[element].nombre ?? OBJECT[element] :  element }}
            </span>
        </div>
    </div>
</div>