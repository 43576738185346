
<div class="background-sin-color">
    <svg class="svg-22 hover-violet hover-bg-white" xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
        <rect y="0.5" width="22" height="22" rx="11" fill="#EAF2FA"/>
        <path d="M10.7465 17.5284H14.5966C14.993 17.5284 15.3147 17.2067 15.3147 16.8103V7.4716V6.71815C15.3147 6.32174 14.993 6 14.5966 6H10.7465H9.46635V6.23757C9.46905 6.26201 9.47042 6.2878 9.47042 6.3136V7.87886V8.2807C9.47042 8.83866 9.01699 9.29208 8.45903 9.29208H8.0572H6.49193C6.47156 9.29208 6.44984 9.29073 6.42948 9.28937H6.17969V16.8103C6.17969 17.2067 6.50143 17.5284 6.89784 17.5284H7.68522H10.7465ZM10.8918 15.0875H8.38165C8.19431 15.0875 8.04226 14.9355 8.04226 14.7481C8.04226 14.5608 8.19431 14.4087 8.38165 14.4087H10.8931C11.0805 14.4087 11.2325 14.5608 11.2325 14.7481C11.2325 14.9355 11.0791 15.0875 10.8918 15.0875ZM13.4033 13.3553H8.38165C8.19431 13.3553 8.04226 13.2032 8.04226 13.0159C8.04226 12.8285 8.19431 12.6765 8.38165 12.6765H13.4033C13.5906 12.6765 13.7427 12.8285 13.7427 13.0159C13.7427 13.2032 13.5906 13.3553 13.4033 13.3553ZM8.38165 10.837H13.4033C13.5906 10.837 13.7427 10.989 13.7427 11.1764C13.7427 11.3637 13.5906 11.5158 13.4033 11.5158H8.38165C8.19431 11.5158 8.04226 11.3637 8.04226 11.1764C8.04226 10.989 8.19431 10.837 8.38165 10.837Z" fill="#ADBCCE"/>
        <path d="M6.49097 8.8847H8.45808C8.45943 8.8847 8.46215 8.8847 8.46351 8.8847C8.79204 8.88198 9.05812 8.6159 9.06083 8.28737C9.06083 8.28601 9.06083 8.2833 9.06083 8.28194V6.31348C9.06083 6.13157 8.9115 6.01074 8.75675 6.01074C8.68209 6.01074 8.60742 6.03789 8.54497 6.10034L6.27647 8.36882C6.08642 8.55888 6.22081 8.8847 6.49097 8.8847Z" fill="#ADBCCE"/>
    </svg>
    
    <div class="tooltip row-center w-80">
        <span>Orden manual</span>
    </div>
</div>