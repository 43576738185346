import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountComponent } from './amount.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { SelectInputModule } from 'src/app/components/inputs/select/select.module';



@NgModule({
  declarations: [
    AmountComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    NumberInputModule,
    SelectInputModule
  ], exports: [
    AmountComponent
  ]
})
export class AmountModule { }
