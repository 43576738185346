import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketPricesComponent } from './market-prices.component';
import { MarketPriceComponent } from './market-price/market-price.component';
import { RouterModule } from '@angular/router';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { PriceMarketCardModule } from 'src/app/components/cards/price-market/price-market.module';

@NgModule({
  declarations: [
    MarketPricesComponent,
    MarketPriceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ArrowSvgModule,
    PriceMarketCardModule
  ],
  exports:[
    MarketPricesComponent
  ]
})
export class MarketPricesModule { }