/* 
Important: Possibly in this file there are 
functions that are already included with JS 
but at the time of performing them I was unaware. 
:) :p
*/
export class ValidatorChecker {

  constructor() {}

  /**
   * Given a string, it returns a number within the range [0, 9000000].
   * If the string is empty or contains letters, it returns 0.
   * @param {string} number - The string to be checked.
   * @returns {number} A number within the range [0, 9000000].
   */
  public validatorNumber(number: string): number {
    return validateNumberInRange(number, 0, 9000000);
  }

  /**
   * Validates a percentage number and returns it within the range [0, 100].
   * If the number is less than 0, it returns 0.
   * If the number is greater than 100, it returns 100.
   * Otherwise, it returns the original number.
   * 
   * @param {number} number - The percentage number to be validated.
   * @returns {number} A percentage number within the range [0, 100].
   */
  public validatorPercentage(number: number): number {
    return validateNumberInRange(String(number), 0, 100);
  }

  /**
   * Removes spaces from a string.
   * 
   * @param {string} string - The string from which to remove spaces.
   * @returns {string} The input string without spaces.
   */
  public deleteSpaces(string: string): string {
    return string.replace(/\s/g, '');
  }

  /**
   * Finds the first non-numeric character in a string and returns it.
   * 
   * @param {string} string - The string to be checked.
   * @returns {string} The first non-numeric character found in the string, or an empty string if none is found.
   */
  public detectFirstNonNumber(string: string): string {
    for (let i = 0; i < string.length; i++) {
      if (string[i] === '-' || string[i] === '/') {
        return string[i];
      }
    }
    return '';
  }

  /**
   * Given a character code, return true if it belongs to a number code
   * @param {number} charCode - The character code to be checked.
   * @returns {boolean} True if the character code belongs to a number code, otherwise false.
   * */
  onlyNumbers(charCode:number){
    return charCode>=48 && charCode<=57
  }

  /**
   * Given a character code, return true if it belongs to a number code or a point or a comma
   * @param {number} charCode - The character code to be checked.
   * @returns {boolean} True if the character code belongs to a number code, a point or a comma, otherwise false.
   * */
  onlyNumbersAndPoint(charCode:number){
    return this.onlyNumbers(charCode) || charCode==46 || charCode==44
  }

  /**
   * Given a number, returns how many numbers there are after the comma (or point)
   * @param {number} number - The number to be checked.
   * @returns {number} The number of decimal places in the number.
   */
  getDecimalPlaces(number: number): number {
    const numberString = number.toString();
    const indexPoint = numberString.indexOf('.');
    return indexPoint === -1 ? 0 : numberString.length - indexPoint - 1;
  }

  /**
   * Given a character, it returns true if it is alphanumeric, otherwise false.
   * @param {number} charCode - The character code to be checked.
   * @returns {boolean} True if the character code is alphanumeric, otherwise false.
   * */
  isAlphanumeric(charCode:number){
    return charCode>=48 && charCode<=57 || charCode>=65 && charCode<=90 || charCode>=97 && charCode<=122
  }
}


/**
 * Counts how many times a character appears in a string.
 * @param {string} string - The string to be checked.
 * @param {string} char - The character to be counted.
 * @returns {number} The number of times the character appears in the string.
 */
export function quantCharInString(string: string, char: string): number {
  let count = 0;
  for (let i = 0; i < string.length; i++) {
    if (string[i] === char) {
      count++;
    }
  }
  return count;
}

/**
 * Given a bool returns true representing that the arrow is open
 * @param {boolean} bool - The boolean to be checked.
 * @returns {string} 'up' if the arrow is open, otherwise 'down'.
 */
export function getArrowDirectionByBool(bool: boolean) {
  return bool ? 'up' : 'down';
}

/**
 * Parse a number so that it appears with a comma.
 * @param {number} number - The number to be parsed.
 * @returns {string} The number with a comma.
 * */
export function parseFloatPoint(number:number){
  return number ? number.toString().replace('.',','):0;
}

/**
 * Parse a number to put the thousands and have the decimals appear with a comma
 * @param number - The number to be formatted.
 * @returns The formatted number as a string.
 */
export function formatNumber(number: number): string {
  return number.toLocaleString('es-ES', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true, // Esto debería activar el separador de miles
    minimumIntegerDigits: 1, // Esto evita que el número se trunque a un formato sin ceros a la izquierda
  });
}

/**
 * Formats a number as text with a specified number of decimals separated by a comma.
 * @param numberToFormat - The number to be formatted.
 * @param quantDecimals - The desired number of decimals.
 * @returns The formatted number as a string.
 */
export function formatNumberWithDecimals(numberToFormat: number, quantDecimals: number): string {
  // Check if the number is not a valid finite number
  if (!Number.isFinite(numberToFormat)) {
    throw new Error('Invalid number provided');
  }

  // Convert the number to a string with the desired number of decimals
  const formattedNumber = numberToFormat.toFixed(quantDecimals);

  // Separate the integer part and the decimal part
  const [integerPart, decimalPart] = formattedNumber.split('.');

  // If there is no decimal part, return the integer part with added zeros for decimals
  if (!decimalPart) {
    return `${integerPart},` + '0'.repeat(quantDecimals);
  }

  // Otherwise, return the formatted number with the comma separator
  return `${integerPart},${decimalPart}`;
}

/**
 * Validates a string and returns a number within the specified range.
 * If the string is empty or contains letters, it returns the default value.
 * @param {string} number - The string to be checked.
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {number} defaultValue - The default value to return if validation fails.
 * @returns {number} A number within the specified range.
 */
function validateNumberInRange(number: string, min: number, max: number, defaultValue: number = 0): number {
  if (!number || haveLetter(number)) {
    return defaultValue;
  }

  const value = Number(number);
  return Math.min(Math.max(value, min), max);
}

/**
 * Checks if a string contains any non-numeric characters.
 * 
 * @param {string} string - The string to be checked.
 * @returns {boolean} True if the string contains non-numeric characters, otherwise false.
 */
export function haveLetter(string: string): boolean {
  return /[^0-9.]/.test(string);
}