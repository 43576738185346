
<div class="dialog-content" mat-dialog-content>
  <div class="logo-container">
    <img class="logo confirm" *ngIf="isConfirm" alt="check-confirm" src="../../../../../../assets/images/extra/confirmed.svg" />
    <img class="logo denied" *ngIf="!isConfirm" alt="check-denied" src="../../../../../../assets/images/extra/denied.svg" />
  </div>
  <span class="hour">{{hour}}</span>
  <p class="message">{{mensaje}}</p>
  <p class="more-info">{{moreInfo}}</p>
</div>
<div class="actions" >
    <button mat-button mat-dialog-close class="button filling" (click)="sendOrderExchange()">Enviar datos de la contrapartida</button>
</div>
 