import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsPriceComponent } from './details.component';
import { DataModule } from './../data/data.module';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { CommissionModule } from 'src/app/components/cards/commission/commission.module';
import { InfoPriceModule } from '../info/info-price/info-price.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { InfoToFixModule } from '../info/info-to-fix/info-to-fix.module';

@NgModule({
  declarations: [
    DetailsPriceComponent,
  ],
  imports: [
    CommonModule,
    DataModule,
    MatSlideToggleModule,
    MatDialogModule,
    CommissionModule,
    InfoPriceModule,
    InfoToFixModule,
    ArrowSvgModule
  ],
  exports:[
    DetailsPriceComponent
  ]
})
export class DetailsPriceModule { }
