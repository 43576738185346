import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { OrderValidator } from 'src/app/shared/order';
import { OrderService } from 'src/app/shared/services/order.service';
import {
  setAccumulatedTonnageFailed,
  setAccumulatedTonnageSuccess,
  setAccumulatedTonnage,
} from '../actions/dashboard.action';
import { AccumTonsLocalStorage, AccumulatedTonage, HarvestTotals } from 'src/app/shared/interfaces/dashboard.interface';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { catchError, concatMap, take } from 'rxjs/operators';


@Injectable()
export class OrderEffects {
  constructor(private store:Store<appState>, private actions$: Actions, private orderSvc: OrderService, private orderV:OrderValidator) { }
  
  getAccumulatedTonnageEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setAccumulatedTonnage),
      concatMap((action) => {
        return this.store.select('dashboard').pipe(
          take(1),
          mergeMap((dashboard) => {
            return this.orderSvc.getAccumulatedTonnage(action.productName, action.harvest).pipe(
              map((accumulatedTonnage) => {
                try {
                  if (accumulatedTonnage) {
                    const date=new Date();
                    const newAccumulatedTonnage = this.getNewAccumulatedTonnage(action.productName, action.harvest, dashboard.accumulatedTonnage, accumulatedTonnage, action.wave, date);
                    return setAccumulatedTonnageSuccess({accumulatedTonnage:newAccumulatedTonnage, date:date })
                  } else {
                    throw new Error();
                  }
                } catch (error: any) {
                  const errorMessage = error.message;
                  return setAccumulatedTonnageFailed();
                }
              }),
              catchError((error) => {
                //Si el backend nos devuelve un error, lo capturamos y hacemos las acciones correspondientes
                const date=new Date();
                //const newAccumulatedTonnage = this.getNewAccumulatedTonnage(action.productName, action.harvest, dashboard.accumulatedTonnage, accumulatedTonnage, action.wave, date);
                //return setAccumulatedTonnageSuccess({accumulatedTonnage:newAccumulatedTonnage, date:date })
                return of(setAccumulatedTonnageFailed());
              })
            );
          })
        );
      }),
      concatMap((action) => of(action))
      
    );
  });

  setLocalStorage(name: string, data: any) {
    let almacenamiento: any[] = [];
    if (data) {
      almacenamiento = data;
    }
    localStorage.setItem(name, JSON.stringify(almacenamiento));
    return almacenamiento;
  }

  getNewAccumulatedTonnage(productName: number, harvestStr: string, previusAccumulatedTonnage: AccumulatedTonage[], accumulatedTonnage:HarvestTotals, wave:number, date:Date){
    let arrayToReturn:AccumulatedTonage[] = JSON.parse(JSON.stringify(previusAccumulatedTonnage))

    const index = arrayToReturn.findIndex((accumulatedTonnage) => accumulatedTonnage.productName == productName);
    if (index != -1) {
      const indexHarvest = arrayToReturn[index].harvestsList.findIndex((harvest) => harvest.fecha == harvestStr);
      if (indexHarvest != -1) {
        arrayToReturn[index].harvestsList[indexHarvest] = {
          ...accumulatedTonnage,
          fecha: harvestStr
        }
        arrayToReturn[index].totalTons = arrayToReturn[index].harvestsList.reduce((a, b) => a + b.totalTons, 0);
      } else {
        arrayToReturn[index].harvestsList.push({
          ...accumulatedTonnage,
          fecha:harvestStr
        });
        arrayToReturn[index].totalTons = arrayToReturn[index].harvestsList.reduce((a, b) => a + b.totalTons, 0);
      }
    }  else {
      const newAccumulatedTonnage:AccumulatedTonage={
        harvestsList: [{
          ...accumulatedTonnage,
          fecha: harvestStr
        }],
        productName: productName,
        totalTons: accumulatedTonnage.totalTons
      }
      arrayToReturn.push(newAccumulatedTonnage);
    }

    this.saveInLocalStorage(arrayToReturn, wave,date);

    return arrayToReturn
  }

  saveInLocalStorage(accumulatedTonnage:AccumulatedTonage[], wave:number, date:Date){
    const acTnLocStor:AccumTonsLocalStorage={
      dataDashboard:accumulatedTonnage,
      date: date,
      wave: wave
    }
    this.setLocalStorage(localStorageNames.accumulatedTonnage, acTnLocStor);
  }

}
