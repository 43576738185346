import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobilePriceComponent } from './mobile-price.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';

@NgModule({
  declarations: [
    MobilePriceComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    ArrowSvgModule
  ],exports:[
    MobilePriceComponent
  ]
})
export class TableMobilePriceModule { }