import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { buyerType, conditionType } from 'src/app/shared/const/options';
import { observations, priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { buyers } from 'src/app/shared/dict/buyers';
import { CONDITIONS_TYPE_TO_FIX } from 'src/app/shared/dict/condition-and-indicators';
import { priceState, puertos, quality, typeCoin } from 'src/app/shared/dict/dict';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { Price, PriceString } from 'src/app/shared/interfaces/price.interface';
import { ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { RowHead } from '../../row-head';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { setShowDeletePrice } from 'src/app/redux/actions/options.action';

@Component({
  selector: 'tr[row-price-head]',
  templateUrl: './row-price-head.component.html',
  styleUrls: ['./row-price-head.component.scss']
})
export class RowPriceHeadComponent extends RowHead implements OnInit {
  @Input() viewSelected!:ViewPrice;
  @Input() buyerType:string='';
  @Input() conditionType:string='';
  @Input() pricesSelected:Price[]=[];

  buyerTypes=buyerType;
  CONDITION_TYPES=conditionType;

  STATUS: any = [];
  BUSINESS_TYPE: any = [];
  PLACE_OF_DELIVERY: any = [];
  QUALITY: any = [];
  TYPE_COIN: any = [];
  WAY_PAY: any = [];
  PRODUCTS: any = [];
  OBSERVATIONS: any = [];
  HARVEST: any = [];
  BUYERS: any = [];

  constructor(private dateC:DateChecker, private store:Store<appState>){
    super();
  }

  ngOnInit(): void {
    this.setArrays();
  }

  deletePrices(){
    this.store.dispatch(setShowDeletePrice({isShow:true, idPrice:''}));
  }

  setArrays(){
    this.STATUS = priceState;
    this.BUSINESS_TYPE = typeBusiness;
    this.QUALITY = quality;
    this.PLACE_OF_DELIVERY = puertos;
    this.TYPE_COIN = typeCoin;
    this.WAY_PAY = wayPay;
    this.PRODUCTS = PRODUCT_NAMES;
    this.OBSERVATIONS = [ observations.featuredPrice, observations.gravanz, observations.comment];
    this.BUYERS = buyers;
    this.HARVEST = this.dateC.getHarvest();
  }
}