<div class="card">
    <div class="header">
        <span class="title">Tasa de diferimiento</span>
        <info-svg class="info" (click)="showInformation(!isShowInformation)"></info-svg>
    </div>

    <app-info-deferral *ngIf="isShowInformation" class="info-deferral" (hidden)="showInformation(false)"></app-info-deferral>

    <p class="description">Ingresar cantidad de días, monto y porcentaje de tasa</p>

    <div class="valuations">
        <div class="valuation" *ngFor="let valuation of valuations; index as i">
            <div class="tasa">

                <div class="field">
                    <span class="field-title tasa-header">
                        <span class="tasa-text field-text">Tasa de diferimiento</span>
                        <span class="add field-text" (click)="addDeferral()">+ Agregar</span>
                    </span>
                    <div>
                        <number-input [initialPlaceHolder]="'Ingresar porcetaje'"
                        [value]="valuation.tasa" (valueOut)="setElement($event, i, 'tasa')"></number-input>
                    </div>

                </div>
            </div>

            <div class="days-amount">

                <div class="field">
                    <div class="days">
                        <span class="field-text">Días</span>
                        <div>
                            <number-input [initialPlaceHolder]="'Ingresar días'" [value]="valuation.days"
                            (valueOut)="setElement($event, i, 'days')"></number-input>
                        </div>
                    </div>
                </div>


                <div class="field">
                    <div class="dolars">
                        <span class="field-text">Interes</span>
                        <div>
                            <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="valuation.amount"
                            (valueOut)="setElement($event, i, 'amount')"></number-input>
                        </div>
                    </div>
                </div>

            </div>

            <div class="line grey"></div>
        </div>
    </div>

    <div class="button">
        <button class="apply" [ngClass]="{'disabled': getIsButtonDisabled()}"
            [disabled]="getIsButtonDisabled()" (click)="save()">Aplicar</button>
    </div>
</div>