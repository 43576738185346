<section class="body">

    <div class="header-section">
        <div class="downs">
            <app-buttons-condition-type></app-buttons-condition-type>
            <app-views-section-exchange class="views"></app-views-section-exchange>
        </div>
      
        <div class="call-to-actions">
          <div class="buttons-to-actions">
            <app-search-input class="search"></app-search-input>
                    
            <button class="add filled-button generate exchange not-visible-in-mobile" (click)="goToGenerateExchange()">Alta de contrapartida</button>
            <button class="add filled-button generate exchange visible-in-mobile" (click)="goToGenerateExchange()">+</button>
          </div>
      
            <div class="description">                 
                <span class="quant">Cantidad de contrapartidas: <span class="stroke">
                    {{(ordersAll| filterByRepresentation: isRepresentation:user | filterByOrder: orderFilter | 
                        filterOrder:search | sorOrderExchange).length }}</span>
                </span>
                
                <span class="quant">Cantidad de toneladas: 
                <span class="stroke">{{getQuantOfTons(ordersAll | filterOrderByConditionType: conditionTypeSelected:conditionFilters | filterByRepresentation: isRepresentation:user | filterByOrder: orderFilter | 
                    filterOrder:search)}}</span></span>
            </div>
        </div>
    </div>

    <app-exchange-table class="table"></app-exchange-table>

    <!-- Slide Cards -->
    <div [ngClass]="{'background opaque': isShowDetails || showExchangedConfirmed || showAddExchange}" (click)="closeDetails()"></div>
    <app-add-exchange class="slide-card slide-card-visible" *ngIf="showAddExchange"></app-add-exchange>
    <app-exchange-details  *ngIf="isShowDetails" class="slide-card slide-card-visible"></app-exchange-details>
    
    <dialog-confirmed-exchanged class="dialog center" *ngIf="showExchangedConfirmed"></dialog-confirmed-exchanged>
    
    <dialog-contract-create *ngIf="typeModal==3"></dialog-contract-create>
    <dialog-contract-failed *ngIf="typeModal==2"></dialog-contract-failed>
    <dialog-eraser-create *ngIf="typeModal==1"></dialog-eraser-create>
</section>
