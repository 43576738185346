import {HostListener, Component, OnInit, OnDestroy,ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { hiddenMenu, setSizeView, showMenu } from 'src/app/redux/actions/options.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { logout } from 'src/app/redux/actions/session.action';
import { Router } from '@angular/router';
import { area, workUnit } from 'src/app/shared/dict/user';
import { Navegation, NAVEGATION } from 'src/app/shared/const/navegation';
import { Subscription } from 'rxjs';
import { areas } from 'src/app/shared/const/user.const';
import { isProduction } from 'src/app/shared/const/options';

const VIEW_OF_MOBILE=510;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('show', [
      state('true', style({
        transform: 'translateX(0)',
        opacity: 100,
        zIndex: 50000,
      })),
      state('false', style({
        transform: 'translateX(-100%)',
        opacity: 0,
      })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush

})

export class MenuComponent implements OnInit, OnDestroy {
  // USER
  public user!: User;
  public isComercial:boolean=false;

  //CONST
  public location!:string;
  public NAVEGATION:Navegation;
  public areas: any;
  public unidades: any;
  
  //SIZE MENU
  public showMenu!: boolean;
  public sizeView!: number;

  //Detect click
  public isClicked=false;

  private userSubscription!:Subscription;
  private showMenuSubscription!: Subscription;
  private sizeSubscription!:Subscription;
  private locationSubscription!:Subscription;

  constructor(private store: Store<appState>,
    private router: Router) {
    this.setSubscriptions();
    this.store.dispatch(setSizeView({size: window.innerWidth}));

    this.NAVEGATION=NAVEGATION;
    this.areas = area;
    this.unidades = workUnit;
    this.menuIsVisible();
  }

  ngOnInit(): void {
    // Pasar la función con nombre checkMenuListener al evento de redimensionamiento
    window.addEventListener("resize", this.checkMenuListener);
  }

  ngOnDestroy(): void {
    this.setUnsubscriptions();

    // Pasar la misma función checkMenuListener al removeEventListener
    window.removeEventListener("resize", this.checkMenuListener);
  }


  checkMenuListener = (event: Event) => {
    // Obtener el tamaño de la ventana del evento
    const size = (event.target as Window).innerWidth;

    // Lógica para actualizar el estado y la visibilidad del menú
    this.store.dispatch(setSizeView({ size }));
    this.menuIsVisible();
  }

  setUnsubscriptions(){
    this.userSubscription?.unsubscribe();
    this.showMenuSubscription?.unsubscribe();
    this.sizeSubscription?.unsubscribe();
    this.locationSubscription?.unsubscribe();
  }

  setSubscriptions() {
    this.userSubscription = this.store.select('usuario').subscribe(usuario => {
      this.user = usuario
      this.isComercial=usuario.area==areas.comercial;
    });

    this.showMenuSubscription = this.store.select('options', 'showMenu').subscribe(showMenu => {
      this.showMenu = showMenu;
    });

    this.sizeSubscription = this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.sizeView = sizeView;
    });

    this.locationSubscription = this.store.select('options', 'location').subscribe(location => {
      this.location=location;
    });
  }

  menuIsVisible(){
    this.isMobile()?this.closeMenu():this.openMenu();
  }
  
  isMobile() {
    return this.sizeView < VIEW_OF_MOBILE;
  }

  closeMenu() {
    this.store.dispatch(hiddenMenu());
  }

  openMenu() {
    this.store.dispatch(showMenu());
  }

  goTo(location:string){
    const url = "/" + location;
    this.router.navigate([url]);
  }

  logout() {
    this.store.dispatch(logout());
    this.router.navigate(['/'+NAVEGATION.ACCESS])
  }
  
  menuClicked(event:any){  
    event.stopPropagation();
    this.isClicked = true;
  }

  isDesarrollo(){
    return !isProduction();
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.isClicked=false;
  }
}
