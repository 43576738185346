import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RebajaComponent } from './rebaja.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { RadioButtonInputModule } from 'src/app/components/inputs/radio-buttons/radio-buttons.module';



@NgModule({
  declarations: [
    RebajaComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    NumberInputModule,
    RadioButtonInputModule,
  ], exports: [
    RebajaComponent
  ]
})
export class RebajaModule { }
