<div class="card" [ngClass]="{'card-275': ['price', 'wayPay', 'placeOfDelivery', 'typeBusiness'].includes(identificatorString),
 'card-short': identificatorString=='reduction'}">
 <div class="title-and-selects row-between">
    <span class="title">{{title}}</span>

    <div class="selects" *ngIf="!isComercial && identificatorString!='hour'">
        
        <span class="option edit" [ngClass]="{'active': !isFilter}" (click)="changeTo('edit')">
            Editar <edit-svg class="logo" [active]="!isFilter"></edit-svg>
        </span>
        <span class="option filter" [ngClass]="{'active': isFilter}" (click)="changeTo('filter')">
            Filtrar <filter-svg class="logo" [active]="isFilter"></filter-svg>
        </span>
    </div>
</div>

 <div class="width-100" [ngSwitch]="identificatorString">
    <div *ngIf="isSearchOrNumber && array.length>0" class="body-card">
        <!-- Array with filter-->
        <div class="select-all" *ngIf="isFilter">
            <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span></div>
        <input style="margin-top:4px" class="input input-search" type="text" placeholder="Buscar" [(ngModel)]="search" value="{{search}}">
        <ul class="list">
            <li class="row-between" *ngFor="let element of array | filterOptions:search; index as i" (click)="addOrDeleteElement(element)">
                <span>{{element.name? element.name: element}}</span>
                <input class="form-check-input" type="{{getTypeForm()}}" [ngClass]="{'filter': isFilter, 'edit': !isFilter}" [checked]="selects.indexOf(element.name? element.name: element) != -1"
                    value="{{element.name? element.name: element}}">
            </li>
        </ul>
    </div>

    <div *ngIf="!isSearchOrNumber && array.length>0 && identificatorString!='wayPay'" class="body-card">
        
        <div class="select-all" *ngIf="isFilter">
            <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span></div>
        <!-- Only array (few elements)-->
        <ul class="list">
            <li class="row-between" *ngFor="let element of array; index as i" (click)="addOrDeleteElement(element)">
                <span>{{element}}</span>
                <input class="form-check-input" type="{{getTypeForm()}}" value="{{element}}"  [ngClass]="{'filter': isFilter, 'edit': !isFilter}"
                    [checked]="selects.indexOf(element) != -1">
            </li>
        </ul>
    </div>
    
    <div  *ngSwitchCase="'price'" class="body-card">
            <mat-radio-group class="radios-buttons"  [ngClass]="{'price-not-filter': !isFilter}">
                <mat-radio-button class="radio-button" value="ARS"
                (change)="setPrice($event, 'typeCoin')" [checked]="valueToSet.typeCoin== 'ARS'">Pesos
                </mat-radio-button>
                <mat-radio-button class="radio-button" value="USD" (change)="setPrice($event, 'typeCoin')" [checked]="valueToSet.typeCoin=='USD'">Dólares
                </mat-radio-button>
                <mat-radio-button *ngIf="isFilter" class="radio-button" value="ALL" [checked]="valueToSet.typeCoin=='ALL'"
                (change)="setPrice($event, 'typeCoin')">Ámbos
                </mat-radio-button>
            </mat-radio-group>

        <p class="text" *ngIf="isFilter">Ingresa el rango de precios que deseas aplicar</p>
        <p class="text" *ngIf="!isFilter">Seleccionar una moneda y un monto para aumentar o disminuir el precio, a las condiciones seleccionadas. </p>
        

        <div class="prices" *ngIf="isFilter else notFiler">
            <number-input class="half-input" [initialPlaceHolder]="'Desde'" (valueOut)="setPrice($event, 'start')"></number-input>
            <number-input class="half-input" [initialPlaceHolder]="'Hasta'" (valueOut)="setPrice($event, 'end')"></number-input>
        </div>
        <ng-template #notFiler class="prices">
            <number-input [initialPlaceHolder]="'Ingrese una cantidad'" [value]="valueToSet.value" [min]="-1000000" (valueOut)="setPrice($event, 'value')"></number-input>
        </ng-template>
    </div>

    <div *ngSwitchCase="'deliveryPeriod'" class="body-card">
        <span class="delete-date" (click)="deleteDateRangeSelected()">Borrar fecha seleccionada</span>
        <div class="width-100">
            <app-date-calendar-inline [datesRangesStruct]="{startDate: null, endDate:null}" 
            (valueOut)="setValueToSetDate($event)" [isRange]="true"></app-date-calendar-inline>
        </div>
    </div>

    <div *ngSwitchCase="'pesificacion'" class="body-card">
        <span class="delete-date" (click)="deleteDateRangeSelected()">Borrar fecha seleccionada</span>
        <div class="width-100">
            <app-date-calendar-inline [datesRangesStruct]="{startDate: null, endDate:null}" 
            (valueOut)="setValueToSetDate($event)" [isRange]="true"></app-date-calendar-inline>
        </div>
    </div>

    <div *ngSwitchCase="'wayPay'" class="body-card">
        <div class="width-100" *ngIf="identificatorStringAux=='expiration' else wayPay" >
            <span class="delete-date" (click)="deleteDateSelected()">Borrar fecha seleccionada</span>
            <div style="width:85%">
                <app-date-calendar-inline 
                (valueOut)="setValueToSet($event)"></app-date-calendar-inline>
            </div>
            <div class="row-left gap-8" *ngIf="!isFilter">
                <input class="pointer" type="checkbox" [checked]="isFixExpiration" (click)="setFixExpiration()">
                <span class="pointer" (click)="setFixExpiration()">Anclar vencimiento</span>
            </div>
        </div>
        <ng-template #wayPay>
            <div class="select-all" *ngIf="isFilter">
                <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span>
            </div>
            <!-- Only array (few elements)-->
            <ul class="list">
                <li class="column-left" *ngFor="let element of array; index as i" (click)="setWayPay(element, 'wayPayName')"
                [ngClass]="{'display-none': showWayPay(element)}">
                    <div class="width-100 row-between">
                        <span>{{element}}</span>
                        <input class="form-check-input" type="{{getTypeForm()}}" value="{{element}}"  [ngClass]="{'filter': isFilter, 'edit': !isFilter}"
                        [checked]="selects.indexOf(element) != -1">
                    </div>

                    <div class="width-100  way-pay-container" *ngIf="!isFilter && selects.indexOf(element)!=-1">
                        <div class="radios-buttons way-pay">
                            <input type="radio" class="radio-button" [value]="1"
                            (change)="setWayPay(1, 'isBusinessDays')" [checked]="valueToSet.isBusinessDays==1">
                            <span class="label">Días habiles</span>
                            
                            <input type="radio" class="radio-button" [value]="-1"
                            (change)="setWayPay(-1, 'isBusinessDays')" [checked]="valueToSet.isBusinessDays==-1">
                            <span class="label">Días corridos</span>
                        </div>

                        <span class="label">Porcentaje</span>
                        <number-input style="margin-bottom:4px" [initialPlaceHolder]="'Ingrese un porcentaje'"  (valueOut)="setWayPay($event, 'percentage')"></number-input>
                        
                        <span class="label">Cantidad de días</span>
                        <number-input [initialPlaceHolder]="'Ingrese una cantidad'" [isWithPoint]="false" (valueOut)="setWayPay($event, 'days')"></number-input>
                    </div>
                </li>
            </ul>
        </ng-template>
    </div>

    <div *ngSwitchCase="'hour'" class="body-card">
        <p class="text">Buscar la hora de realización </p>
        <number-input [initialPlaceHolder]="'Ingresa hora'"  (valueOut)="setValueToSet($event)"></number-input>
    </div>

    <div *ngSwitchCase="'priceId'" class="body-card">
        <p class="text">Buscar el ID de precio</p>
        <number-input [initialPlaceHolder]="'Ingresa un ID'"  (valueOut)="setValueToSet($event)"></number-input>
    </div>
 </div>

    <button (click)="applyChanges()"  [ngClass]="{'disabled': getIsDisabledButton()}" [disabled]="getIsDisabledButton()">Aplicar</button>
</div>