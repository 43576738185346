import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeDolarComponent } from './type-dolar.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [
    TypeDolarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule
  ], exports:[
    TypeDolarComponent
  ]
})
export class TypeDolarModule { }
