import { Component, Input, Output,EventEmitter, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { getMinHeight } from 'src/app/pages/order-list/body-order-list/order-table/order-table.component';
import { orderOriginConst, orderStatusConst } from 'src/app/shared/const/orders';
import { translatePricesField } from 'src/app/shared/const/prices.const';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { Mod } from 'src/app/shared/interfaces/price.interface';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { OrderValidator } from 'src/app/shared/order';
import { PriceValidator } from 'src/app/shared/price';
import { OPTION, OptionMenu } from '../../options/options.component';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { areas } from 'src/app/shared/const/user.const';
import { conditionType } from 'src/app/shared/const/options';
import { getDaysWayPayText, getPercentageWayPay } from 'src/app/shared/way-pay';
import { BUSS_PART_YES_OR_NOT } from 'src/app/shared/dict/business.particularities';
import { separateBussPart } from 'src/app/shared/business-particularities';

@Component({
  selector: 'tr[app-row]',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowComponent implements OnInit, OnDestroy {
  @Input() order!:Order; //Orden a mostrar
  @Input() viewSelected!:ViewOrder; //Vista seleccionada
  @Input() optionsMenu:OptionMenu[]=[]; //Opciones del menu
  public showMenuOptions:boolean=false; //Mostrar menu de opciones
  @Input() isHaveIndicator:boolean=false; //Tiene indicadores
  @Input() directionArrow="down"; //Direccion de la flecha
  @Input() isDisabled:boolean=false; //Para saber si es una fila deshabilitada
  @Input() isReturned:boolean=false; //Para saber si es una fila devuelta
  @Input() showCheckbox:boolean=false; //Para saber si se muestra el checkbox
  @Input() isChecked:boolean=false; //Para saber si esta seleccionado
  @Input() isControlPanel:boolean=false; //Para saber si estamos en el panel de control
  @Input() showActionsCP:boolean=false; //Para saber si se muestra el boton de editar
  @Input() conditionTypeSelected:string=""; //Para saber el tipo de condicion de A FIJAR
  @Input() showBuyer:boolean=true; //Para saber si se muestra la columna de comprador o no.
  CONDITION_TYPE=conditionType;

  @Output() optionMenu= new EventEmitter<{event:OptionMenu, order:Order}>();
  @Output() arrow= new EventEmitter<{order:Order, event:any}>();
  
  public ORDER_ORIGIN_CONST=orderOriginConst;

  allBussPart:string[]=[];
  bussPartWithData:string[]=[];
  bussPartYesOrNot:string[]=[]

  user$!:Subscription;
  isComercial!:boolean;
  constructor(private store:Store<appState>, private orderV:OrderValidator, private priceV:PriceValidator){}

  ngOnInit(): void {
    this.user$=this.store.select('usuario').subscribe(user=>{
      this.isComercial=user.area==areas.comercial;
    });

    this.allBussPart=this.getBusinessParticularities(this.order?.price?.businessParticularities)
    const bussParts=separateBussPart(this.allBussPart, BUSS_PART_YES_OR_NOT)
    this.bussPartWithData=bussParts.withData;
    this.bussPartYesOrNot=bussParts.yesOrNo;
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  showOrderDetail(order:any){
    this.selectOptionMenu({ name: 'Detalles', action: OPTION.DETAILS }, order);
  }

  openOptions(){
    this.showMenuOptions=!this.showMenuOptions;
  }

  selectOrder(order:Order){
    this.selectOptionMenu({ name: 'Seleccionar', action: OPTION.SELECT }, order);
  }

  edit(order:Order){
    this.selectOptionMenu({ name: 'Editar', action: OPTION.EDIT }, order);
  }

  autorizate(order:Order){
    this.selectOptionMenu({ name: 'Autorizar', action: OPTION.AUTORIZATE }, order);
  }

  selectOptionMenu(event:OptionMenu,order:Order){
    this.optionMenu.emit({event:event, order:order});
    this.showMenuOptions=false;
  }

  clickInArrow(order:Order, event:any){
    this.arrow.emit({order:order, event:event});
  }

  getSellerName(order:Order){
    return this.orderV.getSellerName(order);
  }

  getProductName(order:Order){
    return this.orderV.getProductName(order);
  }

  getBusinessType(order:Order){
    return this.orderV.getBusinessType(order);
  }

  getTypeCoinAndPrice(order:Order){
    return this.orderV.getTypeCoinAndPrice(order);
  }
  
  getQuality(order:Order){
    return this.orderV.getQuality(order);
  }

  getQuotasMessage(order:Order){
    return this.orderV.getQuotasMessage(order);
  }

  getMessage(buyer: any) {
    return this.orderV.getRestriction(buyer);
  }

  getDeliveryPeriod(order:Order){
    return this.orderV.getDeliveryPeriod(order);
  }

  getWayPay(order:Order){
    return this.orderV.getWayPayNameText(order);
  }

  getDaysWayPay(order:Order){
    return getDaysWayPayText(order?.price?.wayPay);
  }

  getPercentageWayPay(order:Order){
    return getPercentageWayPay(order?.price?.wayPay);
  }

  getPlaceOfDelivery(order:Order){
    return this.orderV.getPlaceOfDelivery(order.price.placeOfDelivery);
  }

  getPesification(order:Order){
    return this.orderV.getPesification(order);
  }

  getBossNameCreator(order:Order){
    return this.orderV.getBossNameCreator(order);
  }

  getNameOfCreator(order:Order){
    return this.orderV.getNameOfCreator(order);
  }

  getHarvest(order:Order){
    return this.orderV.getHarvest(order);
  }

  getBusinessParticularities(businessParticularities:BusinessParticularities){
    return this.orderV.getBusinessParticularities(businessParticularities);
  }
  
  haveQuotas(order:Order){
    return this.orderV.haveQuotas(order);
  }
  
  getName(mod:Mod){
    const translateField:any=translatePricesField;
    return translateField[mod.fiels] ?? mod.fiels
  }

  getTons(order:Order){
    return this.orderV.getTons(order);
  }

  getHaveRestriction(order:Order){
    return this.orderV.getHaveRestriction(order);
  }

  getStatusFromOrder(order:Order){
    return (order?.statusOrder==orderStatusConst.AUTORIZATION && this.isComercial)?'Confirmada':this.orderV.getStatusOrder(order);
  }

  getQualityIBM(order:Order){
    return this.orderV.getQualityIBM(order);
  }

  getBuyer(order:Order){
    return this.priceV.getBuyer(order.price);
  }

  getContractNumber(order:Order){
    return order.contractNumber;
  }

  getDateOfCreation(order:Order){
    return order.dataOfcreation.dateOfcreation;
  }
  
  showContractNumber(order:Order){
    return order.contractNumber > 0 && order.statusOrder == orderStatusConst.CONTRACT;
  }

  getMinHeight(event:any){
    return getMinHeight(event);
  }
}
