export const catamarcaLocalities=[
    {"afip":"79","location":"Achalco"},
    {"afip":"82","location":"Acheral"},
    {"afip":"87","location":"Aconquija"},
    {"afip":"90","location":"Acostilla"},
    {"afip":"100","location":"Adolfo E Carranza"},
    {"afip":"116","location":"Agua Amarilla La Hoyada"},
    {"afip":"117","location":"Agua Amarilla Pta De Balasto"},
    {"afip":"127","location":"Agua Colorada"},
    {"afip":"132","location":"Agua De Dionisio"},
    {"afip":"137","location":"Agua De Las Palomas"},
    {"afip":"145","location":"Agua Del Campo"},
    {"afip":"148","location":"Agua Del Simbol"},
    {"afip":"152","location":"Agua Escondida"},
    {"afip":"154","location":"Agua Grande"},
    {"afip":"157","location":"Agua Los Matos"},
    {"afip":"165","location":"Agua Salada"},
    {"afip":"169","location":"Agua Verde"},
    {"afip":"171","location":"Aguada"},
    {"afip":"194","location":"Aguadita"},
    {"afip":"205","location":"Aguas Calientes"},
    {"afip":"255","location":"Albigasta"},
    {"afip":"324","location":"Algarrobal"},
    {"afip":"345","location":"Alijilan"},
    {"afip":"360","location":"Almigaucho"},
    {"afip":"375","location":"Alta Gracia"},
    {"afip":"393","location":"Alto Bello"},
    {"afip":"407","location":"Alto De La Junta"},
    {"afip":"435","location":"Alto Del Rosario"},
    {"afip":"440","location":"Alto El Bolson"},
    {"afip":"469","location":"Alumbrera"},
    {"afip":"480","location":"Amadores"},
    {"afip":"487","location":"Amana"},
    {"afip":"488","location":"Amanao"},
    {"afip":"489","location":"Amancala"},
    {"afip":"496","location":"Ambato"},
    {"afip":"515","location":"Ampajango"},
    {"afip":"520","location":"Ampolla"},
    {"afip":"521","location":"Ampujaco"},
    {"afip":"534","location":"Ancasti"},
    {"afip":"535","location":"Ancastillo"},
    {"afip":"540","location":"Anchoca"},
    {"afip":"549","location":"Andalgala"},
    {"afip":"550","location":"Andalhuala"},
    {"afip":"551","location":"Andalucia"},
    {"afip":"574","location":"Angelina"},
    {"afip":"578","location":"Angostura"},
    {"afip":"586","location":"Anillaco"},
    {"afip":"590","location":"Anjuli"},
    {"afip":"592","location":"Anquincila"},
    {"afip":"598","location":"Antapoca"},
    {"afip":"608","location":"Antofagasta De La Sierra"},
    {"afip":"632","location":"Apocango"},
    {"afip":"635","location":"Apoyaco"},
    {"afip":"684","location":"Arima"},
    {"afip":"893","location":"Asampay"},
    {"afip":"901","location":"Aserradero El Pilcio"},
    {"afip":"948","location":"Ayapaso"},
    {"afip":"1057","location":"Balcosna"},
    {"afip":"1058","location":"Balcosna De Afuera"},
    {"afip":"1060","location":"Balde"},
    {"afip":"1094","location":"Balde La Punta"},
    {"afip":"1099","location":"Balde Nuevo"},
    {"afip":"1153","location":"Balungasta"},
    {"afip":"1155","location":"Banda"},
    {"afip":"1156","location":"Banda De Lucero"},
    {"afip":"1162","location":"Banda Varela"},
    {"afip":"1191","location":"Barranca Larga"},
    {"afip":"1198","location":"Barranquitas"},
    {"afip":"1337","location":"Barro Negro"},
    {"afip":"1358","location":"Bastidor"},
    {"afip":"1376","location":"Baviano"},
    {"afip":"1389","location":"Bañado De Divisadero"}
    ,
    {"afip":"1390","location":"Bañado De Ovanta"}
    ,
    {"afip":"1413","location":"Baños Termales"}
    ,
    {"afip":"1418","location":"Bebida"},
    {"afip":"1424","location":"Belen"},
    {"afip":"1484","location":"Biscotal"},
    {"afip":"1568","location":"Brea"},
    {"afip":"1569","location":"Brea Chimpana"},
    {"afip":"1589","location":"Buen Retiro"},
    {"afip":"1595","location":"Buena Vista"},
    {"afip":"1601","location":"Buey Muerto"},
    {"afip":"1632","location":"Caballa"},
    {"afip":"1666","location":"Cabrera"},
    {"afip":"1672","location":"Cachi"},
    {"afip":"1681","location":"Cachijan"},
    {"afip":"1690","location":"Cachuan"},
    {"afip":"1726","location":"Calacio"},
    {"afip":"1740","location":"Calera"},
    {"afip":"1746","location":"Calera La Norma"},
    {"afip":"1829","location":"Campitos"},
    {"afip":"1848","location":"Campo Bello"},
    {"afip":"1850","location":"Campo Blanco"},
    {"afip":"2056","location":"Candelaria"},
    {"afip":"2080","location":"Cantera Rota"},
    {"afip":"2096","location":"Capayan"},
    {"afip":"2165","location":"Carapunco"},
    {"afip":"2181","location":"Caridad"},
    {"afip":"2228","location":"Carrizal"},
    {"afip":"2230","location":"Carrizal De Abajo"},
    {"afip":"2231","location":"Carrizal De La Costa"},
    {"afip":"2245","location":"Casa Armada"},
    {"afip":"2250","location":"Casa De Alto"},
    {"afip":"2255","location":"Casa De La Cumbre"},
    {"afip":"2257","location":"Casa De Piedra"},
    {"afip":"2261","location":"Casa Grande"},
    {"afip":"2270","location":"Casa Vieja"},
    {"afip":"2271","location":"Casa Viejas"},
    {"afip":"2304","location":"Caspinchango"},
    {"afip":"2306","location":"Castañar"}
    ,
    {"afip":"2332","location":"Catita"},
    {"afip":"2360","location":"Cañada"}
    ,
    {"afip":"2377","location":"Cañada De Ipizca"}
    ,
    {"afip":"2396","location":"Cañada De Paez"}
    ,
    {"afip":"2421","location":"Cañada Larga"}
    ,
    {"afip":"2511","location":"Cerdas"},
    {"afip":"2515","location":"Cerrillada"},
    {"afip":"2517","location":"Cerrillos"},
    {"afip":"2571","location":"Cerro Colorado"},
    {"afip":"2757","location":"Cerviño"}
    ,
    {"afip":"2809","location":"Chacritas"},
    {"afip":"2811","location":"Chafiñan"}
    ,
    {"afip":"2828","location":"Chamorro"},
    {"afip":"2835","location":"Chanero"},
    {"afip":"2847","location":"Chaquiago"},
    {"afip":"2872","location":"Chavarria"},
    {"afip":"2873","location":"Chavero"},
    {"afip":"2885","location":"Chañar Laguna"}
    ,
    {"afip":"2895","location":"Chañar Punco"}
    ,
    {"afip":"2905","location":"Chañarcito"}
    ,
    {"afip":"2909","location":"Chañaritos"}
    ,
    {"afip":"2911","location":"Chañaryaco"}
    ,
    {"afip":"2925","location":"Chichagasta"},
    {"afip":"2932","location":"Chiflon"},
    {"afip":"2935","location":"Chilca"},
    {"afip":"2969","location":"Chiquerito"},
    {"afip":"3004","location":"Choya"},
    {"afip":"3009","location":"Chuchucaruana"},
    {"afip":"3010","location":"Chucolay"},
    {"afip":"3018","location":"Chumbicha"},
    {"afip":"3036","location":"Cienaga"},
    {"afip":"3042","location":"Cienaguita"},
    {"afip":"3136","location":"Collagasta"},
    {"afip":"3342","location":"Colonia Del Valle"},
    {"afip":"3738","location":"Colonia Nueva Coneta"},
    {"afip":"3977","location":"Colpes"},
    {"afip":"4006","location":"Concepcion"},
    {"afip":"4019","location":"Condor Huasi"},
    {"afip":"4020","location":"Condor Huasi De Belen"},
    {"afip":"4022","location":"Coneta"},
    {"afip":"4044","location":"Copacabana"},
    {"afip":"4061","location":"Cordobita"},
    {"afip":"4136","location":"Corral De Piedra"},
    {"afip":"4145","location":"Corral Quemado"},
    {"afip":"4146","location":"Corral Viejo"},
    {"afip":"4149","location":"Corralita"},
    {"afip":"4150","location":"Corralito"},
    {"afip":"4152","location":"Corralitos"},
    {"afip":"4158","location":"Cortadera"},
    {"afip":"4160","location":"Cortaderas"},
    {"afip":"4182","location":"Costa De Reyes"},
    {"afip":"4222","location":"Cotagua"},
    {"afip":"4328","location":"Cueva Blanca"},
    {"afip":"4335","location":"Culampaja"},
    {"afip":"4340","location":"Cumbre Del Laudo"},
    {"afip":"4413","location":"Desrumbe"},
    {"afip":"4472","location":"Distrito Espinillo"},
    {"afip":"4475","location":"Divisadero"},
    {"afip":"4554","location":"Dos Pocitos"},
    {"afip":"4560","location":"Dos Troncos"},
    {"afip":"4583","location":"Duraznillo"},
    {"afip":"4585","location":"Durazno"},
    {"afip":"4615","location":"El Abra"},
    {"afip":"4628","location":"El Alamito"},
    {"afip":"4643","location":"El Alto"},
    {"afip":"4656","location":"El Arbol Solo"},
    {"afip":"4658","location":"El Arbolito"},
    {"afip":"4660","location":"El Arenal"},
    {"afip":"4663","location":"El Arroyo"},
    {"afip":"4669","location":"El Atoyal"},
    {"afip":"4672","location":"El Aybal"},
    {"afip":"4681","location":"El Balde"},
    {"afip":"4690","location":"El Barreal"},
    {"afip":"4691","location":"El Barrial"},
    {"afip":"4692","location":"El Barrialito"},
    {"afip":"4693","location":"El Bastidor"},
    {"afip":"4698","location":"El Bañado"}
    ,
    {"afip":"4700","location":"El Bello"},
    {"afip":"4701","location":"El Biscote"},
    {"afip":"4704","location":"El Bolson"},
    {"afip":"4732","location":"El Cachiyuyo"},
    {"afip":"4733","location":"El Cacho"},
    {"afip":"4738","location":"El Cajon"},
    {"afip":"4740","location":"El Calchaqui"},
    {"afip":"4747","location":"El Campillo"},
    {"afip":"4776","location":"El Carrizal"},
    {"afip":"4798","location":"El Centenario"},
    {"afip":"4802","location":"El Cercado"},
    {"afip":"4804","location":"El Cerrito"},
    {"afip":"4807","location":"El Cevil"},
    {"afip":"4810","location":"El Cevilarcito"},
    {"afip":"4821","location":"El Chamico"},
    {"afip":"4829","location":"El Chañaral"}
    ,
    {"afip":"4847","location":"El Chorro"},
    {"afip":"4857","location":"El Cienego"},
    {"afip":"4858","location":"El Ciflon"},
    {"afip":"4868","location":"El Colegio"},
    {"afip":"4877","location":"El Contador"},
    {"afip":"4918","location":"El Desmonte"},
    {"afip":"4937","location":"El Duraznillo"},
    {"afip":"4940","location":"El Eje"},
    {"afip":"4975","location":"El Gacho"},
    {"afip":"4981","location":"El Garabato"},
    {"afip":"5013","location":"El Hueco"},
    {"afip":"5051","location":"El Lindero"},
    {"afip":"5081","location":"El Medanito"},
    {"afip":"5083","location":"El Medio"},
    {"afip":"5085","location":"El Milagro"},
    {"afip":"5091","location":"El Mistolito"},
    {"afip":"5093","location":"El Mojon"},
    {"afip":"5097","location":"El Molinito"},
    {"afip":"5098","location":"El Molino"},
    {"afip":"5099","location":"El Mollar"},
    {"afip":"5101","location":"El Molle"},
    {"afip":"5106","location":"El Moreno"},
    {"afip":"5126","location":"El Nogal"},
    {"afip":"5161","location":"El Pajonal"},
    {"afip":"5219","location":"El Peñon"}
    ,
    {"afip":"5223","location":"El Pie De La Cuesta"},
    {"afip":"5250","location":"El Polear"},
    {"afip":"5258","location":"El Portezuelo"},
    {"afip":"5266","location":"El Potrerillo"},
    {"afip":"5269","location":"El Potrero"},
    {"afip":"5273","location":"El Potrero De Los Cordoba"},
    {"afip":"5275","location":"El Pozo"},
    {"afip":"5280","location":"El Pucara"},
    {"afip":"5281","location":"El Pueblito"},
    {"afip":"5284","location":"El Puestito"},
    {"afip":"5285","location":"El Puesto"},
    {"afip":"5291","location":"El Quebrachal"},
    {"afip":"5292","location":"El Quebrachito"},
    {"afip":"5301","location":"El Quimilo"},
    {"afip":"5311","location":"El Realito"},
    {"afip":"5315","location":"El Recreo"},
    {"afip":"5335","location":"El Rodeito"},
    {"afip":"5336","location":"El Rodeo"},
    {"afip":"5337","location":"El Rodeo Grande"},
    {"afip":"5344","location":"El Saladillo"},
    {"afip":"5349","location":"El Saltito"},
    {"afip":"5351","location":"El Salto"},
    {"afip":"5359","location":"El Sauce Ipizca"},
    {"afip":"5360","location":"El Saucecito"},
    {"afip":"5374","location":"El Simbol"},
    {"afip":"5390","location":"El Suncho"},
    {"afip":"5393","location":"El Tabique"},
    {"afip":"5397","location":"El Tala"},
    {"afip":"5405","location":"El Tambillo"},
    {"afip":"5418","location":"El Tesoro"},
    {"afip":"5421","location":"El Tio"},
    {"afip":"5427","location":"El Tolar"},
    {"afip":"5438","location":"El Totoral"},
    {"afip":"5442","location":"El Trapiche"},
    {"afip":"5470","location":"El Valle"},
    {"afip":"5472","location":"El Vallecito"},
    {"afip":"5512","location":"El Zapallar"},
    {"afip":"5516","location":"El Zarzo"},
    {"afip":"5569","location":"Empalme San Carlos"},
    {"afip":"5591","location":"Entre Rios"},
    {"afip":"5648","location":"Esquiu"},
    {"afip":"5669","location":"Establecimiento Minero Cerro B"},
    {"afip":"5721","location":"Estacion Poman"},
    {"afip":"5740","location":"Estancia"},
    {"afip":"5988","location":"Estancito"},
    {"afip":"5991","location":"Estanque"},
    {"afip":"5992","location":"Estanzuela"},
    {"afip":"6059","location":"Faldeo"},
    {"afip":"6061","location":"Famabalastro"},
    {"afip":"6063","location":"Famatanca"},
    {"afip":"6066","location":"Farallon Negro"},
    {"afip":"6100","location":"Fiambala"},
    {"afip":"6284","location":"Fuerte Quemado"},
    {"afip":"6305","location":"Galpon"},
    {"afip":"6317","location":"Garay"},
    {"afip":"6338","location":"Garzon"},
    {"afip":"6422","location":"Gentile"},
    {"afip":"6584","location":"Guanchicito"},
    {"afip":"6585","location":"Guanchin"},
    {"afip":"6615","location":"Guayamba"},
    {"afip":"6639","location":"Guincho"},
    {"afip":"6706","location":"Higuera Del Alumbre"},
    {"afip":"6771","location":"Huaco"},
    {"afip":"6772","location":"Huacra"},
    {"afip":"6782","location":"Hualfin"},
    {"afip":"6801","location":"Huasan"},
    {"afip":"6802","location":"Huasayaco"},
    {"afip":"6805","location":"Huaschaschi"},
    {"afip":"6807","location":"Huasi Cienaga"},
    {"afip":"6809","location":"Huaycama"},
    {"afip":"6833","location":"Huillapima"},
    {"afip":"6844","location":"Humaya"},
    {"afip":"6859","location":"Iapes"},
    {"afip":"6870","location":"Icaño"}
    ,
    {"afip":"6873","location":"Ichipuca"},
    {"afip":"6889","location":"Iloga"},
    {"afip":"6893","location":"Inacillo"},
    {"afip":"6907","location":"Infanzon"},
    {"afip":"6988","location":"Ipizca"},
    {"afip":"7044","location":"Isla Larga"},
    {"afip":"7107","location":"Jacipunco"},
    {"afip":"7146","location":"Jesus Maria"},
    {"afip":"7182","location":"Joyango"},
    {"afip":"7183","location":"Joyanguito"},
    {"afip":"7237","location":"Julipao"},
    {"afip":"7240","location":"Jumeal"},
    {"afip":"7253","location":"Junta"},
    {"afip":"7294","location":"La Aguada"},
    {"afip":"7296","location":"La Aguada Grande"},
    {"afip":"7297","location":"La Aguadita"},
    {"afip":"7299","location":"La Aguita"},
    {"afip":"7323","location":"La Antigua"},
    {"afip":"7342","location":"La Bajada"},
    {"afip":"7349","location":"La Banda"},
    {"afip":"7355","location":"La Barranca Larga"},
    {"afip":"7359","location":"La Barrosa"},
    {"afip":"7368","location":"La Bebida"},
    {"afip":"7389","location":"La Brea"},
    {"afip":"7392","location":"La Buena Estrella"},
    {"afip":"7405","location":"La Calera Del Sauce"},
    {"afip":"7415","location":"La Candelaria"},
    {"afip":"7418","location":"La Capellania"},
    {"afip":"7429","location":"La Carrera"},
    {"afip":"7446","location":"La Cañada"}
    ,
    {"afip":"7449","location":"La Cañada Larga"}
    ,
    {"afip":"7462","location":"La Chacarita"},
    {"afip":"7463","location":"La Chacarita De Los Padres"},
    {"afip":"7474","location":"La Chilca"},
    {"afip":"7492","location":"La Cienaga"},
    {"afip":"7494","location":"La Cienaga De Los Zondones"},
    {"afip":"7509","location":"La Colonia"},
    {"afip":"7545","location":"La Cuesta"},
    {"afip":"7567","location":"La Dorada"},
    {"afip":"7613","location":"La Esquina"},
    {"afip":"7619","location":"La Estancia"},
    {"afip":"7621","location":"La Estancita"},
    {"afip":"7622","location":"La Estanzuela"},
    {"afip":"7635","location":"La Falda"},
    {"afip":"7636","location":"La Falda De San Antonio"},
    {"afip":"7689","location":"La Granja"},
    {"afip":"7695","location":"La Guardia"},
    {"afip":"7707","location":"La Higuerita"},
    {"afip":"7713","location":"La Hoyada"},
    {"afip":"7715","location":"La Huerta"},
    {"afip":"7733","location":"La Isla"},
    {"afip":"7755","location":"La Laguna"},
    {"afip":"7806","location":"La Majada"},
    {"afip":"7816","location":"La Maravilla"},
    {"afip":"7848","location":"La Merced"},
    {"afip":"7849","location":"La Mesada"},
    {"afip":"7861","location":"La Montosa"},
    {"afip":"7889","location":"La Ollada"},
    {"afip":"7898","location":"La Ovejeria"},
    {"afip":"7906","location":"La Palca"},
    {"afip":"7924","location":"La Parada"},
    {"afip":"7925","location":"La Paraguaya"},
    {"afip":"7949","location":"La Peña"}
    ,
    {"afip":"8001","location":"La Puerta"},
    {"afip":"8004","location":"La Puerta De San Jose"},
    {"afip":"8011","location":"La Puntilla"},
    {"afip":"8012","location":"La Puntilla De San Jose"},
    {"afip":"8016","location":"La Quebrada"},
    {"afip":"8022","location":"La Quinta"},
    {"afip":"8025","location":"La Ramada"},
    {"afip":"8027","location":"La Ramadita"},
    {"afip":"8042","location":"La Renovacion"},
    {"afip":"8043","location":"La Represa"},
    {"afip":"8081","location":"La Salvia"},
    {"afip":"8108","location":"La Soledad"},
    {"afip":"8123","location":"La Tercena"},
    {"afip":"8132","location":"La Toma"},
    {"afip":"8140","location":"La Totora"},
    {"afip":"8163","location":"La Valentina"},
    {"afip":"8190","location":"La Viña"}
    ,
    {"afip":"8191","location":"La Viña De Abajo"}
    ,
    {"afip":"8195","location":"La Yegua Muerta"},
    {"afip":"8254","location":"Laguna Blanca"},
    {"afip":"8259","location":"Laguna Colorada"},
    {"afip":"8319","location":"Lagunita"},
    {"afip":"8323","location":"Laja"},
    {"afip":"8328","location":"Lampasillo"},
    {"afip":"8329","location":"Lampaso"},
    {"afip":"8367","location":"Las Aguitas"},
    {"afip":"8388","location":"Las Barrancas"},
    {"afip":"8389","location":"Las Barrancas Casa Armada"},
    {"afip":"8391","location":"Las Barras"},
    {"afip":"8393","location":"Las Bayas"},
    {"afip":"8399","location":"Las Breas"},
    {"afip":"8403","location":"Las Burras"},
    {"afip":"8418","location":"Las Casitas"},
    {"afip":"8424","location":"Las Cayas"},
    {"afip":"8426","location":"Las Cañas"}
    ,
    {"afip":"8434","location":"Las Chacritas"},
    {"afip":"8441","location":"Las Cienagas"},
    {"afip":"8454","location":"Las Cortaderitas"},
    {"afip":"8464","location":"Las Cuchillas"},
    {"afip":"8466","location":"Las Cuchillas Del Aybal"},
    {"afip":"8468","location":"Las Cuevas"},
    {"afip":"8482","location":"Las Esquinas"},
    {"afip":"8483","location":"Las Estancias"},
    {"afip":"8513","location":"Las Higueritas"},
    {"afip":"8517","location":"Las Huertas"},
    {"afip":"8518","location":"Las Iguanas"},
    {"afip":"8525","location":"Las Juntas"},
    {"afip":"8528","location":"Las Justas"},
    {"afip":"8531","location":"Las Lajas"},
    {"afip":"8534","location":"Las Latillas"},
    {"afip":"8544","location":"Las Lomitas"},
    {"afip":"8545","location":"Las Losas"},
    {"afip":"8549","location":"Las Manzas"},
    {"afip":"8564","location":"Las Minas"},
    {"afip":"8566","location":"Las Mojarras"},
    {"afip":"8588","location":"Las Palmitas"},
    {"afip":"8589","location":"Las Palomas"},
    {"afip":"8591","location":"Las Pampas"},
    {"afip":"8593","location":"Las Pampitas"},
    {"afip":"8594","location":"Las Papas"},
    {"afip":"8601","location":"Las Peladas"},
    {"afip":"8607","location":"Las Peñas"}
    ,
    {"afip":"8614","location":"Las Piedras Blancas"},
    {"afip":"8628","location":"Las Puertas"},
    {"afip":"8640","location":"Las Retamas"},
    {"afip":"8660","location":"Las Tapias"},
    {"afip":"8661","location":"Las Tejas"},
    {"afip":"8662","location":"Las Tejas De Valle Viejo"},
    {"afip":"8676","location":"Las Trancas"},
    {"afip":"8678","location":"Las Tranquitas"},
    {"afip":"8685","location":"Las Trillas"},
    {"afip":"8690","location":"Las Tunas"},
    {"afip":"8697","location":"Las Varitas"},
    {"afip":"8713","location":"Las Zanjas"},
    {"afip":"8731","location":"Lavalle"},
    {"afip":"8734","location":"Lazareto"},
    {"afip":"8788","location":"Liebre"},
    {"afip":"8805","location":"Lindero"},
    {"afip":"8844","location":"Loconte"},
    {"afip":"8857","location":"Loma Cortada"},
    {"afip":"8871","location":"Loma Grande"},
    {"afip":"8883","location":"Loma Sola"},
    {"afip":"8925","location":"Londres"},
    {"afip":"8926","location":"Londres Este"},
    {"afip":"8927","location":"Londres Oeste"},
    {"afip":"8940","location":"Loro Huasi"},
    {"afip":"8950","location":"Los Alamos"},
    {"afip":"8987","location":"Los Baldes"},
    {"afip":"8989","location":"Los Balverdis"},
    {"afip":"8994","location":"Los Bastidores"},
    {"afip":"8997","location":"Los Bazan"},
    {"afip":"9010","location":"Los Bulacios"},
    {"afip":"9013","location":"Los Cadillos"},
    {"afip":"9014","location":"Los Cajones"},
    {"afip":"9027","location":"Los Castillos"},
    {"afip":"9030","location":"Los Caudillos"},
    {"afip":"9045","location":"Los Chanampa"},
    {"afip":"9062","location":"Los Cisternas"},
    {"afip":"9070","location":"Los Colorados"},
    {"afip":"9080","location":"Los Cordobeses"},
    {"afip":"9085","location":"Los Corrales"},
    {"afip":"9115","location":"Los Estantes"},
    {"afip":"9126","location":"Los Galpones"},
    {"afip":"9136","location":"Los Gonzales"},
    {"afip":"9142","location":"Los Guaytimas"},
    {"afip":"9146","location":"Los Guindos"},
    {"afip":"9160","location":"Los Huaycos"},
    {"afip":"9179","location":"Los Loros"},
    {"afip":"9204","location":"Los Mogotes"},
    {"afip":"9214","location":"Los Morteritos"},
    {"afip":"9215","location":"Los Morteros"},
    {"afip":"9219","location":"Los Nacimientos"},
    {"afip":"9221","location":"Los Narvaez"},
    {"afip":"9222","location":"Los Navarros"},
    {"afip":"9224","location":"Los Nogales"},
    {"afip":"9234","location":"Los Ortices"},
    {"afip":"9239","location":"Los Ovejeros"},
    {"afip":"9240","location":"Los Palacios"},
    {"afip":"9255","location":"Los Pedrazas"},
    {"afip":"9267","location":"Los Pintados"},
    {"afip":"9268","location":"Los Piquillines"},
    {"afip":"9273","location":"Los Pocitos"},
    {"afip":"9279","location":"Los Potrerillos"},
    {"afip":"9282","location":"Los Pozuelos"},
    {"afip":"9285","location":"Los Puestos"},
    {"afip":"9294","location":"Los Quinteros"},
    {"afip":"9301","location":"Los Rastrojos"},
    {"afip":"9308","location":"Los Rincones"},
    {"afip":"9311","location":"Los Robledos"},
    {"afip":"9323","location":"Los Saltos"},
    {"afip":"9377","location":"Los Troncos"},
    {"afip":"9382","location":"Los Valdez"},
    {"afip":"9385","location":"Los Valveros"},
    {"afip":"9386","location":"Los Varelas"},
    {"afip":"9396","location":"Los Zanjones"},
    {"afip":"9445","location":"Luna Aguada"},
    {"afip":"9508","location":"Majada"},
    {"afip":"9528","location":"Malcasco"},
    {"afip":"9537","location":"Mallin 1"},
    {"afip":"9538","location":"Mallin 2"},
    {"afip":"9658","location":"Maria Dora"},
    {"afip":"9659","location":"Maria Elena"},
    {"afip":"9727","location":"Matambre"},
    {"afip":"9782","location":"Medanito"},
    {"afip":"9783","location":"Medanitos"},
    {"afip":"9784","location":"Medano"},
    {"afip":"9832","location":"Mesada De Los Zarate"},
    {"afip":"9833","location":"Mesada Grande"},
    {"afip":"9876","location":"Mina Dal"},
    {"afip":"9887","location":"Mina Inca Huasi"},
    {"afip":"9912","location":"Minas Agua Tapada"},
    {"afip":"9937","location":"Miraflores"},
    {"afip":"9947","location":"Mischango"},
    {"afip":"9961","location":"Mistol Ancho"},
    {"afip":"10010","location":"Molle Quemado"},
    {"afip":"10012","location":"Mollecito"},
    {"afip":"10013","location":"Mollegasta"},
    {"afip":"10069","location":"Monte Potrero"},
    {"afip":"10073","location":"Monte Redondo"},
    {"afip":"10081","location":"Montegasta"},
    {"afip":"10122","location":"Mota Botello"},
    {"afip":"10146","location":"Mutquin"},
    {"afip":"10156","location":"Nacimiento"},
    {"afip":"10157","location":"Nacimientos De Abajo"},
    {"afip":"10158","location":"Nacimientos De Arriba"},
    {"afip":"10159","location":"Nacimientos De San Antonio"},
    {"afip":"10160","location":"Nacimientos Del Bolson"},
    {"afip":"10171","location":"Naipa"},
    {"afip":"10195","location":"Navaguin"},
    {"afip":"10198","location":"Navigan"},
    {"afip":"10206","location":"Negro Muerto"},
    {"afip":"10252","location":"Nogalito"},
    {"afip":"10339","location":"Ocho Vados"},
    {"afip":"10349","location":"Ojo De Agua"},
    {"afip":"10351","location":"Ojo De La Cortadera"},
    {"afip":"10370","location":"Ollita"},
    {"afip":"10371","location":"Olmos"},
    {"afip":"10373","location":"Olta"},
    {"afip":"10396","location":"Orellano"},
    {"afip":"10421","location":"Ovanta"},
    {"afip":"10423","location":"Ovejeria"},
    {"afip":"10428","location":"Oyola"},
    {"afip":"10474","location":"Pajanguillo"},
    {"afip":"10482","location":"Palacios"},
    {"afip":"10521","location":"Palo Cruz"},
    {"afip":"10523","location":"Palo Labrado"},
    {"afip":"10527","location":"Palo Parado"},
    {"afip":"10531","location":"Palo Seco"},
    {"afip":"10533","location":"Paloma Yaco"},
    {"afip":"10540","location":"Pampa"},
    {"afip":"10552","location":"Pampa Blanca"},
    {"afip":"10560","location":"Pampa Chacra"},
    {"afip":"10562","location":"Pampa Cienaga"},
    {"afip":"10624","location":"Pampa Pozo"},
    {"afip":"10650","location":"Pan De Azucar"},
    {"afip":"10663","location":"Pantanos"},
    {"afip":"10664","location":"Papa Chacra"},
    {"afip":"10709","location":"Paraje Los Chañaritos"}
    ,
    {"afip":"10900","location":"Paso San Francisco"},
    {"afip":"10923","location":"Pastos Amarillos"},
    {"afip":"10948","location":"Payabuayca"},
    {"afip":"11030","location":"Peñas Blancas"}
    ,
    {"afip":"11033","location":"Peñon"}
    ,
    {"afip":"11082","location":"Pie Del Medano"},
    {"afip":"11085","location":"Piedra Blanca"},
    {"afip":"11098","location":"Piedra Larga"},
    {"afip":"11131","location":"Pilciao"},
    {"afip":"11135","location":"Pillahuasi"},
    {"afip":"11200","location":"Planchada"},
    {"afip":"11221","location":"Plaza De San Pedro"},
    {"afip":"11236","location":"Plumero"},
    {"afip":"11270","location":"Pocitos"},
    {"afip":"11275","location":"Polcos"},
    {"afip":"11282","location":"Poman"},
    {"afip":"11283","location":"Pomancillo"},
    {"afip":"11307","location":"Portezuelo"},
    {"afip":"11315","location":"Portillo Chico"},
    {"afip":"11345","location":"Posta"},
    {"afip":"11362","location":"Potrerito"},
    {"afip":"11363","location":"Potrero"},
    {"afip":"11390","location":"Pozancones"},
    {"afip":"11438","location":"Pozo De Piedra"},
    {"afip":"11439","location":"Pozo Del Algarrobo"},
    {"afip":"11444","location":"Pozo Del Campo"},
    {"afip":"11462","location":"Pozo Del Mistol"},
    {"afip":"11483","location":"Pozo Grande"},
    {"afip":"11515","location":"Pozos Cavados"},
    {"afip":"11518","location":"Pozuelos"},
    {"afip":"11563","location":"Pto Espinal"},
    {"afip":"11602","location":"Pueblito"},
    {"afip":"11733","location":"Puerta Grande"},
    {"afip":"11763","location":"Puerto Blanco"},
    {"afip":"11772","location":"Puerto Chipi"},
    {"afip":"11781","location":"Puerto De La Pampa"},
    {"afip":"11849","location":"Puerto Potrero"},
    {"afip":"11936","location":"Puesto De Fadel O De Lobo"},
    {"afip":"11946","location":"Puesto De La Viuda"},
    {"afip":"11951","location":"Puesto De Los Morales"},
    {"afip":"11965","location":"Puesto De Vera"},
    {"afip":"12039","location":"Puesto Los Gomez"},
    {"afip":"12076","location":"Puesto Sabatte"},
    {"afip":"12124","location":"Punta De Balasto"},
    {"afip":"12143","location":"Punta Del Pozo"},
    {"afip":"12175","location":"Quebrachal"},
    {"afip":"12178","location":"Quebracho"},
    {"afip":"12187","location":"Quebrachos Blancos"},
    {"afip":"12203","location":"Quebrada Honda"},
    {"afip":"12208","location":"Quemadita"},
    {"afip":"12210","location":"Quemado"},
    {"afip":"12246","location":"Quimilpa"},
    {"afip":"12261","location":"Quiquero"},
    {"afip":"12265","location":"Quiros"},
    {"afip":"12280","location":"Qusto"},
    {"afip":"12315","location":"Ramblones"},
    {"afip":"12389","location":"Recreo"},
    {"afip":"12441","location":"Retiro"},
    {"afip":"12442","location":"Retiro De Colana"},
    {"afip":"12470","location":"Rincon"},
    {"afip":"12531","location":"Rincon Grande"},
    {"afip":"12549","location":"Rio Abajo"},
    {"afip":"12565","location":"Rio Chico"},
    {"afip":"12566","location":"Rio Colorado"},
    {"afip":"12570","location":"Rio De Avila"},
    {"afip":"12571","location":"Rio De Bazanes"},
    {"afip":"12572","location":"Rio De Don Diego"},
    {"afip":"12575","location":"Rio De La Dorada"},
    {"afip":"12576","location":"Rio De La Plata"},
    {"afip":"12581","location":"Rio De Los Indios"},
    {"afip":"12587","location":"Rio Del Tala"},
    {"afip":"12593","location":"Rio Grande"},
    {"afip":"12600","location":"Rio Los Molinos"},
    {"afip":"12614","location":"Rio Potrero"},
    {"afip":"12652","location":"Rodeo"},
    {"afip":"12664","location":"Rodeo Gervan"},
    {"afip":"12665","location":"Rodeo Grande"},
    {"afip":"12700","location":"Rosario De Colana"},
    {"afip":"12704","location":"Rosario Del Sumalao"},
    {"afip":"12746","location":"Rumimonion"},
    {"afip":"12820","location":"Salado"},
    {"afip":"12827","location":"Salauca"},
    {"afip":"12830","location":"Salcedo"},
    {"afip":"12891","location":"San Antonio De La Paz"},
    {"afip":"12897","location":"San Antonio De P Blanca"},
    {"afip":"12899","location":"San Antonio De Panclin"},
    {"afip":"12903","location":"San Antonio Del Cajon"},
    {"afip":"12904","location":"San Antonio Fray M Esquiu"},
    {"afip":"12909","location":"San Antonio Viejo"},
    {"afip":"12920","location":"San Buenaventura"},
    {"afip":"12954","location":"San Fdo Del Valle De Catamarca"},
    {"afip":"12961","location":"San Francisco"},
    {"afip":"12979","location":"San Geronimo"},
    {"afip":"12998","location":"San Jeronimo"},
    {"afip":"13007","location":"San Jose Banda"},
    {"afip":"13032","location":"San Jose De Tinogasta"},
    {"afip":"13043","location":"San Jose Norte"},
    {"afip":"13064","location":"San Lorenzo"},
    {"afip":"13067","location":"San Luis"},
    {"afip":"13080","location":"San Martin"},
    {"afip":"13104","location":"San Nicolas"},
    {"afip":"13107","location":"San Pablo"},
    {"afip":"13117","location":"San Pedro Capayan"},
    {"afip":"13165","location":"Sancho"},
    {"afip":"13179","location":"Santa Ana"},
    {"afip":"13183","location":"Santa Barbara"},
    {"afip":"13200","location":"Santa Cruz"},
    {"afip":"13214","location":"Santa Gertrudis"},
    {"afip":"13332","location":"Sauce"},
    {"afip":"13340","location":"Sauce De Los Cejas"},
    {"afip":"13346","location":"Sauce Huacho"},
    {"afip":"13347","location":"Sauce Mayo"},
    {"afip":"13366","location":"Saujil"},
    {"afip":"13367","location":"Saujil De Tinogasta"},
    {"afip":"13382","location":"Sebila"},
    {"afip":"13431","location":"Shincal"},
    {"afip":"13434","location":"Sicha"},
    {"afip":"13440","location":"Sierra Brava"},
    {"afip":"13473","location":"Sijan"},
    {"afip":"13489","location":"Singuil"},
    {"afip":"13495","location":"Sisiguasi"},
    {"afip":"13530","location":"Soledad"},
    {"afip":"13569","location":"Sucuma"},
    {"afip":"13576","location":"Sumalao"},
    {"afip":"13578","location":"Sumampa"},
    {"afip":"13585","location":"Suncho"},
    {"afip":"13593","location":"Superi"},
    {"afip":"13604","location":"Suruipiana"},
    {"afip":"13628","location":"Tacahuasi"},
    {"afip":"13630","location":"Tacana"},
    {"afip":"13635","location":"Taco"},
    {"afip":"13638","location":"Taco De Abajo"},
    {"afip":"13658","location":"Tacopampa"},
    {"afip":"13682","location":"Tajamares"},
    {"afip":"13684","location":"Tala"},
    {"afip":"13704","location":"Tala Zapata"},
    {"afip":"13707","location":"Talaguada"},
    {"afip":"13709","location":"Talamayo"},
    {"afip":"13713","location":"Talar"},
    {"afip":"13718","location":"Talega"},
    {"afip":"13720","location":"Talita"},
    {"afip":"13733","location":"Tamberia"},
    {"afip":"13740","location":"Tambu"},
    {"afip":"13759","location":"Tapso"},
    {"afip":"13787","location":"Taton"},
    {"afip":"13801","location":"Telaritos"},
    {"afip":"13835","location":"Termas Villa Vil"},
    {"afip":"13848","location":"Tierra Verde"},
    {"afip":"13874","location":"Tinajera"},
    {"afip":"13882","location":"Tinogasta"},
    {"afip":"13883","location":"Tintigasta"},
    {"afip":"13895","location":"Tiorco"},
    {"afip":"13963","location":"Toro Muerto"},
    {"afip":"13974","location":"Toroyaco"},
    {"afip":"13988","location":"Totora"},
    {"afip":"13991","location":"Totoral"},
    {"afip":"13995","location":"Totorilla"},
    {"afip":"14009","location":"Trampasacha"},
    {"afip":"14081","location":"Tres Puentes"},
    {"afip":"14119","location":"Troya"},
    {"afip":"14143","location":"Tula"},
    {"afip":"14172","location":"Tuscumayo"},
    {"afip":"14277","location":"Vallecito"},
    {"afip":"14293","location":"Vega"},
    {"afip":"14294","location":"Vega Curutu"},
    {"afip":"14299","location":"Vega Tamberia"},
    {"afip":"14354","location":"Vicuña Pampa"}
    ,
    {"afip":"14368","location":"Vilisman"},
    {"afip":"14468","location":"Villa Collantes"},
    {"afip":"14478","location":"Villa Coronel Arroyo"},
    {"afip":"14483","location":"Villa Cubas"},
    {"afip":"14517","location":"Villa Dolores"},
    {"afip":"14638","location":"Villa Las Pirquitas"},
    {"afip":"14669","location":"Villa Macedo"},
    {"afip":"14719","location":"Villa Ofelia"},
    {"afip":"14731","location":"Villa Parque Chacabuco"},
    {"afip":"14753","location":"Villa Quintin Ahumada"},
    {"afip":"14803","location":"Villa San Roque"},
    {"afip":"14828","location":"Villa Seleme"},
    {"afip":"14836","location":"Villa Sotomayor"},
    {"afip":"14889","location":"Villavil"},
    {"afip":"14899","location":"Vinquis"},
    {"afip":"14912","location":"Viscote"},
    {"afip":"14935","location":"Viña Del Cerro"}
    ,
    {"afip":"14986","location":"Yacochuyo"},
    {"afip":"15015","location":"Yapes"},
    {"afip":"15019","location":"Yaquicho"},
    {"afip":"15039","location":"Yerba Buena"},
    {"afip":"15052","location":"Yocan"},
    {"afip":"15083","location":"Zanja"},
    {"afip":"15101","location":"Zarcito"},
    {"afip":"15102","location":"Zarza"},
    {"afip":"15340","location":"Balsa"},
    {"afip":"15378","location":"Bella Vista"},
    {"afip":"15591","location":"Cerro Negro"},
    {"afip":"15606","location":"Chacabuco"},
    {"afip":"15942","location":"El Carmen"},
    {"afip":"16039","location":"El Durazno"},
    {"afip":"16052","location":"El Espinillo"},
    {"afip":"16183","location":"El Parque"},
    {"afip":"16297","location":"El Retiro"},
    {"afip":"16319","location":"El Rosario"},
    {"afip":"16347","location":"El Sauce"},
    {"afip":"16452","location":"Ensenada"},
    {"afip":"16497","location":"Estancia Vieja"},
    {"afip":"16551","location":"Guanaco"},
    {"afip":"16706","location":"La Calera"},
    {"afip":"16715","location":"La Campana"},
    {"afip":"16811","location":"La Costa"},
    {"afip":"16903","location":"La Estrella"},
    {"afip":"16932","location":"La Florida"},
    {"afip":"16975","location":"La Higuera"},
    {"afip":"16983","location":"La Horqueta"},
    {"afip":"17050","location":"La Loma"},
    {"afip":"17165","location":"La Piedra"},
    {"afip":"17310","location":"La Tigra"},
    {"afip":"17354","location":"La Victoria"},
    {"afip":"17368","location":"La Zanja"},
    {"afip":"17457","location":"Las Chacras"},
    {"afip":"17478","location":"Las Cortaderas"},
    {"afip":"17507","location":"Las Flores"},
    {"afip":"17598","location":"Las Palmas"},
    {"afip":"17672","location":"Las Toscas"},
    {"afip":"17773","location":"Los Altos"},
    {"afip":"17774","location":"Los Angeles"},
    {"afip":"17816","location":"Los Chañares"}
    ,
    {"afip":"17885","location":"Los Molles"},
    {"afip":"17922","location":"Los Pinos"},
    {"afip":"17965","location":"Los Talas"},
    {"afip":"17995","location":"Manantiales"},
    {"afip":"18171","location":"Palo Blanco"},
    {"afip":"18235","location":"Peñaflor"}
    ,
    {"afip":"18388","location":"Puesto Del Medio"},
    {"afip":"18447","location":"Rafael Castillo"},
    {"afip":"18584","location":"San Antonio"},
    {"afip":"18658","location":"San Fernando"},
    {"afip":"18706","location":"San Isidro"},
    {"afip":"18742","location":"San Jose"},
    {"afip":"18806","location":"San Manuel"},
    {"afip":"18824","location":"San Miguel"},
    {"afip":"18870","location":"San Rafael"},
    {"afip":"18888","location":"San Roque"},
    {"afip":"18917","location":"San Vicente"},
    {"afip":"19005","location":"Santa Lucia"},
    {"afip":"19018","location":"Santa Maria"},
    {"afip":"19045","location":"Santa Rosa"},
    {"afip":"19073","location":"Santo Domingo"},
    {"afip":"19088","location":"Santo Tomas"},
    {"afip":"19094","location":"Santos Lugares"},
    {"afip":"21443","location":"La Calera"},
    {"afip":"21444","location":"Las Lomitas"},
    {"afip":"21445","location":"Las Tunas"},
    {"afip":"21446","location":"Los Molles"},
    {"afip":"21447","location":"Los Ortices"},
    {"afip":"21448","location":"Los Pocitos"},
    {"afip":"21449","location":"Carrizal"},
    {"afip":"21450","location":"Casa Grande"},
    {"afip":"21451","location":"Corral De Piedra"},
    {"afip":"21452","location":"Cortadera"},
    {"afip":"21453","location":"El Alto"},
    {"afip":"21454","location":"El Barrialito"},
    {"afip":"21455","location":"El Puesto"},
    {"afip":"21456","location":"El Retiro"},
    {"afip":"21457","location":"La Aguadita"},
    {"afip":"21458","location":"La Capellania"},
    {"afip":"21459","location":"La Cienaga"},
    {"afip":"21460","location":"La Falda"},
    {"afip":"21461","location":"La Florida"},
    {"afip":"21462","location":"La Isla"},
    {"afip":"21463","location":"La Mesada"},
    {"afip":"21464","location":"La Puntilla"},
    {"afip":"21465","location":"Las Chacras"},
    {"afip":"21466","location":"Las Pampas"},
    {"afip":"21467","location":"Loro Huasi"},
    {"afip":"21468","location":"Los Morteros"},
    {"afip":"21469","location":"Ojo De Agua"},
    {"afip":"21470","location":"Palo Blanco"},
    {"afip":"21471","location":"San Buenaventura"},
    {"afip":"21472","location":"Agua Colorada"},
    {"afip":"21473","location":"El Bañado"}
    ,
    {"afip":"21474","location":"Rosario Del Sumalao"},
    {"afip":"21475","location":"Santa Cruz"},
    {"afip":"21476","location":"Santa Rosa"},
    {"afip":"21477","location":"Sebila"},
    {"afip":"21488","location":"Ojo De Agua"},
    {"afip":"21489","location":"San Antonio"},
    {"afip":"21490","location":"Buena Vista"},
    {"afip":"21491","location":"Concepcion"},
    {"afip":"21492","location":"El Carrizal"},
    {"afip":"21493","location":"La Cañada"}
    ,
    {"afip":"21494","location":"Las Latillas"},
    {"afip":"21495","location":"Los Puestos"},
    {"afip":"21496","location":"San Martin"},
    {"afip":"21497","location":"Sebila"},
    {"afip":"21498","location":"El Tala"},
    {"afip":"21499","location":"La Aguada"},
    {"afip":"21500","location":"Bella Vista"},
    {"afip":"21501","location":"El Vallecito"},
    {"afip":"21502","location":"La Calera"},
    {"afip":"21503","location":"La Estancia"},
    {"afip":"21504","location":"La Huerta"},
    {"afip":"21505","location":"La Quebrada"},
    {"afip":"21506","location":"Las Tapias"},
    {"afip":"21507","location":"Los Morteros"},
    {"afip":"21508","location":"Sauce Huacho"},
    {"afip":"21509","location":"San Jose"},
    {"afip":"21510","location":"Albigasta"},
    {"afip":"21511","location":"Candelaria"},
    {"afip":"21512","location":"Chañaritos"}
    ,
    {"afip":"21513","location":"El Bañado"}
    ,
    {"afip":"21514","location":"El Barreal"},
    {"afip":"21515","location":"El Cercado"},
    {"afip":"21516","location":"El Chañaral"}
    ,
    {"afip":"21517","location":"El Lindero"},
    {"afip":"21518","location":"El Milagro"},
    {"afip":"21519","location":"El Polear"},
    {"afip":"21520","location":"El Portezuelo"},
    {"afip":"21521","location":"El Rosario"},
    {"afip":"21522","location":"El Suncho"},
    {"afip":"21523","location":"El Tala"},
    {"afip":"21524","location":"El Vallecito"},
    {"afip":"21525","location":"La Aguada"},
    {"afip":"21526","location":"La Barrosa"},
    {"afip":"21527","location":"La Brea"},
    {"afip":"21528","location":"La Cañada"}
    ,
    {"afip":"21529","location":"La Falda"},
    {"afip":"21530","location":"La Hoyada"},
    {"afip":"21531","location":"La Peña"}
    ,
    {"afip":"21532","location":"Las Lomitas"},
    {"afip":"21533","location":"San Antonio"},
    {"afip":"21534","location":"San Jose"},
    {"afip":"21535","location":"San Lorenzo"},
    {"afip":"21536","location":"El Retiro"},
    {"afip":"21537","location":"El Rosario"},
    {"afip":"21538","location":"El Totoral"},
    {"afip":"21539","location":"La Banda"},
    {"afip":"21540","location":"La Falda"},
    {"afip":"21541","location":"La Viña"}
    ,
    {"afip":"21542","location":"Las Lajas"},
    {"afip":"21543","location":"Colpes"},
    {"afip":"21544","location":"El Potrero"},
    {"afip":"21545","location":"San Miguel"},
    {"afip":"21546","location":"Buey Muerto"},
    {"afip":"21547","location":"Casa De Piedra"},
    {"afip":"21548","location":"Cerrillos"},
    {"afip":"21549","location":"Cerro Colorado"},
    {"afip":"21550","location":"Cienaga"},
    {"afip":"21551","location":"Corral Viejo"},
    {"afip":"21552","location":"El Cajon"},
    {"afip":"21553","location":"El Cerrito"},
    {"afip":"21554","location":"El Desmonte"},
    {"afip":"21555","location":"El Recreo"},
    {"afip":"21556","location":"Estancia Vieja"},
    {"afip":"21557","location":"La Campana"},
    {"afip":"21558","location":"La Hoyada"},
    {"afip":"21559","location":"La Loma"},
    {"afip":"21560","location":"La Quebrada"},
    {"afip":"21561","location":"Los Pozuelos"},
    {"afip":"21562","location":"Palo Seco"},
    {"afip":"21563","location":"San Jose"},
    {"afip":"21564","location":"Yapes"},
    {"afip":"21565","location":"Cortaderas"},
    {"afip":"21566","location":"El Desmonte"},
    {"afip":"21567","location":"El Potrero"},
    {"afip":"21568","location":"El Rodeito"},
    {"afip":"21569","location":"La Aguada"},
    {"afip":"21570","location":"La Bajada"},
    {"afip":"21590","location":"El Chorro"},
    {"afip":"21591","location":"La Higuerita"},
    {"afip":"21592","location":"Las Cuchillas"},
    {"afip":"21593","location":"Los Molles"},
    {"afip":"21594","location":"Agua Verde"},
    {"afip":"21595","location":"El Arbolito"},
    {"afip":"21596","location":"El Zapallar"},
    {"afip":"21597","location":"La Aguada"},
    {"afip":"21598","location":"Las Pampitas"},
    {"afip":"21599","location":"Corralito"},
    {"afip":"21600","location":"El Carrizal"},
    {"afip":"21601","location":"Huaco"},
    {"afip":"21602","location":"La Aguada"},
    {"afip":"21603","location":"La Banda"},
    {"afip":"21604","location":"La Cañada"}
    ,
    {"afip":"21605","location":"La Estancia"},
    {"afip":"21606","location":"Las Barrancas"},
    {"afip":"21607","location":"Las Juntas"},
    {"afip":"21843","location":"San Fernando Del Valle De Catamarca"},
    {"afip":"21844","location":"San Fernando Del Valle De Catamarca"},
    {"afip":"21845","location":"San Pedro (Capayan)"}
    ]