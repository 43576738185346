import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Price } from '../interfaces/price.interface';
import { User } from '../interfaces/user.interface';
import { ResponseRequest } from '../interfaces/options.interface';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  constructor(private http: HttpClient) {
  }

  getPricesByDate(date: string){
    return this.http.get<ResponseRequest>(environment.rutas.prices.getPricesByDate+`?created_at=${date}`);
  }

  createPrice(price:Price):Observable<ResponseRequest>{
    let newPrice=JSON.parse(JSON.stringify(price));
    delete newPrice._id;
    delete newPrice.created_at;
    let request= JSON.parse(JSON.stringify(newPrice));

    return this.http.post<ResponseRequest>(environment.rutas.prices.createPrice, JSON.stringify(request));
  }
  /*----------------------------------------------*/

  editPriceNumber(id:any, fields:string, value:any, user:User){
    const request= {
      campo: fields,
      valorI: value,
      name: user.name,
      lastName: user.lastName
    }
    return this.http.put<ResponseRequest>(environment.rutas.prices.editPriceNumber + `/${id}`, JSON.stringify(request));
    //We edit the order
  }

  updatePrice(price:Price, id:any){
    let request= JSON.parse(JSON.stringify(price))
    delete request._id;
    
    return this.http.post<ResponseRequest>(environment.rutas.prices.updatePrices + '/'+ id, request); 
    //We edit the price
  }

  deletePrice(id:any){
    return this.http.delete<ResponseRequest>(environment.rutas.prices.deletePrice +`/${id}`);
  }

  //---------- HISTORICAL ----------------\\
  getHistorical(id:string){
    return this.http.get<ResponseRequest>(environment.rutas.prices.historical.replace('{id}', id) );
  }
}