import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsSectionExchangeComponent } from './views-section-exchange/views-section-exchange.component';
import { ViewsSectionOrderComponent } from './views-section-order/views-section-order.component';
import { ViewsSectionPriceComponent } from './views-section-price/views-section-price.component';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ViewsPricesModule } from '../views/views-prices/views-prices.module';
import { ViewsOrdersModule } from '../views/views-orders/views-orders.module';
import { ViewsOrdersExchangeModule } from '../views/views-orders-exchange/views-orders.module';

@NgModule({
  declarations: [
    ViewsSectionExchangeComponent,
    ViewsSectionOrderComponent,
    ViewsSectionPriceComponent,
  ],
  imports: [
    CommonModule,
    ThreePointsSvgModule,

    ViewsPricesModule,
    ViewsOrdersModule,
    ViewsOrdersExchangeModule

  ], exports: [
    ViewsSectionExchangeComponent,
    ViewsSectionOrderComponent,
    ViewsSectionPriceComponent,
  ]
})
export class ViewsSectionModule { }
