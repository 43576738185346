import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setHeaderInMobile } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'app-header-in-section',
  templateUrl: './header-in-section.component.html',
  styleUrls: ['./header-in-section.component.scss']
})
export class HeaderInSectionComponent {
  @Input() section!:string;
  @Input() isCross:boolean=false;
  constructor(private store:Store<appState>, private router:Router) { }

  goToDashboard(){
    this.router.navigate(['/' + NAVEGATION.HOME])
  }

  back(){
    let url="/";
    switch(this.section){
      case 'Editar condición': url+=NAVEGATION.PRICE_BOARD; break;
      default: url+='';
    }
    
    this.store.dispatch(setHeaderInMobile({header:''}));
    this.router.navigate([url]);
  }
}
