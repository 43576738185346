import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { PriceValidator } from 'src/app/shared/price';
import { CardPrice } from '../price-card';

@Component({
  selector: 'app-card-price-featured',
  templateUrl: './price-featured.component.html',
  styleUrls: ['./price-featured.component.scss']
})
export class PriceFeaturedComponent extends CardPrice {

  constructor(store:Store<appState>, router:Router, priceV:PriceValidator) {
    super(store, router, priceV)
  }
}