import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./../options.component.scss']
})
export class ConvenioComponent extends BusinessParticularityOption implements OnChanges {
  isAgreement: boolean = false;

  constructor() {
    super()
  }

  ngOnChanges(): void {
    try{
      this.isAgreement=this.businessParticularitiesStruct.agreement==1;
    } catch(err){
      this.isAgreement=false;
    }
  }

  setAgreement() {
    this.isAgreement = !this.isAgreement;
    let newBP:BusinessParticularities = JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.agreement = this.boolToNumber(this.isAgreement);
    this.emit2(newBP);
  }

  getIsAgreement() {
    try{
      return this.isAgreement;
    } catch (e) {
      return false;
    }
  }

}
