import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsPricesComponent } from './views-prices.component';
import { OptionModule } from '../option/option.module';
import { FormsModule } from '@angular/forms';
import { EditSvgModule } from 'src/app/shared/styles/svg/edit/edit-svg.module';

@NgModule({
  declarations: [
    ViewsPricesComponent
  ], imports: [
    CommonModule,
    OptionModule,
    FormsModule,
    EditSvgModule
  ], exports: [
    ViewsPricesComponent
  ]
})

export class ViewsPricesModule { }