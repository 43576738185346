import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesString'
})
export class FilterArrayIncludesStringPipe implements PipeTransform {

  transform(array: any[], object:any, search: string) {
    if(!search) return array;
    //if (!search || 3 > search.length) return lista;
    let newSearch=search.toLowerCase();
    const cantElements = array.length;

    let listAny:any[]=[];

    for (let i = 0; i < cantElements; i++) {
      if (object[array[i]].toLowerCase().includes(newSearch)) {
        listAny.push(array[i]);
      }
    }
    return listAny;
  }

}

