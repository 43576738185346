<!--<cdk-virtual-scroll-viewport itemSize="38" class="width-100" [style]="getMinHeight(table)">-->
<div *ngIf="conditionTypeSelected == CONDITION_TYPES.TO_PRICE || 
(conditionTypeSelected == CONDITION_TYPES.TO_FIX && boardSelected != BOARDS_TYPES.CARDS) 
else cardsIndicators" class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive table-board" [ngClass]="{'is-to-fix': conditionTypeSelected==CONDITION_TYPES.TO_FIX}">
        <thead>
            <tr row-price-head class="sticky-header" 
            [viewSelected]="viewSelected" [buyerType]="buyerTypeSelected" [conditionType]="conditionTypeSelected"
            [pricesSelected]="pricesSelected"
            [filter]="priceFilter" [filterIdentificator]="filterIdentificator" [isCheckedAll]="getCheckedAll()" [showCheckbox]="true"
            (filterSelect)="selectFilter($event)" (actions)="emitAction($event)"></tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let product of pricesWithPipe; trackBy: trackByPriceId; index as i">
            <!--<ng-container *cdkVirtualFor="let product of pricesWithPipe; trackBy: trackByPriceId; let i = index">-->
            <tr *ngIf="!(isChild(product) && !parentShowInDropdown(product, i))"
            class="{{isIdInArrayParent(product._id)?'is-open':'is-close'}}"
            [ngClass]="{'isRetired': product.status == 4,'isInactive': product.status == 5,
            'is-new': getIsNew(product), 'is-parent': isParent(product), 'is-child': isChild(product),
            'is-open': getArrowDirection(product._id)=='up' || isParent(product)}">                

                <td class="options-column fixed">
                    <input class="form-check-input" [checked]="isPriceInPriceList(product)"
                        (click)="selectPrice(product)" type="checkbox" value="true">

                    <eye-svg (click)="openModalPrice(product)" [active]="false"></eye-svg>
                    <three-point-svg (click)="openOptions(i)"></three-point-svg>
                    <div class="menu-options in-menu-option" *ngIf="optionIdentificator==i">
                        <ul class="in-menu-option">
                            <li (click)="editPrice(product)" class="in-menu-option">
                                <edit-svg></edit-svg> Editar condición</li>
                            <li (click)="createDeferral(i)" class="in-menu-option">
                                <create-svg></create-svg> Crear diferido <arrow-svg class="arrow-deferral" [direction]="'right'"></arrow-svg></li>
                            <li (click)="duplicatePrice(product)" class="in-menu-option">
                                <create-svg></create-svg> Crear variante</li>
                            <li (click)="duplicatePrice(product)" class="in-menu-option">
                                <copy-condition-svg></copy-condition-svg> Copiar condición</li>
                            <li (click)="showHistorical(product)" class="in-menu-option">
                                <clock-svg></clock-svg> Historial de cambios</li>
                            <li (click)="createOrderWithPrice(product)" class="in-menu-option">
                                <generate-order-option-svg></generate-order-option-svg> Generar orden</li>
                            <li (click)="deletePrice(product)" class="in-menu-option">
                                <delete-svg></delete-svg> Eliminar condición</li>
                        </ul>
                    </div>

                    <create-deferral *ngIf="deferral==i" class="deferral" [price]="product"
                    (action)="emitActionDeferral($event, i)"></create-deferral>

                    <arrow-board-svg class="arrow" [direction]="getArrowDirection(product._id)" *ngIf="isParent(product) || haveIndicators(product)" 
                    (click)="clickInArrow(product, $event)"></arrow-board-svg>
                </td>

                <td class="observations-column" *ngIf="viewSelected.observations">
                    <div class="observations-column-contain">

                        <featured-price-svg class="icon"
                        (click)="editPriceWithSubTag(product, 'observations', 'isFeatured', product.observations.isFeatured==1? -1:1 )"
                        [active]="product.observations.isFeatured==1"></featured-price-svg>

                        <div class="message-container icon tooltip-container">
                            <message-svg class="icon" 
                            (click)="openDialogToWriting(product)"
                            [active]="product.observations.isMessage==1"></message-svg>

                            <div class="tooltip message" *ngIf="product.observations.isMessage==1">
                                <span>
                                    Comentario: <br>
                                    {{product.observations.message}}
                                </span>
                                <div class="triangle"></div>
                            </div>
                        </div>

                        <gravanz-svg class="icon" 
                        (click)="editPriceWithSubTag(product, 'observations','isBussinesGravanz', product.observations.isBussinesGravanz==1? -1:1)"
                        [active]="product.observations.isBussinesGravanz==1"></gravanz-svg>
                    </div>
                </td>

                <td *ngIf="viewSelected.state" class="{{STATUS[product.status].toLowerCase()}}" #cell>
                    <span [textContent]="STATUS[product.status]" name="status"
                    [class]="'no-background status-price ' + STATUS[product.status].toLowerCase()" 
                    (focus)="paintCell(cell)" (blur)="unpaintCell(cell)"
                    (keypress)="notLinePlus($event)"
                    (blur)="setTagSpan(span, product, 'status', STATUS )"
                    (keyup)="setPlaceHolder($event, STATUS, span,'')" #span
                    [contentEditable]="true"></span>

                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>
                </td>

                <td *ngIf="viewSelected.product" #cell>
                    <span class="no-background" (blur)="setProductName(span, product)"
                    (focus)="paintCell(cell)" (blur)="unpaintCell(cell)"
                    (keypress)="notLinePlus($event)"
                    (keyup)="setPlaceHolder($event, PRODUCTS, span, '')" #span 
                    [textContent]="getProductName(product)" [contentEditable]="true"></span>

                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>
                </td>

                <td *ngIf="buyerTypeSelected==buyerTypes.BUYER || buyerTypeSelected==buyerTypes.ALL">
                    {{ BUYERS[product.observations.buyer] }}
                </td>

                <td *ngIf="viewSelected.typeBusiness" #cell >           
                    <span class="no-background" (focus)="paintCell(cell)" (blur)="unpaintCell(cell)" 
                    (blur)="setTagSpan(span, product, 'typeBusiness', BUSINESS_TYPE)"
                    (keypress)="notLinePlus($event)"
                    (keyup)="setPlaceHolder($event, BUSINESS_TYPE, span, '')" 
                    #span [contentEditable]="true" [textContent]="getBusinessType(product)"></span>

                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>
                </td>

                <td *ngIf="viewSelected.placeOfDelivery" #cell>
                    <span class="no-background" (focus)="paintCell(cell)" (blur)="unpaintCell(cell)" 
                    (blur)="setPlaceOfDelivery(span, product)"
                    (keyup)="setPlaceHolder($event, PLACE_OF_DELIVERY, span,'placeOfDelivery')"
                    (keypress)="notLinePlus($event)" #span
                    [contentEditable]="true" [textContent]="getPlaceOfDelivery(product)"></span>

                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>
                </td>

                <td *ngIf="viewSelected.deliveryPeriod" #cell>
                    <div class="delivery-period-container tooltip-container" [ngClass]="{'is-valid-until': isValidUntil(product)}">

                        <span #span [contentEditable]="true" [textContent]="getDeliveryPeriod(product)"
                        class="no-background" (blur)="setDeliveryPeriod(span, product)" 
                        (focus)="paintCell(cell)" (blur)="unpaintCell(cell)"
                        (keypress)="checkKeyPressForDate($event, span,true)"
                        (keyup)="setInputDate($event,span, true)"></span>
                        
                        <div class="tooltip delivery-period-message" *ngIf="isValidUntil(product)">
                            <span>Valido hasta: {{getValidUntilText(product)}}</span>
                            <div class="triangle"></div>
                        </div>

                    </div>

                </td>

                <td *ngIf="viewSelected.quality" class="quality-column" #cellQuality>
                    <div class="quality-container tooltip-container {{QUALITY[product.quality].toLowerCase()}}" 
                    [ngClass]="{'not-quality-particularity': product.qualityIBM<0}">

                        <span class="no-background quality" [textContent]="getQuality(product)"
                        [contentEditable]="true" (focus)="paintCell(cellQuality)" (blur)="unpaintCell(cellQuality)" 
                        (blur)="setTagSpan(span, product, 'quality', QUALITY)"
                        (keyup)="setPlaceHolder($event, QUALITY, span,'')"
                        (keypress)="notLinePlus($event)" #span></span>

                        <div class="tooltip quality-message" *ngIf="product.qualityIBM > 0">
                            <span>
                                Particularidad de calidad: <br>
                                "{{getQualityIBM(product)}}"
                            </span>
                            <div class="triangle"></div>
                        </div>
                    </div>
                    
                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>
                </td>

                <td class="price-column" *ngIf="viewSelected?.price && conditionTypeSelected!=CONDITION_TYPES.TO_FIX" #cell>
                    <div class="price-container tooltip-container">

                        <span class="no-background" #span [contentEditable]="true"
                        (blur)="setPrice(product, span)" (keyup)="blurInput(span, $event)" (keypress)="notLinePlus($event)" (focus)="paintCell(cell)" (blur)="unpaintCell(cell)">
                            {{getTypeCoinAndPrice(product)}}
                        </span>
                    </div>
                </td>

                <td *ngIf="viewSelected.wayPay" #cell>
                    <span class="no-background" (blur)="setWayPay(span, product)" (focus)="paintCell(cell)" (blur)="unpaintCell(cell)" 
                    (keyup)="setPlaceHolder($event, WAY_PAY, span,'')" (keypress)="notLinePlus($event)" #span [contentEditable]="true"
                    [textContent]="getOnlyWayPayName(product)"></span>

                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>

                    <br>
                    <p class="days-way-pay">{{getDaysWayPay(product)}} - {{getPercentageWayPay(product)}}</p>
                </td>

                <td *ngIf="viewSelected.expiration && conditionTypeSelected==CONDITION_TYPES.TO_PRICE" #cell>
                    <span #span [contentEditable]="true" [textContent]="getExpiration(product)"
                    [title]="product.wayPay.options>0? 'Vencimiento anclado': ''"
                    class="no-background" [ngClass]="{'fix-expiration': isFixExpiration(product)}" (blur)="setExpiration(product, span)"
                    (keypress)="checkKeyPressForDate($event, span)"
                    (keyup)="setInputDate($event,span)"
                    (focus)="paintCell(cell)" (blur)="unpaintCell(cell)" ></span>
                </td>
                    
                <td *ngIf="viewSelected.pesificacion" #cell>
                    <span class="no-background" (blur)="setPesification(product, span)" 
                    (focus)="paintCell(cell)" (blur)="unpaintCell(cell)"
                    (keypress)="checkKeyPressForDate($event, span,true)"
                    (keyup)="setInputDate($event,span,true)" #span
                    [contentEditable]="true" [textContent]="getPesification(product)"></span>
                </td>

                <td *ngIf="viewSelected.harvest" #cell >
                    <span class="no-background" (blur)="setHarvest(span, product, 'harvest')" (focus)="paintCell(cell)" (blur)="unpaintCell(cell)" 
                    (keyup)="setPlaceHolder($event, HARVEST, span,'')" #span
                    [contentEditable]="true" [textContent]="product.harvest"></span>

                    <span class="autocomplete"> 
                        <span class="first-part">{{firstPart}}</span> 
                        <span class="second-part">{{placeHolder}}</span>
                    </span>
                </td>
                
                <td *ngIf="viewSelected.businessParticularities">{{getBusinessParticularities(product)}}</td>

                <td *ngIf="viewSelected.hour">{{product.dataOfcreation.hour}}</td>
            </tr>

                    <tr class="indicators" *ngIf="haveIndicators(product) && isIdInArrayParent(product._id)">
                        <td class="first-col-indicators"></td>
                    
                        <td colspan="14">
                            <div class="indicators-container">
                                <app-indicators-table [price]="product" [showFirstColumn]="true" [user]="user" [isDisabled]="STATUS[product.status].toLowerCase()=='inactivo'"></app-indicators-table>
                            </div>
                        </td>
                    </tr>
            </ng-container>

        </tbody>
    </table>
</div>
<!--</cdk-virtual-scroll-viewport>-->

<ng-template #cardsIndicators>
    <div class="width-100 big-container" [style]="getMinHeight(contenedor)">
        <div class="container-card-indicators" #contenedor>
            <card-indicators class="card-indicator" *ngFor="let price of pricesWithPipe" [price]="price"
            [isSelected]="isPriceInPriceList(price)" (selected)="selectPrice($event)"></card-indicators>
        </div>
    </div>
</ng-template>