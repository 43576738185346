import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetPriceToGenerate, setPriceToGenerate } from 'src/app/redux/actions/price.action';
import { conditionType } from 'src/app/shared/const/options';
import { WAY_PAY_TO_FIX_DEFAULT, WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { PriceValidator } from 'src/app/shared/price';

const SIZE_OF_VIEW_MOBILE=950;

@Component({
  selector: 'app-body-generate-price',
  templateUrl: './body-generate-price.component.html',
  styleUrls: ['./body-generate-price.component.scss']
})

export class BodyGeneratePriceComponent implements OnInit, OnDestroy {
  user!:User;

  //It serves to identify if the user pressed 'Commodities' or 'Exporter'
  public isPort: boolean = false;

  //It is a boolean that represents if the button is disabled or not
  disabledButton:boolean=true;

  //It is a boolean that represents if the details are being displayed or not
  detailsVisible:boolean=false;

  //It is a boolean that represents if the form is being displayed or not
  formVisible:boolean=true;

  private priceToGenerate!: Price;
  private arrayOfBuyers: any[] = [];
  conditionTypes=conditionType;


  count:number=0;

  private price$!:Subscription;
  private sizeView$!:Subscription;
  private user$!:Subscription;
  constructor(private store:Store<appState>, private priceV:PriceValidator) {}

  ngOnInit(): void {
    this.price$=this.store.select('price').subscribe(price=>{
      this.priceToGenerate=price.generatePrice;
      this.arrayOfBuyers=price.arrayOfBuyers;
      this.isPort=price.generatePrice.observations.isPort == 1;
      this.disabledButton= this.priceV.isPriceIncomplete(this.priceToGenerate);
    })

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.showDetailsAndForm(sizeView);
    });

    this.user$=this.store.select('usuario').subscribe(user=>{
      this.user=user;
    })
  }

  ngOnDestroy(): void {
    this.price$?.unsubscribe();
    this.sizeView$?.unsubscribe();
    this.user$?.unsubscribe();
  }

  //It serves to identify if the user pressed 'Commodities' or 'Exporter'
  setIsExport(isPort: boolean) {
    this.store.dispatch(setPriceToGenerate({
      price: {
        ...this.priceToGenerate,
        observations: {
          ...this.priceToGenerate.observations,
          isPort: isPort? 1:-1,
          buyer: -1,
        }
      }
    }))
  }

  //It serves to identify if the user pressed 'A precio' or 'A fijar'
  setIsOrderToPrice(conditionType:string) {
    const isOrderToPrice= conditionType==this.conditionTypes.TO_PRICE;

    let newPrice:Price=JSON.parse(JSON.stringify(this.priceToGenerate));
    if(isOrderToPrice){
      newPrice.indicators=[];
      newPrice.conditionType=0;
      newPrice.wayPay=WAY_PAY_VOID;
      newPrice.grouperCode=1;
    } else {
      newPrice.typeCoin='--';
      newPrice.price=-1;
      newPrice.wayPay=WAY_PAY_TO_FIX_DEFAULT;
      newPrice.grouperCode=0;
    }
    this.store.dispatch(setPriceToGenerate({price: newPrice}))
  }

  showDetails(showDetails:boolean){
    this.detailsVisible=showDetails;
    this.formVisible=!showDetails;
    this.count++;
  }

  showDetailsAndForm(sizeView:number){
    if(sizeView > SIZE_OF_VIEW_MOBILE) {
      this.detailsVisible=true;
      this.formVisible=true;
      this.count=0
    } else if(this.count==0) {
      this.detailsVisible=false;
      this.formVisible=!this.detailsVisible
    }
  }

  confirmPrice(){
    this.priceV.confirmPrice(this.arrayOfBuyers, this.priceToGenerate, this.user);
  }

}
