import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractFailedComponent } from './contract-failed.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';

@NgModule({
  declarations: [
    ContractFailedComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule
  ], exports: [
    ContractFailedComponent
  ]
})
export class DialogContractFailedModule { }
