import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningChangeTypeCoinComponent } from './currency-exchange-warning.component';

@NgModule({
  declarations: [
    WarningChangeTypeCoinComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    WarningChangeTypeCoinComponent
  ]
})
export class WarningChangeTypeCoinModule { }
