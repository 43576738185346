<div class="background-svg">
    <svg class="svg-12" [ngClass]="{'enabled': active, 'disabled': !active}" width="12" height="12" viewBox="0 0 12 12"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1072_31665)">
            <path
                d="M7.06066 0.439335C7.64644 1.02512 7.64644 1.97486 7.06066 2.56064C6.47487 3.14642 5.52512 3.14642 4.93934 2.56064C4.35355 1.97486 4.35355 1.02512 4.93934 0.439335C5.5251 -0.146445 6.47485 -0.146445 7.06066 0.439335Z"
                fill="#4F5858" />
            <path
                d="M7.06066 4.93934C7.64644 5.52512 7.64644 6.47487 7.06066 7.06066C6.47487 7.64644 5.52512 7.64644 4.93934 7.06066C4.35355 6.47487 4.35355 5.52512 4.93934 4.93934C5.5251 4.35355 6.47485 4.35355 7.06066 4.93934Z"
                fill="#4F5858" />
            <path
                d="M7.06066 9.43934C7.64644 10.0251 7.64644 10.9749 7.06066 11.5607C6.47487 12.1464 5.52512 12.1464 4.93934 11.5607C4.35355 10.9749 4.35355 10.0251 4.93934 9.43934C5.5251 8.85355 6.47485 8.85355 7.06066 9.43934Z"
                fill="#4F5858" />
        </g>
        <defs>
            <clipPath id="clip0_1072_31665">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>

    <div class="tooltip row-center w-40">
        <span>Menú</span>
    </div>
</div>