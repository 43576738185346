import { Component, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-deferral',
  templateUrl: './info-deferral.component.html',
  styleUrls: ['./info-deferral.component.scss']
})
export class InfoDeferralComponent {
  @Output() hidden= new EventEmitter<string>();

  constructor() { }

  closeCard(){
    this.hidden.emit('close');
  }
}
