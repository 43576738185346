import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsExchangeComponent } from './details.component';
import { CommissionModule } from 'src/app/components/cards/commission/commission.module';
import { DataModule } from 'src/app/components/details/data/data.module';
import { DuplicateOrdersModule } from '../../cards/duplicate-orders/duplicate-orders.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { DuplicateOrderCollectionSvgModule } from 'src/app/shared/styles/svg/duplicate-orders/duplicate-order.module';
import { InfoOrderModule } from '../info/info-order/info-order.module';
import { InfoExchangeModule } from '../info/info-exchange/info-exchange.module';
import { InfoSyngentaModule } from '../info/info-syngenta/info-syngenta.module';

@NgModule({
  declarations: [
    DetailsExchangeComponent,
  ],
  imports: [
    CommonModule,
    DataModule,
    CommissionModule,
    DuplicateOrdersModule,
    ArrowSvgModule,
    DuplicateOrderCollectionSvgModule,
    CommissionModule,
    InfoOrderModule,
    InfoExchangeModule,
    InfoSyngentaModule
  ],
  exports:[
    DetailsExchangeComponent
  ]
})
export class DetailsExchangeModule { }
