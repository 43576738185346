import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { FormComponent } from '../form.component';
import { OrderValidator } from 'src/app/shared/order';
import { PriceValidator } from 'src/app/shared/price';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { resetPriceToGenerate, setPriceToGenerate } from 'src/app/redux/actions/price.action';
import { DialogPriceCreate } from '../../dialogs/price-create/dialog';
import { DateChecker, isInvalidStartDate } from 'src/app/shared/date.checker';
import { BUSINESS_PARTICULARITIES_VOID } from 'src/app/shared/const/business-particularities';
import { WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { setIsClickedInConfirm } from 'src/app/redux/actions/options.action';
//import { QUALITY_PARTICULARITIES_VOID } from 'src/app/shared/const/quality-particularities';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { fieldType } from 'src/app/shared/const/options';
import { Subscription } from 'rxjs';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';
import { ToFixValidator, isIndicatorsIncomplete } from 'src/app/shared/to-fix';
import { SessionChecker } from 'src/app/shared/session.checker';

@Component({
  selector: 'app-form-price',
  templateUrl: './form.component.html',
  styleUrls: ['./../form.component.scss']
})
export class FormPriceComponent extends FormComponent implements OnInit, OnDestroy {
  //It serves to identify if the user pressed 'Commodities' or 'Exporter'
  public isPort: boolean = false;
  public priceTag: any;
  arrayOfBuyers: any[]=[];

  private priceOfForm$!:Subscription;
  private arrayOfBuyers$!:Subscription;
  constructor( store:Store<appState>, orderExcV:OrderExchangeValidator, 
    orderV:OrderValidator, priceV:PriceValidator, public toFixV:ToFixValidator,
    dialog: MatDialog, public date:DateChecker, checker:SessionChecker) { 
    super(store, checker, orderExcV, orderV, priceV, dialog);

    this.priceOfForm$=this.store.select('price','generatePrice','observations', 'isPort').subscribe(isPort=>{
      this.isPort=isPort==1;
    })

    this.arrayOfBuyers$=this.store.select('price', 'arrayOfBuyers').subscribe(arrayOfBuyers=>{
      this.arrayOfBuyers=arrayOfBuyers;
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.priceOfForm$?.unsubscribe();
    this.arrayOfBuyers$?.unsubscribe();
  }

  confirmPrice(formulario:any){
    if(this.isDisablePrice()){
      this.store.dispatch(setIsClickedInConfirm({isClickedInConfirm:true}));
      this.verifyElement(formulario);
    } else {
      this.priceV.confirmPrice(this.arrayOfBuyers, this.price, this.user);    
      this.changeStep(1, formulario);
    } 
  }

  //Si es una condición a fijar, se va a fijar que la condición sea a fijar, si no, se va a fijar que la condición sea a precio
  isDisablePrice(){
      return this.priceV.isPriceIncomplete(this.price) || 
      this.priceV.getWayPayIBM(this.price)==0 || isInvalidStartDate(this.price.deliveryPeriod.startDate)
  }

  override deleteAll(numOfStep: number=0): void {
    let price:Price=JSON.parse(JSON.stringify(this.price));     
    
    price.productName=-1;
    price.observations.buyer=-1;
    price.typeBusiness=-1;
    price.businessParticularities= BUSINESS_PARTICULARITIES_VOID;
    price.placeOfDelivery={port:-1, zone:-1};
    price.deliveryPeriod= {endDate: '--', startDate: '--'};
    price.price=-1;
    price.typeCoin='--';
    price.quality= -1;
    this.showPartQuality=false;
    //price.qualityParticularities= QUALITY_PARTICULARITIES_VOID;
    price.harvest='--';
    price.wayPay= WAY_PAY_VOID;
    price.pesificacion={endDate: '--', startDate: '--'}
    price.grouperCode=1;
    price.indicators=[];
    price.conditionType= 0;
    
    this.store.dispatch(setPriceToGenerate({price:price}));
  }

  override deleteStep(numOfStep: number=0): void {
    let price:Price=JSON.parse(JSON.stringify(this.price));     
    
    if(numOfStep==1){
      price.productName=-1;
      price.observations.buyer=-1;
      price.typeBusiness=-1;
      price.businessParticularities= BUSINESS_PARTICULARITIES_VOID;
      price.placeOfDelivery={port:-1, zone:-1};
    } else if(numOfStep==2){
      price.deliveryPeriod= {endDate: '--', startDate: '--'};
      price.price=-1;
      price.typeCoin='--';
      price.quality= -1;
      this.showPartQuality=false;
      //price.qualityParticularities= QUALITY_PARTICULARITIES_VOID;
    } else if(numOfStep==3){
      price.harvest='--';
      price.wayPay= WAY_PAY_VOID;
      price.pesificacion={endDate: '--', startDate: '--'}
      price.grouperCode=1;
    } else if(numOfStep==4){
      price.conditionType=0;
      price.indicators=[];
    }
    this.store.dispatch(setPriceToGenerate({price:price}));
  }
  
  override verifyElement(formulario:any){
    if( this.price.productName==-1 || this.price.typeBusiness==-1  || this.price.placeOfDelivery.zone == -1 || this.requirePort()){
        this.changeStep(1, formulario);
    } else if(this.price.deliveryPeriod.endDate=='--' || this.price.deliveryPeriod.startDate=='--' || 
      (this.isGenerateToFix?false: this.price.price==-1 || this.price.typeCoin=='--') || this.price.quality==-1){
      this.changeStep(2, formulario);
    } else if(this.price.harvest=='--' || this.price.wayPay== WAY_PAY_VOID){
      this.changeStep(3, formulario);
    } else if(
      (this.order.price.indicators?.length>0? isIndicatorsIncomplete(this.order.price?.indicators, this.order.price?.conditionType):false)){
      this.changeStep(4, formulario);
    }
  }

  override requirePort():boolean{
    return this.priceV.isInvalidOptionalPort(this.price.placeOfDelivery);
  }

  isContraEntregaPrice(price:Price):boolean{
    return price.wayPay.wayPayName==wayPayNames.contraEntrega;
  }

  isInDolarsPrice(price:Price):boolean{
    return this.priceV.isInDolars(price);
  }

  override getFieldType(){
    return fieldType.PRICE;
  }

  override getNumMaxOfStep(){
    return this.isGenerateToFix? 4:3;
  }
}