import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NroBillingComponent } from './nro-billing.component';
import { TextInputModule } from '../../inputs/text/text.module';

@NgModule({
  declarations: [
    NroBillingComponent
  ],
  imports: [
    CommonModule,
    TextInputModule,  
  ],
  exports: [
    NroBillingComponent
  ]
})
export class NroBillingModule { }