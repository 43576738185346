import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuplicateOrdersComponent } from './duplicate-orders.component';
import { NumberInputModule } from '../../inputs/number/number.module';

@NgModule({
  declarations: [
    DuplicateOrdersComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule
  ], exports:[
    DuplicateOrdersComponent
  ]
})
export class DuplicateOrdersModule { }
