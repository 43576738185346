import { Component, OnInit,OnDestroy,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterIndex, setHistorical, setPriceToGenerate } from 'src/app/redux/actions/price.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { PriceValidator, showSlidePriceDetails } from 'src/app/shared/price';
import { TableComponent } from '../table.component';
import { OrderValidator, createOrderWithPrice } from 'src/app/shared/order';
import { ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { setOrdersSelected, setPriceToGenerateInOrder } from 'src/app/redux/actions/order.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { Subscription } from 'rxjs';
import { ToFixValidator, priceHaveIndicators } from 'src/app/shared/to-fix';
import { BOARD_TYPES } from 'src/app/shared/const/options';
import { getDaysWayPayText, getPercentageWayPay } from 'src/app/shared/way-pay';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: [ "../table.component.scss", './../../../../../shared/styles/board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class PriceTableComponent extends TableComponent implements OnInit, OnDestroy {
  public sizeView!: number;
  public override viewSelected!:ViewPrice;
  BOARDS_TYPES=BOARD_TYPES;
  boardSelected: string = '';

  private boardSelected$!:Subscription;
  private priceFilter$!:Subscription;
  private viewSelected$!:Subscription;

  constructor(store: Store<appState>, router: Router, date:DateChecker, 
    orderV:OrderValidator, priceV:PriceValidator, toFixV:ToFixValidator, cdr:ChangeDetectorRef) {
    super(store, router,date, orderV, priceV, toFixV, cdr);
  }

  override ngOnInit(): void {
    this.setArrays();
    this.setSubscriptions();

    this.priceFilter$=this.store.select('price', 'priceBoard', 'filter').subscribe(filter => {
      this.priceFilter = filter;
      this.setNewOrdersPipe();
    });

    this.viewSelected$=this.store.select('price', 'viewsOfPriceBoard', 'viewSelected').subscribe(viewSelected => {
      this.viewSelected = viewSelected;
      this.setNewOrdersPipe();
    });

    this.boardSelected$=this.store.select('options', 'board', 'boardSelected').subscribe((boardSelected) => {
      this.boardSelected = boardSelected;
      this.setNewOrdersPipe();
    });
  }

  override ngOnDestroy(): void {
    this.priceFilter$?.unsubscribe();
    this.viewSelected$?.unsubscribe();
    this.boardSelected$?.unsubscribe();
    this.setUnsubscriptions();
    this.store.dispatch(setOrdersSelected({ orders: [] }));
  }
  
  //----GENERATE ORDER----\\
  createOrderWithPrice(price: Price) {
    let copyPrice = JSON.parse(JSON.stringify(price));
    delete copyPrice._id;
    delete copyPrice.created_at;
    this.store.dispatch(setPriceToGenerateInOrder({ price: copyPrice }));
    this.router.navigate(['/'+NAVEGATION.GENERATE_MANUAL_ORDER]);
  }

  createPriceWithPrice(price: Price) {
    let copyPrice = JSON.parse(JSON.stringify(price));
    copyPrice._id=0;
    this.store.dispatch(setPriceToGenerate({price:copyPrice}));
    this.router.navigate(['/'+NAVEGATION.GENERATE_PRICE]);
  }

  //---- FILTERS ----\\
  //It is a different function for business users and operators.
  override selectFilter(index: number) {
    this.store.dispatch(setFilterIndex({index: index==this.filterIdentificator ? -1 : index}));
  }

  override openOptions(index: number) {
    if (index == this.optionIdentificator) {
      this.closeOptions();
    } else {
      this.optionIdentificator = index;
      this.wasInside = true;
    }
  }

  showHistoricalP(price:Price){
    this.store.dispatch(setHistorical({historical: price._id}));
    this.closeOptions();
  }

  getPriceStatus(price:Price){
    return this.priceV.getPriceStatus(price);
  }

  getProductName(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.PRODUCTS[price.productName];
  }

  getBusinessType(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.BUSINESS_TYPE[price.typeBusiness]
  }

  getPlaceOfDeliveryP(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.priceV.getPlaceOfDelivery(price.placeOfDelivery);
  }

  getTypeCoinAndPriceWithPoints(price:Price){
    return this.priceV.getTypeCoinAndPriceWithPoints(price);
  }
  
  getDeliveryPeriodP(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return price.deliveryPeriod.startDate+' / '+price.deliveryPeriod.endDate;
  }

  isValidUntil(price:Price){
    return this.priceV.isValidUntil(price);
  }

  getValidUntilText(price:Price){
    return this.priceV.getValidUntilText(price);
  }

  getQualityP(price:Price){
    if(this.isChild(price)){
      return '';
    }
    return this.QUALITY[price.quality];
  }

  getQualityIBM(price:Price){
    return this.priceV.getQualityIBM(price);
  }

  getDaysWayPay(price:Price){
    return getDaysWayPayText(price.wayPay);
  }

  getPercentageWayPay(price:Price){
    return getPercentageWayPay(price.wayPay);
  }

  getOnlyWayPayName(price:Price){
    return this.priceV.getOnlyWayPayName(price);
  }

  getPesificationP(price:Price){
    return this.priceV.getPesification(price);
  }

  reingresateCondition(price:Price){
    this.isComercial? createOrderWithPrice(price, this.store, this.router): this.createPriceWithPrice(price);
  }

  openModalPrice(price:Price){
    showSlidePriceDetails(this.store, price);
  }

  haveIndicatorsP(price:Price){
    return priceHaveIndicators(price);
  }

  isChild(price:Price){
    return false;
  }
}