<div class="info-to-fix gap-8">
    <span>{{getConditionType()}} |</span>
    <span>Periodo de fijación: {{getMacroFixationPeriodToFix()}}</span>
</div>

<div class="indicators">
    <div class="indicators" *ngFor="let indicator of getIndicators()">
        <div class="paso paso2">
            <app-data class="data" [title]="'Periodo de fijación'" [value]="getFixationPeriod(indicator)"></app-data>
            <app-data class="data" [title]="'Indicador'" [value]="getIndicatorName(indicator)"></app-data>
            <app-data class="data" [title]="'Calidad'" [value]="getQuality(indicator)"></app-data>
            <app-data class="data" [title]="'Plaza'" [value]="getPlace(indicator)"></app-data>
            <app-data class="data" [title]="'Fija por'" [value]="getProductAndPercentage(indicator)"></app-data>
            <app-data class="data" [title]="'Mes y año'" [value]="getMonthAndYear(indicator)"></app-data>
            <app-data class="data" [title]="'Rebaja'" [value]="getReduction(indicator)"></app-data>
            <app-data class="data" [title]="'Bonificación'" [value]="getBonus(indicator)"></app-data>
            <app-data class="data" [title]="'Mínimo y máximo'" [value]="getMinAndMax(indicator)"></app-data>
            <app-data class="data" [title]="'Proporcionalidad'" [value]="getProporcionalityDetails(indicator)"></app-data>
        </div>
    </div>
</div>