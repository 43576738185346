import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setEditAll, setValidationOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { PriceValidator } from 'src/app/shared/price';
import { FormComponent } from '../form.component';
import { SessionChecker } from 'src/app/shared/session.checker';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { CONDITIONS_TYPE_TO_FIX } from 'src/app/shared/dict/condition-and-indicators';
import { conditionType } from 'src/app/shared/const/options';

@Component({
  selector: 'app-form-validations',
  templateUrl: './form.component.html',
  styleUrls: ['./../form.component.scss']
})
export class FormValidationComponent extends FormComponent implements OnDestroy {
  indexToEdit:number=-1;
  orderToEdit!:Order;
  isGenerateToFixValidator:boolean=false;

  private orderToForm$!:Subscription;
  private generateToFix$!:Subscription;

  constructor(store:Store<appState>,orderExcV:OrderExchangeValidator,
     orderV:OrderValidator, priceV:PriceValidator,  dialog: MatDialog, checker:SessionChecker,
     private toFixV:ToFixValidator) { 
    super(store,checker, orderExcV,orderV, priceV, dialog);
    this.orderToForm$=this.store.select('order', 'edit').subscribe(edit=>{
      this.indexToEdit=edit.indexToEdit;
      this.orderToEdit=edit.ordersEdit[this.indexToEdit];
    })

    this.generateToFix$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe(conditionTypeSelected=>{
      this.isGenerateToFixValidator=conditionTypeSelected==conditionType.TO_FIX;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orderToForm$?.unsubscribe();
    this.generateToFix$?.unsubscribe();
  }

  getSellerNameAndTons(order:Order){
    return this.orderV.getSellerNameAndTons(order);
  }

  override deleteAll(numOfStep: number): void {
  }

  haveIndicators(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  override deleteStep(numOfStep:number){
    let order:Order=JSON.parse(JSON.stringify(this.orderToEdit));
    if(numOfStep==1){
      order.buyer= {onlySell: -1, notSell: -1};
      order.tons= -1;
      order.quotas= "[]";
    } else if(numOfStep==2){
      order.price.typeBusiness= -1;
      order.price.productName= -1;
      order.price.price=-1;
      order.price.typeCoin='--';
      order.price.placeOfDelivery={port: -1, zone: -1};
      order.price.quality= -1;
    } else if(numOfStep==3){
      order.price.deliveryPeriod= {endDate: '--', startDate: '--'};
      order.price.harvest='--';
      order.price.wayPay= WAY_PAY_VOID;
      order.price.pesificacion= {endDate: '--', startDate: '--'}
    } else if(numOfStep==4){
      order.price.conditionType=0;
      order.price.indicators=[];
    }
    this.store.dispatch(setValidationOrdersToGenerate({orders: [order]}));
  }

  showTypeDolar(order:Order){
    return this.orderV.isInDolars(order);
  }
}