import { Injectable } from "@angular/core";
/*import { Article12, Humidity, QualityParticularities } from "./interfaces/quality-particularities";
import { Price } from "./interfaces/price.interface";
import { PRODUCT_NAMES } from "./const/productNames";
import { QUALITIES } from "./const/quality.const";*/


@Injectable({
    providedIn: 'root',
})
export class QualityParticularitiesValidator {
    constructor() { }

    /*getQualityParticularities(qualityParticularities: QualityParticularities): string[] {
        try {
            let arrayToReturn: string[] = [];
            if (qualityParticularities.greenGrains.percentageReduction > 0) {
                arrayToReturn.push("Granos verdes");
            }

            if (qualityParticularities.demagedGrains.percentageReduction > 0) {
                arrayToReturn.push("Granos dañados");
            }

            if (this.getIsHumidity(qualityParticularities.humidity)) {
                arrayToReturn.push("Humedad");
            }

            if (qualityParticularities.volatileWaste > 0) {
                arrayToReturn.push("Marma volátil");
            }

            if (qualityParticularities.productVariety > 0) {
                arrayToReturn.push("Variedad de producto");
            }

            if (qualityParticularities.rejectGrade3) {
                arrayToReturn.push("Rechazo grado 3");
            }

            if (qualityParticularities.odorFree) {
                arrayToReturn.push("Libre de olores");
            }

            if (qualityParticularities.toxinsFree.length > 0) {
                arrayToReturn.push("Libre de toxinas");
            }

            if (qualityParticularities.hectoliterWeight.percentageReduction > 0) {
                arrayToReturn.push("Peso por hectolitro");
            }

            if (qualityParticularities.proteinContent.bonusProtein && qualityParticularities.proteinContent.lowerProtein) {
                arrayToReturn.push("Contenido proteíco");
            }

            if (this.getIsArticle12(qualityParticularities.article12)) {
                arrayToReturn.push("Articulo 12");
            }

            if (qualityParticularities.PH.start > 0 || qualityParticularities.PH.final > 0) {
                arrayToReturn.push("PH");
            }

            return arrayToReturn;
        } catch (e) {
            return [];
        }
    }

    getIsArticle12(article12:Article12){
        try{
            return article12.fusariumFree.start > 0 || article12.fusariumFree.final > 0 || 
            article12.GMOFree || 
            article12.W.start > 0 || article12.W.final > 0 || 
            article12.fallingNumber.start > 0 || article12.fallingNumber.final > 0 || 
            article12.gluten.bonus.range.start > 0 || article12.gluten.bonus.range.final > 0 || 
            article12.gluten.bonus.amount.quantity > 0 ||
            article12.gluten.receiptTolerance.start > 0 || article12.gluten.receiptTolerance.final > 0 ||
            article12.hectoliterWeightPerGrain.start > 0 || article12.hectoliterWeightPerGrain.final > 0 
        } catch(e){
            return false;
        }
    }

    getIsHumidity(humidity: Humidity) {
      try{
        return humidity.recepitTolerance.start > 0 || humidity.recepitTolerance.final > 0 ||
        humidity.dryingPoints > 0 ||
        humidity.pointsSpender > 0
      } catch(e){
        return false;
      }
    }

    getParticularitiesArrayFromPrice(price:Price):number[]{
        try{;
          let arrayToReturn:any=[];
          if(price.productName==PRODUCT_NAMES.SOJA){ //SOJA
            if(price.quality == QUALITIES.FABRICA){
              arrayToReturn=[1,2,3,4]
            }
          } else if (price.productName==PRODUCT_NAMES.MAIZ){ //MAIZ
            if(price.quality == QUALITIES.CAMARA){
              arrayToReturn=[3,4,5]
            } else if (price.quality==QUALITIES.GRADO_2){
              arrayToReturn=[2,3,4,5,6,7,8]
            }
          } else if (price.productName==PRODUCT_NAMES.TRIGO){ //TRIGO
            if(price.quality == QUALITIES.CAMARA){
              //HUMEDAD - PESO HECTOLITRICO - CONTENIDO PROTEICO - VARIEDAD DEL PRODUCTO - MERMA VOLATIL
              arrayToReturn=[3,4,5,9,10]
            } else if (price.quality==QUALITIES.GRADO_2){
              //HUMEDAD - PESO HECTOLITRICO - GRANOS DAÑADOS - VARIEDAD DEL PRODUCTO - MERMA VOLATIL 
              //- RECHAZA GRADO 3 - LIBRE DE OLOR- ARTICULO 12
              arrayToReturn=[3,9,2,5,4,6,7,11]
            }
          } else if (price.productName==PRODUCT_NAMES.GIRASOL){ //GIRASOL
            if(price.quality == QUALITIES.CAMARA){
              arrayToReturn=[3,4]
            }
          } else if (price.productName==PRODUCT_NAMES.SORGO){ //SORGO
            if(price.quality == QUALITIES.CAMARA){
              //HUMEDAD - MERMA VOLATIL- VARIEDAD DEL PRODUCTO
              arrayToReturn=[3,4,5]
            } else if (price.quality==QUALITIES.GRADO_2){
              //HUMEDAD-GRANOS DAÑADOS- RECHAZA GRADO 3- MERMA VOLATIL- VARIEDAD DEL PRODUCTO- LIBRE DE OLOR - LIBRE DE TOXINAS
              arrayToReturn=[3,2,6,4,5,7,8]
            }
          } else if (price.productName==PRODUCT_NAMES.CEBADA_CERVECERA){ //CEBADA CERVECERA
            if(price.quality==QUALITIES.CAMARA){
              //HUMEDAD - MERMA VOLATIL - VARIEDAD DEL PRODUCTO - PASA A FORRAJERA
              arrayToReturn=[3,4,5] //Falta pasa a forrajera
            }
          } else if (price.productName==PRODUCT_NAMES.CEBADA_FORRAJERA){ //CEBADA FORRAJERA
            if(price.quality==QUALITIES.CAMARA){
              //HUMEDAD - PH
              arrayToReturn=[3,12]
            } else if(price.quality==QUALITIES.GRADO_2){
              //PH
              arrayToReturn=[12]
            }
          }
          return arrayToReturn;
        }catch(err){
          return []
        }
      }*/

}