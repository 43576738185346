import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AMOUNT_VOID } from 'src/app/shared/const/business-particularities';
import { typeCoinsStr } from 'src/app/shared/dict/dict';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Amount } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { Field } from '../../fields.component';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss', './../../fields.component.scss']
})
export class AmountComponent {
   @Input({required: true}) amount:Amount = AMOUNT_VOID;
   array:any[]=[];
    OBJECT:any={};
   @Output() amountToSave = new EventEmitter<string>();

   
  constructor(private validator:ValidatorChecker) {
    this.array = Object.keys(typeCoinsStr);
    this.array.shift();
    this.OBJECT=typeCoinsStr;
  }

  ngOnInit(): void {
  }

  emit(amount:Amount){
    this.amountToSave.emit(JSON.stringify(amount));
    this.amount=amount;
  }

  setTypeCoinAmount(event: string) {
    console.log(event);
    let amount=JSON.parse(JSON.stringify(this.amount));
    amount.typeCoin = event;
    this.emit(amount);
  }
  
  setMontAmount(event:any){
    let amount=JSON.parse(JSON.stringify(this.amount));
    amount.quantity=this.validator.validatorNumber(event);
    this.emit(amount);
  }

  getOptions(){
    return [
      {value:'USD', name:'Dólares'},
      {value:'ARS', name:'Pesos'},
    ]
  }
  
}