import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setHeaderInMobile } from 'src/app/redux/actions/options.action';
import { setPriceToGenerate, showPriceDetails } from 'src/app/redux/actions/price.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { COMMODITIES_CODE } from 'src/app/shared/const/buyers';
import { priceTags, PRICE_STATUS_CONST } from 'src/app/shared/const/prices.const';
import { priceState } from 'src/app/shared/dict/dict';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';
import { DetailsEyeComponent } from '../details-eye.component';
import { getStatusOfPrice } from 'src/app/components/board/mobile-price/mobile-price.component';
import { ToFixValidator, priceHaveIndicators } from 'src/app/shared/to-fix';

@Component({
  selector: 'app-price-eye',
  templateUrl: './price.component.html',
  styleUrls: ['./../details-eye.component.scss', './../../slide-card.scss']
})
export class PriceComponent extends DetailsEyeComponent implements OnInit, OnDestroy {
  @Input() price!: Price;
  STATUS_PRICE_CONST = PRICE_STATUS_CONST;
  isToFix:boolean=false;

  constructor(store: Store<appState>, toFixV:ToFixValidator, private priceV: PriceValidator, private router: Router) {
    super(store, toFixV)
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.isToFix=priceHaveIndicators(this.price);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  override close(): void {
    this.store.dispatch(showPriceDetails({ show: false }));
    this.clickInClose = true;
    this.count = 0;
  };

  override open(): void {
    this.store.dispatch(showPriceDetails({ show: true }));
  }

  override showButtonChangeState() {
    return !this.isComercial;
  }

  override isDisabledButtonStatus() {
    return this.statusSelected == 0;
  }
  override applyNewStatus() {
    if (!this.isDisabledButtonStatus()) {
      this.priceV.editPrice(this.price, priceTags.status, this.statusSelected, this.user);
      this.close();
    }
  }

  editPrice() {
    this.store.dispatch(setHeaderInMobile({ header: 'Editar condición' }));
    this.store.dispatch(setPriceToGenerate({ price: this.price }));
    this.router.navigate(['/' + NAVEGATION.GENERATE_PRICE]);
    this.close();
  }

  getBuyer() {
    return this.price.observations.buyer == COMMODITIES_CODE? 'Commodities' : 'Comprador';
  }
}