import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { catchError, Subscription } from 'rxjs';
import { setErrorStruct, setHeaderInMobile, setLocation } from 'src/app/redux/actions/options.action';
import { loadOrdersAllPrevius, loadOrdersAllSuccess, loadOrdersAllWithPipesSuccess, setOrdersSelected } from 'src/app/redux/actions/order.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';
import { Order, OrderBoard } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { getOrderListWithPipes } from 'src/app/shared/order';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { SessionChecker } from 'src/app/shared/session.checker';
import { WebSocketFunctions } from 'src/app/shared/websocket/websocket';
import { loadAllOrdersSuccess } from 'src/app/redux/actions/dashboard.action';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['../../shared/styles/body.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush

})
export class OrderListComponent extends WebSocketFunctions implements OnInit, OnDestroy{
  public user!:User;
  private orders:Order[]=[];
  private ordersSelected:Order[]=[];
  public title:string = "Listado de ordenes";

  //Filters
  orderBoard!:OrderBoard;
  conditionTypeSelected:string='';
  search:string='';
  conditionFilter:number[]=[];

  private user$!:Subscription;
  private order$!:Subscription;
  private conditionType$!:Subscription;
  private title$!:Subscription;
  private search$!:Subscription;
  private conditionFilter$!:Subscription;

  constructor(private store: Store<appState>,
    private checker: SessionChecker, private orderSvc:OrderService, private dateC:DateChecker, maestroSvc:MaestrosService) {
      super(environment.rutas.websocket.orders, maestroSvc);
    this.loadOrders();
    this.checker.rememberAndActualizateUser(this.store);

    this.store.dispatch(setLocation({location: NAVEGATION.ORDER_BOARD}));
    this.store.dispatch(setHeaderInMobile({header: "Listado de ordenes"}));
  }

  setSubscriptions(){
    this.user$=this.store.select('usuario').subscribe( (usuario:User) => {
      if(this.user?.area!=usuario.area){
        this.user = usuario;
        this.setOrders(this.orders ?? [])
      }
      this.user = usuario;
    })

    this.order$=this.store.select('order').subscribe( (orderR) => {
      this.orders = orderR.orderBoard.all;
      this.ordersSelected= orderR.orderBoard.ordersSelected;

      //Filters
      //En caso de producirse un cambio en los filtros, se actualiza el listado de ordenes
      if(this.orderBoard?.filter != orderR.orderBoard.filter || 
        this.orderBoard?.buyerType != orderR.orderBoard.buyerType){
        this.orderBoard=orderR.orderBoard;
        this.setOrders(this.orders);
      }
    });

    this.title$=this.store.select('options', 'headerInMobile').subscribe(headerInMobile=>{
      this.title = headerInMobile;
    });

    this.conditionType$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe(conditionType=>{
      //En caso de producirse un cambio en el filtro de busqueda, se actualiza el listado de ordenes
      if(this.conditionTypeSelected != conditionType){
        this.conditionTypeSelected= conditionType;
        this.setOrders(this.orders);
      }
    });

    this.search$=this.store.select('options', 'board', 'search').subscribe(search=>{
      //En caso de producirse un cambio en el filtro de busqueda, se actualiza el listado de ordenes
      if(this.search != search){
        this.search = search;
        this.setOrders(this.orders);
      }
    });

    this.conditionFilter$=this.store.select('options', 'board', 'conditionFilter').subscribe(conditionFilter=>{
      //En caso de producirse un cambio en el filtro de busqueda, se actualiza el listado de ordenes
        this.conditionFilter = conditionFilter;
        this.setOrders(this.orders);
    });

  }
 
  ngOnInit(): void {
    this.setSubscriptions();
    this.checkTables();
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.order$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.title$?.unsubscribe();
    this.search$?.unsubscribe();
    this.conditionFilter$?.unsubscribe();
    this.closeConnection();
  }


  override functionToMessage(event: any): void {
    console.log(JSON.parse(event.data))
    if(event.data !== JSON.stringify(this.orders)){
      const orders=JSON.parse(event.data);
      let almacenamiento= !orders? []: orders;
      localStorage.setItem(localStorageNames.ordersAll, JSON.stringify(almacenamiento));

      this.store.dispatch(loadOrdersAllPrevius({orders: this.count==0? almacenamiento: JSON.parse(JSON.stringify(this.orders))}));
      this.count++;

      this.store.dispatch(loadOrdersAllSuccess({orders: almacenamiento}));
      
      this.setOrders(almacenamiento)
      setTimeout(() =>{
        this.store.dispatch(loadOrdersAllPrevius({orders: JSON.parse(JSON.stringify(almacenamiento))}));
      },3000); //3 segundos dura la animación
    }
  }

  //Esto es lo mismo que está en el panel de control.
  loadOrders(){
    const date=this.dateC.getDateInYYYYMMDDformat(this.dateC.stringToDate(this.dateC.getDate()));
    this.orderSvc.getOrdersByDate(date).pipe(
      catchError((error) => {
        console.error(error.error);
        this.store.dispatch(loadAllOrdersSuccess({orderList: []}));
        this.store.dispatch(loadOrdersAllWithPipesSuccess({orders: []}));
        this.store.dispatch(setErrorStruct({error: {color: '#fff', isVisible:true, message: 'No hay datos para mostrar'}}));
        return [];
      })
    ).subscribe( (res: ResponseRequest) => {
      let almacenamiento:Order[]= !res.data? []: res.data;
      localStorage.setItem(localStorageNames.ordersAll, JSON.stringify(almacenamiento));
      this.store.dispatch(loadOrdersAllSuccess({orders: almacenamiento}));

      this.setOrders(almacenamiento)
    })
  }

  /* This function create an orderList with Pipes */
  getOrderListWithPipes(orders:Order[]){
    try{
      return getOrderListWithPipes(orders, this.user, this.orderBoard.filter, 
        this.search, this.orderBoard.buyerType, this.conditionTypeSelected, this.conditionFilter)
    } catch (error) {
      return orders;
    }
  }

  setOrders(orders:Order[]){
    this.store.dispatch(loadOrdersAllWithPipesSuccess({orders: this.getOrderListWithPipes(orders)}))
  }
}