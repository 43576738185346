import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { FilterOrderModule } from 'src/app/components/filters/filter-order/filters.module';
import { MessageSvgModule } from 'src/app/shared/styles/svg/message/message.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EyeSvgModule } from 'src/app/shared/styles/svg/eye/eye.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { DialogYesOrNoModule } from 'src/app/components/dialogs/dialog-yes-or-no/dialog-yes-or-no.module';
import { ObservationsOrderSvgModule } from 'src/app/shared/styles/svg/observations-order/observations-order.module';
import { ExchangeTableComponent } from './exchange-table.component';
import { ComercialComponent } from './comercial/comercial.component';
import { TableMobileExchangeModule } from 'src/app/components/board/mobile-exchange/mobile-exchange.module';

@NgModule({
  declarations: [
    ExchangeTableComponent,
    ComercialComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    FilterOrderModule,
    DialogYesOrNoModule,
    TableMobileExchangeModule,

    PipesModule,

    //SVG
    ThreePointsSvgModule, ArrowSvgModule,
    FilterBoardSvgModule, MessageSvgModule,
    EyeSvgModule,
    PipesModule,
    ObservationsOrderSvgModule
  ], exports:[
    ExchangeTableComponent,
    ComercialComponent
  ]
})
export class ExchangeTableModule { }