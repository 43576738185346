import { Component,Input,HostListener,Output,EventEmitter, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { typeCoins } from 'src/app/shared/const/prices.const';
import { DateChecker, getDaysBetweenDates } from 'src/app/shared/date.checker';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Deferral } from 'src/app/shared/interfaces/daferral.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { PriceValidator } from 'src/app/shared/price';

const daysInYear=365;

@Component({
  selector: 'create-deferral',
  templateUrl: './deferral.component.html',
  styleUrls: ['./deferral.component.scss']
})
export class DeferralComponent implements OnInit, OnDestroy{
  valuations: Deferral[] = [{
    days:0,
    amount:0,
    tasa:0
  }]
  @Input() price!:Price;
  public user!:User;
  public isShowInformation:boolean=false;

  //Detect clicks
  @Output() action = new EventEmitter<string>();
  wasInside:boolean = false;
  count:number=0;
  
  emit(value:string){
    this.action.emit(value); //'open' or 'close'
  }

  private userSubscription!:Subscription;
  constructor(private store:Store<appState>, private date:DateChecker, private priceV:PriceValidator) {}

  ngOnInit(): void {
    this.wasInside=true;
    
    this.userSubscription=this.store.select('usuario').subscribe(usuario=>{
      this.user=usuario;
    })
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  showInformation(boolean:boolean){
    this.isShowInformation=boolean;
  }

  setElement(event:any, index:number, tag:string){
    const valueToSet=Number(event); //Get value from input
    let newDaferral:any= this.valuations[index]; //Parse valuation
    newDaferral[tag]=valueToSet; //Set value to valuation
    this.valuations[index]=newDaferral; //Set valuation to array

    if(tag=='days' || tag=='tasa'){
      newDaferral.amount=this.calculateAmount(index);
    } else if (tag=='amount'){
      newDaferral.days=this.calculateDays(index);
    }
    this.valuations[index]=newDaferral; //Set valuation to array
  }

  calculateAmount(index:number){
    let newDaferral:any= this.valuations[index]; //Parse valuation
    const days=newDaferral.days;
    const tasa=newDaferral.tasa;
    let amount=newDaferral.amount;

    if(this.price.typeCoin==typeCoins.USD){
      const priceUSD=this.price.price;
      const dolarPosition=280;
      amount = ( ( (priceUSD * dolarPosition * (tasa/100))  /  dolarPosition)  /   daysInYear *days   );
    } else if(this.price.typeCoin==typeCoins.ARS){
      const priceARS=this.price.price;
      amount = (priceARS * (tasa/100) ) / daysInYear*days;
    }
    return amount; //Set value to valuation
  }

  calculateDays(index:number){
    let newDaferral:any= this.valuations[index]; //Parse valuation
    const amount=newDaferral.amount;
    const tasa=newDaferral.tasa;
    let days=newDaferral.days;

    if(this.price.typeCoin==typeCoins.USD){
      const priceUSD=this.price.price;
      const dolarPosition=280;
      days = (dolarPosition / ((priceUSD * dolarPosition * (tasa/100) ) / daysInYear)) * amount 
    } else if(this.price.typeCoin==typeCoins.ARS){
      const priceARS=this.price.price;
      days = (1/( priceARS * (tasa/100) / daysInYear)) * amount
    }

    return days; //Set value to valuation
  }

  addDeferral(){
    this.valuations.push({
      days:0,
      amount:0,
      tasa:0
    })
  }

  getIsButtonDisabled(){
    try{
      return this.valuations.filter(deferral=>this.isDeferralValid(deferral)).length==0;
    } catch(err){
      return true;
    }
  }

  isDeferralValid(deferral:Deferral){
    return deferral.amount>0 && deferral.days>0 && deferral.tasa>0;
  }

  /* With amount, a price is assigned (adding the one it already had).
- The wayPayName is transformed into deferred pesification (if the price is in dollars) or billing (if the price is in pesos)
- With wayPay.isBusinessDays, it is set to 1 only when it was already businessDays==1 and had no expiration.
- With wayPay.expiration, if there was no expiration, it remains the same.
If it already had expiration, that date is taken and the days of the deferral are added.
- With waypay.days, if the new wayPay.isBusinessDays == 1 and has no expiration, a conversion table is used, otherwise, if it had an expiration, the difference between the base date and the new expiration is calculated.
The result of both is added to the wayPay.days*/ 
getNewPrice(deferral:Deferral){
    const newPrice:Price=JSON.parse(JSON.stringify(this.price));

    //We round down
    const amountParsed=Math.floor(deferral.amount);
    //We round up
    const daysParsed=Math.ceil(deferral.days);

    //We add the amount to the price
    newPrice.price+=amountParsed;

    //We transformed into
    if(newPrice.typeCoin==typeCoins.USD){
      newPrice.wayPay.wayPayName=wayPayNames.diferidoPesificacion;
    } else if(newPrice.typeCoin==typeCoins.ARS){
      newPrice.wayPay.wayPayName=wayPayNames.diferidoFacturacion;
    }

    //We set isBusinessDays
    newPrice.wayPay.isBusinessDays= newPrice.wayPay.isBusinessDays==1 && newPrice.wayPay.expiration=='--' ? 1 : 0;

    //We set expiration
    if(newPrice.wayPay.expiration!='--'){
      newPrice.wayPay.expiration=this.date.addDaysToDate(newPrice.wayPay.expiration, daysParsed);
    }

    //We set days
    if(newPrice.wayPay.isBusinessDays==1){
      //Tabla de conversión
      newPrice.wayPay.days+=daysParsed;
    } else {
      newPrice.wayPay.days=getDaysBetweenDates(newPrice.deliveryPeriod.endDate, newPrice.wayPay.expiration);
    }

    return newPrice;
  }



  save(){
    for(let i=0; i<this.valuations.length; i++){
      const newPrice=this.getNewPrice(this.valuations[i]);

      //Save new price
      this.priceV.duplicatePrice(newPrice, this.user);
    }
    this.emit('close')
  }

  @HostListener('click') clickInside() {
    this.emit('open');
    this.wasInside = true;
  }

  @HostListener('document:click') clickout() {
    if(this.wasInside) this.count++;

    if (!this.wasInside) {
     if(this.count>0){
        this.emit('close');
      }
      this.count=0;
    }
    this.wasInside = false;
  }

}
