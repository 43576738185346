import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataIBM } from '../interfaces/DataIBM';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private http: HttpClient) {}

  createContract(contract: DataIBM) {
    return this.http.post<any>(environment.rutas.ibm.createContract, contract);
  }
}