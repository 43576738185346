import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-calendar-inline',
  templateUrl: './date-calendar-inline.component.html',
  styleUrls: ['./date-calendar-inline.component.scss']
})
export class DateCalendarInlineComponent implements OnInit {
  @Input() isRange=false;

  @Input() datesRangesStruct={
    startDate: null,
    endDate: null
  }

  @Input() dateStruct:any= null;

  date!:any;

  // DateRange instance using range
  dateRange!:any;
  countOfDates = 0;
  
  @Output() valueOut = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if(this.isRange){
      this.dateRange= new DateRange(this.datesRangesStruct.startDate, this.datesRangesStruct.endDate);
    } else {
      this.date= this.dateStruct? new Date(): new Date(this.dateStruct);
    }
  }

  selectedDateRange(event:any){
    if(this.countOfDates==0){
      this.countOfDates=1;
      this.datesRangesStruct.startDate=event;
      this.datesRangesStruct.endDate=null;
    } else if (this.countOfDates==1){
      this.countOfDates=0;
      this.datesRangesStruct.endDate=event;
    } 
    
    this.dateRange=new DateRange(this.datesRangesStruct.startDate, this.datesRangesStruct.endDate);
    
    this.valueOut.emit(this.datesRangesStruct)
  }

  selectedDateSingle(event:any){
    this.dateStruct=event;
    this.date=new Date(this.dateStruct);
    this.valueOut.emit(this.dateStruct)
  }





}
