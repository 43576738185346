import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardIndicatorsComponent } from './indicators.component';
import { CopyGreenSvgModule } from 'src/app/shared/styles/svg/copy-green/copy-green-svg.module';
import { EyeGreenSvgModule } from 'src/app/shared/styles/svg/eye-green/eye-green-svg.module';
import { PlusSignGreenSvgModule } from 'src/app/shared/styles/svg/plus-sign-green/plus-sign-green.module';
import { StatusTagModule } from '../../tags/status/status.module';

@NgModule({
  declarations: [
    CardIndicatorsComponent
  ],
  imports: [
    CommonModule,
    StatusTagModule,

    CopyGreenSvgModule,
    EyeGreenSvgModule,
    PlusSignGreenSvgModule
  ], exports:[
    CardIndicatorsComponent
  ]
})
export class CardIndicatorsModule { }
