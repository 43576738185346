import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetViewSelectedOrderExchange, setAllViewsOrderExchange, setFilterOrderExchange, setIndexViewSelectedOrderExchange, setViewSelectedOrderExchange } from 'src/app/redux/actions/exchange.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { VIEW_ORDER_EXCHANGE_ALL, VIEW_ORDER_EXCHANGE_FIELDS, VIEW_ORDER_EXCHANGE_VOID } from 'src/app/shared/const/views.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ViewOrderExchange } from 'src/app/shared/interfaces/views.interface';
import { OrderString } from 'src/app/shared/interfaces/order.interface';
import { ViewStructOrder } from '../view-order';

@Component({
  selector: 'views-orders-exchange',
  templateUrl: './views-orders.component.html',
  styleUrls: ['./../views.component.scss']
})
export class ViewsOrdersComponent extends ViewStructOrder implements OnInit, OnDestroy {
  @Input() override view: ViewOrderExchange = VIEW_ORDER_EXCHANGE_VOID
  @Input() override arrayOfViews: ViewOrderExchange[] = [];
  VIEW_ORDER_EXCHANGE_FIELDS = VIEW_ORDER_EXCHANGE_FIELDS;

  private exchange$!:Subscription;

  constructor(store: Store<appState>) {
    super(store);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.exchange$=this.store.select('exchange').subscribe(exReducer => {
      this.arrayOfViews = exReducer.exchangeBoard.viewsOfExchangeBoard.views;
      this.indexToEditInStore = exReducer.exchangeBoard.viewsOfExchangeBoard.indexViewSelected;
        if(this.indexToEditInStore==this.indexToEdit) this.wasInside=true;
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.exchange$?.unsubscribe();
  }

  override getViewAll(): ViewOrderExchange {
    let newView=JSON.parse(JSON.stringify(VIEW_ORDER_EXCHANGE_ALL));
    delete newView.viewName;
    delete newView.orderString;
    delete newView.isHistorical;
    return newView;
  }

  override getViewVoid(): ViewOrderExchange {
    let newView=JSON.parse(JSON.stringify(VIEW_ORDER_EXCHANGE_VOID));
    delete newView.viewName;
    delete newView.orderString;
    delete newView.isHistorical;
    return newView;
  }

  override setIndexViewSelected(index: number): void {
    this.store.dispatch(setIndexViewSelectedOrderExchange({ index: index }));
  }

  override setInStore(views: ViewOrderExchange[], index: number, view?: ViewOrderExchange, filter?: OrderString) {
    this.store.dispatch(setAllViewsOrderExchange({ views: views }));
    this.store.dispatch(view? setViewSelectedOrderExchange({ view: view }): resetViewSelectedOrderExchange());
    this.store.dispatch(setIndexViewSelectedOrderExchange({ index: index }));
    filter? this.store.dispatch(setFilterOrderExchange({orderString: filter})): '';

    this.saveInDB(views);
  }
  
  override getLocalStorageName(): string {
    return localStorageNames.viewExchange;
  }
}
