import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BodyBoard } from 'src/app/components/board/body-board';
import { setAddExchange, setExchangeConfirmed, showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { DataChecker } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderValidator } from 'src/app/shared/order';
import { getSumOfTonsExchange } from 'src/app/shared/order-exchange';

@Component({
  selector: 'app-body-exchange-list',
  templateUrl: './body-exchange-list.component.html',
  styleUrls: ['./body-exchange-list.component.scss', './../../../shared/styles/body-board.scss']
})
export class BodyExchangeListComponent extends BodyBoard implements OnInit, OnDestroy {
  ordersAll:OrderExchange[]=[];
  ordersSelected!:OrderExchange[];

  orderFilter:any;  

  // ---- Options ---- \\
  isShowDetails:boolean=false;
  showAddExchange:boolean=false;
  typeModal:number=0;

  showExchangedConfirmed: boolean = false;

  private typeModal$!:Subscription;
  private ordersAll$!:Subscription;
  private showAddExchange$!:Subscription;
  private showExchangeConfirmed$!:Subscription;

  constructor(store: Store<appState>, private router:Router, private dataChecker: DataChecker, private orderV:OrderValidator, cdr:ChangeDetectorRef) {
    super(store, cdr);
    this.rememberOrdersExchangeOfLocalStorage();
  }

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(){
    this.ordersAll$?.unsubscribe();
    this.typeModal$?.unsubscribe();
    this.showAddExchange$?.unsubscribe()
    this.showExchangeConfirmed$?.unsubscribe()
    this.setBodyUnsubscription();
  }

  setSubscriptions(){
    this.ordersAll$=this.store.select('exchange','exchangeBoard','all').subscribe(orders=>{
      this.ordersAll=orders;
    });

    this.showDetails$=this.store.select('exchange','exchangeBoard','showOrderDetails').subscribe(show=>{
      this.isShowDetails=show;
    });

    this.showAddExchange$=this.store.select('exchange','exchangeBoard','showAddExchange').subscribe(show=>{
      this.showAddExchange=show;
    });
    
    this.selected$=this.store.select('exchange','exchangeBoard','ordersSelected').subscribe(orders=>{
      this.ordersSelected=orders;
    });

    this.filter$= this.store.select('exchange','exchangeBoard','filter').subscribe(filter=>{
      this.orderFilter=filter;
    });

    this.showExchangeConfirmed$=this.store.select('exchange','exchangeConfirmed','showConfirmed').subscribe(confirmed=>{
      this.showExchangedConfirmed=confirmed;
    })

    this.typeModal$=this.store.select('options','modalContract','typeModal').subscribe(typeModal=>{
      this.typeModal=typeModal;
    })

    this.setBodySubscription();
  }
  
  rememberOrdersExchangeOfLocalStorage(){
    this.store.dispatch(this.dataChecker.rememberOrderExchangeList());
  }

  isOrdersSelectedStatus(ordersSelected:OrderExchange[], status:number){
    return ordersSelected.length<=0 || !this.orderV.isOrdersSelectedStatus(ordersSelected, status);
  }

  goToGenerateExchange(){
    this.router.navigate(["/"+NAVEGATION.GENERATE_EXCHANGE]);
  }

  //---- DETAILS ----\\
  closeDetails(){
    this.store.dispatch(showOrderExchangeDetails({show:false}));

    this.store.dispatch(setExchangeConfirmed({exchangedConfirmed: {confirmed: false,
    message: '', showConfirmed:false}}));

    this.store.dispatch(setAddExchange({addExchange: false}))
  }

  getQuantOfTons(orders:OrderExchange[]){
    return getSumOfTonsExchange(orders);
  }
}
