
<div class="background-svg">
    
    <svg class="svg-30" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path [ngClass]="{'background-white': active}" d="M0 15.5C0 7.21573 6.71573 0.5 15 0.5C23.2843 0.5 30 7.21573 30 15.5C30 23.7843 23.2843 30.5 15 30.5C6.71573 30.5 0 23.7843 0 15.5Z" fill="#F2EBD7"/>
        <path [ngClass]="{'blue-fill':active}" d="M19.6364 7H10.3636C9.33931 7.00123 8.35729 7.40868 7.63299 8.13298C6.90868 8.85728 6.50123 9.83929 6.5 10.8636V17.0454C6.50112 17.9357 6.80916 18.7984 7.37219 19.4882C7.93522 20.1779 8.71882 20.6524 9.59091 20.8317V23.2272C9.59089 23.3671 9.62885 23.5043 9.70073 23.6243C9.77262 23.7444 9.87574 23.8426 9.99909 23.9086C10.1224 23.9746 10.2614 24.0059 10.4011 23.9991C10.5409 23.9923 10.6761 23.9477 10.7925 23.8701L15.2318 20.909H19.6364C20.6607 20.9078 21.6427 20.5003 22.367 19.776C23.0913 19.0517 23.4988 18.0697 23.5 17.0454V10.8636C23.4988 9.83929 23.0913 8.85728 22.367 8.13298C21.6427 7.40868 20.6607 7.00123 19.6364 7ZM18.0909 16.2727H11.9091C11.7042 16.2727 11.5076 16.1913 11.3627 16.0463C11.2178 15.9014 11.1364 15.7049 11.1364 15.4999C11.1364 15.295 11.2178 15.0985 11.3627 14.9535C11.5076 14.8086 11.7042 14.7272 11.9091 14.7272H18.0909C18.2958 14.7272 18.4924 14.8086 18.6373 14.9535C18.7822 15.0985 18.8636 15.295 18.8636 15.4999C18.8636 15.7049 18.7822 15.9014 18.6373 16.0463C18.4924 16.1913 18.2958 16.2727 18.0909 16.2727ZM19.6364 13.1818H10.3636C10.1587 13.1818 9.96215 13.1004 9.81724 12.9555C9.67232 12.8105 9.59091 12.614 9.59091 12.4091C9.59091 12.2041 9.67232 12.0076 9.81724 11.8627C9.96215 11.7177 10.1587 11.6363 10.3636 11.6363H19.6364C19.8413 11.6363 20.0379 11.7177 20.1828 11.8627C20.3277 12.0076 20.4091 12.2041 20.4091 12.4091C20.4091 12.614 20.3277 12.8105 20.1828 12.9555C20.0379 13.1004 19.8413 13.1818 19.6364 13.1818Z" fill="#ADBCCE"/>
    </svg>
        

    <div class="tooltip row-center w-60 down" style="z-index:1;">
        <span>Comentario</span>
    </div>
</div>