import { Component, OnInit,HostListener,OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { dateByBusinessDays, getDate } from 'src/app/shared/date.checker';
import {
  wayPay,
  wayPayNames,
} from 'src/app/shared/dict/wayToPay';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderValidator } from 'src/app/shared/order';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { PriceValidator } from 'src/app/shared/price';
import { WayPayValidator, getWayPayDefault, getWayPayFromExchange, getWayPaysValids } from 'src/app/shared/way-pay';
import { Field, isPriceOrExchange } from '../fields.component';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { WayPay } from 'src/app/shared/interfaces/price.interface';
import { businessDict } from 'src/app/shared/dict/typeBusiness';

@Component({
  selector: 'app-way-pay',
  templateUrl: './way-pay.component.html',
  styleUrls: ['./../fields.component.scss'],
})
export class WayPayComponent extends Field implements OnInit, OnDestroy {
  public WAY_PAY_CONST=wayPayNames;
  showSubOptions: boolean = false;
  isSyngentaAgroAvc: boolean = false;
  @Input() isGenerateToFix:boolean=false;
  @Input() isInvalidAux:boolean=false;
  @Input() wayPayDefault!:WayPay; //Only utilized in the exchange

  private store$!:Subscription;
  private exchangeWayPay$!:Subscription;

  constructor(store: Store<appState>, private orderV:OrderValidator, private priceV:PriceValidator, private wayPayV:WayPayValidator) {
    super(store);
    this.store$=this.store.subscribe((state) => {
      setTimeout( () => {
        this.array = this.getWayToPay();
      },300);
    });

    this.exchangeWayPay$=this.store.select('exchange').subscribe(exchange=>{
      const sellerCode=exchange.generateOrder.sellerData.codeS;//exchange.generateOrder.exchange.buyer.codeS;
      const subdivision=exchange.generateOrder.price.businessParticularities.subdivisionSyngenta;
      const isAVC=exchange.generateOrder.price.businessParticularities.isAVC;
      this.isSyngentaAgroAvc=this.orderV.isSyngentaAgroAvc(sellerCode, subdivision, isAVC)
    });

    this.OBJECT = wayPay;
    this.initalElementSelected = 'Ingresar una forma de pago';
    this.pipe = new FilterByObjectPipe();
  }

  ngOnInit(): void {
    this.resetWayPayTags();
    this.setIdentifyClassName();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$?.unsubscribe();
    this.exchangeWayPay$?.unsubscribe();
  }

  resetWayPayTags() {
    if (isPriceOrExchange(this.fieldIdentificator)) {
      this.tag = priceTags.wayPay;
      this.subTag = priceTags.wayPayName;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.wayPay;
      this.thirdTag = priceTags.wayPayName;
    }
  }

  override rememberValueInForm() {
    this.rememberWayPay();
  }

  override setElement(element: any, index: number) {
    this.setWayPayOptions(Number(element)); //Seteamos la forma de pago
    this.setPercentage(); //Seteamos el porcentaje
    if(this.isGenerateCondition()){
      this.checkWayPayFixExpiration();
    }

    this.setChangeShowOptions(false); //Cambiamos el estado de la suboptions

    this.setTag(priceTags.wayPayName); //Reiniciamos la tag
  }

  setWayPayOptions(wayPayName:number) {
    const wayPay= getWayPayDefault(this.getPrice(), wayPayName)
    this.setTag('');
    this.setElementToGenerate(wayPay);
  }

  setOptionField(option:number){
    this.setTag(priceTags.wayPayOptions);
    this.setElementToGenerate(option);

    //Tambien calculamos el vencimiento
    this.setTag(priceTags.wayPayExpiration);
    const days:number=this.getWayPay()?.days ?? 5;
    const isBusinessDays=this.getWayPay()?.isBusinessDays == 1;
    this.setElementToGenerate(dateByBusinessDays(getDate(), days, isBusinessDays));
  }

  getWayToPay() {
    try {
      //Si no tiene periodo de entrega, no se pueden cargar
      //Si la variable isGenerateToFix es true, significa que está generando una condición a fijar
      //Sino, es una condición a precio y lleva ciertas condiciones para saber sus posibles formas de pago
      return this.isGenerateToFix? [wayPayNames.contraFixMercaderiaEntregada, wayPayNames.contraFixCartaGarantia]:
      this.fieldIdentificator==fieldType.EXCHANGE? getWayPayFromExchange(this.getTypeCoinExchange() ?? ''):
      getWayPaysValids(this.getPrice());
    } catch (err) {}
    return [];
  }

  getIsOption(option:number){
    return this.getWayPay()?.options==option;
  }

  rememberWayPay() {
    this.setTag(priceTags.wayPayName);
    let valueToSet = this.rememberValue();
    let tagSelected = isPriceOrExchange(this.fieldIdentificator) ? this.subTag : this.thirdTag;

    if (valueToSet != -1 && this.elementToGenerate && tagSelected == priceTags.wayPayName) {
      this.elementSelected = this.OBJECT[valueToSet];
      setTimeout( () => { this.isInvalid = this.isWayPayInvalid() }, 200)

      this.changeOptionSelected();
      this.search=this.elementSelected;
      this.setPlaceHolder();
    } else if(priceTags.wayPayName==tagSelected){
      this.elementSelected = '';
      this.isInvalid=false;
      this.search='';
      this.setIsOptionSelected(false);
      this.setPlaceHolder();
    }
  }

  checkWayPayFixExpiration(){
    const price=this.getPrice();
    const isForward= price?.typeBusiness == businessDict.forward;
    const isFechaFija= this.wayPayV.getIsFechaFija(price);
    if(isForward && isFechaFija){
      this.setTag(priceTags.wayPayOptions);
      this.setElementToGenerate(1);
    }
  }

  getWayPay():WayPay|null {
    try{
      return isPriceOrExchange(this.fieldIdentificator) ? this.elementToGenerate.wayPay : this.elementToGenerate.price.wayPay;
    } catch(err){}
    return null;
  }

  isWayPayInvalid():boolean{
    if(this.fieldIdentificator==fieldType.EXCHANGE ){
      const wayPay=this.getWayPay();
      const typeCoin=this.getTypeCoinExchange();
      return wayPay && typeCoin ? this.wayPayV.getIBMCode(wayPay, typeCoin) == 0: false;
    } else {
      return this.getPrice() && this.priceV.getWayPayIBM(this.getPrice()) == 0;
    }
  }

  /**
   * Gets the 'typeCoin' field from a structure of type Exchange
   * @returns {string | null} The value of the 'typeCoin' field
   * */
  getTypeCoinExchange(): string | null{
    return this.fieldIdentificator== fieldType.EXCHANGE? this.elementToGenerate?.typeCoin ??null :null;
  }

  setPercentage(){
    if(fieldType.ORDER==this.fieldIdentificator && this.isSyngentaAgroAvc){
      this.setTag(priceTags.wayPayPercentage);
      this.setElementToGenerate(100);
    }
  }

  //DETECT CLICK
  @HostListener('document:click', ['$event']) 
  override clickInside(event: any) {
    try {
      const classNames: string = event.target.classList.value;
      if (this.showOptions) this.count = 1;

      if (classNames.length > 0) {
        if (!classNames.includes(this.identifyClassName) && this.count > 0) {
          this.setChangeShowOptions(false);
        }
      }
      this.count = 0;
    } catch (err) {
      console.error(err);
    }
  }

  changeShowSubOptions() {
    this.setShowSubOptions(!this.showSubOptions);
  }

  setShowSubOptions(value: boolean) {
    this.showSubOptions = value;
  }

  getDirectionSubOption() {
    return getArrowDirectionByBool(this.showSubOptions);
  }

  /*getIsInvalidWayPayDefault(){
    return this.fieldIdentificator==fieldType.EXCHANGE && JSON.stringify(this.wayPayDefault)!=JSON.stringify(this.getWayPay());
  }*/
}
