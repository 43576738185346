import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderEraserTableComponent } from './order-eraser-table.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { RowHeadModule } from 'src/app/components/board/order/row-head/row-head.module';
import { RowOrderBoardModule } from 'src/app/components/board/order/row/row.module';
import { IndicatorsTableModule } from 'src/app/components/board/indicators/indicators.module';

@NgModule({
  declarations: [
    OrderEraserTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    RowHeadModule,
    RowOrderBoardModule,
    IndicatorsTableModule,
  ], exports: [
    OrderEraserTableComponent
  ]
})
export class OrderEraserTableModule { }