import { Pipe, PipeTransform } from '@angular/core';
import { isOwnerCommoditiesOrder } from './filter-all-order.pipe';

@Pipe({
  name: 'filterIsOwnerCommodities'
})
export class FilterOrderIsOwnerCommoditiesPipe implements PipeTransform {

   /*If it is commercial, it is determined that the vendor code is not from Commodities or Hughes, 
   if it is, it does not add it to the arrayToReturn.
    If it is not commercial, it returns the array passed by parameter*/ 
  transform(list: any[], isComercial:boolean) {
    if(!isComercial) return list;

    let arrayFinal: any[] = [];
    list.forEach(element => {
      if ( isOwnerCommoditiesOrder(element, isComercial)){
        arrayFinal.push(element);
      }
    })
    return arrayFinal;
  }
}  
