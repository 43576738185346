import { Action, createReducer, on } from "@ngrx/store";
import { VIEW_ORDER_ALL, VIEW_ORDER_VOID } from "src/app/shared/const/views.const";
import * as actions from '../actions/control-panel.action';
import { divisionsControlPanel, sectionType } from "src/app/shared/const/options";
import { initialOrderString, orderInitialState } from "src/app/shared/const/orders";
import { ControlPanelReducer } from "src/app/shared/interfaces/control-panel.interface";

export const initialState: ControlPanelReducer = {
    controlPanelBoard: {
        all: [],
        sectionType: sectionType.CONTRACTS_IN_ERASERS,
        divisionType: divisionsControlPanel.BUY_COMMODITIES,
        filter: initialOrderString,
        indexFilter:-1,
        ordersSelected:[],
        isOrderReturned:false,
        orderDetails: orderInitialState,
        showOrderDetails: false
    },
    viewsOfControlPanelBoard: {
        views: [],
        newView: VIEW_ORDER_VOID,
        indexViewSelected: -2,
        viewSelected: {
            ...VIEW_ORDER_ALL,
            viewName: 'default'
        }
    },
};

const _controlPanelReducer = createReducer(
    initialState,

    on(actions.setIndexToEdit, (state, action) => {
        return {
            ...state,
            indexToEdit: action.indexToEdit
        }
    }),


    on(actions.loadOrdersAllSuccessInControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                all: action.orders
            }

        };
    }),


    ////----------------------FILTERS------------------------\\\\
    
    on(actions.setFilterOrderControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                filter: action.orderString
            }

        };
    }),
    on(actions.setSectionTypeFilterControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                sectionType: action.sectionType
            }

        };
    }),
    on(actions.setDivisionTypeFilterControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                divisionType: action.sectionType
            }
        };
    }),

    on(actions.setFilterIndexControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                indexFilter: action.index
            }
        };
    }),


    ////----------------------VIEWS------------------------\\\\

    on(actions.setViewToGenerateCP, (state, action) => {
        return {
            ...state,
            viewsOfControlPanelBoard: {
                ...state.viewsOfControlPanelBoard,
                newView: action.view
            }
        }
    }),
    on(actions.resetNewViewCP, (state, action) => {
        return {
            ...state,
            viewsOfControlPanelBoard: {
                ...state.viewsOfControlPanelBoard,
                newView: initialState.viewsOfControlPanelBoard.newView
            }
        }
    }),
    on(actions.setViewSelectedCP, (state, action) => {
        return {
            ...state,
            viewsOfControlPanelBoard: {
                ...state.viewsOfControlPanelBoard,
                viewSelected: action.view
            }
        }
    }),
    on(actions.resetViewSelectedCP, (state, action) => {
        return {
            ...state,
            viewsOfControlPanelBoard: {
                ...state.viewsOfControlPanelBoard,
                viewSelected: initialState.viewsOfControlPanelBoard.viewSelected
            }
        }
    }),
    on(actions.setAllViewsCP, (state, action) => {
        return {
            ...state,
            viewsOfControlPanelBoard: {
                ...state.viewsOfControlPanelBoard,
                views: action.views
            }
        }
    }),
    on(actions.setIndexViewSelectedCP, (state, action) => {
        return {
            ...state,
            viewsOfControlPanelBoard: {
                ...state.viewsOfControlPanelBoard,
                indexViewSelected: action.index
            }
        }
    }),

    //----------------------ORDERS SELECTED----------------------\\
    //Select prices
    on(actions.setOrdersSelectedInControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                ordersSelected:action.orders
            }
        }
    }),



    //Price details
    on(actions.setOrderDetailsInControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                orderDetails: action.order
            }
        }
    }),
    on(actions.showOrderDetailsInControlPanel, (state, action) => {
        return {
            ...state,
            controlPanelBoard: {
                ...state.controlPanelBoard,
                showOrderDetails: action.show
            }
        }
    }),
)


export function controlPanelReducer(state: any, action: Action) {
    return _controlPanelReducer(state, action);
}

