import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setBoardSelected } from 'src/app/redux/actions/options.action';
import { BOARD_TYPES } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  select:string=''
  isActive:boolean=false;
  direction:string='';

  constructor(private store:Store<appState>, private cdr:ChangeDetectorRef){}

  ngOnInit(): void {
    this.setSubscription();
  }

  setSubscription(){
    this.store.select('options', 'board', 'boardSelected').subscribe((boardSelect)=>{
      this.select=boardSelect;
      this.direction=this.getDirection();
    });
  }

  setSelect(){
    this.store.dispatch(setBoardSelected({boardSelected:
      BOARD_TYPES.BOARD==this.select?BOARD_TYPES.CARDS:BOARD_TYPES.BOARD}))
  
    this.isActive=true;
    setTimeout(()=>{
      this.isActive=false;
      this.cdr.detectChanges();
    }, 500);
  }

  getDirection(){
    return this.select==BOARD_TYPES.BOARD?'left':'right';
  }
}
