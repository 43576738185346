import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { exchangeTags } from 'src/app/shared/const/exchange-orders';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../fields.component';

@Component({
  selector: 'app-agreed-discount',
  templateUrl: './agreed-discount.component.html',
  styleUrls: ['./agreed-discount.component.scss', './../fields.component.scss']
})
export class AgreedDiscountComponent extends Field implements OnInit {
  @Input() defaultValue: number = 0;
  @Input() showMessageNT: boolean = false;

  constructor(store: Store<appState>) {
    super(store);
  }

  ngOnInit(): void {
    this.tag = exchangeTags.agreedDiscount;
  }

  override rememberValueInForm(){
    this.rememberValueTypeTwo();
  }

  override setElement(event: any, index: number) {
    let valueToSave = Number(event);
    this.setElementToGenerate(valueToSave);

    this.changeOptionSelected();
  }

}