import { Component, OnInit, Input,ChangeDetectionStrategy,ChangeDetectorRef,OnDestroy, Output, EventEmitter } from '@angular/core';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderString } from 'src/app/shared/interfaces/order.interface';
import * as dict from 'src/app/shared/dict/dict';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { teams } from 'src/app/shared/dict/user';
import { orderStates } from 'src/app/shared/dict/orders';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { buyers } from 'src/app/shared/dict/buyers';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { DateChecker } from 'src/app/shared/date.checker';
import { STATES_ORDERS_VALIDS } from 'src/app/pages/order-list/body-order-list/order-table/commodities/commodities.component';
import { Subscription } from 'rxjs';
import { OPTION } from '../../options/options.component';
import { RowHead } from '../../row-head';
import { conditionType } from 'src/app/shared/const/options';

@Component({
  selector: 'tr[row-head]',
  templateUrl: './row-head.component.html',
  styleUrls: ['./row-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowHeadComponent extends RowHead implements OnInit, OnDestroy {
  @Input() isControlPanel:boolean=false;
  @Input() viewSelected!:ViewOrder;
  @Input() conditionTypeSelected!:string;
  @Input() showBuyer:boolean=true;
  @Input() showAutorizateAll:boolean=false;
  
  public STATES_ORDERS_VALIDS= [...STATES_ORDERS_VALIDS, "Contrato", "Borrador"];
  STATUS: any = [];
  BUSINESS_TYPE: any = [];
  PLACE_OF_DELIVERY: any = [];
  QUALITY: any = [];
  TYPE_COIN: any = [];
  WAY_PAY: any = [];
  PRODUCTS: any = [];
  OBSERVATIONS: any = [];
  HARVEST: any = [];
  SELLERS: any = [];
  TABLES:any=[];
  BUYERS:any=[];  

  CONDITION_TYPES=conditionType;

  constructor(private store:Store<appState>, private date:DateChecker, private cdr:ChangeDetectorRef){
    super();
  }

  ngOnInit(): void {
    this.setArrays();
  }

  ngOnDestroy(){}

  setArrays() {
    this.STATUS = orderStates;
    this.BUSINESS_TYPE = typeBusiness;
    this.QUALITY = dict.quality;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.TYPE_COIN = dict.typeCoin;
    this.WAY_PAY = wayPay;
    this.PRODUCTS = PRODUCT_NAMES;
    this.HARVEST = this.date.getHarvest();
    this.SELLERS = sellerWithData;
    this.TABLES= teams;
    this.BUYERS=buyers;
  }
  
  autorizateAll(){
    this.actions.emit(OPTION.AUTORIZATE_ALL);
  }

  emitAction(action:string){
    this.actions.emit(action);
  }
}