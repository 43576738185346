import { ChangeDetectorRef, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { setChangesVisibles, setErrorStruct } from "src/app/redux/actions/options.action";
import { buyerType, conditionType } from "src/app/shared/const/options";
import { REPRESENTATION_CONST_ARRAY, areas } from "src/app/shared/const/user.const";
import { invertDateWithout0 } from "src/app/shared/date.checker";
import { appState } from "src/app/shared/interfaces/appState.interface";
import { ErrorStruct } from "src/app/shared/interfaces/options.interface";
import { User } from "src/app/shared/interfaces/user.interface";

@Component({
    template:''
})
export class BodyBoard  {
    user!: User; //Store de redux
    isComercial: boolean = false;
    isOperator: boolean = false;
    isRepresentation:boolean=false;

    // ---- Options ---- \\
    date: string = '';
    showDelete: boolean = false;
    isChangeVisible: boolean = false;
    errorVisible: ErrorStruct={isVisible:false,color:'', message:''};
    boardStyleSelected:string='';

    historical:string='';

    // ---- Filters ---- \\
    search: string = '';
    buyerTypes: any = buyerType;
    CONDITION_TYPES=conditionType;
    buyerTypeSelected: string='';
    conditionTypeSelected:string='';
    conditionFilters:number[]=[];

    private user$!:Subscription;
    private date$!:Subscription;
    private showDelete$!:Subscription;
    private isChangePrice$!:Subscription;
    private error$!:Subscription;

    protected selected$!:Subscription;
    protected showDetails$!:Subscription;
    protected details$!:Subscription;
    protected buyerTypeSelected$!:Subscription;
    protected filter$!:Subscription;
    protected historical$!:Subscription;
    
    private conditionTypeSelected$!:Subscription;
    private search$!:Subscription;
    private conditionFilters$!:Subscription;
    constructor(public store:Store<appState>, public cdr:ChangeDetectorRef){
    }

    setBodySubscription(){
        this.user$=this.store.select('usuario').subscribe( user=>{
            this.user=user;
            this.isComercial = user.area == areas.comercial;
            this.isOperator = user.area == areas.commodities;
            this.isRepresentation=REPRESENTATION_CONST_ARRAY.includes(user.workUnit);
        })

        this.date$= this.store.select('options', 'date').subscribe((date) => {
            this.date = date;
        });

        this.showDelete$=this.store.select('options','deletePrice').subscribe((deletePrice)=>{
            this.showDelete=deletePrice.isShow;
        })

        this.isChangePrice$=this.store.select('options','isChangeVisible').subscribe((isChangeVisible)=>{
            this.isChangeVisible=isChangeVisible;
            this.checkChangesVisibles();
            if(isChangeVisible){
                this.cdr.detectChanges();
            }
        })

        this.error$=this.store.select('options','error').subscribe((error)=>{
            this.errorVisible=error;
            this.checkErrorVisibles();
            if(error){
                this.cdr.detectChanges();
            }
        })
        
        this.conditionTypeSelected$=this.store.select('options','board','conditionTypeSelected').subscribe((conditionType) => {
            this.conditionTypeSelected = conditionType;
        });

        this.conditionFilters$=this.store.select('options','board','conditionFilter').subscribe((conditionFilters)=>{
            this.conditionFilters=conditionFilters;
        })

        this.search$=this.store.select('options','board','search').subscribe((search)=>{
            this.search=search;
        });
    }

    setBodyUnsubscription(){
        this.user$?.unsubscribe();
        this.date$?.unsubscribe();
        this.showDelete$?.unsubscribe();
        this.isChangePrice$?.unsubscribe();
        this.error$?.unsubscribe();

        this.selected$?.unsubscribe();
        this.showDetails$?.unsubscribe();
        this.buyerTypeSelected$?.unsubscribe();
        this.conditionTypeSelected$?.unsubscribe();
        this.search$?.unsubscribe();
        this.conditionFilters$?.unsubscribe();
        this.historical$?.unsubscribe();
        this.details$?.unsubscribe();
        this.filter$?.unsubscribe();
    }

    checkChangesVisibles(){
        if(this.isChangeVisible){
            setTimeout(() => {
              this.store.dispatch(setChangesVisibles({isChangeVisible:false}));
            }, 3500); 
        }
    }

    checkErrorVisibles(){
        if(this.errorVisible.isVisible){
            setTimeout(()=>{
              this.store.dispatch(setErrorStruct({error: {isVisible:false,color:'', message:''}}));
            },1200)        
        }
    }

    getDate(){
        return invertDateWithout0(this.date);
    }  
    
    isHover:boolean=false;
    hover(){
        this.isHover=true;
    }
    
    noHover(){
        this.isHover=false;
    }
}