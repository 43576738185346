import { Component, Input } from '@angular/core';

@Component({
  selector: 'clock-svg',
  templateUrl: './clock.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class ClockSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  constructor() { }

}
