//TIPO DE NEGOCIO (TypeBusiness)
export const typeBusiness = {
	'-1':'--',
	0: '--',
	1: 'Con descarga',
	2: 'Sin Descarga',
	3: 'Depositos',
	4: 'Forward'
}

export const businessDict ={
	conDescarga:1,
	sinDescarga:2,
	depositos:3,
	forward:4
}