import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { FilterOrderModule } from 'src/app/components/filters/filter-order/filters.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EyeSvgModule } from 'src/app/shared/styles/svg/eye/eye.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { ObservationsOrderSvgModule } from 'src/app/shared/styles/svg/observations-order/observations-order.module';
import { PriceTableComponent } from './price-table/price-table.component';
import { FilterPriceModule } from 'src/app/components/filters/filter-price/filters.module';
import {OrderTableComponent} from './orden-table/orden-table.component';
import { ExchangeComponent } from './exchange/exchange.component';
import { IndicatorsTableModule } from 'src/app/components/board/indicators/indicators.module';
import { RowOrderBoardModule } from 'src/app/components/board/order/row/row.module';
import { RowHeadModule } from 'src/app/components/board/order/row-head/row-head.module';
import { TableMobileOrderModule } from 'src/app/components/board/mobile-order/mobile-order.module';
import { TableMobilePriceModule } from 'src/app/components/board/mobile-price/mobile-price.module';
import { TableMobileExchangeModule } from 'src/app/components/board/mobile-exchange/mobile-exchange.module';
import { RowPriceHeadModule } from 'src/app/components/board/price/row-price-head/row-price-head.module';
import { CardIndicatorsModule } from 'src/app/components/cards/indicators/indicators.module';
import { ArrowBoardSvgModule } from 'src/app/shared/styles/svg/arrow-board/arrow-price-board.module';
import { ButtonsToFixModule } from 'src/app/components/buttons-to-fix/buttons-to-fix.module';

@NgModule({
  declarations: [
    TableComponent,
    PriceTableComponent,
    OrderTableComponent,
    ExchangeComponent
    
  ],
  imports: [
    CommonModule, 
    MatDatepickerModule,
    FilterOrderModule,
    FilterPriceModule,
    ButtonsToFixModule,

    //SVG
    ThreePointsSvgModule, 
    ArrowSvgModule,
    ArrowBoardSvgModule,
    FilterBoardSvgModule,
    EyeSvgModule,
    ObservationsOrderSvgModule,
    PipesModule,

    TableMobileOrderModule,
    TableMobilePriceModule,
    TableMobileExchangeModule,
    IndicatorsTableModule,
    CardIndicatorsModule,

    RowOrderBoardModule,
    RowHeadModule,

    RowPriceHeadModule
  ],
  exports: [
    TableComponent,
    PriceTableComponent,
    OrderTableComponent,
    ExchangeComponent
  ]
})
export class TableModule { }