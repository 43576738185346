export const laRiojaLocalities=[
    {"afip":"60","location":"Abra Verde"},
    {"afip":"120","location":"Agua Blanca"},
    {"afip":"134","location":"Agua De La Piedra"},
    {"afip":"142","location":"Agua De Piedra"},
    {"afip":"216","location":"Aguayo"},
    {"afip":"231","location":"Aicuña"},
    {"afip":"232","location":"Aimogasta"},
    {"afip":"263","location":"Alcazar"},
    {"afip":"337","location":"Algarrobo Grande"},
    {"afip":"372","location":"Alpasinche"},
    {"afip":"388","location":"Altillo Del Medio"},
    {"afip":"392","location":"Alto Bayo"},
    {"afip":"396","location":"Alto Carrizal"},
    {"afip":"445","location":"Alto Jaguel"},
    {"afip":"498","location":"Ambil"},
    {"afip":"508","location":"Amilgancho"},
    {"afip":"511","location":"Aminga"},
    {"afip":"517","location":"Ampata"},
    {"afip":"523","location":"Amuschina"},
    {"afip":"537","location":"Anchico"},
    {"afip":"544","location":"Anchumbil"},
    {"afip":"555","location":"Andolucas"},
    {"afip":"583","location":"Anguinan"},
    {"afip":"584","location":"Angulos"},
    {"afip":"591","location":"Anjullon"},
    {"afip":"607","location":"Antinaco"},
    {"afip":"648","location":"Arauco"},
    {"afip":"917","location":"Atiles"},
    {"afip":"1021","location":"Bajo Corral De Isaac"},
    {"afip":"1024","location":"Bajo De Gallo"},
    {"afip":"1044","location":"Bajo Jaguel"},
    {"afip":"1086","location":"Balde Del Quebracho"},
    {"afip":"1103","location":"Balde Salado"},
    {"afip":"1106","location":"Balde San Isidro"},
    {"afip":"1114","location":"Baldes De Pacheco"},
    {"afip":"1159","location":"Banda Florida"},
    {"afip":"1240","location":"Barrio De Galli"},
    {"afip":"1379","location":"Bayo Muerto"},
    {"afip":"1381","location":"Bazan"},
    {"afip":"1401","location":"Bañados Del Pantano"}
    ,
    {"afip":"1504","location":"Boca De La Quebrada"},
    {"afip":"1685","location":"Cachiyuyal"},
    {"afip":"1827","location":"Campanas"},
    {"afip":"2018","location":"Campo Tres Pozos"},
    {"afip":"2103","location":"Capihuas"},
    {"afip":"2232","location":"Carrizal Estacion Fcgb"},
    {"afip":"2234","location":"Carrizal Tama"},
    {"afip":"2236","location":"Carrizalillo"},
    {"afip":"2264","location":"Casa Pintada"},
    {"afip":"2274","location":"Casagate"},
    {"afip":"2278","location":"Casangate"},
    {"afip":"2318","location":"Castro Barros"},
    {"afip":"2330","location":"Catinzaco"},
    {"afip":"2331","location":"Catinzaco Embarcadero Fcgb"},
    {"afip":"2338","location":"Catuna"},
    {"afip":"2480","location":"Cebollar"},
    {"afip":"2871","location":"Chaupihuasi"},
    {"afip":"2910","location":"Chañarmuyo"}
    ,
    {"afip":"2914","location":"Chelcos"},
    {"afip":"2919","location":"Chepes"},
    {"afip":"2920","location":"Chepes Viejos"},
    {"afip":"2934","location":"Chila"},
    {"afip":"2962","location":"Chimenea"},
    {"afip":"3017","location":"Chulo"},
    {"afip":"3020","location":"Chuquis"},
    {"afip":"3054","location":"Cisco"},
    {"afip":"3188","location":"Colonia Alfredo"},
    {"afip":"3757","location":"Colonia Ortiz De Ocampo"},
    {"afip":"3989","location":"Comandante Leal"},
    {"afip":"4132","location":"Corral De Isaac"},
    {"afip":"4161","location":"Cortaderas Embarcadero Fcgb"},
    {"afip":"4298","location":"Cuatro Esquinas"},
    {"afip":"4331","location":"Cueva Del Chacho"},
    {"afip":"4334","location":"Cuipan"},
    {"afip":"4407","location":"Desiderio Tello"},
    {"afip":"4450","location":"Dique De Anzulon"},
    {"afip":"4463","location":"Dique Los Sauces"},
    {"afip":"4473","location":"Distrito Pueblo"},
    {"afip":"4642","location":"El Altillo"},
    {"afip":"4688","location":"El Barranco"},
    {"afip":"4695","location":"El Bayito"},
    {"afip":"4741","location":"El Calden"},
    {"afip":"4754","location":"El Cantadero"},
    {"afip":"4803","location":"El Cerco"},
    {"afip":"4833","location":"El Chiflon"},
    {"afip":"4843","location":"El Chocoy"},
    {"afip":"4854","location":"El Chuschin"},
    {"afip":"4855","location":"El Chusco"},
    {"afip":"4856","location":"El Cienago"},
    {"afip":"4861","location":"El Cincuenta"},
    {"afip":"4873","location":"El Condado"},
    {"afip":"4876","location":"El Consuelo"},
    {"afip":"4946","location":"El Escondido"},
    {"afip":"4968","location":"El Fraile"},
    {"afip":"5009","location":"El Horno"},
    {"afip":"5011","location":"El Huaco"},
    {"afip":"5032","location":"El Jumeal"},
    {"afip":"5082","location":"El Medano"},
    {"afip":"5200","location":"El Pedregal"},
    {"afip":"5245","location":"El Plumerillo"},
    {"afip":"5267","location":"El Potrerillo (General Roca)"},
    {"afip":"5324","location":"El Retamo"},
    {"afip":"5468","location":"El Valdecito"},
    {"afip":"5489","location":"El Verde"},
    {"afip":"5623","location":"Esperanza De Los Cerrillos"},
    {"afip":"5641","location":"Esquina Del Norte"},
    {"afip":"5642","location":"Esquina Del Sud"},
    {"afip":"5677","location":"Estacion 69"},
    {"afip":"5715","location":"Estacion Mazan"},
    {"afip":"5779","location":"Estancia De Maiz"},
    {"afip":"6054","location":"Falda De Citan"},
    {"afip":"6064","location":"Famatina"},
    {"afip":"6252","location":"Francisco Ortiz De Ocampo"},
    {"afip":"6459","location":"Gobernador Gordillo"},
    {"afip":"6552","location":"Guachin"},
    {"afip":"6586","location":"Guandacol"},
    {"afip":"6616","location":"Guayapa"},
    {"afip":"6779","location":"Huaja"},
    {"afip":"6850","location":"Hunquillal"},
    {"afip":"6886","location":"Iliar"},
    {"afip":"6887","location":"Ilisco"},
    {"afip":"6888","location":"Illisca"},
    {"afip":"7074","location":"Ismiango"},
    {"afip":"7108","location":"Jague"},
    {"afip":"7314","location":"La America"},
    {"afip":"7394","location":"La Buena Suerte"},
    {"afip":"7409","location":"La Callana"},
    {"afip":"7432","location":"La Carrizana"},
    {"afip":"7481","location":"La Chimenea"},
    {"afip":"7519","location":"La Consulta"},
    {"afip":"7598","location":"La Envidia"},
    {"afip":"7720","location":"La Igualdad"},
    {"afip":"7789","location":"La Lomita"},
    {"afip":"7967","location":"La Pirgua"},
    {"afip":"8055","location":"La Rioja"},
    {"afip":"8092","location":"La Serena"},
    {"afip":"8134","location":"La Tordilla"},
    {"afip":"8137","location":"La Torre"},
    {"afip":"8199","location":"La Yesera"},
    {"afip":"8397","location":"Las Bombas"},
    {"afip":"8421","location":"Las Catas"},
    {"afip":"8503","location":"Las Gredas"},
    {"afip":"8582","location":"Las Padercitas"},
    {"afip":"8647","location":"Las Sierras Bravas"},
    {"afip":"8657","location":"Las Talas"},
    {"afip":"8688","location":"Las Tucumanesas"},
    {"afip":"8941","location":"Lorohuasi"},
    {"afip":"8947","location":"Los Aguirres"},
    {"afip":"8951","location":"Los Alanices"},
    {"afip":"8986","location":"Los Baldecitos"},
    {"afip":"8990","location":"Los Barriacitos"},
    {"afip":"8992","location":"Los Barrialitos"},
    {"afip":"9082","location":"Los Corias"},
    {"afip":"9123","location":"Los Frances"},
    {"afip":"9205","location":"Los Mogotes Colorados"},
    {"afip":"9231","location":"Los Olmos"},
    {"afip":"9233","location":"Los Oros"},
    {"afip":"9312","location":"Los Robles"},
    {"afip":"9328","location":"Los Sarmientos"},
    {"afip":"9350","location":"Los Tambillos"},
    {"afip":"9472","location":"Machigasta"},
    {"afip":"9523","location":"Malanzan"},
    {"afip":"9535","location":"Malligasta"},
    {"afip":"9715","location":"Mascasin"},
    {"afip":"9838","location":"Mesillas Blancas"},
    {"afip":"9862","location":"Milagro"},
    {"afip":"10003","location":"Mollaco"},
    {"afip":"10153","location":"Nacate"},
    {"afip":"10211","location":"Nepes"},
    {"afip":"10258","location":"Nonogasta"},
    {"afip":"10372","location":"Olpas"},
    {"afip":"10443","location":"Pacatala"},
    {"afip":"10448","location":"Padercitas"},
    {"afip":"10454","location":"Pagancillo"},
    {"afip":"10456","location":"Paganzo"},
    {"afip":"10713","location":"Paraje Monte Grande"},
    {"afip":"10745","location":"Parecitas"},
    {"afip":"10901","location":"Paso San Isidro"},
    {"afip":"10926","location":"Pastos Largos"},
    {"afip":"10934","location":"Patquia"},
    {"afip":"11092","location":"Piedra De Talampaya"},
    {"afip":"11146","location":"Pinchas"},
    {"afip":"11189","location":"Pituil"},
    {"afip":"11225","location":"Plaza Nueva"},
    {"afip":"11228","location":"Plaza Vieja"},
    {"afip":"11274","location":"Polco"},
    {"afip":"11309","location":"Portezuelo De Los Arce"},
    {"afip":"11396","location":"Pozo Blanco"},
    {"afip":"11409","location":"Pozo De Avila"},
    {"afip":"11415","location":"Pozo De La Orilla"},
    {"afip":"11418","location":"Pozo De La Piedra"},
    {"afip":"11461","location":"Pozo Del Medio"},
    {"afip":"11481","location":"Pozo Escondido"},
    {"afip":"11745","location":"Puerto Alegre"},
    {"afip":"11783","location":"Puerto Del Valle"},
    {"afip":"11931","location":"Puesto De Carrizo"},
    {"afip":"11953","location":"Puesto De Los Sanchez"},
    {"afip":"11977","location":"Puesto Dichoso"},
    {"afip":"12086","location":"Puesto Talita"},
    {"afip":"12103","location":"Puluchan"},
    {"afip":"12129","location":"Punta De Los Llanos"},
    {"afip":"12137","location":"Punta Del Cerro"},
    {"afip":"12142","location":"Punta Del Negro"},
    {"afip":"12201","location":"Quebrada Del Vallecito"},
    {"afip":"12370","location":"Real Del Cadillo"},
    {"afip":"12423","location":"Represa De La Punta"},
    {"afip":"12427","location":"Represa Del Monte"},
    {"afip":"12436","location":"Retamal"},
    {"afip":"12578","location":"Rio De Las Cañas"}
    ,
    {"afip":"12636","location":"Rios De Las Mesadas"},
    {"afip":"12637","location":"Rios De Los Colcoles"},
    {"afip":"12823","location":"Salana"},
    {"afip":"12837","location":"Salicas - San Blas"},
    {"afip":"12841","location":"Salinas De Bustos"},
    {"afip":"12843","location":"Salinas Del Leoncito"},
    {"afip":"12869","location":"Samay Huasi"},
    {"afip":"13153","location":"San Solano"},
    {"afip":"13212","location":"Santa Florentina"},
    {"afip":"13282","location":"Santa Vera Cruz"},
    {"afip":"13299","location":"Santo Domingo Departamento Fam"},
    {"afip":"13377","location":"Sañogasta"}
    ,
    {"afip":"13378","location":"Schaqui"},
    {"afip":"13428","location":"Señor De La Peña"}
    ,
    {"afip":"13518","location":"Solca"},
    {"afip":"13601","location":"Suriyaco"},
    {"afip":"13701","location":"Tala Verde"},
    {"afip":"13711","location":"Talamuyuna"},
    {"afip":"13728","location":"Talva"},
    {"afip":"13729","location":"Tama"},
    {"afip":"13780","location":"Tasquin"},
    {"afip":"13826","location":"Termas"},
    {"afip":"13832","location":"Termas De Santa Teresita"},
    {"afip":"13857","location":"Tilimuqui"},
    {"afip":"13880","location":"Tinocan"},
    {"afip":"13976","location":"Torrecitas"},
    {"afip":"14008","location":"Trampa Del Tigre"},
    {"afip":"14132","location":"Tuani"},
    {"afip":"14141","location":"Tuizon"},
    {"afip":"14175","location":"Tuyubil"},
    {"afip":"14187","location":"Udpinango"},
    {"afip":"14191","location":"Ulapes"},
    {"afip":"14324","location":"Verde Olivo"},
    {"afip":"14347","location":"Vichigasta"},
    {"afip":"14449","location":"Villa Casana"},
    {"afip":"14452","location":"Villa Castelli"},
    {"afip":"14459","location":"Villa Chepes"},
    {"afip":"14689","location":"Villa Mazan"},
    {"afip":"14710","location":"Villa Nidia"},
    {"afip":"14795","location":"Villa San Jose De Vinchina"},
    {"afip":"14804","location":"Villa Sanagasta"},
    {"afip":"14854","location":"Villa Union"},
    {"afip":"14898","location":"Vinchina"},
    {"afip":"15137","location":"Ñoqueves"}
    ,
    {"afip":"15175","location":"Adolfo E Carranza"},
    {"afip":"15182","location":"Agua Colorada"},
    {"afip":"15195","location":"Aguada"},
    {"afip":"15201","location":"Aguadita"},
    {"afip":"15258","location":"Amana"},
    {"afip":"15270","location":"Anillaco"},
    {"afip":"15320","location":"Bajo Grande"},
    {"afip":"15328","location":"Bajo Hondo"},
    {"afip":"15355","location":"Barranquitas"},
    {"afip":"15384","location":"Bella Vista"},
    {"afip":"15422","location":"Buena Vista"},
    {"afip":"15497","location":"Carrizal"},
    {"afip":"15511","location":"Casas Viejas"},
    {"afip":"15531","location":"Cañada Honda"}
    ,
    {"afip":"15543","location":"Cañada Verde"}
    ,
    {"afip":"15595","location":"Cerro Negro"},
    {"afip":"15612","location":"Chamical"},
    {"afip":"15620","location":"Chañar"}
    ,
    {"afip":"15637","location":"Chilecito"},
    {"afip":"15645","location":"Chumbicha"},
    {"afip":"15772","location":"Cordobita"},
    {"afip":"15799","location":"Cortaderas"},
    {"afip":"15847","location":"El Abra"},
    {"afip":"15872","location":"El Alto"},
    {"afip":"15896","location":"El Balde"},
    {"afip":"15903","location":"El Barreal"},
    {"afip":"15905","location":"El Barrial"},
    {"afip":"15917","location":"El Bordo"},
    {"afip":"15953","location":"El Carrizal"},
    {"afip":"15958","location":"El Catorce"},
    {"afip":"16029","location":"El Divisadero"},
    {"afip":"16036","location":"El Duraznillo"},
    {"afip":"16063","location":"El Fuerte"},
    {"afip":"16067","location":"El Garabato"},
    {"afip":"16106","location":"El Medanito"},
    {"afip":"16134","location":"El Mollar"},
    {"afip":"16140","location":"El Molle"},
    {"afip":"16203","location":"El Portezuelo"},
    {"afip":"16224","location":"El Potrerillo"},
    {"afip":"16229","location":"El Potrero"},
    {"afip":"16245","location":"El Pueblito"},
    {"afip":"16257","location":"El Puesto"},
    {"afip":"16265","location":"El Quebrachal"},
    {"afip":"16272","location":"El Quebracho"},
    {"afip":"16276","location":"El Quemado"},
    {"afip":"16300","location":"El Retiro"},
    {"afip":"16314","location":"El Rodeo"},
    {"afip":"16323","location":"El Rosario"},
    {"afip":"16383","location":"El Tala"},
    {"afip":"16422","location":"El Valle"},
    {"afip":"16427","location":"El Vallecito"},
    {"afip":"16442","location":"El Zapallar"},
    {"afip":"16477","location":"Esquina Grande"},
    {"afip":"16512","location":"Flamenco"},
    {"afip":"16571","location":"Huaco"},
    {"afip":"16608","location":"Jesus Maria"},
    {"afip":"16626","location":"La Aguada"},
    {"afip":"16633","location":"La Aguadita"},
    {"afip":"16650","location":"La Antigua"},
    {"afip":"16666","location":"La Armonia"},
    {"afip":"16683","location":"La Banda"},
    {"afip":"16705","location":"La Buena Estrella"},
    {"afip":"16710","location":"La Calera"},
    {"afip":"16755","location":"La Chilca"},
    {"afip":"16769","location":"La Cienaga"},
    {"afip":"16828","location":"La Cuadra"},
    {"afip":"16844","location":"La Dora"},
    {"afip":"16865","location":"La Escondida"},
    {"afip":"16877","location":"La Esperanza"},
    {"afip":"16886","location":"La Esquina"},
    {"afip":"16927","location":"La Flor"},
    {"afip":"16938","location":"La Florida"},
    {"afip":"16977","location":"La Higuera"},
    {"afip":"16988","location":"La Huerta"},
    {"afip":"16998","location":"La Invernada"},
    {"afip":"17009","location":"La Isla"},
    {"afip":"17017","location":"La Jarilla"},
    {"afip":"17029","location":"La Laguna"},
    {"afip":"17035","location":"La Lancha"},
    {"afip":"17037","location":"La Lata"},
    {"afip":"17044","location":"La Libertad"},
    {"afip":"17076","location":"La Maravilla"},
    {"afip":"17097","location":"La Merced"},
    {"afip":"17140","location":"La Pampa"},
    {"afip":"17161","location":"La Perlita"},
    {"afip":"17167","location":"La Pintada"},
    {"afip":"17173","location":"La Plaza"},
    {"afip":"17199","location":"La Primavera"},
    {"afip":"17217","location":"La Puntilla"},
    {"afip":"17238","location":"La Ramadita"},
    {"afip":"17245","location":"La Reforma"},
    {"afip":"17251","location":"La Represa"},
    {"afip":"17271","location":"La Rosilla"},
    {"afip":"17325","location":"La Trampa"},
    {"afip":"17371","location":"La Zanja"},
    {"afip":"17415","location":"Las Aguaditas"},
    {"afip":"17427","location":"Las Barrancas"},
    {"afip":"17446","location":"Las Cañas"}
    ,
    {"afip":"17524","location":"Las Higueras"},
    {"afip":"17528","location":"Las Higuerillas"},
    {"afip":"17613","location":"Las Palomas"},
    {"afip":"17624","location":"Las Peñas"}
    ,
    {"afip":"17659","location":"Las Salinas"},
    {"afip":"17676","location":"Las Toscas"},
    {"afip":"17690","location":"Las Tuscas"},
    {"afip":"17694","location":"Las Vertientes"},
    {"afip":"17721","location":"Loma Blanca"},
    {"afip":"17732","location":"Loma Larga"},
    {"afip":"17768","location":"Los Algarrobos"},
    {"afip":"17784","location":"Los Baldes"},
    {"afip":"17793","location":"Los Bordos"},
    {"afip":"17809","location":"Los Cerrillos"},
    {"afip":"17828","location":"Los Colorados"},
    {"afip":"17835","location":"Los Corrales"},
    {"afip":"17878","location":"Los Mistoles"},
    {"afip":"17882","location":"Los Molinos"},
    {"afip":"17897","location":"Los Nacimientos"},
    {"afip":"17905","location":"Los Olivares"},
    {"afip":"17908","location":"Los Palacios"},
    {"afip":"18024","location":"Medano"},
    {"afip":"18056","location":"Miranda"},
    {"afip":"18078","location":"Monte Grande"},
    {"afip":"18122","location":"Nueva Esperanza"},
    {"afip":"18144","location":"Olta"},
    {"afip":"18176","location":"Palo Labrado"},
    {"afip":"18238","location":"Peñas Blancas"}
    ,
    {"afip":"18251","location":"Piedra Larga"},
    {"afip":"18254","location":"Piedra Pintada"},
    {"afip":"18297","location":"Potrero Grande"},
    {"afip":"18309","location":"Pozo De La Yegua"},
    {"afip":"18312","location":"Pozo De Piedra"},
    {"afip":"18371","location":"Puerta De La Quebrada"},
    {"afip":"18405","location":"Punta De Agua"},
    {"afip":"18428","location":"Quebrachal"},
    {"afip":"18436","location":"Quebracho Herrado"},
    {"afip":"18519","location":"Rivadavia"},
    {"afip":"18563","location":"San Agustin"},
    {"afip":"18592","location":"San Antonio"},
    {"afip":"18609","location":"San Bernardo"},
    {"afip":"18634","location":"San Cristobal"},
    {"afip":"18690","location":"San Guillermo"},
    {"afip":"18698","location":"San Ignacio"},
    {"afip":"18710","location":"San Isidro"},
    {"afip":"18721","location":"San Javier"},
    {"afip":"18750","location":"San Jose"},
    {"afip":"18764","location":"San Juan"},
    {"afip":"18790","location":"San Lorenzo"},
    {"afip":"18817","location":"San Martin"},
    {"afip":"18831","location":"San Miguel"},
    {"afip":"18841","location":"San Nicolas"},
    {"afip":"18859","location":"San Pedro"},
    {"afip":"18874","location":"San Rafael"},
    {"afip":"18882","location":"San Ramon"},
    {"afip":"18892","location":"San Roque"},
    {"afip":"18922","location":"San Vicente"},
    {"afip":"18934","location":"Santa Ana"},
    {"afip":"18943","location":"Santa Barbara"},
    {"afip":"18961","location":"Santa Clara"},
    {"afip":"18968","location":"Santa Cruz"},
    {"afip":"18976","location":"Santa Elena"},
    {"afip":"19008","location":"Santa Lucia"},
    {"afip":"19052","location":"Santa Rosa"},
    {"afip":"19063","location":"Santa Teresa"},
    {"afip":"19079","location":"Santo Domingo"},
    {"afip":"19123","location":"Siempre Verde"},
    {"afip":"19125","location":"Sierra Brava"},
    {"afip":"19132","location":"Simbolar"},
    {"afip":"19202","location":"Totoral"},
    {"afip":"19222","location":"Tres Cerros"},
    {"afip":"19227","location":"Tres Cruces"},
    {"afip":"19271","location":"Valle Hermoso"},
    {"afip":"19287","location":"Villa Bustos"},
    {"afip":"19333","location":"Villa Santa Rita"},
    {"afip":"20812","location":"Balde Salado"},
    {"afip":"20813","location":"El Carrizal"},
    {"afip":"20814","location":"La Aguadita"},
    {"afip":"20815","location":"San Jose"},
    {"afip":"20816","location":"Bajo Hondo"},
    {"afip":"20817","location":"Cuatro Esquinas"},
    {"afip":"20818","location":"La Esquina"},
    {"afip":"20819","location":"La Libertad"},
    {"afip":"20820","location":"La Represa"},
    {"afip":"20821","location":"San Antonio"},
    {"afip":"20822","location":"San Nicolas"},
    {"afip":"20823","location":"Santo Domingo"},
    {"afip":"20824","location":"La Banda"},
    {"afip":"20825","location":"La Pampa"},
    {"afip":"20826","location":"Buena Vista"},
    {"afip":"20827","location":"El Quemado"},
    {"afip":"20828","location":"Agua Blanca"},
    {"afip":"20829","location":"El Alto"},
    {"afip":"20830","location":"El Barreal"},
    {"afip":"20831","location":"El Potrerillo"},
    {"afip":"20832","location":"El Tala"},
    {"afip":"20833","location":"Las Tuscas"},
    {"afip":"20834","location":"San Antonio"},
    {"afip":"20835","location":"San Jose"},
    {"afip":"20836","location":"San Rafael"},
    {"afip":"20837","location":"Santa Cruz"},
    {"afip":"20838","location":"Tama"},
    {"afip":"20839","location":"Valle Hermoso"},
    {"afip":"20840","location":"Dique Los Sauces"},
    {"afip":"20841","location":"Las Peñas"}
    ,
    {"afip":"20842","location":"La Cienaga"},
    {"afip":"20857","location":"San Antonio"},
    {"afip":"20858","location":"San Miguel"},
    {"afip":"20859","location":"San Nicolas"},
    {"afip":"20860","location":"Puerto Alegre"},
    {"afip":"20861","location":"San Bernardo"},
    {"afip":"20862","location":"San Jose"},
    {"afip":"20863","location":"Carrizal"},
    {"afip":"20864","location":"Santa Cruz"},
    {"afip":"20865","location":"Santo Domingo"},
    {"afip":"20866","location":"El Quebracho"},
    {"afip":"20867","location":"La Cienaga"},
    {"afip":"20868","location":"Carrizalillo"},
    {"afip":"20869","location":"El Potrerillo"},
    {"afip":"20870","location":"Loma Larga"},
    {"afip":"20871","location":"San Antonio"},
    {"afip":"20872","location":"San Pedro"},
    {"afip":"20873","location":"San Ramon"},
    {"afip":"20874","location":"Santa Cruz"},
    {"afip":"20875","location":"Las Padercitas"},
    {"afip":"20876","location":"Agua Colorada"},
    {"afip":"21694","location":"Tama"}
    ]