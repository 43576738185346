import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateCalendarInlineComponent } from './date-calendar-inline.component';
import { MatDatepickerModule } from '@angular/material/datepicker';



@NgModule({
  declarations: [
    DateCalendarInlineComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule
  ], exports:[
    DateCalendarInlineComponent
  ]
})
export class DateCalendarInlineModule { }
