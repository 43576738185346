import { Component, OnInit } from '@angular/core';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Store } from '@ngrx/store';
import { SessionChecker } from 'src/app/shared/session.checker';
import { setLocation } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./../../shared/styles/body.scss']
})
export class ProfileComponent implements OnInit {
  
  constructor(private store:Store<appState>, private checker:SessionChecker) {
    
    this.checker.rememberAndActualizateUser(this.store);

    this.store.dispatch(setLocation({location: NAVEGATION.PROFILE}));
  }

  ngOnInit(): void { 
  }
}
