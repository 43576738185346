<div>
    <div class="radios-buttons">
        <mat-radio-group>
            <mat-radio-button *ngFor="let option of options" class="radio-button" [value]="option.value" 
            [disabled]="isSelected(option.value)"
            (click)="select(option.value)" [checked]="value==option.value"> 
                <span class="radio grassi" [ngClass]="{'selected-radio':value==option.value}" >{{option.name}}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>