<!-- Add a new View-->
<div class="card" *ngIf="identificator==1">
    <div class="header">
        <span>Agregar vista</span>
    </div>

    <div class="search-and-text">
        <input type="text" class="search" [ngClass]="{'input-not-name':isButtonClicked && view.viewName==''}" 
        placeholder="Nombre de la vista" value="{{view.viewName}}" (keyup)="setName($event)">
        <span class="not-name" *ngIf="isButtonClicked && view.viewName==''">No olvides colocar un nombre a la vista.</span>
        <!--<p class="text">Selecciona un elemento para poder ocultar o mostrar las secciones de la tabla.</p>-->
    </div>

    <div class="line-grey"></div>
    <div class="select-all">
        <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span>    
    </div>
    <div class="line-grey"></div>
    
    <div class="options">
        <app-option option="Fecha" [isChecked]="view.date" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.date)"></app-option>
        <app-option option="Vendedor" [isChecked]="view.seller" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.seller)"></app-option>
        <app-option option="Canjeador" [isChecked]="view.exchanger" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.exchanger)"></app-option>
        <app-option option="Producto" [isChecked]="view.product" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.product)"></app-option>
        <app-option option="Precio" [isChecked]="view.price" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.price)"></app-option>
        <app-option option="Periodo de entrega" [isChecked]="view.deliveryPeriod" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.deliveryPeriod)"></app-option>
        <app-option option="Toneladas" [isChecked]="view.tons" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.tons)"></app-option>
        <app-option option="Estado de la contrapartida" [isChecked]="view.exchangeStatus" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.exchangeStatus)"></app-option>
        <app-option option="Contrato madre" [isChecked]="view.motherContract" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.motherContract)"></app-option>
    </div>        
    <span class="not-name" *ngIf="isButtonClicked && notClickedNothing()">No olvides colocar un nombre a la vista.</span>


    <button class="apply" [ngClass]="{'disabled': view.viewName=='' || notClickedNothing()}" (click)="createView()">
        Guardar vista
    </button>
</div>

<!-- Edit an existing view-->
<div class="card" *ngIf="identificator==2" [ngClass]="{'max-z-index': isDeleteView}">
    <div class="header" *ngIf="!isEditingName">
        <span>{{view.viewName}}</span>
        <edit-svg [size]="20" (click)="setIsEditingName(true)"></edit-svg>
    </div>
    
    <div class="column-left search-and-text" style="margin-top: 10px;" *ngIf="isEditingName">
        <input type="text" class="search" [ngClass]="{'input-not-name':isButtonClicked && view.viewName==''}" 
        placeholder="Nombre de la vista" value="{{view.viewName}}" (keyup)="setName($event)">
        <span class="not-name" *ngIf="isButtonClicked && view.viewName==''">No olvides colocar un nombre a la vista.</span>
        <span class="font-small">Se guardará cuando presione "Aplicar".</span>
    </div>
    
    <div class="line-grey"></div>
    <div class="select-all">
        <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span>
    </div>
    <div class="line-grey"></div>

    <div class="options">
        <app-option option="Fecha" [isChecked]="view.date" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.date)"></app-option>
        <app-option option="Vendedor" [isChecked]="view.seller" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.seller)"></app-option>
        <app-option option="Canjeador" [isChecked]="view.exchanger" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.exchanger)"></app-option>
        <app-option option="Producto" [isChecked]="view.product" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.product)"></app-option>
        <app-option option="Precio" [isChecked]="view.price" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.price)"></app-option>
        <app-option option="Periodo de entrega" [isChecked]="view.deliveryPeriod" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.deliveryPeriod)"></app-option>
        <app-option option="Toneladas" [isChecked]="view.tons" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.tons)"></app-option>
        <app-option option="Estado de la contrapartida" [isChecked]="view.exchangeStatus" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.exchangeStatus)"></app-option>
        <app-option option="Contrato madre" [isChecked]="view.motherContract" (checked)="setProperty($event, VIEW_ORDER_EXCHANGE_FIELDS.motherContract)"></app-option>
    </div>

    <button class="apply" [disabled]="view.viewName==''" (click)="editView()">
        Aplicar
    </button>
    <button class="delete" (click)="openModal(true)">
        Eliminar vista
    </button>

    <div id="modal" [ngClass]="{'show': isDeleteView}">
        <div class="modal-content">
            <p class="message">¿Deseas eliminar la vista?</p>
        
            <button class="button not-stuffed" (click)="deleteView()">Si</button>
            <button class="button filling" (click)="openModal(false)">No</button>
        </div>
    </div>
</div>