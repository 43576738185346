import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyExchangeListComponent } from './body-exchange-list.component';
import { ExchangeTableModule } from './exchange-table/exchange-table.module';
import { ViewsOrdersExchangeModule } from 'src/app/components/views/views-orders-exchange/views-orders.module';
import { ExchangeDetailsModule } from 'src/app/components/slide-cards/exchange-details/exchange-details.module';
import { AddExchangeModule } from 'src/app/components/slide-cards/add-exchange/add-exchange.module';
import { ViewsSectionModule } from 'src/app/components/views-section/views-section.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';
import { SearchInputModule } from 'src/app/components/inputs/search/search.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DialogEraserCreateModule } from 'src/app/components/dialogs/eraser-create/eraser-create.module';
import { DialogContractCreateModule } from 'src/app/components/dialogs/contract-create/contract-create.module';
import { DialogContractFailedModule } from 'src/app/components/dialogs/contract-failed/contract-failed.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    BodyExchangeListComponent,
  ],
  imports: [
    CommonModule,
    ExchangeTableModule,
    ViewsSectionModule,
    
    ExchangeDetailsModule,
    ViewsOrdersExchangeModule,
    AddExchangeModule,
    PipesModule,
    ModalModule,

    SearchInputModule,
    ButtonsConditionTypeModule,
    
    DialogContractCreateModule,
    DialogEraserCreateModule,
    DialogContractFailedModule
  ], exports: [
    BodyExchangeListComponent
  ]
})
export class BodyExchangeListModule { }
