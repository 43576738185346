import { Component } from '@angular/core';

@Component({
  selector: 'manual-order-svg',
  templateUrl: './manual-order.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class ManualOrderSvgComponent  {

  constructor() { }
}
