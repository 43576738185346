import { Component, OnInit,Output,EventEmitter,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Field } from '../../fields/fields.component';
import { QualityParticularitiesValidator } from 'src/app/shared/quality-particularities';
//import { QualityParticularities } from 'src/app/shared/interfaces/quality-particularities';
import { Subscription } from 'rxjs';

@Component({
  selector: 'q-p-slide-card',
  templateUrl: './quality-particularities.component.html',
  styleUrls: ['./../particularities.scss', './../slide-card.scss', './../../fields/fields.component.scss']
})
export class QualityParticularitiesComponent extends Field implements OnInit, OnDestroy {
  @Output() closeEvent = new EventEmitter<string>();
  //particularities:any[]=[];
  //options:any[]=[];
  //selectedIndex:number=0;

  //private storeSubscription!:Subscription;
  constructor(store:Store<appState>, private qualityV:QualityParticularitiesValidator) { 
    super(store);
  }

  ngOnInit(): void {
    /*this.storeSubscription=this.store.subscribe(state=>{
      this.particularities=this.getParticularitiesArray();
    })*/
    this.initalElementSelected='Ingresar una particularidad';

    if(fieldType.PRICE == this.fieldIdentificator){
      this.tag=priceTags.qualityParticularities;
    } else {
      this.tag=orderTags.price;
      this.subTag=priceTags.qualityParticularities;
    }
    //this.setIdentifyClassName();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    //this.storeSubscription.unsubscribe();
  }

  closeSlide(){
    this.showOptions=false;
  }
  close(){
    this.closeEvent.emit('close');
  }

  /*saveElement(event:any){
    let qualityPart=JSON.parse(event);
    this.options=this.getArrayQualityParticularities(qualityPart);
    this.setElementSelected(qualityPart)
    this.setElementToGenerate(qualityPart);
  }*/

  /*selected(index:number){
    this.selectedIndex=index;
  }*/

  /*getQualityParticularities(){
    try{
      return this.getPrice().qualityParticularities;
    } catch(err){
      return null;
    }
  }*/

  /*getParticularitiesArray():number[]{
    return []
    //const price=this.getPrice();
    //return this.qualityV.getParticularitiesArrayFromPrice(price);
  }*/

  /*isQualityInArray(number:number):boolean{
    return this.particularities.includes(number);
  }*/

  /*setElementSelected(qualityPart:QualityParticularities){
    if(this.options.length>0){
      this.isOptionSelected=true;
      this.elementSelected="Seleccionada " + this.options.length + " de " + this.particularities.length;
    } else {
      this.elementSelected='';
      this.isOptionSelected=false;
    }
  }

  getArrayQualityParticularities(qualityPart:QualityParticularities):number[]{
    return []
    //return this.qualityV.getQualityParticularities(qualityPart);
  }*/
}
