import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceTableComponent } from './price-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PriceTableComercialComponent } from './price-table-comercial/price-table-comercial.component';
import { PriceTableCommoditiesComponent } from './price-table-commodities/price-table-commodities.component';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { FilterPriceModule } from 'src/app/components/filters/filter-price/filters.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PlusSignSvgModule } from 'src/app/shared/styles/svg/plus-sign/plus-sign.module';
import { EyeSvgModule } from 'src/app/shared/styles/svg/eye/eye.module';
import { DeferralModule } from 'src/app/components/cards/deferral/deferral.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { MenuOptionsSvgModule } from 'src/app/shared/styles/svg/menu-options/menu-options.module';
import { IndicatorsTableModule } from 'src/app/components/board/indicators/indicators.module';
import { ObservationsPriceSvgModule } from 'src/app/shared/styles/svg/observations-price/observations-price.module';
import { ButtonsToFixModule } from 'src/app/components/buttons-to-fix/buttons-to-fix.module';
import { ArrowBoardSvgModule } from 'src/app/shared/styles/svg/arrow-board/arrow-price-board.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { TableMobilePriceModule } from 'src/app/components/board/mobile-price/mobile-price.module';
import { RowPriceHeadModule } from 'src/app/components/board/price/row-price-head/row-price-head.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CardIndicatorsModule } from 'src/app/components/cards/indicators/indicators.module';

@NgModule({
  declarations: [
    PriceTableComponent,
    PriceTableComercialComponent,
    PriceTableCommoditiesComponent,
  ],
  imports: [
    CommonModule,
    TableMobilePriceModule,
    MatDatepickerModule,
    DeferralModule,
    IndicatorsTableModule,
    RowPriceHeadModule,
    CardIndicatorsModule,

    ScrollingModule,

    ButtonsToFixModule,

    //---- SVG ----\\
    FilterBoardSvgModule,
    ArrowSvgModule,
    ArrowBoardSvgModule,
    ThreePointsSvgModule,
    FilterPriceModule,
    PlusSignSvgModule,
    EyeSvgModule,
    MenuOptionsSvgModule,
    ObservationsPriceSvgModule,

    //---- Pipes ----\\
    PipesModule
  ],
  exports: [
    PriceTableComponent
  ]
  
})
export class PriceTableModule { }