<div class="card-indicators column-left gap-4px pointer" [ngClass]="{'selected': isSelected, 'inactivo':isInactive}" #card>
    <div class="actions row-between width-100">
        <div class="row-left gap-8">
            <app-status-tag [price]="price"></app-status-tag>
    
            <div class="business-particularities">{{bussParts}}</div>
        </div>

        <input type="checkbox" class="input-select pointer" [checked]="isSelected" (click)="selectedPrice()"/>
    </div>


    <div class="width-100 row-between header-title">
        <div class="row-left">
            <div class="product row-left gap-2">
                <img class="icon" src="'./../../../../../assets/images/products/{{productName}}.svg" />
                <span class="info-header" class="product {{productName.toLowerCase()}}">{{productName}}</span>
            </div>
            <span class="info-header">&nbsp;| {{businessType}} - {{placeOfDelivery}}</span>
            <span class="info-header">&nbsp;| {{quality}}</span>
            <span class="info-header" *ngIf="conditionType.length>0">&nbsp;| {{conditionType}}</span>
        </div>
    </div>

    <div class="subtitulos" *ngIf="showValidFor">
        <span>{{validFor}}</span>
    </div>

    <div class="body-card column-left gap-4px">
        <span class="description-indicator" *ngFor="let indicator of indicators">
            • Fix {{getIndicatorInfo(indicator)}} <span class="bonus-or-reduction {{getHaveBonifOrReduc(indicator)}}">{{getBonifOrReduc(indicator)}}</span>
        </span>
    </div>

    <div class="icons width-100 row-right gap-4px">
        <eye-green-svg (click)="showDetails(price)" class="pointer"></eye-green-svg>
        <copy-green-svg (click)="copyInfo(card)" class="pointer"></copy-green-svg>
        <plus-generate-order-svg (click)="createOrderWithPrice(price)" class="pointer"></plus-generate-order-svg>
    </div>
</div>