import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeDetailsComponent } from './exchange-details.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { DetailsSmallModule } from '../../details/small-detail/details.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { NumberInputModule } from '../../inputs/number/number.module';
import { SellerNameModule } from '../../fields/seller-name/seller-name.module';
import { PlaceOfOriginModule } from '../../fields/place-of-origin/place-of-origin.module';
import { HowSellModule } from '../../fields/how-sell/how-sell.module';
import { TonsModule } from '../../fields/tons/tons.module';
import { PlusSignGreenSvgModule } from 'src/app/shared/styles/svg/plus-sign-green/plus-sign-green.module';
import { PriceModule } from '../../fields/price/price.module';
import { TypeCoinModule } from '../../fields/type-coin/type-coin.module';
import { BonificationExchangeModule } from '../../fields/bonification-exchange/exchange-bonus.module';
import { ArrowBackSvgModule } from 'src/app/shared/styles/svg/arrow-back/arrow-back.module';
import { AgreedDiscountModule } from '../../fields/agreed-discount/agreed-discount.module';
import { WayPayModule } from '../../fields/way-pay/way-pay.module';

@NgModule({
  declarations: [
    ExchangeDetailsComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule,
    ArrowSvgModule,
    ArrowBackSvgModule,
    AgreedDiscountModule,
    WayPayModule,
    PlusSignGreenSvgModule,
    DetailsSmallModule,
    NumberInputModule,
    SellerNameModule,
    PlaceOfOriginModule,
    HowSellModule,
    TonsModule,
    PriceModule,
    TypeCoinModule,
    BonificationExchangeModule,
    AgreedDiscountModule
  ], exports:[
    ExchangeDetailsComponent
  ]
})
export class ExchangeDetailsModule { }