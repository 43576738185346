import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PesificationComponent } from './pesification.component';
import { StartPesificationComponent } from './start-pesification/start-date.component';
import { EndPesificationComponent } from './end-pesification/end-date.component';
import { DateInputModule } from '../../inputs/date/date.module';

@NgModule({
  declarations: [
    PesificationComponent,
    StartPesificationComponent,
    EndPesificationComponent
  ],
  imports: [
    CommonModule,
    DateInputModule
  ], exports:[
   PesificationComponent 
  ],
  providers:[]
})
export class PesificationModule { }
