<div class="body gap-4px">

    <div class="header">
        <span class="add-buyer title">Indicadores</span>
        <cross-svg class="cross" (click)="close()"></cross-svg>
    </div>

    <app-indicators-list class="indicator-list" [indicators]="getOptions()" (edit)="editIndicator($event.indicator, $event.index)"
    (delete)="deleteIndicator($event)"></app-indicators-list>

    <p class="description info">Completá los datos necesarios de la condición disponible.</p>

    <!-- Cuando se clickea un indicador para completar, desparecen los otros con la class 'invisible' -->
    <ng-container *ngFor="let indicator of arrayIndicators">
        <div class="option row-between" *ngIf="getIsConditionType(indicator[0])"
        (click)="setIndicatorSelectedToComplete(indicator[0], $event)"
        [ngClass]="{'invisible': !isIndicatorSelected(indicator[0]) && !isIndicatorSelected(0) }">
            <span class="option-name">{{indicator[1]}}</span>
            <input type="checkbox" class="pointer" [checked]="isIndicatorSelected(indicator[0])" />
        </div>
    </ng-container>

    <div class="indicators" *ngIf="indicatorSelectedToComplete > 0">
        <app-indicator-information (valueOut)="setIndicatorSelected($event)"
        [conditionType]="getConditionType()" [indicator]="indicatorSelected" [allIndicators]="getAllIndicators()" [previusDateMin]="getEndDateOfPreviusIndicator()"></app-indicator-information>
    </div>

    <span class="message-error" *ngIf="showMessageError">Hay campos incompletos para generar el indicador.</span>

    <button class="button-init not-filled-button-2" [ngClass]="{'visibility-hidden': isConditionTypeSinEspacio()}" *ngIf="indicatorSelectedToComplete > 0" (click)="addNewIndicator()">
        <plus-generate-order-svg></plus-generate-order-svg>
        <span class="text enabled">Agregar otro indicador</span>
    </button>

    <div class="bottom-fixed">
        <button class="filled-button" [ngClass]="{'disabled': isIndicatorIncomplete(getNewIndicator(), this.getConditionType())}" (click)="applyChanges()"><span>Aplicar</span></button>
    </div>
</div>