

<app-buttons-to-fix *ngIf="conditionType==CONDITION_TYPES.TO_FIX"></app-buttons-to-fix>
<div class="no-padding" *ngIf="!isViewMobile else elseBlock">
    <app-price-table-comercial *ngIf="isComercial" class="table"></app-price-table-comercial>
    <app-price-table-commodities *ngIf="isOperator" class="table"></app-price-table-commodities>    
</div>

<ng-template #elseBlock>
    <div class="price-mobile visible-in-mobile" *ngFor="let price of pricesWithPipe | matchPricesByName">
        <app-table-mobile-price [prices]="price" class="info-product"></app-table-mobile-price>
    </div>
</ng-template>