export const cordobaLocations=[
    {"afip":"34","location":"Abburra"},
    {"afip":"84","location":"Achiras"},
    {"afip":"86","location":"Acollarado"},
    {"afip":"97","location":"Adela Maria"},
    {"afip":"130","location":"Agua De Crespin"},
    {"afip":"138","location":"Agua De Las Piedras"},
    {"afip":"141","location":"Agua De Oro"},
    {"afip":"143","location":"Agua De Tala"},
    {"afip":"149","location":"Agua Del Tala"},
    {"afip":"155","location":"Agua Hedionda"},
    {"afip":"161","location":"Agua Pintada"},
    {"afip":"164","location":"Agua Sacha"},
    {"afip":"184","location":"Aguada Del Monte"},
    {"afip":"210","location":"Aguas De Ramon"},
    {"afip":"265","location":"Alcira Estacion Gigena"},
    {"afip":"303","location":"Alejandro"},
    {"afip":"309","location":"Alejo Ledesma"},
    {"afip":"343","location":"Alicia"},
    {"afip":"359","location":"Almafuerte"},
    {"afip":"368","location":"Alpa Corral"},
    {"afip":"371","location":"Alpapuca"},
    {"afip":"386","location":"Altautina"},
    {"afip":"390","location":"Alto Alegre"},
    {"afip":"397","location":"Alto Castro"},
    {"afip":"403","location":"Alto De Castillo"},
    {"afip":"404","location":"Alto De Fierro"},
    {"afip":"405","location":"Alto De Flores"},
    {"afip":"411","location":"Alto De Las Mulas"},
    {"afip":"417","location":"Alto De Los Quebrachos"},
    {"afip":"423","location":"Alto De San Pedro"},
    {"afip":"426","location":"Alto Del Durazno"},
    {"afip":"438","location":"Alto Del Tala"},
    {"afip":"442","location":"Alto Grande"},
    {"afip":"460","location":"Alto Resbaloso"},
    {"afip":"467","location":"Altos De Chipion"},
    {"afip":"500","location":"Amboy"},
    {"afip":"503","location":"Ambul"},
    {"afip":"526","location":"Ana Zumaran"},
    {"afip":"585","location":"Ani Mi"},
    {"afip":"611","location":"Antonio Catalano"},
    {"afip":"651","location":"Arbol Blanco"},
    {"afip":"652","location":"Arbol Chato"},
    {"afip":"658","location":"Arboles Blancos"},
    {"afip":"665","location":"Arcoyo"},
    {"afip":"681","location":"Arias"},
    {"afip":"696","location":"Aromito"},
    {"afip":"708","location":"Arroyito"},
    {"afip":"710","location":"Arroyo"},
    {"afip":"715","location":"Arroyo Algodon"},
    {"afip":"734","location":"Arroyo Cabral"},
    {"afip":"759","location":"Arroyo De Alvarez"},
    {"afip":"767","location":"Arroyo Del Pino"},
    {"afip":"789","location":"Arroyo La Higuera"},
    {"afip":"805","location":"Arroyo Los Patos"},
    {"afip":"851","location":"Arroyo San Antonio"},
    {"afip":"855","location":"Arroyo Santa Catalina"},
    {"afip":"857","location":"Arroyo Santana"},
    {"afip":"862","location":"Arroyo Seco"},
    {"afip":"870","location":"Arroyo Toledo"},
    {"afip":"887","location":"Arsenal Jose Maria Rojas"},
    {"afip":"897","location":"Asconchinga"},
    {"afip":"905","location":"Assunta"},
    {"afip":"911","location":"Atahona"},
    {"afip":"920","location":"Atos Pampa"},
    {"afip":"926","location":"Atumi Pampa"},
    {"afip":"929","location":"Augusto Vandersande"},
    {"afip":"934","location":"Ausonia"},
    {"afip":"1014","location":"Bajada Nueva"},
    {"afip":"1019","location":"Bajo Chico"},
    {"afip":"1020","location":"Bajo Chico  Bajo Grande"},
    {"afip":"1023","location":"Bajo De Fernandez"},
    {"afip":"1025","location":"Bajo De Gomez"},
    {"afip":"1030","location":"Bajo De Olmos"},
    {"afip":"1032","location":"Bajo Del Burro"},
    {"afip":"1033","location":"Bajo Del Carmen"},
    {"afip":"1037","location":"Bajo El Molino"},
    {"afip":"1039","location":"Bajo Galindez"},
    {"afip":"1040","location":"Bajo Grande"},
    {"afip":"1050","location":"Bajo Lindo"},
    {"afip":"1055","location":"Balbuena"},
    {"afip":"1072","location":"Balde De La Mora"},
    {"afip":"1073","location":"Balde De La Orilla"},
    {"afip":"1098","location":"Balde Lindo"},
    {"afip":"1120","location":"Ballesteros"},
    {"afip":"1121","location":"Ballesteros Sud"},
    {"afip":"1124","location":"Balnearia"},
    {"afip":"1132","location":"Balneario Guglieri"},
    {"afip":"1157","location":"Banda De Varela"},
    {"afip":"1192","location":"Barranca Yaco"},
    {"afip":"1207","location":"Barreto"},
    {"afip":"1208","location":"Barrial"},
    {"afip":"1213","location":"Barrialitos"},
    {"afip":"1224","location":"Barrio Ameghino"},
    {"afip":"1229","location":"Barrio Belgrano"},
    {"afip":"1241","location":"Barrio Dean Funes"},
    {"afip":"1242","location":"Barrio Del Libertador"},
    {"afip":"1268","location":"Barrio La Feria"},
    {"afip":"1269","location":"Barrio La Fortuna"},
    {"afip":"1281","location":"Barrio Loza"},
    {"afip":"1283","location":"Barrio Muller"},
    {"afip":"1312","location":"Barrio San Lorenzo Sur"},
    {"afip":"1325","location":"Barrio Tte Benjamin Matie"},
    {"afip":"1334","location":"Barrio Villa Union"},
    {"afip":"1362","location":"Batan"},
    {"afip":"1391","location":"Bañado De Paja"}
    ,
    {"afip":"1392","location":"Bañado De Soto"}
    ,
    {"afip":"1393","location":"Bañado Del Fuerte"}
    ,
    {"afip":"1431","location":"Bell Ville"},
    {"afip":"1443","location":"Bengolea"},
    {"afip":"1448","location":"Benjamin Gould"},
    {"afip":"1473","location":"Berrotaran"},
    {"afip":"1478","location":"Beuce"},
    {"afip":"1479","location":"Bialet Masse"},
    {"afip":"1494","location":"Blas De Rosales"},
    {"afip":"1499","location":"Bo Flores Guarnicion Aerea"},
    {"afip":"1500","location":"Bo Oficial Y Suboficial Ge"},
    {"afip":"1508","location":"Boca Del Rio"},
    {"afip":"1543","location":"Bordo De Los Espinosa"},
    {"afip":"1551","location":"Bosque Alegre"},
    {"afip":"1557","location":"Bouwer"},
    {"afip":"1579","location":"Brinkmann"},
    {"afip":"1604","location":"Bulnes"},
    {"afip":"1605","location":"Burmeister"},
    {"afip":"1631","location":"Cabalango"},
    {"afip":"1634","location":"Cabana"},
    {"afip":"1650","location":"Cabindo"},
    {"afip":"1677","location":"Cachi Yaco"},
    {"afip":"1684","location":"Cachiyullo"},
    {"afip":"1693","location":"Cadete Chavez"},
    {"afip":"1721","location":"Cajon Del Rio"},
    {"afip":"1725","location":"Calabalumba"},
    {"afip":"1729","location":"Calasuya"},
    {"afip":"1732","location":"Calchin"},
    {"afip":"1733","location":"Calchin Oeste"},
    {"afip":"1744","location":"Calera Central"},
    {"afip":"1771","location":"Calmayo"},
    {"afip":"1791","location":"Camilo Aldao"},
    {"afip":"1801","location":"Caminiaga"},
    {"afip":"1803","location":"Camino A Punta Del Agua"},
    {"afip":"1807","location":"Camoati"},
    {"afip":"1816","location":"Campamento Minnetti"},
    {"afip":"1834","location":"Campo Alegre"},
    {"afip":"1835","location":"Campo Alvarez"},
    {"afip":"1837","location":"Campo Ambroggio"},
    {"afip":"1845","location":"Campo Bandillo"},
    {"afip":"1846","location":"Campo Beiro"},
    {"afip":"1851","location":"Campo Boero"},
    {"afip":"1854","location":"Campo Bourdichon"},
    {"afip":"1858","location":"Campo Calvo"},
    {"afip":"1870","location":"Campo Coyunda"},
    {"afip":"1880","location":"Campo De La Torre"},
    {"afip":"1882","location":"Campo De Las Piedras"},
    {"afip":"1927","location":"Campo General Paz"},
    {"afip":"1947","location":"Campo La Luisa"},
    {"afip":"1950","location":"Campo La Piedra"},
    {"afip":"1990","location":"Campo Ramallo"},
    {"afip":"2002","location":"Campo Rossiano"},
    {"afip":"2006","location":"Campo San Antonio"},
    {"afip":"2014","location":"Campo Sol De Mayo"},
    {"afip":"2045","location":"Canals"},
    {"afip":"2057","location":"Candelaria Norte"},
    {"afip":"2058","location":"Candelaria Sud"},
    {"afip":"2061","location":"Candonga"},
    {"afip":"2078","location":"Cantera Los Vieras"},
    {"afip":"2083","location":"Canteras Alta Gracia"},
    {"afip":"2085","location":"Canteras De Quilpo"},
    {"afip":"2086","location":"Canteras El Manzano"},
    {"afip":"2087","location":"Canteras El Sauce"},
    {"afip":"2088","location":"Canteras Iguazu"},
    {"afip":"2089","location":"Canteras La Calera"},
    {"afip":"2090","location":"Canteras Los Morales"},
    {"afip":"2094","location":"Cap Gral Bernardo O Higins"},
    {"afip":"2106","location":"Capilla De Cosme"},
    {"afip":"2107","location":"Capilla De Dolores"},
    {"afip":"2108","location":"Capilla De Los Remedios"},
    {"afip":"2109","location":"Capilla De Romero"},
    {"afip":"2110","location":"Capilla De San Antonio"},
    {"afip":"2111","location":"Capilla De Siton"},
    {"afip":"2112","location":"Capilla De Tegua"},
    {"afip":"2113","location":"Capilla Del Carmen"},
    {"afip":"2115","location":"Capilla Del Monte"},
    {"afip":"2120","location":"Capilla La Candelaria"},
    {"afip":"2121","location":"Capilla La Esperanza"},
    {"afip":"2122","location":"Capilla San Antonio"},
    {"afip":"2123","location":"Capilla San Antonio De Yucat"},
    {"afip":"2125","location":"Capilla Santa Rosa"},
    {"afip":"2156","location":"Carahuasi"},
    {"afip":"2183","location":"Carlomagno"},
    {"afip":"2205","location":"Carnerillo"},
    {"afip":"2206","location":"Carnero Yaco"},
    {"afip":"2207","location":"Carolina"},
    {"afip":"2209","location":"Caroya"},
    {"afip":"2227","location":"Carrilobo"},
    {"afip":"2240","location":"Cartaberol"},
    {"afip":"2246","location":"Casa Bamba"},
    {"afip":"2263","location":"Casa Nueva"},
    {"afip":"2268","location":"Casa Serrana Huerta Grande"},
    {"afip":"2281","location":"Casas Vejas"},
    {"afip":"2282","location":"Casas Viejas"},
    {"afip":"2287","location":"Cascadas"},
    {"afip":"2290","location":"Caseros Este"},
    {"afip":"2302","location":"Caspichuma"},
    {"afip":"2303","location":"Caspicuchana"},
    {"afip":"2305","location":"Cassaffousth Estacion Fcgb"},
    {"afip":"2312","location":"Castellanos"},
    {"afip":"2344","location":"Cavanagh"},
    {"afip":"2353","location":"Cayuqueo"},
    {"afip":"2358","location":"Caña Cruz"}
    ,
    {"afip":"2364","location":"Cañada Ancha Santa Rosa"}
    ,
    {"afip":"2370","location":"Cañada De Alvarez"}
    ,
    {"afip":"2374","location":"Cañada De Coria"}
    ,
    {"afip":"2375","location":"Cañada De Cuevas"}
    ,
    {"afip":"2378","location":"Cañada De Jume"}
    ,
    {"afip":"2384","location":"Cañada De Las Chacras"}
    ,
    {"afip":"2385","location":"Cañada De Las Gatiadas"}
    ,
    {"afip":"2391","location":"Cañada De Luque"}
    ,
    {"afip":"2392","location":"Cañada De Machado"}
    ,
    {"afip":"2393","location":"Cañada De Machado Sud"}
    ,
    {"afip":"2394","location":"Cañada De Mateo"}
    ,
    {"afip":"2395","location":"Cañada De Mayo"}
    ,
    {"afip":"2397","location":"Cañada De Pocho"}
    ,
    {"afip":"2398","location":"Cañada De Rio Pinto"}
    ,
    {"afip":"2399","location":"Cañada De Salas"}
    ,
    {"afip":"2403","location":"Cañada Del Durazno"}
    ,
    {"afip":"2407","location":"Cañada Del Puerto"}
    ,
    {"afip":"2409","location":"Cañada Del Sauce"}
    ,
    {"afip":"2410","location":"Cañada Del Simbol"}
    ,
    {"afip":"2411","location":"Cañada Del Tala"}
    ,
    {"afip":"2416","location":"Cañada Grande"}
    ,
    {"afip":"2417","location":"Cañada Honda"}
    ,
    {"afip":"2431","location":"Cañada San Antonio"}
    ,
    {"afip":"2436","location":"Cañada Verde"}
    ,
    {"afip":"2439","location":"Cañadas Hondas"}
    ,
    {"afip":"2456","location":"Cañadon De Los Mogotes"}
    ,
    {"afip":"2526","location":"Cerritos De Anizacate"},
    {"afip":"2553","location":"Cerro Blanco"},
    {"afip":"2554","location":"Cerro Bola"},
    {"afip":"2582","location":"Cerro De La Cruz"},
    {"afip":"2726","location":"Cerro San Lorenzo"},
    {"afip":"2750","location":"Cerros Asperos"},
    {"afip":"2779","location":"Chacha Del Rey"},
    {"afip":"2793","location":"Chacras"},
    {"afip":"2803","location":"Chacras Del Potrero"},
    {"afip":"2804","location":"Chacras Del Sauce"},
    {"afip":"2808","location":"Chacras Viejas"},
    {"afip":"2817","location":"Chajan"},
    {"afip":"2820","location":"Chalacea"},
    {"afip":"2827","location":"Chamico"},
    {"afip":"2848","location":"Chaquinchuna"},
    {"afip":"2850","location":"Characato"},
    {"afip":"2854","location":"Charbonier"},
    {"afip":"2855","location":"Charcas Norte"},
    {"afip":"2862","location":"Charras"},
    {"afip":"2877","location":"Chazon"},
    {"afip":"2901","location":"Chañar Viejo"}
    ,
    {"afip":"2907","location":"Chañariaco"}
    ,
    {"afip":"2945","location":"Chile Corral Al Agauda"},
    {"afip":"2948","location":"Chilibroste"},
    {"afip":"2950","location":"Chilli Corral"},
    {"afip":"2968","location":"Chipitin"},
    {"afip":"3005","location":"Chua"},
    {"afip":"3008","location":"Chuchiras"},
    {"afip":"3011","location":"Chucul"},
    {"afip":"3022","location":"Churqui Cañada"}
    ,
    {"afip":"3030","location":"Chuña"}
    ,
    {"afip":"3032","location":"Chuña Huasi"}
    ,
    {"afip":"3037","location":"Cienaga De Allende"},
    {"afip":"3039","location":"Cienaga Del Coro"},
    {"afip":"3050","location":"Cintra"},
    {"afip":"3088","location":"Cnia Hogar Velez Sarsfield"},
    {"afip":"3091","location":"Cnia Vacaciones De Empleado"},
    {"afip":"3125","location":"Colazo"},
    {"afip":"3161","location":"Colonia 25 Los Surgentes"},
    {"afip":"3186","location":"Colonia Alemana"},
    {"afip":"3191","location":"Colonia Almada"},
    {"afip":"3207","location":"Colonia Angelita"},
    {"afip":"3209","location":"Colonia Anita"},
    {"afip":"3212","location":"Colonia Argentina"},
    {"afip":"3216","location":"Colonia Arroyo De Alvarez"},
    {"afip":"3227","location":"Colonia Ballesteros"},
    {"afip":"3228","location":"Colonia Banco Pcia Bs As"},
    {"afip":"3231","location":"Colonia Barge"},
    {"afip":"3242","location":"Colonia Beiro"},
    {"afip":"3256","location":"Colonia Bismarck"},
    {"afip":"3259","location":"Colonia Boero"},
    {"afip":"3263","location":"Colonia Botturi"},
    {"afip":"3268","location":"Colonia Bremen"},
    {"afip":"3276","location":"Colonia Calchaqui"},
    {"afip":"3292","location":"Colonia Caroya"},
    {"afip":"3298","location":"Colonia Cañadon"}
    ,
    {"afip":"3300","location":"Colonia Ceferina"},
    {"afip":"3317","location":"Colonia Cortadera"},
    {"afip":"3318","location":"Colonia Cosme Sud"},
    {"afip":"3319","location":"Colonia Coyunda"},
    {"afip":"3321","location":"Colonia Cristina"},
    {"afip":"3337","location":"Colonia Dean Funes"},
    {"afip":"3338","location":"Colonia Del Banco Nacion"},
    {"afip":"3350","location":"Colonia Dolores"},
    {"afip":"3355","location":"Colonia Dorotea"},
    {"afip":"3356","location":"Colonia Dos Hermanos"},
    {"afip":"3374","location":"Colonia El Carmen Paraje"},
    {"afip":"3376","location":"Colonia El Chaja"},
    {"afip":"3382","location":"Colonia El Fortin"},
    {"afip":"3386","location":"Colonia El Milagro"},
    {"afip":"3407","location":"Colonia El Trabajo"},
    {"afip":"3428","location":"Colonia Eugenia"},
    {"afip":"3454","location":"Colonia Garzon"},
    {"afip":"3456","location":"Colonia General Deheza"},
    {"afip":"3468","location":"Colonia Gorchs"},
    {"afip":"3481","location":"Colonia Hamburgo"},
    {"afip":"3494","location":"Colonia Holandesa"},
    {"afip":"3506","location":"Colonia Italiana"},
    {"afip":"3507","location":"Colonia Iturraspe"},
    {"afip":"3533","location":"Colonia La Argentina"},
    {"afip":"3541","location":"Colonia La Calle"},
    {"afip":"3544","location":"Colonia La Carmensita"},
    {"afip":"3546","location":"Colonia La Celestina"},
    {"afip":"3567","location":"Colonia La Leoncita"},
    {"afip":"3571","location":"Colonia La Magdalena De Oro"},
    {"afip":"3580","location":"Colonia La Morocha"},
    {"afip":"3581","location":"Colonia La Muriucha"},
    {"afip":"3591","location":"Colonia La Palestina"},
    {"afip":"3596","location":"Colonia La Piedra"},
    {"afip":"3598","location":"Colonia La Primavera"},
    {"afip":"3599","location":"Colonia La Providencia"},
    {"afip":"3606","location":"Colonia La Tordilla"},
    {"afip":"3609","location":"Colonia La Trinchera"},
    {"afip":"3622","location":"Colonia Las Cuatro Esquinas"},
    {"afip":"3631","location":"Colonia Lavarello"},
    {"afip":"3633","location":"Colonia Ledesma"},
    {"afip":"3662","location":"Colonia Los Vascos"},
    {"afip":"3671","location":"Colonia Luque"},
    {"afip":"3676","location":"Colonia Maipu"},
    {"afip":"3686","location":"Colonia Marina"},
    {"afip":"3690","location":"Colonia Maschi"},
    {"afip":"3695","location":"Colonia Maunier"},
    {"afip":"3706","location":"Colonia Milessi"},
    {"afip":"3717","location":"Colonia Montes Negros"},
    {"afip":"3744","location":"Colonia Nuevo Piamonte"},
    {"afip":"3754","location":"Colonia Orcovi"},
    {"afip":"3769","location":"Colonia Palo Labrado"},
    {"afip":"3773","location":"Colonia Paso Carril"},
    {"afip":"3794","location":"Colonia Prodamonte"},
    {"afip":"3796","location":"Colonia Progreso"},
    {"afip":"3797","location":"Colonia Prosperidad"},
    {"afip":"3829","location":"Colonia Sagrada Familia"},
    {"afip":"3832","location":"Colonia San Bartolome"},
    {"afip":"3843","location":"Colonia San Igancio"},
    {"afip":"3845","location":"Colonia San Isidro"},
    {"afip":"3859","location":"Colonia San Rafael"},
    {"afip":"3865","location":"Colonia Santa Ana"},
    {"afip":"3867","location":"Colonia Santa Catalina"},
    {"afip":"3876","location":"Colonia Santa Margarita"},
    {"afip":"3881","location":"Colonia Santa Paula"},
    {"afip":"3882","location":"Colonia Santa Rita"},
    {"afip":"3901","location":"Colonia Silvio Pellico"},
    {"afip":"3913","location":"Colonia Tacurales"},
    {"afip":"3920","location":"Colonia Tiroleza"},
    {"afip":"3922","location":"Colonia Toro Pujio"},
    {"afip":"3935","location":"Colonia Udine"},
    {"afip":"3941","location":"Colonia Valle Grande"},
    {"afip":"3942","location":"Colonia Valtelina"},
    {"afip":"3946","location":"Colonia Veinticinco"},
    {"afip":"3949","location":"Colonia Vicente Aguero"},
    {"afip":"3951","location":"Colonia Videla"},
    {"afip":"3952","location":"Colonia Vignaud"},
    {"afip":"3962","location":"Colonia Yareta"},
    {"afip":"3965","location":"Colonia Yucat Sud"},
    {"afip":"3971","location":"Colonias"},
    {"afip":"3979","location":"Columbo"},
    {"afip":"3993","location":"Come Tierra"},
    {"afip":"3994","location":"Comechingones"},
    {"afip":"4025","location":"Conlara"},
    {"afip":"4029","location":"Constitucion"},
    {"afip":"4047","location":"Copina"},
    {"afip":"4060","location":"Cordoba"},
    {"afip":"4063","location":"Corimayo"},
    {"afip":"4064","location":"Corito"},
    {"afip":"4076","location":"Coronel Baigorria"},
    {"afip":"4108","location":"Coronel Moldes"},
    {"afip":"4112","location":"Coronel Olmedo"},
    {"afip":"4125","location":"Corral De Barranca"},
    {"afip":"4126","location":"Corral De Bustos"},
    {"afip":"4128","location":"Corral De Ceballos"},
    {"afip":"4130","location":"Corral De Gomez"},
    {"afip":"4131","location":"Corral De Guardia"},
    {"afip":"4135","location":"Corral De Mulas"},
    {"afip":"4140","location":"Corral Del Bajo"},
    {"afip":"4142","location":"Corral Del Rey"},
    {"afip":"4151","location":"Corralito San Javier"},
    {"afip":"4168","location":"Cosquin"},
    {"afip":"4171","location":"Costa Alegre"},
    {"afip":"4185","location":"Costa Del Castaño"}
    ,
    {"afip":"4193","location":"Costa Del Rio Quinto"},
    {"afip":"4196","location":"Costa Del Tambo"},
    {"afip":"4210","location":"Costa Sacate"},
    {"afip":"4221","location":"Cotagaita"},
    {"afip":"4240","location":"Cristina"},
    {"afip":"4255","location":"Cruz Alta"},
    {"afip":"4259","location":"Cruz Chica"},
    {"afip":"4262","location":"Cruz De Caña"}
    ,
    {"afip":"4267","location":"Cruz Del Eje"},
    {"afip":"4269","location":"Cruz Del Quemado"},
    {"afip":"4275","location":"Cruz Mojada"},
    {"afip":"4294","location":"Cuatro Caminos"},
    {"afip":"4302","location":"Cuatro Vientos"},
    {"afip":"4308","location":"Cuchilla Nevada"},
    {"afip":"4314","location":"Cuchillo Yaco"},
    {"afip":"4320","location":"Cuesta Blanca"},
    {"afip":"4349","location":"Curapaligue"},
    {"afip":"4377","location":"Dalmacio Velez Sarsfield"},
    {"afip":"4391","location":"De La Serna"},
    {"afip":"4392","location":"Dean Funes"},
    {"afip":"4393","location":"Del Campillo"},
    {"afip":"4401","location":"Demarchi"},
    {"afip":"4403","location":"Desaguadero"},
    {"afip":"4422","location":"Desvio Chalacea"},
    {"afip":"4430","location":"Devoto"},
    {"afip":"4438","location":"Diego De Rojas"},
    {"afip":"4442","location":"Diez Rios"},
    {"afip":"4458","location":"Dique La Viña"}
    ,
    {"afip":"4460","location":"Dique Las Vaquerias"},
    {"afip":"4461","location":"Dique Los Molinos"},
    {"afip":"4466","location":"Dique San Roque"},
    {"afip":"4496","location":"Doctor Nicasio Salas Oroño"}
    ,
    {"afip":"4503","location":"Dolores Nuñez Del Prado"}
    ,
    {"afip":"4504","location":"Dolores San Esteban"},
    {"afip":"4509","location":"Domingo Funes"},
    {"afip":"4542","location":"Dos Arroyos"},
    {"afip":"4550","location":"Dos Lagunas"},
    {"afip":"4558","location":"Dos Rios"},
    {"afip":"4559","location":"Dos Rosas"},
    {"afip":"4574","location":"Duarte Quiros"},
    {"afip":"4581","location":"Dumesnil"},
    {"afip":"4604","location":"El  Pantanillo"},
    {"afip":"4622","location":"El Aguila Blanca"},
    {"afip":"4632","location":"El Alcalde"},
    {"afip":"4635","location":"El Algadobal"},
    {"afip":"4636","location":"El Algarrobal"},
    {"afip":"4637","location":"El Algarrobo"},
    {"afip":"4638","location":"El Algodonal"},
    {"afip":"4653","location":"El Arañado"}
    ,
    {"afip":"4654","location":"El Arbol"},
    {"afip":"4680","location":"El Bajo"},
    {"afip":"4682","location":"El Baldecito"},
    {"afip":"4713","location":"El Bordo"},
    {"afip":"4716","location":"El Bosque"},
    {"afip":"4723","location":"El Brete"},
    {"afip":"4742","location":"El Callejon"},
    {"afip":"4752","location":"El Cano"},
    {"afip":"4757","location":"El Caracol"},
    {"afip":"4769","location":"El Carmen Guiñazu"}
    ,
    {"afip":"4775","location":"El Carrilito"},
    {"afip":"4777","location":"El Carrizal Chuñaguasi"}
    ,
    {"afip":"4806","location":"El Cerro"},
    {"afip":"4814","location":"El Chacho"},
    {"afip":"4823","location":"El Chanchito"},
    {"afip":"4838","location":"El Chingolo"},
    {"afip":"4841","location":"El Chiquillan"},
    {"afip":"4881","location":"El Coro"},
    {"afip":"4888","location":"El Corte"},
    {"afip":"4892","location":"El Creston De Piedra"},
    {"afip":"4893","location":"El Crispin"},
    {"afip":"4931","location":"El Diquecito"},
    {"afip":"4932","location":"El Divisadero"},
    {"afip":"4938","location":"El Duraznito"},
    {"afip":"4948","location":"El Espinal"},
    {"afip":"4949","location":"El Espinillal"},
    {"afip":"4953","location":"El Estanque"},
    {"afip":"4963","location":"El Florentino"},
    {"afip":"4964","location":"El Florida"},
    {"afip":"4966","location":"El Fortin"},
    {"afip":"4969","location":"El Frances"},
    {"afip":"4972","location":"El Fuertecito"},
    {"afip":"4974","location":"El Gabino"},
    {"afip":"4976","location":"El Gallego"},
    {"afip":"4982","location":"El Gateado"},
    {"afip":"4994","location":"El Guaico"},
    {"afip":"5004","location":"El Guindo"},
    {"afip":"5016","location":"El Ialita"},
    {"afip":"5030","location":"El Jordan"},
    {"afip":"5031","location":"El Jume"},
    {"afip":"5033","location":"El Jumial"},
    {"afip":"5043","location":"El Laurel"},
    {"afip":"5063","location":"El Manantial"},
    {"afip":"5067","location":"El Manzano"},
    {"afip":"5094","location":"El Mojoncito"},
    {"afip":"5109","location":"El Moyano"},
    {"afip":"5130","location":"El Noy"},
    {"afip":"5136","location":"El Ochenta"},
    {"afip":"5141","location":"El Ojo De Agua"},
    {"afip":"5152","location":"El Overo"},
    {"afip":"5173","location":"El Panal"},
    {"afip":"5174","location":"El Pantanillo"},
    {"afip":"5175","location":"El Pantano"},
    {"afip":"5178","location":"El Parador De La Montaña"}
    ,
    {"afip":"5188","location":"El Paso"},
    {"afip":"5189","location":"El Paso De La Pampa"},
    {"afip":"5192","location":"El Pastor"},
    {"afip":"5194","location":"El Payador"},
    {"afip":"5198","location":"El Pedacito"},
    {"afip":"5209","location":"El Perchel"},
    {"afip":"5216","location":"El Pertigo"},
    {"afip":"5218","location":"El Peruel"},
    {"afip":"5227","location":"El Pilcado"},
    {"afip":"5233","location":"El Pingo"},
    {"afip":"5260","location":"El Porteño"}
    ,
    {"afip":"5261","location":"El Portillo"},
    {"afip":"5265","location":"El Potosi"},
    {"afip":"5277","location":"El Prado"},
    {"afip":"5278","location":"El Progreso"},
    {"afip":"5282","location":"El Puente"},
    {"afip":"5287","location":"El Puesto Los Cabrera"},
    {"afip":"5293","location":"El Quebracho"},
    {"afip":"5296","location":"El Quicho"},
    {"afip":"5307","location":"El Ranchito"},
    {"afip":"5309","location":"El Rastreador"},
    {"afip":"5329","location":"El Reymundo"},
    {"afip":"5333","location":"El Rio"},
    {"afip":"5352","location":"El Salto Norte"},
    {"afip":"5363","location":"El Sebil"},
    {"afip":"5373","location":"El Silverio"},
    {"afip":"5389","location":"El Sunchal"},
    {"afip":"5396","location":"El Tajamar"},
    {"afip":"5400","location":"El Talita"},
    {"afip":"5401","location":"El Talita Villa Gral Mitre"},
    {"afip":"5404","location":"El Tambero"},
    {"afip":"5406","location":"El Tambo"},
    {"afip":"5428","location":"El Tomillo"},
    {"afip":"5435","location":"El Torreon"},
    {"afip":"5437","location":"El Tostado"},
    {"afip":"5439","location":"El Trabajo"},
    {"afip":"5457","location":"El Tule"},
    {"afip":"5461","location":"El Tuscal"},
    {"afip":"5467","location":"El Vado"},
    {"afip":"5473","location":"El Vallesito"},
    {"afip":"5479","location":"El Veinticinco"},
    {"afip":"5490","location":"El Vergel"},
    {"afip":"5496","location":"El Vismal"},
    {"afip":"5509","location":"El Zaino"},
    {"afip":"5515","location":"El Zapato"},
    {"afip":"5526","location":"Elena"},
    {"afip":"5536","location":"Embalse"},
    {"afip":"5558","location":"Empalme Barrio Flores"},
    {"afip":"5576","location":"Encrucijada"},
    {"afip":"5579","location":"Enfermera Kelly"},
    {"afip":"5610","location":"Escobas"},
    {"afip":"5622","location":"Esperanza"},
    {"afip":"5630","location":"Espinillo"},
    {"afip":"5631","location":"Espinillo Nuñez Del Prado"}
    ,
    {"afip":"5635","location":"Esquina"},
    {"afip":"5639","location":"Esquina Del Alambre"},
    {"afip":"5678","location":"Estacion Achiras"},
    {"afip":"5688","location":"Estacion Bell Ville"},
    {"afip":"5691","location":"Estacion Calchin"},
    {"afip":"5692","location":"Estacion Caroya"},
    {"afip":"5696","location":"Estacion Colonia Tirolesa"},
    {"afip":"5703","location":"Estacion General Paz"},
    {"afip":"5723","location":"Estacion Punta De Agua"},
    {"afip":"5728","location":"Estacion Soto"},
    {"afip":"5751","location":"Estancia Bottaro"},
    {"afip":"5778","location":"Estancia De Guadalupe"},
    {"afip":"5784","location":"Estancia Dos Rios"},
    {"afip":"5789","location":"Estancia El Carmen"},
    {"afip":"5793","location":"Estancia El Chañar"}
    ,
    {"afip":"5803","location":"Estancia El Nacional"},
    {"afip":"5814","location":"Estancia El Taco"},
    {"afip":"5820","location":"Estancia Gorosito"},
    {"afip":"5839","location":"Estancia La Chiquita"},
    {"afip":"5862","location":"Estancia La Morocha"},
    {"afip":"5870","location":"Estancia La Punta Del Agua"},
    {"afip":"5884","location":"Estancia Las Cañas"}
    ,
    {"afip":"5893","location":"Estancia Las Margaritas"},
    {"afip":"5894","location":"Estancia Las Mercedes"},
    {"afip":"5895","location":"Estancia Las Rosas"},
    {"afip":"5911","location":"Estancia Los Matorrales"},
    {"afip":"5930","location":"Estancia Patiño"}
    ,
    {"afip":"6018","location":"Etruria"},
    {"afip":"6019","location":"Eufrasio Loza"},
    {"afip":"6044","location":"Fabrica Militar"},
    {"afip":"6045","location":"Fabrica Militar Rio Tercero"},
    {"afip":"6055","location":"Falda De Los Reartes"},
    {"afip":"6097","location":"Ferreyra"},
    {"afip":"6164","location":"Flora"},
    {"afip":"6237","location":"Fragueyro"},
    {"afip":"6258","location":"Fray Cayetano Rodriguez"},
    {"afip":"6262","location":"Freyre"},
    {"afip":"6306","location":"Galpon Chico"},
    {"afip":"6345","location":"Gavilan"},
    {"afip":"6358","location":"General Baldissera"},
    {"afip":"6361","location":"General Cabrera"},
    {"afip":"6366","location":"General Deheza"},
    {"afip":"6371","location":"General Fotheringham"},
    {"afip":"6396","location":"General Ortiz De Ocampo"},
    {"afip":"6406","location":"General Pueyrredon"},
    {"afip":"6410","location":"General Roca"},
    {"afip":"6414","location":"General Soler"},
    {"afip":"6433","location":"Gigena Alcira"},
    {"afip":"6446","location":"Glorialdo Fernandez"},
    {"afip":"6490","location":"Golpe De Agua"},
    {"afip":"6516","location":"Graciela"},
    {"afip":"6548","location":"Gruta De San Antonio"},
    {"afip":"6571","location":"Guallascate"},
    {"afip":"6579","location":"Guanaco Boleado"},
    {"afip":"6580","location":"Guanaco Muerto"},
    {"afip":"6600","location":"Guardia Vieja"},
    {"afip":"6601","location":"Guasapampa"},
    {"afip":"6603","location":"Guasta"},
    {"afip":"6606","location":"Guatimozin"},
    {"afip":"6640","location":"Guindas"},
    {"afip":"6645","location":"Gutemberg"},
    {"afip":"6675","location":"Haras San Antonio"},
    {"afip":"6678","location":"Haras Santa Martha"},
    {"afip":"6697","location":"Hernando"},
    {"afip":"6708","location":"Higuerias"},
    {"afip":"6709","location":"Higuerillas"},
    {"afip":"6721","location":"Hipolito Bouchard"},
    {"afip":"6734","location":"Holmberg"},
    {"afip":"6742","location":"Hornillos"},
    {"afip":"6749","location":"Hospital Flia Domingo Funes"},
    {"afip":"6789","location":"Huanchilla"},
    {"afip":"6790","location":"Huanchilla Sud"},
    {"afip":"6804","location":"Huascha"},
    {"afip":"6808","location":"Huasta"},
    {"afip":"6815","location":"Hucle"},
    {"afip":"6823","location":"Huerta Grande"},
    {"afip":"6836","location":"Huinca Renanco"},
    {"afip":"6874","location":"Idiazabal"},
    {"afip":"6878","location":"Iglesia Vieja"},
    {"afip":"6891","location":"Impira"},
    {"afip":"6899","location":"Independencia"},
    {"afip":"6921","location":"Ingeniero Bertini"},
    {"afip":"6973","location":"Inriville"},
    {"afip":"6997","location":"Irigoyen"},
    {"afip":"7007","location":"Ischilin"},
    {"afip":"7029","location":"Isla De San Antonio"},
    {"afip":"7031","location":"Isla Del Cerro"},
    {"afip":"7072","location":"Isleta Negra"},
    {"afip":"7086","location":"Italo"},
    {"afip":"7089","location":"Iti Huasi"},
    {"afip":"7120","location":"Jaime Peter"},
    {"afip":"7122","location":"James Craik"},
    {"afip":"7132","location":"Jarillas"},
    {"afip":"7140","location":"Jeanmaire"},
    {"afip":"7144","location":"Jeronimo Cortes"},
    {"afip":"7162","location":"Jose De La Quintana"},
    {"afip":"7181","location":"Jovita"},
    {"afip":"7200","location":"Juan Garcia"},
    {"afip":"7227","location":"Juarez Celman"},
    {"afip":"7234","location":"Julio Argentino Roca"},
    {"afip":"7238","location":"Jume"},
    {"afip":"7264","location":"Justiniano Posse"},
    {"afip":"7272","location":"Kilegruman"},
    {"afip":"7281","location":"L A Providencia"},
    {"afip":"7286","location":"La Abra"},
    {"afip":"7325","location":"La Arabia"},
    {"afip":"7336","location":"La Aura"},
    {"afip":"7354","location":"La Barranca"},
    {"afip":"7357","location":"La Barranquita"},
    {"afip":"7360","location":"La Batalla"},
    {"afip":"7361","location":"La Batea"},
    {"afip":"7372","location":"La Betania"},
    {"afip":"7374","location":"La Bismutina"},
    {"afip":"7385","location":"La Botija"},
    {"afip":"7390","location":"La Brianza"},
    {"afip":"7393","location":"La Buena Parada"},
    {"afip":"7399","location":"La Cajuela"},
    {"afip":"7416","location":"La Cantera"},
    {"afip":"7420","location":"La Carbonada"},
    {"afip":"7421","location":"La Carbonera"},
    {"afip":"7435","location":"La Cascada"},
    {"afip":"7447","location":"La Cañada Angosta"}
    ,
    {"afip":"7448","location":"La Cañada Grande"}
    ,
    {"afip":"7451","location":"La Cañada Santa Cruz"}
    ,
    {"afip":"7461","location":"La Cesira"},
    {"afip":"7465","location":"La Chacra"},
    {"afip":"7472","location":"La Chicharra"},
    {"afip":"7504","location":"La Cocha"},
    {"afip":"7515","location":"La Compasion"},
    {"afip":"7516","location":"La Concepcion"},
    {"afip":"7527","location":"La Cortadera"},
    {"afip":"7534","location":"La Cotita"},
    {"afip":"7540","location":"La Cruz"},
    {"afip":"7549","location":"La Cumbre"},
    {"afip":"7550","location":"La Cumbrecita"},
    {"afip":"7551","location":"La Curva"},
    {"afip":"7566","location":"La Dora"},
    {"afip":"7569","location":"La Dormida"},
    {"afip":"7617","location":"La Estacada"},
    {"afip":"7637","location":"La Falda Del Carmen"},
    {"afip":"7657","location":"La Francia"},
    {"afip":"7661","location":"La Fronda"},
    {"afip":"7662","location":"La Frontera"},
    {"afip":"7664","location":"La Fuente"},
    {"afip":"7677","location":"La Gilda"},
    {"afip":"7685","location":"La Gramilla"},
    {"afip":"7688","location":"La Granadilla"},
    {"afip":"7703","location":"La Herradura"},
    {"afip":"7732","location":"La Isabela"},
    {"afip":"7736","location":"La Isleta"},
    {"afip":"7737","location":"La Isolina"},
    {"afip":"7738","location":"La Italiana"},
    {"afip":"7742","location":"La Jarilla"},
    {"afip":"7756","location":"La Lagunilla"},
    {"afip":"7775","location":"La Lilia"},
    {"afip":"7778","location":"La Linea"},
    {"afip":"7839","location":"La Maza"},
    {"afip":"7847","location":"La Mercantil"},
    {"afip":"7851","location":"La Mesilla"},
    {"afip":"7853","location":"La Milka"},
    {"afip":"7865","location":"La Mostaza"},
    {"afip":"7866","location":"La Mudana"},
    {"afip":"7868","location":"La Nacional"},
    {"afip":"7895","location":"La Oscuridad"},
    {"afip":"7902","location":"La Paisanita"},
    {"afip":"7907","location":"La Palestina"},
    {"afip":"7912","location":"La Palmerina"},
    {"afip":"7922","location":"La Paquita"},
    {"afip":"7931","location":"La Patria"},
    {"afip":"7939","location":"La Penca"},
    {"afip":"7945","location":"La Perlita"},
    {"afip":"7959","location":"La Piedra Blanca"},
    {"afip":"7960","location":"La Piedra Movediza"},
    {"afip":"7965","location":"La Pintada"},
    {"afip":"7972","location":"La Playosa"},
    {"afip":"7973","location":"La Plaza"},
    {"afip":"7974","location":"La Poblacion"},
    {"afip":"7982","location":"La Porfia"},
    {"afip":"7987","location":"La Posta Chuñaguas"}
    ,
    {"afip":"8006","location":"La Puerta Villa De Soto"},
    {"afip":"8023","location":"La Quintana"},
    {"afip":"8028","location":"La Ramoncita"},
    {"afip":"8036","location":"La Redencion"},
    {"afip":"8037","location":"La Reduccion"},
    {"afip":"8040","location":"La Reina"},
    {"afip":"8053","location":"La Rinconada Candelaria"},
    {"afip":"8064","location":"La Rosarina"},
    {"afip":"8070","location":"La Ruda"},
    {"afip":"8090","location":"La Selva"},
    {"afip":"8094","location":"La Serranita"},
    {"afip":"8098","location":"La Siena"},
    {"afip":"8099","location":"La Sierrita"},
    {"afip":"8135","location":"La Tordilla Norte"},
    {"afip":"8141","location":"La Totorilla"},
    {"afip":"8142","location":"La Trampa"},
    {"afip":"8145","location":"La Travesia"},
    {"afip":"8147","location":"La Trinchera"},
    {"afip":"8151","location":"La Tuna"},
    {"afip":"8152","location":"La Tuna Tinoco"},
    {"afip":"8154","location":"La Udine"},
    {"afip":"8160","location":"La Usina"},
    {"afip":"8172","location":"La Ventana"},
    {"afip":"8177","location":"La Veronica"},
    {"afip":"8180","location":"La Vicenta"},
    {"afip":"8201","location":"La Zara"},
    {"afip":"8203","location":"La Zarita"},
    {"afip":"8210","location":"Laborde"},
    {"afip":"8213","location":"Laboulaye"},
    {"afip":"8216","location":"Ladera Yacus"},
    {"afip":"8258","location":"Laguna Clara"},
    {"afip":"8285","location":"Laguna Larga"},
    {"afip":"8286","location":"Laguna Larga Sud"},
    {"afip":"8295","location":"Laguna Oscura"},
    {"afip":"8305","location":"Laguna Seca"},
    {"afip":"8315","location":"Lagunilla"},
    {"afip":"8318","location":"Lagunillas"},
    {"afip":"8353","location":"Larsen"},
    {"afip":"8355","location":"Las Abahacas"},
    {"afip":"8357","location":"Las Abras"},
    {"afip":"8359","location":"Las Acacias"},
    {"afip":"8360","location":"Las Acequias"},
    {"afip":"8365","location":"Las Aguaditas"},
    {"afip":"8368","location":"Las Aleras"},
    {"afip":"8376","location":"Las Aromas"},
    {"afip":"8377","location":"Las Arrias"},
    {"afip":"8378","location":"Las Astillas"},
    {"afip":"8379","location":"Las Averias"},
    {"afip":"8382","location":"Las Bajadas"},
    {"afip":"8386","location":"Las Bandurrias Norte"},
    {"afip":"8404","location":"Las Cabras"},
    {"afip":"8405","location":"Las Calecitas"},
    {"afip":"8406","location":"Las Caleras"},
    {"afip":"8407","location":"Las Calles"},
    {"afip":"8408","location":"Las Canteras"},
    {"afip":"8411","location":"Las Cardas"},
    {"afip":"8425","location":"Las Cañadas"}
    ,
    {"afip":"8427","location":"Las Cañitas"}
    ,
    {"afip":"8429","location":"Las Cebollas"},
    {"afip":"8443","location":"Las Cinco Cuadras"},
    {"afip":"8451","location":"Las Conanitas"},
    {"afip":"8458","location":"Las Crucecitas"},
    {"afip":"8461","location":"Las Cuatro Esquinas"},
    {"afip":"8470","location":"Las Cusenadas"},
    {"afip":"8476","location":"Las Encadenadas"},
    {"afip":"8477","location":"Las Encrucijadas"},
    {"afip":"8478","location":"Las Ensenadas"},
    {"afip":"8490","location":"Las Gamas"},
    {"afip":"8498","location":"Las Gemelas"},
    {"afip":"8502","location":"Las Gramillas"},
    {"afip":"8506","location":"Las Guindas"},
    {"afip":"8509","location":"Las Heras"},
    {"afip":"8511","location":"Las Higueras"},
    {"afip":"8512","location":"Las Higuerillas"},
    {"afip":"8514","location":"Las Hileras"},
    {"afip":"8521","location":"Las Isletillas"},
    {"afip":"8523","location":"Las Jarillas"},
    {"afip":"8527","location":"Las Junturas"},
    {"afip":"8530","location":"Las Lagunitas"},
    {"afip":"8533","location":"Las Latas"},
    {"afip":"8548","location":"Las Manzanas"},
    {"afip":"8554","location":"Las Masitas"},
    {"afip":"8559","location":"Las Merceditas"},
    {"afip":"8568","location":"Las Moras"},
    {"afip":"8581","location":"Las Overias"},
    {"afip":"8586","location":"Las Palmeras"},
    {"afip":"8590","location":"Las Palomitas"},
    {"afip":"8592","location":"Las Pampillas"},
    {"afip":"8602","location":"Las Pencas"},
    {"afip":"8603","location":"Las Perdices"},
    {"afip":"8608","location":"Las Peñas Norte"}
    ,
    {"afip":"8609","location":"Las Peñas Sud"}
    ,
    {"afip":"8611","location":"Las Pichanas"},
    {"afip":"8613","location":"Las Piedras Anchas"},
    {"afip":"8617","location":"Las Piguas"},
    {"afip":"8622","location":"Las Playas"},
    {"afip":"8624","location":"Las Playas Lozada"},
    {"afip":"8633","location":"Las Quintas"},
    {"afip":"8634","location":"Las Rabonas"},
    {"afip":"8645","location":"Las Sesenta Cuadras"},
    {"afip":"8646","location":"Las Sierras"},
    {"afip":"8648","location":"Las Sierritas"},
    {"afip":"8668","location":"Las Tinajeras"},
    {"afip":"8675","location":"Las Totoritas"},
    {"afip":"8684","location":"Las Tres Piedras"},
    {"afip":"8692","location":"Las Tuscas"},
    {"afip":"8695","location":"Las Varas"},
    {"afip":"8696","location":"Las Varillas"},
    {"afip":"8703","location":"Las Vertientes De La Granja"},
    {"afip":"8721","location":"Latan Hall"},
    {"afip":"8746","location":"Lecueder"},
    {"afip":"8752","location":"Leguizamon"},
    {"afip":"8764","location":"Leones"},
    {"afip":"8837","location":"Lo Machado"},
    {"afip":"8839","location":"Lobera"},
    {"afip":"8852","location":"Loma Blanca"},
    {"afip":"8853","location":"Loma Bola"},
    {"afip":"8862","location":"Loma De Piedra"},
    {"afip":"8905","location":"Lomas Del Trozo"},
    {"afip":"8918","location":"Lomitas"},
    {"afip":"8955","location":"Los Alfalfares"},
    {"afip":"8956","location":"Los Algarrobitos"},
    {"afip":"8964","location":"Los Alvarez"},
    {"afip":"8984","location":"Los Aviles"},
    {"afip":"8991","location":"Los Barriales"},
    {"afip":"9004","location":"Los Bordos"},
    {"afip":"9008","location":"Los Brinzes"},
    {"afip":"9026","location":"Los Castaños"}
    ,
    {"afip":"9032","location":"Los Cedros"},
    {"afip":"9034","location":"Los Cejas"},
    {"afip":"9039","location":"Los Cerros Negros"},
    {"afip":"9051","location":"Los Chañaritos"}
    ,
    {"afip":"9059","location":"Los Cigarrales"},
    {"afip":"9061","location":"Los Cisnes"},
    {"afip":"9066","location":"Los Cocos"},
    {"afip":"9073","location":"Los Cometierra"},
    {"afip":"9076","location":"Los Condores"},
    {"afip":"9078","location":"Los Coquitos"},
    {"afip":"9098","location":"Los Desagues"},
    {"afip":"9103","location":"Los Dos Pozos"},
    {"afip":"9104","location":"Los Dos Rios"},
    {"afip":"9107","location":"Los Duraznos"},
    {"afip":"9111","location":"Los Eslabones"},
    {"afip":"9130","location":"Los Gauchos De Guemes"},
    {"afip":"9132","location":"Los Gigantes"},
    {"afip":"9145","location":"Los Guevara"},
    {"afip":"9149","location":"Los Helechos"},
    {"afip":"9155","location":"Los Hormigueros"},
    {"afip":"9156","location":"Los Hornillos"},
    {"afip":"9158","location":"Los Hoyos"},
    {"afip":"9164","location":"Los Jagueles"},
    {"afip":"9168","location":"Los Justes"},
    {"afip":"9184","location":"Los Manguitos"},
    {"afip":"9185","location":"Los Mansillas"},
    {"afip":"9189","location":"Los Medanos"},
    {"afip":"9200","location":"Los Miguelitos"},
    {"afip":"9202","location":"Los Miquiles"},
    {"afip":"9203","location":"Los Mistoles"},
    {"afip":"9206","location":"Los Molinos"},
    {"afip":"9228","location":"Los Ojos De Agua"},
    {"afip":"9229","location":"Los Olivares"},
    {"afip":"9243","location":"Los Pantalles"},
    {"afip":"9244","location":"Los Pantanillos"},
    {"afip":"9246","location":"Los Paraisos"},
    {"afip":"9248","location":"Los Paredones"},
    {"afip":"9253","location":"Los Pedernales"},
    {"afip":"9280","location":"Los Potreros"},
    {"afip":"9283","location":"Los Puentes"},
    {"afip":"9284","location":"Los Puestitos"},
    {"afip":"9289","location":"Los Quebrachos"},
    {"afip":"9302","location":"Los Reartes"},
    {"afip":"9307","location":"Los Reyunos"},
    {"afip":"9320","location":"Los Ruices"},
    {"afip":"9329","location":"Los Sauces"},
    {"afip":"9334","location":"Los Socabones"},
    {"afip":"9341","location":"Los Surgentes"},
    {"afip":"9345","location":"Los Tajamares"},
    {"afip":"9352","location":"Los Tartagos"},
    {"afip":"9353","location":"Los Tasis"},
    {"afip":"9359","location":"Los Terrones"},
    {"afip":"9375","location":"Los Tres Pozos"},
    {"afip":"9380","location":"Los Ucles"},
    {"afip":"9381","location":"Los Valdes"},
    {"afip":"9387","location":"Los Vazquez"},
    {"afip":"9400","location":"Los Zorros"},
    {"afip":"9404","location":"Lozada"},
    {"afip":"9409","location":"Luca"},
    {"afip":"9423","location":"Lucio V Mansilla"},
    {"afip":"9435","location":"Luis Sauze"},
    {"afip":"9449","location":"Luque"},
    {"afip":"9452","location":"Lutti"},
    {"afip":"9453","location":"Luxardo"},
    {"afip":"9454","location":"Luyaba"},
    {"afip":"9467","location":"Macha"},
    {"afip":"9509","location":"Majada De Santiago"},
    {"afip":"9512","location":"Majadilla"},
    {"afip":"9520","location":"Malagueño"}
    ,
    {"afip":"9530","location":"Malena"},
    {"afip":"9536","location":"Mallin"},
    {"afip":"9580","location":"Mandala"},
    {"afip":"9584","location":"Manfredi"},
    {"afip":"9589","location":"Manguitas"},
    {"afip":"9614","location":"Maquinista Gallini"},
    {"afip":"9637","location":"Marcos Juarez"},
    {"afip":"9652","location":"Maria"},
    {"afip":"9669","location":"Maria Lastenia"},
    {"afip":"9713","location":"Marull"},
    {"afip":"9724","location":"Matacos"},
    {"afip":"9739","location":"Matorrales"},
    {"afip":"9741","location":"Mattaldi"},
    {"afip":"9745","location":"Maunier"},
    {"afip":"9765","location":"Mayu Sumaj"},
    {"afip":"9798","location":"Media Luna"},
    {"afip":"9801","location":"Medio Naranja"},
    {"afip":"9806","location":"Melideo"},
    {"afip":"9810","location":"Melo"},
    {"afip":"9815","location":"Mendiolaza"},
    {"afip":"9828","location":"Mesa De Mariano"},
    {"afip":"9837","location":"Mesillas"},
    {"afip":"9844","location":"Mi Granja"},
    {"afip":"9845","location":"Mi Valle"},
    {"afip":"9859","location":"Miguel Salas"},
    {"afip":"9860","location":"Miguelito"},
    {"afip":"9870","location":"Mina Araujo"},
    {"afip":"9875","location":"Mina Clavero"},
    {"afip":"9890","location":"Mina La Bismutina"},
    {"afip":"9932","location":"Miquilos"},
    {"afip":"9979","location":"Modestino Pizarro"},
    {"afip":"9980","location":"Modesto Acuña"}
    ,
    {"afip":"9981","location":"Mogigasta"},
    {"afip":"9983","location":"Mogote Verde"},
    {"afip":"9995","location":"Molinari"},
    {"afip":"10001","location":"Molinos"},
    {"afip":"10037","location":"Monte Buey"},
    {"afip":"10040","location":"Monte Castillo"},
    {"afip":"10050","location":"Monte Cristo"},
    {"afip":"10051","location":"Monte De Los Gauchos"},
    {"afip":"10052","location":"Monte De Los Lazos"},
    {"afip":"10053","location":"Monte De Toro Pujio"},
    {"afip":"10054","location":"Monte Del Frayle"},
    {"afip":"10059","location":"Monte Grande Rafael Garcia"},
    {"afip":"10061","location":"Monte La Invernada"},
    {"afip":"10063","location":"Monte Leña"}
    ,
    {"afip":"10066","location":"Monte Maiz"},
    {"afip":"10071","location":"Monte Ralo"},
    {"afip":"10112","location":"Morrison"},
    {"afip":"10119","location":"Morteros"},
    {"afip":"10127","location":"Movado"},
    {"afip":"10134","location":"Mula Muerta"},
    {"afip":"10143","location":"Mussi"},
    {"afip":"10200","location":"Nazca"},
    {"afip":"10205","location":"Negro Huasi"},
    {"afip":"10219","location":"Nicho"},
    {"afip":"10220","location":"Nicolas Bruzone"},
    {"afip":"10227","location":"Nido Del Aguila"},
    {"afip":"10234","location":"Ninalquin"},
    {"afip":"10235","location":"Nintes"},
    {"afip":"10245","location":"Niña Paula"}
    ,
    {"afip":"10248","location":"Noetinger"},
    {"afip":"10257","location":"Nono"},
    {"afip":"10270","location":"Nueva Andalucia"},
    {"afip":"10284","location":"Nueva Esperanza"},
    {"afip":"10318","location":"Nuñez Del Prado"}
    ,
    {"afip":"10322","location":"Obispo Trejo"},
    {"afip":"10335","location":"Obregon"},
    {"afip":"10357","location":"Olaeta"},
    {"afip":"10364","location":"Oliva"},
    {"afip":"10365","location":"Olivares San Nicolas"},
    {"afip":"10383","location":"Oncativo"},
    {"afip":"10387","location":"Ongamira"},
    {"afip":"10392","location":"Oratorio De Peralta"},
    {"afip":"10394","location":"Orcosuni"},
    {"afip":"10395","location":"Ordoñez"}
    ,
    {"afip":"10401","location":"Oro Grueso"},
    {"afip":"10426","location":"Overa Negra"},
    {"afip":"10446","location":"Pachango"},
    {"afip":"10476","location":"Pajas Blancas"},
    {"afip":"10478","location":"Pajonal"},
    {"afip":"10520","location":"Palo Cortado"},
    {"afip":"10526","location":"Palo Negro"},
    {"afip":"10529","location":"Palo Quemado"},
    {"afip":"10532","location":"Paloma Pozo"},
    {"afip":"10565","location":"Pampa De Achala"},
    {"afip":"10575","location":"Pampa De Olaen"},
    {"afip":"10643","location":"Pampayasta Norte"},
    {"afip":"10644","location":"Pampayasta Sur"},
    {"afip":"10653","location":"Panaholma"},
    {"afip":"10758","location":"Parque Siquiman"},
    {"afip":"10766","location":"Pascanas"},
    {"afip":"10768","location":"Pasco"},
    {"afip":"10781","location":"Paso Cabral"},
    {"afip":"10782","location":"Paso Castellanos"},
    {"afip":"10816","location":"Paso De Los Gallegos"},
    {"afip":"10824","location":"Paso De Montoya"},
    {"afip":"10832","location":"Paso De Velez"},
    {"afip":"10839","location":"Paso Del Durazno"},
    {"afip":"10852","location":"Paso Del Sauce"},
    {"afip":"10853","location":"Paso Del Silverio"},
    {"afip":"10860","location":"Paso Grande"},
    {"afip":"10869","location":"Paso Las Tropas"},
    {"afip":"10903","location":"Paso Sandialito"},
    {"afip":"10910","location":"Paso Viejo"},
    {"afip":"10922","location":"Pastos Altos"},
    {"afip":"10941","location":"Paunero"},
    {"afip":"10944","location":"Pavin"},
    {"afip":"10964","location":"Pedro E Funes"},
    {"afip":"10965","location":"Pedro E Vivas"},
    {"afip":"11011","location":"Permanentes"},
    {"afip":"11058","location":"Pichanas"},
    {"afip":"11096","location":"Piedra Grande"},
    {"afip":"11100","location":"Piedra Movediza"},
    {"afip":"11102","location":"Piedra Pintada"},
    {"afip":"11108","location":"Piedras Amontonadas"},
    {"afip":"11109","location":"Piedras Anchas"},
    {"afip":"11111","location":"Piedras Blancas"},
    {"afip":"11114","location":"Piedras Grandes"},
    {"afip":"11117","location":"Piedrita Blanca"},
    {"afip":"11119","location":"Piedritas Rosadas"},
    {"afip":"11144","location":"Pinas"},
    {"afip":"11145","location":"Pincen"},
    {"afip":"11165","location":"Piquillin"},
    {"afip":"11183","location":"Pisco Huasi"},
    {"afip":"11188","location":"Pitoa"},
    {"afip":"11212","location":"Playa Grande"},
    {"afip":"11218","location":"Plaza Bruno"},
    {"afip":"11219","location":"Plaza De Mercedes"},
    {"afip":"11223","location":"Plaza Minetti"},
    {"afip":"11226","location":"Plaza Rodriguez"},
    {"afip":"11227","location":"Plaza San Francisco"},
    {"afip":"11231","location":"Plujunta"},
    {"afip":"11266","location":"Pocho"},
    {"afip":"11269","location":"Pocito Del Campo"},
    {"afip":"11311","location":"Porteña"}
    ,
    {"afip":"11368","location":"Potrero De Funes"},
    {"afip":"11369","location":"Potrero De Garay"},
    {"afip":"11377","location":"Potrero De Lujan"},
    {"afip":"11378","location":"Potrero De Marques"},
    {"afip":"11380","location":"Potrero De Tutzer"},
    {"afip":"11406","location":"Pozo Conca"},
    {"afip":"11407","location":"Pozo Correa"},
    {"afip":"11410","location":"Pozo De Juancho"},
    {"afip":"11411","location":"Pozo De La Esquina"},
    {"afip":"11413","location":"Pozo De La Loma"},
    {"afip":"11416","location":"Pozo De La Pampa"},
    {"afip":"11425","location":"Pozo De Las Ollas"},
    {"afip":"11427","location":"Pozo De Las Yeguas"},
    {"afip":"11429","location":"Pozo De Los Arboles"},
    {"afip":"11434","location":"Pozo De Los Troncos"},
    {"afip":"11436","location":"Pozo De Molina"},
    {"afip":"11442","location":"Pozo Del Avestruz"},
    {"afip":"11443","location":"Pozo Del Barrial"},
    {"afip":"11447","location":"Pozo Del Chaja"},
    {"afip":"11448","location":"Pozo Del Chañar"}
    ,
    {"afip":"11465","location":"Pozo Del Moro"},
    {"afip":"11470","location":"Pozo Del Simbol"},
    {"afip":"11492","location":"Pozo La Piedra"},
    {"afip":"11508","location":"Pozo Seco"},
    {"afip":"11510","location":"Pozo Solo"},
    {"afip":"11532","location":"Pretot Freyre"},
    {"afip":"11533","location":"Primavera"},
    {"afip":"11546","location":"Providencia"},
    {"afip":"11610","location":"Pueblo Alberdi"},
    {"afip":"11613","location":"Pueblo Argentino"},
    {"afip":"11618","location":"Pueblo Carlos Sauveran"},
    {"afip":"11629","location":"Pueblo Gambande"},
    {"afip":"11633","location":"Pueblo Italiano"},
    {"afip":"11648","location":"Pueblo Pianelli"},
    {"afip":"11653","location":"Pueblo Rio Tercero"},
    {"afip":"11660","location":"Pueblo Sarmiento"},
    {"afip":"11663","location":"Pueblo Viejo"},
    {"afip":"11692","location":"Puente Los Molles"},
    {"afip":"11702","location":"Puente Rio Plujunta"},
    {"afip":"11714","location":"Puerta Colorada"},
    {"afip":"11721","location":"Puerta De La Quebrada"},
    {"afip":"11926","location":"Puesto De Afuera"},
    {"afip":"11928","location":"Puesto De Arriba"},
    {"afip":"11930","location":"Puesto De Batalla"},
    {"afip":"11932","location":"Puesto De Castro"},
    {"afip":"11933","location":"Puesto De Cerro"},
    {"afip":"11937","location":"Puesto De Fierro"},
    {"afip":"11944","location":"Puesto De La Oveja"},
    {"afip":"11949","location":"Puesto De Los Alamos"},
    {"afip":"11952","location":"Puesto De Los Rodriguez"},
    {"afip":"11955","location":"Puesto De Luna"},
    {"afip":"11961","location":"Puesto De Pucheta"},
    {"afip":"11970","location":"Puesto Del Gallo"},
    {"afip":"11975","location":"Puesto Del Rosario"},
    {"afip":"11997","location":"Puesto Guzman"},
    {"afip":"12055","location":"Puesto Mulita"},
    {"afip":"12058","location":"Puesto Nuevo"},
    {"afip":"12077","location":"Puesto San Jose"},
    {"afip":"12090","location":"Puesto Viejo"},
    {"afip":"12114","location":"Punilla"},
    {"afip":"12133","location":"Punta Del Agua"},
    {"afip":"12141","location":"Punta Del Monte"},
    {"afip":"12173","location":"Pusisuna"},
    {"afip":"12177","location":"Quebrachitos"},
    {"afip":"12180","location":"Quebracho Herrado"},
    {"afip":"12181","location":"Quebracho Ladeado"},
    {"afip":"12184","location":"Quebracho Solo"},
    {"afip":"12186","location":"Quebrachos"},
    {"afip":"12192","location":"Quebrada De Los Pozos"},
    {"afip":"12194","location":"Quebrada De Luna"},
    {"afip":"12195","location":"Quebrada De Nona"},
    {"afip":"12198","location":"Quebrada Del Horno"},
    {"afip":"12234","location":"Quilino"},
    {"afip":"12269","location":"Quiscasacate"},
    {"afip":"12285","location":"Racedo"},
    {"afip":"12293","location":"Rafael Garcia"},
    {"afip":"12311","location":"Ramblon"},
    {"afip":"12318","location":"Ramirez"},
    {"afip":"12324","location":"Ramon J Carcano"},
    {"afip":"12345","location":"Rangel"},
    {"afip":"12347","location":"Ranqueles"},
    {"afip":"12356","location":"Rara Fortuna"},
    {"afip":"12363","location":"Rayo Cortado"},
    {"afip":"12391","location":"Recreo Victoria"},
    {"afip":"12394","location":"Reduccion"},
    {"afip":"12424","location":"Represa De Morales"},
    {"afip":"12471","location":"Rincon Casa Grande"},
    {"afip":"12498","location":"Rincon De Luna"},
    {"afip":"12555","location":"Rio Arriba"},
    {"afip":"12556","location":"Rio Bamba"},
    {"afip":"12563","location":"Rio Ceballos"},
    {"afip":"12568","location":"Rio Cuarto"},
    {"afip":"12574","location":"Rio De Jaime"},
    {"afip":"12577","location":"Rio De La Poblacion"},
    {"afip":"12579","location":"Rio De Las Manzanas"},
    {"afip":"12582","location":"Rio De Los Sauces"},
    {"afip":"12583","location":"Rio De Los Talas"},
    {"afip":"12585","location":"Rio Del Durazno"},
    {"afip":"12589","location":"Rio Dulce"},
    {"afip":"12594","location":"Rio Grande  Amboy"},
    {"afip":"12596","location":"Rio Hondo"},
    {"afip":"12610","location":"Rio Pedro"},
    {"afip":"12613","location":"Rio Pinto"},
    {"afip":"12615","location":"Rio Primero"},
    {"afip":"12619","location":"Rio San Miguel"},
    {"afip":"12622","location":"Rio Seco"},
    {"afip":"12623","location":"Rio Segundo"},
    {"afip":"12627","location":"Rio Tercero"},
    {"afip":"12631","location":"Rio Viejo"},
    {"afip":"12650","location":"Rodeito"},
    {"afip":"12658","location":"Rodeo De Los Caballos"},
    {"afip":"12659","location":"Rodeo De Piedra"},
    {"afip":"12666","location":"Rodeo Las Yeguas"},
    {"afip":"12670","location":"Rodeo Viejo"},
    {"afip":"12731","location":"Ruiz Diaz De Guzman"},
    {"afip":"12742","location":"Rumiaco"},
    {"afip":"12744","location":"Rumiguasi"},
    {"afip":"12790","location":"Sacanta"},
    {"afip":"12798","location":"Sagrada Familia"},
    {"afip":"12802","location":"Saira"},
    {"afip":"12803","location":"Sajon"},
    {"afip":"12831","location":"Saldan"},
    {"afip":"12836","location":"Salguero"},
    {"afip":"12855","location":"Salsacate"},
    {"afip":"12856","location":"Salsipuedes"},
    {"afip":"12871","location":"Sampacho"},
    {"afip":"12879","location":"San Ambrosio"},
    {"afip":"12887","location":"San Antonio De Arredondo"},
    {"afip":"12888","location":"San Antonio De Bella Vista"},
    {"afip":"12893","location":"San Antonio De Litin"},
    {"afip":"12901","location":"San Antonio De Yucat"},
    {"afip":"12906","location":"San Antonio Norte"},
    {"afip":"12912","location":"San Bartolo"},
    {"afip":"12913","location":"San Bartolome"},
    {"afip":"12914","location":"San Basilio"},
    {"afip":"12926","location":"San Carlos Minas"},
    {"afip":"12932","location":"San Clemente"},
    {"afip":"12936","location":"San Cristobal"},
    {"afip":"12949","location":"San Esteban"},
    {"afip":"12951","location":"San Eusebio"},
    {"afip":"12969","location":"San Francisco Del Chañar"}
    ,
    {"afip":"12974","location":"San Gabriel"},
    {"afip":"12996","location":"San Javier"},
    {"afip":"13002","location":"San Joaquin"},
    {"afip":"13018","location":"San Jose De La Dormida"},
    {"afip":"13022","location":"San Jose De Las Salinas"},
    {"afip":"13039","location":"San Jose Del Salteño"}
    ,
    {"afip":"13066","location":"San Lucas Norte"},
    {"afip":"13077","location":"San Marcos Sierra"},
    {"afip":"13078","location":"San Marcos Sud"},
    {"afip":"13091","location":"San Meliton"},
    {"afip":"13094","location":"San Miguel Chancani"},
    {"afip":"13101","location":"San Miguel San Vicente"},
    {"afip":"13124","location":"San Pedro De Toyos"},
    {"afip":"13126","location":"San Pedro Norte"},
    {"afip":"13128","location":"San Pellegrino"},
    {"afip":"13145","location":"San Roque Las Arrias"},
    {"afip":"13148","location":"San Salvador"},
    {"afip":"13159","location":"Sanabria"},
    {"afip":"13188","location":"Santa Cecilia"},
    {"afip":"13192","location":"Santa Clara"},
    {"afip":"13199","location":"Santa Cristina"},
    {"afip":"13207","location":"Santa Eufemia"},
    {"afip":"13228","location":"Santa Magdalena"},
    {"afip":"13234","location":"Santa Maria De Punilla"},
    {"afip":"13235","location":"Santa Maria De Sobremonte"},
    {"afip":"13243","location":"Santa Monica"},
    {"afip":"13255","location":"Santa Rosa De Calamuchita"},
    {"afip":"13262","location":"Santa Rosa De Rio Primero"},
    {"afip":"13266","location":"Santa Rosa Huerta Grande"},
    {"afip":"13270","location":"Santa Sabina"},
    {"afip":"13283","location":"Santa Victoria"},
    {"afip":"13285","location":"Santanilla"},
    {"afip":"13293","location":"Santiago Temple"},
    {"afip":"13312","location":"Sapansoto"},
    {"afip":"13324","location":"Sarlaco"},
    {"afip":"13325","location":"Sarmica"},
    {"afip":"13328","location":"Saturnino M Laspiur"},
    {"afip":"13334","location":"Sauce Arriba"},
    {"afip":"13338","location":"Sauce Chiquito"},
    {"afip":"13341","location":"Sauce De Los Quevedos"},
    {"afip":"13352","location":"Sauce Punco"},
    {"afip":"13381","location":"Sebastian El Cano"},
    {"afip":"13389","location":"Seeber"},
    {"afip":"13394","location":"Segunda Usina"},
    {"afip":"13408","location":"Sendas Grandes"},
    {"afip":"13417","location":"Serrano"},
    {"afip":"13419","location":"Serrezuela"},
    {"afip":"13424","location":"Sevilla"},
    {"afip":"13439","location":"Sierra Blanca"},
    {"afip":"13446","location":"Sierra De Abregu"},
    {"afip":"13451","location":"Sierra De Las Paredes"},
    {"afip":"13468","location":"Sierras Morenas"},
    {"afip":"13483","location":"Simbolar"},
    {"afip":"13493","location":"Sinsacate"},
    {"afip":"13501","location":"Soconcho"},
    {"afip":"13502","location":"Socorro"},
    {"afip":"13514","location":"Solar Los Molinos"},
    {"afip":"13515","location":"Solares De Ycho Cruz"},
    {"afip":"13540","location":"Soria"},
    {"afip":"13567","location":"Suco"},
    {"afip":"13581","location":"Sunchal"},
    {"afip":"13587","location":"Suncho Huico"},
    {"afip":"13613","location":"Tabanillo"},
    {"afip":"13614","location":"Tabaquillo"},
    {"afip":"13674","location":"Tacurel"},
    {"afip":"13688","location":"Tala Cañada"}
    ,
    {"afip":"13690","location":"Tala Cruz"},
    {"afip":"13691","location":"Tala Del Rio Seco"},
    {"afip":"13693","location":"Tala Huasi"},
    {"afip":"13695","location":"Tala Norte"},
    {"afip":"13700","location":"Tala Sud"},
    {"afip":"13708","location":"Talaini"},
    {"afip":"13742","location":"Tancacha"},
    {"afip":"13745","location":"Tanti"},
    {"afip":"13746","location":"Tanti Lomas"},
    {"afip":"13747","location":"Tanti Nuevo"},
    {"afip":"13774","location":"Taruca Pampa"},
    {"afip":"13777","location":"Tasacuna"},
    {"afip":"13779","location":"Tasma"},
    {"afip":"13796","location":"Tegua"},
    {"afip":"13799","location":"Tejeda"},
    {"afip":"13820","location":"Tercer Cuerpo Del Ejercito"},
    {"afip":"13821","location":"Tercera"},
    {"afip":"13844","location":"Ticino"},
    {"afip":"13852","location":"Tigre Muerto"},
    {"afip":"13861","location":"Tilquicho"},
    {"afip":"13881","location":"Tinoco"},
    {"afip":"13886","location":"Tintizaco"},
    {"afip":"13893","location":"Tio Pujio"},
    {"afip":"13919","location":"Todos Los Santos"},
    {"afip":"13927","location":"Toledo"},
    {"afip":"13934","location":"Tomas Echenique"},
    {"afip":"13952","location":"Tordilla Norte"},
    {"afip":"13969","location":"Toro Pujio"},
    {"afip":"13985","location":"Tosno"},
    {"afip":"13986","location":"Tosquita"},
    {"afip":"13989","location":"Totora Guasi"},
    {"afip":"13992","location":"Totoralejos"},
    {"afip":"13997","location":"Totoritas"},
    {"afip":"13999","location":"Totrilla"},
    {"afip":"14012","location":"Transito"},
    {"afip":"14036","location":"Tres Arboles"},
    {"afip":"14047","location":"Tres Chañares"}
    ,
    {"afip":"14053","location":"Tres Esquinas"},
    {"afip":"14098","location":"Trinchera"},
    {"afip":"14103","location":"Tristan Narvaja"},
    {"afip":"14112","location":"Tronco Pozo"},
    {"afip":"14133","location":"Tuclame"},
    {"afip":"14146","location":"Tulumba"},
    {"afip":"14169","location":"Tuscal"},
    {"afip":"14180","location":"Ucacha"},
    {"afip":"14195","location":"Unidad Turistica Embalse"},
    {"afip":"14198","location":"Unquillo"},
    {"afip":"14208","location":"Uritorco"},
    {"afip":"14216","location":"Usina Nuclear Embalse"},
    {"afip":"14237","location":"Vacas Blancas"},
    {"afip":"14260","location":"Valle Del Sol"},
    {"afip":"14263","location":"Valle Dorado"},
    {"afip":"14276","location":"Valle Verde"},
    {"afip":"14279","location":"Vanguardia"},
    {"afip":"14338","location":"Viamonte"},
    {"afip":"14353","location":"Vicuña Mackenna"}
    ,
    {"afip":"14378","location":"Villa Aguada De Los Reyes"},
    {"afip":"14380","location":"Villa Ahora"},
    {"afip":"14385","location":"Villa Albertina"},
    {"afip":"14388","location":"Villa Allende"},
    {"afip":"14389","location":"Villa Alpina"},
    {"afip":"14392","location":"Villa Amancay"},
    {"afip":"14397","location":"Villa Angelica"},
    {"afip":"14400","location":"Villa Anisacate"},
    {"afip":"14407","location":"Villa Ascasubi"},
    {"afip":"14412","location":"Villa Aurora"},
    {"afip":"14438","location":"Villa Bustos"},
    {"afip":"14440","location":"Villa Caeiro"},
    {"afip":"14442","location":"Villa Carlos Paz"},
    {"afip":"14443","location":"Villa Carlos Pellegrini"},
    {"afip":"14457","location":"Villa Cerro Azul"},
    {"afip":"14458","location":"Villa Cerro Negro"},
    {"afip":"14460","location":"Villa Ciudad De America"},
    {"afip":"14464","location":"Villa Clodomira"},
    {"afip":"14467","location":"Villa Colimba"},
    {"afip":"14472","location":"Villa Concepcion Del Tio"},
    {"afip":"14476","location":"Villa Corazon De Maria"},
    {"afip":"14481","location":"Villa Costa Azul"},
    {"afip":"14485","location":"Villa Cuesta Blanca"},
    {"afip":"14486","location":"Villa Cura Brochero"},
    {"afip":"14491","location":"Villa De Las Rosas"},
    {"afip":"14493","location":"Villa De Maria"},
    {"afip":"14496","location":"Villa De Soto"},
    {"afip":"14499","location":"Villa Del Dique"},
    {"afip":"14500","location":"Villa Del Lago"},
    {"afip":"14502","location":"Villa Del Parque"},
    {"afip":"14504","location":"Villa Del Prado"},
    {"afip":"14505","location":"Villa Del Rosario"},
    {"afip":"14507","location":"Villa Del Tala"},
    {"afip":"14508","location":"Villa Del Totoral"},
    {"afip":"14514","location":"Villa Diaz"},
    {"afip":"14527","location":"Villa El Chacay"},
    {"afip":"14529","location":"Villa El Corcovado"},
    {"afip":"14530","location":"Villa El Descanso"},
    {"afip":"14534","location":"Villa El Torreon"},
    {"afip":"14540","location":"Villa Emilia"},
    {"afip":"14546","location":"Villa Esquiu"},
    {"afip":"14556","location":"Villa Flor Serrana"},
    {"afip":"14559","location":"Villa Fontana"},
    {"afip":"14572","location":"Villa General Belgrano"},
    {"afip":"14575","location":"Villa General Mitre"},
    {"afip":"14581","location":"Villa Giardino"},
    {"afip":"14589","location":"Villa Gracia"},
    {"afip":"14597","location":"Villa Gutierrez"},
    {"afip":"14605","location":"Villa Huidobro"},
    {"afip":"14607","location":"Villa Independencia"},
    {"afip":"14621","location":"Villa La Bolsa"},
    {"afip":"14623","location":"Villa La Coba"},
    {"afip":"14628","location":"Villa La Rancherita"},
    {"afip":"14633","location":"Villa Lago Azul"},
    {"afip":"14637","location":"Villa Las Mercedes"},
    {"afip":"14644","location":"Villa Leonor"},
    {"afip":"14655","location":"Villa Los Altos"},
    {"afip":"14656","location":"Villa Los Aromos"},
    {"afip":"14658","location":"Villa Los Leones"},
    {"afip":"14674","location":"Villa Mar Chiquita"},
    {"afip":"14695","location":"Villa Mirea"},
    {"afip":"14697","location":"Villa Moderna"},
    {"afip":"14709","location":"Villa Naturaleza"},
    {"afip":"14713","location":"Villa Nueva"},
    {"afip":"14732","location":"Villa Parque Siquiman"},
    {"afip":"14740","location":"Villa Posse"},
    {"afip":"14750","location":"Villa Quilino"},
    {"afip":"14751","location":"Villa Quillinzo"},
    {"afip":"14755","location":"Villa Rafael Benegas"},
    {"afip":"14767","location":"Villa Rio Ycho Cruz"},
    {"afip":"14777","location":"Villa Rosario Del Saladillo"},
    {"afip":"14780","location":"Villa Rossi"},
    {"afip":"14784","location":"Villa Rumipal"},
    {"afip":"14791","location":"Villa San Esteban"},
    {"afip":"14793","location":"Villa San Javier"},
    {"afip":"14800","location":"Villa San Miguel"},
    {"afip":"14808","location":"Villa Santa Cruz Del Lago"},
    {"afip":"14809","location":"Villa Santa Isabel"},
    {"afip":"14812","location":"Villa Santa Rita"},
    {"afip":"14813","location":"Villa Santa Rosa"},
    {"afip":"14823","location":"Villa Satite"},
    {"afip":"14831","location":"Villa Sierras De Lago"},
    {"afip":"14838","location":"Villa Suiza Argentina"},
    {"afip":"14841","location":"Villa Taninga"},
    {"afip":"14846","location":"Villa Tortosa"},
    {"afip":"14857","location":"Villa Valeria"},
    {"afip":"14860","location":"Villa Vaudagna"},
    {"afip":"14863","location":"Villa Vieja"},
    {"afip":"14913","location":"Viso"},
    {"afip":"14926","location":"Vivero"},
    {"afip":"14958","location":"Washington"},
    {"afip":"14959","location":"Watt"},
    {"afip":"14963","location":"Wenceslao Escalante"},
    {"afip":"14972","location":"Yacanto"},
    {"afip":"14973","location":"Yacanto Calamuchita"},
    {"afip":"15008","location":"Yanacato"},
    {"afip":"15037","location":"Ycho Cruz Sierras"},
    {"afip":"15053","location":"Yocsina"},
    {"afip":"15095","location":"Zapata"},
    {"afip":"15099","location":"Zapoloco"},
    {"afip":"15143","location":"Ñu Pora"}
    ,
    {"afip":"15158","location":"9 De Julio"},
    {"afip":"15172","location":"Acostilla"},
    {"afip":"15200","location":"Aguadita"},
    {"afip":"15221","location":"Algarrobal"},
    {"afip":"15228","location":"Algarrobo"},
    {"afip":"15239","location":"Alta Gracia"},
    {"afip":"15249","location":"Alto Verde"},
    {"afip":"15265","location":"Angostura"},
    {"afip":"15311","location":"Avellaneda"},
    {"afip":"15326","location":"Bajo Hondo"},
    {"afip":"15374","location":"Belen"},
    {"afip":"15379","location":"Bella Vista"},
    {"afip":"15411","location":"Buen Retiro"},
    {"afip":"15419","location":"Buena Vista"},
    {"afip":"15429","location":"Buey Muerto"},
    {"afip":"15432","location":"Cabeza De Buey"},
    {"afip":"15442","location":"Camarones"},
    {"afip":"15456","location":"Campo Grande"},
    {"afip":"15471","location":"Campo San Juan"},
    {"afip":"15473","location":"Candelaria"},
    {"afip":"15494","location":"Carrizal"},
    {"afip":"15499","location":"Casa Blanca"},
    {"afip":"15500","location":"Casa De Piedra"},
    {"afip":"15505","location":"Casa Grande"},
    {"afip":"15536","location":"Cañada Larga"}
    ,
    {"afip":"15581","location":"Cerro Colorado"},
    {"afip":"15593","location":"Cerro Negro"},
    {"afip":"15628","location":"Chañaritos"}
    ,
    {"afip":"15687","location":"Colonia La Lola"},
    {"afip":"15730","location":"Colonia San Pedro"},
    {"afip":"15743","location":"Colonia Santa Maria"},
    {"afip":"15760","location":"Concepcion"},
    {"afip":"15766","location":"Condor Huasi"},
    {"afip":"15770","location":"Copacabana"},
    {"afip":"15786","location":"Corral Viejo"},
    {"afip":"15789","location":"Corralito"},
    {"afip":"15797","location":"Cortaderas"},
    {"afip":"15823","location":"Despeñaderos"}
    ,
    {"afip":"15842","location":"Durazno"},
    {"afip":"15871","location":"El Alto"},
    {"afip":"15888","location":"El Bagual"},
    {"afip":"15902","location":"El Barreal"},
    {"afip":"15904","location":"El Barrial"},
    {"afip":"15911","location":"El Bañado"}
    ,
    {"afip":"15943","location":"El Carmen"},
    {"afip":"15951","location":"El Carrizal"},
    {"afip":"15973","location":"El Cerrito"},
    {"afip":"16014","location":"El Cuadrado"},
    {"afip":"16016","location":"El Descanso"},
    {"afip":"16020","location":"El Desmonte"},
    {"afip":"16033","location":"El Dorado"},
    {"afip":"16040","location":"El Durazno"},
    {"afip":"16054","location":"El Espinillo"},
    {"afip":"16070","location":"El Guanaco"},
    {"afip":"16097","location":"El Mangrullo"},
    {"afip":"16113","location":"El Mirador"},
    {"afip":"16126","location":"El Molino"},
    {"afip":"16171","location":"El Pampero"},
    {"afip":"16175","location":"El Paraiso"},
    {"afip":"16202","location":"El Portezuelo"},
    {"afip":"16212","location":"El Porvenir"},
    {"afip":"16227","location":"El Potrero"},
    {"afip":"16233","location":"El Pozo"},
    {"afip":"16243","location":"El Pueblito"},
    {"afip":"16256","location":"El Puesto"},
    {"afip":"16264","location":"El Quebrachal"},
    {"afip":"16268","location":"El Quebrachito"},
    {"afip":"16305","location":"El Rincon"},
    {"afip":"16312","location":"El Rodeito"},
    {"afip":"16313","location":"El Rodeo"},
    {"afip":"16320","location":"El Rosario"},
    {"afip":"16340","location":"El Salto"},
    {"afip":"16348","location":"El Sauce"},
    {"afip":"16358","location":"El Sauzal"},
    {"afip":"16364","location":"El Simbol"},
    {"afip":"16366","location":"El Simbolar"},
    {"afip":"16382","location":"El Tala"},
    {"afip":"16386","location":"El Talar"},
    {"afip":"16398","location":"El Tio"},
    {"afip":"16410","location":"El Triangulo"},
    {"afip":"16426","location":"El Vallecito"},
    {"afip":"16432","location":"El Vence"},
    {"afip":"16441","location":"El Zapallar"},
    {"afip":"16542","location":"General Las Heras"},
    {"afip":"16543","location":"General Lavalle"},
    {"afip":"16546","location":"General Viamonte"},
    {"afip":"16581","location":"India Muerta"},
    {"afip":"16584","location":"Invernada"},
    {"afip":"16595","location":"Isla Larga"},
    {"afip":"16597","location":"Isla Verde"},
    {"afip":"16606","location":"Jesus Maria"},
    {"afip":"16612","location":"Juan Bautista Alberdi"},
    {"afip":"16625","location":"La Aguada"},
    {"afip":"16631","location":"La Aguadita"},
    {"afip":"16655","location":"La Argentina"},
    {"afip":"16681","location":"La Banda"},
    {"afip":"16707","location":"La Calera"},
    {"afip":"16723","location":"La Carlota"},
    {"afip":"16731","location":"La Cautiva"},
    {"afip":"16738","location":"La Cañada"}
    ,
    {"afip":"16751","location":"La Celina"},
    {"afip":"16765","location":"La Choza"},
    {"afip":"16766","location":"La Cienaga"},
    {"afip":"16787","location":"La Colonia"},
    {"afip":"16794","location":"La Colorada"},
    {"afip":"16812","location":"La Costa"},
    {"afip":"16873","location":"La Esperanza"},
    {"afip":"16885","location":"La Esquina"},
    {"afip":"16894","location":"La Estancia"},
    {"afip":"16898","location":"La Estancita"},
    {"afip":"16904","location":"La Estrella"},
    {"afip":"16913","location":"La Falda"},
    {"afip":"16933","location":"La Florida"},
    {"afip":"16962","location":"La Granja"},
    {"afip":"16967","location":"La Guardia"},
    {"afip":"16976","location":"La Higuera"},
    {"afip":"16980","location":"La Higuerita"},
    {"afip":"16995","location":"La Invernada"},
    {"afip":"17008","location":"La Isla"},
    {"afip":"17028","location":"La Laguna"},
    {"afip":"17066","location":"La Luz"},
    {"afip":"17071","location":"La Majada"},
    {"afip":"17101","location":"La Mesada"},
    {"afip":"17129","location":"La Palma"},
    {"afip":"17138","location":"La Pampa"},
    {"afip":"17144","location":"La Para"},
    {"afip":"17150","location":"La Paz"},
    {"afip":"17172","location":"La Playa"},
    {"afip":"17175","location":"La Pobladora"},
    {"afip":"17181","location":"La Porteña"}
    ,
    {"afip":"17190","location":"La Posta"},
    {"afip":"17196","location":"La Primavera"},
    {"afip":"17211","location":"La Puerta"},
    {"afip":"17221","location":"La Quebrada"},
    {"afip":"17228","location":"La Quinta"},
    {"afip":"17232","location":"La Ramada"},
    {"afip":"17249","location":"La Represa"},
    {"afip":"17258","location":"La Rinconada"},
    {"afip":"17295","location":"La Soledad"},
    {"afip":"17306","location":"La Tablada"},
    {"afip":"17312","location":"La Tigra"},
    {"afip":"17314","location":"La Toma"},
    {"afip":"17355","location":"La Victoria"},
    {"afip":"17361","location":"La Virginia"},
    {"afip":"17370","location":"La Zanja"},
    {"afip":"17380","location":"Laguna Brava"},
    {"afip":"17384","location":"Laguna De Gomez"},
    {"afip":"17424","location":"Las Bandurrias"},
    {"afip":"17440","location":"Las Casitas"},
    {"afip":"17445","location":"Las Cañas"}
    ,
    {"afip":"17458","location":"Las Chacras"},
    {"afip":"17480","location":"Las Cortaderas"},
    {"afip":"17494","location":"Las Delicias"},
    {"afip":"17509","location":"Las Flores"},
    {"afip":"17530","location":"Las Higueritas"},
    {"afip":"17535","location":"Las Horquetas"},
    {"afip":"17540","location":"Las Juntas"},
    {"afip":"17557","location":"Las Lomas"},
    {"afip":"17562","location":"Las Lomitas"},
    {"afip":"17577","location":"Las Mercedes"},
    {"afip":"17590","location":"Las Mojarras"},
    {"afip":"17597","location":"Las Oscuras"},
    {"afip":"17600","location":"Las Palmas"},
    {"afip":"17608","location":"Las Palmitas"},
    {"afip":"17612","location":"Las Palomas"},
    {"afip":"17622","location":"Las Peñas"}
    ,
    {"afip":"17630","location":"Las Piedritas"},
    {"afip":"17647","location":"Las Rosas"},
    {"afip":"17655","location":"Las Saladas"},
    {"afip":"17667","location":"Las Tapias"},
    {"afip":"17674","location":"Las Toscas"},
    {"afip":"17682","location":"Las Trancas"},
    {"afip":"17691","location":"Las Vaquerias"},
    {"afip":"17693","location":"Las Vertientes"},
    {"afip":"17740","location":"Loma Redonda"},
    {"afip":"17758","location":"Los Alamos"},
    {"afip":"17765","location":"Los Algarrobos"},
    {"afip":"17797","location":"Los Cadillos"},
    {"afip":"17798","location":"Los Cajones"},
    {"afip":"17800","location":"Los Callejones"},
    {"afip":"17802","location":"Los Cardos"},
    {"afip":"17808","location":"Los Cerrillos"},
    {"afip":"17814","location":"Los Cerros"},
    {"afip":"17817","location":"Los Chañares"}
    ,
    {"afip":"17856","location":"Los Guindos"},
    {"afip":"17861","location":"Los Huesos"},
    {"afip":"17880","location":"Los Mogotes"},
    {"afip":"17886","location":"Los Molles"},
    {"afip":"17894","location":"Los Morteros"},
    {"afip":"17924","location":"Los Piquillines"},
    {"afip":"17926","location":"Los Pocitos"},
    {"afip":"17934","location":"Los Pozos"},
    {"afip":"17943","location":"Los Quebrachitos"},
    {"afip":"17971","location":"Los Troncos"},
    {"afip":"17992","location":"Malvinas Argentinas"},
    {"afip":"17997","location":"Manantiales"},
    {"afip":"18007","location":"Mar Azul"},
    {"afip":"18023","location":"Medanitos"},
    {"afip":"18050","location":"Miraflores"},
    {"afip":"18053","location":"Miramar"},
    {"afip":"18070","location":"Monsalvo"},
    {"afip":"18076","location":"Monte Grande"},
    {"afip":"18084","location":"Monte Redondo"},
    {"afip":"18108","location":"Navarro"},
    {"afip":"18130","location":"Ojo De Agua"},
    {"afip":"18142","location":"Olmos"},
    {"afip":"18175","location":"Palo Labrado"},
    {"afip":"18178","location":"Palo Parado"},
    {"afip":"18244","location":"Piedra Blanca"},
    {"afip":"18266","location":"Pilar"},
    {"afip":"18319","location":"Pozo Del Molle"},
    {"afip":"18323","location":"Pozo Del Tigre"},
    {"afip":"18387","location":"Puesto De Vera"},
    {"afip":"18389","location":"Puesto Del Medio"},
    {"afip":"18443","location":"Quilmes"},
    {"afip":"18451","location":"Ramallo"},
    {"afip":"18477","location":"Rincon"},
    {"afip":"18497","location":"Rio Chico"},
    {"afip":"18506","location":"Rio Grande"},
    {"afip":"18511","location":"Rio Los Molinos"},
    {"afip":"18536","location":"Rojas"},
    {"afip":"18539","location":"Rosales"},
    {"afip":"18545","location":"Saladillo"},
    {"afip":"18559","location":"Salto"},
    {"afip":"18561","location":"San Agustin"},
    {"afip":"18586","location":"San Antonio"},
    {"afip":"18606","location":"San Bernardo"},
    {"afip":"18613","location":"San Buenaventura"},
    {"afip":"18617","location":"San Carlos"},
    {"afip":"18666","location":"San Francisco"},
    {"afip":"18680","location":"San Geronimo"},
    {"afip":"18695","location":"San Ignacio"},
    {"afip":"18707","location":"San Isidro"},
    {"afip":"18728","location":"San Jeronimo"},
    {"afip":"18735","location":"San Jorge"},
    {"afip":"18745","location":"San Jose"},
    {"afip":"18771","location":"San Juancito"},
    {"afip":"18785","location":"San Lorenzo"},
    {"afip":"18800","location":"San Luis"},
    {"afip":"18814","location":"San Martin"},
    {"afip":"18826","location":"San Miguel"},
    {"afip":"18839","location":"San Nicolas"},
    {"afip":"18845","location":"San Pablo"},
    {"afip":"18854","location":"San Pedro"},
    {"afip":"18871","location":"San Rafael"},
    {"afip":"18879","location":"San Ramon"},
    {"afip":"18889","location":"San Roque"},
    {"afip":"18905","location":"San Sebastian"},
    {"afip":"18918","location":"San Vicente"},
    {"afip":"18929","location":"Santa Ana"},
    {"afip":"18948","location":"Santa Catalina"},
    {"afip":"18967","location":"Santa Cruz"},
    {"afip":"18973","location":"Santa Elena"},
    {"afip":"18994","location":"Santa Isabel"},
    {"afip":"19006","location":"Santa Lucia"},
    {"afip":"19020","location":"Santa Maria"},
    {"afip":"19037","location":"Santa Rita"},
    {"afip":"19047","location":"Santa Rosa"},
    {"afip":"19060","location":"Santa Teresa"},
    {"afip":"19075","location":"Santo Domingo"},
    {"afip":"19089","location":"Santo Tomas"},
    {"afip":"19103","location":"Sarmiento"},
    {"afip":"19145","location":"Soledad"},
    {"afip":"19163","location":"Taco Pozo"},
    {"afip":"19169","location":"Tajamares"},
    {"afip":"19194","location":"Toro Muerto"},
    {"afip":"19201","location":"Totoral"},
    {"afip":"19239","location":"Tres Lomas"},
    {"afip":"19248","location":"Tres Pozos"},
    {"afip":"19270","location":"Valle Hermoso"},
    {"afip":"19293","location":"Villa Dolores"},
    {"afip":"19311","location":"Villa Maria"},
    {"afip":"19332","location":"Villa Santa Maria"},
    {"afip":"19335","location":"Villa Sarmiento"},
    {"afip":"19348","location":"Vista Alegre"},
    {"afip":"19356","location":"Yatay"},
    {"afip":"19359","location":"Yerba Buena"},
    {"afip":"19371","location":"General Levalle"},
    {"afip":"19376","location":"Cerro De Rosas"},
    {"afip":"19377","location":"B Lugones"},
    {"afip":"19379","location":"Barrio Empalme"},
    {"afip":"21196","location":"Lomitas"},
    {"afip":"21197","location":"Los Cerrillos"},
    {"afip":"21198","location":"Manantiales"},
    {"afip":"21199","location":"Pozo Del Tigre"},
    {"afip":"21200","location":"San Pablo"},
    {"afip":"21201","location":"Santa Ana"},
    {"afip":"21202","location":"Santo Domingo"},
    {"afip":"21203","location":"Punta Del Agua"},
    {"afip":"21204","location":"Campo Alegre"},
    {"afip":"21205","location":"Candelaria"},
    {"afip":"21206","location":"Casas Viejas"},
    {"afip":"21207","location":"El Rincon"},
    {"afip":"21208","location":"Espinillo"},
    {"afip":"21209","location":"La Aguada"},
    {"afip":"21210","location":"La Paz"},
    {"afip":"21211","location":"Las Peñas"}
    ,
    {"afip":"21212","location":"Los Mistoles"},
    {"afip":"21213","location":"Rio De Los Sauces"},
    {"afip":"21214","location":"San Jose"},
    {"afip":"21215","location":"San Lorenzo"},
    {"afip":"21216","location":"San Miguel"},
    {"afip":"21217","location":"Santa Catalina"},
    {"afip":"21218","location":"Santa Maria"},
    {"afip":"21219","location":"Simbolar"},
    {"afip":"21220","location":"Cerro Colorado"},
    {"afip":"21221","location":"El Durazno"},
    {"afip":"21222","location":"El Perchel"},
    {"afip":"21223","location":"El Rodeo"},
    {"afip":"21224","location":"Espinillo"},
    {"afip":"21225","location":"La Costa"},
    {"afip":"21226","location":"La Esperanza"},
    {"afip":"21227","location":"La Higuerita"},
    {"afip":"21228","location":"La Laguna"},
    {"afip":"21229","location":"Las Cañas"}
    ,
    {"afip":"21230","location":"Las Lomitas"},
    {"afip":"21231","location":"Las Palmas"},
    {"afip":"21232","location":"Los Pozos"},
    {"afip":"21233","location":"Majadilla"},
    {"afip":"21234","location":"Miraflores"},
    {"afip":"21235","location":"San Jose"},
    {"afip":"21236","location":"San Roque"},
    {"afip":"21237","location":"Alto Alegre"},
    {"afip":"21238","location":"El Carmen"},
    {"afip":"21239","location":"El Paraiso"},
    {"afip":"21240","location":"Los Molles"},
    {"afip":"21241","location":"San Carlos"},
    {"afip":"21242","location":"San Jose"},
    {"afip":"21243","location":"San Pedro"},
    {"afip":"21244","location":"San Vicente"},
    {"afip":"21245","location":"Santa Maria"},
    {"afip":"21246","location":"Santa Rosa"},
    {"afip":"21252","location":"Los Pozos"},
    {"afip":"21253","location":"Pozo Del Simbol"},
    {"afip":"21254","location":"San Ignacio"},
    {"afip":"21255","location":"San Ramon"},
    {"afip":"21256","location":"Santa Catalina"},
    {"afip":"21257","location":"Santa Elena"},
    {"afip":"21258","location":"Santa Isabel"},
    {"afip":"21259","location":"El Carrizal"},
    {"afip":"21260","location":"Los Chañaritos"}
    ,
    {"afip":"21261","location":"San Jose"},
    {"afip":"21262","location":"Buena Vista"},
    {"afip":"21263","location":"Carrizal"},
    {"afip":"21264","location":"Cerro Negro"},
    {"afip":"21265","location":"Comechingones"},
    {"afip":"21266","location":"Dos Rios"},
    {"afip":"21267","location":"El Perchel"},
    {"afip":"21268","location":"Isla Verde"},
    {"afip":"21269","location":"La Aguadita"},
    {"afip":"21270","location":"La Costa"},
    {"afip":"21271","location":"La Majada"},
    {"afip":"21272","location":"La Quebrada"},
    {"afip":"21273","location":"La Quinta"},
    {"afip":"21274","location":"Las Ensenadas"},
    {"afip":"21275","location":"Las Palmitas"},
    {"afip":"21276","location":"Las Toscas"},
    {"afip":"21277","location":"Los Callejones"},
    {"afip":"21278","location":"Monte Redondo"},
    {"afip":"21279","location":"Ojo De Agua"},
    {"afip":"21280","location":"Piedra Blanca"},
    {"afip":"21281","location":"San Pedro"},
    {"afip":"21282","location":"San Rafael"},
    {"afip":"21283","location":"San Vicente"},
    {"afip":"21284","location":"Santa Rita"},
    {"afip":"21285","location":"Achiras"},
    {"afip":"21286","location":"Cañada Grande"}
    ,
    {"afip":"21287","location":"El Alto"},
    {"afip":"21288","location":"El Baldecito"},
    {"afip":"21289","location":"El Manantial"},
    {"afip":"21290","location":"El Pueblito"},
    {"afip":"21291","location":"La Cañada"}
    ,
    {"afip":"21292","location":"La Ramada"},
    {"afip":"21293","location":"Las Cañadas"}
    ,
    {"afip":"21294","location":"Las Chacras"},
    {"afip":"21295","location":"Las Tapias"},
    {"afip":"21296","location":"Los Cerrillos"},
    {"afip":"21297","location":"Los Chañares"}
    ,
    {"afip":"21298","location":"Los Chañaritos"}
    ,
    {"afip":"21299","location":"Los Molles"},
    {"afip":"21300","location":"Los Pozos"},
    {"afip":"21301","location":"Pozo Del Molle"},
    {"afip":"21302","location":"Rio Hondo"},
    {"afip":"21303","location":"Sagrada Familia"},
    {"afip":"21304","location":"San Antonio"},
    {"afip":"21305","location":"San Isidro"},
    {"afip":"21306","location":"San Jose"},
    {"afip":"21307","location":"San Nicolas"},
    {"afip":"21308","location":"San Roque"},
    {"afip":"21309","location":"Colonia Santa Rita"},
    {"afip":"21310","location":"Corral De Gomez"},
    {"afip":"21311","location":"La Cortadera"},
    {"afip":"21312","location":"Las Cañitas"}
    ,
    {"afip":"21313","location":"Las Delicias"},
    {"afip":"21314","location":"Los Algarrobitos"},
    {"afip":"21315","location":"Monte Redondo"},
    {"afip":"21316","location":"Pozo Del Chañar"}
    ,
    {"afip":"21317","location":"San Rafael"},
    {"afip":"21318","location":"Santa Rita"},
    {"afip":"21319","location":"Buena Vista"},
    {"afip":"21320","location":"La Cocha"},
    {"afip":"21321","location":"La Lagunilla"},
    {"afip":"21322","location":"Lagunilla"},
    {"afip":"21323","location":"Las Higueritas"},
    {"afip":"21324","location":"Los Algarrobos"},
    {"afip":"21325","location":"Los Cerrillos"},
    {"afip":"21326","location":"San Antonio"},
    {"afip":"21327","location":"San Nicolas"},
    {"afip":"21328","location":"Santa Ana"},
    {"afip":"21329","location":"Santa Rita"},
    {"afip":"21330","location":"Cañada Del Tala"}
    ,
    {"afip":"21331","location":"La Quinta"},
    {"afip":"21332","location":"Las Chacras"},
    {"afip":"21333","location":"Las Cortaderas"},
    {"afip":"21334","location":"Las Palmas"},
    {"afip":"21335","location":"Toro Muerto"},
    {"afip":"21336","location":"El Carrizal"},
    {"afip":"21337","location":"El Durazno"},
    {"afip":"21338","location":"El Potrero"},
    {"afip":"21339","location":"La Quebrada"},
    {"afip":"21340","location":"Las Playas"},
    {"afip":"21341","location":"Los Cocos"},
    {"afip":"21342","location":"San Ignacio"},
    {"afip":"21343","location":"San Jose"},
    {"afip":"21344","location":"San Roque"},
    {"afip":"21345","location":"Santa Rosa"},
    {"afip":"21346","location":"Villa Santa Cruz Del Lago"},
    {"afip":"21347","location":"El Bañado"}
    ,
    {"afip":"21348","location":"El Barreal"},
    {"afip":"21349","location":"La Aguada"},
    {"afip":"21350","location":"La Calera"},
    {"afip":"21351","location":"La Cumbre"},
    {"afip":"21352","location":"La Esquina"},
    {"afip":"21353","location":"La Mesada"},
    {"afip":"21354","location":"La Sierrita"},
    {"afip":"21355","location":"Las Tapias"},
    {"afip":"21356","location":"Permanentes"},
    {"afip":"21357","location":"Piedra Blanca"},
    {"afip":"21358","location":"Rio Seco"},
    {"afip":"21359","location":"San Bernardo"},
    {"afip":"21360","location":"Cañada Honda"}
    ,
    {"afip":"21361","location":"Colonia San Ignacio"},
    {"afip":"21362","location":"El Carrizal"},
    {"afip":"21363","location":"El Quebracho"},
    {"afip":"21364","location":"Espinillo"},
    {"afip":"21365","location":"Isla Verde"},
    {"afip":"21366","location":"La Cañada"}
    ,
    {"afip":"21367","location":"La Puerta"},
    {"afip":"21368","location":"Las Palmitas"},
    {"afip":"21369","location":"Los Algarrobitos"},
    {"afip":"21370","location":"Los Chañares"}
    ,
    {"afip":"21371","location":"Los Pozos"},
    {"afip":"21372","location":"Punta Del Agua"},
    {"afip":"21373","location":"San Salvador"},
    {"afip":"21374","location":"Agua De Oro"},
    {"afip":"21375","location":"Barreto"},
    {"afip":"21376","location":"Buena Vista"},
    {"afip":"21377","location":"El Bañado"}
    ,
    {"afip":"21378","location":"El Coro"},
    {"afip":"21379","location":"El Durazno"},
    {"afip":"21380","location":"El Puesto"},
    {"afip":"21381","location":"El Quebracho"},
    {"afip":"21382","location":"El Rodeo"},
    {"afip":"21383","location":"La Barranca"},
    {"afip":"21384","location":"La Cañada"}
    ,
    {"afip":"21385","location":"La Costa"},
    {"afip":"21386","location":"La Cruz"},
    {"afip":"21387","location":"La Estancia"},
    {"afip":"21388","location":"La Penca"},
    {"afip":"21389","location":"La Pintada"},
    {"afip":"21390","location":"Las Cañas"}
    ,
    {"afip":"21391","location":"Las Chacras"},
    {"afip":"21392","location":"Las Cortaderas"},
    {"afip":"21393","location":"Las Gramillas"},
    {"afip":"21394","location":"Los Cocos"},
    {"afip":"21395","location":"Los Pocitos"},
    {"afip":"21396","location":"La Puerta"},
    {"afip":"21397","location":"La Virginia"},
    {"afip":"21398","location":"San Isidro"},
    {"afip":"21399","location":"Fabrica Militar"},
    {"afip":"21400","location":"La Laguna"},
    {"afip":"21401","location":"Villa Del Parque"},
    {"afip":"21402","location":"Villa Maria"},
    {"afip":"21403","location":"El Molino"},
    {"afip":"21404","location":"El Quebracho"},
    {"afip":"21405","location":"La Aguada"},
    {"afip":"21406","location":"La Calera"},
    {"afip":"21407","location":"La Florida"},
    {"afip":"21408","location":"Las Cañas"}
    ,
    {"afip":"21409","location":"Los Chañares"}
    ,
    {"afip":"21410","location":"La Reduccion"},
    {"afip":"21411","location":"Piedras Anchas"},
    {"afip":"21412","location":"El Sauce"},
    {"afip":"21413","location":"Las Chacras"},
    {"afip":"21414","location":"Ojo De Agua"},
    {"afip":"21415","location":"Piedras Anchas"},
    {"afip":"21416","location":"El Rincon"},
    {"afip":"21417","location":"La Calera"},
    {"afip":"21423","location":"La Calera"},
    {"afip":"21424","location":"Rio Chico"},
    {"afip":"21699","location":"Pacheco De Melo"},
    {"afip":"21847","location":"Alto Alberdi"},
    {"afip":"21848","location":"Barrio Cabaña Del Pilar"}
    ,
    {"afip":"21849","location":"Barrio Consorcio Esperanza"},
    {"afip":"21850","location":"Barrio Coronel Olmedo"},
    {"afip":"21851","location":"Barrio Inaudi"},
    {"afip":"21852","location":"Barrio Jardin"},
    {"afip":"21853","location":"Barrio Las Violetas"},
    {"afip":"21854","location":"Barrio Maipu"},
    {"afip":"21855","location":"Barrio Parque San Antonio"},
    {"afip":"21856","location":"Barrio Parque San Carlos"},
    {"afip":"21857","location":"Barrio Parque Velez Sarsfield"},
    {"afip":"21858","location":"Barrio Santa Isabel 1Ro."},
    {"afip":"21859","location":"Buena Vista"},
    {"afip":"21860","location":"Cabinda"},
    {"afip":"21861","location":"Cañada De Rio Pinto"}
    ,
    {"afip":"21862","location":"Capilla De La Sagrada Familia"},
    {"afip":"21863","location":"Capilla De Remedios"},
    {"afip":"21865","location":"Ciudad De Cordoba Sur"},
    {"afip":"21866","location":"Ciudad De Cordoba Sur"},
    {"afip":"21867","location":"Ciudad De Cordoba Sur"},
    {"afip":"21868","location":"Ciudad De Cordoba Sur"},
    {"afip":"21869","location":"Ciudad De Cordoba Sur"},
    {"afip":"21870","location":"Ciudad De Cordoba Sur"},
    {"afip":"21871","location":"Ciudad De Cordoba Sur"},
    {"afip":"21872","location":"Colonia Las 4 Esquinas"},
    {"afip":"21873","location":"Cosme"},
    {"afip":"21874","location":"Country Club"},
    {"afip":"21875","location":"El Quebracho Rio I"},
    {"afip":"21876","location":"El Rincon (Dpto. Del Rio Ii)"},
    {"afip":"21877","location":"La Toma De Tulumba"},
    {"afip":"21878","location":"Las 4 Esquinas"},
    {"afip":"21879","location":"Onagoity"},
    {"afip":"21880","location":"Pedro Vivas"},
    {"afip":"21881","location":"Pozo Nuevo"},
    {"afip":"21882","location":"Quebracho Rio Primero"},
    {"afip":"21883","location":"Santa Rosa De Rio I"},
    {"afip":"21884","location":"Villa Argentina"},
    {"afip":"21885","location":"Villa Maria Del Rio Seco"},
    {"afip":"21886","location":"Villa San Isidro"},
    {"afip":"21887","location":"Ascochinga"},
    {"afip":"21889","location":"Barrio Alto Verde"},
    {"afip":"21890","location":"Barrio Barranca Yaco"},
    {"afip":"21891","location":"Barrio Centro Norte"},
    {"afip":"21892","location":"Barrio El Quebrachal"},
    {"afip":"21893","location":"Barrio Ferreyra"},
    {"afip":"21894","location":"Barrio Florentino Ameghino"},
    {"afip":"21895","location":"Barrio General Bustos"},
    {"afip":"21896","location":"Barrio General Paz Norte"},
    {"afip":"21897","location":"Barrio Guiñazu"}
    ,
    {"afip":"21898","location":"Barrio Liceo General Paz"},
    {"afip":"21899","location":"Barrio Los Boulevares"},
    {"afip":"21900","location":"Barrio Los Gigantes"},
    {"afip":"21901","location":"Barrio Palmar"},
    {"afip":"21902","location":"Barrio Remedios De Escalada"},
    {"afip":"21903","location":"Barrio Yofre Sud"},
    {"afip":"21904","location":"Cañada Del Rio Pinto"}
    ,
    {"afip":"21905","location":"Cavalango"},
    {"afip":"21906","location":"Chacra De La Merced"},
    {"afip":"21907","location":"Ciudad De Cordoba Norte"},
    {"afip":"21908","location":"Ciudad De Cordoba Norte"},
    {"afip":"21909","location":"Ciudad De Cordoba Norte"},
    {"afip":"21910","location":"Ciudad De Cordoba Norte"},
    {"afip":"21911","location":"Ciudad De Cordoba Norte"},
    {"afip":"21912","location":"Ciudad De Cordoba Norte"},
    {"afip":"21913","location":"Ciudad De Cordoba Norte"},
    {"afip":"21914","location":"Colonia Tirolesa"},
    {"afip":"21915","location":"Comechingones (San Alberto)"},
    {"afip":"21916","location":"Guiñazu"}
    ,
    {"afip":"21917","location":"Kilometro 658"},
    {"afip":"21918","location":"Kilometro 691"},
    {"afip":"21919","location":"Kilometro 711"},
    {"afip":"21920","location":"Las Chacras Ruta 111 Kilometro"},
    {"afip":"21921","location":"Los Boulevares"},
    {"afip":"21922","location":"Los Patos"},
    {"afip":"21923","location":"Monte Del Rosario"},
    {"afip":"21924","location":"Puesto  De Castro"},
    {"afip":"21925","location":"Ruta 111 Kilometro 14"},
    {"afip":"21926","location":"Santa Rosa"},
    {"afip":"21927","location":"Timon Cruz"},
    {"afip":"21928","location":"Villa Retiro"},
    {"afip":"21929","location":"Villa Urquiza"},
    {"afip":"21931","location":"Villa Warcalde"},
    {"afip":"21932","location":"Capitan General Bernardo O'Higgins"},
    {"afip":"21933","location":"Candelaria Norte"},
    {"afip":"21934","location":"Jaime Peters"},
    {"afip":"21935","location":"Los Chañaritos (Cruz Del Eje)"}
    ,
    {"afip":"21936","location":"San Marcos Sierras"},
    {"afip":"21937","location":"San Vicente"},
    {"afip":"21938","location":"Aguada De Reyes"},
    {"afip":"21939","location":"Dalmacio Velez"},
    {"afip":"21940","location":"Las  Isletillas"},
    {"afip":"21941","location":"San Ignacio Amboy"},
    {"afip":"21942","location":"Cañada Verde"}
    ,
    {"afip":"21943","location":"Hipolito Buchard"},
    {"afip":"21944","location":"Laguna Del Monte"},
    {"afip":"21945","location":"Onagoity"},
    {"afip":"21946","location":"Pegasano"},
    {"afip":"21947","location":"Aldea Santa Maria"},
    {"afip":"21948","location":"Pedro E. Funes"},
    {"afip":"21949","location":"San Severo"},
    {"afip":"21950","location":"Colonia Veinticinco Los Surgentes"},
    {"afip":"21951","location":"Adelia Maria"},
    {"afip":"21952","location":"Alcira Gigena"},
    {"afip":"21953","location":"Alto Lindo"},
    {"afip":"21954","location":"Banda Norte"},
    {"afip":"21955","location":"Colonia El Carmen"},
    {"afip":"21956","location":"Gigena"},
    {"afip":"21957","location":"La Carolina"},
    {"afip":"21958","location":"Las Albahacas"},
    {"afip":"21959","location":"Santa Catalina"},
    {"afip":"21960","location":"Tres Acequias"},
    {"afip":"21961","location":"Amalia"},
    {"afip":"21962","location":"Campo Las Varas"},
    {"afip":"21963","location":"Colonia 10 De Julio"},
    {"afip":"21964","location":"Colonia Bottuli"},
    {"afip":"21965","location":"Colonia Las Cañitas"}
    ,
    {"afip":"21966","location":"Colonia Malbertina"},
    {"afip":"21967","location":"Colonia Nueva Piamonte"},
    {"afip":"21968","location":"Colonia Pradomano"},
    {"afip":"21969","location":"El  Fuertecito"},
    {"afip":"21970","location":"La Africana"},
    {"afip":"21971","location":"Luis A. Sauce"},
    {"afip":"21972","location":"Monte Tigre"},
    {"afip":"21973","location":"Plaza Luxardo"},
    {"afip":"21974","location":"Poza Del Avestruz"},
    {"afip":"21975","location":"Saturnino Maria Laspiur"},
    {"afip":"21976","location":"Villa Del Transito"},
    {"afip":"21977","location":"Bajo De Los Corrales"},
    {"afip":"21978","location":"Buena Vista"},
    {"afip":"21979","location":"Chancani"},
    {"afip":"21980","location":"La Calera"},
    {"afip":"21981","location":"Las Maravillas"},
    {"afip":"21982","location":"Los Barreales"},
    {"afip":"21983","location":"Los Cerillos"},
    {"afip":"21984","location":"Ojo De Agua"},
    {"afip":"21985","location":"Barrio General Paz"},
    {"afip":"21986","location":"Ramon J. Carcano"},
    {"afip":"21987","location":"Silvio Pellico"},
    {"afip":"22115","location":"Altogrande"},
    {"afip":"22249","location":"La Tordilla"},
    {"afip":"22251","location":"Colonia Las Pichanas"},
    {"afip":"22254","location":"La Puerta (Rio Primero)"},
    {"afip":"22255","location":"Comechingones (Rio Primero)"},
    {"afip":"22264","location":"Barrio Centro Sur"},
    {"afip":"22267","location":"Candelaria Sud (Totoral)"},
    {"afip":"22269","location":"Los Quebrachos (Rio Seco)"},
    {"afip":"22270","location":"Agua Sacha (Colon)"},
    {"afip":"22271","location":"Colonia Vicente Aguero (Colon)"},
    {"afip":"22275","location":"Villa Parque Santa Ana"},
    {"afip":"22277","location":"Barrio San Carlos"},
    {"afip":"22278","location":"Villa El Libertador"},
    {"afip":"22280","location":"2 De Abril"},
    {"afip":"22281","location":"20 De Junio"},
    {"afip":"22282","location":"4 De Febrero"},
    {"afip":"22283","location":"Ciudad De Las Artes"},
    {"afip":"22284","location":"Country Tejas Del Sur"},
    {"afip":"22293","location":"Estancia Vieja"},
    {"afip":"22332","location":"Totoral (Kilometro 351)"},
    {"afip":"22333","location":"La Cortadera"}
    ]