<section class="body">

    <div class="header-section">
        <div class="downs">
            <app-buttons-condition-type></app-buttons-condition-type>
            
            <app-views-section-price    [isHistorical]="true" *ngIf="boardSelected==TYPE_OF_HISTORICAL.PRICE"></app-views-section-price>
            <app-views-section-exchange [isHistorical]="true" *ngIf="boardSelected==TYPE_OF_HISTORICAL.EXCHANGE"></app-views-section-exchange>
            <app-views-section-order    [isHistorical]="true" *ngIf="boardSelected==TYPE_OF_HISTORICAL.ORDER"></app-views-section-order>
        </div>
      
        <div class="call-to-actions">
            <div class="buttons-to-actions">
                <app-search-input class="search"></app-search-input>

                <div class="select-board">
                    <div class="select-button button-tablero" (click)="setSelectBoard(TYPE_OF_HISTORICAL.PRICE)" 
                    [ngClass]="{'selected': boardSelected==TYPE_OF_HISTORICAL.PRICE}">
                        <span>Tablero</span>
                    </div>
                    
                    <div class="select-button button-exchange" (click)="setSelectBoard(TYPE_OF_HISTORICAL.EXCHANGE)" 
                    [ngClass]="{'selected': boardSelected==TYPE_OF_HISTORICAL.EXCHANGE}" *ngIf="isComercial">
                        <span>Contrapartidas</span>
                    </div>

                    <div class="select-button button-order" (click)="setSelectBoard(TYPE_OF_HISTORICAL.ORDER)"  
                    [ngClass]="{'selected': boardSelected==TYPE_OF_HISTORICAL.ORDER}">
                        <span>Ordenes</span>
                    </div>
                </div>
            </div>

            <div class="description">                 
                <span class="quant">Cantidad de ordenes: <span class="stroke">{{getOrdersPipe(array).length}}</span></span>
                <span class="quant" *ngIf="boardSelected==TYPE_OF_HISTORICAL.ORDER">
                    Cantidad de toneladas: <span class="stroke">{{getQuantOfTons(getOrdersPipe(array))}}</span>
                </span>
                <span class="days" (mouseenter)="hover()" (mouseleave)="noHover()"
                (click)="isOneDate()? pickerOne.open(): pickerRange.open()">Tablero del día 
                    <ng-container *ngIf="isOneDate() else twoDates">
                        <span class='stroke'>{{getDate()}}</span>
                    </ng-container>
                    <ng-template #twoDates>
                        <span class="stroke">{{getStartDate()}}</span> al <span class="stroke">{{getEndDate()}}</span> 
                    </ng-template>
                    <arrow-svg [direction]="'right'" [isHover]="isHover" class="arrow"></arrow-svg>
                </span>

                
                <input *ngIf="isOneDate()" matInput [matDatepicker]="pickerOne" [formControl]="dateSelected" (dateChange)='setDate($event)' style="width:0; height:0; visibility: hidden;">
                <mat-datepicker #pickerOne></mat-datepicker>

                <mat-date-range-input *ngIf="!isOneDate()" [rangePicker]="pickerRange" style="width:0; height:0; visibility: hidden;">
                    <input matStartDate placeholder="Start date" (dateChange)='setStartDate($event)'>
                    <input matEndDate placeholder="End date" (dateChange)='setEndDate($event)'>
                  </mat-date-range-input>
                <mat-date-range-picker #pickerRange></mat-date-range-picker>

            </div>

            
            <app-slide *ngIf="boardSelected==TYPE_OF_HISTORICAL.PRICE && conditionTypeSelected==CONDITION_TYPES.TO_FIX"></app-slide>
        </div>
    </div>

    <app-table></app-table>

    <!-- Slide Cards -->
    <div (click)="closeDetails()" [ngClass]="{'background opaque': 
    isShowOrderDetails || isShowPriceDetails || isShowOrderExchangeDetails || historical || priceHistorical }"></div>
    <app-order-historical class="slide-card slide-card-visible" *ngIf="historical"></app-order-historical>
    <app-price-historical class="slide-card slide-card-visible" *ngIf="priceHistorical"></app-price-historical>
    <app-order-eye *ngIf="isShowOrderDetails" class="slide-card slide-card-visible" [order]="orderDetails"></app-order-eye>
    <app-price-eye *ngIf="isShowPriceDetails" class="slide-card slide-card-visible" [price]="priceDetails"></app-price-eye>
    <app-exchange-details class="slide-card slide-card-visible" *ngIf="isShowOrderExchangeDetails"></app-exchange-details>
    <app-add-exchange class="slide-card slide-card-visible" *ngIf="showAddExchange"></app-add-exchange>

</section>