import { createAction, props } from "@ngrx/store";
import { User, UserGoogle } from "src/app/shared/interfaces/user.interface";

//LOGIN
export const login = createAction('[SESSION] Login')
export const loginSuccess = createAction('[SESSION] LoginSuccess',
props<{ user: User }>());

export const createUser = createAction('[SESSION] Create User',
props<{ email: string, photo:string, id:string }>())

//LOGOUT
export const logout = createAction('[SESSION] Logout');
export const logoutSuccess = createAction('[SESSION] LogoutSuccess');
export const logoutFailed = createAction('[SESSION] LogoutFailed');
export const notRememberUser=createAction('[SESSION] Not Remember User');

//TOKEN
export const refreshToken = createAction('[TOKEN] Refresh Token',
    props<{ token: string, photo:string, id:string }>() );
export const refreshTokenSuccess = createAction('[TOKEN] Refresh Token Success');
export const refreshTokenFailed = createAction('[TOKEN] Refresh Token Failed');
