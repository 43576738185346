<div class="form" #formulario *ngIf="!isSyngentaBuyerAVC">
    <div class="buttons">
        <span>Completa la siguiente información.</span>
    </div>

    <div class="delete-all gap-8">
        <span (click)="deleteStep(numOfStep)">Borrar paso {{numOfStep}}</span>
    </div>

    <div class="paso paso1" [ngClass]="{'visible': numOfStep==1, 'invisible': numOfStep!=1 }">
        <app-seller-name [fieldIdentificator]="'exchange-buyer'"></app-seller-name>
        <app-seller-name  [fieldIdentificator]="'exchange'"></app-seller-name>
        <app-how-sell [fieldIdentificator]="'exchange'"></app-how-sell>
        <app-place-of-origin [fieldIdentificator]="'exchange'"></app-place-of-origin>
        <app-price *ngIf="!isGenerateToFix" [fieldIdentificator]="'exchange'"></app-price>
        <app-type-coin *ngIf="!isGenerateToFix" [fieldIdentificator]="'exchange'"></app-type-coin>
        <app-tons [fieldIdentificator]="'exchange'"></app-tons>
        <app-agreed-discount [fieldIdentificator]="'exchange'"  [defaultValue]="agreedDiscountDefault" [showMessageNT]="isNideraOSemillas()"></app-agreed-discount>
        <app-way-pay [fieldIdentificator]="'exchange'"></app-way-pay>
        <app-bonification-exchange [fieldIdentificator]="'exchange'"></app-bonification-exchange>
    </div>

    <div class="button-container not-visible-in-950">
        <button class="filled-button" (click)="confirmExchange(formulario)"
            [ngClass]="{'disabled': isDisabledOrder()}">Generar canje</button>
    </div>
</div>
<div class="form" #formulario *ngIf="isSyngentaBuyerAVC">
    <div class="buttons button-order">
        <button [ngClass]="{'selected':numOfStep==1}" class="step" (click)="changeStep(1, formulario)">Paso 1</button>
        <button [ngClass]="{'selected':numOfStep==2}" class="step" (click)="changeStep(2, formulario)">Paso 2</button>
        <button [ngClass]="{'selected':numOfStep==3}" class="step" (click)="changeStep(3, formulario)">Paso 3</button>
        <button [ngClass]="{'selected':numOfStep==4}" class="step" (click)="changeStep(4, formulario)" *ngIf="isGenerateToFix">Paso 4</button>
        <div class="rectangle step-{{numOfStep}}"></div>
    </div>

    <div class="delete-all gap-8">
        <span (click)="deleteStep(numOfStep)">Borrar paso {{numOfStep}}</span>
        
        <span (click)="deleteAll(numOfStep)">Borrar todo</span>
    </div>


    <div class="paso paso1" [ngClass]="{'visible': numOfStep==1, 'invisible': numOfStep!=1 }">
        <app-seller-name [fieldIdentificator]="'exchange-buyer'"></app-seller-name>
        <app-seller-name [fieldIdentificator]="'order'"></app-seller-name>
        <app-place-of-origin [fieldIdentificator]="'order'"></app-place-of-origin>
        <app-broker [fieldIdentificator]="'order'"></app-broker>
        <app-how-sell [fieldIdentificator]="'order'"></app-how-sell>
        <app-business-particularities [fieldIdentificator]="'order'"></app-business-particularities>
        <app-tons [fieldIdentificator]="'order'" (tab)="changeStep(2, formulario)"></app-tons>
    </div>
    <div class="paso paso2" [ngClass]="{'visible': numOfStep==2, 'invisible': numOfStep!=2 }">
        <app-business-type [fieldIdentificator]="'order'"></app-business-type>
        <app-quotas [fieldIdentificator]="'order'" *ngIf="isConDescarga(order)"></app-quotas>
        <app-product-name [fieldIdentificator]="'order'"></app-product-name>
        <app-price [fieldIdentificator]="'order'" *ngIf="!isGenerateToFix"></app-price>
        <app-type-coin [fieldIdentificator]="'order'" *ngIf="!isGenerateToFix"></app-type-coin>
        <app-place-of-delivery [fieldIdentificator]="'order'" [requirePort]="true" [incompleteRequirePort]="incompleteRequirePort"></app-place-of-delivery>
        <app-quality [fieldIdentificator]="'order'" (tab)="changeStep(3, formulario)" (addQuality)="showQualities($event)"></app-quality>
        <app-quality-particularities [fieldIdentificator]="'order'" *ngIf="showPartQuality"></app-quality-particularities>
        <app-condition-type [fieldIdentificator]="'order'" *ngIf="isGenerateToFix"></app-condition-type>
    </div>
    <div class="paso paso3"[ngClass]="{'visible': numOfStep==3, 'invisible': numOfStep!=3 }">
        <app-delivery-period [fieldIdentificator]="'order'" [isGenerateToFix]="isGenerateToFix"></app-delivery-period>
        <app-harvest [fieldIdentificator]="'order'"></app-harvest>
        <app-way-pay [fieldIdentificator]="'order'" [isGenerateToFix]="isGenerateToFix"></app-way-pay>
        <app-expiration [fieldIdentificator]="'order'" *ngIf="!isContraEntrega(order)"></app-expiration>
        <app-pesification [fieldIdentificator]="'order'" *ngIf="isInDolars(order)"></app-pesification>
    </div>
    <div class="paso paso4" [ngClass]="{'visible': numOfStep==4, 'invisible': numOfStep!=4 }" *ngIf="isGenerateToFix">
        <app-condition-type [fieldIdentificator]="'order'"></app-condition-type>
        <app-to-fix-indicators [fieldIdentificator]="'order'" [indicators]="order?.price?.indicators ?? []"
        [conditionType]="order?.price?.conditionType"></app-to-fix-indicators>
    </div>

    <!-- If you are viewing the exchange options.
    It means that the maximum step is 4, otherwise, the maximum is 3. -->

    <div class="button-container">
        <button class="not-filled-button" (click)="changeStep(numOfStep+1, formulario)" 
        *ngIf="getNumMaxOfStep()!=numOfStep">Siguiente</button>

        <button class="filled-button not-visible-in-950" (click)="confirmOrder(formulario)" 
        *ngIf="getNumMaxOfStep()==numOfStep" 
        [ngClass]="{'disabled': isDisabledOrder()}">Generar orden</button>

        <button class="filled-button visible-in-950" [disabled]="isDisabledOrder()" 
        [ngClass]="{'disabled': isDisabledOrder()}" 
        *ngIf="getNumMaxOfStep()==numOfStep" 
        (click)="applyButtonEmit(true)">Aplicar</button>
    </div>
</div>