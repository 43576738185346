<div class="field">
    <span class="field-title">Vencimiento de la parcial<span class="asterisk" *ngIf="isNecesaryExpiration()">*</span></span>

    <date-input (valueOut)="setElement($event, 0)" [isFilter]="true" 
    [initialPlaceHolder]="'Ingresar un vencimiento'" [value]="elementSelected" [isIncomplete]="isIncomplete"></date-input>
    
    <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>
</div>

<div class="row-left gap-8" style="margin-top:8px" *ngIf="isFechaFija && isOperator && isGenerateCondition()">
    <input class="pointer" type="checkbox" [value]="1" [checked]="isFixExpiration"  (click)="setOption()">
    <span class="pointer" (click)="setOption()">Anclar vencimiento</span>
</div>