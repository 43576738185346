import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

import { DateChecker } from 'src/app/shared/date.checker';
import { periods } from 'src/app/shared/dict/objetives';

@Component({
  selector: 'app-grafic',
  templateUrl: './grafic.component.html',
  styleUrls: ['./grafic.component.scss']
})

export class GraficComponent implements OnInit, OnDestroy {
  @Input() average: number = 0
  @Input() periodSelect: string = '';
  @Input() values: any[] = [];

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: any = {
    chart: {
      width: this.calculateResponsiveWidth(),   //684,//815,
      height: 185,
    },
    title: {
      text: 'Objetivos',
      style: {
        display: 'none'
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      borderColor: '##00c689',
      borderRadius: 6,
      borderWidth: 1
    },

    xAxis: {

      categories: this.getCategories()
    },
    yAxis: {
      gridLineColor: 'transparent',
      title: {
        enabled: false
      },
      labels: {
        enabled: false
      }

    },
    plotOptions: {
      series: {
        borderRadius: 3
      }
    },
    series: [],

accessibility: {
    enabled: true,
    linkedDescription: '#highcharts-description-1',
},
  };

  constructor(private detector: ChangeDetectorRef, private date: DateChecker) {
  }

  ngOnInit() {

    window.addEventListener("resize", this.setChartOptions);

    this.refreshValuesOfTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshValuesOfTable();
  }

  ngOnDestroy() {    
    window.removeEventListener("resize", this.setChartOptions);
  }

  setChartOptions = () => {
    this.chartOptions = {
      chart: {
        width: this.calculateResponsiveWidth(),   //684,//815,
        height: 185,
      },
    }
  }

  refreshValuesOfTable() {
    this.chartOptions = {
      ...this.chartOptions,
      xAxis: {
        categories: this.getCategories()
      },
      series: [{
        type: 'column',
        pointPadding: 0.1,
        color: '#00C689',
        border: 16,
        name: 'Realizado',
        data: this.values,
      }, {
        type: 'line',
        pointPadding: 0.1,
        color: '#aaa',
        border: 16,
        name: 'Proyectado',
        data: this.getAverageList(),
      }]
    }
  }

  calculateResponsiveWidth() {
    //288px in screen of 360px;
    //815px in screen of 1024px;
    //710px in screen of 1920px;
    //710px in screen of 1920px;
    //710px in screen of 1920px;

    const screenWidht = window.innerWidth;

    if (screenWidht > 1850) {
      return Math.min(710, screenWidht * 710 / 1920);
    } else if (screenWidht > 1750) {
      return screenWidht * 600 / 1750;
    } else if (screenWidht > 1600) {
      return screenWidht * 520 / 1500;
    } else if (screenWidht > 1500) {
      return screenWidht * 500 / 1500;
    } else if (screenWidht > 1350) {
      return screenWidht * 500 / 1400;
    } else if (screenWidht > 1250) { //We are in desktop
      return screenWidht * 450 / 1366;
    } else if (screenWidht > 750) { //We are in Tablet
      return screenWidht * 815 / 1024;
    } else if (screenWidht > 440) { //We arent in desktop
      return screenWidht * 418 / 603;
    } else {
      return screenWidht * 288 / 360;
    }
  }

  getCategories() {
    if (this.periodSelect == periods.WEEKLY) {
      return ['Lun', 'Mar', 'Mie', 'Jue', 'Vie'];
    } else if (this.periodSelect == periods.MONTHLY) {
      return this.getWeeksOfMonth();
    } else if (this.periodSelect == periods.QUARTERLY) {
      return this.getMonthsOfQuarterly();
    } else if (this.periodSelect == periods.YEARLY) {
      return ['Primer trimestre', 'Segundo trimestre', 'Tercer trimestre', 'Cuarto trimestre']
    }
  }


  getAverageList() {
    if (this.periodSelect == periods.WEEKLY) {
      return [this.average, this.average, this.average, this.average, this.average]
    } else if(this.periodSelect==periods.MONTHLY){
      return [this.average, this.average, this.average, this.average, this.average]
    }
    else if (this.periodSelect == periods.QUARTERLY) {
      return [this.average, this.average, this.average]

    } else if (this.periodSelect == periods.YEARLY) {
      return [this.average, this.average, this.average, this.average]
    }
  }
  
  getWeeksOfMonth(): string[] {
    return ['Primer semana','Segunda semana','Tercer semana','Cuarta semana','Quinta semana'];
  }

  getMonthsOfQuarterly(): string[] {  
    const month = new Date().getMonth() + 1;
    const quarterMap: any = {
      1: ['Ene', 'Feb', 'Mar'],
      2: ['Abr', 'May', 'Jun'],
      3: ['Jul', 'Ago', 'Sep'],
      4: ['Oct', 'Nov', 'Dic'],
    };
    return quarterMap[Math.ceil(month / 3)] || [];
  }
}
