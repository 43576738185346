import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowHeadComponent } from './row-head.component';
import { FilterOrderModule } from 'src/app/components/filters/filter-order/filters.module';
import { FilterBoardSvgModule } from 'src/app/shared/styles/svg/filter-board/filter-board-svg.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { CellHeadModule } from '../../cell-head/cell-head.module';

@NgModule({
  declarations: [
    RowHeadComponent
  ],
  imports: [
    CommonModule,
    FilterOrderModule,
    FilterBoardSvgModule,
    ArrowSvgModule,
    CellHeadModule
  ], exports: [
    RowHeadComponent
  ]
})
export class RowHeadModule { }
