import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyerComponent } from './buyer.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BuyerGeneratePriceCardModule } from '../../slide-cards/buyers-generate-price/buyers-generate-price.module';



@NgModule({
  declarations: [
    BuyerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    BuyerGeneratePriceCardModule
  ], exports:[
    BuyerComponent
  ]
})
export class BuyerModule { }
