<svg class="svg-12" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_3720_13712)">
    <path d="M6 12C9.30799 12 12 9.30799 12 6C12 2.69201 9.30803 0 6 0C2.69197 0 0 2.69201 0 6C0 9.30799 2.69201 12 6 12ZM6 0.799987C8.86799 0.799987 11.2 3.13198 11.2 6C11.2 8.86802 8.86802 11.2 6 11.2C3.13198 11.2 0.799987 8.86802 0.799987 6C0.799987 3.13198 3.13201 0.799987 6 0.799987Z" fill="black"/>
    <path d="M7.75156 7.91239C7.82557 7.97238 7.91354 8.0004 8.00155 8.0004C8.11955 8.0004 8.23555 7.94839 8.31354 7.8504C8.45154 7.67839 8.42352 7.42639 8.25155 7.28839L6.40155 5.80838V2.80038C6.40155 2.58038 6.22156 2.40039 6.00156 2.40039C5.78155 2.40039 5.60156 2.58038 5.60156 2.80038V6.0004C5.60156 6.12241 5.65757 6.2364 5.75156 6.31238L7.75156 7.91239Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_3720_13712">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
</svg>