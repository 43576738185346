import { Component, Input } from '@angular/core';

@Component({
  selector: 'arrow-svg',
  templateUrl: './arrow.component.html',
  styleUrls: ['./../../svg.scss']
})
export class ArrowSvgComponent {
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  @Input() identifyClassName: string = ''; //Usada para los casos donde deba detectarse el click
  @Input() isHover=false;
  constructor() { }

}
