export const buenosAiresLocalities=[
    {"afip":"3","location":"12 De Agosto"},
    {"afip":"4","location":"12 De Octubre"},
    {"afip":"5","location":"16 De Julio"},
    {"afip":"6","location":"17 De Agosto"},
    {"afip":"11","location":"20 De Junio"},
    {"afip":"16","location":"25 De Mayo"},
    {"afip":"24","location":"30 De Agosto"},
    {"afip":"29","location":"9 De Abril"},
    {"afip":"30","location":"9 De Julio"},
    {"afip":"32","location":"Abasto"},
    {"afip":"33","location":"Abbott"},
    {"afip":"36","location":"Abel"},
    {"afip":"43","location":"Abra De Hinojo"},
    {"afip":"71","location":"Acassuso"},
    {"afip":"74","location":"Aceilan"},
    {"afip":"77","location":"Acevedo"},
    {"afip":"85","location":"Achupallas"},
    {"afip":"95","location":"Adela"},
    {"afip":"96","location":"Adela Corti"},
    {"afip":"98","location":"Adela Saenz"},
    {"afip":"99","location":"Adolfo Alsina"},
    {"afip":"101","location":"Adolfo Gonzales Chaves"},
    {"afip":"108","location":"Aeropuerto Ezeiza"},
    {"afip":"112","location":"Agote"},
    {"afip":"199","location":"Aguara"},
    {"afip":"207","location":"Aguas Corrientes"},
    {"afip":"214","location":"Aguas Verdes"},
    {"afip":"221","location":"Aguirrezabala"},
    {"afip":"223","location":"Agustin Mosconi"},
    {"afip":"224","location":"Agustin Roca"},
    {"afip":"225","location":"Agustina"},
    {"afip":"235","location":"Alagon"},
    {"afip":"238","location":"Alamos"},
    {"afip":"242","location":"Alastuey"},
    {"afip":"249","location":"Albariño"},
    {"afip":"251","location":"Alberdi"},
    {"afip":"252","location":"Alberti"},
    {"afip":"279","location":"Aldea Romana"},
    {"afip":"281","location":"Aldea San Andres"},
    {"afip":"296","location":"Aldecon"},
    {"afip":"298","location":"Aldo Bonzi"},
    {"afip":"299","location":"Alegre"},
    {"afip":"304","location":"Alejandro Korn"},
    {"afip":"306","location":"Alejandro Petion"},
    {"afip":"314","location":"Alfa"},
    {"afip":"315","location":"Alfalad"},
    {"afip":"318","location":"Alferez San Martin"},
    {"afip":"321","location":"Alfredo Demarchi"},
    {"afip":"333","location":"Algarrobo"},
    {"afip":"353","location":"Almacen Castro"},
    {"afip":"355","location":"Almacen El Cruce"},
    {"afip":"356","location":"Almacen El Descanso"},
    {"afip":"357","location":"Almacen La Colina"},
    {"afip":"358","location":"Almacen Piatti"},
    {"afip":"361","location":"Almirante Brown"},
    {"afip":"363","location":"Almirante Irizar"},
    {"afip":"364","location":"Almirante Solier"},
    {"afip":"374","location":"Alsina"},
    {"afip":"380","location":"Alta Vista"},
    {"afip":"381","location":"Altamira"},
    {"afip":"382","location":"Altamirano"},
    {"afip":"465","location":"Alto Verde"},
    {"afip":"466","location":"Altona"},
    {"afip":"474","location":"Alvarez De Toledo"},
    {"afip":"475","location":"Alvarez Jonte"},
    {"afip":"476","location":"Alvaro Barros"},
    {"afip":"479","location":"Alzaga"},
    {"afip":"484","location":"Amalia"},
    {"afip":"502","location":"Ambrosio P Lezica"},
    {"afip":"505","location":"America"},
    {"afip":"506","location":"America Unida"},
    {"afip":"529","location":"Anasagasti"},
    {"afip":"547","location":"Ancon"},
    {"afip":"552","location":"Andant"},
    {"afip":"553","location":"Anderson"},
    {"afip":"561","location":"Andres Vaccorezza"},
    {"afip":"565","location":"Aneque Grande"},
    {"afip":"610","location":"Antonio Carboni"},
    {"afip":"612","location":"Antonio De Los Heros"},
    {"afip":"624","location":"Aparicio"},
    {"afip":"640","location":"Arana"},
    {"afip":"644","location":"Arano"},
    {"afip":"649","location":"Araujo"},
    {"afip":"657","location":"Arboleda"},
    {"afip":"663","location":"Arbuco"},
    {"afip":"671","location":"Arenales"},
    {"afip":"672","location":"Arenaza"},
    {"afip":"677","location":"Arevalo"},
    {"afip":"680","location":"Argerich"},
    {"afip":"682","location":"Ariel"},
    {"afip":"699","location":"Arquedas"},
    {"afip":"704","location":"Arrecifes"},
    {"afip":"706","location":"Arribeños"},
    {"afip":"711","location":"Arroyo Aguas Blancas"},
    {"afip":"713","location":"Arroyo Aguila Negra"},
    {"afip":"714","location":"Arroyo Aleli"},
    {"afip":"726","location":"Arroyo Botija Falsa"},
    {"afip":"732","location":"Arroyo Burgos"},
    {"afip":"736","location":"Arroyo Canelon"},
    {"afip":"738","location":"Arroyo Carabelitas"},
    {"afip":"750","location":"Arroyo Chico"},
    {"afip":"756","location":"Arroyo Corto"},
    {"afip":"760","location":"Arroyo De La Cruz"},
    {"afip":"762","location":"Arroyo De Luna"},
    {"afip":"766","location":"Arroyo Del Pescado"},
    {"afip":"771","location":"Arroyo Dulce"},
    {"afip":"772","location":"Arroyo El Ahogado"},
    {"afip":"773","location":"Arroyo El Chingolo"},
    {"afip":"780","location":"Arroyo Grande"},
    {"afip":"790","location":"Arroyo La Maza"},
    {"afip":"797","location":"Arroyo Las Cruces"},
    {"afip":"799","location":"Arroyo Las Rosas"},
    {"afip":"804","location":"Arroyo Los Huesos"},
    {"afip":"808","location":"Arroyo Los Tigres"},
    {"afip":"821","location":"Arroyo Negro"},
    {"afip":"828","location":"Arroyo Pareja"},
    {"afip":"838","location":"Arroyo Pesqueria"},
    {"afip":"865","location":"Arroyo Tajiber"},
    {"afip":"876","location":"Arroyo Venado"},
    {"afip":"880","location":"Arroyo Zanjon"},
    {"afip":"882","location":"Arroyo Ñacurutu"},
    {"afip":"883","location":"Arroyo Ñacurutu Chico"},
    {"afip":"890","location":"Arturo Segui"},
    {"afip":"891","location":"Arturo Vatteone"},
    {"afip":"892","location":"Asamblea"},
    {"afip":"894","location":"Ascencion"},
    {"afip":"908","location":"Asturias"},
    {"afip":"912","location":"Atahualpa"},
    {"afip":"913","location":"Atalaya"},
    {"afip":"923","location":"Atucha"},
    {"afip":"937","location":"Avellaneda"},
    {"afip":"942","location":"Avestruz"},
    {"afip":"946","location":"Ayacucho"},
    {"afip":"953","location":"Azcuenaga"},
    {"afip":"956","location":"Azopardo"},
    {"afip":"960","location":"Azul"},
    {"afip":"969","location":"B Los Aromos San Patricio"},
    {"afip":"970","location":"B Nuestra Señora De La Paz"},
    {"afip":"971","location":"B Sarmiento Don Rolando"},
    {"afip":"974","location":"Bacacay"},
    {"afip":"976","location":"Badano"},
    {"afip":"980","location":"Bagues"},
    {"afip":"981","location":"Bahia Blanca"},
    {"afip":"988","location":"Bahia San Blas"},
    {"afip":"993","location":"Baigorrita"},
    {"afip":"1043","location":"Bajo Hondo"},
    {"afip":"1056","location":"Balcarce"},
    {"afip":"1125","location":"Balneario Atlantida"},
    {"afip":"1126","location":"Balneario Camet Norte"},
    {"afip":"1127","location":"Balneario Chapalco"},
    {"afip":"1128","location":"Balneario Claromeco"},
    {"afip":"1131","location":"Balneario Frente Mar"},
    {"afip":"1133","location":"Balneario La Baliza"},
    {"afip":"1134","location":"Balneario La Caleta"},
    {"afip":"1136","location":"Balneario Los Angeles"},
    {"afip":"1137","location":"Balneario Mar Chiquita"},
    {"afip":"1138","location":"Balneario Mar De Cobo"},
    {"afip":"1139","location":"Balneario Oceano"},
    {"afip":"1140","location":"Balneario Orense"},
    {"afip":"1141","location":"Balneario Oriente"},
    {"afip":"1142","location":"Balneario Parada"},
    {"afip":"1144","location":"Balneario Playa Dorada"},
    {"afip":"1145","location":"Balneario Reta"},
    {"afip":"1146","location":"Balneario San Antonio"},
    {"afip":"1147","location":"Balneario Santa Elena"},
    {"afip":"1148","location":"Balneario Sauce Grande"},
    {"afip":"1150","location":"Balsa"},
    {"afip":"1165","location":"Banderalo"},
    {"afip":"1169","location":"Banfield"},
    {"afip":"1170","location":"Baradero"},
    {"afip":"1183","location":"Barker"},
    {"afip":"1214","location":"Barrientos"},
    {"afip":"1216","location":"Barrio 1 De Mayo"},
    {"afip":"1221","location":"Barrio Almafuerte"},
    {"afip":"1226","location":"Barrio Avellaneda"},
    {"afip":"1228","location":"Barrio Batan"},
    {"afip":"1233","location":"Barrio Caisamar"},
    {"afip":"1234","location":"Barrio Carosio"},
    {"afip":"1236","location":"Barrio Chapadmalal"},
    {"afip":"1245","location":"Barrio El Cazador"},
    {"afip":"1246","location":"Barrio El Hueco"},
    {"afip":"1248","location":"Barrio El Porteño"},
    {"afip":"1249","location":"Barrio Emir Ramon Juarez"},
    {"afip":"1252","location":"Barrio Garin Norte"},
    {"afip":"1253","location":"Barrio Gastronomico"},
    {"afip":"1254","location":"Barrio General Roca"},
    {"afip":"1255","location":"Barrio General San Martin"},
    {"afip":"1258","location":"Barrio Indio Trompa"},
    {"afip":"1261","location":"Barrio Jose M Estrada"},
    {"afip":"1262","location":"Barrio Juan B Justo"},
    {"afip":"1263","location":"Barrio Juarez"},
    {"afip":"1264","location":"Barrio Julio De Vedia"},
    {"afip":"1265","location":"Barrio Juramento"},
    {"afip":"1266","location":"Barrio La Dolly"},
    {"afip":"1267","location":"Barrio La Falda"},
    {"afip":"1272","location":"Barrio La Luisa"},
    {"afip":"1273","location":"Barrio La Perla Casco Urbano"},
    {"afip":"1277","location":"Barrio Las Mandarinas"},
    {"afip":"1279","location":"Barrio Loma Paraguaya"},
    {"afip":"1280","location":"Barrio Los Andes"},
    {"afip":"1284","location":"Barrio Noroeste"},
    {"afip":"1286","location":"Barrio Obrero"},
    {"afip":"1287","location":"Barrio Oeste"},
    {"afip":"1290","location":"Barrio Parque Bristol"},
    {"afip":"1291","location":"Barrio Parque Lambare"},
    {"afip":"1292","location":"Barrio Parque Leloir"},
    {"afip":"1293","location":"Barrio Parque Patagonia"},
    {"afip":"1294","location":"Barrio Pedro Rico"},
    {"afip":"1295","location":"Barrio Pedro Rocco"},
    {"afip":"1297","location":"Barrio Pinares"},
    {"afip":"1298","location":"Barrio Primera Junta"},
    {"afip":"1299","location":"Barrio Pueblo Nuevo"},
    {"afip":"1302","location":"Barrio Rosario Sud"},
    {"afip":"1303","location":"Barrio San Alejo"},
    {"afip":"1305","location":"Barrio San Blas"},
    {"afip":"1306","location":"Barrio San Carlos"},
    {"afip":"1307","location":"Barrio San Cayetano"},
    {"afip":"1309","location":"Barrio San Jacinto"},
    {"afip":"1310","location":"Barrio San Jose"},
    {"afip":"1313","location":"Barrio San Martin"},
    {"afip":"1314","location":"Barrio San Pablo"},
    {"afip":"1315","location":"Barrio San Roque"},
    {"afip":"1317","location":"Barrio Santa Magdalena"},
    {"afip":"1322","location":"Barrio Tierra De Oro"},
    {"afip":"1323","location":"Barrio Tiro Federal"},
    {"afip":"1324","location":"Barrio Trocha"},
    {"afip":"1326","location":"Barrio U O C R A"},
    {"afip":"1331","location":"Barrio Villa Muñiz"},
    {"afip":"1332","location":"Barrio Villa Ortega"},
    {"afip":"1333","location":"Barrio Villa Saladillo"},
    {"afip":"1335","location":"Barrio Vista Alegre"},
    {"afip":"1348","location":"Base Aeronaval Cmte Espora"},
    {"afip":"1349","location":"Base Aeronaval Punta Indio"},
    {"afip":"1356","location":"Base Naval Azopardo"},
    {"afip":"1357","location":"Base Naval Rio Santiago"},
    {"afip":"1369","location":"Baterias"},
    {"afip":"1370","location":"Bathurst Estacion"},
    {"afip":"1373","location":"Baudrix"},
    {"afip":"1377","location":"Bayauca"},
    {"afip":"1420","location":"Beccar"},
    {"afip":"1425","location":"Belen De Escobar"},
    {"afip":"1435","location":"Bella Vista"},
    {"afip":"1438","location":"Bellocq"},
    {"afip":"1442","location":"Benavidez"},
    {"afip":"1444","location":"Benitez"},
    {"afip":"1445","location":"Benito Juarez"},
    {"afip":"1452","location":"Berazategui"},
    {"afip":"1453","location":"Berdier"},
    {"afip":"1455","location":"Berisso"},
    {"afip":"1459","location":"Bermudez"},
    {"afip":"1462","location":"Bernal Este"},
    {"afip":"1463","location":"Bernal Oeste"},
    {"afip":"1468","location":"Bernardo Vera Y Pintado"},
    {"afip":"1471","location":"Berraondo"},
    {"afip":"1474","location":"Berutti"},
    {"afip":"1487","location":"Blanca Grande"},
    {"afip":"1492","location":"Blandengues"},
    {"afip":"1493","location":"Blaquier"},
    {"afip":"1495","location":"Blas Durañona"},
    {"afip":"1497","location":"Blondeau"},
    {"afip":"1498","location":"Bme Bavio Gral Mansilla"},
    {"afip":"1501","location":"Bo Sta Catalina Hornero La L"},
    {"afip":"1516","location":"Bocayuba"},
    {"afip":"1526","location":"Bolivar"},
    {"afip":"1533","location":"Bonifacio"},
    {"afip":"1534","location":"Bonnement"},
    {"afip":"1540","location":"Bordenave"},
    {"afip":"1541","location":"Bordeu"},
    {"afip":"1550","location":"Bosch"},
    {"afip":"1552","location":"Bosques"},
    {"afip":"1555","location":"Boulogne"},
    {"afip":"1563","location":"Bragado"},
    {"afip":"1565","location":"Bravo Del Dos"},
    {"afip":"1585","location":"Buchanan"},
    {"afip":"1610","location":"Burzaco"},
    {"afip":"1642","location":"Cabeza De Buey"},
    {"afip":"1649","location":"Cabildo"},
    {"afip":"1658","location":"Cabo San Fermin"},
    {"afip":"1669","location":"Cachari"},
    {"afip":"1696","location":"Cadret"},
    {"afip":"1703","location":"Cailomuta"},
    {"afip":"1738","location":"Calderon"},
    {"afip":"1743","location":"Calera Avellaneda"},
    {"afip":"1755","location":"Calfucura"},
    {"afip":"1776","location":"Calvo"},
    {"afip":"1778","location":"Camaoti"},
    {"afip":"1780","location":"Camaron Chico"},
    {"afip":"1785","location":"Cambaceres"},
    {"afip":"1790","location":"Camet"},
    {"afip":"1793","location":"Caminera Azul"},
    {"afip":"1794","location":"Caminera General Lopez"},
    {"afip":"1795","location":"Caminera Juarez"},
    {"afip":"1796","location":"Caminera Lujan"},
    {"afip":"1797","location":"Caminera Napaleofu"},
    {"afip":"1798","location":"Caminera Samborombon"},
    {"afip":"1804","location":"Camino Centenario Km 11500"},
    {"afip":"1810","location":"Campamento"},
    {"afip":"1823","location":"Campana"},
    {"afip":"1841","location":"Campo Aristimuño"},
    {"afip":"1856","location":"Campo Buena Vista"},
    {"afip":"1867","location":"Campo Coliqueo"},
    {"afip":"1873","location":"Campo Crisol"},
    {"afip":"1887","location":"Campo De Mayo"},
    {"afip":"1898","location":"Campo Del Norte Americano"},
    {"afip":"1920","location":"Campo Funke"},
    {"afip":"1942","location":"Campo La Elisa"},
    {"afip":"1946","location":"Campo La Lima"},
    {"afip":"1948","location":"Campo La Nena"},
    {"afip":"1951","location":"Campo La Plata"},
    {"afip":"1953","location":"Campo La Tribu"},
    {"afip":"1955","location":"Campo La Zulema"},
    {"afip":"1960","location":"Campo Leite"},
    {"afip":"1963","location":"Campo Lope Seco"},
    {"afip":"1964","location":"Campo Los Aromos"},
    {"afip":"1981","location":"Campo Pelaez"},
    {"afip":"1983","location":"Campo Peña Lopez"},
    {"afip":"2000","location":"Campo Rojas"},
    {"afip":"2004","location":"Campo Sabate"},
    {"afip":"2009","location":"Campo San Juan"},
    {"afip":"2031","location":"Campodonico"},
    {"afip":"2032","location":"Campomar Viñedo"},
    {"afip":"2036","location":"Canal 15 Cerro De La Gloria"},
    {"afip":"2037","location":"Canal Martin Irigoyen"},
    {"afip":"2038","location":"Canal N Alem 1A Sec"},
    {"afip":"2039","location":"Canal N Alem 2A Sec"},
    {"afip":"2049","location":"Cancha Del Pollo"},
    {"afip":"2063","location":"Cangallo"},
    {"afip":"2067","location":"Canning"},
    {"afip":"2068","location":"Canonigo Gorriti"},
    {"afip":"2071","location":"Cantera Aguirre"},
    {"afip":"2072","location":"Cantera Albion"},
    {"afip":"2074","location":"Cantera La Aurora"},
    {"afip":"2076","location":"Cantera La Federacion"},
    {"afip":"2077","location":"Cantera La Movediza"},
    {"afip":"2079","location":"Cantera Monte Cristo"},
    {"afip":"2081","location":"Cantera San Luis"},
    {"afip":"2082","location":"Cantera Villalonga"},
    {"afip":"2084","location":"Canteras De Gregorini"},
    {"afip":"2097","location":"Capdepont"},
    {"afip":"2117","location":"Capilla Del Señor"},
    {"afip":"2134","location":"Capitan Castro"},
    {"afip":"2140","location":"Capitan Sarmiento"},
    {"afip":"2150","location":"Carabelas"},
    {"afip":"2163","location":"Carapachay"},
    {"afip":"2174","location":"Cardenal Cagliero"},
    {"afip":"2178","location":"Carhue"},
    {"afip":"2180","location":"Cari Larquea"},
    {"afip":"2182","location":"Carilo"},
    {"afip":"2184","location":"Carlos Beguerie"},
    {"afip":"2186","location":"Carlos Casares"},
    {"afip":"2188","location":"Carlos Keen"},
    {"afip":"2189","location":"Carlos Lemee"},
    {"afip":"2190","location":"Carlos Maria Naon"},
    {"afip":"2194","location":"Carlos Salas"},
    {"afip":"2195","location":"Carlos Spegazzini"},
    {"afip":"2197","location":"Carlos Tejedor"},
    {"afip":"2201","location":"Carmen De Areco"},
    {"afip":"2202","location":"Carmen De Patagones"},
    {"afip":"2277","location":"Casalins"},
    {"afip":"2284","location":"Casbas"},
    {"afip":"2285","location":"Cascada"},
    {"afip":"2289","location":"Caseros"},
    {"afip":"2291","location":"Casey"},
    {"afip":"2311","location":"Castelar"},
    {"afip":"2313","location":"Castelli"},
    {"afip":"2316","location":"Castilla"},
    {"afip":"2357","location":"Cazon"},
    {"afip":"2372","location":"Cañada De Arias"},
    {"afip":"2424","location":"Cañada Mariano"},
    {"afip":"2425","location":"Cañada Marta"},
    {"afip":"2429","location":"Cañada Rica"},
    {"afip":"2434","location":"Cañada Seca"},
    {"afip":"2477","location":"Cañuelas"},
    {"afip":"2493","location":"Centenario"},
    {"afip":"2501","location":"Centro Agricola El Pato"},
    {"afip":"2506","location":"Centro Guerrero"},
    {"afip":"2514","location":"Cerri"},
    {"afip":"2522","location":"Cerrito"},
    {"afip":"2534","location":"Cerro Aguila"},
    {"afip":"2584","location":"Cerro De La Gloria Canal 15"},
    {"afip":"2595","location":"Cerro De Los Leones"},
    {"afip":"2688","location":"Cerro Negro"},
    {"afip":"2736","location":"Cerro Sotuyo"},
    {"afip":"2767","location":"Chacabuco"},
    {"afip":"2789","location":"Chacra Experimental Inta"},
    {"afip":"2819","location":"Chala Quilca"},
    {"afip":"2832","location":"Chancay"},
    {"afip":"2838","location":"Chapaleoufu"},
    {"afip":"2840","location":"Chapar"},
    {"afip":"2843","location":"Chapi Talo"},
    {"afip":"2863","location":"Chas"},
    {"afip":"2864","location":"Chascomus"},
    {"afip":"2865","location":"Chasico"},
    {"afip":"2916","location":"Chenaut"},
    {"afip":"2929","location":"Chiclana"},
    {"afip":"2949","location":"Chillar"},
    {"afip":"2981","location":"Chivilcoy"},
    {"afip":"2988","location":"Choique"},
    {"afip":"3027","location":"Churruca"},
    {"afip":"3056","location":"City Bell"},
    {"afip":"3059","location":"Ciudad De Eduardo Madero"},
    {"afip":"3061","location":"Ciudad Evita"},
    {"afip":"3063","location":"Ciudad Jardin Del Palomar"},
    {"afip":"3065","location":"Ciudadela"},
    {"afip":"3067","location":"Claraz"},
    {"afip":"3071","location":"Claudio C Molina"},
    {"afip":"3073","location":"Claverie"},
    {"afip":"3074","location":"Claypole"},
    {"afip":"3083","location":"Cna Nacional De Alienados"},
    {"afip":"3085","location":"Cnel Rodolfo Bunge"},
    {"afip":"3094","location":"Cobo"},
    {"afip":"3103","location":"Cochrane"},
    {"afip":"3127","location":"Colegio San Pablo"},
    {"afip":"3134","location":"Coliqueo"},
    {"afip":"3143","location":"Colman"},
    {"afip":"3151","location":"Colon"},
    {"afip":"3156","location":"Colonia  Miguel Esteverena"},
    {"afip":"3182","location":"Colonia Alberdi"},
    {"afip":"3230","location":"Colonia Barga"},
    {"afip":"3233","location":"Colonia Baron Hirsch"},
    {"afip":"3241","location":"Colonia Beethoven"},
    {"afip":"3247","location":"Colonia Bella Vista"},
    {"afip":"3323","location":"Colonia Cuarenta Y Tres"},
    {"afip":"3336","location":"Colonia De Vac Chapadmalal"},
    {"afip":"3358","location":"Colonia Dr Gdor Udaondo"},
    {"afip":"3370","location":"Colonia El Balde"},
    {"afip":"3384","location":"Colonia El Guanaco"},
    {"afip":"3394","location":"Colonia El Pincen"},
    {"afip":"3436","location":"Colonia Ferrari"},
    {"afip":"3489","location":"Colonia Hinojo"},
    {"afip":"3491","location":"Colonia Hipolito Yrigoyen"},
    {"afip":"3493","location":"Colonia Hogar R Gutierrez"},
    {"afip":"3499","location":"Colonia Inchausti"},
    {"afip":"3536","location":"Colonia La Beba"},
    {"afip":"3545","location":"Colonia La Catalina"},
    {"afip":"3547","location":"Colonia La Celina"},
    {"afip":"3554","location":"Colonia La Esperanza"},
    {"afip":"3555","location":"Colonia La Estrella"},
    {"afip":"3563","location":"Colonia La Graciela"},
    {"afip":"3566","location":"Colonia La Invernada"},
    {"afip":"3577","location":"Colonia La Merced"},
    {"afip":"3584","location":"Colonia La Nena"},
    {"afip":"3586","location":"Colonia La Noria"},
    {"afip":"3601","location":"Colonia La Reina"},
    {"afip":"3611","location":"Colonia La Vanguardia"},
    {"afip":"3612","location":"Colonia La Vascongada"},
    {"afip":"3615","location":"Colonia Laborderoy"},
    {"afip":"3620","location":"Colonia Lapin"},
    {"afip":"3630","location":"Colonia Las Yescas"},
    {"afip":"3635","location":"Colonia Leven"},
    {"afip":"3644","location":"Colonia Los Alamos"},
    {"afip":"3645","location":"Colonia Los Alfalfares"},
    {"afip":"3646","location":"Colonia Los Bosques"},
    {"afip":"3650","location":"Colonia Los Hornos"},
    {"afip":"3651","location":"Colonia Los Huesos"},
    {"afip":"3658","location":"Colonia Los Toldos"},
    {"afip":"3661","location":"Colonia Los Tres Usaris"},
    {"afip":"3696","location":"Colonia Mauricio"},
    {"afip":"3713","location":"Colonia Monte La Plata"},
    {"afip":"3721","location":"Colonia Murature"},
    {"afip":"3727","location":"Colonia Nacional De Menores"},
    {"afip":"3730","location":"Colonia Naviera"},
    {"afip":"3733","location":"Colonia Nieves"},
    {"afip":"3745","location":"Colonia Ocampo"},
    {"afip":"3764","location":"Colonia Palantelen"},
    {"afip":"3784","location":"Colonia Phillipson N 1"},
    {"afip":"3799","location":"Colonia Pueblo Ruso"},
    {"afip":"3824","location":"Colonia Rusa"},
    {"afip":"3837","location":"Colonia San Eduardo"},
    {"afip":"3838","location":"Colonia San Enrique"},
    {"afip":"3842","location":"Colonia San Francisco"},
    {"afip":"3854","location":"Colonia San Martin"},
    {"afip":"3856","location":"Colonia San Miguel"},
    {"afip":"3858","location":"Colonia San Pedro"},
    {"afip":"3860","location":"Colonia San Ramon"},
    {"afip":"3877","location":"Colonia Santa Maria"},
    {"afip":"3878","location":"Colonia Santa Mariana"},
    {"afip":"3883","location":"Colonia Santa Rosa"},
    {"afip":"3895","location":"Colonia Sere"},
    {"afip":"3908","location":"Colonia Stegman"},
    {"afip":"3914","location":"Colonia Tapatta"},
    {"afip":"3947","location":"Colonia Velez"},
    {"afip":"3967","location":"Colonia Zambungo"},
    {"afip":"3981","location":"Comahue Oeste"},
    {"afip":"3987","location":"Comandante Giribone"},
    {"afip":"3991","location":"Comandante Nicanor Otamendi"},
    {"afip":"3999","location":"Comodoro Py"},
    {"afip":"4016","location":"Condarco"},
    {"afip":"4040","location":"Cooper"},
    {"afip":"4046","location":"Copetonas"},
    {"afip":"4053","location":"Coraceros"},
    {"afip":"4057","location":"Corazzi"},
    {"afip":"4058","location":"Corbett"},
    {"afip":"4080","location":"Coronel Boerr"},
    {"afip":"4082","location":"Coronel Brandsen"},
    {"afip":"4083","location":"Coronel Charlone"},
    {"afip":"4087","location":"Coronel Dorrego"},
    {"afip":"4091","location":"Coronel Falcon"},
    {"afip":"4096","location":"Coronel Granada"},
    {"afip":"4099","location":"Coronel Isleños"},
    {"afip":"4103","location":"Coronel Maldonado"},
    {"afip":"4105","location":"Coronel Marcelino Freyre"},
    {"afip":"4107","location":"Coronel Martinez De Hoz"},
    {"afip":"4110","location":"Coronel Mon"},
    {"afip":"4113","location":"Coronel Pringles"},
    {"afip":"4114","location":"Coronel Rodolfo Bunge"},
    {"afip":"4118","location":"Coronel Segui"},
    {"afip":"4119","location":"Coronel Suarez"},
    {"afip":"4120","location":"Coronel Vidal"},
    {"afip":"4162","location":"Corti"},
    {"afip":"4163","location":"Cortines"},
    {"afip":"4174","location":"Costa Azul"},
    {"afip":"4176","location":"Costa Bonita Balneario"},
    {"afip":"4177","location":"Costa Brava"},
    {"afip":"4225","location":"Covello"},
    {"afip":"4239","location":"Cristiano Muerto"},
    {"afip":"4241","location":"Cristino Benavidez"},
    {"afip":"4245","location":"Crotto"},
    {"afip":"4254","location":"Crucesita"},
    {"afip":"4284","location":"Cuartel 2"},
    {"afip":"4285","location":"Cuartel 6"},
    {"afip":"4286","location":"Cuartel 8"},
    {"afip":"4287","location":"Cuartel Cuatro"},
    {"afip":"4288","location":"Cuartel V"},
    {"afip":"4290","location":"Cuatreros"},
    {"afip":"4297","location":"Cuatro De Noviembre"},
    {"afip":"4305","location":"Cucha Cucha"},
    {"afip":"4316","location":"Cucullu"},
    {"afip":"4317","location":"Cuenca"},
    {"afip":"4347","location":"Curamalan"},
    {"afip":"4350","location":"Curaru"},
    {"afip":"4375","location":"D Orbigny"},
    {"afip":"4376","location":"Daireaux"},
    {"afip":"4384","location":"Darregueira"},
    {"afip":"4386","location":"De Bary"},
    {"afip":"4387","location":"De Bruyn"},
    {"afip":"4388","location":"De Ferrari"},
    {"afip":"4389","location":"De La Canal"},
    {"afip":"4390","location":"De La Garma"},
    {"afip":"4394","location":"Del Carril"},
    {"afip":"4395","location":"Del Valle"},
    {"afip":"4396","location":"Del Viso"},
    {"afip":"4398","location":"Delfin Huergo"},
    {"afip":"4400","location":"Delgado"},
    {"afip":"4402","location":"Dennehy"},
    {"afip":"4411","location":"Despeñaderos"},
    {"afip":"4417","location":"Destileria Fiscal"},
    {"afip":"4418","location":"Desvio Aguirre"},
    {"afip":"4424","location":"Desvio El Chingolo"},
    {"afip":"4425","location":"Desvio Garbarini"},
    {"afip":"4428","location":"Desvio San Alejo"},
    {"afip":"4429","location":"Desvio Sandrini"},
    {"afip":"4439","location":"Diego Gaynor"},
    {"afip":"4445","location":"Dionisia"},
    {"afip":"4464","location":"Dique Lujan"},
    {"afip":"4483","location":"Doce De Agosto"},
    {"afip":"4484","location":"Dock Central"},
    {"afip":"4485","location":"Dock Sud"},
    {"afip":"4488","location":"Doctor Domingo Cabred"},
    {"afip":"4489","location":"Doctor Domingo Harosteguy"},
    {"afip":"4497","location":"Doctor Ricardo Levene"},
    {"afip":"4501","location":"Dolores"},
    {"afip":"4508","location":"Domingo Faustino Sarmiento"},
    {"afip":"4516","location":"Domselaar"},
    {"afip":"4518","location":"Don Bosco"},
    {"afip":"4519","location":"Don Cipriano"},
    {"afip":"4530","location":"Don Torcuato"},
    {"afip":"4531","location":"Don Vicente"},
    {"afip":"4549","location":"Dos Hermanos"},
    {"afip":"4552","location":"Dos Naciones"},
    {"afip":"4564","location":"Doyhenard"},
    {"afip":"4565","location":"Doyle"},
    {"afip":"4571","location":"Drabble"},
    {"afip":"4573","location":"Drysdale"},
    {"afip":"4575","location":"Ducos"},
    {"afip":"4576","location":"Dudignac"},
    {"afip":"4577","location":"Dufaur"},
    {"afip":"4578","location":"Duggan"},
    {"afip":"4579","location":"Duhau"},
    {"afip":"4587","location":"Durañona"},
    {"afip":"4589","location":"Dussaud"},
    {"afip":"4596","location":"Edmundo Perkins"},
    {"afip":"4598","location":"Eduardo Costa"},
    {"afip":"4599","location":"Egaña"},
    {"afip":"4613","location":"El 60"},
    {"afip":"4630","location":"El Alba"},
    {"afip":"4652","location":"El Araza"},
    {"afip":"4659","location":"El Arbolito Pergamino"},
    {"afip":"4678","location":"El Bagual"},
    {"afip":"4705","location":"El Bombero"},
    {"afip":"4708","location":"El Boqueron"},
    {"afip":"4762","location":"El Carbon"},
    {"afip":"4766","location":"El Carmen"},
    {"afip":"4768","location":"El Carmen De Langueyu"},
    {"afip":"4772","location":"El Carpincho"},
    {"afip":"4773","location":"El Carretero"},
    {"afip":"4799","location":"El Centinela"},
    {"afip":"4816","location":"El Chaja"},
    {"afip":"4817","location":"El Chalar"},
    {"afip":"4831","location":"El Cheique"},
    {"afip":"4849","location":"El Chumbiao"},
    {"afip":"4887","location":"El Cortapie"},
    {"afip":"4894","location":"El Cristiano"},
    {"afip":"4913","location":"El Descanso"},
    {"afip":"4920","location":"El Destino"},
    {"afip":"4923","location":"El Dia"},
    {"afip":"4933","location":"El Divisorio"},
    {"afip":"4934","location":"El Dorado"},
    {"afip":"4939","location":"El Durazno"},
    {"afip":"4950","location":"El Espinillo"},
    {"afip":"4957","location":"El Eucaliptus"},
    {"afip":"4959","location":"El Fenix"},
    {"afip":"4978","location":"El Gallo"},
    {"afip":"4998","location":"El Gualicho"},
    {"afip":"5006","location":"El Hervidero"},
    {"afip":"5022","location":"El Jabali"},
    {"afip":"5024","location":"El Jaguel"},
    {"afip":"5036","location":"El Junco"},
    {"afip":"5038","location":"El Jupiter"},
    {"afip":"5047","location":"El Lenguaraz"},
    {"afip":"5050","location":"El Libertador"},
    {"afip":"5054","location":"El Lucero"},
    {"afip":"5055","location":"El Luchador"},
    {"afip":"5065","location":"El Mangrullo"},
    {"afip":"5073","location":"El Marquesado"},
    {"afip":"5086","location":"El Mirador"},
    {"afip":"5107","location":"El Moro"},
    {"afip":"5113","location":"El Nacional"},
    {"afip":"5124","location":"El Nilo"},
    {"afip":"5170","location":"El Palomar"},
    {"afip":"5171","location":"El Pampero"},
    {"afip":"5180","location":"El Paraiso"},
    {"afip":"5183","location":"El Parche"},
    {"afip":"5184","location":"El Parque"},
    {"afip":"5202","location":"El Pelado"},
    {"afip":"5206","location":"El Pensamiento"},
    {"afip":"5211","location":"El Peregrino"},
    {"afip":"5234","location":"El Pino"},
    {"afip":"5241","location":"El Pito"},
    {"afip":"5263","location":"El Porvenir"},
    {"afip":"5295","location":"El Quemado"},
    {"afip":"5312","location":"El Recado"},
    {"afip":"5318","location":"El Refugio"},
    {"afip":"5326","location":"El Retiro"},
    {"afip":"5327","location":"El Retiro Pdo Gral Viamonte"},
    {"afip":"5332","location":"El Rincon"},
    {"afip":"5341","location":"El Rosario"},
    {"afip":"5354","location":"El Santiago"},
    {"afip":"5358","location":"El Sauce"},
    {"afip":"5371","location":"El Siasgo"},
    {"afip":"5372","location":"El Silencio"},
    {"afip":"5378","location":"El Socorro"},
    {"afip":"5381","location":"El Soldado"},
    {"afip":"5399","location":"El Talar"},
    {"afip":"5415","location":"El Tatu"},
    {"afip":"5416","location":"El Tejar"},
    {"afip":"5444","location":"El Trebañon"},
    {"afip":"5450","location":"El Triangulo"},
    {"afip":"5452","location":"El Trigo"},
    {"afip":"5453","location":"El Trio"},
    {"afip":"5454","location":"El Triunfo"},
    {"afip":"5483","location":"El Vence"},
    {"afip":"5488","location":"El Verano"},
    {"afip":"5491","location":"El Vigilante"},
    {"afip":"5499","location":"El Volante"},
    {"afip":"5518","location":"El Zorro"},
    {"afip":"5528","location":"Elias Romero"},
    {"afip":"5530","location":"Elordi"},
    {"afip":"5534","location":"Elvira"},
    {"afip":"5546","location":"Emiliano Reynoso"},
    {"afip":"5547","location":"Emilio Ayarza"},
    {"afip":"5548","location":"Emilio Bunge"},
    {"afip":"5549","location":"Emilio Lamarca"},
    {"afip":"5553","location":"Emita"},
    {"afip":"5554","location":"Emma"},
    {"afip":"5555","location":"Empalme"},
    {"afip":"5559","location":"Empalme Cerro Chato"},
    {"afip":"5562","location":"Empalme Lobos"},
    {"afip":"5563","location":"Empalme Magdalena"},
    {"afip":"5565","location":"Empalme Piedra Echada"},
    {"afip":"5567","location":"Empalme Querandies"},
    {"afip":"5574","location":"Encina"},
    {"afip":"5578","location":"Energia"},
    {"afip":"5582","location":"Enrique Fynn"},
    {"afip":"5584","location":"Enrique Lavalle"},
    {"afip":"5588","location":"Ensenada"},
    {"afip":"5595","location":"Epumer"},
    {"afip":"5597","location":"Erezcano"},
    {"afip":"5598","location":"Erize"},
    {"afip":"5599","location":"Ernestina"},
    {"afip":"5602","location":"Esc Nav Militar Rio Sant"},
    {"afip":"5606","location":"Escalada"},
    {"afip":"5612","location":"Escribano P Nicolas"},
    {"afip":"5618","location":"Espadaña"},
    {"afip":"5619","location":"Espartillar"},
    {"afip":"5625","location":"Espigas"},
    {"afip":"5633","location":"Espora"},
    {"afip":"5636","location":"Esquina De Crotto"},
    {"afip":"5655","location":"Est San Francisco Belloq"},
    {"afip":"5675","location":"Establecimiento San Miguel"},
    {"afip":"5684","location":"Estacion Ascension"},
    {"afip":"5686","location":"Estacion Baradero"},
    {"afip":"5687","location":"Estacion Barrow"},
    {"afip":"5690","location":"Estacion Caiomuta"},
    {"afip":"5697","location":"Estacion Coronel Pringles"},
    {"afip":"5702","location":"Estacion General Arenales"},
    {"afip":"5705","location":"Estacion Gomez"},
    {"afip":"5709","location":"Estacion Lago Epecuen"},
    {"afip":"5710","location":"Estacion Lazzarino"},
    {"afip":"5712","location":"Estacion Lincoln"},
    {"afip":"5716","location":"Estacion Mitikili"},
    {"afip":"5717","location":"Estacion Moreno"},
    {"afip":"5722","location":"Estacion Provincial"},
    {"afip":"5763","location":"Estancia Chapar"},
    {"afip":"5886","location":"Estancia Las Gamas"},
    {"afip":"5890","location":"Estancia Las Isletas"},
    {"afip":"5949","location":"Estancia San Antonio"},
    {"afip":"5950","location":"Estancia San Claudio"},
    {"afip":"5961","location":"Estancia San Rafael"},
    {"afip":"5966","location":"Estancia Santa Catalina"},
    {"afip":"5982","location":"Estancia Vieja"},
    {"afip":"5987","location":"Estancias"},
    {"afip":"5994","location":"Esteban A Gascon"},
    {"afip":"5995","location":"Esteban De Luca"},
    {"afip":"5997","location":"Esteban Diaz"},
    {"afip":"6001","location":"Estela"},
    {"afip":"6010","location":"Esther"},
    {"afip":"6012","location":"Estomba"},
    {"afip":"6015","location":"Estrella Naciente"},
    {"afip":"6016","location":"Estrugamou"},
    {"afip":"6017","location":"Etchegoyen"},
    {"afip":"6038","location":"Exaltacion De La Cruz"},
    {"afip":"6040","location":"Ezeiza"},
    {"afip":"6041","location":"Ezpeleta Este"},
    {"afip":"6042","location":"Ezpeleta Oeste"},
    {"afip":"6051","location":"Fair"},
    {"afip":"6068","location":"Faro"},
    {"afip":"6071","location":"Faro Querandi"},
    {"afip":"6072","location":"Faro San Antonio"},
    {"afip":"6073","location":"Faro Segunda Barrancosa"},
    {"afip":"6076","location":"Fatima Estacion Empalme"},
    {"afip":"6077","location":"Fatralo"},
    {"afip":"6079","location":"Fauzon"},
    {"afip":"6089","location":"Felipe Sola"},
    {"afip":"6094","location":"Fernando Marti"},
    {"afip":"6095","location":"Ferre"},
    {"afip":"6162","location":"Flamenco"},
    {"afip":"6171","location":"Florencio Varela"},
    {"afip":"6172","location":"Florentino Ameghino"},
    {"afip":"6175","location":"Florida"},
    {"afip":"6176","location":"Florida Oeste"},
    {"afip":"6180","location":"Fontezuela"},
    {"afip":"6182","location":"Fortabat"},
    {"afip":"6185","location":"Fortin Acha"},
    {"afip":"6197","location":"Fortin Chaco"},
    {"afip":"6208","location":"Fortin Irene"},
    {"afip":"6212","location":"Fortin Lavalle"},
    {"afip":"6214","location":"Fortin Mercedes"},
    {"afip":"6215","location":"Fortin Necochea"},
    {"afip":"6216","location":"Fortin Olavarria"},
    {"afip":"6218","location":"Fortin Paunero"},
    {"afip":"6225","location":"Fortin Tiburcio"},
    {"afip":"6229","location":"Fortin Viejo"},
    {"afip":"6230","location":"Fortin Vigilancia"},
    {"afip":"6240","location":"Francisco Alvarez"},
    {"afip":"6241","location":"Francisco Ayerza"},
    {"afip":"6242","location":"Francisco Berra"},
    {"afip":"6243","location":"Francisco Casal"},
    {"afip":"6244","location":"Francisco De Vitoria"},
    {"afip":"6246","location":"Francisco J Meeks"},
    {"afip":"6247","location":"Francisco Madero"},
    {"afip":"6248","location":"Francisco Magnano"},
    {"afip":"6250","location":"Francisco Murature"},
    {"afip":"6257","location":"Franklin"},
    {"afip":"6261","location":"French"},
    {"afip":"6264","location":"Frigorifico Armour"},
    {"afip":"6266","location":"Frigorifico Las Palmas"},
    {"afip":"6280","location":"Fuerte Argentino"},
    {"afip":"6281","location":"Fuerte Barragan"},
    {"afip":"6285","location":"Fulton"},
    {"afip":"6288","location":"Funke"},
    {"afip":"6294","location":"Gahan"},
    {"afip":"6298","location":"Galera De Torres"},
    {"afip":"6304","location":"Galo Llorente"},
    {"afip":"6307","location":"Galvan"},
    {"afip":"6311","location":"Gandara"},
    {"afip":"6321","location":"Garcia Del Rio"},
    {"afip":"6323","location":"Gardey"},
    {"afip":"6326","location":"Garin"},
    {"afip":"6329","location":"Garre"},
    {"afip":"6332","location":"Garro"},
    {"afip":"6355","location":"General Alvarado"},
    {"afip":"6356","location":"General Alvear"},
    {"afip":"6357","location":"General Arenales"},
    {"afip":"6360","location":"General Belgrano"},
    {"afip":"6364","location":"General Conesa"},
    {"afip":"6365","location":"General Daniel Cerri"},
    {"afip":"6374","location":"General Gelly"},
    {"afip":"6376","location":"General Guido"},
    {"afip":"6378","location":"General Hornos"},
    {"afip":"6382","location":"General Lamadrid"},
    {"afip":"6383","location":"General Las Heras"},
    {"afip":"6384","location":"General Lavalle"},
    {"afip":"6387","location":"General Madariaga"},
    {"afip":"6388","location":"General Mansilla"},
    {"afip":"6393","location":"General O Brien"},
    {"afip":"6398","location":"General Pacheco"},
    {"afip":"6403","location":"General Pinto"},
    {"afip":"6404","location":"General Piran"},
    {"afip":"6409","location":"General Rivas"},
    {"afip":"6411","location":"General Rodriguez"},
    {"afip":"6412","location":"General Rondeau"},
    {"afip":"6413","location":"General San Martin"},
    {"afip":"6416","location":"General Valdez"},
    {"afip":"6418","location":"General Viamonte"},
    {"afip":"6419","location":"General Villegas"},
    {"afip":"6423","location":"Gerente Cilley"},
    {"afip":"6425","location":"Gerli"},
    {"afip":"6427","location":"Germania"},
    {"afip":"6434","location":"Gil"},
    {"afip":"6439","location":"Girodias"},
    {"afip":"6443","location":"Glew"},
    {"afip":"6445","location":"Glorialdo"},
    {"afip":"6447","location":"Gnecco"},
    {"afip":"6448","location":"Gobernador Andonaeghi"},
    {"afip":"6449","location":"Gobernador Arias"},
    {"afip":"6452","location":"Gobernador Castro"},
    {"afip":"6454","location":"Gobernador Costa"},
    {"afip":"6470","location":"Gobernador Obligado"},
    {"afip":"6471","location":"Gobernador Ortiz De Rosas"},
    {"afip":"6479","location":"Gobernador Udaondo"},
    {"afip":"6480","location":"Gobernador Ugarte"},
    {"afip":"6483","location":"Gobos"},
    {"afip":"6488","location":"Goldney"},
    {"afip":"6491","location":"Gomez"},
    {"afip":"6492","location":"Gomez  De La Vega"},
    {"afip":"6495","location":"Gondra"},
    {"afip":"6498","location":"Gonzalez Catan"},
    {"afip":"6499","location":"Gonzalez Moreno"},
    {"afip":"6500","location":"Gonzalez Risos"},
    {"afip":"6502","location":"Gorchs"},
    {"afip":"6504","location":"Gornatti"},
    {"afip":"6505","location":"Goroso"},
    {"afip":"6506","location":"Gorostiaga"},
    {"afip":"6508","location":"Gouin"},
    {"afip":"6509","location":"Gowland"},
    {"afip":"6511","location":"Goyena"},
    {"afip":"6512","location":"Goyeneche"},
    {"afip":"6513","location":"Goñi"},
    {"afip":"6515","location":"Graciarena"},
    {"afip":"6532","location":"Grand Bourg"},
    {"afip":"6533","location":"Grand Dock"},
    {"afip":"6540","location":"Gregorio De La Ferrere"},
    {"afip":"6541","location":"Gregorio Villafañe"},
    {"afip":"6543","location":"Grisolia"},
    {"afip":"6544","location":"Grunbein"},
    {"afip":"6575","location":"Guamini"},
    {"afip":"6578","location":"Guanaco"},
    {"afip":"6596","location":"Guardia Del Monte"},
    {"afip":"6630","location":"Guernica"},
    {"afip":"6632","location":"Guerrero"},
    {"afip":"6634","location":"Guerrico"},
    {"afip":"6636","location":"Guido Spano"},
    {"afip":"6638","location":"Guillermo E Hudson"},
    {"afip":"6641","location":"Guirondo"},
    {"afip":"6643","location":"Gunther"},
    {"afip":"6651","location":"Haedo"},
    {"afip":"6654","location":"Halcey"},
    {"afip":"6655","location":"Hale"},
    {"afip":"6656","location":"Ham"},
    {"afip":"6658","location":"Haras 1 De Mayo"},
    {"afip":"6659","location":"Haras Chacabuco"},
    {"afip":"6660","location":"Haras Chapadmalal"},
    {"afip":"6661","location":"Haras El Carmen"},
    {"afip":"6662","location":"Haras El Catorce"},
    {"afip":"6663","location":"Haras El Centinela"},
    {"afip":"6664","location":"Haras El Cisne"},
    {"afip":"6665","location":"Haras El Moro"},
    {"afip":"6666","location":"Haras El Ombu"},
    {"afip":"6667","location":"Haras El Salaso"},
    {"afip":"6668","location":"Haras La Elvira"},
    {"afip":"6669","location":"Haras La Lula"},
    {"afip":"6670","location":"Haras Los Cardales"},
    {"afip":"6671","location":"Haras Nacional"},
    {"afip":"6672","location":"Haras Ojo Del Agua"},
    {"afip":"6674","location":"Haras R De La Parva"},
    {"afip":"6676","location":"Haras San Ignacio"},
    {"afip":"6677","location":"Haras San Jacinto"},
    {"afip":"6679","location":"Haras Trujui"},
    {"afip":"6684","location":"Heavy"},
    {"afip":"6687","location":"Henderson"},
    {"afip":"6688","location":"Henry Bell"},
    {"afip":"6689","location":"Hereford"},
    {"afip":"6700","location":"Herrera Vegas"},
    {"afip":"6712","location":"Hilario Ascasubi"},
    {"afip":"6715","location":"Hinojales"},
    {"afip":"6717","location":"Hinojo"},
    {"afip":"6731","location":"Hogar Mariano Ortiz Basualdo"},
    {"afip":"6745","location":"Hornos"},
    {"afip":"6748","location":"Hortensia"},
    {"afip":"6750","location":"Hospital Interzonal Dr Domingo"},
    {"afip":"6751","location":"Hospital Necochea"},
    {"afip":"6753","location":"Hospital San Antonio De La Lla"},
    {"afip":"6791","location":"Huanguelen"},
    {"afip":"6825","location":"Hueso Clavado"},
    {"afip":"6827","location":"Huetel"},
    {"afip":"6851","location":"Hunter"},
    {"afip":"6853","location":"Hurlingham"},
    {"afip":"6854","location":"Husares"},
    {"afip":"6865","location":"Ibañez"},
    {"afip":"6876","location":"Igarzabal"},
    {"afip":"6880","location":"Ignacio Correas Arana"},
    {"afip":"6898","location":"Indacochea"},
    {"afip":"6900","location":"India Muerta"},
    {"afip":"6902","location":"Indio Rico"},
    {"afip":"6904","location":"Ines Indart"},
    {"afip":"6914","location":"Ingeniero Adolfo Sourdeaux"},
    {"afip":"6915","location":"Ingeniero Allan"},
    {"afip":"6917","location":"Ingeniero Balbin"},
    {"afip":"6920","location":"Ingeniero Beaugey"},
    {"afip":"6925","location":"Ingeniero De Madrid"},
    {"afip":"6937","location":"Ingeniero Maschwitz"},
    {"afip":"6940","location":"Ingeniero Moneta"},
    {"afip":"6943","location":"Ingeniero Silveyra"},
    {"afip":"6944","location":"Ingeniero Thompson"},
    {"afip":"6945","location":"Ingeniero White"},
    {"afip":"6946","location":"Ingeniero Williams"},
    {"afip":"6972","location":"Inocencio Sosa"},
    {"afip":"6986","location":"Invernadas"},
    {"afip":"6991","location":"Irala"},
    {"afip":"6992","location":"Iraola"},
    {"afip":"6994","location":"Irene"},
    {"afip":"6995","location":"Ireneo Portela"},
    {"afip":"6996","location":"Iriarte"},
    {"afip":"7009","location":"Isidro Casanova"},
    {"afip":"7019","location":"Isla Catarelli"},
    {"afip":"7045","location":"Isla Los Laureles"},
    {"afip":"7047","location":"Isla Martin Garcia"},
    {"afip":"7050","location":"Isla Paulino"},
    {"afip":"7057","location":"Isla Santiago"},
    {"afip":"7064","location":"Isla Verde"},
    {"afip":"7066","location":"Islas"},
    {"afip":"7075","location":"Isondu"},
    {"afip":"7094","location":"Iturregui"},
    {"afip":"7096","location":"Ituzaingo"},
    {"afip":"7100","location":"J M Micheo"},
    {"afip":"7135","location":"Jarrilla"},
    {"afip":"7137","location":"Jauregui Jose Maria"},
    {"afip":"7143","location":"Jeppener"},
    {"afip":"7150","location":"Joaquin Gorina"},
    {"afip":"7157","location":"Jose A Guisasola"},
    {"afip":"7158","location":"Jose B Casas"},
    {"afip":"7161","location":"Jose Clemente Paz"},
    {"afip":"7166","location":"Jose Ferrari"},
    {"afip":"7168","location":"Jose Ingenieros"},
    {"afip":"7169","location":"Jose Leon Suarez"},
    {"afip":"7173","location":"Jose Maria Blanco"},
    {"afip":"7174","location":"Jose Maria Gutierrez"},
    {"afip":"7175","location":"Jose Marmol"},
    {"afip":"7178","location":"Jose Sojo"},
    {"afip":"7184","location":"Juan A Pradere"},
    {"afip":"7185","location":"Juan Atucha"},
    {"afip":"7187","location":"Juan B Justo Ing White"},
    {"afip":"7190","location":"Juan Bautista Alberdi"},
    {"afip":"7191","location":"Juan Blaquier"},
    {"afip":"7193","location":"Juan Couste"},
    {"afip":"7195","location":"Juan E Barra"},
    {"afip":"7196","location":"Juan F Ibarra"},
    {"afip":"7198","location":"Juan G Pujol"},
    {"afip":"7204","location":"Juan Jose Almeyra"},
    {"afip":"7206","location":"Juan Jose Paso"},
    {"afip":"7210","location":"Juan N Fernandez"},
    {"afip":"7215","location":"Juan Tronconi"},
    {"afip":"7216","location":"Juan V Cilley"},
    {"afip":"7217","location":"Juan Vela"},
    {"afip":"7218","location":"Juan Vucetich Ex Dr R Levene"},
    {"afip":"7221","location":"Juana A De La Peña"},
    {"afip":"7223","location":"Juancho"},
    {"afip":"7233","location":"Julio Arditi"},
    {"afip":"7251","location":"Junin"},
    {"afip":"7268","location":"Kenny"},
    {"afip":"7280","location":"Krabbe"},
    {"afip":"7285","location":"La  Aurora"},
    {"afip":"7288","location":"La Adela"},
    {"afip":"7289","location":"La Adelaida"},
    {"afip":"7301","location":"La Alameda"},
    {"afip":"7302","location":"La Alcira"},
    {"afip":"7311","location":"La Amalia"},
    {"afip":"7316","location":"La Amistad"},
    {"afip":"7317","location":"La Amorilla"},
    {"afip":"7320","location":"La Angelita"},
    {"afip":"7330","location":"La Argentina"},
    {"afip":"7331","location":"La Armonia"},
    {"afip":"7337","location":"La Aurora"},
    {"afip":"7339","location":"La Azotea"},
    {"afip":"7340","location":"La Azotea Grande"},
    {"afip":"7341","location":"La Azucena"},
    {"afip":"7343","location":"La Balandra"},
    {"afip":"7345","location":"La Ballena"},
    {"afip":"7346","location":"La Ballenera"},
    {"afip":"7356","location":"La Barrancosa"},
    {"afip":"7367","location":"La Beba"},
    {"afip":"7375","location":"La Blanca"},
    {"afip":"7376","location":"La Blanqueada"},
    {"afip":"7380","location":"La Bolsa"},
    {"afip":"7388","location":"La Brava"},
    {"afip":"7391","location":"La Buana Moza"},
    {"afip":"7404","location":"La Calera"},
    {"afip":"7406","location":"La Caleta"},
    {"afip":"7408","location":"La California Argentina"},
    {"afip":"7413","location":"La Campana"},
    {"afip":"7423","location":"La Carlota"},
    {"afip":"7430","location":"La Carreta"},
    {"afip":"7444","location":"La Cautiva"},
    {"afip":"7453","location":"La Celia"},
    {"afip":"7454","location":"La Celina"},
    {"afip":"7457","location":"La Central"},
    {"afip":"7489","location":"La Choza"},
    {"afip":"7490","location":"La Chumbeada"},
    {"afip":"7507","location":"La Colina"},
    {"afip":"7508","location":"La Colmena"},
    {"afip":"7511","location":"La Colorada"},
    {"afip":"7512","location":"La Colorada Chica"},
    {"afip":"7517","location":"La Constancia"},
    {"afip":"7521","location":"La Copeta"},
    {"afip":"7522","location":"La Cora"},
    {"afip":"7523","location":"La Corina"},
    {"afip":"7524","location":"La Corinco"},
    {"afip":"7531","location":"La Costa"},
    {"afip":"7535","location":"La Cotorra"},
    {"afip":"7554","location":"La Delfina"},
    {"afip":"7555","location":"La Delia"},
    {"afip":"7557","location":"La Despierta"},
    {"afip":"7568","location":"La Dorita"},
    {"afip":"7570","location":"La Dormilona"},
    {"afip":"7572","location":"La Dulce"},
    {"afip":"7582","location":"La Elma"},
    {"afip":"7605","location":"La Esperanza"},
    {"afip":"7609","location":"La Esperanza Gral Madariaga"},
    {"afip":"7610","location":"La Esperanza Pdo Las Flores"},
    {"afip":"7626","location":"La Estrella"},
    {"afip":"7631","location":"La Eva"},
    {"afip":"7643","location":"La Feliciana"},
    {"afip":"7654","location":"La Florida"},
    {"afip":"7659","location":"La Fraternidad"},
    {"afip":"7670","location":"La Garita"},
    {"afip":"7674","location":"La Gaviota"},
    {"afip":"7679","location":"La Gleva"},
    {"afip":"7680","location":"La Gloria"},
    {"afip":"7683","location":"La Gracielita"},
    {"afip":"7690","location":"La Gregoria"},
    {"afip":"7701","location":"La Herminia"},
    {"afip":"7706","location":"La Higuera"},
    {"afip":"7710","location":"La Horqueta"},
    {"afip":"7714","location":"La Huayqueria"},
    {"afip":"7728","location":"La Invencible"},
    {"afip":"7731","location":"La Isabel"},
    {"afip":"7759","location":"La Larga"},
    {"afip":"7760","location":"La Larga Nueva"},
    {"afip":"7761","location":"La Lata"},
    {"afip":"7776","location":"La Limpia"},
    {"afip":"7786","location":"La Loma"},
    {"afip":"7793","location":"La Lucia"},
    {"afip":"7794","location":"La Lucila"},
    {"afip":"7795","location":"La Lucila Del Mar"},
    {"afip":"7798","location":"La Luisa"},
    {"afip":"7799","location":"La Luna"},
    {"afip":"7801","location":"La Luz"},
    {"afip":"7802","location":"La Madrecita"},
    {"afip":"7812","location":"La Mantequeria"},
    {"afip":"7813","location":"La Manuela"},
    {"afip":"7818","location":"La Margarita"},
    {"afip":"7820","location":"La Maria"},
    {"afip":"7832","location":"La Martina"},
    {"afip":"7836","location":"La Mascota"},
    {"afip":"7838","location":"La Matilde"},
    {"afip":"7867","location":"La Nacion"},
    {"afip":"7869","location":"La Narcisa"},
    {"afip":"7870","location":"La Navarra"},
    {"afip":"7871","location":"La Negra"},
    {"afip":"7873","location":"La Nelida"},
    {"afip":"7875","location":"La Nevada"},
    {"afip":"7878","location":"La Niña"},
    {"afip":"7880","location":"La Noria"},
    {"afip":"7883","location":"La Numancia"},
    {"afip":"7884","location":"La Nutria"},
    {"afip":"7892","location":"La Oriental"},
    {"afip":"7904","location":"La Pala"},
    {"afip":"7909","location":"La Palma"},
    {"afip":"7913","location":"La Palmira"},
    {"afip":"7917","location":"La Pampa"},
    {"afip":"7923","location":"La Para"},
    {"afip":"7927","location":"La Pastora"},
    {"afip":"7934","location":"La Paz"},
    {"afip":"7935","location":"La Paz Chica"},
    {"afip":"7943","location":"La Peregrina"},
    {"afip":"7944","location":"La Perla"},
    {"afip":"7946","location":"La Pesqueria"},
    {"afip":"7958","location":"La Piedra"},
    {"afip":"7963","location":"La Pinta"},
    {"afip":"7970","location":"La Plata"},
    {"afip":"7971","location":"La Playa"},
    {"afip":"7976","location":"La Pochola"},
    {"afip":"7984","location":"La Porteña"},
    {"afip":"7985","location":"La Posada"},
    {"afip":"7986","location":"La Posta"},
    {"afip":"7990","location":"La Pradera"},
    {"afip":"7991","location":"La Primavera"},
    {"afip":"7996","location":"La Primitiva"},
    {"afip":"7998","location":"La Proteccion"},
    {"afip":"7999","location":"La Protegida"},
    {"afip":"8000","location":"La Providencia"},
    {"afip":"8018","location":"La Querencia"},
    {"afip":"8024","location":"La Rabia"},
    {"afip":"8030","location":"La Razon"},
    {"afip":"8038","location":"La Reforma"},
    {"afip":"8041","location":"La Reja"},
    {"afip":"8046","location":"La Reserva"},
    {"afip":"8051","location":"La Rica"},
    {"afip":"8052","location":"La Rinconada"},
    {"afip":"8062","location":"La Rosada"},
    {"afip":"8063","location":"La Rosalia"},
    {"afip":"8069","location":"La Rubia"},
    {"afip":"8075","location":"La Salada"},
    {"afip":"8085","location":"La Sara"},
    {"afip":"8086","location":"La Sarita"},
    {"afip":"8088","location":"La Saudade"},
    {"afip":"8105","location":"La Sirena"},
    {"afip":"8106","location":"La Soberania"},
    {"afip":"8107","location":"La Sofia"},
    {"afip":"8109","location":"La Sombra"},
    {"afip":"8112","location":"La Sortija"},
    {"afip":"8114","location":"La Suiza"},
    {"afip":"8116","location":"La Tablada"},
    {"afip":"8118","location":"La Talina"},
    {"afip":"8127","location":"La Tigra"},
    {"afip":"8131","location":"La Tobiana"},
    {"afip":"8133","location":"La Tomasa"},
    {"afip":"8138","location":"La Torrecita"},
    {"afip":"8146","location":"La Tribu"},
    {"afip":"8148","location":"La Trinidad"},
    {"afip":"8157","location":"La Union"},
    {"afip":"8164","location":"La Valerosa"},
    {"afip":"8165","location":"La Vanguardia"},
    {"afip":"8167","location":"La Vascongada"},
    {"afip":"8175","location":"La Verde"},
    {"afip":"8181","location":"La Victoria"},
    {"afip":"8182","location":"La Victoria Desvio"},
    {"afip":"8184","location":"La Violeta"},
    {"afip":"8185","location":"La Virginia"},
    {"afip":"8188","location":"La Viticola"},
    {"afip":"8198","location":"La Yesca"},
    {"afip":"8200","location":"La Zanja"},
    {"afip":"8202","location":"La Zarateña"},
    {"afip":"8209","location":"Labarden"},
    {"afip":"8227","location":"Lago Epecuen"},
    {"afip":"8250","location":"Laguna Alsina"},
    {"afip":"8255","location":"Laguna Brava"},
    {"afip":"8257","location":"Laguna Chasico"},
    {"afip":"8261","location":"Laguna De Gomez"},
    {"afip":"8265","location":"Laguna De Lobos"},
    {"afip":"8266","location":"Laguna De Los Padres"},
    {"afip":"8272","location":"Laguna Del Cura"},
    {"afip":"8274","location":"Laguna Del Monte"},
    {"afip":"8277","location":"Laguna Del Soldado"},
    {"afip":"8287","location":"Laguna Las Mulitas"},
    {"afip":"8290","location":"Laguna Medina"},
    {"afip":"8300","location":"Laguna Redonda"},
    {"afip":"8331","location":"Langueyu"},
    {"afip":"8335","location":"Lanus"},
    {"afip":"8343","location":"Laplacette"},
    {"afip":"8344","location":"Laprida"},
    {"afip":"8348","location":"Larramendy"},
    {"afip":"8350","location":"Larrea"},
    {"afip":"8354","location":"Lartigau"},
    {"afip":"8362","location":"Las Achiras"},
    {"afip":"8375","location":"Las Armas"},
    {"afip":"8381","location":"Las Bahamas"},
    {"afip":"8385","location":"Las Bandurrias"},
    {"afip":"8402","location":"Las Bruscas"},
    {"afip":"8432","location":"Las Chacras"},
    {"afip":"8437","location":"Las Chilcas"},
    {"afip":"8453","location":"Las Cortaderas"},
    {"afip":"8462","location":"Las Cuatro Hermanas"},
    {"afip":"8463","location":"Las Cuatro Puertas"},
    {"afip":"8480","location":"Las Escobas"},
    {"afip":"8488","location":"Las Flores"},
    {"afip":"8504","location":"Las Guasquitas"},
    {"afip":"8510","location":"Las Hermanas"},
    {"afip":"8520","location":"Las Isletas"},
    {"afip":"8524","location":"Las Juanitas"},
    {"afip":"8543","location":"Las Lomas"},
    {"afip":"8546","location":"Las Malvinas"},
    {"afip":"8552","location":"Las Marianas"},
    {"afip":"8553","location":"Las Martinetas"},
    {"afip":"8558","location":"Las Mercedes"},
    {"afip":"8572","location":"Las Mostazas"},
    {"afip":"8573","location":"Las Mulas"},
    {"afip":"8575","location":"Las Negras"},
    {"afip":"8576","location":"Las Nieves"},
    {"afip":"8577","location":"Las Nutrias"},
    {"afip":"8579","location":"Las Oscuras"},
    {"afip":"8585","location":"Las Palmas"},
    {"afip":"8598","location":"Las Parvas"},
    {"afip":"8615","location":"Las Piedritas"},
    {"afip":"8616","location":"Las Piedrtas"},
    {"afip":"8642","location":"Las Rosas"},
    {"afip":"8643","location":"Las Saladas"},
    {"afip":"8650","location":"Las Suizas"},
    {"afip":"8651","location":"Las Sultanas"},
    {"afip":"8656","location":"Las Tahonas"},
    {"afip":"8671","location":"Las Toninas"},
    {"afip":"8673","location":"Las Tortugas"},
    {"afip":"8674","location":"Las Toscas"},
    {"afip":"8680","location":"Las Tres Flores"},
    {"afip":"8694","location":"Las Vaquerias"},
    {"afip":"8705","location":"Las Viboras"},
    {"afip":"8720","location":"Lastra"},
    {"afip":"8738","location":"Lazzarino"},
    {"afip":"8741","location":"Leandro N Alem"},
    {"afip":"8748","location":"Legaristi"},
    {"afip":"8765","location":"Lertora"},
    {"afip":"8769","location":"Leubuco"},
    {"afip":"8771","location":"Lezama"},
    {"afip":"8774","location":"Lezica Y Torrezuri"},
    {"afip":"8777","location":"Libano"},
    {"afip":"8779","location":"Libertad"},
    {"afip":"8784","location":"Libres Del Sud"},
    {"afip":"8785","location":"Licenciado Matienzo"},
    {"afip":"8789","location":"Lierra Adjemiro"},
    {"afip":"8793","location":"Lima"},
    {"afip":"8800","location":"Lin Calel"},
    {"afip":"8803","location":"Lincoln"},
    {"afip":"8822","location":"Lisandro Olmos Etcheverry"},
    {"afip":"8833","location":"Llavallol"},
    {"afip":"8840","location":"Loberia"},
    {"afip":"8842","location":"Lobos"},
    {"afip":"8863","location":"Loma De Salomon"},
    {"afip":"8867","location":"Loma Del Indio"},
    {"afip":"8872","location":"Loma Hermosa"},
    {"afip":"8876","location":"Loma Negra"},
    {"afip":"8878","location":"Loma Partida"},
    {"afip":"8884","location":"Loma Verde"},
    {"afip":"8902","location":"Lomas De Zamora"},
    {"afip":"8904","location":"Lomas Del Mirador"},
    {"afip":"8929","location":"Longchamps"},
    {"afip":"8932","location":"Lopez"},
    {"afip":"8934","location":"Lopez Lecube"},
    {"afip":"8935","location":"Lopez Molinari"},
    {"afip":"8942","location":"Los Acantilados"},
    {"afip":"8963","location":"Los Altos"},
    {"afip":"8969","location":"Los Angeles"},
    {"afip":"8977","location":"Los Arcos"},
    {"afip":"8980","location":"Los Aromos"},
    {"afip":"9005","location":"Los Bosques"},
    {"afip":"9015","location":"Los Caldenes"},
    {"afip":"9016","location":"Los Callejones"},
    {"afip":"9020","location":"Los Cardales"},
    {"afip":"9022","location":"Los Cardos"},
    {"afip":"9036","location":"Los Cerrillos"},
    {"afip":"9038","location":"Los Cerros"},
    {"afip":"9050","location":"Los Chañares"},
    {"afip":"9069","location":"Los Coloniales"},
    {"afip":"9094","location":"Los Cuatro Caminos"},
    {"afip":"9117","location":"Los Eucaliptos"},
    {"afip":"9118","location":"Los Eucaliptus Casco Urbano"},
    {"afip":"9129","location":"Los Gauchos"},
    {"afip":"9161","location":"Los Huesos"},
    {"afip":"9162","location":"Los Indios"},
    {"afip":"9172","location":"Los Laureles"},
    {"afip":"9174","location":"Los Leones"},
    {"afip":"9198","location":"Los Merinos"},
    {"afip":"9209","location":"Los Molles"},
    {"afip":"9235","location":"Los Ortiz"},
    {"afip":"9251","location":"Los Patos"},
    {"afip":"9265","location":"Los Pinos"},
    {"afip":"9275","location":"Los Polvorines"},
    {"afip":"9281","location":"Los Pozos"},
    {"afip":"9325","location":"Los Santos Viejos"},
    {"afip":"9346","location":"Los Talas"},
    {"afip":"9365","location":"Los Toldos"},
    {"afip":"9401","location":"Louge"},
    {"afip":"9405","location":"Lozano"},
    {"afip":"9412","location":"Lucas Monteverde"},
    {"afip":"9429","location":"Luis Chico"},
    {"afip":"9431","location":"Luis Guillon"},
    {"afip":"9438","location":"Lujan"},
    {"afip":"9442","location":"Lumb"},
    {"afip":"9451","location":"Luro"},
    {"afip":"9466","location":"Macedo"},
    {"afip":"9493","location":"Magallanes"},
    {"afip":"9494","location":"Magdala"},
    {"afip":"9495","location":"Magdalena"},
    {"afip":"9497","location":"Maguirre"},
    {"afip":"9505","location":"Maipu"},
    {"afip":"9518","location":"Malabia"},
    {"afip":"9529","location":"Malecon Gardelia"},
    {"afip":"9555","location":"Malvinas Argentinas"},
    {"afip":"9559","location":"Mamaguita"},
    {"afip":"9570","location":"Manantiales"},
    {"afip":"9571","location":"Manantiales Grandes"},
    {"afip":"9596","location":"Manuel Alberti"},
    {"afip":"9597","location":"Manuel B Gonnet"},
    {"afip":"9602","location":"Manuel Jose Garcia"},
    {"afip":"9603","location":"Manuel Ocampo"},
    {"afip":"9605","location":"Manzanares"},
    {"afip":"9607","location":"Manzo Y Niño"},
    {"afip":"9608","location":"Manzone"},
    {"afip":"9609","location":"Maori"},
    {"afip":"9610","location":"Mapis"},
    {"afip":"9613","location":"Maquinista F Savio"},
    {"afip":"9620","location":"Mar Azul"},
    {"afip":"9621","location":"Mar Chiquita"},
    {"afip":"9622","location":"Mar De Ajo"},
    {"afip":"9623","location":"Mar De Cobo"},
    {"afip":"9624","location":"Mar De Las Pampas"},
    {"afip":"9625","location":"Mar Del Plata"},
    {"afip":"9626","location":"Mar Del Sud"},
    {"afip":"9627","location":"Mar Del Tuyu"},
    {"afip":"9636","location":"Marcelino Ugarte"},
    {"afip":"9638","location":"Marcos Paz"},
    {"afip":"9639","location":"Marcos Paz B Bernasconi"},
    {"afip":"9640","location":"Marcos Paz B El Martillo"},
    {"afip":"9641","location":"Marcos Paz B El Moro"},
    {"afip":"9642","location":"Marcos Paz B El Zorzal"},
    {"afip":"9643","location":"Marcos Paz B La Lonja"},
    {"afip":"9644","location":"Marcos Paz B La Milagrosa"},
    {"afip":"9645","location":"Marcos Paz B Martin Fierro"},
    {"afip":"9646","location":"Marcos Paz B Urioste"},
    {"afip":"9649","location":"Mari Lauquen"},
    {"afip":"9666","location":"Maria Ignacia"},
    {"afip":"9670","location":"Maria Lucila"},
    {"afip":"9674","location":"Maria P Moreno"},
    {"afip":"9679","location":"Mariano Acosta"},
    {"afip":"9680","location":"Mariano Benitez Pergamino"},
    {"afip":"9682","location":"Mariano H Alfonzo"},
    {"afip":"9686","location":"Mariano Roldan"},
    {"afip":"9688","location":"Mariano Unzue"},
    {"afip":"9690","location":"Mariscal Sucre"},
    {"afip":"9697","location":"Martin Berraondo"},
    {"afip":"9698","location":"Martin Coronado"},
    {"afip":"9700","location":"Martin Fierro"},
    {"afip":"9704","location":"Martinez"},
    {"afip":"9710","location":"Marucha"},
    {"afip":"9719","location":"Masurel"},
    {"afip":"9732","location":"Matheu"},
    {"afip":"9746","location":"Mauras"},
    {"afip":"9748","location":"Mauricio Hirsch"},
    {"afip":"9751","location":"Maximo Fernandez"},
    {"afip":"9752","location":"Maximo Paz"},
    {"afip":"9760","location":"Mayor Buratovich"},
    {"afip":"9762","location":"Mayor Jose Orellano"},
    {"afip":"9766","location":"Maza"},
    {"afip":"9774","location":"Mecha"},
    {"afip":"9777","location":"Mechita"},
    {"afip":"9778","location":"Mechongue"},
    {"afip":"9781","location":"Medaland"},
    {"afip":"9786","location":"Medano Blanco"},
    {"afip":"9791","location":"Medanos"},
    {"afip":"9804","location":"Melchor Romero"},
    {"afip":"9812","location":"Membrillar"},
    {"afip":"9818","location":"Mercado Central"},
    {"afip":"9819","location":"Mercado De Victoria"},
    {"afip":"9821","location":"Mercedes"},
    {"afip":"9825","location":"Meridiano Vo"},
    {"afip":"9826","location":"Merlo"},
    {"afip":"9847","location":"Micaela Cascallares"},
    {"afip":"9930","location":"Ministro Rivadavia"},
    {"afip":"9935","location":"Mira Pampa"},
    {"afip":"9940","location":"Miramar"},
    {"afip":"9941","location":"Miramonte"},
    {"afip":"9942","location":"Miranda"},
    {"afip":"9978","location":"Moctezuma"},
    {"afip":"10000","location":"Molino Galileo"},
    {"afip":"10002","location":"Moll"},
    {"afip":"10021","location":"Monasterio"},
    {"afip":"10024","location":"Mones Cazon"},
    {"afip":"10027","location":"Monroe"},
    {"afip":"10028","location":"Monsalvo"},
    {"afip":"10043","location":"Monte Chingolo"},
    {"afip":"10049","location":"Monte Crespo"},
    {"afip":"10055","location":"Monte Fiore"},
    {"afip":"10058","location":"Monte Grande"},
    {"afip":"10060","location":"Monte Hermoso"},
    {"afip":"10075","location":"Monte Veloz"},
    {"afip":"10080","location":"Montecarlo"},
    {"afip":"10084","location":"Montes De Oca"},
    {"afip":"10092","location":"Moores"},
    {"afip":"10102","location":"Morea"},
    {"afip":"10105","location":"Moreno"},
    {"afip":"10109","location":"Moron"},
    {"afip":"10117","location":"Morse"},
    {"afip":"10125","location":"Mouras"},
    {"afip":"10136","location":"Mulcahy"},
    {"afip":"10141","location":"Munro"},
    {"afip":"10147","location":"Mutti"},
    {"afip":"10150","location":"Muñiz"},
    {"afip":"10151","location":"Muñoz"},
    {"afip":"10168","location":"Nahuel Ruca"},
    {"afip":"10175","location":"Napaleofu"},
    {"afip":"10178","location":"Naposta"},
    {"afip":"10196","location":"Navarro"},
    {"afip":"10201","location":"Necochea"},
    {"afip":"10202","location":"Necol Estacion Fcgm"},
    {"afip":"10216","location":"Newton"},
    {"afip":"10218","location":"Nicanor Olivera"},
    {"afip":"10221","location":"Nicolas Descalzi"},
    {"afip":"10223","location":"Nicolas Levalle"},
    {"afip":"10228","location":"Nieves"},
    {"afip":"10259","location":"Norberto De La Riestra"},
    {"afip":"10262","location":"Norumbega"},
    {"afip":"10272","location":"Nueva Atlantis"},
    {"afip":"10283","location":"Nueva España"},
    {"afip":"10289","location":"Nueva Hermosura"},
    {"afip":"10294","location":"Nueva Plata"},
    {"afip":"10297","location":"Nueva Roma"},
    {"afip":"10299","location":"Nueva Suiza"},
    {"afip":"10319","location":"O Higgins"},
    {"afip":"10337","location":"Ochandio"},
    {"afip":"10344","location":"Odorqui"},
    {"afip":"10360","location":"Olascoaga"},
    {"afip":"10361","location":"Olavarria"},
    {"afip":"10363","location":"Oliden"},
    {"afip":"10366","location":"Olivera"},
    {"afip":"10368","location":"Oliviera Cesar"},
    {"afip":"10369","location":"Olivos"},
    {"afip":"10374","location":"Ombu"},
    {"afip":"10379","location":"Ombucta"},
    {"afip":"10385","location":"Once De Setiembre"},
    {"afip":"10389","location":"Open Door"},
    {"afip":"10397","location":"Orense"},
    {"afip":"10398","location":"Oriente"},
    {"afip":"10399","location":"Orlando"},
    {"afip":"10407","location":"Ortiz Basualdo"},
    {"afip":"10408","location":"Ortiz De Rosas"},
    {"afip":"10411","location":"Ostende"},
    {"afip":"10413","location":"Otamendi"},
    {"afip":"10416","location":"Otoño"},
    {"afip":"10437","location":"Pablo Acosta"},
    {"afip":"10438","location":"Pablo Nogues"},
    {"afip":"10439","location":"Pablo Podesta"},
    {"afip":"10445","location":"Pachan"},
    {"afip":"10483","location":"Palantelen"},
    {"afip":"10487","location":"Palemon Huergo"},
    {"afip":"10517","location":"Palmitas"},
    {"afip":"10519","location":"Palo Blanco"},
    {"afip":"10655","location":"Paname"},
    {"afip":"10657","location":"Pancho Diaz"},
    {"afip":"10667","location":"Papin"},
    {"afip":"10674","location":"Parada Los Robles"},
    {"afip":"10676","location":"Parada Tatay"},
    {"afip":"10679","location":"Paraguil"},
    {"afip":"10703","location":"Paraje La Aurora"},
    {"afip":"10705","location":"Paraje La Vasca"},
    {"afip":"10727","location":"Paraje Santa Rosa"},
    {"afip":"10728","location":"Paraje Starache"},
    {"afip":"10744","location":"Pardo"},
    {"afip":"10749","location":"Parish"},
    {"afip":"10751","location":"Parque Carilo"},
    {"afip":"10753","location":"Parque Muñoz"},
    {"afip":"10757","location":"Parque San Martin"},
    {"afip":"10759","location":"Parque Taillade"},
    {"afip":"10760","location":"Parravichini"},
    {"afip":"10772","location":"Pasman"},
    {"afip":"10776","location":"Paso Alsina"},
    {"afip":"10792","location":"Paso Cramer"},
    {"afip":"10843","location":"Paso Del Medano"},
    {"afip":"10847","location":"Paso Del Rey"},
    {"afip":"10879","location":"Paso Mayor"},
    {"afip":"10912","location":"Pasos"},
    {"afip":"10915","location":"Pasteur"},
    {"afip":"10935","location":"Patricios"},
    {"afip":"10937","location":"Paula"},
    {"afip":"10945","location":"Pavon"},
    {"afip":"10952","location":"Payro R"},
    {"afip":"10954","location":"Pazos Kanki"},
    {"afip":"10956","location":"Pearson"},
    {"afip":"10959","location":"Pedernales"},
    {"afip":"10967","location":"Pedro Gamen"},
    {"afip":"10969","location":"Pedro Lasalle"},
    {"afip":"10971","location":"Pedro Luro"},
    {"afip":"10972","location":"Pedro Nicolas Escribano"},
    {"afip":"10978","location":"Pehuajo"},
    {"afip":"10981","location":"Pehuelches"},
    {"afip":"10982","location":"Pehuen Co"},
    {"afip":"10983","location":"Pelicura"},
    {"afip":"10984","location":"Pellegrini"},
    {"afip":"10996","location":"Pereyra"},
    {"afip":"10997","location":"Pereyra Iraola Parque"},
    {"afip":"11003","location":"Perez Millan"},
    {"afip":"11004","location":"Pergamino"},
    {"afip":"11019","location":"Pessagno"},
    {"afip":"11027","location":"Peñaflor"},
    {"afip":"11071","location":"Pichincha"},
    {"afip":"11084","location":"Piedra Ancha"},
    {"afip":"11118","location":"Piedritas"},
    {"afip":"11120","location":"Pieres"},
    {"afip":"11121","location":"Pierini"},
    {"afip":"11123","location":"Pigue"},
    {"afip":"11124","location":"Pila"},
    {"afip":"11128","location":"Pilar"},
    {"afip":"11136","location":"Pillahuinco"},
    {"afip":"11143","location":"Pinamar"},
    {"afip":"11160","location":"Pinzon"},
    {"afip":"11161","location":"Pipinas"},
    {"afip":"11177","location":"Pirovano"},
    {"afip":"11181","location":"Piruco"},
    {"afip":"11195","location":"Piñeiro"},
    {"afip":"11196","location":"Piñeyro"},
    {"afip":"11197","location":"Pla"},
    {"afip":"11198","location":"Pla Y Ragnoni"},
    {"afip":"11208","location":"Platanos"},
    {"afip":"11211","location":"Playa Chapadmalal"},
    {"afip":"11214","location":"Playa Las Margaritas"},
    {"afip":"11215","location":"Playa Serena"},
    {"afip":"11224","location":"Plaza Montero"},
    {"afip":"11229","location":"Plomer"},
    {"afip":"11233","location":"Plumacho"},
    {"afip":"11265","location":"Poblet"},
    {"afip":"11267","location":"Pocito"},
    {"afip":"11281","location":"Polvaredas"},
    {"afip":"11288","location":"Pontaut"},
    {"afip":"11289","location":"Pontevedra"},
    {"afip":"11339","location":"Porvenir"},
    {"afip":"11388","location":"Pourtale"},
    {"afip":"11520","location":"Pradere Juan A"},
    {"afip":"11528","location":"Presidente Derqui"},
    {"afip":"11529","location":"Presidente Quintana"},
    {"afip":"11539","location":"Primera Junta"},
    {"afip":"11599","location":"Puan"},
    {"afip":"11606","location":"Pueblitos"},
    {"afip":"11614","location":"Pueblo Balneario Reta"},
    {"afip":"11640","location":"Pueblo Martinez De Hoz"},
    {"afip":"11644","location":"Pueblo Nuevo"},
    {"afip":"11645","location":"Pueblo Nuevo Dto Junin"},
    {"afip":"11647","location":"Pueblo Otero"},
    {"afip":"11657","location":"Pueblo San Esteban"},
    {"afip":"11658","location":"Pueblo San Jose"},
    {"afip":"11659","location":"Pueblo Santa Maria"},
    {"afip":"11669","location":"Puente Batalla"},
    {"afip":"11673","location":"Puente Castex"},
    {"afip":"11674","location":"Puente Cañete"},
    {"afip":"11687","location":"Puente El Ochenta"},
    {"afip":"11756","location":"Puerto Bahia Blanca"},
    {"afip":"11760","location":"Puerto Belgrano"},
    {"afip":"11774","location":"Puerto Coloma"},
    {"afip":"11780","location":"Puerto De Escobar"},
    {"afip":"11793","location":"Puerto Galvan"},
    {"afip":"11810","location":"Puerto La Plata"},
    {"afip":"11831","location":"Puerto Necochea"},
    {"afip":"11854","location":"Puerto Rosales"},
    {"afip":"11875","location":"Puerto Tres Bonetes"},
    {"afip":"11889","location":"Puerto Wassermann"},
    {"afip":"11972","location":"Puesto Del Medio"},
    {"afip":"12099","location":"Pujol"},
    {"afip":"12117","location":"Punta Alta"},
    {"afip":"12125","location":"Punta De Canal"},
    {"afip":"12148","location":"Punta Indio"},
    {"afip":"12149","location":"Punta Lara"},
    {"afip":"12152","location":"Punta Medanos"},
    {"afip":"12216","location":"Quenuma"},
    {"afip":"12217","location":"Quequen"},
    {"afip":"12232","location":"Quilco"},
    {"afip":"12237","location":"Quilmes"},
    {"afip":"12238","location":"Quilmes Oeste"},
    {"afip":"12263","location":"Quirno Costa"},
    {"afip":"12264","location":"Quiroga"},
    {"afip":"12279","location":"Quiñihual Estacion"},
    {"afip":"12291","location":"Rafael Calzada"},
    {"afip":"12292","location":"Rafael Castillo"},
    {"afip":"12294","location":"Rafael Obligado"},
    {"afip":"12309","location":"Ramallo"},
    {"afip":"12321","location":"Ramon Biaus"},
    {"afip":"12325","location":"Ramon J Neild"},
    {"afip":"12327","location":"Ramon Santamarina"},
    {"afip":"12331","location":"Ramos Mejia"},
    {"afip":"12332","location":"Ramos Otero"},
    {"afip":"12333","location":"Rancagua"},
    {"afip":"12338","location":"Ranchos"},
    {"afip":"12343","location":"Ranelagh"},
    {"afip":"12359","location":"Rauch"},
    {"afip":"12360","location":"Raulet"},
    {"afip":"12361","location":"Rawson"},
    {"afip":"12365","location":"Real Audiencia"},
    {"afip":"12383","location":"Recalde"},
    {"afip":"12406","location":"Reginaldo J Neild"},
    {"afip":"12415","location":"Remedios De Escalada"},
    {"afip":"12429","location":"Requena"},
    {"afip":"12435","location":"Reta"},
    {"afip":"12443","location":"Retiro San Pablo"},
    {"afip":"12458","location":"Ricardo Cano"},
    {"afip":"12459","location":"Ricardo Gaviña"},
    {"afip":"12462","location":"Ricardo Rojas"},
    {"afip":"12477","location":"Rincon De Baudrix"},
    {"afip":"12500","location":"Rincon De Milberg"},
    {"afip":"12502","location":"Rincon De Noario"},
    {"afip":"12513","location":"Rincon De Vivot"},
    {"afip":"12538","location":"Rincon Norte"},
    {"afip":"12601","location":"Rio Lujan"},
    {"afip":"12624","location":"Rio Tala"},
    {"afip":"12638","location":"Rivadavia"},
    {"afip":"12639","location":"Rivadeo"},
    {"afip":"12641","location":"Rivera"},
    {"afip":"12643","location":"Roberto Payro"},
    {"afip":"12644","location":"Roberts"},
    {"afip":"12649","location":"Rocha"},
    {"afip":"12677","location":"Rojas"},
    {"afip":"12679","location":"Rolito Estacion Fcgb"},
    {"afip":"12683","location":"Roman Baez"},
    {"afip":"12693","location":"Roosevelt Partido Rivadavia"},
    {"afip":"12694","location":"Roque Perez"},
    {"afip":"12709","location":"Rosas"},
    {"afip":"12715","location":"Rovira"},
    {"afip":"12733","location":"Ruiz Solis"},
    {"afip":"12752","location":"Ruta 26 Maquinista F Savio"},
    {"afip":"12787","location":"Saavedra"},
    {"afip":"12796","location":"Saforcada"},
    {"afip":"12806","location":"Salada Chica"},
    {"afip":"12807","location":"Salada Grande"},
    {"afip":"12814","location":"Saladillo"},
    {"afip":"12818","location":"Saladillo Norte"},
    {"afip":"12829","location":"Salazar"},
    {"afip":"12835","location":"Saldungaray"},
    {"afip":"12838","location":"Salina De Piedra"},
    {"afip":"12840","location":"Salinas Chicas"},
    {"afip":"12853","location":"Salliquelo"},
    {"afip":"12858","location":"Salto"},
    {"afip":"12866","location":"Salvador Maria"},
    {"afip":"12870","location":"Samborombon"},
    {"afip":"12873","location":"San Adolfo"},
    {"afip":"12874","location":"San Agustin"},
    {"afip":"12875","location":"San Alberto"},
    {"afip":"12880","location":"San Andres"},
    {"afip":"12881","location":"San Andres De Giles"},
    {"afip":"12882","location":"San Andres De Tapalque"},
    {"afip":"12884","location":"San Antonio"},
    {"afip":"12886","location":"San Antonio De Areco"},
    {"afip":"12898","location":"San Antonio De Padua"},
    {"afip":"12915","location":"San Benito"},
    {"afip":"12916","location":"San Bernardo"},
    {"afip":"12918","location":"San Bernardo Del Tuyu"},
    {"afip":"12921","location":"San Cala"},
    {"afip":"12924","location":"San Carlos"},
    {"afip":"12929","location":"San Cayetano"},
    {"afip":"12933","location":"San Clemente Del Tuyu"},
    {"afip":"12934","location":"San Cornelio"},
    {"afip":"12937","location":"San Daniel"},
    {"afip":"12943","location":"San Eduardo Del Mar"},
    {"afip":"12944","location":"San Eladio"},
    {"afip":"12945","location":"San Emilio"},
    {"afip":"12946","location":"San Enrique"},
    {"afip":"12947","location":"San Ernesto"},
    {"afip":"12955","location":"San Federico"},
    {"afip":"12956","location":"San Felipe"},
    {"afip":"12958","location":"San Fermin"},
    {"afip":"12959","location":"San Fernando"},
    {"afip":"12965","location":"San Francisco De Bellocq"},
    {"afip":"12973","location":"San Francisco Solano"},
    {"afip":"12978","location":"San German"},
    {"afip":"12980","location":"San Gervacio"},
    {"afip":"12988","location":"San Ignacio"},
    {"afip":"12991","location":"San Isidro"},
    {"afip":"12993","location":"San Jacinto"},
    {"afip":"13004","location":"San Jorge"},
    {"afip":"13005","location":"San Jose"},
    {"afip":"13015","location":"San Jose De Gali"},
    {"afip":"13025","location":"San Jose De Los Quinteros"},
    {"afip":"13030","location":"San Jose De Otamendi"},
    {"afip":"13044","location":"San Juan"},
    {"afip":"13048","location":"San Juan De Nelson"},
    {"afip":"13058","location":"San Julian"},
    {"afip":"13060","location":"San Justo"},
    {"afip":"13061","location":"San Laureano"},
    {"afip":"13073","location":"San Manuel"},
    {"afip":"13084","location":"San Martin De Tours"},
    {"afip":"13089","location":"San Mauricio"},
    {"afip":"13090","location":"San Mayol"},
    {"afip":"13092","location":"San Miguel"},
    {"afip":"13093","location":"San Miguel Arcangel"},
    {"afip":"13097","location":"San Miguel Del Monte"},
    {"afip":"13098","location":"San Miguel Del Moro"},
    {"afip":"13105","location":"San Nicolas De Los Arroyos"},
    {"afip":"13110","location":"San Pascual"},
    {"afip":"13112","location":"San Patricio"},
    {"afip":"13115","location":"San Pedro"},
    {"afip":"13130","location":"San Quilco"},
    {"afip":"13131","location":"San Rafael"},
    {"afip":"13134","location":"San Ramon"},
    {"afip":"13136","location":"San Ramon De Anchorena"},
    {"afip":"13142","location":"San Roman"},
    {"afip":"13144","location":"San Roque"},
    {"afip":"13150","location":"San Sebastian"},
    {"afip":"13151","location":"San Severo"},
    {"afip":"13152","location":"San Simon"},
    {"afip":"13156","location":"San Valentin"},
    {"afip":"13157","location":"San Vicente"},
    {"afip":"13174","location":"Sansinena"},
    {"afip":"13178","location":"Santa Alicia"},
    {"afip":"13187","location":"Santa Catalina"},
    {"afip":"13189","location":"Santa Cecilia Centro"},
    {"afip":"13190","location":"Santa Cecilia Norte"},
    {"afip":"13191","location":"Santa Cecilia Sud"},
    {"afip":"13195","location":"Santa Clara Del Mar"},
    {"afip":"13197","location":"Santa Clementina"},
    {"afip":"13198","location":"Santa Coloma"},
    {"afip":"13202","location":"Santa Elena"},
    {"afip":"13203","location":"Santa Eleodora"},
    {"afip":"13209","location":"Santa Felicia"},
    {"afip":"13217","location":"Santa Ines"},
    {"afip":"13218","location":"Santa Irene"},
    {"afip":"13219","location":"Santa Isabel"},
    {"afip":"13224","location":"Santa Lucia"},
    {"afip":"13227","location":"Santa Luisa"},
    {"afip":"13230","location":"Santa Maria"},
    {"afip":"13231","location":"Santa Maria Belloq"},
    {"afip":"13247","location":"Santa Regina"},
    {"afip":"13248","location":"Santa Rita"},
    {"afip":"13250","location":"Santa Rita Pdo Guamini"},
    {"afip":"13251","location":"Santa Rosa"},
    {"afip":"13261","location":"Santa Rosa De Minellono"},
    {"afip":"13277","location":"Santa Teresa"},
    {"afip":"13279","location":"Santa Teresita"},
    {"afip":"13280","location":"Santa Teresita Pergamino"},
    {"afip":"13281","location":"Santa Trinidad"},
    {"afip":"13289","location":"Santiago Garbarini"},
    {"afip":"13290","location":"Santiago Larre"},
    {"afip":"13296","location":"Santo Domingo"},
    {"afip":"13304","location":"Santo Tomas"},
    {"afip":"13305","location":"Santo Tomas Chico"},
    {"afip":"13307","location":"Santos Lugares"},
    {"afip":"13308","location":"Santos Unzue"},
    {"afip":"13316","location":"Sarandi"},
    {"afip":"13318","location":"Sarasa"},
    {"afip":"13331","location":"Saturno"},
    {"afip":"13337","location":"Sauce Chico"},
    {"afip":"13339","location":"Sauce Corto"},
    {"afip":"13345","location":"Sauce Grande"},
    {"afip":"13371","location":"Sauzales"},
    {"afip":"13398","location":"Segurola"},
    {"afip":"13404","location":"Seminario Pio Xii"},
    {"afip":"13423","location":"Sevigne"},
    {"afip":"13430","location":"Shaw"},
    {"afip":"13435","location":"Siempre Verde"},
    {"afip":"13442","location":"Sierra Chica"},
    {"afip":"13449","location":"Sierra De La Ventana"},
    {"afip":"13452","location":"Sierra De Los Padres"},
    {"afip":"13466","location":"Sierras Bayas"},
    {"afip":"13498","location":"Smith"},
    {"afip":"13508","location":"Sol De Mayo"},
    {"afip":"13510","location":"Solale"},
    {"afip":"13512","location":"Solanet"},
    {"afip":"13513","location":"Solano"},
    {"afip":"13532","location":"Solis"},
    {"afip":"13548","location":"Sourigues"},
    {"afip":"13552","location":"Speratti"},
    {"afip":"13553","location":"Speroni"},
    {"afip":"13555","location":"Spurr"},
    {"afip":"13557","location":"Stegmann"},
    {"afip":"13562","location":"Stroeder"},
    {"afip":"13568","location":"Sucre"},
    {"afip":"13575","location":"Suipacha"},
    {"afip":"13591","location":"Sundblad"},
    {"afip":"13618","location":"Tablada"},
    {"afip":"13668","location":"Tacuari"},
    {"afip":"13730","location":"Tamangueyu"},
    {"afip":"13737","location":"Tambo Nuevo"},
    {"afip":"13743","location":"Tandil"},
    {"afip":"13749","location":"Tapalque"},
    {"afip":"13757","location":"Tapiales"},
    {"afip":"13786","location":"Tatay"},
    {"afip":"13795","location":"Tedin Uriburu"},
    {"afip":"13800","location":"Tejo Galeta"},
    {"afip":"13806","location":"Temperley"},
    {"afip":"13810","location":"Teniente Coronel Miñana"},
    {"afip":"13812","location":"Teniente Origone"},
    {"afip":"13834","location":"Termas Los Gauchos"},
    {"afip":"13841","location":"Thames"},
    {"afip":"13851","location":"Tigre"},
    {"afip":"13873","location":"Timote"},
    {"afip":"13889","location":"Tio Domingo"},
    {"afip":"13918","location":"Todd"},
    {"afip":"13926","location":"Toldos Viejos"},
    {"afip":"13936","location":"Tomas Jofre"},
    {"afip":"13953","location":"Tordillo"},
    {"afip":"13954","location":"Tornquist"},
    {"afip":"13955","location":"Toro"},
    {"afip":"13978","location":"Torres"},
    {"afip":"13981","location":"Tortuguitas"},
    {"afip":"14029","location":"Trenque Lauquen"},
    {"afip":"14031","location":"Tres  Lagunas"},
    {"afip":"14034","location":"Tres Algarrobos"},
    {"afip":"14037","location":"Tres Arroyos"},
    {"afip":"14051","location":"Tres Cuervos"},
    {"afip":"14067","location":"Tres Leguas"},
    {"afip":"14068","location":"Tres Lomas"},
    {"afip":"14076","location":"Tres Picos"},
    {"afip":"14086","location":"Tres Sargentos"},
    {"afip":"14095","location":"Trigales"},
    {"afip":"14104","location":"Tristan Suarez"},
    {"afip":"14105","location":"Triunvirato"},
    {"afip":"14114","location":"Troncos Del Talar"},
    {"afip":"14116","location":"Tronge"},
    {"afip":"14118","location":"Tropezon"},
    {"afip":"14122","location":"Trujui"},
    {"afip":"14161","location":"Turdera"},
    {"afip":"14177","location":"Tuyuti"},
    {"afip":"14179","location":"Uballes"},
    {"afip":"14186","location":"Udaquiola"},
    {"afip":"14194","location":"Unidad Turistica Chapadmalal"},
    {"afip":"14203","location":"Urdampilleta"},
    {"afip":"14205","location":"Uribelarrea"},
    {"afip":"14210","location":"Urquiza"},
    {"afip":"14240","location":"Vagues"},
    {"afip":"14242","location":"Valdez"},
    {"afip":"14245","location":"Valentin Alsina"},
    {"afip":"14246","location":"Valentin Gomez"},
    {"afip":"14248","location":"Valenzuela Anton"},
    {"afip":"14249","location":"Valeria Del Mar"},
    {"afip":"14278","location":"Vallimanca"},
    {"afip":"14287","location":"Vasquez"},
    {"afip":"14288","location":"Vaña"},
    {"afip":"14290","location":"Vecino"},
    {"afip":"14291","location":"Vedia"},
    {"afip":"14304","location":"Vela"},
    {"afip":"14308","location":"Velloso"},
    {"afip":"14312","location":"Venancio"},
    {"afip":"14325","location":"Vergara"},
    {"afip":"14330","location":"Veronica"},
    {"afip":"14339","location":"Viboras"},
    {"afip":"14340","location":"Vicente Casares"},
    {"afip":"14342","location":"Vicente Lopez"},
    {"afip":"14345","location":"Vicente Pereda"},
    {"afip":"14350","location":"Victoria"},
    {"afip":"14352","location":"Victorino De La Plaza"},
    {"afip":"14361","location":"Vieytes"},
    {"afip":"14362","location":"Vigelencia"},
    {"afip":"14366","location":"Vilela"},
    {"afip":"14376","location":"Villa Adelina"},
    {"afip":"14377","location":"Villa Adriana"},
    {"afip":"14379","location":"Villa Agueda"},
    {"afip":"14386","location":"Villa Aldeanita"},
    {"afip":"14399","location":"Villa Angus"},
    {"afip":"14404","location":"Villa Arcadia"},
    {"afip":"14408","location":"Villa Astolfi"},
    {"afip":"14413","location":"Villa Ballester"},
    {"afip":"14420","location":"Villa Belgrano Dto Junin"},
    {"afip":"14429","location":"Villa Bosch"},
    {"afip":"14431","location":"Villa Branda"},
    {"afip":"14433","location":"Villa Brown"},
    {"afip":"14434","location":"Villa Buenos Aires"},
    {"afip":"14435","location":"Villa Buide"},
    {"afip":"14436","location":"Villa Burgos"},
    {"afip":"14439","location":"Villa Cacique"},
    {"afip":"14441","location":"Villa Capdepont"},
    {"afip":"14446","location":"Villa Carola"},
    {"afip":"14448","location":"Villa Carucha"},
    {"afip":"14451","location":"Villa Castelar Est Erize"},
    {"afip":"14455","location":"Villa Centenario"},
    {"afip":"14456","location":"Villa Cerrito"},
    {"afip":"14463","location":"Villa Clelia"},
    {"afip":"14475","location":"Villa Copacabana"},
    {"afip":"14487","location":"Villa Da Fonte"},
    {"afip":"14488","location":"Villa Daza"},
    {"afip":"14494","location":"Villa De Mayo"},
    {"afip":"14501","location":"Villa Del Mar"},
    {"afip":"14509","location":"Villa Delfina"},
    {"afip":"14510","location":"Villa Depietri"},
    {"afip":"14513","location":"Villa Diamantina"},
    {"afip":"14515","location":"Villa Diaz Velez"},
    {"afip":"14518","location":"Villa Domingo Pronsato"},
    {"afip":"14521","location":"Villa Dominico"},
    {"afip":"14525","location":"Villa Dufau"},
    {"afip":"14535","location":"Villa Elena"},
    {"afip":"14537","location":"Villa Elisa"},
    {"afip":"14543","location":"Villa España"},
    {"afip":"14544","location":"Villa Espil"},
    {"afip":"14553","location":"Villa Flandria"},
    {"afip":"14557","location":"Villa Floresta"},
    {"afip":"14558","location":"Villa Florida"},
    {"afip":"14561","location":"Villa Fortabat"},
    {"afip":"14562","location":"Villa Fox"},
    {"afip":"14564","location":"Villa Francia"},
    {"afip":"14567","location":"Villa Galicia"},
    {"afip":"14571","location":"Villa General Arias"},
    {"afip":"14580","location":"Villa Gesell"},
    {"afip":"14586","location":"Villa Gobernador Udaondo"},
    {"afip":"14587","location":"Villa Godoy"},
    {"afip":"14591","location":"Villa Gral Savio Ex Sanchez"},
    {"afip":"14598","location":"Villa Harding Green"},
    {"afip":"14599","location":"Villa Herminia"},
    {"afip":"14606","location":"Villa Igollo"},
    {"afip":"14609","location":"Villa Insuperable"},
    {"afip":"14610","location":"Villa Iris"},
    {"afip":"14612","location":"Villa Italia"},
    {"afip":"14622","location":"Villa La Chechela"},
    {"afip":"14625","location":"Villa La Florida"},
    {"afip":"14640","location":"Villa Laura"},
    {"afip":"14641","location":"Villa Laza"},
    {"afip":"14643","location":"Villa Leandra"},
    {"afip":"14646","location":"Villa Leza"},
    {"afip":"14647","location":"Villa Lia"},
    {"afip":"14650","location":"Villa Libre"},
    {"afip":"14653","location":"Villa Loma"},
    {"afip":"14654","location":"Villa Loreto"},
    {"afip":"14665","location":"Villa Luzuriaga"},
    {"afip":"14667","location":"Villa Lynch"},
    {"afip":"14668","location":"Villa Lynch Pueyrredon"},
    {"afip":"14670","location":"Villa Maio"},
    {"afip":"14676","location":"Villa Margarita"},
    {"afip":"14677","location":"Villa Maria"},
    {"afip":"14680","location":"Villa Martelli"},
    {"afip":"14682","location":"Villa Massoni"},
    {"afip":"14686","location":"Villa Mayor"},
    {"afip":"14688","location":"Villa Maza"},
    {"afip":"14696","location":"Villa Mitre"},
    {"afip":"14699","location":"Villa Monica"},
    {"afip":"14701","location":"Villa Moquehua"},
    {"afip":"14703","location":"Villa Mosconi"},
    {"afip":"14711","location":"Villa Nocito"},
    {"afip":"14716","location":"Villa Numancia"},
    {"afip":"14717","location":"Villa Obrera"},
    {"afip":"14721","location":"Villa Olga Grumbein"},
    {"afip":"14723","location":"Villa Ortega"},
    {"afip":"14724","location":"Villa Ortiz"},
    {"afip":"14735","location":"Villa Penotti"},
    {"afip":"14741","location":"Villa Preceptor M Robles"},
    {"afip":"14742","location":"Villa Preceptor Manuel Cruz"},
    {"afip":"14744","location":"Villa Progreso"},
    {"afip":"14745","location":"Villa Pueblo Nuevo"},
    {"afip":"14746","location":"Villa Puerto Quequen"},
    {"afip":"14756","location":"Villa Raffo"},
    {"afip":"14757","location":"Villa Ramallo"},
    {"afip":"14758","location":"Villa Ramallo Estacion Ffcc"},
    {"afip":"14765","location":"Villa Rio Chico"},
    {"afip":"14772","location":"Villa Roch"},
    {"afip":"14776","location":"Villa Rosa"},
    {"afip":"14778","location":"Villa Rosas"},
    {"afip":"14783","location":"Villa Ruiz"},
    {"afip":"14786","location":"Villa Saboya"},
    {"afip":"14787","location":"Villa Saenz Peña"},
    {"afip":"14788","location":"Villa San Alberto"},
    {"afip":"14794","location":"Villa San Jose"},
    {"afip":"14801","location":"Villa San Pedro"},
    {"afip":"14805","location":"Villa Sanchez Elia"},
    {"afip":"14806","location":"Villa Sanguinetti"},
    {"afip":"14810","location":"Villa Santa Maria"},
    {"afip":"14816","location":"Villa Santos Tesei"},
    {"afip":"14817","location":"Villa Sanz"},
    {"afip":"14820","location":"Villa Sarita"},
    {"afip":"14824","location":"Villa Sauce"},
    {"afip":"14825","location":"Villa Sauri"},
    {"afip":"14829","location":"Villa Sena"},
    {"afip":"14830","location":"Villa Serra"},
    {"afip":"14834","location":"Villa Soldati"},
    {"afip":"14840","location":"Villa Talleres"},
    {"afip":"14843","location":"Villa Teresa"},
    {"afip":"14850","location":"Villa Triangulo"},
    {"afip":"14858","location":"Villa Vallier"},
    {"afip":"14859","location":"Villa Vatteone"},
    {"afip":"14862","location":"Villa Verde"},
    {"afip":"14865","location":"Villa Vignolo"},
    {"afip":"14870","location":"Villa York"},
    {"afip":"14875","location":"Villafañe"},
    {"afip":"14880","location":"Villaigrillo"},
    {"afip":"14881","location":"Villalonga"},
    {"afip":"14884","location":"Villanueva"},
    {"afip":"14886","location":"Villars"},
    {"afip":"14907","location":"Virrey Del Pino"},
    {"afip":"14909","location":"Virreyes"},
    {"afip":"14914","location":"Vista Alegre"},
    {"afip":"14921","location":"Vitel"},
    {"afip":"14927","location":"Vivorata"},
    {"afip":"14934","location":"Viña"},
    {"afip":"14944","location":"Volta"},
    {"afip":"14945","location":"Voluntad"},
    {"afip":"14949","location":"Vuelta De Obligado"},
    {"afip":"14950","location":"Vuelta De Zapata"},
    {"afip":"14957","location":"Warnes"},
    {"afip":"14966","location":"Wilde"},
    {"afip":"15044","location":"Yerbas"},
    {"afip":"15057","location":"Yrazoz"},
    {"afip":"15076","location":"Yutuyaco"},
    {"afip":"15081","location":"Zamudio"},
    {"afip":"15097","location":"Zapiola"},
    {"afip":"15100","location":"Zarate"},
    {"afip":"15103","location":"Zavalia"},
    {"afip":"15105","location":"Zeballos"},
    {"afip":"15106","location":"Zelaya"},
    {"afip":"15109","location":"Zenon Videla Dorna"},
    {"afip":"15110","location":"Zentena"},
    {"afip":"15112","location":"Zoilo Peralta"},
    {"afip":"15113","location":"Zona Delta San Fernando"},
    {"afip":"15124","location":"Zubiaurre"},
    {"afip":"19372","location":"Adrogue"},
    {"afip":"19378","location":"Villa Madero"},
    {"afip":"19380","location":"Anderson"},
    {"afip":"19381","location":"La Tribu"},
    {"afip":"19382","location":"San Enrique"},
    {"afip":"19383","location":"San Jose"},
    {"afip":"19384","location":"Altamira"},
    {"afip":"19385","location":"La Aurora"},
    {"afip":"19386","location":"San Jose"},
    {"afip":"19387","location":"Palantelen"},
    {"afip":"21571","location":"Villa Italia"},
    {"afip":"21572","location":"La Protegida"},
    {"afip":"21573","location":"San Bernardo"},
    {"afip":"21574","location":"Santa Rosa"},
    {"afip":"21575","location":"General Conesa"},
    {"afip":"21576","location":"Choique"},
    {"afip":"21577","location":"La Margarita"},
    {"afip":"21578","location":"La Porteña"},
    {"afip":"21579","location":"Martin Fierro"},
    {"afip":"21580","location":"San Ramon"},
    {"afip":"21581","location":"Santa Ines"},
    {"afip":"21582","location":"La Horqueta"},
    {"afip":"21583","location":"La Pastora"},
    {"afip":"21584","location":"Remedios De Escalada"},
    {"afip":"21585","location":"Algarrobo"},
    {"afip":"21586","location":"El Paraiso"},
    {"afip":"21587","location":"El Rincon"},
    {"afip":"21588","location":"Graciarena"},
    {"afip":"21589","location":"La Mascota"},
    {"afip":"21608","location":"San Jacinto"},
    {"afip":"21609","location":"San Juan"},
    {"afip":"21610","location":"Colonia San Francisco"},
    {"afip":"21611","location":"Las Cortaderas"},
    {"afip":"21612","location":"San Juan"},
    {"afip":"21613","location":"San Ramon"},
    {"afip":"21614","location":"La Florida"},
    {"afip":"21615","location":"La Mascota"},
    {"afip":"21616","location":"La Reforma"},
    {"afip":"21617","location":"La Victoria"},
    {"afip":"21618","location":"Las Chilcas"},
    {"afip":"21619","location":"San Antonio"},
    {"afip":"21620","location":"Colonia Santa Rosa"},
    {"afip":"21621","location":"San Andres"},
    {"afip":"21622","location":"San Emilio"},
    {"afip":"21623","location":"San Jose"},
    {"afip":"21624","location":"La Esperanza"},
    {"afip":"21625","location":"Santa Teresa"},
    {"afip":"21626","location":"Loma Negra"},
    {"afip":"21627","location":"San Jose"},
    {"afip":"21628","location":"El Jaguel"},
    {"afip":"21629","location":"Los Indios"},
    {"afip":"21630","location":"Sol De Mayo"},
    {"afip":"21631","location":"Villa Progreso"},
    {"afip":"21632","location":"La Reforma"},
    {"afip":"21633","location":"Alta Vista"},
    {"afip":"21634","location":"Espartillar"},
    {"afip":"21635","location":"La Margarita"},
    {"afip":"21636","location":"Espora"},
    {"afip":"21637","location":"La Florida"},
    {"afip":"21638","location":"Bella Vista"},
    {"afip":"21639","location":"Algarrobo"},
    {"afip":"21640","location":"Las Flores"},
    {"afip":"21641","location":"La Sara"},
    {"afip":"21642","location":"Francisco Casal"},
    {"afip":"21643","location":"San Justo"},
    {"afip":"21644","location":"La Perla"},
    {"afip":"21645","location":"Gerli"},
    {"afip":"21646","location":"Los Pinos"},
    {"afip":"21647","location":"La Porteña"},
    {"afip":"21648","location":"Santa Elena"},
    {"afip":"21649","location":"Villa Francia"},
    {"afip":"21650","location":"La Primavera"},
    {"afip":"21651","location":"La Colorada"},
    {"afip":"21652","location":"Santa Elena"},
    {"afip":"21653","location":"La Verde"},
    {"afip":"21654","location":"Sauce Grande"},
    {"afip":"21655","location":"Grisolia"},
    {"afip":"21656","location":"El Pito"},
    {"afip":"21657","location":"San Jose"},
    {"afip":"21658","location":"Colonia Sere"},
    {"afip":"21659","location":"La Estrella"},
    {"afip":"21660","location":"La Protegida"},
    {"afip":"21661","location":"Pueblo Nuevo"},
    {"afip":"21662","location":"La Costa"},
    {"afip":"21663","location":"La Horqueta"},
    {"afip":"21664","location":"La Limpia"},
    {"afip":"21665","location":"Piñeyro"},
    {"afip":"21666","location":"Arroyo Las Rosas"},
    {"afip":"21667","location":"Canning"},
    {"afip":"21668","location":"Santa Rosa"},
    {"afip":"21669","location":"La Reforma"},
    {"afip":"21670","location":"Miramar"},
    {"afip":"21671","location":"La Chumbeada"},
    {"afip":"21672","location":"La Esperanza"},
    {"afip":"21673","location":"Espartillar"},
    {"afip":"21674","location":"Loma Verde"},
    {"afip":"21675","location":"Barrio Tiro Federal"},
    {"afip":"21676","location":"La Florida"},
    {"afip":"21685","location":"Yraizos"},
    {"afip":"21686","location":"Girondo"},
    {"afip":"21687","location":"Arrecifes"},
    {"afip":"21688","location":"Coronel Brandsen"},
    {"afip":"21689","location":"General Juan Madariaga"},
    {"afip":"21701","location":"Ingeniero Budge"},
    {"afip":"21702","location":"Ingeniero Budge"},
    {"afip":"21703","location":"Loma Florida"},
    {"afip":"21704","location":"Loma Grande"},
    {"afip":"21705","location":"Chilavert"},
    {"afip":"21706","location":"Barrio General Pueyrredon"},
    {"afip":"21707","location":"Caraza"},
    {"afip":"21708","location":"A. A. Fernandez"},
    {"afip":"21709","location":"Barrio San Enrique"},
    {"afip":"21710","location":"Barrio San Pedro"},
    {"afip":"21711","location":"Mercado Central Buenos Aires"},
    {"afip":"21712","location":"Saenz Peña"},
    {"afip":"21713","location":"Bartolome Mitre(San Isidro)"},
    {"afip":"21714","location":"Barrio Santa Paula"},
    {"afip":"21715","location":"El Cruce"},
    {"afip":"21716","location":"Fatima"},
    {"afip":"21717","location":"Balneario Cascallares"},
    {"afip":"21718","location":"Barrio Baires"},
    {"afip":"21719","location":"Villa Nueva"},
    {"afip":"21721","location":"Arroyo De Los Huesos"},
    {"afip":"21722","location":"Bajo De Los Loros"},
    {"afip":"21723","location":"Balneario (Parada)"},
    {"afip":"21724","location":"Balneario Monte Hermoso(Coronel Dorr"},
    {"afip":"21725","location":"Base Aeronaval Comandante Espora"},
    {"afip":"21726","location":"Chapalco"},
    {"afip":"21727","location":"Jose A. Guisasola"},
    {"afip":"21728","location":"Monte Redondo"},
    {"afip":"21729","location":"Negro Muerto"},
    {"afip":"21730","location":"Planta De Agua Pesada"},
    {"afip":"21731","location":"Napaleufu"},
    {"afip":"21732","location":"Juan F. Ibarra"},
    {"afip":"21733","location":"Unzue"},
    {"afip":"21734","location":"Coronel Marcelino Freire"},
    {"afip":"21735","location":"Arboledas (Bolivar)"},
    {"afip":"21736","location":"General O'Brien"},
    {"afip":"21737","location":"Jose Santos Arevalo"},
    {"afip":"21738","location":"Santiago Larrea"},
    {"afip":"21739","location":"Tres Lagunas"},
    {"afip":"21740","location":"Esteban A. Gascon"},
    {"afip":"21741","location":"Miguel Arcangel"},
    {"afip":"21742","location":"Peralta"},
    {"afip":"21743","location":"Sanquilco"},
    {"afip":"21744","location":"Quiñihual"},
    {"afip":"21745","location":"Bathurst"},
    {"afip":"21746","location":"D'Orbigny"},
    {"afip":"21747","location":"Curamalal"},
    {"afip":"21748","location":"Isleño"},
    {"afip":"21749","location":"Palemon Huergo Este"},
    {"afip":"21750","location":"Ingeniero Silveya"},
    {"afip":"21751","location":"O'Higgins"},
    {"afip":"21752","location":"Cuartel Once"},
    {"afip":"21753","location":"General Paz"},
    {"afip":"21754","location":"Cuartel Nueve (Castelli)"},
    {"afip":"21755","location":"Manuel Jose Cobo"},
    {"afip":"21756","location":"Cuartel Ocho"},
    {"afip":"21757","location":"Cuartel Seis"},
    {"afip":"21758","location":"Cuartel Cinco"},
    {"afip":"21759","location":"Rivas"},
    {"afip":"21760","location":"Ayerza"},
    {"afip":"21761","location":"Coronel Mom Este"},
    {"afip":"21762","location":"Palemon Huergo Oeste"},
    {"afip":"21763","location":"Parravicini"},
    {"afip":"21764","location":"Ameghino"},
    {"afip":"21765","location":"Villa Sauze"},
    {"afip":"21766","location":"Emilio V. Bunge"},
    {"afip":"21767","location":"Sunblad"},
    {"afip":"21768","location":"Roosevelt"},
    {"afip":"21769","location":"Roberto Cano"},
    {"afip":"21770","location":"Ascension(General Arenales)"},
    {"afip":"21771","location":"Baigorrita Norte (General Viamonte)"},
    {"afip":"21774","location":"El Peligro"},
    {"afip":"21775","location":"Manuel B. Gonnet"},
    {"afip":"21776","location":"La Plata Noroeste Calle 50"},
    {"afip":"21777","location":"Tolosa"},
    {"afip":"21778","location":"Adolfo F. Orma Apeadero Fcgb"},
    {"afip":"21779","location":"Angel Etcheverry (Noroeste)"},
    {"afip":"21780","location":"Abasto (Noroeste)"},
    {"afip":"21781","location":"San Ponciano"},
    {"afip":"21782","location":"La Plata Sudeste Calle 50 Ambas Vere"},
    {"afip":"21783","location":"Ignacio Correas"},
    {"afip":"21784","location":"Bartolome Bavio"},
    {"afip":"21785","location":"Roberto J. Payro"},
    {"afip":"21786","location":"Coronel Boer"},
    {"afip":"21787","location":"Perkins"},
    {"afip":"21788","location":"Leandro N. Alem"},
    {"afip":"21789","location":"Ghunter"},
    {"afip":"21790","location":"Tortuguitas Norte"},
    {"afip":"21791","location":"Jauregui"},
    {"afip":"21792","location":"Barrio Parque Camet"},
    {"afip":"21793","location":"Mar Del Plata Norte"},
    {"afip":"21794","location":"Calfucura(General Pueyrredon)"},
    {"afip":"21795","location":"Jofre Tomas"},
    {"afip":"21796","location":"Garcia"},
    {"afip":"21797","location":"Golney"},
    {"afip":"21798","location":"La Florida"},
    {"afip":"21799","location":"Mar Del Plata Sur"},
    {"afip":"21800","location":"La Florida"},
    {"afip":"21801","location":"Yraizoz"},
    {"afip":"21802","location":"Juan Nepomuceno Fernandez"},
    {"afip":"21803","location":"Orense Norte"},
    {"afip":"21804","location":"Costa Bonita"},
    {"afip":"21805","location":"Baigorrita Sur"},
    {"afip":"21806","location":"El Provincial"},
    {"afip":"21807","location":"Estacion Provincial 9 De Julio"},
    {"afip":"21808","location":"Ramon J. Neild"},
    {"afip":"21809","location":"Ordoqui"},
    {"afip":"21810","location":"Arboledas"},
    {"afip":"21811","location":"Necol"},
    {"afip":"21812","location":"Girondo"},
    {"afip":"21813","location":"Larramendi"},
    {"afip":"21814","location":"Mariano Benitez"},
    {"afip":"21815","location":"Juana A. De La Peña"},
    {"afip":"21816","location":"Arroyo Del Medio"},
    {"afip":"21817","location":"Mariano H. Alfonzo"},
    {"afip":"21818","location":"El Arbolito"},
    {"afip":"21819","location":"Almacen La Colonia"},
    {"afip":"21820","location":"San Nicolas"},
    {"afip":"21821","location":"La Emilia"},
    {"afip":"21822","location":"Campos Salles"},
    {"afip":"21823","location":"General Rojo"},
    {"afip":"21824","location":"Conesa"},
    {"afip":"21825","location":"Sanchez(Ramallo)"},
    {"afip":"21826","location":"Villa General Savio"},
    {"afip":"21827","location":"Colonia Velaz"},
    {"afip":"21828","location":"La Buena Moza"},
    {"afip":"21829","location":"Ingeniero  Moneta"},
    {"afip":"21830","location":"Bocayuva"},
    {"afip":"21831","location":"Francisco De Victoria"},
    {"afip":"21832","location":"Orense Sur"},
    {"afip":"21833","location":"Claudio Molina"},
    {"afip":"21834","location":"Pedro P. Lasalle"},
    {"afip":"21835","location":"Barrow(Gonzalez Chaves)"},
    {"afip":"21836","location":"Vazquez"},
    {"afip":"21837","location":"Jose Maria Micheo"},
    {"afip":"21838","location":"Juan  Blaquier"},
    {"afip":"21839","location":"Escobar"},
    {"afip":"21840","location":"Gobernador Andonaegui"},
    {"afip":"21841","location":"Parada Robles"},
    {"afip":"21842","location":"Costa Del Este"},
    {"afip":"22030","location":"Boca Del Arroyo Estudiante"},
    {"afip":"22096","location":"Jose B. Casas"},
    {"afip":"22097","location":"Juan A. Pradere"},
    {"afip":"22252","location":"Coronel Mom Oeste"},
    {"afip":"22259","location":"Lisandro Olmos (Sudeste)"},
    {"afip":"22260","location":"Abasto (Sudeste)"},
    {"afip":"22263","location":"Colonia De Vacaciones Chapadmalal"},
    {"afip":"22265","location":"Barrio Faro Norte"},
    {"afip":"22274","location":"Malvinas Argentinas (Alte Brown)"},
    {"afip":"22276","location":"Transradio"},
    {"afip":"22279","location":"Nordelta (Tigre)"},
    {"afip":"22292","location":"Mar Del Plata Norte"},
    {"afip":"22294","location":"Mar Del Plata Sur"},
    {"afip":"22295","location":"16 De Julio (Benito Juarez)"},
    {"afip":"22296","location":"9 De Abril (La Matanza)"},
    {"afip":"22297","location":"Alastuey (Apeadero Fcgu)(Lujan)"},
    {"afip":"22298","location":"Algarrobo (Villarino)"},
    {"afip":"22299","location":"Arboledas (Daireaux)"},
    {"afip":"22300","location":"Arenales ( Junin)"},
    {"afip":"22301","location":"Arribeqos (Junin)"},
    {"afip":"22302","location":"Ascension (Junin)"},
    {"afip":"22303","location":"Baigorrita Norte (Junin)"},
    {"afip":"22304","location":"Baigorrita Norte (Bragado)"},
    {"afip":"22305","location":"Bajo Hondo (Coronel Rosales)"},
    {"afip":"22306","location":"Barker (Necochea)"},
    {"afip":"22307","location":"Barrow (Tres Arroyos)"},
    {"afip":"22308","location":"Bermudez (Lincoln)"},
    {"afip":"22309","location":"Bolivar( General Alvear)"},
    {"afip":"22310","location":"Calfucura (Mar Chiquita)"},
    {"afip":"22311","location":"Capilla Del Seqor (Exaltacion De La"},
    {"afip":"22312","location":"Carabelas (Rojas)"},
    {"afip":"22313","location":"Chenaut (San Antonio De Areco)"},
    {"afip":"22314","location":"Coraceros (Hipolito Irigoyen)"},
    {"afip":"22315","location":"Cristiano Muerto (San Cayetano)"},
    {"afip":"22316","location":"De La Canal (Tandil)"},
    {"afip":"22317","location":"Dos Naciones (Loberia)"},
    {"afip":"22318","location":"Esteban De Luca ( Trenquelauquen)"},
    {"afip":"22319","location":"General Piran (Mar Chiquita)"},
    {"afip":"22320","location":"General Viamonte (Junin)"},
    {"afip":"22321","location":"Gobernador Castro (San Pedro)"},
    {"afip":"22322","location":"Gorchs (Monte)"},
    {"afip":"22323","location":"Hale (Nueve De Julio )"},
    {"afip":"22324","location":"Ingeniero Thompson (Tres Lomas)"},
    {"afip":"22325","location":"La Luisa( Capitan Sarmiento)"},
    {"afip":"22326","location":"Las Nutrias (Loberia)"},
    {"afip":"22327","location":"Rio Tala (San Pedro)"},
    {"afip":"22328","location":"Santa Coloma (San Antonio De Areco)"},
    {"afip":"22330","location":"Don Torcuato Norte"},
    {"afip":"22331","location":"Las Armas (Circ.Xviii Sec.A-Manz.1"},
    {"afip":"22341","location":"Ancon (Pehuajo)"},
    {"afip":"22342","location":"Cachari( Tapalque)"},
    {"afip":"22343","location":"Carlos Salas( Pehuajo)"},
    {"afip":"22344","location":"Crotto (Tapalque)"},
    {"afip":"22345","location":"Enrique Lavalle (Pehuajo)"},
    {"afip":"22346","location":"Esteban De Luca (Pehuajo)"},
    {"afip":"22347","location":"General Lavalle (General Lavalle)"},
    {"afip":"22348","location":"Ingeniero Balbin( General Villegas)"},
    {"afip":"22349","location":"Loma Verde( General Paz)"},
    {"afip":"22350","location":"Monsalvo(Maipu)"},
    {"afip":"22351","location":"Sanchez (San Nicolas)"}
]