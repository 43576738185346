<svg class="svg-22" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clip-path="url(#clip0_937_169695)">
    <path d="M17.875 22H4.125C1.84665 22 0 20.1534 0 17.875V4.125C0 1.84665 1.84665 0 4.125 0H17.875C20.1534 0 22 1.84665 22 4.125V17.875C22 20.1534 20.1534 22 17.875 22Z" fill="#00C689" fill-opacity="0.1"/>
    <path d="M11 15.5249C8.37965 15.5249 5.87075 13.5971 4.21465 11.5957C3.92845 11.2498 3.92845 10.7476 4.21465 10.4018C4.63103 9.89856 5.50361 8.92772 6.66924 8.07925C9.60511 5.94228 12.3892 5.93816 15.3307 8.07925C16.7001 9.07601 17.7853 10.3831 17.7853 10.4018C18.0715 10.7476 18.0715 11.2498 17.7853 11.5956C16.1295 13.5968 13.6209 15.5249 11 15.5249ZM11 7.33467C8.09719 7.33467 5.58091 10.1029 4.87887 10.9514C4.85618 10.9788 4.85618 11.0186 4.87887 11.0461C5.58094 11.8945 8.09719 14.6628 11 14.6628C13.9028 14.6628 16.4191 11.8945 17.1211 11.0461C17.162 10.9966 17.1184 10.9514 17.1211 10.9514C16.419 10.1029 13.9028 7.33467 11 7.33467Z" fill="#00C689"/>
    <path d="M10.9999 14.0162C9.33604 14.0162 7.98242 12.6626 7.98242 10.9988C7.98242 9.33495 9.33604 7.98132 10.9999 7.98132C12.6637 7.98132 14.0173 9.33495 14.0173 10.9988C14.0173 12.6626 12.6637 14.0162 10.9999 14.0162ZM10.9999 8.84345C9.81143 8.84345 8.84455 9.81033 8.84455 10.9988C8.84455 12.1872 9.81143 13.1541 10.9999 13.1541C12.1883 13.1541 13.1552 12.1872 13.1552 10.9988C13.1552 9.81033 12.1883 8.84345 10.9999 8.84345Z" fill="#00C689"/>
    </g>
    <defs>
    <clipPath id="clip0_937_169695">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
</svg>