import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataChecker } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { Router } from '@angular/router';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { OrderService } from 'src/app/shared/services/order.service';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/shared/interfaces/order.interface';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss', './../../../shared/styles/empty-state.scss'],
})

export class OrderComponent implements OnInit {
  public user!: User;
  public orders: Order[] = [];
  public orderStatusSelected:number=orderStatusConst.PENDING;
  public orderStatus=orderStatusConst;

  private order$!: Subscription;
  private user$!: Subscription;

  constructor(private store: Store<appState>, private dataChecker: DataChecker, private router:Router, private orderSvc:OrderService) {
    this.loadOrders();
  }

  ngOnInit(): void {
    this.order$=this.store.select('order').subscribe(order => {
      this.orders = order.orderBoard.all;
    })
    this.user$=this.store.select('usuario').subscribe(user => {
      this.user = user
    })
  }

  ngOnDestroy(): void {
    this.order$.unsubscribe();
    this.user$.unsubscribe();
  }

  loadOrders() {
    this.store.dispatch(this.dataChecker.rememberOrderList());
  }

  goToOrderList(){
    const url= "/"+ NAVEGATION.ORDER_BOARD;
    this.router.navigate([url]);
  }

  setOrderStatusSelected(orderStatus:number){
    this.orderStatusSelected=orderStatus;
  }
}
