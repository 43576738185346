<svg
  class="svg-menu flex"
  [ngClass]="{ enabled: active, disabled: !active }"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2253_148841)">
    <path
      d="M14.6175 5.76558C14.3246 5.47271 14.3246 4.99782 14.6175 4.70494C14.9104 4.41207 15.3853 4.41207 15.6781 4.70494L19.2174 8.24424C19.5104 8.53711 19.5104 9.012 19.2174 9.30488C19.071 9.45131 18.879 9.52458 18.6871 9.52458C18.4952 9.52458 18.3033 9.45131 18.1568 9.30488L14.6175 5.76558ZM24 23.17C24 23.5843 23.6642 23.92 23.25 23.92L8.46741 23.9224C7.67049 23.9227 6.87113 23.6685 6.19735 23.2479C5.90067 23.0628 5.62617 22.8416 5.379 22.5944L1.32802 18.5434C-0.293483 16.9219 -0.452296 14.4425 0.973876 13.0163L12.9381 1.05202C14.3644 -0.374199 16.8439 -0.215293 18.4653 1.40616L22.5163 5.45714C24.1378 7.07864 24.2966 9.5581 22.8704 10.9843L11.4346 22.42H23.25C23.6642 22.42 24 22.7558 24 23.17ZM4.97878 11.1326L12.7898 18.9436L21.8097 9.92363C22.6511 9.08227 22.4922 7.55438 21.4556 6.51774L17.4046 2.46675C16.8158 1.878 16.0685 1.57238 15.3786 1.57238C14.8538 1.57238 14.3622 1.74914 13.9987 2.11261L4.97878 11.1326ZM6.43964 21.5338C7.47624 22.5704 9.00408 22.7292 9.84553 21.8879L11.7292 20.0042L3.91814 12.1933L2.03452 14.0769C1.19316 14.9183 1.35202 16.4461 2.38866 17.4827L6.43964 21.5338Z"
      fill="#2E3B4C"
    />
  </g>
  <defs>
    <clipPath id="clip0_2253_148841">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
