import { Component, Input } from '@angular/core';

@Component({
  selector: 'gravanz-svg',
  templateUrl: './gravanz.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class GravanzSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
