import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { StructBuyerProform, isValidOrderByBuyer } from 'src/app/shared/business-validation';
import { puertos } from 'src/app/shared/dict/dict';
import { zones } from 'src/app/shared/dict/place-of-delivery';
import { PLANTAS_INTERIOR_MAP } from 'src/app/shared/dict/plantas-interior';
import { getPlaceOfDeliveryIBM } from 'src/app/shared/functions-ibm/contract.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { isCodeCommoditiesOrHughesBuyer, resetAllOrders } from 'src/app/shared/order';
import { getPortIBMStr, getQualityStr } from 'src/app/shared/price';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

export const ACT_PROFORM={
  AUTOCORRECT:'autocorrect',
  IGNORE:'ignore'
}

interface Invalid {
  quality:boolean;
  place:boolean;
  wayPay:boolean;
}

@Component({
  selector: 'app-form-proform-buyer',
  templateUrl: './form-proform-buyer.component.html',
  styleUrls: ['./form-proform-buyer.component.scss']
})
export class FormProformBuyerComponent implements OnInit, OnDestroy {
  public IGNORE_CONST=ACT_PROFORM.IGNORE;
  @Input() order!:Order;
  @Input() orderOriginal!:Order | null;
  @Input() proform!:StructBuyerProform|null;
  @Input() buyer!:number;
  @Input() public actionProform:string='';

  @Output() action= new EventEmitter<{action: string, newOrder:Order|null}>();
  @Output() actualizate = new EventEmitter<{newOrder:Order|null}>();

  showInfoToComplete:boolean = false;

  isAllEnabled:boolean = false;

  qualityToSave:number=0;
  placeToSave:number=0;
  percentageWayPayToSave:number=0;

  arrayPlaces:any[]=[];
  PLACES_MAP:any={};
  
  quality$!:Subscription;
  wayPay$!:Subscription;
  constructor(private store:Store<appState>, private cdr:ChangeDetectorRef) {
    this.PLACES_MAP = PLANTAS_INTERIOR_MAP;

    this.quality$=this.store.select('order','generateOrder','price','quality').subscribe((quality)=>{
      if(quality!=this.qualityToSave){
        this.actualizateQuality(quality);
      }
    });

    this.wayPay$=this.store.select('order','generateOrder','price','wayPay').subscribe((wayPay)=>{
      if(wayPay.percentage!=this.percentageWayPayToSave){
        this.actualizateWayPayPercentage(wayPay.percentage);
      }
    });
  }

  ngOnInit(): void {
    this.setAllValues(this.order)
    this.isAllEnabled= isAllValidInProform(this.proform);
    
    this.showInfoToComplete = !this.isAllEnabled && (this.proform? true: false);
  }

  ngOnDestroy(): void {
    this.quality$.unsubscribe();
    this.wayPay$.unsubscribe();
    
    resetAllOrders(this.store);
  }

  setAllValues(order:Order){
    this.store.dispatch(setOrderToGenerate({order:order}));
    this.placeToSave=getPlaceOfDeliveryIBM(order) ;
  }

  getPercentageWayPayBuyer(){
    return this.proform?.wayPay.default;
  }

  getQualityBuyer(){
    return getQualityStr(this.proform?.quality.default ?? 0);
  }

  getPlaceOfDeliveryBuyer(){
    return this.getPlaceOfDeliveryStr(this.proform?.place.default ?? 0);
  }

  getPlaceOfDeliveryStr(code:number){
    return getPortIBMStr(code ??0);
  }

  setPlaceOfDelivery(value:any){
    this.actualizatePlaceOfDelivery(value.element);
  }

  getArrowDirection(){
    return getArrowDirectionByBool(this.showInfoToComplete);
  }

  changeShowInfo(){
    this.showInfoToComplete=!this.showInfoToComplete;
  }

  actualizateQuality(quality:number){
    this.qualityToSave=quality;
    if(this.order){
      const newOrder={...this.order, price:{...this.order.price, quality:quality}};
      this.actualizateDataProform(newOrder);
    }
  }

  actualizateWayPayPercentage(percentage:number){
    this.percentageWayPayToSave=percentage;
    if(this.order){
      const newOrder={...this.order, price:{...this.order.price, wayPay:{...this.order.price.wayPay, percentage:percentage}}};
      this.actualizateDataProform(newOrder);
    }
  }

  actualizatePlaceOfDelivery(value:number){
    this.placeToSave=value;
    if(this.order){
      const newOrder={...this.order, price:{...this.order.price, placeOfDelivery:{zone:11, port:this.getPort(value)}}};
      this.actualizateDataProform(newOrder);
    }
  }

  actualizateDataProform(newOrder:Order){
    this.proform=isValidOrderByBuyer(newOrder, this.buyer);  
    this.arrayPlaces=this.proform?.place.arrayValids ?? [];
    this.isAllEnabled= isAllValidInProform(this.proform);
    this.order=newOrder;
    this.actualizate.emit({newOrder:newOrder})
  }

  getPort(portIBM:number){
    return puertos[11].dropdown.indexOf(this.getPlaceOfDeliveryStr(portIBM)) + 1 
  }

  autocorrect(){
    //Obtenemos los datos vacíos de la orden
    const newOrder= this.getOrderBy(this.order, 
      this.proform?.quality.default ?? -1, 
      this.getPort(this.proform?.place?.default ?? 0),
      this.proform?.wayPay.default ?? -1);
      
    if(newOrder){
      //Seteamos la nueva proforma, orden, plazas, si están activos, etc.
      this.actualizateDataProform(newOrder);

      //Seteamos los valores de plaza, calidad y porcentaje de pago
      this.setAllValues(newOrder); 
    }
  }

  getOrderBy(order:Order, quality:number, place:number, percentage:number){
    return order? {
      ...order,
      price:{
        ...order.price,
        placeOfDelivery:{
          zone: 11, //Plantas interior
          port: place,
        },
        quality:quality,
        wayPay:{
          ...order.price.wayPay,
          percentage:percentage
        }
      }
    }: null
  }

  ignore(){
    this.action.emit({action:ACT_PROFORM.IGNORE, newOrder:this.orderOriginal});

    this.showInfoToComplete=false;
    if(this.orderOriginal){
      this.order=this.orderOriginal;
      //this.actualizateDataProform(this.orderOriginal);
    }
  }

  confirm(){
    this.action.emit({action:ACT_PROFORM.AUTOCORRECT, newOrder: this.order});
    this.showInfoToComplete=false;
  }

  isBuyerCommodities(){
    return isCodeCommoditiesOrHughesBuyer(this.buyer);
  }

}

/**
 * Given a proforma, returns true in case all its isValid fields are true.
 * @param proform
 * @returns boolean
 */
export function isAllValidInProform(proform:StructBuyerProform|null):boolean{
  return proform? proform.quality.isValid && proform.place.isValid && proform.wayPay.isValid: false;
}