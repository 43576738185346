import { Component } from '@angular/core';

@Component({
  selector: 'truck-svg',
  templateUrl: './truck.component.html',
  styleUrls: ['./../../../svg.scss']
})
export class TruckSvgComponent {
  constructor() { }
}
