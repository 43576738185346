import { Component, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-place-of-origin',
  templateUrl: './place-of-origin.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class PlaceOfOriginComponent {
  @Input() fieldIdentificator: any; 
  @Output() focusEvent = new EventEmitter<number>();
  constructor() {}

  emitField(fieldGroups:any){
    //Obtenemos el identificador del campo
    this.focusEvent.emit(fieldGroups.offsetTop);
  }
}