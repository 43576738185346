import { Component, Input } from '@angular/core';

@Component({
  selector: 'copy-green-svg',
  templateUrl: './copy-green-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class CopyGreenSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
