import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneratePriceBoardOrderComponent } from './generate-order-price-board.component';
import { HeaderModule } from 'src/app/components/header/header.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { BodyGenerateOrderModule } from './body-generate-order/body-generate-order.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';
import { LoadingLottieModule } from 'src/app/components/loading/loading.module';
@NgModule({
  declarations: [
    GeneratePriceBoardOrderComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    HeaderInSectionModule,
    MenuModule,
    BodyGenerateOrderModule,
    ModalModule,
    LoadingLottieModule
  ], exports: [
    GeneratePriceBoardOrderComponent
  ]
})
export class GeneratePriceBoardOrderModule { }
