<div class="card">
    <h4 class="title">Duplicar orden</h4>
    <p class="text">Ingresa la cantidad de ordenes a duplicar, teniendo en cuenta que la orden original <b>NO se cuenta en el total</b>.</p>

    <div class="values">
        <number-input class="width-100" [initialPlaceHolder]="'Ingrese una cantidad'" [isWithPoint]="false" 
        [max]="'99'" [step]="'1'" (valueOut)="setValueToDuplicateOrder($event)"></number-input>
    </div>

    <button class="filled-button" (click)="setNumberToDuplicate()">Aplicar</button>
</div>