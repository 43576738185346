import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { HeaderModule } from 'src/app/components/header/header.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import { BodyProfileModule } from './body-profile/body-profile.module';
import { MenuFooterModule } from 'src/app/components/menu-footer/menu-footer.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    ProfileComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    MenuModule,
    HeaderInSectionModule,
    BodyProfileModule,
    MenuFooterModule,
    ModalModule
  ], exports:[
    ProfileComponent
  ]
})
export class ProfileModule { }
