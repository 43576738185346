import { Component, OnInit,OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setAddExchange, setOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { OBJECT_ID_VOID } from 'src/app/shared/const/options';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { ExchangeTableComponent } from '../exchange-table.component';
import { getMinHeight } from 'src/app/pages/order-list/body-order-list/order-table/order-table.component';

@Component({
  selector: 'app-comercial',
  templateUrl: './comercial.component.html',
  styleUrls: ['./../../../../../shared/styles/board.scss', './../exchange-table.component.scss']
})
export class ComercialComponent extends ExchangeTableComponent implements OnInit, OnDestroy {
  OBJECT_ID_VOID=OBJECT_ID_VOID;
  
  constructor(store: Store<appState>, router: Router, dialog: MatDialog, date:DateChecker, orderV:OrderValidator, private ordExcV:OrderExchangeValidator) {
    super(store,router, dialog,date, orderV);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  editOrder(order:OrderExchange){

  }

  getSeller(order:OrderExchange){
    return this.orderV.getSellerName(order);
  }

  getExchanger(order:OrderExchange){
    return this.ordExcV.getSellerOfExchange(order);
  }

  getProductName(order:OrderExchange){
    return this.orderV.getProductName(order);
  }

  getExchangeStatus(order:OrderExchange){
    return this.ordExcV.getExchangeStatus(order);
  }

  getPrice(order:OrderExchange){
    return this.ordExcV.getTypeCoin(order) + ' ' + this.ordExcV.getPrice(order);
  }

  getDeliveryPeriod(order:OrderExchange){
    return this.orderV.getDeliveryPeriod(order)
  }

  getTons(order:OrderExchange){
    return this.ordExcV.getTons(order);
  }

  getMotherContract(order:OrderExchange){
    return this.orderV.getContractNumber(order);
  }

  getContractNumber(order:OrderExchange){
    return this.ordExcV.getContractNumber(order);
  }

  getBossNameCreator(order:OrderExchange){
    return this.orderV.getBossNameCreator(order);
  }

  getNameOfCreator(order:OrderExchange){
    return this.orderV.getNameOfCreator(order);
  }

  isConfirmationMissing(order:OrderExchange){
    try{
      return !this.ordExcV.isOrderIncomplete(order) && order.contractNumber <=0;
    } catch(error){
      return false;
    }
  }

  deleteExchange(order:OrderExchange){
    this.ordExcV.deleteExchange(order);
  }

  showAddExchange(order:OrderExchange){
    this.store.dispatch(setAddExchange({addExchange:true}));
    this.store.dispatch(setOrderExchangeDetails({ order: order }));
  }

  getInvisibleContrapart(order:OrderExchange){
    return order.contractNumber<=0 && order.statusOrder!=orderStatusConst.CONTRACT && 
    (!order.exchange.idMother || order.exchange.idMother != OBJECT_ID_VOID)
  }
  
  getMinHeight(table:any){
    return getMinHeight(table, !this.isComercial);
  }
}
