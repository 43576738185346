import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-yes-or-no',
  templateUrl: './dialog-yes-or-no.component.html',
  styleUrls: ['../modal.scss']
})
export class DialogYesOrNoComponent implements OnInit {
  @Input() showModal=false;
  @Input() title="";
  @Output() response = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  confirm(){
    this.response.emit(true);
    this.close();
  }

  noConfirm(){
    this.response.emit(false);
    this.close();
  }

  close(){
    this.showModal=false;
  }

}
