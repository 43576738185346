import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { loginSuccess, logout, refreshToken } from "../redux/actions/session.action";
import { COOKIES_CONST, localStorageNames } from "./const/localStorageNames";
import { NAVEGATION } from "./const/navegation";
import { isSameDate } from "./date.checker";
import { appState } from "./interfaces/appState.interface";
import { Store } from "@ngrx/store";
import { setErrorStruct } from "../redux/actions/options.action";

const url= "/"+ NAVEGATION.ACCESS

@Injectable()
export class SessionChecker {
    helper = new JwtHelperService();
    constructor(private router:Router){}

    rememberAndActualizateUser(store:Store<appState>){
        const accessTkn = getCookie('access_token');
        const refreshTkn = getCookie('refresh_token');
        const userStr = localStorage.getItem(localStorageNames.user);
        try{
            if(userStr && accessTkn && refreshTkn){
    
                //Nos fijamos la fecha inicial que fue creado el token
                const dateIat= new Date(this.helper.decodeToken(accessTkn).iat*1000); 
    
                if(isSameDate(dateIat, new Date()) && !isTokenExpired(refreshTkn, this.helper)) { 
                    //Si son la misma fecha y el refresh no está vencido, avanzamos, sino irá al login
                    
                    //Si el token NO expiró, guardamos el usuario en el store
                    store.dispatch(loginSuccess({user: JSON.parse(userStr)}));
                    
                    //Si el jwt access_token está vencido o cerca de vencer, lo actualizmos
                    if(isTokenExpired(accessTkn, this.helper) || isCloseToExpire(accessTkn, this.helper)){
                        store.dispatch(refreshToken({token: refreshTkn, photo: JSON.parse(userStr).photo, id: JSON.parse(userStr).id}));
                    }

                    return;
                }
            }
        } catch(err){}

        //else there is no token
        this.router.navigate([url]);
        store.dispatch(logout());
        return 
    }

    public goToHome(estado: any, router: Router) {
        if (estado.usuario.email != '') {
            router.navigate(['/home']);
        }
    }

    public goToLogin(estado: any, router: Router) {
        if (estado.usuario.email == '') {
            router.navigate([url]);
        }
    }
}

/** 
 * Function that is responsible for renewing the token and updating the token
 * @param email - email from user 
 * @return void
 * */ 
/*export function renewToken(store:Store<appState>){
    const accessTkn = getCookie(COOKIES_CONST.accessToken);
    const refreshTkn = getCookie(COOKIES_CONST.refreshToken);
    const userStr = localStorage.getItem(localStorageNames.user);
    try{
        if(userStr && accessTkn && refreshTkn){
            const helper= new JwtHelperService();

            if(!isTokenExpired(refreshTkn, helper)) { 
                //Si el refresh no está vencido, avanzamos, sino irá al login
                
                //Si el jwt access_token está vencido o cerca de vencer, lo actualizmos
                if(isTokenExpired(accessTkn,helper) || isCloseToExpire(accessTkn,helper)){
                    store.dispatch(setErrorStruct({error: {color: '#fff', isVisible:true, message: "Renovando sesión..."}}))
                    
                    store.dispatch(refreshToken({token: refreshTkn, photo: JSON.parse(userStr).photo, id: JSON.parse(userStr).id}));
                }
                //store.dispatch(refreshToken({token: refreshTkn, photo: JSON.parse(userStr).photo, id: JSON.parse(userStr).id}));
                return 
            }
        }
    } catch(err){}

    return 
}*/

function isTokenExpired(token: string, helper:JwtHelperService): boolean {
    return helper.isTokenExpired(token);
}

function isCloseToExpire(token: string, helper:JwtHelperService): boolean {
    const dateExp= new Date(helper.decodeToken(token).exp*1000); //Nos fijamos la fecha inicial que fue creado el token
    const dateNow = new Date();
    const diff = dateExp.getTime() - dateNow.getTime();
    return diff < 1000*60*5; //5 minutos
}


export function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
    return null;
}