import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';


const excludedUrls = [
  environment.rutas.getToken, environment.rutas.getRefreshToken, 
  environment.rutas.claim, environment.rutas.dashboard.getAccumulatedTonnage,
  environment.rutas.maestros.getCount, environment.rutas.maestros.getDB,
  environment.rutas.views.searchAll, environment.rutas.views.search,
  environment.rutas.views.send, environment.rutas.views.update,

];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private tokenSvc: TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Verificar si la solicitud debe ser excluida
    const isExcluded = excludedUrls.some(url => req.url.includes(url));

    if (isExcluded) {
      // Si la solicitud está en la lista de exclusión, se envía sin modificar
      return next.handle(req);
    }

    // Verificar si el token está a 10 minutos de vencer
    if (this.tokenSvc.isTokenExpiring()) {
      this.tokenSvc.renewToken().subscribe(res=>{});
    }
    
    if (this.tokenSvc.isTokenExpired()) {
      return this.tokenSvc.renewToken().pipe(
        switchMap(newToken => {
          const clonedRequest = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${newToken}`)
          });
          return next.handle(clonedRequest);
        }),
        catchError(error => {
          // Manejar errores de renovación de token
          return throwError(error);
        })
      );
    } else {
      const clonedRequest = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.tokenSvc.getAccessToken()}`)
      });
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          // Manejar otros errores HTTP
          return throwError(error);
        })
      );
    }
  }
}
