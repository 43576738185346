<div class="body">
    <div class="header">
        <span class="title">Historial de cambios</span>
        <cross-svg class="cross" (click)="close()"></cross-svg>
    </div>

    <div class="history-information">
        <div class="row-center">
            <span class="field">{{messageInfo}}</span>

        </div>
        <div *ngIf="historicalArray else emptyState">
            <div *ngFor="let historical of historicalArray" class="history"
            [ngClass]="{'invisible':!getValue(historical)}">
                <div class="history-header">
                    <span class="hour modification">Hora de modificación</span>
                    <span class="hour">{{getHour(historical.createdAt)}}</span>
                </div>

                <div class>
                    <span class="name">{{getNameOfChanger(historical)}}</span>
                </div>

                <div class="history-body">
                    <span class="field">{{getField(historical.campo)}}</span>
                    <span class="value">{{getValue(historical)}}</span>
                </div>

                <div class="line grey"></div>
            </div>
        </div>

        <ng-template #emptyState>
            <div class="empty-state" *ngIf="!isError else showError">
                <span class="empty-state-text">Aún no hay ningun cambio en está
                    condición de negocio.</span>
            </div>
            <ng-template #showError>

            </ng-template>
        </ng-template>
    </div>
</div>