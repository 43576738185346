import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pesification',
  templateUrl: './pesification.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class PesificationComponent{
  @Input() fieldIdentificator: any;
  constructor() { }
}
