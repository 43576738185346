import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicatorComponent } from './indicator.component';
import { DateRangeInputModule } from 'src/app/components/inputs/date-range/date-range.module';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { DateMonthInputModule } from 'src/app/components/inputs/date-month/date.module';
import { RadioButtonInputModule } from 'src/app/components/inputs/radio-buttons/radio-buttons.module';
import { SelectInputModule } from 'src/app/components/inputs/select/select.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QualityModule } from '../../quality/quality.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';

@NgModule({
  declarations: [IndicatorComponent],
  imports: [
    CommonModule,
    DateRangeInputModule,
    NumberInputModule,
    DateMonthInputModule,
    RadioButtonInputModule,
    SelectInputModule,
    MatSlideToggleModule,
    QualityModule,
    ArrowSvgModule
  ],
  exports: [IndicatorComponent],
})
export class IndicatorModule {}
