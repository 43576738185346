<section class="body">

  <div class="header-section">
    <div class="downs">
      <div class="selects division-type">
        <button class="buy-commodities" [ngClass]="{ selected: divisionsType.BUY_COMMODITIES == divisionSelected }"
          (click)="setDivisionType(divisionsType.BUY_COMMODITIES)">
          Compras Commodities
        </button>
        <button class="sell-commodities" [ngClass]="{ selected: divisionsType.SELL_COMMODITIES == divisionSelected }"
          (click)="setDivisionType(divisionsType.SELL_COMMODITIES)">
          Ventas Commodities
        </button>
        <button class="exports" [ngClass]="{ selected: divisionsType.EXPORTS == divisionSelected }"
          (click)="setDivisionType(divisionsType.EXPORTS)">
          Exportadores
        </button>
      </div>

      <div class="selects section-type" *ngIf="divisionsType.BUY_COMMODITIES == divisionSelected">
        <button class="contracts-in-eraser" [ngClass]="{ selected: sectionType.CONTRACTS_IN_ERASERS == sectionTypeSelected }"
          (click)="setSectionType(sectionType.CONTRACTS_IN_ERASERS)">
          Contratos en borrador
        </button>
      
        <button class="contracts-autorizates" [ngClass]="{ selected: sectionType.CONTRACTS_AUTORIZATES == sectionTypeSelected }"
          (click)="setSectionType(sectionType.CONTRACTS_AUTORIZATES)">
          Contratos autorizados
        </button>
        <div class="rectangle green {{ sectionTypeSelected }}"></div>
      </div>

      <app-buttons-condition-type></app-buttons-condition-type>
      <app-views-section-order class="views" [isControlPanel]="true"></app-views-section-order>
    </div>
  
    <div class="call-to-actions">
        <div class="buttons-to-actions">
          <app-search-input class="search"></app-search-input>
        </div>

        <div class="description">
          <span class="quant">Cantidad de contratos 
            {{sectionTypeSelected==sectionType.CONTRACTS_IN_ERASERS && getBuyerType()!=buyerType.BUYER?'en borrador': 'autorizados'}}: 
            <span class="stroke">{{(ordersAll | filterOrderByConditionType:conditionTypeSelected:conditionFilters  | 
              withOnlyContractStatus:getStatusSelected() | isOrderOfCommodities: getBuyerType() 
              | filterCommoditiesSeller: getIsSellCommodities() | filterByOrder:orderFilter | filterOrder:search).length }}</span></span>

            <span class="quant">Cantidad de toneladas: 
              <span class="stroke">{{getQuantOfTons(ordersAll | filterOrderByConditionType:conditionTypeSelected:conditionFilters  | withOnlyContractStatus:getStatusSelected() | isOrderOfCommodities: getBuyerType() 
              | filterCommoditiesSeller: getIsSellCommodities() | filterByOrder:orderFilter | filterOrder:search)}}</span>
            </span>
        </div>
    </div>
  </div>

    <app-buttons-to-fix *ngIf="conditionTypeSelected==CONDITION_TYPES.TO_FIX"></app-buttons-to-fix>
    <app-order-eraser-table class="table" *ngIf="!isViewMobile else elseBlock"></app-order-eraser-table>
    <ng-template #elseBlock>
      <div class="order-mobile visible-in-mobile width-100" *ngFor="let order of ordersAll  | filterOrderByConditionType:conditionTypeSelected:conditionFilters  | withOnlyContractStatus:getStatusOfFilterOrder() | isOrderOfCommodities: buyerType.COMMODITIES | 
      filterByOrder:orderFilter | filterOrder:search | matchOrdersByName: getStatusOfFilterOrder(): orderFilter">
        <app-table-mobile-order [orders]="order" class="table-mobile width-100"></app-table-mobile-order>
      </div>
    </ng-template>

    <div [ngClass]="{'background opaque': isShowDetails || historical}"></div>
    <app-order-eye class="slide-card slide-card-visible" *ngIf="isShowDetails" 
    [isShow]="isShowDetails" [order]="orderDetails"></app-order-eye>
    <app-order-historical class="slide-card slide-card-visible" *ngIf="historical"></app-order-historical>

    <app-loading *ngIf="showLoading" class="loading"></app-loading>

    <dialog-contract-create *ngIf="typeModal==3"></dialog-contract-create>
    <dialog-contract-failed *ngIf="typeModal==2"></dialog-contract-failed>
    <dialog-eraser-create *ngIf="typeModal==1"></dialog-eraser-create>
      
  </section>