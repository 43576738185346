<div class="body" class="{{isToFix? 'card-to-fix': 'card-to-price'}}">
    <div class="details-container width-100" *ngIf="!showCompleteState">
        <div class="header">
            <span class="add-buyer title">Detalle de la condición</span>
            <cross-svg class="cross" (click)="close()"></cross-svg>
        </div>

        <div class="information">
            <p class="description owner-create">Condición de negocio creada por <span class="stroke">{{price.dataOfcreation.nameOfcreator}} <br class="visible-in-mobile"/> a las {{price.dataOfcreation.hour}}.</span></p>
        
            <div class="state-container width-100 row-between">
                <p class="description state-text">Estado de la condición: 
                    <app-status-tag [price]="price"></app-status-tag>
                </p>

                <button class="visible-in-mobile filled-button change-state" (click)="setShowChangeState(true)" 
                *ngIf="showButtonChangeState()">Cambiar estado</button>
            </div>
        </div>

        <div class="line grey not-visible-in-950" style="margin-bottom:8px;"></div>
    
        <div class="orders-description">
            <div class="row-between visible-in-mobile first-line">
                <span class="first-description">Condición a precio - {{getBuyer()}}</span>
                <edit-svg class="edit-icon" [size]="20" (click)="editPrice()" *ngIf="!isComercial"></edit-svg>
            </div>
                <app-details-small [price]="price" [isToFix]="isToFix"></app-details-small>
                <div class="line grey"></div>
        </div>
    </div>

     <!--Only visible in mobile-->
     <div class="complete-state-container column-between width-100" style="height: 100%;" *ngIf="showCompleteState">
        <div class="header">
            <span class="add-buyer title">Cambiar estado</span>
            <cross-svg class="cross" (click)="setShowChangeState(false)"></cross-svg>
        </div>

        <p class="description" style="margin-top:-64px;">Seleccioná un estado para aplicar en la condición de negocio y que la visualice el comericial.</p>
        
        <div class="status-container column gap-16" style="margin-top:-64px;">
            <div class="row-between inactive-price">
                <div class="row-between gap-8">
                    <div class="circle"></div>
                    <span>Inactivo</span>
                </div>
                <input type="radio" name="status" [checked]="statusSelected==STATUS_PRICE_CONST.INACTIVO" 
                (click)="setStatusSelected(STATUS_PRICE_CONST.INACTIVO)">
            </div>

            <div class="row-between idea-price">
                <div class="row-between gap-8">
                    <div class="circle"></div>
                    <span>Idea</span>
                </div>
                <input type="radio" name="status" [checked]="statusSelected==STATUS_PRICE_CONST.IDEA" 
                (click)="setStatusSelected(STATUS_PRICE_CONST.IDEA)">
            </div>
            
            <div class="row-between">
                <div class="row-between gap-8 paused-price">
                    <div class="circle"></div>
                    <span>Pausado</span>
                </div>
                <input type="radio" name="status" [checked]="statusSelected==STATUS_PRICE_CONST.PAUSADO" 
                (click)="setStatusSelected(STATUS_PRICE_CONST.PAUSADO)">
            </div>

            <div class="row-between">
                <div class="row-between gap-8 price-price">
                    <div class="circle"></div>
                    <span>Precio</span>
                </div>
                <input type="radio" name="status" [checked]="statusSelected==STATUS_PRICE_CONST.PRECIO" 
                (click)="setStatusSelected(STATUS_PRICE_CONST.PRECIO)">
            </div>

            <div class="row-between">
                <div class="row-between gap-8 retired-price">
                    <div class="circle"></div>
                    <span>Retirado</span>
                </div>
                <input type="radio" name="status" [checked]="statusSelected==STATUS_PRICE_CONST.RETIRADO"
                 (click)="setStatusSelected(STATUS_PRICE_CONST.RETIRADO)">
            </div>

        </div>

        <button class="not-filled-button" style="margin-top:64px;" [disabled]="isDisabledButtonStatus()"  
        [ngClass]="{'disabled':isDisabledButtonStatus()}"
        (click)="applyNewStatus()">
            Aplicar
        </button>
    </div>
    
</div>