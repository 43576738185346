import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedPricesComponent } from './featured-prices.component';
import { CardPriceFeaturedModule } from 'src/app/components/cards/price-featured/price-featured.module';

@NgModule({
  declarations: [
    FeaturedPricesComponent,
  ],
  imports: [
    CommonModule,
    CardPriceFeaturedModule
  ],
  exports: [
    FeaturedPricesComponent
  ]
})
export class FeaturedPricesModule { }
