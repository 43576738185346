import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'th[cell-head]',
  templateUrl: './cell-head.component.html',
  styleUrls: ['./cell-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellHeadComponent {
  @Input() name:string='';
  @Input() isFilter:boolean=false;
  @Input() showFilter:boolean=false;

  @Input() filterIdentificator!: number;
  @Input() identificatorString: string = '';
  @Input() identificatorStringAux:string='';
  @Input() isPropertyOfPrice:boolean=false;

  @Input() isSearchOrNumber: boolean = false;
  @Input() arrayAux:any=[]
  @Input() onlyFilter:boolean=false;
  @Input() isToFix:boolean=false;

  @Input() isPrice:boolean=false;

  @Output() select=new EventEmitter<number>();

  constructor() { }

  selectFilter(){
    this.select.emit(this.filterIdentificator);
  }
}
