import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zonePipe'
})

export class ZonePipe implements PipeTransform {
  /*Given a place of delivery.
It is formatted and returned differently for a mobile view.*/
  transform(placeOfDelivery: string, format: string) {
    return placeOfDelivery;
  }
}
