import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceOfDeliveryComponent } from './place-of-delivery.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ZoneComponent } from './zone/zone.component';
import { PortComponent } from './port/port.component';



@NgModule({
  declarations: [
    PlaceOfDeliveryComponent,
    ZoneComponent,
    PortComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule
  ], exports: [
    PlaceOfDeliveryComponent,
    ZoneComponent,
    PortComponent
  ]
})
export class PlaceOfDeliveryModule { }
