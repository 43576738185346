import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonificacionComponent } from './bonificacion.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { DateInputModule } from 'src/app/components/inputs/date/date.module';
import { SelectInputModule } from 'src/app/components/inputs/select/select.module';
import { AmountModule } from '../../amount/amount.module';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { RadioButtonInputModule } from 'src/app/components/inputs/radio-buttons/radio-buttons.module';
import { DateMonthInputModule } from 'src/app/components/inputs/date-month/date.module';

@NgModule({
  declarations: [
    BonificacionComponent
  ],
  imports: [
    CommonModule,
    AmountModule,
    DateMonthInputModule,
    MatRadioModule,
    ArrowSvgModule,
    MatDatepickerModule,
    NumberInputModule,
    SelectInputModule,
    DateInputModule,
    SelectInputModule,
    RadioButtonInputModule
  ], exports: [
    BonificacionComponent
  ]
})
export class BonificacionModule { }