import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { MatLegacyDialog as MatDialog, } from '@angular/material/legacy-dialog';
import { DateChecker } from 'src/app/shared/date.checker';
import { OrderValidator } from 'src/app/shared/order';
import { Details } from '../details.component';
import { DialogOrderCreate } from '../../dialogs/order-create/dialog';
import { setIndexToEdit, setOrdersToGenerate, setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { Commission, Order } from 'src/app/shared/interfaces/order.interface';
import { orderStates } from 'src/app/shared/dict/orders';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { setExchangeInOrderToGenerate, setIsGenerateOrderExchange, setOrderToGenerateInExchange } from 'src/app/redux/actions/exchange.action';
import { exchangeInitialState } from 'src/app/shared/const/exchange-orders';
import { Exchange, OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { OrderExchangeValidator } from 'src/app/shared/order-exchange';
import { Subscription } from 'rxjs';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { setIsEditedOrderFromBoard } from 'src/app/redux/actions/options.action';
import { ACTIONS_MANY_ORDERS, ActionOrderMany } from '../details-many-orders/details-many-orders.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss','./../details.scss']
})

export class DetailsComponent extends Details implements OnInit, OnDestroy {
  isGenerateExchangeOrder:boolean = false;
  onlyExchange!:OrderExchange;
  showDetailsOrdExc:boolean = false;
  isShowToFix:boolean = false;
  titleOfDetails:string='Detalle de la orden';

  showMessageError:boolean = false;

  private order$!:Subscription;
  private exchange$!:Subscription;
  private location$!:Subscription;

  constructor(store: Store<appState>, dialog: MatDialog, date: DateChecker, orderV:OrderValidator, private orderExcV:OrderExchangeValidator,
    toFixV:ToFixValidator) {
    super(store,dialog,date,orderV, toFixV);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.order$=this.store.select('order').subscribe(orderReducer => {
      
      this.order = orderReducer.generateOrder;
      if(!this.isGenerateExchangeOrder){
        this.orders = orderReducer.generateOrders;
        //this.price = this.order.price;
        this.identificatorOrderEdit = orderReducer.indexToEdit;
        this.disabledButton = this.orderV.isOrderIncomplete(this.order);
      }
    })

    this.exchange$=this.store.select('exchange').subscribe(exchangeReducer => {
      console.log("Exchange")
      this.isGenerateExchangeOrder = exchangeReducer.isGenerateOrderExchange;
      if(this.isGenerateExchangeOrder){
        //this.order = exchangeReducer.generateOrder; 
      }
      this.onlyExchange = exchangeReducer.generateOrder;
    }) 

    this.location$=this.store.select('options','location').subscribe(location=>{
      this.titleOfDetails=location==NAVEGATION.GENERATE_MANUAL_ORDER?'Detalle de la orden manual':'Detalle de la orden desde el tablero';
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.order$?.unsubscribe();
    this.exchange$?.unsubscribe();
    this.location$?.unsubscribe();
  }

  STATUS:any=orderStates

  override sendOrder() {
    this.openModal()
  }

  override sendOrders() {
    if(this.isOrdersIncomplete()){
      this.showMessageError=true;
    } else {
      this.showMessageError=false;
      this.openModal()
    }
  }

  openModal(){
    this.dialog.open(DialogOrderCreate);
  }

  isOrdersIncomplete():boolean{
    return this.orders.some(order => this.orderV.isOrderIncomplete(order));
  }

  changeShowDetailsOrdExc(){
    this.showDetailsOrdExc = !this.showDetailsOrdExc;
    this.store.dispatch(setIsEditedOrderFromBoard({isEditedOrderFromBoard: true}));
  }

  getArrowDirectionShowDetailsOrdExc(){
    return getArrowDirectionByBool(this.showDetailsOrdExc);
  }

  getArrowDirectionToFix(bool:boolean){
    return getArrowDirectionByBool(bool);
  }

  getArrowDirectionGeneric():string{
    return this.showDetailsOrdExc || this.isShowToFix ? 'up' : 'down';
  }

  //-------- ACTIONS WITH ORDERS ------------\\
  /*override deleteOrder(index:number){
    let newOrders= JSON.parse(JSON.stringify(this.orders)); //Parsed orders
    newOrders.splice(index,1); //Deleted order
    let newIndex=index<this.identificatorOrderEdit?this.identificatorOrderEdit-1:this.identificatorOrderEdit; //New index to edit
    if(this.identificatorOrderEdit==index){ //If index to edit is the same as the deleted index
      newIndex=index-1 < 0 ? 0 : index-1;
    }

    if(newOrders.length<=1){ //If there is only one order
      newIndex=0; //Set index to edit to 0
      this.store.dispatch(setOrderToGenerate({order: newOrders[0]})); //Set order to edit
    }
    this.store.dispatch(setIndexToEdit({indexToEdit: newIndex})); //Set index to edit
    this.store.dispatch(setOrdersToGenerate({orders: newOrders})); //Set orders
  }

  //Edit order in parent component.

  override duplicateOrder(index:number){
    let newOrders= JSON.parse(JSON.stringify(this.orders)); //Parsed orders
    newOrders.splice(index,0,newOrders[index]); //Duplicate order
    this.store.dispatch(setOrdersToGenerate({orders: newOrders})); //Set orders
  }*/

  override setCommission(event:Commission, index:number){
    let newOrders:Order[]= JSON.parse(JSON.stringify(this.orders)); //Parsed orders
    newOrders[index].commision=event;
    this.store.dispatch(setOrdersToGenerate({orders: newOrders})); //Set orders
  }

  getTypeCoin(order:Order){
    return this.orderV.getTypeCoinString(order);
  }

  getPriceOfCondition(order:Order){
    return this.orderV.getPriceOfCondition(order);
  }

  changeShowToFix(){
    this.isShowToFix = !this.isShowToFix;
  }

  recieveAction(actOrdMany:ActionOrderMany){
    if(actOrdMany.action== ACTIONS_MANY_ORDERS.COMMISSION && actOrdMany.commission){
      this.setCommission(actOrdMany.commission, actOrdMany.index);
    } else if(actOrdMany.action== ACTIONS_MANY_ORDERS.EDIT){
      this.editOrder(actOrdMany.index);
    }
  }
}