import { Component, Input,Output,EventEmitter,AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fieldType } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';

@Component({
  selector: 'app-place-of-delivery',
  templateUrl: './place-of-delivery.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class PlaceOfDeliveryComponent implements AfterViewInit{
  @Input () fieldIdentificator: any;
  @Input() requirePort:boolean=false;
  @Input() incompleteRequirePort:boolean=false;
  @Output() tab = new EventEmitter<string>();
  @Output() focusEvent= new EventEmitter<number>();
  elementToGenerate:any;
  constructor(private store:Store<appState>) {}

  emit(event: any){
    this.tab.emit(event);
  }

  priceSubscription!:Subscription;
  orderSubscription!:Subscription;
  exchangeSubscription!:Subscription;

  ngAfterViewInit(): void {
    this.priceSubscription=this.store.select('price').subscribe(price=>{
      if(this.fieldIdentificator==fieldType.PRICE){
        this.elementToGenerate = price.generatePrice;
      }
    })

    this.orderSubscription=this.store.select('order').subscribe(order=>{
      if(this.fieldIdentificator==fieldType.ORDER){
        const orders=order.generateOrders;
        if(orders.length>1){
          this.elementToGenerate=orders[order.indexToEdit];
        } else {
          this.elementToGenerate = order.generateOrder;
        }
      } else if(this.fieldIdentificator==fieldType.VALIDATION){
        this.elementToGenerate =
          order.edit.ordersEdit[
            order.edit.indexToEdit
          ];
      } 
    });

    this.exchangeSubscription=this.store.select('exchange').subscribe(exchange=>{
      //Field identificator is exchange or is exchange_buyer
      if(this.fieldIdentificator==fieldType.EXCHANGE ){
        this.elementToGenerate = exchange.generateOrder.exchange;
      } else if(this.fieldIdentificator==fieldType.EXCHANGE_BUYER){
        this.elementToGenerate = exchange.generateOrder;
      }
    });

  }

  ngOnDestroy(): void {
    this.priceSubscription?.unsubscribe();
    this.orderSubscription?.unsubscribe();
    this.exchangeSubscription?.unsubscribe();
  }


  zoneNotHavePort(){
    const price=this.getPrice();
    if(price){
      return [3,4,5,6,7].includes(price.placeOfDelivery.zone)
    }
  }

  getPrice():Price {
    try{
      if (this.fieldIdentificator == fieldType.PRICE) {
        return this.elementToGenerate;
      } else {
        return this.elementToGenerate.price;
      }
    } catch (error) {
      const und:any=undefined;
      return und;
    }
  }

  emitField(fieldGroups: any) {
    this.focusEvent.emit(fieldGroups.offsetTop);
  }
  
}
