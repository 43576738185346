import { Component, Output, EventEmitter,Input } from "@angular/core";
import { BUSINESS_PARTICULARITIES_VOID } from "src/app/shared/const/business-particularities";
import { BusinessParticularities } from "src/app/shared/interfaces/business-particularities";

@Component({
  template: '',
})
export class BusinessParticularityOption {
  optionOpen:string='up'; 
  @Input() businessParticularitiesStruct:BusinessParticularities=BUSINESS_PARTICULARITIES_VOID;

  @Output() save = new EventEmitter<string>();
  @Output() selected = new EventEmitter<number>();
  
  constructor() { }

  setOptionOpen(direction:string){
    this.optionOpen=direction;
  }

  setOptionOpenWithCheck(event:any, selectedIndex:number=0){
    this.setOptionOpen(event.target.value=='up'? 'down': 'up');
    if(this.optionOpen=='down'){
      this.emitSelected(selectedIndex);
    } else {
      this.emitSelected(0);
    }
  }

  changeOptionOpen(){
    if(this.optionOpen=='up'){
      this.setOptionOpen('down');
    } else {
      this.setOptionOpen('up');
    }
  }

  showOptions(index:number){
    setTimeout( ()=> {
      this.setOptionOpen(this.optionOpen=='up'? 'down': 'up');
      this.emitSelected(index);
    }, 200)
  }

  emit(){
    this.save.emit( JSON.stringify(this.businessParticularitiesStruct));
  }

  emit2(businessParticularities:BusinessParticularities){
    this.save.emit( JSON.stringify(businessParticularities));
  }

  emitSelected(index:number){
    this.selected.emit(index);
  }

  boolToNumber(boolean:boolean){
    return boolean? 1: -1;
  }


}
