import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusTagComponent } from './status.component';

@NgModule({
  declarations: [
    StatusTagComponent
  ],
  imports: [
    CommonModule
  ],exports:[
    StatusTagComponent
  ]
})
export class StatusTagModule { }