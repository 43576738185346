<section class="body">

    <div class="header-section">
        <div class="downs">
            <app-buttons-buyer-type [buyerTypeSelected]="buyerTypeSelected" (selected)="setBuyerType($event)"></app-buttons-buyer-type>
            <app-buttons-condition-type></app-buttons-condition-type>
            <app-views-section-order class="views" [ngClass]="{'not-visible-in-mobile': !isComercial}"></app-views-section-order>
        </div>
      
        <div class="call-to-actions">
          <div class="buttons-to-actions">
            <app-search-input class="search"></app-search-input>
            <button class="add filled-button button price not-visible-in-mobile" 
            (click)="goToCreateOrder()"> Nueva orden</button>            
          </div>
      
          <div class="description"> 
            <span class="quant data eraser not-visible-in-mobile" (click)="goToEraser()" *ngIf="isComercial">
                <div class="circle yellow" *ngIf="quantEraserOrders > 0"></div>
                Ordenes en borrador: <span class="stroke">{{quantEraserOrders}}</span></span>
            <span class="quant">Cantidad de ordenes: <span class="stroke">{{(orderWithPipe | filterByRepresentation: isRepresentation:user)?.length ?? 0 }}</span></span>
            <span class="quant">Cantidad de toneladas: 
                <span class="stroke">{{getQuantOfTons(orderWithPipe | filterByRepresentation: isRepresentation:user) }}</span>
            </span>
            <span class="days not-visible-in-mobile pointer" 
            (mouseenter)="hover()" (mouseleave)="noHover()"
            (click)="goToHistorical()">Ordenes del día <span class="stroke">{{getDate()}}</span>
                <arrow-svg [direction]="'right'" [isHover]="isHover" class="arrow"></arrow-svg>
            </span>
            </div>
        </div>
    </div>

    <div class="buttons-actions not-visible-in-mobile" *ngIf="isOperator" [ngClass]="{'active': (ordersSelected? ordersSelected.length: 0) > 0}">
        <button class="confirmed-order" (click)="setEditOrders(ORDER_STATUS_CONTS.CONFIRM)"> <div class="circle confirm"></div> Confirmar</button>
        <button class="return-order" (click)="setEditOrders(ORDER_STATUS_CONTS.RETURNED)"> <div class="circle return"></div> Devolver</button>
        <button class="check-order" (click)="setEditOrders(ORDER_STATUS_CONTS.CHECK)"> <div class="circle check"></div> Vista</button>
        <button class="oferted-order" (click)="setEditOrders(ORDER_STATUS_CONTS.OFERTED)"> <div class="circle ofertar"></div> Ofertar</button>
        <button class="send-to-broker-order" (click)="setEditOrders(ORDER_STATUS_CONTS.SEND_TO_BROKE)"> <div class="circle sent-to-broker"></div> Enviar a bolsa</button>
        <button class="out-of-market-order" (click)="setEditOrders(ORDER_STATUS_CONTS.OUT_OF_MARKET)"> <div class="circle out-of-market"></div> Fuera de mercado</button>
        <!--<button class="btn btn-primary" (click)="getClientesByUserCode()">Obtener clientes</button>-->
        <!--<button class="btn btn-primary" (click)="saveClients()">Almacenar todos los clientes</button>-->
        <!--<button class="btn btn-primary pointer" (click)="saveAfipCode()">Códigos de AFIP</button>-->
    </div>

    <order-table class="table"></order-table>

    <!-- Slide Cards -->
    <div [ngClass]="{'background opaque': isShowDetails || isShowAddBuyer || historical }" (click)="closeCards()"></div>
    <app-order-historical class="slide-card slide-card-visible" *ngIf="historical"></app-order-historical>
    <app-order-eye class="slide-card slide-card-visible" *ngIf="isShowDetails" [isShow]="isShowDetails" [order]="orderDetails"></app-order-eye>
    <app-add-buyer class="slide-card slide-card-visible" *ngIf="isOperator && isShowAddBuyer"></app-add-buyer>

    <!--Modal-->    
    <app-loading *ngIf="showLoading" class="loading"></app-loading>
    <dialog-contract-create *ngIf="typeModal==3"></dialog-contract-create>
    <dialog-contract-failed *ngIf="typeModal==2"></dialog-contract-failed>
    <dialog-eraser-create *ngIf="typeModal==1"></dialog-eraser-create>

    <div class="error-visible" [ngClass]="{'changes-visibles': errorVisible.isVisible, 'changes-invisibles':!errorVisible.isVisible}" *ngIf="!isComercial">
        <span>{{errorVisible.message}}</span>
    </div>
    <div class="save-changes" [ngClass]="{'changes-visibles': isChangeVisible, 'changes-invisibles':!isChangeVisible}" *ngIf="!isComercial">
        <span>Se realizaron cambio en la condición. <span class="undo">Deshacer</span></span>
    </div>

    <div class="footer not-visible-in-950" *ngIf="!isComercial">
        <button (click)="validateOrders()" [disabled]="isOrdersSelectedStatus(ordersSelected, ORDER_STATUS_CONTS.CONFIRM )"
        class="not-filled-button edit-button"
        [ngClass]="{'disabled': isOrdersSelectedStatus(ordersSelected, ORDER_STATUS_CONTS.CONFIRM )}"> Editar </button>
        <button (click)="sendToTickets()" [disabled]="isOrdersSelectedStatus(ordersSelected, ORDER_STATUS_CONTS.CONFIRM )" 
        class="not-filled-button"
        [ngClass]="{'disabled': isOrdersSelectedStatus(ordersSelected, ORDER_STATUS_CONTS.CONFIRM )}"> Generar borrador </button>
    </div>
</section>