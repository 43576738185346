<div class="field" #fieldName>
    <span class="field-title">Tipo de negocio<span class="asterisk">*</span></span>

    <div>
        <div class="form-field" [ngClass]="{'border-green': isOptionSelected, 'is-incomplete': isIncomplete}"
        (click)="changeShowOptions()">
            <span class="title-field {{identifyClassName}}">  
                <span class="autocomplete"> 
                    <span class="first-part">{{search}}</span> 
                    <span class="second-part">{{placeHolder}}</span>
                </span>
                <input  #inputName (focus)="focusField(fieldName);" [readOnly]="isReadOnly()"
                (blur)="unfocusField()" value="{{elementSelected}}" [(ngModel)]="search" placeholder="{{initalElementSelected}}" class="{{identifyClassName}} no-background-border"/>
                <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                <img alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}" (mouseup)="focusInInput(inputName)"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>
        <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>

        <div class="options" *ngIf="showOptions">
            <span *ngFor="let element of array; index as i" [ngClass]="{'selected': OBJECT[element]==elementSelected}"
                (click)="setElement(element, i)">
                {{OBJECT[element]}}
            </span>
        </div>
    </div>
</div>