import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import * as dict from 'src/app/shared/dict/dict';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { provinces } from 'src/app/shared/dict/provinces/provinces';
import { OrderValidator } from 'src/app/shared/order';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { buyers } from 'src/app/shared/dict/buyers';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { orderCategory } from 'src/app/shared/dict/orders';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { SellerObject, sellerWithData } from 'src/app/shared/dict/seller-with-data';
//import { QualityParticularities } from 'src/app/shared/interfaces/quality-particularities';
import { OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { Subscription } from 'rxjs';
import { wayPay } from 'src/app/shared/dict/wayToPay';

@Component({
  selector: 'app-info-syngenta',
  templateUrl: './info-syngenta.component.html',
  styleUrls: ['./../info.scss']
})
export class InfoSyngentaComponent implements OnInit, OnDestroy {
  @Input() order!:Order;
  isGenerateToFix:boolean=false;

  AUX: any;
  SELLERS: SellerObject={};
  PLACE_OF_ORIGIN: any;
  BROKERS: any;
  HOW_SELL: any;
  ONLY_SELL: any;
  DONT_SELL: any;
  BUYERS: any;
  PROVINCE: any;
  LOCALITIES: any;

  public ORDER_TYPE_CATEGORY: any;
  public PRODUCTS: any;
  public BUSINESS_TYPE: any;
  public PLACE_OF_DELIVERY: any;
  public ZONES: any;
  public PECULIARITIES: any;
  public QUALITY: any;
  public COIN_TYPE: any;
  public WAY_TO_PAY: any;
  public HARVEST: any;

  private optionsSubscription!:Subscription;

  constructor(private store: Store<appState>, private orderV:OrderValidator) {}

  ngOnInit(): void {
    this.setArrays();
    
    this.optionsSubscription=this.store.select('options', 'isGenerateToFix').subscribe(isGenerateToFix=>{
      this.isGenerateToFix=isGenerateToFix;
    })
  }

  ngOnDestroy(): void {
    this.optionsSubscription.unsubscribe();
  }

  private setArrays() {
    this.SELLERS = sellerWithData;
    this.PLACE_OF_ORIGIN = provinces;
    this.BROKERS = dict.broker;
    this.HOW_SELL = dict.howSell;
    this.BUYERS = buyers;

    this.ORDER_TYPE_CATEGORY = orderCategory;
    this.BUSINESS_TYPE = typeBusiness;
    this.PRODUCTS = PRODUCT_NAMES;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.QUALITY = dict.quality;
    this.COIN_TYPE = dict.typeCoin;
    this.WAY_TO_PAY = wayPay;
  }

  
  getPlaceOfOrigin(order: Order) {
    return this.orderV.getPlaceOfOrigin(order.placeOfOrigin.province, order.placeOfOrigin.town);
  }

  getBuyer(buyer: any) {
    return this.orderV.getRestriction(buyer);
  }

  getTons(order:Order){
    return this.orderV.getTons(order);
  }

  getPlaceOfDelivery(order: Order) {
    return this.orderV.getPlaceOfDelivery(order.price.placeOfDelivery);
  }

  getWayPay(order: Order) {
    return this.orderV.getWayPay(order);
  }

  getBusinessParticularities(businessParticularities: BusinessParticularities){
    return this.orderV.getBusinessParticularities(businessParticularities);
  }

  getQualityParticularities(qualityParticularities: any /*QualityParticularities*/){
    return this.orderV.getQualityParticularities(qualityParticularities);
  }

  getQualityIBM(order:Order){
    return this.orderV.getQualityIBM(order);
  }

  getQuality(order: Order) {
    return this.orderV.getQuality(order);
  }

  getPesification(order:Order) {
    return this.orderV.getPesification(order);
  }
  getGrouperCode(order:Order){
    return this.orderV.getGrouperCode(order);
  }
  
  getQuotas(order:Order){
    return this.orderV.getQuotas(order);
  }

  isOrderExchange(order:any){
    return order.exchange
  }

  getSellerName(order:any){
    try{
      return this.SELLERS[order.sellerData.codeS].nombre + (order.sellerData.codeS <=0 ? '' : ' (' +  order.sellerData.codeS+ ')');
    } catch (e) {
      return '--'
    }
  }

  isSyngenta(order:any){
    return this.orderV.isSyngenta(order.sellerData.codeS);
  }

  getSubdivisionSyngenta(order:Order){
    let orderE=order as OrderExchange;
    return this.orderV.getSubdivisionSyngenta(orderE);
  }
}
