import { getTableIBM } from "../data.checker"
import { IBM_TABLES } from "./ibm/tables"

export interface SellerObject{
    [key:string]: SellerIndividual
  }
  
export interface SellerIndividual {
  sucProd: number,
  cgoProv: number,
  nombre: string,
  localidad: string,
  cuit: string,
}

export let sellerWithData:SellerObject=getTableIBM(IBM_TABLES.CER002);

export function updateSellerWithData(data:SellerObject){
    sellerWithData=data;
}