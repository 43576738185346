import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setShowDeletePrice } from 'src/app/redux/actions/options.action';
import { setPricesSelected } from 'src/app/redux/actions/price.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';

@Component({
  selector: 'app-price-delete',
  templateUrl: './price-delete.component.html',
  styleUrls: ['./../modal.scss']
})
export class PriceDeleteComponent implements OnInit, OnDestroy {
  showModal:boolean=false;
  idPrice:any;
  pricesSelected:Price[]=[]

  private isShow$!:Subscription;
  private idPrice$!:Subscription;
  private selecteds$!:Subscription;

  constructor(private store:Store<appState>, private priceV:PriceValidator) {}

  ngOnInit(): void {
    this.isShow$=this.store.select('options','deletePrice', 'isShow').subscribe(isShow => {
      this.showModal = isShow;
    });

    this.idPrice$=this.store.select('options','deletePrice', 'idPrice').subscribe(idPrice => {
      this.idPrice = idPrice;
    });

    this.selecteds$=this.store.select('price','priceBoard','pricesSelected').subscribe(selecteds => {
      this.pricesSelected = selecteds;
    });
  }

  ngOnDestroy(): void {
    this.selecteds$.unsubscribe();
    this.isShow$.unsubscribe();
    this.idPrice$.unsubscribe();
  }

  confirm(){
    this.deletePrice();
    this.close();
  }

  noConfirm(){
    this.close();
  }

  close(){
    this.store.dispatch(setShowDeletePrice({isShow:false, idPrice:''}));
  }

  getTextOfModal(){
    if(this.idPrice){
      return '¿Querés eliminar la condición seleccionada?';
    }
    return `¿Querés eliminar ${this.pricesSelected.length<=1? 'la condición seleccionada': 
    'las '+ this.pricesSelected.length+' condiciones seleccionadas'}?`;
  }

  deletePrice(){
    if(this.pricesSelected.length>0 && !this.idPrice){
      this.pricesSelected.forEach(price => {
        this.priceV.deletePrice(price._id);
      });
    } else {
      this.priceV.deletePrice(this.idPrice)
    }
    
    this.store.dispatch(setPricesSelected({prices:[]}))
  }
}