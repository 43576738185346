import { PLACE_OF_DELIVERY_CONST, PLACE_OF_DELIVERY_IBM_CONST } from "./place-of-delivery";

export const NUTRIEN_CUIT='000030681490465'

export const COMMODITIES_CODE=722;
export const COMMODITIES_CODE_SELLER=2249;
export const HUGHES_CODE_BUYER=641;
export const HUGHES_CODE_SELLER=40400;

export const SANCOR_CODE_SELLER=3002;

export const CODE_FMC_QUIMICA=2928;

export const LDC_BUYER = 12
export const COFCO_BUYER = 13
export const CARGILL_BUYER = 4
export const VITERRA_BUYER = 134
export const BUNGE_BUYER = 97
export const ADM_BUYER = 332
export const MOLINOS_AGROS_BUYER  = 697
export const AMAGGI_BUYER = 596
export const CHS_BUYER = 594
export const GEAR_BUYER = 8
export const INGREDION_ARGENTINA_BUYER = 441;


//----------------- PLACE OF DELIVERY -----------------\\

//----------------- LDC -----------------\\
export const placeOfDeliveryLDC_SOJA_FABRICA = [
    PLACE_OF_DELIVERY_IBM_CONST.TIMBUES,
    PLACE_OF_DELIVERY_IBM_CONST.GENERAL_LAGOS,
    PLACE_OF_DELIVERY_IBM_CONST.RIO_CUARTO ]
    
export const placeOfDeliveryLDC_SOJA_CAMARA = [
    PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA]

export const placeOfDeliveryLDC_TRIGO=[
PLACE_OF_DELIVERY_IBM_CONST.GENERAL_LAGOS,	
PLACE_OF_DELIVERY_IBM_CONST.TIMBUES,	
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,	
PLACE_OF_DELIVERY_IBM_CONST.NAVARRO,	
PLACE_OF_DELIVERY_IBM_CONST.AVELLANEDA,	
PLACE_OF_DELIVERY_IBM_CONST.CHABAS
]
export const placeOfDeliveryLDC_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.GENERAL_LAGOS,	
PLACE_OF_DELIVERY_IBM_CONST.TIMBUES,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA
]
export const placeOfDeliveryLDC_CEBADA_FORRAJERA=[PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA]
//----------------------------------------\\

//----------------- COFCO -----------------\\
export const placeOfDeliveryCOFCO_SOJA=[
PLACE_OF_DELIVERY_IBM_CONST.TIMBUES,	
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.JUNIN]

export const placeOfDeliveryCOFCO_TRIGO_97_5=[
    PLACE_OF_DELIVERY_IBM_CONST.TIMBUES,	
    PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
    PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,	
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA
]

export const placeOfDeliveryCOFCO_TRIGO_95=[ PLACE_OF_DELIVERY_IBM_CONST.LIMA ]

export const placeOfDeliveryCOFCO_MAIZ_97_5=[
PLACE_OF_DELIVERY_IBM_CONST.TIMBUES,	
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,	
PLACE_OF_DELIVERY_IBM_CONST.LIMA
]
export const placeOfDeliveryCOFCO_MAIZ_95=[ PLACE_OF_DELIVERY_IBM_CONST.GUARDIA_ESCOLTA ]	

export const placeOfDeliveryCOFCO_SORGO=[
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.PUNTA_ALVEAR
]
export const placeOfDeliveryCOFCO_GIRASOL=[
PLACE_OF_DELIVERY_IBM_CONST.JUNIN,	
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN
]
export const placeOfDeliveryCOFCO_CEBADA_FORRAJERA=[
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA
]
//----------------------------------------\\

//----------------- CARGILL -----------------\\
export const placeOfDeliveryCARGILL_SOJA_FABRICA=[
    PLACE_OF_DELIVERY_IBM_CONST.COMPLEJO_APG,
    PLACE_OF_DELIVERY_IBM_CONST.DIAMANTE,	
    PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
    PLACE_OF_DELIVERY_IBM_CONST.GENERAL_PICO,	
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
    PLACE_OF_DELIVERY_IBM_CONST.PUNTA_ALVEAR
]
export const placeOfDeliveryCARGILL_SOJA_CAMARA=[ PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA ]

export const placeOfDeliveryCARGILL_TRIGO_CAMARA=[
PLACE_OF_DELIVERY_IBM_CONST.PUNTA_ALVEAR,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.DIAMANTE,
PLACE_OF_DELIVERY_IBM_CONST.AMERICA
]
export const placeOfDeliveryCARGILL_TRIGO_GRADO_2=[ PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA ]

export const placeOfDeliveryCARGILL_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.PUNTA_ALVEAR,
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.DIAMANTE,
PLACE_OF_DELIVERY_IBM_CONST.LA_CARLOTA]

export const placeOfDeliveryCARGILL_GIRASOL=[
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
PLACE_OF_DELIVERY_IBM_CONST.QUEQUEN,
PLACE_OF_DELIVERY_IBM_CONST.RECONQUISTA]

export const placeOfDeliveryCARGILL_SORGO=[
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.PUNTA_ALVEAR]

export const placeOfDeliveryCARGILL_CEBADA_FORRAJERA_97_5=[ PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA ]
export const placeOfDeliveryCARGILL_CEBADA_FORRAJERA_90=[ PLACE_OF_DELIVERY_IBM_CONST.VILLA_CONSTITUCION ]

export const placeOfDeliveryCARGILL_CEBADA_CERVECERA=[
PLACE_OF_DELIVERY_IBM_CONST.VILLA_CONSTITUCION,
PLACE_OF_DELIVERY_IBM_CONST.CASCALLARES,
PLACE_OF_DELIVERY_IBM_CONST.ARRECIFES,
PLACE_OF_DELIVERY_IBM_CONST.ORTIZ_BASUALDO,
PLACE_OF_DELIVERY_IBM_CONST.DIAZ_STA_FE]
//----------------------------------------\\

//----------------- VITERRA -----------------\\

export const placeOfDeliveryVITERRA_SOJA_FABRICA=[
PLACE_OF_DELIVERY_IBM_CONST.TERMINAL_DE_EMBARQUE,
PLACE_OF_DELIVERY_IBM_CONST.RENOVA_TIMBUES,
PLACE_OF_DELIVERY_IBM_CONST.DAIREAUX,
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA_QQN]
export const placeOfDeliveryVITERRA_SOJA_CAMARA=[ 
    PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
    PLACE_OF_DELIVERY_IBM_CONST.GENERAL_VILLEGAS
]

export const placeOfDeliveryVITERRA_TRIGO_97_5=[ PLACE_OF_DELIVERY_IBM_CONST.RENOVA_TIMBUES ]
export const placeOfDeliveryVITERRA_TRIGO_95=[ PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA ]

export const placeOfDeliveryVITERRA_MAIZ=[
    PLACE_OF_DELIVERY_IBM_CONST.RENOVA_TIMBUES,
    PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA
]

export const placeOfDeliveryVITERRA_GIRASOL=[
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
    PLACE_OF_DELIVERY_IBM_CONST.DAIREAUX
]

export const placeOfDeliveryVITERRA_SORGO=[ PLACE_OF_DELIVERY_IBM_CONST.RENOVA_TIMBUES ]

export const placeOfDeliveryVITERRA_CEBADA_FORRAJERA=[
    PLACE_OF_DELIVERY_IBM_CONST.RENOVA_TIMBUES,
    PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA
]
//----------------------------------------\\

//------------------ BUNGE ------------------\\
export const placeOfDeliveryBUNGE_SOJA=[
    PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
    PLACE_OF_DELIVERY_IBM_CONST.RAMALLO,
    PLACE_OF_DELIVERY_IBM_CONST.AVIA_TERAI,
    PLACE_OF_DELIVERY_IBM_CONST.TANCACHA,
    PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
    PLACE_OF_DELIVERY_IBM_CONST.BANDERA,
    PLACE_OF_DELIVERY_IBM_CONST.PINEDO,
    PLACE_OF_DELIVERY_IBM_CONST.GENERAL_PAZ,
    PLACE_OF_DELIVERY_IBM_CONST.D_GALLO]

export const placeOfDeliveryBUNGE_TRIGO=[
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
PLACE_OF_DELIVERY_IBM_CONST.LABORDE]

export const placeOfDeliveryBUNGE_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.TANCACHA,
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA]

export const placeOfDeliveryBUNGE_SORGO=[ PLACE_OF_DELIVERY_IBM_CONST.RAMALLO ]

export const placeOfDeliveryBUNGE_GIRASOL=[
PLACE_OF_DELIVERY_IBM_CONST.TANCACHA,
PLACE_OF_DELIVERY_IBM_CONST.PINEDO,
PLACE_OF_DELIVERY_IBM_CONST.SAN_JERONIMO_SUR,
PLACE_OF_DELIVERY_IBM_CONST.AVIA_TERAI]

export const placeOfDeliveryBUNGE_CEBADA_FORRAJERA=[
PLACE_OF_DELIVERY_IBM_CONST.RAMALLO,
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA]
//----------------------------------------\\

//----------------- ADM -----------------\\
export const placeOfDeliveryADM_SOJA_TRIGO_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.ROSARIO,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.ARROYO_SECO,
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA]

export const placeOfDeliveryADM_SORGO=[
PLACE_OF_DELIVERY_IBM_CONST.ROSARIO,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.ARROYO_SECO]
export const placeOfDeliveryADM_CEBADA_CERVECERA=[ PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA ]
//----------------------------------------\\

//-------------- MOLINOS AGROS --------------\\
export const placeOfDeliveryMOLINOS_AGROS_SOJA_TRIGO_GIRASOL=[ PLACE_OF_DELIVERY_IBM_CONST.SAN_LORENZO ]

export const placeOfDeliveryMOLINOS_AGROS_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.SAN_LORENZO, PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN]

//----------------------------------------\\

//------------------ AMAGGI ------------------\\
export const placeOfDeliveryAMAGGI_SOJA_TRIGO=[
PLACE_OF_DELIVERY_IBM_CONST.UP_RIVER,
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,	
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,	
PLACE_OF_DELIVERY_IBM_CONST.TERM_LAS_PALMAS]

export const placeOfDeliveryAMAGGI_SORGO=[ PLACE_OF_DELIVERY_IBM_CONST.UP_RIVER ]

export const placeOfDeliveryAMAGGI_CEBADA=[ PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA ]

//----------------------------------------\\

//------------------ CHS ------------------\\
export const placeOfDeliveryCHS_SOJA=[
PLACE_OF_DELIVERY_IBM_CONST.BAHIA_BLANCA,
PLACE_OF_DELIVERY_IBM_CONST.QUEQUEN,
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
PLACE_OF_DELIVERY_IBM_CONST.TERMINAL_Pto_DEL_GUAZU]

export const placeOfDeliveryCHS_TRIGO=[
PLACE_OF_DELIVERY_IBM_CONST.TERMINAL_Pto_DEL_GUAZU,
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
PLACE_OF_DELIVERY_IBM_CONST.SAN_LORENZO,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,	
PLACE_OF_DELIVERY_IBM_CONST.ROSARIO]

export const placeOfDeliveryCHS_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA,
PLACE_OF_DELIVERY_IBM_CONST.ROSARIO,
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.SAN_LORENZO]
//----------------------------------------\\

//------------------ GEAR ------------------\\
export const placeOfDeliveryGEAR_TRIGO=[ PLACE_OF_DELIVERY_IBM_CONST.CHACABUCO ]

export const placeOfDeliveryGEAR_MAIZ=[
PLACE_OF_DELIVERY_IBM_CONST.NECOCHEA, 
PLACE_OF_DELIVERY_IBM_CONST.SAN_LORENZO]

export const placeOfDeliveryGEAR_SORGO=[
PLACE_OF_DELIVERY_IBM_CONST.SAN_MARTIN,
PLACE_OF_DELIVERY_IBM_CONST.VILLA_CONSTITUCION,
PLACE_OF_DELIVERY_IBM_CONST.ZARATE]
//----------------------------------------\\

//------------- INGREDION ARGENTINA --------------\\
/*export const placeOfDeliveryINGREDION_ARGENTINA_MAIZ=[
    PLACE_OF_DELIVERY_IBM_CONST.BARADERO,
    PLACE_OF_DELIVERY_IBM_CONST.CHACABUCO]*/
//----------------------------------------\\