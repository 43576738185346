import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setAllViewsOrderExchange, setFilterOrderExchange, setIndexViewSelectedOrderExchange, setViewSelectedOrderExchange } from 'src/app/redux/actions/exchange.action';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { VIEWS_SELECTED_CONST, VIEW_ORDER_EXCHANGE_ALL } from 'src/app/shared/const/views.const';
import { getViewsByTag } from 'src/app/shared/data.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ViewOrderExchange } from 'src/app/shared/interfaces/views.interface';
import { OrderString } from 'src/app/shared/interfaces/order.interface';
import { ViewSectionOrderBasic } from '../view-order-section';


@Component({
  selector: 'app-views-section-exchange',
  templateUrl: './views-section-exchange.component.html',
  styleUrls: ['./../views-section.scss']
})
export class ViewsSectionExchangeComponent extends ViewSectionOrderBasic implements OnInit, OnDestroy {
  override views: ViewOrderExchange[] = []; //It is extracted from the LocalStorage
  override VIEW_ALL: ViewOrderExchange = VIEW_ORDER_EXCHANGE_ALL;

  constructor(store:Store<appState>) {
    super(store);    
  }

  override setSubscriptions(){
    this.views$ = this.store.select('exchange', 'exchangeBoard', 'viewsOfExchangeBoard', 'views').subscribe((views) => {
      this.views = this.filterViews(views);
    });

    this.viewSelected$ = this.store.select('exchange', 'exchangeBoard', 'viewsOfExchangeBoard', 'viewSelected').subscribe((viewSelected) => {
      this.viewSelected = viewSelected;
    });

    this.index$ = this.store.select('exchange', 'exchangeBoard', 'viewsOfExchangeBoard', 'indexViewSelected').subscribe((indexViewSelected) => {
      this.editViewIdentificator = indexViewSelected;
    });
  }

  override rememberViewsOfLocalStorage() {
    const views = getViewsByTag(localStorageNames.viewExchange);
    this.setViewAll(views)
  }

  override getViewSelectedConst(): 'orderExchangeBoard' {
    return VIEWS_SELECTED_CONST.ORDER_EXCHANGE_BOARD
  }

  override getLocalStorageName(): string {
    return localStorageNames.viewExchange;
  }

  override selectViewDefault(){
    this.isSelectedMyOrders=true;
    this.viewMyOrders();
  }

  override setViewAll(views:any[]){
    this.store.dispatch(setAllViewsOrderExchange({ views: views }));
  }

  override setIndexViewSelected(index:number){
    this.store.dispatch(setIndexViewSelectedOrderExchange({index: index}));
  }

  override setViewSelected(view:ViewOrderExchange){
    this.store.dispatch(setViewSelectedOrderExchange({view:view}));
  }

  override setFilter(filter: OrderString): void {
    this.store.dispatch(setFilterOrderExchange({orderString: filter}));
  }
}
