import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterOrderComponent } from './filters.component';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EditSvgModule } from 'src/app/shared/styles/svg/edit/edit-svg.module';
import { FilterSvgModule } from 'src/app/shared/styles/svg/filter/filter-svg.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NumberInputModule } from '../../inputs/number/number.module';
import { DateCalendarInlineModule } from '../../inputs/date-calendar-inline/date-calendar-inline.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    FilterOrderComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatDatepickerModule,
    NumberInputModule,
    //SVG
    EditSvgModule,
    FilterSvgModule,
    PipesModule,

    DateCalendarInlineModule,
    ScrollingModule

  ], exports: [
    FilterOrderComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class FilterOrderModule { }
