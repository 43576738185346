<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(10)">Entrega Silo Bolsas</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="isEnabled()" (change)="setOptionOpenWithCheck($event,10)" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="field">
            <span class="field-title">Cantidad<span class="asterisk">*</span></span>

            <number-input [initialPlaceHolder]="'Ingresar cantidad'" [value]="deliveryInSiloBolsas.quantity" 
            (valueOut)="setQuantity($event)"></number-input>
        </div>

        <div class="field">
            <span class="field-title">Marca<span class="asterisk">*</span></span>
            <select-input [array]="[1,2,3,4]" [placeHolder]="'Selecciona una marca'" 
            [value]="MARCA[deliveryInSiloBolsas.brand]" [OBJECT]="MARCA" (valueOut)="setValueWithEvent($event, 'brand')"></select-input>
        </div>

        <div class="field" style="margin-bottom:16px;">
            <span class="field-title">Tamaño<span class="asterisk">*</span></span>
            <select-input [value]="TAMAIN[deliveryInSiloBolsas.size]" [array]="[1,2,3]" 
            [placeHolder]="'Selecciona un tamaño'" [OBJECT]="TAMAIN" (valueOut)="setValueWithEvent($event, 'size')"></select-input>
        </div>

    </div>
</div>