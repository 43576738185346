import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualOrderSvgComponent } from './manual-order/manual-order.component';
import { MessageSvgComponent } from './message/message.component';
import { TruckSvgComponent } from './truck/truck.component';
import { WarningSvgComponent } from './warning/warning.component';
import { EditFromBoardSvgComponent } from './edit-from-board/edit-from-board.component';
import { ParametrizerObservationSvgComponent } from './parametrizer-observation/parametrizer-observation.component';

@NgModule({
  declarations: [
    ManualOrderSvgComponent,
    MessageSvgComponent,
    TruckSvgComponent,
    WarningSvgComponent,
    EditFromBoardSvgComponent,
    ParametrizerObservationSvgComponent
  ],
  imports: [CommonModule],
  exports: [
    ManualOrderSvgComponent,
    MessageSvgComponent,
    TruckSvgComponent,
    WarningSvgComponent,
    EditFromBoardSvgComponent,
    ParametrizerObservationSvgComponent
  ],
})
export class ObservationsOrderSvgModule {}
