<svg  class="svg-menu flex" [ngClass]="{'enabled': active, 'disabled': !active}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_155_25244)">
    <path d="M20.2498 13.9717L17.7823 16.4392V5.75171C17.7867 4.6396 17.4685 3.55005 16.8665 2.61498C16.2645 1.6799 15.4043 0.939392 14.3901 0.483084C13.3759 0.026774 12.2512 -0.125768 11.1521 0.0439256C10.053 0.213618 9.02661 0.69827 8.19726 1.43921C8.05791 1.57308 7.97556 1.75559 7.96746 1.94867C7.95936 2.14175 8.02611 2.33051 8.15376 2.47559C8.28148 2.62066 8.46021 2.71086 8.65273 2.72737C8.84526 2.74388 9.03673 2.68544 9.18726 2.56421C9.80098 2.02309 10.5577 1.67049 11.3668 1.5487C12.1758 1.42691 13.0028 1.54109 13.7486 1.87757C14.4944 2.21404 15.1273 2.75852 15.5713 3.44568C16.0154 4.13285 16.2519 4.93354 16.2523 5.75171V16.4392L13.7773 13.9717C13.634 13.8868 13.4665 13.8521 13.3013 13.8729C13.1361 13.8937 12.9825 13.9689 12.8647 14.0867C12.747 14.2044 12.6718 14.358 12.651 14.5233C12.6301 14.6885 12.6649 14.8559 12.7498 14.9992L16.4998 18.7492C16.5682 18.8193 16.6498 18.8754 16.7398 18.9142C16.8331 18.9498 16.9324 18.9675 17.0323 18.9667C17.1298 18.9679 17.2266 18.9501 17.3173 18.9142C17.4073 18.8754 17.4889 18.8193 17.5573 18.7492L21.3073 14.9992C21.4041 14.8534 21.4469 14.6784 21.4282 14.5044C21.4096 14.3304 21.3307 14.1683 21.2052 14.0463C21.0796 13.9244 20.9154 13.8502 20.7409 13.8366C20.5665 13.8229 20.3927 13.8707 20.2498 13.9717Z" fill="#2E3B4C"/>
    <path d="M6.24686 7.55851V18.246C6.24764 19.3525 6.56747 20.4352 7.16805 21.3644C7.76862 22.2937 8.62445 23.03 9.6329 23.4852C10.6414 23.9404 11.7597 24.095 12.8539 23.9307C13.9481 23.7663 14.9716 23.2899 15.8019 22.5585C15.8807 22.495 15.9459 22.4162 15.9934 22.3268C16.041 22.2374 16.07 22.1393 16.0787 22.0385C16.0873 21.9376 16.0755 21.836 16.0438 21.7399C16.0121 21.6437 15.9613 21.5549 15.8944 21.479C15.8275 21.4029 15.746 21.3413 15.6546 21.2976C15.5632 21.254 15.464 21.2293 15.3628 21.2251C15.2617 21.2208 15.1607 21.2371 15.066 21.2729C14.9713 21.3088 14.8848 21.3634 14.8119 21.4335C14.1982 21.9746 13.4415 22.3272 12.6324 22.449C11.8233 22.5708 10.9963 22.4567 10.2505 22.1201C9.50472 21.7837 8.87187 21.2392 8.4278 20.552C7.98372 19.8649 7.74725 19.0642 7.74687 18.246V7.55851L10.2219 10.026C10.3624 10.1657 10.5525 10.2441 10.7506 10.2441C10.9488 10.2441 11.1388 10.1657 11.2794 10.026C11.4061 9.88313 11.4736 9.69728 11.4681 9.50633C11.4625 9.31538 11.3843 9.13373 11.2494 8.99851L7.49936 5.24851C7.36027 5.11773 7.17653 5.04492 6.98561 5.04492C6.79469 5.04492 6.61096 5.11773 6.47186 5.24851L2.72186 8.99851C2.63701 9.14176 2.60225 9.30923 2.62307 9.47446C2.64389 9.63968 2.7191 9.79328 2.83685 9.91103C2.9546 10.0288 3.1082 10.104 3.27341 10.1248C3.43863 10.1456 3.60608 10.1108 3.74936 10.026L6.24686 7.55851Z" fill="#2E3B4C"/>
    </g>
    <defs>
    <clipPath id="clip0_155_25244">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
</svg>