import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionComponent } from './commission.component';

@NgModule({
  declarations: [
    CommissionComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    CommissionComponent
  ]
})
export class CommissionModule { }
