<div class="background-sin-color">
<svg class="svg-24 hover-enabled" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#DCDCDC"/>
    <path d="M8.46094 9.1249H8.62969L8.625 8.94443C8.625 8.94209 8.625 8.9374 8.625 8.93506C8.62734 8.00693 9.38438 7.25225 10.3125 7.24756H15.375C16.4062 7.2499 17.2453 8.09131 17.25 9.12256V14.1851C17.2477 15.1132 16.4906 15.8679 15.5625 15.8726H15.375V16.0366C15.375 16.9812 14.6086 17.7476 13.6641 17.7476H8.46094C7.51641 17.7476 6.75 16.9812 6.75 16.0366V10.8358C6.75 9.89131 7.51641 9.1249 8.46094 9.1249ZM15.5625 15.1249C16.0781 15.1226 16.5 14.703 16.5 14.1874V9.1249C16.4977 8.50615 15.9937 7.9999 15.375 7.9999H10.3125C9.79688 8.00225 9.37734 8.41943 9.375 8.93506L9.37969 9.1249H13.6641C14.6086 9.1249 15.375 9.89131 15.375 10.8358V15.1249H15.5625ZM7.5 16.039C7.5 16.5687 7.93125 16.9999 8.46094 16.9999H13.6641C14.1937 16.9999 14.625 16.5687 14.625 16.039V10.8358C14.625 10.3062 14.1937 9.8749 13.6641 9.8749H8.46094C7.93125 9.8749 7.5 10.3062 7.5 10.8358V16.039Z" fill="white"/>
    <path d="M9.1875 13.0625H10.6875V11.5625C10.6875 11.3562 10.8563 11.1875 11.0625 11.1875C11.2687 11.1875 11.4375 11.3562 11.4375 11.5625V13.0625H12.9375C13.1438 13.0625 13.3125 13.2313 13.3125 13.4375C13.3125 13.6437 13.1438 13.8125 12.9375 13.8125H11.4375V15.3125C11.4375 15.5188 11.2687 15.6875 11.0625 15.6875C10.8563 15.6875 10.6875 15.5188 10.6875 15.3125V13.8125H9.1875C8.98125 13.8125 8.8125 13.6437 8.8125 13.4375C8.8125 13.2313 8.98125 13.0625 9.1875 13.0625Z" fill="white"/>
</svg>

<div class="tooltip down row-center w-50">
    <span>Duplicar</span>
</div>
</div>