<div class="form" #formulario>
    <div class="buttons" id="top-of-form" *ngIf="isEditedOrderFromBoard">
        <button [ngClass]="{'selected':numOfStep==1}" class="step" (click)="changeStep(1, formulario)">Paso 1</button>
        <button [ngClass]="{'selected':numOfStep==2}" class="step" (click)="changeStep(2, formulario)">Paso 2</button>
        <button [ngClass]="{'selected':numOfStep==3}" class="step" (click)="changeStep(3, formulario)">Paso 3</button>
        <button [ngClass]="{'selected':numOfStep==4}" class="step" (click)="changeStep(4, formulario)" *ngIf="showExchangeOptions || isGenerateToFix">Paso 4</button>
        <button [ngClass]="{'selected':numOfStep==5}" class="step" (click)="changeStep(5, formulario)" *ngIf="showExchangeOptions && isGenerateToFix">Paso 5</button>

        <div class="rectangle step-{{numOfStep}}"></div>
    </div>

    <div class="buttons button-text-2-lines" *ngIf="!isEditedOrderFromBoard">
        <span class="text-2-lines" *ngIf="numOfStep!=4">Completa la siguiente información de la orden de venta</span>
        <span class="text-2-lines" *ngIf="numOfStep==4" >Completa la siguiente información de la contrapartida</span>
    </div>

    <div class="delete-all gap-8" [ngClass]="{'delete-all-2-lines': !isEditedOrderFromBoard}">
        <span (click)="deleteStep(numOfStep)">Borrar paso {{numOfStep}}</span>
    </div>
    
    <div class="paso paso1" [ngClass]="{'visible': numOfStep==1, 'invisible': numOfStep!=1 }">
        <app-seller-name [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-seller-name>
        <app-place-of-origin [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-place-of-origin>
        <app-broker [fieldIdentificator]="'order'"></app-broker>
        <app-how-sell [inProcessRucaAndSisa]="inProcessRucaAndSisa" [isInvalidRucaAndSisa]="isInvalidRucaAndSisa"
        [isInvalid]="isInvalid" [failedConnection]="failedConnection" [inProcessInvalid]="inProcessInvalid" 
        [isExchanger]="isExchangeOrder" [rucaAndSisa]="rucaAndSisa" [howSellValidation]="howSellValidation" 
        [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-how-sell>
        <app-business-particularities [fieldIdentificator]="'order'"></app-business-particularities>
        <app-buyer-order  [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-buyer-order>
        <app-tons [fieldIdentificator]="'order'"(focusEvent)="focusField($event, formulario)" ></app-tons> <!-- (tab)="changeStep(2)" --> 
        <app-quotas [fieldIdentificator]="'order'" *ngIf="isConDescarga(order)" (focusEvent)="focusField($event, formulario)"></app-quotas>
        <app-parameterized-observations [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-parameterized-observations>
        
        <!-- Si tiene valid Until, lo mostramos en el paso 1, sino lo mostramos en el paso 2-->
        <app-delivery-period *ngIf="haveValidUntil && !isEditedOrderFromBoard" [fieldIdentificator]="'order'" [showCross]="false" [isGenerateToFix]="isGenerateToFix"></app-delivery-period>

        <button class="exchange-button" (click)="setExchangeOptions(true)" *ngIf="isExchangeOrder && !showExchangeOptions && !isEditedOrderFromBoard && !isSyngentaAgroAvc">Completar datos de la contrapartida</button>
    </div>
    <div class="paso paso2" [ngClass]="{'visible': numOfStep==2, 'invisible': numOfStep!=2 }">
        <app-business-type [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-business-type>

        <app-delivery-period *ngIf="!haveValidUntil || isEditedOrderFromBoard" [fieldIdentificator]="'order'" [showCross]="false" [isGenerateToFix]="isGenerateToFix"></app-delivery-period>

        <app-product-name [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-product-name>
        <app-price *ngIf="!isGenerateToFix" [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-price>
        <app-type-coin *ngIf="!isGenerateToFix" [fieldIdentificator]="'order'"></app-type-coin>
        <app-place-of-delivery [fieldIdentificator]="'order'" 
        [requirePort]="order.price.placeOfDelivery.zone>7"
        [incompleteRequirePort]="requirePort()" 
        (focusEvent)="focusField($event, formulario)"></app-place-of-delivery>
    </div>
    <div class="paso paso3"[ngClass]="{'visible': numOfStep==3, 'invisible': numOfStep!=3 }">
        <!--(tab)="changeStep(3, formulario)"-->
        <app-harvest [fieldIdentificator]="'order'" (focusEvent)="focusField($event, formulario)"></app-harvest>
        <app-quality [fieldIdentificator]="'order'" (addQuality)="showQualities($event)" (focusEvent)="focusField($event, formulario)"></app-quality>
        <app-quality-particularities [fieldIdentificator]="'order'" *ngIf="showPartQuality || order.price.qualityIBM>0" (focusEvent)="focusField($event, formulario)"></app-quality-particularities>
        <app-way-pay [fieldIdentificator]="'order'" [isGenerateToFix]="isGenerateToFix" (focusEvent)="focusField($event, formulario)"></app-way-pay>
        <app-expiration [fieldIdentificator]="'order'" *ngIf="!isWayPayInvalidToExpiration(order?.price?.wayPay) && !isGenerateToFix"></app-expiration>
        <app-pesification [fieldIdentificator]="'order'" *ngIf="isInDolars(order) || isGenerateToFix"></app-pesification>
        <app-grouper-code *ngIf="isOperator && !isGenerateToFix" [fieldIdentificator]="'order'"></app-grouper-code>
        
        
        <button class="exchange-button" (click)="setExchangeOptions(true)" *ngIf="isExchangeOrder && !showExchangeOptions && isEditedOrderFromBoard && !isSyngentaAgroAvc && !isGenerateToFix">Completar datos de la contrapartida</button>
    </div>
    
    <div class="paso paso4" [ngClass]="{'visible': numOfStep==4, 'invisible': numOfStep!=4 }" *ngIf="isGenerateToFix">
        <app-condition-type [fieldIdentificator]="'order'"></app-condition-type>
        <app-to-fix-indicators [fieldIdentificator]="'order'" [indicators]="order?.price?.indicators ?? []" [conditionType]="order?.price?.conditionType"></app-to-fix-indicators>
        <app-grouper-code *ngIf="isOperator && isGenerateToFix" [fieldIdentificator]="'order'"></app-grouper-code>

        <button class="exchange-button" (click)="setExchangeOptions(true)" *ngIf="isExchangeOrder && !showExchangeOptions && isEditedOrderFromBoard && !isSyngentaAgroAvc && isGenerateToFix">Completar datos de la contrapartida</button>
    </div>

    <div class="paso paso4"  *ngIf="showExchangeOptions" [ngClass]="{'visible': numOfStep==getNumMaxOfStep(), 'invisible': numOfStep!=getNumMaxOfStep() }">
        <app-seller-name  [fieldIdentificator]="'exchange'" (focusEvent)="focusField($event, formulario)"></app-seller-name>
        <app-how-sell [fieldIdentificator]="'exchange'" (focusEvent)="focusField($event, formulario)"></app-how-sell>
        <app-place-of-origin [fieldIdentificator]="'exchange'" (focusEvent)="focusField($event, formulario)"></app-place-of-origin>
        
        <app-price *ngIf="!isGenerateToFix" [fieldIdentificator]="'exchange'" (focusEvent)="focusField($event, formulario)" ></app-price>
        <app-type-coin *ngIf="!isGenerateToFix" [fieldIdentificator]="'exchange'"></app-type-coin>

        <app-tons [fieldIdentificator]="'exchange'" (focusEvent)="focusField($event, formulario)"></app-tons>
        <app-agreed-discount [fieldIdentificator]="'exchange'" [defaultValue]="agreedDiscountDefault" [showMessageNT]="isNideraOSemillas()"></app-agreed-discount>
        <app-way-pay [fieldIdentificator]="'exchange'" [wayPayDefault]="wayPayDefault"></app-way-pay>
        <app-bonification-exchange [fieldIdentificator]="'exchange'" (focusEvent)="focusField($event, formulario)"></app-bonification-exchange>
        
    </div>

    <div class="actions column-left gap-8" *ngIf="!isEditedOrderFromBoard">
        <button class="filled-button not-visible-in-950" (click)="confirmOrder(formulario)" 
        *ngIf="!isDuplicateOrder" [ngClass]="{'disabled': isDisabled}">Generar orden</button>

        <button class="filled-button visible-in-950" [disabled]="isDisabled" [ngClass]="{'disabled': isDisabled}"
        (click)="applyButtonEmit(true)" *ngIf="!isDuplicateOrder">
            Aplicar
        </button>

        <p class="info">Para modificar la información proveniente desde el tablero de condiciones seleccioná 
            <span class="underlined modify" (click)="editOrder()">modificar.</span></p>
            
        <!--<span class="message-error">{{messagePxQ}}</span>-->
    </div>        

    <div class="button-container column-left gap-8" *ngIf="isEditedOrderFromBoard">
        <button class="not-filled-button" (click)="changeStep(numOfStep+1, formulario)" 
        *ngIf="getNumMaxOfStep()!=numOfStep">Siguiente</button>

        <button class="filled-button not-visible-in-950" (click)="confirmOrder(formulario)" 
        *ngIf="getNumMaxOfStep()==numOfStep && !isDuplicateOrder"
            [ngClass]="{'disabled': isDisabledOrder(showExchangeOptions)}">Generar orden</button>

        <button class="filled-button visible-in-950" [disabled]="isDisabledOrder(showExchangeOptions)" 
        [ngClass]="{'disabled': isDisabledOrder(showExchangeOptions)}"
        *ngIf="getNumMaxOfStep()==numOfStep && !isDuplicateOrder"
        (click)="applyButtonEmit(true)">
            Aplicar
        </button>

        <!--<span class="message-error">{{messagePxQ}}</span>-->
    </div>
        
</div>
