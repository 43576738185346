import { Pipe, PipeTransform } from '@angular/core';
import { PriceString } from '../../interfaces/price.interface';
import * as dict from '../../dict/dict';
import { priceTags, typeCoins } from '../../const/prices.const';
import { PRODUCT_NAMES } from '../../dict/productName';
import { typeBusiness } from '../../dict/typeBusiness';
import { wayPay } from '../../dict/wayToPay';
import { buyers } from '../../dict/buyers';

const PRODUCT: any = PRODUCT_NAMES;

const BUSINESS_TYPE: any = typeBusiness;
const QUALITY: any = dict.quality;
const STATUS: any = dict.priceState;
const PLACE_OF_DELIVERY: any = dict.puertos;
const WAY_TO_PAY: any = wayPay;

@Pipe({
  name: 'filterByPrice'
})
export class FilterByPricePipe implements PipeTransform {

  transform(list: any[], price: PriceString) {
    let arrayFinal: any[] = [];

    list.forEach(element => {
      if (filterPriceByPrice(element, price)) { arrayFinal.push(element);}
    })

    return arrayFinal;
  }
}

export function filterPriceByPrice(element: any, price: any): boolean {
  return elementContainObservations(element, price) &&
  elementContainCharacteristic(element, price, priceTags.productName, PRODUCT) &&
  elementContainCharacteristic(element, price, priceTags.typeBusiness, BUSINESS_TYPE) &&
  elementContainCharacteristic(element, price, priceTags.quality, QUALITY) &&
  elementContainCharacteristic(element, price, priceTags.status, STATUS) &&
  elementContainValues(element, price, priceTags.price) &&
  elementContainSubCharasteristic(element, price, priceTags.placeOfDelivery, priceTags.zone, PLACE_OF_DELIVERY) &&
  elementContainCharacteristic(element.wayPay, price, priceTags.wayPayName, WAY_TO_PAY) &&
  elementContainCharacteristicSimple(element?.deliveryPeriod, price?.deliveryPeriod, priceTags.startDate) &&
  elementContainCharacteristicSimple(element?.deliveryPeriod, price?.deliveryPeriod, priceTags.endDate) &&
  elementContainCharacteristicSimple(element?.pesificacion, price?.pesificacion, priceTags.startDate) &&
  elementContainCharacteristicSimple(element?.pesificacion, price?.pesificacion, priceTags.endDate) &&

  elementContainCharacteristicWithOutList(element.wayPay, price, priceTags.wayPayExpiration) &&

  elementContainCharacteristicWithOutList(element, price, priceTags.harvest) &&
  elementContainCharacteristicSimple(element, price, priceTags.priceId) &&
  elementContainHour(element, price, priceTags.hour) &&
  
  elementContainCharacteristic(element.observations, price, priceTags.buyer, buyers)
}


/*Given a label (representing a field of the structure) and two elements, 
it compares the attributes of the elements according to the label.*/
export function elementContainCharacteristicSimple(elementToCompare: any, value: any, tag: string) {
  return !value[tag] || elementToCompare[tag] == value[tag]
}

//It is a filter for a nested structure such as:
//Way to pay. Quality. Business Type.
export function elementContainSubCharasteristic(elementToCompare: any, value: any, tag: string, subTag: string, array: any): boolean {
  try {
    if (value[tag][subTag].length == 0) throw new Error();

    value[tag][subTag].forEach((element: any) => {
      if (element == array[elementToCompare[tag][subTag]].name) throw new Error()
    });
  } catch (err: any) {

    return true;
  }
  return false;
}

/*Given two elements,
  Returns if the element contains at least one field with the same value.*/
export function elementContainCharacteristic(elementToCompare: any, value: any, tag: string, array: any): boolean {
  try {
    if (value[tag].length == 0) throw new Error();
    value[tag].forEach((element: any) => {
      if (element == array[elementToCompare[tag]]) throw new Error()
    });
  } catch (err: any) {
    return true;
  }
  return false;
}

/* Given two elements and a label.
  The value that the element has in that label is searched for and if it matches (at least one), it returns true. */
function elementContainCharacteristicWithOutList(elementToCompare: any, value: any, tag: string) {
  try {
    if (value[tag].length == 0) throw new Error();
    value[tag].forEach((element: any) => {
      if (element == elementToCompare[tag]) throw new Error()
    });
  } catch (err: any) {
    return true;
  }
  return false;
}

//Given two items to compare (of type price) in its 'observations' field.
//The first element is the value that may or may not be in the future list.
//The second element is the fields that element should contain.
//Returns true if all the fields that the second has are included in the first.
function elementContainObservations(elementToCompare: any, value: any) {
    //2 elements.
    let trueCount = 0//One that counts the amount of 'true' the second element has.
    let trueCountMatch = 0//Another that counts the number of 'trues' that match.
    if (value?.observations?.isBussinesGravanz) {
      trueCount++;
      if (elementToCompare.observations.isBussinesGravanz == 1) trueCountMatch++;
    }
    if (value?.observations?.isFeatured) {
      trueCount++;
      if (elementToCompare.observations.isFeatured == 1) trueCountMatch++;
    }
    if (value?.observations?.isFixed) {
      trueCount++;
      if (elementToCompare.observations.isFixed == 1) trueCountMatch++;
    }
    if (value?.observations?.isMessage) {
      trueCount++;
      if (elementToCompare.observations.isMessage == 1) trueCountMatch++;
    }

    //If they are the same, it means that the first element contains all the 'trues' that the second element has.
    return trueCount == trueCountMatch
  }

//Given two elements, it compares if the first one is the same 
//type of currency and if the price is between two values.
function elementContainValues(elementToCompare: any, value: any, tag: string) {
  if (value?.price?.typeCoin == typeCoins.ALL) {
    return valueIsInTheRange(elementToCompare.price, value.price.start, value.price.end);
  }
  if (elementToCompare.typeCoin == value?.price?.typeCoin) {
    return valueIsInTheRange(elementToCompare.price, value.price.start, value.price.end);
  }
  return false
}

//Returns true if a value is between 2 others.
//In case the end is 0, it returns true.
//In case the start is bigger than the end, it returns false.
function valueIsInTheRange(value: number, start: number, end: number): boolean {
  const startN = Number(start);
  const endN = Number(end);
  if ((end == 0 && start == 0) || (end == undefined && start == undefined)) { return true };
  if (end == 0 || end == undefined) { return start <= value; };
  if (start > end) { return false };
  return start <= value && value <= end;
}

//Given two elements, 
//compare if they have the same hour (not minutes)
function elementContainHour(elementToCompare: any, value: any, tag: string) {
  if (value.hour == '' || value.hour == undefined) { return true; }
  const hour = elementToCompare.hour.substr(0, 2);
  return hour == value.hour
}