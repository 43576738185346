import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { setAllViewsO } from '../actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { saveViewDBFailed, saveViewDBSuccess, searchViewInDB, searchViewsFailed, searchViewsSuccess, updateViewDB } from '../actions/views.action';
import { ViewsService } from 'src/app/shared/services/views.service';
import { ViewOrder, ViewOrderExchange, ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { setAllViews } from '../actions/price.action';
import { setAllViewsCP } from '../actions/control-panel.action';
import { setAllViewsOrderExchange } from '../actions/exchange.action';
import { MAP_LOCAL_STORAGE_VIEW_NAME, MAP_VIEW_NAME_LOCAL_STORAGE } from 'src/app/shared/const/views.const';
import { setErrorStruct } from '../actions/options.action';

export interface InfoViewUser{
  email:string,
  viewOrder:ViewOrder[],
  viewPrice:ViewPrice[],
  viewExchange:ViewOrderExchange[],
  viewsControlPanel:ViewOrder[]
}

@Injectable()
export class ViewsEffects {
  constructor(private store:Store<appState>, private actions$: Actions, private viewSvc:ViewsService) {}
  
  searchViewDB$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(searchViewInDB),
      mergeMap((action: any) => 
        this.viewSvc.searchViews(action.email, action.name).pipe(
          map((info: InfoViewUser) => {
            if (info) {
              const copyInfo= JSON.parse(JSON.stringify(info));
              const arrayView= copyInfo[action.name] ?? [];
              localStorage.setItem(MAP_VIEW_NAME_LOCAL_STORAGE[action.name], JSON.stringify(arrayView));
  
              if(action.name==MAP_LOCAL_STORAGE_VIEW_NAME[localStorageNames.viewOrders]){
                return setAllViewsO({ views: arrayView });
              }
              
              if(action.name==MAP_LOCAL_STORAGE_VIEW_NAME[localStorageNames.viewPrices]){
                return setAllViews({ views: arrayView });
              }
              
              if(action.name==MAP_LOCAL_STORAGE_VIEW_NAME[localStorageNames.viewExchange]){
                return setAllViewsOrderExchange({ views: arrayView });
              }
              
              if(action.name==MAP_LOCAL_STORAGE_VIEW_NAME[localStorageNames.viewsControlPanel]){
                return setAllViewsCP({ views: arrayView });
              }
            }
            return searchViewsSuccess();
          }),
          catchError((error) => {
            console.error("Error al buscar vista:", error);
            return of(setErrorStruct({ error: {color: '#fff', isVisible:true, message: "No se pudo recuperar la vista."} }));
          })
        )
      )
    );
  });

  saveViewsDB$ = createEffect(() => {
  return this.actions$.pipe(
      ofType(updateViewDB),
      mergeMap((action: any) => {
        return this.viewSvc.updateViews(action.email, action.name, action.views).pipe(
          map((response) => {
            return saveViewDBSuccess();
          }),
          catchError((error) => {
            return of(saveViewDBFailed({message:error}));
          })
          )
      }))
  });

}
