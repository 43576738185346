import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusConst } from '../const/orders';
import * as dict from '../dict/dict';
import { teams } from '../dict/user';
import { OrderExchange } from '../interfaces/order-exchange';
import { Order, OrderString } from '../interfaces/order.interface';
import { Price } from '../interfaces/price.interface';
import { elementContainsNameOfCreator, stringEquals } from './filter/filter.by.order.pipe';
import { elementContainCharacteristic } from './filter/filter.by.price.pipe';

@Pipe({
  name: 'matchPricesByName'
})
export class MatchPricesByName implements PipeTransform {

  transform(priceList: Price[]): any[][] {
    const quantPrices=priceList.length;
    let arrayToReturn:any[]=[];
    for(let i=0; i<quantPrices; i++){
        let index=this.isNameInArray(arrayToReturn, priceList[i].productName);
        if(index != -1){
            arrayToReturn[index].push(priceList[i]);
        } else {
            arrayToReturn.push([priceList[i]])
        }
    }
    return arrayToReturn;
  }

  isNameInArray(array: any[], value:number){
    let index=-1;
    try{
        const quantElements=array.length;
        for(let i=0; i<quantElements; i++){
            if(array[i][0].productName == value){
                index=i;
                throw new Error();
            }
        }
        return index;
    } catch(err){
        return index;
    }
  }
}


@Pipe({
  name: 'matchOrdersByName'
})
export class MatchOrdersByName implements PipeTransform {

  transform(orderList: Order[], orderStatusSelected:number=-1, orderFilter:OrderString): any[][] {
    const quantOrders=orderList.length;
    let arrayToReturn:Order[][]=[];
  
    for(let i=0; i<quantOrders; i++){
      if(this.isStatusSelected(orderList[i].statusOrder, orderStatusSelected)){
        let index=this.isNameInArray(arrayToReturn, orderList[i].price.productName);
        if(index != -1){
            arrayToReturn[index].push(orderList[i]);
        } else {
            arrayToReturn.push([orderList[i]])
        }
      }
    }

    return arrayToReturn;
  }

  isNameInArray(array: Order[][], value:number){
    let index=-1;
    try{
        const quantElements=array.length;
        for(let i=0; i<quantElements; i++){
            if(array[i][0].price.productName == value){
                index=i;
                throw new Error();
            }
        }
        return index;
    } catch(err){
        return index;
    }
  }

  isStatusSelected(statusOrder:number, orderStatusSelected:number){

    if(orderStatusSelected==-1){
      return true;
    }

    if(statusOrder==orderStatusSelected){
      return true;
    } 

    if(orderStatusSelected==orderStatusConst.CONFIRM){
      if([orderStatusConst.CONTRACT, orderStatusConst.AUTORIZATION].includes(statusOrder)){
        return true;
      }
    }

    if(orderStatusSelected==orderStatusConst.PENDING){
      if([orderStatusConst.CHECK, orderStatusConst.OFERTED, orderStatusConst.SEND_TO_BROKE, orderStatusConst.OUT_OF_MARKET].includes(statusOrder)){
        return true;
      }
    }

    return false;
  }


}


@Pipe({
  name: 'matchExchangesByName'
})
export class MatchExchangesByName implements PipeTransform {

  transform(orderList: OrderExchange[], statusExchangeSelected:number=-1, orderFilter:OrderString): any[][] {
    const quantOrders=orderList.length;
    let arrayToReturn:OrderExchange[][]=[];

    for(let i=0; i<quantOrders; i++){
      if(this.isStatusSelected(orderList[i].exchange.status, statusExchangeSelected) && isOwnerInFilter(orderList[i], orderFilter)){
        let index=this.isNameInArray(arrayToReturn, orderList[i].price.productName);
        if(index != -1){
            arrayToReturn[index].push(orderList[i]);
        } else {
            arrayToReturn.push([orderList[i]])
        }
      }
    }
    return arrayToReturn;
  }

  isNameInArray(array: Order[][], value:number){
    let index=-1;
    try{
        const quantElements=array.length;
        for(let i=0; i<quantElements; i++){
            if(array[i][0].price.productName == value){
                index=i;
                throw new Error();
            }
        }
        return index;
    } catch(err){
        return index;
    }
  }

  isStatusSelected(statusOrder:number, orderStatusSelected:number){
    if(orderStatusSelected==-1){
      return true;
    }

    if(statusOrder==orderStatusSelected){
      return true;
    }

    return false;
  }
}


//Is owner filter in order 
function isOwnerInFilter(order:Order, orderFilter:OrderString){
  return elementContainCharacteristic(order.dataOfcreation.teamOfcreator, order, 'nroTeam', teams) &&
  elementContainsNameOfCreator(order.dataOfcreation.nameOfcreator, orderFilter)
}
