import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  template: '',
})
export class InputE implements OnInit {
  @Input() initialPlaceHolder = '';
  @Input() value: any;
  @Input() isIncomplete=false;
  @Input() isInvalid=false;
  @Output() valueOut = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
  }

  emit(value:any) {
    this.valueOut.emit(value);
  }

  setValue(value: any) {
    this.value = value;
  }

  getValue() {
    return this.value;
  }

  getIsInputCompleted() {
    return this.value != null; 
  }

  select(element: any): void {
    this.emit(element);
    this.setValue(element);
  }
}
