import { ValidatorChecker, haveLetter } from "./validator.checker";

export enum PeriodType {
  Monthly = 1,
  Quincenal = 2,
  Semanal = 3,
}

//Se podrían borrar los feriados viejos.
const FERIADOS=[
// '20-2-2023',
// '21-2-2023',
// '24-3-2023',
// '2-4-2023',
// '6-4-2023',
// '7-4-2023',
// '1-5-2023',
// '25-5-2023',
// '26-5-2023',
// '17-6-2023',
// '20-6-2023',
// '19-6-2023',
// '9-7-2023',
// '21-8-2023',
// '13-10-2023',
// '16-10-2023',
// '20-11-2023',
// '8-12-2023',
// '25-12-2023',
'1-1-2024',
'12-2-2024',
'13-2-2024',
'24-3-2024',
'28-3-2024',
'29-3-2024',
'1-4-2024',
'2-4-2024',
'1-5-2024',
'25-5-2024',
'15-6-2024',
'16-6-2024',
'17-6-2024',
'20-6-2024',
'21-6-2024',
'9-7-2024',
'17-8-2024',
'11-10-2024',
'12-10-2024',
'18-11-2024',
'8-12-2024',
'24-12-2024',
'25-12-2024',
'31-12-2024',
'1-1-2025',
'3-3-2025',
'4-3-2025',
'24-3-2025',
'2-4-2025',
'18-4-2025',
'1-5-2025',
'25-5-2025',
'20-6-2025',
'9-7-2025',
'17-8-2025',
'12-10-2025',
'20-11-2025',
'8-12-2025',
'24-12-2025',
'25-12-2025',
'31-12-2025',
'1-1-2026',
]

/**
 * The date format is incorrectly represented.
 * The DD-MM-YYYY format is used with the exception 
 * that if DD or MM is less than 10, the 0 is not added ahead. 
 * For this reason, several functions were created.
 * :( :( :(
 */

export class DateChecker {
  /**
   * Returns the date of today as a string with specific form
   * @returns {string} The date as a string with specific form
   */
  public getDate(): string {
    return getDate();
  }

  /**
   * Returns the today date in RFC3339 format
   * @returns (string) The date in RFC3339 format (YYYY-MM-DDTHH:MM:SSZ)
   */
  public getDateRFC3339(): string {
    const date = new Date();
    return this.getDateRFC3339ByDate(date);
  }

  /**
   * Given a date in Date Object format, returns the date in RFC3339 format  
   * @param {Date} date - The date to convert to RFC3339 format
   * @returns {string} The date in RFC3339 format (YYYY-MM-DDTHH:MM:SSZ)
   * */
  public getDateRFC3339ByDate(date: Date): string {
    return date.toISOString();
  }

  /**
   * Given a date in Date Object format, returns the date in RFC3339 format with the time set to 00:00:00
   * @param {Date} date - The date to convert to RFC3339 format
   * @returns {string} The date in RFC3339 format (YYYY-MM-DDT00:00:00Z)
   * */
  public getDateRFC3339ByDate2(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${day}T00:00:00Z`;
  }

  /**
   * Given a date in Date Object format, returns the date in YYYY-MM-DD format
   * @param {Date} date - The date to convert to YYYY-MM-DD format
   * @returns {string} The date in YYYY-MM-DD format
   */
  public getDateInYYYYMMDDformat(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
  }

  
  /**
   * Given a string representing a date with the format mentioned above, 
   * returns the date in Date Object
   * @param {string} dateString - The date in string format 
   * @returns {Date} The date in Date Object format
   */
  public stringToDate(dateString: string): Date {
    return stringToDate(dateString)
  }

  /**
   * Given a date in format: dd-mm-yyyy
   * Returns the day of the week being
   * Sunday -> 0
   * Saturday -> 6
   * @param {string} dateStr - The date in string format
   * @returns {number} The day of the week
   * */
  public getDay(dateStr: string) {
    const dateSplit = dateStr.split('-');
    const newFormatDateStr =
      dateSplit[1] + '-' + dateSplit[0] + '-' + dateSplit[2];
    const date = new Date(newFormatDateStr);

    return date.getDay();
  }

  /**
   * Gets the current day, takes its month and transforms it into text format
   * @returns {string} The month in text format
   */
  public getMonthString() {
    const month = new Date().getMonth();
    return getMonthString(month+1)
  }

  /**
   * Return the hour as a string with form: HH:MM
   * @returns {string} The hour as a string with form: HH:MM
   * */
  public getHour(): string {
    const date = new Date();
    const hour = date.getHours();
    const minutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return `${hour}:${minutes}`;
  }

  /**
   * Given a date with string format dd/mm/yyyy, returns the day in number
   *  @param {string} date - The date in string format
   * @returns {number} The day in number
   * */
  public getDateOfDateStr(date:string){
    const dateSplit = date.split('-');
    return Number(dateSplit[0]);
  }

  /**
   * Given a date with string format dd/mm/yyyy, returns the month in number
   * @param {string} date - The date in string format
   * @returns {number} The month in number
   * */
  public getMonthOfDateStr(date:string){
    const dateSplit = date.split('-');
    return Number(dateSplit[1]);
  }

  /** 
   * Given a date with string format dd/mm/yyyy, returns the year in number
   * @param {string} date - The date in string format
   * @returns {number} The year in number
   * */
  public getYearOfDateStr(date:string){
    const dateSplit = date.split('-');
    return Number(dateSplit[2]);
  }

  /**
   * Returns the current time in format:
   * 'DD - MMM - YYYY at HH:MM hrs' 
   * @returns {string} The current time in format: 'DD - MMM - YYYY at HH:MM hrs'
   * */
  public setHourFormat() {
    const date = new Date();
    return date.getDate() +' - ' +
      getMonthStringAbbreviated(date.getMonth() +1) +' - ' +
      date.getFullYear() +' a las ' + date.getHours() + ':' + date.getMinutes() + 'hrs.';
  }

  /**
   * Given a date in string format, returns the date of the end of the month in the same format.
   * @param {string} date - The date in string format
   * @returns {string} The date of the end of the month in the same format
   * */
  getDateEndOfMonth(date:string){
    const dateParsed=this.stringToDate(date);
    const newDate = new Date(dateParsed.getFullYear(), dateParsed.getMonth() + 1, 0);
    const dateToReturn= parseDate(newDate)
    return dateToReturn
  }

  /**
   * The function obtains all possible harvests going 3 years backward and 5 years forward.
   * @returns {string[]} An array with all possible harvests
   */
  getHarvest(): string[] {
    const year = new Date().getFullYear();
    const array = [];
    for (let i = -3; i < 5; i++) {
      array.push(year + i + ' - ' + Number(year + i + 1));
    }
    return array;
  }

  /**
   * Given a date in dd/mm/yyyy format, returns the date in dd-mm-yyyy format
   * @param {string} date - The date in string format
   * @returns {string} The date in dd-mm-yyyy format
   */
  toLocaleDateDashString(value: any) {
    const localeDate = value.toLocaleDateString();
    const localeDateSplit = localeDate.split('/');
    return (
      localeDateSplit[0] + '-' + localeDateSplit[1] + '-' + localeDateSplit[2]
    );
  }

  /**
   * Given a date in string format and a number of days.
   * Add that number of days to the date.
   * @param {string} date - The date in string format
   * @param {number} days - The number of days to add
   * @returns {string} The date with the added days in string format
   *  */
  addDaysToDate(date: string, days: number):string {
    const dateSplit = date.split('-');
    const year = Number(dateSplit[2]);
    const month = Number(dateSplit[1]);
    const day = Number(dateSplit[0]);

    let dateToReturn = new Date(year, month - 1, day);
    dateToReturn.setDate(dateToReturn.getDate() + days);
    return parseDate(dateToReturn);
  }
}

/**
 * Given a date, get the time in a standard format, 
 * add the seconds depending on whether it is asked for by the function parameter.
 * @param {Date} date - The date to get the time from
 * @param {boolean} withSeconds - Whether to include the seconds in the time
 * @returns {string} The time in a standard format
 * */
export function getHourByDate(date:Date, withSeconds:boolean=false){
  const hours=date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const minutes=date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  const seconds=date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return withSeconds ? `${hours}:${minutes}:${seconds}` : `${hours}:${minutes}`;
}

/**
  * Returns the date of today as a string with specific form
  * @returns {string} The date as a string with specific form
  * */
export function getDate(){
  const date = new Date();
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

/**
 * Given a date in Date format, we return it to string format.
 * @param date
 * @returns string
 */
export function parseDate(date: Date):string {
  return date? [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-') : '';
}

/**
 * Given a date in string format mentioned above, it returns a date in Object Date format.
 * @param {string} dateString - The date in string format
 * @returns {Date} The date in Object Date format
 */
export function stringToDate(dateString:string): Date{
  const dateSplit = dateString.split('-');
  return new Date(Number(dateSplit[2]), Number(dateSplit[1]) - 1, Number(dateSplit[0]));
}

/**
 * Given a date in string format mentioned above and a time returns the date in Object Date format
 * @param {string} dateString - The date in string format 
 * @param {string} hour - The time in string format 
 * @returns {Date} The date in Object Date format
 */
export function dateAndHourToDate(dateString:string, hour:string){
  const dateSplit = dateString.split('-');
  const hourSplit = hour.split(':');
  return new Date(Number(dateSplit[2]), Number(dateSplit[1]) - 1, Number(dateSplit[0]), Number(hourSplit[0]), Number(hourSplit[1]));
}


/**
 * Given a date of type string in format:
 * dd-mm-yyyy, returns the date inverted in format: yyyy-mm-dd
 * @param {string} date - The date in string format
 * @returns {string} The date inverted in format: yyyy-mm-dd
 * */
export function invertDate(date:string){
  let dateArray=date.split('-');
  const year = dateArray[2];
  const month =
    Number(dateArray[1]) < 10 ? '0' + dateArray[1] : dateArray[1];
  const day = Number(dateArray[0]) < 10 ? '0' + dateArray[0] : dateArray[0];
  return `${year}-${month}-${day}`;;
}

/**
 * Given a date in string format and separated by hyphens, it returns it by applying a reverse
 * @param {string} date - The date in string format
 * @returns {string} The date in string format with the hyphens reversed
 */
export function invertDateWithout0(date:string){
  return (date ?? '').split('-').reverse().join('-');
}

/**
 * Given two hours, returns true if the second hour is earlier than the first
 * @param {string} hour1 - The first hour in string format
 * @param {string} hour2 - The second hour in string format
 * @returns {boolean} True if the second hour is earlier than the first, false otherwise
 * */
export function isEarlierHour(hour1: string, hour2: string) {
  const [hour1Hours, hour1Minutes] = hour1.split(':').map(Number);
  const [hour2Hours, hour2Minutes] = hour2.split(':').map(Number);

  return hour2Hours < hour1Hours? true: hour2Minutes === hour1Minutes? hour2Minutes < hour1Minutes: false;
}

/**
 * Given two dates, returns true if the second date is earlier than the first
 * @param {string} date1 - The first date in string format
 * @param {string} date2 - The second date in string format
 * @param {string} charOfSeparation - The character that separates the date
 * @returns {boolean} True if the second date is earlier than the first, false otherwise
 * */
export function isEarlierDate(date1: string, date2: string, charOfSeparation: string) {
  if(date1 && date2){

    const date1Split = date1.split(charOfSeparation);
    const date2Split = date2.split(charOfSeparation);

    if (Number(date1Split[2]) < Number(date2Split[2])) {
      //Si el segundo año es mayor, la primera es menor
      return true;
    } else if (Number(date1Split[2]) > Number(date2Split[2])) {
      //Si el primer año es mayor, la primera es mayor
      return false;
    } else if (Number(date1Split[1]) < Number(date2Split[1])) {
      //Si ingresa aquí, el año es igual, por lo tanto
      //si el segundo mes es mayor, la primera es menor
      return true;
    } else if (Number(date1Split[1]) > Number(date2Split[1])) {
      //Si el primer mes es mayor, la primera es mayor
      return false;
    } else if (Number(date1Split[0]) <= Number(date2Split[0])) {
      //Si ingresa aquí, el año y el mes son iguales.
      //Si el segundo mes es mayor, la primera es menor
      //Si es el mismo dia, la fecha también cuenta como anterior.
      return true;
    } else if (Number(date1Split[0]) > Number(date2Split[0])) {
      //Si el primer dia es mayor, la primera es mayor
      return false;
    }
    return false;
  }
  return false;
}

/**
 * Given a valid start date whether it is valid or not. Analyze if:
 * - The date is not empty.
 * - The date is not earlier than today
 * @param {string} startDate - The start date in string format 
 * @returns {boolean} True if the start date is invalid, false otherwise
 */
export function isInvalidStartDate(startDate: string) {
  const dateNow=getDate();
  return startDate=='--' || ((startDate!=dateNow && isEarlierDate(startDate, dateNow, '-')) ?? false); 
}

/**
 * Returns the date following the one passed by parameter
 * @param {Date} date - The date to get the next date from
 * @returns {Date} The next date
 */
export function getNextDate(date:Date){
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
}

/**
 * Returns the date of tomorrow as a string with specific form
 * @param {string} date - The date in string format
 * @returns {string} The date of tomorrow as a string with specific form
 */
export function getDateTomorrow(date:string){
  const tomorrow = stringToDate(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return parseDate(tomorrow);
}

/**
 * Given a past month by parameter, returns the next month. If it is December, it returns January.
 * @param {number} month - The month to get the next month from 
 * @returns {number} The next month
 */
export function getNextMonth(month:number):number {
  return month==12? 1: month+1;
}

/**
 * We will pass a start date in string format, a typePeriod number (which will be propPeriod) 
 * and it will be 1: monthly, 2: biweekly, 3: weekly.
 * Thus, if you placed monthly, the end of the month date must be returned. 
 * If you entered a fortnight, the end date of the next fortnight must be entered and 
 * if you entered a weekly period, the weekend date must be returned.
 * These would be some examples
 * getDateEndPeriod('17-8-2023', 1) -> '31-8-2023';
 * getDateEndPeriod('28-8-2023', 1) -> '31-8-2023';
 * getDateEndPeriod('28-8-2023', 2) -> '31-8-2023'
 * getDateEndPeriod('16-9-2023', 2) -> '30-9-2023'
 * getDateEndPeriod('18-8-2023', 3) -> '20-8-2023'
 * getDateEndPeriod('28-8-2023', 3) -> '3-9-2023' 
 * @param {string} startDate - The start date in string format
 * @param {PeriodType} period - The period type
 * @returns {string} The end date of the period in string format
 * */
export function getDateEndPeriod(startDate: string, period: PeriodType): string {
  const date=stringToDate(startDate)

  if (period === PeriodType.Monthly) { // Monthly
    return getEndOfMonth(date)
  } else if (period === PeriodType.Quincenal) { // Bi-weekly
    return getEndOfBiweekly(date)
  } else if (period === PeriodType.Semanal) { // Weekly
    return getEndOfWeek(date)
  }

  return startDate; // Default to returning the same start date
}

/**
 * Given a date in Object Date format, return the last day of the month that has that date in string format.
 * @param {Date} date - The date to get the last day of the month from.
 * @returns {string} The last day of the month that has that date in string format.
 * */
export function getEndOfMonth(date:Date){
  return parseDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
}

/**
 * Given a month and optionally a year, returns the last day of that month. 
 * @param {number} month - The month to get the last day of.
 * @param {number} year - The year to determine if February has 28 or 29 days.
 * @returns {number} The last day of the month in number format.
 */
export function getEndOfMonthByMonth(month: number, year: number): number {
  if (month < 1 || month > 12) {
    return 0;
  } else if (month == 2 && isLeapYear(year)) {
    return 29;
  } else if (month == 2) {
    return 28;
  } else if ([4, 6, 9, 11].includes(month)) {
    return 30;
  }
  return 31;
}

/**
 * Given a year, checks if it is a leap year.
 * @param {number} year - The year to check.
 * @returns {boolean} True if the year is a leap year, false otherwise.
 */
function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

/**
 * Given a date in Object Date format, 
 * return the last day of the biweekly that has that date in string format.
 * @param {Date} date - The date to get the last day of the biweekly from.
 * @returns {string} The last day of the biweekly that has that date in string format.
 * */
function getEndOfBiweekly(date:Date){
  const day=date.getDate();
  if(day<15){
    return `15-${date.getMonth() + 1}-${date.getFullYear()}`;
  }
  return getEndOfMonth(date);
}

/**
 * Given a date in Object Date format, 
 * return the last day of the week that has that date in string format.
 * @param {Date} date - The date to get the last day of the week from.
 * @returns {string} The last day of the week that has that date in string format.
 * */
function getEndOfWeek(date:Date){
  return parseDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7-date.getDay())));
}

/**
 * Returns the date of yesterDay as a string with specific form
 * @param {string} stringDate - The date in string format
 * @returns {string} The date of yesterDay as a string with specific form
 * */
export function getDateYesterDay(stringDate:string=''){
  const date:Date= (!stringDate || stringDate=='--') ? new Date(): stringToDate(stringDate);
  date.setDate(date.getDate() - 1);
  return parseDate(date);
}

/**
 * Given a date in string format, it returns it in ISO format of numeric type
 * @param {string} date - The date in string format
 * @returns {number} The date in ISO format of numeric type
 * */
export function getDateISOFormat(date:string):number{
  try{
    const dateSplit = date.split('-');
    const dayParsed=Number(dateSplit[0]);
    let day= dayParsed < 10 ? '0' + dayParsed : dayParsed;
    const monthParsed=Number(dateSplit[1]);
    let month = monthParsed < 10 ? '0' + monthParsed : monthParsed;
    let year= dateSplit[2]; //Full year for default.
    
    let valueToReturn = year+month+day //FORMATO AAAAMMDD
    return Number(valueToReturn)
  } catch(err){
    return 0;
  }
}

/**
 * Given a date in string format, checks if it is in dd-mm-yy or dd-mm-yyyy format.
 * If it meets a valid date, it returns it in dd-mm-yyyy format, otherwise, it returns '--'
 * To be valid, it must be in DD-MM-YYYY or DD-MM-YY format.
 * @param {string} date - The date in string format
 * @returns {string} The date in string format
 * */
export function isValidDate(date:string){
    try{
      const dateSplt=date.split('-');
      if(!date || date=='--') {return "--"};

      const day=Number(dateSplt[0]);
      const month=Number(dateSplt[1]);
      const year=Number(dateSplt[2])<100? Number(dateSplt[2])+2000:Number(dateSplt[2]);
  
      if (day > 31) throw new Error();
      if (month > 12) throw new Error();
      if ([4, 6, 9, 11].includes(month) && day >= 31) throw new Error();
      if (month == 2 && day >= 29) throw new Error();
      if ((year >= 100 && year <= 999) || year > 2100) throw new Error();
  
      return day + '-' + month + '-' + year;
    }
    catch(err){}
    return '--';
}

/**
 * Given a date in the aforementioned string format, 
 * it returns true if it is a business day, false otherwise.
 * @param {string} date - The date in string format
 * @returns {boolean} True if it is a business day, false otherwise
 */
export function isBusinessDay(date:string){
  const parseDate=stringToDate(date);
  const numberOfDay=parseDate.getDay();
  return numberOfDay!=0 && numberOfDay!=6 && !FERIADOS.includes(date);
}

/**
 * Given a date, a number of days and whether they are business days or not, 
 * it returns a new date applying everything mentioned above.
 * @param {string} date - The date in string format
 * @param {number} days - The number of days to add
 * @param {boolean} isBusinessDays - Whether the days are business days or not
 * @returns {string} The new date in string format
 */
export function dateByBusinessDays(date: string, days: number, isBusinessDays: boolean) {
  let dateToReturn: string = date;
  if (isBusinessDays) {
    let count = 0;
    let isBusinessDayBool=isBusinessDay(dateToReturn);
    
    //Mientras que en el conteo de días hábiles no se llegue a la cantidad de días que se quiere sumar 
    //o la fecha no sea un día hábil, se suma un día.
    while (count < days || !isBusinessDayBool) {
      if (isBusinessDay(dateToReturn)) {
        count++;
      }
      dateToReturn = getDateTomorrow(dateToReturn);
      isBusinessDayBool=isBusinessDay(dateToReturn);
    }
  } else {
    for (let i = 0; i < days; i++) {
      dateToReturn = getDateTomorrow(dateToReturn);
    }
  }
  return dateToReturn;
}

/**
 * Given a date, returns the last business day of the month that has that date.
 * @param {string} date - The date in string format
 * @returns {string} The last business day of the month that has that date
 * */
export function getDateEndOfMonthBusinessDay(date:string){
  const parseDateStr=stringToDate(date);
  const newDate = new Date(parseDateStr.getFullYear(), parseDateStr.getMonth() + 1, 0);
  let dateStr=parseDate(newDate);

  while(!isBusinessDay(dateStr)){
    newDate.setDate(newDate.getDate()-1);
    dateStr=parseDate(newDate);
  }
  return parseDate(newDate)
}

/** Given two dates in dd-mm-yyyy format, returns the number of days between them 
 * @param string date1
 * @param string date2
 * @param boolean isBusinessDays
 * @returns number
 * */
export function getDaysBetweenDates(date1: string, date2: string) {
  const date1Splt = date1.split('-');
  const date2Splt = date2.split('-');
  const fDate1 = Date.UTC(
    Number(date1Splt[2]),
    Number(date1Splt[1]) - 1,
    Number(date1Splt[0])
  );
  const fDate2 = Date.UTC(
    Number(date2Splt[2]),
    Number(date2Splt[1]) - 1,
    Number(date2Splt[0])
  );
  const diff = fDate2 - fDate1;
  return Math.floor(diff / (1000 * 60 * 60 * 24));
}

/**
 * Based on a start date, an end date and whether they are business days or not, 
 * it returns the number of days between those two dates.
 * Depending on whether they are business days or not
 * @param {string} dateStart - The start date in string format
 * @param {string} dateEnd - The end date in string format
 * @param {boolean} isBusinessDays - Whether the days are business days or not
 * @returns {number} The number of days between the two dates
 * */
export function getDaysBetweenDatesByBusinessDay(dateStart: string, dateEnd: string, isBusinessDays: boolean) {
  let count = 0;
  let dateToReturn = dateStart;
  if(!dateStart || !dateEnd || dateStart=='--' || dateEnd=='--'){
    return 0;
  }
  
  if (isBusinessDays) {
    while (dateToReturn != dateEnd) {
      if (isBusinessDay(dateToReturn)) {
        count++;
      }
      dateToReturn = getDateTomorrow(dateToReturn);
    }
  } else {
    while (dateToReturn != dateEnd) {
      count++;
      dateToReturn = getDateTomorrow(dateToReturn);
    }
  }
  return count;
}

/**
 * Given two dates in string format, returns an array with all the dates between them.
 * @param date1 
 * @param date2 
 * @returns array of dates
 */
export function getDatesBetweenDates(date1: string, date2: string):string[] {
  const dates:string[] = [];
  let dateAux=date1;
  //Si la fecha 2 está vacía, retornamos el array con la fecha inicial.
  if(!date2 || date2=='--'){
    return [date1];
  }

  while (isEarlierDate(dateAux, date2, '-')) {
    dates.push(dateAux);
    dateAux = getDateTomorrow(dateAux);
  }
  return dates;
}

/**
 * Given two dates in Date format, returns whether they are the same date.
 * @param {Date} date1 - The first date
 * @param {Date} date2 - The second date
 * @returns {boolean} True if they are the same date, false otherwise
 */
export function isSameDate(date1:Date, date2:Date){
  return date1.getDate()==date2.getDate() && date1.getMonth()==date2.getMonth() && date1.getFullYear()==date2.getFullYear();
}

/**
 * From two dates in string format and a period that can be:
 * 1: Monthly, 2: Biweekly, 3: Weekly.
 * Returns the number of periods between the two dates.
 * @param {string} date1 - The first date in string format
 * @param {string} date2 - The second date in string format
 * @param {number} period - The period type
 * @returns {number} The number of periods between the two dates
 * */
export function getPeriodsBetweenDates(date1:string, date2:string, period:number){
  if(!date1 || date1=='--' || !date2 || date2=='--') return 0;
  if(date1==date2) return 1;

  let periods=0;
  let date1Parsed=stringToDate(date1);
  let date2Parsed=stringToDate(date2);
  let dateAux=new Date(date1Parsed);

  while(dateAux<=date2Parsed){
    periods++;
    switch(period){
      case 1:
        dateAux.setMonth(dateAux.getMonth()+1);
        break;
      case 2:
        dateAux.setDate(dateAux.getDate()+15);
        break;
      case 3:
        dateAux.setDate(dateAux.getDate()+7);
        break;
    }
  }
  return periods;
}

/**
 * Starting with a month and a year, returns a string with the month and year parsed.
 * getMonthAndYear(3, 2021) -> 'March 2021'
 * @param {number} month - The month in number format
 * @param {number} year - The year in number format
 * @returns {string} The month and year parsed
 */
export function getMonthAndYear(month:number, year:number){
  return `${getMonthString(month)} ${year}`;
}

/**
 * Starting with a month and a year, returns a string with the abbreviated month and the parsed year.
 * getMonthAndYearAbbreviated(3, 2021) -> 'Mar 2021'
 * @param {number} month - The month in number format
 * @param {number} year - The year in number format
 * @returns {string} The abbreviated month and the parsed year
 * */
export function getMonthAndYearAbbreviated(month:number, year:number){
  return `${getMonthStringAbbreviated(month)} ${year}`;
}

/**
 * Given a month in numerical format. Returns the month in string format.
 * getMonthString(3) -> 'March'
 * @param {number} month - The month in number format
 * @returns {string} The month in string format
 * */
export function getMonthString(month:number){
  const months=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre',
  'Octubre','Noviembre','Diciembre'];
  return months[month-1];
}

/**
 * Given a month in numerical format. Returns the abbreviated month in string format.
 * getMonthStringAbbreviated(3) -> 'Mar'
 * @param {number} month - The month in number format
 * @returns {string} The abbreviated month in string format
 */
export function getMonthStringAbbreviated(month:number){
  const months=['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
  return months[month-1];
}