import { Pipe, PipeTransform } from '@angular/core';
import { OrderExchange } from '../../interfaces/order-exchange';
import { Team, User } from '../../interfaces/user.interface';


@Pipe({
  name: 'filterByRepresentation'
})
export class FilterOrderByRepresentationPipe implements PipeTransform {

    /*Filter the orders depending on whether it is representation or not.
If it is a representation, compare if it has the same name as the creator or has the same table manager.
If it is not a representation, it returns the list that was passed by parameter */
  transform(list: any[], isRepresentation:boolean, user:User) {

    if(isRepresentation){
        let arrayFinal: any[] = [];
        list.forEach( (element:OrderExchange) => {
            if( isOrderOfTheSameTeam(element.dataOfcreation.teamOfcreator, user) || isTheSameCreator(element.dataOfcreation.nameOfcreator, user) ){
                arrayFinal.push(element);
            } 
        });

        return arrayFinal;
    }

    return list
  }
} 

export function isTheSameCreator(nameOfCreator:string, user:User){
    return nameOfCreator == (user.name+ " " + user.lastName);
}

export function isOrderOfTheSameTeam(teamOfCreator:Team, user:User){
    return teamOfCreator.bossName == user.team.bossName && teamOfCreator.bossLastName==user.team.bossLastName;
}