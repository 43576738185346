import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOptions'
})
export class FilterOptionsPipe implements PipeTransform {

  transform(lista: any[], search: string, identificator:string='') {
    if(search.length < 1) return lista;
    const searchs = search.trim().toLowerCase().split(" ");
    const QuantElements = lista.length;
    let list = [];
    if(identificator==''){
      for (let i = 0; i < QuantElements; i++) {
        const element = lista[i];
        if (this.elementContainSearch(element, searchs)) {
          list.push(lista[i]);
        }
      }
    } else if(identificator=='buyer') {
      for (let i = 0; i < QuantElements; i++) {
        const element = lista[i][1];
        if (this.elementContainSearch(element, searchs)) {
          list.push(lista[i]);
        }
      }
    } else {
      //CodeS
      for (let i = 0; i < QuantElements; i++) {
        if (this.elementContainSearch(lista[i][1].nombre, searchs)) {
          list.push(lista[i]);
        }
      }
    }
    return list;
  }

  /*Given an element and a list of keywords.
Returns whether the element contains any of the keywords.*/
  elementContainSearch(element: any, values: any[]):boolean {
    let countFalse=0;
    values.forEach((value)  => {
      if(! element.toLowerCase().includes(value)){
        countFalse++;
      }
    })
    return countFalse == 0;
  }

}
