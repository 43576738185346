<div class="field">
    <span class="field-title">Subdivisión Syngenta<span class="asterisk">*</span></span>

    <div>
        <div class="form-field" (click)="changeShowOptions()" 
        [ngClass]="{'border-green': isOptionSelected, 'is-incomplete': isIncomplete}">
            <span class="title-field  {{identifyClassName}}"> 
                <span class="autocomplete"> 
                    <span class="first-part">{{search}}</span> 
                    <span class="second-part">{{placeHolder}}</span>
                </span>
                <input #inputName (focus)="focusField()" 
                (blur)="unfocusField()"
                value="{{elementSelected}}" [(ngModel)]="search" placeholder="{{initalElementSelected}}" 
                class="{{identifyClassName}} no-background-border" />

                <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="{{arrowDirection}} {{identifyClassName}}"
                    src="../../../../../../assets/images/extra/arrow-down-green.svg" />
            </span>
        </div>

        <span class="text-is-incomplete" *ngIf="isIncomplete">Completar la siguiente información para continuar.</span>

        <div class="options" *ngIf="showOptions">
            <span *ngFor="let element of array; index as i" [ngClass]="{'selected': OBJECT[element]==elementSelected}"
                (click)="setElement(element, i)">
                {{OBJECT[element]}}
            </span>
        </div>
    </div>
</div>

<div *ngIf="showOptionAVC" 
class="row-between form-field avc-field" 
[ngClass]="{'border-green': isSelectedAvc}">
    <span class="field-title">AVC</span>

    <mat-slide-toggle (change)="setAVC($event)" [checked]="isSelectedAvc"></mat-slide-toggle>
</div>