import { Pipe, PipeTransform } from '@angular/core';
import { orderTags } from '../../const/orders';
import { priceTags } from '../../const/prices.const';
import { indicatorsTags } from '../../const/to-fix';

@Pipe({
  name: 'filterByObject'
})
export class FilterByObjectPipe implements PipeTransform {

    transform(valueList: any[], search: string, object: any, identificator: string=''): any[] {
    if (!search && identificator !=orderTags.codeS) return valueList;

    const searchAux = search.toLowerCase().trim();
    let arrayReturn: any[] = [];
    const quantElements: number = valueList.length;
    if (object && identificator != orderTags.town && identificator != priceTags.port && 
      identificator != priceTags.harvest && identificator != orderTags.codeS &&
      identificator != priceTags.buyer && identificator != indicatorsTags.location) {
      for (let i = 0; i < quantElements; i++) {
        if (this.startsWith(object[valueList[i]], searchAux) || this.startsWith(valueList[i].toString(), searchAux)) {
          arrayReturn.push(valueList[i]);
        }
      }
    } else if(identificator == orderTags.codeS){
      let quantMaxOfSellers= getQuantMaxOfSellers(searchAux.length)
      if(searchAux.length>0){
        try{
          for (let i = 0; i < quantElements; i++) {
            if (this.containsThis(object[valueList[i]].nombre, searchAux) || this.startsWith(valueList[i], searchAux)) {
              arrayReturn.push(valueList[i]);

              if(arrayReturn.length==quantMaxOfSellers){
                throw new Error(); //Cortamos el ciclo cuando llega a la cantidad maxima de elementos pedidos
              }
            }
          }
        } catch(e){
          //No hacemos nada, simplemente cortamos el ciclo
        }
      } else {
        //Obtenemos los primeros 20 elementos de la lista
        try{
          if(quantElements<=20){return valueList;}

          for (let i = 0; i < quantElements; i++) {
            arrayReturn.push(valueList[i]);
            if(arrayReturn.length==quantMaxOfSellers){
              throw new Error();
            }
          }
        } catch(err){}
      }
    } else if (identificator == orderTags.town) {
      for (let i = 0; i < quantElements; i++) {
        if (this.startsWith(valueList[i].afip, searchAux) || this.containsThis(valueList[i].location, searchAux)) {
          arrayReturn.push(valueList[i]);
        }
      }
    } else if (identificator == priceTags.port) {
      for (let i = 0; i < quantElements; i++) {
        if (this.startsWith(valueList[i], searchAux) || this.containsThis(valueList[i], searchAux)) {
          arrayReturn.push(valueList[i]);
        }
      }
    } else if (identificator == indicatorsTags.location) {
      for (let i = 0; i < quantElements; i++) {
        if (this.startsWith(object[valueList[i]].nombre, searchAux) || this.containsThis(object[valueList[i]].nombre, searchAux)) {
          arrayReturn.push(valueList[i]);
        }
      }
    } else if (identificator == priceTags.buyer){
      let quantMaxOfSellers= getQuantMaxOfSellers(searchAux.length)
      if(searchAux.length>0){
        try{
          for (let i = 0; i < quantElements; i++) {
            if (this.containsThis(object[valueList[i]], searchAux) || this.startsWith(valueList[i], searchAux)) {
              arrayReturn.push(valueList[i]);

              if(arrayReturn.length==quantMaxOfSellers){
                throw new Error(); //Cortamos el ciclo cuando llega a la cantidad maxima de elementos pedidos
              }
            }
          }
        } catch(e){
          //No hacemos nada, simplemente cortamos el ciclo
        }
      } else {
        //Obtenemos los primeros 20 elementos de la lista
        try{
          for (let i = 0; i < quantElements; i++) {
            arrayReturn.push(valueList[i]);
            if(arrayReturn.length==quantMaxOfSellers){
              throw new Error();
            }
          }
        } catch(err){}
      }
    } else {
      for (let i = 0; i < quantElements; i++) {
        if (this.startsWith(valueList[i], searchAux)) {
          arrayReturn.push(valueList[i]);
        }
      }
    }

    return arrayReturn;
  }

  public getOnlyOneSeller(valueList: any[], search: string, object: any): any[] {
    let arrayReturn: any[] = [];
    const searchAux = search.toLowerCase().trim();
    const quantElements: number = valueList.length;
    try{
      for (let i = 0; i < quantElements; i++) {
        if (this.startsWith(object[valueList[i]].nombre, searchAux) || this.startsWith(valueList[i], searchAux)) {
          arrayReturn.push(valueList[i]);
          throw new Error(); //Cortamos el ciclo cuando llega a la cantidad maxima de elementos pedidos
        }
      }
    } catch(err){

    }
    return arrayReturn;
  }

  /* Given two strings, returns true if the second starts the same as the first. */
  private startsWith(first: string, second: string): boolean {
    return first?.toLowerCase()?.startsWith(second) ?? false;
    //Second always arrives with lowerCase and trim
  }

  /*Given 2 strings, returns true if the first contains the second*/
  private containsThis(first: string, second: string): boolean {
    return first?.toLowerCase()?.includes(second) ?? false;
  }
}


function getQuantMaxOfSellers(length:number){
  if(length>4) {
    return 10;
  } else if(length>2){
    return 50;
  } else if(length>=0){
    return 100;
  } 
}