<div class="market-prices column-left">
    <div class="title-and-arrow row-between width-100">
        <h3 class="title">Precios disponibles de mercado</h3>
        <span class="show-board row-right gap-8 pointer" (click)="goToPriceBoard()">
            Ver tablero
            <arrow-svg class="arrow" [direction]="'right'"></arrow-svg>
        </span>
    </div>
    <div class="empty-state"
        *ngIf="marketPrice.soja.length==0 && marketPrice.trigo.length==0 && marketPrice.maiz.length==0; else marketPrices">
        <div class="info">
            <span class="header">Aún no tienes precios disponibles de mercado.</span>
            <span class="description extra">
                Deberás de esperar a que los operadores activen las condiciones, para poder visualizar los <span
                    class="stroke">precios disponibles.</span></span>
            <span class="show-order-board extra">Visualizar precios disponibles del día anterior</span>
        </div>
    </div>

    <ng-template #marketPrices>
        <div class="container-market-prices">
            <app-market-price class="market-price" [prices]="marketPrice.soja"></app-market-price>
            <app-market-price class="market-price" [prices]="marketPrice.trigo"></app-market-price>
            <app-market-price class="market-price" [prices]="marketPrice.maiz"></app-market-price>
        </div>
    </ng-template>
</div>