export const PLACES_TO_FIX = {
    "-1": "--",
    0: "--",
    5: "Rosario",
    41: "Junin",
    4: "Diamante",
    858: "Labordeboy",
    310: "San Genaro",
    90001: "Up River",
    1500: "Rosario Norte",
    1501: "Rosario Sur",
    1: "Bahia Blanca",
    132: "Necochea",
    11: "Ramallo",
    426: "Lima",
    551: "Las Palmas",
    945: "Del Guazú",
}

export const PROP_PERIOD_TO_FIX = {
    1: "Mensual",
    2: "Quincenal",
    3: "Semanal",
}