import { Injectable } from '@angular/core';
import { CONDITIONS_TYPE_TO_FIX, INDICATORS_PASE_COSECHA, INDICATORS_PORCENTAJE_PRODUCTO, INDICATORS_SIN_ESPACIO, INDICATORS_TO_FIX, INDICATORS_TO_FIX_SUMMARY, INDICATORS_TRADICIONAL, INDICATORS_WITH_PARTIAL, INDICATORS_WITH_PLACE, INDICATORS_WITH_POSITION, INDICATORS_WITH_QUALITY } from './dict/condition-and-indicators';
import { PROP_PERIOD_TO_FIX } from './dict/to-fix';
import { BonusOrReduction, Indicator } from './interfaces/price-to-fix.interface';
import { PRODUCT_NAMES } from './dict/productName';
import { Order } from './interfaces/order.interface';
import { PlaceOfDelivery, Price, RangeDate } from './interfaces/price.interface';
import { CONDITIONS_TYPE_TO_FIX_CONST, INDICATORS_TO_FIX_CONST, PLACES_TO_FIX_CONST } from './const/to-fix';
import { getDate, getDateEndPeriod, getDateTomorrow, getDateYesterDay, getMonthAndYear, isEarlierDate, isInvalidStartDate } from './date.checker';
import { quality } from './dict/dict';
import { PLACE_OF_DELIVERY_CONST } from './const/place-of-delivery';
import { getZoneOfPlantasGrassi, getZoneOfPlantasInterior, getZoneOfPuertosBuenosAires, getZoneOfPuertosEntreRios } from './functions-ibm/contract.checker';
import { PLANTAS_INTERIOR_MAP } from './dict/plantas-interior';

@Injectable({
  providedIn: 'root',
})
export class ToFixValidator {
  constructor() {}

  /** 
   * Given a command, returns true if it has flags.
   * This function will serve to identify when it is a condition to be set and when it is not. 
   * @param {Order} order - The order to be checked.
   * @returns {boolean} True if the order has indicators, false otherwise.
   * */
  orderHaveIndicators(order:Order) {
    return (order?.price?.indicators?.length ?? 0) > 0;
  }

  /**
   * Given a order, return the condition type of the price (condition).
   * @param order 
   * @returns number - The condition type.
   */
  getConditionTypeByOrder(order:Order) {
    return this.getConditionTypeByPrice(order.price);
  }

  /**
   * Given a price (condition), return the condition type.
   * @param price 
   * @returns number - The condition type.
   */
  getConditionTypeByPrice(price:Price) {
    return price.conditionType ?? 0;
  }

  /**
   * Given a condition type, return the condition type in text.
   * @param conditionType 
   * @returns string - The condition type in text.
   */
  getConditionType(conditionType: number) {
    let condition_Type: any = CONDITIONS_TYPE_TO_FIX;
    return condition_Type[conditionType] ?? '--';
  }

  /**
   * Given an order, return the indicators of the price.
   * @param order 
   * @returns Indicator[] - The indicators of the price.
   */
  getIndicatorsByOrder(order:Order) {
    return this.getIndicatorsByPrice(order.price);
  }

  /**
   * Given a price, return the indicators of the price.
   * @param price 
   * @returns Indicator[] - The indicators of the price.
   */
  getIndicatorsByPrice(price:Price) {
    return price.indicators ?? [];
  }

  /**
   * Given the type of condition to be set, returns an array 
   * with the possible indicators for that price (condition)
   * @param {number} conditionType - The type of condition to be set.  
   * @returns {Indicator[]} - The possible indicators for that price (condition).
   */
  getIndicatorsByConditionType(conditionType:number){
    switch(conditionType){
      case CONDITIONS_TYPE_TO_FIX_CONST.TRADICIONAL: return INDICATORS_TRADICIONAL;
      case CONDITIONS_TYPE_TO_FIX_CONST.PASE_COSECHA: return INDICATORS_PASE_COSECHA;
      case CONDITIONS_TYPE_TO_FIX_CONST.PORCENTAJE_PRODUCTO: return INDICATORS_PORCENTAJE_PRODUCTO;
      case CONDITIONS_TYPE_TO_FIX_CONST.SIN_ESPACIO: return INDICATORS_SIN_ESPACIO;
      default: return [];
    }
  }

  /**
   * Given a price, an indicator and an index.
   * Accesses the price indicator at that index, inserts the new one, and returns it.
   * @param {Price} price - The price to be edited.
   * @param {Indicator} indicator - The new indicator to be inserted.
   * @param {number} indexToEdit - The index of the indicator to be edited.
   * @returns {Price} - The price with the new indicator.
   * */
  setIndicatorInPrice(price:Price, indicator:Indicator, indexToEdit:number): Price {
    let newPrice=JSON.parse(JSON.stringify(price));
    newPrice.indicators[indexToEdit] = indicator;
    return newPrice;
  }
  
  /**
   * Given a price, and an index,
   * delete the indicator at that index, and returns it.
   * @param {Price} price - The price to be edited.
   * @param {number} indexToDelete - The index of the indicator to be deleted.
   * @returns {Price} - The price without the indicator.
   * */
  deleteIndicatorInPrice(price:Price, indexToDelete:number): Price {
    let newPrice=JSON.parse(JSON.stringify(price));
    newPrice.indicators.splice(indexToDelete,1);
    return newPrice;
  }

  /**
   * Given an indicator object, returns the fixed period as a string in the format "start date / end date".
   * If the indicator or its fixation period is null or undefined, returns '--'.
   * @param {Indicator | null} indicator - The indicator object.
   * @returns {string} The fixed period as a string, or '--' if not available.
   */
  getFixedPeriod(indicator: Indicator | null): string {
    try {
      return indicator?.fixationPeriod ? `${indicator.fixationPeriod.startDate} / ${indicator.fixationPeriod.endDate}` : '--';
    } catch (err) {
      return '--';
    }
  }

  /**
   * Given an indicator object, returns the name of the indicator as a string.
   * If the indicator is null or undefined, returns '--'.
   * @param {Indicator | null} indicator - The indicator object.
   * @returns {string} The name of the indicator, or '--' if not available.
   */
  getIndicatorName(indicator: Indicator) {
    const indicators: any = INDICATORS_TO_FIX;
    return indicators[indicator?.indicatorName ?? 0] ?? '--';
  }

  /**
   * Given an indicator object, returns the summary of the indicator as a string.
   * If the indicator is null or undefined, returns '--'.
   * @param {Indicator | null} indicator - The indicator object.
   * @returns {string} The summary of the indicator, or '--' if not available.
   */
  getIndicatorNameSummary(indicator: Indicator) {
    const indicators: any = INDICATORS_TO_FIX_SUMMARY;
    return indicators[indicator?.indicatorName ?? 0] ?? '--';
  }

  /**
   * Given an indicator object, returns the name of the place as a string.
   * If the indicator is null or undefined, returns '--'.
   * @param {Indicator | null} indicator - The indicator object.
   * @returns {string} The name of the place, or '--' if not available.
   */
  getPlace(indicator: Indicator) {
    const PLACES: any = PLANTAS_INTERIOR_MAP;
    return (PLACES[indicator?.location]?.nombre??'--');
  }

  /**
   * Given an indicator object, return in text the min and max of the indicator.
   * @param {Indicator} indicator - The indicator object.
   * @returns {string} The min and max of the indicator, or '--' if not available.
   */
  getMinAndMax(indicator: Indicator): string {
    if (indicator.min !== undefined || indicator.max !== undefined) {
      if (indicator.min !== undefined && indicator.min >= 0 && indicator.max !== undefined) {
        return `${indicator.min} min - ${indicator.max} max`;
      }
      if (indicator.min !== undefined) {
        return `${indicator.min} mínimo`;
      }
      if (indicator.max !== undefined) {
        return `${indicator.max} máximo`;
      }
    }
    return '--';
  }

  //---------- PROPORCIONALITY ------------\\
  /**
   * Given an indicator object, return the name of the period of the proportionality in text.
   * @param {Indicator} indicator - The indicator object. 
   * @returns {string} The name of the period of the proportionality in text, or '--' if not available.
   */
  getPropPeriodText(indicator: Indicator):string {
    const PROP_PERIOD:any=PROP_PERIOD_TO_FIX;
    return PROP_PERIOD[indicator?.propPeriod ?? 0] ?? '--';
  }

  /**
   * Given an indicator, create a copy of it
   * takes the proportionality fields and sets the minimum or maximum as appropriate and returns the value.
   * Divide 100 by the necessary divisions (quantDivs)
   * Set whether the propMinOrMax field is 1 or 2.
   * If it is 1 (MIN) it is set to the minimum of the indicator and the maximum to 100.
   * If it is 2 (MAX) it is set to the maximum and minimum of the indicator.
   * Finally, the indicator returns with the changed fields.
   * @param {Indicator} indicator - The indicator object.
   * @returns {Indicator} The indicator with the minimum or maximum set according to the proportionality.
   * */
  setMinOrMaxWithProp(indicator: Indicator) {      
    let newIndicator = JSON.parse(JSON.stringify(indicator));
    const newValue = Number((100 / (newIndicator.propQuantDivs ?? 1)).toFixed(2));
    
    if (newIndicator.propMinOrMax == 1 || newIndicator.propMinOrMax == 2) {
      newIndicator.min = newValue;
      newIndicator.max = (newIndicator.propMinOrMax == 1) ? 100 : newValue;
    }
    
    return newIndicator;
  }

  /**
   * Set whether its propMinOrMax value is 1 or 2.
   * Save the value in a constant depending on:
   * If it is 1, take the minimum of the indicator. If it is 2, it takes the maximum of the indicator.
   * The value of its propPeriod is set.
   * Save in a constant:
   * If it is 1: 'monthly', if it is 2: 'biweekly', if it is 3: 'weekly'
   * The endDate of the fixing period is set.
   * Saves the month and year of the date in a variable in string format.
   * Example: "12-20-2020" -> December 2020.
   * Returns a string that says: The (min or max)% of tons (monthly, biweekly or weekly) until (month and year in string).
   * @param {Indicator} indicator - The indicator object.
   * @returns {string} The text of the proportionality.
   * */
  getTextProporcionalityComplete(indicator: Indicator) {
    const minOrMax=indicator.propMinOrMax==1?'mínimo':'máximo';
    const value = indicator.propMinOrMax == 1 ? indicator.min : indicator.propMinOrMax==2?indicator.max:0;
    const propPeriodText = this.getPropPeriodText(indicator).toLowerCase();
    const endDate = indicator.fixationPeriod.endDate.split('-');
    const monthAndYear = getMonthAndYear(Number(endDate[1]), Number(endDate[2]));
    return `Se dividirá un ${value}% de ${minOrMax} ${propPeriodText}mente hasta ${monthAndYear}` 
  }

  /**
   * Proportionality is obtained in short format to show it in certain components
   * @param {Indicator} indicator - The indicator object. 
   * @returns {string} The text of the proportionality in short format.
   */
  getProporcionalityShort(indicator: Indicator) {
    if(indicator.proporcionality){
      const value = indicator.propMinOrMax == 1 ? indicator.min : indicator.propMinOrMax==2?indicator.max:0;
      const propPeriodText = this.getPropPeriodText(indicator).toLowerCase();
      const minOrMax=indicator.propMinOrMax==1?'Mín':'Máx';
      return `${minOrMax} - ${value}% - ${propPeriodText}`
    }
    return '--'
  }

  /**
   * Given an indicator, analiazes if the monthAndYear 
   * is partial or not and returns the text of month and year.
   * @param {Indicator} indicator - The indicator object.
   * @returns {string} The text of month and year.
   */
  getMonthAndYear(indicator: Indicator) {
    try {
      if (indicator.monthAndYear.month && indicator.monthAndYear.year) {
        const monthInitial=indicator.monthAndYear.month + '-' + indicator.monthAndYear.year;
        if(indicator.monthAndYear.isPartial){
          return "15-"+monthInitial+" / 15-"+(indicator.monthAndYear.month+1) + '-' + indicator.monthAndYear.year;
        }

        return monthInitial;
      } 
    } catch (err) {}
    return '--';
  }

  /**
   * Given an indicator, returns the reduction of indicator
   * @param {Indicator} indicator - The indicator object.
   * @returns {string} The reduction of indicator.
   */
  getReduction(indicator: Indicator) {
    return this.getBonusOrReduction(indicator?.reduction);
  }

  /**
   * Given an indicator, returns the bonification of indicator
   * @param {Indicator} indicator - The indicator object. 
   * @returns {string} The bonification of indicator.
   */
  getBonification(indicator: Indicator) {
    return this.getBonusOrReduction(indicator?.bonification);
  }

  /**
   * Given a bonification or reduction, returns the value and type of it in text.
   * @param {BonusOrReduction} bonusOrReduction - The bonus or reduction object. 
   * @returns {string} The value and type of bonus or reduction in text.
   */
  getBonusOrReduction(bonusOrReduction: BonusOrReduction|null) {
    return bonusOrReduction && haveBonusOrReduction(bonusOrReduction)?bonusOrReduction.value + ' ' + bonusOrReduction.type:'--';
  }

  /**
   * Given an indicator, returns the quality of indicator in text.
   * @param {Indicator} indicator - The indicator object. 
   * @returns {string} The quality of indicator.
   */
  getQuality(indicator: Indicator) {
    const QUALITIES: any = quality;
    return QUALITIES[indicator?.quality] ?? '--';
  }

  /**
   * Given an indicator, returns the product of indicator in text.
   * @param {Indicator} indicator - The indicator object. 
   * @returns {string} The product of indicator.
   */
  getProduct(indicator: Indicator) {
    const PRODUCTS: any = PRODUCT_NAMES;
    return PRODUCTS[indicator?.product] ??'--';
  }

  /**
   * Given an indicator, returns the percentage of product of indicator in text.
   * @param {Indicator} indicator - The indicator object. 
   * @returns {string} The percentage of product of indicator.
   */
  getPercentageProduct(indicator: Indicator) {
    return indicator?.percentageProduct>0?indicator.percentageProduct+'%':'--';
  }

  /**
   * Given an indicator, returns the percentage and product of indicator in text.
   * @param {Indicator} indicator - The indicator object. 
   * @param {string} separator - The separator to be used.
   * @returns {string} The percentage and product of indicator.
   */
  getPercentageAndProductName(indicator: Indicator, separator=' - ') {
    return this.getPercentageProduct(indicator) + separator + this.getProduct(indicator);
  }
  
  /**
   * Given an indicator, returns the macro fixation period of the indicator.
   * Included between the lowest date of the indicators and the highest.
   * @param {Indicator[]} indicators - The indicator object. 
   * @returns {string} The text of the indicator.
   */
  getMacroFixationPeriod(indicators: Indicator[]) {
    try {
      if (indicators.length > 0) {
        let minDate: any;
        let maxDate: any;
        indicators.forEach((indicator: Indicator) => {
          if (indicator.fixationPeriod) {
            if (!minDate || isEarlierDate(indicator.fixationPeriod.startDate,minDate,  "-")) {
              minDate = indicator.fixationPeriod.startDate;
            }
            if (!maxDate || isEarlierDate(maxDate,indicator.fixationPeriod.endDate, "-")) {
              maxDate = indicator.fixationPeriod.endDate;
            }
          }
        });
        return minDate + ' - ' + maxDate;
      }
    } catch (err) {}
    return '--';
  }

  /**
   * Given a flag and an array of flags, 
   * returns the index of the flag if it exists in the array.
   * Otherwise it returns -1.
   * @param {Indicator} indicator - The indicator to be searched.
   * @param {Indicator[]} indicators - The array of indicators.
   * @returns {number} The index of the indicator in the array. 
   */
  searchIndicatorInIndicators(indicator: Indicator, indicators: Indicator[]) {
    try {
      return indicators.findIndex((indicatorInArray: Indicator) => {
        return isSameIndicatorStruct(indicator, indicatorInArray);
      });
    } catch (err) {}
    return -1;
  }
}

/**
 * Given a command, returns true if it has flags.
 * This function will serve to identify when it is a condition to be set and when it is not.
 * @param {Price} price - The price to be checked.
 * @returns {boolean} True if the price has indicators, false otherwise.
 */
export function priceHaveIndicators(price:Price) {
  return (price?.indicators?.length ?? 0) > 0;
}

/**
 * If it has no products in the indicators, it returns the one of the price
 * @param {Price} price - The price to be checked.
 * @returns {number} The product of the price.
 */
export function getProductDefault(price:Price){
  const productIndicator=price?.indicators[0]?.product
  return productIndicator>0?productIndicator:price.productName;
}

/**
 * If it has no quality in the indicators, it returns the one of the price
 * @param {Price} price - The price to be checked.
 * @returns {number} The quality of the price.
 */
export function getQualityDefault(price:Price){
  const qualityIndicator=price?.indicators[0]?.quality
  return qualityIndicator>0?qualityIndicator:price.quality;
}

/**
 * There are certain indicators that are only ROSARIO, BAHIA BLANCA, NECOCHEA or DARSENA
 * The function validates that the indicator name is valid with those locations.
 * If it is one of those, check what they put and recommend according to the placeOfDelivery they have placed
 * If it's not one of those, it recommends the placeOfDelivery you selected
 * @param {Price} price - The price to be checked.
 * @param indicatorName - The indicator name to be checked.
 * @returns {number} The place of the price.
 */
export function getPlaceDefault(price:Price, indicatorName:number){
  if(indicatorName<=0){ return 0; }

  //Por pedido de Gastaldi, estos indicadores deben tener todos los valores.
  //const indicSinDarsena=[INDICATORS_TO_FIX_CONST.MERC_COMP_GEN, INDICATORS_TO_FIX_CONST.FORW_GEN]
  const indicDars=[INDICATORS_TO_FIX_CONST.PIZ_DISP, INDICATORS_TO_FIX_CONST.MATBA, INDICATORS_TO_FIX_CONST.DISP_MATBA];
  
  //indicSinDarsena.includes(indicatorName) ||
  if(indicDars.includes(indicatorName)){
    return price.placeOfDelivery.zone <= 7 ? PLACES_TO_FIX_CONST.ROSARIO:
    indicDars.includes(indicatorName) && price.placeOfDelivery.zone==11 && price.placeOfDelivery.port==22? PLACES_TO_FIX_CONST.DARSENA: 0
  }

  const placeIndicator=price?.indicators[0]?.location
  return placeIndicator>0?placeIndicator:getPlaceByPlaceOfDelivery(price.placeOfDelivery);
}

/**
 * Given a RangeDate and an Indicator. 
 * It copies the indicator and makes calculations to return what an indicator without space with the default dates would represent.
 * @param {RangeDate} RangeDate - The range date to get the default dates.
 * @param {Indicator} indicator - The indicator to be copied. 
 * @returns indicator without space with default dates
 */
export function getSinEspacioDefault(deliveryPeriod:RangeDate, indicator:Indicator):Indicator{
  let indicatorCopy=JSON.parse(JSON.stringify(indicator));

  const startDate:string=deliveryPeriod.startDate;
  indicatorCopy.fixationPeriod.startDate= getDate();
  indicatorCopy.fixationPeriod.endDate= getDateYesterDay(startDate);
  indicatorCopy.monthAndYear={
    month: Number(startDate.split('-')[1]),
    year: Number(startDate.split('-')[2]),
    isPartial: Number(startDate.split('-')[0]) == 1? false : true
  }
  return indicatorCopy;
}


/**
 * Given a Range Date, an Indicator and an Indicator 2, 
 * it analyzes which is the new default value that both indicators should have and returns them in the form of an array.
 * @param { RangeDate } RangeDate: the range date to get the default dates.
 * @param { Indicator[] } indicators: the indicators to be checked.
 * @returns indicators: Indicator[] tradicional with default dates
 */
export function getTradicionalDefault(deliveryPeriod:RangeDate, indicators:Indicator[]):Indicator[]{
  let copyIndicators:Indicator[]=JSON.parse(JSON.stringify(indicators));

  if(copyIndicators.length==0){ return [] }

  //Es forward si tiene posición
  const isFwd=indicators[0].monthAndYear.month>0;
  if(isFwd){
    /**
     * Caso A) Dos indicadores (o más, no ocurrió aún), uno forward y otro disponible.
     * El forward es el sin espacio, va desde la fecha de hoy hasta un día antes del inicio del periodo de entrega y 
     * la posición es el periodo de entrega.
     * El indicador disponible va desde el inicio del periodo de entrega hasta la fecha final que puso el operador.
     */

    copyIndicators[0]=getSinEspacioDefault(deliveryPeriod, indicators[0]);
    copyIndicators[1].fixationPeriod.startDate= deliveryPeriod.startDate;
    return copyIndicators
  } else {
    /**
     * Caso B) Es de un indicador disponible o más (ocurrió y por eso debo actualizar el código) 
     * que va desde el inicio del periodo de entrega hasta la fecha final que ponga el operador.
     */
    copyIndicators[0].fixationPeriod.startDate= deliveryPeriod.startDate;
    return copyIndicators;
  }
}

/**
 * Given an array of the indicators, it returns the maximum date of the fixation period of all the indicators.
 * @param {Indicator[]} indicators - The array of indicators to get the maximum date.
 * @returns {string} The maximum date of the fixation period.
 */
export function getMaxDateInIndicators(indicators: Indicator[]) {
  let maxDate: any;
  indicators.forEach((indicator: Indicator) => {
    if (indicator.fixationPeriod) {
      if (!maxDate || isEarlierDate(maxDate,indicator.fixationPeriod.endDate, "-")) {
        maxDate = indicator.fixationPeriod.endDate;
      }
    }
  });
  return maxDate;
}

/**
 * Returns true or false in case 2 indicators are the same
 * @param {number} indicator1 - The first indicator.
 * @param {number} indicator2 - The second indicator.
 * @returns {boolean} True if the indicators are the same, false otherwise.
 * */
export function isSameIndicator(indicator1: number, indicator2: number) {
  return indicator1 === indicator2;
}

/**
 * Compares if two indicators struct of type indicator are equal
 * @param {Indicator} indicator1 - The first indicator.
 * @param {Indicator} indicator2 - The second indicator.
 * @returns {boolean} True if the indicators are the same, false otherwise.
 * */
export function isSameIndicatorStruct(indicator1: Indicator, indicator2: Indicator) {
  return (
    indicator1.fixationPeriod.startDate === indicator2.fixationPeriod.startDate &&
    indicator1.fixationPeriod.endDate === indicator2.fixationPeriod.endDate &&
    indicator1.indicatorName === indicator2.indicatorName &&
    indicator1.location === indicator2.location &&
    indicator1.min === indicator2.min &&
    indicator1.max === indicator2.max &&
    indicator1.bonification.value === indicator2.bonification.value &&
    indicator1.bonification.type === indicator2.bonification.type &&
    indicator1.reduction.value === indicator2.reduction.value &&
    indicator1.reduction.type === indicator2.reduction.type &&
    indicator1.product === indicator2.product &&
    indicator1.percentageProduct === indicator2.percentageProduct
  );
}

/**
 * Given an indicator, returns true if it has a bonus or reduction.
 * @param {Indicator} indicator - The indicator to be checked.
 * @returns {boolean} True if the indicator has a bonus or reduction, false otherwise.
 */
export function haveBonusOrReduction(bonusOrReduction:BonusOrReduction){
  return bonusOrReduction.value > 0 && bonusOrReduction.type.length > 0
}

/**
 * Given an indicators, returns true if one of them has an incomplete indicator.
 * @param {Indicator[]} indicators - Indicators to analizate
 * @param {number} conditionType - The type of condition to be set.
 * @returns {boolean} True if the indicator has a percentage of product, false otherwise.
 */
export function isIndicatorsIncomplete(indicators:Indicator[], conditionType:number){
  return indicators.some((indicator:Indicator)=>isIndicatorIncomplete(indicator, conditionType))
}

/**
 * Given an indicator, returns true if it has an incomplete indicator.
 * @param {Indicator} indicator - The indicator to be checked. 
 * @param {number} conditionType - The type of condition to be set. 
 * @returns {boolean} True if the indicator has a percentage of product, false otherwise.
 */
export function isIndicatorIncomplete(indicator:Indicator, conditionType:number){
  return indicator.fixationPeriod.startDate == '--' || //Si no tiene fecha de inicio
  indicator.fixationPeriod.endDate == '--' || //Si no tiene fecha de fin
  isInvalidStartDate(indicator.fixationPeriod.startDate) || //Si la fecha de inicio es anterior a la de hoy
  isEarlierDate(indicator.fixationPeriod.endDate, indicator.fixationPeriod.startDate, "-") || //Si la fecha de fin es anterior a la de inicio
  indicator.min<0 || indicator.min>indicator.max || indicator.max <= 0 || //Si el minimo es menor a 0 o mayor al maximo o el maximo es menor o igual a 0
  indicator.indicatorName <= 0 || //Si no tiene indicador
  isInvalidPlace(indicator, conditionType) || //Si la plaza es invalida
  isInvalidQuality(indicator, conditionType) || //Si la calidad es invalida
  isInvalidPeriod(indicator, conditionType) || //Si el periodo es invalido
  isInvalidPercentageProduct(indicator, conditionType) || //Si el porcentaje de producto es invalido
  isInvalidProporcionality(indicator, conditionType) //Si la proporcionalidad es invalida
}

/**
 * Given an indicator, returns true if it has an invalid proporcionality
 * @param {Indicator} indicator - The indicator to be checked. 
 * @returns {boolean} True if the indicator has an invalid proporcionality, false otherwise.
 */
function isInvalidProporcionality(indicator:Indicator, conditionType:number){
  return indicator.proporcionality && (indicator.propMinOrMax<=0 || indicator.propQuantDivs<=0 || indicator.propPeriod<=0)
}

/**
 * Given an indicator, returns true if it has an invalid place
 * @param {Indicator} indicator - The indicator to be checked. 
 * @param {number} conditionType - The type of condition to be set. 
 * @returns {boolean} True if the indicator has an invalid place, false otherwise.
 */
function isInvalidPlace(indicator:Indicator, conditionType:number){
  return isRequiredPlace(indicator.indicatorName) && indicator.location<=0
}

/**
 * Given an indicator, returns true if it has an invalid quality
 * @param {Indicator} indicator - The indicator to be checked. 
 * @returns {boolean} True if the indicator has an invalid quality, false otherwise.
 */
function isInvalidQuality(indicator:Indicator, conditionType:number){
  return isRequiredQuality(indicator.indicatorName) && indicator.quality<=0
}

/**
 * Given an indicator, returns true if it has an invalid period
 * @param indicator - The indicator to be checked.
 * @returns {boolean} True if the indicator has an invalid period, false otherwise.
 */
function isInvalidPeriod(indicator:Indicator, conditionType:number){
  return isRequiredPeriod(indicator.indicatorName) && (indicator.monthAndYear.month<=0 || indicator.monthAndYear.year<=0)
}

/**
 * Given an indicator name, returns true if the quality is required
 * @param {number} indicator - The indicator to be checked. 
 * @returns {boolean} True if the indicator required quality, false otherwise.
 */
export function isRequiredQuality(indicator:number){
  return includesValue(INDICATORS_WITH_QUALITY, Number(indicator));
}

/**
 * Given an indicator name, returns true if the place is required
 * @param {number} indicator - The indicator to be checked.
 * @returns {boolean} True if the indicator required place, false otherwise.
 */
export function isRequiredPlace(indicator:number){
  return includesValue(INDICATORS_WITH_PLACE, Number(indicator));
}

/**
 *  Given an indicator name, returns true if the period is required
 * @param {number} indicator - The indicator to be checked.
 * @returns {boolean} True if the indicator required period, false otherwise.
 */
export function isRequiredPeriod(indicator:number){
  return includesValue(INDICATORS_WITH_POSITION, Number(indicator));
}

/**
 * Given an indicator name, returns true if the indicator allows the option to have a partial month
 * @param {number} indicator - The indicator to be checked.
 * @returns {boolean}  true if the indicator allows the option to have a partial month, false otherwise.
 */
export function isOptionalPartial(indicator:number){
  return includesValue(INDICATORS_WITH_PARTIAL, Number(indicator));
}

/**
 * Given an array of numbers and a number, return true if the number is in the array.
 * @param array 
 * @param value 
 * @returns boolean
 */
function includesValue(array:number[], value:number){
  return array.includes(value)
}

/**
 * Given an Indicator and a conditionType, return true if the percentage of product is invalid
 * @param indicator - The indicator to be checked.
 * @param conditionType - The type of condition to be set.
 * @returns {boolean} True if the indicator has an invalid percentage of product, false otherwise.
 */
function isInvalidPercentageProduct(indicator:Indicator, conditionType:number){
  return conditionType==CONDITIONS_TYPE_TO_FIX_CONST.PORCENTAJE_PRODUCTO && indicator.product<=0 && indicator.percentageProduct <= 0
}

/**
 * First it will be determined if it has proportionality.
 * If it has, it will continue. If not, you can return an array with the same indicator passed by parameter.
 * 
 * Once we see if it has proportionality, we must look at the propQuantDivs field. That number will tell us 
 * how many elements the array that we will return will have.
 * The idea of ​​the function is to create different indicators where the start and end date of the fixation period varies 
 * depending on a certain criterion of the fields referring to proportionality.
 * 
 * The fixing period must be divided depending on the propPeriod field.
 * Thus the indicators will have the same information but with different fixation periods to be divided proportionally.
 * 
 * The first indicator will have the same start date. To calculate the end date we will use another function 
 * (which has not been created yet but I will detail it below).
 * Once we have the end date, and everything is assigned to an indicator, we can insert it into the arrangement. 
 * 
 * @param {Indicator} indicator - The indicator to be divided.
 * @returns {Indicator[]} - The array of indicators.
 * */
export function generateIndicators(indicator: Indicator): Indicator[] {
  const indicators: Indicator[] = [];
  
  if (!indicator.proporcionality) {
    return [indicator];
  }
  const propPeriod=indicator.propPeriod;
  const propQuantDivs = indicator.propQuantDivs;

  for (let i = 0; i < propQuantDivs; i++) {
    const lastIndicator = indicators[indicators.length - 1];
    const startDate= lastIndicator? getDateTomorrow(lastIndicator.fixationPeriod.endDate): indicator.fixationPeriod.startDate;
    const endDate = getDateEndPeriod(startDate, propPeriod);

    let newIndicator: Indicator = JSON.parse(JSON.stringify(indicator))
    newIndicator.fixationPeriod.endDate = endDate;
    newIndicator.fixationPeriod.startDate = startDate;
    indicators.push(newIndicator);
  }

  return indicators;
}

/**
 * Given an array of indicators, loop through each one.
 * If it has proportionality add each element of what generateIndicators() returns
 * If it does not have proportionality, add the same indicator.
 * @param {Indicator[]} indicators - The array of indicators to be divided.
 * @returns {Indicator[]} - The array of indicators.
 * */
export function getIndicatorsWithProporcionality(indicators:Indicator[]):Indicator[]{
  const newIndicators:Indicator[]=[];
  indicators.forEach((indicator:Indicator)=>{
    if(indicator.proporcionality){
      newIndicators.push(...generateIndicators(indicator))
    }else{
      newIndicators.push(indicator)
    }
  })
  return newIndicators;
}

/**
 * Given a delivery location, it analyzes the possible default values ​​and returns one.
 * @param {PlaceOfDelivery} placeOfDelivery - The place of delivery to be checked.
 * @returns {number} The place of delivery.
 */
export function getPlaceByPlaceOfDelivery(placeOfDelivery:PlaceOfDelivery){
  const places:any={
    1: PLACES_TO_FIX_CONST.ROSARIO_NORTE,
    2: PLACES_TO_FIX_CONST.ROSARIO_SUR,
    3: PLACES_TO_FIX_CONST.ROSARIO,
    4: PLACES_TO_FIX_CONST.UP_RIVER,
    5: PLACES_TO_FIX_CONST.ROSARIO,
    6:  PLACES_TO_FIX_CONST.ROSARIO,
    7: PLACES_TO_FIX_CONST.ROSARIO,
  }
  let valueToReturn= places[placeOfDelivery.zone]

  if(!valueToReturn){
    switch(placeOfDelivery.zone){
      case PLACE_OF_DELIVERY_CONST.PUERTOS_BUENOS_AIRES: valueToReturn=getZoneOfPuertosBuenosAires(placeOfDelivery.port); break;
      case PLACE_OF_DELIVERY_CONST.PUERTOS_ENTRE_RIOS: valueToReturn=getZoneOfPuertosEntreRios(placeOfDelivery.port); break;
      case PLACE_OF_DELIVERY_CONST.PLANTAS_GRASSI: valueToReturn=getZoneOfPlantasGrassi(placeOfDelivery.port); break;
      case PLACE_OF_DELIVERY_CONST.PLANTAS_INTERIOR: valueToReturn=getZoneOfPlantasInterior(placeOfDelivery.port); break;
    }
  }

  return valueToReturn;
}

/**
 * An arrangement of indicators is ordered according to the start and end date of the fixation period they have.
 * @param indicators
 * @returns indicators: Indicator[]
 */
export function sortIndicatorsByFixationPeriod(indicators: Indicator[]) {
  let newIndicators = JSON.parse(JSON.stringify(indicators));
  newIndicators.sort( (indicator1:Indicator, indicator2:Indicator) => {
    const date1=indicator1.fixationPeriod.startDate;
    const date2=indicator2.fixationPeriod.startDate;
    return isEarlierDate(date1, date2, "-")? -1 : 1;
  });
  return newIndicators;
}