import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParameterizedObservationsComponent } from './parameterized-observations.component';
import { TextInputModule } from '../../inputs/text/text.module';
import { NumberInputModule } from '../../inputs/number/number.module';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';

@NgModule({
  declarations: [
    ParameterizedObservationsComponent
  ],
  imports: [
    CommonModule,
    TextInputModule,
    NumberInputModule,
    CrossSvgModule
  ], exports: [
    ParameterizedObservationsComponent
  ]
})
export class ParameterizedObservationsModule { }
