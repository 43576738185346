import { Component, Input } from '@angular/core';

@Component({
  selector: 'plus-generate-order-svg',
  templateUrl: './plus-sign-green.component.html',
  styleUrls: ['./../../svg.scss']
})
export class PlusSignGreenSvgComponent {
  @Input() active:boolean=false;
  @Input() tooltip:boolean=true;
  @Input() size: number= 28;
  public style={
    width: '28px',
    height: '28px',
  }
  constructor() { }

  ngOnInit(): void {
    this.style.width=this.size+'px';
    this.style.height=this.size+'px';
  }
}
