import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsBuyerTypeComponent } from './buttons-buyer-type.component';

@NgModule({
  declarations: [
    ButtonsBuyerTypeComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    ButtonsBuyerTypeComponent
  ]
})
export class ButtonsBuyerTypeModule { }
