import { Component, HostListener, Input, AfterViewInit,OnDestroy,EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { setExchangeInOrderToGenerate, setOrderToGenerateInExchange } from 'src/app/redux/actions/exchange.action';
import {
  setOrdersToGenerate,
  setOrderToGenerate,
  setValidationOrdersToGenerate,
} from 'src/app/redux/actions/order.action';
import { setPriceToGenerate } from 'src/app/redux/actions/price.action';
import { fieldType } from 'src/app/shared/const/options';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { ValidationStruct } from 'src/app/shared/interfaces/order.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { Subscription } from 'rxjs';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { isConditionForward } from 'src/app/shared/price';

@Component({
  template: '',
})
export class Field implements AfterViewInit, OnDestroy{
  @Input() fieldIdentificator: string = '';
  @Output() focusEvent= new EventEmitter<number>();
  validation!: ValidationStruct;

  //---------- Options ----------\\
  public showOptions: boolean = false;
  public isOptionSelected: boolean = false;
  public arrowDirection: string = 'down';
  public initalElementSelected: string = '';
  public elementSelected: any = '';  
  public isClickedInConfirm:boolean=false; //---- OPTIONS ----\\
  public isIncomplete:boolean=false;
  @Input() isInvalid:boolean=false;
  //-----------------------------\\

  public tag: string = '';
  public subTag: string = '';
  public thirdTag: string = '';
  public array: any[] = [];
  public OBJECT: any;

  public elementToGenerate!: any;
  public orders: any[] = []; //Validations orders or orders to generate
  public indexToEdit: number = -1;
  public editAllOrders: boolean = false;

  //Detect clicks
  public count: number = 0;
  public identifyClassName: string = '';

  //Form with keyboard
  public isFocus: boolean = false;
  public isClickInArrow: boolean = true;
  public search: any='';
  placeHolder:string='';
  public indexOption: number = -1;
  @Input() isSelected: boolean = false;
  
  protected pipe:any;//declarada en los fields que utilicen el pipe

  private priceSubscription!:Subscription;
  private orderSubscription!:Subscription;
  private exchangeSubscription!:Subscription;
  private optionsSubscription!:Subscription;

  constructor(public store: Store<appState>) {}
  
  ngAfterViewInit(): void {
    this.priceSubscription=this.store.select('price', 'generatePrice').subscribe(generatePrice=>{
      if(this.isGenerateCondition()){
        this.elementToGenerate = generatePrice;
        this.rememberValueInForm();
      }
    })

    this.orderSubscription=this.store.select('order').subscribe(order=>{
      if(this.fieldIdentificator==fieldType.ORDER){
        const orders=order.generateOrders;
        if(orders.length>1){
          this.indexToEdit=order.indexToEdit;
          this.orders=orders;
          this.elementToGenerate=orders[order.indexToEdit];
        } else {
          this.elementToGenerate = order.generateOrder;
        }
        this.rememberValueInForm();
      } else if(this.fieldIdentificator==fieldType.VALIDATION){
        this.elementToGenerate =
          order.edit.ordersEdit[
            order.edit.indexToEdit
          ];
        this.orders = order.edit.ordersEdit;
        this.indexToEdit = order.edit.indexToEdit;
        this.editAllOrders = order.edit.editAll;
        this.rememberValueInForm();
      } 
      
      this.validation=order.validation;
    });

    this.exchangeSubscription=this.store.select('exchange', 'generateOrder').subscribe(generateOrder=>{
      //Field identificator is exchange or is exchange_buyer
      if(this.fieldIdentificator==fieldType.EXCHANGE ){
        this.elementToGenerate = generateOrder.exchange;
        this.rememberValueInForm();
      } else if(this.fieldIdentificator==fieldType.EXCHANGE_BUYER){
        this.elementToGenerate = generateOrder;
        this.rememberValueInForm();
      }
    });
    
    this.optionsSubscription=this.store.select('options', 'isClickedInConfirm').subscribe(isClickedInConfirm=>{
      this.isClickedInConfirm=isClickedInConfirm;
      this.setIncomplete(isClickedInConfirm && this.getIsFieldVoid());
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe(){
    this.priceSubscription?.unsubscribe();
    this.orderSubscription?.unsubscribe();
    this.exchangeSubscription?.unsubscribe();
    this.optionsSubscription?.unsubscribe();
  }


  setIdentifyClassName(): void {
    this.identifyClassName = this.tag + '-' + this.subTag + '-' + this.thirdTag;
  }

  deleteLastValueInArray() {
    try {
      if (
        this.array[this.array.length - 1] == '--' ||
        this.OBJECT[this.array[this.array.length - 1]] == '--'
      ) {
        this.array.pop();
      }
    } catch (err) {}
  }

  //---- REMEMBER VALUES ----\\
  getIsFieldVoid(){
    return this.elementSelected=='' || this.elementSelected=='--' || this.elementSelected<=0
  }

  rememberValueInForm() {}

  //Remember simple value;
  rememberValue(): any {
    try {
      if (this.tag && this.subTag && this.thirdTag) {
        return this.elementToGenerate[this.tag][this.subTag][this.thirdTag];
      } else if (this.tag && this.subTag) {
        return this.elementToGenerate[this.tag][this.subTag];
      } else if (this.tag) {
        return this.elementToGenerate[this.tag];
      } else {
        return this.elementToGenerate;
      }
    } catch (err) {}
  }

  //Remember value with Object
  rememberValueWithObject() {
    try {
      //this.rememberValueTypeOne();
      let valueToSet: any = this.rememberValue();
      //If There is a value to remember
      if (valueToSet != -1 && this.elementToGenerate && valueToSet) {
        this.elementSelected = this.OBJECT[valueToSet];
        this.search = this.elementSelected;
        this.indexOption = this.getIndexOfArray(valueToSet, this.array);
        this.setIsOptionSelected(true)
      } else if( valueToSet <= 0){
        this.elementSelected = '';
        this.search = '';
        this.indexOption = -1;
        this.setIsOptionSelected(false)
      }
    } catch (err) {}
  }

  //Remember value from select type.
  rememberValueTypeOne() {
    let valueToSet: any = this.rememberValue();
    //If There is a value to remember
    if (valueToSet != -1 && this.elementToGenerate && valueToSet) {
      this.elementSelected = this.array[valueToSet - 1];
      this.indexOption = valueToSet - 1;
      this.changeOptionSelected();
    } else if (valueToSet == -1) {
      this.elementSelected = '';
      this.indexOption = -1;
      this.setIsOptionSelected(false);
    }
  }

  //Remember value from number type.
  rememberValueTypeTwo() {
    try {
      let valueToSet: number = this.rememberValue();
      //If There is a value to remember
      if (this.elementToGenerate && valueToSet) {
        this.elementSelected = valueToSet;
        this.setIsOptionSelected(this.elementSelected > 0);
      }
    } catch (err) {
      this.elementSelected = 0;
      this.setIsOptionSelected(false);
    }
  }

  //Remember value from date type.
  rememberValueTypeThree() {
    let valueToSet: any = this.rememberValue();
    //If There is a value to remember
    if (valueToSet && valueToSet != '--' && this.elementToGenerate) {
      this.elementSelected=valueToSet;
      this.changeOptionSelected();
    } else if(valueToSet && valueToSet=='--'){
      this.elementSelected='';
      this.changeOptionSelected();
    }
  }

  //Remember value from simple string type.
  rememberValueTypeFour() {
    let valueToSet: any = this.rememberValue();

    //If There is a value to remember
    if (valueToSet && valueToSet != '--' && this.elementToGenerate) {
      this.elementSelected = valueToSet;
      this.setIsOptionSelected(true);
      this.indexOption = this.getIndexOfArray(valueToSet, this.array);
      this.placeHolder = '';
      this.search = valueToSet;
    } else {
      this.elementSelected = '';
      this.setIsOptionSelected(false);
      this.indexOption = -1;
      this.placeHolder = '';
      this.search = '';
    }
  }

  //--------------------------\\

  setShowOptions(boolean: boolean) {
    this.showOptions = boolean;

    this.arrowDirection=this.showOptions?'up':'down';
  }

  setIsFocus(boolean: boolean) {
    this.isFocus = boolean;
    if (!boolean) {
      this.isClickInArrow = false;
    }
  }

  setIsOptionSelected(boolean: boolean) {
    this.isOptionSelected = boolean;
    this.setIncomplete(this.isClickedInConfirm && this.getIsFieldVoid());
  }

  getShowOptions(): boolean {
    return this.showOptions;
  }

  getIsOptionSelected(): boolean {
    return this.isOptionSelected;
  }

  changeShowOptions() {
    this.setChangeShowOptions(this.isFocus);
  }

  setChangeShowOptions(boolean: boolean) {
    this.setShowOptions(boolean);
    this.setIsOptionSelected(
      boolean ||
        (this.elementSelected != this.initalElementSelected &&
          this.elementSelected != '')
    );
    this.changeArrowDirection();
  }

  changeOptionSelected() {
    this.setIsOptionSelected(
      this.elementSelected != this.initalElementSelected
    );
  }

  changeArrowDirection() {
    return getArrowDirectionByBool(this.showOptions)
  }

  setIncomplete(boolean:boolean){
    this.isIncomplete= boolean;
  }

  //---- ELEMENT TO GENERATE ----\\

  setElement(element:any, index:number){
    this.setChangeShowOptions(false); //Ocultamos las options
    this.setElementToGenerate(Number(element));
  }


  setElementToGenerate(value: any) {
    switch (this.fieldIdentificator) {
      case fieldType.PRICE: this.setPriceToGenerate(value); break;
      case fieldType.ORDER: this.setOrderToGenerate(value); break;
      case fieldType.VALIDATION: this.setValidationsOrder(value); break;
      case fieldType.EXCHANGE_BUYER:
      case fieldType.EXCHANGE: this.setExchangeOrder(value); break;
      default: '';
    }
    this.setPlaceHolder();
  }

  getNewElement(value:any){
    let newElement = JSON.parse(JSON.stringify(this.elementToGenerate));
    if (this.tag && this.subTag && this.thirdTag) {
      newElement[this.tag][this.subTag][this.thirdTag] = value;
    } else if (this.tag && this.subTag) {
      newElement[this.tag][this.subTag] = value;
    } else {
      newElement[this.tag] = value;
    }
    return newElement;
  }

  setPriceToGenerate(value: any) {
    let newPrice = this.getNewElement(value);
    this.store.dispatch(setPriceToGenerate({ price: newPrice }));
  }

  setOrderToGenerate(value: any) {
    let newOrder = this.getNewElement(value);

    if(this.orders.length>1){
      let newOrdersToGenerate=JSON.parse(JSON.stringify(this.orders));
      newOrdersToGenerate[this.indexToEdit]=newOrder;
      this.store.dispatch(setOrdersToGenerate({orders: newOrdersToGenerate}));
    } else {
      this.store.dispatch(setOrderToGenerate({ order: newOrder }));
    }
  }

  setExchangeOrder(value:any){
    let newExchange=this.getNewElement(value);
    if(this.fieldIdentificator==fieldType.EXCHANGE_BUYER){
      this.store.dispatch(setOrderToGenerateInExchange({order: newExchange}));
    } else {
      this.store.dispatch(setExchangeInOrderToGenerate({exchange: newExchange}));
    }
  }

  setValidationsOrder(value: any) {
    if (this.editAllOrders) {
      let newValidationsOrders = JSON.parse(
        JSON.stringify(this.orders)
      );

      for (let i = 0; i < newValidationsOrders.length; i++) {
        if (this.tag && this.subTag && this.thirdTag) {
          newValidationsOrders[i][this.tag][this.subTag][this.thirdTag] = value;
        } else if (this.tag && this.subTag) {
          newValidationsOrders[i][this.tag][this.subTag] = value;
        } else {
          newValidationsOrders[i][this.tag] = value;
        }
      }

      this.store.dispatch(
        setValidationOrdersToGenerate({ orders: newValidationsOrders })
      );
    } else {
      let newValidation = this.getNewElement(value);

      let newValidationsOrders = JSON.parse(
        JSON.stringify(this.orders)
      );
      newValidationsOrders[this.indexToEdit] = newValidation;

      this.store.dispatch(
        setValidationOrdersToGenerate({ orders: newValidationsOrders })
      );
    }
  }

  //Tambien cambiar en place of delivery field
  getPrice():Price {
    try{
      if (this.isGenerateCondition()) {
        return this.elementToGenerate;
      } else {
        return this.elementToGenerate.price;
      }
    } catch (error) {
      const und:any=undefined;
      return und;
    }
  }

  setTag(nameOfTag: string): void {
    if (isPriceOrExchange(this.fieldIdentificator)) {
      this.subTag = nameOfTag;
      this.thirdTag = '';
    } else {
      this.thirdTag = nameOfTag;
    }
  }

  setFirstTag(nameOfTag: string): void {
    if (isPriceOrExchange(this.fieldIdentificator)) {
      this.tag = nameOfTag;
      this.subTag = '';
      this.thirdTag = '';
    } else {
      this.subTag = nameOfTag;
      this.thirdTag = '';
    }
  }

  //DETECT CLICK
  @HostListener('document:click', ['$event']) clickInside(event: any) {
    try {
      const classNames: string = event.target.classList.value;
      if (this.showOptions) this.count = 1;

      if (classNames.length > 0) {
        if (!classNames.includes(this.identifyClassName) && this.count > 0) {
          this.setChangeShowOptions(false);
        }
      }
      this.count = 0;
    } catch (err) {
      console.error('Esta es el error', err);
    }
  }

  focusInInput(inputName: any) {
    if (this.arrowDirection == 'down') {
      inputName.focus();
    } else {
      this.setChangeShowOptions(false);
    }
    this.isClickInArrow = true;
  }

  focusField(input:any=null) {

    if(input){
      //Tomamos a que altura de la pantalla se encuentra el input
      this.emitFocusField(input.offsetTop-100)
    }
    this.setIsFocus(true);
    this.setShowOptions(true);
  }

  emitFocusField(position:number){
    this.focusEvent.emit(position);
  }

  unfocusField() {
    this.setIsFocus(false);
    //this.setShowOptions(false); //If we uncomment it, we cannot select with the mouse
  }

  //Si el ancho de la pantalla es menor a 900, el campo es de solo lectura
  isReadOnly(): boolean {
    return window.innerWidth < 900;
  }

  @HostListener('document:keydown', ['$event'])
  listenerKeyPress(event: any) {
    if (this.isFocus) {
      switch (event.key.toLowerCase()) {
        case 'arrowdown': this.moveArrow('down');break;
        case 'arrowup': this.moveArrow('up');break;

        case 'tab':
          this.setChangeShowOptions(false);
          this.unfocusField();
          this.save();
          break;
        case 'arrowright':
        case 'enter':
          //this.unfocusField();  
          this.save();
          break;
      }
    }  
  }

  @HostListener('document:keyup', ['$event'])
  listenerKeyUp(event: any) {
    if (this.isFocus) {
      if(event.key.toLowerCase()=='backspace'){
        this.checkBackspace(event);
      }

      if(this.search.length > 0){
        this.setPlaceHolder();
      }else{
        this.placeHolder='';
      }
    }
  }

  moveArrow(direction:string){
    direction=='down'? this.indexOption++:this.indexOption--;

    if(0<=this.indexOption && this.indexOption < this.array.length){
      this.setElement(this.array[this.indexOption], this.indexOption);
      this.setShowOptions(true)
    } else if(0>=this.indexOption){
      this.indexOption=0;
      this.setShowOptions(false)
    } else if(this.indexOption >= this.array.length){
      this.indexOption=this.array.length-1;
      this.setShowOptions(true)
    }

    this.search=this.elementSelected;
  }

  save() {
    if(this.search.length > 0){
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT,'');
      if (newList.length > 0) {
        this.setElement(newList[0], 0);
      }

      this.search=this.elementSelected;
      this.setChangeShowOptions(false);
      this.setPlaceHolder();
    }
  }

  setPlaceHolder(){
    try{
      let newList = this.pipe.transform(this.array, this.search, this.OBJECT, '');
      if(newList.length > 0 && this.search){
        this.placeHolder= this.removeFirstLetters(this.OBJECT[newList[0]], this.search);
      } else {
        this.placeHolder= '';
      }
    } catch (err) {
      this.placeHolder='';
    } 
  }

  checkBackspace(event: any) {
    if (event.target.value.length <= 0) {
      this.setElement(-1, -1);
      this.setChangeShowOptions(false);
    }
  }

  /*Given two strings, it removes the first letters from the first, depending on which ones match the second*/
  removeFirstLetters(first: string, second: string): string {
    try{
      let newFirst = first.toLowerCase();
      let newSecond = second.toLowerCase();
      let i = 0;
      while (newFirst[i] == newSecond[i] && i < newFirst.length) {
        i++;
      }
      newFirst = newFirst.substring(i);
      return newFirst;
    } catch (err) {
      return '';
    }
  }

  //Dado un elemento y un array ordenado de menor a mayor, devuelve el indice del elemento en el array
  getIndexOfArray(element:any, array:any[]){
    return array.indexOf(element);
  }

  //Devuelve true en caso de que estemos en la sección de generar una condición
  isGenerateCondition():boolean{
    return this.fieldIdentificator == fieldType.PRICE;
  }

  isForward(){
    return isConditionForward(this.getPrice());
  }
}

export function isPriceOrExchange(fieldIdentificator:string){
  return fieldIdentificator==fieldType.PRICE || fieldIdentificator==fieldType.EXCHANGE;
}