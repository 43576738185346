import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getHowSellIBM } from '../functions-ibm/contract.checker';
import { Order } from '../interfaces/order.interface';
import { orderOriginConst } from '../const/orders';
import { User } from '../interfaces/user.interface';
import { IndicatorIBM, PxQIBMString } from '../interfaces/DataIBM';
import { getCookie } from '../session.checker';
import { ResponseRequest } from '../interfaces/options.interface';

interface ReqVALICUITCV {
  VEND: number;
}

interface ReqCUPOSOV {
  CONT: number;
  FECH: number;
  CAM: number;
  ESTA: string;
}

interface ReqVALIRUSI {
  VEND: number;
  PROD: string;
}

interface ReqVALCATE {
  VEND: number;
  COMOV: string;
  CORR: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  /*getOrdersDashboard(email: String, status: any) {
    const request = { email: email, status: status.toString() }
    return this.http.post<Order[]>(environment.environment.rutas.dashboard.getOrders, request);
    //We deliver the orders of the Dashboard
  }*/

  getAccumulatedTonnage(productName: number, harvest: string) {
    return this.http.get<any>(
      environment.rutas.dashboard.getAccumulatedTonnage + `/${productName}/${harvest}`
    );
  }

  getOrdersByDate(date: any) {
    return this.http.get<ResponseRequest>(
      environment.rutas.orders.getOrdersByDate + `?startDate=${date}`
    );
  }

  getOrdersByRange(date1: string, date2: string, statusOrder: number = 0) {
    return this.http.get<ResponseRequest>(
      environment.rutas.orders.getOrdersByRange +
        `?startDate=${date1}&endDate=${date2}` +
        (statusOrder != 0 ? `&status=${statusOrder}` : '')
    );
  }

  getOrderByID(id: any) {
    return this.http.get<ResponseRequest>(environment.rutas.orders.getOrderByID + `/${id}`);
    //We get the order by ID
  }

  getOrderByContract(contract: number) {
    return this.http.get<ResponseRequest>(
      environment.rutas.orders.getOrderByContract + `?contractNumber=${contract}`
    );
  }

  createOrder(order: Order) {
    let newOrder = JSON.parse(JSON.stringify(order));
    delete newOrder._id;
    delete newOrder.created_at;
    delete newOrder.price._id;

    return this.http.post<ResponseRequest>(
      environment.rutas.orders.createOrder,
      JSON.stringify(newOrder)
    );
    //We create the order
  }

  /*----------------------------------------------*/
  //We edit the order
  editOrderNumber(id: any, fields: string, value: any, user: User) {
    const request = {
      campo: fields,
      valorI: value,
      name: user.name,
      lastName: user.lastName,
    };

    return this.http.put<ResponseRequest>(
      environment.rutas.orders.editOrderNumber + `/${id}`,
      JSON.stringify(request)
    );
  }

  //We edit the order
  updateOrder(order: Order, id: string) {
    const isManual =
      order.orderDestination.orderOrigin == orderOriginConst.manual;
    let request = JSON.parse(JSON.stringify(order));
    delete request._id;
    if (isManual) {
      delete request.price._id;
    }

    return this.http.post<ResponseRequest>(
      environment.rutas.orders.updateOrder + '/' + id,
      request
    );
  }

  //---------- HISTORICAL ----------------\\
  getHistorical(id: string) {
    return this.http.get<ResponseRequest>(
      environment.rutas.orders.historical.replace('{id}', id)
    );
  }

  //--------------- VERSIONS ----------------\\
  getFirstVersion(id: string) {
    return this.http.get<ResponseRequest>(
      environment.rutas.orders.getFirstVersion + `/${id}`
    );
  }

  // ---------- VALIDATIONS IBM ----------------\\

  // ------------ CURL EXAMPLE ----------------\\
  /*curl -i -X POST -H "Authorization:Bearer d69x26XvDl4xsMXCG3nywuPbDbnr8r"    
  -H "Content-Type:application/x-www-form-urlencoded"    
  -d "funcion=VALICUITCV" -d "ambiente=desarrollo" -d 
  "accion=_VEND_4486__VEND_" 'https://api.grassisa.com.ar/api/v2/IBM/Mediador/WakeUp/'*/

  //------------  VALIDATION ----------------\\
  async getValidationForSeller(codeS: number) {
    return this.sendRequest('VALICUITCV', { VEND: codeS });
  }

  async solicitateQuotas(
    contractNumber: number,
    date: number,
    quantTrucks: number,
    AssignmentType: string
  ) {
    return this.sendRequest('CUPOSOV', {
      CONT: contractNumber,
      FECH: date,
      CAM: quantTrucks,
      ESTA: AssignmentType,
    });
  }

  async getValidateRucaAndSisa(codeS: number, howSell: number) {
    return this.sendRequest('VALIRUSI', {
      VEND: codeS,
      PROD: getHowSellIBM(howSell),
    });
  }

  async getValidateHowSell(codeS: number, howSell: number, broker: number) {
    return this.sendRequest('VALCATE', {
      VEND: codeS,
      COMOV: getHowSellIBM(howSell),
      CORR: broker,
    });
  }

  async insertNewIndicator(indicator: IndicatorIBM) {
    return this.sendRequest('ALTAFIOV', indicator);
  }

  async getValidatePxQ(PxQ: PxQIBMString) {
    return this.sendRequest('VALIDAPXQ', PxQ);
  }

  async sendRequest(
    funcion: string,
    value:
      | ReqCUPOSOV
      | ReqVALCATE
      | ReqVALICUITCV
      | ReqVALIRUSI
      | IndicatorIBM
      | PxQIBMString
  ): Promise<any> {
    const response = await fetch(environment.rutas.ibm.validation + '?funcion=' + funcion, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getCookie('refresh_token'),
      },
      body: JSON.stringify(value),
    });

    return response.json();
  }
}
