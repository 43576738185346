//WAY TO PAY (Forma de pago)
export const wayPay = {
	'-1':'--',
	0: "--",
	1: "Contra entrega",
	2: "Con carta de garantía",
	3: "Fecha fija",
	4: "Diferido de la pesificación",
	5: "Diferido de la facturación",
	6: "Contra fijacion con mercaderia entregada", 
	7: "Contra fijacion con carta Garantía"
}

export const wayPayNames={
	contraEntrega: 1,
	conCartaGarantia: 2,
	fechaFija:3,
	diferidoPesificacion:4,
	diferidoFacturacion:5,
	contraFixMercaderiaEntregada:6,
	contraFixCartaGarantia:7
}

export const pesosWithDischarge= [1,2,3,5];
export const pesosWithOutDischarge= [1,2,3,5];
export const pesosWithDeposit= [3];
export const pesosWithForward= [1,2,3,5];

export const dolarsWithDischarge= [1,2,4];
export const dolarsWithOutDischarge= [1,2,4];
export const dolarsWithDeposit= [1,4];
export const dolarsWithForward= [1,2,4];