import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraficComponent } from './grafic/grafic.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { GoalsComponent } from './goals.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DateChecker } from 'src/app/shared/date.checker';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';

@NgModule({
  declarations: [
    GoalsComponent,
    GraficComponent,
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    PipesModule,
    ArrowSvgModule
    
  ], exports:[
    GoalsComponent,
    
  ], providers:[
    DateChecker
  ]
})
export class GoalsModule { }
