import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyGenerateOrderComponent } from './body-generate-order.component';
import { DetailsModule } from '../../../components/details/details/details.module';
import { FormOrderModule } from 'src/app/components/form/form-order/form.module';
import { CommissionSvgModule } from 'src/app/shared/styles/svg/commission/commission.module';
import { DuplicateOrderSvgModule } from 'src/app/shared/styles/svg/duplicate-order/duplicate-order.module';
import { CommissionModule } from 'src/app/components/cards/commission/commission.module';
import { DuplicateOrdersModule } from 'src/app/components/cards/duplicate-orders/duplicate-orders.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';

@NgModule({
  declarations: [
    BodyGenerateOrderComponent
  ],
  imports: [
    CommonModule,
    FormOrderModule,
    DetailsModule,
    CommissionSvgModule,
    DuplicateOrderSvgModule,
    CommissionModule,
    DuplicateOrdersModule,
    ButtonsConditionTypeModule
  ], exports: [
    BodyGenerateOrderComponent
  ]
})
export class BodyGenerateOrderModule { }
