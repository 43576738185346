<svg
  class="svg-menu flex"
  [ngClass]="{ enabled: active, disabled: !active }"
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  viewBox="0 0 22 22"
  fill="none"
>
  <g clip-path="url(#clip0_3376_43026)">
    <path
      d="M11 22C17.0647 22 22 17.0647 22 11C22 4.93534 17.0647 0 11 0C4.93528 0 0 4.93534 0 11C0 17.0647 4.93534 22 11 22ZM11 1.46664C16.258 1.46664 20.5334 5.74196 20.5334 11C20.5334 16.258 16.258 20.5334 11 20.5334C5.74196 20.5334 1.46664 16.258 1.46664 11C1.46664 5.74196 5.74202 1.46664 11 1.46664Z"
      fill="#2E3B4C"
    />
    <path
      d="M14.2073 14.5057C14.343 14.6157 14.5043 14.6671 14.6656 14.6671C14.8819 14.6671 15.0946 14.5717 15.2376 14.3921C15.4906 14.0767 15.4392 13.6147 15.1239 13.3617L11.7323 10.6484V5.13371C11.7323 4.73037 11.4023 4.40039 10.9989 4.40039C10.5956 4.40039 10.2656 4.73037 10.2656 5.13371V11.0004C10.2656 11.2241 10.3683 11.4331 10.5406 11.5724L14.2073 14.5057Z"
      fill="#2E3B4C"
    />
  </g>
  <defs>
    <clipPath id="clip0_3376_43026">
      <rect width="22" height="22" fill="white" />
    </clipPath>
  </defs>
</svg>
