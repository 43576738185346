import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowPriceHeadComponent } from './row-price-head.component';
import { CellHeadModule } from '../../cell-head/cell-head.module';
import { MenuOptionsSvgModule } from 'src/app/shared/styles/svg/menu-options/menu-options.module';

@NgModule({
  declarations: [
    RowPriceHeadComponent
  ],
  imports: [
    CommonModule,
    CellHeadModule,
    MenuOptionsSvgModule
  ], exports:[
    RowPriceHeadComponent
  ]
})
export class RowPriceHeadModule { }