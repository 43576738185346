export const PROVINCES_CONST = {
    BUENOS_AIRES: 1,
    CABA: 2,
    CATAMARCA: 3,
    CHACO: 4,
    CHUBUT: 5,
    CORDOBA: 6,
    CORRIENTES: 7,
    ENTRE_RIOS: 8,
    FORMOSA: 9,
    JUJUY: 10,
    LA_PAMPA: 11,
    LA_RIOJA: 12,
    MENDOZA: 13,
    MISIONES: 14,
    NEUQUEN: 15,
    RIO_NEGRO: 16,
    SALTA: 17,
    SAN_JUAN: 18,
    SAN_LUIS: 19,
    SANTA_CRUZ: 20,
    SANTA_FE: 21,
    SANTIAGO_DEL_ESTERO: 22,
    TIERRA_DEL_FUEGO: 23,
    TUCUMAN: 24
}