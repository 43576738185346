import { Component, OnDestroy,OnInit,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterIndex, setHistorical, setOrderDetails, setOrderToGenerate, setOrdersSelected, showOrderDetails } from 'src/app/redux/actions/order.action';
import { areas, REPRESENTATION_CONST_ARRAY } from 'src/app/shared/const/user.const';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { orderOriginConst, orderStatusConst } from 'src/app/shared/const/orders';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateChecker } from 'src/app/shared/date.checker';
import { OrderValidator } from 'src/app/shared/order';
import { setChangesVisibles, setIsGenerateToFix } from 'src/app/redux/actions/options.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';
import { buyerType, conditionType, setViewMobile } from 'src/app/shared/const/options';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';
import { NAVEGATION } from 'src/app/shared/const/navegation';

@Component({
  selector: 'order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss', 
  './../../../../shared/styles/board.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTableComponent implements OnInit, OnDestroy {
  //Array of products
  orders: Order[] = [];
  ordersWithPipes: Order[] = [];
  previusOrders: Order[] = [];

  //---- USER ----\\
  isComercial: boolean = true;
  isOperator:boolean=false;
  isRepresentation: boolean = false;
  user!:User;
  
  isViewMobile = true;

  //Other filter of tabla
  orderFilter!: OrderString;
  buyerTypes=buyerType;
  buyerType!: string;
  CONDITION_TYPES=conditionType;
  conditionTypeSelected!: string;
  search: string = '';

  ORDER_ORIGIN_CONST:any = orderOriginConst;

  //The column selected to apply a filter
  filterIdentificator: number = -1;
  optionIdentificator: number = -1;

  //The columns that will be seen in the table
  viewSelected!: ViewOrder;

  isCheckedAll: boolean = false;
  ordersSelected: Order[] = [];
  isOrderReturned:boolean=false;
  isShowAddBuyer:boolean=false;

  public wasInside: boolean = false; //Inside in menu option;
  
  private orderAll$!:Subscription;
  private orderAllPipe$!:Subscription;
  private orderAllPreviusOrder$!:Subscription;
  private ordersSelected$!:Subscription;
  private viewSelected$!:Subscription;
  private orderFilter$!:Subscription;
  private search$!:Subscription;
  private buyer$!:Subscription;
  private conditionType$!:Subscription;
  private filterIdentificator$!:Subscription;
  private isOrderReturned$!:Subscription;
  private user$!:Subscription;
  private sizeView$!:Subscription;
  private isShowAddBuyer$!:Subscription;

  constructor(public store: Store<appState>, public router: Router, 
    public dialog: MatDialog, public date: DateChecker, public orderV:OrderValidator,
    private toFixV:ToFixValidator, public cdr:ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy(){
    this.store.dispatch(setOrdersSelected({ orders: [] }));
    this.orderAll$?.unsubscribe();
    this.orderAllPipe$?.unsubscribe();
    this.orderAllPreviusOrder$?.unsubscribe();
    this.ordersSelected$?.unsubscribe();
    this.viewSelected$?.unsubscribe();
    this.orderFilter$?.unsubscribe();
    this.search$?.unsubscribe();
    this.buyer$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.filterIdentificator$?.unsubscribe();
    this.isOrderReturned$?.unsubscribe();
    this.user$?.unsubscribe();

    this.sizeView$?.unsubscribe();
    this.isShowAddBuyer$?.unsubscribe();
  }

  setSubscriptions(){
    this.orderAll$=this.store.select('order', 'orderBoard', 'all').subscribe(all => {
      this.orders = all;    
    })

    this.orderAllPipe$=this.store.select('order', 'orderBoard', 'allPipe').subscribe(allPipe => {
      this.ordersWithPipes = allPipe;
      this.cdr.detectChanges();
    })

    this.orderAllPreviusOrder$=this.store.select('order', 'orderBoard', 'allPreviusOrders').subscribe(allPreviusOrders => {
      this.previusOrders = allPreviusOrders;
    })

    this.viewSelected$=this.store.select('order', 'viewsOfOrderBoard', 'viewSelected').subscribe(viewSelected => {
      this.viewSelected=setViewSelected(viewSelected);
    })

    this.orderFilter$=this.store.select('order', 'orderBoard', 'filter').subscribe(filter => {
      this.orderFilter = filter;
    })

    this.search$=this.store.select('options', 'board', 'search').subscribe(search => {
      this.search = search;
    });
    
    this.conditionType$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe(conditionType => {
      this.conditionTypeSelected = conditionType;
      this.store.dispatch(setOrdersSelected({ orders: [] }));
    })

    this.buyer$=this.store.select('order', 'orderBoard', 'buyerType').subscribe(buyerType=> {
      this.buyerType = buyerType;
      this.store.dispatch(setOrdersSelected({ orders: [] }));
    })

    this.filterIdentificator$=this.store.select('order', 'orderBoard', 'indexFilter').subscribe(indexFilter => {
      this.filterIdentificator = indexFilter;
    })

    this.isOrderReturned$=this.store.select('order', 'orderBoard', 'isOrderReturned').subscribe(isOrderReturned => {
      this.isOrderReturned = isOrderReturned;
    })

    this.ordersSelected$=this.store.select('order', 'orderBoard', 'ordersSelected').subscribe(ordersSelected => {
      this.ordersSelected = ordersSelected;
      this.isCheckedAll = this.orderV.areArraysOfOrdersEqual(this.ordersSelected, this.ordersWithPipes);
    })

    this.user$=this.store.select('usuario').subscribe(user => {
      this.user=user;
      this.isComercial = user.area == areas.comercial;
      this.isOperator= user.area == areas.commodities;
      this.isRepresentation=REPRESENTATION_CONST_ARRAY.includes(user.workUnit);
    })

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.isViewMobile= setViewMobile(sizeView); 
      this.cdr.detectChanges();
    });
  }

  setEditOrders(newStatus: any) {
    if (newStatus == orderStatusConst.RETURNED) {
      this.orderV.openModal(orderStatusConst.RETURNED);
    } else if (newStatus == orderStatusConst.CONFIRM) {
      this.orderV.openModal(orderStatusConst.CONFIRM);
    } else {
      //Recorremos el array de ordenes seleccionadas.
      for (let i = 0; i < this.ordersSelected.length; i++) {
        let orderToSave = this.orderV.saveOrderStatus(this.ordersSelected[i], newStatus, ' ', this.user);

        this.store.dispatch(setChangesVisibles({ isChangeVisible: true }));
      }
      this.orderV.resetSelectedOrderList();
    }
  }

  isOrderInOrderList(order: Order): boolean {
    return this.orderV.isOrderInOrderList(order, this.ordersSelected);
  }

  //It is a different function for business users and operators.
  selectFilter(index: number) {
    this.store.dispatch(setFilterIndex({index: index==this.filterIdentificator? -1: index}));
  }

  getIsNew(order: Order):boolean {
    return order.dataOfcreation.hour == this.date.getHour() || this.orderV.isOrderNewInArray(order, this.previusOrders);
  }

  selectOrder(order: Order) {
    if(order._id){
      const positionInOrderList = this.orderV.indexOfOrder(this.ordersSelected, order);
      const ordersSelectedAux = JSON.parse(JSON.stringify(this.ordersSelected));
      //Si el elemento no está, lo añadimos. Si está, lo eliminamos.
      positionInOrderList < 0? ordersSelectedAux.push(order): ordersSelectedAux.splice(positionInOrderList, 1);
      this.store.dispatch(setOrdersSelected({ orders: ordersSelectedAux }));
    }
  }

  showOrderDetail(order: Order) {
    this.store.dispatch(setOrderDetails({ order: order }));
    this.store.dispatch(showOrderDetails({ show: true }));
  }

  showHistorical(order:Order){ 
    this.store.dispatch(setHistorical({historical:order._id}));
  }

  selectAction(event:string){
    switch(event){
      case OPTION.SELECT_ALL: this.checkedAllItems();break;
    }
  }

  checkedAllItems() {
    this.store.dispatch(setOrdersSelected({orders: this.isCheckedAll? []: this.ordersWithPipes}));
  }

  getMinHeight(table:any){
    return getMinHeight(table, !this.isComercial);
  }
  
  //MOBILE
  orderStatus=orderStatusConst;
  orderStatusSelected=orderStatusConst.PENDING;
  setOrderStatusSelected(status:number){
    this.orderStatusSelected=status;
  }

  getStatusString(status:number){
    switch(status){
      case orderStatusConst.RETURNED: return 'returned';
      case orderStatusConst.PENDING: return 'pending';
      case orderStatusConst.CONFIRM : return 'confirmed';
      default: return '';
    }
  }

  haveIndicators(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  public arrayOfShowParents:any[]=[]

  /*Listens to the click event on a date and is passed a Price as a parameter.
  If the price has indicators, a dropDown is performed for the conditions to be set.
  If the price does not have indicators, the dropDown is for the variants of the condition.*/
  clickInArrow(order:Order, event:any){
    if(this.haveIndicators(order)){
      this.dropDown(order._id);
    } 
  }
  
  dropDown(id:string){
    this.isIdInArrayParent(id)? this.arrayOfShowParents.splice(this.arrayOfShowParents.indexOf(id),1): 
    this.arrayOfShowParents.push(id);
  }

  isIdInArrayParent(id:string){
    return this.arrayOfShowParents.indexOf(id) != -1;
  }

  getArrowDirection(id:string):string{
    return getArrowDirectionByBool(this.isIdInArrayParent(id));
  }

  selectOptionMenu(option:OptionMenu, order:Order){
    switch(option.action){
      case OPTION.DETAILS: this.showOrderDetail(order); break;
      case OPTION.HISTORICAL: this.showHistorical(order); break;
    }
  }

  reingresateOrder(order:Order){
    if(order.statusOrder== orderStatusConst.RETURNED){
      this.store.dispatch(setOrderToGenerate({order:order}));
      this.store.dispatch(setIsGenerateToFix({isGenerateToFix: order?.price?.indicators?.length > 0}))
      const url='/'+NAVEGATION.GENERATE_MANUAL_ORDER
      this.router.navigate([url]);  
    }
   }
}

export function getMinHeight(table:any, isCommodities:boolean=false){
  //Obtener en que punto de la pantalla se encuentra el div 'table'
  const tablePosition=table.getBoundingClientRect();
  const heightRest= tablePosition.top + (isCommodities? 50: 0); //Obtenemos el alto restante
  const width = window.innerWidth; //Obtenemos el ancho de la pantalla
  
  return {"min-height": "calc(100vh - " + (heightRest+'px')+ ")",
"max-height": "calc(100vh - " + (heightRest+'px')+ ")",
"margin":"0px"}
}

/*Función que sirve para que dada una vista y
Devuelve una vista con las columnas que tenía antes y las necesarias para exluirlas*/
export function setViewSelected(view: ViewOrder): ViewOrder {
  return {
    ...view,
    date: false,
  }
}