import { Component,HostListener,OnInit,OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { areas } from 'src/app/shared/const/user.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { ToFixValidator } from 'src/app/shared/to-fix';

@Component({
  template: '',
})

export class DetailsEyeComponent implements OnInit,OnDestroy {
  @ViewChild('card') card!:ElementRef;

  user!:User;
  //Detect click
  isShow:boolean=false;
  wasInside = false;
  count=0;
  clickInClose=false;

  isComercial:boolean=false;
  
  showCompleteState:boolean=false;
  statusSelected:number=0;

  private user$!:Subscription;

  constructor(public store:Store<appState>, public toFixV:ToFixValidator) {}

  ngOnInit(): void {
    this.user$=this.store.select('usuario').subscribe(usuarioReducer=>{
      this.user=usuarioReducer;
      this.isComercial=usuarioReducer.area==areas.comercial;
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }

  isDisable(element:any){}

  close(){}

  open(){}

  @HostListener('click') clickInside() {
    if(!this.clickInClose){
      this.open();
    } 
    this.wasInside = true;
  }

  @HostListener('document:click') clickout() {
    if(this.isShow) this.count++;

    if (!this.wasInside && this.count>1) {
      this.close();
      this.count=0;
    }
    this.wasInside = false;
  }

  @HostListener('document:keyup', ['$event'])
  listenerKeyPress(event: KeyboardEvent){
      if(event.key.toLowerCase() == 'escape'){
        this.close();
      }
  }

  //STATE SECTION
  setShowChangeState(boolean:boolean){
    this.showCompleteState=boolean;
  }

  setStatusSelected(status:number){
    this.statusSelected=status;
  }

  goTo(goToDown:boolean){
    this.card.nativeElement.scrollTo({top: goToDown? this.card.nativeElement.scrollHeight:0, behavior: 'smooth'});
  }

  showButtonChangeState(){}

  isDisabledButtonStatus(){}

  applyNewStatus(){}
}