<div class="background-sin-color">
<svg class="svg-24" [ngClass]="{'active-svg':active}" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#DCDCDC"/>
    <path d="M9.85111 9.51172C9.14597 9.51172 8.57227 10.0854 8.57227 10.7906C8.57227 11.4957 9.14597 12.0694 9.85111 12.0694C10.5563 12.0694 11.13 11.4957 11.13 10.7906C11.13 10.0854 10.5562 9.51172 9.85111 9.51172ZM9.85111 11.3663C9.53365 11.3663 9.27539 11.108 9.27539 10.7905C9.27539 10.4731 9.53367 10.2148 9.85111 10.2148C10.1686 10.2148 10.4268 10.4731 10.4268 10.7905C10.4268 11.108 10.1685 11.3663 9.85111 11.3663Z" fill="white"/>
    <path d="M14.149 12.9307C13.4438 12.9307 12.8701 13.5044 12.8701 14.2095C12.8701 14.9146 13.4438 15.4884 14.149 15.4884C14.8541 15.4884 15.4278 14.9146 15.4278 14.2095C15.4278 13.5044 14.8541 12.9307 14.149 12.9307ZM14.149 14.7852C13.8315 14.7852 13.5732 14.5269 13.5732 14.2095C13.5732 13.8921 13.8315 13.6338 14.149 13.6338C14.4664 13.6338 14.7247 13.8921 14.7247 14.2095C14.7247 14.5269 14.4664 14.7852 14.149 14.7852Z" fill="white"/>
    <path d="M14.883 9.61712C14.7457 9.47984 14.5231 9.47984 14.3858 9.61712L9.11762 14.8853C8.98032 15.0226 8.98032 15.2452 9.11762 15.3825C9.18627 15.4512 9.27625 15.4855 9.3662 15.4855C9.45615 15.4855 9.54615 15.4512 9.61478 15.3825L14.883 10.1143C15.0203 9.977 15.0203 9.75439 14.883 9.61712Z" fill="white"/>
    <path d="M16.4766 7.03076H7.52344C6.68341 7.03076 6 7.71418 6 8.5542V16.4455C6 17.2856 6.68341 17.969 7.52344 17.969H16.4766C17.3166 17.969 18 17.2856 18 16.4455V8.5542C18 7.71418 17.3166 7.03076 16.4766 7.03076ZM17.2969 16.4456C17.2969 16.8979 16.9289 17.2659 16.4766 17.2659H7.52344C7.07112 17.2659 6.70312 16.8979 6.70312 16.4456V8.5542C6.70312 8.10188 7.07112 7.73389 7.52344 7.73389H16.4766C16.9289 7.73389 17.2969 8.10188 17.2969 8.5542V16.4456Z" fill="white"/>
</svg>

<div class="tooltip down row-center w-50">
    <span>Comisión</span>
</div>
</div>
    