import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './order.component';
import { TableOrderComponent } from './table-order/table-order.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
 
@NgModule({
  declarations: [
    OrderComponent,
    TableOrderComponent,
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    PipesModule
  ],
  exports: [
    OrderComponent
  ]
})
export class OrderModule { }
