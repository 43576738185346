<div class="info-product">
    <div class="header">
        <div class="icon-and-name">
            <img class="product-icon" src="../../../../assets/images/products/{{productName}}.svg">
            <span>{{productName}}</span>
        </div>
    </div>

    <table>
        <thead>
            <tr>
                <th>Vendedor</th>
                <th>Canjeador</th>
                <th>Precio</th>
                <th>Tn</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr class="row-table" *ngFor="let dataOfOrder of orders" (click)="showDetails(dataOfOrder)">
                <td>{{getSellerName(dataOfOrder)}}</td>
                <td>{{getExchangerName(dataOfOrder)}}</td>
                <td class="precio">{{getTypeCoin(dataOfOrder)}} {{getPrice(dataOfOrder)}}</td>
                <td>{{getTons(dataOfOrder)}}</td>
                <td> <arrow-svg [direction]="'right'"></arrow-svg> </td>
            </tr>
        </tbody>
    </table>
</div>