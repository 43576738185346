<div class="body gap-16">
    <div class="field" *ngIf="isConditionTypeSelected(CONDITION_TYPES.PORCENTAJE_PRODUCTO)">
        <span class="field-title">Fija por<span class="asterisk">*</span></span>

        <div class="inputs">
            <select-input class="input" [placeHolder]="'Producto'"
            [value]="getProductSelected()" [OBJECT]="products" 
            [array]="arrayProducts" (valueOut)="setValueWithTagEvent($event,indicatorsTags.product)"></select-input>

            <number-input class="input" [initialPlaceHolder]="'Ingresar porcentaje'"
            [value]="getValue(indicatorsTags.percentageProduct)" [showAuxiliarText]="true" [auxiliarText]="'%'"
            (valueOut)="setValueWithTag($event, indicatorsTags.percentageProduct)"></number-input>
        </div>
    </div>

    <div class="field" *ngIf="showQuality(indicator.indicatorName)">
        <span class="field-title">Calidad<span class="asterisk">*</span></span>

        <select-input class="input" [placeHolder]="'Calidad'"
            [value]="getQualitySelected()" [OBJECT]="qualities" 
            [array]="arrayQualities" (valueOut)="setValueWithTagEvent($event,indicatorsTags.quality)"></select-input>
    </div>

    <div class="field" *ngIf="showPlaza(indicator.indicatorName)">
        <span class="field-title">Plaza <span class="asterisk">*</span></span>
        
        <select-input [placeHolder]="'Ingresar una plaza'"
        [value]="getPlaceSelected()" [OBJECT]="places" [identificatorSearch]="'location'"
        [array]="arrayPlaces" (valueOut)="setValueWithTagEvent($event, indicatorsTags.location)"></select-input>
    </div>

    <div class="field">
        <span class="field-title">Periodo de fijación<span class="asterisk">*</span></span>
        <date-range-input [valueInit]="getValue(indicatorsTags.fixationPeriod, 'startDate')" 
        [valueEnd]="getValue(indicatorsTags.fixationPeriod, 'endDate')" 
        (valueOut)="setValueWithTag($event, indicatorsTags.fixationPeriod)" 
        [isFilter]="fixationInvalids.length>0" [arrayDatesFilter]="fixationInvalids"></date-range-input>
        <!-- Solo se muestra el filtro cuando el array tiene días. -->
    </div>

    <div class="field">
        <div class="row-between widht-100 pointer" (click)="setValueWithTag(!hasProportionality(indicator),indicatorsTags.proporcionality)">
            <span class="field-title">Proporcionalidad</span>
            <input class="proportionality pointer" style="width:16px; height:16px" type="checkbox" [checked]="hasProportionality(indicator)">
        </div>

        <div class="info-proporcionality column-left gap-8" *ngIf="hasProportionality(indicator)">
            <div class="radios-buttons row-between width-100">
                <radio-button-input [options]="getOptionsProportionality()" (valueOut)="setMinOrMaxProp(indicatorsTags.propMinOrMax,$event)"
                [value]="getValue(indicatorsTags.propMinOrMax)"></radio-button-input>

                <div class="period options pointer" *ngIf="indicator.propMinOrMax>0">
                    <div class="row-left gap-8" (click)="setShowSubOptions(!showSubOptions)">
                        <span class="period-selected">{{getPropPeriodSelected()}}</span>
                        <arrow-svg [direction]="'down'" [active]="true"></arrow-svg>
                    </div>

                    <div class="sub-options column-left" *ngIf="showSubOptions">
                        <span class="first-option" (click)="setPeriodProp(periodType.Monthly)">Mensual</span>
                        <span class="second-option" (click)="setPeriodProp(periodType.Quincenal)">Quincenal</span>
                        <span class="last-option" (click)="setPeriodProp(periodType.Semanal)">Semanal</span>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="indicator.propMinOrMax>0"> 
                <span class="example">
                    {{getTextProporcionalityComplete()}}
                </span>
            </div>
    
        </div>
    </div>

    <div class="field" *ngIf="!hasProportionality(indicator)">
        <span class="field-title">Porcentaje de fijación<span class="asterisk">*</span></span>

        <div class="inputs">
            <number-input class="input" [initialPlaceHolder]="'Minimo'"
            [value]="getValue(indicatorsTags.min)" [min]="0" [max]="'100'"
            [showCero]="true" [showAuxiliarText]="true" [auxiliarText]="'mínimo'"
            (valueOut)="setValueWithTag($event, indicatorsTags.min)"></number-input>

            <number-input class="input" [initialPlaceHolder]="'Maximo'"
            [value]="getValue(indicatorsTags.max)" [max]="'100'" [min]="0"
            [showAuxiliarText]="true" [auxiliarText]="'máximo'"
            (valueOut)="setValueWithTag($event, indicatorsTags.max)"></number-input>
        </div>
    </div>

    <div class="field" *ngIf="showMonthAndYear(indicator.indicatorName)">
        <span class="field-title">Posición<span class="asterisk">*</span></span>
        
        <div class="row-between">
            <date-month-input style="width:75%"
            [initialPlaceHolder]="'Mes y año'"
            [value]="getMonthAndYear(indicator.monthAndYear)"
            [minDate]="indicator.fixationPeriod.startDate"
            (valueOut)="setMonthAndYear($event)"></date-month-input>

            <div class="column-center is-partial" *ngIf="showIsPartial(indicator.indicatorName)">
                <span class="field-title">¿Es parcial?</span>
                <mat-slide-toggle [checked]="indicator.monthAndYear.isPartial" (change)="changeIsPartial()"></mat-slide-toggle>
            </div>
        </div>

        <span class="example" *ngIf="getMonthAndYear(indicator.monthAndYear)">Periodo: {{getMonthAndYearString(indicator)}}</span>
    </div>

    <div class="field">
        <span class="field-title">Bonificación</span>
        <div class="inputs">
            <number-input [initialPlaceHolder]="'Ingresar monto'"
            [value]="getValueBoR(indicatorsTags.bonification, 'amount')" 
            (valueOut)="setValueBoR($event, indicatorsTags.bonification, indicatorsTags.value, 'USD')"></number-input>


            <number-input [isDisabled]="true" [initialPlaceHolder]="'Ingresar porcentaje'"
            [value]="getValueBoR(indicatorsTags.bonification, 'percentage')" 
            [showAuxiliarText]="true" [auxiliarText]="'%'"
            (valueOut)="setValueBoR($event, indicatorsTags.bonification, indicatorsTags.value, '%')"></number-input>
        </div>

        <div class="field" *ngIf="indicator.bonification.value>0 && indicator.bonification.type !='%'">
            <radio-button-input [options]="getOptionsBoR()" (valueOut)="setTypeCoin($event, 'bonification')"
            [value]="indicator.bonification.type"></radio-button-input>
        </div>
    </div>

    <div class="field">
        <span class="field-title">Rebaja</span>
        <div class="inputs">
            <number-input [initialPlaceHolder]="'Ingresar monto'"
            [value]="getValueBoR(indicatorsTags.reduction, 'amount')" 
            (valueOut)="setValueBoR($event, indicatorsTags.reduction, indicatorsTags.value, 'USD')"></number-input>
            <number-input [isDisabled]="true" [initialPlaceHolder]="'Ingresar porcentaje'"
            [value]="getValueBoR(indicatorsTags.reduction, 'percentage')" 
            [showAuxiliarText]="true" [auxiliarText]="'%'"
            (valueOut)="setValueBoR($event, indicatorsTags.reduction, indicatorsTags.value, '%')"></number-input>
        </div>

        <div class="field" *ngIf="indicator.reduction.value>0 && indicator.reduction.type !='%'">
            <radio-button-input [options]="getOptionsBoR()" (valueOut)="setTypeCoin($event, 'reduction')"
            [value]="indicator.reduction.type"></radio-button-input>
        </div>
    </div>

    <button class="filled-button" *ngIf="showButtonSave" (click)="saveIndicator()">
        Aplicar
    </button>
</div>