import {  Amount, BusinessParticularities, Gravanz, Conditional,AgreeWith, Bonification, FreightPaid, GrassiPlants, Reduction, SiloBolsas, Accumulator } from "../interfaces/business-particularities";
import { FinancialAdvance } from "../interfaces/price-to-fix.interface";

export let AMOUNT_VOID:Amount = {
	typeCoin: '--',
	quantity: -1
}

//_------------------------------------_\\

export let ACCUMULATOR_VOID:Accumulator = {
	isAccumulator: -1,
	typeAccumulator: -1
}

export let GRAVANZ_VOID:Gravanz= {
	isGravanz:-1,
	isOwnSensorization: -1,
	business: ' ',
	selectionStock: ' '
}


export let CONDITIONAL_VOID: Conditional={
	selection: -1,
	strike: -1,
	position: ' '
}

export let FREIGHT_PAID_VOID:FreightPaid= {
	isFreightPaid: -1,
	isCustomerFreight: -1,
	amount: AMOUNT_VOID,
	numberOfDays: -1
}

export let GRASSI_PLANTS_VOID:GrassiPlants= {
	placeOfDelivery: -1,
	parity:  AMOUNT_VOID,
	volatileWaste: {
		percentage: -1,
		productDescription: ' ',
	},
	commission: {
		isHoard: -1,
		percentage: -1,
	},
	freight: {
		amount: AMOUNT_VOID,
		description: ' ',
	},
	humidity:{
		isPortFee: -1,
		amount: AMOUNT_VOID, 	//(always)
		percentage: -1,	//(always)
		reductionPoint: -1, //(always)
		reductionAmount: -1 //('only in isPortFee:true')
	}
}

export let SILO_BOLSAS_VOID:SiloBolsas={
	quantity: -1,
	brand: -1,		
	size:-1,
}

export let BONIFICATION_VOID:Bonification={
	typeBonification: -1,
	startDate: '--', 	//(only in 'Entrega anticipada')
	endDate:'--',	//(only in 'Entrega anticipada')
	placeOfDelivery: -1, //(only in 'Cambio de destino')
	amount: AMOUNT_VOID
}

export let REDUCTION_VOID:Reduction={
	percentage: -1,
	amount: -1,
	typeCoin: "--", //AMOUNT_VOID
}

export let AGREE_WITH_VOID:AgreeWith={
	comercialName: ' ',
	buyer: ' ',
	responsable:' '
}

export let FINANCIAL_ADNAVANCE_VOID:FinancialAdvance = {
    amountRate: 0,
    percentagePayable: 0,
    percentageTypeToPay: 0,
    rate: 0,
    VAT: 0
}


export let BUSINESS_PARTICULARITIES_VOID:BusinessParticularities = {
	washOut: -1,
	gravanz: GRAVANZ_VOID,
	accumulator: ACCUMULATOR_VOID,
	conditional: CONDITIONAL_VOID,
	collectionClause: -1,
	respectVatException:-1,
	freightPaidByTheBuyer: FREIGHT_PAID_VOID,
	agreement: -1,
	deliveriesAtGrassiPlant: GRASSI_PLANTS_VOID,
	deliveryInSiloBolsas: SILO_BOLSAS_VOID,
	bonification: BONIFICATION_VOID, 
	reduction: REDUCTION_VOID,
	laboratoryAgreement: -1,
	sealedToRetrieve: -1,
	toWithdraw:-1,
	agreedWith: AGREE_WITH_VOID,
	isAVC: -1,
	subdivisionSyngenta: -1,
	nroBilling:'',
    guaranteedPAF: false,
    financialAdvance: FINANCIAL_ADNAVANCE_VOID,
	campus: '',
	nutrienAgreement: false
}

export let SEDE_AGRUPACION:any={
	'CC': 'Casa Central',
	'CF': 'Capital Federal',
	'CO': 'Córdoba',
	'RC': 'Río Cuarto',
	'RE': 'Resistencia',
	'RO': 'Rosario',
	'SF': 'Santa Fe',
}

//_------------------------------------_\\
export let MARCA_SILO_BOLSAS:any={
	1:'Ipesa',
	2:'Graner',
	3:'Plastar',
	4:'Thyssen', 
}

export let TAMAIN_SILO_BOLSAS:any={
	1:'9 x 60',
	2:'9 x 75',
	3:'9 x 100',
}