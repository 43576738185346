import { Component, OnDestroy, OnInit,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterIndex, setHistorical, setOrdersSelected } from 'src/app/redux/actions/order.action';
import { areas, REPRESENTATION_CONST_ARRAY } from 'src/app/shared/const/user.const';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import * as dict from 'src/app/shared/dict/dict';
import { ViewOrder, ViewPrice } from 'src/app/shared/interfaces/views.interface';
import { orderOriginConst, orderStatusConst } from 'src/app/shared/const/orders';
import { DateChecker } from 'src/app/shared/date.checker';
import { OrderValidator, getOrderListWithPipes } from 'src/app/shared/order';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { teams } from 'src/app/shared/dict/user';
import { orderStates } from 'src/app/shared/dict/orders';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { PriceValidator } from 'src/app/shared/price';
import { Price, PriceString } from 'src/app/shared/interfaces/price.interface';
import { buyerType, setViewMobile, typeOfHistorical } from 'src/app/shared/const/options';
import { buyers } from 'src/app/shared/dict/buyers';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { conditionType } from 'src/app/shared/const/options';
import { getMinHeight } from 'src/app/pages/order-list/body-order-list/order-table/order-table.component';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // Aplica la estrategia OnPush
})
export class TableComponent implements OnInit, OnDestroy {
  arrayOfHistorical:any[]=[];
  arrayHistoricalWithPipe:any[]=[]
  isComercial: boolean = true;
  isRepresentation: boolean = true;
  user!:User;
  isViewMobile = true;

  //Other filter of tabla
  orderFilter!: OrderString;
  exchangeFilter!:OrderString;
  priceFilter!: PriceString;
  conditionTypeSelected!: string;
  conditionTypes=conditionType;
  conditionFilters:number[]=[];
  search: string = '';

  buyerType!:string;
  buyerTypes=buyerType;
  

  //Arrays
  STATUS: any = [];
  BUSINESS_TYPE: any = [];
  PLACE_OF_DELIVERY: any = [];
  QUALITY: any = [];
  TYPE_COIN: any = [];
  WAY_PAY: any = [];
  PRODUCTS: any = [];
  OBSERVATIONS: any = [];
  HARVEST: any = [];
  SELLERS: any = [];
  TABLES:any=[];
  BUYERS:any=[];

  ORDER_ORIGIN_CONST:any = orderOriginConst;


  //The column selected to apply a filter
  filterIdentificator: number = -1;
  optionIdentificator: number = -1;

  //The columns that will be seen in the table
  viewSelected!: ViewOrder | ViewPrice | any;

  ordersSelected: Order[] = [];

  public wasInside: boolean = false; //Inside in menu option;

  TYPE_OF_HISTORICAL=typeOfHistorical;
  typeOfHistorical:string='';

  private usuario$!:Subscription;
  private historical$!:Subscription;
  private historicalArray$!:Subscription;
  private filters$!:Subscription;
  private viewOrder$!:Subscription;
  private indexOrder$!:Subscription;
  private filterOrder$!:Subscription;
  private ordersSelected$!:Subscription;
  private sizeView$!:Subscription;
  private search$!:Subscription;
  private conditionType$!:Subscription;
  private conditionFilters$!:Subscription;

  constructor(public store: Store<appState>, public router: Router, public date: DateChecker, 
    public orderV:OrderValidator, public priceV:PriceValidator, public toFixV:ToFixValidator,
    public cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.setArrays();
    this.setSubscriptions();
  }

  ngOnDestroy(){
    this.setUnsubscriptions();
    this.store.dispatch(setOrdersSelected({ orders: [] }));
  }

  setSubscriptions(){
    this.viewOrder$=this.store.select('order', 'viewsOfOrderBoard', 'viewSelected').subscribe((viewSelected) => {
      this.viewSelected = viewSelected;
      this.setNewOrdersPipe()
    });

    this.indexOrder$=this.store.select('order', 'orderBoard', 'indexFilter').subscribe((indexFilter) => {
      this.filterIdentificator = indexFilter;
    });

    this.search$=this.store.select('options', 'board', 'search').subscribe((search) => {
      this.search = search;
      this.setNewOrdersPipe();
    });

    this.filterOrder$=this.store.select('order', 'orderBoard', 'filter').subscribe((filter) => {
      this.orderFilter = filter;
      this.setNewOrdersPipe();
    });

    this.ordersSelected$=this.store.select('order', 'orderBoard', 'ordersSelected').subscribe((orderSelected) => {
      this.ordersSelected = orderSelected;
    });

    this.usuario$=this.store.select('usuario').subscribe(user => {
      this.user=user;
      this.isComercial = user.area == areas.comercial;
      this.isRepresentation=REPRESENTATION_CONST_ARRAY.includes(user.workUnit);
    })

    //-------- OPTIONS ---------\\
    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.isViewMobile= setViewMobile(sizeView);
    });

    this.historical$=this.store.select('historical').subscribe(historical => {
      this.typeOfHistorical=historical.typeOfHistorical;
    });

    this.historicalArray$ = this.store.select('historicalArray').subscribe(historicalArray => {
      this.arrayOfHistorical=historicalArray?.array ?? [];
      this.setNewOrdersPipe()
    });

    this.conditionType$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe((conditionType) => {
      this.conditionTypeSelected = conditionType;  
      this.setNewOrdersPipe();
    });

    this.conditionFilters$=this.store.select('options','board','conditionFilter').subscribe((conditionFilters)=>{
      this.conditionFilters=conditionFilters;
      this.setNewOrdersPipe()
    })
    
  }

  setUnsubscriptions(){
    this.usuario$?.unsubscribe();
    this.historical$?.unsubscribe();
    this.historicalArray$?.unsubscribe();
    this.filters$?.unsubscribe();
    this.viewOrder$?.unsubscribe();
    this.indexOrder$?.unsubscribe();
    this.filterOrder$?.unsubscribe();
    this.ordersSelected$?.unsubscribe();
    this.sizeView$?.unsubscribe();
    this.search$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.conditionFilters$?.unsubscribe();
  }

  setArrays() {
    this.STATUS = orderStates;
    this.BUSINESS_TYPE = typeBusiness;
    this.QUALITY = dict.quality;
    this.PLACE_OF_DELIVERY = dict.puertos;
    this.TYPE_COIN = dict.typeCoin;
    this.WAY_PAY = wayPay;
    this.PRODUCTS = PRODUCT_NAMES;
    this.HARVEST = this.date.getHarvest();
    this.SELLERS = sellerWithData;
    this.TABLES= teams;
    this.BUYERS=buyers;
  }

  setNewOrdersPipe(){
    this.cdr.detectChanges();
  }
  
  getOrdersPipe(orders:Order[]){
    return getOrderListWithPipes(orders, this.user, this.orderFilter, this.search, buyerType.ALL, this.conditionTypeSelected, this.conditionFilters, true)
  }

  isOrderInOrderList(order: Order): boolean {
    return this.orderV.isOrderInOrderList(order, this.ordersSelected);
  }

  //It is a different function for business users and operators.
  selectFilter(index: number) {
    if (index == this.filterIdentificator) {
      this.closeAllFilters();
    } else {
      //this.store.dispatch(setIndexViewSelected({index:-2})); //Reset when we selected an filter
      this.store.dispatch(setFilterIndex({index: index}));
    }
  }

  closeAllFilters() {
    this.store.dispatch(setFilterIndex({index: -1}));
  }

  selectOrder(order: Order) {
    if(order._id){
      const positionInOrderList = this.orderV.indexOfOrder(this.ordersSelected, order);
      const ordersSelectedAux = [...this.ordersSelected];
      //Is element in array
      if (positionInOrderList != -1) {
        ordersSelectedAux.splice(positionInOrderList, 1);
      } else {
        ordersSelectedAux.push(order);
      }
  
      this.store.dispatch(setOrdersSelected({ orders: ordersSelectedAux }));
    }
  }

  //---------------DATE-----------------\\
  parseDate(date: any) {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-');
  }

  //-----------------------------------------------\\

  //MENU - OPTIONS
  openOptions(index: number) {
    if (index == this.optionIdentificator) {
      this.closeOptions();
    } else {
      this.optionIdentificator = index;
      this.wasInside = true;
    }
  }
  
  //Historical
  showHistorical(order:Order){ 
    this.store.dispatch(setHistorical({historical:order._id}));
    this.closeOptions();
  }
  closeOptions() {
    this.openOptions(-1);
    this.wasInside = false;
  }

  /*@HostListener('document:click', ['$event']) clickInside(event: any) {
    try {
      if(this.optionIdentificator>-1){
        const classNames: string = event.target.classList.value;
        if (classNames.length > 0) {
          if (!this.wasInside && !classNames.includes('options')) {
            this.optionIdentificator = -1;
          }
        }
      }
    } catch (err) {
      console.error('Esta es el error', err);
    }
    this.wasInside = false;
  }*/

  /* FILTERS, SELECTS and CHECKED */
  checkedAllItems() {
    this.store.dispatch(setOrdersSelected({ orders: this.getCheckedAll()? []:this.getOrdersPipe(this.arrayOfHistorical)  }))
  }

  getShowButtonsToFix() {
    return (this.conditionTypeSelected === this.conditionTypes.TO_FIX) &&
    (this.typeOfHistorical === this.TYPE_OF_HISTORICAL.ORDER || this.typeOfHistorical === this.TYPE_OF_HISTORICAL.PRICE);
  }

  getCheckedAll(){
    return JSON.stringify(this.getOrdersPipe(this.arrayOfHistorical)) == JSON.stringify(this.ordersSelected);
  }

  getSellerName(order:Order){
    return this.orderV.getSellerName(order);
  }
  
  getQuality(order:Order){
    return this.orderV.getQuality(order);
  }

  getQuotasMessage(order:Order){
    return this.orderV.getQuotasMessage(order);
  }

  getMessage(buyer: any) {
    return this.orderV.getRestriction(buyer);
  }

  getDeliveryPeriod(order:Order){
    return this.orderV.getDeliveryPeriod(order);
  }

  getWayPay(order:Order){
    return this.WAY_PAY[order.price.wayPay.wayPayName];
  }

  getPlaceOfDelivery(order:Order){
    return this.orderV.getPlaceOfDelivery(order.price.placeOfDelivery);
  }

  getPesification(order:Order){
    return this.orderV.getPesification(order);
  }

  getBossNameCreator(order:Order){
    return this.orderV.getBossNameCreator(order);
  }

  getNameOfCreator(order:Order){
    return this.orderV.getNameOfCreator(order);
  }

  getBusinessParticularities(businessParticularities:BusinessParticularities){
    return this.orderV.getBusinessParticularities(businessParticularities);
  }
  
  haveQuotas(order:Order){
    return this.orderV.haveQuotas(order);
  }

  getIsFilter(tag:string,subTag:string){
    try{
      const newOrderFilter=JSON.parse(JSON.stringify(this.getFilter()));
      let field:any='';
      if(tag && subTag){
        field=newOrderFilter[tag][subTag];
      } else {
        field=newOrderFilter[tag];
      }
      if(this.isArray(field) || typeof field == 'string'){
        return field.length>0; //If it is array or string, we see if its length is greater than 0
      } else if(typeof field=='number'){
        return field>0; //If it is a number, we see if it is greater than 0
      } else if(subTag=='deliveryPeriod'){
        return field.startDate.length>2 || field.endDate.length>2;
      } else if(subTag=='placeOfDelivery'){
        return field.zone.length>0
      } else if(subTag=='price'){
        return field.start>0 || field.end>0 || field.typeCoin!='ALL';
      } else if(tag=='tons'){
        return field.start>0 || field.end>0;
      } else {
        return false;
      }
    }catch(err){
      return false;
    }
  }

  getFilter(){ }

  //Saber si un arreglo es de tipo array
  isArray(value: any) {
    return Array.isArray(value);
  }

  haveIndicators(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  arrayOfShowParents:any[]=[];
  /*Listens to the click event on a date and is passed a Price as a parameter.
  If the price has indicators, a dropDown is performed for the conditions to be set.
  If the price does not have indicators, the dropDown is for the variants of the condition.*/
  clickInArrow(element:Price|Order, event:any){
    const id=element._id;
    if( this.isIdInArrayParent(id) ){
      this.arrayOfShowParents.splice(this.arrayOfShowParents.indexOf(id),1);
    } else {
      this.arrayOfShowParents.push(id);
    }
  }

  getMinHeight(table:any){
    return getMinHeight(table);
  }
  
  isIdInArrayParent(id:string){
    return this.arrayOfShowParents.indexOf(id) != -1;
  }

  getArrowDirection(id:string):string{
    return getArrowDirectionByBool(this.isIdInArrayParent(id));
  }
  
  pauseActualizations(){
    pauseActualizations(this.cdr);
  }

selectOptionMenuOrder(event:OptionMenu, order:Order){
  switch(event.action){
    case OPTION.HISTORICAL: this.showHistorical(order); break;
    /*case OPTION.COPY:this.editOrder(order);break;
    case OPTION.DETAILS: this.showOrderDetail(order); break;*/
  }
 }

}

export function pauseActualizations(cdr:ChangeDetectorRef){
  cdr.detach();
  setTimeout( () => {
    cdr.reattach();
  },100)
}