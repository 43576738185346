import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cross-svg',
  templateUrl: './cross.component.html',
  styleUrls: ['./../../svg.scss']
})
export class CrossSvgComponent implements OnInit{
  @Input() active:boolean=false;
  @Input() direction:string='';
  @Input() color: string='';
  @Input() size: number= 28;
  public style={
    width: '28px',
    height: '28px',
  }
  constructor() { }

  ngOnInit(): void {
    this.style.width=this.size+'px';
    this.style.height=this.size+'px';
  }

}
