<div class="body">

    <div class="header">
        <span class="add-buyer title">Compradores</span>
        <cross-svg class="cross" (click)="close()"></cross-svg>
    </div>

    <p class="description">Seleccioná la cantidad de compradores para aplicar en la condición de negocio.</p>
    <div class="field">
        <span class="field-title">Compradores</span>
    
        <div>
            <div class="form-field" [ngClass]="{'border-green': true}">
                <span class="title-field {{identifyClassName}}"> 
                    <input #inputName autofocus (focus)="focusField()" 
                    (blur)="unfocusField()" [(ngModel)]="search"
                    value="{{getValue()}}" placeholder="{{initalElementSelected}}" 
                    class="{{identifyClassName}} no-background-border" />
                    <!-- <arrow-svg  [active]="true" [direction]="arrowDirection"></arrow-svg> -->
                    <img (mouseup)="focusInInput(inputName)" alt="arrow-green" class="up {{identifyClassName}}"
                        src="../../../../../../assets/images/extra/arrow-down-green.svg" />
                </span>
            </div>
        </div>
    </div>
    <div class="options-selected">
        <div class="option-selected" *ngFor="let option of options">
            <span class="text">{{OBJECT[option]}}</span>
            <cross-svg (click)="removeOption(option)" class="pointer" [size]="12" [style]="{height: '22px'}"></cross-svg>
        </div>
    </div>
    
    <div [ngClass]="{'selected':selectedIndex}"></div>

    <div class="options-buyers width-100">
        <div class="row-between width-100 option-b pointer" 
        *ngFor="let element of array | filterByObject: search:OBJECT:'buyer'; index as i" 
        [ngClass]="{'selected': OBJECT[element]==elementSelected}" (click)="changeSelectedOption(element, inputName)">
            <span>{{OBJECT[element]}} - {{element}}</span>
            <input type="checkbox" [checked]="isOptionInOptionsSelected(element)" />
        </div>
    </div>
    
    <div class="bottom-fixed">
        <button class="filled-button" (click)="applyChanges()"><span>Aplicar</span></button>
    </div>
</div>