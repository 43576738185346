import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PRICE_STATUS_CONST } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Price } from 'src/app/shared/interfaces/price.interface';
import { PriceValidator } from 'src/app/shared/price';

@Component({
  selector: 'app-featured-prices',
  templateUrl: './featured-prices.component.html',
  styleUrls: ['./featured-prices.component.scss', './../../../../shared/styles/empty-state.scss']
})
export class FeaturedPricesComponent implements OnInit {
  public featuredPrices: Price[]=[];

  private allPrices$!:Subscription;

  constructor(private store: Store<appState>, private priceV:PriceValidator) {}

  ngOnInit(): void {
    this.allPrices$=this.store.select('price', 'priceBoard', 'all').subscribe(allPrices => {
      this.loadFeaturedPrices(allPrices); 
    })
  }

  ngOnDestroy(): void {
    this.allPrices$?.unsubscribe();
  }

  loadFeaturedPrices(prices:Price[]){
    let newPrices=prices;

    if(newPrices){
      newPrices.forEach( (price:Price) => {
        if(price.observations.isFeatured == 1 && price.status!= PRICE_STATUS_CONST.INACTIVO && 
          !this.priceV.isPriceInPriceList(price, this.featuredPrices)){
          if(this.featuredPrices.length <5){
            this.featuredPrices.push(JSON.parse(JSON.stringify(price)));
          }
        }
      })
    } 
  }
}