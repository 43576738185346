import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { PriceValidator } from 'src/app/shared/price';
import { CardPrice } from '../price-card';

@Component({
  selector: 'app-price-market-card',
  templateUrl: './price-market.component.html',
  styleUrls: ['./price-market.component.scss']
})
export class PriceMarketCardComponent extends CardPrice {

  constructor(store:Store<appState>, router:Router, priceV:PriceValidator) {
    super(store, router, priceV)
  }

}