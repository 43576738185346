import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicatorsSlideCardComponent } from './to-fix-indicators.component';
import { OptionsModule } from '../../fields/business-particularities/options/options.module';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { IndicatorModule } from '../../fields/to-fix-indicators/indicator/indicator.module';
import { PlusSignGreenSvgModule } from 'src/app/shared/styles/svg/plus-sign-green/plus-sign-green.module';
import { IndicatorsListModule } from '../../details/indicators-list/indicators-list.module';

@NgModule({
  declarations: [
    IndicatorsSlideCardComponent
  ],
  imports: [
    CommonModule,
    OptionsModule,
    CrossSvgModule,
    IndicatorModule,
    PlusSignGreenSvgModule,
    IndicatorsListModule
  ], exports:[
    IndicatorsSlideCardComponent
  ]
})
export class IndicatorsSlideCardModule { }
