<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(16)">Pactado con</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="isPactadoConActive()" (change)="setOptionOpenWithCheck($event,16)" (click)="verifyPactadoCon($event)" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <div class="field" style="margin-bottom:8px">
            <span class="field-title">Nombre comercial<span class="asterisk">*</span></span>

            <text-input [initialPlaceHolder]="'Ingresar nombre y apellido'" [value]="agreeWith.comercialName" 
            (valueOut)="setValue($event, 'comercialName')"></text-input>
        </div>

        <div class="field" style="margin-bottom:8px">
            <span class="field-title">Comprador<span class="asterisk">*</span></span>

            <text-input [initialPlaceHolder]="'Ingresar comprador'" [value]="agreeWith.buyer" 
            (valueOut)="setValue($event, 'buyer')"></text-input>
        </div>

        <div class="field" style="margin-bottom:8px">
            <span class="field-title">Responsable<span class="asterisk">*</span></span>

            <text-input [initialPlaceHolder]="'Ingresar nombre y apellido'" [value]="agreeWith.responsable" 
            (valueOut)="setValue($event, 'responsable')"></text-input>
        </div>
       
    </div>
</div>