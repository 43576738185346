import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataProfileComponent } from './data-profile.component';

@NgModule({
  declarations: [
    DataProfileComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    DataProfileComponent
  ]
})
export class DataProfileModule { }