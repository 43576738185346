import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setOrderToGenerate, setValidation } from 'src/app/redux/actions/order.action';
import { exchangeTags } from 'src/app/shared/const/exchange-orders';
import { HOW_SELL_CONST } from 'src/app/shared/const/how-sell';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags, PLACE_OF_ORIGIN_VOID } from 'src/app/shared/const/orders';
import { REPRESENTATION_CONST } from 'src/app/shared/const/user.const';
import { CODE_BUYER_SELLER } from 'src/app/shared/dict/ibm/cercanj-ibm';
import { matchProvincesWithSellers } from 'src/app/shared/dict/provinces/provinces';
import { SellerIndividual, sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Commission, Order, ValidationStruct } from 'src/app/shared/interfaces/order.interface';
import { OrderValidator, isCodeCommoditiesOrHughesBuyer, isCommoditiesOrHughes, isNutrienCuit, isSellerCommoditiesOrHughes } from 'src/app/shared/order';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { OrderService } from 'src/app/shared/services/order.service';
import { Field } from '../fields.component';
import { WayPay } from 'src/app/shared/interfaces/price.interface';
import { DEFAULT_WAY_PAY_WITH_COMMODITIES_HUGHES, WAY_PAY_VOID } from 'src/app/shared/const/wayPay';
import { priceTags } from 'src/app/shared/const/prices.const';
import { wayPayNames } from 'src/app/shared/dict/wayToPay';

@Component({
  selector: 'app-seller-name',
  templateUrl: './seller-name.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class SellerNameComponent extends Field implements OnInit, OnDestroy {
  userWorkUnit:number=1;
  validationS!:ValidationStruct; //Validacion de la estructura del vendedor.
  messageErrorSeller:string='';
  arrayAux: any[] = [];

  isExchange:boolean=false; //Para saber si el vendedor es canjeador
  isSyngenta:boolean=false; //Para saber si el vendedor es Syngenta
  isSancor:boolean=false; //Para saber si el vendedor es Sancor
  isCuitNutrien:boolean=false; //Para saber si el vendedor es Nutrien (tiene muchas cuentas)

  private orderToField$!: Subscription;
  private user$!:Subscription;
  constructor(store: Store<appState>, private orderV:OrderValidator, private orderSvc:OrderService) {
    super(store);

    this.array = Object.keys(sellerWithData);
    //this.array.shift();
    this.OBJECT=sellerWithData;
    this.deleteLastValueInArray();
    this.initalElementSelected = 'Ingresar un vendedor';
    this.pipe= new FilterByObjectPipe();
    
    this.setSubscriptions();
  }

  ngOnInit(): void {
    this.resetTag();
    this.subTag = orderTags.codeS;
    this.setIdentifyClassName();
  }

  setSubscriptions(){
    this.user$=this.store.select('usuario').subscribe(user => {
      this.userWorkUnit=user.workUnit;
      if(user.workUnit!=0){
        this.filterSellers()
      }
    });

    this.orderToField$=this.store.select('order').subscribe(order => {
      this.validationS=order.validation;
      this.isExchange=order.validation.isExchanger;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.orderToField$?.unsubscribe();
    this.user$?.unsubscribe();
  }

  override setElement(element: any, index: number) {
    this.resetTag();

    //We get the validations for this vendor
    //Cuando no estamos haciendo algo de canje, seteamos las validaciones del vendedor
    if(this.fieldIdentificator!=fieldType.EXCHANGE && this.fieldIdentificator!=fieldType.EXCHANGE_BUYER){
      this.getValidationForSeller(Number(element));
    }

    //We set the codeS field to generate order
    this.subTag=orderTags.codeS;
    this.setElementToGenerate(Number(element));

    //We set the province in generate order
    //Cuando no es el campo de canje, seteamos automaticamente el place of origin.
    //Seteamos el CUIT
    //Seteamos la forma de pago dependiendo el comprador y vendedor
    if(this.fieldIdentificator!=fieldType.EXCHANGE && this.fieldIdentificator!=fieldType.EXCHANGE_BUYER){
      
      this.setPlaceOfOriginInOrder(element); //Seteamos el lugar de origen segun vendedor
      this.setCuitInOrder(element); //Seteamos el cuit según el vendedor
      this.setWayPay(Number(element));


    } else if(this.fieldIdentificator==fieldType.EXCHANGE_BUYER){
      this.tag=exchangeTags.exchange;
      this.subTag=orderTags.placeOfOrigin;
      this.thirdTag=orderTags.province;
      const provinceToSetDefault= this.getProvince(this.OBJECT[element]);
      this.setElementToGenerate(provinceToSetDefault);
  
      //We set the town in generate order
      const localities= this.orderV.getLocality(provinceToSetDefault);
      const afipAndLocality:any=this.getLocality(this.OBJECT[element], localities);
      if(afipAndLocality){
        this.thirdTag=orderTags.town;
        const indexOfTown=this.getIndexToTown(afipAndLocality.location, localities) + 1;
        this.setElementToGenerate(indexOfTown);
  
        this.thirdTag=orderTags.afip;
        this.setElementToGenerate(Number(afipAndLocality.afip));
      }
      this.thirdTag='';
      this.subTag='';
    }

    //Cuando el campo no es de canje, seteamos la comision;
    if(this.fieldIdentificator!=fieldType.EXCHANGE && this.fieldIdentificator!=fieldType.EXCHANGE_BUYER){
      this.setCommission();
    }

    this.resetTag();
  }

  setPlaceOfOriginInOrder(element:any){
    this.tag=orderTags.placeOfOrigin;
    this.subTag=orderTags.province;
    const provinceToSetDefault= this.getProvince(this.OBJECT[element]);
    this.setElementToGenerate(provinceToSetDefault);

    //We set the town in generate order
    const localities= this.orderV.getLocality(provinceToSetDefault);
    const afipAndLocality:any=this.getLocality(this.OBJECT[element], localities);
    if(afipAndLocality){
      this.subTag=orderTags.town;
      const indexOfTown=this.getIndexToTown(afipAndLocality.location, localities) + 1;
      this.setElementToGenerate(indexOfTown);

      this.subTag=orderTags.afip;
      this.setElementToGenerate(Number(afipAndLocality.afip));
    }
  }

  setCuitInOrder(element:any){
    //Seteamos los valores en CUIT y lo almacenamos en la orden.
    this.tag=orderTags.sellerData;
    this.subTag=orderTags.cuit;
    const cuit= this.OBJECT[element]?.cuit ?? '';
    this.setElementToGenerate(cuit);
  }

  setWayPay(codeS: number){
    const potentialBuyer= this.elementToGenerate.buyer.onlySell[0];
    if(isCodeCommoditiesOrHughesBuyer(potentialBuyer) && isSellerCommoditiesOrHughes(codeS)){
      this.tag=orderTags.price;
      this.subTag=priceTags.wayPay;
      this.thirdTag='';

      this.setElementToGenerate(DEFAULT_WAY_PAY_WITH_COMMODITIES_HUGHES);

      this.resetTag();
    }
  }
  
  override rememberValueInForm(){
    if(this.subTag==orderTags.codeS){
      try {
      //this.rememberValueTypeOne();
      let valueToSet: any = this.rememberValue();
      //If There is a value to remember
      if (valueToSet != -1 && this.elementToGenerate && valueToSet) {
        this.isSyngenta=this.orderV.isSyngenta(valueToSet);
        this.isSancor=this.orderV.isSancor(valueToSet);
        this.elementSelected = this.OBJECT[valueToSet].nombre;
        this.search = this.elementSelected;
        this.indexOption = this.getIndexOfArray(valueToSet.toString(), this.array);
        this.isCuitNutrien= isNutrienCuit(this.OBJECT[valueToSet]?.cuit ?? '');
        this.setIsOptionSelected(true);

        //Si la validacion no está completa la seteamos y no estamos haciendo algo de canje.
        if(!this.validationS.isCompleted && this.fieldIdentificator!=fieldType.EXCHANGE && this.fieldIdentificator!=fieldType.EXCHANGE_BUYER){
          this.getValidationForSeller(valueToSet);
        }

      } else {
        this.setValuesVoids();
      }
    } catch (err) {
      this.setValuesVoids();
    }
    }
  }

  setValuesVoids(){
    this.isSyngenta=false;
    this.isSancor=false;
    this.isCuitNutrien=false;
    this.elementSelected = '';
    this.search = '';
    this.placeHolder="";
    this.indexOption = -1;
    this.setIsOptionSelected(false)
  }

  getProvince(seller:any){
    let provincesMatch:any=matchProvincesWithSellers;
    return provincesMatch[seller?.cgoProv] ?? -1;
  }

  getLocality(seller:SellerIndividual, localities:any){
    //Si la provincia del seller es caba, seteamos ciudad autonoma de buenos aires
    if(localities.length==1){ //Estamos en caba
      return localities[0]; //Retornamos Capital Federal
    }

    //Filter an array of localities ({afip: number, location:string})
    //Donde seller.localidad sea igual a location (con lowerCase)
    //En caso de no encontrar, buscamos a ver si incluye a location (con lowerCase)
    let filteredLocalities=localities.filter((locality:any)=>{
      return locality.location.toLowerCase()==seller.localidad.toLowerCase()
    });

    if(filteredLocalities.length==0){
      filteredLocalities=localities.filter((locality:any)=>{
        return locality.location.toLowerCase().includes(seller.localidad.toLowerCase());
      });
    }

    return filteredLocalities[0];
  }

  getIndexToTown(element:any, object:any){
    let index=-1;
    try{
    for(let i=0; i<object.length; i++){
      if(object[i].location==element){
        index=JSON.parse(i.toString());
        throw new Error();
      }
    }
    } catch(err){
    }
    return index;
  }

  resetTag(): void {
    if(this.fieldIdentificator==fieldType.EXCHANGE){
      this.tag=exchangeTags.buyer
    } else {
      this.tag=orderTags.sellerData;
      this.subTag = orderTags.codeS;
    }
  }

  override setPlaceHolder(){
    try{
      let newList = this.pipe.getOnlyOneSeller(this.array, this.search, this.OBJECT);
      if(newList.length > 0 && this.search){
        this.placeHolder= this.removeFirstLetters(this.OBJECT[newList[0]].nombre, this.search);

        if(this.setSearchIndex){
          this.indexOption= this.getIndexOfArray(newList[0], this.array);
        } else {
          this.setSearchIndex=true;
        }
      } else {
        this.placeHolder= '';
        this.indexOption= -1;
      }
    } catch (err) {
      this.placeHolder='';
    }
  }

  setSearchIndex:boolean=false;

  override  moveArrow(direction:string){
    if(this.search!='' || this.placeHolder!=''){
      let array= this.pipe.transform(this.array, this.search, this.OBJECT, orderTags.codeS);
      let indexInArray=this.getIndexOfArray(this.array[this.indexOption], array);
      direction=='down'? indexInArray++:indexInArray--;
      let nextElement=array[indexInArray];
      if(nextElement!=undefined){
        let indexFromNextElement=this.getIndexOfArray(nextElement, this.array);
        this.indexOption=indexFromNextElement;
      }
      this.setSearchIndex=false;
    } else {
      let indexInArray=this.getIndexOfArray(this.array[this.indexOption], this.array);
      direction=='down'? indexInArray++:indexInArray--;
      let nextElement=this.array[indexInArray];
      if(nextElement!=undefined){
        this.indexOption=indexInArray;
      } else {
        this.indexOption=0;
      }
    }
  }

  override save() {
    if(this.search.length > 0){
      let newList = this.pipe.getOnlyOneSeller(this.array, this.search, this.OBJECT);
      if (newList.length > 0) {
        this.setElement(this.array[this.indexOption], 0);
      }
      this.search=this.elementSelected;
      this.setChangeShowOptions(false);
    }
  }

  getValidationForSeller(codeS:number){
    this.orderSvc.getValidationForSeller(codeS).then((res:any)=>{
      if(res){
        this.messageErrorSeller='';
        const isExchange:string=res.data.msg.split(',')[1] //Es Canjeador: N
        const SorN=isExchange.charAt(isExchange.length - 1);

        let newValidation:ValidationStruct=JSON.parse(JSON.stringify(this.validationS));
        newValidation.isExchanger=SorN=='S'?true:false;
        newValidation.isCompleted=true;
        newValidation.sellerCode=codeS;

        this.store.dispatch(setValidation({validation:newValidation}));

        if(newValidation.isExchanger){
          let elementToGenerate:Order=JSON.parse(JSON.stringify(this.elementToGenerate));
          elementToGenerate.placeOfOrigin=PLACE_OF_ORIGIN_VOID;
          elementToGenerate.howSell=HOW_SELL_CONST.ACOPIO;
          this.store.dispatch(setOrderToGenerate({ order: elementToGenerate }));

        }
      }
    }).catch((err:any)=>{
      this.messageErrorSeller='No pudimos procesar si el vendedor es canjeador. Desconecte la VPN o hable con el area de sistemas de Grassi (VALICUITCV).';
    });
  }


  setCommission(){
    const commision:Commission=this.orderV.getCommisionByOrder(this.elementToGenerate, 'V');
    this.tag=orderTags.commision;
    this.subTag='';
    this.thirdTag='';
    this.setElementToGenerate(commision);

    this.resetTag();
  }

  /*The function filters the array containing the vendors.
  If the userWorkUnit is different from 1 (Headquarters), it means that we are in a representation, 
  therefore the vendors must be filtered according to the "Suc. Prod" field that is in the vendor structure.

  We perform an IF for each representation so that the calculation 
  to validate which representation we are in, is not repeated for each iteration of the "filter()"*/
  filterSellers(){
    if(this.userWorkUnit!=1){
      const sucursal=this.getSucursalByRepresentation(this.userWorkUnit);
      this.array=this.filterSellersByProdBranch(sucursal);
    }
  }

  /* Given a number that represents our representation, it returns the value it has in IBM. */
  getSucursalByRepresentation(representation:number){
    if(representation==REPRESENTATION_CONST.CORDOBA){
      return 12;
    } else if(representation==REPRESENTATION_CONST.CHACABUCO){
      return 8;
    } else if(representation==REPRESENTATION_CONST.BOLIVAR){
      return 4;
    }
    return 0;
  }

  /* Filters the vendors according to the Prod branch passed by parameter and 
  returns an array with the code of all the filtered vendors.
  Also adds sellers who are redeemers based on the CERCANJ table */
  filterSellersByProdBranch(prodBranch:number){
    const seller_width_data=Object.entries(sellerWithData)
    const quantItems=seller_width_data.length;
    const arrayToReturn=[];
    
    for(let i=0; i<quantItems; i++){
      const sellerData=seller_width_data[i][1];
      const sellerCode=seller_width_data[i][0];
      if(sellerData.sucProd==prodBranch || this.sellerIsExchanger(sellerCode)){
        arrayToReturn.push(sellerCode)
      }
    } 
    return arrayToReturn
  }

  //Based on the CERCANJ table, it checks if the code passed by parameter exists.
  sellerIsExchanger(sellerCode:number|string){
    const exchagers:any=CODE_BUYER_SELLER;
    if(exchagers[sellerCode]){
      return true;
    }
    return false;
  }
}