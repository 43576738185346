import { Component,OnInit,OnDestroy } from '@angular/core';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Store } from '@ngrx/store';
import { SessionChecker } from 'src/app/shared/session.checker';
import { User } from 'src/app/shared/interfaces/user.interface';
import { setLocation } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { Subscription, catchError, of } from 'rxjs';
import { areas } from 'src/app/shared/const/user.const';
import { TablesIBMClass } from 'src/app/shared/tables-ibm';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { ViewsService } from 'src/app/shared/services/views.service';
import { InfoViewUser } from 'src/app/redux/effects/views.effect';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent extends TablesIBMClass implements OnInit, OnDestroy {
  public user!: User;
  public isComercial: boolean = false;
  public isOperator:boolean = false;
  public showMenu!: boolean;  

  private user$!:Subscription;
  private options$!:Subscription;

  constructor(private store: Store<appState>,
    private checker: SessionChecker,maestroSvc:MaestrosService, private viewSvc:ViewsService) {
      super(maestroSvc);
      
    this.checker.rememberAndActualizateUser(this.store);

    this.store.dispatch(setLocation({location: NAVEGATION.HOME}));
  }

  ngOnInit(): void {
    this.setSubscriptions();
    this.checkTables();

    this.saveViews();
  }

  saveViews(){
    this.viewSvc.searchAllViews(this.user.email).pipe(
      catchError(error => {return of(null)}) ).subscribe(data => {
      if (data) {
        // Si existe data, no hacemos nada
      } else {
        // Si no existe data, creamos un documento vacío
        const body:InfoViewUser={
          email: this.user.email,
          viewsControlPanel: this.getView(localStorageNames.viewsControlPanel),
          viewPrice: this.getView(localStorageNames.viewPrices),
          viewExchange: this.getView(localStorageNames.viewExchange),
          viewOrder: this.getView(localStorageNames.viewOrders),
        }

        this.viewSvc.saveNewView(body).pipe(
          catchError(error => {return of(null)})).subscribe(res => {
            //Almacenamos las vistas en base de datos.
        })
      }
    })
  }

  getView(locStr: string) {
    const str=localStorage.getItem(locStr);
    return str? JSON.parse(str) ?? [] : [];
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.options$?.unsubscribe();
  }

  setSubscriptions(){
    this.user$=this.store.select('usuario').subscribe( (usuarioR) => {
      this.user = usuarioR;
      this.isComercial = this.user.area == areas.comercial;
      this.isOperator = this.user.area == areas.commodities;
    })

    this.options$=this.store.select('options', 'showMenu').subscribe( (showMenu) => {
      this.showMenu = showMenu;
    })
  }
}