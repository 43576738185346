import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityParticularitiesComponent } from './quality-particularities.component';
import { CrossSvgModule } from 'src/app/shared/styles/svg/cross/cross.module';
import { QualityIBMModule } from '../../fields/quality-ibm/quality.module';

@NgModule({
  declarations: [
    QualityParticularitiesComponent
  ],
  imports: [
    CommonModule,
    CrossSvgModule,
    //OptionsModule
    QualityIBMModule
  ], exports: [
    QualityParticularitiesComponent
  ]
})
export class QualityParticularitiesSlideCardModule { }