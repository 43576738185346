import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'three-point-svg',
  templateUrl: './three-points-svg.component.html',
  styleUrls: ['./../../svg.scss']
})
export class ThreePointsSvgComponent {
  @Input() active:boolean=false;
  constructor() { }
}
