import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setOrderToGenerate } from 'src/app/redux/actions/order.action';
import { fieldType } from 'src/app/shared/const/options';
import { orderTags, SELLER_DATA_VOID } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { SUBDIVISION_SYNGENTA, SYNGENTA_CODE_BUYER } from 'src/app/shared/dict/syngenta';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { FilterByObjectPipe } from 'src/app/shared/pipes/filter/filter-by-object';
import { Field } from '../fields.component';

@Component({
  selector: 'app-syngenta-subdivision',
  templateUrl: './syngenta.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class SyngentaSubdivisionComponent extends Field implements OnInit {
  showOptionAVC:boolean=false;
  isSelectedAvc:boolean=false;
  constructor(store: Store<appState>) {
    super(store);
    this.OBJECT=SUBDIVISION_SYNGENTA;
    this.array = Object.keys(SUBDIVISION_SYNGENTA);
    this.array.shift();
    this.deleteLastValueInArray();
    this.initalElementSelected = 'Ingresar subdivisión';
    this.pipe=new FilterByObjectPipe();
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.PRICE) {
      this.tag = priceTags.businessParticularities;
    } else {
      this.tag = orderTags.price;
      this.subTag = priceTags.businessParticularities;
    }
    this.setIdentifyClassName();
  }
  
  override setElement(element:any, index:number){
    this.setTag(priceTags.subdivisionSyngenta);
    const value=Number(element);
    this.setElementToGenerate(Number(element));

    /*If value is different from 1, it means that it is not Agro and AVC must be -1*/
    if(value!=1){
      this.setTag(priceTags.isAVC);
      this.setElementToGenerate(-1);
    }
  }

  override rememberValueInForm(){
    this.rememberSyngenta();
  }

  rememberSyngenta(){
    try{
this.setTag('');
let valueToSet: any = this.rememberValue();
let subdivision= valueToSet.subdivisionSyngenta;
let isAVC= valueToSet.isAVC;

if (this.elementToGenerate && valueToSet && subdivision>0) {
  this.elementSelected = this.OBJECT[subdivision];
  this.showOptionAVC= subdivision==1; //If value is Agro (1), the AVC option is displayed
  this.search = this.elementSelected;
  this.indexOption = this.getIndexOfArray(valueToSet, this.array);
  this.setIsOptionSelected(true)
  this.isSelectedAvc= isAVC==1; //If value is Agro (1), the AVC option is displayed

} else if( valueToSet < 0){
  this.elementSelected = '';
  this.search = '';
  this.indexOption = -1;
  this.showOptionAVC=false;
  this.setIsOptionSelected(false)
  this.isSelectedAvc=false;
}
    } catch(err){}
  }

  setAVC(event:any){
    this.isSelectedAvc=event.checked;
    this.setTag(priceTags.isAVC);
    this.setElementToGenerate(this.isSelectedAvc?1:-1);


    //We pass all the information to generate Order because it is the particular case that the commercials are going to use.
    if(this.fieldIdentificator==fieldType.EXCHANGE_BUYER){
      let newOrder:Order=JSON.parse(JSON.stringify(this.elementToGenerate));
      if(this.isSelectedAvc){
        newOrder.sellerData=SELLER_DATA_VOID;
        newOrder.price.observations.buyer=SYNGENTA_CODE_BUYER;
        this.store.dispatch(setOrderToGenerate({order:newOrder}));
      }
    }
  }

  
}
