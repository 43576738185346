import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceBodyComponent } from './body-price-board.component';
import { PriceTableModule } from './price-table/price-table.module';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ViewsPricesModule } from 'src/app/components/views/views-prices/views-prices.module';
import { ThreePointsSvgModule } from 'src/app/shared/styles/svg/three-points/three-points-svg.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { ErrorChangePriceModule } from 'src/app/components/dialogs/error-change-price/error-change-price.module';
import { DetailsEyeModule } from 'src/app/components/slide-cards/details-eye/details-eye.module';
import { HistoricalModule } from 'src/app/components/slide-cards/historical/historical.module';
import { WarningChangeTypeCoinModule } from 'src/app/components/dialogs/currency-exchange-warning/currency-exchange-warning.module';
import { PriceDeleteModule } from 'src/app/components/dialogs/price-delete/price-delete.module';
import { ViewsSectionModule } from 'src/app/components/views-section/views-section.module';
import { IndicatorModule } from 'src/app/components/fields/to-fix-indicators/indicator/indicator.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';
import { SearchInputModule } from 'src/app/components/inputs/search/search.module';
import { ButtonsBuyerTypeModule } from 'src/app/components/buttons-buyer-type/buttons-buyer-type.module';
import { BoardSlideModule } from 'src/app/components/board/slide/slide.module';

@NgModule({
  declarations: [
    PriceBodyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PriceTableModule,
    BoardSlideModule,
    ViewsSectionModule,
    ViewsPricesModule,

    MatDatepickerModule,
    MatNativeDateModule,

    //Slide cards
    DetailsEyeModule,
    HistoricalModule,
    IndicatorModule,

    //SVG
    ThreePointsSvgModule,
    ArrowSvgModule,
    PipesModule,

    //Dialogs
    ErrorChangePriceModule,
    WarningChangeTypeCoinModule,
    PriceDeleteModule,

    SearchInputModule,
    ButtonsConditionTypeModule,
    ButtonsBuyerTypeModule,
  ], 
  exports: [
    PriceBodyComponent,
  ]
})
export class PriceBodyModule { }