<div class="option">
    <div class="option-content-particularity pointer">
        <span class="option-title" (click)="showOptions(12)">Rebaja</span>

        <div class="actions">
            <input type="checkbox" class="checkbox" [value]="optionOpen" [checked]="isReductionActive()" (change)="setOptionOpenWithCheck($event,12)" />
        </div>
    </div>

    <div class="option-values" *ngIf="optionOpen=='down'">
        <span>Ingresar porcentaje y monto</span>
        <div class="field" style="display:flex; flex-direction: row; justify-content:space-between; margin-bottom:8px">
            <div style="width: 48%">
                <number-input [value]="reduction.percentage" [initialPlaceHolder]="'Porcentaje'"
                (valueOut)="setValue($event, 'percentage')"></number-input>
            </div>

            <div style="width: 48%">
                <number-input [value]="reduction.amount" [initialPlaceHolder]="'Monto'"
                (valueOut)="setValue($event, 'amount')"></number-input>
            </div>
        </div>

        <div class="field" *ngIf="reduction.amount>0">
            <radio-button-input [options]="getOptions()" (valueOut)="setValue($event, 'typeCoin')"
            [value]="reduction.typeCoin"></radio-button-input>
        </div>
    </div>


</div>