import { Component, HostListener, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setObjetive } from 'src/app/redux/actions/dashboard.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Objetive } from 'src/app/shared/interfaces/dashboard.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { periods } from 'src/app/shared/dict/objetives'
import { DateChecker, getDate, getDateTomorrow, getDaysBetweenDatesByBusinessDay, getEndOfMonth, isEarlierDate, stringToDate } from 'src/app/shared/date.checker';
import { teams } from 'src/app/shared/dict/user';
import { OrderService } from 'src/app/shared/services/order.service';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { Subscription } from 'rxjs';

const FIRST_TRIMESTER = 100000;
const SECOND_TRIMESTER = 300000;
const TIRTH_TRIMESTER = 200000;
const FOURTH_TRIMESTER = 500000;

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss', './../../../shared/styles/selects.scss']
})
export class GoalsComponent implements OnInit, OnDestroy {

  public objetives!: Objetive; //Store of redux.
  public orders: any = [];

  public TABLES: any;
  public periodSelect: string = periods.WEEKLY;
  public periodInfo: string = '';
  public workUnitInfo: string = '';
  public user!: User;

  //FILTERS
  option: string = '';
  periodString: string = '';
  tableString: string = '';

  public wasInside: boolean=false; //Inside in options;

  private dashboardSubscription!:Subscription;
  private orderSubscription!:Subscription;
  private userSubscription!:Subscription;

  constructor(private store: Store<appState>, private date: DateChecker, private orderSvc:OrderService) {}

  ngOnInit(): void {
    this.setSubscriptions();
    this.TABLES = Object.values(teams);
    this.setSelectTable(this.TABLES[0]);
    this.setPeriodString(periods.WEEKLY);

    this.setWorkUnitInfo();

    this.setPeriodInfo();
    this.setWorkUnitInfo();
    this.setGoals(periods.WEEKLY);
  }

  ngOnDestroy(): void {
    this.dashboardSubscription?.unsubscribe();
    this.orderSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  setSubscriptions(){
    this.dashboardSubscription=this.store.select('dashboard').subscribe(dashboardReducer => {
      this.objetives = dashboardReducer.objetives;
    })

    this.orderSubscription=this.store.select('order').subscribe(orderReducer => {
      this.orders=orderReducer.orderBoard.all;
    })

    this.userSubscription=this.store.select('usuario').subscribe(userReducer => {
      this.user=userReducer;
    })
  }

  openOption(value: string) {
    if (value == this.option) {
      this.option = '';
      this.wasInside=false;
    } else {
      this.option = value
      this.wasInside=true;
    }
  }

  setPeriodInfo() {
    let info = '';
    switch (this.periodSelect) {
      case periods.WEEKLY: info = 'Mostrando semana del ' + this.getParseDate(); break;
      case periods.MONTHLY: info = 'Mostrando mes de ' + this.date.getMonthString(); break;
      case periods.QUARTERLY: info = 'Mostrando ' + this.getMonthsOfQuarterlyInString(); break;
      case periods.YEARLY: info = 'Año ' + new Date().getFullYear(); break;
    }
    this.periodInfo = info;
  }

  getMonthsOfQuarterlyInArray() {
    const date = new Date();
    let valueToReturn: any;

    switch (date.getMonth()) {
      case 0:
      case 1:
      case 2: valueToReturn = ['Enero', 'Febrero', 'Marzo']; break;
      case 3:
      case 4:
      case 5: valueToReturn = ['Abril', 'Mayo', 'Junio']; break;
      case 6:
      case 7:
      case 8: valueToReturn = ['Julio', 'Agosto', 'Septiembre']; break;
      case 9:
      case 10:
      case 11: valueToReturn = ['Octubre', 'Noviembre', 'Diciembre']; break;
      default: valueToReturn = [];
    }

    return valueToReturn
  }

  getParseDate() {
    const date = new Date();
    const yearStr = date.getFullYear().toString();
    const monthStr = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1).toString();
    const dayStr = date.getDate() < 10 ? '0' + date.getDate() : date.getDate().toString();
    return `${dayStr}/${monthStr}/${yearStr}`
  }

  getMonthsOfQuarterlyInString() {
    const date = new Date();
    let valueToReturn: any;

    switch (date.getMonth()) {
      case 0:
      case 1:
      case 2: valueToReturn = 'primer trimestre'; break;
      case 3:
      case 4:
      case 5: valueToReturn = 'segundo trimestre'; break;
      case 6:
      case 7:
      case 8: valueToReturn = 'tercer trimestre'; break;
      case 9:
      case 10:
      case 11: valueToReturn = 'cuarto trimestre'; break;
      default: valueToReturn = '';
    }

    return valueToReturn
  }

  setSelectPeriod(value: any) {
    const period = value;
    this.setGoals(period);
    this.periodSelect = period;
    this.setPeriodInfo();
    this.setPeriodString(value);
  }

  setPeriodString(value:any){
    let info='';
    switch (this.periodSelect) {
      case periods.WEEKLY: this.periodString = 'Semanal'; break;
      case periods.MONTHLY: this.periodString = 'Mensual'; break;
      case periods.QUARTERLY: this.periodString = 'Trimestral'; break;
      case periods.YEARLY: info = 'Anual'; break;
    }
  }

  setSelectTable(value: any) {
    this.tableString = value;
    this.setWorkUnitInfo();
    /*this.setGoals(period);
    this.periodSelect = period;
    this.setPeriodInfo();*/
  }

  setWorkUnitInfo() {
    this.workUnitInfo = 'Mesa '+ this.tableString;
  }

  setGoals(period: string) {
    let objetives: Objetive = {
      grafic: this.getGrafic(period),
      tons: {
        dailySales: 0,
        missing: 0,
        missingAverage: 0,
        quarterly: 0
      }
    };//=JSON.parse(JSON.stringify(this.objetives));

    objetives.tons.quarterly = this.totalTonsOf(period);
    objetives.tons.dailySales = this.totalSellOf(objetives.grafic);
    objetives.tons.missing = objetives.tons.quarterly - objetives.tons.dailySales;
    objetives.tons.missingAverage = Math.round(objetives.tons.missing / this.getBusinessDays(period));
    this.store.dispatch(setObjetive({ objetives: objetives }));
  }

  //---------TOTAL SELL--------\\
  totalSellOf(array: number[]) {
    let valueToReturn = 0;
    array.forEach((value: number) => {
      valueToReturn += value;
    })
    return valueToReturn;
  }

  //--------- GRAFIC -----------\\
  getGrafic(period: string) {
    let grafic: any[] = [];
    if (period == periods.WEEKLY) {
      const range = this.getRangeDates(this.getStartOfWeek(), this.date.getDate());
      grafic = [0, 0, 0, 0, 0]
      this.orders.forEach((element: Order) => {
        if (range.includes(element.dataOfcreation.dateOfcreation)) {
          grafic[this.date.getDay(element.dataOfcreation.dateOfcreation) - 1] += Number(element.tons);
        }
      });
    } else if (period == periods.MONTHLY) {
      const range = this.getRangeNumOfWeek(this.getStartOfMonth(), this.date.getDate());
      grafic = [0, 0, 0, 0, 0]
      this.orders.forEach((element: Order) => {
        const date: string[] = element.dataOfcreation.dateOfcreation.split("-")
        const numOfWeek: number = this.getWeekOfMonth(element.dataOfcreation.dateOfcreation);
        const indexOfWeek = range.indexOf(numOfWeek.toString());
        if (indexOfWeek != -1) {
          grafic[indexOfWeek] += Number(element.tons);
        }
      });


    } else if (period == periods.QUARTERLY) {
      const range = this.getRangeMonths(this.getStartOfQuarter(), this.date.getDate());
      grafic = [0, 0, 0]
      this.orders.forEach((element: Order) => {
        const month: string = element.dataOfcreation.dateOfcreation.split("-")[1]
        if (range.includes(month)) {
          grafic[(Number(month) - 1) % 3] += Number(element.tons);
        }
      });
    } else if (period == periods.YEARLY) {
      const range = this.getRangeMonths(this.getStartOfYear(), this.date.getDate());
      grafic = [0, 0, 0, 0]
      this.orders.forEach((element: Order) => {
        const month: string = element.dataOfcreation.dateOfcreation.split("-")[1];
        if (range.includes(month)) {
          grafic[this.getQuarterByDate(month)] += Number(element.tons);
        }
      });
    }
    return grafic;
  }

  //BUSINESS DAYS
  getBusinessDays(period: string) {
    const date = new Date();
    let remainingBusinessDays = 1;
    if (period == periods.WEEKLY) {
      const saturday = 6;
      remainingBusinessDays = saturday - date.getDay();
    } else if (period == periods.MONTHLY) {
      remainingBusinessDays = getDaysBetweenDatesByBusinessDay(getDate(), getEndOfMonth(new Date()), true)
    } else if (period == periods.QUARTERLY) {
      remainingBusinessDays = getDaysBetweenDatesByBusinessDay(getDate(), this.getEndOfQuarter(), true)
    } else if (period == periods.YEARLY) {
      remainingBusinessDays = getDaysBetweenDatesByBusinessDay(getDate(), this.getEndOfYear(), true);
    }
    return remainingBusinessDays;
  }

  //---------TOTAL TONS---------\\

  totalTonsOf(period: string) {
    if (period == periods.WEEKLY) {
      return this.totalTonsOfWeekly();
    } else if (period == periods.QUARTERLY) {
      return this.totalTonsOfQuarterly();
    } else if (period == periods.MONTHLY) {
      return this.totalTonsOfMonthly();
    } else if (period == periods.YEARLY) {
      return this.totalTonsOfYearly();
    } else {
      return 0;
    }
  }

  totalTonsOfWeekly() {
    return Math.round(this.totalTonsOfMonthly() / 4);
  }
  totalTonsOfMonthly() {
    return Math.round(this.totalTonsOfQuarterly() / 3);
  }

  totalTonsOfQuarterly() {
    const date = new Date();
    let valueToReturn: number;

    switch (date.getMonth()) {
      case 0:
      case 1:
      case 2: valueToReturn = FIRST_TRIMESTER; break;
      case 3:
      case 4:
      case 5: valueToReturn = SECOND_TRIMESTER; break;
      case 6:
      case 7:
      case 8: valueToReturn = TIRTH_TRIMESTER; break;
      case 9:
      case 10:
      case 11: valueToReturn = FOURTH_TRIMESTER; break;
      default: valueToReturn = 0;
    }

    return valueToReturn
  }

  totalTonsOfYearly() {
    return FIRST_TRIMESTER + SECOND_TRIMESTER + TIRTH_TRIMESTER + FOURTH_TRIMESTER;
  }

  @HostListener('document:click',  ['$event']) clickInside(event:any) {
    try{
      const classNames:string=event.target.classList.value;
      if(classNames.length>0){
        if(!this.wasInside && ! classNames.includes('option') ){
          this.option='';
        }
      } 
    } catch(err){
      console.error("Esta es el error", err);
    }
    this.wasInside=false;
  }


  /* ---- FUNCTIONS OF DATES ---- */
  getStartOfYear(): string {
    const date = new Date();
    return `1-1-${date.getFullYear()}`;
  }

  getStartOfWeek() {
    const date = new Date();
    return `${date.getDate() - date.getDay() + 1}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }

  getStartOfMonth() {
    const date = new Date();
    return `${1}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  getStartOfQuarter() {
    const date = new Date();
    switch (date.getMonth() + 1) {
      case 1:
      case 2:
      case 3:
        return '1-1-' + date.getFullYear();
      case 4:
      case 5:
      case 6:
        return '1-4-' + date.getFullYear();
      case 7:
      case 8:
      case 9:
        return '1-7-' + date.getFullYear();
      case 10:
      case 11:
      case 12:
        return '1-10-' + date.getFullYear();
        break;
    }
    return '';
  }

  
  public getEndOfQuarter() {
    const date = new Date();
    switch (date.getMonth() + 1) {
      case 1:
      case 2:
      case 3:
        return '31-3-' + date.getFullYear();
      case 4:
      case 5:
      case 6:
        return '30-6-' + date.getFullYear();
      case 7:
      case 8:
      case 9:
        return '30-9-' + date.getFullYear();
      case 10:
      case 11:
      case 12:
        return '31-12-' + date.getFullYear();
    }
    return '';
  }

  getEndOfYear() {
    const date = new Date();
    return '31-12-' + date.getFullYear();
  }
  
  getWeekOfMonth(date: string): number {
    let currentdate: any = stringToDate(date);
    let oneJan: any = new Date(currentdate.getFullYear(), 0, 1);
    var numberOfDays = Math.floor( (currentdate - oneJan) / (24 * 60 * 60 * 1000) );
    return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  }

  getRangeNumOfWeek(date1: string, date2: string): string[] {
    let valuesToReturn: string[] = [];
    var week1 = this.getWeekOfMonth(date1);
    var week2 = this.getWeekOfMonth(date2);

    for (let i = week1; i <= week2; i++) {
      valuesToReturn.push(i.toString());
    }
    return valuesToReturn;
  }
  
  getRangeDates(date1: string, date2: string): string[] {
    //Validamos si la fecha 1 es mayor que la fecha 2
    if (isEarlierDate(date2, date1, '-')) {
      return [];
    }
    
    let valuesToReturn: string[] = [];
    let dateVariable = date1;
    let cut = false;
    while (!cut) {
      valuesToReturn.push(dateVariable);
      if (dateVariable == date2) {
        cut = true;
      }
      dateVariable = getDateTomorrow(dateVariable);
    }
    return valuesToReturn;
  }

  getRangeMonths(date1: string, date2: string): string[] {
    let valuesToReturn: string[] = [];
    let monthVariable = Number(date1.split('-')[1]);
    let monthCut = Number(date2.split('-')[1]);
    let cut = false;

    while (!cut) {
      valuesToReturn.push(String(monthVariable));
      if (monthVariable == monthCut) {
        cut = true;
      }
      monthVariable++;
    }
    return valuesToReturn;
  }
  
  getQuarterByDate(month: string): number {
    const monthNumber = parseInt(month, 10);
    return monthNumber >= 1 && monthNumber <= 12
      ? Math.floor((monthNumber - 1) / 3)
      : -1;
  }
}
