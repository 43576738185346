import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrouperCodeComponent } from './grouper-code.component';
import { NumberInputModule } from '../../inputs/number/number.module';



@NgModule({
  declarations: [
    GrouperCodeComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule
  ], exports:[
    GrouperCodeComponent
  ]
})
export class GrouperCodeModule { }
