import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WayPayComponent } from './way-pay.component';
import { WayToPayModule } from './type-way-to-pay/way-to-pay.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';



@NgModule({
  declarations: [
    WayPayComponent
  ],
  imports: [
    CommonModule,
    WayToPayModule,
    FormsModule,
    PipesModule,

    ArrowSvgModule
  ], exports:[
    WayPayComponent
  ]
})
export class WayPayModule { }
