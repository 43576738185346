import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterIndexControlPanel, setOrderDetailsInControlPanel, setOrdersSelectedInControlPanel, showOrderDetailsInControlPanel } from 'src/app/redux/actions/control-panel.action';
import { orderOriginConst, orderStatusConst } from 'src/app/shared/const/orders';
import { areas } from 'src/app/shared/const/user.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import { ViewOrder } from 'src/app/shared/interfaces/views.interface';
import { OrderValidator, copyOrder } from 'src/app/shared/order';
import { FilterOrderWithStringPipe } from 'src/app/shared/pipes/filter/filterWithString.pipe';
import { User } from 'src/app/shared/interfaces/user.interface';
import { isGenerateEraser, setHistorical, setIndexToEditValidation, setValidationOrdersToGenerate } from 'src/app/redux/actions/order.action';
import { getBuyerType, getIsSellCommodities, getStatusOfFilterOrder } from '../body-control-panel.component';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { Subscription } from 'rxjs';
import { FilterWithOnlyContractStatus } from 'src/app/shared/pipes/filter/filterWithContractStatus.pipe';
import { FilterOrderByCommoditiesPipe } from 'src/app/shared/pipes/filter/filterByBuyerType.pipe';
import { ContractChecker } from 'src/app/shared/functions-ibm/contract.checker';
import { conditionType, divisionsControlPanel, sectionType, setViewMobile } from 'src/app/shared/const/options';
import { FilterOrderByConditionTypePipe } from 'src/app/shared/pipes/filter/filter-by-condition-type.pipe';
import { getMinHeight } from 'src/app/pages/order-list/body-order-list/order-table/order-table.component';
import { OPTION, OptionMenu } from 'src/app/components/board/options/options.component';
import { FilterCommoditiesSellerPipe } from 'src/app/shared/pipes/filter/filterOnlyCommoditiesSell.pipe';
import { FilterByOrderPipe } from 'src/app/shared/pipes/filter/filter.by.order.pipe';
import { OrderSortByContractNumerPipe } from 'src/app/shared/pipes/sorts/orderSortByContractNumber';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';

@Component({
  selector: 'app-order-eraser-table',
  templateUrl: './order-eraser-table.component.html',
  styleUrls: ['./order-eraser-table.component.scss', './../../../../shared/styles/board.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderEraserTableComponent implements OnInit, OnDestroy {
  private user!: User;
  sectionTypeSelected: string = ''
  //Array of products
  orders: Order[] = [];
  ordersWithPipe:Order[]=[]
  isComercial: boolean = true;
  isViewMobile = true;

  //Other filter of tabla
  orderFilter!: OrderString;
  sectionType=sectionType;
  search: string = '';
  CONDITION_TYPES=conditionType;
  conditionTypeSelected:string='';
  conditionFilters: number[] = [];
  DIVISION_TYPES=divisionsControlPanel;
  divisionTypeSelected:string='';

  ORDER_ORIGIN_CONST: any = orderOriginConst;

  //The column selected to apply a filter
  filterIdentificator: number = -1;
  optionIdentificator: number = -1;

  //The columns that will be seen in the table
  viewSelected!: ViewOrder;
  isCheckedAll:boolean=false;
  ordersSelected: Order[] = [];

  private orders$!:Subscription;
  private viewSelected$!:Subscription;
  private ordersSelected$!:Subscription;
  private orderFiler$!:Subscription;
  private search$!:Subscription;
  private filterIdentificator$!:Subscription;
  private conditionTypeSelected$!:Subscription;
  private sectionTypeSelected$!:Subscription;
  private divisionTypeSelected$!:Subscription;
  private optionsSubscription!: Subscription;
  private usuarioSubscription!: Subscription;
  private conditionFilters$!:Subscription;

  constructor(public store: Store<appState>, public router: Router, public date: DateChecker, 
     public orderV: OrderValidator, public toFixV:ToFixValidator, public IBM:ContractChecker, public cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setSubscriptions();
  }

  ngOnDestroy() {
    this.setUnsubscriptions();
    this.store.dispatch(setOrdersSelectedInControlPanel({ orders: [] }));
  }

  setSubscriptions() {
    this.orders$=this.store.select('controlPanel', 'controlPanelBoard','all').subscribe(orders => {
      this.orders = orders;
      this.actualizateOrdersWithPipe();
    });

    this.viewSelected$=this.store.select('controlPanel', 'viewsOfControlPanelBoard','viewSelected').subscribe(viewSelected => {
      this.viewSelected = viewSelected;
      this.actualizateOrdersWithPipe();
    });

    this.orderFiler$=this.store.select('controlPanel', 'controlPanelBoard','filter').subscribe(orderFilter => {
      this.orderFilter = orderFilter;
      this.actualizateOrdersWithPipe();
    });

    this.search$=this.store.select('options', 'board','search').subscribe(search => {
      this.search = search;
      this.actualizateOrdersWithPipe();
    });

    this.filterIdentificator$=this.store.select('controlPanel', 'controlPanelBoard','indexFilter').subscribe(filterIdentificator => {
      this.filterIdentificator = filterIdentificator;
      this.actualizateOrdersWithPipe();
    });

    this.conditionTypeSelected$=this.store.select('options', 'board', 'conditionTypeSelected').subscribe(conditionTypeSelected => {
      this.conditionTypeSelected = conditionTypeSelected;
      this.actualizateOrdersWithPipe();
    });

    this.sectionTypeSelected$=this.store.select('controlPanel', 'controlPanelBoard','sectionType').subscribe(sectionTypeSelected => {
      this.sectionTypeSelected = sectionTypeSelected;
      this.actualizateOrdersWithPipe();
    });

    this.divisionTypeSelected$=this.store.select('controlPanel', 'controlPanelBoard','divisionType').subscribe(divisionTypeSelected => {
      this.divisionTypeSelected = divisionTypeSelected;
      this.actualizateOrdersWithPipe();
    });

    this.conditionFilters$=this.store.select('options', 'board','conditionFilter').subscribe(conditionFilters => {
      this.conditionFilters = conditionFilters;
      this.actualizateOrdersWithPipe();
    });

    this.ordersSelected$=this.store.select('controlPanel', 'controlPanelBoard','ordersSelected').subscribe(ordersSelected => {
      this.ordersSelected = ordersSelected;
      this.isCheckedAll = this.orderV.areArraysOfOrdersEqual(this.ordersSelected, this.ordersWithPipe);
      this.cdr.detectChanges();
    });

    this.usuarioSubscription = this.store.select('usuario').subscribe(user => {
      this.user = user;
      this.isComercial = user.area == areas.comercial;
    })

    this.optionsSubscription = this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.isViewMobile= setViewMobile(sizeView);
    });
  }

  setUnsubscriptions() {
    this.orders$?.unsubscribe();
    this.viewSelected$?.unsubscribe();
    this.ordersSelected$?.unsubscribe();
    this.orderFiler$?.unsubscribe();
    this.search$?.unsubscribe();
    this.filterIdentificator$?.unsubscribe();
    this.conditionTypeSelected$?.unsubscribe();
    this.conditionFilters$?.unsubscribe();
    this.sectionTypeSelected$?.unsubscribe();
    this.divisionTypeSelected$?.unsubscribe();
    this.optionsSubscription?.unsubscribe();
    this.usuarioSubscription?.unsubscribe();
  }

  actualizateOrdersWithPipe(){
    this.ordersWithPipe = this.getOrderListWithPipes();
    this.cdr.detectChanges();
  }

  isOrderInOrderList(order: Order): boolean {
    return this.orderV.isOrderInOrderList(order, this.ordersSelected);
  }

  //It is a different function for business users and operators.
  selectFilter(index: number) {
    this.store.dispatch(setFilterIndexControlPanel({index: index == this.filterIdentificator? -1: index}));
  }

  getIsNew(order: Order): boolean {
    return order.dataOfcreation.hour == this.date.getHour();
  }

  selectOrder(order: Order) {
    if (order._id) {
      const positionInOrderList = this.orderV.indexOfOrder(this.ordersSelected, order);
      const ordersSelectedAux = JSON.parse(JSON.stringify(this.ordersSelected));
      positionInOrderList < 0? ordersSelectedAux.push(order): ordersSelectedAux.splice(positionInOrderList, 1);
    
      this.store.dispatch(setOrdersSelectedInControlPanel({ orders: ordersSelectedAux }));
    }
  }

  showOrderDetail(order: Order) {
    this.store.dispatch(setOrderDetailsInControlPanel({ order: order }));
    this.store.dispatch(showOrderDetailsInControlPanel({ show: true }));
  }

  autorizate(order: Order) {
    if(order.statusOrder == orderStatusConst.AUTORIZATION){
      let newOrder: Order = JSON.parse(JSON.stringify(order));
      this.IBM.createContracts([newOrder], 2, this.user); //Llamada 2 porque se enviará a boletos y no se podrá editar más
    }
  }

  edit(order: Order) {
    this.store.dispatch(setOrdersSelectedInControlPanel({orders: [order]}));
    let validationOrders = JSON.parse(JSON.stringify(this.ordersSelected));
    this.store.dispatch(isGenerateEraser({ isGenerateEraser: false }));
    this.store.dispatch(setValidationOrdersToGenerate({ orders: validationOrders }));
    this.store.dispatch(setIndexToEditValidation({ indexToEdit: 0 }));
    this.router.navigate(['/' + NAVEGATION.ORDER_VALIDATION]);
  }

  getOptionsMenu(){
    return [
      {action: OPTION.HISTORICAL, name:'Historial de cambios'},
      {action: OPTION.COPY, name:'Copiar orden'},
    ];
  }

  selectAction(event:string){
    switch(event){
      case OPTION.AUTORIZATE_ALL: this.autorizateSelected();break;
      case OPTION.SELECT_ALL: this.checkedAllItems();break;
    }
  }

  selectOptionMenu(event:OptionMenu, order:Order){
    switch(event.action){
      case OPTION.HISTORICAL: this.showHistorical(order);break;
      case OPTION.COPY:copyOrder(order, this.store, this.router);break;
      case OPTION.AUTORIZATE: this.autorizate(order);break;
      case OPTION.EDIT: this.edit(order);break;
      case OPTION.DETAILS: this.showOrderDetail(order);break;
      case OPTION.SELECT: this.selectOrder(order);break;
    }
  }

  checkedAllItems() {
    this.store.dispatch(setOrdersSelectedInControlPanel({ orders: this.isCheckedAll? []:this.ordersWithPipe }))
  }

  getOrderListWithPipes() {
    let newOrderList = JSON.parse(JSON.stringify(this.orders))  
    const condition = new FilterOrderByConditionTypePipe();
    const without = new FilterWithOnlyContractStatus();
    const comm = new FilterOrderByCommoditiesPipe();
    const commSell = new FilterCommoditiesSellerPipe();
    const order = new FilterByOrderPipe();
    const string = new FilterOrderWithStringPipe();
    const sort = new OrderSortByContractNumerPipe();

    return sort.transform(string.transform(order.transform(commSell.transform(
    comm.transform(without.transform(condition.transform(newOrderList, this.conditionTypeSelected, this.conditionFilters), 
    this.getStatusSelected()), this.getBuyerType()), this.getIsSellCommodities()), this.orderFilter), this.search));
  }

  showHistorical(order: Order) {
    this.store.dispatch(setHistorical({historical: order._id}));
  }

  getStatusSelected() {
    return getStatusOfFilterOrder(this.sectionTypeSelected, this.divisionTypeSelected);
  }

  getIsSellCommodities() {
    return getIsSellCommodities(this.divisionTypeSelected);
  }

  getBuyerType(){
    return getBuyerType(this.divisionTypeSelected)
  }

  autorizateSelected() {
    for(let order of this.ordersSelected) {
      this.autorizate(order);
    }
  }

  isSelectedInEraser() {
    return this.getStatusSelected() == orderStatusConst.AUTORIZATION;
  }
  
  isStatusAutorizated(order: Order) {
    return order.statusOrder == orderStatusConst.AUTORIZATION;
  }

  getMinHeight(table:any){
    return getMinHeight(table, !this.isComercial);
  }

  
  public arrayOfShowParents:any[]=[]

  haveIndicators(order:Order){
    return this.toFixV.orderHaveIndicators(order);
  }

  getArrowDirection(id:string):string{
    return getArrowDirectionByBool(this.isIdInArrayParent(id));
  }

  isIdInArrayParent(id:string){
    return this.arrayOfShowParents.indexOf(id) != -1;
  }

  /*Listens to the click event on a date and is passed a Price as a parameter.
  If the price has indicators, a dropDown is performed for the conditions to be set.
  If the price does not have indicators, the dropDown is for the variants of the condition.*/
  clickInArrow(order:Order, event:any){
    if(this.haveIndicators(order)){
      this.dropDown(order._id);
    } 
  }

  dropDown(id:string){
    this.isIdInArrayParent(id)? this.arrayOfShowParents.splice(this.arrayOfShowParents.indexOf(id),1): 
    this.arrayOfShowParents.push(id);
  }
}