
<svg class="svg-menu flex" [ngClass]="{'enabled': active, 'disabled-grey': !active}" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_29_115473)">
  <path d="M6.419 5.27051C5.24377 5.27051 4.2876 6.27449 4.2876 7.50848C4.2876 8.74248 5.24377 9.74646 6.419 9.74646C7.59428 9.7465 8.55041 8.74252 8.55041 7.50848C8.55041 6.27441 7.59424 5.27051 6.419 5.27051ZM6.419 8.51595C5.8899 8.51599 5.45947 8.064 5.45947 7.50844C5.45947 6.95289 5.88994 6.50094 6.419 6.50094C6.94811 6.50094 7.37853 6.95293 7.37853 7.50844C7.37853 8.06396 6.94807 8.51595 6.419 8.51595Z" fill="#DCDCDC"/>
  <path d="M13.5811 11.2529C12.4058 11.2529 11.4497 12.2569 11.4497 13.4909C11.4497 14.7249 12.4059 15.7289 13.5811 15.7289C14.7563 15.7289 15.7125 14.7249 15.7125 13.4909C15.7125 12.2569 14.7564 11.2529 13.5811 11.2529ZM13.5811 14.4984C13.052 14.4984 12.6216 14.0464 12.6216 13.4909C12.6216 12.9354 13.0521 12.4834 13.5811 12.4834C14.1102 12.4834 14.5406 12.9354 14.5406 13.4909C14.5406 14.0464 14.1102 14.4984 13.5811 14.4984Z" fill="#DCDCDC"/>
  <path d="M14.8045 5.45556C14.5757 5.21533 14.2047 5.21533 13.9759 5.45556L5.19555 14.6749C4.96672 14.9152 4.96672 15.3047 5.19555 15.545C5.30996 15.6651 5.45992 15.7252 5.60984 15.7252C5.75977 15.7252 5.90977 15.6651 6.02414 15.545L14.8045 6.32563C15.0334 6.08536 15.0334 5.69579 14.8045 5.45556Z" fill="#DCDCDC"/>
  <path d="M17.4609 0.928711H2.53906C1.13902 0.928711 0 2.12469 0 3.59473V17.4046C0 18.8746 1.13902 20.0706 2.53906 20.0706H17.4609C18.861 20.0706 20 18.8746 20 17.4046V3.59473C20 2.12469 18.861 0.928711 17.4609 0.928711ZM18.8281 17.4046C18.8281 18.1962 18.2148 18.8402 17.4609 18.8402H2.53906C1.7852 18.8402 1.17188 18.1962 1.17188 17.4046V3.59473C1.17188 2.80317 1.7852 2.15918 2.53906 2.15918H17.4609C18.2148 2.15918 18.8281 2.80317 18.8281 3.59473V17.4046Z" fill="#DCDCDC"/>
  </g>
  <defs>
  <clipPath id="clip0_29_115473">
  <rect width="20" height="21" fill="white"/>
  </clipPath>
  </defs>
  </svg>