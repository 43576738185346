import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BonificationExchangeComponent } from './exchange-bonus.component';
import { NumberInputModule } from '../../inputs/number/number.module';

@NgModule({
  declarations: [
    BonificationExchangeComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule
  ], exports:[
    BonificationExchangeComponent
  ]
})
export class BonificationExchangeModule { }
