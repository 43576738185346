<div class="body">
    <p class="saludo">¡Hola {{user.name}}!, buenos días</p>
    <app-menu></app-menu>
    <app-header ></app-header>
    <h1>Inicio</h1>
    
    <app-body-comercial *ngIf="isComercial"></app-body-comercial>
    <app-commodities *ngIf="isOperator"></app-commodities>
    
    <app-menu-footer></app-menu-footer>
</div>