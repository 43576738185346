<div class="background-svg">

    <svg class="svg-20" width="22" height="22" viewBox="0 0 22 22" fill="none"  xmlns="http://www.w3.org/2000/svg" *ngIf="!active">
        <path d="M11 18.1109C6.8823 18.1109 2.93974 15.0815 0.337307 11.9364C-0.112436 11.3929 -0.112436 10.6039 0.337307 10.0603C0.99162 9.26957 2.36282 7.74396 4.19452 6.41066C8.80803 3.05255 13.183 3.04607 17.8054 6.41066C19.9573 7.97699 21.6626 10.031 21.6626 10.0603C22.1124 10.6039 22.1124 11.3929 21.6626 11.9364C19.0606 15.0811 15.1186 18.1109 11 18.1109ZM11 5.2406C6.43844 5.2406 2.48428 9.59073 1.38108 10.924C1.34543 10.9671 1.34543 11.0297 1.38108 11.0728C2.48433 12.406 6.43844 16.7562 11 16.7562C15.5615 16.7562 19.5157 12.406 20.6189 11.0728C20.6832 10.995 20.6146 10.924 20.6189 10.924C19.5156 9.59073 15.5615 5.2406 11 5.2406Z" fill="#597393"/>
        <path d="M10.9995 15.7393C8.38493 15.7393 6.25781 13.6122 6.25781 10.9976C6.25781 8.38298 8.38493 6.25586 10.9995 6.25586C13.6141 6.25586 15.7412 8.38298 15.7412 10.9976C15.7412 13.6122 13.6141 15.7393 10.9995 15.7393ZM10.9995 7.61063C9.13196 7.61063 7.61259 9.13001 7.61259 10.9976C7.61259 12.8651 9.13196 14.3845 10.9995 14.3845C12.8671 14.3845 14.3865 12.8651 14.3865 10.9976C14.3865 9.13001 12.8671 7.61063 10.9995 7.61063Z" fill="#597393"/>
    </svg>

    <svg  class="svg-20"    width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="active">
        <path d="M11.0002 14.3891C12.8708 14.3891 14.3872 12.8727 14.3872 11.0022C14.3872 9.13162 12.8708 7.61523 11.0002 7.61523C9.12967 7.61523 7.61328 9.13162 7.61328 11.0022C7.61328 12.8727 9.12967 14.3891 11.0002 14.3891Z" fill="#597393"/>
        <path d="M21.6626 10.064C19.0591 6.918 15.1182 3.88965 11 3.88965C6.8811 3.88965 2.93925 6.92011 0.33753 10.064C-0.11251 10.6076 -0.11251 11.3968 0.33753 11.9404C0.991633 12.7308 2.36292 14.2567 4.19441 15.5898C8.80696 18.9476 13.183 18.9551 17.8057 15.5898C19.6372 14.2567 21.0085 12.7308 21.6626 11.9404C22.1113 11.3979 22.1136 10.6094 21.6626 10.064V10.064ZM11 6.26051C13.6148 6.26051 15.7418 8.38751 15.7418 11.0022C15.7418 13.6169 13.6148 15.7439 11 15.7439C8.38532 15.7439 6.25833 13.6169 6.25833 11.0022C6.25833 8.38751 8.38532 6.26051 11 6.26051Z" fill="#597393"/>
    </svg>
        
    
<div class="tooltip row-center w-60">
    <span>Ver detalle</span>
</div>
</div>


