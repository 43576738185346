<div class="background-svg">
    
    <svg class="svg-30" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15.5C0 7.21573 6.71573 0.5 15 0.5C23.2843 0.5 30 7.21573 30 15.5C30 23.7843 23.2843 30.5 15 30.5C6.71573 30.5 0 23.7843 0 15.5Z" [ngClass]="{'background-white': active}" fill="#F2EBD7"/>
        <g clip-path="url(#clip0_1187_7165)">>
            <path [ngClass]="{'rose-fill': active}" d="M25.9427 12.9387C25.7986 12.4932 25.4035 12.1768 24.9361 12.1347L18.5862 11.5582L16.0754 5.68119C15.8902 5.25048 15.4686 4.97168 15.0001 4.97168C14.5316 4.97168 14.11 5.25048 13.9249 5.68219L11.414 11.5582L5.06317 12.1347C4.59654 12.1779 4.20243 12.4932 4.05757 12.9387C3.91271 13.3842 4.04649 13.8728 4.39948 14.1808L9.19918 18.3902L7.78386 24.6247C7.6803 25.0831 7.85822 25.5569 8.23857 25.8319C8.44301 25.9796 8.6822 26.0548 8.9234 26.0548C9.13137 26.0548 9.33766 25.9987 9.5228 25.8879L15.0001 22.6143L20.4754 25.8879C20.8761 26.129 21.3811 26.107 21.7606 25.8319C22.1412 25.5561 22.3189 25.0821 22.2154 24.6247L20.8 18.3902L25.5997 14.1817C25.9527 13.8728 26.0875 13.385 25.9427 12.9387Z" fill="#ADBCCE"/>
        </g>
        <defs>
        <clipPath id="clip0_1187_7165">
        <rect width="22" height="22" fill="white" transform="translate(4 4.5)"/>
        </clipPath>
        </defs>
    </svg>
        
        

<div class="tooltip row-center w-100px down" style="left:-20px; z-index:1;" *ngIf="tooltip">
    <span>Precio destacado</span>
</div>
</div>