import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeOrderListSvgComponent } from './exchange-order.component';

@NgModule({
  declarations: [
    ExchangeOrderListSvgComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    ExchangeOrderListSvgComponent
  ]
})
export class ExchangeOrderListSvgModule { }
