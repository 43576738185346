import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductNameComponent } from './product-name.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProductNameComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    FormsModule,
    PipesModule
  ], exports: [
    ProductNameComponent
  ]
})
export class ProductNameModule { }
