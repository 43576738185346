import { ViewBasic, ViewOrder, ViewOrderExchange, ViewPrice } from "../interfaces/views.interface";
import { localStorageNames } from "./localStorageNames";
import { initialOrderString } from "./orders";
import { initialPriceString } from "./prices.const";

const VIEW_BASIC_VOID: ViewBasic = {
    viewName: '',
    isHistorical: false,
}

export const VIEW_PRICE_VOID: ViewPrice={
    ...VIEW_BASIC_VOID,
    priceString: initialPriceString,
    product: false,
    observations: false,
    state: false,
    typeBusiness: false,
    placeOfDelivery: false,
    quality: false,
    deliveryPeriod: false,
    businessParticularities: false,
    price: false,
    wayPay: false,
    expiration: false,
    bonus: false,
    reduction: false,
    pesificacion: false,
    harvest: false,
    hour: false,
    priceId: false,
}
export const VIEW_ORDER_VOID: ViewOrder = {
    ...VIEW_BASIC_VOID,
    orderString: initialOrderString,
    observations: false,
    product: false,
    orderStatus: false,
    typeBusiness: false,
    placeOfDelivery: false,
    quality: false,
    deliveryPeriod: false,
    businessParticularities: false,
    price: false,
    wayPay: false,
    hour: false,
    tons: false,
    seller:false,
    harvest:false,
    tables:false,
    date:false,
    contractNumber:false,
    buyer:false,
    id:false
}
export const VIEW_ORDER_EXCHANGE_VOID: ViewOrderExchange={
    ...VIEW_BASIC_VOID,
    orderString: initialOrderString,
    date: false,
    seller: false,
    exchanger: false,
    product: false,
    price: false,
    deliveryPeriod: false,
    tons: false,
    exchangeStatus: false,
    tables: false,
    motherContract: false,
}

export const VIEW_PRICE_ALL: ViewPrice={
    ...VIEW_BASIC_VOID,
    priceString: initialPriceString,
    product: true,
    observations: true,
    state: true,
    typeBusiness: true,
    placeOfDelivery: true,
    quality: true,
    deliveryPeriod: true,
    businessParticularities: true,
    price: true,
    wayPay: true,
    expiration: true,
    bonus: true,
    reduction: true,
    pesificacion: true,
    harvest: true,
    hour: true,
    priceId: true
}

export const VIEW_ORDER_ALL: ViewOrder = {
    viewName: '',
    orderString: initialOrderString,
    observations: true,
    product: true,
    orderStatus: true,
    typeBusiness: true,
    placeOfDelivery: true,
    quality: true,
    deliveryPeriod: true,
    businessParticularities: true,
    price: true,
    wayPay: true,
    hour: true,
    tons: true,
    harvest:true,
    seller:true,
    tables:true,
    date:true,
    contractNumber:true,
    buyer:true,
    id:true
}
export const VIEW_ORDER_EXCHANGE_ALL: ViewOrderExchange={
    viewName: '',
    orderString: initialOrderString,
    date: true,
    seller: true,
    exchanger: true,
    product: true,
    price: true,
    deliveryPeriod: true,
    tons: true,
    exchangeStatus: true,
    tables: true,
    motherContract: true,
}

export const VIEW_PRICE_FIELDS ={
    viewName: 'viewName',
    product: 'product',
    observations: 'observations',
    state: 'state',
    typeBusiness: 'typeBusiness',
    placeOfDelivery: 'placeOfDelivery',
    quality: 'quality',
    deliveryPeriod: 'deliveryPeriod',
    businessParticularities: 'businessParticularities',
    price: 'price',
    wayPay: 'wayPay',
    expiration: 'expiration',
    bonus: 'bonus',
    reduction: 'reduction',
    pesificacion: 'pesificacion',
    harvest: 'harvest',
    hour: 'hour',
    priceId: 'priceId',
}
export const VIEW_ORDER_FIELDS = {
    viewName: 'viewName',
    observations: 'observations',
    product: 'product',
    orderStatus: 'orderStatus',
    typeBusiness: 'typeBusiness',
    placeOfDelivery: 'placeOfDelivery',
    quality: 'quality',
    deliveryPeriod: 'deliveryPeriod',
    harvest: 'harvest',
    businessParticularities: 'businessParticularities',
    price: 'price',
    wayPay: 'wayPay',
    hour: 'hour',
    tons: 'tons',
    seller:'seller',
    tables: 'tables',
    contractNumber: 'contractNumber',
    date:'date',
    buyer:'buyer',
    id:'id'
}
export const VIEW_ORDER_EXCHANGE_FIELDS = {
    viewName: 'viewName',
    date: 'date',
    seller: 'seller',
    exchanger: 'exchanger',
    product: 'product',
    price: 'price',
    deliveryPeriod: 'deliveryPeriod',
    tons: 'tons',
    exchangeStatus: 'exchangeStatus',
    motherContract: 'motherContract',
    tables: 'tables',
}

export const VIEWS_SELECTED_CONST:any= {
    PRICE_BOARD: 'priceBoard',
    ORDER_BOARD: 'orderBoard',
    ORDER_EXCHANGE_BOARD: 'orderExchangeBoard',
    HISTORICAL_BOARD: 'historicalBoard',
    CONTROL_PANEL_BOARD: 'controlPanelBoard',
}

export const MAP_VIEW_NAME_LOCAL_STORAGE:any = {
    viewOrder: localStorageNames.viewOrders,
    viewPrice: localStorageNames.viewPrices,
    viewExchange: localStorageNames.viewExchange,
    viewsControlPanel: localStorageNames.viewsControlPanel
}

export const MAP_LOCAL_STORAGE_VIEW_NAME = {
    [localStorageNames.viewOrders]: 'viewOrder',
    [localStorageNames.viewPrices]: 'viewPrice',
    [localStorageNames.viewExchange]: 'viewExchange',
    [localStorageNames.viewsControlPanel]: 'viewsControlPanel'
}