<section class="body">
    <div class="inform">
        <app-form-validations *ngIf="formVisible" class="form"></app-form-validations>

        <!--  [disabled]="disabledButton" 
        [ngClass]="{'disabled': disabledButton}"  -->
        <button *ngIf="formVisible"class="not-filled-button not-visible-in-desktop" (click)="showDetails(true)">
            Aplicar
        </button>

        <details-validation  *ngIf="detailsVisible" class="details"></details-validation>        
    </div>
</section> 