<div class="body">

    <app-loading *ngIf="isLoading"></app-loading>

    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Generar orden desde tablero"></app-header-in-section>
    <!-- TITLE IN HEADER -->
    <h2 class="title">Generar una orden desde el tablero </h2>
    <!-- CONTENT OF GENERATE ORDER -->
    <app-body-generate-order style="margin-top:8px"></app-body-generate-order>
    
    <app-menu class="menu"></app-menu>
    <!--<app-menu-footer></app-menu-footer> -->
</div>