<div class="radios-buttons">
    <mat-radio-group>
        <mat-radio-button class="radio-button pointer" [checked]="conditionTypeSelected==conditionTypes.TO_PRICE" 
        [value]="conditionTypes.TO_PRICE" (click)="setConditionType(conditionTypes.TO_PRICE)"> 
            <span style="margin-left:4px" [ngClass]="{'selected': conditionTypeSelected==conditionTypes.TO_PRICE}">A precio</span>
        </mat-radio-button>

        <mat-radio-button class="radio-button pointer button-order-purchase" [checked]="conditionTypeSelected==conditionTypes.TO_FIX"
        [value]="conditionTypes.TO_FIX" (click)="setConditionType(conditionTypes.TO_FIX)">
        <span style="margin-left:4px" [ngClass]="{'selected': conditionTypeSelected==conditionTypes.TO_FIX}">A fijar</span>
        </mat-radio-button>
    </mat-radio-group>
</div>