<div class="field">
    <span class="field-title">Código agrupador<span class="asterisk">*</span></span>
    <div>
        <number-input [initialPlaceHolder]="'Ingresar un código agrupador'" [value]="elementSelected" (valueOut)="setElement($event,0)" (focus)="setShowOptions($event)"></number-input>
    
        <div class="options" *ngIf="showOptions">
            <span *ngFor="let element of array; index as i" [ngClass]="{'selected': OBJECT[element]==elementSelected}"
                (click)="setElement(element, i)">
                {{OBJECT[element]}} - {{element}}
            </span>
        </div>
    </div>

    <span class="message-info">{{OBJECT[elementSelected]}}</span>
</div>