import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'generate-price-svg',
  templateUrl: './generate-price.component.html',
  styleUrls: ['./../../svg.scss']
})
export class GeneratePriceComponent {
  @Input() active:boolean=false;
  constructor() { }
}
