import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotonGoogleComponent } from './boton-google/boton-google.component';
import { LogoGrassiComponent } from './headers/logo-grassi/logo-grassi.component';
import { HeadersComponent } from './headers/headers.component';
import { LoginComponent } from './login.component';

import { LottieModule } from 'ngx-lottie';
import * as player from 'lottie-web';

export function playerFactory() {
  return player.default;
}

@NgModule({
  declarations: [
    LoginComponent,
    BotonGoogleComponent,
    LogoGrassiComponent,
    HeadersComponent,
  ],
  imports: [
    CommonModule,
    
    LottieModule.forRoot({ player: playerFactory }),
  ],schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LoginModule { }
