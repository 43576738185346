export const mendozaLocalities=[
    {"afip":"22","location":"3 De Mayo"},
    {"afip":"104","location":"Adrian Maturano"},
    {"afip":"113","location":"Agrelo"},
    {"afip":"115","location":"Agua Amarga"},
    {"afip":"121","location":"Agua Botada"},
    {"afip":"128","location":"Agua De Cabrera"},
    {"afip":"131","location":"Agua De Diaz"},
    {"afip":"133","location":"Agua De La Mula"},
    {"afip":"140","location":"Agua De Los Manantiales"},
    {"afip":"146","location":"Agua Del Chancho"},
    {"afip":"159","location":"Agua Nueva"},
    {"afip":"162","location":"Agua Rica"},
    {"afip":"182","location":"Aguada De Toro"},
    {"afip":"191","location":"Aguada Penepe"},
    {"afip":"192","location":"Aguada Perez"},
    {"afip":"193","location":"Aguada Puesto La Totora"},
    {"afip":"253","location":"Alberto Flores"},
    {"afip":"305","location":"Alejandro Perez"},
    {"afip":"322","location":"Alfredo Lucero"},
    {"afip":"325","location":"Algarrobal Abajo"},
    {"afip":"326","location":"Algarrobal Arriba"},
    {"afip":"330","location":"Algarrobito"},
    {"afip":"334","location":"Algarrobo De Sortue"},
    {"afip":"373","location":"Alpatacal"},
    {"afip":"391","location":"Alto Amarillo"},
    {"afip":"400","location":"Alto Con Zampa"},
    {"afip":"410","location":"Alto De Las Arañas"}
    ,
    {"afip":"415","location":"Alto De Los Manantiales"},
    {"afip":"416","location":"Alto De Los Perros"},
    {"afip":"419","location":"Alto De Los Sapos"},
    {"afip":"432","location":"Alto Del Olvido"},
    {"afip":"433","location":"Alto Del Plomo"},
    {"afip":"437","location":"Alto Del Salvador"},
    {"afip":"464","location":"Alto Tres Compadres"},
    {"afip":"472","location":"Alvarez"},
    {"afip":"473","location":"Alvarez Condarco"},
    {"afip":"525","location":"Ana De Donaire"},
    {"afip":"527","location":"Anacleta Viuda De Perez"},
    {"afip":"543","location":"Anchoris"},
    {"afip":"556","location":"Andrade"},
    {"afip":"560","location":"Andres Perez"},
    {"afip":"615","location":"Antonio Sosa"},
    {"afip":"636","location":"Araganita"},
    {"afip":"641","location":"Arancibia"},
    {"afip":"645","location":"Aranzabal"},
    {"afip":"686","location":"Aristides Villanueva"},
    {"afip":"751","location":"Arroyo Claro"},
    {"afip":"807","location":"Arroyo Los Sauces"},
    {"afip":"909","location":"Asuncion"},
    {"afip":"925","location":"Atuel Sud"},
    {"afip":"954","location":"Azcurra"},
    {"afip":"966","location":"B De Araya"},
    {"afip":"967","location":"B De Quebrado"},
    {"afip":"968","location":"B Elena"},
    {"afip":"996","location":"Bajada Araujo"},
    {"afip":"1000","location":"Bajada De La Salada"},
    {"afip":"1001","location":"Bajada De Los Gauchos"},
    {"afip":"1003","location":"Bajada De Los Papagayos"},
    {"afip":"1004","location":"Bajada De Yaucha"},
    {"afip":"1006","location":"Bajada Del Agua Escondida"},
    {"afip":"1008","location":"Bajada Del Fuerte"},
    {"afip":"1010","location":"Bajada Del Perro"},
    {"afip":"1011","location":"Bajada Del Sauce"},
    {"afip":"1035","location":"Bajo Del Peludo"},
    {"afip":"1036","location":"Bajo Del Yuyo"},
    {"afip":"1070","location":"Balde De La Jarilla"},
    {"afip":"1076","location":"Balde De Los Gauchos"},
    {"afip":"1079","location":"Balde De Piedra"},
    {"afip":"1088","location":"Balde E Aquera"},
    {"afip":"1090","location":"Balde El Sosneado"},
    {"afip":"1092","location":"Balde Jofre"},
    {"afip":"1093","location":"Balde La Pichana Vieja"},
    {"afip":"1095","location":"Balde Las Carpas"},
    {"afip":"1096","location":"Balde Las Catitas"},
    {"afip":"1097","location":"Balde Las Lagunitas"},
    {"afip":"1104","location":"Balde San Agustin"},
    {"afip":"1167","location":"Banderita"},
    {"afip":"1173","location":"Barcala"},
    {"afip":"1178","location":"Bardas Blancas"},
    {"afip":"1200","location":"Barreal Colorado"},
    {"afip":"1201","location":"Barreal De La Mesilla"},
    {"afip":"1202","location":"Barreal De La Pampa Seca"},
    {"afip":"1203","location":"Barreal Jose Luis"},
    {"afip":"1204","location":"Barreal Pajaro Muerto"},
    {"afip":"1211","location":"Barriales Los Ranchos"},
    {"afip":"1251","location":"Barrio Ferri"},
    {"afip":"1260","location":"Barrio Jardin Luzuriaga"},
    {"afip":"1278","location":"Barrio Lencina"},
    {"afip":"1301","location":"Barrio Rivadavia"},
    {"afip":"1308","location":"Barrio San Eduardo"},
    {"afip":"1327","location":"Barrio Villa Adela"},
    {"afip":"1400","location":"Bañado Verde"}
    ,
    {"afip":"1403","location":"Baños De Azufre"}
    ,
    {"afip":"1404","location":"Baños De Capiz"}
    ,
    {"afip":"1410","location":"Baños La Salada"}
    ,
    {"afip":"1411","location":"Baños Lunlunta"}
    ,
    {"afip":"1421","location":"Becerra"},
    {"afip":"1436","location":"Belle Ville"},
    {"afip":"1439","location":"Beltran"},
    {"afip":"1458","location":"Bermejo"},
    {"afip":"1490","location":"Blanco Encalada"},
    {"afip":"1496","location":"Blas Panelo"},
    {"afip":"1522","location":"Boliche"},
    {"afip":"1525","location":"Boliche Los Barreales"},
    {"afip":"1539","location":"Borde De La Linea"},
    {"afip":"1542","location":"Bordo Amarillo De La Cruz Pied"},
    {"afip":"1544","location":"Bordo El Algarrobo"},
    {"afip":"1546","location":"Bordo Lechuzo"},
    {"afip":"1548","location":"Bordos Del Plumerillo"},
    {"afip":"1559","location":"Bowen"},
    {"afip":"1560","location":"Boyeros"},
    {"afip":"1587","location":"Buen Orden"},
    {"afip":"1592","location":"Buena Nueva"},
    {"afip":"1603","location":"Buitrera"},
    {"afip":"1613","location":"Buta Billon"},
    {"afip":"1619","location":"C Gonzales Videla"},
    {"afip":"1620","location":"Ca Del Diablo"},
    {"afip":"1646","location":"Cabeza De Vaca"},
    {"afip":"1671","location":"Cacheuta"},
    {"afip":"1694","location":"Cadetes De Chile"},
    {"afip":"1720","location":"Cajon De Mayo"},
    {"afip":"1723","location":"Cajon Grande"},
    {"afip":"1765","location":"Calle Larga Vieja"},
    {"afip":"1767","location":"Calle Terrada"},
    {"afip":"1772","location":"Calmuco"},
    {"afip":"1809","location":"Camp Vizcacheras Ypf"},
    {"afip":"1811","location":"Campamento Cacheuta Ypf"},
    {"afip":"1812","location":"Campamento Carapacho"},
    {"afip":"1817","location":"Campamento Ranquilco"},
    {"afip":"1822","location":"Campamentos"},
    {"afip":"1883","location":"Campo De Los Andes"},
    {"afip":"1903","location":"Campo El Alamo"},
    {"afip":"1913","location":"Campo El Toro"},
    {"afip":"2041","location":"Canal Pescara"},
    {"afip":"2044","location":"Canalejas"},
    {"afip":"2048","location":"Cancha De Esqui"},
    {"afip":"2098","location":"Capdeville"},
    {"afip":"2114","location":"Capilla Del Covadito"},
    {"afip":"2116","location":"Capilla Del Rosario"},
    {"afip":"2124","location":"Capilla San Jose"},
    {"afip":"2139","location":"Capitan Montoya"},
    {"afip":"2144","location":"Capiz"},
    {"afip":"2153","location":"Caracoles"},
    {"afip":"2168","location":"Carbometal"},
    {"afip":"2196","location":"Carlos Subercaseux"},
    {"afip":"2204","location":"Carmensa"},
    {"afip":"2224","location":"Carril Norte"},
    {"afip":"2229","location":"Carrizal  De  Arriba"},
    {"afip":"2239","location":"Carrodilla  La Puntilla"},
    {"afip":"2242","location":"Cartellone"},
    {"afip":"2249","location":"Casa De Adobe"},
    {"afip":"2256","location":"Casa De Las Peñas"}
    ,
    {"afip":"2333","location":"Catitas Viejas"},
    {"afip":"2362","location":"Cañada Amarilla"}
    ,
    {"afip":"2363","location":"Cañada Ancha"}
    ,
    {"afip":"2369","location":"Cañada Colorada"}
    ,
    {"afip":"2441","location":"Cañadita Alegre"}
    ,
    {"afip":"2452","location":"Cañadon De Bonilla"}
    ,
    {"afip":"2499","location":"Central Hidroelectrica 1"},
    {"afip":"2500","location":"Central Hidroelectrica 2"},
    {"afip":"2508","location":"Cepillo Viejo"},
    {"afip":"2518","location":"Cerrillos Al Norte"},
    {"afip":"2519","location":"Cerrillos Al Sud"},
    {"afip":"2521","location":"Cerrillos Negros"},
    {"afip":"2524","location":"Cerrito Moro"},
    {"afip":"2529","location":"Cerro Aconcagua"},
    {"afip":"2530","location":"Cerro Agua Negra"},
    {"afip":"2531","location":"Cerro Agua Salada"},
    {"afip":"2532","location":"Cerro Aguadita"},
    {"afip":"2536","location":"Cerro Alojamiento"},
    {"afip":"2537","location":"Cerro Alquitran"},
    {"afip":"2539","location":"Cerro Alto De Las Peñas"}
    ,
    {"afip":"2541","location":"Cerro Alvarado Centro"},
    {"afip":"2543","location":"Cerro Angostura"},
    {"afip":"2544","location":"Cerro Arroyo Hondo"},
    {"afip":"2546","location":"Cerro Aspero"},
    {"afip":"2548","location":"Cerro Baleado"},
    {"afip":"2549","location":"Cerro Barauca"},
    {"afip":"2550","location":"Cerro Barbaran O Tres Picos"},
    {"afip":"2551","location":"Cerro Bay"},
    {"afip":"2556","location":"Cerro Bonete"},
    {"afip":"2558","location":"Cerro Bravo"},
    {"afip":"2562","location":"Cerro Catedral"},
    {"afip":"2564","location":"Cerro Chato"},
    {"afip":"2566","location":"Cerro Chiquero"},
    {"afip":"2567","location":"Cerro Cielo"},
    {"afip":"2568","location":"Cerro Cienaga"},
    {"afip":"2569","location":"Cerro Clementino"},
    {"afip":"2570","location":"Cerro Color"},
    {"afip":"2572","location":"Cerro Colorado De Las Lagunill"},
    {"afip":"2576","location":"Cerro Cortaderas"},
    {"afip":"2577","location":"Cerro Cuerno"},
    {"afip":"2578","location":"Cerro Cupula"},
    {"afip":"2581","location":"Cerro De La Banderola"},
    {"afip":"2590","location":"Cerro De Las Leñas"}
    ,
    {"afip":"2592","location":"Cerro De Los Burros"},
    {"afip":"2594","location":"Cerro De Los Dedos"},
    {"afip":"2597","location":"Cerro De Los Potrerillos"},
    {"afip":"2607","location":"Cerro Del Chacay"},
    {"afip":"2611","location":"Cerro Del Medio"},
    {"afip":"2612","location":"Cerro Del Pozo"},
    {"afip":"2613","location":"Cerro Del Rincon Bayo"},
    {"afip":"2616","location":"Cerro Del Zorro"},
    {"afip":"2617","location":"Cerro Divisadero De La Cienegu"},
    {"afip":"2619","location":"Cerro Durazno"},
    {"afip":"2624","location":"Cerro El Guanaco"},
    {"afip":"2626","location":"Cerro Fiero"},
    {"afip":"2628","location":"Cerro Fundicion"},
    {"afip":"2629","location":"Cerro Gaspar"},
    {"afip":"2631","location":"Cerro Grande"},
    {"afip":"2632","location":"Cerro Guadaloso"},
    {"afip":"2636","location":"Cerro Horqueta"},
    {"afip":"2639","location":"Cerro Invernada"},
    {"afip":"2642","location":"Cerro Juan Pobre"},
    {"afip":"2643","location":"Cerro L Corrales"},
    {"afip":"2651","location":"Cerro La Invernada"},
    {"afip":"2653","location":"Cerro La Mano"},
    {"afip":"2658","location":"Cerro Lagañoso"}
    ,
    {"afip":"2664","location":"Cerro Las Piedras"},
    {"afip":"2670","location":"Cerro Los Bajos"},
    {"afip":"2671","location":"Cerro Los Barros"},
    {"afip":"2672","location":"Cerro Los Dientitos"},
    {"afip":"2678","location":"Cerro Manantial"},
    {"afip":"2680","location":"Cerro Masillas"},
    {"afip":"2681","location":"Cerro Melocoton"},
    {"afip":"2684","location":"Cerro Mexico"},
    {"afip":"2685","location":"Cerro Montura"},
    {"afip":"2693","location":"Cerro Negros De Las Mesillas"},
    {"afip":"2694","location":"Cerro Nevado"},
    {"afip":"2699","location":"Cerro Pampa Seca"},
    {"afip":"2700","location":"Cerro Pan De Azucar"},
    {"afip":"2701","location":"Cerro Panta"},
    {"afip":"2704","location":"Cerro Pelado"},
    {"afip":"2707","location":"Cerro Piedra Colorada"},
    {"afip":"2710","location":"Cerro Plomo"},
    {"afip":"2711","location":"Cerro Ponderado"},
    {"afip":"2712","location":"Cerro Potrerillos"},
    {"afip":"2715","location":"Cerro Pozo"},
    {"afip":"2716","location":"Cerro Punta De Agua"},
    {"afip":"2717","location":"Cerro Puntilla Negra"},
    {"afip":"2718","location":"Cerro Puntudo"},
    {"afip":"2719","location":"Cerro Puquios"},
    {"afip":"2725","location":"Cerro Riquitipanche"},
    {"afip":"2728","location":"Cerro Santa Maria"},
    {"afip":"2730","location":"Cerro Sapo"},
    {"afip":"2738","location":"Cerro Tigre"},
    {"afip":"2739","location":"Cerro Tolosa"},
    {"afip":"2742","location":"Cerro Tunduquera"},
    {"afip":"2748","location":"Cerro Yalguaras"},
    {"afip":"2749","location":"Cerro Yareta"},
    {"afip":"2751","location":"Cerros Colorados"},
    {"afip":"2758","location":"Cespedes"},
    {"afip":"2780","location":"Chachao"},
    {"afip":"2782","location":"Chacharalen"},
    {"afip":"2785","location":"Chachingo"},
    {"afip":"2799","location":"Chacras De Coria"},
    {"afip":"2800","location":"Chacras De Lima"},
    {"afip":"2830","location":"Chamuscao"},
    {"afip":"2839","location":"Chapanay"},
    {"afip":"2856","location":"Charco Vacas"},
    {"afip":"2904","location":"Chañaral Redondo"}
    ,
    {"afip":"2957","location":"Chimba"},
    {"afip":"3038","location":"Cienaga Del Alto"},
    {"afip":"3052","location":"Circunvalacion"},
    {"afip":"3053","location":"Cirilo Mahona"},
    {"afip":"3060","location":"Ciudad De Junin"},
    {"afip":"3064","location":"Ciudad Oeste"},
    {"afip":"3068","location":"Clarentino Roldan"},
    {"afip":"3079","location":"Clodomiro Reta"},
    {"afip":"3101","location":"Cochico"},
    {"afip":"3114","location":"Coihueco"},
    {"afip":"3115","location":"Coihueco Norte"},
    {"afip":"3117","location":"Cola Mora"},
    {"afip":"3152","location":"Colon Sandalho"},
    {"afip":"3163","location":"Colonia 3 De Mayo"},
    {"afip":"3181","location":"Colonia Alazanes"},
    {"afip":"3199","location":"Colonia Alvear"},
    {"afip":"3200","location":"Colonia Alvear Oeste"},
    {"afip":"3205","location":"Colonia Andre"},
    {"afip":"3218","location":"Colonia Aspera"},
    {"afip":"3219","location":"Colonia Atuel"},
    {"afip":"3220","location":"Colonia Atuel Norte"},
    {"afip":"3234","location":"Colonia Barraquero"},
    {"afip":"3260","location":"Colonia Bombal"},
    {"afip":"3261","location":"Colonia Bombal Y Tabanera"},
    {"afip":"3264","location":"Colonia Bouquet"},
    {"afip":"3305","location":"Colonia Chalet"},
    {"afip":"3307","location":"Colonia Chato"},
    {"afip":"3312","location":"Colonia Colina"},
    {"afip":"3313","location":"Colonia Colomer"},
    {"afip":"3329","location":"Colonia Curniñan"}
    ,
    {"afip":"3334","location":"Colonia De Las Mulas"},
    {"afip":"3335","location":"Colonia De Los Guanaqueros"},
    {"afip":"3339","location":"Colonia Del Carmen"},
    {"afip":"3340","location":"Colonia Del Diablo"},
    {"afip":"3341","location":"Colonia Del Leon"},
    {"afip":"3343","location":"Colonia Delfino"},
    {"afip":"3348","location":"Colonia Divisadero Del Cardal"},
    {"afip":"3349","location":"Colonia Divisadero Negro"},
    {"afip":"3372","location":"Colonia El Campanario"},
    {"afip":"3399","location":"Colonia El Regadio"},
    {"afip":"3411","location":"Colonia Elena"},
    {"afip":"3423","location":"Colonia Española"}
    ,
    {"afip":"3427","location":"Colonia Estrella"},
    {"afip":"3432","location":"Colonia Faro"},
    {"afip":"3451","location":"Colonia Funes"},
    {"afip":"3472","location":"Colonia Guadal"},
    {"afip":"3475","location":"Colonia Guanaco"},
    {"afip":"3510","location":"Colonia Jara"},
    {"afip":"3511","location":"Colonia Jauregui"},
    {"afip":"3553","location":"Colonia La Escondida"},
    {"afip":"3607","location":"Colonia La Torrecilla"},
    {"afip":"3619","location":"Colonia Lambare"},
    {"afip":"3627","location":"Colonia Las Rosas"},
    {"afip":"3640","location":"Colonia Lola"},
    {"afip":"3652","location":"Colonia Los Huevos"},
    {"afip":"3654","location":"Colonia Los Oscuros"},
    {"afip":"3657","location":"Colonia Los Tapones"},
    {"afip":"3708","location":"Colonia Mirador"},
    {"afip":"3712","location":"Colonia Monte Caseros"},
    {"afip":"3720","location":"Colonia Muralla"},
    {"afip":"3726","location":"Colonia Nacional De Los Indios"},
    {"afip":"3732","location":"Colonia Negro"},
    {"afip":"3758","location":"Colonia Osamenta"},
    {"afip":"3771","location":"Colonia Papal"},
    {"afip":"3772","location":"Colonia Pascual Iacarini"},
    {"afip":"3777","location":"Colonia Pedernales"},
    {"afip":"3779","location":"Colonia Pencal"},
    {"afip":"3787","location":"Colonia Pico Colorado"},
    {"afip":"3809","location":"Colonia Reina"},
    {"afip":"3830","location":"Colonia San Agustin"},
    {"afip":"3893","location":"Colonia Segovia"},
    {"afip":"3904","location":"Colonia Soitue"},
    {"afip":"3909","location":"Colonia Tabanera"},
    {"afip":"3923","location":"Colonia Torrecillas"},
    {"afip":"3924","location":"Colonia Torrecito"},
    {"afip":"3929","location":"Colonia Tres Altitos"},
    {"afip":"3980","location":"Com Nac De Energia Atomica"},
    {"afip":"3992","location":"Comandante Salas"},
    {"afip":"3998","location":"Comision Nac De Emergencia"},
    {"afip":"4001","location":"Compuertas Negras"},
    {"afip":"4010","location":"Concha Subercaseaux"},
    {"afip":"4038","location":"Control Ypf"},
    {"afip":"4051","location":"Coquimbito"},
    {"afip":"4062","location":"Cordon De Plata"},
    {"afip":"4079","location":"Coronel Beltran"},
    {"afip":"4129","location":"Corral De Cuero"},
    {"afip":"4133","location":"Corral De Lorca"},
    {"afip":"4141","location":"Corral Del Molle"},
    {"afip":"4179","location":"Costa De Araujo"},
    {"afip":"4186","location":"Costa Del Diamante"},
    {"afip":"4242","location":"Cristo Redentor"},
    {"afip":"4243","location":"Cristobal Lopez"},
    {"afip":"4257","location":"Cruz Blanca"},
    {"afip":"4264","location":"Cruz De Piedra"},
    {"afip":"4265","location":"Cruz De Piedra Pto Gendarmeria"},
    {"afip":"4270","location":"Cruz Del Yugo"},
    {"afip":"4271","location":"Cruz Gimenez"},
    {"afip":"4273","location":"Cruz Ledesma"},
    {"afip":"4279","location":"Cto Del Tigre"},
    {"afip":"4280","location":"Cuadro Benegas"},
    {"afip":"4281","location":"Cuadro Bombal"},
    {"afip":"4282","location":"Cuadro Nacional"},
    {"afip":"4324","location":"Cuesta De Los Terneros"},
    {"afip":"4343","location":"Cupiles"},
    {"afip":"4374","location":"D Lopez"},
    {"afip":"4378","location":"Dalmiro Zapata"},
    {"afip":"4382","location":"Daniel Lucero"},
    {"afip":"4383","location":"Daniel Morgan"},
    {"afip":"4399","location":"Delgadillo"},
    {"afip":"4446","location":"Dionisio Ordoñez"}
    ,
    {"afip":"4447","location":"Dionisio Ortubia"},
    {"afip":"4451","location":"Dique Del Valle De Uco"},
    {"afip":"4465","location":"Dique Rio Mendoza"},
    {"afip":"4470","location":"Distrito Compuerta"},
    {"afip":"4476","location":"Divisadero Colorado"},
    {"afip":"4478","location":"Divisadero Negro"},
    {"afip":"4486","location":"Doctor Antonio Soomas"},
    {"afip":"4510","location":"Domingo Gimenez"},
    {"afip":"4511","location":"Domingo Lara"},
    {"afip":"4513","location":"Domingo Oga"},
    {"afip":"4514","location":"Domingo Real"},
    {"afip":"4526","location":"Don Martin"},
    {"afip":"4534","location":"Donato Ojeda"},
    {"afip":"4538","location":"Doroteo Ordoñez"}
    ,
    {"afip":"4580","location":"Dulce"},
    {"afip":"4590","location":"E Rosales"},
    {"afip":"4640","location":"El Alpero"},
    {"afip":"4644","location":"El Alto Salvador"},
    {"afip":"4674","location":"El Azufre"},
    {"afip":"4683","location":"El Balsadero"},
    {"afip":"4685","location":"El Banderon"},
    {"afip":"4707","location":"El Bonito"},
    {"afip":"4710","location":"El Borbollon"},
    {"afip":"4725","location":"El Buen Pastor"},
    {"afip":"4728","location":"El Cabao Viejo"},
    {"afip":"4743","location":"El Calvadito"},
    {"afip":"4756","location":"El Capacho"},
    {"afip":"4759","location":"El Caranchito"},
    {"afip":"4761","location":"El Carbalino"},
    {"afip":"4778","location":"El Carrizal De Abajo"},
    {"afip":"4784","location":"El Cavadito"},
    {"afip":"4786","location":"El Cavado Chico"},
    {"afip":"4788","location":"El Cañito"}
    ,
    {"afip":"4797","location":"El Cenizo"},
    {"afip":"4800","location":"El Central"},
    {"afip":"4801","location":"El Cepillo"},
    {"afip":"4811","location":"El Chacallal"},
    {"afip":"4812","location":"El Chacay"},
    {"afip":"4819","location":"El Challao"},
    {"afip":"4828","location":"El Chañar"}
    ,
    {"afip":"4834","location":"El Chilcal"},
    {"afip":"4844","location":"El Choique"},
    {"afip":"4862","location":"El Cipres"},
    {"afip":"4871","location":"El Colon"},
    {"afip":"4879","location":"El Corbalno"},
    {"afip":"4922","location":"El Desvio"},
    {"afip":"4986","location":"El Gigantillo"},
    {"afip":"4988","location":"El Gonzano"},
    {"afip":"4989","location":"El Gorgino"},
    {"afip":"4993","location":"El Guadal De Campos"},
    {"afip":"5003","location":"El Guerrino"},
    {"afip":"5018","location":"El Infiernillo"},
    {"afip":"5019","location":"El Infierno"},
    {"afip":"5029","location":"El Jilguero"},
    {"afip":"5035","location":"El Juncalito"},
    {"afip":"5044","location":"El Lechucito"},
    {"afip":"5046","location":"El Lemino"},
    {"afip":"5056","location":"El Luquino"},
    {"afip":"5068","location":"El Manzano Historico"},
    {"afip":"5075","location":"El Marucho"},
    {"afip":"5080","location":"El Maurino"},
    {"afip":"5121","location":"El Nevado"},
    {"afip":"5123","location":"El Nihuil"},
    {"afip":"5185","location":"El Parral"},
    {"afip":"5187","location":"El Pascal"},
    {"afip":"5190","location":"El Pastal"},
    {"afip":"5196","location":"El Payen"},
    {"afip":"5207","location":"El Peral"},
    {"afip":"5213","location":"El Perino"},
    {"afip":"5222","location":"El Picona"},
    {"afip":"5243","location":"El Plateado"},
    {"afip":"5247","location":"El Plumero"},
    {"afip":"5289","location":"El Puntiagudo"},
    {"afip":"5319","location":"El Regadio"},
    {"afip":"5325","location":"El Retamoso"},
    {"afip":"5387","location":"El Sosneado"},
    {"afip":"5403","location":"El Tamarindo"},
    {"afip":"5411","location":"El Tapon"},
    {"afip":"5429","location":"El Topon"},
    {"afip":"5436","location":"El Toscal"},
    {"afip":"5466","location":"El Usillal"},
    {"afip":"5474","location":"El Vaquero"},
    {"afip":"5477","location":"El Vatro"},
    {"afip":"5485","location":"El Ventarron"},
    {"afip":"5492","location":"El Villeguino"},
    {"afip":"5493","location":"El Vilteguino"},
    {"afip":"5510","location":"El Zampal"},
    {"afip":"5514","location":"El Zapatino"},
    {"afip":"5519","location":"El Zorzal"},
    {"afip":"5523","location":"El ñango"}
    ,
    {"afip":"5532","location":"Eloy Funes"},
    {"afip":"5540","location":"Embalse Valle Grande"},
    {"afip":"5545","location":"Emiliano Lucero"},
    {"afip":"5551","location":"Emilio Nieta"},
    {"afip":"5560","location":"Empalme Frontera"},
    {"afip":"5568","location":"Empalme Resguardo"},
    {"afip":"5593","location":"Epifanio Fernandez"},
    {"afip":"5600","location":"Ernesto Alcaraz"},
    {"afip":"5601","location":"Ernesto Lopez"},
    {"afip":"5614","location":"Escudero"},
    {"afip":"5620","location":"Espejo"},
    {"afip":"5621","location":"Espejo Resguardado"},
    {"afip":"5632","location":"Espino"},
    {"afip":"5733","location":"Estacion Uspallata"},
    {"afip":"5745","location":"Estancia Aguanda"},
    {"afip":"5746","location":"Estancia Arroyo Hondo"},
    {"afip":"5748","location":"Estancia Aveiro"},
    {"afip":"5756","location":"Estancia Casa De Piedra"},
    {"afip":"5758","location":"Estancia Casas Viejas"},
    {"afip":"5762","location":"Estancia Chacaico"},
    {"afip":"5774","location":"Estancia Correa"},
    {"afip":"5776","location":"Estancia Cueva Del Toro"},
    {"afip":"5786","location":"Estancia El Balderon"},
    {"afip":"5787","location":"Estancia El Bonito"},
    {"afip":"5788","location":"Estancia El Campamento"},
    {"afip":"5790","location":"Estancia El Carrizal"},
    {"afip":"5791","location":"Estancia El Carrizalito"},
    {"afip":"5818","location":"Estancia Gil"},
    {"afip":"5823","location":"Estancia Guinazu"},
    {"afip":"5829","location":"Estancia Jocoli"},
    {"afip":"5833","location":"Estancia La Argentina"},
    {"afip":"5840","location":"Estancia La Chuña"}
    ,
    {"afip":"5843","location":"Estancia La Cortadera"},
    {"afip":"5863","location":"Estancia La Pampa"},
    {"afip":"5869","location":"Estancia La Puma"},
    {"afip":"5873","location":"Estancia La Rosa"},
    {"afip":"5874","location":"Estancia La Salcedina"},
    {"afip":"5875","location":"Estancia La Sarita"},
    {"afip":"5876","location":"Estancia La Trintrica"},
    {"afip":"5878","location":"Estancia La Varita"},
    {"afip":"5879","location":"Estancia La Vizcachera"},
    {"afip":"5885","location":"Estancia Las Chilcas"},
    {"afip":"5887","location":"Estancia Las Higueras"},
    {"afip":"5899","location":"Estancia Las Vizcacheras"},
    {"afip":"5906","location":"Estancia Los Chacayes"},
    {"afip":"5909","location":"Estancia Los Huaicos"},
    {"afip":"5910","location":"Estancia Los Leones"},
    {"afip":"5917","location":"Estancia Mallea"},
    {"afip":"5957","location":"Estancia San Martin"},
    {"afip":"5971","location":"Estancia Silva"},
    {"afip":"5972","location":"Estancia Sofia Raquel"},
    {"afip":"5977","location":"Estancia Tierras Blancas"},
    {"afip":"5980","location":"Estancia Uspallata"},
    {"afip":"5983","location":"Estancia Villavicencio"},
    {"afip":"5984","location":"Estancia Viluco"},
    {"afip":"5985","location":"Estancia Yalguaraz"},
    {"afip":"5986","location":"Estancia Yaucha"},
    {"afip":"5990","location":"Estanislao Ordoñez"}
    ,
    {"afip":"6020","location":"Eugenio Bustos"},
    {"afip":"6022","location":"Eusebia Viuda De Gomez"},
    {"afip":"6025","location":"Evaristo Acevedo"},
    {"afip":"6026","location":"Evaristo Salas"},
    {"afip":"6031","location":"Ex Fortin Malargue"},
    {"afip":"6047","location":"Fabriciano Rojas"},
    {"afip":"6087","location":"Felipe Garro"},
    {"afip":"6088","location":"Felipe Perez"},
    {"afip":"6092","location":"Fermin Perez"},
    {"afip":"6122","location":"Finca El Arroz"},
    {"afip":"6137","location":"Finca Lopez"},
    {"afip":"6138","location":"Finca Los Alamos"},
    {"afip":"6167","location":"Florencio Garro"},
    {"afip":"6168","location":"Florencio Molina"},
    {"afip":"6169","location":"Florencio Ordoñez"}
    ,
    {"afip":"6177","location":"Florindo Flores"},
    {"afip":"6233","location":"Forzudo"},
    {"afip":"6249","location":"Francisco Molina"},
    {"afip":"6254","location":"Francisco Rojas"},
    {"afip":"6259","location":"Fray Luis Beltran"},
    {"afip":"6276","location":"Fructuoso Diaz"},
    {"afip":"6324","location":"Garganta Del Leon"},
    {"afip":"6339","location":"Gaspar Campos"},
    {"afip":"6377","location":"General Gutierrez"},
    {"afip":"6395","location":"General Ortega"},
    {"afip":"6426","location":"German Maturano"},
    {"afip":"6428","location":"Gertrudis De Ojeda"},
    {"afip":"6436","location":"Gilberto Perez"},
    {"afip":"6440","location":"Glaciares Del Rio Blanco"},
    {"afip":"6451","location":"Gobernador Benegas"},
    {"afip":"6453","location":"Gobernador Civit"},
    {"afip":"6463","location":"Gobernador Luis Molina"},
    {"afip":"6486","location":"Godoy Cruz"},
    {"afip":"6487","location":"Goico"},
    {"afip":"6507","location":"Goudge"},
    {"afip":"6542","location":"Gregorio Zapata"},
    {"afip":"6555","location":"Guadal De Los Perez"},
    {"afip":"6557","location":"Guadales"},
    {"afip":"6572","location":"Gualtallary"},
    {"afip":"6622","location":"Guayqueria Colorada"},
    {"afip":"6635","location":"Guido"},
    {"afip":"6637","location":"Guillermo Donaire"},
    {"afip":"6644","location":"Gurruchaga"},
    {"afip":"6648","location":"H Garzala"},
    {"afip":"6691","location":"Hermenegildo Diaz"},
    {"afip":"6737","location":"Honorio Zapata"},
    {"afip":"6744","location":"Hornito Del Gringo"},
    {"afip":"6746","location":"Hornos De Moyano"},
    {"afip":"6758","location":"Hotel Portezuelo Del Viento"},
    {"afip":"6759","location":"Hotel Termas Del Azufre"},
    {"afip":"6760","location":"Hotel Termas El Sosneado"},
    {"afip":"6776","location":"Huaicos De Rufino"},
    {"afip":"6777","location":"Huaiqueria De La Horqueta"},
    {"afip":"6778","location":"Huaiqueria De Los Burros"},
    {"afip":"6818","location":"Huecos De Los Tordillos"},
    {"afip":"6881","location":"Ignacio Villegas"},
    {"afip":"6884","location":"Iguazu"},
    {"afip":"6918","location":"Ingeniero Balloffet"},
    {"afip":"6932","location":"Ingeniero Giagnoni"},
    {"afip":"6933","location":"Ingeniero Gustavo Andre"},
    {"afip":"6998","location":"Irineo Zapata"},
    {"afip":"7002","location":"Isabel Flores"},
    {"afip":"7032","location":"Isla Del Cuchillo"},
    {"afip":"7052","location":"Isla Retamito"},
    {"afip":"7099","location":"J Campos"},
    {"afip":"7101","location":"J Ortubia"},
    {"afip":"7102","location":"J Veron"},
    {"afip":"7109","location":"Jaguel"},
    {"afip":"7110","location":"Jaguel Amarillo"},
    {"afip":"7111","location":"Jaguel De Las Chilcas"},
    {"afip":"7112","location":"Jaguel De Rosas"},
    {"afip":"7113","location":"Jaguel Del Catalan"},
    {"afip":"7115","location":"Jaguel Del Gaucho"},
    {"afip":"7116","location":"Jaguel Del Gobierno"},
    {"afip":"7121","location":"Jaime Prats"},
    {"afip":"7134","location":"Jarilloso"},
    {"afip":"7136","location":"Jaucha"},
    {"afip":"7147","location":"Jesus Nazareno"},
    {"afip":"7152","location":"Jocoli"},
    {"afip":"7153","location":"Jocoli Viejo"},
    {"afip":"7154","location":"Jofre"},
    {"afip":"7156","location":"Jorge Newbery"},
    {"afip":"7159","location":"Jose Campos"},
    {"afip":"7164","location":"Jose Diaz"},
    {"afip":"7165","location":"Jose Fernandez"},
    {"afip":"7170","location":"Jose Lucero"},
    {"afip":"7177","location":"Jose R Molina"},
    {"afip":"7179","location":"Jose Sosa"},
    {"afip":"7180","location":"Jose Suarez"},
    {"afip":"7186","location":"Juan B Dufau"},
    {"afip":"7201","location":"Juan H Ortiz"},
    {"afip":"7209","location":"Juan Millan"},
    {"afip":"7220","location":"Juan Zapata"},
    {"afip":"7235","location":"Julio Comeglio"},
    {"afip":"7254","location":"Junta De La Vaina"},
    {"afip":"7255","location":"Junta De Los Rios"},
    {"afip":"7282","location":"L Del Aguero"},
    {"afip":"7283","location":"L Perez"},
    {"afip":"7284","location":"L Prado"},
    {"afip":"7290","location":"La Adelina"},
    {"afip":"7319","location":"La Angelina"},
    {"afip":"7321","location":"La Angostura"},
    {"afip":"7328","location":"La Arboleda"},
    {"afip":"7350","location":"La Bandera"},
    {"afip":"7352","location":"La Barda Cortada"},
    {"afip":"7362","location":"La Batra"},
    {"afip":"7386","location":"La Boveda"},
    {"afip":"7402","location":"La Caldenada"},
    {"afip":"7407","location":"La California"},
    {"afip":"7433","location":"La Carusina"},
    {"afip":"7434","location":"La Casa Del Tigre"},
    {"afip":"7458","location":"La Central  Rivadavia"},
    {"afip":"7467","location":"La Chapina"},
    {"afip":"7479","location":"La Chimba"},
    {"afip":"7491","location":"La Chuna"},
    {"afip":"7495","location":"La Cienaguita"},
    {"afip":"7506","location":"La Cola"},
    {"afip":"7510","location":"La Colonia Sud"},
    {"afip":"7562","location":"La Divisoria"},
    {"afip":"7601","location":"La Escandinava"},
    {"afip":"7625","location":"La Estrechura"},
    {"afip":"7633","location":"La Excavacion"},
    {"afip":"7665","location":"La Fundicion"},
    {"afip":"7696","location":"La Guevarina"},
    {"afip":"7709","location":"La Holanda"},
    {"afip":"7717","location":"La Hullera"},
    {"afip":"7734","location":"La Isla Chica"},
    {"afip":"7735","location":"La Isla Grande"},
    {"afip":"7739","location":"La Jacintita"},
    {"afip":"7740","location":"La Jacinto"},
    {"afip":"7757","location":"La Lagunita"},
    {"afip":"7767","location":"La Leona"},
    {"afip":"7781","location":"La Llave Nueva"},
    {"afip":"7782","location":"La Llave Vieja"},
    {"afip":"7800","location":"La Lunina"},
    {"afip":"7835","location":"La Marzolina"},
    {"afip":"7856","location":"La Mina Del Peceño"}
    ,
    {"afip":"7860","location":"La Montilla"},
    {"afip":"7872","location":"La Negrita"},
    {"afip":"7928","location":"La Pastoral"},
    {"afip":"7937","location":"La Pega"},
    {"afip":"7953","location":"La Picarona"},
    {"afip":"7954","location":"La Picasa"},
    {"afip":"7955","location":"La Picaza"},
    {"afip":"7956","location":"La Pichana"},
    {"afip":"7966","location":"La Pirata"},
    {"afip":"8077","location":"La Salinilla"},
    {"afip":"8096","location":"La Seña"}
    ,
    {"afip":"8110","location":"La Sombrilla"},
    {"afip":"8162","location":"La Valenciana"},
    {"afip":"8166","location":"La Varita"},
    {"afip":"8168","location":"La Vasconia"},
    {"afip":"8217","location":"Ladislao"},
    {"afip":"8218","location":"Ladislao Campos"},
    {"afip":"8262","location":"Laguna De Guanacache"},
    {"afip":"8276","location":"Laguna Del Rosario"},
    {"afip":"8294","location":"Laguna Negra"},
    {"afip":"8302","location":"Laguna Salada"},
    {"afip":"8372","location":"Las Arabias"},
    {"afip":"8412","location":"Las Carditas"},
    {"afip":"8422","location":"Las Catitas"},
    {"afip":"8447","location":"Las Colonias"},
    {"afip":"8449","location":"Las Compuertas"},
    {"afip":"8450","location":"Las Compuertas Negras"},
    {"afip":"8459","location":"Las Cruces"},
    {"afip":"8494","location":"Las Gateadas"},
    {"afip":"8495","location":"Las Gatitas"},
    {"afip":"8541","location":"Las Loicas"},
    {"afip":"8595","location":"Las Paredes"},
    {"afip":"8618","location":"Las Pintadas"},
    {"afip":"8636","location":"Las Rajaduras"},
    {"afip":"8655","location":"Las Taguas"},
    {"afip":"8672","location":"Las Torrecitas"},
    {"afip":"8699","location":"Las Vayas"},
    {"afip":"8700","location":"Las Vegas"},
    {"afip":"8708","location":"Las Viscacheras"},
    {"afip":"8709","location":"Las Vistas"},
    {"afip":"8710","location":"Las Vizcachas"},
    {"afip":"8712","location":"Las Yeguas"},
    {"afip":"8732","location":"Lavalle Villa Tulumaya"},
    {"afip":"8772","location":"Lezcano"},
    {"afip":"8798","location":"Limon"},
    {"afip":"8815","location":"Lino Perez"},
    {"afip":"8820","location":"Lira"},
    {"afip":"8821","location":"Lisandro Escudero"},
    {"afip":"8832","location":"Llano Blanco"},
    {"afip":"8854","location":"Loma Chata"},
    {"afip":"8856","location":"Loma Colorada"},
    {"afip":"8861","location":"Loma De Los Burros"},
    {"afip":"8865","location":"Loma Del Cerro Aspero"},
    {"afip":"8866","location":"Loma Del Chañar"}
    ,
    {"afip":"8869","location":"Loma Extendida"},
    {"afip":"8873","location":"Loma Jaguel Del Gaucho"},
    {"afip":"8877","location":"Loma Negra Grande"},
    {"afip":"8888","location":"Lomas Altas"},
    {"afip":"8889","location":"Lomas Bayas"},
    {"afip":"8891","location":"Lomas Chicas"},
    {"afip":"8892","location":"Lomas Coloradas"},
    {"afip":"8916","location":"Lomita Larga"},
    {"afip":"8917","location":"Lomita Morada"},
    {"afip":"8923","location":"Lonco Vacas"},
    {"afip":"8948","location":"Los Ahumados"},
    {"afip":"8958","location":"Los Algodones"},
    {"afip":"8961","location":"Los Altamiques"},
    {"afip":"8965","location":"Los Amarillos"},
    {"afip":"8974","location":"Los Arboles De Villegas"},
    {"afip":"8983","location":"Los Arroyos"},
    {"afip":"9009","location":"Los Britos"},
    {"afip":"9011","location":"Los Burgos"},
    {"afip":"9017","location":"Los Campamentos"},
    {"afip":"9024","location":"Los Carrizales"},
    {"afip":"9042","location":"Los Chacayes"},
    {"afip":"9068","location":"Los Colados"},
    {"afip":"9074","location":"Los Cometierras"},
    {"afip":"9075","location":"Los Compartos"},
    {"afip":"9088","location":"Los Corredores"},
    {"afip":"9108","location":"Los Embarques"},
    {"afip":"9120","location":"Los Filtros"},
    {"afip":"9154","location":"Los Horconcitos"},
    {"afip":"9159","location":"Los Huarpes"},
    {"afip":"9190","location":"Los Medanos Negros"},
    {"afip":"9212","location":"Los Moros"},
    {"afip":"9238","location":"Los Otoyanes"},
    {"afip":"9245","location":"Los Papagayos"},
    {"afip":"9247","location":"Los Paramillos"},
    {"afip":"9249","location":"Los Parlamentos"},
    {"afip":"9256","location":"Los Pejecitos"},
    {"afip":"9257","location":"Los Pejes"},
    {"afip":"9258","location":"Los Penitentes"},
    {"afip":"9297","location":"Los Ralos"},
    {"afip":"9298","location":"Los Ramblones"},
    {"afip":"9305","location":"Los Repuntes"},
    {"afip":"9319","location":"Los Roseti"},
    {"afip":"9330","location":"Los Sauces Lavalle"},
    {"afip":"9347","location":"Los Tamarindos"},
    {"afip":"9357","location":"Los Terneros"},
    {"afip":"9364","location":"Los Tolditos"},
    {"afip":"9366","location":"Los Tordillos"},
    {"afip":"9368","location":"Los Toscales"},
    {"afip":"9390","location":"Los Verdes"},
    {"afip":"9392","location":"Los Villegas"},
    {"afip":"9394","location":"Los Yaullines"},
    {"afip":"9395","location":"Los Yoles"},
    {"afip":"9407","location":"Luanco"},
    {"afip":"9410","location":"Lucas Donaire"},
    {"afip":"9433","location":"Luis Marquez"},
    {"afip":"9439","location":"Lujan De Cuyo"},
    {"afip":"9448","location":"Lunlunta"},
    {"afip":"9457","location":"Luzuriaga"},
    {"afip":"9458","location":"M Escudero"},
    {"afip":"9459","location":"M Quiroga"},
    {"afip":"9522","location":"Malal Del Medio"},
    {"afip":"9524","location":"Malargue"},
    {"afip":"9549","location":"Mallin Quemado"},
    {"afip":"9615","location":"Maquinista Levet"},
    {"afip":"9631","location":"Maravilla"},
    {"afip":"9661","location":"Maria Garcia"},
    {"afip":"9671","location":"Maria Luisa"},
    {"afip":"9678","location":"Maria Viuda De Donaire"},
    {"afip":"9689","location":"Mario Olguin"},
    {"afip":"9714","location":"Masa"},
    {"afip":"9728","location":"Matancilla"},
    {"afip":"9733","location":"Mathieu Norte"},
    {"afip":"9734","location":"Matias Garro"},
    {"afip":"9738","location":"Matoncilla"},
    {"afip":"9744","location":"Maturana"},
    {"afip":"9747","location":"Mauricio Calderon"},
    {"afip":"9761","location":"Mayor Drummond"},
    {"afip":"9775","location":"Mechanquil"},
    {"afip":"9776","location":"Mechenguil"},
    {"afip":"9792","location":"Medanos Colorado"},
    {"afip":"9794","location":"Medardo Miranda"},
    {"afip":"9803","location":"Medrano"},
    {"afip":"9809","location":"Meliton Campos"},
    {"afip":"9811","location":"Melocoton"},
    {"afip":"9816","location":"Mendoza"},
    {"afip":"9835","location":"Meseta Colorada"},
    {"afip":"9861","location":"Miguez"},
    {"afip":"9871","location":"Mina Argentina"},
    {"afip":"9883","location":"Mina Ethel"},
    {"afip":"9886","location":"Mina Huemul"},
    {"afip":"9906","location":"Mina Santa Cruz"},
    {"afip":"9910","location":"Mina Volcan Overo"},
    {"afip":"9911","location":"Minacar"},
    {"afip":"9915","location":"Minas De Petroleo"},
    {"afip":"9917","location":"Minas Del Nevado"},
    {"afip":"9918","location":"Minas El Sosneado"},
    {"afip":"9919","location":"Minas Salagasta"},
    {"afip":"9922","location":"Minelli"},
    {"afip":"9989","location":"Mojon Ocho"},
    {"afip":"9996","location":"Molino"},
    {"afip":"10018","location":"Moluches"},
    {"afip":"10035","location":"Monte Bayo"},
    {"afip":"10047","location":"Monte Coman"},
    {"afip":"10091","location":"Monumento Al Ejercito De Los A"},
    {"afip":"10110","location":"Moron Chico"},
    {"afip":"10111","location":"Moron Viejo"},
    {"afip":"10116","location":"Morro Del Cuero"},
    {"afip":"10120","location":"Mosmota"},
    {"afip":"10129","location":"Moyano"},
    {"afip":"10140","location":"Mundo Nuevo"},
    {"afip":"10152","location":"N Zapata"},
    {"afip":"10192","location":"Natalia Donaire"},
    {"afip":"10203","location":"Necto Sosa"},
    {"afip":"10208","location":"Negro Quemado"},
    {"afip":"10214","location":"Nestor Aguilera"},
    {"afip":"10224","location":"Nicolas Ordoñez"}
    ,
    {"afip":"10229","location":"Nihuil"},
    {"afip":"10241","location":"Nire Co"},
    {"afip":"10275","location":"Nueva California"},
    {"afip":"10279","location":"Nueva Ciudad"},
    {"afip":"10338","location":"Ochenta Y Cuatro"},
    {"afip":"10388","location":"Onotre Puebla"},
    {"afip":"10430","location":"P Planchon"},
    {"afip":"10431","location":"P Rosales"},
    {"afip":"10433","location":"P San Ignacio"},
    {"afip":"10491","location":"Palermo Chico"},
    {"afip":"10547","location":"Pampa Amarilla"},
    {"afip":"10568","location":"Pampa De Las Yaretas"},
    {"afip":"10570","location":"Pampa De Los Bayos"},
    {"afip":"10586","location":"Pampa Del Salado"},
    {"afip":"10588","location":"Pampa Del Tigre"},
    {"afip":"10640","location":"Pampa Yalguaraz"},
    {"afip":"10649","location":"Pampita Embarcadero Fcgsm"},
    {"afip":"10659","location":"Panqueua"},
    {"afip":"10665","location":"Papagayo"},
    {"afip":"10678","location":"Paradero La Superiora"},
    {"afip":"10736","location":"Paramillo"},
    {"afip":"10737","location":"Paramillo De Las Vacas"},
    {"afip":"10747","location":"Pareditas"},
    {"afip":"10769","location":"Pascual Sosa"},
    {"afip":"10805","location":"Paso De Las Canoas"},
    {"afip":"10806","location":"Paso De Las Carretas"},
    {"afip":"10814","location":"Paso De Los Andes"},
    {"afip":"10817","location":"Paso De Los Gauchos"},
    {"afip":"10837","location":"Paso Del Cisne"},
    {"afip":"10862","location":"Paso Hondo"},
    {"afip":"10875","location":"Paso Los Palos"},
    {"afip":"10927","location":"Pata Mora"},
    {"afip":"10931","location":"Patimalal"},
    {"afip":"10940","location":"Paulino Matura"},
    {"afip":"10953","location":"Payun"},
    {"afip":"10961","location":"Pedregal"},
    {"afip":"10962","location":"Pedro Castelu"},
    {"afip":"10974","location":"Pedro Pablo Perez"},
    {"afip":"10976","location":"Pedro Vargas"},
    {"afip":"10995","location":"Perdriel"},
    {"afip":"11020","location":"Petroleo"},
    {"afip":"11023","location":"Peña"}
    ,
    {"afip":"11035","location":"Philipps"},
    {"afip":"11064","location":"Pichi Ciego Estacion Fcgsm"},
    {"afip":"11074","location":"Picos Bayos"},
    {"afip":"11091","location":"Piedra De Afilar"},
    {"afip":"11174","location":"Pircas De Osorio"},
    {"afip":"11178","location":"Pirquita Embarcadero Fcgsm"},
    {"afip":"11220","location":"Plaza De Mulas"},
    {"afip":"11237","location":"Po Alvarado Norte"},
    {"afip":"11238","location":"Po Alvarado Sur"},
    {"afip":"11239","location":"Po Amarillo"},
    {"afip":"11242","location":"Po De Contrabandista"},
    {"afip":"11243","location":"Po De La Cumbre"},
    {"afip":"11245","location":"Po De La Quebrada Honda"},
    {"afip":"11248","location":"Po De Los Escalones"},
    {"afip":"11253","location":"Po Del Huanaco"},
    {"afip":"11256","location":"Po Del Rubio"},
    {"afip":"11259","location":"Po Maipu"},
    {"afip":"11260","location":"Po Mallan"},
    {"afip":"11261","location":"Po Pehuenche"},
    {"afip":"11262","location":"Po Valle Hermoso"},
    {"afip":"11280","location":"Polvareda"},
    {"afip":"11286","location":"Ponce"},
    {"afip":"11308","location":"Portezuelo Choique"},
    {"afip":"11313","location":"Portillo Agua De Toro"},
    {"afip":"11314","location":"Portillo Canales"},
    {"afip":"11316","location":"Portillo Cruz De Piedra"},
    {"afip":"11317","location":"Portillo D Quemado"},
    {"afip":"11318","location":"Portillo De Colina"},
    {"afip":"11319","location":"Portillo De Indio"},
    {"afip":"11320","location":"Portillo De La G  Del Camino"},
    {"afip":"11321","location":"Portillo De La Lagrima Viva"},
    {"afip":"11322","location":"Portillo De La Yesera"},
    {"afip":"11323","location":"Portillo De Las Cabezas"},
    {"afip":"11324","location":"Portillo De Las Vacas"},
    {"afip":"11325","location":"Portillo De Lomas Coloradas"},
    {"afip":"11326","location":"Portillo De Pinquenes"},
    {"afip":"11327","location":"Portillo Del Diablo"},
    {"afip":"11328","location":"Portillo Del Medio"},
    {"afip":"11329","location":"Portillo Del Norte"},
    {"afip":"11330","location":"Portillo Del Papal"},
    {"afip":"11331","location":"Portillo Del Tigre"},
    {"afip":"11332","location":"Portillo Del Viento"},
    {"afip":"11333","location":"Portillo La Pampa"},
    {"afip":"11334","location":"Portillo Occidental Del Bayo"},
    {"afip":"11335","location":"Portillo Pedernales"},
    {"afip":"11348","location":"Posta De Hierro"},
    {"afip":"11358","location":"Poste De Fierro"},
    {"afip":"11361","location":"Potrerillos"},
    {"afip":"11385","location":"Potrero San Pablo"},
    {"afip":"11548","location":"Pta Del Agua Vieja"},
    {"afip":"11577","location":"Pto Los Amarillos"},
    {"afip":"11623","location":"Pueblo Diamante"},
    {"afip":"11625","location":"Pueblo Echevarrieta"},
    {"afip":"11637","location":"Pueblo Luna"},
    {"afip":"11661","location":"Pueblo Soto"},
    {"afip":"11683","location":"Puente Del Inca"},
    {"afip":"11709","location":"Puente Viejo"},
    {"afip":"11719","location":"Puerta De La Isla"},
    {"afip":"11800","location":"Puerto Hortensa"},
    {"afip":"11853","location":"Puerto Rincon Escalona"},
    {"afip":"11896","location":"Puesta El Cavado"},
    {"afip":"11902","location":"Puesto A Martinez"},
    {"afip":"11903","location":"Puesto Agua Amarga"},
    {"afip":"11904","location":"Puesto Agua De La Liebre"},
    {"afip":"11905","location":"Puesto Agua De La Merina"},
    {"afip":"11906","location":"Puesto Agua De La Zorra"},
    {"afip":"11907","location":"Puesto Agua De Zanjon"},
    {"afip":"11908","location":"Puesto Agua Del Medano"},
    {"afip":"11910","location":"Puesto Alfarfa"},
    {"afip":"11911","location":"Puesto Algarrobo Grande"},
    {"afip":"11913","location":"Puesto Atamisqui"},
    {"afip":"11916","location":"Puesto Canales"},
    {"afip":"11917","location":"Puesto Carrizalito"},
    {"afip":"11920","location":"Puesto Chambon"},
    {"afip":"11939","location":"Puesto De Garro"},
    {"afip":"11943","location":"Puesto De La Corona"},
    {"afip":"11945","location":"Puesto De La Salada"},
    {"afip":"11947","location":"Puesto De Las Carretas"},
    {"afip":"11948","location":"Puesto De Las Tropas"},
    {"afip":"11958","location":"Puesto De Olguin"},
    {"afip":"11959","location":"Puesto De Orozco"},
    {"afip":"11960","location":"Puesto De Petra"},
    {"afip":"11962","location":"Puesto De Sosa"},
    {"afip":"11968","location":"Puesto Del Buen Pastor"},
    {"afip":"11969","location":"Puesto Del Chañacal"}
    ,
    {"afip":"11978","location":"Puesto El Carrizalito"},
    {"afip":"11979","location":"Puesto El Jagual"},
    {"afip":"11980","location":"Puesto El Jilguero"},
    {"afip":"11981","location":"Puesto El Manzano"},
    {"afip":"11982","location":"Puesto El Peral"},
    {"afip":"11983","location":"Puesto El Pichon"},
    {"afip":"11984","location":"Puesto El Retamito"},
    {"afip":"11986","location":"Puesto El Totoral"},
    {"afip":"11987","location":"Puesto El Trueno"},
    {"afip":"11988","location":"Puesto Escondido"},
    {"afip":"11989","location":"Puesto F Tello"},
    {"afip":"11991","location":"Puesto Garcia"},
    {"afip":"11993","location":"Puesto Gendarmeria Nacional Po"},
    {"afip":"11994","location":"Puesto Gentile"},
    {"afip":"11996","location":"Puesto Guamparito"},
    {"afip":"11999","location":"Puesto Horqueta"},
    {"afip":"12000","location":"Puesto Isla Chañar"}
    ,
    {"afip":"12001","location":"Puesto J Alvarez"},
    {"afip":"12002","location":"Puesto J Castro"},
    {"afip":"12003","location":"Puesto La Cachaca"},
    {"afip":"12004","location":"Puesto La Caldenada"},
    {"afip":"12006","location":"Puesto La Costa"},
    {"afip":"12007","location":"Puesto La Florida"},
    {"afip":"12008","location":"Puesto La Gruta"},
    {"afip":"12009","location":"Puesto La Hortensia"},
    {"afip":"12010","location":"Puesto La Invernada"},
    {"afip":"12011","location":"Puesto La Jarilla"},
    {"afip":"12012","location":"Puesto La Jerilla"},
    {"afip":"12013","location":"Puesto La Mojada"},
    {"afip":"12014","location":"Puesto La Negrita"},
    {"afip":"12015","location":"Puesto La Niebla"},
    {"afip":"12016","location":"Puesto La Porteña"}
    ,
    {"afip":"12018","location":"Puesto La Seña"}
    ,
    {"afip":"12019","location":"Puesto La Suiza"},
    {"afip":"12020","location":"Puesto La Tosca"},
    {"afip":"12021","location":"Puesto La Ventana"},
    {"afip":"12022","location":"Puesto Las Aguadas"},
    {"afip":"12023","location":"Puesto Las Cortaderas"},
    {"afip":"12024","location":"Puesto Las Higueras"},
    {"afip":"12025","location":"Puesto Las Juntitas"},
    {"afip":"12026","location":"Puesto Las Pichanas"},
    {"afip":"12027","location":"Puesto Las Puntanas"},
    {"afip":"12028","location":"Puesto Las Viboras"},
    {"afip":"12030","location":"Puesto Lorca"},
    {"afip":"12031","location":"Puesto Loreto"},
    {"afip":"12032","location":"Puesto Los Alojamientos"},
    {"afip":"12035","location":"Puesto Los Causes"},
    {"afip":"12036","location":"Puesto Los Chañares"}
    ,
    {"afip":"12038","location":"Puesto Los Gauchos"},
    {"afip":"12041","location":"Puesto Los Pajaritos"},
    {"afip":"12043","location":"Puesto Los Ramblones"},
    {"afip":"12045","location":"Puesto Luffi"},
    {"afip":"12046","location":"Puesto Luna"},
    {"afip":"12047","location":"Puesto Lunina"},
    {"afip":"12048","location":"Puesto Mallin"},
    {"afip":"12049","location":"Puesto Malo"},
    {"afip":"12050","location":"Puesto Manga De Arriba"},
    {"afip":"12051","location":"Puesto Manzanito"},
    {"afip":"12052","location":"Puesto Marfil"},
    {"afip":"12054","location":"Puesto Mironda"},
    {"afip":"12056","location":"Puesto Nieves Negras"},
    {"afip":"12057","location":"Puesto Nueras"},
    {"afip":"12059","location":"Puesto Ojo De Agua"},
    {"afip":"12061","location":"Puesto P Miranda"},
    {"afip":"12062","location":"Puesto P Montriel"},
    {"afip":"12064","location":"Puesto Punta Del Agua"},
    {"afip":"12066","location":"Puesto Quemado"},
    {"afip":"12067","location":"Puesto Quiroga"},
    {"afip":"12068","location":"Puesto Rancho De La Pampa"},
    {"afip":"12070","location":"Puesto Rincon De La Pampa"},
    {"afip":"12071","location":"Puesto Rincon Del Sauce"},
    {"afip":"12072","location":"Puesto Rincon Escalona"},
    {"afip":"12073","location":"Puesto Riquitipanche"},
    {"afip":"12075","location":"Puesto S Perez"},
    {"afip":"12078","location":"Puesto San Miguel"},
    {"afip":"12079","location":"Puesto San Vicente"},
    {"afip":"12080","location":"Puesto Santa Clara De Arriba"},
    {"afip":"12081","location":"Puesto Santa Maria"},
    {"afip":"12083","location":"Puesto Seco"},
    {"afip":"12084","location":"Puesto Sosa"},
    {"afip":"12087","location":"Puesto Ultima Aguada"},
    {"afip":"12089","location":"Puesto Vega"},
    {"afip":"12092","location":"Puesto Viuda De Estrella"},
    {"afip":"12093","location":"Puesto Vuelta Del Zanjon"},
    {"afip":"12094","location":"Puesto Zampal"},
    {"afip":"12132","location":"Punta De Vacas"},
    {"afip":"12136","location":"Punta Del Canal"},
    {"afip":"12170","location":"Puntos De Agua"},
    {"afip":"12262","location":"Quircaco"},
    {"afip":"12281","location":"R Barri"},
    {"afip":"12282","location":"R Bebedera"},
    {"afip":"12283","location":"R Bustos"},
    {"afip":"12303","location":"Rama Caida"},
    {"afip":"12312","location":"Ramblon De La Pampa"},
    {"afip":"12313","location":"Ramblon De Los Chilenos"},
    {"afip":"12314","location":"Ramblon Grande"},
    {"afip":"12322","location":"Ramon Donaire"},
    {"afip":"12323","location":"Ramon Gimenez"},
    {"afip":"12336","location":"Ranchitos"},
    {"afip":"12349","location":"Ranquil Norte"},
    {"afip":"12352","location":"Ranquilco Pozos Petroliferos"},
    {"afip":"12366","location":"Real Bayo"},
    {"afip":"12369","location":"Real De Moyano"},
    {"afip":"12371","location":"Real Del Colorado"},
    {"afip":"12372","location":"Real Del Leon"},
    {"afip":"12373","location":"Real Del Padre"},
    {"afip":"12374","location":"Real Del Pelambre"},
    {"afip":"12375","location":"Real Escondido"},
    {"afip":"12376","location":"Real Loma Blanca"},
    {"afip":"12377","location":"Real Piedra Horadada"},
    {"afip":"12378","location":"Real Primer Rio"},
    {"afip":"12379","location":"Real Rincon De Las Ovejas"},
    {"afip":"12386","location":"Recoaro"},
    {"afip":"12402","location":"Refugio La Faja"},
    {"afip":"12404","location":"Refugio Militar Gral Alvarado"},
    {"afip":"12405","location":"Refugio Vialidad"},
    {"afip":"12407","location":"Regino Ojeda"},
    {"afip":"12408","location":"Reina"},
    {"afip":"12433","location":"Resolana"},
    {"afip":"12434","location":"Resurreccion"},
    {"afip":"12438","location":"Retamo"},
    {"afip":"12439","location":"Retamo Partido"},
    {"afip":"12463","location":"Ricardo Videla"},
    {"afip":"12482","location":"Rincon De Correa"},
    {"afip":"12490","location":"Rincon De La Ramada Chato"},
    {"afip":"12515","location":"Rincon Del Atuel"},
    {"afip":"12528","location":"Rincon Escondido"},
    {"afip":"12532","location":"Rincon Huaiquerias"},
    {"afip":"12557","location":"Rio Barrancas"},
    {"afip":"12657","location":"Rodeo De La Cruz"},
    {"afip":"12663","location":"Rodeo Del Medio"},
    {"afip":"12674","location":"Rodolfo Iselin"},
    {"afip":"12675","location":"Rodriguez"},
    {"afip":"12676","location":"Rodriguez Peña"}
    ,
    {"afip":"12707","location":"Rosario Gatica"},
    {"afip":"12724","location":"Rufino Gomez"},
    {"afip":"12732","location":"Ruiz Huidobro"},
    {"afip":"12750","location":"Russell"},
    {"afip":"12783","location":"S Cortis"},
    {"afip":"12784","location":"S Estrella"},
    {"afip":"12844","location":"Salinas El Diamante"},
    {"afip":"12861","location":"Salto De Las Rosas"},
    {"afip":"12970","location":"San Francisco Del Monte"},
    {"afip":"13016","location":"San Jose De Guaymallen"},
    {"afip":"13033","location":"San Jose De Tupungato"},
    {"afip":"13119","location":"San Pedro De Atuel"},
    {"afip":"13163","location":"Sanchez De Bustamante"},
    {"afip":"13185","location":"Santa Blanca"},
    {"afip":"13233","location":"Santa Maria De Oro"},
    {"afip":"13292","location":"Santiago Romero"},
    {"afip":"13329","location":"Saturnino Romero"},
    {"afip":"13420","location":"Serviliano Ojeda"},
    {"afip":"13437","location":"Sierra Ansilta"},
    {"afip":"13450","location":"Sierra De Las Higueras"},
    {"afip":"13457","location":"Sierra Del Tontal"},
    {"afip":"13496","location":"Sixto Ledesma"},
    {"afip":"13504","location":"Soitue"},
    {"afip":"13537","location":"Sopanta"},
    {"afip":"13608","location":"T Orozco"},
    {"afip":"13612","location":"Tabanera"},
    {"afip":"13727","location":"Talquenca"},
    {"afip":"13736","location":"Tambito"},
    {"afip":"13752","location":"Tapera De Los Viejos"},
    {"afip":"13753","location":"Tapera Negra"},
    {"afip":"13758","location":"Tapon"},
    {"afip":"13815","location":"Teodoro Garro"},
    {"afip":"13816","location":"Teodoro Villaruel"},
    {"afip":"13817","location":"Teofila Acevedo"},
    {"afip":"13818","location":"Teofilo Ruben"},
    {"afip":"13819","location":"Teofilo Zapata"},
    {"afip":"13823","location":"Teresa B De Tittarelli"},
    {"afip":"13836","location":"Termas Villavicencio"},
    {"afip":"13849","location":"Tierras Blancas"},
    {"afip":"13854","location":"Tila"},
    {"afip":"13858","location":"Tilio Alcaraz"},
    {"afip":"13938","location":"Tomas Mercado"},
    {"afip":"13984","location":"Toscal Del Toro"},
    {"afip":"14019","location":"Travesia"},
    {"afip":"14032","location":"Tres Acequias"},
    {"afip":"14040","location":"Tres Banderas"},
    {"afip":"14079","location":"Tres Porteñas"}
    ,
    {"afip":"14100","location":"Trino Rosaleso"},
    {"afip":"14117","location":"Tropero Sosa"},
    {"afip":"14124","location":"Tte Benjamin Matienzo"},
    {"afip":"14145","location":"Tulumaya"},
    {"afip":"14158","location":"Tunuyan"},
    {"afip":"14160","location":"Tupungato"},
    {"afip":"14188","location":"Ugarteche"},
    {"afip":"14207","location":"Urisa"},
    {"afip":"14219","location":"Uspallata"},
    {"afip":"14227","location":"V Hipodromo"},
    {"afip":"14228","location":"V N De Cochiquita"},
    {"afip":"14255","location":"Valle De Las Leñas"}
    ,
    {"afip":"14257","location":"Valle De Uspallata"},
    {"afip":"14296","location":"Vega De Los Burros"},
    {"afip":"14297","location":"Vega De Praso"},
    {"afip":"14298","location":"Vega Ferraina"},
    {"afip":"14300","location":"Vegas De Las Ovejas"},
    {"afip":"14301","location":"Vegas De Los Corrales De Araya"},
    {"afip":"14327","location":"Vergel"},
    {"afip":"14343","location":"Vicente Muñoz"}
    ,
    {"afip":"14344","location":"Vicente Peletay"},
    {"afip":"14401","location":"Villa Antigua"},
    {"afip":"14410","location":"Villa Atuel"},
    {"afip":"14418","location":"Villa Bastias"},
    {"afip":"14453","location":"Villa Catala"},
    {"afip":"14471","location":"Villa Comparto"},
    {"afip":"14497","location":"Villa Del Carmen"},
    {"afip":"14569","location":"Villa Gaviola"},
    {"afip":"14602","location":"Villa Hipodromo"},
    {"afip":"14626","location":"Villa La Paz"},
    {"afip":"14657","location":"Villa Los Corralitos"},
    {"afip":"14698","location":"Villa Molino Orfila"},
    {"afip":"14715","location":"Villa Nueva De Guaymallen"},
    {"afip":"14743","location":"Villa Primavera"},
    {"afip":"14769","location":"Villa Rivadavia"},
    {"afip":"14826","location":"Villa Seca"},
    {"afip":"14827","location":"Villa Seca De Tunuyan"},
    {"afip":"14837","location":"Villa Suava"},
    {"afip":"14887","location":"Villas Unidas 25 De Mayo"},
    {"afip":"14888","location":"Villavicencio"},
    {"afip":"14917","location":"Vista Flores"},
    {"afip":"14919","location":"Vistalba"},
    {"afip":"14922","location":"Viuda De Orozco"},
    {"afip":"14923","location":"Viuda De Satelo"},
    {"afip":"14943","location":"Volcan Maipu"},
    {"afip":"14946","location":"Vra De Las Vacas"},
    {"afip":"14953","location":"Vuelta Del Zanjon"},
    {"afip":"15086","location":"Zanjon Amarillo"},
    {"afip":"15089","location":"Zanon Canal"},
    {"afip":"15127","location":"Ñacuñan"}
    ,
    {"afip":"15129","location":"Ñancuñan"}
    ,
    {"afip":"15147","location":"12 De Octubre"},
    {"afip":"15151","location":"25 De Mayo"},
    {"afip":"15161","location":"9 De Julio"},
    {"afip":"15185","location":"Agua Escondida"},
    {"afip":"15196","location":"Aguada"},
    {"afip":"15230","location":"Algarrobo"},
    {"afip":"15232","location":"Algarrobo Grande"},
    {"afip":"15247","location":"Alto Grande"},
    {"afip":"15251","location":"Alto Verde"},
    {"afip":"15262","location":"Ancon"},
    {"afip":"15283","location":"Arboleda"},
    {"afip":"15286","location":"Arenales"},
    {"afip":"15291","location":"Arroyito"},
    {"afip":"15337","location":"Balde Nuevo"},
    {"afip":"15346","location":"Barrancas"},
    {"afip":"15423","location":"Buena Vista"},
    {"afip":"15498","location":"Carrizal De Abajo"},
    {"afip":"15501","location":"Casa De Piedra"},
    {"afip":"15512","location":"Casas Viejas"},
    {"afip":"15516","location":"Casilla"},
    {"afip":"15541","location":"Cañada Seca"}
    ,
    {"afip":"15556","location":"Cerrillos"},
    {"afip":"15573","location":"Cerro Blanco"},
    {"afip":"15582","location":"Cerro Colorado"},
    {"afip":"15588","location":"Cerro De Los Leones"},
    {"afip":"15603","location":"Cerro San Lorenzo"},
    {"afip":"15638","location":"Chilecito"},
    {"afip":"15641","location":"Chivilcoy"},
    {"afip":"15659","location":"Colonia Alemana"},
    {"afip":"15667","location":"Colonia Cano"},
    {"afip":"15673","location":"Colonia Durazno"},
    {"afip":"15681","location":"Colonia Italiana"},
    {"afip":"15686","location":"Colonia La Llave"},
    {"afip":"15692","location":"Colonia Lopez"},
    {"afip":"15703","location":"Colonia Rusa"},
    {"afip":"15711","location":"Colonia San Francisco"},
    {"afip":"15716","location":"Colonia San Jorge"},
    {"afip":"15752","location":"Colonia Santa Teresa"},
    {"afip":"15775","location":"Coronel Dorrego"},
    {"afip":"15821","location":"Desaguadero"},
    {"afip":"15826","location":"Divisadero"},
    {"afip":"15846","location":"El 15"},
    {"afip":"15851","location":"El Aguila"},
    {"afip":"15859","location":"El Alambrado"},
    {"afip":"15862","location":"El Alamo"},
    {"afip":"15864","location":"El Algarrobal"},
    {"afip":"15867","location":"El Algarrobo"},
    {"afip":"15870","location":"El Altillo"},
    {"afip":"15873","location":"El Alto"},
    {"afip":"15878","location":"El Arbolito"},
    {"afip":"15932","location":"El Cajon"},
    {"afip":"15938","location":"El Campamento"},
    {"afip":"15946","location":"El Carmen"},
    {"afip":"15954","location":"El Carrizal"},
    {"afip":"15965","location":"El Ceibo"},
    {"afip":"15970","location":"El Cercado"},
    {"afip":"15975","location":"El Cerrito"},
    {"afip":"15984","location":"El Chalet"},
    {"afip":"15991","location":"El Chircal"},
    {"afip":"15995","location":"El Cienago"},
    {"afip":"16004","location":"El Colorado"},
    {"afip":"16008","location":"El Consuelo"},
    {"afip":"16030","location":"El Divisadero"},
    {"afip":"16043","location":"El Durazno"},
    {"afip":"16048","location":"El Escondido"},
    {"afip":"16072","location":"El Guanaco"},
    {"afip":"16086","location":"El Jume"},
    {"afip":"16099","location":"El Manzano"},
    {"afip":"16103","location":"El Marcado"},
    {"afip":"16116","location":"El Mirador"},
    {"afip":"16128","location":"El Molino"},
    {"afip":"16135","location":"El Mollar"},
    {"afip":"16147","location":"El Moyano"},
    {"afip":"16178","location":"El Paraiso"},
    {"afip":"16198","location":"El Plumerillo"},
    {"afip":"16209","location":"El Portillo"},
    {"afip":"16216","location":"El Porvenir"},
    {"afip":"16251","location":"El Puestito"},
    {"afip":"16262","location":"El Puma"},
    {"afip":"16288","location":"El Refugio"},
    {"afip":"16294","location":"El Resguardo"},
    {"afip":"16295","location":"El Retamo"},
    {"afip":"16301","location":"El Retiro"},
    {"afip":"16306","location":"El Rincon"},
    {"afip":"16324","location":"El Rosario"},
    {"afip":"16331","location":"El Salado"},
    {"afip":"16341","location":"El Salto"},
    {"afip":"16353","location":"El Sauce"},
    {"afip":"16417","location":"El Tropezon"},
    {"afip":"16423","location":"El Valle"},
    {"afip":"16434","location":"El Vergel"},
    {"afip":"16480","location":"Estancia Bella Vista"},
    {"afip":"16535","location":"General Acha"},
    {"afip":"16538","location":"General Alvear"},
    {"afip":"16590","location":"Isla Chica"},
    {"afip":"16593","location":"Isla Grande"},
    {"afip":"16657","location":"La Argentina"},
    {"afip":"16679","location":"La Bajada"},
    {"afip":"16729","location":"La Carrera"},
    {"afip":"16734","location":"La Cautiva"},
    {"afip":"16740","location":"La Cañada"}
    ,
    {"afip":"16748","location":"La Celia"},
    {"afip":"16756","location":"La Chilca"},
    {"afip":"16778","location":"La Clarita"},
    {"afip":"16789","location":"La Colonia"},
    {"afip":"16804","location":"La Consulta"},
    {"afip":"16807","location":"La Corona"},
    {"afip":"16808","location":"La Cortadera"},
    {"afip":"16814","location":"La Costa"},
    {"afip":"16848","location":"La Dormida"},
    {"afip":"16878","location":"La Esperanza"},
    {"afip":"16887","location":"La Esquina"},
    {"afip":"16892","location":"La Estacada"},
    {"afip":"16895","location":"La Estancia"},
    {"afip":"16919","location":"La Favorita"},
    {"afip":"16939","location":"La Florida"},
    {"afip":"16947","location":"La Fortuna"},
    {"afip":"16984","location":"La Horqueta"},
    {"afip":"17010","location":"La Isla"},
    {"afip":"17018","location":"La Jaula"},
    {"afip":"17026","location":"La Junta"},
    {"afip":"17038","location":"La Lata"},
    {"afip":"17045","location":"La Libertad"},
    {"afip":"17049","location":"La Llave"},
    {"afip":"17107","location":"La Mora"},
    {"afip":"17132","location":"La Palmera"},
    {"afip":"17153","location":"La Paz"},
    {"afip":"17178","location":"La Pomona"},
    {"afip":"17184","location":"La Porteña"}
    ,
    {"afip":"17200","location":"La Primavera"},
    {"afip":"17222","location":"La Quebrada"},
    {"afip":"17292","location":"La Sirena"},
    {"afip":"17316","location":"La Toma"},
    {"afip":"17321","location":"La Tosca"},
    {"afip":"17348","location":"La Verde"},
    {"afip":"17365","location":"La Yesera"},
    {"afip":"17401","location":"Lagunita"},
    {"afip":"17436","location":"Las Canteras"},
    {"afip":"17460","location":"Las Chacras"},
    {"afip":"17466","location":"Las Chacritas"},
    {"afip":"17481","location":"Las Cortaderas"},
    {"afip":"17490","location":"Las Cuevas"},
    {"afip":"17498","location":"Las Delicias"},
    {"afip":"17520","location":"Las Heras"},
    {"afip":"17541","location":"Las Juntas"},
    {"afip":"17569","location":"Las Malvinas"},
    {"afip":"17587","location":"Las Minas"},
    {"afip":"17625","location":"Las Peñas"}
    ,
    {"afip":"17650","location":"Las Rosas"},
    {"afip":"17680","location":"Las Totoritas"},
    {"afip":"17697","location":"Las Violetas"},
    {"afip":"17714","location":"Libertador Gral San Martin"},
    {"afip":"17718","location":"Loma Alta"},
    {"afip":"17725","location":"Loma Del Medio"},
    {"afip":"17735","location":"Loma Negra"},
    {"afip":"17739","location":"Loma Pelada"},
    {"afip":"17743","location":"Loma Sola"},
    {"afip":"17746","location":"Lomas Blancas"},
    {"afip":"17760","location":"Los Alamos"},
    {"afip":"17769","location":"Los Algarrobos"},
    {"afip":"17776","location":"Los Angeles"},
    {"afip":"17779","location":"Los Arboles"},
    {"afip":"17785","location":"Los Baldes"},
    {"afip":"17788","location":"Los Barriales"},
    {"afip":"17791","location":"Los Blancos"},
    {"afip":"17826","location":"Los Claveles"},
    {"afip":"17829","location":"Los Colorados"},
    {"afip":"17836","location":"Los Corrales"},
    {"afip":"17839","location":"Los Corralitos"},
    {"afip":"17847","location":"Los Eucaliptos"},
    {"afip":"17887","location":"Los Molles"},
    {"afip":"17907","location":"Los Olmos"},
    {"afip":"17917","location":"Los Patos"},
    {"afip":"17935","location":"Los Pozos"},
    {"afip":"17951","location":"Los Reyunos"},
    {"afip":"17957","location":"Los Sauces"},
    {"afip":"17985","location":"Maipu"},
    {"afip":"18022","location":"Maza"},
    {"afip":"18033","location":"Media Luna"},
    {"afip":"18073","location":"Monte Caseros"},
    {"afip":"18109","location":"Navarro"},
    {"afip":"18134","location":"Ojo De Agua"},
    {"afip":"18150","location":"Ovejeria"},
    {"afip":"18153","location":"Pachango"},
    {"afip":"18163","location":"Palmira"},
    {"afip":"18260","location":"Piedras Blancas"},
    {"afip":"18345","location":"Primavera"},
    {"afip":"18349","location":"Progreso"},
    {"afip":"18399","location":"Puesto San Jose"},
    {"afip":"18413","location":"Punta Del Agua"},
    {"afip":"18454","location":"Ramblon"},
    {"afip":"18467","location":"Reduccion"},
    {"afip":"18476","location":"Reyes"},
    {"afip":"18482","location":"Rincon Chico"},
    {"afip":"18493","location":"Rio Blanco"},
    {"afip":"18498","location":"Rio Chico"},
    {"afip":"18508","location":"Rio Grande"},
    {"afip":"18520","location":"Rivadavia"},
    {"afip":"18525","location":"Rivas"},
    {"afip":"18526","location":"Roberts"},
    {"afip":"18533","location":"Rodeo Grande"},
    {"afip":"18570","location":"San Alberto"},
    {"afip":"18593","location":"San Antonio"},
    {"afip":"18620","location":"San Carlos"},
    {"afip":"18699","location":"San Ignacio"},
    {"afip":"18751","location":"San Jose"},
    {"afip":"18832","location":"San Miguel"},
    {"afip":"18846","location":"San Pablo"},
    {"afip":"18860","location":"San Pedro"},
    {"afip":"18875","location":"San Rafael"},
    {"afip":"18893","location":"San Roque"},
    {"afip":"18935","location":"Santa Ana"},
    {"afip":"18962","location":"Santa Clara"},
    {"afip":"18977","location":"Santa Elena"},
    {"afip":"19025","location":"Santa Maria"},
    {"afip":"19033","location":"Santa Marta"},
    {"afip":"19053","location":"Santa Rosa"},
    {"afip":"19064","location":"Santa Teresa"},
    {"afip":"19080","location":"Santo Domingo"},
    {"afip":"19104","location":"Sarmiento"},
    {"afip":"19203","location":"Totoral"},
    {"afip":"19231","location":"Tres Esquinas"},
    {"afip":"19272","location":"Valle Hermoso"},
    {"afip":"19289","location":"Villa Centenario"},
    {"afip":"19325","location":"Villa San Isidro"},
    {"afip":"19339","location":"Villa Vieja"},
    {"afip":"19367","location":"Zapata"},
    {"afip":"20755","location":"El Algarrobo"},
    {"afip":"20756","location":"El Colorado"},
    {"afip":"20757","location":"El Divisadero"},
    {"afip":"20758","location":"El Guanaco"},
    {"afip":"20759","location":"El Plumero"},
    {"afip":"20760","location":"El Porvenir"},
    {"afip":"20761","location":"El Retiro"},
    {"afip":"20762","location":"El Zampal"},
    {"afip":"20763","location":"Huaicos De Rufino"},
    {"afip":"20764","location":"La Argentina"},
    {"afip":"20765","location":"La Bandera"},
    {"afip":"20766","location":"La Florida"},
    {"afip":"20767","location":"La Porteña"}
    ,
    {"afip":"20768","location":"Los Verdes"},
    {"afip":"20769","location":"San Pedro"},
    {"afip":"20770","location":"La Primavera"},
    {"afip":"20771","location":"Las Rosas"},
    {"afip":"20772","location":"Las Torrecitas"},
    {"afip":"20773","location":"Los Sauces"},
    {"afip":"20774","location":"Puesto Santa Maria"},
    {"afip":"20775","location":"El Zampal"},
    {"afip":"20778","location":"El Zampal"},
    {"afip":"20779","location":"La Cortadera"},
    {"afip":"20780","location":"Las Vegas"},
    {"afip":"20781","location":"Los Barriales"},
    {"afip":"20782","location":"Los Ramblones"},
    {"afip":"20783","location":"El Retiro"},
    {"afip":"20784","location":"Los Campamentos"},
    {"afip":"20785","location":"El Lechucito"},
    {"afip":"20786","location":"La Cañada"}
    ,
    {"afip":"20787","location":"La Florida"},
    {"afip":"20788","location":"La Jaula"},
    {"afip":"20789","location":"Las Violetas"},
    {"afip":"20790","location":"Los Alamos"},
    {"afip":"20791","location":"Alto Verde"},
    {"afip":"20792","location":"La Verde"},
    {"afip":"20793","location":"Las Colonias"},
    {"afip":"20794","location":"Los Tordillos"},
    {"afip":"20795","location":"La Jaula"},
    {"afip":"20796","location":"La Pichana"},
    {"afip":"20797","location":"Retamo"},
    {"afip":"20798","location":"Alto Grande"},
    {"afip":"20799","location":"Cerro Nevado"},
    {"afip":"20800","location":"La Cortadera"},
    {"afip":"20801","location":"Los Tamarindos"},
    {"afip":"20802","location":"Ramblon"},
    {"afip":"20803","location":"Santa Elena"},
    {"afip":"20804","location":"El Retiro"},
    {"afip":"20805","location":"La Fortuna"},
    {"afip":"20806","location":"Las Cruces"},
    {"afip":"20807","location":"San Pedro"},
    {"afip":"20808","location":"San Ignacio"},
    {"afip":"20809","location":"El Altillo"},
    {"afip":"20810","location":"La Jaula"},
    {"afip":"20811","location":"El Puestito"},
    {"afip":"20843","location":"La Isla"},
    {"afip":"20844","location":"La Primavera"},
    {"afip":"21681","location":"Chilote"},
    {"afip":"22090","location":"Carrodilla"},
    {"afip":"22202","location":"Palmitas"},
    {"afip":"22268","location":"Carrodilla  -  (Dep 631)"}
    ]