<div class="card" [ngClass]="{'price': identificatorString == 'price' || identificatorString=='tons',
 'card-short': identificatorString=='reduction', 'waypay': identificatorString=='wayPay' }">
    <div class="title-and-selects row-between">
        <span class="title">{{title}}</span>

        <div class="selects" *ngIf="!isComercial && identificatorString=='statusOrder' && !onlyFilter">
            <span class="option edit" [ngClass]="{'active': !isFilter}" (click)="changeTo('edit')">
                Editar <edit-svg class="logo" [active]="!isFilter"></edit-svg>
            </span>
            <span class="option filter" [ngClass]="{'active': isFilter}" (click)="changeTo('filter')">
                Filtrar <filter-svg class="logo" [active]="isFilter"></filter-svg>
            </span>
        </div>
    </div>

    <div *ngIf="isSearchOrNumber && array.length>0 && identificatorString!='codeS' && identificatorStringAux!='buyer'" class="body-card">
        <div class="select-all" *ngIf="isFilter">
            <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span></div>
        <!-- Array with filter-->

        <input style="margin-top:4px" class="input input-search" type="text" placeholder="Buscar" [(ngModel)]="search" value="{{search}}">
        <ul class="list">
            <li class="row-between" *ngFor="let element of array | filterOptions:search; index as i" (click)="addOrDeleteElement(element)">
                <span>{{element.name? element.name: element}}</span>
                <input class="form-check-input" type="{{getTypeForm()}}" [ngClass]="{'filter': isFilter, 'edit': !isFilter}"  [checked]="selects.indexOf(element) != -1"
                    value="{{element}}">
            </li>
        </ul>
    </div>

    <div *ngIf="!isSearchOrNumber && array.length>0" class="body-card">
        <!-- Only array (few elements)-->
        <div class="select-all" *ngIf="isFilter">
            <span class="selects"><span class="all" (click)="selectAll()">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span></span></div>

        <ul class="list">
            <li class="row-between" *ngFor="let element of array; index as i" (click)="addOrDeleteElement(element)">
                <span>{{element.name? element.name: element}}</span>
                <input class="form-check-input" type="{{getTypeForm()}}" value="{{element.name? element.name: element}}" [ngClass]="{'filter': isFilter, 'edit': !isFilter}"
                    [checked]="selects.indexOf(element.name? element.name: element) != -1">
            </li>
        </ul>
    </div>

    <div *ngIf="identificatorStringAux=='buyer'"  class="body-card">
        <div class="select-all" *ngIf="isFilter">
            <span class="selects">
                <!-- No se puede seleccionar todo a la vez porque es en vano. -->
                <span class="all">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span>
            </span>
        </div>
        <!-- Array with filter-->
        <input style="margin-top:4px" class="input input-search" type="text" placeholder="Buscar" [(ngModel)]="search" value="{{search}}">
        <cdk-virtual-scroll-viewport itemSize="32" class="cdk list">
            <!--<ul class="list">-->
            <li class="row-between" *ngFor="let element of array | filterOptions:search:'buyer'; index as i" (click)="addOrDeleteElement(element[0])">
                <span>{{element[1]}} - {{element[0]}}</span>
                <input class="form-check-input" type="{{getTypeForm()}}" [ngClass]="{'filter': isFilter, 'edit': !isFilter}"  [checked]="selects.indexOf(element[0]) != -1"
                    value="{{element[0]}}">
            </li>
            <!--</ul>-->
        </cdk-virtual-scroll-viewport>
    </div>

    <div *ngIf="identificatorString=='codeS'"  class="body-card">
        <div class="select-all" *ngIf="isFilter">
            <span class="selects">
                <!-- No se puede seleccionar todo a la vez porque es en vano. -->
                <span class="all">Seleccionar todo</span> - <span class="supr" (click)="deleteAll()">Borrar</span>
            </span>
        </div>
        <!-- Array with filter-->
        <input style="margin-top:4px" class="input input-search" type="text" placeholder="Buscar" [(ngModel)]="search" value="{{search}}">
        <cdk-virtual-scroll-viewport itemSize="32" class="cdk list">
            <!--<ul class="list">-->
            <li class="row-between" *cdkVirtualFor="let element of array | filterOptions:search:'codeS'; index as i" (click)="addOrDeleteElement(element[0])">
                <span>{{element[1].nombre}} - {{element[0]}}</span>
                <input class="form-check-input" type="{{getTypeForm()}}" [ngClass]="{'filter': isFilter, 'edit': !isFilter}"  [checked]="selects.indexOf(element[0]) != -1"
                    value="{{element[0]}}">
            </li>
            <!--</ul>-->
        </cdk-virtual-scroll-viewport>
    </div>

    <div *ngIf="identificatorString=='price'" class="body-card">
        <p class="text">Ingresa el rango de precios que deseas aplicar</p>
        <div class="radios-buttons">
            <mat-radio-group></mat-radio-group>
            <mat-radio-button class="radio-button" value="ARS"
                (change)="setPrice($event, 'typeCoin')">Pesos
            </mat-radio-button>
            <mat-radio-button class="radio-button" value="USD" (change)="setPrice($event, 'typeCoin')">Dólares
            </mat-radio-button>
            <mat-radio-button *ngIf="isFilter" [checked]="true" class="radio-button" value="ALL"
                (change)="setPrice($event, 'typeCoin')">Ámbos
            </mat-radio-button>
        </div>

        <div class="prices" *ngIf="isFilter">
            <input class="input input-number" (change)="setPrice($event, 'start')" type="number"
                placeholder="Desde" />
            <input class="input input-number" (change)="setPrice($event, 'end')" type="number"
                placeholder="Hasta" />
        </div>
    </div>

    <div *ngIf="identificatorString=='tons'" class="body-card">
        <p class="text">Ingresa el rango de toneladas que deseas aplicar</p>
        
        <div class="prices" *ngIf="isFilter else commodities">
            <input class="input input-number" (change)="setTons($event, 'start')" type="number"
                placeholder="Desde" />
            <input class="input input-number" (change)="setTons($event, 'end')" type="number"
                placeholder="Hasta" />
        </div>
        <ng-template #commodities class="prices">
            <input class="input" (change)="setTons($event, 'value')" type="number"
                placeholder="Ingrese una cantidad" />
        </ng-template>
    </div>

    <div *ngIf="identificatorString== 'deliveryPeriod'" class="body-card">
        <span class="delete-date" (click)="deleteDateRangeSelected()">Borrar fecha seleccionada</span>
        <div class="width-100">
            <app-date-calendar-inline [datesRangesStruct]="{startDate: null, endDate:null}" 
            (valueOut)="setValueToSetDate($event)" [isRange]="true"></app-date-calendar-inline>
        </div>
    </div>
    <div *ngIf="identificatorString=='hour'" class="body-card">
        <p class="text">Buscar la hora de realización </p>
        <input class="input" (change)="setValue($event)" type="number" placeholder="Ingresa hora" />
    </div>

    <button (click)="applyChanges()"  [ngClass]="{'disabled': getIsDisabledButton()}" [disabled]="getIsDisabledButton()">Aplicar</button>

</div>