import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { loadDolarPrice } from 'src/app/redux/actions/dashboard.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Dolar } from 'src/app/shared/interfaces/dashboard.interface';

@Component({
  selector: 'app-dolar',
  templateUrl: './dolar.component.html',
  styleUrls: ['./dolar.component.scss', './../../../../shared/styles/empty-state.scss']
})
export class DolarComponent implements OnInit, OnDestroy {
  dolar!: Dolar;
  fecha: string='';

  private dashboardSubscription!:Subscription;
  private optionsSubscription!:Subscription;

  constructor(private store: Store<appState>) {
  }

  ngOnInit(): void {
    this.dashboardSubscription=this.store.select('dashboard').subscribe(dashboardReducer => {
      this.dolar=dashboardReducer.dolar;
    })

    this.optionsSubscription=this.store.select('options').subscribe(optionsReducer => {
      this.fecha=optionsReducer.date;
    })

    const monthsToLoad = this.monthsToLoad();
    //this.loadDolarPrice(monthsToLoad);
  }

  ngOnDestroy(): void {
    try{
      this.dashboardSubscription.unsubscribe();
      this.optionsSubscription.unsubscribe();
    } catch(e){
    }
  }

  loadDolarPrice(monthsToLoad: string[]) {
    monthsToLoad.forEach((month: any) => {
      this.store.dispatch(loadDolarPrice({ date: month }));
    })
  }

  monthsToLoad() {
    const date = new Date();
    const monthActually = date.getMonth();

    let rofexMonths = this.monthsCalculator(monthActually, monthActually + 6, date.getFullYear().toString().substring(2, 4));
    let meses = rofexMonths.months;
    rofexMonths = this.monthsCalculator(0, rofexMonths.nextYearMonthCounter, (date.getFullYear() + 1).toString().substring(2, 4));
    meses = [...meses, ...rofexMonths.months];

    return meses;
  }

  monthsCalculator(initial: number, end: number, year: string) {
    const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    let nextYearMonthCounter = 0;
    let monthsSelecteds = [];
    for (let i = initial; i < end; i++) {
      if (months[i]) monthsSelecteds.push("DLR/" + months[i] + year);
      else nextYearMonthCounter++;
    }
    return {
      months: monthsSelecteds,
      nextYearMonthCounter: nextYearMonthCounter
    }
  }

  getFecha(){
    const fechaSplit=this.fecha.split('-');
    return fechaSplit[2]+'/'+fechaSplit[1]+'/'+fechaSplit[0];
  }
}
