<div class="body">
    <!-- HEADER -->
    <app-header class="header"></app-header>
    <!-- HEADER IN MOBILE -->
    <app-header-in-section section="Panel de control"></app-header-in-section>
    
    <!-- TITLE IN HEADER -->
    <h2 class="title">Panel de control</h2>

    <!-- CONTENT OF CONTROL PANEL -->
    <app-body-control-panel></app-body-control-panel>

    <app-menu class="menu"></app-menu>

    <app-menu-footer></app-menu-footer>
</div>