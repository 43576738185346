import { Component, Output,EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { DateChecker } from 'src/app/shared/date.checker';
import { OrderValidator } from 'src/app/shared/order';

@Component({
  selector: 'dialog-confirmed-exchanged',
  templateUrl: './exchanged-confirmed.html',
  styleUrls: ['./exchanged-confirmed.scss'],
})
export class DialogConfirmedExchanged {
  hour: string = '';
  title: string = 'La contrapartida fue actualizada con éxito';
  ordersSelected!: Order[]; //Store of redux;
  orders!: Order[]; //Store of redux;
  @Output() close = new EventEmitter<boolean>();
  constructor(private store: Store<appState>, private date: DateChecker, private orderV: OrderValidator) {
    this.hour = this.date.setHourFormat();
  }
  
  closeDialog() {
    this.close.emit(true);
  }
}