interface CommisionIBM {
  tipoCom: string,
  comprador: number,
  vendedor: number,
  product: number,
  placeOfDelivery: number,
  unidadDeNegocio: string,
  percentage: number
}

export function updateCommisionIBM(data: CommisionIBM[]) {
  COMMISION_IBM = data;
}

export let COMMISION_IBM:CommisionIBM[]= [
  {
    "tipoCom": "C",
    "comprador": 0,
    "vendedor": 3420,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 1.25
  },
  {
    "tipoCom": "C",
    "comprador": 136,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 1
  },
  {
    "tipoCom": "C",
    "comprador": 175,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.05
  },
  {
    "tipoCom": "C",
    "comprador": 700,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 1
  },
  {
    "tipoCom": "C",
    "comprador": 959,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.25
  },
  {
    "tipoCom": "C",
    "comprador": 976,
    "vendedor": 102,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "C",
    "comprador": 979,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 1,
    "placeOfDelivery": 366,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 1,
    "placeOfDelivery": 858,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 2,
    "placeOfDelivery": 366,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 2,
    "placeOfDelivery": 858,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 3,
    "placeOfDelivery": 366,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 3,
    "placeOfDelivery": 858,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "AC",
    "percentage": 1
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 135,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1040,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1140,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1141,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 1146,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2460,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2559,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 2986,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3420,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3750,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 3770,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4706,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4846,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4895,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 5589,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 31270,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 72430,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 3,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 4,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 5,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 9,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 78280,
    "product": 20,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 89,
    "vendedor": 5568,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.5
  },
  {
    "tipoCom": "V",
    "comprador": 136,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.25
  },
  {
    "tipoCom": "V",
    "comprador": 136,
    "vendedor": 349,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 136,
    "vendedor": 2249,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 136,
    "vendedor": 3750,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 136,
    "vendedor": 4125,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 136,
    "vendedor": 40400,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 700,
    "vendedor": 0,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.25
  },
  {
    "tipoCom": "V",
    "comprador": 700,
    "vendedor": 349,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 700,
    "vendedor": 2249,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 700,
    "vendedor": 3750,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 700,
    "vendedor": 4125,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 700,
    "vendedor": 40400,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 959,
    "vendedor": 4080,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.5
  },
  {
    "tipoCom": "V",
    "comprador": 976,
    "vendedor": 102,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 1
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 5,
    "placeOfDelivery": 310,
    "unidadDeNegocio": "AC",
    "percentage": 2
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 1,
    "placeOfDelivery": 310,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 2,
    "placeOfDelivery": 310,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 3,
    "placeOfDelivery": 310,
    "unidadDeNegocio": "AC",
    "percentage": 2.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 5,
    "placeOfDelivery": 858,
    "unidadDeNegocio": "AC",
    "percentage": 2
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 5,
    "placeOfDelivery": 366,
    "unidadDeNegocio": "AC",
    "percentage": 2
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 1,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "AC",
    "percentage": 1.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 0,
    "product": 2,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "AC",
    "percentage": 1.5
  },
  {
    "tipoCom": "V",
    "comprador": 0,
    "vendedor": 4486,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  },
  {
    "tipoCom": "V",
    "comprador": 781,
    "vendedor": 5755,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0.5
  },
  {
    "tipoCom": "C",
    "comprador": 781,
    "vendedor": 5755,
    "product": 0,
    "placeOfDelivery": 0,
    "unidadDeNegocio": "",
    "percentage": 0
  }
]