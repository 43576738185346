import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularitiesValidator } from 'src/app/shared/business-particularities';
import { BONIFICATION_VOID } from 'src/app/shared/const/business-particularities';
import { DateChecker, isInvalidStartDate, parseDate } from 'src/app/shared/date.checker';
import { Bonification } from 'src/app/shared/interfaces/business-particularities';
import { ValidatorChecker } from 'src/app/shared/validator.checker';
import { BusinessParticularityOption } from '../options.component';
import { PLANTAS_INTERIOR_MAP } from 'src/app/shared/dict/plantas-interior';
import { getPortIBMStr } from 'src/app/shared/price';

@Component({
  selector: 'app-bonificacion',
  templateUrl: './bonificacion.component.html',
  styleUrls: ['./../options.component.scss', './../../../fields.component.scss']
})
export class BonificacionComponent extends BusinessParticularityOption implements OnChanges {
  identificator: number = 0;
  public bonification:Bonification=BONIFICATION_VOID;
  PLANTAS_INTERIOR_MAP:any=PLANTAS_INTERIOR_MAP;
  array:any[]=[];

  constructor(private validator:ValidatorChecker, private date:DateChecker, private bussPartV:BusinessParticularitiesValidator) {
    super();
    this.array=Object.keys(PLANTAS_INTERIOR_MAP);
    this.array.shift();
  }

  ngOnChanges(): void {
    try{
      this.bonification=this.businessParticularitiesStruct.bonification;
      this.identificator=this.businessParticularitiesStruct.bonification.typeBonification;
    } catch(err){
      this.bonification=BONIFICATION_VOID;
      this.identificator=0;
    }
  }

  setBonification(bonification:Bonification){
    this.bonification=bonification;
    let newBP= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.bonification=this.bonification;
    this.emit2(newBP);
  }

  verifyBonification(bonification:any){
    if(JSON.stringify(bonification) != JSON.stringify(BONIFICATION_VOID)){
      this.setBonification(BONIFICATION_VOID); //Reset bonification
    }
  }

  setPlaceOfDelivery(event:any){
    let bonification=JSON.parse(JSON.stringify(this.bonification));
    bonification.placeOfDelivery=event.element;
    this.setBonification(bonification);
  }

  getPlaceOfDelivery(){
    return getPortIBMStr(this.bonification.placeOfDelivery)
  }

  isInvalidPlaceOfDelivery(){
    return (this.bonification?.placeOfDelivery ?? 0) <= 0
  }

  isInvalidDate(startDate:string){
    return startDate=='--';
  }

  setTypeBonification(typeBonification:number){
    let bonificacion:Bonification=JSON.parse(JSON.stringify(this.bonification));
    bonificacion.typeBonification=typeBonification;
    
    //Borramos lugar de entrega
    bonificacion.placeOfDelivery = BONIFICATION_VOID.placeOfDelivery;
    //Borramos fechas
    bonificacion.startDate = BONIFICATION_VOID.startDate;
    bonificacion.endDate = BONIFICATION_VOID.endDate;

    this.identificator=typeBonification;
    this.setBonification(bonificacion);
  }

  setDate(event:string, tag:string){
    let bonification:Bonification=JSON.parse(JSON.stringify(this.bonification));
    if(tag=='startDate'){
      bonification.startDate=event
    } else if (tag=='endDate'){
      bonification.endDate=event
    }
    this.setBonification(bonification);
  }

  setMount(event:any){
    let bonification:Bonification=JSON.parse(JSON.stringify(this.bonification));
    bonification.amount.quantity=this.validator.validatorNumber(event);
    this.setBonification(bonification);
  }

  setTypeCoin(typeCoin:string){
    let bonification:Bonification=JSON.parse(JSON.stringify(this.bonification));
    bonification.amount.typeCoin=typeCoin;
    this.setBonification(bonification);
  }

  isShowMessage(){
    return (!this.bonification.amount.typeCoin || this.bonification.amount.typeCoin == '--') &&
    this.bonification.amount.quantity>0;
  }

  getIsBonification(){
    return this.bussPartV.getIsBonification(this.businessParticularitiesStruct)
  }

  getOptions(){
    return [
      {value:'USD', name:'Dólares'},
      {value:'ARS', name:'Pesos'},
    ]
  }
}