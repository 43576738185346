import { Component, OnInit } from '@angular/core';
import { Field } from '../fields.component';
import { Store } from '@ngrx/store';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { priceTags } from 'src/app/shared/const/prices.const';
import { orderTags } from 'src/app/shared/const/orders';

@Component({
  selector: 'app-nro-billing',
  templateUrl: './nro-billing.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class NroBillingComponent extends Field implements OnInit{

  constructor(store:Store<appState>){
    super(store);
  }

  ngOnInit(): void {
    this.tag = orderTags.price;
    this.subTag = priceTags.businessParticularities;
    this.thirdTag= priceTags.nroBilling;
    this.setIdentifyClassName();
  }

  override setElement(element:any){
    this.setElementToGenerate(element);
  }

  override rememberValueInForm(){
    this.rememberField();
  }

  rememberField(){
    try{
      let valueToSet: any = this.rememberValue();
      
      if (this.elementToGenerate && valueToSet.length>0) {
        this.elementSelected = valueToSet;
        this.search = this.elementSelected;
        this.setIsOptionSelected(true) 
      } else if( valueToSet < 0){
        this.elementSelected = '';
        this.search = '';
        this.indexOption = -1;
        this.setIsOptionSelected(false)
      }
    } catch(err){}
  }
}
