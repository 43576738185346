
<div class="dialog-content" mat-dialog-content>
  <div class="logo-container">
    <!--    <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_eni7kar8.json" background="transparent"  speed="1"  style="width: 38px; height: 38px;" loop controls autoplay></lottie-player> -->
  </div>

  <span class="hour">{{hour}}</span>
  <p class="title">{{title}}</p>
  <textarea (change)="setMessage($event)" [value]="price.observations.message==' '? '': price.observations.message" class="text-area" cols="40" rows="5" placeholder="Escribe una observación"></textarea>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close class="button filling" (click)="sendObservation()">Enviar</button>
</div>

