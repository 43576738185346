import { Injectable } from '@angular/core';
import { typeCoins } from './const/prices.const';
import { WAY_PAY_VOID } from './const/wayPay';
import { WAY_PAY_OV_IBM } from './dict/ibm/way-pay-ibm';
import { Price, WayPay } from './interfaces/price.interface';
import { dolarsWithDeposit, dolarsWithDischarge, dolarsWithForward, dolarsWithOutDischarge, pesosWithDeposit, pesosWithDischarge, pesosWithForward, pesosWithOutDischarge, wayPay, wayPayNames } from './dict/wayToPay';
import { dateByBusinessDays, getDate, getDateEndOfMonthBusinessDay, getDaysBetweenDatesByBusinessDay } from './date.checker';
import { businessDict } from './dict/typeBusiness';

@Injectable({
    providedIn: 'root',
})

export class WayPayValidator {

  constructor(){
  }

  getIBMCode(wayPay:WayPay, typeCoin:string):number {
    return getWayPayCodeIBM(wayPay, typeCoin);
  }

  /**
   * Returns if the payment method of the condition is cash on delivery
   * @param price 
   * @returns boolean
   */
  getIsContraEntrega(price: Price) {
    return price?.wayPay?.wayPayName == wayPayNames.contraEntrega;
  }

  /**
   * Returns if the payment method of the condition is fixed date
   * @param price 
   * @returns boolean
   */
  getIsFechaFija(price:Price){
    return price?.wayPay?.wayPayName  == wayPayNames.fechaFija;
  }

  /**
   * Returns if the payment method of the condition is Con carta de garantía
   * @param price 
   * @returns boolean
   */
  getIsConCartaDeGarantia(price:Price){
    return price?.wayPay?.wayPayName  == wayPayNames.conCartaGarantia;
  }
}

/**
 * Given the structure of the payment method, it returns what it means in human-understandable text format. 
 * @param wayPayParam 
 * @returns string
 */
export function getWayPayText(wayPayParam:WayPay):string{
  if (!wayPayParam || wayPayParam.wayPayName <= 0) {
    return '--';
  }

  const WAY_PAY: any = wayPay;
  let stringToReturn = WAY_PAY[wayPayParam.wayPayName] || '';

  if (wayPayParam.wayPayName == wayPayNames.conCartaGarantia) {
    stringToReturn += wayPayParam.options == 1 ? ' y certificado de depósito' 
      : wayPayParam.options == 2 ? ' y warrant' : '';
  }

  stringToReturn += `\n${getDaysWayPayText(wayPayParam)} - ${getPercentageWayPay(wayPayParam)}`;
  return stringToReturn;
}

/**
 * Given the structure of the payment method, 
 * it returns in human-understandable text format the days it has and whether they are business days or calendar days.
 * @param wayPayParam 
 * @returns string 
 */
export function getDaysWayPayText(wayPayParam:WayPay):string{
  const days=wayPayParam.days;
  const isBusinessDay=wayPayParam.isBusinessDays==1; //Get if is business days or not
  return days + (days == 1 ? ' día' : ' días') + (isBusinessDay? ' hábiles': ' corridos');
}

/**
 * Given the structure of the payment method, it returns the percentage it has and its symbol in human-understandable text format.
 * @param wayPayParam 
 * @returns string
 */
export function getPercentageWayPay(wayPayParam:WayPay):string{
  return wayPayParam.percentage + '%';
}

/**
 * Given a price and a payment method code (wayPayName), perform a validation located at 
 * https://docs.google.com/spreadsheets/d/1o1lC9c9VIid-eXxInv8XYS8H9TYRbXzY76nmqI9P7fk/edit#gid=1213801806 
 * and the function returns a WayPay structure with the values set by Default for that price and that form of payment
 * @param price 
 * @param wayPayName 
 * @returns WayPay 
 */
export function getWayPayDefault(price:Price, wayPayName:number):WayPay{
  try{
    const typeCoin=price.typeCoin;
    const typeBusiness=price.typeBusiness;
    const deliveryPeriod=price.deliveryPeriod;
    let days = 5;
    let newExpiration='--';
    
    if (wayPayName == wayPayNames.contraEntrega) {
      //Cuando se entrega la mercadería.
      days = 3;
      newExpiration='--';//Reseteamos la expiración

    } else if (wayPayName == wayPayNames.conCartaGarantia) {
      if (typeCoin == typeCoins.USD) {
        //Por default 5 días hábiles de la pesificación, no sabemos cuando es la pesificacion, entonces no hay fecha
      } else if (typeCoin == typeCoins.ARS) {
        //Por default 5 días hábiles o corridos del día posterior o sino XX días de selección
        newExpiration = dateByBusinessDays(getDate(), days, true);
      }
    } else if (wayPayName == wayPayNames.fechaFija) {
      if (typeBusiness == businessDict.conDescarga || typeBusiness == businessDict.depositos) {
        newExpiration = dateByBusinessDays(getDate(), days, true);
        //Por default 5 días hábiles o corridos del día posterior o sino se coloca
        //el vencimiento manual - la mercadería tiene que estar entregada para cobrar"
      } else if (typeBusiness == businessDict.sinDescarga) {
        //Se muestra la fecha que ponen manual
      } else if (typeBusiness == businessDict.forward) {
        //Último día hábil del mes de entrega
        newExpiration = getDateEndOfMonthBusinessDay(deliveryPeriod.endDate);
        //days = getDaysBetweenDates(getDate(), newExpiration)
        days = getDaysBetweenDatesByBusinessDay(getDate(), newExpiration, true)

      }
    } else if (wayPayName == wayPayNames.diferidoPesificacion) {
      //newExpiration = dateByBusinessDays(this.date.getDate(), days, true);

      //Por default 5 días hábiles
    } else if (wayPayName == wayPayNames.diferidoFacturacion) {
      days = 10;
      //Por default 10 días hábiles del día posterior o sino XX días de selección
      //newExpiration = dateByBusinessDays(this.date.getDate(), days, true);
    } else if (wayPayName == wayPayNames.contraFixMercaderiaEntregada) {
      //Por default 5 días hábiles, sin fecha de vencimiento
    } else if (wayPayName == wayPayNames.contraFixCartaGarantia) {
      //Por default 5 días hábiles, sin fecha de vencimiento
    }

    return {
      wayPayName: wayPayName,
      percentage: 97.5,
      expiration: newExpiration,
      days: days,
      isBusinessDays: 1,
      options:0
    }
  } catch (error) {
    return WAY_PAY_VOID;
  }
}

/**
 * Given the payment method structure, it returns in date format the expiration date that it should have by default.
 * @param price 
 * @param wayPay 
 * @returns string
 */
export function getExpirationByWayPay(price:Price,wayPay:WayPay):string{
  try{
    const typeCoin=price.typeCoin;
    const typeBusiness=price.typeBusiness;
    const deliveryPeriod=price.deliveryPeriod;
    const wayPayName=wayPay.wayPayName;
    let days = wayPay.days;
    let newExpiration='--';
    
    if (wayPayName == wayPayNames.conCartaGarantia && typeCoin == typeCoins.ARS) {
      newExpiration = dateByBusinessDays(getDate(), days, true);
    } else if (wayPayName == wayPayNames.fechaFija) {
      if (typeBusiness == businessDict.conDescarga || typeBusiness == businessDict.depositos) {
        newExpiration = dateByBusinessDays(getDate(), days, true);
      }  else if (typeBusiness == businessDict.forward) {
        newExpiration = getDateEndOfMonthBusinessDay(deliveryPeriod.endDate);
      }
    } 

    return newExpiration
  } catch (error) {
    return '--';
  }
}

/**
 * Given a payment method, returns if it belongs to a business condition with deferred payment
 * @param wayPayName
 * @returns boolean
 */
export function getIsWayPayDeferred(wayPayName:number){
  return wayPayName==wayPayNames.diferidoFacturacion || wayPayName==wayPayNames.diferidoPesificacion;
}

/**
 * Given a payment method, returns whether it belongs to a business variant
 * @param wayPayName
 * @returns boolean
 */
export function getIsWayPayVariant(wayPayName:number){
  return [wayPayNames.contraEntrega, wayPayNames.conCartaGarantia, wayPayNames.fechaFija].includes(wayPayName);
}

/**
 * Given a business condition, returns the valid payment methods for that case.
 * Analyzes the start and end date, if none, returns an empty array.
 * Analyzes the currency, for each case it returns the valid ones depending on the type of business.
 * @param price 
 * @returns number[] 
 */
export function getWayPaysValids(price: Price): number[] {
  if (price.deliveryPeriod.startDate == '--' || price.deliveryPeriod.endDate == '--') {
    return [];
  }

  const businessMapping: { [key: number]: number[] } = {
    1: price.typeCoin === typeCoins.ARS ? pesosWithDischarge : dolarsWithDischarge,
    2: price.typeCoin === typeCoins.ARS ? pesosWithOutDischarge : dolarsWithOutDischarge,
    3: price.typeCoin === typeCoins.ARS ? pesosWithDeposit : dolarsWithDeposit,
    4: price.typeCoin === typeCoins.ARS ? pesosWithForward : dolarsWithForward,
  };

  return businessMapping[price.typeBusiness] || [];
}

/**
 * Given a currency type, returns the possible payment methods that will be used to complete a counterparty.
 * 
 * @param { string } typeCoin - currency type of exchange
 * @returns number[] - Array of possible payment methods
 */
export function getWayPayFromExchange(typeCoin: string): number[] {
  if (typeCoin == typeCoins.ARS) {
    return [wayPayNames.contraEntrega, wayPayNames.conCartaGarantia, wayPayNames.fechaFija, wayPayNames.diferidoFacturacion];
  } else if(typeCoin == typeCoins.USD) {
    return [wayPayNames.contraEntrega, wayPayNames.conCartaGarantia, wayPayNames.diferidoPesificacion];
  }
  return []
}

/**
 * Given a price, obtain the payment method and analyze which code should reach IBM.
 * Code obtained from CERCPA table.
 * @param price
 * @returns number
 * */
export function getWayPayCodeIBM(wayPay:WayPay, typeCoin:string):number {
  const percentage=wayPay.percentage;
  const isDolar:boolean=typeCoin==typeCoins.USD;
  const expiration=wayPay.expiration;
  const wayPayName=wayPay.wayPayName;
  const isBusinessDay:boolean=wayPay.isBusinessDays==1;
  const options:number=wayPay.options;
  
  switch(percentage){
    case 97.5: return get975PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration,options);
    case 100: return get100PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration);
    case 95: return get950PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration);
    case 98.5: return get985PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration);
    case 90: return get900PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration);
    case 85: return get850PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration);
    case 80: return get800PercentageWayPay(wayPayName, isBusinessDay, isDolar, expiration);
  }
  return 0;
}

/* ------------------------------------------ */

/**
 *  The functions 'get100PercentageWayPay', 'get985PercentageWayPay', 'get975PercentageWayPay', 'get950PercentageWayPay', 
 * 'get900PercentageWayPay', 'get850PercentageWayPay' and 'get800PercentageWayPay' 
 * are passed as a parameter to our payment method, if isBusinessDay, if isDolar, 
 * and the expiration and after doing a series of calculations to analyze what the number is for IBM, it returns it.
 * */

function get100PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string):number{
  if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles
    if(isBusinessDay){
      //Si es en dolares, es siempre la misma
      return isDolar? 15: 15;
    }
  } else if(wayPayNames.conCartaGarantia==wayPayName){ //Con carta de garantía solo con días hábiles.
    if(isBusinessDay){
      if(expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
        return 19;
      }
    }
  } else if(wayPayNames.fechaFija==wayPayName){ //Fecha fija es solo en pesos
    if(!isDolar && expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
      //Si es día hábil o no, es siempre el mismo código.
      return isBusinessDay? 1: 1;
    }
  } else if(wayPayNames.diferidoFacturacion==wayPayName){ //Diferido de la facturacion es siempre en pesos
    if(!isDolar && isBusinessDay){
      return 7;
    } else if(!isDolar && !isBusinessDay){
      return 57;
    }
  } else if(wayPayNames.diferidoPesificacion==wayPayName){ //Diferido de la pesificacion es siempre en dolares
    if(isDolar && isBusinessDay){
      return 10;
    }
  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    return isBusinessDay? 28:49;
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    
  }
  return 0;
}

function get985PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string):number{
  if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles
    

  } else if(wayPayNames.conCartaGarantia==wayPayName){ //Con carta de garantía solo con días hábiles.
    

  } else if(wayPayNames.fechaFija==wayPayName){ //Fecha fija es solo en pesos
    if(!isDolar && expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
      //Si es día hábil o no, es siempre el mismo código.
      return isBusinessDay? 9: 9;
    }
  } else if(wayPayNames.diferidoFacturacion==wayPayName){ //Diferido de la facturacion es siempre en pesos
   if(!isDolar && isBusinessDay){
    return 41;
   }
  } else if(wayPayNames.diferidoPesificacion==wayPayName){ //Diferido de la pesificacion es siempre en dolares
    

  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    return !isBusinessDay? 32:0;
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    
  }
  return 0;
}

function get975PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string, options:number):number{
  if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles
    if(isBusinessDay){
      //Si es en dolares, es siempre la misma
      return isDolar? 212: 212;
    }
  } else if(wayPayNames.conCartaGarantia==wayPayName){ //Con carta de garantía solo con días hábiles.
    //Si tiene marcado opciones, entonces es con Warrant o certificado de depósito
    if(options>0){
      if(options==1){
        return 216; //Certificado de depósito
      } else if(options==2){
        return 230; //Warrant
      }
    } 
    
    if(isBusinessDay){ 
      if(!isDolar && expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
        return 215;
      } else if(isDolar){ //Si es en dolares, y no tiene fecha cierta, es siempre 398
        return (expiration && expiration!='--')? 215: 398;
      }
    }
  } else if(wayPayNames.fechaFija==wayPayName){ //Fecha fija es solo en pesos
    if(!isDolar && expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
      //Si es día hábil o no, es siempre el mismo código.
      return isBusinessDay? 213: 213;
    }
  } else if(wayPayNames.diferidoFacturacion==wayPayName){ //Diferido de la facturacion es siempre en pesos
    if(!isDolar){
      //Si es día hábil, es un código, sino es otro
      return isBusinessDay? 95: 555;
    }
  } else if(wayPayNames.diferidoPesificacion==wayPayName){ //Diferido de la pesificacion es siempre en dolares
    if(isDolar){
      //Si es día hábil, es un código, sino es otro
      return isBusinessDay? 298: 296;
    }
  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    return isBusinessDay? 218: 222;
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    return isBusinessDay? 264: 0;
  }
  return 0;
}

function get950PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string):number{
  if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles
    if(isBusinessDay){
      //Si es en dolares, es siempre la misma
      return isDolar? 117: 117;
    }
  } else if(wayPayNames.conCartaGarantia==wayPayName){ //Con carta de garantía solo con días hábiles.
    if(isBusinessDay){ 
      if(expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
        return 422;
      }
    }
  } else if(wayPayNames.fechaFija==wayPayName){ //Fecha fija es solo en pesos
    if(!isDolar && expiration && expiration!='--'){ //Si tiene vencimiento significa que es una fecha cierta
      //Si es día hábil o no, es siempre el mismo código.
      return isBusinessDay? 421: 421;
    }
  } else if(wayPayNames.diferidoFacturacion==wayPayName){ //Diferido de la facturacion es siempre en pesos
    

  } else if(wayPayNames.diferidoPesificacion==wayPayName){ //Diferido de la pesificacion es siempre en dolares
    if(isDolar){
      //Si es día hábil, es un código, sino es otro
      return isBusinessDay? 322: 301;
    }
  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    if(isBusinessDay){
      return 118;
    }
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    if(isBusinessDay){
      return 0;
    }
  }
  return 0;
}

function get900PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string):number{
  if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles
    if(isBusinessDay){
      //Si es en dolares, es siempre la misma
      return isDolar? 101: 101;
    }
  } else if(wayPayNames.conCartaGarantia==wayPayName){ 



  } else if(wayPayNames.fechaFija==wayPayName){ 



  } else if(wayPayNames.diferidoFacturacion==wayPayName){ 
    if(isBusinessDay){
      return 0;
    } else {
      return 54
    }
  } else if(wayPayNames.diferidoPesificacion==wayPayName){ 


  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    if(isBusinessDay){
      return 0;
    }
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    if(isBusinessDay){
      return 0;
    }
  }
  return 0;
}

function get850PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string):number{
  if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles

  } else if(wayPayNames.conCartaGarantia==wayPayName){ 

  } else if(wayPayNames.fechaFija==wayPayName){ 

  } else if(wayPayNames.diferidoFacturacion==wayPayName){ 

  } else if(wayPayNames.diferidoPesificacion==wayPayName){ 

  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    return isBusinessDay? 557: 0;
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR

  }
  return 0;
}

function get800PercentageWayPay(wayPayName:number, isBusinessDay:boolean, isDolar:boolean, expiration:string):number{
    if(wayPayNames.contraEntrega==wayPayName){ //Contra entrega solo es con dias habiles
    if(isBusinessDay){
      //Si es en dolares, es siempre la misma
      return isDolar? 888: 888;
    }
  } else if(wayPayNames.conCartaGarantia==wayPayName){ 



  } else if(wayPayNames.fechaFija==wayPayName){ 



  } else if(wayPayNames.diferidoFacturacion==wayPayName){ 



  } else if(wayPayNames.diferidoPesificacion==wayPayName){ 

    
  } else if(wayPayNames.contraFixMercaderiaEntregada==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    if(isBusinessDay){
      return 0;
    }
  } else if(wayPayNames.contraFixCartaGarantia==wayPayName){ //Contra fix mercaderia entregada de condición A FIJAR
    if(isBusinessDay){
      return 0;
    }
  }
  return 0;
}

/* ------------------------------------------ */

/**
 * Through a payment code from table CER050T5 (OV), we obtain the payment code that IBM uses in CERCPA.
 * We access using key value.
 * @param code
 * @returns number
 */
export function getWayPayWithProforms(code:number){
  return WAY_PAY_OV_IBM[code];
}

/**
 * Given an IBM code with a number of days, create a WayPay structure according to the ones we use in the system.
 * Follow the following logic:
 * If you do not have a proforma: auto-complete it as the parent contract.
 * If you have a proforma and the parent contract is with a letter of guarantee: automatically suggest a guarantee with the percentage of the payment method of the proforma.
 * If you have a proforma: auto-complete it with the proforma and the possibility of editing it.
 * @param code 
 * @param days
 * @returns WayPay
 * */
export function getWayPayStruct(code:number,days:number, wayPayMother:WayPay):WayPay{
  //If you do not have a proforma: auto-complete it as the parent contract.
  if(code<=0){
    return wayPayMother;
  }

  
  let wayPay:WayPay=JSON.parse(JSON.stringify(WAY_PAY_VOID));

  //If you have a proforma and the parent contract is with a letter of guarantee: automatically suggest a guarantee with the percentage of the payment method of the proforma.
  wayPay.wayPayName= (wayPayMother.wayPayName == wayPayNames.conCartaGarantia)? wayPayNames.conCartaGarantia: wayPayNames.contraEntrega

  /*Si el negocio es con carta de garantía, autocompleta con el porcentaje que trae la proforma.
    Pero en la mayoría de casos la proforma trae 3 días (del contra entrega).
    ¿Debe quedarse los 3 días o cambiar al valor del madre si es con carta de garantía?
    ¿Debe quedarse si son días habiles o cambiar al valor del madre?
    ¿Qué pasa con la fecha de vencimiento de la parcial?*/
    
  wayPay.days=days==0? 3: days;
  wayPay.expiration='--';
  wayPay.isBusinessDays=1;
  wayPay.percentage= getPercentageByCode(code);

  return wayPay;
}

function getPercentageByCode(code:number){
  switch(code){
    case 212: return 97.5;
    case 15: return 100;
    case 117: return 95;
  }
  return 0;
}