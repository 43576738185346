export const PRODUCT_NAMES={
"-1": "--",
0:"--",
1:"Trigo",
2:"Maiz",
3:"Sorgo",
4:"Girasol",
5:"Soja",
6:"Lino",
7:"Mani",
8:"Mijo",
9:"Cebada Forrajera",
10:"Centeno",
11:"Alpiste",
12:"Arroz",
13:"Avena",
14:"Nabo",
15:"Cartamo",
16:"Residuo De Soja",
17:"Triguillo",
18:"Semilla De Girasol",
19:"Maiz Morocho",
20:"Cebada Cervecera",
21:"Descarte De Soja",
22:"Semilla De Soja",
23:"Maicillo",
24:"Afrechillo",
25:"Pellets De Girasol",
26:"Pellets Afrechillo",
27:"Colza",
28:"Granza De Lino",
29:"Lentejas",
30:"Gramina Rhode",
31:"Agropiro Alargado",
32:"Trifoliun Repen",
33:"Lino Inferior",
34:"Cebadilla Australia",
35:"Centeno Natural",
36:"Arvejas",
37:"Rye Grass",
38:"Semilla De Alfalfa",
39:"Sem.Alfalfa Vibrada",
40:"Trigo Carbonudo",
41:"Trigo Candeal",
42:"Trebol Alba Maquin.",
43:"Pellets De Soja",
44:"Avena Amarilla. Maq.",
45:"Pasto Romano Maquin.",
46:"Ceb.Forraj.Ranquelin",
47:"Avena Amarilla",
48:"Centeno Massaux",
49:"Residuo De Lino",
50:"Semilla De Nabo",
51:"Semilla De Lino",
52:"Lino Natural",
53:"Semilla De Trigo",
54:"Sorgo Amarillo",
55:"Semilla Achicoria",
56:"Moha",
57:"Semilla De Sorgo",
58:"Pellets De Mani",
59:"Residuo Girasol",
60:"Afrechillo De Trigo",
61:"Moha Carape",
62:"Sojilla",
63:"Pellets De Trigo",
64:"Gluten Meal",
65:"Residuo De Trigo",
66:"Granza De Maiz",
67:"Semilla De Cartamo",
68:"Semilla De Mijo",
69:"Melilotus",
70:"Bolsas Vacias",
71:"Hilo",
72:"Tela De Yute",
73:"Pellets Algodon",
74:"Expellers Algodon",
75:"Harina De Soja",
76:"Semilla De Trebol",
77:"Harina De Carne",
78:"Semilla De Algodon",
79:"Semilla De Arveja",
80:"Polvo De Hueso",
81:"Mezcla Conc.Granul.",
82:"Glucosa De Maiz",
83:"Pellets De Lino",
84:"Expellers De Lino",
85:"Canola",
86:"Fosfato Diamonico",
87:"Urea",
88:"Agroquimicos",
89:"Gas Oil",
90:"Descarte De Trigo",
91:"Pellets Casc.De Soja",
92:"Nitrato De Amonio",
222:"Maiz Especial",
93:"Cascara De Arroz",
94:"Novillo (I.N.A.)",
95:"Aceite De Soja",
96:"Trigo T",
111:"Ita (Ind.Trigo Arg.)",
99:"Ddf     (Deriv.Fin.)",
98:"Euro Fx",
97:"Mezcla Compuesta",
100:"Fertilizantes",
101:"Boden 2012",
112:"Glifosato",
223:"Super Fosfato Triple",
224:"M.A.P.",
225:"Super Fosfato Simple",
888:"Insumos",
102:"Cafe",
110:"Oro",
113:"Petroleo",
103:"Pellets Casc. Algod",
114:"Jugo Naranja Congel.",
115:"Oro Minisize",
116:"Petroleo Mini",
117:"Soja Mini",
118:"Trigo Mini",
119:"Dolar Canadiense",
401:"Trigo Molinos",
402:"Maiz Consumos",
120:"Azucar",
221:"Maiz Pisingallo",
105:"Soja Fca",
226:"Panceta",
205:"Soja Chicago Matba",
104:"Trigo Blando",
121:"Garbanzo",
106:"Poroto Blanco",
199:"Bonar 24",
198:"Gas Natural",
107:"Poroto Dist. De Bco",
150:"Mani Confitero",
200:"Indice Rofex",
197:"Fci",
201:"Acciones De Galicia"
}