import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { area, workUnit } from 'src/app/shared/dict/user';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { User } from 'src/app/shared/interfaces/user.interface';

@Component({
  selector: 'app-body-profile',
  templateUrl: './body-profile.component.html',
  styleUrls: ['./body-profile.component.scss']
})
export class BodyProfileComponent implements OnInit, OnDestroy {
  public user!:User;
  public isEdited:boolean=false;
  userSubscription!:Subscription;
  constructor(private store:Store<appState>) {}

  ngOnInit(): void {
    this.userSubscription=this.store.select('usuario').subscribe( (usuario:any) => {
      this.user = usuario;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  getName(user:User){
    return `${user.name} ${user.lastName}`;
  }

  getDateOfBirthday(user:User){
    let birthDay=user.birthDay;
    if(birthDay){
      return `${birthDay}`;
    } 
    return '--'
  }

  getEmail(user:User){
    return `${user.email}`;
  }

  getTellphone(user:User){
    let phone = user.phone;
    if(phone){
      return `${phone}`;
    }
    return '--';
  }

  getWorkUnity(user:User){
    const workUnits:any=workUnit;
    if(workUnits[user.workUnit]){
      return `${workUnits[user.workUnit]}`;
    }
    return '--';
  }

  getUbication(user:User){
    let ubication = ""//user.ubication;
    if(ubication){
      return `${ubication}`;
    }
    return 'Rosario, Santa Fe'
  }

  getArea(user:User){
    let areas:any=area;
    if(areas[user.area]){
      return `${areas[user.area]}`;
    }
    return '--'
  }

  getWorkStation(user:User){
    let workStation = ""//user.workStation;
    if(workStation){
      return `${workStation}`;
    }
    return '--'
  }



}
