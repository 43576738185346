<app-buttons-to-fix *ngIf="getShowButtonsToFix()"></app-buttons-to-fix>

<div class="table table-desktop" *ngIf="!isViewMobile else elseBlock">
    <app-orden-table *ngIf="typeOfHistorical==TYPE_OF_HISTORICAL.ORDER"></app-orden-table>
    <app-price-table *ngIf="typeOfHistorical==TYPE_OF_HISTORICAL.PRICE"></app-price-table>
    <app-exchange *ngIf="typeOfHistorical==TYPE_OF_HISTORICAL.EXCHANGE"></app-exchange>
</div>

<ng-template #elseBlock>
    <div class="width-100"  *ngIf="typeOfHistorical==TYPE_OF_HISTORICAL.ORDER">
        <div class="order-mobile" *ngFor="let order of arrayOfHistorical | filterByRepresentation: isRepresentation:user | matchOrdersByName: -1: orderFilter">
            <app-table-mobile-order [orders]="order" class="info-product" (actions)="selectOptionMenuOrder($event.event, $event.order)"></app-table-mobile-order>
        </div>
    </div>

    <div class="width-100" *ngIf="typeOfHistorical==TYPE_OF_HISTORICAL.PRICE">
        <div class="price-mobile" *ngFor="let price of arrayOfHistorical | isPriceVisibleForComercial:isComercial | isPriceOfCommodities: buyerType | matchPricesByName">
            <app-table-mobile-price [prices]="price" class="info-product"></app-table-mobile-price>
        </div>
    </div>

    <div class="width-100" *ngIf="typeOfHistorical==TYPE_OF_HISTORICAL.EXCHANGE">
        <div class="exchange-mobile" *ngFor="let exchanges of arrayOfHistorical | matchExchangesByName: -1:exchangeFilter">
           <app-table-mobile-exchange [orders]="exchanges" class="info-product"></app-table-mobile-exchange>
        </div>
    </div>
</ng-template>