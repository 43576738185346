import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WayToPayComponent } from './way-to-pay.component';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { DateInputModule } from 'src/app/components/inputs/date/date.module';
import { ExpirationModule } from '../../expiration/expiration.module';
import { RadioButtonInputModule } from 'src/app/components/inputs/radio-buttons/radio-buttons.module';

@NgModule({
  declarations: [
    WayToPayComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule,
    DateInputModule,
    ExpirationModule,
    RadioButtonInputModule
  ], exports: [
    WayToPayComponent
  ]
})
export class WayToPayModule { }
