<div class="body-profile">
    
    <!-- BANNER DESKTOP -->
    <div class="banner width-100 not-visible-in-510"></div>

    <!-- BANNER MOBILE -->
    <div class="banner-mobile width-100 visible-in-510"></div>

    <div class="body width-100">
        <div class="image-profile-container width-100">
            <img class="image-profile" src="{{user.photo}}" alt="image-profile">    
        </div>
    
        <section class="info-profile" *ngIf="!isEdited">
            <div class="header-info-profile row-between">
                <h4 class="my-profile title">Mi perfil</h4>
    
                <!--<div class="shared-info-profile gap-16 row-between">
                    <edit-contract-svg class="copy icon pointer" [active]="true" [tooltip]="false" [size]="24"></edit-contract-svg>
                    <shared-svg class="shared icon pointer" [active]="true"></shared-svg>
                </div>-->
            </div>
    
            <div class="info-container gap-32">
                <div class="personal-info gap-16 column-between half-section">
                    <span class="title-info">Información personal</span>
                    <app-data-profile class="data-profile" [fielTitle]="'Nombre y apellido'" [value]="getName(user)"></app-data-profile>
                    <app-data-profile class="data-profile" [fielTitle]="'Fecha de nacimiento'" [value]="getDateOfBirthday(user)"></app-data-profile>
                    <app-data-profile class="data-profile" [fielTitle]="'Correo electrónico'" [value]="getEmail(user)"></app-data-profile>
                    <app-data-profile class="data-profile" [fielTitle]="'Teléfono'" [value]="getTellphone(user)"></app-data-profile>
                </div>

                <div class="line grey"></div>

                <div class="business-info column-between gap-16 half-section">
                    <span class="title-info">Información de la empresa</span>
                    <app-data-profile class="data-profile" [fielTitle]="'Unidad de negocio'" [value]="getWorkUnity(user)"></app-data-profile>
                    <app-data-profile class="data-profile" [fielTitle]="'Ubicación'" [value]="getUbication(user)"></app-data-profile>
                    <app-data-profile class="data-profile" [fielTitle]="'Área'" [value]="getArea(user)"></app-data-profile>
                    <app-data-profile class="data-profile" [fielTitle]="'Puesto'" [value]="getWorkStation(user)"></app-data-profile>  
                </div>
            </div>
        </section>

        <section class="info-profile" *ngIf="isEdited">
            <div class="header-info-profile row-between">
                <h4 class="my-profile title">Mi perfil</h4>
    
                <div class="shared-info-profile gap-16 row-between">
                    <edit-contract-svg class="copy icon pointer" [active]="true" [tooltip]="false" [size]="24"></edit-contract-svg>
                    <shared-svg class="shared icon pointer" [active]="true"></shared-svg>
                </div>
            </div>
    
            <div class="info-container row-between gap-32">
                <div class="personal-info gap-16 column-between half-section">
                    <span class="title-info">Información personal</span>
                    <app-data-profile [fielTitle]="'Nombre y apellido'" [value]="getName(user)"></app-data-profile>
                    <app-data-profile [fielTitle]="'Fecha de nacimiento'" [value]="getDateOfBirthday(user)"></app-data-profile>
                    <app-data-profile [fielTitle]="'Correo electrónico'" [value]="getEmail(user)"></app-data-profile>
                    <app-data-profile [fielTitle]="'Teléfono'" [value]="getTellphone(user)"></app-data-profile>
                </div>

                <div class="line grey"></div>

                <div class="business-info column-between gap-16 half-section">
                    <span class="title-info">Información de la empresa</span>
                    <app-data-profile [fielTitle]="'Unidad de negocio'" [value]="getWorkUnity(user)"></app-data-profile>
                    <app-data-profile [fielTitle]="'Ubicación'" [value]="getUbication(user)"></app-data-profile>
                    <app-data-profile [fielTitle]="'Área'" [value]="getArea(user)"></app-data-profile>
                    <app-data-profile [fielTitle]="'Puesto'" [value]="getWorkStation(user)"></app-data-profile>  
                </div>
            </div>
        </section>
    </div>
</div>