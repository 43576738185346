import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TonsComponent } from './tons.component';
import { NumberInputModule } from '../../inputs/number/number.module';



@NgModule({
  declarations: [
    TonsComponent
  ],
  imports: [
    CommonModule,
    NumberInputModule
  ], exports: [
    TonsComponent
  ]
})
export class TonsModule { }
