import { Component, OnDestroy,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadOrdersAllSuccess, setIsOrderReturned, setOrdersSelected } from 'src/app/redux/actions/order.action';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { orderStatusConst } from 'src/app/shared/const/orders';
import { Router } from '@angular/router';
import { DateChecker } from 'src/app/shared/date.checker';
import { localStorageNames } from 'src/app/shared/const/localStorageNames';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OrderValidator } from 'src/app/shared/order';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';

@Component({
  selector: 'dialog-returned-order',
  templateUrl: './order-returned.html',
  styleUrls: ['./order-returned.scss']
})

export class DialogReturnedOrder implements OnInit, OnDestroy {
  hour: string = '';
  title: string = 'La orden de venta será devuelta';
  ordersSelected!: Order[]; //Store of redux;
  orders!: Order[]; //Store of redux;
  isMessage: boolean = false;
  message: string = '';

  user!:User;
  private orderSubscription!:Subscription;
  private userSubscription!:Subscription;

  constructor(private store: Store<appState>, private date: DateChecker, public dialogRef: MatDialogRef<DialogReturnedOrder>, private orderV:OrderValidator) {}

  ngOnInit() {
    this.hour = this.date.setHourFormat();
    this.orderSubscription=this.store.select('order').subscribe(order => {
      this.ordersSelected = order.orderBoard.ordersSelected;
      this.orders = order.orderBoard.all;
    })

    this.userSubscription=this.store.select('usuario').subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.store.dispatch(setIsOrderReturned({ isOrderReturned: false }));
    this.orderSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  sendOrder() {
    const quantOrders = this.ordersSelected.length;
    for (let i = 0; i < quantOrders; i++) {
      let orderToSave = this.orderV.saveOrderStatus(this.ordersSelected[i], orderStatusConst.RETURNED, this.message,this.user);
    }
    //Guardamos en el local storage
    this.store.dispatch(setOrdersSelected({ orders: [] }));
    this.store.dispatch(setIsOrderReturned({isOrderReturned:false}));

    this.dialogRef.close();
  }
  
  setMessage(event: any) {
    this.message = event.target.value;
  }

}