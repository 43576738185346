import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { getCookie } from '../session.checker';
import { COOKIES_CONST } from '../const/localStorageNames';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ResponseToken, setInCookies } from 'src/app/redux/effects/session.effect';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private jwtHelper= new JwtHelperService();
  private token: string;
  private refreshToken: string;

  constructor(private http: HttpClient) {
    
    this.token = getCookie(COOKIES_CONST.accessToken) || '';
    this.refreshToken = getCookie(COOKIES_CONST.refreshToken) || '';
  }

  getToken(email:string, id:string){
    const request={ 
      email: email,
      userID: id
    }
    
    return this.http.post<any>(environment.rutas.getToken, request).pipe(
      map( (response:ResponseToken) => {
        if(response){
          this.token = response.accessToken;
          this.refreshToken = response.refreshToken;
        }

        return response;
      }),
      catchError(error => {
        console.error('Error al obtener el token', error);
        return throwError(error);
      })
    );
    //We deliver the token
  }
  
  getAccessToken(){
    return this.token;
  }

  getRefreshToken(){
    return this.refreshToken;
  }

  isTokenExpired(){
    return this.jwtHelper.isTokenExpired(this.token);
  }

  //Check that the token is 10 minutes away from expiring
  isTokenExpiring(){
    const tokenExpireDate = this.jwtHelper.getTokenExpirationDate(this.token);
    const now = new Date();
    if(tokenExpireDate){
      return tokenExpireDate.getTime() - now.getTime() < 600000; //10 minutes
      //return tokenExpireDate.getTime() - now.getTime() < 900000; //15 minutes
    }
    return false;
  }

  isRefreshTokenExpired(){
    return this.jwtHelper.isTokenExpired(this.refreshToken);
  }

  public renewToken(): Observable<string> {

    return this.http.get<any>(environment.rutas.getRefreshToken, {headers: {Authorization: `Bearer ${this.refreshToken}`}}).pipe(
      map( (response:ResponseToken) => {
        if(response){
          this.token = response.accessToken;
          this.refreshToken=response.refreshToken;
          setInCookies(response);
          console.log("Actualizamos tokens del refresh")
        }

        return this.token;
      }),
      catchError(error => {
        console.error('Error al renovar el token', error);
        return throwError(error);
      })
    );
  }
}