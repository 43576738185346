import { Component, Input, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { DateChecker } from 'src/app/shared/date.checker';
import { appState } from 'src/app/shared/interfaces/appState.interface';

@Component({
  selector: 'dialog-price-create',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.scss']
})
export class DialogPriceCreate implements OnDestroy {
  hour: string = '';
  moreInfo: string = '';
  isSuccess: boolean = true;

  private modalCreate$!: Subscription;

  constructor(private router: Router, private store: Store<appState>, private date:DateChecker, public dialogRef: MatDialogRef<DialogPriceCreate>) {
    this.hour = this.date.setHourFormat();

    this.store.select('options', 'modalCreate').subscribe(modalCreate => {
      this.isSuccess = modalCreate.isSuccess;
    });
  }

  ngOnDestroy(): void {
    this.modalCreate$?.unsubscribe();
  }

  generateNewPrice() {
    const url= '/' + NAVEGATION.GENERATE_PRICE;
    this.router.navigate([url]);
    this.close();
  }

  goToPriceBoard() {
    const url= '/' + NAVEGATION.PRICE_BOARD;
    this.router.navigate([url]);
    this.close()
  }

  getMessage(){
    return this.isSuccess ? 'La nueva condición fue generada con éxito, podrás verla en el tablero de condiciones de negocio.' : 
    'Ocurrió un error generando la condición, por favor, aguarde un momento y reintente.';
  }

  close() {
    this.dialogRef.close();
  }
}