import { Component, OnChanges, OnInit } from '@angular/core';
import { BusinessParticularities } from 'src/app/shared/interfaces/business-particularities';
import { BusinessParticularityOption } from '../options.component';

@Component({
  selector: 'app-respeta-iva',
  templateUrl: './respeta-iva.component.html',
  styleUrls: ['./../options.component.scss']
})
export class RespetaIvaComponent extends BusinessParticularityOption implements OnChanges {
  isRespectVAT:boolean=false;
  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    try{
      this.isRespectVAT=this.businessParticularitiesStruct.respectVatException==1;
    } catch(err){
      this.isRespectVAT=false;
    }
  }

  setRespectVAT(){
    this.isRespectVAT=JSON.parse(JSON.stringify(!this.isRespectVAT));
    let newBP:BusinessParticularities= JSON.parse(JSON.stringify(this.businessParticularitiesStruct))
    newBP.respectVatException=this.boolToNumber(this.isRespectVAT);
    this.emit2(newBP);
  }
}
