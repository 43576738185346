import { Component, OnInit, HostListener } from '@angular/core';
import { orderTags } from 'src/app/shared/const/orders';
import { priceTags } from 'src/app/shared/const/prices.const';
import { DateChecker } from 'src/app/shared/date.checker';
import { buyers } from 'src/app/shared/dict/buyers';
import { priceState, quality, typeCoin } from 'src/app/shared/dict/dict';
import { orderStates } from 'src/app/shared/dict/orders';
import { zones } from 'src/app/shared/dict/place-of-delivery';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { typeBusiness } from 'src/app/shared/dict/typeBusiness';
import { wayPay } from 'src/app/shared/dict/wayToPay';
import { Historical } from 'src/app/shared/interfaces/historical.interface';
import { getWayPayText } from 'src/app/shared/way-pay';

@Component({
  template: '',
})
export class HistoricalComponent implements OnInit {
  public messageInfo: string = '';
  historicalId: string = '';
  historicalIdAux: string = '';
  historicalArray: Historical[] = []
  isError = false;
  dateC:any;

  //Acordion
  indexToOpen: number = -1;
  isShowAddBuyer: boolean = false;

  //Detect click
  isShow: boolean = false;
  wasInside = false;
  count = 0;
  clickInClose = false;

  constructor() {}

  ngOnInit(): void {
    this.dateC=new DateChecker();
  }

  close() { }

  open() { }

  @HostListener('click') clickInside() {
    if (!this.clickInClose) {
      this.open();
    }
    this.wasInside = true;
  }

  @HostListener('document:click') clickout() {
    if (this.isShow) this.count++;

    if (!this.wasInside && this.count > 1) {
      this.close();
      this.count = 0;
    }
    this.wasInside = false;
  }

  getHour(date: string): string {
    try{
      const newDate: Date = new Date(date);
      const hours = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
      const minutes = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
      return hours + ':' + minutes + ' hr.';
    } catch(err){
      return date;
    }
  }

  getField(field: string): string {
    try{
      const fieldSplit = field.split('.');
      return this.parseField(fieldSplit[fieldSplit.length - 1]);
    } catch(err){
      return 'Campo no definido';
    }
  }

  getValue(historical: Historical): any {
    try{
      const fieldSplit = historical.campo.split('.');
      const object= this.getObjectWithField(fieldSplit[fieldSplit.length - 1]);
      const value=this.setValue(historical, object);
      return value;
    } catch(err){
      return 'Valor no definido';
    }
  }

  getNameOfChanger(historical: Historical): string {
    try{
      if(historical.name && historical.lastname){
        return historical.name + ' ' + historical.lastname;
      }
      return 'Nombre no definido';
    } catch(err){
      return 'Nombre no definido';
    }
  }

  //Traducimos el valor del campo a un valor legible
  parseField(field: string) {
    switch (field) {
      case orderTags.statusOrder:
      case priceTags.status: return 'Estado';
      case priceTags.message: return 'Mensaje';
      case priceTags.price: return 'Precio';
      case priceTags.typeCoin: return 'Tipo de moneda';
      case priceTags.buyer: return 'Comprador';
      case orderTags.codeS: return 'Vendedor';
      case priceTags.wayPay:
      case priceTags.wayPayName: return 'Forma de pago';
      case priceTags.expiration: return 'Vencimiento';
      case priceTags.typeBusiness: return 'Tipo de negocio';
      case priceTags.isFeatured: return 'Precio destacado';
      case priceTags.isFixed: return 'Precio fijado';
      case priceTags.isPort: return 'Precio de comprador';
      case priceTags.productName: return 'Nombre del producto';
      case priceTags.zone: return 'Lugar de entrega';
      case priceTags.port: return 'Lugar de entrega';
      case priceTags.deliveryPeriod: return 'Periodo de entrega';
      case priceTags.startDate: return "Fecha de inicio";
      case priceTags.endDate: return "Fecha de fin";
      case priceTags.quality: return 'Calidad';
      case priceTags.pesificacion: return 'Pesificación';
      case priceTags.harvest: return 'Cosecha';
      case priceTags.hour: return 'Hora';
      default: return '';
    }
  }

  //Devolvemos el tipo de objeto que es el campo
  getObjectWithField(field: string) {
    let objectToReturn: any;
    switch (field) {
      case orderTags.statusOrder: objectToReturn = orderStates; break;
      case priceTags.message: objectToReturn = 'string'; break;
      case priceTags.buyer: objectToReturn = buyers; break;
      case orderTags.codeS: objectToReturn = sellerWithData; break;
      case priceTags.price: objectToReturn = 'number'; break;
      case priceTags.typeCoin: objectToReturn = typeCoin; break;
      case priceTags.wayPay: objectToReturn = priceTags.wayPay; break;
      case priceTags.wayPayName: objectToReturn = wayPay; break;
      case priceTags.hour: objectToReturn = 'string'; break;
      case priceTags.expiration: objectToReturn = 'string'; break;
      case priceTags.typeBusiness: objectToReturn = typeBusiness; break;
      case priceTags.isFeatured: objectToReturn = 'boolean'; break;
      case priceTags.isFixed: objectToReturn = 'boolean'; break;
      case priceTags.isPort: objectToReturn = 'boolean'; break;
      case priceTags.status: objectToReturn = priceState; break;
      case priceTags.productName: objectToReturn = PRODUCT_NAMES; break;
      case priceTags.zone: objectToReturn = zones; break;
      //case priceTags.port: objectToReturn = priceTags; break;
      case priceTags.deliveryPeriod: objectToReturn = priceTags.deliveryPeriod; break;
      case priceTags.startDate: objectToReturn = 'string'; break;
      case priceTags.endDate: objectToReturn = 'string'; break;
      case priceTags.quality: objectToReturn = quality; break;
      case priceTags.pesificacion: objectToReturn = 'string'; break;
      case priceTags.harvest: objectToReturn = this.dateC.getHarvest(); break;
      default: return '';
    }

    return objectToReturn;
  }

  setValue(historical: Historical, object: any) {
    if(object){
      if(object=='number'){
        return historical.valorI;
      } else if(object=='string'){
        return historical.valorS? historical.valorS: historical.valorI;
      } else if(object=='boolean'){
        return historical.valorI<=1? 'Si':'No';
      } else if(object==priceTags.deliveryPeriod){
        const copy:any=JSON.parse(JSON.stringify(historical.valorI))
        return copy[0]? copy[0].Value:'No definido' + ' - ' + copy[1]? copy[1].Value: 'No definido';
      } else if(object==priceTags.wayPay){
        const copy:any=JSON.parse(JSON.stringify(historical.valorI))

        let wayPay:any={};
        copy.forEach( (item:any) => {
          wayPay[item.Key] = item.Value;
        });
      
        return getWayPayText(wayPay);
      } else {
        return object[historical.valorI];
      }
    } else {
      return ''
    }
  }

}
