import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersValidationComponent } from './orders-validation.component';
import { HeaderModule } from 'src/app/components/header/header.module';
import { HeaderInSectionModule } from 'src/app/components/header-in-section/header-in-section.module';
import { MenuModule } from 'src/app/components/menu/menu.module';
import { BodyOrdersValidationModule } from './body-orders-validation/body-orders-validation.module';
import { ModalModule } from 'src/app/components/dialogs/modal.module';

@NgModule({
  declarations: [
    OrdersValidationComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    HeaderInSectionModule,
    MenuModule,
    BodyOrdersValidationModule,
    ModalModule
  ], exports:[
    OrdersValidationComponent
  ]
})
export class OrdersValidationModule { }
