import { Action, createReducer, on } from "@ngrx/store";
import * as actions from '../actions/historical.action';
import { HistoricalArrayReducer, HistoricalReducer } from "src/app/shared/interfaces/historical.interface";
import { typeOfHistorical } from "src/app/shared/const/options";

export const initialState: HistoricalReducer = {
    date: '', //In RFC3339 format
    typeOfHistorical: typeOfHistorical.ORDER,
    startDate: '',
    endDate: ''
};

const _historicalReducer = createReducer(
    initialState,
    on(actions.setDateOfHistorical,  (state, action) => {
        return {
            ...state,
            date: action.date
        };
    }),

    on(actions.setDateOfHistoricalRangeDate,  (state, action) => {
        return {
            ...state,
            startDate: action.date1,
            endDate: action.date2
        };
    }),

    on(actions.setTypeOfHistorical,  (state, action) => {
        return {
            ...state,
            typeOfHistorical: action.typeOfHistorical
        };
    }),   
)

export function historicalReducer(state: any, action: Action) {
    return _historicalReducer(state, action);
}

//----------------------- HISTORICAL ARRAY REDUCER -----------------------//

export const initialStateHistoricalArrayReducer: HistoricalArrayReducer = {
    array: []
}

const _historicalArrayReducer = createReducer(
    initialStateHistoricalArrayReducer,
    
    on(actions.setArrayOfHistoricalSuccess,  (state, action) => {
        return {
            ...state,
            array: action.array
        };
    }),
)

export function historicalArrayReducer(state: any, action: Action) {
    return _historicalArrayReducer(state, action);
}