import { createAction,props } from "@ngrx/store";
import { ViewOrder, ViewOrderExchange, ViewPrice } from "src/app/shared/interfaces/views.interface";

export const searchViewInDB = createAction('[VIEW] Search View In DB',
    props<{ email:string, name:string }>());
export const searchViewsSuccess = createAction('[VIEW] Search Views Success');
export const searchViewsFailed = createAction('[VIEW] Search Views Failed',
    props<{ message:string }>());

export const updateViewDB= createAction('[VIEW] Send Views DB',
    props<{ email:string, name:string,views:ViewOrder[] | ViewPrice[] | ViewOrderExchange[] }>());

export const saveViewDBSuccess= createAction('[VIEW] Send View DB Success');
export const saveViewDBFailed= createAction('[VIEW] Send View DB Failed',
    props<{ message:string }>());