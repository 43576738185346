<div class="table table-desktop" #table [style]="getMinHeight(table)">
    <table class="table table-responsive" [ngClass]="{'is-to-fix': conditionTypeSelected==CONDITION_TYPES.TO_FIX}">
        <thead> 
            <tr row-head [viewSelected]="viewSelected" [showCheckbox]="true" [isCheckedAll]="isCheckedAll" [conditionTypeSelected]="conditionTypeSelected"
            [filter]="orderFilter" [filterIdentificator]="filterIdentificator" 
            [showBuyer]="buyerType!=buyerTypes.COMMODITIES"
            (filterSelect)="selectFilter($event)" (actions)="selectAction($event)"></tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let order of ordersWithPipes | filterByRepresentation: isRepresentation:user | sortOrder: isComercial; index as i">
            
                <tr app-row [ngClass]="{'corredora-1888': order.broke==2, 'is-new': getIsNew(order),
                'is-open': getArrowDirection(order._id)=='up' || isIdInArrayParent(order._id)}" 
                (dblclick)="reingresateOrder(order);" [order]="order" [viewSelected]="viewSelected" 
                [showBuyer]="buyerType!=buyerTypes.COMMODITIES"
                [conditionTypeSelected]="conditionTypeSelected"
                [optionsMenu]="getOptionsMenu(order)" [showCheckbox]="true" [isChecked]="isOrderInOrderList(order)"
                [isHaveIndicator]="haveIndicators(order)" [directionArrow]="getArrowDirection(order._id)"
                (arrow)="clickInArrow($event.order, $event.event)" (optionMenu)="selectOptionMenu($event.event, $event.order)"></tr>

            <tr class="indicators" *ngIf="haveIndicators(order) && isIdInArrayParent(order._id)">
                <td class="first-col-indicators"></td>
            
                <td colspan="14">
                    <div class="indicators-container">
                        <app-indicators-table [price]="order.price" [showFirstColumn]="false"></app-indicators-table>
                    </div>
                </td>
            </tr>
        </ng-container> 
        </tbody>
    </table>
</div>