import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setLocation } from 'src/app/redux/actions/options.action';
import { NAVEGATION } from 'src/app/shared/const/navegation';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { MaestrosService } from 'src/app/shared/services/mestros.service';
import { SessionChecker } from 'src/app/shared/session.checker';
import { TablesIBMClass } from 'src/app/shared/tables-ibm';

@Component({
  selector: 'app-generate-exchange',
  templateUrl: './generate-exchange.component.html',
  styleUrls: ['./../../shared/styles/body.scss']
})
export class GenerateExchangeComponent extends TablesIBMClass implements OnInit {

  constructor(private store:Store<appState>, private checker:SessionChecker, maestrosSvc:MaestrosService) { 
    super(maestrosSvc);
    this.checker.rememberAndActualizateUser(this.store);
    this.store.dispatch(setLocation({location: NAVEGATION.GENERATE_EXCHANGE}));
  }
  ngOnInit(): void {
    this.checkTables();
  }
}