import { HostListener, Component, Output, EventEmitter, Input,OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setFilterIndex, setOrdersSelected } from 'src/app/redux/actions/order.action';
import { areas, REPRESENTATION_CONST_ARRAY } from 'src/app/shared/const/user.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Order, OrderString } from 'src/app/shared/interfaces/order.interface';
import * as dict from 'src/app/shared/dict/dict';
import { ViewOrderExchange } from 'src/app/shared/interfaces/views.interface';
import { orderOriginConst } from 'src/app/shared/const/orders';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateChecker } from 'src/app/shared/date.checker';
import { FilterOrderByCommoditiesPipe } from 'src/app/shared/pipes/filter/filterByBuyerType.pipe';
import { FilterByOrderPipe } from 'src/app/shared/pipes/filter/filter.by.order.pipe';
import { FilterOrderWithStringPipe } from 'src/app/shared/pipes/filter/filterWithString.pipe';
import { OrderValidator } from 'src/app/shared/order';
import { PRODUCT_NAMES } from 'src/app/shared/dict/productName';
import { teams } from 'src/app/shared/dict/user';
import { User } from 'src/app/shared/interfaces/user.interface';
import { ExchangeBoard, OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { EXCHANGER_STATES } from 'src/app/shared/dict/exchange';
import { setOrderExchangeDetails, showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { OrderExchangeSortPipe } from 'src/app/shared/pipes/sorts/ordersExchangeSort.pipe';
import { OBJECT_ID_VOID, setViewMobile } from 'src/app/shared/const/options';
import { EXCHANGE_STATUS_CONST } from 'src/app/shared/const/exchange-orders';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-exchange-table',
  templateUrl: './exchange-table.component.html',
  styleUrls: ['./exchange-table.component.scss']
})
export class ExchangeTableComponent implements OnInit, OnDestroy {
  user!: User;
  @Input() reload:boolean=false;
  //Array of products
  orders: OrderExchange[] = [];
  ordersWithPipe: OrderExchange[] = [];
  public parentsObject: any = {};
  public childsObject: any= {};
  public arrayOfShowParents:any =[];

  orderBoard!: ExchangeBoard;
  isComercial: boolean = true;
  isRepresentation: boolean = true;
  isViewMobile = true;

  //Other filter of tabla
  orderFilter!: OrderString;
  buyerType!: string;
  search: string = '';
  conditionTypeSelected:string='';
  conditionFilters:number[]=[];

  //Arrays
  EXCHANGE_STATES: any = [];
  TYPE_COIN: any = [];
  PRODUCTS: any = [];
  SELLERS: any = [];
  TABLES: any = [];

  ORDER_ORIGIN_CONST: any = orderOriginConst;

  //MOBILE
  EXCHANGE_STATUS_CONST = EXCHANGE_STATUS_CONST;
  exchangeStatusSelected: number = this.EXCHANGE_STATUS_CONST.VERIFICAR;

  //The column selected to apply a filter
  filterIdentificator: number = -1;
  optionIdentificator: number = -1;

  //The columns that will be seen in the table
  viewSelected!: ViewOrderExchange;

  isCheckedAll: boolean = false;
  ordersSelected: OrderExchange[] = [];
  isOrderReturned: boolean = false;

  public wasInside: boolean = false; //Inside in menu option;

  private search$!:Subscription;
  private exchange$!:Subscription;
  private sizeView$!:Subscription;
  private user$!:Subscription;
  private conditionType$!:Subscription;
  private conditionFilters$!:Subscription;

  constructor(public store: Store<appState>, public router: Router, public dialog: MatDialog, 
    public date: DateChecker, public orderV: OrderValidator) {}

  ngOnInit(): void {
    this.setArrays();
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.exchange$?.unsubscribe();
    this.sizeView$?.unsubscribe();
    this.user$?.unsubscribe();
    this.search$?.unsubscribe();
    this.conditionType$?.unsubscribe();
    this.conditionFilters$?.unsubscribe();
  }

  setSubscriptions(){
    this.exchange$=this.store.select('exchange').subscribe(order=> {
      //this.historical=order.historical;
      this.orderBoard = order.exchangeBoard;
      this.viewSelected=order.exchangeBoard.viewsOfExchangeBoard.viewSelected;

      //Filter
      this.filterIdentificator = order.exchangeBoard.indexFilter;
      this.orderFilter = order.exchangeBoard.filter;
      //this.buyerType = order.exchangeBoard.buyerType;
      this.ordersSelected=order.exchangeBoard.ordersSelected;
      //this.buyerTypeSelected=order.exchangeBoard.buyerType;

      if(this.orders != order.exchangeBoard.all){
        this.orders = order.exchangeBoard.all;
        this.ordersWithPipe= this.getOrdExcListWithPipesAndSort();
        this.setArrayParentsAndChilds();
      }
    })

    this.sizeView$=this.store.select('options', 'sizeView').subscribe(sizeView => {
      this.isViewMobile= setViewMobile(sizeView);
    })

    this.conditionType$=this.store.select('options', 'board','conditionTypeSelected').subscribe(conditionType => {
      this.conditionTypeSelected = conditionType;
    });

    this.search$=this.store.select('options', 'board','search').subscribe(search=>{
      this.search = search;
    });

    this.conditionFilters$=this.store.select('options','board','conditionFilter').subscribe((conditionFilters)=>{
      this.conditionFilters=conditionFilters;
    })

    this.user$=this.store.select('usuario').subscribe(state => {
      this.user = state;
      this.isComercial = state.area == areas.comercial;
      this.isRepresentation = REPRESENTATION_CONST_ARRAY.includes(state.workUnit);
    })
  }

  setArrays() {
    this.TYPE_COIN = dict.typeCoin;
    this.PRODUCTS = PRODUCT_NAMES;
    this.EXCHANGE_STATES= EXCHANGER_STATES;
    this.SELLERS = sellerWithData;
    this.TABLES = teams;
  }

  isOrderInOrderList(order: Order): boolean {
    return this.orderV.isOrderInOrderList(order, this.ordersSelected);
  }

  //It is a different function for business users and operators.
  selectFilter(index: number) {
    if (index == this.filterIdentificator) {
      this.closeAllFilters();
    } else {
      this.store.dispatch(setFilterIndex({ index: index }));
    }
  }

  closeAllFilters() {
    this.store.dispatch(setFilterIndex({ index: -1 }));
  }

  getIsNew(order: Order): boolean {
    return order.dataOfcreation.hour == this.date.getHour();
  }

  selectOrder(order: OrderExchange) {
    if (this.isCheckedAll) {
      this.isCheckedAll = false;
    }
    const positionInOrderList = this.ordersSelected.indexOf(order);
    const ordersSelectedAux = [...this.ordersSelected];
    //Is element in array
    if (positionInOrderList != -1) {
      ordersSelectedAux.splice(positionInOrderList, 1);
    } else {
      ordersSelectedAux.push(order);
    }

    this.isCheckedAll = ordersSelectedAux.length == this.orders.length;
    this.store.dispatch(setOrdersSelected({ orders: ordersSelectedAux }));
  }


  showOrderDetail(order: OrderExchange) {
    this.store.dispatch(setOrderExchangeDetails({ order: order }));
    this.store.dispatch(showOrderExchangeDetails({ show: true }));
  }

  //-----------------------------------------------\\

  //MENU - OPTIONS
  openOptions(index: number) {
    if (index == this.optionIdentificator) {
      this.closeOptions();
    } else {
      this.optionIdentificator = index;
      this.wasInside = true;
    }
  }

  closeOptions() {
    this.openOptions(-1);
    this.wasInside = false;
  }

  @HostListener('document:click', ['$event']) clickInside(event: any) {
    try {
      const classNames: string = event.target.classList.value;
      if (classNames.length > 0) {
        if (!this.wasInside && !classNames.includes('options')) {
          this.optionIdentificator = -1;
        }
      }
    } catch (err) {
      console.error('Esta es el error', err);
    }
    this.wasInside = false;
  }

  getMessage(buyer: any) {
    return this.orderV.getRestriction(buyer);
  }


  /* FILTERS, SELECTS and CHECKED */
  checkedAllItems() {
    if (this.isCheckedAll) {
      this.store.dispatch(setOrdersSelected({ orders: [] }))
      this.isCheckedAll = false;
    } else {
      this.store.dispatch(setOrdersSelected({ orders: this.getOrderListWithPipes() }))
      this.isCheckedAll = true;
    }
  }

  getOrderListWithPipes() {
    let newPricesList = JSON.parse(JSON.stringify(this.orders));
    const pipe1 = new FilterOrderByCommoditiesPipe();
    const pipe2 = new FilterByOrderPipe();
    const pipe3 = new FilterOrderWithStringPipe();
    return pipe3.transform(pipe2.transform(pipe1.transform(newPricesList, this.buyerType), this.orderFilter), this.search)
  }

  getCheckedAll() {
    return JSON.stringify(this.getOrderListWithPipes()) == JSON.stringify(this.ordersSelected);
  }

  getIsFilter(tag: string, subTag: string) {
    try {
      const newOrderFilter = JSON.parse(JSON.stringify(this.orderFilter));
      let field: any = '';
      if (tag && subTag) {
        field = newOrderFilter[tag][subTag];
      } else {
        field = newOrderFilter[tag];
      }
      if (Array.isArray(field) || typeof field == 'string') {
        return field.length > 0; //If it is array or string, we see if its length is greater than 0
      } else if (typeof field == 'number') {
        return field > 0; //If it is a number, we see if it is greater than 0
      } else if (subTag == 'deliveryPeriod') {
        return field.startDate.length > 2 || field.endDate.length > 2;
      } else if (subTag == 'placeOfDelivery') {
        return field.zone.length > 0
      } else if (subTag == 'price') {
        return field.start > 0 || field.end > 0 || field.typeCoin != 'ALL';
      } else if (tag == 'tons') {
        return field.start > 0 || field.end > 0;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }


  @Output() actionEraser = new EventEmitter<any>(); //Emit to parent component
  deleteOrder(order: Order) {
    const respon: any = {
      showModal: true,
      title: "¿Está seguro que desea eliminar la orden?",
      action: 'delete',
      order: order
    }
    this.actionEraser.emit(respon); //Emite la respuesta
  }

  sendOrder(order:Order){
    const respon: any = {
      showModal: true,
      title: "¿Está seguro que desea enviar la orden al operador?",
      action: 'send',
      order: order
    }
    this.actionEraser.emit(respon); //Emite la respuesta
  }

  emitAction(event:any){
   this.actionEraser.emit(event);
  }
   
     //---------------------- ARRAY ----------------------\\
  setArrayParentsAndChilds(){
    let parentsObject:any={};
    let childsObject:any={};
    const quantOrders=this.ordersWithPipe.length - 1; //Fpr access to the last with +1
    for(let i=0; i<quantOrders; i++){
      const order1=this.ordersWithPipe[i]; //Get order
      const order2=this.ordersWithPipe[i+1]; //Get order
      if(this.isTheSameIdMother(order1,order2)){ //If the header is the same
        const isChildId=this.isChildWithParentObject(order1, parentsObject); //If is child, add in the parent id, if is not child, add in the price1
        let indexToSave:any;
        //Add in the child object
        if(isChildId){
          childsObject[order2._id]=isChildId;
          indexToSave=isChildId;
        } else {
          childsObject[order2._id]=order1._id;
          indexToSave=order1._id;
        }
        const arrayOfChilds=parentsObject[indexToSave]; //Get array of childs
        //If a value exists, a new element is added to the array, if nothing exists, it adds it
        if(arrayOfChilds){
          parentsObject[indexToSave].push(order2._id); //
        } else {
          parentsObject[indexToSave]=[order2._id];
        }
      }
    }

    this.parentsObject=parentsObject;
    this.childsObject=childsObject;
    this.arrayOfShowParents=Object.keys(parentsObject);
  }


  //Parent function
  isParent(order: OrderExchange) {
    try{
      if(this.parentsObject[order._id]){
        return true;
      } else {
        return false;
      }
    } catch(err){
      return false;
    }
  }

  isChild(order: OrderExchange){
    try{
      if(this.childsObject[order._id]){
        return true;
      } else {
        return false;
      }
    } catch(err){
      return false;
    }
  }

  isChildWithParentObject(order: OrderExchange, parentsObject:any){
    let idToReturn:string='';
    try{
      const values:any[]=Object.values(parentsObject);
      for(let i=0; i<values.length; i++){
        const array=values[i];
        if(array.includes(order._id)){
          idToReturn=Object.keys(parentsObject)[i];
          throw new Error();
        }
      }
      return idToReturn;
    } catch(err){
      return idToReturn;
    }
  }


  //Verifica si el precio es variante del anterior
  isVariant(index:number){
    if(index!=0){
      const order1=this.ordersWithPipe[index];
      const order2=this.ordersWithPipe[index-1];
      if(order1 && order2){
        return this.isTheSameIdMother(order1,order2);
      }
    }
    return false;
  }


  //Devuelve si dos ordersExchange tienen el mismo idMother
  isTheSameIdMother(order1:OrderExchange, order2:OrderExchange):boolean{
    try{
      if(order1.exchange.idMother != OBJECT_ID_VOID){
        return order1.exchange.idMother == order2.exchange.idMother;
      }
      return false;
    } catch(err){
      return false;
    }
  }

  isDeferred(index:number){

  }

  parentShowInDropdown(order:OrderExchange, index:number){
    //La función solo es llamada por prices que son Child
    //Get _id of parent
    const parentId=this.childsObject[order._id];    
    return this.isIdInArrayParent(parentId);
  }


  dropDown(id:string){
    if( this.isIdInArrayParent(id) ){
      this.arrayOfShowParents.splice(this.arrayOfShowParents.indexOf(id),1);
    } else {
      this.arrayOfShowParents.push(id);
    }
  }

  isIdInArrayParent(id:string){
    return this.arrayOfShowParents.indexOf(id) != -1;
  }

  getArrowDirection(id:string){
    if( this.isIdInArrayParent(id)){
      return 'down';
    } else {
      return 'right';
    }
  }

   getOrdExcListWithPipesAndSort(){
    let newPricesList= JSON.parse(JSON.stringify(this.orders));
    
    const pipe1= new FilterOrderByCommoditiesPipe();
    const pipe2= new FilterByOrderPipe();
    const pipe3= new FilterOrderWithStringPipe();
    const pipe4= new OrderExchangeSortPipe();
    return pipe4.transform(pipe3.transform(pipe2.transform(pipe1.transform(newPricesList, this.buyerType), this.orderFilter), this.search));
  }


  setExchangeStatusSelected(status:number){
    this.exchangeStatusSelected=status;
  }

  getStatusString(status:number){
    switch(status){
      case this.EXCHANGE_STATUS_CONST.VERIFICAR:
        return 'verificar';
      case this.EXCHANGE_STATUS_CONST.INCOMPLETO:
        return 'incompleto';
      case this.EXCHANGE_STATUS_CONST.COMPLETA:
        return 'completo';
      default:
        return '';
  }
}
}
