import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsManyOrdersComponent } from './details-many-orders.component';
import { InfoToFixModule } from '../info/info-to-fix/info-to-fix.module';
import { InfoOrderModule } from '../info/info-order/info-order.module';
import { DuplicateOrderCollectionSvgModule } from 'src/app/shared/styles/svg/duplicate-orders/duplicate-order.module';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CommissionModule } from '../../cards/commission/commission.module';
import { DataModule } from '../data/data.module';

@NgModule({
  declarations: [
    DetailsManyOrdersComponent
  ],
  imports: [
    CommonModule,
    DataModule,
    CommissionModule,
    PipesModule,
    ArrowSvgModule,
    DuplicateOrderCollectionSvgModule,
    InfoOrderModule,
    InfoToFixModule,
  ], exports:[
    DetailsManyOrdersComponent
  ]
})
export class DetailsManyOrdersModule { }