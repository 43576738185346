import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsToFixComponent } from './buttons-to-fix.component';



@NgModule({
  declarations: [
    ButtonsToFixComponent
  ],
  imports: [
    CommonModule
  ], exports:[
    ButtonsToFixComponent
  ]
})
export class ButtonsToFixModule { }
