<div class="field">
    <span class="field-title">Monto<span class="asterisk">*</span></span>

    <number-input [initialPlaceHolder]="'Ingresar monto'" [value]="amount.quantity" 
    (valueOut)="setMontAmount($event)"></number-input>
</div>

<div class="field">
    <span class="field-title">Moneda<span class="asterisk">*</span></span>

    <radio-button-input [options]="getOptions()" (valueOut)="setTypeCoinAmount($event)"
    [value]="amount.typeCoin"></radio-button-input>
</div>
