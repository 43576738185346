<section class="body">
    
    <div class="header-section">
        <div class="downs">
            <app-buttons-condition-type></app-buttons-condition-type>
            <app-views-section-order></app-views-section-order>
        </div>
      
        <div class="call-to-actions">
            <div class="buttons-to-actions">
                <app-search-input class="search"></app-search-input>
            </div>

            <div class="description">                 
                <span class="quant">Cantidad de borradores: 
                    <span class="stroke">{{(orders | filterOrderByConditionType:conditionTypeSelected:conditionFilters).length }}</span>
                </span>
                <span class="quant">Cantidad de toneladas: 
                    <span class="stroke">{{getQuantOfTons( orders | filterOrderByConditionType:conditionTypeSelected:conditionFilters )}}</span>
                </span>
            </div>
        </div>
    </div>

    <eraser-table class="table" (actionEraser)="setModal($event)" [reload]="isReload"></eraser-table>

    <!-- Slide Cards -->
    <div [ngClass]="{'background opaque': isShowDetails }" (click)="closeSlide()"></div>
    <app-order-eye class="slide-card slide-card-visible" *ngIf="isShowDetails" [order]="orderDetails"></app-order-eye>
</section>
<app-dialog-yes-or-no [showModal]="dataModal.showModal" *ngIf="dataModal.showModal" [title]="dataModal.title" (response)="applyAction($event)"></app-dialog-yes-or-no>
