import { Component, EventEmitter, Input, Output } from '@angular/core';
import { buyerType } from 'src/app/shared/const/options';

@Component({
  selector: 'app-buttons-buyer-type',
  templateUrl: './buttons-buyer-type.component.html',
  styleUrls: ['./buttons-buyer-type.component.scss']
})
export class ButtonsBuyerTypeComponent {
  @Output() selected= new EventEmitter<string>();
  @Input() buyerTypeSelected:string='';
  buyerTypes=buyerType;
  constructor() { }

  setBuyerType(buyerType:string){
    this.selected.emit(buyerType);
  }

}
