import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';

import { LottieModule } from 'ngx-lottie';
import * as player from 'lottie-web';

export function playerFactory() {
  return player.default;
}

@NgModule({
  declarations: [
    LoadingComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    LoadingComponent
  ]
})
export class LoadingLottieModule { }
