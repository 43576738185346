import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoSvgComponent } from './info-svg.component';

@NgModule({
  declarations: [
    InfoSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    InfoSvgComponent
  ]
})
export class InfoSvgModule { }