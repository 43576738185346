export const chubutLocalities=[
    {"afip":"19","location":"28 De Julio"},
    {"afip":"179","location":"Aguada De Las Tejas"},
    {"afip":"185","location":"Aguada Del Pito"},
    {"afip":"267","location":"Aldea Apeleg"},
    {"afip":"269","location":"Aldea Beleiro"},
    {"afip":"274","location":"Aldea Escolar"},
    {"afip":"412","location":"Alto De Las Plumas"},
    {"afip":"461","location":"Alto Rio Mayo"},
    {"afip":"462","location":"Alto Rio Pico"},
    {"afip":"463","location":"Alto Rio Senguer"},
    {"afip":"579","location":"Angostura Segunda"},
    {"afip":"674","location":"Arenoso"},
    {"afip":"749","location":"Arroyo Chalia"},
    {"afip":"778","location":"Arroyo Gato"},
    {"afip":"781","location":"Arroyo Guilaia"},
    {"afip":"832","location":"Arroyo Percy"},
    {"afip":"837","location":"Arroyo Pescado"},
    {"afip":"844","location":"Arroyo Quilla"},
    {"afip":"878","location":"Arroyo Verde"},
    {"afip":"982","location":"Bahia Bustamante"},
    {"afip":"983","location":"Bahia Cracher"},
    {"afip":"989","location":"Bahia Solano"},
    {"afip":"1007","location":"Bajada Del Diablo"},
    {"afip":"1013","location":"Bajada Moreno"},
    {"afip":"1017","location":"Bajo Bartolo"},
    {"afip":"1028","location":"Bajo De Los Huesos"},
    {"afip":"1034","location":"Bajo Del Gualicho"},
    {"afip":"1045","location":"Bajo La Cancha"},
    {"afip":"1047","location":"Bajo Las Damajuanas"},
    {"afip":"1225","location":"Barrio Astra"},
    {"afip":"1347","location":"Base Aeronaval Almirante Zar"},
    {"afip":"1477","location":"Betesta"},
    {"afip":"1506","location":"Boca De La Zanja"},
    {"afip":"1513","location":"Boca Zanja Sud"},
    {"afip":"1583","location":"Bryn Brown"},
    {"afip":"1584","location":"Bryn Gwyn"},
    {"afip":"1588","location":"Buen Pasto"},
    {"afip":"1600","location":"Buenos Aires Chico"},
    {"afip":"1636","location":"Cabaña Del Valle"}
    ,
    {"afip":"1657","location":"Cabo Raso"},
    {"afip":"1670","location":"Cachel"},
    {"afip":"1715","location":"Cajon De Ginebra Chico"},
    {"afip":"1716","location":"Cajon De Ginebra Grande"},
    {"afip":"1749","location":"Caleta Cordova"},
    {"afip":"1751","location":"Caleta Valdez"},
    {"afip":"1781","location":"Camarones"},
    {"afip":"1821","location":"Campamento Villegas"},
    {"afip":"2179","location":"Carhue Niyeo"},
    {"afip":"2213","location":"Carrenleufu"},
    {"afip":"2247","location":"Casa Blanca"},
    {"afip":"2322","location":"Cataico"},
    {"afip":"2366","location":"Cañada Bagual"}
    ,
    {"afip":"2444","location":"Cañadon Bagual"}
    ,
    {"afip":"2445","location":"Cañadon Blanco"}
    ,
    {"afip":"2447","location":"Cañadon Caliente"}
    ,
    {"afip":"2449","location":"Cañadon Carril"}
    ,
    {"afip":"2450","location":"Cañadon Chacay"}
    ,
    {"afip":"2451","location":"Cañadon Chileno"}
    ,
    {"afip":"2462","location":"Cañadon Ferrais"}
    ,
    {"afip":"2463","location":"Cañadon Grande"}
    ,
    {"afip":"2464","location":"Cañadon La Madera"}
    ,
    {"afip":"2465","location":"Cañadon Lagarto"}
    ,
    {"afip":"2467","location":"Cañadon Lopez"}
    ,
    {"afip":"2469","location":"Cañadon Pedro Ex Valle Hermoso"}
    ,
    {"afip":"2472","location":"Cañadon Tacho"}
    ,
    {"afip":"2563","location":"Cerro Centinela"},
    {"afip":"2573","location":"Cerro Condor"},
    {"afip":"2627","location":"Cerro Fofocahuel"},
    {"afip":"2669","location":"Cerro Lonco Trapial"},
    {"afip":"2677","location":"Cerro Mallaco"},
    {"afip":"2705","location":"Cerro Pichalao"},
    {"afip":"2720","location":"Cerro Radal"},
    {"afip":"2727","location":"Cerro Santa Ana"},
    {"afip":"2773","location":"Chacay Este"},
    {"afip":"2776","location":"Chacay Oeste"},
    {"afip":"2787","location":"Chacra De Austin"},
    {"afip":"2860","location":"Charque Chico"},
    {"afip":"2989","location":"Cholila"},
    {"afip":"3119","location":"Colanconhue"},
    {"afip":"3128","location":"Colelache"},
    {"afip":"3130","location":"Colhue Huapi"},
    {"afip":"3158","location":"Colonia 16 De Octubre"},
    {"afip":"3330","location":"Colonia Cushamen"},
    {"afip":"3419","location":"Colonia Epulief"},
    {"afip":"3463","location":"Colonia Germania"},
    {"afip":"4000","location":"Comodoro Rivadavia"},
    {"afip":"4059","location":"Corcovado"},
    {"afip":"4178","location":"Costa Chubut"},
    {"afip":"4187","location":"Costa Del Lepa"},
    {"afip":"4207","location":"Costa Rio Chico"},
    {"afip":"4366","location":"Cushamen"},
    {"afip":"4455","location":"Dique Florentino Ameghino"},
    {"afip":"4498","location":"Doctor Ricardo Rojas"},
    {"afip":"4500","location":"Dolavon"},
    {"afip":"4555","location":"Dos Pozos"},
    {"afip":"4593","location":"Ebenecer"},
    {"afip":"4629","location":"El Alamo"},
    {"afip":"4661","location":"El Argentino"},
    {"afip":"4709","location":"El Boquete"},
    {"afip":"4739","location":"El Calafate"},
    {"afip":"4753","location":"El Canquel"},
    {"afip":"4813","location":"El Chacay  Dpto Gastre"},
    {"afip":"4818","location":"El Chalet"},
    {"afip":"4832","location":"El Cherque"},
    {"afip":"4866","location":"El Coihue"},
    {"afip":"4889","location":"El Coyte"},
    {"afip":"4895","location":"El Cronometro"},
    {"afip":"4903","location":"El Cuche"},
    {"afip":"4915","location":"El Desempeño"}
    ,
    {"afip":"4947","location":"El Escorial"},
    {"afip":"5010","location":"El Hoyo"},
    {"afip":"5039","location":"El Kaquel"},
    {"afip":"5060","location":"El Maiten"},
    {"afip":"5159","location":"El Pajarito"},
    {"afip":"5191","location":"El Pastizal"},
    {"afip":"5239","location":"El Piquillin"},
    {"afip":"5274","location":"El Poyo"},
    {"afip":"5297","location":"El Quilimuay"},
    {"afip":"5342","location":"El Ruano"},
    {"afip":"5347","location":"El Salitral"},
    {"afip":"5369","location":"El Shaman"},
    {"afip":"5385","location":"El Sombrero"},
    {"afip":"5449","location":"El Triana"},
    {"afip":"5456","location":"El Tropezon"},
    {"afip":"5556","location":"Empalme A Astra"},
    {"afip":"5566","location":"Empalme Puerto Lobos"},
    {"afip":"5583","location":"Enrique Hermitte"},
    {"afip":"5596","location":"Epuyen"},
    {"afip":"5634","location":"Esquel"},
    {"afip":"5802","location":"Estancia El Moro"},
    {"afip":"5860","location":"Estancia La Mimosa"},
    {"afip":"5929","location":"Estancia Pampa Chica"},
    {"afip":"6049","location":"Facundo"},
    {"afip":"6157","location":"Fithen Verin"},
    {"afip":"6158","location":"Fitirhuin"},
    {"afip":"6234","location":"Fotocahuel"},
    {"afip":"6272","location":"Frontera De Rio Pico"},
    {"afip":"6290","location":"Futaleufu"},
    {"afip":"6295","location":"Gaiman"},
    {"afip":"6309","location":"Gan Gan"},
    {"afip":"6318","location":"Garayalde"},
    {"afip":"6343","location":"Gastre"},
    {"afip":"6441","location":"Glasfryn"},
    {"afip":"6570","location":"Gualjaina"},
    {"afip":"6727","location":"Hito 43"},
    {"afip":"6728","location":"Hito 45"},
    {"afip":"6729","location":"Hito 50"},
    {"afip":"6733","location":"Holdich"},
    {"afip":"6908","location":"Ing Bruno J Thomae"},
    {"afip":"7163","location":"Jose De San Martin"},
    {"afip":"7382","location":"La Bombilla"},
    {"afip":"7414","location":"La Cancha"},
    {"afip":"7438","location":"La Castellana"},
    {"afip":"7526","location":"La Corona"},
    {"afip":"7758","location":"La Lancha"},
    {"afip":"7764","location":"La Laurita"},
    {"afip":"7876","location":"La Nicolasa"},
    {"afip":"7942","location":"La Pepita"},
    {"afip":"8065","location":"La Rosilla"},
    {"afip":"8076","location":"La Salamanca"},
    {"afip":"8097","location":"La Siberia"},
    {"afip":"8223","location":"Lago Blanco"},
    {"afip":"8226","location":"Lago Carlos Pellegrini"},
    {"afip":"8228","location":"Lago Fontana"},
    {"afip":"8230","location":"Lago Lezama"},
    {"afip":"8232","location":"Lago Musters"},
    {"afip":"8234","location":"Lago Paz"},
    {"afip":"8237","location":"Lago Puelo"},
    {"afip":"8239","location":"Lago Rivadavia"},
    {"afip":"8241","location":"Lago Rosario"},
    {"afip":"8245","location":"Lago Verde"},
    {"afip":"8246","location":"Lago Vintter"},
    {"afip":"8270","location":"Laguna De Vacas"},
    {"afip":"8273","location":"Laguna Del Mate"},
    {"afip":"8280","location":"Laguna Fria"},
    {"afip":"8283","location":"Laguna Grande"},
    {"afip":"8297","location":"Laguna Palacio"},
    {"afip":"8301","location":"Laguna Rincon Del Moro"},
    {"afip":"8308","location":"Laguna Terraplen"},
    {"afip":"8310","location":"Laguna Verde"},
    {"afip":"8321","location":"Lagunita Salada"},
    {"afip":"8332","location":"Languiñeo"}
    ,
    {"afip":"8347","location":"Larralde"},
    {"afip":"8435","location":"Las Chapas"},
    {"afip":"8516","location":"Las Horquetas"},
    {"afip":"8625","location":"Las Plumas"},
    {"afip":"8630","location":"Las Pulgas"},
    {"afip":"8644","location":"Las Salinas"},
    {"afip":"8750","location":"Legua 24"},
    {"afip":"8755","location":"Leleque"},
    {"afip":"8756","location":"Lenzanilleo"},
    {"afip":"8881","location":"Loma Redonda"},
    {"afip":"8937","location":"Loreto"},
    {"afip":"8962","location":"Los Altares"},
    {"afip":"9060","location":"Los Cipreses"},
    {"afip":"9086","location":"Los Corralitos"},
    {"afip":"9183","location":"Los Manantiales"},
    {"afip":"9348","location":"Los Tamariscos"},
    {"afip":"9490","location":"Maesteg"},
    {"afip":"9525","location":"Malaspina"},
    {"afip":"9540","location":"Mallin Blanco"},
    {"afip":"9547","location":"Mallin Grande"},
    {"afip":"9548","location":"Mallin Grande Corcovado"},
    {"afip":"9568","location":"Manantial Grande"},
    {"afip":"9720","location":"Mata Grande"},
    {"afip":"9742","location":"Matucana"},
    {"afip":"9759","location":"Mayoco"},
    {"afip":"10167","location":"Nahuel Pan Estacion Fcgr"},
    {"afip":"10243","location":"Niriguao"},
    {"afip":"10244","location":"Niriguce Pampa"},
    {"afip":"10292","location":"Nueva Lubecka"},
    {"afip":"10466","location":"Painaluf"},
    {"afip":"10566","location":"Pampa De Agnia"},
    {"afip":"10578","location":"Pampa Del Castillo"},
    {"afip":"10621","location":"Pampa Pelada"},
    {"afip":"10628","location":"Pampa Salamanca"},
    {"afip":"10631","location":"Pampa Tepuel"},
    {"afip":"10756","location":"Parque Nacional Los Alerces"},
    {"afip":"10796","location":"Paso De Indios"},
    {"afip":"10831","location":"Paso De Torres"},
    {"afip":"10851","location":"Paso Del Sapo"},
    {"afip":"10883","location":"Paso Moreno"},
    {"afip":"10924","location":"Pastos Blancos"},
    {"afip":"11072","location":"Pico Salamanca"},
    {"afip":"11101","location":"Piedra Parada"},
    {"afip":"11105","location":"Piedra Shotel"},
    {"afip":"11180","location":"Pirre Mahuida"},
    {"afip":"11202","location":"Plancuntre"},
    {"afip":"11216","location":"Playa Union"},
    {"afip":"11271","location":"Pocitos De Quichaura"},
    {"afip":"11688","location":"Puente Hendre"},
    {"afip":"11819","location":"Puerto Lobos"},
    {"afip":"11824","location":"Puerto Madryn"},
    {"afip":"11847","location":"Puerto Piramides"},
    {"afip":"11867","location":"Puerto San Roman"},
    {"afip":"12118","location":"Punta Bajos"},
    {"afip":"12144","location":"Punta Delgada"},
    {"afip":"12154","location":"Punta Ninfas"},
    {"afip":"12155","location":"Punta Norte"},
    {"afip":"12159","location":"Punta Quiroga"},
    {"afip":"12174","location":"Putrachoique"},
    {"afip":"12290","location":"Rada Tilly"},
    {"afip":"12348","location":"Ranquil Huao"},
    {"afip":"12567","location":"Rio Corinto"},
    {"afip":"12591","location":"Rio Frias"},
    {"afip":"12603","location":"Rio Mayo"},
    {"afip":"12612","location":"Rio Pico"},
    {"afip":"12789","location":"Sacanana"},
    {"afip":"12846","location":"Salinas Grandes"},
    {"afip":"13326","location":"Sarmiento"},
    {"afip":"13410","location":"Seprucal"},
    {"afip":"13436","location":"Siempre Viva"},
    {"afip":"13441","location":"Sierra Chata"},
    {"afip":"13443","location":"Sierra Colorada"},
    {"afip":"13444","location":"Sierra Corrientes"},
    {"afip":"13445","location":"Sierra Cuadrada"},
    {"afip":"13456","location":"Sierra De Tecka"},
    {"afip":"13459","location":"Sierra Nevada Buen Pasto"},
    {"afip":"13460","location":"Sierra Nevada Paso De Indios"},
    {"afip":"13461","location":"Sierra Overa Chicas Y Grandes"},
    {"afip":"13464","location":"Sierra Rosada"},
    {"afip":"13465","location":"Sierra Victoria"},
    {"afip":"13592","location":"Sunica"},
    {"afip":"13706","location":"Talagapa"},
    {"afip":"13761","location":"Taquetren"},
    {"afip":"13789","location":"Tatuen"},
    {"afip":"13793","location":"Tecka"},
    {"afip":"13804","location":"Telsen"},
    {"afip":"13932","location":"Toma De Los Canales"},
    {"afip":"13959","location":"Toro Hosco"},
    {"afip":"14025","location":"Trelew"},
    {"afip":"14030","location":"Treorki"},
    {"afip":"14090","location":"Trevelin"},
    {"afip":"14226","location":"Uzcudun"},
    {"afip":"14259","location":"Valle Del Rio Chubut"},
    {"afip":"14261","location":"Valle Del Tecka"},
    {"afip":"14266","location":"Valle Frio"},
    {"afip":"14267","location":"Valle Garin"},
    {"afip":"14269","location":"Valle Hermoso"},
    {"afip":"14270","location":"Valle Hondo"},
    {"afip":"14271","location":"Valle Huemules"},
    {"afip":"14272","location":"Valle Los Martires"},
    {"afip":"14566","location":"Villa Futalaufquen"},
    {"afip":"14608","location":"Villa Ines"},
    {"afip":"15142","location":"Ñorquinco Sud"}
    ,
    {"afip":"15264","location":"Angostura"},
    {"afip":"15431","location":"Cabeza De Buey"},
    {"afip":"15592","location":"Cerro Negro"},
    {"afip":"15616","location":"Chasico"},
    {"afip":"15794","location":"Corralitos"},
    {"afip":"15931","location":"El Cajon"},
    {"afip":"16082","location":"El Jaguel"},
    {"afip":"16112","location":"El Mirador"},
    {"afip":"16118","location":"El Mirasol"},
    {"afip":"16139","location":"El Molle"},
    {"afip":"16201","location":"El Portezuelo"},
    {"afip":"16211","location":"El Porvenir"},
    {"afip":"16515","location":"Florentino Ameghino"},
    {"afip":"16548","location":"Gobernador Costa"},
    {"afip":"17195","location":"La Primavera"},
    {"afip":"17374","location":"Laguna Blanca"},
    {"afip":"17479","location":"Las Cortaderas"},
    {"afip":"17519","location":"Las Golondrinas"},
    {"afip":"17595","location":"Las Mulas"},
    {"afip":"17617","location":"Las Pampas"},
    {"afip":"18027","location":"Medanos"},
    {"afip":"18460","location":"Rawson"},
    {"afip":"18496","location":"Rio Chico"},
    {"afip":"18558","location":"Salinas Chicas"},
    {"afip":"18744","location":"San Jose"},
    {"afip":"19126","location":"Sierra Chica"},
    {"afip":"19139","location":"Sol De Mayo"},
    {"afip":"19246","location":"Tres Picos"},
    {"afip":"19374","location":"General Mosconi"},
    {"afip":"21418","location":"La Castellana"},
    {"afip":"21419","location":"Gualjaina"},
    {"afip":"21420","location":"La Cancha"},
    {"afip":"21421","location":"Casa Blanca"},
    {"afip":"21422","location":"Laguna De Vacas"}
    ]