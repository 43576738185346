import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpirationComponent } from './expiration.component';
import { DateInputModule } from '../../inputs/date/date.module';

@NgModule({
  declarations: [
    ExpirationComponent
  ],
  imports: [
    CommonModule,
    DateInputModule
  ], exports:[
    ExpirationComponent
  ]
})
export class ExpirationModule { }
