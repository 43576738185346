import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToFixIndicatorsComponent } from './to-fix-indicators.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BPSlideCardModule } from '../../slide-cards/business-particularities/business-particularities.module';
import { PlusSignGreenSvgModule } from 'src/app/shared/styles/svg/plus-sign-green/plus-sign-green.module';
import { IndicatorsSlideCardModule } from '../../slide-cards/to-fix-indicators/to-fix-indicators.module';
import { IndicatorsListModule } from '../../details/indicators-list/indicators-list.module';


@NgModule({
  declarations: [
    ToFixIndicatorsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    PlusSignGreenSvgModule,
    IndicatorsSlideCardModule,
    IndicatorsListModule
  ], exports: [
    ToFixIndicatorsComponent
  ]
})
export class ToFixIndicatorsModule { }
