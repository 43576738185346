import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyGreenSvgComponent } from './copy-green-svg.component';

@NgModule({
  declarations: [
    CopyGreenSvgComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    CopyGreenSvgComponent
  ]
})
export class CopyGreenSvgModule { }