<section class="body">
    <div class="tab" *ngIf="formVisible">
        <div class="rectangle {{isOrderSell}}"></div>
        <div class="tablinks {{isOrderSell}} order-sell" (click)="setIsOrderSell(true)">Orden de venta</div>
        <div class="tablinks {{!isOrderSell}} order-buy disabled" (click)="setIsOrderSell(true)">Orden de compra</div>
    </div>
    
    <div class="buttons row-between">
        <div class="radios-buttons" *ngIf="formVisible">
            <app-buttons-condition-type (valueOut)="setIsOrderToPrice($event)"></app-buttons-condition-type>
            
            <button class="view-details not-visible-in-desktop" (click)="showDetails(true)">Ver detalle</button>
        </div>

        <div class="buttons-options row-between not-visible-in-950">
            <div class="button-option commission" [ngClass]="{'no-enabled': comissionEnabled}" (click)="openCommission()">
                <!--[ngClass]="{'enabled': comissionEnabled}"-->
                <!--<commission-svg [active]="comissionEnabled"></commission-svg>--> 
                <commission-svg [active]="false"></commission-svg>
                <span class="option-text">Cambiar comisión</span>
                    <div class="tooltip commission-message">
                        <span>Presiona para modificar la comisión</span>
                    </div>
            </div>
            <!--<app-commission (close)="closeCommission($event)" [valueBuyer]="getCommisionBuyer(orderToGenerate)" 
            [valueSeller]="getCommisionSeller(orderToGenerate)"
            (commission)="setCommission($event)" *ngIf="isOpenCommission"
                    class="commission pop-up pop-up-visible"></app-commission>-->

            <div class="button-option duplicate-order" [ngClass]="{'enabled': duplicateOrderEnabled}" (click)="openDuplicateOrder()">
                <duplicate-order-svg [active]="duplicateOrderEnabled"></duplicate-order-svg> <span class="option-text">Duplicar orden</span>
                <div class="tooltip duplicate-message">
                    <span>Presiona para duplicar ordenes</span>
                </div>
            </div>
            <app-duplicate-orders (close)="closeDuplicateOrder($event)" *ngIf="isOpenDuplicate"
            class="duplicate pop-up pop-up-visible"></app-duplicate-orders>
        </div>
    </div>

    <div class="inform">
        <app-form-order class="form" (applyButtonEvent)="showDetails($event)" *ngIf="formVisible"></app-form-order>

        <app-details *ngIf="detailsVisible" class="details"></app-details>
        <img *ngIf="detailsVisible" (click)="showDetails(false)" class="button-edit" alt="button-edit" src="../../../../../assets/images/extra/button-edit.svg"/>

        <button *ngIf="detailsVisible" type="button" [disabled]="disabledButton" [ngClass]="{'disabled': disabledButton}" 
        class="filled-button not-visible-in-desktop"
        (click)="confirmOrder()">Confirmar</button>
    </div>
</section> 