import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CondicionalComponent } from './condicional.component';
import { ArrowSvgModule } from 'src/app/shared/styles/svg/arrow/arrow.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NumberInputModule } from 'src/app/components/inputs/number/number.module';
import { DateInputModule } from 'src/app/components/inputs/date/date.module';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { DateMonthInputModule } from 'src/app/components/inputs/date-month/date.module';



@NgModule({
  declarations: [
    CondicionalComponent
  ],
  imports: [
    CommonModule,
    ArrowSvgModule,
    MatDatepickerModule,
    NumberInputModule,
    DateInputModule,
    MatTabsModule,
    DateMonthInputModule
  ], exports:[
    CondicionalComponent
  ]
})
export class CondicionalModule { }
