<div class="field">
    <span class="field-title">Particularidad de negocio</span>

    <div>
        <div class="form-field" [ngClass]="{'border-green': isOptionSelected}"
            (click)="changeShowOptions()">
            <span class="title-field"> 
                <input #inputName type="text" (keypress)="verifyEnter($event)"
                value="{{elementSelected}}" class="no-background-border" [placeholder]="placeHolder" />
            </span>
        </div>

        <div class="background opaque" *ngIf="showOptions" (click)="closeSlide()"></div>
        <b-p-slide-card class="slide-card slide-card-visible" *ngIf="showOptions" (closeEvent)="closeSlide()" 
        [fieldIdentificator]="fieldIdentificator"></b-p-slide-card>
    </div>
</div>