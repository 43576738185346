import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusConst } from '../../const/orders';
const PENDIENTES=[orderStatusConst.CHECK, orderStatusConst.OFERTED, orderStatusConst.PENDING, orderStatusConst.OUT_OF_MARKET, orderStatusConst.SEND_TO_BROKE]
const RETURNED=[orderStatusConst.RETURNED]
const CONFIRMED=[orderStatusConst.CONFIRM, orderStatusConst.AUTORIZATION, orderStatusConst.CONTRACT]

@Pipe({
  name: 'filterByStatus'
})
export class FilterByStatusPipe implements PipeTransform {

  transform(list: any[], status: number) {
    let newList: any[]=JSON.parse(JSON.stringify(list));
    let arrayFinal: any[] = [];
    const statusArray = getArrayByStatus(status);
    
    //Ordenamos por hora
  
    newList.sort(function(a,b){
        return compareByHour(a.hour,b.hour);
    })

    for(let i=0; i<list.length; i++){
        if ( statusArray.includes(list[i].statusOrder)) {
            arrayFinal.push(list[i]);
        }

        if(arrayFinal.length == 6){
            i=list.length; //Para que corte
        }
    }

    return arrayFinal;
  }
}

  /*We obtain the corresponding array according to the state passed by parameter.
If it is pending, fix pending
If it is returned, fix returned
If confirmed, fix confirmed */
export function getArrayByStatus(status: number) {
  if (PENDIENTES.includes(status)) {
    return PENDIENTES;
  } else if (RETURNED.includes(status)) {
    return RETURNED;
  } else if (CONFIRMED.includes(status)) {
    return CONFIRMED;
  }
  return [];
}

export function compareByHour(hour1:string, hour2:string):number{
  
    if (hour1<hour2) {
        return -1;
      }
    if (hour1>hour2) {
        return 1;
    }
    // a debe ser igual b
      return 0;
}