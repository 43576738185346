export const santaCruzLocalities=[
    {"afip":"20","location":"28 De Noviembre"},
    {"afip":"172","location":"Aguada A Pique"},
    {"afip":"173","location":"Aguada Alegre"},
    {"afip":"186","location":"Aguada Escondida"},
    {"afip":"188","location":"Aguada Grande"},
    {"afip":"190","location":"Aguada La Oveja"},
    {"afip":"352","location":"Alma Grande"},
    {"afip":"524","location":"An Aike"},
    {"afip":"984","location":"Bahia Langara"},
    {"afip":"986","location":"Bahia Laura"},
    {"afip":"990","location":"Bahia Tranquila"},
    {"afip":"1018","location":"Bajo Caracoles"},
    {"afip":"1038","location":"Bajo Fuego"},
    {"afip":"1653","location":"Cabo Blanco"},
    {"afip":"1654","location":"Cabo De Las Virgenes"},
    {"afip":"1660","location":"Cabo Tres Puntas"},
    {"afip":"1750","location":"Caleta Olivia"},
    {"afip":"1789","location":"Cameron"},
    {"afip":"1814","location":"Campamento Dorotea"},
    {"afip":"2035","location":"Camuzu Aike"},
    {"afip":"2047","location":"Cancha Carrera"},
    {"afip":"2093","location":"Cap"},
    {"afip":"2147","location":"Cara Mala"},
    {"afip":"2388","location":"Cañada De Las Vacas"}
    ,
    {"afip":"2443","location":"Cañadon 11 De Setiembre"}
    ,
    {"afip":"2446","location":"Cañadon Botello"}
    ,
    {"afip":"2454","location":"Cañadon De Las Vacas"}
    ,
    {"afip":"2459","location":"Cañadon Del Rancho"}
    ,
    {"afip":"2460","location":"Cañadon Del Toro"}
    ,
    {"afip":"2461","location":"Cañadon Fabre"}
    ,
    {"afip":"2466","location":"Cañadon Leon"}
    ,
    {"afip":"2468","location":"Cañadon Molinari"}
    ,
    {"afip":"2470","location":"Cañadon Pluma"}
    ,
    {"afip":"2471","location":"Cañadon Seco"}
    ,
    {"afip":"2656","location":"Cerro La Setenta"},
    {"afip":"2679","location":"Cerro Mangrullo"},
    {"afip":"2686","location":"Cerro Moro"},
    {"afip":"2698","location":"Cerro Palique"},
    {"afip":"2722","location":"Cerro Renzel"},
    {"afip":"2733","location":"Cerro Silva"},
    {"afip":"2961","location":"Chimen Aike"},
    {"afip":"2990","location":"Chonque"},
    {"afip":"3632","location":"Colonia Leandro N Alem"},
    {"afip":"3990","location":"Comandante Luis Piedrabuena"},
    {"afip":"4018","location":"Condor Clif"},
    {"afip":"4097","location":"Coronel Guarumba"},
    {"afip":"4106","location":"Coronel Martin Irigoyen"},
    {"afip":"4329","location":"Cueva De Las Manos"},
    {"afip":"4679","location":"El Baile"},
    {"afip":"4686","location":"El Barbucho"},
    {"afip":"4820","location":"El Chalten"},
    {"afip":"4824","location":"El Chara"},
    {"afip":"4992","location":"El Guadal"},
    {"afip":"5052","location":"El Loro"},
    {"afip":"5172","location":"El Pan De Azucar"},
    {"afip":"5244","location":"El Pluma"},
    {"afip":"5253","location":"El Polvorin"},
    {"afip":"5460","location":"El Turbio"},
    {"afip":"5520","location":"El Zurdo"},
    {"afip":"5706","location":"Estacion Ing Atilio Cappa"},
    {"afip":"6069","location":"Faro Cabo Guardian"},
    {"afip":"6070","location":"Faro Campana"},
    {"afip":"6159","location":"Fitz Roy"},
    {"afip":"6183","location":"Fortaleza"},
    {"afip":"6278","location":"Fuentes De Coyle"},
    {"afip":"6328","location":"Garminue"},
    {"afip":"6347","location":"Gaypon"},
    {"afip":"6442","location":"Glencross"},
    {"afip":"6460","location":"Gobernador Gregores"},
    {"afip":"6467","location":"Gobernador Mayer"},
    {"afip":"6468","location":"Gobernador Moyano"},
    {"afip":"6593","location":"Guardaparque Fitz Roy"},
    {"afip":"6757","location":"Hotel Las Horquetas"},
    {"afip":"6916","location":"Ingeniero Atilio Cappa"},
    {"afip":"6941","location":"Ingeniero Pallavicini"},
    {"afip":"7142","location":"Jelaina"},
    {"afip":"7230","location":"Julia Dufour"},
    {"afip":"7279","location":"Koluel Kaike"},
    {"afip":"7358","location":"La Barreta"},
    {"afip":"7641","location":"La Fecundidad"},
    {"afip":"7642","location":"La Federica"},
    {"afip":"7803","location":"La Madrugada"},
    {"afip":"7809","location":"La Manchuria"},
    {"afip":"7941","location":"La Peninsula"},
    {"afip":"7961","location":"La Pigmea"},
    {"afip":"8221","location":"Lago Argentino"},
    {"afip":"8224","location":"Lago Buenos Aires"},
    {"afip":"8225","location":"Lago Cardiel"},
    {"afip":"8236","location":"Lago Posadas"},
    {"afip":"8238","location":"Lago Pueyrredon"},
    {"afip":"8240","location":"Lago Roca"},
    {"afip":"8242","location":"Lago San Martin"},
    {"afip":"8243","location":"Lago Strobel"},
    {"afip":"8244","location":"Lago Tar"},
    {"afip":"8619","location":"Las Piramides"},
    {"afip":"8742","location":"Leandro Niceforo Alem"},
    {"afip":"8971","location":"Los Antiguos"},
    {"afip":"9721","location":"Mata Magallanes"},
    {"afip":"9767","location":"Mazaredo"},
    {"afip":"9836","location":"Meseta Guengue"},
    {"afip":"9867","location":"Mina 3"},
    {"afip":"9923","location":"Minerales"},
    {"afip":"10034","location":"Monte Aymond"},
    {"afip":"10041","location":"Monte Ceballos"},
    {"afip":"10115","location":"Morro Chico"},
    {"afip":"10161","location":"Nacimientos Del Pluma"},
    {"afip":"10490","location":"Palermo Aike"},
    {"afip":"10493","location":"Pali Aike"},
    {"afip":"10636","location":"Pampa Verdum"},
    {"afip":"10786","location":"Paso Charles Fhur"},
    {"afip":"10835","location":"Paso Del Aguila"},
    {"afip":"10849","location":"Paso Del Rio Santa Cruz"},
    {"afip":"10861","location":"Paso Gregores"},
    {"afip":"10863","location":"Paso Ibañez"}
    ,
    {"afip":"10895","location":"Paso Rio La Leona"},
    {"afip":"10896","location":"Paso Roballo"},
    {"afip":"10989","location":"Peninsula Maipu"},
    {"afip":"11073","location":"Pico Truncado"},
    {"afip":"11089","location":"Piedra Clavada"},
    {"afip":"11671","location":"Puente Blanco"},
    {"afip":"11785","location":"Puerto Deseado"},
    {"afip":"11864","location":"Puerto San Julian"},
    {"afip":"11868","location":"Puerto Santa Cruz"},
    {"afip":"12119","location":"Punta Bandera"},
    {"afip":"12139","location":"Punta Del Lago Viedma"},
    {"afip":"12150","location":"Punta Loyola"},
    {"afip":"12227","location":"Quien Sabe"},
    {"afip":"12496","location":"Rincon De Los Morros"},
    {"afip":"12560","location":"Rio Bote"},
    {"afip":"12561","location":"Rio Calafate"},
    {"afip":"12590","location":"Rio Fenix"},
    {"afip":"12592","location":"Rio Gallegos"},
    {"afip":"12604","location":"Rio Mitre"},
    {"afip":"12629","location":"Rio Turbio"},
    {"afip":"12711","location":"Rospentek"},
    {"afip":"12712","location":"Rospentek Aike"},
    {"afip":"13315","location":"Sarai"},
    {"afip":"13741","location":"Tamel Aike"},
    {"afip":"13755","location":"Tapi Aike"},
    {"afip":"13790","location":"Tauel Aike"},
    {"afip":"13797","location":"Tehuelches"},
    {"afip":"13803","location":"Tellier"},
    {"afip":"14063","location":"Tres Lagos"},
    {"afip":"14083","location":"Tres Puntas"},
    {"afip":"14135","location":"Tucu Tucu"},
    {"afip":"14318","location":"Ventisquero Moreno"},
    {"afip":"14980","location":"Yacimiento Cerro Vanguardia"},
    {"afip":"15038","location":"Yegua Muerta"},
    {"afip":"15087","location":"Zanjon Del Pescado"},
    {"afip":"15390","location":"Bella Vista"},
    {"afip":"15567","location":"Cerro Alto"},
    {"afip":"15599","location":"Cerro Negro"},
    {"afip":"15601","location":"Cerro Puntudo"},
    {"afip":"15602","location":"Cerro Redondo"},
    {"afip":"15668","location":"Colonia Carlos Pellegrini"},
    {"afip":"15765","location":"Condor"},
    {"afip":"15822","location":"Desamparados"},
    {"afip":"15935","location":"El Calafate"},
    {"afip":"15978","location":"El Cerrito"},
    {"afip":"16078","location":"El Hueco"},
    {"afip":"16187","location":"El Paso"},
    {"afip":"16206","location":"El Portezuelo"},
    {"afip":"16336","location":"El Salado"},
    {"afip":"16513","location":"Floradora"},
    {"afip":"16582","location":"India Muerta"},
    {"afip":"16605","location":"Jaramillo"},
    {"afip":"16628","location":"La Aguada"},
    {"afip":"16651","location":"La Antonia"},
    {"afip":"16661","location":"La Argentina"},
    {"afip":"16670","location":"La Asturiana"},
    {"afip":"16754","location":"La Central"},
    {"afip":"16882","location":"La Esperanza"},
    {"afip":"16900","location":"La Estela"},
    {"afip":"16901","location":"La Esther"},
    {"afip":"16943","location":"La Florida"},
    {"afip":"16970","location":"La Guardia"},
    {"afip":"17023","location":"La Julia"},
    {"afip":"17042","location":"La Leona"},
    {"afip":"17084","location":"La Margarita"},
    {"afip":"17087","location":"La Maria"},
    {"afip":"17187","location":"La Porteña"}
    ,
    {"afip":"17206","location":"La Protegida"},
    {"afip":"17265","location":"La Rosa"},
    {"afip":"17269","location":"La Rosada"},
    {"afip":"17357","location":"La Victoria"},
    {"afip":"17360","location":"La Violeta"},
    {"afip":"17383","location":"Laguna Colorada"},
    {"afip":"17387","location":"Laguna Grande"},
    {"afip":"17521","location":"Las Heras"},
    {"afip":"17537","location":"Las Horquetas"},
    {"afip":"17574","location":"Las Masitas"},
    {"afip":"17583","location":"Las Mercedes"},
    {"afip":"17874","location":"Los Manantiales"},
    {"afip":"18090","location":"Monte Verde"},
    {"afip":"18218","location":"Paso De Los Indios"},
    {"afip":"18221","location":"Paso Del Medio"},
    {"afip":"18231","location":"Pellegrini"},
    {"afip":"18232","location":"Perito Moreno"},
    {"afip":"18361","location":"Pueblo Nuevo"},
    {"afip":"18420","location":"Punta Del Monte"},
    {"afip":"18423","location":"Punta Mercedes"},
    {"afip":"18500","location":"Rio Chico"},
    {"afip":"19129","location":"Sierra De La Ventana"},
    {"afip":"19223","location":"Tres Cerros"},
    {"afip":"20366","location":"Piedra Clavada"},
    {"afip":"20367","location":"El Pluma"},
    {"afip":"20368","location":"Lago Buenos Aires"},
    {"afip":"20463","location":"Cerro Redondo"}
    ]