import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetOrderExchangeToGenerate, setExchangeConfirmed, setExchangeInOrderToGenerate, setOrderToGenerateInExchange, showOrderExchangeDetails } from 'src/app/redux/actions/exchange.action';
import { EXCHANGE_STATUS_CONST, exchangeInitialState } from 'src/app/shared/const/exchange-orders';
import { OBJECT_ID_VOID } from 'src/app/shared/const/options';
import { orderStatusConst, SELLER_DATA_VOID } from 'src/app/shared/const/orders';
import { EXCHANGER_STATES } from 'src/app/shared/dict/exchange';
import { orderStates } from 'src/app/shared/dict/orders';
import { sellerWithData } from 'src/app/shared/dict/seller-with-data';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Exchange, OrderExchange } from 'src/app/shared/interfaces/order-exchange';
import { Order } from 'src/app/shared/interfaces/order.interface';
import { User } from 'src/app/shared/interfaces/user.interface';
import { OrderValidator } from 'src/app/shared/order';
import { OrderExchangeValidator, getSumOfTonsExchange } from 'src/app/shared/order-exchange';
import { ToFixValidator } from 'src/app/shared/to-fix';
import { getArrowDirectionByBool } from 'src/app/shared/validator.checker';
import { createOrderToTonsMissing } from '../exchange-details/exchange-details.component'; 
import { ResponseRequest } from 'src/app/shared/interfaces/options.interface';

@Component({
  selector: 'app-add-exchange',
  templateUrl: './add-exchange.component.html',
  styleUrls: ['./add-exchange.component.scss', './../slide-card.scss', './../details-eye/details-eye.component.scss']
})
export class AddExchangeComponent implements OnInit, OnDestroy{
  public order!:OrderExchange;
  EXCHANGE_STATES:any;
  SELLERS:any;
  ORDER_STATES:any;
  user!:User;

  messageError:string='';

  //  ARROWS  \\
  arrowViewCreateExchange:string='down';
  exchangeToGenerate!:Exchange;
  exchangesToGenerate:Exchange[]=[];
  tonsAvaible:number=0;
  contractMotherExchangeToGenerate!:OrderExchange;

  arrowViewExchange:string='down';
  arrowViewOrderData:string='down';

  arrowViewContractMother:string='down';
  numberContractMother:number=-1;
  orderContractMother!:Order|null;
  count=0;

  private user$!:Subscription;
  private exchange$!:Subscription;
  constructor(private store:Store<appState>, private toFixV:ToFixValidator, private orderV:OrderValidator, private exchangeV: OrderExchangeValidator) {}

  ngOnInit(): void {
    this.EXCHANGE_STATES= EXCHANGER_STATES;
    this.SELLERS=sellerWithData;
    this.ORDER_STATES=orderStates;

    this.user$=this.store.select('usuario').subscribe(user=>{
      this.user=user;
    })

    this.exchange$=this.store.select('exchange').subscribe(exchanger=>{
      this.order=exchanger.exchangeBoard.orderDetails;
      this.exchangeToGenerate=exchanger.generateOrder.exchange;

      if(this.exchangesToGenerate.length <= 1){
        this.exchangesToGenerate=[this.exchangeToGenerate];
      } else {
        let lastIndex=this.exchangesToGenerate.length-1;
        this.exchangesToGenerate[lastIndex]=JSON.parse(JSON.stringify(this.exchangeToGenerate));
      }

      if(exchanger.exchangeBoard.showOrderDetails && this.count==0){
        this.getAllTonsAvailable();
        this.count++;
      } else if(!exchanger.exchangeBoard.showOrderDetails){
        this.count=0;
      }
    })
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
    this.exchange$?.unsubscribe();
  }

  orderHaveIndicators(order:OrderExchange){
    return this.toFixV.orderHaveIndicators(order);
  }

  changeArrowViewCreateExchange(){
    let flag=true;
    if(this.arrowViewCreateExchange=='up'){
      flag=false;
    }
    this.closeAllArrows();

    if(flag){
      this.arrowViewCreateExchange=this.changeArrow(this.arrowViewCreateExchange);
    }

    if(this.arrowViewCreateExchange=='down'){
      //Cuando se cierra, reseteamos
      this.store.dispatch(resetOrderExchangeToGenerate()); //Reset form
    } else {
      //Cuando se hbre, seteamos
      try{
        this.contractMotherExchangeToGenerate=this.exchangeV.getParentOrder(this.order);
        
        this.store.dispatch(setOrderToGenerateInExchange({order:this.contractMotherExchangeToGenerate})); //Set order in exchange
        this.store.dispatch(setExchangeInOrderToGenerate({exchange:this.contractMotherExchangeToGenerate.exchange})); //Set exchange in order
      } catch(err){
        this.arrowViewCreateExchange='down';
        this.messageError='El vendedor necesita ser canjeador';
      }
    }
  }

  changeArrowViewExchange(){
    let flag=true;
    if(this.arrowViewExchange=='up'){
      flag=false;
    }
    this.closeAllArrows();

    if(flag){
      this.arrowViewExchange=this.changeArrow(this.arrowViewExchange);
    }
  }

  changeArrowViewOrderData(){
    let flag=true;
    if(this.arrowViewOrderData=='up'){
      flag=false;
    }
    this.closeAllArrows();
    if(flag){
      this.arrowViewOrderData=this.changeArrow(this.arrowViewOrderData);
    }
  }

  //Close all arrows
  closeAllArrows(){
    this.arrowViewCreateExchange='down';
    this.arrowViewExchange='down';
    this.arrowViewOrderData='down';
  }

  changeArrow(direction:string){
    return getArrowDirectionByBool(direction=='down');
  }

  close(){
    this.store.dispatch(showOrderExchangeDetails({show:false}))
    this.orderContractMother=null;
    this.numberContractMother=-1
  }

  getExchangeStatus(order:OrderExchange){
    return this.EXCHANGE_STATES[order?.exchange?.status] ?? '--';
  }

  getStatusContractMother(order:OrderExchange){
    return this.ORDER_STATES[order?.statusOrder] ?? '--';
  }

  getBuyerData(order:OrderExchange){
    try{
      let stringToReturn=this.SELLERS[order.exchange.buyer.codeS].nombre;
      if(order.exchange.tons>0){
        stringToReturn+= ' - ' + order.exchange.tons + ' toneladas.';
      } else {
        stringToReturn+=' - 0 toneladas.';
      }
      return stringToReturn ;
    } catch (error) {
      return '--';
    }
  }

  getBuyerDataOfExchange(exchange:Exchange){
    try{
      let stringToReturn=this.SELLERS[exchange.buyer.codeS].nombre;
      if(exchange.tons>0){
        stringToReturn+= ' - ' + exchange.tons + ' toneladas.';
      } else {
        stringToReturn+=' - 0 toneladas.';
      }
      return stringToReturn ;
    } catch (error) {
      return '--';
    }
  }

  getSellerData(order:OrderExchange){
    try{
      return this.SELLERS[order.sellerData.codeS].nombre + ' - ' + order.tons + ' toneladas.';
    } catch (error) {
      return '--';
    }
  }

  isOrderIncomplete(order:Order){
    return this.orderV.isOrderIncomplete(order)
  }

  isExchangeIncomplete(exchange:Exchange){
    return this.exchangeV.isExchangeIncomplete(exchange);
  }

  addOtherExchange(){
    //Solo añadimos otro, si se completó un canje con toneladas mayores a 0.
    if(!this.isExchangeWithTonsLessThanZero()){
      let newExchange:Exchange=JSON.parse(JSON.stringify(exchangeInitialState));
      newExchange.placeOfOrigin.province=this.exchangeToGenerate.placeOfOrigin.province;
      newExchange.placeOfOrigin.town=this.exchangeToGenerate.placeOfOrigin.town;
      newExchange.placeOfOrigin.afip=this.exchangeToGenerate.placeOfOrigin.afip;
      newExchange.tons=this.getOutstandingTons();
      this.exchangesToGenerate.push(newExchange)
      this.store.dispatch(resetOrderExchangeToGenerate());
      this.store.dispatch(setExchangeInOrderToGenerate({exchange:newExchange}));
    }
  }

  /*There is an exchange with tons less than or equal to 0*/
  isExchangeWithTonsLessThanZero(){
    let exchangeWithTonsLessThanZero=false;
    this.exchangesToGenerate.forEach(exchange=>{
      if(exchange.tons<=0){
        exchangeWithTonsLessThanZero=true;
      }
    })
    return exchangeWithTonsLessThanZero;
  }

  selectExchangeInArray(index:number){
    /*We save the index element in a variable, delete it from the original array and insert it last*/
    let exchangeToMove:Exchange=JSON.parse(JSON.stringify(this.exchangesToGenerate[index]));
    this.exchangesToGenerate.splice(index, 1);
    this.exchangesToGenerate.push(exchangeToMove);
    this.store.dispatch(setExchangeInOrderToGenerate({exchange:exchangeToMove}));
  }

  getExchanges(){
    /* The last element of the array is removed and the array without that element is used (without modifying the initial array) */
    return this.exchangesToGenerate;
  }

  createNewExchange(){
    const quantOfExchanges=this.exchangesToGenerate.length;
    for(let i=0; i<quantOfExchanges; i++){
      let newExchangeToGenerate=JSON.parse(JSON.stringify(this.exchangesToGenerate[i]));
      let statusToSet= !this.isExchangeIncomplete(this.exchangesToGenerate[i]) ? EXCHANGE_STATUS_CONST.VERIFICAR : EXCHANGE_STATUS_CONST.INCOMPLETO;
      newExchangeToGenerate.status= statusToSet;  
      let newOrder=this.exchangeV.setExchangeInOrder(this.contractMotherExchangeToGenerate, newExchangeToGenerate);
      this.exchangeV.saveOrder(newOrder, this.user);
    }

    this.store.dispatch(resetOrderExchangeToGenerate());
    this.closeAllArrows();
    this.close();
  }

  //If the tons of the order are more than the sum of the tons of the arrangement
  showMissingTons(){
    return this.tonsAvaible;
  }

  getOutstandingTons(){
    return this.tonsAvaible;
  }

  createOtherOrderToTonsMissing(orderMother:OrderExchange){
    createOrderToTonsMissing(orderMother, this.user, this.exchangeV);
  }

  getAllTonsAvailable(){
    let getContraparts$=this.exchangeV.getContraparts(this.order.exchange.idMother);
    getContraparts$.subscribe( (res:ResponseRequest) =>{//contraparts=>{
      let allQuantOfTons=getSumOfTonsExchange(res.data);//contraparts);
      this.tonsAvaible=this.order.tons-allQuantOfTons;
    })
  }
}