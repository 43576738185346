import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyGenerateExchangeComponent } from './body-generate-exchange.component';
import { FormOrderExchangeModule } from 'src/app/components/form/form-exchange/form.module';
import { DetailsExchangeModule } from 'src/app/components/details/details-exchange/details.module';
import { ButtonsConditionTypeModule } from 'src/app/components/buttons-condition-type/buttons-condition-type.module';

@NgModule({
  declarations: [
    BodyGenerateExchangeComponent
  ],
  imports: [
    CommonModule,
    FormOrderExchangeModule,
    DetailsExchangeModule,
    ButtonsConditionTypeModule,
  ], exports :[
    BodyGenerateExchangeComponent
  ]
})
export class BodyGenerateExchangeModule { }
