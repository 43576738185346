import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { exchangeTags } from 'src/app/shared/const/exchange-orders';
import { fieldType } from 'src/app/shared/const/options';
import { priceTags } from 'src/app/shared/const/prices.const';
import { appState } from 'src/app/shared/interfaces/appState.interface';
import { Exchange } from 'src/app/shared/interfaces/order-exchange';
import { Field } from '../fields.component';

@Component({
  selector: 'app-bonification-exchange',
  templateUrl: './exchange-bonus.component.html',
  styleUrls: ['./../fields.component.scss']
})
export class BonificationExchangeComponent extends Field implements OnInit {
  amountB: number = -1;
  percentageB: number = -1;
  constructor(store: Store<appState>) {
    super(store);
  }

  ngOnInit(): void {
    if (this.fieldIdentificator== fieldType.EXCHANGE) {
      this.tag = exchangeTags.percentageB;
    }
  }
  override rememberValueInForm(){
    try {
      this.tag='' //Traemos todo lo de canje
      let valueToSet: Exchange = this.rememberValue();
      
      //If There is a value to remember
      if (this.elementToGenerate && valueToSet) {
        this.amountB= valueToSet.amountB;
        this.percentageB= valueToSet.percentageB;
      }
    } catch (err) {
        this.amountB= -1;
        this.percentageB= -1;
    }
  }

  setElementBonus(value:number, tag:string){
    this.tag=tag; //Seteamos el tag
    this.setElementToGenerate(value); //Seteamos el valor

    this.tag= exchangeTags.percentageB==tag? exchangeTags.amountB: exchangeTags.percentageB;
    this.setElementToGenerate(-1); //Seteamos el valor en 0
  }
}

