import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent {
  @Input() option:string='';

  //This is a variable for know if the checked is true or false
  @Input() isChecked: boolean=false; 

  //This is a variable for the parent component
  //for create a new view
  @Output() checked=new EventEmitter<string>();
  constructor() { }

  setNewView(event:any){
    this.checked.emit(event.checked);
  }
}
